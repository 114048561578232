import {
  TRIPPLANNER_ACC_PREF_PRIORITIES_SET_ACC,
  TRIPPLANNER_ACC_PREF_REMOVE_ACC_PRIORITY,
  TRIPPLANNER_ACC_PREF_CHANGE_PRIORITY,
  TRIPPLANNER_ACC_PREF_CLEAR_DEST_PRIORITIES,
  TRIPPLANNER_ACC_PREF_CHANGE_PRIORITY_BEHAVIOUR,
} from "./types";

export const accPrioritiesAdd = ({ destOrder, acc }) => {
  return { type: TRIPPLANNER_ACC_PREF_PRIORITIES_SET_ACC, destOrder, acc };
};

export const accPrioritiesRemove = ({ destOrder, accUid }) => {
  return { type: TRIPPLANNER_ACC_PREF_REMOVE_ACC_PRIORITY, destOrder, accUid };
};

export const accPrioritiesOrderChange = ({ destOrder, currentIdx, newIdx }) => {
  return {
    type: TRIPPLANNER_ACC_PREF_CHANGE_PRIORITY,
    destOrder,
    currentIdx,
    newIdx,
  };
};

export const accPrioritiesClear = ({ destOrder }) => {
  return { type: TRIPPLANNER_ACC_PREF_CLEAR_DEST_PRIORITIES, destOrder };
};

export const accPrioritiesChangeBehaviour = ({ destOrder, behaviour }) => {
  return {
    type: TRIPPLANNER_ACC_PREF_CHANGE_PRIORITY_BEHAVIOUR,
    destOrder,
    behaviour,
  };
};
