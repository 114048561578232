import _ from "lodash";
import React from "react";
import { accPrebook } from "@src/api";
import { toast } from "react-toastify";
import { accPrebookPayloadCreator } from "../accommodation_actions";
import {
  idlePrebookAccModal,
  loadingPrebookAccModal,
  resetAccPrebookData,
  setAccPrebookData,
} from "../step_four";
import { TRIPPLANNER_SHOW_PREBOOKACCOMMODATION_MODAL } from "../types";
import { getAccSrvsForPrebookSelector } from "@src/selectors/Project/TripPlanner/prebook";

function showAccPrebookModal() {
  return { type: TRIPPLANNER_SHOW_PREBOOKACCOMMODATION_MODAL };
}

export const startAccPrebook = () => async (dispatch, getState) => {
  const state = getState();

  const selected_accs = getAccSrvsForPrebookSelector(state);

  if (!selected_accs.length) {
    toast.warning(
      <p>
        Please select at least one <strong>hotel</strong> first.
      </p>,
      { duration: 5000 }
    );
    return;
  }

  const payload_data = [];
  selected_accs.forEach((acc) => {
    payload_data.push(accPrebookPayloadCreator(acc, acc.session_id));
  });

  await dispatch(loadingPrebookAccModal());
  await dispatch(resetAccPrebookData());
  dispatch(showAccPrebookModal());

  const prebook_promises = [];
  for (let i = 0; i < payload_data.length; i++) {
    prebook_promises.push(
      (async () => {
        const payload = payload_data[i].payloads[0];
        const { session_id, acc_id, booking_codes, destOrder, supplier, uids } =
          payload;

        const result = await accPrebook({
          session_id,
          acc_id,
          booking_codes,
          supplier,
          uids,
          market_type: "B2B",
        });

        if (!result) {
          const { accommodation } = payload_data[i];
          dispatch(setAccPrebookData(destOrder, { failed: true }));
          const dest_name = _.get(
            state.tripPlannerDestinations.find(
              (d) => d.order == accommodation.destOrder
            ) || {},
            "name_en",
            "N/A"
          );
          toast.warning(
            <p>{`We failed to get prebook information for ${accommodation.metadata.name} in ${dest_name}`}</p>,
            { duration: 10000 }
          );
        } else {
          await dispatch(
            setAccPrebookData(
              destOrder,
              _.pick(
                result,
                Object.keys(result).filter((k) => k !== "status")
              )
            )
          );
        }
      })()
    );
  }

  await Promise.all(prebook_promises);
  dispatch(idlePrebookAccModal());
};
