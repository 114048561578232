import {
  changePkgMetadata,
  saveMktTrip,
} from "@src/actions/Project/TripPlanner";
import { CustomButton } from "@src/components/common/buttons";
import { NormalInputField, NormalSelectField } from "@src/components/forms";
import { formStyles, variables } from "@src/jsssetup";
import { Form, Formik, useFormikContext } from "formik";
import React, { useCallback, useState } from "react";
import { createUseStyles } from "react-jss";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Loader } from "rsuite";

import * as Yup from "yup";

const schema = Yup.object().shape({
  themes: Yup.string().test(
    "is-csv",
    "Themes must be a comma-separated list of values",
    (value) => {
      if (!value) return true;
      return value.split(",").every((theme) => theme.trim() !== "");
    }
  ),
  order: Yup.number()
    .required("Required")
    .min(0, "Order must be a non-negative number"),
});

const SubmitBtn = () => {
  const { submitForm } = useFormikContext();
  return <CustomButton onClick={submitForm}>Submit</CustomButton>;
};

const pkgMetadataStyles = createUseStyles({
  PkgMetadata: {
    display: "grid",
    gridTemplateColumns: "max-content 1fr",
    gridGap: variables.normal_gap,
    padding: variables.normal_gap,
  },
  actions: {
    display: "grid",
    gridGap: variables.normal_gap,
    gridAutoRows: "max-content",
    alignContent: "center",
  },
  form: {
    ...formStyles.form,
    display: "grid",
    gridTemplateColumns: "repeat(3, max-content)",
    gridGap: variables.normal_gap,
  },
});
const PkgMetadata = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const { order, themes, rating } = useSelector(
    (state) => state.tripPlannerPkgMetadata
  );

  const dispatch = useDispatch();
  const onSubmit = useCallback(
    async ({ order, themes, rating }) => {
      setIsLoading(true);
      await dispatch(changePkgMetadata({ order, themes, rating }));
      const result = await dispatch(saveMktTrip());
      if (result) {
        toast.success("Trip Metadata updated successfully", {
          autoClose: 3000,
        });
      }

      setEditMode(false);
      setIsLoading(false);
    },
    [dispatch]
  );

  const classes = pkgMetadataStyles();
  return (
    <Formik
      initialValues={{ themes: themes.join(", "), order, rating }}
      validationSchema={schema}
      onSubmit={({ order, themes, rating }) => {
        onSubmit({
          order,
          themes: [
            ...new Set(themes.split(",").map((theme) => theme.trim())),
          ].filter((t) => t),
          rating,
        });
      }}>
      <div className={classes.PkgMetadata}>
        {isLoading && <Loader size="lg" backdrop center />}
        <div className={classes.actions}>
          {editMode ? (
            <React.Fragment>
              <SubmitBtn />
              <CustomButton
                appearance="ghost"
                onClick={() => setEditMode(false)}>
                Cancel
              </CustomButton>
            </React.Fragment>
          ) : (
            <CustomButton onClick={() => setEditMode(true)}>Edit</CustomButton>
          )}
        </div>
        {!editMode ? (
          <div className={classes.form}>
            <span>
              <strong>Order: </strong>
              {order}
            </span>
            <span>
              <strong>Themes: </strong>
              {themes.join(", ") || "N/A"}
            </span>
            <span>
              <strong>Rating: </strong>
              {rating}
            </span>
          </div>
        ) : (
          <Form className={classes.form}>
            <NormalInputField
              name="order"
              label="Order"
              type="number"
              extraInputProps={{ min: 0 }}
            />
            <NormalInputField name="themes" label="Themes" />
            <NormalSelectField
              name="rating"
              label="Rating"
              options={[
                [0, "0 Stars"],
                [1, "1 Star"],
                [2, "2 Stars"],
                [3, "3 Stars"],
                [4, "4 Stars"],
                [5, "5 Stars"],
              ]}
            />
          </Form>
        )}
      </div>
    </Formik>
  );
};

export default PkgMetadata;
