import service from "@src/api/request";

export function fetchPaymentMethodList(params = null) {
  const payload = {
    url: "bid/payment-method/",
    method: "get",
  };

  if (params) {
    payload["params"] = params;
  }

  return service(payload);
}

export function attachPaymentMethodToCustomer(id) {
  return service({
    url: `bid/payment-method/attach/`,
    method: "post",
    data: { sid: id },
  });
}

export function setDefaultPaymentMethod(id) {
  return service({
    url: `/bid/payment-method/set-default/`,
    method: "post",
    data: { sid: id },
  });
}

export function detachPaymentMethod(id) {
  return service({
    url: `/bid/payment-method/detach/`,
    method: "post",
    data: { sid: id },
  });
}

export function getStripeInitData() {
  return service({
    url: "/bid/stripe-account-info/",
    method: "get",
  });
}
