import _ from "lodash";
import service from "@src/api/request";
import { SISI_API_URL } from "@src/api";
import axios from "axios";

export function getBranchesList(para) {
  return service({
    url: "/bid/branch/",
    method: "get",
    params: para,
  });
}

export function fetchBranchesProfile(id) {
  return service({
    url: `/bid/branch/${id}`,
    method: "get",
  });
}

export function getBranchesOptions(lang) {
  return service({
    url: "/bid/branch/",
    method: "options",
    headers: {
      "Accept-Language": lang,
    },
  });
}

export function submitBranchesProfile(id, payload, mode) {
  return service({
    url: `/bid/branch/${id}/`,
    method: mode,
    data: payload,
  });
}

export function addNewBranches(data) {
  return service({
    url: "/bid/branch/",
    method: "post",
    data: data,
  });
}

export function fetchBranchesQuickInfoList(para) {
  return service({
    url: "/bid/branch/get_quick_info/",
    method: "get",
    params: para,
  });
}

export async function branchesAutocomplete(params) {
  try {
    const result = await axios.get(
      `${SISI_API_URL}/crm/branches/autocomplete/`,
      { params }
    );
    return _.get(result, "data");
  } catch (error) {
    console.log("error");
    return null;
  }
}
