import {
  MY_OFFER_UNSENT_VIEW_TYPE,
  MY_OFFER_UNSENT_FETCH_GRAPH_DATA,
  MY_OFFER_UNSENT_BEGIN_FETCH_GRAPH_DATA,
  MY_OFFER_UNSENT_END_FETCH_GRAPH_DATA,
  MY_OFFER_UNSENT_INIT_GRAPH_TYPE,
  MY_OFFER__UNSENT_CHANGE_PRICE_STATUS,
  MY_OFFER_UNSENT_SAVE_RESULTS,
  MY_OFFER_UNSENT_LIST_GENERIC_CHANGE,
} from "@src/actions/Project/Archive/MyOffers/InProgress/types";

import { commonList } from "../../../../common";
import * as yup from "yup";

const defaultFilters = yup.object().shape({
  reference: yup.string().default(""),
  title__icontains: yup.string().default(""),
  trip_date__gte: yup.string().default(""),
  trip_date__lte: yup.string().default(""),
  destination__icontains: yup.string().default(""),
  creator__icontains: yup.string().default(""),
  is_owner: yup.boolean().default(true),
  status: yup.string().default(""),
  stay__gte: yup.string().default(""),
  stay__lte: yup.string().default(""),
  page: yup.number().default(1),
  limit: yup.number().default(6),
  link: yup.string().default(""),
  ai_generated: yup.string().default(""),
  target_entity: yup.string().default(""),
  target_entity_autocomplete: yup.string().default(""),
});

export const newMyOffersInProgressList = (
  state = {
    ...commonList,
    displayLength: 100,
    filters: defaultFilters.cast({}),
    defaultFilters: defaultFilters.cast({}),
  },
  action
) => {
  switch (action.type) {
    case MY_OFFER_UNSENT_SAVE_RESULTS: {
      return action.data;
    }
    case MY_OFFER_UNSENT_LIST_GENERIC_CHANGE: {
      const { filters } = action;
      return { ...state, filters: { ...state.filters, ...filters } };
    }
    default:
      return state;
  }
};

//====================================DASHBOARD===============================
export const initData = {
  date: "current",
  type: "PR",
};
export const myOffersUnsentViewType = (state = initData, action) => {
  switch (action.type) {
    case MY_OFFER_UNSENT_INIT_GRAPH_TYPE:
      return initData;
    case MY_OFFER_UNSENT_VIEW_TYPE:
      return action.data;
    default:
      return state;
  }
};

export const myOffersUnsentGraphData = (state = {}, action) => {
  switch (action.type) {
    case MY_OFFER_UNSENT_FETCH_GRAPH_DATA:
      return action.data;
    default:
      return state;
  }
};

export const initialMyOffersUnsentGraphDataStatus = "IDLE";
export const myOffersUnsentGraphDataStatus = (
  state = initialMyOffersUnsentGraphDataStatus,
  action
) => {
  switch (action.type) {
    case MY_OFFER_UNSENT_BEGIN_FETCH_GRAPH_DATA:
      return "FETCHING";
    case MY_OFFER_UNSENT_END_FETCH_GRAPH_DATA:
      return "FETCHED";
    default:
      return state;
  }
};

export const initialSetMyOfferPrice = "selling";
export const setMyOfferPrice = (state = initialSetMyOfferPrice, action) => {
  switch (action.type) {
    case MY_OFFER__UNSENT_CHANGE_PRICE_STATUS:
      return action.data;
    default:
      return state;
  }
};
