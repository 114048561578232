import { createSelector } from "reselect";

import {
  filtersToServices,
  filterTrp,
  filterAcc,
  filterTrf,
  selectedServices,
  selectedBidding,
  selectedBiddingDestinations,
  doneBiddings,
} from "./service_filters";

export {
  filterTrp,
  filterAcc,
  filterTrf,
  filtersToServices,
  selectedServices,
  selectedBidding,
  selectedBiddingDestinations,
  doneBiddings,
};

const userMeta = (state) => state.userMeta;
export const getFlightAPIKey = createSelector(userMeta, (userMeta) => {
  const source_entity = `${userMeta.company_id}___${userMeta.company_type}`;
  const api_key = window.btoa(source_entity);
  return api_key;
});

export * from "./trip_setup_selectors";
export * from "./trip_itinerary_selectors";
export * from "./pricing_selectors";
export * from "./transportation_selectors";
export * from "./accommodation_selectors";
export * from "./room_selectors";
export * from "./flight_selectors";
export * from "./transfer_selectors";
export * from "./prebook_selectors";
export * from "./addon_selectors";
