import { createSelector } from "reselect";
import { getTripLegs } from "./transportation_selectors";

const getFerrySessions = (state) =>
  state.tripPlannerFerriesReducer?.sessions ?? [];

const getFerryServices = (state) =>
  state.tripPlannerFerriesReducer?.services ?? [];

const propsInjector = (___, props) => props;

export const getFerryByLegUidSelector = createSelector(
  [getFerryServices, getFerrySessions, getTripLegs, propsInjector],
  (ferries, sessions, tripLegs, props) => {
    const { legUid } = props;
    const tripLeg = tripLegs.find((l) => l.uid === legUid);

    const session = sessions.find((s) => s?.payload?.legUid === tripLeg.uid);

    const ferryData = ferries.find((s) => s.legUid === tripLeg.uid);
    return { session, ferryData };
  }
);
