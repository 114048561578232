import _ from "lodash";
import { modalGenericStyles, variables } from "@src/jsssetup";
import {
  getSetupFormDataSelector,
  requiredRoomsNumberSelector,
} from "@src/selectors/Project/TripPlanner";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { createUseStyles } from "react-jss";
import { useDispatch, useSelector } from "react-redux";
import { adhocPriceFields } from "../coach";
import { editAdhocAccSrv } from "@src/actions/Project/TripPlanner/addhoc/accommodation";
import { AdhocAccForm } from "./form";

const styles = createUseStyles({
  AdhocAccSrvModal: modalGenericStyles.modal,
  card: { ...modalGenericStyles.card, maxWidth: "90vw" },
  header: modalGenericStyles.cardHeader,
  [`@media ${variables.media.bigscreen}`]: {
    card: { maxWidth: "60vw" },
  },
});
export const EditAdhocAccSrvModal = ({ uid, onCancel }) => {
  const { adults, children: childs } = useSelector((state) =>
    getSetupFormDataSelector(state)
  );
  const rooms_number = useSelector((state) =>
    requiredRoomsNumberSelector(state)
  );
  const accommodation = useSelector((state) =>
    state.tripPlannerAccAddhocData.find((srv) => srv.uid === uid)
  );

  const dispatch = useDispatch();
  const onEdit = useCallback(
    ({ srv }) => dispatch(editAdhocAccSrv({ uid, srv })),
    [dispatch]
  );

  const classes = styles({
    imgsLength: _.get(accommodation.metadata, "images", []).length,
  });

  const initialValues = {
    search_accommodation: "",
    check_in: accommodation.check_in,
    check_out: accommodation.check_out,
    ...adhocPriceFields({
      total_price: accommodation.total_price,
      per_person_price: accommodation.per_person_price,
      currency: accommodation.currency,
    }),
    per_night_price: accommodation.per_night_price,
    metadata: accommodation.metadata,
    rooms: accommodation.rooms,
    extras: _.get(accommodation, "extras", []),
  };

  return (
    <div className={classes.AdhocAccSrvModal}>
      <div className="Modal__card">
        <div className="Modal__card__header">
          <h5>
            Edit Accommodation Service: {accommodation.metadata.name}
            {", "}
            {adults} {adults > 1 ? "Adults" : "Adult"}, {childs}{" "}
            {childs > 1 ? "Children" : "Child"}, {rooms_number} Room
            {rooms_number > 1 ? "s" : ""}
          </h5>
        </div>
        <AdhocAccForm
          searchable={false}
          hotelMeta={accommodation.metadata}
          initialValues={initialValues}
          onSubmit={(values) => {
            onEdit({ srv: { ...accommodation, ...values } });
            onCancel();
          }}
          onCancel={onCancel}
        />
      </div>
    </div>
  );
};
EditAdhocAccSrvModal.propTypes = {
  uid: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
};
