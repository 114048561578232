import {
  //======================LIST======================
  MEMBERS_LOADING_LIST,
  MEMBERS_FETCH_LIST_DATA,
  MEMBERS_IDLE_LIST,
  MEMBERS_VIEW_TYPE_CHANGE,
  //=======================FILTER===================
  MEMBERS_INITIALIZE_LIST_FILTER_FORM,
  MEMBERS_UPDATE_LIST_FILTER_FORM,
  MEMBERS_APPLY_LIST_FILTER,
  MEMBERS_RESET_LIST_FILTER_FORM,
  //=====================COLUMNS======================
  MEMBERS_APPLY_LIST_COLUMNS_FORM,
  //==================ABOUT US========================
  SYSTEM_NET_HIDE_ABOUT_US_MODAL,
  SYSTEM_NET_SHOW_ABOUT_US_MODAL,
  SYSTEM_NET_GET_ABOUT_US_MODAL_DATA,
  //================= MODAL=====================
  MEMBERS_SHOW_CONNECT_MODAL,
  MEMBERS_HIDE_CONNECT_MODAL,
  MEMBERS_SEND_EMAIL_CONTENT,
  MEMBERS_BEGIN_SEND_CONNECT_EMAIL,
  MEMBERS_END_SEND_CONNECT_EMAIL,
  MEMBERS_GET_CONNECT_MODAL_DATA,
} from "@src/actions/Network/Members/types";

import {
  initialMembersFilterForm,
  initialMembersColumnsSetUp,
} from "@src/forms/Network/Members";

import { formProcessor, entitiesListSelector } from "@src/reducers/tools";

export const membersListFilter = (state = initialMembersFilterForm, action) => {
  switch (action.type) {
    case MEMBERS_INITIALIZE_LIST_FILTER_FORM:
      return action.data;
    case MEMBERS_UPDATE_LIST_FILTER_FORM:
      return formProcessor(
        state,
        action.data.type,
        action.data.name,
        action.data.value
      );
    case MEMBERS_APPLY_LIST_FILTER:
      return action.data;
    case MEMBERS_RESET_LIST_FILTER_FORM:
      return action.data;
    default:
      return state;
  }
};

export const initialList = {
  count: 0,
  next: null,
  previous: null,
  results: [],
  page: 1,
  limit: 6,
  displayLength: 100,
};

export const fetchMembersListData = (state = initialList, action) => {
  switch (action.type) {
    case MEMBERS_FETCH_LIST_DATA:
      return { ...state, ...action.data };
    case MEMBERS_LOADING_LIST:
      return { ...state, loading: true };
    case MEMBERS_IDLE_LIST:
      return { ...state, loading: false };
    case MEMBERS_RESET_LIST_FILTER_FORM:
      return { ...state, page: 1 };
    default:
      return state;
  }
};

export const initialChangeMembersViewModal = false;
export const changeMembersViewModal = (
  state = initialChangeMembersViewModal,
  action
) => {
  switch (action.type) {
    case MEMBERS_VIEW_TYPE_CHANGE:
      return action.data;
    default:
      return state;
  }
};

export const membersListColumns = (
  state = entitiesListSelector(initialMembersColumnsSetUp),
  action
) => {
  switch (action.type) {
    case MEMBERS_APPLY_LIST_COLUMNS_FORM:
      return entitiesListSelector(action.data);
    default:
      return state;
  }
};

//======================about us modal=======================
export const initialAboutUsModalStatus = false;
export const aboutUsModalStatus = (
  state = initialAboutUsModalStatus,
  action
) => {
  switch (action.type) {
    case SYSTEM_NET_SHOW_ABOUT_US_MODAL:
      return true;
    case SYSTEM_NET_HIDE_ABOUT_US_MODAL:
      return false;
    default:
      return state;
  }
};

export const aboutUsModalDataDetail = (state = {}, action) => {
  switch (action.type) {
    case SYSTEM_NET_GET_ABOUT_US_MODAL_DATA:
      return action.data;
    default:
      return state;
  }
};

export const initialSendConnectEmailModalStatus = false;
export const sendConnectEmailModalStatus = (
  state = initialSendConnectEmailModalStatus,
  action
) => {
  switch (action.type) {
    case MEMBERS_SHOW_CONNECT_MODAL:
      return true;
    case MEMBERS_HIDE_CONNECT_MODAL:
      return false;
    default:
      return state;
  }
};

export const sendConnectEmailModalDetail = (state = {}, action) => {
  switch (action.type) {
    case MEMBERS_GET_CONNECT_MODAL_DATA:
      return action.data;
    default:
      return state;
  }
};

export const membersSendConnectInvitationContent = (state = "", action) => {
  switch (action.type) {
    case MEMBERS_SEND_EMAIL_CONTENT:
      return action.data;
    default:
      return state;
  }
};

export const initialSendConnectEmailStatus = "IDLE";
export const sendConnectEmailStatus = (
  state = initialSendConnectEmailStatus,
  action
) => {
  switch (action.type) {
    case MEMBERS_BEGIN_SEND_CONNECT_EMAIL:
      return "FETCHING";
    case MEMBERS_END_SEND_CONNECT_EMAIL:
      return "FETCHED";
    default:
      return state;
  }
};
