import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useState } from "react";
import { Icon, IconButton } from "rsuite";
import TransTxt from "@src/components/common/SxFormatMessage";
import { injectIntl } from "react-intl";
import {
  ApplyFiltersBtn,
  NormalDatePicker,
  NormalDestAutocompleteField,
  NormalEntityAutocompleteField,
  NormalInputField,
  NormalRangePicker,
  NormalSelectField,
  NormalSubagentAutocompleteField,
  ResetFiltersBtn,
} from "../forms";

// SCHEMAS
const fieldSchema = yup.object().shape({
  order: yup.number().nullable(),
  id: yup.string().nullable(),
  name: yup.string().nullable(),
  label: yup.string().nullable(),
  type: yup.string().oneOf(["text", "number"]).default("text"),
  comp: yup
    .string()
    .nullable()
    .oneOf([
      "NormalInputField",
      "NormalSelectField",
      "NormalRangePicker",
      "NormalSubagentAutocompleteField",
      "NormalDatePicker",
      "NormalDestAutocompleteField",
      "NormalEntityAutocompleteField",
    ])
    .default("NormalInputField"),
  from_key: yup.string().nullable(),
  to_key: yup.string().nullable(),
  options: yup.array().nullable(),
  advanced: yup.boolean().default(false),
  min: yup.string().nullable(),
  max: yup.string().nullable(),
  data_attributes: yup.array().nullable(),
  // extra_submit_func: yup.function().nullable()
});

const fieldsSchema = yup.array().of(fieldSchema).default([]).max(7);
const advancedFieldsSchema = yup
  .array()
  .oneOf([fieldsSchema, yup.array().of(fieldsSchema)])
  .default([]);

/**
 * This method has the logic that reads the field object
 * and returns the component that is needed.
 * @param {object} field The field object
 * @returns The right component.
 */
const FieldComponent = ({
  field,
  theme = null,
  translationKeyPrefix = null,
  intl,
  modal = false,
}) => {
  const castedProps = fieldSchema.cast(field);

  var CustomField = null;
  switch (castedProps.comp) {
    case "NormalInputField":
      CustomField = NormalInputField;
      break;
    case "NormalDatePicker":
      CustomField = NormalDatePicker;
      break;
    case "NormalSelectField":
      CustomField = NormalSelectField;
      break;
    case "NormalRangePicker":
      CustomField = NormalRangePicker;
      break;
    case "NormalDestAutocompleteField":
      CustomField = NormalDestAutocompleteField;
      break;
    case "NormalEntityAutocompleteField":
      CustomField = NormalEntityAutocompleteField;
      break;
    case "NormalSubagentAutocompleteField":
      CustomField = NormalSubagentAutocompleteField;
      break;
    default:
      CustomField = null;
      break;
  }

  if (!CustomField) return null;

  const flProps = {};
  if (castedProps?.id) flProps.id = castedProps.id ?? castedProps.name;
  if (modal) flProps.id = flProps.id + "__modal";
  if (castedProps?.name) flProps.name = castedProps.name;
  if (castedProps?.from_key) flProps.from_key = castedProps.from_key;
  if (castedProps?.to_key) flProps.to_key = castedProps.to_key;
  if (castedProps?.options) flProps.options = castedProps.options;
  if (castedProps?.type) flProps.type = castedProps.type;
  if (theme) flProps.theme = theme;
  if (castedProps?.min) flProps.min = castedProps.min;
  if (castedProps?.max) flProps.max = castedProps.max;

  if (castedProps?.data_attributes)
    castedProps.data_attributes.forEach((da) => (flProps[da] = "true"));

  var label = castedProps?.label ? castedProps?.label : castedProps.name;
  if (translationKeyPrefix) {
    if (!castedProps?.label) {
      flProps.label = intl.formatMessage({ id: translationKeyPrefix + label });
    } else {
      flProps.label = castedProps.label;
    }
  } else {
    flProps.label = !castedProps?.label ? _.startCase(label) : label;
  }

  var dataTestId = `${translationKeyPrefix}${
    castedProps.name || castedProps.from_key
  }`;

  return (
    <CustomField
      name={castedProps.name}
      label={flProps?.label}
      extraInputProps={{
        "data-testid": dataTestId,
      }}
      {...flProps}
    />
  );
};
FieldComponent.defaultProps = {
  modal: false,
};
FieldComponent.propTypes = {
  field: PropTypes.object.isRequired,
  theme: PropTypes.string,
  translationKeyPrefix: PropTypes.string,
  intl: PropTypes.object.isRequired,
  modal: PropTypes.bool,
};

var AdvancedFiltersModal = ({
  initialFilters,
  fields,
  intl,
  translationKeyPrefix,
  onSubmit,
  onClose,
}) => {
  const advancedFls = advancedFieldsSchema.cast(fields);

  return (
    <div className="Modal AdvancedFiltersModal">
      <Formik
        initialValues={initialFilters}
        onSubmit={(values) => onSubmit({ ...values })}>
        {({ submitForm, resetForm, setFieldValue, values }) => (
          <div className="Modal__card AdvancedFiltersModal__card">
            <div className="Modal__card__header AdvancedFiltersModal__card__header">
              <span>
                <TransTxt id="QuickFiltersDict__advanced_filters" />
              </span>
            </div>
            <div className="Modal__card__body">
              <Form className="AdvancedFiltersModal__card__form Form">
                {advancedFls.map((arrayEntry, rowIdx) => {
                  if (Array.isArray(arrayEntry)) {
                    return (
                      <div
                        className={`desktop-col${arrayEntry.length}`}
                        key={`field_row${rowIdx}`}>
                        {arrayEntry.map((entry, entryIdx) => (
                          <FieldComponent
                            key={entryIdx}
                            {...{
                              field: entry,
                              key: `field_row${rowIdx}_${entryIdx}`,
                              translationKeyPrefix: translationKeyPrefix,
                              intl,
                              modal: true,
                            }}
                          />
                        ))}
                      </div>
                    );
                  }
                  return (
                    <FieldComponent
                      key={rowIdx}
                      {...{
                        field: arrayEntry,
                        key: `field_row${rowIdx}`,
                        translationKeyPrefix: translationKeyPrefix,
                        intl: intl,
                        modal: true,
                      }}
                    />
                  );
                })}
                <div className="desktop-col2"></div>
              </Form>
            </div>
            <div className="Modal__card__actions">
              <button
                data-testid="closeAdvancedFiltersBtn"
                className="Button"
                data-ghost={true}
                onClick={onClose}>
                <TransTxt id="QuickFiltersDict__cancel" />
              </button>
              <button
                className="Button"
                data-ghost={true}
                onClick={(e) => {
                  e.preventDefault();
                  resetForm();
                  // setValues(values);
                  submitForm();
                }}>
                <TransTxt id="QuickFiltersDict__reset_filters" />
              </button>
              <button
                className="Button"
                data-success={true}
                onClick={(e) => {
                  e.preventDefault();
                  // run the extra_submit_func of the fields
                  // Those functions are used mainly for the autocomplete fields.
                  _.flatten(advancedFls).forEach((field) => {
                    if (!field?.extra_submit_func) return;
                    field.extra_submit_func(values, setFieldValue);
                  });
                  submitForm().then(() => onClose());
                }}>
                <TransTxt id="QuickFiltersDict__apply_filters" />
              </button>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};
AdvancedFiltersModal.propTypes = {
  translationKeyPrefix: PropTypes.string,
  initialFilters: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  intl: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
AdvancedFiltersModal = injectIntl(AdvancedFiltersModal);

/**
 * This component is the default filter component for all pages.
 * The filters are separated into default filters, which show up in the list
 * page and the filter modal, and advanced filters, which show up only in the
 * filter modal.
 
 * The only tricky part of the component is the "fields" variable. 
 * The "fields" variable is an array of objects and arrays that are passed to
 * the component.
 * It specifies which fields are used only for the filter modal
 * (advanced: true),
 * how to order the fields on the list page (order: <num>),
 * and how to separate the fields into rows in the filter modal.
 * The data_attributes field adds the data atrributes as "true", that are used
 * from cypress.
 *
 * Each entry in "fields" represents a row. If there is an array of 2 entries
 * within an entry in "fields", then it separates the row into a col-2
 * grid-templates-row
 * 
 * @param {object} initialFilters The initial filters.
 * @param {array} fields An array of objects or arrays.
 * @param {function} onSubmit What to do on Submit.
 * @returns The QuickFilters component.
 */
var QuickFilters = ({
  defaultFilters = null,
  initialFilters,
  fields,
  translationKeyPrefix,
  onReset,
  onSubmit,
  intl,
}) => {
  const [showAdvancedFiltersModal, setShowAdvancedFiltersModal] =
    useState(false);

  const flattenFields = _.flatten(fields);
  const hasAdvancedFields = flattenFields.some((f) => f?.advanced === true);

  const fls = fieldsSchema.cast(
    flattenFields.filter((f) => f?.advanced !== true)
  );

  if (fls.every((f) => f.order)) {
    fls.sort((a, b) => (a.order > b.order ? 1 : -1));
  }

  return (
    <div className="QuickFiltersComponent">
      <Formik initialValues={initialFilters} onSubmit={onSubmit}>
        {({ values, setFieldValue, setValues }) => (
          <React.Fragment>
            <Form
              className={`QuickFiltersComponent__body QuickFiltersComponent__body__col${fls.length}`}>
              {fls.map((fl, idx) => {
                return (
                  <FieldComponent
                    key={idx}
                    {...{
                      field: fl,
                      key: `filter_field_${idx}`,
                      theme: "filter",
                      translationKeyPrefix: translationKeyPrefix,
                      intl: intl,
                    }}
                  />
                );
              })}
              <div
                className={`QuickFiltersComponent__body__actions QuickFiltersComponent__body__actions__${
                  hasAdvancedFields ? "col3" : "col2"
                }`}>
                <ResetFiltersBtn
                  id="resetFiltersBtn"
                  defaultFilters={defaultFilters}
                  onReset={(values, setValues) =>
                    typeof onReset === "function"
                      ? onReset(values, setValues)
                      : null
                  }
                />
                <ApplyFiltersBtn
                  id="applyFiltersBtn"
                  onClick={() => {
                    // run the extra_submit_func of the fields
                    // Those functions are used mainly for the autocomplete fields.
                    fls.forEach((field) => {
                      if (!field.extra_submit_func) return;
                      field.extra_submit_func(values, setFieldValue);
                    });
                  }}
                />
                {hasAdvancedFields && (
                  <IconButton
                    data-testid="advancedFiltersBtn"
                    icon={<Icon icon="filter" />}
                    circle
                    color="blue"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowAdvancedFiltersModal(true);
                    }}
                  />
                )}
              </div>
            </Form>
            {showAdvancedFiltersModal && (
              <AdvancedFiltersModal
                initialFilters={values}
                translationKeyPrefix={translationKeyPrefix}
                fields={fields}
                onSubmit={(vals) => {
                  setValues(vals);
                  onSubmit(vals);
                }}
                onClose={() => setShowAdvancedFiltersModal(false)}
              />
            )}
          </React.Fragment>
        )}
      </Formik>
    </div>
  );
};
QuickFilters.propTypes = {
  defaultFilters: PropTypes.object,
  initialFilters: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  translationKeyPrefix: PropTypes.string,
  onReset: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};
QuickFilters = injectIntl(QuickFilters);
export { QuickFilters };
