import { createSelector } from "reselect";

import { lang_mapping } from "@src/tools/lang_tools";
import _ from "lodash";

const setupForm = (state) => state.tripPlannerItinerarySetupForm;

export const getSetupFormDataSelector = createSelector([setupForm], (form) => {
  var {
    requiredServices,
    adults,
    children,
    title,
    usage,
    budget,
    nationality,
    target_entity,
  } = form;

  if (!requiredServices) requiredServices = [];

  return {
    requiredServices,
    adults,
    children,
    title,
    usage,
    budget,
    nationality,
    target_entity,
  };
});

const getLang = (state) => state.setLang;
const getCountries = (state) => state.countryCodeInfo;

export const getNationalitiesSelector = createSelector(
  [getCountries, getLang],
  (countries, lang) => {
    return _.sortBy(
      countries
        .filter((c) => c.code)
        .map((c) => ({
          label: _.get(c, `name_${lang_mapping[lang]}`) || c.name_en,
          value: c.code,
        })),
      "label"
    );
  }
);

const trpPreferencesLoading = (state) =>
  state.tripPlannerTrpInfraSearchStatus.searching;
export const getPreferencesLoadingSelector = createSelector(
  [trpPreferencesLoading],
  (trpPrefLoading) => {
    return trpPrefLoading;
  }
);
