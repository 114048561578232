import {
  initialIndependentAgentsColumnsSetUp,
  initialIndependentAgentsFilterForm,
  initialIndependentAgentsBaseDataForm,
  initialIndependentAgentsNatureOfBizForm,
} from "@src/forms/Network/IndependentAgents";

import { Schema } from "rsuite";

import {
  INDEPENDENT_AGENTS_BEGIN_FETCH_DATA,
  INDEPENDENT_AGENTS_FETCH_DATA,
  INDEPENDENT_AGENTS_END_FETCH_DATA,
  INDEPENDENT_AGENTS_UPDATE_LIST_FILTER_FORM,
  INDEPENDENT_AGENTS_APPLY_LIST_FILTER,
  INDEPENDENT_AGENTS_INITIALIZE_LIST_FILTER_FORM,
  INDEPENDENT_AGENTS_UPDATE_LIST_COLUMNS_FORM,
  INDEPENDENT_AGENTS_APPLY_LIST_COLUMNS,
  INDEPENDENT_AGENTS_INITIALIZE_LIST_COLUMNS_FORM,
  INDEPENDENT_AGENTS_FETCH_OPTIONS,
  INDEPENDENT_AGENTS_SHOW_BASE_DATA_EDIT_FORM,
  INDEPENDENT_AGENTS_HIDE_BASE_DATA_EDIT_FORM,
  INDEPENDENT_AGENTS_EDIT_BASE_DATA,
  INDEPENDENT_AGENTS_INITIALIZE_BASE_DATA_EDIT_FORM,
  INDEPENDENT_AGENTS_CLEAN_BASE_DATA_EDIT_FORM,
  INDEPENDENT_AGENTS_UPLOADING_BASE_DATA_EDIT_FORM,
  INDEPENDENT_AGENTS_IDLE_BASE_DATA_EDIT_FORM,
  INDEPENDENT_AGENTS_VALIDATE_BASE_DATA_EDIT_FORM,
  INDEPENDENT_AGENTS_CLEAN_BASE_EDIT_FORM_ERRORS,
  INDEPENDENT_AGENTS_PROFILE_DATA,
  INDEPENDENT_AGENTS_VIEW_NATURE_OF_BIZ,
  INDEPENDENT_AGENTS_EDIT_NATURE_OF_BIZ,
  INDEPENDENT_AGENTS_INITIALIZE_NATURE_DATA_FORM,
  INDEPENDENT_AGENTS_EDIT_NATURE_OF_BIZ_DATA,
  INDEPENDENT_AGENTS_SUBMIT_NATURE_OF_BIZ_DATA,
  INDEPENDENT_AGENTS_EDIT_LICENSE_CODE,
  INDEPENDENT_AGENTS_TOGGLE_POSTAL_ADDRESS,
  INDEPENDENT_AGENTS_RESET_LIST_FILTER_FORM,
  INDEPENDENT_AGENTS_FETCHING_OPTIONS,
  INDEPENDENT_AGENTS_FETCHED_OPTIONS,
  //img relation
  INDEPENDENT_AGENTS_UPLOAD_PREFERENCE_IMAGE,
  INDEPENDENT_AGENTS_FETCH_ADDRESS_POINT,
  INDEPENDENT_AGENTS_INITIAL_ADDRESS_POINT,
  INDEPENDENT_AGENTS_TOGGLE_VERIFY_ADDRESS,
  INDEPENDENT_AGENTS_BEGIN_FETCH_ADDRESS_DATA,
  INDEPENDENT_AGENTS_END_FETCH_ADDRESS_DATA,
  INDEPENDENT_AGENTS_BEGIN_FETCH_PROFILE_DATA,
  INDEPENDENT_AGENTS_END_FETCH_PROFILE_DATA,
  INDEPENDENT_AGENTS_CLOSE_VERIFY_ADDRESS,
  INDEPENDENT_AGENTS_CLEAN_ADDRESS_POINT,
  INDEPENDENT_AGENTS_INITIAL_VERIFY_STATUS,
  //==================Email Config ==================
  INDEPENDENT_AGENTS_VIEW_EMAIL_CONFIG_MODE,
  INDEPENDENT_AGENTS_EDIT_EMAIL_CONFIG_MODE,
  INDEPENDENT_AGENTS_LOADING_EMAIL_CONFIG_MODE,
  INDEPENDENT_AGENTS_EDIT_EMAIL_CONFIG_FORM,
  INDEPENDENT_AGENTS_INITIALIZE_EMAIL_CONFIG_FORM,
} from "@src/actions/Network/IndependentAgents/types";

import {
  FORMAT_TRAVEL_SERVICES_OPTIONS,
  FORMAT_TRAVEL_SERVICES_FORM,
} from "@src/actions/Shares/types";

import { formProcessor, entitiesListSelector } from "@src/reducers/tools";

import update from "immutability-helper";
import _ from "lodash";

// independentAgents profile
export const independentAgentsBaseDataForm = (
  state = initialIndependentAgentsBaseDataForm,
  action
) => {
  switch (action.type) {
    case INDEPENDENT_AGENTS_INITIALIZE_BASE_DATA_EDIT_FORM:
      return action.data;
    case INDEPENDENT_AGENTS_CLEAN_BASE_DATA_EDIT_FORM:
      return initialIndependentAgentsBaseDataForm;
    case INDEPENDENT_AGENTS_UPLOAD_PREFERENCE_IMAGE:
      return update(state, {
        [action.data.name]: {
          value: { $set: action.data.value },
          fileName: { $set: action.data.fileName },
        },
      });
    case INDEPENDENT_AGENTS_EDIT_BASE_DATA:
      return update(state, {
        [action.data.name]: { value: { $set: action.data.value } },
      });
    default:
      return state;
  }
};

export const initialIndependentAgentsBaseDataFormErrorsState = { valid: false };
export const independentAgentsBaseDataFormErrors = (
  state = initialIndependentAgentsBaseDataFormErrorsState,
  action
) => {
  const tmp = {};
  Object.entries(initialIndependentAgentsBaseDataForm).forEach((e) => {
    tmp[e[0]] = e[1].validator;
  });
  const validator = Schema.Model(tmp);

  switch (action.type) {
    case INDEPENDENT_AGENTS_VALIDATE_BASE_DATA_EDIT_FORM: {
      const tmp = {};
      Object.entries(action.data).forEach((e) => (tmp[e[0]] = e[1].value));

      const validation = validator.check(tmp);

      const valid = Object.entries(validation).every((e) => !e[1].hasError);
      validation["valid"] = valid;
      return validation;
    }
    case INDEPENDENT_AGENTS_CLEAN_BASE_EDIT_FORM_ERRORS:
      return initialIndependentAgentsBaseDataFormErrorsState;
    default:
      return state;
  }
};

export const initialIndependentAgentsBaseDataUploadStatus = "IDLE";
export const independentAgentsBaseDataUploadStatus = (
  state = initialIndependentAgentsBaseDataUploadStatus,
  action
) => {
  switch (action.type) {
    case INDEPENDENT_AGENTS_UPLOADING_BASE_DATA_EDIT_FORM:
      return "LOADING";
    case INDEPENDENT_AGENTS_IDLE_BASE_DATA_EDIT_FORM:
      return "IDLE";
    default:
      return state;
  }
};

export const independentAgentsProfile = (state = {}, action) => {
  switch (action.type) {
    case INDEPENDENT_AGENTS_PROFILE_DATA:
      return update(state, { $merge: action.data });
    default:
      return state;
  }
};

export const initialIndependentAgentsBaseDataEditFormShow = false;
export const independentAgentsBaseDataEditFormShow = (
  state = initialIndependentAgentsBaseDataEditFormShow,
  action
) => {
  switch (action.type) {
    case INDEPENDENT_AGENTS_SHOW_BASE_DATA_EDIT_FORM:
      return true;
    case INDEPENDENT_AGENTS_HIDE_BASE_DATA_EDIT_FORM:
      return false;
    default:
      return state;
  }
};

export const independentAgentsOptions = (state = {}, action) => {
  switch (action.type) {
    case INDEPENDENT_AGENTS_FETCH_OPTIONS:
      return update(state, { $merge: action.data });
    case FORMAT_TRAVEL_SERVICES_OPTIONS:
      return update(state, { $merge: action.data });
    default:
      return state;
  }
};

export const initialIndependentAgentsProfileFetchStatus = "IDLE";
export const independentAgentsProfileFetchStatus = (
  state = initialIndependentAgentsProfileFetchStatus,
  action
) => {
  switch (action.type) {
    case INDEPENDENT_AGENTS_BEGIN_FETCH_PROFILE_DATA:
      return "FETCHING";
    case INDEPENDENT_AGENTS_END_FETCH_PROFILE_DATA:
      return "FETCHED";
    default:
      return state;
  }
};

export const initialIndependentAgentsOptionsFetchStatus = "IDLE";
export const independentAgentsOptionsFetchStatus = (
  state = initialIndependentAgentsOptionsFetchStatus,
  action
) => {
  switch (action.type) {
    case INDEPENDENT_AGENTS_FETCHING_OPTIONS:
      return "FETCHING";
    case INDEPENDENT_AGENTS_FETCHED_OPTIONS:
      return "FETCHED";
    default:
      return state;
  }
};

export const independentAgentsList = (
  state = initialIndependentAgentsList,
  action
) => {
  switch (action.type) {
    case INDEPENDENT_AGENTS_FETCH_DATA:
      return action.data;
    case INDEPENDENT_AGENTS_RESET_LIST_FILTER_FORM:
      return { ...state, page: 1 };
    default:
      return state;
  }
};

export const initialIndependentAgentsPostalAddressIsSynchronous = false;
export const independentAgentsPostalAddressIsSynchronous = (
  state = initialIndependentAgentsPostalAddressIsSynchronous,
  action
) => {
  switch (action.type) {
    case INDEPENDENT_AGENTS_SHOW_BASE_DATA_EDIT_FORM:
      return action.data;
    case INDEPENDENT_AGENTS_TOGGLE_POSTAL_ADDRESS:
      return action.data;
    default:
      return state;
  }
};

// independentAgents list
export const initialIndependentAgentsList = {
  count: 0,
  next: null,
  previous: null,
  results: [],
  page: 1,
  limit: 20,
  displayLength: 100,
};

export const initialIndependentAgentsListFetchStatus = "IDLE";
export const independentAgentsListFetchStatus = (
  state = initialIndependentAgentsListFetchStatus,
  action
) => {
  switch (action.type) {
    case INDEPENDENT_AGENTS_BEGIN_FETCH_DATA:
      return "FETCHING";
    case INDEPENDENT_AGENTS_END_FETCH_DATA:
      return "FETCHED";
    default:
      return state;
  }
};

// filter
export const independentAgentsListFilter = (
  state = initialIndependentAgentsFilterForm,
  action
) => {
  switch (action.type) {
    case INDEPENDENT_AGENTS_APPLY_LIST_FILTER:
      return action.data;
    case FORMAT_TRAVEL_SERVICES_FORM:
      return update(state, { $merge: action.data });
    case INDEPENDENT_AGENTS_RESET_LIST_FILTER_FORM:
      return action.data;
    default:
      return state;
  }
};

export const independentAgentsListFilterForm = (
  state = initialIndependentAgentsFilterForm,
  action
) => {
  switch (action.type) {
    case INDEPENDENT_AGENTS_INITIALIZE_LIST_FILTER_FORM:
      return action.data;
    case INDEPENDENT_AGENTS_RESET_LIST_FILTER_FORM:
      return action.data;
    case INDEPENDENT_AGENTS_UPDATE_LIST_FILTER_FORM:
      return formProcessor(
        state,
        action.data.type,
        action.data.name,
        action.data.value
      );
    case FORMAT_TRAVEL_SERVICES_FORM:
      return update(state, { $merge: action.data });
    default:
      return state;
  }
};

// columns
export const independentAgentsListColumns = (
  state = entitiesListSelector(initialIndependentAgentsColumnsSetUp),
  action
) => {
  switch (action.type) {
    case INDEPENDENT_AGENTS_APPLY_LIST_COLUMNS:
      return entitiesListSelector(action.data);
    default:
      return state;
  }
};

export const independentAgentsListColumnsForm = (
  state = initialIndependentAgentsColumnsSetUp,
  action
) => {
  switch (action.type) {
    case INDEPENDENT_AGENTS_INITIALIZE_LIST_COLUMNS_FORM: {
      var newState = {};
      const tmp = _.cloneDeep(state);
      for (const key in tmp) {
        // clear form's value
        tmp[key] = update(tmp[key], { value: { $set: [] } });
        newState[key] = update(tmp[key], { value: { $set: [] } });
        // add value by action data
        action.data.forEach((item) => {
          if (_.find(tmp[key].choices, { key: item.key })) {
            newState[key] = update(newState[key], {
              value: { $push: [item.key] },
            });
          }
        });
      }

      return newState;
    }
    case INDEPENDENT_AGENTS_UPDATE_LIST_COLUMNS_FORM:
      return action.data;
    default:
      return state;
  }
};

export const initialIndependentAgentsNatureOfBizMode = "VIEW";
export const independentAgentsNatureOfBizMode = (
  state = initialIndependentAgentsNatureOfBizMode,
  action
) => {
  switch (action.type) {
    case INDEPENDENT_AGENTS_VIEW_NATURE_OF_BIZ:
      return "VIEW";
    case INDEPENDENT_AGENTS_EDIT_NATURE_OF_BIZ:
      return "EDIT";
    case INDEPENDENT_AGENTS_SUBMIT_NATURE_OF_BIZ_DATA:
      return "LOADING";
    default:
      return state;
  }
};

export const independentAgentsNatureOfBizForm = (
  state = initialIndependentAgentsNatureOfBizForm,
  action
) => {
  switch (action.type) {
    case INDEPENDENT_AGENTS_INITIALIZE_NATURE_DATA_FORM:
      return action.data;
    case INDEPENDENT_AGENTS_EDIT_NATURE_OF_BIZ_DATA:
      // action.data.type defines the type of the update operation
      // push: we push an array to the existing data.
      // set: we directly set the data contained in the action.
      // remove: we empty the form array.
      //License type is a two dimensional array so we need to have special handling
      // of this data. [[<licence_type>, <license_code>]]

      if (action.data.type == "push") {
        let value = action.data.value;
        if (action.data.name == "license_type") {
          value = [action.data.value, ""];
        }
        return update(state, {
          [action.data.name]: { value: { $push: [value] } },
        });
      } else if (action.data.type == "remove") {
        let truth = (v) => v !== action.data.value;

        if (action.data.name == "license_type") {
          truth = (v) => v[0] !== action.data.value;
        }

        return update(state, {
          [action.data.name]: {
            value: { $apply: (va) => va.filter((v) => truth(v)) },
          },
        });
      } else {
        let value = action.data.value;
        if (action.data.name == "license_type") {
          value = value.map((v) => [v, ""]);
        }

        return update(state, {
          [action.data.name]: { value: { $set: value } },
        });
      }
    case INDEPENDENT_AGENTS_EDIT_LICENSE_CODE:
      return update(state, {
        license_type: {
          value: {
            $apply: (lt) => {
              const newLt = [];
              lt.forEach((t) => {
                if (t[0] == action.data.name) {
                  newLt.push([t[0], action.data.value]);
                } else {
                  newLt.push(t);
                }
              });
              return newLt;
            },
          },
        },
      });
    default:
      return state;
  }
};

export const independentAgentsAddressPoint = (state = {}, action) => {
  switch (action.type) {
    case INDEPENDENT_AGENTS_FETCH_ADDRESS_POINT:
      return action.data;
    case INDEPENDENT_AGENTS_INITIAL_ADDRESS_POINT:
      return action.data;
    case INDEPENDENT_AGENTS_CLEAN_ADDRESS_POINT:
      return { lat: "", lng: "" };
    default:
      return state;
  }
};

export const initialIsVerifyIndependentAgentsPostalAddress = false;
export const isVerifyIndependentAgentsPostalAddress = (
  state = initialIsVerifyIndependentAgentsPostalAddress,
  action
) => {
  switch (action.type) {
    case INDEPENDENT_AGENTS_TOGGLE_VERIFY_ADDRESS:
      return action.data;
    case INDEPENDENT_AGENTS_CLOSE_VERIFY_ADDRESS:
      return false;
    case INDEPENDENT_AGENTS_INITIAL_VERIFY_STATUS:
      return false;
    default:
      return state;
  }
};

export const initialFetchIndependentAgentsAddressPointStatus = "IDLE";
export const fetchIndependentAgentsAddressPointStatus = (
  state = initialFetchIndependentAgentsAddressPointStatus,
  action
) => {
  switch (action.type) {
    case INDEPENDENT_AGENTS_BEGIN_FETCH_ADDRESS_DATA:
      return "FETCHING";
    case INDEPENDENT_AGENTS_END_FETCH_ADDRESS_DATA:
      return "FETCHED";
    default:
      return state;
  }
};

export const initialIndependentAgentsEmailConfigEditMode = "VIEW";
export const independentAgentsEmailConfigEditMode = (
  state = initialIndependentAgentsEmailConfigEditMode,
  action
) => {
  switch (action.type) {
    case INDEPENDENT_AGENTS_VIEW_EMAIL_CONFIG_MODE:
      return "VIEW";
    case INDEPENDENT_AGENTS_EDIT_EMAIL_CONFIG_MODE:
      return "EDIT";
    case INDEPENDENT_AGENTS_LOADING_EMAIL_CONFIG_MODE:
      return "LOADING";
    default:
      return state;
  }
};

export const initialEmailConfigForm = {
  email_config: {
    value: [],
  },
};

export const independentAgentsEmailConfigForm = (
  state = initialEmailConfigForm,
  action
) => {
  switch (action.type) {
    case INDEPENDENT_AGENTS_INITIALIZE_EMAIL_CONFIG_FORM:
      return action.data;
    case INDEPENDENT_AGENTS_EDIT_EMAIL_CONFIG_FORM: {
      const { name, value } = action.data;
      return update(state, {
        email_config: {
          value: {
            $apply: (config) =>
              config.map((c) => {
                if (c.key == name.key) {
                  return { ...name, value: value };
                } else {
                  return c;
                }
              }),
          },
        },
      });
    }
    default:
      return state;
  }
};

export * from "./ServiceCategories";
