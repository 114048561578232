import {
  // ========================== INIT ACTIONS =========================
  TRIPPLANNER_INIT,
  // ======================= TRIP LOAD ACTIONS =======================
  TRIPPLANNER_LOAD_TRIP,
  // ===================== ACCOMMODATION ITINERARY =====================
  TRIPPLANNER_CLEAR_ACCS,
  TRIPPLANNER_ACCOMMODATION_STORE_POLL_RESULTS,
  TRIPPLANNER_ACCOMMODATION_STORE_BEST_ACC,
  TRIPPLANNER_ACCOMMODATION_SET_CXL,
  // ===================== OVERVIEW ACCOMMODATION ====================
  TRIPPLANNER_OVERVIEW_SELECT_ROOM,
  // =========================== SEARCHING ===========================
  TRIPPLANNER_CLEAR_DESTINATION_ACCS,
  TRIPPLANNER_ACCOMMODATION_SET_CUSTOMER_REMARKS,
} from "@src/actions/Project/TripPlanner/types";

import { selectBestRoom } from "@src/actions/Project/TripPlanner/accommodation_tools";
import _ from "lodash";
import update from "immutability-helper";
import {
  TRIPPLANNER_ADHOC_ACC_SRV_ADD,
  TRIPPLANNER_ADHOC_ACC_SRV_EDIT,
  TRIPPLANNER_ADHOC_ACC_SRV_REMOVE,
  TRIPPLANNER_CUSTOM_ACC_SRV_ADD,
  TRIPPLANNER_CUSTOM_ACC_SRV_REMOVE,
} from "@src/actions/Project/TripPlanner/types/accommodation";
import { TRIPPLANNER_SAVE_SERVICE_BOOKING_METADATA } from "@src/actions/Project/TripPlanner/step_four/service_remarks/types";

export const tripPlannerAccData = (state = {}, action) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return {};
    case TRIPPLANNER_LOAD_TRIP:
      return action.data.accommodation_data ?? {};
    case TRIPPLANNER_CLEAR_ACCS:
      return {};
    case TRIPPLANNER_CLEAR_DESTINATION_ACCS: {
      const { destOrder } = action;
      return { ...state, [destOrder]: [] };
    }
    case TRIPPLANNER_ACCOMMODATION_STORE_POLL_RESULTS: {
      const { destOrder, results, prefs } = action;
      var newState = state;
      if (results.length) {
        newState = update(state, {
          [destOrder.toString()]: { $set: results },
        });
      }

      const bestRoomId = selectBestRoom({
        prefs,
        rooms: newState[destOrder.toString()]?.[0]?.rooms || [],
      });
      const bestRoom = (
        newState?.[destOrder.toString()]?.[0]?.rooms ?? []
      ).find((room) => room.id == bestRoomId);

      (newState?.[destOrder.toString()]?.[0]?.rooms ?? [])
        .filter((room) => {
          return room.id == bestRoom.id;
        })
        .forEach((room) => {
          room["selected"] = true;
        });
      newState[destOrder.toString()][0]["selected"] = true;

      return newState;
    }
    case TRIPPLANNER_ACCOMMODATION_STORE_BEST_ACC: {
      const { destOrder, prefs, disabledId } = action;
      return update(state, {
        [destOrder.toString()]: {
          $apply: (accs) =>
            accs.map((a, idx) =>
              idx == 0
                ? update(a, {
                    rooms: {
                      $apply: (rr) => {
                        const buid = selectBestRoom({
                          prefs,
                          rooms: rr,
                          disabledId,
                        });
                        return rr.map((r) =>
                          update(r, { selected: { $set: r.id == buid } })
                        );
                      },
                    },
                    selected: { $set: true },
                  })
                : a
            ),
        },
      });
    }
    case TRIPPLANNER_OVERVIEW_SELECT_ROOM: {
      const { accommodation } = action;

      const roomsUpdater = (rooms) =>
        rooms.map((r) =>
          update(r, { selected: { $set: r.id == action.roomId } })
        );

      const accUpdater = (acc) => {
        return update(acc, {
          selected: {
            $set: acc.metadata.id === action.accommodation.metadata.id,
          },
          rooms: { $apply: (rr) => roomsUpdater(rr) },
          detailed_rooms: {
            $apply: (rr) => roomsUpdater(accommodation?.detailed_rooms ?? []),
          },
        });
      };

      let newState = update(state, {
        [action.destOrder]: {
          $apply: (accs) =>
            accs.map((a) => accUpdater(a)).filter((a) => a.selected),
        },
      });

      if (
        !newState[action.destOrder]
          .map((a) => a.metadata.id)
          .includes(action.accommodation.metadata.id)
      ) {
        newState = update(newState, {
          [action.destOrder]: { $set: [accUpdater(action.accommodation)] },
        });
      }

      return newState;
    }
    case TRIPPLANNER_ACCOMMODATION_SET_CXL: {
      const { accId, destOrder, booking_code, cxl_policies } = action;
      return update(state, {
        [destOrder]: {
          $apply: (accs) => {
            return accs.map((a) =>
              a.metadata.id == accId
                ? update(a, {
                    rooms: {
                      $apply: (rooms) =>
                        rooms.map((r) =>
                          r.booking_code == booking_code
                            ? update(r, {
                                cancellation_policies: { $set: cxl_policies },
                              })
                            : r
                        ),
                    },
                    detailed_rooms: {
                      $apply: (rooms) =>
                        rooms
                          ? rooms.map((r) =>
                              r.booking_code == booking_code
                                ? update(r, {
                                    cancellation_policies: {
                                      $set: cxl_policies,
                                    },
                                  })
                                : r
                            )
                          : rooms,
                    },
                  })
                : a
            );
          },
        },
      });
    }
    case TRIPPLANNER_ACCOMMODATION_SET_CUSTOMER_REMARKS: {
      const { destOrder, remarks } = action;
      return update(state, {
        [destOrder]: {
          $apply: (accs) =>
            accs.map((acc) => {
              if (acc.selected) {
                acc["customer_remarks"] = remarks;
              }
              return acc;
            }),
        },
      });
    }
    default:
      return state;
  }
};

export const tripPlannerAccAddhocData = (state = [], action) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return [];
    case TRIPPLANNER_LOAD_TRIP: {
      const { data } = action;
      return _.get(data, "acc_addhoc_srv_data", []);
    }
    case TRIPPLANNER_ADHOC_ACC_SRV_ADD: {
      const { srv } = action;
      return [...state, srv];
    }
    case TRIPPLANNER_ADHOC_ACC_SRV_EDIT: {
      const { uid, srv } = action;
      var newState = _.cloneDeep(state);
      newState = newState.filter((srv) => srv.uid !== uid);

      return [...newState, srv];
    }
    case TRIPPLANNER_ADHOC_ACC_SRV_REMOVE: {
      const { srv_uid } = action;
      const newState = _.cloneDeep(state);
      return newState.filter((acc) => acc.uid !== srv_uid);
    }
    case TRIPPLANNER_SAVE_SERVICE_BOOKING_METADATA: {
      const { uid, booking_metadata } = action;

      const newState = _.cloneDeep(state) || [];
      const service = newState.find((srv) => srv.uid === uid);
      if (!service) {
        return state;
      }

      service.booking_metadata = booking_metadata;
      return newState;
    }
    default:
      return state;
  }
};

export const tripPlannerAccCustomData = (state = [], action) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return [];
    case TRIPPLANNER_LOAD_TRIP: {
      const { data } = action;
      return _.get(data, "acc_custom_srv_data", []) || [];
    }
    case TRIPPLANNER_CUSTOM_ACC_SRV_ADD: {
      const { srv } = action;
      return [...state, srv];
    }
    case TRIPPLANNER_CUSTOM_ACC_SRV_REMOVE: {
      const { srv_uid } = action;
      const newState = _.cloneDeep(state);
      return newState.filter((acc) => acc.uid !== srv_uid);
    }
    case TRIPPLANNER_SAVE_SERVICE_BOOKING_METADATA: {
      const { uid, booking_metadata } = action;

      const newState = _.cloneDeep(state) || [];
      const service = newState.find((srv) => srv.uid === uid);
      if (!service) {
        return state;
      }

      service.booking_metadata = booking_metadata;
      return newState;
    }
    default:
      return state;
  }
};
