import { getServicesPricingSelector } from "@src/selectors/Project/TripPlanner/pricing";
import { bankersRounder } from "@src/tools/pricing_tools";
import { useSelector } from "react-redux";

export function useFinalSellingPricing() {
  const {
    sellingAmount,
    sellingAmountPerPerson,
    sellingCurrency,
    totalPriceRounding,
  } = useSelector((state) => {
    const pricing = getServicesPricingSelector(state);
    const itinSetupForm = state.tripPlannerItinerarySetupForm;

    const pax = itinSetupForm?.adults ?? 0 + itinSetupForm?.children ?? 0;

    const {
      balance,
      deposit,
      currency: selCurrency,
      totalPriceRounding,
    } = state?.tripPlannerCxlReducer ?? {};
    var sellingAmount = pricing?.selling?.amount ?? 0;
    var sellingCurrency = pricing.currency;
    if (deposit + balance > 0) {
      sellingAmount = deposit + balance;
      sellingCurrency = selCurrency;
    }

    const sellingAmountPerPerson = bankersRounder(sellingAmount / pax);

    return {
      pricing,
      pax,
      sellingAmount,
      sellingAmountPerPerson,
      sellingCurrency,
      totalPriceRounding,
    };
  });

  return {
    sellingAmount,
    sellingAmountPerPerson,
    sellingCurrency,
    totalPriceRounding,
  };
}
