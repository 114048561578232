import {
  ONE_TO_ONE_FETCH_DATA,
  ONE_TO_ONE_BEGIN_FETCH_DATA,
  ONE_TO_ONE_END_FETCH_DATA,
  ONE_TO_ONE_INITIALIZE_LIST_FILTER_FORM,
  ONE_TO_ONE_UPDATE_LIST_FILTER_FORM,
  ONE_TO_ONE_APPLY_LIST_FILTER,
  ONE_TO_ONE_UPDATE_LIST_COLUMNS_FORM,
  ONE_TO_ONE_APPLY_LIST_COLUMNS,
  ONE_TO_ONE_INITIALIZE_LIST_COLUMNS_FORM,
  ONE_TO_ONE_FETCH_OPTIONS,
  ONE_TO_ONE_CLEAN_BASE_DATA_EDIT_FORM,
  ONE_TO_ONE_EDIT_BASE_DATA,
  ONE_TO_ONE_VALIDATE_BASE_DATA_EDIT_FORM,
  ONE_TO_ONE_RESET_LIST_FILTER_FORM,
  //===============================EDIT=======================
  ONE_TO_ONE_FETCH_EDIT_DATA,
  ONE_TO_ONE_INIT_POLICY_BASE_DATA_EDIT_FORM,
  //==========================MODAL===========================
  ONE_TO_ONE_LOADING_POLICY_FORM_MODAL,
  ONE_TO_ONE_IDLE_POLICY_FORM_MODAL,
  ONE_TO_ONE_SHOW_POLICY_FORM_MODAL,
  ONE_TO_ONE_HIDE_POLICY_FORM_MODAL,
  ONE_TO_ONE_INIT_POLICY_FORM_MODAL,
  ONE_TO_ONE_POLICY_EDIT_CHANGE,
} from "@src/actions/Admin/OneToOne/types";

import {
  FORMAT_BRANCH_LIST_DATA,
  FORMAT_SUB_AGENT_LIST_DATA,
  FORMAT_INDEPENDENT_AGENT_LIST_DATA,
} from "@src/actions/Admin/GroupPolicy/types";

import { Schema } from "rsuite";

import {
  FORMAT_TRAVEL_SERVICES_OPTIONS,
  FORMAT_TRAVEL_SERVICES_FORM,
} from "@src/actions/Shares/types";

import {
  initialOneToOneBaseDataForm,
  initialOneToOnePolicyColumnsSetUp,
  initialOneToOneFilterForm,
} from "@src/forms/Admin/OneToOne";

import update from "immutability-helper";
import _ from "lodash";
import { formProcessor, entitiesListSelector } from "@src/reducers/tools";

export const initialStatus = "IDLE";
export const oneToOnePolicyFetchStatus = (state = initialStatus, action) => {
  switch (action.type) {
    case ONE_TO_ONE_BEGIN_FETCH_DATA:
      return "FETCHING";
    case ONE_TO_ONE_END_FETCH_DATA:
      return "FETCHED";
    default:
      return state;
  }
};

export const initialList = {
  count: 0,
  next: null,
  previous: null,
  results: [],
  page: 1,
  limit: 20,
  displayLength: 100,
};

export const oneToOnePolicyList = (state = initialList, action) => {
  switch (action.type) {
    case ONE_TO_ONE_FETCH_DATA:
      return action.data;
    case ONE_TO_ONE_RESET_LIST_FILTER_FORM:
      return { ...state, page: 1 };
    default:
      return state;
  }
};

// columns
export const oneToOneListColumns = (
  state = entitiesListSelector(initialOneToOnePolicyColumnsSetUp),
  action
) => {
  switch (action.type) {
    case ONE_TO_ONE_APPLY_LIST_COLUMNS:
      return entitiesListSelector(action.data);
    default:
      return state;
  }
};

export const oneToOneListColumnsForm = (
  state = initialOneToOnePolicyColumnsSetUp,
  action
) => {
  switch (action.type) {
    case ONE_TO_ONE_INITIALIZE_LIST_COLUMNS_FORM: {
      var newState = {};
      const tmp = _.cloneDeep(state);
      for (const key in tmp) {
        // clear form's value
        tmp[key] = update(tmp[key], { value: { $set: [] } });
        newState[key] = update(tmp[key], { value: { $set: [] } });
        // add value by action data
        action.data.forEach((item) => {
          if (_.find(tmp[key].choices, { key: item.key })) {
            newState[key] = update(newState[key], {
              value: { $push: [item.key] },
            });
          }
        });
      }
      return newState;
    }
    case ONE_TO_ONE_UPDATE_LIST_COLUMNS_FORM:
      return action.data;
    default:
      return state;
  }
};

// filter
export const oneToOneListFilter = (
  state = initialOneToOneFilterForm,
  action
) => {
  switch (action.type) {
    case ONE_TO_ONE_APPLY_LIST_FILTER:
      return action.data;
    case FORMAT_TRAVEL_SERVICES_FORM:
      return update(state, { $merge: action.data });
    default:
      return state;
  }
};

export const oneToOneListFilterForm = (
  state = initialOneToOneFilterForm,
  action
) => {
  switch (action.type) {
    case ONE_TO_ONE_INITIALIZE_LIST_FILTER_FORM:
      return action.data;
    case ONE_TO_ONE_RESET_LIST_FILTER_FORM:
      return action.data;
    case ONE_TO_ONE_UPDATE_LIST_FILTER_FORM:
      return formProcessor(
        state,
        action.data.type,
        action.data.name,
        action.data.value
      );
    case FORMAT_TRAVEL_SERVICES_FORM:
      return update(state, { $merge: action.data });
    case FORMAT_TRAVEL_SERVICES_OPTIONS:
      return update(state, { $merge: action.data });
    case ONE_TO_ONE_FETCH_OPTIONS:
      return update(state, { $merge: action.data.travel_entities });
    default:
      return state;
  }
};

//add list
export const oneToOneBaseDataForm = (
  state = initialOneToOneBaseDataForm,
  action
) => {
  switch (action.type) {
    case ONE_TO_ONE_INIT_POLICY_BASE_DATA_EDIT_FORM:
      return action.data;
    case ONE_TO_ONE_CLEAN_BASE_DATA_EDIT_FORM:
      return _.merge(initialOneToOneBaseDataForm, action.data);
    case ONE_TO_ONE_EDIT_BASE_DATA:
      return formProcessor(
        _.merge(state, action.data),
        action.data.type,
        action.data.name,
        action.data.value
      );
    case ONE_TO_ONE_POLICY_EDIT_CHANGE: {
      const { key, value } = action;
      return update(state, { [key]: { $set: value } });
    }
    case ONE_TO_ONE_FETCH_OPTIONS:
      return update(state, { $merge: action.data.travel_entities });
    case FORMAT_TRAVEL_SERVICES_FORM:
      return update(state, { $merge: action.data });
    case FORMAT_TRAVEL_SERVICES_OPTIONS:
      return update(state, { $merge: action.data });
    case FORMAT_BRANCH_LIST_DATA:
      return _.cloneDeep({ ...state, ...action.data });
    case FORMAT_SUB_AGENT_LIST_DATA:
      return _.cloneDeep({ ...state, ...action.data });
    case FORMAT_INDEPENDENT_AGENT_LIST_DATA:
      return _.cloneDeep({ ...state, ...action.data });
    default:
      return state;
  }
};

export const oneToOnePolicyOptions = (state = {}, action) => {
  switch (action.type) {
    case ONE_TO_ONE_FETCH_OPTIONS:
      return update(state, { $merge: action.data });
    default:
      return state;
  }
};

// validate
export const initialOneToOnePolicyBaseDataFormErrorsState = { valid: false };
export const oneToOnePolicyBaseDataFormErrors = (
  state = initialOneToOnePolicyBaseDataFormErrorsState,
  action
) => {
  const tmp = {};
  Object.entries(initialOneToOneBaseDataForm).forEach((e) => {
    tmp[e[0]] = e[1].validator;
  });
  const validator = Schema.Model(tmp);

  switch (action.type) {
    case ONE_TO_ONE_VALIDATE_BASE_DATA_EDIT_FORM: {
      const tmp = {};
      Object.entries(action.data).forEach((e) => {
        tmp[e[0]] = e[1].value;
      });
      const validation = validator.check(tmp);

      const valid = Object.entries(validation).every((e) => !e[1].hasError);
      validation["valid"] = valid;
      console.log(validation);
      return validation;
    }
    default:
      return state;
  }
};

export const oneToOneEditData = (state = {}, action) => {
  switch (action.type) {
    case ONE_TO_ONE_FETCH_EDIT_DATA:
      return action.data;
    default:
      return state;
  }
};

export const initialOneToOnePolicyEditFormStatus = {
  loading: false,
  show: false,
  mode: "",
  data: [],
  form: {
    name: "",
    pax_to: 10,
    pax_from: 1,
    booking_window_from: "",
    booking_window_to: "",
    service_execution_from: "",
    service_execution_to: "",
    status: "CA",
    travel_services_policies: [],
    description: "",
    rounding_digits: "1",
    entities: [],
  },
};
export const oneToOnePolicyFormModal = (
  state = initialOneToOnePolicyEditFormStatus,
  action
) => {
  switch (action.type) {
    case ONE_TO_ONE_INIT_POLICY_FORM_MODAL:
      return { ...state, data: action.data };
    case ONE_TO_ONE_LOADING_POLICY_FORM_MODAL:
      return { ...state, loading: true };
    case ONE_TO_ONE_IDLE_POLICY_FORM_MODAL:
      return { ...state, loading: false };
    case ONE_TO_ONE_HIDE_POLICY_FORM_MODAL:
      return initialOneToOnePolicyEditFormStatus;
    case ONE_TO_ONE_SHOW_POLICY_FORM_MODAL:
      return { ...state, show: true, mode: action.data.mode };
    case FORMAT_BRANCH_LIST_DATA: {
      return update(state, {
        data: {
          $set: action.data.data,
        },
      });
    }
    case FORMAT_SUB_AGENT_LIST_DATA:
      return update(state, {
        data: {
          $set: action.data.data,
        },
      });
    case FORMAT_INDEPENDENT_AGENT_LIST_DATA:
      return update(state, {
        data: {
          $set: action.data.data,
        },
      });
    default:
      return state;
  }
};
