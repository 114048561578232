import {
  TRIPPLANNER_HIDE_TRIP_PREFERENCES,
  TRIPPLANNER_VIEW_TRIP_PREFERENCES,
  TRIPPLANNER_SETUP_CHANGE,
  TRIPPLANNER_UPDATE_PREFERENCES,
} from "./types";

// ============================= ACTIONS =============================
import { saveTrip } from "./save_actions";
import {
  setQRModalUrl,
  loadingQRModal,
  showQRModal,
  hideQRModal,
  idleQRModal,
} from "@src/actions/QRModal";
import { changeViewStyle } from ".";

export const viewTripPreferences = () => {
  return { type: TRIPPLANNER_VIEW_TRIP_PREFERENCES };
};

export const hideTripPreferences = () => {
  return { type: TRIPPLANNER_HIDE_TRIP_PREFERENCES };
};

export const changeTripPreferences = (name, value) => (dispatch) => {
  if (name == "requiredServices" && !value.includes("MI")) {
    dispatch(changeViewStyle("overview"));
  }

  dispatch(changePreferences(name, value));
};

export const changePreferences = (name, value) => {
  return {
    type: TRIPPLANNER_SETUP_CHANGE,
    name,
    value,
  };
};

export const updateTripPreferences = ({ data }) => {
  return {
    type: TRIPPLANNER_UPDATE_PREFERENCES,
    data,
  };
};

export const exportWithQr = () => async (dispatch, getState) => {
  await dispatch(loadingQRModal());
  await dispatch(showQRModal());

  const save_result = await dispatch(saveTrip({ nonUi: true }));
  var trip_instance = null;

  if (!save_result) {
    dispatch(hideQRModal());
    return;
  } else {
    trip_instance = getState().tripPlannerInstanceData;
  }
  await dispatch(
    setQRModalUrl(
      `http:testwxapp.suixingtrips.com:8888/?tpid=${trip_instance.id}`
    )
  );
  dispatch(idleQRModal());
};
