const initialMenu = [
  {
    icon: "ri:stack-fill",
    name: "project",
    path: "/project",
    allowed_groups: ["member", "subagent", "project"],
    allowed_user_types: ["member", "subagent"],
    required_groups: ["no_pending_payments"],
    user_required_groups: ["project"],
    subMenu: [
      {
        icon: "icon-tripplanningicon",
        name: "trip_plan",
        path: "/trip",
        redirect: true,
        allowed_groups: ["member", "subagent", "trip_planning"],
        allowed_user_types: ["member", "subagent"],
        required_groups: ["no_pending_payments"],
        subMenu: [
          {
            name: "new_trip",
            path: "/new-trip",
            parentPath: "/project/trip",
            component: "TripPlanner",
            required_groups: ["no_pending_payments"],
          },
          {
            name: "new_quotation_request",
            path: "/new-quotation-request",
            parentPath: "/project/trip",
            component: "QuotationRequest",
            required_groups: ["no_pending_payments"],
            allowed_groups: ["quotations"],
            allowed_user_types: ["member", "subagent"],
          },
        ],
      },
      {
        icon: "icon-AddOns_Function",
        name: "static packages",
        path: "/custom-services",
        redirect: true,
        allowed_user_types: ["member"],
        required_groups: ["no_pending_payments"],
        subMenu: [
          {
            name: "new static package",
            path: "/new-addon",
            component: "AddOn",
            parentPath: "/project/custom-services",
            required_groups: ["no_pending_payments"],
            allowed_user_types: ["member"],
          },
        ],
      },
      {
        icon: "icon-AddOns_Function",
        name: "experiences",
        path: "/custom-services",
        redirect: true,
        allowed_user_types: ["member"],
        required_groups: ["no_pending_payments"],
        subMenu: [
          {
            name: "new_experience",
            path: "/new-addon",
            component: "AddOn",
            parentPath: "/project/custom-services",
            required_groups: ["no_pending_payments"],
          },
        ],
      },
      {
        icon: "icon-tripplanningicon",
        name: "search_and_book",
        path: "",
        redirect: true,
        allowed_groups: ["member", "subagent"],
        allowed_user_types: ["member", "subagent"],
        required_groups: ["no_pending_payments"],
        subMenu: [
          {
            name: "Hotels",
            link: "https://snb.easytravel.tech",
            required_groups: ["no_pending_payments"],
          },
          {
            name: "Transfers",
            link: "https://snb.easytravel.tech",
            required_groups: ["no_pending_payments"],
          },
          {
            name: "Activities",
            link: "https://snb.easytravel.tech",
            required_groups: ["no_pending_payments"],
          },
        ],
      },
      {
        icon: "icon-icon_Myofferswweixuanze",
        name: "my_offers",
        path: "/my-offers",
        redirect: true,
        required_groups: ["no_pending_payments"],
        subMenu: [
          {
            name: "unsent",
            path: "/unsent",
            parentPath: "/project/my-offers",
            component: "MyOffersInProgress",
          },
          {
            name: "sent",
            path: "/sent",
            parentPath: "/project/my-offers",
            component: "MyOffersSent",
          },
          {
            name: "accepted",
            path: "/accepted",
            parentPath: "/project/my-offers",
            component: "MyOffersAccepted",
          },
          {
            name: "booked",
            path: "/booked",
            parentPath: "/project/my-offers",
            component: "MyOffersBooked",
          },
          {
            name: "rejected",
            path: "/rejected",
            parentPath: "/project/my-offers",
            component: "MyOffersRejected",
          },
        ],
      },
      {
        icon: "icon-icon_Myofferswweixuanze",
        name: "my_quotations",
        path: "/my-quotations",
        redirect: true,
        required_groups: ["no_pending_payments"],
        allowed_user_types: ["member", "subagent"],
        // allowed_groups: ["quotations"],
        subMenu: [
          {
            name: "unsent",
            path: "/unsent",
            parentPath: "/project/my-quotations",
            component: "MyQuotationsUnsent",
          },
          {
            name: "sent",
            path: "/sent",
            parentPath: "/project/my-quotations",
            component: "MyQuotationsSent",
          },
          {
            name: "rejected",
            path: "/rejected",
            parentPath: "/project/quotation-requests",
            component: "RejectedQuotations",
          },
        ],
      },
      {
        icon: "icon-icon_Myofferswweixuanze",
        name: "quotation_requests",
        path: "/quotation-requests",
        redirect: true,
        required_groups: ["no_pending_payments"],
        allowed_groups: ["quotations"],
        subMenu: [
          {
            name: "unassigned",
            path: "/unassigned",
            parentPath: "/project/quotation-requests",
            component: "UnassignedQuotations",
          },
          {
            name: "assigned",
            path: "/assigned",
            parentPath: "/project/quotation-requests",
            component: "AssignedQuotations",
          },
          {
            name: "rejected",
            path: "/rejected",
            parentPath: "/project/quotation-requests",
            component: "RejectedQuotations",
          },
        ],
      },
    ],
  },
  {
    icon: "material-symbols:person",
    name: "admin",
    path: "/admin",
    allowed_user_types: ["member", "subagent"],
    user_required_groups: ["admin"],
    subMenu: [
      {
        icon: "icon-organisation1",
        name: "company",
        path: "/company",
        redirect: true,

        subMenu: [
          {
            name: "company_profile",
            path: "/profile",
            parentPath: "/admin/company",
            component: "CompanyProfile",
          },
          {
            name: "user_management",
            path: "/users",
            parentPath: "/admin/company",
            component: "Users",
            allowed_groups: ["member", "subagent"],
            allowed_user_types: ["member", "subagent"],
            user_required_groups: ["admin"],
          },
        ],
      },
      {
        icon: "icon-ControlPanel",
        name: "control_panel",
        path: "/control-panel",
        redirect: true,
        allowed_groups: [],
        allowed_user_types: ["member"],
        user_required_groups: ["admin"],

        subMenu: [
          {
            name: "communications",
            path: "/communications",
            parentPath: "/admin/control-panel",
            component: "Communications",
          },
          {
            name: "product_terms_and_conditions",
            path: "/product_terms_and_conditions",
            parentPath: "/admin/control-panel",
            component: "ProductTermsAndConditions",
          },
          {
            name: "booking_remarks",
            path: "/booking_remarks",
            parentPath: "/admin/control-panel",
            component: "BookingRemarks",
          },
          {
            name: "currency_setup",
            path: "/currency_setup",
            parentPath: "/admin/control-panel",
            component: "CurrencySetup",
            allowed_groups: ["member"],
            allowed_user_types: ["member"],
          },
          {
            name: "invoice_setup",
            path: "/invoice_setup",
            parentPath: "/admin/control-panel",
            component: "InvoiceSetupList",
            allowed_groups: ["member"],
            allowed_user_types: ["member"],
          },
          {
            name: "company_stamps",
            path: "/company_stamps",
            parentPath: "/admin/control-panel",
            component: "CompanyStampsList",
            allowed_groups: ["member"],
            allowed_user_types: ["member"],
          },
        ],
      },
      {
        icon: "material-symbols:finance",
        name: "financial",
        path: "/financial",
        redirect: true,
        allowed_groups: ["member", "subagent"],
        allowed_user_types: ["member"],
        required_groups: ["no_pending_payments"],
        user_required_groups: ["admin"],
        subMenu: [
          {
            name: "invoices",
            path: "/invoices",
            parentPath: "/admin/financial",
            component: "InvoiceList",
            required_groups: ["no_pending_payments"],
          },
        ],
      },
      {
        icon: "icon-marketpolicyicon",
        name: "market_policy",
        path: "/market",
        redirect: true,
        allowed_groups: ["member", "subagent"],
        allowed_user_types: ["member"],
        required_groups: ["no_pending_payments"],
        user_required_groups: ["admin"],
        subMenu: [
          {
            name: "b2b geo policies",
            path: "/geopolicy",
            parentPath: "/admin/market",
            component: "GeoPolicy",
          },
          {
            name: "b2b group policies",
            path: "/group",
            parentPath: "/admin/market",
            component: "GroupPolicy",
          },
          {
            name: "b2b one_to_one policies",
            path: "/onetoone",
            parentPath: "/admin/market",
            component: "OneToOne",
          },
          {
            name: "B2C Policies",
            path: "/b2c-policy",
            allowed_groups: ["has_b2c"],
            parentPath: "/admin/market",
            component: "B2CPolicy",
          },
        ],
      },
    ],
  },
  {
    icon: "zondicons:network",
    name: "network",
    path: "/network",
    allowed_groups: ["member", "subagent", "branch", "network"],
    allowed_user_types: ["member"],
    required_groups: ["no_pending_payments"],
    user_required_groups: ["network"],
    subMenu: [
      {
        icon: "icon-SalesPartner",
        name: "sales_partner",
        path: "/sales",
        redirect: true,
        parentPath: "/network",
        required_groups: ["no_pending_payments"],
        subMenu: [
          {
            name: "subagents",
            path: "/subagents",
            parentPath: "/network/sales",
            component: "SubAgents",
            allowed_groups: ["member", "branch"],
            allowed_user_types: ["member", "branch"],
          },
        ],
      },
      {
        icon: "icon-supplypartner",
        name: "supply_partners",
        path: "/supply",
        parentPath: "/network",
        redirect: true,
        allowed_groups: ["member", "supply_chain_scm"],
        allowed_user_types: ["member"],
        required_groups: ["no_pending_payments"],
        subMenu: [
          {
            name: "providers",
            path: "/providers",
            parentPath: "/network/supply",
            component: "Providers",
          },
        ],
      },
      {
        icon: "icon-Vendors1",
        name: "vendors",
        path: "/vendors",
        parentPath: "/network/",
        redirect: true,
        allowed_groups: ["member"],
        allowed_user_types: ["member"],
        required_groups: ["no_pending_payments"],
        subMenu: [
          {
            name: "accommodation",
            path: "/accommodation",
            parentPath: "/network/vendors",
            component: "AccommodationVendors",
            allowed_user_types: ["member"],
          },
          {
            name: "activity",
            path: "/activity",
            parentPath: "/network/vendors",
            component: "ActivityRentalVendors",
            allowed_user_types: ["member"],
          },
          {
            name: "car_rental",
            path: "/car-rentals",
            parentPath: "/network/vendors",
            component: "CarRentalVendors",
            allowed_user_types: ["member"],
          },
          {
            name: "ferry",
            path: "/ferry",
            parentPath: "/network/vendors",
            component: "FerryVendors",
            allowed_user_types: ["member"],
          },
          {
            name: "flight",
            path: "/flight",
            parentPath: "/network/vendors",
            component: "TransportationFlightVendors",
            allowed_user_types: ["member"],
          },
          {
            name: "train",
            path: "/train",
            parentPath: "/network/vendors",
            component: "TrainRentalVendors",
            allowed_user_types: ["member"],
          },
          {
            name: "transfer",
            path: "/transfer",
            parentPath: "/network/vendors",
            component: "TransferVendors",
            allowed_user_types: ["member"],
          },
        ],
      },
      {
        icon: "icon-system_net",
        name: "network_members",
        path: "/network_members",
        parentPath: "/network",
        redirect: true,
        allowed_groups: ["member"],
        allowed_user_types: ["member"],
        required_groups: ["no_pending_payments"],
        subMenu: [
          {
            name: "members",
            path: "/members",
            parentPath: "/network/network_members",
            component: "Members",
          },
          {
            name: "system_net__my_connections",
            path: "/my-connections",
            parentPath: "/network/network_members",
            component: "MyConnections",
          },
          {
            name: "sent invitations",
            path: "/invitations-sent",
            parentPath: "/network/network_members",
            component: "InvitationsSend",
          },
          {
            name: "received invitations",
            path: "/invitations-received",
            parentPath: "/network/network_members",
            component: "Invitations",
          },
        ],
      },
      {
        icon: "icon-system_net",
        name: "B2C_distribution_channels",
        path: "/b2c-distribution-channels",
        parentPath: "/network",
        allowed_groups: ["has_b2c"],
        required_groups: ["no_pending_payments"],
        subMenu: [],
      },
    ],
  },
  {
    icon: "solar:widget-bold",
    name: "operation",
    path: "/operation",
    allowed_groups: [
      "member",
      "subagent",
      "operation_system_os",
      "sales_team",
      "operation",
    ],
    allowed_user_types: ["member", "subagent"],
    required_groups: ["no_pending_payments"],
    subMenu: [
      {
        icon: "icon-reservation",
        name: "b2b_reservations",
        path: "/B2B-reservations",
        redirect: true,
        required_groups: ["no_pending_payments", "operation"],
        subMenu: [
          {
            name: "multiservices",
            path: "/multi-services",
            parentPath: "/operation/B2B-reservations",
            component: "MultiServices",
            required_groups: ["no_pending_payments"],
          },
          {
            name: "accommodations",
            path: "/accommodations",
            parentPath: "/operation/B2B-reservations",
            component: "AccommodationB2BReservationsList",
            required_groups: ["no_pending_payments"],
          },
          {
            name: "adhoc_accommodations",
            path: "/adhoc-accommodations",
            parentPath: "/operation/B2B-reservations",
            component: "B2BAdhocAccReservationsLists",
            required_groups: ["no_pending_payments"],
          },
          {
            name: "contracted_accommodations",
            path: "/contracted-accommodations",
            parentPath: "/operation/B2B-reservations",
            component: "B2BContractedAccReservationsLists",
            required_groups: ["no_pending_payments"],
          },
          {
            name: "activities",
            path: "/activities",
            parentPath: "/operation/B2B-reservations",
            component: "B2BActivitiesReservationsLists",
            required_groups: ["no_pending_payments"],
          },
          {
            name: "experiences",
            path: "/addons",
            parentPath: "/operation/B2B-reservations",
            component: "B2BAddonReservationsLists",
            required_groups: ["no_pending_payments"],
          },
          {
            name: "car_rentals",
            path: "/car-rentals",
            parentPath: "/operation/B2B-reservations",
            component: "CarRentalB2BList",
            required_groups: ["no_pending_payments"],
          },
          {
            name: "coaches",
            path: "/coaches",
            parentPath: "/operation/B2B-reservations",
            component: "B2BCoachReservationsLists",
            required_groups: ["no_pending_payments", "custom_services"],
          },
          {
            name: "coordinators",
            path: "/coordinators",
            parentPath: "/operation/B2B-reservations",
            component: "SSCoordinators",
            required_groups: ["no_pending_payments"],
          },
          {
            name: "ferries",
            path: "/ferries",
            parentPath: "/operation/B2B-reservations",
            component: "SSFerries",
            required_groups: ["no_pending_payments", "custom_services"],
          },
          {
            name: "api_ferries",
            path: "/api-ferries",
            parentPath: "/operation/B2B-reservations",
            component: "APIFerries",
            user_required_groups: [],
            required_groups: ["no_pending_payments", "custom_services"],
          },
          {
            name: "flights",
            path: "/flights",
            parentPath: "/operation/B2B-reservations",
            component: "Flights",
            required_groups: ["no_pending_payments"],
          },
          {
            name: "general_services",
            path: "/general-services",
            parentPath: "/operation/B2B-reservations",
            component: "SSGeneralServices",
            required_groups: ["no_pending_payments", "custom_services"],
          },
          {
            name: "restaurants",
            path: "/restaurants",
            parentPath: "/operation/B2B-reservations",
            component: "SSRestaurants",
            required_groups: ["no_pending_payments", "custom_services"],
          },
          {
            name: "trains",
            path: "/trains",
            parentPath: "/operation/B2B-reservations",
            component: "SSTrains",
            required_groups: ["no_pending_payments", "custom_services"],
          },
          {
            name: "api_trains",
            path: "/api-trains",
            parentPath: "/operation/B2B-reservations",
            component: "APITrains",
            user_required_groups: [],
            required_groups: ["no_pending_payments", "custom_services"],
          },
          {
            name: "transfers",
            path: "/transfer",
            parentPath: "/operation/B2B-reservations",
            component: "Transfer",
            required_groups: ["no_pending_payments"],
          },
        ],
      },
      {
        icon: "icon-reservation",
        name: "b2c_reservations",
        path: "/B2C-reservations",
        redirect: true,
        required_groups: ["no_pending_payments", "operation", "has_b2c"],
        allowed_user_types: ["member", "subagent"],
        subMenu: [
          {
            name: "accommodation",
            path: "/accommodation",
            parentPath: "/operation/B2C-reservations",
            component: "AccommodationB2CReservationsList",
            required_groups: ["no_pending_payments"],
            allowed_user_types: ["member", "subagent"],
          },
          {
            name: "activities",
            path: "/activities",
            parentPath: "/operation/B2C-reservations",
            component: "B2CActivitiesReservationsLists",
            required_groups: ["no_pending_payments"],
            allowed_user_types: ["member", "subagent"],
          },
          {
            name: "experiences",
            path: "/addons",
            parentPath: "/operation/B2C-reservations",
            component: "AddonReservationsLists",
            required_groups: ["no_pending_payments"],
            allowed_user_types: ["member", "subagent"],
          },
          {
            name: "api_ferries",
            path: "/api-ferries",
            parentPath: "/operation/B2C-reservations",
            component: "APIFerriesB2C",
            required_groups: ["no_pending_payments", "custom_services"],
            allowed_user_types: ["member", "subagent"],
          },
          {
            name: "api_trains",
            path: "/api-trains",
            parentPath: "/operation/B2C-reservations",
            component: "APITrainsB2C",
            required_groups: ["no_pending_payments", "custom_services"],
            allowed_user_types: ["member", "subagent"],
          },
          {
            name: "car_rentals",
            path: "/car-rentals",
            parentPath: "/operation/B2C-reservations",
            component: "CarRentalB2CList",
            required_groups: ["no_pending_payments"],
            allowed_user_types: ["member", "subagent"],
          },
          {
            name: "flights",
            path: "/flights",
            parentPath: "/operation/B2C-reservations",
            component: "FlightsB2CReservationsList",
            required_groups: ["no_pending_payments"],
          },
          {
            name: "transfers",
            path: "/transfer",
            parentPath: "/operation/B2C-reservations",
            component: "B2CTransfer",
            required_groups: ["no_pending_payments"],
            allowed_user_types: ["member", "subagent"],
          },
        ],
      },
      {
        icon: "icon-reservation",
        name: "guests",
        path: "/guests",
        allowed_groups: ["sales_team"],
        redirect: true,
        required_groups: ["no_pending_payments", "has_b2c"],
        allowed_user_types: ["member", "subagent"],
        subMenu: [
          {
            name: "b2c_guests",
            path: "/b2c",
            parentPath: "/operation/guests",
            component: "B2CGuestsList",
            required_groups: ["no_pending_payments", "has_b2c"],
          },
        ],
      },
    ],
  },
  {
    icon: "solar:library-bold",
    name: "my_library",
    path: "/my-library",
    allowed_groups: ["member"],
    allowed_user_types: ["member"],
    user_required_groups: ["my_library"],
    required_groups: ["no_pending_payments"],
    subMenu: [
      {
        icon: "icon-Businessserviceicon",
        name: "packages",
        path: "/packages",
        redirect: true,
        allowed_groups: ["has_b2c"],
        required_groups: ["no_pending_payments"],
        subMenu: [
          {
            name: "dynamic",
            path: "/dynamic-packages",
            parentPath: "/my-library/packages",
            component: "Wholesales",
            required_groups: ["no_pending_payments"],
          },
          {
            name: "static",
            path: "/static-packages",
            component: "MyLibraryAddons",
            parentPath: "/my-library/packages",
            required_groups: ["no_pending_payments"],
          },
        ],
      },
      {
        icon: "icon-icon_MyTripwweixuanze",
        name: "my_trips",
        path: "/my-trips",
        redirect: true,
        required_groups: ["no_pending_payments"],
        subMenu: [
          {
            name: "templates",
            path: "/templates",
            parentPath: "/my-library/my-trips",
            component: "TripTemplatesList",
            required_groups: ["no_pending_payments"],
          },
        ],
      },
      {
        icon: "icon-services",
        name: "services",
        path: "/services",
        redirect: true,
        component: "MyLibraryAddons",
        required_groups: ["no_pending_payments"],
        subMenu: [
          {
            name: "experiences",
            path: "/experiences",
            component: "MyLibraryAddons",
            parentPath: "/my-library/services",
            required_groups: ["no_pending_payments"],
          },
          {
            name: "hotel-contracts",
            path: "/hotel-contracts",
            component: "HotelContractsList",
            parentPath: "/my-library/services",
            required_groups: ["no_pending_payments"],
            user_required_groups: [],
          },
          {
            name: "restaurant-contracts",
            path: "/restaurant-contracts",
            component: "RestaurantContractsList",
            parentPath: "/my-library/services",
            required_groups: ["no_pending_payments"],
            allowed_groups: ["general_services"],
          },
          {
            name: "coordinator-contracts",
            path: "/coordinator-contracts",
            component: "CoordinatorContractsList",
            parentPath: "/my-library/services",
            required_groups: ["no_pending_payments"],
            allowed_groups: ["general_services"],
          },
          {
            name: "general-services",
            path: "/general-services",
            component: "GeneralServices",
            parentPath: "/my-library/services",
            required_groups: ["no_pending_payments"],
            allowed_groups: ["general_services"],
          },
        ],
      },
      {
        icon: "icon-laster-post",
        name: "image_bank",
        path: "/image-bank",
        parentPath: "/my-library",
        allowed_groups: ["my_library"],
        required_groups: ["no_pending_payments"],
        subMenu: [],
      },
    ],
  },
  {
    icon: "mingcute:settings-6-fill",
    name: "management_console",
    path: "/management-console",
    allowed_groups: ["member", "subagent"],
    allowed_user_types: ["member"],
    user_required_groups: ["management_console"],
    subMenu: [
      {
        icon: "icon-subscription",
        name: "subscription",
        path: "/subscription",
        redirect: true,
        allowed_groups: ["member"],
        allowed_user_types: ["member"],
        user_required_groups: ["admin"],
        subMenu: [
          {
            name: "manage",
            path: "/manage",
            component: "Manage",
            parentPath: "/management-console/subscription",
          },
        ],
      },
      {
        icon: "icon-bill",
        name: "billing",
        path: "/billing",
        redirect: true,
        allowed_groups: ["member"],
        allowed_user_types: ["member"],
        user_required_groups: ["admin"],
        subMenu: [
          {
            name: "payment_methods",
            path: "/payment-methods",
            parentPath: "/management-console/billing",
            component: "PaymentMethods",
          },
          {
            name: "bill & payments",
            path: "/cost-bill",
            parentPath: "/management-console/billing",
            component: "BillAndPayments",
          },
        ],
      },
    ],
  },
];

const IDROUTES = [
  {
    name: "tripplan",
    path: "/project/trip/tripplan/:id?/:mode?",
    id_route: true,
    component: "TripPlanner",
    required_groups: ["no_pending_payments"],
  },
  {
    name: "quotation_request",
    path: "/project/trip/quotation-request/:id",
    id_route: true,
    component: "QuotationRequest",
    required_groups: ["no_pending_payments"],
  },
  {
    name: "addon",
    path: "/my-library/custom-services/addon/:id",
    id_route: true,
    component: "AddOn",
    required_groups: ["no_pending_payments"],
  },
  {
    name: "new-addon",
    path: "/my-library/custom-services/new-addon/",
    id_route: true,
    component: "AddOn",
    required_groups: ["no_pending_payments"],
  },
];

export { initialMenu, IDROUTES };
