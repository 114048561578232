import { Loader } from "rsuite";

import { convertAmountSelector } from "@src/selectors/Financial";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";
import { TripModeHoc } from "../Hydrators";
import { createUseStyles } from "react-jss";
import { variables } from "@src/jsssetup";

const drawerStyles = createUseStyles({
  PriceDetailsDrawer: {
    display: "grid",
    gridTemplateColumns: "1fr max-content",
    gridGap: variables.normal_gap,
    alignItems: "center",
    borderRadius: `calc(${variables.normal_gap} / 2)`,
    fontWeight: "500",
  },
  price: {
    color: variables.colors.text.dark,
  },
  priceTxt: (props) => ({
    fontSize: "medium",
    borderRadius: `calc(${variables.normal_gap} / 2)`,
    color:
      props.priceType === "selling"
        ? variables.colors.text.dark
        : `${variables.colors.text.light} !important`,
    border:
      props.priceType === "selling"
        ? `2px solid ${variables.colors.lightGreen}`
        : `2px solid ${variables.colors.easy.orange}`,
    background:
      props.priceType === "selling"
        ? variables.colors.lightGreen
        : variables.colors.easy.orange,
    padding: [
      `calc(${variables.normal_gap} / 4)`,
      `calc(${variables.normal_gap} / 2)`,
    ],
  }),
});
export const PriceDetailsDrawer = ({
  convertedCurrency,
  convertedAmount,
  searching,
  price,
  priceVisible,
  priceType,
  digits,
  onDetails,
}) => {
  const classes = drawerStyles({ priceType });

  const isSelling = priceType === "selling";

  return price !== 0 && !price ? null : (
    <div className={classes.PriceDetailsDrawer}>
      {searching ? (
        <Loader size="md" />
      ) : (
        <React.Fragment>
          {priceVisible && (
            <strong className={classes.price}>
              {convertedCurrency
                ? convertedAmount.toLocaleString("en-US", {
                    style: "currency",
                    currency: convertedCurrency,
                  })
                : convertedAmount.toFixed(digits)}
            </strong>
          )}
          {typeof onDetails == "function" ? (
            <a
              className={classes.priceTxt}
              href=""
              onClick={(e) => {
                e.preventDefault();
                onDetails();
              }}>
              Details
            </a>
          ) : (
            isSelling && <span className={classes.priceTxt}>Selling Price</span>
          )}
        </React.Fragment>
      )}
    </div>
  );
};
PriceDetailsDrawer.defaultProps = {
  priceType: "buying",
  price: 0,
  convertedAmount: 0,
  priceVisible: true,
  searching: false,
  digits: 2,
};
PriceDetailsDrawer.propTypes = {
  convertedAmount: PropTypes.number,
  priceType: PropTypes.oneOf(["buying", "selling", "percentage"]),
  price: PropTypes.number,
  priceVisible: PropTypes.bool,
  searching: PropTypes.bool,
  onDetails: PropTypes.func,
  digits: PropTypes.number,
};
const mapStateToProps = (state, ownProps) => {
  const {
    convertedAmount,
    currSymbol,
    currency: convertedCurrency,
  } = convertAmountSelector(state, {
    amount: ownProps.price,
    currentCurrency: ownProps.currency,
  });

  return { convertedAmount, currSymbol, convertedCurrency };
};
export default connect(mapStateToProps, null)(TripModeHoc(PriceDetailsDrawer));
