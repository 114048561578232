import { notifyCommonSuccess } from "@src/components/common/notifications/CommonSuccessNotification.js";

import { errorHandle as commonErrorHandle } from "@src/tools/common_tools";
import { timeStampInjector } from "@src/tools/date_tools";
import {
  getTravelServices,
  getTravelServicesOptions,
  getGuestOptions,
  changeEmployeesStatus,
  getCountryCodeInfo,
  getCountriesList,
  getUserGraphData,
  getCompanyGraphData,
  getGraphMapViewData,
  getReservationEmailById,
} from "@src/api";

import { getRelatedEmployeesDataFactory } from "@src/actions/ActionFactory";

import { getCurrenciesInfo } from "@src/api/Financial";

import { timeSkipper } from "@src/actions/tools";

import { lang_mapping } from "@src/tools/lang_tools";
import {
  SHOW_GLOBAL_FILTER_MODAL,
  HIDE_GLOBAL_FILTER_MODAL,
  FETCHED_TRAVEL_SERVICES,
  FETCHED_TRAVEL_SERVICE_OPTIONS,
  FORMAT_TRAVEL_SERVICES_OPTIONS,
  FORMAT_TRAVEL_SERVICES_FORM,
  FETCHED_GUEST_OPTIONS,
  SET_RESERVATION_EMAIL_LIST_DATA,
  BEGIN_FETCH_ENTITY,
  END_FETCH_ENTITY,
  BEGIN_CHANGE_ENTITY_STATUS,
  END_CHANGE_ENTITY_STATUS,
  SHOW_GLOBAL_CHART_ROOMS,
  HIDE_GLOBAL_CHART_ROOMS,
  SAVE_COUNTRY_CODE_INFO,
  FORMAT_COUNTRY_CODE_LIST,
  SHARES_FETCHED_COUNTRY_LIST,
  FETCH_USER_GRAPH_DATA,
  FETCH_COMPANY_GRAPH_DATA,
  SHOW_GRAPH_BOX_MAP_VIEW_MODAL,
  HIDE_GRAPH_BOX_MAP_VIEW_MODAL,
  FETCH_GRAPH_MAP_VIEW_DATA,
  HIDE_REPLACEABLE_MODAL,
  SHOW_REPLACEABLE_MODAL,
  FINANCIAL_CURRENCIES_LIST,
  //=================================GET RELATED EMPLOYEES========================
  GET_RELATED_EMPLOYEES_DATA,
  //===================================graph===================================
  GRAPH_DASHBOARDS_TOGGLE_MODE,
  GRAPH_DASHBOARDS_INIT_MODE,
  DOWNLOAD_PDF_MODAL_SHOW,
  DOWNLOAD_PDF_MODAL_HIDE,
  DOWNLOAD_EXCEL_MODAL_SHOW,
  DOWNLOAD_EXCEL_MODAL_HIDE,
} from "./types";

// ========================== Filter Actions =========================

export const hideGlobalModal = () => {
  return { type: HIDE_GLOBAL_FILTER_MODAL };
};

export const showGlobalModal = (data) => {
  return { type: SHOW_GLOBAL_FILTER_MODAL, data: data };
};

//=========================== graph box map view =================================

export const showGraphBoxMapViewModel = (data) => {
  return { type: SHOW_GRAPH_BOX_MAP_VIEW_MODAL, data: data };
};

export const hideGraphBoxMapViewModel = () => {
  return { type: HIDE_GRAPH_BOX_MAP_VIEW_MODAL };
};

//==========================replaceable modal ======================================

export const showReplaceableModal = (data) => {
  return { type: SHOW_REPLACEABLE_MODAL, data: data };
};
export const hideReplaceableModal = () => {
  return { type: HIDE_REPLACEABLE_MODAL };
};

// ======================= Service Info Actions ======================

export const fetchTravelSrv = () => (dispatch, getState) => {
  const trvServices = getState().travelServices;

  const call = timeSkipper(
    () =>
      getTravelServices().then((data) =>
        dispatch({
          type: FETCHED_TRAVEL_SERVICES,
          data: { ...{ data: data }, ...{ metadata: timeStampInjector({}) } },
        })
      ),
    trvServices,
    3600
  );

  call();
};

export const fetchTravelSrvOptions = (langCode) => async (
  dispatch,
  getState
) => {
  const srvOptions = getState().travelServiceOptions;
  const lang = getState().setLang;

  if (srvOptions.langCode == (langCode || lang)) {
    return;
  }

  const data = await getTravelServicesOptions(lang);
  dispatch({
    type: FETCHED_TRAVEL_SERVICE_OPTIONS,
    data: {
      ...data.actions.POST,
      langCode: langCode || lang,
    },
  });
};

export const formatTravelServices = () => (dispatch, getState) => {
  const state = getState();
  const options = {};
  const travelServiceForm = {};
  const travelServices = state.travelServices;
  const travelServiceOptions = state.travelServiceOptions;
  travelServiceOptions.service_type.choices.forEach((item) => {
    travelServiceForm[item.value] = {
      verbose: item.display_name,
      value: [],
    };
    const type = item.value;
    const arr = [];
    travelServices.data.results.forEach((item) => {
      // filter categories that is null
      if (item.service_type == type && item.service_code) {
        const obj = {
          value: item.id,
          service_code: item.service_code,
          display_name:
            item[`brief_description_${lang_mapping[state.setLang]}`],
          label: item[`brief_description_${lang_mapping[state.setLang]}`],
        };
        arr.push(obj);
      }
    });
    options[item.value] = {
      choices: arr,
    };
  });

  dispatch({
    type: FORMAT_TRAVEL_SERVICES_FORM,
    data: travelServiceForm,
  });

  dispatch({
    type: FORMAT_TRAVEL_SERVICES_OPTIONS,
    data: options,
  });
};

export const fetchReservationEmailList = (reservation_id, srv_type) => async (
  dispatch
) => {
  const data = await getReservationEmailById(reservation_id, srv_type);

  dispatch({ type: SET_RESERVATION_EMAIL_LIST_DATA, data: data.results });
  return data;
};

export const fetchGuestOptions = () => (dispatch, getState) => {
  const guestOptions = getState().guestOptions;

  const call = timeSkipper(
    () => {
      getGuestOptions().then((data) => {
        dispatch({
          type: FETCHED_GUEST_OPTIONS,
          data: {
            ...data.actions.POST,
            ...{ metadata: timeStampInjector({}) },
          },
        });
      });
    },
    guestOptions,
    3600
  );

  call();
};

export const beginFetchEntity = () => {
  return { type: BEGIN_FETCH_ENTITY };
};

export const endFetchEntity = () => {
  return { type: END_FETCH_ENTITY };
};

export const changeEntityStatus = (data, TYPE) => (dispatch) => {
  dispatch({ type: BEGIN_CHANGE_ENTITY_STATUS });
  changeEmployeesStatus(data)
    .then((res) => {
      notifyCommonSuccess(res.message);
      dispatch({ type: END_CHANGE_ENTITY_STATUS });
      dispatch({ type: TYPE, data: { status: data.status } });
    })
    .catch((error) => {
      dispatch({ type: END_CHANGE_ENTITY_STATUS });
      error.ovrdErrHandle && error.ovrdErrHandle();
      commonErrorHandle({ error });
    });
};

export const showGlobalChatRooms = () => {
  return { type: SHOW_GLOBAL_CHART_ROOMS };
};

export const hideGlobalChartRooms = () => {
  return { type: HIDE_GLOBAL_CHART_ROOMS };
};

//========================COUNTRY CODE INFO===========================
export const fetchCountriesList = () => (dispatch, getState) => {
  const countryList = getState().countryList;
  const call = timeSkipper(
    () => {
      getCountriesList().then((data) => {
        dispatch({
          type: SHARES_FETCHED_COUNTRY_LIST,
          data: data.results,
        });
      });
    },
    countryList,
    3600
  );
  call();
};

export const getCountryCodeListInfo = () => (dispatch, getState) => {
  const countryCodeInfo = getState().countryCodeInfo;
  const call = timeSkipper(
    () => {
      getCountryCodeInfo()
        .then((data) => {
          dispatch({
            type: SAVE_COUNTRY_CODE_INFO,
            data: data,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    countryCodeInfo,
    3600
  );
  call();
};

export const formatCountryCodeInfo = () => (dispatch, getState) => {
  const state = getState();
  const country_code_list = state.countryCodeInfo;
  const country_code = [];
  country_code_list.forEach((item) => {
    var name = item[`name_${lang_mapping[state.setLang]}`];

    if (!name) {
      name = item["name_en"];
    }

    country_code.push({
      value: `${item.code}-${name}`,
      label: name,
      code: item.code,
    });
  });

  dispatch({
    type: FORMAT_COUNTRY_CODE_LIST,
    data: country_code,
  });
};

//=======================================dashBoard==========================================
export const fetchUserGraphData = (params) => (dispatch) => {
  getUserGraphData({ ...params }).then((res) => {
    dispatch({
      type: FETCH_USER_GRAPH_DATA,
      data: res,
    });
  });
};

export const fetchCompanyGraphData = (params) => (dispatch) => {
  getCompanyGraphData({ ...params }).then((res) => {
    dispatch({
      type: FETCH_COMPANY_GRAPH_DATA,
      data: res,
    });
  });
};

export const fetchGraphMapViewData = (params) => (dispatch) => {
  getGraphMapViewData({ ...params }).then((res) => {
    dispatch({
      type: FETCH_GRAPH_MAP_VIEW_DATA,
      data: res,
    });
  });
};

// ==========================Financial============================================

export const fetchFinancialCurrenciesList = () => async (dispatch) => {
  try {
    const data = await getCurrenciesInfo();
    dispatch({
      type: FINANCIAL_CURRENCIES_LIST,
      data: data.results,
    });
  } catch (error) {
    error.ovrdErrHandle && error.ovrdErrHandle();
    commonErrorHandle({ error });
  }
};

// ============================getRelatedEmployees==============================
export const getRelatedEmployeesData = (word) => (dispatch) => {
  dispatch(getRelatedEmployeesDataFactory(word, GET_RELATED_EMPLOYEES_DATA));
};

//==================================Graph============================
export const toggleGraphDashboardsMode = (status) => {
  return {
    type: GRAPH_DASHBOARDS_TOGGLE_MODE,
    data: status,
  };
};

export const initGraphDashboardsMode = () => {
  return {
    type: GRAPH_DASHBOARDS_INIT_MODE,
  };
};

//======================DOWNLOAD PDF MODAL==================
export const showDownloadPdfModal = (form) => {
  return {
    type: DOWNLOAD_PDF_MODAL_SHOW,
    form,
  };
};

export const hideDownloadPdfModal = () => {
  return {
    type: DOWNLOAD_PDF_MODAL_HIDE,
  };
};

//====================DOWNLOAD EXCEL MODAL==================
export const showDownloadExcelModal = () => {
  return {
    type: DOWNLOAD_EXCEL_MODAL_SHOW,
  };
};

export const hideDownloadExcelModal = () => {
  return {
    type: DOWNLOAD_EXCEL_MODAL_HIDE,
  };
};
