import _ from "lodash";
import { createSelector } from "reselect";
import {
  getCurrencies,
  getGlobalCurrencySelector,
} from "@src/selectors/Financial";
import { unConvert } from "@src/tools/pricing_tools";
import { getSetupFormDataSelector } from "../trip_setup_selectors";
import { getServicesListSelector } from "../generic_service_selectors";
import { getAccAPIPrice } from "../accommodation_selectors";
import { requiredRoomsNumberSelector } from "../room_selectors";
import { getSelectedActivityAttrs } from "@src/pages/Project/TripPlan/Components/Modals/products/activities/details/ActivityPriceModal";

function getServicesPricing(state) {
  return state?.tripPlannerDetailedSrvPricing?.pricing ?? {};
}
function getServicePricing(state, props) {
  return (
    state.tripPlannerDetailedSrvPricing?.pricing?.[props.key] ?? {
      markup: 0,
    }
  );
}

export const getServicePricingSelector = createSelector(
  [getServicePricing],
  (service_pricing) => service_pricing
);

function getAccMultiTypePrice(acc, requiredRoomsNumber, payable) {
  var price = 0;
  var currency = "EUR";
  switch (acc.accommodation_service_type) {
    case "ACC": {
      const priceData = getAccAPIPrice({
        accommodation: acc,
        rooms_requested: requiredRoomsNumber,
        payable,
      });
      price = priceData.price;
      currency = priceData.currency;
      break;
    }
    case "ADD": {
      currency = _.get(acc, "currency", "EUR");
      if (payable) {
        price = 0;
      } else {
        price = acc?.total_price ?? 0;
      }
      break;
    }
    case "CU": {
      currency = acc.rooms[0].price.currency;
      if (payable) {
        price = 0;
      } else {
        price = _.get(acc, "rooms", []).reduce(
          (ra, rb) => ra + rb.price.amount,
          0
        );
      }
      break;
    }
    default:
      break;
  }

  return { price, currency };
}

function getTrpPrice({ srv, currenciesExc, globalCurrency, payable }) {
  if (srv.transportation_service_type === "FL") {
    if (payable && (srv?.is_own ?? false)) return 0;
    const { total_price: price, currency } = srv?.price ?? {};
    const payload = { currency, price, currenciesExc, globalCurrency };
    return converterToGlobal(payload).amount;
  } else if (srv.transportation_service_type === "ATRA") {
    const selectedOffer = (srv?.offers ?? []).find((o) => o.selected);
    const price = selectedOffer?.amount?.value ?? 0;
    const currency = selectedOffer?.amount?.currency ?? "EUR";
    const payload = { currency, price, currenciesExc, globalCurrency };
    return converterToGlobal(payload).amount;
  } else if (srv.transportation_service_type === "AFER") {
    if (payable && (srv?.is_own ?? false)) return 0;
    const price = srv?.price?.value ?? 0;
    const currency = srv?.price?.currency ?? "EUR";
    const payload = { currency, price, currenciesExc, globalCurrency };
    return converterToGlobal(payload).amount;
  } else {
    if (payable) return 0;
    const { total_price: price, currency } = srv?.service ?? {};
    const payload = { currency, price, currenciesExc, globalCurrency };
    return converterToGlobal(payload).amount;
  }
}

function getTransferPrice({ srv, currenciesExc, globalCurrency, payable }) {
  if (payable && _.get(srv, "is_own", false)) {
    return 0;
  }

  const { value: price, currency } = _.get(srv, "price", {});
  const payload = { price, currency, currenciesExc, globalCurrency };

  return converterToGlobal(payload).amount;
}

function getAccPrice({
  srv,
  currenciesExc,
  globalCurrency,
  requiredRoomsNumber,
  payable = false,
}) {
  const { price, currency } = getAccMultiTypePrice(
    srv,
    requiredRoomsNumber,
    payable
  );
  const payload = { price, currency, currenciesExc, globalCurrency };
  return converterToGlobal(payload).amount;
}

function getAddonPrice({ srv, currenciesExc, globalCurrency }) {
  const { amount: price, currency } = srv;
  const payload = { price, currency, currenciesExc, globalCurrency };
  return converterToGlobal(payload).amount;
}

function getRestPrice({ srv, currenciesExc, globalCurrency, payable }) {
  if (payable) {
    return 0;
  }

  const pricing = _.get(srv, "menu.0.pricing", {});
  const { currency, total_price: price } = pricing;
  const payload = { currency, price, currenciesExc, globalCurrency };
  return converterToGlobal(payload).amount;
}

function getCooPrice({ srv, currenciesExc, globalCurrency, payable }) {
  if (payable) {
    return 0;
  }

  const { currency, daily_cost } = srv;
  const price = daily_cost.reduce((a, b) => a + b.amount, 0);

  const payload = { currency, price, currenciesExc, globalCurrency };
  return converterToGlobal(payload).amount;
}

function getGenPrice({ srv, currenciesExc, globalCurrency, payable }) {
  if (payable) {
    return 0;
  }

  const price_data = _.get(srv, "service_data.price_data", {});
  const { total_price: price, currency } = price_data;
  const payload = { price, currency, currenciesExc, globalCurrency };
  return converterToGlobal(payload).amount;
}

function getActPrice({ srv, currenciesExc, globalCurrency, payable }) {
  if (payable && srv.free_cancellation) {
    return 0;
  }

  const { totalPrice, currency } = getSelectedActivityAttrs({
    selectionInfo: srv.selectionInfo,
    bookingData: srv.bookingData,
  });

  const payload = {
    price: totalPrice,
    currency,
    currenciesExc,
    globalCurrency,
  };

  return converterToGlobal(payload).amount;
}

function getAdPrice({ srv, currenciesExc, globalCurrency, payable }) {
  if (payable) {
    return 0;
  }

  const payload = {
    price: srv.amount,
    currency: srv.currency,
    currenciesExc,
    globalCurrency,
  };

  return converterToGlobal(payload).amount;
}

const pricerFn = {
  TR: getTrpPrice,
  TRF: getTransferPrice,
  ACC: getAccPrice,
  AD: getAddonPrice,
  REST: getRestPrice,
  COO: getCooPrice,
  GEN: getGenPrice,
  ACT: getActPrice,
  AD: getAdPrice,
};

function markupper(price, markup) {
  return price * (1 + markup / 100);
}

function converterToGlobal({ currency, price, currenciesExc, globalCurrency }) {
  const currencyData = currenciesExc.find((cr) => cr.notation === currency);
  if (!currencyData) {
    return { amount: 0, currency: "EUR" };
  }

  const { rate } = currencyData;
  var amount = unConvert(price, globalCurrency.notation, currency, rate);

  return { amount, currency: globalCurrency.notation };
}

/**
 * Creates a specific key per service type so that the markup can be easily
 * accessed from the tripPlannerDetailedSrvPricing reducer.
 */
export function srvPriceKeyCreator({ service, service_type }) {
  var key = "";
  switch (service_type) {
    case "TR": {
      const uid = service?.uid ?? service?.id;
      key = `${service?.transportation_service_type}___${uid}`;
      break;
    }
    case "TRF": {
      key = `TRF___${service?.booking_id}`;
      break;
    }
    case "ACC": {
      key = `ACC___${service?.accommodation_service_type}___${service?.uid}`;
      break;
    }
    case "REST": {
      key = `REST___${service?.external_id}`;
      break;
    }
    case "COO": {
      key = `COO___${service?.uid}`;
      break;
    }
    case "GEN": {
      key = `GEN___${_.get(service, "service_data.id")}`;
      break;
    }
    case "ACT": {
      key = `ACT___${service?.uid}`;
      break;
    }
    case "AD": {
      key = `AD___${service?.id}`;
      break;
    }
    default: {
      break;
    }
  }
  return key;
}

export const getServicesPricingSelector = createSelector(
  [
    getServicesListSelector,
    getServicesPricing,
    getCurrencies,
    getGlobalCurrencySelector,
    getSetupFormDataSelector,
    requiredRoomsNumberSelector,
    (__, props = { payable: false, caller: null }) => props,
  ],
  (
    services,
    pricing,
    currenciesExc,
    globalCurrency,
    tripSetup,
    requiredRoomsNumber,
    props
  ) => {
    const { payable } = props;

    var buyingAmount = services.reduce((a, srv) => {
      const fn = pricerFn?.[srv.service_type];

      if (!fn) return a + 0;

      return (
        a +
        parseFloat(
          fn({
            srv,
            currenciesExc,
            globalCurrency,
            requiredRoomsNumber,
            payable,
          })
        )
      );
    }, 0);

    var sellingAmount = services.reduce((a, srv) => {
      const fn = _.get(pricerFn, srv.service_type);
      if (!fn) return a + 0;
      const price = fn({
        srv,
        currenciesExc,
        globalCurrency,
        requiredRoomsNumber,
        payable,
      });

      var markup = 0;

      switch (srv.service_type) {
        case "TR": {
          markup = _.get(
            pricing,
            `${srvPriceKeyCreator({
              service: srv?.service ? srv.service : srv,
              service_type: "TR",
            })}.markup`,
            0
          );
          break;
        }
        case "TRF": {
          markup =
            pricing?.[srvPriceKeyCreator({ service: srv, service_type: "TRF" })]
              ?.markup ?? 0;
          break;
        }
        case "ACC": {
          markup = _.get(
            pricing,
            `${srvPriceKeyCreator({
              service: srv,
              service_type: "ACC",
            })}.markup`,
            0
          );
          break;
        }
        case "REST": {
          markup = _.get(
            pricing,
            `${srvPriceKeyCreator({
              service: srv,
              service_type: "REST",
            })}.markup`,
            0
          );
          break;
        }
        case "COO": {
          markup = _.get(
            pricing,
            `${srvPriceKeyCreator({
              service: srv,
              service_type: "COO",
            })}.markup`,
            0
          );
          break;
        }
        case "GEN": {
          markup = _.get(
            pricing,
            `${srvPriceKeyCreator({
              service: srv,
              service_type: "GEN",
            })}.markup`,
            0
          );
          break;
        }
        case "ACT": {
          markup = _.get(
            pricing,
            `${srvPriceKeyCreator({
              service: srv,
              service_type: "ACT",
            })}.markup`,
            0
          );
          break;
        }
        case "AD": {
          const key = srvPriceKeyCreator({ service: srv, service_type: "AD" });
          markup = pricing?.[key]?.markup ?? 0;
          break;
        }
        default: {
          break;
        }
      }

      return a + markupper(parseFloat(price), parseFloat(markup));
    }, 0);

    buyingAmount = parseFloat(buyingAmount.toFixed(2));
    sellingAmount = parseFloat(sellingAmount.toFixed(2));

    const { adults, children } = tripSetup;
    const pax = adults + children;
    const avgBuyingAmount = parseFloat((buyingAmount / pax).toFixed(2));
    const avgSellingAmount = parseFloat((sellingAmount / pax).toFixed(2));

    return {
      buying: { amount: buyingAmount },
      selling: { amount: sellingAmount },
      avgPerPersonBuying: { amount: avgBuyingAmount },
      avgPerPersonSelling: { amount: avgSellingAmount },
      currency: globalCurrency.notation,
      symbol: globalCurrency.symbol,
    };
  }
);
