import _ from "lodash";
import { NormalInputField, NormalSelectField } from "@src/components/forms";
import { variables } from "@src/jsssetup";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { createUseStyles } from "react-jss";
import { SectionHeader, sectionCommonStyles } from "./components";
import { useFormikContext } from "formik";
import { InlineIcon } from "@iconify/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchInvoiceSetup } from "@src/pages/Admin/ControlPanel/InvoiceSetup/list";
import { useSelector } from "react-redux";
import { getUserSourceEntitySelector } from "@src/selectors/Shared/user_selectors";
import { Loader } from "rsuite";
import { fetchCompanyStamps } from "@src/pages/Admin/ControlPanel/CompanyStamps/list";

const inpoutGroupStyles = createUseStyles(() => ({
  indexNumber: {
    fontWeight: "500",
    fontSize: "medium",
  },
}));
const InputGroup = ({ fieldIdx }) => {
  const classes = inpoutGroupStyles({});

  return (
    <React.Fragment>
      <span className={classes.indexNumber}>{fieldIdx + 1}.</span>
      <NormalInputField
        label=""
        name={`customFields.${fieldIdx}.label`}
        highlightErrors={true}
      />
      <NormalInputField
        label=""
        name={`customFields.${fieldIdx}.value`}
        highlightErrors={true}
      />
    </React.Fragment>
  );
};
InputGroup.defaultProps = {
  fieldIdx: 0,
};
InputGroup.propTypes = {
  fieldIdx: PropTypes.number.isRequired,
};

const optionsStyles = createUseStyles({
  Options: {
    ...sectionCommonStyles,
    display: "grid",
    gridGap: variables.half_gap,
  },
  items: {
    display: "grid",
    gridGap: variables.normal_gap,
  },
  optionLabel: {
    display: "grid",
    gridTemplateColumns: "repeat(2,max-content)",
    gridGap: variables.half_gap,
    fontSize: "medium",
    fontWeight: "bold",
  },
  memoFooterOption: {
    display: "grid",
    gridTemplateColumns: "repeat(2,max-content)",
    gridGap: variables.half_gap,
  },
  option: {
    display: "grid",
    gridGap: variables.half_gap,
  },
  inputGroup: {
    display: "grid",
    gridTemplateColumns: "repeat(4,max-content)",
    gridGap: variables.normal_gap,
    alignItems: "center",
  },
  deleteBtn: {
    fontSize: "large",
    color: variables.colors.text.colored,
    cursor: "pointer",
  },
  paymentOption: {
    fontSize: "medium",
    fontWeight: "400",
  },
  edit: {
    fontSize: "small",
    color: variables.colors.text.colored,
    cursor: "pointer",
  },
  actionBtn: {
    fontSize: "large",
    color: variables.colors.text.colored,
    cursor: "pointer",
    alignSelf: "center",
  },
  memoField: (props) => ({
    display: "grid",
    gridTemplateColumns:
      props.memoEdit || props.memoValue ? "23rem  max-content" : "max-content",
    gridGap: variables.normal_gap,
    gridColumn: "span 2",
  }),
  footerField: (props) => ({
    display: "grid",
    gridTemplateColumns:
      props.footerEdit || props.footerValue
        ? "23rem  max-content"
        : "max-content",
    gridGap: variables.normal_gap,
    gridColumn: "span 2",
  }),
  footerMemoCntr: {
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
  },
  axisCntr: (props) => ({
    display: "grid",
    gridTemplateColumns: "repeat(2,1fr)",
    gridGap: variables.normal_gap,
  }),
  [`@media ${variables.media.smallscreen}`]: {},
  [`@media ${variables.media.bigscreen}`]: {},
});
export const Options = ({
  data,
  memoEdit,
  footerEdit,
  setMemoEdit,
  setFooterEdit,
}) => {
  const { values, setFieldValue } = useFormikContext();
  const memoValue = values.memo;
  const footerValue = values.footer;
  const customFields = values.customFields;
  const classes = optionsStyles({
    memoEdit,
    footerEdit,
    memoValue,
    footerValue,
  });

  const addCustomFieldHandle = () => {
    const newField = { label: "", value: "" };
    setFieldValue("customFields", [...customFields, newField]);
  };

  function onRemoveCustomField(fieldIdx) {
    const updatedFields = [...customFields];
    updatedFields.splice(fieldIdx, 1);
    setFieldValue("customFields", updatedFields);
  }

  const paymentOptions = [
    ["flywire", "Flywire", "Credit/Debit Cards"],
    ["airwallex", "Airwallex", "Alipay, Wechat Pay"],
  ];

  const source_entity = useSelector((state) =>
    getUserSourceEntitySelector(state)
  );

  const queryClient = useQueryClient();

  var invoiceSetup = queryClient.getQueryData(["invoiceInvoiceSetup"]);
  invoiceSetup = invoiceSetup?.data?.results?.[0];

  const invoiceOfferData = queryClient.getQueryState([
    "invoiceOfferData",
    values.product_reference,
  ]);
  const addonData = queryClient.getQueryData([
    "invoiceAddonData",
    values.product_reference,
  ]);

  // Initialize values in case of product type is offer
  useEffect(() => {
    if (values.product_type !== "offer") return;
    if (!invoiceSetup) return;
    if (!invoiceOfferData?.data?.external_reference) return;

    const product_ref_label =
      invoiceSetup?.product_reference_label || "Product Ref.";
    const external_ref_label =
      invoiceSetup?.external_reference_label || "External Ref.";

    const externalRefOptExists =
      values.customFields.length > 0 &&
      values.customFields.some((f) => f.label === external_ref_label);
    const productRefOptExists =
      values.customFields.length > 0 &&
      values.customFields.some((f) => f.label === product_ref_label);

    const newCustomFields = [...values.customFields];
    if (!externalRefOptExists) {
      newCustomFields.push({
        label: product_ref_label,
        value: invoiceOfferData.data.reference,
      });
    }
    if (!productRefOptExists) {
      newCustomFields.push({
        label: external_ref_label,
        value: invoiceOfferData.data.external_reference,
      });
    }

    setFieldValue("customFields", newCustomFields);
  }, [invoiceOfferData?.data?.external_reference, invoiceSetup]);

  //Initialize values in case of product type is addon
  useEffect(() => {
    if (values.product_type !== "addon") return;

    const product_ref_label =
      invoiceSetup?.product_reference_label || "Product Ref.";

    const newCustomFields = [...values.customFields];

    const productRefOptExists =
      values.customFields.length > 0 &&
      values.customFields.some((f) => f.label === product_ref_label);

    if (!productRefOptExists) {
      newCustomFields.push({
        label: product_ref_label,
        value: addonData?.data?.reference,
      });
    }
    setFieldValue("customFields", newCustomFields);
  }, [addonData]);

  const { data: invoicesSetupData, isLoading } = useQuery({
    queryKey: ["invoiceInvoiceSetup"],
    queryFn: () => fetchInvoiceSetup({ source_entity, status: "AC" }),
    refetchOnWindowFocus: false,
  });

  const { data: stamps, isStampsLoading } = useQuery({
    queryKey: ["companyStamps", source_entity],
    queryFn: () => fetchCompanyStamps({ source_entity }),
  });

  useEffect(() => {
    if (!_.get(invoicesSetupData, "data.results", []).length) return;

    const setup = invoicesSetupData.data.results[0];
    setFieldValue("memo", setup.default_memo);
    setFieldValue("footer", setup.default_footer_description);
    setFieldValue("email.sender_email", setup.sender_email);
  }, [_.get(invoicesSetupData, "data.results", []).length]);

  useEffect(() => {
    let stampSelected = (stamps?.data?.results || []).find(
      (item) => item.stamp === values.stamp
    );
    setFieldValue(
      "xAxis",
      typeof stampSelected?.position_x === "number"
        ? stampSelected?.position_x
        : 0
    );
    setFieldValue(
      "yAxis",
      typeof stampSelected?.position_y === "number"
        ? stampSelected?.position_y
        : 0
    );
  }, [values.stamp]);

  return (
    <div className={classes.Options}>
      <SectionHeader text="Additional options" />
      {isLoading && <Loader center size="lg" backdrop />}
      <div className={classes.items}>
        <React.Fragment>
          {data.map(([key, label], idx) => (
            <div
              className={
                key === "memo" || key === "footer"
                  ? classes.memoFooterOption
                  : classes.option
              }
              key={idx}>
              <label className={classes.optionLabel}>
                {label}
                {!memoEdit && key === "memo" && (
                  <InlineIcon
                    icon="ic:baseline-edit"
                    className={classes.actionBtn}
                    onClick={() => setMemoEdit(!memoEdit)}
                  />
                )}
                {!footerEdit && key === "footer" && (
                  <InlineIcon
                    icon="ic:baseline-edit"
                    className={classes.actionBtn}
                    onClick={() => setFooterEdit(!footerEdit)}
                  />
                )}
              </label>
              {key === "memo" && (
                <div className={classes.memoField}>
                  {memoEdit ? (
                    <React.Fragment>
                      <NormalInputField
                        label=""
                        name="memo"
                        highlightErrors={true}
                        as="textarea"
                        resize={false}
                        extraInputProps={{ rows: 10 }}
                      />
                      <InlineIcon
                        icon="mdi:tick"
                        className={classes.actionBtn}
                        onClick={() => setMemoEdit(!memoEdit)}
                      />
                    </React.Fragment>
                  ) : (
                    <span className={classes.footerMemoCntr}>
                      {values.memo}
                    </span>
                  )}
                </div>
              )}
              {key === "footer" && (
                <div className={classes.footerField}>
                  {footerEdit ? (
                    <React.Fragment>
                      <NormalInputField
                        label=""
                        name="footer"
                        highlightErrors={true}
                        as="textarea"
                        resize={false}
                        extraInputProps={{ rows: 10 }}
                      />
                      <InlineIcon
                        icon="mdi:tick"
                        className={classes.actionBtn}
                        onClick={() => setFooterEdit(!footerEdit)}
                      />
                    </React.Fragment>
                  ) : (
                    <span className={classes.footerMemoCntr}>
                      {values.footer}
                    </span>
                  )}
                </div>
              )}
              {key === "custom_fields" && (
                <React.Fragment>
                  <div className={classes.inputGroup}>
                    {customFields.map((field, idx) => (
                      <React.Fragment key={idx}>
                        <InputGroup fieldIdx={idx} />
                        <InlineIcon
                          icon="ph:x-bold"
                          className={classes.deleteBtn}
                          onClick={() => onRemoveCustomField(idx)}
                        />
                      </React.Fragment>
                    ))}
                  </div>
                  <div className={classes.edit} onClick={addCustomFieldHandle}>
                    Add custom field
                  </div>
                </React.Fragment>
              )}
              {key === "payment_methods" && (
                <NormalSelectField
                  name="payment_method"
                  options={[
                    ["", "Without integrated payment"],
                    ...paymentOptions,
                  ]}
                />
              )}
              {key === "stamps" && (
                <React.Fragment>
                  <NormalSelectField
                    name="stamp"
                    options={[
                      ["", "Without stamp"],
                      ...(stamps?.data?.results || []).map((stamp) => [
                        stamp.stamp,
                        stamp.name,
                      ]),
                    ]}
                  />
                  <div className={classes.axisCntr}>
                    <NormalInputField
                      label="X axis"
                      name="xAxis"
                      type="number"
                      highlightErrors
                      extraInputProps={{
                        min: 0,
                        max: 100,
                      }}
                    />
                    <NormalInputField
                      label="Y axis"
                      name="yAxis"
                      type="number"
                      highlightErrors
                      extraInputProps={{
                        min: 0,
                        max: 100,
                      }}
                    />
                  </div>
                </React.Fragment>
              )}
            </div>
          ))}
        </React.Fragment>
      </div>
    </div>
  );
};
Options.defaultProps = {
  data: [],
  memoEdit: false,
  footerEdit: false,
};
Options.propTypes = {
  data: PropTypes.array.isRequired,
  memoEdit: PropTypes.bool.isRequired,
  footerEdit: PropTypes.bool.isRequired,
  setMemoEdit: PropTypes.func.isRequired,
  setFooterEdit: PropTypes.func.isRequired,
};
