import _ from "lodash";
import {
  getAccAPIPriceSelector,
  getSelectedAccSelector,
} from "@src/selectors/Project/TripPlanner";
import {
  getServicePricingSelector,
  srvPriceKeyCreator,
} from "@src/selectors/Project/TripPlanner/pricing";
import { bankersRounder, markUpper } from "@src/tools/pricing_tools";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { PricingFormContext } from "../../TripPlanner";
import OverviewPrice from "../OverviewPrice";
import PrebookPrice from "../PrebookPrice";
import ServicePricingForm from "../ServicePricingForm";
import { createUseStyles } from "react-jss";
import { daySrvContainerStyles } from "../Day";
import { sideControlsStyles } from "../../Controllers/OverviewDestination";
import { isServiceFilterSelectedSelector } from "@src/selectors/Project/TripPlanner/service_filters";
import { WhisperWrapper } from "@src/components/common/ui_helpers";
import IconButton from "rsuite/lib/IconButton";
import { Icon } from "rsuite";
import OverviewAccommodation from "../../Controllers/OverviewAccommodation";
import CustomAccSrv from "../custom/accommodation";
import AdhocAccSrv from "../addhoc/accommodation";
import { getAccServicesSelector } from "@src/selectors/Project/TripPlanner/day_by_day_selectors";
import LeanAuthHoc from "@src/components/authorization/LeanAuthHoc";
import { ItinOverviewContext } from "../../Controllers/ItineraryOverview";
import { useServiceCardExpansion } from "@src/pages/hooks";

export const AccPrice = ({
  priceType,
  currentStep,
  destination,
  accFailed,
  accommodation,
  accSearching,
  onAccDetails,
}) => {
  var { price, currency } = useSelector((state) =>
    getAccAPIPriceSelector(state, { accommodation })
  );

  const { markup, gross_margin, flat_value } = useSelector((state) =>
    getServicePricingSelector(state, {
      key: srvPriceKeyCreator({ service: accommodation, service_type: "ACC" }),
    })
  );

  const { showPricingForm } = useContext(PricingFormContext);

  price =
    priceType == "buying"
      ? bankersRounder(price, 2)
      : markUpper(price, markup, 2);

  if (![3, 4].includes(currentStep)) return null;

  if (accFailed || _.isEmpty(accommodation) || accSearching) {
    return (
      <OverviewPrice serviceType="ACC" price={0} searching={accSearching} />
    );
  }

  if (currentStep == 3) {
    return showPricingForm ? (
      <ServicePricingForm service_type="ACC___ACC" service={accommodation} />
    ) : (
      <OverviewPrice
        price={price}
        currency={currency}
        markup={markup}
        gross_margin={gross_margin}
        flat_value={flat_value}
        onDetails={
          typeof onAccDetails === "function"
            ? function () {
                onAccDetails(destination.order, accommodation.metadata.id);
              }
            : null
        }
      />
    );
  } else if (currentStep == 4) {
    return <PrebookPrice price={price} currency={currency} />;
  }
  return null;
};
AccPrice.propTypes = {
  accommodation: PropTypes.object,
  priceType: PropTypes.string.isRequired,
  currentStep: PropTypes.number.isRequired,
  destination: PropTypes.object.isRequired,
  accFailed: PropTypes.bool.isRequired,
  accSearching: PropTypes.bool.isRequired,
  onAccDetails: PropTypes.func,
};

const AddAdhocHotel = ({ onClick }) => {
  return (
    <WhisperWrapper msg="Add Ad hoc Accommodation Service" placement="right">
      <IconButton
        icon={<Icon icon="plus" />}
        color="green"
        size="xs"
        circle
        onClick={onClick}
      />
    </WhisperWrapper>
  );
};

const AddCustomHotel = ({ onClick }) => {
  return (
    <WhisperWrapper msg="Add Custom Accommodation Service" placement="right">
      <IconButton
        icon={<Icon icon="plus-circle" />}
        color="blue"
        size="xs"
        circle
        onClick={onClick}
      />
    </WhisperWrapper>
  );
};

const accommodationStyles = createUseStyles({
  Accommodation: (props) =>
    daySrvContainerStyles({ currentStep: props.currentStep }),
  sideControls: sideControlsStyles(),
});
export const Accommodation = ({
  destination,
  currentStep,
  destOrder,
  requiredServices,
}) => {
  const apiacc = useSelector((state) =>
    getSelectedAccSelector(state, { destOrder })
  );
  const res = useSelector((state) => getAccServicesSelector(state));
  var accommodation = res.find((acc) => acc.destOrder == destOrder);

  const acc_srv_type = accommodation?.accommodation_service_type ?? "ACC";
  if (acc_srv_type === "ACC") accommodation = apiacc;

  const serviceFilterIsSelected = useSelector((state) =>
    isServiceFilterSelectedSelector(state, { service_type: "ACC" })
  );

  const { services_collapsed, toggleSelfCollapse } = useServiceCardExpansion();

  const classes = accommodationStyles({ currentStep });

  const itnContext = useContext(ItinOverviewContext);
  const { onSetCustomAccSrv, onSetAddhocAccSrv } = itnContext;

  const withoutAcc = !requiredServices.includes("ACC");
  return withoutAcc || !serviceFilterIsSelected ? null : (
    <React.Fragment>
      {acc_srv_type === "CU" ? (
        <CustomAccSrv srv={accommodation} />
      ) : acc_srv_type === "ADD" ? (
        <AdhocAccSrv srv={accommodation} />
      ) : (
        <div className={classes.Accommodation}>
          {currentStep < 4 && !services_collapsed && (
            <div className={classes.sideControls}>
              <AddAdhocHotel
                onClick={() => onSetAddhocAccSrv({ destination })}
              />
              {
                // <AddCustomHotel
                //   onClick={() => onSetCustomAccSrv({ destination })}
                // />
              }
            </div>
          )}
          <OverviewAccommodation
            accommodation={accommodation}
            destOrder={destOrder}
            currentStep={currentStep}
            services_collapsed={services_collapsed}
            toggleSelfCollapse={toggleSelfCollapse}
          />
        </div>
      )}
    </React.Fragment>
  );
};
Accommodation.propTypes = {
  destination: PropTypes.object.isRequired,
  currentStep: PropTypes.number.isRequired,
  destOrder: PropTypes.number.isRequired,
  requiredServices: PropTypes.array.isRequired,
};
