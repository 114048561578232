import _ from "lodash";
import { Tag } from "rsuite";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { modalGenericStyles, variables, tableStyles } from "@src/jsssetup";
import { currencies } from "@src/definitions";
import { Formik, Form } from "formik";
import { NormalInputField, NormalSelectField } from "@src/components/forms";
import Fuse from "fuse.js";
import { useDispatch, useSelector } from "react-redux";
import { addDbDCustomSrv } from "@src/actions/Project/TripPlanner/day_by_day/custom_services";
import { adhocPriceFields, AdhocSrvPriceFields } from "./addhoc/coach";
import { getSetupFormDataSelector } from "@src/selectors/Project/TripPlanner";
import { addonSrvPriceCalculator } from "./addhoc/common";

const ghostImg =
  "https://sisistaticassets.s3.ap-southeast-1.amazonaws.com/platform_graphics/imgs/others/destination_placeholder.jpg";

const FiltersForm = ({ restaus, onFilter }) => {
  const main_course_type_options = [
    ...new Set(
      _.flatten(
        restaus.map((dt) => dt.menu.map((me) => me.main_course_type))
      ).filter((me) => me)
    ),
  ].map((tp) => [tp, _.startCase(tp)]);

  return (
    <Formik
      initialValues={{
        menu_external_id: "",
        name: "",
        address: "",
        main_course_type: null,
        min_price: 0,
        max_price: 0,
        menu: "",
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validateOnMount={false}
      onSubmit={(values) => onFilter(values)}>
      {({ handleReset }) => (
        <React.Fragment>
          <div className="Card__body">
            <Form className="Form">
              <NormalInputField name="name" label="Name" />
              <NormalInputField name="address" label="Address" />
              <NormalInputField
                name="menu_external_id"
                label="Menu External Id"
              />
              <NormalSelectField
                name="main_course_type"
                label="Main Course Type"
                options={[[null, "------"], ...main_course_type_options]}
              />
              <NormalInputField name="menu" label="Menu" />
              <NormalInputField
                name="min_price"
                label="Min Price"
                type="number"
              />
              <NormalInputField
                name="max_price"
                label="Max Price"
                type="number"
              />
            </Form>
          </div>
          <div className="Card__actions">
            <button
              className="Button"
              data-ghost="true"
              type="button"
              onClick={handleReset}>
              Reset
            </button>
            <button className="Button" type="submit">
              Filter
            </button>
          </div>
        </React.Fragment>
      )}
    </Formik>
  );
};
FiltersForm.defaultProps = { restaus: [] };
FiltersForm.propTypes = {
  restaus: PropTypes.array.isRequired,
  onFilter: PropTypes.func.isRequired,
};

export const mealTypeOptions = {
  BR: "Breakfast",
  LU: "Lunch",
  DI: "Dinner",
  TE: "Afternoon Tea",
};

const RestauDetailsStyles = createUseStyles({
  RestauDetails: {
    display: "grid",
    gridTemplateColumns: "1fr 5fr",
    gridGap: variables.normal_gap,
  },
  img: {
    objectFit: "cover",
    maxWidth: "100%",
    padding: variables.normal_gap,
  },
  content: {
    display: "grid",
    gridGap: variables.normal_gap,
    padding: variables.normal_gap,
  },
  menu: {
    ...modalGenericStyles.card,
    padding: variables.normal_gap,
    boxShadow: variables.shadows.normalShadow,
  },
  menuHeader: modalGenericStyles.cardHeader,
  menuBody: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    gridTemplateColumns: "1fr 3fr",
    alignItems: "start",
    padding: variables.normal_gap,
  },
  controls: { display: "grid", gridGap: variables.normal_gap },
  price: {
    padding: variables.normal_gap,
    border: `2px solid ${variables.colors.deepblue}`,
    borderRadius: "5px",
  },
  menuItems: { display: "grid", gridGap: variables.normal_gap },
  tHead: tableStyles.head,
  tCell: tableStyles.cell,
  mealTypeForm: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
  },
});
const RestauDetails = ({
  adults,
  childs,
  restau,
  destination_id,
  secondary_destination_id,
  onSelect,
  onCloseModal,
}) => {
  const classes = RestauDetailsStyles();

  return (
    <div className={classes.RestauDetails}>
      <img alt="" src={ghostImg} className={classes.img} />
      <div className={classes.content}>
        {(restau?.menu ?? []).map((mn, idx) => (
          <div key={idx} className={classes.menu}>
            <h6 className={classes.menuHeader}>
              ({mn.external_id}) - {mn.title}
            </h6>
            <div className={classes.menuBody}>
              <div className={classes.controls}>
                <Formik
                  initialValues={{
                    meal_type: "LU",
                    ...adhocPriceFields({
                      per_person_price: parseFloat(
                        (mn.total_price.value / (adults + childs)).toFixed(2)
                      ),
                      total_price: mn.total_price.value,
                      currency: mn.total_price.currency,
                    }),
                  }}
                  validate={(values) =>
                    addonSrvPriceCalculator({
                      values,
                      adults,
                      childs,
                      original_total_price: mn.total_price.value,
                      original_currency: mn.total_price.currency,
                    })
                  }
                  onSubmit={async (values) => {
                    const tmp = _.cloneDeep(restau);
                    tmp.menu = [mn];
                    tmp.meal_type = values.meal_type;
                    tmp["destination_id"] = destination_id;
                    tmp["secondary_destination_id"] = secondary_destination_id;

                    const menu = tmp.menu[0];
                    menu.pricing = _.omit(values, "meal_type");

                    await onSelect(tmp);
                    onCloseModal();
                  }}>
                  <Form className={`${classes.mealTypeForm} Form`}>
                    <AdhocSrvPriceFields />
                    <NormalSelectField
                      name="meal_type"
                      label="Meal Type"
                      options={Object.entries(mealTypeOptions)}
                    />
                    <button
                      className="Button"
                      data-success="true"
                      type="submit">
                      Select
                    </button>
                  </Form>
                </Formik>
              </div>
              <div className={classes.menuItems}>
                <table>
                  <thead className={classes.tHead}>
                    <tr>
                      <th>#</th>
                      <th>Course Name</th>
                      <th>Main Course</th>
                    </tr>
                  </thead>
                  <tbody>
                    {mn.items
                      .filter((it) => it.name)
                      .map((it, idx) => (
                        <tr key={idx}>
                          <td clasNsame={classes.tCell}>{idx + 1}</td>
                          <td className={classes.tCell}>{it.name}</td>
                          <td className={classes.tCell}>
                            {it.main ? <Tag color="green">Yes</Tag> : "No"}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
RestauDetails.propTypes = {
  adults: PropTypes.number.isRequired,
  childs: PropTypes.number.isRequired,
  destination_id: PropTypes.string.isRequired,
  restau: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

const headerPriceStyles = {
  borderRadius: "5px",
  fontWeight: "bold",
  padding: `calc(${variables.normal_gap} / 2)`,
};
export const minPriceStyles = {
  ...headerPriceStyles,
  background: variables.colors.lightGrey,
};
export const avgPriceStyles = {
  ...headerPriceStyles,
  background: "green",
  color: "white",
};
export const maxPriceStyles = {
  ...headerPriceStyles,
  background: variables.colors.orange,
};

function restauMinPrice(restau) {
  return Math.min(...restau.menu.map((mn) => mn.total_price.value));
}

function restauAvgPrice(restau) {
  return (
    restau.menu.reduce((a, b) => _.get(b, "total_price.value", 0) + a, 0) /
    restau.menu.length
  );
}

function restauMaxPrice(restau) {
  return Math.max(...restau.menu.map((mn) => mn.total_price.value));
}

const RestaurantStyles = createUseStyles({
  restauCard: modalGenericStyles.card,
  restauHeader: modalGenericStyles.cardHeader,
  restauHeaderActions: {
    display: "grid",
    gridAutoFlow: "column",
    gridAutoColumns: "max-content",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    alignItems: "center",
  },
  minPrice: minPriceStyles,
  avgPrice: avgPriceStyles,
  maxPrice: maxPriceStyles,
  address: { fontSize: "smaller" },
});
const Restaurant = ({
  adults,
  childs,
  idx,
  destination_id,
  secondary_destination_id,
  restau,
  onSelect,
  onCloseModal,
}) => {
  const [viewDetails, setViewDetails] = useState(false);

  const minPrice = restauMinPrice(restau).toFixed(2);
  const avgPrice = restauAvgPrice(restau).toFixed(2);
  const maxPrice = restauMaxPrice(restau).toFixed(2);
  const currency = _.get(
    currencies,
    _.get(restau, `menu0.total_price.currency`),
    _.get(restau, "menu.0.total_price.currency")
  );

  const classes = RestaurantStyles();

  return (
    <div className={classes.restauCard}>
      <div className={classes.restauHeader}>
        <h6>
          {idx}. {restau.name}
          <br />
          <span className={classes.address}>{restau.address}</span>
          <br />
          <span className={classes.address}>
            {[
              ...new Set(
                restau.menu
                  .filter((m) => m.main_course_type)
                  .map((m) => _.startCase(m.main_course_type))
              ),
            ].join(", ")}
          </span>
        </h6>
        <div className={classes.restauHeaderActions}>
          <span>
            <strong>Prices:</strong>
          </span>
          <span className={classes.minPrice}>
            Min:
            {currency}
            {minPrice}
          </span>
          <span className={classes.avgPrice}>
            Avg: {currency}
            {avgPrice}
          </span>
          <span className={classes.maxPrice}>
            Max: {currency} {maxPrice}
          </span>
          <button
            className="Button"
            data-ghost={viewDetails ? "true" : "false"}
            appearance={viewDetails ? "ghost" : "primary"}
            onClick={() => setViewDetails((p) => !p)}>
            <strong>{viewDetails ? "Hide Details" : "View Details"}</strong>
          </button>
        </div>
      </div>
      {viewDetails && (
        <div className={classes.restauBody}>
          <RestauDetails
            adults={adults}
            childs={childs}
            destination_id={destination_id}
            secondary_destination_id={secondary_destination_id}
            restau={restau}
            onSelect={(srv) => onSelect(srv)}
            onCloseModal={onCloseModal}
          />
        </div>
      )}
    </div>
  );
};
Restaurant.propTypes = {
  idx: PropTypes.number.isRequired,
  adults: PropTypes.number.isRequired,
  childs: PropTypes.number.isRequired,
  destination_id: PropTypes.string.isRequired,
  secondary_destination_id: PropTypes.string.isRequired,
  restau: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

const fuseCfg = {
  shouldSort: false,
  threshold: 0.5,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ["name"],
};
const styles = createUseStyles({
  body: {
    display: "grid",
    gridTemplateColumns: "1fr 4fr",
    gridGap: variables.normal_gap,
    overflow: "hidden",
  },
  list: {
    display: "grid",
    gridGap: variables.normal_gap,
    gridAutoRows: "max-content",
    overflow: "auto",
  },
  filters: { ...modalGenericStyles.card, minWidth: "unset" },
  filtersHeader: modalGenericStyles.cardHeader,
  filtersBody: modalGenericStyles.cardBody,
  filtersActions: modalGenericStyles.cardActions,
});
const RestaurantsList = ({
  destination_id,
  secondary_destination_id,
  dayIdx,
  loading,
  data,
  onCancel,
}) => {
  const { adults, children: childs } = useSelector((state) =>
    getSetupFormDataSelector(state)
  );
  const [filters, setFilters] = useState({});
  const [filteredData, setFilteredData] = useState(data);

  const dispatch = useDispatch();
  const onSelect = useCallback(
    ({ dayIdx, srv }) =>
      dispatch(addDbDCustomSrv({ dayIdx, srv, srv_type: "REST" })),
    [dispatch]
  );

  useEffect(() => {
    var fData = _.cloneDeep(data);

    if (filters.name) {
      const fuseData = new Fuse(fData, fuseCfg);
      fData = fuseData.search(filters.name);
    }

    if (filters.address) {
      const fuseData = new Fuse(fData, { ...fuseCfg, keys: ["address"] });
      fData = fuseData.search(filters.address);
    }

    if (filters.main_course_type) {
      fData = fData.filter((dt) =>
        dt.menu.some((m) => m.main_course_type === filters.main_course_type)
      );
    }

    if (filters.menu) {
      fData = fData
        .map((restau) => {
          const fuseData = new Fuse(restau.menu, {
            ...fuseCfg,
            keys: ["title"],
          });
          restau.menu = fuseData.search(filters.menu);
          return restau;
        })
        .filter((restau) => restau.menu.length > 0);
    }

    if (filters.menu_external_id) {
      fData = fData
        .map((restau) => {
          restau.menu = restau.menu.filter(
            (mn) => mn.external_id === filters.menu_external_id
          );
          return restau;
        })
        .filter((restau) => restau.menu.length > 0);
    }

    if (filters.min_price > 0) {
      fData = fData.filter(
        (restau) => filters.min_price <= restauMinPrice(restau)
      );
    }

    if (filters.max_price > 0) {
      fData = fData.filter(
        (restau) => restauMaxPrice(restau) <= filters.max_price
      );
    }

    setFilteredData(fData);
  }, [data, filters]);

  const classes = styles();
  return (
    !loading && (
      <div className="RestaurantsList Modal__card">
        <div className="Modal__card__header">
          <h5>Available Restaurants</h5>
        </div>
        <div className={`${classes.body} Modal__card__body`}>
          <div className="Card">
            <div className="Card__header">
              <h6>Filters</h6>
            </div>
            <FiltersForm
              restaus={data}
              onFilter={(filters) => setFilters(filters)}
            />
          </div>
          <div className={classes.list}>
            {filteredData.map((restau, idx) => (
              <Restaurant
                key={idx}
                adults={adults}
                childs={childs}
                destination_id={destination_id}
                secondary_destination_id={secondary_destination_id}
                restau={restau}
                idx={idx + 1}
                onSelect={(srv) => onSelect({ dayIdx, srv })}
                onCloseModal={onCancel}
              />
            ))}
          </div>
        </div>
        <div className="Modal__card__actions">
          <button className="Button" data-ghost="true" onClick={onCancel}>
            Cancel
          </button>
        </div>
      </div>
    )
  );
};
RestaurantsList.defaultProps = {
  destination_id: "",
  loading: false,
  data: [],
};
RestaurantsList.propTypes = {
  destination_id: PropTypes.string.isRequired,
  secondary_destination_id: PropTypes.string.isRequired,
  dayIdx: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
};
export default RestaurantsList;
