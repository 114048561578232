import {
  TRIPPLANNER_SHOW_BOOKINGMESSAGE_MODAL,
  TRIPPLANNER_HIDE_BOOKINGMESSAGE_MODAL,
  TRIPPLANNER_LOADING_BOOKINGMESSAGE_MODAL,
  TRIPPLANNER_IDLE_BOOKINGMESSAGE_MODAL,
  TRIPPLANNER_CHANGE_BOOKING_MESSAGE,
} from "./types";

import { Notification } from "rsuite";

// ============================= ACTIONS =============================
import { saveTrip } from "./save_actions";

import { tripUpdate } from "@src/api/Project/TripPlanner";

import React from "react";

export const showBookingMessageModal = () => {
  return { type: TRIPPLANNER_SHOW_BOOKINGMESSAGE_MODAL };
};

export const hideBookingMessageModal = () => {
  return { type: TRIPPLANNER_HIDE_BOOKINGMESSAGE_MODAL };
};

export const setLoadingBookingMessageModal = () => {
  return { type: TRIPPLANNER_LOADING_BOOKINGMESSAGE_MODAL };
};

export const setIdleBookingMessageModal = () => {
  return { type: TRIPPLANNER_IDLE_BOOKINGMESSAGE_MODAL };
};

export const changeBookingMessage = (msg) => {
  return { type: TRIPPLANNER_CHANGE_BOOKING_MESSAGE, msg };
};

export const saveBookingMessage = () => async (dispatch, getState) => {
  const booking_message = getState().tripPlannerBookingMessage;
  var trip_instance = getState().tripPlannerInstanceData;

  await dispatch(setLoadingBookingMessageModal());

  if (!trip_instance.id) {
    const save_result = await dispatch(saveTrip({ nonUi: true }));
    if (!save_result) {
      return;
    } else {
      trip_instance = getState().tripPlannerInstanceData;
    }
  }

  try {
    await tripUpdate({ booking_message }, trip_instance.id);
    await dispatch(hideBookingMessageModal());
  } catch (error) {
    Notification.warning({
      title: "Booking Message Save Failure",
      description: (
        <p>
          {`We could not save your "Booking Message". We are sorry for the
          inconvenience!`}
        </p>
      ),
      duration: 6000,
    });
    await dispatch(setIdleBookingMessageModal());
  }
};
