import { commonList } from "@src/reducers/common";
import { ACC_CONTRACT_RES_LIST_GENERIC_CHANGE } from "../../../../../actions/Operation/Reservations/SingleServices/ContractedAccommodations/types";

const SSContractedAccListReducerInitial = {
  ...commonList,
  displayLength: 100,
  filters: {
    reference: "",
    external_reference: "",
    accommodation_name: "",
    source_entity_autocomplete: "",
    source_entity: "",
    destination_name__icontains: "",
    check_in__gte: "",
    check_in__lte: "",
    creator: "",
    creator_email: "",
    created__gte: "",
    created__lte: "",
    supplier_name__icontains: "",
    booking_mode: "",
    status: "",
    tags: "",
    page: 1,
    limit: 20,
    ordering: "",
  },
};
export const SSContractedAccListReducer = (
  state = SSContractedAccListReducerInitial,
  action
) => {
  switch (action.type) {
    case ACC_CONTRACT_RES_LIST_GENERIC_CHANGE: {
      const { filters } = action;
      return { ...state, filters: { ...state.filters, ...filters } };
    }
    default:
      return state;
  }
};
