import _ from "lodash";
import { notifyCommonWarning } from "@src/components/common/notifications/CommonWarningNotification.js";

import {
  // ========================== INIT ACTIONS =========================
  TRIPPLANNER_INIT,
  TRIP_MAP_REDRAW,
  GOTO_NEXT_STEP,
  GOTO_PREV_STEP,
  ITINERARY_SETUP_FORM_CHANGE,
  // ============================= ORIGIN ============================
  ITINERARY_SELECT_ORIGIN_DESTINATION,
  ITINERARY_RETURN_DATE_CHANGE,
  // ============================= RETURN ============================
  ITINERARY_ADD_RETURN,
  ITINERARY_REMOVE_RETURN_POINT,
  ITINERARY_SELECT_RETURN_DESTINATION,
  // ========================== DESTINATION ==========================
  TRIPPLANNER_ITINERARY_ADD_DESTINATION,
  TRIPPLANNER_ITINERARY_SELECT_DESTINATION,
  ITINERARY_REMOVE_DESTINATION,
  ITINERARY_DESTINATION_QUERY_CHANGE,
  ITINERARY_DESTINATION_DOWN,
  ITINERARY_DESTINATION_UP,
  ITINERARY_DESTINATION_STAY_CHANGE,
  // =================== TRANSPORTATION INFORMATION ==================
  TRIPPLANNER_SELECT_TRANSPORTATION_INFRASTRUCTURE,
  // ========================== PREFERENCES ==========================
  TRIPPLANNER_TRANSPORTATION_EDIT_ENABLED,
  TRANSPORTATION_PREFERENCES_CHANGE,
  TRANSPORTATION_PREFERENCES_RESET,
  ACCOMMODATION_PREFERENCES_CHANGE,
  ACCOMMODATION_PREFERENCES_ROOM_ADD,
  ACCOMMODATION_PREFERENCES_ROOM_REMOVE,
  ACCOMMODATION_PREFERENCES_ROOM_PAX_ADD,
  ACCOMMODATION_PREFERENCES_ROOM_PAX_REMOVE,
  ACCOMMODATION_PREFERENCES_AGE_CHANGE,
  ACCOMMODATION_PREFERENCES_RESET,
  TRANSFER_PREFERENCES_CHANGE,
  TRANSFER_PREFERENCES_RESET,
  // ======================= SEARCHING STATUSES ======================
  TRIPPLANNER_TRANSPORTATION_FAST_IDLE,
  // ======================== TRIP WAIT MODAL ========================
  TRIPPLANNER_SHOW_TRIP_WAIT_MODAL,
  TRIPPLANNER_HIDE_TRIP_WAIT_MODAL,
  TRIPPLANNER_ITINERARY_DESTINATION_LOADING,
  TRIPPLANNER_ITINERARY_DESTINATION_IDLE,
  TRIPPLANNER_ADD_PAX,
  TRIPPLANNER_REMOVE_PAX,
  TRIPPLANNER_CHANGE_PAX,
  TRIPPLANNER_TRP_INFRA_GENERIC_CHANGE,
  TRIPPLANNER_ITINERARY_DEST_GENERIC_CHANGE,
  TRIPPLANNER_ITINERARY_UPDATE_DESTINATIONS,
} from "./types";

import {
  infoLvl1,
  infoLvl2,
  infoLvl3,
  infoLvl4,
} from "@src/api/Project/TripPlanner";

import { queryProcessor, validateFirstStep, validateSecondStep } from "./tools";
import stepTwoRqs from "./step_two";

// ============================= ACTIONS =============================
import {
  itnSelectOriginDate,
  itnInitialDestDateChange,
  stepOneRqs,
} from "./step_one";

import {
  serviceFiltersChange,
  serviceFiltersReset,
  requestTripSave,
  cancelRequestTripSave,
  stepThreeRequirements,
} from "./step_three";

import { stepFourRequirements, stepFourBackRequirements } from "./step_four";
import { initRoomGuestMapping, initGuestsInfo } from "./guest_actions";

// ============================ SELECTORS ============================
import { getSelCurrencySelector } from "@src/selectors/Financial";

import { overviewAccFiltersChange } from "./accommodation_selection_actions";
import { notifyStepFourFailedService } from "@src/components/common/notifications/TripPlanNotifications";
import { destinationDataExtractor, getDestinationTypeInfoFn } from "./helpers";

export const showTripWaitModal = (msg = "Please wait...") => {
  return { type: TRIPPLANNER_SHOW_TRIP_WAIT_MODAL, msg };
};

export const hideTripWaitModal = () => {
  return { type: TRIPPLANNER_HIDE_TRIP_WAIT_MODAL };
};

export const initializePlanner = () => (dispatch, getState) => {
  const state = getState();
  const defaultNationality = _.get(
    state,
    "companyProfile.postalAddress.country_code",
    ""
  );
  dispatch(initPlanner(defaultNationality));
};

export const initPlanner = (defaultNationality) => {
  return { type: TRIPPLANNER_INIT, defaultNationality };
};

export const mapRedraw = () => {
  return { type: TRIP_MAP_REDRAW };
};

export const nextStep = (currentStep) => async (dispatch, getState) => {
  const state = getState();

  var validInfo = { isValid: false, msg: [] };

  switch (currentStep) {
    case 1:
      validInfo = validateFirstStep(
        state.tripPlannerItinerarySetupForm,
        state.tripPlannerOriginData,
        state.tripPlannerDestinations
      );
      break;
    case 2:
      validInfo = validateSecondStep(
        {
          adults: state.tripPlannerItinerarySetupForm.adults,
          children: state.tripPlannerItinerarySetupForm.children,
          children_ages: state.tripPlannerItinerarySetupForm.adults,
        },
        state.tripPlannerAccPax,
        state.tripPlannerItinerarySetupForm.requiredServices,
        state.tripPlannerTrpPrefEditMode,
        state.tripPlannerAccPrefEditMode,
        state.tripPlannerTrfPrefEditMode
      );
      break;
    case 3:
      validInfo = { isValid: true, msg: [] };
      break;
    default:
      validInfo = { isValid: false, msg: [] };
  }

  if (!validInfo.isValid) {
    validInfo.msg.forEach((m) => {
      window.setTimeout(() => notifyCommonWarning(m), 100);
    });
    return null;
  }

  if (currentStep == 2) {
    const result = dispatch(stepThreeRequirements());
    if (result === false) {
      return null;
    }
  } else if (currentStep == 3) {
    if (!(await dispatch(stepFourRequirements()))) {
      notifyStepFourFailedService();
      return null;
    }
  }

  dispatch(goToNextStep(currentStep));
  return null;
};

export const goToNextStep = (currentStep) => {
  return {
    type: GOTO_NEXT_STEP,
    currentStep,
  };
};

export const prevStep = (currentStep) => (dispatch) => {
  if (currentStep == 1) {
    return;
  } else if (currentStep == 2) {
    dispatch(mapRedraw());
    dispatch(stepOneRqs());
  } else if (currentStep == 4) {
    dispatch(stepFourBackRequirements());
  }

  dispatch(goToPrevStep(currentStep));
};

export const goToPrevStep = (currentStep) => {
  return { type: GOTO_PREV_STEP, currentStep };
};

export const itinerarySetupFormChange =
  (data) => async (dispatch, getState) => {
    if (data.name == "budget") {
      const currency = getSelCurrencySelector(getState());
      await dispatch(
        itinerarySetupFormChangeData({
          name: "currency",
          value: currency.notation,
          type: "text",
        })
      );
    }

    await dispatch(itinerarySetupFormChangeData(data));

    //Possible Post actions
    if (["adults", "children", "nationality"].includes(data.name)) {
      dispatch(initGuestsInfo());
      dispatch(initRoomGuestMapping());
    }
  };

export const itinerarySetupFormChangeData = (data) => {
  return {
    type: ITINERARY_SETUP_FORM_CHANGE,
    data,
  };
};

export const itineraryAddPax = (paxType) => {
  return { type: TRIPPLANNER_ADD_PAX, paxType };
};

export const itineraryRemovePax = (paxType) => {
  return { type: TRIPPLANNER_REMOVE_PAX, paxType };
};

export const itineraryChangePax = (paxType, value) => async (dispatch) => {
  await dispatch({ type: TRIPPLANNER_CHANGE_PAX, paxType, value });
  dispatch(initGuestsInfo());
  dispatch(initRoomGuestMapping());
};

// ======================= ORIGIN POINT ACTIONS ======================

const extremalExtractor = (destData, type, query) => {
  const { id, name_en, name_cn, coords: geodata, images } = destData;

  return {
    name_en,
    name_cn,
    geodata,
    images: images.filter((___, idx) => idx < 5),
    id,
    type,
    query,
  };
};

export const itnSelectExtremalDest =
  (id, lvl, query, type) => async (dispatch) => {
    var fn = null;
    switch (lvl) {
      case "lvl1":
        fn = infoLvl1;
        break;
      case "lvl2":
        fn = infoLvl2;
        break;
      case "lvl3":
        fn = infoLvl3;
        break;
      case "lvl4":
        fn = infoLvl4;
        break;
      default:
        fn = infoLvl2;
        break;
    }

    await dispatch(loadingDest(type));
    try {
      const result = await fn(id);
      const dest = extremalExtractor(result, lvl, query);
      const action_type =
        type == "origin"
          ? ITINERARY_SELECT_ORIGIN_DESTINATION
          : ITINERARY_SELECT_RETURN_DESTINATION;
      await dispatch({ type: action_type, data: dest });
      dispatch(mapRedraw());
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(idleDest(type));
    }
  };

// ======================= RETURN POINT ACTIONS ======================

export const itnAddReturn = () => (dispatch, getState) => {
  const arrival = _.last(getState().tripPlannerDestinations).checkOut;
  const data = getState().tripPlannerOriginData;

  dispatch(itineraryAddReturn(data, arrival));
  // dispatch({ type: ITINERARY_ADD_RETURN, data, arrival });
  dispatch(mapRedraw());
};

export const itineraryAddReturn = (data, arrival) => {
  return {
    type: ITINERARY_ADD_RETURN,
    data,
    arrival,
  };
};

export const itnReturnPointArrivalChange = (arrival) => {
  return { type: ITINERARY_RETURN_DATE_CHANGE, arrival };
};

export const itnRemoveReturnPoint = () => (dispatch) => {
  dispatch(itineraryRemoveReturnPoint());
  // dispatch({ type: ITINERARY_REMOVE_RETURN_POINT });
  dispatch(mapRedraw());
};

export const itineraryRemoveReturnPoint = () => {
  return {
    type: ITINERARY_REMOVE_RETURN_POINT,
  };
};

// ======================= DESTINATION ACTIONS =======================

export const itnAddDestination = (order) => async (dispatch, getState) => {
  await dispatch(
    itineraryAddDestination(order, getState().tripPlannerOriginData)
  );
  const destinations = getState().tripPlannerDestinations;
  dispatch(itnReturnPointArrivalChange(_.last(destinations).checkOut));
};

export const itineraryAddDestination = (idx, originData) => {
  return {
    type: TRIPPLANNER_ITINERARY_ADD_DESTINATION,
    idx,
    originData,
  };
};

export const itineraryUpdateDestinations = (destinations) => {
  return {
    type: TRIPPLANNER_ITINERARY_UPDATE_DESTINATIONS,
    destinations,
  };
};

export const loadingDest = (destOrder) => {
  return { type: TRIPPLANNER_ITINERARY_DESTINATION_LOADING, destOrder };
};

export const idleDest = (destOrder) => {
  return { type: TRIPPLANNER_ITINERARY_DESTINATION_IDLE, destOrder };
};

export const itnSelectDestination = (options) => async (dispatch) => {
  const { id, lvl, query, order } = options;
  const fetchFn = getDestinationTypeInfoFn({ destLvl: lvl });

  await dispatch(loadingDest(order));
  try {
    const result = await fetchFn(id);
    const dest = destinationDataExtractor({
      data: result,
      type: lvl,
      order,
      query,
    });
    await dispatch(itinerarySelectDestination(order - 1, dest));
    dispatch(mapRedraw());
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(idleDest(order));
  }
};

export const itinerarySelectDestination = (idx, data) => {
  return {
    type: TRIPPLANNER_ITINERARY_SELECT_DESTINATION,
    idx,
    data,
  };
};

export const itnRemoveDestination =
  (order, destId) => async (dispatch, getState) => {
    await dispatch(
      itineraryRemoveDestination(
        order,
        getState().tripPlannerOriginData,
        destId
      )
    );
    dispatch(mapRedraw());
  };

export const itineraryRemoveDestination = (order, originData, destId) => {
  return {
    type: ITINERARY_REMOVE_DESTINATION,
    order,
    originData,
    destId,
  };
};

export const itnDestinationGenericChange = ({ destOrder, key, value }) => {
  return {
    type: TRIPPLANNER_ITINERARY_DEST_GENERIC_CHANGE,
    destOrder,
    key,
    value,
  };
};

export const itnDestinationQueryChange = (order, query) => {
  return {
    type: ITINERARY_DESTINATION_QUERY_CHANGE,
    idx: order - 1,
    query: queryProcessor(query),
  };
};

export const itnDestinationDown = (order) => (dispatch, getState) => {
  dispatch(
    itineraryDestinationDown(order - 1, getState().tripPlannerOriginData)
  );
  dispatch(mapRedraw());
};

export const itineraryDestinationDown = (idx, originData) => {
  return {
    type: ITINERARY_DESTINATION_DOWN,
    idx,
    originData,
  };
};

export const itnDestinationUp = (order) => (dispatch, getState) => {
  dispatch(itineraryDestinationUp(order - 1, getState().tripPlannerOriginData));
  dispatch(mapRedraw());
};

export const itineraryDestinationUp = (idx, originData) => {
  return {
    type: ITINERARY_DESTINATION_UP,
    idx,
    originData,
  };
};

export const itnDestStayChange = ({ stay, destOrder }) => {
  return {
    type: ITINERARY_DESTINATION_STAY_CHANGE,
    stay,
    destOrder,
  };
};

export const transportationPrefChange = (name, value) => {
  return { type: TRANSPORTATION_PREFERENCES_CHANGE, name, value };
};

export const accPrefChange = (preferences) => {
  return { type: ACCOMMODATION_PREFERENCES_CHANGE, preferences };
};

export const accPrefAddRoom = () => {
  return { type: ACCOMMODATION_PREFERENCES_ROOM_ADD };
};

export const accPrefRemoveRoom = () => {
  return { type: ACCOMMODATION_PREFERENCES_ROOM_REMOVE };
};

export const accPrefRoomPaxAdd = (roomIdx, paxType) => {
  return { type: ACCOMMODATION_PREFERENCES_ROOM_PAX_ADD, roomIdx, paxType };
};

export const accPrefRoomPaxRemove = (roomIdx, paxType) => {
  return { type: ACCOMMODATION_PREFERENCES_ROOM_PAX_REMOVE, roomIdx, paxType };
};

export const accRoomPaxAgeChange = (roomIdx, childIdx, age) => {
  return { type: ACCOMMODATION_PREFERENCES_AGE_CHANGE, roomIdx, childIdx, age };
};

export const transferPrefChange = (preferences) => {
  return { type: TRANSFER_PREFERENCES_CHANGE, preferences };
};

export const resetPreferences = (type) => (dispatch, getState) => {
  switch (type) {
    case "transportation": {
      const state = getState();
      dispatch(resetTrPreferences());
      dispatch(
        selectTranspotationInfrastructure(
          [
            ...new Set([
              ...state.tripPlannerDestinations.map((d) => d.id.toString()),
              ...[state.tripPlannerOriginData.destination.id.toString()],
              ...(_.isEmpty(state.tripPlannerReturnData)
                ? []
                : [state.tripPlannerReturnData.destination.id.toString()]),
            ]),
          ],
          "airports"
        )
      );
      return null;
    }
    case "accommodation":
      dispatch(resetAccPreferences());
      return null;
    case "transfers":
      dispatch(resetTrfPreferences());
      return null;
    default:
      return null;
  }
};

export const resetTrPreferences = () => {
  return {
    type: TRANSPORTATION_PREFERENCES_RESET,
  };
};

export const selectTranspotationInfrastructure = (ids, infraType) => {
  return {
    type: TRIPPLANNER_SELECT_TRANSPORTATION_INFRASTRUCTURE,
    ids,
    infraType,
  };
};

export const resetAccPreferences = () => {
  return {
    type: ACCOMMODATION_PREFERENCES_RESET,
  };
};

export const resetTrfPreferences = () => {
  return {
    type: TRANSFER_PREFERENCES_RESET,
  };
};
// ====================== TRANSPORTATION ACTIONS =====================

export const infrastructureChange = (ids, infraType) => {
  return {
    type: TRIPPLANNER_SELECT_TRANSPORTATION_INFRASTRUCTURE,
    ids,
    infraType,
  };
};

export const trpInfraGenericChange = (key, value) => {
  return { type: TRIPPLANNER_TRP_INFRA_GENERIC_CHANGE, key, value };
};

export const transportationIdle = () => {
  return { type: TRIPPLANNER_TRANSPORTATION_FAST_IDLE };
};

export const transportationEdit = () => {
  return { type: TRIPPLANNER_TRANSPORTATION_EDIT_ENABLED };
};

export {
  // ======================== STEP ONE ACTIONS =======================
  itnInitialDestDateChange,
  itnSelectOriginDate,
  serviceFiltersChange,
  serviceFiltersReset,
  // ========================== SAVE ACTIONS =========================
  requestTripSave,
  cancelRequestTripSave,
};

export * from "./load_actions";
export * from "./save_actions";
export * from "./guest_actions";
export * from "./transportation_actions";
export * from "./accommodation_actions";
export * from "./transfer_actions";
export * from "./control_panel_actions";
export * from "./booking_message_actions";
export * from "./step_four";
export * from "./flight_actions";
export * from "./step_three";
export * from "./accommodation_selection_actions";
export * from "./post_booking_actions";
export * from "./transfers_prebook";
export * from "./accommodation_preferences";
export * from "./marketplace_actions";
export * from "./modals/saveas_modal_actions";
