import _ from "lodash";
import {
  // ========================== INIT ACTIONS =========================
  TRIPPLANNER_INIT,
  // ======================= TRIP LOAD ACTIONS =======================
  TRIPPLANNER_LOAD_TRIP,
  TRIPPLANNER_TRANSFER_EDIT_ENABLED,
  TRIPPLANNER_TRANSFER_EDIT_DISABLED,
  TRANSFER_PREFERENCES_CHANGE,
  TRANSFER_PREFERENCES_RESET,
} from "@src/actions/Project/TripPlanner/types";

const tripPlannerTrfPrefInitState = {
  vehicleType: "ALL",
  transferType: "ALL", //enum: ['shared', 'private']
  pickupBeforeDeparture: 180,
  pickupAfterArrival: 30,
  preselectionMode: "prefer", //enum: ['prefer', 'comply']
};
export const tripPlannerTrfPref = (
  state = tripPlannerTrfPrefInitState,
  action
) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return tripPlannerTrfPrefInitState;
    case TRIPPLANNER_LOAD_TRIP:
      return action.data?.transfer_preferences ?? tripPlannerTrfPrefInitState;
    case TRANSFER_PREFERENCES_CHANGE: {
      const { preferences } = action;
      const newState = _.cloneDeep(state);
      return { ...newState, ...preferences };
    }
    case TRANSFER_PREFERENCES_RESET:
      return tripPlannerTrfPrefInitState;
    default:
      return state;
  }
};

export const tripPlannerTrfPrefEditMode = (state = false, action) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return false;
    case TRIPPLANNER_LOAD_TRIP:
      return false;
    case TRIPPLANNER_TRANSFER_EDIT_ENABLED:
      return true;
    case TRIPPLANNER_TRANSFER_EDIT_DISABLED:
      return false;
    default:
      return state;
  }
};
