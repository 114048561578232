import { Tag } from "rsuite";

// ============================ SELECTORS ============================

import React from "react";
import { createUseStyles } from "react-jss";
import {
  overviewCtrlSectionHeaderStyles,
  overviewCtrlSectionStyles,
} from "./ServicesFilter";
import { variables } from "@src/jsssetup";
import { getServicesPricingSelector } from "@src/selectors/Project/TripPlanner/pricing";
import { useSelector } from "react-redux";
import { PriceExcHoc } from "../Components/PriceDetails";

var Price = ({ price, symbol }) => {
  return (
    <strong>
      {symbol}
      {price}
    </strong>
  );
};
Price = PriceExcHoc(Price);

const prebookCalculatorStyles = createUseStyles({
  PrebookCalculator: {
    ...overviewCtrlSectionStyles(),
  },
  header: overviewCtrlSectionHeaderStyles(),
  content: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    marginTop: variables.normal_gap,
  },
  list: {
    padding: 0,
  },
  item: {
    listStyleType: "none",
    display: "grid",
    gridTemplateColumns: "1fr max-content",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    marginBottom: variables.normal_gap,
    alignItems: "center",
  },
  refundable: {
    background: variables.colors.easy.orange,
    color: "white",
  },
  bookable: {
    background: variables.colors.deepPurple,
    color: "white",
  },
});
export default function PrebookCalculator() {
  const classes = prebookCalculatorStyles();

  const refundable_pricing = useSelector((state) =>
    getServicesPricingSelector(state, {
      booking_mode: "regular",
      refundable: true,
      caller: "PrebookCalculator",
    })
  );

  const option_refundable_pricing = useSelector((state) =>
    getServicesPricingSelector(state, { booking_mode: "option" })
  );

  const refundable_amount =
    refundable_pricing.buying.amount + option_refundable_pricing.buying.amount;

  const non_refundable_pricing = useSelector((state) =>
    getServicesPricingSelector(state, {
      booking_mode: "regular",
      refundable: false,
    })
  );
  const non_refundable_amount = non_refundable_pricing.buying.amount;

  return (
    <div className={classes.PrebookCalculator}>
      <h5 className={classes.header}>CALCULATOR</h5>
      <div className={classes.content}>
        <ul className={classes.list}>
          <li className={classes.item}>
            <span>
              <strong>Non Refundable</strong>
            </span>
            <Tag color="red">
              <Price
                price={non_refundable_amount}
                currency={non_refundable_pricing.currency}
              />
            </Tag>
          </li>
          <li className={classes.item}>
            <span>
              <strong>Refundable</strong>
            </span>
            <Tag className={classes.refundable}>
              <Price
                price={refundable_amount}
                currency={refundable_pricing.currency}
              />
            </Tag>
          </li>
          <li className={classes.item}>
            <span>
              <strong>Total Bookable</strong>
            </span>
            <Tag className={classes.bookable}>
              <Price
                price={non_refundable_amount + refundable_amount}
                currency={refundable_pricing.currency}
              />
            </Tag>
          </li>
        </ul>
      </div>
    </div>
  );
}
