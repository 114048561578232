import { NormalSelectField } from "@src/components/forms";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { FlightPreferences } from "./FlightPreferences";
import { TrainPreferences } from "./TrainPreferences";
import { FerryPreferences } from "./FerryPreferences.js";
import { Icon } from "@iconify/react";
import { useFormikContext } from "formik";

export const Leg = ({
  leg,
  idx,
  originName,
  destinationName,
  mode,
  allCollapsed,
}) => {
  const { setFieldValue } = useFormikContext();
  const [collapsed, setCollapsed] = useState(false);
  const serviceOptions = [
    ["FL", "Flight"],
    ["TRA", "Train"],
    ["FER", "Ferry"],
  ];

  // Control the collapsed state based on the allCollapsed variable.
  useEffect(() => {
    setCollapsed(allCollapsed);
  }, [allCollapsed]);

  return (
    <div
      className="TransportationLegs__leg"
      data-collapsed={collapsed ? "true" : "false"}>
      <h6>
        Leg {idx + 1}{" "}
        <button
          className="Toggle"
          data-on={leg.disabled ? "true" : "false"}
          data-disabled="true"
          type="button"
          onClick={(e) => {
            e.preventDefault();
            if (mode !== "edit") return;
            setFieldValue(`legs.${idx}.disabled`, !leg?.disabled);
          }}>
          <Icon
            icon={leg.disabled ? "ic:round-toggle-on" : "ic:round-toggle-off"}
          />
          <strong>Disabled</strong>
        </button>
        {!leg.disabled && (
          <button
            className="Button"
            data-size="xs"
            data-ghost="true"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              setCollapsed((p) => !p);
            }}>
            {collapsed ? "Expand" : "Collapse"}
          </button>
        )}
      </h6>
      {leg?.disabled ? null : mode === "edit" ? (
        <NormalSelectField
          label="Service Type"
          name={`legs.${idx}.service_preferences.service_type`}
          options={[
            ["", "------"],
            ["FL", "Flight"],
            ["TRA", "Train"],
            ["FER", "Ferry"],
          ]}
          highlightErrors={true}
        />
      ) : (
        <span>
          <strong>Service Type: </strong>
          {serviceOptions.find(
            (s) => s[0] === leg?.service_preferences?.service_type
          )?.[1] ?? "N/A"}
        </span>
      )}
      {leg.disabled ? null : (
        <React.Fragment>
          {leg?.service_preferences?.service_type === "FL" && (
            <FlightPreferences
              leg={leg}
              legIdx={idx}
              origin_code={leg.origin_code}
              destination_code={leg.destination_code}
              mode={mode}
            />
          )}
          {leg?.service_preferences?.service_type === "TRA" && (
            <TrainPreferences
              leg={leg}
              legIdx={idx}
              originName={originName}
              destinationName={destinationName}
              mode={mode}
            />
          )}
          {leg?.service_preferences?.service_type === "FER" && (
            <FerryPreferences leg={leg} legIdx={idx} mode={mode} />
          )}
        </React.Fragment>
      )}
    </div>
  );
};
Leg.defaultProps = { mode: "view", allCollapsed: false };
Leg.propTypes = {
  idx: PropTypes.number.isRequired,
  originName: PropTypes.string,
  destinationName: PropTypes.string,
  leg: PropTypes.object.isRequired,
  mode: PropTypes.oneOf(["view", "edit"]),
  allCollapsed: PropTypes.bool,
};
