import React, { useEffect } from "react";
import { variables } from "@src/jsssetup";
import PropTypes from "prop-types";
import _ from "lodash";
import { humanizeDurationFromMins } from "@src/tools/common_tools";
import { DateTime } from "luxon";
import { InlineIcon } from "@iconify/react";
import { createOptions, updateOptions } from "../helpers";
import Select from "react-select";

export const TripSeats = ({
  leg,
  selectedOptions,
  setSelectedOptions,
  selectedReturnOptions,
  setSelectedReturnOptions,
  totalPax,
  isReturnLeg,
  totalTicketsSelected,
  setTotalTicketsSelected,
  selectedNum,
  selectedReturnNum,
  setSelectedNum,
  setSelectedReturnNum,
}) => {
  const routeName = _.get(leg, "route_name", "");
  const departure = DateTime.fromISO(_.get(leg, "departure_datetime", ""))
    .setZone("UTC", {
      keepLocalTime: false,
    })
    .toLocaleString(DateTime.DATETIME_MED);
  const duration = humanizeDurationFromMins(_.get(leg, "duration_minutes", 0));
  const accommodations = _.get(leg, "accommodations", []);

  useEffect(() => {
    if (!isReturnLeg) {
      if (selectedOptions?.length > 0) {
        setTotalTicketsSelected(
          selectedOptions?.reduce((total, option) => total + option.value, 0) ??
            0
        );
        setSelectedNum(totalTicketsSelected);
      }
      if (selectedOptions?.length === 0) {
        setSelectedNum(0);
      }
    }
  }, [
    selectedOptions,
    selectedReturnOptions,
    totalTicketsSelected,
    isReturnLeg,
  ]);

  return (
    <div className="TripSeats">
      <React.Fragment>
        <div className="TripSeats__titleContainer">
          <div className="TripSeats__titleContainer__tripTitle">
            {routeName}
          </div>
          <div className="TripSeats__titleContainer__amountSelected">
            {" "}
            {`${
              isReturnLeg ? selectedReturnNum : selectedNum
            }/${totalPax} Selected`}
          </div>
        </div>
        <div className="TripSeats__dateContainer">
          <div className="TripSeats__dateContainer__departure">
            Departure: {departure}
          </div>
          <div className="TripSeats__dateContainer__duration">
            Duration: {duration}
          </div>
        </div>
        <div className="TripSeats__titleOptions">
          Cabin / Seating / Deck Space
          <span className="TripSeats__titleOptions__compulsory">
            {" "}
            (Compulsory)
          </span>
        </div>
        {accommodations.map((acc, idx) =>
          acc.is_cabin && !acc.is_shared ? null : (
            <div className="TripSeats__SeatCardContainer" key={idx}>
              {acc.is_cabin && !acc.is_shared ? null : (
                <SeatCard
                  leg={leg}
                  accommodation={acc}
                  key={idx}
                  selectId={idx}
                  totalPax={totalPax}
                  onSelect={(selected) =>
                    setSelectedOptions(
                      updateOptions({ options: selectedOptions, selected })
                    )
                  }
                  onReturnSelect={(selected) =>
                    setSelectedReturnOptions(
                      updateOptions({
                        options: selectedReturnOptions,
                        selected,
                      })
                    )
                  }
                />
              )}
            </div>
          )
        )}
      </React.Fragment>
    </div>
  );
};
TripSeats.defaultProps = {
  leg: {},
  selectedOptions: [],
  selectedReturnOptions: [],
  totalPax: 0,
  isReturnLeg: false,
  selectedNum: 0,
  selectedReturnNum: 0,
  totalTicketsSelected: 0,
};
TripSeats.propTypes = {
  leg: PropTypes.object.isRequired,
  selectedOptions: PropTypes.array.isRequired,
  setSelectedOptions: PropTypes.func.isRequired,
  selectedReturnOptions: PropTypes.array.isRequired,
  setSelectedReturnOptions: PropTypes.func.isRequired,
  totalPax: PropTypes.number.isRequired,
  isReturnLeg: PropTypes.bool.isRequired,
  selectedNum: PropTypes.number.isRequired,
  selectedReturnNum: PropTypes.number.isRequired,
  setSelectedNum: PropTypes.func.isRequired,
  setSelectedReturnNum: PropTypes.func.isRequired,
  totalTicketsSelected: PropTypes.number.isRequired,
  setTotalTicketsSelected: PropTypes.func.isRequired,
};

const selectFieldCcustomStyles = {
  control: (provided, state) => ({
    ...provided,
    cursor: "pointer",
    borderColor: state.isFocused
      ? variables.colors.easy.orange
      : provided.borderColor,
    boxShadow: "none",
  }),
  menu: (provided, __) => ({
    ...provided,
    zIndex: 9999,
  }),
};

const SeatCard = ({
  accommodation,
  selectId,
  onSelect,
  onReturnSelect,
  totalPax,
  leg,
}) => {
  const capacity = _.get(accommodation, "capacity", 1);

  const notOnlineBooking = accommodation.is_cabin && !accommodation.is_shared;

  function onUpdate({ data }) {
    if (!_.get(data, "is_return_leg", false)) {
      onSelect(data);
    } else {
      onReturnSelect(data);
    }
  }

  return (
    <div className="SeatCard">
      <div
        className="SeatCard__cardHeader"
        data-capacity-more-than-one={capacity > 1 ? "true" : "false"}>
        {capacity > 1 ? (
          new Array(capacity).fill(1).map((x, idx) => (
            <React.Fragment key={idx}>
              <InlineIcon
                icon="ic:baseline-person"
                className="SeatCard__cardHeader__passengerIcon"
              />
            </React.Fragment>
          ))
        ) : (
          <InlineIcon
            icon="ic:baseline-person"
            className="SeatCard__cardHeader__passengerIcon"
          />
        )}
      </div>
      <div className="SeatCard__cardBody">
        <div className="SeatCard__cardBody__cardTitle">
          {accommodation.description}
        </div>
        {accommodation.price.value > 0 && (
          <div className="SeatCard__cardBody__priceContr">
            <div className="SeatCard__cardBody__priceContr__price">
              {accommodation.price.value.toLocaleString("en-US", {
                style: "currency",
                currency: accommodation.price.currency,
              })}{" "}
              Per Person
            </div>
            {accommodation.availability < 9 && (
              <small className="SeatCard__cardBody__priceContr__ltdAvailability">
                <i>Hurry Limited Availability</i>
              </small>
            )}
          </div>
        )}
        <Select
          key={selectId}
          isDisabled={notOnlineBooking}
          className="SeatCard__cardBody__inputGroup"
          onChange={(data) => onUpdate({ data })}
          options={createOptions({ accommodation, selectId, leg, totalPax })}
          defaultValue={
            createOptions({ accommodation, selectId, leg, totalPax })[0]
          }
          placeholder=""
          styles={selectFieldCcustomStyles}
        />
      </div>
    </div>
  );
};
SeatCard.defaultProps = {
  accommodation: {},
  selectId: 0,
  totalPax: 1,
  leg: {},
};
SeatCard.propTypes = {
  accommodation: PropTypes.object.isRequired,
  selectId: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
  onReturnSelect: PropTypes.func.isRequired,
  totalPax: PropTypes.number.isRequired,
  leg: PropTypes.object.isRequired,
};
