import {
  SUBAGENTS_BEGIN_FETCH_DATA,
  SUBAGENTS_FETCH_DATA,
  SUBAGENTS_END_FETCH_DATA,
  SUBAGENTS_FETCH_OPTIONS,
  SUBAGENTS_UPDATE_LIST_FILTER_FORM,
  SUBAGENTS_APPLY_LIST_FILTER,
  SUBAGENTS_INITIALIZE_LIST_FILTER_FORM,
  SUBAGENTS_UPDATE_LIST_COLUMNS_FORM,
  SUBAGENTS_APPLY_LIST_COLUMNS,
  SUBAGENTS_INITIALIZE_LIST_COLUMNS_FORM,
  SUBAGENTS_SHOW_BASE_DATA_EDIT_FORM,
  SUBAGENTS_HIDE_BASE_DATA_EDIT_FORM,
  SUBAGENTS_EDIT_BASE_DATA,
  SUBAGENTS_INITIALIZE_BASE_DATA_EDIT_FORM,
  SUBAGENTS_VALIDATE_BASE_DATA_EDIT_FORM,
  SUBAGENTS_PROFILE_DATA,
  SUBAGENTS_UPLOADING_BASE_DATA_EDIT_FORM,
  SUBAGENTS_IDLE_BASE_DATA_EDIT_FORM,
  SUBAGENTS_CLEAN_BASE_EDIT_FORM_ERRORS,
  SUBAGENTS_CLEAN_BASE_DATA_EDIT_FORM,
  SUBAGENTS_VIEW_NATURE_OF_BIZ,
  SUBAGENTS_EDIT_NATURE_OF_BIZ,
  SUBAGENTS_INITIALIZE_NATURE_DATA_FORM,
  SUBAGENTS_EDIT_NATURE_OF_BIZ_DATA,
  SUBAGENTS_SUBMIT_NATURE_OF_BIZ_DATA,
  SUBAGENTS_EDIT_LICENSE_CODE,
  SUBAGENTS_TOGGLE_POSTAL_ADDRESS,
  SUBAGENTS_RESET_LIST_FILTER_FORM,
  SUBAGENTS_INITIALIZE_TRAVEL_SERVICE_FORM,
  SUBAGENTS_INITIALIZE_MAIN_SERVICES_FORM,
  //img relation
  SUBAGENTS_UPLOAD_PREFERENCE_IMAGE,
  SUBAGENTS_FETCH_ADDRESS_POINT,
  SUBAGENTS_INITIAL_ADDRESS_POINT,
  SUBAGENTS_TOGGLE_VERIFY_ADDRESS,
  SUBAGENTS_BEGIN_FETCH_ADDRESS_DATA,
  SUBAGENTS_END_FETCH_ADDRESS_DATA,
  SUBAGENTS_BEGIN_FETCH_PROFILE_DATA,
  SUBAGENTS_END_FETCH_PROFILE_DATA,
  SUBAGENTS_CLOSE_VERIFY_ADDRESS,
  SUBAGENTS_CLEAN_ADDRESS_POINT,
  SUBAGENTS_INITIAL_VERIFY_STATUS,

  //==================Email Config ==================
  SUBAGENTS_VIEW_EMAIL_CONFIG_MODE,
  SUBAGENTS_EDIT_EMAIL_CONFIG_MODE,
  SUBAGENTS_LOADING_EMAIL_CONFIG_MODE,
  SUBAGENTS_EDIT_EMAIL_CONFIG_FORM,
  SUBAGENTS_INITIALIZE_EMAIL_CONFIG_FORM,
} from "./types";

import { notifyCommonError } from "@src/components/common/notifications/CommonErrorNotification.js";
import { notifyCommonSuccess } from "@src/components/common/notifications/CommonSuccessNotification.js";

import _ from "lodash";
import { createHashHistory } from "history";

import {
  initialSubAgentsBaseDataForm,
  initialSubAgentsNatureOfBizForm,
  initialSubAgentsFilterForm,
  defaultOption,
} from "@src/forms/Network/SubAgents";

import React from "react";
import TransTxt from "@src/components/common/SxFormatMessage";

import { submitProcessor, entityError400Handle } from "@src/actions/tools";
import { travelServicesFrom } from "@src/forms/Admin/Company/companyProfileForms";

import {
  fetchCompleteAddressFactory,
  fetchAddressPointDetailFactory,
  changeUploadImgFactory,
} from "./../../ActionFactory";

import {
  getSubAgentsList,
  getSubAgentsOptions,
  addNewSubAgents as addNewSubAgentsAPI,
  fetchSubAgentsProfile as fetchSubAgentsProfileAPI,
  submitSubAgentsProfile as submitSubAgentsProfileAPI,
} from "@src/api";

import {
  filterEmptyValue,
  paramsProcessor,
  isEmptyObj,
  errorHandle as commonErrorHandle,
} from "@src/tools/common_tools";

import { hideGlobalModal } from "@src/actions/Shares";

import update from "immutability-helper";

import { getFilter, getPagination } from "@src/tools/form_tools";

import { uploadImgLink } from "@src/api";

// subAgents list
export const addNewSubAgentsData = () => (dispatch, getState) => {
  const state = getState();
  const userMeta = state.userMeta;
  const verifyStatus = state.isVerifySubAgentsPostalAddress;
  if (state.subAgentsBaseDataFormErrors.valid == false) {
    for (const key in state.subAgentsBaseDataFormErrors) {
      if (state.subAgentsBaseDataFormErrors[key].hasError) {
        notifyCommonError(state.subAgentsBaseDataFormErrors[key].errorMessage);
        return null;
      }
    }
    return null;
  }

  if (!verifyStatus) {
    return notifyCommonError("Please Verify the Address");
  }

  let entities = userMeta.company_type;
  if (userMeta.company_type == "subagent") {
    entities = "sub_agent";
  }

  const entityData = {
    name: state.subAgentsBaseDataForm.name.value,
    legal_title: state.subAgentsBaseDataForm.legal_title.value,
    registration_code: state.subAgentsBaseDataForm.registration_code.value,
    founding_year: state.subAgentsBaseDataForm.founding_year.value,
    email: state.subAgentsBaseDataForm.email.value,
    url: state.subAgentsBaseDataForm.url.value,
    weekdays: [true, true, true, true, true, false, false],
    working_time_from: "09:00",
    working_time_to: "18:00",
    brief_description_en:
      state.subAgentsBaseDataForm.brief_description_en.value,
    brief_description_cn:
      state.subAgentsBaseDataForm.brief_description_cn.value,
    travel_services: [],
    license_type: [],
    phone: state.subAgentsBaseDataForm.phone.value,
    mobile_phone: state.subAgentsBaseDataForm.mobile_phone.value,
    country_code: state.subAgentsBaseDataForm.country_code.value,
    [entities]: userMeta.company_id,
  };

  //contact person
  const contactPersonData = {
    first_name: state.subAgentsBaseDataForm.first_name.value,
    last_name: state.subAgentsBaseDataForm.last_name.value,
  };
  entityData.contact_person = [contactPersonData];

  // address
  let postal_country_code = "";
  if (state.subAgentsBaseDataForm.postal_country_code.value) {
    postal_country_code =
      state.subAgentsBaseDataForm.postal_country_code.value.split("-")[0];
  }
  const postal_Address_Data = {
    street_number: state.subAgentsBaseDataForm.postal_street_number.value,
    street: state.subAgentsBaseDataForm.postal_street.value,
    country_code: postal_country_code,
    region: state.subAgentsBaseDataForm.postal_region.value,
    city: state.subAgentsBaseDataForm.postal_city.value,
    post_code: state.subAgentsBaseDataForm.postal_post_code.value,
    lat: state.subAgentsBaseDataForm.lat.value,
    lng: state.subAgentsBaseDataForm.lng.value,
  };
  entityData.address = [postal_Address_Data];

  dispatch(loadingSubAgentsBaseDataEditForm());
  dispatch(addNewSubAgentsAPIForm(entityData));
};

export const addNewSubAgentsAPIForm =
  (entityData) => async (dispatch, getState) => {
    try {
      const res = await addNewSubAgentsAPI(filterEmptyValue(entityData));
      const { id } = res;
      const customHistory = createHashHistory();
      customHistory.push(`/network/subAgents/profile/?id=${id}`);
    } catch (error) {
      commonErrorHandle({ error, form: getState().subAgentsBaseDataForm });
    } finally {
      dispatch(idleSubAgentsBaseDataEditForm());
      dispatch(hideGlobalModal());
    }
  };

export const addNewSubAgents = () => async (dispatch, getState) => {
  const tempForm = _.cloneDeep(getState().subAgentsBaseDataForm);
  // add protocol to validate,but no send to backend
  if (tempForm.url.value) {
    tempForm.url.value = "http://" + tempForm.url.value;
  }
  await dispatch(validateSubAgentsBaseDataEditFormData(tempForm));
  dispatch(addNewSubAgentsData());
};

export const validateSubAgentsBaseDataEditFormData = (data) => {
  return {
    type: SUBAGENTS_VALIDATE_BASE_DATA_EDIT_FORM,
    data,
  };
};

const initialOptions = {
  status: {
    choices: [
      { value: "AC", display_name: <TransTxt id="active" startCase /> },
      {
        value: "IN",
        display_name: <TransTxt id="inactive" startCase />,
      },
      {
        value: "CA",
        display_name: <TransTxt id="candidate" startCase />,
      },
    ],
  },
};
export const fetchSubAgentsOptions =
  (langCode) => async (dispatch, getState) => {
    const lang = getState().setLang;

    try {
      const data = await getSubAgentsOptions(langCode || lang);
      const options = _.merge(data.actions.POST, initialOptions);

      dispatch(getSubAgentsOptionsData({ ...options }));
    } catch (error) {
      commonErrorHandle({ error });
    }
  };

export const getSubAgentsOptionsData = (data) => {
  return {
    type: SUBAGENTS_FETCH_OPTIONS,
    data,
  };
};

export const fetchSubAgentsListData = (data) => {
  return {
    type: SUBAGENTS_FETCH_DATA,
    data: data,
  };
};

export const beginSubAgentsListData = () => {
  return { type: SUBAGENTS_BEGIN_FETCH_DATA };
};

export const endSubAgentsListData = () => {
  return { type: SUBAGENTS_END_FETCH_DATA };
};

// subAgents profile

export const submitSubAgentsProfile =
  (fields, uploadingAction, errorAction = [], successAction, alert = true) =>
  async (dispatch, getState) => {
    dispatch(uploadingAction());

    const state = getState();
    const result = submitProcessor(
      state,
      "subAgentsTravelServicesForm",
      travelServicesFrom,
      fields
    );

    if (result.hasError) {
      errorAction.forEach((action) => dispatch(action(result)));
      if (alert) {
        result.errorMsgs.forEach((e) => notifyCommonError(e.msg));
      }
      return null;
    }
    const req = {
      ...result.payload,
      main_service: state.subAgentsMainServicesForm.value,
    };

    try {
      const data = await submitSubAgentsProfileAPI(
        state.subAgentsProfile.id,
        req,
        "patch"
      );
      dispatch({ type: SUBAGENTS_PROFILE_DATA, data: data });
      dispatch(successAction());
      notifyCommonSuccess("Successfully updated your profile.");
    } catch (error) {
      notifyCommonError("Error updating your profile please try again.");
      dispatch(errorAction());
    }
  };

export const getSubAgentsProfileData = (data) => {
  return {
    type: SUBAGENTS_PROFILE_DATA,
    data,
  };
};

export const cleanSubAgentsBaseEditFormErrors = () => {
  return { type: SUBAGENTS_CLEAN_BASE_EDIT_FORM_ERRORS };
};

export const subAgentsProfileData = (data) => (dispatch) => {
  const addresses = _.groupBy(data.address || [], "address_type");
  data.billingAddress = _.get(addresses, "BI.0") || {};
  data.postalAddress = _.get(addresses, "BU.0") || {};
  data.contactPerson = _.get(data, "contact_person.0") || {};
  dispatch(getSubAgentsProfileData(data));
};

export const beginFetchSubAgentsProfile = () => {
  return { type: SUBAGENTS_BEGIN_FETCH_PROFILE_DATA };
};

export const endFetchSubAgentsProfile = () => {
  return { type: SUBAGENTS_END_FETCH_PROFILE_DATA };
};

export const fetchSubAgentsProfile = (id) => async (dispatch) => {
  dispatch(beginFetchSubAgentsProfile());
  try {
    const data = await fetchSubAgentsProfileAPI(id);
    dispatch(subAgentsProfileData(data));
    dispatch(initializeSubAgentsMainServiceForm(data.main_service));
    dispatch(
      initializeSubAgentsTravelServiceForm({ value: data.travel_services })
    );
  } catch (error) {
    commonErrorHandle({ error });
  } finally {
    dispatch(endFetchSubAgentsProfile());
  }
};

export const initializeSubAgentsMainServiceForm = (data) => {
  return {
    type: SUBAGENTS_INITIALIZE_MAIN_SERVICES_FORM,
    data,
  };
};

export const initializeSubAgentsTravelServiceForm = (data) => {
  return {
    type: SUBAGENTS_INITIALIZE_TRAVEL_SERVICE_FORM,
    data,
  };
};

export const hideSubAgentsBaseEditForm = () => {
  return { type: SUBAGENTS_HIDE_BASE_DATA_EDIT_FORM };
};

export const editSubAgentsBaseDataForm = (data) => {
  return { type: SUBAGENTS_EDIT_BASE_DATA, data: data };
};

export const submitSubAgentsBaseDataEditForm = () => (dispatch, getState) => {
  const state = getState();
  const subAgentsProfile = state.subAgentsProfile;
  const verifyStatus = state.isVerifySubAgentsPostalAddress;
  const postalAddress = subAgentsProfile.postalAddress;

  const file = state.subAgentsBaseDataForm.original_image.fileName;
  const formData = new FormData();

  formData.append("original_image", file);
  formData.append("content_type", "subagent");
  formData.append("object_id", state.subAgentsProfile.id);

  let country_code = "";
  if (state.subAgentsBaseDataForm.country_code.value) {
    country_code = state.subAgentsBaseDataForm.country_code.value.split("-")[0];
  }
  const billingAddressData = {
    street_number: state.subAgentsBaseDataForm.street_number.value,
    street: state.subAgentsBaseDataForm.street.value,
    country_code: country_code,
    region: state.subAgentsBaseDataForm.region.value,
    city: state.subAgentsBaseDataForm.city.value,
    post_code: state.subAgentsBaseDataForm.post_code.value,
  };

  let postal_country_code = "";
  if (state.subAgentsBaseDataForm.postal_country_code.value) {
    postal_country_code =
      state.subAgentsBaseDataForm.postal_country_code.value.split("-")[0];
  }

  const postalAddressData = {
    street_number: state.subAgentsBaseDataForm.postal_street_number.value,
    street: state.subAgentsBaseDataForm.postal_street.value,
    country_code: postal_country_code,
    region: state.subAgentsBaseDataForm.postal_region.value,
    city: state.subAgentsBaseDataForm.postal_city.value,
    post_code: state.subAgentsBaseDataForm.postal_post_code.value,
    lat: state.subAgentsBaseDataForm.lat.value || postalAddress.lat,
    lng: state.subAgentsBaseDataForm.lng.value || postalAddress.lng,
  };

  if (state.subAgentsBaseDataFormErrors.valid == false) {
    for (const key in state.subAgentsBaseDataFormErrors) {
      if (state.subAgentsBaseDataFormErrors[key].hasError) {
        notifyCommonError(state.subAgentsBaseDataFormErrors[key].errorMessage);
        return null;
      }
    }
    return null;
  }

  if (
    !postalAddress.lat &&
    !postalAddress.lng &&
    !state.subAgentsBaseDataForm.lat.value &&
    !state.subAgentsBaseDataForm.lng.value
  ) {
    return notifyCommonError("Please Geolocate the map to get exact location");
  }

  if (!verifyStatus) {
    return notifyCommonError("Please Verify the Address");
  }

  let entityData = {
    name: state.subAgentsBaseDataForm.name.value,
    legal_title: state.subAgentsBaseDataForm.legal_title.value,
    registration_code: state.subAgentsBaseDataForm.registration_code.value,
    founding_year: state.subAgentsBaseDataForm.founding_year.value,
    email: state.subAgentsBaseDataForm.email.value,
    phone: state.subAgentsBaseDataForm.phone.value,
    mobile_phone: state.subAgentsBaseDataForm.mobile_phone.value,
    url: state.subAgentsBaseDataForm.url.value,
    weekdays: state.subAgentsBaseDataForm.weekdays.value,
    timezone: state.subAgentsBaseDataForm.timezone.value,
    working_time_from:
      state.subAgentsBaseDataForm.working_time_from.value + ":00",
    working_time_to: state.subAgentsBaseDataForm.working_time_to.value + ":00",
    brief_description_en:
      state.subAgentsBaseDataForm.brief_description_en.value,
    brief_description_cn:
      state.subAgentsBaseDataForm.brief_description_cn.value,
    currency: state.subAgentsBaseDataForm.currency.value,
  };

  // address
  const address = subAgentsProfile.address;
  if (_.get(subAgentsProfile, "billingAddress.id")) {
    filterEmptyValue(
      _.merge(
        _.find(address, { id: _.get(subAgentsProfile, "billingAddress.id") }),
        billingAddressData
      )
    );
  } else {
    if (!isEmptyObj(billingAddressData)) {
      billingAddressData.address_type = "BI";
      address.push(filterEmptyValue(billingAddressData));
    }
  }

  if (_.get(subAgentsProfile, "postalAddress.id")) {
    filterEmptyValue(
      _.merge(
        _.find(address, { id: _.get(subAgentsProfile, "postalAddress.id") }),
        postalAddressData
      )
    );
  } else {
    if (!isEmptyObj(postalAddressData)) {
      postalAddressData.address_type = "BU";
      address.push(filterEmptyValue(postalAddressData));
    }
  }
  entityData.address = address;

  //contact person
  const contactPersonData = {
    first_name: state.subAgentsBaseDataForm.first_name.value,
    last_name: state.subAgentsBaseDataForm.last_name.value,
  };

  if (_.get(subAgentsProfile, "contactPerson.id")) {
    _.merge(
      _.find(subAgentsProfile.contact_person, {
        id: _.get(subAgentsProfile, "contactPerson.id"),
      }),
      contactPersonData
    );
  } else {
    subAgentsProfile.contact_person.push(filterEmptyValue(contactPersonData));
  }
  entityData.contact_person = subAgentsProfile.contact_person;

  entityData = filterEmptyValue(entityData);
  dispatch(loadingSubAgentsBaseDataEditForm());

  if (
    file !== undefined &&
    !(Object.prototype.toString.call(file) === "[object Object]")
  ) {
    uploadImgLink(formData)
      .then((res) => {
        const original_image = res.original_image;
        dispatch(
          uploadSubAgentsPreferenceImage({
            name: "original_image",
            value: original_image,
          })
        );
        dispatch(
          submitSubAgentsProfileForm(entityData, state.subAgentsBaseDataForm)
        );
      })
      .catch((error) => {
        dispatch(idleSubAgentsBaseDataEditForm());
        commonErrorHandle({ error });
      });
  } else {
    dispatch(
      submitSubAgentsProfileForm(entityData, state.subAgentsBaseDataForm)
    );
  }

  return null;
};

export const uploadSubAgentsPreferenceImage = (data) => {
  return {
    type: SUBAGENTS_UPLOAD_PREFERENCE_IMAGE,
    data,
  };
};

export const submitSubAgentsProfileForm =
  (entityData, form) => async (dispatch, getState) => {
    try {
      const data = await submitSubAgentsProfileAPI(
        getState().subAgentsProfile.id,
        entityData,
        "patch"
      );
      dispatch(subAgentsProfileData(data));
      notifyCommonSuccess("Successfully updated your profile.");
    } catch (error) {
      const error400Handle = entityError400Handle(entityData, error.data, form);
      commonErrorHandle({ error, error400Handle });
    } finally {
      dispatch(idleSubAgentsBaseDataEditForm());
      dispatch(hideSubAgentsBaseEditForm());
    }
  };

export const loadingSubAgentsBaseDataEditForm = () => {
  return { type: SUBAGENTS_UPLOADING_BASE_DATA_EDIT_FORM };
};

export const idleSubAgentsBaseDataEditForm = () => {
  return { type: SUBAGENTS_IDLE_BASE_DATA_EDIT_FORM };
};

export const validateSubAgentsBaseDataEditForm = () => (dispatch, getState) => {
  const form = _.cloneDeep(getState().subAgentsBaseDataForm);
  // add protocol to validate,but no send to backend
  if (form.url.value) {
    form.url.value = "http://" + form.url.value;
  }
  dispatch(validateSubAgentsBaseDataEditFormData(form));
  Promise.resolve().then(() => dispatch(submitSubAgentsBaseDataEditForm()));
};

// Base data edit section

export const showSubAgentsBaseEditForm = () => (dispatch, getState) => {
  const profile = getState().subAgentsProfile;
  const address = profile.billingAddress || {};
  const postalAddress = profile.postalAddress || {};
  const subAgentsContactPerson = profile.contactPerson || {};
  const formatCountryCodeList = getState().formatCountryCodeList;

  formatCountryCodeList.forEach((item) => {
    if (item.code && item.code == address.country_code) {
      address.country_code = item.value;
    } else {
      return null;
    }
  });
  formatCountryCodeList.forEach((item) => {
    if (item.code && item.code == postalAddress.country_code) {
      postalAddress.country_code = item.value;
    } else {
      return null;
    }
  });

  const data = update(initialSubAgentsBaseDataForm, {
    name: { value: { $set: profile.name } },
    legal_title: { value: { $set: profile.legal_title } },
    registration_code: { value: { $set: profile.registration_code } },
    founding_year: { value: { $set: profile.founding_year } },
    first_name: { value: { $set: subAgentsContactPerson.first_name } },
    last_name: { value: { $set: subAgentsContactPerson.last_name } },
    email: { value: { $set: profile.email } },
    mobile_phone: { value: { $set: profile.mobile_phone } },
    phone: { value: { $set: profile.phone } },
    url: { value: { $set: profile.url } },
    brief_description_en: { value: { $set: profile.brief_description_en } },
    brief_description_cn: { value: { $set: profile.brief_description_cn } },
    street_number: { value: { $set: address.street_number } },
    street: { value: { $set: address.street } },
    country_code: { value: { $set: address.country_code } },
    region: { value: { $set: address.region } },
    city: { value: { $set: address.city } },
    post_code: { value: { $set: address.post_code } },
    postal_street_number: { value: { $set: postalAddress.street_number } },
    postal_street: { value: { $set: postalAddress.street } },
    postal_country_code: { value: { $set: postalAddress.country_code } },
    postal_region: { value: { $set: postalAddress.region } },
    postal_city: { value: { $set: postalAddress.city } },
    postal_post_code: { value: { $set: postalAddress.post_code } },
    original_image: {
      value: {
        $set: profile.logo ? profile.logo.photo_sm_url : "",
      },
    },
    weekdays: {
      value: {
        $set: profile.weekdays || [
          false,
          false,
          false,
          false,
          false,
          false,
          false,
        ],
      },
    },
    timezone: { value: { $set: profile.timezone } },
    working_time_from: {
      value: {
        $set: parseInt((profile.working_time_from || "0").substr(0, 2)),
      },
    },
    working_time_to: {
      value: {
        $set: parseInt((profile.working_time_to || "0").substr(0, 2)),
      },
    },
    currency: {
      value: {
        $set: profile.currency,
      },
    },
  });

  dispatch(initializeBaseDataEditForm(data));
  dispatch(
    initializeSubAgentsAddressPoint({
      lat: postalAddress.lat || 0,
      lng: postalAddress.lng || 0,
    })
  );
  dispatch(closeSubAgentsVerifyAddress());
  let isSame =
    JSON.stringify({
      street_number: address.street_number,
      street: address.street,
      country_code: address.country_code,
      region: address.region,
      city: address.city,
      post_code: address.post_code,
    }) ==
    JSON.stringify({
      street_number: postalAddress.street_number,
      street: postalAddress.street,
      country_code: postalAddress.country_code,
      region: postalAddress.region,
      city: postalAddress.city,
      post_code: postalAddress.post_code,
    });
  if (
    !address.street_number &&
    !address.street &&
    !address.country_code &&
    !address.region &&
    !address.city &&
    !address.post_code
  ) {
    isSame = false;
  }

  dispatch(showSubAgentsBaseDataEditForm(isSame));
};

export const closeSubAgentsVerifyAddress = () => {
  return {
    type: SUBAGENTS_CLOSE_VERIFY_ADDRESS,
  };
};

export const initializeSubAgentsAddressPoint = (data) => {
  return {
    type: SUBAGENTS_INITIAL_ADDRESS_POINT,
    data,
  };
};

export const showSubAgentsBaseDataEditForm = (data) => {
  return {
    type: SUBAGENTS_SHOW_BASE_DATA_EDIT_FORM,
    data,
  };
};

export const initializeBaseDataEditForm = (data) => {
  return { type: SUBAGENTS_INITIALIZE_BASE_DATA_EDIT_FORM, data: data };
};

export const cleanSubAgentsBaseEditForm = () => (dispatch) => {
  dispatch(cleanSubAgentsBasicBaseEditForm());
  dispatch(cleanSubAgentsMapInfo());
  dispatch(initialSubAgentsVerifyStatus());
};

export const cleanSubAgentsMapInfo = () => {
  return { type: SUBAGENTS_CLEAN_ADDRESS_POINT };
};

export const initialSubAgentsVerifyStatus = () => {
  return { type: SUBAGENTS_INITIAL_VERIFY_STATUS };
};

export const cleanSubAgentsBasicBaseEditForm = () => {
  return { type: SUBAGENTS_CLEAN_BASE_DATA_EDIT_FORM };
};

export const fetchSubAgentsList = (cb) => async (dispatch, getState) => {
  const userMeta = getState().userMeta;
  const obj = {};
  obj[userMeta.company_type] = userMeta.company_id;

  dispatch(beginSubAgentsListData());

  const subAgentsListFilter = _.cloneDeep(getState().subAgentsListFilter);
  const payload = paramsProcessor(subAgentsListFilter);
  const service_type = getState().travelServiceOptions.service_type || {};
  const filterParams = getFilter(payload, service_type);

  const req = {
    id: filterParams.id, // UID
    name__icontains: filterParams.brand_name, // brand name
    phone__contains: filterParams.phone, // Telephone
    mobile_phone__contains: filterParams.mobile_phone, // Contact Mobile
    contact_person__first_name__icontains: filterParams.first_name, // First Name
    contact_person__last_name__icontains: filterParams.last_name, // Last Name
    email__icontains: filterParams.email, // email
    business_address_street__icontains: filterParams.postal_address, // address
    business_address_city__icontains: filterParams.postal_city, // city
    business_address_post_code__icontains: filterParams.postal_post_code, // post code
    business_address_region__icontains: filterParams.postal_region, // region
    business_address_country_code__icontains: filterParams.postal_country, // country
    url__icontains: filterParams.url, // url
    registration_code__icontains: filterParams.registration_code, // registration code
    legal_title__icontains: filterParams.business_name, // business name
    founding_year: filterParams.founding_year, // founding year
    license_type__contains: filterParams.license_type_choices, // license_type
    expertise_type__contains: filterParams.expertise_type, // expertise_type
    entity_type__contains: filterParams.entity_type, // tags
    travel_services__in: filterParams.travel_services, // travel_services
    status__in: filterParams.status, // status
    creator__icontains: filterParams.creator, // creator
    created__gte: filterParams.created__gte, //
    created__lte: filterParams.created__lte, //
    edited__gte: filterParams.edited__gte, //
    edited__lte: filterParams.edited__lte, //
  };

  const filter = filterEmptyValue(req);
  const pagination = getPagination(getState().subAgentsList);

  try {
    const data = await getSubAgentsList({ ...filter, ...pagination, ...obj });
    if (typeof cb == "function") cb();
    dispatch(
      fetchSubAgentsListData({
        page: pagination.page,
        limit: pagination.limit,
        ...data,
      })
    );
  } catch (error) {
    commonErrorHandle({ error });
  } finally {
    dispatch(endSubAgentsListData());
  }
};

export const modifySubAgentsPage = (page) => (dispatch, getState) => {
  let subAgentsList = getState().subAgentsList;
  subAgentsList = update(subAgentsList, { page: { $set: page } });
  dispatch(fetchSubAgentsListData({ ...subAgentsList }));
  dispatch(fetchSubAgentsList());
};

export const modifySubAgentsLimit = (limit) => (dispatch, getState) => {
  let subAgentsList = getState().subAgentsList;
  subAgentsList = update(subAgentsList, {
    limit: { $set: limit },
    page: { $set: 1 },
  });
  dispatch(fetchSubAgentsListData({ ...subAgentsList }));
  dispatch(fetchSubAgentsList());
};

// filter relation
export const updateSubAgentsListFilterForm = (payload) => {
  return { type: SUBAGENTS_UPDATE_LIST_FILTER_FORM, data: payload };
};

export const applySubAgentsListFilter = () => (dispatch, getState) => {
  const state = getState().subAgentsListFilterForm || {};
  dispatch(hideGlobalModal());
  dispatch(applySubAgentsListFilterForm(state));
  dispatch(fetchSubAgentsList());
};

export const applySubAgentsListFilterForm = (data) => {
  return {
    type: SUBAGENTS_APPLY_LIST_FILTER,
    data,
  };
};

export const initializeSubAgentsListFilterForm = () => (dispatch, getState) => {
  const state = getState().subAgentsListFilter || {};
  dispatch(initializeSubAgentsListFilterFormData(state));
};

export const initializeSubAgentsListFilterFormData = (data) => {
  return {
    type: SUBAGENTS_INITIALIZE_LIST_FILTER_FORM,
    data,
  };
};

export const resetSubAgentsListFilter = () => (dispatch, getState) => {
  const data = update(initialSubAgentsFilterForm, {
    $merge: getState().travelServicesForm,
  });
  dispatch(resetSubAgentsListFilterForm(data));
};

export const resetSubAgentsListFilterForm = (data) => {
  return {
    type: SUBAGENTS_RESET_LIST_FILTER_FORM,
    data,
  };
};

// columns relation
export const updateSubAgentsListColumnsForm =
  (field, value) => (dispatch, getState) => {
    const state = getState().subAgentsListColumnsForm || {};
    if (field.type == "push") {
      value = update(new Set(state[field.name].value), { $add: [field.value] });
    }
    if (field.type == "remove") {
      value = update(new Set(state[field.name].value), {
        $remove: [field.value],
      });
    }
    if (field.type == "set") {
      value = field.value;
    }
    const subAgentsListColumnsForm = update(state, {
      [field.name]: { value: { $set: [...value] } },
    });
    dispatch(updateSubAgentsListColumnsFormData(subAgentsListColumnsForm));
  };

export const updateSubAgentsListColumnsFormData = (data) => {
  return {
    type: SUBAGENTS_UPDATE_LIST_COLUMNS_FORM,
    data,
  };
};

export const applySubAgentsListColumns = () => (dispatch, getState) => {
  const state = getState().subAgentsListColumnsForm || {};
  dispatch(hideGlobalModal());
  dispatch(applySubAgentsListColumnsForm(state));
};

export const applySubAgentsListColumnsForm = (data) => {
  return {
    type: SUBAGENTS_APPLY_LIST_COLUMNS,
    data,
  };
};

export const initializeSubAgentsListColumnsForm =
  () => (dispatch, getState) => {
    const currentCols = getState().subAgentsListColumns;
    dispatch(initializeSubAgentsListColumnsFormData(currentCols));
  };

export const initializeSubAgentsListColumnsFormData = (data) => {
  return {
    type: SUBAGENTS_INITIALIZE_LIST_COLUMNS_FORM,
    data,
  };
};

// Nature of Biz section.
export const viewSubAgentsNatureSection = () => {
  return { type: SUBAGENTS_VIEW_NATURE_OF_BIZ };
};

export const editSubAgentsNatureSection =
  (initForm = true) =>
  (dispatch, getState) => {
    if (initForm) {
      const profile = getState().subAgentsProfile;

      const data = update(initialSubAgentsNatureOfBizForm, {
        entity_type: { value: { $set: profile.entity_type || [] } },
        expertise_type: { value: { $set: profile.expertise_type || [] } },
        license_type: { value: { $set: profile.license_type || [] } },
      });

      dispatch(initializeNatureDataForm(data));
    }
    dispatch(editSubAgentsBaseNatureDataForm());
  };

export const editSubAgentsBaseNatureDataForm = () => {
  return {
    type: SUBAGENTS_EDIT_NATURE_OF_BIZ,
  };
};

export const initializeNatureDataForm = (data) => {
  return { type: SUBAGENTS_INITIALIZE_NATURE_DATA_FORM, data: data };
};

export const editBaseNatureDataForm = (data) => {
  return { type: SUBAGENTS_EDIT_NATURE_OF_BIZ_DATA, data: data };
};

export const submitSubAgentsNatureOfBizData =
  () => async (dispatch, getState) => {
    dispatch(loadingSubmitSubAgentsNatureOfBizData());
    const state = getState();
    const entityId = state.subAgentsProfile.id;
    const result = submitProcessor(
      state,
      "subAgentsNatureOfBizForm",
      initialSubAgentsNatureOfBizForm
    );

    if (result.hasError) {
      dispatch(editSubAgentsNatureSection(false));
      result.errorMsgs.forEach((e) => notifyCommonError(e.msg));
      return null;
    }

    try {
      const data = await submitSubAgentsProfileAPI(
        entityId,
        result.payload,
        "patch"
      );
      dispatch(subAgentsProfileData(data));
      notifyCommonSuccess("Successfully updated Nature of Business.");
    } catch (error) {
      notifyCommonError(
        "Error submitting Nature of Business data. Please try again."
      );
    } finally {
      dispatch(viewSubAgentsNatureSection());
    }
  };

export const loadingSubmitSubAgentsNatureOfBizData = () => {
  return {
    type: SUBAGENTS_SUBMIT_NATURE_OF_BIZ_DATA,
  };
};

export const subAgentsLicenseCodeEdit = (data) => {
  return { type: SUBAGENTS_EDIT_LICENSE_CODE, data: data };
};

export const synchronizeSubAgentsPostalAddress =
  (checked) => (dispatch, getState) => {
    const form = getState().subAgentsBaseDataForm;

    const data = update(form, {
      street_number: { value: { $set: form.postal_street_number.value } },
      street: { value: { $set: form.postal_street.value } },
      country_code: { value: { $set: form.postal_country_code.value } },
      region: { value: { $set: form.postal_region.value } },
      city: { value: { $set: form.postal_city.value } },
      post_code: { value: { $set: form.postal_post_code.value } },
    });
    dispatch({ type: SUBAGENTS_TOGGLE_POSTAL_ADDRESS, data: checked });
    if (checked) {
      dispatch(initializeBaseDataEditForm(data));
    }
  };

// img relation
export const changeSubAgentsImg = (data) => (dispatch) => {
  dispatch(changeUploadImgFactory(data, SUBAGENTS_UPLOAD_PREFERENCE_IMAGE));
};

export const applySubAgentsDefaultOption = () => (dispatch, getState) => {
  let form = getState().subAgentsListColumnsForm;

  for (const key in defaultOption) {
    form = update(form, {
      [key]: { value: { $set: defaultOption[key].value } },
    });
  }
  dispatch(applySubAgentsDefaultOptionForm(form));
};

export const applySubAgentsDefaultOptionForm = (data) => {
  return {
    type: SUBAGENTS_UPDATE_LIST_COLUMNS_FORM,
    data,
  };
};

//fetchGeolocation

export const beginSubAgentsAddressPoint = () => {
  return { type: SUBAGENTS_BEGIN_FETCH_ADDRESS_DATA };
};

export const endSubAgentsAddressPoint = () => {
  return { type: SUBAGENTS_END_FETCH_ADDRESS_DATA };
};

export const fetchSubAgentsAddressPoint = () => (dispatch, getState) => {
  const state = getState();
  const address = state.subAgentsBaseDataForm;
  dispatch(
    fetchCompleteAddressFactory(address, fetchSubAgentsAddressPointDetail)
  );
};

export const fetchSubAgentsAddressPointDetail = (data) => (dispatch) => {
  dispatch(
    fetchAddressPointDetailFactory(
      data,
      SUBAGENTS_FETCH_ADDRESS_POINT,
      editSubAgentsBaseDataForm,
      beginSubAgentsAddressPoint,
      endSubAgentsAddressPoint
    )
  );
};

export const verifySubAgentsPostalAddress = (checked) => {
  return {
    type: SUBAGENTS_TOGGLE_VERIFY_ADDRESS,
    data: checked,
  };
};

//======================== User Email Config Section========================

export const viewSubAgentsEmailConfigSection = () => {
  return {
    type: SUBAGENTS_VIEW_EMAIL_CONFIG_MODE,
  };
};

export const editSubAgentsEmailConfigSection = () => {
  return { type: SUBAGENTS_EDIT_EMAIL_CONFIG_MODE };
};

export const loadingSubAgentsEmailConfigMode = () => {
  return { type: SUBAGENTS_LOADING_EMAIL_CONFIG_MODE };
};

export const editSubAgentsEmailConfigDataForm = (data) => {
  return {
    type: SUBAGENTS_EDIT_EMAIL_CONFIG_FORM,
    data,
  };
};

export const initializeBaseEmailConfigDataForm = () => (dispatch, getState) => {
  const state = getState();
  const res = state.subAgentsProfile.allowed_email_info;
  if (res) {
    dispatch(initializeBaseEmailConfigData({ email_config: { value: res } }));
  }
};

export const initializeBaseEmailConfigData = (data) => {
  return {
    type: SUBAGENTS_INITIALIZE_EMAIL_CONFIG_FORM,
    data,
  };
};

export const submitSubAgentsEmailConfigData =
  () => async (dispatch, getState) => {
    const state = getState();
    const profile_id = state.subAgentsProfile.id;
    const form = state.subAgentsEmailConfigForm;
    const res = form.email_config.value;
    const payload = {
      allowed_email_info: res,
    };
    dispatch(loadingSubAgentsEmailConfigMode());
    try {
      const data = await submitSubAgentsProfileAPI(
        profile_id,
        payload,
        "patch"
      );
      notifyCommonSuccess("Successfully updated your profile.");
      dispatch(getSubAgentsProfileData(data));
    } catch (error) {
      console.log(error);
      if (error.status == 500) {
        notifyCommonError(
          "Failed updated your profile, we are sorry for this inconvenience"
        );
      }
      commonErrorHandle({ error });
    } finally {
      dispatch(viewSubAgentsEmailConfigSection());
    }
  };
