import _ from "lodash";
import { variables } from "@src/jsssetup";
import PropTypes from "prop-types";
import React from "react";
import { createUseStyles } from "react-jss";
import { Button, Loader, Pagination, SelectPicker } from "rsuite";
import TransTxt from "@src/components/common/SxFormatMessage";
import { Form, Formik } from "formik";
import { NormalSelectField } from "@src/components/forms";
import { Icon } from "@iconify/react";

const orderingStyles = createUseStyles({
  Ordering: {
    paddingLeft: variables.half_gap,
  },
  selectGroup: {
    display: "grid",
    gridTemplateColumns: "repeat( 2, max-content )",
    gridGap: variables.half_gap,
    alignItems: "center",
  },
  label: {
    justifySelf: "center",
    lineHeight: "1.6rem",
  },
  col2: {
    display: "grid",
    gridTemplateColumns: "repeat( 2, max-content )",
    gridGap: variables.half_gap,
  },
});
const Ordering = ({
  ordering,
  orderingOptions,
  directionOptions,
  onChange,
}) => {
  const classes = orderingStyles();
  const customStyles = {
    input: {
      height: "1.6rem",
      padding: "1px",
      borderColor: variables.colors.lightGrey,
      color: variables.colors.easy.orange,
    },
  };

  const direction = !ordering ? "" : ordering.startsWith("-") ? "DS" : "AS";

  return (
    <div className={classes.Ordering}>
      <Formik
        enableReinitialize={true}
        initialValues={{ ordering: _.last(ordering.split("-")), direction }}
        validateOnMount={false}
        validateOnBlur={false}
        validate={(values) => {
          if (values.ordering === "" || values.direction === "") {
            return;
          }

          onChange(
            values.direction === "DS" ? `-${values.ordering}` : values.ordering
          );
        }}>
        <Form className={classes.col2}>
          <div className={classes.selectGroup}>
            <span className={classes.label}>
              <TransTxt id="Ordering__order_by" />
            </span>
            <NormalSelectField
              name="ordering"
              options={[["", "------"], ...orderingOptions]}
              customStyles={customStyles}
            />
          </div>
          <div className={classes.selectGroup}>
            <span className={classes.label}>
              <TransTxt id="Ordering__direction" />
            </span>
            <NormalSelectField
              name="direction"
              options={directionOptions}
              customStyles={customStyles}
            />
          </div>
        </Form>
      </Formik>
    </div>
  );
};
Ordering.defaultProps = {
  ordering: "",
  orderingOptions: [],
  directionOptions: [
    ["", "-----"],
    ["AS", "Ascending"],
    ["DS", "Descending"],
  ],
};
Ordering.propTypes = {
  ordering: PropTypes.string.isRequired,
  orderingOptions: PropTypes.array.isRequired,
  directionOptions: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

const detailPageHeaderStyles = createUseStyles({
  header: {
    padding: variables.normal_gap,
    paddingBottom: "0px",
    display: "grid",
    gridTemplateColumns: "1fr auto",
    placeItems: "center",
    background: variables.colors.background.creme,
  },
  headerTag: {
    padding: variables.normal_gap,
    background: variables.colors.background.dark,
    color: variables.colors.lightTextColor,
    borderRadius: "5px 5px 0px 0px",
    justifySelf: "start",
  },
  headerActions: {
    display: "grid",
    gridAutoFlow: "column",
    gridGap: variables.half_gap,
  },
});
export const ListPageHeader = ({ title, onRefresh, children }) => {
  const classes = detailPageHeaderStyles();

  return (
    <div className={classes.header}>
      <div className={classes.headerTag}>
        <strong>{title}</strong>
      </div>
      <div className={classes.headerActions}>
        {typeof onRefresh === "function" && (
          <Button size="xs" color="green" onClick={onRefresh}>
            <strong>{<TransTxt id="ListPageHeader__refresh_list" />}</strong>
          </Button>
        )}
        {children}
      </div>
    </div>
  );
};
ListPageHeader.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.node,
  ]).isRequired,
  onRefresh: PropTypes.func,
  children: PropTypes.node,
};

const controlStripStyles = createUseStyles({
  controlStrip: {
    display: "grid",
    gridTemplateColumns: ({ hasOrdering, hasDisplay }) => {
      var result = "max-content max-content 1fr max-content";
      if (hasOrdering) {
        result = "max-content " + result;
      }
      if (hasDisplay) {
        result = "max-content " + result;
      }
      return result;
    },
    gridGap: variables.normal_gap,
    padding: variables.normal_gap,
    placeItems: "center",
    justifyContent: "space-between",
    background: "white",
    width: "max-content",
  },
  displayControl: {
    display: "grid",
    gridAutoFlow: "column",
    gridGap: variables.half_gap,
    fontSize: "1rem",
  },
  paginationControl: {
    justifySelf: "start",
  },
  totalResults: {
    "justifySelf": "center",
    "&> span": {
      color: variables.colors.text.colored,
    },
  },
  actions: {
    display: "grid",
    gridAutoFlow: "column",
    gridGap: `calc(${variables.normal_gap} / 2)`,
  },
  [`@media ${variables.media.smallscreen}`]: {
    controlStrip: {
      width: "100%",
      gridTemplateColumns: ({ hasOrdering, hasDisplay }) => {
        var result = "max-content max-content 1fr max-content";
        if (hasOrdering) {
          result = "max-content " + result;
        }
        if (hasDisplay) {
          result = "max-content " + result;
        }
        return result;
      },
    },
  },
});
export const ControlStrip = ({
  page,
  limit,
  count,
  ordering,
  isFetching,
  display,
  pageOptions,
  orderingOptions,
  directionOptions,
  displayOptions,
  onChangeLimit,
  onChangePage,
  onChangeOrdering,
  onChangeDisplay,
  children,
}) => {
  const pages = Math.ceil(count / limit);
  const classes = controlStripStyles({
    hasOrdering: !!orderingOptions.length,
    hasDisplay: !!displayOptions.length,
  });

  const iconMapping = {
    tile: variables.icons.tile,
    table: variables.icons.table,
  };

  return (
    <div className={classes.controlStrip}>
      {!_.isEmpty(displayOptions) && (
        <div className={classes.displayControl}>
          {displayOptions.map((displayOption, idx) => {
            if (!iconMapping?.[displayOption]) {
              return null;
            }
            return (
              <button
                key={`displayOption-${idx}`}
                className="Button"
                data-ghost={String(display !== displayOption)}
                data-size="xs"
                onClick={() => onChangeDisplay(displayOption)}>
                <Icon icon={iconMapping?.[displayOption]} />
              </button>
            );
          })}
        </div>
      )}
      <div className={classes.paginationControl}>
        <SelectPicker
          searchable={false}
          size="xs"
          data={pageOptions.map((p) => ({ label: p, value: p }))}
          value={limit}
          cleanable={false}
          onChange={(value) => onChangeLimit(value)}
        />
      </div>
      {orderingOptions.length >= 1 && (
        <Ordering
          ordering={ordering}
          orderingOptions={orderingOptions}
          directionOptions={directionOptions}
          onChange={(value) => onChangeOrdering(value)}
        />
      )}
      <div className={classes.totalResults}>
        <TransTxt id="Ordering__total_results" /> <span>{count}</span>
      </div>
      <div className={classes.paginator}>
        <Pagination
          prev
          last
          next
          first
          size="xs"
          total={count}
          limit={limit}
          activePage={page}
          pages={pages}
          maxButtons={10}
          onSelect={(page) => onChangePage(page)}
        />
      </div>
      <div className={classes.actions}>
        {isFetching && <Loader />}
        {children}
      </div>
    </div>
  );
};
ControlStrip.defaultProps = {
  isFetching: false,
  count: 0,
  page: 0,
  limit: 0,
  ordering: "",
  pageOptions: [10, 20, 50],
  orderingOptions: [],
  displayOptions: [],
  directionOptions: [
    ["", "-----"],
    ["AS", "Ascending"],
    ["DS", "Descending"],
  ],
};
ControlStrip.propTypes = {
  isFetching: PropTypes.bool,
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  ordering: PropTypes.string.isRequired,
  display: PropTypes.string,
  displayOptions: PropTypes.array,
  children: PropTypes.node,
  pageOptions: PropTypes.array,
  orderingOptions: PropTypes.array,
  directionOptions: PropTypes.array,
  onChangeLimit: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeOrdering: PropTypes.func,
  onChangeDisplay: PropTypes.func,
};
