import { SISI_API_URL } from "@src/api";
import service, { getAuthHeaders } from "@src/api/request";
import axios from "axios";

export function getTransferList(para) {
  return service({
    url: "bid/transferreservation/",
    method: "get",
    params: para,
  });
}

export function getTransferOptions(para) {
  return service({
    url: "bid/transferreservation/",
    method: "options",
    params: para,
  });
}

export function fetchTransferReservationInfoAPI(reference) {
  return service({
    url: `bid/transferreservation/${reference}`,
    method: "get",
  });
}

export function submitTransferReservationChange(reference, payload) {
  return service({
    url: `bid/transferreservation/${reference}/`,
    method: "patch",
    data: payload,
  });
}

export async function getTransferStats(params) {
  const auth_headers = getAuthHeaders();
  return await axios.get(`${SISI_API_URL}/reservations/transfers/stats/`, {
    params,
    headers: { ...auth_headers },
  });
}

//=========================TRANSFER DASHBOARDS================================
export const getTransferReservationsGraphData = (payload) => {
  return service({
    url: "/bid/transfer-stats/",
    method: "get",
    params: payload,
  });
};
