import { InputGroup, AutoComplete, Icon } from "rsuite";

import { destAutoComplete } from "@src/api/Project/TripPlanner";

import update from "immutability-helper";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { lang_mapping } from "@src/tools/lang_tools";
import { fastDestinationAutocomplete } from "@src/api/cms";

export class DestAutoComplete extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      searching: false,
      value: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.autocomplete = _.debounce(this.autocomplete.bind(this), 200);
  }
  componentDidMount() {
    const { value, id } = this.props;

    if (value && id) {
      this.setState((p) => update(p, { value: { $set: value } }));
    }
  }
  componentDidUpdate(prevProps) {
    const { value, id } = this.props;
    if (prevProps.id !== id) {
      this.setState((p) => update(p, { value: { $set: value || "" } }));
    }
  }
  async autocomplete(query) {
    const { lang } = this.props;

    if (lang == "en" && query.length < 3) {
      return;
    } else if (lang == "cn" && query.length < 1) {
      return;
    }

    function returner(dest) {
      return {
        id: dest.id,
        type: dest.type,
        value: `${dest.id}__${dest.destination}`,
        label: [...new Set(dest.destination.split("-"))].join(" - "),
      };
    }

    const autocompleter = async () => {
      this.setState(
        (p) => update(p, { searching: { $set: true } }),
        async () => {
          try {
            const data = await fastDestinationAutocomplete({ name: query });

            this.setState((p) => update(p, { searching: { $set: false } }));
            if (data.count === 0) {
              return;
            }

            this.setState((p) =>
              update(p, {
                data: {
                  $set: data.destinations.map((dest) => returner(dest)).sort(),
                },
              })
            );
          } catch (error) {
            if (error.status == 408) {
              this.setState((p) => update(p, { searching: { $set: false } }));
            }
          }
        }
      );
    };

    autocompleter();
  }
  async handleChange(query) {
    this.setState(
      (p) => update(p, { value: { $set: query } }),
      async () => {
        await this.autocomplete(query);
      }
    );
  }
  handleSelect(item) {
    const { onSelect } = this.props;

    this.setState((p) => update(p, { value: { $set: item.label } }));
    if (typeof onSelect == "function") {
      onSelect(item.id, item.type, item.label);
    }
  }
  render() {
    const { data, searching, value } = this.state;
    const { disabled } = this.props;

    return (
      <InputGroup inside>
        <AutoComplete
          disabled={disabled}
          data={data}
          value={value}
          onChange={(query, event) => {
            event.persist();

            if (event.type == "change") {
              this.handleChange(query);
            }
          }}
          onSelect={(item) => this.handleSelect(item)}
          renderItem={function (item) {
            {
              return <p>{item.label}</p>;
            }
          }}
        />
        <InputGroup.Button>
          <Icon icon={searching ? "spinner" : "search"} spin={searching} />
        </InputGroup.Button>
      </InputGroup>
    );
  }
}

DestAutoComplete.defaultProps = {
  disabled: false,
};

DestAutoComplete.propTypes = {
  // Both value and id are used to update the input during UI rerenderings
  // when multiple autocomplete components are used. In case of only one
  // component shown there is no need to provide them.
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  value: PropTypes.string,
  lang: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};
