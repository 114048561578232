//==============LIST=======================
export const SALES_BEGIN_FETCH_DATA = "SALES_BEGIN_FETCH_DATA";
export const SALES_FETCH_DATA = "SALES_FETCH_DATA";
export const SALES_END_FETCH_DATA = "SALES_END_FETCH_DATA";

//==================================FILTER RELATION================================
export const SALES_INITIALIZE_LIST_FILTER_FORM =
  "SALES_INITIALIZE_LIST_FILTER_FORM";
export const SALES_UPDATE_LIST_FILTER_FORM = "SALES_UPDATE_LIST_FILTER_FORM";
export const SALES_APPLY_LIST_FILTER = "SALES_APPLY_LIST_FILTER";

//================================== COLUMNS RELATION====================================
export const SALES_INITIALIZE_LIST_COLUMNS_FORM =
  "SALES_INITIALIZE_LIST_COLUMNS_FORM";
export const SALES_APPLY_LIST_COLUMNS = "SALES_APPLY_LIST_COLUMNS";

//==================================GRAPH================================
export const SALES_INIT_VIEW_TYPE = "SALES_INIT_VIEW_TYPE";
export const SALES_CHANGE_VIEW_TYPE = "SALES_CHANGE_VIEW_TYPE";
export const SALES_FETCH_GRAPH_DATA = "SALES_FETCH_GRAPH_DATA";
export const SALES_BEGIN_FETCH_GRAPH_DATA = "SALES_BEGIN_FETCH_GRAPH_DATA";
export const SALES_END_FETCH_GRAPH_DATA = "SALES_END_FETCH_GRAPH_DATA";
