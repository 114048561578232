import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Drawer, Icon, IconButton } from "rsuite";
import _ from "lodash";
import { filtersSchema, usePageLimit } from "./helpers";
import CarRental from "./components/CarRental";
import { FiltersBar } from "./components/FiltersBar";
import { CarRentalsPrice } from "./components/CarRentalsPrice";

const noResultsSvg = (
  <svg
    className="carRentals-drawer-body__content--noCars__svg"
    width="119"
    height="130"
    viewBox="0 0 119 130"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.3333 109L44.2511 93H68.4154L98.3333 109H14.3333Z"
      fill="#C7C7C7"
      fillOpacity="0.3"
    />
    <path
      d="M59.3498 19.7842C60.1144 21.6498 60.6597 23.6265 60.9541 25.6829L60.4591 25.7538C60.6224 26.8948 60.7069 28.0611 60.7069 29.2472C60.7069 30.4332 60.6224 31.5995 60.4591 32.7405L60.9541 32.8114C60.6597 34.8678 60.1144 36.8445 59.3498 38.7101L58.8872 38.5205C57.9946 40.6983 56.7968 42.7222 55.3459 44.5402L55.7367 44.852C54.4631 46.4479 52.9985 47.8879 51.3771 49.1384L51.0717 48.7425C49.2352 50.1589 47.1935 51.3272 44.9981 52.1966L45.1822 52.6615C43.3105 53.4027 41.3293 53.9309 39.2692 54.2158L39.2007 53.7205C38.0583 53.8785 36.891 53.9602 35.704 53.9602C34.5171 53.9602 33.3498 53.8785 32.2074 53.7205L32.1389 54.2158C30.0788 53.9309 28.0976 53.4027 26.2259 52.6615L26.41 52.1966C24.2146 51.3272 22.1729 50.1589 20.3363 48.7425L20.031 49.1384C18.4096 47.8879 16.945 46.4479 15.6714 44.852L16.0622 44.5402C14.6113 42.7222 13.4135 40.6983 12.5209 38.5205L12.0583 38.7101C11.2936 36.8445 10.7484 34.8678 10.454 32.8114L10.949 32.7405C10.7857 31.5995 10.7012 30.4332 10.7012 29.2472C10.7012 28.0611 10.7857 26.8948 10.949 25.7538L10.454 25.6829C10.7484 23.6265 11.2936 21.6498 12.0583 19.7842L12.5209 19.9738C13.4135 17.796 14.6113 15.7721 16.0622 13.9542L15.6714 13.6423C16.945 12.0464 18.4096 10.6064 20.031 9.35588L20.3363 9.75181C22.1729 8.33542 24.2146 7.16709 26.41 6.29769L26.2259 5.83281C28.0976 5.0916 30.0788 4.56339 32.1389 4.27849L32.2074 4.77378C33.3498 4.6158 34.5171 4.53409 35.704 4.53409C36.891 4.53409 38.0583 4.6158 39.2007 4.77378L39.2692 4.27849C41.3293 4.56339 43.3105 5.0916 45.1822 5.83281L44.9981 6.29769C47.1935 7.16709 49.2352 8.33542 51.0717 9.75181L51.3771 9.35588C52.9985 10.6064 54.4631 12.0464 55.7367 13.6423L55.3459 13.9542C56.7968 15.7721 57.9946 17.796 58.8872 19.9738L59.3498 19.7842Z"
      stroke="black"
      strokeOpacity="0.2"
      strokeDasharray="7 6"
    />
    <path
      opacity="0.3"
      d="M62.5507 46.7117C61.1422 46.7117 59.8421 47.0486 58.6502 47.6187C57.5939 43.4982 53.9101 40.4402 49.4679 40.4402H49.4137C48.6553 34.6871 43.7526 30.2557 37.8478 30.2557C31.4012 30.2557 26.1735 35.5164 26.1735 41.9951C26.1735 43.0058 26.3089 43.9647 26.5527 44.8976C23.5461 45.0531 21.027 46.9449 19.9706 49.6141C18.7247 48.6812 17.2078 48.137 15.5284 48.137C11.7905 48.137 8.70262 50.884 8.16089 54.4602H71.408C70.8392 50.0806 67.1012 46.6857 62.5778 46.6857L62.5507 46.7117Z"
      fill="#B4B4B4"
    />
    <path
      d="M102.13 56.4637C101.818 56.4637 101.49 56.3821 101.207 56.2191L96.6955 53.5964C96.1743 53.2975 95.8765 52.7675 95.8914 52.1968C95.9212 51.626 96.2637 51.1232 96.8146 50.865L101.535 48.5957C102.086 48.3239 102.726 48.3511 103.247 48.6636C103.768 48.9626 104.066 49.4926 104.051 50.0633L103.843 54.9553C103.813 55.526 103.471 56.0288 102.92 56.287C102.666 56.4093 102.398 56.4773 102.116 56.4773L102.13 56.4637ZM102.354 49.7915C102.354 49.7915 102.294 49.7915 102.25 49.8187L97.5293 52.088C97.44 52.1288 97.4251 52.1968 97.4102 52.2511C97.4102 52.3055 97.4102 52.3734 97.5144 52.4278L102.026 55.0504C102.116 55.1048 102.19 55.0776 102.235 55.0504C102.279 55.0233 102.339 54.9825 102.354 54.8874L102.562 49.9953C102.562 49.9002 102.518 49.8459 102.458 49.8187C102.428 49.8051 102.384 49.7915 102.339 49.7915H102.354Z"
      fill="#C8C8C8"
    />
    <path
      opacity="0.3"
      d="M91.1783 83.381C88.1579 83.381 85.6897 80.9676 85.6897 77.9973C85.6897 75.027 88.1579 72.6136 91.1783 72.6136C94.1988 72.6136 96.6669 75.027 96.6669 77.9973C96.6669 80.9676 94.1988 83.381 91.1783 83.381ZM91.1783 75.5839C89.8321 75.5839 88.7274 76.6641 88.7274 77.9973C88.7274 79.3306 89.8321 80.4107 91.1783 80.4107C92.5246 80.4107 93.6292 79.3306 93.6292 77.9973C93.6292 76.6641 92.5246 75.5839 91.1783 75.5839Z"
      fill="#AAAAAA"
    />
    <path
      d="M27.5431 36.3068C29.219 36.3068 30.6035 34.9381 30.6035 33.2812C30.6035 31.6244 29.2433 30.2557 27.5431 30.2557C25.8429 30.2557 24.4828 31.6244 24.4828 33.2812C24.4828 34.9381 25.8429 36.3068 27.5431 36.3068Z"
      fill="#B4B4B4"
    />
    <path
      d="M20.6483 66.1356C20.3204 65.8149 20.3204 65.3086 20.6483 64.9879L24.9805 60.7518C25.3084 60.4312 25.8262 60.4312 26.1542 60.7518C26.4821 61.0725 26.4821 61.5788 26.1542 61.8995L21.822 66.1356C21.494 66.4562 20.9762 66.4562 20.6483 66.1356Z"
      fill="#AAAAAA"
    />
    <path
      d="M24.9803 66.1187L20.6654 61.8995C20.3374 61.5788 20.3374 61.0725 20.6654 60.7518C20.9933 60.4312 21.5111 60.4312 21.8391 60.7518L26.154 64.9711C26.4819 65.2917 26.4819 65.798 26.154 66.1187C25.8261 66.4393 25.3083 66.4393 24.9803 66.1187Z"
      fill="#AAAAAA"
    />
    <path
      d="M86.4167 26.4167H83.5834V17.9167H86.4167M86.4167 32.0833H83.5834V29.25H86.4167M85 10.8333C83.1396 10.8333 81.2975 11.1998 79.5787 11.9117C77.8599 12.6237 76.2982 13.6672 74.9827 14.9827C72.3259 17.6394 70.8334 21.2428 70.8334 25C70.8334 28.7572 72.3259 32.3606 74.9827 35.0174C76.2982 36.3329 77.8599 37.3764 79.5787 38.0883C81.2975 38.8002 83.1396 39.1667 85 39.1667C88.7573 39.1667 92.3606 37.6741 95.0174 35.0174C97.6742 32.3606 99.1667 28.7572 99.1667 25C99.1667 23.1396 98.8003 21.2974 98.0883 19.5787C97.3764 17.8599 96.3329 16.2982 95.0174 14.9827C93.7019 13.6672 92.1402 12.6237 90.4214 11.9117C88.7026 11.1998 86.8604 10.8333 85 10.8333Z"
      fill="#B4B4B4"
    />
    <path
      d="M75.3378 60.7775C74.7933 59.155 73.2689 58 71.4722 58H41.5278C39.7311 58 38.2339 59.155 37.6622 60.7775L32 77.25V99.25C32 100.763 33.225 102 34.7222 102H37.4444C38.9417 102 40.1667 100.763 40.1667 99.25V96.5H72.8333V99.25C72.8333 100.763 74.0583 102 75.5556 102H78.2778C79.775 102 81 100.763 81 99.25V77.25L75.3378 60.7775ZM41.5278 88.25C39.2683 88.25 37.4444 86.4075 37.4444 84.125C37.4444 81.8425 39.2683 80 41.5278 80C43.7872 80 45.6111 81.8425 45.6111 84.125C45.6111 86.4075 43.7872 88.25 41.5278 88.25ZM71.4722 88.25C69.2128 88.25 67.3889 86.4075 67.3889 84.125C67.3889 81.8425 69.2128 80 71.4722 80C73.7317 80 75.5556 81.8425 75.5556 84.125C75.5556 86.4075 73.7317 88.25 71.4722 88.25ZM37.4444 74.5L41.5278 62.125H71.4722L75.5556 74.5H37.4444Z"
      fill="#B4B4B4"
    />
  </svg>
);

export const OverviewAllCarRentals = ({ closeCarRentalsDrawer }) => {
  const { pageLimit, increasePageSize } = usePageLimit();
  const [filters, setFilters] = useState(filtersSchema.cast({}));

  const carRentals = {
    results: [
      {
        vehicle: {
          door_count: 2,
          model_year: null,
          name: "Renault Twingo",
          brand_code: "MBMR",
          code: "Renault Twingo",
          image:
            "https://vehicles.carhire-solutions.com/Budget/small/t_MBMR_FR.jpg",
          transmission_type: "Manual",
          fuel_type: "Unspecified",
          passenger_quantity: 4,
          baggage_quantity: 2,
          has_air_condition: true,
        },
        provider: {
          name: "Budget",
          code: "ZD",
        },
        price: {
          currency: "EUR",
          amount: "145.80",
        },
        pick_up: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "Bd Beauvais Apt",
          locationCode: null,
        },
        drop_off: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "Bd Beauvais Apt",
          locationCode: null,
        },
        inclusions: [
          {
            coverage_type: "Airport Service Charge",
            code: "407",
            description: "Facility charge at commercial airports.",
            currency: "EUR",
            amount: 37.5,
          },
          {
            coverage_type: "Vehicle Licence Fee / Road Fund Licence",
            code: "402",
            description:
              "Licensing and other vehicle-related taxes charged by local governments.",
            currency: "EUR",
            amount: 15.4,
          },
          {
            coverage_type: "Kilometer inclusive: 1250 km (0.30 EUR/km)",
            code: "416",
            description: "per km: .30 EUR",
            currency: "EUR",
            amount: 0.3,
          },
          {
            coverage_type: "Other taxes and service charges",
            code: "418",
            description: "per rental: 24.30 EUR",
            currency: "EUR",
            amount: 24.3,
          },
          {
            coverage_type: "Collision damage waiver",
            code: "CDW",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Theft protection",
            code: "TP",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Fuel Information",
            code: "F2F",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "VAT",
            code: "TAX",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Cancellation fee",
            code: "CF",
            description:
              "Cancellation is free of charge until 08 Nov 2023 13:30. A cancellation fee of 100 percent will be charged from 08 Nov 2023 13:30 until 10 Nov 2023 13:30. A no show fee of 100 percent will be charged.",
            currency: "EUR",
            amount: 0,
          },
        ],
        booking_id: "-YW7z95zfk2O5epudAHSpA-66",
        api_uid: "66357eaf-1761-48e5-ab58-0c69f7a95c9a",
        supplier: "carnect",
        session_id: "610b57ee-77b5-4245-9bcc-1987cb90353a",
        is_own: true,
        is_parent_own: true,
      },
      {
        vehicle: {
          door_count: 2,
          model_year: null,
          name: "A FIAT 500",
          brand_code: "MCMR",
          code: "A FIAT 500",
          image:
            "https://vehicles.carhire-solutions.com/Thrifty Europe/small/t_MCMR_FR.jpg",
          transmission_type: "Manual",
          fuel_type: "Unspecified",
          passenger_quantity: 4,
          baggage_quantity: 2,
          has_air_condition: false,
        },
        provider: {
          name: "Thrifty Europe",
          code: "TO",
        },
        price: {
          currency: "EUR",
          amount: "158.40",
        },
        pick_up: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "BEAUVAIS AIRPORT",
          locationCode: null,
        },
        drop_off: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "BEAUVAIS AIRPORT",
          locationCode: null,
        },
        inclusions: [
          {
            coverage_type: "Airport Service Charge",
            code: "407",
            description: "Facility charge at commercial airports.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Vehicle Licence Fee / Road Fund Licence",
            code: "402",
            description:
              "Licensing and other vehicle-related taxes charged by local governments.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Unlimited Mileage",
            code: "405",
            description: "No further costs apply for extra miles driven",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Stay Safe Initiative",
            code: "55",
            description:
              "As the world faces an unprecedented health crisis, our car rental suppliers have put special measures in place to provide you with a safe and flexible travel experience. The utmost attention is being paid to cleaning the vehicles. Additional to the high standards of cleaning there is now an enhanced process in place, which involves the sanitizing of every vehicle between each rental using detergent cleaning products to protect all customers and add peace of mind.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Collision damage waiver",
            code: "CDW",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Supplementary Liability Insurance",
            code: "SLI",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Theft protection",
            code: "TP",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Fuel Information",
            code: "F2F",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "VAT",
            code: "TAX",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Cancellation fee",
            code: "CF",
            description:
              "Cancellation is free of charge until 08 Nov 2023 13:30. A cancellation fee of 100 percent will be charged from 08 Nov 2023 13:30 until 10 Nov 2023 13:30. A no show fee of 100 percent will be charged.",
            currency: "EUR",
            amount: 0,
          },
        ],
        booking_id: "-YW7z95zfk2O5epudAHSpA-86",
        api_uid: "66357eaf-1761-48e5-ab58-0c69f7a95c9a",
        supplier: "carnect",
        session_id: "610b57ee-77b5-4245-9bcc-1987cb90353a",
        is_own: true,
        is_parent_own: true,
      },
      {
        vehicle: {
          door_count: 2,
          model_year: null,
          name: "Dacia Sander",
          brand_code: "ECMR",
          code: "Dacia Sander",
          image:
            "https://vehicles.carhire-solutions.com/Budget/small/t_ECMR_FR.jpg",
          transmission_type: "Manual",
          fuel_type: "Unspecified",
          passenger_quantity: 5,
          baggage_quantity: 2,
          has_air_condition: false,
        },
        provider: {
          name: "Budget",
          code: "ZD",
        },
        price: {
          currency: "EUR",
          amount: "160.20",
        },
        pick_up: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "Bd Beauvais Apt",
          locationCode: null,
        },
        drop_off: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "Bd Beauvais Apt",
          locationCode: null,
        },
        inclusions: [
          {
            coverage_type: "Airport Service Charge",
            code: "407",
            description: "Facility charge at commercial airports.",
            currency: "EUR",
            amount: 37.5,
          },
          {
            coverage_type: "Vehicle Licence Fee / Road Fund Licence",
            code: "402",
            description:
              "Licensing and other vehicle-related taxes charged by local governments.",
            currency: "EUR",
            amount: 15.4,
          },
          {
            coverage_type: "Kilometer inclusive: 1250 km (0.30 EUR/km)",
            code: "416",
            description: "per km: .30 EUR",
            currency: "EUR",
            amount: 0.3,
          },
          {
            coverage_type: "Other taxes and service charges",
            code: "418",
            description: "per rental: 26.70 EUR",
            currency: "EUR",
            amount: 26.7,
          },
          {
            coverage_type: "Collision damage waiver",
            code: "CDW",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Theft protection",
            code: "TP",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Fuel Information",
            code: "F2F",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "VAT",
            code: "TAX",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Cancellation fee",
            code: "CF",
            description:
              "Cancellation is free of charge until 08 Nov 2023 13:30. A cancellation fee of 100 percent will be charged from 08 Nov 2023 13:30 until 10 Nov 2023 13:30. A no show fee of 100 percent will be charged.",
            currency: "EUR",
            amount: 0,
          },
        ],
        booking_id: "-YW7z95zfk2O5epudAHSpA-67",
        api_uid: "66357eaf-1761-48e5-ab58-0c69f7a95c9a",
        supplier: "carnect",
        session_id: "610b57ee-77b5-4245-9bcc-1987cb90353a",
        is_own: true,
        is_parent_own: true,
      },
      {
        vehicle: {
          door_count: 3,
          model_year: null,
          name: "FIAT 500",
          brand_code: "MBMR",
          code: "FIAT 500",
          image:
            "https://vehicles.carhire-solutions.com/Europcar/small/t_MBMR_FR.jpg",
          transmission_type: "Manual",
          fuel_type: "Unspecified",
          passenger_quantity: 4,
          baggage_quantity: 1,
          has_air_condition: false,
        },
        provider: {
          name: "Europcar",
          code: "EP",
        },
        price: {
          currency: "EUR",
          amount: "173.58",
        },
        pick_up: {
          geodata: {
            lat: 49.4544,
            lng: 2.1115,
          },
          counter: 14,
          name: "Beauvais Airport Fr",
          locationCode: null,
        },
        drop_off: {
          geodata: {
            lat: 49.4544,
            lng: 2.1115,
          },
          counter: 14,
          name: "Beauvais Airport Fr",
          locationCode: null,
        },
        inclusions: [
          {
            coverage_type: "Airport Service Charge",
            code: "407",
            description: "Facility charge at commercial airports.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Vehicle Licence Fee / Road Fund Licence",
            code: "402",
            description:
              "Licensing and other vehicle-related taxes charged by local governments.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Unlimited Mileage",
            code: "405",
            description: "No further costs apply for extra miles driven",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Enviromental Tax",
            code: "110",
            description: "per rental: 5.40 EUR",
            currency: "EUR",
            amount: 5.4,
          },
          {
            coverage_type: "Other taxes and service charges",
            code: "418",
            description: ", HSS",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Collision damage waiver",
            code: "CDW",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Theft protection",
            code: "TP",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Fuel Information",
            code: "F2F",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "VAT",
            code: "TAX",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Cancellation fee",
            code: "CF",
            description:
              "Cancellation is free of charge until 08 Nov 2023 13:30. A cancellation fee of 100 percent will be charged from 08 Nov 2023 13:30 until 10 Nov 2023 13:30. A no show fee of 100 percent will be charged.",
            currency: "EUR",
            amount: 0,
          },
        ],
        booking_id: "-YW7z95zfk2O5epudAHSpA-14",
        api_uid: "66357eaf-1761-48e5-ab58-0c69f7a95c9a",
        supplier: "carnect",
        session_id: "610b57ee-77b5-4245-9bcc-1987cb90353a",
        is_own: true,
        is_parent_own: true,
      },
      {
        vehicle: {
          door_count: 4,
          model_year: null,
          name: "B RENAULT CLIO",
          brand_code: "EDMR",
          code: "B RENAULT CLIO",
          image:
            "https://vehicles.carhire-solutions.com/Thrifty Europe/small/t_EDMR_FR.jpg",
          transmission_type: "Manual",
          fuel_type: "Unspecified",
          passenger_quantity: 4,
          baggage_quantity: 3,
          has_air_condition: true,
        },
        provider: {
          name: "Thrifty Europe",
          code: "TO",
        },
        price: {
          currency: "EUR",
          amount: "176.02",
        },
        pick_up: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "BEAUVAIS AIRPORT",
          locationCode: null,
        },
        drop_off: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "BEAUVAIS AIRPORT",
          locationCode: null,
        },
        inclusions: [
          {
            coverage_type: "Airport Service Charge",
            code: "407",
            description: "Facility charge at commercial airports.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Vehicle Licence Fee / Road Fund Licence",
            code: "402",
            description:
              "Licensing and other vehicle-related taxes charged by local governments.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Unlimited Mileage",
            code: "405",
            description: "No further costs apply for extra miles driven",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Stay Safe Initiative",
            code: "55",
            description:
              "As the world faces an unprecedented health crisis, our car rental suppliers have put special measures in place to provide you with a safe and flexible travel experience. The utmost attention is being paid to cleaning the vehicles. Additional to the high standards of cleaning there is now an enhanced process in place, which involves the sanitizing of every vehicle between each rental using detergent cleaning products to protect all customers and add peace of mind.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Collision damage waiver",
            code: "CDW",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Supplementary Liability Insurance",
            code: "SLI",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Theft protection",
            code: "TP",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Fuel Information",
            code: "F2F",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "VAT",
            code: "TAX",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Cancellation fee",
            code: "CF",
            description:
              "Cancellation is free of charge until 08 Nov 2023 13:30. A cancellation fee of 100 percent will be charged from 08 Nov 2023 13:30 until 10 Nov 2023 13:30. A no show fee of 100 percent will be charged.",
            currency: "EUR",
            amount: 0,
          },
        ],
        booking_id: "-YW7z95zfk2O5epudAHSpA-87",
        api_uid: "66357eaf-1761-48e5-ab58-0c69f7a95c9a",
        supplier: "carnect",
        session_id: "610b57ee-77b5-4245-9bcc-1987cb90353a",
        is_own: true,
        is_parent_own: true,
      },
      {
        vehicle: {
          door_count: 2,
          model_year: null,
          name: "Renault Twingo",
          brand_code: "MBMR",
          code: "Renault Twingo",
          image:
            "https://vehicles.carhire-solutions.com/Budget/small/t_MBMR_FR.jpg",
          transmission_type: "Manual",
          fuel_type: "Unspecified",
          passenger_quantity: 4,
          baggage_quantity: 2,
          has_air_condition: true,
        },
        provider: {
          name: "Budget",
          code: "ZD",
        },
        price: {
          currency: "EUR",
          amount: "182.30",
        },
        pick_up: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "Bd Beauvais Apt",
          locationCode: null,
        },
        drop_off: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "Bd Beauvais Apt",
          locationCode: null,
        },
        inclusions: [
          {
            coverage_type: "Airport Service Charge",
            code: "407",
            description: "Facility charge at commercial airports.",
            currency: "EUR",
            amount: 37.5,
          },
          {
            coverage_type: "Vehicle Licence Fee / Road Fund Licence",
            code: "402",
            description:
              "Licensing and other vehicle-related taxes charged by local governments.",
            currency: "EUR",
            amount: 15.4,
          },
          {
            coverage_type: "Kilometer inclusive: 1250 km (0.30 EUR/km)",
            code: "416",
            description: "per km: .30 EUR",
            currency: "EUR",
            amount: 0.3,
          },
          {
            coverage_type: "Other taxes and service charges",
            code: "418",
            description: "per rental: 24.30 EUR",
            currency: "EUR",
            amount: 24.3,
          },
          {
            coverage_type: "Full Protection",
            code: "413",
            description: "per day: 7.30 EUR",
            currency: "EUR",
            amount: 36.5,
          },
          {
            coverage_type: "Collision damage waiver",
            code: "CDW",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Theft protection",
            code: "TP",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Fuel Information",
            code: "F2F",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "VAT",
            code: "TAX",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Cancellation fee",
            code: "CF",
            description:
              "Cancellation is free of charge until 08 Nov 2023 13:30. A cancellation fee of 100 percent will be charged from 08 Nov 2023 13:30 until 10 Nov 2023 13:30. A no show fee of 100 percent will be charged.",
            currency: "EUR",
            amount: 0,
          },
        ],
        booking_id: "-YW7z95zfk2O5epudAHSpA-75",
        api_uid: "66357eaf-1761-48e5-ab58-0c69f7a95c9a",
        supplier: "carnect",
        session_id: "610b57ee-77b5-4245-9bcc-1987cb90353a",
        is_own: true,
        is_parent_own: true,
      },
      {
        vehicle: {
          door_count: 2,
          model_year: null,
          name: "A FIAT 500",
          brand_code: "MCMR",
          code: "A FIAT 500",
          image:
            "https://vehicles.carhire-solutions.com/Thrifty Europe/small/t_MCMR_FR.jpg",
          transmission_type: "Manual",
          fuel_type: "Unspecified",
          passenger_quantity: 4,
          baggage_quantity: 2,
          has_air_condition: true,
        },
        provider: {
          name: "Thrifty Europe",
          code: "TO",
        },
        price: {
          currency: "EUR",
          amount: "194.90",
        },
        pick_up: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "BEAUVAIS AIRPORT",
          locationCode: null,
        },
        drop_off: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "BEAUVAIS AIRPORT",
          locationCode: null,
        },
        inclusions: [
          {
            coverage_type: "Airport Service Charge",
            code: "407",
            description: "Facility charge at commercial airports.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Vehicle Licence Fee / Road Fund Licence",
            code: "402",
            description:
              "Licensing and other vehicle-related taxes charged by local governments.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Unlimited Mileage",
            code: "405",
            description: "No further costs apply for extra miles driven",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Stay Safe Initiative",
            code: "55",
            description:
              "As the world faces an unprecedented health crisis, our car rental suppliers have put special measures in place to provide you with a safe and flexible travel experience. The utmost attention is being paid to cleaning the vehicles. Additional to the high standards of cleaning there is now an enhanced process in place, which involves the sanitizing of every vehicle between each rental using detergent cleaning products to protect all customers and add peace of mind.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Full Protection",
            code: "413",
            description: "per day: 7.30 EUR",
            currency: "EUR",
            amount: 36.5,
          },
          {
            coverage_type: "Collision damage waiver",
            code: "CDW",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Supplementary Liability Insurance",
            code: "SLI",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Theft protection",
            code: "TP",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Fuel Information",
            code: "F2F",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "VAT",
            code: "TAX",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Cancellation fee",
            code: "CF",
            description:
              "Cancellation is free of charge until 08 Nov 2023 13:30. A cancellation fee of 100 percent will be charged from 08 Nov 2023 13:30 until 10 Nov 2023 13:30. A no show fee of 100 percent will be charged.",
            currency: "EUR",
            amount: 0,
          },
        ],
        booking_id: "-YW7z95zfk2O5epudAHSpA-92",
        api_uid: "66357eaf-1761-48e5-ab58-0c69f7a95c9a",
        supplier: "carnect",
        session_id: "610b57ee-77b5-4245-9bcc-1987cb90353a",
        is_own: true,
        is_parent_own: true,
      },
      {
        vehicle: {
          door_count: 2,
          model_year: null,
          name: "Dacia Sander",
          brand_code: "ECMR",
          code: "Dacia Sander",
          image:
            "https://vehicles.carhire-solutions.com/Budget/small/t_ECMR_FR.jpg",
          transmission_type: "Manual",
          fuel_type: "Unspecified",
          passenger_quantity: 5,
          baggage_quantity: 2,
          has_air_condition: true,
        },
        provider: {
          name: "Budget",
          code: "ZD",
        },
        price: {
          currency: "EUR",
          amount: "196.70",
        },
        pick_up: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "Bd Beauvais Apt",
          locationCode: null,
        },
        drop_off: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "Bd Beauvais Apt",
          locationCode: null,
        },
        inclusions: [
          {
            coverage_type: "Airport Service Charge",
            code: "407",
            description: "Facility charge at commercial airports.",
            currency: "EUR",
            amount: 37.5,
          },
          {
            coverage_type: "Vehicle Licence Fee / Road Fund Licence",
            code: "402",
            description:
              "Licensing and other vehicle-related taxes charged by local governments.",
            currency: "EUR",
            amount: 15.4,
          },
          {
            coverage_type: "Kilometer inclusive: 1250 km (0.30 EUR/km)",
            code: "416",
            description: "per km: .30 EUR",
            currency: "EUR",
            amount: 0.3,
          },
          {
            coverage_type: "Other taxes and service charges",
            code: "418",
            description: "per rental: 26.70 EUR",
            currency: "EUR",
            amount: 26.7,
          },
          {
            coverage_type: "Full Protection",
            code: "413",
            description: "per day: 7.30 EUR",
            currency: "EUR",
            amount: 36.5,
          },
          {
            coverage_type: "Collision damage waiver",
            code: "CDW",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Theft protection",
            code: "TP",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Fuel Information",
            code: "F2F",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "VAT",
            code: "TAX",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Cancellation fee",
            code: "CF",
            description:
              "Cancellation is free of charge until 08 Nov 2023 13:30. A cancellation fee of 100 percent will be charged from 08 Nov 2023 13:30 until 10 Nov 2023 13:30. A no show fee of 100 percent will be charged.",
            currency: "EUR",
            amount: 0,
          },
        ],
        booking_id: "-YW7z95zfk2O5epudAHSpA-76",
        api_uid: "66357eaf-1761-48e5-ab58-0c69f7a95c9a",
        supplier: "carnect",
        session_id: "610b57ee-77b5-4245-9bcc-1987cb90353a",
        is_own: true,
        is_parent_own: true,
      },
      {
        vehicle: {
          door_count: 4,
          model_year: null,
          name: "C PEUGEOT 2008",
          brand_code: "CDMR",
          code: "C PEUGEOT 2008",
          image:
            "https://vehicles.carhire-solutions.com/Thrifty Europe/small/t_CDMR_FR.jpg",
          transmission_type: "Manual",
          fuel_type: "Unspecified",
          passenger_quantity: 5,
          baggage_quantity: 3,
          has_air_condition: true,
        },
        provider: {
          name: "Thrifty Europe",
          code: "TO",
        },
        price: {
          currency: "EUR",
          amount: "203.48",
        },
        pick_up: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "BEAUVAIS AIRPORT",
          locationCode: null,
        },
        drop_off: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "BEAUVAIS AIRPORT",
          locationCode: null,
        },
        inclusions: [
          {
            coverage_type: "Airport Service Charge",
            code: "407",
            description: "Facility charge at commercial airports.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Vehicle Licence Fee / Road Fund Licence",
            code: "402",
            description:
              "Licensing and other vehicle-related taxes charged by local governments.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Unlimited Mileage",
            code: "405",
            description: "No further costs apply for extra miles driven",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Stay Safe Initiative",
            code: "55",
            description:
              "As the world faces an unprecedented health crisis, our car rental suppliers have put special measures in place to provide you with a safe and flexible travel experience. The utmost attention is being paid to cleaning the vehicles. Additional to the high standards of cleaning there is now an enhanced process in place, which involves the sanitizing of every vehicle between each rental using detergent cleaning products to protect all customers and add peace of mind.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Collision damage waiver",
            code: "CDW",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Supplementary Liability Insurance",
            code: "SLI",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Theft protection",
            code: "TP",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Fuel Information",
            code: "F2F",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "VAT",
            code: "TAX",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Cancellation fee",
            code: "CF",
            description:
              "Cancellation is free of charge until 08 Nov 2023 13:30. A cancellation fee of 100 percent will be charged from 08 Nov 2023 13:30 until 10 Nov 2023 13:30. A no show fee of 100 percent will be charged.",
            currency: "EUR",
            amount: 0,
          },
        ],
        booking_id: "-YW7z95zfk2O5epudAHSpA-84",
        api_uid: "66357eaf-1761-48e5-ab58-0c69f7a95c9a",
        supplier: "carnect",
        session_id: "610b57ee-77b5-4245-9bcc-1987cb90353a",
        is_own: true,
        is_parent_own: true,
      },
      {
        vehicle: {
          door_count: 3,
          model_year: null,
          name: "FIAT 500",
          brand_code: "MBMR",
          code: "FIAT 500",
          image:
            "https://vehicles.carhire-solutions.com/Europcar/small/t_MBMR_FR.jpg",
          transmission_type: "Manual",
          fuel_type: "Unspecified",
          passenger_quantity: 4,
          baggage_quantity: 1,
          has_air_condition: true,
        },
        provider: {
          name: "Europcar",
          code: "EP",
        },
        price: {
          currency: "EUR",
          amount: "210.08",
        },
        pick_up: {
          geodata: {
            lat: 49.4544,
            lng: 2.1115,
          },
          counter: 14,
          name: "Beauvais Airport Fr",
          locationCode: null,
        },
        drop_off: {
          geodata: {
            lat: 49.4544,
            lng: 2.1115,
          },
          counter: 14,
          name: "Beauvais Airport Fr",
          locationCode: null,
        },
        inclusions: [
          {
            coverage_type: "Airport Service Charge",
            code: "407",
            description: "Facility charge at commercial airports.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Vehicle Licence Fee / Road Fund Licence",
            code: "402",
            description:
              "Licensing and other vehicle-related taxes charged by local governments.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Unlimited Mileage",
            code: "405",
            description: "No further costs apply for extra miles driven",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Enviromental Tax",
            code: "110",
            description: "per rental: 5.40 EUR",
            currency: "EUR",
            amount: 5.4,
          },
          {
            coverage_type: "Other taxes and service charges",
            code: "418",
            description: ", HSS",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Full Protection",
            code: "413",
            description: "per day: 7.30 EUR",
            currency: "EUR",
            amount: 36.5,
          },
          {
            coverage_type: "Collision damage waiver",
            code: "CDW",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Theft protection",
            code: "TP",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Fuel Information",
            code: "F2F",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "VAT",
            code: "TAX",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Cancellation fee",
            code: "CF",
            description:
              "Cancellation is free of charge until 08 Nov 2023 13:30. A cancellation fee of 100 percent will be charged from 08 Nov 2023 13:30 until 10 Nov 2023 13:30. A no show fee of 100 percent will be charged.",
            currency: "EUR",
            amount: 0,
          },
        ],
        booking_id: "-YW7z95zfk2O5epudAHSpA-19",
        api_uid: "66357eaf-1761-48e5-ab58-0c69f7a95c9a",
        supplier: "carnect",
        session_id: "610b57ee-77b5-4245-9bcc-1987cb90353a",
        is_own: true,
        is_parent_own: true,
      },
      {
        vehicle: {
          door_count: 4,
          model_year: null,
          name: "B RENAULT CLIO",
          brand_code: "EDMR",
          code: "B RENAULT CLIO",
          image:
            "https://vehicles.carhire-solutions.com/Thrifty Europe/small/t_EDMR_FR.jpg",
          transmission_type: "Manual",
          fuel_type: "Unspecified",
          passenger_quantity: 4,
          baggage_quantity: 3,
          has_air_condition: true,
        },
        provider: {
          name: "Thrifty Europe",
          code: "TO",
        },
        price: {
          currency: "EUR",
          amount: "212.52",
        },
        pick_up: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "BEAUVAIS AIRPORT",
          locationCode: null,
        },
        drop_off: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "BEAUVAIS AIRPORT",
          locationCode: null,
        },
        inclusions: [
          {
            coverage_type: "Airport Service Charge",
            code: "407",
            description: "Facility charge at commercial airports.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Vehicle Licence Fee / Road Fund Licence",
            code: "402",
            description:
              "Licensing and other vehicle-related taxes charged by local governments.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Unlimited Mileage",
            code: "405",
            description: "No further costs apply for extra miles driven",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Stay Safe Initiative",
            code: "55",
            description:
              "As the world faces an unprecedented health crisis, our car rental suppliers have put special measures in place to provide you with a safe and flexible travel experience. The utmost attention is being paid to cleaning the vehicles. Additional to the high standards of cleaning there is now an enhanced process in place, which involves the sanitizing of every vehicle between each rental using detergent cleaning products to protect all customers and add peace of mind.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Full Protection",
            code: "413",
            description: "per day: 7.30 EUR",
            currency: "EUR",
            amount: 36.5,
          },
          {
            coverage_type: "Collision damage waiver",
            code: "CDW",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Supplementary Liability Insurance",
            code: "SLI",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Theft protection",
            code: "TP",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Fuel Information",
            code: "F2F",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "VAT",
            code: "TAX",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Cancellation fee",
            code: "CF",
            description:
              "Cancellation is free of charge until 08 Nov 2023 13:30. A cancellation fee of 100 percent will be charged from 08 Nov 2023 13:30 until 10 Nov 2023 13:30. A no show fee of 100 percent will be charged.",
            currency: "EUR",
            amount: 0,
          },
        ],
        booking_id: "-YW7z95zfk2O5epudAHSpA-93",
        api_uid: "66357eaf-1761-48e5-ab58-0c69f7a95c9a",
        supplier: "carnect",
        session_id: "610b57ee-77b5-4245-9bcc-1987cb90353a",
        is_own: true,
        is_parent_own: true,
      },
      {
        vehicle: {
          door_count: 4,
          model_year: null,
          name: "Fiat Tipo",
          brand_code: "CDMR",
          code: "Fiat Tipo",
          image:
            "https://vehicles.carhire-solutions.com/Budget/small/t_CDMR_FR.jpg",
          transmission_type: "Manual",
          fuel_type: "Unspecified",
          passenger_quantity: 5,
          baggage_quantity: 2,
          has_air_condition: true,
        },
        provider: {
          name: "Budget",
          code: "ZD",
        },
        price: {
          currency: "EUR",
          amount: "213.00",
        },
        pick_up: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "Bd Beauvais Apt",
          locationCode: null,
        },
        drop_off: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "Bd Beauvais Apt",
          locationCode: null,
        },
        inclusions: [
          {
            coverage_type: "Airport Service Charge",
            code: "407",
            description: "Facility charge at commercial airports.",
            currency: "EUR",
            amount: 37.5,
          },
          {
            coverage_type: "Vehicle Licence Fee / Road Fund Licence",
            code: "402",
            description:
              "Licensing and other vehicle-related taxes charged by local governments.",
            currency: "EUR",
            amount: 15.4,
          },
          {
            coverage_type: "Kilometer inclusive: 1250 km (0.36 EUR/km)",
            code: "416",
            description: "per km: .36 EUR",
            currency: "EUR",
            amount: 0.36,
          },
          {
            coverage_type: "Other taxes and service charges",
            code: "418",
            description: "per rental: 35.50 EUR",
            currency: "EUR",
            amount: 35.5,
          },
          {
            coverage_type: "Collision damage waiver",
            code: "CDW",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Theft protection",
            code: "TP",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Fuel Information",
            code: "F2F",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "VAT",
            code: "TAX",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Cancellation fee",
            code: "CF",
            description:
              "Cancellation is free of charge until 08 Nov 2023 13:30. A cancellation fee of 100 percent will be charged from 08 Nov 2023 13:30 until 10 Nov 2023 13:30. A no show fee of 100 percent will be charged.",
            currency: "EUR",
            amount: 0,
          },
        ],
        booking_id: "-YW7z95zfk2O5epudAHSpA-68",
        api_uid: "66357eaf-1761-48e5-ab58-0c69f7a95c9a",
        supplier: "carnect",
        session_id: "610b57ee-77b5-4245-9bcc-1987cb90353a",
        is_own: true,
        is_parent_own: true,
      },
      {
        vehicle: {
          door_count: 5,
          model_year: null,
          name: "TOYOTA YARIS",
          brand_code: "ECMR",
          code: "TOYOTA YARIS",
          image: "https://vehicles.carhire-solutions.com/default/small/E.jpg",
          transmission_type: "Manual",
          fuel_type: "Unspecified",
          passenger_quantity: 5,
          baggage_quantity: 2,
          has_air_condition: true,
        },
        provider: {
          name: "Europcar",
          code: "EP",
        },
        price: {
          currency: "EUR",
          amount: "214.10",
        },
        pick_up: {
          geodata: {
            lat: 49.4544,
            lng: 2.1115,
          },
          counter: 14,
          name: "Beauvais Airport Fr",
          locationCode: null,
        },
        drop_off: {
          geodata: {
            lat: 49.4544,
            lng: 2.1115,
          },
          counter: 14,
          name: "Beauvais Airport Fr",
          locationCode: null,
        },
        inclusions: [
          {
            coverage_type: "Airport Service Charge",
            code: "407",
            description: "Facility charge at commercial airports.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Vehicle Licence Fee / Road Fund Licence",
            code: "402",
            description:
              "Licensing and other vehicle-related taxes charged by local governments.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Unlimited Mileage",
            code: "405",
            description: "No further costs apply for extra miles driven",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Enviromental Tax",
            code: "110",
            description: "per rental: 5.40 EUR",
            currency: "EUR",
            amount: 5.4,
          },
          {
            coverage_type: "Other taxes and service charges",
            code: "418",
            description: ", HSS",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Collision damage waiver",
            code: "CDW",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Theft protection",
            code: "TP",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Fuel Information",
            code: "F2F",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "VAT",
            code: "TAX",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Cancellation fee",
            code: "CF",
            description:
              "Cancellation is free of charge until 08 Nov 2023 13:30. A cancellation fee of 100 percent will be charged from 08 Nov 2023 13:30 until 10 Nov 2023 13:30. A no show fee of 100 percent will be charged.",
            currency: "EUR",
            amount: 0,
          },
        ],
        booking_id: "-YW7z95zfk2O5epudAHSpA-10",
        api_uid: "66357eaf-1761-48e5-ab58-0c69f7a95c9a",
        supplier: "carnect",
        session_id: "610b57ee-77b5-4245-9bcc-1987cb90353a",
        is_own: true,
        is_parent_own: true,
      },
      {
        vehicle: {
          door_count: 3,
          model_year: null,
          name: "FIAT 500",
          brand_code: "MBMR",
          code: "FIAT 500",
          image:
            "https://vehicles.carhire-solutions.com/Europcar/small/t_MBMR_FR.jpg",
          transmission_type: "Manual",
          fuel_type: "Unspecified",
          passenger_quantity: 4,
          baggage_quantity: 1,
          has_air_condition: true,
        },
        provider: {
          name: "Europcar",
          code: "EP",
        },
        price: {
          currency: "EUR",
          amount: "219.17",
        },
        pick_up: {
          geodata: {
            lat: 49.4544,
            lng: 2.1115,
          },
          counter: 14,
          name: "Beauvais Airport Fr",
          locationCode: null,
        },
        drop_off: {
          geodata: {
            lat: 49.4544,
            lng: 2.1115,
          },
          counter: 14,
          name: "Beauvais Airport Fr",
          locationCode: null,
        },
        inclusions: [
          {
            coverage_type: "Airport Service Charge",
            code: "407",
            description: "Facility charge at commercial airports.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Vehicle Licence Fee / Road Fund Licence",
            code: "402",
            description:
              "Licensing and other vehicle-related taxes charged by local governments.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Unlimited Mileage",
            code: "405",
            description: "No further costs apply for extra miles driven",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Enviromental Tax",
            code: "110",
            description: "per rental: 5.40 EUR",
            currency: "EUR",
            amount: 5.4,
          },
          {
            coverage_type: "Other taxes and service charges",
            code: "418",
            description: ", HSS",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Collision damage waiver",
            code: "CDW",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Theft protection",
            code: "TP",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Fuel Information",
            code: "F2F",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "VAT",
            code: "TAX",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Cancellation fee",
            code: "CF",
            description:
              "Cancellation is free of charge until 08 Nov 2023 13:30. A cancellation fee of 100 percent will be charged from 08 Nov 2023 13:30 until 10 Nov 2023 13:30. A no show fee of 100 percent will be charged.",
            currency: "EUR",
            amount: 0,
          },
        ],
        booking_id: "-YW7z95zfk2O5epudAHSpA-4",
        api_uid: "66357eaf-1761-48e5-ab58-0c69f7a95c9a",
        supplier: "carnect",
        session_id: "610b57ee-77b5-4245-9bcc-1987cb90353a",
        is_own: true,
        is_parent_own: true,
      },
      {
        vehicle: {
          door_count: 5,
          model_year: null,
          name: "FIAT 500X",
          brand_code: "CDMR",
          code: "FIAT 500X",
          image:
            "https://vehicles.carhire-solutions.com/Europcar/small/t_CDMR_FR.jpg",
          transmission_type: "Manual",
          fuel_type: "Diesel",
          passenger_quantity: 5,
          baggage_quantity: 2,
          has_air_condition: true,
        },
        provider: {
          name: "Europcar",
          code: "EP",
        },
        price: {
          currency: "EUR",
          amount: "219.71",
        },
        pick_up: {
          geodata: {
            lat: 49.4544,
            lng: 2.1115,
          },
          counter: 14,
          name: "Beauvais Airport Fr",
          locationCode: null,
        },
        drop_off: {
          geodata: {
            lat: 49.4544,
            lng: 2.1115,
          },
          counter: 14,
          name: "Beauvais Airport Fr",
          locationCode: null,
        },
        inclusions: [
          {
            coverage_type: "Airport Service Charge",
            code: "407",
            description: "Facility charge at commercial airports.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Vehicle Licence Fee / Road Fund Licence",
            code: "402",
            description:
              "Licensing and other vehicle-related taxes charged by local governments.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Unlimited Mileage",
            code: "405",
            description: "No further costs apply for extra miles driven",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Enviromental Tax",
            code: "110",
            description: "per rental: 10.80 EUR",
            currency: "EUR",
            amount: 10.8,
          },
          {
            coverage_type: "Other taxes and service charges",
            code: "418",
            description: ", HSS",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Collision damage waiver",
            code: "CDW",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Theft protection",
            code: "TP",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Fuel Information",
            code: "F2F",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "VAT",
            code: "TAX",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Cancellation fee",
            code: "CF",
            description:
              "Cancellation is free of charge until 08 Nov 2023 13:30. A cancellation fee of 100 percent will be charged from 08 Nov 2023 13:30 until 10 Nov 2023 13:30. A no show fee of 100 percent will be charged.",
            currency: "EUR",
            amount: 0,
          },
        ],
        booking_id: "-YW7z95zfk2O5epudAHSpA-11",
        api_uid: "66357eaf-1761-48e5-ab58-0c69f7a95c9a",
        supplier: "carnect",
        session_id: "610b57ee-77b5-4245-9bcc-1987cb90353a",
        is_own: true,
        is_parent_own: true,
      },
      {
        vehicle: {
          door_count: 5,
          model_year: null,
          name: "D PEUGEOT 308 SW",
          brand_code: "CWMR",
          code: "D PEUGEOT 308 SW",
          image:
            "https://vehicles.carhire-solutions.com/Thrifty Europe/small/t_CWMR_FR.jpg",
          transmission_type: "Manual",
          fuel_type: "Unspecified",
          passenger_quantity: 5,
          baggage_quantity: 3,
          has_air_condition: true,
        },
        provider: {
          name: "Thrifty Europe",
          code: "TO",
        },
        price: {
          currency: "EUR",
          amount: "223.87",
        },
        pick_up: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "BEAUVAIS AIRPORT",
          locationCode: null,
        },
        drop_off: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "BEAUVAIS AIRPORT",
          locationCode: null,
        },
        inclusions: [
          {
            coverage_type: "Airport Service Charge",
            code: "407",
            description: "Facility charge at commercial airports.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Vehicle Licence Fee / Road Fund Licence",
            code: "402",
            description:
              "Licensing and other vehicle-related taxes charged by local governments.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Unlimited Mileage",
            code: "405",
            description: "No further costs apply for extra miles driven",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Stay Safe Initiative",
            code: "55",
            description:
              "As the world faces an unprecedented health crisis, our car rental suppliers have put special measures in place to provide you with a safe and flexible travel experience. The utmost attention is being paid to cleaning the vehicles. Additional to the high standards of cleaning there is now an enhanced process in place, which involves the sanitizing of every vehicle between each rental using detergent cleaning products to protect all customers and add peace of mind.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Collision damage waiver",
            code: "CDW",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Supplementary Liability Insurance",
            code: "SLI",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Theft protection",
            code: "TP",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Fuel Information",
            code: "F2F",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "VAT",
            code: "TAX",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Cancellation fee",
            code: "CF",
            description:
              "Cancellation is free of charge until 08 Nov 2023 13:30. A cancellation fee of 100 percent will be charged from 08 Nov 2023 13:30 until 10 Nov 2023 13:30. A no show fee of 100 percent will be charged.",
            currency: "EUR",
            amount: 0,
          },
        ],
        booking_id: "-YW7z95zfk2O5epudAHSpA-88",
        api_uid: "66357eaf-1761-48e5-ab58-0c69f7a95c9a",
        supplier: "carnect",
        session_id: "610b57ee-77b5-4245-9bcc-1987cb90353a",
        is_own: true,
        is_parent_own: true,
      },
      {
        vehicle: {
          door_count: 2,
          model_year: null,
          name: "Fiat 500e Electric",
          brand_code: "MBAE",
          code: "Fiat 500e Electric",
          image:
            "https://vehicles.carhire-solutions.com/Budget/small/t_MBAE_FR.jpg",
          transmission_type: "Automatic",
          fuel_type: "Electric",
          passenger_quantity: 4,
          baggage_quantity: 2,
          has_air_condition: true,
        },
        provider: {
          name: "Budget",
          code: "ZD",
        },
        price: {
          currency: "EUR",
          amount: "225.48",
        },
        pick_up: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "Bd Beauvais Apt",
          locationCode: null,
        },
        drop_off: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "Bd Beauvais Apt",
          locationCode: null,
        },
        inclusions: [
          {
            coverage_type: "Airport Service Charge",
            code: "407",
            description: "Facility charge at commercial airports.",
            currency: "EUR",
            amount: 37.5,
          },
          {
            coverage_type: "Vehicle Licence Fee / Road Fund Licence",
            code: "402",
            description:
              "Licensing and other vehicle-related taxes charged by local governments.",
            currency: "EUR",
            amount: 15.4,
          },
          {
            coverage_type: "Kilometer inclusive: 1250 km (0.42 EUR/km)",
            code: "416",
            description: "per km: .42 EUR",
            currency: "EUR",
            amount: 0.42,
          },
          {
            coverage_type: "Other taxes and service charges",
            code: "418",
            description: "per rental: 37.58 EUR",
            currency: "EUR",
            amount: 37.58,
          },
          {
            coverage_type: "Collision damage waiver",
            code: "CDW",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Theft protection",
            code: "TP",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Fuel Information",
            code: "F2F",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "VAT",
            code: "TAX",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Cancellation fee",
            code: "CF",
            description:
              "Cancellation is free of charge until 08 Nov 2023 13:30. A cancellation fee of 100 percent will be charged from 08 Nov 2023 13:30 until 10 Nov 2023 13:30. A no show fee of 100 percent will be charged.",
            currency: "EUR",
            amount: 0,
          },
        ],
        booking_id: "-YW7z95zfk2O5epudAHSpA-71",
        api_uid: "66357eaf-1761-48e5-ab58-0c69f7a95c9a",
        supplier: "carnect",
        session_id: "610b57ee-77b5-4245-9bcc-1987cb90353a",
        is_own: true,
        is_parent_own: true,
      },
      {
        vehicle: {
          door_count: 2,
          model_year: null,
          name: "A FIAT 500",
          brand_code: "MCMR",
          code: "A FIAT 500",
          image:
            "https://vehicles.carhire-solutions.com/Hertz/small/t_MCMR_FR.jpg",
          transmission_type: "Manual",
          fuel_type: "Unspecified",
          passenger_quantity: 4,
          baggage_quantity: 2,
          has_air_condition: true,
        },
        provider: {
          name: "Hertz",
          code: "ZE",
        },
        price: {
          currency: "EUR",
          amount: "229.98",
        },
        pick_up: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "BEAUVAIS AIRPORT",
          locationCode: null,
        },
        drop_off: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "BEAUVAIS AIRPORT",
          locationCode: null,
        },
        inclusions: [
          {
            coverage_type: "Airport Service Charge",
            code: "407",
            description: "Facility charge at commercial airports.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Vehicle Licence Fee / Road Fund Licence",
            code: "402",
            description:
              "Licensing and other vehicle-related taxes charged by local governments.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Unlimited Mileage",
            code: "405",
            description: "No further costs apply for extra miles driven",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Stay Safe Initiative",
            code: "55",
            description:
              "As the world faces an unprecedented health crisis, our car rental suppliers have put special measures in place to provide you with a safe and flexible travel experience. The utmost attention is being paid to cleaning the vehicles. Additional to the high standards of cleaning there is now an enhanced process in place, which involves the sanitizing of every vehicle between each rental using detergent cleaning products to protect all customers and add peace of mind.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Collision damage waiver",
            code: "CDW",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Theft protection",
            code: "TP",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Fuel Information",
            code: "F2F",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "VAT",
            code: "TAX",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Cancellation fee",
            code: "CF",
            description:
              "Cancellation is free of charge until 08 Nov 2023 13:30. A cancellation fee of 100 percent will be charged from 08 Nov 2023 13:30 until 10 Nov 2023 13:30. A no show fee of 100 percent will be charged.",
            currency: "EUR",
            amount: 0,
          },
        ],
        booking_id: "-YW7z95zfk2O5epudAHSpA-22",
        api_uid: "66357eaf-1761-48e5-ab58-0c69f7a95c9a",
        supplier: "carnect",
        session_id: "610b57ee-77b5-4245-9bcc-1987cb90353a",
        is_own: true,
        is_parent_own: true,
      },
      {
        vehicle: {
          door_count: 2,
          model_year: null,
          name: "A FIAT 500",
          brand_code: "MCMR",
          code: "A FIAT 500",
          image:
            "https://vehicles.carhire-solutions.com/Hertz/small/t_MCMR_FR.jpg",
          transmission_type: "Manual",
          fuel_type: "Unspecified",
          passenger_quantity: 4,
          baggage_quantity: 2,
          has_air_condition: true,
        },
        provider: {
          name: "Hertz",
          code: "ZE",
        },
        price: {
          currency: "EUR",
          amount: "229.98",
        },
        pick_up: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 7,
          name: "BEAUVAIS DEL & COL",
          locationCode: null,
        },
        drop_off: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 7,
          name: "BEAUVAIS DEL & COL",
          locationCode: null,
        },
        inclusions: [
          {
            coverage_type: "Vehicle Licence Fee / Road Fund Licence",
            code: "402",
            description:
              "Licensing and other vehicle-related taxes charged by local governments.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Unlimited Mileage",
            code: "405",
            description: "No further costs apply for extra miles driven",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Stay Safe Initiative",
            code: "55",
            description:
              "As the world faces an unprecedented health crisis, our car rental suppliers have put special measures in place to provide you with a safe and flexible travel experience. The utmost attention is being paid to cleaning the vehicles. Additional to the high standards of cleaning there is now an enhanced process in place, which involves the sanitizing of every vehicle between each rental using detergent cleaning products to protect all customers and add peace of mind.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Other taxes and service charges",
            code: "418",
            description: ", MISCELLANEOUS APF FEE",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Collision damage waiver",
            code: "CDW",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Theft protection",
            code: "TP",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Fuel Information",
            code: "F2F",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "VAT",
            code: "TAX",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Cancellation fee",
            code: "CF",
            description:
              "Cancellation is free of charge until 08 Nov 2023 13:30. A cancellation fee of 100 percent will be charged from 08 Nov 2023 13:30 until 10 Nov 2023 13:30. A no show fee of 100 percent will be charged.",
            currency: "EUR",
            amount: 0,
          },
        ],
        booking_id: "-YW7z95zfk2O5epudAHSpA-37",
        api_uid: "66357eaf-1761-48e5-ab58-0c69f7a95c9a",
        supplier: "carnect",
        session_id: "610b57ee-77b5-4245-9bcc-1987cb90353a",
        is_own: true,
        is_parent_own: true,
      },
      {
        vehicle: {
          door_count: 5,
          model_year: null,
          name: "NISSAN QASHQAI",
          brand_code: "ISAR",
          code: "NISSAN QASHQAI",
          image:
            "https://vehicles.carhire-solutions.com/406/small/1967565_ISAR.jpg",
          transmission_type: "Automatic",
          fuel_type: "Unspecified",
          passenger_quantity: 5,
          baggage_quantity: 3,
          has_air_condition: true,
        },
        provider: {
          name: "Europcar",
          code: "EP",
        },
        price: {
          currency: "EUR",
          amount: "238.49",
        },
        pick_up: {
          geodata: {
            lat: 49.4544,
            lng: 2.1115,
          },
          counter: 14,
          name: "Beauvais Airport Fr",
          locationCode: null,
        },
        drop_off: {
          geodata: {
            lat: 49.4544,
            lng: 2.1115,
          },
          counter: 14,
          name: "Beauvais Airport Fr",
          locationCode: null,
        },
        inclusions: [
          {
            coverage_type: "Airport Service Charge",
            code: "407",
            description: "Facility charge at commercial airports.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Vehicle Licence Fee / Road Fund Licence",
            code: "402",
            description:
              "Licensing and other vehicle-related taxes charged by local governments.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Unlimited Mileage",
            code: "405",
            description: "No further costs apply for extra miles driven",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Enviromental Tax",
            code: "110",
            description: "per rental: 18.00 EUR",
            currency: "EUR",
            amount: 18,
          },
          {
            coverage_type: "Other taxes and service charges",
            code: "418",
            description: ", HSS",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Collision damage waiver",
            code: "CDW",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Theft protection",
            code: "TP",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Fuel Information",
            code: "F2F",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "VAT",
            code: "TAX",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Cancellation fee",
            code: "CF",
            description:
              "Cancellation is free of charge until 08 Nov 2023 13:30. A cancellation fee of 100 percent will be charged from 08 Nov 2023 13:30 until 10 Nov 2023 13:30. A no show fee of 100 percent will be charged.",
            currency: "EUR",
            amount: 0,
          },
        ],
        booking_id: "-YW7z95zfk2O5epudAHSpA-13",
        api_uid: "66357eaf-1761-48e5-ab58-0c69f7a95c9a",
        supplier: "carnect",
        session_id: "610b57ee-77b5-4245-9bcc-1987cb90353a",
        is_own: true,
        is_parent_own: true,
      },
    ],
    computed_data: {
      pricesRange: {
        minPrice: 145,
        maxPrice: 239,
        currency: "EUR",
      },
      providers: ["Budget", "Thrifty Europe", "Europcar", "Hertz"],
      vehicles: [
        "Renault Twingo",
        "A FIAT 500",
        "Dacia Sander",
        "FIAT 500",
        "B RENAULT CLIO",
        "C PEUGEOT 2008",
        "Fiat Tipo",
        "TOYOTA YARIS",
        "FIAT 500X",
        "D PEUGEOT 308 SW",
        "Fiat 500e Electric",
        "NISSAN QASHQAI",
      ],
    },
  };

  const [carRentalsList, setCarRentalsList] = useState(carRentals.results);

  const providers = carRentals.results.map((item) => item.provider.name);
  const providersCount = _.countBy(providers);
  const uniqueProviders = [...new Set(providers)];
  const providerOptions = [
    ["", "---"],
    ...uniqueProviders.map((provider) => [
      provider,
      `${provider} (${providersCount[provider]})`,
    ]),
  ];

  const passengerQuantities = carRentals.results.map(
    (item) => item.vehicle.passenger_quantity
  );
  const passengerCount = _.countBy(passengerQuantities);
  const uniquePassengerQuantities = [...new Set(passengerQuantities)];
  const passengerOptions = [
    ["", "---"],
    ...uniquePassengerQuantities.map((quantity) => [
      quantity,
      `${quantity} Passengers (${passengerCount[quantity]})`,
    ]),
  ];

  const vehicles = carRentals.results.map((item) => item.vehicle.name);
  const vehicleCount = _.countBy(vehicles);
  const uniqueVehicles = [...new Set(vehicles)];
  const vehicleOptions = [
    ["", "---"],
    ...uniqueVehicles.map((vehicle) => [
      vehicle,
      `${vehicle} (${vehicleCount[vehicle]})`,
    ]),
  ];

  const doors = carRentals.results.map((item) => item.vehicle.door_count);
  const doorsCount = _.countBy(doors);
  const uniqueDoors = [...new Set(doors)];
  const doorOptions = [
    ["", "---"],
    ...uniqueDoors.map((door) => [door, `${door} Doors (${doorsCount[door]})`]),
  ];

  const transmissions = carRentals.results.map(
    (item) => item.vehicle.transmission_type
  );
  const transmissionCount = _.countBy(transmissions);
  const uniqueTransmissions = [...new Set(transmissions)];
  const transmissionOptions = [
    ["", "---"],
    ...uniqueTransmissions.map((transmission) => [
      transmission,
      `${transmission} (${transmissionCount[transmission]})`,
    ]),
  ];

  const aircondition = carRentals.results.map(
    (item) => item.vehicle.has_air_condition
  );
  const airconditionCount = _.countBy(aircondition);
  const uniqueAircondition = [...new Set(aircondition)];
  const airconditionOptions = [
    ["", "---"],
    ...uniqueAircondition.map((aircondition) => [
      aircondition,
      `${aircondition ? "YES" : "NO"} (${airconditionCount[aircondition]})`,
    ]),
  ];

  var averagePrice = 0;
  var maxPrice = 0;
  var minPrice = 0;
  const currency = _.get(
    carRentals,
    "computed_data.pricesRange.currency",
    "EUR"
  );

  if (carRentalsList.length > 0) {
    const prices = carRentalsList.map((car) => parseFloat(car.price.amount));
    const sum = prices.reduce((acc, price) => acc + price, 0);
    averagePrice = sum / prices.length;

    minPrice = Math.min(...prices);
    maxPrice = Math.max(...prices);
  }

  useEffect(() => {
    var filteredCarRentals = carRentals.results.filter((car) => {
      if (filters.provider && !car.provider.name.includes(filters.provider)) {
        return false;
      }
      if (filters.vehicle && !car.vehicle.name.includes(filters.vehicle)) {
        return false;
      }
      if (
        filters.passengers &&
        Number(car.vehicle.passenger_quantity) !== Number(filters.passengers)
      ) {
        return false;
      }
      if (
        filters.numOfDoors &&
        Number(car.vehicle.door_count) !== Number(filters.numOfDoors)
      ) {
        return false;
      }
      if (
        filters.transmission &&
        !car.vehicle.transmission_type.includes(filters.transmission)
      ) {
        return false;
      }
      const airconditionFilter = filters.aircondition === "true"; // convert string to boolean
      if (
        filters.aircondition &&
        car.vehicle.has_air_condition !== airconditionFilter
      ) {
        return false;
      }
      if (
        filters.min_price &&
        Number(car.price.amount) < Number(filters.min_price)
      ) {
        return false;
      }
      if (
        filters.max_price &&
        Number(car.price.amount) > Number(filters.max_price)
      ) {
        return false;
      }

      return true;
    });

    if (filters.order_by) {
      if (filters.order_by === "price") {
        filteredCarRentals.sort(
          (a, b) => parseFloat(a.price.amount) - parseFloat(b.price.amount)
        );
      } else if (filters.order_by === "-price") {
        filteredCarRentals.sort(
          (a, b) => parseFloat(b.price.amount) - parseFloat(a.price.amount)
        );
      }
    }

    setCarRentalsList(filteredCarRentals);
  }, [filters]);

  return (
    <Drawer size="lg" placement="right" show={true}>
      <Drawer.Header className="carRentals-drawer-header">
        <div></div>
        <Drawer.Title>Car Rentals</Drawer.Title>
        <IconButton
          className="close-btn"
          icon={<Icon icon="close" className="rs-icon" />}
          size="sm"
          placement="right"
          onClick={closeCarRentalsDrawer}>
          Close
        </IconButton>
      </Drawer.Header>
      <Drawer.Body className="carRentals-drawer-body">
        <FiltersBar
          options={carRentalsList.length}
          filters={filters}
          passengerOptions={passengerOptions}
          airconditionOptions={airconditionOptions}
          transmissionOptions={transmissionOptions}
          providerOptions={providerOptions}
          vehicleOptions={vehicleOptions}
          doorOptions={doorOptions}
          averagePrice={
            averagePrice !== 0 ? (
              <CarRentalsPrice
                currency={currency}
                price={averagePrice}
                customStyles={{
                  Price: { fontWeight: "normal", fontSize: "small" },
                }}
              />
            ) : (
              "N/A"
            )
          }
          minPrice={
            minPrice !== 0 ? (
              <CarRentalsPrice
                currency={currency}
                price={minPrice}
                customStyles={{
                  Price: { fontWeight: "normal", fontSize: "small" },
                }}
              />
            ) : (
              "N/A"
            )
          }
          maxPrice={
            maxPrice !== 0 ? (
              <CarRentalsPrice
                currency={currency}
                price={maxPrice}
                customStyles={{
                  Price: { fontWeight: "normal", fontSize: "small" },
                }}
              />
            ) : (
              "N/A"
            )
          }
          onSetFilters={(filters) => setFilters(filters)}
        />
        {carRentalsList.length ? (
          <React.Fragment>
            {carRentalsList.slice(0, pageLimit).map((carRental, idx) => (
              <div className="carRentals-drawer-body__content" key={idx}>
                <CarRental carRental={carRental} />
              </div>
            ))}
            {pageLimit < carRentalsList.length && (
              <button
                className="carRentals-drawer-body__showMoreBtn"
                onClick={() => increasePageSize()}>
                Show More
              </button>
            )}
          </React.Fragment>
        ) : (
          <div className="carRentals-drawer-body__content--noCars">
            No results found
            {noResultsSvg}
          </div>
        )}
      </Drawer.Body>
    </Drawer>
  );
};
OverviewAllCarRentals.propTypes = {
  closeCarRentalsDrawer: PropTypes.func.isRequired,
};
