import _ from "lodash";
import {
  Panel,
  IconButton,
  Icon,
  Slider,
  PanelGroup,
  List,
  Rate,
  Button,
} from "rsuite";

import { PrefActions } from "../Components/PreferencesControllersCommon";
import TransTxt from "@src/components/common/SxFormatMessage";
import PropTypes from "prop-types";
import React, {
  useCallback, // useContext,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { WhisperHelper } from "@src/components/common/ui_helpers";
import { getDestsWithStaySelector } from "@src/selectors/Project/TripPlanner/destination_selectors";
import { createUseStyles } from "react-jss";
import { cardStyles, variables } from "@src/jsssetup";
import SuIcon from "@src/style/icon/components/SuIcon";
// import { TripPlanContext } from "../TripPlanner";
import { accPrioritiesClear } from "@src/actions/Project/TripPlanner/accommodation_priorities";
import {
  accommodationEdit,
  accPrefAddRoom,
  accPrefChange,
  accPrefRemoveRoom,
  accPrefRoomPaxAdd,
  accPrefRoomPaxRemove,
  accRoomPaxAgeChange,
  resetPreferences,
  accConfirmAndSearch,
} from "@src/actions/Project/TripPlanner";
import { Form, Formik } from "formik";
import { ExtendedSelectField, NormalSelectField } from "@src/components/forms";

const roomSetupStyles = createUseStyles({
  RoomSetup: cardStyles.card,
  header: { ...cardStyles.header, padding: variables.half_gap },
  body: {
    ...cardStyles.body,
    padding: variables.half_gap,
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: variables.half_gap,
    justifyItems: "center",
    background: "white",
    borderRadius: [0, 0, variables.half_gap, variables.half_gap],
  },
  paxCategory: {
    display: "grid",
    gridTemplateColumns: "repeat(3, max-content)",
    gridGap: variables.half_gap,
    alignItems: "center",
  },
});
export const RoomSetup = ({
  idx,
  disabled,
  room,
  onPaxRemove,
  onPaxAdd,
  onAgeChange,
}) => {
  const classes = roomSetupStyles();

  const quantBtnProps = {
    disabled,
    appearance: "primary",
    circle: true,
    size: "xs",
  };

  const adults = room?.adults ?? 0;
  const children = room?.children ?? 0;

  return (
    <div className={classes.RoomSetup}>
      <div className={classes.header}>
        <strong>
          <TransTxt id="tripplan_room" startCase /> {idx + 1}
        </strong>
      </div>
      <div className={classes.body}>
        <div className={classes.paxCategory}>
          <IconButton
            {...quantBtnProps}
            icon={<Icon icon="minus" />}
            onClick={
              disabled || adults === 1 ? null : () => onPaxRemove(idx, "adults")
            }
          />
          <span>
            <TransTxt id="tripplan_adults" startCase /> {adults}
          </span>
          <IconButton
            {...quantBtnProps}
            icon={<Icon icon="plus" />}
            onClick={disabled ? null : () => onPaxAdd(idx, "adults")}
          />
        </div>
        <div className={classes.paxCategory}>
          <IconButton
            {...quantBtnProps}
            icon={<Icon icon="minus" />}
            onClick={
              disabled || children === 0
                ? null
                : () => onPaxRemove(idx, "children")
            }
          />
          <span>
            <TransTxt id="tripplan_children" startCase /> {children}
          </span>
          <IconButton
            {...quantBtnProps}
            icon={<Icon icon="plus" />}
            onClick={disabled ? null : () => onPaxAdd(idx, "children")}
          />
        </div>
        {new Array(room?.children ?? 0).fill(1).map((__, cidx) => {
          return (
            <div key={cidx}>
              <span>
                <TransTxt id="tripplan_child" startCase />
                {` ${cidx + 1} Age: ${(room?.children_ages ?? [])?.[cidx]}`}
              </span>
              <Slider
                disabled={disabled}
                defaultValue={(room?.children_ages ?? [])?.[cidx]}
                min={0}
                max={12}
                onChange={(value) => onAgeChange(idx, cidx, value)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
RoomSetup.propTypes = {
  idx: PropTypes.number.isRequired,
  room: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  onPaxAdd: PropTypes.func.isRequired,
  onPaxRemove: PropTypes.func.isRequired,
  onAgeChange: PropTypes.func.isRequired,
};

const hotelPrioritiesStyles = createUseStyles({
  destinationPriorities: (props) => ({
    padding: variables.normal_gap,
    borderLeft: `2px solid ${variables.colors.borders.base}`,
    display: "grid",
    gridGap: variables.half_gap,
    gridTemplateColumns: props.hidePriorities ? "max-content 1fr" : "1fr",
    gridAutoRows: "max-content",
    alignItems: "center",
  }),
  destinationPrioritiesHeader: (props) => ({
    gridColumn: props.hidePriorities ? "span 2" : "span 1",
    borderBottom: `2px solid ${variables.colors.borders.base}`,
  }),
  destinationPanelHeader: { display: "grid", gridGap: variables.half_gap },
  destinationPanelSubheader: { fontSize: "small" },
  destinationPanelActions: {
    display: "grid",
    gridTemplateColumns: "1fr repeat(2, max-content)",
    gridGap: variables.half_gap,
    alignItems: "center",
  },
  destinationPrioritiesDescription: { fontSize: "small" },
  priority: {
    "& .rs-list-item-content": { display: "grid", gridTemplateColumns: "1fr" },
  },
  [`@media ${variables.media.bigscreen}`]: {
    priority: {
      "& .rs-list-item-content": { gridTemplateColumns: "1fr max-content" },
    },
  },
});
const HotelPriorities = ({
  disabled,
  destinations,
  accPriorities,
  onEditDestinationPriorities,
}) => {
  const [editMode, setEditMode] = useState(false);

  const hidePriorities = _.isEmpty(accPriorities) && !editMode;
  const classes = hotelPrioritiesStyles({ hidePriorities });

  const dispatch = useDispatch();
  const onClearDestPriorities = useCallback(
    ({ destOrder }) => dispatch(accPrioritiesClear({ destOrder })),
    [dispatch]
  );

  return (
    <div className={classes.destinationPriorities}>
      <span className={classes.destinationPrioritiesHeader}>
        <strong>Hotel Priorities:</strong> None
      </span>
      {hidePriorities ? (
        <React.Fragment>
          <WhisperHelper
            msg={
              <p className={classes.destinationPrioritiesDescription}>
                With the <strong>Hotel Priorities List</strong> you can control
                which hotels will be auto-selected in each destination. Please
                note that hotel auto-selection is always dependent on room
                availability.
              </p>
            }
          />
          <Button
            disabled={disabled}
            appearance="primary"
            size="sm"
            onClick={() => setEditMode(true)}>
            <strong>Edit Hotel Priorities List</strong>
          </Button>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <span>
            <strong>Destination Hotel Priorities</strong>{" "}
            <WhisperHelper msg="Hotels added on this list will be prefered by the autoselection algorithm." />
          </span>
          <PanelGroup accordion defaultActiveKey={0} bordered>
            {destinations.map((dest, idx) => {
              const priorities = accPriorities?.[dest.order]?.hotels ?? [];
              const behaviour = accPriorities?.[dest.order]?.behaviour ?? "PR";

              return (
                <Panel
                  key={idx}
                  header={
                    <div className={classes.destinationPanelHeader}>
                      <h6>
                        <span>
                          {idx + 1}. {dest.name_en}: {priorities.length} Hotels
                          Selected
                        </span>
                      </h6>
                      <div className={classes.destinationPanelActions}>
                        <span className={classes.destinationPanelSubheader}>
                          Search Behaviour:{" "}
                          {behaviour == "PR" ? "Prefer" : "Restrict"}{" "}
                          <WhisperHelper
                            placement="left"
                            msg={
                              behaviour == "PR"
                                ? "When in Prefer mode, during the search process hotels in the priority list will be prefered for autoselection. In case none is available the next cheapest hotel will be selected."
                                : "When in Restrict mode, during the search process only hotels in the priority list will be autoselected. In case none is available then a no availability message will be displayed."
                            }
                          />
                        </span>
                        {priorities.length > 0 && (
                          <IconButton
                            disabled={disabled}
                            icon={<Icon icon="close" />}
                            color="red"
                            size="xs"
                            onClick={function () {
                              onClearDestPriorities({ destOrder: dest.order });
                            }}>
                            Clear
                          </IconButton>
                        )}
                        <IconButton
                          disabled={disabled}
                          icon={
                            <Icon icon={priorities.length ? "edit" : "plus"} />
                          }
                          color={priorities.length ? "blue" : "green"}
                          size="xs"
                          onClick={function () {
                            onEditDestinationPriorities(dest.order);
                          }}>
                          {priorities.length ? "Edit" : "Add"}
                        </IconButton>
                      </div>
                    </div>
                  }
                  eventKey={idx}>
                  {priorities.length > 0 && (
                    <List>
                      {priorities.map((acc, idx) => (
                        <List.Item key={idx} className={classes.priority}>
                          <strong>
                            {idx + 1}. {acc.metadata.name}
                          </strong>
                          <Rate
                            value={acc.metadata.rating}
                            size="xs"
                            readOnly={true}
                          />
                        </List.Item>
                      ))}
                    </List>
                  )}
                </Panel>
              );
            })}
          </PanelGroup>
        </React.Fragment>
      )}
    </div>
  );
};
HotelPriorities.propTypes = {
  disabled: PropTypes.bool.isRequired,
  destinations: PropTypes.array.isRequired,
  accPriorities: PropTypes.object.isRequired,
  onEditDestinationPriorities: PropTypes.func.isRequired,
};

const accommodationSetupStyles = createUseStyles({
  AccommodationSetup: cardStyles.card,
  header: {
    ...cardStyles.header,
    "gridTemplateColumns": "max-content 1fr",
    "& .sisi-family": {
      background: variables.colors.background.dark,
      borderRadius: "50%",
      height: "2rem",
      width: "2rem",
      display: "grid",
      placeItems: "center",
      color: "white",
    },
  },
  body: { ...cardStyles.body, display: "grid", gridGap: variables.normal_gap },
  actions: cardStyles.actions,
  generalPreference: {
    display: "grid",
    gridGap: variables.half_gap,
    gridAutoRows: "max-content",
  },
  inputs: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: variables.half_gap,
  },
  inputGroup: { display: "grid" },
  roomsController: {
    display: "grid",
    gridTemplateColumns: "repeat(3, max-content)",
    gridGap: variables.half_gap,
    alignItems: "center",
  },
  rooms: { display: "grid", gridGap: variables.normal_gap },
});
const AccommodationSetup = ({ onNextStep }) => {
  const dispatch = useDispatch();
  const onRoomAdd = useCallback(() => dispatch(accPrefAddRoom()), [dispatch]);
  const onRoomRemove = useCallback(
    () => dispatch(accPrefRemoveRoom()),
    [dispatch]
  );
  const onPaxAdd = useCallback(
    (roomIdx, paxType) => dispatch(accPrefRoomPaxAdd(roomIdx, paxType)),
    [dispatch]
  );
  const onPaxRemove = useCallback(
    (roomIdx, paxType) => dispatch(accPrefRoomPaxRemove(roomIdx, paxType)),
    [dispatch]
  );
  const onAgeChange = useCallback(
    (roomIdx, childIdx, age) =>
      dispatch(accRoomPaxAgeChange(roomIdx, childIdx, age)),
    [dispatch]
  );
  const accSearch = useCallback(
    () => dispatch(accConfirmAndSearch()),
    [dispatch]
  );
  const onEdit = useCallback(() => dispatch(accommodationEdit()), [dispatch]);
  const onReset = useCallback(
    (type) => dispatch(resetPreferences(type)),
    [dispatch]
  );
  const onChange = useCallback(
    (preferences) => dispatch(accPrefChange(preferences)),
    [dispatch]
  );

  // const { setAccPrioritiesDestOrder, setShowAccPrioritiesModal } =
  //   useContext(TripPlanContext);
  const classes = accommodationSetupStyles();

  const {
    rooms,
    // destinations,
    // accPriorities,
    preferences,
    editMode,
    currentTripStep,
  } = useSelector((state) => {
    const destinations = getDestsWithStaySelector(state);
    const accPriorities = state.tripPlannerAccPriorities;
    const rooms = state.tripPlannerAccPax;
    const preferences = state.tripPlannerAccPref;
    const editMode = state.tripPlannerAccPrefEditMode;

    const currentTripStep = state.tripPlannerCurrentStep;

    return {
      rooms,
      destinations,
      accPriorities,
      preferences,
      editMode,
      currentTripStep,
    };
  });

  useEffect(() => {
    if (currentTripStep <= 2) onEdit();
  }, [currentTripStep]);

  return (
    <Formik
      initialValues={{ preferences }}
      onSubmit={(values) => {
        onChange(values.preferences);
        accSearch();
        if (typeof onNextStep === "function") onNextStep();
      }}>
      {({ submitForm }) => {
        return (
          <div className={classes.AccommodationSetup}>
            <div className={classes.header}>
              <SuIcon icon="icon-Accommodation" />
              <h6>Accommodation</h6>
            </div>
            <div className={classes.body}>
              <Form className="Form">
                <div className={classes.generalPreference}>
                  <div className={classes.inputs}>
                    <ExtendedSelectField
                      label={
                        <TransTxt
                          id="tripplan_accommodation_rating"
                          startCase
                        />
                      }
                      name="preferences.rating"
                      multi={true}
                      options={[...new Array(5)].map((___, idx) => [
                        idx + 1,
                        `${idx + 1} Star${idx == 0 ? "" : "s"}`,
                      ])}
                    />
                    <NormalSelectField
                      label={
                        <TransTxt id="tripplan_accommodation_board" startCase />
                      }
                      name="preferences.board"
                      options={[
                        ["ALL", "All Boards"],
                        ["RO", "Room Only"],
                        ["BB", "Bed & Breakfast"],
                        ["HB", "Half Board"],
                        ["FB", "Full Board"],
                        ["AI", "All Inclusive"],
                      ]}
                    />
                    <NormalSelectField
                      label={
                        <TransTxt
                          id="tripplan_accommodation_bedding"
                          startCase
                        />
                      }
                      name="preferences.bedding"
                      options={[
                        ["ALL", "All Beddings"],
                        ["DBL", "Double"],
                        ["TWI", "Twin"],
                      ]}
                    />
                    <NormalSelectField
                      label={<TransTxt id="tripplan_room_type" startCase />}
                      name="preferences.type"
                      options={[
                        ["ALL", "All Types"],
                        ["STD", "Standard"],
                        ["DEL", "Deluxe"],
                        ["EXC", "Executive"],
                        ["SUI", "Suite"],
                      ]}
                    />
                  </div>
                  <div className={classes.roomsController}>
                    <IconButton
                      disabled={!editMode}
                      appearance="primary"
                      icon={<Icon icon="minus" />}
                      circle
                      size="xs"
                      onClick={editMode ? onRoomRemove : null}
                    />
                    <span>
                      <strong>
                        <TransTxt id="tripplan_rooms" startCase />:{" "}
                        {rooms.length}
                      </strong>
                    </span>
                    <IconButton
                      disabled={!editMode}
                      appearance="primary"
                      icon={<Icon icon="plus" />}
                      circle
                      size="xs"
                      onClick={editMode ? onRoomAdd : null}
                    />
                  </div>
                  <div className={classes.rooms}>
                    {rooms.map((r, idx) => (
                      <RoomSetup
                        key={idx}
                        idx={idx}
                        room={r}
                        disabled={!editMode}
                        onPaxAdd={onPaxAdd}
                        onPaxRemove={onPaxRemove}
                        onAgeChange={onAgeChange}
                      />
                    ))}
                  </div>
                </div>
              </Form>
              {/* <HotelPriorities */}
              {/*   disabled={!editMode} */}
              {/*   destinations={destinations} */}
              {/*   accPriorities={accPriorities} */}
              {/*   onEditDestinationPriorities={(destOrder) => { */}
              {/*     setAccPrioritiesDestOrder(destOrder); */}
              {/*     setShowAccPrioritiesModal(true); */}
              {/*   }} */}
              {/* /> */}
            </div>
            <div className={classes.actions}>
              <PrefActions
                editMode={editMode}
                onConfirm={submitForm}
                onEdit={onEdit}
                onReset={() => onReset("accommodation")}
              />
            </div>
          </div>
        );
      }}
    </Formik>
  );
};
AccommodationSetup.propTypes = { onNextStep: PropTypes.func };
export default AccommodationSetup;
