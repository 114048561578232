import { ACCOMMODATION_RESERVATION_INIT } from "@src/actions/Operation/Accommodation/types";
import {
  SHOW_RESERVATION_REQUEST_OPTIONCONF_MODAL,
  HIDE_RESERVATION_REQUEST_OPTIONCONF_MODAL,
  LOADING_RESERVATION_REQUEST_OPTIONCONF_MODAL,
  IDLE_RESERVATION_REQUEST_OPTIONCONF_MODAL,
  SAVE_RESERVATION_REQUEST_OPTIONCONF_DATA,
  SHOW_RESERVATION_REQUEST_VOUCHER_DOWNLOAD_MODAL,
  HIDE_RESERVATION_REQUEST_VOUCHER_DOWNLOAD_MODAL,
  // ====================== PAYMENT CONFIRMATION =====================
  SHOW_RESERVATION_REQUEST_OPTIONCONF_PAYMENT_MODAL,
  HIDE_RESERVATION_REQUEST_OPTIONCONF_PAYMENT_MODAL,
  LOADING_RESERVATION_REQUEST_OPTIONCONF_PAYMENT_MODAL,
  IDLE_RESERVATION_REQUEST_OPTIONCONF_PAYMENT_MODAL,
  RESERVATION_EDIT_STATUS_MODAL_SHOW,
  RESERVATION_EDIT_STATUS_MODAL_HIDE,
  RESERVATION_EDIT_STATUS_MODAL_LOADING,
  RESERVATION_EDIT_STATUS_MODAL_IDLE,
  RESERVATION_EDIT_STATUS_MODAL_CHANGE,
  RESERVATION_EVENTS_MODAL_SHOW,
  RESERVATION_EVENTS_MODAL_HIDE,
} from "@src/actions/Operation/Reservations/types";

import update from "immutability-helper";
import { TRF_RESERVATION_INIT } from "@src/actions/Operation/Transfers/types";
import { ADDON_RESERVATION_DETAILS_INIT } from "@src/actions/Operation/AddOns/types";

export const resRqOptionConfModalInitial = {
  show: false,
  loading: false,
  option_confirmation_info: {},
  srvType: "",
  reference: null,
};

export const reservationRqOptionConfModal = (
  state = resRqOptionConfModalInitial,
  action
) => {
  switch (action.type) {
    case ACCOMMODATION_RESERVATION_INIT:
      return resRqOptionConfModalInitial;
    case TRF_RESERVATION_INIT:
      return resRqOptionConfModalInitial;
    case ADDON_RESERVATION_DETAILS_INIT:
      return resRqOptionConfModalInitial;
    case SHOW_RESERVATION_REQUEST_OPTIONCONF_MODAL:
      return update(state, {
        show: { $set: true },
        srvType: { $set: action.srvType },
        reference: { $set: action.reference },
      });
    case HIDE_RESERVATION_REQUEST_OPTIONCONF_MODAL:
      return resRqOptionConfModalInitial;
    case LOADING_RESERVATION_REQUEST_OPTIONCONF_MODAL:
      return update(state, { loading: { $set: true } });
    case IDLE_RESERVATION_REQUEST_OPTIONCONF_MODAL:
      return update(state, { loading: { $set: false } });
    default:
      return state;
  }
};

export const resRqOptionConfPaymentModalInitial = {
  show: false,
  loading: false,
  option_confirmation_info: {},
  srvType: "",
  reference: null,
};

export const resRqOptionConfPaymentModal = (
  state = resRqOptionConfPaymentModalInitial,
  action
) => {
  switch (action.type) {
    case ACCOMMODATION_RESERVATION_INIT:
      return resRqOptionConfPaymentModalInitial;
    case TRF_RESERVATION_INIT:
      return resRqOptionConfPaymentModalInitial;
    case ADDON_RESERVATION_DETAILS_INIT:
      return resRqOptionConfPaymentModalInitial;
    case SHOW_RESERVATION_REQUEST_OPTIONCONF_PAYMENT_MODAL:
      return update(state, {
        show: { $set: true },
        srvType: { $set: action.srvType },
        reference: { $set: action.reference },
      });
    case HIDE_RESERVATION_REQUEST_OPTIONCONF_PAYMENT_MODAL:
      return resRqOptionConfModalInitial;
    case LOADING_RESERVATION_REQUEST_OPTIONCONF_PAYMENT_MODAL:
      return update(state, { loading: { $set: true } });
    case IDLE_RESERVATION_REQUEST_OPTIONCONF_PAYMENT_MODAL:
      return update(state, { loading: { $set: false } });
    default:
      return state;
  }
};

export const reservationRqOptionConfData = (state = {}, action) => {
  switch (action.type) {
    case ACCOMMODATION_RESERVATION_INIT:
      return {};
    case TRF_RESERVATION_INIT:
      return {};
    case ADDON_RESERVATION_DETAILS_INIT:
      return {};
    case SAVE_RESERVATION_REQUEST_OPTIONCONF_DATA:
      return action.data;
    default:
      return state;
  }
};

export const reservationEditStatusModalInitial = {
  show: false,
  loading: false,
  srvType: "ACC", //[FL, ACC, TRF, MI]
  status: "",
};
export const reservationEditStatusModal = (
  state = reservationEditStatusModalInitial,
  action
) => {
  switch (action.type) {
    case RESERVATION_EDIT_STATUS_MODAL_SHOW: {
      const { srvType, status } = action;
      return { ...state, show: true, srvType, status };
    }
    case RESERVATION_EDIT_STATUS_MODAL_HIDE:
      return reservationEditStatusModalInitial;
    case RESERVATION_EDIT_STATUS_MODAL_LOADING:
      return { ...state, loading: true };
    case RESERVATION_EDIT_STATUS_MODAL_IDLE:
      return { ...state, loading: false };
    case RESERVATION_EDIT_STATUS_MODAL_CHANGE: {
      const { status } = action;
      return { ...state, status };
    }
    default:
      return state;
  }
};

export const reservationVoucherPDFModalInitial = {
  show: false,
  loading: false,
  srvType: "",
};
export const reservationVoucherPDFModal = (
  state = reservationVoucherPDFModalInitial,
  action
) => {
  switch (action.type) {
    case SHOW_RESERVATION_REQUEST_VOUCHER_DOWNLOAD_MODAL:
      return { ...state, show: true, srvType: action.srvType };
    case HIDE_RESERVATION_REQUEST_VOUCHER_DOWNLOAD_MODAL:
      return { ...state, show: false };
    default:
      return state;
  }
};

export const initEventsModal = { show: false, data: {} };
export const reservationEventsModal = (state = initEventsModal, action) => {
  switch (action.type) {
    case RESERVATION_EVENTS_MODAL_SHOW:
      return { show: true, data: action.data };
    case RESERVATION_EVENTS_MODAL_HIDE:
      return initEventsModal;
    default:
      return state;
  }
};

export * from "./addons";
