import {
  // ========================== INIT ACTIONS =========================
  TRIPPLANNER_INIT,
  // ======================= TRIP LOAD ACTIONS =======================
  TRIPPLANNER_LOAD_TRIP,
  ITINERARY_SETUP_FORM_CHANGE,
  TRIPPLANNER_SETUP_CHANGE,
  TRIPPLANNER_ADD_PAX,
  TRIPPLANNER_REMOVE_PAX,
  TRIPPLANNER_CHANGE_PAX,
  TRIPPLANNER_UPDATE_PREFERENCES,
} from "@src/actions/Project/TripPlanner/types";

import { FINANCIAL_SET_CURRENCY } from "@src/actions/Financial/types";

import { convert, unConvert } from "@src/tools/pricing_tools";

import update from "immutability-helper";
import { TRIPPLANNER_SET_CXL_POLICY_DATA } from "@src/actions/Project/TripPlanner/pricing";

const tripPlannerItinerarySetupFormInitial = {
  adults: 2,
  children: 0,
  children_ages: [],
  title: "My Trip",
  usage: "OFF",
  budget: 0,
  requiredServices: [],
  nationality: "",
  external_reference: "",
  target_entity: "",
  linked_offer: "",
  linked_references: [],
};
export const tripPlannerItinerarySetupForm = (
  state = tripPlannerItinerarySetupFormInitial,
  action
) => {
  switch (action.type) {
    case TRIPPLANNER_INIT: {
      const { defaultNationality } = action;
      return {
        ...tripPlannerItinerarySetupFormInitial,
        nationality: defaultNationality,
      };
    }
    case TRIPPLANNER_LOAD_TRIP: {
      return {
        ...action.data.itinerary_setup,
        external_reference: action.data.external_reference,
        target_entity: action.data.target_entity,
        linked_offer: action.data.link,
        linked_references: action?.data?.linked_references ?? [],
      };
    }
    case ITINERARY_SETUP_FORM_CHANGE: {
      const { type, name, value } = action.data;
      if (type == "array") {
        if (action.data.action == "push") {
          return { ...state, [name]: [...new Set([...state[name], value])] };
        } else if (action.data.action == "remove") {
          return update(state, {
            [name]: { $apply: (va) => va.filter((v) => v !== value) },
          });
        } else {
          return state;
        }
      } else if (type == "text" || type == "number") {
        return update(state, { [name]: { $set: value } });
      } else {
        return state;
      }
    }
    case TRIPPLANNER_SETUP_CHANGE: {
      const { name, value } = action;
      return update(state, { [name]: { $set: value } });
    }
    case TRIPPLANNER_UPDATE_PREFERENCES: {
      const { data } = action;
      return update(state, { $merge: data });
    }
    case FINANCIAL_SET_CURRENCY: {
      const { notation, rate, currentCurrency, globalCurrency } = action;
      // If currencies are equal just return then state as is.
      if (notation == state.currency) {
        return state;
      }

      // If budget is zero there is no need to do any conversions. Just set
      // the new currency.
      if (state.budget == 0) {
        return update(state, { currency: { $set: notation } });
      }

      if (state.currency == globalCurrency.notation) {
        return update(state, {
          currency: { $set: notation },
          budget: {
            $set: convert(
              state.budget,
              globalCurrency.notation,
              notation,
              rate
            ),
          },
        });
      } else {
        return update(state, {
          currency: { $set: notation },
          budget: {
            $set: convert(
              unConvert(
                state.budget,
                globalCurrency.notation,
                state.currency,
                currentCurrency.rate
              ),
              globalCurrency.notation,
              notation,
              rate
            ),
          },
        });
      }
    }
    case TRIPPLANNER_ADD_PAX: {
      const { paxType } = action;
      return { ...state, [paxType]: state[paxType] + 1 };
    }
    case TRIPPLANNER_REMOVE_PAX: {
      const { paxType } = action;
      return { ...state, [paxType]: state[paxType] - 1 };
    }
    case TRIPPLANNER_CHANGE_PAX: {
      const { paxType, value } = action;
      return { ...state, [paxType]: value };
    }
    default:
      return state;
  }
};

const tripPlannerCxlReducerInitial = {
  mode: "prepaid",
  buffer: 5,
  cancellation_policies: [],
  deposit: 0,
  balance: 0,
  currency: null,
  priceBuffer: 0,
  rounding: "round-up",
  totalPriceRounding: "round-up",
};
export const tripPlannerCxlReducer = (
  state = tripPlannerCxlReducerInitial,
  action
) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return tripPlannerCxlReducerInitial;
    case TRIPPLANNER_LOAD_TRIP:
      return (
        action?.data?.custom_cancellation_policy ?? tripPlannerCxlReducerInitial
      );
    case TRIPPLANNER_SET_CXL_POLICY_DATA:
      return action.data;
    default:
      return state;
  }
};
