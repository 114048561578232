import React from "react";
import { Route, Switch } from "react-router-dom";

import CoordinatorResFeedback from "@src/public_pages/reservation_feedback/coordinators";
import RestaurantResFeeback from "@src/public_pages/reservation_feedback/restaurants";
import CoachResFeedback from "@src/public_pages/reservation_feedback/coach";
import FerryResFeedback from "@src/public_pages/reservation_feedback/ferry";
import TrainResFeedback from "@src/public_pages/reservation_feedback/train";
import GeneralServiceResFeeback from "@src/public_pages/reservation_feedback/general_services";
import AccommodationResFeedback from "@src/public_pages/reservation_feedback/accommodation";
import SuccessfullPage from "@src/public_pages/reservation_feedback/successfullPage";

export default function PublicRoutes() {
  return (
    <Switch>
      <Route
        path="/public/reservation_feedback/coordinator/:reference"
        exact
        component={CoordinatorResFeedback}
      />
      <Route
        path="/public/reservation_feedback/restaurant/:reference"
        exact
        component={RestaurantResFeeback}
      />
      <Route
        path="/public/reservation_feedback/coach/:reference"
        exact
        component={CoachResFeedback}
      />
      <Route
        path="/public/reservation_feedback/ferry/:reference"
        exact
        component={FerryResFeedback}
      />
      <Route
        path="/public/reservation_feedback/train/:reference"
        exact
        component={TrainResFeedback}
      />
      <Route
        path="/public/reservation_feedback/general_service/:reference"
        exact
        component={GeneralServiceResFeeback}
      />
      <Route path="/public/reservation_feedback/accommodation/:reference" exact>
        <AccommodationResFeedback type="ACC" />
      </Route>
      <Route
        path="/public/reservation_feedback/adhoc_accommodation/:reference"
        exact>
        <AccommodationResFeedback type="ADD" />
      </Route>
      <Route
        path="/public/reservation_feedback/custom_accommodation/:reference"
        exact>
        <AccommodationResFeedback type="CU" />
      </Route>
      <Route
        path="/public/reservation_feedback/success-reservation"
        exact
        component={SuccessfullPage}
      />
    </Switch>
  );
}
