export const TRIPPLANNER_SAVEAS_MODAL_SHOW = "TRIPPLANNER_SAVEAS_MODAL_SHOW";
export const TRIPPLANNER_SAVEAS_MODAL_HIDE = "TRIPPLANNER_SAVEAS_MODAL_HIDE";
export const TRIPPLANNER_SAVEAS_MODAL_LOADING =
  "TRIPPLANNER_SAVEAS_MODAL_LOADING";
export const TRIPPLANNER_SAVEAS_MODAL_IDLE = "TRIPPLANNER_SAVEAS_MODAL_IDLE";
export const TRIPPLANNER_SAVEAS_MODAL_CHANGE =
  "TRIPPLANNER_SAVEAS_MODAL_CHANGE";

// ========================= QUOTATION MODAL =========================
export const TRIPPLANNER_QUOTATION_MODAL_SHOW =
  "TRIPPLANNER_QUOTATION_MODAL_SHOW";
export const TRIPPLANNER_QUOTATION_MODAL_HIDE =
  "TRIPPLANNER_QUOTATION_MODAL_HIDE";
export const TRIPPLANNER_QUOTATION_MODAL_LOADING =
  "TRIPPLANNER_QUOTATION_MODAL_LOADING";
export const TRIPPLANNER_QUOTATION_MODAL_IDLE =
  "TRIPPLANNER_QUOTATION_MODAL_IDLE";
export const TRIPPLANNER_SERVICE_COMMENTS_MODAL_SHOW =
  "TRIPPLANNER_SERVICE_COMMENTS_MODAL_SHOW";
export const TRIPPLANNER_SERVICE_COMMENTS_MODAL_HIDE =
  "TRIPPLANNER_SERVICE_COMMENTS_MODAL_HIDE";
