import {
  GROUP_POLICY_FETCH_DATA,
  GROUP_POLICY_BEGIN_FETCH_DATA,
  GROUP_POLICY_END_FETCH_DATA,
  GROUP_POLICY_INITIALIZE_LIST_FILTER_FORM,
  GROUP_POLICY_UPDATE_LIST_FILTER_FORM,
  GROUP_POLICY_APPLY_LIST_FILTER,
  GROUP_POLICY_UPDATE_LIST_COLUMNS_FORM,
  GROUP_POLICY_APPLY_LIST_COLUMNS,
  GROUP_POLICY_INITIALIZE_LIST_COLUMNS_FORM,
  GROUP_POLICY_FETCH_OPTIONS,
  GROUP_POLICY_CLEAN_BASE_DATA_EDIT_FORM,
  GROUP_POLICY_EDIT_BASE_DATA,
  GROUP_POLICY_VALIDATE_BASE_DATA_EDIT_FORM,
  GROUP_POLICY_RESET_LIST_FILTER_FORM,
  FORMAT_BRANCH_LIST_DATA,
  FORMAT_SUB_AGENT_LIST_DATA,
  FORMAT_INDEPENDENT_AGENT_LIST_DATA,
  GROUP_POLICY_FETCH_GRAPH_DATA,
  //=============================EDIT=======================
  GROUP_POLICY_INIT_BASE_DATA_EDIT_FORM,
  GROUP_POLICY_FETCH_EDIT_DATA,
  //==================================MODAL================================
  GROUP_POLICY_HIDE_FORM_MODAL,
  GROUP_POLICY_SHOW_FORM_MODAL,
  GROUP_POLICY_LOADING_EDIT_FORM,
  GROUP_POLICY_IDLE_EDIT_FORM,
  GROUP_POLICY_EDIT_CHANGE,
} from "@src/actions/Admin/GroupPolicy/types";

import { Schema } from "rsuite";

import {
  FORMAT_TRAVEL_SERVICES_OPTIONS,
  FORMAT_TRAVEL_SERVICES_FORM,
} from "@src/actions/Shares/types";

import {
  initialGroupPolicyColumnsSetUp,
  initialGroupPolicyFilterForm,
  initialGroupPolicyBaseDataForm,
} from "@src/forms/Admin/GroupPolicy";

import update from "immutability-helper";
import _ from "lodash";
import { formProcessor, entitiesListSelector } from "@src/reducers/tools";

export const initialGroupPolicyFetchStatus = "IDLE";
export const groupPolicyFetchStatus = (
  state = initialGroupPolicyFetchStatus,
  action
) => {
  switch (action.type) {
    case GROUP_POLICY_BEGIN_FETCH_DATA:
      return "FETCHING";
    case GROUP_POLICY_END_FETCH_DATA:
      return "FETCHED";
    default:
      return state;
  }
};

export const initialGroupPolicyList = {
  count: 0,
  next: null,
  previous: null,
  results: [],
  page: 1,
  limit: 20,
  displayLength: 100,
};

export const groupPolicyList = (state = initialGroupPolicyList, action) => {
  switch (action.type) {
    case GROUP_POLICY_FETCH_DATA:
      return action.data;
    case GROUP_POLICY_RESET_LIST_FILTER_FORM:
      return { ...state, page: 1 };
    default:
      return state;
  }
};

// columns
export const groupPolicyListColumns = (
  state = entitiesListSelector(initialGroupPolicyColumnsSetUp),
  action
) => {
  switch (action.type) {
    case GROUP_POLICY_APPLY_LIST_COLUMNS:
      return entitiesListSelector(action.data);
    default:
      return state;
  }
};

export const groupPolicyListColumnsForm = (
  state = initialGroupPolicyColumnsSetUp,
  action
) => {
  switch (action.type) {
    case GROUP_POLICY_INITIALIZE_LIST_COLUMNS_FORM: {
      var newState = {};
      const tmp = _.cloneDeep(state);
      for (const key in tmp) {
        // clear form's value
        tmp[key] = update(tmp[key], { value: { $set: [] } });
        newState[key] = update(tmp[key], { value: { $set: [] } });
        // add value by action data
        action.data.forEach((item) => {
          if (_.find(tmp[key].choices, { key: item.key })) {
            newState[key] = update(newState[key], {
              value: { $push: [item.key] },
            });
          }
        });
      }
      return newState;
    }
    case GROUP_POLICY_UPDATE_LIST_COLUMNS_FORM:
      return action.data;
    default:
      return state;
  }
};

// filter
export const groupPolicyListFilter = (
  state = initialGroupPolicyFilterForm,
  action
) => {
  switch (action.type) {
    case GROUP_POLICY_APPLY_LIST_FILTER:
      return action.data;
    case FORMAT_TRAVEL_SERVICES_FORM:
      return update(state, { $merge: action.data });
    default:
      return state;
  }
};

export const groupPolicyListFilterForm = (
  state = initialGroupPolicyFilterForm,
  action
) => {
  switch (action.type) {
    case GROUP_POLICY_INITIALIZE_LIST_FILTER_FORM:
      return action.data;
    case GROUP_POLICY_RESET_LIST_FILTER_FORM:
      return action.data;
    case GROUP_POLICY_UPDATE_LIST_FILTER_FORM:
      return formProcessor(
        state,
        action.data.type,
        action.data.name,
        action.data.value
      );
    case FORMAT_TRAVEL_SERVICES_FORM:
      return update(state, { $merge: action.data });
    case FORMAT_TRAVEL_SERVICES_OPTIONS:
      return update(state, { $merge: action.data });
    case GROUP_POLICY_FETCH_OPTIONS:
      return update(state, { $merge: action.data.travel_entities });
    default:
      return state;
  }
};

//add list
export const groupPolicyBaseDataForm = (
  state = initialGroupPolicyBaseDataForm,
  action
) => {
  switch (action.type) {
    case GROUP_POLICY_INIT_BASE_DATA_EDIT_FORM:
      return action.data;
    case GROUP_POLICY_CLEAN_BASE_DATA_EDIT_FORM: {
      const data = _.merge(initialGroupPolicyBaseDataForm, action.data);
      return update(data, {
        sales_partners: { value: { $set: [] } },
      });
    }
    case GROUP_POLICY_EDIT_BASE_DATA:
      return formProcessor(
        _.merge(state, action.data),
        action.data.type,
        action.data.name,
        action.data.value
      );
    case GROUP_POLICY_EDIT_CHANGE: {
      const { key, value } = action;
      return update(state, { [key]: { $set: value } });
    }
    case GROUP_POLICY_FETCH_OPTIONS:
      return update(state, { $merge: action.data.travel_entities });
    case FORMAT_TRAVEL_SERVICES_FORM:
      return update(state, { $merge: action.data });
    case FORMAT_TRAVEL_SERVICES_OPTIONS:
      return update(state, { $merge: action.data });
    case FORMAT_BRANCH_LIST_DATA:
      return _.cloneDeep({ ...state, ...action.data });
    case FORMAT_SUB_AGENT_LIST_DATA:
      return _.cloneDeep({ ...state, ...action.data });
    case FORMAT_INDEPENDENT_AGENT_LIST_DATA:
      return _.cloneDeep({ ...state, ...action.data });
    default:
      return state;
  }
};

export const groupPolicyOptions = (state = {}, action) => {
  switch (action.type) {
    case GROUP_POLICY_FETCH_OPTIONS:
      return update(state, { $merge: action.data });
    default:
      return state;
  }
};

// validate
export const initialGroupPolicyBaseDataFormErrorsState = { valid: false };
export const groupPolicyPolicyBaseDataFormErrors = (
  state = initialGroupPolicyBaseDataFormErrorsState,
  action
) => {
  const tmp = {};
  Object.entries(initialGroupPolicyBaseDataForm).forEach((e) => {
    tmp[e[0]] = e[1].validator;
  });
  const validator = Schema.Model(tmp);

  switch (action.type) {
    case GROUP_POLICY_VALIDATE_BASE_DATA_EDIT_FORM: {
      const tmp = {};
      Object.entries(action.data).forEach((e) => {
        tmp[e[0]] = e[1].value;
      });
      const validation = validator.check(tmp);

      const valid = Object.entries(validation).every((e) => !e[1].hasError);
      validation["valid"] = valid;
      return validation;
    }
    default:
      return state;
  }
};

export const groupPolicyGraphData = (state = {}, action) => {
  switch (action.type) {
    case GROUP_POLICY_FETCH_GRAPH_DATA:
      return action.data;
    default:
      return state;
  }
};

export const groupPolicyEditData = (state = {}, action) => {
  switch (action.type) {
    case GROUP_POLICY_FETCH_EDIT_DATA:
      return action.data;
    default:
      return state;
  }
};

export const initialGroupPolicyEditFormStatus = {
  loading: false,
  show: false,
  mode: "",
};
export const groupPolicyFormModal = (
  state = initialGroupPolicyEditFormStatus,
  action
) => {
  switch (action.type) {
    case GROUP_POLICY_LOADING_EDIT_FORM:
      return { ...state, loading: true };
    case GROUP_POLICY_IDLE_EDIT_FORM:
      return { ...state, loading: false };
    case GROUP_POLICY_SHOW_FORM_MODAL:
      return { ...state, show: true, mode: action.data.mode };
    case GROUP_POLICY_HIDE_FORM_MODAL:
      return initialGroupPolicyEditFormStatus;
    default:
      return state;
  }
};
