import {
  CUSTOM_FLIGHT_INIT,
  CUSTOM_FLIGHT_INIT_FROM_DATA,
  CUSTOM_FLIGHT_CHANGE_STOPS,
  CUSTOM_FLIGHT_CHANGE_SEGMENTS_NUMBER,
  CUSTOM_FLIGHT_CHANGE_AIRPORT,
  CUSTOM_FLIGHT_CHANGE_SEGMENT_DATETIME,
  CUSTOM_FLIGHT_CHANGE_SEG_SIMPLE_DATA,
  CUSTOM_FLIGHT_CHANGE_AIRLINE,
  CUSTOM_FLIGHT_CHANGE_SEGMENT_AIRLINE,
  CUSTOM_FLIGHT_CHANGE_AIRCRAFT,
} from "./types";

export const initCustomFlight = () => {
  return { type: CUSTOM_FLIGHT_INIT };
};

export const initCustomFlightFromData = (data) => {
  return { type: CUSTOM_FLIGHT_INIT_FROM_DATA, data };
};

export const changeStops = (stops, legType) => {
  return { type: CUSTOM_FLIGHT_CHANGE_STOPS, legs: stops, legType };
};

export const changeSegmentsNumber = (legType, segments_number) => {
  return { type: CUSTOM_FLIGHT_CHANGE_SEGMENTS_NUMBER, segments_number };
};

export const changeAirport = (legType, segmentIdx, airport_type, airport) => {
  return {
    type: CUSTOM_FLIGHT_CHANGE_AIRPORT,
    legType,
    segmentIdx,
    airport_type,
    airport,
  };
};

export const changeSegmentDateTime = (
  legType,
  segment_idx,
  datetime_type,
  datetime
) => {
  return {
    type: CUSTOM_FLIGHT_CHANGE_SEGMENT_DATETIME,
    legType,
    segment_idx,
    datetime_type,
    datetime,
  };
};

export const changeSegmentSimpleData = (
  fieldName,
  legType,
  segment_idx,
  flNumber
) => {
  return {
    type: CUSTOM_FLIGHT_CHANGE_SEG_SIMPLE_DATA,
    fieldName,
    legType,
    segment_idx,
    flNumber,
  };
};

export const changeAirline = (legType, airline) => {
  return { type: CUSTOM_FLIGHT_CHANGE_AIRLINE, legType, airline };
};

export const changeSegmentAirline = (legType, segment_idx, airline) => {
  return {
    type: CUSTOM_FLIGHT_CHANGE_SEGMENT_AIRLINE,
    legType,
    segment_idx,
    airline,
  };
};

export const changeSegmentAircraft = (legType, segment_idx, aircraft) => {
  return {
    type: CUSTOM_FLIGHT_CHANGE_AIRCRAFT,
    legType,
    segment_idx,
    aircraft,
  };
};
