import { Icon } from "rsuite";

import PropTypes from "prop-types";
import React from "react";

class Stepper extends React.Component {
  constructor(props) {
    super(props);
  }
  renderStep(step, extremal = false) {
    const { currentStep, previousStepAsDone } = this.props;

    const secTxt =
      step.description !== "" ? (
        <div className="secondary-text">{step.description}</div>
      ) : null;

    var countCls = "pending";
    if (currentStep == step.id) {
      countCls = "current";
    } else if (currentStep > step.id) {
      countCls = "done";
    }

    const textCls = currentStep == step.id ? " current" : "";
    const befCls = step.id <= currentStep ? "done" : "pending";
    const aftCls = step.id >= currentStep ? "pending" : "done";
    const befLine = !extremal ? (
      <div className={`step-line ${befCls}`}></div>
    ) : null;
    const aftLine = !extremal ? (
      <div className={`step-line ${aftCls}`}></div>
    ) : null;

    return (
      <div key={step.id} className={`step${extremal ? " extremal-step" : ""}`}>
        {befLine}
        <div className="step-content">
          <div className={`step-count ${countCls}`}>
            {previousStepAsDone ? (
              currentStep > step.id ? (
                <Icon icon="check" />
              ) : (
                step.id
              )
            ) : (
              step.id
            )}
          </div>
          <div className="text-content">
            <div className={`main-text${textCls}`}>{step.verbose}</div>
            {secTxt}
          </div>
        </div>
        {aftLine}
      </div>
    );
  }
  renderExtremal(type) {
    const idx = type == "first" ? 0 : this.props.steps.length - 1;
    return this.renderStep(this.props.steps[idx], true);
  }
  renderSteps() {
    return this.props.steps
      .filter((s, idx) => idx > 0 && idx < this.props.steps.length - 1)
      .map((s) => this.renderStep(s));
  }
  render() {
    return (
      <div className="Stepper">
        {this.renderExtremal("first")}
        <div className="middle-steps">{this.renderSteps()}</div>
        {this.renderExtremal("last")}
      </div>
    );
  }
}

Stepper.defaultProps = {
  previousStepAsDone: false,
};

Stepper.propTypes = {
  steps: PropTypes.array.isRequired,
  previousStepAsDone: PropTypes.bool,
  currentStep: PropTypes.number.isRequired,
};

export default Stepper;
