import {
  // ========================== INIT ACTIONS =========================
  TRIPPLANNER_INIT,
  // ======================= TRIP LOAD ACTIONS =======================
  TRIPPLANNER_LOAD_TRIP,
  TRIP_MAP_REDRAW,
  GOTO_NEXT_STEP,
  GOTO_PREV_STEP,
  // ======================= SEARCHING STATUSES ======================
  TRIPPLANNER_TRANSPORTATION_FAST_SEARCHING,
  TRIPPLANNER_TRANSPORTATION_FAST_IDLE,
  TRIPPLANNER_FLIGHT_FAST_STORE_RESULTS,
  // =================== TRIPPLANNER INSTANCE DATA ===================
  TRIPPLANNER_INSTANCE_DATA_UPDATE,
  TRIPPLANNER_BOOKING_DATA_UPDATE,
  TRIPPLANNER_SET_MODE,
} from "@src/actions/Project/TripPlanner/types";

import _ from "lodash";
import update from "immutability-helper";
import { TRIPPLANNER_UPDATE_OFFER_DATA } from "@src/actions/Project/TripPlanner/offer_actions";

export const tripPlannerMapDrawVersion = (state = 0, action) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return 0;
    case TRIP_MAP_REDRAW:
      return state + 1;
    default:
      return state;
  }
};

export const tripPlannerCurrentStep = (state = 1, action) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return 1;
    case TRIPPLANNER_LOAD_TRIP:
      return action.data.current_step;
    case GOTO_NEXT_STEP:
      return action.currentStep + 1;
    case GOTO_PREV_STEP:
      return action.currentStep - 1;
    default:
      return state;
  }
};

export const tripPlannerTrpFastSearching = (state = false, action) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return false;
    case TRIPPLANNER_LOAD_TRIP:
      return false;
    case TRIPPLANNER_TRANSPORTATION_FAST_SEARCHING:
      return true;
    case TRIPPLANNER_TRANSPORTATION_FAST_IDLE:
      return false;
    default:
      return state;
  }
};

export const tripPlannerFlightFastData = (state = {}, action) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return {};
    case TRIPPLANNER_LOAD_TRIP:
      return {};
    case TRIPPLANNER_FLIGHT_FAST_STORE_RESULTS:
      return update(state, {
        [action.legType]: {
          $apply: (leg) =>
            typeof leg !== "undefined"
              ? [...leg, ...action.results]
              : action.results,
        },
      });
    default:
      return state;
  }
};

const tripPlannerInstanceDataInitial = {
  reference: null,
  id: null,
  link: null,
  linked_trips: null,
  created: null,
  edited: null,
  creator: null,
  mode: "normal", // normal or recalculate
  servicebundle: {},
  booked_services: {},
  quotation_request_metadata: null,
};
export const tripPlannerInstanceData = (
  state = tripPlannerInstanceDataInitial,
  action
) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return tripPlannerInstanceDataInitial;
    case TRIPPLANNER_LOAD_TRIP: {
      return update(_.cloneDeep(tripPlannerInstanceDataInitial), {
        reference: { $set: action.data.reference },
        external_reference: { $set: action.data.external_reference },
        id: { $set: action.data.id },
        link: { $set: action.data.link },
        linked_trips: { $set: action.data.linked_trips },
        created: { $set: action.data.created },
        edited: { $set: action.data.edited },
        creator: { $set: action.data.creator },
        mode: { $set: action.mode },
        version: { $set: action?.data?.version },
        trip_instance_type: { $set: action?.data?.trip_instance_type },
        booked_services: { $set: action?.data?.booked_services },
        quotation_request_metadata: {
          $set: action?.data?.quotation_request_metadata,
        },
        invoices: { $set: action?.data?.invoices ?? [] },
      });
    }
    case TRIPPLANNER_INSTANCE_DATA_UPDATE:
      return { ...state, ...action.data };
    case TRIPPLANNER_BOOKING_DATA_UPDATE:
      return update(state, { servicebundle: { $set: action.servicebundle } });
    case TRIPPLANNER_SET_MODE: {
      const { mode } = action;
      return update(state, { mode: { $set: mode } });
    }
    default:
      return state;
  }
};

const tripPlannerOfferInstanceDataInitial = {};
export const tripPlannerOfferInstanceData = (
  state = tripPlannerOfferInstanceDataInitial,
  action
) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return tripPlannerOfferInstanceDataInitial;
    case TRIPPLANNER_LOAD_TRIP: {
      const { data, mode } = action;

      if (mode !== "view-offer") {
        return tripPlannerOfferInstanceDataInitial;
      } else {
        const {
          version,
          offer_email,
          offer_emails,
          offer_session,
          offer_title,
          reference,
          validity,
          terms_and_conditions,
          currency,
          notes,
        } = data;

        return {
          ...state,
          ...{
            version,
            offer_email,
            offer_emails,
            offer_session,
            offer_title,
            reference,
            validity,
            terms_and_conditions,
            currency,
            notes,
          },
        };
      }
    }
    case TRIPPLANNER_UPDATE_OFFER_DATA: {
      const { offerData } = action;
      offerData.offer_email = offerData?.offer_emails?.[0] ?? "";
      return { ..._.cloneDeep(state), ...offerData };
    }
    default:
      return state;
  }
};

export * from "./TripPlanner/TripSetupReducers";
export * from "./TripPlanner/ItinerarySetupReducers";
export * from "./TripPlanner/TransportationPreferencesReducers";
export * from "./TripPlanner/AccommodationReducers";
export * from "./TripPlanner/AccommodationPreferencesReducers";
export * from "./TripPlanner/TransferReducers";
export * from "./TripPlanner/TransferPreferencesReducers";
export * from "./TripPlanner/GuestReducers";
export * from "./TripPlanner/BookingMessageReducers";
export * from "./TripPlanner/PricingAndFiltersReducers";
export * from "./TripPlanner/ModalReducers";
export * from "./TripPlanner/OverviewFiltersReducers";
export * from "./TripPlanner/PrebookReducers";
export * from "./TripPlanner/FlightReducers";
export * from "./TripPlanner/ViewAllReducers";
export * from "./TripPlanner/OverviewReducers";
export * from "./TripPlanner/DayByDayReducers";
export * from "./TripPlanner/MarketPlaceReducers";
export * from "./TripPlanner/QuotationReducers";
export * from "./TripPlanner/TransportationReducers";
export * from "./TripPlanner/TripAi";
export * from "./TripPlanner/TrainReducers";
export * from "./TripPlanner/FerriesReducers.js";
