import moment from "moment";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import {
  Button,
  Carousel,
  Form,
  FormControl,
  FormGroup,
  Input,
  Panel,
  Timeline,
} from "rsuite";
import { naString } from "@src/tools/string_tools";
import { DayServices } from "./SectionedPkgTemplate";
import bookFlowHoc from "./BookFlowHoc";
import { pkgContentHoc } from "./PkgContentHoc";
import { createUseStyles } from "react-jss";
import { Formik } from "formik";
import { NormalInputField } from "@src/components/forms";

class Day extends React.Component {
  constructor(props) {
    super(props);
  }
  renderServices(baseCls) {
    const { day } = this.props;

    if (!day.services.length) {
      return null;
    }

    return (
      <div>
        <span>
          <strong>Services</strong>
        </span>
        <DayServices day={day} baseCls={baseCls} />
      </div>
    );
  }
  renderImgs() {
    const { day } = this.props;

    return day.destinations.map((dest, idx) => (
      <img
        key={idx}
        alt=""
        src={_.get(
          dest,
          `images.${
            Math.abs(day.day - dest.order) % _.get(dest, "images", []).length
          }.photo_sm_url`
        )}
      />
    ));
  }
  renderNormalHeader(bCls) {
    const { day } = this.props;

    return (
      <h3 className={`${bCls}__header`}>
        Day {day.day} -{" "}
        {day.destinations.map((dest) => dest.name_en).join(" to ")}
      </h3>
    );
  }
  renderAtLeisureHeader(bCls) {
    const { day } = this.props;
    return (
      <h4 className={`${bCls}__header ${bCls}__header--atleisure`}>
        Day {day.day} -{" "}
        {day.destinations.map((dest) => dest.name_en).join(" to ")}
        {` (day at leisure)`}
        {this.renderImgs()}
      </h4>
    );
  }
  render() {
    const { baseCls, day, mode, data, onDescriptionChange } = this.props;

    const bCls = `${baseCls}__content__itinerary__daybyday__day__descpanel`;

    const atLeisure = day.services.length == 0;

    return (
      <Panel
        className={`${bCls}${atLeisure ? ` ${bCls}--atleisure` : ""}`}
        bordered
        header={
          atLeisure
            ? this.renderAtLeisureHeader(bCls)
            : this.renderNormalHeader(bCls)
        }>
        {atLeisure ? null : mode == "view" ? (
          <p>{naString(_.get(data, "description.en"))}</p>
        ) : (
          <Form
            formValue={_.get(data, "description", { en: "" })}
            className={`${bCls}__form`}
            onChange={(formValue) => onDescriptionChange(day.day, formValue)}>
            <FormGroup>
              {" "}
              <FormControl rows={5} name="en" componentClass="textarea" />
            </FormGroup>
          </Form>
        )}
        {atLeisure ? null : (
          <div className={`${bCls}__content`}>
            <div
              className={`${bCls}__content__img ${bCls}__content__img--${day.destinations.length}`}>
              {this.renderImgs()}
            </div>
            <div className={`${bCls}__content__services`}>
              {this.renderServices(`${bCls}__content__services`)}
            </div>
          </div>
        )}
      </Panel>
    );
  }
}
Day.propTypes = {
  baseCls: PropTypes.string.isRequired,
  day: PropTypes.object.isRequired,
  mode: PropTypes.oneOf(["edit", "view"]).isRequired,
  data: PropTypes.object.isRequired,
  onDescriptionChange: PropTypes.func.isRequired,
};

class DestCarousel extends React.PureComponent {
  constructor(props) {
    super(props);
  }
  render() {
    const { baseCls, imgs } = this.props;

    return (
      <div className={`${baseCls}__content__carousel`}>
        <Carousel autoplay={true}>
          {imgs.map((img, idx) => (
            <img
              key={idx}
              className={`${baseCls}__content__carousel__img`}
              alt=""
              src={img}
            />
          ))}
        </Carousel>
      </div>
    );
  }
}
DestCarousel.propTypes = {
  baseCls: PropTypes.string.isRequired,
  imgs: PropTypes.array.isRequired,
};

const onePagePkgTemplateStyles = createUseStyles({
  OnePagePkgTemplate: {},
  videoContainer: {
    "borderRadius": "5px",
    "& iframe": { borderRadius: "5px", width: "100%" },
  },
});
var OnePagePkgTemplate = ({
  content,
  color,
  mode,
  destinations,
  step,
  pkgTitle,
  pkgSubTitle,
  generalDescription,
  days,
  itineraryDaysData,
  pricePerPerson,
  pkgStyle,
  onGoToRecalculate,
  onPkgStyleChange,
  onDayDescChange,
  onPkgTitleChange,
  onPkgSubTitleChange,
  onGenDescChange,
  onContentChange,
}) => {
  const classes = onePagePkgTemplateStyles();
  const baseCls = "TravelPackage--onepagetemplate";
  var imgs = [];
  destinations.forEach((dest) => {
    for (let i = 0; i <= 2; i++) {
      imgs.push(dest.images[_.random(0, dest.images.length - 1)].photo_sm_url);
    }
  });

  imgs = [...new Set(imgs)];

  function renderItinerary() {
    return (
      <div className={`${baseCls}__content__itinerary`}>
        <h3 className={`${baseCls}__content__itinerary__header`}>Itinerary</h3>
        <Timeline className={`${baseCls}__content__itinerary__daybyday`}>
          {days.map((day, idx) => (
            <Timeline.Item
              key={idx}
              className={`${baseCls}__content__itinerary__daybyday__day`}>
              <Day
                baseCls={baseCls}
                day={day}
                mode={mode}
                data={_.get(itineraryDaysData, day.day)}
                onDescriptionChange={onDayDescChange}
              />
            </Timeline.Item>
          ))}
        </Timeline>
      </div>
    );
  }

  function renderMiddleStrip() {
    const tripStay = moment
      .duration(
        moment(_.last(destinations).checkOut).diff(
          moment(destinations[0].checkIn)
        )
      )
      .days();

    return (
      <div className={`${baseCls}__middlestrip`}>
        <div className={`${baseCls}__middlestrip__section`}>
          <span className={`${baseCls}__middlestrip__section__header`}>
            <strong>Nights / Days</strong>
          </span>
          <span className={`${baseCls}__middlestrip__section__value`}>
            {tripStay + 1} Days / {tripStay}{" "}
            {tripStay == 1 ? "Night" : "Nights"}
          </span>
        </div>
        <div className={`${baseCls}__middlestrip__section`}>
          <span className={`${baseCls}__middlestrip__section__header`}>
            <strong>Style</strong>
          </span>
          {mode == "view" ? (
            <span className={`${baseCls}__middlestrip__section__value`}>
              {naString(_.startCase(pkgStyle.en))}
            </span>
          ) : (
            <Input
              value={pkgStyle.en}
              onChange={(value) => onPkgStyleChange(value)}
            />
          )}
        </div>
        <div className={`${baseCls}__middlestrip__section`}>
          <span className={`${baseCls}__middlestrip__section__header`}>
            <strong>From Price</strong>
          </span>
          <span className={`${baseCls}__middlestrip__section__value`}>
            {pricePerPerson}
          </span>
        </div>
        <div className={`${baseCls}__middlestrip__section`}>
          <Button onClick={onGoToRecalculate}>
            <strong>Book Now</strong>
          </Button>
        </div>
      </div>
    );
  }

  return (
    <Formik
      initialValues={content}
      validateOnBlur={false}
      validateOnMount={false}
      validate={({ video_url }) =>
        onContentChange((p) => ({ ...p, video_url }))
      }>
      {({ values }) => (
        <div className={`${baseCls} ${baseCls}--${color} ${baseCls}--${step}`}>
          <div className={`${baseCls}__header`}>
            <img alt="" src={_.get(destinations, "0.images.0.photo_l_url")} />
            <h1 className={`${baseCls}__header__title`}>
              {mode == "view" ? (
                naString(pkgTitle.en)
              ) : (
                <Input
                  value={pkgTitle.en}
                  onChange={(value) => onPkgTitleChange(value)}
                />
              )}
            </h1>
            <div className={`${baseCls}__header__overlay`}></div>
          </div>
          {renderMiddleStrip()}
          <div className={`${baseCls}__content`}>
            <DestCarousel imgs={imgs} baseCls={baseCls} />
            <div className={`${baseCls}__content__header`}>
              <h3>
                {mode == "view" ? (
                  naString(pkgSubTitle.en)
                ) : (
                  <Input
                    value={pkgSubTitle.en}
                    onChange={(value) => onPkgSubTitleChange(value)}
                  />
                )}
              </h3>
            </div>
            <div className={`${baseCls}__content__maindescription`}>
              {mode == "view" ? (
                <p>{naString(generalDescription.en)}</p>
              ) : (
                <Form
                  formValue={generalDescription}
                  className={`${baseCls}__content__maindescription__form`}
                  onChange={(formValue) => onGenDescChange(formValue)}>
                  <FormGroup>
                    {" "}
                    <FormControl rows={5} name="en" componentClass="textarea" />
                  </FormGroup>
                </Form>
              )}
            </div>
            {renderItinerary()}
            <div className={classes.videoContainer}>
              {mode == "edit" ? (
                <NormalInputField name="video_url" label="Video URL" />
              ) : (
                content?.video_url && (
                  <iframe
                    height="315"
                    src={content.video_url}
                    title="Video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen></iframe>
                )
              )}
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};
OnePagePkgTemplate.defaultProps = {
  content: {},
  color: "teal",
  days: [],
  mode: "view",
  destinations: [],
  inclusions: [],
  exclusions: [],
  price: 0,
  pricePerPerson: 0,
  loading: false,
};
OnePagePkgTemplate.propTypes = {
  content: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  days: PropTypes.array.isRequired,
  mode: PropTypes.oneOf(["edit", "view"]).isRequired,
  destinations: PropTypes.array.isRequired,
  inclusions: PropTypes.array.isRequired,
  exclusions: PropTypes.array.isRequired,
  price: PropTypes.number.isRequired,
  pricePerPerson: PropTypes.number.isRequired,
  content: PropTypes.object.isRequired,
  step: PropTypes.oneOf(["overview", "recalculate"]).isRequired,
  loading: PropTypes.bool.isRequired,
  itineraryDaysData: PropTypes.object.isRequired,
  pkgTitle: PropTypes.object.isRequired,
  pkgSubTitle: PropTypes.object.isRequired,
  pkgStyle: PropTypes.object.isRequired,
  generalDescription: PropTypes.object.isRequired,
  onGoToRecalculate: PropTypes.func.isRequired,
  renderRecalcOverlay: PropTypes.func.isRequired,
  renderLoadingOverlay: PropTypes.func.isRequired,
  onGenDescChange: PropTypes.func.isRequired,
  onPkgTitleChange: PropTypes.func.isRequired,
  onPkgSubTitleChange: PropTypes.func.isRequired,
  onDayDescChange: PropTypes.func.isRequired,
  onPkgStyleChange: PropTypes.func,
  onContentChange: PropTypes.func,
};

export default bookFlowHoc(pkgContentHoc(OnePagePkgTemplate));
