import _ from "lodash";

export function getFormikProps(ref) {
  const formikKey = Object.keys(ref.current).find((key) =>
    key.includes("__reactProps")
  );
  const formikProps = _.get(ref.current, formikKey);
  return formikProps;
}

export function onFormikFormSubmit(ref) {
  const formikProps = getFormikProps(ref);
  if (!formikProps) {
    return;
  }
  formikProps.onSubmit();
}
