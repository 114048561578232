import { getRestaurantServicesSelector } from "@src/selectors/Project/TripPlanner/day_by_day/custom_services";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { RestaurantData } from "../../Day/restaurant";

const RestaurantReservations = ({ reservations }) => {
  const regular_coords = useSelector((state) =>
    getRestaurantServicesSelector(state, { booking_mode: "regular" })
  );
  const option_coords = useSelector((state) =>
    getRestaurantServicesSelector(state, { booking_mode: "option" })
  );

  const for_reservation_srvs = [...regular_coords, ...option_coords];

  return !reservations.length ? null : (
    <div>
      <h5>Restaurants</h5>
      {reservations.map((res, idx) => {
        const rest = for_reservation_srvs.find(
          (rest) => rest.reference === res.search_uid
        );

        return (
          <RestaurantData
            key={idx}
            service={rest}
            reservationMode={true}
            reservationLoading={res.status === "PE"}
            reservationStatus={res.status}
          />
        );
      })}
    </div>
  );
};
RestaurantReservations.defaultProps = {
  reservations: [],
};
RestaurantReservations.propTypes = {
  reservations: PropTypes.array.isRequired,
};

export default RestaurantReservations;
