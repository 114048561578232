import _ from "lodash";
import { cardStyles, variables } from "@src/jsssetup";

export function serviceTypeHeaderStyles() {
  return {
    borderRadius: "5px",
    background: "white",
    padding: ["0.1rem", variables.normal_gap],
    fontSize: "small",
    display: "grid",
    alignItems: "center",
  };
}

export const daySrvHeaderStyles = () => ({
  ...cardStyles.header,
  "fontWeight": "bold",
  "fontSize": "medium",
  "padding": `calc(${variables.normal_gap} / 2)`,
  "display": "grid",
  "gridTemplateColumns": "1fr auto",
  "alignItems": "center",
  "&>span": {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflowX: "hidden",
  },
});

export const serviceHeaderActions = {
  display: "grid",
  gridAutoFlow: "column",
  gridGap: `calc(${variables.normal_gap} / 2)`,
};

export const daySrvBodyStyles = (props) => {
  const { expandedHeight = "10rem" } = props || {};

  return {
    ...cardStyles.body,
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    height: _.get(props, "collapsed", false) ? 0 : expandedHeight,
    borderRadius: [
      0,
      0,
      `calc(${variables.normal_gap} / 2)`,
      `calc(${variables.normal_gap} / 2)`,
    ],
  };
};
