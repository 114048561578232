import TransTxt from "@src/components/common/SxFormatMessage";

import React from "react";

export const initialPricingHeader = [
  {
    content: (
      <TransTxt id="pricing_products__welcome_to_a_world_of_really" upperCase />
    ),
  },
  {
    content: <TransTxt id="pricing_products__dynamic_itineraries" upperCase />,
  },
  {
    content: (
      <TransTxt
        id="pricing_users__designed_for_travel_professionals"
        upperCase
      />
    ),
  },
];

export const inititalUsersNetworkHeader = [
  {
    content: <TransTxt id="pricing_users__connect_trade_in_real_time" />,
  },
  {
    content: <TransTxt id="pricing_users__users_network" startCase />,
  },
  {
    content: (
      <TransTxt
        id="pricing_users__designed_for_travel_professionals"
        startCase
      />
    ),
  },
];

export const initialManageHeader = [
  {
    id: 1,
    content: "",
    key: <TransTxt id="sign_up" upperCase />,
  },
  {
    id: 2,
    content: <TransTxt id="7_days_free_usage" upperCase />,
  },
  {
    id: 3,
    content: "",
    key: <TransTxt id="remain" upperCase />,
  },
];

export const initialSubscriptionPlanForm = {
  sub_manage_trip_planning: {
    id: 1,
    key: "sub_manage_trip_planning",
    checked: false,
    amount: 0,
    strip_id: "",
    stripe_id: "",
    default: true,
    codename: "trip_planning",
  },
  sub_manage_bidding: {
    id: 2,
    key: "sub_manage_bidding",
    checked: false,
    amount: 0,
    strip_id: "",
    stripe_id: "",
    codename: "bidding",
  },
  sub_manage_operation_system_os: {
    id: 3,
    key: "sub_manage_operation_system_os",
    checked: false,
    amount: 0,
    strip_id: "",
    stripe_id: "",
    default: true,
    codename: "operation_system_os",
  },
  sub_manage_my_serveice_library: {
    id: 4,
    key: "sub_manage_my_serveice_library",
    checked: false,
    amount: 0,
    strip_id: "",
    stripe_id: "",
    codename: "my_services_library",
  },
  sub_manage_supply_chain_scm: {
    id: 5,
    key: "sub_manage_supply_chain_scm",
    checked: false,
    amount: 0,
    strip_id: "",
    stripe_id: "",
    codename: "supply_chain_scm",
  },
  sub_manage_itinerary_app: {
    id: 6,
    key: "sub_manage_itinerary_app",
    checked: false,
    amount: 0,
    strip_id: "",
    stripe_id: "",
    default: true,
    codename: "itinerary_app",
  },
  sub_manage_trip_chat: {
    id: 7,
    key: "sub_manage_trip_chat",
    checked: false,
    amount: 0,
    strip_id: "",
    stripe_id: "",
    default: true,
    codename: "trip_chat",
  },
};
