import { tripSetupChange } from "@src/actions/Project/TripPlanner";
import { CustomButton } from "@src/components/common/buttons";
import { NormalSubagentAutocompleteField } from "@src/components/forms";
import { formStyles, modalGenericStyles } from "@src/jsssetup";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { createUseStyles } from "react-jss";
import { useDispatch } from "react-redux";
import { Loader } from "rsuite";
import * as yup from "yup";

const validationSchema = yup.object({
  target_entity: yup
    .string()
    .required("Required")
    .test("is-valid", "Please select a valid subagent", (value) => {
      if (value.includes("---")) {
        return true;
      }
      return false;
    }),
});

const subEntitySelectModalStyles = createUseStyles({
  ...modalGenericStyles,
  SubEntitySelectModal: modalGenericStyles.modal,
  form: formStyles.form,
});
export const SubEntitySelectModal = ({ onCancel }) => {
  const [isLoading, setIsLoading] = useState(false);
  const classes = subEntitySelectModalStyles();

  const dispatch = useDispatch();
  const onSubmit = useCallback(
    async ({ target_entity }) => {
      setIsLoading(true);
      await dispatch(tripSetupChange("target_entity", target_entity));
      onCancel();
      setIsLoading(false);
    },
    [dispatch]
  );

  return (
    <div className={classes.SubEntitySelectModal}>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        validateOnMount={false}
        validationSchema={validationSchema}
        initialValues={{ target_entity: "" }}
        onSubmit={(values) => {
          var target_entity = values.target_entity.split("---");
          target_entity = `${target_entity[1]}___${target_entity[3]}`;
          onSubmit({ target_entity });
        }}>
        {({ submitForm }) => (
          <div className={classes.card}>
            {isLoading && <Loader size="lg" backdrop center />}
            <div className={classes.cardHeader}>
              <h5>Select Subagent</h5>
            </div>
            <div className={classes.cardBody}>
              <Form className={classes.form}>
                <NormalSubagentAutocompleteField
                  id="SubEntitySelectModal__NormalSubagentAutocompleteField"
                  name="target_entity"
                  label="Subagent"
                  id="trip_planner_subagent_autocomplete"
                />
              </Form>
            </div>
            <div className={classes.cardActions}>
              <CustomButton appearance="ghost" onClick={onCancel}>
                Cancel
              </CustomButton>
              <CustomButton onClick={submitForm}>Submit</CustomButton>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};
SubEntitySelectModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
};
