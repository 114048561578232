import _ from "lodash";
import axios from "axios";
import { SISI_API_URL } from "@src/api";

// ============================ TRANSFERS ============================

export const trfSearch = ({
  origin_lat,
  origin_lng,
  origin_iata,
  origin_name,
  origin_country_code,
  destination_lat,
  destination_lng,
  destination_name,
  destination_country_code,
  inbound,
  outbound,
  adults,
  transfer_type = "inbound", // outbound
  route_type = "airport_to_point", // point_to_airport
  page_size = 100,
  market_type = "B2B",
  source_entity,
}) => {
  return axios.get(`${SISI_API_URL}/transferengine/search/`, {
    params: {
      origin_lat,
      origin_lng,
      origin_iata,
      origin_name,
      origin_country_code,
      destination_lat,
      destination_lng,
      destination_name,
      destination_country_code,
      inbound,
      outbound,
      adults,
      transfer_type,
      route_type,
      page_size,
      market_type,
      source_entity,
    },
  });
};

export const trfGetResults = (session_id) => {
  return axios.get(`${SISI_API_URL}/transferengine/search/${session_id}`);
};

export const trfPrebook = async (payload) => {
  return axios.post(`${SISI_API_URL}/transferengine/pre-book/`, payload);
};
