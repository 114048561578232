import {
  BRANCHES_LIST_FILTER_CHANGE,
  BRANCHES_SAVE_LIST_DATA,
} from "@src/actions/Network/SalesPartners/Branches/types";
import { commonList } from "@src/reducers/common";

export const initialBranchesList = {
  ...commonList,
  displayLength: 100,
  filters: {
    name__icontains: "",
    contact_person__first_name__icontains: "",
    contact_person__last_name__icontains: "",
    email__icontains: "",
    creator__icontains: "",
    created__gte: "",
    created__lte: "",
    status__in: "",
    page: 1,
    limit: 20,
  },
};
export const BranchesListReducer = (state = initialBranchesList, action) => {
  switch (action.type) {
    case BRANCHES_LIST_FILTER_CHANGE: {
      const { filters } = action;
      return { ...state, filters: { ...state.filters, ...filters } };
    }
    case BRANCHES_SAVE_LIST_DATA:
      return action.data;
    default:
      return state;
  }
};
