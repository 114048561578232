import { SUBAGENTS_LIST_FILTER_CHANGE } from "@src/actions/Network/SalesPartners/SubAgents/types";
import { commonList } from "@src/reducers/common";

export const initialSubAgentsList = {
  ...commonList,
  displayLength: 100,
  filters: {
    name__icontains: "",
    contact_person__first_name__icontains: "",
    contact_person__last_name__icontains: "",
    email__icontains: "",
    legal_title__icontains: "",
    creator__icontains: "",
    created__gte: "",
    created__lte: "",
    approved: "",
    tags: "",
    status__in: "",
    page: 1,
    limit: 20,
    with_financials: false,
  },
};
export const SubAgentsListReducer = (state = initialSubAgentsList, action) => {
  switch (action.type) {
    case SUBAGENTS_LIST_FILTER_CHANGE: {
      const { filters } = action;
      return { ...state, filters: { ...state.filters, ...filters } };
    }
    default:
      return state;
  }
};
