import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { Loader, Tag } from "rsuite";
import { Activity } from "../products/activities/ActivitiesDrawer";

const STATUS_MAPPING = {
  CO: "Confirmed",
  PE: "Pending",
  CA: "Cancelled",
  FA: "Failed",
};

export const ActivityReservations = ({ reservations = [] }) => {
  const activityServices = useSelector((state) =>
    _.flatten(Object.values(state.tripPlannerDbDActivityReducer))
  );

  return (
    reservations.length > 0 && (
      <div className="BookingModal__section APIFerryReservations">
        <h5 className="BookingModal__section__header">Activity Reservations</h5>
        {activityServices.map((act, idx) => {
          const reservation = reservations.find(
            (r) => r.search_uid === act.uid
          );

          return (
            !!reservation && (
              <React.Fragment>
                <div className="BookingModal__section__service-title">
                  <h6>
                    Activity Service {idx + 1}. Reservation Reference:{" "}
                    {reservation?.reference}.
                  </h6>
                  <span className="AccommodationReservation__title__actions">
                    Status:
                    {reservation?.status == "PE" ? (
                      <Loader className="reservation-loader" />
                    ) : (
                      <Tag
                        color={reservation?.status !== "CO" ? "red" : "green"}>
                        <strong>
                          {STATUS_MAPPING?.[reservation?.status] ?? "N/A"}
                        </strong>
                      </Tag>
                    )}
                  </span>
                </div>
                <Activity
                  activity={act}
                  mode="short"
                  services_collapsed={false}
                />
              </React.Fragment>
            )
          );
        })}
      </div>
    )
  );
};
ActivityReservations.defaultProps = { reservations: [] };
ActivityReservations.propTypes = { reservations: PropTypes.array.isRequired };
