import PropTypes from "prop-types";
import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { Button } from "rsuite";
import { TripPlanContext } from "../../TripPlanner";
import { createUseStyles } from "react-jss";

const bookingMetadataBtnStyles = createUseStyles({
  bookingMetadataBtn: (props) => ({
    background: props.booking_metadata ? "#6083FF" : "#239E10",
    color: "white",
  }),
});
export const BookingMetadataBtn = ({ booking_metadata, uid, srv_type }) => {
  const classes = bookingMetadataBtnStyles({ booking_metadata });
  const currentStep = useSelector((state) => state.tripPlannerCurrentStep);
  const {
    setServiceRemarksUid,
    setServiceRemarksSrvType,
    setShowServiceRemarksModal,
  } = useContext(TripPlanContext);
  return currentStep !== 4 ? null : (
    <Button
      className={classes.bookingMetadataBtn}
      size="xs"
      onClick={() => {
        setServiceRemarksUid(uid);
        setServiceRemarksSrvType(srv_type);
        setShowServiceRemarksModal(true);
      }}>
      <strong>{booking_metadata ? "Edit Remarks" : "Add Remarks"}</strong>
    </Button>
  );
};
BookingMetadataBtn.propTypes = {
  booking_metadata: PropTypes.object,
  uid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  srv_type: PropTypes.string.isRequired,
};
