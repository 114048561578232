import _ from "lodash";
import { View, Text, StyleSheet } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import React from "react";
import { DateTime } from "luxon";
import * as sanitizeHtml from "sanitize-html";
import Html from "react-pdf-html";

const dayByDayContentStyles = StyleSheet.create({
  DayByDayContent: { width: 550, flexDirection: "column", fontSize: 10 },
  firstRow: {
    flexDirection: "row",
    borderRadius: "5px",
    backgroundColor: "#302A83",
    border: "1px solid #302A83",
    color: "white",
    padding: 8,
    fontFamily: "Helvetica-Bold",
  },
  row: { flexDirection: "row", borderBottom: "1px solid black" },
  lastRow: { flexDirection: "row" },
  description: { fontSize: 10 },
  mainColumn: { width: 130 },
  secondColumn: { width: 120 },
  thirdColumn: { width: 300 },
  headerCellTxt: {},
  cellTxt: { padding: 5 },
  lastCellTxt: { padding: 5 },
});
export const DayByDayContent = ({ day_by_day_descriptions = [] }) => {
  return (
    <View style={dayByDayContentStyles.DayByDayContent}>
      <View style={dayByDayContentStyles.firstRow}>
        <View style={dayByDayContentStyles.mainColumn}>
          <Text style={dayByDayContentStyles.headerCellTxt}>Day</Text>
        </View>
        <View style={dayByDayContentStyles.secondColumn}>
          <Text style={dayByDayContentStyles.headerCellTxt}>Title</Text>
        </View>
        <View style={dayByDayContentStyles.thirdColumn}>
          <Text style={dayByDayContentStyles.headerCellTxt}>Description</Text>
        </View>
      </View>
      {day_by_day_descriptions.map((day, idx) => (
        <View
          wrap={false}
          key={idx}
          style={
            idx !== day_by_day_descriptions.length - 1
              ? dayByDayContentStyles.row
              : dayByDayContentStyles.lastRow
          }>
          <View style={dayByDayContentStyles.mainColumn}>
            <Text key={idx} style={dayByDayContentStyles.cellTxt}>
              <strong>Day {idx + 1}: </strong>
              {DateTime.fromISO(day.date)
                .setLocale("en-US")
                .toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}{" "}
            </Text>
          </View>
          <View style={dayByDayContentStyles.secondColumn}>
            <Text key={idx} style={dayByDayContentStyles.cellTxt}>
              {day.title}
            </Text>
          </View>
          <View style={dayByDayContentStyles.thirdColumn}>
            <Text key={idx} style={dayByDayContentStyles.cellTxt}>
              <Html
                collapse={false}
                style={
                  dayByDayContentStyles.description
                }>{`<html><body>${day.description}</body></html>`}</Html>
            </Text>
          </View>
        </View>
      ))}
    </View>
  );
};
DayByDayContent.propTypes = {
  day_by_day_descriptions: PropTypes.array.isRequired,
};
