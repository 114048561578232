import { SISI_API_URL } from "@src/api";
import { getAuthHeaders } from "@src/api/request";
import axios from "axios";

export async function getSubscriptionValidity() {
  const auth_headers = getAuthHeaders();

  try {
    const result = await axios.get(`${SISI_API_URL}/crm/status-check/`, {
      params: {},
      headers: { ...auth_headers },
    });

    return result.data;
  } catch (error) {
    console.log(error);
    return null;
  }
}
