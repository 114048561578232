import { Notification } from "rsuite";
import React from "react";

export const notifyStepFourFailedService = () => {
  Notification.error({
    title: "Final Step Validation Error",
    description: (
      <p>All services must have results to continue to the next step.</p>
    ),
    duration: 6000,
  });
};
