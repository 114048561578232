import jwt_decode from "jwt-decode";

import { notifyCommonError } from "@src/components/common/notifications/CommonErrorNotification.js";
import { notifyCommonSuccess } from "@src/components/common/notifications/CommonSuccessNotification.js";
import { submitProcessor, submitChangeProcessor } from "@src/actions/tools";
import {
  errorHandle as commonErrorHandle,
  filterEmptyValue,
  paramsProcessor,
} from "@src/tools/common_tools";

import { clearLSUserData } from "@src/tools/auth_tools";

import {
  USER_FETCHED_DETAILS,
  USER_FETCH_OPTIONS,
  USER_FETCH_DATA,
  USER_BEGIN_FETCH_DATA,
  USER_END_FETCH_DATA,
  USER_APPLY_LIST_FILTER,
  USER_APPLY_LIST_COLUMNS,
  USER_UPDATE_LIST_FILTER_FORM,
  USER_INITIALIZE_LIST_FILTER_FORM,
  USER_UPDATE_LIST_COLUMNS_FORM,
  USER_INITIALIZE_LIST_COLUMNS_FORM,
  USER_RESET_LIST_FILTER_FORM,
  // ========================== User Profile =========================
  USER_PROFILE_LOADED,
  USER_PROFILE_LOADING,
  // ============ User Profile: User Base Section Actions ============
  USER_CHANGE_EMAIL,
  USER_END_SUBMIT_NEXT_EMAIL,
  USER_BEGIN_SUBMIT_NEXT_EMAIL,
  USER_END_CHANGE_USER_PASSWORD,
  USER_BEGIN_CHANGE_USER_PASSWORD,
  USER_BEGIN_CHANGE_USER_STATUS,
  USER_END_CHANGE_USER_STATUS,
  USER_VIEW_BASE_DATA_MODE,
  USER_EDIT_BASE_DATA_MODE,
  USER_UPLOADING_BASE_DATA_MODE,
  USER_INITIALIZE_BASE_DATA_FORM,
  USER_VALIDATE_BASE_DATA_FORM,
  USER_EDIT_BASE_DATA_FORM,
  USER_EDIT_JOB_DESCRIPTION_FORM,
  USER_INITIALIZE_USER_GENDER_FORM,
  // ========== User Profile: User Expertise Section Actions =========
  USER_VIEW_USER_EXPERTISE_MODE,
  USER_EDIT_USER_EXPERTISE_MODE,
  USER_UPLOADING_USER_EXPERTISE_MODE,
  USER_EDIT_EXPERTISE_FORM,
  USER_INITIALIZE_EXPERTISE_FORM,
  // ======== User Profile: User Travel Sector Section Actions =======
  USER_VIEW_TRAVEL_SECTOR_MODE,
  USER_EDIT_TRAVEL_SECTOR_MODE,
  USER_UPLOADING_TRAVEL_SECTOR_MODE,
  USER_EDIT_TRAVEL_SECTOR_FORM,
  USER_INITIALIZE_TRAVEL_SECTOR_FORM,
  // ========= User Profile: User Preferences Section Actions ========
  USER_VIEW_PREFERENCES_MODE,
  USER_EDIT_PREFERENCES_MODE,
  USER_UPLOADING_PREFERENCES_MODE,
  USER_EDIT_LANGUAGE_FORM,
  USER_INITIALIZE_LANGUAGE_FORM,
  USER_INITIALIZE_IMAGE_FORM,
  USER_UPLOAD_PREFERENCE_IMAGE,
  // ======= User Profile: User Job Description Section Actions ======
  USER_VIEW_JOB_DESCRIPTION_MODE,
  USER_EDIT_JOB_DESCRIPTION_MODE,
  USER_UPLOADING_JOB_DESCRIPTION_MODE,
  USER_EDIT_DEPARTMENT_FORM,
  USER_INITIALIZE_DEPARTMENT_FORM,
  USER_INITIALIZE_JOB_DESCRIPTION_FORM,
  USER_FORMAT_JOB_DESCRIPTION_FORM,
  // ======= User Profile: User Work Duties Section Actions ======
  USER_VIEW_WORK_DUTIES_MODE,
  USER_EDIT_WORK_DUTIES_MODE,
  USER_UPLOADING_WORK_DUTIES_MODE,
  // =================== Service Specialty Section ===================
  USER_SELECT_SERVICE_CATEGORY,
  USER_VIEW_SERVICE_CATEGORIES_MODE,
  USER_EDIT_SERVICE_CATEGORIES_MODE,
  USER_LOADING_SERVICE_SPECIALTY_MODE,
  USER_SELECT_TRAVEL_SERVICE,
  USER_INITIALIZE_SERVICE_SPECIALTY_FORM,
  USER_LOADING_SERVICE_CATEGORY_MODE,
  USER_INITIALIZE_TRAVEL_SERVICE_FORM,
  //graph
  USER_PERMISSION_FEATURE_GROUPS,
  USER_EMAIL_CONFIG_LIST,
  USER_UPDATE_FILTERS,
  USER_FILTERS_GENERIC_CHANGE,
} from "./types";

import { getFilter, getPagination } from "@src/tools/form_tools";

import { changeLang } from "./../../i18n/lang";

import update from "immutability-helper";
import _ from "lodash";

import {
  getUserDetails,
  getUserOptions,
  getUsersList,
  submitUserProfile,
  changeEmail as changeEmailAPI,
  changePassword as changePasswordAPI,
  changeUserStatus as changeUserStatusAPI,
  resendVerifyEmail as resendVerifyEmailAPI,
  getJobDescriptions as getJobDescriptionsAPI,
  getUsersFeatureGroup as getUsersFeatureGroupAPI,
  getUsersEmailConfigList as getUsersEmailConfigListAPI,
  uploadImgLink,
} from "@src/api";

import {
  initialUserForm,
  initialUsersFilterForm,
  defaultOption,
} from "@src/forms/Admin/Company/userForms";

import { changeUploadImgFactory } from "./../../ActionFactory";

import { hideGlobalModal } from "@src/actions/Shares";

// User Profile
export const getJobDescriptions = (word, cb, TYPE) => async (dispatch) => {
  try {
    const res = await getJobDescriptionsAPI({ page: 1, limit: 10 });
    dispatch({ type: TYPE, data: res.results });
  } catch (error) {
    error.ovrdErrHandle && error.ovrdErrHandle();
    commonErrorHandle({ error });
  } finally {
    cb && cb();
  }
};

export const resendVerifyEmail = (cb) => async () => {
  try {
    await resendVerifyEmailAPI();
    notifyCommonSuccess("Send email successfully");
  } catch (error) {
    error.ovrdErrHandle && error.ovrdErrHandle();
    commonErrorHandle({ error });
  } finally {
    cb && cb();
  }
};

export const changeUserStatus = (data, TYPE) => async (dispatch) => {
  dispatch(beginChangeUserStatus());
  try {
    const res = await changeUserStatusAPI(data);
    notifyCommonSuccess("Change user status successfully");
    dispatch({ type: TYPE, data: res });
  } catch (error) {
    error.ovrdErrHandle && error.ovrdErrHandle();
    commonErrorHandle({ error });
  } finally {
    dispatch(endChangeUserStatus());
  }
};

export const beginChangeUserStatus = () => {
  return { type: USER_BEGIN_CHANGE_USER_STATUS };
};

export const endChangeUserStatus = () => {
  return { type: USER_END_CHANGE_USER_STATUS };
};

export const changeUserPassword = (data) => async (dispatch) => {
  dispatch(beginChangeUserPassword());
  try {
    const res = await changePasswordAPI(data);
    notifyCommonSuccess(res.message);
    dispatch(hideGlobalModal());
    clearLSUserData();
  } catch (error) {
    error.ovrdErrHandle && error.ovrdErrHandle();
    commonErrorHandle({ error });
  } finally {
    dispatch(endChangeUserPassword());
  }
};

export const beginChangeUserPassword = () => {
  return { type: USER_BEGIN_CHANGE_USER_PASSWORD };
};

export const endChangeUserPassword = () => {
  return { type: USER_END_CHANGE_USER_PASSWORD };
};

export const fetchUserDetails = (cb) => async (dispatch) => {
  const user_info = window.localStorage.getItem("user_info");
  if (typeof user_info == "undefined") {
    return;
  }

  const decoded_user_info = jwt_decode(user_info);
  const username = decoded_user_info.sub;
  const data = await getUserDetails({ user__username: username });

  if (!data.results.length) {
    notifyCommonError(
      "We could not find the specified user. Please try again."
    );
    window.location.href = "/#/user/SignInAndUp";
    return;
  }

  dispatch(
    initializeUserTravelServiceForm({
      value: _.get(data, "results.0.travel_services") || [],
    })
  );

  dispatch(userFetchedDetails(data.results[0]));
  dispatch(
    changeLang({ lang: _.get(data, ["results", "0", "preferred_language"]) })
  );
  dispatch(userProfileLoaded());
  if (cb && typeof cb == "function") {
    cb();
  }
};

export const userFetchedDetails = (data) => {
  return {
    type: USER_FETCHED_DETAILS,
    data,
  };
};

export const initializeUserTravelServiceForm = (data) => {
  return {
    type: USER_INITIALIZE_TRAVEL_SERVICE_FORM,
    data,
  };
};

const initialUserOptions = {
  status: {
    choices: [
      { value: "AC", display_name: "Active" },
      { value: "IN", display_name: "Inactive" },
    ],
  },
  company_type: {
    choices: [
      { value: "member", display_name: "Company User" },
      { value: "branch", display_name: "Branch" },
      { value: "subagent", display_name: "Sub Agent" },
      { value: "independentagent", display_name: "Independent Agent" },
      { value: "affiliate", display_name: "Affiliate" },
    ],
  },
};

export const fetchUserOptions = (lang) => async (dispatch, getState) => {
  const setLang = getState().setLang;

  if (getState().userObjOptions.langCode == (lang || setLang)) {
    return;
  }

  const data = await getUserOptions(lang || setLang);

  dispatch(
    userOptionsData({
      ..._.merge(initialUserOptions, data.actions.POST),
      langCode: lang || setLang,
    })
  );
};

export const userOptionsData = (data) => {
  return {
    type: USER_FETCH_OPTIONS,
    data,
  };
};

// filter relation
export const updateUsersListFilterForm = (payload) => {
  return { type: USER_UPDATE_LIST_FILTER_FORM, data: payload };
};
export const updateUsersListFilter = (payload) => {
  return { type: USER_UPDATE_FILTERS, data: payload };
};

export const applyUsersListFilter = () => (dispatch, getState) => {
  const state = getState().usersListFilterForm || {};
  dispatch(hideGlobalModal());
  dispatch(applyUsersListFilterForm(state));
  dispatch(fetchUsersList(1));
};

export const applyUsersListFilterForm = (data) => {
  return {
    type: USER_APPLY_LIST_FILTER,
    data,
  };
};

export const initializeUsersListFilterForm = () => (dispatch, getState) => {
  const state = getState().usersListFilter || {};
  dispatch(initializeUsersListFilterFormData(state));
};

export const initializeUsersListFilterFormData = (data) => {
  return {
    type: USER_INITIALIZE_LIST_FILTER_FORM,
    data,
  };
};

// columns relation
export const updateUsersListColumnsForm =
  (field, value) => (dispatch, getState) => {
    const state = getState().usersListColumnsForm || {};
    if (field.type == "push") {
      value = update(new Set(state[field.name].value), { $add: [field.key] });
    }
    if (field.type == "remove") {
      value = update(new Set(state[field.name].value), {
        $remove: [field.key],
      });
    }
    const usersListColumnsForm = update(state, {
      [field.name]: { value: { $set: [...value] } },
    });
    dispatch(updateUsersListColumnsFormData(usersListColumnsForm));
  };

export const updateUsersListColumnsFormData = (data) => {
  return {
    type: USER_UPDATE_LIST_COLUMNS_FORM,
    data,
  };
};

export const applyUsersListColumns = () => (dispatch, getState) => {
  const state = getState().usersListColumnsForm || {};
  dispatch(hideGlobalModal());
  dispatch(applyUsersListColumnsForm(state));
};

export const applyUsersListColumnsForm = (data) => {
  return {
    type: USER_APPLY_LIST_COLUMNS,
    data,
  };
};

export const initializeUsersListColumnsForm = () => (dispatch, getState) => {
  const currentCols = getState().usersListColumns;
  dispatch(initializeUsersListColumnsFormData(currentCols));
};

export const initializeUsersListColumnsFormData = (data) => {
  return {
    type: USER_INITIALIZE_LIST_COLUMNS_FORM,
    data,
  };
};

// ===================== User List ====================

export const resetUsersListFilter = () => (dispatch, getState) => {
  const data = update(initialUsersFilterForm, {
    $merge: getState().travelServicesForm,
  });
  dispatch(resetUsersListFilterForm(data));
};

export const resetUsersListFilterForm = (data) => {
  return {
    type: USER_RESET_LIST_FILTER_FORM,
    data,
  };
};

export const modifyUsersPage = (page) => (dispatch, getState) => {
  let usersList = getState().usersList;
  usersList = update(usersList, { page: { $set: page } });
  dispatch(fetchUsersListData({ ...usersList }));
  dispatch(fetchUsersList());
};

export const modifyUsersLimit = (limit) => (dispatch, getState) => {
  let usersList = getState().usersList;
  usersList = update(usersList, { limit: { $set: limit }, page: { $set: 1 } });
  dispatch(fetchUsersListData({ ...usersList }));
  dispatch(fetchUsersList());
};

export const fetchUsersListData = (data) => {
  return { type: USER_FETCH_DATA, data: data };
};

export const beginUsersListData = () => {
  return { type: USER_BEGIN_FETCH_DATA };
};

export const endUsersListData = () => {
  return { type: USER_END_FETCH_DATA };
};

export const fetchUsersList = () => async (dispatch, getState) => {
  dispatch(beginUsersListData());

  const usersListFilter = _.cloneDeep(getState().usersListFilter);
  const payload = paramsProcessor(usersListFilter);
  const service_type = getState().travelServiceOptions.service_type || {};
  const tempFilter = filterEmptyValue(getFilter(payload, service_type));
  const pagination = getPagination(getState().usersList);
  const filter = {
    id: tempFilter.id,
    created__gte: tempFilter.created__gte,
    created__lte: tempFilter.created__lte,
    edited__gte: tempFilter.edited__gte,
    edited__lte: tempFilter.edited__lte,
    email__icontains: tempFilter.email,
    last_name__icontains: tempFilter.last_name,
    first_name__icontains: tempFilter.first_name,
    phone__contains: tempFilter.phone,
    creator__icontains: tempFilter.creator,
    mobile_phone__contains: tempFilter.mobile_phone,
    company_legal_title__icontains: tempFilter.company_legal_title,
    company_name__icontains: tempFilter.company_brand_name,
    expertise_type__in: tempFilter.expertise_type,
    travel_sector__contains: tempFilter.travel_sector,
    gender__in: tempFilter.gender,
    job_description__in: tempFilter.job_description,
    company_type__in: tempFilter.company_type,
    department__in: tempFilter.department,
    travel_services__in: tempFilter.travel_services,
    status__in: tempFilter.status,
  };

  try {
    const data = await getUsersList({ ...filter, ...pagination });
    dispatch(
      fetchUsersListData({
        page: pagination.page,
        limit: pagination.limit,
        ...data,
      })
    );
  } catch (error) {
    commonErrorHandle({ error });
  } finally {
    dispatch(endUsersListData());
  }
};

export const newFetchUsersList = () => async (dispatch, getState) => {
  // The new fetchUserList after refactor the user filters in
  // admin-> company-> user management
  // dispatch(beginUsersListData());

  const { page, limit, filters } = getState().usersListFilterForm;

  try {
    const data = await getUsersList({ ...filters, page, limit });
    dispatch(
      fetchUsersListData({
        page,
        limit,
        ...data,
      })
    );
  } catch (error) {
    commonErrorHandle({ error });
  } finally {
    dispatch(endUsersListData());
  }
};

export const userGenericChange = (key, value) => {
  return { type: USER_FILTERS_GENERIC_CHANGE, key, value };
};

export const applyUserListColumnsData = (data) => {
  return { type: USER_APPLY_LIST_COLUMNS, data: data };
};

export const applyUserListFilterData = (data) => {
  return { type: USER_APPLY_LIST_FILTER, data: data };
};

export const applyUserListColumns = () => (dispatch, getState) => {
  const state = getState().usersListColumnsForm || {};
  dispatch(hideGlobalModal());
  dispatch(applyUserListColumnsData(state));
};

export const applyUserListFilter = () => (dispatch, getState) => {
  const state = getState().usersListFilterForm || {};
  dispatch(hideGlobalModal());
  dispatch(applyUserListFilterData(state));
  dispatch(fetchUsersList(1));
};

// ======================== User Profile Page ========================
export const userProfileLoading = () => {
  return { type: USER_PROFILE_LOADING };
};

export const userProfileLoaded = () => {
  return { type: USER_PROFILE_LOADED };
};

// ===================== User Profile: Base Data ====================

export const changeEmail = (data) => {
  return { type: USER_CHANGE_EMAIL, data };
};

export const beginsubmitNextEmail = () => {
  return { type: USER_BEGIN_SUBMIT_NEXT_EMAIL };
};

export const endsubmitNextEmail = () => {
  return { type: USER_END_SUBMIT_NEXT_EMAIL };
};

export const submitNextEmail = () => async (dispatch, getState) => {
  const email = getState().nextEmail;
  const result = submitChangeProcessor({ email });
  if (result.email.hasError) {
    notifyCommonError(result.email.errorMessage);
    return;
  }
  dispatch(beginsubmitNextEmail());

  try {
    await changeEmailAPI({ email });
    notifyCommonSuccess("Please verify your email address.");
  } catch (error) {
    error.ovrdErrHandle && error.ovrdErrHandle();
    commonErrorHandle({ error });
  } finally {
    dispatch(hideGlobalModal());
    dispatch(endsubmitNextEmail());
  }
};

export const viewUserBaseDataMode = () => {
  return { type: USER_VIEW_BASE_DATA_MODE };
};

export const editUserBaseDataMode = () => {
  return { type: USER_EDIT_BASE_DATA_MODE };
};

export const uploadingUserBaseDataMode = () => {
  return { type: USER_UPLOADING_BASE_DATA_MODE };
};

export const initializeUserBaseDataForm = () => (dispatch, getState) => {
  const userMeta = getState().userMeta;
  const tmp = {};
  [
    "user.first_name",
    "user.last_name",
    "gender",
    "phone",
    "mobile_phone",
  ].forEach((f) => {
    if (f.includes(".")) {
      tmp[_.last(f.split("."))] = _.get(userMeta, f);
    } else {
      tmp[f] = userMeta[f];
    }
  });

  dispatch(initializeUserBaseForm({ value: tmp }));
};

export const initializeUserBaseForm = (data) => {
  return {
    type: USER_INITIALIZE_BASE_DATA_FORM,
    data,
  };
};
export const initializeUserGenderForm = () => (dispatch, getState) => {
  const state = getState();
  const gender = state.userMeta.gender;
  dispatch(initializeUserGenderFormData({ value: gender }));
};

export const initializeUserGenderFormData = (data) => {
  return {
    type: USER_INITIALIZE_USER_GENDER_FORM,
    data,
  };
};

export const editUserBaseDataForm = (data) => {
  return { type: USER_EDIT_BASE_DATA_FORM, data: data };
};

export const editUserJobDescriptionForm = (data) => {
  return { type: USER_EDIT_JOB_DESCRIPTION_FORM, data: data };
};

// ================= User Profile: Expertise Section =================
export const viewUserExpertiseMode = () => {
  return { type: USER_VIEW_USER_EXPERTISE_MODE };
};

export const editUserExpertiseMode = () => {
  return { type: USER_EDIT_USER_EXPERTISE_MODE };
};

export const initializeUserExpertiseForm = () => (dispatch, getState) => {
  const state = getState();
  const expertise_type = state.userMeta.expertise_type;
  dispatch(initializeUserExpertiseFormData({ value: expertise_type }));
};

export const initializeUserExpertiseFormData = (data) => {
  return {
    type: USER_INITIALIZE_EXPERTISE_FORM,
    data,
  };
};

export const validateUserBaseDataForm = (data) => {
  return { type: USER_VALIDATE_BASE_DATA_FORM, data: data };
};

export const uploadingUserExpertiseMode = () => {
  return { type: USER_UPLOADING_USER_EXPERTISE_MODE };
};

export const editUserExpertiseForm = (data) => {
  return { type: USER_EDIT_EXPERTISE_FORM, data: data };
};

// =============== User Profile: Travel Sector Section ===============
export const viewUserTravelSectorMode = () => {
  return { type: USER_VIEW_TRAVEL_SECTOR_MODE };
};

export const editUserTravelSectorMode = () => {
  return { type: USER_EDIT_TRAVEL_SECTOR_MODE };
};

export const initializeUserTravelSectorForm = () => (dispatch, getState) => {
  const state = getState();
  const travel_sector = state.userMeta.travel_sector;
  dispatch(initializeUserTravelSectorFormData({ value: travel_sector }));
};

export const initializeUserTravelSectorFormData = (data) => {
  return {
    type: USER_INITIALIZE_TRAVEL_SECTOR_FORM,
    data,
  };
};

export const uploadingUserTravelSectorMode = () => {
  return { type: USER_UPLOADING_TRAVEL_SECTOR_MODE };
};

export const editUserTravelSectorForm = (data) => {
  return { type: USER_EDIT_TRAVEL_SECTOR_FORM, data: data };
};

// ======================= Preferences Section ======================
export const viewUserPreferencesMode = () => (dispatch) => {
  dispatch(initializeUserLanguageForm());
  dispatch(initializeUserImageForm());
  dispatch(viewUserPreferencesDataMode());
};

export const viewUserPreferencesDataMode = () => {
  return {
    type: USER_VIEW_PREFERENCES_MODE,
  };
};

export const editUserPreferencesMode = () => {
  return { type: USER_EDIT_PREFERENCES_MODE };
};

export const uploadingUserPreferencesMode = () => {
  return { type: USER_UPLOADING_PREFERENCES_MODE };
};

export const initializeUserLanguageForm = () => (dispatch, getState) => {
  const state = getState();
  const preferred_language = state.userMeta.preferred_language;
  dispatch(initializeUserLanguageDataForm({ value: preferred_language }));
};

export const initializeUserLanguageDataForm = (data) => {
  return {
    type: USER_INITIALIZE_LANGUAGE_FORM,
    data,
  };
};

export const initializeUserImageForm = () => (dispatch, getState) => {
  const state = getState();
  const image =
    _.get(state.userMeta, "logo.thumbnail_url", null) ||
    _.get(state.userMeta, "logo.photo_sm_url", null);

  dispatch(initializeUserImageFormData({ value: image }));
};

export const initializeUserImageFormData = (data) => {
  return {
    type: USER_INITIALIZE_IMAGE_FORM,
    data,
  };
};

export const editUserLanguageForm = (data) => {
  return { type: USER_EDIT_LANGUAGE_FORM, data: data };
};

//img relation
export const changeUserImg = (data) => (dispatch) => {
  dispatch(changeUploadImgFactory(data, USER_UPLOAD_PREFERENCE_IMAGE));
};

// ===================== Job Description Section ====================
export const viewUserJobDescriptionMode = () => (dispatch) => {
  dispatch(initializeUserDepartmentForm());
  dispatch(initializeUserJobDescriptionForm());
  dispatch(viewUserJobDescriptionDataMode());
};

export const viewUserJobDescriptionDataMode = () => {
  return {
    type: USER_VIEW_JOB_DESCRIPTION_MODE,
  };
};

export const editUserJobDescriptionMode = () => {
  return { type: USER_EDIT_JOB_DESCRIPTION_MODE };
};

export const uploadingUserJobDescriptionMode = () => {
  return { type: USER_UPLOADING_JOB_DESCRIPTION_MODE };
};

export const initializeUserDepartmentForm = () => (dispatch, getState) => {
  const state = getState();
  const department = state.userMeta.department;
  dispatch(initializeUserDepartmentFormData({ value: department }));
};

export const initializeUserDepartmentFormData = (data) => {
  return {
    type: USER_INITIALIZE_DEPARTMENT_FORM,
    data,
  };
};

export const initializeUserJobDescriptionForm = () => (dispatch, getState) => {
  const state = getState();
  const job_description = state.userMeta.job_description;
  dispatch(initializeUserJobDescriptionFormData({ value: job_description }));
};

export const initializeUserJobDescriptionFormData = (data) => {
  return {
    type: USER_INITIALIZE_JOB_DESCRIPTION_FORM,
    data,
  };
};

export const editUserDepartmentForm = (data) => {
  return { type: USER_EDIT_DEPARTMENT_FORM, data };
};

// ===================== Work Duties Section ====================
export const viewUserWorkDutiesMode = () => (dispatch) => {
  dispatch(initializeUserTravelSectorForm());
  dispatch(initializeUserExpertiseForm());
  dispatch(viewUserWorkDutiesDataMode());
};

export const viewUserWorkDutiesDataMode = () => {
  return {
    type: USER_VIEW_WORK_DUTIES_MODE,
  };
};

export const editUserWorkDutiesMode = () => {
  return { type: USER_EDIT_WORK_DUTIES_MODE };
};

export const uploadingUserWorkDutiesMode = () => {
  return { type: USER_UPLOADING_WORK_DUTIES_MODE };
};

// ==================== Service Specialty Section ====================

export const selectServiceCategory = (data) => {
  return { type: USER_SELECT_SERVICE_CATEGORY, data: data };
};

export const viewServiceCategoriesMode = () => (dispatch) => {
  dispatch(initializeTravelServicesForm());
  dispatch(viewServiceCategoriesDataMode());
};

export const viewServiceCategoriesDataMode = () => {
  return {
    type: USER_VIEW_SERVICE_CATEGORIES_MODE,
  };
};

export const editServiceCategoriesMode = () => {
  return { type: USER_EDIT_SERVICE_CATEGORIES_MODE };
};

export const loadingUserServiceSpecialtyMode = () => {
  return { type: USER_LOADING_SERVICE_SPECIALTY_MODE };
};

export const selectTravelService = (data) => (dispatch, getState) => {
  data.travelServices = getState().travelServices;
  dispatch(selectTravelServiceData(data));
};

export const selectTravelServiceData = (data) => {
  return {
    type: USER_SELECT_TRAVEL_SERVICE,
    data,
  };
};

export const initializeTravelServicesForm = () => (dispatch, getState) => {
  const state = getState();
  const travel_services = state.userMeta.travel_services;
  dispatch(initializeTravelServicesFormData({ value: travel_services }));
};

export const initializeTravelServicesFormData = (data) => {
  return {
    type: USER_INITIALIZE_SERVICE_SPECIALTY_FORM,
    data,
  };
};

export const loadingServiceCategory = () => {
  return { type: USER_LOADING_SERVICE_CATEGORY_MODE };
};

// ============================ Submitoras ===========================
export const formatUserJobDescriptionForm = () => {
  return { type: USER_FORMAT_JOB_DESCRIPTION_FORM };
};

export const submitUserForm =
  (fields, uploadingAction, errorAction = [], successAction, alert = true) =>
  (dispatch, getState) => {
    const state = getState();
    const userMeta = state.userMeta;
    const result = submitProcessor(state, "userForm", initialUserForm, fields);
    const file = state.userForm.original_image.fileName;
    const formData = new FormData();
    formData.append("original_image", file);
    formData.append("content_type", "appuser");
    formData.append("object_id", userMeta.id);

    if (result.hasError) {
      errorAction.forEach((action) => dispatch(action(result)));
      if (alert) {
        result.errorMsgs.forEach((e) => notifyCommonError(e.msg));
      }
      return null;
    }

    if (
      ["phone", "mobile_phone"].every((k) =>
        Object.keys(result.payload).includes(k)
      )
    ) {
      const { mobile_phone, phone } = result.payload;
      if (!mobile_phone && !phone) {
        notifyCommonError(
          "Please enter at least, a mobile phone number or a fixed phone number."
        );

        return null;
      }
    }

    if (
      ["first_name", "last_name"].some((k) =>
        Object.keys(result.payload).includes(k)
      )
    ) {
      result.payload["user"] = { id: userMeta.user.id };
      if (typeof result.payload.first_name !== "undefined") {
        result.payload.user["first_name"] = result.payload.first_name;
        delete result.payload.first_name;
      }

      if (typeof result.payload.last_name !== "undefined") {
        result.payload.user["last_name"] = result.payload.last_name;
        delete result.payload.last_name;
      }
    }

    if (
      file !== undefined &&
      !(Object.prototype.toString.call(file) === "[object Object]")
    ) {
      dispatch(uploadingAction());
      uploadImgLink(formData)
        .then((res) => {
          const data = { value: res.original_image, id: res.id };
          dispatch(uploadedUserPreferenceImage(data));
          dispatch(
            submitUserProfileForm(result, uploadingAction, successAction)
          );
        })
        .catch((error) => {
          dispatch(editUserBaseDataMode());
          notifyCommonError("Error updating your profile please try again.");
          error.ovrdErrHandle && error.ovrdErrHandle();
        });
    } else {
      dispatch(submitUserProfileForm(result, uploadingAction, successAction));
    }
    return null;
  };

export const uploadedUserPreferenceImage = (data) => {
  return {
    type: USER_UPLOAD_PREFERENCE_IMAGE,
    data,
  };
};

export const submitUserProfileForm =
  (data, uploadingAction, successAction) => (dispatch, getState) => {
    dispatch(uploadingAction());
    submitUserProfile(getState().userMeta.id, data.payload, "patch")
      .then((data) => {
        dispatch(submitUserProfileFormData(data));
        dispatch(successAction());
        notifyCommonSuccess("Successfully updated your profile.");
      })
      .catch((error) => {
        dispatch(editUserBaseDataMode());
        error.ovrdErrHandle && error.ovrdErrHandle();
        commonErrorHandle({ error });
      });
  };

export const submitUserProfileFormData = (data) => {
  return {
    type: USER_FETCHED_DETAILS,
    data,
  };
};

export const applyUsersDefaultOption = () => (dispatch, getState) => {
  let form = getState().usersListColumnsForm;

  for (const key in defaultOption) {
    form = update(form, {
      [key]: { value: { $set: defaultOption[key].value } },
    });
  }
  dispatch(applyUsersDefaultOptionData(form));
};

export const applyUsersDefaultOptionData = (data) => {
  return {
    type: USER_UPDATE_LIST_COLUMNS_FORM,
    data,
  };
};

export const userFeatureGroups = () => async (dispatch) => {
  try {
    const data = await getUsersFeatureGroupAPI();
    dispatch(userFeatureGroupsData(data));
  } catch (error) {
    console.log(error);
  }
};

export const userFeatureGroupsData = (data) => {
  return {
    type: USER_PERMISSION_FEATURE_GROUPS,
    data,
  };
};

export const fetchUsersEmailConfigList = () => async (dispatch) => {
  try {
    const data = await getUsersEmailConfigListAPI();
    dispatch(fetchUsersEmailConfigListData(data));
  } catch (error) {
    console.log(error);
  }
};

export const fetchUsersEmailConfigListData = (data) => {
  return {
    type: USER_EMAIL_CONFIG_LIST,
    data,
  };
};

export * from "./add_user_actions";
