import _ from "lodash";
import { variables } from "@src/jsssetup";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import {
  SectionHeader,
  SectionSubHeader,
  sectionCommonStyles,
} from "./components";
import { NormalInputField } from "@src/components/forms";
import { InlineIcon } from "@iconify/react";
import { useFormikContext } from "formik";
import { useQueryClient } from "@tanstack/react-query";

const addGuestStyles = createUseStyles(() => ({
  AddGuest: {
    display: "grid",
    gridGap: variables.normal_gap,
  },
  btnsContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(3,max-content)",
    gridGap: variables.half_gap,
    placeContent: "center",
    paddingTop: variables.normal_gap,
  },
  cancelBtn: {
    background: "#F6F8FA",
    color: variables.colors.text.colored,
    fontSize: "medium",
    fontWeight: 600,
  },
  saveAddBtn: {
    background: "#F6F8FA",
    color: variables.colors.text.colored,
    border: `1px solid ${variables.colors.text.colored}`,
    borderRadius: "5px",
    fontSize: "medium",
    fontWeight: 600,
  },
  saveBtn: {
    background: variables.colors.easy.orange,
    color: variables.colors.text.light,
    border: `1px solid ${variables.colors.text.colored}`,
    borderRadius: "5px",
    fontSize: "medium",
    fontWeight: 600,
  },
  formContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(4,max-content)",
    placeContent: "start",
    placeItems: "center",
    gridGap: variables.normal_gap,
  },
  actionBtn: {
    fontSize: "large",
    color: variables.colors.text.colored,
    cursor: "pointer",
  },
}));
const AddGuest = ({ guestIdx, guest, onSave, onResetGuest }) => {
  const classes = addGuestStyles();
  const [initialState, setInitialState] = useState(guest);

  return (
    <div className={classes.AddGuest}>
      <div className={classes.titleContainer}>
        <SectionHeader text="Guest Details" />
      </div>
      <div className={classes.formContainer}>
        <NormalInputField
          label="First Name"
          name={`guests.${guestIdx}.firstName`}
          customStyles={{ input: { width: "8rem" } }}
          highlightErrors={true}
        />
        <NormalInputField
          label="Last Name"
          name={`guests.${guestIdx}.lastName`}
          customStyles={{ input: { width: "8rem" } }}
          highlightErrors={true}
        />
        <NormalInputField
          label="Price"
          name={`guests.${guestIdx}.price`}
          type="number"
          customStyles={{ input: { width: "5rem" } }}
          highlightErrors={true}
        />
        <div className={classes.btnsContainer}>
          <InlineIcon
            icon="mdi:tick"
            className={classes.actionBtn}
            onClick={onSave}
          />
          <InlineIcon
            icon="ph:x-bold"
            className={classes.actionBtn}
            onClick={() => onResetGuest(initialState)}
          />
        </div>
        <NormalInputField
          label="Description"
          name={`guests.${guestIdx}.description`}
          customStyles={{ inputGroup: { gridColumn: "span 3" } }}
          highlightErrors={true}
        />
      </div>
    </div>
  );
};
AddGuest.defaultProps = {
  guest: {},
  guestIdx: 0,
};
AddGuest.propTypes = {
  onSave: PropTypes.func.isRequired,
  onResetGuest: PropTypes.func.isRequired,
  guest: PropTypes.object.isRequired,
  guestIdx: PropTypes.number.isRequired,
};

const guestStyles = createUseStyles(() => ({
  Guest: {
    display: "grid",
    gridTemplateColumns: "1fr repeat(3,max-content)",
    alignItems: "center",
    gridGap: variables.normal_gap,
  },
  name: {
    fontSize: "medium",
    fontWeight: "600",
  },
  price: {
    fontSize: "medium",
    fontWeight: "600",
  },
  editBtn: {
    fontSize: "medium",
    color: variables.colors.text.colored,
    cursor: "pointer",
  },
  divider: {
    marginTop: variables.half_gap,
    marginBottom: variables.half_gap,
    border: "0",
    borderTop: "1px solid #e5e5ea",
  },
  deleteBtn: {
    fontSize: "medium",
    color: variables.colors.text.colored,
    cursor: "pointer",
  },
  description: {
    fontSize: "small",
    fontWeight: 500,
  },
}));
const Guest = ({ currency, guest, guestIdx, onRemoveGuest, onEdit }) => {
  const classes = guestStyles();

  return (
    <React.Fragment>
      <div className={classes.Guest}>
        <span className={classes.name}>
          {guest.firstName} {guest.lastName}
          <br />
          <span className={classes.description}>{guest.description}</span>
        </span>
        <span className={classes.price}>
          {(guest.price || 0).toLocaleString("en-US", {
            style: "currency",
            currency: currency || "EUR",
          })}
        </span>
        <InlineIcon
          icon="ic:baseline-edit"
          className={classes.editBtn}
          onClick={onEdit}
        />
        <InlineIcon
          icon="ph:x-bold"
          className={classes.deleteBtn}
          onClick={() => onRemoveGuest(guestIdx)}
        />
      </div>
      <hr className={classes.divider} />
    </React.Fragment>
  );
};
Guest.defaultProps = {
  guest: {},
  guestIdx: 0,
  currency: "",
};
Guest.propTypes = {
  guest: PropTypes.object.isRequired,
  guestIdx: PropTypes.number.isRequired,
  onRemoveGuest: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
};

const guestsStyles = createUseStyles({
  Guests: {
    ...sectionCommonStyles,
    display: "grid",
    gridGap: variables.half_gap,
  },
  addGuestBtn: {
    cursor: "pointer",
  },
});
export const Guests = ({
  onResetGuest,
  onRemoveGuest,
  addGuestForm,
  setAddGuestForm,
}) => {
  const { values, setFieldValue } = useFormikContext();
  const classes = guestsStyles();
  const [addGuestActive, setAddGuestActive] = useState(false);
  const guests = values.guests;
  const currency = values.currency;

  function addGuestHandle() {
    if (!addGuestForm) {
      const newGuest = { firstName: "", lastName: "", price: "", currency: "" };
      setAddGuestActive(true);
      setFieldValue("guests", [...guests, newGuest]);
      setAddGuestForm(guests.length);
    }
  }

  const queryClient = useQueryClient();
  const offerData = queryClient.getQueryData([
    "invoiceOfferData",
    values.product_reference,
  ]);
  const addonData = queryClient.getQueryData([
    "invoiceAddonData",
    values.product_reference,
  ]);

  // Initialize values in case of product type offer
  useEffect(() => {
    if (values.product_type !== "offer") return null;
    if (!offerData) return null;

    const guests = offerData?.guests ?? [];
    if (!guests.length) return null;

    const guestsTmp = guests.map((guest) => ({
      firstName: `${guest.guest_title} ${guest.first_name}`,
      lastName: guest.last_name,
      price: 0,
      currency: "",
    }));
    setFieldValue("guests", guestsTmp);
    return null;
  }, [offerData]);

  // Initialize values in case of product type addon
  useEffect(() => {
    if (values.product_type !== "addon") return null;
    if (!addonData) return null;

    const guests = addonData?.data?.guests || [];
    if (!guests.length) return null;

    const pData = _.last(addonData?.data?.pricing_data || []);

    var sortedGuests = _.sortBy(guests, (v) => v?.room_uids?.[0]);
    sortedGuests = _.sortBy(
      _.flatten(
        Object.values(
          _.sortBy(
            _.groupBy(sortedGuests, (v) => v?.room_uids?.[0]),
            (v) => v.every((g) => !g.group_leader)
          )
        )
      )
    );
    const guestsTmp = sortedGuests.map((guest) => {
      const pricing_breakdown =
        addonData?.data?.service_data?.price?.[0]?.pricing_breakdown || {};

      const roomPrice =
        Object.entries(pricing_breakdown).find(([room_guest_uid, price]) => {
          return room_guest_uid.includes(guest.room_uids?.[0]);
        }) || [];

      const room = addonData?.data?.booked_rooms?.find((room) =>
        (guest?.room_uids || []).includes(room.room_idx_uid)
      );

      return {
        firstName: `${_.capitalize(guest.guest_title)}. ${guest.first_name}`,
        lastName: `${guest.last_name}${
          guest.group_leader ? " (Group Leader)" : ""
        }`,
        price: roomPrice.length ? roomPrice[1] : 0,
        currency: pData?.currency || "",
        description: room?.name || "N/A",
      };
    });
    setFieldValue("guests", guestsTmp);
    return null;
  }, [addonData]);

  return (
    <div className={classes.Guests}>
      <SectionHeader text="Guests" />
      <React.Fragment>
        {guests.map((guest, idx) =>
          addGuestForm === idx ? (
            <AddGuest
              key={idx}
              setAddGuestForm={setAddGuestForm}
              guests={guests}
              guest={guest}
              guestIdx={idx}
              onSave={() => {
                setAddGuestForm(null);
                setAddGuestActive(false);
              }}
              onResetGuest={(initialGuestState) => {
                setAddGuestForm(null);
                addGuestActive
                  ? onRemoveGuest(idx)
                  : onResetGuest(initialGuestState, idx);
              }}
            />
          ) : (
            <Guest
              currency={currency}
              key={idx}
              guestIdx={idx}
              guest={guest}
              onEdit={() => {
                if (addGuestForm === null) {
                  setAddGuestForm(idx);
                  setAddGuestActive(false);
                }
              }}
              onRemoveGuest={() => {
                if (addGuestForm === null) {
                  onRemoveGuest(idx);
                }
              }}
            />
          )
        )}
      </React.Fragment>
      {addGuestForm === null && (
        <div className={classes.addGuestBtn} onClick={addGuestHandle}>
          <SectionSubHeader text="Add guest" />
        </div>
      )}
    </div>
  );
};
Guests.defaultProps = {
  addGuestForm: null,
};
Guests.propTypes = {
  addGuestForm: PropTypes.number,
  setAddGuestForm: PropTypes.func.isRequired,
  onResetGuest: PropTypes.func.isRequired,
  onRemoveGuest: PropTypes.func.isRequired,
};
