import _ from "lodash";
import { cardStyles, variables } from "@src/jsssetup";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { createUseStyles } from "react-jss";
import { DayItemControls, daySrvContainerStyles } from "..";
import { dayAdhocTypes } from "../../Modals/AddAddhocServiceModal";
import OverviewPrice from "../../OverviewPrice";
import { removeDbDAdhocSrv } from "@src/actions/Project/TripPlanner/day_by_day/addhoc_services";
import { connect, useSelector } from "react-redux";
import { isServiceFilterSelectedSelector } from "@src/selectors/Project/TripPlanner/service_filters";
import { daySrvBodyStyles, daySrvHeaderStyles } from "../common";
import { useServiceCardExpansion } from "@src/pages/hooks";
import { InlineIcon } from "@iconify/react";
import {
  getServicePricingSelector,
  srvPriceKeyCreator,
} from "@src/selectors/Project/TripPlanner/pricing";
import { PricingFormContext } from "../../../TripPlanner";
import ServicePricingForm from "../../ServicePricingForm";
import CancellationPolicyDetails from "../../CancellationPolicyDetails";

const genericAddhocSrvStyles = createUseStyles({
  GenericAdhocSrvContainer: { ...daySrvContainerStyles() },
  GenericAdhocSrv: { display: "grid", gridGap: variables.half_gap },
  header: {
    ...daySrvHeaderStyles(),
    ...cardStyles.card,
    borderBottomColor: variables.colors.services.addhocBorder,
    background: variables.colors.services.addhoc,
  },
  headerActions: {
    display: "grid",
    gridAutoFlow: "column",
    grid: `calc(${variables.normal_gap} / 2)`,
  },
  body: (props) => ({
    ...daySrvBodyStyles(props),
    ...cardStyles.card,
    gridAutoRows: "max-content",
    height: "max-content",
    maxHeight: "12rem",
    overflow: "auto",
    background: variables.colors.services.addhocBody,
  }),
  expandIconCntr: {
    display: "grid",
    placeContent: "center",
    width: "1.5rem",
    height: "1.5rem",
    borderRadius: "50%",
    background: variables.colors.easy.orange,
    padding: variables.half_gap,
    cursor: "pointer",
  },
  expandIcon: { fontSize: "large", color: variables.colors.text.light },
});
var GenericAdhocSrv = ({ dayIdx, service, onRemove, onEdit }) => {
  const { services_collapsed, toggleSelfCollapse } = useServiceCardExpansion();
  const classes = genericAddhocSrvStyles({ collapsed: services_collapsed });
  const { showPricingForm } = useContext(PricingFormContext);
  const serviceIsSelected = useSelector((state) =>
    isServiceFilterSelectedSelector(state, { service_type: "AD" })
  );

  const { currency, amount: price } = service;

  const { markup } = useSelector((state) =>
    getServicePricingSelector(state, {
      key: srvPriceKeyCreator({ service, service_type: "AD" }),
    })
  );

  return !serviceIsSelected ? null : (
    <div className={classes.GenericAdhocSrvContainer}>
      {!services_collapsed && typeof dayIdx !== "undefined" ? (
        <DayItemControls
          onDeselect={() => onRemove({ dayIdx, srv_id: service.id })}
          onEdit={() => onEdit({ srv_id: service.id })}
        />
      ) : (
        <div />
      )}
      <div className={classes.GenericAdhocSrv}>
        <div className={classes.header}>
          <span>
            {dayAdhocTypes?.[service.addhoc_service_type] ??
              service.addhoc_service_type}
            : {service.description}
          </span>
          <div className={classes.headerActions}>
            <div className={classes.expandIconCntr}>
              <InlineIcon
                className={classes.expandIcon}
                icon={
                  services_collapsed
                    ? "ri:arrow-down-s-line"
                    : "ri:arrow-up-s-line"
                }
                onClick={toggleSelfCollapse}
              />
            </div>
          </div>
        </div>
        {!services_collapsed && (
          <div className={classes.body}>
            <span>
              <strong>Notes</strong>
            </span>
            <p>{service.notes || "N/A"}</p>
            {(service?.cancellation_policies ?? []).length > 0 && (
              <React.Fragment>
                <span>
                  <strong>Cancellation Policies</strong>
                </span>
                <CancellationPolicyDetails
                  cxl_policies={service.cancellation_policies}
                />
              </React.Fragment>
            )}
          </div>
        )}
      </div>
      {services_collapsed ? (
        <div />
      ) : showPricingForm ? (
        <ServicePricingForm service={service} service_type="AD" />
      ) : (
        <OverviewPrice
          searching={false}
          serviceType="MI"
          price={price}
          currency={currency}
          markup={markup}
          onDetails={() => console.log("onDetails")}
        />
      )}
    </div>
  );
};
GenericAdhocSrv.propTypes = {
  dayIdx: PropTypes.number,
  service: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};
const mapDispatchToProps = (dispatch) => {
  return {
    onRemove: ({ dayIdx, srv_id }) =>
      dispatch(removeDbDAdhocSrv({ dayIdx, srv_id })),
  };
};
GenericAdhocSrv = connect(null, mapDispatchToProps)(GenericAdhocSrv);
export { GenericAdhocSrv };
