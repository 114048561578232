import _ from "lodash";
import { modalGenericStyles } from "@src/jsssetup";
import {
  getAdhocTrpSrvByIdSelector,
  getSetupFormDataSelector,
} from "@src/selectors/Project/TripPlanner";
import { Formik } from "formik";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { createUseStyles } from "react-jss";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "rsuite";
import { TrainAdhocSrvFormFields } from "./products/addhoc/train";
import { addonSrvPriceCalculator } from "./products/addhoc/common";
import { updateTrpAdhocSrv } from "@src/actions/Project/TripPlanner/addhoc/transportation";

const editTrainSrvModalStyles = createUseStyles({
  ...modalGenericStyles,
  EditTrainSrvModal: modalGenericStyles.modal,
  card: { ...modalGenericStyles.card, minWidth: "45vw" },
});
const EditTrainSrvModal = ({ srv_id, onCancel }) => {
  const srv_type = "TRA";
  const classes = editTrainSrvModalStyles();

  const { service, adults, children } = useSelector((state) => {
    const service = getAdhocTrpSrvByIdSelector(state, {
      srv_id,
      srv_type,
    });

    const { adults, children } = getSetupFormDataSelector(state);
    return { service, adults, children };
  });

  const dispatch = useDispatch();
  const onSubmit = useCallback(
    ({ service }) => dispatch(updateTrpAdhocSrv({ srv_id, srv_type, service })),
    [dispatch]
  );

  return (
    <div className={classes.EditTrainSrvModal}>
      <div className={classes.card}>
        <div className={classes.cardHeader}>
          <h5>Edit Train Service</h5>
        </div>
        <Formik
          initialValues={{ ...service.service }}
          validate={(values) =>
            addonSrvPriceCalculator({ values, adults, childs: children })
          }
          onSubmit={(values) => {
            onSubmit({ service: values });
            onCancel();
          }}>
          {({ values, setFieldValue, submitForm }) => (
            <React.Fragment>
              <div className={classes.cardBody}>
                <TrainAdhocSrvFormFields
                  values={values}
                  origin_name={_.get(service, "service.origin.name_en", "N/A")}
                  destination_name={_.get(
                    service,
                    "service.destination.name_en",
                    "N/A"
                  )}
                  setFieldValue={setFieldValue}
                />
              </div>
              <div className={classes.cardActions}>
                <Button appearance="ghost" onClick={onCancel}>
                  <strong>Cancel</strong>
                </Button>
                <Button appearance="primary" onClick={submitForm}>
                  <strong>Apply</strong>
                </Button>
              </div>
            </React.Fragment>
          )}
        </Formik>
      </div>
    </div>
  );
};
EditTrainSrvModal.propTypes = {
  srv_id: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
};
export default EditTrainSrvModal;
