import { createHashHistory } from "history";
import { notifySaveAsFailed } from "@src/components/common/notifications/TripPlanner/saveas_notifications";
import { getSetupFormDataSelector } from "@src/selectors/Project/TripPlanner";
import { saveAsTrip } from "../save_actions";
import {
  TRIPPLANNER_SAVEAS_MODAL_CHANGE,
  TRIPPLANNER_SAVEAS_MODAL_HIDE,
  TRIPPLANNER_SAVEAS_MODAL_IDLE,
  TRIPPLANNER_SAVEAS_MODAL_LOADING,
  TRIPPLANNER_SAVEAS_MODAL_SHOW,
} from "../types/modal_types";

export const showSaveAsModal = () => (dispatch, getState) => {
  const { usage, title } = getSetupFormDataSelector(getState());

  dispatch({
    type: TRIPPLANNER_SAVEAS_MODAL_SHOW,
    usage,
    title: `${title} (Copy)`,
  });
};

export const hideSaveAsModal = () => {
  return { type: TRIPPLANNER_SAVEAS_MODAL_HIDE };
};

export const loadingSaveAsModal = () => {
  return { type: TRIPPLANNER_SAVEAS_MODAL_LOADING };
};

export const idleSaveAsModal = () => {
  return { type: TRIPPLANNER_SAVEAS_MODAL_IDLE };
};

export const onChangeSaveAsModalAttrs = (key, value) => {
  return { type: TRIPPLANNER_SAVEAS_MODAL_CHANGE, key, value };
};

export const saveAs = (options) => async (dispatch) => {
  const { title, usage, link } = options;
  const newId = await dispatch(saveAsTrip({ title, usage, link }));

  if (!newId) {
    notifySaveAsFailed();
    return false;
  }
  return newId;
};
