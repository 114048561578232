import _ from "lodash";
import {
  getSecondaryLegFlightSelector,
  getSelectedFlightByLegUidSelector,
  getSelectedTrfSelector,
  getSetupFormDataSelector,
  getTransferPriceSelector,
} from "@src/selectors/Project/TripPlanner";
import {
  getServicePricingSelector,
  srvPriceKeyCreator,
} from "@src/selectors/Project/TripPlanner/pricing";
import { isServiceFilterSelectedSelector } from "@src/selectors/Project/TripPlanner/service_filters";
import PropTypes from "prop-types";
import React, { useCallback, useContext } from "react";
import { createUseStyles } from "react-jss";
import { useDispatch, useSelector } from "react-redux";
import {
  sideControlsStyles,
  srvHeaderStyles,
  srvHeaderTitleActionsStyles,
  srvHeaderTitleStyles,
} from "../../Controllers/OverviewDestination";
import OverviewTransfer from "../../Controllers/OverviewTransfer";
import { PricingFormContext } from "../../TripPlanner";
import { daySrvContainerStyles } from "../Day";
import OverviewPrice from "../OverviewPrice";
import PrebookPrice from "../PrebookPrice";
import ServicePricingForm from "../ServicePricingForm";
import { useServiceCardExpansion } from "@src/pages/hooks";
import { useQuery } from "@tanstack/react-query";
import { trfSearch as transferSearch } from "@src/api";
import { getUserSourceEntitySelector } from "@src/selectors/Shared/user_selectors";
import { getFerryByLegUidSelector } from "@src/selectors/Project/TripPlanner/ferry_selectors";
import { saveTransfer } from "@src/actions/Project/TripPlanner";
import { BookChecker } from "../ItineraryOverviewCommon";

function generatePayloadGuid(payload) {
  return [
    payload?.origin_lat,
    payload?.origin_lng,
    payload?.destination_lat,
    payload?.destination_lng,
    payload?.inbound ?? "",
    payload?.outbound ?? "",
  ].join("-");
}

const transferStyles = createUseStyles({
  TrfHeader: (props) => srvHeaderStyles(props),
  srvHeaderTitle: (props) => srvHeaderTitleStyles(props),
  srvHeaderTitleActions: () => srvHeaderTitleActionsStyles(),
  Transfer: (props) =>
    daySrvContainerStyles({ currentStep: props.currentStep }),
  sideControls: sideControlsStyles(),
});
export const Transfer = ({ trfType, destOrder, currentStep }) => {
  const classes = transferStyles({ currentStep });
  var transfer = useSelector((state) =>
    getSelectedTrfSelector(state, { destOrder, transfer_type: trfType })
  );

  const {
    source_entity,
    serviceIsSelected,
    accommodation,
    adults,
    children,
    destination,
    trp_lat,
    trp_lng,
    trp_name,
    trp_date,
  } = useSelector((state) => {
    const source_entity = getUserSourceEntitySelector(state);
    const serviceIsSelected = isServiceFilterSelectedSelector(state, {
      service_type: "TRF",
    });

    const destination = state.tripPlannerDestinations.find(
      (d) => d.order === destOrder
    );

    const { adults, children } = getSetupFormDataSelector(state);

    const accommodation = (state.tripPlannerAccData?.[destOrder] ?? []).find(
      (a) => a.selected
    );

    const leg = state.tripPlannerLegsReducer.find((l) =>
      trfType === "inbound"
        ? l.origin_order === destOrder - 1
        : l.origin_order === destOrder
    );

    const trp_service_type = leg?.service_preferences?.service_type;
    var flight = null;
    var trp_lat = 0;
    var trp_lng = 0;
    var trp_name = null;
    var trp_date = null;
    if (trp_service_type === "FL") {
      flight = getSelectedFlightByLegUidSelector(state, {
        legUid: leg?.uid,
      })?.flight;

      var flightLeg = flight?.legs?.[0]?.[0];
      if (!flight) {
        const { flight: fl } = getSecondaryLegFlightSelector(state, {
          legUid: leg?.uid,
        });
        flight = fl;
        flightLeg = _.last(flight?.legs)?.[0];
      }

      if (trfType === "inbound") {
        const segment = _.last(flightLeg?.segments);
        trp_lat = segment?.destination_airport?.lat;
        trp_lng = segment?.destination_airport?.lng;
        trp_name = segment?.destination_airport?.name;
        trp_date = segment?.arrival;
      } else {
        const segment = flightLeg?.segments?.[0];
        trp_lat = segment?.origin_airport?.lat;
        trp_lng = segment?.origin_airport?.lng;
        trp_name = segment?.origin_airport?.name;
        trp_date = segment?.departure;
      }
    } else if (trp_service_type === "FER") {
      const { ferryData } = getFerryByLegUidSelector(state, {
        legUid: leg.uid,
      });

      if (trfType === "inbound") {
        trp_lat = ferryData?.legs?.[0]?.arrival_port_geodata?.lat;
        trp_lng = ferryData?.legs?.[0]?.arrival_port_geodata?.lng;
        trp_name = ferryData?.legs?.[0]?.arrival_port_name;
        trp_date = ferryData?.legs?.[0]?.arrival_datetime;
      } else {
        trp_lat = ferryData?.legs?.[0]?.departure_port_geodata?.lat;
        trp_lng = ferryData?.legs?.[0]?.departure_port_geodata?.lng;
        trp_name = ferryData?.legs?.[0]?.departure_port_name;
        trp_date = ferryData?.legs?.[0]?.departure_datetime;
      }
    } else if (trp_service_type === "TRA") {
      const train = (state.tripPlannerTrainsReducer?.services ?? []).find(
        (tra) => tra.legUid === leg.uid
      );
      if (trfType === "inbound") {
        trp_lat = train?.destination?.geodata?.lat;
        trp_lng = train?.destination?.geodata?.lon;
        trp_name = train?.destination?.label;
        trp_date = train?.arrival;
      } else {
        trp_lat = train?.origin?.geodata?.lat;
        trp_lng = train?.origin?.geodata?.lon;
        trp_name = train?.origin?.label;
        trp_date = train?.departure;
      }
    }

    return {
      source_entity,
      serviceIsSelected,
      accommodation,
      adults,
      children,
      destination,
      trp_lat,
      trp_lng,
      trp_name,
      trp_date,
    };
  });

  const { showPricingForm } = useContext(PricingFormContext);
  const { markup, gross_margin, flat_value } = useSelector((state) =>
    getServicePricingSelector(state, {
      key: srvPriceKeyCreator({ service: transfer, service_type: "TRF" }),
    })
  );
  const { services_collapsed, toggleSelfCollapse } = useServiceCardExpansion();

  var pld = {};
  if (trfType === "inbound") {
    pld = {
      origin_lat: trp_lat,
      origin_lng: trp_lng,
      origin_name: trp_name,
      origin_country_code: destination?.country_code,
      destination_lat: accommodation?.metadata?.geodata?.lat,
      destination_lng: accommodation?.metadata?.geodata?.lng,
      destination_name: accommodation?.metadata?.name,
      destination_country_code: destination?.country_code,
      adults,
      children,
      transfer_type: "inbound",
      route_type: "airport_to_point",
      page_size: 1,
      inbound: trp_date,
      market_type: "B2B",
      source_entity,
    };
  } else {
    pld = {
      origin_lat: accommodation?.metadata?.geodata?.lat,
      origin_lng: accommodation?.metadata?.geodata?.lng,
      origin_name: accommodation?.metadata?.name,
      origin_country_code: destination?.country_code,
      destination_lat: trp_lat,
      destination_lng: trp_lng,
      destination_name: trp_name,
      destination_country_code: destination?.country_code,
      adults,
      children,
      transfer_type: "outbound",
      route_type: "point_to_airport",
      page_size: 1,
      outbound: trp_date,
      market_type: "B2B",
      source_entity,
    };
  }

  const dispatch = useDispatch();
  const saveTransferResult = useCallback(
    ({ trfType, destOrder, transfer }) =>
      dispatch(saveTransfer({ trfType, destOrder, transfer })),
    [dispatch]
  );

  const guid = generatePayloadGuid(pld);
  const { data, isLoading, isFetching } = useQuery({
    queryKey: ["transfer_overview_search", guid],
    queryFn: () => {
      // Skip searching if we don't have enough data for origin and destination
      const { origin_lat, origin_lng, destination_lat, destination_lng } = pld;
      if (
        ![origin_lat, origin_lng, destination_lat, destination_lng].every(
          (g) => !!g
        )
      ) {
        return new Promise((resolve) => resolve({ data: { results: [] } }));
      }

      // Skip searching if we don't have date data.
      if (trfType === "inbound" && !pld?.inbound) {
        return new Promise((resolve) => resolve({ data: { results: [] } }));
      } else if (trfType === "outbound" && !pld?.outbound) {
        return new Promise((resolve) => resolve({ data: { results: [] } }));
      }

      return transferSearch(pld);
    },
    onSuccess: (data) => {
      const transfer = _.sortBy(
        data?.data?.results ?? [],
        (v) => v?.price?.value ?? 0
      )[0];
      if (transfer) {
        transfer.payload = pld;
        saveTransferResult({ trfType, destOrder, transfer });
      }
    },
    refetchOnWindowFocus: false,
    enabled: !transfer || guid !== generatePayloadGuid(transfer?.payload),
    retry: false,
  });

  var loading = isFetching;
  if (!transfer) {
    loading = isLoading || isFetching;
    transfer = data?.data?.results?.[0];
  }

  const price = getTransferPriceSelector(transfer);

  return !serviceIsSelected || !trp_date ? null : (
    <div className={classes.Transfer}>
      {currentStep === 4 ? (
        <BookChecker
          uid={transfer?.booking_id}
          srv_type="TRF"
          disabled={false}
          optionable={true}
        />
      ) : (
        <div />
      )}
      <OverviewTransfer
        isLoading={loading}
        transfer={transfer}
        trfType={trfType}
        destOrder={destOrder}
        currentStep={currentStep}
        services_collapsed={services_collapsed}
        toggleSelfCollapse={toggleSelfCollapse}
      />
      {currentStep === 3 ? (
        showPricingForm ? (
          <ServicePricingForm service_type="TRF" service={transfer} />
        ) : services_collapsed ? (
          <div />
        ) : (
          <OverviewPrice
            serviceType="TRF"
            price={price}
            markup={markup}
            gross_margin={gross_margin}
            flat_value={flat_value}
          />
        )
      ) : (
        <PrebookPrice
          serviceType="TRF"
          price={price}
          currency={transfer?.price?.currency}
          service={transfer}
          destOrder={destOrder}
        />
      )}
    </div>
  );
};
Transfer.propTypes = {
  trfType: PropTypes.oneOf(["inbound", "outbound"]).isRequired,
  destOrder: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
};
