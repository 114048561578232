import {
  PAYABLE_BEGIN_FETCH_DATA,
  PAYABLE_FETCH_DATA,
  PAYABLE_END_FETCH_DATA,
  PAYABLE_INITIALIZE_LIST_FILTER_FORM,
  PAYABLE_UPDATE_LIST_FILTER_FORM,
  PAYABLE_APPLY_LIST_FILTER,
  PAYABLE_INITIALIZE_LIST_COLUMNS_FORM,
  PAYABLE_APPLY_LIST_COLUMNS,
  PAYABLE_INITIALIZE_GRAPH_VIEW_TYPE,
  PAYABLE_CHANGE_GRAPH_VIEW_TYPE,
  PAYABLE_BEGIN_FETCH_GRAPH_DATA,
  PAYABLE_END_FETCH_GRAPH_DATA,
  PAYABLE_FETCH_GRAPH_DATA,
} from "@src/actions/ManagementConsole/Accounts/Payable/types";

import {
  initialPayableFilterForm,
  initialPayableColumnsSetUp,
} from "@src/forms/ManagementConsole/Payable";

import { formProcessor, entitiesListSelector } from "@src/reducers/tools";

import update from "immutability-helper";
import _ from "lodash";

//========================================LIST VIEW==============================================
export const initialPayableList = {
  count: 0,
  next: null,
  previous: null,
  results: [],
  page: 1,
  limit: 20,
  displayLength: 100,
};

export const payableList = (state = initialPayableList, action) => {
  switch (action.type) {
    case PAYABLE_FETCH_DATA:
      return action.data;
    case PAYABLE_INITIALIZE_LIST_FILTER_FORM:
      return { ...state, page: 1 };
    default:
      return state;
  }
};

export const initialPayableListFetchStatus = "IDLE";
export const payableListFetchStatus = (
  state = initialPayableListFetchStatus,
  action
) => {
  switch (action.type) {
    case PAYABLE_BEGIN_FETCH_DATA:
      return "FETCHING";
    case PAYABLE_END_FETCH_DATA:
      return "FETCHED";
    default:
      return state;
  }
};

//========================================FILTER RELATION============================================
export const payableListFilterForm = (
  state = initialPayableFilterForm,
  action
) => {
  switch (action.type) {
    case PAYABLE_INITIALIZE_LIST_FILTER_FORM:
      return initialPayableFilterForm;
    case PAYABLE_UPDATE_LIST_FILTER_FORM:
      return formProcessor(
        state,
        action.data.type,
        action.data.name,
        action.data.value
      );
    default:
      return state;
  }
};

export const payableListFilter = (state = initialPayableFilterForm, action) => {
  switch (action.type) {
    case PAYABLE_APPLY_LIST_FILTER:
      return action.data;
    default:
      return state;
  }
};

// ========================= COLUMNS RELATION ========================
export const payableListColumns = (
  state = entitiesListSelector(initialPayableColumnsSetUp),
  action
) => {
  switch (action.type) {
    case PAYABLE_APPLY_LIST_COLUMNS:
      return entitiesListSelector(action.data);
    default:
      return state;
  }
};

export const payableListColumnsForm = (
  state = initialPayableColumnsSetUp,
  action
) => {
  switch (action.type) {
    case PAYABLE_INITIALIZE_LIST_COLUMNS_FORM:
      var newState = {};

      for (const key in state) {
        // clear form's value
        state[key] = update(state[key], { value: { $set: [] } });
        newState[key] = update(state[key], { value: { $set: [] } });
        // add value by action data
        action.data.forEach((item) => {
          if (_.find(state[key].choices, { value: item.value })) {
            newState[key] = update(newState[key], {
              value: { $push: [item.value] },
            });
          }
        });
      }

      return newState;
    default:
      return state;
  }
};

// =============================================================GRAPH==================================
export const initType = "ALL";

export const payableGraphViewType = (state = initType, action) => {
  switch (action.type) {
    case PAYABLE_INITIALIZE_GRAPH_VIEW_TYPE:
      return initType;
    case PAYABLE_CHANGE_GRAPH_VIEW_TYPE:
      return action.data;
    default:
      return state;
  }
};

export const payableGraphData = (state = {}, action) => {
  switch (action.type) {
    case PAYABLE_FETCH_GRAPH_DATA:
      return action.data;
    default:
      return state;
  }
};

export const initialPayableGraphDataStatus = "IDLE";
export const payableGraphDataStatus = (
  state = initialPayableGraphDataStatus,
  action
) => {
  switch (action.type) {
    case PAYABLE_BEGIN_FETCH_GRAPH_DATA:
      return "FETCHING";
    case PAYABLE_END_FETCH_GRAPH_DATA:
      return "FETCHED";
    default:
      return state;
  }
};
