//==========================FILTER===============================
// export const MY_OFFER__BOOKED_UPDATE_LIST_FILTER_FORM =
//   "MY_OFFER__BOOKED_UPDATE_LIST_FILTER_FORM";
// export const MY_OFFER__BOOKED_APPLY_LIST_FILTER =
//   "MY_OFFER__BOOKED_APPLY_LIST_FILTER";
// export const MY_OFFER__BOOKED_INITIALIZE_LIST_FILTER_FORM =
//   "MY_OFFER__BOOKED_INITIALIZE_LIST_FILTER_FORM";
// export const MY_OFFER__BOOKED_RESET_LIST_FILTER_FORM =
//   "MY_OFFER__BOOKED_RESET_LIST_FILTER_FORM";
//==============================LIST========================
// export const MY_OFFER__BOOKED_LIST_LOADING = "MY_OFFER__BOOKED_LIST_LOADING";
// export const MY_OFFER__BOOKED_LIST_IDLE = "MY_OFFER__BOOKED_LIST_IDLE";
// export const MY_OFFER__BOOKED_FETCH_DATA = "MY_OFFER__BOOKED_FETCH_DATA";
//========================DASHBOARD===============================
export const MY_OFFER__BOOKED_VIEW_TYPE = "MY_OFFER__BOOKED_VIEW_TYPE";
export const MY_OFFER__BOOKED_FETCH_GRAPH_DATA =
  "MY_OFFER__BOOKED_FETCH_GRAPH_DATA";
export const MY_OFFER__BOOKED_BEGIN_FETCH_GRAPH_DATA =
  " MY_OFFER__BOOKED_BEGIN_FETCH_GRAPH_DATA";
export const MY_OFFER__BOOKED_END_FETCH_GRAPH_DATA =
  "MY_OFFER__BOOKED_END_FETCH_GRAPH_DATA";
export const MY_OFFER__BOOKED_INIT_GRAPH_TYPE =
  "MY_OFFER__BOOKED_INIT_GRAPH_TYPE";
//===================================RESENT EMAIL=========================================
export const MY_OFFER__BOOKED_SHOW_RESENT_EMAIL_MODAL =
  "MY_OFFER__BOOKED_SHOW_RESENT_EMAIL_MODAL";
export const MY_OFFER__BOOKED_HIDE_RESENT_EMAIL_MODAL =
  "MY_OFFER__BOOKED_HIDE_RESENT_EMAIL_MODAL";
export const MY_OFFER__BOOKED_BEGIN_SEND_EMAIL =
  "MY_OFFER__BOOKED_BEGIN_SEND_EMAIL";
export const MY_OFFER__BOOKED_END_SEND_EMAIL =
  "MY_OFFER__BOOKED_END_SEND_EMAIL";
//===================================New Refactor=========================================
export const MY_OFFER_BOOKED_LIST_GENERIC_CHANGE =
  "MY_OFFER_BOOKED_LIST_GENERIC_CHANGE";
export const MY_OFFER_BOOKED_LIST_SAVE_RESULTS =
  "MY_OFFER_BOOKED_LIST_SAVE_RESULTS";
