import _ from "lodash";
import { modalGenericStyles } from "@src/jsssetup";
import {
  getAdhocTrpSrvByIdSelector,
  getSetupFormDataSelector,
} from "@src/selectors/Project/TripPlanner";
import { Formik } from "formik";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { createUseStyles } from "react-jss";
import { useDispatch, useSelector } from "react-redux";
import { FerryAdhocSrvFormFields } from "./products/addhoc/ferry";
import { addonSrvPriceCalculator } from "./products/addhoc/common";
import { updateTrpAdhocSrv } from "@src/actions/Project/TripPlanner/addhoc/transportation";

const editFerrySrvModalStyles = createUseStyles({
  ...modalGenericStyles,
  EditFerrySrvModal: modalGenericStyles.modal,
  card: { ...modalGenericStyles.card, minWidth: "45vw" },
});
const EditFerrySrvModal = ({ srv_id, onCancel }) => {
  const classes = editFerrySrvModalStyles();

  const { service, adults, children } = useSelector((state) => {
    const service = getAdhocTrpSrvByIdSelector(state, {
      srv_id,
      srv_type: "FER",
    });

    const { adults, children } = getSetupFormDataSelector(state);
    return { service, adults, children };
  });

  const dispatch = useDispatch();
  const onSubmit = useCallback(
    ({ service }) =>
      dispatch(updateTrpAdhocSrv({ srv_id, srv_type: "FER", service })),
    [dispatch]
  );

  return (
    <div className={classes.EditFerrySrvModal}>
      <Formik
        initialValues={{ ...service.service }}
        validate={(values) =>
          addonSrvPriceCalculator({ values, adults, childs: children })
        }
        onSubmit={(values) => {
          onSubmit({ service: values });
          onCancel();
        }}>
        {({ values, setFieldValue, submitForm }) => (
          <div className={classes.card}>
            <div className={classes.cardHeader}>
              <h5>Edit Ferry Service</h5>
            </div>
            <div className={classes.cardBody}>
              <FerryAdhocSrvFormFields
                values={values}
                origin_name={service?.service?.origin?.name_en ?? "N/A"}
                destination_name={
                  service?.service?.destination?.name_en ?? "N/A"
                }
                setFieldValue={setFieldValue}></FerryAdhocSrvFormFields>
            </div>
            <div className={classes.cardActions}>
              <button
                className="Button"
                data-ghost="true"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  onCancel();
                }}>
                Cancel
              </button>
              <button
                className="Button"
                appearance="primary"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  submitForm();
                }}>
                Apply
              </button>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};
EditFerrySrvModal.propTypes = {
  srv_id: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
};
export default EditFerrySrvModal;
