import _ from "lodash";
import {
  getActivityPriceInfo,
  retrieveActivity,
} from "@src/api/Project/activities";
import { variables } from "@src/jsssetup";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { addActivityToDay } from "@src/actions/Project/TripPlanner/activities";
import { Loader } from "rsuite";
import Slider from "react-slick/lib/slider";

import { Icon } from "@iconify/react";
import { Rating } from "../../../../OverviewAllAccommodation";
import { ActivityCxlTag, ActivityLanguages } from "../common";
import { useMutation } from "@tanstack/react-query";
import { TripPlanContext } from "@src/pages/Project/TripPlan/TripPlanner";

const carouselSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
};

const activityDetailsStyles = createUseStyles({
  ActivityDetails: (props) => ({
    position: "relative",
    width: `calc(100% - ${variables.normal_gap} * 2)`,
    top: props.loading ? "50%" : "auto",
    padding: [0, variables.normal_gap],
  }),
  content: {
    marginTop: "10rem",
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateRows: "max-content",
  },
  title: { fontSize: "x-large", fontWeight: "bold" },
  subtitle: { fontSize: "large", fontWeight: "bold" },
  features: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: variables.normal_gap,
  },
  carousel: {
    height: "5rem",
    width: "100%",
  },
  carouselImgContainer: { height: "12rem" },
  carouselImg: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },
  sectionHeader: {
    fontSize: "x-large",
    fontWeight: "bold",
  },
  sectionList: {},
  span2: { gridColumn: "span 2" },
  col2: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: variables.normal_gap,
  },
  inclusionsExclusions: {
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  listItems: {
    "& li": {
      listStyleType: "none",
      display: "grid",
      gridTemplateColumns: "max-content 1fr",
      gridGap: variables.half_gap,
      alignItems: "center",
    },
    "& svg": {
      fontSize: "x-large",
    },
  },
  bulletIcon: {
    color: variables.colors.easy.orange,
  },
  incIcon: {
    color: "green",
  },
  excIcon: {
    color: "red",
  },
  price: {
    fontSize: "x-large",
    fontWeight: "bold",
    display: "grid",
    justifyItems: "end",
    position: "sticky",
    bottom: "2rem",
    background: "white",
    borderTop: `2px solid #E6E6E6`,
    padding: variables.normal_gap,
    paddingBottom: `calc(${variables.normal_gap} / 2)`,
  },
  priceLabel: {
    fontSize: "large",
    fontWeight: "bold",
    color: variables.colors.easy.orange,
  },
  actions: {
    position: "sticky",
    bottom: "-1rem",
    padding: `calc(${variables.normal_gap} * 2)`,
    paddingTop: 0,
    background: "white",
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateColumns: "repeat(2, max-content)",
    justifyContent: "end",
  },
  [`@media ${variables.media.bigscreen}`]: {
    content: { marginTop: "17rem" },
    carouselImgContainer: { height: "20rem" },
  },
});
export const ActivityDetails = ({
  activity_uid,
  session_id,
  searchDate,
  destination_id,
  destOrder,
  onCancel,
  onHideDrawer,
}) => {
  const { setShowActivityPriceModal, setActivityPriceInfo } =
    useContext(TripPlanContext);
  const [loading, setLoading] = useState(true);
  const [activity, setActivity] = useState(null);
  const classes = activityDetailsStyles({ loading });

  useEffect(() => {
    setLoading(true);
    retrieveActivity({ activity_uid, session_id })
      .then((res) => {
        if (res) {
          setActivity({ ...res, session_id });
        } else {
          toast.warning(
            "Activity not found. We are sorry for the inconvenience. Please try again later.",
            { autoClose: 6000 }
          );
          onCancel();
        }
      })
      .finally(() => setLoading(false));
  }, []);

  const imageUrls = (activity?.images ?? []).map((img) => img.hd.url);
  const rating = Math.ceil(activity?.ratings?.average_rating ?? 0);
  const refundable = activity?.refundable ?? false;

  return (
    <div className={`${classes.ActivityDetails} ActivityDetails`}>
      {loading ? (
        <Loader center content="Please wait..." size="lg" />
      ) : (
        <React.Fragment>
          <div className={classes.carousel}>
            <Slider {...carouselSettings}>
              {imageUrls.map((url, idx) => (
                <div key={idx} className={classes.carouselImgContainer}>
                  <img
                    src={url}
                    alt={`Image ${idx}`}
                    className={classes.carouselImg}
                  />
                </div>
              ))}
            </Slider>
          </div>
          <div className={classes.content}>
            <span className={classes.title}>{activity.title}</span>
            <Rating rating={rating} />
            <span className={classes.subtitle}>
              {activity.description || "N/A"}
            </span>
            <p className={classes.description}>{activity.about || "N/A"}</p>
            <div className={classes.features}>
              <span className={`${classes.sectionHeader} ${classes.span2}`}>
                Features
              </span>
              <ActivityCxlTag refundable={refundable} />
              <ActivityLanguages
                languages={_.get(activity, "languages", []).map(
                  (lng) => lng.name
                )}
              />
              <ActivityLanguages
                label="Features"
                languages={_.get(activity, "features", []).map(
                  (lng) => lng.label
                )}
              />
            </div>
            <div className={classes.col2}>
              <div>
                <span className={`${classes.sectionHeader} ${classes.span2}`}>
                  Additional Info
                </span>
                <ul className={classes.listItems}>
                  <li>
                    <Icon
                      icon="ph:dot-outline-fill"
                      className={classes.bulletIcon}
                    />
                    <span>
                      <strong>Meeting Point Info: </strong>
                      {_.get(activity, "meeting_point_info", "N/A")}
                    </span>
                  </li>
                  <li>
                    <Icon
                      icon="ph:dot-outline-fill"
                      className={classes.bulletIcon}
                    />
                    <span>
                      <strong>Provided By: </strong>
                      {_.startCase(_.get(activity, "provider", "N/A"))}
                    </span>
                  </li>
                </ul>
              </div>
              <div>
                <span className={`${classes.sectionHeader} ${classes.span2}`}>
                  Do this because
                </span>
                <ul className={classes.listItems}>
                  {_.get(activity, "highlights", []).map((hl, idx) => (
                    <li key={idx}>
                      <Icon
                        icon="ph:dot-outline-fill"
                        className={classes.bulletIcon}
                      />
                      {hl}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className={classes.safetyInfo}>
              <span className={`${classes.sectionHeader} ${classes.span2}`}>
                Safety Information
              </span>
              <p
                dangerouslySetInnerHTML={{
                  __html: _.get(activity, "safety_info", "N/A"),
                }}
              />
            </div>
            <div className={classes.inclusionsExclusions}>
              <span className={classes.sectionHeader}>Included</span>
              <span className={classes.sectionHeader}>Excluded</span>
              <ul className={classes.listItems}>
                {_.get(activity, "inclusions", []).map((inc, idx) => (
                  <li key={idx}>
                    <Icon icon="mdi:tick" className={classes.incIcon} />
                    {inc}
                  </li>
                ))}
              </ul>
              <ul className={classes.listItems}>
                {_.get(activity, "not_included", []).map((exc, idx) => (
                  <li key={idx}>
                    <Icon
                      icon="humbleicons:times"
                      className={classes.excIcon}
                    />
                    {exc}
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <span className={`${classes.sectionHeader} ${classes.span2}`}>
                What to remember
              </span>
              <p
                dangerouslySetInnerHTML={{ __html: _.get(activity, "info") }}
              />
            </div>
            <div className={classes.cxlPolicies}></div>
            <div className={classes.price}>
              <span className={classes.priceLabel}>From</span>
              <span className={classes.priceValue}>
                {_.get(activity, "price.currency", "N/A")}
                {_.get(activity, "price.value", "N/A")}
              </span>
            </div>
            <div className="ActivityDetails__actions">
              <button className="Button" data-ghost="true" onClick={onCancel}>
                Cancel
              </button>
              <button
                className="Button"
                onClick={() => {
                  setActivityPriceInfo({
                    activity,
                    activity_uid,
                    session_id,
                    date: searchDate,
                    destination_id,
                    destOrder,
                  });
                  setShowActivityPriceModal(true);
                }}>
                View Price Details
              </button>
              {/* <button */}
              {/*   className="Button" */}
              {/*   onClick={() => */}
              {/*     onAddToTrip({ */}
              {/*       activity, */}
              {/*       date: searchDate, */}
              {/*       destination_id, */}
              {/*       destOrder, */}
              {/*     }) */}
              {/*   }> */}
              {/*   Add to Trip */}
              {/* </button> */}
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
ActivityDetails.propTypes = {
  activity_uid: PropTypes.string.isRequired,
  session_id: PropTypes.string.isRequired,
  searchDate: PropTypes.string.isRequired,
  destination_id: PropTypes.string.isRequired,
  destOrder: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onHideDrawer: PropTypes.func.isRequired,
};
