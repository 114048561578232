import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

const pageNoAuth =
  "https://sisistaticassets.s3.ap-southeast-1.amazonaws.com/platform_graphics/imgs/error/no_auth.png";

import { Button } from "rsuite";
import { useHistory } from "react-router-dom";
import { createUseStyles } from "react-jss";

const noAuthStyles = createUseStyles({ main: {} });
const NoAuth = () => {
  const history = useHistory();
  const classes = noAuthStyles();
  return (
    <div className="no-auth-container">
      <div className="si-form-no-auth">
        <div>
          <img className="si-form-no-auth-img" src={pageNoAuth} />
        </div>
        <div>
          <Button
            color="green"
            size="sm"
            onClick={() => history.push(`/admin/company/profile`)}>
            Go To Home Page
          </Button>
        </div>
      </div>
    </div>
  );
};
NoAuth.propTypes = {
  history: PropTypes.object,
};

export default withRouter(NoAuth);
