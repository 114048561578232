import {
  TRIPPLANNER_DBD_ADD_ACTIVITY,
  TRIPPLANNER_DBD_REMOVE_ACTIVITY,
  TRIPPLANNER_DBD_RESET_ACTIVITY_PREBOOK,
  TRIPPLANNER_DBD_UPDATE_ACTIVITY,
} from "@src/reducers/Project/TripPlanner";

export const addActivityToDay = ({ activity, date }) => {
  return { type: TRIPPLANNER_DBD_ADD_ACTIVITY, activity, date };
};

export const removeActivityFromDay = ({ activity_uid, date }) => {
  return { type: TRIPPLANNER_DBD_REMOVE_ACTIVITY, activity_uid, date };
};

export const updateDayActivity = ({ uid, activity, date }) => {
  return { type: TRIPPLANNER_DBD_UPDATE_ACTIVITY, uid, activity, date };
};

export const resetActivityPrebook = ({ uid, date }) => {
  return { type: TRIPPLANNER_DBD_RESET_ACTIVITY_PREBOOK, uid, date };
};
