// ============================= PERIODS =============================
export const TRIPPLANNER_MKT_DIST_PERIOD_ADD =
  "TRIPPLANNER_MKT_DIST_PERIOD_ADD";
export const TRIPPLANNER_MKT_DIST_PERIOD_REMOVE =
  "TRIPPLANNER_MKT_DIST_PERIOD_REMOVE";
export const TRIPPLANNER_MKT_DIST_PERIOD_CHANGE =
  "TRIPPLANNER_MKT_DIST_PERIOD_CHANGE";
export const TRIPPLANNER_MKT_DIST_PERIOD_COPY =
  "TRIPPLANNER_MKT_DIST_PERIOD_COPY";
// ============================ STOP SALES ===========================
export const TRIPPLANNER_MKT_STOP_SALES_ADD = "TRIPPLANNER_MKT_STOP_SALES_ADD";
export const TRIPPLANNER_MKT_STOP_SALES_REMOVE =
  "TRIPPLANNER_MKT_STOP_SALES_REMOVE";
export const TRIPPLANNER_MKT_STOP_SALES_CHANGE =
  "TRIPPLANNER_MKT_STOP_SALES_CHANGE";
// ====================== INCLUSIONS EXCLUSIONS ======================
export const TRIPPLANNER_MKT_INCEXC_ADD = "TRIPPLANNER_MKT_INCEXC_ADD";
export const TRIPPLANNER_MKT_INCEXC_REMOVE = "TRIPPLANNER_MKT_INCEXC_REMOVE";
export const TRIPPLANNER_MKT_INCEXC_CHANGE = "TRIPPLANNER_MKT_INCEXC_CHANGE";
// =========================== CXL POLICIES ==========================
export const TRIPPLANNER_MKT_CXL_CHANGE = "TRIPPLANNER_MKT_CXL_CHANGE";
// ======================== DISTRIBUTION SETUP =======================
export const TRIPPLANNER_MKT_DIST_SETUP_CHANGE =
  "TRIPPLANNER_MKT_DIST_SETUP_CHANGE";
// =========================== PKG CONTENT ===========================
export const TRIPPLANNER_MKT_CONTENT_INIT = "TRIPPLANNER_MKT_CONTENT_INIT";
export const TRIPPLANNER_MKT_CONTENT_MODAL_SHOW =
  "TRIPPLANNER_MKT_CONTENT_MODAL_SHOW";
export const TRIPPLANNER_MKT_CONTENT_MODAL_HIDE =
  "TRIPPLANNER_MKT_CONTENT_MODAL_HIDE";
export const TRIPPLANNER_MKT_CONTENT_MODAL_SIMPLE_HIDE =
  "TRIPPLANNER_MKT_CONTENT_MODAL_SIMPLE_HIDE";
export const TRIPPLANNER_MKT_CONTENT_MODAL_LOADING =
  "TRIPPLANNER_MKT_CONTENT_MODAL_LOADING";
export const TRIPPLANNER_MKT_CONTENT_MODAL_IDLE =
  "TRIPPLANNER_MKT_CONTENT_MODAL_IDLE";
export const TRIPPLANNER_MKT_CONTENT_MODAL_MODE_TOGGLE =
  "TRIPPLANNER_MKT_CONTENT_MODAL_MODE_TOGGLE";
export const TRIPPLANNER_MKT_CONTENT_CHANGE_COLOR_PALETTE =
  "TRIPPLANNER_MKT_CONTENT_CHANGE_COLOR_PALETTE";
export const TRIPPLANNER_MKT_CONTENT_MODAL_CHANGE_TEMPLATE =
  "TRIPPLANNER_MKT_CONTENT_MODAL_CHANGE_TEMPLATE";
export const TRIPPLANNER_MKT_CONTENT_MODAL_CHANGE_DATA_CONTENT =
  "TRIPPLANNER_MKT_CONTENT_MODAL_CHANGE_DATA_CONTENT";
export const TRIPPLANNER_DBD_CONTENT_CHANGE = "TRIPPLANNER_DBD_CONTENT_CHANGE";
