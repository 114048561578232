import {
  B2CGUESTS_LIST_GENERIC_CHANGE,
  B2CGUESTS_LIST_SAVE_RESULTS,
} from "@src/actions/Operation/Guests/B2C/types";
import { commonList } from "@src/reducers/common";

const B2CGuestsListReducerInitial = {
  ...commonList,
  filters: {
    ext_user_id__icontains: "",
    first_name: "",
    last_name: "",
    email: "",
    stripe_id: "",
    free_period: null,
  },
};
export const B2CGuestsListReducer = (
  state = B2CGuestsListReducerInitial,
  action
) => {
  switch (action.type) {
    case B2CGUESTS_LIST_SAVE_RESULTS: {
      const { data } = action;
      return { ...state, ...data };
    }
    case B2CGUESTS_LIST_GENERIC_CHANGE: {
      const { key, value } = action;
      if (key === "filters") {
        return { ...state, [key]: value, page: 1 };
      } else {
        return { ...state, [key]: value };
      }
    }
    default:
      return state;
  }
};
