import { HOTEL_CONTRACT_LIST_GENERIC_CHANGE } from "@src/actions/MyLibrary/Hotels/types";
import { commonList } from "../../common";

const HotelContractsListReducerInitial = {
  ...commonList,
  filters: {
    ext_contract_id: "",
    ext_accommodation_name: "",
    destination: "",
    country: "",
    currency: "",
    status: "",
    booking_from: "",
    booking_to: "",
    valid_from: "",
    valid_to: "",
  },
};
export const HotelContractsListReducer = (
  state = HotelContractsListReducerInitial,
  action
) => {
  switch (action.type) {
    case HOTEL_CONTRACT_LIST_GENERIC_CHANGE: {
      const { key, value } = action;
      if (key === "filters") {
        return { ...state, [key]: value, page: 1 };
      } else {
        return { ...state, [key]: value };
      }
    }
    default:
      return state;
  }
};
