import { SISI_API_URL } from "@src/api";
import service, { getAuthHeaders } from "@src/api/request";
import axios from "axios";

export function getFlightsList(para) {
  return service({
    url: "bid/flightreservation/",
    method: "get",
    params: para,
  });
}

export function getFlightsReservationOptions(para) {
  return service({
    url: "bid/flightreservation/",
    method: "options",
    params: para,
  });
}

export function fetchFlightReservationInfo(reference) {
  return service({
    url: `bid/flightreservation/${reference}`,
    method: "get",
  });
}

export function submitFlightReservationChange(reference, payload) {
  return service({
    url: `bid/flightreservation/${reference}/`,
    method: "patch",
    data: payload,
  });
}

export async function getFlightStats(params) {
  const auth_headers = getAuthHeaders();
  return await axios.get(`${SISI_API_URL}/reservations/flights/stats/`, {
    params,
    headers: { ...auth_headers },
  });
}

//=========================FLIGHT DASHBOARDS================================
export const getFlightReservationsGraphData = (payload) => {
  return service({
    url: "/bid/flight-stats/",
    method: "get",
    params: payload,
  });
};
