import { StyleSheet, Text, View, Font } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import React from "react";
import Divider from "./Divider";
import { easyBasicColor } from "../PreviewPDF";
import _ from "lodash";
import NotoSansRegular from "@src/style/fonts/NotoSans-Regular.ttf";
import NotoSansBold from "@src/style/fonts/NotoSans-Bold.ttf";

Font.register({
  family: "Noto Sans",
  fonts: [
    { src: NotoSansRegular, fontWeight: "normal" },
    { src: NotoSansBold, fontWeight: "bold" },
  ],
});

const footerStyles = StyleSheet.create({
  Footer: { fontSize: 10 },
  description: { fontFamily: "Noto Sans", fontWeight: "bold" },
  content: { width: "100%" },
});
const Footer = ({ data, entity_colors }) => {
  return (
    <View style={footerStyles.Footer}>
      <Divider color={_.get(entity_colors, "text.colored", easyBasicColor)} />
      <View style={footerStyles.content}>
        <Text style={footerStyles.description}>{data}</Text>;
      </View>
    </View>
  );
};
Footer.defaultProps = { entity_colors: {}, data: "" };
Footer.propTypes = {
  data: PropTypes.string.isRequired,
  entity_colors: PropTypes.object,
};
export default Footer;
