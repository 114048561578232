import { IconButton, Icon, Checkbox, RangeSlider } from "rsuite";

import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";

import { PricingFilter } from "../FiltersCommon";

import {
  getFlAvgPriceSelector,
  getFlCheapestPriceSelector,
  getFlFastestPriceSelector,
  getFlBestPriceSelector,
} from "@src/selectors/Project/TripPlanner";

import { convertAmountSelector } from "@src/selectors/Financial";

import _ from "lodash";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { variables } from "@src/jsssetup";
import { Form, Formik } from "formik";
import { NormalSelectField } from "@src/components/forms";
import { useDebounce } from "@src/pages/hooks";

const DepArrRanges = ({ resetVersion, deparrKey, onSetValue }) => {
  const [value, setValue] = useState([0, 24]);

  const debValue = useDebounce(value, 1000);
  useEffect(() => {
    onSetValue(debValue);
  }, [debValue]);

  useEffect(() => {
    setValue([0, 24]);
  }, [resetVersion]);

  const label = deparrKey == "departure" ? "Dep" : "Arr";

  return (
    <div>
      <strong>{`${label} ${value[0]}:00h - ${value[1]}:00h`}</strong>
      <RangeSlider
        defaultValue={[0, 24]}
        min={0}
        max={24}
        value={value}
        onChange={function (value) {
          setValue(value);
        }}
      />
    </div>
  );
};
DepArrRanges.propTypes = {
  resetVersion: PropTypes.number.isRequired,
  deparrKey: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
  onSetValue: PropTypes.func.isRequired,
};

const viewOptionStyles = {
  display: "grid",
  gridGap: variables.half_gap,
  gridTemplateColumns: "repeat(2, max-content)",
  fontWeight: "bold",
  alignItems: "center",
};

const input10 = {
  "width": "10rem",
  "& select": {
    width: "10rem",
  },
};

const overviewFlightFiltersStyles = createUseStyles({
  OverviewFlightFilters: {
    display: "grid",
    gridGap: variables.normal_gap,
    padding: [variables.normal_gap, `calc(${variables.normal_gap} * 4)`],
    background: variables.colors.background.grey,
  },
  form: {
    display: "grid",
    gridGap: variables.half_gap,
  },
  formTopRow: {
    display: "grid",
    gridGap: variables.half_gap,
    gridTemplateColumns: "repeat(2, 1fr) max-content",
  },
  formSecondRow: {
    display: "grid",
    gridGap: variables.half_gap,
    gridTemplateColumns: "repeat(4, max-content) 1fr",
  },
  formThirdRow: {
    display: "grid",
    gridGap: variables.half_gap,
    gridTemplateColumns: "1fr 1fr",
  },
  formSliders: ({ roundtrip }) => ({
    display: "grid",
    gridGap: `calc(${variables.normal_gap} * 2)`,
    gridTemplateColumns: roundtrip ? "repeat(3, 1fr)" : "repeat(2, 1fr)",
  }),
  viewOptions: {
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateColumns: "repeat(5, max-content)",
    alignItems: "center",
    justifyContent: "center",
  },
  bestOption: {
    ...viewOptionStyles,
    color: variables.colors.green,
    borderRight: `2px solid grey`,
    paddingRight: variables.normal_gap,
  },
  viewOption: { ...viewOptionStyles },
  checkViewOption: {
    ...viewOptionStyles,
    gridTemplateColumns: "repeat(3, max-content)",
  },
  inputGroup: {
    display: "grid",
    gridGap: variables.half_gap,
  },
  slidersContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: `calc(${variables.normal_gap} * 2)`,
  },
});
const OverviewFlightFilters = ({
  flights,
  initialFilters,
  filteredFlights,
  onSetFilters,
}) => {
  const { avgPrice, cheapestPrice, fastestPrice, bestPrice } = useSelector(
    (state) => {
      const avgPrice = getFlAvgPriceSelector(state, {
        amountList: filteredFlights.map((f) => f.price.total_price),
      });

      const cheapestPrice = getFlCheapestPriceSelector(state, {
        amountList: filteredFlights.map((f) => f.price.total_price),
      });

      var fastestPrice = getFlFastestPriceSelector(state, { filteredFlights });
      fastestPrice = convertAmountSelector(state, { amount: fastestPrice });

      var bestPrice = getFlBestPriceSelector(state, { filteredFlights });
      bestPrice = convertAmountSelector(state, { amount: bestPrice });

      return { avgPrice, cheapestPrice, fastestPrice, bestPrice };
    }
  );
  const roundtrip = flights.some((f) => f.legs.length > 1);
  const classes = overviewFlightFiltersStyles({ roundtrip });
  const [resetVersion, setResetVersion] = useState(0);

  if (!flights.length) return null;

  const departureAirportsData = _.sortBy(
    Object.entries(
      _.countBy(
        _.flattenDeep(
          flights.map((fl) =>
            fl.legs[0].map((opt) => {
              const seg = opt.segments[0];
              return `${seg.origin_airport.iata}___${seg.origin_airport.name}`;
            })
          )
        )
      )
    ).map(([code, count]) => [
      code.split("___")[0],
      `${_.reverse(code.split("___")).join(", ")} (${count})`,
    ]),
    (v) => v[1]
  );

  const arrivalAirportsData = _.sortBy(
    Object.entries(
      _.countBy(
        _.flattenDeep(
          flights.map((fl) =>
            fl.legs[0].map((opt) => {
              const seg = _.last(opt.segments);
              return `${seg.destination_airport.iata}___${seg.destination_airport.name}`;
            })
          )
        )
      )
    ).map(([code, count]) => [
      code.split("___")[0],
      `${_.reverse(code.split("___")).join(", ")} (${count})`,
    ]),
    (v) => v[1]
  );

  const airlineData = _.sortBy(
    Object.entries(
      _.countBy(
        _.flattenDeep(
          flights.map((f) =>
            f.legs.map((l) =>
              l.map((fl) =>
                fl.segments
                  .filter(
                    (s) =>
                      s?.operating_airline?.iata && s?.operating_airline?.name
                  )
                  .map(
                    (s) =>
                      `${s.operating_airline.iata}___${s.operating_airline.name}`
                  )
              )
            )
          )
        )
      )
    ).map(([code, count]) => [
      code.split("___")[0],
      `${_.reverse(code.split("___")).join(", ")} (${count})`,
    ]),
    (v) => v[1]
  );

  const flightClassData = Object.entries(
    _.countBy(
      _.flatten(
        flights.map((f) => [
          ...new Set([
            ..._.flatten(
              f.legs[0].map((o) =>
                o.segments.map(
                  (s) => `${s.cabin_class}___${s.cabin_class_display}`
                )
              )
            ),
            ..._.flatten(
              _.last(f.legs).map((o) =>
                o.segments.map(
                  (s) => `${s.cabin_class}___${s.cabin_class_display}`
                )
              )
            ),
          ]),
        ])
      )
    )
  ).map(([codedclass, count]) => {
    const [code, display] = codedclass.split("___");
    return [code, `${display} ${code} (${count})`];
  });

  const stopsData = Object.entries(
    _.countBy(
      _.flatten(
        flights.map((f) => {
          const stopTypes = [
            ...new Set([
              ...f.legs[0].map((o) => {
                const len = o.segments.length;
                return `${len === 1 ? "direct" : "multi"}___${
                  len === 1 ? "Direct" : "Multi Stops"
                }`;
              }),
              ..._.last(f.legs).map((o) => {
                const len = o.segments.length;
                return `${len === 1 ? "direct" : "multi"}___${
                  len === 1 ? "Direct" : "Multi Stops"
                }`;
              }),
            ]),
          ];
          return stopTypes.length === 1 ? stopTypes : [];
        })
      )
    )
  ).map(([code, count]) => [
    code.split("___")[0],
    `${code.split("___")[1]} (${count})`,
  ]);

  const sourceData = Object.entries(
    _.countBy(
      flights.map((f) =>
        f.source == "WebSource" ? "LCC___Low Cost" : "GDS___GDS"
      )
    )
  ).map(([code, count]) => [
    code.split("___")[0],
    `${code.split("___")[1]} (${count})`,
  ]);

  const gdsMapping = {
    "F1": "Farelogix",
    "1S": "Sabre",
    "1U": "Unififi",
    "1A": "Amadeus",
    "+W": "Low Cost Feed",
    "AV": "Aviate",
    "UF": "Unififi",
  };

  var channelData = _.countBy(
    flights.map(
      (fl) => `${fl.crs_info.terminal_pcc}___${gdsMapping[fl.crs_info.gds]}`
    )
  );
  channelData = Object.entries(channelData).map(([code, count]) => [
    code.split("___")[0],
    `${code.split("___").join(", ")} (${count})`,
  ]);

  const baggageData = Object.entries(
    _.countBy(
      flights.map((flight) => [
        ...new Set([
          ..._.flatten(
            flight.legs[0].map((option) =>
              option.segments
                .filter((seg) => _.get(seg, "baggage_allowance.verbose"))
                .map((seg) => _.get(seg, "baggage_allowance.verbose"))
            )
          ),
          ..._.flatten(
            _.last(flight.legs).map((option) =>
              option.segments
                .filter((seg) => _.get(seg, "baggage_allowance.verbose"))
                .map((seg) => _.get(seg, "baggage_allowance.verbose"))
            )
          ),
        ]),
      ])
    )
  )
    .filter(([code, count]) => code)
    .map(([code, count]) => [code, `${code} (${count})`]);

  var fareTypeData = _.countBy(flights.map((flight) => flight.fare_type));
  fareTypeData = Object.entries(fareTypeData).map(([code, count]) => [
    code,
    `${code} (${count})`,
  ]);

  var brandData = _.countBy(
    _.flattenDeep(
      flights.map((flight) => [
        ...flight.legs[0].map((option) =>
          option.segments
            .filter((segment) => segment.brand_name)
            .map((segment) => `${segment.brand_name}___${segment.brand_code}`)
        ),
        ..._.last(flight.legs).map((option) =>
          option.segments
            .filter((segment) => segment.brand_name)
            .map((segment) => `${segment.brand_name}___${segment.brand_code}`)
            .filter((brand) => brand)
        ),
      ])
    )
  );
  brandData = _.sortBy(
    Object.entries(brandData).map(([code, count]) => [
      code.split("___")[1],
      `${code.split("___").join(", ")} (${count})`,
    ]),
    (v) => v[1]
  );

  const min_price = Number(
    Math.min(...flights.map((fl) => fl.price.total_price)) - 1
  );
  const max_price = Number(
    Math.max(...flights.map((fl) => fl.price.total_price)) + 1
  );

  return (
    <div className={classes.OverviewFlightFilters}>
      <Formik
        initialValues={{ ...initialFilters, min_price, max_price }}
        validate={(values) => onSetFilters(_.cloneDeep(values))}
        onReset={(values) => {
          onSetFilters(_.cloneDeep(values));
          setResetVersion((p) => p + 1);
        }}>
        {({ values, resetForm, setFieldValue }) => (
          <Form className={classes.form}>
            <div className={classes.formTopRow}>
              <NormalSelectField
                label="Departure Airports"
                name="departure_airports"
                options={[[[], "------"], ...departureAirportsData]}
                customStyles={{ input: { width: "13rem" } }}
              />
              <NormalSelectField
                label="Arrival Airports"
                name="arrival_airports"
                options={[[[], "------"], ...arrivalAirportsData]}
                customStyles={{ input: { maxWidth: "13rem" } }}
              />
              <NormalSelectField
                label="Flight Class"
                name="flight_class"
                options={[[[], "------"], ...flightClassData]}
              />
            </div>
            <div className={classes.formSecondRow}>
              <NormalSelectField
                label="Stops"
                name="stops"
                options={[[[], "------"], ...stopsData]}
              />
              <NormalSelectField
                label="Baggage"
                name="baggage_allowance"
                options={[[[], "------"], ...baggageData]}
              />
              <NormalSelectField
                label="Fare Type"
                name="fare_type"
                options={[[[], "------"], ...fareTypeData]}
              />
              <NormalSelectField
                label="Fare Brand"
                name="brand"
                options={[[[], "------"], ...brandData]}
              />
              <NormalSelectField
                label="Airlines"
                name="airlines"
                options={[[[], "------"], ...airlineData]}
              />
            </div>
            <div className={classes.formThirdRow}>
              <NormalSelectField
                label="Flight Source"
                name="flight_source"
                options={[[[], "------"], ...sourceData]}
              />
              <NormalSelectField
                label="Channel"
                name="channel"
                options={[[[], "------"], ...channelData]}
              />
            </div>
            <div className={classes.formSliders}>
              <div className={classes.inputGroup}>
                <strong>Outbound</strong>
                <div className={classes.slidersContainer}>
                  <DepArrRanges
                    resetVersion={resetVersion}
                    deparrKey="departure"
                    onSetValue={async (v) => {
                      await setFieldValue("outbound_min_departure", v[0]);
                      await setFieldValue("outbound_max_departure", v[1]);
                    }}
                  />
                  <DepArrRanges
                    resetVersion={resetVersion}
                    deparrKey="arrival"
                    onSetValue={(v) => {
                      setFieldValue("outbound_min_arrival", v[0]);
                      setFieldValue("outbound_max_arrival", v[1]);
                    }}
                  />
                </div>
              </div>
              {roundtrip ? (
                <div className={classes.inputGroup}>
                  <strong>Inbound</strong>
                  <div className={classes.slidersContainer}>
                    <DepArrRanges
                      resetVersion={resetVersion}
                      deparrKey="departure"
                      onSetValue={(v) => {
                        setFieldValue("inbound_min_departure", v[0]);
                        setFieldValue("inbound_max_departure", v[1]);
                      }}
                    />
                    <DepArrRanges
                      resetVersion={resetVersion}
                      deparrKey="arrival"
                      onSetValue={(v) => {
                        setFieldValue("inbound_min_arrival", v[0]);
                        setFieldValue("inbound_max_arrival", v[1]);
                      }}
                    />
                  </div>
                </div>
              ) : null}
              <div className={classes.inputGroup}>
                <strong>Prices</strong>
                <PricingFilter
                  minPrice={min_price}
                  maxPrice={max_price}
                  fMinPrice={values.min_price}
                  fMaxPrice={values.max_price}
                />
              </div>
            </div>
            <div className={classes.viewOptions}>
              <div className={classes.bestOption}>
                <span>Best</span>
                <span>
                  {bestPrice.currSymbol}
                  {bestPrice.convertedAmount}
                </span>
              </div>
              <div className={classes.viewOption}>
                <strong>AVG Price</strong>
                <strong>
                  {avgPrice.currSymbol}
                  {avgPrice.price}
                </strong>
              </div>
              <div className={classes.checkViewOption}>
                <Checkbox
                  checked={values.ordering == "cheapest"}
                  onChange={(value, checked) =>
                    setFieldValue("ordering", "cheapest")
                  }
                />
                <strong>Cheapest</strong>
                <strong>
                  {cheapestPrice.currSymbol}
                  {cheapestPrice.price}
                </strong>
              </div>
              <div className={classes.checkViewOption}>
                <Checkbox
                  checked={values.ordering == "fastest"}
                  onChange={(value, checked) =>
                    setFieldValue("ordering", checked ? "fastest" : "cheapest")
                  }
                />
                <strong>Fastest</strong>
                <strong>
                  {fastestPrice.currSymbol}
                  {fastestPrice.convertedAmount}
                </strong>
              </div>
              <IconButton
                color="blue"
                size="sm"
                icon={<Icon icon="reload" />}
                placement="left"
                onClick={() => resetForm()}>
                Reset
              </IconButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
OverviewFlightFilters.propTypes = {
  flights: PropTypes.array.isRequired,
  filteredFlights: PropTypes.array.isRequired,
  initialFilters: PropTypes.object.isRequired,
  onSetFilters: PropTypes.func.isRequired,
};

export default OverviewFlightFilters;
