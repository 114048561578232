import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { FerryData } from "../../ferry";
import { Loader, Tag } from "rsuite";

const STATUS_MAPPING = {
  CO: "Confirmed",
  PE: "Pending",
  CA: "Cancelled",
  FA: "Failed",
};

export const APIFerryReservations = ({ apiFerryReservations = [] }) => {
  const ferryServices = useSelector(
    (state) => state.tripPlannerFerriesReducer?.services ?? []
  );

  return (
    apiFerryReservations.length > 0 && (
      <div className="BookingModal__section APIFerryReservations">
        <h5 className="BookingModal__section__header">
          API Ferry Reservations
        </h5>
        {ferryServices.map((f, idx) => {
          var reservation = apiFerryReservations.find(
            (r) => r.search_uid === f.prebookData.booking_token
          );
          reservation = apiFerryReservations[0];
          return (
            <React.Fragment>
              <div className="BookingModal__section__service-title">
                <h6>
                  Ferry Service {idx + 1}. Reservation Reference:{" "}
                  {reservation?.reference}.
                </h6>
                <span className="AccommodationReservation__title__actions">
                  Status:
                  {reservation?.status == "PE" ? (
                    <Loader className="reservation-loader" />
                  ) : (
                    <Tag color={reservation.status !== "CO" ? "red" : "green"}>
                      <strong>
                        {STATUS_MAPPING?.[reservation?.status] ?? "N/A"}
                      </strong>
                    </Tag>
                  )}
                </span>
              </div>
              <FerryData key={idx} ferryData={f} services_collapsed={false} />
            </React.Fragment>
          );
        })}
      </div>
    )
  );
};
APIFerryReservations.defaultProps = {
  apiFerryReservations: [],
};
APIFerryReservations.propTypes = {
  apiFerryReservations: PropTypes.array.isRequired,
};
