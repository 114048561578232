import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { WhisperHelper } from "@src/components/common/ui_helpers";
import { createUseStyles } from "react-jss";
import { AddOn } from "../../Controllers/DayByDay";
import { daySrvContainerStyles } from "../Day";
import OverviewPrice from "../OverviewPrice";
import PrebookPrice from "../PrebookPrice";
import { useSelector } from "react-redux";
import { isServiceFilterSelectedSelector } from "@src/selectors/Project/TripPlanner/service_filters";
import { variables } from "@src/jsssetup";
import { useServiceCardExpansion } from "@src/pages/hooks";
import { CarRentalData } from "../carRental";

const addonsStyles = createUseStyles({
  Addon: (props) => ({
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    gridTemplateColumns:
      props.currentStep === 3 ? "2rem 1fr 6rem" : "2rem 1fr 5rem",
    alignItems: "center",
  }),
});
export const Addons = ({ currentStep, addons, addonsStatus }) => {
  const classes = addonsStyles({ currentStep });

  const serviceIsSelected = useSelector((state) =>
    isServiceFilterSelectedSelector(state, { service_type: "MI" })
  );

  const { services_collapsed, toggleSelfCollapse } = useServiceCardExpansion();
  const carRentals = {
    results: [
      {
        vehicle: {
          door_count: 2,
          model_year: null,
          name: "Renault Twingo",
          brand_code: "MBMR",
          code: "Renault Twingo",
          image:
            "https://vehicles.carhire-solutions.com/Budget/small/t_MBMR_FR.jpg",
          transmission_type: "Manual",
          fuel_type: "Unspecified",
          passenger_quantity: 4,
          baggage_quantity: 2,
          has_air_condition: true,
        },
        provider: {
          name: "Budget",
          code: "ZD",
        },
        price: {
          currency: "EUR",
          amount: "145.80",
        },
        pick_up: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "Bd Beauvais Apt",
          locationCode: null,
        },
        drop_off: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "Bd Beauvais Apt",
          locationCode: null,
        },
        inclusions: [
          {
            coverage_type: "Airport Service Charge",
            code: "407",
            description: "Facility charge at commercial airports.",
            currency: "EUR",
            amount: 37.5,
          },
          {
            coverage_type: "Vehicle Licence Fee / Road Fund Licence",
            code: "402",
            description:
              "Licensing and other vehicle-related taxes charged by local governments.",
            currency: "EUR",
            amount: 15.4,
          },
          {
            coverage_type: "Kilometer inclusive: 1250 km (0.30 EUR/km)",
            code: "416",
            description: "per km: .30 EUR",
            currency: "EUR",
            amount: 0.3,
          },
          {
            coverage_type: "Other taxes and service charges",
            code: "418",
            description: "per rental: 24.30 EUR",
            currency: "EUR",
            amount: 24.3,
          },
          {
            coverage_type: "Collision damage waiver",
            code: "CDW",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Theft protection",
            code: "TP",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Fuel Information",
            code: "F2F",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "VAT",
            code: "TAX",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Cancellation fee",
            code: "CF",
            description:
              "Cancellation is free of charge until 08 Nov 2023 13:30. A cancellation fee of 100 percent will be charged from 08 Nov 2023 13:30 until 10 Nov 2023 13:30. A no show fee of 100 percent will be charged.",
            currency: "EUR",
            amount: 0,
          },
        ],
        booking_id: "-YW7z95zfk2O5epudAHSpA-66",
        api_uid: "66357eaf-1761-48e5-ab58-0c69f7a95c9a",
        supplier: "carnect",
        session_id: "610b57ee-77b5-4245-9bcc-1987cb90353a",
        is_own: true,
        is_parent_own: true,
      },
      {
        vehicle: {
          door_count: 2,
          model_year: null,
          name: "A FIAT 500",
          brand_code: "MCMR",
          code: "A FIAT 500",
          image:
            "https://vehicles.carhire-solutions.com/Thrifty Europe/small/t_MCMR_FR.jpg",
          transmission_type: "Manual",
          fuel_type: "Unspecified",
          passenger_quantity: 4,
          baggage_quantity: 2,
          has_air_condition: true,
        },
        provider: {
          name: "Thrifty Europe",
          code: "TO",
        },
        price: {
          currency: "EUR",
          amount: "158.40",
        },
        pick_up: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "BEAUVAIS AIRPORT",
          locationCode: null,
        },
        drop_off: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "BEAUVAIS AIRPORT",
          locationCode: null,
        },
        inclusions: [
          {
            coverage_type: "Airport Service Charge",
            code: "407",
            description: "Facility charge at commercial airports.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Vehicle Licence Fee / Road Fund Licence",
            code: "402",
            description:
              "Licensing and other vehicle-related taxes charged by local governments.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Unlimited Mileage",
            code: "405",
            description: "No further costs apply for extra miles driven",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Stay Safe Initiative",
            code: "55",
            description:
              "As the world faces an unprecedented health crisis, our car rental suppliers have put special measures in place to provide you with a safe and flexible travel experience. The utmost attention is being paid to cleaning the vehicles. Additional to the high standards of cleaning there is now an enhanced process in place, which involves the sanitizing of every vehicle between each rental using detergent cleaning products to protect all customers and add peace of mind.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Collision damage waiver",
            code: "CDW",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Supplementary Liability Insurance",
            code: "SLI",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Theft protection",
            code: "TP",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Fuel Information",
            code: "F2F",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "VAT",
            code: "TAX",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Cancellation fee",
            code: "CF",
            description:
              "Cancellation is free of charge until 08 Nov 2023 13:30. A cancellation fee of 100 percent will be charged from 08 Nov 2023 13:30 until 10 Nov 2023 13:30. A no show fee of 100 percent will be charged.",
            currency: "EUR",
            amount: 0,
          },
        ],
        booking_id: "-YW7z95zfk2O5epudAHSpA-86",
        api_uid: "66357eaf-1761-48e5-ab58-0c69f7a95c9a",
        supplier: "carnect",
        session_id: "610b57ee-77b5-4245-9bcc-1987cb90353a",
        is_own: true,
        is_parent_own: true,
      },
      {
        vehicle: {
          door_count: 2,
          model_year: null,
          name: "Dacia Sander",
          brand_code: "ECMR",
          code: "Dacia Sander",
          image:
            "https://vehicles.carhire-solutions.com/Budget/small/t_ECMR_FR.jpg",
          transmission_type: "Manual",
          fuel_type: "Unspecified",
          passenger_quantity: 5,
          baggage_quantity: 2,
          has_air_condition: true,
        },
        provider: {
          name: "Budget",
          code: "ZD",
        },
        price: {
          currency: "EUR",
          amount: "160.20",
        },
        pick_up: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "Bd Beauvais Apt",
          locationCode: null,
        },
        drop_off: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "Bd Beauvais Apt",
          locationCode: null,
        },
        inclusions: [
          {
            coverage_type: "Airport Service Charge",
            code: "407",
            description: "Facility charge at commercial airports.",
            currency: "EUR",
            amount: 37.5,
          },
          {
            coverage_type: "Vehicle Licence Fee / Road Fund Licence",
            code: "402",
            description:
              "Licensing and other vehicle-related taxes charged by local governments.",
            currency: "EUR",
            amount: 15.4,
          },
          {
            coverage_type: "Kilometer inclusive: 1250 km (0.30 EUR/km)",
            code: "416",
            description: "per km: .30 EUR",
            currency: "EUR",
            amount: 0.3,
          },
          {
            coverage_type: "Other taxes and service charges",
            code: "418",
            description: "per rental: 26.70 EUR",
            currency: "EUR",
            amount: 26.7,
          },
          {
            coverage_type: "Collision damage waiver",
            code: "CDW",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Theft protection",
            code: "TP",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Fuel Information",
            code: "F2F",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "VAT",
            code: "TAX",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Cancellation fee",
            code: "CF",
            description:
              "Cancellation is free of charge until 08 Nov 2023 13:30. A cancellation fee of 100 percent will be charged from 08 Nov 2023 13:30 until 10 Nov 2023 13:30. A no show fee of 100 percent will be charged.",
            currency: "EUR",
            amount: 0,
          },
        ],
        booking_id: "-YW7z95zfk2O5epudAHSpA-67",
        api_uid: "66357eaf-1761-48e5-ab58-0c69f7a95c9a",
        supplier: "carnect",
        session_id: "610b57ee-77b5-4245-9bcc-1987cb90353a",
        is_own: true,
        is_parent_own: true,
      },
      {
        vehicle: {
          door_count: 3,
          model_year: null,
          name: "FIAT 500",
          brand_code: "MBMR",
          code: "FIAT 500",
          image:
            "https://vehicles.carhire-solutions.com/Europcar/small/t_MBMR_FR.jpg",
          transmission_type: "Manual",
          fuel_type: "Unspecified",
          passenger_quantity: 4,
          baggage_quantity: 1,
          has_air_condition: true,
        },
        provider: {
          name: "Europcar",
          code: "EP",
        },
        price: {
          currency: "EUR",
          amount: "173.58",
        },
        pick_up: {
          geodata: {
            lat: 49.4544,
            lng: 2.1115,
          },
          counter: 14,
          name: "Beauvais Airport Fr",
          locationCode: null,
        },
        drop_off: {
          geodata: {
            lat: 49.4544,
            lng: 2.1115,
          },
          counter: 14,
          name: "Beauvais Airport Fr",
          locationCode: null,
        },
        inclusions: [
          {
            coverage_type: "Airport Service Charge",
            code: "407",
            description: "Facility charge at commercial airports.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Vehicle Licence Fee / Road Fund Licence",
            code: "402",
            description:
              "Licensing and other vehicle-related taxes charged by local governments.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Unlimited Mileage",
            code: "405",
            description: "No further costs apply for extra miles driven",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Enviromental Tax",
            code: "110",
            description: "per rental: 5.40 EUR",
            currency: "EUR",
            amount: 5.4,
          },
          {
            coverage_type: "Other taxes and service charges",
            code: "418",
            description: ", HSS",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Collision damage waiver",
            code: "CDW",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Theft protection",
            code: "TP",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Fuel Information",
            code: "F2F",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "VAT",
            code: "TAX",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Cancellation fee",
            code: "CF",
            description:
              "Cancellation is free of charge until 08 Nov 2023 13:30. A cancellation fee of 100 percent will be charged from 08 Nov 2023 13:30 until 10 Nov 2023 13:30. A no show fee of 100 percent will be charged.",
            currency: "EUR",
            amount: 0,
          },
        ],
        booking_id: "-YW7z95zfk2O5epudAHSpA-14",
        api_uid: "66357eaf-1761-48e5-ab58-0c69f7a95c9a",
        supplier: "carnect",
        session_id: "610b57ee-77b5-4245-9bcc-1987cb90353a",
        is_own: true,
        is_parent_own: true,
      },
      {
        vehicle: {
          door_count: 4,
          model_year: null,
          name: "B RENAULT CLIO",
          brand_code: "EDMR",
          code: "B RENAULT CLIO",
          image:
            "https://vehicles.carhire-solutions.com/Thrifty Europe/small/t_EDMR_FR.jpg",
          transmission_type: "Manual",
          fuel_type: "Unspecified",
          passenger_quantity: 4,
          baggage_quantity: 3,
          has_air_condition: true,
        },
        provider: {
          name: "Thrifty Europe",
          code: "TO",
        },
        price: {
          currency: "EUR",
          amount: "176.02",
        },
        pick_up: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "BEAUVAIS AIRPORT",
          locationCode: null,
        },
        drop_off: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "BEAUVAIS AIRPORT",
          locationCode: null,
        },
        inclusions: [
          {
            coverage_type: "Airport Service Charge",
            code: "407",
            description: "Facility charge at commercial airports.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Vehicle Licence Fee / Road Fund Licence",
            code: "402",
            description:
              "Licensing and other vehicle-related taxes charged by local governments.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Unlimited Mileage",
            code: "405",
            description: "No further costs apply for extra miles driven",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Stay Safe Initiative",
            code: "55",
            description:
              "As the world faces an unprecedented health crisis, our car rental suppliers have put special measures in place to provide you with a safe and flexible travel experience. The utmost attention is being paid to cleaning the vehicles. Additional to the high standards of cleaning there is now an enhanced process in place, which involves the sanitizing of every vehicle between each rental using detergent cleaning products to protect all customers and add peace of mind.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Collision damage waiver",
            code: "CDW",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Supplementary Liability Insurance",
            code: "SLI",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Theft protection",
            code: "TP",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Fuel Information",
            code: "F2F",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "VAT",
            code: "TAX",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Cancellation fee",
            code: "CF",
            description:
              "Cancellation is free of charge until 08 Nov 2023 13:30. A cancellation fee of 100 percent will be charged from 08 Nov 2023 13:30 until 10 Nov 2023 13:30. A no show fee of 100 percent will be charged.",
            currency: "EUR",
            amount: 0,
          },
        ],
        booking_id: "-YW7z95zfk2O5epudAHSpA-87",
        api_uid: "66357eaf-1761-48e5-ab58-0c69f7a95c9a",
        supplier: "carnect",
        session_id: "610b57ee-77b5-4245-9bcc-1987cb90353a",
        is_own: true,
        is_parent_own: true,
      },
      {
        vehicle: {
          door_count: 2,
          model_year: null,
          name: "Renault Twingo",
          brand_code: "MBMR",
          code: "Renault Twingo",
          image:
            "https://vehicles.carhire-solutions.com/Budget/small/t_MBMR_FR.jpg",
          transmission_type: "Manual",
          fuel_type: "Unspecified",
          passenger_quantity: 4,
          baggage_quantity: 2,
          has_air_condition: true,
        },
        provider: {
          name: "Budget",
          code: "ZD",
        },
        price: {
          currency: "EUR",
          amount: "182.30",
        },
        pick_up: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "Bd Beauvais Apt",
          locationCode: null,
        },
        drop_off: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "Bd Beauvais Apt",
          locationCode: null,
        },
        inclusions: [
          {
            coverage_type: "Airport Service Charge",
            code: "407",
            description: "Facility charge at commercial airports.",
            currency: "EUR",
            amount: 37.5,
          },
          {
            coverage_type: "Vehicle Licence Fee / Road Fund Licence",
            code: "402",
            description:
              "Licensing and other vehicle-related taxes charged by local governments.",
            currency: "EUR",
            amount: 15.4,
          },
          {
            coverage_type: "Kilometer inclusive: 1250 km (0.30 EUR/km)",
            code: "416",
            description: "per km: .30 EUR",
            currency: "EUR",
            amount: 0.3,
          },
          {
            coverage_type: "Other taxes and service charges",
            code: "418",
            description: "per rental: 24.30 EUR",
            currency: "EUR",
            amount: 24.3,
          },
          {
            coverage_type: "Full Protection",
            code: "413",
            description: "per day: 7.30 EUR",
            currency: "EUR",
            amount: 36.5,
          },
          {
            coverage_type: "Collision damage waiver",
            code: "CDW",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Theft protection",
            code: "TP",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Fuel Information",
            code: "F2F",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "VAT",
            code: "TAX",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Cancellation fee",
            code: "CF",
            description:
              "Cancellation is free of charge until 08 Nov 2023 13:30. A cancellation fee of 100 percent will be charged from 08 Nov 2023 13:30 until 10 Nov 2023 13:30. A no show fee of 100 percent will be charged.",
            currency: "EUR",
            amount: 0,
          },
        ],
        booking_id: "-YW7z95zfk2O5epudAHSpA-75",
        api_uid: "66357eaf-1761-48e5-ab58-0c69f7a95c9a",
        supplier: "carnect",
        session_id: "610b57ee-77b5-4245-9bcc-1987cb90353a",
        is_own: true,
        is_parent_own: true,
      },
      {
        vehicle: {
          door_count: 2,
          model_year: null,
          name: "A FIAT 500",
          brand_code: "MCMR",
          code: "A FIAT 500",
          image:
            "https://vehicles.carhire-solutions.com/Thrifty Europe/small/t_MCMR_FR.jpg",
          transmission_type: "Manual",
          fuel_type: "Unspecified",
          passenger_quantity: 4,
          baggage_quantity: 2,
          has_air_condition: true,
        },
        provider: {
          name: "Thrifty Europe",
          code: "TO",
        },
        price: {
          currency: "EUR",
          amount: "194.90",
        },
        pick_up: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "BEAUVAIS AIRPORT",
          locationCode: null,
        },
        drop_off: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "BEAUVAIS AIRPORT",
          locationCode: null,
        },
        inclusions: [
          {
            coverage_type: "Airport Service Charge",
            code: "407",
            description: "Facility charge at commercial airports.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Vehicle Licence Fee / Road Fund Licence",
            code: "402",
            description:
              "Licensing and other vehicle-related taxes charged by local governments.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Unlimited Mileage",
            code: "405",
            description: "No further costs apply for extra miles driven",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Stay Safe Initiative",
            code: "55",
            description:
              "As the world faces an unprecedented health crisis, our car rental suppliers have put special measures in place to provide you with a safe and flexible travel experience. The utmost attention is being paid to cleaning the vehicles. Additional to the high standards of cleaning there is now an enhanced process in place, which involves the sanitizing of every vehicle between each rental using detergent cleaning products to protect all customers and add peace of mind.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Full Protection",
            code: "413",
            description: "per day: 7.30 EUR",
            currency: "EUR",
            amount: 36.5,
          },
          {
            coverage_type: "Collision damage waiver",
            code: "CDW",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Supplementary Liability Insurance",
            code: "SLI",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Theft protection",
            code: "TP",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Fuel Information",
            code: "F2F",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "VAT",
            code: "TAX",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Cancellation fee",
            code: "CF",
            description:
              "Cancellation is free of charge until 08 Nov 2023 13:30. A cancellation fee of 100 percent will be charged from 08 Nov 2023 13:30 until 10 Nov 2023 13:30. A no show fee of 100 percent will be charged.",
            currency: "EUR",
            amount: 0,
          },
        ],
        booking_id: "-YW7z95zfk2O5epudAHSpA-92",
        api_uid: "66357eaf-1761-48e5-ab58-0c69f7a95c9a",
        supplier: "carnect",
        session_id: "610b57ee-77b5-4245-9bcc-1987cb90353a",
        is_own: true,
        is_parent_own: true,
      },
      {
        vehicle: {
          door_count: 2,
          model_year: null,
          name: "Dacia Sander",
          brand_code: "ECMR",
          code: "Dacia Sander",
          image:
            "https://vehicles.carhire-solutions.com/Budget/small/t_ECMR_FR.jpg",
          transmission_type: "Manual",
          fuel_type: "Unspecified",
          passenger_quantity: 5,
          baggage_quantity: 2,
          has_air_condition: true,
        },
        provider: {
          name: "Budget",
          code: "ZD",
        },
        price: {
          currency: "EUR",
          amount: "196.70",
        },
        pick_up: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "Bd Beauvais Apt",
          locationCode: null,
        },
        drop_off: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "Bd Beauvais Apt",
          locationCode: null,
        },
        inclusions: [
          {
            coverage_type: "Airport Service Charge",
            code: "407",
            description: "Facility charge at commercial airports.",
            currency: "EUR",
            amount: 37.5,
          },
          {
            coverage_type: "Vehicle Licence Fee / Road Fund Licence",
            code: "402",
            description:
              "Licensing and other vehicle-related taxes charged by local governments.",
            currency: "EUR",
            amount: 15.4,
          },
          {
            coverage_type: "Kilometer inclusive: 1250 km (0.30 EUR/km)",
            code: "416",
            description: "per km: .30 EUR",
            currency: "EUR",
            amount: 0.3,
          },
          {
            coverage_type: "Other taxes and service charges",
            code: "418",
            description: "per rental: 26.70 EUR",
            currency: "EUR",
            amount: 26.7,
          },
          {
            coverage_type: "Full Protection",
            code: "413",
            description: "per day: 7.30 EUR",
            currency: "EUR",
            amount: 36.5,
          },
          {
            coverage_type: "Collision damage waiver",
            code: "CDW",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Theft protection",
            code: "TP",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Fuel Information",
            code: "F2F",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "VAT",
            code: "TAX",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Cancellation fee",
            code: "CF",
            description:
              "Cancellation is free of charge until 08 Nov 2023 13:30. A cancellation fee of 100 percent will be charged from 08 Nov 2023 13:30 until 10 Nov 2023 13:30. A no show fee of 100 percent will be charged.",
            currency: "EUR",
            amount: 0,
          },
        ],
        booking_id: "-YW7z95zfk2O5epudAHSpA-76",
        api_uid: "66357eaf-1761-48e5-ab58-0c69f7a95c9a",
        supplier: "carnect",
        session_id: "610b57ee-77b5-4245-9bcc-1987cb90353a",
        is_own: true,
        is_parent_own: true,
      },
      {
        vehicle: {
          door_count: 4,
          model_year: null,
          name: "C PEUGEOT 2008",
          brand_code: "CDMR",
          code: "C PEUGEOT 2008",
          image:
            "https://vehicles.carhire-solutions.com/Thrifty Europe/small/t_CDMR_FR.jpg",
          transmission_type: "Manual",
          fuel_type: "Unspecified",
          passenger_quantity: 5,
          baggage_quantity: 3,
          has_air_condition: true,
        },
        provider: {
          name: "Thrifty Europe",
          code: "TO",
        },
        price: {
          currency: "EUR",
          amount: "203.48",
        },
        pick_up: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "BEAUVAIS AIRPORT",
          locationCode: null,
        },
        drop_off: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "BEAUVAIS AIRPORT",
          locationCode: null,
        },
        inclusions: [
          {
            coverage_type: "Airport Service Charge",
            code: "407",
            description: "Facility charge at commercial airports.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Vehicle Licence Fee / Road Fund Licence",
            code: "402",
            description:
              "Licensing and other vehicle-related taxes charged by local governments.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Unlimited Mileage",
            code: "405",
            description: "No further costs apply for extra miles driven",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Stay Safe Initiative",
            code: "55",
            description:
              "As the world faces an unprecedented health crisis, our car rental suppliers have put special measures in place to provide you with a safe and flexible travel experience. The utmost attention is being paid to cleaning the vehicles. Additional to the high standards of cleaning there is now an enhanced process in place, which involves the sanitizing of every vehicle between each rental using detergent cleaning products to protect all customers and add peace of mind.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Collision damage waiver",
            code: "CDW",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Supplementary Liability Insurance",
            code: "SLI",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Theft protection",
            code: "TP",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Fuel Information",
            code: "F2F",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "VAT",
            code: "TAX",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Cancellation fee",
            code: "CF",
            description:
              "Cancellation is free of charge until 08 Nov 2023 13:30. A cancellation fee of 100 percent will be charged from 08 Nov 2023 13:30 until 10 Nov 2023 13:30. A no show fee of 100 percent will be charged.",
            currency: "EUR",
            amount: 0,
          },
        ],
        booking_id: "-YW7z95zfk2O5epudAHSpA-84",
        api_uid: "66357eaf-1761-48e5-ab58-0c69f7a95c9a",
        supplier: "carnect",
        session_id: "610b57ee-77b5-4245-9bcc-1987cb90353a",
        is_own: true,
        is_parent_own: true,
      },
      {
        vehicle: {
          door_count: 3,
          model_year: null,
          name: "FIAT 500",
          brand_code: "MBMR",
          code: "FIAT 500",
          image:
            "https://vehicles.carhire-solutions.com/Europcar/small/t_MBMR_FR.jpg",
          transmission_type: "Manual",
          fuel_type: "Unspecified",
          passenger_quantity: 4,
          baggage_quantity: 1,
          has_air_condition: true,
        },
        provider: {
          name: "Europcar",
          code: "EP",
        },
        price: {
          currency: "EUR",
          amount: "210.08",
        },
        pick_up: {
          geodata: {
            lat: 49.4544,
            lng: 2.1115,
          },
          counter: 14,
          name: "Beauvais Airport Fr",
          locationCode: null,
        },
        drop_off: {
          geodata: {
            lat: 49.4544,
            lng: 2.1115,
          },
          counter: 14,
          name: "Beauvais Airport Fr",
          locationCode: null,
        },
        inclusions: [
          {
            coverage_type: "Airport Service Charge",
            code: "407",
            description: "Facility charge at commercial airports.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Vehicle Licence Fee / Road Fund Licence",
            code: "402",
            description:
              "Licensing and other vehicle-related taxes charged by local governments.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Unlimited Mileage",
            code: "405",
            description: "No further costs apply for extra miles driven",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Enviromental Tax",
            code: "110",
            description: "per rental: 5.40 EUR",
            currency: "EUR",
            amount: 5.4,
          },
          {
            coverage_type: "Other taxes and service charges",
            code: "418",
            description: ", HSS",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Full Protection",
            code: "413",
            description: "per day: 7.30 EUR",
            currency: "EUR",
            amount: 36.5,
          },
          {
            coverage_type: "Collision damage waiver",
            code: "CDW",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Theft protection",
            code: "TP",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Fuel Information",
            code: "F2F",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "VAT",
            code: "TAX",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Cancellation fee",
            code: "CF",
            description:
              "Cancellation is free of charge until 08 Nov 2023 13:30. A cancellation fee of 100 percent will be charged from 08 Nov 2023 13:30 until 10 Nov 2023 13:30. A no show fee of 100 percent will be charged.",
            currency: "EUR",
            amount: 0,
          },
        ],
        booking_id: "-YW7z95zfk2O5epudAHSpA-19",
        api_uid: "66357eaf-1761-48e5-ab58-0c69f7a95c9a",
        supplier: "carnect",
        session_id: "610b57ee-77b5-4245-9bcc-1987cb90353a",
        is_own: true,
        is_parent_own: true,
      },
      {
        vehicle: {
          door_count: 4,
          model_year: null,
          name: "B RENAULT CLIO",
          brand_code: "EDMR",
          code: "B RENAULT CLIO",
          image:
            "https://vehicles.carhire-solutions.com/Thrifty Europe/small/t_EDMR_FR.jpg",
          transmission_type: "Manual",
          fuel_type: "Unspecified",
          passenger_quantity: 4,
          baggage_quantity: 3,
          has_air_condition: true,
        },
        provider: {
          name: "Thrifty Europe",
          code: "TO",
        },
        price: {
          currency: "EUR",
          amount: "212.52",
        },
        pick_up: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "BEAUVAIS AIRPORT",
          locationCode: null,
        },
        drop_off: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "BEAUVAIS AIRPORT",
          locationCode: null,
        },
        inclusions: [
          {
            coverage_type: "Airport Service Charge",
            code: "407",
            description: "Facility charge at commercial airports.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Vehicle Licence Fee / Road Fund Licence",
            code: "402",
            description:
              "Licensing and other vehicle-related taxes charged by local governments.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Unlimited Mileage",
            code: "405",
            description: "No further costs apply for extra miles driven",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Stay Safe Initiative",
            code: "55",
            description:
              "As the world faces an unprecedented health crisis, our car rental suppliers have put special measures in place to provide you with a safe and flexible travel experience. The utmost attention is being paid to cleaning the vehicles. Additional to the high standards of cleaning there is now an enhanced process in place, which involves the sanitizing of every vehicle between each rental using detergent cleaning products to protect all customers and add peace of mind.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Full Protection",
            code: "413",
            description: "per day: 7.30 EUR",
            currency: "EUR",
            amount: 36.5,
          },
          {
            coverage_type: "Collision damage waiver",
            code: "CDW",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Supplementary Liability Insurance",
            code: "SLI",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Theft protection",
            code: "TP",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Fuel Information",
            code: "F2F",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "VAT",
            code: "TAX",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Cancellation fee",
            code: "CF",
            description:
              "Cancellation is free of charge until 08 Nov 2023 13:30. A cancellation fee of 100 percent will be charged from 08 Nov 2023 13:30 until 10 Nov 2023 13:30. A no show fee of 100 percent will be charged.",
            currency: "EUR",
            amount: 0,
          },
        ],
        booking_id: "-YW7z95zfk2O5epudAHSpA-93",
        api_uid: "66357eaf-1761-48e5-ab58-0c69f7a95c9a",
        supplier: "carnect",
        session_id: "610b57ee-77b5-4245-9bcc-1987cb90353a",
        is_own: true,
        is_parent_own: true,
      },
      {
        vehicle: {
          door_count: 4,
          model_year: null,
          name: "Fiat Tipo",
          brand_code: "CDMR",
          code: "Fiat Tipo",
          image:
            "https://vehicles.carhire-solutions.com/Budget/small/t_CDMR_FR.jpg",
          transmission_type: "Manual",
          fuel_type: "Unspecified",
          passenger_quantity: 5,
          baggage_quantity: 2,
          has_air_condition: true,
        },
        provider: {
          name: "Budget",
          code: "ZD",
        },
        price: {
          currency: "EUR",
          amount: "213.00",
        },
        pick_up: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "Bd Beauvais Apt",
          locationCode: null,
        },
        drop_off: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "Bd Beauvais Apt",
          locationCode: null,
        },
        inclusions: [
          {
            coverage_type: "Airport Service Charge",
            code: "407",
            description: "Facility charge at commercial airports.",
            currency: "EUR",
            amount: 37.5,
          },
          {
            coverage_type: "Vehicle Licence Fee / Road Fund Licence",
            code: "402",
            description:
              "Licensing and other vehicle-related taxes charged by local governments.",
            currency: "EUR",
            amount: 15.4,
          },
          {
            coverage_type: "Kilometer inclusive: 1250 km (0.36 EUR/km)",
            code: "416",
            description: "per km: .36 EUR",
            currency: "EUR",
            amount: 0.36,
          },
          {
            coverage_type: "Other taxes and service charges",
            code: "418",
            description: "per rental: 35.50 EUR",
            currency: "EUR",
            amount: 35.5,
          },
          {
            coverage_type: "Collision damage waiver",
            code: "CDW",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Theft protection",
            code: "TP",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Fuel Information",
            code: "F2F",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "VAT",
            code: "TAX",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Cancellation fee",
            code: "CF",
            description:
              "Cancellation is free of charge until 08 Nov 2023 13:30. A cancellation fee of 100 percent will be charged from 08 Nov 2023 13:30 until 10 Nov 2023 13:30. A no show fee of 100 percent will be charged.",
            currency: "EUR",
            amount: 0,
          },
        ],
        booking_id: "-YW7z95zfk2O5epudAHSpA-68",
        api_uid: "66357eaf-1761-48e5-ab58-0c69f7a95c9a",
        supplier: "carnect",
        session_id: "610b57ee-77b5-4245-9bcc-1987cb90353a",
        is_own: true,
        is_parent_own: true,
      },
      {
        vehicle: {
          door_count: 5,
          model_year: null,
          name: "TOYOTA YARIS",
          brand_code: "ECMR",
          code: "TOYOTA YARIS",
          image: "https://vehicles.carhire-solutions.com/default/small/E.jpg",
          transmission_type: "Manual",
          fuel_type: "Unspecified",
          passenger_quantity: 5,
          baggage_quantity: 2,
          has_air_condition: true,
        },
        provider: {
          name: "Europcar",
          code: "EP",
        },
        price: {
          currency: "EUR",
          amount: "214.10",
        },
        pick_up: {
          geodata: {
            lat: 49.4544,
            lng: 2.1115,
          },
          counter: 14,
          name: "Beauvais Airport Fr",
          locationCode: null,
        },
        drop_off: {
          geodata: {
            lat: 49.4544,
            lng: 2.1115,
          },
          counter: 14,
          name: "Beauvais Airport Fr",
          locationCode: null,
        },
        inclusions: [
          {
            coverage_type: "Airport Service Charge",
            code: "407",
            description: "Facility charge at commercial airports.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Vehicle Licence Fee / Road Fund Licence",
            code: "402",
            description:
              "Licensing and other vehicle-related taxes charged by local governments.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Unlimited Mileage",
            code: "405",
            description: "No further costs apply for extra miles driven",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Enviromental Tax",
            code: "110",
            description: "per rental: 5.40 EUR",
            currency: "EUR",
            amount: 5.4,
          },
          {
            coverage_type: "Other taxes and service charges",
            code: "418",
            description: ", HSS",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Collision damage waiver",
            code: "CDW",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Theft protection",
            code: "TP",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Fuel Information",
            code: "F2F",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "VAT",
            code: "TAX",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Cancellation fee",
            code: "CF",
            description:
              "Cancellation is free of charge until 08 Nov 2023 13:30. A cancellation fee of 100 percent will be charged from 08 Nov 2023 13:30 until 10 Nov 2023 13:30. A no show fee of 100 percent will be charged.",
            currency: "EUR",
            amount: 0,
          },
        ],
        booking_id: "-YW7z95zfk2O5epudAHSpA-10",
        api_uid: "66357eaf-1761-48e5-ab58-0c69f7a95c9a",
        supplier: "carnect",
        session_id: "610b57ee-77b5-4245-9bcc-1987cb90353a",
        is_own: true,
        is_parent_own: true,
      },
      {
        vehicle: {
          door_count: 3,
          model_year: null,
          name: "FIAT 500",
          brand_code: "MBMR",
          code: "FIAT 500",
          image:
            "https://vehicles.carhire-solutions.com/Europcar/small/t_MBMR_FR.jpg",
          transmission_type: "Manual",
          fuel_type: "Unspecified",
          passenger_quantity: 4,
          baggage_quantity: 1,
          has_air_condition: true,
        },
        provider: {
          name: "Europcar",
          code: "EP",
        },
        price: {
          currency: "EUR",
          amount: "219.17",
        },
        pick_up: {
          geodata: {
            lat: 49.4544,
            lng: 2.1115,
          },
          counter: 14,
          name: "Beauvais Airport Fr",
          locationCode: null,
        },
        drop_off: {
          geodata: {
            lat: 49.4544,
            lng: 2.1115,
          },
          counter: 14,
          name: "Beauvais Airport Fr",
          locationCode: null,
        },
        inclusions: [
          {
            coverage_type: "Airport Service Charge",
            code: "407",
            description: "Facility charge at commercial airports.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Vehicle Licence Fee / Road Fund Licence",
            code: "402",
            description:
              "Licensing and other vehicle-related taxes charged by local governments.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Unlimited Mileage",
            code: "405",
            description: "No further costs apply for extra miles driven",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Enviromental Tax",
            code: "110",
            description: "per rental: 5.40 EUR",
            currency: "EUR",
            amount: 5.4,
          },
          {
            coverage_type: "Other taxes and service charges",
            code: "418",
            description: ", HSS",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Collision damage waiver",
            code: "CDW",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Theft protection",
            code: "TP",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Fuel Information",
            code: "F2F",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "VAT",
            code: "TAX",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Cancellation fee",
            code: "CF",
            description:
              "Cancellation is free of charge until 08 Nov 2023 13:30. A cancellation fee of 100 percent will be charged from 08 Nov 2023 13:30 until 10 Nov 2023 13:30. A no show fee of 100 percent will be charged.",
            currency: "EUR",
            amount: 0,
          },
        ],
        booking_id: "-YW7z95zfk2O5epudAHSpA-4",
        api_uid: "66357eaf-1761-48e5-ab58-0c69f7a95c9a",
        supplier: "carnect",
        session_id: "610b57ee-77b5-4245-9bcc-1987cb90353a",
        is_own: true,
        is_parent_own: true,
      },
      {
        vehicle: {
          door_count: 5,
          model_year: null,
          name: "FIAT 500X",
          brand_code: "CDMR",
          code: "FIAT 500X",
          image:
            "https://vehicles.carhire-solutions.com/Europcar/small/t_CDMR_FR.jpg",
          transmission_type: "Manual",
          fuel_type: "Diesel",
          passenger_quantity: 5,
          baggage_quantity: 2,
          has_air_condition: true,
        },
        provider: {
          name: "Europcar",
          code: "EP",
        },
        price: {
          currency: "EUR",
          amount: "219.71",
        },
        pick_up: {
          geodata: {
            lat: 49.4544,
            lng: 2.1115,
          },
          counter: 14,
          name: "Beauvais Airport Fr",
          locationCode: null,
        },
        drop_off: {
          geodata: {
            lat: 49.4544,
            lng: 2.1115,
          },
          counter: 14,
          name: "Beauvais Airport Fr",
          locationCode: null,
        },
        inclusions: [
          {
            coverage_type: "Airport Service Charge",
            code: "407",
            description: "Facility charge at commercial airports.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Vehicle Licence Fee / Road Fund Licence",
            code: "402",
            description:
              "Licensing and other vehicle-related taxes charged by local governments.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Unlimited Mileage",
            code: "405",
            description: "No further costs apply for extra miles driven",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Enviromental Tax",
            code: "110",
            description: "per rental: 10.80 EUR",
            currency: "EUR",
            amount: 10.8,
          },
          {
            coverage_type: "Other taxes and service charges",
            code: "418",
            description: ", HSS",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Collision damage waiver",
            code: "CDW",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Theft protection",
            code: "TP",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Fuel Information",
            code: "F2F",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "VAT",
            code: "TAX",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Cancellation fee",
            code: "CF",
            description:
              "Cancellation is free of charge until 08 Nov 2023 13:30. A cancellation fee of 100 percent will be charged from 08 Nov 2023 13:30 until 10 Nov 2023 13:30. A no show fee of 100 percent will be charged.",
            currency: "EUR",
            amount: 0,
          },
        ],
        booking_id: "-YW7z95zfk2O5epudAHSpA-11",
        api_uid: "66357eaf-1761-48e5-ab58-0c69f7a95c9a",
        supplier: "carnect",
        session_id: "610b57ee-77b5-4245-9bcc-1987cb90353a",
        is_own: true,
        is_parent_own: true,
      },
      {
        vehicle: {
          door_count: 5,
          model_year: null,
          name: "D PEUGEOT 308 SW",
          brand_code: "CWMR",
          code: "D PEUGEOT 308 SW",
          image:
            "https://vehicles.carhire-solutions.com/Thrifty Europe/small/t_CWMR_FR.jpg",
          transmission_type: "Manual",
          fuel_type: "Unspecified",
          passenger_quantity: 5,
          baggage_quantity: 3,
          has_air_condition: true,
        },
        provider: {
          name: "Thrifty Europe",
          code: "TO",
        },
        price: {
          currency: "EUR",
          amount: "223.87",
        },
        pick_up: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "BEAUVAIS AIRPORT",
          locationCode: null,
        },
        drop_off: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "BEAUVAIS AIRPORT",
          locationCode: null,
        },
        inclusions: [
          {
            coverage_type: "Airport Service Charge",
            code: "407",
            description: "Facility charge at commercial airports.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Vehicle Licence Fee / Road Fund Licence",
            code: "402",
            description:
              "Licensing and other vehicle-related taxes charged by local governments.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Unlimited Mileage",
            code: "405",
            description: "No further costs apply for extra miles driven",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Stay Safe Initiative",
            code: "55",
            description:
              "As the world faces an unprecedented health crisis, our car rental suppliers have put special measures in place to provide you with a safe and flexible travel experience. The utmost attention is being paid to cleaning the vehicles. Additional to the high standards of cleaning there is now an enhanced process in place, which involves the sanitizing of every vehicle between each rental using detergent cleaning products to protect all customers and add peace of mind.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Collision damage waiver",
            code: "CDW",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Supplementary Liability Insurance",
            code: "SLI",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Theft protection",
            code: "TP",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Fuel Information",
            code: "F2F",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "VAT",
            code: "TAX",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Cancellation fee",
            code: "CF",
            description:
              "Cancellation is free of charge until 08 Nov 2023 13:30. A cancellation fee of 100 percent will be charged from 08 Nov 2023 13:30 until 10 Nov 2023 13:30. A no show fee of 100 percent will be charged.",
            currency: "EUR",
            amount: 0,
          },
        ],
        booking_id: "-YW7z95zfk2O5epudAHSpA-88",
        api_uid: "66357eaf-1761-48e5-ab58-0c69f7a95c9a",
        supplier: "carnect",
        session_id: "610b57ee-77b5-4245-9bcc-1987cb90353a",
        is_own: true,
        is_parent_own: true,
      },
      {
        vehicle: {
          door_count: 2,
          model_year: null,
          name: "Fiat 500e Electric",
          brand_code: "MBAE",
          code: "Fiat 500e Electric",
          image:
            "https://vehicles.carhire-solutions.com/Budget/small/t_MBAE_FR.jpg",
          transmission_type: "Automatic",
          fuel_type: "Electric",
          passenger_quantity: 4,
          baggage_quantity: 2,
          has_air_condition: true,
        },
        provider: {
          name: "Budget",
          code: "ZD",
        },
        price: {
          currency: "EUR",
          amount: "225.48",
        },
        pick_up: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "Bd Beauvais Apt",
          locationCode: null,
        },
        drop_off: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "Bd Beauvais Apt",
          locationCode: null,
        },
        inclusions: [
          {
            coverage_type: "Airport Service Charge",
            code: "407",
            description: "Facility charge at commercial airports.",
            currency: "EUR",
            amount: 37.5,
          },
          {
            coverage_type: "Vehicle Licence Fee / Road Fund Licence",
            code: "402",
            description:
              "Licensing and other vehicle-related taxes charged by local governments.",
            currency: "EUR",
            amount: 15.4,
          },
          {
            coverage_type: "Kilometer inclusive: 1250 km (0.42 EUR/km)",
            code: "416",
            description: "per km: .42 EUR",
            currency: "EUR",
            amount: 0.42,
          },
          {
            coverage_type: "Other taxes and service charges",
            code: "418",
            description: "per rental: 37.58 EUR",
            currency: "EUR",
            amount: 37.58,
          },
          {
            coverage_type: "Collision damage waiver",
            code: "CDW",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Theft protection",
            code: "TP",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Fuel Information",
            code: "F2F",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "VAT",
            code: "TAX",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Cancellation fee",
            code: "CF",
            description:
              "Cancellation is free of charge until 08 Nov 2023 13:30. A cancellation fee of 100 percent will be charged from 08 Nov 2023 13:30 until 10 Nov 2023 13:30. A no show fee of 100 percent will be charged.",
            currency: "EUR",
            amount: 0,
          },
        ],
        booking_id: "-YW7z95zfk2O5epudAHSpA-71",
        api_uid: "66357eaf-1761-48e5-ab58-0c69f7a95c9a",
        supplier: "carnect",
        session_id: "610b57ee-77b5-4245-9bcc-1987cb90353a",
        is_own: true,
        is_parent_own: true,
      },
      {
        vehicle: {
          door_count: 2,
          model_year: null,
          name: "A FIAT 500",
          brand_code: "MCMR",
          code: "A FIAT 500",
          image:
            "https://vehicles.carhire-solutions.com/Hertz/small/t_MCMR_FR.jpg",
          transmission_type: "Manual",
          fuel_type: "Unspecified",
          passenger_quantity: 4,
          baggage_quantity: 2,
          has_air_condition: true,
        },
        provider: {
          name: "Hertz",
          code: "ZE",
        },
        price: {
          currency: "EUR",
          amount: "229.98",
        },
        pick_up: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "BEAUVAIS AIRPORT",
          locationCode: null,
        },
        drop_off: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 14,
          name: "BEAUVAIS AIRPORT",
          locationCode: null,
        },
        inclusions: [
          {
            coverage_type: "Airport Service Charge",
            code: "407",
            description: "Facility charge at commercial airports.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Vehicle Licence Fee / Road Fund Licence",
            code: "402",
            description:
              "Licensing and other vehicle-related taxes charged by local governments.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Unlimited Mileage",
            code: "405",
            description: "No further costs apply for extra miles driven",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Stay Safe Initiative",
            code: "55",
            description:
              "As the world faces an unprecedented health crisis, our car rental suppliers have put special measures in place to provide you with a safe and flexible travel experience. The utmost attention is being paid to cleaning the vehicles. Additional to the high standards of cleaning there is now an enhanced process in place, which involves the sanitizing of every vehicle between each rental using detergent cleaning products to protect all customers and add peace of mind.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Collision damage waiver",
            code: "CDW",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Theft protection",
            code: "TP",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Fuel Information",
            code: "F2F",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "VAT",
            code: "TAX",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Cancellation fee",
            code: "CF",
            description:
              "Cancellation is free of charge until 08 Nov 2023 13:30. A cancellation fee of 100 percent will be charged from 08 Nov 2023 13:30 until 10 Nov 2023 13:30. A no show fee of 100 percent will be charged.",
            currency: "EUR",
            amount: 0,
          },
        ],
        booking_id: "-YW7z95zfk2O5epudAHSpA-22",
        api_uid: "66357eaf-1761-48e5-ab58-0c69f7a95c9a",
        supplier: "carnect",
        session_id: "610b57ee-77b5-4245-9bcc-1987cb90353a",
        is_own: true,
        is_parent_own: true,
      },
      {
        vehicle: {
          door_count: 2,
          model_year: null,
          name: "A FIAT 500",
          brand_code: "MCMR",
          code: "A FIAT 500",
          image:
            "https://vehicles.carhire-solutions.com/Hertz/small/t_MCMR_FR.jpg",
          transmission_type: "Manual",
          fuel_type: "Unspecified",
          passenger_quantity: 4,
          baggage_quantity: 2,
          has_air_condition: true,
        },
        provider: {
          name: "Hertz",
          code: "ZE",
        },
        price: {
          currency: "EUR",
          amount: "229.98",
        },
        pick_up: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 7,
          name: "BEAUVAIS DEL & COL",
          locationCode: null,
        },
        drop_off: {
          geodata: {
            lat: 49.454444,
            lng: 2.112778,
          },
          counter: 7,
          name: "BEAUVAIS DEL & COL",
          locationCode: null,
        },
        inclusions: [
          {
            coverage_type: "Vehicle Licence Fee / Road Fund Licence",
            code: "402",
            description:
              "Licensing and other vehicle-related taxes charged by local governments.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Unlimited Mileage",
            code: "405",
            description: "No further costs apply for extra miles driven",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Stay Safe Initiative",
            code: "55",
            description:
              "As the world faces an unprecedented health crisis, our car rental suppliers have put special measures in place to provide you with a safe and flexible travel experience. The utmost attention is being paid to cleaning the vehicles. Additional to the high standards of cleaning there is now an enhanced process in place, which involves the sanitizing of every vehicle between each rental using detergent cleaning products to protect all customers and add peace of mind.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Other taxes and service charges",
            code: "418",
            description: ", MISCELLANEOUS APF FEE",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Collision damage waiver",
            code: "CDW",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Theft protection",
            code: "TP",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Fuel Information",
            code: "F2F",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "VAT",
            code: "TAX",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Cancellation fee",
            code: "CF",
            description:
              "Cancellation is free of charge until 08 Nov 2023 13:30. A cancellation fee of 100 percent will be charged from 08 Nov 2023 13:30 until 10 Nov 2023 13:30. A no show fee of 100 percent will be charged.",
            currency: "EUR",
            amount: 0,
          },
        ],
        booking_id: "-YW7z95zfk2O5epudAHSpA-37",
        api_uid: "66357eaf-1761-48e5-ab58-0c69f7a95c9a",
        supplier: "carnect",
        session_id: "610b57ee-77b5-4245-9bcc-1987cb90353a",
        is_own: true,
        is_parent_own: true,
      },
      {
        vehicle: {
          door_count: 5,
          model_year: null,
          name: "NISSAN QASHQAI",
          brand_code: "ISAR",
          code: "NISSAN QASHQAI",
          image:
            "https://vehicles.carhire-solutions.com/406/small/1967565_ISAR.jpg",
          transmission_type: "Automatic",
          fuel_type: "Unspecified",
          passenger_quantity: 5,
          baggage_quantity: 3,
          has_air_condition: true,
        },
        provider: {
          name: "Europcar",
          code: "EP",
        },
        price: {
          currency: "EUR",
          amount: "238.49",
        },
        pick_up: {
          geodata: {
            lat: 49.4544,
            lng: 2.1115,
          },
          counter: 14,
          name: "Beauvais Airport Fr",
          locationCode: null,
        },
        drop_off: {
          geodata: {
            lat: 49.4544,
            lng: 2.1115,
          },
          counter: 14,
          name: "Beauvais Airport Fr",
          locationCode: null,
        },
        inclusions: [
          {
            coverage_type: "Airport Service Charge",
            code: "407",
            description: "Facility charge at commercial airports.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Vehicle Licence Fee / Road Fund Licence",
            code: "402",
            description:
              "Licensing and other vehicle-related taxes charged by local governments.",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Unlimited Mileage",
            code: "405",
            description: "No further costs apply for extra miles driven",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Enviromental Tax",
            code: "110",
            description: "per rental: 18.00 EUR",
            currency: "EUR",
            amount: 18,
          },
          {
            coverage_type: "Other taxes and service charges",
            code: "418",
            description: ", HSS",
            currency: "EUR",
            amount: 0,
          },
          {
            coverage_type: "Collision damage waiver",
            code: "CDW",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Theft protection",
            code: "TP",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Fuel Information",
            code: "F2F",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "VAT",
            code: "TAX",
            description: null,
            currency: null,
            amount: 0,
          },
          {
            coverage_type: "Cancellation fee",
            code: "CF",
            description:
              "Cancellation is free of charge until 08 Nov 2023 13:30. A cancellation fee of 100 percent will be charged from 08 Nov 2023 13:30 until 10 Nov 2023 13:30. A no show fee of 100 percent will be charged.",
            currency: "EUR",
            amount: 0,
          },
        ],
        booking_id: "-YW7z95zfk2O5epudAHSpA-13",
        api_uid: "66357eaf-1761-48e5-ab58-0c69f7a95c9a",
        supplier: "carnect",
        session_id: "610b57ee-77b5-4245-9bcc-1987cb90353a",
        is_own: true,
        is_parent_own: true,
      },
    ],
    computed_data: {
      pricesRange: {
        minPrice: 145,
        maxPrice: 239,
        currency: "EUR",
      },
      providers: ["Budget", "Thrifty Europe", "Europcar", "Hertz"],
      vehicles: [
        "Renault Twingo",
        "A FIAT 500",
        "Dacia Sander",
        "FIAT 500",
        "B RENAULT CLIO",
        "C PEUGEOT 2008",
        "Fiat Tipo",
        "TOYOTA YARIS",
        "FIAT 500X",
        "D PEUGEOT 308 SW",
        "Fiat 500e Electric",
        "NISSAN QASHQAI",
      ],
    },
  };
  return !serviceIsSelected
    ? null
    : addons.map((addon, idx) => {
        const price = addon.service.price.find(
          (pr) => pr.group_uid == addon.service.meetingGroupUid
        );

        const status = _.get(
          addonsStatus.find((status) => status.search_uid == addon.search_uid),
          "service.status"
        );

        return (
          <div key={idx} className={classes.Addon}>
            <div />
            {
              // <CarRentalData
              //    car={carRentals.results[0]}
              //    services_collapsed={services_collapsed}
              //    toggleSelfCollapse={toggleSelfCollapse}
              // />
            }
            <AddOn
              key={idx}
              addon={addon}
              status={status}
              services_collapsed={services_collapsed}
              toggleSelfCollapse={toggleSelfCollapse}
              actions={
                <WhisperHelper
                  color={variables.colors.easy.orange}
                  msg="To view Add On details switch to Day By Day mode."
                />
              }
            />
            {currentStep == 4 ? (
              <PrebookPrice
                serviceType="MI"
                price={status == "unavailable" ? 0 : price.value}
                currency={price.currency}
                service={addon.service}
                destOrder={addon.service.destOrder}
                searching={status == "searching"}
              />
            ) : (
              !services_collapsed && (
                <OverviewPrice
                  serviceType="MI"
                  price={status == "unavailable" ? 0 : price?.value ?? 0}
                  currency={price?.currency ?? "EUR"}
                  markup={addon.markup}
                  searching={status == "searching"}
                />
              )
            )}
          </div>
        );
      });
};
Addons.propTypes = {
  currentStep: PropTypes.number.isRequired,
  addons: PropTypes.array.isRequired,
  addonsStatus: PropTypes.array.isRequired,
};
