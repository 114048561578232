import _ from "lodash";

export const validateOffer = (offer) => {
  const msgs = [];

  if (!offer.offer_pricing_data) {
    console.log(offer.offer_pricing_data);
    msgs.push(["Offer Pricing", "Offer pricing information is missing."]);
  }

  if (
    _.flatten(
      Object.values(offer.offer_pricing_data).map((v) => Object.values(v))
    ).reduce((a, b) => a + b, 0) == 0
  ) {
    msgs.push(["Offer Pricing", "Offer pricing information is missing."]);
  }

  if (!offer.valid_to) {
    msgs.push(["Offer Terms", "Offer validity date missing."]);
  }

  if (!offer.paid_before) {
    msgs.push(["Offer Terms", "Pay date missing."]);
  }

  if (!offer.offer_terms || offer.offer_terms == "") {
    msgs.push(["Offer Message", "Offer Message is missing."]);
  }

  return msgs;
};
