import { NormalInputField } from "@src/components/forms";
import { currencies } from "@src/definitions";
import { extraTypeMapping } from "@src/pages/Project/TripPlan/Components/Modals/products/addhoc/accommodation/formComponents";
import { Form, Formik } from "formik";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { Button } from "rsuite";
import { genericRsrvFeebackStyles } from "../common/styles";
// import { basicInfoStylesObj } from "./common";

const extrasInfoStyles = createUseStyles({
  ExtrasInfo: genericRsrvFeebackStyles.card,
  header: genericRsrvFeebackStyles.cardHeader,
  body: { ...genericRsrvFeebackStyles.cardBody, overflow: "visible" },
});
const ExtrasInfo = ({ confirmData, currency, onConfirm }) => {
  const classes = extrasInfoStyles();

  const [viewMode, setViewMode] = useState(
    _.get(confirmData, "extrasConfirmed", false)
  );

  var extras = _.get(confirmData, "extras", []);

  return !extras.length ? null : (
    <div className={classes.ExtrasInfo}>
      <div className={classes.header}>
        <h5>Extras Information</h5>
      </div>
      <Formik
        initialValues={{
          extras: _.cloneDeep(extras).map((extra) => {
            extra.type = extraTypeMapping[extra.type];
            extra.currency = currency;
            return extra;
          }),
        }}
        validateOnChange={false}
        validateOnBlur={false}
        validateOnMount={false}
        onSubmit={(values) => {
          setViewMode(true);
          onConfirm({ ...values, extrasConfirmed: true });
        }}>
        {({ values }) => (
          <Form>
            <table className={classes.table}>
              <thead className={classes.head}>
                <tr>
                  <th>#</th>
                  <th>Type</th>
                  <th>Description</th>
                  <th>Currency</th>
                  <th>Price Per Room</th>
                  <th>Price Per Person</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {values.extras.map((extra, idx) => (
                  <tr key={idx}>
                    <td className={classes.cell}>{idx + 1}</td>
                    <td className={classes.cell}>{extra.type || "N/A"}</td>
                    <td className={classes.cell}>
                      {viewMode ? (
                        extra.description
                      ) : (
                        <NormalInputField name={`extras.${idx}.description`} />
                      )}
                    </td>
                    <td className={classes.cell}>
                      {currency} ({_.get(currencies, currency)})
                    </td>
                    <td className={classes.pricecell}>
                      {viewMode ? (
                        extra.price_per_room
                      ) : (
                        <NormalInputField
                          type="number"
                          name={`extras.${idx}.price_per_room`}
                        />
                      )}
                    </td>
                    <td className={classes.pricecell}>
                      {viewMode ? (
                        extra.price_per_person
                      ) : (
                        <NormalInputField
                          type="number"
                          name={`extras.${idx}.price_per_person`}
                        />
                      )}
                    </td>
                    <td className={classes.cell}>
                      {viewMode ? (
                        <Button
                          appearance="ghost"
                          size="xs"
                          onClick={(e) => {
                            e.preventDefault();
                            setViewMode(false);
                            onConfirm({ ...values, extrasConfirmed: false });
                          }}>
                          <strong>Edit</strong>
                        </Button>
                      ) : (
                        <Button color="green" size="xs" type="submit">
                          <strong>Confirm</strong>
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Form>
        )}
      </Formik>
    </div>
  );
};
ExtrasInfo.propTypes = {
  confirmData: PropTypes.object.isRequired,
  currency: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default ExtrasInfo;
