import { InlineIcon } from "@iconify/react";
import _ from "lodash";
import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import { variables } from "@src/jsssetup";
import { WhisperWrapper } from "../common/ui_helpers";

const styles = createUseStyles({
  AvatarComp: (props) => ({
    display: "grid",
    gridTemplateColumns: props.moreThanTwo
      ? `${variables.normal_gap} max-content`
      : "max-content",
    alignItems: "center",
  }),
  userIconContr: {
    display: "grid",
    background: "#D9D9D9",
    borderRadius: "50%",
    height: `calc(${variables.normal_gap} * 2.5)`,
    width: `calc(${variables.normal_gap} * 2.5)`,
    placeItems: "center",
    border: `2px solid ${variables.colors.easy.orange}`,
    fontSize: "small",
  },
  userIcon: {
    display: "block",
    color: "white",
    fontSize: "medium",
  },
  participantsList: {
    textAlign: "left",
  },
});
export const AvatarComp = ({ members, moreThanTwo, toolTipPlacement }) => {
  const classes = styles({ moreThanTwo });

  return (
    <WhisperWrapper
      placement={toolTipPlacement}
      msg={
        <span className={classes.participantsList__}>
          {members.map((member, idx) => (
            <div key={idx}>
              {member.first_name && member.last_name
                ? `${member.first_name} ${member.last_name}`
                : member.email}
            </div>
          ))}
        </span>
      }>
      <span>
        <div className={classes.AvatarComp}>
          <div className={classes.userIconContr}>
            {_.get(members, "0.first_name", "") &&
            _.get(members, "0.last_name", "") ? (
              <React.Fragment>
                {_.get(members, "0.first_name", "").substring(0, 1)}
                {_.get(members, "0.last_name", "").substring(0, 1)}
              </React.Fragment>
            ) : (
              <InlineIcon icon="mdi:user" className={classes.userIcon} />
            )}
          </div>
          {moreThanTwo && (
            <div className={classes.userIconContr}>
              {_.get(members, "0.first_name", "") &&
              _.get(members, "0.first_name", "") ? (
                <React.Fragment>
                  {_.get(members, "0.first_name", "").substring(0, 1)}
                  {_.get(members, "0.last_name", "").substring(0, 1)}
                </React.Fragment>
              ) : (
                <InlineIcon icon="mdi:user" className={classes.userIcon} />
              )}
            </div>
          )}
        </div>
      </span>
    </WhisperWrapper>
  );
};
AvatarComp.propTypes = {
  toolTipPlacement: PropTypes.string,
  members: PropTypes.array.isRequired,
  moreThanTwo: PropTypes.bool.isRequired,
};
