import {
  accPrefAddRoom,
  accPrefRemoveRoom,
  accPrefRoomPaxAdd,
  accPrefRoomPaxRemove,
  accRoomPaxAgeChange,
  itineraryAddPax,
  itineraryChangePax,
  itineraryRemovePax,
  itnSelectExtremalDest,
  itnSelectOriginDate,
} from "@src/actions/Project/TripPlanner";
import { getSetupFormDataSelector } from "@src/selectors/Project/TripPlanner";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import {
  DatePicker,
  Icon,
  IconButton,
  Loader,
  Panel,
  Radio,
  RadioGroup,
  Slider,
} from "rsuite";
import { DestAutoComplete } from "../../Controllers/DestAutoComplete";
import _ from "lodash";
import { WhisperHelper } from "@src/components/common/ui_helpers";
import { recalculatePkg } from "@src/actions/Project/Packages/Wholesales";

class RecalcForm extends React.Component {
  constructor(props) {
    super(props);
    this.baseCls = "TravelPackage__overlay";
  }
  renderPaxSliderGroup(paxType) {
    const { adults, childs, onAddPax, onRemovePax, onChangePax } = this.props;

    return (
      <div className={`${this.baseCls}__recalcform__form__inputgroup`}>
        <span>
          <strong>
            {paxType == "adults" ? adults : childs} {_.startCase(paxType)}
          </strong>
        </span>
        <div
          className={`${this.baseCls}__recalcform__form__inputgroup__slidergroup`}>
          <IconButton
            icon={<Icon icon="minus" />}
            circle
            size="xs"
            onClick={function () {
              onRemovePax(paxType);
            }}
          />
          <Slider
            progress={true}
            min={0}
            max={9}
            value={paxType == "adults" ? adults : childs}
            onChange={function (value) {
              onChangePax(paxType, value);
            }}
          />
          <IconButton
            icon={<Icon icon="plus" />}
            circle
            size="xs"
            onClick={function () {
              onAddPax(paxType);
            }}
          />
        </div>
      </div>
    );
  }
  renderPaxSliders() {
    return (
      <React.Fragment>
        {this.renderPaxSliderGroup("adults")}
        {this.renderPaxSliderGroup("children")}
      </React.Fragment>
    );
  }
  renderRooms() {
    const { rooms, onRoomPaxAdd, onRoomPaxRemove, onRoomAgeChange } =
      this.props;

    return rooms.map((room, idx) => (
      <Panel
        key={idx}
        className={`${this.baseCls}__recalcform__rooms__room`}
        shaded
        header={<h6>Room {idx + 1}</h6>}>
        <div className={`${this.baseCls}__recalcform__rooms__room__paxtype`}>
          <IconButton
            icon={<Icon icon="minus" />}
            circle
            size="xs"
            onClick={function () {
              onRoomPaxRemove(idx, "adults");
            }}
          />
          <strong>Adults {room.adults}</strong>
          <IconButton
            icon={<Icon icon="plus" />}
            circle
            size="xs"
            onClick={function () {
              onRoomPaxAdd(idx, "adults");
            }}
          />
        </div>
        <div className={`${this.baseCls}__recalcform__rooms__room__paxtype`}>
          <IconButton
            icon={<Icon icon="minus" />}
            circle
            size="xs"
            onClick={function () {
              onRoomPaxRemove(idx, "children");
            }}
          />
          <strong>Children {room.children}</strong>
          <IconButton
            icon={<Icon icon="plus" />}
            circle
            size="xs"
            onClick={function () {
              onRoomPaxAdd(idx, "children");
            }}
          />
        </div>
        {room.children_ages.map((age, aidx) => (
          <div
            key={aidx}
            className={`${this.baseCls}__recalcform__rooms__room__childage`}>
            <span>
              <strong>
                Child {aidx + 1} Age: {age}
              </strong>
            </span>
            <Slider
              progress={true}
              min={0}
              max={12}
              value={age}
              onChange={function (value) {
                onRoomAgeChange(idx, aidx, value);
              }}
            />
          </div>
        ))}
      </Panel>
    ));
  }
  render() {
    const {
      startDate,
      rooms,
      originRequired,
      requiredServices,
      onGoToOverview,
      onOriginSelect,
      onToggleOriginRequired,
      onRemoveRoom,
      onAddRoom,
      onRecalculate,
      onDateChange,
    } = this.props;

    return (
      <div className="TravelPackage__overlay">
        <Panel className={`${this.baseCls}__recalcform`} shaded>
          <IconButton
            className={`${this.baseCls}__recalcform__closebtn`}
            icon={<Icon icon="close" />}
            circle
            size="xs"
            onClick={onGoToOverview}
          />
          <div className={`${this.baseCls}__recalcform__form`}>
            <div className={`${this.baseCls}__recalcform__form__inputgroup`}>
              <span>
                <strong>Transport From</strong>{" "}
                <WhisperHelper msg="A nice text to explain what happens" />
              </span>
              <DestAutoComplete
                lang="en"
                onSelect={onOriginSelect}
                disabled={!originRequired}
              />
              <RadioGroup
                name="originRequired"
                inline
                value={originRequired}
                onChange={function () {
                  onToggleOriginRequired();
                }}>
                <Radio value={false}>Not Required</Radio>
                <Radio value={true}>Required</Radio>
              </RadioGroup>
            </div>
            <div className={`${this.baseCls}__recalcform__form__inputgroup`}>
              <span>
                <strong>Departure Date</strong>
              </span>
              <DatePicker
                format="DD/MM/YYYY"
                value={moment.parseZone(startDate).toDate()}
                onChange={(date) => onDateChange(moment(date))}
              />
            </div>
            {this.renderPaxSliders()}
          </div>
          {requiredServices.includes("ACC") ? (
            <div className={`${this.baseCls}__recalcform__rooms`}>
              <span>
                <strong>Rooms:</strong>
                <IconButton
                  icon={<Icon icon="minus" />}
                  circle
                  size="xs"
                  onClick={onRemoveRoom}
                />
                <strong>{rooms.length}</strong>
                <IconButton
                  icon={<Icon icon="plus" />}
                  circle
                  size="xs"
                  onClick={onAddRoom}
                />
              </span>
              <div className={`${this.baseCls}__recalcform__rooms__roomslist`}>
                {this.renderRooms()}
              </div>
            </div>
          ) : null}
          <IconButton
            className={`${this.baseCls}__recalcform__searchbtn`}
            icon={<Icon icon="search" />}
            onClick={onRecalculate}>
            <strong>Refresh Price & Services</strong>
          </IconButton>
        </Panel>
      </div>
    );
  }
}

RecalcForm.propTypes = {
  startDate: PropTypes.string.isRequired,
  rooms: PropTypes.array.isRequired,
  requiredServices: PropTypes.array.isRequired,
  adults: PropTypes.number.isRequired,
  childs: PropTypes.number.isRequired,
  originRequired: PropTypes.bool.isRequired,
  onGoToOverview: PropTypes.func.isRequired,
  onOriginSelect: PropTypes.func.isRequired,
  onToggleOriginRequired: PropTypes.func.isRequired,
  onAddPax: PropTypes.func.isRequired,
  onRemovePax: PropTypes.func.isRequired,
  onChangePax: PropTypes.func.isRequired,
  onAddRoom: PropTypes.func.isRequired,
  onRemoveRoom: PropTypes.func.isRequired,
  onRoomPaxAdd: PropTypes.func.isRequired,
  onRoomPaxRemove: PropTypes.func.isRequired,
  onRoomAgeChange: PropTypes.func.isRequired,
  onRecalculate: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
};

class LoadingOverlay extends React.Component {
  constructor(props) {
    super(props);
    this.baseCls = "TravelPackage__overlay";
  }
  render() {
    const { msg } = this.props;

    return (
      <div className={this.baseCls}>
        <Panel shaded className={`${this.baseCls}__loading`}>
          <Loader size="lg" content={msg} vertical />;
        </Panel>
      </div>
    );
  }
}

LoadingOverlay.defaultProps = {
  msg: "Please Wait...",
};

LoadingOverlay.propTypes = {
  msg: PropTypes.string.isRequired,
};

const bookFlowHoc = (WrappedComponent) => {
  class Comp extends React.Component {
    constructor(props) {
      super(props);
      this.state = { step: "overview", originRequired: false, loading: false };

      this.toggleOriginRequired = this.toggleOriginRequired.bind(this);
      this.handleGoToRecalculate = this.handleGoToRecalculate.bind(this);
      this.handleGoToOverview = this.handleGoToOverview.bind(this);
      this.renderRecalcOverlay = this.renderRecalcOverlay.bind(this);
      this.renderLoadingOverlay = this.renderLoadingOverlay.bind(this);
      this.setLoading = this.setLoading.bind(this);
      this.setIdle = this.setIdle.bind(this);
      this.recalculate = this.recalculate.bind(this);
    }
    toggleOriginRequired() {
      this.setState((p) => ({ ...p, originRequired: !p.originRequired }));
    }
    handleGoToOverview() {
      this.setState((p) => ({ ...p, step: "overview" }));
    }
    handleGoToRecalculate() {
      this.setState((p) => ({ ...p, step: "recalculate" }));
    }
    async setLoading() {
      await this.setState((p) => ({ ...p, loading: true }));
    }
    setIdle() {
      this.setState((p) => ({ ...p, loading: false }));
    }
    async recalculate() {
      const { onRecalculate } = this.props;
      await onRecalculate();
    }
    renderRecalcOverlay() {
      const { originRequired } = this.state;
      const {
        startDate,
        rooms,
        requiredServices,
        adults,
        children,
        onOriginSelect,
        onAddPax,
        onRemovePax,
        onChangePax,
        onAddRoom,
        onRemoveRoom,
        onRoomPaxAdd,
        onRoomPaxRemove,
        onRoomAgeChange,
        onDateChange,
      } = this.props;

      return (
        <RecalcForm
          startDate={startDate}
          rooms={rooms}
          requiredServices={requiredServices}
          adults={adults}
          childs={children}
          originRequired={originRequired}
          onToggleOriginRequired={this.toggleOriginRequired}
          onGoToOverview={this.handleGoToOverview}
          onOriginSelect={onOriginSelect}
          onAddPax={onAddPax}
          onRemovePax={onRemovePax}
          onChangePax={onChangePax}
          onAddRoom={onAddRoom}
          onRemoveRoom={onRemoveRoom}
          onRoomPaxAdd={onRoomPaxAdd}
          onRoomPaxRemove={onRoomPaxRemove}
          onRoomAgeChange={onRoomAgeChange}
          onRecalculate={this.recalculate}
          onDateChange={onDateChange}
        />
      );
    }
    renderLoadingOverlay() {
      return <LoadingOverlay />;
    }
    render() {
      const { step, loading } = this.state;
      return (
        <WrappedComponent
          {...this.props}
          step={step}
          loading={loading}
          onGoToRecalculate={this.handleGoToRecalculate}
          renderRecalcOverlay={this.renderRecalcOverlay}
          renderLoadingOverlay={this.renderLoadingOverlay}
        />
      );
    }
  }

  Comp.propTypes = {
    startDate: PropTypes.string.isRequired,
    rooms: PropTypes.array.isRequired,
    requiredServices: PropTypes.array.isRequired,
    adults: PropTypes.number.isRequired,
    children: PropTypes.number.isRequired,
    onOriginSelect: PropTypes.func.isRequired,
    onAddPax: PropTypes.func.isRequired,
    onRemovePax: PropTypes.func.isRequired,
    onChangePax: PropTypes.func.isRequired,
    onAddRoom: PropTypes.func.isRequired,
    onRemoveRoom: PropTypes.func.isRequired,
    onRoomPaxAdd: PropTypes.func.isRequired,
    onRoomPaxRemove: PropTypes.func.isRequired,
    onRoomAgeChange: PropTypes.func.isRequired,
    onRecalculate: PropTypes.func.isRequired,
    onDateChange: PropTypes.func.isRequired,
  };

  const mapStateToProps = (state) => {
    const { requiredServices, adults, children } =
      getSetupFormDataSelector(state);

    const rooms = state.tripPlannerAccPax;

    const { date: startDate } = state.tripPlannerOriginData;

    return {
      requiredServices,
      adults,
      children,
      rooms,
      startDate,
    };
  };

  const mapDispatchToProps = (dispatch) => {
    return {
      onOriginSelect: (id, lvl, query) =>
        dispatch(itnSelectExtremalDest(id, lvl, query, "origin")),
      onAddPax: (paxType) => dispatch(itineraryAddPax(paxType)),
      onRemovePax: (paxType) => dispatch(itineraryRemovePax(paxType)),
      onChangePax: (paxType, pax) => dispatch(itineraryChangePax(paxType, pax)),
      onAddRoom: () => dispatch(accPrefAddRoom()),
      onRemoveRoom: () => dispatch(accPrefRemoveRoom()),
      onRoomPaxAdd: (roomIdx, paxType) =>
        dispatch(accPrefRoomPaxAdd(roomIdx, paxType)),
      onRoomPaxRemove: (roomIdx, paxType) =>
        dispatch(accPrefRoomPaxRemove(roomIdx, paxType)),
      onRoomAgeChange: (roomIdx, childIdx, age) =>
        dispatch(accRoomPaxAgeChange(roomIdx, childIdx, age)),
      onRecalculate: () => dispatch(recalculatePkg()),
      onDateChange: (date) => dispatch(itnSelectOriginDate(date)),
    };
  };

  return connect(mapStateToProps, mapDispatchToProps)(Comp);
};

export default bookFlowHoc;
