import _ from "lodash";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { variables } from "@src/jsssetup";
import { boardMapping } from "@src/config/common";

const customerInfoStyles = StyleSheet.create({
  offerPrice: { fontSize: 10 },
  offerTitle: { fontSize: 16, paddingLeft: 5 },
  basicInfoField: {
    flexDirection: "row",
    paddingLeft: 5,
    justifyContent: "space-between",
    padding: "5",
  },
});
export const CustomerInfo = (props) => {
  const offer_title = props?.data?.offer_title;
  const first_name = props?.data.first_name;
  const last_name = props?.data.last_name;
  const email = props?.data.email;

  return (
    <View style={customerInfoStyles.offerPrice}>
      {offer_title ? (
        <View style={customerInfoStyles.offerTitle}>
          <Text>{offer_title}</Text>
        </View>
      ) : (
        <View>
          <Text></Text>
        </View>
      )}
      {first_name ? (
        <View style={customerInfoStyles.basicInfoField}>
          <Text>First Name: </Text>
          <View>
            <Text style={customerInfoStyles.priceTxt}>{first_name}</Text>
          </View>
        </View>
      ) : (
        <View>
          <Text></Text>
        </View>
      )}
      {last_name ? (
        <View style={customerInfoStyles.basicInfoField}>
          <Text>Last Name: </Text>
          <Text>{last_name}</Text>
        </View>
      ) : (
        <View>
          <Text></Text>
        </View>
      )}
      {email ? (
        <View style={customerInfoStyles.basicInfoField}>
          <Text>Email: </Text>
          <Text>{email}</Text>
        </View>
      ) : (
        <View>
          <Text></Text>
        </View>
      )}
    </View>
  );
};

const offerPriceStyles = StyleSheet.create({
  offerPrice: {
    fontSize: 10,
  },
  priceInfo: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 5,
  },
  totalPrice: {
    fontSize: 10,
    padding: "2",
    backgroundColor: "#4caf50",
    color: "#fff",
    borderRadius: 2,
    paddingLeft: 5,
    paddingRight: 5,
  },
  perPrice: {
    fontSize: 10,
    padding: "2",
    backgroundColor: "orange",
    color: "#fff",
    borderRadius: 2,
    paddingLeft: 5,
    paddingRight: 5,
  },
});
export const OfferPrice = ({
  // currencyArr,
  // company_currency,
  totalSellingPrice,
  totalSellingPricePerPeson,
}) => {
  return (
    <View style={offerPriceStyles.offerPrice}>
      <View style={offerPriceStyles.priceInfo}>
        <Text>Total Price: </Text>
        <View style={offerPriceStyles.totalPrice}>
          <Text>
            {/* getCurrencySymbol(company_currency, currencyArr)}{" " */}
            {totalSellingPrice}
          </Text>
        </View>
      </View>
      <View style={offerPriceStyles.priceInfo}>
        <Text>Per Person Price: </Text>
        <View style={offerPriceStyles.perPrice}>
          <Text>
            {/* getCurrencySymbol(company_currency, currencyArr)}{" " */}
            {totalSellingPricePerPeson}
          </Text>
        </View>
      </View>
    </View>
  );
};
OfferPrice.propTypes = {
  currencyArr: PropTypes.array.isRequired,
  company_currency: PropTypes.string.isRequired,
  totalSellingPrice: PropTypes.number.isRequired,
  totalSellingPricePerPeson: PropTypes.number.isRequired,
};

const tripMetaStyles = StyleSheet.create({
  TripMeta: {
    width: 550,
    margin: "10px 0",
    flexDirection: "column",
  },
  info: {
    fontSize: "10",
    marginTop: 5,
    marginRight: 5,
  },
  infoHeader: {
    fontWeight: "1000",
  },
});
export const TripMeta = ({
  configForm,
  start,
  end,
  external_reference,
  reference,
}) => {
  start = DateTime.fromISO(start);
  end = DateTime.fromISO(end);
  const stay = end.diff(start, "days").as("days");

  const start_dt = start
    .setLocale("en-US")
    .toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
  const end_dt = end
    .setLocale("en-US")
    .toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);

  return (
    <View style={tripMetaStyles.TripMeta}>
      {configForm.offer_title !== "" && (
        <View style={tripMetaStyles.info}>
          <Text>Tour Name: {configForm.offer_title},</Text>
        </View>
      )}
      <View style={tripMetaStyles.info}>
        <Text>
          Start: {start_dt} - End: {end_dt} ({stay} Nights),
        </Text>
      </View>
      {(configForm?.reference_type === "internal_only" ||
        configForm?.reference_type === "both") && (
        <View style={tripMetaStyles.info}>
          <Text>Reference: {reference ?? "N/A"}</Text>
        </View>
      )}
      {(configForm?.reference_type === "external_only" ||
        configForm?.reference_type === "both") && (
        <View style={tripMetaStyles.info}>
          <Text>External Reference: {external_reference ?? "N/A"}</Text>
        </View>
      )}
    </View>
  );
};
TripMeta.propTypes = {
  configForm: PropTypes.object.isRequired,
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  external_reference: PropTypes.string,
  reference: PropTypes.string,
};

const descriptionStyles = StyleSheet.create({
  Description: {
    marginTop: 10,
    marginBottom: 5,
    border: "2px solid #b7cade",
    borderRadius: 5,
    fontFamily: "Roboto",
    fontSize: 10,
    padding: 5,
  },
  header: {
    fontFamily: "Roboto",
    fontSize: 12,
    marginBottom: 5,
  },
});
export const Description = (props) => {
  const { description } = props;
  return (
    <View style={descriptionStyles.Description} wrap={false}>
      <Text style={descriptionStyles.header}>Description</Text>
      <Text>{description}</Text>
    </View>
  );
};
Description.propTypes = {
  description: PropTypes.string.isRequired,
  styles: PropTypes.object.isRequired,
};

const serviceSummaryStyles = StyleSheet.create({
  ServiceSummary: {
    width: 550,
    flexDirection: "column",
    fontSize: 10,
  },
  firstRow: {
    flexDirection: "row",
    backgroundColor: "#302A83",
    border: "1px solid #302A83",
    borderRadius: "5px",
    color: "white",
    fontFamily: "Helvetica-Bold",
    marginBottom: 5,
    padding: 8,
  },
  row: {
    flexDirection: "row",
    borderBottom: "1px solid black",
  },
  firstCell: {
    width: 150,
  },
  firstDescriptionCell: {
    width: 400,
  },
  cell: {
    padding: 5,
    borderRight: "1px solid black",
    width: 150,
  },
  descriptionCell: {
    width: 400,
    padding: 5,
  },
  table: {
    border: "1px solid black",
    borderRadius: "5px",
  },
  lastRow: {
    flexDirection: "row",
    borderBottom: "none",
  },
});
export const ServiceSummary = ({
  services,
  selectedSrvs,
  remarks,
  destinations,
}) => {
  const trpMapping = {
    COO: "Van",
    COA: "Coach",
    FL: "Flight",
    FER: "Ferry",
    TRA: "Train",
  };
  const accs = services
    .filter((srv) => srv.service_type === "ACC")
    .filter((acc) => selectedSrvs.has(`ACC___${acc.uid}`));
  const accsNo = accs.length;

  var accNights = accs.reduce((a, acc) => {
    var cin = acc?.operational_data?.check_in;
    var cout = acc?.operational_data?.check_out;
    if (!cin || !cout) {
      cin = acc?.check_in;
      cout = acc?.check_out;
    }
    cin = DateTime.fromISO(cin);
    cout = DateTime.fromISO(cout);
    return a + Math.ceil(cout.diff(cin, "days").as("days"));
  }, 0);

  const meals = [
    ...new Set(_.flatten(accs.map((srv) => srv.rooms.map((r) => r.board)))),
  ].map((board) => boardMapping[board]);

  const coords = services
    .filter((srv) => srv.custom_service_type === "COO")
    .filter((srv) => selectedSrvs.has(`COO___${srv?.uid}`));

  const driver_guides = coords.filter((srv) => srv.specialty.includes("DRV"));
  const driver = coords.filter((srv) => srv.specialty.includes("DRI"));

  const driverCoordDays = _.flattenDeep(
    driver.map((srv) => srv.daily_cost.map((co) => co.date))
  );
  const driverCoordDests = _.flattenDeep(
    driver.map((srv) => srv.destinations.map((dest) => dest.name_en))
  );

  const driverGuidesCoordDays = _.flattenDeep(
    driver_guides.map((srv) => srv.daily_cost.map((co) => co.date))
  );
  const driverGuidesCoordDests = _.flattenDeep(
    driver_guides.map((srv) => srv.destinations.map((dest) => dest.name_en))
  );

  const trains = services
    .filter((srv) => srv.transportation_service_type === "TRA")
    .filter((srv) => selectedSrvs.has(`TRA___${srv.service.uid}`));
  const ferries = services
    .filter((srv) => srv.transportation_service_type === "FER")
    .filter((srv) => selectedSrvs.has(`FER___${srv.service.uid}`));
  const flights = services
    .filter((srv) => srv.transportation_service_type === "FL")
    .filter((srv) => selectedSrvs.has(`FL___${srv.uid}`));
  const trpModes = [
    ...flights,
    ...ferries,
    ...trains,
    ...coords.filter((srv) =>
      ["DRV", "DRI"].some((sp) => srv.specialty.includes(sp))
    ),
  ];

  const genSrvs = services
    .filter((srv) => srv.custom_service_type === "GEN")
    .filter((srv) => selectedSrvs.has(`GEN___${srv.id}`));

  const guides = genSrvs.filter((srv) => srv.service_data.type === "GUI");
  const validGuides = Object.entries(
    _.groupBy(
      guides.map((gd) => gd?.service_data?.destination_meta?.name_en ?? "")
    )
  ).map(([dest, dates]) => `${dest} (${dates.length} services)`);
  const transfers = genSrvs
    .filter((srv) => srv.service_data.type === "TRF")
    .filter((srv) => selectedSrvs.has(`TRF___${srv.booking_id}`));

  const tips = services.filter((srv) => srv.addhoc_service_type === "TIP");

  const rests = services
    .filter((srv) => srv.custom_service_type === "REST")
    .filter((srv) => selectedSrvs.has(`REST___${srv.reference}`));
  const restaurants = Object.entries(
    _.groupBy(rests.map((rest) => rest.main_cms_destination_meta.name_en))
  ).map((entry) => `${entry[0]} (${entry[1].length})`);

  const activities = _.cloneDeep(
    services
      .filter((srv) => srv.service_type === "ACT")
      .filter((srv) => selectedSrvs.has(`ACT___${srv.uid}`))
  );
  activities.forEach((act) => {
    const destination = destinations.find(
      (dest) => `${dest.type}__${dest.id}` === act.destination_id
    );
    if (destination) {
      act.destination = destination;
    }
  });
  const actNo = activities.length;

  const transportationModes = [
    ...new Set(
      trpModes.map((m) => {
        if (m?.custom_service_type === "COO") return trpMapping["COO"];
        return trpMapping[m.transportation_service_type];
      })
    ),
  ];
  const generalServiceTransfers = [
    ...new Set(
      transfers
        .map((trf) => {
          if (trf?.custom_service_type === "GEN")
            return trf?.service_data?.destination_meta.name_en;
          return null;
        })
        .filter((trf) => trf)
    ),
  ];

  function findLastRowKey() {
    // Define an array of conditions and corresponding keys
    const conditions = [
      { condition: accsNo > 0, key: "Accommodation" },
      {
        condition: transportationModes.length > 0,
        key: "Transportation Modes",
      },
      { condition: generalServiceTransfers.length > 0, key: "Transfers" },
      { condition: activities.length > 0, key: "Activities" },
      {
        condition: driverCoordDays.length > 0,
        key: "Daily Vehicle",
      },
      { condition: driverGuidesCoordDays.length > 0, key: "Driver Guide" },
      { condition: validGuides.length > 0, key: "Guide Service" },
      { condition: restaurants.length > 0, key: "Meals" },
      { condition: tips.length > 0, key: "Tipping" },
      { condition: remarks !== "", key: "Remarks" },
    ];

    for (let i = conditions.length - 1; i >= 0; i--) {
      if (conditions[i].condition) {
        return conditions[i].key;
      }
    }

    return "";
  }
  const [lastRow, setLastRow] = useState("");
  useEffect(() => {
    setLastRow(findLastRowKey());
  }, []);

  return (
    <View style={serviceSummaryStyles.ServiceSummary}>
      <View style={serviceSummaryStyles.firstRow}>
        <View style={serviceSummaryStyles.firstCell}>
          <Text>Service Type</Text>
        </View>
        <View style={serviceSummaryStyles.firstDescriptionCell}>
          <Text>Summary </Text>
        </View>
      </View>
      <View style={serviceSummaryStyles.table}>
        {accsNo > 0 && (
          <View
            style={
              lastRow === "Accommodation"
                ? serviceSummaryStyles.lastRow
                : serviceSummaryStyles.row
            }>
            <View style={serviceSummaryStyles.cell}>
              <Text>Accommodation</Text>
            </View>
            <View style={serviceSummaryStyles.descriptionCell}>
              <Text>
                {accsNo} Hotels, {accNights} Nights, Meal Plans:{" "}
                {meals.join(", ")}
              </Text>
            </View>
          </View>
        )}
        {transportationModes.length > 0 && (
          <View
            style={
              lastRow === "Transportation Modes"
                ? serviceSummaryStyles.lastRow
                : serviceSummaryStyles.row
            }>
            <View style={serviceSummaryStyles.cell}>
              <Text>Transportation Modes</Text>
            </View>
            <View style={serviceSummaryStyles.descriptionCell}>
              <Text>{transportationModes.join(", ")}</Text>
            </View>
          </View>
        )}
        {generalServiceTransfers.length > 0 && (
          <View
            style={
              lastRow === "Transfers"
                ? serviceSummaryStyles.lastRow
                : serviceSummaryStyles.row
            }>
            <View style={serviceSummaryStyles.cell}>
              <Text>Transfers</Text>
            </View>
            <View style={serviceSummaryStyles.descriptionCell}>
              <Text>{generalServiceTransfers.join(", ")}</Text>
            </View>
          </View>
        )}
        {activities.length > 0 && (
          <View
            style={
              lastRow === "Activities"
                ? serviceSummaryStyles.lastRow
                : serviceSummaryStyles.row
            }>
            <View style={serviceSummaryStyles.cell}>
              <Text>Activities</Text>
            </View>
            <View style={serviceSummaryStyles.descriptionCell}>
              <Text>
                {actNo}{" "}
                {`${actNo > 1 ? "Activities" : "Activity"} in ${Object.entries(
                  _.groupBy(activities, (v) => v.destination.name_en)
                )
                  .map(([dest, items]) => `${dest} (${items.length})`)
                  .join(", ")}`}
              </Text>
            </View>
          </View>
        )}
        {driverCoordDays.length > 0 && (
          <View
            style={
              lastRow === "Daily Vehicle"
                ? serviceSummaryStyles.lastRow
                : serviceSummaryStyles.row
            }>
            <View style={serviceSummaryStyles.cell}>
              <Text>Daily Vehicle (with Driver)</Text>
            </View>
            <View style={serviceSummaryStyles.descriptionCell}>
              <Text>
                {driverCoordDays.length} Days, {driverCoordDests.join(", ")}
              </Text>
            </View>
          </View>
        )}
        {driverGuidesCoordDays.length > 0 && (
          <View
            style={
              lastRow === "Driver Guide"
                ? serviceSummaryStyles.lastRow
                : serviceSummaryStyles.row
            }>
            <View style={serviceSummaryStyles.cell}>
              <Text>Driver Guide</Text>
            </View>
            <View style={serviceSummaryStyles.descriptionCell}>
              <Text>
                {driverGuidesCoordDays.length} Days,{" "}
                {driverGuidesCoordDests.join(", ")}
              </Text>
            </View>
          </View>
        )}
        {validGuides.length > 0 && (
          <View
            style={
              lastRow === "Guide Service"
                ? serviceSummaryStyles.lastRow
                : serviceSummaryStyles.row
            }>
            <View style={serviceSummaryStyles.cell}>
              <Text>Guide Service</Text>
            </View>
            <View style={serviceSummaryStyles.descriptionCell}>
              <Text>{validGuides.join(", ")}</Text>
            </View>
          </View>
        )}
        {restaurants.length > 0 && (
          <View
            style={
              lastRow === "Meals"
                ? serviceSummaryStyles.lastRow
                : serviceSummaryStyles.row
            }>
            <View style={serviceSummaryStyles.cell}>
              <Text>Meals</Text>
            </View>
            <View style={serviceSummaryStyles.descriptionCell}>
              <Text>{restaurants.join(", ")}</Text>
            </View>
          </View>
        )}
        {tips.length > 0 && (
          <View
            style={
              lastRow === "Tipping"
                ? serviceSummaryStyles.lastRow
                : serviceSummaryStyles.row
            }>
            <View style={serviceSummaryStyles.cell}>
              <Text>Tipping</Text>
            </View>
            <View style={serviceSummaryStyles.descriptionCell}>
              <Text>{tips.length > 0 ? "Included" : "Excluded"}</Text>
            </View>
          </View>
        )}
        {remarks !== "" && (
          <View
            style={
              lastRow === "Remarks"
                ? serviceSummaryStyles.lastRow
                : serviceSummaryStyles.row
            }>
            <View style={serviceSummaryStyles.cell}>
              <Text>Remarks</Text>
            </View>
            <View style={serviceSummaryStyles.descriptionCell}>
              <Text>{remarks}</Text>
            </View>
          </View>
        )}
      </View>
    </View>
  );
};
ServiceSummary.defaultProps = {
  remarks: "",
  services: [],
  destinations: [],
};
ServiceSummary.propTypes = {
  remarks: PropTypes.string.isRequired,
  services: PropTypes.array.isRequired,
  selectedSrvs: PropTypes.array.isRequired,
  destinations: PropTypes.array.isRequired,
};

export function getAccBoardDisplay(rooms) {
  const boards = [...new Set(rooms.map((r) => r.board || r.board_code))]
    .map((b) => boardMapping?.[b] ?? "")
    .join(", ");
  return boards;
}

const accSummaryStyles = StyleSheet.create({
  AccSummary: {
    borderRadius: "5px",
    width: 550,
    flexDirection: "column",
    fontSize: 10,
  },
  firstRow: {
    flexDirection: "row",
    alignContent: "center",
    borderRadius: "5px",
    border: "1px solid #302A83",
    backgroundColor: "#302A83",
    color: "white",
    padding: 8,
    fontFamily: "Helvetica-Bold",
  },
  row: {
    flexDirection: "row",
    paddingLeft: 8,
    paddingRight: 5,
    paddingBottom: 5,
    paddingTop: 5,
  },
  mainColumn: { width: 90 },
  secondColumn: { width: 160 },
  thirdColumn: { width: 70 },
  fourthColumn: { width: 100 },
  fifthColumn: { width: 60 },
  sixthColumn: { width: 100 },
  cellTxt: {},
});
export const AccSummary = ({ accs, destinations }) => {
  const destAccs = destinations
    .filter((dest) => dest.dest_type === "NOR")
    .map((dest) => [
      dest,
      accs.find((acc) => acc.destOrder.toString() === dest.order.toString()),
    ]);

  function makeHyphenBreakable(text) {
    return text.replace(/-/g, "\u00AD");
  }

  return (
    <View style={accSummaryStyles.AccSummary}>
      <View style={accSummaryStyles.firstRow}>
        <View style={accSummaryStyles.mainColumn}>
          <Text style={accSummaryStyles.headerCellTxt}>Destination</Text>
        </View>
        <View style={accSummaryStyles.secondColumn}>
          <Text style={accSummaryStyles.headerCellTxt}>Hotel Name</Text>
        </View>
        <View style={accSummaryStyles.thirdColumn}>
          <Text style={accSummaryStyles.headerCellTxt}>Rooms</Text>
        </View>
        <View style={accSummaryStyles.fourthColumn}>
          <Text style={accSummaryStyles.headerCellTxt}>Check In</Text>
        </View>
        <View style={accSummaryStyles.fifthColumn}>
          <Text style={accSummaryStyles.headerCellTxt}>Stay</Text>
        </View>
        <View style={accSummaryStyles.sixthColumn}>
          <Text style={accSummaryStyles.headerCellTxt}>Meal Plan</Text>
        </View>
      </View>
      {destAccs
        .filter(([__, acc]) => acc)
        .map(([dest, acc], idx) => {
          const acc_srv_type = acc?.accommodation_service_type;
          var validRooms = acc?.detailed_rooms ?? [];
          if (!validRooms.length) validRooms = acc.rooms || [];
          // Ensure we are keeping only selected rooms in case of a
          // hotel coming through an API feed.
          if (acc_srv_type === "ACC")
            validRooms = validRooms.filter((r) => r.selected);

          var check_in = acc.check_in;
          var check_out = acc.check_out;
          if (acc_srv_type === "ACC") {
            check_in = acc?.operational_data?.check_in;
            check_out = acc?.operational_data?.check_out;
          }

          return (
            <View key={idx} style={accSummaryStyles.row}>
              <View style={accSummaryStyles.mainColumn}>
                <Text key={idx} style={accSummaryStyles.cellTxt}>
                  {makeHyphenBreakable(dest.name_en)}
                </Text>
              </View>
              <View style={accSummaryStyles.secondColumn}>
                <Text key={idx} style={accSummaryStyles.cellTxt}>
                  {acc_srv_type === "CU"
                    ? acc.ext_accommodation_name
                    : ["ADD", "ACC"].includes(acc_srv_type)
                    ? acc.metadata.name
                    : ""}
                </Text>
              </View>
              <View style={accSummaryStyles.thirdColumn}>
                {validRooms.length ? (
                  Object.entries(_.groupBy(validRooms, "bedding")).map(
                    ([rtype, rooms], ridx) => (
                      <Text key={ridx} style={accSummaryStyles.cellTxt}>
                        {rooms.length} {_.startCase(rtype)}
                      </Text>
                    )
                  )
                ) : (
                  <Text key={idx} style={accSummaryStyles.cellTxt}>
                    N/A
                  </Text>
                )}
              </View>
              <View style={accSummaryStyles.fourthColumn}>
                <Text key={idx} style={accSummaryStyles.cellTxt}>
                  {DateTime.fromISO(check_in)
                    .setLocale("en-US")
                    .toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
                </Text>
              </View>
              <View style={accSummaryStyles.fifthColumn}>
                <Text key={idx} style={accSummaryStyles.cellTxt}>
                  {Math.ceil(
                    DateTime.fromISO(check_out).diff(
                      DateTime.fromISO(check_in),
                      "days"
                    ).days
                  )}{" "}
                  Nights
                </Text>
              </View>
              <View style={accSummaryStyles.sixthColumn}>
                <Text key={idx} style={accSummaryStyles.cellTxt}>
                  {getAccBoardDisplay(validRooms) || "N/A"}
                </Text>
              </View>
            </View>
          );
        })}
    </View>
  );
};
AccSummary.defaultProps = {
  destinations: [],
  accs: [],
};
AccSummary.propTypes = {
  destinations: PropTypes.array.isRequired,
  accs: PropTypes.array.isRequired,
};

export * from "./day_by_day_content";
