import {
  // initialSuppliersColumnsSetUp,
  // initialSuppliersFilterForm,
  initialSuppliersBaseDataForm,
} from "@src/forms/Network/Suppliers";

import { Schema } from "rsuite";

import {
  SUPPLIERS_BEGIN_FETCH_DATA,
  PROVIDERS_LIST_SAVE_DATA,
  SUPPLIERS_END_FETCH_DATA,
  // SUPPLIERS_UPDATE_LIST_FILTER_FORM,
  // SUPPLIERS_APPLY_LIST_FILTER,
  // SUPPLIERS_INITIALIZE_LIST_FILTER_FORM,
  // SUPPLIERS_UPDATE_LIST_COLUMNS_FORM,
  // SUPPLIERS_APPLY_LIST_COLUMNS,
  // SUPPLIERS_INITIALIZE_LIST_COLUMNS_FORM,
  SUPPLIERS_FETCH_OPTIONS,
  SUPPLIERS_SHOW_BASE_DATA_EDIT_FORM,
  SUPPLIERS_HIDE_BASE_DATA_EDIT_FORM,
  SUPPLIERS_EDIT_BASE_DATA,
  SUPPLIERS_INITIALIZE_BASE_DATA_EDIT_FORM,
  SUPPLIERS_CLEAN_BASE_DATA_EDIT_FORM,
  SUPPLIERS_UPLOADING_BASE_DATA_EDIT_FORM,
  SUPPLIERS_IDLE_BASE_DATA_EDIT_FORM,
  SUPPLIERS_VALIDATE_BASE_DATA_EDIT_FORM,
  SUPPLIERS_CLEAN_BASE_EDIT_FORM_ERRORS,
  SUPPLIERS_PROFILE_DATA,
  SUPPLIERS_TOGGLE_POSTAL_ADDRESS,
  SUPPLIERS_RESET_LIST_FILTER_FORM,
  //geo
  SUPPLIERS_FETCH_ADDRESS_POINT,
  SUPPLIERS_INITIAL_ADDRESS_POINT,
  SUPPLIERS_CLEAN_ADDRESS_POINT,
  SUPPLIERS_TOGGLE_VERIFY_ADDRESS,
  SUPPLIERS_INITIAL_VERIFY_STATUS,
  SUPPLIERS_CLOSE_VERIFY_ADDRESS,
  SUPPLIERS_BEGIN_FETCH_ADDRESS_DATA,
  SUPPLIERS_END_FETCH_ADDRESS_DATA,
  SUPPLIERS_LOADING_PROFILE_VIEW,
  SUPPLIERS_LOADED_PROFILE_VIEW,
  SUPPLIERS_UPLOAD_PREFERENCE_IMAGE,
  //================TABS=============
  SUPPLIERS_NAV_TAB_INIT,
  SUPPLIERS_CHANGE_NAV_TAB,
  SUPPLIERS_SAVE_PROPERTY,
  SUPPLIERS_LOADING_PROPERTY_VIEW,
  SUPPLIERS_LOADED_PROPERTY_VIEW,
  //=====================ADD PROPERTY MODAL======================
  SUPPLIERS_INIT_PROPERTY_MODAL,
  SUPPLIERS_SHOW_PROPERTY_MODAL,
  SUPPLIERS_HIDE_PROPERTY_MODAL,
  SUPPLIERS_LOADING_PROPERTY_MODAL,
  SUPPLIERS_UPDATE_PROPERTY_MODAL_FORM,
  SUPPLIERS_LOADED_PROPERTY_MODAL,
  SUPPLIERS_SAVE_PROPERTY_DETAIL,
  PROVIDERS_LIST_SIMPLE_CHANGE,
} from "@src/actions/Network/Suppliers/types";

import {
  FORMAT_TRAVEL_SERVICES_OPTIONS,
  // FORMAT_TRAVEL_SERVICES_FORM,
} from "@src/actions/Shares/types";

// import { formProcessor, entitiesListSelector } from "@src/reducers/tools";

import update from "immutability-helper";
import _ from "lodash";
import { commonList } from "@src/reducers/common";

// suppliers profile
export const suppliersBaseDataForm = (
  state = initialSuppliersBaseDataForm,
  action
) => {
  switch (action.type) {
    case SUPPLIERS_INITIALIZE_BASE_DATA_EDIT_FORM:
      return action.data;
    case SUPPLIERS_CLEAN_BASE_DATA_EDIT_FORM:
      return initialSuppliersBaseDataForm;
    case SUPPLIERS_EDIT_BASE_DATA:
      return update(state, {
        [action.data.name]: { value: { $set: action.data.value } },
      });
    case SUPPLIERS_UPLOAD_PREFERENCE_IMAGE:
      return update(state, {
        [action.data.name]: {
          value: { $set: action.data.value },
          fileName: { $set: action.data.fileName },
        },
      });
    default:
      return state;
  }
};

export const initialSuppliersBaseDataFormErrorsState = { valid: false };
export const suppliersBaseDataFormErrors = (
  state = initialSuppliersBaseDataFormErrorsState,
  action
) => {
  const tmp = {};
  Object.entries(initialSuppliersBaseDataForm).forEach((e) => {
    tmp[e[0]] = e[1].validator;
  });
  const validator = Schema.Model(tmp);

  switch (action.type) {
    case SUPPLIERS_VALIDATE_BASE_DATA_EDIT_FORM: {
      const tmp = {};
      Object.entries(action.data).forEach((e) => (tmp[e[0]] = e[1].value));

      const validation = validator.check(tmp);

      const valid = Object.entries(validation).every((e) => !e[1].hasError);
      validation["valid"] = valid;
      return validation;
    }
    case SUPPLIERS_CLEAN_BASE_EDIT_FORM_ERRORS:
      return initialSuppliersBaseDataFormErrorsState;
    default:
      return state;
  }
};

export const initialSuppliersBaseDataUploadStatus = "IDLE";
export const suppliersBaseDataUploadStatus = (
  state = initialSuppliersBaseDataUploadStatus,
  action
) => {
  switch (action.type) {
    case SUPPLIERS_UPLOADING_BASE_DATA_EDIT_FORM:
      return "LOADING";
    case SUPPLIERS_IDLE_BASE_DATA_EDIT_FORM:
      return "IDLE";
    default:
      return state;
  }
};

export const suppliersProfile = (state = {}, action) => {
  switch (action.type) {
    case SUPPLIERS_PROFILE_DATA:
      return action.data;
    default:
      return state;
  }
};

export const initialSuppliersBaseDataEditFormShow = false;
export const suppliersBaseDataEditFormShow = (
  state = initialSuppliersBaseDataEditFormShow,
  action
) => {
  switch (action.type) {
    case SUPPLIERS_SHOW_BASE_DATA_EDIT_FORM:
      return true;
    case SUPPLIERS_HIDE_BASE_DATA_EDIT_FORM:
      return false;
    default:
      return state;
  }
};

export const initialSuppliersOptions = {
  status: {
    choices: [
      { value: 1, display_name: "Active" },
      { value: 2, display_name: "Inactive" },
      { value: 3, display_name: "Candidate" },
    ],
  },
};
export const suppliersOptions = (state = initialSuppliersOptions, action) => {
  switch (action.type) {
    case SUPPLIERS_FETCH_OPTIONS:
      return update(state, { $merge: action.data });
    case FORMAT_TRAVEL_SERVICES_OPTIONS:
      return update(state, { $merge: action.data });
    default:
      return state;
  }
};

export const initialSuppliersProfileFetchStatus = "IDLE";
export const suppliersProfileFetchStatus = (
  state = initialSuppliersProfileFetchStatus,
  action
) => {
  switch (action.type) {
    case SUPPLIERS_LOADING_PROFILE_VIEW:
      return "FETCHING";
    case SUPPLIERS_LOADED_PROFILE_VIEW:
      return "FETCHED";
    default:
      return state;
  }
};

export const initialSuppliersOptionsFetchStatus = "IDLE";
export const suppliersOptionsFetchStatus = (
  state = initialSuppliersOptionsFetchStatus,
  action
) => {
  switch (action.type) {
    case SUPPLIERS_FETCH_OPTIONS:
      return "FETCHED";
    default:
      return state;
  }
};

// suppliers list
export const initialProvidersList = {
  ...commonList,
  filters: {
    name: "",
    ext_id: "",
    email: "",
    city: "",
    currency: "",
    service_code: "",
  },
};
export const providersListReducer = (state = initialProvidersList, action) => {
  switch (action.type) {
    case PROVIDERS_LIST_SAVE_DATA:
      return { ...state, ...action.data };
    case PROVIDERS_LIST_SIMPLE_CHANGE: {
      const { key, value } = action;
      if (key === "filters") {
        return { ...state, [key]: value, page: 1 };
      } else {
        return { ...state, [key]: value };
      }
    }
    case SUPPLIERS_RESET_LIST_FILTER_FORM:
      return { ...state, page: 1 };
    default:
      return state;
  }
};

export const initialSuppliersPostalAddressIsSynchronous = false;
export const suppliersPostalAddressIsSynchronous = (
  state = initialSuppliersPostalAddressIsSynchronous,
  action
) => {
  switch (action.type) {
    case SUPPLIERS_SHOW_BASE_DATA_EDIT_FORM:
      return action.data;
    case SUPPLIERS_TOGGLE_POSTAL_ADDRESS:
      return action.data;
    default:
      return state;
  }
};

export const initialSuppliersListFetchStatus = "IDLE";
export const suppliersListFetchStatus = (
  state = initialSuppliersListFetchStatus,
  action
) => {
  switch (action.type) {
    case SUPPLIERS_BEGIN_FETCH_DATA:
      return "FETCHING";
    case SUPPLIERS_END_FETCH_DATA:
      return "FETCHED";
    default:
      return state;
  }
};

// filter
// export const suppliersListFilter = (
//   state = initialSuppliersFilterForm,
//   action
// ) => {
//   switch (action.type) {
//     case SUPPLIERS_APPLY_LIST_FILTER:
//       return action.data;
//     case FORMAT_TRAVEL_SERVICES_FORM:
//       return update(state, { $merge: action.data });
//     case SUPPLIERS_RESET_LIST_FILTER_FORM:
//       return action.data;
//     default:
//       return state;
//   }
// };

// export const suppliersListFilterForm = (
//   state = initialSuppliersFilterForm,
//   action
// ) => {
//   switch (action.type) {
//     case SUPPLIERS_INITIALIZE_LIST_FILTER_FORM:
//       return action.data;
//     case SUPPLIERS_RESET_LIST_FILTER_FORM:
//       return action.data;
//     case SUPPLIERS_UPDATE_LIST_FILTER_FORM:
//       return formProcessor(
//         state,
//         action.data.type,
//         action.data.name,
//         action.data.value
//       );
//     case FORMAT_TRAVEL_SERVICES_FORM:
//       return update(state, { $merge: action.data });
//     default:
//       return state;
//   }
// };

// columns
// export const suppliersListColumns = (
//   state = entitiesListSelector(initialSuppliersColumnsSetUp),
//   action
// ) => {
//   switch (action.type) {
//     case SUPPLIERS_APPLY_LIST_COLUMNS:
//       return entitiesListSelector(action.data);
//     default:
//       return state;
//   }
// };

// export const suppliersListColumnsForm = (
//   state = initialSuppliersColumnsSetUp,
//   action
// ) => {
//   switch (action.type) {
//     case SUPPLIERS_INITIALIZE_LIST_COLUMNS_FORM: {
//       var newState = {};
//       const tmp = _.cloneDeep(state);
//       for (const key in tmp) {
//         // clear form's value
//         tmp[key] = update(tmp[key], { value: { $set: [] } });
//         newState[key] = update(tmp[key], { value: { $set: [] } });
//         // add value by action data
//         action.data.forEach((item) => {
//           if (_.find(tmp[key].choices, { key: item.key })) {
//             newState[key] = update(newState[key], {
//               value: { $push: [item.key] },
//             });
//           }
//         });
//       }

//       return newState;
//     }
//     case SUPPLIERS_UPDATE_LIST_COLUMNS_FORM:
//       return action.data;
//     default:
//       return state;
//   }
// };

//geo

export const suppliersAddressPoint = (state = {}, action) => {
  switch (action.type) {
    case SUPPLIERS_FETCH_ADDRESS_POINT:
      return action.data;
    case SUPPLIERS_INITIAL_ADDRESS_POINT:
      return action.data;
    case SUPPLIERS_CLEAN_ADDRESS_POINT:
      return { lat: "", lng: "" };
    default:
      return state;
  }
};

export const initialIsVerifySuppliersPostalAddress = false;
export const isVerifySuppliersPostalAddress = (
  state = initialIsVerifySuppliersPostalAddress,
  action
) => {
  switch (action.type) {
    case SUPPLIERS_TOGGLE_VERIFY_ADDRESS:
      return action.data;
    case SUPPLIERS_INITIAL_VERIFY_STATUS:
      return false;
    case SUPPLIERS_CLOSE_VERIFY_ADDRESS:
      return false;
    default:
      return state;
  }
};

export const initialFetchSuppliersAddressPointStatus = "IDLE";
export const fetchSuppliersAddressPointStatus = (
  state = initialFetchSuppliersAddressPointStatus,
  action
) => {
  switch (action.type) {
    case SUPPLIERS_BEGIN_FETCH_ADDRESS_DATA:
      return "FETCHING";
    case SUPPLIERS_END_FETCH_ADDRESS_DATA:
      return "FETCHED";
    default:
      return state;
  }
};

//================================TABS========================================
export const initialNavStatus = "basic_info";

export const suppliersNavStatus = (state = initialNavStatus, action) => {
  switch (action.type) {
    case SUPPLIERS_NAV_TAB_INIT:
      return initialNavStatus;
    case SUPPLIERS_CHANGE_NAV_TAB:
      return action.data;
    default:
      return state;
  }
};

export const supplierPropertyList = (state = {}, action) => {
  switch (action.type) {
    case SUPPLIERS_SAVE_PROPERTY:
      return action.data;
    default:
      return state;
  }
};

//=====================ADD PROPERTY MODAL======================
export const initialSupplierPropertyModal = {
  loading: false,
  show: false,
  form: {
    country_code: "",
    reservations_email: "",
    accommodation_data: {},
    status: "CA",
  },
  mode: "ADD",
};

export const supplierPropertyModal = (
  state = initialSupplierPropertyModal,
  action
) => {
  switch (action.type) {
    case SUPPLIERS_INIT_PROPERTY_MODAL:
      return initialSupplierPropertyModal;
    case SUPPLIERS_SHOW_PROPERTY_MODAL:
      return { ...state, show: true, mode: action.data.mode };
    case SUPPLIERS_HIDE_PROPERTY_MODAL:
      return initialSupplierPropertyModal;
    case SUPPLIERS_LOADING_PROPERTY_MODAL:
      return { ...state, loading: true };
    case SUPPLIERS_LOADED_PROPERTY_MODAL:
      return { ...state, loading: false };
    case SUPPLIERS_SAVE_PROPERTY_DETAIL:
      return {
        ...state,
        form: {
          ...action.data,
          country_code: _.get(
            action.data,
            "accommodation_data.country_code",
            ""
          ),
        },
      };
    case SUPPLIERS_UPDATE_PROPERTY_MODAL_FORM:
      return update(state, {
        form: {
          [action.key]: { $set: action.value },
        },
      });
    default:
      return state;
  }
};

export const initialSupplierPropertyListStatus = { loading: false };
export const supplierPropertyListStatus = (
  state = initialSupplierPropertyListStatus,
  action
) => {
  switch (action.type) {
    case SUPPLIERS_LOADING_PROPERTY_VIEW:
      return { loading: true };
    case SUPPLIERS_LOADED_PROPERTY_VIEW:
      return { loading: false };
    default:
      return state;
  }
};

export * from "./ServiceCategories";
