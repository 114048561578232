import {
  CUSTOM_HOTEL_INIT,
  CUSTOM_HOTEL_INIT_FROM_DATA,
  CUSTOM_HOTEL_NEW_HOTEL,
  CUSTOM_HOTEL_FULL_META_CHANGE,
  CUSTOM_HOTEL_META_CHANGE,
  CUSTOM_HOTEL_IMAGE_CHANGE,
  CUSTOM_HOTEL_ADD_ROOM,
  CUSTOM_HOTEL_REMOVE_ROOM,
  CUSTOM_HOTEL_CLONE_ROOM,
  CUSTOM_HOTEL_ROOM_CHANGE,
} from "./types";

import { notifyCommonError } from "@src/components/common/notifications/CommonErrorNotification.js";

import { fetchAccommodationMeta } from "@src/api/Project/Bidding";

export const initCustomHotel = () => {
  return { type: CUSTOM_HOTEL_INIT };
};

export const initCustomHotelFromData = (data) => {
  return { type: CUSTOM_HOTEL_INIT_FROM_DATA, data };
};

export const fetchHotelMeta = (id) => async (dispatch) => {
  try {
    const meta = await fetchAccommodationMeta(id);
    dispatch({ type: CUSTOM_HOTEL_FULL_META_CHANGE, meta });
  } catch (error) {
    notifyCommonError(
      "We could not get hotel details for the accommodation you requested. We are sorry for the inconvenience."
    );
  }
};

export const metaChange = (name, value) => async (dispatch) => {
  if (name == "is_custom") {
    await dispatch({ type: CUSTOM_HOTEL_NEW_HOTEL });
  }

  dispatch({ type: CUSTOM_HOTEL_META_CHANGE, name, value });
};

// export const imageChange = (idx, imageFile) => dispatch => {
//   let reader = new window.FileReader();
//   reader.readAsDataURL(imageFile);
//   reader.onload = event => {
//     const url = event.target.result;

//     dispatch({
//       type: CUSTOM_HOTEL_IMAGE_CHANGE,
//       idx,
//       image: { url: url, main: idx == 0, file: imageFile }
//     });
//   };
// };

export const addRoom = () => {
  return { type: CUSTOM_HOTEL_ADD_ROOM };
};

export const removeRoom = (idx = null) => {
  return { type: CUSTOM_HOTEL_REMOVE_ROOM, idx };
};

export const cloneRoom = (idx) => {
  return { type: CUSTOM_HOTEL_CLONE_ROOM, idx };
};

export const roomChange = (idx, name, value) => {
  return { type: CUSTOM_HOTEL_ROOM_CHANGE, idx, name, value };
};
