import _ from "lodash";
import { Icon, Panel, Loader, Checkbox, Tag } from "rsuite";

import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { modalGenericStyles, variables } from "@src/jsssetup";
import { CustomButton } from "@src/components/common/buttons";
import {
  getAccSrvsForPrebookSelector,
  getFlSrvsForPrebookSelector,
} from "@src/selectors/Project/TripPlanner/prebook";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQueries } from "@tanstack/react-query";
import { prebookFlight } from "@src/api";
import { toast } from "react-toastify";
import { FlightData } from "./OverviewFlight";
import {
  acceptFlightsPrebook,
  resetFlightsPrebook,
} from "@src/actions/Project/TripPlanner/step_four/prebook/flights";

const prebookFlightsModalStyles = createUseStyles({
  ...modalGenericStyles,
  PrebookFlightsModal: modalGenericStyles.modal,
  cardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    gridGap: variables.normal_gap,
    gridAutoRows: "max-content",
  },
  acceptHeader: {
    display: "grid",
    gridTemplateColumns: "1fr max-content",
    borderBottom: `1px solid ${variables.colors.borders.base}`,
    paddingBottom: variables.half_gap,
  },
  checkbox: {
    width: variables.double_gap,
  },
});
const PrebookFlightsModal = ({ onCancel }) => {
  const [acceptedFlights, setAcceptedFlights] = useState([]);
  const classes = prebookFlightsModalStyles();

  const dispatch = useDispatch();
  const onAcceptFlightsPrebook = useCallback(
    ({ flight_uids }) => dispatch(acceptFlightsPrebook({ flight_uids })),
    [dispatch]
  );
  const onResetFlightsPrebook = useCallback(
    () => dispatch(resetFlightsPrebook()),
    [dispatch]
  );

  const flights = useSelector((state) =>
    getFlSrvsForPrebookSelector(state, { srv_type: "FL" })
  );

  // For each flight fetch prebook info. If any of the flight fails to fetch
  // show error toast.
  const results = useQueries({
    queries: flights.map((fl) => {
      const { session_id, record_set: recordset, legs } = fl;
      const avail_strings = _.flatten(
        legs.map((l) => l.filter((o) => o.selected).map((o) => o.avail_string))
      );

      return {
        queryKey: ["prebook_flight_query", fl.uid],
        queryFn: () => prebookFlight({ recordset, session_id, avail_strings }),
        onSuccess: () => {
          toast.success("Flight prebook info fetched successfully", {
            autoClose: 3000,
          });
        },
        onError: () => {
          toast.error("Failed to prebook flight", { autoClose: 3000 });
        },
        refetchOnWindowFocus: false,
        refetchOnMount: true,
      };
    }),
  });

  const isFetching = results.some((r) => r.isLoading || r.isFetching);
  const prebookResults = results
    .map((r) => r?.data?.data?.fare)
    .filter((r) => r);

  // Check if all flights prebook info fetched and if not then close modal
  // after showing error toast
  useEffect(() => {
    if (!isFetching && !prebookResults.length) {
      toast.error("Failed to prebook all flights", { autoClose: 3000 });
      onCancel();
    }
  }, [isFetching]);

  // Reset accepted flights prebook on unmount
  useEffect(() => onResetFlightsPrebook(), []);

  return (
    <div className={classes.PrebookFlightsModal}>
      <div className={classes.card}>
        {results.some(
          (res) => res.status === "loading" || res.fetchStatus === "fetching"
        ) && <Loader size="lg" backdrop center />}
        <div className={classes.cardHeader}>
          <h5>Flight Booking Information</h5>
        </div>
        <div className={classes.cardBody}>
          {prebookResults.map((flight, idx) => {
            return (
              <React.Fragment>
                <div className={classes.acceptHeader}>
                  <h6>Accept Prebook Information</h6>
                  <input
                    type="checkbox"
                    className={classes.checkbox}
                    checked={acceptedFlights.includes(flight.uid)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setAcceptedFlights((p) => [...p, flight.uid]);
                      } else {
                        setAcceptedFlights((p) =>
                          p.filter((f) => f !== flight.uid)
                        );
                      }
                    }}
                  />
                </div>
                <FlightData
                  key={idx}
                  flight={flight}
                  flightLegUid={flight?.legs?.[0]?.[0]?.uid}
                  services_collapsed={false}
                />
                {(flight?.legs ?? []).length > 1 && (
                  <FlightData
                    key={`${idx}__inbound`}
                    flight={flight}
                    flightLegUid={_.last(flight.legs)?.[0]?.uid}
                    services_collapsed={false}
                  />
                )}
              </React.Fragment>
            );
          })}
        </div>
        <div className={classes.cardActions}>
          <React.Fragment>
            <button className="Button" data-ghost="true" onClick={onCancel}>
              Close
            </button>
            <button
              className="Button"
              onClick={async () => {
                if (flights.length !== acceptedFlights.length) {
                  toast.warning(
                    "Please accept all flights prebook information first.",
                    { autoClose: 3000 }
                  );
                  return;
                }

                await onAcceptFlightsPrebook({
                  flight_uids: acceptedFlights.reduce(
                    (a, b) => ({ ...a, [b]: true }),
                    {}
                  ),
                });
                onCancel();
              }}>
              Submit
            </button>
          </React.Fragment>
        </div>
      </div>
    </div>
  );
};
PrebookFlightsModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
};
export default PrebookFlightsModal;
