import _ from "lodash";
import { cardStyles, variables } from "@src/jsssetup";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { createUseStyles } from "react-jss";
import { DayItemControls, daySrvContainerStyles } from "../Day";
import { DateTime } from "luxon";
import OverviewPrice from "../OverviewPrice";
import { connect, useSelector } from "react-redux";
import { PricingFormContext } from "../../TripPlanner";
import { vehicleTypesMapping } from "../Modals/AddAddhocTrfSrvModal";
import { removeAdhocTrfService } from "@src/actions/Project/TripPlanner/addhoc/transfers";
import ServicePricingForm from "../ServicePricingForm";
import {
  getServicePricingSelector,
  srvPriceKeyCreator,
} from "@src/selectors/Project/TripPlanner/pricing";
import { isServiceFilterSelectedSelector } from "@src/selectors/Project/TripPlanner/service_filters";
import { BookChecker } from "../ItineraryOverviewCommon";
import { daySrvBodyStyles, daySrvHeaderStyles } from "../Day/common";
import { useServiceCardExpansion } from "@src/pages/hooks";
import { InlineIcon } from "@iconify/react";
// const ghostImg =
//   "https://sisistaticassets.s3.ap-southeast-1.amazonaws.com/platform_graphics/imgs/others/destination_placeholder.jpg";

function dater(dt) {
  return DateTime.fromISO(dt).toLocaleString(
    DateTime.DATETIME_MED_WITH_WEEKDAY
  );
}

const attributeStyles = createUseStyles({
  attribute: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  label: {
    color: variables.colors.text.colored,
  },
});
export const AdhocSrvAttribute = ({ label, value }) => {
  const classes = attributeStyles();
  return (
    <span className={classes.attribute}>
      <strong className={classes.label}>{label}: </strong>
      {value ? value : "N/A"}
    </span>
  );
};
AdhocSrvAttribute.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

const TransferServiceStyles = createUseStyles({
  TransferServiceContainer: (props) => ({
    ...daySrvContainerStyles({ currentStep: props.currentStep }),
  }),
  TransferService: {
    display: "grid",
    gridGap: variables.half_gap,
  },
  header: {
    ...daySrvHeaderStyles(),
    ...cardStyles.card,
    paddingLeft: variables.double_gap,
    borderRadius: `calc(${variables.normal_gap} / 2)`,
    background: variables.colors.services.trfService,
  },
  body: (props) => ({
    ...daySrvBodyStyles(props),
    ...cardStyles.card,
    paddingLeft: variables.double_gap,
    height: "auto",
    gridTemplateColumns: "1fr",
    background: variables.colors.services.trfServiceBody,
    overflow: "hidden",
  }),
  content: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    gridAutoRows: "max-content",
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  title: {
    fontWeight: "600",
    color: variables.colors.text.colored,
  },
  expandIconCntr: {
    display: "grid",
    placeContent: "center",
    width: "1.5rem",
    height: "1.5rem",
    borderRadius: "50%",
    background: variables.colors.easy.orange,
    padding: variables.half_gap,
    cursor: "pointer",
  },
  expandIcon: {
    fontSize: "large",
    color: variables.colors.text.light,
  },
});
var TransferAdhocService = (props) => {
  const { srv, onRemove } = props;
  const currentStep = useSelector((state) => state.tripPlannerCurrentStep);
  const serviceIsSelected = useSelector((state) =>
    isServiceFilterSelectedSelector(state, { service_type: "TRF" })
  );

  const { markup, gross_margin, flat_value } = useSelector((state) =>
    getServicePricingSelector(state, {
      key: srvPriceKeyCreator({ service: srv, service_type: "TRF" }),
    })
  );

  const { showPricingForm } = useContext(PricingFormContext);
  const { services_collapsed, toggleSelfCollapse } = useServiceCardExpansion();

  const classes = TransferServiceStyles({ currentStep });

  const price = srv.total_price;
  const currency = srv.currency;

  return !serviceIsSelected || !srv ? null : (
    <div className={classes.TransferServiceContainer}>
      {currentStep === 4 ? (
        <BookChecker />
      ) : !services_collapsed ? (
        <DayItemControls onDeselect={() => onRemove({ srv_uid: srv.uid })} />
      ) : (
        <div />
      )}
      <div className={classes.TransferService}>
        <div className={classes.header}>
          <span>
            Transfer: {_.startCase(srv.origin_point.type)} to{" "}
            {_.startCase(srv.destination_point.type)}: {srv.origin_point.name}{" "}
            to {srv.destination_point.name}
          </span>
          <div className={classes.expandIconCntr}>
            <InlineIcon
              className={classes.expandIcon}
              icon={
                services_collapsed
                  ? "ri:arrow-down-s-line"
                  : "ri:arrow-up-s-line"
              }
              onClick={toggleSelfCollapse}
            />
          </div>
        </div>
        {services_collapsed ? null : (
          <div className={classes.body}>
            <div className={classes.content}>
              <AdhocSrvAttribute
                label="Departure"
                value={dater(srv.departure)}
              />
              <AdhocSrvAttribute
                label="Vehicle Type"
                value={_.get(vehicleTypesMapping, srv.vehicle_type, "N/A")}
              />
              <AdhocSrvAttribute label="Arrival" value={dater(srv.arrival)} />
              <AdhocSrvAttribute
                label="Number of Vehicles"
                value={srv.vehicles}
              />
              <AdhocSrvAttribute
                label="Baggage Allowance"
                value={srv.baggages_per_vehicle}
              />
              <AdhocSrvAttribute label="Vehicle Seats" value={srv.seats} />
              <AdhocSrvAttribute label="Distance (km)" value={srv.distance} />
              <AdhocSrvAttribute
                label="Company"
                value={srv.company_name || "N/A"}
              />
            </div>
          </div>
        )}
      </div>
      {showPricingForm ? (
        <ServicePricingForm service_type="TRF" service={srv} />
      ) : services_collapsed ? (
        <div />
      ) : (
        <OverviewPrice
          searching={false}
          serviceType="MI"
          price={price}
          currency={currency}
          markup={markup}
          gross_margin={gross_margin}
          flat_value={flat_value}
        />
      )}
    </div>
  );
};
TransferAdhocService.defaultProps = {
  srv: {},
};
TransferAdhocService.propTypes = {
  srv: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
};
const trfMapDispatchToProps = (dispatch) => {
  return {
    onRemove: ({ srv_uid }) => dispatch(removeAdhocTrfService({ srv_uid })),
  };
};
TransferAdhocService = connect(
  null,
  trfMapDispatchToProps
)(TransferAdhocService);

export { TransferAdhocService };
