import _ from "lodash";
import {
  hideTripPreferences,
  updateTripPreferences,
} from "@src/actions/Project/TripPlanner";

import { connect, useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { formStyles, modalGenericStyles, variables } from "@src/jsssetup";
import { CustomButton } from "@src/components/common/buttons";
import { Form, Formik, useFormikContext } from "formik";
import {
  NormalDatePicker,
  NormalInputField,
  NormalSelectField,
  NormalSentOfferAutocompleteField,
} from "@src/components/forms";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  BUILD,
  fetchProductTermsProm,
  resentMyOffersSentEmail,
} from "@src/api";
import { toast } from "react-toastify";
import { Loader } from "rsuite";
import { updateOfferData } from "@src/actions/Project/TripPlanner/offer_actions";

const SubmitBtn = () => {
  const { submitForm } = useFormikContext();
  return <CustomButton onClick={submitForm}>Submit</CustomButton>;
};

const tripPreferencesModalStyles = createUseStyles({
  ...modalGenericStyles,
  TripPreferencesModal: modalGenericStyles.modal,
  form: { ...formStyles.form, display: "grid", gridGap: variables.half_gap },
  col2: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: variables.half_gap,
  },
  adaptiveCol2: {
    display: "grid",
    gridTemplateColumns: "1fr max-content",
    gridGap: variables.half_gap,
    alignItems: "end",
  },
  linkedOffer: {
    display: "grid",
    gridTemplateColumns: "1fr max-content",
    gridGap: variables.half_gap,
    padding: variables.half_gap,
  },
});
const TripPreferencesModal = ({ show, onHide }) => {
  const classes = tripPreferencesModalStyles();
  const [loading, setLoading] = useState(false);
  const [editLinkedOffer, setEditLinkedOffer] = useState(false);

  const { tripSetupForm, instanceData, offerInstanceData } = useSelector(
    (state) => {
      return {
        offerInstanceData: state.tripPlannerOfferInstanceData,
        instanceData: state.tripPlannerInstanceData,
        tripSetupForm: state.tripPlannerItinerarySetupForm,
      };
    }
  );

  const linkedReferences = tripSetupForm?.linked_references ?? [];
  const isParent = linkedReferences.length > 0;

  const queryClient = useQueryClient();
  const linkedOffer = queryClient.getQueryData([
    "tripLinkedOffer",
    tripSetupForm?.linked_offer,
  ]);

  const { data: termsData } = useQuery({
    queryKey: ["offerProductTerms"],
    queryFn: () => fetchProductTermsProm(),
    onError: () => {
      toast.error("Error fetching available product terms & conditions", {
        autoClose: 3000,
      });
    },
    refetchOnWindowFocus: false,
    enabled: !!show,
  });
  const terms = (termsData?.data?.results ?? []).filter(
    (t) => t.status === "AC"
  );

  const dispatch = useDispatch();
  const onSubmit = useCallback(
    (data) => {
      dispatch(updateTripPreferences({ data: data.tripSetupForm }));
      dispatch(updateOfferData({ offerData: data.offerInstanceData }));
      onHide();
    },
    [dispatch]
  );

  useEffect(() => {
    if (!show) setEditLinkedOffer(false);
  }, [show]);

  return !show ? null : !tripSetupForm ? null : (
    <div className={classes.TripPreferencesModal} size="sm">
      <Formik
        initialValues={{
          tripSetupForm,
          offerInstanceData: {
            ...offerInstanceData,
            terms_and_conditions_id:
              offerInstanceData?.terms_and_conditions?.id,
          },
        }}
        onSubmit={(values) => {
          // We need toString because if the id is set will be a number.
          if (
            (values?.tripSetupForm?.linked_offer ?? "")
              .toString()
              .includes("---")
          ) {
            values.tripSetupForm.linked_offer =
              values.tripSetupForm.linked_offer.split("---")[2];
          }

          if (values?.offerInstanceData?.terms_and_conditions_id) {
            const id = parseInt(
              values.offerInstanceData.terms_and_conditions_id,
              10
            );
            var te = _.cloneDeep(terms.find((t) => t.id === id));
            te = _.omit(te, [
              "member",
              "status",
              "created",
              "creator",
              "last_editor",
              "edited",
            ]);
            values.offerInstanceData.terms_and_conditions = te;
            delete values.offerInstanceData.terms_and_conditions_id;
          }

          onSubmit(values);
        }}>
        {({ values, setFieldValue }) => {
          const offerEmails = values?.offerInstanceData?.offer_emails ?? [];
          return (
            <div className={classes.card}>
              {loading && <Loader size="lg" center backdrop />}
              <div className={classes.cardHeader}>
                <h5>Trip Preferences</h5>
              </div>
              <div className={classes.cardBody}>
                <Form className={classes.form}>
                  <NormalInputField name="tripSetupForm.title" label="Title" />
                  {offerEmails.length > 0 && (
                    <div className="col2">
                      <NormalInputField
                        name="offerInstanceData.offer_title"
                        label="Offer Title"
                      />
                      <NormalInputField
                        name="offerInstanceData.currency"
                        label="Currency"
                        extraInputProps={{ disabled: true }}
                      />
                    </div>
                  )}
                  <NormalInputField
                    name="tripSetupForm.external_reference"
                    label="External Reference"
                  />

                  {offerEmails.length > 0 && (
                    <div className="col2">
                      <NormalDatePicker
                        name="offerInstanceData.validity"
                        label="Valid Until"
                        withTime={true}
                      />
                      <NormalSelectField
                        name="offerInstanceData.terms_and_conditions_id"
                        label="Terms and Conditions"
                        options={terms.map((t) => [
                          t.id,
                          `${t.name} (${t.product_type})`,
                        ])}
                      />
                    </div>
                  )}
                  {offerEmails.map((___, idx) => (
                    <React.Fragment>
                      <div className={classes.adaptiveCol2}>
                        <NormalInputField
                          name={`offerInstanceData.offer_emails.${idx}`}
                          label={`Recipient ${idx + 1}`}
                          type="email"
                        />
                        {idx !== 0 && (
                          <button
                            className="Button"
                            data-ghost="true"
                            onClick={() => {
                              setFieldValue(
                                "offerInstanceData.offer_emails",
                                offerEmails.filter((_, i) => i !== idx)
                              );
                            }}>
                            Remove
                          </button>
                        )}
                      </div>
                      {idx + 1 === offerEmails.length && (
                        <button
                          className="Button"
                          onClick={() =>
                            setFieldValue("offerInstanceData.offer_emails", [
                              ...offerEmails,
                              "",
                            ])
                          }>
                          Add Recipient
                        </button>
                      )}
                    </React.Fragment>
                  ))}
                  <div className={classes.col2}>
                    <NormalSelectField
                      name="tripSetupForm.usage"
                      label="Trip Type"
                      options={[
                        ["OFF", "Offer"],
                        ["RQ", "Template"],
                        ["MKT", "Marketplace"],
                      ]}
                    />
                    <NormalInputField
                      name="tripSetupForm.budget"
                      label="Budget"
                    />
                  </div>
                  <NormalInputField
                    name="offerInstanceData.notes"
                    label="Description"
                    as="textarea"
                  />
                  {!isParent &&
                    (editLinkedOffer ? (
                      <NormalSentOfferAutocompleteField
                        id="TripPreferencesModal__NormalSentOfferAutocompleteField"
                        name="tripSetupForm.linked_offer"
                        label="Linked Offer"
                      />
                    ) : (
                      <React.Fragment>
                        <span>
                          <strong>Linked Offer</strong>
                        </span>
                        <div className={classes.linkedOffer}>
                          <span>
                            <strong>{linkedOffer?.offer_title ?? "N/A"}</strong>
                          </span>
                          <CustomButton
                            onClick={() => {
                              setEditLinkedOffer(true);
                              setFieldValue("linked_offer", "");
                            }}>
                            Edit
                          </CustomButton>
                        </div>
                      </React.Fragment>
                    ))}
                </Form>
              </div>
              <div className={classes.cardActions}>
                <button className="Button" data-ghost="true" onClick={onHide}>
                  Close
                </button>
                {(offerInstanceData?.offer_emails ?? []).length > 0 && (
                  <React.Fragment>
                    <button
                      className="Button"
                      onClick={() => {
                        const build = BUILD === "staging" ? ".dev" : "";
                        const baseUrl = `https://travelcompanion${build}.easytravel.tech/trip_info`;
                        window.open(
                          `${baseUrl}/REF__${instanceData.reference}`,
                          "_blank"
                        );
                      }}>
                      Review Offer
                    </button>
                    <button
                      className="Button"
                      onClick={() => {
                        setLoading(true);
                        resentMyOffersSentEmail(instanceData.reference)
                          .then(() =>
                            toast.success(
                              "Offer email resent. Please check your inbox.",
                              { autoClose: 5000 }
                            )
                          )
                          .catch(() =>
                            toast.error(
                              "Offer email not resent. We are sorry for the inconvenience.",
                              { autoClose: 5000 }
                            )
                          )
                          .finally(() => setLoading(false));
                      }}>
                      Send Offer Email
                    </button>
                  </React.Fragment>
                )}
                <SubmitBtn />
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};
TripPreferencesModal.defaultProps = { show: false };
TripPreferencesModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  const { show } = state.tripPlannerPreferencesModal;
  if (!show) {
    return {};
  }

  return { show: state.tripPlannerPreferencesModal.show };
};
const mapDispatchToProps = (dispatch) => {
  return { onHide: () => dispatch(hideTripPreferences()) };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TripPreferencesModal);
