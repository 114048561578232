import {
  // ========================== INIT ACTIONS =========================
  TRIPPLANNER_INIT,
  // ======================= TRIP LOAD ACTIONS =======================
  TRIPPLANNER_LOAD_TRIP,
  // ============================== ORIGIN =============================
  ITINERARY_SELECT_ORIGIN_DESTINATION,
  ITINERARY_SELECT_ORIGIN_DATE,
  // ============================= RETURN ============================
  ITINERARY_RETURN_DATE_CHANGE,
  ITINERARY_ADD_RETURN,
  ITINERARY_REMOVE_RETURN_POINT,
  ITINERARY_SELECT_RETURN_DESTINATION,
  // ========================== DESTINATION ==========================
  TRIPPLANNER_ITINERARY_ADD_DESTINATION,
  TRIPPLANNER_ITINERARY_SELECT_DESTINATION,
  ITINERARY_REMOVE_DESTINATION,
  ITINERARY_DESTINATION_QUERY_CHANGE,
  ITINERARY_DESTINATION_DOWN,
  ITINERARY_DESTINATION_UP,
  ITINERARY_DESTINATION_STAY_CHANGE,
  ITINERARY_GLOBAL_DESTINATION_STAY_CHANGE,
  TRIPPLANNER_ITINERARY_DESTINATION_LOADING,
  TRIPPLANNER_ITINERARY_DESTINATION_IDLE,
  TRIPPLANNER_ITINERARY_DEST_GENERIC_CHANGE,
  ITINERARY_DESTINATION_MANUAL_DATES_CHANGE,
  ITINERARY_DESTINATION_MANUAL_DATES_RESET,
  ITINERARY_INITIAL_DESTINATION_DATES_CHANGE,
  TRIPPLANNER_ITINERARY_UPDATE_DESTINATIONS,
  ITINERARY_SETUP_FORM_CHANGE,
} from "@src/actions/Project/TripPlanner/types";

import { notifyCommonWarning } from "@src/components/common/notifications/CommonWarningNotification.js";

import { juxtEl } from "@src/tools/common_tools";
import { calcTripDates, calcDestOrders } from "../tools";

import _ from "lodash";
import moment from "moment";
import update from "immutability-helper";
import { DateTime, Duration } from "luxon";

export function getDestinationTemplate(
  { manualDates = false } = { manualDates: false }
) {
  return {
    query: "",
    name_en: "",
    name_cn: "",
    geodata: { lng: 0, lat: 0 },
    images: [],
    id: null,
    order: 1,
    type: "",
    stay: 1,
    checkIn: DateTime.now().plus({ days: 7 }).toISODate(),
    checkOut: DateTime.now().plus({ days: 8 }).toISODate(),
    dest_type: "NOR",
    manualDates,
  };
}

const tripPlannerOriginDataInitial = {
  date: moment().add(7, "days").toISOString(true),
  destination: _.cloneDeep(getDestinationTemplate()),
};
export const tripPlannerOriginData = (
  state = tripPlannerOriginDataInitial,
  action
) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return tripPlannerOriginDataInitial;
    case TRIPPLANNER_LOAD_TRIP:
      return action.data.origin_data ?? tripPlannerOriginDataInitial;
    case ITINERARY_SELECT_ORIGIN_DESTINATION:
      return update(state, { destination: { $merge: action.data } });
    case ITINERARY_SELECT_ORIGIN_DATE: {
      {
        const { date } = action;
        if (DateTime.fromISO(date) < DateTime.now()) {
          notifyCommonWarning("You cannot select a past date.");
          return state;
        }

        return update(state, { date: { $set: date } });
      }
    }
    case ITINERARY_INITIAL_DESTINATION_DATES_CHANGE: {
      const { checkIn } = action;
      return update(state, { date: { $set: checkIn } });
    }
    case ITINERARY_SETUP_FORM_CHANGE: {
      if (action?.data?.name !== "requiredServices") return state;
      if (action?.data?.action !== "remove") return state;
      if (action?.data?.value !== "TR") return state;

      return tripPlannerOriginDataInitial;
    }
    default:
      return state;
  }
};

export const tripPlannerReturnData = (state = {}, action) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return {};
    case TRIPPLANNER_LOAD_TRIP:
      return action.data.return_data;
    case ITINERARY_ADD_RETURN:
      return update(action.data, { date: { $set: action.arrival } });
    case ITINERARY_RETURN_DATE_CHANGE: {
      if (_.isEmpty(state)) {
        return state;
      }

      return update(state, { date: { $set: action.arrival } });
    }
    case ITINERARY_REMOVE_RETURN_POINT:
      return {};
    case ITINERARY_SELECT_RETURN_DESTINATION:
      return update(state, { destination: { $merge: action.data } });
    default:
      return state;
  }
};

export const tripPlannerDestinations = (state = [], action) => {
  switch (action.type) {
    case TRIPPLANNER_INIT: {
      return [...[getDestinationTemplate()]];
    }
    case TRIPPLANNER_LOAD_TRIP:
      return action.data.destinations;
    case TRIPPLANNER_ITINERARY_UPDATE_DESTINATIONS:
      return action?.destinations ?? [];
    case TRIPPLANNER_ITINERARY_ADD_DESTINATION:
      return calcTripDates(
        calcDestOrders(
          update(state, {
            $splice: [[action.idx, 0, getDestinationTemplate()]],
          })
        ),
        action.originData
      );
    case TRIPPLANNER_ITINERARY_DEST_GENERIC_CHANGE: {
      const { destOrder, key, value } = action;
      const newDests = _.cloneDeep(state);
      const dest = newDests.find((d) => d.order === destOrder);
      dest[key] = value;
      return newDests;
    }
    case TRIPPLANNER_ITINERARY_SELECT_DESTINATION: {
      const { idx, data } = action;
      return update(state, {
        [idx]: { $merge: data },
      });
    }
    case ITINERARY_DESTINATION_QUERY_CHANGE:
      return update(state, {
        [action.idx]: {
          query: {
            $set: action.query,
          },
        },
      });
    case ITINERARY_REMOVE_DESTINATION:
      return update(state, {
        $apply: (dests) => {
          return calcTripDates(
            calcDestOrders(dests.filter((d) => d.order !== action.order)),
            action.originData
          );
        },
      });
    case ITINERARY_DESTINATION_DOWN:
      return update(state, {
        $apply: (dests) => {
          return calcTripDates(
            juxtEl(dests, action.idx).map((d, idx) => {
              d.order = idx + 1;
              return d;
            }),
            action.originData
          );
        },
      });
    case ITINERARY_DESTINATION_UP:
      return update(state, {
        $apply: (dests) => {
          return calcTripDates(
            juxtEl(dests, action.idx - 1).map((d, idx) => {
              d.order = idx + 1;
              return d;
            }),
            action.originData
          );
        },
      });
    case ITINERARY_INITIAL_DESTINATION_DATES_CHANGE: {
      const { checkIn, checkOut } = action;

      return update(state, {
        $apply: (dests) => {
          var prevDest = null;
          return dests.map((d, idx) => {
            if (idx === 0) {
              const newDest = _.cloneDeep(d);
              newDest.checkIn = checkIn;
              newDest.checkOut = checkOut;
              newDest.stay = DateTime.fromISO(checkOut)
                .diff(DateTime.fromISO(checkIn), "days")
                .as("days");
              prevDest = newDest;
              return newDest;
            }

            const newDest = _.cloneDeep(d);
            newDest.checkIn = prevDest.checkOut;
            newDest.checkOut = DateTime.fromISO(newDest.checkIn)
              .plus({ days: d.stay })
              .toISODate();
            prevDest = newDest;

            return newDest;
          });
        },
      });
    }
    case ITINERARY_DESTINATION_STAY_CHANGE: {
      const { stay, destOrder } = action;

      return update(state, {
        $apply: (dests) => {
          var prevDest = null;
          return dests.map((d) => {
            if (d.order == destOrder) {
              const newDest = _.cloneDeep(d);
              newDest.checkOut = DateTime.fromISO(newDest.checkIn)
                .plus({ days: stay })
                .toISODate();
              newDest.stay = stay;
              prevDest = newDest;
              return newDest;
            } else if (destOrder < d.order && !_.get(d, "manualDates", false)) {
              const newDest = _.cloneDeep(d);
              newDest.checkIn = prevDest.checkOut;
              newDest.checkOut = DateTime.fromISO(newDest.checkIn)
                .plus({ days: d.stay })
                .toISODate();
              prevDest = newDest;
              return newDest;
            }
            prevDest = d;
            return d;
          });
        },
      });
    }
    case ITINERARY_DESTINATION_MANUAL_DATES_CHANGE: {
      const { destOrder, checkIn, checkOut } = action;
      return update(state, {
        $apply: (dests) => {
          var prevDest = null;
          return dests.map((dest, idx) => {
            if (dest.order == destOrder) {
              const newDest = _.cloneDeep(dest);
              newDest.checkIn = checkIn;
              newDest.checkOut = checkOut;
              newDest.stay = DateTime.fromISO(checkOut)
                .diff(DateTime.fromISO(checkIn), "days")
                .as("days");
              newDest.manualDates = true;
              prevDest = newDest;
              return newDest;
            } else if (
              dest.order > Number(destOrder) &&
              !_.get(dest, "manualDates", false)
            ) {
              const newDest = _.cloneDeep(dest);
              const prevCheckOut = DateTime.fromISO(prevDest.checkOut);
              newDest.checkIn = prevCheckOut.toISODate();
              newDest.checkOut = prevCheckOut
                .plus(Duration.fromObject({ days: newDest.stay }))
                .toISODate();
              prevDest = newDest;
              return newDest;
            }

            prevDest = dest;
            return dest;
          });
        },
      });
    }
    case ITINERARY_DESTINATION_MANUAL_DATES_RESET: {
      const { destOrder } = action;
      return update(state, {
        $apply: (dests) => {
          var prevDest = null;
          return dests.map((dest, idx) => {
            if (dest.order == destOrder) {
              const newDest = _.cloneDeep(dest);
              if (idx > 0) {
                const prevCheckOut = DateTime.fromISO(prevDest.checkOut);
                newDest.checkIn = prevCheckOut.toISODate();
                newDest.checkOut = prevCheckOut
                  .plus(Duration.fromObject({ days: newDest.stay }))
                  .toISODate();
              }
              newDest.manualDates = false;
              prevDest = newDest;
              return newDest;
            } else {
              prevDest = dest;
              return dest;
            }
          });
        },
      });
    }
    case ITINERARY_GLOBAL_DESTINATION_STAY_CHANGE:
      return calcTripDates(state, action.originData);
    default:
      return state;
  }
};

export const tripPlannerDestinationsLoadingStatus = (state = {}, action) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return {};
    case TRIPPLANNER_LOAD_TRIP:
      return {};
    case TRIPPLANNER_ITINERARY_DESTINATION_LOADING: {
      const { destOrder } = action;
      return { ...state, ...{ [destOrder]: true } };
    }
    case TRIPPLANNER_ITINERARY_DESTINATION_IDLE: {
      const { destOrder } = action;
      return { ...state, ...{ [destOrder]: false } };
    }
    default:
      return state;
  }
};
