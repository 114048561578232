import {
  INDEPENDENT_AGENTS_BEGIN_FETCH_DATA,
  INDEPENDENT_AGENTS_FETCH_DATA,
  INDEPENDENT_AGENTS_END_FETCH_DATA,
  INDEPENDENT_AGENTS_FETCH_OPTIONS,
  INDEPENDENT_AGENTS_UPDATE_LIST_FILTER_FORM,
  INDEPENDENT_AGENTS_APPLY_LIST_FILTER,
  INDEPENDENT_AGENTS_INITIALIZE_LIST_FILTER_FORM,
  INDEPENDENT_AGENTS_UPDATE_LIST_COLUMNS_FORM,
  INDEPENDENT_AGENTS_APPLY_LIST_COLUMNS,
  INDEPENDENT_AGENTS_INITIALIZE_LIST_COLUMNS_FORM,
  INDEPENDENT_AGENTS_SHOW_BASE_DATA_EDIT_FORM,
  INDEPENDENT_AGENTS_HIDE_BASE_DATA_EDIT_FORM,
  INDEPENDENT_AGENTS_EDIT_BASE_DATA,
  INDEPENDENT_AGENTS_INITIALIZE_BASE_DATA_EDIT_FORM,
  INDEPENDENT_AGENTS_VALIDATE_BASE_DATA_EDIT_FORM,
  INDEPENDENT_AGENTS_PROFILE_DATA,
  INDEPENDENT_AGENTS_UPLOADING_BASE_DATA_EDIT_FORM,
  INDEPENDENT_AGENTS_IDLE_BASE_DATA_EDIT_FORM,
  INDEPENDENT_AGENTS_CLEAN_BASE_EDIT_FORM_ERRORS,
  INDEPENDENT_AGENTS_CLEAN_BASE_DATA_EDIT_FORM,
  INDEPENDENT_AGENTS_VIEW_NATURE_OF_BIZ,
  INDEPENDENT_AGENTS_EDIT_NATURE_OF_BIZ,
  INDEPENDENT_AGENTS_INITIALIZE_NATURE_DATA_FORM,
  INDEPENDENT_AGENTS_EDIT_NATURE_OF_BIZ_DATA,
  INDEPENDENT_AGENTS_SUBMIT_NATURE_OF_BIZ_DATA,
  INDEPENDENT_AGENTS_EDIT_LICENSE_CODE,
  INDEPENDENT_AGENTS_TOGGLE_POSTAL_ADDRESS,
  INDEPENDENT_AGENTS_RESET_LIST_FILTER_FORM,
  INDEPENDENT_AGENTS_INITIALIZE_TRAVEL_SERVICE_FORM,
  INDEPENDENT_AGENTS_INITIALIZE_MAIN_SERVICES_FORM,
  INDEPENDENT_AGENTS_FETCHING_OPTIONS,
  INDEPENDENT_AGENTS_FETCHED_OPTIONS,
  //img relation
  INDEPENDENT_AGENTS_UPLOAD_PREFERENCE_IMAGE,
  INDEPENDENT_AGENTS_FETCH_ADDRESS_POINT,
  INDEPENDENT_AGENTS_INITIAL_ADDRESS_POINT,
  INDEPENDENT_AGENTS_TOGGLE_VERIFY_ADDRESS,
  INDEPENDENT_AGENTS_BEGIN_FETCH_ADDRESS_DATA,
  INDEPENDENT_AGENTS_END_FETCH_ADDRESS_DATA,
  INDEPENDENT_AGENTS_BEGIN_FETCH_PROFILE_DATA,
  INDEPENDENT_AGENTS_END_FETCH_PROFILE_DATA,
  INDEPENDENT_AGENTS_CLOSE_VERIFY_ADDRESS,
  INDEPENDENT_AGENTS_CLEAN_ADDRESS_POINT,
  INDEPENDENT_AGENTS_INITIAL_VERIFY_STATUS,
  //==================Email Config ==================
  INDEPENDENT_AGENTS_VIEW_EMAIL_CONFIG_MODE,
  INDEPENDENT_AGENTS_EDIT_EMAIL_CONFIG_MODE,
  INDEPENDENT_AGENTS_LOADING_EMAIL_CONFIG_MODE,
  INDEPENDENT_AGENTS_EDIT_EMAIL_CONFIG_FORM,
  INDEPENDENT_AGENTS_INITIALIZE_EMAIL_CONFIG_FORM,
} from "./types";

import { notifyCommonError } from "@src/components/common/notifications/CommonErrorNotification.js";
import { notifyCommonSuccess } from "@src/components/common/notifications/CommonSuccessNotification.js";
import _ from "lodash";
import { createHashHistory } from "history";

import {
  initialIndependentAgentsBaseDataForm,
  initialIndependentAgentsNatureOfBizForm,
  initialIndependentAgentsFilterForm,
  defaultOption,
} from "@src/forms/Network/IndependentAgents";

import {
  changeUploadImgFactory,
  fetchCompleteAddressFactory,
  fetchAddressPointDetailFactory,
} from "./../../ActionFactory";

import React from "react";
import TransTxt from "@src/components/common/SxFormatMessage";

import { submitProcessor, entityError400Handle } from "@src/actions/tools";
import { travelServicesFrom } from "@src/forms/Admin/Company/companyProfileForms";

import {
  getIndependentAgentsList,
  getIndependentAgentsOptions,
  addNewIndependentAgents as addNewIndependentAgentsAPI,
  fetchIndependentAgentsProfile as fetchIndependentAgentsProfileAPI,
  submitIndependentAgentsProfile as submitIndependentAgentsProfileAPI,
  uploadImgLink,
} from "@src/api";

import {
  filterEmptyValue,
  paramsProcessor,
  isEmptyObj,
  errorHandle as commonErrorHandle,
} from "@src/tools/common_tools";

import { hideGlobalModal } from "@src/actions/Shares";

import update from "immutability-helper";

import { getFilter, getPagination } from "@src/tools/form_tools";

// independentAgents list
export const addNewIndependentAgentsData = () => async (dispatch, getState) => {
  const state = getState();
  const type = state.userMeta;
  const verifyStatus = state.isVerifyIndependentAgentsPostalAddress;
  if (state.independentAgentsBaseDataFormErrors.valid == false) {
    for (const key in state.independentAgentsBaseDataFormErrors) {
      if (state.independentAgentsBaseDataFormErrors[key].hasError) {
        notifyCommonError(
          state.independentAgentsBaseDataFormErrors[key].errorMessage
        );
        return null;
      }
    }
    return null;
  }

  if (!verifyStatus) {
    return notifyCommonError("Please Verify the Address");
  }

  let entities = type.company_type;

  const entityData = {
    name: state.independentAgentsBaseDataForm.name.value,
    legal_title: state.independentAgentsBaseDataForm.legal_title.value,
    registration_code:
      state.independentAgentsBaseDataForm.registration_code.value,
    founding_year: state.independentAgentsBaseDataForm.founding_year.value,
    email: state.independentAgentsBaseDataForm.email.value,
    url: state.independentAgentsBaseDataForm.url.value,
    weekdays: [true, true, true, true, true, false, false],
    working_time_from: "09:00",
    working_time_to: "18:00",
    brief_description_en:
      state.independentAgentsBaseDataForm.brief_description_en.value,
    brief_description_cn:
      state.independentAgentsBaseDataForm.brief_description_cn.value,
    travel_services: [],
    license_type: [],
    phone: state.independentAgentsBaseDataForm.phone.value,
    mobile_phone: state.independentAgentsBaseDataForm.mobile_phone.value,
    [entities]: type.company_id,
  };

  //contact person
  const contactPersonData = {
    first_name: state.independentAgentsBaseDataForm.first_name.value,
    last_name: state.independentAgentsBaseDataForm.last_name.value,
  };
  entityData.contact_person = [contactPersonData];

  // address
  let postal_country_code = "";
  if (state.independentAgentsBaseDataForm.postal_country_code.value) {
    postal_country_code =
      state.independentAgentsBaseDataForm.postal_country_code.value.split(
        "-"
      )[0];
  }
  const postal_Address_Data = {
    street_number:
      state.independentAgentsBaseDataForm.postal_street_number.value,
    street: state.independentAgentsBaseDataForm.postal_street.value,
    country_code: postal_country_code,
    region: state.independentAgentsBaseDataForm.postal_region.value,
    city: state.independentAgentsBaseDataForm.postal_city.value,
    post_code: state.independentAgentsBaseDataForm.postal_post_code.value,
    lat: state.independentAgentsBaseDataForm.lat.value,
    lng: state.independentAgentsBaseDataForm.lng.value,
  };
  entityData.address = [postal_Address_Data];
  dispatch(loadingIndependentAgentsBaseDataEditForm());

  try {
    const res = await addNewIndependentAgentsAPI(filterEmptyValue(entityData));
    const { id } = res;
    const customHistory = createHashHistory();
    customHistory.push(`/network/independentagents/profile/?id=${id}`);
  } catch (error) {
    error.ovrdErrHandle && error.ovrdErrHandle();
    commonErrorHandle({ error, form: state.independentAgentsBaseDataForm });
  } finally {
    dispatch(idleIndependentAgentsBaseDataEditForm());
    dispatch(hideGlobalModal());
  }
};

export const addNewIndependentAgents = () => (dispatch, getState) => {
  const tempForm = _.cloneDeep(getState().independentAgentsBaseDataForm);
  // add protocol to validate,but no send to backend
  if (tempForm.url.value) {
    tempForm.url.value = "http://" + tempForm.url.value;
  }
  dispatch(validateIndependentAgentsBaseDataEditForm(tempForm));
  Promise.resolve().then(() => dispatch(addNewIndependentAgentsData()));
};

export const validateIndependentAgentsBaseData = (data) => {
  return {
    type: INDEPENDENT_AGENTS_VALIDATE_BASE_DATA_EDIT_FORM,
    data,
  };
};

const initialOptions = {
  status: {
    choices: [
      { value: "AC", display_name: <TransTxt id="active" startCase /> },
      {
        value: "IN",
        display_name: <TransTxt id="inactive" startCase />,
      },
      {
        value: "CA",
        display_name: <TransTxt id="candidate" startCase />,
      },
    ],
  },
};
export const fetchIndependentAgentsOptions =
  (langCode) => async (dispatch, getState) => {
    const lang = getState().setLang;

    dispatch(beginFetchOptionsData());
    try {
      const data = await getIndependentAgentsOptions(langCode || lang);
      const options = _.merge(data.actions.POST, initialOptions);
      dispatch(getIndependentAgentsOptionsData(...options));
    } catch (error) {
      commonErrorHandle({ error });
    } finally {
      dispatch(endFetchOptionsData());
    }
  };

export const beginFetchOptionsData = () => {
  return {
    type: INDEPENDENT_AGENTS_FETCHING_OPTIONS,
  };
};

export const endFetchOptionsData = () => {
  return {
    type: INDEPENDENT_AGENTS_FETCHED_OPTIONS,
  };
};

export const getIndependentAgentsOptionsData = (data) => {
  return {
    type: INDEPENDENT_AGENTS_FETCH_OPTIONS,
    data,
  };
};

export const fetchIndependentAgentsListData = (data) => {
  return {
    type: INDEPENDENT_AGENTS_FETCH_DATA,
    data: data,
  };
};

export const beginIndependentAgentsListData = () => {
  return { type: INDEPENDENT_AGENTS_BEGIN_FETCH_DATA };
};

export const endIndependentAgentsListData = () => {
  return { type: INDEPENDENT_AGENTS_END_FETCH_DATA };
};

// independentAgents profile
export const submitIndependentAgentsProfile =
  (fields, uploadingAction, errorAction = [], successAction, alert = true) =>
  async (dispatch, getState) => {
    dispatch(uploadingAction());

    const state = getState();
    const result = submitProcessor(
      state,
      "independentAgentsTravelServicesForm",
      travelServicesFrom,
      fields
    );

    if (result.hasError) {
      errorAction.forEach((action) => dispatch(action(result)));
      if (alert) {
        result.errorMsgs.forEach((e) => notifyCommonError(e.msg));
      }
      return null;
    }
    const req = {
      ...result.payload,
      main_service: state.independentAgentsMainServicesForm.value,
    };

    try {
      const data = await submitIndependentAgentsProfileAPI(
        state.independentAgentsProfile.id,
        req,
        "patch"
      );
      dispatch({ type: INDEPENDENT_AGENTS_PROFILE_DATA, data: data });
      dispatch(successAction());
      notifyCommonSuccess("Successfully updated your profile.");
    } catch (error) {
      error.ovrdErrHandle && error.ovrdErrHandle();
      notifyCommonError("Error updating your profile please try again.");
      dispatch(errorAction());
    }
  };

export const getIndependentAgentsProfileData = (data) => {
  return {
    type: INDEPENDENT_AGENTS_PROFILE_DATA,
    data,
  };
};

export const cleanIndependentAgentsBaseEditFormErrors = () => {
  return { type: INDEPENDENT_AGENTS_CLEAN_BASE_EDIT_FORM_ERRORS };
};

export const independentAgentsProfileData = (data) => (dispatch) => {
  const addresses = _.groupBy(data.address || [], "address_type");
  data.billingAddress = _.get(addresses, "BI.0") || {};
  data.postalAddress = _.get(addresses, "BU.0") || {};
  data.contactPerson = _.get(data, "contact_person.0") || {};
  dispatch(getIndependentAgentsProfileData(data));
};

export const beginFetchIndependentAgentsProfile = () => {
  return { type: INDEPENDENT_AGENTS_BEGIN_FETCH_PROFILE_DATA };
};

export const endFetchIndependentAgentsProfile = () => {
  return { type: INDEPENDENT_AGENTS_END_FETCH_PROFILE_DATA };
};

export const fetchIndependentAgentsProfile = (id) => async (dispatch) => {
  dispatch(beginFetchIndependentAgentsProfile());

  try {
    const data = await fetchIndependentAgentsProfileAPI(id);
    dispatch(independentAgentsProfileData(data));
    dispatch(
      initializeIndependentAgentsProfileMainServicesForm(data.main_service)
    );
    dispatch(
      initializeIndependentAgentsProfilerTravelServicesForm({
        value: data.travel_services,
      })
    );
  } catch (error) {
    commonErrorHandle({ error });
  } finally {
    dispatch(endFetchIndependentAgentsProfile());
  }
};

export const initializeIndependentAgentsProfileMainServicesForm = (data) => {
  return {
    type: INDEPENDENT_AGENTS_INITIALIZE_MAIN_SERVICES_FORM,
    data,
  };
};

export const initializeIndependentAgentsProfilerTravelServicesForm = (data) => {
  return {
    type: INDEPENDENT_AGENTS_INITIALIZE_TRAVEL_SERVICE_FORM,
    data,
  };
};

export const hideIndependentAgentsBaseEditForm = () => {
  return { type: INDEPENDENT_AGENTS_HIDE_BASE_DATA_EDIT_FORM };
};

export const editIndependentAgentsBaseDataForm = (data) => {
  return { type: INDEPENDENT_AGENTS_EDIT_BASE_DATA, data: data };
};

export const submitIndependentAgentsBaseDataEditForm =
  () => (dispatch, getState) => {
    const state = _.cloneDeep(getState());
    const independentAgentsProfile = state.independentAgentsProfile;
    const verifyStatus = state.isVerifyIndependentAgentsPostalAddress;
    const postalAddress = independentAgentsProfile.postalAddress;

    const file = state.independentAgentsBaseDataForm.original_image.fileName;
    const formData = new FormData();

    formData.append("original_image", file);
    formData.append("content_type", "independentagent");
    formData.append("object_id", getState().independentAgentsProfile.id);

    let country_code = "";
    if (state.independentAgentsBaseDataForm.country_code.value) {
      country_code =
        state.independentAgentsBaseDataForm.country_code.value.split("-")[0];
    }
    const billingAddressData = {
      street_number: state.independentAgentsBaseDataForm.street_number.value,
      street: state.independentAgentsBaseDataForm.street.value,
      country_code: country_code,
      region: state.independentAgentsBaseDataForm.region.value,
      city: state.independentAgentsBaseDataForm.city.value,
      post_code: state.independentAgentsBaseDataForm.post_code.value,
    };

    let postal_country_code = "";
    if (state.independentAgentsBaseDataForm.postal_country_code.value) {
      postal_country_code =
        state.independentAgentsBaseDataForm.postal_country_code.value.split(
          "-"
        )[0];
    }

    const postalAddressData = {
      street_number:
        state.independentAgentsBaseDataForm.postal_street_number.value,
      street: state.independentAgentsBaseDataForm.postal_street.value,
      country_code: postal_country_code,
      region: state.independentAgentsBaseDataForm.postal_region.value,
      city: state.independentAgentsBaseDataForm.postal_city.value,
      post_code: state.independentAgentsBaseDataForm.postal_post_code.value,
      lat: state.independentAgentsBaseDataForm.lat.value || postalAddress.lat,
      lng: state.independentAgentsBaseDataForm.lng.value || postalAddress.lng,
    };

    if (state.independentAgentsBaseDataFormErrors.valid == false) {
      for (const key in state.independentAgentsBaseDataFormErrors) {
        if (state.independentAgentsBaseDataFormErrors[key].hasError) {
          notifyCommonError(
            state.independentAgentsBaseDataFormErrors[key].errorMessage
          );
          return null;
        }
      }
      return null;
    }

    if (
      !postalAddress.lat &&
      !postalAddress.lng &&
      !state.independentAgentsBaseDataForm.lat.value &&
      !state.independentAgentsBaseDataForm.lng.value
    ) {
      return notifyCommonError(
        "Please Geolocate the map to get exact location"
      );
    }

    if (!verifyStatus) {
      return notifyCommonError("Please Verify the Address");
    }

    let entityData = {
      name: state.independentAgentsBaseDataForm.name.value,
      legal_title: state.independentAgentsBaseDataForm.legal_title.value,
      registration_code:
        state.independentAgentsBaseDataForm.registration_code.value,
      first_name: state.independentAgentsBaseDataForm.first_name.value,
      last_name: state.independentAgentsBaseDataForm.last_name.value,
      founding_year: state.independentAgentsBaseDataForm.founding_year.value,
      email: state.independentAgentsBaseDataForm.email.value,
      phone: state.independentAgentsBaseDataForm.phone.value,
      mobile_phone: state.independentAgentsBaseDataForm.mobile_phone.value,
      url: state.independentAgentsBaseDataForm.url.value,
      weekdays: state.independentAgentsBaseDataForm.weekdays.value,
      timezone: state.independentAgentsBaseDataForm.timezone.value,
      working_time_from:
        state.independentAgentsBaseDataForm.working_time_from.value + ":00",
      working_time_to:
        state.independentAgentsBaseDataForm.working_time_to.value + ":00",
      brief_description_en:
        state.independentAgentsBaseDataForm.brief_description_en.value,
      brief_description_cn:
        state.independentAgentsBaseDataForm.brief_description_cn.value,
      currency: state.independentAgentsBaseDataForm.currency.value,
    };

    // address
    const address = independentAgentsProfile.address;
    if (_.get(independentAgentsProfile, "billingAddress.id")) {
      filterEmptyValue(
        _.merge(
          _.find(address, {
            id: _.get(independentAgentsProfile, "billingAddress.id"),
          }),
          billingAddressData
        )
      );
    } else {
      if (!isEmptyObj(billingAddressData)) {
        billingAddressData.address_type = "BI";
        address.push(filterEmptyValue(billingAddressData));
      }
    }

    if (_.get(independentAgentsProfile, "postalAddress.id")) {
      filterEmptyValue(
        _.merge(
          _.find(address, {
            id: _.get(independentAgentsProfile, "postalAddress.id"),
          }),
          postalAddressData
        )
      );
    } else {
      if (!isEmptyObj(postalAddressData)) {
        postalAddressData.address_type = "BU";
        address.push(filterEmptyValue(postalAddressData));
      }
    }
    entityData.address = address;

    //contact person
    const contactPersonData = {
      first_name: state.independentAgentsBaseDataForm.first_name.value,
      last_name: state.independentAgentsBaseDataForm.last_name.value,
    };

    if (_.get(independentAgentsProfile, "contactPerson.id")) {
      _.merge(
        _.find(independentAgentsProfile.contact_person, {
          id: _.get(independentAgentsProfile, "contactPerson.id"),
        }),
        contactPersonData
      );
    } else {
      independentAgentsProfile.contact_person.push(
        filterEmptyValue(contactPersonData)
      );
    }
    entityData.contact_person = independentAgentsProfile.contact_person;

    entityData = filterEmptyValue(entityData);
    dispatch(loadingIndependentAgentsBaseDataEditForm());

    if (
      file !== undefined &&
      !(Object.prototype.toString.call(file) === "[object Object]")
    ) {
      uploadImgLink(formData)
        .then((res) => {
          const original_image = res.original_image;
          dispatch(
            uploadIndependentAgentsImages({
              name: "original_image",
              value: original_image,
            })
          );
          dispatch(
            submitIndependentAgentsProfileForm(
              entityData,
              state.independentAgentsBaseDataForm
            )
          );
        })
        .catch((error) => {
          dispatch(idleIndependentAgentsBaseDataEditForm());
          commonErrorHandle({ error });
        });
    } else {
      dispatch(
        submitIndependentAgentsProfileForm(
          entityData,
          state.independentAgentsBaseDataForm
        )
      );
    }

    return null;
  };

export const uploadIndependentAgentsImages = (data) => {
  return {
    type: INDEPENDENT_AGENTS_UPLOAD_PREFERENCE_IMAGE,
    data,
  };
};

export const submitIndependentAgentsProfileForm =
  (entityData, form) => async (dispatch, getState) => {
    try {
      const data = await submitIndependentAgentsProfileAPI(
        getState().independentAgentsProfile.id,
        entityData,
        "patch"
      );
      dispatch(independentAgentsProfileData(data));
      notifyCommonSuccess("Successfully updated your profile.");
    } catch (error) {
      error.ovrdErrHandle && error.ovrdErrHandle();
      const error400Handle = entityError400Handle(entityData, error.data, form);
      commonErrorHandle({ error, error400Handle });
    } finally {
      dispatch(idleIndependentAgentsBaseDataEditForm());
      dispatch(hideIndependentAgentsBaseEditForm());
    }
  };

export const loadingIndependentAgentsBaseDataEditForm = () => {
  return { type: INDEPENDENT_AGENTS_UPLOADING_BASE_DATA_EDIT_FORM };
};

export const idleIndependentAgentsBaseDataEditForm = () => {
  return { type: INDEPENDENT_AGENTS_IDLE_BASE_DATA_EDIT_FORM };
};

export const validateIndependentAgentsBaseDataEditForm =
  () => (dispatch, getState) => {
    const form = _.cloneDeep(getState().independentAgentsBaseDataForm);
    // add protocol to validate,but no send to backend
    if (form.url.value) {
      form.url.value = "http://" + form.url.value;
    }

    dispatch(validateIndependentAgentsBaseData(form));

    Promise.resolve().then(() =>
      dispatch(submitIndependentAgentsBaseDataEditForm())
    );
  };

// Base data edit section

export const showIndependentAgentsBaseEditForm = () => (dispatch, getState) => {
  const profile = getState().independentAgentsProfile;
  const address = profile.billingAddress || {};
  const postalAddress = profile.postalAddress || {};
  const independentAgentsContactPerson = profile.contactPerson || {};
  const formatCountryCodeList = getState().formatCountryCodeList;

  formatCountryCodeList.forEach((item) => {
    if (item.code && item.code == address.country_code) {
      address.country_code = item.value;
    } else {
      return null;
    }
  });
  formatCountryCodeList.forEach((item) => {
    if (item.code && item.code == postalAddress.country_code) {
      postalAddress.country_code = item.value;
    } else {
      return null;
    }
  });

  const data = update(initialIndependentAgentsBaseDataForm, {
    name: { value: { $set: profile.name } },
    legal_title: { value: { $set: profile.legal_title } },
    registration_code: { value: { $set: profile.registration_code } },
    founding_year: { value: { $set: profile.founding_year } },
    first_name: { value: { $set: independentAgentsContactPerson.first_name } },
    last_name: { value: { $set: independentAgentsContactPerson.last_name } },
    email: { value: { $set: profile.email } },
    mobile_phone: { value: { $set: profile.mobile_phone } },
    phone: { value: { $set: profile.phone } },
    url: { value: { $set: profile.url } },
    brief_description_en: { value: { $set: profile.brief_description_en } },
    brief_description_cn: { value: { $set: profile.brief_description_cn } },
    street_number: { value: { $set: address.street_number } },
    street: { value: { $set: address.street } },
    country_code: { value: { $set: address.country_code } },
    region: { value: { $set: address.region } },
    city: { value: { $set: address.city } },
    post_code: { value: { $set: address.post_code } },
    postal_street_number: { value: { $set: postalAddress.street_number } },
    postal_street: { value: { $set: postalAddress.street } },
    postal_country_code: { value: { $set: postalAddress.country_code } },
    postal_region: { value: { $set: postalAddress.region } },
    postal_city: { value: { $set: postalAddress.city } },
    postal_post_code: { value: { $set: postalAddress.post_code } },
    original_image: {
      value: {
        $set: profile.logo ? profile.logo.photo_sm_url : "",
      },
    },
    weekdays: {
      value: {
        $set: profile.weekdays || [
          false,
          false,
          false,
          false,
          false,
          false,
          false,
        ],
      },
    },
    timezone: { value: { $set: profile.timezone } },
    working_time_from: {
      value: {
        $set: parseInt((profile.working_time_from || "0").substr(0, 2)),
      },
    },
    working_time_to: {
      value: {
        $set: parseInt((profile.working_time_to || "0").substr(0, 2)),
      },
    },
    currency: {
      value: {
        $set: profile.currency,
      },
    },
  });
  dispatch(initializeBaseDataEditForm(data));
  dispatch(
    initializeIndependentAgentsAddressPoint({
      lat: postalAddress.lat || 0,
      lng: postalAddress.lng || 0,
    })
  );
  dispatch(closeIndependentAgentsVerifyAddress());
  let isSame =
    JSON.stringify({
      street_number: address.street_number,
      street: address.street,
      country_code: address.country_code,
      region: address.region,
      city: address.city,
      post_code: address.post_code,
    }) ==
    JSON.stringify({
      street_number: postalAddress.street_number,
      street: postalAddress.street,
      country_code: postalAddress.country_code,
      region: postalAddress.region,
      city: postalAddress.city,
      post_code: postalAddress.post_code,
    });
  if (
    !address.street_number &&
    !address.street &&
    !address.country_code &&
    !address.region &&
    !address.city &&
    !address.post_code
  ) {
    isSame = false;
  }
  dispatch(showIndependentAgentsBaseEditFormModal(isSame));
};

export const initializeIndependentAgentsAddressPoint = (data) => {
  return {
    type: INDEPENDENT_AGENTS_INITIAL_ADDRESS_POINT,
    data,
  };
};

export const closeIndependentAgentsVerifyAddress = () => {
  return {
    type: INDEPENDENT_AGENTS_CLOSE_VERIFY_ADDRESS,
  };
};

export const showIndependentAgentsBaseEditFormModal = (data) => {
  return {
    type: INDEPENDENT_AGENTS_SHOW_BASE_DATA_EDIT_FORM,
    data,
  };
};

export const initializeBaseDataEditForm = (data) => {
  return {
    type: INDEPENDENT_AGENTS_INITIALIZE_BASE_DATA_EDIT_FORM,
    data: data,
  };
};

export const cleanIndependentAgentsBaseEditForm = () => (dispatch) => {
  const data = update(initialIndependentAgentsBaseDataForm, {
    name: { required: { $set: false }, validator: { $set: null } },
  });
  dispatch(initializeBaseDataEditForm(data));
  dispatch(cleanIndependentAgentsBasicBaseEditForm());
  dispatch(cleanIndependentAgentsMapInfo());
  dispatch(initialIndependentAgentsVerifyStatus());
};

export const cleanIndependentAgentsMapInfo = () => {
  return { type: INDEPENDENT_AGENTS_CLEAN_ADDRESS_POINT };
};

export const initialIndependentAgentsVerifyStatus = () => {
  return { type: INDEPENDENT_AGENTS_INITIAL_VERIFY_STATUS };
};

export const cleanIndependentAgentsBasicBaseEditForm = () => {
  return { type: INDEPENDENT_AGENTS_CLEAN_BASE_DATA_EDIT_FORM };
};

export const fetchIndependentAgentsList =
  (cb) => async (dispatch, getState) => {
    const userMeta = getState().userMeta;
    const obj = {};
    obj[userMeta.company_type] = userMeta.company_id;

    dispatch(beginIndependentAgentsListData());

    const independentAgentsListFilter = _.cloneDeep(
      getState().independentAgentsListFilter
    );
    const payload = paramsProcessor(independentAgentsListFilter);
    const service_type = getState().travelServiceOptions.service_type || {};
    const filterParams = getFilter(payload, service_type);

    const req = {
      id: filterParams.id, // UID
      name__icontains: filterParams.brand_name, // brand name
      phone__contains: filterParams.phone, // Telephone
      mobile_phone__contains: filterParams.mobile_phone, // Contact Mobile
      contact_person__first_name__icontains: filterParams.first_name, // First Name
      contact_person__last_name__icontains: filterParams.last_name, // Last Name
      email__icontains: filterParams.email, // email
      business_address_street__icontains: filterParams.postal_address, // address
      business_address_city__icontains: filterParams.postal_city, // city
      business_address_post_code__icontains: filterParams.postal_post_code, // post code
      business_address_region__icontains: filterParams.postal_region, // region
      business_address_country_code__icontains: filterParams.postal_country, // country
      url__icontains: filterParams.url, // url
      registration_code__icontains: filterParams.registration_code, // registration code
      legal_title__icontains: filterParams.business_name, // business name
      tax_office__icontains: filterParams.tax_office, // tax office
      founding_year: filterParams.founding_year, // founding year
      license_type__contains: filterParams.license_type_choices, // license_type
      expertise_type__contains: filterParams.expertise_type, // expertise_type
      entity_type__contains: filterParams.entity_type, // tags
      travel_services__in: filterParams.travel_services, // travel_services
      status__in: filterParams.status, // status
      creator__icontains: filterParams.creator, // creator
      created__gte: filterParams.created__gte, //
      created__lte: filterParams.created__lte, //
      edited__gte: filterParams.edited__gte, //
      edited__lte: filterParams.edited__lte, //
    };

    const filter = filterEmptyValue(req);
    const pagination = getPagination(getState().independentAgentsList);

    try {
      const data = await getIndependentAgentsList({
        ...filter,
        ...pagination,
        ...obj,
      });
      if (typeof cb == "function") cb();
      dispatch(
        fetchIndependentAgentsListData({
          page: pagination.page,
          limit: pagination.limit,
          ...data,
        })
      );
    } catch (error) {
      commonErrorHandle({ error });
    } finally {
      dispatch(endIndependentAgentsListData());
    }
  };

export const modifyIndependentAgentsPage = (page) => (dispatch, getState) => {
  let independentAgentsList = getState().independentAgentsList;
  independentAgentsList = update(independentAgentsList, {
    page: { $set: page },
  });
  dispatch(fetchIndependentAgentsListData({ ...independentAgentsList }));
  dispatch(fetchIndependentAgentsList());
};

export const modifyIndependentAgentsLimit = (limit) => (dispatch, getState) => {
  let independentAgentsList = getState().independentAgentsList;
  independentAgentsList = update(independentAgentsList, {
    limit: { $set: limit },
    page: { $set: 1 },
  });
  dispatch(fetchIndependentAgentsListData({ ...independentAgentsList }));
  dispatch(fetchIndependentAgentsList());
};

// filter relation
export const updateIndependentAgentsListFilterForm = (payload) => {
  return { type: INDEPENDENT_AGENTS_UPDATE_LIST_FILTER_FORM, data: payload };
};

export const applyIndependentAgentsListFilter = () => (dispatch, getState) => {
  const state = getState().independentAgentsListFilterForm || {};
  dispatch(hideGlobalModal());
  dispatch(applyIndependentAgentsListFilterForm(state));
  dispatch(fetchIndependentAgentsList(1));
};

export const applyIndependentAgentsListFilterForm = (data) => {
  return {
    type: INDEPENDENT_AGENTS_APPLY_LIST_FILTER,
    data,
  };
};

export const initializeIndependentAgentsListFilterForm =
  () => (dispatch, getState) => {
    const state = getState().independentAgentsListFilter || {};
    dispatch(initializeIndependentAgentsListFilter(state));
  };

export const initializeIndependentAgentsListFilter = (data) => {
  return {
    type: INDEPENDENT_AGENTS_INITIALIZE_LIST_FILTER_FORM,
    data,
  };
};

export const resetIndependentAgentsListFilter = () => (dispatch, getState) => {
  const data = update(initialIndependentAgentsFilterForm, {
    $merge: getState().travelServicesForm,
  });
  dispatch(resetIndependentAgentsListFilterForm(data));
};

export const resetIndependentAgentsListFilterForm = (data) => {
  return {
    type: INDEPENDENT_AGENTS_RESET_LIST_FILTER_FORM,
    data,
  };
};

// columns relation
export const updateIndependentAgentsListColumnsForm =
  (field, value) => (dispatch, getState) => {
    const state = getState().independentAgentsListColumnsForm || {};
    if (field.type == "push") {
      value = update(new Set(state[field.name].value), { $add: [field.value] });
    }
    if (field.type == "remove") {
      value = update(new Set(state[field.name].value), {
        $remove: [field.value],
      });
    }
    if (field.type == "set") {
      value = field.value;
    }
    const independentAgentsListColumnsForm = update(state, {
      [field.name]: { value: { $set: [...value] } },
    });

    dispatch(
      updateIndependentAgentsListColumns(independentAgentsListColumnsForm)
    );
  };

export const updateIndependentAgentsListColumns = (data) => {
  return {
    type: INDEPENDENT_AGENTS_UPDATE_LIST_COLUMNS_FORM,
    data,
  };
};

export const applyIndependentAgentsListColumns = () => (dispatch, getState) => {
  const state = getState().independentAgentsListColumnsForm || {};
  dispatch(hideGlobalModal());
  dispatch(applyIndependentAgentsListColumnsForm(state));
};

export const applyIndependentAgentsListColumnsForm = (data) => {
  return {
    type: INDEPENDENT_AGENTS_APPLY_LIST_COLUMNS,
    data,
  };
};

export const initializeIndependentAgentsListColumnsForm =
  () => (dispatch, getState) => {
    const currentCols = getState().independentAgentsListColumns;
    dispatch(initializeIndependentAgentsListColumns(currentCols));
  };

export const initializeIndependentAgentsListColumns = (data) => {
  return {
    type: INDEPENDENT_AGENTS_INITIALIZE_LIST_COLUMNS_FORM,
    data,
  };
};

// Nature of Biz section.
export const viewIndependentAgentsNatureSection = () => {
  return { type: INDEPENDENT_AGENTS_VIEW_NATURE_OF_BIZ };
};

export const editIndependentAgentsNatureSection =
  (initForm = true) =>
  (dispatch, getState) => {
    if (initForm) {
      const profile = getState().independentAgentsProfile;
      const data = update(initialIndependentAgentsNatureOfBizForm, {
        expertise_type: { value: { $set: profile.expertise_type || [] } },
      });
      dispatch(initializeNatureDataForm(data));
    }
    dispatch(editIndependentAgentsNature());
  };

export const editIndependentAgentsNature = () => {
  return {
    type: INDEPENDENT_AGENTS_EDIT_NATURE_OF_BIZ,
  };
};

export const initializeNatureDataForm = (data) => {
  return { type: INDEPENDENT_AGENTS_INITIALIZE_NATURE_DATA_FORM, data: data };
};

export const editBaseNatureDataForm = (data) => {
  return { type: INDEPENDENT_AGENTS_EDIT_NATURE_OF_BIZ_DATA, data: data };
};

export const submitIndependentAgentsNatureOfBizData =
  () => async (dispatch, getState) => {
    dispatch(loadingSubmitIndependentAgentsNatureOfBizData());

    const state = getState();
    const entityId = state.independentAgentsProfile.id;
    const result = submitProcessor(
      state,
      "independentAgentsNatureOfBizForm",
      initialIndependentAgentsNatureOfBizForm
    );

    if (result.hasError) {
      dispatch(editIndependentAgentsNatureSection(false));
      result.errorMsgs.forEach((e) => notifyCommonError(e.msg));
      return null;
    }

    try {
      const data = await submitIndependentAgentsProfileAPI(
        entityId,
        result.payload,
        "patch"
      );
      dispatch(independentAgentsProfileData(data));
      notifyCommonSuccess("Successfully updated Nature of Business.");
    } catch (error) {
      notifyCommonError(
        "Error submitting Nature of Business data. Please try again."
      );
      error.ovrdErrHandle && error.ovrdErrHandle();
    } finally {
      dispatch(viewIndependentAgentsNatureSection());
    }
  };

export const loadingSubmitIndependentAgentsNatureOfBizData = () => {
  return {
    type: INDEPENDENT_AGENTS_SUBMIT_NATURE_OF_BIZ_DATA,
  };
};

export const independentAgentsLicenseCodeEdit = (data) => {
  return { type: INDEPENDENT_AGENTS_EDIT_LICENSE_CODE, data: data };
};

export const synchronizeIndependentAgentsPostalAddress =
  (checked) => (dispatch, getState) => {
    const form = getState().independentAgentsBaseDataForm;

    const data = update(form, {
      street_number: { value: { $set: form.postal_street_number.value } },
      street: { value: { $set: form.postal_street.value } },
      country_code: { value: { $set: form.postal_country_code.value } },
      region: { value: { $set: form.postal_region.value } },
      city: { value: { $set: form.postal_city.value } },
      post_code: { value: { $set: form.postal_post_code.value } },
    });
    dispatch(toggleIndependentAgentsPostalAddress(checked));
    if (checked) {
      dispatch(initializeBaseDataEditForm(data));
    }
  };

export const toggleIndependentAgentsPostalAddress = (data) => {
  return {
    type: INDEPENDENT_AGENTS_TOGGLE_POSTAL_ADDRESS,
    data,
  };
};

// img relation
export const changeIndependentAgentImg = (data) => (dispatch) => {
  dispatch(
    changeUploadImgFactory(data, INDEPENDENT_AGENTS_UPLOAD_PREFERENCE_IMAGE)
  );
};

export const applyIndependentAgentsDefaultOption =
  () => (dispatch, getState) => {
    let form = getState().independentAgentsListColumnsForm;

    for (const key in defaultOption) {
      form = update(form, {
        [key]: { value: { $set: defaultOption[key].value } },
      });
    }
    dispatch(applyIndependentAgentsDefaultOptionForm(form));
  };

export const applyIndependentAgentsDefaultOptionForm = (data) => {
  return {
    type: INDEPENDENT_AGENTS_UPDATE_LIST_COLUMNS_FORM,
    data,
  };
};

//fetchGeolocation

export const beginIndependentAgentsAddressPoint = () => {
  return { type: INDEPENDENT_AGENTS_BEGIN_FETCH_ADDRESS_DATA };
};

export const endIndependentAgentsAddressPoint = () => {
  return { type: INDEPENDENT_AGENTS_END_FETCH_ADDRESS_DATA };
};

export const fetchIndependentAgentsAddressPoint =
  () => (dispatch, getState) => {
    const state = getState();
    const address = state.independentAgentsBaseDataForm;
    dispatch(
      fetchCompleteAddressFactory(address, fetchCompanyAddressPointDetail)
    );
  };

export const fetchCompanyAddressPointDetail = (data) => (dispatch) => {
  dispatch(
    fetchAddressPointDetailFactory(
      data,
      INDEPENDENT_AGENTS_FETCH_ADDRESS_POINT,
      editIndependentAgentsBaseDataForm,
      beginIndependentAgentsAddressPoint,
      endIndependentAgentsAddressPoint
    )
  );
};

export const verifyIndependentAgentsPostalAddress = (checked) => {
  return {
    type: INDEPENDENT_AGENTS_TOGGLE_VERIFY_ADDRESS,
    data: checked,
  };
};

//======================== User Email Config Section========================

export const viewIndependentAgentsEmailConfigSection = () => {
  return {
    type: INDEPENDENT_AGENTS_VIEW_EMAIL_CONFIG_MODE,
  };
};

export const editIndependentAgentsEmailConfigSection = () => {
  return { type: INDEPENDENT_AGENTS_EDIT_EMAIL_CONFIG_MODE };
};

export const loadingIndependentAgentsEmailConfigMode = () => {
  return { type: INDEPENDENT_AGENTS_LOADING_EMAIL_CONFIG_MODE };
};

export const editIndependentAgentsEmailConfigDataForm = (data) => {
  return {
    type: INDEPENDENT_AGENTS_EDIT_EMAIL_CONFIG_FORM,
    data,
  };
};

export const initializeIndependentAgentsEmailConfigDataForm =
  () => (dispatch, getState) => {
    const state = getState();
    const res = state.independentAgentsProfile.allowed_email_info;
    if (res) {
      dispatch(
        initializeIndependentAgentsEmailConfigData({
          email_config: { value: res },
        })
      );
    }
  };

export const initializeIndependentAgentsEmailConfigData = (data) => {
  return {
    type: INDEPENDENT_AGENTS_INITIALIZE_EMAIL_CONFIG_FORM,
    data,
  };
};

export const submitIndependentAgentsEmailConfigData =
  () => async (dispatch, getState) => {
    const state = getState();
    const profile_id = state.independentAgentsProfile.id;
    const form = state.independentAgentsEmailConfigForm;
    const res = form.email_config.value;
    const payload = {
      allowed_email_info: res,
    };
    dispatch(loadingIndependentAgentsEmailConfigMode());
    try {
      const data = await submitIndependentAgentsProfileAPI(
        profile_id,
        payload,
        "patch"
      );
      notifyCommonSuccess("Successfully updated your profile.");
      dispatch(getIndependentAgentsProfileData(data));
    } catch (error) {
      console.log(error);
      if (error.status == 500) {
        notifyCommonError(
          "Failed updated your profile, we are sorry for this inconvenience"
        );
      }
      commonErrorHandle({ error });
    } finally {
      dispatch(viewIndependentAgentsEmailConfigSection());
    }
  };
