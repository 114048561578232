import Axios from "axios";
import _ from "lodash";
import * as Yup from "yup";

export const getCarrierIcon = async (carrier) => {
  try {
    const response = await Axios.get(
      `https://era.raileurope.com/assets/images/operating-carriers/${carrier}.svg`
    );
    return _.get(response, "data", {});
  } catch (error) {
    console.error(error);
  }
  return null;
};

export const filtersSchema = Yup.object().shape({
  max_price: Yup.mixed().nullable().default(""),
  min_price: Yup.mixed().nullable().default(""),
  minArrival: Yup.mixed().nullable().default(""),
  maxArrival: Yup.mixed().nullable().default(""),
  minDeparture: Yup.mixed().nullable().default(""),
  maxDeparture: Yup.mixed().nullable().default(""),
  order_by: Yup.string()
    .oneOf([
      "price",
      "-price",
      "duration",
      "-duration",
      "arrival",
      "-arrival",
      "departure",
      "-departure",
    ])
    .nullable()
    .default(""),
  operator: Yup.string().oneOf(["TGV", "Eurostar"]).nullable().default(""),
});

export const defaultFilters = Yup.object().shape({
  max_price: Yup.mixed().nullable().default(""),
  min_price: Yup.mixed().nullable().default(""),
  minArrival: Yup.mixed().nullable().default(""),
  maxArrival: Yup.mixed().nullable().default(""),
  minDeparture: Yup.mixed().nullable().default(""),
  maxDeparture: Yup.mixed().nullable().default(""),
  order_by: Yup.string()
    .oneOf([
      "price",
      "-price",
      "duration",
      "-duration",
      "arrival",
      "-arrival",
      "departure",
      "-departure",
    ])
    .nullable()
    .default("price"),
  operator: Yup.string().oneOf(["TGV", "Eurostar"]).nullable().default(""),
});
