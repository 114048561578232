import _ from "lodash";
import { getActivityPriceInfo } from "@src/api/Project/activities";
import { NormalSelectField } from "@src/components/forms";
import { useQuery } from "@tanstack/react-query";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { Loader, Placeholder } from "rsuite";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { addActivityToDay } from "@src/actions/Project/TripPlanner/activities";
import { PriceExcHoc } from "../../../../PriceDetails";

export function getSelectedActivityAttrs({ selectionInfo, bookingData }) {
  const groups = bookingData?.schedules?.[0].groups ?? [];

  const selectedGroup = groups.find((grp) => grp.name === selectionInfo.group);
  const selectedSlot = selectedGroup?.slots.find((slot) =>
    slot.time.includes(selectionInfo.slot)
  );
  const selectedProducts = Object.entries(selectionInfo.products)
    .filter(([__, quantity]) => quantity > 0)
    .map(([product_id, quantity]) => ({
      ...selectedSlot.products.find((prod) => prod.product_id === product_id),
      quantity,
    }));
  const totalPrice = selectedProducts.reduce(
    (a, b) => a + (b?.price?.value ?? 0) * (b?.quantity ?? 0),
    0
  );
  const currency = selectedProducts?.[0]?.price?.currency ?? "EUR";

  const availableLanguages = Object.entries(
    _.groupBy(
      _.flatten((selectedProducts ?? []).map((p) => p.languages)),
      (v) => v.code
    )
  ).map(([code, lngs]) => ({ code, name: lngs[0].name }));

  return {
    selectedGroup,
    selectedSlot,
    selectedProducts,
    totalPrice,
    currency,
    availableLanguages,
  };
}

export const ActivityPriceModal = ({
  activity,
  session_id,
  activity_uid,
  date,
  destination_id,
  destOrder,
  onCancel,
}) => {
  const { data, isLoading } = useQuery({
    queryKey: ["activity-price-info", session_id, activity_uid, date],
    queryFn: () => getActivityPriceInfo({ activity_uid, session_id, date }),
    refetchOnWindowFocus: false,
    onSuccess: () => {
      toast.success("Price information fetched successfully", {
        autoClose: 5000,
      });
    },
    onError: () => {
      onCancel();
      toast.error("We couldn't fetch price information for this activity", {
        autoClose: 5000,
      });
    },
  });
  const bookingData = data?.data ?? {};
  const groups = bookingData?.schedules?.[0].groups ?? [];

  const dispatch = useDispatch();
  const onAddToTrip = useCallback(
    ({
      activity,
      date,
      destination_id,
      destOrder,
      selectionInfo,
      bookingData,
    }) => {
      const tmp = _.cloneDeep(activity);
      dispatch(
        addActivityToDay({
          activity: {
            ...tmp,
            destination_id,
            destOrder,
            selectionInfo,
            bookingData,
          },
          date,
        })
      );
      toast.success(`Activity added succesfully.`, { autoClose: 6000 });
    },
    [dispatch]
  );

  return (
    <div className="Modal ActivityPriceModal">
      {isLoading ? (
        <Loader size="lg" center />
      ) : (
        <Formik
          initialValues={{
            date,
            group: null,
            slot: null,
            products: {},
            language: null,
            pickup: null,
          }}
          onSubmit={async (values) => {
            await onAddToTrip({
              activity,
              date,
              destination_id,
              destOrder,
              selectionInfo: values,
              bookingData,
            });
            onCancel();
          }}>
          {({ values, resetForm, submitForm, setFieldValue }) => {
            const {
              selectedGroup,
              selectedSlot,
              selectedProducts,
              totalPrice,
              availableLanguages,
            } = getSelectedActivityAttrs({
              selectionInfo: values,
              bookingData,
            });

            return (
              <div className="Modal__card">
                <div className="Modal__card__header">
                  <h5>Activity Price Information</h5>
                </div>
                <div className="Modal__card__body">
                  {isLoading ? (
                    <React.Fragment>
                      <Placeholder.Paragraph rows={2} active />
                      <br />
                      <br />
                      <Placeholder.Paragraph rows={2} active />
                    </React.Fragment>
                  ) : (
                    <div /* className={classes.containers} */>
                      <Form
                        /* className={classes.form} */ className="ActivityPriceModal__form">
                        <React.Fragment>
                          <NormalSelectField
                            name="group"
                            label="Ticket Options"
                            options={[
                              [null, "------"],
                              ..._.sortBy(
                                groups.map((gr) => [gr.name, gr.name]),
                                (v) => v[1]
                              ),
                            ]}
                          />
                          {values.group && (
                            <NormalSelectField
                              name="slot"
                              label="Slot"
                              options={[
                                [null, "------"],
                                ...(selectedGroup?.slots ?? []).map((slot) => [
                                  slot.time.substring(0, slot.time.length - 3),
                                  slot.time.substring(0, slot.time.length - 3),
                                ]),
                              ]}
                            />
                          )}
                          {values.slot && (
                            <React.Fragment>
                              <span>
                                <strong>Products</strong>
                              </span>
                              {(selectedSlot?.products ?? []).map((prod) => {
                                const currentQuantity =
                                  values?.products?.[prod.product_id] ?? 0;
                                const pKey = `products.${prod.product_id}`;

                                return (
                                  <div className="ActivityPriceModal__product-controller">
                                    <span>
                                      {prod.name}:{" "}
                                      {prod.price.value.toLocaleString(
                                        "en-US",
                                        {
                                          style: "currency",
                                          currency: prod.price.currency,
                                        }
                                      )}
                                    </span>
                                    <div className="ActivityPriceModal__product-controller__controls">
                                      <Icon
                                        className="ActivityPriceModal__product-controller__icon"
                                        icon="ic:round-minus"
                                        onClick={() =>
                                          currentQuantity > prod.min_pax &&
                                          setFieldValue(
                                            pKey,
                                            currentQuantity - 1
                                          )
                                        }
                                      />
                                      <span>{currentQuantity}</span>
                                      <Icon
                                        className="ActivityPriceModal__product-controller__icon"
                                        icon="ic:round-plus"
                                        onClick={() =>
                                          currentQuantity < prod.max_pax &&
                                          setFieldValue(
                                            pKey,
                                            currentQuantity === 0
                                              ? prod.min_pax
                                              : currentQuantity + 1
                                          )
                                        }
                                      />
                                    </div>
                                    <span>
                                      Available Languages:{" "}
                                      {(prod?.languages ?? [])
                                        .map((lang) => lang.name)
                                        .join(", ")}
                                    </span>
                                  </div>
                                );
                              })}
                              {!(availableLanguages ?? []).length ? (
                                <span>
                                  Languages: Only available in English
                                </span>
                              ) : (
                                <NormalSelectField
                                  name="language"
                                  label="Languages"
                                  options={[
                                    [null, "------"],
                                    ...availableLanguages.map((lng) => [
                                      lng.code,
                                      lng.name,
                                    ]),
                                  ]}
                                />
                              )}
                              {selectedProducts.some(
                                (pr) =>
                                  pr.guest_type === "ADT" && pr.quantity > 0
                              ) && (
                                <div className="ActivityPriceModal__total-price">
                                  <span>Total Price</span>
                                  <div>
                                    {totalPrice.toLocaleString("en-US", {
                                      style: "currency",
                                      currency:
                                        selectedProducts[0].price.currency,
                                    })}
                                  </div>
                                </div>
                              )}
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      </Form>
                    </div>
                  )}
                </div>
                <div className="Modal__card__actions">
                  <button
                    className="Button"
                    data-ghost="true"
                    onClick={onCancel}>
                    Cancel
                  </button>
                  <button className="Button" onClick={resetForm}>
                    Reset Form
                  </button>
                  <button className="Button" onClick={submitForm}>
                    Submit
                  </button>
                </div>
              </div>
            );
          }}
        </Formik>
      )}
    </div>
  );
};
ActivityPriceModal.propTypes = {
  activity: PropTypes.object.isRequired,
  session_id: PropTypes.string.isRequired,
  activity_uid: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  destination_id: PropTypes.string.isRequired,
  destOrder: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
};
