import { SISI2_API_URL } from "@src/api";
import service, { getAuthHeaders } from "@src/api/request";
import axios from "axios";

export function getMembersList(param) {
  return service({
    url: "/bid/network-member/",
    method: "get",
    params: param,
  });
}

export function sendEmailInvitation(params) {
  return service({
    url: "/bid/entity-invitation/",
    method: "post",
    data: params,
  });
}

export async function fetchNetworkMembers(params) {
  const auth_headers = getAuthHeaders();
  return await axios.get(`${SISI2_API_URL}/crm/entity/network-members/`, {
    params,
    headers: { ...auth_headers },
  });
}
