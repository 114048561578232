export const BRANCHES_FETCH_DATA = "BRANCHES_FETCH_DATA";
export const BRANCHES_BEGIN_FETCH_DATA = "BRANCHES_BEGIN_FETCH_DATA";
export const BRANCHES_END_FETCH_DATA = "BRANCHES_END_FETCH_DATA";
export const BRANCHES_INITIALIZE_LIST_FILTER_FORM =
  "BRANCHES_INITIALIZE_LIST_FILTER_FORM";
export const BRANCHES_UPDATE_LIST_FILTER_FORM =
  "BRANCHES_UPDATE_LIST_FILTER_FORM";
export const BRANCHES_APPLY_LIST_FILTER = "BRANCHES_APPLY_LIST_FILTER";
export const BRANCHES_UPDATE_LIST_COLUMNS_FORM =
  "BRANCHES_UPDATE_LIST_COLUMNS_FORM";
export const BRANCHES_APPLY_LIST_COLUMNS = "BRANCHES_APPLY_LIST_COLUMNS";
export const BRANCHES_INITIALIZE_LIST_COLUMNS_FORM =
  "BRANCHES_INITIALIZE_LIST_COLUMNS_FORM";
export const BRANCHES_FETCH_OPTIONS = "BRANCHES_FETCH_OPTIONS";
export const BRANCHES_FETCH_PROFILE = "BRANCHES_FETCH_PROFILE";
export const BRANCHES_INITIALIZE_BASE_DATA_EDIT_FORM =
  "BRANCHES_INITIALIZE_BASE_DATA_EDIT_FORM";
export const BRANCHES_HIDE_BASE_DATA_EDIT_FORM =
  "BRANCHES_HIDE_BASE_DATA_EDIT_FORM";
export const BRANCHES_SHOW_BASE_DATA_EDIT_FORM =
  "BRANCHES_SHOW_BASE_DATA_EDIT_FORM";
export const BRANCHES_VALIDATE_BASE_DATA_EDIT_FORM =
  "BRANCHES_VALIDATE_BASE_DATA_EDIT_FORM";
export const BRANCHES_PROFILE_DATA = "BRANCHES_PROFILE_DATA";
export const BRANCHES_UPLOADING_BASE_DATA_EDIT_FORM =
  "BRANCHES_UPLOADING_BASE_DATA_EDIT_FORM";
export const BRANCHES_IDLE_BASE_DATA_EDIT_FORM =
  "BRANCHES_IDLE_BASE_DATA_EDIT_FORM";
export const BRANCHES_EDIT_BASE_DATA = "BRANCHES_EDIT_BASE_DATA";
export const BRANCHES_CLEAN_BASE_EDIT_FORM_ERRORS =
  "BRANCHES_CLEAN_BASE_EDIT_FORM_ERRORS";
export const BRANCHES_CLEAN_BASE_DATA_EDIT_FORM =
  "BRANCHES_CLEAN_BASE_DATA_EDIT_FORM";
export const BRANCHES_VIEW_NATURE_OF_BIZ = "BRANCHES_VIEW_NATURE_OF_BIZ";
export const BRANCHES_SUBMIT_NATURE_OF_BIZ_DATA =
  "BRANCHES_SUBMIT_NATURE_OF_BIZ_DATA";
export const BRANCHES_EDIT_NATURE_OF_BIZ_DATA =
  "BRANCHES_EDIT_NATURE_OF_BIZ_DATA";
export const BRANCHES_INITIALIZE_NATURE_DATA_FORM =
  "BRANCHES_INITIALIZE_NATURE_DATA_FORM";
export const BRANCHES_EDIT_NATURE_OF_BIZ = "BRANCHES_EDIT_NATURE_OF_BIZ";
export const BRANCHES_EDIT_LICENSE_CODE = "BRANCHES_EDIT_LICENSE_CODE";
export const BRANCHES_TOGGLE_POSTAL_ADDRESS = "BRANCHES_TOGGLE_POSTAL_ADDRESS";
export const BRANCHES_RESET_LIST_FILTER_FORM =
  "BRANCHES_RESET_LIST_FILTER_FORM";

// ======================== Service categories =======================
export const BRANCHES_SELECT_SERVICE_CATEGORY =
  "BRANCHES_SELECT_SERVICE_CATEGORY";
export const BRANCHES_SELECT_TRAVEL_SERVICE = "BRANCHES_SELECT_TRAVEL_SERVICE";
export const BRANCHES_INITIALIZE_TRAVEL_SERVICE_FORM =
  "BRANCHES_INITIALIZE_TRAVEL_SERVICE_FORM";

export const BRANCHES_VIEW_SERVICE_CATEGORIES_MODE =
  "BRANCHES_VIEW_SERVICE_CATEGORIES_MODE";
export const BRANCHES_EDIT_SERVICE_CATEGORIES_MODE =
  "BRANCHES_EDIT_SERVICE_CATEGORIES_MODE";
export const BRANCHES_LOADING_SERVICE_CATEGORY_MODE =
  "BRANCHES_LOADING_SERVICE_CATEGORY_MODE";
export const BRANCHES_INITIALIZE_MAIN_SERVICES_FORM =
  "BRANCHES_INITIALIZE_MAIN_SERVICES_FORM";
export const BRANCHES_UPDATE_MAIN_SERVICES_FORM =
  "BRANCHES_UPDATE_MAIN_SERVICES_FORM";

//img relation
export const BRANCHES_UPLOAD_PREFERENCE_IMAGE =
  "BRANCHES_UPLOAD_PREFERENCE_IMAGE";
export const BRANCHES_FETCH_ADDRESS_POINT = "BRANCHES_FETCH_ADDRESS_POINT";
export const BRANCHES_INITIAL_ADDRESS_POINT = "BRANCHES_INITIAL_ADDRESS_POINT";
export const BRANCHES_TOGGLE_VERIFY_ADDRESS = "BRANCHES_TOGGLE_VERIFY_ADDRESS";
export const BRANCHES_BEGIN_FETCH_ADDRESS_DATA =
  "BRANCHES_BEGIN_FETCH_ADDRESS_DATA";
export const BRANCHES_END_FETCH_ADDRESS_DATA =
  "BRANCHES_END_FETCH_ADDRESS_DATA";
export const BRANCHES_BEGIN_FETCH_PROFILE_DATA =
  "BRANCHES_BEGIN_FETCH_PROFILE_DATA";
export const BRANCHES_END_FETCH_PROFILE_DATA =
  "BRANCHES_END_FETCH_PROFILE_DATA";
export const BRANCHES_CLOSE_VERIFY_ADDRESS = "BRANCHES_CLOSE_VERIFY_ADDRESS";
export const BRANCHES_CLEAN_ADDRESS_POINT = "BRANCHES_CLEAN_ADDRESS_POINT";
export const BRANCHES_INITIAL_VERIFY_STATUS = "BRANCHES_INITIAL_VERIFY_STATUS";

//======================= Email Config Section==========================
export const BRANCHES_VIEW_EMAIL_CONFIG_MODE =
  "BRANCHES_VIEW_EMAIL_CONFIG_MODE";
export const BRANCHES_EDIT_EMAIL_CONFIG_MODE =
  "BRANCHES_EDIT_EMAIL_CONFIG_MODE";
export const BRANCHES_LOADING_EMAIL_CONFIG_MODE =
  "BRANCHES_LOADING_EMAIL_CONFIG_MODE";
export const BRANCHES_EDIT_EMAIL_CONFIG_FORM =
  "BRANCHES_EDIT_EMAIL_CONFIG_FORM";
export const BRANCHES_INITIALIZE_EMAIL_CONFIG_FORM =
  "BRANCHES_INITIALIZE_EMAIL_CONFIG_FORM";
