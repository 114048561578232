import { injectIntl } from "react-intl";

import { Icon, IconButton, Loader } from "rsuite";

import {
  itnSelectOriginDate,
  itnRemoveReturnPoint,
  itnSelectExtremalDest,
} from "@src/actions/Project/TripPlanner";

import ItineraryDestinations from "./ItineraryDestinations";

import TransTxt from "@src/components/common/SxFormatMessage";

import _ from "lodash";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";
import { getSetupFormDataSelector } from "@src/selectors/Project/TripPlanner";
import { DestAutoComplete } from "./DestAutoComplete";
import { createUseStyles } from "react-jss";
import { formStyles, variables } from "@src/jsssetup";
import { DateTime } from "luxon";
import { Form, Formik } from "formik";
import { NormalDatePicker } from "@src/components/forms";

const ghostDest =
  "https://sisistaticassets.s3.ap-southeast-1.amazonaws.com/platform_graphics/imgs/others/destination_placeholder.jpg";

export class DestinationImage extends React.PureComponent {
  constructor(props) {
    super(props);
  }
  imageGetter() {
    const { images } = this.props;

    var imgs = images
      .filter((img) => img)
      .filter((img) => _.get(img, "enabled", true));

    if (!imgs.length) {
      return { src: ghostDest };
    }

    const img =
      imgs.sort((a, b) => a.priority - b.priority).find((i) => i.primary) ||
      imgs[0];

    if (typeof img == "undefined") {
      return {
        src: ghostDest,
      };
    } else {
      return { src: img.photo_sm_url, className: "" };
    }
  }
  render() {
    const meta = this.imageGetter();
    const { custom_images } = this.props;

    var tmp_images = custom_images.filter((img) => img.main_photo);
    if (tmp_images.length >= 1) {
      return <img alt="" src={tmp_images[0].big_thumbnail_url} />;
    }

    return <img alt="" src={meta.src} className={meta.className} />;
  }
}
DestinationImage.defaultProps = {
  images: [],
  custom_images: [],
};
DestinationImage.propTypes = {
  cls: PropTypes.string,
  images: PropTypes.array.isRequired,
  custom_images: PropTypes.array,
};

const extremalPointStyles = createUseStyles({
  ExtremalPoint: (props) => ({
    "display": "grid",
    "gridGap": variables.half_gap,
    "gridTemplateColumns": "1fr",
    "padding": variables.normal_gap,
    "boxShadow": variables.shadows.normalShadow,
    "borderRadius": variables.normal_gap,
    "&:hover": {
      boxShadow: variables.shadows.hoverShadow,
    },
  }),
  controls: {
    display: "grid",
    placeItems: "center",
  },
  content: {
    display: "grid",
    gridTemplateColumns: "1fr 15rem",
    gridGap: variables.normal_gap,
    position: "relative",
  },
  imgContainer: {
    "height": "10rem",
    "width": "100%",
    "& img": {
      height: "10rem",
      width: "100%",
      objectFit: "cover",
      borderRadius: variables.half_gap,
    },
  },
  form: {
    ...formStyles.form,
    display: "grid",
    gridGap: variables.half_gap,
    gridAutoRows: "max-content",
  },
  inputGroup: {
    display: "grid",
    gridAutoRows: "max-content",
  },
  destLabel: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  dateTag: {
    fontWeight: "bold",
    background: variables.colors.background.dark,
    color: "white",
    padding: variables.half_gap,
    borderRadius: variables.half_gap,
  },
  [`@media ${variables.media.bigscreen}`]: {
    content: {
      gridTemplateColumns: "1fr 20rem",
    },
  },
});
function ExtremalPoint({
  lang,
  data,
  type,
  disabled,
  loading,
  onDateSelect,
  onReturnRemove,
  onSelect,
}) {
  const classes = extremalPointStyles({ pointType: type });

  if (_.isEmpty(data)) {
    return null;
  }

  return (
    <div className={classes.ExtremalPoint}>
      {type === "return" && !disabled && (
        <div className={classes.controls}>
          <IconButton
            icon={<Icon icon="minus" />}
            color="red"
            size="xs"
            circle
            onClick={onReturnRemove}
          />
        </div>
      )}
      <div className={classes.content}>
        {loading && <Loader vertical backdrop />}
        <Formik
          initialValues={{ departure: data.date }}
          validate={(values) => onDateSelect(values.departure)}>
          <Form className={classes.form}>
            <div className={classes.inputGroup}>
              <span>
                <strong>{type == "origin" ? "Origin" : "Return"}</strong>
              </span>
              {disabled ? (
                <h5 className={classes.destLabel}>
                  {_.get(data, "destination.query")}
                </h5>
              ) : (
                <DestAutoComplete
                  disabled={disabled}
                  lang={lang}
                  id={type}
                  value={_.get(data, "destination.query")}
                  onSelect={function (id, lvl, query) {
                    onSelect(id, lvl, query);
                  }}
                />
              )}
            </div>
            {disabled ? (
              <span className={classes.dateTag}>
                {DateTime.fromISO(data.date).toLocaleString(DateTime.DATE_FULL)}
              </span>
            ) : (
              type === "origin" && (
                <NormalDatePicker name="departure" label="Departure" />
              )
            )}
          </Form>
        </Formik>
        <div className={classes.imgContainer}>
          <DestinationImage images={data.destination.images} />
        </div>
      </div>
    </div>
  );
}
ExtremalPoint.propTypes = {
  intl: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(["origin", "return"]),
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  onReturnRemove: PropTypes.func,
  onDateSelect: PropTypes.func.isRequired,
};

const itineraryControllerStyles = createUseStyles({
  ItineraryController: {
    padding: variables.normal_gap,
    height: "100%",
    overflow: "auto",
    display: "grid",
    gridGap: variables.normal_gap,
    justifyContent: "center",
    gridAutoRows: "max-content",
  },
});
const ItineraryController = ({
  intl,
  lang,
  currentStep,
  originData,
  returnData,
  requiredServices,
  originLoading,
  returnLoading,
  onOriginSelect,
  onOriginDateSelect,
  onReturnSelect,
  onReturnRemove,
}) => {
  const classes = itineraryControllerStyles();

  return (
    <div className={classes.ItineraryController}>
      {requiredServices.includes("TR") && (
        <ExtremalPoint
          intl={intl}
          lang={lang}
          disabled={currentStep > 1}
          type="origin"
          data={originData}
          loading={originLoading}
          onSelect={onOriginSelect}
          onDateSelect={onOriginDateSelect}
        />
      )}
      <ItineraryDestinations />
      {requiredServices.includes("TR") && (
        <ExtremalPoint
          intl={intl}
          lang={lang}
          disabled={currentStep > 1}
          type="return"
          data={returnData}
          loading={returnLoading}
          onSelect={onReturnSelect}
          onDateSelect={onOriginDateSelect}
          onReturnRemove={onReturnRemove}
        />
      )}
    </div>
  );
};
ItineraryController.propTypes = {
  intl: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  currentStep: PropTypes.number.isRequired,
  originData: PropTypes.object.isRequired,
  returnData: PropTypes.object.isRequired,
  requiredServices: PropTypes.array.isRequired,
  originLoading: PropTypes.bool.isRequired,
  returnLoading: PropTypes.bool.isRequired,
  onOriginSelect: PropTypes.func.isRequired,
  onOriginDateSelect: PropTypes.func.isRequired,
  onReturnRemove: PropTypes.func.isRequired,
  onReturnSelect: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  const { requiredServices } = getSetupFormDataSelector(state);
  const originLoading = _.get(
    state.tripPlannerDestinationsLoadingStatus,
    "origin",
    false
  );
  const returnLoading = _.get(
    state.tripPlannerDestinationsLoadingStatus,
    "return",
    false
  );

  return {
    lang: state.setLang,
    currentStep: state.tripPlannerCurrentStep,
    originData: state.tripPlannerOriginData,
    returnData: state.tripPlannerReturnData,
    requiredServices,
    originLoading,
    returnLoading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onOriginSelect: (id, lvl, query) =>
      dispatch(itnSelectExtremalDest(id, lvl, query, "origin")),
    onOriginDateSelect: (date) => dispatch(itnSelectOriginDate(date)),
    onReturnRemove: () => dispatch(itnRemoveReturnPoint()),
    onReturnSelect: (id, lvl, query) =>
      dispatch(itnSelectExtremalDest(id, lvl, query, "return")),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ItineraryController));
