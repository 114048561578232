import { IconButton, Icon } from "rsuite";

import Stepper from "@src/components/common/Stepper";
import TransTxt from "@src/components/common/SxFormatMessage";

import ItinerarySetupController from "./Controllers/ItinerarySetupController";
import ItineraryController from "./Controllers/ItineraryController";
import OverviewController from "./Controllers/OverviewController";
import PreBookTopController from "./Controllers/PrebookTopController";

// ======================== MIDDLE CONTROLLERS =======================
import OverviewMiddleController from "./Controllers/ActionsController";
import PrebookMiddleController from "./Controllers/PrebookActionController";

// ======================== BOTTOM CONTROLLERS =======================
import ItineraryOverview from "./Controllers/ItineraryOverview";
import TripMap from "./TripMap";
import PrebookSetupController from "./Controllers/PrebookSetupController";

import ServiceSetupController from "./Controllers/ServiceSetupController";

// ============================== MODALS =============================
import TripPreferencesModal from "./Components/TripPreferencesModal";
import TripModeModal from "./Components/TripModeModal";
import TripGuestsModal from "./Components/TripGuestsModal";
import BookingMessageModal from "./Components/BookingMessageModal";
import TripWaitModal from "./Components/TripWaitModal";
import PrebookFlightsModal from "./Components/PrebookFlightsModal";
import PrebookAccommodationModal from "./Components/PrebookAccommodationModal";
import AddFundsModal from "@src/pages/ManagementConsole/AccountStatement/AddFundsModal";
import BookingModal from "./Components/BookingModal";
import RequestSaveModal from "./Components/RequestSaveModal";
import PrebookTransferModal from "./Components/PrebookTransferModal";
import ServiceCommentsModal from "@src/pages/Project/TripPlan/Components/ServiceComments/index.js";

// ============================ BOUNDARIES ===========================
import NotifierErrorBoundary from "@src/components/common/NotifierErrorBoundary";

// ============================= ACTIONS =============================
import {
  nextStep,
  prevStep,
  initializePlanner,
  fetchTripPlanInstance,
} from "@src/actions/Project/TripPlanner";

import _ from "lodash";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import React, { createContext, useContext, useEffect, useState } from "react";
import OfferModal from "./Components/OfferModal";
import AddOnDrawer from "./Components/AddOnDrawer";
import UpdateOfferModal from "./Components/UpdateOfferModal";
import AccPrioritiesDrawer from "./Components/Preferences/AccPrioritiesDrawer";
import {
  getPreferencesLoadingSelector,
  getSetupFormDataSelector,
} from "@src/selectors/Project/TripPlanner";
import MktFinalizeTopController from "./Controllers/MktFinalizeTopController";
import MktItinerary from "./Controllers/MktItinerary";
import MktPkgContent from "./Components/MktPkgContent";
import SaveAsModal from "./Components/Modals/SaveAsModal";
import OfferPDFModal from "./Components/Modals/offer_pdf";
import { HelpBtn } from "@src/components/layout/MainHeader";
import { TripModeHoc } from "./Hydrators";
import { createUseStyles } from "react-jss";
import { variables } from "@src/jsssetup";
import ServiceRemarksModal from "./Components/Modals/products/service_remarks";
import ActivitiesDrawer from "./Components/Modals/products/activities/ActivitiesDrawer";
import { SubEntitySelectModal } from "./Components/Modals/entities";
import { toast } from "react-toastify";
import { fetchSubAgentsProfile, fetchTripPlanOffer } from "@src/api";
import { getRoomPaxSetupSelector } from "@src/selectors/Project/TripPlanner/guest_selector";
import { stayer } from "@src/pages/Operation/common/helpers";
import { useQuery } from "@tanstack/react-query";
import { DayByDayContentModal } from "./Components/Modals/day_by_day";
import EasyAI from "./Modals/EasyAI";
import { CancellationInfoModal } from "./Components/Modals/cancellationinfo";
import { ActivityPriceModal } from "./Components/Modals/products/activities/details/ActivityPriceModal";
import { ActivitiesPrebookModal } from "./Components/Modals/prebook/ActivitiesPrebookModal.js";
import { TransportationLegs } from "./Components/Preferences/TransportationLegs";
import { ServicePreferencesModal } from "./Modals/ServicePreferences/index.js";
import { validateFirstStep } from "@src/actions/Project/TripPlanner/tools";
import { notifyCommonWarning } from "@src/components/common/notifications/CommonWarningNotification";
import { PrebookFerriesModal } from "./Components/Modals/prebook/ferries";
import { withAppUserGroup } from "@src/components/authorization/permissionhocs";
import { PrebookTrainsModal } from "./Components/Modals/prebook/trains/index.js";

export const PricingFormContext = createContext({
  showPricingForm: false,
  setShowPricingForm: function () {
    return null;
  },
});

const STEPS = [
  {
    id: 1,
    verbose: <TransTxt id="tripplan_itinerary_setup_step" startCase />,
    description: "",
  },
  {
    id: 2,
    verbose: <TransTxt id="tripplan_service_selection_step" startCase />,
    description: "",
  },
  {
    id: 3,
    verbose: <TransTxt id="tripplan_overview_step" startCase />,
    description: "",
  },
  {
    id: 4,
    verbose: <TransTxt id="tripplan_finalize_step" startCase />,
    description: "",
  },
];

function getHelpUrl({ currentStep }) {
  switch (currentStep) {
    case 1:
      return "https://b2btrips.bit.ai/docs/view/U4xZlO0i1g6wsChb";
    case 2:
      return "https://b2btrips.bit.ai/docs/view/m5m3wveONG1t2YiL";
    case 3:
      return "https://b2btrips.bit.ai/docs/view/BRax3nkadqyw8mnv";
    case 4:
      return "https://b2btrips.bit.ai/docs/view/fgSlBeMd1TfJ0J3Z";
    default:
      return "https://b2btrips.bit.ai/docs/view/U4xZlO0i1g6wsChb";
  }
}

const tripHeaderStyles = createUseStyles({
  TripHeader: (props) => ({
    padding: variables.normal_gap,
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateColumns:
      props.currentStep === 1
        ? "auto 1fr auto auto"
        : props.currentStep === 4
        ? "auto 1fr auto"
        : "auto 1fr auto auto",
    justifyContent: "center",
    borderBottom: `2px solid ${variables.colors.borders.base}`,
  }),
  leftControl: {
    "& .rs-icon": {
      background: `${variables.colors.orange}!important`,
      color: "white",
    },
  },
  rightControl: {
    "& .rs-icon": {
      background: "#4caf50!important",
      color: "white",
    },
  },
});
const TripHeader = ({
  currentStep,
  preferencesLoading,
  onPrevStep,
  onNextStep,
}) => {
  const classes = tripHeaderStyles({ currentStep });
  const { transportationMode } = useContext(TripPlanContext);

  var canGoToThree = true;
  if (currentStep == 2) {
    canGoToThree = transportationMode === "view";
  }

  return (
    <div className={classes.TripHeader}>
      <IconButton
        className={classes.leftControl}
        icon={<Icon icon="left" />}
        size="lg"
        placement="left"
        onClick={() => onPrevStep(currentStep)}>
        <TransTxt id="previous_step" startCase />
      </IconButton>
      <Stepper
        steps={STEPS}
        currentStep={currentStep}
        previousStepAsDone={true}
      />
      {currentStep == 4 ? null : (
        <IconButton
          className={classes.rightControl}
          disabled={preferencesLoading}
          icon={<Icon icon="right" />}
          size="lg"
          placement="right"
          onClick={() => {
            if (currentStep == 2 && !canGoToThree) {
              toast.warning(
                "Please make sure that all service preferences edits are confirmed",
                { autoClose: 6000 }
              );
              return;
            }

            onNextStep(currentStep);
          }}>
          <TransTxt id="next_step" startCase />
        </IconButton>
      )}
      <HelpBtn url={getHelpUrl({ currentStep })} />
    </div>
  );
};
TripHeader.defaultProps = {
  currentStep: 1,
};
TripHeader.propTypes = {
  currentStep: PropTypes.number.isRequired,
  preferencesLoading: PropTypes.bool.isRequired,
  onNextStep: PropTypes.func.isRequired,
  onPrevStep: PropTypes.func.isRequired,
};

const topControllerStyles = createUseStyles({
  TopController: {
    background: variables.colors.background.light,
    position: "relative",
    height: "auto",
    transition: "max-height 0.3s ease-out",
    maxHeight: "600px",
  },
  expander: {
    position: "absolute",
    bottom: "-15px",
    right: "40px",
    boxShadow: variables.shadows.normalShadow,
    zIndex: 7,
  },
});
const TopController = ({ currentStep, usage, changeResizeFlag }) => {
  const [expanded, setExpanded] = useState(true);

  function renderRouter() {
    if (!expanded && ![1].includes(currentStep)) {
      return null;
    }

    if ([1, 2].includes(currentStep)) {
      return <ItinerarySetupController />;
    } else if (currentStep == 3) {
      return <OverviewController currentStep={currentStep} usage={usage} />;
    } else if (currentStep == 4) {
      if (usage == "MKT") {
        return <MktFinalizeTopController />;
      } else {
        return <PreBookTopController currentStep={currentStep} usage={usage} />;
      }
    } else {
      return null;
    }
  }
  const classes = topControllerStyles();

  useEffect(() => {
    if (currentStep === 2) {
      setExpanded(false);
    }
  }, [currentStep]);

  return (
    <div className={classes.TopController}>
      {renderRouter()}
      {currentStep !== 1 && (
        <IconButton
          className="TopController__expander"
          circle
          icon={<Icon icon={expanded ? "angle-up" : "angle-down"} />}
          appearance="primary"
          onClick={() => {
            setExpanded((p) => !p);
            changeResizeFlag();
          }}
        />
      )}
    </div>
  );
};
TopController.defaultProps = {
  currentStep: 1,
};
TopController.propTypes = {
  currentStep: PropTypes.number.isRequired,
  usage: PropTypes.string.isRequired,
  changeResizeFlag: PropTypes.func.isRequired,
};

const QtTitle = ({ quotation_rq_meta }) => {
  if (!quotation_rq_meta?.reference) return null;

  return (
    <a
      href={`/#/project/trip/quotation-request/${quotation_rq_meta.id}`}
      target="_blank">{`Quotation Request: ${quotation_rq_meta.name} (${quotation_rq_meta.reference})`}</a>
  );
};

function TargetEntityLabel({ targetEntityData }) {
  return (
    !!targetEntityData && (
      <React.Fragment>
        <span>For</span>
        <span className="Tag" data-size="xs" data-success="true">
          {targetEntityData?.name}
        </span>
      </React.Fragment>
    )
  );
}

const BottomLeftController = ({ currentStep, usage }) => {
  const {
    stay,
    tripSetup,
    requiredRooms,
    tripDestinations,
    reference,
    invoices,
    quotation_request_metadata: quotation_rq_meta,
  } = useSelector((state) => {
    const { reference, quotation_request_metadata, invoices } =
      state.tripPlannerInstanceData;

    const cIn = state.tripPlannerDestinations?.[0]?.checkIn;
    const cOut = _.last(state.tripPlannerDestinations)?.checkOut;
    const stay = stayer(cIn, cOut);

    const paxSetup = getRoomPaxSetupSelector(state);

    return {
      tripSetup: state.tripPlannerItinerarySetupForm,
      tripDestinations: state.tripPlannerDestinations.length,
      requiredRooms: paxSetup.length,
      stay,
      reference,
      quotation_request_metadata,
      invoices: invoices ?? [],
    };
  });

  const destsStr = tripDestinations == 1 ? "Destination" : "Destinations";
  const adultsStr = tripSetup.adults !== 1 ? "Adults" : "Adult";
  const childStr = tripSetup.children == 1 ? "Child" : "Children";
  const target_entity = tripSetup.target_entity;

  // TODO: maybe we need to use targetEntityIsLoading
  const { data: targetEntityData } = useQuery({
    queryKey: ["tripTargetEntity", target_entity],
    queryFn: () =>
      target_entity
        ? fetchSubAgentsProfile(target_entity.split("___")[0])
        : new Promise((resolve) => resolve(null)),
    onError: () =>
      toast.error("Error fetching target entity", { autoClose: 3000 }),
    refetchOnWindowFocus: false,
    enabled: (target_entity || "").includes("subagent"),
  });

  const { data: linkedOffer } = useQuery({
    queryKey: ["tripLinkedOffer", tripSetup.linked_offer],
    queryFn: () => {
      if ((tripSetup?.linked_offer ?? "").toString().includes("---"))
        return new Promise((resolve) => resolve(null));

      if (tripSetup.linked_offer)
        return fetchTripPlanOffer(tripSetup.linked_offer);

      return new Promise((resolve) => resolve(null));
    },
    refetchOnWindowFocus: false,
  });

  const linkedReferences = tripSetup?.linked_references ?? [];
  const isParent = linkedReferences.length > 0;

  const title = [
    reference
      ? `${isParent ? "Parent Offer: " : ""}${reference} ${tripSetup.title}`
      : null,
    `${tripDestinations} ${destsStr}`,
    stay,
    `${tripSetup.adults} ${adultsStr}`,
    tripSetup.children > 0 && `${tripSetup.children} ${childStr}`,
    `${requiredRooms} Rooms`,
  ]
    .filter((s) => s)
    .join(", ");

  const linkedOffers = linkedReferences.map(([id, reference]) => {
    const path = [
      window.location.origin,
      `/#/project/trip/tripplan/`,
      id,
      `/view-offer`,
    ].join("");

    return { path, label: `(${reference})` };
  });
  if (linkedOffer?.id) {
    const linkedOfferPath = [
      window.location.origin,
      `/#/project/trip/tripplan/`,
      linkedOffer?.id,
      `/view-offer`,
    ].join("");
    linkedOffers.push({
      path: linkedOfferPath,
      label: `${linkedOffer?.offer_title ?? "N/A"} (${linkedOffer.reference})`,
    });
  }

  if ([1, 2].includes(currentStep)) {
    return (
      <React.Fragment>
        <div className="BottomLeftControllerTitle">
          {invoices.length > 0 && (
            <span className="Tag" data-size="xs" data-success="true">
              Invoiced
            </span>
          )}
          <TargetEntityLabel targetEntityData={targetEntityData} />
          {title}
          <QtTitle quotation_rq_meta={quotation_rq_meta} />
        </div>
        {/* <ItineraryController currentStep={currentStep} /> */}
        {currentStep === 2 ? (
          <TransportationLegs />
        ) : (
          <ItineraryController currentStep={currentStep} />
        )}
      </React.Fragment>
    );
  } else if ([3, 4].includes(currentStep)) {
    if (currentStep == 4 && usage == "MKT") {
      return <MktItinerary />;
    }
    return (
      <React.Fragment>
        <div className="BottomLeftControllerTitle">
          {invoices.length > 0 && (
            <span className="Tag" data-size="xs" data-success="true">
              Invoiced
            </span>
          )}
          <TargetEntityLabel targetEntityData={targetEntityData} />
          {title}
          <QtTitle quotation_rq_meta={quotation_rq_meta} />
          {linkedOffers.length > 0 && (
            <React.Fragment>
              <span>linked with: </span>
              {linkedOffers.map(({ label, path }, idx) => (
                <a key={idx} href={path} target="_blank" rel="noreferrer">
                  {label}
                </a>
              ))}
            </React.Fragment>
          )}
        </div>
        <ItineraryOverview />
      </React.Fragment>
    );
  } else {
    return null;
  }
};
BottomLeftController.propTypes = {
  currentStep: PropTypes.number.isRequired,
  usage: PropTypes.string.isRequired,
};

function BottomRightController({
  currentStep,
  usage,
  resizeFlag,
  mapCenterLocation,
}) {
  const { layout } = useContext(LayoutContext);

  if ([1, 3].includes(currentStep)) {
    return (
      <TripMap
        layout={layout}
        currentStep={currentStep}
        shouldGeolocate={false}
        resizeFlag={resizeFlag}
        mapCenterLocation={mapCenterLocation}
      />
    );
  } else if (currentStep == 2) {
    return <ServiceSetupController />;
  } else if (currentStep == 4) {
    if (usage == "MKT") {
      return null;
    }

    return <PrebookSetupController />;
  } else {
    return null;
  }
}
BottomRightController.propTypes = {
  currentStep: PropTypes.number.isRequired,
  resizeFlag: PropTypes.string.isRequired,
  usage: PropTypes.string.isRequired,
  mapCenterLocation: PropTypes.object.isRequired,
};

export const MiddleController = ({ currentStep, usage }) => {
  if ([1, 2].includes(currentStep)) {
    return null;
  } else if (currentStep == 3) {
    return <OverviewMiddleController />;
  } else if (currentStep == 4) {
    if (usage == "MKT") {
      return null;
    }

    return <PrebookMiddleController />;
  } else {
    return null;
  }
};
MiddleController.propTypes = {
  currentStep: PropTypes.number.isRequired,
  usage: PropTypes.string.isRequired,
};

export const TripPlanContext = React.createContext({
  services_collapsed: false,
  setSrvsCollapsed: () => null,
  showSaveAsModal: () => null,
  setShowSaveAsModal: () => null,
  setShowServiceRemarksModal: () => null,
  setServiceRemarksUid: () => null,
  setServiceRemarksSrvType: () => null,
  setShowAccPrioritiesModal: () => null,
  setAccPrioritiesDestOrder: () => null,
  setShowActivitiesDrawer: () => null,
  setActivitiesDestOrder: () => null,
  setActivitiesDate: () => null,
  setShowOfferModal: () => null,
  setShowTripGuestsModal: () => null,
});

const LayoutContext = React.createContext({ layout: "center" });

function getLayout({ currentStep, layout }) {
  if (["leftonly", "rightonly"].includes(layout)) {
    return "1fr";
  }

  if (layout !== "center") {
    switch (layout) {
      case "left":
        return "5% 95%";
      case "right":
        return "95% 5%";
      default:
        return "50% 50%";
    }
  }

  switch (currentStep) {
    case 1:
      return "50% 50%";
    case 2:
      return "45% 55%";
    case 3:
      return "75% 25%";
    case 4:
      return "60% 40%";
    default:
      return "50% 50%";
  }
}

function getBtnLayout({ currentStep, layout }) {
  if (layout !== "center") {
    switch (layout) {
      case "left":
        return "5%";
      case "right":
        return "95%";
    }
  }

  switch (currentStep) {
    case 1:
      return "50%";
    case 2:
      return "45%";
    case 3:
      return "75%";
    case 4:
      return "60%";
    default:
      return "50%";
  }
}

function layoutBtnStyles({ layout, currentStep, off = "left" }) {
  return {
    display: layout === off ? "none" : "block",
    position: "absolute!important",
    left: getBtnLayout({ currentStep, layout }),
    top: "50%",
  };
}

const styles = createUseStyles({
  bottom: ({ currentStep, layout }) => ({
    display: "grid",
    gridTemplateColumns: getLayout({ currentStep, layout }),
    overflow: "hidden",
    position: "relative",
  }),
  bottomLeft: {
    display: "grid",
    gridTemplateRows: "max-content",
    height: "100%",
    overflow: "hidden",
    borderRight: `2px solid ${variables.colors.borders.base}`,
  },
  bottomRight: {
    display: "grid",
    height: "100%",
    overflow: "hidden",
    position: "relative",
  },
  leftLayoutBtn: ({ layout, currentStep }) => ({
    ...layoutBtnStyles({ layout, currentStep, off: "right" }),
    transform:
      layout === "center" ? "translate(-50%, -55%)" : "translate(-50%, -50%)",
  }),
  rightLayoutBtn: ({ layout, currentStep }) => ({
    ...layoutBtnStyles({ layout, currentStep, off: "left" }),
    transform:
      layout === "center" ? "translate(-50%, 55%)" : "translate(-50%, -50%)",
  }),
});
var TripPlanner = ({
  match,
  currentStep,
  usage,
  preferencesLoading,
  mapCenterLocation,
  onNextStep,
  onPrevStep,
  initPlanner,
  fetchTripPlanInst,
}) => {
  const [tripLoading, setTripLoading] = useState(false);
  const [transportationMode, setTransportationMode] = useState("view");
  const [resizeFlag, setResizeFlag] = useState("0");
  const [services_collapsed, setSrvsCollapsed] = useState(false);
  const [showSaveAsModal, setShowSaveAsModal] = useState(false);
  const [layout, setLayout] = useState("center");
  const [serviceRemarksModal, setShowServiceRemarksModal] = useState(false);
  const [serviceRemarksUid, setServiceRemarksUid] = useState(null);
  const [serviceRemarksSrvType, setServiceRemarksSrvType] = useState(null);

  const [showEasyAI, setShowEasyAI] = useState(false);

  // ======================== SERVICE PREFERECNES MODAL =======================
  const [showSrvPreferencesModal, setShowSrvPreferencesModal] = useState(false);

  // ===================== Package Content Modal =====================
  const [showContentModal, setShowContentModal] = useState(false);

  const [showOfferModal, setShowOfferModal] = useState(false);
  const [showBookingModal, setShowBookingModal] = useState(false);

  const [showActivitiesDrawer, setShowActivitiesDrawer] = useState(false);
  const [activitiesDate, setActivitiesDate] = useState(false);
  const [activitiesDestOrder, setActivitiesDestOrder] = useState(false);

  const [showAccPrioritiesModal, setShowAccPrioritiesModal] = useState(false);
  const [accPrioritiesDestOrder, setAccPrioritiesDestOrder] = useState(false);

  const [showTripModeModal, setShowTripModeModal] = useState(false);
  const [tripModeModalMsg, setTripModeModalMsg] = useState("");

  const [showTripGuestsModal, setShowTripGuestsModal] = useState(false);
  const [showFlightsPrebookModal, setShowFlightsPrebookModal] = useState(false);
  const [showFerriesPrebookModal, setShowFerriesPrebookModal] = useState(false);
  const [showTrainsPrebookModal, setShowTrainsPrebookModal] = useState(false);
  const [showTransfersPrebookModal, setShowTransfersPrebookModal] =
    useState(false);
  const [showActivitiesPrebookModal, setShowActivitiesPrebookModal] =
    useState(false);

  const [showDayByDayContentModal, setShowDayByDayContentModal] =
    useState(false);

  const [showCancellationPolicyModal, setShowCancellationPolicyModal] =
    useState(false);

  const [showSubEntitySelectModal, setShowSubEntitySelectModal] =
    useState(false);

  // ========================== ACTIVITY PRICE MODAL ==========================
  const [showActivityPriceModal, setShowActivityPriceModal] = useState(false);
  const [activityPriceInfo, setActivityPriceInfo] = useState(null);

  const {
    tripId,
    setupForm,
    originData,
    destinations,
    requiredServices,
    showQuotationPDFModal,
  } = useSelector((state) => {
    const { show: showQuotationPDFModal } = state.tripPlannerQuotationPDFModal;
    const setupForm = state.tripPlannerItinerarySetupForm;
    const originData = state.tripPlannerOriginData;
    const destinations = state.tripPlannerDestinations;

    const tripId = state.tripPlannerInstanceData?.id;

    const { requiredServices } = getSetupFormDataSelector(state);

    return {
      tripId,
      setupForm,
      originData,
      destinations,
      showQuotationPDFModal,
      requiredServices,
    };
  });

  function changeResizeFlag() {
    setResizeFlag((p) => (parseInt(p, 10) + 1).toString());
  }

  // Initialize trip planner
  useEffect(() => {
    initPlanner();

    if (match.params.id) {
      const mode = match?.params?.mode ?? "normal";
      setTripLoading(true);
      fetchTripPlanInst({
        id: match.params.id,
        mode,
        setShowTripModeModal,
        setTripModeModalMsg,
      });
    }
  }, []);

  useEffect(() => {
    if (!tripId) return;
    setTripLoading(false);
  }, [tripId]);

  // Marketplace mode changes
  useEffect(() => {
    if (currentStep === 4 && usage === "MKT") {
      setSrvsCollapsed(false);
      setLayout("leftonly");
      return;
    }
    setLayout("center");
  }, [currentStep]);

  const classes = styles({ currentStep, layout });

  return (
    <LayoutContext.Provider value={{ layout, setLayout }}>
      <TripPlanContext.Provider
        value={{
          tripLoading,
          transportationMode,
          setTransportationMode,
          services_collapsed,
          setSrvsCollapsed,
          showSaveAsModal,
          setShowSaveAsModal,
          setShowServiceRemarksModal,
          setServiceRemarksUid,
          setServiceRemarksSrvType,
          setShowAccPrioritiesModal,
          setAccPrioritiesDestOrder,
          setShowActivitiesDrawer,
          setActivitiesDestOrder,
          setActivitiesDate,
          setShowOfferModal,
          setShowTripGuestsModal,
          setShowContentModal,
          setShowSubEntitySelectModal,
          setShowDayByDayContentModal,
          setShowFlightsPrebookModal,
          setShowTrainsPrebookModal,
          setShowEasyAI,
          setShowCancellationPolicyModal,
          setShowActivityPriceModal,
          setActivityPriceInfo,
          setShowActivitiesPrebookModal,
          setShowTransfersPrebookModal,
          setShowSrvPreferencesModal,
          setShowFerriesPrebookModal,
          setShowBookingModal,
        }}>
        <div className="TripPlanner">
          <div className="top-section">
            <TripHeader
              currentStep={currentStep}
              preferencesLoading={preferencesLoading}
              onNextStep={
                currentStep === 1 &&
                requiredServices.length === 1 &&
                requiredServices.includes("MI")
                  ? () => onNextStep(3)
                  : currentStep === 1
                  ? () => {
                      const validInfo = validateFirstStep(
                        setupForm,
                        originData,
                        destinations
                      );
                      if (!validInfo.isValid) {
                        validInfo.msg.forEach((m) => {
                          window.setTimeout(() => notifyCommonWarning(m), 100);
                        });
                        return;
                      }

                      setShowSrvPreferencesModal(true);
                    }
                  : onNextStep
              }
              onPrevStep={() => {
                if (currentStep === 3) {
                  setShowSrvPreferencesModal(true);
                  return;
                }

                onPrevStep(currentStep);
              }}
            />
            <TopController
              currentStep={currentStep}
              usage={usage}
              changeResizeFlag={changeResizeFlag}
            />
          </div>
          <div className={classes.bottom}>
            <div className={classes.bottomLeft}>
              <BottomLeftController currentStep={currentStep} usage={usage} />
            </div>
            {usage === "MKT" && currentStep == 4 ? null : (
              <React.Fragment>
                <div className={classes.bottomRight}>
                  <BottomRightController
                    currentStep={currentStep}
                    usage={usage}
                    resizeFlag={resizeFlag}
                    mapCenterLocation={mapCenterLocation}
                  />
                </div>
                <IconButton
                  className={classes.leftLayoutBtn}
                  appearance="primary"
                  circle
                  icon={
                    <Icon
                      icon={layout === "center" ? "angle-left" : "angle-right"}
                    />
                  }
                  onClick={() =>
                    setLayout((p) => (p === "center" ? "left" : "center"))
                  }
                />
                <IconButton
                  className={classes.rightLayoutBtn}
                  appearance="primary"
                  circle
                  icon={
                    <Icon
                      icon={layout === "center" ? "angle-right" : "angle-left"}
                    />
                  }
                  onClick={() =>
                    setLayout((p) => (p === "center" ? "right" : "center"))
                  }
                />
              </React.Fragment>
            )}
          </div>
          <NotifierErrorBoundary>
            <RequestSaveModal
              mode={usage == "MKT" ? "marketplace" : "tripplan"}
            />
          </NotifierErrorBoundary>
          {showSaveAsModal && (
            <NotifierErrorBoundary>
              <SaveAsModal onCancel={() => setShowSaveAsModal(false)} />
            </NotifierErrorBoundary>
          )}
          {showSrvPreferencesModal && (
            <ServicePreferencesModal
              onClose={() => setShowSrvPreferencesModal(false)}
              onNextStep={onNextStep}
            />
          )}
          <NotifierErrorBoundary>
            <TripPreferencesModal />
          </NotifierErrorBoundary>
          {showTripModeModal && (
            <NotifierErrorBoundary>
              <TripModeModal
                msg={tripModeModalMsg}
                onHide={() => setShowTripModeModal(false)}
              />
            </NotifierErrorBoundary>
          )}
          {showTripGuestsModal && (
            <NotifierErrorBoundary>
              <TripGuestsModal onClose={() => setShowTripGuestsModal(false)} />
            </NotifierErrorBoundary>
          )}
          <NotifierErrorBoundary>
            <BookingMessageModal />
          </NotifierErrorBoundary>
          <NotifierErrorBoundary>
            <TripWaitModal />
          </NotifierErrorBoundary>
          {showFlightsPrebookModal && (
            <NotifierErrorBoundary>
              <PrebookFlightsModal
                onCancel={() => setShowFlightsPrebookModal(false)}
              />
            </NotifierErrorBoundary>
          )}
          {showFerriesPrebookModal && (
            <NotifierErrorBoundary>
              <PrebookFerriesModal
                onCancel={() => setShowFerriesPrebookModal(false)}
              />
            </NotifierErrorBoundary>
          )}
          {showTrainsPrebookModal && (
            <NotifierErrorBoundary>
              <PrebookTrainsModal
                onCancel={() => setShowTrainsPrebookModal(false)}
              />
            </NotifierErrorBoundary>
          )}
          {showActivitiesPrebookModal && (
            <NotifierErrorBoundary>
              <ActivitiesPrebookModal
                onCancel={() => setShowActivitiesPrebookModal(false)}
              />
            </NotifierErrorBoundary>
          )}
          {showTransfersPrebookModal && (
            <NotifierErrorBoundary>
              <PrebookTransferModal
                onCancel={() => setShowTransfersPrebookModal(false)}
              />
            </NotifierErrorBoundary>
          )}
          <NotifierErrorBoundary>
            <PrebookAccommodationModal />
          </NotifierErrorBoundary>
          <NotifierErrorBoundary>
            <AddFundsModal />
          </NotifierErrorBoundary>
          {showBookingModal && (
            <NotifierErrorBoundary>
              <BookingModal />
            </NotifierErrorBoundary>
          )}
          {showOfferModal && (
            <NotifierErrorBoundary>
              <OfferModal />
            </NotifierErrorBoundary>
          )}
          <NotifierErrorBoundary>
            <AddOnDrawer />
          </NotifierErrorBoundary>
          {showActivitiesDrawer && (
            <NotifierErrorBoundary>
              <ActivitiesDrawer
                searchDate={activitiesDate}
                destOrder={activitiesDestOrder}
                onHide={() => setShowActivitiesDrawer(false)}
              />
            </NotifierErrorBoundary>
          )}
          <NotifierErrorBoundary>
            <UpdateOfferModal />
          </NotifierErrorBoundary>
          {showAccPrioritiesModal && (
            <NotifierErrorBoundary>
              <AccPrioritiesDrawer
                destOrder={accPrioritiesDestOrder}
                onHide={() => setShowAccPrioritiesModal(false)}
              />
            </NotifierErrorBoundary>
          )}
          {showContentModal && (
            <NotifierErrorBoundary>
              <MktPkgContent />
            </NotifierErrorBoundary>
          )}
          {showQuotationPDFModal && (
            <NotifierErrorBoundary>
              <OfferPDFModal />
            </NotifierErrorBoundary>
          )}
          <NotifierErrorBoundary>
            <ServiceCommentsModal />
          </NotifierErrorBoundary>
          {serviceRemarksModal && (
            <NotifierErrorBoundary>
              <ServiceRemarksModal
                uid={serviceRemarksUid}
                srv_type={serviceRemarksSrvType}
                onCancel={() => setShowServiceRemarksModal(false)}
              />
            </NotifierErrorBoundary>
          )}
          {showSubEntitySelectModal && (
            <NotifierErrorBoundary>
              <SubEntitySelectModal
                onCancel={() => setShowSubEntitySelectModal(false)}
              />
            </NotifierErrorBoundary>
          )}
          {showDayByDayContentModal && (
            <NotifierErrorBoundary>
              <DayByDayContentModal
                onCancel={() => setShowDayByDayContentModal(false)}
              />
            </NotifierErrorBoundary>
          )}
          {showCancellationPolicyModal && (
            <NotifierErrorBoundary>
              <CancellationInfoModal
                onCancel={() => setShowCancellationPolicyModal(false)}
              />
            </NotifierErrorBoundary>
          )}
          {showEasyAI && (
            <NotifierErrorBoundary>
              <EasyAI onClose={() => setShowEasyAI(false)} />
            </NotifierErrorBoundary>
          )}
          {showActivityPriceModal && (
            <NotifierErrorBoundary>
              <ActivityPriceModal
                activity={activityPriceInfo.activity}
                activity_uid={activityPriceInfo.activity_uid}
                date={activityPriceInfo.date}
                session_id={activityPriceInfo.session_id}
                destination_id={activityPriceInfo.destination_id}
                destOrder={activityPriceInfo.destOrder}
                onCancel={() => setShowActivityPriceModal(false)}
              />
            </NotifierErrorBoundary>
          )}
        </div>
      </TripPlanContext.Provider>
    </LayoutContext.Provider>
  );
};
TripPlanner.defaultProps = {
  currentStep: 0,
};
TripPlanner.propTypes = {
  match: PropTypes.object.isRequired,
  currentStep: PropTypes.number.isRequired,
  usage: PropTypes.oneOf(["RQ", "OFF", "MKT", "BI"]).isRequired,
  preferencesLoading: PropTypes.bool.isRequired,
  mapCenterLocation: PropTypes.object.isRequired,
  onNextStep: PropTypes.func.isRequired,
  onPrevStep: PropTypes.func.isRequired,
  initPlanner: PropTypes.func.isRequired,
  fetchTripPlanInst: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  const { usage } = getSetupFormDataSelector(state);

  const preferencesLoading = getPreferencesLoadingSelector(state);

  const bizAddress = (state?.companyProfile?.address ?? []).find(
    (addr) => addr.address_type == "BU"
  );
  const mapCenterLocation = {
    lat: bizAddress?.lat ?? 0,
    lng: bizAddress?.lng ?? 0,
  };

  return {
    currentStep: state.tripPlannerCurrentStep,
    usage,
    preferencesLoading,
    mapCenterLocation,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    initPlanner: () => dispatch(initializePlanner()),
    onNextStep: (current) => dispatch(nextStep(current)),
    onPrevStep: (current) => dispatch(prevStep(current)),
    fetchTripPlanInst: async ({
      id,
      mode,
      setShowTripModeModal,
      setTripModeModalMsg,
    }) =>
      await dispatch(
        fetchTripPlanInstance({
          id,
          mode,
          setShowTripModeModal,
          setTripModeModalMsg,
        })
      ),
  };
};

const withTripPricingForm = (WrappedComponent) => {
  const Comp = (props) => {
    const [showPricingForm, setShowPricingForm] = useState(false);

    return (
      <PricingFormContext.Provider
        value={{ showPricingForm, setShowPricingForm }}>
        <WrappedComponent {...props} />
      </PricingFormContext.Provider>
    );
  };
  return Comp;
};

TripPlanner = connect(
  mapStateToProps,
  mapDispatchToProps
)(TripModeHoc(withTripPricingForm(TripPlanner)));
export default withAppUserGroup(TripPlanner, ["PROJECT"], { view: true });
