import React from "react";
import PropTypes from "prop-types";
import { Text, View, StyleSheet, Svg, Path, Font } from "@react-pdf/renderer";
import { DateTime } from "luxon";
import NotoSansRegular from "@src/style/fonts/NotoSans-Regular.ttf";
import NotoSansBold from "@src/style/fonts/NotoSans-Bold.ttf";

Font.register({
  family: "Noto Sans",
  fonts: [
    { src: NotoSansRegular, fontWeight: "normal" },
    { src: NotoSansBold, fontWeight: "bold" },
  ],
});

const iconStyles = { width: 13, height: 13, color: "#000" };

const PhoneIcon = ({ width, height, color }) => (
  <Svg width={width} height={height} fill={color} viewBox="0 0 21 21">
    <Path d="M5.7925 9.44125C7.0525 11.9175 9.0825 13.9387 11.5588 15.2075L13.4837 13.2825C13.72 13.0463 14.07 12.9675 14.3763 13.0725C15.3562 13.3962 16.415 13.5712 17.5 13.5712C17.9812 13.5712 18.375 13.965 18.375 14.4462V17.5C18.375 17.9812 17.9812 18.375 17.5 18.375C9.28375 18.375 2.625 11.7163 2.625 3.5C2.625 3.01875 3.01875 2.625 3.5 2.625H6.5625C7.04375 2.625 7.4375 3.01875 7.4375 3.5C7.4375 4.59375 7.6125 5.64375 7.93625 6.62375C8.0325 6.93 7.9625 7.27125 7.7175 7.51625L5.7925 9.44125Z" />
  </Svg>
);
PhoneIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

const MailIcon = ({ width, height, color }) => (
  <Svg width={width} height={height} fill={color} viewBox="0 0 22 22">
    <Path d="M18.3333 3.66667H3.66665C2.65831 3.66667 1.84248 4.49167 1.84248 5.50001L1.83331 16.5C1.83331 17.5083 2.65831 18.3333 3.66665 18.3333H18.3333C19.3416 18.3333 20.1666 17.5083 20.1666 16.5V5.50001C20.1666 4.49167 19.3416 3.66667 18.3333 3.66667ZM18.3333 7.33334L11 11.9167L3.66665 7.33334V5.50001L11 10.0833L18.3333 5.50001V7.33334Z" />
  </Svg>
);
MailIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

const offerMetaStyles = StyleSheet.create({
  offerMeta: { flexDirection: "row", width: 550, fontFamily: "Noto Sans" },
  sender: {
    width: 275,
    borderLeft: "3px solid #302A83",
    paddingTop: 5,
    paddingRight: 0,
    paddingBottom: 5,
    paddingLeft: 20,
    fontFamily: "Noto Sans",
  },
  receiver: {
    height: 70,
    width: 275,
    borderLeft: "3px solid #302A83",
    paddingTop: 5,
    paddingRight: 0,
    paddingBottom: 5,
    paddingLeft: 20,
    fontFamily: "Noto Sans",
  },
  title: { fontFamily: "Helvetica-Bold", fontFamily: "Noto Sans" },
  attribute: {
    fontSize: 10,
    flexDirection: "row",
    gap: 5,
    padding: 2,
    fontFamily: "Noto Sans",
  },
});
const OfferMeta = ({ sender, receiver, configForm }) => {
  const validity = DateTime.fromISO(configForm.offer_validity);

  return (
    <View style={offerMetaStyles.offerMeta}>
      <View style={offerMetaStyles.receiver}>
        {receiver.first_name !== "" && receiver.last_name !== "" && (
          <View style={offerMetaStyles.attribute}>
            <Text style={offerMetaStyles.title}>For: </Text>
            <Text>
              {receiver.first_name} {receiver.last_name}
            </Text>
          </View>
        )}
        {receiver.company !== "" && (
          <View style={offerMetaStyles.attribute}>
            <Text style={offerMetaStyles.title}>Company: </Text>
            <Text>{receiver.company}</Text>
          </View>
        )}
        {receiver.email !== "" && (
          <View style={offerMetaStyles.attribute}>
            <MailIcon
              width={iconStyles.width}
              height={iconStyles.height}
              color={iconStyles.color}
            />
            <Text>{receiver.email}</Text>
          </View>
        )}
        {!!receiver.phone && (
          <View style={offerMetaStyles.attribute}>
            <PhoneIcon
              width={iconStyles.width}
              height={iconStyles.height}
              color={iconStyles.color}
            />
            <Text>{receiver.phone}</Text>
          </View>
        )}
      </View>
      <View style={offerMetaStyles.sender}>
        <View style={offerMetaStyles.attribute}>
          <Text style={offerMetaStyles.title}>Offer Date: </Text>
          <Text>
            {DateTime.now()
              .setLocale("en-US")
              .toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
          </Text>
        </View>
        {Boolean(configForm.offer_version) && (
          <View style={offerMetaStyles.attribute}>
            <Text style={offerMetaStyles.title}>Offer Version: </Text>
            <Text>{configForm.offer_version}</Text>
          </View>
        )}
        {!validity.invalid && (
          <View style={offerMetaStyles.attribute}>
            <Text style={offerMetaStyles.title}>Offer Validity: </Text>
            <Text>
              {validity
                .setLocale("en-US")
                .toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)}
            </Text>
          </View>
        )}
        {Boolean(sender.first_name) && Boolean(sender.last_name) && (
          <View style={offerMetaStyles.attribute}>
            <Text style={offerMetaStyles.title}>Prepared By: </Text>
            <Text>
              {sender.first_name} {sender.last_name}
            </Text>
          </View>
        )}
        {Boolean(sender.email) && (
          <View style={offerMetaStyles.attribute}>
            <MailIcon
              width={iconStyles.width}
              height={iconStyles.height}
              color={iconStyles.color}
            />
            <Text>{sender.email}</Text>
          </View>
        )}
        {Boolean(sender.telephone) && (
          <View style={offerMetaStyles.attribute}>
            <PhoneIcon
              width={iconStyles.width}
              height={iconStyles.height}
              color={iconStyles.color}
            />
            <Text>{sender.telephone}</Text>
          </View>
        )}
      </View>
    </View>
  );
};
OfferMeta.propTypes = {
  sender: PropTypes.object.isRequired,
  receiver: PropTypes.object.isRequired,
  configForm: PropTypes.object.isRequired,
};

export default OfferMeta;
