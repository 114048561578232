import { Notification } from "rsuite";
import React from "react";

export const notifySaveAsFailed = () => {
  Notification.error({
    title: "Save As Error",
    description: (
      <p>We failed to save your trip. We are sorry for the inconvenience.</p>
    ),
    duration: 6000,
  });
};
