import {
  SUB_ENTITIES_STATEMENT_INIT,
  // ==================== DEPOSIT BALANCE ACTIONS ====================
  SUB_ENTITIES_STATEMENT_FETCHING_DEPOSIT_BALANCE,
  SUB_ENTITIES_STATEMENT_FETCHED_DEPOSIT_BALANCE,
  SUB_ENTITIES_STATEMENT_SET_DEPOSIT_BALANCE,
  // ======================== DEPOSIT ENTRIES ========================
  SUB_ENTITIES_STATEMENT_SET_DEPOSIT_ENTRIES,
  SUB_ENTITIES_STATEMENT_FETCHING_DEPOSIT_ENTRIES,
  SUB_ENTITIES_STATEMENT_FETCHED_DEPOSIT_ENTRIES,
  //========================= GRAPH DATA =============================
  SUB_ENTITIES_STATEMENT_STAT_DATA,
  SUB_ENTITIES_STATEMENT_VIEW_TYPE,
  SUB_ENTITIES_STATEMENT_INIT_VIEW_TYPE,
  //========================= Filter =================================
  SUB_ENTITIES_STATEMENT_INITIALIZE_LIST_FILTER_FORM,
  SUB_ENTITIES_STATEMENT_UPDATE_LIST_FILTER_FORM,
  //==========================Add Credit==============================
  SUB_ENTITIES_STATEMENT_SHOW_MODAL,
  SUB_ENTITIES_STATEMENT_HIDE_MODAL,
  SUB_ENTITIES_STATEMENT_EDIT_CREDIT_MOUNT,
  SUB_ENTITIES_STATEMENT_IDLE_MODAL,
  SUB_ENTITIES_STATEMENT_SAVE_BRANCHES_LIST_DATA,
  SUB_ENTITIES_STATEMENT_SAVE_SUBAGENTS_LIST_DATA,
  SUB_ENTITIES_STATEMENT_SAVE_INDEPENDENT_AGENTS_LIST_DATA,
} from "@src/actions/ManagementConsole/SubEntityStatement/types";

import update from "immutability-helper";
import { formProcessor } from "@src/reducers/tools";
import { initialAccountStatementFilterForm } from "@src/forms/ManagementConsole/AccountStatement";

export const initialFetchSubEntitiesStatementStatus = "IDLE";
export const fetchSubEntitiesStatementStatus = (
  state = initialFetchSubEntitiesStatementStatus,
  action
) => {
  switch (action.type) {
    case SUB_ENTITIES_STATEMENT_INIT:
      return "IDLE";
    case SUB_ENTITIES_STATEMENT_FETCHING_DEPOSIT_ENTRIES:
      return "FETCHING";
    case SUB_ENTITIES_STATEMENT_FETCHED_DEPOSIT_ENTRIES:
      return "FETCHED";
    default:
      return state;
  }
};

export const initialList = {
  count: 0,
  next: null,
  previous: null,
  details: [],
  page: 1,
  limit: 20,
  displayLength: 100,
};
export const subentitiesStatementData = (state = initialList, action) => {
  switch (action.type) {
    case SUB_ENTITIES_STATEMENT_INIT:
      return initialList;
    case SUB_ENTITIES_STATEMENT_SET_DEPOSIT_ENTRIES:
      return action.data;
    case SUB_ENTITIES_STATEMENT_INITIALIZE_LIST_FILTER_FORM:
      return { ...state, page: 1 };
    default:
      return state;
  }
};

export const subagentsStatementDepositBalanceInitial = {
  balance: 0,
  total_debit: 0,
  total_credit: 0,
  currency: {},
  loading: false,
};
export const subentitiesStatementDepositBalance = (
  state = subagentsStatementDepositBalanceInitial,
  action
) => {
  switch (action.type) {
    case SUB_ENTITIES_STATEMENT_INIT:
      return subagentsStatementDepositBalanceInitial;
    case SUB_ENTITIES_STATEMENT_FETCHING_DEPOSIT_BALANCE:
      return update(state, { loading: { $set: true } });
    case SUB_ENTITIES_STATEMENT_FETCHED_DEPOSIT_BALANCE:
      return update(state, { loading: { $set: false } });
    case SUB_ENTITIES_STATEMENT_SET_DEPOSIT_BALANCE: {
      const { balance, total_debit, total_credit, currency } = action;
      return update(state, {
        balance: { $set: balance },
        total_credit: { $set: total_credit },
        total_debit: { $set: total_debit },
        currency: { $set: currency },
      });
    }
    default:
      return state;
  }
};

export const subentitiesStatementGraphData = (state = {}, action) => {
  switch (action.type) {
    case SUB_ENTITIES_STATEMENT_STAT_DATA:
      return action.data;
    default:
      return state;
  }
};

export const initType = "current";

export const subentitiesStatementViewType = (state = initType, action) => {
  switch (action.type) {
    case SUB_ENTITIES_STATEMENT_INIT_VIEW_TYPE:
      return initType;
    case SUB_ENTITIES_STATEMENT_VIEW_TYPE:
      return action.data;
    default:
      return state;
  }
};

export const subentitiesStatementListFilterForm = (
  state = initialAccountStatementFilterForm,
  action
) => {
  switch (action.type) {
    case SUB_ENTITIES_STATEMENT_INITIALIZE_LIST_FILTER_FORM: {
      return action.data;
    }
    case SUB_ENTITIES_STATEMENT_UPDATE_LIST_FILTER_FORM:
      return formProcessor(
        state,
        action.data.type,
        action.data.name,
        action.data.value
      );
    default:
      return state;
  }
};
//========================================Add Credit==============================================
export const subentitiesStatementModalStatus = (state = {}, action) => {
  switch (action.type) {
    case SUB_ENTITIES_STATEMENT_SHOW_MODAL:
      return update(state, { loading: { $set: false }, show: { $set: true } });
    case SUB_ENTITIES_STATEMENT_HIDE_MODAL:
      return update(state, { loading: { $set: false }, show: { $set: false } });
    case SUB_ENTITIES_STATEMENT_IDLE_MODAL:
      return update(state, { loading: { $set: true }, show: { $set: true } });
    default:
      return state;
  }
};

export const subentitiesStatementCreditAmount = (state = 0, action) => {
  switch (action.type) {
    case SUB_ENTITIES_STATEMENT_EDIT_CREDIT_MOUNT:
      return action.data;
    default:
      return state;
  }
};

export const branchesEntitiesNameList = (state = [], action) => {
  switch (action.type) {
    case SUB_ENTITIES_STATEMENT_SAVE_BRANCHES_LIST_DATA:
      return action.data;
    default:
      return state;
  }
};

export const subagentsEntitiesNameList = (state = [], action) => {
  switch (action.type) {
    case SUB_ENTITIES_STATEMENT_SAVE_SUBAGENTS_LIST_DATA:
      return action.data;
    default:
      return state;
  }
};

export const independentAgentsEntitiesNameList = (state = [], action) => {
  switch (action.type) {
    case SUB_ENTITIES_STATEMENT_SAVE_INDEPENDENT_AGENTS_LIST_DATA:
      return action.data;
    default:
      return state;
  }
};
