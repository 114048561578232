import { createSelector } from "reselect";

import { convert, unConvert } from "@src/tools/pricing_tools";
import { getUserMetaSelector } from "../Shared/user_selectors";

export const getCurrencies = (state) => {
  return state.financialAvailableCurrencies;
};

const getCurrency = (state, props) => {
  return state.financialAvailableCurrencies.find(
    (c) => c.notation == props.currentCurrency
  );
};

export const getCurrencySelector = createSelector([getCurrency], (currency) => {
  return currency;
});

export const getCompanyCurrencyInfo = createSelector(
  [getCurrencies, getUserMetaSelector],
  (currencies, userMeta) => {
    const currency = currencies.find(
      (currency) => currency.notation === userMeta.company_currency
    );

    return currency;
  }
);

const getSelCurrency = (state) =>
  state.financialAvailableCurrencies.find((c) => c.selected);

export const getSelCurrencySelector = createSelector(
  [getSelCurrency],
  (currency) => currency
);

const getGlobalCurrency = (state) =>
  state.financialAvailableCurrencies.find((c) => c.is_global);

export const getGlobalCurrencySelector = createSelector(
  [getGlobalCurrency],
  (currency) => currency
);

const propsInjector = (state, props) => props;

export const convertAmountSelector = createSelector(
  [getSelCurrency, getGlobalCurrency, getCurrency, propsInjector],
  (currency, globalCurrency, amountCurrency, props) => {
    const { amount } = props;

    var convertedAmount = amount;
    if (
      amountCurrency &&
      amountCurrency.rate &&
      amountCurrency.notation !== globalCurrency.notation
    ) {
      convertedAmount = unConvert(
        amount,
        globalCurrency.notation,
        amountCurrency.notation,
        amountCurrency.rate
      );
    }

    convertedAmount = convert(
      convertedAmount,
      globalCurrency.notation,
      currency.notation,
      currency.rate
    );

    if (!isFinite(convertedAmount)) {
      convertedAmount = 0;
    }

    return {
      currency: currency.notation,
      currSymbol: currency.symbol,
      convertedAmount,
    };
  }
);

export const unConvertAmount = createSelector(
  [getSelCurrency, getGlobalCurrency, propsInjector],
  (currency, globalCurrency, props) => {
    const { amount } = props;

    var convertedAmount = unConvert(
      amount,
      globalCurrency.notation,
      currency.notation,
      currency.rate
    );

    if (!isFinite(convertedAmount)) {
      convertedAmount = 0;
    }

    return {
      currency: currency.notation,
      currSymbol: currency.symbol,
      convertedAmount,
    };
  }
);

export const convertAmountToCurrencySelector = createSelector(
  [getGlobalCurrency, getCurrency, propsInjector],
  (globalCurrency, currentCurrency, props) => {
    const { amount, targetCurrency: currency } = props;
    var convertedAmount = amount;

    if (!currency) return { currency: currentCurrency, currSymbol: "", amount };

    if (
      currentCurrency &&
      currentCurrency.rate &&
      currentCurrency.notation !== globalCurrency.notation
    ) {
      convertedAmount = unConvert(
        amount,
        globalCurrency.notation,
        currentCurrency.notation,
        currentCurrency.rate
      );
    }

    convertedAmount = convert(
      convertedAmount,
      globalCurrency.notation,
      currency.notation,
      currency.rate
    );

    if (!isFinite(convertedAmount)) convertedAmount = 0;

    return {
      currency: currency.notation,
      currSymbol: currency.symbol,
      convertedAmount,
    };
  }
);

/**
 * Converts an amount to the current Selected Currency. If the amount is in the
 * global currency the convertAmount selector is preferred.
 * @param {} getCurrency
 * @param {} getSelCurrency
 * @param {} getGlobalCurrency
 * @param {} propsInjector
 * @param {} currency
 * @param {} selectedCurrency
 * @param {} globalCurrency
 * @param {} props: Required props: amount, currentCurrency (the currency
 * of the given amount)
 * @returns {}
 */
export const convertToCurrency = createSelector(
  [getCurrency, getSelCurrency, getGlobalCurrency, propsInjector],
  (currency, selectedCurrency, globalCurrency, props) => {
    const { amount, toGlobal } = props;
    var convertedAmount = unConvert(
      amount,
      globalCurrency.notation,
      currency.notation,
      currency.rate
    );

    if (!toGlobal) {
      convertedAmount = convert(
        convertedAmount,
        globalCurrency.notation,
        selectedCurrency.notation,
        selectedCurrency.rate
      );
    }

    return {
      currency: selectedCurrency.notation,
      currSymbol: selectedCurrency.symbol,
      convertedAmount,
    };
  }
);

export const convertAmountList = createSelector(
  [getSelCurrency, getGlobalCurrency, propsInjector],
  (currency, globalCurrency, props) => {
    const { amountList } = props;

    return amountList.map((a) => ({
      currency: currency.notation,
      currSymbol: currency.symbol,
      convertedAmount: convert(
        a,
        globalCurrency.notation,
        currency.notation,
        currency.rate
      ),
    }));
  }
);
