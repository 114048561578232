import PropTypes from "prop-types";
import React from "react";
import { Form, Formik } from "formik";
import { NormalInputField, NormalSelectField } from "@src/components/forms";
import { CustomButton } from "@src/components/common/buttons";
import { defaultfiltersSchema } from "../helpers";


export const FiltersBar = ({
  options,
  filters,
  onSetFilters,
  passengerOptions,
  providerOptions,
  vehicleOptions,
  doorOptions,
  averagePrice,
  minPrice,
  maxPrice,
  transmissionOptions,
  airconditionOptions,
}) => {

  return (
    <div className="FiltersBar">
      <Formik
        initialValues={filters}
        onSubmit={async (values) => {
          onSetFilters(values);
        }}>
        {({ resetForm }) => (
          <Form className="FiltersBar__form">
            <NormalSelectField
              name="provider"
              label="Provider"
              options={providerOptions}
            />
            <NormalSelectField
              name="vehicle"
              label="Vehicle"
              options={vehicleOptions}
            />
            <NormalSelectField
              name="passengers"
              label="Passengers"
              options={passengerOptions}
            />
            <NormalSelectField
              name="numOfDoors"
              label="Number of doors"
              options={doorOptions}
            />
            <NormalSelectField
              name="transmission"
              label="Transmission"
              options={transmissionOptions}
            />
            <NormalSelectField
              name="aircondition"
              label="Aircondition"
              options={airconditionOptions}
            />
            <NormalInputField
              name="min_price"
              label="Min Price"
              type="number"
            />
            <NormalInputField
              name="max_price"
              label="Max Price"
              type="number"
            />
            <NormalSelectField
              name="order_by"
              label="Order By"
              options={[
                ["", "---"],
                ["price", "Price (Lower First)"],
                ["-price", "Price (Higher First)"],
              ]}
            />
            <div className="FiltersBar__form__actions">
              <span className="FiltersBar__form__actions__properties">
                <strong>Available Options: </strong>
                {options}
              </span>
              <span className="FiltersBar__form__actions__properties">
                <strong>Average Price: </strong>
                {averagePrice}
              </span>
              <span className="FiltersBar__form__actions__properties">
                <strong>Max Price: </strong>
                {maxPrice}
              </span>
              <span className="FiltersBar__form__actions__properties">
                <strong>Min Price: </strong>
                {minPrice}
              </span>
              <div className="FiltersBar__form__actions__actionBtnsContr">
                <CustomButton
                  onClick={(e) => {
                    e.preventDefault();
                    resetForm();
                    onSetFilters(defaultfiltersSchema.cast({}));
                  }}>
                  Reset
                </CustomButton>
                <CustomButton type="submit">Apply</CustomButton>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
FiltersBar.defaultProps = {
  currency: "EUR",
  filters: {},
  passengerOptions: [],
  providerOptions: [],
  vehicleOptions: [],
  doorOptions: [],
  transmissionOptions: [],
  airconditionOptions: [],
  options: 0,
  averagePrice: 0,
  minPrice: 0,
  maxPrice: 0,
};
FiltersBar.propTypes = {
  filters: PropTypes.object.isRequired,
  onSetFilters: PropTypes.func.isRequired,
  options: PropTypes.number.isRequired,
  passengerOptions: PropTypes.array.isRequired,
  providerOptions: PropTypes.array.isRequired,
  vehicleOptions: PropTypes.array.isRequired,
  doorOptions: PropTypes.array.isRequired,
  transmissionOptions: PropTypes.array.isRequired,
  airconditionOptions: PropTypes.array.isRequired,
  averagePrice: PropTypes.number.isRequired,
  minPrice: PropTypes.number.isRequired,
  maxPrice: PropTypes.number.isRequired,
  setShowFilters: PropTypes.func,
  currency: PropTypes.string.isRequired,
};
