import React from "react";

import { convertAddressToDisplayName } from "@src/tools/form_tools";

import TransTxt from "@src/components/common/SxFormatMessage";

import {
  initialBranchesColumnsProcessor,
  initialBranchesColumnsMapping,
} from "./../Branches";

export const initialIndependentAgentsColumnsSetUp = {
  basic: {
    value: ["id", "business_name"],
    choices: [
      {
        display_name: <TransTxt id="affiliates_uid" upperCase />,
        key: "id",
        disabled: false,
      },
      {
        display_name: <TransTxt id="affiliates_business_name" startCase />,
        key: "business_name",
        disabled: false,
      },
    ],
  },
  contact: {
    value: ["mobile_phone", "last_name", "email", "phone", "postal_address"],
    choices: [
      {
        display_name: <TransTxt id="phone" startCase />,
        disabled: false,
        key: "phone",
      },
      {
        display_name: <TransTxt id="last_name" startCase />,
        disabled: false,
        key: "last_name",
      },
      {
        display_name: <TransTxt id="first_name" startCase />,
        disabled: false,
        key: "first_name",
      },
      {
        display_name: <TransTxt id="email" startCase />,
        disabled: false,
        key: "email",
      },
      {
        display_name: <TransTxt id="mobile_phone" startCase />,
        disabled: false,
        key: "mobile_phone",
      },
      {
        display_name: <TransTxt id="postal_address" startCase />,
        disabled: false,
        key: "postal_address",
        cb: convertAddressToDisplayName,
      },
      {
        display_name: <TransTxt id="url" startCase />,
        disabled: false,
        key: "url",
      },
    ],
  },
  certification: {
    value: [],
    choices: [
      {
        display_name: <TransTxt id="affiliates_brand_name" startCase />,
        key: "brand_name",
        disabled: false,
      },
      {
        display_name: <TransTxt id="registration_code" startCase />,
        disabled: false,
        key: "registration_code",
      },
      {
        display_name: <TransTxt id="founding_year" startCase />,
        disabled: false,
        key: "founding_year",
      },
    ],
  },
  services: {
    value: ["main_service", "service_type"],
    choices: [
      {
        display_name: <TransTxt id="main_service" startCase />,
        disabled: false,
        key: "main_service",
      },
      {
        display_name: <TransTxt id="service_categories" startCase />,
        disabled: false,
        key: "service_categories",
      },
      {
        display_name: <TransTxt id="service_type" startCase />,
        disabled: false,
        key: "service_type",
      },
    ],
  },
  operation: {
    value: ["expertise_type", "entity_type"],
    choices: [
      {
        display_name: <TransTxt id="expertise_type" startCase />,
        disabled: false,
        key: "expertise_type",
      },
    ],
  },
  stage: {
    value: ["status"],
    choices: [
      {
        display_name: <TransTxt id="creator" startCase />,
        disabled: false,
        key: "creator",
      },
      {
        display_name: <TransTxt id="created" startCase />,
        disabled: false,
        key: "created",
      },

      {
        display_name: <TransTxt id="edited" startCase />,
        key: "edited",
        disabled: false,
      },
      {
        display_name: <TransTxt id="status" startCase />,
        key: "status",
        disabled: true,
      },
    ],
  },
};

export const initialIndependentAgentsColumnsProcessor =
  initialBranchesColumnsProcessor;

export const initialIndependentAgentsColumnsMapping =
  initialBranchesColumnsMapping;
