export const initialB2CAccountStatementFilterForm = {
  entities: {
    value: "",
    verbose: "Entity Name",
  },
  website: {
    value: [],
    verbose: "B2C Website",
  },
  reference: {
    value: "",
    verbose: "Description",
  },
  stripe_transaction_id: {
    value: "",
    verbose: "Stripe Transaction Id ",
  },
};
