import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { NormalSelectField } from "@src/components/forms";
import {
  AccommodationAllowance,
  EmptyRun,
  Entrance,
  MealAllowance,
  Tips,
} from "./products/addhoc";
import { useSelector } from "react-redux";
import { getSetupFormDataSelector } from "@src/selectors/Project/TripPlanner";
import { getDbDAdhocServicesList } from "@src/selectors/Project/TripPlanner/day_by_day/addhoc_services";

export const dayAdhocTypes = {
  MEA: "Coordinator Meal Allowance",
  EMT: "Coordinator Empty Run",
  ACL: "Coordinator Accommodation Allowance",
  TIP: "Tips",
  ENT: "Entrace",
};

const AdhocServiceModal = ({
  date,
  dayIdx,
  commonDestDays,
  editingAdhoc,
  onCancel,
}) => {
  const [serviceType, setServiceType] = useState(null);
  const [step, setStep] = useState(editingAdhoc ? 2 : 1);

  const initialValues = { service_type: null };

  commonDestDays.forEach(
    (day) => (initialValues[`${day.date}___${day.day}`] = day.date === date)
  );

  const { adults, children: childs } = useSelector((state) =>
    getSetupFormDataSelector(state)
  );

  const { adhoc } = useSelector((state) => {
    const adhoc = (getDbDAdhocServicesList(state) ?? []).find(
      (srv) => srv.id === editingAdhoc
    );
    return { adhoc };
  });

  useEffect(() => {
    if (!adhoc?.id) return;

    setServiceType(adhoc.addhoc_service_type);
  }, [adhoc?.id]);

  return (
    <div className="Modal AddAdhocServiceModal">
      {step === 1 && (
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            setServiceType(values.service_type);
            setStep(2);
          }}>
          {({ submitForm }) => (
            <div className="Modal__card">
              <div className="Modal__card__header">
                <h5>Add Ad hoc Service</h5>
              </div>
              <div className="Modal__card__body">
                <Form className="Form">
                  <NormalSelectField
                    name="service_type"
                    label="Service Type"
                    options={[
                      [null, "------"],
                      ...Object.entries(dayAdhocTypes),
                    ]}
                  />
                </Form>
              </div>
              <div className="Modal__card__actions">
                <button className="Button" data-ghost={true} onClick={onCancel}>
                  Cancel
                </button>
                <button className="Button" onClick={submitForm}>
                  Next
                </button>
              </div>
            </div>
          )}
        </Formik>
      )}
      {step === 2 && serviceType === "ACL" && (
        <AccommodationAllowance
          adhoc={adhoc}
          dayIdx={dayIdx}
          adults={adults}
          childs={childs}
          onCancel={onCancel}
        />
      )}
      {step === 2 && serviceType === "EMT" && (
        <EmptyRun
          adhoc={adhoc}
          dayIdx={dayIdx}
          adults={adults}
          childs={childs}
          onCancel={onCancel}
        />
      )}
      {step === 2 && serviceType === "MEA" && (
        <MealAllowance
          adhoc={adhoc}
          dayIdx={dayIdx}
          adults={adults}
          childs={childs}
          onCancel={onCancel}
        />
      )}
      {step === 2 && serviceType === "TIP" && (
        <Tips
          adhoc={adhoc}
          dayIdx={dayIdx}
          adults={adults}
          childs={childs}
          onCancel={onCancel}
        />
      )}
      {step === 2 && serviceType === "ENT" && (
        <Entrance
          adhoc={adhoc}
          dayIdx={dayIdx}
          adults={adults}
          childs={childs}
          onCancel={onCancel}
        />
      )}
    </div>
  );
};
AdhocServiceModal.defaultProps = { commonDestDays: [] };
AdhocServiceModal.propTypes = {
  commonDestDays: PropTypes.array.isRequired,
  date: PropTypes.string.isRequired,
  dayIdx: PropTypes.number.isRequired,
  editingAdhoc: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
};
export default AdhocServiceModal;
