import { initialMenu } from "@src/config/menu";
import {
  MENU_CHANGE_SLIDER,
  MENU_RESIZE_CONTAINER,
} from "@src/actions/Menu/types";

export const initialActiveSlider = {
  path: "/project",
};

export const sidenavMenu = (state = initialMenu, action) => {
  // do something like init or handle permissions
  return state;
};

export const activeSlider = (state = initialActiveSlider, action) => {
  switch (action.type) {
    case MENU_CHANGE_SLIDER:
      return action.data;
    default:
      return state;
  }
};

// FOR REMOVAL
export const toggleAllContainerStatus = (state = true, action) => {
  switch (action.type) {
    case MENU_RESIZE_CONTAINER:
      return action.data;
    default:
      return state;
  }
};
