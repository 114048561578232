import React from "react";
import _ from "lodash";

import { convertAddressToDisplayName } from "@src/tools/form_tools";
import { getDisplayNameFromOptions } from "@src/processors/columnsDataProcessor";

import TransTxt from "@src/components/common/SxFormatMessage";

import { baseColumnsProcessor } from "@src/forms/CommonColumns/baseColumns";

export const initialBranchesColumnsSetUp = {
  basic: {
    value: ["id", "brand_name"],
    choices: [
      {
        display_name: <TransTxt id="affiliates_uid" upperCase />,
        key: "id",
        disabled: false,
      },
      {
        display_name: <TransTxt id="affiliates_brand_name" startCase />,
        key: "brand_name",
        disabled: true,
      },
    ],
  },
  contact: {
    value: ["mobile_phone", "last_name", "email", "phone", "postal_address"],
    choices: [
      {
        display_name: <TransTxt id="phone" startCase />,
        disabled: false,
        key: "phone",
      },
      {
        display_name: <TransTxt id="last_name" startCase />,
        disabled: false,
        key: "last_name",
      },
      {
        display_name: <TransTxt id="first_name" startCase />,
        disabled: false,
        key: "first_name",
      },
      {
        display_name: <TransTxt id="email" startCase />,
        disabled: false,
        key: "email",
      },
      {
        display_name: <TransTxt id="mobile_phone" startCase />,
        disabled: false,
        key: "mobile_phone",
      },
      {
        display_name: <TransTxt id="postal_address" startCase />,
        disabled: false,
        key: "postal_address",
        cb: convertAddressToDisplayName,
      },
      {
        display_name: <TransTxt id="url" startCase />,
        disabled: false,
        key: "url",
      },
    ],
  },
  certification: {
    value: [],
    choices: [
      {
        display_name: <TransTxt id="affiliates_business_name" startCase />,
        key: "business_name",
        disabled: false,
      },
      {
        display_name: <TransTxt id="registration_code" startCase />,
        disabled: false,
        key: "registration_code",
      },
      {
        display_name: <TransTxt id="founding_year" startCase />,
        disabled: false,
        key: "founding_year",
      },
      {
        display_name: <TransTxt id="licence_type" startCase />,
        disabled: false,
        key: "licence_type",
      },
    ],
  },
  services: {
    value: ["main_service", "service_type"],
    choices: [
      {
        display_name: <TransTxt id="main_service" startCase />,
        disabled: false,
        key: "main_service",
      },
      {
        display_name: <TransTxt id="service_categories" startCase />,
        disabled: false,
        key: "service_categories",
      },
      {
        display_name: <TransTxt id="service_type" startCase />,
        disabled: false,
        key: "service_type",
      },
    ],
  },
  operation: {
    value: ["expertise_type", "entity_type"],
    choices: [
      {
        display_name: <TransTxt id="expertise_type" startCase />,
        disabled: false,
        key: "expertise_type",
      },
      {
        display_name: <TransTxt id="entity_type" startCase />,
        disabled: false,
        key: "entity_type",
      },
    ],
  },
  stage: {
    value: ["status"],
    choices: [
      {
        display_name: <TransTxt id="creator" startCase />,
        disabled: false,
        key: "creator",
      },
      {
        display_name: <TransTxt id="created" startCase />,
        disabled: false,
        key: "created",
      },

      {
        display_name: <TransTxt id="edited" startCase />,
        key: "edited",
        disabled: false,
      },
      {
        display_name: <TransTxt id="status" startCase />,
        key: "status",
        disabled: true,
      },
    ],
  },
};

export const initialBranchesColumnsProcessor = _.merge(baseColumnsProcessor, {
  id: {
    key: "id",
    getter: (data) => data.id,
  },
  brand_name: {
    key: "name",
    getter: (data) => data.name,
  },
  phone: {
    key: "phone",
    getter: (data) => data.phone,
  },
  last_name: {
    key: "last_name",
    getter: (data) => `${_.get(data, "contact_person[0].last_name", "")}`,
  },
  first_name: {
    key: "first_name",
    getter: (data) => `${_.get(data, "contact_person[0].first_name", "")}`,
  },
  email: {
    key: "email",
    getter: (data) => `${_.get(data, "email", "")}`,
  },
  mobile_phone: {
    key: "mobile_phone",
    getter: (data) => `${_.get(data, "mobile_phone", "")}`,
  },
  postal_address: {
    key: "postal_address",
    getter: (data) => convertAddressToDisplayName(data.address),
  },
  url: {
    key: "url",
    getter: (data) => data.url,
  },
  business_name: {
    key: "business_name",
    getter: (data) => data.legal_title,
  },
  registration_code: {
    key: "registration_code",
    getter: (data) => data.registration_code,
  },
  founding_year: {
    key: "founding_year",
    getter: (data) => data.founding_year,
  },
  main_service: {
    key: "main_service",
    getter: (data, options) => {
      return getDisplayNameFromOptions(
        data.main_service,
        _.get(options[3], "main_service.choices")
      );
    },
  },
  licence_type: {
    key: "licence_type",
    getter: (data) => {
      const res = data.license_type;
      const arr = [];
      res.forEach((item) => {
        arr.push(item[0]);
      });
      if (arr.length > 1) {
        return `${arr[0]}(${arr.length})`;
      }
      return arr;
    },
    extraGetter: (data, options) => {
      const res = data.license_type;
      const arr = [];
      res.forEach((item) => {
        arr.push(
          getDisplayNameFromOptions(
            item[0],
            _.get(options[3], "license_type_choices.choices")
          )
        );
      });
      return arr;
    },
  },
  expertise_type: {
    key: "expertise_type",
    getter: (data) => data.expertise_type,
    extraGetter: (data, options) => {
      return getDisplayNameFromOptions(
        data.expertise_type,
        _.get(options[3], "expertise_type.child.choices")
      );
    },
  },
  entity_type: {
    key: "entity_type",
    getter: (data) => data.entity_type,
    extraGetter: (data, options) => {
      return getDisplayNameFromOptions(
        data.entity_type,
        _.get(options[3], "entity_type.child.choices")
      );
    },
  },
});

export const initialBranchesColumnsMapping = {
  id: {
    key: "id",
    fixed: "left",
    width: 80,
  },
  brand_name: {
    key: "brand_name",
    width: 170,
  },
  phone: {
    key: "phone",
    width: 200,
  },
  last_name: {
    key: "last_name",
    width: 150,
  },
  first_name: {
    key: "first_name",
    width: 150,
  },
  email: {
    key: "email",
    width: 250,
  },
  mobile_phone: {
    key: "mobile_phone",
    width: 200,
  },
  postal_address: {
    key: "postal_address",
    width: 200,
  },
  url: {
    key: "url",
    width: 200,
  },
  business_name: {
    key: "business_name",
    width: 150,
  },
  registration_code: {
    key: "registration_code",
    width: 150,
  },
  founding_year: {
    key: "founding_year",
    width: 150,
  },

  licence_type: {
    key: "licence_type",
    width: 200,
  },
  expertise_type: {
    key: "expertise_type",
    width: 170,
  },
  entity_type: {
    key: "entity_type",
    width: 170,
  },
  main_service: {
    key: "main_service",
    width: 150,
  },
  service_categories: {
    key: "service_categories",
    width: 170,
  },
  service_type: {
    key: "service_type",
    width: 170,
  },
  created: {
    key: "created",
    width: 170,
  },
  edited: {
    key: "edited",
    width: 170,
  },
  creator: {
    key: "creator",
    width: 170,
  },
  status: {
    key: "status",
    fixed: "right",
  },
};
