import { Tag } from "rsuite";
import { bankersRounder, grossMarginer } from "@src/tools/pricing_tools";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import React from "react";
import { createUseStyles } from "react-jss";
import {
  overviewCtrlSectionHeaderStyles,
  overviewCtrlSectionStyles,
} from "./ServicesFilter";
import { variables } from "@src/jsssetup";
import { getServicesPricingSelector } from "@src/selectors/Project/TripPlanner/pricing";
import { PriceExcHoc } from "../Components/PriceDetails";
import {
  convertAmountToCurrencySelector,
  getCurrencySelector,
  getSelCurrencySelector,
} from "@src/selectors/Financial";
import { useFinalSellingPricing } from "@src/pages/hooks/pricing";

var Price = ({ price, currency, rounding = "no-rounding" }) => {
  var finalPrice = price;
  if (rounding === "round-up") {
    finalPrice = Math.ceil(price);
  } else if (rounding === "round-down") {
    finalPrice = Math.floor(price);
  }

  return (
    <strong>
      {finalPrice.toLocaleString("en-US", {
        style: "currency",
        currency: currency,
      })}
    </strong>
  );
};
Price.defaultProps = { price: 0, currency: "EUR", rounding: "no-rounding" };
Price.propTypes = {
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  currency: PropTypes.string.isRequired,
  rounding: PropTypes.oneOf(["no-rounding", "round-up", "round-down"]),
};
Price = PriceExcHoc(Price);

const styles = createUseStyles({
  KPIController: overviewCtrlSectionStyles(),
  header: overviewCtrlSectionHeaderStyles(),
  content: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    marginTop: variables.normal_gap,
  },
  group: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    alignItems: "center",
  },
  orangeTags: {
    background: variables.colors.easy.orange,
    color: variables.colors.text.light,
    fontWeight: "500",
    fontSize: "small",
  },
  purpleTags: {
    background: variables.colors.deepPurple,
    color: variables.colors.text.light,
    fontWeight: "500",
    fontSize: "small",
  },
  greenTags: {
    background: variables.colors.green,
    color: variables.colors.text.light,
    fontWeight: "500",
    fontSize: "small",
  },
  overBudget: (props) => ({
    background: props.over ? variables.colors.deepRed : variables.colors.green,
    color: variables.colors.text.light,
    fontWeight: "500",
    fontSize: "small",
  }),
});
export default function KPIController() {
  const currentStep = useSelector((state) => state.tripPlannerCurrentStep);
  const { budget, selectedCurrency } = useSelector((state) => {
    var { budget } = state.tripPlannerItinerarySetupForm;
    if (typeof budget === "string")
      budget = parseFloat(parseFloat(budget).toFixed(2));

    const selectedCurrency = getSelCurrencySelector(state);
    return { budget, selectedCurrency };
  });

  const { sellingAmount, sellingCurrency, totalPriceRounding } =
    useFinalSellingPricing();

  var buying = 0;
  var selling = 0;
  var currency = "";
  var rounding = "no-rounding";
  if (currentStep === 4) {
    const { regular_pricing, option_pricing } = useSelector((state) => {
      const regular_pricing = getServicesPricingSelector(state, {
        booking_mode: "regular",
      });
      const option_pricing = useSelector((state) =>
        getServicesPricingSelector(state, { booking_mode: "option" })
      );

      return { regular_pricing, option_pricing };
    });

    currency = regular_pricing.currency;
    buying = regular_pricing.buying.amount;
    selling = regular_pricing.selling.amount;
    buying += option_pricing.buying.amount;
    selling += option_pricing.selling.amount;
  } else {
    const pricing = useSelector((state) => getServicesPricingSelector(state));
    buying = pricing.buying.amount;
    selling = sellingAmount;
    currency = sellingCurrency;
    rounding = totalPriceRounding;
  }

  const targetCurrency = useSelector((state) =>
    getCurrencySelector(state, { currentCurrency: currency })
  );
  const convertedBudget =
    useSelector((state) =>
      convertAmountToCurrencySelector(state, {
        amount: budget,
        targetCurrency,
        currentCurrency: selectedCurrency.notation,
      })
    )?.convertedAmount ?? 0;

  const budgetDiff = Math.abs(selling - convertedBudget).toFixed(2);
  const over = convertedBudget < selling;
  const classes = styles({ over });

  function getGrossMargin() {
    const revenue = selling;
    const cost = buying;

    if (isNaN(revenue) || isNaN(cost)) return "%0";

    var grossMargin = grossMarginer(revenue, cost, 4);
    if (isNaN(grossMargin)) grossMargin = 0;

    return `%${bankersRounder(grossMargin * 100)}`;
  }

  return (
    <div className={classes.KPIController}>
      <div className={classes.header}>KPI</div>
      <div className={classes.content}>
        <div className={classes.group}>
          <strong>Budget</strong>
          <Tag className={classes.purpleTags}>
            <Price currency={currency} price={budget} />
          </Tag>
        </div>
        <div className={classes.group}>
          <strong>Total Cost</strong>
          <Tag className={classes.orangeTags}>
            <Price currency={currency} price={buying} />
          </Tag>
        </div>
        <div className={classes.group}>
          <strong>Sale</strong>
          <Tag className={classes.greenTags}>
            <Price currency={currency} price={selling} rounding={rounding} />
          </Tag>
        </div>
        <div className={classes.group}>
          <strong>{over ? "Over Budget" : "Balance"}</strong>
          <Tag className={classes.overBudget}>
            <Price currency={currency} price={budgetDiff} rounding={rounding} />
          </Tag>
        </div>
        <div className={classes.group}>
          <strong>Margin</strong>
          <Tag className={classes.greenTags}>
            <strong>{getGrossMargin()}</strong>
          </Tag>
        </div>
      </div>
    </div>
  );
}
