import { Svg, Path } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import React from "react";

export const AccIcon = ({ width, height, color }) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none">
    <Path
      d="M0.833374 9.16667V17.5H5.00004V12.5H8.33337V17.5H12.5V9.16667L6.66671 5L0.833374 9.16667Z"
      fill={color}
    />
    <Path
      d="M8.33337 2.5V4.14167L14.1667 8.30833V9.16667H15.8334V10.8333H14.1667V12.5H15.8334V14.1667H14.1667V17.5H19.1667V2.5H8.33337ZM15.8334 7.5H14.1667V5.83333H15.8334V7.5Z"
      fill={color}
    />
  </Svg>
);
AccIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

export const RestIcon = ({ width, height, color }) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={color}
    viewBox="0 0 22 22">
    <Path d="M14.6667 5.49999V12.8333H17.4167V20.1667H19.25V1.83333C16.72 1.83333 14.6667 3.88666 14.6667 5.49999ZM10.0833 8.25H8.25V1.83333H6.41667V8.25H4.58333V1.83333H2.75V8.25C2.75 10.2758 4.39083 11.9167 6.41667 11.9167V20.1667H8.25V11.9167C10.2758 11.9167 11.9167 10.2758 11.9167 8.25V1.83333H10.0833V8.25Z" />
  </Svg>
);
RestIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

export const GeneralIcon = ({ width, height, color }) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={color}
    viewBox="0 0 18 18">
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.5 4.9755C1.5 4.05374 1.86617 3.16973 2.51795 2.51795C3.16973 1.86617 4.05374 1.5 4.9755 1.5C5.89726 1.5 6.78127 1.86617 7.43305 2.51795C8.08483 3.16973 8.451 4.05374 8.451 4.9755C8.451 5.89726 8.08483 6.78127 7.43305 7.43305C6.78127 8.08483 5.89726 8.451 4.9755 8.451C4.05374 8.451 3.16973 8.08483 2.51795 7.43305C1.86617 6.78127 1.5 5.89726 1.5 4.9755ZM9.549 13.0245C9.549 12.5681 9.6389 12.1162 9.81356 11.6945C9.98822 11.2728 10.2442 10.8897 10.5669 10.5669C10.8897 10.2442 11.2728 9.98822 11.6945 9.81356C12.1162 9.6389 12.5681 9.549 13.0245 9.549C13.4809 9.549 13.9328 9.6389 14.3545 9.81356C14.7762 9.98822 15.1593 10.2442 15.482 10.5669C15.8048 10.8897 16.0608 11.2728 16.2354 11.6945C16.4101 12.1162 16.5 12.5681 16.5 13.0245C16.5 13.9463 16.1338 14.8303 15.482 15.482C14.8303 16.1338 13.9463 16.5 13.0245 16.5C12.1027 16.5 11.2187 16.1338 10.5669 15.482C9.91517 14.8303 9.549 13.9463 9.549 13.0245Z"
    />
    <Path d="M1.5 13.125C1.5 11.5343 1.5 10.7385 1.99425 10.2443C2.4885 9.75 3.28425 9.75 4.875 9.75C6.46575 9.75 7.2615 9.75 7.75575 10.2443C8.25 10.7385 8.25 11.5343 8.25 13.125C8.25 14.7157 8.25 15.5115 7.75575 16.0057C7.2615 16.5 6.46575 16.5 4.875 16.5C3.28425 16.5 2.4885 16.5 1.99425 16.0057C1.5 15.5115 1.5 14.7157 1.5 13.125ZM9.75 4.875C9.75 3.28425 9.75 2.4885 10.2443 1.99425C10.7385 1.5 11.5343 1.5 13.125 1.5C14.7157 1.5 15.5115 1.5 16.0057 1.99425C16.5 2.4885 16.5 3.28425 16.5 4.875C16.5 6.46575 16.5 7.2615 16.0057 7.75575C15.5115 8.25 14.7157 8.25 13.125 8.25C11.5343 8.25 10.7385 8.25 10.2443 7.75575C9.75 7.2615 9.75 6.46575 9.75 4.875Z" />
  </Svg>
);
GeneralIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

export const CoordinatorIcon = ({ width, height, color }) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={color}
    viewBox="0 0 121 136">
    <Path d="M82.3734 63.6897C93.9443 68.2324 103.146 75.3246 109.843 84.9663C116.585 94.608 120.047 105.501 120.184 117.646V129.188C120.093 130.857 119.501 132.248 118.362 133.36C117.223 134.473 115.811 135.075 114.125 135.075H6.29706C4.56598 135.075 3.15378 134.519 2.06047 133.36C0.967157 132.201 0.329391 130.811 0.238281 129.188V117.646C0.4205 105.501 3.88266 94.608 10.6703 84.9663C17.458 75.3246 26.6145 68.2324 38.0943 63.6897C31.9899 58.8688 27.89 52.9818 25.7489 45.9823C23.6079 38.9827 23.7901 31.8905 26.25 24.6129C28.8011 17.3816 33.1743 11.6336 39.3242 7.36904C45.4741 3.19715 52.444 1.01849 60.2338 0.925781C68.0237 1.01849 75.0391 3.19715 81.1435 7.4154C87.2934 11.6336 91.621 17.3816 94.2177 24.6592C96.7232 31.8905 96.8598 39.0291 94.7188 46.0286C92.5777 53.0281 88.4322 58.9151 82.3734 63.736V63.6897Z" />
  </Svg>
);
CoordinatorIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

export const TrainIcon = ({ width, height, color }) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={color}
    viewBox="0 0 168 168">
    <Path
      d="M84 14C56 14 28 17.5 28 42V108.5C28 122.01 38.99 133 52.5 133L42 143.5V147H57.61L71.61 133H98L112 147H126V143.5L115.5 133C129.01 133 140 122.01 140 108.5V42C140 17.5 114.94 14 84 14ZM52.5 119C46.69 119 42 114.31 42 108.5C42 102.69 46.69 98 52.5 98C58.31 98 63 102.69 63 108.5C63 114.31 58.31 119 52.5 119ZM77 70H42V42H77V70ZM91 70V42H126V70H91ZM115.5 119C109.69 119 105 114.31 105 108.5C105 102.69 109.69 98 115.5 98C121.31 98 126 102.69 126 108.5C126 114.31 121.31 119 115.5 119Z"
      fill="white"
    />
  </Svg>
);
TrainIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

export const FlightIcon = ({ width, height, color }) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 179 189"
    fill={color}>
    <Path d="M163.247 165.841L134.41 71.1354L174.181 27.836C180.183 21.3017 179.753 11.1822 173.218 5.18034C166.684 -0.821487 156.565 -0.391677 150.563 6.1426L110.792 49.4421L13.9818 28.7391C10.161 27.8422 6.05558 29.1514 3.38006 32.0643C-1.68172 37.5751 -0.0226179 46.3562 6.62596 49.7053L78.1793 84.9476L38.4805 128.168L10.9263 122.454C9.09461 122.078 7.15679 122.765 5.9275 124.104L1.73345 128.67C-0.652824 131.268 0.0522217 135.399 3.07123 137.011L31.338 152.379L35.8272 154.76L38.168 158.217L40.679 162.12L52.1661 179.638L56.2115 185.821C58.1522 188.765 62.2495 189.045 64.6357 186.447L68.8298 181.88C70.1314 180.463 70.5798 178.553 70.0497 176.76L62.0983 149.862L101.87 106.562L143.052 174.87C146.881 181.289 155.771 182.197 160.833 176.687C163.508 173.774 164.464 169.572 163.247 165.841Z" />
  </Svg>
);
FlightIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

export const TransferIcon = ({ width, height, color }) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 160 160"
    fill={color}>
    <Path d="M30.7689 61.5379L55.3843 30.7686L79.9997 61.5379L61.5382 61.5379L61.5382 141.538L49.2305 141.538L49.2305 61.5379L30.7689 61.5379ZM79.9997 98.4609L98.4612 98.4609L98.4612 18.4609L110.769 18.4609L110.769 98.4609L129.23 98.4609L104.615 129.23L79.9997 98.4609Z" />
  </Svg>
);
TransferIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

export const CoachIcon = ({ width, height, color }) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 168 168"
    fill={color}>
    <Path d="M28 112C28 118.16 30.73 123.69 35 127.54V140C35 143.85 38.15 147 42 147H49C52.85 147 56 143.85 56 140V133H112V140C112 143.85 115.15 147 119 147H126C129.85 147 133 143.85 133 140V127.54C137.27 123.69 140 118.16 140 112V42C140 17.5 114.94 14 84 14C53.06 14 28 17.5 28 42V112ZM52.5 119C46.69 119 42 114.31 42 108.5C42 102.69 46.69 98 52.5 98C58.31 98 63 102.69 63 108.5C63 114.31 58.31 119 52.5 119ZM115.5 119C109.69 119 105 114.31 105 108.5C105 102.69 109.69 98 115.5 98C121.31 98 126 102.69 126 108.5C126 114.31 121.31 119 115.5 119ZM126 77H42V42H126V77Z" />
  </Svg>
);
CoachIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

export const AddonIcon = ({ width, height, color }) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={color}
    viewBox="0 0 178 178">
    <Path d="M103.833 74.1667H22.25V89H103.833V74.1667ZM103.833 44.5H22.25V59.3333H103.833V44.5ZM133.5 103.833V74.1667H118.667V103.833H89V118.667H118.667V148.333H133.5V118.667H163.167V103.833H133.5ZM22.25 118.667H74.1667V103.833H22.25V118.667Z" />
  </Svg>
);
AddonIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

export const FerryIcon = ({ width, height, color }) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 139 139"
    fill={color}>
    <Path
      d="M115.832 121.624C107.782 121.624 99.7312 118.902 92.6654 113.979C78.5337 123.883 60.4637 123.883 46.332 113.979C39.2662 118.902 31.2158 121.624 23.1654 121.624H11.582V133.208H23.1654C31.1579 133.208 39.0345 131.181 46.332 127.474C53.4991 131.157 61.4408 133.078 69.4987 133.078C77.5566 133.078 85.4983 131.157 92.6654 127.474C99.9629 131.239 107.84 133.208 115.832 133.208H127.415V121.624H115.832ZM22.8758 110.041H23.1654C32.432 110.041 40.6562 104.944 46.332 98.4577C52.0079 104.944 60.232 110.041 69.4987 110.041C78.7654 110.041 86.9895 104.944 92.6654 98.4577C98.3412 104.944 106.565 110.041 115.832 110.041H116.122L127.126 71.3527C127.763 69.2098 127.357 65.2135 123.303 63.9394L115.832 61.5069V34.7494C115.832 28.3785 110.62 23.166 104.249 23.166H86.8737V5.79102H52.1237V23.166H34.7487C28.3779 23.166 23.1654 28.3785 23.1654 34.7494V61.5069L15.6941 63.9394C12.0454 65.0398 11.0029 68.8044 11.8716 71.3527L22.8758 110.041ZM34.7487 34.7494H104.249V57.7423L69.4987 46.3327L34.7487 57.7423V34.7494Z"
      fill="white"
    />
  </Svg>
);
FerryIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

export const AdHocIcon = ({ width, height, color }) => (
  <Svg
    width={width}
    height={height}
    viewBox="0 0 146 146"
    fill={color}
    xmlns="http://www.w3.org/2000/svg">
    <Path d="M29.2016 29.1996C29.2016 25.3274 30.7398 21.6139 33.4778 18.8758C36.2158 16.1378 39.9294 14.5996 43.8016 14.5996H102.202C106.074 14.5996 109.787 16.1378 112.525 18.8758C115.263 21.6139 116.802 25.3274 116.802 29.1996V43.7996C116.802 47.6718 115.263 51.3853 112.525 54.1234C109.787 56.8614 106.074 58.3996 102.202 58.3996H43.8016C39.9294 58.3996 36.2158 56.8614 33.4778 54.1234C30.7398 51.3853 29.2016 47.6718 29.2016 43.7996V29.1996ZM29.2016 116.8C29.2016 120.672 30.7398 124.385 33.4778 127.123C36.2158 129.861 39.9294 131.4 43.8016 131.4H102.202C106.074 131.4 109.787 129.861 112.525 127.123C115.263 124.385 116.802 120.672 116.802 116.8V102.2C116.802 98.3274 115.263 94.6139 112.525 91.8759C109.787 89.1378 106.074 87.5996 102.202 87.5996H43.8016C39.9294 87.5996 36.2158 89.1378 33.4778 91.8759C30.7398 94.6139 29.2016 98.3274 29.2016 102.2V116.8ZM18.2516 69.3496C17.2835 69.3496 16.3551 69.7342 15.6706 70.4187C14.9861 71.1032 14.6016 72.0316 14.6016 72.9996C14.6016 73.9676 14.9861 74.896 15.6706 75.5805C16.3551 76.2651 17.2835 76.6496 18.2516 76.6496H127.752C128.72 76.6496 129.648 76.2651 130.333 75.5805C131.017 74.896 131.402 73.9676 131.402 72.9996C131.402 72.0316 131.017 71.1032 130.333 70.4187C129.648 69.7342 128.72 69.3496 127.752 69.3496H18.2516Z" />
  </Svg>
);
AdHocIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

export const ActivitiesIcon = ({ width, height, color }) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={color}
    viewBox="0 0 165 165">
    <Path d="M137.5 82.5C137.5 74.9375 143.688 68.75 151.25 68.75V41.25C151.25 33.6875 145.063 27.5 137.5 27.5H27.5C19.9375 27.5 13.8187 33.6875 13.8187 41.25V68.75C21.3813 68.75 27.5 74.9375 27.5 82.5C27.5 90.0625 21.3813 96.25 13.75 96.25V123.75C13.75 131.312 19.9375 137.5 27.5 137.5H137.5C145.063 137.5 151.25 131.312 151.25 123.75V96.25C143.688 96.25 137.5 90.0625 137.5 82.5ZM107.113 115.5L82.5 99.6875L57.8875 115.5L65.3125 87.175L42.6938 68.6812L71.8438 66.9625L82.5 39.875L93.0875 67.0312L122.238 68.75L99.6188 87.2437L107.113 115.5V115.5Z" />
  </Svg>
);
ActivitiesIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};
