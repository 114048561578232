import { Modal, IconButton, Icon, Loader, Panel, Tag } from "rsuite";

import PropTypes from "prop-types";
import React from "react";
import {
  hideUpdateOfferModal,
  updateOffer,
} from "@src/actions/Project/TripPlanner";
import { connect } from "react-redux";

class UpdateOfferModal extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      show,
      loading,
      version,
      offer_email,
      offer_title,
      reference,
      onHide,
      onUpdate,
    } = this.props;

    if (!show) {
      return null;
    }

    const cls = `CustomModal${
      loading ? " CustomModal--loading" : ""
    } UpdateOfferModal`;

    return (
      <Modal className={cls} show={show}>
        <Modal.Header closeButton={false}>
          <Modal.Title>Update Offer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <Loader vertical content="Please wait..." size="lg" />
          ) : (
            <React.Fragment>
              <Panel
                className="CustomPanel UpdateOfferModal__offer-info"
                bordered
                header={<h6>Offer Information</h6>}>
                <ul>
                  <li>
                    <strong>Version: </strong>#{version}
                  </li>
                  <li>
                    <strong>Offer Title: </strong>
                    {offer_title}
                  </li>
                  <li>
                    <strong>Email: </strong>
                    {offer_email}
                  </li>
                  <li>
                    <strong>Reference: </strong>
                    <Tag color="blue">
                      <strong>{reference}</strong>
                    </Tag>
                  </li>
                </ul>
              </Panel>
              <p className="UpdateOfferModal__description">
                Are you sure you want to update this offer?
              </p>
              <p className="UpdateOfferModal__description">
                The customer will be notified in real time with all updated
                information.
              </p>
            </React.Fragment>
          )}
        </Modal.Body>
        {loading ? null : (
          <Modal.Footer>
            <IconButton
              color="blue"
              icon={<Icon icon="close" />}
              onClick={onHide}>
              Cancel
            </IconButton>
            <IconButton
              color="green"
              icon={<Icon icon="check" />}
              onClick={onUpdate}>
              Update
            </IconButton>
          </Modal.Footer>
        )}
      </Modal>
    );
  }
}
UpdateOfferModal.defaultProps = {
  show: false,
  loading: false,
  version: 0,
  offer_email: "",
  offer_title: "",
  reference: "",
  validity: "",
};
UpdateOfferModal.propTypes = {
  show: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  version: PropTypes.number,
  offer_email: PropTypes.string.isRequired,
  offer_title: PropTypes.string.isRequired,
  reference: PropTypes.string.isRequired,
  validity: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  const { show, loading } = state.tripPlannerRequestUpdateOfferModal;
  const {
    version,
    offer_email,
    offer_title,
    reference,
    validity,
  } = state.tripPlannerOfferInstanceData;

  return {
    show,
    loading,
    version,
    offer_email,
    offer_title,
    reference,
    validity,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onHide: () => dispatch(hideUpdateOfferModal()),
    onUpdate: () => dispatch(updateOffer()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateOfferModal);
