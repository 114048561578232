import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { NormalInputField } from "@src/components/forms";
import { createUseStyles } from "react-jss";
import { variables } from "@src/jsssetup";
import { useFormikContext } from "formik";

export function getPricingUIDForSrv({ srvType, service }) {
  const uid = service?.uid;

  switch (srvType) {
    case "FL":
      return `${srvType}___${uid}`;
    case "AFER":
      return `${srvType}___${service.token}`;
    case "ATRA":
      return `${srvType}___${service.id}`;
    case "FER":
      return `${srvType}___${uid}`;
    case "TRA":
      return `${srvType}___${uid}`;
    case "COA":
      return `${srvType}___${uid}`;
    case "TRF": {
      if (!service?.booking_id) return null;

      return `${srvType}___${service?.booking_id}`;
    }
    case "ACC___ADD":
      return `${srvType}___${uid}`;
    case "ACC___ACC":
      return `${srvType}___${uid}`;
    case "ACC___CU":
      return `${srvType}___${uid}`;
    case "GEN":
      return `${srvType}___${service.service_data.id}`;
    case "COO":
      return `${srvType}___${service?.uid}`;
    case "REST":
      return `${srvType}___${service.external_id}`;
    case "ACT":
      return `${srvType}___${service.uid}`;
    case "AD":
      return `${srvType}___${service.id}`;
    default:
      return null;
  }
}

const styles = createUseStyles({
  ServicePricingForm: {
    "alignSelf": "center",
    "display": "grid",
    "gridGap": `calc(${variables.normal_gap} / 2)`,
    "& input": {
      width: "100%",
    },
  },
});
const ServicePricingForm = ({ service, service_type }) => {
  const classes = styles();
  const { submitForm } = useFormikContext();

  const srvKey = getPricingUIDForSrv({ srvType: service_type, service });

  return !!srvKey ? (
    <div className={`${classes.ServicePricingForm} ServicePricingForm`}>
      <NormalInputField
        type="number"
        label="Markup (%)"
        name={`pricing.${srvKey}.markup`}
      />
      <button
        className="Button ServicePricingForm__applybtn"
        data-success="green"
        onClick={submitForm}>
        Apply
      </button>
    </div>
  ) : (
    <div className={`${classes.ServicePricingForm} ServicePricingForm`} />
  );
};
ServicePricingForm.propTypes = {
  service_type: PropTypes.string.isRequired,
  service: PropTypes.object.isRequired,
};

export default ServicePricingForm;
