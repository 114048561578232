export const QUOTATION_REQUEST_SET_MODE = "QUOTATION_REQUEST_SET_MODE";
export const QUOTATION_REQUEST_INIT = "QUOTATION_REQUEST_INIT";

export const QUOTATION_REQUEST_NEXT_STEP = "QUOTATION_REQUEST_NEXT_STEP";
export const QUOTATION_REQUEST_PREV_STEP = "QUOTATION_REQUEST_PREV_STEP";
export const QUOTATION_REQUEST_BASIC_INFO_CHANGE =
  "QUOTATION_REQUEST_BASIC_INFO_CHANGE";
export const QUOTATION_REQUEST_REQUIRED_SERVICES_CHANGE =
  "QUOTATION_REQUEST_REQUIRED_SERVICES_CHANGE";
export const QUOTATION_REQUEST_ADD_DESTINATION =
  "QUOTATION_REQUEST_ADD_DESTINATION";
export const QUOTATION_REQUEST_DESTINATION_CHANGE =
  "QUOTATION_REQUEST_DESTINATION_CHANGE";
export const QUOTATION_REQUEST_DESTINATION_ORDER_CHANGE =
  "QUOTATION_REQUEST_DESTINATION_ORDER_CHANGE";
export const QUOTATION_REQUEST_DESTINATION_REMOVE =
  "QUOTATION_REQUEST_DESTINATION_REMOVE";
export const QUOTATION_REQUEST_SRV_REQUIREMENTS_CHANGE =
  "QUOTATION_REQUEST_SRV_REQUIREMENTS_CHANGE";
export const QUOTATION_REQUEST_GENERIC_CHANGE =
  "QUOTATION_REQUEST_GENERIC_CHANGE";
export const QUOTATION_REQUEST_LEGS_CHANGE = "QUOTATION_REQUEST_LEGS_CHANGE";
export const QUOTATION_REQUEST_LEG_REMOVE_SERVICE =
  "QUOTATION_REQUEST_LEG_REMOVE_SERVICE";
export const QUOTATION_REQUEST_LEG_ADD_SERVICE =
  "QUOTATION_REQUEST_LEG_ADD_SERVICE";

export const QUOTATION_REQUEST_LOAD = "QUOTATION_REQUEST_LOAD";
export const QUOTATION_REQUEST_METADATA_LOAD =
  "QUOTATION_REQUEST_METADATA_LOAD";
