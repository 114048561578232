import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { createUseStyles } from "react-jss";
import { variables } from "@src/jsssetup";
import { Icon, Toggle } from "rsuite";
const placeholderImg =
  "https://sisistaticassets.s3.ap-southeast-1.amazonaws.com/platform_graphics/imgs/others/destination_placeholder.jpg";

const removeImgBtn = createUseStyles({
  btn: {
    position: "absolute",
    right: variables.normal_gap,
    top: variables.normal_gap,
    color: "white",
    cursor: "pointer",
  },
});

const RemoveImgBtn = (props) => {
  const { onClick } = props;
  const classes = removeImgBtn();
  return (
    <Icon
      className={classes.btn}
      icon="close-circle"
      size="lg"
      onClick={onClick}
    />
  );
};

RemoveImgBtn.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const styles = createUseStyles({
  CustomImage: {
    height: "10rem",
    width: "100%",
    position: "relative",
  },
  img: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
    borderRadius: variables.normal_gap,
  },
  toggleBox: {
    "position": "absolute",
    "bottom": variables.normal_gap,
    "right": variables.normal_gap,
    "display": "grid",
    "border": "2px solid white",
    "borderRadius": "5px",
    "padding": `calc(${variables.normal_gap} / 2)`,
    "background": "rgba(0, 0, 0, 0.4)",
    "& strong": {
      color: "white",
    },
  },
  [`@media ${variables.media.bigscreen}`]: {
    CustomImage: {
      height: "12rem",
    },
  },
});
const CustomImage = (props) => {
  const { image, onRemoveImage, onMainToggle } = props;

  const classes = styles();

  return (
    <div className={classes.CustomImage}>
      <img className={classes.img} src={image.big_thumbnail_url} />
      <div className={classes.toggleBox}>
        {image.main_photo && <strong>Main Image</strong>}
        <Toggle
          checked={image.main_photo}
          onChange={function (value) {
            onMainToggle(value);
          }}
        />
      </div>
      <RemoveImgBtn
        onClick={function () {
          onRemoveImage(image.id);
        }}
      />
    </div>
  );
};
CustomImage.defaultProps = {
  image: {},
  allCustomImages: [],
};
CustomImage.propTypes = {
  image: PropTypes.object.isRequired,
  onMainToggle: PropTypes.func.isRequired,
  onRemoveImage: PropTypes.func.isRequired,
};

export const PlaceHolderImg = () => {
  const classes = styles();
  return (
    <div className={classes.imgContainer}>
      <img className={classes.img} src={placeholderImg} />
    </div>
  );
};

export default CustomImage;
