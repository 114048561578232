import { Image } from "@react-pdf/renderer";

import PropTypes from "prop-types";
import React from "react";

export const PDFImage = ({ style, src }) => {
  return (
    <Image
      src={src + "?noCache=" + Math.random().toString()}
      source={{ header: { "Access-Control-Allow-Origin": "*" } }}
      style={style}
      fixed
    />
  );
};
PDFImage.defaultProps = { style: {} };
PDFImage.propTypes = {
  src: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired,
};
