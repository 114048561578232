import { Notification } from "rsuite";
import React from "react";

import {
  // ========================== STEP CONTROL =========================
  SISIBID_GOTO_NEXT_STEP,
  SISIBID_GOTO_PREV_STEP,
  SISIBID_TRIP_SERVICES,
  SISIBID_NEW_BID_TOGGLE_TARGET_PRICE_MODE,
  SISIBID_BID_MODIFY_LIST_TITLE,
  SISIBID_BID_MODIFY_LIST_STATUS,
  SISIBID_BID_INSERT_LIST,
  SISIBID_CHANGE_CURRENT_BID_UID,
  SISIBID_BID_UPDATE_BIDDING_STATUS,
  SISIBID_BID_DROP_FROM_LIST,
  // ========================= INSTANCE DATA =========================
  SISIBID_FETCHING_TRIPPLAN_DATA,
  SISIBID_FETCHED_TRIPPLAN_DATA,
  SISIBID_NEW_BID_FETCH_TRIP_PLAN_DATA,
  SISIBID_BID_UPDATE_SHOW_PRICE_TYPE,
  SISIBID_BID_UPDATE_NEED_TIME,
  SISIBID_BID_UPDATE_MESSAGE,
  SISIBID_BID_SERVICE_PRICING_CHANGE,
  SISIBID_BID_REVERT_SERVICE_PRICING,
  SISIBID_BID_SET_BIDDING_LIST,
  SISIBID_SERVICE_FILTERS_RESET,
  SISIBID_BID_UPDATE_FLIGHT_STOPS,
  SISIBID_BID_UPDATE_FLIGHT_TYPE,
  SISIBID_BID_UPDATE_FLIGHT_START_AIRPORT,
  SISIBID_BID_UPDATE_FLIGHT_SEGMENT,
  SISIBID_UPDATE_TRANSPORTATION_TYPE,
  SISIBID_BID_REVERT_FLIGHT_SEGMENTS,
  SISIBID_BID_REVERT_FLIGHT,
  FETCHED_BIDDING_FACILITY,
  // ========================= SERVICE DETAILS =========================
  SISIBID_VIEW_FLIGHT_DETAILS,
  SISIBID_HIDE_FLIGHT_DETAILS,
  SISIBID_VIEW_ACC_DETAILS,
  SISIBID_HIDE_ACC_DETAILS,
  SISIBID_VIEW_TRF_DETAILS,
  SISIBID_HIDE_TRF_DETAILS,
  // ========================== SAVE ACTIONS =========================
  SISIBID_REQUESTS_DATA_UPDATE,
  // ======================= WAIT MODAL ACTIONS ======================
  SISIBID_SHOW_WAIT_MODAL,
  SISIBID_HIDE_WAIT_MODAL,
} from "./types";

import {
  serviceFiltersChange,
  serviceFiltersReset,
  changeRequiredServices,
  changeAllFilterdRequiredServices,
  changeReplaceableServices,
  changeAllFilterdReplaceableServices,
  changeRefundableServices,
  changeAllFilterdRefundableServices,
  changeServiceReplaceability,
  addServiceComment,
  removeServiceComment,
} from "./step_one";

import {
  saveBiddingTrip,
  requestBidSave,
  cancelBidSave,
  backgroundProgressSave,
} from "./save_actions";

import { fetchBiddingTripPlan } from "@src/api/Project/Bidding";

import {
  changeBiddersFilters,
  selectBidder,
  selectAllBidders,
  unselectAllBidders,
  // ======================= SEND REQUEST ACTIONS ======================
  sendBiddingRequest,
  requestSendBidRequest,
  cancelSendBidRequest,
  // ===================== STEP TWO INIT ACTIONS =====================
  stepTwoInitActions,
} from "./step_two";

import {
  changeFlowViewType,
  compareOffer,
  exitCompareMode,
  selectOffer,
  deselectOffer,
  // ===================== STEP THREE INIT ACTIONS =====================
  stepThreeInitActions,
} from "./step_three";

import _ from "lodash";
import { timeSkipper } from "@src/actions/tools";
import { timeStampInjector } from "@src/tools/date_tools";
import { fetchFacility as fetchFacilityAPI } from "@src/api/Project/Bidding";

import v4 from "uuid";

const Hashids = require("hashids/cjs");
const hashids = new Hashids(
  v4(),
  undefined,
  "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890"
);

import { notifyCommonError } from "@src/components/common/notifications/CommonErrorNotification.js";

export const toggleTargetPriceMode = (value) => {
  return { type: SISIBID_NEW_BID_TOGGLE_TARGET_PRICE_MODE, value };
};

export const fetchTripData = (tripId) => async (dispatch, getState) => {
  dispatch({ type: SISIBID_FETCHING_TRIPPLAN_DATA });

  try {
    const res = await fetchBiddingTripPlan(tripId);

    await dispatch({
      type: SISIBID_NEW_BID_FETCH_TRIP_PLAN_DATA,
      data: res,
    });

    if (res.bid_requests.length) {
      await dispatch({
        type: SISIBID_REQUESTS_DATA_UPDATE,
        bid_requests: res.bid_requests,
      });

      const uid = (
        res.bid_requests.find((r) => r.setup_status == "DO") ||
        res.bid_requests[0]
      ).uid;
      dispatch(switchBidding(uid));
    } else {
      await dispatch(createBidding());
      dispatch(switchBidding(getState().newBiddingList[0].uid));
    }

    if (res.bidding_current_step > 1) {
      dispatch(nextStep(res.bidding_current_step - 1));
    }

    dispatch({ type: SISIBID_FETCHED_TRIPPLAN_DATA });
  } catch (error) {
    // TODO
  }

  dispatch({ type: SISIBID_TRIP_SERVICES });
};

export const modifyBiddingTitle = (uid, title) => {
  return { type: SISIBID_BID_MODIFY_LIST_TITLE, uid, title };
};

export const modifyNumber = (srvTypes, destOrder, value, uid) => {
  return {
    type: SISIBID_BID_SERVICE_PRICING_CHANGE,
    srvTypes: srvTypes,
    destOrder: destOrder,
    value: value,
    uid,
  };
};

export const modifyTotal = (value, uid) => (dispatch, getState) => {
  const state = getState();
  const trip = state.newBiddingOriginalTripData;
  const srvFilters = state.newBiddingServiceFilters;

  const commonPayload = {
    type: SISIBID_BID_SERVICE_PRICING_CHANGE,
    value,
    uid,
  };
  const extPayload = {
    type: SISIBID_BID_SERVICE_PRICING_CHANGE,
    value,
    uid,
    destOrder: "extremal",
    srvTypes: ["FL"],
  };

  if (!_.flatten(Object.values(srvFilters)).length) {
    trip.destinations.forEach((d) =>
      dispatch({
        ...commonPayload,
        destOrder: d.order,
        srvTypes: ["FL", "ACC", "TF"],
      })
    );
    dispatch(extPayload);
  } else if (srvFilters.extremal.length) {
    dispatch(extPayload);
  } else {
    const destArray = srvFilters.destinations.length
      ? srvFilters.destinations
      : trip.destinations.map((d) => d.order);

    destArray.forEach((d) =>
      dispatch({
        ...commonPayload,
        destOrder: d,
        srvTypes: !srvFilters.service_types.length
          ? ["FL", "ACC", "TF"]
          : srvFilters.service_types.map((s) => (s == "TR" ? "FL" : s)),
      })
    );

    if (
      !srvFilters.destinations.length &&
      srvFilters.service_types.includes("TR")
    ) {
      dispatch(extPayload);
    }
  }
};

export const changeServiceCheckStatus = (index, value) => (
  dispatch,
  getState
) => {
  const currentBiddingUid = getState().newBiddingCurrentBiddingUid;
  const biddingList = getState().newBiddingList;
  const currentBiddingIndex = _.findIndex(biddingList, {
    uid: currentBiddingUid,
  });

  dispatch({
    type: SISIBID_BID_MODIFY_LIST_STATUS,
    data: { index, value, currentBiddingIndex },
  });
};

export const updatePriceType = (uid, priceType) => {
  return { type: SISIBID_BID_UPDATE_SHOW_PRICE_TYPE, priceType, uid };
};

export const updateRequiredTime = (key, value) => (dispatch, getState) => {
  const currentBiddingUid = getState().newBiddingCurrentBiddingUid;
  const biddingList = getState().newBiddingList;
  const currentBiddingIndex = _.findIndex(biddingList, {
    uid: currentBiddingUid,
  });
  const currentBidding = biddingList[currentBiddingIndex];
  const required_time = _.cloneDeep(currentBidding.required_time);
  switch (key) {
    case "hours":
      required_time.hours = value;
      break;
    case "minutes":
      required_time.minutes = value;
      break;

    default:
      break;
  }
  dispatch({
    type: SISIBID_BID_UPDATE_NEED_TIME,
    data: { index: currentBiddingIndex, value: required_time },
  });
};

export const updateMessage = (value) => (dispatch, getState) => {
  const currentBiddingUid = getState().newBiddingCurrentBiddingUid;
  const biddingList = getState().newBiddingList;
  const currentBiddingIndex = _.findIndex(biddingList, {
    uid: currentBiddingUid,
  });

  dispatch({
    type: SISIBID_BID_UPDATE_MESSAGE,
    data: { index: currentBiddingIndex, value },
  });
};

export const changeBiddingStatus = (uid, status) => (dispatch, getState) => {
  const bidding = getState().newBiddingList.find((b) => b.uid == uid);

  // Validation
  if (status == "DO") {
    if (bidding.title == "") {
      Notification.warning({
        title: `${bidding.uid}: Validation Warning`,
        description: (
          <p>Please set a Title before marking a bidding request as "Done"</p>
        ),
        duration: 6000,
      });
      return;
    } else if (!_.flatten(Object.values(bidding.required_services)).length) {
      Notification.warning({
        title: `${bidding.uid}: Validation Warning`,
        description: (
          <p>
            Please select some services before marking a bidding request as
            "Done"
          </p>
        ),
        duration: 6000,
      });
      return;
    }
  }

  dispatch({ type: SISIBID_SERVICE_FILTERS_RESET });
  dispatch({ type: SISIBID_BID_UPDATE_BIDDING_STATUS, uid, status });
};

export const switchBidding = (uid) => (dispatch, getState) => {
  !uid && (uid = _.get(getState(), "newBiddingList.0.uid"));

  dispatch({ type: SISIBID_SERVICE_FILTERS_RESET });
  dispatch({ type: SISIBID_CHANGE_CURRENT_BID_UID, uid });
};

export const createBidding = () => (dispatch, getState) => {
  const uid = hashids.encode(new Date().getTime());

  const trip = getState().newBiddingOriginalTripData;

  const flights = Object.entries(
    _.get(trip, "transportation_data.flights", {})
  ).filter((e) => e[1].some((f) => f.selected));

  const accs = Object.entries(
    _.get(trip, "accommodation_data", {})
  ).filter((e) => e[1].some((a) => a.selected));
  const transfers = Object.entries(
    _.get(trip, "transfer_data", {})
  ).filter((e) => _.flatten(Object.values(e[1])).some((v) => v.selected));
  const required_services = { FL: [], ACC: [], TF: [] };

  const FLRp = {};
  flights.forEach(
    (e) =>
      (FLRp[e[0] == "extremalLeg" ? "extremal" : parseInt(e[0], 10)] =
        "similar")
  );
  const ACCRp = {};
  accs.forEach((e) => (ACCRp[e[0]] = "similar"));
  const TFRp = {};
  transfers.forEach((e) => (TFRp[e[0]] = "similar"));

  const replaceableTypes = { FL: FLRp, ACC: ACCRp, TF: TFRp };

  dispatch({
    type: SISIBID_BID_INSERT_LIST,
    uid,
    required_services,
    replaceableTypes,
  });

  if (!getState().newBiddingCurrentBiddingUid) {
    dispatch(switchBidding(_.get(getState(), "newBiddingList.0.uid")));
  }
};

export const deleteBidding = (uid) => (dispatch, getState) => {
  const biddingList = getState().newBiddingList;
  if (biddingList.length < 2) {
    notifyCommonError("At least have one biding request.");
    return;
  }
  const index = _.findIndex(biddingList, { uid: uid });
  dispatch({ type: SISIBID_BID_DROP_FROM_LIST, data: { index: index } });

  dispatch({
    type: SISIBID_CHANGE_CURRENT_BID_UID,
    data: getState().newBiddingList[0].uid,
  });
};

export const nextStep = (currentStep) => async (dispatch, getState) => {
  const biddingList = getState().newBiddingList;

  //Validation
  if (_.isEmpty(biddingList)) {
    notifyCommonError(
      "Please create at least one bidding request before moving to the next step"
    );
    return;
  } else if (biddingList.every((b) => b.setup_status !== "DO")) {
    notifyCommonError(
      `Change the "Bidding Setup" of at least one bidding request as "Done" before moving to the next step.`
    );
    return;
  }

  if (currentStep == 1) {
    dispatch(stepTwoInitActions());
  } else if (currentStep == 2) {
    await dispatch({ type: SISIBID_SHOW_WAIT_MODAL });
    await dispatch(stepThreeInitActions());
    await dispatch({ type: SISIBID_HIDE_WAIT_MODAL });
  } else if (currentStep == 4) {
    return;
  }

  await dispatch({ type: SISIBID_GOTO_NEXT_STEP, currentStep: currentStep });
  if (
    currentStep == 2 &&
    getState().newBiddingOriginalTripData.bidding_current_step < 3
  ) {
    await dispatch(backgroundProgressSave());
  }
};

export const prevStep = (currentStep) => (dispatch, getState) => {
  if (currentStep == 1) {
    return;
  }

  if (currentStep == 2) {
    const newBiddingList = getState().newBiddingList;
    newBiddingList.forEach((bidding) => {
      bidding.status = "PE";
    });

    dispatch({ type: SISIBID_BID_SET_BIDDING_LIST, data: newBiddingList });
  } else if (currentStep == 3) {
    dispatch(stepTwoInitActions());
  }

  dispatch({ type: SISIBID_GOTO_PREV_STEP, currentStep: currentStep });
};

export const initializeBiddingPricing = (uid) => (dispatch) => {
  dispatch(modifyTotal(0, uid));
  dispatch({ type: SISIBID_BID_REVERT_SERVICE_PRICING });
};

export const viewFlightDetails = (flOrder) => {
  return { type: SISIBID_VIEW_FLIGHT_DETAILS, flOrder };
};

export const hideFlightDetails = () => {
  return { type: SISIBID_HIDE_FLIGHT_DETAILS };
};

export const viewAccDetails = (destOrder) => {
  return { type: SISIBID_VIEW_ACC_DETAILS, destOrder };
};

export const hideAccDetails = () => {
  return { type: SISIBID_HIDE_ACC_DETAILS };
};

export const viewTrfDetails = (destOrder, transfer_type) => {
  return { type: SISIBID_VIEW_TRF_DETAILS, destOrder, transfer_type };
};

export const hideTrfDetails = () => {
  return { type: SISIBID_HIDE_TRF_DETAILS };
};

export const updateTransportationType = (value) => {
  return {
    type: SISIBID_UPDATE_TRANSPORTATION_TYPE,
    SISIBID_BID_REVERT_FLIGHT_SEGMENTS,
    data: value,
  };
};

export const revertSegments = () => {
  return { type: SISIBID_BID_REVERT_FLIGHT_SEGMENTS };
};

export const revertFlight = () => {
  return { type: SISIBID_BID_REVERT_FLIGHT };
};

export const updateFlightStops = (value, bound) => (dispatch) => {
  const arr = [];
  arr.length = parseInt(value) + 1;
  arr.fill(1);
  const res = arr.map(() => {
    return {
      baggage_allowance: { verbose: "" },
      aircraft: { label: "" },
      operating_airline: { label: "" },
      origin_airport: { label: "" },
      destination_airport: { label: "" },
      key: Math.random().toString().split(".")[1],
    };
  });

  dispatch({
    type: SISIBID_BID_UPDATE_FLIGHT_STOPS,
    data: { arr: [...res], bound },
  });
};

export const updateTripType = (value) => {
  return { type: SISIBID_BID_UPDATE_FLIGHT_TYPE, data: value };
};

export const updateStartAirport = (value) => {
  return { type: SISIBID_BID_UPDATE_FLIGHT_START_AIRPORT, data: value };
};

export const updateSegment = (obj, index, bound, field) => (dispatch) => {
  dispatch({
    type: SISIBID_BID_UPDATE_FLIGHT_SEGMENT,
    data: { obj, index, bound, field },
  });
};

export const fetchFacility = () => (dispatch, getState) => {
  const newBiddingFacility = getState().newBiddingFacility;
  const call = timeSkipper(
    () => {
      fetchFacilityAPI()
        .then((res) => {
          dispatch({
            type: FETCHED_BIDDING_FACILITY,
            data: {
              ...{ data: res },
              ...{ metadata: timeStampInjector({}) },
            },
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    newBiddingFacility,
    3600
  );
  call();
};

export {
  serviceFiltersChange,
  serviceFiltersReset,
  changeRequiredServices,
  changeAllFilterdRequiredServices,
  changeReplaceableServices,
  changeAllFilterdReplaceableServices,
  changeRefundableServices,
  changeAllFilterdRefundableServices,
  changeServiceReplaceability,
  addServiceComment,
  removeServiceComment,
  // ============================ STEP TWO ===========================
  changeBiddersFilters,
  selectBidder,
  selectAllBidders,
  unselectAllBidders,
  // ========================== SAVE ACTIONS =========================
  saveBiddingTrip,
  requestBidSave,
  cancelBidSave,
  // ======================= SEND REQUEST ACTIONS ======================
  sendBiddingRequest,
  requestSendBidRequest,
  cancelSendBidRequest,
  // =========================== STEP THREE ==========================
  changeFlowViewType,
  compareOffer,
  selectOffer,
  deselectOffer,
  exitCompareMode,
};
