import { toast } from "react-toastify";
import { postReservationFeedback } from "@src/api/Operation/Reservations/feedback";
import React from "react";
import TransTxt from "@src/components/common/SxFormatMessage";
import _ from "lodash";

export async function retrieverFn({
  reference,
  fn,
  history,
  setLoading,
  setReservation,
}) {
  setLoading(true);
  try {
    const data = await fn({
      reference,
    });

    if (_.isEmpty(data)) {
      history.push("/404/");
    }
    setReservation(data);
  } catch (error) {
    toast.error(
      <p>
        <TransTxt id="acc_detail__unable_to_fetch" />
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    setLoading(false);
    history.push("/404/");
  }

  setLoading(false);
}

export function payloader({
  supplierInfo,
  feedRemarks,
  cxl_rules,
  reference,
  feedback_service_data = {},
  reservation_type,
}) {
  const { supplier_name, supplier_email, supplier_phone, supplier_remarks } =
    supplierInfo;

  const {
    reservation_remarks,
    booking_mode_remarks,
    payment_mode_remarks,
    cxl_remarks,
  } = feedRemarks;

  return {
    supplier_name,
    supplier_email,
    supplier_phone,
    supplier_remarks,
    reservation_remarks: reservation_remarks.remarks,
    booking_mode_remarks: booking_mode_remarks.remarks,
    payment_mode_remarks: payment_mode_remarks.remarks,
    request_cxl_policy_remarks: cxl_remarks.remarks,
    cxl_policies: { cxl_policies: cxl_rules },
    service_data: feedback_service_data,
    source: "SU",
    reservation_reference: reference,
    reservation_type,
    response_status: "CO",
  };
}

export async function onSubmit({ history, payload, setLoading }) {
  setLoading(true);
  const result = await postReservationFeedback({ ...payload });
  if (result) {
    toast.success(
      "Successfully registered information. Thank you for your feedback",
      { duration: 10000 }
    );
    history.push("/public/reservation_feedback/success-reservation");
  }

  setLoading(false);
}

export async function onReject({
  history,
  reference,
  payload,
  setLoading,
  resPatchFunc,
}) {
  setLoading(true);
  const result = await resPatchFunc({
    reference: reference,
    payload,
  });
  if (result) {
    toast.success(
      "Successfully registered information. Thank you for your feedback",
      { duration: 10000 }
    );
    history.push("/public/reservation_feedback/success-reservation");
  }
  setLoading(false);
}
