import _ from "lodash";
import React from "react";
import axios from "axios";
import { getAuthHeaders } from "../../../request";
import { SISI_API_URL } from "@src/api";
import { toast } from "react-toastify";

export async function fetchAPIFerriesReser(params) {
  const auth_headers = getAuthHeaders();
  return await axios.get(`${SISI_API_URL}/reservations/api-ferries`, {
    params,
    headers: { ...auth_headers },
  });
}

export async function retrieveAPIFerryReservation({ reference }) {
  const auth_headers = getAuthHeaders();
  return await axios.get(
    `${SISI_API_URL}/reservations/api-ferries/${reference}/`,
    {
      params: {},
      headers: { ...auth_headers },
    }
  );
}

export async function updateAPIFerryReservation({ reference, payload }) {
  const auth_headers = getAuthHeaders();
  return await axios.patch(
    `${SISI_API_URL}/reservations/api-ferries/${reference}/`,
    payload,
    {
      params: {},
      headers: { ...auth_headers },
    }
  );
}

export async function retrievePublicAPIFerryReservation({
  reference,
  onError = (error) =>
    toast.error(
      <p>
        We failed to retrieve the API Ferry Reservation. We are sorry for the
        inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    ),
}) {
  const auth_headers = getAuthHeaders();
  try {
    const result = await axios.get(
      `${SISI_API_URL}/reservations/api-ferries/public/${reference}/`,
      {
        params: {},
        headers: { ...auth_headers },
      }
    );
    return result.data;
  } catch (error) {
    if (typeof onError === "function") {
      onError(error);
    }

    return null;
  }
}

export async function patchPublicAPIFerryReservation({
  reference,
  payload,
  onError = (error) =>
    toast.error(
      <p>
        We failed to update the API Ferry Reservation. We are sorry for the
        inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    ),
}) {
  try {
    const result = await axios.patch(
      `${SISI_API_URL}/reservations/api-ferries/public/update/${reference}/`,
      payload
    );
    return result.data;
  } catch (error) {
    if (typeof onError === "function") {
      onError(error);
    }
    return null;
  }
}

export async function getAPIFerryStats(params) {
  const auth_headers = getAuthHeaders();
  return await axios.get(`${SISI_API_URL}/reservations/api-ferries/stats/`, {
    params,
    headers: { ...auth_headers },
  });
}
