// =========================== INIT ACTIONS ==========================
export const CUSTOM_HOTEL_INIT = "CUSTOM_HOTEL_INIT";
export const CUSTOM_HOTEL_INIT_FROM_DATA = "CUSTOM_HOTEL_INIT_FROM_DATA";

export const CUSTOM_HOTEL_NEW_HOTEL = "CUSTOM_HOTEL_NEW_HOTEL";
export const CUSTOM_HOTEL_FULL_META_CHANGE = "CUSTOM_HOTEL_FULL_META_CHANGE";
export const CUSTOM_HOTEL_META_CHANGE = "CUSTOM_HOTEL_META_CHANGE";
export const CUSTOM_HOTEL_IMAGE_CHANGE = "CUSTOM_HOTEL_IMAGE_CHANGE";
export const CUSTOM_HOTEL_ADD_ROOM = "CUSTOM_HOTEL_ADD_ROOM";
export const CUSTOM_HOTEL_REMOVE_ROOM = "CUSTOM_HOTEL_REMOVE_ROOM";
export const CUSTOM_HOTEL_CLONE_ROOM = "CUSTOM_HOTEL_CLONE_ROOM";
export const CUSTOM_HOTEL_ROOM_CHANGE = "CUSTOM_HOTEL_ROOM_CHANGE";
