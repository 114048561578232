import _ from "lodash";
import { createSelector } from "reselect";
import {
  getCoachServicesSelector,
  getFerryServicesSelector,
  getTrainServicesSelector,
} from "./addhoc/transportation";
import { getSelectedAddonsSelector } from "./addon_selectors";
import { getDbDAdhocServicesSelector } from "./day_by_day/addhoc_services";
import {
  getCoordinatorServicesSelector,
  getGeneralServicesSelector,
  getRestaurantServicesSelector,
} from "./day_by_day/custom_services";
import {
  getAPIFerryServices,
  getAPITrainServices,
  getAccServicesSelector,
  getFlightServices,
  getTransferServices,
} from "./day_by_day_selectors";
import { getTripActivitiesListSelector } from "./day_by_day/activities";
import { getTripDestinations } from "./trip_itinerary_selectors";
import { DateTime } from "luxon";

export const getServicesListSelector = createSelector(
  [
    getTripDestinations,
    getFlightServices,
    getAPITrainServices,
    getAPIFerryServices,
    getAccServicesSelector,
    getTransferServices,
    getRestaurantServicesSelector,
    getCoordinatorServicesSelector,
    getGeneralServicesSelector,
    getCoachServicesSelector,
    getFerryServicesSelector,
    getTrainServicesSelector,
    getDbDAdhocServicesSelector,
    getTripActivitiesListSelector,
    getSelectedAddonsSelector,
  ],
  (
    destinations,
    flights,
    apiTrains,
    apiFerries,
    accommodations,
    transfers,
    restaurants,
    coordinators,
    general_services,
    coaches,
    ferries,
    trains,
    addhocs,
    activities,
    addons
  ) => {
    const earliestCheckin = DateTime.fromISO(destinations[0].checkIn);
    const latestCheckout = DateTime.fromISO(_.last(destinations).checkOut);

    const newAddons = _.flatten(Object.values(addons)).map((srv) => {
      srv.service_type = "MI";
      return srv;
    });

    const adds = addhocs.map((srv) => {
      srv.service_type = "AD";
      return srv;
    });

    var accs = _.flatten(Object.values(accommodations)).map((srv) => {
      srv.service_type = "ACC";
      return srv;
    });

    accs = accs.filter((a) => {
      var cIn, cOut;
      if (a.accommodation_service_type !== "ACC") {
        cIn = DateTime.fromISO(a.check_in);
        cOut = DateTime.fromISO(a.check_out);
      } else {
        cIn = DateTime.fromISO(a.operational_data.check_in);
        cOut = DateTime.fromISO(a.operational_data.check_out);
      }

      return (
        cIn.startOf("day") >= earliestCheckin.startOf("day") &&
        cOut.startOf("day") <= latestCheckout.startOf("day")
      );
    });

    const coas = coaches.map((srv) => {
      srv.service_type = "TR";
      srv.transportation_service_type = "COA";
      srv.service.transportation_service_type = "COA";
      return srv;
    });
    const ferrs = ferries.map((srv) => {
      srv.service_type = "TR";
      srv.transportation_service_type = "FER";
      srv.service.transportation_service_type = "FER";
      return srv;
    });

    const tras = trains.map((srv) => {
      srv.service_type = "TR";
      srv.transportation_service_type = "TRA";
      srv.service.transportation_service_type = "TRA";
      return srv;
    });

    const trfs = _.flattenDeep(
      _.flatten(Object.values(transfers)).map((leg) => Object.values(leg))
    )
      .filter((srv) => srv.selected)
      .map((srv) => {
        srv.service_type = "TRF";
        return srv;
      });

    var rests = restaurants.map((rest) => {
      rest.service_type = "REST";
      return rest;
    });

    var coords = coordinators.map((coo) => {
      coo.service_type = "COO";
      return coo;
    });

    var gens = general_services.map((rest) => {
      rest.service_type = "GEN";
      return rest;
    });

    var acts = activities.map((srv) => {
      srv.service_type = "ACT";
      return srv;
    });

    return [
      ...flights,
      ...apiFerries,
      ...apiTrains,
      ...coas,
      ...ferrs,
      ...tras,
      ...accs,
      ...trfs,
      ...rests,
      ...coords,
      ...gens,
      ...adds,
      ...acts,
      ...newAddons,
    ];
  }
);
