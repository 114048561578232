import {
  ACCOUNT_FETCHED_DETAILS,
  // ========================== Account Profile =========================
  ACCOUNT_PROFILE_LOADED,
  ACCOUNT_PROFILE_LOADING,
  ACCOUNT_BEGIN_CHANGE_STATUS,
  ACCOUNT_END_CHANGE_STATUS,
  ACCOUNT_UPDATE_DETAILS,
  ACCOUNT_BEGIN_CHANGE_PASSWORD,
  ACCOUNT_END_CHANGE_PASSWORD,
  // ============== Account Profile: Account Base Data Section =============
  ACCOUNT_VIEW_BASE_DATA_MODE,
  ACCOUNT_EDIT_BASE_DATA_MODE,
  ACCOUNT_UPLOADING_BASE_DATA_MODE,
  ACCOUNT_VALIDATE_BASE_DATA_FORM,
  ACCOUNT_INITIALIZE_BASE_DATA_FORM,
  ACCOUNT_EDIT_BASE_DATA_FORM,
  // ================ Account Profile: Expertise Section ================
  ACCOUNT_VIEW_EXPERTISE_MODE,
  ACCOUNT_EDIT_EXPERTISE_MODE,
  ACCOUNT_UPLOADING_EXPERTISE_MODE,
  ACCOUNT_EDIT_EXPERTISE_FORM,
  ACCOUNT_INITIALIZE_EXPERTISE_FORM,
  // ================ Account Profile: Travel Sector Section ===============
  ACCOUNT_VIEW_TRAVEL_SECTOR_MODE,
  ACCOUNT_EDIT_TRAVEL_SECTOR_MODE,
  ACCOUNT_UPLOADING_TRAVEL_SECTOR_MODE,
  ACCOUNT_EDIT_TRAVEL_SECTOR_FORM,
  ACCOUNT_INITIALIZE_TRAVEL_SECTOR_FORM,
  // =============== Account Profile: Preferences Section ===============
  ACCOUNT_VIEW_PREFERENCES_MODE,
  ACCOUNT_EDIT_PREFERENCES_MODE,
  ACCOUNT_UPLOADING_PREFERENCES_MODE,
  ACCOUNT_INITIALIZE_LANGUAGE_FORM,
  ACCOUNT_EDIT_LANGUAGE_FORM,
  ACCOUNT_UPLOADED_PREFERENCE_IMAGE,
  ACCOUNT_BEGIN_UPLOAD_IMG_LINK,
  ACCOUNT_ENG_UPLOAD_IMG_LINK,
  ACCOUNT_INITIALIZE_IMAGE_FORM,
  // ============= Account Profile: Job Description Section =============
  ACCOUNT_VIEW_JOB_DESCRIPTION_MODE,
  ACCOUNT_EDIT_JOB_DESCRIPTION_MODE,
  ACCOUNT_UPLOADING_JOB_DESCRIPTION_MODE,
  ACCOUNT_INITIALIZE_DEPARTMENT_FORM,
  ACCOUNT_FORMAT_JOB_DESCRIPTION_FORM,
  ACCOUNT_INITIALIZE_JOB_DESCRIPTION_FORM,
  ACCOUNT_EDIT_JOB_DESCRIPTION_FORM,
  ACCOUNT_PROFILE_UPDATE_JOB_DESCRIPTION,
  ACCOUNT_EDIT_DEPARTMENT_FORM,
  // ======= Account Profile: User Work Duties Section Actions ======
  ACCOUNT_VIEW_WORK_DUTIES_MODE,
  ACCOUNT_EDIT_WORK_DUTIES_MODE,
  ACCOUNT_UPLOADING_WORK_DUTIES_MODE,
  // ============ Account Profile: Service Specialty Section ============
  ACCOUNT_LOADING_SERVICE_SPECIALTY_MODE,
  ACCOUNT_SELECT_SERVICE_CATEGORY,
  ACCOUNT_INITIALIZE_SERVICE_SPECIALTY_FORM,
  ACCOUNT_INITIALIZE_TRAVEL_SERVICE_FORM,
  ACCOUNT_SELECT_TRAVEL_SERVICE,
  ACCOUNT_LOADING_SERVICE_CATEGORY_MODE,
  ACCOUNT_VIEW_SERVICE_CATEGORIES_MODE,
  ACCOUNT_EDIT_SERVICE_CATEGORIES_MODE,
  //=========== Account profile: User Permission Section Action===========
  ACCOUNT_EDIT_PERMISSION_MODE,
  ACCOUNT_VIEW_PERMISSION_MODE,
  ACCOUNT_LOADING_PERMISSION_MODE,
  ACCOUNT_EDIT_PERMISSION_FORM,
  ACCOUNT_INITIALIZE_PERMISSION_FORM,
  ACCOUNT_LOADED_PERMISSION_MODE,
  //=========== Account profile: User Email Config Section Action===========
  ACCOUNT_EDIT_EMAIL_CONFIG_MODE,
  ACCOUNT_VIEW_EMAIL_CONFIG_MODE,
  ACCOUNT_LOADING_EMAIL_CONFIG_MODE,
  ACCOUNT_EDIT_EMAIL_CONFIG_FORM,
  ACCOUNT_INITIALIZE_EMAIL_CONFIG_FORM,
  ACCOUNT_LOADED_EMAIL_CONFIG_MODE,
} from "@src/actions/Account/types";

import { initialUserForm } from "@src/forms/Admin/Company/userForms";

import {
  formProcessor,
  travelServicesFormProcessor,
} from "@src/reducers/tools";

import update from "immutability-helper";
import _ from "lodash";

export const accountProfile = (state = {}, action) => {
  switch (action.type) {
    case ACCOUNT_FETCHED_DETAILS:
      return action.data;
    case ACCOUNT_UPDATE_DETAILS:
      return update(state, { $merge: action.data });
    default:
      return state;
  }
};

export const initialChangeAccountPasswordStatus = "LOADED";
export const changeAccountPasswordStatus = (
  state = initialChangeAccountPasswordStatus,
  action
) => {
  switch (action.type) {
    case ACCOUNT_END_CHANGE_PASSWORD:
      return "LOADED";
    case ACCOUNT_BEGIN_CHANGE_PASSWORD:
      return "LOADING";
    default:
      return state;
  }
};

export const initialChangeAccountStatusStatus = "LOADED";
export const changeAccountStatusStatus = (
  state = initialChangeAccountStatusStatus,
  action
) => {
  switch (action.type) {
    case ACCOUNT_END_CHANGE_STATUS:
      return "LOADED";
    case ACCOUNT_BEGIN_CHANGE_STATUS:
      return "LOADING";
    default:
      return state;
  }
};

// ======================= Account Profile Section ======================
export const initialAccountProfileStatus = "LOADING";
export const accountProfileStatus = (
  state = initialAccountProfileStatus,
  action
) => {
  switch (action.type) {
    case ACCOUNT_PROFILE_LOADING:
      return "LOADING";
    case ACCOUNT_PROFILE_LOADED:
      return "LOADED";
    default:
      return state;
  }
};

export const initialAccountBaseDataMode = "VIEW";
export const accountBaseDataMode = (
  state = initialAccountBaseDataMode,
  action
) => {
  switch (action.type) {
    case ACCOUNT_VIEW_BASE_DATA_MODE:
      return "VIEW";
    case ACCOUNT_EDIT_BASE_DATA_MODE:
      return "EDIT";
    case ACCOUNT_UPLOADING_BASE_DATA_MODE:
      return "LOADING";
    default:
      return state;
  }
};

export const initialAccountExpertiseMode = "VIEW";
export const accountExpertiseMode = (
  state = initialAccountExpertiseMode,
  action
) => {
  switch (action.type) {
    case ACCOUNT_VIEW_EXPERTISE_MODE:
      return "VIEW";
    case ACCOUNT_EDIT_EXPERTISE_MODE:
      return "EDIT";
    case ACCOUNT_UPLOADING_EXPERTISE_MODE:
      return "LOADING";
    default:
      return state;
  }
};

export const initialAccountTravelSectorMode = "VIEW";
export const accountTravelSectorMode = (
  state = initialAccountTravelSectorMode,
  action
) => {
  switch (action.type) {
    case ACCOUNT_VIEW_TRAVEL_SECTOR_MODE:
      return "VIEW";
    case ACCOUNT_EDIT_TRAVEL_SECTOR_MODE:
      return "EDIT";
    case ACCOUNT_UPLOADING_TRAVEL_SECTOR_MODE:
      return "LOADING";
    default:
      return state;
  }
};

export const initialAccountPreferencesMode = "VIEW";
export const accountPreferencesMode = (
  state = initialAccountPreferencesMode,
  action
) => {
  switch (action.type) {
    case ACCOUNT_VIEW_PREFERENCES_MODE:
      return "VIEW";
    case ACCOUNT_EDIT_PREFERENCES_MODE:
      return "EDIT";
    case ACCOUNT_UPLOADING_PREFERENCES_MODE:
      return "LOADING";
    default:
      return state;
  }
};

export const initialAccountJobDescriptionMode = "VIEW";
export const accountJobDescriptionMode = (
  state = initialAccountJobDescriptionMode,
  action
) => {
  switch (action.type) {
    case ACCOUNT_VIEW_JOB_DESCRIPTION_MODE:
      return "VIEW";
    case ACCOUNT_EDIT_JOB_DESCRIPTION_MODE:
      return "EDIT";
    case ACCOUNT_UPLOADING_JOB_DESCRIPTION_MODE:
      return "LOADING";
    default:
      return state;
  }
};

// ============= Account Profile: Service Specialty Section =============
export const initialAccountProfileServiceSpecialtyMode = "VIEW";
export const accountProfileServiceSpecialtyMode = (
  state = initialAccountProfileServiceSpecialtyMode,
  action
) => {
  switch (action.type) {
    case ACCOUNT_LOADING_SERVICE_SPECIALTY_MODE:
      return "LOADING";
    default:
      return state;
  }
};

export const initialAccountServiceCategoriesMode = "VIEW";
export const accountServiceCategoriesMode = (
  state = initialAccountServiceCategoriesMode,
  action
) => {
  switch (action.type) {
    case ACCOUNT_VIEW_SERVICE_CATEGORIES_MODE:
      return "VIEW";
    case ACCOUNT_EDIT_SERVICE_CATEGORIES_MODE:
      return "EDIT";
    case ACCOUNT_LOADING_SERVICE_CATEGORY_MODE:
      return "LOADING";
    default:
      return state;
  }
};

export const initialAccountPermissionEditMode = "VIEW";
export const accountPermissionEditMode = (
  state = initialAccountPermissionEditMode,
  action
) => {
  switch (action.type) {
    case ACCOUNT_VIEW_PERMISSION_MODE:
      return "VIEW";
    case ACCOUNT_EDIT_PERMISSION_MODE:
      return "EDIT";
    case ACCOUNT_LOADING_PERMISSION_MODE:
      return "LOADING";
    case ACCOUNT_LOADED_PERMISSION_MODE:
      return "LOADED";
    default:
      return state;
  }
};

export const accountSelectedServiceCategory = (state = "", action) => {
  switch (action.type) {
    case ACCOUNT_SELECT_SERVICE_CATEGORY:
      return action.data;
    default:
      return state;
  }
};

export const initialAccountWorkDutiesMode = "VIEW";
export const accountWorkDutiesMode = (
  state = initialAccountWorkDutiesMode,
  action
) => {
  switch (action.type) {
    case ACCOUNT_VIEW_WORK_DUTIES_MODE:
      return "VIEW";
    case ACCOUNT_EDIT_WORK_DUTIES_MODE:
      return "EDIT";
    case ACCOUNT_UPLOADING_WORK_DUTIES_MODE:
      return "LOADING";
    default:
      return state;
  }
};

// ===================== Account Profile: Account Form ====================

export const accountForm = (state = initialUserForm, action) => {
  switch (action.type) {
    case ACCOUNT_INITIALIZE_TRAVEL_SERVICE_FORM:
      return update(state, { travel_services: { $set: action.data } });
    case ACCOUNT_SELECT_TRAVEL_SERVICE:
      return travelServicesFormProcessor(
        state,
        action.data.type,
        "travel_services",
        action.data.value,
        action.data.name,
        action.data.travelServices
      );
    case ACCOUNT_VALIDATE_BASE_DATA_FORM: {
      let newState = state;
      action.data.errorMsgs.forEach(
        (e) =>
          (newState = update(newState, {
            [e.field]: { errorMsg: { $set: e.msg } },
          }))
      );

      return newState;
    }
    case ACCOUNT_INITIALIZE_BASE_DATA_FORM: {
      let newState = state;
      Object.entries(action.data.value).forEach(
        (e) =>
          (newState = update(newState, { [e[0]]: { value: { $set: e[1] } } }))
      );

      return newState;
    }
    case ACCOUNT_INITIALIZE_DEPARTMENT_FORM:
      return update(state, {
        department: { value: { $set: action.data.value } },
      });
    case ACCOUNT_FORMAT_JOB_DESCRIPTION_FORM: {
      const tempValue = state.job_description.tempValue;
      const value = state.job_description.value;
      const arr = tempValue.map((id) => {
        if (_.find(value, { id: id })) {
          return _.find(value, { id: id });
        } else {
          return {
            brief_description_en: id,
          };
        }
      });

      return update(state, {
        job_description: { value: { $set: arr } },
      });
    }
    case ACCOUNT_PROFILE_UPDATE_JOB_DESCRIPTION: {
      return update(state, {
        job_description: { value: { $set: action.data } },
      });
    }
    case ACCOUNT_EDIT_JOB_DESCRIPTION_FORM: {
      return update(state, {
        job_description: { tempValue: { $set: action.data } },
      });
    }
    case ACCOUNT_INITIALIZE_JOB_DESCRIPTION_FORM:
      return update(state, {
        job_description: {
          value: { $set: action.data.value },
          tempValue: {
            $set: action.data.value.map((item) => {
              return item.id;
            }),
          },
        },
      });
    case ACCOUNT_INITIALIZE_LANGUAGE_FORM:
      return update(state, {
        preferred_language: { value: { $set: action.data.value } },
      });
    case ACCOUNT_INITIALIZE_IMAGE_FORM:
      return update(state, {
        original_image: { value: { $set: action.data.value } },
      });
    case ACCOUNT_INITIALIZE_TRAVEL_SECTOR_FORM:
      return update(state, {
        travel_sector: { value: { $set: action.data.value } },
      });
    case ACCOUNT_INITIALIZE_EXPERTISE_FORM:
      return update(state, {
        expertise_type: { value: { $set: action.data.value } },
      });
    case ACCOUNT_INITIALIZE_SERVICE_SPECIALTY_FORM:
      return update(state, {
        travel_services: { value: { $set: action.data.value } },
      });
    case ACCOUNT_INITIALIZE_PERMISSION_FORM:
      return update(state, {
        permission: {
          value: {
            $set: action.data,
          },
        },
      });
    case ACCOUNT_INITIALIZE_EMAIL_CONFIG_FORM:
      return update(state, {
        email_config: {
          value: {
            $set: action.data,
          },
        },
      });
    case ACCOUNT_EDIT_BASE_DATA_FORM:
      return update(state, {
        [action.data.name]: {
          value: { $set: action.data.value },
          errorMsg: { $set: "" },
        },
      });
    case ACCOUNT_EDIT_EXPERTISE_FORM:
      return formProcessor(
        state,
        action.data.type,
        action.data.name,
        action.data.value
      );
    case ACCOUNT_EDIT_TRAVEL_SECTOR_FORM:
      return formProcessor(
        state,
        action.data.type,
        action.data.name,
        action.data.value
      );
    case ACCOUNT_EDIT_LANGUAGE_FORM:
      return update(state, {
        preferred_language: { value: { $set: action.data.value } },
      });
    case ACCOUNT_EDIT_DEPARTMENT_FORM:
      return update(state, {
        department: { value: { $set: action.data.value } },
      });
    case ACCOUNT_UPLOADED_PREFERENCE_IMAGE:
      return update(state, {
        original_image: {
          value: { $set: action.data.value },
          fileName: { $set: action.data.fileName },
        },
      });
    case ACCOUNT_EDIT_PERMISSION_FORM: {
      const { data, form } = action.data;
      if (data.value) {
        return update(state, {
          permission: {
            value: { $push: [form] },
          },
        });
      } else {
        const tmp = _.cloneDeep(state);
        const permission = tmp.permission.value;
        const res = _.remove(permission, (e) => {
          return e.id !== form.id;
        });
        return update(state, {
          permission: {
            value: { $set: res },
          },
        });
      }
    }
    case ACCOUNT_EDIT_EMAIL_CONFIG_FORM: {
      const { data, form } = action.data;
      if (data.value) {
        return update(state, {
          email_config: {
            value: { $push: [form] },
          },
        });
      } else {
        const tmp = _.cloneDeep(state);
        const email_config = tmp.email_config.value;
        const res = _.remove(email_config, (e) => {
          return e.id !== form.id;
        });
        return update(state, {
          email_config: {
            value: { $set: res },
          },
        });
      }
    }
    default:
      return state;
  }
};

export const initialUploadAccountImgLinkStatus = "IDLE";
export const uploadAccountImgLinkStatus = (
  state = initialUploadAccountImgLinkStatus,
  action
) => {
  switch (action.type) {
    case ACCOUNT_BEGIN_UPLOAD_IMG_LINK:
      return "FETCHING";
    case ACCOUNT_ENG_UPLOAD_IMG_LINK:
      return "FETCHED";
    default:
      return state;
  }
};

export const initialAccountEmailConfigEditMode = "VIEW";
export const accountEmailConfigEditMode = (
  state = initialAccountEmailConfigEditMode,
  action
) => {
  switch (action.type) {
    case ACCOUNT_VIEW_EMAIL_CONFIG_MODE:
      return "VIEW";
    case ACCOUNT_EDIT_EMAIL_CONFIG_MODE:
      return "EDIT";
    case ACCOUNT_LOADING_EMAIL_CONFIG_MODE:
      return "LOADING";
    case ACCOUNT_LOADED_EMAIL_CONFIG_MODE:
      return "LOADED";
    default:
      return state;
  }
};
