export const BIDDING_REQUESTS_FOR_MEMBER_INITIALIZE_BASIC_FILTER_DATA =
  "BIDDING_REQUESTS_FOR_MEMBER_INITIALIZE_BASIC_FILTER_DATA";

export const BIDDING_REQUESTS_FOR_MEMBER_EDIT_BASIC_FILTER_DATA_FORM =
  "BIDDING_REQUESTS_FOR_MEMBER_EDIT_BASIC_FILTER_DATA_FORM";

export const BIDDING_REQUESTS_FOR_MEMBER_CHANGE_VIEW_MODEL =
  "BIDDING_REQUESTS_FOR_MEMBER_CHANGE_VIEW_MODEL";
export const BIDDING_REQUESTS_FOR_MEMBER_INITIALIZE_LIST_COLUMNS_FORM =
  "BIDDING_REQUESTS_FOR_MEMBER_INITIALIZE_LIST_COLUMNS_FORM";
export const BIDDING_REQUESTS_FOR_MEMBER_APPLY_LIST_COLUMNS =
  "BIDDING_REQUESTS_FOR_MEMBER_APPLY_LIST_COLUMNS";
export const BIDDING_REQUESTS_FOR_MEMBER_UPDATE_LIST_FILTER_FORM =
  "BIDDING_REQUESTS_FOR_MEMBER_UPDATE_LIST_FILTER_FORM";
export const BIDDING_REQUESTS_FOR_MEMBER_APPLY_LIST_FILTER =
  "BIDDING_REQUESTS_FOR_MEMBER_APPLY_LIST_FILTER";
export const BIDDING_REQUESTS_FOR_MEMBER_INITIALIZE_LIST_FILTER_FORM =
  "BIDDING_REQUESTS_FOR_MEMBER_INITIALIZE_LIST_FILTER_FORM";
export const BIDDING_REQUESTS_FOR_MEMBER_RESET_LIST_FILTER_FORM =
  "BIDDING_REQUESTS_FOR_MEMBER_RESET_LIST_FILTER_FORM";
export const TOGGLE_ARCHIVE_MAP_VIEW = "TOGGLE_ARCHIVE_MAP_VIEW";
export const GET_ARCHIVE_MAP_VIEW = "GET_ARCHIVE_MAP_VIEW";
export const BIDDING_REQUESTS_FOR_MEMBER_BEGIN_FETCH_DATA =
  "BIDDING_REQUESTS_FOR_MEMBER_BEGIN_FETCH_DATA";
export const BIDDING_REQUESTS_FOR_MEMBER_END_FETCH_DATA =
  "BIDDING_REQUESTS_FOR_MEMBER_END_FETCH_DATA";
export const BIDDING_REQUESTS_FOR_MEMBER_FETCH_DATA =
  "BIDDING_REQUESTS_FOR_MEMBER_FETCH_DATA";
export const BIDDING_REQUESTS_FOR_MEMBER_CHANGE_PRICE_STATUS =
  "BIDDING_REQUESTS_FOR_MEMBER_CHANGE_PRICE_STATUS";
export const BIDDING_REQUESTS_FOR_MEMBER_DETAIL =
  "BIDDING_REQUESTS_FOR_MEMBER_DETAIL";

export const BIDDING_REQUESTS_FOR_MEMBER_BEGIN_FETCH_DETAIL =
  "BIDDING_REQUESTS_FOR_MEMBER_BEGIN_FETCH_DETAIL";
export const BIDDING_REQUESTS_FOR_MEMBER_END_FETCH_DETAIL =
  "BIDDING_REQUESTS_FOR_MEMBER_END_FETCH_DETAIL";
export const BIDDING_REQUESTS_FOR_MEMBER_FETCH_GRAPH_DATA =
  "BIDDING_REQUESTS_FOR_MEMBER_FETCH_GRAPH_DATA";
