import { naString } from "@src/tools/string_tools";
import _ from "lodash";

export const convertForList = (
  data,
  columns = [],
  dataProcessor,
  columnsSetUp,
  ...options
) => {
  const newData = [];
  let columns_key = [];
  columns.forEach((item) => {
    columns_key.push(item.key);
  });

  if (!_.isEmpty(data)) {
    data.forEach((datum, idx) => {
      const tmp = {
        "#": {
          value: idx + 1,
          fixed: "left",
          verbose: "#",
          id: datum.id,
          width: 50,
          fullName: idx + 1,
        },
      };

      Object.values(columnsSetUp).forEach((column) => {
        if (columns_key.includes(column.key)) {
          const getterData = dataProcessor[column.key]
            ? // If not a number then use naString
              dataProcessor[column.key].getter(datum, options) === 0
              ? dataProcessor[column.key].getter(datum, options)
              : naString(dataProcessor[column.key].getter(datum, options))
            : "";

          tmp[column.key] = {
            value: getterData,
            fullName: _.get(dataProcessor[column.key], "extraGetter")
              ? dataProcessor[column.key].extraGetter(datum, options)
              : getterData,
            verbose: (_.find(columns, (e) => e.key == column.key) || {})
              .display_name,
            fixed: _.get(columnsSetUp[column.key], "fixed", ""),
            width: _.get(columnsSetUp[column.key], "width", ""),
            flexGrow: _.get(columnsSetUp[column.key], "flexGrow", 0),
          };
        }
      });

      newData.push(tmp);
    });
    return newData;
  }
};

export const getDisplayNameFromOptions = (value, options) => {
  return (
    (
      _.find(options, (item) => {
        return item.value == value;
      }) || {}
    ).display_name || value
  );
};

export const getColorFromOptions = (value, options) => {
  return (
    _.find(options, (item) => {
      return (item.value = value);
    }) || {}
  ).color;
};
