import { Icon, Loader } from "rsuite";
import { Transfer } from "./OverviewAllTransfers";

// ============================= ACTIONS =============================
import {
  resetTrfPrebookData,
  setTrfPrebookData,
} from "@src/actions/Project/TripPlanner";

// ============================ SELECTORS ============================
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import * as yup from "yup";
import { useQueries } from "@tanstack/react-query";
import { trfPrebook } from "@src/api";
import { toast } from "react-toastify";
import { getTransferServices } from "@src/selectors/Project/TripPlanner/day_by_day_selectors";

const prebookSchema = yup.object().shape({
  session_id: yup.string().required("Required"),
  booking_id: yup.string().required("Required"),
  inbound: yup.string().nullable(), // datetime string
  outbound: yup.string().nullable(), // datetime string
  supplier: yup.string().required("Required"),
});

const trfPrebookPayloadCreator = (transfer) => {
  return prebookSchema.cast({
    session_id: transfer.session_id,
    booking_id: transfer.booking_id,
    inbound: transfer?.inbound?.start,
    outbound: transfer?.outbound?.start,
    supplier: transfer.supplier,
  });
};

function getPrebookTransfersPayloads(transfers) {
  const payloads = [];

  transfers.forEach((trf) => {
    if (_.isEmpty(trf?.inbound)) {
      payloads.push({
        transfer_type: "inbound",
        ...trfPrebookPayloadCreator(trf),
      });
    }
    if (_.isEmpty(trf?.outbound)) {
      payloads.push({
        transfer_type: "outbound",
        ...trfPrebookPayloadCreator(trf),
      });
    }
  });
  return payloads;
}

const PrebookTransferError = ({ children }) => {
  return (
    <span className="PrebookTransfer PrebookTransfer--error">
      <Icon icon="exclamation-circle" color="red" size="2x" />
      <strong>{children}</strong>
    </span>
  );
};
PrebookTransferError.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

const TrfPrebook = ({ transfer, prebook, index, onAccept }) => {
  const prebookAccepted = prebook?.accepted ?? false;

  return (
    <div className="Card">
      <div className="Card__header">
        <h5>
          {index + 1}. {transfer?.origin?.name} to {transfer?.destination?.name}
        </h5>
        <div className="Card__actions">
          <input
            type="checkbox"
            onChange={(e) => onAccept({ checked: e.target.checked })}
          />
          <span>
            <strong>{prebookAccepted ? "Accepted" : "Accept"}</strong>
          </span>
        </div>
      </div>
      {prebook ? (
        <Transfer transfer_type="outbound" transfer={transfer} />
      ) : (
        <span>
          Prebook for this transfer failed. Please select another transfer.
        </span>
      )}
    </div>
  );
};
TrfPrebook.propTypes = {
  prebook: PropTypes.object.isRequired,
  transfer: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onAccept: PropTypes.func.isRequired,
};

const PrebookTransferModal = ({ onCancel }) => {
  const [prebookInfo, setPrebookInfo] = useState([]);

  const { payloads, transfers } = useSelector((state) => {
    var regularTrfs = getTransferServices(state, {
      booking_mode: "regular",
    });
    regularTrfs = _.flattenDeep(
      _.flatten(Object.values(regularTrfs)).map((leg) => Object.values(leg))
    );
    var optionTrfs = getTransferServices(state, {
      booking_mode: "option",
    });
    optionTrfs = _.flattenDeep(
      _.flatten(Object.values(optionTrfs)).map((leg) => Object.values(leg))
    );

    const transfers = [...regularTrfs, ...optionTrfs];

    const payloads = getPrebookTransfersPayloads(transfers);
    return { payloads, transfers };
  });

  const queries = useQueries({
    queries: payloads.length
      ? payloads.map((payload, idx) => ({
          queryKey: ["transfer_prebook", payload.session_id, idx],
          queryFn: () => trfPrebook(payload),
          onSuccess: (data) => {
            setPrebookInfo((p) => [...p, data?.data ?? {}]);
          },
          onError: () => {
            toast.error(
              "Failed to prebook transfer. We are sorry for the inconvenience",
              { autoClose: 5000 }
            );
            onCancel();
          },
          refetchOnWindowFocus: false,
          retry: false,
        }))
      : [],
  });

  const loading = queries.length
    ? queries.some((q) => (q?.isLoading ?? false) || (q?.isFetching ?? false))
    : false;

  const allPrebooksAccepted = prebookInfo.every((v) => v?.accepted ?? false);

  const dispatch = useDispatch();
  const onAccept = useCallback(
    async (prebookInfo) => dispatch(setTrfPrebookData({ data: prebookInfo })),
    [dispatch]
  );
  const onResetPrebook = useCallback(
    async () => dispatch(resetTrfPrebookData()),
    [dispatch]
  );

  return (
    <div className="Modal PrebookTransferModal">
      <div className="Modal__card">
        <div className="Modal__card__header">
          <h5>Transfer Prebook Information</h5>
        </div>
        <div className="Modal__card__body">
          {loading ? (
            <Loader vertical content="Please wait..." size="lg" center />
          ) : (
            transfers.map((trf, idx) => {
              const prebook = prebookInfo.find(
                (t) => t.booking_id === trf.booking_id
              );

              return (
                <TrfPrebook
                  key={idx}
                  prebook={prebook}
                  transfer={trf}
                  index={idx}
                  onAccept={({ checked }) => {
                    setPrebookInfo((p) =>
                      _.cloneDeep(p).map((t) => {
                        if (t.booking_id === trf.booking_id) {
                          t["accepted"] = checked;
                        }
                        return t;
                      })
                    );
                  }}
                />
              );
            })
          )}
        </div>
        <div className="Modal__card__actions">
          {!loading && (
            <React.Fragment>
              <button
                className="Button"
                data-ghost={true}
                onClick={() => {
                  onResetPrebook();
                  onCancel();
                }}>
                Close
              </button>
              <button
                className="Button"
                data-disabled={!allPrebooksAccepted}
                onClick={async () => {
                  if (!allPrebooksAccepted) {
                    toast.warning(
                      "Please accept all prebook information first",
                      { autoClose: 5000 }
                    );
                    return;
                  }

                  await onAccept(prebookInfo);
                  onCancel();
                }}>
                Accept
              </button>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};
PrebookTransferModal.propTypes = { onCancel: PropTypes.func.isRequired };
export default PrebookTransferModal;
