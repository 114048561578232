import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { WhisperWrapper } from "@src/components/common/ui_helpers";
import { createUseStyles } from "react-jss";
import { CustomButton } from "@src/components/common/buttons";
import { variables } from "@src/jsssetup";

const messages = {
  sidebarBtnHint: "Click to open sidebar",
};

const useStyles = createUseStyles({
  hintCntr: {
    display: "grid",
    placeItems: "center",
    gridGap: `calc(${variables.half_gap} /2)`,
  },
  checkboxCntr: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    gridGap: variables.half_gap,
    alignItems: "center",
  },
});
export const withHelpHint = (WrappedComponent, msgId = null, placement) => {
  const Comp = (props) => {
    const classes = useStyles();
    const [hide, setHide] = useState(localStorage.getItem(msgId));
    const [dontShowAgain, setDontShowAgain] = useState(
      localStorage.getItem(msgId)
    );

    const handleGotItClick = () => {
      if (dontShowAgain) {
        localStorage.setItem(msgId, "true");
        setHide(true);
      } else {
        setHide(true);
      }
    };

    useEffect(() => {
      if (dontShowAgain) {
        setHide(true);
      } else {
        setHide(false);
      }
    }, [props.showHint]);

    const message = (
      <div className={classes.hintCntr}>
        <span>{messages[msgId]}</span>
        <CustomButton size="xs" onClick={handleGotItClick}>
          Got It!
        </CustomButton>
        <div className={classes.checkboxCntr}>
          <input
            type="checkbox"
            checked={dontShowAgain}
            onChange={() => setDontShowAgain(!dontShowAgain)}
          />
          <span>Don't show this again</span>
        </div>
      </div>
    );

    return hide ? (
      <WrappedComponent {...props} />
    ) : (
      <WhisperWrapper
        delay={500}
        msg={message}
        open={props.showHint && !hide ? true : false}
        placement={placement}>
        <WrappedComponent {...props} />
      </WhisperWrapper>
    );
  };
  return Comp;
};
withHelpHint.propTypes = {
  showHint: PropTypes.bool,
};
