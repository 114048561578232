export const TRIPPLANNER_ACC_PREF_PRIORITIES_SET_ACC =
  "TRIPPLANNER_ACC_PREF_PRIORITIES_SET_ACC";
export const TRIPPLANNER_ACC_PREF_REMOVE_ACC_PRIORITY =
  "TRIPPLANNER_ACC_PREF_REMOVE_ACC_PRIORITY";
export const TRIPPLANNER_ACC_PREF_CHANGE_PRIORITY =
  "TRIPPLANNER_ACC_PREF_CHANGE_PRIORITY";
export const TRIPPLANNER_ACC_PREF_CHANGE_PRIORITY_BEHAVIOUR =
  "TRIPPLANNER_ACC_PREF_CHANGE_PRIORITY_BEHAVIOUR";
export const TRIPPLANNER_ACC_PREF_CLEAR_DEST_PRIORITIES =
  "TRIPPLANNER_ACC_PREF_CLEAR_DEST_PRIORITIES";
