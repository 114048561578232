import Authorize from "@src/components/authorization/Authorize";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { InlineIcon } from "@iconify/react";
import { injectIntl } from "react-intl";
import { initialMenu } from "@src/config/menu";
import { useLocation } from "react-router-dom";
import { MobileViewContext } from "@src/App";
import { useSelector } from "react-redux";
import { getUserMetaSelector } from "@src/selectors/Shared/user_selectors";

var SubItems = ({ items, intl, onClick }) => {
  const { pathname } = useLocation();
  const { company_type } = useSelector((state) => getUserMetaSelector(state));

  return items
    .filter((item) => {
      // Filter the items based on the company type
      const allowedUserTypes = item?.allowed_user_types ?? [];
      if (
        allowedUserTypes.length > 0 &&
        !allowedUserTypes.includes(company_type)
      ) {
        return false;
      } else {
        return true;
      }
    })
    .map((item, index) => {
      let required_groups = null;
      required_groups = item?.required_groups ?? [];

      const isActive = pathname === item.parentPath + item.path;

      return (
        <Authorize
          required_groups={required_groups}
          allowed_groups={_.get(item, "allowed_groups", [])}
          user_required_groups={_.get(item, "user_required_groups", [])}
          key={index}>
          {item.subMenu ? (
            <div className="SubItem__subMenu">
              <div className="SubItem__subMenu__title">
                {_.upperCase(intl.formatMessage({ id: item.name }))}
              </div>
              <div className="SubItem__subMenu__body">
                {(item?.subMenu ?? []).map((subItem, idx) => {
                  const subItems = subItem.subMenu?.filter((e) => e.subMenu);
                  const required_groups_b = subItem?.required_groups ?? [];

                  const isActive =
                    pathname === subItem.parentPath + subItem.path;

                  return subItem.subMenu ? (
                    <SubItems items={subItems} onClick={onClick} />
                  ) : (
                    <Authorize
                      key={idx}
                      required_groups={required_groups_b}
                      allowed_groups={_.get(subItem, "allowed_groups", [])}
                      user_required_groups={_.get(
                        subItem,
                        "user_required_groups",
                        []
                      )}>
                      <div
                        id={subItem.name}
                        className={`SubItem__subMenu__body__item${
                          isActive
                            ? " SubItem__subMenu__body__item--active"
                            : ""
                        }`}
                        onClick={() =>
                          onClick(subItem.parentPath + subItem.path, subItem)
                        }>
                        {_.startCase(intl.formatMessage({ id: subItem.name }))}
                      </div>
                    </Authorize>
                  );
                })}
              </div>
            </div>
          ) : (
            <div
              id={item.name}
              className={`SubItem${isActive ? " SubItem--active" : ""}`}
              onClick={() => onClick(item.parentPath + item.path, item)}>
              {item.isB2CList
                ? item.name
                : _.upperCase(intl.formatMessage({ id: item.name }))}
            </div>
          )}
        </Authorize>
      );
    });
};
SubItems.propTypes = {
  intl: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
};
SubItems = injectIntl(SubItems);

var Item = ({ item, index, required_groups, intl, onReroute }) => {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setExpanded(index === 0);
  }, [item.name]);

  const hasSubMenu = item.subMenu.length > 0;

  var icon = "ri:arrow-right-s-line";
  if (expanded && hasSubMenu) {
    icon = "ri:arrow-up-s-line";
  } else if (!expanded && hasSubMenu) {
    icon = "ri:arrow-down-s-line";
  }

  return (
    <Authorize
      required_groups={required_groups}
      allowed_groups={_.get(item, "allowed_groups", [])}
      user_required_groups={_.get(item, "user_required_groups", [])}>
      <div
        id={item.name}
        className={expanded ? "SubSideBar__item--expanded" : "SubSideBar__item"}
        onClick={
          !hasSubMenu
            ? () => onReroute(item.parentPath + item.path, item)
            : () => setExpanded(!expanded)
        }>
        {_.upperCase(intl.formatMessage({ id: item.name }))}
        <div className="SubSideBar__expand__icon__container">
          <InlineIcon className="SubSideBar__expand__icon" icon={icon} />
        </div>
      </div>
      {expanded && <SubItems items={item.subMenu} onClick={onReroute} />}
    </Authorize>
  );
};
Item.defaultProps = {
  index: 0,
  item: {},
};
Item.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  required_groups: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired,
  onReroute: PropTypes.func.isRequired,
};
Item = injectIntl(Item);

const SubSideBar = ({ sidebarCollapsed }) => {
  const history = useHistory();
  const mobileView = useContext(MobileViewContext);
  const { company_type } = useSelector((state) => getUserMetaSelector(state));

  function handleClick(fullPath, item) {
    // If the item has a full url link then we just open it
    // without changing the route.
    if (item.link) {
      window.open(item.link);
      return;
    }
    if (item.redirect) return;

    history.push(fullPath);
    return;
  }

  let activeFirstLevMenu = `/${history.location.pathname.split("/")[1]}`;
  if (history.location.pathname == "/my-profile") {
    activeFirstLevMenu = "/admin";
  }
  const subMenu = (
    _.find(initialMenu, (item) => item.path == activeFirstLevMenu) || {}
  ).subMenu;

  return !subMenu ? null : (
    <div
      className="SubSideBar"
      data-sidebar-collapsed={sidebarCollapsed}
      data-mobile-view={mobileView}>
      <div className="SubSideBar__body">
        {subMenu
          .filter((item) => {
            const allowedUserTypes = item?.allowed_user_types ?? [];
            if (
              allowedUserTypes.length > 0 &&
              !allowedUserTypes.includes(company_type)
            ) {
              return false;
            } else {
              return true;
            }
          })
          .map((item, index) => {
            let required_groups = null;
            required_groups = item?.required_groups ?? [];

            return (
              <Item
                key={index}
                index={index}
                item={item}
                required_groups={required_groups}
                onReroute={(item, c) => handleClick(item, c)}
              />
            );
          })}
      </div>
    </div>
  );
};
SubSideBar.propTypes = { sidebarCollapsed: PropTypes.bool.isRequired };
export default SubSideBar;
