export const BIDDING_REQUEST_INITIALIZE_BASIC_FILTER_DATA =
  "BIDDING_REQUEST_INITIALIZE_BASIC_FILTER_DATA";

export const BIDDING_REQUEST_EDIT_BASIC_FILTER_DATA_FORM =
  "BIDDING_REQUEST_EDIT_BASIC_FILTER_DATA_FORM";

export const CHANGE_ARCHIVE_VIEW_MODEL = "CHANGE_ARCHIVE_VIEW_MODEL";
export const BIDDING_REQUEST_INITIALIZE_LIST_COLUMNS_FORM =
  "BIDDING_REQUEST_INITIALIZE_LIST_COLUMNS_FORM";
export const BIDDING_REQUEST_APPLY_LIST_COLUMNS =
  "BIDDING_REQUEST_APPLY_LIST_COLUMNS";
export const BIDDING_REQUEST_UPDATE_LIST_FILTER_FORM =
  "BIDDING_REQUEST_UPDATE_LIST_FILTER_FORM";
export const BIDDING_REQUEST_APPLY_LIST_FILTER =
  "BIDDING_REQUEST_APPLY_LIST_FILTER";
export const BIDDING_REQUEST_INITIALIZE_LIST_FILTER_FORM =
  "BIDDING_REQUEST_INITIALIZE_LIST_FILTER_FORM";
export const BIDDING_REQUEST_RESET_LIST_FILTER_FORM =
  "BIDDING_REQUEST_RESET_LIST_FILTER_FORM";
export const TOGGLE_ARCHIVE_MAP_VIEW = "TOGGLE_ARCHIVE_MAP_VIEW";

export const BIDDING_REQUEST_BEGIN_FETCH_DATA =
  "BIDDING_REQUEST_BEGIN_FETCH_DATA";
export const BIDDING_REQUEST_END_FETCH_DATA = "BIDDING_REQUEST_END_FETCH_DATA";
export const BIDDING_REQUEST_FETCH_DATA = "BIDDING_REQUEST_FETCH_DATA";
export const BIDDING_REQUEST_CHANGE_PRICE_STATUS =
  "BIDDING_REQUEST_CHANGE_PRICE_STATUS";
export const BIDDING_REQUEST_DETAIL = "BIDDING_REQUEST_DETAIL";

export const BIDDING_REQUEST_BEGIN_FETCH_DETAIL =
  "BIDDING_REQUEST_BEGIN_FETCH_DETAIL";
export const BIDDING_REQUEST_END_FETCH_DETAIL =
  "BIDDING_REQUEST_END_FETCH_DETAIL";
