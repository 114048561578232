import { Notification, Divider, IconButton, Icon } from "rsuite";

import _ from "lodash";
import React from "react";
import { toast } from "react-toastify";
import { CustomButton } from "./buttons";

/**
 * Presents a warning notification, notifying the user that there no payment
 * methods set up.
 * @param {history} React Router history object.
 */
export const notifyPaymentNotSetupWarning = (history) => {
  Notification.warning({
    key: "payment_method_missing",
    title: "Payment method missing!",
    description: (
      <React.Fragment>
        <p>Please add a payment method.</p>
        <p>
          You can view your payment methods in the{" "}
          <strong>{`"Payment Methods"`}</strong> page.
        </p>
        <Divider />
        <IconButton
          icon={<Icon icon="angle-right" />}
          onClick={() => {
            if (history) {
              history.push("/management-console/billing/payment-methods");
            } else {
              window.location.href =
                "/#/management-console/billing/payment-methods";
            }

            Notification.close("payment_method_missing");
          }}>
          Go to Payment Methods
        </IconButton>
      </React.Fragment>
    ),
    duration: 20000,
  });
};

/**
 * Presents an error notification to the user notifying that there is
 * an uncollectible or void payment
 * due and thus the account is disabled untile the payment is settled.
 * @param {history} React Router history object.
 */
export const accoundDisabledError = (history) => {
  Notification.error({
    title: "Account Disabled",
    description: (
      <React.Fragment>
        <p>
          Your account has been disabled because your subscription has expired
          due to a pending <strong>pending payment</strong>.
        </p>
        <p>Please complete the required payment to renable your account.</p>
        <Divider />
      </React.Fragment>
    ),
    duration: 10000,
  });
};

/**
 * Notifies the user that there is an open payment.
 * @param {history} React Router history object.
 */
export const paymentDueInfo = (history) => {
  Notification.info({
    title: "Subscription Payment Due",
    description: (
      <React.Fragment>
        <p>
          You have a due payment. You can see more information by going to your
          subscription management page.
        </p>
        <Divider />
        <IconButton
          icon={<Icon icon="angle-right" />}
          onClick={() => history.push("/")}>
          Go to Subscription Management
        </IconButton>
      </React.Fragment>
    ),
    duration: 10000,
  });
};

/**
 * Warns the user that the company profile is not complete enough.
 */
export const notifyIncompleteCompanyProfileWarning = ({ history }) => {
  toast.warning(
    <p>
      <span>
        Please complete at least 70% of your company profile to unlock the rest
        of the application.
      </span>
      <br />
      <CustomButton onClick={() => history.push("/admin/company/profile")}>
        Company Profile
      </CustomButton>
    </p>,
    { autoClose: 10000 }
  );
};

/**
 * Warns the user that his personal profile is not complete enough.
 */
export const notifyIncompletePersonalProfileWarning = ({ history }) => {
  toast.warning(
    <p>
      <span>
        Please complete at least 75% of your personal profile to unlock the rest
        of the application.
      </span>
      <br />
      <CustomButton onClick={() => history.push("/my-profile")}>
        My Profile
      </CustomButton>
    </p>,
    { autoClose: 10000 }
  );
};

// =================== FLIGHT PREBOOK NOTIFICATIONS ==================
export const notifyFailedFlightRules = () => {
  Notification.warning({
    title: "Flight Rules Failure",
    description: <p>We failed to fetch flight rules for ...</p>,
    duration: 6000,
  });
};

// ================== BOOKING PROCESS NOTIFICATIONS ==================

export const notifyBookingLongerThanUsual = () => {
  Notification.info({
    title: "Booking Status Information",
    description: (
      <p>Hang on! This is taking longer than usual. Please be patient.</p>
    ),
    duration: 6000,
  });
};

export const notifyBookingFailed = () => {
  Notification.error({
    title: "Booking Status Error",
    description: (
      <p>
        Some of your reservations failed to complete. We have been notified and
        are actively looking into it. Please be patient.
      </p>
    ),
    duration: 10000,
  });
};

// =============== TRIP GUEST INFORMATION NOTIFICATIONS ==============
export const notifyNoDoubleRoomLeaders = () => {
  Notification.warning({
    title: "Room Mapping Validation",
    description: <p>This room already has a room leader assigned to it.</p>,
    duration: 6000,
  });
};

export const notifyRoomPaxExceeded = () => {
  Notification.warning({
    title: "Room Mapping Validation",
    description: <p>This room already has the maximum pax allowed.</p>,
    duration: 6000,
  });
};

// ================ OPERATION RESERVATION NOTIFICATION ===============

const srvMapping = {
  ACC: "accommodation",
  FL: "flight",
  TRF: "transfer",
};

export const notifyFailedToGetReservationInfo = (srvType) => {
  Notification.warning({
    title: `${_.startCase(srvMapping[srvType])} Information Failure`,
    description: <p>Failed to retrieve reservation information.</p>,
    duration: 6000,
  });
};

export const notifyFailedToCxlReservation = (srvType) => {
  Notification.warning({
    title: `${_.startCase(srvMapping[srvType])} Cancellation Failure`,
    description: <p>Failed to cancel reservation.</p>,
    duration: 6000,
  });
};

export const notifySuccessCxlReservation = (srvType) => {
  Notification.success({
    title: `Successful cancellation reservation`,
    description: (
      <p>
        {_.startCase(srvMapping[srvType])} Reservation successfully cancelled.
      </p>
    ),
    duration: 6000,
  });
};

// ======================== OPTION RESERVATION =======================

export const notifyCannotConfirmOption = () => {
  Notification.warning({
    title: `Reservation Option Confirmation`,
    description: <p>This option reservation cannot be confirmed.</p>,
    duration: 6000,
  });
};

export const notifyFailedToConfirmOptionReservation = (srvType) => {
  Notification.warning({
    title: `${_.startCase(srvMapping[srvType])} Option Confirmation Failure`,
    description: <p>Failed to confirm option reservation.</p>,
    duration: 6000,
  });
};

export const notifySuccessOptionConfirmReservation = (srvType) => {
  Notification.success({
    title: `Successful option reservation confirmation.`,
    description: (
      <p>
        {_.startCase(srvMapping[srvType])} Option Reservation successfully
        confirmed.
      </p>
    ),
    duration: 6000,
  });
};

export const notifyComingSoon = () => {
  Notification.success({
    title: `Coming Soon!`,
    description: <p>You will get notified once this feature is available.</p>,
    duration: 6000,
  });
};

export * from "./notifications/BookingNotifications";
export * from "./notifications/TranslationNotifications";
export * from "./notifications/AccountStatementNotifications";
