import { Button } from "rsuite";

// ============================ SELECTORS ============================
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { createUseStyles } from "react-jss";
import { Form, Formik } from "formik";
import { NormalSelectField, NormalSliderField } from "@src/components/forms";
import { formStyles, variables } from "@src/jsssetup";
import { currencies } from "@src/definitions";

const overviewTrfFiltersStyles = createUseStyles({
  OverviewTrfFilters: { position: "sticky", top: 0, zIndex: 1 },
  form: {
    ...formStyles.form,
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridGap: variables.normal_gap,
  },
  actions: {
    display: "grid",
    gridGap: variables.normal_gap,
    justifyContent: "end",
    alignItems: "end",
    gridColumn: "span 2",
    gridTemplateColumns: "repeat(2, max-content)",
  },
});
const OverviewTrfFilters = ({ transfers, onApplyFilters }) => {
  const classes = overviewTrfFiltersStyles();

  const providers = Object.entries(
    _.groupBy(
      _.sortBy(
        _.flatten(
          transfers.map(
            (trf) =>
              _.get(trf, "inbound.provider.name") ||
              _.get(trf, "outbound.provider.name")
          )
        )
      )
    )
  ).map(([key, items]) => [key, `${key} (${items.length})`]);

  const vehicles = Object.entries(
    _.groupBy(
      _.sortBy(
        _.flatten(
          transfers.map(
            (trf) =>
              _.get(trf, "inbound.vehicle.brand") ||
              _.get(trf, "inbound.vehicle.vehicle_type") ||
              _.get(trf, "outbound.vehicle.brand") ||
              _.get(trf, "outbound.vehicle.vehicle_type")
          )
        )
      )
    )
  ).map(([key, items]) => [key, `${key} (${items.length})`]);

  const suppliers = Object.entries(
    _.groupBy(_.sortBy(transfers.map((trf) => trf.supplier)))
  ).map(([key, items]) => [key, `${_.startCase(key)} (${items.length})`]);

  const maxPrice =
    parseInt(
      Math.max(...transfers.map((trf) => _.get(trf, "price.value", 0))) + 1,
      10
    ) || 10000000000;
  const minPrice =
    parseInt(
      Math.min(...transfers.map((trf) => _.get(trf, "price.value", 0))) + 1,
      10
    ) || 0;

  return (
    transfers.length > 0 && (
      <div className={classes.OverviewTrfFilters}>
        <Formik
          initialValues={{
            vehicle_type: "",
            provider: "",
            supplier: "",
            max_price: maxPrice,
          }}
          onSubmit={(values) => onApplyFilters(values)}>
          {({ values, resetForm, submitForm }) => (
            <Form className={classes.form}>
              <NormalSelectField
                label="Vehicle Type"
                name="vehicle_type"
                options={[["", "------"], ...vehicles]}
              />
              <NormalSelectField
                label="Provider"
                name="provider"
                options={[["", "------"], ...providers]}
              />
              <NormalSelectField
                label="Supplier"
                name="supplier"
                options={[["", "------"], ...suppliers]}
              />
              <NormalSliderField
                label={`Max Price: ${_.get(
                  currencies,
                  _.get(transfers, `0.price.currency`, "N/A")
                )}${values.max_price}`}
                name="max_price"
                min={minPrice}
                max={maxPrice}
              />
              <div className={classes.actions}>
                <Button
                  appearance="ghost"
                  onClick={async () => {
                    await resetForm();
                    submitForm();
                  }}>
                  <strong>Reset</strong>
                </Button>
                <Button appearance="primary" type="submit">
                  <strong>Apply</strong>
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        <div></div>
      </div>
    )
  );
};
OverviewTrfFilters.defaultProps = { transfers: [] };
OverviewTrfFilters.propTypes = {
  transfers: PropTypes.array.isRequired,
  onApplyFilters: PropTypes.func.isRequired,
};
export default OverviewTrfFilters;
