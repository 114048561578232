import * as Yup from "yup";

export function updateOptions({ options, selected }) {
  var opts = options.filter((opt) => opt.value > 0 && opt.id !== selected.id);
  if (selected.value > 0) {
    opts.push(selected);
  }
  return opts;
}

export function createOptions({ accommodation, selectId, leg, totalPax }) {
  var output = [
    {
      value: 0,
      label: "None",
      selectId: selectId,
      id: accommodation.id,
      capacity: 0,
      is_return_leg: leg.is_return_leg,
    },
  ];

  var optionsNum =
    accommodation.is_cabin && !accommodation.is_shared
      ? Math.min(
          Math.ceil(totalPax / accommodation.capacity),
          accommodation.availability
        )
      : accommodation.availability >= totalPax
      ? totalPax
      : accommodation.availability;

  new Array(optionsNum || 0).fill(1).forEach((x, idx) => {
    output.push({
      value: idx + 1,
      label:
        accommodation.is_cabin && !accommodation.is_shared
          ? `${idx + 1}  ${idx + 1 > 1 ? "Cabins" : "Cabin"}`
          : accommodation.is_seat || accommodation.is_deck_space
          ? `${idx + 1}  ${
              accommodation.is_deck_space
                ? idx + 1 > 1
                  ? "Places"
                  : "Place"
                : idx + 1 > 1
                ? "Seats"
                : "Seat"
            }`
          : `${idx + 1} ${idx + 1 > 1 ? "Berths" : "Berth"}`,
      tickets: idx + 1,
      is_return_leg: leg.is_return_leg,
      desc: accommodation.description,
      ...accommodation,
    });
  });
  return output;
}

export const filtersSchema = Yup.object().shape({
  max_price: Yup.mixed().nullable().default(""),
  min_price: Yup.mixed().nullable().default(""),
  order_by: Yup.string()
    .oneOf([
      "price",
      "-price",
      "duration",
      "-duration",
      "arrival",
      "-arrival",
      "departure",
      "-departure",
    ])
    .nullable()
    .default(""),
  operator: Yup.string().nullable().default(""),
});

export const defaultFilters = Yup.object().shape({
  max_price: Yup.mixed().nullable().default(""),
  min_price: Yup.mixed().nullable().default(""),
  order_by: Yup.string()
    .oneOf([
      "price",
      "-price",
      "duration",
      "-duration",
      "arrival",
      "-arrival",
      "departure",
      "-departure",
    ])
    .nullable()
    .default("price"),
  operator: Yup.string().nullable().default(""),
});
