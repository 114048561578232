import _ from "lodash";
import { cardStyles, variables } from "@src/jsssetup";
import { humanizeDuration } from "@src/tools/date_tools";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React from "react";
import { createUseStyles } from "react-jss";
import { naString } from "@src/tools/string_tools";
import LeanAuthHoc from "@src/components/authorization/LeanAuthHoc";

import { handleNonValidImgFn } from "@src/tools/common_tools";
import PriceDetailsDrawer from "../PriceDetailsDrawer";

const FlDuration = ({ flight }) => {
  return (
    <small>Dur: {!flight ? "N/A" : humanizeDuration(flight.duration)}</small>
  );
};
FlDuration.propTypes = {
  flight: PropTypes.object.isRequired,
};

var VendorInfo = ({ crs_info }) => {
  return <small>Channel: {naString(_.get(crs_info, "terminal_pcc"))}</small>;
};
VendorInfo.propTypes = {
  crs_info: PropTypes.object.isRequired,
};
VendorInfo = LeanAuthHoc(VendorInfo, { allowed_groups: ["member"] });

const airlineInfoStyles = createUseStyles({
  AirlineInfo: {
    display: "grid",
    placeItems: "start",
    gridGap: variables.half_gap,
  },
});
const AirlineInfo = ({
  crs_info,
  airline,
  ticketing_deadline,
  flightClasses,
}) => {
  const classes = airlineInfoStyles();

  return (
    <small className={classes.AirlineInfo}>
      <strong>{_.get(airline, "name") || "N/A"}</strong>
      <VendorInfo crs_info={crs_info} />
      {flightClasses.length > 0 && (
        <small>Flight Class: {flightClasses.join(", ")}</small>
      )}
      {!!ticketing_deadline && (
        <small>
          Ticket Deadline:{" "}
          {DateTime.fromISO(ticketing_deadline).toLocaleString(
            DateTime.DATETIME_MED_WITH_WEEKDAY
          )}
        </small>
      )}
    </small>
  );
};
AirlineInfo.propTypes = {
  crs_info: PropTypes.object.isRequired,
  airline: PropTypes.object.isRequired,
  ticketing_deadline: PropTypes.string,
  flightClasses: PropTypes.array,
};

const flightItinStyles = createUseStyles({
  FlightItin: {},
  leg: {
    "display": "grid",
    "gridTemplateColumns": "auto 10rem auto",
    "alignItems": "center",
    "gridGap": variables.half_gap,
    "&:not(:last-child)": {
      paddingBottom: variables.half_gap,
      borderBottom: `1px solid ${variables.colors.borders.base}`,
    },
  },
  point: {
    display: "grid",
    gridAutoRows: "max-content",
    gridGap: variables.half_gap,
    textAlign: "center",
  },
  opaque: { visibility: "hidden" },
  flightLabel: { color: variables.colors.easy.orange, fontWeight: "bold" },
  flightGraphContainer: {
    display: "grid",
    gridAutoRows: "max-content",
    gridGap: variables.half_gap,
    textAlign: "center",
  },
  flightGraph: {
    position: "relative",
    width: "90%",
    border: "1px solid grey",
    justifySelf: "center",
  },
  flightGraphArrowRight: {
    position: "absolute",
    width: 0,
    height: 0,
    right: "-5px",
    top: "-4px",
    borderTop: "4px solid transparent",
    borderBottom: "4px solid transparent",
    borderLeft: "12px solid grey",
  },
  interSegment: { display: "grid", gridGap: variables.half_gap },
});
export const FlightItin = ({ leg }) => {
  const classes = flightItinStyles();
  if (!leg) return null;

  const firstSegment = leg.segments[0];

  function dater(dt) {
    return DateTime.fromISO(dt, {
      setZone: true,
    }).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY);
  }

  return (
    <div className={classes.leg}>
      <div className={classes.point}>
        <small className={classes.flightLabel}>
          {firstSegment.flight_number}
        </small>
        <small>
          <strong>{dater(leg.departure)}</strong>
        </small>
        <small className={classes.flightLabel}>
          {firstSegment.origin_airport.iata}
        </small>
      </div>
      <div className={classes.flightGraphContainer}>
        {leg.segments.length > 1 ? (
          <small className={classes.flightLabel}>
            {leg.segments
              .filter((s, idx) => idx > 0)
              .map((s) => s.flight_number)
              .join(", ")}
          </small>
        ) : (
          <small className={classes.opaque}>/</small>
        )}
        <div className={classes.flightGraph}>
          <div className={classes.flightGraphArrowRight} />
        </div>
        {leg.segments.length > 1 ? (
          <div className={classes.interSegment}>
            <small className={classes.flightLabel}>
              {[
                leg.segments
                  .filter((s, idx) => idx > 0)
                  .map((s) => s.origin_airport.iata)
                  .join(", "),
              ].join(", ")}
            </small>
            <small>
              Layover:{" "}
              {humanizeDuration(
                leg.segments
                  .filter((s, idx) => idx > 0)
                  .reduce((a, b) => b.duration + a, 0),
                { abbrev: true }
              )}
            </small>
          </div>
        ) : (
          <small className={classes.opaque}>/</small>
        )}
      </div>
      <div className={classes.point}>
        <small className={classes.opaque}>/</small>
        <small>
          <strong>{dater(leg.arrival)}</strong>
        </small>
        <small className={classes.flightLabel}>
          {_.last(leg.segments).destination_airport.iata}
        </small>
      </div>
    </div>
  );
};
FlightItin.propTypes = { leg: PropTypes.object.isRequired };

const flightHeaderStyles = createUseStyles({
  FlightHeader: (props) => ({
    ...cardStyles.card,
    display: "grid",
    gridTemplateColumns: !props.hasReturn
      ? "repeat(4, max-content)"
      : "repeat(4, max-content)",
    justifyContent: "space-around",
    gridGap: variables.half_gap,
    background: variables.colors.white,
    padding: [variables.double_gap, 0],
  }),
  airlineInfoContainer: { display: "grid", placeItems: "center" },
  logoContainer: {
    "display": "grid",
    "alignItems": "center",
    "&>img": {
      width: "100%",
      height: "3.5rem",
      objectFit: "contain",
    },
  },
});
export const FlightHeader = ({
  hasReturn,
  flight,
  isSelected,
  showDetails,
  onSelectFlight,
}) => {
  const classes = flightHeaderStyles({ hasReturn });

  const outbound_leg = flight.legs[0].find((o) => o.selected);
  const outbound_air = outbound_leg.segments[0].operating_airline;

  const hasInbd = flight.legs.length > 1;

  var inbound_air = null;
  var inbound_leg = null;

  if (hasInbd) {
    inbound_leg = _.last(flight.legs).find((o) => o.selected);
    inbound_air = inbound_leg.segments[0].operating_airline;
  }

  const opts = _.flatten(
    flight.legs.filter((l) => l.filter((o) => o.selected))
  );
  const flightClasses = [
    ...new Set(
      _.flatten(
        opts.map((o) =>
          o.segments.map((s) => `${s.cabin_class_display} (${s.cabin_class})`)
        )
      )
    ),
  ];

  return (
    <div className={classes.FlightHeader}>
      <div className={classes.logoContainer}>
        <img
          src={outbound_air?.logo ?? ""}
          onError={(event) => handleNonValidImgFn(event, "FL")}
        />
        {!!hasInbd && (
          <img
            src={inbound_air?.logo ?? ""}
            onError={(event) => handleNonValidImgFn(event, "FL")}
          />
        )}
      </div>
      <div className={classes.airlineInfoContainer}>
        <AirlineInfo
          crs_info={flight.crs_info}
          airline={outbound_air}
          ticketing_deadline={flight.ticketing_deadline}
          flightClasses={flightClasses}
        />
        {!!hasInbd && (
          <AirlineInfo
            crs_info={flight.crs_info}
            airline={inbound_air}
            ticketing_deadline={flight.ticketing_deadline}
            flightClasses={flightClasses}
          />
        )}
      </div>
      <div className="FlightHeader__itinerary">
        <FlightItin
          leg={outbound_leg}
          ticketing_deadline={flight.ticketing_deadline}
          fare_type={flight.fare_type}
        />
        {!!hasInbd && (
          <FlightItin
            leg={inbound_leg}
            ticketing_deadline={flight.ticketing_deadline}
            fare_type={flight.fare_type}
          />
        )}
      </div>
      {hasReturn && (
        <div className="FlightHeader__duration">
          <FlDuration flight={outbound_leg} />
          {!!hasInbd && <FlDuration flight={inbound_leg} />}
        </div>
      )}
      <div
        className="FlightHeader__price"
        data-has-return={hasReturn ? "true" : "false"}>
        {!hasReturn && <FlDuration flight={outbound_leg} />}
        <PriceDetailsDrawer
          hasReturn={hasReturn}
          price={flight.price.total_price}
          currency={flight.price.currency}
          searching={false}
          onDetails={() => showDetails(flight.uid)}
        />
        <button
          className="Button"
          data-size="xs"
          data-success={isSelected ? "true" : "false"}
          onClick={() => onSelectFlight()}>
          {isSelected ? "Selected" : "Select"}
        </button>
      </div>
    </div>
  );
};
FlightHeader.propTypes = {
  flight: PropTypes.object.isRequired,
  onSelectFlight: PropTypes.func.isRequired,
  showDetails: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  hasReturn: PropTypes.bool.isRequired,
};

export const FlOption = ({ airline, uid, option, crs_info, children }) => {
  return (
    <div className="FlightOptions__option__content">
      <div className="FlightOptions__option__content__airline_logo">
        <img alt={airline?.name} src={airline?.logo} />
      </div>
      <div className="FlightOptions__option__content__airline">
        <AirlineInfo
          airline={airline}
          flight={option}
          uid={uid}
          crs_info={crs_info}
          flightClasses={[]}
        />
      </div>
      <div>
        <FlightItin leg={option} />
      </div>
      <div className="FlightOptions__option__content__price">
        <FlDuration flight={option} />
        {children}
      </div>
    </div>
  );
};
FlOption.propTypes = {
  airline: PropTypes.object.isRequired,
  uid: PropTypes.string.isRequired,
  option: PropTypes.object.isRequired,
  crs_info: PropTypes.object.isRequired,
};
