import {
  TRIPPLANNER_DETAILED_SERVICE_PRICING_CHANGE,
  TRIPPLANNER_DETAILED_SERVICE_PRICING_RESET,
} from "../types/detailed_pricing";

export const changeDetailedSrvPricing = ({ pricing }) => {
  return { type: TRIPPLANNER_DETAILED_SERVICE_PRICING_CHANGE, pricing };
};

export const resetDetailedSrvPricing = () => {
  return { type: TRIPPLANNER_DETAILED_SERVICE_PRICING_RESET };
};

export const TRIPPLANNER_SET_CXL_POLICY_DATA =
  "TRIPPLANNER_SET_CXL_POLICY_DATA";
export function setCxlPolicyData({ data }) {
  return { type: TRIPPLANNER_SET_CXL_POLICY_DATA, data };
}
