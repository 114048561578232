export const PRODUCTS_NAV_BAR_STATUS = "PRODUCTS_NAV_BAR_STATUS";
export const PRODUCTS_FETCH_FEATURES_LIST = "PRODUCTS_FETCH_FEATURES_LIST";
export const PRODUCTS_BEGIN_FETCH_FEATURES_DATA =
  "PRODUCTS_BEGIN_FETCH_FEATURES_DATA";
export const PRODUCTS_END_FETCH_FEATURES_DATA =
  "PRODUCTS_END_FETCH_FEATURES_DATA";
export const PRODUCTS_FETCH_FEATURES_DETAIL = "PRODUCTS_FETCH_FEATURES_DETAIL";

//======================================Modal=============================
export const PRODUCTS_SHOW_LANDING_MODAL = "PRODUCTS_SHOW_LANDING_MODAL";
export const PRODUCTS_HIDE_LANDING_MODAL = "PRODUCTS_HIDE_LANDING_MODAL";

//=========================Auto Play==================
export const PRODUCTS_HANDLE_SECTION_AUTO_PLAY =
  "PRODUCTS_HANDLE_SECTION_AUTO_PLAY";
export const PRODUCTS_INIT_SECTION_AUTO_PLAY =
  "PRODUCTS_INIT_SECTION_AUTO_PLAY";
export const PRODUCTS_HANDLE_SECTION_SELECT = "PRODUCTS_HANDLE_SECTION_SELECT";
export const PRODUCTS_INIT_SECTION_SELECT = "PRODUCTS_INIT_SECTION_SELECT";
