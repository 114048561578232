import {
  BILL_AND_PAYMENTS_LIST_DATA,
  BILL_AND_PAYMENTS_BEGIN_LIST,
  BILL_AND_PAYMENTS_END_LIST,
  BILL_AND_PAYMENTS_APPLY_LIST_COLUMNS,
  BILL_AND_PAYMENTS_VIEW_TYPE,
  BILL_AND_PAYMENTS_STAT_DATA,
  BILL_AND_PAYMENTS_TAG_TYPE,
  BILL_AND_PAYMENTS_INIT_VIEW_TYPE,
  BILL_AND_PAYMENTS_INIT_TAG_TYPE,
} from "@src/actions/ManagementConsole/BillAndPayments/types";

import { initialBillAndPaymentColumnsSetUp } from "@src/forms/ManagementConsole/BillAndPayments";

import { entitiesListSelector } from "@src/reducers/tools";

export const initialFetchBillAndPaymentsStatus = "IDLE";
export const fetchBillAndPaymentsStatus = (
  state = initialFetchBillAndPaymentsStatus,
  action
) => {
  switch (action.type) {
    case BILL_AND_PAYMENTS_BEGIN_LIST:
      return "FETCHING";
    case BILL_AND_PAYMENTS_END_LIST:
      return "FETCHED";
    default:
      return state;
  }
};

export const initialList = {
  count: 0,
  next: null,
  previous: null,
  details: [],
  page: 1,
  limit: 20,
  displayLength: 100,
};

export const fetchBillAndPaymentsData = (state = initialList, action) => {
  switch (action.type) {
    case BILL_AND_PAYMENTS_LIST_DATA:
      return action.data;
    default:
      return state;
  }
};

// columns
export const billAndPaymentsListColumns = (
  state = entitiesListSelector(initialBillAndPaymentColumnsSetUp),
  action
) => {
  switch (action.type) {
    case BILL_AND_PAYMENTS_APPLY_LIST_COLUMNS:
      return entitiesListSelector(action.data);
    default:
      return state;
  }
};

export const initType = "three_months";

export const billAndPaymentsViewType = (state = initType, action) => {
  switch (action.type) {
    case BILL_AND_PAYMENTS_INIT_VIEW_TYPE:
      return initType;
    case BILL_AND_PAYMENTS_VIEW_TYPE:
      return action.data;
    default:
      return state;
  }
};

export const initTagType = "paid";

export const billAndPaymentTagType = (state = initTagType, action) => {
  switch (action.type) {
    case BILL_AND_PAYMENTS_INIT_TAG_TYPE:
      return initTagType;
    case BILL_AND_PAYMENTS_TAG_TYPE:
      return action.data;
    default:
      return state;
  }
};

export const billAndPaymentsStat = (state = {}, action) => {
  switch (action.type) {
    case BILL_AND_PAYMENTS_STAT_DATA:
      return action.data;
    default:
      return state;
  }
};
