import {
  TRIPPLANNER_DETAILED_SERVICE_PRICING_CHANGE,
  TRIPPLANNER_DETAILED_SERVICE_PRICING_RESET,
  // ========================== INIT ACTIONS =========================
  TRIPPLANNER_INIT,
  // ======================= TRIP LOAD ACTIONS =======================
  TRIPPLANNER_LOAD_TRIP,
  // ===================== OVERVIEW SERVICE FILTERS ====================
  TRIPPLANNER_SERVICE_FILTERS_CHANGE,
  TRIPPLANNER_SERVICE_FILTERS_RESET,
  // ======================== PRICING ACTIONS ========================
} from "@src/actions/Project/TripPlanner/types";

import _ from "lodash";
import update from "immutability-helper";

const tripPlannerServiceFiltersInitial = {
  extremal: [],
  destinations: [],
  service_types: [],
  add_ons: [],
};
export const tripPlannerServiceFilters = (
  state = tripPlannerServiceFiltersInitial,
  action
) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return tripPlannerServiceFiltersInitial;
    case TRIPPLANNER_LOAD_TRIP:
      return tripPlannerServiceFiltersInitial;
    case TRIPPLANNER_SERVICE_FILTERS_CHANGE: {
      if (action.name == "extremal") {
        return update(state, {
          $apply: (srvs) => {
            const newSrvs = {};
            Object.entries(srvs).forEach((e) => {
              if (e[0] == "extremal") {
                newSrvs["extremal"] = action.value;
              } else {
                newSrvs[e[0]] = [];
              }
            });
            return newSrvs;
          },
        });
      } else {
        return update(state, {
          $apply: (srvs) => {
            const newSrvs = {};
            Object.entries(srvs).forEach((e) => {
              if (e[0] == "extremal") {
                newSrvs["extremal"] = [];
              } else if (action.name == e[0]) {
                newSrvs[action.name] = action.value;
              } else {
                newSrvs[e[0]] = e[1];
              }
            });
            return newSrvs;
          },
        });
      }
    }
    case TRIPPLANNER_SERVICE_FILTERS_RESET:
      return tripPlannerServiceFiltersInitial;
    default:
      return state;
  }
};

const tripPlannerDetailedSrvPricingInitial = { pricing: {} };
export const tripPlannerDetailedSrvPricing = (
  state = tripPlannerDetailedSrvPricingInitial,
  action
) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return tripPlannerDetailedSrvPricingInitial;
    case TRIPPLANNER_LOAD_TRIP: {
      return {
        ...(action?.data?.detailed_pricing_data ?? {}),
        loaded: true,
      };
    }
    case TRIPPLANNER_DETAILED_SERVICE_PRICING_CHANGE: {
      const { pricing } = action;
      return update(state, { pricing: { $set: pricing } });
    }
    case TRIPPLANNER_DETAILED_SERVICE_PRICING_RESET:
      return tripPlannerDetailedSrvPricingInitial;
    default:
      return state;
  }
};
