import _ from "lodash";
import { createSelector } from "reselect";
import update from "immutability-helper";
import { requiredRoomsNumberSelector } from "./room_selectors";
import { getDestsWithStaySelector } from "./destination_selectors";

// ========================== PREBOOK TOOLS ==========================

export const getValidPrebookData = (data) =>
  Object.values(data).filter((value) => !_.get(value, "failed", false));

// ================= ACCOMMODATIONS RELATED SELECTORS ================
const propsInjector = (__, props) => props || {};

export function getAllAPITripAccs(state) {
  return state.tripPlannerAccData;
}

export function getAllAPITripAccsAsList(state) {
  return _.flatten(Object.values(state.tripPlannerAccData));
}

export const getAccommodationFromUidSelector = createSelector(
  [getAllAPITripAccs, propsInjector],
  (accData, props) => {
    const { uid } = props;

    const allAccs = _.flatten(
      Object.entries(accData).map((e) => {
        return e[1].map((acc) =>
          update(_.cloneDeep(acc), { destOrder: { $set: e[0] } })
        );
      })
    );

    return allAccs.find((acc) => acc.uid === uid);
  }
);

const getAPIDestAccommodations = (state, { destOrder }) =>
  state.tripPlannerAccData[destOrder];

export const getSelectedAccSelector = createSelector(
  [
    getAllAPITripAccsAsList,
    getAPIDestAccommodations,
    getDestsWithStaySelector,
    propsInjector,
  ],
  (allAccommodationsAsList, accommodations, destsWithStay, { destOrder }) => {
    var acc = (accommodations || []).find((a) => a.selected) || null;

    if (!acc) {
      const prevDest = destsWithStay.find(
        (dest) => dest.order.toString() === Number(destOrder - 2).toString()
      );
      var prevDestId = null;
      if (prevDest) {
        prevDestId = `${prevDest.type}__${prevDest.id}`;
      }

      var currentDestId = null;
      const currentDest = destsWithStay.find(
        (dest) => dest.order.toString() === destOrder.toString()
      );
      if (currentDest) {
        currentDestId = `${currentDest.type}__${currentDest.id}`;
      }

      if (prevDestId === currentDestId) {
        const prevAcc = allAccommodationsAsList.find((acc) => {
          const prevOrder = (Number(destOrder) - 2).toString();
          return acc.destOrder === prevOrder && acc.selected;
        });
        if (prevAcc) {
          acc = prevAcc;
        }
      }
    }
    return acc;
  }
);

export const getAllSelectedAccSelector = createSelector(
  [getAllAPITripAccs],
  (accommodations) => {
    const accs = {};

    Object.entries(accommodations).forEach((entry) => {
      const acc = _.cloneDeep(entry[1].find((acc) => acc.selected));

      if (!acc) return;

      acc["check_in"] = acc?.operational_data?.check_in;
      acc["check_out"] = acc?.operational_data?.check_out;
      acc["rooms"] = acc["rooms"].filter((room) => room.selected);
      acc["detailed_rooms"] = _.get(acc, "detailed_rooms", []).filter(
        (room) => room.selected
      );

      accs[entry[0].toString()] = acc;
    });

    return accs;
  }
);

export const getSelectedAccRoomsSelector = createSelector(
  [getSelectedAccSelector],
  (accommodation) => {
    var selected_rooms = _.get(accommodation, "detailed_rooms", []).filter(
      (r) => r.selected
    );

    if (!selected_rooms.length) {
      selected_rooms = _.get(accommodation, "rooms", []).filter(
        (r) => r.selected
      );
    }

    return selected_rooms;
  }
);

export const getSelectedAccCxl = createSelector(
  [getSelectedAccRoomsSelector, propsInjector],
  (rooms, props) => {
    const { prebookData } = props;

    if (!_.isEmpty(prebookData)) {
      return _.flatten(
        getValidPrebookData(prebookData).map(
          (value) => value.cancellation_policies
        )
      );
    } else {
      return _.flatten(rooms.map((room) => room.cancellation_policies));
    }
  }
);

export function isAccRefundable({ acc, prebook_info }) {
  if (!acc) {
    return false;
  }

  var selRooms = [];

  if (!_.isEmpty(prebook_info)) {
    selRooms = prebook_info.rooms;
  } else {
    selRooms = getHotelSelectedRooms({ acc });
  }

  const nonRef = (selRooms || []).every((r) =>
    (r.cancellation_policies || []).every((c) => c.type !== "refundable")
  );

  return !nonRef;
}

export const isAccRefundableSelector = createSelector(
  [propsInjector],
  (props) => {
    const { accommodation, prebookData } = props;
    return isAccRefundable({ acc: accommodation, prebook_info: prebookData });
  }
);

// ====================== ROOM RELATED SELECTORS =====================
export const getAccBucketRooms = (accommodation) => {
  return [
    ..._.get(accommodation, "detailed_rooms", []),
    ...accommodation.rooms,
  ];
};

export const getSelectedAccRoomsIdsSelector = createSelector(
  [getAPIDestAccommodations],
  (accommodations) => {
    if (typeof accommodations == "undefined") {
      return [];
    }

    return _.flatten(
      accommodations
        .filter(
          (a) => a.selected && getAccBucketRooms(a).some((r) => r.selected)
        )
        .map((a) => [
          ...new Set(
            getAccBucketRooms(a)
              .filter((r) => r.selected)
              .map((r) => r.id)
          ),
        ])
    );
  }
);

export function getHotelSelectedRooms({ acc }) {
  if (_.get(acc, "accommodation_service_type", "ACC") !== "ACC") {
    return _.get(acc, "rooms", []);
  }

  var selected_rooms = _.get(acc, "detailed_rooms", []).filter(
    (r) => r.selected
  );

  if (!selected_rooms.length) {
    selected_rooms = _.get(acc, "rooms", []).filter((r) => r.selected);
  }

  return selected_rooms;
}

// ====================== ACCOMMODATION PRICING ======================
export function getAccAPIPrice({
  accommodation,
  rooms_requested,
  payable = false,
}) {
  var price = 0;
  var currency = "EUR";
  if (!_.get(accommodation, "prebook_info.failed", true)) {
    const prices = _.flatten(
      Object.values(accommodation.prebook_info).map((val) => {
        if (rooms_requested === val.rooms.length) {
          return val.rooms[0].price;
        }

        var rooms = val.rooms;
        if (payable) {
          rooms = rooms.filter((room) => !room.is_own);
        }

        return rooms.map((room) => room.price);
      })
    );
    price = prices.reduce((a, b) => a + b.value, 0);
    currency = prices[0].currency;
  } else {
    var rooms = getHotelSelectedRooms({ acc: accommodation });

    if (payable) {
      rooms = rooms.filter((room) => !room.is_own);
    }

    price = _.get(rooms, "0.price.value", 0);
    currency = _.get(rooms, "0.price.currency", "EUR");
  }
  return { price, currency };
}

export const getAccAPIPriceSelector = createSelector(
  [requiredRoomsNumberSelector, propsInjector],
  (rooms_requested, props) => {
    const { accommodation } = props;
    return getAccAPIPrice({ accommodation, rooms_requested });
  }
);

// ============== ACCOMMODATION ITINERARY COMPATIBILITY ==============
export const isAccDateCompatibleSelector = createSelector(
  [propsInjector],
  (props) => {
    return true;
    // const { checkIn } = props;

    // const arrivalFlight = _.get(flight, "outbound_options", []).find(
    //   (option) => option.selected
    // );
    // var arrival = _.get(arrivalFlight, "arrival");
    // if (arrival) {
    //   arrival = arrival.split("T")[0];
    //   return moment(checkIn).isSame(moment(arrival), "day");
    // } else {
    //   return true;
    // }
  }
);
