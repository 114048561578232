// ========================= CUSTOM SERVICES =========================
export const TRIPPLANNER_CUSTOM_ACC_SRV_ADD = "TRIPPLANNER_CUSTOM_ACC_SRV_ADD";
export const TRIPPLANNER_CUSTOM_ACC_SRV_REMOVE =
  "TRIPPLANNER_CUSTOM_ACC_SRV_REMOVE";

// ========================= ADDHOC SERVICES =========================
export const TRIPPLANNER_ADHOC_ACC_SRV_ADD = "TRIPPLANNER_ADHOC_ACC_SRV_ADD";
export const TRIPPLANNER_ADHOC_ACC_SRV_EDIT = "TRIPPLANNER_ADHOC_ACC_SRV_EDIT";
export const TRIPPLANNER_ADHOC_ACC_SRV_REMOVE =
  "TRIPPLANNER_ADHOC_ACC_SRV_REMOVE";
