import { createSelector } from "reselect";
import { bankersRounder } from "@src/tools/pricing_tools";
import { convertAmountList } from "@src/selectors/Financial";
import _ from "lodash";
import { DateTime } from "luxon";

const propsInjector = (state, props) => props;

export const getFlAvgPriceSelector = createSelector(
  [convertAmountList],
  (convertedAmounts) => {
    const { currSymbol, currency } = _.get(convertedAmounts, 0, {});

    return {
      price: bankersRounder(
        _.mean(convertedAmounts.map((c) => c.convertedAmount)),
        2
      ),
      currSymbol,
      currency,
    };
  }
);

export const getFlCheapestPriceSelector = createSelector(
  [convertAmountList],
  (convertedAmounts) => {
    const { currSymbol, currency } = _.get(convertedAmounts, 0, {});

    return {
      price: Math.min(...convertedAmounts.map((c) => c.convertedAmount)),
      currSymbol,
      currency,
    };
  }
);

const orderFlightsByFastest = createSelector([propsInjector], (props) => {
  const { filteredFlights } = props;

  return _.cloneDeep(filteredFlights).sort((a, b) => {
    var aDuration = Math.min(...a.legs[0].map((o) => o.duration));
    if (a.legs.length > 1) {
      aDuration += Math.min(..._.last(a.legs).map((o) => o.duration));
    }

    var bDuration = Math.min(...b.legs[0].map((o) => o.duration));
    if (b.legs.length > 1) {
      bDuration += Math.min(..._.last(b.legs).map((o) => o.duration));
    }

    return aDuration - bDuration;
  });
});

export const getFlFastestPriceSelector = createSelector(
  [orderFlightsByFastest],
  (orderedFlights) => {
    return orderedFlights.length ? orderedFlights[0].price.total_price : 0;
  }
);

export const getFlBestPriceSelector = createSelector(
  [orderFlightsByFastest],
  (orderedFlights) => {
    return orderedFlights.length
      ? _.cloneDeep(orderedFlights).filter(
          (f) => f.price.total_price <= orderedFlights[0].price.total_price
        )[0].price.total_price
      : 0;
  }
);

export function filterFlights({ flights, filters }) {
  if (typeof flights == "undefined") {
    return [];
  }

  function timeCheck(flight, optionKey, deparrKey, checkType) {
    const compare = checkType == "min" ? (a, b) => a >= b : (a, b) => a <= b;

    flight[`${optionKey}_options`] = flight[`${optionKey}_options`].filter(
      (opt) => {
        const depHour = DateTime.fromISO(opt[deparrKey], {
          setZone: true,
        }).toObject().hour;

        return compare(
          depHour,
          filters[`${optionKey}_${checkType}_${deparrKey}`]
        );
      }
    );
    return flight;
  }

  var filteredFlights = _.cloneDeep(flights)
    .map((fl) => {
      var flight = timeCheck(fl, "outbound", "departure", "min");
      flight = timeCheck(flight, "outbound", "departure", "max");
      flight = timeCheck(flight, "outbound", "arrival", "min");
      flight = timeCheck(flight, "outbound", "arrival", "max");

      if (flight.legs.length > 1) {
        flight = timeCheck(flight, "inbound", "departure", "min");
        flight = timeCheck(flight, "inbound", "departure", "max");
        flight = timeCheck(flight, "inbound", "arrival", "min");
        flight = timeCheck(flight, "inbound", "arrival", "max");
      }

      return flight;
    })
    .filter((fl) => fl.legs[0].length > 0)
    .filter((fl) => {
      const depAirportsOk = filters.departure_airports.length
        ? fl.legs[0].some(
            (opt) =>
              filters.departure_airports === opt.segments[0].origin_airport.iata
          )
        : true;

      const arrAirportsOk = filters.arrival_airports.length
        ? fl.legs[0].some(
            (opt) =>
              filters.arrival_airports ===
              _.last(opt.segments).destination_airport.iata
          )
        : true;

      const airlinesOk = filters.airlines.length
        ? filters.airlines === _.get(fl, "outbound_operating_airline.iata") ||
          filters.airlines === _.get(fl, "inbound_operating_airline.iata")
        : true;

      const flightClassOk = filters.flight_class.length
        ? _.intersection(
            [filters.flight_class],
            [
              ...new Set([
                ..._.flatten(
                  fl.legs[0].map((o) => o.segments.map((s) => s.cabin_class))
                ),
                ..._.flatten(
                  _.last(fl.legs).map((o) =>
                    o.segments.map((s) => s.cabin_class)
                  )
                ),
              ]),
            ]
          ).length > 0
        : true;

      const stopsOk = filters.stops.length
        ? filters.stops === "direct"
          ? fl.legs[0].every((opt) => opt.segments.length === 1) &&
            _.last(fl.legs).every((opt) => opt.segments.length === 1)
          : fl.legs[0].every((opt) => opt.segments.length > 1) &&
            _.last(fl.legs).every((opt) => opt.segments.length > 1)
        : true;

      const flightSourceOk = filters.flight_source.length
        ? filters.flight_source === (fl.source === "WebSource" ? "LCC" : "GDS")
        : true;

      const baggageOk = filters.baggage_allowance.length
        ? fl.legs[0].some((option) =>
            option.segments.some(
              (segment) =>
                filters.baggage_allowance === segment.baggage_allowance.verbose
            )
          ) ||
          (fl.legs.length > 1 &&
            _.last(fl.legs).some((option) =>
              option.segments.some(
                (segment) =>
                  filters.baggage_allowance ===
                  segment.baggage_allowance.verbose
              )
            ))
        : true;

      const brandOk = filters.brand.length
        ? fl.legs[0].some((option) =>
            option.segments.some(
              (segment) => filters.brand === segment.brand_code
            )
          ) ||
          (fl.legs.length > 1 &&
            _.last(fl.legs).some((option) =>
              option.segments.some(
                (segment) => filters.brand === segment.brand_code
              )
            ))
        : true;

      const channelOk = filters.channel.length
        ? filters.channel === fl.crs_info.terminal_pcc
        : true;

      const minPriceOk =
        filters.min_price > 0
          ? filters.min_price <= fl.price.total_price
          : true;

      const maxPriceOk =
        filters.max_price > 0
          ? filters.max_price >= fl.price.total_price
          : true;

      const fareTypeOk =
        filters.fare_type.length > 0
          ? filters.fare_type === fl.fare_type
          : true;

      return (
        depAirportsOk &&
        arrAirportsOk &&
        airlinesOk &&
        flightClassOk &&
        stopsOk &&
        flightSourceOk &&
        minPriceOk &&
        maxPriceOk &&
        baggageOk &&
        channelOk &&
        brandOk &&
        fareTypeOk
      );
    })
    .map((fl) => {
      if (fl.legs[0].every((o) => !o.selected)) {
        fl.legs[0][0].selected = true;
      }

      if (fl.legs.length > 1) {
        if (_.last(fl.legs).every((o) => !o.selected)) {
          _.last(fl.legs)[0].selected = true;
        }
      }
      return fl;
    });

  if (filters.ordering == "cheapest") {
    filteredFlights.sort((a, b) => a.price.total_price - b.price.total_price);
  } else {
    filteredFlights.sort((a, b) => {
      var aDuration = Math.min(...a.legs[0].map((o) => o.duration));
      if (a.legs.length > 1) {
        aDuration += Math.min(..._.last(a.legs).map((o) => o.duration));
      }

      var bDuration = Math.min(...b.legs[0].map((o) => o.duration));
      if (b.legs.length > 1) {
        bDuration += Math.min(..._.last(b.legs).map((o) => o.duration));
      }
      return aDuration - bDuration;
    });
  }

  return filteredFlights;
}
