import { StyleSheet, Text, View, Font } from "@react-pdf/renderer";
import { currencies } from "@src/definitions";
import PropTypes from "prop-types";
import React from "react";
import { formatPrice } from "../helpers";
import NotoSansRegular from "@src/style/fonts/NotoSans-Regular.ttf";
import NotoSansBold from "@src/style/fonts/NotoSans-Bold.ttf";

Font.register({
  family: "Noto Sans",
  fonts: [
    { src: NotoSansRegular, fontWeight: "normal" },
    { src: NotoSansBold, fontWeight: "bold" },
  ],
});

const totalCostDueDateStyles = StyleSheet.create({
  TotalCostDueDate: { flexDirection: "row", fontSize: 13 },
  text: { fontFamily: "Noto Sans", fontWeight: "bold" },
  textError: { fontFamily: "Noto Sans", fontWeight: "bold", color: "red" },
});
const TotalCostDueDate = ({ data }) => {
  const totalCostDueDateStyle = { ...totalCostDueDateStyles.TotalCostDueDate };

  const totalAmount = data.items
    ? data.items.reduce((acc, item) => {
        return acc + parseInt(item.quantity, 10) * parseFloat(item.unitPrice);
      }, 0)
    : "";
  const formattedPrice = formatPrice(totalAmount);

  return (
    <View style={totalCostDueDateStyle}>
      {formattedPrice ? (
        data.currency && (
          <Text style={totalCostDueDateStyles.text}>
            {`${currencies[data.currency]} ${formattedPrice}  (${
              data.currency
            }) due ${data.dueDate}`}
          </Text>
        )
      ) : (
        <Text style={totalCostDueDateStyles.textError}>
          Price formatting error
        </Text>
      )}
    </View>
  );
};
TotalCostDueDate.defaultProps = { data: {} };
TotalCostDueDate.propTypes = { data: PropTypes.object.isRequired };
export default TotalCostDueDate;
