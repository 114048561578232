import { Schema } from "rsuite";

import {
  COMPANY_START_FETCH_PROFILE,
  COMPANY_END_FETCH_PROFILE,
  COMPANY_SET_PROFILE_DATA,
  COMPANY_SHOW_BASE_DATA_EDIT_FORM,
  COMPANY_HIDE_BASE_DATA_EDIT_FORM,
  INITIALIZE_BASE_DATA_EDIT_FORM,
  COMPANY_EDIT_BASE_DATA,
  COMPANY_VALIDATE_BASE_DATA_EDIT_FORM,
  COMPANY_LOADING_BASE_DATA_EDIT_FORM,
  COMPANY_IDLE_BASE_DATA_EDIT_FORM,
  COMPANY_VIEW_NATURE_OF_BIZ,
  COMPANY_EDIT_NATURE_OF_BIZ,
  COMPANY_CLEAN_BASE_EDIT_FORM_ERRORS,
  COMPANY_INITIALIZE_NATURE_DATA_FORM,
  COMPANY_EDIT_NATURE_OF_BIZ_DATA,
  COMPANY_SUBMIT_NATURE_OF_BIZ_DATA,
  COMPANY_EDIT_LICENSE_CODE,
  COMPANY_CLEAN_BASE_DATA_EDIT_FORM,
  COMPANY_TOGGLE_BILLING_ADDRESS,
  COMPANY_FETCH_OPTIONS,
  COMPANY_START_FETCH_ADDRESS,
  COMPANY_END_FETCH_ADDRESS,
  COMPANY_START_FETCH_CONTACT_PERSON,
  COMPANY_END_FETCH_CONTACT_PERSON,
  //imgUpload
  COMPANY_UPLOADED_PREFERENCE_IMAGE,
  COMPANY_BEGIN_UPLOAD_IMG_LINK,
  COMPANY_ENG_UPLOAD_IMG_LINK,
  COMPANY_FETCH_ADDRESS_POINT,
  COMPANY_TOGGLE_GEOLOCATION,
  COMPANY_INITIAL_ADDRESS_POINT,
  COMPANY_TOGGLE_VERIFY_ADDRESS,
  COMPANY_BEGIN_FETCH_ADDRESS_DATA,
  COMPANY_END_FETCH_ADDRESS_DATA,
  COMPANY_CLOSE_VERIFY_ADDRESS,
} from "@src/actions/Admin/Company/types";

// import { FORMAT_TRAVEL_SERVICES_OPTIONS } from "@src/actions/Shares/types";

import {
  initialCompanyBaseDataForm,
  initialCompanyNatureOfBizForm,
} from "@src/forms/Admin/Company/companyProfileForms";

import update from "immutability-helper";

export const initialProfileFetchState = "IDLE";
export const profileFetchStatus = (
  state = initialProfileFetchState,
  action
) => {
  switch (action.type) {
    case COMPANY_START_FETCH_PROFILE:
      return "FETCHING";
    case COMPANY_END_FETCH_PROFILE:
      return "FETCHED";
    default:
      return state;
  }
};

export const initialAddressFetchState = "IDLE";
export const companyAddressFetchStatus = (
  state = initialAddressFetchState,
  action
) => {
  switch (action.type) {
    case COMPANY_START_FETCH_ADDRESS:
      return "FETCHING";
    case COMPANY_END_FETCH_ADDRESS:
      return "FETCHED";
    default:
      return state;
  }
};

export const initialContactPersonFetchState = "IDLE";
export const companyContactPersonFetchStatus = (
  state = initialContactPersonFetchState,
  action
) => {
  switch (action.type) {
    case COMPANY_START_FETCH_CONTACT_PERSON:
      return "FETCHING";
    case COMPANY_END_FETCH_CONTACT_PERSON:
      return "FETCHED";
    default:
      return state;
  }
};

export const initialCompanyProfile = {};
export const companyProfile = (state = initialCompanyProfile, action) => {
  switch (action.type) {
    case COMPANY_SET_PROFILE_DATA:
      return update(state, { $set: action.data });
    default:
      return state;
  }
};

export const initialCompanyProfileOptions = {};
export const companyOptions = (
  state = initialCompanyProfileOptions,
  action
) => {
  switch (action.type) {
    case COMPANY_FETCH_OPTIONS:
      return update(state, { $merge: action.data });
    default:
      return state;
  }
};

export const initialCompanyBaseDataEditFormShow = false;
export const companyBaseDataEditFormShow = (
  state = initialCompanyBaseDataEditFormShow,
  action
) => {
  switch (action.type) {
    case COMPANY_SHOW_BASE_DATA_EDIT_FORM:
      return true;
    case COMPANY_HIDE_BASE_DATA_EDIT_FORM:
      return false;
    default:
      return state;
  }
};

export const companyBaseDataForm = (
  state = initialCompanyBaseDataForm,
  action
) => {
  switch (action.type) {
    case INITIALIZE_BASE_DATA_EDIT_FORM:
      return action.data;
    case COMPANY_CLEAN_BASE_DATA_EDIT_FORM:
      return initialCompanyBaseDataForm;
    case COMPANY_EDIT_BASE_DATA:
      return update(state, {
        [action.data.name]: { value: { $set: action.data.value } },
      });
    case COMPANY_UPLOADED_PREFERENCE_IMAGE:
      return update(state, {
        [action.data.name]: {
          value: { $set: action.data.value },
          fileName: { $set: action.data.fileName },
        },
      });
    default:
      return state;
  }
};

export const initialCompanyBaseDataFormErrorsState = { valid: false };
export const companyBaseDataFormErrors = (
  state = initialCompanyBaseDataFormErrorsState,
  action
) => {
  const tmp = {};
  Object.entries(initialCompanyBaseDataForm).forEach((e) => {
    tmp[e[0]] = e[1].validator;
  });
  const validator = Schema.Model(tmp);

  switch (action.type) {
    case COMPANY_VALIDATE_BASE_DATA_EDIT_FORM: {
      const tmp = {};
      Object.entries(action.data).forEach((e) => (tmp[e[0]] = e[1].value));

      const validation = validator.check(tmp);

      const valid = Object.entries(validation).every((e) => !e[1].hasError);
      validation["valid"] = valid;
      return validation;
    }
    case COMPANY_CLEAN_BASE_EDIT_FORM_ERRORS:
      return initialCompanyBaseDataFormErrorsState;
    default:
      return state;
  }
};

export const companyBaseDataUploadStatus = (state = "IDLE", action) => {
  switch (action.type) {
    case COMPANY_LOADING_BASE_DATA_EDIT_FORM:
      return "LOADING";
    case COMPANY_IDLE_BASE_DATA_EDIT_FORM:
      return "IDLE";
    default:
      return state;
  }
};

export const companyNatureOfBizMode = (state = "VIEW", action) => {
  switch (action.type) {
    case COMPANY_VIEW_NATURE_OF_BIZ:
      return "VIEW";
    case COMPANY_EDIT_NATURE_OF_BIZ:
      return "EDIT";
    case COMPANY_SUBMIT_NATURE_OF_BIZ_DATA:
      return "LOADING";
    default:
      return state;
  }
};

export const companyNatureOfBizForm = (
  state = initialCompanyNatureOfBizForm,
  action
) => {
  switch (action.type) {
    case COMPANY_INITIALIZE_NATURE_DATA_FORM:
      return action.data;
    case COMPANY_EDIT_NATURE_OF_BIZ_DATA:
      // action.data.type defines the type of the update operation
      // push: we push an array to the existing data.
      // set: we directly set the data contained in the action.
      // remove: we empty the form array.
      //License type is a two dimensional array so we need to have special handling
      // of this data. [[<licence_type>, <license_code>]]

      if (action.data.type == "push") {
        let value = action.data.value;
        if (action.data.name == "license_type") {
          value = [action.data.value, ""];
        }

        return update(state, {
          [action.data.name]: { value: { $push: [value] } },
        });
      } else if (action.data.type == "remove") {
        let truth = (v) => v !== action.data.value;

        if (action.data.name == "license_type") {
          truth = (v) => v[0] !== action.data.value;
        }

        return update(state, {
          [action.data.name]: {
            value: { $apply: (va) => va.filter((v) => truth(v)) },
          },
        });
      } else {
        let value = action.data.value;
        if (action.data.name == "license_type") {
          value = value.map((v) => [v, ""]);
        }

        return update(state, {
          [action.data.name]: { value: { $set: value } },
        });
      }
    case COMPANY_EDIT_LICENSE_CODE:
      return update(state, {
        license_type: {
          value: {
            $apply: (lt) => {
              const newLt = [];
              lt.forEach((t) => {
                if (t[0] == action.data.name) {
                  newLt.push([t[0], action.data.value]);
                } else {
                  newLt.push(t);
                }
              });
              return newLt;
            },
          },
        },
      });
    default:
      return state;
  }
};

export const initialCompanyPostalAddressIsSynchronous = false;
export const companyPostalAddressIsSynchronous = (
  state = initialCompanyPostalAddressIsSynchronous,
  action
) => {
  switch (action.type) {
    case COMPANY_SHOW_BASE_DATA_EDIT_FORM:
      return action.data || false;
    case COMPANY_TOGGLE_BILLING_ADDRESS:
      return action.data;
    default:
      return state;
  }
};

//img relation

export const initialUploadCompanyImgLinkStatus = "IDLE";
export const uploadCompanyImgLinkStatus = (
  state = initialUploadCompanyImgLinkStatus,
  action
) => {
  switch (action.type) {
    case COMPANY_BEGIN_UPLOAD_IMG_LINK:
      return "FETCHING";
    case COMPANY_ENG_UPLOAD_IMG_LINK:
      return "FETCHED";
    default:
      return state;
  }
};

export const initialAddressPoint = {};
export const companyAddressPoint = (state = initialAddressPoint, action) => {
  switch (action.type) {
    case COMPANY_FETCH_ADDRESS_POINT:
      return action.data;
    case COMPANY_INITIAL_ADDRESS_POINT:
      return action.data;
    default:
      return state;
  }
};

export const initialIsFetchGeolocation = false;

export const isFetchGeolocation = (
  state = initialIsFetchGeolocation,
  action
) => {
  switch (action.type) {
    case COMPANY_TOGGLE_GEOLOCATION:
      return action.data;
    default:
      return state;
  }
};

export const initialIsVerifyCompanyPostalAddress = false;
export const isVerifyCompanyPostalAddress = (
  state = initialIsVerifyCompanyPostalAddress,
  action
) => {
  switch (action.type) {
    case COMPANY_TOGGLE_VERIFY_ADDRESS:
      return action.data;
    case COMPANY_CLOSE_VERIFY_ADDRESS:
      return false;
    default:
      return state;
  }
};

export const InitialFetchCompanyAddressPointStatus = "IDLE";
export const fetchCompanyAddressPointStatus = (
  state = InitialFetchCompanyAddressPointStatus,
  action
) => {
  switch (action.type) {
    case COMPANY_BEGIN_FETCH_ADDRESS_DATA:
      return "FETCHING";
    case COMPANY_END_FETCH_ADDRESS_DATA:
      return "FETCHED";
    default:
      return state;
  }
};
