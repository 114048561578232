import _ from "lodash";
import { Button, Icon, IconButton, Loader, Modal } from "rsuite";

import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import {
  changeBaseInfo,
  hideAddonImagesModal,
} from "@src/actions/Operation/CustomServices/AddOn";
import { createUseStyles } from "react-jss";
import { modalGenericStyles, variables } from "@src/jsssetup";
import CustomImage from "../CustomImage";
import { ImagePicker } from "@src/pages/Network/B2CDistributionChannels/detail/components/ImageModal";
import { getImageBankDetail } from "@src/api";
import { toast } from "react-toastify";

const styles = createUseStyles({
  ...modalGenericStyles,
  AddOnImagesModal: modalGenericStyles.modal,
  cardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    gridTemplateColumns: "1fr 3fr",
    overflow: "hidden",
    position: "relative",
    height: "100%",
  },
  imgsContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    padding: variables.normal_gap,
    overflow: "auto",
    gridAutoRows: "max-content",
  },
});
const AddOnImagesModal = ({ custom_images, onImgsChange, onHide }) => {
  const classes = styles();
  const [loading, setLoading] = useState(false);

  return (
    <div className={classes.AddOnImagesModal}>
      <div className={classes.card}>
        <div className={classes.cardHeader}>
          <h5>Add On Images</h5>
        </div>
        <div className={classes.cardBody}>
          {loading && <Loader size="lg" backdrop center />}
          <ImagePicker
            selectedIds={custom_images.map((img) => img.id)}
            onSelect={(img) => {
              setLoading(true);
              getImageBankDetail(img.uid)
                .then((data) => {
                  onImgsChange([...custom_images, data.data]);
                })
                .catch(() => {
                  toast.error("Failed to load image", { autoClose: 3000 });
                })
                .finally(() => setLoading(false));
            }}
          />
          <div className={classes.imgsContainer}>
            {custom_images.map((img, idx) => (
              <CustomImage
                key={idx}
                image={img}
                onMainToggle={function (isMainImg) {
                  if (!isMainImg) {
                    return;
                  }

                  const newImgs = custom_images.map((img, iidx) => ({
                    ...img,
                    main_photo: iidx === idx ? true : false,
                  }));
                  onImgsChange(newImgs);
                }}
                onRemoveImage={function (imageId) {
                  const newImgs = custom_images.filter(
                    (img) => img.id !== imageId
                  );
                  if (
                    newImgs.length > 0 &&
                    !newImgs.some((img) => _.get(img, "main_photo", false))
                  ) {
                    newImgs[0]["main_photo"] = true;
                  }

                  onImgsChange(newImgs);
                }}
              />
            ))}
          </div>
        </div>
        <div className={classes.cardActions}>
          <Button appearance="ghost" onClick={onHide}>
            <strong>Close</strong>
          </Button>
        </div>
      </div>
    </div>
  );
};
AddOnImagesModal.defaultProps = {
  show: false,
  custom_images: [],
};
AddOnImagesModal.propTypes = {
  custom_images: PropTypes.array.isRequired,
  onImgsChange: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  const { custom_images } = state.customServiceAddOn;
  return { custom_images };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onImgsChange: (images) => dispatch(changeBaseInfo("custom_images", images)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddOnImagesModal);
