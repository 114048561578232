import { Loader, PanelGroup, Panel, Tag } from "rsuite";

import { FlightData } from "@src/pages/Project/TripPlan/Components/OverviewFlight";

// ============================ SELECTORS ============================
import {
  getTransferFromUid,
  getAddOnFromUid,
} from "@src/selectors/Project/TripPlanner";
import { getAccommodationFromUidSelector } from "@src/selectors/Project/TripPlanner";

import { useHistory } from "react-router-dom";

import _ from "lodash";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { flightReservationStatus } from "@src/config/service";
import { getSetupFormDataSelector } from "@src/selectors/Project/TripPlanner/trip_setup_selectors";
import BookingModalTransfer from "./BookingModalTransfer";
import BookingModalAddon from "./BookingModalAddon";
import CoordinatorReservations from "./Modals/booking/coordinators";
import AccommodationReservation from "./Modals/booking/accommodation";
import RestaurantReservations from "./Modals/booking/restaurants";
import { GeneralSrvReservations } from "./Modals/booking/general_service";
import { CoachSrvReservations } from "./Modals/booking/coach";
import { TrainSrvReservations } from "./Modals/booking/train";
import { FerrySrvReservations } from "./Modals/booking/ferry";
import AdhocAccReservations from "./Modals/booking/adhoc_accommodation";
import CustomAccReservations from "./Modals/booking/custom_accommodation";
import { useQuery } from "@tanstack/react-query";
import { fetchBookStatus } from "@src/api";
import { APIFerryReservations } from "./Modals/booking/aferry_resevations";
import { ActivityReservations } from "./Modals/booking/activity_reservations";

const FlightReservation = ({ flight_status_data, flight, index }) => {
  return (
    <Panel
      className="CustomPanel reservation-panel ServicePanel ServicePanel--booking-version"
      header={
        <h5>
          {`Flight ${index}. Reservation Reference: ${flight_status_data.reference}`}
          <span className="reservation-status">Status:</span>
          {flight_status_data.status == "PE" ? (
            <Loader className="reservation-loader" />
          ) : (
            <Tag color={flight_status_data.status == "FA" ? "red" : "green"}>
              {flightReservationStatus?.[flight_status_data.status] ?? "N/A"}
            </Tag>
          )}
        </h5>
      }>
      <FlightData flight={flight} legKey="outbound" />
    </Panel>
  );
};
FlightReservation.propTypes = {
  flight_status_data: PropTypes.object.isRequired,
  flight: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

const BookingModal = () => {
  const history = useHistory();
  const [shouldPoll, setShouldPoll] = useState(true);

  const { adults, children, servicebundle, reference } = useSelector(
    (state) => {
      const { reference } = state.tripPlannerBookingModal;
      const { adults, children } = getSetupFormDataSelector(state);
      const servicebundle = state.tripPlannerInstanceData.servicebundle;
      return { adults, children, servicebundle, reference };
    }
  );

  const { data: reservationStatusData, isLoading } = useQuery({
    queryKey: ["booking_status", servicebundle?.uid],
    queryFn: () => fetchBookStatus(servicebundle?.uid),
    onSuccess: (data) => {
      if (data?.reservations_progress === "done") setShouldPoll(false);
    },
    refetchOnWindowFocus: false,
    enabled: !!servicebundle?.uid,
    refetchInterval: shouldPoll ? 2000 : false,
    refetchIntervalInBackground: true,
    retry: false,
  });
  const loading = isLoading;

  const apiFerryReservations =
    reservationStatusData?.["api_ferry_reservations"] ?? [];
  const activityReservations =
    reservationStatusData?.["activity_service_reservations"] ?? [];
  const flight_reservations =
    reservationStatusData?.["flight_reservations"] ?? [];
  const coach_reservations =
    reservationStatusData?.["coach_reservations"] ?? [];
  const ferry_reservations =
    reservationStatusData?.["ferry_reservations"] ?? [];
  const train_reservations =
    reservationStatusData?.["train_reservations"] ?? [];
  const accommodation_reservations =
    reservationStatusData?.["accommodation_reservations"] ?? [];
  const adhoc_acc_reservations =
    reservationStatusData?.["adhoc_acc_reservations"] ?? [];
  const custom_acc_reservations =
    reservationStatusData?.["custom_acc_reservations"] ?? [];
  const transfer_reservations =
    reservationStatusData?.["transfer_reservations"] ?? [];
  const addon_reservations =
    reservationStatusData?.["addon_reservations"] ?? [];
  const coordinator_reservations =
    reservationStatusData?.["coordinator_reservations"] ?? [];
  const restaurant_reservations =
    reservationStatusData?.["restaurant_reservations"] ?? [];
  const general_service_reservations =
    reservationStatusData?.["general_service_reservations"] ?? [];

  const { prebookAccData, accommodation_data, transfers_data, addon_data } =
    useSelector((state) => {
      const prebookAccData = state.tripPlannerPrebookAccInfo;
      const accommodation_data = {};
      (accommodation_reservations || []).forEach(
        (acc) =>
          (accommodation_data[acc.search_uid] = getAccommodationFromUidSelector(
            state,
            { uid: acc.search_uid }
          ))
      );

      const transfers_data = {};
      (transfer_reservations || []).forEach(
        (trf) =>
          (transfers_data[trf.search_uid] = getTransferFromUid(state, {
            search_uid: trf.search_uid,
          }))
      );

      const addon_data = {};
      (addon_reservations || []).forEach((addon) => {
        addon_data[addon.search_uid] = getAddOnFromUid(state, {
          search_uid: addon.search_uid,
        });
      });

      return { prebookAccData, accommodation_data, transfers_data, addon_data };
    });

  function handleGoToReservations() {
    const base_url = "/operation/reservations";

    const reservations_length = [
      flight_reservations.length,
      accommodation_reservations.length,
      adhoc_acc_reservations.length,
      custom_acc_reservations.length,
      transfer_reservations.length,
      addon_reservations.length,
    ].reduce((a, b) => a + b, 0);

    if (reservations_length > 1) {
      history.push(`${base_url}/multi-services/${reference}`);
    } else {
      history.push(`/operation/`);
    }
  }

  function renderTripInfo() {
    return (
      <div className="BookingModal__section trip-info">
        <h5 className="BookingModal__section__header">
          Trip Booking Reference: {reference ?? "N/A"}
        </h5>
        <div>
          <span>
            <strong>Pax: </strong>
          </span>
          <span color="blue">
            <strong>{`${adults} ${adults > 1 ? "Adults" : "Adult"}`}</strong>
          </span>
          {children > 0 && (
            <span>
              <strong>, Children: {children}</strong>
            </span>
          )}
        </div>
      </div>
    );
  }
  // TODO: This is temporary
  const flights_data = {};
  function renderFlightReservations() {
    if (!flight_reservations.length) return null;

    return (
      <div className="BookingModal__section flight-reservations">
        <h5 className="BookingModal__section__header">Flight Reservations</h5>
        <PanelGroup bordered>
          {flight_reservations.map((fl, idx) => {
            const flight = Object.values(flights_data).find(
              (f) => f.uid == fl.flight_search_uid
            );

            return (
              <FlightReservation
                key={idx}
                flight_status_data={fl}
                flight={flight}
                index={idx + 1}
              />
            );
          })}
        </PanelGroup>
      </div>
    );
  }
  function renderAccReservations() {
    if (!accommodation_reservations.length) return null;

    return (
      <div className="BookingModal__section accommodation-reservations">
        <h5 className="BookingModal__section__header">
          Accommodation Reservations
        </h5>
        {accommodation_reservations.map((acc, idx) => {
          const accommodation = Object.values(accommodation_data).find((a) => {
            return a.uid === acc.search_uid;
          });

          return (
            !!accommodation && (
              <AccommodationReservation
                key={idx}
                index={idx + 1}
                acc_status_data={acc}
                accommodation={accommodation}
                prebookData={prebookAccData[accommodation.destOrder]}
              />
            )
          );
        })}
      </div>
    );
  }
  function renderTrfReservations() {
    if (!transfer_reservations.length) return null;

    return (
      <div className="BookingModal__section transfer-reservations">
        <h5 className="BookingModal__section__header">Transfer Reservations</h5>
        {transfer_reservations.map((trf, idx) => {
          const transfer = transfers_data[trf.search_uid];
          return (
            <BookingModalTransfer
              key={idx}
              index={idx + 1}
              transfer={transfer}
              reservation_data={trf}
            />
          );
        })}
      </div>
    );
  }
  function renderAddOnReservations() {
    if (!addon_reservations.length) return null;

    return (
      <div className="BookingModal__section addon-reservations">
        <h5 className="BookingModal__section__header">Add Ons</h5>
        {addon_reservations.map((addon_reservation, idx) => {
          const add = _.flatten(Object.values(addon_data)).find(
            (add) => add.search_uid == addon_reservation.search_uid
          );

          return (
            <BookingModalAddon
              key={idx}
              addon={add}
              reservation={addon_reservation}
            />
          );
        })}
      </div>
    );
  }

  return (
    <div className="Modal BookingModal">
      <div className="Modal__card">
        <div className="Modal__card__header">
          <h5>Booking Proccess:</h5>
          <div className="Modal__card__header__actions">
            {!shouldPoll ? (
              <Tag color="blue">
                <strong>Done</strong>
              </Tag>
            ) : (
              <Loader className="reservation-loader" />
            )}
          </div>
        </div>
        <div className="Modal__card__body">
          {loading ? (
            <Loader vertical size="lg" center />
          ) : (
            <React.Fragment>
              {renderTripInfo()}
              {renderFlightReservations()}
              <APIFerryReservations
                apiFerryReservations={apiFerryReservations}
              />
              {renderAccReservations()}
              {renderTrfReservations()}
              {renderAddOnReservations()}
              <AdhocAccReservations reservations={adhoc_acc_reservations} />
              <CustomAccReservations reservations={custom_acc_reservations} />
              <CoordinatorReservations
                reservations={coordinator_reservations}
              />
              <RestaurantReservations reservations={restaurant_reservations} />
              <GeneralSrvReservations
                reservations={general_service_reservations}
              />
              <CoachSrvReservations reservations={coach_reservations} />
              <TrainSrvReservations reservations={train_reservations} />
              <FerrySrvReservations reservations={ferry_reservations} />
              <ActivityReservations reservations={activityReservations} />
            </React.Fragment>
          )}
        </div>
        <div className="Modal__card__actions">
          {loading || shouldPoll ? null : (
            <button className="Button" onClick={handleGoToReservations}>
              Go to Reservations
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
export default BookingModal;
