import _ from "lodash";
import PropTypes from "prop-types";
import React, { memo, useCallback } from "react";
import { Whisper, Dropdown, Popover, IconButton, Icon } from "rsuite";
import { useDispatch, useSelector } from "react-redux";
import { changeDistributionPeriods } from "@src/actions/Operation/CustomServices/AddOn";
import SuIcon from "@src/style/icon/components/SuIcon";
import { createUseStyles } from "react-jss";
import { tableHeadStyles } from "./Highlights";
import { cardStyles, tableStyles, variables } from "@src/jsssetup";
import { WhisperHelper } from "@src/components/common/ui_helpers";
import { Formik, Form } from "formik";
import {
  ExtendedSelectField,
  NormalInputField,
  NormalRangePicker,
} from "@src/components/forms";
import { distributionPeriodTemplate } from "@src/reducers/Operation/CustomServices/AddOn";

function RowActions(props) {
  const { idx, canRemove, onRemove, onAdd, onCopy } = props;

  const triggerRef = React.createRef();

  function handleSelectMenu(eventKey) {
    switch (eventKey) {
      case 1:
        onRemove(idx);
        break;
      case 2:
        onAdd(idx);
        break;
      case 3:
        onCopy(idx);
        break;
      default:
        break;
    }

    triggerRef.current.hide();
  }

  return (
    <Whisper
      trigger="click"
      triggerRef={triggerRef}
      placement="auto"
      speaker={
        <Popover full>
          <Dropdown.Menu onSelect={handleSelectMenu}>
            {canRemove && (
              <Dropdown.Item icon={<Icon icon="minus" />} eventKey={1}>
                Remove
              </Dropdown.Item>
            )}
            <Dropdown.Item icon={<Icon icon="plus" />} eventKey={2}>
              Add Below
            </Dropdown.Item>
            <Dropdown.Item icon={<Icon icon="copy" />} eventKey={3}>
              Copy Below
            </Dropdown.Item>
          </Dropdown.Menu>
        </Popover>
      }>
      <IconButton color="blue" icon={<Icon icon="chevron-down" />} size="xs">
        Actions
      </IconButton>
    </Whisper>
  );
}
RowActions.propTypes = {
  idx: PropTypes.number.isRequired,
  canRemove: PropTypes.bool.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired,
};

const styles = createUseStyles({
  DistributionPeriods: cardStyles.card,
  header: cardStyles.header,
  body: { ...cardStyles.body, padding: 0 },
  table: {
    width: "100%",
  },
  tableHead: {
    ...tableHeadStyles,
  },
  tableCell: {
    ...tableStyles.cell,
    padding: `calc(${variables.normal_gap} / 2)`,
  },
  nameCell: { ...tableStyles.cell, "& input": { width: "5rem" } },
  inventoryCell: { ...tableStyles.cell, "& input": { width: "4rem" } },
  dateCell: {
    ...tableStyles.cell,
    "& input": { width: "10rem", fontSize: "small", marginBottom: "5px" },
  },
  [`@media ${variables.media.smallscreen}`]: {
    nameCell: { "& input": { width: "unset" } },
  },
});
var DistributionPeriods = () => {
  const { distribution_periods } = useSelector(
    (state) => state.customServiceAddOn
  );

  const dispatch = useDispatch();
  const onChange = useCallback(({ distribution_periods }) =>
    dispatch(changeDistributionPeriods({ distribution_periods }))
  );

  function onAdd({ idx, distribution_periods }) {
    const periods = _.cloneDeep(distribution_periods);
    const newPeriod = {
      ...distributionPeriodTemplate,
      name: `Period ${idx + 2}`,
    };
    if (idx + 1 == periods.length) {
      periods.push(newPeriod);
    } else {
      periods.splice(idx + 1, 0, newPeriod);
    }
    return periods;
  }

  function onRemove({ idx, distribution_periods }) {
    const newPeriods = _.cloneDeep(distribution_periods);
    newPeriods.splice(idx, 1);
    return newPeriods;
  }

  function onCopy({ idx, distribution_periods }) {
    const newPeriods = _.cloneDeep(distribution_periods);
    const copiedPeriod = {
      ...newPeriods[idx],
      name: `${newPeriods[idx].name} (copy)`,
    };
    if (idx + 1 == newPeriods.length) {
      newPeriods.push(copiedPeriod);
    } else {
      newPeriods.splice(idx + 1, 0, copiedPeriod);
    }
    return newPeriods;
  }

  const classes = styles();
  return (
    <div className={classes.DistributionPeriods}>
      <h5 className={classes.header}>
        <span>
          <SuIcon icon="icon-icon_Address" size="lg" /> Distribution Preferences
        </span>
      </h5>
      <div className={classes.body}>
        <Formik
          initialValues={{ distribution_periods }}
          validate={(values) => {
            const { distribution_periods } = values;
            onChange({ distribution_periods });
          }}
          validateOnBlur={false}
          validateOnMount={false}>
          {({ values, setFieldValue }) => (
            <Form>
              <table className={classes.table}>
                <thead className={classes.tableHead}>
                  <tr>
                    <th>Name</th>
                    <th>Distribution/Operation</th>
                    <th>
                      Weekdays{" "}
                      <WhisperHelper msg="Days which the product is operating" />
                    </th>
                    <th>Stay</th>
                    <th>Inventory</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {values.distribution_periods.map((period, idx) => (
                    <tr key={idx}>
                      <td className={classes.nameCell}>
                        <NormalInputField
                          name={`distribution_periods.${idx}.name`}
                        />
                      </td>
                      <td className={classes.dateCell}>
                        <NormalRangePicker
                          from_key={`distribution_periods.${idx}.distribution_from`}
                          to_key={`distribution_periods.${idx}.distribution_to`}
                        />
                        <NormalRangePicker
                          from_key={`distribution_periods.${idx}.operation_from`}
                          to_key={`distribution_periods.${idx}.operation_to`}
                        />
                      </td>
                      <td className={classes.tableCell}>
                        <ExtendedSelectField
                          name={`distribution_periods.${idx}.weekdays`}
                          multi={true}
                          options={[
                            [1, "Monday"],
                            [2, "Tuesday"],
                            [3, "Wednesday"],
                            [4, "Thursday"],
                            [5, "Friday"],
                            [6, "Saturday"],
                            [7, "Sunday"],
                          ]}
                        />
                      </td>
                      <td className={classes.inventoryCell}>
                        <NormalInputField
                          name={`distribution_periods.${idx}.stay`}
                          type="number"
                        />
                      </td>
                      <td className={classes.inventoryCell}>
                        <NormalInputField
                          name={`distribution_periods.${idx}.inventory`}
                          type="number"
                        />
                      </td>
                      <td className={classes.tableCell}>
                        <RowActions
                          idx={idx}
                          canRemove={
                            (values?.distribution_periods ?? []).length > 1
                          }
                          onRemove={() => {
                            const { distribution_periods } = values;
                            setFieldValue(
                              "distribution_periods",
                              onRemove({ idx, distribution_periods })
                            );
                          }}
                          onAdd={() => {
                            const { distribution_periods } = values;
                            setFieldValue(
                              "distribution_periods",
                              onAdd({ idx, distribution_periods })
                            );
                          }}
                          onCopy={() => {
                            const { distribution_periods } = values;
                            setFieldValue(
                              "distribution_periods",
                              onCopy({ idx, distribution_periods })
                            );
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
DistributionPeriods = memo(DistributionPeriods);
export default DistributionPeriods;
