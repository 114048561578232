import {
  //=================FILTER======================
  TRIP_TEMPLATES_UPDATE_FILTER_FORM,
  TRIP_TEMPLATES_INITIALIZE_LIST_FILTER_FORM,
  TRIP_TEMPLATES_RESET_LIST_FILTER_FORM,
  TRIP_TEMPLATES_ONREQUEST_FETCH_LIST,
  TRIP_TEMPLATES_ONREQUEST_GENERIC_CHANGE,
} from "@src/actions/Project/OnRequest/types";

import { FORMAT_TRAVEL_SERVICES_FORM } from "@src/actions/Shares/types";

import { initialOnRequestFilterForm } from "@src/forms/Project/Archive/OnRequest";

import update from "immutability-helper";

import { formProcessor } from "@src/reducers/tools";
import { commonList } from "../common";

export const TripTemplatesListFilters = (
  state = initialOnRequestFilterForm,
  action
) => {
  switch (action.type) {
    case TRIP_TEMPLATES_INITIALIZE_LIST_FILTER_FORM:
      return initialOnRequestFilterForm;
    case TRIP_TEMPLATES_RESET_LIST_FILTER_FORM:
      return action.data;
    case TRIP_TEMPLATES_UPDATE_FILTER_FORM:
      return formProcessor(
        state,
        action.data.type,
        action.data.name,
        action.data.value
      );
    case FORMAT_TRAVEL_SERVICES_FORM:
      return update(state, { $merge: action.data });
    default:
      return state;
  }
};

export const newOnRequestList = (
  state = {
    ...commonList,
    limit: 6,
    filters: {
      reference: "",
      title__icontains: "",
      trip_date__gte: "",
      trip_date__lte: "",
      destination__icontains: "",
      creator__icontains: "",
      status: "",
      stay: "",
    },
  },
  action
) => {
  switch (action.type) {
    case TRIP_TEMPLATES_ONREQUEST_FETCH_LIST: {
      const { data } = action;
      return { ...state, ...data };
    }
    case TRIP_TEMPLATES_ONREQUEST_GENERIC_CHANGE: {
      const { key, value } = action;
      if (key !== "filters") {
        return { ...state, [key]: value };
      } else {
        return { ...state, [key]: value, page: 1 };
      }
    }
    default:
      return state;
  }
};
