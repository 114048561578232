import { formatDate } from "@src/tools/date_tools";

import {
  BookChecker,
  Bookability,
  ServicePanelGhost,
} from "../Components/ItineraryOverviewCommon";

import {
  clearDestAccs,
  accStorePollResults,
} from "@src/actions/Project/TripPlanner";

import { maxAccPollCount } from "@src/actions/Project/TripPlanner";

import _ from "lodash";

import { connect, useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  getHotelSelectedRooms,
  getSelectedAccSelector,
  getSetupFormDataSelector,
  isAccDateCompatibleSelector,
  isAccRefundable,
} from "@src/selectors/Project/TripPlanner";
import { WhisperWrapper } from "@src/components/common/ui_helpers";
import { TripModeHoc } from "../Hydrators";
import { createUseStyles } from "react-jss";
import { variables } from "@src/jsssetup";
import { DateTime } from "luxon";
import LeanAuthHoc from "@src/components/authorization/LeanAuthHoc";
import { ItinOverviewContext } from "./ItineraryOverview";
import { InlineIcon } from "@iconify/react";
import RatingPanel from "@src/components/common/RatingPanel";
import LoadingBar from "react-top-loading-bar";
import { handleNonValidImgFn } from "@src/tools/common_tools";
import { useMutation, useQuery } from "@tanstack/react-query";
import { initAccSearchNew, pollAccSearchNew } from "@src/api";
import { getUserSourceEntitySelector } from "@src/selectors/Shared/user_selectors";
import { AccPrice } from "../Components/api_srvs/accommodation";
import { toast } from "react-toastify";

const providersStyles = createUseStyles({
  title: { color: variables.colors.text.colored, fontWeight: "600" },
});
var Providers = ({ rooms }) => {
  const classes = providersStyles();
  const providers = [...new Set((rooms || []).map((r) => r.provider))];
  return (
    <span>
      <span className={classes.title}>{`${
        providers.length === 1 ? "Provider" : "Providers"
      }: `}</span>
      <span>{providers.join(", ")}</span>
    </span>
  );
};
Providers.defaultProps = { rooms: [] };
Providers.propTypes = { rooms: PropTypes.array.isRequired };
Providers = LeanAuthHoc(Providers, { allowed_groups: ["member"] });

const accommodationDataHeaderStyles = createUseStyles({
  AccommodationDataHeader: (props) => ({
    display: "grid",
    gridTemplateColumns: props.services_collapsed
      ? "max-content 1fr max-content"
      : "1fr max-content",
    alignItems: "center",
    gridGap: variables.half_gap,
    position: "relative",
    padding: props.services_collapsed && [
      variables.half_gap,
      variables.half_gap,
      variables.half_gap,
      variables.double_gap,
    ],
    borderRadius: props.services_collapsed && variables.half_gap,
    boxShadow: props.services_collapsed && variables.shadows.normalShadow,
  }),
  headerTitle: (props) => ({
    fontSize: "medium",
    fontWeight: props.services_collapsed ? "bold" : "600",
  }),
  iconCntr: {
    display: "grid",
    placeContent: "center",
    width: "2rem",
    height: "2rem",
    borderRadius: "50%",
    background: variables.colors.easy.orange,
    padding: variables.half_gap,
  },
  icon: { fontSize: "large", color: variables.colors.text.light },
  actionBtns: {
    display: "grid",
    gridAutoFlow: "column",
    gridGap: variables.half_gap,
    placeItems: "center",
  },
  expandIconCntr: {
    display: "grid",
    placeContent: "center",
    width: "1.5rem",
    height: "1.5rem",
    borderRadius: "50%",
    background: variables.colors.easy.orange,
    padding: variables.half_gap,
    cursor: "pointer",
  },
  expandIcon: { fontSize: "large", color: variables.colors.text.light },
});
const AccommodationDataHeader = ({
  header,
  children,
  loadingBar,
  services_collapsed,
  toggleSelfCollapse,
}) => {
  const classes = accommodationDataHeaderStyles({ services_collapsed });

  return (
    <div className={classes.AccommodationDataHeader}>
      {services_collapsed && (
        <span className={classes.iconCntr}>
          <InlineIcon className={classes.icon} icon="ic:baseline-home-work" />
        </span>
      )}
      <span className={classes.headerTitle}>{header}</span>
      <div className={classes.actionBtns}>
        {children}
        {!services_collapsed && loadingBar}
        <div className={classes.expandIconCntr}>
          <InlineIcon
            className={classes.expandIcon}
            icon={
              services_collapsed ? "ri:arrow-down-s-line" : "ri:arrow-up-s-line"
            }
            onClick={toggleSelfCollapse}
          />
        </div>
      </div>
    </div>
  );
};
AccommodationDataHeader.propTypes = {
  header: PropTypes.string.isRequired,
  children: PropTypes.object,
  loadingBar: PropTypes.object,
  services_collapsed: PropTypes.bool.isRequired,
  toggleSelfCollapse: PropTypes.func,
};

const accommodationDataStyles = createUseStyles({
  AccommodationData: (props) => ({
    display: "grid",
    gridTemplateColumns: props.services_collapsed ? "1fr" : "8rem 1fr",
    gridTemplateRows: props.services_collapsed ? "unset" : "10rem",
    gridGap: variables.normal_gap,
    maxHeight: "10rem",
  }),
  cardContent: {
    display: "grid",
    padding: variables.normal_gap,
    gridGap: variables.half_gap,
    boxShadow: variables.shadows.heavyShadow,
    borderRadius: `calc(${variables.normal_gap} / 2)`,
    textTransform: "capitalize",
  },
  img: {
    width: "100%",
    height: "100%",
    borderRadius: "5px",
    objectFit: "cover",
    boxShadow: variables.shadows.heavyShadow,
  },
  metadata: {
    display: "grid",
    gridTemplateColumns: "1fr max-content 1fr",
    alignItems: "center",
    gridGap: variables.normal_gap,
  },
  title: { color: variables.colors.text.colored, fontWeight: "bold" },
  attr: { whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" },
  ratingCntr: {
    display: "grid",
    gridColumn: "span 3",
    gridTemplateColumns: "max-content max-content",
    gridGap: variables.half_gap,
  },
  refundCntr: {
    display: "grid",
    gridTemplateColumns: "max-content max-content",
    gridGap: variables.half_gap,
    alignItems: "center",
    placeContent: "center",
  },
  refundText: (props) => ({ color: props.refundable ? "green" : "red" }),
  refundIcon: (props) => ({
    fontSize: "large",
    color: props.refundable ? "green" : "red",
  }),
});
export const AccommodationData = ({
  destOrder,
  accommodation,
  rooms,
  meta,
  currentStep,
  header,
  loadingBar,
  children,
  services_collapsed,
  toggleSelfCollapse,
}) => {
  function getImg() {
    return meta?.images?.[0]?.url ?? "";
  }

  function getRating() {
    const rating = meta?.rating ?? "N/A";

    if (isNaN(rating)) {
      return rating;
    }

    return parseInt(rating, 10);
  }

  var refundable = isAccRefundable({
    acc: accommodation,
    prebook_info: accommodation?.prebook_info,
  });
  const classes = accommodationDataStyles({ refundable, services_collapsed });
  var boards = "N/A";
  if (rooms.length) {
    boards = rooms.map((r) => r.board_display);
    boards = [...new Set(boards)].map((r, idx) => <span key={idx}>{r}</span>);
  }

  const uniqueRooms = [...new Set(rooms.map((r) => r.name))];

  return (
    <div className={classes.AccommodationData}>
      {services_collapsed ? (
        <AccommodationDataHeader
          header={header}
          loadingBar={loadingBar}
          services_collapsed={services_collapsed}
          toggleSelfCollapse={toggleSelfCollapse}>
          {children}
        </AccommodationDataHeader>
      ) : (
        <React.Fragment>
          <img
            className={classes.img}
            alt=""
            src={getImg()}
            onError={(event) => handleNonValidImgFn(event, "ACC")}
          />
          <div className={classes.cardContent}>
            <AccommodationDataHeader
              header={header}
              loadingBar={loadingBar}
              services_collapsed={services_collapsed}
              toggleSelfCollapse={toggleSelfCollapse}>
              {children}
            </AccommodationDataHeader>
            <div className={classes.metadata}>
              <span className={classes.ratingCntr}>
                <span className={classes.title}>Rating: </span>
                <RatingPanel
                  rating={getRating()}
                  color={"#F0B040"}
                  fontSize="medium"
                  showEmpty={true}
                />
              </span>
              <span className={classes.attr} title={meta?.name ?? "N/A"}>
                <span className={classes.title}>Name: </span>
                {meta?.name ?? "N/A"}
              </span>
              <Providers rooms={rooms} />
              <span>
                {refundable ? (
                  <span className={classes.refundCntr}>
                    <InlineIcon
                      className={classes.refundIcon}
                      icon="mdi:calendar-check"
                    />
                    <strong className={classes.refundText}>Refundable</strong>
                  </span>
                ) : (
                  <span className={classes.refundCntr}>
                    <InlineIcon
                      className={classes.refundIcon}
                      icon="mdi:calendar-check"
                    />
                    <strong className={classes.refundText}>
                      Non Refundable
                    </strong>
                  </span>
                )}
              </span>
              <span className={classes.attr}>
                <span className={classes.title}>{`${
                  rooms.length !== 1 ? "Rooms" : "Room"
                } (${rooms.length}): `}</span>
                <span title={uniqueRooms.join(", ")}>
                  {rooms.length ? uniqueRooms.join(", ") : <span>N/A</span>}
                </span>
              </span>
              <span>
                <span className={classes.title}>{`${
                  rooms.length !== 1 ? "Board" : "Boards"
                }: `}</span>
                <span>{boards}</span>
              </span>
              {currentStep === 4 && refundable === 1 && (
                <span>
                  <strong>Last free Cxl Date: </strong>
                  <Bookability
                    srv={accommodation}
                    srvType="ACC"
                    prebookData={accommodation.prebook_info || {}}
                    destOrder={destOrder}
                  />
                </span>
              )}
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
AccommodationData.propTypes = {
  accommodation: PropTypes.object.isRequired,
  header: PropTypes.string,
  destOrder: PropTypes.number,
  meta: PropTypes.object.isRequired,
  rooms: PropTypes.array.isRequired,
  currentStep: PropTypes.number.isRequired,
  loadingBar: PropTypes.object,
  children: PropTypes.object,
  services_collapsed: PropTypes.bool.isRequired,
  toggleSelfCollapse: PropTypes.func,
};

export function getPrebookReadyRooms({ prebook_info }) {
  if (_.isEmpty(prebook_info)) return [];
  return prebook_info?.rooms || [];
}

function getAccInitSearchPayload({
  destination,
  source_entity,
  nationality,
  accommodationPaxSetup,
}) {
  var check_in = DateTime.fromISO(destination.checkIn).toISODate();
  var check_out = DateTime.fromISO(destination.checkOut).toISODate();

  const dType = destination.type;
  const dId = destination.id;
  const query = {
    id: [window.btoa(`lv${dType.split("lvl")[1]}__${dId}`)],
    type: "destination",
  };

  // We need to add this later
  // if (
  //   _.get(priorities, `${dest.order}.behaviour`) == "RE" &&
  //     _.get(priorities, `${dest.order}.hotels`, []).length == 1
  // ) {
  //   const hotel = _.get(priorities, `${dest.order}.hotels.0`);
  //   query.id = [window.btoa(hotel.metadata.id)];
  //   query.type = "hotel";
  // }

  return {
    lang: "en",
    check_in,
    check_out,
    rooms: accommodationPaxSetup,
    query,
    nationality,
    destOrder: destination.order,
    market_type: "B2B",
    source_entity,
  };
}

function getAccommodationPollPayload({ sessionId, accommodationPrefs }) {
  const payload = {
    sessionId: sessionId,
    best: true,
    rating: accommodationPrefs.rating.map((r) => parseInt(r, 10)),
    board: accommodationPrefs.board,
    bedding: accommodationPrefs.bedding,
    room_type: accommodationPrefs.type,
    result_id: null, //acc_id,
    market_type: "B2B",
  };

  // if (priority_ids.length) {
  //   payload["priority_ids"] = priority_ids;
  //   payload["priority_behaviour"] = priority_behaviour;
  // }

  return payload;
}

const overviewAccommodationStyles = createUseStyles({
  OverviewAccommodation: { display: "grid" },
  actionBtnsCntr: {
    display: "grid",
    gridAutoFlow: "column",
    gridGap: variables.half_gap,
  },
});
const OverviewAccommodation = ({
  eventType,
  destOrder,
  services_collapsed,
  withPrice,
  toggleSelfCollapse,
}) => {
  const [sessionId, setSessionId] = useState(null);
  const [shouldPoll, setShouldPoll] = useState(false);
  const [pollCount, setPollCount] = useState(0);
  const [progress, setProgress] = useState(0);

  const { onShowViewAllAccs } = useContext(ItinOverviewContext);

  const classes = overviewAccommodationStyles();

  const dispatch = useDispatch();
  const onStoreResults = useCallback(
    (destOrder, results, prefs) =>
      dispatch(accStorePollResults(destOrder, results, prefs)),
    [dispatch]
  );
  const onClearDestAccs = useCallback(
    (destOrder) => dispatch(clearDestAccs(destOrder)),
    [dispatch]
  );

  const {
    currentStep,
    source_entity,
    nationality,
    accommodationPaxSetup,
    accommodationPrefs,
    accommodation,
    destination,
  } = useSelector((state) => {
    const { nationality } = getSetupFormDataSelector(state);

    const currentStep = state.tripPlannerCurrentStep;
    const source_entity = getUserSourceEntitySelector(state);
    const accommodationPaxSetup = state.tripPlannerAccPax;
    const accommodationPrefs = state.tripPlannerAccPref;
    const accommodation = getSelectedAccSelector(state, { destOrder });

    const destination = state.tripPlannerDestinations.find(
      (d) => d.order == destOrder
    );

    return {
      currentStep,
      source_entity,
      nationality,
      accommodationPaxSetup,
      accommodationPrefs,
      accommodation,
      destination,
    };
  });

  var sessionIdDecoded = "";
  if (!!accommodation)
    sessionIdDecoded = window.atob(accommodation?.session_id);
  if (!!sessionId) sessionIdDecoded = window.atob(sessionId);

  var sessionTimestamp = null;
  var sessionExpired = false;
  var remainingMinutes = 0;

  if (sessionIdDecoded.includes("___")) {
    sessionTimestamp = sessionIdDecoded.split("___")?.[2];
    const diff = DateTime.fromISO(sessionTimestamp, { zone: "utc" })
      .diffNow("minutes")
      .as("minutes");

    remainingMinutes = parseInt(30 - Math.abs(diff), 10);
    sessionExpired = diff < -30;
  }

  const initSearchPayload = getAccInitSearchPayload({
    destination,
    source_entity,
    nationality,
    accommodationPaxSetup,
  });

  const { isDateCompatible } = useSelector((state) => {
    const isDateCompatible = isAccDateCompatibleSelector(state, {
      extremal: destOrder == 1,
      destOrder: destOrder - 1,
      returnExtremal: false,
      checkIn:
        accommodation?.operational_data?.check_in ?? initSearchPayload.check_in,
    });
    return { isDateCompatible };
  });

  const initSearchMutation = useMutation({
    mutationFn: ({ payload, currentAccommodation }) => {
      // If there is an accommodation selected clearing it from the state
      // will trigger the useQuery to initiate the hotel search
      if (!!currentAccommodation) {
        onClearDestAccs(destOrder);
        return new Promise((resolve) => resolve());
      }
      return initAccSearchNew(payload);
    },
    onSuccess: (data) => {
      setSessionId(data.data.session_id);
      setShouldPoll(true);
    },
    onError: () => {
      toast.error("Could not initiate accommodation search session.", {
        autoClose: 5000,
      });
    },
  });

  // Initiate accommodation search session
  useQuery({
    queryKey: [
      "accommodation_trip_dest_init_search",
      destOrder,
      JSON.stringify(initSearchPayload),
    ],
    queryFn: () => {
      onClearDestAccs(destOrder);
      return initAccSearchNew(initSearchPayload);
    },
    onSuccess: (data) => {
      if (data?.data?.session_id) {
        setSessionId(data.data.session_id);
        setShouldPoll(true);
      }
    },
    refetchOnWindowFocus: false,
    refetchOnMount: "always",
    enabled: !accommodation,
  });

  const pollPayload = getAccommodationPollPayload({
    sessionId,
    accommodationPrefs,
  });

  // Accommodation Polling
  useQuery({
    queryKey: ["accommodation_trip_dest_poll", sessionId],
    queryFn: () =>
      pollAccSearchNew({
        sessionId,
        best: pollPayload.best,
        rating: pollPayload.rating,
        board: pollPayload.board,
        bedding: pollPayload.bedding,
        room_type: pollPayload.room_type,
        result_id: pollPayload.room_id,
      }),
    onSettled: () => {
      if (pollCount >= maxAccPollCount) {
        setShouldPoll(false);
        setPollCount(0);
      } else {
        setPollCount((p) => p + 1);
      }
    },
    onSuccess: (data) => {
      const results = data?.data?.results ?? [];
      if (results.length > 0)
        onStoreResults(destOrder, results, accommodationPrefs);

      if (data?.data?.status == "done") {
        setShouldPoll(false);
        setPollCount(0);
      }
    },
    onError: () => {
      setShouldPoll(false);
      setPollCount(0);
    },
    enabled: !!sessionId,
    refetchInterval: shouldPoll ? 1000 : false,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: false,
    retry: true,
  });

  function getRooms() {
    // In case we have prebook information and we are in the final step.
    if (currentStep == 4 && !_.isEmpty(accommodation.prebook_info))
      return getPrebookReadyRooms({ prebook_info: accommodation.prebook_info });

    return getHotelSelectedRooms({ acc: accommodation });
  }

  // Set search progress bar
  useEffect(() => {
    if (!shouldPoll) {
      setProgress(100);
      return;
    }

    var sProgress = parseInt(
      ((pollCount / maxAccPollCount) * 100).toFixed(),
      10
    );
    setProgress(sProgress);
  }, [shouldPoll, pollCount]);

  // Poll if there is a session but there isn't a hotel connected.
  useEffect(() => {
    if ((!accommodation || _.isEmpty(accommodation)) && sessionId) {
      setPollCount(0);
      setShouldPoll(true);
    }
  }, []);

  const loadingBar = (
    <LoadingBar
      color={variables.colors.easy.orange}
      progress={progress}
      containerStyle={{
        position: "absolute",
        height: "5px",
        top: "100%",
        zIndex: 2,
      }}
      style={{ borderRadius: "10px" }}
      onLoaderFinished={() => setProgress(0)}
      shadow={false}
    />
  );

  function getTitle() {
    const cInDt = DateTime.fromISO(accommodation?.operational_data?.check_in);
    const cOutDt = DateTime.fromISO(accommodation?.operational_data?.check_out);
    const stay = Math.floor(cOutDt.diff(cInDt, "days").as("days"));

    const cIn = cInDt.toLocaleString(DateTime.DATE_MED);
    const cOut = cOutDt.toLocaleString(DateTime.DATE_MED);

    var event = "";
    if (eventType) event = " " + _.startCase(eventType);

    return `Accommodation${event}: ${cIn} to ${cOut} (${stay} ${
      stay == 1 ? "Night" : "Nights"
    }) ${shouldPoll ? `Search progress: (${progress}%)` : ""}`;
  }

  const finallyFailed = !shouldPoll && _.isEmpty(accommodation);
  const showGhost = (_.isEmpty(accommodation) && shouldPoll) || finallyFailed;

  const isRefundable = isAccRefundable({
    acc: accommodation,
    prebook_info: accommodation?.prebook_info,
  });

  return showGhost ? (
    <React.Fragment>
      {currentStep === 4 && <div />}
      <ServicePanelGhost
        failed={finallyFailed}
        searchProgress={progress}
        service_type="ACC"
        title={
          finallyFailed
            ? `Failed to find accommodation for ${DateTime.fromISO(
                initSearchPayload.check_in
              ).toLocaleString(
                DateTime.DATE_MED_WITH_WEEKDAY
              )} to ${DateTime.fromISO(
                initSearchPayload.check_out
              ).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}`
            : `Searching progress: (${progress}%) Please wait...`
        }
        failHolder={
          finallyFailed ? (
            <span>
              You can try changing the dates in the{" "}
              <strong>Itinerary Setup</strong> step.
            </span>
          ) : null
        }
        actions={
          finallyFailed && currentStep < 4
            ? [
                {
                  title: "Research",
                  onClick: () => {
                    initSearchMutation.mutate({
                      payload: initSearchPayload,
                      currentAccommodation: accommodation,
                    });
                  },
                },
              ]
            : []
        }
      />
    </React.Fragment>
  ) : (
    <React.Fragment>
      {currentStep === 4 && (
        <BookChecker
          uid={accommodation?.uid}
          srv_type="ACC"
          optionable={isRefundable}
        />
      )}
      <div className={classes.OverviewAccommodation}>
        <AccommodationData
          destOrder={destOrder}
          accommodation={accommodation}
          meta={accommodation.metadata}
          rooms={getRooms()}
          loadingBar={loadingBar}
          currentStep={currentStep}
          header={getTitle()}
          services_collapsed={services_collapsed}
          toggleSelfCollapse={toggleSelfCollapse}>
          <span className={classes.actionBtnsCntr}>
            {sessionExpired ? (
              <span className="Tag" data-danger="true" data-size="xs">
                Session Expired
              </span>
            ) : (
              <span className="Tag" data-success="true" data-size="xs">
                <strong>{remainingMinutes} Minutes remaining</strong>
              </span>
            )}
            {currentStep < 4 && (
              <WhisperWrapper
                msg={
                  isDateCompatible
                    ? null
                    : "Flight arrival date does not much with accommodation check in date. Press Research to fix this."
                }>
                <button
                  className="Button"
                  data-size="xs"
                  data-danger={!isDateCompatible}
                  data-ghost={isDateCompatible}
                  onClick={() => {
                    initSearchMutation.mutate({
                      payload: initSearchPayload,
                      currentAccommodation: accommodation,
                    });
                  }}>
                  Research
                </button>
              </WhisperWrapper>
            )}
            {shouldPoll || currentStep == 4 || sessionExpired ? null : (
              <button
                className="Button"
                data-size="xs"
                onClick={() =>
                  onShowViewAllAccs({
                    destOrder,
                    session_id: accommodation.session_id,
                  })
                }>
                View All
              </button>
            )}
          </span>
        </AccommodationData>
      </div>
      {services_collapsed || !withPrice ? (
        <div />
      ) : (
        <AccPrice
          priceType="buying"
          currentStep={currentStep}
          destination={destination}
          accFailed={false}
          accommodation={accommodation}
          accSearching={shouldPoll}
          onAccDetails={null}
        />
      )}
    </React.Fragment>
  );
};
OverviewAccommodation.defaultProps = {
  services_collapsed: false,
  withPrice: true,
};
OverviewAccommodation.propTypes = {
  eventType: PropTypes.oneOf(["checkIn", "checkOut"]),
  destOrder: PropTypes.number.isRequired,
  services_collapsed: PropTypes.bool.isRequired,
  withPrice: PropTypes.bool,
  toggleSelfCollapse: PropTypes.func,
};

export default OverviewAccommodation;
