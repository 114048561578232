import { variables } from "@src/jsssetup";
import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";

export const getDateTitle = (startDate, endDate) => {
  const formatDate = (date) => {
    const options = { day: "numeric", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-US", options).replace(",", "");
  };
  if (!startDate && !endDate) return "All Reservations";
  return `
  ${formatDate(new Date(startDate))} - ${formatDate(new Date(endDate))}`;
};

const statsStyles = createUseStyles({
  GeneralStats: {
    display: "grid",
    gridTemplateRows: "repeat(3, max-content)",
  },

  title: {
    display: "grid",
    gridColumn: "1 span",
    alignSelf: "start",
    padding: variables.half_gap,
    width: "100%",
    borderRadius: "5px",
    textAlign: "center",
    color: "white",
    backgroundColor: "#9A9A9A",
    fontFamily: "futura,sans-serif",
  },
  period: {
    display: "grid",
    gridTemplateRows: "repeat(3, max-content)",
    padding: variables.half_gap,
    gridGap: variables.half_gap,
    textAlign: "center",
  },
  date: {
    display: "grid",
    gridTemplateColumns: "repeat(2, max-content)",
    justifyContent: "center",
    gap: variables.half_gap,
    paddingTop: variables.normal_gap,
    fontFamily: "futura,sans-serif",
  },
  dateLabel: { display: "grid" },
  dateValue: { display: "grid" },
  divider: {
    justifySelf: "center",
    height: "0%",
    border: `1px solid rgba(0, 0, 0, 0.1)`,
    gridColumn: "1 span",
    width: "30%",
  },
  reservation: {
    display: "grid",
    justifySelf: "center",
    gridTemplateRows: "repeat(2, max-content)",
  },
  label: {
    display: "grid",
    justifyContent: "center",
    paddingBottom: variables.half_gap,
    fontFamily: "futura,sans-serif",
    fontSize: "small ",
    height: "15%",
  },
});

export const GeneralStats = ({ title, tableTitle, startDate, endDate }) => {
  const classes = statsStyles();

  return (
    <div className={classes.GeneralStats}>
      <div className={classes.title}>
        <span>
          <strong>{title}</strong>
        </span>
      </div>
      <div className={classes.period}>
        <div className={classes.date}>
          <div className={classes.dateLabel}>
            <strong></strong>
          </div>
          <div className={classes.dateValue}>
            {getDateTitle(startDate, endDate)}
          </div>
        </div>
        <div className={classes.divider}></div>
        <div className={classes.reservation}>
          <div className={classes.label}>
            <label>{tableTitle}</label>
          </div>
        </div>
      </div>
    </div>
  );
};
GeneralStats.propTypes = {
  title: PropTypes.string.isRequired,
  tableTitle: PropTypes.string.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
};
