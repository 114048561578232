import _ from "lodash";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React from "react";

export const ExtremalPoint = ({ pointType = "origin", point }) => {
  return (
    <div className="EasyAI__extremalPoint">
      <span>
        <strong>{_.startCase(pointType)}: </strong>
      </span>
      <span>{point?.name_en ?? ""}</span>
      <span>
        <strong>{pointType === "origin" ? "Departure" : "Return"}: </strong>
      </span>
      <span>
        {DateTime.fromISO(point.date).toLocaleString(
          DateTime.DATE_MED_WITH_WEEKDAY
        )}
      </span>
    </div>
  );
};
ExtremalPoint.defaultProps = {
  pointType: "origin",
};
ExtremalPoint.propTypes = {
  point: PropTypes.object.isRequired,
  pointType: PropTypes.oneOf(["origin", "return"]).isRequired,
};
