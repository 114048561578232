import TransTxt from "@src/components/common/SxFormatMessage";

import { initialColumnsProcessor } from "./../Columns/policyCommonColumns";
import React from "react";

export const initialOneToOnePolicyColumnsSetUp = {
  basic: {
    value: ["policy_name", "pax", "distribution_period", "rounding_digits"],
    choices: [
      {
        key: "policy_name",
        disabled: true,
        display_name: <TransTxt id="policy_name" startCase />,
      },
      {
        key: "pax",
        disabled: false,
        display_name: <TransTxt id="pax" startCase />,
      },
      {
        key: "distribution_period",
        disabled: false,
        display_name: <TransTxt id="distribution_period" startCase />,
      },
      {
        key: "reservation_period",
        disabled: false,
        display_name: <TransTxt id="reservation_period" startCase />,
      },
      {
        key: "rounding_digits",
        disabled: false,
        display_name: "Rounding Digits",
      },
    ],
  },
  sales_partners: {
    value: ["sales_partners_categories", "company_name"],
    choices: [
      {
        key: "sales_partners_categories",
        disabled: false,
        display_name: <TransTxt id="sales_partners_categories" startCase />,
      },
      {
        key: "company_name",
        disabled: false,
        display_name: <TransTxt id="company_name" startCase />,
      },
    ],
  },
  services: {
    value: ["service_categories", "service_type"],
    choices: [
      {
        key: "service_categories",
        disabled: false,
        display_name: <TransTxt id="service_categories" startCase />,
      },
      {
        key: "service_type",
        disabled: false,
        display_name: <TransTxt id="service_type" startCase />,
      },
    ],
  },
  stage: {
    value: ["status"],
    choices: [
      {
        key: "created",
        disabled: false,
        display_name: <TransTxt id="created" startCase />,
      },
      {
        key: "edited",
        disabled: false,
        display_name: <TransTxt id="edited" startCase />,
      },
      {
        key: "creator",
        disabled: false,
        display_name: <TransTxt id="creator" startCase />,
      },
      {
        key: "status",
        disabled: true,
        display_name: <TransTxt id="status" startCase />,
      },
    ],
  },
};

export const initialOneToOnePolicyColumnsProcessor = initialColumnsProcessor;

export const initialOneToOnePolicyColumnsMapping = {
  policy_name: {
    key: "policy_name",
    fixed: "left",
    width: 150,
  },
  pax: {
    key: "pax",
    fixed: "left",
  },
  distribution_period: {
    key: "distribution_period",
    width: 200,
  },
  reservation_period: {
    key: "reservation_period",
    width: 200,
  },
  rounding_digits: {
    key: "rounding_digits",
    width: 200,
  },
  sales_partners_categories: {
    key: "sales_partners_categories",
    width: 200,
  },
  company_name: {
    key: "company_name",
    width: 200,
  },
  service_categories: {
    key: "service_categories",
    width: 200,
  },
  service_type: {
    key: "service_type",
    width: 150,
  },
  created: {
    key: "created",
    width: 170,
  },
  edited: {
    key: "edited",
    width: 170,
  },
  creator: {
    key: "creator",
    width: 170,
  },
  status: {
    key: "status",
    fixed: "right",
  },
};
