import langDictionaries from "./lang_dictionaries";
import _ from "lodash";

const LANGUAGE = "zh";

var langZhDictionary = {};

langDictionaries.forEach((langDictionary) => {
  const obj = _.get(langDictionary, LANGUAGE, {});
  Object.entries(obj).forEach(([key, value]) => {
    langZhDictionary[key] = value;
  });
});
export default langZhDictionary;
