import { B2C_TRANSFERS_LIST_FILTER_CHANGE } from "@src/actions/Operation/Reservations/SingleServices/Transfers/types";
import { commonList } from "../../../../common";

export const initialB2CTransfersList = {
  ...commonList,
  displayLength: 100,
  filters: {
    reference: "",
    external_reference: "",
    origin_name__icontains: "",
    destination_name__icontains: "",
    creator__icontains: "",
    creator_email: "",
    created__gte: "",
    created__lte: "",
    status: "",
    tags: "",
    page: 1,
    limit: 20,
    accommodation_meta__name__icontains: "",
    room_number: "",
    room_id: "",
    check_in_from: "",
    check_in_to: "",
    check_out_from: "",
    check_out_to: "",
    stay: "",
    provider__icontains: "",
    provider__reference: "",
    guests_number: "",
    room_leader_name__icontains: "",
    option_booking: "",
    edited__gte: "",
    edited__lte: "",
    is_voucher_sent: "",
    non_refundable: "",
    accommodation_meta__address__icontains: "",
    ordering: "",
    market_type: "B2C",
  },
};
export const b2cTransfersReservationsList = (
  state = initialB2CTransfersList,
  action
) => {
  switch (action.type) {
    case B2C_TRANSFERS_LIST_FILTER_CHANGE: {
      const { filters } = action;
      return { ...state, filters: { ...state.filters, ...filters } };
    }
    default:
      return state;
  }
};
