import {
  pollContentGenerationSession,
  startContentGenerationSession,
} from "@src/api/ai";
import { getUserSourceEntitySelector } from "@src/selectors/Shared/user_selectors";
import { useMutation } from "@tanstack/react-query";
import { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { TripPlanContext } from "../Project/TripPlan/TripPlanner";
import axios from "axios";
import { SISI2_API_URL } from "@src/api";
export function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}
import Lottie from "lottie-web";

// ============================= easy Ai =============================
export function useAIContentGeneration({ context = "content_generator" }) {
  const maxPolls = 100;
  const source_entity = useSelector((state) =>
    getUserSourceEntitySelector(state)
  );

  const [progressIdx, setProgressIdx] = useState(0);
  const [contentSessionId, setContentSessionId] = useState(null);
  const [contentGenerationStatus, setContentGenerationStatus] = useState(null);

  const [content, setContent] = useState("");
  const [streamContent, setStreamContent] = useState("");

  const initAutoContentSesssionMutation = useMutation({
    mutationFn: async ({ messages, max_tokens = 200 }) =>
      startContentGenerationSession({
        context,
        source_entity,
        messages,
        max_tokens,
      }),
    onSuccess: (data) => {
      setContentSessionId(data?.data?.session_id);
      setContentGenerationStatus(data?.data?.status);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const pollContentSessionMutation = useMutation({
    mutationFn: async ({ session_id, idx }) =>
      pollContentGenerationSession(session_id),
    onSuccess: (data, variables) => {
      var { idx, session_id } = variables;
      setContent(data?.data?.message);
      setStreamContent(data?.data?.message);
      setContentGenerationStatus(data?.data?.status);
      if (idx <= maxPolls && contentGenerationStatus === "processing") {
        setProgressIdx(idx++);
        pollContentSessionMutation.mutate({ session_id, idx });
      } else if (idx > maxPolls || contentGenerationStatus === "finished") {
        setContentSessionId(null);
        setContentGenerationStatus(null);
        setStreamContent("");
        setProgressIdx(0);
      }
    },
    onError: (error, variables) => {
      var { idx, session_id } = variables;
      if (idx <= maxPolls && contentGenerationStatus === "processing") {
        setProgressIdx(idx++);
        pollContentSessionMutation.mutate({ session_id, idx });
      } else if (idx > maxPolls || contentGenerationStatus === "finished") {
        setContentSessionId(null);
        setContentGenerationStatus(null);
        setStreamContent("");
        setProgressIdx(0);
      }
    },
  });

  // Polling for content generation
  useEffect(() => {
    if (!contentSessionId) return null;
    pollContentSessionMutation.mutate({ session_id: contentSessionId, idx: 0 });
    return null;
  }, [contentSessionId]);

  // Funky way to stream content so as to show the text being generated.
  // useEffect(() => {
  //   if (!content) return;
  //   if (content.length < streamContent.length) {
  //     return;
  //   }

  //   setTimeout(() => {
  //     setStreamContent((p) => {
  //       if (!content[p.length]) {
  //         return p;
  //       }

  //       return p + content[p.length];
  //     });
  //   }, 50);
  // }, [content, streamContent]);

  return {
    content,
    streamContent,
    contentGenerationStatus,
    initAutoContentSesssionMutation,
    progress: Math.ceil((progressIdx / maxPolls) * 100),
  };
}

// =========================== Trip Ai Chat ==========================
const promptIt = ({
  lang = "en",
  source_entity,
  messages,
  max_tokens = 2000,
  chat_context = "tripplan_assistant",
}) => {
  return axios.post(`${SISI2_API_URL}/primeradiant/chat/`, {
    lang,
    source_entity,
    messages,
    max_tokens,
    chat_context,
  });
};

const pollAIChatSession = ({ session_id }) => {
  return axios.get(`${SISI2_API_URL}/primeradiant/chat/${session_id}`);
};

export function useAIChat() {
  const maxPolls = 200;

  const [progressIdx, setProgressIdx] = useState(0);
  const [contentSessionId, setContentSessionId] = useState(null);
  const [contentGenerationStatus, setContentGenerationStatus] = useState(null);

  const [content, setContent] = useState("");
  const [streamContent, setStreamContent] = useState("");

  const promptMutation = useMutation({
    mutationFn: async ({ source_entity, messages }) =>
      promptIt({ source_entity, messages }),
    onSuccess: (data) => {
      setContentSessionId(data?.data?.session_id);
      setContentGenerationStatus(data?.data?.status);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  function onResetAIChat() {
    setContentSessionId(null);
    setContentGenerationStatus(null);
    setStreamContent("");
    setProgressIdx(0);
  }

  const pollContentSessionMutation = useMutation({
    mutationFn: async ({ session_id, idx }) =>
      pollAIChatSession({ session_id }),
    onSuccess: (data, variables) => {
      var { idx, session_id } = variables;
      setContent(data?.data);
      setStreamContent(data?.data);
      setContentGenerationStatus(data?.data?.status);
      if (idx <= maxPolls && contentGenerationStatus === "processing") {
        setProgressIdx(idx++);
        pollContentSessionMutation.mutate({ session_id, idx });
      } else if (idx > maxPolls || contentGenerationStatus === "finished") {
        onResetAIChat();
      }
    },
    onError: (error, variables) => {
      var { idx, session_id } = variables;
      if (idx <= maxPolls && contentGenerationStatus === "processing") {
        setProgressIdx(idx++);
        pollContentSessionMutation.mutate({ session_id, idx });
      } else if (idx > maxPolls || contentGenerationStatus === "finished") {
        onResetAIChat();
      }
    },
  });

  // Polling for content generation
  useEffect(() => {
    if (!contentSessionId) return null;
    pollContentSessionMutation.mutate({ session_id: contentSessionId, idx: 0 });
    return null;
  }, [contentSessionId]);

  return {
    content,
    streamContent,
    contentGenerationStatus,
    promptMutation,
    progress: Math.ceil((progressIdx / maxPolls) * 100),
    onResetAIChat,
  };
}

// =========================== Ai Trip Plan ==========================
const planIt = ({
  lang = "en",
  source_entity,
  message,
  max_tokens = 2000,
  task = "trip_planner",
}) => {
  return axios.post(`${SISI2_API_URL}/primeradiant/trip-planning/`, {
    lang,
    source_entity,
    message,
    max_tokens,
    task,
  });
};

const pollAITripSession = ({ session_id }) => {
  return axios.get(`${SISI2_API_URL}/primeradiant/trip-planning/${session_id}`);
};

export function useAITripGenerator() {
  const maxPolls = 100;

  const [progressIdx, setProgressIdx] = useState(0);
  const [contentSessionId, setContentSessionId] = useState(null);
  const [contentGenerationStatus, setContentGenerationStatus] = useState(null);

  const [content, setContent] = useState("");
  const [streamContent, setStreamContent] = useState("");

  const tripGenMutation = useMutation({
    mutationFn: async ({ source_entity, message }) =>
      planIt({ source_entity, message }),
    onSuccess: (data) => {
      setContentSessionId(data?.data?.session_id);
      setContentGenerationStatus(data?.data?.status);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  function onResetAITripGenerator() {
    setContentSessionId(null);
    setContentGenerationStatus(null);
    setStreamContent("");
    setProgressIdx(0);
  }

  const pollContentSessionMutation = useMutation({
    mutationFn: async ({ session_id, idx }) =>
      pollAITripSession({ session_id }),
    onSuccess: (data, variables) => {
      var { idx, session_id } = variables;
      setContent(data?.data);
      setStreamContent(data?.data);
      setContentGenerationStatus(data?.data?.status);
      if (idx <= maxPolls && contentGenerationStatus === "processing") {
        setProgressIdx(idx++);
        pollContentSessionMutation.mutate({ session_id, idx });
      } else if (idx > maxPolls || contentGenerationStatus === "finished") {
        onResetAITripGenerator();
      }
    },
    onError: (error, variables) => {
      var { idx, session_id } = variables;
      if (idx <= maxPolls && contentGenerationStatus === "processing") {
        setProgressIdx(idx++);
        pollContentSessionMutation.mutate({ session_id, idx });
      } else if (idx > maxPolls || contentGenerationStatus === "finished") {
        onResetAITripGenerator();
      }
    },
  });

  // Polling for content generation
  useEffect(() => {
    if (!contentSessionId) return null;
    pollContentSessionMutation.mutate({ session_id: contentSessionId, idx: 0 });
    return null;
  }, [contentSessionId]);

  return {
    content,
    streamTripContent: streamContent,
    contentGenerationStatus,
    tripGenMutation,
    progress: Math.ceil((progressIdx / maxPolls) * 100),
    onResetAITripGenerator,
  };
}

export const verifyAITrip = ({
  lang = "en",
  source_entity,
  messages,
  max_tokens = 500,
  chat_context = "tripplan_assistant_check",
}) => {
  return axios.post(
    `${SISI2_API_URL}/primeradiant/trip-planning/tripplan_completeness`,
    { lang, source_entity, messages, max_tokens, chat_context }
  );
};
// ============================ end easyAI ===========================

export function useServiceCardExpansion() {
  const { services_collapsed } = useContext(TripPlanContext);
  const [selfCollapse, setSelfCollapse] = useState(services_collapsed);

  useEffect(() => {
    setSelfCollapse(services_collapsed);
  }, [services_collapsed]);

  function toggleSelfCollapse() {
    setSelfCollapse((p) => !p);
  }

  return {
    services_collapsed: selfCollapse,
    toggleSelfCollapse,
  };
}

export function useLoadingAnimation({ loading, animationName }) {
  const animationRef = useRef(null);
  const [animation, setAnimation] = useState(null);
  const destroy = () => {
    if (animation) {
      animation.destroy();
    }
  };
  useEffect(() => {
    if (!loading) {
      destroy();
      return destroy;
    }

    const a = Lottie.loadAnimation({
      container: animationRef.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: `https:apollo-statics.s3.eu-central-1.amazonaws.com/svgs/${animationName}`,
    });
    setAnimation(a);

    return destroy;
  }, [loading]);

  return { animationRef };
}
