export const BIDDING_FLOW_INITIALIZE_BASIC_FILTER_DATA =
  "BIDDING_FLOW_INITIALIZE_BASIC_FILTER_DATA";
export const BIDDING_FLOW_EDIT_BASIC_FILTER_DATA_FORM =
  "BIDDING_FLOW_EDIT_BASIC_FILTER_DATA_FORM";
export const CHANGE_ARCHIVE_VIEW_MODEL = "CHANGE_ARCHIVE_VIEW_MODEL";
export const BIDDING_FLOW_INITIALIZE_LIST_COLUMNS_FORM =
  "BIDDING_FLOW_INITIALIZE_LIST_COLUMNS_FORM";
export const BIDDING_FLOW_APPLY_LIST_COLUMNS =
  "BIDDING_FLOW_APPLY_LIST_COLUMNS";
export const BIDDING_FLOW_UPDATE_LIST_FILTER_FORM =
  "BIDDING_FLOW_UPDATE_LIST_FILTER_FORM";
export const BIDDING_FLOW_APPLY_LIST_FILTER = "BIDDING_FLOW_APPLY_LIST_FILTER";
export const BIDDING_FLOW_INITIALIZE_LIST_FILTER_FORM =
  "BIDDING_FLOW_INITIALIZE_LIST_FILTER_FORM";
export const BIDDING_FLOW_RESET_LIST_FILTER_FORM =
  "BIDDING_FLOW_RESET_LIST_FILTER_FORM";
export const TOGGLE_ARCHIVE_MAP_VIEW = "TOGGLE_ARCHIVE_MAP_VIEW";
export const BIDDING_FLOW_BEGIN_FETCH_DATA = "BIDDING_FLOW_BEGIN_FETCH_DATA";
export const BIDDING_FLOW_END_FETCH_DATA = "BIDDING_FLOW_END_FETCH_DATA";
export const BIDDING_FLOW_FETCH_DATA = "BIDDING_FLOW_FETCH_DATA";
export const BIDDING_FLOW_CHANGE_PRICE_STATUS =
  "BIDDING_FLOW_CHANGE_PRICE_STATUS";
export const BIDDING_FLOW_GET_GRAPH_DATA = "BIDDING_FLOW_GET_GRAPH_DATA";
