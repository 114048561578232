import { Tag, IconButton, Icon } from "rsuite";

import { bankersRounder, formatToCurrency } from "@src/tools/pricing_tools";

// ============================= ACTIONS =============================
import { showDepositModal } from "@src/actions/ManagementConsole/AccountStatement/index";
import { refreshPaymentKPI } from "@src/actions/Project/TripPlanner/step_four";

// ============================ SELECTORS ============================
import { getRequiredDepositSelector } from "@src/selectors/Project/TripPlanner";
import {
  convertAmountSelector,
  convertToCurrency,
} from "@src/selectors/Financial/index";

import _ from "lodash";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { naString } from "@src/tools/string_tools";
import LeanAuthHoc from "@src/components/authorization/LeanAuthHoc";
import { createUseStyles } from "react-jss";
import {
  overviewCtrlSectionHeaderStyles,
  overviewCtrlSectionStyles,
} from "./ServicesFilter";
import { variables } from "@src/jsssetup";
import { getSubEntityAggregates } from "@src/api";

const commonBtnStyles = {
  background: variables.colors.easy.orange,
  color: "white",
  fontWeight: "600",
};

var AddFundsBtn = ({ onClick }) => {
  return (
    <IconButton
      style={commonBtnStyles}
      icon={<Icon icon="plus" style={commonBtnStyles} />}
      size="xs"
      onClick={onClick}>
      Add Funds
    </IconButton>
  );
};
AddFundsBtn.propTypes = {
  onClick: PropTypes.func.isRequired,
};
AddFundsBtn = LeanAuthHoc(AddFundsBtn, { allowed_groups: ["member"] });

const actionsStyles = createUseStyles({
  Actions: {
    display: "grid",
    gridTemplateColumns: "1fr auto",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    alignItems: "center",
  },
});
var Actions = ({ loading, onAddFunds, onRefresh }) => {
  const classes = actionsStyles();
  return (
    <div className={classes.Actions}>
      <AddFundsBtn onClick={onAddFunds} />
      <IconButton
        icon={<Icon icon="refresh" />}
        style={{ background: variables.colors.easy.orange, color: "white" }}
        circle
        size="sm"
        loading={loading}
        onClick={onRefresh}
      />
    </div>
  );
};
Actions.propTypes = {
  loading: PropTypes.bool.isRequired,
  onAddFunds: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
};

const prebookPaymentStyles = createUseStyles({
  PrebookPayment: {
    ...overviewCtrlSectionStyles(),
  },
  header: overviewCtrlSectionHeaderStyles(),
  content: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    marginTop: variables.normal_gap,
  },
  list: {
    padding: 0,
  },
  item: {
    listStyleType: "none",
    display: "grid",
    gridTemplateColumns: "1fr max-content",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    marginBottom: variables.normal_gap,
    alignItems: "center",
  },
  depositBalance: (props) => ({
    background:
      props.deposit_balance > 0
        ? variables.colors.green
        : variables.colors.deepRed,
    color: "white",
  }),
  balance: (props) => ({
    background:
      (props.balance || 0) > 0
        ? variables.colors.green
        : variables.colors.deepRed,
    color: "white",
  }),
  payable: {
    background: variables.colors.easy.orange,
    color: "white",
  },
});
function PrebookPayment({ current_deposit_balance, onAddFunds, onRefresh }) {
  const [loading, setLoading] = useState(false);
  const [availableCredit, setAvailableCredit] = useState(0);
  const [availableCreditCurrency, setAvailableCreditCurrency] = useState("EUR");

  const { required_deposit, source_entity, parent_entity } = useSelector(
    (state) => {
      var required_deposit = getRequiredDepositSelector(state);
      required_deposit = convertAmountSelector(state, {
        amount: required_deposit,
      });

      const source_entity = `${state.userMeta.company_id}___${state.userMeta.company_type}`;
      const parent_entity = `${state.userMeta.parent_entity_id}___${state.userMeta.parent_entity_type}`;
      return { required_deposit, source_entity, parent_entity };
    }
  );
  const isSubEntity = !source_entity.includes("member");

  async function handleRefresh() {
    setLoading(true);
    if (isSubEntity) {
      await subEntityCreditFetch({ parent_entity, source_entity });
    } else {
      await onRefresh();
    }
    setLoading(false);
  }

  const currentBalance = isSubEntity
    ? availableCredit
    : _.get(current_deposit_balance, "convertedAmount", 0);
  var balance = currentBalance - _.get(required_deposit, "convertedAmount", 0);
  balance = bankersRounder(balance);

  const deposit_balance = bankersRounder(
    _.get(current_deposit_balance, "convertedAmount", 0),
    2
  );
  const classes = prebookPaymentStyles({ deposit_balance, balance });

  async function subEntityCreditFetch({ parent_entity, source_entity }) {
    const results = await getSubEntityAggregates({
      parent_entity,
      source_entity,
    });
    setAvailableCredit(parseFloat(results.balance));
    setAvailableCreditCurrency(results.currency);
  }

  useEffect(() => {
    if (!source_entity || !parent_entity) return;

    if (!source_entity.includes("member")) {
      subEntityCreditFetch({ parent_entity, source_entity });
    }
  }, [source_entity, parent_entity]);

  return (
    <div className={classes.PrebookPayment}>
      <h5 className={classes.header}>PAYMENT</h5>
      <div className={classes.content}>
        <ul className={classes.list}>
          <li className={classes.item}>
            <span>
              <strong>Floating Deposit</strong>
            </span>
            <Tag className={classes.depositBalance}>
              <strong>
                {isSubEntity
                  ? formatToCurrency(availableCredit, availableCreditCurrency)
                  : deposit_balance}
              </strong>
            </Tag>
          </li>
          <li className={classes.item}>
            <span>
              <strong>Payable</strong>
            </span>
            <Tag className={classes.payable}>
              <strong>
                {required_deposit.currSymbol}
                {required_deposit.convertedAmount}
              </strong>
            </Tag>
          </li>
          <li className={classes.item}>
            <span>
              <strong>Balance</strong>
            </span>
            <Tag className={classes.balance}>
              <strong>
                {required_deposit.currSymbol}
                {balance || 0}
              </strong>
            </Tag>
          </li>
        </ul>
        <Actions
          loading={loading}
          onAddFunds={onAddFunds}
          onRefresh={handleRefresh}
        />
      </div>
    </div>
  );
}
PrebookPayment.propTypes = {
  current_deposit_balance: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object,
  ]).isRequired,
  onAddFunds: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  var { balance, currency } = state.accountStatementDepositBalance;

  var current_deposit_balance = balance;

  if (!_.isEmpty(currency)) {
    current_deposit_balance = convertToCurrency(state, {
      amount: current_deposit_balance,
      currentCurrency: currency.notation,
    });
  }

  return {
    current_deposit_balance,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onAddFunds: () => dispatch(showDepositModal("tripplan")),
    onRefresh: () => dispatch(refreshPaymentKPI()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrebookPayment);
