import { Tag } from "rsuite";

import { archiveColumnsForm } from "@src/forms/Project/Archive";

import React from "react";
import TransTxt from "@src/components/common/SxFormatMessage";
import _ from "lodash";
import { getDisplayNameFromOptions } from "@src/processors/columnsDataProcessor";
import { formatDate } from "@src/tools/date_tools";
import { lang_mapping } from "@src/tools/lang_tools";

export const initialBiddingTripsColumnsSetUp = {
  basic: {
    value: [
      "title",
      "request_by",
      "pax",
      "time_period",
      "destinations",
      "service_types",
      "status",
      "creator",
      "created",
      "edited",
    ],
    choices: [
      {
        display_name: <TransTxt id="tripplanlist_trip_title" startCase />,
        key: "title",
      },
      {
        display_name: <TransTxt id="tripplanlist_request_by" startCase />,
        key: "request_by",
      },
      {
        display_name: <TransTxt id="tripplanlist_pax" startCase />,
        key: "pax",
      },
      {
        display_name: <TransTxt id="tripplanlist_selling_price" startCase />,
        key: "selling_price",
      },
      {
        display_name: <TransTxt id="tripplanlist_buying_price" startCase />,
        key: "buying_price",
      },
      {
        display_name: <TransTxt id="tripplanlist_time_period" startCase />,
        key: "time_period",
      },
      {
        display_name: <TransTxt id="tripplanlist_destinations" startCase />,
        key: "destinations",
      },
      {
        display_name: (
          <TransTxt id="tripplanlist_services_category" startCase />
        ),
        key: "service_types",
      },
      {
        display_name: <TransTxt id="creator" startCase />,
        key: "creator",
      },
      {
        display_name: <TransTxt id="created" startCase />,
        key: "created",
      },

      {
        display_name: <TransTxt id="edited" startCase />,
        key: "edited",
      },
      {
        display_name: <TransTxt id="status" startCase />,
        key: "status",
      },
    ],
  },
};

export const initialBiddingTripsColumnsProcessor = {
  title: {
    key: "title",
    getter: (data) => _.get(data, "itinerary_setup.title"),
  },
  request_by: {
    key: "request_by",
    getter: () => "",
  },
  pax: {
    key: "pax",
    getter: (data) => {
      return `${_.get(data, "itinerary_setup.adults")} Adults,${_.get(
        data,
        "itinerary_setup.children"
      )} Children`;
    },
  },
  time_period: {
    key: "main_service",
    getter: (data) => {
      const data_a = formatDate(_.get(data, "origin_data.date"));
      const data_b = formatDate(_.get(data, "return_data.date"));
      return `${data_a}-${data_b}`;
    },
  },
  destinations: {
    key: "destinations",
    getter: (data, options) => {
      const res = _.get(data, "destinations", []);
      const lang = options[0];
      const arr = [];
      res.forEach((item) => {
        arr.push(item[`name_${lang_mapping[lang]}`]);
      });

      return `${arr[0]}(${arr.length})`;
    },
    extraGetter: (data, options) => {
      const res = _.get(data, "destinations", []);
      const lang = options[0];
      const arr = [];
      res.forEach((item) => {
        arr.push(item[`name_${lang_mapping[lang]}`]);
      });

      return arr;
    },
  },
  service_types: {
    key: "service_types",
    getter: (data) => {
      const res = _.get(data, "service_types");
      const arr = [];

      Object.keys(res).map((item) => {
        if (res[item].available) {
          arr.push(
            getDisplayNameFromOptions(item, [
              { display_name: "Transportation", value: "FL" },
              { display_name: "Transfer", value: "TF" },
              { display_name: "Accommodation", value: "ACC" },
            ])
          );
        }
      });
      return `${arr[0]}(${arr.length})`;
    },
    extraGetter: (data) => {
      const res = _.get(data, "service_types");
      const arr = [];
      Object.keys(res).map((item) => {
        if (res[item].available) {
          arr.push(
            getDisplayNameFromOptions(item, [
              { display_name: "Transportation", value: "FL" },
              { display_name: "Transfer", value: "TF" },
              { display_name: "Accommodation", value: "ACC" },
            ])
          );
        }
      });
      return arr;
    },
  },
  selling_price: {
    key: "selling_price",
    getter: (data) => {
      return data.final_pricing.selling_price;
    },
  },
  buying_price: {
    key: "buying_price",
    getter: (data) => {
      return data.final_pricing.buying_price;
    },
  },
  status: {
    key: "status",
    getter: (data) => {
      const status = _.get(data, "bid_requests", []);
      if (status.length > 0) {
        return <Tag color="green">Used</Tag>;
      } else {
        return <Tag color="red">Unused</Tag>;
      }
    },
  },
  creator: {
    key: "creator",
    getter: () => "",
  },
  created: {
    key: "created",
    getter: (data) => formatDate(data.created),
  },
  edited: {
    key: "edited",
    getter: (data) => formatDate(data.edited),
  },
};

export const initialBiddingTripsColumnsMapping = {
  title: {
    key: "title",
  },
  request_by: {
    key: "request_by",
  },
  pax: {
    key: "pax",
  },
  time_period: {
    key: "time_period",
  },
  destinations: {
    key: "destinations",
  },
  service_types: {
    key: "service_types",
  },
  creator: {
    key: "creator",
  },
  created: {
    key: "created",
  },
  edited: {
    key: "edited",
  },
  status: {
    key: "status",
  },
};

export const biddingTripsArchiveColumnsForm = _.merge({}, archiveColumnsForm, {
  per_person: {
    display_name: (data, setPrice) => {
      if (setPrice == "selling") {
        return "Selling Price";
      } else {
        return "Buying Price";
      }
    },
    // eslint-disable-next-line react/display-name
    getter: (data, options, setPrice) => {
      const price_selling = parseFloat(
        _.get(data, "final_pricing.buying_price")
      ).toFixed(2);
      const price_buying = parseFloat(
        _.get(data, "final_pricing.buying_price")
      ).toFixed(2);
      // const currency = _.get(data, "currency");
      // const symbol = (
      //   _.find(options, item => {
      //     return item.notation == currency;
      //   }) || {}
      // ).symbol;
      if (setPrice == "buying") {
        return <h5>{price_buying}</h5>;
      } else {
        return <h5>{price_selling}</h5>;
      }
    },
    extraGetter: () => {
      return "";
    },
  },
});
