import _ from "lodash";

import { createSelector } from "reselect";
import { getTripDestinations } from "./trip_itinerary_selectors";

export const getServiceFilters = (state) => state.tripPlannerServiceFilters;

export const filterTrp = (
  srvFilters,
  extremal,
  destOrder,
  lastTrp,
  returnData
) => {
  var extremalOk = true;
  if (srvFilters.extremal.length) {
    if (["origin", "return"].every((e) => srvFilters.extremal.includes(e))) {
      extremalOk = extremal || (!_.isEmpty(returnData) && lastTrp);
    } else if (srvFilters.extremal.includes("origin")) {
      extremalOk = extremal;
    } else if (srvFilters.extremal.includes("return")) {
      extremalOk = !_.isEmpty(returnData) && lastTrp;
    }
  }

  var serviceTypesOk = true;
  if (srvFilters.service_types.length) {
    serviceTypesOk = srvFilters.service_types.includes("TR");
  }

  var destinationsOk = true;
  if (srvFilters.destinations.length) {
    destinationsOk = srvFilters.destinations.includes(destOrder);
  }

  return extremalOk && serviceTypesOk && destinationsOk;
};

export const filterAcc = (srvFilters, destOrder) => {
  var extremalOk = true;
  if (srvFilters.extremal.length) {
    extremalOk = false;
  }

  var serviceOk = true;
  if (srvFilters.service_types.length) {
    serviceOk = srvFilters.service_types.includes("ACC");
  }

  var destinationOk = true;
  if (srvFilters.destinations.length) {
    destinationOk = srvFilters.destinations.includes(destOrder);
  }

  return extremalOk && serviceOk && destinationOk;
};

export const filterTrf = (srvFilters, destOrder) => {
  var extremalOk = true;
  if (srvFilters.extremal.length) {
    extremalOk = false;
  }

  var serviceOk = true;
  if (srvFilters.service_types.length) {
    serviceOk = srvFilters.service_types.includes("TF");
  }

  var destinationOk = true;
  if (srvFilters.destinations.length) {
    destinationOk = srvFilters.destinations.includes(destOrder);
  }

  return extremalOk && serviceOk && destinationOk;
};

export const filtersToServices = (filters, tripDestinations) => {
  const visibleSrvs = {
    FL: [],
    ACC: [],
    TF: [],
    MI: [],
  };

  if (filters.extremal.length) {
    // If extremal is combined with other filters return nothing;
    if (_.flatten(Object.values(_.omit(filters, "extremal"))).length) {
      return visibleSrvs;
    }

    // If only origin is elected
    if (filters.extremal.join("") == "origin") {
      visibleSrvs.FL.push("extremal");
      // If only return is selected
    } else if (filters.extremal.join("") == "return") {
      // If extremal roundtrip
      if (tripDestinations[0].order == _.last(tripDestinations).order) {
        visibleSrvs.FL.push("extremal");
      } else {
        visibleSrvs.FL.push(_.last(tripDestinations).order);
      }
    } else {
      visibleSrvs.FL.push("extremal");
      // If not extremal roundtrip
      if (tripDestinations[0].order !== _.last(tripDestinations).order) {
        visibleSrvs.FL.push(_.last(tripDestinations).order);
      }
    }

    visibleSrvs.FL = visibleSrvs.FL.sort();
    return visibleSrvs;
  } else if (
    !_.flatten(Object.values(filters)).length ||
    (!filters.extremal.length &&
      filters.service_types.includes("TR") &&
      !filters.destinations.length)
  ) {
    visibleSrvs.FL.push("extremal");
  }

  var destinations = [];
  if (filters.destinations.length) {
    destinations = filters.destinations;
  } else if (
    !_.flatten(Object.values(filters)).length ||
    filters.service_types.length
  ) {
    destinations = tripDestinations.map((d) => d.order);
  }

  destinations.forEach((d) => {
    var services = [];
    if (filters.service_types.length) {
      services = filters.service_types.map((s) => (s == "TR" ? "FL" : s));
    } else {
      services = ["FL", "ACC", "TF", "MI"];
    }

    services.forEach((s) => {
      _.get(visibleSrvs, s, []).push(d);
    });
  });

  const tmp = {};
  Object.entries(visibleSrvs).forEach((e) => (tmp[e[0]] = e[1].sort()));

  return {};
};

export const filtersToServicesSelector = createSelector(
  [getServiceFilters, getTripDestinations],
  (filters, destinations) => {
    return filtersToServices(filters, destinations);
  }
);

const bidSelector = (state) => state.newBiddingList;
const currentBidUidSelector = (state) => state.newBiddingCurrentBiddingUid;
const tripSelector = (state) => state.newBiddingOriginalTripData;

export const selectedBidding = createSelector(
  bidSelector,
  currentBidUidSelector,
  (bids, uid) => {
    if (bids.length) {
      return bids.find((b) => b.uid == uid) || bids[0];
    } else {
      return {};
    }
  }
);

export const doneBiddings = createSelector(bidSelector, (biddings) =>
  biddings.filter((b) => b.setup_status == "DO")
);

export const selectedBiddingDestinations = createSelector(
  selectedBidding,
  (bid) => {
    return [
      ...new Set(
        _.flatten(Object.values(_.get(bid, "required_services", {}))).filter(
          (o) => o !== "extremal"
        )
      ),
    ];
  }
);

export const selectedServices = createSelector(
  bidSelector,
  tripSelector,
  (biddings, trip) => {
    if (!biddings.length) {
      return {};
    }

    const servicesData = {};

    biddings.forEach((b) => {
      if (_.isEmpty(_.get(b, "required_services", {})) || _.isEmpty(trip)) {
        return;
      }

      const rqServices = b.required_services;
      var services = 0;
      rqServices.FL.forEach((fl) => {
        if (
          _.get(
            trip.transportation_data.flights,
            fl == "extremal" ? "extremalLeg" : fl,
            []
          ).some((fl) => fl.selected)
        ) {
          services += 1;
        }
      });

      rqServices.ACC.forEach((acc) => {
        if (
          _.get(trip.accommodation_data, acc, []).some((acc) => acc.selected)
        ) {
          services += 1;
        }
      });

      rqServices.TF.forEach((tf) => {
        services += _.flatten(
          Object.values(_.get(trip.transfer_data, tf, {}))
        ).filter((tf) => tf.selected).length;
      });

      servicesData[b.uid] = services;
    });

    return servicesData;
  }
);

/**
 * Returns true if the service is selected
 * @param {object} state
 * @param {string} service_type
 * @returns {boolean}
 */
const isServiceFilterSelected = (state, { service_type }) => {
  const filters = getServiceFilters(state);

  if (!filters.service_types.length) {
    return true;
  }

  if (filters.service_types.includes(service_type)) {
    return true;
  } else {
    return false;
  }
};

export const isServiceFilterSelectedSelector = createSelector(
  [isServiceFilterSelected],
  (serviceIsSelected) => serviceIsSelected
);
