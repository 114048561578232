import PropTypes from "prop-types";
import React, { useContext } from "react";
import { GlobalModalContext } from "@src/App";
import { CustomButton } from "@src/components/common/buttons";
import { ListPageHeader } from "@src/components/common/lists";
import { NormalInputField, NormalSelectField } from "@src/components/forms";
import {
  formStyles,
  modalGenericStyles,
  tableStyles,
  variables,
} from "@src/jsssetup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Form, Formik, useFormikContext } from "formik";
import { createUseStyles } from "react-jss";
import * as yup from "yup";
import axios from "axios";
import { SISI2_API_URL } from "@src/api";
import { getAuthHeaders } from "@src/api/request";
import { toast } from "react-toastify";
import { getUserSourceEntitySelector } from "@src/selectors/Shared/user_selectors";
import { useSelector } from "react-redux";
import { Loader } from "rsuite";
import _ from "lodash";
import TransTxt from "@src/components/common/SxFormatMessage";
import { injectIntl } from "react-intl";
import { handleNonValidImgFn } from "@src/tools/common_tools";
import {
  withAppUserGroup,
  withAppUserPermission,
} from "@src/components/authorization/permissionhocs";

async function createCompanyStamps({ payload }) {
  const headers = getAuthHeaders();

  return await axios.post(`${SISI2_API_URL}/crm/stamps/`, payload, { headers });
}

export async function fetchCompanyStamps({ source_entity, status = null }) {
  const headers = getAuthHeaders();

  return await axios.get(`${SISI2_API_URL}/crm/stamps/`, {
    params: { source_entity, status },
    headers,
  });
}

export async function retrieveCompanyStamp({ id, source_entity }) {
  const headers = getAuthHeaders();

  return await axios.get(`${SISI2_API_URL}/crm/stamps/${id}`, {
    headers,
    params: { source_entity },
  });
}

export async function patchCompanyStamp({ id, payload }) {
  const headers = getAuthHeaders();

  return await axios.patch(`${SISI2_API_URL}/crm/stamps/${id}`, payload, {
    headers,
  });
}

export async function deleteCompanyStamp({ id }) {
  const headers = getAuthHeaders();
  return await axios.delete(`${SISI2_API_URL}/crm/stamps/${id}`, {
    headers,
  });
}

const AddButton = withAppUserPermission(
  (props) => (
    <button className="Button" data-success="true" {...props}>
      {props.children}
    </button>
  ),
  ["ADMIN"],
  {
    edit: true,
  }
);

const EditButton = withAppUserPermission(
  (props) => (
    <button className="Button" data-success="true" {...props}>
      {props.children}
    </button>
  ),
  ["ADMIN"],
  {
    edit: true,
  }
);

const DeleteButton = withAppUserPermission(
  (props) => (
    <button className="Button" data-danger="true" {...props}>
      {props.children}
    </button>
  ),
  ["ADMIN"],
  {
    edit: true,
  }
);

const validationSchema = (props) =>
  yup.object().shape({
    name: yup.string().required("Name is required"),
    description: yup.string(),
    stamp: yup.mixed().test("file-selected", "Stamp is required", (value) => {
      if (props.mode === "add") {
        return value instanceof File;
      } else {
        return true;
      }
    }),
    is_default: yup.bool().oneOf([true, false]).default(false),
    status: yup.string().oneOf(["IN", "AC"], "Invalid status").default("AC"),
    position: yup.string().oneOf(["TL", "TR", "BL", "BR"]),
    position_x: yup.number().default(0),
    position_y: yup.number().default(0),
  });

const SubmitBtn = () => {
  const { submitForm } = useFormikContext();
  return (
    <CustomButton onClick={submitForm}>
      <TransTxt id="CompanyStampAddEditMdl__submit" />
    </CustomButton>
  );
};

const deleteModalStyles = createUseStyles({
  ...modalGenericStyles,
  DeleteModal: {
    ...modalGenericStyles.modal,
  },
  card: {
    ...modalGenericStyles.card,
    minWidth: "auto",
    position: "relative",
  },
  cardBody: {
    ...modalGenericStyles.cardBody,
    minWidth: "auto",
    minHeight: "auto",
  },
  cardHeader: {
    ...modalGenericStyles.cardHeader,
    gridGap: variables.normal_gap,
    gridTemplateColumns: "auto 1fr",
    alignItems: "center",
  },
  cardActions: {
    ...modalGenericStyles.cardActions,
  },
});
export const CompanyStampDeleteModal = ({ id, onHide }) => {
  const classes = deleteModalStyles({});

  const source_entity = useSelector((state) =>
    getUserSourceEntitySelector(state)
  );

  const queryClient = useQueryClient();
  const { mutate: deleteMutation, isLoading: deleteLoading } = useMutation({
    mutationFn: () => deleteCompanyStamp({ id }),
    onSuccess: () => {
      toast.success(
        <TransTxt id="CompanyStampDltMdl__deleted_successfully_msg" />,
        {
          autoClose: 3000,
        }
      );
      queryClient.invalidateQueries(["companyStamps", source_entity]);
      onHide();
    },
    onError: (error) => {
      console.log(error);
      toast.error(
        error?.response?.data?.message || (
          <TransTxt id="CompanyStampDltMdl__unable_to_delete_msg" />
        ),
        {
          autoClose: 3000,
        }
      );
    },
  });

  return (
    <div className={classes.DeleteModal}>
      <div className={classes.card}>
        {deleteLoading && <Loader backdrop size="lg" center />}
        <div className={classes.cardHeader}>
          <h5>
            <TransTxt id="CompanyStampDltMdl__delete_the_stamp" />
          </h5>
        </div>
        <div className={classes.cardBody}>
          <p>
            <TransTxt id="CompanyStampDltMdl__body_message" />
          </p>
        </div>
        <div className={classes.cardActions}>
          <CustomButton appearance="ghost" onClick={onHide}>
            <TransTxt id="CompanyStampDltMdl__cancel" />
          </CustomButton>
          <CustomButton
            appearance="primary"
            onClick={() => {
              deleteMutation();
            }}>
            <TransTxt id="CompanyStampDltMdl__yes" />
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
CompanyStampDeleteModal.propTypes = {
  id: PropTypes.number.isRequired,
  onHide: PropTypes.func.isRequired,
};

const addEditModalStyles = createUseStyles({
  ...modalGenericStyles,
  AddEditModal: {
    ...modalGenericStyles.modal,
  },
  card: {
    ...modalGenericStyles.card,
    width: "50rem",
    minWidth: "auto",
    position: "relative",
  },
  cardBody: {
    ...modalGenericStyles.cardBody,
    minWidth: "auto",
    minHeight: "auto",
  },
  form: { ...formStyles.form },
  col2: {
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  fileInput: {
    paddingTop: variables.normal_gap,
  },
  error: {
    color: "red",
  },
  editImage: {
    width: "5rem",
    height: "5rem",
    objectFit: "contain",
  },
  imageCntr: {
    display: "grid",
    gridTemplateColumns: "max-content max-content",
    gridGap: variables.half_gap,
    alignItems: "center",
  },
  imageLabel: {
    fontWeight: "bold",
  },
  nameAndStampCntr: (props) => ({
    display: "grid",
    gridTemplateColumns: props.mode === "edit" ? "1fr max-content" : "1fr",
    gridGap: variables.double_gap,
    alignItems: "center",
  }),
});
export var CompanyStampAddEditModal = ({ id, onHide, intl }) => {
  const mode = id ? "edit" : "add";
  const classes = addEditModalStyles({ mode });

  const source_entity = useSelector((state) =>
    getUserSourceEntitySelector(state)
  );
  const { data: companyStamp, isLoading: companyStampLoading } = useQuery({
    queryKey: ["companyStampInstance", id],
    queryFn: () => retrieveCompanyStamp({ id, source_entity }),
    enabled: mode === "edit",
  });

  const queryClient = useQueryClient();

  const { mutate: createMutation, isLoading: createLoading } = useMutation({
    mutationFn: (payload) => {
      const formData = new FormData();
      formData.append("file", payload.stamp);
      formData.append("source_entity", source_entity);
      formData.append("name", payload.name);
      formData.append("description", payload.description);
      formData.append("status", payload.status);
      formData.append("is_default", payload.is_default);
      formData.append("position_x", payload.position_x);
      formData.append("position_y", payload.position_y);

      return createCompanyStamps({ payload: formData });
    },
    onSuccess: async () => {
      toast.success(
        <TransTxt id="CompanyStampAddEditMdl__created_successfully_msg" />,
        {
          autoClose: 3000,
        }
      );
      queryClient.invalidateQueries(["companyStamps", source_entity]);
      onHide();
    },
    onError: (error) => {
      console.log(error);
      toast.error(
        error?.response?.data?.message || (
          <TransTxt id="CompanyStampAddEditMdl__unable_to_create_msg" />
        ),
        {
          autoClose: 3000,
        }
      );
    },
  });

  const { mutate: editMutation, isLoading: editLoading } = useMutation({
    mutationFn: (payload) => {
      let newPayload = { ...payload };
      delete newPayload.stamp;
      return patchCompanyStamp({ id, payload: payload });
    },
    onSuccess: async () => {
      toast.success(
        <TransTxt id="CompanyStampAddEditMdl__edited_successfully_msg" />,
        {
          autoClose: 3000,
        }
      );
      queryClient.refetchQueries(["companyStamps", source_entity]);
      onHide();
    },
    onError: (error) => {
      console.log(error);
      toast.error(
        error?.response?.data?.message || (
          <TransTxt id="CompanyStampAddEditMdl__unable_to_edit_msg" />
        ),
        { autoClose: 3000 }
      );
    },
  });

  const loading =
    createLoading || editLoading || (companyStampLoading && mode === "edit");

  const data = _.get(companyStamp, "data", {});
  const name = _.get(data, "name", "");
  const description = _.get(data, "description", "");
  const status = _.get(data, "status", "IN");
  const isDefault = _.get(data, "is_default", false);
  const stamp = _.get(data, "stamp", "");
  const positionX = _.get(data, "position_x", 0);
  const positionY = _.get(data, "position_y", 0);

  const positionMappings = {
    TL: { position_x: 0, position_y: 0 },
    TR: { position_x: 95, position_y: 0 },
    BL: { position_x: 0, position_y: 95 },
    BR: { position_x: 95, position_y: 95 },
  };

  const positionKey = Object.keys(positionMappings).find(
    (key) =>
      positionMappings[key].position_x === positionX &&
      positionMappings[key].position_y === positionY
  );

  return (
    <div className={classes.AddEditModal}>
      <Formik
        enableReinitialize
        initialValues={{
          name: name,
          description: description,
          status: status,
          is_default: isDefault,
          stamp: stamp,
          position_x: positionX,
          position_y: positionY,
          position: positionKey,
        }}
        validationSchema={validationSchema({ mode })}
        onSubmit={(values) => {
          let newPositionValues = positionMappings[values.position];
          values.position_x = newPositionValues.position_x;
          values.position_y = newPositionValues.position_y;

          mode === "edit" ? editMutation(values) : createMutation(values);
        }}>
        {({ setFieldValue, values, errors, touched }) => (
          <div className={classes.card}>
            {loading && <Loader backdrop size="lg" center />}
            <div className={classes.cardHeader}>
              <h5>
                <TransTxt id="CompanyStampAddEditMdl__company_stamp" />
              </h5>
            </div>
            <div className={classes.cardBody}>
              <Form className={classes.form}>
                <div className={classes.nameAndStampCntr}>
                  <NormalInputField
                    id="name"
                    name="name"
                    label={intl.formatMessage({
                      id: "CompanyStampAddEditMdl__name",
                    })}
                    withError
                    highlightErrors
                  />
                  {mode === "edit" && (
                    <div className={classes.imageCntr}>
                      <span className={classes.imageLabel}>
                        <TransTxt id="CompanyStampAddEditMdl__stamp" /> :
                      </span>
                      <img
                        name="stamp"
                        className={classes.editImage}
                        src={values.stamp}
                        onError={handleNonValidImgFn}
                        alt="Stamp"
                      />
                    </div>
                  )}
                </div>
                <NormalInputField
                  id="description"
                  name="description"
                  label={intl.formatMessage({
                    id: "CompanyStampAddEditMdl__description",
                  })}
                  as="textarea"
                  extraInputProps={{ rows: 5 }}
                />
                <div className={classes.col2}>
                  <NormalSelectField
                    id="status"
                    name="status"
                    label={intl.formatMessage({
                      id: "CompanyStampAddEditMdl__status",
                    })}
                    options={[
                      [
                        "AC",
                        intl.formatMessage({
                          id: "CompanyStampAddEditMdl__active",
                        }),
                      ],
                      [
                        "IN",
                        intl.formatMessage({
                          id: "CompanyStampAddEditMdl__inactive",
                        }),
                      ],
                    ]}
                  />
                  <NormalSelectField
                    id="is_default"
                    name="is_default"
                    label={intl.formatMessage({
                      id: "CompanyStampAddEditMdl__default",
                    })}
                    options={[
                      [
                        true,
                        intl.formatMessage({
                          id: "CompanyStampAddEditMdl__yes",
                        }),
                      ],
                      [
                        false,
                        intl.formatMessage({
                          id: "CompanyStampAddEditMdl__no",
                        }),
                      ],
                    ]}
                  />
                </div>
                <NormalSelectField
                  id="position"
                  name="position"
                  label={intl.formatMessage({
                    id: "CompanyStampAddEditMdl__stampPosition",
                  })}
                  options={[
                    [
                      "TL",
                      intl.formatMessage({
                        id: "CompanyStampAddEditMdl__topLeft",
                      }),
                    ],
                    [
                      "TR",
                      intl.formatMessage({
                        id: "CompanyStampAddEditMdl__topRight",
                      }),
                    ],
                    [
                      "BL",
                      intl.formatMessage({
                        id: "CompanyStampAddEditMdl__bottomLeft",
                      }),
                    ],
                    [
                      "BR",
                      intl.formatMessage({
                        id: "CompanyStampAddEditMdl__bottomRight",
                      }),
                    ],
                  ]}
                />
                {mode === "add" && (
                  <React.Fragment>
                    <input
                      name="stamp"
                      className={classes.fileInput}
                      type="file"
                      accept=".png,.jpeg"
                      onChange={(event) => {
                        event.persist();
                        setFieldValue("stamp", event.currentTarget.files[0]);
                      }}
                    />
                    <div className={classes.error}>
                      <div className={classes.error}>
                        {errors.stamp && touched.stamp && errors.stamp}
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </Form>
            </div>
            <div className={classes.cardActions}>
              <CustomButton
                id="close_modal"
                appearance="ghost"
                onClick={onHide}>
                <TransTxt id="CompanyStampAddEditMdl__close" />
              </CustomButton>
              <SubmitBtn />
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};
CompanyStampAddEditModal.defaultProps = {
  id: null,
};
CompanyStampAddEditModal.propTypes = {
  id: PropTypes.number,
  intl: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
};
CompanyStampAddEditModal = injectIntl(CompanyStampAddEditModal);

const companyStampListStyles = createUseStyles({
  InvoiceSetupList: {
    overflow: "hidden",
    display: "grid",
    gridTemplateRows: "auto 1fr",
    height: "100%",
  },
  tableContainer: {
    overflow: "auto",
  },
  table: tableStyles.table,
  cell: tableStyles.cell,
  thead: tableStyles.head,
  actionCell: {
    ...tableStyles.cell,
    padding: variables.double_gap,
    display: "grid",
    gridAutoFlow: "column",
    justifyContent: "center",
    gridGap: variables.half_gap,
  },
  image: {
    width: "3rem",
    height: "3rem",
    objectFit: "contain",
  },
});
const CompanyStampList = () => {
  const { setStampId, setShowStampModal, setShowStampDeleteModal } =
    useContext(GlobalModalContext);

  const classes = companyStampListStyles({});
  const source_entity = useSelector((state) =>
    getUserSourceEntitySelector(state)
  );
  const { data, isLoading } = useQuery({
    queryKey: ["companyStamps", source_entity],
    queryFn: () => fetchCompanyStamps({ source_entity }),
  });

  const positionMappings = {
    TL: { position_x: 0, position_y: 0 },
    TR: { position_x: 95, position_y: 0 },
    BL: { position_x: 0, position_y: 95 },
    BR: { position_x: 95, position_y: 95 },
  };

  function findStampPosition(x, y) {
    const key = Object.keys(positionMappings).find(
      (key) =>
        positionMappings[key].position_x === x &&
        positionMappings[key].position_y === y
    );

    if (key === "TL") return <TransTxt id="CompanyStampList__topLeft" />;
    if (key === "TR") return <TransTxt id="CompanyStampList__topRight" />;
    if (key === "BL") return <TransTxt id="CompanyStampList__bottomLeft" />;
    if (key === "BR") return <TransTxt id="CompanyStampList__bottomRight" />;
  }
  return (
    <div>
      <ListPageHeader
        title={
          <span>
            <TransTxt id="CompanyStampList__company_stamp" />
          </span>
        }>
        <AddButton id="add_stamp" onClick={() => setShowStampModal(true)}>
          <TransTxt id="CompanyStampList__add_company_stamp" />
        </AddButton>
      </ListPageHeader>
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead className={classes.thead}>
            <tr>
              <th>#</th>
              <th>
                <TransTxt id="CompanyStampList__stamp" />
              </th>
              <th>
                <TransTxt id="CompanyStampList__name" />
              </th>
              <th>
                <TransTxt id="CompanyStampList__description" />
              </th>
              <th>
                <TransTxt id="CompanyStampList__Default" />
              </th>
              <th>
                <TransTxt id="CompanyStampList__status" />
              </th>
              <th>
                <TransTxt id="CompanyStampList__position" />
              </th>
              <th>
                <TransTxt id="CompanyStampList__actions" />
              </th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <Loader size="lg" center />
            ) : (
              (data?.data?.results || []).map((item, index) => (
                <tr key={item.id}>
                  <td className={classes.cell}>{index + 1}</td>
                  <td className={classes.cell}>
                    <a
                      href={item.stamp}
                      target="_blank"
                      rel="noopener noreferrer">
                      <img
                        className={classes.image}
                        src={item.stamp}
                        onError={handleNonValidImgFn}
                        alt="Stamp"
                      />
                    </a>
                  </td>
                  <td className={classes.cell}>{item.name || "N/A"}</td>
                  <td className={classes.cell}>{item.description || "N/A"}</td>
                  <td className={classes.cell}>
                    {item.is_default ? (
                      <TransTxt id="CompanyStampList__yes" />
                    ) : (
                      <TransTxt id="CompanyStampList__no" />
                    )}
                  </td>
                  <td className={classes.cell}>
                    {item.status && item.status === "AC" ? (
                      <TransTxt id="CompanyStampList__active" />
                    ) : (
                      <TransTxt id="CompanyStampList__inactive" />
                    )}
                  </td>
                  <td className={classes.cell}>
                    {findStampPosition(item.position_x, item.position_y)}
                  </td>
                  <td className={classes.actionCell}>
                    <DeleteButton
                      onClick={() => {
                        setStampId(item.id);
                        setShowStampDeleteModal(true);
                      }}>
                      <TransTxt id="CompanyStampList__delete" />
                    </DeleteButton>
                    <EditButton
                      id={`edit__stamp__${index}`}
                      onClick={() => {
                        setStampId(item.id);
                        setShowStampModal(true);
                      }}>
                      <TransTxt id="CompanyStampList__edit" />
                    </EditButton>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default withAppUserGroup(CompanyStampList, ["ADMIN"], { view: true });
