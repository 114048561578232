import _ from "lodash";
import { createSelector } from "reselect";
import { formatRangeDateTimeToUTC } from "./date_tools";
import { notifyCommonError } from "@src/components/common/notifications/CommonErrorNotification.js";
import {
  companyLogoPlaceHolderError,
  imgPlaceHolderError,
} from "@src/jsssetup";
import { Duration } from "luxon";

export const selectorFactory = ([...funcArray], fn) => {
  return createSelector([...funcArray], fn);
};

/**
 * Immutably juxtaposes two elements of an array starting with
 * the element on the left referred to by the idx parameter.
 * @param array array containing the elements to be juxtaposed.
 * @param number idx of the starting element to the left to be juxtaposed.
 * @returns array Returns the array with the juxtaposed elements
 */
export const juxtEl = (array, idx) => {
  const newArray = [...array];
  const removed = newArray.splice(idx, 2);
  newArray.splice(idx, 0, removed[1], removed[0]);
  return newArray;
};

// filter object property shallowly
export const filterEmptyValue = (obj) => {
  return _.pickBy(_.omitBy(obj, _.isNil), (v) => v !== "" && v.length != 0);
};

export const paramsProcessor = (filter) => {
  const created =
    formatRangeDateTimeToUTC(_.get(filter, "created.value")) || [];
  const edited = formatRangeDateTimeToUTC(_.get(filter, "edited.value")) || [];

  const created__gte = created[0];
  const created__lte = created[1];

  const edited__gte = edited[0];
  const edited__lte = edited[1];

  filter.created__gte = { value: created__gte };
  filter.created__lte = { value: created__lte };
  filter.edited__gte = { value: edited__gte };
  filter.edited__lte = { value: edited__lte };

  delete filter.created;
  delete filter.edited;

  return filter;
};

export const commonError400Handle = (error, form) => {
  if (error.data.message) {
    notifyCommonError(error.data.message);
  } else {
    for (const key in error.data) {
      error.data[key].forEach((item) => {
        const label = _.get(form, `${key}.verbose`);
        notifyCommonError(`${_.startCase(label || key)}:${item.message}`);
      });
    }
  }

  if (!form || typeof form == "undefined") {
    throw "Form is required";
  }
};

export const error401Handle = (error) => {
  notifyCommonError(`${error.data.message}`);
};

export const error403Handle = (error) => {
  notifyCommonError(`${error.data.message}`);
};

export const error500Handle = () => {
  notifyCommonError(`Internal Server Error`);
};

export const errorHandle = ({ error, form, error400Handle }) => {
  if (typeof error === "undefined") {
    error500Handle();
  }

  const { status } = error;
  switch (status) {
    case 400:
      {
        if (error400Handle) {
          error400Handle();
        } else {
          commonError400Handle(error, form);
        }
      }
      break;
    case 401:
      error401Handle(error);
      break;
    case 403:
      error403Handle(error);
      break;
    case 500:
      error500Handle(error);
      break;
    default:
      break;
  }
};

export const isEmptyObj = (object) => {
  for (const key in object) {
    if (object[key]) {
      return false;
    }
  }
  return true;
};

export function formatNumber(value) {
  const precision = 1;
  return Number(_.round(value, precision));
}

export function getProfit(buy, sell) {
  return formatNumber(parseFloat(sell) - parseFloat(buy));
}

export function getGP(profit, sell) {
  return formatNumber((profit / sell) * 100).toFixed(1);
}

export function getMU(profit, buy) {
  return formatNumber((profit / buy) * 100).toFixed(1);
}

export function getMarkup(buying, selling) {
  const res = ((selling - buying) / selling) * 100;
  return res;
}

export function getTotal(biddingServices) {
  let totalBuying = biddingServices.reduce((prev, now) => {
    if (!now.checked) {
      return prev;
    }
    return prev + parseFloat(now.price.buying);
  }, 0);

  let totalSelling = biddingServices.reduce((prev, now) => {
    if (!now.checked) {
      return prev;
    }
    return prev + parseFloat(now.price.selling);
  }, 0);

  let totalTarget = biddingServices.reduce((prev, now) => {
    if (!now.checked) {
      return prev;
    }
    return prev + parseFloat(now.price.target);
  }, 0);

  const grossProfit = totalBuying - totalTarget;
  const markUp = (grossProfit / totalBuying) * 100;

  const targetGrossProfit = totalSelling - totalTarget;
  const targetMarginPercent = (targetGrossProfit / totalSelling) * 100;

  return {
    totalBuying: parseFloat(totalBuying.toFixed(2)),
    totalSelling: parseFloat(totalSelling.toFixed(2)),
    totalTarget: parseFloat(totalTarget.toFixed(2)),
    grossProfit: parseFloat(grossProfit.toFixed(2)),
    markUp: parseFloat(markUp.toFixed(2)),
    targetGrossProfit: parseFloat(targetGrossProfit.toFixed(2)),
    targetMarginPercent: parseFloat(targetMarginPercent.toFixed(2)),
  };
}

export function handleNonValidImgFn(event, imgType = "COMP") {
  let errorImage;
  if (imgType === "COMP") {
    errorImage = companyLogoPlaceHolderError;
  } else {
    errorImage = imgPlaceHolderError(imgType);
  }

  event.target.onerror = null;
  event.target.src = errorImage;
}

export const humanizeDurationFromMins = (minutes) => {
  const duration = Duration.fromObject({ minutes });
  const { hours, minutes: remainingMinutes } = duration
    .shiftTo("hours", "minutes")
    .toObject();

  let durationString = "";
  if (hours > 0) {
    durationString += `${hours > 9 ? "" : "0"}${hours} ${
      hours > 1 ? "hrs" : "hr"
    }`;
  }
  if (remainingMinutes > 0) {
    durationString += ` ${remainingMinutes > 9 ? "" : "0"}${remainingMinutes} ${
      remainingMinutes > 1 ? "mins" : "min"
    }`;
  }

  return durationString.trim();
};
