import { SISI_API_URL, SISI2_API_URL } from "@src/api";
import service, { getAuthHeaders } from "@src/api/request";
import axios from "axios";

export function getBillAndPaymentsListData(param) {
  return service({ url: "bid/invoice/", method: "get", params: param });
}

export function getBillAndPaymentsStat(param) {
  return service({ url: "bid/invoice-stats/", method: "get", params: param });
}

export async function getMemberDepositEntries(params) {
  const headers = getAuthHeaders();

  return await axios.get(
    `${SISI2_API_URL}/paymentengine/deposit-ledger-entry/`,
    { params, headers }
  );
}

export async function getMemberDepositEntry(id, params) {
  const headers = getAuthHeaders();

  return await axios.get(
    // `${SISI_API_URL}/payments/accounts/member-deposit-ledger/${id}/`,
    `${SISI2_API_URL}/paymentengine/deposit-ledger-entry/${id}/`,
    { params, headers }
  );
}

export async function patchMemberDepositEntry(id, payload, params) {
  const headers = getAuthHeaders();
  return await axios.patch(
    // `${SISI_API_URL}/payments/accounts/member-deposit-ledger/${id}/`,
    `${SISI2_API_URL}/paymentengine/deposit-ledger-entry/${id}/`,
    payload,
    { params, headers }
  );
}

export async function getSubEntityDepositEntries(params) {
  const headers = getAuthHeaders();

  return await axios.get(
    // `${SISI_API_URL}/payments/accounts/sub-entity-deposit-ledger/`,
    `${SISI2_API_URL}/paymentengine/subentity-deposit-ledger-entry/`,
    { params, headers }
  );
}

export async function getSubEntityDepositEntry(id, params) {
  const headers = getAuthHeaders();
  return await axios.get(
    // `${SISI_API_URL}/payments/accounts/sub-entity-deposit-ledger/${id}/`,
    `${SISI2_API_URL}/paymentengine/subentity-deposit-ledger-entry/${id}/`,
    { params, headers }
  );
}

export async function patchSubEntityDepositEntry(id, payload, params) {
  const headers = getAuthHeaders();
  return await axios.patch(
    // `${SISI_API_URL}/payments/accounts/sub-entity-deposit-ledger/${id}/`,
    `${SISI2_API_URL}/paymentengine/subentity-deposit-ledger-entry/${id}/`,
    payload,
    { params, headers }
  );
}

export async function createSubEntityDepositEntry(payload) {
  const headers = getAuthHeaders();
  return await axios.post(
    // `${SISI_API_URL}/payments/accounts/sub-entity-deposit-ledger/`,
    `${SISI2_API_URL}/paymentengine/subentity-deposit-ledger-entry/`,
    payload,
    { headers }
  );
}

export async function createProformaSubentityEntry(payload) {
  const headers = getAuthHeaders();
  return await axios.post(
    `${SISI2_API_URL}/paymentengine/proforma-subentity-ledger-entry/`,
    payload,
    { headers }
  );
}

export async function getProformaSubentityEntries(params) {
  const headers = getAuthHeaders();

  return await axios.get(
    `${SISI2_API_URL}/paymentengine/proforma-subentity-ledger-entry/`,
    { params, headers }
  );
}

export async function getProformaSubentityEntry(uid) {
  const headers = getAuthHeaders();

  return await axios.get(
    `${SISI2_API_URL}/paymentengine/proforma-subentity-ledger-entry/${uid}/`,
    { headers }
  );
}

export async function patchProformaSubentityEntry(uid, payload) {
  const headers = getAuthHeaders();
  return await axios.patch(
    `${SISI2_API_URL}/paymentengine/proforma-subentity-ledger-entry/${uid}/`,
    payload,
    { headers }
  );
}

export async function voidProformaSubentityEntry({ uid }) {
  const headers = getAuthHeaders();
  return axios.get(
    `${SISI2_API_URL}/paymentengine/proforma-subentity-ledger-entry/void/${uid}`,
    { headers }
  );
}

// export async function createSubEntityDepositEntry(
//   {
//     source_entity,
//     parent_entity,
//     amount,
//     currency,
//     description,
//     entry_type = "CR",
//     tags = [],
//     expiration_datetime = null,
//   },
//   onError = () =>
//     toast.error("Error creating sub-entity deposit entries", {
//       autoClose: 5000,
//     })
// ) {
//   const headers = getAuthHeaders();

//   const payload = {
//     source_entity,
//     parent_entity,
//     amount: entry_type === "CR" ? amount : -amount,
//     currency,
//     description,
//     entry_type,
//     tags,
//     expiration_datetime,
//   };

//   try {
//     const response = await axios.post(
//       `${SISI_API_URL}/payments/accounts/sub-entity-deposit-ledger/`,
//       payload,
//       { headers }
//     );
//     return response.data;
//   } catch (error) {
//     onError();
//     return null;
//   }
// }

// export async function voidSubEntityDepositEntry({
//   uid,
//   source_entity,
//   product_type,
//   product_uid,
// }) {
//   const headers = getAuthHeaders();
//   const payload = { uid, source_entity, product_type, product_uid };
//   return axios.delete(
//     `${SISI_API_URL}/payments/accounts/sub-entity-deposit-ledger/`,
//     { headers, data: payload }
//   );
// }

export async function voidSubEntityDepositEntry({ id }) {
  const headers = getAuthHeaders();
  return axios.get(
    `${SISI2_API_URL}/paymentengine/subentity-deposit-ledger-entry/void/${id}/`,
    { headers }
  );
}

export const getSubEntityAggregates = async ({
  source_entity,
  parent_entity,
}) => {
  const headers = getAuthHeaders();

  try {
    const response = await axios.get(
      `${SISI_API_URL}/payments/accounts/sub-entity-deposit-ledger/aggregates/`,
      { params: { source_entity, parent_entity }, headers }
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export async function getProformaDepositEntries(params) {
  const headers = getAuthHeaders();

  // return await axios.get(`${SISI_API_URL}/payments/proforma-ledger-entry/`, {
  return await axios.get(
    `${SISI2_API_URL}/paymentengine/proforma-ledger-entry/`,
    { params, headers }
  );
}

export async function getProformaDepositEntry(uid) {
  const headers = getAuthHeaders();

  return await axios.get(
    // `${SISI_API_URL}/payments/proforma-ledger-entry/${uid}/`,
    `${SISI_API_URL}/paymentengine/proforma-ledger-entry/${uid}/`,
    { headers }
  );
}

export async function patchProformaDepositEntry(uid, payload) {
  const headers = getAuthHeaders();
  return await axios.patch(
    // `${SISI_API_URL}/payments/proforma-ledger-entry/${uid}/`,
    `${SISI_API_URL}/paymentengine/proforma-ledger-entry/${uid}/`,
    payload,
    { headers }
  );
}

export async function getSupplierProformaDepositEntries(params) {
  const headers = getAuthHeaders();

  return await axios.get(
    `${SISI2_API_URL}/paymentengine/proforma-supplier-ledger-entry/`,
    { params, headers }
  );
}

export async function getSupplierProformaDepositEntry(uid) {
  const headers = getAuthHeaders();

  return await axios.get(
    `${SISI2_API_URL}/paymentengine/proforma-supplier-ledger-entry/${uid}/`,
    { headers }
  );
}

export async function patchSupplierProformaDepositEntry(uid, payload) {
  const headers = getAuthHeaders();
  return await axios.patch(
    `${SISI2_API_URL}/paymentengine/proforma-supplier-ledger-entry/${uid}/`,
    payload,
    { headers }
  );
}

export async function createSupplierDepositEntry(payload) {
  const headers = getAuthHeaders();
  return await axios.post(
    `${SISI2_API_URL}/paymentengine/supplier-ledger-entry/`,
    payload,
    { headers }
  );
}

export async function getSupplierDepositEntries(params) {
  const headers = getAuthHeaders();

  return await axios.get(
    `${SISI2_API_URL}/paymentengine/supplier-ledger-entry/`,
    { params, headers }
  );
}

export async function getSupplierDepositEntry(uid) {
  const headers = getAuthHeaders();

  return await axios.get(
    `${SISI2_API_URL}/paymentengine/supplier-ledger-entry/${uid}/`,
    { headers }
  );
}

export async function patchSupplierDepositEntry(uid, payload) {
  const headers = getAuthHeaders();
  return await axios.patch(
    `${SISI2_API_URL}/paymentengine/supplier-ledger-entry/${uid}/`,
    payload,
    { headers }
  );
}

export async function voidSupplierDepositEntry({ uid }) {
  const headers = getAuthHeaders();
  return axios.get(
    `${SISI2_API_URL}/paymentengine/supplier-ledger-entry/void/${uid}`,
    { headers }
  );
}
