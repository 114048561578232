import {
  Panel,
  InputNumber,
  Whisper,
  Popover,
  Dropdown,
  Button,
  IconButton,
  Icon,
  SelectPicker,
} from "rsuite";

import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { naString } from "@src/tools/string_tools";
import { formatDate } from "@src/tools/date_tools";
import _ from "lodash";
import update from "immutability-helper";
import { WhisperWrapper } from "@src/components/common/ui_helpers";
import { createUseStyles } from "react-jss";
import { variables } from "@src/jsssetup";
import { tableHeadStyles } from "./Highlights";
import { AddonModalsContext } from "../AddOn";

class RowAction extends React.Component {
  constructor(props) {
    super(props);
    this.triggerRef = React.createRef();
    this.handleSelectMenu = this.handleSelectMenu.bind(this);
  }
  handleSelectMenu(eventKey) {
    const { idx, onCopy, onReset } = this.props;

    switch (eventKey) {
      case 1:
        onCopy(idx);
        break;
      case 2:
        onReset(idx);
        break;
    }

    this.triggerRef.current.hide();
  }
  render() {
    const { copyAvailable } = this.props;

    return (
      <Whisper
        placement="auto"
        trigger="click"
        triggerRef={this.triggerRef}
        speaker={
          <Popover full>
            <Dropdown.Menu onSelect={this.handleSelectMenu}>
              {copyAvailable ? (
                <Dropdown.Item icon={<Icon icon="copy" />} eventKey={1}>
                  Copy Below
                </Dropdown.Item>
              ) : null}
              <Dropdown.Item icon={<Icon icon="refresh" />} eventKey={2}>
                Reset
              </Dropdown.Item>
            </Dropdown.Menu>
          </Popover>
        }>
        <IconButton color="blue" icon={<Icon icon="chevron-down" />} size="sm">
          Actions
        </IconButton>
      </Whisper>
    );
  }
}
RowAction.propTypes = {
  idx: PropTypes.number.isRequired,
  copyAvailable: PropTypes.bool.isRequired,
  onCopy: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
};

const inputStyles = createUseStyles({
  main: {
    borderWidth: "2px!important",
    borderColor: `${variables.colors.deepblue}!important`,
  },
});
const PriceInput = (props) => {
  const { distIdx, linked, currSymbol, price, onPriceChange } = props;

  const classes = inputStyles({ price_type: price.price_type });

  return (
    <InputNumber
      className={classes.main}
      value={price.value}
      prefix={currSymbol}
      postfix={
        <WhisperWrapper msg={`${_.startCase(price.price_type)} Price`}>
          <Icon icon={price.price_type == "adult" ? "male" : "child"} />
        </WhisperWrapper>
      }
      step={0.01}
      onChange={function (value) {
        onPriceChange(
          distIdx,
          price.meeting_group_uid,
          price.pax_group_uid,
          price.price_type,
          value
        );

        if (linked) {
          onPriceChange(
            distIdx,
            price.meeting_group_uid,
            price.pax_group_uid,
            price.price_type == "adult" ? "child" : "adult",
            value
          );
        }
      }}
    />
  );
};
PriceInput.propTypes = {
  distIdx: PropTypes.number.isRequired,
  linked: PropTypes.bool.isRequired,
  currSymbol: PropTypes.string.isRequired,
  price: PropTypes.object.isRequired,
  onPriceChange: PropTypes.func.isRequired,
};

const inputsStyles = createUseStyles({
  inputs: {
    display: "grid",
    gridTemplateColumns: "1fr auto 1fr",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    alignItems: "center",
  },
});
const Inputs = (props) => {
  const {
    rooms,
    groupPrices,
    links,
    currSymbol,
    distIdx,
    onPriceChange,
    onLinkChange,
  } = props;

  const classes = inputsStyles();

  return (
    <div className={classes.inputs}>
      {groupPrices.map((price, priceIdx) => {
        const linkKey = `${distIdx}__${price.meeting_group_uid}__${price.pax_group_uid}`;
        const linked = _.get(links, linkKey, true);

        return (
          <React.Fragment key={priceIdx}>
            <PriceInput
              distIdx={distIdx}
              currSymbol={currSymbol}
              price={price}
              linked={linked}
              onPriceChange={onPriceChange}
            />
            {priceIdx === 0 ? (
              <WhisperWrapper
                msg={
                  linked
                    ? "Adult and Child prices are linked together."
                    : "Adult and Child prices are unlinked."
                }>
                <IconButton
                  icon={<Icon icon={linked ? "link" : "unlink"} />}
                  circle
                  color="blue"
                  size="xs"
                  onClick={() => onLinkChange(linkKey, !linked)}
                />
              </WhisperWrapper>
            ) : null}
          </React.Fragment>
        );
      })}
    </div>
  );
};
Inputs.propTypes = {
  rooms: PropTypes.array.isRequired,
  groupPrices: PropTypes.array.isRequired,
  links: PropTypes.object.isRequired,
  currSymbol: PropTypes.string.isRequired,
  price: PropTypes.object.isRequired,
  priceIdx: PropTypes.number.isRequired,
  distIdx: PropTypes.number.isRequired,
  linkKey: PropTypes.string.isRequired,
  linked: PropTypes.bool.isRequired,
  onPriceChange: PropTypes.func.isRequired,
  onLinkChange: PropTypes.func.isRequired,
};

const priceCellStyles = createUseStyles({
  main: {
    display: "grid",
    gridGap: variables.normal_gap,
  },
  paxgroup: {
    display: "grid",
    placeItems: "center",
    gridGap: variables.half_gap,
  },
  header: {
    display: "grid",
    gridTemplateColumns: "repeat(3, max-content)",
    gridGap: variables.half_gap,
  },
});
const PriceCell = ({
  rooms,
  distIdx,
  pax_groups,
  pax_group_prices,
  links,
  currSymbol,
  onPriceChange,
  onLinkChange,
}) => {
  const classes = priceCellStyles();

  return (
    <div className={classes.main}>
      {Object.entries(pax_group_prices).map((group_entry, pidx) => {
        const pax_group = pax_groups.find(
          (pax_group) => pax_group.uid == group_entry[0]
        );

        return (
          <div key={pidx} className={classes.paxgroup}>
            <div className={classes.header}>
              <span>
                <strong>Pax</strong> ({pax_group.min_pax} - {pax_group.max_pax})
              </span>
              {pax_group.max_children > 0 && (
                <span>
                  <strong>Child Ages From/To</strong> (
                  {pax_group.child_age_from} - {pax_group.child_age_to})
                </span>
              )}
              <span>
                <strong>Count As Pax: </strong>
                {_.capitalize(pax_group.count_as_pax)}
              </span>
            </div>
            <Inputs
              rooms={rooms}
              links={links}
              groupPrices={group_entry[1]}
              currSymbol={currSymbol}
              distIdx={distIdx}
              onPriceChange={onPriceChange}
              onLinkChange={onLinkChange}
            />
          </div>
        );
      })}
    </div>
  );
};
PriceCell.propTypes = {
  rooms: PropTypes.array.isRequired,
  distIdx: PropTypes.number.isRequired,
  pax_groups: PropTypes.array.isRequired,
  pax_group_prices: PropTypes.object.isRequired,
  links: PropTypes.object.isRequired,
  currSymbol: PropTypes.string.isRequired,
  onPriceChange: PropTypes.func.isRequired,
  onLinkChange: PropTypes.func.isRequired,
};

const styles = createUseStyles({
  table: {
    width: "100%",
  },
  tableHead: { ...tableHeadStyles },
  tableCell: {
    padding: `calc(${variables.normal_gap} / 2)`,
    textAlign: "center",
    maxWidth: "7vw",
  },
  darkRow: { background: variables.colors.lightGrey },
  inputCell: {
    maxWidth: "20vw",
    padding: `calc(${variables.normal_gap} / 2)`,
  },
  [`@media ${variables.media.bigscreen}`]: {
    inputCell: {
      maxWidth: "15vw",
    },
  },
});
const PricingTable = ({
  rooms,
  distribution_periods,
  currencies,
  companyCurrency,
  pax_groups,
  onPriceChange,
  onPricingCopy,
  onPricingReset,
}) => {
  const [links, setLinks] = useState({});

  const currSymbol = currencies.find(
    (currency) => currency.notation == companyCurrency
  ).symbol;

  const meeting_price_groups = _.groupBy(
    distribution_periods[0].pricing_data,
    "meeting_group_uid"
  );

  const classes = styles();

  const { setShowPaxSCallingModal } = useContext(AddonModalsContext);

  return (
    <Panel
      className="CustomPanel PricingTable"
      header={
        <React.Fragment>
          <h5>Pricing Table</h5>
          <span className="CustomPanel__actions">
            <Button
              color="blue"
              size="sm"
              onClick={() => setShowPaxSCallingModal(true)}>
              Edit Pax Scalling
            </Button>
          </span>
        </React.Fragment>
      }
      shaded>
      <table className={classes.table}>
        <thead className={classes.tableHead}>
          <tr>
            <th>Name</th>
            <th>Distribution From To</th>
            <th>Operation From To</th>
            {Object.entries(meeting_price_groups).map((entry, idx) => (
              <th key={idx}>Pick-up Group {idx + 1} Price</th>
            ))}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {distribution_periods.map((period, idx) => (
            <tr key={idx} className={idx % 2 === 0 ? classes.darkRow : ""}>
              <td className={classes.tableCell}>{period.name}</td>
              <td className={classes.tableCell}>
                {`${naString(
                  formatDate(period.distribution_from)
                )} - ${naString(formatDate(period.distribution_to))}`}
              </td>
              <td className={classes.tableCell}>
                {`${naString(formatDate(period.operation_from))} - ${naString(
                  formatDate(period.operation_to)
                )}`}
              </td>
              {Object.entries(meeting_price_groups).map((entry, eidx) => {
                const pax_group_prices = _.groupBy(
                  period.pricing_data.filter(
                    (pd) => pd.meeting_group_uid == entry[0]
                  ),
                  "pax_group_uid"
                );

                return (
                  <td className={classes.inputCell} key={eidx}>
                    <PriceCell
                      rooms={rooms}
                      distIdx={period.distIdx}
                      pax_group_prices={pax_group_prices}
                      pax_groups={pax_groups}
                      links={links}
                      currSymbol={currSymbol}
                      onPriceChange={onPriceChange}
                      onLinkChange={(key, value) =>
                        setLinks((p) => update(p, { [key]: { $set: value } }))
                      }
                    />
                  </td>
                );
              })}
              <td className={classes.tableCell}>
                <RowAction
                  idx={period.distIdx}
                  copyAvailable={
                    distribution_periods.length > 1 &&
                    period.distIdx + 1 !== distribution_periods.length
                  }
                  onReset={onPricingReset}
                  onCopy={onPricingCopy}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Panel>
  );
};
PricingTable.propTypes = {
  rooms: PropTypes.array.isRequired,
  currencies: PropTypes.array.isRequired,
  companyCurrency: PropTypes.string.isRequired,
  distribution_periods: PropTypes.array.isRequired,
  pax_groups: PropTypes.array.isRequired,
  onPriceChange: PropTypes.func.isRequired,
  onPricingReset: PropTypes.func.isRequired,
  onPricingCopy: PropTypes.func.isRequired,
  onPriceTypeChange: PropTypes.func.isRequired,
  onShowPaxScalling: PropTypes.func.isRequired,
};
export default PricingTable;
