import { InlineIcon } from "@iconify/react";
import { fetchChatOffer } from "@src/api/chat";
import { WhisperWrapper } from "@src/components/common/ui_helpers";
import { variables } from "@src/jsssetup";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { AvatarComp } from "../helpers";

const connStatus = {
  open: "#2AD200",
  notOpen: "#FF6600",
};
const styles = createUseStyles({
  Header: {
    display: "grid",
    gridTemplateColumns: "15rem 1fr",
    gridGap: variables.double_gap,
    alignItems: "end",
    color: "white",
  },
  mainPanel: {
    alignSelf: "center",
  },
  mainPanelIcon: {
    fontSize: "x-large",
  },
  mainPanelTitle: {
    fontSize: "x-large",
  },
  content: {
    display: "grid",
  },
  selectedChatPanel: {
    display: "grid",
    gridTemplateColumns: "max-content 1fr max-content",
    gridGap: variables.half_gap,
    alignItems: "center",
  },
  emailsContr: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: variables.double_gap,
    paddingLeft: `calc(${variables.normal_gap} * 3)`,
  },
  chatNameContr: {
    display: "grid",
    fontSize: "medium",
  },
  chatName: {
    fontSize: "large",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  chatParticipants: {
    fontSize: "small",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  offerOwner: {
    fontSize: "small",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "95%",
  },
  connectionStatus: (props) => ({
    height: variables.normal_gap,
    width: variables.normal_gap,
    borderRadius: "50%",
    background: props.connectionOpen ? connStatus.open : connStatus.notOpen,
  }),
});
const Header = ({ selectedGroup, connectionOpen }) => {
  const [offerData, setOfferData] = useState(null);
  const { uid } = selectedGroup;

  const moreThanTwoMembers =
    _.get(selectedGroup, "members", []).filter(
      (member) => member.user_type === "customer"
    ).length > 1;

  const classes = styles({ moreThanTwoMembers, connectionOpen });

  useEffect(() => {
    if (!uid && !offerData) {
      return;
    }
    fetchChatOffer({ chat_group_uid: uid }).then((data) => setOfferData(data));
  }, [uid]);

  return (
    <div className={classes.Header}>
      <div className={classes.mainPanel}>
        <InlineIcon icon="mdi:user" className={classes.mainPanelIcon} />
        <span className={classes.mainPanelTitle}>Chats</span>
      </div>
      <div className={classes.content}>
        {selectedGroup.members.length > 0 && (
          <div className={classes.selectedChatPanel}>
            <AvatarComp
              members={selectedGroup.members.filter(
                (member) => member.user_type === "customer"
              )}
              moreThanTwo={moreThanTwoMembers}
              toolTipPlacement="bottom"
            />
            <div className={classes.chatNameContr}>
              <div className={classes.chatName}>{selectedGroup.name}</div>
              {offerData ? `(${_.get(offerData, "reference", "")})` : null}
            </div>
            <WhisperWrapper
              placement="bottom"
              msg={
                connectionOpen ? "Connection active" : "Connection in-active"
              }>
              <div className={classes.connectionStatus} />
            </WhisperWrapper>
          </div>
        )}
        {offerData && (
          <div className={classes.emailsContr}>
            <WhisperWrapper
              placement="bottom"
              msg={_.get(offerData, "offer_emails", [])}>
              <div className={classes.chatParticipants}>
                {_.get(offerData, "offer_emails", [])
                  .map((email) => email)
                  .join(", ")}
              </div>
            </WhisperWrapper>

            <div className={classes.offerOwner}>
              <strong>For: </strong>
              {_.get(offerData, "offer_email", "")}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
Header.defaultProps = {
  selectedGroup: {},
  connectionOpen: false,
};
Header.propTypes = {
  connectionOpen: PropTypes.bool,
  selectedGroup: PropTypes.object,
};
export default Header;
