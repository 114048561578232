import React from "react";
import { Schema, Tag } from "rsuite";
const { ArrayType } = Schema.Types;
import _ from "lodash";

import TransTxt from "@src/components/common/SxFormatMessage";
import { initialPolicyBaseDataForm } from "@src/forms/Admin/Columns/policyCommonBaseForm";

export const initialGeoPolicyFilterForm = {
  name: {
    value: "",
    verbose: <TransTxt id="policy_name" startCase />,
  },
  pax: {
    value: [1, 40],
    verbose: "pax",
  },
  distribution: {
    value: [],
    placeholder: "YYYY-MM-DD~YYYY-MM-DD",
    placement: "auto",
    verbose: <TransTxt id="distribution_period" startCase />,
  },
  reservation: {
    value: [],
    placeholder: "YYYY-MM-DD~YYYY-MM-DD",
    placement: "auto",
    verbose: <TransTxt id="reservation_period" startCase />,
  },
  sales_partners: {
    value: [],
    verbose: <TransTxt id="sales_partners" startCase />,
    data: [
      {
        value: "BR",
        label: <TransTxt id="branches" startCase />,
      },
      {
        value: "SA",
        label: <TransTxt id="sub_agents" startCase />,
      },
      {
        value: "IN",
        label: <TransTxt id="independent_agents" startCase />,
      },
      {
        value: "AM",
        label: <TransTxt id="area_managers" startCase />,
      },
    ],
  },
  territory: {
    value: [],
    verbose: "",
  },
  created: {
    value: [],
    placeholder: "YYYY-MM-DD~YYYY-MM-DD",
    verbose: <TransTxt id="created_date" startCase />,
    placement: "left",
  },
  creator: {
    value: "",
    verbose: <TransTxt id="created_by" startCase />,
  },
  travel_services: {
    value: [],
    verbose: "Travel Services",
  },
  last_edit: {
    value: [],
    placeholder: "YYYY-MM-DD~YYYY-MM-DD",
    verbose: <TransTxt id="last_edited_date" startCase />,
    placement: "auto",
  },
  status: {
    value: [],
    verbose: "Status",
    data: [
      {
        value: "IN",
        label: <Tag color="red">Inactive</Tag>,
        display_name: <TransTxt id="inactive" startCase />,
      },
      {
        value: "AC",
        label: <Tag color="green">Active</Tag>,
        display_name: <TransTxt id="active" startCase />,
      },
      {
        value: "CA",
        label: <Tag color="violet">Candidate</Tag>,
        display_name: <TransTxt id="candidate" startCase />,
      },
    ],
  },
  AF: {
    value: [],
    verbose: "AF",
  },
  AS: {
    value: [],
    verbose: "AS",
  },
  EU: {
    value: [],
    verbose: "EU",
  },
  NA: {
    value: [],
    verbose: "NA",
  },
  SA: {
    value: [],
    verbose: "SA",
  },
  AU: {
    value: [],
    verbose: "AU",
  },
  AN: {
    value: [],
    verbose: "AN",
  },
};

export const initialGeoPolicyBaseDataForm = _.merge(
  {},
  {
    market: {
      value: "B2B",
      verbose: "market",
      disabled: true,
    },
    sales_partners: {
      value: [],
      verbose: "sales_partners",
      validator: ArrayType().isRequired("Sales Partners is required"),
    },
    territory: {
      value: [],
      verbose: "Territory",
    },
    AF: {
      value: [],
      verbose: "AF",
    },
    AS: {
      value: [],
      verbose: "AS",
    },
    EU: {
      value: [],
      verbose: "EU",
    },
    NA: {
      value: [],
      verbose: "NA",
    },
    SA: {
      value: [],
      verbose: "SA",
    },
    AU: {
      value: [],
      verbose: "AU",
    },
    AN: {
      value: [],
      verbose: "AN",
    },
  },
  initialPolicyBaseDataForm
);

export const defaultOption = {
  basic: {
    value: ["policy_name", "status"],
  },
};

export * from "./columns";
