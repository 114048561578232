import _ from "lodash";

import {
  TRIPPLANNER_TRP_ADHOC_ADD,
  TRIPPLANNER_TRP_ADHOC_REMOVE,
  TRIPPLANNER_TRP_ADHOC_UPDATE,
} from "@src/actions/Project/TripPlanner/addhoc/transportation/types";
import {
  TRIPPLANNER_INIT,
  TRIPPLANNER_LOAD_TRIP,
} from "@src/actions/Project/TripPlanner/types";
import { TRIPPLANNER_SAVE_SERVICE_BOOKING_METADATA } from "@src/actions/Project/TripPlanner/step_four/service_remarks/types";

import update from "immutability-helper";
import { TRIPPLANNER_SAVE_TRIP_LEGS } from "@src/actions/Project/TripPlanner/transportation/index.js";
import { TRIPPLANNER_RESET_TRIP_LEGS } from "@src/actions/Project/TripPlanner/transportation";

export const tripPlanTrpAddhocServices = (state = { legs: [] }, action) => {
  switch (action.type) {
    case TRIPPLANNER_INIT: {
      return { legs: [] };
    }
    case TRIPPLANNER_LOAD_TRIP: {
      const { data } = action;
      return _.get(data, "trp_addhoc_srv_data", {});
    }
    case TRIPPLANNER_TRP_ADHOC_ADD: {
      const {
        addhoc_service_type,
        srvType,
        service,
        origin_order,
        destination_order,
      } = action;

      return {
        ...state,
        legs: [
          ...state.legs,
          {
            addhoc_service_type,
            srvType,
            service,
            origin_order,
            destination_order,
          },
        ],
      };
    }
    case TRIPPLANNER_TRP_ADHOC_UPDATE: {
      const { srv_type, service, srv_id } = action;

      return update(state, {
        legs: {
          $apply: (legs) => {
            const newLegs = _.cloneDeep(legs).map((srv) => {
              if (srv.transportation_service_type !== srv_type) {
                return srv;
              }

              if (srv.service.uid !== srv_id) {
                return srv;
              }

              srv.service = service;
              return srv;
            });

            return newLegs;
          },
        },
      });
    }
    case TRIPPLANNER_TRP_ADHOC_REMOVE: {
      const { service_uid } = action;
      const tmp = _.cloneDeep(state);
      tmp.legs = tmp.legs.filter((srv) => srv.service.uid !== service_uid);
      return tmp;
    }
    case TRIPPLANNER_SAVE_SERVICE_BOOKING_METADATA: {
      const { uid, srv_type, booking_metadata } = action;

      const newState = _.cloneDeep(state) || { legs: [] };

      const services = _.flatten(Object.values(newState));

      const service = services.find(
        (srv) => srv.service.uid === uid && srv.addhoc_service_type === srv_type
      );
      if (!service) {
        return state;
      }

      service.service.booking_metadata = booking_metadata;
      return newState;
    }
    default:
      return state;
  }
};

export const tripPlannerLegsReducer = (state = [], action) => {
  switch (action.type) {
    case TRIPPLANNER_INIT: {
      return [];
    }
    case TRIPPLANNER_LOAD_TRIP: {
      const { data } = action;
      return data?.trip_legs ?? [];
    }
    case TRIPPLANNER_SAVE_TRIP_LEGS: {
      return action.legs;
    }
    case TRIPPLANNER_RESET_TRIP_LEGS: {
      return [];
    }
    default:
      return state;
  }
};
