import _ from "lodash";

const validateLeg = (leg, legType) => {
  const errorType =
    legType == "inbound"
      ? "Transportation Hub to Hotel"
      : "Hotel to Transportation Hub";
  const msgs = [];

  if (_.get(leg, "start", "") == "") {
    msgs.push([`${errorType}`, "Pickup datetime information missing."]);
  }

  if (_.get(leg, "vehicle.name", "") == "") {
    msgs.push([`${errorType}`, "Vehicle information missing."]);
  }

  if (_.get(leg, "distance", 0) == 0) {
    msgs.push([`${errorType}`, "Distance information missing."]);
  }

  if (_.get(leg, "duration", 0) == 0) {
    msgs.push([`${errorType}`, "Duration information missing."]);
  }

  return msgs;
};

const validateTransfer = (transfer, transfer_type) => {
  var msgs = [];

  if (transfer.origin.name == "") {
    msgs.push(["Pickup", "Pickup point information missing."]);
  }

  if (transfer.destination.name == "") {
    msgs.push(["Drop Off", "Drop Off point information missing."]);
  }

  if (["inbound_outbound", "inbound"].includes(transfer_type)) {
    msgs = [
      ...msgs,
      ...validateLeg(transfer.inbound, "inbound"),
      ...validateLeg(transfer.outbound, "outbound"),
    ];
  } else {
    msgs = [...msgs, ...validateLeg(transfer.outbound, "outbound")];
  }

  return msgs;
};

export default validateTransfer;
