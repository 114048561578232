import { CHANGE_LANG } from "./types";
import { FETCHED_TRAVEL_SERVICE_OPTIONS } from "@src/actions/Shares/types";

import { getTravelServicesOptions } from "@src/api";
import { fetchUserOptions } from "@src/actions/Admin/Users";
import { fetchAffiliatesOptions } from "@src/actions/Network/Affiliates";
import { fetchBranchesOptions } from "@src/actions/Network/Branches";
import { fetchSubAgentsOptions } from "@src/actions/Network/SubAgents";
import { fetchIndependentAgentsOptions } from "@src/actions/Network/IndependentAgents";
import { fetchCompanyOptions } from "../Admin/Company";
import { fetchTravelSrvOptions } from "../Shares";

export const changeLang = ({ lang, updateOPTIONS = false }) => async (
  dispatch
) => {
  await dispatch(fetchTravelSrvOptions(lang));
  dispatch(fetchCompanyOptions(lang));
  dispatch(fetchUserOptions(lang));

  if (updateOPTIONS) {
    dispatch(fetchAffiliatesOptions(lang));
    dispatch(fetchBranchesOptions(lang));
    dispatch(fetchSubAgentsOptions(lang));
    dispatch(fetchIndependentAgentsOptions(lang));
  }

  dispatch(changeLangCode(lang));
};

export const changeLangCode = (data) => {
  return {
    type: CHANGE_LANG,
    lang: data,
  };
};

export const fetchTravelServiceOptionsWithLang = (langCode) => (
  dispatch,
  getState
) => {
  const lang = getState().setLang;
  getTravelServicesOptions(langCode || lang).then((data) => {
    dispatch({
      type: FETCHED_TRAVEL_SERVICE_OPTIONS,
      data: {
        ...data.actions.POST,
      },
    });
  });
};
