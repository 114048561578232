import { cardStyles, variables } from "@src/jsssetup";
import tinycolor from "tinycolor2";

export const rsrvFeebackStyles = {
  padding: variables.normal_gap,
  display: "grid",
  gridTemplateRows: "auto 1fr",
  gridGap: variables.normal_gap,
  alignItems: "center",
  justifyItems: "center",
  height: "100%",
  overflow: "auto",
};

export const rsrvFeedbackMainCardStyles = {
  boxShadow: variables.shadows.normalShadow,
  borderRadius: `calc(${variables.normal_gap} / 2)`,
};

export const rsrvFeedbackColorCardStyles = {
  ...cardStyles.card,
  display: "grid",
  gridGap: variables.normal_gap,
  gridAutoRows: "max-content",
  borderRadius: "10px",
  backgroundColor: variables.colors.easy.orange,
  color: "white",
  padding: "20px",
};

export const genericRsrvFeebackStyles = {
  card: {
    border: "solid 1px rgba(0, 0, 0, 0.12)",
    boxShadow: variables.shadows.normalShadow,
    borderRadius: `calc(${variables.normal_gap} / 2)`,
    display: "grid",
    gridTemplateRows: "auto 1fr auto",
  },
  cardHeader: {
    padding: variables.normal_gap,
    display: "grid",
    gridTemplateColumns: "1fr auto",
    gridGap: variables.half_gap,
    color: "black",
    fontSize: "1.5rem",
  },
  cardBody: {
    padding: variables.normal_gap,
    display: "grid",
    gridGap: variables.normal_gap,
    overflow: "auto",
  },
};

export const genericInfoGroupStyles = {
  infoCouple: {
    display: "grid",
    gridTemplateRows: "repeat ( 2, 1fr )",
  },
  infoCoupleTitle: {
    fontSize: "1rem",
    color: "gray",
  },
  infoCoupleValue: {
    fontSize: "1rem",
    fontWeight: "bold",
    color: variables.colors.text.dark,
  },
};

export const genericButtonsStyles = {
  buttonsGroup: {
    display: "grid",
    gridTemplateColumns: "max-content max-content",
    gridGap: variables.half_gap,
  },
  confirmButton: {
    border: `solid 1px ${variables.colors.easy.orange}`,
    borderRadius: "2px",
    backgroundColor: variables.colors.easy.orange,
    color: "white",
    fontSize: "0.9rem",
    padding: "0.3em 1em 0.3em 1em",
  },
  replyButton: {
    border: `solid 1px ${variables.colors.easy.lightOrange2}`,
    borderRadius: "2px",
    backgroundColor: variables.colors.easy.lightOrange2,
    color: "white",
    fontSize: "0.9rem",
    padding: "0.3em 1em 0.3em 1em",
  },
  cancelButton: {
    border: `solid 1px ${variables.colors.easy.orange}`,
    borderRadius: "2px",
    backgroundColor: "white",
    color: variables.colors.easy.orange,
    fontSize: "0.9rem",
    padding: "0.3em 1em 0.3em 1em",
  },
  deleteButton: {
    border: `solid 1px red`,
    borderRadius: "2px",
    backgroundColor: "white",
    color: "red",
    fontSize: "0.9rem",
    padding: "0.3em 1em 0.3em 1em",
  },
};

//   ================ Tables ==============
export const rsrvFeebackTableHeadStyles = {
  // "background": variables.colors.background.base,
  "borderBottom": `2px solid ${variables.colors.easy.orange}`,
  "& th": {
    padding: `calc(${variables.normal_gap} / 2)`,
  },
  "& td": {
    fontWeight: "bold",
  },
};
export const rsrvFeebackTableStyles = {
  table: {
    "width": "100%",
    "& > tbody > tr:nth-child(2n)": {
      background: tinycolor(variables.colors.background.light)
        .lighten(5)
        .toHexString(),
    },
  },
  verticalTable: {
    width: "100%",
  },
  head: {
    ...rsrvFeebackTableHeadStyles,
    color: "gray",
    fontSize: "1rem",
  },
  horizontalHead: {
    borderRight: `2px solid ${variables.colors.easy.orange}`,
    color: "gray",
    fontSize: "1rem",
  },
  cell: {
    padding: variables.normal_gap,
    textAlign: "center",
    fontSize: "1rem",
    fontWeight: "bold",
    color: variables.colors.text.dark,
  },
};
