import service from "@src/api/request";

export function getMarketPolicyList(params) {
  return service({
    url: `/bid/pricing-policy/`,
    method: "get",
    params: params,
  });
}

export function getMarketPolicyOptions() {
  return service({
    url: "/bid/pricing-policy/",
    method: "options",
  });
}

export function addNewMarketPolicy(data) {
  return service({
    url: "/bid/pricing-policy/",
    method: "post",
    data: data,
  });
}

export function getGeoPolicyGraphData(data) {
  return service({
    url: "/bid/geo-policy-statistics/",
    method: "get",
    params: data,
  });
}

export function getGroupPolicyGraphData(data) {
  return service({
    url: "/bid/group-policy-statistics/",
    method: "get",
    params: data,
  });
}

export function getGroupPolicyEditData(data) {
  return service({
    url: `/bid/pricing-policy/${data}`,
    method: "get",
  });
}

export function submitGroupPolicyProfile(id, payload, mode) {
  return service({
    url: `/bid/pricing-policy/${id}/`,
    method: mode,
    data: payload,
  });
}

export function getB2CPolicyList(params) {
  return service({
    url: `/bid/b2c-pricing-policy/`,
    method: "get",
    params: params,
  });
}

export function addNewB2CPolicy(data) {
  return service({
    url: "/bid/b2c-pricing-policy/",
    method: "post",
    data: data,
  });
}

export function getB2CPolicyEditData(id) {
  return service({
    url: `/bid/b2c-pricing-policy/${id}`,
    method: "get",
  });
}

export function submitB2CPolicy(id, payload, mode) {
  return service({
    url: `/bid/b2c-pricing-policy/${id}/`,
    method: mode,
    data: payload,
  });
}
