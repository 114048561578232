import { Notification } from "rsuite";
import React from "react";

export const notifyCommonSuccess = (msg) => {
  Notification.success({
    title: "Success",
    description: <p>{msg}</p>,
    duration: 3000,
  });
};
