import { modalGenericStyles, variables } from "@src/jsssetup";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import { Loader } from "rsuite";
import Ordering from "../Stats/Ordering";
import StatsCharts from "../Stats/Charts/Components/StatsCharts";
import { Form, Formik } from "formik";
import {
  getAccommodationStats,
  getAddonStats,
  getFlightStats,
  getTransferStats,
} from "@src/api";
import { getActivityStats } from "@src/api/Operation/Reservations/Activities";
import { getAdhocAccStats } from "@src/api/Operation/Reservations/Accommodation/adhoc";
import { getAPIFerryStats } from "@src/api/Operation/Reservations/APIFerries";
import { getAPITrainStats } from "@src/api/Operation/Reservations/APITrains";
import { getCarRentalStats } from "@src/api/Operation/Reservations/CarRental";
import { getCoachStats } from "@src/api/Operation/Reservations/Coach";
import { getCoordinatorStats } from "@src/api/Operation/Reservations/Coordinators";
import { getContractedAccStats } from "@src/api/Operation/Reservations/ContractedAccommodations";
import { getFerryStats } from "@src/api/Operation/Reservations/Ferries";
import { getGeneralServiceStats } from "@src/api/Operation/Reservations/GeneralServices";
import { getRestaurantStats } from "@src/api/Operation/Reservations/Restaurants";
import { getTrainStats } from "@src/api/Operation/Reservations/Trains";
import { useQuery } from "@tanstack/react-query";
import _ from "lodash";
import { useSelector } from "react-redux";
import { InlineIcon } from "@iconify/react";
import Filters from "../Stats/Charts/Components/Filters";
import { getUserSourceEntitySelector } from "@src/selectors/Shared/user_selectors";
import ACCSalesReportCharts from "../Stats/Charts/Components/SalesReports/ACCSalesReports";
import ACCTopSales from "../Stats/Charts/Components/TopSales/ACCTopSales";
import ACCTrends from "../Stats/Charts/Components/Trends/ACCTrends";
import ACCFinanceCharts from "../Stats/Charts/Components/Finance/ACCFinance";
import TRFSalesReportCharts from "../Stats/Charts/Components/SalesReports/TRFSalesReports";
import TRFTopSales from "../Stats/Charts/Components/TopSales/TRFTopSales";
import TRFFinanceCharts from "../Stats/Charts/Components/Finance/TRFFinance";
import TRFTrends from "../Stats/Charts/Components/Trends/TRFTrends";
import { toast } from "react-toastify";
import FLSalesReportCharts from "../Stats/Charts/Components/SalesReports/FLSalesReports";
import FLTopSales from "../Stats/Charts/Components/TopSales/FLTopSales";
import FLFinanceCharts from "../Stats/Charts/Components/Finance/FLFinance";
import FLTrends from "../Stats/Charts/Components/Trends/FLTrends";
import ACTSalesReportCharts from "../Stats/Charts/Components/SalesReports/ACTSalesReports";
import ACTTopSales from "../Stats/Charts/Components/TopSales/ACTTopSales";
import ACTFinanceCharts from "../Stats/Charts/Components/Finance/ACTFinance";
import ACTTrends from "../Stats/Charts/Components/Trends/ACTTrends";
import ADHACCSalesReportCharts from "../Stats/Charts/Components/SalesReports/ADHACCSalesReports";
import ADHACCTopSales from "../Stats/Charts/Components/TopSales/ADHACCTopSales";
import ADHACCFinanceCharts from "../Stats/Charts/Components/Finance/ADHACCFinance";
import ADHACCTrends from "../Stats/Charts/Components/Trends/ADHACCTrends";
import CUACCSalesReportCharts from "../Stats/Charts/Components/SalesReports/CUACCSalesReports";
import CUACCTopSales from "../Stats/Charts/Components/TopSales/CUACCTopSales";
import CUACCFinanceCharts from "../Stats/Charts/Components/Finance/CUACCFinance";
import CUACCTrends from "../Stats/Charts/Components/Trends/CUACCTrends";
import MISalesReportCharts from "../Stats/Charts/Components/SalesReports/MISalesReports";
import MITopSales from "../Stats/Charts/Components/TopSales/MITopSales";
import MIFinanceCharts from "../Stats/Charts/Components/Finance/MIFinance";
import MITrends from "../Stats/Charts/Components/Trends/MITrends";
import TransTxt from "@src/components/common/SxFormatMessage";

const styles = createUseStyles({
  ...modalGenericStyles,
  DashboardACCModal: modalGenericStyles.modal,
  cardHeader: {
    ...modalGenericStyles.cardHeader,
    display: "grid",
    gridTemplateColumns: "1fr max-content",
    backgroundColor: variables.colors.background.creme,
    color: variables.colors.text.colored,
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
  },
  title: {
    display: "grid",
    fontWeight: "600",
    fontSize: "1rem",
    fontFamily: "futura,sans-serif",
  },
  logoXContainer: {
    display: "grid",
  },
  logoX: {
    "display": "grid",
    "justifyItems": "end",
    "alignItems": "center",
    "height": "1.5rem",
    "cursor": "pointer",
    "& svg": {
      alignSelf: "center",
      height: "100%!important",
      width: "100%!important",
    },
  },
  card: {
    ...modalGenericStyles.card,
    maxWidth: "85rem",
    width: "86rem",
    borderTopRightRadius: "10px",
    borderTopRightLeft: "10px",
  },
  cardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    gridTemplateColumns: "max-content 19rem",
  },
  filters: {
    display: "grid",
    gridAutoFlow: "row",
    padding: variables.normal_gap,
    backgroundColor: "#F7F7FA",
    height: "56.5rem",
    borderRadius: "10px",
    boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.12)",
  },
  rightInfo: {
    display: "grid",
    gridAutoFlow: "row",
    padding: variables.normal_gap,
  },
  ordering: {
    display: "grid",
  },
  stats: {},

  info: {
    display: "grid",
    justifyItems: "center",
    alignItems: "center",
  },

  charts: {
    display: "grid",
    gridTemplateRows: "max-content",
    gap: variables.normal_gap,
  },
  divider: {
    justifySelf: "center",
    alignSelf: "center",
    height: "85%",
    border: `1px solid rgba(0, 0, 0, 0.1)`,
    gridColumn: "1 span",
    width: "0%",
  },
  timeBarPieCharts: {
    display: "grid",
  },
  chart: { dispaly: "grid" },
  chart2: { dispaly: "grid" },
  chart3: { dispaly: "grid" },
  chart4: { dispaly: "grid" },
  chart5: { dispaly: "grid" },
});

const DashboardACCModal = ({ dashboardPayload, onClose }) => {
  const classes = styles();
  const [filters, setFilters] = useState({
    ...dashboardPayload,
    selectedDays: null,
  });

  const funcMapping = {
    ACC: getAccommodationStats,
    ACT: getActivityStats,
    MI: getAddonStats,
    ADHACC: getAdhocAccStats,
    APIFER: getAPIFerryStats,
    APITRA: getAPITrainStats,
    CAR: getCarRentalStats,
    COA: getCoachStats,
    COO: getCoordinatorStats,
    CUACC: getContractedAccStats,
    FER: getFerryStats,
    FL: getFlightStats,
    GEN: getGeneralServiceStats,
    RES: getRestaurantStats,
    TRA: getTrainStats,
    TRF: getTransferStats,
  };

  const componentMapping = {
    ACC: [ACCSalesReportCharts, ACCTopSales, ACCTrends, ACCFinanceCharts],
    TRF: [TRFSalesReportCharts, TRFTopSales, TRFTrends, TRFFinanceCharts],
    FL: [FLSalesReportCharts, FLTopSales, FLTrends, FLFinanceCharts],
    ACT: [ACTSalesReportCharts, ACTTopSales, ACTTrends, ACTFinanceCharts],
    ADHACC: [
      ADHACCSalesReportCharts,
      ADHACCTopSales,
      ADHACCTrends,
      ADHACCFinanceCharts,
    ],
    CUACC: [
      CUACCSalesReportCharts,
      CUACCTopSales,
      CUACCTrends,
      CUACCFinanceCharts,
    ],
    MI: [MISalesReportCharts, MITopSales, MITrends, MIFinanceCharts],
  };
  const renderingComponents = componentMapping[dashboardPayload.serviceType];

  const featureFlag = useSelector((state) => state.authFeatureFlagData);
  const source_entity = useSelector((state) =>
    getUserSourceEntitySelector(state)
  );

  const getPayload = (filters) => {
    const is_admin = _.get(featureFlag, "groups", []).includes("admin");
    let vFilters = {};
    Object.entries(filters)
      .filter((entry) => entry[1])
      .forEach((entry) => (vFilters[entry[0]] = entry[1]));

    if (filters.source_entity_autocomplete) {
      const splittedValue = filters.source_entity_autocomplete.split("---");
      vFilters["source_entity"] = `${splittedValue[1]}___subagent`;
    }
    if (_.get(vFilters, "created__lte")) {
      vFilters["created__lte"] = vFilters["created__lte"].split("T")[0];
    }
    if (_.get(vFilters, "created__gte")) {
      vFilters["created__gte"] = vFilters["created__gte"].split("T")[0];
    }
    var payload = {
      ...vFilters,
      is_admin,
      stats_source_entity: source_entity,
      new_data: true,
    };
    delete payload["source_entity_autocomplete"];
    delete payload["serviceType"];
    delete payload["selectedDays"];
    return payload;
  };

  const { data, isLoading } = useQuery({
    queryKey: [`${_.get(dashboardPayload, "serviceType")}_Chart`, filters],
    queryFn: async () => {
      return await funcMapping[_.get(filters, "serviceType")](
        getPayload(filters)
      );
    },
    onError: () => {
      onClose();
      toast.error(
        "We failed to complete this action. We are sorry for the inconvenience."
      );
    },
  });

  const statsData = _.get(data, "data", {});

  var currencies = [];
  if (!_.isEmpty(statsData)) {
    currencies = Object.keys(_.get(statsData, "stats_per_currency", {}));
  }

  return (
    <div className={classes.DashboardACCModal}>
      <div className={classes.card}>
        <React.Fragment>
          <div className={classes.cardHeader}>
            <div className={classes.title}>
              <TransTxt id="___B2B_stats_modal__dashboard" />
            </div>
            <div className={classes.logoX}>
              <InlineIcon icon="ph:x-bold" onClick={onClose} id="closeDashBoardModal"/>
            </div>
          </div>
          <Formik
            initialValues={{
              ...dashboardPayload,
              selectedDays: null,
            }}
            onSubmit={(values) => {
              setFilters(values);
            }}>
            {({ values }) => (
              <Form>
                <React.Fragment>
                  <div className={classes.cardBody}>
                    <div className={classes.filters}>
                      <Filters filters={values} />
                    </div>
                    <div className={classes.rightInfo}>
                      <div className={classes.ordering}>
                        <Ordering filters={filters} setFilters={setFilters} />
                      </div>
                      <div className={classes.charts}>
                        <div className={classes.charts}>
                          <div className={classes.chart}>
                            {isLoading && _.isEmpty(statsData) ? (
                              <Loader
                                backdrop
                                size="lg"
                                vertical
                                content={
                                  <TransTxt id="B2B_stats_modal__loaderMessage" />
                                }
                              />
                            ) : (
                              renderingComponents.map((Component, idx) => (
                                <Component key={idx} data={statsData} />
                              ))
                            )}
                          </div>
                        </div>
                        <div className={classes.chart5}>
                          <StatsCharts
                            currencies={currencies}
                            dashboardPayload={dashboardPayload}
                            data={statsData}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              </Form>
            )}
          </Formik>
        </React.Fragment>
      </div>
    </div>
  );
};
DashboardACCModal.propTypes = {
  dashboardPayload: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default DashboardACCModal;
