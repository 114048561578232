import _ from "lodash";
import {
  initSearchActivities,
  pollActivitiesSearch,
} from "@src/api/Project/activities";
import {
  getSetupFormDataSelector,
  getTripDestinationsSelector,
} from "@src/selectors/Project/TripPlanner";
import { getUserSourceEntitySelector } from "@src/selectors/Shared/user_selectors";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from "react-redux";
import { Drawer, Loader } from "rsuite";
import { variables } from "@src/jsssetup";
import { Rating } from "../../../OverviewAllAccommodation";
import { CustomButton } from "@src/components/common/buttons";
import { Icon, InlineIcon } from "@iconify/react";
import Fuse from "fuse.js";
import { toast } from "react-toastify";
import { ActivityDetails } from "./details";
import { ActivityFilters } from "./filters";
import { ActivityCxlTag, ActivityLanguages } from "./common";
import { handleNonValidImgFn } from "@src/tools/common_tools";
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
} from "react-virtualized";
import { useQuery } from "@tanstack/react-query";

const activityStyles = createUseStyles({
  Activity: (props) => ({
    display: "grid",
    gridTemplateColumns: props.services_collapsed
      ? "1fr"
      : `${props.mode === "short" ? "8rem" : "15rem"} 1fr`,
    gridTemplateRows: props.services_collapsed
      ? "unset"
      : props.mode === "short"
      ? "10rem"
      : "max-content",
    gridGap: variables.normal_gap,
    maxHeight: props.mode === "short" ? "10rem" : "15rem",
  }),
  header: (props) => ({
    display: "grid",
    gridTemplateColumns: props.services_collapsed
      ? "max-content 1fr max-content"
      : "1fr max-content",
    gridGap: variables.half_gap,
    alignItems: "center",
    padding: props.services_collapsed && [
      variables.half_gap,
      variables.half_gap,
      variables.half_gap,
      variables.double_gap,
    ],
    borderRadius: props.services_collapsed && variables.half_gap,
    boxShadow: props.services_collapsed && variables.shadows.normalShadow,
  }),
  metadata: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    alignItems: "center",
    gridGap: variables.normal_gap,
  },
  headerTitle: { fontSize: "medium", fontWeight: "600" },
  img: (props) => ({
    width: "100%",
    height: "100%",
    borderRadius: "5px",
    objectFit: "cover",
    boxShadow: variables.shadows.heavyShadow,
    maxHeight: props.mode === "short" ? "10rem" : "15rem",
  }),
  imgError: {
    width: "100%",
    height: "100%",
    borderRadius: "5px",
    objectFit: "cover",
    boxShadow: variables.shadows.heavyShadow,
  },
  content: {
    display: "grid",
    gridGap: variables.half_gap,
    gridAutoRows: "max-content",
    padding: variables.normal_gap,
    borderRadius: "5px",
    boxShadow: variables.shadows.heavyShadow,
    transition: "box-shadow .3s",
  },
  categories: {
    display: "grid",
    gridGap: variables.half_gap,
    gridTemplateColumns: "repeat(6, max-content)",
  },
  category: {
    padding: [`calc(${variables.half_gap} / 2)`, variables.half_gap],
    color: variables.colors.lightTextColor,
    background: "#000000",
    borderRadius: "5px",
    fontSize: "smaller",
  },
  selectBtn: { justifySelf: "end" },
  confirmation: {
    "fontWeight": "bold",
    "display": "grid",
    "gridTemplateColumns": "max-content 1fr",
    "gridGap": variables.half_gap,
    "alignItems": "center",
    "& .iconify": { background: "green", color: "white", borderRadius: "50%" },
  },
  attributes: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: variables.half_gap,
    alignItems: "end",
  },
  bottomContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(2, max-content)",
    gridGap: variables.normal_gap,
    justifyContent: "end",
    alignItems: "center",
    paddingTop: variables.half_gap,
    borderTop: `2px solid #E6E6E6`,
  },
  price: {
    "justifySelf": "end",
    "fontWeight": "bold",
    "fontSize": "large",
    "&>small:first-child": { color: variables.colors.easy.orange },
  },
  iconCntr: {
    display: "grid",
    placeContent: "center",
    width: "2rem",
    height: "2rem",
    borderRadius: "50%",
    background: variables.colors.easy.orange,
    padding: variables.half_gap,
  },
  icon: { fontSize: "x-large", color: variables.colors.text.light },
  actionBtns: {
    display: "grid",
    gridAutoFlow: "column",
    gridGap: variables.half_gap,
    placeItems: "center",
  },
  expandIconCntr: {
    display: "grid",
    placeContent: "center",
    width: "1.5rem",
    height: "1.5rem",
    borderRadius: "50%",
    background: variables.colors.easy.orange,
    padding: variables.half_gap,
    cursor: "pointer",
  },
  expandIcon: { fontSize: "large", color: variables.colors.text.light },
});
export const Activity = ({
  activity,
  mode = "normal",
  services_collapsed = false,
  toggleSelfCollapse,
  onViewDetails,
}) => {
  const refundable = activity.free_cancellation;
  const classes = activityStyles({ refundable, mode, services_collapsed });

  const rating = activity?.ratings?.average_rating ?? 0;
  const currency = activity?.price?.currency;

  const header = (
    <React.Fragment>
      <div className={`${classes.headerTitle} Activity__header`}>
        <h6
          title={activity.title}
          data-short={mode === "short" ? "true" : "false"}>
          {mode === "short" && "Activity: "}
          {activity.title}
        </h6>
      </div>
      {mode === "short" && typeof toggleSelfCollapse === "function" && (
        <div className={classes.actionBtns}>
          <div className={classes.expandIconCntr}>
            <InlineIcon
              className={classes.expandIcon}
              icon={
                services_collapsed
                  ? "ri:arrow-down-s-line"
                  : "ri:arrow-up-s-line"
              }
              onClick={toggleSelfCollapse}
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );

  return (
    <div className={`${classes.Activity} Activity`}>
      {services_collapsed ? (
        <div className={classes.header}>
          <div className={classes.iconCntr}>
            <InlineIcon
              className={classes.icon}
              icon="ic:baseline-local-activity"
            />
          </div>
          {header}
        </div>
      ) : (
        <React.Fragment>
          <img
            className={
              activity?.images?.[0]?.sm?.url ? classes.img : classes.imgError
            }
            src={activity?.images?.[0]?.sm?.url ?? ""}
            onError={(event) => handleNonValidImgFn(event, "ACT")}
          />
          <div className={classes.content}>
            <div className={classes.header}>{header}</div>
            {rating > 0 && (
              <div>
                <Rating rating={Math.ceil(rating)} />{" "}
              </div>
            )}
            <div className={classes.categories}>
              {(activity?.categories ?? []).map((cat, idx) => (
                <span className={classes.category} key={idx}>
                  {cat.label}
                </span>
              ))}
            </div>
            {mode === "normal" && <p>{activity.description}</p>}
            <div className={classes.metadata}>
              <ActivityLanguages
                languages={(activity?.languages ?? []).map((lng) => lng.name)}
              />
              <ActivityCxlTag refundable={refundable} />
              <ActivityLanguages
                label="Features"
                languages={(activity?.features ?? []).map((lng) => lng.label)}
              />
              {activity.instant_confirmation && (
                <span className={classes.confirmation}>
                  <Icon icon="mdi:tick" />
                  Innstant Confirmation
                </span>
              )}
            </div>
            {typeof onViewDetails === "function" && (
              <div className={classes.bottomContainer}>
                <div className={classes.price}>
                  <small>From </small>
                  {activity.price.value.toLocaleString("en-US", {
                    style: "currency",
                    currency,
                  })}{" "}
                  <small>per person</small>
                </div>
                <CustomButton
                  icon="material-symbols:keyboard-arrow-right"
                  placement="right"
                  onClick={onViewDetails}>
                  View Details
                </CustomButton>
              </div>
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
Activity.defaultProps = {
  mode: "normal",
  services_collapsed: false,
};
Activity.propTypes = {
  activity: PropTypes.object.isRequired,
  mode: PropTypes.oneOf(["short", "normal"]),
  services_collapsed: PropTypes.bool,
  onViewDetails: PropTypes.func.isRequired,
  toggleSelfCollapse: PropTypes.func,
};

const activitiesDrawerStyles = createUseStyles({
  ActivitiesDrawer: {
    "width": "60vw!important",
    "& .rs-drawer-content": {
      display: "grid",
      gridTemplateRows: "max-content 1fr",
      height: "100%",
      position: "relative",
      overflow: "visible",
    },
  },
  header: {
    "position": "relative",
    "background": variables.colors.background.darkCreme,
    "margin": 0,
    "padding": variables.normal_gap,
    "display": "grid",
    "gridAutoFlow": "column",
    "gridAutoColumns": "max-content",
    "alignItems": "center",
    "& button": {
      top: "50%!important",
      transform: "translateY(-50%)",
      right: `${variables.normal_gap}!important`,
    },
  },
  body: {
    padding: variables.normal_gap,
    paddingTop: variables.normal_gap,
    margin: 0,
    height: "100%!important",
  },
  activities: {
    display: "grid",
    gridGap: variables.normal_gap,
    height: "inherit",
  },
  [`@media ${variables.media.smallscreen}`]: {
    ActivitiesDrawer: { width: "80vw!important" },
  },
  [`@media ${variables.media.bigscreen}`]: {
    ActivitiesDrawer: { width: "60vw!important" },
  },
});
const ActivitiesDrawer = ({ searchDate, destOrder, onHide }) => {
  const [activityDetailsPage, setActivityDetailsPage] = useState(false);
  const classes = activitiesDrawerStyles({ activityDetailsPage });
  const [filters, setFilters] = useState({});
  const [destination, setDestination] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [shouldPoll, setShouldPoll] = useState(false);
  const [activities, setActivities] = useState([]);
  const [filteredActivities, setFilteredActivities] = useState([]);
  const [selectedActivityUid, setSelectedActivityUid] = useState(null);

  const { destinations, adults, children, source_entity } = useSelector(
    (state) => {
      const { adults, children } = getSetupFormDataSelector(state);
      const source_entity = getUserSourceEntitySelector(state);
      const destinations = getTripDestinationsSelector(state);
      return { destinations, adults, children, source_entity };
    }
  );

  const queryKey = [
    "activities_init_search",
    source_entity,
    destination?.geodata?.lat ?? 0,
    destination?.geodata?.lng ?? 0,
    searchDate,
  ];
  const { isLoading, isFetching } = useQuery({
    queryKey,
    queryFn: () => {
      const payload = {
        pax: { adults, children, children_ages: [] },
        date_from: searchDate,
        date_to: searchDate,
        latitude: destination.geodata.lat,
        longitude: destination.geodata.lng,
        radius: 50,
        source_entity,
      };
      return initSearchActivities({ payload });
    },
    onSuccess: (data) => {
      setSessionId(data?.data?.session_id);
      setShouldPoll(true);
    },
    onError: () => onHide(),
    enabled: !!destination?.id,
    refetchOnWindowFocus: false,
    staleTime: 1000,
  });
  const searchLoading = isLoading || isFetching;

  const { isLoading: pollIsLoading, isFetching: pollIsFetching } = useQuery({
    queryKey: ["activities_poll_search", sessionId],
    queryFn: () => pollActivitiesSearch({ session_id: sessionId }),
    onSuccess: (data) => {
      const pollData = data?.data;

      const results = pollData?.results ?? [];
      if (pollData.status === "done" && results.length === 0) {
        onHide();
        toast.warning(
          "No activities found, we are sorry for the inconvenience. Please try again later.",
          { autoClose: 6000 }
        );
        return;
      }

      if (pollData.status === "done") {
        const finalResults = results.map((r) => ({
          ...r,
          session_id: sessionId,
        }));
        setActivities(finalResults);
        setFilteredActivities(finalResults);
        setShouldPoll(false);
        return;
      }
    },
    onError: (error) => {
      const non_field_errors = error?.response?.data?.non_field_errors ?? [];
      if (non_field_errors.includes("Too early.")) return;
      setShouldPoll(false);
      toast.error(
        "An error occurred while fetching activities. Please try again later.",
        { autoClose: 5000 }
      );
      onHide();
    },
    enabled: shouldPoll,
    refetchOnWindowFocus: false,
    refetchInterval: shouldPoll ? 1000 : false,
    refetchIntervalInBackground: true,
  });
  const pollLoading = pollIsLoading || pollIsFetching;

  // Init destination
  useEffect(() => {
    const destination = destinations.find(
      (dest) => dest.order.toString() === destOrder.toString()
    );
    if (!destination) {
      onHide();
      return;
    } else {
      setDestination(destination);
    }
  }, []);

  const [activityVirtualizerCache, setActivityVirtualizerCache] = useState(
    new CellMeasurerCache({
      defaultHeight: 190,
      minHeight: 190,
      fixedWidth: true,
    })
  );

  // Apply filters
  useEffect(() => {
    if (_.isEmpty(filters)) return;

    var tmp = _.cloneDeep(activities);

    if (filters.title) {
      const fuseConfig = { keys: ["title"], threshold: 0.3 };
      const fuseData = new Fuse(tmp, fuseConfig);
      tmp = fuseData.search(filters.title);
    }

    if (filters.order) {
      const [key, ordering] = filters.order.split("__");
      tmp = _.orderBy(tmp, [key], [ordering]);
    }

    if (filters.categories) {
      tmp = tmp.filter((act) => {
        const actCategories = (act?.categories ?? []).map((cat) => cat.label);
        return _.intersection(actCategories, [filters.categories]).length > 0;
      });
    }

    if (filters.features) {
      tmp = tmp.filter((act) => {
        const actFeatures = (act?.features ?? []).map((feat) => feat.label);
        return _.intersection(actFeatures, [filters.features]).length > 0;
      });
    }

    if (filters.max_price) {
      tmp = tmp.filter((act) => {
        const actPrice = act?.price?.value ?? 0;
        return actPrice <= filters.max_price;
      });
    }

    setFilteredActivities(tmp);

    setActivityVirtualizerCache(
      new CellMeasurerCache({
        defaultHeight: 190,
        minHeight: 190,
        fixedWidth: true,
      })
    );
  }, [filters]);

  const loading = searchLoading || shouldPoll;

  return (
    <Drawer
      className={`${classes.ActivitiesDrawer} ActivitiesDrawer`}
      show={true}
      onHide={onHide}>
      <Drawer.Header className={classes.header}>
        <Drawer.Title>
          {loading
            ? "Searching. Please wait..."
            : `Found ${activities.length} Activities in ${
                destination?.name_en ?? ""
              }. Showing ${filteredActivities.length}.`}
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body
        className={classes.body}
        data-details={activityDetailsPage ? "true" : "false"}>
        {loading ? (
          <Loader center size="lg" content="Please wait..." />
        ) : selectedActivityUid ? (
          <ActivityDetails
            activity_uid={selectedActivityUid}
            session_id={sessionId}
            searchDate={searchDate}
            destination_id={`${destination.type}__${destination.id}`}
            destOrder={destination.order.toString()}
            onCancel={() => {
              setSelectedActivityUid(null);
              setActivityDetailsPage(false);
            }}
            onHideDrawer={onHide}
          />
        ) : (
          <React.Fragment>
            <ActivityFilters
              filters={filters}
              activities={activities}
              onApplyFilters={setFilters}
            />
            <div className={classes.activities}>
              <AutoSizer>
                {({ width, height }) => (
                  <List
                    height={height}
                    width={width}
                    rowCount={(filteredActivities ?? []).length}
                    overscanRowCount={10}
                    deferredMeasurementCache={activityVirtualizerCache}
                    rowHeight={activityVirtualizerCache.rowHeight}
                    scrollToIndex={0}
                    rowRenderer={({ index, key, parent, style }) => {
                      const activity = filteredActivities[index];

                      return !activity ? null : (
                        <CellMeasurer
                          cache={activityVirtualizerCache}
                          key={key}
                          parent={parent}
                          columnIndex={0}
                          rowIndex={index}>
                          {({ registerChild }) => {
                            return (
                              <div
                                ref={registerChild}
                                style={{
                                  ...style,
                                  paddingBottom: variables.double_gap,
                                  paddingRight: variables.normal_gap,
                                }}>
                                <Activity
                                  activity={activity}
                                  onViewDetails={() => {
                                    setSelectedActivityUid(activity.uid);
                                    setActivityDetailsPage(true);
                                  }}
                                />
                              </div>
                            );
                          }}
                        </CellMeasurer>
                      );
                    }}
                  />
                )}
              </AutoSizer>
            </div>
          </React.Fragment>
        )}
      </Drawer.Body>
    </Drawer>
  );
};
ActivitiesDrawer.propTypes = {
  searchDate: PropTypes.string.isRequired,
  destOrder: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  onHide: PropTypes.func.isRequired,
};

export default ActivitiesDrawer;
