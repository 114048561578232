import React from "react";
import { createUseStyles } from "react-jss";
import { GeneralStats } from "./../GeneralStats";
import CreatePieChart from "../../ChartComponents/CreatePieChart";
import CreateBarChart from "../../ChartComponents/CreateBarChart";
import { variables } from "@src/jsssetup";
import PropTypes from "prop-types";
import _, { capitalize } from "lodash";
import TransTxt from "@src/components/common/SxFormatMessage";
import { injectIntl } from "react-intl";

const trendsReportTable = createUseStyles({
  TrendsReportTable: {
    display: "grid",
    gridTemplateRows: "max-content",
  },
  tableContainer: {
    display: "grid",
    border: `2px solid #FF6600`,
    borderRadius: "10px",
    textAlign: "center",
  },
  table: {
    width: "100%",
  },
  tHead: {
    backgroundColor: "#FF6600",
  },
  headRow: {
    "background": "#FF6600",
    "& th:first-child": {
      borderTopLeftRadius: "10%",
    },
    "& th:last-child": {
      borderTopRightRadius: "10%",
    },
  },
  headCell: {
    backgroundColor: "#FF6600",
    textAlign: "center",
    color: "white",
    padding: variables.half_gap,
    fontFamily: "futura,sans-serif",
    fontWeight: "400",
  },
  tableCell: {
    textAlign: "center",
    padding: `${variables.half_gap} ${variables.normal_gap}`,
    fontFamily: "futura,sans-serif",
    height: "2rem",
  },
});

const TrendsReportTable = ({ data }) => {
  const classes = trendsReportTable();

  return (
    <div className={classes.TrendsReportTable}>
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead className={classes.tHead}>
            <tr className={classes.headRow}>
              <th className={classes.headCell}>
                <TransTxt id="B2B_stats_ACC_trends__count" />
              </th>
              <th className={classes.headCell}>
                <TransTxt id="B2B_stats_ACC_trends__avg_stay" />
              </th>
              <th className={classes.headCell}>
                <TransTxt id="B2B_stats_ACC_trends__avg_price_per_night" />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={classes.tableCell}>
                {_.get(data, "trends.count") !== 0
                  ? _.get(data, "trends.count") || "N/A"
                  : "0"}
              </td>
              <td className={classes.tableCell}>
                {_.get(data, "trends.avg_stay") !== 0
                  ? _.get(data, "trends.avg_stay") || "N/A"
                  : "0"}
              </td>
              <td className={classes.tableCell}>
                {_.get(data, "trends.price_per_stay.avg") !== 0
                  ? _.get(data, "trends.price_per_stay.currency")
                  : ""}{" "}
                {_.get(data, "trends.price_per_stay.avg") !== 0
                  ? _.get(data, "trends.price_per_stay.avg") || "N/A"
                  : "0"}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
TrendsReportTable.propTypes = {
  data: PropTypes.object.isRequired,
};

const styles = createUseStyles({
  ACCTrends: {
    display: "grid",
    padding: variables.normal_gap,
  },
  statsTable: {
    display: "grid",
    justifyItems: "center",
  },
  charts: {
    display: "grid",
    gridAutoFlow: "column",
    gridTemplateColumns: "22rem 1rem 22rem 1rem 22rem",
    justifyItems: "center",
  },
  chart1: {
    display: "grid",
  },
  chart2: {
    display: "grid",
  },
  chart3: {
    display: "grid",
  },
  divider: {
    justifySelf: "center",
    alignSelf: "center",
    height: "85%",
    border: `1px solid rgba(0, 0, 0, 0.1)`,
    gridColumn: "1 span",
    width: "0%",
  },
});

var ACCTrends = ({ intl, data }) => {
  const classes = styles();

  const nightStayPieChartData = _.get(data, "trends.night_stays").map(
    ([date, value]) => ({
      name: date,
      value,
    })
  );
  const nightStayTotal = nightStayPieChartData.reduce(
    (acc, stay) => acc + stay.value,
    0
  );
  const nightStayPercentages = nightStayPieChartData.map((stay) => ({
    name: capitalize(stay.name),

    value: ((stay.value / nightStayTotal) * 100).toFixed(2),
  }));

  const nightStayColors = [
    "#FF6600",
    "#B174FF",
    "#B8B8B8",
    "#B0754E",
    "#FFB381",
  ];
  const boardsBarChartData = _.get(data, "trends.boards.items").map(
    ([date, value]) => ({
      name: date,
      value,
    })
  );
  const boardsColors = ["#FF6600", "#FFB381", "#B8B8B8", "#B0754E", "#B174FF"];

  const roomTypePieChartData = _.get(data, "trends.room_types.items").map(
    ([date, value]) => ({
      name: date,
      value,
    })
  );
  const roomTypeTotal = roomTypePieChartData.reduce(
    (acc, room) => acc + room.value,
    0
  );
  const roomTypePercentages = roomTypePieChartData.map((room) => ({
    name: room.name,
    value: ((room.value / roomTypeTotal) * 100).toFixed(2),
  }));
  const roomTypesColors = [
    "#FFB381",
    "#B0754E",
    "#B8B8B8",
    "#FF6600",
    "#B174FF",
  ];

  return (
    <div className={classes.ACCTrends}>
      <div className={classes.stats}>
        <GeneralStats
          tableTitle={intl.formatMessage({
            id: "B2B_stats_ACC_trends__confirmed_reservations",
          })}
          title={intl.formatMessage({
            id: "B2B_stats_ACC_trends__confirmed_trends",
          })}
          startDate={_.get(data, "period.start_date")}
          endDate={_.get(data, "period.end_date")}
        />
        <div className={classes.statsTable}>
          <TrendsReportTable data={data} />
        </div>
      </div>
      <div className={classes.charts}>
        <div className={classes.chart1}>
          <CreatePieChart
            title={intl.formatMessage({
              id: "B2B_stats_ACC_trends__night_stay",
            })}
            bottom={90}
            colors={nightStayColors}
            data={nightStayPercentages}
          />
        </div>
        <div className={classes.divider}></div>
        <div className={classes.chart2}>
          <CreateBarChart
            title={intl.formatMessage({
              id: "B2B_stats_ACC_trends__boards",
            })}
            barWidth={"20%"}
            xAxisColor="#FF6600"
            seriesLabelColor="#FF6600"
            width={20}
            predefinedColors={boardsColors}
            data={boardsBarChartData}
          />
        </div>
        <div className={classes.divider}></div>
        <div className={classes.chart3}>
          <CreatePieChart
            title={intl.formatMessage({
              id: "B2B_stats_ACC_trends__room_type",
            })}
            bottom={90}
            colors={roomTypesColors}
            data={roomTypePercentages}
          />
        </div>
      </div>
    </div>
  );
};
ACCTrends.propTypes = {
  data: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  dashboardPayload: PropTypes.object.isRequired,
};
ACCTrends = injectIntl(ACCTrends);
export default ACCTrends;
