import { Notification } from "rsuite";
import React from "react";

export const notifyNoServicesForDate = (title = "Day By Day Services") => {
  Notification.warning({
    title,
    description: <p>We did not find any services for this date.</p>,
    duration: 6000,
  });
};

export const notifyAddOnNotAvailableForDate = () => {
  Notification.warning({
    title: "Add On Price",
    description: <p>Add On is not available on this date.</p>,
    duration: 6000,
  });
};

export const notifyAddOnPayloadError = (msg) => {
  Notification.warning({
    title: "Validation Error",
    description: <p>{msg}</p>,
    duration: 6000,
  });
};
