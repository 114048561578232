// //=============================LIST VIEW===========================
// export const VENDORS_ACCOMMODATION_BEGIN_FETCH_DATA =
//   "VENDORS_ACCOMMODATION_BEGIN_FETCH_DATA";
// export const VENDORS_ACCOMMODATION_END_FETCH_DATA =
//   "VENDORS_ACCOMMODATION_END_FETCH_DATA";
// export const VENDORS_ACCOMMODATION_FETCH_DATA =
//   "VENDORS_ACCOMMODATION_FETCH_DATA";
// export const VENDORS_ACCOMMODATION_FETCH_OPTIONS =
//   "VENDORS_ACCOMMODATION_FETCH_OPTIONS";
// //=============================FILTER RELATIONS========================
// export const VENDORS_ACCOMMODATION_INITIALIZE_LIST_FILTER_FORM =
//   "VENDORS_ACCOMMODATION_INITIALIZE_LIST_FILTER_FORM";
// export const VENDORS_ACCOMMODATION_UPDATE_LIST_FILTER_FORM =
//   "VENDORS_ACCOMMODATION_UPDATE_LIST_FILTER_FORM";
// export const VENDORS_ACCOMMODATION_APPLY_LIST_FILTER =
//   "VENDORS_ACCOMMODATION_APPLY_LIST_FILTER";

// //================================ADD VENDORS_ACCOMMODATION========================
// export const VENDORS_ACCOMMODATION_BEGIN_ADD =
//   "VENDORS_ACCOMMODATION_BEGIN_ADD";
// export const VENDORS_ACCOMMODATION_END_ADD = "VENDORS_ACCOMMODATION_END_ADD";
// export const VENDORS_ACCOMMODATION_IDLE_ADD = "VENDORS_ACCOMMODATION_IDLE_ADD";
// export const VENDORS_ACCOMMODATION_SHOW_BASE_FORM_MODAL =
//   "VENDORS_ACCOMMODATION_SHOW_BASE_FORM_MODAL";
// export const VENDORS_ACCOMMODATION_HIDE_BASE_FORM_MODAL =
//   "VENDORS_ACCOMMODATION_HIDE_BASE_FORM_MODAL";
// export const VENDORS_ACCOMMODATION_EDIT_BASE_FORM_MODAL =
//   "VENDORS_ACCOMMODATION_EDIT_BASE_FORM_MODAL";
// //================================EDIT===========================
// export const VENDORS_ACCOMMODATION_INITIALIZE_BASE_DATA_EDIT_FORM =
//   "VENDORS_ACCOMMODATION_INITIALIZE_BASE_DATA_EDIT_FORM";
// export const VENDORS_ACCOMMODATION_FETCH_EDIT_DATA =
//   "VENDORS_ACCOMMODATION_FETCH_EDIT_DATA";
// export const VENDORS_ACCOMMODATION_INIT_BASE_DATA_EDIT_FORM =
//   "VENDORS_ACCOMMODATION_INIT_BASE_DATA_EDIT_FORM";
// export const VENDORS_ACCOMMODATION_UPDATE_BASE_DATA_FORM =
//   "VENDORS_ACCOMMODATION_UPDATE_BASE_DATA_FORM";
// export const VENDORS_ACCOMMODATION_APPLY_BASE_DATA_FORM =
//   "VENDORS_ACCOMMODATION_APPLY_BASE_DATA_FORM";
// export const VENDORS_ACCOMMODATION_INITIALIZE_BASE_DATA_FORM =
//   "VENDORS_ACCOMMODATION_INITIALIZE_BASE_DATA_FORM";
// export const VENDORS_ACCOMMODATION_BEGIN_FETCH_EDIT_DATA =
//   "VENDORS_ACCOMMODATION_BEGIN_FETCH_EDIT_DATA";
// export const VENDORS_ACCOMMODATION_END_FETCH_EDIT_DATA =
//   "VENDORS_ACCOMMODATION_END_FETCH_EDIT_DATA";

// //===============================================================
// export const VENDORS_ACCOMMODATION_DATA_FORM =
//   "VENDORS_ACCOMMODATION_DATA_FORM";
// export const VENDORS_ACCOMMODATION_INIT_COLUMNS_BASE_FORM =
//   "VENDORS_ACCOMMODATION_INIT_COLUMNS_BASE_FORM";
// export const VENDORS_ACCOMMODATION_INIT_FORM =
//   "VENDORS_ACCOMMODATION_INIT_FORM";
// export const VENDORS_ACCOMMODATION_BEGIN_FETCH_ACC_DATA_FORM =
//   "VENDORS_ACCOMMODATION_BEGIN_FETCH_ACC_DATA_FORM";
// export const VENDORS_ACCOMMODATION_END_FETCH_ACC_DATA_FORM =
//   "VENDORS_ACCOMMODATION_END_FETCH_ACC_DATA_FORM";

// //============================Validate======================
// export const VENDORS_ACCOMMODATION_VALIDATE_BASE_DATA_EDIT_FORM =
//   "VENDORS_ACCOMMODATION_VALIDATE_BASE_DATA_EDIT_FORM";

//============================New Refactored======================
export const VENDORS_ACCOMMODATIONS_LIST_SAVE_RESULTS =
  "VENDORS_ACCOMMODATIONS_LIST_SAVE_RESULTS";
export const VENDORS_ACCOMMODATIONS_LIST_GENERIC_CHANGE =
  "VENDORS_ACCOMMODATIONS_LIST_GENERIC_CHANGE";
