export const TRIPPLANNER_INIT = "TRIPPLANNER_INIT";
// ======================== TRIP LOAD ACTIONS ========================
export const TRIPPLANNER_LOAD_TRIP = "TRIPPLANNER_LOAD_TRIP";

export const TRIP_MAP_REDRAW = "TRIP_MAP_REDRAW";
export const GOTO_NEXT_STEP = "GOTO_NEXT_STEP";
export const GOTO_PREV_STEP = "GOTO_PREV_STEP";

export const ITINERARY_SETUP_FORM_CHANGE = "ITINERARY_SETUP_FORM_CHANGE";

export const TRIPPLANNER_ADD_PAX = "TRIPPLANNER_ADD_PAX";
export const TRIPPLANNER_REMOVE_PAX = "TRIPPLANNER_REMOVE_PAX";
export const TRIPPLANNER_CHANGE_PAX = "TRIPPLANNER_CHANGE_PAX";
export const TRIPPLANNER_SET_ROOM_SETUP = "TRIPPLANNER_SET_ROOM_SETUP";
export const TRIPPLANNER_SET_MODE = "TRIPPLANNER_SET_MODE";
// ======================== ORIGIN POINT TYPES =======================

export const ITINERARY_SELECT_ORIGIN_DESTINATION =
  "ITINERARY_SELECT_ORIGIN_DESTINATION";

export const ITINERARY_SELECT_ORIGIN_DATE = "ITINERARY_SELECT_ORIGIN_DATE";

// ======================== RETURN POINT TYPES =======================

export const ITINERARY_RETURN_DATE_CHANGE = "ITINERARY_RETURN_DATE_CHANGE";
export const ITINERARY_ADD_RETURN = "ITINERARY_ADD_RETURN";
export const ITINERARY_REMOVE_RETURN_POINT = "ITINERARY_REMOVE_RETURN_POINT";
export const ITINERARY_SELECT_RETURN_DESTINATION =
  "ITINERARY_SELECT_RETURN_DESTINATION";

// ======================== DESTINATION TYPES ========================

export const TRIPPLANNER_ITINERARY_UPDATE_DESTINATIONS =
  "TRIPPLANNER_ITINERARY_UPDATE_DESTINATIONS";
export const TRIPPLANNER_ITINERARY_DEST_GENERIC_CHANGE =
  "TRIPPLANNER_ITINERARY_DEST_GENERIC_CHANGE";
export const TRIPPLANNER_ITINERARY_ADD_DESTINATION =
  "TRIPPLANNER_ITINERARY_ADD_DESTINATION";
export const TRIPPLANNER_ITINERARY_SELECT_DESTINATION =
  "TRIPPLANNER_ITINERARY_SELECT_DESTINATION";
export const ITINERARY_REMOVE_DESTINATION = "ITINERARY_REMOVE_DESTINATION";
export const ITINERARY_DESTINATION_QUERY_CHANGE =
  "ITINERARY_DESTINATION_QUERY_CHANGE";
export const ITINERARY_DESTINATION_DOWN = "ITINERARY_DESTINATION_DOWN";
export const ITINERARY_DESTINATION_UP = "ITINERARY_DESTINATION_UP";
export const ITINERARY_DESTINATION_STAY_CHANGE =
  "ITINERARY_DESTINATION_STAY_CHANGE";
export const ITINERARY_INITIAL_DESTINATION_DATES_CHANGE =
  "ITINERARY_INITIAL_DESTINATION_DATES_CHANGE";
export const ITINERARY_DESTINATION_MANUAL_DATES_CHANGE =
  "ITINERARY_DESTINATION_MANUAL_DATES_CHANGE";
export const ITINERARY_DESTINATION_MANUAL_DATES_RESET =
  "ITINERARY_DESTINATION_MANUAL_DATES_RESET";
export const ITINERARY_GLOBAL_DESTINATION_STAY_CHANGE =
  "ITINERARY_GLOBAL_DESTINATION_STAY_CHANGE";
export const TRIPPLANNER_ITINERARY_DESTINATION_LOADING =
  "TRIPPLANNER_ITINERARY_DESTINATION_LOADING";
export const TRIPPLANNER_ITINERARY_DESTINATION_IDLE =
  "TRIPPLANNER_ITINERARY_DESTINATION_IDLE";
// ==================== TRANSPORTATION INFORMATION ===================

export const TRIPPLANNER_RESET_TRANSPORTATION_INFRASTRUCTURE =
  "TRIPPLANNER_RESET_TRANSPORTATION_INFRASTRUCTURE";

export const TRIPPLANNER_SET_ORIGIN_TRANSPORTATION_INFRASTRUCTURE =
  "TRIPPLANNER_SET_ORIGIN_TRANSPORTATION_INFRASTRUCTURE";

export const TRIPPLANNER_SET_RETURN_TRANSPORTATION_INFRASTRUCTURE =
  "TRIPPLANNER_SET_RETURN_TRANSPORTATION_INFRASTRUCTURE";

export const TRIPPLANNER_SET_DESTINATION_TRANSPORTATION_INFRASTRUCTURE =
  "TRIPPLANNER_SET_DESTINATION_TRANSPORTATION_INFRASTRUCTURE";

export const TRIPPLANNER_SELECT_TRANSPORTATION_INFRASTRUCTURE =
  "TRIPPLANNER_SELECT_TRANSPORTATION_INFRASTRUCTURE";

export const TRIPPLANNER_TRP_INFRA_GENERIC_CHANGE =
  "TRIPPLANNER_TRP_INFRA_GENERIC_CHANGE";

export const TRIPPLANNER_TRP_INFRASTRUCTURE_SEARCHING =
  "TRIPPLANNER_TRP_INFRASTRUCTURE_SEARCHING";
export const TRIPPLANNER_TRP_INFRASTRUCTURE_SEARCHING_RESET =
  "TRIPPLANNER_TRP_INFRASTRUCTURE_SEARCHING_RESET";
export const TRIPPLANNER_TRP_INFRASTRUCTURE_IDLE =
  "TRIPPLANNER_TRP_INFRASTRUCTURE_IDLE";
export const TRIPPLANNER_TRP_INFRASTRUCTURE_FAIL =
  "TRIPPLANNER_TRP_INFRASTRUCTURE_FAIL";

// ======================= TRANSFER PREFERENCES ======================
export const TRANSFER_PREFERENCES_CHANGE = "TRANSFER_PREFERENCES_CHANGE";

export const TRANSFER_PREFERENCES_RESET = "TRANSFER_PREFERENCES_RESET";
export const TRIPPLANNER_TRANSFER_EDIT_ENABLED =
  "TRIPPLANNER_TRANSFER_EDIT_ENABLED";
export const TRIPPLANNER_TRANSFER_EDIT_DISABLED =
  "TRIPPLANNER_TRANSFER_EDIT_DISABLED";
// ==================== TRANSPORTATION PREFERENCES ===================
export const TRANSPORTATION_PREFERENCES_CHANGE =
  "TRANSPORTATION_PREFERENCES_CHANGE";
export const TRANSPORTATION_PREFERENCES_RESET =
  "TRANSPORTATION_PREFERENCES_RESET";
export const TRIPPLANNER_TRANSPORTATION_EDIT_ENABLED =
  "TRIPPLANNER_TRANSPORTATION_EDIT_ENABLED";
export const TRIPPLANNER_TRANSPORTATION_EDIT_DISABLED =
  "TRIPPLANNER_TRANSPORTATION_EDIT_DISABLED";
// ==================== ACCOMMODATION PREFERENCES ====================
export const ACCOMMODATION_PREFERENCES_CHANGE =
  "ACCOMMODATION_PREFERENCES_CHANGE";
export const ACCOMMODATION_PREFERENCES_ROOM_ADD =
  "ACCOMMODATION_PREFERENCES_ROOM_ADD";
export const ACCOMMODATION_PREFERENCES_ROOM_REMOVE =
  "ACCOMMODATION_PREFERENCES_ROOM_REMOVE";
export const ACCOMMODATION_PREFERENCES_ROOM_PAX_ADD =
  "ACCOMMODATION_PREFERENCES_ROOM_PAX_ADD";
export const ACCOMMODATION_PREFERENCES_ROOM_PAX_REMOVE =
  "ACCOMMODATION_PREFERENCES_ROOM_PAX_REMOVE";
export const ACCOMMODATION_PREFERENCES_AGE_CHANGE =
  "ACCOMMODATION_PREFERENCES_AGE_CHANGE";
export const ACCOMMODATION_PREFERENCES_RESET =
  "ACCOMMODATION_PREFERENCES_RESET";
export const TRIPPLANNER_ACCOMMODATION_EDIT_ENABLED =
  "TRIPPLANNER_ACCOMMODATION_EDIT_ENABLED";
export const TRIPPLANNER_ACCOMMODATION_EDIT_DISABLED =
  "TRIPPLANNER_ACCOMMODATION_EDIT_DISABLED";

// ========================== FAST SEARCHING =========================
export const TRIPPLANNER_TRANSPORTATION_FAST_SEARCHING =
  "TRIPPLANNER_TRANSPORTATION_FAST_SEARCHING";
export const TRIPPLANNER_TRANSPORTATION_FAST_IDLE =
  "TRIPPLANNER_TRANSPORTATION_FAST_IDLE";

// ======================= SEARCHING STATUSES ======================
export const TRIPPLANNER_TRANSPORTATION_SEARCHING =
  "TRIPPLANNER_TRANSPORTATION_SEARCHING";

// ===================== TRANSPORTATION ITINERARY ====================
export const TRIPPLANNER_FLIGHT_FAST_STORE_RESULTS =
  "TRIPPLANNER_FLIGHT_FAST_STORE_RESULTS";

export const TRIPPLANNER_TRANSPORTATION_ITIN_GENERATED =
  "TRIPPLANNER_TRANSPORTATION_ITIN_GENERATED";
export const TRIPPLANNER_TRANSPORTATION_ITIN_PAYLOADS_SAVE =
  "TRIPPLANNER_TRANSPORTATION_ITIN_PAYLOADS_SAVE";
export const TRIPPLANNER_TRANSPORTATION_CLEAR_DEST_FLIGHT =
  "TRIPPLANNER_TRANSPORTATION_CLEAR_DEST_FLIGHT";
export const TRIPPLANNER_TRANSPORTATION_STORE_FLIGHTS =
  "TRIPPLANNER_TRANSPORTATION_STORE_FLIGHTS";
export const TRIPPLANNER_TRANSPORTATION_STORE_BEST_FLIGHT =
  "TRIPPLANNER_TRANSPORTATION_STORE_BEST_FLIGHT";

// ===================== ACCOMMODATION ITINERARY =====================
export const TRIPPLANNER_CLEAR_ACCS = "TRIPPLANNER_CLEAR_ACCS";
export const TRIPPLANNER_CLEAR_DESTINATION_ACCS =
  "TRIPPLANNER_CLEAR_DESTINATION_ACCS";
export const TRIPPLANNER_ACCOMMODATION_STORE_POLL_RESULTS =
  "TRIPPLANNER_ACCOMMODATION_STORE_POLL_RESULTS";
export const TRIPPLANNER_ACCOMMODATION_STORE_BEST_ACC =
  "TRIPPLANNER_ACCOMMODATION_STORE_BEST_ACC";
export const TRIPPLANNER_ACCOMMODATION_SET_CXL =
  "TRIPPLANNER_ACCOMMODATION_SET_CXL";
export const TRIPPLANNER_ACCOMMODATION_SET_CUSTOMER_REMARKS =
  "TRIPPLANNER_ACCOMMODATION_SET_CUSTOMER_REMARKS";
// ====================== OVERVIEW ACCOMMODATION =====================
export const TRIPPLANNER_OVERVIEW_ACC_FILTERS_CHANGE =
  "TRIPPLANNER_OVERVIEW_ACC_FILTERS_CHANGE";
export const TRIPPLANNER_OVERVIEW_SELECT_ROOM =
  "TRIPPLANNER_OVERVIEW_SELECT_ROOM";
// ======================== OVERVIEW TRANSFER ========================
export const TRIPPLANNER_OVERVIEW_VIEW_TRANSFER_DETAILS =
  "TRIPPLANNER_OVERVIEW_VIEW_TRANSFER_DETAILS";
// ===================== OVERVIEW SERVICE FILTERS ====================
export const TRIPPLANNER_SERVICE_FILTERS_CHANGE =
  "TRIPPLANNER_SERVICE_FILTERS_CHANGE";
export const TRIPPLANNER_SERVICE_FILTERS_RESET =
  "TRIPPLANNER_SERVICE_FILTERS_RESET";
// ====================== CONTROL PANEL ACTIONS ======================
export const TRIPPLANNER_REQUEST_SAVE_TRIP = "TRIPPLANNER_REQUEST_SAVE_TRIP";
export const TRIPPLANNER_CANCEL_CONTROL_PANEL_REQUEST =
  "TRIPPLANNER_CANCEL_CONTROL_PANEL_REQUEST";
export const TRIPPLANNER_SAVING_TRIP = "TRIPPLANNER_SAVING_TRIP";
export const TRIPPLANNER_SAVED_TRIP = "TRIPPLANNER_SAVED_TRIP";
export const TRIPPLANNER_SAVE_AS_TRIP = "TRIPPLANNER_SAVE_AS_TRIP";

export const TRIPPLANNER_INSTANCE_DATA_UPDATE =
  "TRIPPLANNER_INSTANCE_DATA_UPDATE";
export const TRIPPLANNER_VIEW_TRIP_PREFERENCES =
  "TRIPPLANNER_VIEW_TRIP_PREFERENCES";
export const TRIPPLANNER_HIDE_TRIP_PREFERENCES =
  "TRIPPLANNER_HIDE_TRIP_PREFERENCES";
export const TRIPPLANNER_SETUP_CHANGE = "TRIPPLANNER_CHANGE_PREFERENCES";
export const TRIPPLANNER_UPDATE_PREFERENCES = "TRIPPLANNER_UPDATE_PREFERENCES";
export const TRIPPLANNER_REQUEST_UPDATE_OFFER =
  "TRIPPLANNER_REQUEST_UPDATE_OFFER";
export const TRIPPLANNER_REQUEST_UPDATE_OFFER_HIDE_MODAL =
  "TRIPPLANNER_REQUEST_UPDATE_OFFER_HIDE_MODAL";
export const TRIPPLANNER_REQUEST_UPDATE_OFFER_LOADING =
  "TRIPPLANNER_REQUEST_UPDATE_OFFER_LOADING";
export const TRIPPLANNER_REQUEST_UPDATE_OFFER_IDLE =
  "TRIPPLANNER_REQUEST_UPDATE_OFFER_IDLE";
// ========================== GUEST ACTIONS ==========================
export const TRIPPLANNER_SHOW_GUEST_MODAL = "TRIPPLANNER_SHOW_GUEST_MODAL";
export const TRIPPLANNER_HIDE_GUEST_MODAL = "TRIPPLANNER_HIDE_GUEST_MODAL";
export const TRIPPLANNER_CHANGE_GUEST = "TRIPPLANNER_CHANGE_GUEST";
export const TRIPPLANNER_UPDATE_GUESTS = "TRIPPLANNER_UPDATE_GUESTS";
export const TRIPPLANNER_RESET_GUESTS_ROOMS = "TRIPPLANNER_RESET_GUESTS_ROOMS";
export const TRIPPLANNER_INIT_GUESTS = "TRIPPLANNER_INIT_GUESTS";
export const TRIPPLANNER_SAVING_GUESTS = "TRIPPLANNER_SAVING_GUESTS";
export const TRIPPLANNER_SAVED_GUESTS = "TRIPPLANNER_SAVED_GUESTS";
export const TRIPPLANNER_GUESTS_ARE_VALID = "TRIPPLANNER_GUESTS_ARE_VALID";
export const TRIPPLANNER_GUESTS_ARE_INVALID = "TRIPPLANNER_GUESTS_ARE_INVALID";

// ==================== ROOM GUEST MAPPING ACTIONS ===================
export const TRIPPLANNER_SHOW_ROOMGUEST_MODAL =
  "TRIPPLANNER_SHOW_ROOMGUEST_MODAL";
export const TRIPPLANNER_HIDE_ROOMGUEST_MODAL =
  "TRIPPLANNER_HIDE_ROOMGUEST_MODAL";
export const TRIPPLANNER_INIT_ROOMGUEST_MAPPING =
  "TRIPPLANNER_INIT_ROOMGUEST_MAPPING";
export const TRIPPLANNER_CHANGE_ROOMGUEST_MAPPING =
  "TRIPPLANNER_CHANGE_ROOMGUEST_MAPPING";
export const TRIPPLANNER_SAVING_ROOMGUESTS_MAPPING =
  "TRIPPLANNER_SAVING_ROOMGUESTS_MAPPING";
export const TRIPPLANNER_SAVED_ROOMGUESTS_MAPPING =
  "TRIPPLANNER_SAVED_ROOMGUESTS_MAPPING";
export const TRIPPLANNER_ROOMGUESTS_MAPPING_IS_VALID =
  "TRIPPLANNER_ROOMGUESTS_MAPPING_IS_VALID";
export const TRIPPLANNER_ROOMGUESTS_MAPPING_IS_INVALID =
  "TRIPPLANNER_ROOMGUESTS_MAPPING_IS_INVALID";

// ====================== BOOKING MESSAGE MODAL ======================
export const TRIPPLANNER_SHOW_BOOKINGMESSAGE_MODAL =
  "TRIPPLANNER_SHOW_BOOKINGMESSAGE_MODAL";
export const TRIPPLANNER_HIDE_BOOKINGMESSAGE_MODAL =
  "TRIPPLANNER_HIDE_BOOKINGMESSAGE_MODAL";
export const TRIPPLANNER_LOADING_BOOKINGMESSAGE_MODAL =
  "TRIPPLANNER_LOADING_BOOKINGMESSAGE_MODAL";
export const TRIPPLANNER_IDLE_BOOKINGMESSAGE_MODAL =
  "TRIPPLANNER_IDLE_BOOKINGMESSAGE_MODAL";

// ========================= BOOKING MESSAGE =========================
export const TRIPPLANNER_CHANGE_BOOKING_MESSAGE =
  "TRIPPLANNER_CHANGE_BOOKING_MESSAGE";

// ========================= TRIP WAIT MODAL =========================
export const TRIPPLANNER_SHOW_TRIP_WAIT_MODAL =
  "TRIPPLANNER_SHOW_TRIP_WAIT_MODAL";
export const TRIPPLANNER_HIDE_TRIP_WAIT_MODAL =
  "TRIPPLANNER_HIDE_TRIP_WAIT_MODAL";

// ============================= PREBOOK =============================
export const TRIPPLANNER_RESET_SRV_FOR_BOOKING =
  "TRIPPLANNER_RESET_SRV_FOR_BOOKING";
export const TRIPPLANNER_RESET_SRV_FOR_OPTION_BOOKING =
  "TRIPPLANNER_RESET_SRV_FOR_OPTION_BOOKING";
export const TRIPPLANNER_SELECT_SRV_FOR_BOOKING =
  "TRIPPLANNER_SELECT_SRV_FOR_BOOKING";
export const TRIPPLANNER_SELECT_SRV_FOR_OPTION_BOOKING =
  "TRIPPLANNER_SELECT_SRV_FOR_OPTION_BOOKING";
// ========================= BOOKING PROCESS =========================
export const TRIPPLANNER_RESET_SRVS_FOR_REGULAR_BOOKING =
  "TRIPPLANNER_RESET_SRVS_FOR_REGULAR_BOOKING";
export const TRIPPLANNER_ADD_SRVS_FOR_REGULAR_BOOKING =
  "TRIPPLANNER_ADD_SRVS_FOR_REGULAR_BOOKING";
export const TRIPPLANNER_REMOVE_SRVS_FOR_REGULAR_BOOKING =
  "TRIPPLANNER_REMOVE_SRVS_FOR_REGULAR_BOOKING";
export const TRIPPLANNER_RESET_SRVS_FOR_OPTION_BOOKING =
  "TRIPPLANNER_RESET_SRVS_FOR_OPTION_BOOKING";
export const TRIPPLANNER_ADD_SRVS_FOR_OPTION_BOOKING =
  "TRIPPLANNER_ADD_SRVS_FOR_OPTION_BOOKING";
export const TRIPPLANNER_REMOVE_SRVS_FOR_OPTION_BOOKING =
  "TRIPPLANNER_REMOVE_SRVS_FOR_OPTION_BOOKING";
// ==================== PREBOOK FLIGHT RULES ===================
export const TRIPPLANNER_SHOW_PREBOOKFLIGHTRULES_MODAL =
  "TRIPPLANNER_SHOW_PREBOOKFLIGHTRULES_MODAL";
export const TRIPPLANNER_IDLE_PREBOOKFLIGHTRULES_MODAL =
  "TRIPPLANNER_IDLE_PREBOOKFLIGHTRULES_MODAL";
export const TRIPPLANNER_LOADING_PREBOOKFLIGHTRULES_MODAL =
  "TRIPPLANNER_LOADING_PREBOOKFLIGHTRULES_MODAL";
export const TRIPPLANNER_RESET_PREBOOK_FLIGHT_RULES =
  "TRIPPLANNER_RESET_PREBOOK_FLIGHT_RULES";
export const TRIPPLANNER_SET_PREBOOK_FLIGHT_RULES =
  "TRIPPLANNER_SET_PREBOOK_FLIGHT_RULES";
export const TRIPPLANNER_ACCEPT_PREBOOK_FLIGHT_RULES =
  "TRIPPLANNER_ACCEPT_PREBOOK_FLIGHT_RULES";
// =================== PREBOOK ACCOMMODATION RULES ===================
export const TRIPPLANNER_SHOW_PREBOOKACCOMMODATION_MODAL =
  "TRIPPLANNER_SHOW_PREBOOKACCOMMODATION_MODAL";
export const TRIPPLANNER_HIDE_PREBOOKACCOMMODATION_MODAL =
  "TRIPPLANNER_HIDE_PREBOOKACCOMMODATION_MODAL";
export const TRIPPLANNER_IDLE_PREBOOKACCOMMODATION_MODAL =
  "TRIPPLANNER_IDLE_PREBOOKACCOMMODATION_MODAL";
export const TRIPPLANNER_LOADING_PREBOOKACCOMMODATION_MODAL =
  "TRIPPLANNER_LOADING_PREBOOKACCOMMODATION_MODAL";
export const TRIPPLANNER_RESET_PREBOOKACCOMMODATION_DATA =
  "TRIPPLANNER_RESET_PREBOOKACCOMMODATION_DATA";
export const TRIPPLANNER_SET_PREBOOKACCOMMODATION_DATA =
  "TRIPPLANNER_SET_PREBOOKACCOMMODATION_DATA";
export const TRIPPLANNER_ACCEPT_PREBOOKACCOMMODATION =
  "TRIPPLANNER_ACCEPT_PREBOOKACCOMMODATION";
export const TRIPPLANNER_UNACCEPT_ALL_PREBOOKACCOMMODATIONS =
  "TRIPPLANNER_UNACCEPT_ALL_PREBOOKACCOMMODATIONS";

// ============================== FLIGHT =============================
export const TRIPPLANNER_FLIGHT_SHOW_RULES_MODAL =
  "TRIPPLANNER_FLIGHT_SHOW_RULES_MODAL";
export const TRIPPLANNER_FLIGHT_HIDE_RULES_MODAL =
  "TRIPPLANNER_FLIGHT_HIDE_RULES_MODAL";
export const TRIPPLANNER_FLIGHT_FETCHING_RULES =
  "TRIPPLANNER_FLIGHT_FETCHING_RULES";
export const TRIPPLANNER_FLIGHT_SAVE_RULES = "TRIPPLANNER_FLIGHT_SAVE_RULES";
export const TRIPPLANNER_FLIGHT_FETCHED_RULES =
  "TRIPPLANNER_FLIGHT_FETCHED_RULES";

// ========================= BOOKING ACTIONS =========================
export const TRIPPLANNER_SHOW_BOOKING_MODAL = "TRIPPLANNER_SHOW_BOOKING_MODAL";
export const TRIPPLANNER_HIDE_BOOKING_MODAL = "TRIPPLANNER_HIDE_BOOKING_MODAL";
export const TRIPPLANNER_LOADING_BOOKING_MODAL =
  "TRIPPLANNER_LOADING_BOOKING_MODAL";
export const TRIPPLANNER_BOOKING_DATA_UPDATE =
  "TRIPPLANNER_BOOKING_DATA_UPDATE";

export * from "./prebook_types";
export * from "./day_by_day_types";
export * from "./types/overview_transportation_types";
export * from "./types/marketplace_types";
export * from "./types/detailed_pricing";
export * from "./accommodation_priorities/types";
