import langDictionaries from "./lang_dictionaries";
import _ from "lodash";

const LANGUAGE = "it";

var langItDictionary = {};

langDictionaries.forEach((langDictionary) => {
  const obj = _.get(langDictionary, LANGUAGE, {});
  Object.entries(obj).forEach(([key, value]) => {
    langItDictionary[key] = value;
  });
});

export default langItDictionary;
