export const CUSTOM_SERVICE_ADDON_INIT = "CUSTOM_SERVICE_ADDON_INIT";
export const CUSTOM_SERVICE_ADDON_LOAD = "CUSTOM_SERVICE_ADDON_LOAD";
export const CUSTOM_SERVICE_ADDON_LOADING = "CUSTOM_SERVICE_ADDON_LOADING";
export const CUSTOM_SERVICE_ADDON_IDLE = "CUSTOM_SERVICE_ADDON_IDLE";
export const CUSTOM_SERVICE_ADDON_NEXT_STEP = "CUSTOM_SERVICE_ADDON_NEXT_STEP";
export const CUSTOM_SERVICE_ADDON_PREV_STEP = "CUSTOM_SERVICE_ADDON_PREV_STEP";
export const CUSTOM_SERVICE_ADDON_SHOW_SAVE_MODAL =
  "CUSTOM_SERVICE_ADDON_SHOW_SAVE_MODAL";
export const CUSTOM_SERVICE_ADDON_HIDE_SAVE_MODAL =
  "CUSTOM_SERVICE_ADDON_HIDE_SAVE_MODAL";
export const CUSTOM_SERVICE_ADDON_LOADING_SAVE_MODAL =
  "CUSTOM_SERVICE_ADDON_LOADING_SAVE_MODAL";
export const CUSTOM_SERVICE_ADDON_IDLE_SAVE_MODAL =
  "CUSTOM_SERVICE_ADDON_IDLE_SAVE_MODAL";

export const CUSTOM_SERVICE_ADDON_SAVE = "CUSTOM_SERVICE_ADDON_SAVE";
export const CUSTOM_SERVICE_ADDON_START_TIME_CHANGE =
  "CUSTOM_SERVICE_ADDON_START_TIME_CHANGE";
export const CUSTOM_SERVICE_ADDON_INDICATIVE_TIME_CHANGE =
  "CUSTOM_SERVICE_ADDON_INDICATIVE_TIME_CHANGE";
// =========================== DESTINATION ===========================
export const CUSTOM_SERVICE_ADDON_SET_DESTINATION =
  "CUSTOM_SERVICE_ADDON_SET_DESTINATION";
// =========================== ITEM ACTIONS ==========================
export const CUSTOM_SERVICE_ADDON_SET_ITEM = "CUSTOM_SERVICE_ADDON_SET_ITEM";
export const CUSTOM_SERVICE_ADDON_ADD_ITEM = "CUSTOM_SERVICE_ADDON_ADD_ITEM";
export const CUSTOM_SERVICE_ADDON_REMOVE_ITEM =
  "CUSTOM_SERVICE_ADDON_REMOVE_ITEM";
export const CUSTOM_SERVICE_ADDON_MOVEUP_ITEM =
  "CUSTOM_SERVICE_ADDON_MOVEUP_ITEM";
export const CUSTOM_SERVICE_ADDON_MOVEDOWN_ITEM =
  "CUSTOM_SERVICE_ADDON_MOVEDOWN_ITEM";
export const CUSTOM_SERVICE_ADDON_CHANGE_ITEM_INFO =
  "CUSTOM_SERVICE_ADDON_CHANGE_ITEM_INFO";
export const CUSTOM_SERVICE_ADDON_SHOW_EDIT_ITEM_MODAL =
  "CUSTOM_SERVICE_ADDON_SHOW_EDIT_ITEM_MODAL";
export const CUSTOM_SERVICE_ADDON_HIDE_EDIT_ITEM_MODAL =
  "CUSTOM_SERVICE_ADDON_HIDE_EDIT_ITEM_MODAL";
export const CUSTOM_SERVICE_ADDON_CHANGE_EDIT_ITEM_CURRENT_LANG =
  "CUSTOM_SERVICE_ADDON_CHANGE_EDIT_ITEM_CURRENT_LANG";
export const CUSTOM_SERVICE_ADDON_EDIT_ITEM_CONTENT =
  "CUSTOM_SERVICE_ADDON_EDIT_ITEM_CONTENT";
export const CUSTOM_SERVICE_ADDON_UPDATE_ITEM_DISTANCE =
  "CUSTOM_SERVICE_ADDON_UPDATE_ITEM_DISTANCE";
// ============================ BASE INFO ============================
export const CUSTOM_SERVICE_ADDON_PATCH = "CUSTOM_SERVICE_ADDON_PATCH";
export const CUSTOM_SERVICE_ADDON_CHANGE_BASE_INFO =
  "CUSTOM_SERVICE_ADDON_CHANGE_BASE_INFO";
// ====================== OPERATION PREFERENCES ======================
export const CUSTOM_SERVICE_ADDON_ADD_OPERATOR =
  "CUSTOM_SERVICE_ADDON_ADD_OPERATOR";
export const CUSTOM_SERVICE_ADDON_REMOVE_OPERATOR =
  "CUSTOM_SERVICE_ADDON_REMOVE_OPERATOR";
export const CUSTOM_SERVICE_ADDON_COPY_OPERATOR =
  "CUSTOM_SERVICE_ADDON_COPY_OPERATOR";
export const CUSTOM_SERVICE_ADDON_CHANGE_OPERATOR =
  "CUSTOM_SERVICE_ADDON_CHANGE_OPERATOR";
// ===================== DISTRIBUTION PREFERENCES ====================
export const CUSTOM_SERVICE_CHANGE_DISTRIBUTION_PERIODS =
  "CUSTOM_SERVICE_CHANGE_DISTRIBUTION_PERIODS";
// ========================== FINAL PRICING ==========================
export const CUSTOM_SERVICE_ADDON_PRICING_INIT =
  "CUSTOM_SERVICE_ADDON_PRICING_INIT";
export const CUSTOM_SERVICE_ADDON_PRICING_CURRENCY_CHANGE =
  "CUSTOM_SERVICE_ADDON_PRICING_CURRENCY_CHANGE";
export const CUSTOM_SERVICE_ADDON_PRICING_PRICE_CHANGE =
  "CUSTOM_SERVICE_ADDON_PRICING_PRICE_CHANGE";
export const CUSTOM_SERVICE_ADDON_RESET_PRICING_PRICE =
  "CUSTOM_SERVICE_ADDON_RESET_PRICING_PRICE";
export const CUSTOM_SERVICE_ADDON_COPY_PRICING_PRICE =
  "CUSTOM_SERVICE_ADDON_COPY_PRICING_PRICE";
export const CUSTOM_SERVICE_ADDON_CHANGE_CXL_FEE =
  "CUSTOM_SERVICE_ADDON_CHANGE_CXL_FEE";
export const CUSTOM_SERVICE_ADDON_CHANGE_CXL =
  "CUSTOM_SERVICE_ADDON_CHANGE_CXL";
export const CUSTOM_SERVICE_ADDON_RESET_PRICING_CXL =
  "CUSTOM_SERVICE_ADDON_RESET_PRICING_CXL";
export const CUSTOM_SERVICE_ADDON_COPY_PRICING_CXL =
  "CUSTOM_SERVICE_ADDON_COPY_PRICING_CXL";
// =========================== DESCRIPTION ===========================
export const CUSTOM_SERVICE_ADDON_SHOW_DESCRIPTION_MODAL =
  "CUSTOM_SERVICE_ADDON_SHOW_DESCRIPTION_MODAL";
export const CUSTOM_SERVICE_ADDON_HIDE_DESCRIPTION_MODAL =
  "CUSTOM_SERVICE_ADDON_HIDE_DESCRIPTION_MODAL";
export const CUSTOM_SERVICE_ADDON_CHANGE_DESCRIPTION =
  "CUSTOM_SERVICE_ADDON_CHANGE_DESCRIPTION";
export const CUSTOM_SERVICE_ADDON_CHANGE_SHORT_DESCRIPTION =
  "CUSTOM_SERVICE_ADDON_CHANGE_SHORT_DESCRIPTION";
export const CUSTOM_SERVICE_ADDON_CHANGE_DESCRIPTION_MODAL_LANG =
  "CUSTOM_SERVICE_ADDON_CHANGE_DESCRIPTION_MODAL_LANG";
// ======================= BOOKING INFORMATION =======================
export const CUSTOM_SERVICE_ADDON_CHANGE_BOOKING_INFORMATION =
  "CUSTOM_SERVICE_ADDON_CHANGE_BOOKING_INFORMATION";
// ========================== MEETING POINTS =========================
export const CUSTOM_SERVICE_ADDON_ADD_MEETING_POINT_GROUP =
  "CUSTOM_SERVICE_ADDON_ADD_MEETING_POINT_GROUP";
export const CUSTOM_SERVICE_ADDON_REMOVE_MEETING_POINT_GROUP =
  "CUSTOM_SERVICE_ADDON_REMOVE_MEETING_POINT_GROUP";
export const CUSTOM_SERVICE_ADDON_CHANGE_MEETING_POINT_SETUP =
  "CUSTOM_SERVICE_ADDON_CHANGE_MEETING_POINT_SETUP";
export const CUSTOM_SERVICE_ADDON_CHANGE_MEETING_POINT_GROUP_MODE =
  "CUSTOM_SERVICE_ADDON_CHANGE_MEETING_POINT_GROUP_MODE";
export const CUSTOM_SERVICE_ADDON_LOADING_MEETING_POINT_GROUP =
  "CUSTOM_SERVICE_ADDON_LOADING_MEETING_POINT_GROUP";
export const CUSTOM_SERVICE_ADDON_IDLE_MEETING_POINT_GROUP =
  "CUSTOM_SERVICE_ADDON_IDLE_MEETING_POINT_GROUP";
export const CUSTOM_SERVICE_ADDON_SET_MEETING_POINT_AIRPORTS =
  "CUSTOM_SERVICE_ADDON_SET_MEETING_POINT_AIRPORTS";
export const CUSTOM_SERVICE_ADDON_SET_MEETING_POINT_ACCOMMODATIONS =
  "CUSTOM_SERVICE_ADDON_SET_MEETING_POINT_ACCOMMODATIONS";
export const CUSTOM_SERVICE_ADDON_SELECT_MEETING_POINT =
  "CUSTOM_SERVICE_ADDON_SELECT_MEETING_POINT";
export const CUSTOM_SERVICE_ADDON_SELECT_ALL_MEETING_POINTS =
  "CUSTOM_SERVICE_ADDON_SELECT_ALL_MEETING_POINTS";
export const CUSTOM_SERVICE_ADDON_DESELECT_ALL_MEETING_POINTS =
  "CUSTOM_SERVICE_ADDON_DESELECT_ALL_MEETING_POINTS";
// ==================== CUSTOM MEETINT POINT MODAL ===================
export const CUSTOM_SERVICE_ADDON_SHOW_ADD_CUSTOM_MEETING_POINT_POI_MODAL =
  "CUSTOM_SERVICE_ADDON_SHOW_ADD_CUSTOM_MEETING_POINT_POI_MODAL";
export const CUSTOM_SERVICE_ADDON_HIDE_ADD_CUSTOM_MEETING_POINT_POI_MODAL =
  "CUSTOM_SERVICE_ADDON_HIDE_ADD_CUSTOM_MEETING_POINT_POI_MODAL";
export const CUSTOM_SERVICE_ADDON_SET_CUSTOM_MEETING_POINT =
  "CUSTOM_SERVICE_ADDON_SET_CUSTOM_MEETING_POINT";
// ======================== PAX SCALLING MODAL =======================
export const CUSTOM_SERVICE_ADDON_SHOW_PAX_SCALLING_MODAL =
  "CUSTOM_SERVICE_ADDON_SHOW_PAX_SCALLING_MODAL";
export const CUSTOM_SERVICE_ADDON_HIDE_PAX_SCALLING_MODAL =
  "CUSTOM_SERVICE_ADDON_HIDE_PAX_SCALLING_MODAL";
export const CUSTOM_SERVICE_ADDON_SET_PAX_GROUPS =
  "CUSTOM_SERVICE_ADDON_SET_PAX_GROUPS";
// ============================ STOP SALES ===========================
export const CUSTOM_SERVICE_ADDON_ADD_STOP_SALES =
  "CUSTOM_SERVICE_ADDON_ADD_STOP_SALES";
export const CUSTOM_SERVICE_ADDON_REMOVE_STOP_SALES =
  "CUSTOM_SERVICE_REMOVE_STOP_SALES";
export const CUSTOM_SERVICE_ADDON_CHANGE_STOP_SALES =
  "CUSTOM_SERVICE_ADDON_CHANGE_STOP_SALES";
// ============================ OFFERS ===============================
export const CUSTOM_SERVICE_ADDON_CHANGE_OFFERS =
  "CUSTOM_SERVICE_ADDON_CHANGE_OFFERS";
// ====================== EXCLUSIONS/INCLUSIONS ======================
export const CUSTOM_SERVICE_ADDON_ADD_EXCLUSION_INCLUSION =
  "CUSTOM_SERVICE_ADDON_ADD_EXCLUSION_INCLUSION";
export const CUSTOM_SERVICE_ADDON_REMOVE_EXCLUSION_INCLUSION =
  "CUSTOM_SERVICE_ADDON_REMOVE_EXCLUSION_INCLUSION";
export const CUSTOM_SERVICE_ADDON_CHANGE_EXCLUSION_INCLUSION =
  "CUSTOM_SERVICE_ADDON_CHANGE_EXCLUSION_INCLUSION";

export const CUSTOM_ADDONS_IMAGE_CHANGE = "CUSTOM_ADDONS_IMAGE_CHANGE";
export const CUSTOM_SERVICE_ADDON_EDIT_ITEM_IMAGE =
  "CUSTOM_SERVICE_ADDON_EDIT_ITEM_IMAGE";
export const CUSTOM_SERVICE_ADDON_EDIT_ITEM_IMAGE_MAIN_PHOTO =
  "CUSTOM_SERVICE_ADDON_EDIT_ITEM_IMAGE_MAIN_PHOTO";
export const CUSTOM_SERVICE_ADDON_REMOVE_ITEM_IMAGE =
  "CUSTOM_SERVICE_ADDON_REMOVE_ITEM_IMAGE";

export const CUSTOM_SERVICE_ADDON_TOGGLE_ITEM_NON_CUSTOM_IMAGE =
  "CUSTOM_SERVICE_ADDON_TOGGLE_ITEM_NON_CUSTOM_IMAGE";
// ==================== ITEM DEPARTURE META MODAL ====================
export const CUSTOM_SERVICE_ADDON_SHOW_EDIT_ITEM_DEP_META_MODAL =
  "CUSTOM_SERVICE_ADDON_SHOW_EDIT_ITEM_DEP_META_MODAL";
export const CUSTOM_SERVICE_ADDON_HIDE_EDIT_ITEM_DEP_META_MODAL =
  "CUSTOM_SERVICE_ADDON_HIDE_EDIT_ITEM_DEP_META_MODAL";
// ============================ ADDON MAP ============================
export const CUSTOM_SERVICE_ADDON_MAP_EDIT_ON =
  "CUSTOM_SERVICE_ADDON_MAP_EDIT_ON";
export const CUSTOM_SERVICE_ADDON_MAP_EDIT_OFF =
  "CUSTOM_SERVICE_ADDON_MAP_EDIT_OFF";
export const CUSTOM_SERVICE_ADDON_ITEM_LOCK_DEPARTURE_META =
  "CUSTOM_SERVICE_ADDON_ITEM_LOCK_DEPARTURE_META";
export const CUSTOM_SERVICE_ADDON_ITEM_UNLOCK_DEPARTURE_META =
  "CUSTOM_SERVICE_ADDON_ITEM_UNLOCK_DEPARTURE_META";

// =========================== AVAILABILITY ==========================
export const CUSTOM_SERVICE_ADDON_CHANGE_AVAILABILITY =
  "CUSTOM_SERVICE_ADDON_CHANGE_AVAILABILITY";
// ============================== ROOMS ==============================
export const CUSTOM_SERVICE_ADDON_CHANGE_ROOMS =
  "CUSTOM_SERVICE_ADDON_CHANGE_ROOMS";
