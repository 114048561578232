import {
  Icon,
  IconButton,
  ButtonToolbar,
  Button,
  ButtonGroup,
  Dropdown,
  Loader,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
} from "rsuite";

import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import {
  hideGenDescriptionModal,
  changeDescriptionLang,
  changeDescription,
  changeShortDescription,
  changeBookingInformation,
  changeBaseInfo,
} from "@src/actions/Operation/CustomServices/AddOn";
import ReactQuill from "react-quill";
import { langs, langTranslateMapping } from "@src/config/common";
import { translate } from "@src/api";
import {
  notifyNoContentToTranslate,
  notifyTranslateFailure,
} from "@src/components/common/Notifications";
import { createUseStyles } from "react-jss";
import { modalGenericStyles, variables } from "@src/jsssetup";
import { useAIContentGeneration } from "@src/pages/hooks";
import { CustomButton } from "@src/components/common/buttons";
import LeanAuthHoc from "@src/components/authorization/LeanAuthHoc";
import { quillFormats, quillModules } from "@src/components/forms";

export const descTranslationHoc = (WrappedComponent) => {
  class Comp extends React.Component {
    constructor(props) {
      super(props);
      this.state = { loading: false };
      this.onTranslate = this.onTranslate.bind(this);
    }
    async onTranslate(data, origin_text) {
      const { current_lang, onPostTranslation } = this.props;
      const { payload } = data;

      var tmp = document.createElement("DIV");
      tmp.innerHTML = origin_text;
      origin_text = tmp.textContent || tmp.innerText || "";

      if (!origin_text) {
        notifyNoContentToTranslate(
          payload.translations[0].origin_lang,
          payload.translations[0].target_lang
        );
        return;
      }

      payload.translations[0]["origin_text"] = origin_text;

      this.setState((p) => ({ ...p, loading: true }));

      try {
        const result = await translate(payload);
        onPostTranslation(
          current_lang,
          result?.translations?.[0]?.target_text ?? ""
        );
      } catch (error) {
        console.log("error=", error);
        notifyTranslateFailure();
      } finally {
        this.setState((p) => ({ ...p, loading: false }));
      }
    }
    render() {
      return (
        <WrappedComponent {...this.props} onTranslate={this.onTranslate} />
      );
    }
  }

  Comp.propTypes = {
    current_lang: PropTypes.string.isRequired,
    onPostTranslation: PropTypes.func.isRequired,
  };

  return Comp;
};

export class LanguageBar extends React.Component {
  constructor(props) {
    super(props);
    this.langs = { ...langs };
    this.langTranslateMapping = { ...langTranslateMapping };
    this.handleTranslate = this.handleTranslate.bind(this);
  }
  handleTranslate(origin_lang) {
    const { current_lang, onTranslate } = this.props;

    onTranslate({
      payload: {
        translations: [
          {
            origin_lang: this.langTranslateMapping[origin_lang],
            target_lang: this.langTranslateMapping[current_lang],
          },
        ],
      },
      translate_func: translate,
    });
  }
  render() {
    const { current_lang, onChange } = this.props;

    return (
      <div className="LanguageBar">
        <div className="LanguageBar__control">
          <span>
            <strong>Languages</strong>
          </span>
          <ButtonToolbar>
            <ButtonGroup>
              {Object.entries(this.langs).map((e, idx) => (
                <Button
                  key={idx}
                  appearance={current_lang == e[0] ? "primary" : "default"}
                  onClick={function () {
                    onChange(e[0]);
                  }}>
                  {e[1]}
                </Button>
              ))}
            </ButtonGroup>
          </ButtonToolbar>
        </div>
        <div className="LanguageBar__control">
          <span>
            <strong>Translate</strong>
          </span>
          <Dropdown title="Options" placement="leftStart">
            {Object.entries(this.langs)
              .filter((e) => e[0] !== current_lang)
              .map((e, idx) => (
                <Dropdown.Item
                  key={idx}
                  onClick={() => {
                    this.handleTranslate(e[0]);
                  }}>{`${e[1]} to ${this.langs[current_lang]}`}</Dropdown.Item>
              ))}
          </Dropdown>
        </div>
      </div>
    );
  }
}
LanguageBar.defaultProps = { current_lang: "en" };
LanguageBar.propTypes = {
  current_lang: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onTranslate: PropTypes.func.isRequired,
};

var AIAutoGenBtn = ({
  title,
  items,
  current_lang,
  onInitAiSession,
  onSetDescription,
}) => {
  return (
    <CustomButton
      appearance="primary"
      onClick={() => {
        onSetDescription("");
        onInitAiSession({
          messages: [
            [
              `${title}:`,
              items.map((item) => `${item.data.name_en}(${item.type})`),
            ].join(" "),
            `Your reply has to be in the following language code: ${current_lang}`,
          ],
        });
      }}>
      AI Auto-Generation
    </CustomButton>
  );
};
AIAutoGenBtn.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  current_lang: PropTypes.string.isRequired,
  onInitAiSession: PropTypes.func.isRequired,
  onSetDescription: PropTypes.func.isRequired,
};
AIAutoGenBtn = LeanAuthHoc(AIAutoGenBtn, {
  allowed_groups: ["testingfeature"],
});

const styles = createUseStyles({
  ...modalGenericStyles,
  GeneralDescriptionModal: modalGenericStyles.modal,
  col3: {
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateColumns: "repeat(3, 1fr)",
  },
  generalDescLabel: {
    display: "grid",
    gridTemplateColumns: "1fr max-content",
    width: "100%",
    padding: [variables.half_gap, 0],
  },
});
const GeneralDescriptionModal = () => {
  const gen_desc = useSelector((state) =>
    _.get(state, "customServiceAddOn.general_description", {})
  );

  const [desc_en_content, setDesc_en_content] = useState(
    gen_desc?.description_en || ""
  );

  const [desc_cn_content, setDesc_cn_content] = useState(
    gen_desc?.description_en || ""
  );
  const [desc_it_content, setDesc_it_content] = useState(
    gen_desc?.description_it || ""
  );
  const [desc_es_content, setDesc_es_content] = useState(
    gen_desc?.description_es || ""
  );
  const [desc_fr_content, setDesc_fr_content] = useState(
    gen_desc?.description_fr || ""
  );
  const [desc_el_content, setDesc_el_content] = useState(
    gen_desc?.description_el || ""
  );
  const [desc_nl_content, setDesc_nl_content] = useState(
    gen_desc?.description_nl || ""
  );
  const [desc_de_content, setDesc_de_content] = useState(
    gen_desc?.description_de || ""
  );
  const [desc_pt_content, setDesc_pt_content] = useState(
    gen_desc?.description_pt || ""
  );

  const title = useSelector((state) => state?.customServiceAddOn?.title ?? {});
  const items = useSelector((state) => state?.customServiceAddOnItems ?? []);

  const booking_information = useSelector(
    (state) => state?.customServiceAddOn?.booking_information ?? {}
  );

  const short_desc = useSelector(
    (state) => state?.customServiceAddOn?.general_short_description ?? {}
  );
  const { show, current_lang } = useSelector(
    (state) => state.customGenDescriptionModal
  );
  const { main_youtube_video, product_version, redirect_link, venue_address } =
    useSelector((state) => state.customServiceAddOn);

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const onHide = useCallback(() => dispatch(hideGenDescriptionModal()));
  const onDescriptionChange = useCallback(
    async (lang, desc) => await dispatch(changeDescription(lang, desc)),
    [dispatch]
  );
  const onShortDescriptionChange = useCallback(
    (lang, desc) => dispatch(changeShortDescription(lang, desc)),
    [dispatch]
  );
  const onBookingInfoChange = useCallback(
    (lang, desc) => dispatch(changeBookingInformation(lang, desc)),
    [dispatch]
  );
  const onLangChange = useCallback(
    (lang) => dispatch(changeDescriptionLang(lang)),
    [dispatch]
  );
  const onGenericChange = useCallback(
    ({ key, value }) => dispatch(changeBaseInfo(key, value)),
    [dispatch]
  );

  async function onTranslate(data) {
    const { payload, translate_func } = data;

    var origin_text = _.get(
      gen_desc,
      `description_${payload.translations[0].origin_lang}`,
      ""
    );

    const origin_short_text = _.get(
      short_desc,
      `description_${payload.translations[0].origin_lang}`,
      ""
    );

    var origin_booking_information = _.get(
      booking_information,
      `description_${payload.translations[0].origin_lang}`,
      ""
    );

    var tmp = document.createElement("DIV");
    tmp.innerHTML = origin_text;
    origin_text = tmp.textContent || tmp.innerText || "";

    var booktmp = document.createElement("DIV");
    booktmp.innerHTML = origin_booking_information;
    origin_booking_information = booktmp.textContent || booktmp.innerText || "";

    if (!origin_text && !origin_booking_information && !origin_short_text) {
      notifyNoContentToTranslate(
        payload.translations[0].origin_lang,
        payload.translations[0].target_lang
      );
      return;
    }

    const translationIdxs = {};

    const getIdx = () => {
      return payload.translations.filter((tra) => tra.origin_text).length - 1;
    };

    if (origin_text) {
      payload.translations[0]["origin_text"] = origin_text;
      translationIdxs["origin_text"] = 0;
    }

    if (origin_short_text) {
      payload.translations.push({
        ...payload.translations[0],
        origin_text: origin_short_text,
      });
      translationIdxs["origin_short_text"] = getIdx();
    }

    if (origin_booking_information) {
      payload.translations.push({
        ...payload.translations[0],
        origin_text: origin_booking_information,
      });
      translationIdxs["origin_booking_information"] = getIdx();
    }

    payload.translations = payload.translations.filter(
      (tra) => tra.origin_text
    );

    setLoading(true);

    try {
      const result = await translate_func(payload);
      onDescriptionChange(
        current_lang,
        _.get(
          result,
          `translations.${translationIdxs["origin_text"]}.target_text`,
          ""
        )
      );

      onShortDescriptionChange(
        current_lang,
        _.get(
          result,
          `translations.${translationIdxs["origin_short_text"]}.target_text`,
          ""
        )
      );

      onBookingInfoChange(
        current_lang,
        _.get(
          result,
          `translations.${translationIdxs["origin_booking_information"]}.target_text`,
          ""
        )
      );
    } catch (error) {
      notifyTranslateFailure();
    } finally {
      setLoading(false);
    }
  }

  const classes = styles();

  var description = "";
  var setDescription = setDesc_en_content;
  switch (current_lang) {
    case "en": {
      description = desc_en_content;
      setDescription = setDesc_en_content;
      break;
    }
    case "cn": {
      description = desc_cn_content;
      setDescription = setDesc_cn_content;
      break;
    }
    case "it": {
      description = desc_it_content;
      setDescription = setDesc_it_content;
      break;
    }
    case "es": {
      description = desc_es_content;
      setDescription = setDesc_es_content;
      break;
    }
    case "fr": {
      description = desc_fr_content;
      setDescription = setDesc_fr_content;
      break;
    }
    case "el": {
      description = desc_el_content;
      setDescription = setDesc_el_content;
      break;
    }
    case "nl": {
      description = desc_nl_content;
      setDescription = setDesc_nl_content;
      break;
    }
    case "de": {
      description = desc_de_content;
      setDescription = setDesc_de_content;
      break;
    }
    case "pt": {
      description = desc_pt_content;
      setDescription = setDesc_pt_content;
      break;
    }
    default: {
      description = desc_en_content;
      setDescription = setDesc_en_content;
    }
  }

  const {
    streamContent,
    contentGenerationStatus,
    initAutoContentSesssionMutation,
  } = useAIContentGeneration({
    context: "experience_planner",
    max_length: 500,
  });

  useEffect(() => {
    setDesc_en_content(gen_desc?.description_en || "");
    setDesc_cn_content(gen_desc?.description_en || "");
    setDesc_it_content(gen_desc?.description_it || "");
    setDesc_es_content(gen_desc?.description_es || "");
    setDesc_fr_content(gen_desc?.description_fr || "");
    setDesc_el_content(gen_desc?.description_el || "");
    setDesc_nl_content(gen_desc?.description_nl || "");
    setDesc_de_content(gen_desc?.description_de || "");
    setDesc_pt_content(gen_desc?.description_pt || "");
  }, [gen_desc]);

  // Store the content as it comes
  useEffect(() => {
    if (!streamContent) return;
    setDescription(streamContent);
  }, [streamContent, contentGenerationStatus]);

  return !show ? null : (
    <div
      className={`GeneralDescriptionModal ${classes.GeneralDescriptionModal}`}>
      <div className={`GeneralDescriptionModal__card ${classes.card}`}>
        <div className={classes.cardHeader}>
          <h5>Add On Description</h5>
        </div>
        <div className="GeneralDescriptionModal__card__body">
          <LanguageBar
            current_lang={current_lang}
            onChange={onLangChange}
            onTranslate={onTranslate}
          />
          {loading ? (
            <Loader vertical content="Please wait..." size="lg" />
          ) : (
            <React.Fragment>
              <span className={classes.generalDescLabel}>
                <strong>General Description</strong>
                <AIAutoGenBtn
                  title={title}
                  items={items}
                  current_lang={current_lang}
                  onInitAiSession={initAutoContentSesssionMutation.mutate}
                  onSetDescription={setDescription}
                />
              </span>
              <Form
                formValue={{
                  [`description_${current_lang}`]: _.get(
                    short_desc,
                    `description_${current_lang}`,
                    ""
                  ),
                  main_youtube_video,
                  product_version,
                  redirect_link,
                  venue_address,
                }}
                onChange={async (value) => {
                  await onShortDescriptionChange(
                    current_lang,
                    value[`description_${current_lang}`]
                  );
                  await onGenericChange({
                    key: "main_youtube_video",
                    value: value["main_youtube_video"],
                  });
                  onGenericChange({
                    key: "product_version",
                    value: value["product_version"],
                  });
                  await onGenericChange({
                    key: "redirect_link",
                    value: value["redirect_link"],
                  });
                  await onGenericChange({
                    key: "venue_address",
                    value: value["venue_address"],
                  });
                }}>
                <div className="GeneralDescriptionModal__card__body__brief">
                  <FormGroup>
                    <ControlLabel>
                      <strong>
                        Short Description (
                        {
                          _.get(short_desc, `description_${current_lang}`, "")
                            .length
                        }
                        /300 characters)
                      </strong>
                    </ControlLabel>
                    <FormControl
                      rows={5}
                      name={`description_${current_lang}`}
                      componentClass="textarea"
                    />
                  </FormGroup>
                  <div className="GeneralDescriptionModal__card__body__brief__col2">
                    <FormGroup>
                      <ControlLabel>
                        <strong>Venue Address</strong>
                      </ControlLabel>
                      <FormControl name="venue_address" />
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel>
                        <strong>Product Version</strong>
                      </ControlLabel>
                      <FormControl name="product_version" />
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel>
                        <strong>Video</strong>
                      </ControlLabel>
                      <FormControl name="main_youtube_video" />
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel>
                        <strong>Redirect Link</strong>
                      </ControlLabel>
                      <FormControl name="redirect_link" />
                    </FormGroup>
                  </div>
                </div>
              </Form>
              <ReactQuill
                className="GeneralDescriptionModal__card__body__general_desc custom-quill"
                value={description}
                modules={quillModules}
                formats={quillFormats}
                onChange={(value, delta, source) => {
                  if (source == "api") {
                    return;
                  }

                  setDescription(value);
                }}
              />
              <span>
                <strong>Booking Information</strong>
              </span>
              <ReactQuill
                className="GeneralDescriptionModal__card__body__booking_info custom-quill"
                modules={quillModules}
                formats={quillFormats}
                value={_.get(
                  booking_information,
                  `description_${current_lang}`,
                  ""
                )}
                onChange={(value, delta, source) => {
                  if (source == "api") {
                    return;
                  }

                  onBookingInfoChange(current_lang, value);
                }}
              />
            </React.Fragment>
          )}
        </div>
        <div className={classes.cardActions}>
          <IconButton
            color="blue"
            icon={<Icon icon="close" />}
            onClick={async () => {
              await onDescriptionChange("en", desc_en_content);
              await onDescriptionChange("cn", desc_en_content);
              await onDescriptionChange("it", desc_it_content);
              await onDescriptionChange("es", desc_es_content);
              await onDescriptionChange("fr", desc_fr_content);
              await onDescriptionChange("el", desc_el_content);
              await onDescriptionChange("nl", desc_nl_content);
              await onDescriptionChange("de", desc_de_content);
              await onDescriptionChange("pt", desc_pt_content);
              onHide();
            }}>
            Close
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default GeneralDescriptionModal;
