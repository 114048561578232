import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import _ from "lodash";
import { variables } from "@src/jsssetup";
import TransTxt from "@src/components/common/SxFormatMessage";
import { injectIntl } from "react-intl";

const titleMapping = {
  cxled_confirmed_percs: {
    ACC: `B2B_stats_CreatPieChart1__accommodations_reservations_statuses`,
    FL: `B2B_stats_CreatPieChart1__flights_reservations_statuses`,
    TRF: `B2B_stats_CreatPieChart1__transfers_reservations_statuses`,
    TRA: `B2B_stats_CreatPieChart1__trains_reservations_statuses`,
    ACT: "B2B_stats_CreatPieChart1__activities_reservations_statuses",
    MI: "B2B_stats_CreatPieChart1__addons_reservations_statuses",
    ADHACC: "B2B_stats_CreatPieChart1__adhoc_reservations_statuses",
    APIFER: "B2B_stats_CreatPieChart1__api_ferries_reservations_statuses",
    APITRA: "B2B_stats_CreatPieChart1__api_trains_reservations_statuses",
    COA: "B2B_stats_CreatPieChart1__coaches_reservations_statuses",
    COO: "B2B_stats_CreatPieChart1__coordinators_reservations_statuses",
    CUACC: "B2B_stats_CreatPieChart1__contracted_accommodations_statuses",
    FER: "B2B_stats_CreatPieChart1__ferries_reservations_statuses",
    GEN: "B2B_stats_CreatPieChart1__general_services_reservations_statuses",
    RES: "B2B_stats_CreatPieChart1__restaurants_reservations_statuses",
    CAR: "B2B_stats_CreatPieChart1__car_rental_reservations_statuses",
  },
  fail_success_percs: {
    ACC: `B2B_stats_CreatPieChart1__title_ACC`,
    FL: `B2B_stats_CreatPieChart1__title_FL`,
    TRF: `B2B_stats_CreatPieChart1__title_TRF`,
    TRA: `B2B_stats_CreatPieChart1__title_TRA`,
    ACT: `B2B_stats_CreatPieChart1__title_ACT`,
    MI: `B2B_stats_CreatPieChart1__title_MI`,
    ADHACC: `B2B_stats_CreatPieChart1__title_ADHACC`,
    APIFER: `B2B_stats_CreatPieChart1__title_APIFER`,
    APITRA: `B2B_stats_CreatPieChart1__title_APITRA`,
    COA: `B2B_stats_CreatPieChart1__title_COA`,
    COO: `B2B_stats_CreatPieChart1__title_COO`,
    CUACC: `B2B_stats_CreatPieChart1__title_CUACC`,
    FER: `B2B_stats_CreatPieChart1__title_FER`,
    GEN: `B2B_stats_CreatPieChart1__title_GEN`,
    RES: `B2B_stats_CreatPieChart1__title_RES`,
    CAR: `B2B_stats_CreatPieChart1__title_CAR`,
  },
};
const srvMapping = {
  ACC: "B2B_stats_CreatPieChart1__accommodations",
  FL: "B2B_stats_CreatPieChart1__flights",
  TRF: "B2B_stats_CreatPieChart1__transfers",
  TRA: "B2B_stats_CreatPieChart1__trains",
  MI: "B2B_stats_CreatPieChart1__addons",
  ACT: "B2B_stats_CreatPieChart1__activities",
  ADHACC: "B2B_stats_CreatPieChart1__adhocs",
  APIFER: "B2B_stats_CreatPieChart1__api_ferries",
  APITRA: "B2B_stats_CreatPieChart1__api_trains",
  COA: "B2B_stats_CreatPieChart1__coach",
  COO: "B2B_stats_CreatPieChart1__coordinators",
  CUACC: "B2B_stats_CreatPieChart1__contracted_accommodations",
  FER: "B2B_stats_CreatPieChart1__ferries",
  GEN: "B2B_stats_CreatPieChart1__general_services",
  RES: "B2B_stats_CreatPieChart1__restaurants",
  CAR: "B2B_stats_CreatPieChart1__car_rental",
};
const srvReservationsMapping = {
  ACC: "B2B_stats_CreatPieChart1__accommodations_reservations",
  FL: "B2B_stats_CreatPieChart1__flights_reservations",
  TRF: "B2B_stats_CreatPieChart1__transfers_reservations",
  TRA: "B2B_stats_CreatPieChart1__trains_reservations",
  MI: "B2B_stats_CreatPieChart1__addons_reservations",
  ACT: "B2B_stats_CreatPieChart1__activities_reservations",
  ADHACC: "B2B_stats_CreatPieChart1__adhoc_reservations",
  APIFER: "B2B_stats_CreatPieChart1__api_ferries_reservations",
  APITRA: "B2B_stats_CreatPieChart1__api_trains_reservations",
  COA: "B2B_stats_CreatPieChart1__coach_reservations",
  COO: "B2B_stats_CreatPieChart1__coordinators_reservations",
  CUACC: "B2B_stats_CreatPieChart1__contracted_accommodations_reservations",
  FER: "B2B_stats_CreatPieChart1__ferries_reservations",
  GEN: "B2B_stats_CreatPieChart1__general_services_reservations",
  RES: "B2B_stats_CreatPieChart1__restaurants_reservations",
  CAR: "B2B_stats_CreatPieChart1__car_rental_reservations",
};

const pieChartStyles = createUseStyles({
  CreatePieChartSecond: {
    display: "grid",
    padding: variables.normal_gap,
    width: "23rem",
    height: "30vh",
  },
});
var CreatePieChartSecond = ({
  chartType,
  currency,
  colors,
  dashboardPayload,
  data,
  intl,
}) => {
  const classes = pieChartStyles();
  const chartRef = useRef(null);

  const createChartInstance = () => {
    const chartInstance = echarts.init(chartRef.current);
    const dataSources = Object.entries(
      _.get(data, `stats_per_currency.${currency}.${chartType}`, {})
    );
    const finalData = dataSources.map(([name, value], idx) => ({
      name: intl.formatMessage({ id: `B2B_stats_CreatPieChart1__${name}` }),
      value,
      itemStyle: {
        color: colors[idx] || "gray",
      },
    }));
    const customColors = [
      "#ff5733",
      "#33ff57",
      "#5733ff",
      "#33ffff",
      "#ff33ff",
    ];
    const option = {
      title: [
        {
          text: _.get(
            titleMapping,
            `${chartType}.${_.get(dashboardPayload, "serviceType")}`
          )
            ? intl.formatMessage({
                id: _.get(
                  titleMapping,
                  `${chartType}.${_.get(dashboardPayload, "serviceType")}`
                ),
              })
            : "N/A",
          left: "center",
          textStyle: {
            fontSize: 16,
            fontWeight: "bold",
          },
        },
      ],

      tooltip: {
        trigger: "item",
        formatter: function (params) {
          return `<strong>${intl.formatMessage({
            id: srvReservationsMapping[
              _.get(dashboardPayload, "serviceType", {})
            ],
          })}</strong><br /> ${params.name} ${params.value * 100}%`;
        },

        // formatter: function (params) {
        //   return `<strong>
        //     ${intl.formatMessage({
        //       id: srvReservationsMapping[
        //         _.get(dashboardPayload, "serviceType", {})
        //       ],
        //     })},
        //   </strong><br /> ${params.name} ${params.value * 100}%`;
        // },
        //   `<strong>
        //   ${intl.formatMessage({
        //     id:
        //     srvReservationsMapping[serviceType],
        //   })}
        //   </strong><br /> ${params.name} ${params.value * 100}%`;
        // },
      },
      legend: {
        type: "scroll",
        orient: "vertical",
        left: 0,
        bottom: 20,
        itemWidth: 10,
        itemHeight: 10,
        textStyle: {
          fontSize: 8,
          color: colors,
        },
      },
      color: customColors,

      series: [
        {
          name: `${intl.formatMessage({
            id: srvMapping[_.get(dashboardPayload, "serviceType", {})],
          })}`,
          type: "pie",
          top: "25%",
          radius: ["40%", "70%"],
          avoidLabelOverlap: true,
          itemStyle: {
            borderRadius: 10,
            borderColor: "#fff",
            borderWidth: 2,
          },
          label: {
            show: false,
          },
          emphasis: {
            label: {
              show: false,
              fontWeight: "bold",
            },
            labelLine: {
              show: true,
            },
          },
          labelLine: {
            show: true,
          },
          data: finalData,
        },
      ],
    };

    chartInstance.setOption(option);
    return chartInstance;
  };
  useEffect(() => {
    createChartInstance();
  }, [data, currency]);
  useEffect(() => {
    if (!data) return;
    const existingChart = echarts.getInstanceByDom(chartRef.current);
    if (existingChart) {
      existingChart.resize();
    }
  }, []);
  return (
    <React.Fragment>
      <div ref={chartRef} className={classes.CreatePieChartSecond} />
    </React.Fragment>
  );
};
CreatePieChartSecond.propTypes = {
  chartType: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  dataSources: PropTypes.array.isRequired,
  colors: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  dashboardPayload: PropTypes.object.isRequired,
};
CreatePieChartSecond = injectIntl(CreatePieChartSecond);
export default CreatePieChartSecond;
