import _ from "lodash";
import moment from "moment";
import { connect } from "react-redux";
import { DateRangePicker, Icon, IconButton, Input, Nav, Table } from "rsuite";
import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  addDistPeriod,
  changeDistPeriod,
  copyDistPeriod,
  removeDistPeriod,
} from "@src/actions/Project/TripPlanner";
import StopSalesSection from "./StopSalesSection";
import IncExcRulesSection from "./IncExcRulesSection";
import MktCxlSection from "./MktCxlSection";
import MktPricingSection from "./MktPricingSection";
import MktContentSection from "./MktContentSection";
import MktDistributionSetupSection from "./MktDistributionSetupSection";
import PkgMetadata from "./marketplace/PkgMetadata";
import HighlightsSection from "./HighlightsSection";
import PkgBookingInformation from "./PkgBookingInformation";

export const DatePickers = (props) => {
  var { idx, attr, value, onChange } = props;

  return (
    <DateRangePicker
      format="DD/MM/YYYY"
      placement="auto"
      disabledDate={function (date) {
        moment(date).isBefore(moment());
      }}
      value={value.filter((v) => v).map((v) => moment(v).toDate())}
      onChange={function (value) {
        onChange(
          idx,
          `${attr}_from`,
          moment.parseZone(value[0]).format("YYYY-MM-DD")
        );
        onChange(
          idx,
          `${attr}_to`,
          moment.parseZone(value[1]).format("YYYY-MM-DD")
        );
      }}
      onClean={function () {
        onChange(idx, `${attr}_from`, "");
        onChange(idx, `${attr}_to`, "");
      }}
    />
  );
};

DatePickers.propTypes = {
  idx: PropTypes.number.isRequired,
  attr: PropTypes.oneOf(["operation", "distribution"]).isRequired,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

class TDistPeriods extends React.PureComponent {
  constructor(props) {
    super(props);
  }
  renderDatePicker(idx, key, value) {
    var { onChange } = this.props;

    return (
      <DateRangePicker
        format="DD/MM/YYYY"
        placement="auto"
        disabledDate={function (date) {
          moment(date).isBefore(moment());
        }}
        value={value.filter((v) => v).map((v) => moment(v).toDate())}
        onChange={function (value) {
          onChange(
            idx,
            `${key}_from`,
            moment.parseZone(value[0]).format("YYYY-MM-DD")
          );
          onChange(
            idx,
            `${key}_to`,
            moment.parseZone(value[1]).format("YYYY-MM-DD")
          );
        }}
        onClean={function () {
          onChange(idx, `${key}_from`, "");
          onChange(idx, `${key}_to`, "");
        }}
      />
    );
  }
  render() {
    var { periods, onAdd, onRemove, onChange, onCopy } = this.props;
    periods = periods.map((per, idx) => ({ ...per, idx }));

    return (
      <Table data={periods} rowHeight={60}>
        <Table.Column width={120}>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.Cell dataKey="num">
            {(rowData) => (
              <Input
                value={rowData.name}
                onChange={function (value) {
                  onChange(rowData.idx, "name", value);
                }}
              />
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Distribution From - To</Table.HeaderCell>
          <Table.Cell dataKey="num">
            {(rowData) =>
              this.renderDatePicker(rowData.idx, "distribution", [
                rowData.distribution_from,
                rowData.distribution_to,
              ])
            }
          </Table.Cell>
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Operation From - To</Table.HeaderCell>
          <Table.Cell dataKey="num">
            {(rowData) =>
              this.renderDatePicker(rowData.idx, "operation", [
                rowData.operation_from,
                rowData.operation_to,
              ])
            }
          </Table.Cell>
        </Table.Column>
        <Table.Column width={300} fixed="right">
          <Table.HeaderCell>Actions</Table.HeaderCell>
          <Table.Cell>
            {(rowData) => {
              return (
                <React.Fragment>
                  <IconButton
                    icon={<Icon icon="plus" />}
                    size="xs"
                    color="green"
                    onClick={onAdd}>
                    Add Below
                  </IconButton>{" "}
                  {periods.length == 1 ? null : (
                    <React.Fragment>
                      <IconButton
                        icon={<Icon icon="minus" />}
                        size="xs"
                        color="red"
                        onClick={function () {
                          onRemove(rowData.idx);
                        }}>
                        Remove
                      </IconButton>{" "}
                    </React.Fragment>
                  )}
                  <IconButton
                    icon={<Icon icon="copy" />}
                    size="xs"
                    color="blue"
                    onClick={function () {
                      console.log(rowData.idx);
                      onCopy(rowData.idx);
                    }}>
                    Copy Below
                  </IconButton>
                </React.Fragment>
              );
            }}
          </Table.Cell>
        </Table.Column>
      </Table>
    );
  }
}

TDistPeriods.propTypes = {
  periods: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired,
};

const TDistPeriodsMapStateToProps = (state) => {
  const { periods } = state.tripPlannerMktDistributionPeriods;
  return { periods };
};
const TDistPeriodsMapDispatchToProps = (dispatch) => {
  return {
    onAdd: () => dispatch(addDistPeriod()),
    onRemove: (idx) => dispatch(removeDistPeriod(idx)),
    onChange: (idx, key, value) => dispatch(changeDistPeriod(idx, key, value)),
    onCopy: (idx) => dispatch(copyDistPeriod(idx)),
  };
};

const DistPeriods = connect(
  TDistPeriodsMapStateToProps,
  TDistPeriodsMapDispatchToProps
)(TDistPeriods);

const MktSectionsPanel = () => {
  const [section, setSection] = useState("distribution_setup");

  const sections = [
    // "distribution_periods",
    // "stop_sales",
    "distribution_setup",
    "highlights",
    "inclusions_exclusions",
    "booking_information",
    "content",
    "metadata",
  ];

  return (
    <div className="MktFinalizeTopController__section MktSectionsPanel">
      <Nav appearance="tabs" activeKey={section}>
        {sections.map((section, idx) => (
          <Nav.Item
            key={idx}
            eventKey={section}
            onClick={() => setSection(section)}>
            {_.startCase(section)}
          </Nav.Item>
        ))}
      </Nav>
      <div className="MktFinalizeTopController__section__content">
        {section === "highlights" && <HighlightsSection />}
        {section === "inclusions_exclusions" && <IncExcRulesSection />}
        {section === "booking_information" && <PkgBookingInformation />}
        {section === "content" && <MktContentSection />}
        {section === "metadata" && <PkgMetadata />}
        {section === "distribution_setup" && <MktDistributionSetupSection />}
      </div>
    </div>
  );
};
export default MktSectionsPanel;
