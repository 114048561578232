import { acc_reservation_status_color_mapping } from "./accommodation";

const cabin = [
  {
    value: 1,
    label: "Economy",
  },
  {
    value: 2,
    label: "Premium Economy",
  },
  {
    value: 3,
    label: "Business",
  },
  {
    value: 4,
    label: "First Class",
  },
];

const stops = [
  {
    value: 1,
    label: "Direct",
  },
  {
    value: 2,
    label: "One Stop",
  },
  {
    value: 3,
    label: "2+ Stops",
  },
];
const alliance = [
  {
    value: 1,
    label: "Oneworld",
  },
  {
    value: 2,
    label: "Sky Team",
  },
  {
    value: 3,
    label: "Star Alliance",
  },
];
const vehicle = [
  {
    value: 1,
    label: "Car",
  },
  {
    value: 2,
    label: "Taxi",
  },
  {
    value: 3,
    label: "Minivan",
  },
  {
    value: 4,
    label: "Bus",
  },
];
const transferType = [
  {
    value: 1,
    label: "Private",
  },
  {
    value: 2,
    label: "Shared",
  },
];
const accommodationType = [
  {
    value: 1,
    label: "Hotel",
  },
  {
    value: 2,
    label: "Villa",
  },
  {
    value: 3,
    label: "Apartment",
  },
];
const transportationType = [
  {
    value: 1,
    label: "Flight",
  },
  {
    value: 2,
    label: "Ferry",
  },
  {
    value: 3,
    label: "Bus",
  },
  {
    value: 4,
    label: "Car",
  },
  {
    value: 5,
    label: "Train",
  },
  {
    value: 6,
    label: "Long Range Transfer",
  },
];
const rating = [
  {
    value: 5,
    label: "5",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 1,
    label: "1",
  },
];
const board = [
  {
    value: 1,
    label: "Breakfast",
  },
  {
    value: 2,
    label: "Breakfast & Meal",
  },
  {
    value: 3,
    label: "Breakfast - Lunch - Dinnner",
  },
  {
    value: 4,
    label: "All Inclusive",
  },
];
const wheelchair = [
  {
    value: 1,
    label: "Need",
  },
  {
    value: 2,
    label: "Without",
  },
];
const roomType = [
  {
    value: 1,
    label: "Double",
  },
  {
    value: 2,
    label: "Twin Beds",
  },
  {
    value: 3,
    label: "King Bed",
  },
  {
    value: 4,
    label: "Suite",
  },
];

const accommodationPerferred = [
  {
    value: 1,
    label: "Free WiFi",
  },
  {
    value: 2,
    label: "Fitness Center",
  },
  {
    value: 3,
    label: "Wheelchair Accessible",
  },
  {
    value: 4,
    label: "Non-smoking Rooms",
  },
  {
    value: 5,
    label: "Sauna",
  },
  {
    value: 6,
    label: "Massage",
  },
  {
    value: 7,
    label: "Restaruant",
  },
  {
    value: 8,
    label: "Swimming Pool",
  },
  {
    value: 9,
    label: "Toilet with Grab Rails",
  },
  {
    value: 10,
    label: "Spa",
  },
];
const services = [
  {
    value: 1,
    label: "Transportation",
  },
  {
    value: 2,
    label: "Transfer",
  },
  {
    value: 3,
    label: "Accommodation",
  },
  {
    value: 4,
    label: "Add Ons",
  },
];

export const flightReservationStatus = {
  IS: "Issued",
  BO: "Booked",
  PE: "Pending",
  CA: "Cancelled",
  FA: "Failed",
};

export const flightReservationStatusColor = {
  IS: "green",
  BO: "blue",
  PE: "violet",
  CA: "orange",
  FA: "red",
};

export const accommodationReservationStatus = {
  CO: "Confirmed",
  // RQ: "On Request",
  PE: "Pending",
  CA: "Cancelled",
  FA: "Failed",
};

export const transferReservationStatus = {
  CO: "Confirmed",
  // RQ: "On Request",
  PE: "Pending",
  CA: "Cancelled",
  FA: "Failed",
};

export const addOnReservationStatus = {
  CO: "Confirmed",
  PE: "Pending",
  CA: "Cancelled",
  FA: "Failed",
};

export const coordinatorReservationStatus = {
  CO: "Confirmed",
  PE: "Pending",
  CA: "Cancelled",
  FA: "Failed",
  RQ: "On Request",
};

export const ferryReservationStatus = {
  CO: "Confirmed",
  PE: "Pending",
  CA: "Cancelled",
  FA: "Failed",
  RQ: "On Request",
};

export const getStatusBySrvType = (srvType) => {
  switch (srvType) {
    case "FL":
      return flightReservationStatus;
    case "ACC":
      return accommodationReservationStatus;
    case "TRF":
      return transferReservationStatus;
    case "MI":
      return addOnReservationStatus;
    case "COO":
      return coordinatorReservationStatus;
    case "FER":
      return ferryReservationStatus;
    default:
      return {};
  }
};

export const getStatusColorBySrvType = (srvType) => {
  switch (srvType) {
    case "FL":
      return flightReservationStatusColor;
    case "ACC":
      return acc_reservation_status_color_mapping;
    case "TRF":
      return acc_reservation_status_color_mapping;
    case "MI":
      return acc_reservation_status_color_mapping;
    case "COO":
      return acc_reservation_status_color_mapping;
    case "FER":
      return acc_reservation_status_color_mapping;
    case "RR":
      return acc_reservation_status_color_mapping;
    default:
      return {};
  }
};

export const emailStatusMapping = {
  SE: "Sent",
  FA: "Failed",
  PE: "Pending",
};

export const addon_icon_mapping = {
  highlight: "icon-highlightsicon",
  food_and_drink: "icon-foodanddrinkicon",
  shopping: "icon-Shoppingicon",
};

export {
  cabin,
  stops,
  alliance,
  vehicle,
  transferType,
  accommodationType,
  transportationType,
  rating,
  board,
  wheelchair,
  roomType,
  accommodationPerferred,
  services,
};
