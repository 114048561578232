import React, { memo, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import _ from "lodash";
import { formStyles, modalGenericStyles, variables } from "@src/jsssetup";
import PreviewPDF, { MainPage } from "./pdfComponents/PreviewPDF";
import { EmailMessage } from "./components";
import { Form, Formik, useFormikContext } from "formik";
import {
  createPayload,
  customerSchema,
  onSaveInvoiceDocument,
  validationSchema,
} from "./helpers";
import { toast } from "react-toastify";
import { saveInvoice } from "@src/api/DynamicInvoice";
import { usePDF, Document } from "@react-pdf/renderer";
import { CustomButton } from "@src/components/common/buttons";
import { Loader } from "rsuite";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import {
  createAirwallexPaymentLink,
  createFellohPaymentLink,
  createFlywirePaymentLink,
  fetchSubAgentsProfile,
} from "@src/api";
import {
  NormalInputField,
  NormalProviderAutocompleteField,
  NormalSelectField,
  NormalSubagentAutocompleteField,
  subAgentValuer,
  subagentLabeler,
} from "@src/components/forms";
import { CustomerInfo } from "./CustomerInfo";
import { Items } from "./Items";
import { Guests } from "./Guests";
import { Options } from "./Options";
import Hashids from "hashids";
import { DateTime } from "luxon";
import { getUserSourceEntitySelector } from "@src/selectors/Shared/user_selectors";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { PaymentLinks } from "./PaymentLinks";
const hashids = new Hashids();

function generateInvoiceReference() {
  return hashids
    .encode(DateTime.now().toMillis() + parseInt(Math.random() * 1000, 10))
    .toUpperCase();
}

function dataInitial({ initialSetup, initialSubagentLabel }) {
  return {
    product_type: initialSetup?.productType || "offer",
    product_reference: initialSetup?.reference || "",
    customer_type: initialSetup?.customerType || "guest",
    provider: "",
    subagent: !!initialSubagentLabel ? initialSubagentLabel : "",
    currency: "EUR",
    dueDate: "",
    invoiceNumber: generateInvoiceReference(),
    memo: "",
    footer: "",
    payment_method: null,
    payment_link: null,
    payment_links_list: [],
    stamp: "",
    xAxis: 0,
    yAxis: 0,
    customer: {
      name: "",
      address: "",
      country: "",
      phone: "",
      company: "",
      company_email: "", // this will be used as the sender email
    },
    email: {
      sender_email: "",
      receiver_email: "",
      cc_email: "",
      email_subject: "",
      email_body: "",
    },
    customFields: [],
    items: [],
    guests: [],
  };
}

const additional_options = [
  ["custom_fields", "Custom fields"],
  ["memo", "Memo"],
  ["footer", "Footer"],
  // ["tax_id", "Tax ID"],
  ["payment_methods", "Payment Methods"],
  ["stamps", "Stamps"],
];

const PDFInvoice = memo(({ data, metadata, values }) => {
  return (
    <Document title="Customize PDF Report">
      <MainPage data={data} metadata={metadata} values={values} />
    </Document>
  );
});
PDFInvoice.propTypes = {
  data: PropTypes.object.isRequired,
  metadata: PropTypes.object.isRequired,
  reference: PropTypes.object,
};

const submitterStyles = createUseStyles({
  Submitter: { height: "30vh" },
});
const Submitter = ({
  payment_link,
  payment_reference,
  form,
  formErrors,
  pdfVersion,
  pdfData,
  onFail,
  onSuccess,
  productType,
}) => {
  const classes = submitterStyles();

  const source_entity = useSelector((state) =>
    getUserSourceEntitySelector(state)
  );
  const metadata = useSelector((state) => companyMetadataSelector(state));

  async function onSubmit({ pdfInstance }) {
    if (pdfVersion === 0) {
      onFail("Generate pdf to continue");
      return;
    }

    form.guests.forEach((guest) => {
      guest.currency = form.currency;
    });
    form.items.forEach((item) => {
      item.currency = form.currency;
    });
    const updatedValidationSchema = validationSchema({
      currencyRequired: true,
    });
    try {
      await updatedValidationSchema.validate(form, {
        abortEarly: false,
      });
    } catch (errors) {
      onFail("Please fill all required fields");
      return;
    }

    const logo = metadata?.logo?.photo_sm_url ?? "";
    var payload = createPayload({
      values: form,
      source_entity,
      logo,
      payment_link,
      payment_reference,
      productType,
    });

    const responseOnDocSave = await onSaveInvoiceDocument({
      pdfInstance,
      payload,
    });

    if (_.isEmpty(responseOnDocSave)) {
      onFail("Failed to upload invoice pdf.");
      return;
    }
    payload["invoice_pdf_path"] = responseOnDocSave.path;
    payload["invoice_pdf_uid"] = responseOnDocSave.uid;

    const result = await saveInvoice(payload);
    if (!result) {
      onFail("Invoice creation failed.");
    } else {
      onSuccess("Invoice created successfully!");
    }
  }

  const [pdfInstance, updateInstance] = usePDF({
    document: <PDFInvoice data={pdfData} metadata={metadata} />,
  });

  useEffect(() => {
    if (!_.isEmpty(formErrors)) {
      return;
    }

    if (pdfInstance.loading) {
      return;
    }

    onSubmit({ pdfInstance });
  }, [pdfInstance.loading]);

  if (!_.isEmpty(formErrors)) {
    const errorEntries = Object.entries(formErrors).map(
      ([fieldKey, fieldError]) => ({
        field: _.startCase(fieldKey),
        message: Array.isArray(fieldError)
          ? _.flatten(
              fieldError.filter((obj) => obj).map((obj) => errorMessenger(obj))
            ).join(", ")
          : fieldError && typeof fieldError === "object"
          ? errorMessenger(fieldError).join(", ")
          : fieldError,
      })
    );
    const errorMessage = errorEntries
      .map(({ field, message }) => `<li>${field} : ${message}</li>`)
      .filter((errorMessage) => errorMessage !== null)
      .join("");

    if (errorMessage) {
      toast.warning(
        <div>
          <p>Please correct all fields:</p>
          <ul
            dangerouslySetInnerHTML={{
              __html: errorMessage,
            }}
          />
        </div>,
        {
          className: classes.toastMssg,
          autoClose: 3000,
          position: "top-center",
          closeOnClick: true,
          hideProgressBar: false,
          theme: "light",
          type: "warning",
          toastId: "correctAllFields",
        }
      );
    }
    onFail();
    return null;
  }

  return (
    <div className={classes.Submitter}>
      <Loader size="lg" center />
    </div>
  );
};
Submitter.propTypes = {
  payment_link: PropTypes.string,
  payment_reference: PropTypes.string,
  form: PropTypes.object.isRequired,
  formErrors: PropTypes.object.isRequired,
  pdfVersion: PropTypes.number.isRequired,
  pdfData: PropTypes.object.isRequired,
  metadata: PropTypes.object,
  onFail: PropTypes.func,
  onSuccess: PropTypes.func,
  productType: PropTypes.string,
};

const SubmitInvoiceBtn = ({ isEditing, onGoToSubmit }) => {
  const { submitForm } = useFormikContext();
  return (
    <CustomButton
      onClick={() => {
        if (isEditing) {
          toast.warning(
            "Please finish editing the invoice before generating PDF",
            { autoClose: 6000 }
          );
          return;
        }
        onGoToSubmit();
        submitForm();
      }}>
      Submit
    </CustomButton>
  );
};
SubmitInvoiceBtn.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  onGoToSubmit: PropTypes.func.isRequired,
};

const getEntityColors = (state) => {
  const { entity_colors } = state.companyProfile;
  return entity_colors;
};
const getCompanyProfile = (state) => {
  const { logo, logo_url, legal_title, name } = state.companyProfile;
  return { logo, logo_url, legal_title, name };
};
const companyMetadataSelector = createSelector(
  [getCompanyProfile, getEntityColors],
  (companyProfile, entityColors) => {
    const { logo, logo_url, legal_title, name } = companyProfile;
    return { logo, logo_url, legal_title, name, entity_colors: entityColors };
  }
);

function errorMessenger(obj) {
  return Object.entries(obj).map(
    ([key, value]) => `${_.startCase(key)}: ${value}`
  );
}

const DynamicInvoiceModalStyles = createUseStyles({
  ...modalGenericStyles,
  DynamicInvoiceModal: { ...modalGenericStyles.modal },
  card: (props) => ({
    ...modalGenericStyles.card,
    display: "grid",
    gridTemplateRows: "max-content 1fr max-content",
    height: props.step > 0 ? "90vh" : "auto",
    maxWidth: "95vw",
  }),
  cardBody: (props) => ({
    ...modalGenericStyles.cardBody,
    display: "grid",
    gridTemplateColumns: props.step === 0 ? "1fr" : "2fr 3fr",
    gridGap: variables.normal_gap,
    overflow: "hidden",
    position: "relative",
    minHeight: props.step === 0 ? "auto" : "30vh",
    maxHeight: "unset",
    height: "100%",
  }),
  panel: {
    overflow: "auto",
  },
  formContainer: {
    display: "grid",
    gridGap: variables.normal_gap,
    alignContent: "baseline",
  },
  previewContr: {
    background: variables.colors.easy.lightOrange3,
    height: "100%",
    width: "100%",
    overflow: "auto",
  },
  section: {},
  sectionTitle: {
    display: "grid",
    gridTemplateColumns: "1fr max-content",
  },
  items: {
    display: "grid",
    gridGap: variables.half_gap,
  },
  item: {
    display: "grid",
    gridTemplateColumns: "1fr max-content max-content",
    gridGap: variables.normal_gap,
  },
  checkboxLbl: {
    display: "grid",
    gridTemplateColumns: "max-content max-content",
    gridGap: variables.half_gap,
  },
  toastMssg: {
    fontSize: "large",
  },
  actionsBtnContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(2,max-content)",
    placeContent: "center",
    gridGap: variables.normal_gap,
  },
  customerForm: {
    ...formStyles.form,
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateColumns: "repeat(2, 1fr)",
    gridAutoRows: "max-content",
  },
});
export const DynamicInvoiceModal = ({ invoiceInitialSetup, onHide }) => {
  const [step, setStep] = useState(0);
  const classes = DynamicInvoiceModalStyles({ step });
  const queryClient = useQueryClient();
  const [data, setData] = useState(
    dataInitial({ initialSetup: invoiceInitialSetup })
  );
  const [addItemForm, setAddItemForm] = useState(null);
  const [addGuestForm, setAddGuestForm] = useState(null);
  const [paymentLinkForm, setPaymentLinkForm] = useState(null);
  const [customerEdit, setCustomerEdit] = useState(false);
  const [paymentLinksEdit, setPaymentLinksEdit] = useState(false);
  const [memoEdit, setMemoEdit] = useState(false);
  const [footerEdit, setFooterEdit] = useState(false);
  const [optionsData, setOptionsData] = useState(additional_options);
  const [pdfVersion, setPdfVersion] = useState(0);
  const [paymentLinkResponse, setPaymentLinkResponse] = useState("");

  const source_entity = useSelector((state) =>
    getUserSourceEntitySelector(state)
  );

  const metadata = useSelector((state) => companyMetadataSelector(state));

  const { data: initialSubagentData } = useQuery({
    queryKey: ["invoiceInitialSubagentData", invoiceInitialSetup?.targetEntity],
    queryFn: () =>
      fetchSubAgentsProfile(invoiceInitialSetup.targetEntity.split("___")[0]),
    enabled: (invoiceInitialSetup?.targetEntity ?? "").includes("subagent"),
  });

  var initialSubagentLabel = "";
  if (typeof initialSubagentData !== "undefined") {
    initialSubagentData["label"] = subagentLabeler(initialSubagentData);
    initialSubagentLabel = subAgentValuer({ option: initialSubagentData });
  }

  async function generatePdfHandle({ values, payment_link }) {
    await setPdfVersion((p) => p + 1);
    if (payment_link) {
      await setData({ ...values, payment_link });
    } else {
      await setData(values);
    }
  }

  async function generatePdfHandleForPLink({ payment_link }) {
    await setPdfVersion((p) => p + 1);
    await setData((p) => ({ ...p, payment_link }));
  }

  async function onPaymentLinkSuccess(response) {
    const payment_link = response?.data?.payment_link;
    if (payment_link) {
      // the payment link response contains the payment link
      // and the payment reference
      await setPaymentLinkResponse(response?.data ?? {});
      await generatePdfHandleForPLink({ payment_link });
      setStep(2);
      return;
    }
    toast.error(
      "Payment link not found. Something went wrong, please try again",
      { autoClose: 6000 }
    );
  }
  function onPaymentLinkError(error) {
    console.log({ error });
    toast.error("Payment link not generated. Please try again", {
      autoClose: 6000,
    });
  }

  const airWallexMutation = useMutation({
    mutationFn: ({ payload }) => createAirwallexPaymentLink(payload),
    onSuccess: async (res) => await onPaymentLinkSuccess(res),
    onError: (err) => onPaymentLinkError(err),
  });
  const flyWireMutation = useMutation({
    mutationFn: ({ payload }) => createFlywirePaymentLink(payload),
    onSuccess: async (res) => await onPaymentLinkSuccess(res),
    onError: (err) => onPaymentLinkError(err),
  });
  const fellohMutation = useMutation({
    mutationFn: ({ payload }) => createFellohPaymentLink(payload),
    onSuccess: async (res) => await onPaymentLinkSuccess(res),
    onError: (err) => onPaymentLinkError(err),
  });

  const mutationIsLoading =
    airWallexMutation.isLoading ||
    flyWireMutation.isLoading ||
    fellohMutation.isLoading;

  const paymentLinkMutationMapping = {
    airwallex: airWallexMutation,
    flywire: flyWireMutation,
    felloh: fellohMutation,
  };

  function createField(entityData, field) {
    const address = entityData.address.find(
      (addr) => addr.address_type === "BU"
    );
    if (field === "address") {
      return [address.street_number, address.street, address.post_code]
        .filter((v) => v)
        .join(", ");
    }
    if (field === "country") {
      return address.country_code;
    } else if (field === "city") {
      return address.city;
    }
  }

  async function generatePaymentLink({ values }) {
    const subagent = queryClient.getQueryData([
      "invoiceSubagentData",
      values.subagent,
    ]);
    const provider = queryClient.getQueryData([
      "invoiceProviderData",
      values.provider,
    ]);
    const entityData = !provider ? subagent : provider;

    var first_name = values.customer.name;
    var last_name = values.customer.name;
    if (values.customer.name.split(" ").length > 1) {
      first_name = values.customer.name.split(" ");
      last_name = first_name.pop();
      first_name = first_name.join(" ");
    }

    const payload = {
      // for flywire
      first_name,
      last_name,
      phone: entityData.phone,
      address: createField(entityData, "address"),
      country: createField(entityData, "country"),
      city: createField(entityData, "city"),
      // for airwallex and felloh
      customer_name: values.customer.name,
      email: values.customer.company_email,
      booking_id: values.invoiceNumber,
      amount: values.items
        .reduce((acc, item) => acc + item.unitPrice, 0)
        .toString(),
      source_entity,
      open_banking_enabled: true,
      card_enabled: true,
      currency: values.currency,
      description: `Payment for invoice ${values.invoiceNumber}`,
      expires_at: values.dueDate,
      authorisation_only: false,
      booking_data: {
        // for firewire
        first_name: values.customer.name,
        last_name: values.customer.name,
        // for airwallex
        customer_name: values.customer.name,
        email: values.email.receiver_email,
        gross_amount: values.items.reduce(
          (acc, item) => acc + item.unitPrice,
          0
        ),
        // create date for today
        start_date: DateTime.now().toISODate(),
        end_date: values.dueDate,
        booking_reference: values.invoiceNumber,
        srv_type: "ACC",
      },
    };

    const mutation = paymentLinkMutationMapping?.[values.payment_method];
    await mutation.mutate({ payload });
  }
  const isEditing =
    addGuestForm !== null ||
    addItemForm !== null ||
    paymentLinkForm !== null ||
    footerEdit ||
    memoEdit ||
    customerEdit;

  const [productType, setProductType] = useState("");

  const productTypeMapping = {
    offer: "OFF",
    addon: "MI",
    hotel: "ACC",
    flight: "FL",
  };

  return (
    <div className={classes.DynamicInvoiceModal}>
      <div className={classes.card}>
        <div className={classes.cardHeader}>
          <h5>Invoice Modal</h5>
        </div>
        {mutationIsLoading && <Loader center size="lg" backdrop />}
        <Formik
          initialValues={dataInitial({
            initialSetup: invoiceInitialSetup,
            initialSubagentLabel,
          })}
          validateOnMount={false}
          validateOnChange={true}
          validateOnBlur={false}
          validationSchema={validationSchema({ currencyRequired: false })}
          onSubmit={() => {}}>
          {({ values, errors, setFieldValue }) =>
            step === 0 ? (
              <React.Fragment>
                <div className={classes.cardBody}>
                  <div className={classes.customerForm}>
                    <NormalSelectField
                      name="customer_type"
                      label="Customer Type"
                      options={[
                        ["guest", "Guest"],
                        ["subagent", "Sub-Agent"],
                        ["provider", "Provider"],
                      ]}
                    />
                    {values.customer_type === "subagent" ? (
                      <NormalSubagentAutocompleteField
                        id="DynamicInvoiceModal__NormalSubagentAutocompleteField"
                        name="subagent"
                        label="Sub-Agent"
                      />
                    ) : values.customer_type === "provider" ? (
                      <NormalProviderAutocompleteField
                        id="DynamicInvoiceModal__NormalProviderAutocompleteField"
                        name="provider"
                        label="Provider"
                      />
                    ) : (
                      <div />
                    )}
                    <NormalSelectField
                      name="product_type"
                      label="Product Type"
                      options={[
                        ["offer", "Offer"],
                        ["addon", "Add-On Reservation"],
                        /* ["hotel", "Hotel Reservation"], */
                        /* ["flight", "Flight Reservation"], */
                      ]}
                    />
                    <NormalInputField
                      name="product_reference"
                      label="Product Reference"
                    />
                  </div>
                </div>
                <div className={classes.cardActions}>
                  <CustomButton appearance="ghost" onClick={onHide}>
                    Close
                  </CustomButton>
                  <CustomButton
                    id="next_step"
                    onClick={() => {
                      setFieldValue("customer", customerSchema().cast({}));
                      setFieldValue("customFields", []);
                      const selectedProductType =
                        productTypeMapping[values.product_type] ?? "";
                      setProductType(selectedProductType);
                      setStep(1);
                    }}>
                    Next
                  </CustomButton>
                </div>
              </React.Fragment>
            ) : step === 1 ? (
              <React.Fragment>
                <div className={classes.cardBody}>
                  <div className={classes.panel}>
                    {values && (
                      <Form className={classes.formContainer}>
                        <CustomerInfo
                          customerEdit={customerEdit}
                          setCustomerEdit={setCustomerEdit}
                        />
                        <Items
                          onResetItem={(initialItemState, itemIdx) =>
                            setFieldValue(`items.${itemIdx}`, initialItemState)
                          }
                          onRemoveItem={(itemIdx) => {
                            const updatedItems = [...values.items];
                            updatedItems.splice(itemIdx, 1);
                            setFieldValue("items", updatedItems);
                          }}
                          addItemForm={addItemForm}
                          setAddItemForm={setAddItemForm}
                        />
                        <Guests
                          onResetGuest={(initialGuestState, guestIdx) =>
                            setFieldValue(
                              `guests.${guestIdx}`,
                              initialGuestState
                            )
                          }
                          onRemoveGuest={(guestIdx) => {
                            const updatedGuests = [...values.guests];
                            updatedGuests.splice(guestIdx, 1);
                            setFieldValue("guests", updatedGuests);
                          }}
                          addGuestForm={addGuestForm}
                          setAddGuestForm={setAddGuestForm}
                        />
                        <PaymentLinks
                          setPaymentLinkForm={setPaymentLinkForm}
                          paymentLinkForm={paymentLinkForm}
                          paymentLinksEdit={paymentLinksEdit}
                          setPaymentLinksEdit={setPaymentLinksEdit}
                          onResetPaymentLink={(
                            initialPaymentLinkState,
                            linkIdx
                          ) =>
                            setFieldValue(
                              `payment_links_list.${linkIdx}`,
                              initialPaymentLinkState
                            )
                          }
                          onRemovePaymentLink={(linkIdx) => {
                            const updatedLinks = [...values.payment_links_list];
                            updatedLinks.splice(linkIdx, 1);
                            setFieldValue("payment_links_list", updatedLinks);
                          }}
                        />
                        {optionsData && values && (
                          <Options
                            data={optionsData}
                            memoEdit={memoEdit}
                            setMemoEdit={setMemoEdit}
                            footerEdit={footerEdit}
                            setFooterEdit={setFooterEdit}
                          />
                        )}
                        <EmailMessage />
                      </Form>
                    )}
                  </div>
                  <div className={classes.previewContr}>
                    <PreviewPDF>
                      <PDFInvoice data={data} metadata={metadata} />
                    </PreviewPDF>
                  </div>
                </div>
                <div className={classes.cardActions}>
                  <CustomButton
                    id="close_modal"
                    appearance="ghost"
                    onClick={onHide}>
                    Close
                  </CustomButton>
                  <CustomButton
                    appearance="ghost"
                    onClick={() => {
                      setStep((p) => p - 1);
                      setCustomerEdit(false);
                    }}>
                    Back
                  </CustomButton>
                  <CustomButton
                    onClick={(e) => {
                      if (isEditing) {
                        toast.warning(
                          "Please finish editing the invoice before generating PDF",
                          { autoClose: 3000 }
                        );
                        return;
                      }
                      e.preventDefault();
                      generatePdfHandle({ values });
                    }}>
                    Generate PDF
                  </CustomButton>
                  <SubmitInvoiceBtn
                    isEditing={isEditing}
                    onGoToSubmit={async () => {
                      await generatePdfHandle({ values });
                      if (!values.payment_method) {
                        setStep(2);
                        return;
                      }
                      await generatePaymentLink({ values });
                    }}
                  />
                </div>
              </React.Fragment>
            ) : (
              <Submitter
                payment_link={paymentLinkResponse?.payment_link}
                payment_reference={paymentLinkResponse?.payment_reference}
                pdfData={data}
                form={values}
                formErrors={errors}
                pdfVersion={pdfVersion}
                productType={productType}
                onFail={(msg) => {
                  if (msg) toast.error(msg, { autoClose: 6000 });
                  setStep(1);
                }}
                onSuccess={(msg) => {
                  if (msg) toast.success(msg, { autoClose: 5000 });
                  onHide();
                }}
              />
            )
          }
        </Formik>
      </div>
    </div>
  );
};
DynamicInvoiceModal.propTypes = {
  invoiceInitialSetup: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
};
