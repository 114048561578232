import {
  initialBranchesColumnsSetUp,
  initialBranchesColumnsProcessor,
  initialBranchesColumnsMapping,
} from "./../Branches";

export const initialSubAgentsColumnsSetUp = initialBranchesColumnsSetUp;

export const initialSubAgentsColumnsProcessor = initialBranchesColumnsProcessor;

export const initialSubAgentsColumnsMapping = initialBranchesColumnsMapping;
