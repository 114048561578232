import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import _ from "lodash";
import { variables } from "@src/jsssetup";
import { DateTime, Duration } from "luxon";
import { InlineIcon } from "@iconify/react";
import { currencies } from "@src/definitions";
import { Train } from "./Train";
import { TrainPrice } from "./TrainPrice";

const styles = createUseStyles({
  DateTimeComp: (props) => ({
    fontSize: "medium",
    ..._.get(props, "customStyles.DateTimeComp", {}),
  }),
  time: (props) => ({
    fontWeight: "bold",
    ..._.get(props, "customStyles.time", {}),
  }),
  date: (props) => ({
    ..._.get(props, "customStyles.date", {}),
  }),
  [`@media ${variables.media.smallscreen}`]: {
    time: (props) => ({
      fontSize: "large",
      ..._.get(props, "customStyles.time", {}),
    }),
  },
  [`@media ${variables.media.bigscreen}`]: {},
});
export const DateTimeComp = ({ dateTime }) => {
  const classes = styles({});
  return (
    <div className={classes.DateTimeComp}>
      <div className={classes.time}>
        {DateTime.fromISO(dateTime).toLocal().toFormat("hh:mm a")}
      </div>
      <div className={classes.date}>
        {DateTime.fromISO(dateTime).toLocaleString(DateTime.DATE_MED)}
      </div>
    </div>
  );
};
DateTimeComp.defaultProps = {
  dateTime: "",
  dateTimeObj: "",
};
DateTimeComp.propTypes = {
  dateTime: PropTypes.string.isRequired,
  dateTimeObj: PropTypes.string.isRequired,
};

export const Header = ({ data }) => {
  const departure = _.get(data, "departure", "");
  const arrival = _.get(data, "arrival", "");
  const duration = Duration.fromISO(_.get(data, "duration", ""));

  const minPrice = Math.min(
    ...data.offers.map((offer) => Number(offer.amount.value))
  ).toFixed(2);
  const direct = _.get(data, "segments.length", 0) === 1;
  const currency = _.get(data, "offers.0.amount.currency", "EUR");
  const carrier = _.get(data, "segments.0.marketing_carrier", "");
 
  return (
    <div className="Header">
      <div className="Header__headerContr">
        <DateTimeComp dateTime={departure} />
        <InlineIcon
          icon="material-symbols:arrow-right-alt"
          className="Header__headerContr__icon"
        />
        <DateTimeComp dateTime={arrival} />
        <div className="Header__headerContr__duration">
          <div>
            {`${duration.values.hours} hrs ${duration.values.minutes} min`}
            {direct && " direct"}
          </div>
        </div>
        <div className="Header__headerContr__carrier">
          <div>{carrier}</div>
        </div>
        <div className="Header__headerContr__priceComp">
          From
          <div className="Header__headerContr__priceComp__price">
            <TrainPrice price={Number(minPrice)} currency={currency} />
          </div>
        </div>
      </div>
    </div>
  );
};
Header.defaultProps = {
  data: {},
};
Header.propTypes = {
  data: PropTypes.object.isRequired,
};
