import { Notification } from "rsuite";
import React from "react";

export const notifyCommonWarning = (msg) => {
  Notification.warning({
    title: "Warning",
    description: <p>{msg}</p>,
    duration: 6000,
  });
};
