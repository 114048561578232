import {
  // ========================== INIT ACTIONS =========================
  TRIPPLANNER_INIT,
  // ======================= TRIP LOAD ACTIONS =======================
  TRIPPLANNER_LOAD_TRIP,
  // ========================= GUEST ACTIONS =========================
  TRIPPLANNER_SHOW_GUEST_MODAL,
  TRIPPLANNER_HIDE_GUEST_MODAL,
  TRIPPLANNER_INIT_GUESTS,
  TRIPPLANNER_CHANGE_GUEST,
  TRIPPLANNER_GUESTS_ARE_VALID,
  TRIPPLANNER_GUESTS_ARE_INVALID,
  TRIPPLANNER_SAVING_GUESTS,
  TRIPPLANNER_SAVED_GUESTS,
  // =================== ROOM GUEST MAPPING ACTIONS ==================
  TRIPPLANNER_SHOW_ROOMGUEST_MODAL,
  TRIPPLANNER_HIDE_ROOMGUEST_MODAL,
  TRIPPLANNER_CHANGE_ROOMGUEST_MAPPING,
  TRIPPLANNER_INIT_ROOMGUEST_MAPPING,
  TRIPPLANNER_SAVING_ROOMGUESTS_MAPPING,
  TRIPPLANNER_SAVED_ROOMGUESTS_MAPPING,
  TRIPPLANNER_ROOMGUESTS_MAPPING_IS_VALID,
  TRIPPLANNER_ROOMGUESTS_MAPPING_IS_INVALID,
  TRIPPLANNER_UPDATE_GUESTS,
  TRIPPLANNER_RESET_GUESTS_ROOMS,
} from "@src/actions/Project/TripPlanner/types";
import * as yup from "yup";
import _ from "lodash";
import update from "immutability-helper";
import v4 from "uuid";

const tripPlannerGuestsModalInitial = { show: false, loading: false };
export const tripPlannerGuestsModal = (
  state = tripPlannerGuestsModalInitial,
  action
) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return tripPlannerGuestsModalInitial;
    case TRIPPLANNER_LOAD_TRIP:
      return tripPlannerGuestsModalInitial;
    case TRIPPLANNER_SHOW_GUEST_MODAL:
      return update(state, { show: { $set: true } });
    case TRIPPLANNER_HIDE_GUEST_MODAL:
      return update(state, { show: { $set: false } });
    case TRIPPLANNER_SAVING_GUESTS:
      return update(state, { loading: { $set: true } });
    case TRIPPLANNER_SAVED_GUESTS:
      return update(state, { loading: { $set: false } });
    default:
      return state;
  }
};

function transferSpecificGuestFields() {
  return {
    phone: yup
      .string()
      .nullable()
      .test(
        "required_phone_for_group_leader",
        "Phone is required for Group Leader",
        function (value) {
          const { group_leader } = this.parent;
          return !group_leader || (group_leader && value);
        }
      ),
  };
}

function hotelSpecificGuestFields() {
  return {
    room_uids: yup
      .array()
      .of(yup.string())
      .required("A room must be selected.")
      .test(
        "room_uids_selected",
        "A room must be selected.",
        (value) => value.length > 0
      ),
  };
}

function flightSpecificGuestRules() {
  return {
    identification_type: yup
      .string()
      .oneOf(["ID", "PA"], "Invalid ID type.")
      .default(""),
    identification_code: yup
      .string()
      .required("ID or Passport number is required.")
      .nullable(),
    identification_expiry_date: yup
      .string()
      .required("ID expiry date is required.")
      .nullable(),
    gender: yup.string().required("Gender is required."),
    date_of_birth: yup
      .string()
      .nullable()
      .required("Date of birth is required."),
  };
}

function aferSpecificGuestRules() {
  return {
    date_of_birth: yup
      .string()
      .nullable()
      .required("Date of birth is required."),
  };
}

export function guestValidationSchema({ srv_types_for_booking = [] } = {}) {
  return yup.object().shape({
    guest_type: yup.string().required("Guest type is required."),
    guest_title: yup
      .string()
      .oneOf(["MR", "MRS", "MS"], "Invalid guest title.")
      .required("Guest title is required."),
    first_name: yup.string().required("First name is required."),
    last_name: yup.string().required("Last name is required."),
    identification_type: yup.string().oneOf(["ID", "PA"], "Invalid ID type."),
    identification_code: yup.string().nullable(),
    identification_expiry_date: yup.string().nullable(),
    nationality: yup
      .string()
      .required("Nationality is required.")
      .length(2, "Nationality should be exactly 2 characters.")
      .nullable(),
    email: yup
      .string()
      .email("Invalid email format.")
      .nullable()
      .test(
        "if_group_leader_required",
        "Email is required for group leader.",
        function (value) {
          const { group_leader } = this.parent;
          return !group_leader || (group_leader && value);
        }
      ),
    phone: yup.string().nullable(),
    date_of_birth: yup.string().nullable(),
    birth_place: yup.string().nullable(),
    room_leader: yup.boolean().default(false),
    group_leader: yup.boolean().default(false),
    gender: yup.string(),
    guest_uid: yup.string().required("Guest UID is required.").default(v4),
    frequent_flyer_number: yup.string().nullable(),
    frequent_flyer_airline: yup.string().nullable(),
    room_uids: yup.array().of(yup.string()).default([]),
    ...(srv_types_for_booking.includes("FL") ? flightSpecificGuestRules() : {}),
    ...(srv_types_for_booking.includes("AFER") ? aferSpecificGuestRules() : {}),
    ...(srv_types_for_booking.includes("ACC")
      ? hotelSpecificGuestFields()
      : {}),
    ...(srv_types_for_booking.includes("TRF")
      ? transferSpecificGuestFields()
      : {}),
  });
}

export function tripGuestsValidationSchema({
  srv_types_for_booking = [],
} = {}) {
  return yup.object().shape({
    adults: yup
      .array()
      .of(guestValidationSchema({ srv_types_for_booking }))
      .min(1)
      .test(
        "group_leader",
        "Exactly one group leader is required.",
        (value) => {
          const groupLeaders = value.filter((guest) => guest.group_leader);
          return groupLeaders.length === 1;
        }
      )
      .test(
        "at_least_on_room_leader_per_room",
        "Each room must have exactly one room leader",
        (value) => {
          const roomMapping = {};

          value.forEach((guest) =>
            (guest?.room_uids || []).forEach((uid) => {
              if (roomMapping?.[uid]) {
                roomMapping[uid].push(guest);
              } else {
                roomMapping[uid] = [guest];
              }
            })
          );

          const rooms = Object.keys(roomMapping);

          const roomLeaders = _.flatten(
            Object.entries(roomMapping).map(([roomUid, roomGuests]) =>
              roomGuests.filter((g) => g.room_leader).map((g) => g.guest_uid)
            )
          );
          return roomLeaders.length === rooms.length;
        }
      ),
    children: yup.array().of(guestValidationSchema({ srv_types_for_booking })),
  });
}

export const adultTemplate = {
  guest_type: "ADT",
  guest_title: "",
  first_name: "",
  last_name: "",
  identification_type: "",
  identification_code: "",
  identification_expiry_date: "",
  nationality: "",
  email: "",
  phone: "",
  date_of_birth: "",
  birth_place: "",
  room_leader: false,
  group_leader: false,
  gender: "",
  guest_uid: "",
  frequent_flyer_number: "",
  frequent_flyer_airline: "",
};

export const childTemplate = {
  guest_type: "CHD",
  first_name: "",
  last_name: "",
  identification_type: "",
  identification_code: "",
  identification_expiry_date: "",
  nationality: "",
  date_of_birth: "",
  birth_place: "",
  gender: "",
  guest_uid: "",
  frequent_flyer_number: "",
  frequent_flyer_airline: "",
};
export const tripPlannerGuestsInfo = (state = {}, action) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return {};
    case TRIPPLANNER_LOAD_TRIP: {
      const { guest_information } = action.data;
      return guest_information ? guest_information : {};
    }
    case TRIPPLANNER_CHANGE_GUEST: {
      const { guestType, idx, formValue } = action;
      return update(state, { [guestType]: { [idx]: { $set: formValue } } });
    }
    case TRIPPLANNER_INIT_GUESTS: {
      const { adults, children, nationality } = action;

      return update(state, {
        ["adults"]: {
          $apply: () =>
            new Array(adults).fill(1).map((i, idx) =>
              update(_.cloneDeep(adultTemplate), {
                guest_uid: { $set: v4() },
                nationality: { $set: nationality },
                group_leader: { $set: idx === 0 },
                room_leader: { $set: idx === 0 },
              })
            ),
        },
        ["children"]: {
          $apply: () =>
            new Array(children).fill(1).map(() =>
              update(_.cloneDeep(childTemplate), {
                guest_uid: { $set: v4() },
                nationality: { $set: nationality },
              })
            ),
        },
      });
    }
    case TRIPPLANNER_UPDATE_GUESTS: {
      const { guestsInfo } = action;
      return guestsInfo;
    }
    case TRIPPLANNER_RESET_GUESTS_ROOMS: {
      return update(state, {
        adults: {
          $apply: (adts) =>
            adts.map((adt) => update(adt, { room_uids: { $set: [] } })),
        },
        children: {
          $apply: (chds) =>
            chds.map((chd) => update(chd, { room_uids: { $set: [] } })),
        },
      });
    }
    default:
      return state;
  }
};

export const tripPlannerGuestsInfoValidity = (state = false, action) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return false;
    case TRIPPLANNER_LOAD_TRIP:
      return action.data.guest_information !== null;
    case TRIPPLANNER_INIT_GUESTS:
      return false;
    case TRIPPLANNER_GUESTS_ARE_VALID:
      return true;
    case TRIPPLANNER_GUESTS_ARE_INVALID:
      return false;
    default:
      return state;
  }
};

const tripPlannerRoomGuestModalInitial = { show: false, loading: false };
export const tripPlannerRoomGuestModal = (
  state = tripPlannerRoomGuestModalInitial,
  action
) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return tripPlannerRoomGuestModalInitial;
    case TRIPPLANNER_LOAD_TRIP:
      return tripPlannerRoomGuestModalInitial;
    case TRIPPLANNER_SHOW_ROOMGUEST_MODAL:
      return update(state, { show: { $set: true } });
    case TRIPPLANNER_HIDE_ROOMGUEST_MODAL:
      return tripPlannerRoomGuestModalInitial;
    case TRIPPLANNER_SAVING_ROOMGUESTS_MAPPING:
      return update(state, { loading: { $set: true } });
    case TRIPPLANNER_SAVED_ROOMGUESTS_MAPPING:
      return update(state, { loading: { $set: false } });
    default:
      return state;
  }
};

export const tripPlannerRoomsGuestMapping = (state = {}, action) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return {};
    case TRIPPLANNER_LOAD_TRIP: {
      const { guest_room_mapping } = action.data;
      return guest_room_mapping ? guest_room_mapping : {};
    }
    case TRIPPLANNER_INIT_ROOMGUEST_MAPPING: {
      const { guests, roomIds } = action;

      const tmp = {};
      guests.forEach((g) => (tmp[g.guest_uid] = roomIds));
      return tmp;
    }
    case TRIPPLANNER_CHANGE_ROOMGUEST_MAPPING: {
      const { roomCodes, guestUid, actionType } = action;

      return actionType == "add"
        ? update(state, {
            [guestUid]: {
              $apply: (rooms) => [...new Set([...rooms, ...roomCodes])],
            },
          })
        : update(state, {
            [guestUid]: {
              $apply: (rooms) =>
                rooms.filter((code) => !roomCodes.includes(code)),
            },
          });
    }
    default:
      return state;
  }
};

export const tripPlannerRoomsGuestMappingValidity = (state = false, action) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return false;
    case TRIPPLANNER_LOAD_TRIP:
      return action.data.guest_room_mapping !== null;
    case TRIPPLANNER_INIT_ROOMGUEST_MAPPING: {
      const { isValid } = action;
      return isValid;
    }
    case TRIPPLANNER_ROOMGUESTS_MAPPING_IS_VALID:
      return true;
    case TRIPPLANNER_ROOMGUESTS_MAPPING_IS_INVALID:
      return false;
    default:
      return state;
  }
};
