// import React from "react";
import { initialCompanyBaseDataForm } from "@src/forms/Admin/Company/companyProfileForms";
import { Schema, Tag } from "rsuite";
const { ArrayType, StringType } = Schema.Types;
import _ from "lodash";

// import SxFormatMessage from "@src/components/common/SxFormatMessage";

// export const initialSuppliersFilterForm = {
//   status: {
//     value: [],
//     verbose: "Status",
//     data: [
//       {
//         value: "AC",
//         display_name: (
//           <Tag color="green">
//             <strong>Active</strong>
//           </Tag>
//         ),
//         label: (
//           <Tag color="green">
//             <strong>Active</strong>
//           </Tag>
//         ),
//       },
//       {
//         value: "IN",
//         display_name: (
//           <Tag color="red">
//             <strong>Inactive</strong>
//           </Tag>
//         ),
//         label: (
//           <Tag color="red">
//             <strong>Inactive</strong>
//           </Tag>
//         ),
//       },
//       {
//         value: "CA",
//         display_name: (
//           <Tag color="violet">
//             <strong>Candidate</strong>
//           </Tag>
//         ),
//         label: (
//           <Tag color="violet">
//             <strong>Candidate</strong>
//           </Tag>
//         ),
//       },
//     ],
//   },
//   postal_address: {
//     value: "",
//     verbose: <SxFormatMessage id="address" startCase />,
//   },
//   postal_city: {
//     value: "",
//     verbose: <SxFormatMessage id="city" startCase />,
//   },
//   postal_post_code: {
//     value: "",
//     verbose: <SxFormatMessage id="post_code" startCase />,
//   },
//   postal_region: {
//     value: "",
//     verbose: <SxFormatMessage id="region" startCase />,
//   },
//   postal_country: {
//     value: "",
//     verbose: <SxFormatMessage id="country" startCase />,
//   },
//   billing_address: {
//     value: "",
//     verbose: "Billing Address",
//   },
//   licence: {
//     value: "",
//     verbose: "Licence",
//   },
//   address: {
//     value: "",
//     verbose: "Address",
//   },
//   id: {
//     value: "",
//     verbose: <SxFormatMessage id="uid" upperCase />,
//   },
//   business_name: {
//     value: "",
//     verbose: <SxFormatMessage id="affiliates_business_name" startCase />,
//   },
//   post_code: {
//     value: "",
//     verbose: "Post Code",
//   },
//   region: {
//     value: "",
//     verbose: "Region",
//   },
//   country: {
//     value: "",
//     verbose: "Country",
//   },
//   brand_name: {
//     value: "",
//     verbose: <SxFormatMessage id="affiliates_brand_name" startCase />,
//   },
//   city: {
//     value: "",
//     verbose: "City",
//   },
//   person_name: {
//     value: "",
//     verbose: <SxFormatMessage id="contact_person" startCase />,
//   },
//   phone: {
//     value: "",
//     verbose: <SxFormatMessage id="phone" startCase />,
//   },
//   mobile_phone: {
//     value: "",
//     verbose: <SxFormatMessage id="mobile_phone" startCase />,
//   },
//   legal_title: {
//     value: "",
//     verbose: "Legal Title",
//   },
//   registration_code: {
//     value: "",
//     verbose: <SxFormatMessage id="registration_code" startCase />,
//   },
//   license_number: {
//     value: "",
//     verbose: "License Number",
//   },
//   tax_office: {
//     value: "",
//     verbose: <SxFormatMessage id="tax_office" startCase />,
//   },
//   email: {
//     value: "",
//     verbose: <SxFormatMessage id="email" startCase />,
//   },
//   entity_type: {
//     value: [],
//     verbose: "Entity Type",
//     labelKey: "display_name",
//   },
//   expertise_type: {
//     value: [],
//     verbose: "Expertise Type",
//     labelKey: "display_name",
//   },
//   license_type: {
//     value: "",
//     verbose: "License Type",
//   },
//   license_type_choices: {
//     value: [],
//     verbose: "License Type",
//     labelKey: "display_name",
//   },
//   creator: {
//     value: "",
//     verbose: <SxFormatMessage id="created_by" startCase />,
//   },
//   last_editor: {
//     value: "",
//     verbose: <SxFormatMessage id="last_edited_date" startCase />,
//   },
//   created: {
//     value: [],
//     placement: "left",
//     verbose: <SxFormatMessage id="created_date" startCase />,
//   },
//   edited: {
//     value: [],
//     placement: "left",
//     verbose: "Last Edited Date",
//   },
//   url: {
//     value: "",
//     verbose: <SxFormatMessage id="url" upperCase />,
//   },
//   founding_year: {
//     value: "",
//     verbose: <SxFormatMessage id="founding_year" startCase />,
//   },
//   travel_sector: {
//     value: [],
//     verbose: "travel_sector",
//   },
//   travel_services: {
//     value: "",
//     verbose: "Travel Services",
//   },
//   last_name: {
//     value: "",
//     verbose: <SxFormatMessage id="last_name" startCase />,
//   },
//   first_name: {
//     value: "",
//     verbose: <SxFormatMessage id="first_name" startCase />,
//   },
// };

export const initialSuppliersBaseDataForm = _.merge(
  initialCompanyBaseDataForm,
  {
    travel_services: {
      value: [],
      required: false,
      verbose: "Travel Services",
      validator: ArrayType().unrepeatable("Duplicate services cannot appear."),
    },
    currency: {
      value: "",
      verbose: "currency",
      required: false,
      placement: "top",
    },
    name: {
      value: "",
      required: true,
      verbose: "brand_name",
      type: "text",
      validator: StringType().isRequired("Brand Name is required"),
    },
  }
);

// export const defaultOption = {
//   basic: {
//     value: ["name", "status"],
//   },
// };

// export * from "./columns";
