import React, { useEffect, useRef, useState } from "react";
import * as echarts from "echarts";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import _ from "lodash";
import { DateTime } from "luxon";
import { variables } from "@src/jsssetup";
import { injectIntl } from "react-intl";

// import { injectIntl } from "react-intl";

const titleMapping = {
  entities_per_month: {
    ACC: "B2B_stats_CreateBarChart1__accommodations_reservations_entities_per_month",
    FL: "B2B_stats_CreateBarChart1__flights_reservations_entities_per_month",
    TRF: "B2B_stats_CreateBarChart1__transfers_reservations_entities_per_month",
    TRA: "B2B_stats_CreateBarChart1__trains_reservations_entities_per_month",
    ACT: "B2B_stats_CreateBarChart1__activities_reservations_entities_per_month",
    MI: "B2B_stats_CreateBarChart1__addons_reservations_entities_per_month",
    ADHACC: "B2B_stats_CreateBarChart1__adhoc_reservations_entities_per_month",
    APIFER:
      "B2B_stats_CreateBarChart1__api_ferries_reservations_entities_per_month",
    APITRA:
      "B2B_stats_CreateBarChart1__api_trains_reservations_entities_per_month",
    COA: "B2B_stats_CreateBarChart1__coaches_reservations_entities_per_month",
    COO: "B2B_stats_CreateBarChart1__coordinators_reservations_entities_per_month",
    CUACC:
      "B2B_stats_CreateBarChart1__contracted_accommodations_entities_per_month",
    FER: "B2B_stats_CreateBarChart1__ferries_reservations_entities_per_month",
    GEN: "B2B_stats_CreateBarChart1__general_services_reservations_entities_per_month",
    RES: "B2B_stats_CreateBarChart1__restaurants_reservations_entities_per_month",
    CAR: "B2B_stats_CreateBarChart1__car_rental_reservations_entities_per_month",
  },
};

const tooltipLabelMapping = {
  entities_per_month: "B2B_stats_CreateBarChart1__entities",
};

const srvMapping = {
  ACC: "B2B_stats_CreateBarChart1__accommodations",
  FL: "B2B_stats_CreateBarChart1__flights",
  TRF: "B2B_stats_CreateBarChart1__transfers",
  TRA: "B2B_stats_CreateBarChart1__trains",
  MI: "B2B_stats_CreateBarChart1__addons",
  ACT: "B2B_stats_CreateBarChart1__activities",
  ADHACC: "B2B_stats_CreateBarChart1__adhocs",
  APIFER: "B2B_stats_CreateBarChart1__api_ferries",
  APITRA: "B2B_stats_CreateBarChart1__api_trains",
  COA: "B2B_stats_CreateBarChart1__coach",
  COO: "B2B_stats_CreateBarChart1__coordinators",
  CUACC: "B2B_stats_CreateBarChart1__contracted_accommodations",
  FER: "B2B_stats_CreateBarChart1__ferries",
  GEN: "B2B_stats_CreateBarChart1__general_services",
  RES: "B2B_stats_CreateBarChart1__restaurants",
  CAR: "B2B_stats_CreateBarChart1__car_rental",
};

const chartStyles = createUseStyles({
  CreateBarChartSecond: {
    "display": "grid",
    "padding": variables.normal_gap,
    "width": "37rem",
    "height": "30vh",
    "& div": {
      width: "100%",
      height: "100%",
    },
    "& canvas": {
      alignSelf: "center",
      height: "100%!important",
      width: "100%!important",
    },
  },
  title: {
    display: "grid",
    alignContent: "end",
    fontWeight: "bold",
  },
  chart: {
    display: "grid",
    padding: variables.normal_gap,
    width: "40vw",
    height: "30vh",
  },
});
var CreateBarChartSecond = ({
  chartType,
  currency,
  color,
  dashboardPayload,
  data,
  intl,
}) => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const classes = chartStyles();
  const chartRef = useRef(null);

  const createChartInstance = () => {
    const chartInstance = echarts.init(chartRef.current);
    const ent_per_month = _.get(
      data,
      `stats_per_currency.${currency}.${chartType}`,
      []
    );
    const finalData = ent_per_month.map(([xAxis, yAxis]) => {
      return {
        name: DateTime.fromISO(xAxis).toLocaleString(
          DateTime.DATE_MED_WITH_WEEKDAY
        ),
        value: [new Date(xAxis).getTime(), yAxis],
      };
    });
    const options = {
      title: {
        text: _.get(
          titleMapping,
          `${chartType}.${_.get(dashboardPayload, "serviceType")}`
        )
          ? intl.formatMessage({
              id: _.get(
                titleMapping,
                `${chartType}.${_.get(dashboardPayload, "serviceType", {})}`
              ),
            })
          : "N/A",
        left: "center",
        textStyle: {
          fontWeight: "bold",
          fontSize: 16,
        },
      },

      tooltip: {
        trigger: "axis",
        position: function (pt) {
          return [pt[0], "10%"];
        },
        formatter: (params) => {
          const name = params[0].name;
          var value = params[0].value[1];
          return `${name}<br/><strong>${value} ${intl.formatMessage({
            id: tooltipLabelMapping[chartType],
          })}</strong>`;
          // return `${name}<br/><strong>${value} ${tooltipLabelMapping[chartType]}</strong>`;
        },
      },
      xAxis: {
        type: "time",
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          name: `${intl.formatMessage({
            id: _.get(dashboardPayload, "serviceType", {}),
          })}`,
          type: "bar",
          areaStyle: { color },
          data: finalData,
          itemStyle: {
            color: color,
          },
        },
      ],
    };

    chartInstance.setOption(options);
    return chartInstance;
  };

  const handleResize = () => setWindowSize(window.innerWidth);
  window.removeEventListener("resize", handleResize);

  useEffect(() => {
    if (!data) return;
    createChartInstance(data);
    window.addEventListener("resize", handleResize);
  }, [data, currency]);

  useEffect(() => {
    const existingChart = echarts.getInstanceByDom(chartRef.current);
    if (existingChart) {
      existingChart.resize();
    }
  }, [windowSize]);
  return <div ref={chartRef} className={classes.CreateBarChartSecond} />;
};
CreateBarChartSecond.propTypes = {
  chartType: PropTypes.oneOf(["entities_per_month", "aa"]).isRequired,
  currency: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  dashboardPayload: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};
CreateBarChartSecond = injectIntl(CreateBarChartSecond);
export default CreateBarChartSecond;
