import {
  TRIPPLANNER_INIT,
  TRIPPLANNER_LOAD_TRIP,
} from "@src/actions/Project/TripPlanner/types";
import { TRIPPLANNER_CHANGE_OVERVIEW_VIEW_STYLE } from "@src/actions/Project/TripPlanner/overview_types";

const tripPlannerOverviewStyleInitial = {
  view_style: "overview", // ['overview', 'day_by_day']
};

export const tripPlannerOverviewStyle = (
  state = tripPlannerOverviewStyleInitial,
  action
) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return tripPlannerOverviewStyleInitial;
    case TRIPPLANNER_LOAD_TRIP:
      return tripPlannerOverviewStyleInitial;
    case TRIPPLANNER_CHANGE_OVERVIEW_VIEW_STYLE: {
      const { view_style } = action;
      return { ...state, view_style };
    }
    default:
      return state;
  }
};
