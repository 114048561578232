import {
  CUSTOM_SERVICE_ADDON_SHOW_PAX_SCALLING_MODAL,
  CUSTOM_SERVICE_ADDON_HIDE_PAX_SCALLING_MODAL,
  CUSTOM_SERVICE_ADDON_SET_PAX_GROUPS,
} from "./types";

export const showPaxScallingModal = () => {
  return { type: CUSTOM_SERVICE_ADDON_SHOW_PAX_SCALLING_MODAL };
};

export const hidePaxScallingModal = () => {
  return { type: CUSTOM_SERVICE_ADDON_HIDE_PAX_SCALLING_MODAL };
};

export const setPaxScalling = (pax_groups) => (dispatch, getState) => {
  const state = getState();
  const currency = state.userMeta.company_currency;
  const meeting_groups = state.customMeetingPointGroups;

  dispatch({
    type: CUSTOM_SERVICE_ADDON_SET_PAX_GROUPS,
    currency,
    pax_groups,
    meeting_groups,
  });
  dispatch(hidePaxScallingModal());
};
