import _ from "lodash";
import { Button, Loader, Pagination, Tag } from "rsuite";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { tableHeadStyles, tableStyles, variables } from "@src/jsssetup";
import Fuse from "fuse.js";
import {
  generalServicesExist,
  getMikiGeneralServices,
} from "@src/api/GeneralServices";
import { Field, Form, Formik } from "formik";
import { ServiceImportContext } from "./ServiceImportModal";

const fuseCfg = {
  shouldSort: false,
  threshold: 0.5,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ["name"],
};

const itemTableStyles = createUseStyles({
  ItemsTable: {
    width: "100%",
    display: "grid",
  },
  controlStrip: {
    display: "grid",
    justifyItems: "center",
    gridTemplateColumns: "1fr auto",
    padding: variables.normal_gap,
    alignItems: "center",
  },
  table: { width: "100%" },
  tableHead: { ...tableHeadStyles },
  cell: tableStyles.cell,
});
const ItemsTable = ({ type, country, filters }) => {
  const page_size = 100;
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState(items);
  const [importedItems, setImportedItems] = useState(new Set());
  const [multiSelectDisabled, setMultiSelectDisabled] = useState(true);

  const classes = itemTableStyles();

  function pageDecider(idx) {
    if (idx < (page - 1) * page_size) {
      return false;
    }

    if ((page - 1) * page_size + page_size <= idx) {
      return false;
    }

    return true;
  }

  async function retriever() {
    setLoading(true);
    const data = await getMikiGeneralServices({
      params: { service_type: type, country_code: country },
    });

    const srvs = _.get(data, "data.results", []);
    if (data.status < 400) {
      setItems(srvs);
    } else {
      setItems([]);
    }
    setLoading(false);
  }

  async function importedChecker() {
    setLoading(true);
    const ids = filteredItems
      .filter((i, idx) => pageDecider(idx))
      .map((srv) => srv.id);

    if (!ids.length) {
      setLoading(false);
      return;
    }

    const data = await generalServicesExist({ ids });
    const existing_ids = new Set(data.data);
    const tmp = _.cloneDeep(filteredItems)
      .filter((item, idx) => pageDecider(idx))
      .filter((item) => existing_ids.has(item.id))
      .map((item) => item.id);
    setImportedItems(new Set(tmp));
    setLoading(false);
  }

  useEffect(() => retriever(), []);

  useEffect(() => {
    var tmp = items.filter((item) => {
      var checks = [];
      if (filters.external_id) {
        checks.push(
          item.id.toString().includes(filters.external_id.toString())
        );
      }

      if (filters.destination) {
        if (filters.destination.includes(`"`)) {
          var tmp = filters.destination;
          tmp = tmp.replace(/\"/g, "");
          checks.push(item.city.name.toLowerCase() === tmp.toLowerCase());
        } else {
          checks.push(
            item.city.name
              .toLowerCase()
              .includes(filters.destination.toLowerCase())
          );
        }
      }

      if (filters.pricing === "true") {
        checks.push((_.get(item, "pricing", []) || []).length > 0);
      }

      if (!checks.length) {
        return true;
      }

      return checks.every((c) => c);
    });

    if (filters.name) {
      const fuseData = new Fuse(items, fuseCfg);
      tmp = fuseData.search(filters.name);
    }

    setFilteredItems(tmp);
    setPage(1);
  }, [items, filters]);

  useEffect(() => {
    if ([...new Set(filteredItems.map((i) => i.city.name))].length === 1) {
      setMultiSelectDisabled(false);
    } else {
      setMultiSelectDisabled(true);
    }
  }, [filteredItems]);

  useEffect(() => {
    if (filteredItems.length === 0) {
      return;
    }

    importedChecker();
  }, [page, filteredItems]);

  const pagination_props = {
    prev: true,
    last: true,
    next: true,
    first: true,
    size: "lg",
    pages: Math.ceil(filteredItems.length / page_size),
    activePage: page,
    maxButtons: 10,
    onSelect: (page) => setPage(page),
  };

  const selected_items = {};
  filteredItems.forEach((item) => {
    selected_items[item.id] = false;
  });

  const { setSelectedItems, gotoBulkImport } = useContext(ServiceImportContext);

  return (
    <div className={classes.ItemsTable}>
      {loading ? (
        <Loader size="lg" center />
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={{
            select_all: false,
            selected_items: filteredItems
              .filter((i, idx) => pageDecider(idx))
              .map((item) => ({ id: item.id, selected: false })),
          }}
          onSubmit={(values) => {
            const setItems = new Set(
              values.selected_items.filter((i) => i.selected).map((i) => i.id)
            );
            const selected_items = items.filter((i) => setItems.has(i.id));
            setSelectedItems(selected_items);
            gotoBulkImport();
          }}>
          {({ values, setFieldValue }) => (
            <Form>
              <div className={classes.controlStrip}>
                <Pagination {...pagination_props} />
                {values.selected_items.filter((i) => i.selected).length > 0 && (
                  <Button type="submit" appearance="primary">
                    <strong>Import</strong>
                  </Button>
                )}
              </div>
              <table className={classes.table}>
                <thead className={classes.tableHead}>
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        disabled={multiSelectDisabled}
                        onClick={(e) =>
                          setFieldValue(
                            "selected_items",
                            [...values.selected_items]
                              .filter((it) => !importedItems.has(it.id))
                              .map((it) => {
                                it.selected = e.target.checked;
                                return it;
                              })
                          )
                        }
                      />
                    </th>
                    <th>#</th>
                    <th>External Id</th>
                    <th>Name</th>
                    <th>Codename</th>
                    <th>Destination</th>
                    <th>Has Price</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredItems
                    .filter((i, idx) => pageDecider(idx))
                    .map((item, idx) => {
                      const is_imported = importedItems.has(item.id);
                      return (
                        <tr key={idx}>
                          <td className={classes.cell}>
                            {multiSelectDisabled || is_imported ? null : (
                              <Field
                                type="checkbox"
                                name={`selected_items.${idx}.selected`}
                              />
                            )}
                          </td>
                          <td className={classes.cell}>
                            {(page - 1) * page_size + idx + 1}
                          </td>
                          <td className={classes.cell}>{item.id}</td>
                          <td className={classes.cell}>{item.name}</td>
                          <td className={classes.cell}>{item.description}</td>
                          <td className={classes.cell}>{item.city.name}</td>
                          <td className={classes.cell}>
                            {(_.get(item, "pricing", []) || []).length > 0 ? (
                              <Tag color="green">Yes</Tag>
                            ) : (
                              <Tag>No</Tag>
                            )}
                          </td>
                          <td className={classes.cell}>
                            <Tag color={is_imported ? "green" : "red"}>
                              <strong>
                                {is_imported ? "Imported" : "Not Imported"}
                              </strong>
                            </Tag>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              <div className={classes.controlStrip}>
                <Pagination {...pagination_props} />
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};
ItemsTable.propTypes = {
  type: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  setSelectedItems: PropTypes.func.isRequired,
};

export default ItemsTable;
