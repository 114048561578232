import _ from "lodash";
import { Tag } from "rsuite";

import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { getServicesPricingSelector } from "@src/selectors/Project/TripPlanner/pricing";
import { createUseStyles } from "react-jss";
import {
  overviewCtrlSectionHeaderStyles,
  overviewCtrlSectionStyles,
  topControlsOptionBtnStyles,
  topControlsSelectedOptionBtnStyles,
} from "./ServicesFilter";
import { variables } from "@src/jsssetup";
import { PriceExcHoc } from "../Components/PriceDetails";
import { InlineIcon } from "@iconify/react";
import { TripPlanContext } from "../TripPlanner";
import { useFinalSellingPricing } from "@src/pages/hooks/pricing";

var Price = ({ price, currency, rounding = "no-rounding" }) => {
  var finalPrice = price;
  if (rounding === "round-up") {
    finalPrice = Math.ceil(price);
  } else if (rounding === "round-down") {
    finalPrice = Math.floor(price);
  }

  return (
    <strong>
      {parseFloat(finalPrice).toLocaleString("en-US", {
        style: "currency",
        currency,
      })}
    </strong>
  );
};
Price.defaultProps = { price: 0, currency: "EUR", rounding: "no-rounding" };
Price.propTypes = {
  price: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  rounding: PropTypes.oneOf(["no-rounding", "round-up", "round-down"]),
};
Price = PriceExcHoc(Price);

const styles = createUseStyles({
  PricingController: overviewCtrlSectionStyles(),
  header: overviewCtrlSectionHeaderStyles(),
  modes: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: variables.half_gap,
  },
  content: {
    display: "grid",
    gridGap: variables.normal_gap,
    marginTop: variables.normal_gap,
  },
  priceType: {
    display: "grid",
    gridTemplateColumns: "max-content max-content 1fr",
    alignItems: "center",
    gridGap: variables.half_gap,
    fontWeight: "bold",
  },
  icon: { color: variables.colors.easy.lightOrange2, background: "white" },
  buyingPriceCntr: {
    background: variables.colors.lightBlue,
    color: "white",
    fontSize: "small",
  },
  sellingPriceCntr: {
    background: variables.colors.green,
    color: "white",
    fontSize: "small",
  },
  priceIcon: {
    background: variables.colors.background.base,
    borderRadius: variables.half_gap,
    color: variables.colors.easy.orange,
    padding: `calc(${variables.half_gap} / 1.5)`,
    placeSelf: "center",
    fontSize: "xx-large",
  },
});
const PricingController = () => {
  const [mode, setMode] = useState("total");
  const pricing = useSelector((state) => getServicesPricingSelector(state));
  const {
    sellingAmount,
    sellingAmountPerPerson,
    sellingCurrency,
    totalPriceRounding,
  } = useFinalSellingPricing();

  const classes = styles();
  const { setShowCancellationPolicyModal } = useContext(TripPlanContext);

  return (
    <div className={classes.PricingController}>
      <h5 className={classes.header}>PRICING</h5>
      <div className={classes.content}>
        <div className={classes.modes}>
          <button
            className="Button"
            data-full-width="true"
            data-size="xs"
            data-ghost={mode !== "total"}
            onClick={() => setMode("total")}>
            Total Price
          </button>
          <button
            className="Button"
            data-full-width="true"
            data-size="xs"
            data-ghost={mode !== "per_person"}
            onClick={() => setMode("per_person")}>
            Per Person Price
          </button>
        </div>
        <div className={classes.priceType}>
          <InlineIcon
            className={classes.priceIcon}
            icon="carbon:shopping-cart-arrow-up"
          />
          <span>Buying</span>
          <span className="Tag" data-important="true" data-size="xs">
            <Price
              price={
                mode === "total"
                  ? pricing?.buying?.amount ?? 0
                  : pricing?.avgPerPersonBuying?.amount ?? 0
              }
              currency={pricing.currency}
            />
          </span>
        </div>
        <div className={classes.priceType}>
          <InlineIcon
            className={classes.priceIcon}
            icon="carbon:shopping-cart-arrow-down"
          />
          <span>Selling</span>
          <span className="Tag" data-size="xs" data-success="true">
            <Price
              price={mode == "total" ? sellingAmount : sellingAmountPerPerson}
              currency={sellingCurrency}
              rounding={totalPriceRounding}
            />
          </span>
        </div>
        <button
          className="Button"
          data-size="xs"
          data-full-width="true"
          onClick={() => setShowCancellationPolicyModal((p) => !p)}>
          Cancellation & Payment Info
        </button>
      </div>
    </div>
  );
};
export default PricingController;
