import {
  QRMODAL_INIT,
  QRMODAL_SET_URL,
  QRMODAL_SHOW_MODAL,
  QRMODAL_HIDE_MODAL,
  QRMODAL_LOADING_MODAL,
  QRMODAL_IDLE_MODAL,
} from "@src/actions/QRModal/types";

import update from "immutability-helper";

const QRModalInitial = { show: false, loading: false, url: "" };

export const QRModal = (state = QRModalInitial, action) => {
  switch (action.type) {
    case QRMODAL_INIT:
      return QRModalInitial;
    case QRMODAL_SHOW_MODAL:
      return update(state, { show: { $set: true } });
    case QRMODAL_HIDE_MODAL:
      return QRModalInitial;
    case QRMODAL_LOADING_MODAL:
      return update(state, { loading: { $set: true } });
    case QRMODAL_IDLE_MODAL:
      return update(state, { loading: { $set: false } });
    case QRMODAL_SET_URL:
      return update(state, { url: { $set: action.url } });
    default:
      return state;
  }
};
