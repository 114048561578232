import { createSelector } from "reselect";
import _ from "lodash";

const propsInjector = (state, props) => props;

const addOnItems = (state) => state.customServiceAddOnItems;

export const getItemIdxsSelector = createSelector([addOnItems], (items) => {
  return items.map((i) => i.order);
});

export const getItemSelector = createSelector(
  [addOnItems, propsInjector],
  (items, props) => {
    const { order } = props;

    const item = items.find((i) => i.order == order);
    item.isLast = items.length == item.order;

    return { item, isLast: items.length == item.order };
  }
);

const getAddOnDescriptions = (state) => {
  const {
    description_en,
    description_cn,
    description_it,
    description_es,
    description_fr,
    description_el,
    description_nl,
    description_de,
  } = _.get(state, "customServiceAddOn.general_description", {});

  const {
    description_en: short_description_en,
    description_cn: short_description_cn,
    description_it: short_description_it,
    description_es: short_description_es,
    description_fr: short_description_fr,
    description_el: short_description_el,
    description_nl: short_description_nl,
    description_de: short_description_de,
  } = _.get(state, "customServiceAddOn.general_short_description", {});

  const {
    description_en: booking_info_en,
    description_cn: booking_info_cn,
    description_it: booking_info_it,
    description_es: booking_info_es,
    description_fr: booking_info_fr,
    description_el: booking_info_el,
    description_nl: booking_info_nl,
    description_de: booking_info_de,
  } = _.get(state, "customServiceAddOn.booking_information", {});

  return {
    description_en,
    description_cn,
    description_it,
    description_es,
    description_fr,
    description_el,
    description_nl,
    description_de,
    short_description_en,
    short_description_cn,
    short_description_it,
    short_description_es,
    short_description_fr,
    short_description_el,
    short_description_nl,
    short_description_de,
    booking_info_en,
    booking_info_cn,
    booking_info_it,
    booking_info_es,
    booking_info_fr,
    booking_info_el,
    booking_info_nl,
    booking_info_de,
  };
};

export const addOnHasDescriptionSelector = createSelector(
  [getAddOnDescriptions],
  (descriptions) => Object.values(descriptions).some((value) => value)
);

const getMeetingPointGroups = (state) => state.customMeetingPointGroups;

export const getMeetingPointGroupSelector = createSelector(
  [getMeetingPointGroups, propsInjector],
  (groups, props) => {
    const { idx } = props;
    return _.get(groups, idx, {});
  }
);

const providersOptions = (state) => state.providersListReducer;

export const addOnProvidersSelector = createSelector(
  [providersOptions],
  (providers) => {
    return providers.results.map((c) => ({
      label: c.legal_title,
      value: c.id,
      detail: c,
    }));
  }
);
