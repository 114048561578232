import {
  AutoSizer,
  List,
  CellMeasurer,
  CellMeasurerCache,
} from "react-virtualized";
import {
  Button,
  Drawer,
  Icon,
  IconButton,
  Loader,
  Radio,
  RadioGroup,
  Rate,
} from "rsuite";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";

import { WhisperHelper } from "@src/components/common/ui_helpers";
import { hotelGeocoding } from "@src/api";
import { createUseStyles } from "react-jss";
import { cardStyles, variables } from "@src/jsssetup";
import { Form, Formik } from "formik";
import { NormalInputField, NormalSelectField } from "@src/components/forms";
import {
  accPrioritiesAdd,
  accPrioritiesChangeBehaviour,
  accPrioritiesOrderChange,
  accPrioritiesRemove,
} from "@src/actions/Project/TripPlanner/accommodation_priorities";
import Fuse from "fuse.js";

const imgGhost =
  "https://sisistaticassets.s3.ap-southeast-1.amazonaws.com/platform_graphics/imgs/others/ghost_destination.png";

const accommodationInfoStyles = createUseStyles({
  ...cardStyles,
  AccommodationInfo: { ...cardStyles.card },
  body: {
    ...cardStyles.body,
    gridTemplateColumns: "10rem 1fr",
    gridGap: variables.half_gap,
  },
  description: {
    height: "7rem",
    overflow: "auto",
  },
  img: {
    width: "100%",
    height: "7rem",
    objectFit: "cover",
    borderRadius: variables.half_gap,
  },
  headerTitle: {
    display: "grid",
    fontWeight: "bold",
  },
  headerAddress: {
    fontWeight: "normal",
  },
});
const AccommodationInfo = ({
  accommodation,
  idx,
  style,
  onAdd,
  onUp,
  onDown,
  onRemove,
}) => {
  const classes = accommodationInfoStyles();

  var img = accommodation.metadata.images.find((img) => img.main);
  if (!img) {
    img = accommodation.metadata.images[0];
  }

  return (
    <div style={style}>
      <div className={classes.AccommodationInfo}>
        <div className={classes.header}>
          <div className={classes.headerTitle}>
            <span>
              {idx}. {accommodation.metadata.name}{" "}
            </span>
            <small className={classes.headerAddress}>
              {accommodation.metadata.address}
            </small>
          </div>
          <Rate
            value={accommodation.metadata.rating}
            size="xs"
            readOnly={true}
          />
        </div>
        <div className={classes.body}>
          <img className={classes.img} src={img.url || imgGhost} />
          <p className={classes.description}>
            {_.get(accommodation, "metadata.description")}
          </p>
        </div>
        <div className={classes.actions}>
          {typeof onAdd === "function" && (
            <IconButton
              icon={<Icon icon="plus" />}
              color="green"
              size="xs"
              onClick={onAdd}>
              Add
            </IconButton>
          )}
          {typeof onUp === "function" && (
            <IconButton
              icon={<Icon icon="angle-up" />}
              size="xs"
              color="blue"
              onClick={onUp}
            />
          )}
          {typeof onDown === "function" && (
            <IconButton
              icon={<Icon icon="angle-down" />}
              size="xs"
              color="blue"
              onClick={onDown}
            />
          )}
          {typeof onRemove === "function" && (
            <IconButton
              icon={<Icon icon="close" />}
              size="xs"
              color="red"
              onClick={onRemove}>
              Remove
            </IconButton>
          )}
        </div>
      </div>
    </div>
  );
};
AccommodationInfo.propTypes = {
  style: PropTypes.object,
  accommodation: PropTypes.object.isRequired,
  idx: PropTypes.number.isRequired,
  onAdd: PropTypes.func,
  onUp: PropTypes.func,
  onDown: PropTypes.func,
  onRemove: PropTypes.func,
};

const cache = new CellMeasurerCache({
  defaultHeight: 150,
  fixedWidth: true,
});

const filterStyles = createUseStyles({
  Filters: {},
  form: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr) repeat(2, max-content)",
    gridGap: variables.normal_gap,
    alignItems: "end",
    background: variables.colors.background.light,
    padding: variables.normal_gap,
  },
});
const Filters = ({ onSubmit }) => {
  const classes = filterStyles();
  return (
    <Formik
      initialValues={{ name: "", rating: null }}
      className={classes.Filters}
      onSubmit={(values) => onSubmit(values)}>
      {({ resetForm }) => (
        <Form className={classes.form}>
          <NormalInputField name="name" label="Name" />
          <NormalSelectField
            name="rating"
            label="Rating"
            options={[
              [null, "------"],
              [1, "1 Star"],
              [2, "2 Stars"],
              [3, "3 Stars"],
              [4, "4 Stars"],
              [5, "5 Stars"],
            ]}
          />
          <Button appearance="primary" onClick={() => resetForm()}>
            <strong>Reset</strong>
          </Button>
          <Button color="green" type="submit">
            <strong>Filter</strong>
          </Button>
        </Form>
      )}
    </Formik>
  );
};
Filters.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

const fuseConfig = {
  shouldSort: false,
  threshold: 0.1,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ["name"],
};

const accPrioritiesDrawerStyles = createUseStyles({
  AccPrioritiesDrawer: {},
  header: {
    "background": variables.colors.background.dark,
    "color": "white",
    "padding": 20,
    "margin": 0,
    "& button": {
      top: "50%!important",
      transform: "translateY(-50%)!important",
    },
  },
  body: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: variables.normal_gap,
    gridTemplateRows: "max-content  1fr",
    height: "90%!important",
    overflow: "hidden!important",
    margin: 0,
  },
  filters: {
    gridColumn: "span 2",
  },
  availableHotels: {
    display: "grid",
    gridTemplateRows: "max-content 1fr",
    gridGap: variables.normal_gap,
    height: "90%",
    padding: variables.normal_gap,
  },
  selectedHotels: {
    display: "grid",
    gridTemplateRows: "max-content 1fr",
    gridGap: variables.normal_gap,
    padding: variables.normal_gap,
    overflow: "hidden",
  },
  selectedList: {
    overflow: "auto",
    display: "grid",
    gridGap: variables.normal_gap,
    gridAutoRows: "max-content",
  },
});
const AccPrioritiesDrawer = ({ destOrder, onHide }) => {
  const [hotels, setHotels] = useState([]);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);

  const { destination, priorities, behaviour } = useSelector((state) => {
    const destinations = state.tripPlannerDestinations;
    const destination = destinations.find((dest) => dest.order == destOrder);

    const priorities = _.get(
      state.tripPlannerAccPriorities,
      `${destOrder}.hotels`,
      []
    );
    const behaviour = _.get(
      state.tripPlannerAccPriorities,
      `${destOrder}.behaviour`,
      "PR"
    );

    return {
      loading,
      filters,
      destination,
      priorities,
      behaviour,
    };
  });

  const dispatch = useDispatch();
  const onAdd = useCallback(
    ({ destOrder, acc }) => dispatch(accPrioritiesAdd({ destOrder, acc })),
    [dispatch]
  );
  const onRemove = useCallback(
    ({ destOrder, accUid }) =>
      dispatch(accPrioritiesRemove({ destOrder, accUid })),
    [dispatch]
  );
  const onOrderChange = useCallback(
    ({ destOrder, currentIdx, newIdx }) =>
      dispatch(accPrioritiesOrderChange({ destOrder, currentIdx, newIdx })),
    [dispatch]
  );
  const onChangeBehaviour = useCallback(
    ({ destOrder, behaviour }) =>
      dispatch(accPrioritiesChangeBehaviour({ destOrder, behaviour })),
    []
  );

  const classes = accPrioritiesDrawerStyles();

  useEffect(() => {
    setLoading(true);
    hotelGeocoding({
      radius: 4000,
      lat: destination.geodata.lat,
      lng: destination.geodata.lng,
      with_metadata: true,
    })
      .then((data) => setHotels(_.get(data, "hotels", [])))
      .finally(() => setLoading(false));
  }, []);

  var validAccs = hotels
    .filter((acc) => acc.metadata.images.length)
    .filter((acc) => !new Set(priorities.map((pr) => pr.uid)).has(acc.uid));
  if (!_.isEmpty(filters)) {
    validAccs = validAccs.filter((acc) => {
      var ratingOk = true;
      if (filters.rating) {
        ratingOk = acc.metadata.rating.toString() === filters.rating.toString();
      }

      return ratingOk;
    });

    if (filters.name) {
      const fuseAccs = new Fuse(
        validAccs.map((a) => ({ uid: a.uid, name: a.metadata.name })),
        fuseConfig
      );

      const fuzzyMatchings = new Set(
        fuseAccs.search(filters.name).map((m) => m.uid)
      );
      validAccs = validAccs.filter((r) => fuzzyMatchings.has(r.uid));
    }
  }

  return (
    <Drawer show={true} onHide={onHide} size="lg">
      <Drawer.Header className={classes.header}>
        <h5>Accommodation Priority</h5>
      </Drawer.Header>
      <Drawer.Body className={classes.body}>
        <div className={classes.filters}>
          <Filters onSubmit={(filters) => setFilters(filters)} />
        </div>
        {loading ? (
          <Loader size="lg" content="Loading..." vertical center={true} />
        ) : (
          <React.Fragment>
            <div className={classes.availableHotels}>
              <div>
                <h6>Accommodations on Destination</h6>
                <span>Showing {validAccs.length} hotels</span>
              </div>
              <AutoSizer>
                {({ height, width }) => (
                  <List
                    height={height}
                    width={width}
                    rowCount={validAccs.length}
                    deferredMeasurementCache={cache}
                    rowHeight={cache.rowHeight}
                    rowRenderer={({ index, key, parent, style }) => {
                      const acc = validAccs[index];
                      return (
                        <CellMeasurer
                          cache={cache}
                          key={key}
                          parent={parent}
                          rowIndex={index}>
                          <AccommodationInfo
                            accommodation={acc}
                            idx={index + 1}
                            style={{ ...style, padding: variables.half_gap }}
                            destOrder={destOrder}
                            onAdd={() => onAdd({ destOrder, acc })}
                          />
                        </CellMeasurer>
                      );
                    }}
                  />
                )}
              </AutoSizer>
            </div>
            <div className={classes.selectedHotels}>
              <div>
                <h6>Accommodation Priority List</h6>
                <div>
                  <span>Search behaviour:</span>
                  <RadioGroup
                    name="radioList"
                    inline
                    value={behaviour}
                    onChange={function (behaviour) {
                      onChangeBehaviour({ destOrder, behaviour });
                    }}>
                    <Radio value="PR">
                      Prefer{" "}
                      <WhisperHelper msg="When in Prefer mode, during the search process hotels in the priority list will be prefered for autoselection. In case none is available the next cheapest hotel will be selected." />
                    </Radio>
                    <Radio value="RE">
                      Restrict{" "}
                      <WhisperHelper msg="When in Restrict mode, during the search process only hotels in the priority list will be autoselected. In case none is available then a no availability message will be displayed." />
                    </Radio>
                  </RadioGroup>
                </div>
              </div>
              <div className={classes.selectedList}>
                {priorities.map((acc, idx) => (
                  <AccommodationInfo
                    key={idx}
                    accommodation={acc}
                    idx={idx + 1}
                    destOrder={destOrder}
                    onUp={
                      idx === 0
                        ? null
                        : () =>
                            onOrderChange({
                              destOrder,
                              currentIdx: idx,
                              newIdx: idx - 1,
                            })
                    }
                    onDown={
                      idx === priorities.length - 1
                        ? null
                        : () =>
                            onOrderChange({
                              destOrder,
                              currentIdx: idx,
                              newIdx: idx + 1,
                            })
                    }
                    onRemove={() => onRemove({ destOrder, accUid: acc.uid })}
                  />
                ))}
              </div>
            </div>
          </React.Fragment>
        )}
      </Drawer.Body>
    </Drawer>
  );
};
AccPrioritiesDrawer.propTypes = {
  destOrder: PropTypes.number,
  onHide: PropTypes.func.isRequired,
};

export default AccPrioritiesDrawer;
