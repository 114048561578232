import { Avatar, IconButton, Icon, Dropdown } from "rsuite";
import SuIcon from "@src/style/icon/components/SuIcon";

import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import {
  changeBaseInfo,
  requestSaveService,
} from "@src/actions/Operation/CustomServices/AddOn";
import TransTxt from "@src/components/common/SxFormatMessage";
import { createUseStyles } from "react-jss";
import { variables } from "@src/jsssetup";
import BasicInfo from "./SetupController/BasicInfo";
import { Form, Formik } from "formik";
import { NormalInputField } from "@src/components/forms";
import { AddonModalsContext } from "../AddOn";
import { fetchB2CWebsitesDetail } from "@src/api";

const controlPanelStyles = createUseStyles({
  panel: {
    display: "grid",
    border: "2px solid white",
    borderRadius: "5px",
    justifyItems: "center",
    padding: variables.normal_gap,
    gridGap: variables.half_gap,
  },
  buttons: { display: "grid", gridGap: variables.normal_gap },
  btn: { "&>a": { width: "100%" } },
});
var ControlPanel = () => {
  const classes = controlPanelStyles();
  const [websiteUrl, setWebsiteUrl] = useState("");
  const { setShowSaveAsModal } = useContext(AddonModalsContext);

  const { reference, websites = [] } = useSelector(
    (state) => state.customServiceAddOn
  );

  const dispatch = useDispatch();
  const onRequestSave = useCallback(
    () => dispatch(requestSaveService()),
    [dispatch]
  );

  useEffect(() => {
    if (!websites.length) {
      return;
    }

    fetchB2CWebsitesDetail(websites[0]).then((data) =>
      setWebsiteUrl(data.production_url)
    );
  }, [websites]);

  return (
    <div className={classes.panel}>
      <h5>CONTROL</h5>
      <div className={classes.buttons}>
        <Dropdown
          appearance="default"
          title="Save"
          icon={<Icon icon="save" />}
          className={classes.btn}>
          <Dropdown.Item onClick={onRequestSave}>
            <TransTxt id="save" startCase />
          </Dropdown.Item>
          <Dropdown.Item onClick={() => setShowSaveAsModal(true)}>
            <TransTxt id="save_as" startCase />
          </Dropdown.Item>
        </Dropdown>
        {websiteUrl && (
          <Dropdown
            appearance="default"
            title="Web Actions"
            icon={<Icon icon="share" />}>
            <Dropdown.Item>
              <a
                href={`${websiteUrl}/tour/MI_${reference}`}
                rel="noreferrer"
                target="_blank">
                View on Website
              </a>
            </Dropdown.Item>
          </Dropdown>
        )}
      </div>
    </div>
  );
};

const iconHeaderStyles = createUseStyles({
  header: {
    display: "none",
    alignContent: "center",
    gridGap: variables.normal_gap,
    justifyItems: "center",
  },
  title: {
    textAlign: "center",
  },
  [`@media ${variables.media.bigscreen}`]: {
    header: {
      display: "grid",
    },
  },
});
export var IconHeader = (props) => {
  const { title, icon } = props;
  const classes = iconHeaderStyles();
  return (
    <div className={classes.header}>
      <Avatar className="top-controller-avatar" circle size="md">
        <SuIcon icon={icon} size="xl" />
      </Avatar>
      <h6 className={classes.title}>{title}</h6>
    </div>
  );
};
IconHeader.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};
IconHeader = memo(IconHeader);

export const ADDON_CATEGORIES = [
  { label: "Sightseeing", value: "sightseeing", role: "Highlights" },
  { label: "Art & Culture", value: "art_and_culture", role: "Highlights" },
  {
    label: "Attractions & Shows",
    value: "attractions_and_shows",
    role: "Highlights",
  },
  {
    label: "Experiences",
    value: "experiences",
    role: "Highlights",
  },
  { label: "Local Food", value: "local_food", role: "Food & Drink" },
  { label: "Western", value: "western", role: "Food & Drink" },
  { label: "Chinese", value: "chinese", role: "Food & Drink" },
  { label: "Italian", value: "italian", role: "Food & Drink" },
  { label: "Mexican", value: "mexican", role: "Food & Drink" },
  { label: "Vegetarian", value: "vegetarian", role: "Food & Drink" },
  { label: "Street Food", value: "street_food", role: "Food & Drink" },
  { label: "Fine Dining", value: "fine_dining", role: "Food & Drink" },
  { label: "Local Brands", value: "local_brands", role: "Shopping" },
  { label: "Flea Market", value: "flea_market", role: "Shopping" },
  { label: "Luxury Brands", value: "luxury_brands", role: "Shopping" },
];

const paxSetupStyles = createUseStyles({
  PaxSetup: {
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateColumns: "1fr",
    alignItems: "center",
  },
  sliders: {
    display: "grid",
    gridGap: variables.normal_gap,
  },
  form: {},
  col2: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: variables.normal_gap,
  },
  [`@media ${variables.media.bigscreen}`]: {
    setup: {
      gridTemplateColumns: "auto 1fr",
    },
  },
});
const PaxSetup = (props) => {
  const { min_max_pax, max_children, min_adults, onChange } = props;
  const classes = paxSetupStyles();

  const customStyles = {
    input: { padding: [0, `calc(${variables.normal_gap} / 2)`] },
    label: { fontSize: "small" },
  };

  return (
    <div className={classes.setup}>
      <Formik
        initialValues={{
          min_adults,
          max_children,
          min_pax: min_max_pax[0],
          max_pax: min_max_pax[1],
        }}
        validate={(values) => {
          const { min_pax, max_pax, min_adults, max_children } = values;
          onChange("min_max_pax", [min_pax, max_pax]);
          onChange("min_adults", min_adults);
          onChange("max_children", max_children);
        }}>
        <Form className={classes.form}>
          <div className={classes.col2}>
            <NormalInputField
              type="number"
              label="Min Pax"
              name="min_pax"
              extraInputProps={{ min: 1 }}
            />
            <NormalInputField
              type="number"
              label="Max Pax"
              name="max_pax"
              extraInputProps={{ min: 1 }}
            />
            <NormalInputField
              type="number"
              label="Min Adults"
              name="min_adults"
              extraInputProps={{ min: 1 }}
            />
            <NormalInputField
              type="number"
              label="Max Children"
              name="max_children"
              extraInputProps={{ min: 0 }}
            />
          </div>
        </Form>
      </Formik>
    </div>
  );
};
PaxSetup.defaultProps = { min_max_pax: [], min_adults: 1, max_children: 0 };
PaxSetup.propTypes = {
  min_max_pax: PropTypes.array,
  min_adults: PropTypes.number,
  max_children: PropTypes.number,
  onChange: PropTypes.func,
};

export const setupControllerStyles = createUseStyles({
  container: {
    position: "relative",
    background: variables.colors.background.base,
    height: (props) => (props.controllerClosed ? "10px" : "auto"),
  },
  openCloseBtn: {
    position: "absolute!important",
    right: `calc(${variables.normal_gap} * 4)`,
    bottom: 0,
    transform: "translateY(50%)",
    zIndex: 1,
  },
});
export const AddonSetupController = () => {
  const [controllerClosed, setControllerClosed] = useState(false);
  const { min_max_pax, min_adults, max_children, currentStep } = useSelector(
    (state) => state.customServiceAddOn
  );

  const dispatch = useDispatch();
  const onChange = useCallback((key, value) =>
    dispatch(changeBaseInfo(key, value))
  );

  useEffect(() => {
    if (currentStep === 1) {
      setControllerClosed(false);
    }
  }, [currentStep]);

  const classes = setupControllerStyles({ currentStep, controllerClosed });

  return (
    <div className={classes.container}>
      <div className="AddonSetupController" data-step={currentStep}>
        <ControlPanel />
        <BasicInfo currentStep={currentStep} />
        {/* TODO: this has to be removed */}
        {1 < currentStep && currentStep < 3 && (
          <PaxSetup
            min_max_pax={min_max_pax}
            max_children={max_children}
            min_adults={min_adults}
            onChange={onChange}
          />
        )}
      </div>
      {currentStep > 1 ? (
        <IconButton
          className={classes.openCloseBtn}
          icon={<Icon icon={controllerClosed ? "angle-down" : "angle-up"} />}
          appearance="primary"
          circle
          onClick={() => setControllerClosed(!controllerClosed)}
        />
      ) : null}
    </div>
  );
};
export default AddonSetupController;
export { ControlPanel };
