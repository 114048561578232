import { createSelector } from "reselect";

import { bankersRounder } from "@src/tools/pricing_tools";

import _ from "lodash";
import { getServicesPricingSelector } from "./pricing";
import { getTripActivitiesListSelector } from "./day_by_day/activities";

const propsInjector = (state, props) => props || {};

// =========================== ACCOMMODATION PREBOOK ==========================

export function getAccPrebookInfo(state, props = {}) {
  const { destOrder } = props;
  if (destOrder) {
    return _.get(state.tripPlannerPrebookAccInfo, destOrder);
  }

  return state.tripPlannerPrebookAccInfo;
}
export const accPrebookDestInfoSelector = createSelector(
  [getAccPrebookInfo, propsInjector],
  (prebookData, props) => {
    const { destOrder } = props;
    return _.get(prebookData, destOrder, {});
  }
);

const isAccPrebookComplete = createSelector(
  [getAccPrebookInfo],
  (prebookData) => {
    return !_.isEmpty(prebookData);
  }
);

export const areAccPrebooksAcceptedSelector = createSelector(
  getAccPrebookInfo,
  (prebook_info) => {
    const accepted = Object.values(prebook_info).map((r) => r.accepted);
    if (!accepted.length) {
      return false;
    }

    return accepted.every((p) => p);
  }
);

// ============================= TRANSFER PREBOOK =============================
const getTrfPrebookInfo = (state) => state.tripPlannerPrebookTrfInfo;

export const areTrfPrebooksAcceptedSelector = createSelector(
  getTrfPrebookInfo,
  (prebook_info) => {
    const accepted =
      prebook_info.length > 0 ? prebook_info.every((p) => p.accepted) : false;
    return accepted;
  }
);

// ============================ ACTIVITIES PREBOOK ============================

export const areActPrebooksAcceptedSelector = createSelector(
  [getTripActivitiesListSelector],
  (activities) => {
    if (!activities.length) {
      return true;
    }

    return activities.every((act) => !_.isEmpty(act.prebookData));
  }
);
// =============================== FLIGHT RULES ===============================
const flightRulesInfo = (state) => state.tripPlannerPrebookFlightRules;

export const areFlightsRulesAccepted = createSelector(
  [flightRulesInfo],
  (rules) => {
    if (_.isEmpty(rules)) {
      return false;
    }

    return Object.entries(rules).every((e) => e[1].accepted);
  }
);

const flightsPrebookInfo = (state) => state.tripPlannerFlightPrebookInfo;
export const isFlightsPrebookCompleteSelector = createSelector(
  [flightsPrebookInfo],
  (prebookData) => {
    if (_.isEmpty(prebookData)) return false;
    return Object.values(prebookData).every((p) => p);
  }
);

// ========================== ALL SERVICES SELECTORS ==========================
export const areAllPrebooksCompleteSelector = createSelector(
  [isAccPrebookComplete],
  (accComplete) => accComplete
);

// ================================= FINANCIAL ================================
export const getRequiredDeposit = (state) => {
  const payable = getServicesPricingSelector(state, {
    booking_mode: "regular",
    payable: true,
  });
  return bankersRounder(payable.buying.amount, 2);
};

export const getRequiredDepositSelector = createSelector(
  [getRequiredDeposit],
  (deposit) => deposit
);
