import {
  ITINERARY_SELECT_ORIGIN_DATE,
  ITINERARY_GLOBAL_DESTINATION_STAY_CHANGE,
  ITINERARY_DESTINATION_MANUAL_DATES_CHANGE,
  ITINERARY_DESTINATION_MANUAL_DATES_RESET,
  ITINERARY_INITIAL_DESTINATION_DATES_CHANGE,
} from "./types";

import { clearAccs, clearTransfers, itnReturnPointArrivalChange } from ".";

import _ from "lodash";
import { resetTripLegs } from "./transportation";
import { resetTripTrains } from "./trains";
import { resetFerrySessionData } from "./ferries";
import { resetFlightSessionData } from "./flights";
import { resetDetailedSrvPricing } from "./pricing";
import { resetDbDCustomSrvs } from "./day_by_day/custom_services";

export const itnDestManualDateChange = ({ destOrder, checkIn, checkOut }) => {
  return {
    type: ITINERARY_DESTINATION_MANUAL_DATES_CHANGE,
    destOrder,
    checkIn,
    checkOut,
  };
};

export const itnDestManualDateReset = ({ destOrder }) => {
  return { type: ITINERARY_DESTINATION_MANUAL_DATES_RESET, destOrder };
};

export const itnInitialDestDateChange = ({ checkIn, checkOut }) => {
  return {
    type: ITINERARY_INITIAL_DESTINATION_DATES_CHANGE,
    checkIn,
    checkOut,
  };
};

export const itnSelectOriginDate = (date) => async (dispatch, getState) => {
  await dispatch(itinerarySelectOriginDate(date));

  const originData = getState().tripPlannerOriginData;
  await dispatch(itineraryGlobalDesStayChange(originData));

  if (!_.isEmpty(getState().tripPlannerReturnData)) {
    const destinations = getState().tripPlannerDestinations;
    dispatch(itnReturnPointArrivalChange(_.last(destinations).checkOut));
  }
};

export const itinerarySelectOriginDate = (date) => {
  return {
    type: ITINERARY_SELECT_ORIGIN_DATE,
    date,
  };
};

export const itineraryGlobalDesStayChange = (originData) => {
  return {
    type: ITINERARY_GLOBAL_DESTINATION_STAY_CHANGE,
    originData,
  };
};

export const stepOneRqs = () => (dispatch) => {
  dispatch(resetTripLegs());
  dispatch(resetTripTrains());
  dispatch(resetFerrySessionData());
  dispatch(resetTripTrains());
  dispatch(resetFlightSessionData());
  dispatch(clearAccs());
  dispatch(clearTransfers());
  dispatch(resetDetailedSrvPricing());
  dispatch(resetDbDCustomSrvs());
};
