import PropTypes from "prop-types";
import React, { useState } from "react";
import Filters from "../ServiceModalFilters";
import ItemsTable from "../ServiceModalItemsTable";

const ItemsList = ({ type, country }) => {
  const [filters, setFilters] = useState({
    external_id: "",
    name: "",
    destination: "",
    pricing: null,
    status: "",
  });

  return (
    <React.Fragment>
      <Filters filters={filters} setFilters={setFilters} />
      <ItemsTable filters={filters} type={type} country={country} />
    </React.Fragment>
  );
};
ItemsList.propTypes = {
  type: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
};

export default ItemsList;
