import _ from "lodash";
import {
  getFerryRoutesByOriginPort,
  getPortsByGeolocation,
} from "@src/api/Project/ferries/index.js";
import { NormalDatePicker, NormalSelectField } from "@src/components/forms";
import { useQuery } from "@tanstack/react-query";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import levenshtein from "fast-levenshtein";
import { distance, point } from "@turf/turf";

export const FerryPreferences = ({ leg, legIdx, mode }) => {
  const [originPortOpts, setOriginPortOpts] = useState([]);
  const [destPortOpts, setDestPortOpts] = useState([]);

  const { originDest, destinationDest } = useSelector((state) => {
    const destinations = state.tripPlannerDestinations ?? [];
    var originDest = null;

    if (leg.origin_order === 0) {
      originDest = state.tripPlannerOriginData?.destination;
    } else {
      originDest = destinations.find((d) => d.order === leg.origin_order);
    }
    var destinationDest = null;
    if (leg.destination_order > _.last(destinations)?.order) {
      destinationDest = state.tripPlannerReturnData?.destination;
    } else {
      destinationDest = destinations.find(
        (d) => d.order === leg.destination_order
      );
    }

    return { originDest, destinationDest };
  });

  const { setFieldValue } = useFormikContext();

  const originPort = leg?.service_preferences?.preferences?.origin_port;
  const destPort = leg?.service_preferences?.preferences?.destination_port;

  const { data: originPortData } = useQuery({
    queryKey: ["ports_by_geolocation", originDest?.name_en],
    queryFn: () =>
      getPortsByGeolocation({
        lat: originDest?.geodata?.lat,
        lng: originDest?.geodata?.lng,
        country_code: "GR",
        radius: 20000,
      }),
    refetchOnWindowFocus: false,
    enabled: !!originDest?.name_en,
  });

  const { data: destPortData } = useQuery({
    queryKey: [
      "port_routes_by_port",
      leg?.service_preferences?.preferences?.origin_port,
    ],
    queryFn: () => {
      if (!originPort) {
        return new Promise((resolve) => resolve([]));
      }
      return getFerryRoutesByOriginPort(originPort);
    },
    refetchOnWindowFocus: false,
  });
  const originPorts = originPortData?.data?.ports ?? [];
  const destPorts = destPortData?.data?.routes ?? [];

  // Populate origin options and preselect the first origin port
  useEffect(() => {
    if (!originPorts.length) return;
    const originPortOptions = originPorts.map((port) => [
      port.code,
      `${port.name} (${port.code})`,
    ]);
    setOriginPortOpts(originPortOptions);

    var closestPort = originPorts[0];
    var closestDistance = 20000;
    const dpoint = point([originDest.geodata.lat, originDest.geodata.lng]);
    originPorts.forEach((p) => {
      const ppoint = point([p.latitude, p.longitude]);
      const geodist = parseFloat(distance(dpoint, ppoint).toFixed(1));
      if (geodist < closestDistance) {
        closestPort = p;
        closestDistance = geodist;
      }
    });

    //Preselect the first port which is also the nearest to our destination
    setFieldValue(
      `legs.${legIdx}.service_preferences.preferences.origin_port`,
      closestPort.code
    );
  }, [originPorts]);

  // Get combatible routes based on the selected origin and populate
  // destination ports.
  useEffect(() => {
    if (!destPorts.length) return;

    const destPortsOptions = _.sortBy(
      destPorts,
      (v) => v.destination_port.name_en
    ).map((route) => {
      const destPort = route.destination_port;
      return [destPort.code, `${destPort.name_en} (${destPort.code})`];
    });

    var bestCode = null;
    var bestCodeScore = 1000;
    const dpoint = point([
      destinationDest.geodata.lat,
      destinationDest.geodata.lng,
    ]);
    destPorts
      .filter((p) => {
        const dPort = p.destination_port;
        const ppoint = point([dPort.latitude, dPort.longitude]);
        const geodist = parseFloat(distance(dpoint, ppoint).toFixed(1));
        return geodist < 20;
      })
      .forEach((p, idx) => {
        const dPort = p.destination_port;
        const score = levenshtein.get(dPort.name_en, destinationDest.name_en);
        if (idx === 0) {
          bestCode = dPort.code;
          bestCodeScore = score;
          return;
        }

        if (score < bestCodeScore) {
          bestCode = dPort.code;
          bestCodeScore = score;
        }
      });

    if (bestCode) {
      setFieldValue(
        `legs.${legIdx}.service_preferences.preferences.destination_port`,
        bestCode
      );
    }
    setDestPortOpts(destPortsOptions);
  }, [destPorts]);

  return (
    <div className="TransportationLegs__leg__ferry-preferences">
      {mode === "edit" && (
        <React.Fragment>
          <NormalDatePicker
            name={`legs.${legIdx}.service_preferences.preferences.departure`}
            label="Departure Time"
            withTime={true}
            withTimeOnly={true}
          />
          <NormalSelectField
            name={`legs.${legIdx}.service_preferences.preferences.origin_port`}
            label="Departing Port"
            options={[["", "------"], ...originPortOpts]}
          />
          <NormalSelectField
            name={`legs.${legIdx}.service_preferences.preferences.destination_port`}
            label="Arriving Port"
            options={[["", "------"], ...destPortOpts]}
          />
        </React.Fragment>
      )}
      {mode === "view" && (
        <React.Fragment>
          <span className="TransportationLegs__view-attribute">
            <strong>Departing Station</strong>
            {originPort}
          </span>
          <span className="TransportationLegs__view-attribute">
            <strong>Arriving Station</strong>
            {destPortOpts.find((c) => c[0] === destPort)?.[1] ?? "N/A"}
          </span>
        </React.Fragment>
      )}
    </div>
  );
};
FerryPreferences.propTypes = {
  leg: PropTypes.object.isRequired,
  legIdx: PropTypes.number.isRequired,
  mode: PropTypes.string.isRequired,
};
