const validateMeta = (meta) => {
  const msgs = [];
  if (meta.name == "") {
    msgs.push(["Hotel Name", "Please add a name."]);
  }

  if (Object.values(meta.geodata).reduce((a, b) => a + b, 0) == 0) {
    msgs.push([
      "Hotel location",
      `Please use the "Geolocate" button to get the hotel location.`,
    ]);
  }

  if (!meta.facilities.length) {
    msgs.push(["Facilities", "Please select facilities."]);
  }

  if (meta.images.every((i) => i.url == "")) {
    msgs.push(["Images", "At least one image must be supplied."]);
  }

  return msgs;
};

export const validateRooms = (rooms) => {
  const msgs = [];

  rooms.forEach((r, idx) => {
    if (r.name == "") {
      msgs.push([`Room ${idx + 1}`, `Missing name.`]);
    }

    if (r.board_code == "") {
      msgs.push([`Room ${idx + 1}`, `Select a board.`]);
    }

    if (r.bedding == "") {
      msgs.push([`Room ${idx + 1}`, `Select a bedding.`]);
    }

    if (r.type == "") {
      msgs.push([`Room ${idx + 1}`, `Select a room type.`]);
    }
  });

  return msgs;
};

export const validateData = (data) => {
  var msgs = [];

  msgs = [
    ...msgs,
    ...validateMeta(data.metadata),
    ...validateRooms(data.rooms),
  ];
  return msgs;
};
