//======================filter=================================
// export const MY_OFFER__SENT_UPDATE_LIST_FILTER_FORM =
//   "MY_OFFER__SENT_UPDATE_LIST_FILTER_FORM";
// export const MY_OFFER__SENT_APPLY_LIST_FILTER =
//   "MY_OFFER__SENT_APPLY_LIST_FILTER";
export const MY_OFFER__SENT_INITIALIZE_LIST_FILTER_FORM =
  "MY_OFFER__SENT_INITIALIZE_LIST_FILTER_FORM";
// export const MY_OFFER__SENT_RESET_LIST_FILTER_FORM =
//   "MY_OFFER__SENT_RESET_LIST_FILTER_FORM";
//==========================LIST=================================
export const MY_OFFER__SENT_BEGIN_FETCH_DATA =
  "MY_OFFER__SENT_BEGIN_FETCH_DATA";
export const MY_OFFER__SENT_END_FETCH_DATA = "MY_OFFER__SENT_END_FETCH_DATA";
export const MY_OFFER__SENT_FETCH_DATA = "MY_OFFER__SENT_FETCH_DATA";
export const MY_OFFER__SENT_LIST_LOADING = "MY_OFFER__SENT_LIST_LOADING";
export const MY_OFFER__SENT_LIST_IDLE = "MY_OFFER__SENT_LIST_IDLE";

//========================DASHBOARD===============================
export const MY_OFFER__SENT_VIEW_TYPE = "MY_OFFER__SENT_VIEW_TYPE";
export const MY_OFFER__SENT_FETCH_GRAPH_DATA =
  "MY_OFFER__SENT_FETCH_GRAPH_DATA";
export const MY_OFFER__SENT_BEGIN_FETCH_GRAPH_DATA =
  "MY_OFFER__SENT_BEGIN_FETCH_GRAPH_DATA";
export const MY_OFFER__SENT_END_FETCH_GRAPH_DATA =
  "MY_OFFER__SENT_END_FETCH_GRAPH_DATA";
export const MY_OFFER__SENT_INIT_GRAPH_TYPE = "MY_OFFER__SENT_INIT_GRAPH_TYPE";

//========================CHAT==============================,
export const MY_OFFER__SENT_OPEN_CHAT_DRAWER =
  "MY_OFFER__SENT_OPEN_CHAT_DRAWER";
export const MY_OFFER__SENT_HIDE_CHAT_DRAWER =
  "MY_OFFER__SENT_HIDE_CHAT_DRAWER";
export const MY_OFFERS_SENT_DATA_DETAIL = "MY_OFFERS_SENT_DATA_DETAIL";
export const MY_OFFER__SENT_SHOW_CHAT_DRAWER =
  "MY_OFFER__SENT_SHOW_CHAT_DRAWER";
//=====================================RESENT EMAIL=====================
export const MY_OFFER__SENT_SHOW_RESENT_EMAIL_MODAL =
  "MY_OFFER__SENT_SHOW_RESENT_EMAIL_MODAL";
export const MY_OFFER__SENT_HIDE_RESENT_EMAIL_MODAL =
  "MY_OFFER__SENT_HIDE_RESENT_EMAIL_MODAL";
export const MY_OFFER__SENT_BEGIN_SEND_EMAIL =
  "MY_OFFER__SENT_BEGIN_SEND_EMAIL";
export const MY_OFFER__SENT_END_SEND_EMAIL = "MY_OFFER__SENT_END_SEND_EMAIL";

export const CHANGE_ARCHIVE_VIEW_MODEL = "CHANGE_ARCHIVE_VIEW_MODEL";
export const TOGGLE_ARCHIVE_MAP_VIEW = "TOGGLE_ARCHIVE_MAP_VIEW";
export const MY_OFFER__SENT__RESENT_EMAIL_MODAL_DATA =
  "MY_OFFER__SENT__RESENT_EMAIL_MODAL_DATA";

//==================change status==================
export const MY_OFFER__SENT_SHOW_STATUS_MODAL =
  "MY_OFFER__SENT_SHOW_STATUS_MODAL";
export const MY_OFFER__SENT_HIDE_STATUS_MODAL =
  "MY_OFFER__SENT_HIDE_STATUS_MODAL";
export const MY_OFFER__SENT_EDIT_STATUS = "MY_OFFER__SENT_EDIT_STATUS";
export const MY_OFFER__SENT_BEGIN_SUBMIT_STATUS =
  "MY_OFFER__SENT_BEGIN_SUBMIT_STATUS";
export const MY_OFFER__SENT_END_SUBMIT_STATUS =
  "MY_OFFER__SENT_END_SUBMIT_STATUS";
//================New Refactored==================
export const MY_OFFER__SENT_LIST_GENERIC_CHANGE =
  "MY_OFFER__SENT_LIST_GENERIC_CHANGE";
export const MY_OFFER__SENT_LIST_SAVE_RESULTS =
  "MY_OFFER__SENT_LIST_SAVE_RESULTS";
