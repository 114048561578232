import { GlobalModalContext } from "@src/App";
import { CustomButton } from "@src/components/common/buttons";
import { ListPageHeader } from "@src/components/common/lists";
import { NormalInputField, NormalSelectField } from "@src/components/forms";
import {
  formStyles,
  modalGenericStyles,
  tableStyles,
  variables,
} from "@src/jsssetup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Form, Formik, useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { createUseStyles } from "react-jss";
import * as yup from "yup";
import axios from "axios";
import { SISI2_API_URL } from "@src/api";
import { getAuthHeaders } from "@src/api/request";
import { toast } from "react-toastify";
import { getUserSourceEntitySelector } from "@src/selectors/Shared/user_selectors";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";
import { Loader } from "rsuite";
import TransTxt from "@src/components/common/SxFormatMessage";
import { injectIntl } from "react-intl";
import {
  withAppUserGroup,
  withAppUserPermission,
} from "@src/components/authorization/permissionhocs";

async function createInvoiceSetup({ payload }) {
  const headers = getAuthHeaders();

  return await axios.post(
    `${SISI2_API_URL}/paymentengine/invoice/defaults-setup`,
    payload,
    { headers }
  );
}

export async function fetchInvoiceSetup({ source_entity, status = null }) {
  const headers = getAuthHeaders();

  return await axios.get(
    `${SISI2_API_URL}/paymentengine/invoice/defaults-setup`,
    { params: { source_entity, status }, headers }
  );
}

export async function retrieveInvoiceSetup({ id, source_entity }) {
  const headers = getAuthHeaders();

  return await axios.get(
    `${SISI2_API_URL}/paymentengine/invoice/defaults-setup/${id}`,
    { headers, params: { source_entity } }
  );
}

export async function patchInvoiceSetup({ id, source_entity, payload }) {
  const headers = getAuthHeaders();

  return await axios.patch(
    `${SISI2_API_URL}/paymentengine/invoice/defaults-setup/${id}`,
    payload,
    { headers, params: { source_entity } }
  );
}

export async function deleteInvoiceSetup({ id, source_entity }) {
  const headers = getAuthHeaders();
  return await axios.delete(
    `${SISI2_API_URL}/paymentengine/invoice/defaults-setup/${id}`,
    { headers, params: { source_entity } }
  );
}

const AddButton = withAppUserPermission(
  (props) => (
    <button className="Button" data-success="true" {...props}>
      {props.children}
    </button>
  ),
  ["ADMIN"],
  { edit: true }
);

const EditButton = withAppUserPermission(
  (props) => (
    <button className="Button" data-success="true" {...props}>
      {props.children}
    </button>
  ),
  ["ADMIN"],
  { edit: true }
);

const DeleteButton = withAppUserPermission(
  (props) => (
    <button className="Button" data-danger="true" {...props}>
      {props.children}
    </button>
  ),
  ["ADMIN"],
  { edit: true }
);

const validationSchema = yup.object().shape({
  default_memo: yup.string(),
  default_footer_description: yup.string(),
  email_message: yup.string(),
  subject: yup.string(),
  external_reference_label: yup.string(),
  product_reference_label: yup.string(),
  status: yup
    .string()
    .oneOf(["IN", "AC"], "Invalid status")
    .required("Status is required"),
});

const SubmitBtn = ({ id }) => {
  const { submitForm } = useFormikContext();
  return (
    <CustomButton id={id} onClick={submitForm}>
      <TransTxt id="InvoiceStpAddEditMdl__submit" />
    </CustomButton>
  );
};
SubmitBtn.defaultProps = { id: "" };
SubmitBtn.propTypes = { id: PropTypes.string };

const deleteModalStyles = createUseStyles({
  ...modalGenericStyles,
  DeleteModal: { ...modalGenericStyles.modal },
  card: { ...modalGenericStyles.card, minWidth: "auto", position: "relative" },
  cardBody: {
    ...modalGenericStyles.cardBody,
    minWidth: "auto",
    minHeight: "auto",
  },
  cardHeader: {
    ...modalGenericStyles.cardHeader,
    gridGap: variables.normal_gap,
    gridTemplateColumns: "auto 1fr",
    alignItems: "center",
  },
  cardActions: { ...modalGenericStyles.cardActions },
});
export const InvoiceSetupDeleteModal = ({ id, onHide }) => {
  const classes = deleteModalStyles({});

  const source_entity = useSelector((state) =>
    getUserSourceEntitySelector(state)
  );

  const queryClient = useQueryClient();
  const { mutate: deleteMutation, isLoading: deleteLoading } = useMutation({
    mutationFn: () => deleteInvoiceSetup({ id, source_entity }),
    onSuccess: () => {
      toast.success(
        <TransTxt id="InvoiceStpDltMdl__deleted_successfully_msg" />,
        { autoClose: 6000 }
      );
      queryClient.invalidateQueries(["invoiceSetup", source_entity]);
      onHide();
    },
    onError: (error) => {
      console.log(error);
      toast.error(
        error?.response?.data?.message || (
          <TransTxt id="InvoiceStpDltMdl__unable_to_delete_msg" />
        ),
        { autoClose: 6000 }
      );
    },
  });

  return (
    <div className={classes.DeleteModal}>
      <div className={classes.card}>
        {deleteLoading && <Loader backdrop size="lg" center />}
        <div className={classes.cardHeader}>
          <h5>
            <TransTxt id="InvoiceStpDltMdl__delete_the_invoice" />
          </h5>
        </div>
        <div className={classes.cardBody}>
          <p>
            <TransTxt id="InvoiceStpDltMdl__body_message" />
          </p>
        </div>
        <div className={classes.cardActions}>
          <CustomButton appearance="ghost" onClick={onHide}>
            <TransTxt id="InvoiceStpDltMdl__cancel" />
          </CustomButton>
          <CustomButton
            id="confirm_delete"
            appearance="primary"
            onClick={() => deleteMutation()}>
            <TransTxt id="InvoiceStpDltMdl__yes" />
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
InvoiceSetupDeleteModal.propTypes = {
  id: PropTypes.number.isRequired,
  onHide: PropTypes.func.isRequired,
};

const addEditModalStyles = createUseStyles({
  ...modalGenericStyles,
  AddEditModal: { ...modalGenericStyles.modal },
  card: { ...modalGenericStyles.card, minWidth: "auto", position: "relative" },
  cardBody: {
    ...modalGenericStyles.cardBody,
    minWidth: "auto",
    minHeight: "auto",
  },
  form: { ...formStyles.form },
  col2: {
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateColumns: "repeat(2, 1fr)",
  },
});
export var InvoiceSetupAddEditModal = ({ id, onHide, intl }) => {
  const classes = addEditModalStyles({});

  const mode = id ? "edit" : "add";

  const source_entity = useSelector((state) =>
    getUserSourceEntitySelector(state)
  );

  const { data: invoiceSetup, isLoading: invoiceSetupLoading } = useQuery({
    queryKey: ["invoiceSetup", id],
    queryFn: () => retrieveInvoiceSetup({ id, source_entity }),
    enabled: mode === "edit",
  });

  const queryClient = useQueryClient();
  const { mutate: createMutation, isLoading: createLoading } = useMutation({
    mutationFn: (payload) =>
      createInvoiceSetup({ payload: { ...payload, source_entity } }),
    onSuccess: () => {
      toast.success(
        <TransTxt id="InvoiceStpAddEditMdl__created_successfully_msg" />,
        { autoClose: 6000 }
      );
      queryClient.invalidateQueries(["invoiceSetup", source_entity]);
      onHide();
    },
    onError: (error) => {
      toast.error(
        error?.response?.data?.message || (
          <TransTxt id="InvoiceStpAddEditMdl__unable_to_create_msg" />
        ),
        { autoClose: 6000 }
      );
    },
  });

  const { mutate: editMutation, isLoading: editLoading } = useMutation({
    mutationFn: (payload) =>
      patchInvoiceSetup({ id, payload: { ...payload }, source_entity }),
    onSuccess: () => {
      toast.success(
        <TransTxt id="InvoiceStpAddEditMdl__edited_successfully_msg" />,
        { autoClose: 6000 }
      );
      queryClient.invalidateQueries(["invoiceSetup", source_entity]);
      onHide();
    },
    onError: (error) => {
      console.log(error);
      toast.error(
        error?.response?.data?.message || (
          <TransTxt id="InvoiceStpAddEditMdl__unable_to_edit_msg" />
        ),
        { autoClose: 6000 }
      );
    },
  });

  const loading =
    createLoading || editLoading || (invoiceSetupLoading && mode === "edit");

  return (
    <div className={classes.AddEditModal}>
      <Formik
        enableReinitialize
        initialValues={{
          default_memo: invoiceSetup?.data?.default_memo ?? "",
          default_footer_description:
            invoiceSetup?.data?.default_footer_description ?? "",
          status: invoiceSetup?.data?.status ?? "IN",
          subject: invoiceSetup?.data?.subject ?? "",
          sender_email: invoiceSetup?.data?.sender_email ?? "",
          email_message: invoiceSetup?.data?.email_message ?? "",
          external_reference_label:
            invoiceSetup?.data?.external_reference_label ?? "",
          product_reference_label:
            invoiceSetup?.data?.product_reference_label ?? "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values) =>
          mode === "edit" ? editMutation(values) : createMutation(values)
        }>
        <div className={classes.card}>
          {loading && <Loader backdrop size="lg" center />}
          <div className={classes.cardHeader}>
            <h5>
              <TransTxt id="InvoiceStpAddEditMdl__invoice_setup" />
            </h5>
          </div>
          <div className={classes.cardBody}>
            <Form className={classes.form}>
              <NormalInputField
                id="sender_email"
                name="sender_email"
                label="Sender Email"
              />
              <NormalInputField
                id="subject"
                name="subject"
                label={intl.formatMessage({
                  id: "InvoiceStpAddEditMdl__subject",
                })}
              />
              <NormalInputField
                id="email_message"
                name="email_message"
                label={intl.formatMessage({
                  id: "InvoiceStpAddEditMdl__email_message",
                })}
                as="textarea"
                extraInputProps={{ rows: 5 }}
              />
              <div className={classes.col2}>
                <NormalInputField
                  id="default_memo"
                  name="default_memo"
                  label={intl.formatMessage({
                    id: "InvoiceStpAddEditMdl__memo",
                  })}
                  as="textarea"
                  extraInputProps={{ rows: 5 }}
                />
                <NormalInputField
                  id="default_footer_description"
                  name="default_footer_description"
                  label={intl.formatMessage({
                    id: "InvoiceStpAddEditMdl__footer",
                  })}
                  as="textarea"
                  extraInputProps={{ rows: 5 }}
                />
                <NormalInputField
                  id="product_reference_label"
                  name="product_reference_label"
                  label={intl.formatMessage({
                    id: "InvoiceStpAddEditMdl__product_reference_label",
                  })}
                />
                <NormalInputField
                  id="external_reference_label"
                  name="external_reference_label"
                  label={intl.formatMessage({
                    id: "InvoiceStpAddEditMdl__external_reference_label",
                  })}
                />
              </div>
              <NormalSelectField
                id="status"
                name="status"
                label={intl.formatMessage({
                  id: "InvoiceStpAddEditMdl__status",
                })}
                options={[
                  [
                    "AC",
                    intl.formatMessage({ id: "InvoiceStpAddEditMdl__active" }),
                  ],
                  [
                    "IN",
                    intl.formatMessage({
                      id: "InvoiceStpAddEditMdl__inactive",
                    }),
                  ],
                ]}
              />
            </Form>
          </div>
          <div className={classes.cardActions}>
            <CustomButton appearance="ghost" onClick={onHide}>
              <TransTxt id="InvoiceStpAddEditMdl__close" />
            </CustomButton>
            <SubmitBtn id="submit_btn" />
          </div>
        </div>
      </Formik>
    </div>
  );
};
InvoiceSetupAddEditModal.defaultProps = { id: null };
InvoiceSetupAddEditModal.propTypes = {
  id: PropTypes.number,
  intl: PropTypes.object.isRequired,
  invoiceInitialSetup: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
};
InvoiceSetupAddEditModal = injectIntl(InvoiceSetupAddEditModal);

const invoiceSetupListStyles = createUseStyles({
  InvoiceSetupList: {
    overflow: "hidden",
    display: "grid",
    gridTemplateRows: "auto 1fr",
    height: "100%",
  },
  tableContainer: { overflow: "auto" },
  table: tableStyles.table,
  cell: tableStyles.cell,
  thead: tableStyles.head,
  actionCell: {
    ...tableStyles.cell,
    display: "grid",
    gridAutoFlow: "column",
    gridGap: variables.half_gap,
    gridAutoColumns: "max-content",
  },
});
const InvoiceSetupList = () => {
  const { setId, setShowInvoiceSetupModal, setShowInvoiceDeleteModal } =
    useContext(GlobalModalContext);

  const classes = invoiceSetupListStyles({});
  const source_entity = useSelector((state) =>
    getUserSourceEntitySelector(state)
  );
  const { data, isLoading } = useQuery({
    queryKey: ["invoiceSetup", source_entity],
    queryFn: () => fetchInvoiceSetup({ source_entity }),
  });

  return (
    <div>
      <ListPageHeader
        title={
          <span>
            <TransTxt id="InvoiceStpList__invoice_setup" />
          </span>
        }>
        <AddButton
          id="add_invoice"
          onClick={() => setShowInvoiceSetupModal(true)}>
          <TransTxt id="InvoiceStpList__add_invoice_setup" />
        </AddButton>
      </ListPageHeader>
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead className={classes.thead}>
            <tr>
              <th>#</th>
              <th>Sender Email</th>
              <th>
                <TransTxt id="InvoiceStpList__email_subject" />
              </th>
              <th>
                <TransTxt id="InvoiceStpList__email_message" />
              </th>
              <th>
                <TransTxt id="InvoiceStpList__product_reference_label" />
              </th>
              <th>
                <TransTxt id="InvoiceStpList__external_reference_label" />
              </th>
              <th>
                <TransTxt id="InvoiceStpList__memo" />
              </th>
              <th>
                <TransTxt id="InvoiceStpList__footer" />
              </th>
              <th>
                <TransTxt id="InvoiceStpList__last_edited" />
              </th>
              <th>
                <TransTxt id="InvoiceStpList__created" />
              </th>
              <th>
                <TransTxt id="InvoiceStpList__status" />
              </th>
              <th>
                <TransTxt id="InvoiceStpList__actions" />
              </th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <Loader size="lg" center />
            ) : (
              (data?.data?.results || []).map((invoiceSetup, index) => (
                <tr key={invoiceSetup.id}>
                  <td className={classes.cell}>{index + 1}</td>
                  <td className={classes.cell}>
                    {invoiceSetup.sender_email || "N/A"}
                  </td>
                  <td className={classes.cell}>
                    {invoiceSetup.subject || "N/A"}
                  </td>
                  <td className={classes.cell}>
                    {invoiceSetup.email_message || "N/A"}
                  </td>
                  <td className={classes.cell}>
                    {invoiceSetup.product_reference_label || "N/A"}
                  </td>
                  <td className={classes.cell}>
                    {invoiceSetup.external_reference_label || "N/A"}
                  </td>
                  <td className={classes.cell}>
                    {invoiceSetup.default_memo || "N/A"}
                  </td>
                  <td className={classes.cell}>
                    {invoiceSetup.default_footer_description || "N/A"}
                  </td>
                  <td className={classes.cell}>
                    {DateTime.fromISO(invoiceSetup.last_edited).toLocaleString(
                      DateTime.DATETIME_MED_WITH_WEEKDAY
                    )}
                  </td>
                  <td className={classes.cell}>
                    {DateTime.fromISO(invoiceSetup.created).toLocaleString(
                      DateTime.DATETIME_MED_WITH_WEEKDAY
                    )}
                  </td>
                  <td className={classes.cell}>
                    {invoiceSetup.status_display}
                  </td>
                  <td className={classes.actionCell}>
                    <DeleteButton
                      id={`delete__invoice__${index}`}
                      onClick={() => {
                        setId(invoiceSetup.id);
                        setShowInvoiceDeleteModal(true);
                      }}>
                      <TransTxt id="InvoiceStpList__delete" />
                    </DeleteButton>
                    <EditButton
                      onClick={() => {
                        setId(invoiceSetup.id);
                        setShowInvoiceSetupModal(true);
                      }}>
                      <TransTxt id="InvoiceStpList__edit" />
                    </EditButton>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default withAppUserGroup(InvoiceSetupList, ["ADMIN"], { view: true });
