import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { getTripDaysSelector } from "@src/selectors/Project/TripPlanner/day_by_day_selectors";
import { formatDate } from "@src/tools/date_tools";
import _ from "lodash";
import { Icon, IconButton, Tag, Placeholder } from "rsuite";
import {
  requestAddService,
  removeAddOn,
  viewAddOnDetails,
} from "@src/actions/Project/TripPlanner/day_by_day_actions";
import SuIcon from "@src/style/icon/components/SuIcon";
import { addon_icon_mapping } from "@src/config/service";
import { viewTrfDetails } from "@src/actions/Project/TripPlanner";
import { TripModeHoc } from "../Hydrators";
import {
  DayAccommodation,
  DayAddons,
  DayCustomSrvs,
  DayAdhocSrvs,
  DayFlight,
  DayTransfer,
  DayTrain,
  DayFerry,
  DayCoach,
  DayAPIFerry,
  DayAPITrain,
} from "../Components/Day";
import { createUseStyles } from "react-jss";
import { variables } from "@src/jsssetup";
import LeanAuthHoc from "@src/components/authorization/LeanAuthHoc";
import { TripPlanContext } from "../TripPlanner";
import DayActivities from "../Components/Day/activities";
import { InlineIcon } from "@iconify/react";
import { handleNonValidImgFn } from "@src/tools/common_tools";

const customIconBtnStyles = {
  background: variables.colors.easy.orange,
  color: variables.colors.text.light,
  fontWeight: "600",
};

const iconsStyles = {
  display: "grid",
  placeContent: "center",
  width: "2rem",
  height: "2rem",
  border: `1px solid ${variables.colors.easy.orange}`,
  borderRadius: "50%",
  padding: variables.half_gap,
  background: variables.colors.easy.orange,
  color: "white",
};

const AddonGhost = () => {
  return (
    <React.Fragment>
      <div className="ServicePanel__image">
        <Placeholder.Paragraph graph="square" />
      </div>
      <div className="ServicePanel__content">
        <div className="ServicePanel__content__top-section--addon">
          <Placeholder.Paragraph rows={3} active />
        </div>
      </div>
    </React.Fragment>
  );
};

const addonContentStyles = createUseStyles({
  header: (props) => ({
    display: "grid",
    gridTemplateColumns: "1fr max-content",
    alignItems: "center",
    gridGap: variables.half_gap,
    padding: [
      variables.half_gap,
      variables.half_gap,
      variables.half_gap,
      variables.double_gap,
    ],
    boxShadow: props.services_collapsed && variables.shadows.normalShadow,
    borderTopLeftRadius: variables.half_gap,
    borderTopRightRadius: variables.half_gap,
    borderBottomLeftRadius: props.services_collapsed ? variables.half_gap : 0,
    borderBottomRightRadius: props.services_collapsed ? variables.half_gap : 0,
    color: variables.colors.text.dark,
    background: variables.colors.background.light,
  }),
  cardContent: {
    "display": "grid",
    "gridGap": variables.half_gap,
    "boxShadow": variables.shadows.heavyShadow,
    "borderRadius": `calc(${variables.normal_gap} / 2)`,
    "textTransform": "capitalize",
    "& > :not(:first-child)": { padding: variables.normal_gap },
  },
  img: {
    width: "100%",
    height: "100%",
    borderRadius: "5px",
    objectFit: "cover",
    boxShadow: variables.shadows.heavyShadow,
  },
  data: {
    display: "grid",
    alignItems: "start",
    justifyContent: "start",
    gridAutoFlow: "column",
    gridGap: `calc(${variables.normal_gap} * 2.5)`,
  },
  actionBtns: {
    display: "grid",
    gridAutoFlow: "column",
    gridGap: variables.half_gap,
    placeItems: "center",
  },
  expandIconCntr: {
    display: "grid",
    placeContent: "center",
    width: "1.5rem",
    height: "1.5rem",
    borderRadius: "50%",
    background: variables.colors.easy.orange,
    padding: variables.half_gap,
    cursor: "pointer",
  },
  expandIcon: { fontSize: "large", color: variables.colors.text.light },
  addonsCntr: {
    "display": "grid",
    "gridTemplateColumns": "1fr",
    "placeItems": "center",
    "position": "relative",
    "width": "8rem",
    "&:not(:last-child):after": {
      content: '""',
      position: "absolute",
      top: "1rem",
      left: "5rem",
      width: "100%",
      height: "4px",
      background: variables.colors.easy.orange,
    },
  },
  iconCntr: { display: "grid", justifyContent: "center", position: "relative" },
  addonDescr: { fontWeight: "600", display: "grid", justifyItems: "center" },
  [`@media ${variables.media.bigscreen}`]: {},
});
export const AddOnContent = ({
  addon,
  status,
  actions,
  onViewAll,
  service,
  services_collapsed,
  toggleSelfCollapse,
}) => {
  const classes = addonContentStyles({ services_collapsed });

  const nonCustomImgs = service?.items?.[0]?.data?.image_set ?? [];
  const customImgs = service?.items?.[0]?.data?.custom_images ?? [];
  const imgs = [...customImgs, ...nonCustomImgs].filter(
    (img) => img.big_thumbnail_url
  );

  const headerContent = (
    <div className={classes.header}>
      <h6>{addon.service.title}</h6>
      <div className={classes.actionBtns}>
        {status == "unavailable" ? (
          <Tag color="red">
            <strong>Unavailable</strong>
          </Tag>
        ) : null}
        {actions}
        {onViewAll ? (
          <IconButton
            style={customIconBtnStyles}
            size="xs"
            icon={<Icon icon="info" style={customIconBtnStyles} />}
            onClick={onViewAll}>
            View All
          </IconButton>
        ) : null}
        <div className={classes.expandIconCntr}>
          <InlineIcon
            className={classes.expandIcon}
            icon={
              services_collapsed ? "ri:arrow-down-s-line" : "ri:arrow-up-s-line"
            }
            onClick={toggleSelfCollapse}
          />
        </div>
      </div>
    </div>
  );

  return status == "searching" ? (
    <AddonGhost />
  ) : services_collapsed ? (
    headerContent
  ) : (
    <React.Fragment>
      <img
        className={classes.img}
        alt=""
        src={imgs?.[0]?.big_thumbnail_url}
        onError={handleNonValidImgFn}
      />
      <div className={classes.cardContent}>
        {headerContent}
        <div className={classes.data}>
          {(service?.items ?? []).map((item, idx) => (
            <div key={idx} className={classes.addonsCntr}>
              <div className={classes.iconCntr}>
                <SuIcon
                  style={iconsStyles}
                  icon={addon_icon_mapping[item.type]}
                />
              </div>
              <div className={classes.addonDescr}>
                {<div>{item.data.name_en}</div>}
              </div>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};
AddOnContent.propTypes = {
  service: PropTypes.object.isRequired,
  status: PropTypes.oneOf(["unavailable", "searching", "idle"]),
  actions: PropTypes.object,
  onViewAll: PropTypes.func,
  services_collapsed: PropTypes.bool,
  toggleSelfCollapse: PropTypes.func,
  addon: PropTypes.object,
};

const addonStyles = createUseStyles({
  AddOn: (props) => ({
    display: "grid",
    gridTemplateColumns: props.services_collapsed ? "1fr" : "8rem 1fr",
    gridTemplateRows: props.services_collapsed ? "unset" : "10rem",
    gridGap: variables.normal_gap,
    maxHeight: "10rem",
  }),
});
export const AddOn = ({
  addon,
  status,
  actions,
  onViewAll,
  services_collapsed,
  toggleSelfCollapse,
}) => {
  const classes = addonStyles({ services_collapsed });

  return (
    <div className={classes.AddOn}>
      <AddOnContent
        service={addon.service}
        status={status}
        addon={addon}
        actions={actions}
        services_collapsed={services_collapsed}
        toggleSelfCollapse={toggleSelfCollapse}
        onViewAll={onViewAll}
      />
    </div>
  );
};
AddOn.propTypes = {
  actions: PropTypes.object,
  addon: PropTypes.object.isRequired,
  status: PropTypes.oneOf(["unavailable", "searching", "idle"]),
  onViewAll: PropTypes.func,
  services_collapsed: PropTypes.bool.isRequired,
  toggleSelfCollapse: PropTypes.func,
};

const AddCustomSrvBtn = ({ onAddCustomService }) => {
  return (
    <button
      className="Button"
      data-success={true}
      data-size="xs"
      onClick={onAddCustomService}>
      Add Custom Service
    </button>
  );
};
AddCustomSrvBtn.propTypes = { onAddCustomService: PropTypes.func.isRequired };

const AddAdhocSrvBtn = ({ day, commonDestDays, onAddAddhocService }) => {
  return (
    <button
      className="Button"
      data-success={true}
      data-size="xs"
      onClick={() =>
        onAddAddhocService({
          dayIdx: day.day,
          date: day.date,
          destination_ids: day.destinations.map(
            (dest) => `${dest.type}__${dest.id}`
          ),
          commonDestDays,
        })
      }>
      Add Ad hoc Service
    </button>
  );
};
AddAdhocSrvBtn.propTypes = {
  day: PropTypes.object.isRequired,
  commonDestDays: PropTypes.array.isRequired,
  onAddAddhocService: PropTypes.func.isRequired,
};

var AddActivityBtn = ({ onAddActivity }) => {
  return (
    <button
      className="Button"
      data-success={true}
      data-size="xs"
      onClick={onAddActivity}>
      Add Activity
    </button>
  );
};
AddActivityBtn.propTypes = { onAddActivity: PropTypes.func.isRequired };

const dayStyles = createUseStyles({
  Day: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} * 2)`,
    gridAutoRows: "max-content",
  },
  header: {
    display: "grid",
    gridTemplateColumns: "1fr auto",
    paddingBottom: `calc(${variables.normal_gap} / 2)`,
    borderBottom: `2px solid ${variables.colors.borders.base}`,
    alignItems: "center",
  },
  headerActions: {
    display: "grid",
    gridAutoFlow: "column",
    gridAutoColumns: "max-content",
    gridGap: `calc(${variables.normal_gap} /2)`,
    alignItems: "center",
  },
});
const Day = ({
  day,
  idx,
  currentStep,
  isLastDay,
  commonDestDays,
  onAdd,
  onAddCustomService,
  onViewTrfDetails,
  onViewAddOnDetails,
  onDeselect,
  onAddAddhocService,
  onEditCustomService,
  onEditRestService,
  onEditCoordService,
  onEditAdhocService,
}) => {
  const classes = dayStyles({ empty: day.services.length === 0 });
  const { setShowActivitiesDrawer, setActivitiesDestOrder, setActivitiesDate } =
    useContext(TripPlanContext);

  return (
    <div key={idx} className={classes.Day}>
      <div className={classes.header}>
        <h5>
          {formatDate(day.date)} at{" "}
          {day.destinations.map((d) => d.name_en).join(" and ")} (Day {idx}){" "}
          {day.services.length ? null : `(day at leisure)`}
        </h5>
        <div className={classes.headerActions}>
          <AddAdhocSrvBtn
            day={day}
            commonDestDays={commonDestDays}
            onAddAddhocService={onAddAddhocService}
          />
          <AddCustomSrvBtn
            onAddCustomService={() => {
              onAddCustomService({
                dayIdx: day.day,
                date: day.date,
                destination_ids: day.destinations.map(
                  (dest) => `${dest.type}__${dest.id}`
                ),
                commonDestDays,
              });
            }}
          />
          <button
            className="Button"
            data-success={true}
            data-size="xs"
            onClick={function () {
              onAdd(
                day.destinations.map((d) => d.order),
                day.date,
                day.day
              );
            }}>
            Add Addon
          </button>
          <AddActivityBtn
            onAddActivity={function () {
              setShowActivitiesDrawer(true);
              setActivitiesDestOrder(day.destinations.map((d) => d.order)[0]);
              setActivitiesDate(day.date);
            }}
          />
        </div>
      </div>
      {day.services.length > 0 && (
        <React.Fragment>
          <DayAccommodation
            day={day}
            eventType="checkOut"
            withPrice={false}
            onAdd={onAdd}
          />
          <DayTransfer
            day={day}
            eventType="outbound"
            currentStep={currentStep}
            onViewTrfDetails={onViewTrfDetails}
          />
          {/* We are on a checkin date */}
          {_.last(day.destinations).checkIn === day.date && (
            <React.Fragment>
              <DayAPIFerry day={day} eventType="arrival" />
            </React.Fragment>
          )}
          <DayTrain day={day} eventType="arrival" />
          <DayFerry day={day} eventType="arrival" />
          <DayCoach day={day} eventType="arrival" />
          <DayTransfer
            day={day}
            eventType="inbound"
            currentStep={currentStep}
            onViewTrfDetails={onViewTrfDetails}
          />
          <DayAccommodation
            day={day}
            eventType="checkIn"
            withPrice={true}
            onAdd={onAdd}
          />
          {/* We are on a checkout date */}
          {day.destinations[0].checkOut === day.date && (
            <React.Fragment>
              <DayFlight day={day} eventType="departure" lastFlight={true} />
              <DayAPIFerry day={day} eventType="departure" />
              <DayAPITrain day={day} eventType="departure" />
            </React.Fragment>
          )}
          {_.last(day.destinations).checkIn === day.date && (
            <React.Fragment>
              <DayFlight
                day={day}
                eventType="arrival"
                lastFlight={false}
                withPrice={day.day === 1}
              />
              <DayAPITrain
                day={day}
                eventType="arrival"
                withPrice={day.day === 1}
              />
            </React.Fragment>
          )}
          <DayTrain day={day} eventType="departure" />
          <DayFerry day={day} eventType="departure" />
          <DayCoach day={day} eventType="departure" />
          <DayAddons
            day={day}
            onAdd={onAdd}
            onViewAddOnDetails={onViewAddOnDetails}
            onDeselect={onDeselect}
            onAddCustomService={onAddCustomService}
          />
          <DayActivities day={day} />
          <DayCustomSrvs
            day={day}
            isLastDay={isLastDay}
            onAdd={() => {
              onAddCustomService({
                dayIdx: day.day,
                date: day.date,
                destination_ids: day.destinations.map(
                  (dest) => `${dest.type}__${dest.id}`
                ),
                commonDestDays,
              });
            }}
            onEdit={onEditCustomService}
            onEditRestService={onEditRestService}
            onEditCoordService={onEditCoordService}
          />
          <DayAdhocSrvs
            day={day}
            isLastDay={isLastDay}
            onEdit={onEditAdhocService}
          />
        </React.Fragment>
      )}
    </div>
  );
};
Day.defaultProps = { day: {}, commonDestDays: [] };
Day.propTypes = {
  idx: PropTypes.number.isRequired,
  day: PropTypes.object.isRequired,
  currentStep: PropTypes.number.isRequired,
  isLastDay: PropTypes.bool.isRequired,
  commonDestDays: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
  onDeselect: PropTypes.func.isRequired,
  onViewTrfDetails: PropTypes.func.isRequired,
  onViewAddOnDetails: PropTypes.func.isRequired,
  onAddCustomService: PropTypes.func.isRequired,
  onAddAddhocService: PropTypes.func.isRequired,
  onEditCustomService: PropTypes.func.isRequired,
  onEditRestService: PropTypes.func.isRequired,
  onEditCoordService: PropTypes.func.isRequired,
};

const styles = createUseStyles({
  DayByDay: { display: "grid", gridGap: `calc(${variables.normal_gap} * 3)` },
});
const DayByDay = ({
  onAdd,
  onViewAddOnDetails,
  onDeselect,
  onViewTrfDetails,
  onAddCustomService,
  onAddAddhocService,
  onEditCustomService,
  onEditRestService,
  onEditCoordService,
  onEditAdhocService,
}) => {
  const days = useSelector((state) => getTripDaysSelector(state));

  const currentStep = useSelector((state) => state.tripPlannerCurrentStep);

  const classes = styles();

  return (
    <div className={classes.DayByDay}>
      {days.map((d, idx) => {
        const isLastDay = idx + 1 == days.length;
        const dayDests = d.destinations.map(
          (dest) => `${dest.type}__${dest.id}`
        );
        const commonDestDays = days.filter((day) => {
          return _.intersection(
            day.destinations.map((dest) => `${dest.type}__${dest.id}`),
            dayDests
          );
        });

        return (
          <Day
            key={idx}
            idx={idx + 1}
            isLastDay={isLastDay}
            day={d}
            commonDestDays={commonDestDays}
            currentStep={currentStep}
            onAdd={onAdd}
            onViewAddOnDetails={onViewAddOnDetails}
            onDeselect={onDeselect}
            onViewTrfDetails={onViewTrfDetails}
            onAddCustomService={onAddCustomService}
            onAddAddhocService={onAddAddhocService}
            onEditCustomService={onEditCustomService}
            onEditRestService={onEditRestService}
            onEditCoordService={onEditCoordService}
            onEditAdhocService={onEditAdhocService}
          />
        );
      })}
    </div>
  );
};
DayByDay.propTypes = {
  onAdd: PropTypes.func.isRequired,
  onViewAddOnDetails: PropTypes.func.isRequired,
  onDeselect: PropTypes.func.isRequired,
  onViewTrfDetails: PropTypes.func.isRequired,
  onAddCustomService: PropTypes.func.isRequired,
  onAddAddhocService: PropTypes.func.isRequired,
  onEditCustomService: PropTypes.func.isRequired,
  onEditRestService: PropTypes.func.isRequired,
  onEditCoordService: PropTypes.func.isRequired,
  onEditAdhocService: PropTypes.func.isRequired,
};
const mapDispatchToProps = (dispatch) => {
  return {
    onAdd: (destOrders, serviceDate, day) =>
      dispatch(requestAddService(destOrders, serviceDate, day)),
    onViewAddOnDetails: (destOrders, serviceDate, day, addOnId) =>
      dispatch(viewAddOnDetails(destOrders, serviceDate, day, addOnId)),
    onDeselect: (day, id) => dispatch(removeAddOn(day, id)),
    onViewTrfDetails: (destOrder, trfType, booking_id) =>
      dispatch(viewTrfDetails(destOrder, trfType, booking_id)),
  };
};
export default connect(null, mapDispatchToProps)(TripModeHoc(DayByDay));
