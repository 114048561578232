import { NormalInputField } from "@src/components/forms";
import { boardMapping } from "@src/config/common";
import { currencies } from "@src/definitions";
import { variables } from "@src/jsssetup";
import { Form, Formik } from "formik";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { toast } from "react-toastify";
import { genericButtonsStyles, genericInfoGroupStyles } from "../common/styles";
import { basicInfoStylesObj } from "./common";

const roomInfoStyles = createUseStyles({
  ...genericButtonsStyles,
  RoomInfo: basicInfoStylesObj.BasicInfo,
  body: {
    ...basicInfoStylesObj.body,
    padding: variables.normal_gap,
  },
  header: basicInfoStylesObj.header,
  groupCard: basicInfoStylesObj.groupCard,
  infoGroup2: {
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr)",
    gridGap: variables.normal_gap,
  },
  infoGroup: {
    display: "grid",
    gridTemplateRows: "auto",
    gridGap: variables.normal_gap,
  },
  infoCouple: {
    "display": "grid",
    "gridTemplateColumns": "1fr 1fr",
    "gridGap": variables.normal_gap,
    "& input": {
      width: "10rem",
    },
  },
  indexCouple: {
    // extend: "infoCouple",
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    gridGap: variables.half_gap,
    borderBottom: `solid 1px ${variables.colors.easy.orange}`,
  },
  infoCoupleTitle: genericInfoGroupStyles.infoCoupleTitle,
  infoCoupleValue: genericInfoGroupStyles.infoCoupleValue,
  actions: {
    display: "grid",
    justifyContent: "end",
    padding: variables.normal_gap,
  },
  [`@media ${variables.media.bigscreen}`]: {
    infoGroup2: {
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
    },
  },
});
// const roomInfoStyles = createUseStyles({
//   ...basicInfoStylesObj,
//   RoomInfo: basicInfoStylesObj.BasicInfo,
//   cell: {
//     ...basicInfoStylesObj.cell,
//     "& >div": { justifyContent: "center" },
//     "& input": { width: "10rem" },
//   },
//   typeCell: {
//     ...basicInfoStylesObj.cell,
//     "& >div": { justifyContent: "center" },
//     "& input": { width: "6rem" },
//   },
//   priceCell: {
//     ...basicInfoStylesObj.cell,
//     "& >div": { justifyContent: "center" },
//     "& input": { width: "5rem" },
//   },
//   [`@media ${variables.media.bigscreen}`]: {
//     cell: {
//       "& input": { width: "100%" },
//     },
//   },
// });
const RoomInfo = ({ step, confirmData, currency, onConfirm }) => {
  const classes = roomInfoStyles();

  const [viewMode, setViewMode] = useState(
    _.get(confirmData, "roomsConfirmed", false)
  );

  var rooms = _.get(confirmData, "rooms", []);

  return (
    <div className={classes.RoomInfo}>
      <div className={classes.header}>
        <h5>Room Information</h5>
      </div>
      <Formik
        initialValues={{
          rooms: _.cloneDeep(rooms).map((room) => {
            room.bedding = _.startCase(room.bedding);
            room.type = _.startCase(room.type);
            room.board = _.get(boardMapping, room.board, room.board);
            room.price_per_person_per_night =
              room.price_per_person_per_night || 0;
            room.price_per_room_per_night = room.price_per_room_per_night || 0;
            room.currency = currency;
            return room;
          }),
        }}
        validateOnChange={false}
        validateOnBlur={false}
        validateOnMount={false}
        validate={(values) => {
          const errors = {};
          const roomKeys = ["name", "type", "bedding", "board"];
          if (
            Object.values(_.get(values, "rooms", [])).some((v) =>
              Object.entries(v)
                .filter(([key, value]) => roomKeys.includes(key))
                .some(([key, value]) => !value)
            )
          ) {
            toast.warning(
              "Please make sure that all room information fields are complete.",
              { duration: 5000 }
            );
            if (values.rooms.some((r) => r.price_per_person == 0)) {
              toast.warning("Zero prices are not allowed.", { duration: 5000 });
            }
            errors["rooms"] = "Please fill all values for each room";
          }
          return errors;
        }}
        onSubmit={(values) => {
          setViewMode(true);
          onConfirm({ ...values, roomsConfirmed: true });
        }}>
        {({ values }) => (
          <Form className={classes.body}>
            {values.rooms.map((room, idx) => (
              <div className={classes.groupCard} key={idx}>
                <div className={classes.indexCouple}>
                  <div className={classes.infoCoupleTitle}>#</div>
                  <div className={classes.infoCoupleValue}>{idx + 1}</div>
                </div>
                <div className={classes.infoGroup2}>
                  <div className={classes.infoGroup}>
                    <div className={classes.infoCouple}>
                      <div className={classes.infoCoupleTitle}>Name</div>
                      <div className={classes.infoCoupleValue}>
                        {viewMode || step > 1 ? (
                          values.rooms[idx].name
                        ) : (
                          <NormalInputField name={`rooms.${idx}.name`} />
                        )}
                      </div>
                    </div>
                    <div className={classes.infoCouple}>
                      <div className={classes.infoCoupleTitle}>Type</div>
                      <div className={classes.infoCoupleValue}>
                        {viewMode || step > 1 ? (
                          values.rooms[idx].type
                        ) : (
                          <NormalInputField name={`rooms.${idx}.type`} />
                        )}
                      </div>
                    </div>
                    <div className={classes.infoCouple}>
                      <div className={classes.infoCoupleTitle}>Bedding</div>
                      <div className={classes.infoCoupleValue}>
                        {viewMode || step > 1 ? (
                          values.rooms[idx].bedding
                        ) : (
                          <NormalInputField name={`rooms.${idx}.bedding`} />
                        )}
                      </div>
                    </div>
                    <div className={classes.infoCouple}>
                      <div className={classes.infoCoupleTitle}>Board</div>
                      <div className={classes.infoCoupleValue}>
                        {viewMode || step > 1 ? (
                          values.rooms[idx].board
                        ) : (
                          <NormalInputField name={`rooms.${idx}.board`} />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={classes.infoGroup}>
                    <div className={classes.infoCouple}>
                      <div className={classes.infoCoupleTitle}>Currency</div>
                      <div className={classes.infoCoupleValue}>
                        {currency} ({_.get(currencies, currency)})
                      </div>
                    </div>
                    <div className={classes.infoCouple}>
                      <div className={classes.infoCoupleTitle}>
                        Price Per Person Per Room
                      </div>
                      <div className={classes.infoCoupleValue}>
                        {viewMode || step > 1 ? (
                          values.rooms[idx].price_per_person_per_night
                        ) : (
                          <NormalInputField
                            type="number"
                            name={`rooms.${idx}.price_per_person_per_night`}
                          />
                        )}
                      </div>
                    </div>
                    <div className={classes.infoCouple}>
                      <div className={classes.infoCoupleTitle}>
                        Price Per Person Per Night
                      </div>
                      <div className={classes.infoCoupleValue}>
                        {viewMode || step > 1 ? (
                          values.rooms[idx].price_per_room_per_night
                        ) : (
                          <NormalInputField
                            type="number"
                            name={`rooms.${idx}.price_per_room_per_night`}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {step <= 1 && (
                  // <div className={classes.infoCouple}>
                  //   <div className={classes.infoCoupleTitle}>Actions</div>
                  <div className={classes.actions}>
                    {viewMode ? (
                      <button
                        // appearance="ghost"
                        // size="xs"
                        className={classes.cancelButton}
                        onClick={(e) => {
                          e.preventDefault();
                          setViewMode(false);
                          onConfirm({ ...values, roomsConfirmed: false });
                        }}>
                        <strong>Edit</strong>
                      </button>
                    ) : (
                      <button
                        // color="green" size="xs"
                        className={classes.confirmButton}
                        type="submit">
                        <strong>Confirm</strong>
                      </button>
                    )}
                  </div>
                  // </div>
                )}
              </div>
            ))}
            {/* <table className={classes.table}>
              <thead className={classes.head}>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Bedding</th>
                  <th>Board</th>
                  <th>Currency</th>
                  <th>Price Per Person Per Room</th>
                  <th>Price Per Person Per Night</th>
                  {step <= 1 && <th>Actions</th>}
                </tr>
              </thead>
              <tbody>
                {values.rooms.map((room, idx) => (
                  <tr key={idx}>
                    <td className={classes.cell}>{idx + 1}</td>
                    <td className={classes.cell}>
                      {viewMode || step > 1 ? (
                        values.rooms[idx].name
                      ) : (
                        <NormalInputField name={`rooms.${idx}.name`} />
                      )}
                    </td>
                    <td className={classes.typeCell}>
                      {viewMode || step > 1 ? (
                        values.rooms[idx].type
                      ) : (
                        <NormalInputField name={`rooms.${idx}.type`} />
                      )}
                    </td>
                    <td className={classes.cell}>
                      {viewMode || step > 1 ? (
                        values.rooms[idx].bedding
                      ) : (
                        <NormalInputField name={`rooms.${idx}.bedding`} />
                      )}
                    </td>
                    <td className={classes.cell}>
                      {viewMode || step > 1 ? (
                        values.rooms[idx].board
                      ) : (
                        <NormalInputField name={`rooms.${idx}.board`} />
                      )}
                    </td>
                    <td className={classes.cell}>
                      {currency} ({_.get(currencies, currency)})
                    </td>
                    <td className={classes.priceCell}>
                      {viewMode || step > 1 ? (
                        values.rooms[idx].price_per_person_per_night
                      ) : (
                        <NormalInputField
                          type="number"
                          name={`rooms.${idx}.price_per_person_per_night`}
                        />
                      )}
                    </td>
                    <td className={classes.priceCell}>
                      {viewMode || step > 1 ? (
                        values.rooms[idx].price_per_room_per_night
                      ) : (
                        <NormalInputField
                          type="number"
                          name={`rooms.${idx}.price_per_room_per_night`}
                        />
                      )}
                    </td>
                    {step <= 1 && (
                      <td className={classes.cell}>
                        {viewMode ? (
                          <Button
                            appearance="ghost"
                            size="xs"
                            onClick={(e) => {
                              e.preventDefault();
                              setViewMode(false);
                              onConfirm({ ...values, roomsConfirmed: false });
                            }}>
                            <strong>Edit</strong>
                          </Button>
                        ) : (
                          <Button color="green" size="xs" type="submit">
                            <strong>Confirm</strong>
                          </Button>
                        )}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table> */}
          </Form>
        )}
      </Formik>
    </div>
  );
};
RoomInfo.propTypes = {
  step: PropTypes.number.isRequired,
  confirmData: PropTypes.object.isRequired,
  currency: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default RoomInfo;
