import { Tag } from "rsuite";

import { formatDateTime } from "@src/tools/date_tools";
import { getDisplayNameFromOptions } from "@src/processors/columnsDataProcessor";
import { lang_mapping } from "@src/tools/lang_tools";

import _ from "lodash";

import React from "react";

export const statusOption = [
  {
    value: "AC",
    label: "Active",
    color: "green",
  },
  {
    value: "IN",
    label: "Inactive",
    color: "red",
  },
  {
    value: "CA",
    label: "Candidate",
    color: "violet",
  },
];

export const baseColumnsProcessor = {
  service_categories: {
    key: "service_categories",
    getter: (data, options) => {
      const travelServices = options[1];
      const travelServiceOptions = options[2];

      let arr = [];
      _.get(data, "travel_services", []).forEach((el) => {
        _.get(travelServices, "data.results", []).forEach((items) => {
          if (el == items.id) {
            if (!_.isEmpty(travelServiceOptions)) {
              arr.push(
                getDisplayNameFromOptions(
                  items.service_type,
                  travelServiceOptions.service_type.choices
                )
              );
            }
          }
        });
      });
      arr = _.reverse(Array.from(new Set(arr)));
      if (arr.length > 1) {
        return `${arr[0]}(${arr.length})`;
      } else if (arr.length == 1) {
        return arr;
      }
    },
    extraGetter: (data, options) => {
      const travelServices = options[1];
      const travelServiceOptions = options[2];

      const arr = [];
      _.get(data, "travel_services").forEach((ele) => {
        if (!_.isEmpty(travelServices)) {
          _.get(travelServices, "data.results", []).forEach((items) => {
            if (ele == items.id) {
              if (!_.isEmpty(travelServiceOptions)) {
                arr.push(
                  getDisplayNameFromOptions(
                    items.service_type,
                    travelServiceOptions.service_type.choices
                  )
                );
              }
            }
          });
        }
      });
      return _.reverse(Array.from(new Set(arr))).join(" , ");
    },
  },
  service_type: {
    key: "service_type",
    getter: (data, options) => {
      const travelServices = options[1];
      const language = options[0];

      const num = _.get(data, "travel_services", []).length;
      const arr = [];
      _.get(data, "travel_services", []).forEach((ele) => {
        _.get(travelServices, "data.results", []).forEach((items) => {
          if (ele == items.id) {
            arr.push(items[`brief_description_${lang_mapping[language]}`]);
          }
        });
      });
      if (arr.length > 1) {
        return `${_.reverse(arr)[0]}(${num})`;
      } else if (arr.length == 1) {
        return arr;
      }
    },
    extraGetter: (data, options) => {
      const travelServices = options[1];
      const language = options[0];
      const arr = [];
      _.get(data, "travel_services", []).forEach((ele) => {
        if (!_.isEmpty(travelServices)) {
          _.get(travelServices, "data.results", []).forEach((items) => {
            if (ele == items.id) {
              arr.push(items[`brief_description_${lang_mapping[language]}`]);
            }
          });
        }
      });

      return _.reverse(arr).join(" , ");
    },
  },
  status: {
    key: "status",
    // eslint-disable-next-line react/display-name
    getter: (data) => {
      const tmp = _.get(data, "status");
      const res = _.find(statusOption, (item) => {
        return item.value == tmp;
      });
      return <Tag color={res.color}>{res.label}</Tag>;
    },
    extraGetter: (data) => {
      const tmp = _.get(data, "status");
      const res = _.find(statusOption, (item) => {
        return item.value == tmp;
      });
      return res.label;
    },
  },
  created: {
    key: "created",
    getter: (data) => formatDateTime(data.created),
  },
  creator: {
    key: "creator",
    getter: (data) =>
      `${_.get(data, "creator_info.first_name")} ${_.get(
        data,
        "creator_info.last_name"
      )}`,
  },
  edited: {
    key: "edited",
    getter: (data) => formatDateTime(data.edited),
  },
};

export const baseColumnsMapping = {
  service_type: {
    key: "service_type",
  },
  service_categories: {
    key: "service_categories",
  },
  created: {
    key: "created",
  },
  edited: {
    key: "edited",
  },
  creator: {
    key: "creator",
  },
  status: {
    key: "status",
    fixed: "right",
  },
};
