import axios from "axios";

import {
  verifyUserInfo,
  authlessEndpoints,
  getLSUserData,
} from "@src/tools/auth_tools";

import { notifyCommonError } from "@src/components/common/notifications/CommonErrorNotification.js";
import _ from "lodash";
import store from "@src/store";
import { refreshIdToken } from "@src/actions/Authentication";

export const getAuthHeaders = () => {
  const userData = getLSUserData();

  if (!userData) {
    notifyCommonError("You are not authenticated. Please sign in.");
    window.location.href = "/#/user/SignInAndUp";
    return null;
  }

  const user_info = JSON.parse(userData.user_info);
  if (!user_info) return null;

  const { id_token } = user_info;

  return { Authorization: `JWT ${id_token}` };
};

const CancelToken = axios.CancelToken;

// create an axios instance
const service = axios.create({ timeout: 60000, withCredentials: true });

// ======================= REQUEST INTERCEPTOR =======================
service.interceptors.request.use(
  async (config) => {
    if (!authlessEndpoints.includes(config.url)) {
      // Check if the user is authenticated and it's token is still valid.
      const result = verifyUserInfo();

      if (!result.verified && result.reason == "user_not_authenticated") {
        notifyCommonError("You are not authenticated. Please sign in.");
        window.location.href = "/#/user/SignInAndUp";
        return null;
      }

      if (!result.verified && result.reason == "expired_token") {
        const result = await store.refreshIdToken(true);
        if (result.refreshed) return null;
      }

      const userData = getLSUserData();

      if (!userData) {
        notifyCommonError("You are not authenticated. Please sign in.");
        window.location.href = "/#/user/SignInAndUp";
        return null;
      }

      const user_info = JSON.parse(userData.user_info);
      const { id_token } = user_info;

      config.headers = { ...config.headers, Authorization: `JWT ${id_token}` };
    }

    if (config.method === "post") {
      config.headers = {
        ...config.headers,
        "Content-Type": "application/json",
      };
    }

    return config;
  },
  (error) => {
    // Do something with request error
    Promise.reject(error);
  }
);

// ======================= RESPONSE INTERCEPTOR ======================
service.interceptors.response.use(
  async (response) => {
    const needRefresh = response.headers["x-refresh-auth-token"] == "True";

    if (needRefresh) store.dispatch(refreshIdToken(true));
    return response.data;
  },
  async (error) => {
    if (axios.isCancel(error)) {
      return Promise.reject({ status: 408 });
    } else {
      if (
        _.get(error, "response.status", 500) == 401 &&
        error.config.url !== "/api/passport/refresh-token/"
      ) {
        store.dispatch(refreshIdToken(true));
      }

      let useGlobalErrorHandle = true;
      const ovrdErrHandle = (toggle) => {
        useGlobalErrorHandle = toggle || false;
      };

      _.set(error.response, "ovrdErrHandle", ovrdErrHandle);
      return Promise.reject(error.response);
    }
  }
);

export const serviceCxl = (payload, timeout) => {
  const source = CancelToken.source();
  window.setTimeout(() => source.cancel(), timeout);

  return service({ ...payload, cancelToken: source.token });
};

export default service;
