import {
  FINANCIAL_SET_CURRENCIES,
  FINANCIAL_SET_CURRENCY,
  FINANCIAL_UPDATING_CURRENCY_INFO,
  FINANCIAL_UPDATED_CURRENCY_INFO,
  FINANCIAL_UPDATE_CURRENCY_RATE,
} from "@src/actions/Financial/types";

import update from "immutability-helper";

export const financialCurrenciesStatus = (state = "UPDATED", action) => {
  switch (action.type) {
    case FINANCIAL_UPDATING_CURRENCY_INFO:
      return "UPDATING";
    case FINANCIAL_UPDATED_CURRENCY_INFO:
      return "UPDATED";
    default:
      return state;
  }
};

export const financialAvailableCurrenciesInitial = [
  { notation: "EUR", selected: true, is_global: true, symbol: "" },
  { notation: "CNY", selected: false, is_global: false, symbol: "" },
  { notation: "USD", selected: false, is_global: false, symbol: "" },
  { notation: "MYR", selected: false, is_global: false, symbol: "" },
  { notation: "SGD", selected: false, is_global: false, symbol: "" },
  { notation: "AUD", selected: false, is_global: false, symbol: "" },
  { notation: "CHF", selected: false, is_global: false, symbol: "" },
  { notation: "GBP", selected: false, is_global: false, symbol: "" },
  { notation: "DKK", selected: false, is_global: false, symbol: "" },
  { notation: "SEK", selected: false, is_global: false, symbol: "" },
  { notation: "NOK", selected: false, is_global: false, symbol: "" },
  { notation: "ISK", selected: false, is_global: false, symbol: "" },
  { notation: "HUF", selected: false, is_global: false, symbol: "" },
  { notation: "CZK", selected: false, is_global: false, symbol: "" },
  { notation: "BRL", selected: false, is_global: false, symbol: "" },
  { notation: "TND", selected: false, is_global: false, symbol: "" },
];
export const financialAvailableCurrencies = (
  state = financialAvailableCurrenciesInitial,
  action
) => {
  switch (action.type) {
    case FINANCIAL_SET_CURRENCIES: {
      const { currencies } = action;
      return update(currencies, {
        $apply: (curr) =>
          curr
            .filter((c) =>
              [
                "EUR",
                "CNY",
                "USD",
                "MYR",
                "SGD",
                "AUD",
                "CHF",
                "GBP",
                "DKK",
                "SEK",
                "NOK",
                "ISK",
                "HUF",
                "CZK",
                "BRL",
                "TND",
              ].includes(c.notation)
            )
            .map((c) => {
              if (c.notation == "EUR") {
                return update(c, {
                  is_global: { $set: true },
                  selected: { $set: true },
                  rate: { $set: 1 },
                });
              } else {
                return c;
              }
            }),
      });
    }
    case FINANCIAL_SET_CURRENCY: {
      const { notation, rate } = action;
      return update(state, {
        $apply: (curr) =>
          curr.map((c) =>
            c.notation == notation
              ? update(c, {
                  selected: { $set: true },
                  ["rate"]: { $set: rate },
                })
              : update(c, {
                  selected: { $set: false },
                })
          ),
      });
    }
    case FINANCIAL_UPDATE_CURRENCY_RATE: {
      const { notation, rate } = action;
      return update(state, {
        $apply: (curr) =>
          curr.map((c) =>
            c.notation == notation ? update(c, { ["rate"]: { $set: rate } }) : c
          ),
      });
    }
    default:
      return state;
  }
};
