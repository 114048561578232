import _ from "lodash";
import { NormalInputField } from "@src/components/forms";
import { variables } from "@src/jsssetup";
import { useFormikContext } from "formik";
import { createUseStyles } from "react-jss";
import { SectionHeader, sectionCommonStyles } from "./components";
import React, { useState } from "react";
import { InlineIcon } from "@iconify/react";
import PropTypes from "prop-types";

const editPaymentLinktStyles = createUseStyles(() => ({
  EditPaymentLink: {
    display: "grid",
    gridGap: variables.normal_gap,
  },
  btnsContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(2,max-content)",
    gridGap: variables.half_gap,
    paddingTop: `calc(${variables.normal_gap} * 3)`,
  },
  cancelBtn: {
    background: "#F6F8FA",
    color: variables.colors.text.colored,
    fontSize: "medium",
    fontWeight: 600,
  },
  saveAddBtn: {
    background: "#F6F8FA",
    color: variables.colors.text.colored,
    border: `1px solid ${variables.colors.text.colored}`,
    borderRadius: "5px",
    fontSize: "medium",
    fontWeight: 600,
  },
  saveBtn: {
    background: variables.colors.easy.orange,
    color: variables.colors.text.light,
    border: `1px solid ${variables.colors.text.colored}`,
    borderRadius: "5px",
    fontSize: "medium",
    fontWeight: 600,
  },
  formContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(2,1fr) max-content",
    placeContent: "start",
    gridGap: variables.normal_gap,
  },
  actionBtn: {
    fontSize: "large",
    color: variables.colors.text.colored,
    cursor: "pointer",
  },
}));
const EditPaymentLink = ({
  paymentLinkIdx,
  paymentLink,
  onSave,
  onResetPaymentLink,
}) => {
  const classes = editPaymentLinktStyles();
  const [initialState, setInitialState] = useState(paymentLink);

  return (
    <div className={classes.EditPaymentLink}>
      <div className={classes.titleContainer}>
        <SectionHeader text="Payment Link Details" />
      </div>
      <div className={classes.formContainer}>
        <NormalInputField
          label="Description"
          name={`payment_links_list.${paymentLinkIdx}.description`}
          customStyles={{ input: { width: "8rem" } }}
          highlightErrors={true}
          as={"textarea"}
          rows={4}
          resize={false}
        />
        <NormalInputField
          label="Amount"
          name={`payment_links_list.${paymentLinkIdx}.amount`}
          customStyles={{ input: { width: "8rem" } }}
          highlightErrors={true}
          readOnly={true}
        />
        <div className={classes.btnsContainer}>
          <InlineIcon
            icon="mdi:tick"
            className={classes.actionBtn}
            onClick={onSave}
          />
          <InlineIcon
            icon="ph:x-bold"
            className={classes.actionBtn}
            onClick={() => onResetPaymentLink(initialState)}
          />
        </div>
      </div>
    </div>
  );
};
EditPaymentLink.defaultProps = {
  paymentLink: {},
  paymentLinkIdx: 0,
};
EditPaymentLink.propTypes = {
  onSave: PropTypes.func.isRequired,
  onResetPaymentLink: PropTypes.func.isRequired,
  paymentLink: PropTypes.object.isRequired,
  paymentLinkIdx: PropTypes.number.isRequired,
};

const guestStyles = createUseStyles(() => ({
  PaymentLink: {
    display: "grid",
    gridTemplateColumns: "1fr repeat(3,max-content)",
    alignItems: "center",
    gridGap: variables.normal_gap,
  },
  description: {
    fontSize: "medium",
    fontWeight: "600",
  },
  price: {
    fontSize: "medium",
    fontWeight: "600",
  },
  editBtn: {
    fontSize: "medium",
    color: variables.colors.text.colored,
    cursor: "pointer",
  },
  divider: {
    marginTop: variables.half_gap,
    marginBottom: variables.half_gap,
    border: "0",
    borderTop: "1px solid #e5e5ea",
  },
  deleteBtn: {
    fontSize: "medium",
    color: variables.colors.text.colored,
    cursor: "pointer",
  },
}));
const PaymentLink = ({
  paymentLink,
  paymentLinkIdx,
  onRemovePaymentLink,
  onEdit,
}) => {
  const classes = guestStyles();
  const currency =
    paymentLink?.currency === "GBX" ? "GBP" : paymentLink.currency;
  return (
    <React.Fragment>
      <div className={classes.PaymentLink}>
        <span className={classes.description}>{paymentLink.description}</span>
        <span className={classes.price}>
          {(paymentLink.amount || 0).toLocaleString("en-US", {
            style: "currency",
            currency: currency || "EUR",
          })}
        </span>
        <InlineIcon
          icon="ic:baseline-edit"
          className={classes.editBtn}
          onClick={onEdit}
        />
        <InlineIcon
          icon="ph:x-bold"
          className={classes.deleteBtn}
          onClick={() => onRemovePaymentLink(paymentLinkIdx)}
        />
      </div>
      <hr className={classes.divider} />
    </React.Fragment>
  );
};
PaymentLink.defaultProps = {
  paymentLink: {},
  paymentLinkIdx: 0,
};
PaymentLink.propTypes = {
  paymentLink: PropTypes.object.isRequired,
  paymentLinkIdx: PropTypes.number.isRequired,
  onRemovePaymentLink: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

const paymentLinkStyles = createUseStyles({
  PaymentLinks: {
    ...sectionCommonStyles,
    display: "grid",
    gridGap: variables.half_gap,
  },
  addGuestBtn: {
    cursor: "pointer",
  },
});
export const PaymentLinks = ({
  onResetPaymentLink,
  onRemovePaymentLink,
  setPaymentLinkForm,
  paymentLinkForm,
}) => {
  const classes = paymentLinkStyles();
  const { values, setFieldValue } = useFormikContext();

  const paymentLinksList = values?.payment_links_list;

  return (
    (paymentLinksList ?? []).length > 0 && (
      <div className={classes.PaymentLinks}>
        <SectionHeader text="Payment Links" />
        <React.Fragment>
          {paymentLinksList.map((link, idx) =>
            paymentLinkForm === idx ? (
              <EditPaymentLink
                key={idx}
                setPaymentLinkForm={setPaymentLinkForm}
                paymentLinks={paymentLinksList}
                paymentLink={link}
                paymentLinkIdx={idx}
                onSave={() => {
                  setPaymentLinkForm(null);
                }}
                onResetPaymentLink={(initialPaymentLinkState) => {
                  setPaymentLinkForm(null);
                  onResetPaymentLink(initialPaymentLinkState, idx);
                }}
              />
            ) : (
              <PaymentLink
                key={idx}
                paymentLinkIdx={idx}
                paymentLink={link}
                onEdit={() => {
                  if (paymentLinkForm === null) {
                    setPaymentLinkForm(idx);
                  }
                }}
                onRemovePaymentLink={() => {
                  if (paymentLinkForm === null) {
                    onRemovePaymentLink(idx);
                  }
                }}
              />
            )
          )}
        </React.Fragment>
      </div>
    )
  );
};
PaymentLinks.defaultProps = {
  paymentLinkForm: null,
};
PaymentLinks.propTypes = {
  paymentLinkForm: PropTypes.number,
  setPaymentLinkForm: PropTypes.func.isRequired,
  onResetPaymentLink: PropTypes.func.isRequired,
  onRemovePaymentLink: PropTypes.func.isRequired,
};
