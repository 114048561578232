import {
  initialBranchesColumnsSetUp,
  initialBranchesColumnsProcessor,
  initialBranchesColumnsMapping,
} from "./../Branches";

export const initialAffiliatesColumnsSetUp = initialBranchesColumnsSetUp;

export const initialAffiliatesColumnsProcessor = initialBranchesColumnsProcessor;

export const initialAffiliatesColumnsMapping = initialBranchesColumnsMapping;
