import _ from "lodash";
import { InlineIcon } from "@iconify/react";
import { variables } from "@src/jsssetup";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import {
  SectionHeader,
  SectionSubHeader,
  sectionCommonStyles,
} from "./components";
import { NormalInputField } from "@src/components/forms";
import { useFormikContext } from "formik";
import { useQuery } from "@tanstack/react-query";
import { fetchAddonsReservationInfo, getArchiveMyOffersDetail } from "@src/api";

const itemStyles = createUseStyles(() => ({
  Item: {
    display: "grid",
    gridTemplateColumns: "1fr repeat(3,max-content)",
    alignItems: "center",
    gridGap: variables.normal_gap,
  },
  itemName: {
    fontSize: "medium",
    fontWeight: "600",
  },
  itemPrice: {
    fontSize: "medium",
    fontWeight: "600",
  },
  editBtn: {
    fontSize: "large",
    color: variables.colors.text.colored,
    cursor: "pointer",
  },
  divider: {
    marginTop: variables.half_gap,
    marginBottom: variables.half_gap,
    border: "0",
    borderTop: "1px solid #e5e5ea",
  },
  deleteBtn: {
    fontSize: "large",
    color: variables.colors.text.colored,
    cursor: "pointer",
  },
}));
const Item = ({ currency, item, itemIdx, onRemoveItem, onEdit }) => {
  const classes = itemStyles();

  return (
    <React.Fragment>
      <div className={classes.Item}>
        <span className={classes.itemName}>
          {item.name} {item.name && item.quantity && "x"} {item.quantity}
        </span>
        <span className={classes.itemPrice}>
          {(item.unitPrice * item.quantity || 0).toLocaleString("en-US", {
            style: "currency",
            currency: currency || "USD",
          })}
        </span>
        <InlineIcon
          icon="ic:baseline-edit"
          className={classes.editBtn}
          onClick={onEdit}
        />
        <InlineIcon
          icon="ph:x-bold"
          className={classes.deleteBtn}
          onClick={() => onRemoveItem(itemIdx)}
        />
      </div>
      <hr className={classes.divider} />
    </React.Fragment>
  );
};
Item.defaultProps = {
  item: {},
  itemIdx: 0,
  currency: "",
};
Item.propTypes = {
  item: PropTypes.object.isRequired,
  itemIdx: PropTypes.number.isRequired,
  onRemoveItem: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
};

const addItemStyles = createUseStyles(() => ({
  AddItem: {
    display: "grid",
    gridGap: variables.normal_gap,
  },
  btnsContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(3,max-content)",
    gridGap: variables.half_gap,
    placeContent: "center",
    paddingTop: variables.normal_gap,
  },
  cancelBtn: {
    background: "#F6F8FA",
    color: variables.colors.text.colored,
    fontSize: "medium",
    fontWeight: 600,
  },
  saveAddBtn: {
    background: "#F6F8FA",
    color: variables.colors.text.colored,
    border: `1px solid ${variables.colors.text.colored}`,
    borderRadius: "5px",
    fontSize: "medium",
    fontWeight: 600,
  },
  saveBtn: {
    background: variables.colors.easy.orange,
    color: variables.colors.text.light,
    border: `1px solid ${variables.colors.text.colored}`,
    borderRadius: "5px",
    fontSize: "medium",
    fontWeight: 600,
  },
  formContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(4,max-content)",
    placeContent: "start",
    placeItems: "center",
    gridGap: variables.normal_gap,
  },
  actionBtn: {
    fontSize: "large",
    color: variables.colors.text.colored,
    cursor: "pointer",
  },
}));
const AddItem = ({ itemIdx, item, onSave, onResetItem }) => {
  const classes = addItemStyles();
  const [initialState, setInitialState] = useState(item);

  return (
    <div className={classes.AddItem}>
      <div className={classes.titleContainer}>
        <SectionHeader text="Item Details" />
      </div>
      <div className={classes.formContainer}>
        <NormalInputField
          label="Name"
          name={`items.${itemIdx}.name`}
          customStyles={{ input: { width: "8rem" } }}
          highlightErrors={true}
        />
        <NormalInputField
          label="Quantity"
          name={`items.${itemIdx}.quantity`}
          type="number"
          customStyles={{ input: { width: "8rem" } }}
          highlightErrors={true}
        />
        <NormalInputField
          label="Price"
          name={`items.${itemIdx}.unitPrice`}
          type="number"
          customStyles={{ input: { width: "5rem" } }}
          highlightErrors={true}
        />
        <div className={classes.btnsContainer}>
          <InlineIcon
            icon="mdi:tick"
            className={classes.actionBtn}
            onClick={onSave}
          />
          <InlineIcon
            icon="ph:x-bold"
            className={classes.actionBtn}
            onClick={() => onResetItem(initialState)}
          />
        </div>
      </div>
    </div>
  );
};
AddItem.defaultProps = {
  item: {},
  itemIdx: 0,
};
AddItem.propTypes = {
  onSave: PropTypes.func.isRequired,
  onResetItem: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  itemIdx: PropTypes.number.isRequired,
};

const itemsStyles = createUseStyles({
  Items: {
    ...sectionCommonStyles,
    display: "grid",
    gridGap: variables.half_gap,
  },
  addItemBtn: {
    cursor: "pointer",
  },
});
export const Items = ({
  onResetItem,
  onRemoveItem,
  addItemForm,
  setAddItemForm,
}) => {
  const { values, setFieldValue } = useFormikContext();
  const classes = itemsStyles();
  const [addItemActive, setAddItemActive] = useState(null);
  const items = values.items;
  const currency = values.currency;

  function addItemHandle() {
    if (!addItemForm) {
      const newItem = { name: "", quantity: "", unitPrice: "", currency: "" };
      setAddItemActive(true);
      setFieldValue("items", [...items, newItem]);
      setAddItemForm(items.length);
    }
  }

  const { data: offerData } = useQuery({
    queryKey: ["invoiceOfferData", values.product_reference],
    queryFn: () =>
      values.product_reference && values.product_type === "offer"
        ? getArchiveMyOffersDetail(values.product_reference)
        : new Promise((resolve) => resolve(null)),
    refetchOnWindowFocus: false,
  });

  const { data: addonData } = useQuery({
    queryKey: ["invoiceAddonData", values.product_reference],
    queryFn: () =>
      values.product_reference && values.product_type === "addon"
        ? fetchAddonsReservationInfo(values.product_reference)
        : new Promise((resolve) => resolve(null)),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (!offerData && !addonData) return null;

    var tmp = {};
    var currency = "";
    if (!!offerData) {
      tmp = {
        reference: offerData.reference,
        name: `${offerData.offer_title} (${offerData.reference})`,
        quantity: 1,
        unitPrice: offerData?.trip_pricing?.selling?.amount ?? 0,
        currency: offerData?.trip_pricing?.selling?.currency ?? "EUR",
      };
      currency = offerData?.trip_pricing?.selling?.currency ?? "EUR";
    } else if (addonData) {
      const pData = _.last(addonData?.data?.pricing_data || []);
      const serviceData = addonData?.data?.service_data;
      tmp = {
        reference: addonData.reference,
        name: [serviceData?.title, serviceData?.subtitle]
          .filter((v) => v)
          .join(", "),
        quantity: 1,
        unitPrice: pData?.calculated_price,
        currency: pData?.currency,
      };
    }

    setFieldValue("payment_links_list", addonData?.data?.payment_links || []);
    setFieldValue("items", [tmp]);
    setFieldValue("currency", currency);
    return null;
  }, [offerData, addonData]);

  return (
    <div className={classes.Items}>
      <SectionHeader text="Items" />
      <React.Fragment>
        {items.map((item, idx) =>
          addItemForm === idx ? (
            <AddItem
              key={idx}
              setAddItemForm={setAddItemForm}
              items={items}
              item={item}
              itemIdx={idx}
              onSave={() => {
                setAddItemForm(null);
                setAddItemActive(false);
              }}
              onResetItem={(initialiIemState) => {
                setAddItemForm(null);
                addItemActive
                  ? onRemoveItem(idx)
                  : onResetItem(initialiIemState, idx);
              }}
            />
          ) : (
            <Item
              currency={currency}
              key={idx}
              itemIdx={idx}
              item={item}
              onEdit={() => {
                if (addItemForm === null) {
                  setAddItemForm(idx);
                  setAddItemActive(false);
                }
              }}
              onRemoveItem={() => {
                if (addItemForm === null) onRemoveItem(idx);
              }}
            />
          )
        )}
      </React.Fragment>
      {addItemForm === null && (
        <div className={classes.addItemBtn} onClick={addItemHandle}>
          <SectionSubHeader text="Add item" />
        </div>
      )}
    </div>
  );
};
Items.defaultProps = { addItemForm: null };
Items.propTypes = {
  addItemForm: PropTypes.number,
  setAddItemForm: PropTypes.func.isRequired,
  onResetItem: PropTypes.func.isRequired,
  onRemoveItem: PropTypes.func.isRequired,
};
