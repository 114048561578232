import _ from "lodash";
import { Button, Steps, Divider, Loader } from "rsuite";

import PropTypes from "prop-types";
import React, { createContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { createUseStyles } from "react-jss";
import { cardStyles, modalGenericStyles, variables } from "@src/jsssetup";
import {
  batchCreateGeneralService,
  getMikiCountries,
} from "@src/api/GeneralServices";

import BatchServiceImportOverview from "./BatchServiceImportOverview";
import { getUserSourceEntitySelector } from "@src/selectors/Shared/user_selectors";
import { Form, Formik } from "formik";
import { NormalSelectField } from "@src/components/forms";
import ItemsList from "./service_import_modal/items_list";
import { toast } from "react-toastify";

const preselectStyles = createUseStyles({
  ...cardStyles,
  body: {
    ...cardStyles.body,
  },
  form: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: variables.normal_gap,
  },
  preselect: {
    "& .rs-panel-body": {
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
      gridGap: variables.normal_gap,
    },
  },
  inputgroup: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
  },
});
const Preselect = ({ setCountry, setType }) => {
  const classes = preselectStyles();
  const [loading, setLoading] = useState(false);
  const [mikiCountries, setMikiCountries] = useState(new Set());

  const typesData = [
    { value: "ACC", label: "Accommodation" },
    { value: "BUN", label: "Service Combination" },
    { value: "GUI", label: "Guide/Assistant" },
    { value: "INT", label: "Interpreter" },
    { value: "MEAL", label: "Meal" },
    { value: "GEN", label: "Generic Service" },
    { value: "SIGH", label: "Sightseeing" },
    { value: "TRF", label: "Transfer" },
    { value: "VEH", label: "Vehicle Rental" },
  ];

  useEffect(() => {
    setLoading(true);
    getMikiCountries()
      .then((data) => {
        if (data.status < 400) {
          setMikiCountries(new Set(_.get(data, "data.results", [])));
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  }, []);

  const countryData = useSelector((state) =>
    _.sortBy(
      _.get(state, "countryCodeInfo", [])
        .filter((c) => c.code)
        .filter((c) => mikiCountries.has(c.code))
        .map((c) => ({ value: c.code, label: `${c.name_en} (${c.code})` })),
      "label"
    )
  );

  return loading ? (
    <Loader size="lg" center />
  ) : (
    <div className={classes.card} shaded>
      <div className={classes.header}>
        <h5>Type & Country</h5>
      </div>
      <div className={classes.body}>
        <div className={classes.body}>
          <Formik
            initialValues={{ country: "", type: "" }}
            validate={(values) => {
              if (values.country) {
                setCountry(values.country);
              }

              if (values.type) {
                setType(values.type);
              }
            }}>
            <Form className={classes.form}>
              <NormalSelectField
                name="country"
                label="Country"
                options={[
                  [null, "------"],
                  ...countryData.map((c) => [c.value, c.label]),
                ]}
              />
              <NormalSelectField
                name="type"
                label="Type"
                options={[
                  [null, "------"],
                  ...typesData.map((t) => [t.value, t.label]),
                ]}
              />
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};
Preselect.propTypes = {
  setCountry: PropTypes.func.isRequired,
  setType: PropTypes.func.isRequired,
};

export const ServiceImportContext = createContext({});

const actionsStyles = createUseStyles({
  Actions: { ...modalGenericStyles.cardActions },
});
const Actions = ({ loading, step, onBatchImport, setStep, onHide }) => {
  const classes = actionsStyles();
  return (
    <div className={classes.Actions}>
      {loading ? null : (
        <React.Fragment>
          <Button id="close_import_service" appearance="ghost" onClick={onHide}>
            <strong>Close</strong>
          </Button>
          {step > 1 && (
            <Button
              appearance="ghost"
              onClick={() => setStep(step === 5 ? 2 : step - 1)}>
              <strong>Back</strong>
            </Button>
          )}
          {step === 5 ? (
            <Button color="green" onClick={onBatchImport}>
              <strong>Batch Import</strong>
            </Button>
          ) : null}
          {[1].includes(step) && (
            <Button appearance="primary" onClick={() => setStep((p) => p + 1)}>
              <strong>Next</strong>
            </Button>
          )}
        </React.Fragment>
      )}
    </div>
  );
};
Actions.propTypes = {
  loading: PropTypes.bool.isRequired,
  step: PropTypes.number.isRequired,
  onSave: PropTypes.func.isRequired,
  onBatchImport: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
};

const styles = createUseStyles({
  ...modalGenericStyles,
  serviceImportModal: {
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateRows: "auto auto auto 1fr",
    overflow: "hidden",
    height: (props) => (props.loading ? "20vh" : "70vh"),
    padding: `calc(${variables.normal_gap} * 2)!important`,
  },
});
const ServiceImportModal = ({ onHide }) => {
  const [step, setStep] = useState(1);
  const [country, setCountry] = useState(null);
  const [type, setType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [finalItems, setFinalItems] = useState([]);

  const classes = styles({ loading });

  const actionsProps = {
    loading,
    step,
    setStep,
    onHide,
    onBatchImport: batchImport,
  };

  const source_entity = useSelector((state) =>
    getUserSourceEntitySelector(state)
  );
  useEffect(
    () =>
      setFinalItems(
        selectedItems.map((item) => {
          const tmp = {
            source_entity,
            service_data: {
              ...item,
              description_en: item.description,
              short_description_en: item.description,
              booking_description_en: item.details.join(", "),
              pricing: item.pricing === null ? [] : item.pricing,
            },
          };
          return tmp;
        })
      ),
    [selectedItems]
  );

  async function batchImport() {
    if (loading) {
      return;
    }

    setLoading(true);

    try {
      const result = await batchCreateGeneralService({
        services_list: finalItems,
      });
      toast.success(
        `We successfully imported ${_.get(
          result,
          "data.created_services"
        )} services.`,
        { duration: 5000 }
      );
      onHide();
    } catch (error) {
      console.log(error);
      toast.error(
        "We couldn't finish this batch import of services. We are sorry for the inconvenience.",
        { duration: 5000 }
      );
    } finally {
      setLoading(false);
    }
  }

  return (
    <ServiceImportContext.Provider
      value={{
        setSelectedItems,
        gotoBulkImport: () => setStep(5),
      }}>
      <div className={classes.modal}>
        <div className={classes.card}>
          <div className={classes.cardHeader}>
            <h5>Import Service</h5>
          </div>
          <div className={classes.cardBody}>
            {loading ? (
              <Loader center={true} size="lg" />
            ) : (
              <React.Fragment>
                <Steps current={step === 5 ? 2 : step - 1}>
                  <Steps.Item title="Select Types & Country" />
                  <Steps.Item title="Select Import Item" />
                  {step === 5 ? null : <Steps.Item title="Verify Data" />}
                  {step === 5 ? null : <Steps.Item title="View Result" />}
                  {step === 5 ? (
                    <Steps.Item title="Bulk Import Overview" />
                  ) : null}
                </Steps>
                <Divider />
                {step === 1 ? (
                  <Preselect
                    setCountry={(value) => setCountry(value)}
                    setType={(value) => setType(value)}
                  />
                ) : step === 2 ? (
                  <ItemsList
                    type={type}
                    country={country}
                    setSelectedItems={setSelectedItems}
                    onBulkImport={() => setStep(5)}
                  />
                ) : step === 5 ? (
                  <BatchServiceImportOverview
                    selectedItems={selectedItems}
                    finalItems={finalItems}
                    setFinalItems={setFinalItems}>
                    <Actions {...actionsProps} />
                  </BatchServiceImportOverview>
                ) : null}
              </React.Fragment>
            )}
          </div>
          {step !== 5 && <Actions {...actionsProps} />}
        </div>
      </div>
    </ServiceImportContext.Provider>
  );
};
ServiceImportModal.propTypes = {
  onHide: PropTypes.func.isRequired,
};

export default ServiceImportModal;
