import _ from "lodash";
import { inititiateFlightSearch } from "@src/api";
import { trainSearch } from "@src/api/Project/trains";
import { getUserSourceEntitySelector } from "@src/selectors/Shared/user_selectors";
import { useMutation } from "@tanstack/react-query";
import { Form, Formik } from "formik";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import {
  resetFlightSessionData,
  saveFlightSessionData,
} from "@src/actions/Project/TripPlanner/flights/index.js";
import { v4 } from "uuid";
import {
  resetTripLegs,
  saveTripLegs,
} from "@src/actions/Project/TripPlanner/transportation/index.js";
import { TripPlanContext } from "../../../TripPlanner";
import { toast } from "react-toastify";
import {
  alterTrainSearchState,
  resetTripTrains,
  saveTrainsResult,
  saveTrainsSession,
} from "@src/actions/Project/TripPlanner/trains";
import { Leg } from "./Leg.js";
import { getSetupFormDataSelector } from "@src/selectors/Project/TripPlanner";
import { initiateFerriesSearch } from "@src/api/Project/ferries";
import {
  resetFerrySessionData,
  saveFerrySessionData,
} from "@src/actions/Project/TripPlanner/ferries";

const ferryPayloadSchema = yup.object().shape({
  language: yup.string().required("Required").default("en-GB"),
  market_type: yup
    .string()
    .required("Required")
    .oneOf(["B2B", "B2C"])
    .default("B2B"),
  source_entity: yup.string().required("Required"),
  currency: yup.string().required("Required").default("EUR"),
  pax: yup.object().shape({
    adults: yup.number().required("Required").default(2),
    seniors: yup.number().required("Required").default(0),
    children: yup.number().required("Required").default(0),
    children_ages: yup.array().of(yup.number()).default([]),
  }),
  legs: yup.array().of(
    yup.object().shape({
      origin: yup.string().required("Required"),
      destination: yup.string().required("Required"),
      is_return_leg: yup.boolean().required("Required").default(false),
      departure_datetime: yup.string().required("Required"),
      vehicles: yup.array().of(yup.string()).default([]),
    })
  ),
  website_api_key: yup.string(),
});

const trainPayloadSchema = yup.object().shape({
  legUid: yup.string().required("Required"),
  market_type: yup
    .string()
    .required("Required")
    .oneOf(["B2B", "B2C"])
    .default("B2B"),
  source_entity: yup.string().required("Required"),
  legs: yup.array().of(
    yup.object().shape({
      origin: yup.object().shape({
        type_: yup.string().required("Required").oneOf(["city", "station"]),
        code: yup.string().required("Required"),
      }),
      destination: yup.object().shape({
        type_: yup.string().required("Required").oneOf(["city", "station"]),
        code: yup.string().required("Required"),
      }),
      departure: yup.string().required("Required"),
      direct_only: yup.boolean().required("Required").default(false),
    })
  ),
  traveler_ages: yup.array().of(yup.number()).required("Required"),
  website_api_key: yup.string(),
});

const flightClassMapping = {
  ALL: "All",
  ECO: "Economy",
  BIZ: "Business",
  FIR: "First",
};

const flightPayloadSchema = yup.object().shape({
  legUid: yup.string().required("Required"),
  roundtrip: yup.boolean().required("Required").default(false),
  relatedLegUids: yup.array().of(yup.string()).default([]),
  origin: yup.string().required("Required"),
  destination: yup.string().required("Required"),
  outbound_departure: yup.string().required("Required"),
  itinerary: yup.array().of(
    yup.object().shape({
      origin: yup.string().required("Required"),
      destination: yup.string().required("Required"),
      departure: yup.string().required("Required"),
    })
  ),
  adults: yup.number().required("Required"),
  children: yup.number().required("Required"),
  baggage_only: yup.boolean().required("Required").default(false),
  stops: yup
    .string()
    .required("Required")
    .oneOf(["AllFlight", "Direct", "MultipleStops"])
    .default("AllFlight"),
  flight_class: yup
    .string()
    .required("Required")
    .oneOf(["All", "Economy", "Business", "First", "PremiumEconomy"])
    .default("All"),
  api_key: yup.string().required("Required"),
  page_size: yup.number().required("Required").default(1000),
  ordering: yup.string().required("Required").default("price"),
  market_type: yup
    .string()
    .required("Required")
    .oneOf(["B2B", "B2C"])
    .default("B2B"),
});

const legSchema = yup.object().shape({
  uid: yup.string().default("").default(v4),
  arrival: yup.string().required("Required"),
  leg_type: yup.string().required("Required").oneOf(["inbetween", "extremal"]),
  departure: yup.string().required("Required"),
  origin_code: yup.string().required("Required"),
  origin_order: yup.number().required("Required"),
  destination_code: yup.string().required("Required"),
  destination_order: yup.number().required("Required"),
  disabled: yup.boolean().default(false),
  service_preferences: yup
    .object()
    .shape({
      preferences: yup.object().default({}),
      service_type: yup
        .string()
        .oneOf(["", "FL", "FER", "TRA", "COA"])
        .default("")
        .nullable(),
    })
    .default({}),
});

const legsSchema = yup
  .array()
  .of(legSchema)
  .default([])
  .test("service_preferences", "Service type has to be selected.", (legs) => {
    if (
      legs
        .filter((l) => !l.disabled)
        .some((l) => !l.service_preferences.service_type)
    ) {
      toast.warning("Please select a service type for all legs.", {
        autoClose: 5000,
      });
      return false;
    }

    return true;
  });

function generateLegs({ originData, destinations, returnData }) {
  const hasOrigin = (originData?.destination?.id ?? 0) > 0;
  const hasReturn = (returnData?.destination?.id ?? 0) > 0;

  const legs = destinations.map((dest, idx) => {
    if (hasOrigin && idx === 0) {
      const departure = DateTime.fromISO(originData.date, {
        setZone: true,
      }).toISODate({ includeOffset: false });
      return legSchema.cast({
        departure,
        arrival: dest.checkIn,
        origin_code: `${originData.destination.type}__${originData.destination.id}`,
        origin_order: 0,
        destination_code: `${dest.type}__${dest.id}`,
        destination_order: dest.order,
        leg_type: "extremal",
        service_preferences: { service_type: "" },
      });
    }
    const prevDest = destinations[idx - 1];
    return legSchema.cast({
      departure: prevDest.checkOut,
      arrival: dest.checkIn,
      origin_code: `${prevDest.type}__${prevDest.id}`,
      origin_order: prevDest.order,
      destination_code: `${dest.type}__${dest.id}`,
      destination_order: dest.order,
      leg_type: "inbetween",
      service_preferences: { service_type: "" },
    });
  });

  if (hasReturn) {
    const lastDest = destinations[destinations.length - 1];

    legs.push(
      legSchema.cast({
        departure: lastDest.checkOut,
        arrival: lastDest.checkOut,
        origin_code: `${lastDest.type}__${lastDest.id}`,
        origin_order: lastDest.order,
        destination_code: `${returnData.destination.type}__${returnData.destination.id}`,
        destination_order: lastDest.order + 1,
        leg_type: "extremal",
        service_preferences: { service_type: "" },
      })
    );
  }
  return legs;
}

function generateFlightPayloads(
  source_entity,
  legs,
  multiCity,
  adults,
  children
) {
  const api_key = window.btoa(source_entity);

  if (multiCity) {
    return [
      flightPayloadSchema.cast({
        legUid: legs[0].uid,
        origin: legs[0].service_preferences.preferences.origin_airports,
        destination:
          legs[0].service_preferences.preferences.destination_airports,
        outbound_departure: DateTime.fromISO(legs[0].departure, {
          setZone: true,
        }).toISO({ includeOffset: false }),
        itinerary: legs.map((l) => ({
          origin: l.service_preferences.preferences.origin_airports,
          destination: l.service_preferences.preferences.destination_airports,
          departure: DateTime.fromISO(l.departure, { setZone: true }).toISO({
            includeOffset: false,
          }),
        })),
        adults,
        children,
        api_key,
      }),
    ];
  } else {
    const payloads = legs.map((l) => {
      const departure = DateTime.fromISO(l.departure, {
        setZone: true,
      }).toISO({ includeOffset: false });

      const flightClass =
        flightClassMapping?.[l.service_preferences?.preferences?.["class"]] ??
        "All";
      return flightPayloadSchema.cast({
        legUid: l.uid,
        origin: l.service_preferences.preferences.origin_airports,
        destination: l.service_preferences.preferences.destination_airports,
        itinerary: [
          {
            origin: l.service_preferences.preferences.origin_airports,
            destination: l.service_preferences.preferences.destination_airports,
            departure,
          },
        ],
        outbound_departure: departure,
        flight_class: flightClass,
        adults,
        children,
        api_key,
      });
    });

    const finalPayloads = [];
    var idxsToSkip = [];
    payloads.forEach((p, idx) => {
      if (idxsToSkip.includes(idx)) return;
      const itin = `${p?.itinerary?.[0]?.origin}___${
        _.last(p?.itinerary ?? [])?.destination
      }`;
      const nextMatchingPld = payloads.find((pld, jidx) => {
        if (idx === jidx) {
          return false;
        }

        const reverseItin = `${_.last(pld?.itinerary ?? [])?.destination}___${
          pld?.itinerary?.[0]?.origin
        }`;
        if (reverseItin === itin) {
          idxsToSkip.push(jidx);
          return true;
        }

        return false;
      });

      if (!nextMatchingPld) {
        finalPayloads.push(p);
        return;
      }

      const newPld = _.cloneDeep(p);
      newPld.relatedLegUids = [
        ...newPld.relatedLegUids,
        nextMatchingPld.legUid,
      ];
      newPld.itinerary = [
        ...p.itinerary,
        ...(nextMatchingPld?.itinerary ?? []),
      ];
      finalPayloads.push(newPld);
    });

    return finalPayloads;
  }
}

function generateTrainPayloads(source_entity, legs, adults, children) {
  const payloads = legs.map((leg) => {
    const [originCode, originType] =
      leg?.service_preferences?.preferences?.origin_station.split("___");
    const [destinationCode, destinationType] =
      leg?.service_preferences?.preferences?.destination_station.split("___");

    const rawDeparture = leg?.service_preferences?.preferences?.departure;
    var departureDt = DateTime.fromISO(leg.departure);
    if (!!rawDeparture) {
      const departureTime = DateTime.fromISO(rawDeparture);
      departureDt = departureDt.set({
        hour: departureTime.hour,
        minute: departureTime.minute,
      });
    }
    departureDt = departureDt.toISO({
      includeOffset: false,
      suppressMilliseconds: true,
      suppressSeconds: true,
    });

    return trainPayloadSchema.cast({
      legUid: leg.uid,
      market_type: "B2B",
      source_entity,
      legs: [
        {
          origin: { type_: originType, code: originCode },
          destination: { type_: destinationType, code: destinationCode },
          departure: departureDt,
          direct_only: false,
        },
      ],
      traveler_ages: [
        ...new Array(adults).fill(30),
        ...new Array(children).fill(2),
      ],
    });
  });
  return payloads;
}

function generateFerryPayloads(source_entity, legs, adults, children) {
  const payloads = legs.map((leg) => {
    const originPort = leg?.service_preferences?.preferences?.origin_port;
    const destPort = leg?.service_preferences?.preferences?.destination_port;

    const rawDeparture = leg?.service_preferences?.preferences?.departure;
    var departureDt = DateTime.fromISO(leg.departure);
    if (!!rawDeparture) {
      const departureTime = DateTime.fromISO(rawDeparture);
      departureDt = departureDt.set({
        hour: departureTime.hour,
        minute: departureTime.minute,
      });
    }
    departureDt = departureDt.toISO({
      includeOffset: false,
      suppressMilliseconds: true,
      suppressSeconds: true,
    });

    return ferryPayloadSchema.cast({
      legUid: leg.uid,
      market_type: "B2B",
      source_entity,
      currency: "EUR",
      pax: { adults, seniors: 0, children, children_ages: [] },
      legs: [
        {
          origin: originPort,
          destination: destPort,
          is_return_leg: false,
          departure_datetime: departureDt,
        },
      ],
    });
  });
  return payloads;
}

export const TransportationLegs = ({ onNextStep }) => {
  const { source_entity, adults, children } = useSelector((state) => {
    const source_entity = getUserSourceEntitySelector(state);

    const { adults, children } = getSetupFormDataSelector(state);
    return { source_entity, adults, children };
  });

  const { transportationMode, setTransportationMode } =
    useContext(TripPlanContext);

  const { originData, destinations, returnData, legs, currentTripStep } =
    useSelector((state) => {
      const originData = state.tripPlannerOriginData;
      const returnData = state.tripPlannerReturnData;
      const destinations = state.tripPlannerDestinations;

      const legs = state.tripPlannerLegsReducer;
      const currentTripStep = state.tripPlannerCurrentStep;

      return { originData, destinations, returnData, legs, currentTripStep };
    });

  const dtFmt = DateTime.DATE_MED_WITH_WEEKDAY;
  function toDtString(dt) {
    return DateTime.fromISO(dt, { setZone: true }).toLocaleString(dtFmt);
  }

  const hasOrigin = originData?.destination?.id ?? 0 > 0;
  const hasReturn = returnData?.destination?.id ?? 0 > 0;
  const lastDest = destinations?.[destinations.length - 1];

  const dispatch = useDispatch();
  const resetTransporation = useCallback(() => {
    dispatch(resetFerrySessionData());
    dispatch(resetTripTrains());
    dispatch(resetFlightSessionData());
  });

  const saveFlSessionData = useCallback(
    async (session_data) => dispatch(saveFlightSessionData(session_data)),
    [dispatch]
  );
  const saveFerSessionData = useCallback(
    async (session_data) => dispatch(saveFerrySessionData(session_data)),
    [dispatch]
  );
  const saveTrainSessionData = useCallback(
    async (sessionData) => dispatch(saveTrainsSession(sessionData)),
    [dispatch]
  );
  const alterTraSearchState = useCallback(
    (legUid, searchState) =>
      dispatch(alterTrainSearchState(legUid, searchState)),
    [dispatch]
  );
  const saveTraResult = useCallback(
    async (trainData, legUid, sessionId) =>
      dispatch(saveTrainsResult(trainData, legUid, sessionId, false)),
    [dispatch]
  );

  const saveLegs = useCallback(
    async (legs) => dispatch(saveTripLegs({ legs })),
    [dispatch]
  );

  const resetLegs = useCallback(() => dispatch(resetTripLegs()), [dispatch]);

  const flightMutation = useMutation({
    mutationFn: (payload) => inititiateFlightSearch({ payload }),
    onSuccess: async (data, values) =>
      await saveFlSessionData({ ...data?.data, payload: values }),
  });

  const trainMutation = useMutation({
    mutationFn: (payload) => trainSearch({ payload }),
    onMutate: (payload) => {
      alterTraSearchState(payload.legUid, true);
      return true;
    },
    onSuccess: async (data, values) => {
      const sessionData = _.cloneDeep(data?.data);
      delete sessionData["results"];
      sessionData["payload"] = values;

      const trainData = data?.data?.results?.[0]?.solutions?.[0];
      trainData.offers = _.sortBy(trainData.offers, (v) =>
        parseFloat(v.amount.value)
      );
      trainData.offers[0]["selected"] = true;
      await saveTrainSessionData(sessionData);
      saveTraResult(
        trainData,
        sessionData["payload"]["legUid"],
        sessionData["session_id"]
      );
      alterTraSearchState(values.legUid, false);
    },
    onError: (___, values) => {
      alterTraSearchState(values.legUid, false);
    },
  });

  const ferryMutation = useMutation({
    mutationFn: (payload) => initiateFerriesSearch(payload),
    onSuccess: async (data, values) =>
      await saveFerSessionData({ ...data?.data, payload: values }),
  });

  // Auto set edit mode if we are at the fist step
  useEffect(() => {
    if (currentTripStep !== 1) return;

    resetLegs();
    setTransportationMode("edit");
  }, [currentTripStep]);

  return (
    <Formik
      initialValues={{
        legs:
          legs.length > 0
            ? legs
            : generateLegs({ originData, destinations, returnData }),
        multiCity: false,
        collapsed: false,
      }}
      validationSchema={yup.object().shape({
        legs: legsSchema,
        multiCity: yup.boolean().default(false),
        collapsed: yup.boolean().default(false),
      })}
      validateOnChange={false}
      validateOnBlur={false}
      validateOnMount={false}
      validateOnSubmit={true}
      onSubmit={async (values) => {
        resetTransporation();
        // Process flights
        const flightPayloads = generateFlightPayloads(
          source_entity,
          values.legs.filter(
            (l) => !l.disabled && l.service_preferences.service_type === "FL"
          ),
          values.multiCity,
          adults,
          children
        );
        flightPayloads.forEach((payload) => flightMutation.mutate(payload));

        /* Process trains */
        const trainPayloads = generateTrainPayloads(
          source_entity,
          values.legs.filter(
            (l) => !l.disabled && l.service_preferences.service_type === "TRA"
          ),
          adults,
          children
        );
        trainPayloads.forEach((payload) => trainMutation.mutate(payload));

        // Process trains.
        const ferryPayloads = generateFerryPayloads(
          source_entity,
          values.legs.filter(
            (l) => !l.disabled && l.service_preferences.service_type === "FER"
          ),
          adults,
          children
        );
        ferryPayloads.forEach((payload) => ferryMutation.mutate(payload));

        await saveLegs(values.legs);
        setTransportationMode("view");
        if (typeof onNextStep === "function") {
          onNextStep();
        }
      }}>
      {({ values, setFieldValue }) => {
        const legs = values?.legs ?? [];
        const originLeg = legs.find(
          (l) => l.origin_order === 0 && l.destination_order === 1
        );

        const returnLeg = legs.find(
          (l) =>
            l.origin_order === lastDest?.order &&
            l.destination_order === (lastDest?.order ?? -1) + 1
        );

        return (
          <Form className="TransportationLegs">
            <div className="TransportationLegs__top-controls Form">
              {transportationMode === "edit" && (
                <React.Fragment>
                  <button
                    className="Button"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      setFieldValue(
                        "legs",
                        values.legs.map((l) => {
                          l.service_preferences.service_type = "FL";
                          return l;
                        })
                      );
                    }}>
                    All Flights
                  </button>
                  <button
                    className="Button"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      setFieldValue(
                        "legs",
                        values.legs.map((l) => {
                          l.service_preferences.service_type = "TRA";
                          return l;
                        })
                      );
                    }}>
                    All Trains
                  </button>
                  <button
                    className="Button"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      setFieldValue(
                        "legs",
                        values.legs.map((l) => {
                          l.service_preferences.service_type = "FER";
                          return l;
                        })
                      );
                    }}>
                    All Ferries
                  </button>
                  {/* <button */}
                  {/*   className="Toggle" */}
                  {/*   data-on={values.multiCity ? "true" : "false"} */}
                  {/*   onClick={(e) => { */}
                  {/*     e.preventDefault(); */}
                  {/*     setFieldValue("multiCity", !values.multiCity); */}
                  {/*   }}> */}
                  {/*   <Icon */}
                  {/*     icon={ */}
                  {/*       values.multiCity */}
                  {/*         ? "ic:round-toggle-on" */}
                  {/*         : "ic:round-toggle-off" */}
                  {/*     } */}
                  {/*   /> */}
                  {/*   <strong>Multi-City Flights</strong> */}
                  {/* </button> */}
                </React.Fragment>
              )}
              <div className="TransportationLegs__top-controls__actions">
                <button
                  className="Button"
                  type="button"
                  data-ghost="true"
                  onClick={(e) => {
                    e.preventDefault();
                    setFieldValue("collapsed", !values.collapsed);
                  }}>
                  {values.collapsed ? "Expand All" : "Collapse All"}
                </button>
                {transportationMode === "edit" ? (
                  <React.Fragment>
                    <button
                      className="Button"
                      type="button"
                      data-ghost="true"
                      onClick={(e) => {
                        e.preventDefault();
                        setFieldValue(
                          "legs",
                          generateLegs({ originData, destinations, returnData })
                        );
                      }}>
                      Reset
                    </button>
                    <button
                      className="Button"
                      data-success="true"
                      type="submit">
                      Confirm
                    </button>
                  </React.Fragment>
                ) : (
                  <button
                    className="Button"
                    onClick={() => setTransportationMode("edit")}>
                    Edit
                  </button>
                )}
              </div>
            </div>
            {hasOrigin && (
              <div className="TransportationLegs__destination">
                <h6>
                  Origin: {originData?.destination?.name_en} -{" "}
                  {originLeg && toDtString(originLeg.departure)}
                </h6>
              </div>
            )}
            {originLeg && (
              <Leg
                idx={0}
                leg={originLeg}
                originName={originData?.destination?.name_en}
                destinationName={destinations?.[0]?.name_en}
                mode={transportationMode}
                allCollapsed={values.collapsed}
              />
            )}
            {destinations.map((dest, idx) => {
              var legIdx = -1;
              var leg = null;
              var originName = null;
              const destinationName = dest.name_en;

              if (idx > 0 && idx < destinations.length) {
                const prevDest = destinations[idx - 1];

                originName = prevDest.name_en;

                leg = legs.find((l, idx) => {
                  if (
                    l.origin_order === prevDest.order &&
                    l.destination_order === dest.order
                  ) {
                    legIdx = idx;
                    return true;
                  }
                  return false;
                });
              }

              return (
                <React.Fragment key={idx}>
                  {leg && (
                    <Leg
                      leg={leg}
                      idx={legIdx}
                      originName={originName}
                      destinationName={destinationName}
                      mode={transportationMode}
                      allCollapsed={values.collapsed}
                    />
                  )}
                  <div className="TransportationLegs__destination">
                    <h6>
                      {idx + 1}. {dest.name_en}
                      {" - "}
                      {toDtString(dest.checkIn)} to {toDtString(dest.checkOut)}
                    </h6>
                  </div>
                </React.Fragment>
              );
            })}
            {returnLeg && (
              <Leg
                leg={returnLeg}
                idx={legs.length - 1}
                originName={lastDest?.name_en}
                destinationName={returnData?.destination?.name_en}
                mode={transportationMode}
                allCollapsed={values.collapsed}
              />
            )}
            {hasReturn && (
              <div className="TransportationLegs__destination">
                <h6>
                  Return: {returnData?.destination?.name_en} -{" "}
                  {returnLeg && toDtString(returnLeg.arrival)}
                </h6>
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};
TransportationLegs.propTypes = {
  onNextStep: PropTypes.func,
};
