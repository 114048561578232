import {
  // ======================= GUEST INFORMATION ======================
  TRIPPLANNER_SHOW_GUEST_MODAL,
  TRIPPLANNER_HIDE_GUEST_MODAL,
  TRIPPLANNER_CHANGE_GUEST,
  TRIPPLANNER_GUESTS_ARE_VALID,
  TRIPPLANNER_GUESTS_ARE_INVALID,
  TRIPPLANNER_SAVING_GUESTS,
  TRIPPLANNER_SAVED_GUESTS,
  // ======================= ROOMGUEST MAPPING =======================
  TRIPPLANNER_SHOW_ROOMGUEST_MODAL,
  TRIPPLANNER_HIDE_ROOMGUEST_MODAL,
  TRIPPLANNER_CHANGE_ROOMGUEST_MAPPING,
  TRIPPLANNER_SAVING_ROOMGUESTS_MAPPING,
  TRIPPLANNER_SAVED_ROOMGUESTS_MAPPING,
  TRIPPLANNER_ROOMGUESTS_MAPPING_IS_VALID,
  TRIPPLANNER_ROOMGUESTS_MAPPING_IS_INVALID,
  TRIPPLANNER_INIT_GUESTS,
  TRIPPLANNER_INIT_ROOMGUEST_MAPPING,
  TRIPPLANNER_UPDATE_GUESTS,
  TRIPPLANNER_RESET_GUESTS_ROOMS,
} from "./types";

import { Notification } from "rsuite";

import { tripUpdate } from "@src/api/Project/TripPlanner";
import { validateTripGuests } from "@src/validators/Project/TripGuests";
import {
  validateRoomMapping,
  validateMapGuestToRoom,
} from "@src/validators/Project/TripRoomGuestMapping";

// ============================= ACTIONS =============================
import { saveTrip } from "./save_actions";

import _ from "lodash";
import React from "react";
import {
  getSelectedAccRoomsSelector,
  getSelectedAccRoomsIdsSelector,
} from "@src/selectors/Project/TripPlanner";

export const initGuestsInfo = () => (dispatch, getState) => {
  const { adults, children, nationality } =
    getState().tripPlannerItinerarySetupForm;

  dispatch(initGuestsInfoData(adults, children, nationality));
};

export const initGuestsInfoData = (adults, children, nationality) => {
  return {
    type: TRIPPLANNER_INIT_GUESTS,
    adults,
    children,
    nationality,
  };
};

export const initRoomGuestMapping = () => (dispatch, getState) => {
  const state = getState();
  const requiredRoomsNumber = state.tripPlannerAccPax.length;
  const guests = _.flatten(Object.values(state.tripPlannerGuestsInfo));
  const destinations = state.tripPlannerDestinations;

  var roomIds = [];
  var roomUids = [];

  destinations.forEach((dest) => {
    roomIds = [
      ...roomIds,
      ...getSelectedAccRoomsIdsSelector(state, { destOrder: dest.order }),
    ];

    roomUids = [
      ...roomUids,
      ...getSelectedAccRoomsSelector(state, { destOrder: dest.order }).map(
        (room) => room.uid
      ),
    ];
  });

  if (requiredRoomsNumber == 1) {
    dispatch(
      initRoomGuestMappingInfo({
        guests,
        roomIds: [[roomIds.join("____"), roomUids.join("__")].join("____")],
        isValid: true,
      })
    );
    return;
  }

  dispatch(initRoomGuestMappingInfo({ guests }));
};

export const initRoomGuestMappingInfo = ({
  guests,
  roomIds = [],
  isValid = false,
}) => {
  return {
    type: TRIPPLANNER_INIT_ROOMGUEST_MAPPING,
    guests,
    roomIds,
    isValid,
  };
};

export const guestsUpdate = ({ guestsInfo }) => {
  return { type: TRIPPLANNER_UPDATE_GUESTS, guestsInfo };
};

export const resetGuestsRooms = () => {
  return { type: TRIPPLANNER_RESET_GUESTS_ROOMS };
};

export const showGuestValidation = async (globalMessages, messages) => {
  const globalWarningKey = "guests_global_warning";
  try {
    await Notification.close(globalWarningKey);
  } catch (error) {
    //pass
  }

  if (globalMessages.length) {
    await Notification.warning({
      key: globalWarningKey,
      title: "Guests Validation Warning",
      description: (
        <ul>
          {globalMessages.map((m, idx) => (
            <li key={idx}>{m}</li>
          ))}
        </ul>
      ),
      duration: 10000,
    });
    return false;
  }

  if (_.flattenDeep(Object.values(messages)).length) {
    const showGuestTypeMessages = async (guestType) => {
      for (var i = 0; i < messages[guestType].length; i++) {
        const errors = messages[guestType][i];
        if (!errors.length) {
          continue;
        }

        const typeMapping = { adults: "adult", children: "child" };

        const key = `${guestType}_${i}_validation_warning`;
        try {
          await Notification.close(key);
        } catch (error) {
          //pass
        }

        await Notification.warning({
          key,
          title: `${_.startCase(typeMapping[guestType])} ${
            i + 1
          } Validation Warning`,
          description: (
            <ul>
              {errors.map((e, idx) => (
                <li key={idx}>{e}</li>
              ))}
            </ul>
          ),
          duration: 10000,
        });
      }
    };

    await showGuestTypeMessages("adults");
    await showGuestTypeMessages("children");
    return false;
  }

  return true;
};

export const guestsAreInvalid = () => {
  return { type: TRIPPLANNER_GUESTS_ARE_INVALID };
};

export const guestsAreValid = () => {
  return { type: TRIPPLANNER_GUESTS_ARE_VALID };
};

export const hideRoomGuestMappingModal = () => (dispatch, getState) => {
  const state = getState();
  const roomsMapping = state.tripPlannerRoomsGuestMapping;

  const isValid = validateRoomMapping(roomsMapping);
  if (!isValid) {
    dispatch(roomGuestMappingIsInvalid());
  }

  dispatch(hideTripPlannerRoomGuestMappingModal());
};

export const roomGuestMappingIsInvalid = () => {
  return {
    type: TRIPPLANNER_ROOMGUESTS_MAPPING_IS_INVALID,
  };
};

export const hideTripPlannerRoomGuestMappingModal = () => {
  return {
    type: TRIPPLANNER_HIDE_ROOMGUEST_MODAL,
  };
};

export const changeRoomGuestMapping =
  (roomCodes, guestUid, actionType = "add", adults, children) =>
  (dispatch, getState) => {
    if (actionType == "add") {
      const state = getState();
      const paxSetup = state.tripPlannerAccPax;
      const mapping = state.tripPlannerRoomsGuestMapping;
      const guests = state.tripPlannerGuestsInfo;

      const selectedRooms = _.flatten(
        state.tripPlannerDestinations.map((destination) =>
          getSelectedAccRoomsSelector(state, { destOrder: destination.order })
        )
      );

      const isValid = validateMapGuestToRoom({
        guestUid,
        roomCodes,
        roomMapping: mapping,
        guestsInfo: guests,
        paxSetup,
        selectedRooms,
        adults,
        children,
      });

      if (!isValid) {
        return;
      }
    }

    dispatch(
      changeTripPlannerRoomGuestMapping(roomCodes, guestUid, actionType)
    );
  };

export const changeTripPlannerRoomGuestMapping = (
  roomCodes,
  guestUid,
  actionType
) => {
  return {
    type: TRIPPLANNER_CHANGE_ROOMGUEST_MAPPING,
    roomCodes,
    guestUid,
    actionType,
  };
};

export const saveRoomGuestMapping = () => async (dispatch, getState) => {
  const state = getState();
  const roomsMapping = state.tripPlannerRoomsGuestMapping;
  var trip_instance = state.tripPlannerInstanceData;

  const isValid = validateRoomMapping(roomsMapping);
  if (!isValid) {
    Notification.warning({
      title: "Room - Guest Mapping Validation Warning",
      description: <p>Please assing all guests to a room</p>,
    });
    return;
  }

  await dispatch(savingRoomsGuestsMapping());
  // await dispatch({ type: TRIPPLANNER_SAVING_ROOMGUESTS_MAPPING });

  if (!trip_instance.id) {
    const save_result = await dispatch(saveTrip({ nonUi: true }));
    if (!save_result) {
      dispatch(hideRoomGuestMappingModal());
      return;
    } else {
      trip_instance = getState().tripPlannerInstanceData;
    }
  }

  try {
    await tripUpdate({ guest_room_mapping: roomsMapping }, trip_instance.id);
    // dispatch({ type: TRIPPLANNER_ROOMGUESTS_MAPPING_IS_VALID });
    dispatch(roomGuestsMappingIsValid());
    dispatch(hideRoomGuestMappingModal());
  } catch (error) {
    Notification.error({
      title: "Room - Guest Mapping Save Failure",
      description: (
        <p>
          {`We failed to save your "Room - Guest Mapping". We are sorry for the
        inconvenience.`}
        </p>
      ),
      duration: 6000,
    });
    dispatch({ type: TRIPPLANNER_ROOMGUESTS_MAPPING_IS_INVALID });
  }
  dispatch(savedRoomsGuestsMapping());
  // dispatch({ type: TRIPPLANNER_SAVED_ROOMGUESTS_MAPPING });
};

export const savingRoomsGuestsMapping = () => {
  return {
    type: TRIPPLANNER_SAVING_ROOMGUESTS_MAPPING,
  };
};

export const roomGuestsMappingIsValid = () => {
  return {
    type: TRIPPLANNER_ROOMGUESTS_MAPPING_IS_VALID,
  };
};

export const savedRoomsGuestsMapping = () => {
  return {
    type: TRIPPLANNER_SAVED_ROOMGUESTS_MAPPING,
  };
};
