import { connect } from "react-redux";

import PropTypes from "prop-types";
import React from "react";
import {
  Panel,
  Table,
  IconButton,
  Icon,
  CheckPicker,
  Slider,
  Checkbox,
  Input,
  InputGroup,
  Toggle,
} from "rsuite";
import SuIcon from "@src/style/icon/components/SuIcon";
import {
  addMeetingPointGroup,
  changeMeetingPointGroupSetup,
  changeMeetingPointGroupMode,
  removeMeeetingPointGroup,
  showCustomMPModal,
  selectMeetingPoint,
  selectAllMeetingPoints,
  deselectAllMeetingPoints,
} from "@src/actions/Operation/CustomServices/AddOn";
import { getMeetingPointGroupSelector } from "@src/selectors/Operation/CustomServices/AddOnSelectors";
import PoiAutoComplete from "@src/pages/Project/TripPlan/Components/PoiAutoComplete";
import { naString } from "@src/tools/string_tools";
import _ from "lodash";
import Fuse from "fuse.js";
import LogoLoading from "@src/components/common/LogoLoading";

class TMeetingPointGroup extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pointName: "",
      showSelected: false,
      expanded: true,
    };

    this.fuseCfg = {
      shouldSort: true,
      threshold: 0.3,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ["name", "name_en", "iata", "query_value"],
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.handlePointNameChange = this.handlePointNameChange.bind(this);
    this.handleExpand = this.handleExpand.bind(this);
  }
  handleSearch() {
    const { pointName, showSelected } = this.state;
    const { group } = this.props;

    const points = _.flatten(Object.values(group.points));

    var fusePoints = new Fuse(points, this.fuseCfg);
    if (pointName !== "") {
      const fuzzyMatchings = fusePoints.search(pointName);
      return fuzzyMatchings.filter((point) =>
        showSelected ? point.selected : point
      );
    }
    return points.filter((point) => (showSelected ? point.selected : point));
  }
  handlePointNameChange(value) {
    this.setState((p) => ({ ...p, pointName: value }));
  }
  handleExpand() {
    this.setState((p) => ({ ...p, expanded: p.expanded ? false : true }));
  }
  renderGroupSetup() {
    const { idx, group, lang, onSetupChange, onChangeMode } = this.props;
    return (
      <div className="MeetingPointGroup__group-setup">
        <span>
          <Icon icon="help-o" /> Set the required criteria to populate the
          pick-up points list.
        </span>
        <div className="MeetingPointGroup__group-setup__filters">
          <div className="MeetingPointGroup__group-setup__filters__filter">
            <span>
              <strong>Point Types</strong>
            </span>
            <CheckPicker
              placement="auto"
              searchable={false}
              value={group.setup.type}
              data={[
                { label: "Airports", value: "airports" },
                { label: "Hotels", value: "hotels" },
                { label: "Custom POIs", value: "custom" },
              ]}
              onChange={function (value) {
                onSetupChange(idx, "type", value);
              }}
            />
          </div>
          <div className="MeetingPointGroup__group-setup__filters__filter">
            <span>
              <strong>Rating</strong>
            </span>
            <CheckPicker
              placement="auto"
              searchable={false}
              value={group.setup.rating}
              data={[
                { label: "1 Star", value: 1 },
                { label: "2 Stars", value: 2 },
                { label: "3 Stars", value: 3 },
                { label: "4 Stars", value: 4 },
                { label: "5 Stars", value: 5 },
              ]}
              onChange={function (value) {
                onSetupChange(idx, "rating", value);
              }}
            />
          </div>
          <div className="MeetingPointGroup__group-setup__filters__filter">
            <span>
              <strong>Nearby</strong>
            </span>
            <PoiAutoComplete
              geodataDetailsOnly={true}
              lang={lang}
              onSelect={function (value) {
                onSetupChange(idx, "nearby", value);
              }}
            />
          </div>
          <div className="MeetingPointGroup__group-setup__filters__filter">
            <span>
              <strong>Radius: {group.setup.radius}km</strong>
            </span>
            <Slider
              progress
              value={group.setup.radius}
              min={0}
              max={50}
              onChange={function (value) {
                onSetupChange(idx, "radius", value);
              }}
            />
          </div>
        </div>
        <div className="MeetingPointGroup__group-setup__controls">
          <IconButton
            icon={<Icon icon="check" />}
            color="green"
            size="sm"
            onClick={function () {
              onChangeMode(idx, "list");
            }}>
            Apply
          </IconButton>
        </div>
      </div>
    );
  }
  renderGroupTables() {
    const { pointName } = this.state;
    const { idx, group, onSelectPoi, onSelectAll, onDeselectAll } = this.props;

    const allSelected = _.flatten(Object.values(group.points)).every(
      (point) => point.selected
    );
    const noneSelected = _.flatten(Object.values(group.points)).every(
      (point) => !point.selected
    );

    const data = this.handleSearch();

    return (
      <Table
        virtualized
        data={data}
        loading={group.loading}
        className="MeetingPointGroup__group-table"
        headerHeight={75}
        rowHeight={60}
        height={300}
        shouldUpdateScroll={false}
        renderEmpty={function () {
          return <LogoLoading msg="No points available" />;
        }}>
        <Table.Column fixed="left" width={60} verticalAlign="middle">
          <Table.HeaderCell>
            <div>
              Selected{" "}
              <Checkbox
                indeterminate={!noneSelected && !allSelected}
                checked={allSelected}
                onChange={function (value, checked) {
                  checked ? onSelectAll(idx) : onDeselectAll(idx);
                }}
              />
            </div>
          </Table.HeaderCell>
          <Table.Cell>
            {(rowData) => (
              <Checkbox
                checked={rowData.selected}
                onChange={function (value, checked) {
                  onSelectPoi(
                    idx,
                    rowData.type,
                    rowData.type == "airport" ? rowData.iata : rowData.id,
                    checked
                  );
                }}
              />
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column flexGrow={1} verticalAlign="middle">
          <Table.HeaderCell>
            Name
            <InputGroup>
              <Input
                value={pointName}
                onChange={(value) => this.handlePointNameChange(value)}
              />
              <InputGroup.Addon>
                <Icon icon="search" />
              </InputGroup.Addon>
            </InputGroup>
          </Table.HeaderCell>
          <Table.Cell>
            {(rowData) =>
              rowData.type == "airport"
                ? `${rowData.name_en} (${rowData.iata})`
                : rowData.type == "custom"
                ? rowData.query_value
                : rowData.name
            }
          </Table.Cell>
        </Table.Column>
        <Table.Column verticalAlign="middle" width={60}>
          <Table.HeaderCell>Rating</Table.HeaderCell>
          <Table.Cell>
            {(rowData) =>
              rowData.type !== "accommodation"
                ? naString()
                : `${rowData.official_rating} ${
                    rowData.official_rating == 1 ? "Star" : "Stars"
                  }`
            }
          </Table.Cell>
        </Table.Column>
        <Table.Column verticalAlign="middle" width={60}>
          <Table.HeaderCell>Type</Table.HeaderCell>
          <Table.Cell>
            {(rowData) =>
              rowData.type == "airport"
                ? "Airport"
                : rowData.type == "custom"
                ? "Custom"
                : "Hotel"
            }
          </Table.Cell>
        </Table.Column>
      </Table>
    );
  }
  render() {
    const { showSelected, expanded } = this.state;
    const { idx, group, onChangeMode, onRemove, onAddCustomPOI } = this.props;

    const allPoints = _.flatten(Object.values(group.points));

    return (
      <Panel
        bordered
        className={`MeetingPointGroup${
          !expanded ? " MeetingPointGroup--collapsed" : ""
        }`}
        expanded={expanded}
        collapsible
        header={
          <React.Fragment>
            <h5>
              <Icon
                icon={expanded ? "tree-open" : "tree-close"}
                onClick={this.handleExpand}
              />
              Group {idx + 1}
              {group.mode == "setup"
                ? " Setup"
                : `: (${allPoints.filter((point) => point.selected).length}/
              ${allPoints.length})`}
            </h5>
            {expanded ? (
              <span className="MeetingPointGroup__header__actions">
                {group.mode == "list" ? (
                  <React.Fragment>
                    <span>
                      <strong>Show: </strong>
                      <Toggle
                        checked={showSelected}
                        checkedChildren={
                          <span>
                            <strong>Selected</strong>
                          </span>
                        }
                        unCheckedChildren={
                          <span>
                            <strong>All</strong>
                          </span>
                        }
                        onChange={(checked) => {
                          this.setState((p) => ({
                            ...p,
                            showSelected: checked,
                          }));
                        }}
                      />
                    </span>
                    <IconButton
                      icon={<Icon icon="edit" />}
                      color="blue"
                      size="xs"
                      onClick={function () {
                        onChangeMode(idx, "setup");
                      }}>
                      Setup
                    </IconButton>
                  </React.Fragment>
                ) : null}
                <IconButton
                  icon={<Icon icon="minus" />}
                  color="red"
                  size="xs"
                  onClick={function () {
                    onRemove(idx);
                  }}>
                  Remove Group
                </IconButton>
                {group.mode == "list" && group.setup.type.includes("custom") ? (
                  <IconButton
                    icon={<Icon icon="plus" />}
                    color="green"
                    size="xs"
                    onClick={function () {
                      onAddCustomPOI(idx);
                    }}>
                    Add Custom Point
                  </IconButton>
                ) : null}
              </span>
            ) : null}
          </React.Fragment>
        }>
        {group.mode == "list"
          ? this.renderGroupTables()
          : this.renderGroupSetup()}
      </Panel>
    );
  }
}

TMeetingPointGroup.propTypes = {
  lang: PropTypes.string.isRequired,
  idx: PropTypes.number.isRequired,
  group: PropTypes.object.isRequired,
  onSetupChange: PropTypes.func.isRequired,
  onChangeMode: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onAddCustomPOI: PropTypes.func.isRequired,
  onSelectPoi: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  onDeselectAll: PropTypes.func.isRequired,
};

const mpMapStateToProps = (state, ownProps) => {
  const { idx } = ownProps;
  const group = getMeetingPointGroupSelector(state, { idx });

  return { group, lang: state.setLang };
};

const mpMapDispatchToProps = (dispatch) => {
  return {
    onSetupChange: (groupIdx, key, value) =>
      dispatch(changeMeetingPointGroupSetup(groupIdx, key, value)),
    onChangeMode: (groupIdx, mode) =>
      dispatch(changeMeetingPointGroupMode(groupIdx, mode)),
    onRemove: (groupIdx) => dispatch(removeMeeetingPointGroup(groupIdx)),
    onAddCustomPOI: (groupIdx) => dispatch(showCustomMPModal(groupIdx)),
    onSelectPoi: (groupIdx, pointType, id, selected) =>
      dispatch(selectMeetingPoint(groupIdx, pointType, id, selected)),
    onSelectAll: (groupIdx, pointType) =>
      dispatch(selectAllMeetingPoints(groupIdx, pointType)),
    onDeselectAll: (groupIdx, pointType) =>
      dispatch(deselectAllMeetingPoints(groupIdx, pointType)),
  };
};
const MeetingPointGroup = connect(
  mpMapStateToProps,
  mpMapDispatchToProps
)(TMeetingPointGroup);

class MeetingPointSetupController extends React.PureComponent {
  constructor(props) {
    super(props);
  }
  render() {
    const { groupIdxs, onAddGroup } = this.props;
    return (
      <Panel
        className="CustomPanel MeetingPointSetupController"
        header={
          <React.Fragment>
            <h5>
              <SuIcon icon="icon-Guides" size="lg" /> Pick-up Groups
            </h5>
            <span className="CustomPanel__header-actions">
              <IconButton
                icon={<Icon icon="plus" />}
                size="sm"
                color="green"
                onClick={function () {
                  onAddGroup();
                }}>
                Add Group
              </IconButton>
            </span>
          </React.Fragment>
        }
        shaded>
        {groupIdxs.length ? (
          groupIdxs.map((idx) => (
            <MeetingPointGroup key={idx} idx={idx} onAddBelow={onAddGroup} />
          ))
        ) : (
          <LogoLoading msg="No Groups available" />
        )}
      </Panel>
    );
  }
}

MeetingPointSetupController.propTypes = {
  groupIdxs: PropTypes.array.isRequired,
  onAddGroup: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const groupIdxs = state.customMeetingPointGroups.map((group, idx) => idx);
  return { groupIdxs };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddGroup: (idx) =>
      idx
        ? dispatch(addMeetingPointGroup(idx))
        : dispatch(addMeetingPointGroup()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MeetingPointSetupController);
