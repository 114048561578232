import PropTypes from "prop-types";
import React from "react";
import PriceDetails from "./PriceDetails";
import { markUpper, revenuer } from "@src/tools/pricing_tools";
import { createUseStyles } from "react-jss";
import { useSelector } from "react-redux";
import { variables } from "@src/jsssetup";

// function markupPseudoStyles({ hasMarkup }) {
//   return {
//     content: `""`,
//     position: "absolute",
//     height: "8px",
//     left: "50%",
//     width: "0px",
//     border: hasMarkup
//       ? `1px solid ${variables.colors.deepblue}`
//       : `1px solid ${variables.colors.layoverRed}`,
//   };
// }

const markupStyles = createUseStyles({
  Markup: (props) => ({
    position: "relative",
    border: props.hasMarkup
      ? `2px solid ${variables.colors.lightGrey2}`
      : `2px solid ${variables.colors.lightRed}`,
    borderRadius: `calc(${variables.normal_gap} / 2)`,
    width: "100%",
    textAlign: "center",
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    alignItems: "center",
    color: variables.colors.text.dark,
    background: props.hasMarkup
      ? variables.colors.lightGrey2
      : variables.colors.lightRed,
    fontWeight: "500",
    fontSize: "small",
  }),
});
const Markup = ({ markup }) => {
  const classes = markupStyles({ hasMarkup: markup > 0 });

  return (
    <div className={classes.Markup}>
      <strong>{markup}%</strong>
      <small>Markup</small>
    </div>
  );
};
Markup.defaultProps = { markup: 0 };
Markup.propTypes = { markup: PropTypes.number.isRequired };

const GrossMargin = ({ gross_margin }) => {
  const classes = markupStyles({ hasMarkup: gross_margin > 0 });

  return (
    <div className={classes.Markup}>
      <strong>{gross_margin}%</strong>
      <small>Gross Margin</small>
    </div>
  );
};
GrossMargin.defaultProps = { gross_margin: 0 };
GrossMargin.propTypes = { gross_margin: PropTypes.number.isRequired };

const FlatValue = ({ flat_value }) => {
  const classes = markupStyles({ hasMarkup: flat_value > 0 });

  return (
    <div className={classes.Markup}>
      <strong>{flat_value}</strong>
      <small>Gross Profit</small>
    </div>
  );
};
FlatValue.defaultProps = { flat_value: 0 };
FlatValue.propTypes = { flat_value: PropTypes.number.isRequired };

const styles = createUseStyles({
  OverviewPrice: (props) => ({
    display: "grid",
    gridGap: `6px`,
    alignItems: "center",
    justifyContent: props.searching ? "center" : "end",
    padding: 0,
  }),
});
const OverviewPrice = ({
  price,
  currency,
  markup,
  gross_margin,
  flat_value,
  searching,
  onDetails,
}) => {
  const currentStep = useSelector((state) => state.tripPlannerCurrentStep);

  var finalPrice = 0;
  if (flat_value > 0) {
    finalPrice = price + flat_value;
  } else if (gross_margin > 0) {
    finalPrice = revenuer(price, gross_margin);
  } else {
    finalPrice = markUpper(price, markup);
  }

  const classes = styles({ currentStep, searching });

  return (
    <div className={classes.OverviewPrice}>
      {searching ? (
        <PriceDetails price={0} currency={currency} searching={searching} />
      ) : (
        <React.Fragment>
          <PriceDetails
            price={price}
            currency={currency}
            onDetails={onDetails}
          />
          {currentStep < 4 && (
            <React.Fragment>
              {flat_value > 0 ? (
                <FlatValue flat_value={flat_value} />
              ) : gross_margin > 0 ? (
                <GrossMargin gross_margin={gross_margin} />
              ) : (
                <Markup markup={markup} />
              )}
              <PriceDetails
                priceType="selling"
                currency={currency}
                price={finalPrice}
                searching={searching}
              />
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </div>
  );
};
OverviewPrice.defaultProps = {
  price: 0,
  currency: "EUR",
  markup: 0,
  searching: false,
};
OverviewPrice.propTypes = {
  searching: PropTypes.bool,
  price: PropTypes.number.isRequired,
  markup: PropTypes.number,
  gross_margin: PropTypes.number,
  flat_value: PropTypes.number,
  currency: PropTypes.string.isRequired,
  onDetails: PropTypes.func,
};

export default OverviewPrice;
