import ControlPanel from "../Components/ControlPanel";
import ServicesFilter from "./ServicesFilter";
import PrebookCalculator from "./PrebookCalculator";
import PrebookPayment from "./PrebookPayment";
import React from "react";
import KPIController from "./KPIController";
import { createUseStyles } from "react-jss";
import { variables } from "@src/jsssetup";
import PropTypes from "prop-types";
import PrebookActionController from "./PrebookActionController";

const styles = createUseStyles({
  PrebookTopController: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} * 2)`,
    placeItems: "center",
    gridAutoFlow: "row",
    justifyContent: "center",
    padding: [
      `calc(${variables.normal_gap} * 3)`,
      variables.normal_gap,
      variables.normal_gap,
      variables.normal_gap,
    ],
  },
  card: {
    display: "grid",
    gridTemplateColumns: "repeat(6, max-content)",
    gridGap: variables.half_gap,
    justifyContent: "center",
    borderRadius: variables.half_gap,
    boxShadow: variables.shadows.heavyShadow,
    background: "#FFFF",
  },
});
export default function PrebookTopController({ currentStep, usage }) {
  const classes = styles();
  const actionsSection = () => {
    if ([1, 2].includes(currentStep)) {
      return null;
    } else if (currentStep == 4) {
      if (usage == "MKT") {
        return null;
      }
      return <PrebookActionController />;
    } else {
      return null;
    }
  };

  return (
    <div className={classes.PrebookTopController}>
      <div className={classes.card}>
        <ControlPanel />
        {actionsSection()}
        <ServicesFilter mode="tripplan" />
        <PrebookCalculator />
        <PrebookPayment />
        <KPIController />
      </div>
    </div>
  );
}
PrebookTopController.propTypes = {
  currentStep: PropTypes.number.isRequired,
  usage: PropTypes.string.isRequired,
};
