import _ from "lodash";
import { createSelector } from "reselect";
import {
  getSrvsUidForBooking,
  getSrvsUidForOptionBooking,
} from "../prebook/selected_services";

export function getDbDCustomServices(state) {
  return state.tripPlannerDbDCustomSrvData;
}

export const getRestaurantServicesSelector = createSelector(
  [
    getDbDCustomServices,
    getSrvsUidForBooking,
    getSrvsUidForOptionBooking,
    (state, props) => props || {},
  ],
  (custom_services, all_reg_booking_srvs, all_option_booking_srvs, props) => {
    const { booking_mode } = props;
    const srvs = _.flattenDeep(Object.values(custom_services));
    if (["regular", "option"].includes(booking_mode)) {
      const book_srvs =
        booking_mode === "regular"
          ? all_reg_booking_srvs
          : all_option_booking_srvs;
      return srvs.filter(
        (srv) =>
          srv.custom_service_type === "REST" &&
          book_srvs.some(
            (s) => s.srv_type === "REST" && s.uid === srv.reference
          )
      );
    } else {
      return srvs.filter((srv) => srv.custom_service_type === "REST");
    }
  }
);

export const getCoordinatorServicesSelector = createSelector(
  [
    getDbDCustomServices,
    getSrvsUidForBooking,
    getSrvsUidForOptionBooking,
    (__, props) => props || {},
  ],
  (custom_services, all_reg_booking_srvs, all_option_booking_srvs, props) => {
    const { booking_mode } = props;
    const srvs = _.flattenDeep(
      Object.entries(custom_services).map(([__, services]) => services)
    );

    const tmp = {};

    srvs
      .filter((srv) => srv.custom_service_type === "COO")
      .forEach((srv) => {
        if (Object.keys(tmp).includes(srv?.uid)) {
          const currentDailyCostDates = tmp?.[srv?.uid]?.daily_cost.map(
            (d) => d.date
          );
          srv.daily_cost.forEach((day) => {
            if (currentDailyCostDates.includes(day.date)) return;
            tmp[srv?.uid].daily_cost.push(day);
          });

          return;
        }

        tmp[srv?.uid] = srv;
      });

    const services = Object.values(tmp);

    if (["regular", "option"].includes(booking_mode)) {
      const book_srvs =
        booking_mode === "regular"
          ? all_reg_booking_srvs
          : all_option_booking_srvs;

      return services.filter((srv) =>
        book_srvs.some((s) => s.srv_type === "COO" && s.uid === srv?.uid)
      );
    } else {
      return services;
    }
  }
);

export const getGeneralServicesSelector = createSelector(
  [
    getDbDCustomServices,
    getSrvsUidForBooking,
    getSrvsUidForOptionBooking,
    (state, props) => props || {},
  ],
  (custom_services, all_reg_booking_srvs, all_option_booking_srvs, props) => {
    const { booking_mode } = props;
    const srvs = _.flattenDeep(Object.values(custom_services));
    if (["regular", "option"].includes(booking_mode)) {
      const book_srvs =
        booking_mode === "regular"
          ? all_reg_booking_srvs
          : all_option_booking_srvs;
      return srvs.filter(
        (srv) =>
          srv.custom_service_type === "GEN" &&
          book_srvs.some((s) => s.srv_type === "GEN" && s.uid === srv.id)
      );
    } else {
      return srvs.filter((srv) => srv.custom_service_type === "GEN");
    }
  }
);
