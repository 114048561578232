import TransTxt from "@src/components/common/SxFormatMessage";
import React from "react";
import _ from "lodash";
import { lang_mapping } from "@src/tools/lang_tools";
import { getDisplayNameFromOptions } from "@src/processors/columnsDataProcessor";

import { baseColumnsProcessor } from "@src/forms/CommonColumns/baseColumns";

export const initialUsersColumnsSetUp = _.merge({
  basic: {
    value: [
      "id",
      "account_email",
      "job_description",
      "company_type",
      "last_name",
      "first_name",
      "gender",
    ],
    choices: [
      {
        display_name: <TransTxt id="affiliates_uid" upperCase />,
        key: "id",
        disabled: false,
      },
      {
        key: "company_type",
        disabled: false,
        display_name: <TransTxt id="user_type" startCase />,
      },
      {
        key: "gender",
        disabled: false,
        display_name: <TransTxt id="gender" startCase />,
      },
      {
        key: "account_email",
        disabled: false,
        display_name: <TransTxt id="account_email" startCase />,
      },
      {
        key: "last_name",
        disabled: false,
        display_name: <TransTxt id="last_name" startCase />,
      },
      {
        key: "first_name",
        disabled: false,
        display_name: <TransTxt id="first_name" startCase />,
      },
      {
        key: "job_description",
        disabled: false,
        display_name: <TransTxt id="job_description" startCase />,
      },
    ],
  },
  contact: {
    value: ["phone", "mobile_phone"],
    choices: [
      {
        key: "phone",
        disabled: false,
        display_name: <TransTxt id="phone" startCase />,
      },
      {
        key: "mobile_phone",
        disabled: false,
        display_name: <TransTxt id="mobile_phone" startCase />,
      },
    ],
  },
  organisation: {
    value: ["legal_title", "department", "role"],
    choices: [
      {
        key: "legal_title",
        disabled: false,
        display_name: <TransTxt id="business_name" startCase />,
      },
      {
        display_name: <TransTxt id="brand_name" startCase />,
        disabled: false,
        key: "brand_name",
      },
      {
        display_name: <TransTxt id="department" startCase />,
        disabled: false,
        key: "department",
      },
      {
        display_name: <TransTxt id="role" startCase />,
        disabled: false,
        key: "role",
      },
    ],
  },
  sections: {
    value: ["travel_sector", "expertise_type"],
    choices: [
      {
        display_name: <TransTxt id="travel_sector" startCase />,
        disabled: false,
        key: "travel_sector",
      },
      {
        display_name: <TransTxt id="expertise_type" startCase />,
        disabled: false,
        key: "expertise_type",
      },
    ],
  },
  services: {
    value: ["service_categories", "service_type"],
    choices: [
      {
        key: "service_categories",
        disabled: false,
        display_name: <TransTxt id="service_categories" startCase />,
      },
      {
        key: "service_type",
        disabled: false,
        display_name: <TransTxt id="service_type" startCase />,
      },
    ],
  },
  stage: {
    value: ["status"],
    choices: [
      {
        key: "created",
        disabled: false,
        display_name: <TransTxt id="created" startCase />,
      },
      {
        key: "edited",
        disabled: false,
        display_name: <TransTxt id="edited" startCase />,
      },
      {
        key: "creator",
        disabled: false,
        display_name: <TransTxt id="creator" startCase />,
      },
      {
        key: "status",
        disabled: true,
        display_name: <TransTxt id="status" startCase />,
      },
    ],
  },
});

export const initialUsersColumnsProcessor = Object.assign(
  {
    brand_name: {
      key: "brand_name",
      getter: (data) => data.company_brand_name,
    },
    first_name: {
      key: "first_name",
      getter: (data) => {
        return _.get(data, "user.first_name");
      },
    },
    account_email: {
      key: "account_email",
      getter: (data) => _.get(data, "user.email"),
    },
    last_name: {
      key: "last_name",
      getter: (data) => _.get(data, "user.last_name"),
    },
    job_description: {
      key: "job_description",
      getter: (data, options) => {
        const language = options[0];
        const arr = [];
        data.job_description.forEach((item) => {
          arr.push(item[`brief_description_${lang_mapping[language]}`]);
        });
        if (arr.length > 1) {
          return `${arr[0]}(${arr.length})`;
        }
        return arr;
      },
      extraGetter: (data, options) => {
        const language = options[0];
        const arr = [];
        data.job_description.forEach((item) => {
          arr.push(item[`brief_description_${lang_mapping[language]}`]);
        });
        return arr;
      },
    },
    company_type: {
      key: "company_type",
      getter: (data) => {
        if (data.company_type == "independentagent") {
          return "Independent Agent";
        } else if (data.company_type == "subagent") {
          return "Sub Agent";
        }
        return _.startCase(data.company_type);
      },
    },
    gender: {
      key: "gender",
      getter: (data, options) => {
        const res = data.gender;
        const option = options[3];
        return getDisplayNameFromOptions(res, _.get(option, "gender.choices"));
      },
    },
    phone: {
      key: "phone",
      getter: (data) => data.phone,
    },
    mobile_phone: {
      key: "mobile_phone",
      getter: (data) => {
        if (data.mobile_phone) {
          return data.mobile_phone;
        } else {
          return "";
        }
      },
    },
    legal_title: {
      key: "legal_title",
      getter: (data) => data.company_legal_title,
    },
    department: {
      key: "department",
      getter: (data, options) => {
        const res = data.department;
        const option = options[3];
        return getDisplayNameFromOptions(
          res,
          _.get(option, "department.choices")
        );
      },
    },
    role: {
      key: "role",
      getter: (data) => data.roles.toString(),
    },
    travel_sector: {
      key: "travel_sector",
      getter: (data) => data.travel_sector.join(","),
      extraGetter: (data, options) => {
        const res = data.travel_sector;
        const option = options[3];
        const arr = [];
        res.forEach((item) => {
          arr.push(
            getDisplayNameFromOptions(
              item,
              _.get(option, "travel_sector.child.choices")
            )
          );
        });
        return arr.join(",");
      },
    },
    expertise_type: {
      key: "expertise_type",
      getter: (data) => {
        return data.expertise_type.join(",");
      },
      extraGetter: (data, options) => {
        const res = data.expertise_type;
        const option = options[3];
        const arr = [];
        res.forEach((item) => {
          arr.push(
            getDisplayNameFromOptions(
              item,
              _.get(option, "expertise_type.child.choices")
            )
          );
        });
        return arr.join(",");
      },
    },
  },
  baseColumnsProcessor
);

export const initialUsersColumnsMapping = {
  company_type: {
    key: "company_type",
    fixed: "left",
    width: 120,
  },
  gender: {
    key: "gender",
    width: 120,
  },
  account_email: {
    key: "account_email",
    width: 250,
  },
  last_name: {
    key: "last_name",
    width: 120,
  },
  first_name: {
    key: "first_name",
    width: 120,
  },
  job_description: {
    key: "job_description",
    width: 180,
  },
  phone: {
    key: "phone",
    width: 150,
  },
  mobile_phone: {
    key: "mobile_phone",
    width: 150,
  },
  legal_title: {
    key: "legal_title",
    width: 170,
  },
  brand_name: {
    key: "brand_name",
    width: 170,
  },

  department: {
    key: "department",
  },
  role: {
    key: "role",
  },
  travel_sector: {
    key: "travel_sector",
    width: 170,
  },
  expertise_type: {
    key: "expertise_type",
    width: 170,
  },
  service_categories: {
    key: "service_categories",
    width: 170,
  },
  service_type: {
    key: "service_type",
    width: 170,
  },
  created: {
    key: "created",
    width: 170,
  },
  edited: {
    key: "edited",
    width: 170,
  },
  creator: {
    key: "creator",
    width: 170,
  },
  status: {
    key: "status",
    fixed: "right",
  },
};
