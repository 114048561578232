export const TRIP_TEMPLATES_UPDATE_FILTER_FORM =
  "TRIP_TEMPLATES_UPDATE_FILTER_FORM";
export const TRIP_TEMPLATES_INITIALIZE_LIST_FILTER_FORM =
  "TRIP_TEMPLATES_INITIALIZE_LIST_FILTER_FORM";
export const TRIP_TEMPLATES_RESET_LIST_FILTER_FORM =
  "TRIP_TEMPLATES_RESET_LIST_FILTER_FORM";
//===================================NEW======================================
export const TRIP_TEMPLATES_ONREQUEST_FETCH_LIST =
  "TRIP_TEMPLATES_ONREQUEST_FETCH_LIST";
export const TRIP_TEMPLATES_ONREQUEST_GENERIC_CHANGE =
  "TRIP_TEMPLATES_ONREQUEST_GENERIC_CHANGE";
