import {
  // ===================== PAYMENT METHODS MODAL =====================
  PAYMENT_METHODS_SHOW_ADD_METHODS_MODAL,
  PAYMENT_METHODS_HIDE_ADD_METHODS_MODAL,
  PAYMENT_METHODS_LOADING_ADD_METHODS_MODAL,
  PAYMENT_METHODS_IDLE_ADD_METHODS_MODAL,
  PAYMENT_METHODS_CHANGE_PAYMENT_METHODS,
  PAYMENT_METHODS_GET_PAYMENT_METHODS_LIST,
  PAYMENT_METHODS_BEGIN_LIST,
  PAYMENT_METHODS_END_LIST,
  PAYMENT_METHODS_APPLY_LIST_COLUMNS,
  PAYMENT_METHODS_SHOW_PAYMENT_MODAL,
  PAYMENT_METHODS_HIDE_PAYMENT_MODAL,
  PAYMENT_METHODS_LOADING_EDIT_METHODS_MODAL,
  PAYMENT_METHODS_IDLE_EDIT_METHODS_MODAL,
  PAYMENT_METHODS_GET_STRIPE_INIT_DATA,
  PAYMENT_METHODS_LOADING_GET_STRIPE_INIT_DATA,
  PAYMENT_METHODS_IDLE_GET_STRIPE_INIT_DATA,
} from "@src/actions/ManagementConsole/PaymentMethods/types";

import { entitiesListSelector } from "@src/reducers/tools";

import { initialPaymentColumnsSetUp } from "@src/forms/ManagementConsole/PaymentMethods";

import update from "immutability-helper";

export const paymentMethodsModalInitial = { show: false, loading: false };
export const paymentMethodsModal = (
  state = paymentMethodsModalInitial,
  action
) => {
  switch (action.type) {
    case PAYMENT_METHODS_SHOW_ADD_METHODS_MODAL:
      return update(state, { show: { $set: true } });
    case PAYMENT_METHODS_HIDE_ADD_METHODS_MODAL:
      return paymentMethodsModalInitial;
    case PAYMENT_METHODS_LOADING_ADD_METHODS_MODAL:
      return update(state, { loading: { $set: true } });
    case PAYMENT_METHODS_IDLE_ADD_METHODS_MODAL:
      return update(state, { loading: { $set: false } });
    default:
      return state;
  }
};

export const paymentMethodsEditModalInitial = { loading: false };
export const paymentMethodsEditModal = (
  state = paymentMethodsEditModalInitial,
  action
) => {
  switch (action.type) {
    case PAYMENT_METHODS_LOADING_EDIT_METHODS_MODAL:
      return update(state, { loading: { $set: true } });
    case PAYMENT_METHODS_IDLE_EDIT_METHODS_MODAL:
      return update(state, { loading: { $set: false } });
    default:
      return state;
  }
};

export const initialPaymentMethodsType = "card";
export const paymentMethodsType = (
  state = initialPaymentMethodsType,
  action
) => {
  switch (action.type) {
    case PAYMENT_METHODS_CHANGE_PAYMENT_METHODS:
      return action.data;
    default:
      return state;
  }
};

export const initialPaymentMessageModal = {
  id: "",
  loading: false,
  show: false,
  type: "set",
};
export const paymentMessageModal = (
  state = initialPaymentMessageModal,
  action
) => {
  switch (action.type) {
    case PAYMENT_METHODS_SHOW_PAYMENT_MODAL:
      return { ...state, show: true, type: action.modalType, id: action.id };
    case PAYMENT_METHODS_HIDE_PAYMENT_MODAL:
      return initialPaymentMessageModal;
    case PAYMENT_METHODS_LOADING_EDIT_METHODS_MODAL:
      return update(state, { loading: { $set: true } });
    case PAYMENT_METHODS_IDLE_EDIT_METHODS_MODAL:
      return update(state, { loading: { $set: false } });
    default:
      return state;
  }
};

export const initialPaymentMethodsList = {
  count: 0,
  next: null,
  previous: null,
  details: [],
  page: 1,
  limit: 20,
  displayLength: 100,
};

export const paymentMethodsList = (
  state = initialPaymentMethodsList,
  action
) => {
  switch (action.type) {
    case PAYMENT_METHODS_GET_PAYMENT_METHODS_LIST:
      return action.data;
    default:
      return state;
  }
};

export const initialPaymentMethodsListStatus = "IDLE";
export const paymentMethodsListStatus = (
  state = initialPaymentMethodsListStatus,
  action
) => {
  switch (action.type) {
    case PAYMENT_METHODS_BEGIN_LIST:
      return "FETCHING";
    case PAYMENT_METHODS_END_LIST:
      return "FETCHED";
    default:
      return state;
  }
};

// columns
export const paymentMethodsListColumns = (
  state = entitiesListSelector(initialPaymentColumnsSetUp),
  action
) => {
  switch (action.type) {
    case PAYMENT_METHODS_APPLY_LIST_COLUMNS:
      return entitiesListSelector(action.data);
    default:
      return state;
  }
};

export const paymentMethodsInitStripeInfo = (state = {}, action) => {
  switch (action.type) {
    case PAYMENT_METHODS_GET_STRIPE_INIT_DATA:
      return action.data;
    default:
      return state;
  }
};

export const initialPaymentMethodsInitStripeInfoStatus = { loading: true };
export const paymentMethodsInitStripeInfoStatus = (
  state = initialPaymentMethodsInitStripeInfoStatus,
  action
) => {
  switch (action.type) {
    case PAYMENT_METHODS_LOADING_GET_STRIPE_INIT_DATA:
      return update(state, { loading: { $set: true } });
    case PAYMENT_METHODS_IDLE_GET_STRIPE_INIT_DATA:
      return update(state, { loading: { $set: false } });
    default:
      return state;
  }
};
