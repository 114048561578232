import {
  //===================== DETAIL VIEW =============================
  ACCOMMODATION_RESERVATION_INIT,
  ACCOMMODATION_FETCH_DETAIL_VIEW,
  ACCOMMODATION_RESERVATION_DATA,
  //========================DASHBOARD===============================
  ACCOMMODATION_VIEW_TYPE,
  ACCOMMODATION_FETCH_GRAPH_DATA,
  ACCOMMODATION_BEGIN_FETCH_GRAPH_DATA,
  ACCOMMODATION_END_FETCH_GRAPH_DATA,
  ACCOMMODATION_INIT_GRAPH_TYPE,
  B2C_ACCOMMODATION_LIST_FILTER_CHANGE,
} from "@src/actions/Operation/Accommodation/types";

import update from "immutability-helper";
import { commonList } from "@src/reducers/common";

// ============================ LIST VIEW ============================

export const initialAccommodationsList = {
  ...commonList,
  displayLength: 100,
  filters: {
    with_financial: false,
    market_type: "B2C",
    reference: "",
    external_reference: "",
    source_entity_autocomplete: "",
    source_entity: "",
    accommodation_meta__name__icontains: "",
    destination_name__icontains: "",
    provider__icontains: "",
    provider_reference: "",
    supplier_reservation_reference__icontains: "",
    status: "",
    non_refundable: "",
    option_booking: "",
    accommodation_id: "",
    check_in_from: "",
    check_in_to: "",
    check_out_from: "",
    check_out_to: "",
    created__gte: "",
    created__lte: "",
    creator_email: "",
    supplier_reservation_reference: "",
    is_voucher_sent: "",
    room_leader_name__icontains: "",
    guest_pax: "",
    room_number: "",
    stay: "",
    page: 1,
    limit: 20,
    ordering: "",
    tags: "",
    provider: "",
  },
};
export const accommodationsList = (
  state = initialAccommodationsList,
  action
) => {
  switch (action.type) {
    case B2C_ACCOMMODATION_LIST_FILTER_CHANGE: {
      const { filters } = action;
      return update(state, { filters: { $set: filters } });
    }
    default:
      return state;
  }
};

//============================ DETAIL VIEW =============================
export const initialAccommodationNavStatus = "reservation";
export const AccommodationNavStatus = (
  state = initialAccommodationNavStatus,
  action
) => {
  switch (action.type) {
    case ACCOMMODATION_RESERVATION_INIT:
      return "reservation";
    case ACCOMMODATION_FETCH_DETAIL_VIEW:
      return action.data;
    default:
      return state;
  }
};

export const accommodationReservationInfo = (state = {}, action) => {
  switch (action.type) {
    case ACCOMMODATION_RESERVATION_INIT:
      return {};
    case ACCOMMODATION_RESERVATION_DATA:
      return action.data;
    default:
      return state;
  }
};

//====================================DASHBOARD===============================
export const initData = {
  type: "current",
  name: "SR",
};
export const accommodationViewType = (state = initData, action) => {
  switch (action.type) {
    case ACCOMMODATION_INIT_GRAPH_TYPE:
      return initData;
    case ACCOMMODATION_VIEW_TYPE:
      return action.data;
    default:
      return state;
  }
};

export const accommodationGraphData = (state = {}, action) => {
  switch (action.type) {
    case ACCOMMODATION_FETCH_GRAPH_DATA:
      return action.data;
    default:
      return state;
  }
};

export const initialAccommodationGraphDataStatus = "IDLE";
export const accommodationGraphDataStatus = (
  state = initialAccommodationGraphDataStatus,
  action
) => {
  switch (action.type) {
    case ACCOMMODATION_BEGIN_FETCH_GRAPH_DATA:
      return "FETCHING";
    case ACCOMMODATION_END_FETCH_GRAPH_DATA:
      return "FETCHED";
    default:
      return state;
  }
};

export * from "./B2B";
