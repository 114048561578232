// =========================== INIT ACTIONS ==========================
export const CUSTOM_FLIGHT_INIT = "CUSTOM_FLIGHT_INIT";
export const CUSTOM_FLIGHT_INIT_FROM_DATA = "CUSTOM_FLIGHT_INIT_FROM_DATA";
export const CUSTOM_FLIGHT_CHANGE_STOPS = "CUSTOM_FLIGHT_CHANGE_STOPS";

export const CUSTOM_FLIGHT_CHANGE_SEGMENTS_NUMBER =
  "CUSTOM_FLIGHT_CHANGE_SEGMENTS_NUMBER";
export const CUSTOM_FLIGHT_CHANGE_SEGMENT = "CUSTOM_FLIGHT_CHANGE_SEGMENT";
export const CUSTOM_FLIGHT_CHANGE_AIRPORT = "CUSTOM_FLIGHT_CHANGE_AIRPORT";
export const CUSTOM_FLIGHT_CHANGE_SEGMENT_DATETIME =
  "CUSTOM_FLIGHT_CHANGE_SEGMENT_DATETIME";
export const CUSTOM_FLIGHT_CHANGE_SEG_SIMPLE_DATA =
  "CUSTOM_FLIGHT_CHANGE_SEG_SIMPLE_DATA";
export const CUSTOM_FLIGHT_CHANGE_AIRLINE = "CUSTOM_FLIGHT_CHANGE_AIRLINE";
export const CUSTOM_FLIGHT_CHANGE_SEGMENT_AIRLINE =
  "CUSTOM_FLIGHT_CHANGE_SEGMENT_AIRLINE";
export const CUSTOM_FLIGHT_CHANGE_AIRCRAFT = "CUSTOM_FLIGHT_CHANGE_AIRCRAFT";
