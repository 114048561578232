//===================MODAL=====================
export const IMAGE_BANK_SHOW_UPLOAD_IMAGE_MODAL =
  "IMAGE_BANK_SHOW_UPLOAD_IMAGE_MODAL";
export const IMAGE_BANK_HIDE_UPLOAD_IMAGE_MODAL =
  "IMAGE_BANK_HIDE_UPLOAD_IMAGE_MODAL";
export const IMAGE_BANK_LOADING_UPLOAD_IMAGE_MODAL =
  "IMAGE_BANK_LOADING_UPLOAD_IMAGE_MODAL";
export const IMAGE_BANK_UPDATE_IMAGE = "IMAGE_BANK_UPDATE_IMAGE";
export const IMAGE_BANK_UPDATE_IMAGE_MODAL_TITLE =
  "IMAGE_BANK_UPDATE_IMAGE_MODAL_TITLE";
export const IMAGE_BANK_LOADED_UPLOAD_IMAGE_MODAL =
  "IMAGE_BANK_LOADED_UPLOAD_IMAGE_MODAL";
//===================LIST======================
export const IMAGE_BANK_LIST_SET_PAGE = "IMAGE_BANK_LIST_SET_PAGE";
export const IMAGE_BANK_SAVE_IMAGES_DATA = "IMAGE_BANK_SAVE_IMAGES_DATA";
export const IMAGE_BANK_LOADING_SAVE_IMAGES_DATA =
  "IMAGE_BANK_LOADING_SAVE_IMAGES_DATA";
export const IMAGE_BANK_LOADED_SAVE_IMAGES_DATA =
  "IMAGE_BANK_LOADED_SAVE_IMAGES_DATA";
export const IMAGE_BANK_SAVE_IMAGES_DETAIL = "IMAGE_BANK_SAVE_IMAGES_DETAIL";
//======================FILTERS================
export const IMAGE_BANK_INITIALIZE_LIST_FILTER_FORM =
  "IMAGE_BANK_INITIALIZE_LIST_FILTER_FORM";
export const IMAGE_BANK_UPDATE_LIST_FILTER_FORM =
  "IMAGE_BANK_UPDATE_LIST_FILTER_FORM";
export const IMAGE_BANK_APPLY_LIST_FILTER = "IMAGE_BANK_APPLY_LIST_FILTER";

//=======================NEW===================

export const IMAGE_BANK_FETCH_LIST = "IMAGE_BANK_FETCH_LIST";
export const IMAGE_BANK_LIST_GENERIC_CHANGE = "IMAGE_BANK_LIST_GENERIC_CHANGE";
