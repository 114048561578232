import React from "react";
import TransTxt from "@src/components/common/SxFormatMessage";
import _ from "lodash";
import { formatDate } from "@src/tools/date_tools";

export const initialBiddingFlowColumnsSetUp = {
  basic: {
    value: [
      "title",
      "requests",
      "in_process",
      "response",
      "expired",
      "status",
      "creator",
      "created",
      "edited",
    ],
    choices: [
      {
        display_name: <TransTxt id="tripplanlist_trip_title" startCase />,
        key: "title",
      },
      {
        display_name: <TransTxt id="tripplanlist_requests" startCase />,
        key: "requests",
      },
      {
        display_name: <TransTxt id="in_process" startCase />,
        key: "in_process",
      },
      {
        display_name: <TransTxt id="tripplanlist_response" startCase />,
        key: "response",
      },
      {
        display_name: <TransTxt id="expired" startCase />,
        key: "expired",
      },
      {
        display_name: <TransTxt id="creator" startCase />,
        key: "creator",
      },
      {
        display_name: <TransTxt id="created" startCase />,
        key: "created",
      },

      {
        display_name: <TransTxt id="edited" startCase />,
        key: "edited",
      },
      {
        display_name: <TransTxt id="status" startCase />,
        key: "status",
      },
    ],
  },
};

export const initialBiddingFlowColumnsProcessor = {
  title: {
    key: "title",
    getter: (data) => _.get(data, "itinerary_setup.title"),
  },
  requests: {
    key: "requests",
    getter: (data) => _.get(data, "bid_request_status_count.RQ"),
  },
  in_process: {
    key: "in_process",
    getter: (data) => _.get(data, "bid_request_status_count.IP"),
  },
  response: {
    key: "response",
    getter: (data) => _.get(data, "bid_request_status_count.RS"),
  },
  expired: {
    key: "expired",
    getter: (data) => _.get(data, "bid_request_status_count.EX"),
  },
  status: {
    key: "status",
    getter: (data) => "",
  },
  creator: {
    key: "creator",
    getter: () => "",
  },
  created: {
    key: "created",
    getter: (data) => formatDate(data.created),
  },
  edited: {
    key: "edited",
    getter: (data) => formatDate(data.edited),
  },
};

export const initialBiddingFlowColumnsMapping = {
  title: {
    key: "title",
  },
  requests: {
    key: "requests",
  },
  in_process: {
    key: "in_process",
  },
  response: {
    key: "response",
    getter: "",
  },
  expired: {
    key: "expired",
  },
  created: {
    key: "created",
  },
  edited: {
    key: "edited",
  },
  creator: {
    key: "creator",
  },
  status: {
    key: "status",
  },
};
