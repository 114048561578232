import _ from "lodash";
import {
  NormalInputField,
  NormalRangePicker,
  NormalSelectField,
} from "@src/components/forms";
import { variables } from "@src/jsssetup";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { Icon, IconButton } from "rsuite";
import { DateTime } from "luxon";
import {
  genericInfoGroupStyles,
  genericRsrvFeebackStyles,
  rsrvFeebackTableStyles,
} from "./styles";

export const cxlRuleTemplate = {
  from: "",
  to: "",
  type: "refundable",
  fee_type: "perc",
  fee: 0,
};

const cxlPolicyStyles = createUseStyles({
  ...genericInfoGroupStyles,
  ...rsrvFeebackTableStyles,
  CxlPolicy: genericRsrvFeebackStyles.card,
  header: genericRsrvFeebackStyles.cardHeader,
  body: genericRsrvFeebackStyles.cardBody,
  table: { width: "100%" },
  actionCell: {
    ...rsrvFeebackTableStyles.cell,
    "& .rs-btn": {
      marginRight: `calc(${variables.normal_gap} / 2)`,
    },
  },
  feeCell: {
    ...rsrvFeebackTableStyles.cell,
    "& input": {
      width: "5rem",
    },
  },
});
// const cxlPolicyStyles = createUseStyles({
//   CxlPolicy: cardStyles.card,
//   header: cardStyles.header,
//   table: tableStyles.table,
//   thead: tableStyles.head,
//   cell: tableStyles.cell,
//   actionCell: {
//     ...tableStyles.cell,
//     "& .rs-btn": {
//       marginRight: `calc(${variables.normal_gap} / 2)`,
//     },
//   },
//   feeCell: {
//     ...tableStyles.cell,
//     "& input": {
//       width: "5rem",
//     },
//   },
// });
export const CxlPolicies = ({ editable, rules, onChange }) => {
  const classes = cxlPolicyStyles();

  const [init, setInit] = useState([]);
  useEffect(() => {
    if (rules.length) {
      setInit(rules);
    }
  }, []);

  function dater(dt) {
    return DateTime.fromISO(dt).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
  }

  return init.length === 0 ? null : (
    <div className={classes.CxlPolicy}>
      <div className={classes.header}>
        <h5>Cancellation Policies</h5>
      </div>
      <Formik
        initialValues={{ cxl_policies: init }}
        validateOnMount={false}
        validate={(values) => {
          const tmp = _.cloneDeep(values);
          tmp.cxl_policies.forEach((cxl) => {
            cxl.valid = Object.entries(cxl).every((entry) => {
              if (["from", "to"].includes(entry[0])) {
                return Boolean(entry[1]);
              } else {
                return true;
              }
            });
          });

          onChange(tmp.cxl_policies);
        }}>
        {({ values, setValues }) => (
          <Form>
            <table className={classes.table}>
              <thead className={classes.head}>
                <tr>
                  <th>#</th>
                  <th>From - To</th>
                  <th>Type</th>
                  <th>Fee Type</th>
                  <th>Fee</th>
                  {editable && <th>Actions</th>}
                </tr>
              </thead>
              <tbody>
                {values.cxl_policies.map((cxl, idx) => {
                  const from = values.cxl_policies[idx].from;
                  const to = values.cxl_policies[idx].to;

                  return (
                    <tr key={idx}>
                      <td className={classes.cell}>{idx + 1}</td>
                      <td className={classes.cell}>
                        {editable ? (
                          <NormalRangePicker
                            from_key={`cxl_policies.${idx}.from`}
                            to_key={`cxl_policies.${idx}.to`}
                          />
                        ) : (
                          <span>
                            {from ? dater(from) : "N/A"} -{" "}
                            {to ? dater(to) : "N/A"}
                          </span>
                        )}
                      </td>
                      <td className={classes.cell}>
                        {editable ? (
                          <NormalSelectField
                            name={`cxl_policies.${idx}.type`}
                            options={[
                              ["refundable", "Refundable"],
                              ["chargeable", "Chargeable"],
                            ]}
                          />
                        ) : (
                          <span>
                            {_.startCase(values.cxl_policies[idx].type)}
                          </span>
                        )}
                      </td>
                      <td className={classes.cell}>
                        {editable ? (
                          <NormalSelectField
                            name={`cxl_policies.${idx}.fee_type`}
                            options={[
                              ["PERC", "Percentage"],
                              ["NUM", "Numerical"],
                            ]}
                          />
                        ) : (
                          <span>
                            {_.startCase(values.cxl_policies[idx].fee_type)}
                          </span>
                        )}
                      </td>
                      <td className={classes.feeCell}>
                        {editable ? (
                          <NormalInputField
                            name={`cxl_policies.${idx}.fee`}
                            type="number"
                          />
                        ) : (
                          <span>{values.cxl_policies[idx].fee}</span>
                        )}
                      </td>
                      {editable && (
                        <td className={classes.actionCell}>
                          {idx !== 0 && (
                            <IconButton
                              icon={<Icon icon="minus" />}
                              size="xs"
                              circle
                              style={{
                                background: "white",
                                color: `${variables.colors.easy.orange}`,
                                border: `1px solid ${variables.colors.easy.orange}`,
                              }}
                              onClick={() => {
                                const cxl_policies = [...values.cxl_policies];
                                cxl_policies.splice(idx, 1);
                                setValues({ cxl_policies });
                              }}
                            />
                          )}
                          <IconButton
                            icon={<Icon icon="plus" />}
                            size="xs"
                            style={{
                              background: `${variables.colors.easy.orange}`,
                              color: "white",
                            }}
                            circle
                            onClick={() =>
                              setValues({
                                cxl_policies: [
                                  ...values.cxl_policies,
                                  { ...cxlRuleTemplate },
                                ],
                              })
                            }
                          />
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {/* <table className={classes.table}>
              <thead className={classes.thead}>
                <tr>
                  <th>#</th>
                  <th>From - To</th>
                  <th>Type</th>
                  <th>Fee Type</th>
                  <th>Fee</th>
                  {editable && <th>Actions</th>}
                </tr>
              </thead>
              <tbody>
                {values.cxl_policies.map((cxl, idx) => {
                  const from = values.cxl_policies[idx].from;
                  const to = values.cxl_policies[idx].to;

                  return (
                    <tr key={idx}>
                      <td className={classes.cell}>{idx + 1}</td>
                      <td className={classes.cell}>
                        {editable ? (
                          <NormalRangePicker
                            from_key={`cxl_policies.${idx}.from`}
                            to_key={`cxl_policies.${idx}.to`}
                          />
                        ) : (
                          <span>
                            {from ? dater(from) : "N/A"} -{" "}
                            {to ? dater(to) : "N/A"}
                          </span>
                        )}
                      </td>
                      <td className={classes.cell}>
                        {editable ? (
                          <NormalSelectField
                            name={`cxl_policies.${idx}.type`}
                            options={[
                              ["refundable", "Refundable"],
                              ["chargeable", "Chargeable"],
                            ]}
                          />
                        ) : (
                          <span>
                            {_.startCase(values.cxl_policies[idx].type)}
                          </span>
                        )}
                      </td>
                      <td className={classes.cell}>
                        {editable ? (
                          <NormalSelectField
                            name={`cxl_policies.${idx}.fee_type`}
                            options={[
                              ["PERC", "Percentage"],
                              ["NUM", "Numerical"],
                            ]}
                          />
                        ) : (
                          <span>
                            {_.startCase(values.cxl_policies[idx].fee_type)}
                          </span>
                        )}
                      </td>
                      <td className={classes.feeCell}>
                        {editable ? (
                          <NormalInputField
                            name={`cxl_policies.${idx}.fee`}
                            type="number"
                          />
                        ) : (
                          <span>{values.cxl_policies[idx].fee}</span>
                        )}
                      </td>
                      {editable && (
                        <td className={classes.actionCell}>
                          {idx !== 0 && (
                            <IconButton
                              icon={<Icon icon="minus" />}
                              size="xs"
                              circle
                              color="red"
                              onClick={() => {
                                const cxl_policies = [...values.cxl_policies];
                                cxl_policies.splice(idx, 1);
                                setValues({ cxl_policies });
                              }}
                            />
                          )}
                          <IconButton
                            icon={<Icon icon="plus" />}
                            size="xs"
                            circle
                            color="green"
                            onClick={() =>
                              setValues({
                                cxl_policies: [
                                  ...values.cxl_policies,
                                  { ...cxlRuleTemplate },
                                ],
                              })
                            }
                          />
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table> */}
          </Form>
        )}
      </Formik>
    </div>
  );
};
CxlPolicies.propTypes = {
  rules: PropTypes.array.isRequired,
  editable: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
};
