import _ from "lodash";

import { getTripDaysSelector } from "@src/selectors/Project/TripPlanner/day_by_day_selectors";
import {
  TRIPPLANNER_MKT_CONTENT_CHANGE_COLOR_PALETTE,
  TRIPPLANNER_MKT_CONTENT_MODAL_HIDE,
  TRIPPLANNER_MKT_CONTENT_MODAL_IDLE,
  TRIPPLANNER_MKT_CONTENT_MODAL_LOADING,
  TRIPPLANNER_MKT_CONTENT_MODAL_MODE_TOGGLE,
  TRIPPLANNER_MKT_CONTENT_MODAL_SHOW,
  TRIPPLANNER_MKT_CONTENT_MODAL_CHANGE_TEMPLATE,
  TRIPPLANNER_MKT_CXL_CHANGE,
  TRIPPLANNER_MKT_DIST_PERIOD_ADD,
  TRIPPLANNER_MKT_DIST_PERIOD_CHANGE,
  TRIPPLANNER_MKT_DIST_PERIOD_COPY,
  TRIPPLANNER_MKT_DIST_PERIOD_REMOVE,
  TRIPPLANNER_MKT_INCEXC_ADD,
  TRIPPLANNER_MKT_INCEXC_CHANGE,
  TRIPPLANNER_MKT_INCEXC_REMOVE,
  TRIPPLANNER_MKT_STOP_SALES_ADD,
  TRIPPLANNER_MKT_STOP_SALES_CHANGE,
  TRIPPLANNER_MKT_STOP_SALES_REMOVE,
  TRIPPLANNER_MKT_DIST_SETUP_CHANGE,
  TRIPPLANNER_MKT_CONTENT_MODAL_CHANGE_DATA_CONTENT,
  TRIPPLANNER_MKT_CONTENT_MODAL_SIMPLE_HIDE,
  TRIPPLANNER_MKT_CONTENT_INIT,
  TRIPPLANNER_DBD_CONTENT_CHANGE,
} from "./types/marketplace_types";
import { TRIPPLANNER_PKG_METADATA_CHANGE } from "@src/reducers/Project/TripPlanner";

export const addDistPeriod = () => {
  return { type: TRIPPLANNER_MKT_DIST_PERIOD_ADD };
};

export const removeDistPeriod = (idx) => {
  return { type: TRIPPLANNER_MKT_DIST_PERIOD_REMOVE, idx };
};

export const changeDistPeriod = (idx, key, value) => {
  return { type: TRIPPLANNER_MKT_DIST_PERIOD_CHANGE, idx, key, value };
};

export const copyDistPeriod = (idx) => {
  return { type: TRIPPLANNER_MKT_DIST_PERIOD_COPY, idx };
};

export const addStopSales = () => {
  return { type: TRIPPLANNER_MKT_STOP_SALES_ADD };
};

export const removeStopSales = (idx) => {
  return { type: TRIPPLANNER_MKT_STOP_SALES_REMOVE, idx };
};

export const changeStopSales = (idx, key, value) => {
  return { type: TRIPPLANNER_MKT_STOP_SALES_CHANGE, idx, key, value };
};

export const addIncEncRule = (ruleType) => {
  return { type: TRIPPLANNER_MKT_INCEXC_ADD, ruleType };
};

export const removeIncEncRule = (ruleType, idx) => {
  return { type: TRIPPLANNER_MKT_INCEXC_REMOVE, ruleType, idx };
};

export const changeIncEncRule = (ruleType, idx, key, value) => {
  return { type: TRIPPLANNER_MKT_INCEXC_CHANGE, ruleType, idx, key, value };
};

export const changeCxlPolicy = (idx, key, value) => {
  return { type: TRIPPLANNER_MKT_CXL_CHANGE, idx, key, value };
};

// ======================== DISTRIBUTION SETUP =======================
export const distributionSetupChange = (key, value) => {
  return { type: TRIPPLANNER_MKT_DIST_SETUP_CHANGE, key, value };
};
// =========================== PKG CONTENT ===========================
const initPkgContent = () => (dispatch, getState) => {
  const days = getTripDaysSelector(getState());
  const itnDays = {};
  days.forEach(
    (day) => (itnDays[day.day] = { description: { en: "" }, images: [] })
  );

  const content = {
    itineraryDaysData: itnDays,
    pkgTitle: { en: "" },
    pkgSubTitle: { en: "" },
    pkgStyle: { en: "" },
    generalDescription: { en: "" },
  };

  dispatch({ type: TRIPPLANNER_MKT_CONTENT_INIT, content });
};

export const showPkgContentModal = () => async (dispatch, getState) => {
  if (_.isEmpty(getState().tripPlannerMktPkgContent)) {
    await dispatch(initPkgContent());
  }

  dispatch({ type: TRIPPLANNER_MKT_CONTENT_MODAL_SHOW });
};

export const hidePkgContentModal = (content) => {
  return { type: TRIPPLANNER_MKT_CONTENT_MODAL_HIDE, content };
};

export const simpleHidePkgContentModal = () => {
  return { type: TRIPPLANNER_MKT_CONTENT_MODAL_SIMPLE_HIDE };
};

export const loadingPkgContentModal = () => {
  return { type: TRIPPLANNER_MKT_CONTENT_MODAL_LOADING };
};

export const idlePkgContentModal = () => {
  return { type: TRIPPLANNER_MKT_CONTENT_MODAL_IDLE };
};

export const togglePkgContentMode = () => {
  return { type: TRIPPLANNER_MKT_CONTENT_MODAL_MODE_TOGGLE };
};

export const changePkgColorPalette = (color) => {
  return { type: TRIPPLANNER_MKT_CONTENT_CHANGE_COLOR_PALETTE, color };
};

export const changePkgTemplate = (template) => {
  return { type: TRIPPLANNER_MKT_CONTENT_MODAL_CHANGE_TEMPLATE, template };
};

export const changePkgDataContent = (content) => {
  return { type: TRIPPLANNER_MKT_CONTENT_MODAL_CHANGE_DATA_CONTENT, content };
};

// ========================= metadata actions ========================
export const changePkgMetadata = ({ order, themes, rating }) => {
  return { type: TRIPPLANNER_PKG_METADATA_CHANGE, order, themes, rating };
};

export const tripPlannerDBDCntChangeAction = (days) => {
  return { type: TRIPPLANNER_DBD_CONTENT_CHANGE, days };
};

export const tripPlannerDBDCntResetAction = () => {
  return { type: TRIPPLANNER_DBD_CONTENT_CHANGE, days: [] };
};
