import _ from "lodash";
import moment from "moment";

export const createCMSB64Id = (type, id) => {
  return window.btoa(`lv${type.split("lvl")[1]}__${id}`);
};

export const queryProcessor = (query) => {
  if (query.includes("__")) {
    query = query.split("__")[1];
  }
  return query;
};

// ============================ VALIDATORS ===========================

export const validateFirstStep = (tripSetup, originData, destinations) => {
  const { requiredServices } = tripSetup;
  const transportationRequired = requiredServices.includes("TR");

  var msg = [];

  const titleValid = tripSetup.title !== "";
  if (!titleValid) {
    msg.push("Please add a trip title.");
  }

  const requiredServicesValid = requiredServices.length > 0;
  if (!requiredServicesValid) {
    msg.push("At least on service must be selected.");
  }

  const nationalityValid = tripSetup.nationality !== "";
  if (!nationalityValid) {
    msg.push("Please select the travellers nationality.");
  }

  var originValid = true;
  if (transportationRequired) {
    if (originData.destination.id == null) {
      originValid = false;
      msg.push("Please choose a valid Origin.");
    }

    if (moment(originData.date).isBefore(moment(), "day")) {
      originValid = false;
      msg.push("Please choose a valid Trip start date.");
    }
  }

  var destsValid = true;
  if (destinations.length == 0) {
    destsValid = false;
    msg.push("Please add at least one destination");
  }

  if (destinations.some((d) => d.id == null)) {
    destsValid = false;
    msg.push(
      [
        "All destinations must have valid data.",
        "Please fill all fields for all destinations.",
      ].join(" ")
    );
  }

  if (
    !_.zip(
      destinations.map((d) => d.id),
      destinations.slice(1).map((d) => d.id)
    )
      .filter((c) => c.every((e) => typeof e !== "undefined"))
      .every((c) => [...new Set(c)].length == 2)
  ) {
    destsValid = false;
    msg.push(
      [
        "Your itinerary is not valid.",
        "Please remove or change the order of any neighboring destinations.",
      ].join(" ")
    );
  }

  if (
    !titleValid ||
    !originValid ||
    !destsValid ||
    !requiredServicesValid ||
    !nationalityValid
  ) {
    return { isValid: false, msg };
  }

  return { isValid: true, msg: [] };
};

export const validateAccPref = (pax, prefPax) => {
  const msg = [];
  const roomAdults = prefPax.reduce((a, b) => a + b.adults, 0);
  const roomChildren = prefPax.reduce((a, b) => a + b.children, 0);

  if (pax.adults !== roomAdults) {
    msg.push("Please correct your room preferences");

    if (pax.adults < roomAdults) {
      msg.push(
        `You have ${
          roomAdults - pax.adults
        } adults more in your Accommodation preferences.`
      );
    } else if (pax.adults > roomAdults) {
      msg.push(
        `You have ${
          pax.adults - roomAdults
        } adults unused in your Accommodation preferences.`
      );
    }
  }

  if (pax.children !== roomChildren) {
    msg.push("Please correct your room preferences");

    if (pax.children < roomChildren) {
      msg.push(
        `You have ${
          roomChildren - pax.children
        } children more in your Accommodation preferences.`
      );
    } else if (pax.children > roomChildren) {
      msg.push(
        `You have ${
          pax.children - roomChildren
        } children unused in your Accommodation preferences.`
      );
    }
  }

  return { isValid: msg.length == 0, msg };
};

export const validateSecondStep = (
  pax,
  accPrefPax,
  requiredServices,
  trpEditMode,
  accEditMode,
  trfEditMode
) => {
  var msg = [];
  var trValid = true;
  var accValid = true;
  var trfValid = true;

  if (requiredServices.includes("TR") && trpEditMode) {
    msg.push("Please confirm your Transportation Preferences");
    trValid = false;
  }

  if (requiredServices.includes("ACC") && accEditMode) {
    msg.push("Please confirm your Accommodation Preferences");
    accValid = false;
  }

  if (requiredServices.includes("TR") && trfEditMode) {
    msg.push("Please confirm your Transfer Preferences");
    trfValid = false;
  }

  if (!trValid || !accValid) {
    return { isValid: false, msg };
  }

  return { isValid: true, msg: [] };
};
