export const BILL_AND_PAYMENTS_LIST_DATA = "BILL_AND_PAYMENTS_LIST_DATA";
export const BILL_AND_PAYMENTS_BEGIN_LIST = "BILL_AND_PAYMENTS_BEGIN_LIST";
export const BILL_AND_PAYMENTS_END_LIST = "BILL_AND_PAYMENTS_END_LIST";
export const BILL_AND_PAYMENTS_APPLY_LIST_COLUMNS =
  "BILL_AND_PAYMENTS_APPLY_LIST_COLUMNS";
export const BILL_AND_PAYMENTS_VIEW_TYPE = "BILL_AND_PAYMENTS_VIEW_TYPE";
export const BILL_AND_PAYMENTS_STAT_DATA = "BILL_AND_PAYMENTS_STAT_DATA";
export const BILL_AND_PAYMENTS_TAG_TYPE = "BILL_AND_PAYMENTS_TAG_TYPE";
export const BILL_AND_PAYMENTS_INIT_VIEW_TYPE =
  "BILL_AND_PAYMENTS_INIT_VIEW_TYPE";
export const BILL_AND_PAYMENTS_INIT_TAG_TYPE =
  "BILL_AND_PAYMENTS_INIT_TAG_TYPE";
