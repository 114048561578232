/**
 * @fileOverview A module containing commong structures for various reducer types.
 * @name common.js
 * @author Filodamos Papanatsios
 */

export const commonList = {
  count: 0,
  next: null,
  previous: null,
  results: [],
  page: 1,
  limit: 20,
  loading: false,
  order_by: null,
};
