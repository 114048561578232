import { NETWORK_MEMBERS_LIST_GENERIC_CHANGE } from "@src/actions/Network/Members/NewVersion/types";
import { commonList } from "@src/reducers/common";

const NetworkMembersListReducerInitial = {
  ...commonList,
  displayLength: 100,
  filters: {
    business_address_country_code__in: "",
    entity_type__contains: "",
    expertise_type__contains: "",
    main_service: "",
    business_address_city__icontains: "",
    legal_title__icontains: "",
    connection_status: "UC",
    page: 1,
    limit: 20,
    ordering: "",
  },
};
export const NetworkMembersListReducer = (
  state = NetworkMembersListReducerInitial,
  action
) => {
  switch (action.type) {
    case NETWORK_MEMBERS_LIST_GENERIC_CHANGE: {
      const { filters } = action;
      return { ...state, filters: { ...state.filters, ...filters } };
    }
    default:
      return state;
  }
};
