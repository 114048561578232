import _ from "lodash";
import { humanizeDuration } from "@src/tools/date_tools";
import moment from "moment";

export const getItinerary = (data) => {
  if (!_.isEmpty(data?.options ?? [])) {
    return [
      ...new Set(
        _.flatten(
          ((data?.options ?? []).find((o) => o.selected)?.segments ?? []).map(
            (s) => [s.origin_airport.iata, s.destination_airport.iata]
          )
        )
      ),
    ].join(" - ");
  }
  return null;
};

export const getDuration = (data) => {
  if (!_.isEmpty(data?.options ?? [])) {
    return humanizeDuration(
      ((data?.options ?? []).find((o) => o.selected)?.segments ?? []).reduce(
        (a, b) => a + (b.duration + b?.transfer_time ?? 0),
        0
      ),
      { abbrev: true }
    );
  }
  return null;
};

export const getLayover = (data) => {
  if (!_.isEmpty(data?.options ?? [])) {
    return humanizeDuration(
      ((data?.options ?? []).find((o) => o.selected)?.segments ?? []).reduce(
        (a, b) => a + b?.transfer_time ?? 0,
        0
      ),
      { abbrev: true }
    );
  }
  return null;
};

export const getDeparture = (data) => {
  if (!_.isEmpty(data?.options ?? [])) {
    return moment
      .parseZone(
        (data?.options ?? []).find((o) => o.selected)?.segments?.[0]?.departure
      )
      .format("DD/MM/YYYY HH:mm");
  }
  return null;
};

export const getArrival = (data) => {
  if (!_.isEmpty(data?.options ?? [])) {
    return moment
      .parseZone(
        _.last((data?.options ?? []).find((o) => o.selected)?.segments ?? [])
          ?.arrival
      )
      .format("DD/MM/YYYY HH:mm");
  }
  return null;
};

export const flightTitle = (service) => {
  if (!_.isEmpty(service?.options ?? [])) {
    const origin_airport = (service?.options ?? []).find((o) => o.selected)
      ?.segments?.[0]?.origin_airport;
    const destination_airport = _.last(
      (service?.options ?? []).find((o) => o.selected)?.segments
    )?.destination_airport;

    return [
      `(${origin_airport?.iata}) ${origin_airport?.name}`,
      `to (${destination_airport?.iata}) ${destination_airport?.name}`,
    ].join(" ");
  }
  return null;
};
