import _ from "lodash";
import { CustomButton } from "@src/components/common/buttons";
import { NormalInputField } from "@src/components/forms";
import { cardStyles, tableStyles, variables } from "@src/jsssetup";
import { saveHighlightsAction } from "@src/reducers/Project/TripPlanner";
import { Form, Formik, useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { createUseStyles } from "react-jss";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "rsuite";
import { saveMktTrip } from "@src/actions/Project/TripPlanner";
import { toast } from "react-toastify";

const AddHighlightBtn = () => {
  const { values, setFieldValue } = useFormikContext();
  return (
    <CustomButton
      appearance="primary"
      onClick={() =>
        setFieldValue("highlights", [
          ...values.highlights,
          { description_en: "" },
        ])
      }>
      Add Highlight
    </CustomButton>
  );
};

const RemoveHighlightBtn = ({ idx }) => {
  const { values, setFieldValue } = useFormikContext();
  return (
    <CustomButton
      appearance="ghost"
      onClick={() =>
        setFieldValue(
          "highlights",
          values.highlights.filter((_, i) => i !== idx)
        )
      }>
      Remove
    </CustomButton>
  );
};
RemoveHighlightBtn.propTypes = {
  idx: PropTypes.number.isRequired,
};

const highlightsSectionStyles = createUseStyles({
  ...cardStyles,
  HighlightsSection: cardStyles.card,
  hightLightRowForm: {
    display: "grid",
    gridTemplateColumns: "max-content 1fr max-content",
    gridGap: variables.normal_gap,
    alignItems: "end",
  },
  table: tableStyles.table,
  thead: tableStyles.head,
  cell: tableStyles.cell,
  headerActions: {
    display: "grid",
    gridAutoFlow: "column",
    gridGap: variables.half_gap,
  },
});
const HighlightsSection = () => {
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const classes = highlightsSectionStyles({ editMode });

  const highlights = useSelector((state) => state.tripPlannerMktHighlights);

  const dispatch = useDispatch();
  const onSubmit = useCallback(
    async (highlights) => {
      setIsLoading(true);
      await dispatch(
        saveHighlightsAction(
          _.cloneDeep(highlights.filter((h) => h.description_en))
        )
      );
      const success = await dispatch(saveMktTrip());
      if (success) {
        toast.success("Highlights saved successfully", {
          autoClose: 3000,
        });
      }

      setEditMode(false);
      setIsLoading(false);
    },
    [dispatch]
  );

  return (
    <Formik
      initialValues={{ highlights }}
      enable
      enableReinitialize={true}
      onSubmit={(values) => onSubmit(values.highlights)}>
      {({ values, submitForm }) => (
        <div className={classes.HighlightsSection}>
          {isLoading && <Loader backdrop center size="lg" />}
          <div className={classes.header}>
            <h5>Highlights</h5>
            <div className={classes.headerActions}>
              <CustomButton
                appearance="ghost"
                onClick={() => (editMode ? submitForm() : setEditMode(true))}>
                {editMode ? "Done" : "Edit"}
              </CustomButton>
              {editMode && <AddHighlightBtn />}
            </div>
          </div>
          <div className={classes.body}>
            {editMode ? (
              <Form>
                {values.highlights.map((highlight, idx) => (
                  <div className={classes.hightLightRowForm}>
                    <span>
                      <strong>{idx + 1}.</strong>
                    </span>
                    <NormalInputField
                      name={`highlights.${idx}.description_en`}
                      label="Description"
                    />
                    <RemoveHighlightBtn idx={idx} />
                  </div>
                ))}
              </Form>
            ) : (
              <table className={classes.table}>
                <thead className={classes.thead}>
                  <tr>
                    <th>#</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {highlights.map((highlight, idx) => (
                    <tr>
                      <td className={classes.cell}>{idx + 1}</td>
                      <td className={classes.cell}>
                        {highlight.description_en}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      )}
    </Formik>
  );
};
HighlightsSection.propTypes = {};
export default HighlightsSection;
