export const SUBAGENTS_FETCH_DATA = "SUBAGENTS_FETCH_DATA";
export const SUBAGENTS_BEGIN_FETCH_DATA = "SUBAGENTS_BEGIN_FETCH_DATA";
export const SUBAGENTS_END_FETCH_DATA = "SUBAGENTS_END_FETCH_DATA";
export const SUBAGENTS_INITIALIZE_LIST_FILTER_FORM =
  "SUBAGENTS_INITIALIZE_LIST_FILTER_FORM";
export const SUBAGENTS_UPDATE_LIST_FILTER_FORM =
  "SUBAGENTS_UPDATE_LIST_FILTER_FORM";
export const SUBAGENTS_APPLY_LIST_FILTER = "SUBAGENTS_APPLY_LIST_FILTER";
export const SUBAGENTS_UPDATE_LIST_COLUMNS_FORM =
  "SUBAGENTS_UPDATE_LIST_COLUMNS_FORM";
export const SUBAGENTS_APPLY_LIST_COLUMNS = "SUBAGENTS_APPLY_LIST_COLUMNS";
export const SUBAGENTS_INITIALIZE_LIST_COLUMNS_FORM =
  "SUBAGENTS_INITIALIZE_LIST_COLUMNS_FORM";
export const SUBAGENTS_FETCH_OPTIONS = "SUBAGENTS_FETCH_OPTIONS";
export const SUBAGENTS_INITIALIZE_BASE_DATA_EDIT_FORM =
  "SUBAGENTS_INITIALIZE_BASE_DATA_EDIT_FORM";
export const SUBAGENTS_HIDE_BASE_DATA_EDIT_FORM =
  "SUBAGENTS_HIDE_BASE_DATA_EDIT_FORM";
export const SUBAGENTS_SHOW_BASE_DATA_EDIT_FORM =
  "SUBAGENTS_SHOW_BASE_DATA_EDIT_FORM";
export const SUBAGENTS_VALIDATE_BASE_DATA_EDIT_FORM =
  "SUBAGENTS_VALIDATE_BASE_DATA_EDIT_FORM";
export const SUBAGENTS_PROFILE_DATA = "SUBAGENTS_PROFILE_DATA";
export const SUBAGENTS_UPLOADING_BASE_DATA_EDIT_FORM =
  "SUBAGENTS_UPLOADING_BASE_DATA_EDIT_FORM";
export const SUBAGENTS_IDLE_BASE_DATA_EDIT_FORM =
  "SUBAGENTS_IDLE_BASE_DATA_EDIT_FORM";
export const SUBAGENTS_EDIT_BASE_DATA = "SUBAGENTS_EDIT_BASE_DATA";
export const SUBAGENTS_CLEAN_BASE_EDIT_FORM_ERRORS =
  "SUBAGENTS_CLEAN_BASE_EDIT_FORM_ERRORS";
export const SUBAGENTS_CLEAN_BASE_DATA_EDIT_FORM =
  "SUBAGENTS_CLEAN_BASE_DATA_EDIT_FORM";
export const SUBAGENTS_VIEW_NATURE_OF_BIZ = "SUBAGENTS_VIEW_NATURE_OF_BIZ";
export const SUBAGENTS_SUBMIT_NATURE_OF_BIZ_DATA =
  "SUBAGENTS_SUBMIT_NATURE_OF_BIZ_DATA";
export const SUBAGENTS_EDIT_NATURE_OF_BIZ_DATA =
  "SUBAGENTS_EDIT_NATURE_OF_BIZ_DATA";
export const SUBAGENTS_INITIALIZE_NATURE_DATA_FORM =
  "SUBAGENTS_INITIALIZE_NATURE_DATA_FORM";
export const SUBAGENTS_EDIT_NATURE_OF_BIZ = "SUBAGENTS_EDIT_NATURE_OF_BIZ";
export const SUBAGENTS_EDIT_LICENSE_CODE = "SUBAGENTS_EDIT_LICENSE_CODE";
export const SUBAGENTS_TOGGLE_POSTAL_ADDRESS =
  "SUBAGENTS_TOGGLE_POSTAL_ADDRESS";
export const SUBAGENTS_RESET_LIST_FILTER_FORM =
  "SUBAGENTS_RESET_LIST_FILTER_FORM";

// ======================== Service categories =======================

export const SUBAGENTS_SELECT_SERVICE_CATEGORY =
  "SUBAGENTS_SELECT_SERVICE_CATEGORY";
export const SUBAGENTS_SELECT_TRAVEL_SERVICE =
  "SUBAGENTS_SELECT_TRAVEL_SERVICE";
export const SUBAGENTS_INITIALIZE_TRAVEL_SERVICE_FORM =
  "SUBAGENTS_INITIALIZE_TRAVEL_SERVICE_FORM";

export const SUBAGENTS_VIEW_SERVICE_CATEGORIES_MODE =
  "SUBAGENTS_VIEW_SERVICE_CATEGORIES_MODE";
export const SUBAGENTS_EDIT_SERVICE_CATEGORIES_MODE =
  "SUBAGENTS_EDIT_SERVICE_CATEGORIES_MODE";
export const SUBAGENTS_LOADING_SERVICE_CATEGORY_MODE =
  "SUBAGENTS_LOADING_SERVICE_CATEGORY_MODE";
export const SUBAGENTS_INITIALIZE_MAIN_SERVICES_FORM =
  "SUBAGENTS_INITIALIZE_MAIN_SERVICES_FORM";
export const SUBAGENTS_UPDATE_MAIN_SERVICES_FORM =
  "SUBAGENTS_UPDATE_MAIN_SERVICES_FORM";

//img relation
export const SUBAGENTS_UPLOAD_PREFERENCE_IMAGE =
  "SUBAGENTS_UPLOAD_PREFERENCE_IMAGE";
export const SUBAGENTS_FETCH_ADDRESS_POINT = "SUBAGENTS_FETCH_ADDRESS_POINT";
export const SUBAGENTS_INITIAL_ADDRESS_POINT =
  "SUBAGENTS_INITIAL_ADDRESS_POINT";
export const SUBAGENTS_TOGGLE_VERIFY_ADDRESS =
  "SUBAGENTS_TOGGLE_VERIFY_ADDRESS";
export const SUBAGENTS_BEGIN_FETCH_ADDRESS_DATA =
  "SUBAGENTS_BEGIN_FETCH_ADDRESS_DATA";
export const SUBAGENTS_END_FETCH_ADDRESS_DATA =
  "SUBAGENTS_END_FETCH_ADDRESS_DATA";
export const SUBAGENTS_BEGIN_FETCH_PROFILE_DATA =
  "SUBAGENTS_BEGIN_FETCH_PROFILE_DATA";
export const SUBAGENTS_END_FETCH_PROFILE_DATA =
  "SUBAGENTS_END_FETCH_PROFILE_DATA";
export const SUBAGENTS_CLOSE_VERIFY_ADDRESS = "SUBAGENTS_CLOSE_VERIFY_ADDRESS";
export const SUBAGENTS_CLEAN_ADDRESS_POINT = "SUBAGENTS_CLEAN_ADDRESS_POINT";
export const SUBAGENTS_INITIAL_VERIFY_STATUS =
  "SUBAGENTS_INITIAL_VERIFY_STATUS";

//======================= Email Config Section==========================
export const SUBAGENTS_VIEW_EMAIL_CONFIG_MODE =
  "SUBAGENTS_VIEW_EMAIL_CONFIG_MODE";
export const SUBAGENTS_EDIT_EMAIL_CONFIG_MODE =
  "SUBAGENTS_EDIT_EMAIL_CONFIG_MODE";
export const SUBAGENTS_LOADING_EMAIL_CONFIG_MODE =
  "SUBAGENTS_LOADING_EMAIL_CONFIG_MODE";
export const SUBAGENTS_EDIT_EMAIL_CONFIG_FORM =
  "SUBAGENTS_EDIT_EMAIL_CONFIG_FORM";
export const SUBAGENTS_INITIALIZE_EMAIL_CONFIG_FORM =
  "SUBAGENTS_INITIALIZE_EMAIL_CONFIG_FORM";
