import {
  INVITATIONS_RECEIVE_LOADING_LIST,
  INVITATIONS_RECEIVE_FETCH_LIST_DATA,
  INVITATIONS_RECEIVE_IDLE_LIST,
  INVITATIONS_RECEIVE_INITIALIZE_LIST_FILTER_FORM,
  INVITATIONS_RECEIVE_UPDATE_LIST_FILTER_FORM,
  INVITATIONS_RECEIVE_APPLY_LIST_FILTER,
  INVITATIONS_RECEIVE_RESET_LIST_FILTER_FORM,
  INVITATIONS_RECEIVE_VIEW_TYPE_CHANGE,
  INVITATIONS_RECEIVE_APPLY_LIST_COLUMNS_FORM,
  INVITATIONS_RECEIVE_HIDE_VIEW_MODAL,
  INVITATIONS_RECEIVE_SHOW_VIEW_MODAL,
  INVITATIONS_RECEIVE_SHOW_ACCEPT_INVITATION_MODAL,
  INVITATIONS_RECEIVE_HIDE_ACCEPT_INVITATION_MODAL,
  INVITATIONS_RECEIVE_OPEN_ACCEPT_INVITATION_MODAL,
  INVITATIONS_RECEIVE_BEGIN_INVITATIONS_STATUS,
  INVITATIONS_RECEIVE_END_INVITATIONS_STATUS,
  INVITATIONS_RECEIVE_MESSAGE_DETAIL,
  INVITATIONS_RECEIVE_OPEN_VIEW_MODAL,
} from "@src/actions/Network/Invitations/types";

import {
  initialMembersFilterForm,
  initialMembersColumnsSetUp,
} from "@src/forms/Network/Members";

import { formProcessor, entitiesListSelector } from "@src/reducers/tools";

import update from "immutability-helper";
export const invitationsListFilter = (
  state = initialMembersFilterForm,
  action
) => {
  switch (action.type) {
    case INVITATIONS_RECEIVE_INITIALIZE_LIST_FILTER_FORM:
      return action.data;
    case INVITATIONS_RECEIVE_UPDATE_LIST_FILTER_FORM:
      return formProcessor(
        state,
        action.data.type,
        action.data.name,
        action.data.value
      );
    case INVITATIONS_RECEIVE_APPLY_LIST_FILTER:
      return action.data;
    case INVITATIONS_RECEIVE_RESET_LIST_FILTER_FORM:
      return action.data;
    default:
      return state;
  }
};

export const initialList = {
  count: 0,
  next: null,
  previous: null,
  results: [],
  page: 1,
  limit: 6,
  displayLength: 100,
  loading: false,
};

export const fetchInvitationsListData = (state = initialList, action) => {
  switch (action.type) {
    case INVITATIONS_RECEIVE_FETCH_LIST_DATA:
      return { ...state, ...action.data };
    case INVITATIONS_RECEIVE_LOADING_LIST:
      return { ...state, loading: true };
    case INVITATIONS_RECEIVE_IDLE_LIST:
      return { ...state, loading: false };
    case INVITATIONS_RECEIVE_RESET_LIST_FILTER_FORM:
      return { ...state, page: 1 };
    default:
      return state;
  }
};

export const initialChangeInvitationsViewModal = false;
export const changeInvitationsViewModal = (
  state = initialChangeInvitationsViewModal,
  action
) => {
  switch (action.type) {
    case INVITATIONS_RECEIVE_VIEW_TYPE_CHANGE:
      return action.data;
    default:
      return state;
  }
};

export const invitationsListColumns = (
  state = entitiesListSelector(initialMembersColumnsSetUp),
  action
) => {
  switch (action.type) {
    case INVITATIONS_RECEIVE_APPLY_LIST_COLUMNS_FORM:
      return entitiesListSelector(action.data);
    default:
      return state;
  }
};

export const initialInvitationModalStatus = { loading: false };
export const invitationModalStatus = (
  state = initialInvitationModalStatus,
  action
) => {
  switch (action.type) {
    case INVITATIONS_RECEIVE_OPEN_VIEW_MODAL:
      return update(state, { loading: { $set: true } });
    case INVITATIONS_RECEIVE_HIDE_VIEW_MODAL:
      return update(state, { loading: { $set: false } });
    default:
      return state;
  }
};

export const invitationModalData = (state = {}, action) => {
  switch (action.type) {
    case INVITATIONS_RECEIVE_SHOW_VIEW_MODAL:
      return action.data.data;
    default:
      return state;
  }
};

export const invitationsAcceptModalData = (state = {}, action) => {
  switch (action.type) {
    case INVITATIONS_RECEIVE_SHOW_ACCEPT_INVITATION_MODAL:
      return action.data;
    default:
      return state;
  }
};

export const initialInvitationsAcceptModalStatus = { loading: false };
export const invitationsAcceptModalStatus = (
  state = initialInvitationsAcceptModalStatus,
  action
) => {
  switch (action.type) {
    case INVITATIONS_RECEIVE_OPEN_ACCEPT_INVITATION_MODAL:
      return update(state, { loading: { $set: true } });
    case INVITATIONS_RECEIVE_HIDE_ACCEPT_INVITATION_MODAL:
      return update(state, { loading: { $set: false } });
    default:
      return state;
  }
};

export const initialHandleInvitationsStatus = "IDLE";
export const handleInvitationsStatus = (
  state = initialHandleInvitationsStatus,
  action
) => {
  switch (action.type) {
    case INVITATIONS_RECEIVE_BEGIN_INVITATIONS_STATUS:
      return "FETCHING";
    case INVITATIONS_RECEIVE_END_INVITATIONS_STATUS:
      return "FETCHED";
    default:
      return state;
  }
};

export const fetchInvitationMessageDetail = (state = "", action) => {
  switch (action.type) {
    case INVITATIONS_RECEIVE_MESSAGE_DETAIL:
      return action.data;
    default:
      return state;
  }
};
