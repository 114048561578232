import update from "immutability-helper";

import {
  INDEPENDENT_AGENTS_SELECT_SERVICE_CATEGORY,
  INDEPENDENT_AGENTS_SELECT_TRAVEL_SERVICE,
  INDEPENDENT_AGENTS_VIEW_SERVICE_CATEGORIES_MODE,
  INDEPENDENT_AGENTS_EDIT_SERVICE_CATEGORIES_MODE,
  INDEPENDENT_AGENTS_LOADING_SERVICE_CATEGORY_MODE,
  INDEPENDENT_AGENTS_INITIALIZE_TRAVEL_SERVICE_FORM,
  INDEPENDENT_AGENTS_INITIALIZE_MAIN_SERVICES_FORM,
  INDEPENDENT_AGENTS_UPDATE_MAIN_SERVICES_FORM,
} from "@src/actions/Network/IndependentAgents/types";

import { travelServicesFrom } from "@src/forms/Admin/Company/companyProfileForms";

import { travelServicesFormProcessor } from "@src/reducers/tools";

export const initialIndependentAgentsServiceCategoriesMode = "VIEW";
export const independentAgentsServiceCategoriesMode = (
  state = initialIndependentAgentsServiceCategoriesMode,
  action
) => {
  switch (action.type) {
    case INDEPENDENT_AGENTS_VIEW_SERVICE_CATEGORIES_MODE:
      return "VIEW";
    case INDEPENDENT_AGENTS_EDIT_SERVICE_CATEGORIES_MODE:
      return "EDIT";
    case INDEPENDENT_AGENTS_LOADING_SERVICE_CATEGORY_MODE:
      return "LOADING";
    default:
      return state;
  }
};

export const independentAgentsSelectedServiceCategory = (
  state = "",
  action
) => {
  switch (action.type) {
    case INDEPENDENT_AGENTS_SELECT_SERVICE_CATEGORY:
      return action.data;
    default:
      return state;
  }
};

export const independentAgentsTravelServicesForm = (
  state = travelServicesFrom,
  action
) => {
  switch (action.type) {
    case INDEPENDENT_AGENTS_INITIALIZE_TRAVEL_SERVICE_FORM:
      return update(state, { travel_services: { $set: action.data } });
    case INDEPENDENT_AGENTS_SELECT_TRAVEL_SERVICE:
      return travelServicesFormProcessor(
        state,
        action.data.type,
        "travel_services",
        action.data.value,
        action.data.name,
        action.data.travelServices
      );
    default:
      return state;
  }
};

export const initialIndependentAgentsMainServicesForm = {
  value: "",
  verbose: "Main Services",
};

export const independentAgentsMainServicesForm = (
  state = initialIndependentAgentsMainServicesForm,
  action
) => {
  switch (action.type) {
    case INDEPENDENT_AGENTS_INITIALIZE_MAIN_SERVICES_FORM:
      return update(state, { value: { $set: action.data } });
    case INDEPENDENT_AGENTS_UPDATE_MAIN_SERVICES_FORM:
      return update(state, { value: { $set: action.data } });
    default:
      break;
  }
  return state;
};
