import { Loader } from "rsuite";

import {
  convertAmountSelector,
  getCurrencies,
  getGlobalCurrencySelector,
  getSelCurrencySelector,
} from "@src/selectors/Financial";

import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import React from "react";
import { TripModeHoc } from "../Hydrators";
import { createUseStyles } from "react-jss";
import { variables } from "@src/jsssetup";
import { convert, unConvert } from "@src/tools/pricing_tools";

export const PriceExcHoc = (WrappedComponent) => {
  const Comp = (props) => {
    const { price, currency } = props;
    const currenciesExc = useSelector((state) => getCurrencies(state));
    const globalCurrency = useSelector((state) =>
      getGlobalCurrencySelector(state)
    ).notation;

    const selectedCurrency = useSelector((state) =>
      getSelCurrencySelector(state)
    );

    const selCurrNotation = selectedCurrency.notation;
    const selCurrSymbol = selectedCurrency.symbol;
    const selectedCurrencyRate = selectedCurrency.rate;

    var rate = 1;
    const priceCurrency = currenciesExc.find((cr) => cr.notation === currency);
    if (priceCurrency) {
      rate = priceCurrency.rate;
    }

    const amountInGlobal = unConvert(price, globalCurrency, currency, rate);
    const convertedAmount = convert(
      amountInGlobal,
      globalCurrency,
      selCurrNotation,
      selectedCurrencyRate
    );

    return (
      <WrappedComponent
        {...props}
        price={convertedAmount}
        currency={selCurrNotation}
        symbol={selCurrSymbol}
      />
    );
  };
  Comp.defaultProps = {
    price: 0,
    currency: "EUR",
  };
  Comp.propTypes = {
    price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    currency: PropTypes.string.isRequired,
  };
  return Comp;
};

const styles = createUseStyles({
  PriceDetails: (props) => ({
    padding: [
      `calc(${variables.normal_gap} / 4)`,
      `calc(${variables.normal_gap} / 2)`,
    ],
    borderRadius: `calc(${variables.normal_gap} / 2)`,
    display: "grid",
    justifyItems: "center",
    color:
      props.priceType === "selling"
        ? variables.colors.text.dark
        : `${variables.colors.text.light} !important`,
    border:
      props.priceType === "selling"
        ? `2px solid ${variables.colors.lightGreen}`
        : `2px solid ${variables.colors.lightBlue}`,
    background:
      props.priceType === "selling"
        ? variables.colors.lightGreen
        : variables.colors.lightBlue,
    fontWeight: "500",
  }),
  price: (props) => ({
    color:
      props.priceType === "selling"
        ? variables.colors.text.dark
        : variables.colors.text.light,
  }),
  priceTxt: (props) => ({
    color:
      props.priceType === "selling"
        ? variables.colors.text.dark
        : variables.colors.text.light,
    fontSize: "medium",
  }),
});
const PriceDetails = ({
  convertedCurrency,
  convertedAmount,
  searching,
  price,
  priceVisible,
  priceType,
  digits,
  onDetails,
}) => {
  const classes = styles({ priceType });

  const isSelling = priceType === "selling";

  return price !== 0 && !price ? null : (
    <div className={classes.PriceDetails}>
      {searching ? (
        <Loader size="md" />
      ) : (
        <React.Fragment>
          {priceVisible && (
            <strong className={classes.price}>
              {convertedCurrency
                ? convertedAmount.toLocaleString("en-US", {
                    style: "currency",
                    currency: convertedCurrency,
                  })
                : convertedAmount.toFixed(digits)}
            </strong>
          )}
          {typeof onDetails === "function" ? (
            <a
              className={classes.priceTxt}
              href=""
              onClick={(e) => {
                e.preventDefault();
                onDetails();
              }}>
              Details
            </a>
          ) : (
            isSelling && <span className={classes.priceTxt}>Selling Price</span>
          )}
        </React.Fragment>
      )}
    </div>
  );
};
PriceDetails.defaultProps = {
  priceType: "buying",
  price: 0,
  convertedAmount: 0,
  priceVisible: true,
  searching: false,
  digits: 2,
};
PriceDetails.propTypes = {
  convertedAmount: PropTypes.number,
  priceType: PropTypes.oneOf(["buying", "selling", "percentage"]),
  price: PropTypes.number,
  priceVisible: PropTypes.bool,
  searching: PropTypes.bool,
  onDetails: PropTypes.func,
  digits: PropTypes.number,
};
const mapStateToProps = (state, ownProps) => {
  const {
    convertedAmount,
    currSymbol,
    currency: convertedCurrency,
  } = convertAmountSelector(state, {
    amount: ownProps.price,
    currentCurrency: ownProps.currency,
  });

  return { convertedAmount, currSymbol, convertedCurrency };
};
export default connect(mapStateToProps, null)(TripModeHoc(PriceDetails));
