import {
  AUTH_SHOW_AUTH_MODAL,
  AUTH_HIDE_AUTH_MODAL,
  AUTH_INIT_USER_PERMS,
  AUTH_SET_USER_PERMS,
  AUTH_SET_USER_FEATURE_FLAG_DATA,
} from "@src/actions/Auth/types";

import update from "immutability-helper";

export const authModalInitial = { show: false };
export const authModal = (state = authModalInitial, action) => {
  switch (action.type) {
    case AUTH_SHOW_AUTH_MODAL:
      return update(state, { show: { $set: true } });
    case AUTH_HIDE_AUTH_MODAL:
      return authModalInitial;
    default:
      return state;
  }
};

export const userPermsInitial = {
  groups: [],
  entity_groups: [],
  top_entity_groups: [],
};
/**
 * Keeps information about user, entity and top_entity permissions.
 * @param {object} userPermsInitial
 * @param {object} action
 * @returns {object}
 */
export const userPerms = (state = userPermsInitial, action) => {
  switch (action.type) {
    case AUTH_INIT_USER_PERMS:
      return userPermsInitial;
    case AUTH_SET_USER_PERMS: {
      const { groups, entity_groups, top_entity_groups } = action;
      return { ...state, groups, top_entity_groups, entity_groups };
    }
    default:
      return state;
  }
};

export const authFeatureFlagData = (state = {}, action) => {
  switch (action.type) {
    case AUTH_SET_USER_FEATURE_FLAG_DATA:
      return action.data;
    default:
      return state;
  }
};
