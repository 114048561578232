import React from "react";
import { createUseStyles } from "react-jss";
import { GeneralStats } from "../GeneralStats";
import CreateBarChart from "../../ChartComponents/CreateBarChart";
import { variables } from "@src/jsssetup";
import PropTypes from "prop-types";
import TransTxt from "@src/components/common/SxFormatMessage";
import { injectIntl } from "react-intl";
import _ from "lodash";
import MultipleBarChart from "../../ChartComponents/MultipleBarChart";

const financeReportTable = createUseStyles({
  FinanceReportTable: {
    display: "grid",
    gridTemplateRows: "max-content",
  },
  tableContainer: {
    display: "grid",
    border: `2px solid #FF6600`,
    borderRadius: "10px",
    textAlign: "center",
  },
  table: {
    width: "100%",
  },
  tHead: {
    backgroundColor: "#FF6600",
  },
  headRow: {
    "background": "#FF6600",
    "& th:first-child": {
      borderTopLeftRadius: "10%",
    },
    "& th:last-child": {
      borderTopRightRadius: "10%",
    },
  },
  headCell: {
    backgroundColor: "#FF6600",
    textAlign: "center",
    color: "white",
    padding: variables.half_gap,
    fontFamily: "futura,sans-serif",
    fontWeight: "400",
  },
  tableCell: {
    textAlign: "center",
    padding: `${variables.half_gap} ${variables.normal_gap}`,
    fontFamily: "futura,sans-serif",
    height: "2rem",
  },
});

const FinanceReportTable = ({ data }) => {
  const classes = financeReportTable();

  return (
    <div className={classes.FLFinanceCharts}>
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead className={classes.tHead}>
            <tr className={classes.headRow}>
              <th className={classes.headCell}>
                <TransTxt id="B2B_stats_FL_finance__count" />
              </th>
              <th className={classes.headCell}>
                <TransTxt id="B2B_stats_FL_finance__buying" />
              </th>
              <th className={classes.headCell}>
                <TransTxt id="B2B_stats_FL_finance__selling" />
              </th>
              <th className={classes.headCell}>
                <TransTxt id="B2B_stats_FL_finance__margin" />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={classes.tableCell}>
                {_.get(data, "finance.count") !== 0
                  ? _.get(data, "finance.count") || "N/A"
                  : "0"}
              </td>
              <td className={classes.tableCell}>
                {_.get(data, "finance.buying") !== 0
                  ? _.get(data, "finance.currency")
                  : ""}{" "}
                {_.get(data, "finance.buying") !== 0
                  ? _.get(data, "finance.buying") || "N/A"
                  : "0"}
              </td>
              <td className={classes.tableCell}>
                {_.get(data, "finance.selling") !== 0
                  ? _.get(data, "finance.currency")
                  : ""}{" "}
                {_.get(data, "finance.selling") !== 0
                  ? _.get(data, "finance.selling") || "N/A"
                  : "0"}
              </td>
              <td className={classes.tableCell}>
                {(_.get(data, "finance.margin.amount") !== 0
                  ? (
                      (_.get(data, "finance.margin.amount") * 100) /
                      _.get(data, "finance.count")
                    ).toFixed(2)
                  : "0") || "N/A"}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
FinanceReportTable.propTypes = {
  data: PropTypes.object.isRequired,
};

const styles = createUseStyles({
  FLFinanceCharts: {
    display: "grid",
    padding: variables.double_gap,
  },
  stats: {
    display: "grid",
    gridTemplateRows: "max-content",
  },
  statsTable: {
    display: "grid",
    justifyItems: "center",
  },
  chartsContainer: {
    display: "grid",
    gridTemplateRows: "repeat(2, max-content)",
  },
  labelContainer: {
    display: "grid",
    gridTemplateColumns: "37rem 29.5rem",
    justifyItems: "center",
    paddingTop: variables.normal_gap,
  },
  label: {
    display: "grid",
    alignSelf: "start",
    padding: variables.half_gap,
    width: "15rem",
    borderRadius: "5px",
    textAlign: "center",
    color: "white",
    backgroundColor: "#C9C9C9",
    fontFamily: "futura,sans-serif",
  },
  label1: {
    display: "grid",
    alignSelf: "start",
    padding: variables.half_gap,
    width: "15rem",
    borderRadius: "5px",
    textAlign: "center",
    color: "white",
    backgroundColor: "#C9C9C9",
    fontFamily: "futura,sans-serif",
  },
  charts: {
    display: "grid",
    gridTemplateColumns: "34rem 0.5rem 34rem",
    justifyContent: "center",
    height: "20rem",
  },
  leftSideCharts: {
    display: "grid",
    gridAutoFlow: "column",
  },
  rightSideCharts: {
    display: "grid",
    // gridAutoFlow: "column",
  },
  chart1: {
    display: "grid",
    justifyContent: "center",
  },
  chart2: {
    display: "grid",
    justifyContent: "center",
  },
  chart3: {
    display: "grid",
    justifyContent: "center",
  },
  chart4: {
    display: "grid",
    justifyContent: "center",
  },
  divider: {
    justifySelf: "center",
    alignSelf: "center",
    height: "85%",
    border: `1px solid rgba(0, 0, 0, 0.1)`,
    gridColumn: "1 span",
    width: "0%",
  },
});

var FLFinanceCharts = ({ intl, selectedDate, data }) => {
  const classes = styles();

  const purchasesVendorsBarChartData = _.get(data, "finance.purchases.fs").map(
    ([name, value]) => ({
      name,
      value,
    })
  );
  const purchasesVendorsChartColors = ["#FF6600"];

  const purchasesLCCBarChartData = _.get(data, "finance.purchases.lc", []).map(
    ([name, value]) => ({
      name,
      value,
    })
  );
  const purchasesLCCChartColors = ["#FF6600"];

  const salesFullServiceBarChartData = Object.entries(
    _.get(data, "finance.trading.fs", {})
  ).map(([name, value]) => ({
    name,
    value,
  }));

  const salesLowServiceBarChartData = Object.entries(
    _.get(data, "finance.trading.lc", {})
  ).map(([date, value]) => ({
    name: date,
    value,
  }));

  const combinedData = [
    ["product", "Sales", "Buy", "Margin"],
    [
      "Full Services ESC",
      salesFullServiceBarChartData[0]?.value || 0,
      salesFullServiceBarChartData[1]?.value || 0,
      salesFullServiceBarChartData[2]?.value || 0,
    ],
    [
      "Low Cost LCC",
      salesLowServiceBarChartData[0]?.value || 0,
      salesLowServiceBarChartData[1]?.value || 0,
      salesLowServiceBarChartData[2]?.value || 0,
    ],
  ];
  const combineDataChartColors = ["#FFB381", "#B0754E", "#B174FF"];
  return (
    <div className={classes.FLFinanceCharts}>
      <div className={classes.stats}>
        <GeneralStats
          tableTitle={intl.formatMessage({
            id: "B2B_stats_FL_finance__issued",
          })}
          title={intl.formatMessage({
            id: "B2B_stats_FL_finance__finance",
          })}
          startDate={_.get(data, "period.start_date")}
          endDate={_.get(data, "period.end_date")}
        />
        <div className={classes.statsTable}>
          <FinanceReportTable data={data} />
        </div>
      </div>
      <div className={classes.chartsContainer}>
        <div className={classes.labelContainer}>
          <div className={classes.label}>
            <TransTxt id="B2B_stats_FL_finance__purchases" />
          </div>
          <div className={classes.label1}>
            <TransTxt id="B2B_stats_FL_finance__sales" />
          </div>
        </div>
        <div className={classes.charts}>
          <div className={classes.leftSideCharts}>
            <div className={classes.chart1}>
              <CreateBarChart
                title={intl.formatMessage({
                  id: "B2B_stats_FL_finance__top_5_vendors_fsc",
                })}
                barWidth={"100%"}
                width={12}
                xAxisColor="#FF6600"
                seriesLabelColor="#FF6600"
                seriesLabelShow={false}
                predefinedColors={purchasesVendorsChartColors}
                data={purchasesVendorsBarChartData}
              />
            </div>
            <div className={classes.chart2}>
              <CreateBarChart
                title={intl.formatMessage({
                  id: "B2B_stats_FL_finance__top_5_lcc",
                })}
                barWidth={"100%"}
                width={12}
                xAxisColor="#FF6600"
                seriesLabelColor="#FF6600"
                seriesLabelShow={false}
                predefinedColors={purchasesLCCChartColors}
                data={purchasesLCCBarChartData}
              />
            </div>
          </div>
          <div className={classes.divider}></div>
          <div className={classes.rightSideCharts}>
            <div className={classes.chart3}>
              <MultipleBarChart
                title={intl.formatMessage({
                  id: "B2B_stats_FL_finance__trading",
                })}
                width={30}
                barWidth={"30%"}
                seriesLabelColor={combineDataChartColors}
                predefinedColors={combineDataChartColors}
                data={combinedData}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
FLFinanceCharts.propTypes = {
  selectedDate: PropTypes.instanceOf(Date),
  data: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  dashboardPayload: PropTypes.object.isRequired,
};
FLFinanceCharts = injectIntl(FLFinanceCharts);
export default FLFinanceCharts;
