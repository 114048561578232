import { VENDORS_REST_LIST_GENERIC_CHANGE } from "@src/actions/Network/Vendors/Restaurant/types";
import { commonList } from "@src/reducers/common";

const restaurantVendorsListReducerInitial = {
  ...commonList,
  displayLength: 100,
  filters: {
    creator__icontains: "",
    status: "",
    created__gte: "",
    created__lte: "",
    vendor_type: "",
    vendor: "",
    market_type: "",
    page: 1,
    limit: 20,
    ordering: "",
  },
};
export const RestaurantVendorsListReducer = (
  state = restaurantVendorsListReducerInitial,
  action
) => {
  switch (action.type) {
    case VENDORS_REST_LIST_GENERIC_CHANGE: {
      const { filters } = action;
      return { ...state, filters: { ...state.filters, ...filters } };
    }
    default:
      return state;
  }
};
