import _ from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { CustomButton } from "@src/components/common/buttons";
import { modalGenericStyles, variables } from "@src/jsssetup";
import { createUseStyles } from "react-jss";
import { Form, Formik, useFormikContext } from "formik";
import { DateTime } from "luxon";
import * as yup from "yup";
import {
  NormalInputField,
  NormalRichText,
  NormalSelectField,
} from "@src/components/forms";
import { getTripDaysSelector } from "@src/selectors/Project/TripPlanner/day_by_day_selectors";
import { useDispatch, useSelector } from "react-redux";
import { useAIContentGeneration } from "@src/pages/hooks";
import { Loader } from "rsuite";
import {
  tripPlannerDBDCntChangeAction,
  tripPlannerDBDCntResetAction,
} from "@src/actions/Project/TripPlanner";
import { verboseAvailableLanguagesCouples } from "@src/definitions";
import LoadingBar from "react-top-loading-bar";

const schema = yup.object().shape({
  date: yup.string().required("Required").default(""),
  title: yup.string().default(""),
  description: yup
    .string()
    .max(100, "Description must be less than or equal to 100 characters")
    .default(""),
  destinations: yup.array().of(yup.string()),
  order: yup.number(),
});

export const SubmitBtn = () => {
  const { submitForm } = useFormikContext();

  return <CustomButton onClick={submitForm}>Save</CustomButton>;
};
SubmitBtn.propTypes = {};

const customBtnStyles = {
  CustomButton: {
    backgroundColor: "white",
    color: variables.colors.easy.orange,
    border: `1px solid ${variables.colors.easy.orange}`,
  },
};

const customRichTextStyles = {
  inputGroup: { background: "white" },
  label: { background: variables.colors.lightGrey },
};

const dayByDayContentModalStyles = createUseStyles({
  ...modalGenericStyles,
  DayByDayContentModal: modalGenericStyles.modal,
  card: { ...modalGenericStyles.card, maxWidth: "35vw" },
  cardHeader: {
    ...modalGenericStyles.cardHeader,
    backgroundColor: variables.colors.easy.lightOrange4,
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
    fontWeight: "600",
    borderBottom: "unset",
    position: "relative",
  },
  cardHeaderActions: {
    display: "grid",
    gridAutoFlow: "column",
    gridGap: variables.half_gap,
    alignSelf: "end",
  },
  cardBody: {
    ...modalGenericStyles.cardBody,
    padding: variables.double_gap,
    display: "grid",
    gridGap: variables.normal_gap,
    position: "relative",
  },
  formContainer: { display: "grid", gridGap: variables.double_gap },
  form: {
    display: "grid",
    gridGap: variables.double_gap,
    background: variables.colors.lightGrey,
    padding: [
      variables.normal_gap,
      variables.double_gap,
      variables.double_gap,
      variables.double_gap,
    ],
    borderRadius: "5px",
  },
  form2: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: variables.double_gap,
    background: variables.colors.lightGrey,
    padding: [
      variables.normal_gap,
      variables.double_gap,
      variables.double_gap,
      variables.double_gap,
    ],
    borderRadius: "5px",
  },
  day: { display: "grid", gridGap: variables.half_gap },
  date: {
    display: "grid",
    gridTemplateColumns: "1fr max-content",
    fontWeight: "600",
    color: variables.colors.easy.orange,
  },
});
export const DayByDayContentModal = ({ onCancel }) => {
  const classes = dayByDayContentModalStyles();
  const trip_days = useSelector((state) => getTripDaysSelector(state));

  const [aiTheme, setAiTheme] = useState("d2d_descriptor_leisure");
  const [aiContent, setAiContent] = useState(
    trip_days.map((d, idx) =>
      schema.cast({
        date: d.date,
        order: d.day,
        title: "",
        description: "",
      })
    )
  );

  const dbd_descriptions = useSelector(
    (state) => state.tripPlannerDayByDayContent
  );

  const dispatch = useDispatch();
  const onSubmit = useCallback(
    async ({ days }) =>
      await dispatch(
        tripPlannerDBDCntChangeAction(
          Object.values(days).map((d) => _.omit(d, "edit"))
        )
      ),
    [dispatch]
  );
  const onReset = useCallback(
    () => dispatch(tripPlannerDBDCntResetAction()),
    [dispatch]
  );

  const messages = _.flatten(
    trip_days.map((day, idx) => {
      var msg = `DAY ${idx + 1}::${day.date}`;
      msg += "::" + day.destinations.map((dest) => dest.query).join(", ");
      if (day.services.length > 0) {
        if (day.services.every((srv) => !srv.eventType)) {
          msg += "::Free day";
          return msg;
        }

        return day.services
          .map((srv) => {
            var srvVerbose = "";
            switch (srv.srvType) {
              case "FL": {
                srvVerbose = "Flight service";
                break;
              }
              case "ACC": {
                srvVerbose = "Accommodation service";
                break;
              }
              case "TRF": {
                srvVerbose = "Transfer service";
                break;
              }
              case "COA": {
                srvVerbose = "Coach service";
                break;
              }
              case "CU": {
                switch (srv.custom_service_type) {
                  case "COO": {
                    srvVerbose = "Coordinator";
                    break;
                  }
                  default: {
                    srvVerbose = "Unknown service";
                    break;
                  }
                }
                break;
              }
              default: {
                srvVerbose = "Unknown service";
                break;
              }
            }

            if (srv.srvType === "CU") return null;

            var tmp = `${msg}::${srvVerbose}::${srv.eventType || "Normal Day"}`;
            if (srv.srvType === "COA") {
              const origin = srv?.service?.service?.origin?.name_en;
              const dest = srv?.service?.service?.destination?.name_en;
              tmp += `::From ${origin} to ${dest} with ${srvVerbose}::descriptive_flag=info`;
            } else if (srv.srvType === "FL") {
              const origin = srv?.service?.origin_airport;
              const dest = srv?.service?.destination_airport;
              tmp += `::From ${origin} Airport to ${dest} Airport with ${srvVerbose}::descriptive_flag=info`;
            } else if (srv.srvType === "ACC" && srv.eventType) {
              const hotel = srv?.service?.metadata?.name;
              tmp += `::At ${hotel}::descriptive_flag=rich`;
            } else if (srv.srvType === "TRF") {
              const origin = srv?.service?.origin?.name;
              const dest = srv?.service?.destination?.name;
              tmp += `::From ${origin} to ${dest} with ${srvVerbose}::descriptive_flag=info`;
            } //  else if (
            //   srv.srvType === "CU" &&
            //   srv.custom_service_type === "COO"
            // ) {
            //   const dest = srv.destinations.find((d) => `${d.type}__${d.id}`);
            //   tmp += `::${srv.specialty_display.join(", ")} service in ${
            //     dest.name_en
            //   } in the following languages ${srv.languages.join(
            //     ", "
            //   )}::descriptive_flag=info`;
            // }

            return tmp;
          })
          .filter((m) => m);
      }

      return msg;
    })
  );

  const {
    streamContent,
    contentGenerationStatus,
    initAutoContentSesssionMutation,
    progress,
  } = useAIContentGeneration({ context: aiTheme });

  // Ai Streamer
  useEffect(() => {
    if (!streamContent) return;
    setAiContent(streamContent.map((i, idx) => ({ ...i, order: idx + 1 })));

    const day_dom_id = `day_by_day_content_${streamContent.length - 1}`;
    const domEl = document.getElementById(day_dom_id);
    if (!domEl) return;
    document.getElementById(day_dom_id).scrollIntoView({
      behavior: "smooth",
    });
  }, [JSON.stringify(streamContent).length]);

  const tripDates = trip_days.map((day) => day.date);

  if (
    dbd_descriptions?.[0]?.date !== trip_days?.[0]?.date ||
    dbd_descriptions.length === trip_days.length
  ) {
    dbd_descriptions.forEach((d, idx) => (d.date = trip_days[idx].date));
  }

  return (
    <div className={classes.DayByDayContentModal}>
      <div className={classes.card}>
        <div className={classes.cardHeader}>
          Day By Day
          <div className={classes.cardHeaderActions}>
            {contentGenerationStatus === "processing" && <Loader />}
          </div>
          {contentGenerationStatus === "processing" && (
            <LoadingBar
              color={variables.colors.easy.orange}
              progress={progress}
              containerStyle={{
                position: "absolute",
                height: "5px",
                top: "100%",
                zIndex: 2,
              }}
              style={{ borderRadius: "0 10px 10px 0" }}
              shadow={false}
            />
          )}
        </div>
        <Formik
          enableReinitialize={true}
          initialValues={{
            theme: aiTheme,
            days:
              dbd_descriptions.length > 0 &&
              Object.values(aiContent).every((val) => !val?.title)
                ? dbd_descriptions.length === trip_days.length
                  ? dbd_descriptions
                  : dbd_descriptions.filter((dbd) =>
                      tripDates.includes(dbd.date)
                    )
                : trip_days.map((day, idx) =>
                    schema.cast({
                      date: day.date,
                      description: aiContent?.[idx]?.description || "",
                      title: aiContent?.[idx]?.title || "",
                      order: idx + 1,
                      edit: false,
                      destinations: day.destinations.map(
                        (dest) => `${dest.type}__${dest.id}`
                      ),
                    })
                  ),
          }}
          validationSchema={schema}
          validate={(values) => setAiTheme(values.theme)}
          onSubmit={() => {}}>
          {({ values, setFieldValue }) => (
            <React.Fragment>
              <div className={classes.cardBody}>
                <Form className={classes.form2}>
                  <NormalSelectField
                    name="theme"
                    label="Ai Theme"
                    options={[
                      ["d2d_descriptor_leisure", "Leisure Trip"],
                      ["d2d_descriptor_corporate", "Corporate Trip"],
                    ]}
                  />
                  <NormalSelectField
                    name="language"
                    label="Language"
                    options={verboseAvailableLanguagesCouples}
                  />
                </Form>
                <Form className={classes.formContainer}>
                  {values.days.map((day, dayidx) => {
                    return (
                      <div
                        className={classes.day}
                        id={`day_by_day_content_${dayidx}`}>
                        <div className={classes.date}>
                          Day {day.order},{" "}
                          {day.date &&
                            DateTime.fromISO(day.date).toLocaleString(
                              DateTime.DATE_MED_WITH_WEEKDAY
                            )}
                          <CustomButton
                            onClick={() =>
                              setFieldValue(`days.${dayidx}.edit`, !day.edit)
                            }>
                            {day.edit ? "Apply" : "Edit"}
                          </CustomButton>
                        </div>
                        {day.edit ? (
                          <div className={classes.form}>
                            <NormalInputField
                              name={`days.${dayidx}.title`}
                              label="Title"
                            />
                            <NormalRichText
                              name={`days.${dayidx}.description`}
                              label="Description"
                              customStyles={customRichTextStyles}
                            />
                          </div>
                        ) : (
                          <div className={classes.form}>
                            <span>
                              <strong>Title:</strong>{" "}
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: day.title,
                                }}
                              />
                            </span>
                            <span>
                              <strong>Description:</strong>{" "}
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: day.description,
                                }}
                              />
                            </span>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </Form>
              </div>
              <div className={classes.cardActions}>
                <button className="Button" data-ghost={true} onClick={onCancel}>
                  Cancel
                </button>
                <button className="Button" data-ghost={true} onClick={onReset}>
                  Reset
                </button>
                <button
                  className="Button"
                  onClick={() =>
                    initAutoContentSesssionMutation.mutate({
                      messages,
                      max_tokens: 3000,
                    })
                  }>
                  Generate with A.I
                </button>
                <button
                  className="Button"
                  onClick={async () => {
                    await onSubmit({ days: values.days });
                    onCancel();
                  }}>
                  Submit
                </button>
              </div>
            </React.Fragment>
          )}
        </Formik>
      </div>
    </div>
  );
};
DayByDayContentModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
};
