// ===================== FLIGHT RESERVATIONS LIST ====================
// export const FLIGHTS_FETCH_DATA = "FLIGHTS_FETCH_DATA";
// export const FLIGHTS_RESERVATION_BEGIN_FETCH_DATA =
//   "FLIGHTS_RESERVATION_BEGIN_FETCH_DATA";
// export const FLIGHTS_RESERVATION_END_FETCH_DATA =
//   "FLIGHTS_RESERVATION_END_FETCH_DATA";
// //======================= FILTERS=============================
// export const FLIGHTS_RESERVATION_INITIALIZE_LIST_FILTER_FORM =
//   "FLIGHTS_RESERVATION_INITIALIZE_LIST_FILTER_FORM";
// export const FLIGHTS_RESERVATION_UPDATE_LIST_FILTER_FORM =
//   "FLIGHTS_RESERVATION_UPDATE_LIST_FILTER_FORM";
// export const FLIGHTS_RESERVATION_APPLY_LIST_FILTER =
//   "FLIGHTS_RESERVATION_APPLY_LIST_FILTER";
// export const FLIGHT_RESERVATION_RESET_LIST_FILTER_FORM =
//   "FLIGHT_RESERVATION_RESET_LIST_FILTER_FORM";
// //===============================COLUMNS=====================================
// export const FLIGHTS_RESERVATION_UPDATE_LIST_COLUMNS_FORM =
//   "FLIGHTS_RESERVATION_UPDATE_LIST_COLUMNS_FORM";
// export const FLIGHTS_RESERVATION_APPLY_LIST_COLUMNS =
//   "FLIGHTS_RESERVATION_APPLY_LIST_COLUMNS";
// export const FLIGHTS_RESERVATION_INITIALIZE_LIST_COLUMNS_FORM =
//   "FLIGHTS_RESERVATION_INITIALIZE_LIST_COLUMNS_FORM";
// export const FLIGHTS_RESERVATION_FETCH_OPTIONS =
//   "FLIGHTS_RESERVATION_FETCH_OPTIONS";
// ==================== FLIGHT RESERVATION DETAILS ===================
// export const FLIGHTS_RESERVATION_FLIGHT_INIT =
//   "FLIGHTS_RESERVATION_FLIGHT_INIT";
// export const FLIGHT_RESERVATION_SAVE_DATA = "FLIGHT_RESERVATION_SAVE_DATA";
// export const FLIGHT_RESERVATION_FETCH_NAV_STATUS =
//   "FLIGHT_RESERVATION_FETCH_NAV_STATUS";
//========================DASHBOARD===============================
export const FLIGHT_RESERVATION_VIEW_TYPE = "FLIGHT_RESERVATION_VIEW_TYPE";
export const FLIGHT_RESERVATION_FETCH_GRAPH_DATA =
  "FLIGHT_RESERVATION_FETCH_GRAPH_DATA";
export const FLIGHT_RESERVATION_BEGIN_FETCH_GRAPH_DATA =
  "FLIGHT_RESERVATION_BEGIN_FETCH_GRAPH_DATA";
export const FLIGHT_RESERVATION_END_FETCH_GRAPH_DATA =
  "FLIGHT_RESERVATION_END_FETCH_GRAPH_DATA";
export const FLIGHT_RESERVATION_INIT_GRAPH_TYPE =
  "FLIGHT_RESERVATION_INIT_GRAPH_TYPE";
