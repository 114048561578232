import _ from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSetupFormDataSelector,
  requiredRoomsNumberSelector,
} from "@src/selectors/Project/TripPlanner";
import { adhocPriceFields } from "./products/addhoc/coach";
import { DateTime } from "luxon";
import { v4 } from "uuid";
import { fetchAccommodationMetadata } from "@src/api";
import { addAdhocAccSrv } from "@src/actions/Project/TripPlanner/addhoc/accommodation";
import { AdhocAccForm } from "./products/addhoc/accommodation/form";

export function addonAccSrvPriceCalculator({
  values,
  adults,
  children,
  original_total_price = 0,
  original_currency = "EUR",
}) {
  const check_in = DateTime.fromISO(values.check_in);
  const check_out = DateTime.fromISO(values.check_out);

  const stay = Math.ceil(check_out.diff(check_in, "days").as("days"), 10);

  const pax = adults + children;
  values.rooms.forEach((room) => {
    const ptype = _.get(room, "price_type", "per_person");

    const price =
      ptype === "per_person"
        ? room.price_per_person_per_night
        : room.price_per_room_per_night;
    const pax = room.adults + room.children;
    if (ptype === "per_person") {
      room.price = Number((price * stay * pax).toFixed(2));
    } else {
      room.price = Number((price * stay).toFixed(2));
    }
  });

  _.get(values, "extras", []).forEach((ext) => {
    if (_.get(ext, "price_type") === "per_person") {
      ext.price = ext.price_per_person * pax;
    } else {
      ext.price = ext.price_per_room;
    }
  });
  const totalExtrasPrice = _.get(values, "extras", []).reduce(
    (a, b) => a + b.price,
    0
  );

  values["total_price"] =
    (values.rooms.reduce((a, b) => a + b.price, 0) || 0) + totalExtrasPrice;
  values["per_night_price"] = values["total_price"] / stay;

  const price_changed = values["total_price"] !== original_total_price;
  const currency_changed = values["currency"] !== original_currency;
  values["original_price_edited"] = price_changed || currency_changed;
}

const AddAdhocAccSrvModal = ({ destination, onCancel }) => {
  const { adults, children: childs } = useSelector((state) =>
    getSetupFormDataSelector(state)
  );
  const rooms_number = useSelector((state) =>
    requiredRoomsNumberSelector(state)
  );

  const dispatch = useDispatch();
  const onAdd = useCallback(
    ({ srv }) => dispatch(addAdhocAccSrv({ srv })),
    [dispatch]
  );

  const [loading, setLoading] = useState(false);
  const [uid, setUid] = useState(null);
  const [hotelMeta, setHotelMeta] = useState({});

  async function accMetaFetcher({ uid }) {
    setLoading(true);
    const result = await fetchAccommodationMetadata({ hotel_ids: [uid] });
    const htl = _.get(result, "hotels.0");
    setHotelMeta(htl);
    setLoading(false);
  }

  useEffect(() => {
    if (uid) {
      accMetaFetcher({ uid });
    }
  }, [uid]);

  const initialCheckIn = DateTime.fromISO(destination.checkIn)
    .set({ hour: 14, minute: 0 })
    .toISO();
  const initialCheckOut = DateTime.fromISO(destination.checkOut)
    .set({ hour: 12, minute: 0 })
    .toISO();

  const initialValues = {
    search_accommodation: "",
    check_in: initialCheckIn,
    check_out: initialCheckOut,
    ...adhocPriceFields(),
    per_night_price: 0,
    metadata: hotelMeta,
    rooms: [],
    extras: [],
  };

  return (
    <div className="Modal AddAdhocAccSrvModal">
      <div className="Modal__card">
        <div className="Modal__card__header">
          <h5>
            Add Accommodation Service: {adults}{" "}
            {adults > 1 ? "Adults" : "Adult"}, {childs}{" "}
            {childs > 1 ? "Children" : "Child"}, {rooms_number} Room
            {rooms_number > 1 ? "s" : ""}
          </h5>
        </div>
        <AdhocAccForm
          hotelMeta={hotelMeta}
          initialValues={initialValues}
          loading={loading}
          setUid={setUid}
          onSubmit={(values) => {
            onAdd({
              srv: {
                ...values,
                destOrder: destination.order,
                destination: _.omit({ ..._.cloneDeep(destination) }, [
                  "images",
                  "checkIn",
                  "checkOut",
                  "dest_type",
                  "stay",
                ]),
                uid: v4(),
              },
            });
            onCancel();
          }}
          onCancel={onCancel}
        />
      </div>
    </div>
  );
};
AddAdhocAccSrvModal.propTypes = {
  destination: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default AddAdhocAccSrvModal;
