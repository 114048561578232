import _ from "lodash";

function deepExtend(out) {
  out = out || {};
  for (let i = 1; i < arguments.length; i++) {
    const obj = arguments[i];
    if (!obj) {
      continue;
    }
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (typeof obj[key] === "object") {
          out[key] = deepExtend(out[key], obj[key]);
        } else {
          out[key] = obj[key];
        }
      }
    }
  }
  return out;
}
function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
function formatNumber(value) {
  const precision = 1;
  return Number(_.round(value, precision));
}

function getProfit(buy, sell) {
  return formatNumber(sell - buy);
}
function getGP(profit, sell) {
  return formatNumber((profit / sell) * 100).toFixed(1);
}
function getMU(profit, buy) {
  return formatNumber((profit / buy) * 100).toFixed(1);
}

const getTotle = (data) => {
  const buy = formatNumber(
    _.sumBy(data, function (o) {
      if (o.ignore) {
        return 0;
      }
      return parseFloat(o.buy);
    })
  );
  const sell = formatNumber(
    _.sumBy(data, function (o) {
      if (o.ignore) {
        return 0;
      }
      return parseFloat(o.sell);
    })
  );
  const profit = getProfit(buy, sell);
  const GP = getGP(profit, sell);
  const MU = getMU(profit, buy);

  return {
    buy: buy,
    sell: sell,
    profit: profit,
    GP: GP,
    MU: MU,
  };
};

export const userAgentType = () => {
  if (window.ActiveXObject) {
    return "IE";
  } else if (document.getBoxObjectFor) {
    return "Firefox";
  } else if (window.MessageEvent && !document.getBoxObjectFor) {
    return "Chrome";
  } else if (window.opera) {
    return "Opera";
  } else if (window.openDatabase) {
    return "Safari";
  }
  return null;
};

export default {
  deepExtend,
  getCookie,
  setCookie,
  getTotle,
  userAgentType,
};
