import {
  getSrvsForBookingNoSelector,
  getSrvsForOptionBookingNoSelector,
  getSrvsUidForBookingSelector,
  getSrvsUidForOptBookingSelector,
} from "@src/selectors/Project/TripPlanner/prebook";

import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { TripPlanContext } from "../../../TripPlanner";

export function useFerriesPrebookRequirement() {
  const { ferRegNo, ferOptNo } = useSelector((state) => {
    const ferRegNo = getSrvsForBookingNoSelector(state, { srv_type: "AFER" });
    const ferOptNo = getSrvsForOptionBookingNoSelector(state, {
      srv_type: "AFER",
    });

    return { ferRegNo, ferOptNo };
  });

  const aferPrebookRequired = ferRegNo + ferOptNo > 0;
  return { aferPrebookRequired };
}

export const PostGuestDataPrebookServices = () => {
  const { setShowFerriesPrebookModal } = useContext(TripPlanContext);

  const { ferRegNo, ferOptNo, aferPrebookComplete } = useSelector((state) => {
    const ferRegNo = getSrvsForBookingNoSelector(state, { srv_type: "AFER" });
    const ferOptNo = getSrvsForOptionBookingNoSelector(state, {
      srv_type: "AFER",
    });

    const ferryNormalUids = getSrvsUidForBookingSelector(state, {
      srv_type: "AFER",
    });
    const ferryOptUids = getSrvsUidForOptBookingSelector(state, {
      srv_type: "AFER",
    });
    const ferryUids = [
      ...ferryNormalUids.map((f) => f.uid),
      ...ferryOptUids.map((f) => f.uid),
    ];
    const aferPrebookComplete = (
      state.tripPlannerFerriesReducer?.services ?? []
    )
      .filter((s) => ferryUids.includes(s.token))
      .every((s) => !!s?.prebookData);
    return { ferRegNo, ferOptNo, aferPrebookComplete };
  });

  const { aferPrebookRequired } = useFerriesPrebookRequirement();

  return (
    aferPrebookRequired && (
      <div className="PostGuestDataPrebookServices Card">
        <div className="Card__header">
          <h5>3. Post Traveller's Information Prebook</h5>
        </div>
        <div className="Card__body">
          <div className="PostGuestDataPrebookServices__srv">
            {aferPrebookRequired && (
              <button
                className="Button"
                onClick={() => setShowFerriesPrebookModal(true)}>
                Prebook
              </button>
            )}
            <span>
              <strong>Ferries</strong>
            </span>
            <span>
              <strong>Regular</strong>
            </span>
            <span className="PostGuestDataPrebookServices__srv__srvcounter">
              {ferRegNo}
            </span>
            <span>
              <strong>Option</strong>
            </span>
            <span
              className="PostGuestDataPrebookServices__srv__srvcounter"
              data-option="true">
              {ferOptNo}
            </span>
            <span>
              <strong>Prebook Completed</strong>
            </span>
            <span
              className="PostGuestDataPrebookServices__srv__status"
              data-complete={aferPrebookComplete ? "true" : "false"}>
              {aferPrebookComplete ? "Yes" : "No"}
            </span>
          </div>
        </div>
      </div>
    )
  );
};
