import _ from "lodash";
import { Loader, IconButton, Icon, Button } from "rsuite";

import {
  accPrefAddRoom,
  accPrefRemoveRoom,
  accPrefRoomPaxAdd,
  accPrefRoomPaxRemove,
  accRoomPaxAgeChange,
  itineraryChangePax,
  recalculateTrip,
  setRoomSetup,
  setTripMode,
  tripSetupChange,
} from "@src/actions/Project/TripPlanner";

import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import React, { useCallback, useRef, useState } from "react";
import { RoomSetup } from "../Controllers/AccommodationSetupController";
import {
  getNationalitiesSelector,
  getSetupFormDataSelector,
} from "@src/selectors/Project/TripPlanner";
import { notifyPastDate } from "@src/components/common/notifications/TripPlanner/trip_load_notifications";
import { withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { DateTime } from "luxon";
import { createUseStyles } from "react-jss";
import { formStyles, modalGenericStyles, variables } from "@src/jsssetup";
import { Form, Formik } from "formik";
import {
  NormalDatePicker,
  NormalInputField,
  NormalSelectField,
} from "@src/components/forms";
import { roomTemplate } from "@src/reducers/Project/TripPlanner";
import { v4 } from "uuid";
import { onFormikFormSubmit } from "@src/components/forms/helpers";
import { toast } from "react-toastify";

const tripModeModalStyles = createUseStyles({
  ...modalGenericStyles,
  TripModeModal: modalGenericStyles.modal,
  cardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    placeItems: "center",
    minHeight: "10rem",
  },
  initialActions: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridGap: variables.normal_gap,
  },
  form: {
    ...formStyles.form,
    display: "grid",
    gridGap: variables.half_gap,
    placeContent: "center",
  },
  col2: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: variables.normal_gap,
  },
  roomsNo: { display: "grid", gridGap: variables.normal_gap },
  rooms: { display: "grid", gridGap: variables.normal_gap },
});
const TripModeModal = ({
  msg,
  adults,
  children,
  rooms,
  countryData,
  nationality,
  tripStartDate,
  onHide,
  onRecalculateTrip,
  onSetupChange,
}) => {
  const classes = tripModeModalStyles();

  const [modalMode, setModalMode] = useState(null);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  function handleGoBack() {
    onHide();
    history.goBack();
  }

  const dispatch = useDispatch();
  const onSetTripMode = useCallback(
    ({ mode }) => dispatch(setTripMode({ mode })),
    [dispatch]
  );
  const onSetRoomSetup = useCallback(
    ({ rooms }) => dispatch(setRoomSetup({ rooms })),
    [dispatch]
  );
  const onPaxChange = useCallback(
    ({ paxType, value }) => dispatch(itineraryChangePax(paxType, value)),
    [dispatch]
  );

  const formRef = useRef(null);

  const tripStartDt = DateTime.fromISO(tripStartDate);
  const now = DateTime.now();

  return (
    <div className={classes.TripModeModal}>
      <div className={classes.card}>
        <div className={classes.cardHeader}>
          <h5>Trip Mode</h5>
        </div>
        <div className={classes.cardBody}>
          {modalMode === "recalculate" ? (
            <div>
              <p>
                Before the trip is recalculated you can change your trip setup.
              </p>
              <Formik
                initialValues={{
                  tripStartDate:
                    tripStartDt < now
                      ? DateTime.now().plus({ days: 7 }).toISODate()
                      : tripStartDt.toISODate(),
                  nationality,
                  adults,
                  children,
                  rooms,
                }}
                validateOnChange={false}
                validateOnBlur={false}
                validate={(values) => {
                  if (DateTime.fromISO(values.tripStartDate) < DateTime.now()) {
                    notifyPastDate();
                    return { tripStartDate: "Past Date" };
                  }

                  const totalPax = values.adults + values.children;
                  const roomTotalPax = values.rooms.reduce(
                    (a, b) => a + b.adults + b.children,
                    0
                  );
                  if (totalPax !== roomTotalPax) {
                    toast.error("Please correct your pax setup.");
                    return {
                      adults: "Please correct your pax setup.",
                      children: "Please correct your pax setup.",
                    };
                  }

                  return {};
                }}
                onSubmit={async (values) => {
                  await setLoading(true);
                  setTimeout(async () => {
                    await onSetTripMode({ mode: "recalculate" });
                    await onSetRoomSetup({ rooms: values.rooms });
                    await onPaxChange({
                      paxType: "adults",
                      value: values.adults,
                    });
                    await onPaxChange({
                      paxType: "children",
                      value: values.children,
                    });
                    await onSetupChange("nationality", values.nationality);
                    onRecalculateTrip(DateTime.fromISO(values.tripStartDate));
                    onHide();
                  }, 1000);
                }}>
                {({ values, setFieldValue }) => (
                  <Form className={classes.form} ref={formRef}>
                    {loading ? (
                      <Loader size="lg" content="Please wait..." />
                    ) : (
                      <React.Fragment>
                        <NormalDatePicker
                          name="tripStartDate"
                          label="Trip Start Date"
                          minAvailableDate={DateTime.now()
                            .plus({ days: 1 })
                            .toISODate()}
                        />
                        <NormalSelectField
                          name="nationality"
                          label="Nationality"
                          options={countryData.map((co) => [
                            co.value,
                            `${co.label} (${co.value})`,
                          ])}
                        />
                        <div className={classes.col2}>
                          <NormalInputField
                            name="adults"
                            label="Adults"
                            type="number"
                            extraInputProps={{ min: 1, max: 10 }}
                          />
                          <NormalInputField
                            name="children"
                            label="Children"
                            type="number"
                            extraInputProps={{ min: 0, max: 10 }}
                          />
                        </div>
                        <div className={classes.roomsNo}>
                          <span>
                            <strong>Rooms</strong>
                          </span>
                          <div>
                            <IconButton
                              icon={<Icon icon="minus" />}
                              circle
                              appearance="primary"
                              size="xs"
                              onClick={() => {
                                const rooms = _.cloneDeep(values.rooms);
                                rooms.pop();
                                setFieldValue("rooms", rooms);
                              }}
                            />
                            <span> Rooms {values.rooms.length} </span>
                            <IconButton
                              icon={<Icon icon="plus" />}
                              circle
                              appearance="primary"
                              size="xs"
                              onClick={() =>
                                setFieldValue("rooms", [
                                  ..._.cloneDeep(values.rooms),
                                  { ...roomTemplate, uid: v4() },
                                ])
                              }
                            />
                          </div>
                        </div>
                        <div className={classes.rooms}>
                          {values.rooms.map((room, idx) => (
                            <RoomSetup
                              key={idx}
                              idx={idx}
                              room={room}
                              disabled={false}
                              onPaxAdd={(idx, paxType) => {
                                setFieldValue(
                                  `rooms.${idx}.${paxType}`,
                                  room[paxType] + 1
                                );
                                if (paxType === "children") {
                                  setFieldValue(`rooms.${idx}.children_ages`, [
                                    ...values.rooms[idx].children_ages,
                                    2,
                                  ]);
                                }
                              }}
                              onPaxRemove={(idx, paxType) => {
                                setFieldValue(
                                  `rooms.${idx}.${paxType}`,
                                  room[paxType] - 1
                                );
                                if (paxType === "children") {
                                  const ages = [
                                    ...values.rooms[idx].children_ages,
                                  ];
                                  ages.pop();
                                  setFieldValue(
                                    `rooms.${idx}.children_ages`,
                                    ages
                                  );
                                }
                              }}
                              onAgeChange={(roomIdx, childIdx, age) =>
                                setFieldValue(
                                  `rooms.${roomIdx}.children_ages.${childIdx}`,
                                  age
                                )
                              }
                            />
                          ))}
                        </div>
                      </React.Fragment>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          ) : (
            <React.Fragment>
              <p>{msg}</p>
              <div className={classes.initialActions}>
                <Button
                  id="backBtn"
                  appearance="ghost"
                  icon={<Icon icon="list-alt" />}
                  onClick={handleGoBack}>
                  <strong>Back</strong>
                </Button>
                <IconButton
                  appearance="default"
                  icon={<Icon icon="refresh" />}
                  onClick={() => setModalMode("recalculate")}>
                  <strong>Recalculate Trip</strong>
                </IconButton>
                <IconButton
                  id="tripViewModeBtn"
                  appearance="primary"
                  icon={<Icon icon="eye" />}
                  onClick={async () => {
                    await onSetTripMode({ mode: "view" });
                    onHide();
                  }}>
                  <strong>Trip View Mode</strong>
                </IconButton>
              </div>
            </React.Fragment>
          )}
        </div>
        {!loading && modalMode === "recalculate" && (
          <div className={classes.cardActions}>
            <IconButton icon={<Icon icon="close" />} onClick={handleGoBack}>
              <strong>Cancel</strong>
            </IconButton>
            <IconButton
              color="green"
              icon={<Icon icon="check" />}
              onClick={() => onFormikFormSubmit(formRef)}>
              <strong>Apply</strong>
            </IconButton>
          </div>
        )}
      </div>
    </div>
  );
};
TripModeModal.defaultProps = {
  msg: "",
  adults: 0,
  children: 0,
  rooms: [],
};
TripModeModal.propTypes = {
  msg: PropTypes.string,
  adults: PropTypes.number,
  children: PropTypes.number,
  rooms: PropTypes.array,
  countryData: PropTypes.array,
  nationality: PropTypes.string,
  tripStartDate: PropTypes.string,
  onHide: PropTypes.func.isRequired,
  onRecalculateTrip: PropTypes.func.isRequired,
  onSetupChange: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  const { date: tripStartDate } = state.tripPlannerOriginData;
  const { adults, children, nationality, requiredServices } =
    getSetupFormDataSelector(state);
  const rooms = state.tripPlannerAccPax;

  const countryData = getNationalitiesSelector(state);

  return {
    adults,
    children,
    nationality,
    tripStartDate,
    rooms,
    countryData,
    requiredServices,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onRecalculateTrip: (date) => dispatch(recalculateTrip(date)),
    onSetupChange: (key, value) => dispatch(tripSetupChange(key, value)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TripModeModal);
