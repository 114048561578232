import _ from "lodash";
import { currencies } from "@src/definitions";
import { cardStyles, variables } from "@src/jsssetup";
import {
  getServicePricingSelector,
  srvPriceKeyCreator,
} from "@src/selectors/Project/TripPlanner/pricing";
import { isServiceFilterSelectedSelector } from "@src/selectors/Project/TripPlanner/service_filters";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useState } from "react";
import { createUseStyles } from "react-jss";
import { useDispatch, useSelector } from "react-redux";
import { PricingFormContext, TripPlanContext } from "../../TripPlanner";
import { BookingMetadataBtn } from "../../Widgets/step_four/booking_metadata_btn";
import { DayItemControls, daySrvContainerStyles } from "../Day";
import {
  daySrvBodyStyles,
  daySrvHeaderStyles,
  serviceHeaderActions,
} from "../Day/common";
import { BookChecker } from "../ItineraryOverviewCommon";
import OverviewPrice from "../OverviewPrice";
import ServicePricingForm from "../ServicePricingForm";
import { BookedTag, ReservationStatusTag } from "../service_panels";
import { removeTrpAdhocSrv } from "@src/actions/Project/TripPlanner/addhoc/transportation";
import { srvIsBookedSelector } from "@src/selectors/Project/TripPlanner/booking_service_select";
import { useServiceCardExpansion } from "@src/pages/hooks";
import { InlineIcon } from "@iconify/react";

function dater(dt) {
  return DateTime.fromISO(dt).toLocaleString(
    DateTime.DATETIME_MED_WITH_WEEKDAY
  );
}

const CoachDataStyles = createUseStyles({
  CoachService: {
    display: "grid",
    gridGap: variables.half_gap,
  },
  header: {
    ...daySrvHeaderStyles(),
    ...cardStyles.card,
    paddingLeft: variables.double_gap,
    borderRadius: `calc(${variables.normal_gap} / 2)`,
    background: variables.colors.services.trpService,
  },
  headerActions: serviceHeaderActions,
  body: (props) => ({
    ...daySrvBodyStyles({ ...props, expandedHeight: "auto" }),
    ...cardStyles.card,
    zIndex: 1,
    paddingLeft: variables.double_gap,
    background: variables.colors.services.trpServiceBody,
    gridTemplateColumns: "1fr",
    overflow: "hidden",
  }),
  content: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    gridAutoRows: "max-content",
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  title: {
    fontWeight: "600",
    color: variables.colors.text.colored,
  },
  expandIconCntr: {
    display: "grid",
    placeContent: "center",
    width: "1.5rem",
    height: "1.5rem",
    borderRadius: "50%",
    background: variables.colors.easy.orange,
    padding: variables.half_gap,
    cursor: "pointer",
  },
  expandIcon: {
    fontSize: "large",
    color: variables.colors.text.light,
  },
  expandCntr: {
    display: "grid",
    gridTemplateColumns: "max-content 1fr",
    gridGap: variables.normal_gap,
    alignItems: "center",
    marginTop: `calc(${variables.normal_gap} * -1)`,
    padding: [
      `calc(${variables.normal_gap} * 1.2)`,
      0,
      `calc(${variables.normal_gap} * 0.8)`,
      variables.double_gap,
    ],
    boxShadow: variables.shadows.normalShadow,
    borderBottomLeftRadius: `calc(${variables.normal_gap} / 2)`,
    borderBottomRightRadius: `calc(${variables.normal_gap} / 2)`,
    background: variables.colors.services.trpServiceBorder,
  },
  expandedDataCntr: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridColumn: "span 2",
    gridGap: variables.normal_gap,
    width: "100%",
  },
  expandTitle: {
    fontSize: "medium",
    fontWeight: "500",
  },
  arrowCntr: {
    display: "grid",
    placeContent: "center",
    width: `calc(${variables.normal_gap} * 1.5)`,
    height: `calc(${variables.normal_gap} * 1.5)`,
    borderRadius: "50%",
    padding: `calc(${variables.half_gap} / 2)`,
    background: variables.colors.easy.orange,
    cursor: "pointer",
  },
  arrowIcon: {
    fontSize: "medium",
    color: "white",
  },
  divider: {
    borderBottom: `1px solid ${variables.colors.deepgrey}`,
    width: "98%",
  },
});
export const CoachSrvData = ({
  service,
  reservationMode,
  reservationLoading,
  reservationStatus,
  services_collapsed,
  toggleSelfCollapse,
}) => {
  const classes = CoachDataStyles();
  const [expanded, setExpanded] = useState(false);
  const { isBooked, reservation_reference } = useSelector((state) =>
    srvIsBookedSelector(state, {
      reference: service.uid,
      srvType: "COA",
    })
  );

  return (
    <div className={classes.CoachService}>
      <div className={classes.header}>
        <span>
          Coach Service: {service.origin.name_en} to{" "}
          {service.destination.name_en}
        </span>
        <div className={classes.headerActions}>
          {!reservationMode ? (
            <BookingMetadataBtn
              uid={service.uid}
              srv_type="COA"
              booking_metadata={service.booking_metadata}
            />
          ) : (
            <ReservationStatusTag
              reservationLoading={reservationLoading}
              reservationStatus={reservationStatus}
            />
          )}
          {isBooked && (
            <BookedTag reservation_reference={reservation_reference} />
          )}
          <div className={classes.expandIconCntr}>
            <InlineIcon
              className={classes.expandIcon}
              icon={
                services_collapsed
                  ? "ri:arrow-down-s-line"
                  : "ri:arrow-up-s-line"
              }
              onClick={toggleSelfCollapse}
            />
          </div>
        </div>
      </div>
      {services_collapsed ? null : (
        <React.Fragment>
          <div className={classes.body}>
            <div className={classes.content}>
              <span>
                <span className={classes.title}>Departure: </span>
                {service.departure ? dater(service.departure) : "N/A"}
              </span>
              <span>
                <span className={classes.title}>Arrival: </span>
                {service.arrival ? dater(service.arrival) : "N/A"}
              </span>
              <span>
                <span className={classes.title}>Distance: </span>
                {service.distance ? `${service.distance}km` : "N/A"}
              </span>
              <span>
                <span className={classes.title}>Coach Type: </span>
                {service.coach_type ? _.startCase(service.coach_type) : "N/A"}
              </span>
              <span>
                <span className={classes.title}>Company: </span>
                {service.company_name || "N/A"}
              </span>
            </div>
          </div>
          <div className={classes.expandCntr}>
            <span className={classes.expandTitle}>Notes</span>
            <span className={classes.arrowCntr}>
              <InlineIcon
                className={classes.arrowIcon}
                icon={
                  expanded ? "akar-icons:chevron-up" : "akar-icons:chevron-down"
                }
                onClick={() => setExpanded(!expanded)}
              />
            </span>
            {expanded && (
              <div className={classes.expandedDataCntr}>
                <div className={classes.divider} />
                <div className={classes.expandedData}>
                  {service?.booking_information ? (
                    <div className={classes.note}>
                      {service.booking_information}
                    </div>
                  ) : (
                    <div className={classes.note}> N/A</div>
                  )}
                </div>
              </div>
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
CoachSrvData.propTypes = {
  service: PropTypes.object.isRequired,
  reservationMode: PropTypes.bool.isRequired,
  reservationLoading: PropTypes.bool.isRequired,
  reservationStatus: PropTypes.string,
  services_collapsed: PropTypes.bool.isRequired,
  toggleSelfCollapse: PropTypes.func,
};

const CoachServiceStyles = createUseStyles({
  CoachServiceContainer: (props) => ({
    ...daySrvContainerStyles({ currentStep: props.currentStep }),
  }),
});
const CoachService = ({
  srv,
  reservationMode,
  reservationLoading,
  reservationStatus,
  onEdit,
}) => {
  const { service } = srv;
  const currentStep = useSelector((state) => state.tripPlannerCurrentStep);
  const serviceIsSelected = useSelector((state) =>
    isServiceFilterSelectedSelector(state, { service_type: "TR" })
  );

  const { markup, gross_margin, flat_value } = useSelector((state) => {
    return getServicePricingSelector(state, {
      key: srvPriceKeyCreator({ service, service_type: "TR" }),
    });
  });

  const { showPricingForm } = useContext(PricingFormContext);
  const { services_collapsed, toggleSelfCollapse } = useServiceCardExpansion();

  const classes = CoachServiceStyles({ currentStep });

  const price = service.total_price;
  const currency = service.currency;

  const dispatch = useDispatch();
  const onRemove = useCallback(
    ({ service_uid }) => dispatch(removeTrpAdhocSrv({ service_uid })),
    [dispatch]
  );

  return !serviceIsSelected || !service ? null : (
    <div className={classes.CoachServiceContainer}>
      {currentStep === 4 ? (
        <BookChecker uid={service.uid} srv_type="COA" optionable={false} />
      ) : !services_collapsed ? (
        <DayItemControls
          onEdit={() => onEdit({ srv_id: service.uid })}
          onDeselect={() => onRemove({ service_uid: service.uid })}
        />
      ) : (
        <div />
      )}
      <CoachSrvData
        service={service}
        services_collapsed={services_collapsed}
        toggleSelfCollapse={toggleSelfCollapse}
      />
      {showPricingForm ? (
        <ServicePricingForm service_type="COA" service={service} />
      ) : services_collapsed ? (
        <div />
      ) : (
        <OverviewPrice
          searching={false}
          serviceType="MI"
          price={price}
          currency={currency}
          markup={markup}
          gross_margin={gross_margin}
          flat_value={flat_value}
        />
      )}
    </div>
  );
};
CoachService.defaultProps = {
  srv: {},
  reservationMode: false,
};
CoachService.propTypes = {
  srv: PropTypes.object.isRequired,
  reservationMode: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
};
export default CoachService;
