import TransTxt from "@src/components/common/SxFormatMessage";
import { Tag } from "rsuite";

import { formatDate, formatDateTime } from "@src/tools/date_tools";
import { getDisplayNameFromOptions } from "@src/processors/columnsDataProcessor";
import { lang_mapping } from "@src/tools/lang_tools";

import _ from "lodash";
import React from "react";

const statusOption = [
  {
    value: "AC",
    label: "Active",
    color: "green",
  },
  {
    value: "IN",
    label: "Inactive",
    color: "red",
  },
  {
    value: "CA",
    label: "Candidate",
    color: "violet",
  },
];

export const initialInnerGroupPolicyColumnsSetUp = {
  basic: {
    value: ["policy_name", "pax", "distribution_period"],
    choices: [
      {
        key: "policy_name",
        disabled: true,
        display_name: <TransTxt id="policy_name" startCase />,
      },
      {
        key: "pax",
        disabled: false,
        display_name: <TransTxt id="pax" startCase />,
      },
      {
        key: "distribution_period",
        disabled: false,
        display_name: <TransTxt id="distribution_period" startCase />,
      },
      {
        key: "reservation_period",
        disabled: false,
        display_name: <TransTxt id="reservation_period" startCase />,
      },
    ],
  },
  company_users: {
    value: ["sales_partners_categories"],
    choices: [
      {
        key: "sales_partners_categories",
        disabled: false,
        display_name: <TransTxt id="sales_partners_categories" startCase />,
      },
      {
        key: "users_name",
        disabled: false,
        display_name: <TransTxt id="users_name" startCase />,
      },
    ],
  },
  services: {
    value: ["service_categories", "service_type"],
    choices: [
      {
        key: "service_categories",
        disabled: false,
        display_name: <TransTxt id="service_categories" startCase />,
      },
      {
        key: "service_type",
        disabled: false,
        display_name: <TransTxt id="service_type" startCase />,
      },
    ],
  },
  stage: {
    value: ["status"],
    choices: [
      {
        key: "created",
        disabled: false,
        display_name: <TransTxt id="created" startCase />,
      },
      {
        key: "edited",
        disabled: false,
        display_name: <TransTxt id="edited" startCase />,
      },
      {
        key: "creator",
        disabled: false,
        display_name: <TransTxt id="creator" startCase />,
      },
      {
        key: "status",
        disabled: true,
        display_name: <TransTxt id="status" startCase />,
      },
    ],
  },
};

export const initialInnerGroupPolicyColumnsProcessor = {
  sales_partners_categories: {
    key: "sales_partners_categories",
    getter: () => {
      return "AppUser";
    },
  },
  users_name: {
    key: "users_name",
    getter: (data) => {
      const arr = [];
      _.get(data, "entities", []).forEach((el) => {
        arr.push(
          `${_.get(el, "target_entity_info.first_name")} ${_.get(
            el,
            "target_entity_info.last_name"
          )}`
        );
      });
      if (arr.length > 1) {
        return `${Array.from(new Set(arr))[0]}(${
          Array.from(new Set(arr)).length
        })`;
      } else {
        return Array.from(new Set(arr));
      }
    },
    extraGetter: (data) => {
      const arr = [];
      _.get(data, "entities", []).forEach((el) => {
        arr.push(
          `${_.get(el, "target_entity_info.first_name")} ${_.get(
            el,
            "target_entity_info.last_name"
          )}`
        );
      });
      return Array.from(new Set(arr));
    },
  },
  policy_name: {
    key: "policy_name",
    getter: (data) => data.name,
  },
  pax: {
    key: "pax",
    getter: (data) => `${data.pax_from} to ${data.pax_to}`,
  },
  distribution_period: {
    key: "distribution_period",
    getter: (data) =>
      `${formatDate(data.booking_window_from)} to ${formatDate(
        data.booking_window_to
      )}`,
  },
  reservation_period: {
    key: "reservation_period",
    getter: (data) =>
      `${formatDate(data.service_execution_from)} to ${formatDate(
        data.service_execution_to
      )}`,
  },
  service_categories: {
    key: "service_categories",
    getter: (data, options) => {
      const travelServices = options[1];
      const travelServiceOptions = options[2];

      let arr = [];
      _.get(data, "travel_services", []).forEach((el) => {
        _.get(travelServices, "data.results", []).forEach((items) => {
          if (el == items.id) {
            if (!_.isEmpty(travelServiceOptions)) {
              arr.push(
                getDisplayNameFromOptions(
                  items.service_type,
                  travelServiceOptions.service_type.choices
                )
              );
            }
          }
        });
      });
      arr = _.reverse(Array.from(new Set(arr)));
      return `${arr[0]}(${arr.length})`;
    },
    extraGetter: (data, options) => {
      const travelServices = options[1];
      const travelServiceOptions = options[2];

      const arr = [];
      _.get(data, "travel_services").forEach((ele) => {
        if (!_.isEmpty(travelServices)) {
          _.get(travelServices, "data.results", []).forEach((items) => {
            if (ele == items.id) {
              if (!_.isEmpty(travelServiceOptions)) {
                arr.push(
                  getDisplayNameFromOptions(
                    items.service_type,
                    travelServiceOptions.service_type.choices
                  )
                );
              }
            }
          });
        }
      });
      return _.reverse(Array.from(new Set(arr)));
    },
  },
  service_type: {
    key: "service_type",
    getter: (data, options) => {
      const travelServices = options[1];
      const language = options[0];

      const num = _.get(data, "travel_services", []).length;
      const arr = [];
      _.get(data, "travel_services", []).forEach((ele) => {
        _.get(travelServices, "data.results", []).forEach((items) => {
          if (ele == items.id) {
            arr.push(items[`brief_description_${lang_mapping[language]}`]);
          }
        });
      });
      return `${_.reverse(arr)[0]}(${num})`;
    },
    extraGetter: (data, options) => {
      // const { travelServices, language } = options;
      const travelServices = options[1];
      const language = options[0];
      const arr = [];
      _.get(data, "travel_services", []).forEach((ele) => {
        if (!_.isEmpty(travelServices)) {
          _.get(travelServices, "data.results", []).forEach((items) => {
            if (ele == items.id) {
              arr.push(items[`brief_description_${lang_mapping[language]}`]);
            }
          });
        }
      });

      return _.reverse(arr);
    },
  },
  status: {
    key: "status",
    // eslint-disable-next-line react/display-name
    getter: (data) => {
      const tmp = _.get(data, "status");
      const res = _.find(statusOption, (item) => {
        return item.value == tmp;
      });
      return <Tag color={res.color}>{res.label}</Tag>;
    },
    extraGetter: (data) => _.get(data, "status"),
  },
  created: {
    key: "created",
    getter: (data) => formatDateTime(data.created),
  },
  creator: {
    key: "creator",
    getter: (data) =>
      `${_.get(data, "creator_info.first_name")} ${_.get(
        data,
        "creator_info.last_name"
      )}`,
  },
  edited: {
    key: "edited",
    getter: (data) => formatDateTime(data.edited),
  },
};

export const initialInnerGroupPolicyColumnsMapping = {
  policy_name: {
    key: "policy_name",
    fixed: "left",
    width: 130,
  },
  pax: {
    key: "pax",
    fixed: "left",
  },
  distribution_period: {
    key: "distribution_period",
    fixed: "left",
    width: 200,
  },
  reservation_period: {
    key: "reservation_period",
    fixed: "left",
    width: 200,
  },

  sales_partners_categories: {
    key: "sales_partners_categories",
    width: 200,
  },
  users_name: {
    key: "users_name",
    width: 200,
  },
  sales_partners: {
    key: "sales_partners",
    width: 200,
  },
  service_type: {
    key: "service_type",
    width: 200,
  },
  created: {
    key: "created",
    width: 170,
  },
  edited: {
    key: "edited",
    width: 170,
  },
  creator: {
    key: "creator",
    width: 170,
  },
  status: {
    key: "status",
    fixed: "right",
  },
};
