import service from "@src/api/request";
import { VALAQUENTA_API_URL } from "@src/api";
import axios from "axios";

export function fetchCompanyProfile() {
  return service({
    url: `/bid/company-profile/`,
    method: "get",
  });
}

export function getCompanyOptions(lang) {
  return service({
    url: "/bid/company-profile/",
    method: "options",
    headers: {
      "Accept-Language": lang,
    },
  });
}

export function fetchContactPerson(params) {
  return service({
    url: "/bid/contact_person/",
    method: "get",
    params: params,
  });
}

export function submitCompanyProfile(id, payload, mode) {
  return service({
    url: `/bid/company-profile/`,
    method: mode,
    data: payload,
  });
}

export function patchCompanyProfile({ payload }) {
  return service({
    url: `/bid/company-profile/`,
    method: "patch",
    data: payload,
  });
}

export function submitContactPerson(id, payload) {
  return service({
    url: `/bid/contact_person/${id}/`,
    method: "patch",
    data: payload,
  });
}

export function createContactPerson(payload) {
  return service({
    url: `/bid/contact_person/`,
    method: "post",
    data: payload,
  });
}

export async function uploadImage(data) {
  return await axios.post(
    `${VALAQUENTA_API_URL}/reviewer/entity/image/`,
    data,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
}
