import _ from "lodash";
import { NormalInputField } from "@src/components/forms";
import { formStyles, modalGenericStyles, variables } from "@src/jsssetup";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { dayAdhocTypes } from "../AddAddhocServiceModal";

function keyer(reference) {
  return `${reference}___tripContent`;
}

export function getTripContentFromSession({ reference }) {
  const rawTripContent = window.sessionStorage.getItem(keyer(reference));

  if (!rawTripContent) return null;

  try {
    return JSON.parse(rawTripContent);
  } catch (error) {
    return null;
  }
}

export function saveTripContentToSession({ reference, content }) {
  window.sessionStorage.setItem(keyer(reference), content);
}

function generateInitialData({ accommodations = [] } = {}) {
  const accInitialData = {};
  accommodations.map(
    (acc) =>
      (accInitialData[`${acc.destOrder}___${acc.uid}`] = {
        remarks: { en: "" },
      })
  );

  return {
    accommodationGeneralRemarks: { en: "" },
    accommodations: accInitialData,
  };
}

const srvCheckboxStyles = createUseStyles({
  SrvCheckbox: {
    "padding": [0, variables.half_gap],
    "border": `2px solid ${variables.colors.borders.base}`,
    "borderRadius": variables.half_gap,
    "display": "grid",
    "gridGap": variables.half_gap,
    "gridTemplateColumns": "max-content 1fr",
    "alignItems": "center",
    "& label": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },
});
const SrvCheckbox = ({ selectedSrvs, label, uid, srvType }) => {
  const classes = srvCheckboxStyles();
  const val = `${srvType}___${uid}`;
  return (
    <div className={classes.SrvCheckbox}>
      <input
        type="checkbox"
        id={val}
        name={val}
        value={val}
        checked={selectedSrvs.has(val)}
      />
      <label htmlFor={val}>{label}</label>
    </div>
  );
};
SrvCheckbox.propTypes = {
  selectedSrvs: PropTypes.object.isRequired,
  srvType: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  uid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

const srvCategoryCheckboxesStyles = createUseStyles({
  SrvCategoryCheckboxes: {
    display: "grid",
    gridGap: variables.half_gap,
    gridAutoRows: "max-content",
  },
});
const SrvCategoryCheckboxes = ({ selectedSrvs, srvs, srvType, srvLabel }) => {
  const classes = srvCategoryCheckboxesStyles();
  return (
    srvs.length > 0 && (
      <div className={classes.SrvCategoryCheckboxes}>
        <span>
          <strong>{srvLabel}</strong>
        </span>
        {srvs.map(({ uid, label }, idx) => (
          <SrvCheckbox
            selectedSrvs={selectedSrvs}
            srvType={srvType}
            uid={uid}
            label={label}
            key={idx}
          />
        ))}
      </div>
    )
  );
};
SrvCategoryCheckboxes.propTypes = {
  selectedSrvs: PropTypes.object.isRequired,
  srvs: PropTypes.array.isRequired,
  srvType: PropTypes.string.isRequired,
  srvLabel: PropTypes.string.isRequired,
};

const tripServicesStyles = createUseStyles({
  TripServices: {
    ...formStyles.form,
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridGap: variables.half_gap,
    gridAutoRows: "max-content",
  },
  header: {
    gridColumn: "span 3",
    borderBottom: `2px solid ${variables.colors.borders.base}`,
  },
});
const TripServices = ({ services, selectedSrvs, setSelectedSrvs }) => {
  const classes = tripServicesStyles();

  const srvGroups = _.groupBy(services, "service_type");

  const flights = (srvGroups?.TR ?? [])
    .filter((srv) => srv.transportation_service_type === "FL")
    .map((srv) => ({
      uid: srv.uid,
      label: `Flight from ${srv.origin_airport} to ${srv.destination_airport}`,
    }));
  const ferries = (srvGroups?.TR ?? [])
    .filter((srv) => srv.transportation_service_type === "FER")
    .map((srv) => ({
      uid: srv.service.uid,
      label: `Ferry from ${srv.service.origin_port} to ${srv.service.destination_port}`,
    }));
  const trains = (srvGroups?.TR ?? [])
    .filter((srv) => srv.transportation_service_type === "TRA")
    .map((srv) => ({
      uid: srv.service.uid,
      label: `Train from ${srv.service.origin_station} to ${srv.service.destination_station}`,
    }));
  const coaches = (srvGroups?.TR ?? [])
    .filter((srv) => srv.transportation_service_type === "COA")
    .map((srv) => {
      return {
        uid: srv.service.uid,
        label: `Coach from ${srv?.service?.pickup_point} to ${srv?.service?.dropoff_point}`,
      };
    });
  const hotels = (srvGroups?.ACC ?? [])
    .sort((a, b) => parseInt(a.destOrder, 10) - parseInt(b.destOrder, 10))
    .map((srv) => ({
      uid: srv?.uid,
      label: srv?.metadata?.name,
    }));

  const transfers = (srvGroups?.TRF ?? []).map((srv) => ({
    uid: srv.booking_id,
    label: `${srv?.origin?.name} to ${srv?.destination?.name}`,
  }));
  const add_ons = (srvGroups?.MI ?? []).map((srv) => ({
    uid: srv?.search_uid,
    label: srv?.title,
  }));
  const coordinators = (srvGroups?.COO ?? []).map((srv) => ({
    uid: srv?.reference,
    label: srv?.name_en,
  }));
  const restaurants = (srvGroups?.REST ?? []).map((srv) => ({
    uid: srv?.reference,
    label: srv?.name,
  }));
  const general_services = (srvGroups?.GEN ?? []).map((srv) => ({
    uid: srv?.id,
    label: srv?.service_data?.name,
  }));
  const activities = (srvGroups?.ACT ?? []).map((srv) => ({
    uid: srv?.uid,
    label: srv?.title,
  }));
  const ad_hoc_services = (srvGroups?.AD ?? []).map((srv) => ({
    uid: srv?.id,
    label: `${
      dayAdhocTypes?.[srv?.addhoc_service_type] ||
      srv?.addhoc_service_type ||
      "N/A"
    }: ${srv?.description || "N/A"}`,
  }));

  useEffect(() => {
    setSelectedSrvs(
      new Set([
        ...flights.map((s) => `FL___${s.uid}`),
        ...ferries.map((s) => `FER___${s.uid}`),
        ...trains.map((s) => `TRA___${s.uid}`),
        ...coaches.map((s) => `COA___${s.uid}`),
        ...hotels.map((s) => `ACC___${s.uid}`),
        ...transfers.map((s) => `TRF___${s.uid}`),
        ...add_ons.map((s) => `MI___${s.uid}`),
        ...coordinators.map((s) => `COO___${s.uid}`),
        ...restaurants.map((s) => `REST___${s.uid}`),
        ...general_services.map((s) => `GEN___${s.uid}`),
        ...activities.map((s) => `ACT___${s.uid}`),
      ])
    );
  }, []);

  return (
    <form
      className={classes.TripServices}
      onChange={(e) => {
        var setFn = (st) => {
          st.delete(e.target.value);
          return st;
        };
        if (e.target.checked) {
          setFn = (st) => {
            st.add(e.target.value);
            return st;
          };
        }
        setSelectedSrvs((p) => setFn(_.cloneDeep(p)));
      }}>
      <h5 className={classes.header}>Included Services</h5>
      <SrvCategoryCheckboxes
        selectedSrvs={selectedSrvs}
        srvs={flights}
        srvType="FL"
        srvLabel="Flights"
      />
      <SrvCategoryCheckboxes
        selectedSrvs={selectedSrvs}
        srvs={ferries}
        srvType="FER"
        srvLabel="Ferries"
      />
      <SrvCategoryCheckboxes
        selectedSrvs={selectedSrvs}
        srvs={trains}
        srvType="TRA"
        srvLabel="Trains"
      />
      <SrvCategoryCheckboxes
        selectedSrvs={selectedSrvs}
        srvs={coaches}
        srvType="COA"
        srvLabel="Coach"
      />
      <SrvCategoryCheckboxes
        selectedSrvs={selectedSrvs}
        srvs={hotels}
        srvType="ACC"
        srvLabel="Accommodation"
      />
      <SrvCategoryCheckboxes
        selectedSrvs={selectedSrvs}
        srvs={transfers}
        srvType="TRF"
        srvLabel="Transfers"
      />
      <SrvCategoryCheckboxes
        selectedSrvs={selectedSrvs}
        srvs={add_ons}
        srvType="MI"
        srvLabel="Add-ons"
      />
      <SrvCategoryCheckboxes
        selectedSrvs={selectedSrvs}
        srvs={coordinators}
        srvType="COO"
        srvLabel="Coordinators"
      />
      <SrvCategoryCheckboxes
        selectedSrvs={selectedSrvs}
        srvs={restaurants}
        srvType="REST"
        srvLabel="Restaurants"
      />
      <SrvCategoryCheckboxes
        selectedSrvs={selectedSrvs}
        srvs={general_services}
        srvType="GEN"
        srvLabel="General Services"
      />
      <SrvCategoryCheckboxes
        selectedSrvs={selectedSrvs}
        srvs={activities}
        srvType="ACT"
        srvLabel="Activities"
      />
      <SrvCategoryCheckboxes
        selectedSrvs={selectedSrvs}
        srvs={ad_hoc_services}
        srvType="AD"
        srvLabel="Ad-hoc Services"
      />
    </form>
  );
};
TripServices.propTypes = {
  services: PropTypes.array.isRequired,
  selectedSrvs: PropTypes.object.isRequired, // new Set()
  setSelectedSrvs: PropTypes.func.isRequired,
};

const contentStyles = createUseStyles({
  Content: {
    ...modalGenericStyles.cardBody,
    padding: variables.normal_gap,
    display: "grid",
    gridGap: variables.normal_gap,
  },
  forms: { display: "grid", gridGap: variables.normal_gap },
  form: {
    ...formStyles.form,
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: variables.normal_gap,
  },
  form1: {
    ...formStyles.form,
    "display": "grid",
    "gridGap": variables.normal_gap,
    "gridTemplateColumns": "1fr",
    "& textarea": { width: "100%" },
  },
  formHeader: { gridColumn: "span 2" },
  day: {
    display: "grid",
    gridGap: variables.half_gap,
    padding: variables.half_gap,
    border: `2px solid ${variables.colors.borders.base}`,
    borderRadius: "5px",
  },
});
const PDFTripContent = ({
  services,
  accommodations,
  destinations,
  tripContentRef,
  onSubmit,
}) => {
  const classes = contentStyles();

  const [selectedSrvs, setSelectedSrvs] = useState(new Set([]));

  return (
    <div className={classes.Content}>
      <TripServices
        services={services}
        selectedSrvs={selectedSrvs}
        setSelectedSrvs={setSelectedSrvs}
      />
      <Formik
        enableReinitialize={true}
        initialValues={generateInitialData({ accommodations })}
        onSubmit={(values) =>
          onSubmit({ ...values, selectedSrvs: [...selectedSrvs] })
        }>
        {({ values }) => (
          <Form className={classes.forms} ref={tripContentRef}>
            <div className={classes.form1}>
              <h5 className={classes.formHeader}>
                Accommodation General Remarks
              </h5>
              <NormalInputField
                name={`accommodationGeneralRemarks.en`}
                label="General Remarks"
                as="textarea"
              />
            </div>
            <div className={classes.form}>
              <h5 className={classes.formHeader}>Accommodation Remarks</h5>
              {Object.entries(values.accommodations).map(
                ([destacc_code, __], idx) => {
                  const [destOrder, uid] = destacc_code.split("___");
                  const acc = accommodations.find(
                    (acc) =>
                      acc.destOrder.toString() === destOrder && acc.uid === uid
                  );
                  const accName = acc.metadata.name;
                  const destName = destinations?.[destOrder.query] ?? "N/A";
                  const label = `${accName} (${destName})`;

                  return (
                    <NormalInputField
                      key={idx}
                      name={`accommodations.${destacc_code}.remarks.en`}
                      label={label}
                      as="textarea"
                    />
                  );
                }
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
PDFTripContent.propTypes = {
  services: PropTypes.array.isRequired,
  tripContentRef: PropTypes.object.isRequired,
  accommodations: PropTypes.array.isRequired,
  destinations: PropTypes.object.isRequired,
  trip_days: PropTypes.array.isRequired,
  tripContent: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

export default PDFTripContent;
