import _ from "lodash";
import { Steps, Loader } from "rsuite";

import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { hideQuotationPDFModal } from "@src/actions/Project/TripPlanner/modals/quotation_modal";
import OfferPDFBody from "./pdf_body";
import { PDFViewer, usePDF } from "@react-pdf/renderer";
import { getTripDataSelector } from "@src/selectors/Project/TripPlanner/quotation_pdf_selectors";
import { getSetupFormDataSelector } from "@src/selectors/Project/TripPlanner";
import { createUseStyles } from "react-jss";
import { formStyles, modalGenericStyles, variables } from "@src/jsssetup";
import { getTripDaysSelector } from "@src/selectors/Project/TripPlanner/day_by_day_selectors";
import { getServicesPricingSelector } from "@src/selectors/Project/TripPlanner/pricing";
import { Formik, Form } from "formik";
import { NormalInputField } from "@src/components/forms";
import OfferConfigForm from "./offer_config_form";
import {
  fetchProductTerms,
  fetchSubAgentsProfile,
  fetchTripPlan,
} from "@src/api";
import { getServicesListSelector } from "@src/selectors/Project/TripPlanner/generic_service_selectors";
import PDFTripContent, { getTripContentFromSession } from "./content";
import { onFormikFormSubmit } from "@src/components/forms/helpers";
import {
  convertAmountToCurrencySelector,
  getCurrencySelector,
} from "@src/selectors/Financial";
import { CustomButton } from "@src/components/common/buttons";
import { useMutation, useQuery } from "@tanstack/react-query";
import { createDocument } from "@src/api/Documents";
import {
  getUserMetaSelector,
  getUserSourceEntitySelector,
} from "@src/selectors/Shared/user_selectors";
import { toast } from "react-toastify";
import { useFinalSellingPricing } from "@src/pages/hooks/pricing";
import { DateTime } from "luxon";

const stepperStyles = createUseStyles({
  Steps: { padding: variables.normal_gap },
});
export const Stepper = ({ step }) => {
  const classes = stepperStyles();
  return (
    <Steps current={step} className={classes.Steps}>
      <Steps.Item title="Setup Sender" />
      <Steps.Item title="Setup Receiver" />
      <Steps.Item title="Offer Configuration" />
      <Steps.Item title="Trip Content" />
      <Steps.Item title="PDF Overview" />
    </Steps>
  );
};
Stepper.defaultProps = { step: 0 };
Stepper.propTypes = { step: PropTypes.number.isRequired };

export const senderFormStyles = createUseStyles({
  body: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    gridGap: variables.normal_gap,
  },
  actions: modalGenericStyles.cardActions,
  form: {
    display: "grid",
    gridGap: variables.normal_gap,
    gridAutoRows: "1fr max-content",
  },
  fields: formStyles.form,
  col2: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: variables.normal_gap,
  },
  col3: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridGap: variables.normal_gap,
  },
  col4: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridGap: variables.normal_gap,
  },
  col5: {
    display: "grid",
    gridTemplateColumns: "repeat(5, 1fr)",
    gridGap: variables.normal_gap,
  },
  col6: {
    display: "grid",
    gridTemplateColumns: "repeat(6, 1fr)",
    gridGap: variables.normal_gap,
  },
  inputgroups: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    gridAutoRows: "min-content",
    alignItems: "center",
  },
  toggle: { justifySelf: "start" },
  priceInfo: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    gridGap: variables.normal_gap,
  },
  checkboxContainer: {
    padding: variables.normal_gap,
    borderRadius: "5px",
    border: "2px solid black",
    display: "grid",
    gridGap: variables.normal_gap,
  },
  checkbox: {
    color: "black",
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateColumns: "auto 1fr",
  },
});
const SenderForm = ({ sender, onHide, onNextStep }) => {
  const classes = senderFormStyles();

  return (
    <Formik
      initialValues={sender}
      onSubmit={(values) => {
        onNextStep(values);
      }}>
      <div className={classes.body}>
        <Form className={classes.form}>
          <div className={classes.fields}>
            <div className={classes.col4}>
              <NormalInputField label="First Name" name="first_name" />
              <NormalInputField label="Last Name" name="last_name" />
              <NormalInputField label="Email" name="email" />
              <NormalInputField label="Telephone" name="phone" />
            </div>
          </div>
          <div className={classes.actions}>
            <CustomButton appearance="ghost" onClick={onHide}>
              <strong>Cancel</strong>
            </CustomButton>
            <CustomButton appearance="primary" type="submit">
              <strong>Next</strong>
            </CustomButton>
          </div>
        </Form>
      </div>
    </Formik>
  );
};
SenderForm.propTypes = {
  sender: PropTypes.object.isRequired,
  onNextStep: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
};

const ReceiverForm = ({ receiver, onNextStep, onPrevStep }) => {
  const classes = senderFormStyles();

  return (
    <Formik
      initialValues={receiver}
      onSubmit={(values) => {
        onNextStep(values);
      }}>
      <React.Fragment>
        <div className={classes.body}>
          <Form className={classes.form}>
            <div className={classes.fields}>
              <div className={classes.col2}>
                <NormalInputField label="Company" name="company" />
                <NormalInputField label="First Name" name="first_name" />
                <NormalInputField label="Last Name" name="last_name" />
                <NormalInputField label="Email" name="email" />
                <NormalInputField label="Telephone" name="phone" />
              </div>
            </div>
            <div className={classes.actions}>
              <CustomButton appearance="ghost" onClick={onPrevStep}>
                <strong>Back</strong>
              </CustomButton>
              <CustomButton appearance="primary" type="submit">
                <strong>Next</strong>
              </CustomButton>
            </div>
          </Form>
        </div>
      </React.Fragment>
    </Formik>
  );
};
ReceiverForm.propTypes = {
  receiver: PropTypes.object.isRequired,
  onPrevStep: PropTypes.func.isRequired,
  onNextStep: PropTypes.func.isRequired,
};

const PDFOfferAttacher = ({
  offerPDFProps,
  onSuccessfulAttach,
  onFailedAttach,
}) => {
  const source_entity = useSelector((state) =>
    getUserSourceEntitySelector(state)
  );

  const attachPDFMutation = useMutation({
    mutationKey: "attachTripOfferPDF",
    mutationFn: (pdfInstance) => {
      const tags = ["offer_pdf", offerPDFProps.reference];

      const file = new File(
        [pdfInstance.blob],
        `offer_pdf_${offerPDFProps.reference}__${source_entity}.pdf`,
        { type: "application/pdf" }
      );

      const formData = new FormData();
      formData.append("file", file);
      formData.append("source_entity", source_entity);
      formData.append("description", "Trip Offer Pdf");
      tags.forEach((tag) => formData.append("tags", tag));

      return createDocument(formData);
    },
    onSuccess: () => {
      toast.success("PDF Attached to Offer", { autoClose: 3000 });
      onSuccessfulAttach();
    },
    onError: () => {
      toast.error("Error Attaching PDF to Offer", { autoClose: 3000 });
      onFailedAttach();
    },
  });

  const [pdfInstance, __] = usePDF({
    document: !offerPDFProps?.tripContent ? null : (
      <OfferPDFBody {...offerPDFProps} />
    ),
  });

  useEffect(() => {
    if (pdfInstance.loading) return;

    attachPDFMutation.mutate(pdfInstance);
  }, [pdfInstance.loading]);

  return <Loader size="lg" center backdrop />;
};
PDFOfferAttacher.propTypes = {
  offerPDFProps: PropTypes.object.isRequired,
  onSuccessfulAttach: PropTypes.func.isRequired,
  onFailedAttach: PropTypes.func.isRequired,
};

const offerPdfModalStyles = createUseStyles({
  OfferPDFModal: modalGenericStyles.modal,
  card: {
    ...modalGenericStyles.card,
    minWidth: "65rem",
    minHeight: "30vh",
    maxHeight: "95vh",
  },
  header: modalGenericStyles.cardHeader,
  body: modalGenericStyles.cardBody,
  actions: modalGenericStyles.cardActions,
  form: { display: "grid", gridGap: variables.normal_gap },
  col2: {
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  inputgroups: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    gridAutoRows: "min-content",
    alignItems: "center",
  },
  toggle: { justifySelf: "start" },
  priceInfo: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    gridGap: variables.normal_gap,
  },
});
var OfferPDFModal = ({ onHide }) => {
  const classes = offerPdfModalStyles();

  const {
    trip_days,
    legal_title,
    phone,
    address,
    logo,
    linked_trips,
    trip_data,
    services,
    adults,
    children,
    instanceData,
    day_by_day_descriptions,
    reference,
    external_reference,
    custom_cancellation_policy,
    target_entity,
    offerCurrency,
  } = useSelector((state) => {
    const trip_days = getTripDaysSelector(state);
    const { legal_title, phone, address, logo } = state.companyProfile;
    const { linked_trips, reference, external_reference } =
      state.tripPlannerInstanceData;
    const trip_data = getTripDataSelector(state);
    const services = getServicesListSelector(state);

    const { adults, children, target_entity } = getSetupFormDataSelector(state);
    const instanceData = state.tripPlannerInstanceData;
    const day_by_day_descriptions = state.tripPlannerDayByDayContent;
    const custom_cancellation_policy = state.tripPlannerCxlReducer;
    const offerCurrency = state.tripPlannerOfferInstanceData.currency;

    return {
      target_entity,
      trip_days,
      legal_title,
      phone,
      address,
      logo,
      linked_trips,
      trip_data,
      services,
      adults,
      children,
      instanceData,
      day_by_day_descriptions,
      reference,
      external_reference,
      custom_cancellation_policy,
      offerCurrency,
    };
  });
  const { destinations, originData, returnData, pax } = trip_data;

  const userMeta = useSelector((state) => getUserMetaSelector(state));

  const [step, setStep] = useState(0);
  const [sender, setSender] = useState({
    first_name: userMeta?.user?.first_name || "",
    last_name: userMeta?.user?.last_name || "",
    email: userMeta?.user?.email || "",
    phone: userMeta?.phone ?? (userMeta?.mobile_phone || ""),
  });
  const [receiver, setReceiver] = useState({
    company: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
  });

  const [configForm, setConfigForm] = useState({
    offer_size: "summary", //summary__itinerary
    description: "",
    service_remarks: "",
    offer_title: "",
    price_info: false,
    offer_validity: DateTime.now().plus({ days: 2 }).toISO(),
    offer_version: "",
    linked: false,
    linked_trips: [],
    terms_and_conditions_id: "",
    convert_currency: offerCurrency,
    reference_type: "both",
    show_cxl_policy: "false",
  });

  const [loading, setLoading] = useState(false);
  const [termsAndConditionsList, setTermsAndConditionsList] = useState(null);
  const [tripContent, setTripContent] = useState(null);

  useQuery({
    queryKey: ["tripTargetEntity", target_entity],
    queryFn: () =>
      target_entity
        ? fetchSubAgentsProfile(target_entity.split("___")[0])
        : new Promise((resolve) => resolve(null)),
    onSuccess: (data) => {
      const cPerson = data?.contact_person?.[0];
      setReceiver((p) => ({
        ...p,
        email: data?.email ?? "",
        first_name: cPerson?.first_name,
        last_name: cPerson?.last_name,
        phone: cPerson?.mobile_phone ?? cPerson?.phone ?? "",
        company: data?.legal_title,
      }));
    },
    onError: () =>
      toast.error("Error fetching target entity", { autoClose: 3000 }),
    refetchOnWindowFocus: false,
    enabled: (target_entity || "").includes("subagent"),
  });

  async function getLinkedOffers() {
    const promises = linked_trips.map((id) => fetchTripPlan(id));
    await Promise.all(promises)
      .then((trips) => {
        setConfigForm((p) => ({
          ...p,
          linked_trips: trips.map((trp) => {
            trp.selected_trip = false;
            return trp;
          }),
        }));
      })
      .catch(() => setConfigForm((p) => ({ ...p, linked_trips: [] })));
  }

  const targetCurrency = useSelector((state) =>
    getCurrencySelector(state, { currentCurrency: configForm.convert_currency })
  );

  const { sellingAmount, sellingAmountPerPerson, sellingCurrency } =
    useFinalSellingPricing();
  const selling = useSelector(
    (state) =>
      convertAmountToCurrencySelector(state, {
        amount: sellingAmount,
        targetCurrency,
        currentCurrency: sellingCurrency,
      }).convertedAmount
  );

  const pp_selling = useSelector(
    (state) =>
      convertAmountToCurrencySelector(state, {
        amount: sellingAmountPerPerson,
        targetCurrency,
        currentCurrency: sellingCurrency,
      }).convertedAmount
  );

  var priceSegments = [
    {
      currency: configForm.convert_currency,
      amount: selling,
      amount_per_person: pp_selling,
      pax: adults + children,
      coords: 0,
    },
  ];

  const customCancellationPolicy = useSelector((state) => {
    const kwargs = {
      targetCurrency,
      currentCurrency: custom_cancellation_policy.currency,
    };
    const balance = convertAmountToCurrencySelector(state, {
      amount: custom_cancellation_policy.balance,
      ...kwargs,
    }).convertedAmount;
    const deposit = convertAmountToCurrencySelector(state, {
      amount: custom_cancellation_policy.deposit,
      ...kwargs,
    }).convertedAmount;
    const buffer = convertAmountToCurrencySelector(state, {
      amount: custom_cancellation_policy.buffer,
      ...kwargs,
    }).convertedAmount;
    const priceBuffer = convertAmountToCurrencySelector(state, {
      amount: custom_cancellation_policy.priceBuffer,
      ...kwargs,
    }).convertedAmount;
    const newCxl = _.cloneDeep(custom_cancellation_policy);
    newCxl.balance = balance;
    newCxl.deposit = deposit;
    newCxl.buffer = buffer;
    newCxl.priceBuffer = priceBuffer;
    newCxl.currency = targetCurrency?.notation;
    return newCxl;
  });

  async function fetchTerms() {
    setLoading(true);
    const data = await fetchProductTerms();
    setTermsAndConditionsList(data?.results ?? []);
    setLoading(false);
  }

  // Get Terms and Conditions
  useEffect(() => {
    fetchTerms();
  }, []);

  // Load trip content from session
  useEffect(() => {
    getTripContentFromSession({ reference: instanceData.reference });
    const tripContent = getTripContentFromSession({
      reference: instanceData.reference,
    });

    if (!tripContent) return;
    setTripContent(tripContent);
  }, []);

  const tripContentRef = useRef(null);
  const accommodations = services.filter((srv) => srv.service_type === "ACC");

  const offerPDFProps = {
    reference,
    custom_cancellation_policy: customCancellationPolicy,
    external_reference,
    priceSegments,
    trip_days,
    services,
    sender,
    receiver,
    configForm,
    logo,
    pax,
    phone,
    address,
    originData,
    returnData,
    legal_title,
    destinations,
    day_by_day_descriptions: day_by_day_descriptions,
    termsAndConditions: configForm.terms_and_conditions_id
      ? termsAndConditionsList.find(
          (tr) =>
            tr.id.toString() === configForm.terms_and_conditions_id.toString()
        )
      : null,
    tripContent,
    sellingAmount,
    sellingAmountPerPerson,
    sellingCurrency,
  };

  return (
    <div className={classes.OfferPDFModal}>
      {loading ? (
        <div className={classes.card}>
          <div className={classes.header}>
            <h5>Offer PDF</h5>
          </div>
          <div className={classes.body}>
            <Loader center size="lg" />
          </div>
        </div>
      ) : (
        <div className={classes.card}>
          <div className={classes.header}>
            <h5>Offer PDF</h5>
          </div>
          {step !== 5 && <Stepper step={step} />}
          {step === 0 && (
            <SenderForm
              step={step}
              sender={sender}
              onNextStep={(values) => {
                setSender(values);
                setStep((p) => p + 1);
              }}
              onHide={onHide}
            />
          )}
          {step === 1 && (
            <ReceiverForm
              step={step}
              receiver={receiver}
              onPrevStep={() => setStep((p) => p - 1)}
              onNextStep={(values) => {
                setReceiver(values);
                setStep((p) => p + 1);
              }}
            />
          )}
          {step === 2 && (
            <OfferConfigForm
              step={step}
              configForm={configForm}
              termsAndConditionsList={termsAndConditionsList}
              onPrevStep={() => setStep((p) => p - 1)}
              onNextStep={(values) => {
                setConfigForm(values);
                setStep((p) => p + 1);
              }}
              setConfigForm={setConfigForm}
              getLinkedOffers={getLinkedOffers}
            />
          )}
          {step === 3 && (
            <div className={classes.body}>
              <PDFTripContent
                services={services}
                trip_days={trip_days}
                accommodations={accommodations}
                destinations={destinations}
                tripContent={tripContent}
                tripContentRef={tripContentRef}
                onSubmit={(values) => setTripContent(values)}
              />
            </div>
          )}
          {step === 4 && tripContent && (
            <React.Fragment>
              <div className={classes.body}>
                <PDFViewer width="100%" height="500px" showToolbar={true}>
                  <OfferPDFBody {...offerPDFProps} />
                </PDFViewer>
              </div>
            </React.Fragment>
          )}
          {step === 5 && (
            <React.Fragment>
              <PDFOfferAttacher
                offerPDFProps={offerPDFProps}
                onSuccessfulAttach={() => setStep((p) => p - 1)}
                onFailedAttach={() => setStep((p) => p - 1)}
              />
              <div className={classes.body} />
            </React.Fragment>
          )}
          {[3, 4].includes(step) && (
            <div className={classes.actions}>
              <CustomButton appearance="ghost" onClick={onHide}>
                <strong>Cancel</strong>
              </CustomButton>
              <CustomButton
                appearance="ghost"
                onClick={() => setStep((p) => p - 1)}>
                <strong>Back</strong>
              </CustomButton>
              {step === 4 && (
                <CustomButton onClick={() => setStep(5)}>
                  <strong>Attach to Offer</strong>
                </CustomButton>
              )}
              {step === 3 && (
                <React.Fragment>
                  <CustomButton
                    appearance="ghost"
                    onClick={() => setTripContent(null)}>
                    <strong>Reset Content</strong>
                  </CustomButton>
                  <CustomButton
                    appearance="primary"
                    onClick={() => {
                      onFormikFormSubmit(tripContentRef);
                      setStep((p) => p + 1);
                    }}>
                    <strong>Next</strong>
                  </CustomButton>
                </React.Fragment>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
OfferPDFModal.propTypes = { onHide: PropTypes.func.isRequired };
const mapDispatchToProps = (dispatch) => {
  return {
    onHide: () => dispatch(hideQuotationPDFModal()),
  };
};

export default connect(null, mapDispatchToProps)(OfferPDFModal);
