import {
  CUSTOM_SERVICE_ADDON_INIT,
  CUSTOM_SERVICE_ADDON_SET_DESTINATION,
  CUSTOM_SERVICE_ADDON_SET_ITEM,
  CUSTOM_SERVICE_ADDON_ADD_ITEM,
  CUSTOM_SERVICE_ADDON_REMOVE_ITEM,
  CUSTOM_SERVICE_ADDON_MOVEUP_ITEM,
  CUSTOM_SERVICE_ADDON_MOVEDOWN_ITEM,
  CUSTOM_SERVICE_ADDON_CHANGE_BASE_INFO,
  CUSTOM_SERVICE_ADDON_CHANGE_ITEM_INFO,
  CUSTOM_SERVICE_ADDON_NEXT_STEP,
  CUSTOM_SERVICE_ADDON_PREV_STEP,
  CUSTOM_SERVICE_ADDON_ADD_OPERATOR,
  CUSTOM_SERVICE_ADDON_REMOVE_OPERATOR,
  CUSTOM_SERVICE_ADDON_CHANGE_OPERATOR,
  CUSTOM_SERVICE_ADDON_PRICING_INIT,
  CUSTOM_SERVICE_ADDON_PRICING_PRICE_CHANGE,
  CUSTOM_SERVICE_ADDON_RESET_PRICING_PRICE,
  CUSTOM_SERVICE_ADDON_COPY_PRICING_PRICE,
  CUSTOM_SERVICE_ADDON_SHOW_SAVE_MODAL,
  CUSTOM_SERVICE_ADDON_HIDE_SAVE_MODAL,
  CUSTOM_SERVICE_ADDON_LOADING_SAVE_MODAL,
  CUSTOM_SERVICE_ADDON_IDLE_SAVE_MODAL,
  CUSTOM_SERVICE_ADDON_LOAD,
  CUSTOM_SERVICE_ADDON_LOADING,
  CUSTOM_SERVICE_ADDON_IDLE,
  CUSTOM_SERVICE_ADDON_CHANGE_CXL_FEE,
  CUSTOM_SERVICE_ADDON_CHANGE_CXL,
  CUSTOM_SERVICE_ADDON_RESET_PRICING_CXL,
  CUSTOM_SERVICE_ADDON_COPY_PRICING_CXL,
  CUSTOM_SERVICE_ADDON_SHOW_DESCRIPTION_MODAL,
  CUSTOM_SERVICE_ADDON_HIDE_DESCRIPTION_MODAL,
  CUSTOM_SERVICE_ADDON_CHANGE_DESCRIPTION,
  CUSTOM_SERVICE_ADDON_CHANGE_DESCRIPTION_MODAL_LANG,
  CUSTOM_SERVICE_ADDON_ADD_MEETING_POINT_GROUP,
  CUSTOM_SERVICE_ADDON_REMOVE_MEETING_POINT_GROUP,
  CUSTOM_SERVICE_ADDON_CHANGE_MEETING_POINT_SETUP,
  CUSTOM_SERVICE_ADDON_CHANGE_MEETING_POINT_GROUP_MODE,
  CUSTOM_SERVICE_ADDON_LOADING_MEETING_POINT_GROUP,
  CUSTOM_SERVICE_ADDON_IDLE_MEETING_POINT_GROUP,
  CUSTOM_SERVICE_ADDON_SET_MEETING_POINT_AIRPORTS,
  CUSTOM_SERVICE_ADDON_SET_MEETING_POINT_ACCOMMODATIONS,
  CUSTOM_SERVICE_ADDON_SHOW_ADD_CUSTOM_MEETING_POINT_POI_MODAL,
  CUSTOM_SERVICE_ADDON_HIDE_ADD_CUSTOM_MEETING_POINT_POI_MODAL,
  CUSTOM_SERVICE_ADDON_SELECT_MEETING_POINT,
  CUSTOM_SERVICE_ADDON_SELECT_ALL_MEETING_POINTS,
  CUSTOM_SERVICE_ADDON_DESELECT_ALL_MEETING_POINTS,
  CUSTOM_SERVICE_ADDON_SET_CUSTOM_MEETING_POINT,
  CUSTOM_SERVICE_ADDON_SHOW_PAX_SCALLING_MODAL,
  CUSTOM_SERVICE_ADDON_HIDE_PAX_SCALLING_MODAL,
  CUSTOM_SERVICE_ADDON_SET_PAX_GROUPS,
  CUSTOM_SERVICE_ADDON_ADD_STOP_SALES,
  CUSTOM_SERVICE_ADDON_CHANGE_STOP_SALES,
  CUSTOM_SERVICE_ADDON_REMOVE_STOP_SALES,
  CUSTOM_SERVICE_ADDON_SHOW_EDIT_ITEM_MODAL,
  CUSTOM_SERVICE_ADDON_HIDE_EDIT_ITEM_MODAL,
  CUSTOM_SERVICE_ADDON_CHANGE_EDIT_ITEM_CURRENT_LANG,
  CUSTOM_SERVICE_ADDON_EDIT_ITEM_CONTENT,
  CUSTOM_SERVICE_ADDON_UPDATE_ITEM_DISTANCE,
  CUSTOM_SERVICE_ADDON_START_TIME_CHANGE,
  CUSTOM_SERVICE_ADDON_ADD_EXCLUSION_INCLUSION,
  CUSTOM_SERVICE_ADDON_REMOVE_EXCLUSION_INCLUSION,
  CUSTOM_SERVICE_ADDON_CHANGE_EXCLUSION_INCLUSION,
  CUSTOM_SERVICE_ADDON_INDICATIVE_TIME_CHANGE,
  CUSTOM_SERVICE_ADDON_CHANGE_SHORT_DESCRIPTION,
  CUSTOM_SERVICE_ADDON_CHANGE_BOOKING_INFORMATION,
  CUSTOM_SERVICE_ADDON_EDIT_ITEM_IMAGE,
  CUSTOM_SERVICE_ADDON_REMOVE_ITEM_IMAGE,
  CUSTOM_SERVICE_ADDON_EDIT_ITEM_IMAGE_MAIN_PHOTO,
  CUSTOM_SERVICE_ADDON_TOGGLE_ITEM_NON_CUSTOM_IMAGE,
  CUSTOM_SERVICE_ADDON_SHOW_EDIT_ITEM_DEP_META_MODAL,
  CUSTOM_SERVICE_ADDON_HIDE_EDIT_ITEM_DEP_META_MODAL,
  CUSTOM_SERVICE_ADDON_MAP_EDIT_ON,
  CUSTOM_SERVICE_ADDON_MAP_EDIT_OFF,
  CUSTOM_SERVICE_ADDON_ITEM_LOCK_DEPARTURE_META,
  CUSTOM_SERVICE_ADDON_ITEM_UNLOCK_DEPARTURE_META,
  CUSTOM_SERVICE_ADDON_CHANGE_AVAILABILITY,
  CUSTOM_SERVICE_CHANGE_DISTRIBUTION_PERIODS,
  CUSTOM_SERVICE_ADDON_CHANGE_OFFERS,
  CUSTOM_SERVICE_ADDON_CHANGE_ROOMS,
  CUSTOM_SERVICE_ADDON_PATCH,
} from "@src/actions/Operation/CustomServices/AddOn/types";

import _ from "lodash";
import update from "immutability-helper";
import v4 from "uuid/v4";
import moment from "moment";
import { notifyAddOnValidation } from "@src/components/common/notifications/AddOnNotifications";
import { langs } from "@src/config/common";

const customImgTemplate = {
  main_photo: false,
  url: "",
  uid: "",
};

const customServiceAddOnStatusModalInitial = { show: false };
export const customServiceAddOnStatusModal = (
  state = customServiceAddOnStatusModalInitial,
  action
) => {
  switch (action.type) {
    case CUSTOM_SERVICE_ADDON_INIT:
      return customServiceAddOnStatusModalInitial;
    case CUSTOM_SERVICE_ADDON_LOADING:
      return { ...state, show: true };
    case CUSTOM_SERVICE_ADDON_IDLE:
      return { ...state, show: false };
    default:
      return state;
  }
};

export const distributionPeriodTemplate = {
  name: "Period 1",
  distribution_from: "",
  distribution_to: "",
  operation_from: "",
  operation_to: "",
  pricing_data: [],
  cancellation_policy_data: {
    type: "non-refundable",
    days_ahead: 0,
    fee: { value: 0, currency: "" },
  },
  weekdays: [1, 2, 3, 4, 5, 6, 7],
  inventory: 0,
};

const operatorTemplate = {
  name: "",
  type: [],
  languages: [],
  about: "",
};

const stopSalesTemplate = {
  name: "",
  distribution_from: "",
  distribution_to: "",
  operation_from: "",
  operation_to: "",
  status: "IN", // ['AC', 'IN']
};

const inclExclTemplate = { name: "", description_en: "", type: "" };
export const highlightTemplate = {};
Object.entries(langs).forEach((lang) => {
  highlightTemplate[`description_${lang[0]}`] = "";
});

const availDayTemplate = {
  year: 0,
  month: 0,
  day: 0,
  inventory: 0,
};

const addOnInitial = {
  id: null,
  reference: "",
  title: "",
  subtitle: "",
  provider: {},
  website: "",
  access: "PUB",
  internal_rating: 0,
  categories: [],
  themes: [],
  min_max_pax: [1, 4],
  min_adults: 1,
  max_children: 0,
  pax_groups: [],
  mapVersion: 0,
  mapEditMode: false,
  mapEditItemOrders: [],
  currentStep: 1,
  price_type: "per_person", // ['per_person', 'total']
  distribution_periods: [{ ...distributionPeriodTemplate }],
  operators: [{ ...operatorTemplate }],
  stop_sales: [],
  offers: [],
  status: "UV", // ['UV', 'AV']
  booking_mode: "CO", // ['CO', 'RQ']
  start_time: "",
  indicative_pickup_time: "",
  general_description: {},
  general_short_description: {},
  booking_information: {},
  inclusions: [],
  exclusions: [],
  approximate_duration: { hours: "0", minutes: "0" },
  custom_images: [],
  highlights: [],
  availability: {},
  main_youtube_video: "",
  product_version: "",
  redirect_link: "",
  venue_address: "",
  rooms: [],
  purchase_mode: "",
  deposit_size: 0,
  deposit_type_choices: "",
  deposit_type: "",
  balance_deadline_days: 0,
  extra_charges: [],
  required_guest_ages: "CHD",
  room_selection_type: "NOR",
};
export const customServiceAddOn = (state = addOnInitial, action) => {
  const versionUpdater = (state) =>
    update(state, { mapVersion: { $apply: (v) => v + 1 } });

  const periodPriceUpdater = (
    periods,
    meeting_groups,
    pax_groups,
    currency
  ) => {
    return periods.map((period) => {
      const pricing_data = [];

      meeting_groups.forEach((meeting_group) => {
        pax_groups.forEach((pax_group) => {
          var adultPrice = { value: 0 };
          var childPrice = { value: 0 };

          if (Array.isArray(period.pricing_data)) {
            adultPrice = period.pricing_data.find(
              (price) =>
                price.price_type == "adult" &&
                price.meeting_group_uid == meeting_group.uid &&
                price.pax_group_uid == pax_group.uid
            );

            childPrice = period.pricing_data.find(
              (price) =>
                price.price_type == "child" &&
                price.meeting_group_uid == meeting_group.uid &&
                price.pax_group_uid == pax_group.uid
            );
          }

          pricing_data.push({
            price_type: "adult",
            meeting_group_uid: meeting_group.uid,
            pax_group_uid: pax_group.uid,
            currency,
            value: _.get(adultPrice, "value", 0),
          });
          pricing_data.push({
            price_type: "child",
            meeting_group_uid: meeting_group.uid,
            pax_group_uid: pax_group.uid,
            currency,
            value: _.get(childPrice, "value", 0),
          });
        });
      });

      return {
        ...period,
        pricing_data,
        cancellation_policy_data: { ["fee"]: { currency, value: 0 } },
      };
    });
  };

  switch (action.type) {
    case CUSTOM_SERVICE_ADDON_LOADING:
      return update(state, { loading: { $set: true } });
    case CUSTOM_SERVICE_ADDON_IDLE:
      return update(state, { loading: { $set: false } });
    case CUSTOM_SERVICE_ADDON_INIT:
      return addOnInitial;
    case CUSTOM_SERVICE_ADDON_LOAD: {
      const { data } = action;
      const addOn = _.omit(data.service_data, [
        "destination",
        "items",
        "currentStep",
        "meeting_point_groups",
      ]);

      const {
        purchase_mode,
        deposit_size,
        deposit_type,
        balance_deadline_days,
      } = data;

      return _.cloneDeep({
        ...state,
        ...addOn,
        id: data.id,
        reference: data.reference,
        mapVersion: state.mapVersion + 1,
        status: data.status,
        booking_mode: data.booking_mode,
        images: data.images,
        purchase_mode,
        deposit_size,
        deposit_type,
        balance_deadline_days,
      });
    }
    case CUSTOM_SERVICE_ADDON_NEXT_STEP: {
      if (state.currentStep == 3) {
        return state;
      }

      return update(state, { currentStep: { $apply: (c) => c + 1 } });
    }
    case CUSTOM_SERVICE_ADDON_PREV_STEP: {
      if (state.currentStep == 1) {
        return state;
      }

      return update(state, { currentStep: { $apply: (c) => c - 1 } });
    }
    case CUSTOM_SERVICE_ADDON_PATCH: {
      const { data } = action;
      return update(state, { $merge: data });
    }
    case CUSTOM_SERVICE_ADDON_CHANGE_BASE_INFO: {
      const { key, value } = action;
      return update(state, { [key]: { $set: value } });
    }
    case CUSTOM_SERVICE_ADDON_SET_ITEM:
      return versionUpdater(state);
    case CUSTOM_SERVICE_ADDON_CHANGE_ITEM_INFO: {
      return versionUpdater(state);
    }
    case CUSTOM_SERVICE_ADDON_REMOVE_ITEM:
      return versionUpdater(state);
    case CUSTOM_SERVICE_ADDON_MOVEDOWN_ITEM:
      return versionUpdater(state);
    case CUSTOM_SERVICE_ADDON_MOVEUP_ITEM:
      return versionUpdater(state);
    case CUSTOM_SERVICE_ADDON_HIDE_EDIT_ITEM_MODAL:
      return versionUpdater(state);
    case CUSTOM_SERVICE_CHANGE_DISTRIBUTION_PERIODS: {
      const { distribution_periods } = action;
      return { ...state, distribution_periods };
    }
    case CUSTOM_SERVICE_ADDON_ADD_OPERATOR:
      return update(state, { operators: { $push: [{ ...operatorTemplate }] } });
    case CUSTOM_SERVICE_ADDON_REMOVE_OPERATOR: {
      const { idx } = action;
      return update(state, {
        operators: {
          $apply: (operators) => operators.filter((o, oidx) => oidx !== idx),
        },
      });
    }
    case CUSTOM_SERVICE_ADDON_CHANGE_OPERATOR: {
      const { idx, key, value } = action;
      return update(state, {
        operators: { [idx]: { [key]: { $set: value } } },
      });
    }
    case CUSTOM_SERVICE_ADDON_PRICING_INIT: {
      const { currency, meeting_groups } = action;
      var newState = state;
      if (!state.pax_groups.length) {
        newState = {
          ...newState,
          pax_groups: [
            {
              min_pax: newState.min_max_pax[0],
              max_pax: newState.min_max_pax[1],
              min_adults: newState.min_max_pax[0],
              max_adults: newState.min_max_pax[1],
              max_children: 0,
              uid: v4(),
            },
          ],
        };
      }

      const price_mgroup_uids = [
        ...new Set(
          _.flatten(
            newState.distribution_periods.map((period) => {
              if (!Array.isArray(period.pricing_data)) {
                return null;
              }

              return _.get(period, "pricing_data", []).map(
                (group_price) => group_price.meeting_group_uid
              );
            })
          )
        ),
      ].filter((uid) => uid);

      const meeting_group_uids = meeting_groups.map((group) => group.uid);

      if (
        newState.distribution_periods.every((period) =>
          Array.isArray(_.get(period, "pricing_data"))
        ) &&
        newState.distribution_periods.every(
          (period) => _.get(period, "pricing_data", []).length
        ) &&
        meeting_group_uids.length == price_mgroup_uids.length &&
        meeting_group_uids.every((uid) => price_mgroup_uids.includes(uid))
      ) {
        return newState;
      } else {
        return update(newState, {
          distribution_periods: {
            $apply: (periods) =>
              periodPriceUpdater(
                periods,
                meeting_groups,
                newState.pax_groups,
                currency
              ),
          },
        });
      }
    }
    case CUSTOM_SERVICE_ADDON_PRICING_PRICE_CHANGE: {
      const { distIdx, meeting_group_uid, pax_group_uid, price_type, value } =
        action;

      return update(state, {
        distribution_periods: {
          [distIdx]: {
            pricing_data: {
              $apply: (data) => {
                return data.map((datum) =>
                  datum.meeting_group_uid !== meeting_group_uid ||
                  datum.pax_group_uid !== pax_group_uid ||
                  datum.price_type !== price_type
                    ? datum
                    : { ...datum, value }
                );
              },
            },
          },
        },
      });
    }
    case CUSTOM_SERVICE_ADDON_RESET_PRICING_PRICE: {
      const { distIdx } = action;
      return update(state, {
        distribution_periods: {
          [distIdx]: {
            pricing_data: {
              $apply: (data) => data.map((price) => ({ ...price, value: 0 })),
            },
          },
        },
      });
    }
    case CUSTOM_SERVICE_ADDON_COPY_PRICING_PRICE: {
      const { distIdx } = action;
      const pricingData = state.distribution_periods[distIdx].pricing_data;
      return update(state, {
        distribution_periods: {
          [distIdx + 1]: {
            pricing_data: {
              $apply: (data) =>
                data.map((datum) => {
                  const pData = pricingData.find(
                    (pd) =>
                      pd.meeting_group_uid == datum.meeting_group_uid &&
                      pd.pax_group_uid == datum.pax_group_uid &&
                      pd.price_type == datum.price_type
                  );
                  return { ...datum, value: pData.value };
                }),
            },
          },
        },
      });
    }
    case CUSTOM_SERVICE_ADDON_CHANGE_CXL_FEE: {
      const { distIdx, value } = action;
      return update(state, {
        distribution_periods: {
          [distIdx]: {
            cancellation_policy_data: { fee: { value: { $set: value } } },
          },
        },
      });
    }
    case CUSTOM_SERVICE_ADDON_CHANGE_CXL: {
      const { distIdx, key, value } = action;
      return update(state, {
        distribution_periods: {
          [distIdx]: { cancellation_policy_data: { [key]: { $set: value } } },
        },
      });
    }
    case CUSTOM_SERVICE_ADDON_RESET_PRICING_CXL: {
      const { distIdx } = action;

      return update(state, {
        distribution_periods: {
          [distIdx]: {
            cancellation_policy_data: {
              $set: {
                type: "non-refundable",
                days_ahead: 0,
                fee: { value: 0, currency: "" },
              },
            },
          },
        },
      });
    }
    case CUSTOM_SERVICE_ADDON_COPY_PRICING_CXL: {
      const { distIdx } = action;

      const cxlData =
        state.distribution_periods[distIdx].cancellation_policy_data;
      return update(state, {
        distribution_periods: {
          [distIdx + 1]: { cancellation_policy_data: { $set: cxlData } },
        },
      });
    }
    case CUSTOM_SERVICE_ADDON_SET_PAX_GROUPS: {
      const { currency, pax_groups, meeting_groups } = action;

      return update(state, {
        pax_groups: { $set: pax_groups },
        distribution_periods: {
          $apply: (periods) =>
            periodPriceUpdater(periods, meeting_groups, pax_groups, currency),
        },
      });
    }
    case CUSTOM_SERVICE_ADDON_ADD_STOP_SALES: {
      const { idx } = action;
      if (!idx) {
        return {
          ...state,
          stop_sales: [
            ...state.stop_sales,
            ...[
              {
                ...stopSalesTemplate,
                name: `Stop Sales ${state.stop_sales.length + 2}`,
              },
            ],
          ],
        };
      } else {
        return state;
      }
    }
    case CUSTOM_SERVICE_ADDON_CHANGE_STOP_SALES: {
      const { stop_sales } = action;
      return update(state, { stop_sales: { $set: stop_sales } });
    }
    case CUSTOM_SERVICE_ADDON_REMOVE_STOP_SALES: {
      const { idx } = action;
      const stop_sales = [...state.stop_sales];
      stop_sales.splice(idx, 1);
      return { ...state, stop_sales: [...stop_sales] };
    }
    case CUSTOM_SERVICE_ADDON_CHANGE_OFFERS: {
      const { offers } = action;
      return update(state, { offers: { $set: offers } });
    }
    case CUSTOM_SERVICE_ADDON_CHANGE_DESCRIPTION: {
      const { lang, description } = action;
      return update(state, {
        ["general_description"]: {
          [`description_${lang}`]: { $set: description },
        },
      });
    }
    case CUSTOM_SERVICE_ADDON_CHANGE_SHORT_DESCRIPTION: {
      const { lang, description } = action;

      if (description.length > 300) {
        notifyAddOnValidation([
          "Short description cannot be more than 300 characters.",
        ]);
        return state;
      }

      return update(state, {
        ["general_short_description"]: {
          [`description_${lang}`]: { $set: description },
        },
      });
    }
    case CUSTOM_SERVICE_ADDON_CHANGE_BOOKING_INFORMATION: {
      const { lang, description } = action;
      return update(state, {
        ["booking_information"]: {
          [`description_${lang}`]: { $set: description },
        },
      });
    }
    case CUSTOM_SERVICE_ADDON_START_TIME_CHANGE: {
      const { start_time } = action;
      return { ...state, start_time };
    }
    case CUSTOM_SERVICE_ADDON_INDICATIVE_TIME_CHANGE: {
      const { pickup_time } = action;
      return { ...state, indicative_pickup_time: pickup_time };
    }
    case CUSTOM_SERVICE_ADDON_ADD_EXCLUSION_INCLUSION: {
      const { dataType } = action;

      if (dataType == "exclusion") {
        return {
          ...state,
          exclusions: [
            ...state.exclusions,
            ...[{ ...inclExclTemplate, uid: v4(), type: "exclusion" }],
          ],
        };
      } else {
        return {
          ...state,
          inclusions: [
            ...state.inclusions,
            ...[{ ...inclExclTemplate, uid: v4(), type: "inclusion" }],
          ],
        };
      }
    }
    case CUSTOM_SERVICE_ADDON_REMOVE_EXCLUSION_INCLUSION: {
      const { uid, dataType } = action;
      if (dataType == "exclusion") {
        return {
          ...state,
          exclusions: state.exclusions.filter((dat) => dat.uid !== uid),
        };
      } else {
        return {
          ...state,
          inclusions: state.inclusions.filter((dat) => dat.uid !== uid),
        };
      }
    }
    case CUSTOM_SERVICE_ADDON_CHANGE_EXCLUSION_INCLUSION: {
      const { dataType, uid, key, value } = action;

      if (dataType == "exclusion") {
        return {
          ...state,
          exclusions: state.exclusions.map((dat) =>
            dat.uid == uid ? { ...dat, [key]: value } : dat
          ),
        };
      } else {
        return {
          ...state,
          inclusions: state.inclusions.map((dat) =>
            dat.uid == uid ? { ...dat, [key]: value } : dat
          ),
        };
      }
    }
    case CUSTOM_SERVICE_ADDON_MAP_EDIT_ON: {
      const { itemOrders } = action;
      return { ...state, mapEditMode: true, mapEditItemOrders: itemOrders };
    }
    case CUSTOM_SERVICE_ADDON_MAP_EDIT_OFF: {
      return { ...state, mapEditMode: false, mapEditItemOrders: [] };
    }
    case CUSTOM_SERVICE_ADDON_CHANGE_AVAILABILITY: {
      const { year, month, day, inventory } = action;
      const payload = { ...{ year, month, day, inventory } };

      return update(state, {
        availability: (availability) =>
          update(availability || {}, {
            [year]: (year) =>
              update(year || {}, {
                [month]: (month) =>
                  update(month || {}, {
                    [day]: (day) =>
                      update(day || { ...availDayTemplate }, {
                        $set: payload,
                      }),
                  }),
              }),
          }),
      });
    }
    case CUSTOM_SERVICE_ADDON_CHANGE_ROOMS: {
      const { rooms } = action;
      return update(state, { rooms: { $set: rooms } });
    }
    default:
      return state;
  }
};

const customServiceAddOnDestinationInitial = { destination: {} };
export const customServiceAddOnDestination = (
  state = customServiceAddOnDestinationInitial,
  action
) => {
  switch (action.type) {
    case CUSTOM_SERVICE_ADDON_INIT:
      return customServiceAddOnDestinationInitial;
    case CUSTOM_SERVICE_ADDON_LOAD: {
      const { data } = action;
      return { ...state, ...data.service_data.destination };
    }
    case CUSTOM_SERVICE_ADDON_SET_DESTINATION: {
      const { destData } = action;
      return update(state, { destination: { $set: destData } });
    }
    default:
      return state;
  }
};

const customServiceAddOnItemInitial = [];
export const customServiceAddOnItems = (
  state = customServiceAddOnItemInitial,
  action
) => {
  const template = {
    data: {
      name_en: "",
      name_cn: "",
      country: { code: "" },
      geodata: { lat: 0, lng: 0 },
      custom_images: [{ ...customImgTemplate }],
      custom: false, //signifies if the user has added custom POI basic data.
      // like name, geolocation or country.
    },
    visit_duration: 0,
    arrival_time: "",
    transportation_mode: "transfer", // ['on_foot', 'transfer']
    type: "", // ['highlight', 'shopping', 'food_and_drink'],
    description_en: "",
    description_cn: "",
    description_it: "",
    description_es: "",
    description_fr: "",
    description_el: "",
    description_nl: "",
    description_de: "",
    description_pt: "",
    departure_route: { distance: 0, duration: 0, geometry: {}, lock: false },
  };

  const orderer = (items) =>
    items.map((i, idx) => ({
      ...i,
      order: idx + 1,
    }));

  const timeUpdater = (items, idx) => {
    const prevItem = items[idx - 1];
    const prevTransitDuration = parseInt(
      _.get(prevItem, "departure_route.duration", 0) / 60,
      10
    );
    const prevVisitDuration = prevItem.visit_duration;
    const prevArrivalTime = moment()
      .hour(_.get(prevItem, "arrival_time", "0:0").split(":")[0])
      .minute(_.get(prevItem, "arrival_time", "0:0").split(":")[1]);

    if (!moment.isMoment(prevArrivalTime)) {
      return moment().format("HH:mm");
    }

    const arrival_time = prevArrivalTime
      .clone()
      .add(prevVisitDuration + prevTransitDuration, "minutes")
      .format("HH:mm");

    return arrival_time;
  };

  switch (action.type) {
    case CUSTOM_SERVICE_ADDON_INIT:
      return customServiceAddOnItemInitial;
    case CUSTOM_SERVICE_ADDON_LOAD: {
      const { data } = action;
      return data.service_data.items.map((item) => {
        const custom_images = _.get(item, "data.custom_images", []);
        const initial_length = custom_images.length;
        if (initial_length < 4) {
          for (let i = 0; i < 4 - initial_length; i++) {
            item.data["custom_images"].push({ url: "", main: false, uuid: "" });
          }
        }

        return {
          ...template,
          ...item,
          data: {
            ...template.data,
            ...item.data,
          },
        };
      });
    }
    case CUSTOM_SERVICE_ADDON_ADD_ITEM: {
      const { order, start_time: arrival_time } = action;
      if (!order) {
        return [
          ...state,
          { order: state.length + 1, ...template, arrival_time },
        ];
      } else {
        const newState = [...state];
        newState.splice(order, 0, { order, ...template });
        return [...orderer(newState)].map((item, idx) =>
          idx == 0 ? { ...item, arrival_time } : item
        );
      }
    }
    case CUSTOM_SERVICE_ADDON_SET_ITEM: {
      const { order, data } = action;
      data.custom_images = _.cloneDeep(template.data.custom_images);

      return update(state, {
        $apply: (items) => {
          const newItems = [];
          items.forEach((i, idx) =>
            order - 1 == idx
              ? newItems.push({
                  ...i,
                  data,
                  description_en: data.rich_full_description_en,
                  description_cn: data.rich_full_description_cn,
                })
              : newItems.push(i)
          );
          return newItems;
        },
      });
    }
    case CUSTOM_SERVICE_ADDON_CHANGE_ITEM_INFO: {
      const { order, key, value } = action;

      let newState = update(state, {
        $apply: (items) =>
          items.map((item) => {
            if (item.order == order) {
              return { ...item, ...{ [key]: value } };
            } else {
              return item;
            }
          }),
      });

      return update(newState, {
        $apply: (items) =>
          items.map((item, idx) => {
            var arrival_time = item.arrival_time;
            if (idx > 0) {
              arrival_time = timeUpdater(items, idx);
            }

            return { ...item, arrival_time };
          }),
      });
    }
    case CUSTOM_SERVICE_ADDON_REMOVE_ITEM: {
      const { order, start_time: arrival_time } = action;
      return [...orderer(state.filter((i) => i.order != order))].map(
        (item, idx) => (idx == 0 ? { ...item, arrival_time } : item)
      );
    }
    case CUSTOM_SERVICE_ADDON_MOVEUP_ITEM: {
      const { order, start_time: arrival_time } = action;
      const items = state.filter((i) => i.order !== order);
      const movingItem = state.find((i) => i.order == order);

      items.splice(order - 2, 0, movingItem);
      return [...orderer(items)].map((item, idx) =>
        idx == 0 ? { ...item, arrival_time } : item
      );
    }
    case CUSTOM_SERVICE_ADDON_MOVEDOWN_ITEM: {
      const { order, start_time: arrival_time } = action;
      const items = state.filter((i) => i.order !== order);
      const movingItem = state.find((i) => i.order == order);

      items.splice(order, 0, movingItem);

      return [...orderer(items)].map((item, idx) =>
        idx == 0 ? { ...item, arrival_time } : item
      );
    }
    case CUSTOM_SERVICE_ADDON_EDIT_ITEM_CONTENT: {
      const { itemOrder, key, value } = action;
      return update(state, { [itemOrder - 1]: { [key]: { $set: value } } });
    }
    case CUSTOM_SERVICE_ADDON_UPDATE_ITEM_DISTANCE: {
      const { data } = action;

      let newState = update(state, {
        $apply: (items) => {
          return items.map((item) => {
            return item.order == data.couple[0].order
              ? { ...item, departure_route: data.route }
              : item;
          });
        },
      });

      return update(newState, {
        $apply: (items) => {
          return items.map((item, idx) => {
            var arrival_time = item.arrival_time;
            if (idx > 0) {
              arrival_time = timeUpdater(items, idx);
            }

            return { ...item, arrival_time };
          });
        },
      });
    }
    case CUSTOM_SERVICE_ADDON_ITEM_LOCK_DEPARTURE_META: {
      const { itemOrder } = action;
      return update(state, {
        [itemOrder - 1]: { departure_route: { lock: { $set: true } } },
      });
    }
    case CUSTOM_SERVICE_ADDON_ITEM_UNLOCK_DEPARTURE_META: {
      const { itemOrder } = action;
      return update(state, {
        [itemOrder - 1]: { departure_route: { lock: { $set: false } } },
      });
    }
    case CUSTOM_SERVICE_ADDON_START_TIME_CHANGE: {
      const { start_time: arrival_time } = action;
      let newState = update(state, {
        $apply: (items) =>
          items.map((item, idx) =>
            idx == 0 ? { ...item, arrival_time } : item
          ),
      });
      return update(newState, {
        $apply: (items) =>
          items.map((item, idx) => {
            var arrival_time = item.arrival_time;
            if (idx > 0) {
              arrival_time = timeUpdater(items, idx);
            }

            return { ...item, arrival_time };
          }),
      });
    }
    case CUSTOM_SERVICE_ADDON_EDIT_ITEM_IMAGE: {
      const {
        image_type,
        images,
        itemOrder, // data, list
      } = action;

      return update(state, {
        [itemOrder - 1]: { data: { [image_type]: { $set: images } } },
      });
    }
    case CUSTOM_SERVICE_ADDON_REMOVE_ITEM_IMAGE: {
      const { itemOrder, key, value } = action;
      return update(state, {
        [itemOrder - 1]: {
          data: {
            [key]: {
              $apply: (items) => {
                return items.filter((e) => e.id !== value);
              },
            },
          },
        },
      });
    }
    case CUSTOM_SERVICE_ADDON_EDIT_ITEM_IMAGE_MAIN_PHOTO: {
      const { key, value, itemOrder, id } = action;
      const newState = _.cloneDeep(state);
      const images = newState[itemOrder - 1].data.custom_images;
      images.forEach((e, idx) => {
        if (idx == id) {
          e[key] = value;
        } else {
          if (value) {
            e[key] = false;
          }
        }
      });

      return update(state, {
        [itemOrder - 1]: {
          data: {
            custom_images: {
              $set: images,
            },
          },
        },
      });
    }
    case CUSTOM_SERVICE_ADDON_TOGGLE_ITEM_NON_CUSTOM_IMAGE: {
      const { itemOrder, imgIdxs, status } = action;
      var newState = state;
      imgIdxs.forEach((idx) => {
        newState = update(newState, {
          [itemOrder - 1]: {
            data: {
              image_set: {
                [idx]: { ["enabled"]: { $set: status } },
              },
            },
          },
        });
      });
      return newState;
    }
    default:
      return state;
  }
};

const customServiceSaveModalInitial = { show: false, loading: false };
export const customServiceSaveModal = (
  state = customServiceSaveModalInitial,
  action
) => {
  switch (action.type) {
    case CUSTOM_SERVICE_ADDON_INIT:
      return customServiceSaveModalInitial;
    case CUSTOM_SERVICE_ADDON_SHOW_SAVE_MODAL:
      return { ...state, show: true };
    case CUSTOM_SERVICE_ADDON_HIDE_SAVE_MODAL:
      return customServiceSaveModalInitial;
    case CUSTOM_SERVICE_ADDON_LOADING_SAVE_MODAL:
      return { ...state, loading: true };
    case CUSTOM_SERVICE_ADDON_IDLE_SAVE_MODAL:
      return { ...state, loading: false };
    default:
      return state;
  }
};

const customGenDescriptionModalInitial = {
  show: false,
  loading: false,
  current_lang: "en",
  description_en: "",
  description_cn: "",
  description_it: "",
  description_es: "",
  description_fr: "",
  description_el: "",
  description_nl: "",
  description_de: "",
  description_pt: "",
  short_description_en: "",
  short_description_cn: "",
  short_description_it: "",
  short_description_es: "",
  short_description_fr: "",
  short_description_el: "",
  short_description_nl: "",
  short_description_de: "",
  short_description_pt: "",
};
export const customGenDescriptionModal = (
  state = customGenDescriptionModalInitial,
  action
) => {
  switch (action.type) {
    case CUSTOM_SERVICE_ADDON_INIT:
      return customGenDescriptionModalInitial;
    case CUSTOM_SERVICE_ADDON_LOAD: {
      const { data } = action;

      return {
        ...state,
        ...data.service_data.general_description,
      };
    }
    case CUSTOM_SERVICE_ADDON_SHOW_DESCRIPTION_MODAL:
      return { ...state, show: true };
    case CUSTOM_SERVICE_ADDON_HIDE_DESCRIPTION_MODAL:
      return { ...state, show: false };
    case CUSTOM_SERVICE_ADDON_CHANGE_DESCRIPTION_MODAL_LANG: {
      const { lang } = action;
      return { ...state, current_lang: lang };
    }
    default:
      return state;
  }
};

const group = {
  mode: "setup", // ["setup", "list"]
  loading: false,
  setup: {
    type: ["hotels"], // ["hotels", "airports", "custom"]
    rating: [],
    nearby: {},
    radius: 1,
  },
  points: {
    custom: [],
    airports: [],
    hotels: [],
  },
};
const customMeetingPointGroupsInitial = [];
export const customMeetingPointGroups = (
  state = customMeetingPointGroupsInitial,
  action
) => {
  switch (action.type) {
    case CUSTOM_SERVICE_ADDON_INIT:
      return customMeetingPointGroupsInitial;
    case CUSTOM_SERVICE_ADDON_LOAD: {
      const { data } = action;
      return _.get(data, "service_data.meeting_point_groups", []).map(
        (group) => ({
          ...group,
          mode: "list",
          loading: false,
        })
      );
    }
    case CUSTOM_SERVICE_ADDON_ADD_MEETING_POINT_GROUP: {
      const { groupIdx } = action;
      if (groupIdx) {
        return [...state.splice(groupIdx + 1, 0, { ...group, uid: v4() })];
      } else {
        return [...state, { ...group, uid: v4() }];
      }
    }
    case CUSTOM_SERVICE_ADDON_REMOVE_MEETING_POINT_GROUP: {
      const { groupIdx } = action;
      return state.filter((group, idx) => idx !== groupIdx);
    }
    case CUSTOM_SERVICE_ADDON_CHANGE_MEETING_POINT_SETUP: {
      const { groupIdx, key, value } = action;
      return update(state, {
        [groupIdx]: { setup: { [key]: { $set: value } } },
      });
    }
    case CUSTOM_SERVICE_ADDON_CHANGE_MEETING_POINT_GROUP_MODE: {
      const { groupIdx, mode } = action;
      return update(state, { [groupIdx]: { mode: { $set: mode } } });
    }
    case CUSTOM_SERVICE_ADDON_LOADING_MEETING_POINT_GROUP: {
      const { groupIdx } = action;
      return update(state, { [groupIdx]: { loading: { $set: true } } });
    }
    case CUSTOM_SERVICE_ADDON_IDLE_MEETING_POINT_GROUP: {
      const { groupIdx } = action;
      return update(state, { [groupIdx]: { loading: { $set: false } } });
    }
    case CUSTOM_SERVICE_ADDON_SET_MEETING_POINT_AIRPORTS: {
      const { groupIdx, airports } = action;
      return update(state, {
        [groupIdx]: { points: { airports: { $set: airports } } },
      });
    }
    case CUSTOM_SERVICE_ADDON_SET_MEETING_POINT_ACCOMMODATIONS: {
      const { groupIdx, accommodations } = action;
      return update(state, {
        [groupIdx]: { points: { hotels: { $set: accommodations } } },
      });
    }
    case CUSTOM_SERVICE_ADDON_SELECT_MEETING_POINT: {
      const { groupIdx, pointType, id, selected } = action;
      const pointKey =
        pointType == "custom"
          ? "custom"
          : pointType == "airport"
          ? "airports"
          : "hotels";
      const pointIdKey = pointType == "airport" ? "iata" : "id";
      return update(state, {
        [groupIdx]: {
          points: {
            [pointKey]: {
              $apply: (poi) => {
                return poi.map((p) =>
                  p[pointIdKey] == id ? { ...p, selected } : p
                );
              },
            },
          },
        },
      });
    }
    case CUSTOM_SERVICE_ADDON_SELECT_ALL_MEETING_POINTS: {
      const { groupIdx } = action;
      return update(state, {
        [groupIdx]: {
          points: {
            airports: {
              $apply: (points) =>
                points.map((point) => ({ ...point, selected: true })),
            },
            hotels: {
              $apply: (points) =>
                points.map((point) => ({ ...point, selected: true })),
            },
            custom: {
              $apply: (points) =>
                points.map((point) => ({ ...point, selected: true })),
            },
          },
        },
      });
    }
    case CUSTOM_SERVICE_ADDON_DESELECT_ALL_MEETING_POINTS: {
      const { groupIdx } = action;
      return update(state, {
        [groupIdx]: {
          points: {
            airports: {
              $apply: (points) =>
                points.map((point) => ({ ...point, selected: false })),
            },
            hotels: {
              $apply: (points) =>
                points.map((point) => ({ ...point, selected: false })),
            },
            custom: {
              $apply: (points) =>
                points.map((point) => ({ ...point, selected: false })),
            },
          },
        },
      });
    }
    case CUSTOM_SERVICE_ADDON_SET_CUSTOM_MEETING_POINT: {
      const { groupIdx, point } = action;
      return update(state, {
        [groupIdx]: { points: { custom: { $push: [point] } } },
      });
    }
    default:
      return state;
  }
};

const customMeetingPointPOIModalInitial = {
  show: false,
  loading: false,
  groupIdx: null,
};
export const customMeetingPointPOIModal = (
  state = customMeetingPointPOIModalInitial,
  action
) => {
  switch (action.type) {
    case CUSTOM_SERVICE_ADDON_INIT:
      return customMeetingPointPOIModalInitial;
    case CUSTOM_SERVICE_ADDON_SHOW_ADD_CUSTOM_MEETING_POINT_POI_MODAL: {
      const { groupIdx } = action;
      return { ...state, show: true, groupIdx };
    }
    case CUSTOM_SERVICE_ADDON_HIDE_ADD_CUSTOM_MEETING_POINT_POI_MODAL:
      return customMeetingPointPOIModalInitial;
    default:
      return state;
  }
};

const customServicePaxScallingModalInitial = { show: false };
export const customServicePaxScallingModal = (
  state = customServicePaxScallingModalInitial,
  action
) => {
  switch (action.type) {
    case CUSTOM_SERVICE_ADDON_INIT:
      return customServicePaxScallingModalInitial;
    case CUSTOM_SERVICE_ADDON_SHOW_PAX_SCALLING_MODAL:
      return { ...state, show: true };
    case CUSTOM_SERVICE_ADDON_HIDE_PAX_SCALLING_MODAL:
      return customServicePaxScallingModalInitial;
    default:
      return state;
  }
};

const customServiceItemContentModalInitial = {
  show: false,
  itemIdx: 0,
  current_lang: "en",
};
export const customServiceItemContentModal = (
  state = customServiceItemContentModalInitial,
  action
) => {
  switch (action.type) {
    case CUSTOM_SERVICE_ADDON_SHOW_EDIT_ITEM_MODAL: {
      const { itemOrder } = action;
      return { ...state, show: true, itemOrder };
    }
    case CUSTOM_SERVICE_ADDON_HIDE_EDIT_ITEM_MODAL:
      return customServiceItemContentModalInitial;
    case CUSTOM_SERVICE_ADDON_CHANGE_EDIT_ITEM_CURRENT_LANG: {
      const { lang } = action;
      return { ...state, current_lang: lang };
    }
    default:
      return state;
  }
};

const customServiceItemDepartureMetaModalInitial = {
  show: false,
  itemIdx: 0,
};
export const customServiceItemDepartureMetaModal = (
  state = customServiceItemDepartureMetaModalInitial,
  action
) => {
  switch (action.type) {
    case CUSTOM_SERVICE_ADDON_SHOW_EDIT_ITEM_DEP_META_MODAL: {
      const { itemOrder } = action;
      return { ...state, show: true, itemOrder };
    }
    case CUSTOM_SERVICE_ADDON_HIDE_EDIT_ITEM_DEP_META_MODAL:
      return customServiceItemContentModalInitial;
    default:
      return state;
  }
};
