import { IconButton, Icon, Drawer, Button, Loader } from "rsuite";

import OverviewFlightFilters from "./AllFlights/filters";

// ============================= ACTIONS =============================
import { selectFlightOption } from "@src/actions/Project/TripPlanner";

import _ from "lodash";
import { connect, useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import {
  filterFlights,
  getSelectedFlightByLegUidSelector,
} from "@src/selectors/Project/TripPlanner";
import { FlightHeader, FlOption } from "./AllFlights";
import { createUseStyles } from "react-jss";
import { variables } from "@src/jsssetup";
import FlightDetails from "./AllFlights/FlightDetails";
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
} from "react-virtualized";
import { InlineIcon } from "@iconify/react";
import { useQuery } from "@tanstack/react-query";
import { pollFlightsSession } from "@src/api";
import { toast } from "react-toastify";
import {
  removeFlightData,
  saveFlightData,
} from "@src/actions/Project/TripPlanner/flights";

const FlightOptions = ({ flight, destOrder, extremalLeg }) => {
  const hasOutbound =
    (flight?.legs?.[0] ?? []).filter((o) => !o.selected).length > 0;
  const hasInbound =
    flight.legs.length > 1 &&
    _.last(flight.legs).filter((o) => !o.selected).length > 0;

  const dispatch = useDispatch();
  const onSelectOption = useCallback(
    ({ extremalLeg, destOrder, uid, optionType, optionUid }) =>
      dispatch(
        selectFlightOption(extremalLeg, destOrder, uid, optionType, optionUid)
      ),
    [dispatch]
  );
  return _.isEmpty(flight)
    ? null
    : (hasOutbound || hasInbound) && (
        <div className="FlightOptions">
          {hasOutbound && (
            <React.Fragment>
              <div className="FlightOptions__headerCntr">
                <div className="FlightOptions__headerCntr__circle"></div>
                <div className="FlightOptions__headerCntr__line" />
                <div className="FlightOptions__headerCntr__titleContr">
                  <InlineIcon
                    className="FlightOptions__headerCntr__titleContr__arrow"
                    icon="carbon:arrow-right"
                  />
                  <h5>Alternative Outbound Options</h5>
                </div>
              </div>
              {flight.legs[0]
                .filter((o) => !o.selected)
                .map((o) => {
                  const operating_airline = o.segments[0].operating_airline;
                  return (
                    <div key={o.uid} className="FlightOptions__option">
                      <FlOption
                        airline={operating_airline}
                        uid={flight.uid}
                        option={o}
                        crs_info={flight.crs_info}>
                        <Button
                          size="xs"
                          color="orange"
                          onClick={() =>
                            onSelectOption({
                              extremalLeg,
                              destOrder,
                              uid: flight.uid,
                              optionType: `outbound_options`,
                              optionUid: o.uid,
                            })
                          }>
                          Select
                        </Button>
                      </FlOption>
                    </div>
                  );
                })}
            </React.Fragment>
          )}
          {hasInbound && (
            <React.Fragment>
              <div className="FlightOptions__headerCntr">
                <div className="FlightOptions__headerCntr__circle"></div>
                <div className="FlightOptions__headerCntr__line" />
                <div className="FlightOptions__headerCntr__titleContr">
                  <InlineIcon
                    className="FlightOptions__headerCntr__titleContr__arrow"
                    icon="carbon:arrow-right"
                  />
                  <h5>Alternative Inbound Options</h5>
                </div>
              </div>
              {_.last(flight.legs)
                .filter((o) => !o.selected)
                .map((o) => {
                  const operating_airlines = o.segments[0].operating_airline;
                  return (
                    <div key={o.uid} className="FlightOptions__option">
                      <FlOption
                        airline={operating_airlines}
                        uid={flight.uid}
                        option={o}
                        crs_info={flight.crs_info}>
                        <Button
                          size="xs"
                          color="orange"
                          onClick={() =>
                            onSelectOption({
                              extremalLeg,
                              destOrder,
                              uid: flight.uid,
                              optionType: `inbound_options`,
                              optionUid: o.uid,
                            })
                          }>
                          Select
                        </Button>
                      </FlOption>
                    </div>
                  );
                })}
            </React.Fragment>
          )}
        </div>
      );
};
FlightOptions.propTypes = {
  flight: PropTypes.object,
  destOrder: PropTypes.number.isRequired,
  extremalLeg: PropTypes.bool.isRequired,
};

const overviewAllFlightsStyles = createUseStyles({
  OverviewAllFlightsDrawer: {
    "width": "90vw!important",
    "& .rs-drawer-body": { overflow: "hidden!important" },
    "& .rs-drawer-footer": { display: "none" },
  },
  header: {
    "margin": 0,
    "padding": variables.normal_gap,
    "background": variables.colors.background.light,
    "color": variables.colors.text.colored,
    "display": "grid",
    "gridTemplateColumns": "max-content 1fr max-content",
    "alignItems": "center",
    "& .rs-drawer-header-close": { display: "none" },
  },
  title: {
    color: `${variables.colors.text.colored}!important`,
  },
  [`@media ${variables.media.bigscreen}`]: {
    OverviewAllFlightsDrawer: { width: "60vw!important" },
  },
});
const OverviewAllFlights = ({
  session_id,
  destOrder,
  trpUid,
  extremalLeg,
  flOrder,
  onClose,
}) => {
  const { flightSession, selectedFlight, tripLeg } = useSelector((state) => {
    const flSessions = state.tripPlannerFlightsReducer.sessions;
    var flightSession = flSessions.find((s) => s?.session_id === session_id);
    const legUid = flightSession?.payload?.legUid;

    const { flight, tripLeg } = getSelectedFlightByLegUidSelector(state, {
      legUid,
    });
    return { flightSession, selectedFlight: flight, tripLeg };
  });

  const classes = overviewAllFlightsStyles();
  const [activeDetailsUid, setActiveDetailsUid] = useState(null);
  const [filters, setFilters] = useState({
    departure_airports: [],
    arrival_airports: [],
    airlines: [],
    flight_class: [],
    stops: [],
    baggage_allowance: [],
    brand: [],
    fare_type: [],
    flight_source: [],
    channel: [],
    min_price: 0,
    max_price: Infinity,
    ordering: "cheapest",
    outbound_min_departure: 0,
    outbound_max_departure: 24,
    outbound_min_arrival: 0,
    outbound_max_arrival: 24,
    inbound_min_departure: 0,
    inbound_max_departure: 24,
    inbound_min_arrival: 0,
    inbound_max_arrival: 24,
  });

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ["viewAllFlights", session_id],
    queryFn: () =>
      pollFlightsSession({
        session_id,
        api_key: flightSession?.payload?.api_key,
      }),
    onError: () => {
      toast.error("Error fetching flights", { autoClose: 5000 });
      onClose();
    },
    refetchOnWindowFocus: false,
  });
  const allFlights = data?.data?.results ?? [];
  var flights = filterFlights({ flights: allFlights, filters });

  function showDetails(uid) {
    setActiveDetailsUid(uid);
  }

  // Show flight details
  useEffect(() => {
    if (trpUid) {
      setActiveDetailsUid(trpUid);
    } else {
      setActiveDetailsUid(null);
    }
  }, [trpUid]);

  const activeDetailsFlight = activeDetailsUid
    ? flights.find((fl) => fl.uid === activeDetailsUid)
    : null;

  const hasReturn = (flights?.[0]?.legs ?? []).length > 1;
  const [flightVirtualizerCache, setflightVirtualizerCache] = useState(
    new CellMeasurerCache({
      defaultHeight: !hasReturn ? 150 : 250,
      minHeight: !hasReturn ? 150 : 250,
      fixedWidth: true,
    })
  );

  useEffect(() => {
    setflightVirtualizerCache(
      new CellMeasurerCache({
        defaultHeight: !hasReturn ? 150 : 250,
        minHeight: !hasReturn ? 150 : 250,
        fixedWidth: true,
      })
    );
  }, [filters]);

  const dispatch = useDispatch();
  const onSelectFlight = useCallback(
    async (currentFlightUid, newFlight) => {
      if (currentFlightUid) {
        dispatch(removeFlightData(currentFlightUid));
      }

      if (!newFlight.legUid) {
        newFlight.legUid = tripLeg?.uid;
      }

      dispatch(saveFlightData(newFlight));
    },
    [dispatch]
  );

  return (
    <Drawer
      className={classes.OverviewAllFlightsDrawer}
      size="lg"
      placement="right"
      show={true}
      onHide={onClose}>
      <Drawer.Header className={classes.header}>
        <Drawer.Title className={classes.title}>
          {trpUid ? "Flight Details" : `Available flights`}
        </Drawer.Title>
        <IconButton
          className="close-btn"
          icon={<Icon icon="close" />}
          size="sm"
          placement="right"
          onClick={onClose}>
          Close
        </IconButton>
      </Drawer.Header>
      <Drawer.Body className="overview-drawer-body">
        {isLoading || isFetching ? (
          <Loader center size="lg" content="Please wait..." />
        ) : (
          <div className="OverviewAllFlights">
            {trpUid ? null : (
              <OverviewFlightFilters
                flights={allFlights}
                filteredFlights={flights}
                initialFilters={filters}
                onSetFilters={setFilters}
              />
            )}
            <div className="FlightList">
              {!activeDetailsUid ? (
                <div className="FlightList__content">
                  {flights.length > 0 && (
                    <AutoSizer>
                      {({ width, height }) => (
                        <List
                          height={height}
                          width={width}
                          rowCount={(flights ?? []).length}
                          overscanRowCount={10}
                          deferredMeasurementCache={flightVirtualizerCache}
                          rowHeight={flightVirtualizerCache.rowHeight}
                          scrollToIndex={0}
                          rowRenderer={({ index, key, parent, style }) => {
                            const f = flights[index];
                            return !f ? null : (
                              <CellMeasurer
                                cache={flightVirtualizerCache}
                                key={key}
                                parent={parent}
                                columnIndex={0}
                                rowIndex={index}>
                                {({ registerChild }) => (
                                  <div
                                    ref={registerChild}
                                    style={{
                                      ...style,
                                      paddingBottom: variables.half_gap,
                                    }}
                                    className="FlightList__flight">
                                    <FlightHeader
                                      hasReturn={hasReturn}
                                      flight={f}
                                      isSelected={f.uid === selectedFlight?.uid}
                                      onSelectFlight={() =>
                                        onSelectFlight(selectedFlight?.uid, f)
                                      }
                                      showDetails={showDetails}
                                    />
                                    <FlightOptions
                                      flight={f}
                                      extremalLeg={extremalLeg}
                                      destOrder={destOrder}
                                    />
                                  </div>
                                )}
                              </CellMeasurer>
                            );
                          }}
                        />
                      )}
                    </AutoSizer>
                  )}
                </div>
              ) : activeDetailsFlight ? (
                <FlightDetails
                  hideDetails={() => setActiveDetailsUid(null)}
                  flight_uid={activeDetailsFlight.uid}
                  crs_info={activeDetailsFlight.crs_info}
                  outbound_option={activeDetailsFlight.legs[0].find(
                    (o) => o.selected
                  )}
                  inbound_option={_.last(activeDetailsFlight.legs).find(
                    (o) => o.selected
                  )}
                  flOrder={flOrder}
                  ticketing_deadline={activeDetailsFlight.ticketing_deadline}
                  fare_type={activeDetailsFlight.fare_type}
                />
              ) : null}
            </div>
          </div>
        )}
      </Drawer.Body>
      <Drawer.Footer>
        <Button onClick={onClose} appearance="primary">
          Close
        </Button>
      </Drawer.Footer>
    </Drawer>
  );
};
OverviewAllFlights.defaultProps = {
  uid: null,
  flights: [],
};
OverviewAllFlights.propTypes = {
  flights: PropTypes.array.isRequired,
  extremalLeg: PropTypes.bool.isRequired,
  destOrder: PropTypes.number.isRequired,
  flOrder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  trpUid: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  const { extremal, destOrder, loading } = state.tripPlannerViewAllFlights;

  const key = extremal || destOrder == 0 ? "extremalLeg" : destOrder;
  const flOrder = destOrder == 0 ? "extremal" : destOrder;

  return {
    extremalLeg: key == "extremalLeg",
    destOrder,
    flOrder,
    loading,
    trpUid: state.tripPlannerViewAllFlights.uid,
  };
};
export default connect(mapStateToProps, null)(OverviewAllFlights);
