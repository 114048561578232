import update from "immutability-helper";

import {
  COMPANY_SELECT_SERVICE_CATEGORY,
  COMPANY_SELECT_TRAVEL_SERVICE,
  COMPANY_VIEW_SERVICE_CATEGORIES_MODE,
  COMPANY_EDIT_SERVICE_CATEGORIES_MODE,
  COMPANY_LOADING_SERVICE_CATEGORY_MODE,
  COMPANY_INITIALIZE_TRAVEL_SERVICE_FORM,
  COMPANY_INITIALIZE_MAIN_SERVICES_FORM,
  COMPANY_UPDATE_MAIN_SERVICES_FORM,
} from "@src/actions/Admin/Company/types";

import { travelServicesFrom } from "@src/forms/Admin/Company/companyProfileForms";

import { travelServicesFormProcessor } from "@src/reducers/tools";

export const companyServiceCategoriesMode = (state = "VIEW", action) => {
  switch (action.type) {
    case COMPANY_VIEW_SERVICE_CATEGORIES_MODE:
      return "VIEW";
    case COMPANY_EDIT_SERVICE_CATEGORIES_MODE:
      return "EDIT";
    case COMPANY_LOADING_SERVICE_CATEGORY_MODE:
      return "LOADING";
    default:
      return state;
  }
};

export const companySelectedServiceCategory = (state = "", action) => {
  switch (action.type) {
    case COMPANY_SELECT_SERVICE_CATEGORY:
      return action.data;
    default:
      return state;
  }
};

export const companyTravelServicesForm = (
  state = travelServicesFrom,
  action
) => {
  switch (action.type) {
    case COMPANY_INITIALIZE_TRAVEL_SERVICE_FORM:
      return update(state, { travel_services: { $set: action.data } });
    case COMPANY_SELECT_TRAVEL_SERVICE:
      return travelServicesFormProcessor(
        state,
        action.data.type,
        "travel_services",
        action.data.value,
        action.data.name,
        action.data.travelServices
      );
    default:
      return state;
  }
};

const initialCompanyMainServicesForm = {
  value: "",
  verbose: "Main Services",
};

export const companyMainServicesForm = (
  state = initialCompanyMainServicesForm,
  action
) => {
  switch (action.type) {
    case COMPANY_INITIALIZE_MAIN_SERVICES_FORM:
      return update(state, { value: { $set: action.data } });
    case COMPANY_UPDATE_MAIN_SERVICES_FORM:
      return update(state, { value: { $set: action.data } });
    default:
      break;
  }
  return state;
};
