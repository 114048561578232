import { StyleSheet, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import React from "react";
import PDFImage from "./PDFImage";

const stampStyles = StyleSheet.create({
  Stamp: {},
  stampContainer: { height: "40", width: "200", flexDirection: "row" },
});
const Stamp = ({ data }) => {
  const stampSrc = data?.stamp ?? "";
  const xAxis = data?.xAxis ?? 0;
  const yAxis = data?.yAxis ?? 0;

  const stampStyle = {
    position: "absolute",
    top: `${yAxis}%`,
    left: `${xAxis}%`,
  };

  return (
    <View style={stampStyle}>
      <View style={stampStyles.stampContainer}>
        <PDFImage src={stampSrc} />
      </View>
    </View>
  );
};
Stamp.defaultProps = { data: {}, entity_colors: {} };
Stamp.propTypes = {
  data: PropTypes.object.isRequired,
  entity_colors: PropTypes.object,
};
export default Stamp;
