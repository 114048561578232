import {
  COORDINATOR_LIST_GENERIC_CHANGE,
  COORDINATOR_LIST_SAVE_RESULTS,
} from "@src/actions/MyLibrary/Coordinators/types";
import { commonList } from "@src/reducers/common";

const coordinatorsListReducerInitial = {
  ...commonList,
  filters: {
    reference: "",
    name: "",
    destinations: "",
    countries: "",
    internal_rating: "",
    languages: "",
  },
};
export const CoordinatorsListReducer = (
  state = coordinatorsListReducerInitial,
  action
) => {
  switch (action.type) {
    case COORDINATOR_LIST_SAVE_RESULTS: {
      const { data } = action;
      return { ...state, ...data };
    }
    case COORDINATOR_LIST_GENERIC_CHANGE: {
      const { key, value } = action;
      if (key === "filters") {
        return { ...state, [key]: value, page: 1 };
      } else {
        return { ...state, [key]: value };
      }
    }
    default:
      return state;
  }
};
