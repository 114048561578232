import { connect, useDispatch } from "react-redux";
import { Nav, SelectPicker, Toggle, Loader } from "rsuite";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  changePkgColorPalette,
  changePkgDataContent,
  changePkgTemplate,
  hidePkgContentModal,
  saveMktTrip,
  simpleHidePkgContentModal,
  togglePkgContentMode,
} from "@src/actions/Project/TripPlanner";
import { getTripDaysSelector } from "@src/selectors/Project/TripPlanner/day_by_day_selectors";
import {
  getMktPkgTemplateSelector,
  getSectTemplateColorSelector,
} from "@src/selectors/Project/Marketplace";
import OnePagePkgTemplate from "./PkgContent/OnePagePkgTemplate";
import { getSetupFormDataSelector } from "@src/selectors/Project/TripPlanner";
import PkgImageForm from "@src/pages/Project/TripPlan/Components/PkgContent/PkgImageForm";
import { getServicesPricingSelector } from "@src/selectors/Project/TripPlanner/pricing";
import { createUseStyles } from "react-jss";
import { modalGenericStyles } from "@src/jsssetup";
import { TripPlanContext } from "../TripPlanner";
import { CustomButton } from "@src/components/common/buttons";
import { toast } from "react-toastify";

const mktPkgContentStyles = createUseStyles({
  ...modalGenericStyles,
  MktPkgContent: modalGenericStyles.modal,
});
const MktPkgContent = ({
  destinations,
  mode,
  days,
  inclusions,
  exclusions,
  color,
  price,
  pricePerPerson,
  requiredServices,
  pkgContent,
  onToggleMode,
  onChangeColor,
}) => {
  const { setShowContentModal } = useContext(TripPlanContext);
  const classes = mktPkgContentStyles();
  const [activeEditor, setActiveEditor] = useState("wysiwyg_editor");
  const [content, setContent] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  function renderEditTools() {
    return (
      <div className="MktPkgContent__edittools">
        <div className="MktPkgContent__edittools__templater">
          <div className="MktPkgContent__edittools__inputgroup">
            <span>
              <strong>Edit Mode</strong>
            </span>
            <Toggle
              checked={mode == "edit"}
              checkedChildren={<strong>On</strong>}
              unCheckedChildren={<strong>Off</strong>}
              onChange={function () {
                onToggleMode();
              }}
            />
          </div>
          <div className="MktPkgContent__edittools__inputgroup">
            <span>
              <strong>Color Palette</strong>
            </span>
            <SelectPicker
              value={color}
              searchable={false}
              data={[
                { value: "purple", label: "Purple" },
                { value: "teal", label: "Teal" },
                { value: "yellow", label: "Yellow" },
                { value: "orange", label: "Orange" },
              ]}
              onChange={function (value) {
                onChangeColor(value);
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  function renderWYSIWYGRouter() {
    const tmpProps = {
      content,
      destinations,
      mode,
      days,
      inclusions,
      exclusions,
      color,
      price,
      pricePerPerson,
      requiredServices,
      onContentChange: setContent,
    };

    return <OnePagePkgTemplate content={content} {...tmpProps} />;
  }

  useEffect(() => {
    setContent(pkgContent);
  }, [pkgContent]);

  const dispatch = useDispatch();

  const onSubmit = useCallback(
    async (content) => {
      setIsLoading(true);
      await dispatch(changePkgDataContent(content));
      const result = await dispatch(saveMktTrip());

      if (result) {
        toast.success("Package Content Saved Successfully", {
          autoClose: 2000,
        });
      }

      setShowContentModal(false);
      setIsLoading(false);
    },
    [dispatch]
  );

  return (
    <div className={classes.MktPkgContent}>
      <div className={classes.card}>
        {isLoading && <Loader backdrop size="lg" center />}
        <div className={classes.cardHeader}>
          <h5>Package Content</h5>
        </div>
        <div className={classes.cardBody}>
          <div className="MktPkgContent__editor">
            <Nav appearance="tabs" activeKey={activeEditor}>
              <Nav.Item
                eventKey="wysiwyg_editor"
                onClick={() => setActiveEditor("wysiwyg_editor")}>
                WYSIWYG Editor
              </Nav.Item>
              <Nav.Item
                eventKey="image_editor"
                onClick={() => setActiveEditor("image_editor")}>
                Image Editor
              </Nav.Item>
            </Nav>
            {activeEditor == "wysiwyg_editor" && (
              <div className="MktPkgContent__editor__wysiwyg">
                {renderWYSIWYGRouter()}
                {renderEditTools()}
              </div>
            )}
            {activeEditor == "image_editor" && (
              <div className="MktPkgContent__editor__image">
                <PkgImageForm />
              </div>
            )}
          </div>
        </div>
        <div className={classes.cardActions}>
          <CustomButton
            appearance="ghost"
            onClick={() => setShowContentModal(false)}>
            Close
          </CustomButton>
          <CustomButton appearance="primary" onClick={() => onSubmit(content)}>
            Submit
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
MktPkgContent.defaultProps = { price: 0, pricePerPerson: 0 };
MktPkgContent.propTypes = {
  mode: PropTypes.oneOf(["edit", "view"]).isRequired,
  pkgContent: PropTypes.object,
  template: PropTypes.string,
  color: PropTypes.string,
  days: PropTypes.array,
  destinations: PropTypes.array,
  inclusions: PropTypes.array,
  exclusions: PropTypes.array,
  price: PropTypes.number,
  pricePerPerson: PropTypes.number,
  requiredServices: PropTypes.array,
  onSimpleHide: PropTypes.func,
  onHide: PropTypes.func,
  onToggleMode: PropTypes.func,
  onChangeColor: PropTypes.func,
  onChangeTemplate: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { mode } = state.tripPlannerMktPkgContentModal;

  const { requiredServices } = getSetupFormDataSelector(state);

  const { inclusions, exclusions } = state.tripPlannerMktIncExc;

  const content = state.tripPlannerMktPkgContent;

  const color = getSectTemplateColorSelector(state);
  const template = getMktPkgTemplateSelector(state);

  const destinations = state.tripPlannerDestinations;

  const days = getTripDaysSelector(state);

  const pricing = getServicesPricingSelector(state);
  const price = pricing.selling.amount;
  const pricePerPerson = pricing.avgPerPersonSelling.amount;

  return {
    destinations,
    mode,
    days,
    inclusions,
    exclusions,
    color,
    template,
    price,
    pricePerPerson,
    pkgContent: content,
    requiredServices,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSimpleHide: () => dispatch(simpleHidePkgContentModal()),
    onHide: (content) => dispatch(hidePkgContentModal(content)),
    onToggleMode: () => dispatch(togglePkgContentMode()),
    onChangeColor: (color) => dispatch(changePkgColorPalette(color)),
    onChangeTemplate: (template) => dispatch(changePkgTemplate(template)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MktPkgContent);
