import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import { convertAmountSelector } from "@src/selectors/Financial";
import { useSelector } from "react-redux";

const priceStyles = createUseStyles({
  Price: (props) => ({
    fontWeight: "bold",
    fontSize: "large",
    alignSelf: "end",
    ...props?.customStyles?.Price,
  }),
});
export const CarRentalsPrice = ({ price, currency, customStyles }) => {
  const classes = priceStyles({ customStyles });
  const { convertedAmount, currency: convertedCurrency } = useSelector(
    (state) =>
      convertAmountSelector(state, {
        amount: price,
        currentCurrency: currency,
      })
  );

  return (
    <span className={classes.Price}>
      {convertedAmount.toLocaleString("en-US", {
        style: "currency",
        currency: convertedCurrency,
      })}
    </span>
  );
};
CarRentalsPrice.propTypes = {
  price: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  customStyles: PropTypes.object,
};
