import { Notification, Tag } from "rsuite";
import React from "react";

export const notifyAddOnValidation = (msgs) => {
  Notification.warning({
    title: "Add On Validation Warning",
    description: (
      <React.Fragment>
        <p>Please correct the following errors before coninuing.</p>
        <ul>
          {msgs.map((msg, idx) => (
            <li key={idx}>{msg}</li>
          ))}
        </ul>
      </React.Fragment>
    ),
    duration: 10000,
  });
};

export const notifyAvailableAddOnValidation = (msgs) => {
  Notification.warning({
    title: "Add On Validation Warning",
    description: (
      <React.Fragment>
        <p>
          <Tag color="green">
            <strong>Available</strong>
          </Tag>{" "}
          add-ons must fullfill the following.
        </p>
        <ul>
          {msgs.map((msg, idx) => (
            <li key={idx}>{msg}</li>
          ))}
        </ul>
      </React.Fragment>
    ),
    duration: 10000,
  });
};

export const notifyMeetingPointGroupValidation = (msgs) => {
  Notification.warning({
    title: "Group Validation",
    description: (
      <React.Fragment>
        <ul>
          {msgs.map((msg, idx) => (
            <li key={idx}>{msg}</li>
          ))}
        </ul>
      </React.Fragment>
    ),
    duration: 10000,
  });
};

export const notifyPaxScallingValidation = (msgs) => {
  Notification.warning({
    title: "Pax Scalling Validation",
    description: (
      <React.Fragment>
        <ul>
          {msgs.map((msg, idx) => (
            <li key={idx}>{msg}</li>
          ))}
        </ul>
      </React.Fragment>
    ),
    duration: 10000,
  });
};

export const notifyMeetingGroupPoints = () => {
  Notification.warning({
    title: "Pick Up Points failure.",
    description: (
      <p>We failed to find any points based on your search criteria.</p>
    ),
    duration: 10000,
  });
};
