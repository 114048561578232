import { CHAT_RESET, CHAT_UPDATE } from "@src/actions/Chat/types";

const ChatReducerInitial = {
  notifications: [],
};
export const ChatReducer = (state = ChatReducerInitial, action) => {
  switch (action.type) {
    case CHAT_UPDATE: {
      const { data } = action;
      return { ...state, ...data };
    }
    case CHAT_RESET: {
      return ChatReducerInitial;
    }
    default:
      return state;
  }
};
