import { commonList } from "@src/reducers/common";
import {
  PRODUCT_TERMS_AND_CONDITIONS_FETCH_LIST,
  PRODUCT_TERMS_AND_CONDITIONS_LIST_GENERIC_CHANGE,
} from "../../../actions/Admin/ProductTermsAndConditions/types";

const ProductTermsAndConditionsReducerInitial = {
  ...commonList,
  filters: {},
};

export const ProductTermsAndConditionsReducer = (
  state = ProductTermsAndConditionsReducerInitial,
  action
) => {
  switch (action.type) {
    case PRODUCT_TERMS_AND_CONDITIONS_FETCH_LIST: {
      const { data } = action;
      return { ...state, ...data };
    }
    case PRODUCT_TERMS_AND_CONDITIONS_LIST_GENERIC_CHANGE: {
      const { key, value } = action;
      return { ...state, [key]: value };
    }
    default:
      return state;
  }
};
