import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchSubAgentsProfile, submitSubAgentsProfile } from "@src/api";
import { formStyles, modalGenericStyles } from "@src/jsssetup";
import { Loader } from "rsuite";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import { queryClient } from "@src/index";
import { CustomButton } from "@src/components/common/buttons";
import TransTxt from "@src/components/common/SxFormatMessage";
import { NormalSelectField } from "@src/components/forms";
import { injectIntl } from "react-intl";

const styles = createUseStyles({
  ...modalGenericStyles,
  ApproveSubagentModal: {
    ...modalGenericStyles.modal,
  },
  card: {
    ...modalGenericStyles.card,
  },
  cardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  cardBody: {
    ...modalGenericStyles.cardBody,
  },
  formStyles: {
    ...formStyles.form,
  },
  cardActions: {
    ...modalGenericStyles.cardActions,
  },
});
var ApproveSubagentModal = ({ subagentId, onClose, intl }) => {
  const classes = styles();

  const { data, isLoading } = useQuery({
    queryKey: ["subagentApproveModal", subagentId],
    queryFn: () => fetchSubAgentsProfile(subagentId),
    onError: (error) => {
      console.log(error);
      toast.error(
        "Unable to fetch the subagent profile. We are sorry for the inconvenience."
      );
    },
  });

  const mutation = useMutation({
    mutationKey: "subagentApproveModalMutation",
    mutationFn: (values) => submitSubAgentsProfile(subagentId, values, "patch"),
    onSuccess: () => {
      toast.success("Subagent profile updated successfully.");
      queryClient.invalidateQueries("SubAgentsList");
      onClose();
    },
  });

  return (
    <div className={classes.ApproveSubagentModal}>
      <div className={classes.card}>
        <div className={classes.cardHeader}>
          <h5>
            <TransTxt id="subagentApproveModal__subagent_aproval" />
          </h5>
        </div>
        {isLoading ? (
          <div className={classes.cardBody}>
            <Loader backdrop size="lg" center />
          </div>
        ) : (
          <Formik
            initialValues={{ approved: data.approved }}
            onSubmit={(values) => mutation.mutate(values)}>
            <Form>
              <div className={classes.cardBody}>
                <div className={classes.formStyles}>
                  <NormalSelectField
                    label={<TransTxt id="subagentApproveModal__approved" />}
                    name="approved"
                    options={[
                      [
                        true,
                        intl.formatMessage({ id: "subagentApproveModal__yes" }),
                      ],
                      [
                        false,
                        intl.formatMessage({ id: "subagentApproveModal__no" }),
                      ],
                    ]}
                  />
                </div>
              </div>
              <div className={classes.cardActions}>
                <CustomButton appearance="ghost" onClick={onClose}>
                  <TransTxt id="subagentApproveModal__close" />
                </CustomButton>
                <CustomButton appearance="primary" type="submit">
                  <TransTxt id="subagentApproveModal__submit" />
                </CustomButton>
              </div>
            </Form>
          </Formik>
        )}
      </div>
    </div>
  );
};
ApproveSubagentModal.propTypes = {
  subagentId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};
ApproveSubagentModal = injectIntl(ApproveSubagentModal);
export default ApproveSubagentModal;
