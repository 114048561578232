import {
  TRIPPLANNER_ADHOC_TRF_SRV_ADD,
  TRIPPLANNER_ADHOC_TRF_SRV_REMOVE,
} from "../../types/transfers";

export const addAdhocTrfService = ({ srv }) => {
  return { type: TRIPPLANNER_ADHOC_TRF_SRV_ADD, srv };
};

export const removeAdhocTrfService = ({ srv_uid }) => {
  return { type: TRIPPLANNER_ADHOC_TRF_SRV_REMOVE, srv_uid };
};
