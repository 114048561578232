import _ from "lodash";
import {
  NormalDatePicker,
  NormalInputField,
  NormalProviderAutocompleteField,
  NormalRichText,
  NormalSelectField,
} from "@src/components/forms";
import {
  formStyles,
  modalGenericStyles,
  tableStyles,
  variables,
} from "@src/jsssetup";
import { Formik, Form } from "formik";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { createUseStyles } from "react-jss";
import { useDispatch, useSelector } from "react-redux";
import { Button, Loader, Tag, Toggle } from "rsuite";
import { DateTime, Duration } from "luxon";
import { saveServiceRemarks } from "@src/actions/Project/TripPlanner/step_four/service_remarks";
import { getServicesListSelector } from "@src/selectors/Project/TripPlanner/generic_service_selectors";
import { toast } from "react-toastify";
import { fetchSuppliersProfile } from "@src/api";
import { humanizeDuration } from "@src/tools/date_tools";

function validateBookingRemarks({ values, srv_type }) {
  const requiredFields = [
    "supplier_name",
    "supplier_email",
    "response_deadline",
  ];

  switch (srv_type) {
    case "REST": {
      requiredFields.push("start_time");
    }
  }

  const errors = {};
  requiredFields.forEach((key) => {
    if (!values[key]) {
      errors[key] = `${_.startCase(key)} is required.`;
    }
  });

  Object.values(errors).forEach((msg) => toast.error(msg, { duration: 5000 }));

  return errors;
}

function setSupplierForService({ srv_type, service, form }) {
  switch (srv_type) {
    case "COO": {
      form.supplier_name = service.name_en || "";
      form.supplier_email = service.email || "";
      form.supplier_phone = service.phone || "";
      break;
    }
    case "REST": {
      form.supplier_name = service.name || "";
      form.supplier_email = service.email || "";
      form.supplier_phone = service.phone || "";
      break;
    }
    default: {
      break;
    }
  }
  return form;
}

function setSpecialInitialData({ srv_type, service, form }) {
  switch (srv_type) {
    case "COO": {
      const {
        vehicle_brand = "",
        vehicle_seats = 0,
        vehicles_no = 0,
        daily_start_times = _.get(service, "daily_cost", []).map((dt) => ({
          date: dt.date,
          time: "",
          point: "",
          end_point: "",
        })),
      } = _.get(service, "booking_metadata", {});

      form = {
        ...form,
        vehicle_brand,
        vehicle_seats,
        vehicles_no,
        daily_start_times,
      };

      if (!form.custom_supplier) {
        form = setSupplierForService({ srv_type, service, form });
      }
      break;
    }
    case "REST": {
      const { start_time = "" } = _.get(service, "booking_metadata", {});
      form = { ...form, start_time };

      if (!form.custom_supplier) {
        form = setSupplierForService({ srv_type, service, form });
      }
      break;
    }
    case "GEN": {
      const { start_time = "" } = _.get(service, "booking_metadata", {});
      form = { ...form, start_time };
      form.custom_supplier = true;
      break;
    }
    case "ACC": {
      form.custom_supplier = true;
      break;
    }
    case "COA": {
      form.custom_supplier = true;
      break;
    }
    case "FER": {
      form.custom_supplier = true;
      break;
    }
    case "TRA": {
      form.custom_supplier = true;
      break;
    }
    default: {
      break;
    }
  }
  return form;
}

const styles = createUseStyles({
  "@keyframes slidein": modalGenericStyles["@keyframes slidein"],
  "ServiceCommentsModal": modalGenericStyles.modal,
  "card": modalGenericStyles.card,
  "header": modalGenericStyles.cardHeader,
  "body": {
    ...modalGenericStyles.cardBody,
    display: "grid",
    gridGap: variables.normal_gap,
  },
  "col2": {
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  "col3": {
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateColumns: "repeat(3, 1fr)",
  },
  "section": {
    padding: [`calc(${variables.normal_gap} / 2)`, 0],
    borderBottom: `2px solid ${variables.colors.borders.base}`,
    fontWeight: "bold",
    fontSize: "large",
    display: "grid",
    gridTemplateColumns: "1fr auto auto auto",
    gridGap: variables.half_gap,
  },
  "supplierAutocomplete": {
    position: "relative",
    display: "grid",
    gridTemplateColumns: "1fr auto",
    gridGap: variables.half_gap,
    alignItems: "end",
  },
  "actions": modalGenericStyles.cardActions,
  ...tableStyles,
  "cell": tableStyles.cell,
  "form": formStyles.form,
});
const ServiceRemarksModal = ({ uid, srv_type, onCancel }) => {
  const service_list = useSelector((state) => getServicesListSelector(state));
  const [loadSupplierMode, setLoadSupplierMode] = useState(false);
  const [supplierModeLoading, setSupplierModeLoading] = useState(false);

  const dispatch = useDispatch();
  const onSave = useCallback(
    (booking_metadata) =>
      dispatch(saveServiceRemarks({ uid, srv_type, booking_metadata })),
    [dispatch]
  );

  var service = null;
  switch (srv_type) {
    case "COO":
      service = service_list.find(
        (srv) =>
          srv.srvType === "CU" &&
          srv.custom_service_type === "COO" &&
          srv?.uid === uid
      );
      break;
    case "REST":
      service = service_list.find(
        (srv) =>
          srv.srvType === "CU" &&
          srv.custom_service_type === "REST" &&
          srv.id === uid
      );
      break;
    case "GEN":
      service = service_list.find(
        (srv) =>
          srv.srvType === "CU" &&
          srv.custom_service_type === "GEN" &&
          srv.id === uid
      );
      break;
    case "ACC":
      service = service_list.find(
        (srv) => srv.service_type === "ACC" && srv.uid === uid
      );
      break;
    case "COA": {
      service = service_list.find(
        (srv) =>
          srv.addhoc_service_type === "COA" && _.get(srv, "service.uid") === uid
      );
      service = _.get(service, "service");
      break;
    }
    case "FER": {
      service = service_list.find(
        (srv) =>
          srv.addhoc_service_type === "FER" && _.get(srv, "service.uid") === uid
      );
      service = _.get(service, "service");
      break;
    }
    case "TRA": {
      service = service_list.find(
        (srv) =>
          srv.addhoc_service_type === "TRA" && _.get(srv, "service.uid") === uid
      );
      service = _.get(service, "service");
      break;
    }
    default:
      service = null;
      break;
  }

  const {
    booking_general_remarks = "",
    customer_remarks = "",
    request_cxl_policy = false,
    request_cxl_policy_remarks = "",
    booking_mode = "CO",
    booking_mode_remarks = "",
    payment_mode = "PRE",
    payment_deadline = 0,
    payment_mode_remarks = "",
    response_deadline = DateTime.now().plus({ days: 3 }).toISO(),
    custom_supplier = false,
    supplier_name = "",
    supplier_email = "",
    supplier_phone = "",
  } = _.get(service, "booking_metadata", {});

  var form = {
    booking_general_remarks,
    customer_remarks,
    request_cxl_policy,
    request_cxl_policy_remarks,
    booking_mode,
    booking_mode_remarks,
    payment_mode,
    payment_deadline,
    payment_mode_remarks,
    response_deadline,
    custom_supplier,
    supplier_name,
    supplier_email,
    supplier_phone,
  };

  form = setSpecialInitialData({ srv_type, service, form });

  const classes = styles();
  return (
    <div className={classes.ServiceCommentsModal}>
      <div className={classes.card}>
        <div className={classes.header}>
          <h5>Service Booking Remarks</h5>
        </div>
        <Formik
          initialValues={form}
          validateOnBlur={false}
          validateOnChange={false}
          validateOnMount={false}
          validate={(values) => validateBookingRemarks({ values, srv_type })}
          onSubmit={(values) => {
            if (values.request_cxl_policy === "true") {
              values.request_cxl_policy = true;
            } else {
              values.request_cxl_policy = false;
            }

            onSave(values);
            onCancel();
          }}>
          {({ values, setFieldValue }) => (
            <Form>
              <div className={classes.body}>
                {srv_type === "COO" && (
                  <React.Fragment>
                    <div className={classes.section}>
                      <span>Service Specific Information</span>
                    </div>
                    <div className={`${classes.col3} ${classes.form}`}>
                      <NormalInputField
                        name="vehicle_brand"
                        label="Vehicle Brand"
                      />
                      <NormalInputField
                        name="vehicles_seats"
                        label="Vehicle Seats"
                        type="number"
                      />
                      <NormalInputField
                        name="vehicles_no"
                        label="Number of Vehicles"
                        type="number"
                      />
                    </div>
                    <table className={classes.table}>
                      <thead className={classes.head}>
                        <tr>
                          <th>Date</th>
                          <th>Start Point</th>
                          <th>Start Time</th>
                          <th>End Point</th>
                          <th>End Time</th>
                          <th>Duration</th>
                        </tr>
                      </thead>
                      <tbody>
                        {values.daily_start_times.map((dt, idx) => {
                          const durationObj = service.daily_durations.find(
                            (dur) => dur.date === dt.date
                          );

                          const endTime = DateTime.fromISO(dt.time).plus(
                            Duration.fromObject({
                              minutes: durationObj.duration,
                            })
                          );
                          var endTimeStr = "N/A";
                          if (!endTime.invalid) {
                            endTimeStr = endTime.toLocaleString(
                              DateTime.TIME_24_SIMPLE
                            );
                          }

                          return (
                            <tr key={idx}>
                              <td className={classes.cell}>
                                {DateTime.fromISO(dt.date).toLocaleString(
                                  DateTime.DATE_MED_WITH_WEEKDAY
                                )}
                              </td>
                              <td className={classes.cell}>
                                <NormalInputField
                                  name={`daily_start_times.${idx}.point`}
                                />
                              </td>
                              <td className={classes.cell}>
                                <NormalDatePicker
                                  name={`daily_start_times.${idx}.time`}
                                  withTimeOnly={true}
                                  withTime={true}
                                />
                              </td>
                              <td className={classes.cell}>
                                <NormalInputField
                                  name={`daily_start_times.${idx}.end_point`}
                                />
                              </td>
                              <td className={classes.cell}>{endTimeStr}</td>
                              <td className={classes.cell}>
                                {humanizeDuration(
                                  durationObj.duration * 60 * 1000
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </React.Fragment>
                )}
                {["REST", "GEN"].includes(srv_type) && (
                  <React.Fragment>
                    <div className={classes.section}>
                      <span>Service Specific Information</span>
                    </div>
                    <NormalDatePicker
                      label="Start Time"
                      name="start_time"
                      withTimeOnly={true}
                      withTime={true}
                    />
                  </React.Fragment>
                )}
                <div className={classes.section}>
                  <span>Supplier Information</span>
                  {!loadSupplierMode && (
                    <React.Fragment>
                      <small>
                        <strong>Custom Supplier</strong>{" "}
                        <Toggle
                          checked={values.custom_supplier}
                          onChange={(checked) => {
                            setFieldValue("custom_supplier", checked);
                            if (checked) {
                              setFieldValue("supplier_name", "");
                              setFieldValue("supplier_email", "");
                              setFieldValue("supplier_phone", "");
                            }
                          }}
                        />
                      </small>
                      <Button
                        appearance="primary"
                        size="xs"
                        onClick={() => {
                          setFieldValue("custom_supplier", false);
                          const tmp = setSupplierForService({
                            srv_type,
                            service,
                            form: values,
                          });
                          setFieldValue("supplier_name", tmp.supplier_name);
                          setFieldValue("supplier_email", tmp.supplier_email);
                          setFieldValue("supplier_phone", tmp.supplier_phone);
                        }}>
                        <strong>Get Supplier From Service</strong>
                      </Button>
                    </React.Fragment>
                  )}
                  <Button
                    appearance={loadSupplierMode ? "ghost" : "primary"}
                    size="xs"
                    onClick={() => setLoadSupplierMode((p) => !p)}>
                    <strong>
                      {loadSupplierMode ? "Cancel" : "Load Supplier"}
                    </strong>
                  </Button>
                </div>
                {loadSupplierMode ? (
                  <div className={classes.supplierAutocomplete}>
                    {supplierModeLoading ? (
                      <Loader center />
                    ) : (
                      <React.Fragment>
                        <NormalProviderAutocompleteField
                          id="ServiceRemarksModal__NormalProviderAutocompleteField"
                          name="supplier_autocomplete"
                          label="Supplier"
                        />
                        <Button
                          color="green"
                          size="xs"
                          onClick={async () => {
                            if (!values.supplier_autocomplete.includes("---")) {
                              return;
                            }

                            const [__, id] =
                              values.supplier_autocomplete.split("---");
                            setSupplierModeLoading(true);
                            const result = await fetchSuppliersProfile(id);
                            setFieldValue("supplier_name", result.name);
                            setFieldValue(
                              "supplier_phone",
                              result.phone || "N/A"
                            );
                            setFieldValue(
                              "supplier_email",
                              result.email || "N/A"
                            );
                            setLoadSupplierMode(false);
                            setSupplierModeLoading(false);
                          }}>
                          <strong>Apply</strong>
                        </Button>
                      </React.Fragment>
                    )}
                  </div>
                ) : values.custom_supplier ? (
                  <div className={classes.col3}>
                    <NormalInputField
                      name="supplier_name"
                      label="Supplier Name"
                    />
                    <NormalInputField
                      name="supplier_email"
                      label="Supplier Email"
                    />
                    <NormalInputField
                      name="supplier_phone"
                      label="Supplier Phone"
                    />
                  </div>
                ) : (
                  <div className={classes.col3}>
                    <Tag>
                      <strong>{values.supplier_name || "N/A"}</strong>
                    </Tag>
                    <Tag>
                      <strong>{values.supplier_email || "N/A"}</strong>
                    </Tag>
                    <Tag>
                      <strong>{values.supplier_phone || "N/A"}</strong>
                    </Tag>
                  </div>
                )}
                <div className={classes.section}>
                  <span>General Information</span>
                </div>
                <div className={`${classes.col2} ${classes.form}`}>
                  <div>
                    <NormalSelectField
                      name="booking_mode"
                      label="Booking Mode"
                      options={[
                        ["CO", "Confirm"],
                        ["RCO", "Reconfirm"],
                        ["RQ", "On Request"],
                      ]}
                    />
                    <NormalRichText
                      name="booking_mode_remarks"
                      label="Booking Mode Remarks"
                    />
                  </div>
                  <div>
                    <div className={classes.col2}>
                      <NormalSelectField
                        name="payment_mode"
                        label="Payment Mode"
                        options={[
                          ["PRE", "Pre-payment"],
                          ["GUA", "By Guarantee"],
                          ["CXL", "By Cancellation Deadline"],
                          ["STA", "By Service Start"],
                          ["STO", "By Service Finish"],
                          ["CU", "Custom"],
                        ]}
                      />
                      <NormalInputField
                        name="payment_deadline"
                        label="Payment Deadline (in days)"
                        type="number"
                      />
                    </div>
                    <NormalRichText
                      name="payment_mode_remarks"
                      label="Payment Remarks"
                    />
                  </div>
                </div>
                <div className={`${classes.col2} ${classes.form}`}>
                  <NormalDatePicker
                    name="response_deadline"
                    label="Response Deadline"
                    withTime={true}
                  />
                  <NormalSelectField
                    name="request_cxl_policy"
                    label="Request Cancellation Policy"
                    options={[
                      [true, "Yes"],
                      [false, "No"],
                    ]}
                  />
                </div>
                <div className={`${classes.col2} ${classes.form}`}>
                  <NormalRichText
                    name="booking_general_remarks"
                    label="Booking General Remarks"
                  />
                  <NormalRichText
                    name="request_cxl_policy_remarks"
                    label="Cancellation Policy Remarks"
                  />
                </div>
                <div className={classes.form}>
                  <NormalRichText
                    name="customer_remarks"
                    label="Customer Remarks"
                  />
                </div>
              </div>
              <div className={classes.actions}>
                <Button onClick={onCancel}>
                  <strong>Cancel</strong>
                </Button>
                <Button color="blue" type="submit">
                  <strong>Submit</strong>
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
ServiceRemarksModal.propTypes = {
  uid: PropTypes.string.isRequired,
  srv_type: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ServiceRemarksModal;
