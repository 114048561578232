import { notifyCommonError } from "@src/components/common/notifications/CommonErrorNotification.js";

import {
  SISIBID_SAVE_OFFERS,
  SISIBID_CHANGE_FLOW_VIEW_TYPE,
  SISIBID_COMPARE_OFFER,
  SISIBID_RESET_COMPARE_OFFER,
  // ===================== OFFER SELECTION ACTIONS =====================
  SISIBID_INIT_SELECTED_OFFERS,
  SISIBID_SELECT_OFFER,
  SISIBID_SELECT_FULL_OFFER,
  SISIBID_DESELECT_OFFER,
} from "./types";

import { selectedBidding } from "@src/selectors/Project/TripPlanner";

import { initializeBiddersFilters } from "./step_two";
import { fetchTravelSrv, fetchTravelSrvOptions } from "@src/actions/Shares";
import { fetchBiddingOffers } from "@src/api/Project/Bidding";

export const fetchOffers = () => async (dispatch, getState) => {
  const state = getState();

  const rq_ids = state.newBiddingList
    .filter((r) => r.request_status == "SE")
    .map((r) => r.id);

  if (rq_ids.length) {
    try {
      const offers = await fetchBiddingOffers([], rq_ids);

      if (offers.results) {
        dispatch({
          type: SISIBID_SAVE_OFFERS,
          offers: offers.results.filter((o) => o.offer_status == "ST"),
        });
      }
    } catch (error) {
      error && error.ovrdErrHandle && error.ovrdErrHandle();
      notifyCommonError(
        "We could not fetch offer data. We are sorry for the inconvenience"
      );
    }
  }
};

export const initSelectedOffers = () => (dispatch, getState) => {
  const state = getState();
  state.newBiddingList.forEach((rq) =>
    dispatch({
      type: SISIBID_INIT_SELECTED_OFFERS,
      request_uid: rq.uid,
      required_services: rq.required_services,
    })
  );
};

export const changeFlowViewType = (view_type) => {
  return { type: SISIBID_CHANGE_FLOW_VIEW_TYPE, view_type };
};

export const compareOffer = (offer_id) => {
  return { type: SISIBID_COMPARE_OFFER, offer_id };
};

export const exitCompareMode = () => ({ type: SISIBID_RESET_COMPARE_OFFER });

export const stepThreeInitActions = () => async (dispatch) => {
  dispatch(initializeBiddersFilters());
  dispatch(fetchTravelSrvOptions());
  dispatch(fetchTravelSrv());
  await dispatch(fetchOffers());
  dispatch(initSelectedOffers());
};

export const selectOffer = (
  offer_id,
  fullOffer = false,
  service_type,
  destOrder
) => (dispatch, getState) => {
  const bidding = selectedBidding(getState());
  if (!fullOffer) {
    dispatch({
      type: SISIBID_SELECT_OFFER,
      service_type,
      destOrder,
      request_uid: bidding.uid,
      offer_id,
    });
  } else {
    dispatch({
      type: SISIBID_SELECT_FULL_OFFER,
      offer_id,
      request_uid: bidding.uid,
    });
  }
};

export const deselectOffer = (fullOffer = false, service_type, destOrder) => (
  dispatch,
  getState
) => {
  const bidding = selectedBidding(getState());
  if (!fullOffer) {
    dispatch({
      type: SISIBID_DESELECT_OFFER,
      service_type,
      destOrder,
      request_uid: bidding.uid,
    });
  } else {
    dispatch({
      type: SISIBID_SELECT_FULL_OFFER,
      request_uid: bidding.uid,
    });
  }
};
