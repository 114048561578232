import { createSelector } from "reselect";

const getAdhocTrfSrv = (state, props) => {
  const { destOrder, trfType } = props;
  return (state.tripPlanTransferAddhocServices || []).find(
    (trf) => trf.destOrder === destOrder && trf.trfType === trfType
  );
};

export const getAdhocDestTrfSelector = createSelector(
  [getAdhocTrfSrv],
  (transfer) => transfer
);
