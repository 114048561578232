import { notifyCommonError } from "@src/components/common/notifications/CommonErrorNotification.js";

import {
  SISIBID_REQUEST_SAVE_TRIP,
  SISIBID_CANCEL_SAVE_REQUEST,
  SISIBID_SAVING_BID,
  SISIBID_SAVED_BID,
  SISIBID_REQUESTS_DATA_UPDATE,
  // ======================= WAIT MODAL ACTIONS ======================
  SISIBID_SHOW_WAIT_MODAL,
  SISIBID_HIDE_WAIT_MODAL,
} from "./types";

import { tripUpdate } from "@src/api/Project/TripPlanner";
import _ from "lodash";

export const requestBidSave = () => {
  return { type: SISIBID_REQUEST_SAVE_TRIP };
};

export const cancelBidSave = () => {
  return { type: SISIBID_CANCEL_SAVE_REQUEST };
};

export const backgroundProgressSave = () => async (dispatch, getState) => {
  const state = getState();

  if (!_.get(state.newBiddingOriginalTripData, "id", null)) {
    return;
  }

  const trip_instance = {
    id: state.newBiddingOriginalTripData.id,
    bidding_current_step: state.newBiddingCurrentStep,
  };

  try {
    await dispatch({
      type: SISIBID_SHOW_WAIT_MODAL,
      msg: "Please wait while we are saving your progress",
    });
    const data = await tripUpdate(trip_instance, trip_instance.id);
    await dispatch({ type: SISIBID_HIDE_WAIT_MODAL });
    console.log(data);
  } catch (error) {
    await dispatch({ type: SISIBID_HIDE_WAIT_MODAL });
    notifyCommonError(
      "Failed to save your bidding progress. We are sorry for the inconvenience"
    );
  }
};

export const saveBiddingTrip = () => async (dispatch, getState) => {
  const state = getState();

  const trip_instance = {
    id: state.newBiddingOriginalTripData.id,
    bidding_current_step: state.newBiddingCurrentStep,
    bid_requests: state.newBiddingList
      .filter((b) => b.setup_status == "DO")
      .map((b) => {
        const tmp = {
          uid: b.uid,
          title: b.title,
          bidder_entities: b.bidder_entities,
          setup_status: b.setup_status,
          request_status: b.request_status,
          pricing_data: b.pricing_data,
          required_services: b.required_services,
          refundable_services: b.refundable_services,
          replaceable_services: b.replaceable_services,
          replaceable_services_types: b.replaceable_services_types,
          service_comments: b.service_comments,
          // In seconds
          required_time:
            (b.required_time.hours * 60 + b.required_time.minutes) * 60,
          price_visibility: b.price_visibility,
        };

        if (b.id) {
          tmp["id"] = b.id;
        }

        return tmp;
      }),
  };

  await dispatch({ type: SISIBID_SAVING_BID });
  try {
    const data = await tripUpdate(trip_instance, trip_instance.id);

    dispatch({
      type: SISIBID_REQUESTS_DATA_UPDATE,
      bid_requests: data.bid_requests,
    });
  } catch (error) {
    notifyCommonError(
      "Failed to save your bidding setup. We are sorry for the inconvenience"
    );
  }

  dispatch({ type: SISIBID_SAVED_BID });
};
