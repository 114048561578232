import tinycolor from "tinycolor2";

const easyColors = {
  orange: "#FF6600",
  lightOrange: tinycolor("#FF6600").lighten(10).toHexString(),
  lightOrange2: tinycolor("#FF6600").lighten(15).toHexString(),
  lightOrange3: tinycolor("#FF6600").lighten(40).toHexString(),
  lightOrange4: tinycolor("#FF6600").lighten(45).toHexString(),
  creme: "#FFF5EB",
  darkCreme: "#FFD8BE",
};

export const variables = {
  normal_gap: ".75rem",
  half_gap: ".375rem",
  double_gap: "1.5rem",
  colors: {
    easy: easyColors,
    green: "#4caf50",
    lightGreen: "#A1E08B",
    lightBlue: "#5FA7FB",
    purple: "#DF70A6",
    deepPurple: "#B274FE",
    default: "#00a0e9",
    deepblue: "#006dcc",
    lightGrey: tinycolor("grey").lighten(45).toHexString(),
    deepgrey: "#6f6f6f",
    orange: "orange",
    lightOrange: "#FFE0CB",
    orangeLight1: tinycolor("orange").lighten(25).toHexString(),
    calculatorGrey: "#f7f7f7",
    lightGrey1: "#D7D7D7",
    lightGrey2: tinycolor("grey").lighten(25).toHexString(),
    highlight: "#33b2db",
    food_and_drink: "#f0ad74",
    shopping: "#7ecb94",
    lightTextColor: "#F9F8FD",
    darkTextColor: tinycolor("black").lighten(20).toHexString(),
    lightDarkTextColor: tinycolor("black").lighten(40).toHexString(),
    lightBorderFillColor: "#00a0e9",
    red: "#df7474",
    white: "#ffffff",
    deepRed: "#D30000",
    lightRed: "#FF6A61",
    layoverRed: "#df7474",
    background: {
      base: easyColors.lightOrange3,
      light: easyColors.lightOrange3,
      ultraLight: easyColors.lightOrange4,
      dark: easyColors.lightOrange2,
      creme: easyColors.creme,
      darkCreme: easyColors.darkCreme,
      grey: "#F7F7FA",
      lightGrey1: "#f7f7f7",
      lightGrey: "#EAEAEA",
    },
    text: {
      colored: easyColors.orange,
      dark: tinycolor("black").lighten(20).toHexString(),
      light: "#F9F8FD",
      faint: "grey",
    },
    borders: {
      base: easyColors.lightOrange2,
      dark: easyColors.orange,
      light: "#F9F8FD",
    },
    services: {
      addhoc: tinycolor("#AC78F1").lighten(20).toHexString(),
      addhocBorder: "#AC78F1",
      addhocBody: tinycolor("#AC78F1").lighten(25).toHexString(),
      restaurant: "#ACEFA1",
      restaurantBody: tinycolor("#ACEFA1").lighten(10).toHexString(),
      restaurantBorder: tinycolor("#ACEFA1").lighten(14).toHexString(),
      coordinator: "#FFD598",
      coordinatorBorder: tinycolor("FFD598").lighten(13).toHexString(),
      coordinatorBody: tinycolor("FFD598").lighten(6).toHexString(),
      generalService: "#FFC5C0",
      generalServiceBorder: tinycolor("#FFC5C0").lighten(9).toHexString(),
      generalServiceBody: tinycolor("#FFC5C0").lighten(6).toHexString(),
      activityService: tinycolor("#91A2A4").lighten(10).toHexString(),
      activityServiceBorder: tinycolor("#91A2A4").lighten(20).toHexString(),
      activityServiceBody: tinycolor("#91A2A4").lighten(30).toHexString(),
      trpService: "#B6CDF8",
      trpServiceBorder: tinycolor("#B6CDF8").lighten(13).toHexString(),
      trpServiceBody: tinycolor("#B6CDF8").lighten(8).toHexString(),
      trfService: tinycolor("#ADC1B9").lighten(10).toHexString(),
      trfServiceBorder: tinycolor("#ADC1B9").lighten(5).toHexString(),
      trfServiceBody: tinycolor("#ADC1B9").lighten(20).toHexString(),
      accService: tinycolor("#DE4580").lighten(25).toHexString(),
      accServiceBorder: "#DE4580",
      accServiceBody: tinycolor("#DE4580").lighten(35).toHexString(),
    },
  },
  spaces: {
    centerMargin: "0 25vw",
    centerPadding: "0 25vw",
    smallScreenCenterPadding: "0 15vw",
  },
  media: {
    smallscreen: "screen and (min-width: 1024px)",
    bigscreen: "screen and (min-width: 1441px)",
    retina:
      "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-width: 1441px)",
  },
  shadows: {
    normalShadow: "2px 2px 5px rgba(0, 0, 0, 0.12)",
    heavyShadow: "2px 2px 5px rgba(0, 0, 0, 0.4)",
    normalInsetShadow: "inset 2px 2px 10px rgba(0, 0, 0, 0.2)",
    normalInsetSideShadow: "inset 2px 0px 10px rgba(0, 0, 0, 0.1)",
    hoverShadow: `2px 2px 20px rgba(0, 0, 0, 0.2)`,
  },
  icons: {
    edit: "material-symbols:edit",
    save: "material-symbols:save-outline-rounded",
    cancel: "iconoir:cancel",
    tick: "fluent:checkbox-checked-20-filled",
    toggleOn: "ic:round-toggle-on",
    toggleOff: "ic:round-toggle-off",
    add: "ic:outline-plus",
    remove: "ic:baseline-minus",
    questionMark: "ic:sharp-question-mark",
    checkedCheckbox: "ic:round-check-box",
    uncheckedCheckbox: "ic:round-check-box-outline-blank",
    tile: "material-symbols:view-cozy",
    table: "pepicons-pop:list",
  },
};

// ============================== LISTS ==============================

export const baseListLayout = {
  display: "grid",
  gridTemplateRows: "auto auto auto 1fr",
  overflow: "hidden",
  height: "100%",
};

// ============================== TABLES =============================
export const tableHeadStyles = {
  "background": variables.colors.background.base,
  "borderBottom": `2px solid ${variables.colors.borders.base}`,
  "& th": {
    padding: `calc(${variables.normal_gap} / 2)`,
  },
  "& td": {
    fontWeight: "bold",
  },
};
export const tableStyles = {
  table: {
    "width": "100%",
    "& > tbody > tr:nth-child(odd)": {
      background: "white",
    },
    "& > tbody > tr:nth-child(2n)": {
      background: tinycolor(variables.colors.background.light)
        .lighten(5)
        .toHexString(),
    },
  },
  head: {
    ...tableHeadStyles,
  },
  cell: {
    padding: variables.normal_gap,
    textAlign: "center",
  },
};

// ========================= VERTICAL TABLES =========================
export const verticalTableMainCellStyles = {
  padding: `calc(${variables.normal_gap} / 2)`,
  background: variables.colors.background.light,
  borderRight: `2px solid ${variables.colors.borders.base}`,
  fontWeight: "bold",
};

export const verticalTableCellStyles = {
  padding: `calc(${variables.normal_gap} / 2)`,
};

export const verticalTableStyles = {
  mainCell: { ...verticalTableMainCellStyles },
  cell: { ...verticalTableCellStyles },
};

// ============================== CARDS ==============================
export const cardStyles = {
  card: {
    boxShadow: variables.shadows.normalShadow,
    borderRadius: `calc(${variables.normal_gap} / 2)`,
  },
  header: {
    padding: variables.normal_gap,
    background: variables.colors.background.ultraLight,
    borderRadius: [
      `calc(${variables.normal_gap} / 2)`,
      `calc(${variables.normal_gap} / 2)`,
      0,
      0,
    ],
    display: "grid",
    gridTemplateColumns: "1fr auto",
    gridGap: variables.half_gap,
    alignItems: "center",
  },
  headerLabel: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    gridGap: variables.half_gap,
    fontSize: "large",
    fontWeight: "600",
    alignItems: "center",
  },
  headerIcon: {
    background: variables.colors.background.dark,
    color: "white",
    fontSize: "2rem",
    borderRadius: "50%",
    padding: `calc(${variables.half_gap} / 2)`,
  },
  body: {
    display: "grid",
    padding: variables.normal_gap,
    paddingLeft: `3rem`,
  },
  actions: {
    display: "grid",
    gridAutoFlow: "column",
    justifyContent: "end",
    gridGap: variables.normal_gap,
    padding: variables.normal_gap,
  },
};

// ============================= FILTERS =============================
export function quickFiltersStylesMain(filtersNo = null) {
  return {
    background: variables.colors.background.dark,
    padding: variables.normal_gap,
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    gridTemplateColumns: filtersNo
      ? `repeat(${filtersNo}, 1fr) auto auto`
      : "repeat(7, 1fr) auto auto",
    alignItems: "end",
    justifyContent: "space-between",
    borderBottom: `2px solid ${variables.colors.borders.dark}`,
  };
}

export const quickFiltersInputGroup = {
  display: "grid",
  gridGap: `calc(${variables.normal_gap} / 2)`,
  color: variables.colors.lightTextColor,
  width: "100%",
};

export const quickFiltersBtn = {
  justifySelf: "end",
};

// ============================== MODALS =============================
export const modalMainStyle = {
  position: "fixed",
  width: "100vw",
  height: "100vh",
  top: 0,
  left: 0,
  zIndex: 6,
  display: "grid",
  placeContent: "center",
  background: "rgba(0,0,0,0.3)",
  backdropFilter: "blur(5px)",
};

export const cardStyle = {
  background: "white",
  boxShadow: variables.shadows.normalShadow,
  minWidth: "30vw",
  maxWidth: "80vw",
  position: "relative",
  borderRadius: "5px",
};

export const cardHeaderStyle = {
  borderBottom: `2px solid ${variables.colors.borders.base}`,
  display: "grid",
  gridTemplateColumns: "1fr auto",
  alignItems: "center",
  padding: variables.normal_gap,
  gridGap: variables.normal_gap,
};

export const modalCardBodyStyle = {
  padding: variables.normal_gap,
  minHeight: "30vh",
  maxHeight: "70vh",
  overflow: "auto",
};

export const modalCardActionsStyle = {
  display: "grid",
  gridGap: variables.normal_gap,
  gridAutoFlow: "column",
  justifyContent: "end",
  padding: variables.normal_gap,
};

export const modalLoaderStyle = {
  zIndex: 9999,
};

export const modalGenericStyles = {
  "@keyframes slidein": {
    from: {
      background: "rgba(0,0,0,0)",
      backdropFilter: "blur(0px)",
    },
    to: {
      background: "rgba(0,0,0,0.3)",
      backdropFilter: "blur(5px)",
    },
  },
  "modal": {
    ...modalMainStyle,
    animationDuration: ".5s",
    animationName: "$slidein",
  },
  "card": cardStyle,
  "cardHeader": cardHeaderStyle,
  "cardBody": modalCardBodyStyle,
  "cardActions": modalCardActionsStyle,
  "loader": modalLoaderStyle,
};

// ============================== FORMS ==============================
export const inputStyles = {
  border: `2px solid ${variables.colors.deepgrey}`,
  borderRadius: "5px",
  padding: `calc(${variables.normal_gap} / 2)`,
  background: "white",
};

export const quillStyles = {
  "display": "grid",
  "gridTemplateRows": "min-content 1fr",
  "border": `2px solid ${variables.colors.deepgrey}`,
  "borderRadius": "5px",
  "background": "white",
  "& .ql-toolbar": {
    borderRadius: `calc(${variables.normal_gap} / 2) calc(${variables.normal_gap} / 2) 0 0`,
  },
  "& .ql-container": {
    borderRadius: `0 0 calc(${variables.normal_gap} / 2) calc(${variables.normal_gap} / 2)`,
    minHeight: "15vh",
    maxHeight: "20vh",
  },
};

export const formStyles = {
  form: {
    background: variables.colors.background.creme,
    borderRadius: variables.half_gap,
    padding: variables.normal_gap,
  },
};

// =========================== DETAIL VIEWS ==========================
export const detailViewStyles = {
  view: {
    display: "grid",
    gridTemplateRows: "auto 1fr",
    height: "100%",
    overflow: "hidden",
  },
  nav: {
    background: variables.colors.background.dark,
    padding: [0, 0, 0, variables.normal_gap],
    display: "grid",
    gridAutoFlow: "column",
    gridTemplateColumns: "max-content max-content 1fr",
    gridGap: variables.normal_gap,
    alignItems: "center",
  },
  navTabs: {
    display: "grid",
    gridAutoFlow: "column",
    gridAutoColumns: "max-content",
    gridGap: variables.normal_gap,
    overflowX: "auto",
    padding: variables.normal_gap,
  },
  navActionBtn: {
    margin: variables.normal_gap,
  },
  content: {
    display: "grid",
    gridGap: variables.normal_gap,
    padding: variables.normal_gap,
  },
  verticalSeparator: {
    width: "0px",
    height: "100%",
    border: "2px solid white",
  },
};

export const reservationSectionStyles = {
  section: {
    display: "grid",
  },
  sectionHeader: { ...cardStyles.header },
  sectionHeaderButtons: {
    display: "grid",
    gridTemplateColumns: "auto auto auto",
    gridColumnGap: `calc(${variables.normal_gap} / 2)`,
  },
  sectionBody: {
    display: "grid",
    gridGap: variables.normal_gap,
    padding: variables.normal_gap,
    gridAutoRows: "max-content",
  },
};
export const companyLogoPlaceHolderError =
  "https://s3.sisitrip.com/dev.crm.suixing/CACHE/images/company_pic_ocuEkc5/a4f161303b076106e91263e42decd248.jpg";

export function imgPlaceHolderError(imgType = "ACC") {
  switch (imgType) {
    case "ACC":
      imgType = "accommodation";
      break;
    case "FL":
      imgType = "flight";
      break;
    case "ACT":
      imgType = "activities";
      break;
    case "ADD":
      imgType = "add_on";
      break;
    case "TRF":
      imgType = "transfer";
      break;
    case "COA":
      imgType = "coach";
      break;
    case "FER":
      imgType = "ferries";
      break;
    case "AFER":
      imgType = "ferries";
      break;
    case "TRA":
      imgType = "train";
      break;
    case "ATRA":
      imgType = "train";
      break;
    default:
      return image_placeholder_s3;
  }
  return `https://sisistaticassets.s3.ap-southeast-1.amazonaws.com/assets/services/${imgType}.svg`;
}

export const common_assets_s3 =
  "https://apollo-common-assets.s3.eu-west-3.amazonaws.com/images";
export const image_placeholder_s3 = `${common_assets_s3}/image_placeholder.jpg`;

// =============================== TAGS ==============================
export const labeledTagStyles = {
  "width": "max-content",
  "display": "inline-grid",
  "gridGap": variables.half_gap,
  "fontWeight": "bold",
  "& strong:last-child": {
    borderRadius: "5px",
    background: variables.colors.easy.orange,
    color: "white",
    padding: variables.half_gap,
  },
};
