import { commonList } from "@src/reducers/common";
import {
  B2C_DISTRIBUTION_CHANNELS_FETCH_LIST,
  B2C_DISTRIBUTION_CHANNELS_LIST_GENERIC_CHANGE,
} from "@src/actions/Network/B2CDistributionChannels/types.js";

const B2CWebsitesListReducerInitial = {
  ...commonList,
  filters: {
    api_key: "",
    name: "",
    product_categories: null,
    status: null,
  },
};
export const B2CWebsitesListReducer = (
  state = B2CWebsitesListReducerInitial,
  action
) => {
  switch (action.type) {
    case B2C_DISTRIBUTION_CHANNELS_FETCH_LIST: {
      const { data } = action;
      return { ...state, ...data };
    }
    case B2C_DISTRIBUTION_CHANNELS_LIST_GENERIC_CHANGE: {
      const { key, value } = action;
      if (key === "filters") {
        return { ...state, [key]: value, page: 1 };
      } else {
        return { ...state, [key]: value };
      }
    }
    default:
      return state;
  }
};
