import { airptsByDest } from "@src/api";
import { NormalSelectField } from "@src/components/forms";
import { useQueries, useQuery } from "@tanstack/react-query";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

export const FlightPreferences = ({
  leg,
  legIdx,
  origin_code,
  destination_code,
  mode,
}) => {
  const { setFieldValue } = useFormikContext();

  const [oriAptsOpts, setOriginAirportOptions] = useState([]);
  const [destAptsOpts, setDestAirportOptions] = useState([]);

  useQueries({
    queries: [
      {
        queryKey: ["flight_preferences", legIdx, "origin", origin_code],
        queryFn: () => airptsByDest({ destination_id: origin_code }),
        onSuccess: (data) =>
          setOriginAirportOptions(
            (data?.data?.airports ?? []).map((a) => [
              a.iata,
              `${a.iata}: ${a.name_en}`,
            ])
          ),
        refetchOnWindowFocus: false,
        refetchOnMount: "always",
      },
      {
        queryKey: [
          "flight_preferences",
          legIdx,
          "destination",
          destination_code,
        ],
        queryFn: () => airptsByDest({ destination_id: destination_code }),
        onSuccess: (data) =>
          setDestAirportOptions(
            (data?.data?.airports ?? []).map((a) => [
              a.iata,
              `${a.iata}: ${a.name_en}`,
            ])
          ),
        refetchOnWindowFocus: false,
        refetchOnMount: "always",
      },
    ],
  });

  // Select the first origin option when that's available
  useEffect(() => {
    if (!oriAptsOpts.length) return;
    const origin_airports =
      leg?.service_preferences?.preferences?.origin_airports;
    if (origin_airports) return;

    setFieldValue(
      `legs.${legIdx}.service_preferences.preferences.origin_airports`,
      oriAptsOpts?.[0]?.[0]
    );
  }, [oriAptsOpts]);

  // Select the first destination option when that's available
  useEffect(() => {
    if (!destAptsOpts.length) return;
    const destination_airports =
      leg?.service_preferences?.preferences?.destination_airports;
    if (destination_airports) return;

    setFieldValue(
      `legs.${legIdx}.service_preferences.preferences.destination_airports`,
      destAptsOpts?.[0]?.[0]
    );
  }, [destAptsOpts]);

  const classesOpts = [
    ["", "------"],
    ["ALL", "All Classes"],
    ["ECO", "Economy"],
    ["BIZ", "Business Class"],
    ["FIR", "First Class"],
  ];
  const flTypeOpts = [
    ["", "------"],
    ["ALL", "All"],
    ["GDS", "Gds Only"],
    ["LCC", "Low Cost Carriers Only"],
  ];
  const stopsOptions = [
    ["", "------"],
    ["ALL", "All Stops"],
    ["DIRECT", "Direct Only"],
    ["MULTIPLE", "Multiple Stops Only"],
  ];
  const arrTimeOpts = [
    ["", "------"],
    ["ALL", "Any"],
    ["SAME", "Same day arrival only"],
  ];

  const srvPrefs = leg?.service_preferences?.preferences ?? {};

  return (
    <div className="TransportationLegs__leg__flight-preferences">
      {mode === "edit" ? (
        <NormalSelectField
          label="Flight Class"
          name={`legs.${legIdx}.service_preferences.preferences.class`}
          options={classesOpts}
        />
      ) : (
        <span className="TransportationLegs__view-attribute">
          <strong>Flight Class</strong>
          {classesOpts.find((c) => c[0] === srvPrefs?.["class"])?.[1] ?? "N/A"}
        </span>
      )}
      {mode === "edit" ? (
        <NormalSelectField
          label="Flight Type"
          name={`legs.${legIdx}.service_preferences.preferences.flight_type`}
          options={flTypeOpts}
          disabled={true}
        />
      ) : (
        <span className="TransportationLegs__view-attribute">
          <strong>Flight Type</strong>
          {flTypeOpts.find((c) => c[0] === srvPrefs?.flight_type)?.[1] ?? "N/A"}
        </span>
      )}
      {mode === "edit" ? (
        <NormalSelectField
          label="Stops"
          name={`legs.${legIdx}.service_preferences.preferences.stops`}
          options={stopsOptions}
          disabled={true}
        />
      ) : (
        <span className="TransportationLegs__view-attribute">
          <strong>Stops</strong>
          {stopsOptions.find((c) => c[0] === srvPrefs?.stops)?.[1] ?? "N/A"}
        </span>
      )}
      {mode === "edit" ? (
        <NormalSelectField
          label="Arrival Time"
          name={`legs.${legIdx}.service_preferences.preferences.arrival`}
          options={arrTimeOpts}
          disabled={true}
        />
      ) : (
        <span className="TransportationLegs__view-attribute">
          <strong>Arrival Time</strong>
          {arrTimeOpts.find((c) => c[0] === srvPrefs?.arrival)?.[1] ?? "N/A"}
        </span>
      )}
      <div className="TransportationLegs__leg__flight-preferences__airports">
        {mode === "edit" ? (
          <NormalSelectField
            name={`legs.${legIdx}.service_preferences.preferences.origin_airports`}
            label="Origin Airport"
            options={[["", "------"], ...oriAptsOpts]}
            highlightErrors={true}
          />
        ) : (
          <span className="TransportationLegs__view-attribute">
            <strong>Origin Airport</strong>
            {oriAptsOpts.find((c) => c[0] === srvPrefs?.origin_airports)?.[1] ??
              "N/A"}
          </span>
        )}
        {mode === "edit" ? (
          <NormalSelectField
            name={`legs.${legIdx}.service_preferences.preferences.destination_airports`}
            label="Destination Airport"
            options={[["", "------"], ...destAptsOpts]}
            highlightErrors={true}
          />
        ) : (
          <span className="TransportationLegs__view-attribute">
            <strong>Destination Airport</strong>
            {destAptsOpts.find(
              (c) => c[0] === srvPrefs?.destination_airports
            )?.[1] ?? "N/A"}
          </span>
        )}
      </div>
    </div>
  );
};
FlightPreferences.defaultProps = {
  mode: "view",
};
FlightPreferences.propTypes = {
  leg: PropTypes.object.isRequired,
  legIdx: PropTypes.number.isRequired,
  origin_code: PropTypes.string.isRequired,
  destination_code: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(["view", "edit"]),
};
