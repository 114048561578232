import service from "@src/api/request";

export function getSubscriptionFeesStatistic(param) {
  return service({
    url: "/bid/subscription-fee",
    method: "get",
    params: param,
  });
}

export function getSubscriptionFeescurrentMonthStatistic() {
  return service({
    url: "/bid/subscription-fee/current",
    method: "get",
  });
}
