// ============================== DETAIL =============================
export const ADDON_RESERVATION_DETAILS_INIT = "ADDON_RESERVATION_DETAILS_INIT";
export const ADDON_RESERVATION_DETAILS_SET = "ADDON_RESERVATION_DETAILS_SET";
export const ADDON_RESERVATION_DETAILS_CHANGE_SECTION =
  "ADDON_RESERVATION_DETAILS_CHANGE_SECTION";
export const ADDON_RESERVATION_DETAILS_SHOW_BOOKING_INFO_MODAL =
  "ADDON_RESERVATION_DETAILS_SHOW_BOOKING_INFO_MODAL";
export const ADDON_RESERVATION_DETAILS_HIDE_BOOKING_INFO_MODAL =
  "ADDON_RESERVATION_DETAILS_HIDE_BOOKING_INFO_MODAL";

// ============================ LIST VIEW ============================
// export const ADDON_RESERVATION_LIST_SAVE = "ADDON_RESERVATION_LIST_SAVE";
// export const ADDON_RESERVATION_BEGIN_FETCH_DATA =
//   "ADDON_RESERVATION_BEGIN_FETCH_DATA";
// export const ADDON_RESERVATION_END_FETCH_DATA =
//   "ADDON_RESERVATION_END_FETCH_DATA";

// ========================= FILTER RELATION =========================
// export const ADDON_RESERVATION_INITIALIZE_LIST_FILTER_FORM =
//   "ADDON_RESERVATION_INITIALIZE_LIST_FILTER_FORM";
// export const ADDON_RESERVATION_UPDATE_FILTERS =
//   "ADDON_RESERVATION_UPDATE_LIST_FILTER_FORM";
// export const ADDON_RESERVATION_APPLY_LIST_FILTER =
//   "ADDON_RESERVATION_APPLY_LIST_FILTER";
// ========================= COLUMNS RELATION ========================
// export const ADDON_RESERVATION_UPDATE_LIST_COLUMNS_FORM =
//   "ADDON_RESERVATION_UPDATE_LIST_COLUMNS_FORM";
// export const ADDON_RESERVATION_APPLY_LIST_COLUMNS =
//   "ADDON_RESERVATION_APPLY_LIST_COLUMNS";
// export const ADDON_RESERVATION_INITIALIZE_LIST_COLUMNS_FORM =
//   "ADDON_RESERVATION_INITIALIZE_LIST_COLUMNS_FORM";

// =========================== DETAIL VIEW ===========================
// export const ADDON_RESERVATION_RESERVATION_INIT =
//   "ADDON_RESERVATION_RESERVATION_INIT";
// export const ADDON_RESERVATION_FETCH_DETAIL_VIEW =
//   "ADDON_RESERVATION_FETCH_DETAIL_VIEW";
// export const ADDON_RESERVATION_RESERVATION_DATA =
//   "ADDON_RESERVATION_RESERVATION_DATA";

//========================DASHBOARD===============================
export const ADDON_RESERVATION_VIEW_TYPE = "ADDON_RESERVATION_VIEW_TYPE";
export const ADDON_RESERVATION_FETCH_GRAPH_DATA =
  "ADDON_RESERVATION_FETCH_GRAPH_DATA";
export const ADDON_RESERVATION_BEGIN_FETCH_GRAPH_DATA =
  "ADDON_RESERVATION_BEGIN_FETCH_GRAPH_DATA";
export const ADDON_RESERVATION_END_FETCH_GRAPH_DATA =
  "ADDON_RESERVATION_END_FETCH_GRAPH_DATA";
export const ADDON_RESERVATION_INIT_GRAPH_TYPE =
  "ADDON_RESERVATION_INIT_GRAPH_TYPE";
