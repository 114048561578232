//=============================LIST VIEW===========================
// export const MULTI_SERVICES_BEGIN_FETCH_DATA =
//   "MULTI_SERVICES_BEGIN_FETCH_DATA";
// export const MULTI_SERVICES_END_FETCH_DATA = "MULTI_SERVICES_END_FETCH_DATA";
// export const MULTI_SERVICES_FETCH_DATA = "MULTI_SERVICES_FETCH_DATA";
//=============================FILTER ========================
// export const MULTI_SERVICES_INITIALIZE_LIST_FILTER_FORM =
//   "MULTI_SERVICES_INITIALIZE_LIST_FILTER_FORM";
// export const MULTI_SERVICES_UPDATE_LIST_FILTER_FORM =
//   "MULTI_SERVICES_UPDATE_LIST_FILTER_FORM";
// export const MULTI_SERVICES_APPLY_LIST_FILTER =
//   "MULTI_SERVICES_APPLY_LIST_FILTER";

// export const MULTI_SERVICES_APPLY_LIST_COLUMNS =
//   "MULTI_SERVICES_APPLY_LIST_COLUMNS";
// export const MULTI_SERVICES_INITIALIZE_LIST_COLUMNS_FORM =
//   "MULTI_SERVICES_INITIALIZE_LIST_COLUMNS_FORM";
// export const MULTI_SERVICES_RESET_LIST_FILTER =
//   "MULTI_SERVICES_RESET_LIST_FILTER";

//==================================DETAIL VIEW==========================
// export const MULTI_SERVICES_FETCH_DETAIL_VIEW =
//   "MULTI_SERVICES_FETCH_DETAIL_VIEW";
// export const MULTI_SERVICES_RESERVATION_INIT =
//   "MULTI_SERVICES_RESERVATION_INIT";
// export const MULTI_SERVICES_DETAIL_DATA = "MULTI_SERVICES_DETAIL_DATA";

//========================DASHBOARD===============================
export const MULTI_SERVICES_CHANGE_GRAPH_VIEW_TYPE =
  "MULTI_SERVICES_CHANGE_GRAPH_VIEW_TYPE";
export const MULTI_SERVICES_FETCH_GRAPH_DATA =
  "MULTI_SERVICES_FETCH_GRAPH_DATA";
export const MULTI_SERVICES_BEGIN_FETCH_GRAPH_DATA =
  "MULTI_SERVICES_BEGIN_FETCH_GRAPH_DATA";
export const MULTI_SERVICES_END_FETCH_GRAPH_DATA =
  "MULTI_SERVICES_END_FETCH_GRAPH_DATA";
export const MULTI_SERVICES_INIT_GRAPH_TYPE = "MULTI_SERVICES_INIT_GRAPH_TYPE";
