import {
  BRANCHES_BEGIN_FETCH_DATA,
  BRANCHES_FETCH_DATA,
  BRANCHES_END_FETCH_DATA,
  BRANCHES_FETCH_OPTIONS,
  BRANCHES_UPDATE_LIST_FILTER_FORM,
  BRANCHES_APPLY_LIST_FILTER,
  BRANCHES_INITIALIZE_LIST_FILTER_FORM,
  BRANCHES_UPDATE_LIST_COLUMNS_FORM,
  BRANCHES_APPLY_LIST_COLUMNS,
  BRANCHES_INITIALIZE_LIST_COLUMNS_FORM,
  BRANCHES_SHOW_BASE_DATA_EDIT_FORM,
  BRANCHES_HIDE_BASE_DATA_EDIT_FORM,
  BRANCHES_EDIT_BASE_DATA,
  BRANCHES_INITIALIZE_BASE_DATA_EDIT_FORM,
  BRANCHES_VALIDATE_BASE_DATA_EDIT_FORM,
  BRANCHES_PROFILE_DATA,
  BRANCHES_UPLOADING_BASE_DATA_EDIT_FORM,
  BRANCHES_IDLE_BASE_DATA_EDIT_FORM,
  BRANCHES_CLEAN_BASE_EDIT_FORM_ERRORS,
  BRANCHES_CLEAN_BASE_DATA_EDIT_FORM,
  BRANCHES_VIEW_NATURE_OF_BIZ,
  BRANCHES_EDIT_NATURE_OF_BIZ,
  BRANCHES_INITIALIZE_NATURE_DATA_FORM,
  BRANCHES_EDIT_NATURE_OF_BIZ_DATA,
  BRANCHES_SUBMIT_NATURE_OF_BIZ_DATA,
  BRANCHES_EDIT_LICENSE_CODE,
  BRANCHES_TOGGLE_POSTAL_ADDRESS,
  BRANCHES_RESET_LIST_FILTER_FORM,
  BRANCHES_INITIALIZE_TRAVEL_SERVICE_FORM,
  BRANCHES_INITIALIZE_MAIN_SERVICES_FORM,
  // ========================== IMG RELATION =========================
  BRANCHES_UPLOAD_PREFERENCE_IMAGE,
  BRANCHES_FETCH_ADDRESS_POINT,
  BRANCHES_INITIAL_ADDRESS_POINT,
  BRANCHES_TOGGLE_VERIFY_ADDRESS,
  BRANCHES_BEGIN_FETCH_ADDRESS_DATA,
  BRANCHES_END_FETCH_ADDRESS_DATA,
  BRANCHES_BEGIN_FETCH_PROFILE_DATA,
  BRANCHES_END_FETCH_PROFILE_DATA,
  BRANCHES_CLOSE_VERIFY_ADDRESS,
  BRANCHES_CLEAN_ADDRESS_POINT,
  BRANCHES_INITIAL_VERIFY_STATUS,
  //==================Email Config ==================
  BRANCHES_VIEW_EMAIL_CONFIG_MODE,
  BRANCHES_EDIT_EMAIL_CONFIG_MODE,
  BRANCHES_LOADING_EMAIL_CONFIG_MODE,
  BRANCHES_EDIT_EMAIL_CONFIG_FORM,
  BRANCHES_INITIALIZE_EMAIL_CONFIG_FORM,
} from "./types";

import { notifyCommonError } from "@src/components/common/notifications/CommonErrorNotification.js";
import { notifyCommonSuccess } from "@src/components/common/notifications/CommonSuccessNotification.js";
import _ from "lodash";
import { createHashHistory } from "history";

import {
  initialBranchesBaseDataForm,
  initialBranchesNatureOfBizForm,
  initialBranchesFilterForm,
  defaultOption,
} from "@src/forms/Network/Branches";

import React from "react";
import TransTxt from "@src/components/common/SxFormatMessage";

import {
  changeUploadImgFactory,
  fetchCompleteAddressFactory,
  fetchAddressPointDetailFactory,
} from "./../../ActionFactory";

import { submitProcessor, entityError400Handle } from "@src/actions/tools";
import { travelServicesFrom } from "@src/forms/Admin/Company/companyProfileForms";

import {
  getBranchesList,
  getBranchesOptions,
  addNewBranches as addNewBranchesAPI,
  fetchBranchesProfile as fetchBranchesProfileAPI,
  submitBranchesProfile as submitBranchesProfileAPI,
  uploadImgLink,
} from "@src/api";

import {
  filterEmptyValue,
  paramsProcessor,
  isEmptyObj,
  errorHandle as commonErrorHandle,
} from "@src/tools/common_tools";

import { hideGlobalModal } from "@src/actions/Shares";

import update from "immutability-helper";

import { getFilter, getPagination } from "@src/tools/form_tools";

// branches list
export const addNewBranchesData = () => (dispatch, getState) => {
  const state = getState();
  const userMeta = state.userMeta;
  const verifyStatus = state.isVerifyBranchesPostalAddress;
  if (state.branchesBaseDataFormErrors.valid == false) {
    for (const key in state.branchesBaseDataFormErrors) {
      if (state.branchesBaseDataFormErrors[key].hasError) {
        notifyCommonError(state.branchesBaseDataFormErrors[key].errorMessage);
        return null;
      }
    }
    return null;
  }

  if (!verifyStatus) {
    return notifyCommonError("Please Verify the Address");
  }

  const entityData = {
    name: state.branchesBaseDataForm.name.value,
    legal_title: state.branchesBaseDataForm.legal_title.value,
    registration_code: state.branchesBaseDataForm.registration_code.value,
    founding_year: state.branchesBaseDataForm.founding_year.value,
    email: state.branchesBaseDataForm.email.value,
    url: state.branchesBaseDataForm.url.value,
    weekdays: [true, true, true, true, true, false, false],
    working_time_from: "09:00",
    working_time_to: "18:00",
    brief_description_en: state.branchesBaseDataForm.brief_description_en.value,
    brief_description_cn: state.branchesBaseDataForm.brief_description_cn.value,
    travel_services: [],
    license_type: [],
    phone: state.branchesBaseDataForm.phone.value,
    mobile_phone: state.branchesBaseDataForm.mobile_phone.value,
    country_code: state.branchesBaseDataForm.country_code.value,
    [userMeta.company_type]: userMeta.company_id,
  };
  //contact person
  const contactPersonData = {
    first_name: state.branchesBaseDataForm.first_name.value,
    last_name: state.branchesBaseDataForm.last_name.value,
  };
  entityData.contact_person = [contactPersonData];

  // address
  let postal_country_code = "";
  if (state.branchesBaseDataForm.postal_country_code.value) {
    postal_country_code =
      state.branchesBaseDataForm.postal_country_code.value.split("-")[0];
  }
  const postal_Address_Data = {
    street_number: state.branchesBaseDataForm.postal_street_number.value,
    street: state.branchesBaseDataForm.postal_street.value,
    country_code: postal_country_code,
    region: state.branchesBaseDataForm.postal_region.value,
    city: state.branchesBaseDataForm.postal_city.value,
    post_code: state.branchesBaseDataForm.postal_post_code.value,
    lat: state.branchesBaseDataForm.lat.value,
    lng: state.branchesBaseDataForm.lng.value,
  };
  entityData.address = [postal_Address_Data];

  dispatch(loadingBranchesBaseDataEditForm());
  dispatch(addNewBranchesAPIForm(entityData));
};

export const addNewBranchesAPIForm =
  (entityData) => async (dispatch, getState) => {
    try {
      const res = await addNewBranchesAPI(entityData);
      const { id } = res;
      const customHistory = createHashHistory();
      customHistory.push(`/network/branches/profile/?id=${id}`);
    } catch (error) {
      commonErrorHandle({ error, form: getState().affiliatesBaseDataForm });
    } finally {
      dispatch(idleBranchesBaseDataEditForm());
      dispatch(hideGlobalModal());
    }
  };

export const addNewBranches = () => (dispatch, getState) => {
  const tempForm = _.cloneDeep(getState().branchesBaseDataForm);
  // add protocol to validate,but no send to backend
  if (tempForm.url.value) {
    tempForm.url.value = "http://" + tempForm.url.value;
  }
  dispatch(validateBranchesBaseDataEditFormData(tempForm));

  Promise.resolve().then(() => dispatch(addNewBranchesData()));
};

const initialOptions = {
  status: {
    choices: [
      { value: "AC", display_name: <TransTxt id="active" startCase /> },
      {
        value: "IN",
        display_name: <TransTxt id="inactive" startCase />,
      },
      {
        value: "CA",
        display_name: <TransTxt id="candidate" startCase />,
      },
    ],
  },
};
export const fetchBranchesOptions =
  (langCode) => async (dispatch, getState) => {
    const lang = getState().setLang;

    if (getState().branchesOptions.langCode == (langCode || lang)) {
      return;
    }

    const data = await getBranchesOptions(langCode || lang);
    const options = _.merge(data.actions.POST, initialOptions);
    dispatch(
      branchesOptionsData({
        ...options,
        langCode: langCode || lang,
      })
    );
  };

export const branchesOptionsData = (data) => {
  return {
    type: BRANCHES_FETCH_OPTIONS,
    data,
  };
};

export const fetchBranchesListData = (data) => {
  return {
    type: BRANCHES_FETCH_DATA,
    data: data,
  };
};

export const beginBranchesListData = () => {
  return { type: BRANCHES_BEGIN_FETCH_DATA };
};

export const endBranchesListData = () => {
  return { type: BRANCHES_END_FETCH_DATA };
};

// branches profile

export const submitBranchesProfile =
  (fields, uploadingAction, errorAction = [], successAction, alert = true) =>
  async (dispatch, getState) => {
    dispatch(uploadingAction());

    const state = getState();
    const result = submitProcessor(
      state,
      "branchesTravelServicesForm",
      travelServicesFrom,
      fields
    );

    if (result.hasError) {
      errorAction.forEach((action) => dispatch(action(result)));
      if (alert) {
        result.errorMsgs.forEach((e) => notifyCommonError(e.msg));
      }
      return null;
    }
    const req = {
      ...result.payload,
      main_service: state.branchesMainServicesForm.value,
    };

    try {
      const data = await submitBranchesProfileAPI(
        state.branchesProfile.id,
        req,
        "patch"
      );
      dispatch({ type: BRANCHES_PROFILE_DATA, data: data });
      dispatch(successAction());
      notifyCommonSuccess("Successfully updated your profile.");
    } catch (error) {
      commonErrorHandle({ error });
      dispatch(errorAction());
    }
  };

export const branchProfileData = (data) => {
  return {
    type: BRANCHES_PROFILE_DATA,
    data,
  };
};

export const cleanBranchesBaseEditFormErrors = () => {
  return { type: BRANCHES_CLEAN_BASE_EDIT_FORM_ERRORS };
};

export const branchesProfileData = (data) => (dispatch) => {
  const addresses = _.groupBy(data.address || [], "address_type");
  data.billingAddress = _.get(addresses, "BI.0") || {};
  data.postalAddress = _.get(addresses, "BU.0") || {};
  data.contactPerson = _.get(data, "contact_person.0") || {};
  dispatch(branchProfileData(data));
};

export const beginFetchBranchesProfile = () => {
  return { type: BRANCHES_BEGIN_FETCH_PROFILE_DATA };
};

export const endFetchBranchesProfile = () => {
  return { type: BRANCHES_END_FETCH_PROFILE_DATA };
};

export const fetchBranchesProfile = (id) => async (dispatch) => {
  dispatch(beginFetchBranchesProfile());

  try {
    const data = await fetchBranchesProfileAPI(id);
    dispatch(branchesProfileData(data));
    dispatch(initializeBranchesProfileMainServicesForm(data.main_service));
    dispatch(
      initializeBranchesProfileTravelServicesForm({
        value: data.travel_services,
      })
    );
  } catch (error) {
    commonErrorHandle({ error });
  } finally {
    dispatch(endFetchBranchesProfile());
  }
};

export const initializeBranchesProfileMainServicesForm = (data) => {
  return {
    type: BRANCHES_INITIALIZE_MAIN_SERVICES_FORM,
    data,
  };
};

export const initializeBranchesProfileTravelServicesForm = (data) => {
  return {
    type: BRANCHES_INITIALIZE_TRAVEL_SERVICE_FORM,
    data,
  };
};

export const hideBranchesBaseEditForm = () => {
  return { type: BRANCHES_HIDE_BASE_DATA_EDIT_FORM };
};

export const editBranchesBaseDataForm = (data) => {
  return { type: BRANCHES_EDIT_BASE_DATA, data: data };
};

export const submitBranchesBaseDataEditForm = () => (dispatch, getState) => {
  const state = _.cloneDeep(getState());
  const branchesProfile = state.branchesProfile;
  const verifyStatus = state.isVerifyBranchesPostalAddress;
  const postalAddress = branchesProfile.postalAddress;

  const file = state.branchesBaseDataForm.original_image.fileName;
  const formData = new FormData();

  formData.append("original_image", file);
  formData.append("content_type", "branch");
  formData.append("object_id", getState().branchesProfile.id);

  let country_code = "";
  if (state.branchesBaseDataForm.country_code.value) {
    country_code = state.branchesBaseDataForm.country_code.value.split("-")[0];
  }
  const billingAddressData = {
    street_number: state.branchesBaseDataForm.street_number.value,
    street: state.branchesBaseDataForm.street.value,
    country_code: country_code,
    region: state.branchesBaseDataForm.region.value,
    city: state.branchesBaseDataForm.city.value,
    post_code: state.branchesBaseDataForm.post_code.value,
  };

  let postal_country_code = "";
  if (state.branchesBaseDataForm.postal_country_code.value) {
    postal_country_code =
      state.branchesBaseDataForm.postal_country_code.value.split("-")[0];
  }

  const postalAddressData = {
    street_number: state.branchesBaseDataForm.postal_street_number.value,
    street: state.branchesBaseDataForm.postal_street.value,
    country_code: postal_country_code,
    region: state.branchesBaseDataForm.postal_region.value,
    city: state.branchesBaseDataForm.postal_city.value,
    post_code: state.branchesBaseDataForm.postal_post_code.value,
    lat: state.branchesBaseDataForm.lat.value || postalAddress.lat,
    lng: state.branchesBaseDataForm.lng.value || postalAddress.lng,
  };

  if (state.branchesBaseDataFormErrors.valid == false) {
    for (const key in state.branchesBaseDataFormErrors) {
      if (state.branchesBaseDataFormErrors[key].hasError) {
        notifyCommonError(state.branchesBaseDataFormErrors[key].errorMessage);
        return null;
      }
    }
    return null;
  }

  if (
    !postalAddress.lat &&
    !postalAddress.lng &&
    !state.branchesBaseDataForm.lat.value &&
    !state.branchesBaseDataForm.lng.value
  ) {
    return notifyCommonError("Please Geolocate the map to get exact location");
  }

  if (!verifyStatus) {
    return notifyCommonError("Please Verify the Address");
  }

  let entityData = {
    name: state.branchesBaseDataForm.name.value,
    legal_title: state.branchesBaseDataForm.legal_title.value,
    registration_code: state.branchesBaseDataForm.registration_code.value,
    founding_year: state.branchesBaseDataForm.founding_year.value,
    email: state.branchesBaseDataForm.email.value,
    phone: state.branchesBaseDataForm.phone.value,
    mobile_phone: state.branchesBaseDataForm.mobile_phone.value,
    url: state.branchesBaseDataForm.url.value,
    weekdays: state.branchesBaseDataForm.weekdays.value,
    timezone: state.branchesBaseDataForm.timezone.value,
    working_time_from:
      state.branchesBaseDataForm.working_time_from.value + ":00",
    working_time_to: state.branchesBaseDataForm.working_time_to.value + ":00",
    brief_description_en: state.branchesBaseDataForm.brief_description_en.value,
    brief_description_cn: state.branchesBaseDataForm.brief_description_cn.value,
    currency: state.branchesBaseDataForm.currency.value,
  };

  // address
  const address = branchesProfile.address;
  if (_.get(branchesProfile, "billingAddress.id")) {
    filterEmptyValue(
      _.merge(
        _.find(address, { id: _.get(branchesProfile, "billingAddress.id") }),
        billingAddressData
      )
    );
  } else {
    if (!isEmptyObj(billingAddressData)) {
      billingAddressData.address_type = "BI";
      address.push(filterEmptyValue(billingAddressData));
    }
  }

  if (_.get(branchesProfile, "postalAddress.id")) {
    filterEmptyValue(
      _.merge(
        _.find(address, { id: _.get(branchesProfile, "postalAddress.id") }),
        postalAddressData
      )
    );
  } else {
    if (!isEmptyObj(postalAddressData)) {
      postalAddressData.address_type = "BU";
      address.push(filterEmptyValue(postalAddressData));
    }
  }
  entityData.address = address;

  //contact person
  const contactPersonData = {
    first_name: state.branchesBaseDataForm.first_name.value,
    last_name: state.branchesBaseDataForm.last_name.value,
  };

  if (_.get(branchesProfile, "contactPerson.id")) {
    _.merge(
      _.find(branchesProfile.contact_person, {
        id: _.get(branchesProfile, "contactPerson.id"),
      }),
      contactPersonData
    );
  } else {
    branchesProfile.contact_person.push(filterEmptyValue(contactPersonData));
  }
  entityData.contact_person = branchesProfile.contact_person;

  entityData = filterEmptyValue(entityData);
  dispatch(loadingBranchesBaseDataEditForm());

  if (
    file !== undefined &&
    !(Object.prototype.toString.call(file) === "[object Object]")
  ) {
    uploadImgLink(formData)
      .then((res) => {
        const original_image = res.original_image;
        dispatch(
          branchUploadPreferenceImage({
            name: "original_image",
            value: original_image,
          })
        );
        dispatch(
          submitBranchesProfileForm(entityData, state.branchesBaseDataForm)
        );
      })
      .catch((error) => {
        dispatch(idleBranchesBaseDataEditForm());
        commonErrorHandle({ error });
      });
  } else {
    dispatch(submitBranchesProfileForm(entityData, state.branchesBaseDataForm));
  }

  return null;
};

export const branchUploadPreferenceImage = (data) => {
  return {
    type: BRANCHES_UPLOAD_PREFERENCE_IMAGE,
    data,
  };
};

export const submitBranchesProfileForm =
  (entityData, form) => async (dispatch, getState) => {
    try {
      const data = await submitBranchesProfileAPI(
        getState().branchesProfile.id,
        entityData,
        "patch"
      );
      dispatch(branchesProfileData(data));
      notifyCommonSuccess("Successfully updated your profile.");
    } catch (error) {
      error.ovrdErrHandle && error.ovrdErrHandle();
      const error400Handle = entityError400Handle(entityData, error.data, form);
      commonErrorHandle({ error, error400Handle });
    } finally {
      dispatch(idleBranchesBaseDataEditForm());
      dispatch(hideBranchesBaseEditForm());
    }
  };

export const loadingBranchesBaseDataEditForm = () => {
  return { type: BRANCHES_UPLOADING_BASE_DATA_EDIT_FORM };
};

export const idleBranchesBaseDataEditForm = () => {
  return { type: BRANCHES_IDLE_BASE_DATA_EDIT_FORM };
};

export const validateBranchesBaseDataEditForm = () => (dispatch, getState) => {
  const form = _.cloneDeep(getState().branchesBaseDataForm);
  // add protocol to validate,but no send to backend
  if (form.url.value) {
    form.url.value = "http://" + form.url.value;
  }

  dispatch(validateBranchesBaseDataEditFormData(form));
  Promise.resolve().then(() => dispatch(submitBranchesBaseDataEditForm()));
};

export const validateBranchesBaseDataEditFormData = (data) => {
  return {
    type: BRANCHES_VALIDATE_BASE_DATA_EDIT_FORM,
    data,
  };
};

// Base data edit section

export const showBranchesBaseEditForm = () => (dispatch, getState) => {
  const profile = getState().branchesProfile;
  const address = profile.billingAddress || {};
  const postalAddress = profile.postalAddress || {};
  const branchesContactPerson = profile.contactPerson || {};
  const formatCountryCodeList = getState().formatCountryCodeList;

  formatCountryCodeList.forEach((item) => {
    if (item.code && item.code == address.country_code) {
      address.country_code = item.value;
    } else {
      return null;
    }
  });
  formatCountryCodeList.forEach((item) => {
    if (item.code && item.code == postalAddress.country_code) {
      postalAddress.country_code = item.value;
    } else {
      return null;
    }
  });

  const data = update(initialBranchesBaseDataForm, {
    name: { value: { $set: profile.name } },
    legal_title: { value: { $set: profile.legal_title } },
    registration_code: { value: { $set: profile.registration_code } },
    founding_year: { value: { $set: profile.founding_year } },
    first_name: { value: { $set: branchesContactPerson.first_name } },
    last_name: { value: { $set: branchesContactPerson.last_name } },
    email: { value: { $set: profile.email } },
    mobile_phone: { value: { $set: profile.mobile_phone } },
    phone: { value: { $set: profile.phone } },
    url: { value: { $set: profile.url } },
    brief_description_en: { value: { $set: profile.brief_description_en } },
    brief_description_cn: { value: { $set: profile.brief_description_cn } },
    street_number: { value: { $set: address.street_number } },
    street: { value: { $set: address.street } },
    country_code: { value: { $set: address.country_code } },
    region: { value: { $set: address.region } },
    city: { value: { $set: address.city } },
    post_code: { value: { $set: address.post_code } },
    postal_street_number: { value: { $set: postalAddress.street_number } },
    postal_street: { value: { $set: postalAddress.street } },
    postal_country_code: { value: { $set: postalAddress.country_code } },
    postal_region: { value: { $set: postalAddress.region } },
    postal_city: { value: { $set: postalAddress.city } },
    postal_post_code: { value: { $set: postalAddress.post_code } },
    original_image: {
      value: {
        $set: profile.logo ? profile.logo.photo_sm_url : "",
      },
    },
    weekdays: {
      value: {
        $set: profile.weekdays || [
          false,
          false,
          false,
          false,
          false,
          false,
          false,
        ],
      },
    },
    timezone: { value: { $set: profile.timezone } },
    working_time_from: {
      value: {
        $set: parseInt((profile.working_time_from || "0").substr(0, 2)),
      },
    },
    working_time_to: {
      value: {
        $set: parseInt((profile.working_time_to || "0").substr(0, 2)),
      },
    },
    currency: {
      value: {
        $set: profile.currency,
      },
    },
  });

  dispatch(initializeBaseDataEditForm(data));
  dispatch(
    initialBranchesAddressPointData({
      lat: postalAddress.lat || 0,
      lng: postalAddress.lng || 0,
    })
  );
  dispatch(closeBranchesVerifyAddress());

  let isSame =
    JSON.stringify({
      street_number: address.street_number,
      street: address.street,
      country_code: address.country_code,
      region: address.region,
      city: address.city,
      post_code: address.post_code,
    }) ==
    JSON.stringify({
      street_number: postalAddress.street_number,
      street: postalAddress.street,
      country_code: postalAddress.country_code,
      region: postalAddress.region,
      city: postalAddress.city,
      post_code: postalAddress.post_code,
    });
  if (
    !address.street_number &&
    !address.street &&
    !address.country_code &&
    !address.region &&
    !address.city &&
    !address.post_code
  ) {
    isSame = false;
  }
  dispatch(showBranchesBaseDataEditForm(isSame));
};

export const initialBranchesAddressPointData = (data) => {
  return {
    type: BRANCHES_INITIAL_ADDRESS_POINT,
    data,
  };
};

export const closeBranchesVerifyAddress = () => {
  return {
    type: BRANCHES_CLOSE_VERIFY_ADDRESS,
  };
};

export const showBranchesBaseDataEditForm = (data) => {
  return {
    type: BRANCHES_SHOW_BASE_DATA_EDIT_FORM,
    data,
  };
};

export const initializeBaseDataEditForm = (data) => {
  return { type: BRANCHES_INITIALIZE_BASE_DATA_EDIT_FORM, data: data };
};

export const cleanBranchesBaseEditForm = () => (dispatch) => {
  dispatch(cleanBranchesBasicBaseEditForm());
  dispatch(cleanBranchesMapInfo());
  dispatch(initialBranchesVerifyStatus());
};

export const cleanBranchesMapInfo = () => {
  return { type: BRANCHES_CLEAN_ADDRESS_POINT };
};

export const initialBranchesVerifyStatus = () => {
  return { type: BRANCHES_INITIAL_VERIFY_STATUS };
};

export const cleanBranchesBasicBaseEditForm = () => {
  return { type: BRANCHES_CLEAN_BASE_DATA_EDIT_FORM };
};

export const fetchBranchesList = (cb) => async (dispatch, getState) => {
  const userMeta = getState().userMeta;
  const obj = {};
  obj[userMeta.company_type] = userMeta.company_id;

  dispatch(beginBranchesListData());

  const branchesListFilter = _.cloneDeep(getState().branchesListFilter);
  const payload = paramsProcessor(branchesListFilter);
  const service_type = getState().travelServiceOptions.service_type || {};
  const filterParams = getFilter(payload, service_type);

  const req = {
    id: filterParams.id, // UID
    name__icontains: filterParams.brand_name, // brand name
    phone__contains: filterParams.phone, // Telephone
    mobile_phone__contains: filterParams.mobile_phone, // Contact Mobile
    contact_person__first_name__icontains: filterParams.first_name, // First Name
    contact_person__last_name__icontains: filterParams.last_name, // Last Name
    email__icontains: filterParams.email, // email
    business_address_street__icontains: filterParams.postal_address, // address
    business_address_city__icontains: filterParams.postal_city, // city
    business_address_post_code__icontains: filterParams.postal_post_code, // post code
    business_address_region__icontains: filterParams.postal_region, // region
    business_address_country_code__icontains: filterParams.postal_country, // country
    url__icontains: filterParams.url, // url
    registration_code__icontains: filterParams.registration_code, // registration code
    legal_title__icontains: filterParams.business_name, // business name
    founding_year: filterParams.founding_year, // founding year
    license_type__contains: filterParams.license_type_choices, // license_type
    expertise_type__contains: filterParams.expertise_type, // expertise_type
    entity_type__contains: filterParams.entity_type, // tags
    travel_services__in: filterParams.travel_services, // travel_services
    status__in: filterParams.status, // status
    creator__icontains: filterParams.creator, // creator
    created__gte: filterParams.created__gte, //
    created__lte: filterParams.created__lte, //
    edited__gte: filterParams.edited__gte, //
    edited__lte: filterParams.edited__lte, //
  };

  const filter = filterEmptyValue(req);
  const pagination = getPagination(getState().branchesList);

  try {
    const data = await getBranchesList({ ...filter, ...pagination, ...obj });
    if (typeof cb == "function") cb();
    dispatch(
      fetchBranchesListData({
        page: pagination.page,
        limit: pagination.limit,
        ...data,
      })
    );
  } catch (error) {
    commonErrorHandle({ error });
  } finally {
    dispatch(endBranchesListData());
  }
};

export const modifyBranchesPage = (page) => (dispatch, getState) => {
  let branchesList = getState().branchesList;
  branchesList = update(branchesList, { page: { $set: page } });
  dispatch(fetchBranchesListData({ ...branchesList }));
  dispatch(fetchBranchesList());
};

export const modifyBranchesLimit = (limit) => (dispatch, getState) => {
  let branchesList = getState().branchesList;
  branchesList = update(branchesList, {
    limit: { $set: limit },
    page: { $set: 1 },
  });
  dispatch(fetchBranchesListData({ ...branchesList }));
  dispatch(fetchBranchesList());
};

// filter relation
export const updateBranchesListFilterForm = (payload) => {
  return { type: BRANCHES_UPDATE_LIST_FILTER_FORM, data: payload };
};

export const applyBranchesListFilter = () => (dispatch, getState) => {
  const state = getState().branchesListFilterForm || {};
  dispatch(hideGlobalModal());
  dispatch(applyBranchesListFilterForm(state));
  dispatch(fetchBranchesList(1));
};

export const applyBranchesListFilterForm = (data) => {
  return {
    type: BRANCHES_APPLY_LIST_FILTER,
    data,
  };
};

export const initializeBranchesListFilterForm = () => (dispatch, getState) => {
  const state = getState().branchesListFilter || {};
  dispatch(initializeBranchesListFilterFormData(state));
};

export const initializeBranchesListFilterFormData = (data) => {
  return {
    type: BRANCHES_INITIALIZE_LIST_FILTER_FORM,
    data,
  };
};

export const resetBranchesListFilter = () => (dispatch, getState) => {
  const data = update(initialBranchesFilterForm, {
    $merge: getState().travelServicesForm,
  });
  dispatch(resetBranchesListFilterForm(data));
};

export const resetBranchesListFilterForm = (data) => {
  return {
    type: BRANCHES_RESET_LIST_FILTER_FORM,
    data,
  };
};

// columns relation
export const updateBranchesListColumnsForm =
  (field, value) => (dispatch, getState) => {
    const state = getState().branchesListColumnsForm || {};
    if (field.type == "push") {
      value = update(new Set(state[field.name].value), { $add: [field.value] });
    }
    if (field.type == "remove") {
      value = update(new Set(state[field.name].value), {
        $remove: [field.value],
      });
    }
    if (field.type == "set") {
      value = field.value;
    }
    const branchesListColumnsForm = update(state, {
      [field.name]: { value: { $set: [...value] } },
    });
    dispatch(updateBranchesListColumnsFormData(branchesListColumnsForm));
  };

export const updateBranchesListColumnsFormData = (data) => {
  return {
    type: BRANCHES_UPDATE_LIST_COLUMNS_FORM,
    data,
  };
};

export const applyBranchesListColumns = () => (dispatch, getState) => {
  const state = getState().branchesListColumnsForm || {};
  dispatch(hideGlobalModal());
  dispatch(applyBranchesListColumnsForm(state));
};

export const applyBranchesListColumnsForm = (data) => {
  return {
    type: BRANCHES_APPLY_LIST_COLUMNS,
    data,
  };
};

export const initializeBranchesListColumnsForm = () => (dispatch, getState) => {
  const currentCols = getState().branchesListColumns;
  dispatch(initializeBranchesListColumnsFormData(currentCols));
};

export const initializeBranchesListColumnsFormData = (data) => {
  return {
    type: BRANCHES_INITIALIZE_LIST_COLUMNS_FORM,
    data,
  };
};

// Nature of Biz section.
export const viewBranchesNatureSection = () => {
  return { type: BRANCHES_VIEW_NATURE_OF_BIZ };
};

export const editBranchesNatureSection =
  (initForm = true) =>
  (dispatch, getState) => {
    if (initForm) {
      const profile = getState().branchesProfile;

      const data = update(initialBranchesNatureOfBizForm, {
        entity_type: { value: { $set: profile.entity_type || [] } },
        expertise_type: { value: { $set: profile.expertise_type || [] } },
        license_type: { value: { $set: profile.license_type || [] } },
      });

      dispatch(initializeNatureDataForm(data));
    }
    dispatch(editBranchesNatureSectionData());
  };

export const editBranchesNatureSectionData = () => {
  return {
    type: BRANCHES_EDIT_NATURE_OF_BIZ,
  };
};

export const initializeNatureDataForm = (data) => {
  return { type: BRANCHES_INITIALIZE_NATURE_DATA_FORM, data: data };
};

export const editBaseNatureDataForm = (data) => {
  return { type: BRANCHES_EDIT_NATURE_OF_BIZ_DATA, data: data };
};

export const loadingBaseNatureDataForm = () => {
  return {
    type: BRANCHES_SUBMIT_NATURE_OF_BIZ_DATA,
  };
};

export const submitBranchesNatureOfBizData =
  () => async (dispatch, getState) => {
    dispatch(loadingBaseNatureDataForm());
    const state = getState();
    const entityId = state.branchesProfile.id;
    const result = submitProcessor(
      state,
      "branchesNatureOfBizForm",
      initialBranchesNatureOfBizForm
    );

    if (result.hasError) {
      dispatch(editBranchesNatureSection(false));
      result.errorMsgs.forEach((e) => notifyCommonError(e.msg));
      return null;
    }

    try {
      const data = await submitBranchesProfileAPI(
        entityId,
        result.payload,
        "patch"
      );
      dispatch(branchesProfileData(data));
      notifyCommonSuccess("Successfully updated Nature of Business.");
    } catch (error) {
      error.ovrdErrHandle && error.ovrdErrHandle();
      commonErrorHandle({ error });
    } finally {
      dispatch(viewBranchesNatureSection());
    }
  };

export const branchesLicenseCodeEdit = (data) => {
  return { type: BRANCHES_EDIT_LICENSE_CODE, data: data };
};

export const synchronizeBranchesPostalAddress =
  (checked) => (dispatch, getState) => {
    const form = getState().branchesBaseDataForm;
    const data = update(form, {
      street_number: { value: { $set: form.postal_street_number.value } },
      street: { value: { $set: form.postal_street.value } },
      country_code: { value: { $set: form.postal_country_code.value } },
      region: { value: { $set: form.postal_region.value } },
      city: { value: { $set: form.postal_city.value } },
      post_code: { value: { $set: form.postal_post_code.value } },
    });
    dispatch(toggleBranchesPostalAddress(checked));
    if (checked) {
      dispatch(initializeBaseDataEditForm(data));
    }
  };

export const toggleBranchesPostalAddress = (data) => {
  return {
    type: BRANCHES_TOGGLE_POSTAL_ADDRESS,
    data,
  };
};

// img relation
export const changeBranchImg = (data) => (dispatch) => {
  dispatch(changeUploadImgFactory(data, BRANCHES_UPLOAD_PREFERENCE_IMAGE));
};

export const applyBranchesDefaultOption = () => (dispatch, getState) => {
  let form = getState().branchesListColumnsForm;

  for (const key in defaultOption) {
    form = update(form, {
      [key]: { value: { $set: defaultOption[key].value } },
    });
  }
  dispatch(applyBranchesDefaultOptionForm(form));
};

export const applyBranchesDefaultOptionForm = (data) => {
  return {
    type: BRANCHES_UPDATE_LIST_COLUMNS_FORM,
    data,
  };
};

//fetchGeolocation

export const beginBranchesAddressPoint = () => {
  return { type: BRANCHES_BEGIN_FETCH_ADDRESS_DATA };
};

export const endBranchesAddressPoint = () => {
  return { type: BRANCHES_END_FETCH_ADDRESS_DATA };
};

export const fetchBranchesAddressPoint = () => (dispatch, getState) => {
  const state = getState();
  const address = state.branchesBaseDataForm;
  dispatch(
    fetchCompleteAddressFactory(address, fetchBranchesAddressPointDetail)
  );
};

export const fetchBranchesAddressPointDetail = (data) => (dispatch) => {
  dispatch(
    fetchAddressPointDetailFactory(
      data,
      BRANCHES_FETCH_ADDRESS_POINT,
      editBranchesBaseDataForm,
      beginBranchesAddressPoint,
      endBranchesAddressPoint
    )
  );
};

export const verifyBranchesPostalAddress = (checked) => {
  return {
    type: BRANCHES_TOGGLE_VERIFY_ADDRESS,
    data: checked,
  };
};

//======================== User Email Config Section========================

export const viewBranchesEmailConfigSection = () => {
  return {
    type: BRANCHES_VIEW_EMAIL_CONFIG_MODE,
  };
};

export const editBranchesEmailConfigSection = () => {
  return { type: BRANCHES_EDIT_EMAIL_CONFIG_MODE };
};

export const loadingBranchesEmailConfigMode = () => {
  return { type: BRANCHES_LOADING_EMAIL_CONFIG_MODE };
};

export const editBranchesEmailConfigDataForm = (data) => {
  return {
    type: BRANCHES_EDIT_EMAIL_CONFIG_FORM,
    data,
  };
};

export const initializeBranchesEmailConfigDataForm =
  () => (dispatch, getState) => {
    const state = getState();
    const res = state.branchesProfile.allowed_email_info;
    if (res) {
      dispatch(
        initializeBranchesEmailConfigData({ email_config: { value: res } })
      );
    }
  };

export const initializeBranchesEmailConfigData = (data) => {
  return {
    type: BRANCHES_INITIALIZE_EMAIL_CONFIG_FORM,
    data,
  };
};

export const submitBranchesEmailConfigData =
  () => async (dispatch, getState) => {
    const state = getState();
    const profile_id = state.branchesProfile.id;
    const form = state.branchesEmailConfigForm;
    const res = form.email_config.value;
    const payload = {
      allowed_email_info: res,
    };
    dispatch(loadingBranchesEmailConfigMode());
    try {
      const data = await submitBranchesProfileAPI(profile_id, payload, "patch");
      notifyCommonSuccess("Successfully updated your profile.");
      await dispatch({ type: BRANCHES_PROFILE_DATA, data: data });
    } catch (error) {
      console.log(error);
      if (error.status == 500) {
        notifyCommonError(
          "Failed updated your profile, we are sorry for this inconvenience"
        );
      }
      commonErrorHandle({ error });
    } finally {
      dispatch(viewBranchesEmailConfigSection());
    }
  };
