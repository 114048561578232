import update from "immutability-helper";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";
import { getMap, geolocateUser } from "@src/map_tools/map";
import mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";

const InteractiveMapHoc = (
  WrappedComponent,
  { containerId = "map", withDraw = false }
) => {
  class Hoc extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        map: null,
        draw: null,
        version: 0,
      };
    }
    componentDidMount() {
      const { mapCenterLocation, zoom } = this.props;

      const options = {
        containerId,
        zoom,
      };

      if (mapCenterLocation.lat !== 0 && mapCenterLocation.lng !== 0) {
        options["startingPosition"] = [
          mapCenterLocation.lng,
          mapCenterLocation.lat,
        ];
      }

      this.setState(
        (p) =>
          update(p, {
            map: {
              $set: getMap(options),
            },
          }),
        () => {
          if (withDraw) {
            const draw = this.getDrawObject();
            this.state.map.addControl(draw, "top-left");
            this.setState((p) => ({ ...p, draw }));
          }
        }
      );
    }
    componentWillUnmount() {
      if (this.state.map) {
        this.state.map.remove();
      }
    }
    async componentDidUpdate(pp, ps) {
      const { map } = this.state;
      const { sliderStatus, resizeFlag } = this.props;

      if (
        map &&
        (pp.sliderStatus !== sliderStatus || pp.resizeFlag !== resizeFlag)
      ) {
        if (map.loaded()) {
          await window.setTimeout(() => map.resize(), 1000);
        } else {
          map.once(
            "load",
            async () => await window.setTimeout(() => map.resize(), 1000)
          );
        }
      }

      if (!ps.map && map) {
        map.once("load", () => {
          this.initialActions();
        });
      }
    }
    getDrawObject() {
      var draw = new MapboxDraw({
        clickBuffer: 8,
        displayControlsDefault: false,
        controls: { line_string: true, trash: true },
        styles: [
          // ACTIVE (being drawn)
          // line stroke
          {
            id: "gl-draw-line",
            type: "line",
            filter: [
              "all",
              ["==", "$type", "LineString"],
              ["!=", "mode", "static"],
            ],
            layout: {
              "line-cap": "round",
              "line-join": "round",
            },
            paint: {
              "line-color": "#D20C0C",
              "line-width": 3,
            },
          },
          // polygon mid points
          {
            id: "gl-draw-polygon-midpoint",
            type: "circle",
            filter: [
              "all",
              ["==", "$type", "Point"],
              ["==", "meta", "midpoint"],
            ],
            paint: {
              "circle-radius": 6,
              "circle-color": "#D20C0C",
            },
          },
          // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
          {
            id: "gl-draw-polygon-stroke-active",
            type: "line",
            filter: [
              "all",
              ["==", "$type", "Polygon"],
              ["!=", "mode", "static"],
            ],
            layout: {
              "line-cap": "round",
              "line-join": "round",
            },
            paint: {
              "line-color": "#D20C0C",
              "line-dasharray": [0.2, 2],
              "line-width": 2,
            },
          },
          // vertex point halos
          {
            id: "gl-draw-polygon-and-line-vertex-halo-active",
            type: "circle",
            filter: [
              "all",
              ["==", "meta", "vertex"],
              ["==", "$type", "Point"],
              ["!=", "mode", "static"],
            ],
            paint: {
              "circle-radius": 10,
              "circle-color": "#FFF",
            },
          },
          // vertex points
          {
            id: "gl-draw-polygon-and-line-vertex-active",
            type: "circle",
            filter: [
              "all",
              ["==", "meta", "vertex"],
              ["==", "$type", "Point"],
              ["!=", "mode", "static"],
            ],
            paint: {
              "circle-radius": 8,
              "circle-color": "#D20C0C",
            },
          },
          // INACTIVE (static, already drawn)
          // line stroke
          {
            id: "gl-draw-line-static",
            type: "line",
            filter: [
              "all",
              ["==", "$type", "LineString"],
              ["==", "mode", "static"],
            ],
            layout: {
              "line-cap": "round",
              "line-join": "round",
            },
            paint: {
              "line-color": "#000",
              "line-width": 2,
            },
          },
        ],
      });
      return draw;
    }
    initialActions() {
      const { map } = this.state;
      const { shouldGeolocate } = this.props;
      if (shouldGeolocate) {
        geolocateUser().then((data) => {
          map.flyTo({ center: data, zoom: 5, duration: 300 });
        });
      }
      this.setState((p) => ({ ...p, version: p.version + 1 }));
    }
    render() {
      const { map, draw, version } = this.state;
      return (
        <WrappedComponent
          map={map}
          draw={draw}
          {...this.props}
          version={version}
        />
      );
    }
  }
  Hoc.defaultProps = {
    mapCenterLocation: { lat: 0, lng: 0 },
    zoom: 10,
  };
  Hoc.propTypes = {
    shouldGeolocate: PropTypes.bool.isRequired,
    sliderStatus: PropTypes.bool.isRequired,
    resizeFlag: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    mapCenterLocation: PropTypes.object.isRequired,
    zoom: PropTypes.number,
  };

  const mapStateToProps = (state) => {
    const sliderStatus = state.toggleAllContainerStatus;
    return {
      sliderStatus,
    };
  };

  return connect(mapStateToProps, null)(Hoc);
};

export default InteractiveMapHoc;
