import service, { getAuthHeaders } from "@src/api/request";
import axios from "axios";
import { SISI_API_URL } from "@src/api";

export function getAccommodationsList(para) {
  return service({
    url: "bid/accreservations/",
    method: "get",
    params: para,
  });
}

export async function getAccommodationStats(params) {
  const auth_headers = getAuthHeaders();
  return await axios.get(`${SISI_API_URL}/reservations/accommodations/stats/`, {
    params,
    headers: { ...auth_headers },
  });
}

export function getAccommodationsOptions(para) {
  return service({
    url: "bid/accreservations/",
    method: "options",
    params: para,
  });
}

export function fetchAccommodationReservationInfoAPI(id) {
  return service({
    url: `bid/accreservations/${id}`,
    method: "get",
  });
}

export function submitAccReservationChange(reference, payload) {
  return service({
    url: `bid/accreservations/${reference}/`,
    method: "patch",
    data: payload,
  });
}

export async function patchPublicAccReservation({ reference, payload }) {
  return service({
    url: `bid/accreservations/${reference}/external_update/`,
    method: "patch",
    data: payload,
  });
}

// ======================== ACCOMMODATION CXL ========================

export const requestReservationCxl = (reference, payload) => {
  return service({
    url: `bid/request_alter_reservation/${reference}/`,
    method: "get",
    params: payload,
  });
};

export const cancelReservation = (reference, payload) => {
  return service({
    url: `bid/alter_reservation/${reference}/`,
    method: "patch",
    data: payload,
  });
};

// =================== RESERVATION OPTION CONFIRM ==================

export const requestReservationOptionConfirm = (reference, payload) => {
  return service({
    url: `bid/request_alter_reservation/${reference}/`,
    method: "get",
    params: payload,
  });
};

export const confirmReservationOption = (reference, payload) => {
  return service({
    url: `bid/alter_reservation/${reference}/`,
    method: "patch",
    data: payload,
  });
};

// ===================== ACCOMMODATION DASHBOARDS ====================
export const accReservationsGraphData = (payload) => {
  return service({
    url: "/bid/accommodation-stats/",
    method: "get",
    params: payload,
  });
};
