import _ from "lodash";
import { NormalDatePicker, NormalInputField } from "@src/components/forms";
import { currencies } from "@src/definitions";
import { variables } from "@src/jsssetup";
import { Form, Formik } from "formik";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Loader } from "rsuite";
import {
  Actions,
  CommonFeedback,
  ContactInformation,
  RequestInfo,
  Stepper,
} from "../common";
import {
  genericButtonsStyles,
  genericInfoGroupStyles,
  genericRsrvFeebackStyles,
  rsrvFeebackStyles,
  rsrvFeebackTableStyles,
} from "../common/styles";
import { useFeedbackRemarks, useSupplierInfo } from "../common/hooks";
import { cxlRuleTemplate } from "../common/cxl_policies";
import { onReject, onSubmit, payloader, retrieverFn } from "../common/helpers";
import {
  patchPublicTrainReservation,
  retrievePublicTrainReservation,
} from "@src/api/Operation/Reservations/Trains";
import Cabins from "../common/cabins";
import {
  commonValidateFeedback,
  validateSupplierInfo,
} from "../common/validators";
import { toast } from "react-toastify";
import { postReservationFeedback } from "@src/api/Operation/Reservations/feedback";
import { basicInfoStylesObj } from "../accommodation/common";

const basicInfoStyles = createUseStyles({
  ...genericInfoGroupStyles,
  ...genericButtonsStyles,
  BasicInfo: genericRsrvFeebackStyles.card,
  header: genericRsrvFeebackStyles.cardHeader,
  body: genericRsrvFeebackStyles.cardBody,
  groupCard: basicInfoStylesObj.groupCard,
  infoGroup1to3: {
    ...basicInfoStylesObj.groupCard,
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr)",
    gridGap: variables.normal_gap,
  },
  infoGroup1to2: {
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr)",
    gridGap: variables.normal_gap,
  },
  infoGroup1to2Right: {
    extend: "infoGroup1to2",
  },
  infoGroup1to2Left: {
    extend: "infoGroup1to2",
  },
  infoGroup1to2_3: {
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr)",
    gridGap: variables.normal_gap,
  },
  infoGroup2: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: variables.normal_gap,
  },
  infoGroup2span2: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: variables.normal_gap,
  },
  infoGroup2to3: {
    ...basicInfoStylesObj.groupCard,
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: variables.normal_gap,
  },
  infoGroup3: {
    ...basicInfoStylesObj.groupCard,
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridGap: variables.normal_gap,
  },
  priceInfoValue: {
    ...genericInfoGroupStyles.infoCoupleValue,
    "width": "5rem",
    "& input": {
      width: "5rem",
    },
  },
  inputInfoValue: {
    ...genericInfoGroupStyles.infoCoupleValue,
    "width": "10rem",
    "& input": {
      width: "10rem",
    },
  },
  table: rsrvFeebackTableStyles.table,
  head: rsrvFeebackTableStyles.head,
  cell: rsrvFeebackTableStyles.cell,
  pricecell: {
    ...rsrvFeebackTableStyles.cell,
    "width": "5rem",
    "& input": {
      width: "5rem",
    },
  },
  buttonsGroup: {
    display: "grid",
    gridGap: variables.normal_gap,
    justifyContent: "end",
  },
  [`@media ${variables.media.bigscreen}`]: {
    infoGroup1to3: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    infoGroup1to2: {
      gridTemplateColumns: "1fr 1fr",
    },
    infoGroup1to2Right: {
      gridTemplateColumns: "1fr 2fr",
    },
    infoGroup1to2Left: {
      gridTemplateColumns: "2fr 1fr",
    },
    infoGroup2to3: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    infoGroup1to2_3: {
      gridTemplateColumns: "2fr 1fr",
    },
    infoGroup2span2: {
      gridColumn: "span 2",
    },
  },
});
// const basicInfoStyles = createUseStyles({
//   BasicInfo: cardStyles.card,
//   header: cardStyles.header,
//   body: cardStyles.body,
//   table: tableStyles.table,
//   head: tableStyles.head,
//   cell: tableStyles.cell,
//   pricecell: {
//     ...tableStyles.cell,
//     "width": "5rem",
//     "& input": {
//       width: "5rem",
//     },
//   },
// });
const BasicInfo = ({ reservation, step, confirmData, onConfirm }) => {
  const classes = basicInfoStyles();
  const [viewMode, setViewMode] = useState(step !== 1);

  const initialConfimData = {
    departure: confirmData.departure || reservation.departure,
    arrival: confirmData.arrival || reservation.arrival,
    price:
      confirmData.price ||
      parseFloat(_.get(reservation, "original_service_price", 0)),
    train_route_number:
      confirmData.train_route_number || reservation.train_route_number,
    company_name: confirmData.train_route_number || reservation.company_name,
    ticket_class: confirmData.ticket_class || reservation.ticket_class_display,
    meal_type: confirmData.meal_type || reservation.meal_type,
    cabins: confirmData.cabins || [
      ..._.get(reservation, "service_data.cabins", []).map((c) => {
        c.type = _.startCase(c.type);
        return c;
      }),
    ],
  };

  return (
    <div className={classes.BasicInfo}>
      <div className={classes.header}>
        <h5>Basic Information</h5>
      </div>
      <div className={classes.body}>
        <Formik
          initialValues={initialConfimData}
          onSubmit={(values) => {
            setViewMode(true);
            onConfirm(values);
          }}>
          {({ values }) => (
            <Form className={classes.body}>
              <div className={classes.infoGroup2to3}>
                <div className={classes.infoCouple}>
                  <span className={classes.infoCoupleTitle}>Origin</span>
                  <span className={classes.infoCoupleValue}>
                    {reservation.origin || "N/A"}
                  </span>
                </div>
                <div className={classes.infoCouple}>
                  <span className={classes.infoCoupleTitle}>Destination</span>
                  <span className={classes.infoCoupleValue}>
                    {reservation.destination || "N/A"}
                  </span>
                </div>
              </div>
              <div className={classes.infoGroup2to3}>
                <div className={classes.infoCouple}>
                  <span className={classes.infoCoupleTitle}>
                    Origin Station
                  </span>
                  <span className={classes.infoCoupleValue}>
                    {reservation.origin_station || "N/A"}
                  </span>
                </div>
                <div className={classes.infoCouple}>
                  <span className={classes.infoCoupleTitle}>
                    Destination Station
                  </span>
                  <span className={classes.infoCoupleValue}>
                    {reservation.destination_station || "N/A"}
                  </span>
                </div>
                <div className={classes.infoCouple}>
                  <span className={classes.infoCoupleTitle}>
                    Train Route Number
                  </span>
                  <span className={classes.inputInfoValue}>
                    {viewMode ? (
                      values.train_route_number || "N/A"
                    ) : (
                      <NormalInputField name="train_route_number" />
                    )}
                  </span>
                </div>
              </div>
              <div className={classes.infoGroup1to2Left}>
                <div className={classes.groupCard}>
                  <div className={classes.infoGroup1to2}>
                    <div className={classes.infoCouple}>
                      <span className={classes.infoCoupleTitle}>Departure</span>
                      <span className={classes.inputInfoValue}>
                        {viewMode ? (
                          DateTime.fromISO(values.departure).toLocaleString(
                            DateTime.DATETIME_MED_WITH_WEEKDAY
                          )
                        ) : (
                          <NormalDatePicker withTime={true} name="departure" />
                        )}
                      </span>
                    </div>
                    <div className={classes.infoCouple}>
                      <span className={classes.infoCoupleTitle}>Arrival</span>
                      <span className={classes.inputInfoValue}>
                        {viewMode ? (
                          DateTime.fromISO(values.arrival).toLocaleString(
                            DateTime.DATETIME_MED_WITH_WEEKDAY
                          )
                        ) : (
                          <NormalDatePicker withTime={true} name="arrival" />
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div className={classes.groupCard}>
                  <div className={classes.infoGroup2}>
                    <div className={classes.infoCouple}>
                      <span className={classes.infoCoupleTitle}>Currency</span>
                      <span className={classes.infoCoupleValue}>
                        {reservation.currency} (
                        {_.get(currencies, reservation.currency)})
                      </span>
                    </div>
                    <div className={classes.infoCouple}>
                      <span className={classes.infoCoupleTitle}>Price</span>
                      <span className={classes.priceInfoValue}>
                        {viewMode ? (
                          Number(values.price).toFixed(2)
                        ) : (
                          <NormalInputField name="price" type="number" />
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.buttonsGroup}>
                {viewMode ? (
                  <button
                    className={classes.cancelButton}
                    onClick={(e) => {
                      e.preventDefault();
                      setViewMode(false);
                    }}>
                    <strong>Edit</strong>
                  </button>
                ) : (
                  <button className={classes.confirmButton} type="submit">
                    <strong>Confirm</strong>
                  </button>
                )}
              </div>

              <div className={classes.infoGroup1to3}>
                <div className={classes.infoGroup1to2Right}>
                  <div className={classes.infoCouple}>
                    <span className={classes.infoCoupleTitle}>
                      Company Name
                    </span>
                    <span className={classes.infoCoupleValue}>
                      {viewMode ? (
                        values.company_name || "N/A"
                      ) : (
                        <NormalInputField name="company_name" />
                      )}
                    </span>
                  </div>
                </div>
                <div className={classes.infoGroup2span2}>
                  <div className={classes.infoCouple}>
                    <span className={classes.infoCoupleTitle}>
                      Ticket Class
                    </span>
                    <span className={classes.infoCoupleValue}>
                      {viewMode ? (
                        reservation.ticket_class_display || "N/A"
                      ) : (
                        <NormalInputField name="ticket_class" />
                      )}
                    </span>
                  </div>
                  <div className={classes.infoCouple}>
                    <span className={classes.infoCoupleTitle}>Meal Type</span>
                    <span className={classes.infoCoupleValue}>
                      {viewMode ? (
                        values.meal_type || "N/A"
                      ) : (
                        <NormalInputField name="meal_type" />
                      )}
                    </span>
                  </div>
                </div>
              </div>

              <div className={classes.infoGroup3}>
                <div className={classes.infoCouple}>
                  <span className={classes.infoCoupleTitle}>Pax</span>
                  <span className={classes.infoCoupleValue}>
                    {reservation.pax}
                  </span>
                </div>
                <div className={classes.infoCouple}>
                  <span className={classes.infoCoupleTitle}>Adults</span>
                  <span className={classes.infoCoupleValue}>
                    {reservation.adults}
                  </span>
                </div>
                <div className={classes.infoCouple}>
                  <span className={classes.infoCoupleTitle}>Children</span>
                  <span className={classes.infoCoupleValue}>
                    {reservation.children}
                  </span>
                </div>
              </div>
              {/* <table className={classes.table}>
                <thead className={classes.head}>
                  <tr>
                    <th>Origin</th>
                    <th>Destination</th>
                    <th>Origin Station</th>
                    <th>Destination Station</th>
                    <th>Train Route Number</th>
                    <th>Departure</th>
                    <th>Arrival</th>
                    <th>Currency</th>
                    <th>Price</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className={classes.cell}>
                      {reservation.origin || "N/A"}
                    </td>
                    <td className={classes.cell}>
                      {reservation.destination || "N/A"}
                    </td>
                    <td className={classes.cell}>
                      {reservation.origin_station || "N/A"}
                    </td>
                    <td className={classes.cell}>
                      {reservation.destination_station || "N/A"}
                    </td>
                    <td className={classes.cell}>
                      {viewMode ? (
                        values.train_route_number || "N/A"
                      ) : (
                        <NormalInputField name="train_route_number" />
                      )}
                    </td>
                    <td className={classes.cell}>
                      {viewMode ? (
                        DateTime.fromISO(values.departure).toLocaleString(
                          DateTime.DATETIME_MED_WITH_WEEKDAY
                        )
                      ) : (
                        <NormalDatePicker withTime={true} name="departure" />
                      )}
                    </td>
                    <td className={classes.cell}>
                      {viewMode ? (
                        DateTime.fromISO(values.arrival).toLocaleString(
                          DateTime.DATETIME_MED_WITH_WEEKDAY
                        )
                      ) : (
                        <NormalDatePicker withTime={true} name="arrival" />
                      )}
                    </td>
                    <td className={classes.cell}>
                      {reservation.currency} (
                      {_.get(currencies, reservation.currency)})
                    </td>
                    <td className={classes.pricecell}>
                      {viewMode ? (
                        Number(values.price).toFixed(2)
                      ) : (
                        <NormalInputField name="price" type="number" />
                      )}
                    </td>
                    <td className={classes.cell}>
                      {viewMode ? (
                        <Button
                          appearance="ghost"
                          size="xs"
                          onClick={(e) => {
                            e.preventDefault();
                            setViewMode(false);
                          }}>
                          <strong>Edit</strong>
                        </Button>
                      ) : (
                        <Button color="green" size="xs" type="submit">
                          <strong>Confirm</strong>
                        </Button>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table> */}
              {/* <table className={classes.table}>
                <thead className={classes.head}>
                  <tr>
                    <th>Company Name</th>
                    <th>Ticket Class</th>
                    <th>Meal Type</th>
                    <th>Pax</th>
                    <th>Adults</th>
                    <th>Children</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className={classes.cell}>
                      {viewMode ? (
                        values.company_name || "N/A"
                      ) : (
                        <NormalInputField name="company_name" />
                      )}
                    </td>
                    <td className={classes.cell}>
                      {viewMode ? (
                        reservation.ticket_class_display || "N/A"
                      ) : (
                        <NormalInputField name="ticket_class" />
                      )}
                    </td>
                    <td className={classes.cell}>
                      {viewMode ? (
                        values.meal_type || "N/A"
                      ) : (
                        <NormalInputField name="meal_type" />
                      )}
                    </td>
                    <td className={classes.cell}>{reservation.pax}</td>
                    <td className={classes.cell}>{reservation.adults}</td>
                    <td className={classes.cell}>{reservation.children}</td>
                  </tr>
                </tbody>
              </table> */}
              <Cabins cabins={values.cabins} viewMode={viewMode} />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
BasicInfo.propTypes = {
  confirmData: PropTypes.object.isRequired,
  step: PropTypes.number.isRequired,
  reservation: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

const itineraryStyles = createUseStyles({
  ...genericInfoGroupStyles,
  ...genericButtonsStyles,
  Itinerary: genericRsrvFeebackStyles.card,
  header: {
    ...genericRsrvFeebackStyles.cardHeader,
    display: "grid",
    gridTemplateColumns: "1fr auto",
  },
  headerActions: {
    display: "grid",
    gridTemplateColumns: "1fr auto",
    gridGap: `calc(${variables.normal_gap} / 2)`,
  },
  body: genericRsrvFeebackStyles.cardBody,
  indexCouple: {
    extend: "infoCouple",
    borderBottom: `solid 1px ${variables.colors.easy.orange}`,
  },
  infoCouple: {
    display: "grid",
    gridTemplateColumns: "1fr 2fr",
    gridGap: variables.normal_gap,
  },
  infoGroup2: {
    display: "grid",
    gridTemplateRows: "1fr",
    gridGap: variables.normal_gap,
  },
  pnrCoachHead: {
    ...genericInfoGroupStyles.infoCoupleValue,
    textAlign: "right",
  },
  pnrSeatHead: {
    ...genericInfoGroupStyles.infoCoupleValue,
    textAlign: "left",
  },
  pointCell: {
    ...genericInfoGroupStyles.infoCoupleValue,
    "width": "7rem",
    "& input": {
      width: "7rem",
    },
  },
  companyCell: {
    ...genericInfoGroupStyles.infoCoupleValue,
    "width": "7rem",
    "& input": {
      width: "7rem",
    },
  },
  trainRouteCell: {
    ...genericInfoGroupStyles.infoCoupleValue,
    "width": "5rem",
    "& input": {
      width: "5rem",
    },
  },
  passengers: {
    ...genericInfoGroupStyles.infoCoupleValue,
    display: "inline-grid",
    gridGap: `calc(${variables.normal_gap} /2)`,
  },
  passenger: {
    "display": "inline-grid",
    "gridTemplateColumns": "auto repeat(2, 1fr)",
    "gridGap": `calc(${variables.normal_gap} /2)`,
    "alignItems": "center",
    "& input": {
      width: "5rem",
    },
  },
  groupCard: basicInfoStylesObj.groupCard,
  buttonsGroup: {
    ...genericButtonsStyles.buttonsGroup,
    justifyContent: "right",
  },
  [`@media ${variables.media.bigscreen}`]: {
    pointCell: {
      "width": "auto",
      "& input": {
        width: "auto",
      },
    },
    passenger: {
      "& input": {
        width: "8rem",
      },
    },
    infoGroup2: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
  },
});
// const itineraryStyles = createUseStyles({
//   Itinerary: cardStyles.card,
//   header: {
//     ...cardStyles.header,
//     display: "grid",
//     gridTemplateColumns: "1fr auto",
//   },
//   headerActions: {
//     display: "grid",
//     gridTemplateColumns: "1fr auto",
//     gridGap: `calc(${variables.normal_gap} / 2)`,
//   },
//   body: cardStyles.body,
//   table: tableStyles.table,
//   head: tableStyles.head,
//   pnrCoachHead: {
//     textAlign: "right",
//   },
//   pnrSeatHead: {
//     textAlign: "left",
//   },
//   cell: tableStyles.cell,
//   pointCell: {
//     ...tableStyles.cell,
//     "width": "7rem",
//     "& input": {
//       width: "7rem",
//     },
//   },
//   companyCell: {
//     ...tableStyles.cell,
//     "width": "7rem",
//     "& input": {
//       width: "7rem",
//     },
//   },
//   trainRouteCell: {
//     ...tableStyles.cell,
//     "width": "5rem",
//     "& input": {
//       width: "5rem",
//     },
//   },
//   passengers: {
//     display: "inline-grid",
//     gridGap: `calc(${variables.normal_gap} /2)`,
//   },
//   passenger: {
//     "display": "inline-grid",
//     "gridTemplateColumns": "auto repeat(2, 1fr)",
//     "gridGap": `calc(${variables.normal_gap} /2)`,
//     "alignItems": "center",
//     "& input": {
//       width: "5rem",
//     },
//   },
//   [`@media ${variables.media.bigscreen}`]: {
//     pointCell: {
//       ...tableStyles.cell,
//       "width": "auto",
//       "& input": {
//         width: "auto",
//       },
//     },
//     passenger: {
//       "& input": {
//         width: "8rem",
//       },
//     },
//   },
// });
const Itinerary = ({ pax, step, confirmData, onConfirm }) => {
  const classes = itineraryStyles();

  const [viewMode, setViewMode] = useState(step != 1);

  if (!pax) {
    return null;
  }

  const itineraryTmp = {
    origin_station: "",
    destination_station: "",
    departure: "",
    arrival: "",
    company: "",
    train_route_number: "",
    passenger_info: Array(pax)
      .fill(1)
      .map(() => ({ coach: "", seat: "" })),
  };

  return (
    <div className={classes.Itinerary}>
      <Formik
        initialValues={{
          itinerary:
            _.get(confirmData, "itinerary", []).length === 0
              ? [{ ...itineraryTmp }]
              : confirmData.itinerary,
        }}
        onSubmit={(values) => {
          setViewMode(true);
          onConfirm(values);
        }}>
        {({ values, setFieldValue }) => (
          <Form>
            <div className={classes.header}>
              <h5>Train Itinerary</h5>
              <div className={classes.headerActions}>
                {viewMode ? (
                  <button
                    className={classes.cancelButton}
                    onClick={(e) => {
                      e.preventDefault();
                      setViewMode(false);
                    }}>
                    <strong>Edit</strong>
                  </button>
                ) : (
                  <React.Fragment>
                    <button
                      className={classes.replyButton}
                      onClick={(e) => {
                        e.preventDefault();
                        setFieldValue("itinerary", [
                          ...values.itinerary,
                          { ...itineraryTmp },
                        ]);
                      }}>
                      <strong>Add Leg</strong>
                    </button>
                    <button className={classes.confirmButton} type="submit">
                      <strong>Confirm</strong>
                    </button>
                  </React.Fragment>
                )}
              </div>
            </div>
            <div className={classes.body}>
              {values.itinerary.map((itin, idx) => (
                <div className={classes.groupCard} key={idx}>
                  <div className={classes.indexCouple}>
                    <div className={classes.infoCoupleTitle}>#</div>
                    <div className={classes.infoCoupleValue}>{idx + 1}</div>
                  </div>

                  <div className={classes.infoGroup2}>
                    <div className={classes.infoCouple}>
                      <span className={classes.infoCoupleTitle}>
                        Origin Station
                      </span>
                      <span className={classes.pointCell}>
                        {viewMode ? (
                          values.itinerary[idx].origin_station || "N/A"
                        ) : (
                          <NormalInputField
                            name={`itinerary.${idx}.origin_station`}
                          />
                        )}
                      </span>
                    </div>
                    <div className={classes.infoCouple}>
                      <span className={classes.infoCoupleTitle}>
                        Destination Station
                      </span>
                      <span className={classes.pointCell}>
                        {viewMode ? (
                          values.itinerary[idx].destination_station || "N/A"
                        ) : (
                          <NormalInputField
                            name={`itinerary.${idx}.destination_station`}
                          />
                        )}
                      </span>
                    </div>
                    <div className={classes.infoCouple}>
                      <span className={classes.infoCoupleTitle}>Departure</span>
                      <span className={classes.pointCell}>
                        {viewMode ? (
                          values.itinerary[idx].departure
                        ) : (
                          <NormalDatePicker
                            name={`itinerary.${idx}.departure`}
                            withTime={true}
                          />
                        )}
                      </span>
                    </div>
                    <div className={classes.infoCouple}>
                      <span className={classes.infoCoupleTitle}>Arrival</span>
                      <span className={classes.pointCell}>
                        {viewMode ? (
                          values.itinerary[idx].arrival
                        ) : (
                          <NormalDatePicker
                            name={`itinerary.${idx}.arrival`}
                            withTime={true}
                          />
                        )}
                      </span>
                    </div>
                    <div className={classes.infoCouple}>
                      <span className={classes.infoCoupleTitle}>Company</span>
                      <span className={classes.companyCell}>
                        {viewMode ? (
                          values.itinerary[idx].company
                        ) : (
                          <NormalInputField name={`itinerary.${idx}.company`} />
                        )}
                      </span>
                    </div>
                    <div className={classes.infoCouple}>
                      <span className={classes.infoCoupleTitle}>
                        Train Route Number
                      </span>
                      <span className={classes.trainRouteCell}>
                        {viewMode ? (
                          values.itinerary[idx].train_route_number
                        ) : (
                          <NormalInputField
                            name={`itinerary.${idx}.train_route_number`}
                          />
                        )}
                      </span>
                    </div>
                    <div className={classes.infoCouple}>
                      <span className={classes.infoCoupleTitle}>
                        Passenger Coach/Seat
                      </span>
                      <span className={classes.infoCoupleValue}>
                        <span className={classes.passengers}>
                          {values.itinerary[idx].passenger_info.map(
                            (info, pidx) => {
                              const pnr =
                                values.itinerary[idx].passenger_info[pidx];

                              return (
                                <span key={pidx} className={classes.passenger}>
                                  <strong>{pidx + 1}.</strong>
                                  {viewMode ? (
                                    <React.Fragment>
                                      <span>{pnr.coach}</span>
                                      <span>{pnr.seat}</span>
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <NormalInputField
                                        name={`itinerary.${idx}.passenger_info.${pidx}.coach`}
                                      />
                                      <NormalInputField
                                        name={`itinerary.${idx}.passenger_info.${pidx}.seat`}
                                      />
                                    </React.Fragment>
                                  )}
                                </span>
                              );
                            }
                          )}
                        </span>
                      </span>
                    </div>
                  </div>
                  {!viewMode && (
                    <div className={classes.buttonsGroup}>
                      <button
                        className={classes.deleteButton}
                        onClick={(e) => {
                          e.preventDefault();
                          var itin = _.cloneDeep(values.itinerary);
                          itin.splice(idx, 1);
                          setFieldValue("itinerary", itin);
                        }}>
                        <strong>Remove</strong>
                      </button>
                    </div>
                  )}
                  {/* <table className={classes.table}>
                <thead className={classes.head}>
                  <tr>
                    <th>#</th>
                    <th>Origin Station</th>
                    <th>Destination Station</th>
                    <th>Departure</th>
                    <th>Arrival</th>
                    <th>Company</th>
                    <th>Train Route Number</th>
                    <th className={classes.pnrCoachHead}>Passenger Coach</th>
                    <th className={classes.pnrSeatHead}>Passenger Seat</th>
                    {!viewMode && <th>Actions</th>}
                  </tr>
                </thead>
                <tbody>
                  {values.itinerary.map((itin, idx) => (
                    <tr key={idx}>
                      <td className={classes.cell}>{idx + 1}</td>
                      <td className={classes.pointCell}>
                        {viewMode ? (
                          values.itinerary[idx].origin_station || "N/A"
                        ) : (
                          <NormalInputField
                            name={`itinerary.${idx}.origin_station`}
                          />
                        )}
                      </td>
                      <td className={classes.pointCell}>
                        {viewMode ? (
                          values.itinerary[idx].destination_station || "N/A"
                        ) : (
                          <NormalInputField
                            name={`itinerary.${idx}.destination_station`}
                          />
                        )}
                      </td>
                      <td className={classes.cell}>
                        {viewMode ? (
                          values.itinerary[idx].departure
                        ) : (
                          <NormalDatePicker
                            name={`itinerary.${idx}.departure`}
                            withTime={true}
                          />
                        )}
                      </td>
                      <td className={classes.cell}>
                        {viewMode ? (
                          values.itinerary[idx].arrival
                        ) : (
                          <NormalDatePicker
                            name={`itinerary.${idx}.arrival`}
                            withTime={true}
                          />
                        )}
                      </td>
                      <td className={classes.companyCell}>
                        {viewMode ? (
                          values.itinerary[idx].company
                        ) : (
                          <NormalInputField name={`itinerary.${idx}.company`} />
                        )}
                      </td>
                      <td className={classes.trainRouteCell}>
                        {viewMode ? (
                          values.itinerary[idx].train_route_number
                        ) : (
                          <NormalInputField
                            name={`itinerary.${idx}.train_route_number`}
                          />
                        )}
                      </td>
                      <td className={classes.cell} colSpan={2}>
                        <span className={classes.passengers}>
                          {values.itinerary[idx].passenger_info.map(
                            (info, pidx) => {
                              const pnr =
                                values.itinerary[idx].passenger_info[pidx];

                              return (
                                <span key={pidx} className={classes.passenger}>
                                  <strong>{pidx + 1}.</strong>
                                  {viewMode ? (
                                    <React.Fragment>
                                      <span>{pnr.coach}</span>
                                      <span>{pnr.seat}</span>
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <NormalInputField
                                        name={`itinerary.${idx}.passenger_info.${pidx}.coach`}
                                      />
                                      <NormalInputField
                                        name={`itinerary.${idx}.passenger_info.${pidx}.seat`}
                                      />
                                    </React.Fragment>
                                  )}
                                </span>
                              );
                            }
                          )}
                        </span>
                      </td>
                      {!viewMode && (
                        <td>
                          <Button
                            size="xs"
                            color="red"
                            onClick={(e) => {
                              e.preventDefault();
                              var itin = _.cloneDeep(values.itinerary);
                              itin.splice(idx, 1);
                              setFieldValue("itinerary", itin);
                            }}>
                            <strong>Remove</strong>
                          </Button>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table> */}
                </div>
              ))}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
Itinerary.propTypes = {
  confirmData: PropTypes.object.isRequired,
  step: PropTypes.number.isRequired,
  pax: PropTypes.number.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

function validateFeedback({
  cxl_remarks,
  booking_mode_remarks,
  reservation_remarks,
  payment_mode_remarks,
  request_cxl_policy,
  cxl_rules,
}) {
  const msgs = commonValidateFeedback({
    cxl_remarks,
    booking_mode_remarks,
    reservation_remarks,
    payment_mode_remarks,
    request_cxl_policy,
    cxl_rules,
  });

  return msgs;
}

const TrainResFeedbackStyles = createUseStyles({
  ...genericRsrvFeebackStyles,
  TrainResFeedback: rsrvFeebackStyles,
  CoordinatorReservationFeedback: rsrvFeebackStyles,
  cardBody: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridGap: "1.5rem",
    overflow: "auto",
  },
  leftCol: {
    width: "100%",
  },
  rightCol: {
    width: "100%",
    display: "grid",
    gridGap: variables.normal_gap,
  },
  [`@media ${variables.media.bigscreen}`]: {
    cardBody: {
      gridTemplateColumns: "30% 65%",
    },
  },
});
const TrainResFeedback = () => {
  const classes = TrainResFeedbackStyles();
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const feedRemarks = useFeedbackRemarks();
  const supplierInfo = useSupplierInfo();

  const [reservation, setReservation] = useState({});
  const [cxl_rules, setCxlRules] = useState([cxlRuleTemplate]);
  const [confirmData, setConfirmData] = useState({});

  const history = useHistory();

  function nextStepper() {
    var msgs = [];
    if (step === 0) {
      msgs = validateFeedback({
        cxl_remarks: feedRemarks.cxl_remarks,
        booking_mode_remarks: feedRemarks.booking_mode_remarks,
        reservation_remarks: feedRemarks.reservation_remarks,
        payment_mode_remarks: feedRemarks.payment_mode_remarks,
        request_cxl_policy: reservation.request_cxl_policy,
        cxl_rules,
      });
    } else if (step === 1) {
      msgs = validateSupplierInfo({
        supplier_name: supplierInfo.supplier_name,
        supplier_email: supplierInfo.supplier_email,
        supplier_phone: supplierInfo.supplier_phone,
      });
    }
    if (msgs.length) {
      msgs.forEach((error) => toast.error(error, { duration: 5000 }));
      return;
    }

    setStep((p) => p + 1);
  }

  const {
    params: { reference },
  } = useRouteMatch();

  useEffect(() => {
    if (reference) {
      retrieverFn({
        reference,
        fn: retrievePublicTrainReservation,
        setLoading,
        setReservation,
      });
    }
  }, [reference]);

  const actionProps = {
    step,
    prevStepper: () => setStep((p) => p - 1),
    nextStepper,
    onSubmit: () => {
      const itineraryData = confirmData.itinerary;
      delete confirmData.itinerary;

      const payload = payloader({
        supplierInfo,
        feedRemarks,
        cxl_rules,
        reference: reservation.reference,
        feedback_service_data: {
          basic_info: confirmData,
          itinerary: itineraryData,
        },
        reservation_type: "TRA",
      });
      onSubmit({
        history,
        payload,
        setLoading,
        postReservationFeedback: (payload) =>
          postReservationFeedback(payload, () =>
            toast.error(
              "Reservation feedback creation failed. We are sorry for the inconvenience.",
              { duration: 5000 }
            )
          ),
      });
    },
    onReject: () =>
      onReject({
        history,
        reference,
        payload: { status: "RJ" },
        setLoading,
        resPatchFunc: patchPublicTrainReservation,
      }),
  };

  return (
    <div className={classes.TrainResFeedback}>
      <Stepper step={step} />
      {loading ? (
        <div className={classes.cardBody}>
          <Loader size="lg" center />
        </div>
      ) : [0, 2].includes(step) ? (
        <div>
          {/* <div className={classes.card}>
           <div className={classes.cardHeader}>
             <h5>
               Reservation Confirmation Panel: {reservation.reference || "N/A"}
             </h5> */}
          <div className={classes.cardBody}>
            <div className={classes.leftCol}>
              <RequestInfo reservation={reservation} />
            </div>
            <div className={classes.rightCol}>
              <BasicInfo
                step={step}
                reservation={reservation}
                confirmData={confirmData}
                onConfirm={(data) => setConfirmData((p) => ({ ...p, ...data }))}
              />
              <Itinerary
                pax={reservation.pax}
                step={step}
                confirmData={confirmData}
                onConfirm={(data) => setConfirmData((p) => ({ ...p, ...data }))}
              />
              <CommonFeedback
                reservation={reservation}
                feedRemarks={feedRemarks}
                editable={step == 0}
                cxl_rules={cxl_rules}
                setCxlRules={setCxlRules}
              />
              <Actions {...actionProps} />
            </div>
          </div>
        </div>
      ) : (
        <ContactInformation
          {...supplierInfo}
          actionProps={actionProps}
          onChange={(values) => {
            supplierInfo.setSupplierName(values.supplier_name);
            supplierInfo.setSupplierEmail(values.supplier_email);
            supplierInfo.setSupplierPhone(values.supplier_phone);
            supplierInfo.setSupplierRemarks(values.supplier_remarks);
          }}
        />
      )}
    </div>
  );
};

export default TrainResFeedback;
