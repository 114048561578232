import _ from "lodash";
import axios from "axios";
import { SISI_API_URL } from "@src/api";

export const airportsByDestination = async ({ destination_id }) => {
  try {
    const response = await axios.get(
      `${SISI_API_URL}/flightengine/airports/by_destination/`,
      { params: { destination_id } }
    );
    return _.get(response, "data", {});
  } catch (error) {
    console.error(error);
  }
  return null;
};

export const airptsByDest = ({ destination_id }) => {
  return axios.get(`${SISI_API_URL}/flightengine/airports/by_destination/`, {
    params: { destination_id },
  });
};

export const inititiateFlightSearch = ({ payload }) => {
  return axios.post(`${SISI_API_URL}/flightengine/search/`, payload);
};

export const pollFlightsSession = ({
  session_id,
  page_size = 1000,
  ordering = "price",
  api_key,
}) => {
  return axios.get(`${SISI_API_URL}/flightengine/search/${session_id}`, {
    params: { page_size, ordering, api_key },
  });
};

export const getSessionFlights = async ({
  session_id,
  page_size = 1000,
  ordering = "price",
  api_key,
}) => {
  try {
    const response = await axios.get(
      `${SISI_API_URL}/flightengine/search/${session_id}`,
      {
        params: {
          page_size,
          ordering,
          api_key,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const fetchFlightRules = async (payload) => {
  try {
    const response = await axios.post(
      `${SISI_API_URL}/flightengine/search/fare-rules/`,
      payload
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const prebookFlight = ({ recordset, avail_strings, session_id }) => {
  return axios.post(`${SISI_API_URL}/flightengine/prebook/`, {
    recordset,
    avail_strings,
    session_id,
  });
};
