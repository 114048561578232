import {
  BIDREQUEST_INIT,
  BIDREQUEST_FETCHING_REQUEST,
  BIDREQUEST_FETCHED_REQUEST,
  BIDREQUEST_SET_REQUEST_DATA,
  // =========================== BID OFFER ===========================
  BIDREQUEST_SET_OFFER_DATA,
  // ========================== SRV PRICING ==========================
  BIDREQUEST_CHANGE_SRV_PRICING,
  BIDREQUEST_RESET_SRV_PRICING,
  BIDREQUEST_INIT_SRV_PRICING,
  BIDREQUEST_PRICING_TYPE,
  // ======================== BID RESPONSE MSG =======================
  BIDREQUEST_CHANGE_OFFER_TERMS_MSG,
  // ========================== OFFER TERMS ==========================
  BIDREQUEST_CHANGE_OFFER_TERMS,
  // ========================= SERVICE DETAILS =========================
  BIDREQUEST_VIEW_FLIGHT_DETAILS,
  BIDREQUEST_HIDE_FLIGHT_DETAILS,
  BIDREQUEST_VIEW_ACC_DETAILS,
  BIDREQUEST_HIDE_ACC_DETAILS,
  BIDREQUEST_VIEW_TRF_DETAILS,
  BIDREQUEST_HIDE_TRF_DETAILS,
  // ======================== SERVICE COMMENTS =======================
  BIDREQUEST_ADD_SERVICE_COMMENT,
  BIDREQUEST_REMOVE_SERVICE_COMMENT,
  // ====================== SERVICE BOOKING TERMS ======================
  BIDREQUEST_CHANGE_SERVICE_BOOKING_TERMS,
  // =================== SERVICE CANCELLATION POLICY ===================
  BIDREQUEST_CHANGE_SRV_BOOKING_CXL_POLICY,
  // ==================== REPLACEABLE ACCOMMODATIONS ===================
  BIDREQUEST_SHOW_REPLACEABLE_ACC_MODAL,
  BIDREQUEST_HIDE_REPLACEABLE_ACC_MODAL,
  BIDREQUEST_SAVE_REPLACEABLE_ACC,
  BIDREQUEST_REVERT_REPLACEABLE_ACC,
  // ====================== REPLACEABLE TRPS ======================
  BIDREQUEST_SHOW_REPLACEABLE_TRP_MODAL,
  BIDREQUEST_HIDE_REPLACEABLE_TRP_MODAL,
  BIDREQUEST_SAVE_REPLACEABLE_TRP,
  BIDREQUEST_REVERT_REPLACEABLE_TRP,
  // ======================== REPLACEABLE TRFS =======================
  BIDREQUEST_SHOW_REPLACEABLE_TRF_MODAL,
  BIDREQUEST_HIDE_REPLACEABLE_TRF_MODAL,
  BIDREQUEST_SAVE_REPLACEABLE_TRF,
  BIDREQUEST_REVERT_REPLACEABLE_TRF,
  // ======================== SERVICE FILTERS ========================
  BIDREQUEST_SRV_FILTERS_CHANGE,
  // ========================= SERVICE TYPE FILTERS =========================
  BIDREQUEST_SRV_RQ_TYPE_FILTERS_CHANGE,
  // =========================== SAVE ACTIONS ==========================
  BIDREQUEST_REQUEST_SAVE_OFFER,
  BIDREQUEST_CANCEL_SAVE_OFFER,
  BIDREQUEST_SAVING_OFFER,
  BIDREQUEST_SAVED_OFFER,
  BIDREQUEST_OFFER_DATA_UPDATE,
  // ================ COMMON REPLACEABLE SERVICE ACTIONS ===============
  REPLACEABLE_SERVICE_SAVING,
  REPLACEABLE_SERVICE_SAVED,
  // ========================== SEND ACTIONS =========================
  BIDREQUEST_OFFER_REQUEST_SEND,
  BIDREQUEST_CANCEL_SEND_OFFER,
  BIDREQUEST_SENDING_OFFER,
  BIDREQUEST_SENT_OFFER,
} from "@src/actions/Project/BiddingRequest/types";

import update from "immutability-helper";

export const bidRequestRequestDataStatus = (state = "idle", action) => {
  switch (action.type) {
    case BIDREQUEST_FETCHING_REQUEST:
      return "fetching";
    case BIDREQUEST_FETCHED_REQUEST:
      return "fetched";
    case BIDREQUEST_INIT:
      return "idle";
    default:
      return state;
  }
};

export const bidRequestRequestData = (state = {}, action) => {
  switch (action.type) {
    case BIDREQUEST_SET_REQUEST_DATA:
      return action.data;
    case BIDREQUEST_INIT:
      return {};
    default:
      return state;
  }
};

export const bidRequestOfferData = (state = {}, action) => {
  switch (action.type) {
    case BIDREQUEST_INIT:
      return {};
    case BIDREQUEST_SET_OFFER_DATA:
      return action.data;
    case BIDREQUEST_ADD_SERVICE_COMMENT: {
      const { destOrder, serviceType, comment } = action;
      return update(state, {
        service_comments: {
          [serviceType]: {
            [destOrder == 0 ? "extremal" : destOrder]: {
              $apply: (coms) => (coms ? [...coms, ...[comment]] : [comment]),
            },
          },
        },
      });
    }
    case BIDREQUEST_REMOVE_SERVICE_COMMENT: {
      const { destOrder, serviceType, uuid } = action;
      return update(state, {
        service_comments: {
          [serviceType]: {
            [destOrder == 0 ? "extremal" : destOrder]: {
              $apply: (coms) => coms.filter((c) => c.uuid !== uuid),
            },
          },
        },
      });
    }
    case BIDREQUEST_CHANGE_SERVICE_BOOKING_TERMS: {
      const { destOrder, serviceType, terms_text } = action;

      return update(state, {
        booking_terms: {
          $apply: (bt) => {
            if (!bt) {
              const tmp = { FL: {}, ACC: {}, TF: {} };
              return update(tmp, {
                [serviceType]: { [destOrder]: { $set: terms_text } },
              });
            } else {
              return update(bt, {
                [serviceType]: { [destOrder]: { $set: terms_text } },
              });
            }
          },
        },
      });
    }
    case BIDREQUEST_CHANGE_SRV_BOOKING_CXL_POLICY: {
      const { destOrder, serviceType, rules } = action;

      return update(state, {
        cancellation_policies: {
          $apply: (cxl) => {
            if (!cxl) {
              const tmp = { FL: {}, ACC: {}, TF: {} };
              return update(tmp, {
                [serviceType]: { [destOrder]: { $set: rules } },
              });
            } else {
              return update(cxl, {
                [serviceType]: { [destOrder]: { $set: rules } },
              });
            }
          },
        },
      });
    }
    case BIDREQUEST_SAVE_REPLACEABLE_ACC:
      return update(state, {
        replaceable_accommodations: {
          $apply: (accs) => {
            if (!accs) {
              return { [action.destOrder]: action.data };
            } else {
              return update(accs, {
                [action.destOrder]: { $set: action.data },
              });
            }
          },
        },
      });
    case BIDREQUEST_REVERT_REPLACEABLE_ACC:
      return update(state, {
        replaceable_accommodations: { [action.destOrder]: { $set: null } },
      });
    case BIDREQUEST_SAVE_REPLACEABLE_TRP: {
      const { flOrder, flData } = action;

      return update(state, {
        replaceable_flights: {
          $apply: (fls) => {
            if (!fls) {
              return { [flOrder]: flData };
            } else {
              return update(fls, { [flOrder]: { $set: flData } });
            }
          },
        },
      });
    }
    case BIDREQUEST_REVERT_REPLACEABLE_TRP:
      return update(state, {
        replaceable_flights: { [action.flOrder]: { $set: null } },
      });
    case BIDREQUEST_SAVE_REPLACEABLE_TRF: {
      const { destOrder, trfData, transfer_type } = action;

      return update(state, {
        replaceable_transfers: {
          $apply: (trfs) => {
            if (!trfs) {
              return { [destOrder]: { [transfer_type]: trfData } };
            } else {
              return update(trfs, {
                [destOrder]: { [transfer_type]: { $set: trfData } },
              });
            }
          },
        },
      });
    }
    case BIDREQUEST_REVERT_REPLACEABLE_TRF: {
      const { destOrder, transfer_type } = action;

      return update(state, {
        replaceable_transfers: {
          [destOrder]: { [transfer_type]: { $set: null } },
        },
      });
    }
    case BIDREQUEST_CHANGE_OFFER_TERMS:
      return update(state, { [action.name]: { $set: action.value } });
    case BIDREQUEST_CHANGE_OFFER_TERMS_MSG:
      return update(state, { offer_terms: { $set: action.msg } });
    case BIDREQUEST_OFFER_DATA_UPDATE:
      return action.data;
    case BIDREQUEST_CHANGE_SRV_PRICING: {
      const { serviceType, destOrder, value } = action;
      return update(state, {
        offer_pricing_data: {
          $apply: (pdata) =>
            pdata
              ? update(pdata, {
                  [serviceType]: { [destOrder]: { $set: value } },
                })
              : { [serviceType]: { [destOrder]: value } },
        },
      });
    }
    case BIDREQUEST_INIT_SRV_PRICING:
      return update(state, { offer_pricing_data: { $set: action.data } });
    default:
      return state;
  }
};

const bidRequestServiceFiltersInitial = { FL: [], ACC: [], TF: [] };
export const bidRequestServiceFilters = (
  state = bidRequestServiceFiltersInitial,
  action
) => {
  switch (action.type) {
    case BIDREQUEST_SRV_FILTERS_CHANGE: {
      const { destOrder, serviceType, actionType } = action;
      return update(state, {
        [serviceType]: {
          $apply: (fil) => {
            const newFil = [...fil];

            if (actionType == "add") {
              newFil.push(destOrder);
              return [...new Set(newFil)];
            } else {
              return [];
            }
          },
        },
      });
    }
    default:
      return state;
  }
};

const bidRequestServiceRqTypeFiltersInitial = { FL: [], ACC: [], TF: [] };
export const bidRequestServiceRqTypeFilters = (
  state = bidRequestServiceRqTypeFiltersInitial,
  action
) => {
  switch (action.type) {
    case BIDREQUEST_SRV_RQ_TYPE_FILTERS_CHANGE: {
      const { serviceType, serviceRqType, actionType } = action;

      return update(state, {
        [serviceType]: {
          $apply: (fil) => {
            const newFil = [...fil];

            if (actionType == "add") {
              newFil.push(serviceRqType);
              return [...new Set(newFil)];
            } else {
              return [];
            }
          },
        },
      });
    }
    default:
      return state;
  }
};

export const bidRequestPricingType = (state = "total", action) => {
  switch (action.type) {
    case BIDREQUEST_PRICING_TYPE:
      return action.pricingType;
    default:
      return state;
  }
};

const bidRequestFlightDetailsInitial = {
  show: false,
  flOrder: 0,
  isReplaceable: false,
};
export const bidRequestFlightDetails = (
  state = bidRequestFlightDetailsInitial,
  action
) => {
  switch (action.type) {
    case BIDREQUEST_VIEW_FLIGHT_DETAILS: {
      const { flOrder, isReplaceable } = action;
      return { show: true, flOrder, isReplaceable };
    }
    case BIDREQUEST_HIDE_FLIGHT_DETAILS:
      return bidRequestFlightDetailsInitial;
    default:
      return state;
  }
};

const bidRequestAccDetailsInitial = {
  show: false,
  destOrder: 0,
  isReplaceable: false,
};
export const bidRequestAccDetails = (
  state = bidRequestAccDetailsInitial,
  action
) => {
  switch (action.type) {
    case BIDREQUEST_VIEW_ACC_DETAILS: {
      const { destOrder, isReplaceable } = action;
      return { show: true, destOrder, isReplaceable };
    }
    case BIDREQUEST_HIDE_ACC_DETAILS:
      return bidRequestAccDetailsInitial;
    default:
      return state;
  }
};

const bidRequestTrfDetailsInitial = {
  show: false,
  destOrder: 0,
  transfer_type: "",
};
export const bidRequestTrfDetails = (
  state = bidRequestTrfDetailsInitial,
  action
) => {
  switch (action.type) {
    case BIDREQUEST_VIEW_TRF_DETAILS:
      return {
        show: true,
        destOrder: action.destOrder,
        transfer_type: action.transfer_type,
        isReplaceable: action.isReplaceable,
      };
    case BIDREQUEST_HIDE_TRF_DETAILS:
      return bidRequestTrfDetailsInitial;
    default:
      return state;
  }
};

// ======================= REPLACEABLE SERVICES ======================
const bidRequestReplaceableAccInitial = {
  show: false,
  destOrder: 0,
  loading: false,
};
export const bidRequestReplaceableAcc = (
  state = bidRequestReplaceableAccInitial,
  action
) => {
  switch (action.type) {
    case BIDREQUEST_SHOW_REPLACEABLE_ACC_MODAL:
      return update(state, {
        show: { $set: true },
        destOrder: { $set: action.destOrder },
      });
    case BIDREQUEST_HIDE_REPLACEABLE_ACC_MODAL:
      return bidRequestReplaceableAccInitial;
    case REPLACEABLE_SERVICE_SAVING:
      return update(state, { loading: { $set: true } });
    case REPLACEABLE_SERVICE_SAVED:
      return update(state, { loading: { $set: false } });
    default:
      return state;
  }
};

const bidRequestReplaceableTrpInitial = {
  show: false,
  destOrder: 0,
  trpType: "FL",
  loading: false,
};
export const bidRequestReplaceableTrp = (
  state = bidRequestReplaceableTrpInitial,
  action
) => {
  switch (action.type) {
    case BIDREQUEST_SHOW_REPLACEABLE_TRP_MODAL: {
      const { destOrder, trpType } = action;

      return update(state, {
        show: { $set: true },
        destOrder: { $set: destOrder },
        trpType: { $set: trpType },
      });
    }
    case BIDREQUEST_HIDE_REPLACEABLE_TRP_MODAL:
      return bidRequestReplaceableTrpInitial;
    case REPLACEABLE_SERVICE_SAVING:
      return update(state, { loading: { $set: true } });
    case REPLACEABLE_SERVICE_SAVED:
      return update(state, { loading: { $set: false } });
    default:
      return state;
  }
};

const bidRequestReplaceableTrfInitial = {
  show: false,
  destOrder: 0,
  transfer_type: "inbound_outbound",
  loading: false,
};
export const bidRequestReplaceableTrf = (
  state = bidRequestReplaceableTrfInitial,
  action
) => {
  switch (action.type) {
    case BIDREQUEST_SHOW_REPLACEABLE_TRF_MODAL: {
      const { destOrder, transfer_type } = action;
      return update(state, {
        show: { $set: true },
        destOrder: { $set: destOrder },
        transfer_type: { $set: transfer_type },
      });
    }
    case BIDREQUEST_HIDE_REPLACEABLE_TRF_MODAL:
      return bidRequestReplaceableTrfInitial;
    case REPLACEABLE_SERVICE_SAVING:
      return update(state, { loading: { $set: true } });
    case REPLACEABLE_SERVICE_SAVED:
      return update(state, { loading: { $set: false } });
    default:
      return state;
  }
};

// ========================== SAVE REDUCERS ==========================
const bidRequestOfferSaveModalInitial = { show: false, loading: false };
export const bidRequestOfferSaveModal = (
  state = bidRequestOfferSaveModalInitial,
  action
) => {
  switch (action.type) {
    case BIDREQUEST_REQUEST_SAVE_OFFER:
      return update(state, { show: { $set: true }, loading: { $set: false } });
    case BIDREQUEST_CANCEL_SAVE_OFFER:
      return bidRequestOfferSaveModalInitial;
    case BIDREQUEST_SAVING_OFFER:
      return update(state, { loading: { $set: true } });
    case BIDREQUEST_SAVED_OFFER:
      return bidRequestOfferSaveModalInitial;
    default:
      return state;
  }
};

// ========================== SEND REDUCERS ==========================

const bidRequestOfferSendModalInitial = { show: false, loading: false };
export const bidRequestOfferSendModal = (
  state = bidRequestOfferSendModalInitial,
  action
) => {
  switch (action.type) {
    case BIDREQUEST_OFFER_REQUEST_SEND:
      return update(state, { show: { $set: true } });
    case BIDREQUEST_CANCEL_SEND_OFFER:
      return bidRequestOfferSendModalInitial;
    case BIDREQUEST_SENDING_OFFER:
      return update(state, { loading: { $set: true } });
    case BIDREQUEST_SENT_OFFER:
      return bidRequestOfferSendModalInitial;
    default:
      return state;
  }
};
