import moment from "moment";
import { Schema } from "rsuite";
import _ from "lodash";
import { notifyCommonError } from "@src/components/common/notifications/CommonErrorNotification.js";
export const validatePayload = (payload, initialForm, explicitKeys = []) => {
  const tmp = {};
  Object.entries(initialForm)
    .filter((e) => {
      if (explicitKeys.length) {
        return explicitKeys.includes(e[0]);
      } else {
        return true;
      }
    })
    .forEach((e) => {
      tmp[e[0]] = e[1].validator;
    });
  const validator = Schema.Model(tmp);
  const validation = validator.check(payload);

  const hasError = Object.values(validation).some((v) => v.hasError);
  var errorMsgs = [];
  if (hasError) {
    errorMsgs = Object.entries(validation)
      .filter((e) => e[1].hasError)
      .map((e) => {
        return { field: e[0], msg: e[1].errorMessage };
      });
  }

  return errorMsgs;
};

export const submitProcessor = (
  state,
  formKey,
  initialForm,
  explicitKeys = []
) => {
  const formData = state[formKey];
  const payload = {};
  Object.entries(formData)
    .filter((e) => {
      if (explicitKeys.length) {
        return explicitKeys.includes(e[0]);
      } else {
        return true;
      }
    })
    .forEach((e) => (payload[e[0]] = e[1].value));

  const errorMsgs = validatePayload(payload, initialForm, explicitKeys);

  return {
    payload: payload,
    hasError: errorMsgs.length,
    errorMsgs: errorMsgs,
  };
};

export const submitChangeProcessor = ({ email }) => {
  const { StringType } = Schema.Types;
  const userModel = Schema.Model({
    email: StringType()
      .isEmail("请输入正确的邮箱")
      .isRequired("用户名不能为空"),
  });

  const checkResult = userModel.check({
    email: email,
  });
  return checkResult;
};

export const timeSkipper = (fn, dataObj, duration) => {
  var timestamp = moment(
    _.get(dataObj, "metadata.timestamp", moment().toISOString())
  );

  const diff = moment.duration(moment().diff(timestamp)).seconds();
  if (diff > 0 && diff < duration) {
    const nullFn = () => {
      return null;
    };
    return nullFn;
  }

  return fn;
};

export const entityError400Handle = (data, errorData, dataForm) => {
  const error400Handle = () => {
    for (const key in errorData) {
      if (key == "address") {
        errorData[key].forEach((address, index) => {
          if (_.isEmpty(address)) {
            return;
          }
          for (const key in address) {
            const originAddressType = _.get(
              data,
              `address.${index}.address_type`
            );
            address[key].forEach((item) => {
              if (originAddressType == "BU") {
                const label = _.get(dataForm, `postal_${key}.verbose`);
                notifyCommonError(
                  `${_.upperFirst(label || key)}:${item.message}`
                );
              } else {
                const label = _.get(dataForm, `${key}.verbose`);
                notifyCommonError(
                  `${_.upperFirst(label || key)}:${item.message}`
                );
              }
            });
          }
        });
      } else {
        errorData[key].forEach((item) => {
          const label = _.get(dataForm, `${key}.verbose`);
          notifyCommonError(`${_.upperFirst(label || key)}:${item.message}`);
        });
      }
    }
  };
  return error400Handle;
};
