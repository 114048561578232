export const BEGIN_CHANGE_ENTITY_STATUS = "BEGIN_CHANGE_ENTITY_STATUS";
export const END_CHANGE_ENTITY_STATUS = "END_CHANGE_ENTITY_STATUS";
export const UPDATE_ENTITY_DETAILS = "UPDATE_ENTITY_DETAILS";

// ========================== Filter Actions =========================
export const SHOW_GLOBAL_FILTER_MODAL = "SHOW_GLOBAL_FILTER_MODAL";
export const HIDE_GLOBAL_FILTER_MODAL = "HIDE_GLOBAL_FILTER_MODAL";

// ====================== Travel Service Actions =====================
export const FETCHED_TRAVEL_SERVICES = "FETCHED_TRAVEL_SERVICES";
export const FORMAT_TRAVEL_SERVICES_OPTIONS = "FORMAT_TRAVEL_SERVICES_OPTIONS";
export const FORMAT_TRAVEL_SERVICES_FORM = "FORMAT_TRAVEL_SERVICES_FORM";
export const FETCHED_TRAVEL_SERVICE_OPTIONS = "FETCHED_TRAVEL_SERVICE_OPTIONS";

// ======================= New Entity Actions ======================
export const CLEAN_NEW_ENTITY_FORM_ERRORS = "CLEAN_NEW_ENTITY_FORM_ERRORS";
export const VALIDATE_NEW_ENTITY_FORM = "VALIDATE_NEW_ENTITY_FORM";

// ======================= Guest Actions ======================
export const FETCHED_GUEST_OPTIONS = "FETCHED_GUEST_OPTIONS";

// ======================= Reservation Email Actions =============================
export const SET_RESERVATION_EMAIL_LIST_DATA =
  "SET_RESERVATION_EMAIL_LIST_DATA";

//======================== Countries Info =================================
export const COUNTRIES_FORMAT_OPTIONS = "COUNTRIES_FORMAT_OPTIONS";

//======================== New User =================================
export const BEGIN_FETCH_ENTITY = "BEGIN_FETCH_ENTITY";
export const END_FETCH_ENTITY = "END_FETCH_ENTITY";
export const BEGIN_FETCH_SUPPLY_PARTNER = "BEGIN_FETCH_SUPPLY_PARTNER";
export const END_FETCH_SUPPLY_PARTNER = "END_FETCH_SUPPLY_PARTNER";

// ========================= Chore  =========================
export const SHOW_GLOBAL_CHART_ROOMS = "SHOW_GLOBAL_CHART_ROOMS";

export const HIDE_GLOBAL_CHART_ROOMS = "HIDE_GLOBAL_CHART_ROOMS";

//========================COUNTRY CODE INFO=======================
export const SAVE_COUNTRY_CODE_INFO = "SAVE_COUNTRY_CODE_INFO";
export const FORMAT_COUNTRY_CODE_LIST = "FORMAT_COUNTRY_CODE_LIST";
export const SHARES_FETCHED_COUNTRY_LIST = "SHARES_FETCHED_COUNTRY_LIST";

//==============================dashboard========================
export const FETCH_USER_GRAPH_DATA = "FETCH_USER_GRAPH_DATA";
export const FETCH_COMPANY_GRAPH_DATA = "FETCH_COMPANY_GRAPH_DATA";
export const SHOW_GRAPH_BOX_MAP_VIEW_MODAL = "SHOW_GRAPH_BOX_MAP_VIEW_MODAL";
export const HIDE_GRAPH_BOX_MAP_VIEW_MODAL = "HIDE_GRAPH_BOX_MAP_VIEW_MODAL";
export const FETCH_GRAPH_MAP_VIEW_DATA = "FETCH_GRAPH_MAP_VIEW_DATA";

//================================replaceable modal=====================================
export const HIDE_REPLACEABLE_MODAL = "HIDE_REPLACEABLE_MODAL";
export const SHOW_REPLACEABLE_MODAL = "SHOW_REPLACEABLE_MODAL";

//===================================Finincial ===========================================
export const FINANCIAL_CURRENCIES_LIST = "FINANCIAL_CURRENCIES_LIST";

//=================================GET RELATED EMPLOYEES========================
export const GET_RELATED_EMPLOYEES_DATA = "GET_RELATED_EMPLOYEES_DATA";

//======================================GRAPH==========================
export const GRAPH_DASHBOARDS_TOGGLE_MODE = "GRAPH_DASHBOARDS_TOGGLE_MODE";
export const GRAPH_DASHBOARDS_INIT_MODE = "GRAPH_DASHBOARDS_INIT_MODE";

export const DOWNLOAD_PDF_MODAL_SHOW = "DOWNLOAD_PDF_MODAL_SHOW";
export const DOWNLOAD_PDF_MODAL_HIDE = "DOWNLOAD_PDF_MODAL_HIDE";
export const DOWNLOAD_PDF_MODAL_UPDATE = "DOWNLOAD_PDF_MODAL_UPDATE";
export const DOWNLOAD_EXCEL_MODAL_SHOW = "DOWNLOAD_EXCEL_MODAL_SHOW";
export const DOWNLOAD_EXCEL_MODAL_HIDE = "DOWNLOAD_EXCEL_MODAL_HIDE";
