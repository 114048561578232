// // import enCommon from "./common/en";
// import { ___commonDictionary } from "@src/i18n/common/dictionary";
// import { ___commonListHeaderDictionary } from "@src/components/common/dictionary";
// import {
//   ___downLoadPDFBtnDictionary,
//   ___exportExcelBtnDictionary,
// } from "@src/components/common/buttons/dictionary";
// import {
//   ___controlStripDictionary,
//   ___listPageHeaderDictionary,
//   ___orderingDictionary,
// } from "@src/components/common/lists/dictionary";

// // Project
// import { ___myTripListDictionary } from "@src/pages/Project/TripPlan/Archive/TripTemplates/dictionary";
// import {
//   ___projectPckgsCmtsDictionary,
//   ___projectPckgsCmtsCarouselDictionary,
// } from "@src/pages/Project/Packages/components/dictionary";
// // Admin
// import {
//   ___companyProfileDictionary,
//   ___companyProfileServCategDict,
//   ___companyProfileServCategModalDict,
//   ___companyProfileNatOfBusinessDict,
//   ___companyProfileNatOfBusinessModalDict,
//   ___companyProfileBasicDataModalDict,
//   ___companyProfileBasicDataDict,
// } from "@src/pages/Admin/Company/Profile/NewCompanyProfile/dictionary";
// import {
//   ___usersListsDictionary,
//   ___usersAddModalDictionary,
// } from "@src/pages/Admin/Company/Users/list/dictionary";
// import {
//   ___usersDetailsDictionary,
//   ___usersDetChangePassDict,
//   ___usersDetJobDescrDict,
//   ___usersDetJobDescrModalDict,
//   ___usersDetPermissionsDict,
//   ___usersDetPermissionsModalDict,
//   ___usersDetProfileDict,
//   ___usersDetProfileModalDict,
//   ___usersDetServCategDict,
//   ___usersDetServCategModalDict,
//   ___usersDetWorkDutiesDict,
//   ___usersDetWorkDutiesModalDict,
//   ___usersUploadImgModalDict,
// } from "@src/pages/Admin/Company/Users/details/dictionary";
// import {
//   ___communicationsListDictionary,
//   ___communicationsModalDictionary,
// } from "@src/pages/Admin/ControlPanel/Communications/list/dictionary";
// import {
//   ___PTAC_ListDictionary,
//   ___PTAC_ModalDictionary,
//   ___PTAC_CloneModalDictionary,
// } from "@src/pages/Admin/ControlPanel/ProductTermsAndConditions/dictionary";
// import {
//   ___bookingRemarksListDictionary,
//   ___bookingRemarksModalDictionary,
// } from "@src/pages/Admin/ControlPanel/BookingRemarks/dictionary";
// import {
//   ___B2BPoliciesModalDictionary,
//   ___B2BPoliciesModal_BI_Dictionary,
//   ___B2BPoliciesModal_NAS_Dictionary,
//   ___B2BPoliciesModal_TS_Dictionary,
//   ___B2BPoliciesModal_helpers_Dictionary,
//   ___B2BPoliciesModal_TE_Dictionary,
// } from "@src/pages/Admin/MarketPolicy/common/list/AddEditPolicyModal/dictionary";
// import { ___B2BPolicies_ListDictionary } from "@src/pages/Admin/MarketPolicy/common/list/dictionary";

// // Operation
// import { ___reservationsEditReservationModalDict } from "@src/pages/Operation/common/EditReservationModal/dictionary";
// import {
//   ___multiservicesDetail,
//   ___multiservicesDetailPrimary,
//   ___multiservicesDetailSrvTable,
// } from "@src/pages/Operation/B2BReservations/MultiServices/details/dictionary";
// import { ___travelDocModalDict } from "@src/pages/Operation/common/TravelDocModal/dictionary";
// import {
//   ___operationGuestsDict,
//   ___operationGuestsTableDict,
//   ___operationGuestsModalDict,
// } from "@src/pages/Operation/common/GuestsSection/dictionary";
// import { ___operationsCommonDictionary } from "@src/pages/Operation/B2BReservations/SingleServices/Common/dictionary";
// import {
//   ___operationResSrvSpecifications,
//   ___operationResSrvCxlModal,
// } from "@src/pages/Operation/Reservations/Accommodations/dictionary";
// import {
//   ___operationCommunicationDict,
//   ___operationCommunicationTableDict,
// } from "@src/pages/Operation/common/CommunicationSection/dictionary";
// import { ___operationsRsrvtEvents } from "@src/pages/Operation/components/dictionary";
// import { ___operationShared } from "@src/pages/Operation/Reservations/Shared/dictionary";
// import { ___geopolicyB2BListsDictionary } from "@src/pages/Admin/MarketPolicy/B2C/list/dictionary";
// import { ___multiservicesListsDictionary } from "@src/pages/Operation/B2BReservations/MultiServices/list/dictionary";
// import { ___accListsDictionary } from "@src/pages/Operation/B2BReservations/SingleServices/Accommodation/list/dictionary";
// import {
//   ___B2CAccDetailsDictionary,
//   ___B2CAccDetailsResPrimaryDict,
// } from "@src/pages/Operation/B2CReservations/SingleServices/Accommodation/details/dictionary";
// import {
//   ___accDetail,
//   ___accDetail_primary,
// } from "@src/pages/Operation/B2BReservations/SingleServices/Accommodation/detail/dictionary";
// import { ___actListsDictionary } from "@src/pages/Operation/B2BReservations/SingleServices/Activities/list/dictionary";
// import {
//   ___actDetail,
//   ___actDetailPrimary,
//   ___actDetailSrvSpecification,
// } from "@src/pages/Operation/B2BReservations/SingleServices/Activities/details/dictionary";
// import { ___miListsDictionary } from "@src/pages/Operation/B2BReservations/SingleServices/Addons/list/dictionary";
// import {
//   ___miDetail,
//   ___miDetailEvents,
//   ___miDetailPrimary,
//   ___miDetailPrEditStatus,
//   ___miDetailPrReqOptConfModal,
//   ___miDetailPrReqOptConfPayModal,
// } from "@src/pages/Operation/B2BReservations/SingleServices/Addons/details/dictionary";
// import { ___adhocAccListsDictionary } from "@src/pages/Operation/B2BReservations/SingleServices/AdhocAccommodations/list/dictionary";
// import {
//   ___adhocDetails,
//   ___adhocDetailsEventFBM,
//   ___adhocDetailsEvent,
//   ___adhocDetailsPrim,
//   ___adhocDetailsServSpec,
// } from "@src/pages/Operation/B2BReservations/SingleServices/AdhocAccommodations/detail/dictionary";
// import { ___coaListsDictionary } from "@src/pages/Operation/B2BReservations/SingleServices/Coach/list/dictionary";
// import {
//   ___coaDetailsDictionary,
//   ___coaDetailsPrimaryDictionary,
//   ___coaDetailsSrvSpecificationDictionary,
// } from "@src/pages/Operation/B2BReservations/SingleServices/Coach/detail/dictionary";
// import { ___contractedAccListsDictionary } from "@src/pages/Operation/B2BReservations/SingleServices/ContractedAccommodations/list/dictionary";
// import {
//   ___contractedAccDictionary,
//   ___contractedAccEventsFeedbackDictionary,
//   ___contractedAccEventsDictionary,
//   ___contractedAccPrimaryDictionary,
//   ___contractedAccSrvSpecificationsDictionary,
// } from "@src/pages/Operation/B2BReservations/SingleServices/ContractedAccommodations/detail/dictionary";
// import { ___cooListsDictionary } from "@src/pages/Operation/B2BReservations/SingleServices/Coordinators/list/dictionary";
// import {
//   ___CooDetailsDictionary,
//   ___CooDetailsEventsDictionary,
//   ___CooDetailsPrimaryDictionary,
//   ___CooDetailsSrvSpecificationsDictionary,
// } from "@src/pages/Operation/B2BReservations/SingleServices/Coordinators/detail/dictionary";
// import { ___ferListsDictionary } from "@src/pages/Operation/B2BReservations/SingleServices/Ferries/list/dictionary";
// import { ___apiFerListsDictionary } from "@src/pages/Operation/B2BReservations/SingleServices/APIFerries/list/dictionary";
// import {
//   ___ferDetailsDictionary,
//   ___ferDetailsEventsFMDictionary,
//   ___ferDetailsPrimaryDictionary,
//   ___ferDetailsSrvSpecificationsDictionary,
// } from "@src/pages/Operation/B2BReservations/SingleServices/Ferries/detail/dictionary";
// import {
//   ___APIFerDetailsDictionary,
//   ___APIFerDetailsEventsFMDictionary,
//   ___APIFerDetailsPrimaryDictionary,
//   ___APIFerDetailsSrvSpecificationsDictionary,
// } from "@src/pages/Operation/B2BReservations/SingleServices/APIFerries/detail/dictionary";
// import { ___flightListsdictionary } from "@src/pages/Operation/B2BReservations/SingleServices/Flights/list/dictionary";
// import {
//   ___flDetailsDictionary,
//   ___flDetailsResPrimaryDict,
//   ___flDetailsServSpecDict,
//   ___flDetailsServSpecLBDict,
// } from "@src/pages/Operation/B2CReservations/SingleServices/Flights/details/dictionary";
// import { ___genListsDictionary } from "@src/pages/Operation/B2BReservations/SingleServices/GeneralServices/list/dictionary";
// import {
//   ___GSDetailsDictionary,
//   ___GSDetailsEventsModalDict,
//   ___GSDetailsResPrimaryDict,
//   ___GSDetailsResServSpecDict,
// } from "@src/pages/Operation/B2BReservations/SingleServices/GeneralServices/detail/dictionary";
// import { ___resListsDictionary } from "@src/pages/Operation/B2BReservations/SingleServices/Restaurants/list/dictionary";
// import {
//   ___ResDetailsDictionary,
//   ___ResDetailsEventsModalDictionary,
//   ___ResDetailsResPrimaryDictionary,
//   ___ResDetailsResServSpecDictionary,
// } from "@src/pages/Operation/B2BReservations/SingleServices/Restaurants/detail/dictionary";
// import { ___traListsDictionary } from "@src/pages/Operation/B2BReservations/SingleServices/Trains/list/dictionary";
// import {
//   ___TRDetailsDictionary,
//   ___TRDetailsEventsModalDict,
//   ___TRDetailsResPrimaryDict,
//   ___TRDetailsResServSpecDict,
// } from "@src/pages/Operation/B2BReservations/SingleServices/Trains/detail/dictionary";
// import { ___trfListsDictionary } from "@src/pages/Operation/B2BReservations/SingleServices/Transfers/list/dictionary";
// import {
//   ___trfDetailsDictionary,
//   ___trfDetailsPrimaryDictionary,
//   ___trfDetailsPrimarySSDictionary,
// } from "@src/pages/Operation/B2BReservations/SingleServices/Transfers/details/dictionary";
// import {
//   ___dashHdnBtnDictionary,
//   ___flightReservationStatusDictionary,
//   ___reservationBookingModeDictionary,
//   ___reservationStatusDictionary,
//   ___translatedTableHeadDictionary,
//   ___commonModeTagsDictionary,
// } from "@src/pages/Operation/common/dictionary";
// import {
//   ___GuestsB2cListsDictionary,
//   ___GuestsAddModalDictionary,
// } from "@src/pages/Operation/Guests/B2C/list/dictionary";
// import {
//   ___commonReceivableAddModal,
//   ___commonReceivableList,
//   ___commonReceivableVoidModal,
// } from "@src/pages/Operation/components/ReservationReceivable/dictionary";

// // Newtwork
// import {
//   ___network_components_Dictionary,
//   ___WebCMSStatusTagDictionary,
//   ___ConfirmationModalDictionary,
//   ___ImageModalDictionary,
// } from "@src/pages/Network/B2CDistributionChannels/detail/components/dictionary";
// import { ___branchesList } from "@src/pages/Network/Sales/Branches/list/dictionary";
// import { ___subAgentsList } from "@src/pages/Network/Sales/SubAgents/list/dictionary";
// import { ___independentAgentsList } from "@src/pages/Network/Sales/IndependentAgents/list/dictionary";
// import {
//   ___providersListDictionary,
//   ___providersModalDictionary,
// } from "@src/pages/Network/Supply/Suppliers/dictionary";
// import { ___providerProfileDictionary } from "@src/pages/Network/Supply/Suppliers/NewProfile/dictionary";

// import {
//   ___networkVendorsFlightslist,
//   ___networkVendorsFlightsModal,
//   ___networkVendorsFlightsVLTBModal,
// } from "@src/pages/Network/Vendors/Transportation/Flights/list/dictionary";
// import {
//   ___networkVendorsAcclist,
//   ___networkVendorsAccModal,
// } from "@src/pages/Network/Vendors/Accommodation/list/dictionary";
// import {
//   ___networkVendorsCommonHooks,
//   ___networkVendorsCommonHelpers,
// } from "@src/pages/Network/Vendors/common/dictionary";
// import {
//   ___networkVendorsTFlist,
//   ___networkVendorsTFModal,
// } from "@src/pages/Network/Vendors/Transfer/list/dictionary";
// import { ___networkB2CDistributionlist } from "@src/pages/Network/B2CDistributionChannels/list/dictionary";
// import { ___networkB2CDistributionDetails } from "@src/pages/Network/B2CDistributionChannels/detail/dictionary";
// import {
//   ___networkB2CDistributionDetailsAboutUs,
//   ___networkB2CDistributionDetailsAboutUsList,
//   ___networkB2CDistributionDetailsAboutUsModal,
// } from "@src/pages/Network/B2CDistributionChannels/detail/AboutUs/dictionary";
// import {
//   ___networkB2CDistDetContactInfoModal,
//   ___networkB2CDistDetContactInfoList,
//   ___networkB2CDistDetContactInfo,
// } from "@src/pages/Network/B2CDistributionChannels/detail/ContactInfo/dictionary";
// import {
//   ___networkB2CDistDetFAQ,
//   ___networkB2CDistDetFAQModal,
// } from "@src/pages/Network/B2CDistributionChannels/detail/FAQ/dictionary";
// import { ___networkB2CDistDetGenSec } from "@src/pages/Network/B2CDistributionChannels/detail/GenericSections/dictionary";
// import {
//   ___networkB2CDistDetHotelPrior,
//   ___networkB2CDistDetHotelPriorImpModal,
//   ___networkB2CDistDetHotelPriorConfModal,
// } from "@src/pages/Network/B2CDistributionChannels/detail/HotelPriorities/dictionary";
// import {
//   ___networkB2CDistDetLandPages,
//   ___networkB2CDistDetLandPagModal,
// } from "@src/pages/Network/B2CDistributionChannels/detail/LandingPages/dictionary";
// import {
//   ___networkB2CDistDetListPages,
//   ___networkB2CDistDetListPagEditModal,
//   ___networkB2CDistDetListPagList,
// } from "@src/pages/Network/B2CDistributionChannels/detail/ListPages/dictionary";
// import {
//   ___networkB2CDistDetMainPromos,
//   ___networkB2CDistDetMainPromosEM,
//   ___networkB2CDistDetMainPromosList,
// } from "@src/pages/Network/B2CDistributionChannels/detail/MainPromos/dictionary";
// import {
//   ___networkB2CDistDetPromoLandMod,
//   ___networkB2CDistDetPromoLand,
// } from "@src/pages/Network/B2CDistributionChannels/detail/PromoLandingPage/dictionary";
// import {
//   ___networkB2CDistDetRest,
//   ___networkB2CDistDetRestMod,
//   ___networkB2CDistDetRestList,
// } from "@src/pages/Network/B2CDistributionChannels/detail/RestaurantPromos/dictionary";

// // My Library
// import {
//   ___myLibraryAddonsList,
//   ___myLibraryAddonTitle,
// } from "@src/pages/MyLibrary/Addons/dictionary";

// import {
//   ___myLibraryHotelsList,
//   ___myLibraryHotelsAddModal,
//   ___myLibraryHotelsEditModal,
//   ___myLibraryHotelsdetail,
// } from "@src/pages/MyLibrary/Hotels/dictionary";
// import {
//   ___myLibraryHotelsCmpsARM,
//   ___myLibraryHotelsCmpsBI,
//   ___myLibraryHotelsCmpsCalendars,
//   ___myLibraryHotelsCmpsERM,
//   ___myLibraryHotelsCmps,
//   ___myLibraryHotelsCmpsRPC,
//   ___myLibraryHotelsCmpsrooms,
// } from "@src/pages/MyLibrary/Hotels/components/dictionary";
// import {
//   ___myLibraryHotelsCmpsCalACa,
//   ___myLibraryHotelsCmpsCalACo,
//   ___myLibraryHotelsCmpsCalCntrls,
//   ___myLibraryHotelsCmpsCalRAF,
//   ___myLibraryHotelsCmpsCalRRF,
//   ___myLibraryHotelsCmpsCalSSC,
//   ___myLibraryHotelsCmpsCalSSCs,
//   ___myLibraryHotelsCmpsCalSSCntrls,
// } from "@src/pages/MyLibrary/Hotels/components/calendars/dictionary";
// import {
//   ___myLibraryResList,
//   ___myLibraryResAddRes,
//   ___myLibraryResAddMM,
//   ___myLibraryResdetail,
//   ___myLibraryResEditBIModal,
//   ___myLibraryResRemMenuModal,
//   ___myLibraryResPriceTool,
//   ___myLibraryResMenus,
//   ___myLibraryResLocation,
//   ___myLibraryResEditMenuModal,
// } from "@src/pages/MyLibrary/Restaurants/dictionary";
// import {
//   ___myLibraryCooList,
//   ___myLibraryCooAddModal,
// } from "@src/pages/MyLibrary/Coordinators/dictionary";
// import {
//   ___myLibraryGenList,
//   ___myLibraryGenServiceModal,
// } from "@src/pages/MyLibrary/GeneralServices/dictionary";
// import {
//   ___myLibraryImgList,
//   ___myLibraryImgPanel,
//   ___myLibraryImgModal,
// } from "@src/pages/MyLibrary/ImageBank/dictionary";

// // User Profile
// import {
//   ___userProfileDictionary,
//   ___userProfileChangePassDict,
//   ___userProfileJobDescrDict,
//   ___userProfileJobDescrModalDict,
//   ___userProfilePermissionsDict,
//   ___userProfilePermissionsModalDict,
//   ___userProfileProfileDict,
//   ___userProfileProfileModalDict,
//   ___userProfileServCategDict,
//   ___userProfileServCategModalDict,
//   ___userProfileWorkDutiesDict,
//   ___userProfileWorkDutiesModalDict,
//   ___userProfileUploadImgModalDict,
// } from "@src/pages/UserProfile/New/dictionary";

// const langEnDictionary = {
//   // Common
//   ...___commonDictionary.en,
//   ...___commonListHeaderDictionary.en,
//   // ...enCommon,
//   // Project
//   ...___myTripListDictionary.en,
//   ...___projectPckgsCmtsDictionary.en,
//   ...___projectPckgsCmtsCarouselDictionary.en,
//   // Operations
//   ...___reservationsEditReservationModalDict.en,
//   ...___travelDocModalDict.en,
//   ...___operationResSrvSpecifications.en,
//   ...___operationsCommonDictionary.en,
//   ...___operationsRsrvtEvents.en,
//   ...___operationResSrvCxlModal.en,
//   ...___flightListsdictionary.en,
//   ...___flDetailsDictionary.en,
//   ...___flDetailsResPrimaryDict.en,
//   ...___flDetailsServSpecDict.en,
//   ...___flDetailsServSpecLBDict.en,
//   ...___controlStripDictionary.en,
//   ...___operationGuestsDict.en,
//   ...___operationGuestsTableDict.en,
//   ...___operationGuestsModalDict.en,
//   ...___operationCommunicationDict.en,
//   ...___operationCommunicationTableDict.en,
//   ...___operationShared.en,
//   ...___orderingDictionary.en,
//   ...___dashHdnBtnDictionary.en,
//   ...___downLoadPDFBtnDictionary.en,
//   ...___exportExcelBtnDictionary.en,
//   ...___translatedTableHeadDictionary.en,
//   ...___commonModeTagsDictionary.en,
//   ...___reservationStatusDictionary.en,
//   ...___flightReservationStatusDictionary.en,
//   ...___reservationBookingModeDictionary.en,
//   ...___listPageHeaderDictionary.en,
//   ...___accListsDictionary.en,
//   ...___B2CAccDetailsDictionary.en,
//   ...___B2CAccDetailsResPrimaryDict.en,
//   ...___accDetail.en,
//   ...___accDetail_primary.en,
//   ...___actListsDictionary.en,
//   ...___actDetail.en,
//   ...___actDetailPrimary.en,
//   ...___actDetailSrvSpecification.en,
//   ...___miListsDictionary.en,
//   ...___miDetail.en,
//   ...___miDetailEvents.en,
//   ...___miDetailPrimary.en,
//   ...___miDetailPrEditStatus.en,
//   ...___miDetailPrReqOptConfModal.en,
//   ...___miDetailPrReqOptConfPayModal.en,
//   ...___adhocAccListsDictionary.en,
//   ...___adhocDetails.en,
//   ...___adhocDetailsEventFBM.en,
//   ...___adhocDetailsEvent.en,
//   ...___adhocDetailsPrim.en,
//   ...___adhocDetailsServSpec.en,
//   ...___coaListsDictionary.en,
//   ...___coaDetailsDictionary.en,
//   ...___coaDetailsPrimaryDictionary.en,
//   ...___coaDetailsSrvSpecificationDictionary.en,
//   ...___contractedAccListsDictionary.en,
//   ...___contractedAccDictionary.en,
//   ...___contractedAccEventsFeedbackDictionary.en,
//   ...___contractedAccEventsDictionary.en,
//   ...___contractedAccPrimaryDictionary.en,
//   ...___contractedAccSrvSpecificationsDictionary.en,
//   ...___cooListsDictionary.en,
//   ...___CooDetailsDictionary.en,
//   ...___CooDetailsEventsDictionary.en,
//   ...___CooDetailsPrimaryDictionary.en,
//   ...___CooDetailsSrvSpecificationsDictionary.en,
//   ...___ferListsDictionary.en,
//   ...___apiFerListsDictionary.en,
//   ...___ferDetailsDictionary.en,
//   ...___ferDetailsEventsFMDictionary.en,
//   ...___ferDetailsPrimaryDictionary.en,
//   ...___ferDetailsSrvSpecificationsDictionary.en,
//   ...___APIFerDetailsDictionary.en,
//   ...___APIFerDetailsEventsFMDictionary.en,
//   ...___APIFerDetailsPrimaryDictionary.en,
//   ...___APIFerDetailsSrvSpecificationsDictionary.en,
//   ...___genListsDictionary.en,
//   ...___GSDetailsDictionary.en,
//   ...___GSDetailsEventsModalDict.en,
//   ...___GSDetailsResPrimaryDict.en,
//   ...___GSDetailsResServSpecDict.en,
//   ...___resListsDictionary.en,
//   ...___ResDetailsDictionary.en,
//   ...___ResDetailsEventsModalDictionary.en,
//   ...___ResDetailsResPrimaryDictionary.en,
//   ...___ResDetailsResServSpecDictionary.en,
//   ...___traListsDictionary.en,
//   ...___TRDetailsDictionary.en,
//   ...___TRDetailsEventsModalDict.en,
//   ...___TRDetailsResPrimaryDict.en,
//   ...___TRDetailsResServSpecDict.en,
//   ...___trfListsDictionary.en,
//   ...___trfDetailsDictionary.en,
//   ...___trfDetailsPrimaryDictionary.en,
//   ...___trfDetailsPrimarySSDictionary.en,
//   ...___multiservicesListsDictionary.en,
//   ...___multiservicesDetail.en,
//   ...___multiservicesDetailPrimary.en,
//   ...___multiservicesDetailSrvTable.en,
//   ...___GuestsB2cListsDictionary.en,
//   ...___GuestsAddModalDictionary.en,
//   ...___commonReceivableAddModal.en,
//   ...___commonReceivableList.en,
//   ...___commonReceivableVoidModal.en,
//   // Admin
//   ...___companyProfileDictionary.en,
//   ...___companyProfileServCategDict.en,
//   ...___companyProfileServCategModalDict.en,
//   ...___companyProfileNatOfBusinessDict.en,
//   ...___companyProfileNatOfBusinessModalDict.en,
//   ...___companyProfileBasicDataModalDict.en,
//   ...___companyProfileBasicDataDict.en,
//   ...___usersListsDictionary.en,
//   ...___usersAddModalDictionary.en,
//   ...___usersDetailsDictionary.en,
//   ...___usersDetChangePassDict.en,
//   ...___usersDetJobDescrDict.en,
//   ...___usersDetJobDescrModalDict.en,
//   ...___usersDetPermissionsDict.en,
//   ...___usersDetPermissionsModalDict.en,
//   ...___usersDetProfileDict.en,
//   ...___usersDetProfileModalDict.en,
//   ...___usersDetServCategDict.en,
//   ...___usersDetServCategModalDict.en,
//   ...___usersDetWorkDutiesDict.en,
//   ...___usersDetWorkDutiesModalDict.en,
//   ...___usersUploadImgModalDict.en,
//   ...___geopolicyB2BListsDictionary.en,
//   ...___communicationsListDictionary.en,
//   ...___communicationsModalDictionary.en,
//   ...___PTAC_ListDictionary.en,
//   ...___PTAC_ModalDictionary.en,
//   ...___PTAC_CloneModalDictionary.en,
//   ...___bookingRemarksListDictionary.en,
//   ...___bookingRemarksModalDictionary.en,
//   ...___B2BPolicies_ListDictionary.en,
//   ...___B2BPoliciesModal_NAS_Dictionary.en,
//   ...___B2BPoliciesModal_BI_Dictionary.en,
//   ...___B2BPoliciesModal_TS_Dictionary.en,
//   ...___B2BPoliciesModal_helpers_Dictionary.en,
//   ...___B2BPoliciesModal_TE_Dictionary.en,
//   // Network
//   ...___branchesList.en,
//   ...___subAgentsList.en,
//   ...___independentAgentsList.en,
//   ...___network_components_Dictionary.en,
//   ...___WebCMSStatusTagDictionary.en,
//   ...___ConfirmationModalDictionary.en,
//   ...___ImageModalDictionary.en,
//   ...___B2BPoliciesModalDictionary.en,
//   ...___providersListDictionary.en,
//   ...___providersModalDictionary.en,
//   ...___providerProfileDictionary.en,
//   ...___networkVendorsFlightslist.en,
//   ...___networkVendorsFlightsModal.en,
//   ...___networkVendorsFlightsVLTBModal.en,
//   ...___networkVendorsAcclist.en,
//   ...___networkVendorsCommonHooks.en,
//   ...___networkVendorsCommonHelpers.en,
//   ...___networkVendorsAccModal.en,
//   ...___networkVendorsTFlist.en,
//   ...___networkVendorsTFModal.en,
//   ...___networkB2CDistributionlist.en,
//   ...___networkB2CDistributionDetails.en,
//   ...___networkB2CDistributionDetailsAboutUs.en,
//   ...___networkB2CDistributionDetailsAboutUsList.en,
//   ...___networkB2CDistributionDetailsAboutUsModal.en,
//   ...___networkB2CDistDetContactInfoModal.en,
//   ...___networkB2CDistDetContactInfoList.en,
//   ...___networkB2CDistDetContactInfo.en,
//   ...___networkB2CDistDetFAQ.en,
//   ...___networkB2CDistDetFAQModal.en,
//   ...___networkB2CDistDetGenSec.en,
//   ...___networkB2CDistDetHotelPrior.en,
//   ...___networkB2CDistDetHotelPriorImpModal.en,
//   ...___networkB2CDistDetHotelPriorConfModal.en,
//   ...___networkB2CDistDetLandPages.en,
//   ...___networkB2CDistDetLandPagModal.en,
//   ...___networkB2CDistDetListPages.en,
//   ...___networkB2CDistDetListPagEditModal.en,
//   ...___networkB2CDistDetListPagList.en,
//   ...___networkB2CDistDetMainPromos.en,
//   ...___networkB2CDistDetMainPromosEM.en,
//   ...___networkB2CDistDetMainPromosList.en,
//   ...___networkB2CDistDetPromoLandMod.en,
//   ...___networkB2CDistDetPromoLand.en,
//   ...___networkB2CDistDetRest.en,
//   ...___networkB2CDistDetRestMod.en,
//   ...___networkB2CDistDetRestList.en,
//   // MyLibrary
//   ...___myLibraryAddonsList.en,
//   ...___myLibraryAddonTitle.en,
//   ...___myLibraryHotelsList.en,
//   ...___myLibraryHotelsAddModal.en,
//   ...___myLibraryHotelsEditModal.en,
//   ...___myLibraryHotelsdetail.en,
//   ...___myLibraryHotelsCmpsARM.en,
//   ...___myLibraryHotelsCmpsBI.en,
//   ...___myLibraryHotelsCmpsCalendars.en,
//   ...___myLibraryHotelsCmpsERM.en,
//   ...___myLibraryHotelsCmps.en,
//   ...___myLibraryHotelsCmpsRPC.en,
//   ...___myLibraryHotelsCmpsrooms.en,
//   ...___myLibraryHotelsCmpsCalACa.en,
//   ...___myLibraryHotelsCmpsCalACo.en,
//   ...___myLibraryHotelsCmpsCalCntrls.en,
//   ...___myLibraryHotelsCmpsCalRAF.en,
//   ...___myLibraryHotelsCmpsCalRRF.en,
//   ...___myLibraryHotelsCmpsCalSSC.en,
//   ...___myLibraryHotelsCmpsCalSSCs.en,
//   ...___myLibraryHotelsCmpsCalSSCntrls.en,
//   ...___myLibraryResList.en,
//   ...___myLibraryResAddRes.en,
//   ...___myLibraryResAddMM.en,
//   ...___myLibraryResdetail.en,
//   ...___myLibraryResEditBIModal.en,
//   ...___myLibraryResRemMenuModal.en,
//   ...___myLibraryResPriceTool.en,
//   ...___myLibraryResMenus.en,
//   ...___myLibraryResLocation.en,
//   ...___myLibraryResEditMenuModal.en,
//   ...___myLibraryCooList.en,
//   ...___myLibraryCooAddModal.en,
//   ...___myLibraryGenList.en,
//   ...___myLibraryGenServiceModal.en,
//   ...___myLibraryImgList.en,
//   ...___myLibraryImgPanel.en,
//   ...___myLibraryImgModal.en,
//   // My Profile
//   ...___userProfileDictionary.en,
//   ...___userProfileChangePassDict.en,
//   ...___userProfileJobDescrDict.en,
//   ...___userProfileJobDescrModalDict.en,
//   ...___userProfilePermissionsDict.en,
//   ...___userProfilePermissionsModalDict.en,
//   ...___userProfileProfileDict.en,
//   ...___userProfileProfileModalDict.en,
//   ...___userProfileServCategDict.en,
//   ...___userProfileServCategModalDict.en,
//   ...___userProfileWorkDutiesDict.en,
//   ...___userProfileWorkDutiesModalDict.en,
//   ...___userProfileUploadImgModalDict.en,
// };

import langDictionaries from "./lang_dictionaries";
import _ from "lodash";

const LANGUAGE = "en";

var langEnDictionary = {};

langDictionaries.forEach((langDictionary) => {
  const obj = _.get(langDictionary, LANGUAGE, {});
  Object.entries(obj).forEach(([key, value]) => {
    langEnDictionary[key] = value;
  });
});

export default langEnDictionary;
