import { Rating } from "./OverviewAllAccommodation";
import Rooms from "./AccDetailsRooms";

// ============================= ACTIONS =============================
import { overviewAccSelectRoom } from "@src/actions/Project/TripPlanner";

// ============================ SELECTORS ============================
import { getSelectedAccRoomsIdsSelector } from "@src/selectors/Project/TripPlanner";

import _ from "lodash";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { useQuery } from "@tanstack/react-query";
import { accSearchDetails, fetchFullHotelMetaData } from "@src/api";
import { toast } from "react-toastify";
import { CustomButton } from "@src/components/common/buttons";
import { Icon } from "@iconify/react";
import { RoomFilters, roomFilters } from "./OverviewAllHotels/RoomFilters";
import Fuse from "fuse.js";

export const BasicDetails = ({ loading, metadata }) => {
  const [detailsExpanded, setDetailsExpanded] = useState(false);
  const [descriptionExpanded, setDescriptionExpanded] = useState(false);
  const [facilitiesExpanded, setFacilitiesExpanded] = useState(false);
  const [imgIdx, setImgIdx] = useState(0);

  var facilities = _.get(metadata, "facilities", []).map((fac) =>
    _.startCase(fac)
  );
  return (
    <div className="BasicDetails">
      <div className="title">
        <h5>{_.get(metadata, "name", "N/A")}</h5>
        <Rating rating={_.get(metadata, "rating", 0)} />
        <CustomButton
          appearance={detailsExpanded ? "ghost" : "primary"}
          onClick={() => setDetailsExpanded((p) => !p)}>
          {detailsExpanded ? "Hide Details" : "Show Details"}
        </CustomButton>
        <span>{metadata?.address || "N/A"}</span>
      </div>
      <div className={`details ${detailsExpanded ? "details--expanded" : ""}`}>
        <div className="images">
          <Icon
            className="prev-btn"
            icon="ri:arrow-left-s-line"
            onClick={() =>
              imgIdx - 1 > 0
                ? setImgIdx((p) => p - 1)
                : setImgIdx(() => (metadata?.images || []).length - 1)
            }
          />
          <img alt="" src={metadata?.images?.[imgIdx]?.url} />
          <Icon
            className="next-btn"
            icon="ri:arrow-right-s-line"
            onClick={() =>
              imgIdx + 1 < (metadata?.images || []).length
                ? setImgIdx((p) => p + 1)
                : setImgIdx(0)
            }
          />
        </div>
        <div className="details__sections">
          <div className="section-container">
            <div className="section-title">Type</div>
            <h6>{_.startCase(_.toLower(metadata?.hotel_type || "Hotel"))}</h6>
          </div>
          <div className="section-container">
            <span className="section-title">Description</span>
            <div className="section-container__content">
              <p
                className={`description ${
                  descriptionExpanded ? "description--expanded" : ""
                }`}
                dangerouslySetInnerHTML={{
                  __html: metadata?.description || "N/A",
                }}></p>
              <CustomButton onClick={() => setDescriptionExpanded((p) => !p)}>
                {descriptionExpanded ? "View Less" : "View More"}
              </CustomButton>
            </div>
          </div>
          <div className="section-container">
            <div className="section-title">Facilities</div>
            <div className="section-container__content">
              <div
                className={`facilities ${
                  facilitiesExpanded ? "facilities--expanded" : ""
                }`}>
                {_.sortBy(facilities, (f) => f.length).map((fac, idx) => (
                  <span key={idx} className="tag">
                    {fac}
                  </span>
                ))}
              </div>
              <CustomButton onClick={() => setFacilitiesExpanded((p) => !p)}>
                {facilitiesExpanded ? "View Less" : "View More"}
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
BasicDetails.defaultProps = {
  loading: false,
};
BasicDetails.propTypes = {
  loading: PropTypes.bool.isRequired,
  metadata: PropTypes.object.isRequired,
};

const FuseCfg = {
  keys: ["name"],
  thrsehold: 0.2,
};

const HotelDetails = ({
  accId,
  session_id,
  accommodation,
  destOrder,
  onSelectRoom,
  selectedRoomsIds,
  onBack,
  onCloseDetails,
}) => {
  const [filters, setFilters] = useState(roomFilters.cast({}));
  const [disabledBookingCodes, setDisabledBookingCodes] = useState([]);

  const { data, isLoading: hotelDetailsAreLoading } = useQuery({
    queryKey: ["hotelDetails", accId],
    queryFn: () => fetchFullHotelMetaData(accId),
    onError: () => {
      toast.error("Error fetching hotel details", { autoClose: 3000 });
      onBack();
    },
    refetchOnWindowFocus: false,
  });
  const metadata = data?.data?.hotels[0];

  const { data: accDetails, isLoading: roomDetailsAreLoading } = useQuery({
    queryKey: ["hotelRoomsDetails", accId],
    queryFn: () => accSearchDetails(session_id, accId),
    onError: () => {
      toast.error("Error fetching hotel details", { autoClose: 3000 });
      onBack();
    },
    refetchOnWindowFocus: false,
  });

  const selectedAcc = accDetails?.data?.details;
  if (selectedAcc) {
    selectedAcc["uid"] = accommodation.uid;
  }

  var detailedRooms = accDetails?.data?.details?.detailed_rooms || [];
  if (!detailedRooms.length) {
    detailedRooms = accDetails?.data?.details?.rooms || [];
  }
  var filteredRooms = _.cloneDeep(detailedRooms);
  if (filters.board) {
    filteredRooms = filteredRooms.filter((r) => r.board_code === filters.board);
  }
  if (filters.room_type) {
    filteredRooms = filteredRooms.filter((r) => r.type === filters.room_type);
  }
  if (filters.bedding) {
    filteredRooms = filteredRooms.filter((r) =>
      !r.bedding ? true : r.bedding === filters.bedding
    );
  }
  if (filters.vendor) {
    filteredRooms = filteredRooms.filter((r) => r.provider === filters.vendor);
  }
  if (filters.refundable === "true") {
    filteredRooms = filteredRooms.filter((r) =>
      r.cancellation_policies.some((cxl) => cxl.type === "refundable")
    );
  }
  if (filters.refundable === "false") {
    filteredRooms = filteredRooms.filter((r) =>
      r.cancellation_policies.every((cxl) => cxl.type !== "refundable")
    );
  }
  if (filters.name) {
    const fuseData = new Fuse(filteredRooms, FuseCfg);
    filteredRooms = fuseData.search(filters.name);
  }

  const filteredRoomGroups = _.sortBy(
    Object.entries(_.groupBy(filteredRooms, "id")),
    ([__, rooms]) => rooms?.[0]?.price?.value || 0
  );

  const isLoading = hotelDetailsAreLoading || roomDetailsAreLoading;

  return (
    <React.Fragment>
      <RoomFilters
        rooms={detailedRooms}
        onSetFilters={(filters) => setFilters(filters)}
      />
      <div className="HotelDetails">
        <BasicDetails metadata={metadata} loading={isLoading} />
        <Rooms
          filters={filters}
          isLoading={isLoading}
          destOrder={destOrder}
          accommodation={selectedAcc}
          roomGroups={filteredRoomGroups}
          selectedRoomsIds={selectedRoomsIds}
          onSelectRoom={onSelectRoom}
          onCloseDetails={onCloseDetails}
          session_id={session_id}
          disabledBookingCodes={disabledBookingCodes}
          onDisable={(booking_code) =>
            setDisabledBookingCodes((p) => [...new Set([...p, booking_code])])
          }
        />
      </div>
    </React.Fragment>
  );
};
HotelDetails.defaultProps = { selectedRoomsIds: [] };
HotelDetails.propTypes = {
  selectedRoomsIds: PropTypes.array.isRequired,
  destOrder: PropTypes.number.isRequired,
  accId: PropTypes.string.isRequired,
  session_id: PropTypes.string.isRequired,
  onSelectRoom: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onCloseDetails: PropTypes.func.isRequired,
};
const mapStateToProps = (state, ownProps) => {
  const { destOrder } = ownProps;
  const selectedRoomsIds = getSelectedAccRoomsIdsSelector(state, { destOrder });
  return { selectedRoomsIds };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onSelectRoom: async (destOrder, accommodation, roomId) =>
      await dispatch(overviewAccSelectRoom(destOrder, accommodation, roomId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HotelDetails);
