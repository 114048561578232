import { B2B_CAR_RENTAL_LIST_GENERIC_CHANGE } from "@src/actions/Operation/B2BReservations/SingleServices/CarRental/types";
import { commonList } from "@src/reducers/common";

const B2BCarRentalListReducerInitial = {
  ...commonList,
  displayLength: 100,
  filters: {
    market_type: "B2B",
    car_name__icontains: "",
    created__gte: "",
    created__lte: "",
    creator: "",
    creator_email: "",
    dropoff_datetime__gte: "",
    dropoff_datetime__lte: "",
    dropoff_location__icontains: "",
    pickup_datetime__gte: "",
    pickup_datetime__lte: "",
    pickup_location__icontains: "",
    reference: "",
    external_reference: "",
    tags: "",
    status: "",
    page: 1,
    limit: 20,
    ordering: "",
  },
};
export const B2BCarRentalListReducer = (
  state = B2BCarRentalListReducerInitial,
  action
) => {
  switch (action.type) {
    case B2B_CAR_RENTAL_LIST_GENERIC_CHANGE: {
      const { filters } = action;
      return { ...state, filters: { ...state.filters, ...filters } };
    }
    default:
      return state;
  }
};
