import { Notification, Tag } from "rsuite";
import React from "react";

export const notifyImgTooBig = (sizeMB) => {
  Notification.warning({
    title: "Image Validation Warning",
    description: (
      <p>A maximum size of 2MB is allowed. Your image is {sizeMB}MB</p>
    ),
    duration: 10000,
  });
};
