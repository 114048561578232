import { VENDORS_CAR_LIST_GENERIC_CHANGE } from "@src/actions/Network/Vendors/CarRental/types";
import { commonList } from "@src/reducers/common";

const carRentalVendorsListReducerInitial = {
  ...commonList,
  displayLength: 100,
  filters: {
    creator__icontains: "",
    status: "",
    created__gte: "",
    created__lte: "",
    vendor_type: "",
    vendor: "",
    market_type: "",
    page: 1,
    limit: 20,
    ordering: "",
  },
};
export const CarRentalVendorsListReducer = (
  state = carRentalVendorsListReducerInitial,
  action
) => {
  switch (action.type) {
    case VENDORS_CAR_LIST_GENERIC_CHANGE: {
      const { filters } = action;
      return { ...state, filters: { ...state.filters, ...filters } };
    }
    default:
      return state;
  }
};
