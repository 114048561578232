import { Tag } from "rsuite";

import { formatDate, formatDateTime } from "@src/tools/date_tools";
import { getDisplayNameFromOptions } from "@src/processors/columnsDataProcessor";
import { lang_mapping } from "@src/tools/lang_tools";

import _ from "lodash";
import React from "react";

const statusOption = [
  {
    value: "AC",
    label: "Active",
    color: "green",
  },
  {
    value: "IN",
    label: "Inactive",
    color: "red",
  },
  {
    value: "CA",
    label: "Candidate",
    color: "violet",
  },
];

export const initialColumnsProcessor = {
  policy_name: {
    key: "policy_name",
    getter: (data) => data.name,
  },
  pax: {
    key: "pax",
    getter: (data) => `${data.pax_from} to ${data.pax_to}`,
  },
  rounding_digits: {
    key: "rounding_digits",
    getter: (data) => {
      return data.rounding_digits || "N/A";
    },
  },
  b2c_websites: {
    key: "b2c_websites",
    getter: (data, option) => {
      const websites = option[3];
      const res = data.b2c_websites[0];
      const newData = _.find(websites, (e) => e.value == res) || {};
      const length = data.b2c_websites.length;
      if (length > 1) {
        return `${newData.label} (${length})`;
      } else {
        return newData.label;
      }
    },
    extraGetter: (data, option) => {
      const websites = option[3];
      const newData = data.b2c_websites.map((e) => {
        const res = _.find(websites, (el) => el.value == e).label;
        return res;
      });
      return newData.join(", ");
    },
  },
  distribution_period: {
    key: "distribution_period",
    getter: (data) =>
      `${formatDate(data.booking_window_from)} to ${formatDate(
        data.booking_window_to
      )}`,
  },
  reservation_period: {
    key: "reservation_period",
    getter: (data) =>
      `${formatDate(data.service_execution_from)} to ${formatDate(
        data.service_execution_to
      )}`,
  },
  service_categories: {
    key: "service_categories",
    getter: (data, options) => {
      const travelServices = options[1];
      const travelServiceOptions = options[2];

      let arr = [];
      _.get(data, "travel_services", []).forEach((el) => {
        _.get(travelServices, "data.results", []).forEach((items) => {
          if (el == items.id) {
            if (!_.isEmpty(travelServiceOptions)) {
              arr.push(
                getDisplayNameFromOptions(
                  items.service_type,
                  travelServiceOptions.service_type.choices
                )
              );
            }
          }
        });
      });
      arr = _.reverse(Array.from(new Set(arr)));
      if (arr.length == 1) {
        return `${arr[0]}`;
      } else {
        return `${arr[0]}(${arr.length})`;
      }
    },
    extraGetter: (data, options) => {
      const travelServices = options[1];
      const travelServiceOptions = options[2];

      const arr = [];
      _.get(data, "travel_services").forEach((ele) => {
        if (!_.isEmpty(travelServices)) {
          _.get(travelServices, "data.results", []).forEach((items) => {
            if (ele == items.id) {
              if (!_.isEmpty(travelServiceOptions)) {
                arr.push(
                  getDisplayNameFromOptions(
                    items.service_type,
                    travelServiceOptions.service_type.choices
                  )
                );
              }
            }
          });
        }
      });
      return _.reverse(Array.from(new Set(arr)));
    },
  },
  sales_partners_categories: {
    key: "sales_partners_categories",
    getter: (data) => {
      let arr = [];
      _.get(data, "entities", []).forEach((el) => {
        if (_.startCase(el.target_content_type) == "Independentagent") {
          arr.push("Independent Agent");
        } else if (_.startCase(el.target_content_type) == "Subagent") {
          arr.push("Sub Agent");
        } else {
          arr.push(_.startCase(el.target_content_type));
        }
      });

      const res = Array.from(new Set(arr));

      if (res.length > 1) {
        return `${res[0]}(${res.length})`;
      } else {
        return res;
      }
    },
    extraGetter: (data) => {
      const arr = [];
      if (!_.isEmpty(_.get(data, "entities"))) {
        _.get(data, "entities", []).forEach((el) => {
          if (_.startCase(el.target_content_type) == "Independentagent") {
            arr.push("Independent Agent");
          } else if (_.startCase(el.target_content_type) == "Subagent") {
            arr.push("Sub Agent");
          } else {
            arr.push(_.startCase(el.target_content_type));
          }
        });
      }
      return Array.from(new Set(arr));
    },
  },
  company_name: {
    key: "company_name",
    getter: (data) => {
      let arr = [];
      _.get(data, "entities", []).forEach((el) => {
        arr.push(el.target_entity_legal_title);
      });
      if (arr.length > 1) {
        return `${arr[0]}(${arr.length})`;
      } else {
        return arr;
      }
    },
    extraGetter: (data) => {
      const arr = [];
      if (!_.isEmpty(_.get(data, "entities"))) {
        _.get(data, "entities", []).forEach((item) => {
          arr.push(item.target_entity_legal_title);
        });
      }
      return arr;
    },
  },
  service_type: {
    key: "service_type",
    getter: (data, options) => {
      const travelServices = options[1];
      const language = options[0];

      const num = _.get(data, "travel_services", []).length;
      const arr = [];
      _.get(data, "travel_services", []).forEach((ele) => {
        _.get(travelServices, "data.results", []).forEach((items) => {
          if (ele == items.id) {
            arr.push(items[`brief_description_${lang_mapping[language]}`]);
          }
        });
      });

      if (num == 1) {
        return `${_.reverse(arr)[0]}`;
      } else {
        return `${_.reverse(arr)[0]}(${num})`;
      }
    },
    extraGetter: (data, options) => {
      // const { travelServices, language } = options;
      const travelServices = options[1];
      const language = options[0];
      const arr = [];
      _.get(data, "travel_services", []).forEach((ele) => {
        if (!_.isEmpty(travelServices)) {
          _.get(travelServices, "data.results", []).forEach((items) => {
            if (ele == items.id) {
              arr.push(items[`brief_description_${lang_mapping[language]}`]);
            }
          });
        }
      });

      return _.reverse(arr);
    },
  },
  sales_partners: {
    key: "sales_partners",
    getter: (data, options) => {
      const travel_entities_options = _.get(
        options[5],
        "travel_entities.child.choices"
      );
      let arr = [];
      _.get(data, "travel_entities", []).forEach((el) => {
        arr.push(
          _.startCase(getDisplayNameFromOptions(el, travel_entities_options))
        );
      });
      if (arr.length > 1) {
        return `${Array.from(new Set(arr))[0]}(${
          Array.from(new Set(arr)).length
        })`;
      } else {
        return Array.from(new Set(arr));
      }
    },
    extraGetter: (data, options) => {
      const travel_entities_options = _.get(
        options[5],
        "travel_entities.child.choices"
      );
      const arr = [];
      if (!_.isEmpty(_.get(data, "travel_entities"))) {
        _.get(data, "travel_entities", []).forEach((item) => {
          arr.push(
            _.startCase(
              getDisplayNameFromOptions(item, travel_entities_options)
            )
          );
        });
      }
      return Array.from(new Set(arr));
    },
  },
  status: {
    key: "status",
    // eslint-disable-next-line react/display-name
    getter: (data) => {
      const tmp = _.get(data, "status");
      const res = _.find(statusOption, (item) => {
        return item.value == tmp;
      });
      return <Tag color={res.color}>{res.label}</Tag>;
    },
    extraGetter: (data) => {
      const tmp = _.get(data, "status");
      const res = _.find(statusOption, (item) => {
        return item.value == tmp;
      });
      return res.label;
    },
  },
  created: {
    key: "created",
    getter: (data) => formatDateTime(data.created),
  },
  creator: {
    key: "creator",
    getter: (data) =>
      `${_.get(data, "creator_info.first_name")} ${_.get(
        data,
        "creator_info.last_name"
      )}`,
  },
  edited: {
    key: "edited",
    getter: (data) => formatDateTime(data.edited),
  },
};

export const initialColumnsMapping = {
  policy_name: {
    key: "policy_name",
    fixed: "left",
    width: 150,
  },
  pax: {
    key: "pax",
    fixed: "left",
  },
  b2c_websites: {
    key: "b2c_websites",
    fixed: true,
    width: 250,
  },
  distribution_period: {
    key: "distribution_period",
    fixed: "left",
    width: 200,
  },
  reservation_period: {
    key: "reservation_period",
    fixed: "left",
    width: 200,
  },
  rounding_digits: {
    key: "rounding_digits",
    width: 200,
  },
  sales_partners_categories: {
    key: "sales_partners_categories",
    width: 200,
  },
  company_name: {
    key: "company_name",
    width: 200,
  },
  service_categories: {
    key: "service_categories",
    width: 200,
  },
  sales_partners: {
    key: "sales_partners",
    width: 200,
  },
  service_type: {
    key: "service_type",
    width: 150,
  },
  created: {
    key: "created",
    width: 170,
  },
  edited: {
    key: "edited",
    width: 170,
  },
  creator: {
    key: "creator",
    width: 170,
  },
  status: {
    key: "status",
    fixed: "right",
  },
};
