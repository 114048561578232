import {
  TRIPPLANNER_CUSTOM_ACC_SRV_ADD,
  TRIPPLANNER_CUSTOM_ACC_SRV_REMOVE,
} from "../../types/accommodation";

export const addCustomAccSrv = ({ srv }) => {
  return { type: TRIPPLANNER_CUSTOM_ACC_SRV_ADD, srv };
};

export const removeCustomAccSrv = ({ srv_uid }) => {
  return { type: TRIPPLANNER_CUSTOM_ACC_SRV_REMOVE, srv_uid };
};
