import _ from "lodash";
import { StyleSheet, Text, View, Font } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import React from "react";
import { easyBasicColor } from "../PreviewPDF";
import NotoSansRegular from "@src/style/fonts/NotoSans-Regular.ttf";
import NotoSansBold from "@src/style/fonts/NotoSans-Bold.ttf";

Font.register({
  family: "Noto Sans",
  fonts: [
    { src: NotoSansRegular, fontWeight: "normal" },
    { src: NotoSansBold, fontWeight: "bold" },
  ],
});

const customerInfoStyles = StyleSheet.create({
  CustomerInfo: {
    flexDirection: "row",
    alignItems: "start",
    gap: 45,
    fontSize: 10,
    fontFamily: "Noto Sans",
    fontWeight: "bold",
  },
  title: { width: "50%" },
  values: { flexDirection: "column", width: 450 },
  companyName: {
    fontFamily: "Noto Sans",
    fontWeight: "bold",
    color: "#FF6600",
    paddingBottom: 5,
  },
  label: { flexDirection: "column" },
  value: { fontFamily: "Noto Sans", paddingBottom: 5 },
});
const CustomerInfo = ({ data, entity_colors }) => {
  const titleStyle = { ...customerInfoStyles.title };

  const companyNameStyle = {
    ...customerInfoStyles.companyName,
    color: _.get(entity_colors, "text.colored", easyBasicColor),
  };

  return (
    !!data && (
      <View style={customerInfoStyles.CustomerInfo}>
        <View style={customerInfoStyles.label}>
          <Text style={titleStyle}>Bill to :</Text>
        </View>
        <View style={customerInfoStyles.values}>
          {!!data?.customer?.company && (
            <Text style={companyNameStyle}>{data.customer.company}</Text>
          )}
          {!!data?.customer?.name && (
            <Text style={customerInfoStyles.value}>{data.customer.name}</Text>
          )}
          {!!data?.customer?.address && (
            <Text style={customerInfoStyles.value}>
              {data.customer.address}
            </Text>
          )}
          {!!data?.customer?.country && (
            <Text style={customerInfoStyles.value}>
              {data.customer.country}
            </Text>
          )}
          {!!data?.customer?.phone && (
            <Text style={customerInfoStyles.value}>{data.customer.phone}</Text>
          )}
          {!!data?.customer?.company_email && (
            <Text style={customerInfoStyles.value}>
              {data.customer.company_email}
            </Text>
          )}
        </View>
      </View>
    )
  );
};
CustomerInfo.defaultProps = { data: {}, entity_colors: {} };
CustomerInfo.propTypes = {
  data: PropTypes.object.isRequired,
  entity_colors: PropTypes.object,
};
export default CustomerInfo;
