import {
  // ========================== INIT ACTIONS =========================
  TRIPPLANNER_INIT,
  // ======================= TRIP LOAD ACTIONS =======================
  TRIPPLANNER_LOAD_TRIP,
  // =================== TRANSPORTATION INFORMATION ==================
  TRIPPLANNER_RESET_TRANSPORTATION_INFRASTRUCTURE,
  TRIPPLANNER_SET_ORIGIN_TRANSPORTATION_INFRASTRUCTURE,
  TRIPPLANNER_SET_RETURN_TRANSPORTATION_INFRASTRUCTURE,
  TRIPPLANNER_SET_DESTINATION_TRANSPORTATION_INFRASTRUCTURE,
  TRIPPLANNER_SELECT_TRANSPORTATION_INFRASTRUCTURE,
  TRIPPLANNER_TRP_INFRASTRUCTURE_SEARCHING_RESET,
  TRIPPLANNER_TRP_INFRASTRUCTURE_SEARCHING,
  TRIPPLANNER_TRP_INFRASTRUCTURE_IDLE,
  TRIPPLANNER_TRP_INFRASTRUCTURE_FAIL,
  // ========================== PREFERENCES ==========================
  TRANSPORTATION_PREFERENCES_CHANGE,
  TRANSPORTATION_PREFERENCES_RESET,
  TRIPPLANNER_TRANSPORTATION_EDIT_ENABLED,
  TRIPPLANNER_TRANSPORTATION_EDIT_DISABLED,
  TRIPPLANNER_TRP_INFRA_GENERIC_CHANGE,
} from "@src/actions/Project/TripPlanner/types";

import update from "immutability-helper";

const tripPlannerTrpPrefInitState = {
  stops: "AllFlight",
  class: "All",
  source: ["LC", "GDS"],
  arrival: "ALL",
  preselectionMode: "prefer", // enum: ['prefer', 'comply']
};
export const tripPlannerTrpPrefReducer = (
  state = tripPlannerTrpPrefInitState,
  action
) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return tripPlannerTrpPrefInitState;
    case TRIPPLANNER_LOAD_TRIP:
      return action.data.transportation_preferences;
    case TRANSPORTATION_PREFERENCES_CHANGE:
      return update(state, { [action.name]: { $set: action.value } });
    case TRANSPORTATION_PREFERENCES_RESET:
      return tripPlannerTrpPrefInitState;
    default:
      return state;
  }
};

const tripPlannerTrpInfraSearchStatusInitial = {
  // origin: { airports: 0, ports: 0 },
  // return: { airports: 0, ports: 0 },
  // destinations: { airports: 0, ports: 0 }
  searching: false,
};
export const tripPlannerTrpInfraSearchStatus = (
  state = tripPlannerTrpInfraSearchStatusInitial,
  action
) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return tripPlannerTrpInfraSearchStatusInitial;
    case TRIPPLANNER_LOAD_TRIP:
      return tripPlannerTrpInfraSearchStatusInitial;
    case TRIPPLANNER_TRP_INFRASTRUCTURE_SEARCHING:
      return { ...state, searching: true };
    case TRIPPLANNER_TRP_INFRASTRUCTURE_IDLE:
      return { ...state, searching: false };
    case TRIPPLANNER_TRP_INFRASTRUCTURE_SEARCHING_RESET:
      return tripPlannerTrpInfraSearchStatusInitial;
    default:
      return state;
  }
};

// NB: Not used currently
export const tripPlannerTrpInfraSearchFail = (
  state = tripPlannerTrpInfraSearchStatusInitial,
  action
) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return tripPlannerTrpInfraSearchFail;
    case TRIPPLANNER_LOAD_TRIP:
      return tripPlannerTrpInfraSearchFail;
    case TRIPPLANNER_TRP_INFRASTRUCTURE_FAIL:
      return update(state, {
        [action.infrLegType]: { [action.infrType]: { $apply: (v) => v + 1 } },
      });
    case TRIPPLANNER_TRP_INFRASTRUCTURE_SEARCHING_RESET:
      return tripPlannerTrpInfraSearchStatusInitial;
    default:
      return state;
  }
};

const tripPlannerTrpInfrastructureInitial = {
  origin: {
    airports: [],
    ports: [],
  },
  return: {
    airports: [],
    ports: [],
  },
  destinations: [],
  legs: [],
};
export const tripPlannerTrpInfrastructure = (
  state = tripPlannerTrpInfrastructureInitial,
  action
) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return tripPlannerTrpInfrastructureInitial;
    case TRIPPLANNER_LOAD_TRIP:
      return tripPlannerTrpInfrastructureInitial;
    case TRIPPLANNER_TRP_INFRA_GENERIC_CHANGE: {
      const { key, value } = action;
      return { ...state, [key]: value };
    }
    case TRIPPLANNER_RESET_TRANSPORTATION_INFRASTRUCTURE:
      return update(tripPlannerTrpInfrastructureInitial, {
        destinations: {
          $set: new Array(action.destLength).fill(1).map(() => ({
            airports: [],
            ports: [],
          })),
        },
        legs: { $set: [] },
      });
    case TRIPPLANNER_SET_ORIGIN_TRANSPORTATION_INFRASTRUCTURE:
      return update(state, {
        origin: { [action.infrastructureType]: { $set: action.data } },
      });
    case TRIPPLANNER_SET_RETURN_TRANSPORTATION_INFRASTRUCTURE:
      return update(state, {
        return: { [action.infrastructureType]: { $set: action.data } },
      });
    case TRIPPLANNER_SET_DESTINATION_TRANSPORTATION_INFRASTRUCTURE:
      return update(state, {
        destinations: {
          [action.idx]: { [action.infrastructureType]: { $set: action.data } },
        },
      });
    case TRIPPLANNER_SELECT_TRANSPORTATION_INFRASTRUCTURE: {
      var updater = null;
      if (!action.ids.length) {
        updater = (items) =>
          items.map((i) => update(i, { selected: { $set: false } }));
      } else {
        updater = (items) =>
          items.map((i) => {
            if (
              action.ids.includes(i.iata) ||
              action.ids.includes(i.destinationId.toString())
            ) {
              return update(i, { selected: { $set: true } });
            } else if (
              !action.ids.includes(i.iata) &&
              !action.ids.includes(i.destinationId.toString())
            ) {
              return update(i, { selected: { $set: false } });
            } else {
              return i;
            }
          });
      }

      return update(state, {
        origin: { [action.infraType]: { $apply: (items) => updater(items) } },
        return: { [action.infraType]: { $apply: (items) => updater(items) } },
        destinations: {
          $apply: (dests) =>
            dests.map((d) =>
              update(d, {
                [action.infraType]: { $apply: (items) => updater(items) },
              })
            ),
        },
      });
    }
    default:
      return state;
  }
};

export const tripPlannerTrpPrefEditMode = (state = false, action) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return false;
    case TRIPPLANNER_LOAD_TRIP:
      return false;
    case TRIPPLANNER_TRANSPORTATION_EDIT_ENABLED:
      return true;
    case TRIPPLANNER_TRANSPORTATION_EDIT_DISABLED:
      return false;
    default:
      return state;
  }
};
