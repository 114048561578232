import _ from "lodash";
import { createSelector } from "reselect";
import { getAccServicesSelector } from "../day_by_day_selectors";

import {
  getSrvsForBookingNo,
  getSrvsForOptionBookingNo,
  getSrvsUidForBooking,
  getSrvsUidForOptionBooking,
} from "./selected_services";
import { getNewAllFlights } from "../transportation_selectors";

export const getSrvsForBookingNoSelector = createSelector(
  [getSrvsForBookingNo],
  (serviceNo) => {
    return serviceNo;
  }
);

export const getSrvsForOptionBookingNoSelector = createSelector(
  [getSrvsForOptionBookingNo],
  (serviceNo) => {
    return serviceNo;
  }
);

export const getSrvsUidForBookingSelector = createSelector(
  [getSrvsUidForBooking],
  (services) => {
    return services;
  }
);

export const getSrvsUidForOptBookingSelector = createSelector(
  [getSrvsUidForOptionBooking],
  (services) => {
    return services;
  }
);

// ========================== ACCOMMODATION ==========================
export const getAccSrvsForBookingSelector = createSelector(
  [getSrvsUidForBooking, getSrvsUidForOptionBooking, getAccServicesSelector],
  (srvs_for_booking, srvs_for_option_booking, acc_services) => {
    const valid_services = [];
    srvs_for_booking.forEach((srv) => {
      const fsrv = acc_services.find((s) => s.uid === srv.uid);

      if (fsrv) {
        valid_services.push(fsrv);
      }
    });

    srvs_for_option_booking.forEach((srv) => {
      const fsrv = acc_services.find((s) => s.uid === srv.uid);

      if (fsrv) {
        valid_services.push(fsrv);
      }
    });
    return valid_services;
  }
);

export const getAccSrvsForPrebookSelector = createSelector(
  [getAccSrvsForBookingSelector],
  (acc_services) =>
    acc_services.filter((srv) => srv.accommodation_service_type === "ACC")
);

export const isAccPrebookRequiredSelector = createSelector(
  [getAccSrvsForPrebookSelector],
  (accs) => accs.length > 0
);

// ============================= FLIGHTS =============================
export const getFlSrvsForPrebookSelector = createSelector(
  [getSrvsUidForBooking, getNewAllFlights],
  (uids_for_booking, selected_flights) => {
    const valid_uids_for_booking = new Set(
      [...uids_for_booking, uids_for_booking]
        .filter((uid) => uid.srv_type === "FL")
        .map((uid) => uid.uid)
    );
    const valid_flights = selected_flights.filter((fl) =>
      valid_uids_for_booking.has(fl.uid)
    );
    return valid_flights;
  }
);
