import _ from "lodash";
import { IconButton, Icon } from "rsuite";
import PropTypes from "prop-types";
import React, { forwardRef, useEffect, useState } from "react";
import { inputStyles } from "@src/jsssetup";
import { Field, useField, useFormikContext } from "formik";
import { DateTime, Duration } from "luxon";
import DatePicker from "react-datepicker";
import ReactQuill from "react-quill";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";

export function RangePicker({ from_key, to_key, min_date }) {
  const { setFieldValue } = useFormikContext();
  const [from_field] = useField({ name: from_key });
  const [to_field] = useField({ name: to_key });

  function jsDateToIso(date) {
    let result = null;
    result = date ? DateTime.fromJSDate(date).toISODate() : null;
    return result;
  }

  const startDate = from_field.value
    ? DateTime.fromISO(from_field.value).toJSDate()
    : null;
  const endDate = to_field.value
    ? DateTime.fromISO(to_field.value).toJSDate()
    : null;

  return (
    <DatePicker
      dateFormat="MMM d, yyyy"
      className="RangePicker__input"
      showYearDropdown={true}
      yearDropdownItemNumber={100}
      scrollableYearDropdown
      withPortal
      portalId="date-portal"
      selectsRange={true}
      startDate={startDate}
      minDate={min_date ? DateTime.fromISO(min_date).toJSDate() : null}
      endDate={endDate}
      onChange={(dates) => {
        setFieldValue(from_key, jsDateToIso(dates[0]));
        setFieldValue(to_key, jsDateToIso(dates[1]));
      }}
    />
  );
}
RangePicker.propTypes = {
  from_key: PropTypes.string.isRequired,
  to_key: PropTypes.string.isRequired,
  min_date: PropTypes.string.isRequired,
};

export function NormalRangePicker({
  id,
  label,
  theme,
  required,
  from_key,
  to_key,
  withError,
  withTime,
  extraInputProps,
  min_date,
  highlightErrors,
}) {
  const [__, from_meta] = useField({ name: from_key });
  const [___, to_meta] = useField({ name: to_key });

  return (
    <div
      className="NormalRangePicker__inputGroup"
      data-normal-theme={theme === "normal"}
      id={id}
      {...extraInputProps}>
      <span>
        <strong>
          {label}
          {required && " *"}
        </strong>
      </span>
      <RangePicker
        from_key={from_key}
        to_key={to_key}
        withTime={withTime}
        min_date={min_date}
      />
      {withError &&
        ((from_meta?.error ?? "").length > 1 ||
          (to_meta?.error ?? "").length > 1) && (
          <div
            className="NormalRangePicker__inputGroup__error"
            data-highlight-errors={highlightErrors}>
            {from_meta.error}
            {to_meta.error}
          </div>
        )}
    </div>
  );
}
NormalRangePicker.defaultProps = {
  customStyles: {},
  required: false,
  theme: "normal",
  withTime: false,
  withError: false,
  extraInputProps: {},
  highlightErrors: false,
  min_date: "1974-01-01",
  id: "",
};
NormalRangePicker.propTypes = {
  customStyles: PropTypes.object,
  theme: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  required: PropTypes.bool.isRequired,
  from_key: PropTypes.string.isRequired,
  to_key: PropTypes.string.isRequired,
  withError: PropTypes.bool,
  extraInputProps: PropTypes.object,
  withTime: PropTypes.bool,
  min_date: PropTypes.string,
  highlightErrors: PropTypes.bool,
  id: PropTypes.string,
};

export function NormalInputField({
  id,
  label,
  type,
  name,
  withError,
  extraInputProps,
  required,
  theme,
  highlightErrors,
  as,
  rows,
  readOnly = false,
  resize = true,
}) {
  const [___, meta] = useField({ name });

  return (
    <div
      className="NormalInputField__inputGroup"
      data-normal-theme={theme === "normal"}
      id={id}>
      {label && (
        <span className="NormalInputField__inputGroup__label">
          <strong>
            {label}
            {required && " *"}
          </strong>
        </span>
      )}
      <Field
        type={type}
        name={name}
        className="NormalInputField__inputGroup__input"
        data-normal-theme={theme === "normal"}
        data-resize={resize}
        as={as}
        rows={rows}
        readOnly={readOnly}
        {...extraInputProps}
      />
      {withError && (meta?.error ?? "").length > 1 && (
        <div
          className="NormalInputField__inputGroup__error"
          data-highlight-errors={highlightErrors}>
          {meta.error}
        </div>
      )}
    </div>
  );
}
NormalInputField.defaultProps = {
  as: "",
  label: "",
  type: "text",
  name: "",
  extraInputProps: {},
  withError: true,
  highlightErrors: false,
  required: false,
  theme: "normal",
  rows: 2,
  resize: true,
  readOnly: false,
  id: "",
};
NormalInputField.propTypes = {
  withError: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  as: PropTypes.string.isRequired,
  extraInputProps: PropTypes.object,
  highlightErrors: PropTypes.bool,
  required: PropTypes.bool.isRequired,
  rows: PropTypes.number,
  theme: PropTypes.oneOf(["normal", "filter"]).isRequired,
  resize: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
  id: PropTypes.string,
};

const TimeInput = forwardRef(({ vl, onClick }, ref) => {
  var time = DateTime.fromISO(vl);
  if (time.invalid) {
    time = "N/A";
  } else {
    time = time.toLocaleString(DateTime.TIME_24_SIMPLE);
  }

  return (
    <button
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      ref={ref}
      className="TimeInput">
      {time}
    </button>
  );
});
TimeInput.displayName = "TimeInput";
TimeInput.propTypes = {
  customStyles: PropTypes.object,
  withTimeOnly: PropTypes.bool,
  vl: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export function NormalDatePicker({
  id,
  name,
  label,
  required,
  theme,
  withTime,
  withTimeOnly,
  showYearDropdown,
  minAvailableDate,
  maxAvailableDate,
  extraInputProps,
  highlightErrors = false,
  withError = false,
}) {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField({ name: name });
  const { value } = field;

  const extraProps = withTime ? { timeFormat: "p", dateFormat: "Pp" } : {};
  if (minAvailableDate) {
    extraProps["filterDate"] = (date) =>
      DateTime.fromJSDate(date) >= DateTime.fromISO(minAvailableDate);
  }

  var dropdownProps = {};
  if (showYearDropdown) {
    dropdownProps = {
      showYearDropdown,
      minDate: minAvailableDate
        ? DateTime.fromISO(minAvailableDate).toJSDate()
        : DateTime.now()
            .minus(Duration.fromObject({ years: 100 }))
            .toJSDate(),
      maxDate: maxAvailableDate
        ? DateTime.fromISO(maxAvailableDate).toJSDate()
        : DateTime.now().toJSDate(),
      yearDropdownItemNumber: 100,
      scrollableYearDropdown: true,
    };
  }

  return (
    <div
      className="NormalDatePicker__inputGroup"
      data-normal-theme={theme === "normal"}
      id={id}>
      <span>
        <strong>
          {label}
          {required && " *"}
        </strong>
      </span>
      <DatePicker
        dateFormat={
          withTimeOnly
            ? "h:mm aa"
            : withTime
            ? "MMM d, yyyy h:mm aa"
            : "MMM d, yyyy"
        }
        showTimeSelect={withTime}
        showTimeInput={withTime}
        showTimeSelectOnly={withTimeOnly}
        className="NormalDatePicker__inputGroup__input"
        withPortal
        portalId="date-portal"
        selected={value ? DateTime.fromISO(value).toJSDate() : null}
        {...dropdownProps}
        customInput={withTimeOnly ? <TimeInput vl={value} /> : null}
        onChange={(date) => {
          setFieldValue(
            name,
            withTimeOnly
              ? DateTime.fromJSDate(date).toISOTime()
              : withTime
              ? DateTime.fromJSDate(date).toISO()
              : DateTime.fromJSDate(date).toISODate()
          );
        }}
        {...extraProps}
        {...extraInputProps}
      />
      {withError && (
        <div
          // className={classes.error}
          className="NormalDatePicker__inputGroup__error"
          data-highlight-errors={highlightErrors}>
          {meta.error}
        </div>
      )}
    </div>
  );
}
NormalDatePicker.defaultProps = {
  showYearDropdown: false,
  withTime: false,
  withTimeOnly: false,
  required: false,
  theme: "normal",
  withError: false,
  highlightErrors: false,
  extraInputProps: {},
  id: "",
};
NormalDatePicker.propTypes = {
  minAvailableDate: PropTypes.string,
  maxAvailableDate: PropTypes.string,
  withTime: PropTypes.bool,
  withTimeOnly: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  theme: PropTypes.oneOf(["normal", "filter"]).isRequired,
  required: PropTypes.bool.isRequired,
  showYearDropdown: PropTypes.bool.isRequired,
  withError: PropTypes.bool.isRequired,
  extraInputProps: PropTypes.object,
  highlightErrors: PropTypes.bool.isRequired,
  id: PropTypes.string,
};

export function NormalSelectField({
  id,
  theme,
  name,
  label,
  withError = true,
  highlightErrors = false,
  extraInputProps,
  options,
  disabled,
}) {
  const [__, meta] = useField({ name });

  return (
    <div
      id={id}
      className="NormalSelectField__inputGroup"
      data-normal-theme={theme === "normal"}>
      <span>
        <strong>{label}</strong>
      </span>
      <Field
        as="select"
        name={name}
        className="NormalSelectField__inputGroup__input"
        data-normal-theme={theme === "normal"}
        disabled={disabled}
        {...extraInputProps}>
        {options.map(([value, label, subtitle], idx) => (
          <React.Fragment key={idx}>
            <option value={value}>{label}</option>
            {subtitle && (
              <option disabled key={`disabled-${idx}`}>
                &nbsp;&nbsp;&nbsp;{subtitle}
              </option>
            )}
          </React.Fragment>
        ))}
      </Field>
      {withError && (meta?.error ?? "").length > 1 && (
        <div
          className="NormalSelectField__inputGroup__error"
          data-highlight-errors={highlightErrors ? "true" : "false"}>
          {meta.error}
        </div>
      )}
    </div>
  );
}
NormalSelectField.defaultProps = {
  theme: "normal",
  name: "",
  label: "",
  extraInputProps: {},
  withError: true,
  highlightErrors: false,
  disabled: false,
  options: [],
  id: "",
};
NormalSelectField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  extraInputProps: PropTypes.object,
  withError: PropTypes.bool.isRequired,
  highlightErrors: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  options: PropTypes.array.isRequired,
  theme: PropTypes.oneOf(["normal", "filter"]).isRequired,
  id: PropTypes.string,
};

export const quillModules = {
  toolbar: {
    container: [
      [{ header: [2, 3, 4, false] }],
      ["bold", "italic", "underline", "blockquote"],
      [
        {
          color: [
            "#000000",
            "#e60000",
            "#ff9900",
            "#ffff00",
            "#008a00",
            "#0066cc",
            "#9933ff",
            "#ffffff",
            "#facccc",
            "#ffebcc",
            "#ffffcc",
            "#cce8cc",
            "#cce0f5",
            "#ebd6ff",
            "#bbbbbb",
            "#f06666",
            "#ffc266",
            "#ffff66",
            "#66b966",
            "#66a3e0",
            "#c285ff",
            "#888888",
            "#a10000",
            "#b26b00",
            "#b2b200",
            "#006100",
            "#0047b2",
            "#6b24b2",
            "#444444",
            "#5c0000",
            "#663d00",
            "#666600",
            "#003700",
            "#002966",
            "#3d1466",
            "custom-color",
          ],
        },
      ],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" },
      ],
      ["clean"],
    ],
    handlers: {
      color: function (value) {
        if (value == "custom-color")
          value = window.prompt("Enter Hex Color Code");
        this.quill.format("color", value);
      },
    },
  },
  clipboard: { matchVisual: true },
};
export const quillFormats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "clean",
  "align",
];
export function RichDescriptionField(props) {
  const { description_key } = props;

  const { setFieldValue } = useFormikContext();
  const [description] = useField({ name: description_key });

  return (
    <ReactQuill
      className="RichDescriptionField"
      modules={quillModules}
      formats={quillFormats}
      value={description.value ? description.value : ""}
      onChange={(value, __, source) => {
        if (source == "api") {
          return;
        }
        setFieldValue(description_key, value);
      }}
    />
  );
}
RichDescriptionField.propTypes = {
  description_key: PropTypes.string.isRequired,
};

const selectFieldStyles = {
  control: () => ({
    ...inputStyles,
    border: "none",
    display: "grid",
    gridTemplateColumns: "1fr auto",
    padding: "unset",
  }),
  valueContainer: (provided) => ({
    ...provided,
    overflow: "auto",
    display: "grid",
    gridTemplateColumns: "repeat(1000,max-content)",
  }),
};
export function SelectField({ options, multi, search, clear, field_key }) {
  const { setFieldValue } = useFormikContext();
  const [field] = useField({ name: field_key });
  function isNotObject(value) {
    return ["number", "string"].includes(typeof value);
  }
  function findOption(value) {
    return options.find((opt) => opt.value === value);
  }

  const [selected, setSelected] = useState(
    Array.isArray(field.value)
      ? isNotObject(_.get(field.value, 0))
        ? field.value.map((v) => findOption(v))
        : field.value
      : isNotObject(field.value)
      ? findOption(field.value)
      : field.value
  );

  const allSelected = multi
    ? options.length === (selected || []).length
    : false;
  const newOptions = multi
    ? allSelected
      ? options
      : [
          {
            value: options.filter((opt) => opt.value).map((opt) => opt),
            label: "Select All",
            key: "select_all",
          },
          ...options,
        ]
    : options;

  // Handle selection
  useEffect(() => {
    if (selected && typeof selected !== "undefined") {
      setFieldValue(
        field_key,
        Array.isArray(selected)
          ? selected.map((sel) => _.get(sel, "value", sel)).filter((s) => s)
          : selected?.value ?? selected
      );
    } else {
      setFieldValue(field_key, multi ? [] : null);
    }
  }, [selected]);

  // Handle reset
  useEffect(() => {
    if (!field.value) setSelected(null);
  }, [field.value]);

  return (
    <Select
      className="ExtendedSelectField_inputGroup__input"
      menuPortalTarget={document.getElementById("select-portal")}
      value={selected}
      styles={selectFieldStyles}
      options={newOptions}
      isMulti={multi}
      isSearchable={search}
      isClearable={clear}
      closeMenuOnSelect={!multi}
      menuPlacement="auto"
      onChange={(newValue, actionMeta) => {
        if (
          multi &&
          actionMeta.action === "select-option" &&
          newValue.some((val) => val.key === "select_all")
        ) {
          const selectAll = newValue.find((val) => val.key === "select_all");
          setSelected(selectAll.value);
        } else {
          setSelected(newValue);
        }
      }}
    />
  );
}
SelectField.defaultProps = {
  field_key: "",
  options: [],
  multi: false,
  search: false,
  clear: false,
};
SelectField.propTypes = {
  options: PropTypes.array.isRequired,
  field_key: PropTypes.string.isRequired,
  multi: PropTypes.bool.isRequired,
  search: PropTypes.bool.isRequired,
  clear: PropTypes.bool.isRequired,
};

export function ExtendedSelectField({
  id,
  name,
  label,
  options,
  multi,
  search,
  clear,
  withError,
  highlightErrors,
  theme,
}) {
  const [__, meta] = useField({ name });

  return (
    <div
      className="ExtendedSelectField_inputGroup"
      data-normal-theme={theme === "normal"}
      id={id}>
      <span>
        <strong>{label}</strong>
      </span>
      <SelectField
        multi={multi}
        search={search}
        clear={clear}
        field_key={name}
        options={options.map(([value, label]) => ({ label, value }))}
      />
      {withError && (
        <div
          className="ExtendedSelectField_inputGroup__error"
          data-highlight-errors={highlightErrors}>
          {meta.error}
        </div>
      )}
    </div>
  );
}
ExtendedSelectField.defaultProps = {
  theme: "normal",
  name: "",
  label: "",
  options: [],
  multi: false,
  search: false,
  clear: false,
  withError: false,
  highlightErrors: false,
  id: "",
};
ExtendedSelectField.propTypes = {
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  multi: PropTypes.bool.isRequired,
  search: PropTypes.bool.isRequired,
  clear: PropTypes.bool.isRequired,
  withError: PropTypes.bool.isRequired,
  highlightErrors: PropTypes.bool.isRequired,
  theme: PropTypes.oneOf(["normal", "filter"]).isRequired,
  id: PropTypes.string,
};

export function NormalRadioButtons(props) {
  const { name, options, label } = props;

  return (
    <div className="RadioGroups">
      <div className="RadioGroups__radioHeader">
        <span>
          <strong>{label || _.startCase(name)}:</strong>
        </span>
      </div>
      {options?.map((row) => (
        <div className="RadioGroups__radioBody" key={row[0]}>
          <div className="RadioGroups__radioBody__radioGroup">
            <Field
              className="RadioGroups__radioBody__radioGroup__radioValue"
              name={name}
              value={row[0]}
              type="radio"
              id={row[0]}
            />
            <label
              className="RadioGroups__radioBody__radioGroup__radioLabel"
              htmlFor={row[0]}>
              <strong>{_.startCase(row[1])}</strong>
            </label>
          </div>
        </div>
      ))}
    </div>
  );
}
NormalRadioButtons.defaultProps = { name: "", label: "", options: [] };
NormalRadioButtons.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  options: PropTypes.array.isRequired,
};

export function NormalRichText({
  name,
  withError,
  label,
  required,
  theme,
  highlightErrors,
  id,
}) {
  const { setFieldValue } = useFormikContext();
  const [normalDescription] = useField({ name: name });
  const [__, meta] = useField({ name });

  return (
    <div
      className="NormalRichText__inputGroup"
      data-normal-theme={theme === "normal"}>
      <span className="NormalRichText__inputGroup__label">
        <strong>
          {label}
          {required && " *"}
        </strong>
      </span>
      <ReactQuill
        id={id}
        className="NormalRichText__inputGroup__quill"
        modules={quillModules}
        formats={quillFormats}
        value={normalDescription.value ? normalDescription.value : ""}
        onChange={(value, ___, source) => {
          if (source == "api") {
            return;
          }
          setFieldValue(name, value);
        }}
      />
      {withError && (
        <div
          className="NormalRichText__inputGroup__error"
          data-highlight-errors={highlightErrors}>
          {meta.error}
        </div>
      )}
    </div>
  );
}
NormalRichText.defaultProps = {
  customStyles: {},
  theme: "normal",
  name: "description",
  highlightErrors: false,
  id: "",
};
NormalRichText.propTypes = {
  customStyles: PropTypes.object,
  theme: PropTypes.oneOf(["normal", "filter"]).isRequired,
  name: PropTypes.string.isRequired,
  highlightErrors: PropTypes.bool.isRequired,
  withError: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  id: PropTypes.string,
};

export function NormalSliderField({
  label,
  name,
  min,
  max,
  withError,
  extraInputProps,
  required,
  theme,
  rows,
}) {
  const [__, meta] = useField({ name });

  return (
    <div
      className="NormalSliderField__inputGroup"
      data-normal-theme={theme === "normal"}>
      <span className="NormalSliderField__inputGroup__label">
        <strong>
          {label}
          {required && " *"}
        </strong>
      </span>
      <Field
        type="range"
        min={min}
        max={max}
        name={name}
        className="NormalSliderField__inputGroup__input"
        data-normal-theme={theme === "normal"}
        rows={rows}
        {...extraInputProps}
      />
      {withError && (
        <div className="NormalSliderField__inputGroup__error">{meta.error}</div>
      )}
    </div>
  );
}
NormalSliderField.defaultProps = {
  customStyles: {},
  label: "",
  name: "",
  extraInputProps: {},
  withError: true,
  highlightErrors: false,
  required: false,
  theme: "normal",
  rows: 2,
};
NormalSliderField.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  customStyles: PropTypes.object,
  withError: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  name: PropTypes.string.isRequired,
  extraInputProps: PropTypes.object,
  highlightErrors: PropTypes.bool,
  required: PropTypes.bool.isRequired,
  rows: PropTypes.number,
  theme: PropTypes.oneOf(["normal", "filter"]).isRequired,
};

export function ApplyFiltersBtn({ id, onClick }) {
  const { submitForm } = useFormikContext();
  return (
    <IconButton
      id={id}
      icon={<Icon icon="search" />}
      circle
      color="green"
      type="submit"
      onClick={(e) => {
        e.preventDefault();
        onClick();
        submitForm();
      }}
    />
  );
}
ApplyFiltersBtn.defaultProps = { id: "", onClick: () => null };
ApplyFiltersBtn.propTypes = { id: PropTypes.string, onClick: PropTypes.func };

export function ResetFiltersBtn({ id, onReset, defaultFilters = null }) {
  const { values, resetForm, submitForm, setValues } = useFormikContext();

  return (
    <IconButton
      id={id}
      icon={<Icon icon="refresh" />}
      circle
      appearance="primary"
      onClick={(e) => {
        e.preventDefault();
        if (!!defaultFilters) {
          setValues(defaultFilters);
        } else {
          resetForm();
        }

        if (!!onReset) onReset(values, setValues);
        submitForm();
      }}
    />
  );
}
ResetFiltersBtn.defaultProps = { id: "", defaultFilters: null, onReset: null };
ResetFiltersBtn.propTypes = {
  id: PropTypes.string,
  onReset: PropTypes.func,
  defaultFilters: PropTypes.object,
};

export * from "./autocomplete";
export * from "./checkbox";
