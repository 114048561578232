import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { handleNonValidImgFn } from "@src/tools/common_tools";
import { InlineIcon } from "@iconify/react";
import { BottomContainer } from "./BottomContainer";

const CarRental = ({ carRental }) => {
  return (
    <div className="CarRental">
      <div className="CarRental__topContr">
        <div className="CarRental__topContr__vehicleName">
          {_.get(carRental, "vehicle.name", "")}
        </div>
        <div className="CarRental__topContr__imgContainer">
          <img
            className="CarRental__topContr__imgContainer__img"
            alt=""
            onError={handleNonValidImgFn}
            src={_.get(carRental, "vehicle.image", "")}
          />
        </div>
        <div className="CarRental__topContr__topContrInfo">
          <span>
            {_.get(carRental, "vehicle.passenger_quantity", 0)}{" "}
            <InlineIcon
              icon="ic:baseline-person"
              className="CarRental__topContr__topContrInfo__topContrInfoIcon"
            />
          </span>
          <span>
            {_.get(carRental, "vehicle.baggage_quantity", 0)}{" "}
            <InlineIcon
              icon="material-symbols:luggage"
              className="CarRental__topContr__topContrInfo__topContrInfoIcon"
            />
          </span>
          <span>
            {_.get(carRental, "vehicle.door_count", 0)}{" "}
            <InlineIcon
              icon="mdi:car-door"
              className="CarRental__topContr__topContrInfo__topContrInfoIcon"
            />
          </span>
        </div>
      </div>
      <div className="CarRental__contentAndBottomContainer">
        <div className="CarRental__contentAndBottomContainer__content">
          <div className="CarRental__contentAndBottomContainer__content__basicInfo">
            <div className="CarRental__contentAndBottomContainer__content__basicInfo__item">
              <InlineIcon
                icon="ic:baseline-location-on"
                className="CarRental__contentAndBottomContainer__content__basicInfo__item__itemIcon"
              />
              <div className="CarRental__contentAndBottomContainer__content__basicInfo__item__itemText">
                Pick Up Location:{" "}
                <strong>{_.get(carRental, "pick_up.name", "")}</strong>
              </div>
            </div>
            <div className="CarRental__contentAndBottomContainer__content__basicInfo__item">
              <InlineIcon
                icon="mdi:gas-station"
                className="CarRental__contentAndBottomContainer__content__basicInfo__item__itemIcon"
              />
              <div className="CarRental__contentAndBottomContainer__content__basicInfo__item__itemText">
                Fuel Type:{" "}
                <strong>{_.get(carRental, "vehicle.fuel_type", "")}</strong>
              </div>
            </div>
            <div className="CarRental__contentAndBottomContainer__content__basicInfo__item">
              <InlineIcon
                icon="mdi:car-shift-pattern"
                className="CarRental__contentAndBottomContainer__content__basicInfo__item__itemIcon"
              />
              <div className="CarRental__contentAndBottomContainer__content__basicInfo__item__itemText">
                Transmission:{" "}
                <strong>
                  {_.get(carRental, "vehicle.transmission_type", "")}
                </strong>
              </div>
            </div>
            <div className="CarRental__contentAndBottomContainer__content__basicInfo__item">
              <InlineIcon
                icon="material-symbols:mode-fan"
                className="CarRental__contentAndBottomContainer__content__basicInfo__item__itemIcon"
              />
              <div className="CarRental__contentAndBottomContainer__content__basicInfo__item__itemText">
                AirCondition:{" "}
                <strong>
                  {_.get(carRental, "vehicle.has_air_condition", false)
                    ? "Yes"
                    : "No"}
                </strong>
              </div>
            </div>
            <div className="CarRental__contentAndBottomContainer__content__basicInfo__item">
              <InlineIcon
                icon="tabler:building"
                className="CarRental__contentAndBottomContainer__content__basicInfo__item__itemIcon"
              />
              <div className="CarRental__contentAndBottomContainer__content__basicInfo__item__itemText">
                Provider:{" "}
                <strong>{_.get(carRental, "provider.name", 0)}</strong>
              </div>
            </div>
          </div>
          <div className="CarRental__contentAndBottomContainer__content__extraInfo">
            <div className="CarRental__contentAndBottomContainer__content__extraInfo__extraInfoTitle">
              Inclusions
            </div>
            {carRental.inclusions.map((inclusion, idx) =>
              inclusion.coverage_type && inclusion.code !== "CF" ? (
                <div
                  className="CarRental__contentAndBottomContainer__content__extraInfo__extraInfoItem"
                  key={idx}>
                  <InlineIcon
                    icon="ic:outline-check"
                    className="CarRental__contentAndBottomContainer__content__extraInfo__extraInfoItem__extraInfoIcon"
                  />
                  {inclusion.coverage_type}
                </div>
              ) : null
            )}
          </div>
        </div>
        <BottomContainer carRental={carRental} />
      </div>
    </div>
  );
};
CarRental.defaultProps = {
  carRental: {},
};
CarRental.propTypes = {
  carRental: PropTypes.object.isRequired,
};
export default CarRental;
