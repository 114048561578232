import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { injectIntl } from "react-intl";
import { Button, Loader } from "rsuite";
import { changeLang } from "@src/actions/i18n/lang";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { NormalInputField } from "../forms";
import { InlineIcon } from "@iconify/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { patchCompanyProfile, uploadImage } from "@src/api";
import { toast } from "react-toastify";
import { fetchCompanyProfile } from "@src/actions/Admin/Company";

const LOGO =
  "https://easytravel-tech.s3.eu-central-1.amazonaws.com/easyTravel_logo.svg";

const FILE_SIZE = 2 * 1024 * 1024; // 2MB
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

function initialSchemaStepOne(profile) {
  return {
    first_name: profile?.contact_person?.[0]?.first_name ?? "",
    last_name: profile?.contact_person?.[0]?.last_name ?? "",
    user_email: profile?.email,
  };
}

function initialSchemaStepTwo(profile) {
  return {
    // company_name: profile?.legal_title ?? "",
    brand_name: profile?.name ?? "",
    reg_code: profile?.registration_code ?? "",
    company_logo: "",
  };
}

const validationSchemaStepOne = Yup.object().shape({
  first_name: Yup.string().required("First Name is required"),
  last_name: Yup.string().required("Last Name is required"),
  user_email: Yup.string().email("Invalid email").required("Email is required"),
});

const validationSchemaStepTwo = Yup.object().shape({
  // company_name: Yup.string().required("Company Name is required"),
  brand_name: Yup.string().required("Brand Name is required"),
  reg_code: Yup.string(),
  company_logo: Yup.mixed()
    .nullable()
    .test(
      "fileSize",
      "File too large (max file size 2MB)",
      (value) => !value || value.size <= FILE_SIZE
    )
    .test(
      "fileFormat",
      "Unsupported Format",
      (value) => !value || SUPPORTED_FORMATS.includes(value.type)
    ),
});

const SecondForm = ({ step, profile, payload }) => {
  const fileInputRef = useRef();

  const dispatch = useDispatch();
  const onFetchCProfile = useCallback(
    () => dispatch(fetchCompanyProfile()),
    [dispatch]
  );

  const { mutate: signUpEditMutation, isLoading: signUpLoading } = useMutation({
    mutationFn: ({ payload }) => patchCompanyProfile({ payload }),
    onSuccess: async () => {
      await onFetchCProfile();
      toast.success("Successfully updated profile");
      window.location.href = "/#/project/trip/new-trip";
      window.location.reload();
    },
    onError: (error) => {
      console.log("error = ", error);
      toast.error(error);
    },
  });

  const { mutate: uploadImageMutation, isLoading: imageLoading } = useMutation({
    mutationFn: (values) => {
      const formData = new FormData();
      formData.append("image", values.company_logo);
      return uploadImage(formData);
    },
    onSuccess: (data, values) => {
      if (data?.data?.url) {
        signUpEditMutation({
          payload: {
            logo_url: data?.data?.url,
            contact_person: [
              {
                first_name: payload.first_name,
                last_name: payload.last_name,
                email: payload.user_email,
              },
            ],
            name: values.company_name,
            legal_title: values.brand_name,
            registration_code: values.reg_code,
            email: payload.user_email,
          },
        });
      }
    },
    onError: (error) => {
      toast.error(error);
    },
  });

  const loading = imageLoading || signUpLoading;

  return (
    <div className="FlowForm">
      {loading ? (
        <Loader center size="lg" />
      ) : (
        <Formik
          initialValues={initialSchemaStepTwo(profile)}
          validationSchema={validationSchemaStepTwo}
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize={true}
          onSubmit={(values) => {
            if (values?.company_logo instanceof File) {
              uploadImageMutation(values);
            } else {
              signUpEditMutation({
                payload: {
                  contact_person: [
                    {
                      first_name: payload.first_name,
                      last_name: payload.last_name,
                      email: payload.user_email,
                    },
                  ],
                  name: values.brand_name,
                  registration_code: values.reg_code,
                  email: payload.user_email,
                },
              });
            }
          }}>
          {({ setFieldValue, values, errors }) => (
            <Form className="FlowForm__formContainer">
              <span>
                Please provide some more information about your company.
                <br />
                If you don't have a brand name you can use your company's legal
                title
              </span>
              {/* <div className="FlowForm__formContainer__element"> */}
              {/*   <div className="FlowForm__formContainer__element__inputLabel"> */}
              {/*     <NormalInputField */}
              {/*       name="company_name" */}
              {/*       id="company_name" */}
              {/*       label={"Company Name"} */}
              {/*       highlightErrors={true} */}
              {/*     /> */}
              {/*   </div> */}
              {/* </div> */}
              <div className="FlowForm__formContainer__element">
                <div className="FlowForm__formContainer__element__inputLabel">
                  <NormalInputField
                    name="brand_name"
                    id="brand_name"
                    label={"Company Brand Name"}
                    highlightErrors={true}
                  />
                </div>
              </div>
              <div className="FlowForm__formContainer__element">
                <NormalInputField
                  name="reg_code"
                  id="reg_code"
                  label={
                    <>
                      Company Registration Code{" "}
                      <span className="FlowForm__formContainer__element__optional">
                        (Optional)
                      </span>
                    </>
                  }
                  highlightErrors={true}
                />
              </div>
              <div className="FlowForm__formContainer__element">
                <label>
                  <span className="FlowForm__formContainer__element__inputLabel">
                    Company Logo{" "}
                  </span>
                  <span className="FlowForm__formContainer__element__optional">
                    (Optional)
                  </span>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    name="company_logo"
                    onChange={(event) => {
                      setFieldValue(
                        "company_logo",
                        event.currentTarget.files[0]
                      );
                    }}
                    accept=".png, .jpg, .jpeg"
                  />
                  <div
                    id="company_logo"
                    className="FlowForm__formContainer__element__companyLogoCntr">
                    <InlineIcon
                      icon="mdi:file-document-outline"
                      onClick={() => fileInputRef.current.click()}
                    />
                    Upload File{" "}
                    {values.company_logo ? (
                      <span style={{ fontSize: "small" }}>
                        ({values.company_logo.name},{" "}
                        {(values.company_logo.size / 1024 / 1024).toFixed(2)}{" "}
                        MB/2MB)
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  {errors?.company_logo && (
                    <div className="FlowForm__formContainer__element__error">
                      {errors?.company_logo}
                    </div>
                  )}
                </label>
              </div>
              <div className="FlowForm__formContainer__element">
                <div
                  className="FlowForm__formContainer__element__submitBtnCntr"
                  data-step-one={step === 0 ? "true" : "false"}>
                  <Button
                    id="submitBtn"
                    size="sm"
                    appearance="primary"
                    block
                    type="submit">
                    Submit
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};
SecondForm.propTypes = {
  payload: PropTypes.object,
  step: PropTypes.number,
  profile: PropTypes.func.isRequired,
};

const FirstForm = ({ step, profile, setStep, setPayload }) => {
  return (
    <div className="FlowForm">
      <span>
        <strong>Welcome to easyTravel.tech!</strong>
        <br />
        Finishing the Sign Up process will only take a moment.
        <br />
        Please provide your contact information
      </span>
      <Formik
        initialValues={initialSchemaStepOne(profile)}
        validationSchema={validationSchemaStepOne}
        validateOnBlur={false}
        validateOnChange={false}
        enableReinitialize={true}
        onSubmit={(values) => {
          setPayload(values);
          setStep(1);
        }}>
        {({ errors }) => (
          <Form className="FlowForm__formContainer">
            <div className="FlowForm__formContainer__element">
              <div className="FlowForm__formContainer__element__inputLabel">
                <NormalInputField
                  name="first_name"
                  id="first_name"
                  label={"First Name"}
                  highlightErrors={true}
                />
              </div>
            </div>
            <div className="FlowForm__formContainer__element">
              <div className="FlowForm__formContainer__element__inputLabel">
                <NormalInputField
                  name="last_name"
                  id="last_name"
                  label={"Last Name"}
                  highlightErrors={true}
                />
              </div>
            </div>
            <div className="FlowForm__formContainer__element">
              <NormalInputField
                name="user_email"
                id="user_email"
                label="Contact Email"
                highlightErrors={true}
              />
            </div>
            <div className="FlowForm__formContainer__element">
              <div
                className="FlowForm__formContainer__element__submitBtnCntr"
                data-step-one={step === 0 ? "true" : "false"}>
                <Button
                  id="next_form_btn"
                  size="sm"
                  appearance="primary"
                  block
                  type="submit">
                  {">"}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
FirstForm.propTypes = {
  step: PropTypes.number,
  profile: PropTypes.func.isRequired,
  setStep: PropTypes.func,
  setPayload: PropTypes.func,
};

const SignUpFlow = () => {
  const [imgUrl, setImgUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [payload, setPayload] = useState({});

  useEffect(() => {
    const bg =
      "https://sisistaticassets.s3.ap-southeast-1.amazonaws.com/assets/signup/step+bg.jpg";
    const tempImg = new Image();
    tempImg.src = bg;

    if (!loading) {
      tempImg.onload = () => {
        setImgUrl(bg);
        setLoading(true);
      };
    }
  }, []);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ["signupMemberFlow"],
    queryFn: () => fetchCompanyProfile(),
    refetchOnWindowFocus: false,
  });
  const profile = data;
  const profileLoading = isLoading || isFetching;

  return (
    <div className="SignInAndUp">
      <img className="SignInAndUp__backgroundImg" src={imgUrl} alt="" />
      {profileLoading ? (
        <Loader center size="lg" />
      ) : (
        <div className="SignInAndUp__container">
          <img className="SignInAndUp__container__logo" src={LOGO} />
          {step === 1 && (
            <div
              className="SignInAndUp__container__backBtn"
              onClick={() => setStep(0)}>
              {"<"}
            </div>
          )}
          <div className="SignInAndUp__container__formContainer">
            <div className="SignInAndUp__container__formContainer__header">
              {step === 0 && "Step 1/2: Contact Information"}
              {step === 1 && "Step 2/2: Company Information"}
            </div>
            {step === 0 ? (
              <FirstForm
                step={step}
                profile={profile}
                setStep={setStep}
                setPayload={setPayload}
              />
            ) : (
              step === 1 && (
                <SecondForm
                  payload={payload}
                  profile={profile}
                  step={step}
                  setStep={setStep}
                  setPayload={setPayload}
                />
              )
            )}
          </div>
          <div className="SignInAndUp__container__bottomIconsCntr">
            <span
              className="SignInAndUp__container__bottomIconsCntr__icon"
              data-active-tab={step === 0 ? "true" : "false"}></span>
            <span
              className="SignInAndUp__container__bottomIconsCntr__icon"
              data-active-tab={step === 1 ? "true" : "false"}></span>
          </div>
        </div>
      )}
    </div>
  );
};
SignUpFlow.propTypes = {
  intl: PropTypes.object,
  changeLang: PropTypes.func,
};
const mapDispatchToProps = (dispatch) => {
  return {
    changeLang: (lang) => {
      dispatch(changeLang({ lang }));
    },
  };
};
export default connect(
  null,
  mapDispatchToProps
)(injectIntl(withRouter(SignUpFlow)));
