import _ from "lodash";
import { Button, Loader, Rate } from "rsuite";
import { modalGenericStyles, variables } from "@src/jsssetup";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { Form, Formik } from "formik";
import {
  NormalDatePicker,
  NormalInputField,
  NormalSelectField,
} from "@src/components/forms";

import { connect, useSelector } from "react-redux";
import {
  getSetupFormDataSelector,
  requiredRoomsNumberSelector,
} from "@src/selectors/Project/TripPlanner";
import { adhocPointStyles } from "./products/addhoc/coach";
import { DateTime } from "luxon";
import { v4 } from "uuid";
import { fetchPricedAccContract } from "@src/api";
import { adhocCurrencyOptions } from "./products/addhoc";
import { addCustomAccSrv } from "@src/actions/Project/TripPlanner/custom/accommodation";
import { toast } from "react-toastify";

const boardOptions = [
  ["", "------"],
  ["RO", "Room Only"],
  ["BB", "Bed & Breakfast"],
  ["HB", "Half Board"],
  ["FB", "Full Board"],
  ["AI", "All Inclusive"],
];

const beddingOptions = [
  ["", "------"],
  ["single", "Single"],
  ["double", "Double"],
  ["double_for_single_use", "Double For Single Use"],
  ["twin", "Twin"],
  ["triple", "Triple"],
  ["quadruple", "Quadruple"],
];

const styles = createUseStyles({
  CustomAccSrvModal: modalGenericStyles.modal,
  card: modalGenericStyles.card,
  header: modalGenericStyles.cardHeader,
  body: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    gridGap: variables.normal_gap,
    gridAutoRows: "max-content",
  },
  requiredDays: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
  },
  day: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    gridTemplateColumns: "auto 1fr",
  },
  actions: modalGenericStyles.cardActions,
  imagesContainer: (props) => ({
    display: "grid",
    gridTemplateColumns: `repeat(${props.imgsLength}, 1fr)`,
    gridGap: variables.normal_gap,
    overflowX: "auto",
  }),
  img: {
    borderRadius: variables.normal_gap,
    height: "10rem",
  },
  col2: {
    display: "grid",
    gridTemplateColumns: "repeat(2 ,1fr)",
    gridGap: variables.normal_gap,
  },
  point: adhocPointStyles,
  roomsHeader: {
    borderBottom: `2px solid black`,
    fontSize: "medium",
    fontWeight: "bold",
    display: "grid",
    gridTemplateColumns: "1fr auto",
    padding: `calc(${variables.normal_gap} / 2)`,
  },
  roomsHeaderActions: {
    display: "grid",
    gridAutoFlow: "column",
    gridGap: `calc(${variables.normal_gap} / 2)`,
  },
  room: {
    padding: variables.normal_gap,
    borderRadius: `calc(${variables.normal_gap} / 2)`,
    border: "2px solid black",
    gridGap: variables.normal_gap,
    display: "grid",
  },
  roomHeader: {
    display: "grid",
    gridTemplateColumns: "1fr auto",
    alignItems: "center",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    borderBottom: "2px solid black",
  },
  roomActions: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    justifyItems: "end",
  },
  roomAttrs: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridGap: `calc(${variables.normal_gap} / 2)`,
  },
  extSearcher: {
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateColumns: "1fr max-content",
    alignItems: "end",
  },
  results: {
    display: "Grid",
    gridTemplateColumns: "1fr 4fr",
    overflow: "hidden",
  },
  filters: {
    borderRight: `2px solid ${variables.colors.lightBorderFillColor}`,
    display: "grid",
    gridGap: variables.normal_gap,
    padding: variables.normal_gap,
    gridAutoRows: "max-content",
  },
  filterActions: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: `calc(${variables.normal_gap} / 2)`,
  },
  hotels: {
    display: "grid",
    gridGap: variables.normal_gap,
    maxHeight: "80vh",
    overflow: "auto",
    padding: variables.normal_gap,
  },
  hotel: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    padding: variables.normal_gap,
    boxShadow: variables.shadows.normalShadow,
    borderRadius: "5px",
  },
  hotelHeader: {
    display: "grid",
    gridTemplateColumns: "1fr auto",
    fontSize: "medium",
    fontWeight: "bold",
  },
  hotelAddress: {
    fontSize: "small",
  },
  roomsHeader: {
    fontWeight: "bold",
    borderBottom: "2px solid black",
  },
  rooms: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
  },
});
const CustomAccSrvModal = (props) => {
  const {
    destination,
    adults,
    childs,
    rooms_number,
    nationality,
    onCancel,
    onAdd,
  } = props;

  const { type, id } = destination;
  const destination_uid = `${type}__${id}`;

  const paxRooms = useSelector((state) => state.tripPlannerAccPax);

  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hotelData, setHotelData] = useState({});
  const [hotels, setHotels] = useState([]);
  const [filters, setFilters] = useState({});

  const classes = styles({ imgsLength: 0 });

  async function accContractFetcher({
    ext_id,
    check_in,
    check_out,
    search_mode,
    destination_uid,
  }) {
    setLoading(true);
    const payload = {
      check_in,
      check_out,
      nationality,
      rooms: paxRooms.map((r) => _.omit(r, "uid")),
    };

    if (search_mode === "by_contract") {
      payload["ext_ids"] = [ext_id];
    } else {
      payload["destination_uid"] = destination_uid;
    }

    const result = await fetchPricedAccContract(payload);

    const htls = _.get(result, "results", []);

    if (!htls.length) {
      setLoading(false);
      toast.warning("We couldn't find available prices for this contract", {
        duration: 5000,
      });
      return;
    }

    setHotels(htls.map((htl) => ({ ...htl, check_in, check_out })));

    setStep((p) => p + 1);
  }

  const filteredHotels = hotels.filter((htl) => {
    if (
      filters.rating &&
      htl.metadata.rating.toString() !== filters.rating.toString()
    ) {
      return false;
    }

    if (
      filters.name &&
      !htl.metadata.name.toLowerCase().includes(filters.name.toLowerCase())
    ) {
      return false;
    }

    if (
      filters.ext_accommodation_id &&
      !htl.ext_accommodation_id
        .toLowerCase()
        .includes(filters.ext_accommodation_id.toLowerCase())
    ) {
      return false;
    }

    return true;
  });

  return (
    <div className={classes.CustomAccSrvModal}>
      <div className={classes.card}>
        <div className={classes.header}>
          <h5>
            Add Custom Accommodation Service: {adults}{" "}
            {adults > 1 ? "Adults" : "Adult"}, {childs}{" "}
            {childs > 1 ? "Children" : "Child"}, {rooms_number} Room
            {rooms_number > 1 ? "s" : ""}
          </h5>
        </div>
        {step === 1 ? (
          <Formik
            enableReinitialize={true}
            initialValues={{
              ext_id: "",
              check_in: destination.checkIn,
              check_out: destination.checkOut,
              search_mode: "by_destination",
              destination_uid,
            }}
            onSubmit={(values) => {
              accContractFetcher({
                ext_id: values.ext_id,
                check_in: DateTime.fromISO(values.check_in).toISODate(),
                check_out: DateTime.fromISO(values.check_out).toISODate(),
                search_mode: values.search_mode,
                destination_uid: values.destination_uid,
              });
            }}>
            {({ values }) => (
              <Form className={classes.filters}>
                <div className={classes.body}>
                  {loading && <Loader size="lg" backdrop={true} />}
                  <div className={classes.col2}>
                    <NormalDatePicker name="check_in" label="Check In" />
                    <NormalDatePicker name="check_out" label="Check Out" />
                  </div>
                  <NormalSelectField
                    name="search_mode"
                    label="Search Mode"
                    options={[
                      ["by_contract", "By Contract"],
                      ["by_destination", "By Destination"],
                    ]}
                  />
                  {values.search_mode === "by_contract" && (
                    <NormalInputField name="ext_id" label="Contract Id" />
                  )}
                </div>
                <div className={classes.actions}>
                  <Button appearance="ghost" onClick={onCancel}>
                    <strong>Cancel</strong>
                  </Button>
                  <Button appearance="primary" type="submit">
                    <strong>Next</strong>
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        ) : step === 2 ? (
          <React.Fragment>
            <div className={classes.results}>
              <Formik
                initialValues={{
                  rating: "",
                  name: "",
                  ext_accommodation_id: "",
                }}
                onSubmit={(values) => {
                  setFilters(values);
                }}>
                {({ resetForm, submitForm }) => (
                  <Form className={classes.filters}>
                    <NormalInputField name="name" label="Hotel Name" />
                    <NormalInputField
                      name="ext_accommodation_id"
                      label="Hotel Id"
                    />
                    <NormalSelectField
                      name="rating"
                      label="Rating"
                      options={[
                        ["", "------"],
                        ["1", "1 Star"],
                        ["2", "2 Stars"],
                        ["3", "3 Stars"],
                        ["4", "4 Stars"],
                        ["5", "5 Stars"],
                      ]}
                    />
                    <div className={classes.filterActions}>
                      <Button
                        appearance="ghost"
                        onClick={() => {
                          resetForm();
                          submitForm();
                        }}>
                        <strong>Reset</strong>
                      </Button>
                      <Button color="blue" type="submit">
                        <strong>Filter</strong>
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
              <div className={classes.hotels}>
                {filteredHotels.map((htl, idx) => {
                  return (
                    <div key={idx} className={classes.hotel}>
                      <span className={classes.hotelHeader}>
                        {_.get(htl, "metadata.name")} (
                        {htl.ext_accommodation_id}){" "}
                        <Rate
                          value={_.get(htl, "metadata.rating")}
                          size="xs"
                          readOnly={true}
                        />
                      </span>
                      <span className={classes.hotelAddress}>
                        Address: {_.get(htl, "metadata.address")}
                      </span>
                      <span className={classes.roomsHeader}>Rooms</span>
                      <Formik initialValues={{ hotel: htl }}>
                        {({ values }) => (
                          <Form className={classes.rooms}>
                            {values.hotel.rooms.map((room, idx) => (
                              <div key={idx} className={classes.room}>
                                <div className={classes.roomHeader}>
                                  <strong>
                                    {idx + 1}. {room.title}
                                  </strong>
                                </div>
                                <div className={classes.roomAttrs}>
                                  <NormalSelectField
                                    label="Board"
                                    name={`hotel.rooms.${idx}.board`}
                                    options={boardOptions}
                                  />
                                  <NormalSelectField
                                    label="Bedding"
                                    name={`hotel.rooms.${idx}.bedding`}
                                    options={beddingOptions}
                                  />
                                  <NormalInputField
                                    type="number"
                                    label="Price"
                                    name={`hotel.rooms.${idx}.price.amount`}
                                  />
                                  <NormalSelectField
                                    name={`hotel.rooms.${idx}.price.currency`}
                                    label="Currency"
                                    options={adhocCurrencyOptions}
                                  />
                                </div>
                                <div className={classes.roomActions}>
                                  <Button
                                    color="green"
                                    size="xs"
                                    onClick={() => {
                                      const hotel = _.cloneDeep(values.hotel);
                                      const total_price = _.get(
                                        hotel,
                                        "rooms",
                                        []
                                      ).reduce((a, b) => a + b.price.amount, 0);
                                      const currency = _.get(
                                        hotel,
                                        "rooms.0.price.currency"
                                      );

                                      hotel.rooms = hotel.rooms.filter(
                                        (pr, ridx) => ridx === idx
                                      );

                                      const srv = {
                                        ...hotel,
                                        total_price,
                                        currency,
                                        destOrder: destination.order,
                                        uid: v4(),
                                      };
                                      onAdd({ srv });
                                      onCancel();
                                    }}>
                                    <strong>Select Room</strong>
                                  </Button>
                                </div>
                              </div>
                            ))}
                          </Form>
                        )}
                      </Formik>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={classes.actions}>
              <Button appearance="ghost" onClick={onCancel}>
                <strong>Cancel</strong>
              </Button>
              <Button appearance="ghost" onClick={() => setStep((p) => p - 1)}>
                <strong>Back</strong>
              </Button>
            </div>
          </React.Fragment>
        ) : null}
      </div>
    </div>
  );
};
CustomAccSrvModal.defaultProps = {
  rooms_number: 0,
  adults: 1,
  childs: 0,
};
CustomAccSrvModal.propTypes = {
  nationality: PropTypes.string.isRequired,
  adults: PropTypes.number.isRequired,
  childs: PropTypes.number.isRequired,
  destination: PropTypes.object.isRequired,
  rooms_number: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  const options = getSetupFormDataSelector(state);
  const { adults, children: childs, nationality } = options;
  const rooms_number = requiredRoomsNumberSelector(state);
  return { adults, childs, rooms_number, nationality };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onAdd: ({ srv }) => dispatch(addCustomAccSrv({ srv })),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomAccSrvModal);
