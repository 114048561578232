import { getFerryServicesSelector } from "@src/selectors/Project/TripPlanner/addhoc/transportation";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { FerrySrvData } from "../products/addhoc/ferry";

export const FerrySrvReservations = ({ reservations }) => {
  const regular_coords = useSelector((state) =>
    getFerryServicesSelector(state, { booking_mode: "regular" })
  );
  const option_coords = useSelector((state) =>
    getFerryServicesSelector(state, { booking_mode: "option" })
  );

  const for_reservation_srvs = [...regular_coords, ...option_coords];

  return !reservations.length ? null : (
    <div>
      <h5>Ferry Services</h5>
      {reservations.map((res, idx) => {
        const srv = for_reservation_srvs.find(
          (srv) => srv.service.uid === res.search_uid
        );

        return (
          <FerrySrvData
            key={idx}
            service={srv.service}
            reservationMode={true}
            reservationLoading={res.status === "PE"}
            reservationStatus={res.status}
          />
        );
      })}
    </div>
  );
};

FerrySrvReservations.defaultProps = {
  reservations: [],
};

FerrySrvReservations.propTypes = {
  reservations: PropTypes.array.isRequired,
};
