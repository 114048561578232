import _ from "lodash";
import { createSelector } from "reselect";
import {
  getSrvsUidForBooking,
  getSrvsUidForOptionBooking,
} from "../prebook/selected_services";

const getActivities = (state) => state.tripPlannerDbDActivityReducer;

const getActivitiesList = (state, props = {}) => {
  const booking_mode = props?.booking_mode;
  var refundable = props?.refundable;

  var restrictedToServices = [];
  if (booking_mode === "regular") {
    restrictedToServices = getSrvsUidForBooking(state, {
      srv_type: "ACT",
    });
  } else if (booking_mode === "option") {
    refundable = true;
    restrictedToServices = getSrvsUidForOptionBooking(state, {
      srv_type: "ACT",
    });
  }
  if (booking_mode && restrictedToServices.length === 0) {
    return [];
  }

  const tmp = Object.entries(state.tripPlannerDbDActivityReducer);
  var activitiesList = _.flatten(
    tmp.map((item) => {
      const [date, activities] = item;
      return activities.map((activity) => {
        return { ...activity, date };
      });
    })
  );
  if (restrictedToServices.length > 0) {
    const restrictedUids = restrictedToServices.map((srv) => srv.uid);
    activitiesList = activitiesList.filter((activity) =>
      restrictedUids.includes(activity.uid)
    );
    if (refundable) {
      activitiesList = activitiesList.filter((srv) => srv.free_cancellation);
    }
  }

  return activitiesList;
};

const getActivitiesByDate = (state, { date }) =>
  state.tripPlannerDbDActivityReducer[date].map((activity) => {
    return { ...activity, date };
  });

const getActivitiesByDestOrder = (state, { destOrder }) => {
  const activities = getActivitiesList(state);
  return activities.filter(
    (activity) => activity.destOrder.toString() === destOrder.toString()
  );
};

export const getTripActivitiesSelector = createSelector(
  [getActivities],
  (activities) => {
    return activities;
  }
);

export const getTripActivitiesListSelector = createSelector(
  [getActivitiesList],
  (activities) => {
    return activities;
  }
);

export const getTripActivitiesByDateSelector = createSelector(
  [getActivitiesByDate],
  (activities) => {
    return activities;
  }
);

export const getTripActivitiesByDestOrderSelector = createSelector(
  [getActivitiesByDestOrder],
  (activities) => {
    return activities;
  }
);
