import {
  ACCOUNT_STATEMENT_INIT,
  // ==================== DEPOSIT BALANCE ACTIONS ====================
  ACCOUNT_STATEMENT_FETCHING_DEPOSIT_BALANCE,
  ACCOUNT_STATEMENT_FETCHED_DEPOSIT_BALANCE,
  ACCOUNT_STATEMENT_SET_DEPOSIT_BALANCE,
  // ======================== DEPOSIT ENTRIES ========================
  ACCOUNT_STATEMENT_SET_DEPOSIT_ENTRIES,
  ACCOUNT_STATEMENT_FETCHING_DEPOSIT_ENTRIES,
  ACCOUNT_STATEMENT_FETCHED_DEPOSIT_ENTRIES,
  // ========================= DEPOSIT MODAL =========================
  ACCOUNT_STATEMENT_SHOW_DEPOSIT_MODAL,
  ACCOUNT_STATEMENT_HIDE_DEPOSIT_MODAL,
  ACCOUNT_STATEMENT_IDLE_DEPOSIT_MODAL,
  ACCOUNT_STATEMENT_LOADING_DEPOSIT_MODAL,
  //========================= GRAPH DATA =============================
  ACCOUNT_STATEMENT_STAT_DATA,
  ACCOUNT_STATEMENT_VIEW_TYPE,
  ACCOUNT_STATEMENT_INIT_VIEW_TYPE,
  //========================= FILTERS ================================
  ACCOUNT_STATEMENT_INITIALIZE_LIST_FILTER_FORM,
  ACCOUNT_STATEMENT_UPDATE_LIST_FILTER_FORM,
  ACCOUNT_STATEMENT_APPLY_LIST_FILTER,
  ACCOUNT_STATEMENT_SHOW_SUB_ENTITY_DEPOSIT_MODAL,
  ACCOUNT_STATEMENT_HIDE_SUB_ENTITY_DEPOSIT_MODAL,
  ACCOUNT_STATEMENT_LOADING_SUB_ENTITY_DEPOSIT_MODAL,
  ACCOUNT_STATEMENT_IDLE_SUB_ENTITY_DEPOSIT_MODAL,
} from "@src/actions/ManagementConsole/AccountStatement/types";
import { initialAccountStatementFilterForm } from "@src/forms/ManagementConsole/AccountStatement";

import { formProcessor } from "@src/reducers/tools";
import update from "immutability-helper";

export const initialFetchAccountStatementStatus = "IDLE";
export const fetchAccountStatementStatus = (
  state = initialFetchAccountStatementStatus,
  action
) => {
  switch (action.type) {
    case ACCOUNT_STATEMENT_INIT:
      return "IDLE";
    case ACCOUNT_STATEMENT_FETCHING_DEPOSIT_ENTRIES:
      return "FETCHING";
    case ACCOUNT_STATEMENT_FETCHED_DEPOSIT_ENTRIES:
      return "FETCHED";
    default:
      return state;
  }
};

export const initialList = {
  count: 0,
  next: null,
  previous: null,
  details: [],
  page: 1,
  limit: 20,
  displayLength: 100,
};
export const accountStatementData = (state = initialList, action) => {
  switch (action.type) {
    case ACCOUNT_STATEMENT_INIT:
      return initialList;
    case ACCOUNT_STATEMENT_SET_DEPOSIT_ENTRIES:
      return action.data;
    case ACCOUNT_STATEMENT_INITIALIZE_LIST_FILTER_FORM:
      return { ...state, page: 1 };
    default:
      return state;
  }
};

export const accountStatementDepositBalanceInitial = {
  balance: 0,
  total_debit: 0,
  total_credit: 0,
  currency: {},
  loading: false,
};
export const accountStatementDepositBalance = (
  state = accountStatementDepositBalanceInitial,
  action
) => {
  switch (action.type) {
    case ACCOUNT_STATEMENT_INIT:
      return accountStatementDepositBalanceInitial;
    case ACCOUNT_STATEMENT_FETCHING_DEPOSIT_BALANCE:
      return update(state, { loading: { $set: true } });
    case ACCOUNT_STATEMENT_FETCHED_DEPOSIT_BALANCE:
      return update(state, { loading: { $set: false } });
    case ACCOUNT_STATEMENT_SET_DEPOSIT_BALANCE: {
      const { balance, total_debit, total_credit, currency } = action;
      return update(state, {
        balance: { $set: balance },
        total_credit: { $set: total_credit },
        total_debit: { $set: total_debit },
        currency: { $set: currency },
      });
    }
    default:
      return state;
  }
};

// ========================== DEPOSIT MODAL ==========================
export const accountStatementDepositModalInitial = {
  show: false,
  loading: false,
  mode: "",
};
export const accountStatementDepositModal = (
  state = accountStatementDepositModalInitial,
  action
) => {
  switch (action.type) {
    case ACCOUNT_STATEMENT_INIT:
      return accountStatementDepositModalInitial;
    case ACCOUNT_STATEMENT_SHOW_DEPOSIT_MODAL:
      return update(state, {
        show: { $set: true },
        mode: { $set: action.mode },
      });
    case ACCOUNT_STATEMENT_HIDE_DEPOSIT_MODAL:
      return accountStatementDepositModalInitial;
    case ACCOUNT_STATEMENT_IDLE_DEPOSIT_MODAL:
      return update(state, { loading: { $set: false } });
    case ACCOUNT_STATEMENT_LOADING_DEPOSIT_MODAL:
      return update(state, { loading: { $set: true } });
    default:
      return state;
  }
};

const subEntityDepositModalInitial = { show: false, loading: false };
export const subEntityDepositModal = (
  state = subEntityDepositModalInitial,
  action
) => {
  switch (action.type) {
    case ACCOUNT_STATEMENT_SHOW_SUB_ENTITY_DEPOSIT_MODAL:
      return { ...state, show: true };
    case ACCOUNT_STATEMENT_HIDE_SUB_ENTITY_DEPOSIT_MODAL:
      return subEntityDepositModalInitial;
    case ACCOUNT_STATEMENT_LOADING_SUB_ENTITY_DEPOSIT_MODAL:
      return { ...state, loading: true };
    case ACCOUNT_STATEMENT_IDLE_SUB_ENTITY_DEPOSIT_MODAL:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const accountStatementGraphData = (state = {}, action) => {
  switch (action.type) {
    case ACCOUNT_STATEMENT_STAT_DATA:
      return action.data;
    default:
      return state;
  }
};

export const initType = "current";

export const accountStatementViewType = (state = initType, action) => {
  switch (action.type) {
    case ACCOUNT_STATEMENT_INIT_VIEW_TYPE:
      return initType;
    case ACCOUNT_STATEMENT_VIEW_TYPE:
      return action.data;
    default:
      return state;
  }
};

//===============================FILTERS=================================
export const accountStatementListFilterForm = (
  state = initialAccountStatementFilterForm,
  action
) => {
  switch (action.type) {
    case ACCOUNT_STATEMENT_INITIALIZE_LIST_FILTER_FORM: {
      return action.data;
    }
    case ACCOUNT_STATEMENT_UPDATE_LIST_FILTER_FORM:
      return formProcessor(
        state,
        action.data.type,
        action.data.name,
        action.data.value
      );
    default:
      return state;
  }
};

export const accountStatementListFilter = (
  state = initialAccountStatementFilterForm,
  action
) => {
  switch (action.type) {
    case ACCOUNT_STATEMENT_APPLY_LIST_FILTER:
      return action.data;
    default:
      return state;
  }
};
