import { commonList } from "@src/reducers/common";
import { B2C_ADDONS_LIST_GENERIC_CHANGE } from "../../../../actions/Operation/Reservations/SingleServices/Addons/types";

const B2CAddonsListReducerInitial = {
  ...commonList,
  displayLength: 100,
  filters: {
    market_type: "B2C",
    reference: "",
    external_reference: "",
    title: "",
    destination: "",
    pax: "",
    operation_date__gte: "",
    operation_date__lte: "",
    guest_lead__icontains: "",
    external_creator__icontains: "",
    created__gte: "",
    created__lte: "",
    creator_email: "",
    status: "",
    page: 1,
    limit: 20,
    ordering: "",
    tags: "",
  },
};

export const B2CAddonsListReducer = (
  state = B2CAddonsListReducerInitial,
  action
) => {
  switch (action.type) {
    case B2C_ADDONS_LIST_GENERIC_CHANGE: {
      const { filters } = action;
      return { ...state, filters: { ...state.filters, ...filters } };
    }
    default:
      return state;
  }
};
