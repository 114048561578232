import React from "react";
import { createUseStyles } from "react-jss";
import { GeneralStats } from "../GeneralStats";
import CreatePieChart from "../../ChartComponents/CreatePieChart";
import { variables } from "@src/jsssetup";
import PropTypes from "prop-types";
import TransTxt from "@src/components/common/SxFormatMessage";
import _ from "lodash";
import { injectIntl } from "react-intl";

const salesReportTable = createUseStyles({
  SalesReportTable: {
    display: "grid",
    gridTemplateRows: "max-content",
  },
  statsTable: {
    display: "grid",
    justifyItems: "center",
  },
  tableContainer: {
    display: "grid",
    border: `2px solid #FF6600`,
    borderRadius: "10px",
    textAlign: "center",
  },
  table: {
    width: "100%",
  },
  tHead: {
    backgroundColor: "#FF6600",
  },
  headRow: {
    "background": "#FF6600",
    "& th:first-child": {
      borderTopLeftRadius: "10%",
    },
    "& th:last-child": {
      borderTopRightRadius: "10%",
    },
  },
  headCell: {
    backgroundColor: "#FF6600",
    textAlign: "center",
    color: "white",
    padding: variables.half_gap,
    fontFamily: "futura,sans-serif",
    fontWeight: "400",
  },
  tableCell: {
    textAlign: "center",
    padding: `${variables.half_gap} ${variables.normal_gap}`,
    fontFamily: "futura,sans-serif",
    height: "2rem",
  },
});

const SalesReportTable = ({ data }) => {
  const classes = salesReportTable();

  return (
    <div className={classes.SalesReportTable}>
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead className={classes.tHead}>
            <tr className={classes.headRow}>
              <th className={classes.headCell}>
                {" "}
                <TransTxt id="B2B_stats_ACT_top_sales__count" />
              </th>
              <th className={classes.headCell}>
                <TransTxt id="B2B_stats_ACT_top_sales__pax_per_booking" />
              </th>
              <th className={classes.headCell}>
                <TransTxt id="B2B_stats_ACT_top_sales__avg_price_per_room" />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={classes.tableCell}>
                {_.get(data, "top_sales.count") !== 0
                  ? _.get(data, "top_sales.count") || "N/A"
                  : "0"}
              </td>
              <td className={classes.tableCell}>
                {_.get(data, "top_sales.pax_per_booking") !== 0
                  ? Math.round(_.get(data, "top_sales.pax_per_booking")) ||
                    "N/A"
                  : "0"}
              </td>
              <td className={classes.tableCell}>
                {_.get(data, "top_sales.room_average_price.avg") !== 0
                  ? _.get(data, "top_sales.room_average_price.currency")
                  : ""}{" "}
                {_.get(data, "top_sales.room_average_price.avg") !== 0
                  ? _.get(data, "top_sales.room_average_price.avg") || "N/A"
                  : "0"}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
SalesReportTable.propTypes = {
  data: PropTypes.object.isRequired,
};

const accTopSales = createUseStyles({
  ACCTopSales: {
    display: "grid",
    padding: variables.normal_gap,
  },
  statsTable: {
    display: "grid",
    justifyItems: "center",
  },
  charts: {
    display: "grid",
    gridTemplateColumns: "20fr 1fr 20fr ",
    justifyItems: "center",
  },
  chart1: {
    display: "grid",
  },
  chart2: {
    display: "grid",
  },
  chart3: {
    display: "grid",
  },
  divider: {
    justifySelf: "center",
    alignSelf: "center",
    height: "85%",
    border: `1px solid rgba(0, 0, 0, 0.1)`,
    gridColumn: "1 span",
    width: "0%",
  },
});

var ACCTopSales = ({ intl, data }) => {
  const classes = accTopSales();

  const top5ActivitiesData = _.get(data, "top_sales.top_activities.items").map(
    ([date, value]) => ({
      name: date,
      value,
    })
  );
  const top5ActivitiesTotal = top5ActivitiesData.reduce(
    (acc, dt) => acc + dt.value,
    0
  );
  const top5ActivitiesPercentages = top5ActivitiesData.map((dest) => ({
    name: dest.name,
    value: ((dest.value / top5ActivitiesTotal) * 100).toFixed(2),
  }));
  const top5ActivitiesColors = [
    "#FF6600",
    "#B174FF",
    "#B8B8B8",
    "#FFB381",
    "#B0754E",
  ];

  const top5POIData = _.get(data, "top_sales.top_hotels.items", []).map(
    ([name, value]) => ({
      name,
      value,
    })
  );

  const top5HotelsTotal = top5POIData.reduce((acc, dt) => acc + dt.value, 0);
  const top5POIPercentages = top5POIData.map((dt) => ({
    name: dt.name,
    value: ((dt.value / top5HotelsTotal) * 100).toFixed(2),
  }));

  const top5POIColors = ["#FF6600", "#B174FF", "#B8B8B8", "#B0754E", "#FFB381"];

  //   const topBookingData = _.get(data, "top_sales.top_booking.items").map(
  //     ([date, value]) => ({
  //       name: date,
  //       value,
  //     })
  //   );
  //   const topBookingsTotal = topBookingData.reduce(
  //     (acc, sale) => acc + sale.value,
  //     0
  //   );
  //   const topBookingsPercentages = top5HotelsData.map((sale) => ({
  //     name: sale.name,
  //     value: ((sale.value / topBookingsTotal) * 100).toFixed(2),
  //   }));
  //   const bookingPerAccRatingColors = [
  //     "#B0754E",
  //     "#FFB381",
  //     "#B8B8B8",
  //     "#B174FF",
  //     "#FF6600",
  //   ];

  return (
    <div className={classes.ACCTopSales}>
      <div className={classes.stats}>
        <GeneralStats
          title={intl.formatMessage({
            id: "B2B_stats_ACT_top_sales__confirmed_reservations",
          })}
          tableTitle={intl.formatMessage({
            id: "B2B_stats_ACT_top_sales__top_sales",
          })}
          startDate={_.get(data, "period.start_date")}
          endDate={_.get(data, "period.end_date")}
        />
        <div className={classes.statsTable}>
          <SalesReportTable data={data} />
        </div>
      </div>
      <div className={classes.charts}>
        <div className={classes.chart1}>
          <CreatePieChart
            title={intl.formatMessage({
              id: "B2B_stats_ACT_top_sales__top__5_activities",
            })}
            bottom={75}
            colors={top5ActivitiesColors}
            data={top5ActivitiesPercentages}
          />
        </div>
        <div className={classes.divider}></div>
        <div className={classes.chart2}>
          <CreatePieChart
            title={intl.formatMessage({
              id: "B2B_stats_ACT_top_sales__top_5_poi",
            })}
            bottom={75}
            colors={top5POIColors}
            data={top5POIPercentages}
          />
        </div>
        {/* <div className={classes.divider}></div> */}
        {/* <div className={classes.chart3}>
          <CreatePieChart
            title="Booking per ACC Rating %"
            bottom={75}
            colors={bookingPerAccRatingColors}
            data={topBookingsPercentages}
          />
        </div> */}
      </div>
    </div>
  );
};
ACCTopSales.propTypes = {
  data: PropTypes.object.isRequired,
  intl: PropTypes.object,
  dashboardPayload: PropTypes.object.isRequired,
};
ACCTopSales = injectIntl(ACCTopSales);
export default ACCTopSales;
