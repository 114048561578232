import _ from "lodash";
import { StyleSheet, Text, View, Link, Font } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import React from "react";
import { easyBasicColor } from "../PreviewPDF";
import Divider from "./Divider";
import { formatPrice } from "../helpers";
import { currencies } from "@src/definitions";
import NotoSansRegular from "@src/style/fonts/NotoSans-Regular.ttf";
import NotoSansBold from "@src/style/fonts/NotoSans-Bold.ttf";

Font.register({
  family: "Noto Sans",
  fonts: [
    { src: NotoSansRegular, fontWeight: "normal" },
    { src: NotoSansBold, fontWeight: "bold" },
  ],
});

const itemsTableStyles = StyleSheet.create({
  ItemsTable: { fontSize: 10, fontFamily: "Noto Sans", fontWeight: "bold" },
  headers: { flexDirection: "row", justifyContent: "space-between" },
  header: {},
  divider: { height: 1, width: "100%", marginVertical: 5 },
  descriptionContr: {},
  descriptionDates: { fontSize: 10, fontFamily: "Noto Sans" },
  value: { fontSize: 10, fontFamily: "Noto Sans" },
  sumsContr: { flexDirection: "row" },
  sums: { width: "50%" },
  labelValueRow: {
    flexDirection: "row",
    justifyContent: "flex-end",
    fontSize: 10,
    paddingBottom: 5,
  },
  normalText: { fontSize: 10, fontFamily: "Noto Sans" },
  boldText: { fontSize: 10, fontFamily: "Noto Sans", fontWeight: "bold" },
  classicButton: {
    backgroundColor: "#007BFF",
    color: "white",
    padding: 8,
    borderRadius: 4,
    textAlign: "center",
    cursor: "pointer",
    fontWeight: "bold",
    width: 80,
  },
  textLabel: { color: "white", textDecoration: "none" },
});
const ItemsTable = ({ data, entity_colors }) => {
  const headerStyle = {
    ...itemsTableStyles.header,
    color: entity_colors?.text?.colored ?? easyBasicColor,
  };

  const valuesStyle = { ...itemsTableStyles.headers, paddingBottom: 10 };

  const payBtn = {
    ...itemsTableStyles.classicButton,
    backgroundColor: entity_colors?.text?.colored ?? easyBasicColor,
  };

  const totalAmount = data.items
    ? data.items.reduce((acc, item) => acc + item.quantity * item.unitPrice, 0)
    : "";

  return (
    <View style={itemsTableStyles.ItemsTable}>
      <Text style={itemsTableStyles.boldText}>
        {data.guests ? "Guests" : ""}
      </Text>
      {(data?.guests || []).map((guest, idx) => (
        <View style={valuesStyle} key={idx}>
          <View style={{ ...itemsTableStyles.descriptionContr, width: "50%" }}>
            <Text style={itemsTableStyles.value}>
              {guest.firstName} {guest.lastName}
            </Text>
          </View>
          <View>
            <Text style={{ ...itemsTableStyles.value, width: "30%" }}>
              ({guest.description})
            </Text>
          </View>
          <Text
            style={{
              ...itemsTableStyles.value,
              width: "17%",
              textAlign: "right",
            }}>
            {guest.price &&
              currencies[data.currency] + formatPrice(guest.price)}
          </Text>
        </View>
      ))}
      <View style={itemsTableStyles.headers}>
        <Text style={{ ...headerStyle, width: "51%" }}>Description</Text>
        <Text style={{ ...headerStyle, width: "17%" }}>Qty</Text>
        <Text style={{ ...headerStyle, width: "17%" }}>Unit price</Text>
        <Text style={{ ...headerStyle, width: "17%", textAlign: "right" }}>
          Amount
        </Text>
      </View>
      <Divider color={entity_colors?.text?.colored ?? easyBasicColor} />
      {(data?.items || []).map((item, idx) => (
        <View style={valuesStyle} key={idx}>
          <View style={{ ...itemsTableStyles.descriptionContr, width: "51%" }}>
            <Text style={itemsTableStyles.value}>{item.name}</Text>
          </View>
          <Text style={{ ...itemsTableStyles.value, width: "17%" }}>
            {item.quantity}
          </Text>
          <Text style={{ ...itemsTableStyles.value, width: "17%" }}>
            {currencies[data.currency]}
            {formatPrice(item.unitPrice)}
          </Text>
          <Text
            style={{
              ...itemsTableStyles.value,
              width: "17%",
              textAlign: "right",
            }}>
            {currencies[data.currency]}
            {formatPrice(item.unitPrice * item.quantity)}
          </Text>
        </View>
      ))}
      <View style={itemsTableStyles.sumsContr}>
        <View style={{ width: "50%" }} />
        <View style={itemsTableStyles.sums}>
          <Divider color={entity_colors?.text?.colored ?? easyBasicColor} />
          <View style={itemsTableStyles.labelValueRow}>
            <Text style={itemsTableStyles.boldText}>Total</Text>
            <View style={{ width: 10 }} />
            <Text
              style={{
                ...itemsTableStyles.boldText,
                width: 80,
                textAlign: "right",
              }}>
              {currencies[data.currency]}
              {formatPrice(totalAmount)}
            </Text>
          </View>
          {data.payment_link && (
            <View style={itemsTableStyles.labelValueRow}>
              <Text style={payBtn}>
                <Link
                  style={itemsTableStyles.textLabel}
                  src={data.payment_link}>
                  Pay now
                </Link>
              </Text>
            </View>
          )}
        </View>
      </View>
    </View>
  );
};
ItemsTable.defaultProps = { data: {}, entity_colors: {} };
ItemsTable.propTypes = {
  data: PropTypes.object.isRequired,
  entity_colors: PropTypes.object,
};
export default ItemsTable;
