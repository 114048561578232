import _ from "lodash";
import update from "immutability-helper";
import PropTypes from "prop-types";
import React from "react";

export const pkgContentHoc = (WrappedComponent) => {
  class Comp extends React.Component {
    constructor(props) {
      super(props);
      const { days } = props;

      const itnDays = {};
      days.forEach(
        (day) => (itnDays[day.day] = { description: { en: "" }, images: [] })
      );

      this.state = {
        itineraryDaysData: _.cloneDeep(itnDays),
        pkgTitle: { en: "" },
        pkgSubTitle: { en: "" },
        pkgStyle: { en: "" },
        generalDescription: { en: "" },
      };

      this.handleGenDescChange = this.handleGenDescChange.bind(this);
      this.handlePkgTitleChange = this.handlePkgTitleChange.bind(this);
      this.handlePkgSubTitleChange = this.handlePkgSubTitleChange.bind(this);
      this.handlePkgStyleChange = this.handlePkgStyleChange.bind(this);
      this.handleDayDescChange = this.handleDayDescChange.bind(this);
      this.handleDayImageChange = this.handleDayImageChange.bind(this);
    }
    componentDidMount() {
      const { content } = this.props;
      this.setState((p) => ({ ...p, ...content }));
    }
    handleContentChange() {
      const { onContentChange } = this.props;

      onContentChange((p) => ({
        ...p,
        ..._.pick(this.state, [
          "itineraryDaysData",
          "pkgTitle",
          "pkgSubTitle",
          "pkgStyle",
          "generalDescription",
        ]),
      }));
    }
    handlePkgTitleChange(value) {
      this.setState(
        (p) => ({ ...p, pkgTitle: { ...p.pkgTitle, en: value } }),
        () => this.handleContentChange()
      );
    }
    handlePkgSubTitleChange(value) {
      this.setState(
        (p) => ({
          ...p,
          pkgSubTitle: { ...p.pkgSubTitle, en: value },
        }),
        () => this.handleContentChange()
      );
    }
    handlePkgStyleChange(value) {
      this.setState(
        (p) => ({ ...p, pkgStyle: { ...p.pkgStyle, en: value } }),
        () => this.handleContentChange()
      );
    }
    handleGenDescChange(value) {
      this.setState(
        (p) => ({
          ...p,
          generalDescription: value,
        }),
        () => this.handleContentChange()
      );
    }
    handleDayDescChange(day, value) {
      this.setState(
        (p) =>
          update(p, {
            itineraryDaysData: {
              [day]: {
                $apply: (day) => {
                  return day
                    ? { ...day, description: value }
                    : { description: value };
                },
              },
            },
          }),
        () => this.handleContentChange()
      );
    }
    handleDayImageChange(day, value) {
      this.setState(
        (p) =>
          update(p, {
            itineraryDaysData: {
              [day]: {
                $apply: (day) => {
                  return day ? { ...day, images: value } : { images: value };
                },
              },
            },
          }),
        () => this.handleContentChange()
      );
    }
    render() {
      const {
        itineraryDaysData,
        pkgTitle,
        pkgSubTitle,
        pkgStyle,
        generalDescription,
      } = this.state;

      return (
        <WrappedComponent
          {...this.props}
          itineraryDaysData={itineraryDaysData}
          pkgTitle={pkgTitle}
          pkgSubTitle={pkgSubTitle}
          pkgStyle={pkgStyle}
          generalDescription={generalDescription}
          onGenDescChange={this.handleGenDescChange}
          onPkgTitleChange={this.handlePkgTitleChange}
          onPkgSubTitleChange={this.handlePkgSubTitleChange}
          onDayDescChange={this.handleDayDescChange}
          onPkgStyleChange={this.handlePkgStyleChange}
          handleDayImageChange={this.handleDayImageChange}
        />
      );
    }
  }

  Comp.propTypes = {
    content: PropTypes.object.isRequired,
    days: PropTypes.array.isRequired,
    onContentChange: PropTypes.func,
  };

  return Comp;
};
