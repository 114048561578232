//================================ LIST VIEW======================================
export const PAYABLE_BEGIN_FETCH_DATA = "PAYABLE_BEGIN_FETCH_DATA";
export const PAYABLE_FETCH_DATA = "PAYABLE_FETCH_DATA";
export const PAYABLE_END_FETCH_DATA = "PAYABLE_END_FETCH_DATA";

//==================================FILTER RELATION================================
export const PAYABLE_INITIALIZE_LIST_FILTER_FORM =
  "PAYABLE_INITIALIZE_LIST_FILTER_FORM";
export const PAYABLE_UPDATE_LIST_FILTER_FORM =
  "PAYABLE_UPDATE_LIST_FILTER_FORM";
export const PAYABLE_APPLY_LIST_FILTER = "PAYABLE_APPLY_LIST_FILTER";

//================================== COLUMNS RELATION====================================
export const PAYABLE_INITIALIZE_LIST_COLUMNS_FORM =
  "PAYABLE_INITIALIZE_LIST_COLUMNS_FORM";
export const PAYABLE_APPLY_LIST_COLUMNS = "PAYABLE_APPLY_LIST_COLUMNS";

//================================= GRAPH============================================
export const PAYABLE_INITIALIZE_GRAPH_VIEW_TYPE =
  "PAYABLE_INITIALIZE_GRAPH_VIEW_TYPE";
export const PAYABLE_CHANGE_GRAPH_VIEW_TYPE = "PAYABLE_CHANGE_GRAPH_VIEW_TYPE";
export const PAYABLE_BEGIN_FETCH_GRAPH_DATA = "PAYABLE_BEGIN_FETCH_GRAPH_DATA";
export const PAYABLE_END_FETCH_GRAPH_DATA = "PAYABLE_END_FETCH_GRAPH_DATA";
export const PAYABLE_FETCH_GRAPH_DATA = "PAYABLE_FETCH_GRAPH_DATA";
