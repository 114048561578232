import axios from "axios";
import { SISI_API_URL, VALAQUENTA_API_URL } from "@src/api";

export async function getDocuments(params) {
  return await axios.get(`${SISI_API_URL}/reviewer/entity/document/`, {
    params,
  });
}

export async function retrieveDocument(uid) {
  return await axios.get(`${SISI_API_URL}/reviewer/entity/document/${uid}/`);
}

export async function createDocument(data) {
  return await axios.post(`${SISI_API_URL}/reviewer/entity/document/`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export async function deleteDocument(uid) {
  return await axios.delete(`${SISI_API_URL}/reviewer/entity/document/${uid}/`);
}

export async function uploadImage(data) {
  return await axios.post(
    `${VALAQUENTA_API_URL}/reviewer/entity/image/`,
    data,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
}
