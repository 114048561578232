import _ from "lodash";
import {
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Icon,
  IconButton,
  InputNumber,
  Modal,
} from "rsuite";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createUseStyles } from "react-jss";
import { variables } from "@src/jsssetup";
import { hideItemDepMetaModal } from "@src/actions/Operation/CustomServices/AddOn";
import { changeItem } from "@src/actions/Operation/CustomServices/AddOn/item_actions";

const styles = createUseStyles({
  modal: {
    top: "50%",
    transform: "translateY(-50%)!important",
  },
  body: { display: "grid" },
  form: {
    "width": "100%",
    "display": "grid",
    "gridTemplateColumns": "repeat(3, 1fr)",
    "gridGap": variables.normal_gap,
    "& .rs-input-group": {
      width: "100%!important",
    },
  },
});

const ItemDepartureMetaModal = (props) => {
  const { itemOrder, show, departure_route, onHide, onChange } = props;
  const classes = styles();

  const [formData, setFormData] = useState({
    geometry: {},
    hours: 0,
    minutes: 0,
    duration: 0,
    distance: 0,
  });

  useEffect(() => {
    const dur = _.get(departure_route, "duration", 0);
    const distance = _.get(departure_route, "distance", 0);
    const hours = Math.floor(dur / (60 * 60));
    setFormData((formData) => ({
      ...formData,
      hours,
      minutes: Math.floor((dur - hours * 60 * 60) / 60),
      distance,
      duration: dur,
    }));
  }, [show]);

  if (!show) {
    return null;
  }

  const transformData = (data) => {
    const tra = _.omit(data, ["geometry", "hours", "minutes"]);
    tra["distance"] = parseInt(_.get(tra, "distance", 0), 10);

    Object.entries(tra).forEach((entry) => {
      if (typeof entry[1] !== "number" || isNaN(entry[1])) {
        tra[entry[0]] = 0;
      }
    });

    return tra;
  };

  return (
    <Modal
      className={`CustomModal ${classes.modal}`}
      show={show}
      onHide={async () => {
        await onChange(transformData(formData), itemOrder);
        onHide();
      }}>
      <Modal.Header>
        <Modal.Title>Route Metadata</Modal.Title>
      </Modal.Header>
      <Modal.Body className={classes.body}>
        <Form
          fluid={true}
          className={classes.form}
          formValue={formData}
          onChange={function (value) {
            value["duration"] =
              (parseInt(value.hours, 10) * 60 + parseInt(value.minutes, 10)) *
              60;
            setFormData(value);
          }}>
          <FormGroup>
            <ControlLabel>
              <strong>Distance (m)</strong>
            </ControlLabel>
            <FormControl name="distance" accepter={InputNumber} />
          </FormGroup>
          <FormGroup>
            <ControlLabel>
              <strong>Transit Hours</strong>
            </ControlLabel>
            <FormControl name="hours" accepter={InputNumber} />
          </FormGroup>
          <FormGroup>
            <ControlLabel>
              <strong>Transit Minutes</strong>
            </ControlLabel>
            <FormControl name="minutes" accepter={InputNumber} />
          </FormGroup>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <IconButton
          icon={<Icon icon="close" />}
          appearance="primary"
          onClick={async () => {
            await onChange(transformData(formData), itemOrder);
            onHide();
          }}>
          Close
        </IconButton>
      </Modal.Footer>
    </Modal>
  );
};

ItemDepartureMetaModal.defaultProps = {
  itemOrder: 1,
  departure_route: {},
};

ItemDepartureMetaModal.propTypes = {
  itemOrder: PropTypes.number,
  show: PropTypes.bool.isRequired,
  departure_route: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { show, itemOrder } = state.customServiceItemDepartureMetaModal;

  const item = _.get(state.customServiceAddOnItems, itemOrder - 1, {});
  const { departure_route } = item;

  return {
    show,
    departure_route,
    itemOrder,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChange: (data, itemOrder) =>
      dispatch(changeItem("departure_route", data, itemOrder)),
    onHide: () => dispatch(hideItemDepMetaModal()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemDepartureMetaModal);
