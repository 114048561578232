import { refreshToken } from "@src/tools/auth_tools";
import { showAuthModal } from "../Auth";

export const refreshIdToken = (recursive = false) => async (dispatch) => {
  try {
    const result = await refreshToken(recursive);

    if (result.should_login) {
      dispatch(showAuthModal());
    }
    return true;
  } catch (error) {
    window.localStorage.clear();
    window.location.reload();
    return false;
  }
};
