import { createSelector } from "reselect";

const getUserGroupPerms = (state) => state.userPerms.groups;

export const userIsAdminSelector = createSelector(
  [getUserGroupPerms],
  (group_perms) => {
    return group_perms.includes("admin");
  }
);

const getUserMeta = (state) => state.userMeta;

export const getUserMetaSelector = createSelector([getUserMeta], (userMeta) => {
  return userMeta;
});

export const getUserTopParentEntitySelector = createSelector(
  [getUserMeta],
  (userMeta) => {
    const { parent_entity_id, parent_entity_type } = userMeta;

    return `${parent_entity_id}___${parent_entity_type}`;
  }
);

export const getUserSourceEntitySelector = createSelector(
  [getUserMeta],
  (userMeta) => `${userMeta.company_id}___${userMeta.company_type}`
);
