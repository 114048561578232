import {
  B2C_POLICY_FETCH_DATA,
  B2C_POLICY_BEGIN_FETCH_DATA,
  B2C_POLICY_END_FETCH_DATA,
  B2C_POLICY_INITIALIZE_LIST_FILTER_FORM,
  B2C_POLICY_UPDATE_LIST_FILTER_FORM,
  B2C_POLICY_APPLY_LIST_FILTER,
  B2C_POLICY_UPDATE_LIST_COLUMNS_FORM,
  B2C_POLICY_APPLY_LIST_COLUMNS,
  B2C_POLICY_INITIALIZE_LIST_COLUMNS_FORM,
  B2C_POLICY_FETCH_OPTIONS,
  B2C_POLICY_VALIDATE_BASE_DATA_EDIT_FORM,
  B2C_POLICY_RESET_LIST_FILTER_FORM,
  B2C_POLICY_FETCH_GRAPH_DATA,
  //=============================EDIT=======================
  B2C_POLICY_INIT_BASE_DATA_EDIT_FORM,
  B2C_POLICY_FETCH_EDIT_DATA,
  //==================================MODAL================================
  B2C_POLICY_HIDE_FORM_MODAL,
  B2C_POLICY_SHOW_FORM_MODAL,
  B2C_POLICY_LOADING_EDIT_FORM,
  B2C_POLICY_IDLE_EDIT_FORM,
  B2C_POLICY_WEBSITE_DATA,
} from "@src/actions/Admin/B2CPolicy/types";

import { Schema } from "rsuite";

import {
  FORMAT_TRAVEL_SERVICES_OPTIONS,
  FORMAT_TRAVEL_SERVICES_FORM,
} from "@src/actions/Shares/types";

import {
  initialB2CPolicyColumnsSetUp,
  initialB2CPolicyFilterForm,
  initialB2CPolicyBaseDataForm,
} from "@src/forms/Admin/B2CPolicy";

import update from "immutability-helper";
import _ from "lodash";
import { formProcessor, entitiesListSelector } from "@src/reducers/tools";

export const initialB2CPolicyFetchStatus = "IDLE";
export const b2cPolicyFetchStatus = (
  state = initialB2CPolicyFetchStatus,
  action
) => {
  switch (action.type) {
    case B2C_POLICY_BEGIN_FETCH_DATA:
      return "FETCHING";
    case B2C_POLICY_END_FETCH_DATA:
      return "FETCHED";
    default:
      return state;
  }
};

export const initialB2CPolicyList = {
  count: 0,
  next: null,
  previous: null,
  results: [],
  page: 1,
  limit: 20,
  displayLength: 100,
};

export const b2cPolicyList = (state = initialB2CPolicyList, action) => {
  switch (action.type) {
    case B2C_POLICY_FETCH_DATA:
      return action.data;
    case B2C_POLICY_RESET_LIST_FILTER_FORM:
      return { ...state, page: 1 };
    default:
      return state;
  }
};

// columns
export const b2cPolicyListColumns = (
  state = entitiesListSelector(initialB2CPolicyColumnsSetUp),
  action
) => {
  switch (action.type) {
    case B2C_POLICY_APPLY_LIST_COLUMNS:
      return entitiesListSelector(action.data);
    default:
      return state;
  }
};

export const b2cPolicyListColumnsForm = (
  state = initialB2CPolicyColumnsSetUp,
  action
) => {
  switch (action.type) {
    case B2C_POLICY_INITIALIZE_LIST_COLUMNS_FORM: {
      var newState = {};
      const tmp = _.cloneDeep(state);
      for (const key in tmp) {
        // clear form's value
        tmp[key] = update(tmp[key], { value: { $set: [] } });
        newState[key] = update(tmp[key], { value: { $set: [] } });
        // add value by action data
        action.data.forEach((item) => {
          if (_.find(tmp[key].choices, { key: item.key })) {
            newState[key] = update(newState[key], {
              value: { $push: [item.key] },
            });
          }
        });
      }
      return newState;
    }
    case B2C_POLICY_UPDATE_LIST_COLUMNS_FORM:
      return action.data;
    default:
      return state;
  }
};

// filter
export const b2cPolicyListFilter = (
  state = initialB2CPolicyFilterForm,
  action
) => {
  switch (action.type) {
    case B2C_POLICY_APPLY_LIST_FILTER:
      return action.data;
    case FORMAT_TRAVEL_SERVICES_FORM:
      return update(state, { $merge: action.data });
    default:
      return state;
  }
};

export const b2cPolicyListFilterForm = (
  state = initialB2CPolicyFilterForm,
  action
) => {
  switch (action.type) {
    case B2C_POLICY_INITIALIZE_LIST_FILTER_FORM:
      return action.data;
    case B2C_POLICY_RESET_LIST_FILTER_FORM:
      return action.data;
    case B2C_POLICY_UPDATE_LIST_FILTER_FORM:
      return formProcessor(
        state,
        action.data.type,
        action.data.name,
        action.data.value
      );
    case FORMAT_TRAVEL_SERVICES_FORM:
      return update(state, { $merge: action.data });
    case FORMAT_TRAVEL_SERVICES_OPTIONS:
      return update(state, { $merge: action.data });
    case B2C_POLICY_FETCH_OPTIONS:
      return update(state, { $merge: action.data.travel_entities });
    default:
      return state;
  }
};

export const b2cPolicyOptions = (state = {}, action) => {
  switch (action.type) {
    case B2C_POLICY_FETCH_OPTIONS:
      return update(state, { $merge: action.data });
    default:
      return state;
  }
};

// validate
export const initialB2CPolicyBaseDataFormErrorsState = { valid: false };
export const b2cPolicyPolicyBaseDataFormErrors = (
  state = initialB2CPolicyBaseDataFormErrorsState,
  action
) => {
  const tmp = {};
  Object.entries(initialB2CPolicyBaseDataForm).forEach((e) => {
    tmp[e[0]] = e[1].validator;
  });
  const validator = Schema.Model(tmp);

  switch (action.type) {
    case B2C_POLICY_VALIDATE_BASE_DATA_EDIT_FORM: {
      const tmp = {};
      Object.entries(action.data).forEach((e) => {
        tmp[e[0]] = e[1].value;
      });
      const validation = validator.check(tmp);

      const valid = Object.entries(validation).every((e) => !e[1].hasError);
      validation["valid"] = valid;
      return validation;
    }
    default:
      return state;
  }
};

export const b2cPolicyGraphData = (state = {}, action) => {
  switch (action.type) {
    case B2C_POLICY_FETCH_GRAPH_DATA:
      return action.data;
    default:
      return state;
  }
};

export const b2cPolicyEditData = (state = {}, action) => {
  switch (action.type) {
    case B2C_POLICY_FETCH_EDIT_DATA:
      return action.data;
    default:
      return state;
  }
};

export const initialB2CPolicyEditFormStatus = {
  loading: false,
  show: false,
  mode: "",
  form: {
    name: "",
    pax_to: 10,
    pax_from: 1,
    booking_window_from: "",
    booking_window_to: "",
    service_execution_from: "",
    service_execution_to: "",
    status: "CA",
    travel_services_policies: [],
    description: "",
    tr_policies: [],
    trf_policies: [],
    acc_policies: [],
    mi_policies: [],
    travel_services: [],
    b2c_websites: [],
    rounding_digits: "1",
  },
};
export const b2cPolicyFormModal = (
  state = initialB2CPolicyEditFormStatus,
  action
) => {
  switch (action.type) {
    case B2C_POLICY_LOADING_EDIT_FORM:
      return { ...state, loading: true };
    case B2C_POLICY_IDLE_EDIT_FORM:
      return { ...state, loading: false };
    case B2C_POLICY_SHOW_FORM_MODAL:
      return { ...state, show: true, mode: action.data.mode };
    case B2C_POLICY_HIDE_FORM_MODAL:
      return initialB2CPolicyEditFormStatus;
    case B2C_POLICY_INIT_BASE_DATA_EDIT_FORM:
      return { ...state, form: action.data };
    default:
      return state;
  }
};

export const b2cWebsiteData = (state = [], action) => {
  switch (action.type) {
    case B2C_POLICY_WEBSITE_DATA: {
      const res = action.data.map((e) => {
        return {
          value: e.uid,
          label: `${e.name} (${e.status_display} Website)`,
          name: e.name,
        };
      });
      return res;
    }
    default:
      return state;
  }
};
