import React, { useState } from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { variables } from "@src/jsssetup";
import { CustomButton } from "@src/components/common/buttons";
import _ from "lodash";
import CreateTimeLineChart from "../ChartComponents/CreateTimeLineChart";
import TransTxt from "@src/components/common/SxFormatMessage";
import CreatePieChartSecond from "../ChartComponents/CreatePieChartSecond";
import CreateBarChartSecond from "../ChartComponents/CreateBarChartSecond";

const styles = createUseStyles({
  StatsCharts: {
    display: "grid",
    gridTemplateRows: " repeat(2, max-content)",
    padding: variables.normal_gap,
  },
  title: {
    display: "grid",
    gridColumn: "1 span",
    alignSelf: "start",
    color: "white",
    backgroundColor: "#9A9A9A",
    textAlign: "center",
    borderRadius: "5px",
    width: "100%",
    padding: variables.half_gap,
  },
  stats: {
    display: "grid",
    gridTemplateColumns: "1fr 5fr",
  },
  leftSection: {
    display: "grid",
    gridAutoFlow: "row",
    gridGap: variables.normal_gap,
    alignContent: "start",
    justifyContent: "start",
    paddingTop: `calc(${variables.double_gap}*2)`,
  },
  rightSection: {
    display: "grid",
    gridTemplateRows: "repeat(4, max-content)",
    gridGap: variables.normal_gap,
    paddingTop: `calc(${variables.double_gap}*2)`,
  },
  timelineChartsContainer: {
    display: "grid",
    gridAutoFlow: "column",
    gridGap: variables.normal_gap,
  },
  chartColumn: {
    display: "grid",
    gridAutoFlow: "column",
    gridTemplateColumns: "max-content",
    justifyContent: "center",
  },
  chartsRow: {
    display: "grid",
    gridAutoFlow: "row",
    gridTemplateRows: "1fr",
  },
  button: {
    display: "grid",
    maxHeight: "2rem",
  },
});

var StatsCharts = ({ currencies, dashboardPayload, data }) => {
  const classes = styles();

  const [selectedCurrency, setSelectedCurrency] = useState("");
  const pieChartConfigs = [
    {
      chartType: "cxled_confirmed_percs",
      dataSource: ["cancelled", "confirmed"],
      colors: ["red", "green"],
    },
    {
      serviceType: _.get(dashboardPayload, "serviceType", {}),
      // serviceType: "ACC",
      chartType: "fail_success_percs",
      dataSource: ["fail", "success"],
      colors: ["red", "orange", "pink", "teal"],
    },
  ];

  const timeChartConfigs = [
    {
      chartType: "res_per_day",
      color: "blue",
    },
    {
      chartType: "avg_price_per_day",
      color: "red",
    },
  ];

  const barChartConfigs = [
    {
      chartType: "entities_per_month",
      color: "maroon",
    },
  ];

  return (
    <div className={classes.StatsCharts}>
      <div className={classes.title}>
        <span>
          <strong> Stats </strong>
        </span>
      </div>
      <div className={classes.stats}>
        <div className={classes.leftSection}>
          {currencies.map((cur) => (
            <div className={classes.cus} key={cur}>
              <CustomButton
                key={cur}
                size="md"
                appearance={selectedCurrency === cur ? "primary" : "ghost"}
                customStyles={{
                  CustomButton: {
                    ...CustomButton,
                    gridTemplateColumns: "1fr",
                  },
                }}
                onClick={() => {
                  setSelectedCurrency(cur);
                }}>
                {cur}
              </CustomButton>
            </div>
          ))}
        </div>
        <div className={classes.rightSection}>
          <div className={classes.timelineChartsContainer}>
            {timeChartConfigs.map((tC, tCIdx) => (
              <div className={classes.chartsRow} key={tCIdx}>
                <CreateTimeLineChart
                  dashboardPayload={dashboardPayload}
                  chartType={tC.chartType}
                  currency={selectedCurrency}
                  data={data}
                  color={tC.color}
                />
              </div>
            ))}
          </div>
          <div className={classes.chartsRow}>
            <div className={classes.chartColumn}>
              {pieChartConfigs.map((pC, pieIdx) => (
                <CreatePieChartSecond
                  key={pieIdx}
                  dashboardPayload={dashboardPayload}
                  chartType={pC.chartType}
                  currency={selectedCurrency}
                  dataSources={pC.dataSource}
                  colors={pC.colors}
                  data={data}
                />
              ))}
            </div>
          </div>
          {barChartConfigs.map((bC, bCIdx) => (
            <div className={classes.chartsRow} key={bCIdx}>
              <div className={classes.chartColumn}>
                <CreateBarChartSecond
                  dashboardPayload={dashboardPayload}
                  chartType={bC.chartType}
                  currency={selectedCurrency}
                  data={data}
                  color={bC.color}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
StatsCharts.propTypes = {
  data: PropTypes.object.isRequired,
  currencies: PropTypes.array.isRequired,
  dashboardPayload: PropTypes.object.isRequired,
};
export default StatsCharts;
