import moment from "moment";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";
import { Icon, IconButton, Radio, Table } from "rsuite";
import { formatDate } from "@src/tools/date_tools";
import { naString } from "@src/tools/string_tools";

class MktPricingSection extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { periods } = this.props;
    return (
      <div className="MktPricingSection">
        <Table data={periods} rowHeight={60}>
          <Table.Column verticalAlign="middle" width={50} align="center">
            <Table.HeaderCell>#</Table.HeaderCell>
            <Table.Cell>
              <Radio />
            </Table.Cell>
          </Table.Column>
          <Table.Column flexGrow={1} verticalAlign="middle">
            <Table.HeaderCell>Distribution From - To</Table.HeaderCell>
            <Table.Cell>
              {(rowData) => (
                <span>
                  {naString(formatDate(rowData.distribution_from))} -{" "}
                  {naString(formatDate(rowData.distribution_to))}
                </span>
              )}
            </Table.Cell>
          </Table.Column>
          <Table.Column flexGrow={1} verticalAlign="middle">
            <Table.HeaderCell>Operation From - To</Table.HeaderCell>
            <Table.Cell>
              {(rowData) => (
                <span>
                  {naString(formatDate(rowData.operation_from))} -{" "}
                  {naString(formatDate(rowData.operation_to))}
                </span>
              )}
            </Table.Cell>
          </Table.Column>
          <Table.Column width={150} verticalAlign="middle">
            <Table.HeaderCell>Geo Policy</Table.HeaderCell>
            <Table.Cell>
              <IconButton icon={<Icon icon="plus" />} color="green" size="xs">
                Add
              </IconButton>
            </Table.Cell>
          </Table.Column>
          <Table.Column width={150} verticalAlign="middle">
            <Table.HeaderCell>Group Policy</Table.HeaderCell>
            <Table.Cell>
              <IconButton icon={<Icon icon="plus" />} color="green" size="xs">
                Add
              </IconButton>
            </Table.Cell>
          </Table.Column>
          <Table.Column width={150} verticalAlign="middle">
            <Table.HeaderCell>One to One Policy</Table.HeaderCell>
            <Table.Cell>
              <IconButton icon={<Icon icon="plus" />} color="green" size="xs">
                Add
              </IconButton>
            </Table.Cell>
          </Table.Column>
        </Table>
      </div>
    );
  }
}

MktPricingSection.propTypes = {
  periods: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  const { periods } = state.tripPlannerMktDistributionPeriods;

  return { periods };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, null)(MktPricingSection);
