import {
  InputGroup,
  Input,
  IconButton,
  Icon,
  CheckPicker,
  RangeSlider,
} from "rsuite";

import { ADDON_CATEGORIES } from "@src/pages/Operation/CustomServices/AddOns/Controllers/SetupController";

import { addOnsMinMaxSelector } from "@src/selectors/Project/TripPlanner/addon_selectors";
import { changeAddOnFilters } from "@src/actions/Project/TripPlanner/day_by_day_actions";

import _ from "lodash";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";
import { convertAmountSelector } from "@src/selectors/Financial";

class AddOnFilters extends React.PureComponent {
  constructor(props) {
    super(props);
    this.categories = [...ADDON_CATEGORIES];
  }
  getCategories() {
    const { results } = this.props;

    const categories = [...this.categories];
    const resultCategories = _.flatten(
      results.map((res) =>
        res.categories.map((cat) => ({
          label: _.startCase(cat),
          value: cat,
        }))
      )
    );

    return categories.filter((cat) =>
      resultCategories.find((rcat) => rcat.value == cat.value)
    );
  }
  getItems() {
    const { results } = this.props;

    const items = [];

    _.flatten(
      results.map((res) =>
        res.items.map((it) => ({
          label: it.data.name_en,
          value: it.data.id,
          role: _.startCase(it.type),
        }))
      )
    ).forEach((item) => {
      if (items.find((it) => it.value == item.value)) {
        return;
      } else {
        items.push(item);
      }
    });
    return items;
  }
  render() {
    const {
      filters,
      minMaxPrice,
      minCurrentPrice,
      maxCurrentPrice,
      onChange,
    } = this.props;

    return (
      <div className="TripDrawer__filters">
        <div className="TripDrawer__filters__inputs">
          <div className="TripDrawer__filters__inputs__input">
            <span className="TripDrawer__filters__inputs__input__label">
              <strong>Add On Name</strong>
            </span>
            <InputGroup>
              <Input
                value={filters.title}
                onChange={function (value) {
                  onChange("title", value);
                }}
              />
              <InputGroup.Addon>
                <Icon icon="search" />
              </InputGroup.Addon>
            </InputGroup>
          </div>
          <div className="TripDrawer__filters__inputs__input">
            <span className="TripDrawer__filters__inputs__input__label">
              <strong>Items</strong>
            </span>
            <CheckPicker
              groupBy="role"
              data={this.getItems()}
              value={filters.items}
              onChange={function (value) {
                onChange("items", value);
              }}
            />
          </div>
          <div className="TripDrawer__filters__inputs__input">
            <span className="TripDrawer__filters__inputs__input__label">
              <strong>Categories</strong>
            </span>
            <CheckPicker
              groupBy="role"
              data={this.getCategories()}
              value={filters.categories}
              onChange={function (value) {
                onChange("categories", value);
              }}
            />
          </div>
          <div className="TripDrawer__filters__inputs__input">
            <span className="TripDrawer__filters__inputs__input__label">
              <strong>
                Min/Max Price ({minCurrentPrice.currSymbol}
                {minCurrentPrice.convertedAmount} - {maxCurrentPrice.currSymbol}
                {maxCurrentPrice.convertedAmount})
              </strong>
            </span>
            <RangeSlider
              value={filters.min_max_price}
              tooltip={false}
              min={minMaxPrice[0] > 0 ? minMaxPrice[0] - 1 : 0}
              max={minMaxPrice[1] + 1}
              onChange={function (value) {
                onChange("min_max_price", value);
              }}
            />
          </div>
        </div>
        <div className="TripDrawer__filters__controls">
          <IconButton color="blue" icon={<Icon icon="reload" />}>
            Reset
          </IconButton>
        </div>
      </div>
    );
  }
}

AddOnFilters.propTypes = {
  results: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  minMaxPrice: PropTypes.array.isRequired,
  minPrice: PropTypes.object,
  maxPrice: PropTypes.object,
  minCurrentPrice: PropTypes.object.isRequired,
  maxCurrentPrice: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { results, filters } = state.tripPlannerAddOnDrawer;
  const minMaxPrice = addOnsMinMaxSelector(state);

  const minCurrentPrice = convertAmountSelector(state, {
    amount: filters.min_max_price[0],
  });
  const maxCurrentPrice = convertAmountSelector(state, {
    amount: filters.min_max_price[1],
  });

  return {
    results,
    filters,
    minMaxPrice,
    minCurrentPrice,
    maxCurrentPrice,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChange: (key, value) => dispatch(changeAddOnFilters(key, value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddOnFilters);
