import { createUseStyles } from "react-jss";
import React from "react";
import PropTypes from "prop-types";
import { InlineIcon } from "@iconify/react";
import _ from "lodash";
import { variables } from "@src/jsssetup";

const ratingPanelStyles = createUseStyles({
  ratingPanel: (props) => ({
    display: "grid",
    gridAutoFlow: "column",
    background: "transparent",
    justifyContent: "start",
    width: "max-content",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    ..._.get(props, "customStyles.ratingPanel", {}),
  }),
  iconFilled: (props) => ({
    fontSize: props.fontSize,
    color: props.color ? props.color : variables.colors.easy.orange,
    ..._.get(props, "customStyles.iconFilled", {}),
  }),
  iconEmpty: (props) => ({
    fontSize: props.fontSize,
    color: props.color ? props.color : variables.colors.easy.orange,
    ..._.get(props, "customStyles.iconEmpty", {}),
  }),
  iconFilledGhost: (props) => ({
    fontSize: props.fontSize,
    color: "#f2f2f5",
    ..._.get(props, "customStyles.iconFilledGhost", {}),
  }),
});
const RatingPanel = ({
  ghost,
  rating,
  showEmpty,
  fontSize,
  color,
  customStyles,
  showZeroRating = true,
}) => {
  const classes = ratingPanelStyles({ fontSize, color, customStyles });

  const stars = [...new Array(5)].map((x, idx) => idx + 1 <= rating);

  if (!showZeroRating && rating === 0) {
    showEmpty = false;
  }
  return (
    <div className={classes.ratingPanel}>
      {ghost
        ? [...new Array(5)].map((x, idx) => (
            <InlineIcon
              key={idx}
              icon="fa:star"
              className={classes.iconFilledGhost}
            />
          ))
        : stars
            .map((star, idx) => {
              if (star) {
                return (
                  <InlineIcon
                    key={idx}
                    icon="fa:star"
                    className={classes.iconFilled}
                  />
                );
              }

              if (showEmpty) {
                return (
                  <InlineIcon
                    key={idx}
                    icon="fa:star-o"
                    className={classes.iconEmpty}
                  />
                );
              }
              return null;
            })
            .filter((r) => r)}
    </div>
  );
};
RatingPanel.defaultProps = {
  ghost: false,
  rating: 0,
  showEmpty: true,
  fontSize: "large",
  color: variables.colors.base_color,
  customStyles: {},
  showZeroRating: true,
};
RatingPanel.propTypes = {
  ghost: PropTypes.bool,
  rating: PropTypes.number.isRequired,
  showEmpty: PropTypes.bool,
  fontSize: PropTypes.string,
  color: PropTypes.string,
  customStyles: PropTypes.object,
  showZeroRating: PropTypes.bool,
};
export default RatingPanel;
