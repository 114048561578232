import axios from "axios";
import { SISI_API_URL } from "..";
import { getAuthHeaders } from "../request";

const valaquenta_api_url =
  "https://9n837gy1ne.execute-api.ap-southeast-1.amazonaws.com/staging";

export const fetchChatGroups = async ({ member_email }) => {
  try {
    const response = await axios.get(`${valaquenta_api_url}/chatengine/chat/`, {
      params: { member_email },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const retrieveChatGroupMetadata = async ({ uid }) => {
  try {
    const response = await axios.get(
      `${valaquenta_api_url}/chatengine/chat/${uid}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const fetchChatGroupMessages = async ({ uid }) => {
  try {
    const response = await axios.get(
      `${valaquenta_api_url}/chatengine/messages/${uid}`,
      { params: { timedelta: 1 } }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const fetchChatOffer = async ({ chat_group_uid }) => {
  const auth_headers = getAuthHeaders();

  try {
    const response = await axios.get(
      `${SISI_API_URL}/crs/trip-offer/chat-view/${chat_group_uid}`,
      { headers: { ...auth_headers } }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};
