import {
  GENERAL_SERVICES_SERVICE_MODAL_HIDE,
  GENERAL_SERVICES_SERVICE_MODAL_SHOW,
  GENERAL_SERVICES_IMPORT_SERVICE_MODAL_SHOW,
  GENERAL_SERVICES_IMPORT_SERVICE_MODAL_HIDE,
  GENERAL_SERVICES_FETCH_LIST,
  GENERAL_SERVICES_LIST_GENERIC_CHANGE,
} from "@src/actions/MyLibrary/GeneralServices/types";
import { commonList } from "../../common";

const generalServiceModalInitial = {
  show: false,
  loading: false,
};

export const GeneralServiceModal = (
  state = generalServiceModalInitial,
  action
) => {
  switch (action.type) {
    case GENERAL_SERVICES_SERVICE_MODAL_HIDE:
      return { ...state, show: false };
    case GENERAL_SERVICES_SERVICE_MODAL_SHOW:
      return { ...state, show: true };
    default:
      return state;
  }
};

const importServiceModalInitial = {
  show: false,
  loading: false,
};

export const ImportServiceModal = (
  state = importServiceModalInitial,
  action
) => {
  switch (action.type) {
    case GENERAL_SERVICES_IMPORT_SERVICE_MODAL_HIDE:
      return { ...state, show: false };
    case GENERAL_SERVICES_IMPORT_SERVICE_MODAL_SHOW:
      return { ...state, show: true };
    default:
      return state;
  }
};

const GeneralSrvsListReducerInitial = {
  ...commonList,
  filters: {
    reference: "",
    external_id: "",
    codename: "",
    name: "",
    destination: "",
    service_type: "",
  },
};
export const GeneralSrvsListReducer = (
  state = GeneralSrvsListReducerInitial,
  action
) => {
  switch (action.type) {
    case GENERAL_SERVICES_FETCH_LIST: {
      const { data } = action;
      return { ...state, ...data };
    }
    case GENERAL_SERVICES_LIST_GENERIC_CHANGE: {
      const { key, value } = action;
      if (key !== "filters") {
        return { ...state, [key]: value };
      } else {
        return { ...state, [key]: value, page: 1 };
      }
    }
    default:
      return state;
  }
};
