import { cardStyles, variables } from "@src/jsssetup";
import {
  getSrvsForBookingNoSelector,
  getSrvsForOptionBookingNoSelector,
  isAccPrebookRequiredSelector,
} from "@src/selectors/Project/TripPlanner/prebook";
import PropTypes from "prop-types";
import React, { useCallback, useContext } from "react";
import { createUseStyles } from "react-jss";
import { useDispatch, useSelector } from "react-redux";
import { Button, Tag } from "rsuite";
import { WhisperWrapper } from "@src/components/common/ui_helpers";
import { startAccPrebook } from "@src/actions/Project/TripPlanner/step_four/accommodation_prebook";
import {
  areAccPrebooksAcceptedSelector,
  areActPrebooksAcceptedSelector,
  areTrfPrebooksAcceptedSelector,
  isFlightsPrebookCompleteSelector,
} from "@src/selectors/Project/TripPlanner";
import { TripPlanContext } from "../../TripPlanner";

const categoryStyles = createUseStyles({
  Category: (props) => ({
    "display": "grid",
    "gridTemplateColumns":
      !props.prebookComplete && props.withPrebook
        ? "min-content 1fr repeat(5, auto) min-content"
        : props.prebookComplete && props.withPrebook
        ? "1fr repeat(5, auto) min-content"
        : "1fr repeat(4, auto)",
    "gridGap": `calc(${variables.normal_gap} / 2)`,
    "fontWeight": "bold",
    "border": `2px solid ${variables.colors.lightBorderFillColor}`,
    "padding": `calc(${variables.normal_gap} / 2)`,
    "borderRadius": "5px",
    "alignItems": "center",
    "fontSize": "smaller",
    "& .rs-tag": { fontSize: "smaller" },
    "& .rs-tag + .rs-tag": { marginLeft: "0px" },
  }),
  btn: { fontSize: "smaller" },
  [`@media ${variables.media.bigscreen}`]: {
    Category: (props) => ({
      "fontSize": "small",
      "& .rs-tag": { fontSize: "small" },
    }),
    btn: { fontSize: "small" },
  },
});
const Category = ({
  type,
  regular,
  option,
  withPrebook = true,
  prebookComplete,
  onPrebook,
}) => {
  const classes = categoryStyles({ prebookComplete, withPrebook });

  return (
    <div className={classes.Category}>
      {!prebookComplete && withPrebook && (
        <Button
          className={classes.btn}
          size="xs"
          color="blue"
          onClick={onPrebook}>
          <strong>Prebook</strong>
        </Button>
      )}
      <span>{type}</span>
      <WhisperWrapper msg="Services selected for regular booking">
        <React.Fragment>
          <span>Regular</span>
          <Tag color="blue">
            <strong>{regular}</strong>
          </Tag>
        </React.Fragment>
      </WhisperWrapper>
      <WhisperWrapper msg="Services selected for option booking">
        <React.Fragment>
          <span>Option</span>
          <Tag color="orange">
            <strong>{option}</strong>
          </Tag>
        </React.Fragment>
      </WhisperWrapper>
      {withPrebook && (
        <React.Fragment>
          <span>Prebook Completed</span>
          <Tag color={prebookComplete ? "green" : "red"}>
            <strong>{prebookComplete ? "Yes" : "No"}</strong>
          </Tag>
        </React.Fragment>
      )}
    </div>
  );
};
Category.defaultProps = {
  regular: 0,
  option: 0,
};
Category.propTypes = {
  srv_type: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  regular: PropTypes.number.isRequired,
  option: PropTypes.number.isRequired,
  withPrebook: PropTypes.bool,
  prebookComplete: PropTypes.bool,
  onPrebook: PropTypes.func,
};

const styles = createUseStyles({
  SelectedServicesController: cardStyles.card,
  header: cardStyles.header,
  body: {
    ...cardStyles.body,
    gridGap: `calc(${variables.normal_gap} / 2)`,
    alignItems: "center",
    paddingLeft: variables.normal_gap,
    padding: variables.normal_gap,
  },
});
const SelectedServicesController = () => {
  const classes = styles();

  const {
    setShowFlightsPrebookModal,
    setShowActivitiesPrebookModal,
    setShowTransfersPrebookModal,
    setShowTrainsPrebookModal,
  } = useContext(TripPlanContext);

  const { flRegNo, flOptNo, isFlightsPrebookComplete, atraRegNo } = useSelector(
    (state) => {
      const flRegNo = getSrvsForBookingNoSelector(state, { srv_type: "FL" });
      const flOptNo = getSrvsForOptionBookingNoSelector(state, {
        srv_type: "FL",
      });
      const isFlightsPrebookComplete = isFlightsPrebookCompleteSelector(state);

      const atraRegNo = getSrvsForBookingNoSelector(state, {
        srv_type: "ATRA",
      });

      return { flRegNo, flOptNo, isFlightsPrebookComplete, atraRegNo };
    }
  );
  const flPrebookRequired = flRegNo + flOptNo > 0;

  const atraPrebokRequired = atraRegNo > 0;

  const traRegNo = useSelector((state) =>
    getSrvsForBookingNoSelector(state, { srv_type: "TRA" })
  );
  const traOptNo = useSelector((state) =>
    getSrvsForOptionBookingNoSelector(state, { srv_type: "TRA" })
  );

  const accRegNo = useSelector((state) =>
    getSrvsForBookingNoSelector(state, { srv_type: "ACC" })
  );
  const accOptNo = useSelector((state) =>
    getSrvsForOptionBookingNoSelector(state, { srv_type: "ACC" })
  );
  const accPrebookComplete = useSelector((state) =>
    areAccPrebooksAcceptedSelector(state)
  );
  const accPrebookRequired = useSelector((state) =>
    isAccPrebookRequiredSelector(state)
  );

  const trfRegNo = useSelector((state) =>
    getSrvsForBookingNoSelector(state, { srv_type: "TRF" })
  );
  const trfOptNo = useSelector((state) =>
    getSrvsForOptionBookingNoSelector(state, { srv_type: "TRF" })
  );
  const trfPrebookComplete = useSelector((state) =>
    areTrfPrebooksAcceptedSelector(state)
  );

  const cooRegNo = useSelector((state) =>
    getSrvsForBookingNoSelector(state, { srv_type: "COO" })
  );
  const cooOptNo = useSelector((state) =>
    getSrvsForOptionBookingNoSelector(state, { srv_type: "COO" })
  );

  const genRegNo = useSelector((state) =>
    getSrvsForBookingNoSelector(state, { srv_type: "GEN" })
  );
  const genOptNo = useSelector((state) =>
    getSrvsForOptionBookingNoSelector(state, { srv_type: "GEN" })
  );

  const restRegNo = useSelector((state) =>
    getSrvsForBookingNoSelector(state, { srv_type: "REST" })
  );
  const restOptNo = useSelector((state) =>
    getSrvsForOptionBookingNoSelector(state, { srv_type: "REST" })
  );

  const actRegNo = useSelector((state) =>
    getSrvsForBookingNoSelector(state, { srv_type: "ACT" })
  );
  const actOptNo = useSelector((state) =>
    getSrvsForOptionBookingNoSelector(state, { srv_type: "ACT" })
  );
  const actPrebookComplete = useSelector((state) =>
    areActPrebooksAcceptedSelector(state)
  );

  const srvsSum = [
    flRegNo,
    flOptNo,
    traRegNo,
    traOptNo,
    accRegNo,
    accOptNo,
    trfRegNo,
    trfOptNo,
    cooRegNo,
    cooOptNo,
    genRegNo,
    genOptNo,
    restRegNo,
    restOptNo,
    actRegNo,
    actOptNo,
  ].reduce((a, b) => a + b, 0);

  const dispatch = useDispatch();
  const onViewAccPrebook = useCallback(
    () => dispatch(startAccPrebook()),
    [dispatch]
  );

  return (
    <div className={classes.SelectedServicesController}>
      <div className={classes.header}>
        <h5>1. Service Booking Information</h5>
      </div>
      <div className={classes.body}>
        {srvsSum === 0 && (
          <span>
            <strong>No services selected for booking.</strong>
          </span>
        )}
        {flPrebookRequired && (
          <Category
            srv_type="FL"
            type="Flights"
            regular={flRegNo}
            option={flOptNo}
            withPrebook={true}
            prebookComplete={isFlightsPrebookComplete}
            onPrebook={() => setShowFlightsPrebookModal(true)}
          />
        )}
        {atraPrebokRequired && (
          <Category
            srv_type="ATRA"
            type="Trains"
            regular={atraRegNo}
            option={0}
            withPrebook={true}
            prebookComplete={false}
            onPrebook={() => setShowTrainsPrebookModal(true)}
          />
        )}
        {traRegNo + traOptNo > 0 && (
          <Category
            srv_type="TRA"
            type="Trains"
            regular={traRegNo}
            option={traOptNo}
          />
        )}
        {cooRegNo + cooOptNo > 0 && (
          <Category
            srv_type="COO"
            type="Coordinators"
            regular={cooRegNo}
            option={cooOptNo}
          />
        )}
        {genRegNo + genOptNo > 0 && (
          <Category
            srv_type="GEN"
            type="Gen. Services"
            regular={genRegNo}
            option={genOptNo}
          />
        )}
        {restRegNo + restOptNo > 0 && (
          <Category
            srv_type="REST"
            type="Restaurants"
            regular={restRegNo}
            option={restOptNo}
          />
        )}
        {accRegNo + accOptNo > 0 && (
          <Category
            srv_type="ACC"
            type="Hotels"
            regular={accRegNo}
            option={accOptNo}
            withPrebook={accPrebookRequired}
            prebookComplete={accPrebookComplete}
            onPrebook={onViewAccPrebook}
          />
        )}
        {trfRegNo + trfOptNo > 0 && (
          <Category
            srv_type="TRF"
            type="Transfers"
            regular={trfRegNo}
            option={trfOptNo}
            withPrebook={true}
            prebookComplete={trfPrebookComplete}
            onPrebook={() => setShowTransfersPrebookModal(true)}
          />
        )}
        {actRegNo + actOptNo > 0 && (
          <Category
            srv_type="ACT"
            type="Activities"
            regular={actRegNo}
            option={actOptNo}
            withPrebook={true}
            prebookComplete={actPrebookComplete}
            onPrebook={() => setShowActivitiesPrebookModal(true)}
          />
        )}
      </div>
    </div>
  );
};

export default SelectedServicesController;
