import PropTypes from "prop-types";
import React from "react";

import _ from "lodash";
import { NormalInputField, NormalSelectField } from "@src/components/forms";

import { Button } from "rsuite";
import { v4 } from "uuid";
import { createUseStyles } from "react-jss";
import { variables } from "@src/jsssetup";

const priceStyles = createUseStyles({
  priceContainer: {
    display: "grid",
  },
  price: {
    padding: variables.half_gap,
    background: variables.colors.lightGrey,
    borderRadius: "5px",
    border: `2px solid ${variables.colors.lightGrey}`,
  },
});
export const AdhocPrice = ({ price, label }) => {
  const classes = priceStyles();
  return (
    <div className={classes.priceContainer}>
      <span>
        <strong>{label}</strong>
      </span>
      <span className={classes.price}>{isNaN(price) ? 0 : price}</span>
    </div>
  );
};
AdhocPrice.propTypes = {
  price: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
};

const beddingOptions = [
  ["", "------"],
  ["single", "Single"],
  ["double", "Double"],
  ["double_for_single_use", "Double For Single Use"],
  ["double_with_extra_bed", "Double with extra bed"],
  ["twin", "Twin"],
  ["twin_with_extra_bed", "Twin with extra bed"],
  ["triple", "Triple"],
  ["quadruple", "Quadruple"],
  ["familly_room", "Familly Room"],
];

const roomFormStyles = createUseStyles({
  room: {
    padding: variables.normal_gap,
    borderRadius: variables.half_gap,
    border: "2px solid black",
    display: "grid",
    gridGap: variables.half_gap,
  },
  roomHeader: {
    display: "grid",
    gridTemplateColumns: "1fr auto",
    alignItems: "center",
    gridGap: variables.half_gap,
  },
  roomAttrs: {
    display: "grid",
    gridTemplateColumns: "repeat(5, 1fr)",
    gridGap: variables.half_gap,
  },
  bottomRoomAttrs: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridGap: variables.half_gap,
  },
  actions: {
    display: "grid",
    gridGap: variables.half_gap,
    gridTemplateColumns: "repeat(2, max-content)",
    justifyContent: "end",
  },
});
export const RoomForm = ({ idx, room, rooms, setRooms }) => {
  const classes = roomFormStyles();

  return (
    <div className={classes.room}>
      <div className={classes.roomHeader}>
        <NormalInputField
          name={`rooms.${idx}.name`}
          label={`Room ${idx + 1} Name`}
        />
      </div>
      <div className={classes.roomAttrs}>
        <NormalSelectField
          label="Type"
          name={`rooms.${idx}.type`}
          options={[
            ["", "------"],
            ["standard", "Standard"],
            ["deluxe", "Deluxe"],
          ]}
        />
        <NormalSelectField
          label="Board"
          name={`rooms.${idx}.board`}
          options={[
            ["", "------"],
            ["RO", "Room Only"],
            ["BB", "Bed & Breakfast"],
            ["HB", "Half Board"],
            ["FB", "Full Board"],
            ["AI", "All Inclusive"],
            ["CCB", "Continental Breakfast"],
            ["CBB", "Continental Buffet Breakfast"],
            ["AB", "American Breakfast"],
            ["ABB", "American Buffet Breakfast"],
            ["EB", "English Breakfast"],
            ["IB", "Irish Breakfast"],
            ["JB", "Japanese Breakfast"],
            ["CB", "Chinese Breakfast"],
            ["BBB", "Breakfast Box"],
          ]}
        />
        <NormalInputField
          name={`rooms.${idx}.adults`}
          label="Adults"
          type="number"
        />
        <NormalInputField
          name={`rooms.${idx}.children`}
          label="Children"
          type="number"
        />
        <NormalInputField
          name={`rooms.${idx}.child_ages`}
          label="Children Ages"
        />
      </div>
      <div className={classes.bottomRoomAttrs}>
        <NormalSelectField
          label="Bedding"
          name={`rooms.${idx}.bedding`}
          options={beddingOptions}
        />
        <NormalSelectField
          label="Price Type"
          name={`rooms.${idx}.price_type`}
          options={[
            ["per_person", "Per Person"],
            ["per_room", "Per Room"],
          ]}
        />
        {_.get(room, "price_type", "per_person") === "per_person" ? (
          <NormalInputField
            name={`rooms.${idx}.price_per_person_per_night`}
            label="Price per Person per Night"
            type="number"
          />
        ) : (
          <NormalInputField
            name={`rooms.${idx}.price_per_room_per_night`}
            label="Price per Room per Night"
            type="number"
          />
        )}
        <AdhocPrice
          label="Total Price"
          price={_.get(rooms, `${idx}.price`, 0)}
        />
      </div>
      <div className={classes.actions}>
        <Button
          appearance="primary"
          size="xs"
          onClick={() => {
            const newRoom = _.cloneDeep(room);
            newRoom["uid"] = v4();
            const newRooms = _.cloneDeep(rooms);
            newRooms.push(newRoom);
            setRooms({ rooms: newRooms });
          }}>
          <strong>Copy to new</strong>
        </Button>
        <Button
          color="red"
          size="xs"
          onClick={() => {
            const rrooms = _.cloneDeep(rooms) ?? [];
            setRooms({
              rooms: rrooms.filter((r) => r.uid !== room.uid),
            });
          }}>
          <strong>Remove</strong>
        </Button>
      </div>
    </div>
  );
};
RoomForm.propTypes = {
  idx: PropTypes.number.isRequired,
  room: PropTypes.object.isRequired,
  rooms: PropTypes.array.isRequired,
  setRooms: PropTypes.func.isRequired,
};

export const extraTypeMapping = {
  "POI": "Porterage In",
  "POO": "Porterage Out",
  "POIO": "Porterage In/Out",
  "2MEAL": "2 Course Meal",
  "3MEAL": "3 Course Meal",
  "4MEAL": "4 Course Meal",
  "BUF": "Buffet",
  "OTH": "Other",
};

const extraFormStyles = createUseStyles({
  extra: {
    padding: variables.normal_gap,
    borderRadius: variables.half_gap,
    border: "2px solid black",
    display: "grid",
    gridGap: variables.half_gap,
  },
  extraHeader: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr) auto",
    alignItems: "center",
    gridGap: variables.half_gap,
  },
  actions: {
    display: "grid",
    gridGap: variables.half_gap,
    gridTemplateColumns: "repeat(2, max-content)",
    justifyContent: "end",
  },
  extraAttrs: {
    display: "grid",
    gridGap: variables.half_gap,
  },
});
export const ExtraForm = ({ idx, extra, extras, setExtras }) => {
  const classes = extraFormStyles();

  return (
    <div className={classes.extra}>
      <div className={classes.extraHeader}>
        <NormalSelectField
          name={`extras.${idx}.type`}
          label={`Extra ${idx + 1} Type`}
          options={[[null, "------"], ...Object.entries(extraTypeMapping)]}
        />
        <NormalSelectField
          name={`extras.${idx}.price_type`}
          label="Price Type"
          options={[
            ["per_room", "Per Room"],
            ["per_person", "Per Person"],
          ]}
        />
        {extra.price_type === "per_person" ? (
          <NormalInputField
            name={`extras.${idx}.price_per_person`}
            label="Price Per Person"
            type="number"
          />
        ) : (
          <NormalInputField
            name={`extras.${idx}.price_per_room`}
            label="Price Per Room"
            type="number"
          />
        )}
        <AdhocPrice label="Price" price={extras[idx].price} />
      </div>
      <div className={classes.extraAttrs}>
        <NormalInputField
          label="Description"
          name={`extras.${idx}.description`}
        />
      </div>
      <div className={classes.actions}>
        <Button
          color="red"
          size="xs"
          onClick={() => {
            const rooms = _.cloneDeep(rooms);
            setExtras({
              extras: extras.filter((r) => r.uid !== extra.uid),
            });
          }}>
          <strong>Remove</strong>
        </Button>
        <Button
          appearance="primary"
          size="xs"
          onClick={() => {
            const newExtra = _.cloneDeep(extra);
            newExtra["uid"] = v4();
            const newExtras = _.cloneDeep(extras);
            newExtras.push(newExtra);
            setExtras({ extras: newExtras });
          }}>
          <strong>Copy to new</strong>
        </Button>
      </div>
    </div>
  );
};
ExtraForm.propTypes = {
  idx: PropTypes.number.isRequired,
  extra: PropTypes.object.isRequired,
  extras: PropTypes.array.isRequired,
  setExtras: PropTypes.func.isRequired,
};
