import _ from "lodash";
import {
  patchPublicGenSrvReservation,
  retrievePublicGenSrvReservation,
} from "@src/api/Operation/Reservations/GeneralServices";
import { currencies } from "@src/definitions";
import { variables } from "@src/jsssetup";
import { Form, Formik } from "formik";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Loader } from "rsuite";
import {
  Actions,
  CommonFeedback,
  ContactInformation,
  RequestInfo,
  Stepper,
} from "../common";
import { cxlRuleTemplate } from "../common/cxl_policies";
import { onReject, onSubmit, payloader, retrieverFn } from "../common/helpers";
import { useFeedbackRemarks, useSupplierInfo } from "../common/hooks";
import {
  genericButtonsStyles,
  genericInfoGroupStyles,
  genericRsrvFeebackStyles,
  rsrvFeebackStyles,
} from "../common/styles";
import { NormalInputField } from "@src/components/forms";
import {
  commonValidateFeedback,
  validateSupplierInfo,
} from "../common/validators";
import { toast } from "react-toastify";
import { postReservationFeedback } from "@src/api/Operation/Reservations/feedback";
import { basicInfoStylesObj } from "../accommodation/common";

const basicInfoStyles = createUseStyles({
  ...genericInfoGroupStyles,
  ...genericButtonsStyles,
  BasicInfo: genericRsrvFeebackStyles.card,
  header: genericRsrvFeebackStyles.cardHeader,
  body: genericRsrvFeebackStyles.cardBody,
  groupCard: basicInfoStylesObj.groupCard,
  infoCouple: {
    ...genericInfoGroupStyles.infoCouple,
    "& input": { width: "15rem" },
  },
  numericInfoCouple: {
    ...genericInfoGroupStyles.infoCouple,
    "& input": { width: "8rem" },
  },
  infoGroup2: {
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr)",
    gridGap: variables.normal_gap,
  },
  infoGroup3: {
    ...basicInfoStylesObj.groupCard,
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridGap: variables.normal_gap,
  },
  infoGroup4: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: variables.normal_gap,
  },
  actions: {
    display: "grid",
    justifyContent: "end",
    padding: variables.normal_gap,
  },
  [`@media ${variables.media.bigscreen}`]: {
    infoGroup2: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    infoGroup4: {
      gridTemplateColumns: "repeat(4, 1fr)",
    },
  },
});
// const basicInfoStyles = createUseStyles({
//   BasicInfo: cardStyles.card,
//   header: cardStyles.header,
//   table: tableStyles.table,
//   head: tableStyles.head,
//   cell: tableStyles.cell,
//   pricecell: {
//     ...tableStyles.cell,
//     "width": "5rem",
//     "& input": {
//       width: "5rem",
//     },
//   },
// });
function BasicInfo({ step, reservation, editable, onConfirm }) {
  const classes = basicInfoStyles();
  const [viewMode, setViewMode] = useState(editable ? false : true);

  return (
    <div className={classes.BasicInfo}>
      <div className={classes.header}>
        <h5>Basic Information</h5>
      </div>
      <Formik
        initialValues={{
          currency: reservation.currency,
          price: parseFloat(_.get(reservation, "original_service_price", 0)),
          reference: "",
        }}
        onSubmit={(values) => {
          setViewMode(true);
          onConfirm(values);
        }}>
        {({ values }) => (
          <Form className={classes.body}>
            <div className={classes.groupCard}>
              <div className={classes.infoGroup2}>
                <div className={classes.infoCouple}>
                  <span className={classes.infoCoupleTitle}>
                    Supplier Reference
                  </span>
                  <span className={classes.infoCoupleValue}>
                    {!viewMode ? (
                      <NormalInputField name="reference" />
                    ) : (
                      <span>{values.reference || "N/A"}</span>
                    )}
                  </span>
                </div>
                <div className={classes.infoCouple}>
                  <span className={classes.infoCoupleTitle}>Name</span>
                  <span className={classes.infoCoupleValue}>
                    {reservation.name}
                  </span>
                </div>
                <div className={classes.infoCouple}>
                  <span className={classes.infoCoupleTitle}>Destination</span>
                  <span className={classes.infoCoupleValue}>{"N/A"}</span>
                </div>
              </div>
            </div>
            <div className={classes.infoGroup3}>
              <div className={classes.infoCouple}>
                <span className={classes.infoCoupleTitle}>Pax</span>
                <span className={classes.infoCoupleValue}>
                  {reservation.pax}
                </span>
              </div>
              <div className={classes.infoCouple}>
                <span className={classes.infoCoupleTitle}>Adults</span>
                <span className={classes.infoCoupleValue}>
                  {reservation.adults}
                </span>
              </div>
              <div className={classes.infoCouple}>
                <span className={classes.infoCoupleTitle}>Children</span>
                <span className={classes.infoCoupleValue}>
                  {reservation.children}
                </span>
              </div>
            </div>
            <div className={classes.infoGroup2}>
              <div className={classes.groupCard}>
                <div className={classes.infoGroup2}>
                  <div className={classes.infoCouple}>
                    <span className={classes.infoCoupleTitle}>
                      Travel Date From
                    </span>
                    <span className={classes.infoCoupleValue}>
                      {DateTime.fromISO(
                        reservation.travel_date_from
                      ).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
                    </span>
                  </div>
                  <div className={classes.infoCouple}>
                    <span className={classes.infoCoupleTitle}>
                      Travel Date To
                    </span>
                    <span className={classes.infoCoupleValue}>
                      {" "}
                      {DateTime.fromISO(
                        reservation.travel_date_to
                      ).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
                    </span>
                  </div>
                </div>
              </div>
              <div className={classes.groupCard}>
                <div className={classes.infoGroup2}>
                  <div className={classes.infoCouple}>
                    <span className={classes.infoCoupleTitle}>Currency</span>
                    <span className={classes.infoCoupleValue}>
                      {reservation.currency} (
                      {_.get(currencies, reservation.currency)})
                    </span>
                  </div>
                  <div className={classes.numericInfoCouple}>
                    <span className={classes.infoCoupleTitle}>Price</span>
                    <span className={classes.infoCoupleValue}>
                      {viewMode ? (
                        <span>{values.price}</span>
                      ) : (
                        <NormalInputField name="price" type="number" />
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {step !== 2 && (
              <td className={classes.actions}>
                {viewMode ? (
                  <button
                    className={classes.cancelButton}
                    onClick={(e) => {
                      e.preventDefault();
                      setViewMode(false);
                    }}>
                    <strong>Edit</strong>
                  </button>
                ) : (
                  <button className={classes.confirmButton} type="submit">
                    <strong>Confirm</strong>
                  </button>
                )}
              </td>
            )}

            {/* <table className={classes.table}>
              <thead className={classes.head}>
                <tr>
                  <th>Supplier Reference</th>
                  <th>Name</th>
                  <th>Destination</th>
                  <th>Travel Date From</th>
                  <th>Travel Date To</th>
                  <th>Pax</th>
                  <th>Adults</th>
                  <th>Children</th>
                  <th>Currency</th>
                  <th>Price</th>
                  {step !== 2 && <th>Actions</th>}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={classes.cell}>
                    {!viewMode ? (
                      <NormalInputField name="reference" />
                    ) : (
                      <span>{values.reference || "N/A"}</span>
                    )}
                  </td>
                  <td className={classes.cell}>{reservation.name}</td>
                  <td className={classes.cell}></td>
                  <td className={classes.cell}>
                    {DateTime.fromISO(
                      reservation.travel_date_from
                    ).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
                  </td>
                  <td className={classes.cell}>
                    {DateTime.fromISO(
                      reservation.travel_date_to
                    ).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
                  </td>
                  <td className={classes.cell}>{reservation.pax}</td>
                  <td className={classes.cell}>{reservation.adults}</td>
                  <td className={classes.cell}>{reservation.children}</td>
                  <td className={classes.cell}>
                    {reservation.currency} (
                    {_.get(currencies, reservation.currency)})
                  </td>
                  <td className={classes.pricecell}>
                    {viewMode ? (
                      <span>{values.price}</span>
                    ) : (
                      <NormalInputField name="price" type="number" />
                    )}
                  </td>
                  {step !== 2 && (
                    <td className={classes.cell}>
                      {viewMode ? (
                        <Button
                          appearance="ghost"
                          size="xs"
                          onClick={(e) => {
                            e.preventDefault();
                            setViewMode(false);
                          }}>
                          <strong>Edit</strong>
                        </Button>
                      ) : (
                        <Button color="green" size="xs" type="submit">
                          <strong>Confirm</strong>
                        </Button>
                      )}
                    </td>
                  )}
                </tr>
              </tbody>
            </table> */}
          </Form>
        )}
      </Formik>
    </div>
  );
}
BasicInfo.propTypes = {
  step: PropTypes.number.isRequired,
  editable: PropTypes.bool.isRequired,
  reservation: PropTypes.object.isRequired,
  onConfirm: PropTypes.object.isRequired,
};

function validateFeedback({
  cxl_remarks,
  booking_mode_remarks,
  reservation_remarks,
  payment_mode_remarks,
  request_cxl_policy,
  cxl_rules,
}) {
  const msgs = commonValidateFeedback({
    cxl_remarks,
    booking_mode_remarks,
    reservation_remarks,
    payment_mode_remarks,
    request_cxl_policy,
    cxl_rules,
  });

  return msgs;
}

const GeneralServiceResFeebackStyles = createUseStyles({
  ...genericRsrvFeebackStyles,
  GeneralServiceResFeeback: rsrvFeebackStyles,
  cardBody: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridGap: "1.5rem",
    overflow: "auto",
  },
  leftCol: {
    width: "100%",
  },
  rightCol: {
    width: "100%",
    display: "grid",
    gridGap: variables.normal_gap,
  },
  [`@media ${variables.media.bigscreen}`]: {
    cardBody: {
      gridTemplateColumns: "30% 65%",
    },
  },
});
const GeneralServiceResFeeback = () => {
  const [step, setStep] = useState(0);
  const [reservation, setReservation] = useState({});
  const [loading, setLoading] = useState(false);

  const {
    params: { reference },
  } = useRouteMatch();

  const history = useHistory();

  const feedRemarks = useFeedbackRemarks();
  const supplierInfo = useSupplierInfo();
  const [cxl_rules, setCxlRules] = useState([cxlRuleTemplate]);
  const [confirmData, setConfirmData] = useState({});

  function nextStepper() {
    var msgs = [];
    if (step === 0) {
      msgs = validateFeedback({
        cxl_remarks: feedRemarks.cxl_remarks,
        booking_mode_remarks: feedRemarks.booking_mode_remarks,
        reservation_remarks: feedRemarks.reservation_remarks,
        payment_mode_remarks: feedRemarks.payment_mode_remarks,
        request_cxl_policy: reservation.request_cxl_policy,
        cxl_rules,
      });
    } else if (step === 1) {
      msgs = validateSupplierInfo({
        supplier_name: supplierInfo.supplier_name,
        supplier_email: supplierInfo.supplier_email,
        supplier_phone: supplierInfo.supplier_phone,
      });
    }
    if (msgs.length) {
      msgs.forEach((error) => toast.error(error, { duration: 5000 }));
      return;
    }

    setStep((p) => p + 1);
  }

  const classes = GeneralServiceResFeebackStyles();

  const actionProps = {
    step,
    history,
    prevStepper: () => setStep((p) => p - 1),
    nextStepper,
    onSubmit: () => {
      const payload = payloader({
        supplierInfo,
        feedRemarks,
        cxl_rules,
        reference: reservation.reference,
        feedback_service_data: { basic_info: confirmData },
        reservation_type: "GEN",
      });
      onSubmit({
        payload,
        history,
        setLoading,
        postReservationFeedback: (payload) =>
          postReservationFeedback(payload, () =>
            toast.error(
              "Reservation feedback creation failed. We are sorry for the inconvenience.",
              { duration: 5000 }
            )
          ),
      });
    },
    onReject: () =>
      onReject({
        history,
        reference,
        payload: { status: "RJ" },
        setLoading,
        resPatchFunc: patchPublicGenSrvReservation,
      }),
  };

  useEffect(() => {
    if (reference) {
      retrieverFn({
        reference,
        fn: retrievePublicGenSrvReservation,
        setLoading,
        setReservation,
      });
    }
  }, [reference]);

  return (
    <div className={classes.GeneralServiceResFeeback}>
      <Stepper step={step} />
      {loading ? (
        <div className={classes.cardBody}>
          <Loader size="lg" center />
        </div>
      ) : [0, 2].includes(step) ? (
        <div>
          {/* <div className={classes.card}> */}
          {/* <div className={classes.cardHeader}>
            <h5>Reservation Confirmation Panel: {reference}</h5>
          </div> */}
          <div className={classes.cardBody}>
            <div className={classes.leftCol}>
              <RequestInfo reservation={reservation} />
            </div>
            <div className={classes.rightCol}>
              <BasicInfo
                step={step}
                editable={step === 0}
                reservation={reservation}
                onConfirm={(data) => setConfirmData(data)}
              />
              <CommonFeedback
                reservation={reservation}
                feedRemarks={feedRemarks}
                editable={step == 0}
                cxl_rules={cxl_rules}
                setCxlRules={setCxlRules}
              />
              <Actions {...actionProps} />
            </div>
          </div>
        </div>
      ) : (
        <ContactInformation
          {...supplierInfo}
          actionProps={actionProps}
          onChange={(values) => {
            supplierInfo.setSupplierName(values.supplier_name);
            supplierInfo.setSupplierEmail(values.supplier_email);
            supplierInfo.setSupplierPhone(values.supplier_phone);
            supplierInfo.setSupplierRemarks(values.supplier_remarks);
          }}
        />
      )}
    </div>
  );
};

export default GeneralServiceResFeeback;
