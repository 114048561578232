import { Notification } from "rsuite";

import React from "react";

export const notifySelectService = () => {
  Notification.warning({
    title: "Booking Information Warning",
    description: <p>Please select at least one service for booking first.</p>,
    duration: 10000,
    placement: "bottomEnd",
  });
};

export const notifySelectTrpService = () => {
  Notification.warning({
    title: "Booking Information Warning",
    description: (
      <p>Please select at least one transportation service first.</p>
    ),
    duration: 10000,
    placement: "bottomEnd",
  });
};

export const notifyAcceptFlightBookingInfo = () => {
  Notification.warning({
    title: "Flight Booking Information Warning",
    description: <p>You must first accept the information for all flights.</p>,
    duration: 10000,
  });
};

export const notifyNotAllFlightRulesAccepted = () => {
  Notification.warning({
    title: "Booking Validation Warning",
    description: (
      <p>
        Not all <strong>Flight Rules</strong> are accepted.{" "}
      </p>
    ),
    duration: 10000,
    placement: "bottomEnd",
  });
};

// ====================== ACCOMMODATION PREBOOK ======================
export const notifyAcceptAllTermsForRooms = () => {
  Notification.warning({
    title: "Accommodation Prebook Warning",
    description: (
      <p>
        Please accept all <strong>Prebook Terms & Conditions</strong> for each
        room first.
      </p>
    ),
    duration: 10000,
  });
};

// ========================= TRANSFER PREBOOK ========================
export const notifyAcceptAllTermsForTrfs = () => {
  Notification.warning({
    title: "Transfer Prebook Warning",
    description: (
      <p>
        Please accept all <strong>Terms & Conditions</strong> for each transfer
        first.
      </p>
    ),
    duration: 10000,
  });
};

export const notifyTransferPrebookNotAccepted = () => {
  Notification.warning({
    title: "Booking Validation Warning",
    description: (
      <p>
        Please accept <strong>Transfer Prebook</strong> information first.
      </p>
    ),
    duration: 10000,
    placement: "bottomEnd",
  });
};

export const notifyNotEnoughDepositBalance = () => {
  Notification.warning({
    title: "Booking Validation Warning",
    description: (
      <p>
        Your current <strong>Deposit Balance</strong> is not sufficient. Please
        add funds to continue this booking.
      </p>
    ),
    duration: 10000,
    placement: "bottomEnd",
  });
};

export const notifyBookingInitializationFailed = () => {
  Notification.error({
    title: "Booking Failed",
    description: (
      <p>
        The booking initialization process has failed. We are sorry for the
        inconvenience.
      </p>
    ),
    duration: 6000,
  });
};
