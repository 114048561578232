import axios from "axios";
import { getAuthHeaders } from "../../../request";
import { SISI_API_URL } from "@src/api";

export async function fetchActivityReservations({
  page,
  page_size,
  filters = {},
}) {
  const auth_headers = getAuthHeaders();
  return await axios.get(`${SISI_API_URL}/reservations/activities`, {
    params: { page, page_size, ...filters },
    headers: { ...auth_headers },
  });
}

export async function retrieveActivityReservation({ reference }) {
  const auth_headers = getAuthHeaders();
  return await axios.get(
    `${SISI_API_URL}/reservations/activities/${reference}/`,
    {
      params: {},
      headers: { ...auth_headers },
    }
  );
}

export async function patchActivityReservation({ reference, payload }) {
  const auth_headers = getAuthHeaders();
  return await axios.patch(
    `${SISI_API_URL}/reservations/activities/${reference}/`,
    payload,
    {
      params: {},
      headers: { ...auth_headers },
    }
  );
}

export async function getActivitySupplierVoucher({
  activity_uuid,
  supplier_reservation_uid,
  provider,
  api_uid,
}) {
  try {
    const result = await axios.get(`${SISI_API_URL}/activities/voucher/`, {
      params: {
        activity_uuid,
        supplier_reservation_uid,
        provider,
        api_uid,
      },
    });
    return result.data;
  } catch (error) {
    return null;
  }
}

export async function getActivityStats(params) {
  const auth_headers = getAuthHeaders();
  return await axios.get(`${SISI_API_URL}/reservations/activities/stats/`, {
    params,
    headers: { ...auth_headers },
  });
}
