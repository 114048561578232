import { Steps, IconButton, Icon } from "rsuite";
import TransTxt from "@src/components/common/SxFormatMessage";
import AddonSetupController from "./Controllers/SetupController";
import AddOnPreferences from "./Controllers/AddOnPreferences";

import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import ItemsSetupController from "./Controllers/ItemsSetupController";
import {
  initAddOn,
  nextStep,
  prevStep,
  loadAddOnById,
} from "@src/actions/Operation/CustomServices/AddOn";
import { getProvidersList } from "@src/actions/Network/Suppliers";
import AddOnMap from "./Components/AddOnMap";
import FinalPricing from "./Controllers/FinalPricing";
import NotifierErrorBoundary from "@src/components/common/NotifierErrorBoundary";
import SaveModal from "./Components/SaveModal";
import LoadingModal from "./Components/LoadingModal";
import GeneralDescriptionModal from "./Components/modals/GeneralDescriptionModal";
import MeetingPointPOIModal from "./Components/MeetingPointPOIModal";
import PaxScallingModal from "./Modals/PaxScallingModal";
import ItemContentModal from "./Components/ItemContentModal";
import ItemDepartureMetaModal from "./Components/ItemDepartureMetaModal";
import { HelpBtn } from "@src/components/layout/MainHeader";
import AddOnImagesModal from "./Components/modals/AddonImagesModal";
import { createUseStyles } from "react-jss";
import { variables } from "@src/jsssetup";
import SaveAsModal from "./Modals/save_as_modal";
import { useContext } from "react";
import { Prompt } from "react-router-dom";
import { withAppUserGroup } from "@src/components/authorization/permissionhocs";

const headerStyles = createUseStyles({
  Header: (props) => ({
    display: "grid",
    gridTemplateColumns:
      props.currentStep === 1
        ? "10fr 1fr auto"
        : props.currentStep === 3
        ? "1fr 10fr auto"
        : "1fr 10fr 1fr auto",
    gridGap: variables.normal_gap,
    padding: `calc(${variables.normal_gap} / 2)`,
    alignItems: "center",
    boxShadow: variables.shadows.normalShadow,
  }),
});
const Header = ({ currentStep, onNextStep, onPrevStep }) => {
  const classes = headerStyles({ currentStep });

  function getHelpUrl() {
    switch (currentStep) {
      case 1:
        return "https://b2btrips.bit.ai/docs/view/Bs5sGgKmtTSEw87U";
      case 2:
        return "https://b2btrips.bit.ai/docs/view/AmKkhXRlBpPv2tFE";
      case 3:
        return "https://b2btrips.bit.ai/docs/view/HomqaoIwex75ZrLa";
      default:
        return "https://b2btrips.bit.ai/docs/view/Bs5sGgKmtTSEw87U";
    }
  }

  return (
    <div className="AddonHeader" data-step={currentStep}>
      {currentStep !== 1 && (
        <IconButton
          className="AddOn__top-section__header__previous-btn"
          icon={<Icon icon="left" />}
          onClick={onPrevStep}>
          <TransTxt id="previous_step" startCase />
        </IconButton>
      )}
      <Steps current={currentStep - 1}>
        <Steps.Item title="Add-on Setup" />
        <Steps.Item title="Preferences" />
        <Steps.Item title="Pricing" />
      </Steps>
      {currentStep !== 3 && (
        <IconButton
          className="AddOn__top-section__header__next-btn"
          icon={<Icon icon="right" />}
          placement="right"
          onClick={onNextStep}>
          <TransTxt id="next_step" startCase />
        </IconButton>
      )}
      <HelpBtn url={getHelpUrl()} />
    </div>
  );
};
Header.propTypes = {
  currentStep: PropTypes.number.isRequired,
  onNextStep: PropTypes.func.isRequired,
  onPrevStep: PropTypes.func.isRequired,
};

const addonTopSectionStyles = createUseStyles({
  AddonTopSection: {
    borderBottom: `4px solid ${variables.colors.borders.base}`,
  },
});
const AddonTopSection = ({ currentStep, onNextStep, onPrevStep }) => {
  const classes = addonTopSectionStyles();
  return (
    <div className={classes.AddonTopSection}>
      <Header
        currentStep={currentStep}
        onNextStep={onNextStep}
        onPrevStep={onPrevStep}
      />
      <AddonSetupController />
    </div>
  );
};
AddonTopSection.propTypes = {
  currentStep: PropTypes.number.isRequired,
  onNextStep: PropTypes.func.isRequired,
  onPrevStep: PropTypes.func.isRequired,
};

const BottomContext = React.createContext({ mapVersion: 0 });

const BottomLeftControllerStyles = createUseStyles({
  BottomLeftController: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
  },
});
const BottomLeftController = (props) => {
  const { currentStep } = props;

  const classes = BottomLeftControllerStyles();
  return (
    <div className={classes.BottomLeftController}>
      {currentStep === 1 && <ItemsSetupController />}
    </div>
  );
};
BottomLeftController.defaultProps = {
  currentStep: 1,
};
BottomLeftController.propTypes = {
  currentStep: PropTypes.number.isRequired,
};

const bottomRightControllerStyles = createUseStyles({
  BottomRightController: {
    height: "100%",
    overflow: "auto",
  },
});
const BottomRightController = ({ currentStep }) => {
  const classes = bottomRightControllerStyles();

  const { version: mapVersion } = useContext(BottomContext);

  return (
    <div className={classes.BottomRightController}>
      {currentStep == 1 ? (
        <AddOnMap
          shouldGeolocate={currentStep === 1}
          addonMapVersion={mapVersion}
        />
      ) : currentStep === 2 ? (
        <AddOnPreferences />
      ) : currentStep === 3 ? (
        <FinalPricing />
      ) : null}
    </div>
  );
};
BottomRightController.defaultProps = {
  currentStep: 1,
};
BottomRightController.propTypes = {
  currentStep: PropTypes.number.isRequired,
};

function btnPosition({ props, media = "" }) {
  if (props.leftState === "collapsed") {
    return "5%";
  }

  if (props.leftState === "expanded") {
    return "95%";
  }

  switch (media) {
    case "": {
      switch (props.currentStep) {
        case 2:
          return "40%";
        default:
          return "60%";
      }
    }
    case "smallscreen": {
      return "40%";
    }
    default:
      return "60%";
  }
}

const bottomSectionStyles = createUseStyles({
  BottomSection: {
    position: "relative",
    display: "grid",
    gridTemplateColumns: (props) => {
      switch (props.leftState) {
        case "collapsed":
          return "5% 95%";
        case "expanded":
          return "95% 5%";
      }

      switch (props.currentStep) {
        case 1:
          return "60% 40%";
        case 2:
          return "1fr";
        case 3:
          return "1fr";
        default:
          return "1fr";
      }
    },
    height: "100%",
    overflow: "hidden",
  },
  bottomLeft: {
    padding: variables.normal_gap,
    borderRight: `2px solid ${variables.colors.borders.base}`,
    overflow: "auto",
  },
  collapseBtn: {
    display: (props) => (props.currentStep !== 3 ? "block" : "none"),
    position: "absolute!important",
    left: (props) => btnPosition({ props, media: "" }),
    top: "50%",
    transform: "translate(-50%, -100%)",
    background: variables.colors.deepblue,
    color: "white",
    zIndex: 2,
  },
  expandBtn: {
    display: (props) => (props.currentStep !== 3 ? "block" : "none"),
    position: "absolute!important",
    left: (props) => btnPosition({ props, media: "" }),
    top: "50%",
    transform: "translate(-50%, 50%)",
    background: variables.colors.deepblue,
    color: "white",
    zIndex: 2,
  },
  [`@media ${variables.media.bigscreen}`]: {
    BottomSection: {
      gridTemplateColumns: (props) => {
        switch (props.leftState) {
          case "collapsed":
            return "5% 95%";
          case "expanded":
            return "95% 5%";
        }

        switch (props.currentStep) {
          case 2:
            return "1fr";
          case 3:
            return "1fr";
          default:
            return "40% 60%";
        }
      },
    },
    collapseBtn: {
      left: (props) => btnPosition({ props, media: "smallscreen" }),
    },
    expandBtn: {
      left: (props) => btnPosition({ props, media: "smallscreen" }),
    },
  },
});
const BottomSection = (props) => {
  const { currentStep } = props;
  const [leftState, setLeftState] = useState("normal");
  const [version, setVersion] = useState(0);
  const classes = bottomSectionStyles({ currentStep, leftState });

  function layoutChanger({ type = "collapsed" }) {
    switch (leftState) {
      case type:
        {
          setLeftState("normal");
          setVersion((p) => p + 1);
        }
        return;
      case "normal":
        {
          setLeftState(type);
          setVersion((p) => p + 1);
        }
        return;
      default:
        return;
    }
  }

  return (
    <BottomContext.Provider value={{ version }}>
      <div className={`bottom-section ${classes.BottomSection}`}>
        {![2, 3].includes(currentStep) && (
          <div className={classes.bottomLeft}>
            <BottomLeftController currentStep={currentStep} />
          </div>
        )}
        <div className="bottom-right">
          <BottomRightController currentStep={currentStep} />
        </div>
        {leftState !== "expanded" && ![2, 3].includes(currentStep) && (
          <IconButton
            circle
            className={classes.collapseBtn}
            color="blue"
            icon={
              <Icon
                icon={leftState === "collapsed" ? "angle-right" : "angle-left"}
              />
            }
            onClick={() => layoutChanger({ type: "collapsed" })}
          />
        )}
        {leftState !== "collapsed" && ![2, 3].includes(currentStep) && (
          <IconButton
            circle
            className={classes.expandBtn}
            color="blue"
            icon={
              <Icon
                icon={leftState === "expanded" ? "angle-left" : "angle-right"}
              />
            }
            onClick={() => layoutChanger({ type: "expanded" })}
          />
        )}
      </div>
    </BottomContext.Provider>
  );
};
BottomSection.propTypes = {
  currentStep: PropTypes.number.isRequired,
};

export const AddonModalsContext = React.createContext({
  saveAsModal: false,
  showPaxCallingModal: false,
});

const AddOn = ({
  match,
  init,
  loadAddOn,
  fetchSupplierData,
  onNextStep,
  onPrevStep,
}) => {
  const { currentStep } = useSelector((state) => state.customServiceAddOn);
  const [showAddonImgsModal, setShowAddonImgsModal] = useState(false);
  const [showSaveAsModal, setShowSaveAsModal] = useState(false);
  const [showPaxCallingModal, setShowPaxSCallingModal] = useState(false);
  const [showItemContentModal, setShowItemContentModal] = useState(false);

  useEffect(() => {
    init();
    fetchSupplierData();

    if (match.params.id) {
      loadAddOn(match.params.id);
    }
  }, [match.params.id]);

  return (
    <React.Fragment>
      <Prompt
        when={true}
        message="You might have unsaved changes. Please make sure you save your changes before leaving. Are you sure you want to leave?"
      />
      <AddonModalsContext.Provider
        value={{
          showSaveAsModal,
          setShowSaveAsModal,
          showPaxCallingModal,
          setShowPaxSCallingModal,
          setShowAddonImgsModal,
          setShowItemContentModal,
        }}>
        <div className="AddOn">
          <AddonTopSection
            currentStep={currentStep}
            onNextStep={onNextStep}
            onPrevStep={onPrevStep}
          />
          <BottomSection currentStep={currentStep} />
        </div>
        <NotifierErrorBoundary>
          <SaveModal />
        </NotifierErrorBoundary>
        <NotifierErrorBoundary>
          <LoadingModal />
        </NotifierErrorBoundary>
        <NotifierErrorBoundary>
          <GeneralDescriptionModal />
        </NotifierErrorBoundary>
        <NotifierErrorBoundary>
          <MeetingPointPOIModal />
        </NotifierErrorBoundary>
        {showPaxCallingModal && (
          <NotifierErrorBoundary>
            <PaxScallingModal onClose={() => setShowPaxSCallingModal(false)} />
          </NotifierErrorBoundary>
        )}
        {showItemContentModal && (
          <NotifierErrorBoundary>
            <ItemContentModal />
          </NotifierErrorBoundary>
        )}
        <NotifierErrorBoundary>
          <ItemDepartureMetaModal />
        </NotifierErrorBoundary>
        {showAddonImgsModal && (
          <NotifierErrorBoundary>
            <AddOnImagesModal onHide={() => setShowAddonImgsModal(false)} />
          </NotifierErrorBoundary>
        )}
        {showSaveAsModal && (
          <NotifierErrorBoundary>
            <SaveAsModal onCancel={() => setShowSaveAsModal(false)} />
          </NotifierErrorBoundary>
        )}
      </AddonModalsContext.Provider>
    </React.Fragment>
  );
};
AddOn.propTypes = {
  match: PropTypes.object.isRequired,
  init: PropTypes.func.isRequired,
  loadAddOn: PropTypes.func.isRequired,
  onNextStep: PropTypes.func.isRequired,
  onPrevStep: PropTypes.func.isRequired,
  fetchSupplierData: PropTypes.func.isRequired,
};
const mapDispatchToProps = (dispatch) => {
  return {
    init: () => dispatch(initAddOn()),
    loadAddOn: (id) => dispatch(loadAddOnById(id)),
    onNextStep: () => dispatch(nextStep()),
    onPrevStep: () => dispatch(prevStep()),
    fetchSupplierData: () => dispatch(getProvidersList()),
  };
};

export default withAppUserGroup(
  connect(null, mapDispatchToProps)(AddOn),
  ["OPERATION"],
  { view: true }
);
