import service, { serviceCxl } from "@src/api/request";
import axios from "axios";
import { SISI2_API_URL, VALAQUENTA_API_URL } from "..";

export const fetchTripPlan = (id) => {
  return service({
    url: `/bid/trip-plan/${id}`,
    method: "get",
  });
};

export const fetchTripPlanOffer = (id) => {
  return service({
    url: `/bid/trip-offer-trip-view/${id}`,
    method: "get",
  });
};

export const updateTripPlanOffer = (id, payload) => {
  return service({
    url: `/bid/trip-offer-trip-view/${id}/`,
    method: "patch",
    data: payload,
  });
};

const autocompleteTimeout = 20000;
export const destAutoComplete = (params) => {
  return serviceCxl(
    {
      url: `/bid/dest_autocomplete_aggregate_lvl/`,
      method: "get",
      params: params,
    },
    autocompleteTimeout
  );
};

export const poiAutoComplete = (params) => {
  return serviceCxl(
    {
      url: `/bid/poi-autocomplete/`,
      method: "get",
      params: params,
    },
    autocompleteTimeout
  );
};

export const poiDetails = (params) => {
  return serviceCxl(
    {
      url: `/bid/poi_details/`,
      method: "get",
      params: params,
    },
    60000
  );
};

export const poiGeodata = (params) => {
  return serviceCxl(
    {
      url: `/bid/poi_details/geodata_only`,
      method: "get",
      params: params,
    },
    60000
  );
};

export const autocompleteLvl1 = (params) => {
  return serviceCxl(
    {
      url: `/bid/dest_autocomplete_lvl1`,
      method: "get",
      params: params,
    },
    autocompleteTimeout
  );
};

export const autocompleteLvl2 = (params) => {
  return serviceCxl(
    {
      url: `/bid/dest_autocomplete_lvl2`,
      method: "get",
      params: params,
    },
    autocompleteTimeout
  );
};

export const autocompleteLvl3 = (params) => {
  return serviceCxl(
    {
      url: `/bid/dest_autocomplete_lvl3`,
      method: "get",
      params: params,
    },
    autocompleteTimeout
  );
};

export const autocompleteLvl4 = (params) => {
  return serviceCxl(
    {
      url: `/bid/dest_autocomplete_lvl4`,
      method: "get",
      params: params,
    },
    autocompleteTimeout
  );
};

export const infoLvl1 = (id) => {
  return service({
    url: `/bid/dest_info_lvl1/${id}`,
    method: "get",
  });
};

export const infoLvl2 = (id) => {
  return service({
    url: `/bid/dest_info_lvl2/${id}`,
    method: "get",
  });
};

export const infoLvl3 = (id) => {
  return service({
    url: `/bid/dest_info_lvl3/${id}`,
    method: "get",
  });
};

export const infoLvl4 = (id) => {
  return service({
    url: `/bid/dest_info_lvl4/${id}`,
    method: "get",
  });
};

export const infoLvl5 = (id) => {
  return service({
    url: `/bid/dest_info_lvl5/${id}`,
    method: "get",
  });
};

// ============================= FLIGHTS =============================

export const getCachedFlights = (payload) => {
  return serviceCxl(
    {
      url: `/bid/cached_flights/`,
      method: "get",
      params: payload,
    },
    15000
  );
};

// ========================== ACCOMMODATION ==========================

export const initAccSearch = async (payload) => {
  try {
    const result = await axios.post(
      `${SISI2_API_URL}/accengine/initiate_search/`,
      payload
    );
    return result.data;
  } catch (error) {
    return null;
  }
};

export const initAccSearchNew = (payload) => {
  return axios.post(`${SISI2_API_URL}/accengine/initiate_search/`, payload);
};

export const pollAccSearchNew = ({
  sessionId,
  best = false,
  rating,
  board,
  bedding,
  room_type,
  result_id = null,
  priority_ids = [],
  priority_behaviour = null,
}) => {
  var params = { session_id: sessionId, best };

  if (best) {
    params = {
      ...params,
      ...{
        rating: rating.map((r) => parseInt(r, 10)).join(","),
        board,
        bedding,
        room_type,
        result_id,
      },
    };

    if (priority_behaviour) params = { ...params, priority_behaviour };
  }

  if (priority_ids.length) params["priority_ids"] = priority_ids.join(",");

  return axios.get(`${SISI2_API_URL}/accengine/poll/`, { params });
};

export const pollAccSearch = async ({
  sessionId,
  best = false,
  rating,
  board,
  bedding,
  room_type,
  result_id = null,
  priority_ids = [],
  priority_behaviour = null,
}) => {
  var params = { session_id: sessionId, best };

  if (best) {
    params = {
      ...params,
      ...{
        rating: rating.map((r) => parseInt(r, 10)).join(","),
        board,
        bedding,
        room_type,
        result_id,
      },
    };

    if (priority_behaviour) {
      params = { ...params, priority_behaviour };
    }
  }

  if (priority_ids.length) {
    params["priority_ids"] = priority_ids.join(",");
  }

  try {
    const result = await axios.get(`${SISI2_API_URL}/accengine/poll/`, {
      params,
    });
    return result.data;
  } catch (error) {
    return null;
  }
};

export const pollAllAccs = async ({ session_id, market_type, lean }) => {
  return axios.get(`${SISI2_API_URL}/accengine/poll/`, {
    params: { session_id, market_type, lean },
  });
};

export function fetchFullHotelMetaData(hotelId) {
  return axios.post(`${VALAQUENTA_API_URL}/accommodator/hotels-metadata/`, {
    hotel_ids: [hotelId],
    supplier: "hotelston",
  });
}

export function fetchSimpleHotelMetaData(hotelId, supplier) {
  return axios.post(
    `${VALAQUENTA_API_URL}/accommodator/hotels-metadata/simple`,
    {
      hotel_ids: [hotelId],
      supplier,
    }
  );
}

export const accSearchDetails = async (session_id, acc_id) => {
  return await axios.get(`${SISI2_API_URL}/accengine/details/`, {
    params: { session_id, acc_id: parseInt(acc_id, 10) },
  });
};

export const accCxlDetails = (session_id, acc_id, booking_code, supplier) => {
  return axios.get(`${SISI2_API_URL}/accengine/cancellation_details/`, {
    params: {
      session_id,
      acc_id: parseInt(acc_id, 10),
      booking_code,
      supplier,
    },
  });
};

export const accPrebook = async ({
  session_id,
  acc_id,
  booking_codes,
  supplier,
  uids,
}) => {
  try {
    const result = await axios.post(`${SISI2_API_URL}/accengine/prebook/`, {
      session_id,
      acc_id: parseInt(acc_id, 10),
      booking_code: booking_codes,
      supplier,
      uids,
    });
    return result.data;
  } catch (error) {
    return null;
  }
};

// ============================ TRIP CALLS ===========================

export const tripSave = (payload) => {
  return serviceCxl(
    { url: `/bid/trip-plan/`, method: "post", data: payload },
    10000
  );
};

export const tripUpdate = (payload, id) => {
  return serviceCxl(
    { url: `/bid/trip-plan/${id}/`, method: "patch", data: payload },
    20000
  );
};

export const tripBook = (payload) => {
  return service({ url: "/bid/servicebundle/", method: "post", data: payload });
};

export const fetchBookStatus = (uid) => {
  return service({ url: `/bid/servicebundle-status/${uid}/`, method: "get" });
};

export const updateOffer = ({ payload, id }) => {
  return service({
    url: `/bid/trip-offer/${id}/`,
    method: "post",
    data: payload,
  });
};

export const fetchOfferBookingInfo = (reference) => {
  return service({
    url: `/bid/trip-offer/${reference}/booking-info/`,
    method: "get",
  });
};

export const fetchAddonsPrice = (params) => {
  return service({ url: "/bid/search-addons/", method: "get", params });
};

export const searchAddOnById = (id, params) => {
  return service({ url: `/bid/search-addons/${id}/`, method: "get", params });
};

export * from "./flights";
export * from "./transfers";
