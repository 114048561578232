import axios from "axios";
import service from "@src/api/request";
import { getAuthHeaders } from "@src/api/request";
import { SISI_API_URL } from "@src/api";

export function getAddonsList(para) {
  return service({
    url: "bid/addonreservation/",
    method: "get",
    params: para,
  });
}

export async function fetchAddonsReservationInfo(reference) {
  const auth_headers = getAuthHeaders();
  return await axios.get(`/bid/addonreservation/${reference}/`, {
    headers: { ...auth_headers },
  });
}

export async function submitAddonReservationChange(reference, payload) {
  const auth_headers = getAuthHeaders();
  return await axios.patch(`/bid/addonreservation/${reference}/`, payload, {
    headers: { ...auth_headers },
  });
}

export async function getAddonStats(params) {
  const auth_headers = getAuthHeaders();
  return await axios.get(`${SISI_API_URL}/reservations/addons/stats/`, {
    params,
    headers: { ...auth_headers },
  });
}

// =====================  DASHBOARDS ====================
export const getReservationAddOnStats = (param) => {
  return service({
    url: `bid/addons-stats/`,
    method: "get",
    params: param,
  });
};
