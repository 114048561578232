import React, { createContext, useEffect, useState } from "react";

import "nprogress/nprogress.css";
import "./style/icon/iconfont.css";
import "@src/less/base.less";

import { HashRouter as Router, Route, useLocation } from "react-router-dom";
import { Provider } from "react-redux";
import langEnDictionary from "@src/i18n/lang_en";
import langZhDictionary from "@src/i18n/lang_zh";
import langPtDictionary from "@src/i18n/lang_pt";
import langFrDictionary from "@src/i18n/lang_fr";
import langItDictionary from "@src/i18n/lang_it";
import langEsDictionary from "@src/i18n/lang_es";
import langNlDictionary from "./i18n/lang_nl";
import langDeDictionary from "./i18n/lang_de";
import { IntlProvider } from "react-intl";
import { ToastContainer } from "react-toastify";
import util from "@src/util";
import "react-toastify/dist/ReactToastify.css";
import store from "@src/store";
import Routes from "./routes/Routes";
import PublicRoutes from "./routes/public_routes";
import ChatDrawer from "./components/chat";
import NotifierErrorBoundary from "./components/common/NotifierErrorBoundary";
import { useQueries } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { getCompanyCompleteness, getProfileCompleteness } from "./api";
import { DynamicInvoiceModal } from "./components/DynamicInvoice";
import {
  InvoiceSetupAddEditModal,
  InvoiceSetupDeleteModal,
} from "./pages/Admin/ControlPanel/InvoiceSetup/list";
import {
  CompanyStampAddEditModal,
  CompanyStampDeleteModal,
} from "./pages/Admin/ControlPanel/CompanyStamps/list";
import DashboardACCModal from "./pages/Operation/B2BReservations/SingleServices/Common/StatsModal/DashboardModal";
import ApproveSubagentModal from "./pages/Network/Sales/SubAgents/ApproveModal";
import * as yup from "yup";

export const invoiceSetupSchema = yup.object().shape({
  reference: yup.string().required("Reference is required").default(""),
  productType: yup
    .string()
    .required("Product type is required")
    .oneOf(["offer", "addon"])
    .default("offer"),
  customerType: yup
    .string()
    .required("Customer type is required")
    .oneOf(["guest", "subagent"])
    .default("subagent"),
  targetEntity: yup.string().default(""),
});

export const GlobalModalContext = createContext({ showChatDrawer: false });
export const MobileViewContext = createContext({ mobileView: false });

const App = () => {
  const [lang, setLang] = useState("en");
  const [theme, __] = useState("default");

  const [id, setId] = useState(null);
  const [dashboardPayload, setDashboardPayload] = useState(null);
  const [showChatDrawer, setShowChatDrawer] = useState(false);
  const [defaultChatRoom, setDefaultChatRoom] = useState(null);

  const [showInvoiceSetupModal, setShowInvoiceSetupModal] = useState(false);
  const [invoiceSetup, setInvoiceSetup] = useState(invoiceSetupSchema.cast({}));
  const [showDynamicInvoiceModal, setShowDynamicInvoiceModal] = useState(false);
  const [showInvoiceDeleteModal, setShowInvoiceDeleteModal] = useState(false);

  const [stampId, setStampId] = useState(null);
  const [showStampModal, setShowStampModal] = useState(false);
  const [showStampDeleteModal, setShowStampDeleteModal] = useState(false);
  const [showDashboardModal, setShowDashboardModal] = useState(false);
  const [showSubAgentApprovalModal, setShowSubAgentApprovalModal] =
    useState(false);

  // Get company and profile completeness
  useQueries({
    queries: [
      {
        queryKey: ["companyCompleteness"],
        queryFn: () => getCompanyCompleteness(),
        notifyOnChangeProps: ["error"],
      },
      {
        queryKey: ["profileCompleteness"],
        queryFn: () => getProfileCompleteness(),
        notifyOnChangeProps: ["error"],
      },
    ],
  });

  store.subscribe(() => {
    const lang = store.getState().setLang;
    setLang(lang);
  });

  let messages = {};
  messages["en"] = langEnDictionary;
  messages["zh"] = langZhDictionary;
  messages["pt"] = langPtDictionary;
  messages["fr"] = langFrDictionary;
  messages["it"] = langItDictionary;
  messages["es"] = langEsDictionary;
  messages["nl"] = langNlDictionary;
  messages["de"] = langDeDictionary;

  // Control theming
  useEffect(() => {
    var lang = util.getCookie("lang") || "en";
    setLang(lang);
    document.body.className = `theme-${theme}`;
  }, []);

  const location = useLocation();
  const paths = [
    "/operation/B2B-reservations/accommodations",
    "/operation/reservations/accommodations",
    "/operation/B2C-reservations/accommodation",
    "/operation/B2B-reservations/transfer",
    "/operation/B2C-reservations/transfer",
    "/operation/reservations/transfer",
    "/operation/B2B-reservations/flights",
    "/operation/B2C-reservations/flights",
    "/operation/reservations/flights",
    "/operation/B2B-reservations/activities",
    "/operation/B2C-reservations/single-services/activities",
    "/operation/B2C-reservations/activities",
    "/operation/B2C-reservations/addons",
    "/operation/B2B-reservations/addons",
    "/operation/B2B-reservations/ferries",
    "/operation/B2B-reservations/single-services/ferries",
    "/operation/B2C-reservations/api-ferries",
    "/operation/B2C-reservations/single-services/api-ferries",
    "/operation/B2B-reservations/api-ferries",
    "/operation/B2B-reservations/single-services/api-ferries",
    "/operation/B2B-reservations/trains",
    "/operation/B2B-reservations/single-services/trains",
    "/operation/B2B-reservations/api-trains",
    "/operation/B2C-reservations/api-trains",
    "/operation/B2B-reservations/single-services/api-trains/",
    "/operation/B2C-reservations/car-rentals",
    "/operation/B2C-reservations/single-services/car-rental",
    "/operation/B2B-reservations/car-rentals",
    "/operation/B2B-reservations/single-services/car-rental",
    "/operation/B2B-reservations/adhoc-accommodations",
    "/operation/B2B-reservations/single-services/adhoc-accommodations",
    "/operation/B2B-reservations/contracted-accommodations",
    "/operation/B2B-reservations/single-services/contracted-accommodations",
    "/operation/B2B-reservations/coaches",
    "/operation/B2B-reservations/single-services/coaches",
    "/operation/B2B-reservations/coordinators",
    "/operation/B2B-reservations/single-services/coordinators",
    "/operation/B2B-reservations/general-services",
    "/my-library/general-services",
    "/operation/B2B-reservations/restaurants",
    "/operation/B2B-reservations/single-services/restaurants",
    "/operation/guests/b2c",
    "/operation/guests/b2c",
  ];
  const [mobileView, setMobileView] = useState(false);

  // Control mobile view logic
  useEffect(() => {
    if (paths.some((path) => location.pathname.includes(path))) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  }, [location.pathname]);

  return (
    <React.Fragment>
      <Provider store={store}>
        <IntlProvider locale={lang} messages={messages[lang]}>
          <Router>
            <GlobalModalContext.Provider
              value={{
                showDynamicInvoiceModal,
                setId,
                setDashboardPayload,
                setStampId,
                setShowChatDrawer,
                setDefaultChatRoom,
                setShowInvoiceSetupModal,
                setInvoiceSetup,
                setShowDynamicInvoiceModal,
                setShowInvoiceDeleteModal,
                setShowStampModal,
                setShowStampDeleteModal,
                setShowDashboardModal,
                setShowSubAgentApprovalModal,
              }}>
              <MobileViewContext.Provider value={mobileView}>
                <div
                  className="app"
                  style={{ fontFamily: "futura-pt,sans-serif" }}>
                  <Route
                    render={({ location }) => {
                      const basePath = location.pathname
                        .split("/")
                        .filter((p) => p)[0];
                      if (basePath === "public") {
                        return <PublicRoutes />;
                      } else {
                        return <Routes />;
                      }
                    }}
                  />
                  <ToastContainer />
                  <NotifierErrorBoundary>
                    <ChatDrawer
                      show={showChatDrawer}
                      defaultChatRoom={defaultChatRoom}
                      onHide={() => setShowChatDrawer(false)}
                    />
                  </NotifierErrorBoundary>
                  {showDynamicInvoiceModal && (
                    <NotifierErrorBoundary>
                      <DynamicInvoiceModal
                        invoiceInitialSetup={invoiceSetup}
                        onHide={() => setShowDynamicInvoiceModal(false)}
                      />
                    </NotifierErrorBoundary>
                  )}
                  {showInvoiceSetupModal && (
                    <NotifierErrorBoundary>
                      <InvoiceSetupAddEditModal
                        id={id}
                        invoiceInitialSetup={invoiceSetup}
                        onHide={() => {
                          setId(null), setShowInvoiceSetupModal(false);
                        }}
                      />
                    </NotifierErrorBoundary>
                  )}
                  {showInvoiceDeleteModal && (
                    <NotifierErrorBoundary>
                      <InvoiceSetupDeleteModal
                        id={id}
                        onHide={() => {
                          setId(null);
                          setShowInvoiceDeleteModal(false);
                        }}
                      />
                    </NotifierErrorBoundary>
                  )}
                  {showStampModal && (
                    <NotifierErrorBoundary>
                      <CompanyStampAddEditModal
                        id={stampId}
                        onHide={() => {
                          setStampId(null);
                          setShowStampModal(false);
                        }}
                      />
                    </NotifierErrorBoundary>
                  )}
                  {showStampDeleteModal && (
                    <NotifierErrorBoundary>
                      <CompanyStampDeleteModal
                        id={stampId}
                        onHide={() => {
                          setStampId(null);
                          setShowStampDeleteModal(false);
                        }}
                      />
                    </NotifierErrorBoundary>
                  )}
                  {showDashboardModal && (
                    <NotifierErrorBoundary>
                      <DashboardACCModal
                        dashboardPayload={dashboardPayload}
                        onClose={() => {
                          setDashboardPayload({});
                          setShowDashboardModal(false);
                        }}
                      />
                    </NotifierErrorBoundary>
                  )}
                  {showSubAgentApprovalModal && id && (
                    <ApproveSubagentModal
                      subagentId={id}
                      onClose={() => {
                        setId(null);
                        setShowSubAgentApprovalModal(false);
                      }}
                    />
                  )}
                </div>
              </MobileViewContext.Provider>
            </GlobalModalContext.Provider>
          </Router>
        </IntlProvider>
      </Provider>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </React.Fragment>
  );
};

export default App;
