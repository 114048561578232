import moment from "moment";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";
import { InputNumber, SelectPicker, Table, Tag } from "rsuite";
import { naString } from "@src/tools/string_tools";
import { changeCxlPolicy } from "@src/actions/Project/TripPlanner";

class MktCxlSection extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    var { periods, onChange } = this.props;

    periods = periods.map((period, idx) => ({ ...period, idx }));

    return (
      <div className="MktSectionsPanel__cxl">
        <Table data={periods} rowHeight={60}>
          <Table.Column flexGrow={1} verticalAlign="middle">
            <Table.HeaderCell>Distribution From - To</Table.HeaderCell>
            <Table.Cell>
              {(rowData) => (
                <span>
                  {rowData.distribution_from
                    ? moment(rowData.distribution_from).format("DD/MM/YYYY")
                    : naString()}{" "}
                  -{" "}
                  {rowData.distribution_to
                    ? moment(rowData.distribution_to).format("DD/MM/YYYY")
                    : naString()}
                </span>
              )}
            </Table.Cell>
          </Table.Column>
          <Table.Column flexGrow={1} verticalAlign="middle">
            <Table.HeaderCell>Operation From - To</Table.HeaderCell>
            <Table.Cell>
              {(rowData) => (
                <span>
                  {rowData.operation_from
                    ? moment(rowData.operation_from).format("DD/MM/YYYY")
                    : naString()}{" "}
                  -{" "}
                  {rowData.operation_to
                    ? moment(rowData.operation_to).format("DD/MM/YYYY")
                    : naString()}
                </span>
              )}
            </Table.Cell>
          </Table.Column>
          <Table.Column width={200} verticalAlign="middle">
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.Cell>
              {(rowData) => (
                <SelectPicker
                  value={rowData.cancellation_policy_data.type}
                  cleanable={false}
                  data={[
                    { label: "Non-Refundable", value: "non-refundable" },
                    { label: "Chargeable", value: "chargeable" },
                  ]}
                  renderMenuItem={(label, item) => (
                    <Tag
                      color={item.value == "non-refundable" ? "red" : "orange"}>
                      <strong>{label}</strong>
                    </Tag>
                  )}
                  renderValue={(value, item) => (
                    <Tag color={value == "non-refundable" ? "red" : "orange"}>
                      <strong>{item.label}</strong>
                    </Tag>
                  )}
                  searchable={false}
                  onChange={(value) => onChange(rowData.idx, "type", value)}
                />
              )}
            </Table.Cell>
          </Table.Column>
          <Table.Column width={150} verticalAlign="middle">
            <Table.HeaderCell>Days Ahead</Table.HeaderCell>
            <Table.Cell>
              {(rowData) => (
                <InputNumber
                  value={rowData.cancellation_policy_data.days_ahead}
                  onChange={function (value) {
                    onChange(rowData.idx, "days_ahead", value);
                  }}
                />
              )}
            </Table.Cell>
          </Table.Column>
          <Table.Column width={150} verticalAlign="middle">
            <Table.HeaderCell>Fee</Table.HeaderCell>
            <Table.Cell>
              {(rowData) => (
                <InputNumber
                  prefix="%"
                  value={rowData.cancellation_policy_data.fee.value}
                  onChange={function (value) {
                    onChange(rowData.idx, "fee", {
                      ...rowData.cancellation_policy_data.fee,
                      value,
                    });
                  }}
                />
              )}
            </Table.Cell>
          </Table.Column>
        </Table>
      </div>
    );
  }
}

MktCxlSection.propTypes = {
  periods: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { periods } = state.tripPlannerMktDistributionPeriods;
  return { periods };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChange: (idx, key, value) => dispatch(changeCxlPolicy(idx, key, value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MktCxlSection);
