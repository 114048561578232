import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { Icon, IconButton, Panel, Rate, Tag, Button, Loader } from "rsuite";
import { formatDate } from "@src/tools/date_tools";
import { naString } from "@src/tools/string_tools";
import { createUseStyles } from "react-jss";
import { variables } from "@src/jsssetup";
import { connect, useSelector } from "react-redux";
import { showServiceCommentsModal } from "@src/actions/Project/TripPlanner/service_comments";
import {
  srvHeaderStyles,
  srvHeaderTitleActionsStyles,
  srvHeaderTitleStyles,
} from "../../Controllers/OverviewDestination";
import { isServiceFilterSelectedSelector } from "@src/selectors/Project/TripPlanner/service_filters";
import LeanAuthHoc from "@src/components/authorization/LeanAuthHoc";
const ghostImg =
  "https://sisistaticassets.s3.ap-southeast-1.amazonaws.com/platform_graphics/imgs/others/destination_placeholder.jpg";

export const tripServiceStyles = {
  service: {
    "marginBottom": 0,
    "& > .rs-panel-body": {
      display: "grid",
      gridTemplateColumns: "auto 1fr",
      gridGap: variables.normal_gap,
      background: "white",
      alignItems: "center",
    },
  },
  imageContainer: {
    width: "170px",
    height: "100px",
    borderRadius: "5px",
    position: "relative",
  },
  image: {
    width: "100%",
    height: "100%",
    borderRadius: "5px",
    objectFit: "cover",
  },
  content: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
  },
};

const accServicePanelStyles = createUseStyles({
  service: { ...tripServiceStyles.service },
  imageContainer: { ...tripServiceStyles.imageContainer },
  image: { ...tripServiceStyles.image },
  content: {
    "display": "grid",
    "gridTemplateColumns": "auto 1fr",
    "gridGap": variables.normal_gap,
    "& > div:first-of-type": {
      display: "grid",
      gridGap: `calc(${variables.normal_gap} / 2)`,
    },
    "& > div:last-of-type": {
      "& p": {
        maxHeight: "70px",
        overflowY: "auto",
      },
    },
    "& h1, h2, h3, h4, h5": {
      fontSize: "small",
    },
  },
});
var AccommodationServicePanel = (props) => {
  const {
    accommodation,
    selectedRooms,
    mode,
    services_collapsed,
    onShowServiceComments,
  } = props;

  const classes = accServicePanelStyles();
  const rooms = accommodation.rooms.filter((room) => room.selected);
  const refundable = rooms.every((r) =>
    r.cancellation_policies.some((c) => c.type == "refundable")
  );

  var customer_remarks = accommodation.customer_remarks;
  if (typeof customer_remarks !== "undefined") {
    customer_remarks = new DOMParser().parseFromString(
      accommodation.customer_remarks,
      "text/html"
    ).body.textContent;
  }

  return (
    <Panel
      className={"CustomPanel " + classes.service}
      header={
        <React.Fragment>
          <h6>
            {accommodation.metadata.name} - Check In:{" "}
            {formatDate(_.get(accommodation, "operational_data.check_in"))} -
            Check Out:{" "}
            {formatDate(_.get(accommodation, "operational_data.check_out"))}
          </h6>
          <div className="CustomPanel__header-actions">
            {mode === "prebook" ? (
              <IconButton
                icon={<Icon icon="comment" />}
                appearance="primary"
                color={
                  typeof customer_remarks !== "undefined" &&
                  Boolean(customer_remarks)
                    ? ""
                    : "green"
                }
                size="xs"
                onClick={onShowServiceComments}>
                <strong>
                  {typeof customer_remarks !== "undefined" &&
                  Boolean(customer_remarks)
                    ? "Edit Booking Remarks"
                    : "Add Booking Remarks"}
                </strong>
              </IconButton>
            ) : null}
          </div>
        </React.Fragment>
      }
      shaded>
      {services_collapsed ? null : (
        <React.Fragment>
          <div className={classes.imageContainer}>
            <img
              className={classes.image}
              alt=""
              src={_.get(accommodation, "metadata.images.0.url", ghostImg)}
            />
          </div>
          <div className={classes.content}>
            <div>
              <div>
                <strong>Rating: </strong>
                <Rate
                  value={accommodation.metadata.rating}
                  size="xs"
                  readOnly={true}
                />
              </div>
              <div>
                <strong>Rooms: </strong>
                {[...new Set(selectedRooms.map((room) => room.name))].join(
                  ", "
                )}
              </div>
              <div>
                <strong>Board: </strong>
                {[
                  ...new Set(selectedRooms.map((room) => room.board_display)),
                ].join(", ")}
              </div>
              <div>
                <strong>Cancellation Policy: </strong>
                <Tag color={refundable ? "green" : "red"}>
                  <strong>
                    {refundable ? "Refundable" : "Non Refundable"}
                  </strong>
                </Tag>
              </div>
            </div>
            <div>
              <span>
                <strong>Short Description:</strong>
              </span>
              <p
                dangerouslySetInnerHTML={{
                  __html: accommodation?.metadata?.description,
                }}
              />
            </div>
          </div>
        </React.Fragment>
      )}
    </Panel>
  );
};
AccommodationServicePanel.defaultProps = {
  destOrder: 0,
  mode: "package",
  selectedRooms: [],
  services_collapsed: false,
};
AccommodationServicePanel.propTypes = {
  selectedRooms: PropTypes.array.isRequired,
  accommodation: PropTypes.object.isRequired,
  mode: PropTypes.oneOf(["prebook", "package"]).isRequired,
  destOrder: PropTypes.number.isRequired,
  services_collapsed: PropTypes.bool.isRequired,
  onShowServiceComments: PropTypes.func.isRequired,
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onShowServiceComments: () =>
      dispatch(showServiceCommentsModal(ownProps.destOrder)),
  };
};
AccommodationServicePanel = connect(
  null,
  mapDispatchToProps
)(AccommodationServicePanel);

const AddAdhocTrpSrv = ({ onClick }) => {
  return (
    <Button color="green" size="xs" onClick={onClick}>
      <strong>Add Ad hoc Transportation</strong>
    </Button>
  );
};
AddAdhocTrpSrv.propTypes = { onClick: PropTypes.func.isRequired };

const trpServiceHeaderStyles = createUseStyles({
  TrpServiceHeader: (props) => srvHeaderStyles(props),
  srvHeaderTitle: (props) => srvHeaderTitleStyles(props),
  srvHeaderTitleActions: (props) => srvHeaderTitleActionsStyles(),
});
export const TrpServiceHeader = ({ title, onSetAddhocDestDest }) => {
  const currentStep = useSelector((state) => state.tripPlannerCurrentStep);
  const serviceIsSelected = useSelector((state) =>
    isServiceFilterSelectedSelector(state, { service_type: "TR" })
  );

  const classes = trpServiceHeaderStyles({ currentStep });

  return !serviceIsSelected || currentStep === 4 ? null : (
    <div className={classes.TrpServiceHeader}>
      <span />
      <div className={classes.srvHeaderTitle}>
        <span>{title}</span>
        <div className={classes.srvHeaderTitleActions}>
          {typeof onSetAddhocDestDest === "function" && (
            <AddAdhocTrpSrv onClick={onSetAddhocDestDest} />
          )}
        </div>
      </div>
    </div>
  );
};
TrpServiceHeader.defaultProps = {
  title: "",
};
TrpServiceHeader.propTypes = {
  title: PropTypes.string.isRequired,
  onSetAddhocDestDest: PropTypes.func,
};

const reservationStatusTagStyles = createUseStyles({
  ReservationStatusTag: {
    padding: [`0 calc(${variables.normal_gap} * 2)`],
    border: `1px solid ${variables.colors.text.colored}`,
    borderRadius: `calc(${variables.normal_gap} / 2)`,
    background: "inherit",
    color: variables.colors.text.colored,
    fontSize: "medium",
    fontWeight: "600",
    display: "inline-grid",
    gridAutoFlow: "column",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    alignItems: "center",
  },
});
export const ReservationStatusTag = ({
  reservationLoading,
  reservationStatus,
}) => {
  const classes = reservationStatusTagStyles();

  return (
    <span className={classes.ReservationStatusTag}>
      Status: {reservationLoading ? <Loader /> : reservationStatus}
    </span>
  );
};
ReservationStatusTag.propTypes = {
  reservationLoading: PropTypes.bool.isRequired,
  reservationStatus: PropTypes.string.isRequired,
};

const bookedStyles = createUseStyles({
  BookedTag: {
    padding: [
      `calc(${variables.normal_gap} /4)`,
      `calc(${variables.normal_gap} /2)`,
    ],
    background: variables.colors.green,
    color: "white",
    fontWeight: "bold",
    fontSize: "small",
    display: "inline-grid",
    gridAutoFlow: "column",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    alignItems: "center",
    borderRadius: "5px",
  },
});
export const BookedTag = ({ reservation_reference }) => {
  const classes = bookedStyles();
  return (
    <span className={classes.BookedTag}>Booked: {reservation_reference}</span>
  );
};
BookedTag.propTypes = {
  reservation_reference: PropTypes.string.isRequired,
};

export { AccommodationServicePanel };
