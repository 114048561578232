import {
  TRIPPLANNER_INIT,
  TRIPPLANNER_LOAD_TRIP,
  TRIPPLANNER_SELECT_SRV_FOR_BOOKING,
  TRIPPLANNER_SELECT_SRV_FOR_OPTION_BOOKING,
  TRIPPLANNER_RESET_PREBOOK_FLIGHT_RULES,
  TRIPPLANNER_SET_PREBOOK_FLIGHT_RULES,
  TRIPPLANNER_ACCEPT_PREBOOK_FLIGHT_RULES,
  // ================= ACCOMMODATION PREBOOK ACTIONS =================
  TRIPPLANNER_RESET_PREBOOKACCOMMODATION_DATA,
  TRIPPLANNER_SET_PREBOOKACCOMMODATION_DATA,
  TRIPPLANNER_ACCEPT_PREBOOKACCOMMODATION,
  TRIPPLANNER_UNACCEPT_ALL_PREBOOKACCOMMODATIONS,
  TRIPPLANNER_RESET_SRV_FOR_BOOKING,
  TRIPPLANNER_RESET_SRV_FOR_OPTION_BOOKING,
  TRIPPLANNER_SET_PREBOOKTRF_DATA,
  TRIPPLANNER_RESET_PREBOOKTRF_DATA,
  TRIPPLANNER_ADD_SRVS_FOR_REGULAR_BOOKING,
  TRIPPLANNER_ADD_SRVS_FOR_OPTION_BOOKING,
  TRIPPLANNER_REMOVE_SRVS_FOR_REGULAR_BOOKING,
  TRIPPLANNER_REMOVE_SRVS_FOR_OPTION_BOOKING,
  TRIPPLANNER_RESET_SRVS_FOR_REGULAR_BOOKING,
  TRIPPLANNER_RESET_SRVS_FOR_OPTION_BOOKING,
} from "@src/actions/Project/TripPlanner/types";

import _ from "lodash";
import update from "immutability-helper";

const srvBookingUpdater = (state, destOrder, srvType, actionType) => {
  if (actionType == "add") {
    if (_.get(state, srvType, []).includes(destOrder)) {
      return state;
    }

    return update(state, { [srvType]: { $push: [destOrder] } });
  } else {
    return update(state, {
      [srvType]: { $apply: (orders) => orders.filter((o) => o !== destOrder) },
    });
  }
};

const tripPlannerForBookingSrvsInitial = { FL: [], ACC: [], TF: [], MI: [] };
export const tripPlannerForBookingSrvs = (
  state = tripPlannerForBookingSrvsInitial,
  action
) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return tripPlannerForBookingSrvsInitial;
    case TRIPPLANNER_LOAD_TRIP:
      return tripPlannerForBookingSrvsInitial;
    case TRIPPLANNER_RESET_SRV_FOR_BOOKING:
      return tripPlannerForBookingSrvsInitial;
    case TRIPPLANNER_SELECT_SRV_FOR_BOOKING: {
      const { srvCode, srvType, actionType } = action;
      return srvBookingUpdater(state, srvCode, srvType, actionType);
    }
    default:
      return state;
  }
};

const tripPlannerForOptionBookingSrvsInitial = {
  FL: [],
  ACC: [],
  TF: [],
  MI: [],
};
export const tripPlannerForOptionBookingSrvs = (
  state = tripPlannerForOptionBookingSrvsInitial,
  action
) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return tripPlannerForOptionBookingSrvsInitial;
    case TRIPPLANNER_LOAD_TRIP:
      return tripPlannerForOptionBookingSrvsInitial;
    case TRIPPLANNER_RESET_SRV_FOR_OPTION_BOOKING:
      return tripPlannerForOptionBookingSrvsInitial;
    case TRIPPLANNER_SELECT_SRV_FOR_OPTION_BOOKING: {
      const { srvCode, srvType, actionType } = action;
      return srvBookingUpdater(state, srvCode, srvType, actionType);
    }
    default:
      return state;
  }
};

const tripPlannerPrebookFlightRulesInitial = {};
export const tripPlannerPrebookFlightRules = (
  state = tripPlannerPrebookFlightRulesInitial,
  action
) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return tripPlannerPrebookFlightRulesInitial;
    case TRIPPLANNER_LOAD_TRIP:
      return tripPlannerPrebookFlightRulesInitial;
    case TRIPPLANNER_RESET_PREBOOK_FLIGHT_RULES:
      return tripPlannerPrebookFlightRulesInitial;
    case TRIPPLANNER_SET_PREBOOK_FLIGHT_RULES: {
      const { rules, flight } = action;
      return update(state, {
        [flight.uid]: { $set: { flight, rules, accepted: false } },
      });
    }
    case TRIPPLANNER_ACCEPT_PREBOOK_FLIGHT_RULES: {
      const { flight_uid, checked } = action;
      return update(state, {
        [flight_uid]: { ["accepted"]: { $set: checked } },
      });
    }
    default:
      return state;
  }
};

export const tripPlannerRegularBookSrvs = (state = [], action) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return [];
    case TRIPPLANNER_RESET_SRVS_FOR_REGULAR_BOOKING: {
      return [];
    }
    case TRIPPLANNER_ADD_SRVS_FOR_REGULAR_BOOKING: {
      const { srvs } = action;
      return [...state, ...srvs];
    }
    case TRIPPLANNER_REMOVE_SRVS_FOR_REGULAR_BOOKING: {
      const { srvs } = action;
      return state.filter((srv) =>
        srvs.every((s) => s.uid !== srv.uid && s.srv_type && srv.srv_type)
      );
    }
    default:
      return state;
  }
};

export const tripPlannerOptionBookSrvs = (state = [], action) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return [];
    case TRIPPLANNER_RESET_SRVS_FOR_OPTION_BOOKING: {
      return [];
    }
    case TRIPPLANNER_ADD_SRVS_FOR_OPTION_BOOKING: {
      const { srvs } = action;
      return [...state, ...srvs];
    }
    case TRIPPLANNER_REMOVE_SRVS_FOR_OPTION_BOOKING: {
      const { srvs } = action;
      return state.filter((srv) =>
        srvs.every((s) => s.uid !== srv.uid && s.srv_type && srv.srv_type)
      );
    }
    default:
      return state;
  }
};

// ===================== FLIGHT PREBOOK REDUCERS =====================
export const TRIPPLANNER_ACCEPT_FLIGHTS_PREBOOK =
  "TRIPPLANNER_ACCEPT_FLIGHTS_PREBOOK";
export const TRIPPLANNER_RESET_FLIGHTS_PREBOOK =
  "TRIPPLANNER_RESET_FLIGHTS_PREBOOK";
export const tripPlannerFlightPrebookInfo = (state = {}, action) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return {};
    case TRIPPLANNER_LOAD_TRIP:
      return {};
    case TRIPPLANNER_ACCEPT_FLIGHTS_PREBOOK: {
      const { flight_uids } = action;
      return flight_uids;
    }
    case TRIPPLANNER_RESET_FLIGHTS_PREBOOK: {
      return {};
    }
    default:
      return state;
  }
};

// ================== ACCOMMODATION PREBOOK REDUCERS =================
export const tripPlannerPrebookAccInfo = (state = {}, action) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return {};
    case TRIPPLANNER_LOAD_TRIP:
      return {};
    case TRIPPLANNER_RESET_PREBOOKACCOMMODATION_DATA:
      return {};
    case TRIPPLANNER_SET_PREBOOKACCOMMODATION_DATA: {
      const { destOrder, prebookData } = action;
      return update(state, {
        [destOrder]: {
          $apply: (dest) => {
            if (typeof dest !== "undefined") {
              return update(dest, {
                $set: { ...prebookData, accepted: false },
              });
            } else {
              return { ...prebookData, accepted: false };
            }
          },
        },
      });
    }
    case TRIPPLANNER_ACCEPT_PREBOOKACCOMMODATION: {
      const { destOrder, accepted } = action;
      return update(state, {
        [destOrder]: { accepted: { $set: accepted } },
      });
    }
    case TRIPPLANNER_UNACCEPT_ALL_PREBOOKACCOMMODATIONS:
      return update(state, {
        $apply: (state) => {
          const newState = {};
          Object.entries(state).forEach(([destOrder, prebook_data]) => {
            newState[destOrder] = update(prebook_data, {
              accepted: { $set: false },
            });
          });
          return newState;
        },
      });
    default:
      return state;
  }
};

// ==================== TRANSFER PREBOOK REDUCERS ====================
export const tripPlannerPrebookTrfInfo = (state = [], action) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return [];
    case TRIPPLANNER_LOAD_TRIP:
      return [];
    case TRIPPLANNER_SET_PREBOOKTRF_DATA: {
      const { data } = action;
      return data;
    }
    case TRIPPLANNER_RESET_PREBOOKTRF_DATA:
      return [];
    default:
      return state;
  }
};
