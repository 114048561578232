import { Tag, Panel, Loader } from "rsuite";

import { Transfer } from "./OverviewAllTransfers";

import _ from "lodash";

import PropTypes from "prop-types";
import React from "react";
import { transferReservationStatus } from "@src/config/service";
import { naString } from "@src/tools/string_tools";

export default class BookingModalTransfer extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { index, transfer, reservation_data } = this.props;

    if (!transfer) {
      return null;
    }

    return (
      <Panel
        className="CustomPanel reservation-panel"
        bordered
        header={
          <h5>
            {`Transfer ${index}: ${reservation_data.reference}`}
            <span className="reservation-status">Status:</span>
            {reservation_data.status == "PE" ? (
              <Loader className="reservation-loader" />
            ) : (
              <div>
                <Tag color={reservation_data.status !== "CO" ? "red" : "green"}>
                  <strong>
                    {naString(
                      _.get(transferReservationStatus, reservation_data.status)
                    )}
                  </strong>
                </Tag>
              </div>
            )}
          </h5>
        }>
        <Transfer
          destOrder={transfer.destOrder}
          transfer_type={transfer.transfer_type}
          idx={index}
          transfer={transfer}
        />
      </Panel>
    );
  }
}

BookingModalTransfer.propTypes = {
  index: PropTypes.number.isRequired,
  transfer: PropTypes.object.isRequired,
  reservation_data: PropTypes.object.isRequired,
};
