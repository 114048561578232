import { NormalInputField } from "@src/components/forms";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { createUseStyles } from "react-jss";
import { rsrvFeebackTableStyles } from "./styles";

// make the cabin name editable.

const cabinsStyles = createUseStyles({
  // table: tableStyles.table,
  // head: tableStyles.head,
  // cell: tableStyles.cell,
  ...rsrvFeebackTableStyles,
  cell: {
    ...rsrvFeebackTableStyles.cell,
    "width": "10rem",
    "& input": {
      width: "10rem",
    },
  },
});
const Cabins = ({ cabins, viewMode }) => {
  const classes = cabinsStyles();

  return (
    <table className={classes.table}>
      <thead className={classes.head}>
        <tr>
          <th>#</th>
          <th>Cabin</th>
          <th>Pax</th>
          <th>Number of Cabins</th>
        </tr>
      </thead>
      <tbody>
        {cabins.map((cabin, idx) => (
          <tr key={idx}>
            <td className={classes.cell}>{idx + 1}</td>
            <td className={classes.cell}>
              {viewMode ? (
                _.startCase(cabin.type)
              ) : (
                <NormalInputField name={`cabins.${idx}.type`} />
              )}
            </td>
            <td className={classes.cell}>{cabin.pax}</td>
            <td className={classes.cell}>{cabin.number_of_cabins}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
Cabins.defaultProps = {
  cabins: [],
};
Cabins.propTypes = {
  cabins: PropTypes.array.isRequired,
  viewMode: PropTypes.bool.isRequired,
};

export default Cabins;
