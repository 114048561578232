import { Modal, Loader } from "rsuite";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";

class TripWaitModal extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { show, msg } = this.props;

    return (
      <Modal
        className="ModalCentered CustomModal CustomModal--loading TripWaitModal"
        show={show}>
        <Modal.Header closeButton={false}>
          <Modal.Title>Please wait.</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Loader size="lg" content={msg} vertical />
        </Modal.Body>
      </Modal>
    );
  }
}

TripWaitModal.defaultProps = {
  show: false,
  msg: "Please wait...",
};

TripWaitModal.propTypes = {
  show: PropTypes.bool.isRequired,
  msg: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const { msg, show } = state.tripPlannerTripWaitModal;

  return { show, msg };
};

export default connect(mapStateToProps, null)(TripWaitModal);
