import _ from "lodash";
const options = [
  {
    value: "branch",
    display_name: "Branch",
  },
  {
    value: "member",
    display_name: "Company",
  },
  {
    value: "subagent",
    display_name: "Subagent",
  },
  {
    value: "independentagent",
    display_name: "Independent Agent",
  },
  {
    value: "affiliate",
    display_name: "Affiliate",
  },
];

export const getDisplayName = (value, options) => {
  const res = _.find(options, (item) => {
    return item.value == value;
  });

  if (res) {
    return res.display_name;
  } else {
    return value;
  }
};

export const calculateDiscountValue = (data1 = [], data2 = []) => {
  const arr = [];
  data1.forEach((item) => {
    arr.push(item.pricing);
  });
  data2.forEach((items) => {
    arr.push(items.pricing);
  });
  const group = _.groupBy(_.flatten(arr), "interval");
  const num_year = group.year.reduce((a, b) => {
    return a + b.amount;
  }, 0);
  const num_month = group.month.reduce((a, b) => {
    return a + b.amount;
  }, 0);
  return num_year / (num_month * 12);
};

export const SubScriptionDataProcessor = (data, colums, viewType) => {
  const newData = [];
  if (viewType !== "current_month") {
    _.get(data, "total.items", []).forEach((items) => {
      const obj = {};
      const tmp = data.months.map((e) => {
        return _.find(colums, (item) => {
          return item.id == e.month;
        });
      });

      tmp.unshift(colums[1]);
      tmp.unshift(colums[2]);
      tmp.unshift(colums[0]);
      tmp.forEach((c) => {
        obj[c.key] = {
          value: ValuePropcessor(c, data, items.name),
          product_sum: ValuePropcessor(c, data, items.name, "product"),
          license_sum: ValuePropcessor(c, data, items.name, "licence"),
          fixed: c.fixed,
          width: c.width,
          verbose: c.verbose,
          withoutCurr: c.withoutCurr,
        };
      });
      newData.push(obj);
    });
    const tmpObj = {};
    const tmpObj_a = {};
    if (!_.isEmpty(newData)) {
      Object.keys(newData[1]).forEach((e) => {
        tmpObj[e] = {
          value: activeValue(e, data, colums, "Active Products", true),
          withoutCurr:
            e == "subscription_fees" || e == "percent" ? true : false,
          total: activeValue(e, data, colums, "total"),
        };
      });
      Object.keys(newData[1]).forEach((e) => {
        tmpObj_a[e] = {
          value: activeValue(e, data, colums, "User License", false),
          withoutCurr:
            e == "subscription_fees" || e == "percent" ? true : false,
          total: activeValue(e, data, colums, "total"),
        };
      });
      newData.unshift(tmpObj);
      newData.splice(8, 0, tmpObj_a);
    }

    return newData;
  } else {
    _.get(data, "items", []).forEach((items) => {
      const obj = {};
      const arr = [];
      arr.push(colums[0]);
      arr.push(colums[2]);
      arr.push(colums[1]);

      arr.forEach((c) => {
        obj[c.key] = {
          value: currentValuePropcessor(c, data, items.name),
          withoutCurr:
            c.key == "subscription_fees" || c.key == "percent" ? true : false,
          verbose: c.verbose,
          width: c.width,
        };
      });
      newData.push(obj);
    });
    const tmpObj = {};
    const tmpObj_a = {};
    Object.keys(newData[1]).forEach((e) => {
      tmpObj[e] = {
        value: currentActiveValue(e, data, colums, "Active Products", true),
        withoutCurr: e == "subscription_fees" || e == "percent" ? true : false,
        total: currentActiveValue(e, data, colums, "total"),
      };
    });
    Object.keys(newData[1]).forEach((e) => {
      tmpObj_a[e] = {
        value: currentActiveValue(e, data, colums, "User License", false),
        withoutCurr: e == "subscription_fees" || e == "percent" ? true : false,
        total: currentActiveValue(e, data, colums, "total"),
      };
    });
    newData.unshift(tmpObj);
    newData.splice(8, 0, tmpObj_a);
    return newData;
  }
};

export const ValuePropcessor = (value, data, name, mode) => {
  const res = _.find(data.months, (item) => {
    return item.month == value.id;
  });

  if (res) {
    if (mode == "product") {
      return res.product_sum;
    }
    if (mode == "licence") {
      return res.license_sum;
    }
    return _.find(Object.values(res.items), (item) => {
      return item.name == name;
    }).amount;
  } else if (value.key == "subscription_fees") {
    return getDisplayName(name, options);
  } else if (value.key == "total") {
    return _.find(Object.values(data.total.items), (item) => {
      return item.name == name;
    }).amount;
  } else if (value.key == "percent") {
    return _.find(Object.values(data.total.items), (item) => {
      return item.name == name;
    }).percent != 0
      ? `${
          _.find(Object.values(data.total.items), (item) => {
            return item.name == name;
          }).percent * 100
        } %`
      : 0;
  }
};

export const activeValue = (e, data, colums, title, flag) => {
  if (e == "subscription_fees") {
    return title;
  } else if (e == "total") {
    if (title == "total") {
      return data.total.total_sum;
    }
    if (flag) {
      return data.total.product_sum;
    } else {
      return data.total.license_sum;
    }
  } else if (e == "percent") {
    if (flag) {
      return data.total.product_percent != 0
        ? `${data.total.product_percent * 100} %`
        : 0;
    } else {
      return data.total.license_percent != 0
        ? `${data.total.license_percent * 100} %`
        : 0;
    }
  } else {
    let num = 0;
    const month = _.find(colums, (item) => {
      return item.key == e;
    }).id;

    const res = _.find(data.months, (item) => {
      return item.month == month;
    }).items;

    if (flag) {
      Object.keys(res).map((item) => {
        if (item.split("_")[0] !== "prod") {
          num += res[item].amount;
        }
      });
      return num;
    } else {
      Object.keys(res).map((item) => {
        if (item.split("_")[0] == "prod") {
          num += res[item].amount;
        }
      });
      return num;
    }
  }
};

export const currentValuePropcessor = (c, data, name) => {
  if (c.key == "subscription_fees") {
    return getDisplayName(name, options);
  } else if (c.key == "total") {
    return _.find(Object.values(data.items), (item) => {
      return item.name == name;
    }).amount;
  } else if (c.key == "percent") {
    return _.find(Object.values(data.items), (item) => {
      return item.name == name;
    }).percent != 0
      ? `${
          _.find(Object.values(data.items), (item) => {
            return item.name == name;
          }).percent * 100
        } %`
      : 0;
  }
};

export const currentActiveValue = (e, data, colums, title, flag) => {
  if (e == "subscription_fees") {
    return title;
  } else if (e == "total") {
    if (title == "total") {
      return data.product_sum + data.license_sum;
    }
    if (flag) {
      return data.product_sum;
    } else {
      return data.license_sum;
    }
  } else if (e == "percent") {
    if (flag) {
      return data.product_percent != 0 ? `${data.product_percent * 100} %` : 0;
    } else {
      return data.license_percent != 0 ? `${data.license_percent * 100} %` : 0;
    }
  }
};
