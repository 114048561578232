import { SSGENERAL_SERVICES_LIST_GENERIC_CHANGE } from "@src/actions/Operation/Reservations/SingleServices/GeneralServices/types";
import { commonList } from "@src/reducers/common";

const SSGeneralServicesListReducerInitial = {
  ...commonList,
  displayLength: 100,
  filters: {
    reference: "",
    external_reference: "",
    source_entity_autocomplete: "",
    source_entity: "",
    name__icontains: "",
    destination_name__icontains: "",
    country: "",
    created__gte: "",
    created__lte: "",
    creator: "",
    creator_email: "",
    service_type: "",
    status: "",
    page: 1,
    limit: 20,
    ordering: "",
    tags: "",
  },
};
export const SSGeneralServicesListReducer = (
  state = SSGeneralServicesListReducerInitial,
  action
) => {
  switch (action.type) {
    case SSGENERAL_SERVICES_LIST_GENERIC_CHANGE: {
      const { filters } = action;
      return { ...state, filters: { ...state.filters, ...filters } };
    }
    default:
      return state;
  }
};
