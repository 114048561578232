import { Modal, Button, Loader } from "rsuite";

import TransTxt from "@src/components/common/SxFormatMessage";

import {
  saveTrip,
  cancelRequestTripSave,
  saveMktTrip,
} from "@src/actions/Project/TripPlanner";

import { cancelBidSave, saveBiddingTrip } from "@src/actions/Project/SiSiBid";
import {
  cancelOfferSave,
  offerSave,
} from "@src/actions/Project/BiddingRequest";

import _ from "lodash";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";

class RequestSaveModal extends React.Component {
  constructor(props) {
    super(props);
  }
  getTitle() {
    const { mode } = this.props;

    switch (mode) {
      case "tripplan":
        return <TransTxt id="tripplan_save_trip" startCase />;
      case "bidding":
        return "Save Bidding";
      case "bid_request":
        return "Save Bid Offer";
      default:
        return <TransTxt id="tripplan_save_trip" startCase />;
    }
  }
  render() {
    const { show, loading, onCancel, onSave } = this.props;

    return (
      <Modal
        className="CustomModal RequestSaveModal"
        show={show}
        onHide={onCancel}>
        <Modal.Header>
          <Modal.Title>{this.getTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading && show ? (
            <Loader
              size="lg"
              content={<TransTxt id="please_wait" startCase />}
            />
          ) : (
            <React.Fragment>
              <p>Are you sure you want to proceed saving?</p>
              <p></p>
            </React.Fragment>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onSave} appearance="primary" disabled={loading}>
            <TransTxt id="save" startCase />
          </Button>
          <Button onClick={onCancel} appearance="subtle" disabled={loading}>
            <TransTxt id="cancel" startCase />
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

RequestSaveModal.defaultProps = {
  mode: "tripplan",
};

RequestSaveModal.propTypes = {
  mode: PropTypes.oneOf(["tripplan", "bidding", "bid_request", "marketplace"]),
  show: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { mode } = ownProps;
  var data = {};
  switch (mode) {
    case "tripplan":
      data = state.tripPlannerRequestSaveModal;
      break;
    case "marketplace":
      data = state.tripPlannerRequestSaveModal;
      break;
    case "bidding":
      data = state.biddingRequestSaveModal;
      break;
    case "bid_request":
      data = state.bidRequestOfferSaveModal;
      break;
    default:
      data = state.tripPlannerRequestSaveModal;
      break;
  }

  return { show: data.show, loading: data.loading };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { mode } = ownProps;
  const leadingDebDispatch = _.debounce(dispatch, { leading: 500 });

  var fnData = {};
  switch (mode) {
    case "tripplan":
      fnData["onCancel"] = cancelRequestTripSave;
      fnData["onSave"] = saveTrip;
      break;
    case "marketplace":
      fnData["onCancel"] = cancelRequestTripSave;
      fnData["onSave"] = saveMktTrip;
      break;
    case "bidding":
      fnData["onCancel"] = cancelBidSave;
      fnData["onSave"] = saveBiddingTrip;
      break;
    case "bid_request":
      fnData["onCancel"] = cancelOfferSave;
      fnData["onSave"] = offerSave;
      break;
    default:
      fnData["onCancel"] = cancelRequestTripSave;
      fnData["onSave"] = saveTrip;
      break;
  }

  return {
    onCancel: () => dispatch(fnData.onCancel()),
    onSave: () => leadingDebDispatch(fnData.onSave()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestSaveModal);
