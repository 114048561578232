import { ACCOMMODATION_RESERVATION_INIT } from "@src/actions/Operation/Accommodation/types";
import {
  BEGIN_CHANGE_ENTITY_STATUS,
  END_CHANGE_ENTITY_STATUS,
  SAVE_COUNTRY_CODE_INFO,
  FETCHED_GUEST_OPTIONS,
  FETCHED_TRAVEL_SERVICES,
  FETCHED_TRAVEL_SERVICE_OPTIONS,
  FETCH_COMPANY_GRAPH_DATA,
  FETCH_GRAPH_MAP_VIEW_DATA,
  FETCH_USER_GRAPH_DATA,
  FINANCIAL_CURRENCIES_LIST,
  COUNTRIES_FORMAT_OPTIONS,
  FORMAT_COUNTRY_CODE_LIST,
  FORMAT_TRAVEL_SERVICES_FORM,
  FORMAT_TRAVEL_SERVICES_OPTIONS,
  HIDE_GLOBAL_CHART_ROOMS,
  SET_RESERVATION_EMAIL_LIST_DATA,
  SHARES_FETCHED_COUNTRY_LIST,
  SHOW_GLOBAL_CHART_ROOMS,
  //=================================GET RELATED EMPLOYEES========================
  GET_RELATED_EMPLOYEES_DATA,
  //================================ Graph========================
  GRAPH_DASHBOARDS_TOGGLE_MODE,
  GRAPH_DASHBOARDS_INIT_MODE,
} from "@src/actions/Shares/types";
import { TRF_RESERVATION_INIT } from "@src/actions/Operation/Transfers/types";
import update from "immutability-helper";

// ========================= Travel Services =========================

export const travelServiceOptions = (state = {}, action) => {
  switch (action.type) {
    case FETCHED_TRAVEL_SERVICE_OPTIONS:
      return action.data;
    default:
      return state;
  }
};

export const travelServices = (state = {}, action) => {
  switch (action.type) {
    case FETCHED_TRAVEL_SERVICES:
      return action.data;
    default:
      return state;
  }
};

export const travelServicesFetchStatus = (state = "IDLE", action) => {
  switch (action.type) {
    case FORMAT_TRAVEL_SERVICES_OPTIONS:
      return "FETCHED";
    case FORMAT_TRAVEL_SERVICES_FORM:
      return "FETCHED";
    default:
      return state;
  }
};

export const servicesMixinStatus = (state = false, action) => {
  switch (action.type) {
    case FORMAT_TRAVEL_SERVICES_OPTIONS:
      return true;
    default:
      return state;
  }
};

export const travelServicesForm = (state = {}, action) => {
  switch (action.type) {
    case FORMAT_TRAVEL_SERVICES_FORM:
      return action.data;
    default:
      return state;
  }
};

// ============================== Guest ==============================

export const guestOptions = (state = {}, action) => {
  switch (action.type) {
    case FETCHED_GUEST_OPTIONS:
      return action.data;
    default:
      return state;
  }
};

// ======================== Reservation Email ========================

export const reservationEmailList = (state = [], action) => {
  switch (action.type) {
    case ACCOMMODATION_RESERVATION_INIT:
      return [];
    case TRF_RESERVATION_INIT:
      return [];
    case SET_RESERVATION_EMAIL_LIST_DATA:
      return action.data;
    default:
      return state;
  }
};

// ========================== Countries Info =========================

export const formatCountriesOptionsStatus = (state = false, action) => {
  switch (action.type) {
    case COUNTRIES_FORMAT_OPTIONS:
      return true;
    default:
      return state;
  }
};

export const changeEntityStatusStatus = (state = "LOADED", action) => {
  switch (action.type) {
    case END_CHANGE_ENTITY_STATUS:
      return "LOADED";
    case BEGIN_CHANGE_ENTITY_STATUS:
      return "LOADING";
    default:
      return state;
  }
};

//========================= CHATTING ===========================

export const toggleChattingRoomStatus = (state = false, action) => {
  switch (action.type) {
    case SHOW_GLOBAL_CHART_ROOMS:
      return true;
    case HIDE_GLOBAL_CHART_ROOMS:
      return false;
    default:
      return state;
  }
};

// =========================== COUNTRY CODE ==========================
export const countryCodeInfo = (state = [], action) => {
  switch (action.type) {
    case SAVE_COUNTRY_CODE_INFO:
      return action.data;
    default:
      return state;
  }
};

export const formatCountryCodeList = (state = [], action) => {
  switch (action.type) {
    case FORMAT_COUNTRY_CODE_LIST:
      return action.data;
    default:
      return state;
  }
};

export const countryList = (state = [], action) => {
  switch (action.type) {
    case SHARES_FETCHED_COUNTRY_LIST:
      return action.data;
    default:
      return state;
  }
};

export const usersGraphData = (state = {}, action) => {
  switch (action.type) {
    case FETCH_USER_GRAPH_DATA:
      return action.data;
    default:
      return state;
  }
};

export const companyGraphData = (state = {}, action) => {
  switch (action.type) {
    case FETCH_COMPANY_GRAPH_DATA:
      return action.data;
    default:
      return state;
  }
};

export const graphMapViewData = (state = {}, action) => {
  switch (action.type) {
    case FETCH_GRAPH_MAP_VIEW_DATA:
      return action.data;
    default:
      return state;
  }
};

export const financialCurrenciesList = (state = [], action) => {
  switch (action.type) {
    case FINANCIAL_CURRENCIES_LIST: {
      return update(action.data, {
        $apply: (curr) =>
          curr
            .filter((c) => ["EUR", "USD", "CNY", "GBP"].includes(c.notation))
            .map((c) => {
              return update(c, {
                value: { $set: c.notation },
                label: { $set: `${c.notation}, ${c.symbol}` },
              });
            }),
      });
    }

    default:
      return state;
  }
};

//==============================GET RELATED EMPLOYEES=======================================
export const relatedEmployeesData = (state = [], action) => {
  switch (action.type) {
    case GET_RELATED_EMPLOYEES_DATA: {
      return update(action.data, {
        $apply: (curr) =>
          curr.map((c) => {
            return update(c, {
              label: { $set: `${c.first_name} ${c.last_name} ` },
              value: { $set: `${c.username}` },
            });
          }),
      });
    }
    default:
      return state;
  }
};

//=============================Graph=================================
export const graphDashboardsStatus = (state = false, action) => {
  switch (action.type) {
    case GRAPH_DASHBOARDS_INIT_MODE:
      return true;
    case GRAPH_DASHBOARDS_TOGGLE_MODE:
      return action.data;
    default:
      return state;
  }
};

export * from "./globalModal";
