import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import {
  NormalDatePicker,
  NormalInputField,
  NormalSelectField,
} from "@src/components/forms";
import { senderFormStyles } from ".";
import { Loader } from "rsuite";
import { currencies } from "@src/definitions";
import { CustomButton } from "@src/components/common/buttons";

const OfferConfigForm = ({
  configForm,
  termsAndConditionsList,
  onNextStep,
  onPrevStep,
  getLinkedOffers,
  setConfigForm,
}) => {
  const classes = senderFormStyles();

  const [withLinkedOffers, setWithLinkedOffers] = useState(false);
  const [loading, setLoading] = useState(false);

  async function getter() {
    setLoading(true);
    await getLinkedOffers();
    setLoading(false);
  }

  useEffect(() => {
    if (!withLinkedOffers) return;

    getter();
  }, [withLinkedOffers]);

  return loading ? (
    <div className={classes.body}>
      <Loader center size="lg" />
    </div>
  ) : (
    <Formik
      enableReinitialize={true}
      initialValues={configForm}
      validate={(values) => {
        if (values.linked) {
          setConfigForm(values);
          setWithLinkedOffers(true);
        } else {
          setWithLinkedOffers(false);
        }
      }}
      onSubmit={(values) => onNextStep(values)}>
      {({ values }) => (
        <div className={classes.body}>
          <Form className={classes.form}>
            <div className={classes.fields}>
              <div className={classes.col5}>
                <NormalSelectField
                  name="offer_size"
                  label="Offer Size"
                  options={[
                    ["summary", "Summary"],
                    ["summary__itinerary", "Summary & Itinerary"],
                  ]}
                />
                <NormalSelectField
                  name="reference_type"
                  label="Trip Reference Type"
                  options={[
                    ["internal_only", "Internal Only"],
                    ["external_only", "External Only"],
                    ["both", "Both"],
                  ]}
                />
                <NormalSelectField
                  name="price_info"
                  label="Show Price Info"
                  options={[
                    [true, "Yes"],
                    [false, "No"],
                  ]}
                />
                <NormalSelectField
                  name="convert_currency"
                  label="Convert Currency"
                  options={_.sortBy(
                    Object.entries(currencies).map(([iso, symbol]) => [
                      iso,
                      `${iso} (${symbol})`,
                    ]),
                    (v) => v[0]
                  )}
                />
                <NormalInputField label="Offer Title" name="offer_title" />
              </div>
              <div className={classes.col4}>
                <NormalInputField label="Offer Version" name="offer_version" />
                <NormalDatePicker
                  name="offer_validity"
                  label="Offer Validity"
                  withTime={true}
                />
                <NormalSelectField
                  name="terms_and_conditions_id"
                  label="Terms And Conditions Version"
                  options={[
                    [null, "------"],
                    ...termsAndConditionsList.map((tr) => [
                      tr.id,
                      `${tr.name} (${
                        tr.status === "AC"
                          ? "Active"
                          : tr.status === "PE"
                          ? "Pending"
                          : "Inactive"
                      })`,
                    ]),
                  ]}
                />
                <NormalSelectField
                  name="show_cxl_policy"
                  label="Show Cxl Policy & Payment Terms"
                  options={[
                    [null, "------"],
                    ["true", "Yes"],
                    ["false", "No"],
                  ]}
                />
              </div>
              {values.linked_trips.length > 0 && (
                <div className={classes.checkboxContainer}>
                  <h6>Linked Offers</h6>
                  {values.linked_trips.map((trp, idx) => {
                    const { adults, children, title } = trp.itinerary_setup;
                    return (
                      <span className={classes.checkbox}>
                        <Field
                          type="checkbox"
                          name={`linked_trips.${idx}.selected_trip`}
                          key={idx}
                        />
                        {title} ({adults} Adults, {children} Children)
                      </span>
                    );
                  })}
                </div>
              )}
              <NormalInputField
                label="Description"
                name="description"
                as="textarea"
              />
              <NormalInputField
                label="Service Remarks"
                name="service_remarks"
                as="textarea"
              />
            </div>
            <div className={classes.actions}>
              <CustomButton appearance="ghost" onClick={onPrevStep}>
                <strong>Back</strong>
              </CustomButton>
              <CustomButton appearance="primary" type="submit">
                <strong>Next</strong>
              </CustomButton>
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
};
OfferConfigForm.defaultProps = { linkedOffers: [], termsAndConditionsList: [] };
OfferConfigForm.propTypes = {
  configForm: PropTypes.object.isRequired,
  termsAndConditionsList: PropTypes.array.isRequired,
  linkedOffers: PropTypes.array.isRequired,
  onPrevStep: PropTypes.func.isRequired,
  onNextStep: PropTypes.func.isRequired,
  getLinkedOffers: PropTypes.func.isRequired,
  setConfigForm: PropTypes.func.isRequired,
};

export default OfferConfigForm;
