export const ACCOUNT_STATEMENT_INIT = "ACCOUNT_STATEMENT_INIT";

// ========================= DEPOSIT BALANCE =========================
export const ACCOUNT_STATEMENT_FETCHING_DEPOSIT_BALANCE =
  "ACCOUNT_STATEMENT_FETCHING_DEPOSIT_BALANCE";
export const ACCOUNT_STATEMENT_FETCHED_DEPOSIT_BALANCE =
  "ACCOUNT_STATEMENT_FETCHED_DEPOSIT_BALANCE";
export const ACCOUNT_STATEMENT_SET_DEPOSIT_BALANCE =
  "ACCOUNT_STATEMENT_SET_DEPOSIT_BALANCE";

// ========================= DEPOSIT ENTRIES =========================
export const ACCOUNT_STATEMENT_SET_DEPOSIT_ENTRIES =
  "ACCOUNT_STATEMENT_SET_DEPOSIT_ENTRIES";
export const ACCOUNT_STATEMENT_FETCHING_DEPOSIT_ENTRIES =
  "ACCOUNT_STATEMENT_FETCHING_DEPOSIT_ENTRIES";
export const ACCOUNT_STATEMENT_FETCHED_DEPOSIT_ENTRIES =
  "ACCOUNT_STATEMENT_FETCHED_DEPOSIT_ENTRIES";

export const ACCOUNT_STATEMENT_VIEW_TYPE = "ACCOUNT_STATEMENT_VIEW_TYPE";
export const ACCOUNT_STATEMENT_STAT_DATA = "ACCOUNT_STATEMENT_STAT_DATA";
export const ACCOUNT_STATEMENT_TAG_TYPE = "ACCOUNT_STATEMENT_TAG_TYPE";
export const ACCOUNT_STATEMENT_INIT_VIEW_TYPE =
  "ACCOUNT_STATEMENT_INIT_VIEW_TYPE";
export const ACCOUNT_STATEMENT_INIT_TAG_TYPE =
  "ACCOUNT_STATEMENT_INIT_TAG_TYPE";
// ========================== DEPOSIT MODAL ==========================
export const ACCOUNT_STATEMENT_SHOW_DEPOSIT_MODAL =
  "ACCOUNT_STATEMENT_SHOW_DEPOSIT_MODAL";
export const ACCOUNT_STATEMENT_HIDE_DEPOSIT_MODAL =
  "ACCOUNT_STATEMENT_HIDE_DEPOSIT_MODAL";
export const ACCOUNT_STATEMENT_IDLE_DEPOSIT_MODAL =
  "ACCOUNT_STATEMENT_IDLE_DEPOSIT_MODAL";
export const ACCOUNT_STATEMENT_LOADING_DEPOSIT_MODAL =
  "ACCOUNT_STATEMENT_LOADING_DEPOSIT_MODAL";
//========================= FILTERS ================================
export const ACCOUNT_STATEMENT_INITIALIZE_LIST_FILTER_FORM =
  "ACCOUNT_STATEMENT_INITIALIZE_LIST_FILTER_FORM";
export const ACCOUNT_STATEMENT_UPDATE_LIST_FILTER_FORM =
  "ACCOUNT_STATEMENT_UPDATE_LIST_FILTER_FORM";
export const ACCOUNT_STATEMENT_APPLY_LIST_FILTER =
  "ACCOUNT_STATEMENT_APPLY_LIST_FILTER";

// ===================== SUB ENTITY DEPOSIT ORDER ====================
export const ACCOUNT_STATEMENT_SHOW_SUB_ENTITY_DEPOSIT_MODAL =
  "ACCOUNT_STATEMENT_SHOW_SUB_ENTITY_DEPOSIT_MODAL";
export const ACCOUNT_STATEMENT_HIDE_SUB_ENTITY_DEPOSIT_MODAL =
  "ACCOUNT_STATEMENT_HIDE_SUB_ENTITY_DEPOSIT_MODAL";
export const ACCOUNT_STATEMENT_IDLE_SUB_ENTITY_DEPOSIT_MODAL =
  "ACCOUNT_STATEMENT_IDLE_SUB_ENTITY_DEPOSIT_MODAL";
export const ACCOUNT_STATEMENT_LOADING_SUB_ENTITY_DEPOSIT_MODAL =
  "ACCOUNT_STATEMENT_LOADING_SUB_ENTITY_DEPOSIT_MODAL";
