import {
  // ========================== INIT ACTIONS =========================
  TRIPPLANNER_INIT,
  // ======================= TRIP LOAD ACTIONS =======================
  TRIPPLANNER_LOAD_TRIP,
  // ===================== ACCOMMODATION FILTERS ====================
  TRIPPLANNER_OVERVIEW_ACC_FILTERS_CHANGE,
  // ========================== ROOM FILTERS =========================
} from "@src/actions/Project/TripPlanner/types";
import { accFiltersValidationSchema } from "@src/pages/Project/TripPlan/Components/OverviewAccFilters";

export const tripPlannerOverviewAccsFilters = (
  state = accFiltersValidationSchema.cast({}),
  action
) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return accFiltersValidationSchema.cast({});
    case TRIPPLANNER_LOAD_TRIP:
      return accFiltersValidationSchema.cast({});
    case TRIPPLANNER_OVERVIEW_ACC_FILTERS_CHANGE: {
      const { filters } = action;
      return filters;
    }
    default:
      return state;
  }
};
