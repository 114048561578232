import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { createUseStyles } from "react-jss";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "rsuite";
import { modalGenericStyles } from "../../../../../jsssetup";
import { getServicesListSelector } from "@src/selectors/Project/TripPlanner/generic_service_selectors";
import { CoordinatorForm } from "./products/coordinators";
import { updateDbDCoordSrv } from "@src/actions/Project/TripPlanner/day_by_day/custom_services";

const editCoordSrvModalStyles = createUseStyles({
  ...modalGenericStyles,
  EditCoordSrvModal: modalGenericStyles.modal,
  cardBody: { ...modalGenericStyles.cardBody, display: "grid" },
});
const EditCoordSrvModal = ({ srv_id, onCancel }) => {
  const services = useSelector((state) => getServicesListSelector(state));

  const service = services
    .filter((srv) => srv.service_type === "COO")
    .find((srv) => srv?.uid === srv_id);

  const classes = editCoordSrvModalStyles();

  const dispatch = useDispatch();
  const onSelect = useCallback(
    (data) => dispatch(updateDbDCoordSrv({ srv_id, data })),
    [dispatch]
  );

  return !service ? null : (
    <div className={classes.EditCoordSrvModal}>
      <div className={classes.card}>
        <div className={classes.cardHeader}>
          <h5>Edit Coordinator Service: {service.name_en}</h5>
        </div>
        <div className={classes.cardBody}>
          <CoordinatorForm
            coordinator={service}
            onSelect={(data) => {
              onSelect(data);
              onCancel();
            }}>
            <Button appearance="ghost" size="sm" onClick={onCancel}>
              <strong>Cancel</strong>
            </Button>
          </CoordinatorForm>
        </div>
      </div>
    </div>
  );
};
EditCoordSrvModal.propTypes = {
  srv_id: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default EditCoordSrvModal;
