import TransTxt from "@src/components/common/SxFormatMessage";
import { getDisplayNameFromOptions } from "@src/processors/columnsDataProcessor";

import { lang_mapping } from "@src/tools/lang_tools";

import { initialColumnsProcessor } from "./../Columns/policyCommonColumns";

import _ from "lodash";
import React from "react";

export const initialGeoPolicyColumnsSetUp = {
  basic: {
    value: ["policy_name", "pax", "rounding_digits", "distribution_period"],
    choices: [
      {
        key: "policy_name",
        disabled: true,
        display_name: <TransTxt id="policy_name" startCase />,
      },
      {
        key: "pax",
        disabled: false,
        display_name: <TransTxt id="pax" startCase />,
      },
      {
        key: "distribution_period",
        disabled: false,
        display_name: <TransTxt id="distribution_period" startCase />,
      },
      {
        key: "reservation_period",
        disabled: false,
        display_name: <TransTxt id="reservation_period" startCase />,
      },
      {
        key: "rounding_digits",
        disabled: false,
        display_name: "Rounding Digits",
      },
    ],
  },
  sales_partners: {
    value: ["sales_partners"],
    choices: [
      {
        key: "sales_partners",
        disabled: false,
        display_name: <TransTxt id="sales_partners" startCase />,
      },
    ],
  },
  services: {
    value: ["service_categories", "service_type"],
    choices: [
      {
        key: "service_categories",
        disabled: false,
        display_name: <TransTxt id="service_categories" startCase />,
      },
      {
        key: "service_type",
        disabled: false,
        display_name: <TransTxt id="service_type" startCase />,
      },
    ],
  },
  territories: {
    value: ["territories", "countries"],

    choices: [
      {
        key: "territories",
        display_name: <TransTxt id="territories" startCase />,
      },
      {
        key: "countries",
        display_name: <TransTxt id="countries" startCase />,
      },
    ],
  },
  stage: {
    value: ["status"],
    choices: [
      {
        key: "created",
        disabled: false,
        display_name: <TransTxt id="created" startCase />,
      },
      {
        key: "edited",
        disabled: false,
        display_name: <TransTxt id="edited" startCase />,
      },
      {
        key: "creator",
        disabled: false,
        display_name: <TransTxt id="creator" startCase />,
      },
      {
        key: "status",
        disabled: true,
        display_name: <TransTxt id="status" startCase />,
      },
    ],
  },
};

export const initialGeoPolicyColumnsProcessor = _.merge(
  initialColumnsProcessor,
  {
    territories: {
      key: "territories",
      getter: (data, options) => {
        const countriesListOptions = options[3];
        const countriesName = options[4];
        let arr = [];
        _.get(data, "countries").forEach((ele) => {
          if (!_.isEmpty(countriesName)) {
            countriesName.forEach((items) => {
              if (ele == items.id) {
                if (!_.isEmpty(countriesListOptions)) {
                  arr.push(
                    getDisplayNameFromOptions(
                      items.continent,
                      countriesListOptions.continent.choices
                    )
                  );
                }
              }
            });
          }
        });
        arr = Array.from(new Set(arr));
        if (!_.isEmpty(arr)) {
          if (arr.length == 1) {
            return `${arr[0]}`;
          } else {
            return `${arr[0]}(${arr.length})`;
          }
        } else {
          return "N/A";
        }
      },
      extraGetter: (data, options) => {
        const countriesListOptions = options[3];
        const countriesName = options[4];
        const arr = [];
        _.get(data, "countries", []).forEach((ele) => {
          if (!_.isEmpty(countriesName)) {
            countriesName.forEach((items) => {
              if (ele == items.id) {
                if (!_.isEmpty(countriesListOptions)) {
                  arr.push(
                    getDisplayNameFromOptions(
                      items.continent,
                      countriesListOptions.continent.choices
                    )
                  );
                }
              }
            });
          }
        });
        return Array.from(new Set(arr));
      },
    },
    countries: {
      key: "countries",
      getter: (data, options) => {
        const language = options[0];
        const countriesName = options[4];
        const num = _.get(data, "countries").length;
        const arr = [];
        _.get(data, "countries").forEach((ele) => {
          if (!_.isEmpty(countriesName)) {
            countriesName.forEach((items) => {
              if (ele == items.id) {
                arr.push(items[`name_${lang_mapping[language]}`]);
              }
            });
          }
        });
        if (!_.isEmpty(arr)) {
          if (arr.length == 1) {
            return `${arr[0]}`;
          } else {
            return `${arr[0]}(${num})`;
          }
        } else {
          return "N/A";
        }
      },
      extraGetter: (data, options) => {
        const language = options[0];
        const countriesName = options[4];
        const arr = [];
        _.get(data, "countries", []).forEach((ele) => {
          if (!_.isEmpty(countriesName)) {
            countriesName.forEach((items) => {
              if (ele == items.id) {
                arr.push(items[`name_${lang_mapping[language]}`]);
              }
            });
          }
        });
        return arr;
      },
    },
  }
);

export const initialGeoPolicyColumnsMapping = {
  policy_name: {
    key: "policy_name",
    fixed: "left",
    width: 120,
  },
  pax: {
    key: "pax",
    fixed: "left",
  },
  distribution_period: {
    key: "distribution_period",
    width: 200,
  },
  reservation_period: {
    key: "reservation_period",
    width: 200,
  },
  rounding_digits: {
    key: "rounding_digits",
    width: 200,
  },
  sales_partners: {
    key: "sales_partners",
    width: 150,
  },
  service_categories: {
    key: "service_categories",
    width: 200,
  },
  service_type: {
    key: "service_type",
    width: 150,
  },
  territories: {
    key: "territories",
    width: 150,
  },
  countries: {
    key: "countries",
    width: 150,
  },
  created: {
    key: "created",
    width: 170,
  },
  edited: {
    key: "edited",
    width: 170,
  },
  creator: {
    key: "creator",
    width: 170,
  },
  status: {
    key: "status",
    fixed: "right",
  },
};
