import _ from "lodash";
import { cardStyles, variables } from "@src/jsssetup";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { createUseStyles } from "react-jss";
import { DayItemControls, daySrvContainerStyles } from ".";
import OverviewPrice from "../OverviewPrice";
import { connect, useSelector } from "react-redux";
import {
  addDbDCustomSrv,
  removeDbDCustomSrv,
} from "@src/actions/Project/TripPlanner/day_by_day/custom_services";
import { humanizeDuration } from "@src/tools/date_tools";
import { PricingFormContext, TripPlanContext } from "../../TripPlanner";

import ServicePricingForm from "../ServicePricingForm";
import {
  getServicePricingSelector,
  srvPriceKeyCreator,
} from "@src/selectors/Project/TripPlanner/pricing";
import { isServiceFilterSelectedSelector } from "@src/selectors/Project/TripPlanner/service_filters";
import { BookChecker } from "../ItineraryOverviewCommon";
import { Button } from "rsuite";
import { BookedTag, ReservationStatusTag } from "../service_panels";
import { daySrvBodyStyles, daySrvHeaderStyles } from "./common";
import { DateTime } from "luxon";
import { srvIsBookedSelector } from "@src/selectors/Project/TripPlanner/booking_service_select";
import RatingPanel from "@src/components/common/RatingPanel";
import { InlineIcon } from "@iconify/react";
import { useServiceCardExpansion } from "@src/pages/hooks";
// const ghostImg =
//   "https://sisistaticassets.s3.ap-southeast-1.amazonaws.com/platform_graphics/imgs/others/destination_placeholder.jpg";

const coordinatorDataStyles = createUseStyles({
  CoordinatorService: {
    display: "grid",
    gridGap: variables.half_gap,
  },
  header: {
    ...daySrvHeaderStyles(),
    ...cardStyles.card,
    paddingLeft: variables.double_gap,
    borderRadius: `calc(${variables.normal_gap} / 2)`,
    background: variables.colors.services.coordinator,
  },
  headerActions: {
    display: "grid",
    gridAutoFlow: "column",
    grid: `calc(${variables.normal_gap} / 2)`,
  },
  body: (props) => ({
    ...daySrvBodyStyles(props),
    ...cardStyles.card,
    height: "auto",
    paddingLeft: variables.double_gap,
    zIndex: 1,
    gridTemplateColumns: "1fr",
    background: variables.colors.services.coordinatorBody,
    overflow: "hidden",
  }),
  content: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridAutoRows: "max-content",
    gridGap: `calc(${variables.normal_gap} / 2)`,
  },
  title: {
    fontWeight: "600",
    color: variables.colors.text.colored,
  },
  ratingCntr: {
    display: "grid",
    gridColumn: "span 2",
    gridTemplateColumns: "max-content max-content",
    alignItems: "center",
    gridGap: variables.half_gap,
  },
  expandCntr: {
    display: "grid",
    gridTemplateColumns: "max-content 1fr",
    gridGap: variables.normal_gap,
    alignItems: "center",
    marginTop: `calc(${variables.normal_gap} * -1)`,
    padding: [
      `calc(${variables.normal_gap} * 1.2)`,
      0,
      `calc(${variables.normal_gap} * 0.8)`,
      variables.double_gap,
    ],
    boxShadow: variables.shadows.normalShadow,
    borderBottomLeftRadius: `calc(${variables.normal_gap} / 2)`,
    borderBottomRightRadius: `calc(${variables.normal_gap} / 2)`,
    background: variables.colors.services.coordinatorBorder,
  },
  expandedDataCntr: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridColumn: "span 2",
    gridGap: variables.normal_gap,
    width: "100%",
  },
  expandTitle: {
    fontSize: "medium",
    fontWeight: "500",
  },
  arrowCntr: {
    display: "grid",
    placeContent: "center",
    width: `calc(${variables.normal_gap} * 1.5)`,
    height: `calc(${variables.normal_gap} * 1.5)`,
    borderRadius: "50%",
    padding: `calc(${variables.half_gap} / 2)`,
    background: variables.colors.easy.orange,
    cursor: "pointer",
  },
  arrowIcon: {
    fontSize: "medium",
    color: "white",
  },
  divider: {
    borderBottom: `1px solid ${variables.colors.deepgrey}`,
    width: "98%",
  },
  tagsCntr: {
    display: "grid",
    gridAutoFlow: "column",
    gridGap: variables.half_gap,
  },
  expandIconCntr: {
    display: "grid",
    placeContent: "center",
    width: "1.5rem",
    height: "1.5rem",
    borderRadius: "50%",
    background: variables.colors.easy.orange,
    padding: variables.half_gap,
    cursor: "pointer",
  },
  expandIcon: {
    fontSize: "large",
    color: variables.colors.text.light,
  },
});
export const CoordinatorData = ({
  service,
  date,
  reservationMode,
  reservationLoading,
  reservationStatus,
  services_collapsed,
  toggleSelfCollapse,
}) => {
  var duration = 0;
  const dailyDurations = service?.daily_durations ?? [];
  if (date) {
    duration = dailyDurations.find((day) => day.date === date)?.duration ?? 0;
  } else {
    duration = dailyDurations
      .map((dt) => dt.duration)
      .reduce((a, b) => a + b, 0);
  }

  const currentStep = useSelector((state) => state.tripPlannerCurrentStep);

  const service_reference = service.reference;
  const [expanded, setExpanded] = useState(false);
  const { isBooked, reservation_reference } = useSelector((state) =>
    srvIsBookedSelector(state, { reference: service_reference, srvType: "COO" })
  );

  const {
    setServiceRemarksUid,
    setServiceRemarksSrvType,
    setShowServiceRemarksModal,
  } = useContext(TripPlanContext);

  const classes = coordinatorDataStyles();

  if (typeof service.notes === "string") {
    service.notes = service.notes
      .split(" // ")
      .map((note) => note.replace("//", ""));
  }

  return (
    <div className={classes.CoordinatorService}>
      <div className={classes.header}>
        <span>
          {date &&
            `${DateTime.fromISO(date).toLocaleString(
              DateTime.DATE_MED_WITH_WEEKDAY
            )}: `}
          Coordinator: {service.name_en}
          <small>{service.uid}</small>
        </span>
        <div className={classes.tagsCntr}>
          {currentStep === 4 && !reservationMode && !isBooked && (
            <Button
              size="xs"
              color={service.booking_metadata ? "blue" : "green"}
              onClick={() => {
                setServiceRemarksUid(service?.uid);
                setServiceRemarksSrvType(service.custom_service_type);
                setShowServiceRemarksModal(true);
              }}>
              <strong>
                {service.booking_metadata ? "Edit Remarks" : "Add Remarks"}
              </strong>
            </Button>
          )}
          {isBooked && (
            <BookedTag reservation_reference={reservation_reference} />
          )}
          {reservationMode && (
            <ReservationStatusTag
              reservationLoading={reservationLoading}
              reservationStatus={reservationStatus}
            />
          )}
          <div className={classes.expandIconCntr}>
            <InlineIcon
              className={classes.expandIcon}
              icon={
                services_collapsed
                  ? "ri:arrow-down-s-line"
                  : "ri:arrow-up-s-line"
              }
              onClick={toggleSelfCollapse}
            />
          </div>
        </div>
      </div>
      {services_collapsed ? null : (
        <React.Fragment>
          <div className={classes.body}>
            <div className={classes.content}>
              <span className={classes.ratingCntr}>
                <span className={classes.title}>Internal Rating: </span>{" "}
                <RatingPanel
                  rating={service.internal_rating}
                  color="#F0B040"
                  fontSize="medium"
                  showEmpty={true}
                />
              </span>
              <span>
                <span className={classes.title}>Experience: </span>{" "}
                {service.experience_years
                  ? `${service.experience_years} Years`
                  : "N/A"}
              </span>
              <span>
                <span className={classes.title}>Driving License: </span>{" "}
                {service.has_driving_license ? "Yes" : "No"}
              </span>
              <span>
                <span className={classes.title}>Gender: </span>{" "}
                {service.gender === "MA" ? "Male" : "Female"}
              </span>
              <span>
                <span className={classes.title}>Travel License: </span>{" "}
                {service.has_travel_license ? "Yes" : "No"}
              </span>
              {date && (
                <span>
                  <span className={classes.title}>Operation Duration: </span>
                  {duration ? humanizeDuration(duration * 60 * 1000) : "N/A"}
                </span>
              )}
              <span>
                <span className={classes.title}>Type: </span>
                {_.get(service, "specialty", []).length
                  ? `(${service.specialty_display.join(", ")})`
                  : "N/A"}
              </span>
            </div>
          </div>
          <div className={classes.expandCntr}>
            <span className={classes.expandTitle}>Internal Notes</span>
            <span className={classes.arrowCntr}>
              <InlineIcon
                className={classes.arrowIcon}
                icon={
                  expanded ? "akar-icons:chevron-up" : "akar-icons:chevron-down"
                }
                onClick={() => setExpanded(!expanded)}
              />
            </span>
            {expanded && (
              <div className={classes.expandedDataCntr}>
                <div className={classes.divider} />
                <div className={classes.expandedData}>
                  {(service?.notes || []).length > 0 &&
                    service.notes.map((note, idx) => (
                      <div key={idx} className={classes.note}>
                        {note}
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
CoordinatorData.defaultProps = {
  reservationMode: false,
  reservationLoading: false,
  services_collapsed: false,
};
CoordinatorData.propTypes = {
  reservationMode: PropTypes.bool,
  reservationLoading: PropTypes.bool,
  reservationStatus: PropTypes.string,
  status: PropTypes.string,
  service: PropTypes.object.isRequired,
  date: PropTypes.string,
  services_collapsed: PropTypes.bool.isRequired,
  toggleSelfCollapse: PropTypes.func,
};

const CoordinatorServiceStyles = createUseStyles({
  CoordinatorServiceContainer: (props) =>
    daySrvContainerStyles({ currentStep: props.currentStep }),
  CoordinatorService: {
    ...cardStyles.card,
    background: variables.colors.services.coordinator,
  },
  header: {
    ...daySrvHeaderStyles(),
    borderBottomColor: variables.colors.services.coordinatorBorder,
  },
  headerActions: {
    display: "grid",
    gridAutoFlow: "column",
    grid: `calc(${variables.normal_gap} / 2)`,
  },
  body: (props) => ({
    ...daySrvBodyStyles(props),
    gridTemplateColumns: "2fr 3fr 4fr",
    overflow: "hidden",
    background: variables.colors.services.coordinatorBody,
  }),
  img: {
    objectFit: "cover",
    width: "100%",
    borderRadius: "5px",
    height: "8rem",
    alignSelf: "center",
  },
  content: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    gridAutoRows: "max-content",
  },
  secondaryContent: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    gridAutoRows: "max-content",
    overflow: "auto",
  },
  secondaryContentTitle: {
    borderBottom: `2px solid ${variables.colors.services.coordinatorBorder}`,
  },
  markupField: {
    alignSelf: "center",
    padding: `0 calc(${variables.normal_gap} * 3)`,
  },
});
const CoordinatorService = ({
  no_price,
  service,
  dayIdx,
  date,
  withControls,
  onRemove,
  onAdd,
  onEdit,
}) => {
  const { view_style } = useSelector((state) => state.tripPlannerOverviewStyle);
  const currentStep = useSelector((state) => state.tripPlannerCurrentStep);

  const { services_collapsed, toggleSelfCollapse } = useServiceCardExpansion();

  const classes = CoordinatorServiceStyles({
    collapsed: services_collapsed,
    currentStep,
  });
  const serviceIsSelected = useSelector((state) =>
    isServiceFilterSelectedSelector(state, { service_type: "COO" })
  );

  const { markup, gross_margin, flat_value } = useSelector((state) =>
    getServicePricingSelector(state, {
      key: srvPriceKeyCreator({ service, service_type: "COO" }),
    })
  );

  var price = 0;
  if (date) {
    price =
      (service?.daily_cost ?? []).find((day) => day.date === date)?.amount ?? 0;
  } else {
    price = (service?.daily_cost ?? [])
      .map((dt) => dt.amount)
      .reduce((a, b) => a + b, 0);
  }

  const currency = _.get(service, "currency");

  const { showPricingForm } = useContext(PricingFormContext);

  return !serviceIsSelected ? null : (
    <div className={classes.CoordinatorServiceContainer}>
      {currentStep === 4 ? (
        <BookChecker
          uid={service?.uid}
          srv_type={service.custom_service_type}
        />
      ) : !services_collapsed && withControls ? (
        <DayItemControls
          onDeselect={() =>
            onRemove({ dayIdx, srv_id: service?.uid ?? service.reference })
          }
          onEdit={() => onEdit({ dayIdx, srv_id: service?.uid })}
          onAdd={onAdd}
        />
      ) : (
        <div />
      )}
      <CoordinatorData
        service={service}
        date={view_style === "overview" ? date : null}
        services_collapsed={services_collapsed}
        toggleSelfCollapse={toggleSelfCollapse}
      />
      {showPricingForm ? (
        <ServicePricingForm service={service} service_type="COO" />
      ) : services_collapsed || no_price ? (
        <div />
      ) : (
        <OverviewPrice
          searching={false}
          serviceType="MI"
          price={price}
          currency={currency}
          markup={markup}
          gross_margin={gross_margin}
          flat_value={flat_value}
          onDetails={() => console.log("onDetails")}
        />
      )}
    </div>
  );
};
CoordinatorService.defaultProps = {
  no_price: false,
  withControls: true,
};
CoordinatorService.propTypes = {
  no_price: PropTypes.bool.isRequired,
  withControls: PropTypes.bool.isRequired,
  dayIdx: PropTypes.number,
  service: PropTypes.object.isRequired,
  date: PropTypes.string,
  onRemove: PropTypes.func,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
};
const mapDispatchToProps = (dispatch) => {
  return {
    onRemove: ({ dayIdx, srv_id }) =>
      dispatch(removeDbDCustomSrv({ dayIdx, srv_id, srv_type: "COO" })),
    onCopyBelow: ({ dayIdx, srv }) =>
      dispatch(addDbDCustomSrv({ dayIdx: dayIdx + 1, srv, srv_type: "COO" })),
  };
};
export default connect(null, mapDispatchToProps)(CoordinatorService);
