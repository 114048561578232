import _ from "lodash";
import React from "react";
import axios from "axios";
import { getAuthHeaders } from "../../../request";
import { SISI_API_URL } from "@src/api";
import { toast } from "react-toastify";

export async function fetchCoachReservation(params) {
  const auth_headers = getAuthHeaders();
  return await axios.get(`${SISI_API_URL}/reservations/coach`, {
    params,
    headers: { ...auth_headers },
  });
}

export async function retrieveCoachReservation({ reference }) {
  const auth_headers = getAuthHeaders();
  return await axios.get(`${SISI_API_URL}/reservations/coach/${reference}/`, {
    headers: { ...auth_headers },
  });
}

export async function updateCoachReservation({ reference, payload }) {
  const auth_headers = getAuthHeaders();
  try {
    const result = await axios.patch(
      `${SISI_API_URL}/reservations/coach/${reference}/`,
      payload,
      { headers: { ...auth_headers } }
    );
    return result.data;
  } catch (error) {
    const data = _.get(error, "response.data", {});
    toast.error(
      <p>
        We failed to update the Coach Reservation. We are sorry for the
        inconvenience.
        <br />
        <span>
          {!_.isEmpty(data)
            ? Object.entries(data).map(([field, errors], idxField) => (
                <React.Fragment key={idxField}>
                  <strong>{field}</strong>
                  <br />
                  <ul>
                    {errors.map((err, idxError) => (
                      <li key={idxError}>{err}</li>
                    ))}
                  </ul>
                  <br />
                </React.Fragment>
              ))
            : null}
        </span>
        <span>{_.get(data, "message")}</span>
        <br />
        <span>{_.get(data, "error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function retrievePubCoachReservation({ reference }) {
  try {
    const result = await axios.get(
      `${SISI_API_URL}/reservations/coach/public/${reference}/`
    );
    return result.data;
  } catch (error) {
    toast.error(
      <p>
        We failed to retrieve the Coach Reservation. We are sorry for the
        inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function patchPublicCoachReservation({
  reference,
  payload,
  onError = (error) =>
    toast.error(
      <p>
        We failed to update the Coach Reservation. We are sorry for the
        inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    ),
}) {
  try {
    const result = await axios.patch(
      `${SISI_API_URL}/reservations/coach/public/update/${reference}/`,
      payload
    );
    return result.data;
  } catch (error) {
    if (typeof onError === "function") {
      onError(error);
    }
    return null;
  }
}

export async function getCoachStats(params) {
  const auth_headers = getAuthHeaders();
  return await axios.get(`${SISI_API_URL}/reservations/coach/stats/`, {
    params,
    headers: { ...auth_headers },
  });
}
