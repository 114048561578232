export const TRIPPLANNER_SAVE_FLIGHT_SESSION_DATA =
  "TRIPPLANNER_SAVE_FLIGHT_SESSION_DATA";
export const TRIPPLANNER_RESET_FLIGHT_SESSION_DATA =
  "TRIPPLANNER_RESET_FLIGHT_SESSION_DATA";
export const TRIPPLANNER_SAVE_FLIGHT_DATA = "TRIPPLANNER_SAVE_FLIGHT_DATA";
export const TRIPPLANNER_REMOVE_FLIGHT_DATA = "TRIPPLANNER_REMOVE_FLIGHT_DATA";

export function saveFlightSessionData(session_data) {
  return { type: TRIPPLANNER_SAVE_FLIGHT_SESSION_DATA, session_data };
}

export function saveFlightData(flight) {
  flight.service_type = "TR";
  flight.transportation_service_type = "FL";
  return { type: TRIPPLANNER_SAVE_FLIGHT_DATA, flight };
}

export function removeFlightData(flightUid) {
  return { type: TRIPPLANNER_REMOVE_FLIGHT_DATA, flightUid };
}

export function resetFlightSessionData() {
  return { type: TRIPPLANNER_RESET_FLIGHT_SESSION_DATA };
}
