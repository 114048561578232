//=========================FILTER============================
// export const MY_OFFER__UNSENT_UPDATE_LIST_FILTER_FORM =
//   "MY_OFFER__UNSENT_UPDATE_LIST_FILTER_FORM";
// export const MY_OFFER__UNSENT_APPLY_LIST_FILTER =
//   "MY_OFFER__UNSENT_APPLY_LIST_FILTER";
// export const MY_OFFER__UNSENT_INITIALIZE_LIST_FILTER_FORM =
//   "MY_OFFER__UNSENT_INITIALIZE_LIST_FILTER_FORM";
// export const MY_OFFER__UNSENT_RESET_LIST_FILTER_FORM =
//   "MY_OFFER__UNSENT_RESET_LIST_FILTER_FORM";
export const MY_OFFER__UNSENT_CHANGE_PRICE_STATUS =
  "MY_OFFER__UNSENT_CHANGE_PRICE_STATUS";
//==============================NEW============================
export const MY_OFFER_UNSENT_SAVE_RESULTS = "MY_OFFER_UNSENT_SAVE_RESULTS";
export const MY_OFFER_UNSENT_LIST_GENERIC_CHANGE =
  "MY_OFFER_UNSENT_LIST_GENERIC_CHANGE";
//=============================LIST============================
// export const MY_OFFER_UNSENT_LIST_LOADING = "MY_OFFER_UNSENT_LIST_LOADING";
// export const MY_OFFER_UNSENT_LIST_IDLE = "MY_OFFER_UNSENT_LIST_IDLE";
// export const MY_OFFER_UNSENT_FETCH_DATA = "MY_OFFER_UNSENT_FETCH_DATA";
//========================DASHBOARD===============================
export const MY_OFFER_UNSENT_VIEW_TYPE = "MY_OFFER_UNSENT_VIEW_TYPE";
export const MY_OFFER_UNSENT_FETCH_GRAPH_DATA =
  "MY_OFFER_UNSENT_FETCH_GRAPH_DATA";
export const MY_OFFER_UNSENT_BEGIN_FETCH_GRAPH_DATA =
  "MY_OFFER_UNSENT_BEGIN_FETCH_GRAPH_DATA";
export const MY_OFFER_UNSENT_END_FETCH_GRAPH_DATA =
  "MY_OFFER_UNSENT_END_FETCH_GRAPH_DATA";
export const MY_OFFER_UNSENT_INIT_GRAPH_TYPE =
  "MY_OFFER_UNSENT_INIT_GRAPH_TYPE,";
