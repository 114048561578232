import PropTypes from "prop-types";
import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { currencies } from "@src/definitions";

const styles = StyleSheet.create({
  PriceTable: {
    borderRadius: "5px",
    backgroundColor: "#302A83",
    border: "1px solid #302A83",
    width: 550,
    fontSize: 10,
    color: "white",
    padding: 5,
    fontFamily: "Helvetica-Bold",
  },
  row: { flexDirection: "row" },
  cell: {
    paddingLeft: 10,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 5,
    width: 100,
  },
  titleCell: {
    paddingLeft: 10,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 5,
    width: 100,
    borderRight: "1px solid white",
  },
});
const PriceTable = ({ priceSegments, cancellationPolicy }) => {
  const currency = cancellationPolicy?.currency;
  const deposit = cancellationPolicy?.deposit ?? 0;
  const balance = cancellationPolicy?.balance ?? 0;
  var totalPaymentTerms = deposit + balance;
  if (cancellationPolicy?.totalPriceRounding === "round-up") {
    totalPaymentTerms = Math.ceil(totalPaymentTerms);
  } else if (cancellationPolicy?.totalPriceRounding === "round-down") {
    totalPaymentTerms = Math.floor(totalPaymentTerms);
  }

  return (
    <View style={styles.PriceTable}>
      <View style={styles.row}>
        <View style={styles.titleCell}>
          <Text>Paying Members</Text>
        </View>
        {priceSegments.map((seg, idx) => {
          return (
            <View key={idx} style={styles.cell}>
              <Text>
                {seg.pax} + {seg.coords}
              </Text>
            </View>
          );
        })}
      </View>
      <View style={styles.row}>
        <View style={styles.titleCell}>
          <Text>Package Price</Text>
        </View>
        {priceSegments.map((__, idx) => (
          <View key={idx} style={styles.cell}>
            <Text>
              {!!currency &&
                totalPaymentTerms.toLocaleString("en-US", {
                  style: "currency",
                  currency,
                })}
            </Text>
          </View>
        ))}
      </View>
    </View>
  );
};
PriceTable.defaultProps = { priceSegments: [] };
PriceTable.propTypes = { priceSegments: PropTypes.array.isRequired };
export default PriceTable;
