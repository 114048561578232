import { Button } from "rsuite";

const pageWentWrong =
  "https://sisistaticassets.s3.ap-southeast-1.amazonaws.com/platform_graphics/imgs/logo_page_filler.svg";

import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";

class PageErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError() {
    return { hasError: true };
  }
  render() {
    const { hasError } = this.state;
    const { children, history } = this.props;

    if (hasError) {
      return (
        <div className="PageError">
          <img src={pageWentWrong} />
          <h3>
            Ooops! This page failed to render. We are sorry for the
            inconvenience.
          </h3>
          <Button
            appearance="primary"
            onClick={() => {
              history.goBack();
            }}>
            Go back
          </Button>
        </div>
      );
    }

    return children;
  }
}

PageErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  history: PropTypes.object.isRequired,
};

export default connect(null, null)(withRouter(PageErrorBoundary));
