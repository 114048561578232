import _ from "lodash";
import { createSelector } from "reselect";

export const getTripLegs = (state) => state.tripPlannerLegsReducer;

export const getNewAllFlights = (state) =>
  state.tripPlannerFlightsReducer?.services ?? [];

const propsInjector = (__, props) => props;

const destinations = (state) => state.tripPlannerDestinations;
const originData = (state) => state.tripPlannerOriginData;
const returnData = (state) => state.tripPlannerReturnData;

export const extremalIsRoundSelector = createSelector(
  [destinations, originData, returnData],
  (destinations, originData, returnData) => {
    const returnDestId = _.get(returnData, "destination.id", null);

    return (
      destinations[0].id == _.last(destinations).id &&
      originData?.destination?.id == returnDestId
    );
  }
);

const getSelectedFlightByLegUid = (flights, tripLegs, props) => {
  const { legUid } = props;

  const tripLeg = tripLegs.find((l) => l.uid === legUid);
  const flight = flights.find((fl) => fl.legUid === legUid);
  return { flight, tripLeg };
};
export const getSelectedFlightByLegUidSelector = createSelector(
  [getNewAllFlights, getTripLegs, propsInjector],
  getSelectedFlightByLegUid
);

export const getFlightSessions = (state) =>
  state.tripPlannerFlightsReducer.sessions;

export const getSecondaryLegFlight = (
  flights,
  tripLegs,
  flightSessions,
  legUid
) => {
  const flightSession = flightSessions.find((s) =>
    s?.payload?.relatedLegUids.includes(legUid)
  );

  const leg = tripLegs.find((l) => l.uid === flightSession?.payload?.legUid);
  const { flight, tripLeg } = getSelectedFlightByLegUid(flights, tripLegs, {
    legUid: leg?.uid,
  });
  return { flight, tripLeg, flightSession };
};
export const getSecondaryLegFlightSelector = createSelector(
  [getNewAllFlights, getTripLegs, getFlightSessions, propsInjector],
  (flights, tripLegs, flightSessions, props) => {
    const { legUid } = props;
    return getSecondaryLegFlight(flights, tripLegs, flightSessions, legUid);
  }
);

export const getDestArrivalFlightSelector = createSelector(
  [getNewAllFlights, propsInjector],
  (flights, props) => {
    if (!flights.length) {
      return null;
    }
    // const { destOrder, returnFlight } = props;
    return flights;
  }
);

const getAdhocTrpService = (state) => state.tripPlanTrpAddhocServices;
export const getAdhocOriginTrpSrvSelector = createSelector(
  [getAdhocTrpService],
  (trp_services) => {
    const leg = _.get(trp_services, "legs", []).find(
      (srv) => srv.destination_order === 1
    );
    return leg;
  }
);

const getAdhocTrpSrvById = (state, { srv_id, srv_type }) =>
  state.tripPlanTrpAddhocServices.legs.find(
    (srv) =>
      srv.service.uid === srv_id && srv.transportation_service_type === srv_type
  );
export const getAdhocTrpSrvByIdSelector = createSelector(
  [getAdhocTrpSrvById],
  (service) => service
);

export const getAdhocDestTrpSrvSelector = createSelector(
  [getAdhocTrpService, propsInjector],
  (trp_services, props) => {
    const { originDestOrder, destinationDestOrder } = props;
    const leg = _.get(trp_services, "legs", []).find(
      (srv) => srv.origin_order === originDestOrder
    );
    return leg;
  }
);
