import {
  CUSTOM_SERVICE_ADDON_ADD_EXCLUSION_INCLUSION,
  CUSTOM_SERVICE_ADDON_REMOVE_EXCLUSION_INCLUSION,
  CUSTOM_SERVICE_ADDON_CHANGE_EXCLUSION_INCLUSION,
} from "./types";

export const addInclusionExclusion = (dataType) => {
  return { type: CUSTOM_SERVICE_ADDON_ADD_EXCLUSION_INCLUSION, dataType };
};

export const removeInclusionExclusion = (uid, dataType) => {
  return {
    type: CUSTOM_SERVICE_ADDON_REMOVE_EXCLUSION_INCLUSION,
    uid,
    dataType,
  };
};

export const changeInclusionExclusion = (dataType, uid, key, value) => {
  return {
    type: CUSTOM_SERVICE_ADDON_CHANGE_EXCLUSION_INCLUSION,
    dataType,
    uid,
    key,
    value,
  };
};
