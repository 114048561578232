import {
  Tag,
  Modal,
  IconButton,
  Icon,
  Loader,
  PanelGroup,
  Panel,
  List,
  Checkbox,
} from "rsuite";

import {
  RoomDetails,
  RoomCancellationPolicy,
} from "@src/pages/Project/TripPlan/Components/AccDetailsRooms";

import { Rating } from "./OverviewAllAccommodation";

// ============================= ACTIONS =============================
import {
  hidePrebookAccModal,
  acceptAccPrebook,
  acceptAllAccPrebook,
} from "@src/actions/Project/TripPlanner";

// ============================ SELECTORS ============================
import {
  getAccPrebookInfo,
  getAccAPIPriceSelector,
  getHotelSelectedRooms,
  getPrebookAccInfo,
  requiredRoomsNumberSelector,
} from "@src/selectors/Project/TripPlanner";

import { formatDate } from "@src/tools/date_tools";

import _ from "lodash";
import moment from "moment";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import React from "react";
import { naString } from "@src/tools/string_tools";
import sanitizeHtml from "sanitize-html";
import { getAccSrvsForPrebookSelector } from "@src/selectors/Project/TripPlanner/prebook";
import { createUseStyles } from "react-jss";
import PriceDetails from "./PriceDetails";
import { cardStyles, variables } from "@src/jsssetup";

const accPrebookFailed = (accommodation) => {
  const failed =
    _.isEmpty(_.get(accommodation, "prebook_info", {})) ||
    _.flatten(Object.values(_.get(accommodation, "prebook_info", {}))).some(
      (p) => _.get(p, "failed", false)
    );

  return failed;
};

function htmlEncode(string) {
  let txt = new DOMParser().parseFromString(string, "text/html");

  txt = txt.documentElement.textContent;

  let e = document.createElement("div");
  e.innerHTML = txt;
  return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
}

export function BookingRemarks({ remarks }) {
  if (!remarks) {
    return null;
  }

  return (
    <List>
      {Object.entries(remarks).map((e, idx) => (
        <List.Item key={idx}>
          <strong>{_.startCase(e[0])}: </strong>
          <span
            dangerouslySetInnerHTML={{ __html: sanitizeHtml(htmlEncode(e[1])) }}
          />
        </List.Item>
      ))}
    </List>
  );
}
BookingRemarks.propTypes = {
  remarks: PropTypes.object.isRequired,
};

const prebookRoomDetailsStyles = createUseStyles({
  PrebookRoomDetails: {},
});
function PrebookRoomDetails(props) {
  const { destOrder, prebook_info, room, onAcceptRoom } = props;

  const { metadata, cancellation_policies, accepted } = prebook_info;

  const classes = prebookRoomDetailsStyles();

  return (
    <React.Fragment>
      <h6>
        Accommodation Details{" "}
        <Checkbox
          checked={accepted}
          onChange={(value, checked) => onAcceptRoom(destOrder, checked)}>
          Accept
        </Checkbox>
      </h6>
      <ul className="AccommodationDetails">
        <li>
          <strong>Name: </strong>
          {metadata.name}
        </li>
        <li>
          <strong>Rating: </strong>
          <Rating rating={metadata.rating} />
        </li>
        <li>
          <strong>Address: </strong>
          {metadata.address}
        </li>
        <li>
          <strong>Phone Numbers: </strong>
          {naString(
            _.get(metadata, "phones", [])
              .filter((p) => p.phone)
              .map((p) => p.phone)
              .join(", ")
          )}
        </li>
      </ul>
      <h6>Room Details</h6>
      <RoomDetails room={room} />
      <RoomCancellationPolicy cancellation_policies={cancellation_policies} />
      <h6>Booking Remarks</h6>
      <BookingRemarks remarks={_.get(room, "booking_remarks", {})} />
    </React.Fragment>
  );
}
PrebookRoomDetails.propTypes = {
  destOrder: PropTypes.number.isRequired,
  room: PropTypes.object.isRequired,
  prebook_info: PropTypes.object.isRequired,
  onAcceptRoom: PropTypes.func.isRequired,
};

const failedPrebookStyles = createUseStyles({
  FailedPrebook: {
    fontWeight: "bold",
    color: variables.colors.layoverRed,
    display: "grid",
    gridTemplateColumns: "max-content 1fr",
    gridGap: variables.normal_gap,
    alignItems: "center",
  },
});
function FailedPrebook() {
  const classes = failedPrebookStyles();
  return (
    <span className={classes.FailedPrebook}>
      <Icon icon="exclamation-circle" color="red" size="2x" /> Failed to get
      prebook information. Please refresh your search or select another
      accommodation and/or room.
    </span>
  );
}

const destinationStyles = createUseStyles({
  Destination: cardStyles.card,
  header: {
    ...cardStyles.header,
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateColumns: "1fr max-content",
  },
  body: cardStyles.body,
});
const Destination = ({ destination, accommodation, onAcceptRoom }) => {
  const failed = accPrebookFailed(accommodation);

  const stay = moment
    .duration(moment(destination.checkOut).diff(moment(destination.checkIn)))
    .days();

  const nights = stay > 1 ? "Nights" : "Night";

  const { name_en, checkIn, checkOut } = destination;

  // const { price, currency } = useSelector((state) =>
  //   getAccAPIPriceSelector(state, { accommodation })
  // );

  const classes = destinationStyles();

  function renderRoomHeader(prebook_info, room, idx) {
    var ad = room.pax.adults;
    var ch = room.pax.children;

    ad = ad > 1 ? `${ad} Adults` : `${ad} Adult`;
    ch = ch == 0 ? "" : ch > 1 ? `, ${ch} Children` : `, ${ch} Child`;

    return (
      <React.Fragment>
        <strong>{`${idx}. ${room.name} (${ad}${ch})`}</strong>
        {prebook_info.result_changed ? (
          <span className="warning">
            <Icon icon="exclamation-triangle" />
            {`There was a change in this room's details.`}
          </span>
        ) : null}
        {prebook_info.accepted ? <Tag color="green">Accepted</Tag> : null}
      </React.Fragment>
    );
  }

  const price = accommodation?.prebook_info?.total_price?.price || 0;
  const currency = accommodation?.prebook_info?.total_price?.currency || "";

  return !accommodation ? null : (
    <div className={classes.Destination}>
      <div className={classes.header}>
        <h5>
          {`${name_en} ${formatDate(checkIn)} - ${formatDate(
            checkOut
          )} (${stay} ${nights}) at ${accommodation.metadata.name}`}
        </h5>
        <PriceDetails
          serviceType="ACC"
          searching={false}
          price={price}
          currency={currency}
        />
      </div>
      <div className={classes.body}>
        {failed ? (
          <FailedPrebook />
        ) : (
          <PanelGroup defaultActiveKey={"00"} accordion bordered>
            {Object.values(_.get(accommodation, "prebook_info.rooms", [])).map(
              (room, idx) => {
                const eventKey = `${idx}`;

                var cls = "PrebookRoomDetails";
                const result_changed =
                  accommodation.prebook_info.result_changed;
                const accepted = accommodation.prebook_info.accepted;
                cls += result_changed ? "  PrebookRoomDetails--altered" : "";
                cls += accepted ? " PrebookRoomDetails--accepted" : "";

                return (
                  <Panel
                    className={cls}
                    key={eventKey}
                    eventKey={eventKey}
                    header={renderRoomHeader(
                      accommodation.prebook_info,
                      room,
                      idx + 1
                    )}>
                    <PrebookRoomDetails
                      room={room}
                      prebook_info={accommodation.prebook_info}
                      destOrder={accommodation.destOrder}
                      onAcceptRoom={onAcceptRoom}
                    />
                  </Panel>
                );
              }
            )}
          </PanelGroup>
        )}
      </div>
    </div>
  );
};
Destination.propTypes = {
  accommodation: PropTypes.object.isRequired,
  destination: PropTypes.object.isRequired,
  onAcceptRoom: PropTypes.func.isRequired,
};

const PrebookAccommodationModal = ({ onHide, onAcceptRoom, onAcceptAll }) => {
  const { show, loading } = useSelector(
    (state) => state.tripPlannerPrebookAccModal
  );

  const destinations = useSelector((state) => state.tripPlannerDestinations);

  const selected_accs = useSelector((state) =>
    getAccSrvsForPrebookSelector(state)
  );

  const prebook_info = useSelector((state) => getAccPrebookInfo(state));

  const accommodations = destinations
    .filter((d) =>
      selected_accs.some(
        (acc) => acc.destOrder.toString() === d.order.toString()
      )
    )
    .map((d) => {
      const acc = selected_accs.find(
        (acc) => acc.destOrder.toString() === d.order.toString()
      );

      return {
        ...selected_accs.find(
          (acc) => acc.destOrder.toString() === d.order.toString()
        ),
        ...{ rooms: getHotelSelectedRooms({ acc }) },
        prebook_info: _.get(prebook_info, d.order),
        destOrder: d.order,
      };
    });

  const someFailed = accommodations.some((a) => accPrebookFailed(a));

  return !show ? null : (
    <Modal
      className={`CustomModal PrebookAccommodationModal ${
        loading ? " CustomModal--loading" : ""
      }`}
      show={show}
      onHide={onHide}
      size="md">
      <Modal.Header>
        <Modal.Title>Accommodation Prebook Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Loader vertical content="Please wait..." size="lg" />
        ) : (
          destinations.map((d, idx) => (
            <Destination
              key={idx}
              destination={d}
              accommodation={accommodations.find((a) => a.destOrder == d.order)}
              onAcceptRoom={onAcceptRoom}
            />
          ))
        )}
      </Modal.Body>
      <Modal.Footer>
        {loading ? null : (
          <React.Fragment>
            <IconButton
              color="blue"
              icon={<Icon icon="close" />}
              onClick={onHide}>
              Close
            </IconButton>
            {someFailed ? null : (
              <IconButton
                color="green"
                icon={<Icon icon="check" />}
                onClick={onAcceptAll}>
                Accept
              </IconButton>
            )}
          </React.Fragment>
        )}
      </Modal.Footer>
    </Modal>
  );
};
PrebookAccommodationModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  onAcceptRoom: PropTypes.func.isRequired,
  onAcceptAll: PropTypes.func.isRequired,
};
const mapDispatchToProps = (dispatch) => {
  return {
    onHide: () => dispatch(hidePrebookAccModal()),
    onAcceptRoom: (destOrder, accepted) => {
      dispatch(acceptAccPrebook(destOrder, accepted));
    },
    onAcceptAll: () => dispatch(acceptAllAccPrebook()),
  };
};
export default connect(null, mapDispatchToProps)(PrebookAccommodationModal);
