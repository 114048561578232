import {
  //======================= LIST VIEW=============================
  // ADDON_RESERVATION_LIST_SAVE,
  // ADDON_RESERVATION_BEGIN_FETCH_DATA,
  // ADDON_RESERVATION_END_FETCH_DATA,
  //==================== FILTER RELATION ==========================
  // ADDON_RESERVATION_INITIALIZE_LIST_FILTER_FORM,
  // ADDON_RESERVATION_UPDATE_FILTERS,
  // ADDON_RESERVATION_APPLY_LIST_FILTER,
  // ADDON_RESERVATION_RESET_LIST_FILTER_FORM,
  //==================== COLUMNS RELATION =========================
  // ADDON_RESERVATION_UPDATE_LIST_COLUMNS_FORM,
  // ADDON_RESERVATION_APPLY_LIST_COLUMNS,
  // ADDON_RESERVATION_INITIALIZE_LIST_COLUMNS_FORM,
  //===================== DETAIL VIEW =============================
  // ADDON_RESERVATION_RESERVATION_INIT,
  // ADDON_RESERVATION_FETCH_DETAIL_VIEW,
  // ADDON_RESERVATION_RESERVATION_DATA,

  //========================DASHBOARD===============================
  ADDON_RESERVATION_VIEW_TYPE,
  ADDON_RESERVATION_FETCH_GRAPH_DATA,
  ADDON_RESERVATION_BEGIN_FETCH_GRAPH_DATA,
  ADDON_RESERVATION_END_FETCH_GRAPH_DATA,
  ADDON_RESERVATION_INIT_GRAPH_TYPE,
} from "@src/actions/Operation/AddOns/types";

// import { initialReservationAddonsFilterForm } from "@src/forms/Operation/Addons/addonsForm";
// import { initialAddonsColumnsSetUp } from "@src/forms/Operation/Addons/addonsColumns";

// import { formProcessor, entitiesListSelector } from "@src/reducers/tools";

// import update from "immutability-helper";
// import _ from "lodash";
// import { commonList } from "@src/reducers/common";

// ============================ LIST VIEW ============================
// const addonsFiltersInitial = {
//   title: "",
//   destination: "",
//   created__gte: "",
//   created__lte: "",
//   pax: "",
//   reference: "",
//   operational_dates__gte: "",
//   operational_dates__lte: "",
//   status: "",
// };
// export const initialAddonsList = {
//   ...commonList,
//   page: 1,
//   limit: 20,
//   filters: addonsFiltersInitial,
// };
// export const reservationAddonsList = (state = initialAddonsList, action) => {
//   switch (action.type) {
//     case ADDON_RESERVATION_LIST_SAVE: {
//       const { data } = action;
//       return { ...state, ...data };
//     }
//     case ADDON_RESERVATION_UPDATE_FILTERS: {
//       const { data } = action;
//       return { ...state, filters: data };
//     }
//     default:
//       return state;
//   }
// };

// export const initialReservationAddonsFetchStatus = "IDLE";
// export const reservationAddonsFetchStatus = (
//   state = initialReservationAddonsFetchStatus,
//   action
// ) => {
//   switch (action.type) {
//     case ADDON_RESERVATION_BEGIN_FETCH_DATA:
//       return "FETCHING";
//     case ADDON_RESERVATION_END_FETCH_DATA:
//       return "FETCHED";
//     default:
//       return state;
//   }
// };

// ========================= COLUMNS RELATION ========================
// export const addonsListColumns = (
//   state = entitiesListSelector(initialAddonsColumnsSetUp),
//   action
// ) => {
//   switch (action.type) {
//     case ADDON_RESERVATION_APPLY_LIST_COLUMNS:
//       return entitiesListSelector(action.data);
//     default:
//       return state;
//   }
// };

// export const addonsListColumnsForm = (
//   state = initialAddonsColumnsSetUp,
//   action
// ) => {
//   switch (action.type) {
//     case ADDON_RESERVATION_INITIALIZE_LIST_COLUMNS_FORM:
//       var newState = {};
//       for (const key in state) {
//         // clear form's value
//         state[key] = update(state[key], { value: { $set: [] } });
//         newState[key] = update(state[key], { value: { $set: [] } });
//         // add value by action data
//         action.data.forEach((item) => {
//           if (_.find(state[key].choices, { key: item.key })) {
//             newState[key] = update(newState[key], {
//               value: { $push: [item.key] },
//             });
//           }
//         });
//       }
//       return newState;
//     case ADDON_RESERVATION_UPDATE_LIST_COLUMNS_FORM:
//       return action.data;
//     default:
//       return state;
//   }
// };

//============================ DETAIL VIEW =============================
// export const initialAddonsNavStatus = "reservation";
// export const AddonsNavStatus = (state = initialAddonsNavStatus, action) => {
//   switch (action.type) {
//     case ADDON_RESERVATION_RESERVATION_INIT:
//       return "reservation";
//     case ADDON_RESERVATION_FETCH_DETAIL_VIEW:
//       return action.data;
//     default:
//       return state;
//   }
// };

// export const addonsReservationInfo = (state = {}, action) => {
//   switch (action.type) {
//     case ADDON_RESERVATION_RESERVATION_INIT:
//       return {};
//     case ADDON_RESERVATION_RESERVATION_DATA:
//       return action.data;
//     default:
//       return state;
//   }
// };

//====================================DASHBOARD===============================
export const initData = {
  type: "current",
  name: "SR",
};
export const addonsViewType = (state = initData, action) => {
  switch (action.type) {
    case ADDON_RESERVATION_INIT_GRAPH_TYPE:
      return initData;
    case ADDON_RESERVATION_VIEW_TYPE:
      return action.data;
    default:
      return state;
  }
};

export const addonsGraphData = (state = {}, action) => {
  switch (action.type) {
    case ADDON_RESERVATION_FETCH_GRAPH_DATA:
      return action.data;
    default:
      return state;
  }
};

export const initialAddonsGraphDataStatus = "IDLE";
export const addonsGraphDataStatus = (
  state = initialAddonsGraphDataStatus,
  action
) => {
  switch (action.type) {
    case ADDON_RESERVATION_BEGIN_FETCH_GRAPH_DATA:
      return "FETCHING";
    case ADDON_RESERVATION_END_FETCH_GRAPH_DATA:
      return "FETCHED";
    default:
      return state;
  }
};
