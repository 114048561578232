export const TRIPPLANNER_SAVE_TRIP_LEGS = "TRIPPLANNER_SAVE_TRIP_LEGS";
export const TRIPPLANNER_RESET_TRIP_LEGS = "TRIPPLANNER_RESET_TRIP_LEGS";

export function saveTripLegs({ legs }) {
  return { type: TRIPPLANNER_SAVE_TRIP_LEGS, legs };
}

export function resetTripLegs() {
  return { type: TRIPPLANNER_RESET_TRIP_LEGS };
}
