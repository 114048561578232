import { getGeneralServices } from "../../../api/GeneralServices";
import { getUserSourceEntitySelector } from "../../../selectors/Shared/user_selectors";
import {
  GENERAL_SERVICES_SERVICE_MODAL_SHOW,
  GENERAL_SERVICES_SERVICE_MODAL_HIDE,
  GENERAL_SERVICES_IMPORT_SERVICE_MODAL_SHOW,
  GENERAL_SERVICES_IMPORT_SERVICE_MODAL_HIDE,
  GENERAL_SERVICES_FETCH_LIST,
  GENERAL_SERVICES_LIST_GENERIC_CHANGE,
} from "./types";

export const showGenServiceModal = () => {
  return { type: GENERAL_SERVICES_SERVICE_MODAL_SHOW };
};

export const hideGenServiceModal = () => {
  return { type: GENERAL_SERVICES_SERVICE_MODAL_HIDE };
};

export const showGenImportServiceModal = () => {
  return { type: GENERAL_SERVICES_IMPORT_SERVICE_MODAL_SHOW };
};

export const hideGenImportServiceModalServiceModal = () => {
  return { type: GENERAL_SERVICES_IMPORT_SERVICE_MODAL_HIDE };
};

export const saveGeneralServicesResults = (data) => {
  return { type: GENERAL_SERVICES_FETCH_LIST, data };
};

export const fetchGeneralServicesData = () => async (dispatch, getState) => {
  const state = getState();

  const source_entity = getUserSourceEntitySelector(state);
  const { page, limit, filters } = state.GeneralSrvsListReducer;

  const vFilters = {};
  Object.entries(filters)
    .filter((entry) => entry[1])
    .forEach((entry) => (vFilters[entry[0]] = entry[1]));

  const data = await getGeneralServices({
    params: { page_size: limit, page, source_entity, ...filters },
  });
  dispatch(
    saveGeneralServicesResults({
      page,
      limit,
      ...data,
    })
  );
};
export const generalServicesGenericChange = (key, value) => {
  return { type: GENERAL_SERVICES_LIST_GENERIC_CHANGE, key, value };
};
