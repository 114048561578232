import { getAuthHeaders, serviceCxl } from "@src/api/request";
import { SISI2_API_URL, SISI_API_URL } from "..";
import axios from "axios";
import * as yup from "yup";

export const getCurrenciesInfo = () => {
  return serviceCxl(
    {
      url: `/bid/currency-info`,
      method: "get",
      params: { limit: 1000 },
    },
    10000
  );
};

export const getForexData = (
  base_currency,
  target_currency,
  conversion_date,
  source_entity
) => {
  return serviceCxl(
    {
      url: `/bid/exchange-rates`,
      method: "get",
      params: {
        base_currency,
        target_currency,
        conversion_date,
        source_entity,
      },
    },
    10000
  );
};

export const getCustomCurrencies = async ({ source_entity }) => {
  const response = await axios.get(
    `${SISI2_API_URL}/paymentengine/custom-currency/`,
    { params: { source_entity } }
  );
  return response.data;
};

export const createCustomCurrency = async (payload) => {
  const response = await axios.post(
    `${SISI2_API_URL}/paymentengine/custom-currency/`,
    payload
  );
  return response.data;
};

export const deleteCustomCurrency = async ({ id, source_entity }) => {
  const response = await axios.delete(
    `${SISI2_API_URL}/paymentengine/custom-currency/${id}/`,
    { params: { source_entity } }
  );
  return response.data;
};

const srvTypes = [
  "FL",
  "TRF",
  "ACC",
  "ACT",
  "FER",
  "TRA",
  "COO",
  "REST",
  "GEN",
  "PKG",
];

const paymentLinkValidationSchema = (provider = "flywire") =>
  yup.object().shape({
    first_name:
      provider === "flywire" ? yup.string().required("Required") : yup.string(),
    last_name:
      provider === "flywire" ? yup.string().required("Required") : yup.string(),
    customer_name: ["airwallex", "felloh"].includes(provider)
      ? yup.string().required("Required")
      : yup.string(),
    email: yup.string().email().required("Required"),
    booking_id: yup.string().required("Required"),
    amount: yup.number().required("Required"),
    source_entity: yup.string().required("Required"),
    open_banking_enabled: yup.boolean().required("Required").default(true),
    card_enabled: yup.boolean().required("Required").default(true),
    currency: yup.string().required("Required"),
    description: yup.string().required("Required"),
    expires_at: yup.string().required("Required"),
    authorisation_only: yup.boolean().required("Required").default(false),
    booking_data: yup
      .object()
      .shape({
        first_name:
          provider === "flywire"
            ? yup.string().required("Required")
            : yup.string(),
        last_name:
          provider === "flywire"
            ? yup.string().required("Required")
            : yup.string(),
        customer_name: ["airwallex", "felloh"].includes(provider)
          ? yup.string().required("Required")
          : yup.string(),
        email: yup.string().email().required("Required"),
        gross_amount: yup.number().required("Required"),
        start_date: yup.string().required("Required"),
        end_date: yup.string().required("Required"),
        booking_reference: yup.string().required("Required"),
        srv_type: yup
          .string()
          .required("Required")
          .oneOf(srvTypes, "Invalid service type"),
      })
      .required("Required"),
  });
export const createAirwallexPaymentLink = async (payload) => {
  const validatedData = await paymentLinkValidationSchema("airwallex").validate(
    payload
  );

  const authHeaders = getAuthHeaders();
  const url = `${SISI_API_URL}/b2c-payment/airwallex/payment-link/`;
  return await axios.post(url, validatedData, { headers: authHeaders });
};

export const createFlywirePaymentLink = async (payload) => {
  const validatedPayload = await paymentLinkValidationSchema(
    "flywire"
  ).validate(payload);

  const authHeaders = getAuthHeaders();
  const url = `${SISI_API_URL}/b2c-payment/flywire/payment-link/`;
  return await axios.post(url, validatedPayload, { headers: authHeaders });
};

export const createFellohPaymentLink = async (payload) => {
  const validatedPayload = await paymentLinkValidationSchema("felloh").validate(
    payload
  );

  const authHeaders = getAuthHeaders();
  const url = `${SISI_API_URL}/b2c-payment/felloh/checkout/`;
  return await axios.post(url, validatedPayload, { headers: authHeaders });
};
