import React from "react";
import _ from "lodash";

import { getDisplayNameFromOptions } from "@src/processors/columnsDataProcessor";

import TransTxt from "@src/components/common/SxFormatMessage";

export const initialMembersColumnsSetUp = {
  basic: {
    value: ["company", "tag", "expertise", "main_service", "city", "country"],
    choices: [
      {
        display_name: <TransTxt id="company" startCase />,
        key: "company",
        path: "legal_title",
      },
      {
        display_name: <TransTxt id="tag" startCase />,

        key: "tag",
        path: "entity_type[0]",
      },
      {
        display_name: <TransTxt id="expertise" startCase />,

        key: "expertise",
        path: "expertise_type[0]",
      },
      {
        display_name: <TransTxt id="main_service" startCase />,

        key: "main_service",
        path: "main_service",
        hasOptions: true,
      },
      {
        display_name: <TransTxt id="city" startCase />,

        key: "city",

        isAddress: true,
        option: "travelServiceOptions",
      },
      {
        display_name: <TransTxt id="country" startCase />,

        key: "country",
        path: "country_code",
        isAddress: true,
      },
    ],
  },
};

export const initialMembersColumnsProcessor = {
  company: {
    key: "company",
    getter: (data) => data.legal_title,
  },
  tag: {
    key: "tag",
    getter: (data) => data.entity_type,
  },
  expertise: {
    key: "expertise",
    getter: (data) => data.expertise_type,
    extraGetter: (data, options) => {
      return getDisplayNameFromOptions(
        data.expertise_type,
        _.get(options[1], "expertise_type.child.choices")
      );
    },
  },
  main_service: {
    key: "main_service",
    getter: (data, options) => {
      return getDisplayNameFromOptions(
        data.main_service,
        _.get(options[1], "main_service.choices", [])
      );
    },
  },
  city: {
    key: "city",
    getter: (data) => {
      const res = _.groupBy(data.address, "address_type", {}).BU;
      return res[0].city;
    },
  },
  country: {
    key: "country",
    getter: (data) => {
      const res = _.groupBy(data.address, "address_type", {}).BU;
      return res[0].country_code;
    },
  },
};

export const initialMembersColumnsMapping = {
  id: {
    key: "id",
    fixed: "left",
  },
  tag: {
    key: "tag",
    fixed: "left",
  },
  expertise: {
    key: "expertise",
  },
  main_service: {
    key: "main_service",
  },
  city: {
    key: "city",
  },
  country: {
    key: "country",
  },
};
