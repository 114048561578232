import {
  TRIPPLANNER_DBD_ADD_CUSTOM_SRV,
  TRIPPLANNER_DBD_REMOVE_CUSTOM_SRV,
  TRIPPLANNER_DBD_UPDATE_COORD_SRV,
  TRIPPLANNER_DBD_UPDATE_CUSTOM_SRV,
  TRIPPLANNER_RESET_CUSTOM_SERVICES,
} from "../types/day_by_day/custom_services";

export function resetDbDCustomSrvs() {
  return { type: TRIPPLANNER_RESET_CUSTOM_SERVICES };
}

export const addDbDCustomSrv = ({ dayIdx, srv, srv_type }) => {
  srv.srvType = "CU";
  srv.custom_service_type = srv_type;
  return { type: TRIPPLANNER_DBD_ADD_CUSTOM_SRV, dayIdx, srv };
};

export const removeDbDCustomSrv = ({ dayIdx, srv_id, srv_type }) => {
  return { type: TRIPPLANNER_DBD_REMOVE_CUSTOM_SRV, dayIdx, srv_id, srv_type };
};

export const updateDbDCustomSrv = ({ dayIdx, srv_id, srv_type, data }) => {
  return {
    type: TRIPPLANNER_DBD_UPDATE_CUSTOM_SRV,
    dayIdx,
    srv_id,
    srv_type,
    data,
  };
};

export const updateDbDCoordSrv = ({ srv_id, data }) => {
  return {
    type: TRIPPLANNER_DBD_UPDATE_COORD_SRV,
    srv_id,
    data,
  };
};
