import _ from "lodash";
import service from "@src/api/request";
import { SISI_API_URL } from "@src/api";
import axios from "axios";

export function getMembersList(param) {
  return service({
    url: "/bid/network-member/",
    method: "get",
    params: param,
  });
}

export function sendEmailInvitation(params) {
  return service({
    url: "/bid/entity-invitation/",
    method: "post",
    data: params,
  });
}

export async function membersAutocomplete(params) {
  try {
    const result = await axios.get(
      `${SISI_API_URL}/crm/members/autocomplete/`,
      { params }
    );
    return _.get(result, "data");
  } catch (error) {
    console.log("error");
    return null;
  }
}
