//==============================================LIST===========================================================
export const INVITATIONS_RECEIVE_LOADING_LIST =
  "INVITATIONS_RECEIVE_LOADING_LIST";
export const INVITATIONS_RECEIVE_FETCH_LIST_DATA =
  "INVITATIONS_RECEIVE_FETCH_LIST_DATA";
export const INVITATIONS_RECEIVE_IDLE_LIST = "INVITATIONS_RECEIVE_IDLE_LIST";
export const INVITATIONS_RECEIVE_VIEW_TYPE_CHANGE =
  "INVITATIONS_RECEIVE_VIEW_TYPE_CHANGE";
//=============================================FILTER==========================================================
export const INVITATIONS_RECEIVE_INITIALIZE_LIST_FILTER_FORM =
  "INVITATIONS_RECEIVE_INITIALIZE_LIST_FILTER_FORM";
export const INVITATIONS_RECEIVE_UPDATE_LIST_FILTER_FORM =
  "INVITATIONS_RECEIVE_UPDATE_LIST_FILTER_FORM";
export const INVITATIONS_RECEIVE_APPLY_LIST_FILTER =
  "INVITATIONS_RECEIVE_APPLY_LIST_FILTER";
export const INVITATIONS_RECEIVE_RESET_LIST_FILTER_FORM =
  "INVITATIONS_RECEIVE_RESET_LIST_FILTER_FORM";
//==============================================COLUMNS========================================================
export const INVITATIONS_RECEIVE_APPLY_LIST_COLUMNS_FORM =
  "INVITATIONS_RECEIVE_APPLY_LIST_COLUMNS_FORM";
//============================================VIEW INVITATION================================================
export const INVITATIONS_RECEIVE_HIDE_VIEW_MODAL =
  "INVITATIONS_RECEIVE_HIDE_VIEW_MODAL";
export const INVITATIONS_RECEIVE_SHOW_VIEW_MODAL =
  "INVITATIONS_RECEIVE_SHOW_VIEW_MODAL";
export const INVITATIONS_RECEIVE_OPEN_VIEW_MODAL =
  "INVITATIONS_RECEIVE_OPEN_VIEW_MODAL";
//=============================================ACCEPT ==========================================================
export const INVITATIONS_RECEIVE_SHOW_ACCEPT_INVITATION_MODAL =
  "INVITATIONS_RECEIVE_SHOW_ACCEPT_INVITATION_MODAL";
export const INVITATIONS_RECEIVE_HIDE_ACCEPT_INVITATION_MODAL =
  "INVITATIONS_RECEIVE_HIDE_ACCEPT_INVITATION_MODAL";
export const INVITATIONS_RECEIVE_OPEN_ACCEPT_INVITATION_MODAL =
  "INVITATIONS_RECEIVE_OPEN_ACCEPT_INVITATION_MODAL";
export const INVITATIONS_RECEIVE_BEGIN_INVITATIONS_STATUS =
  "INVITATIONS_RECEIVE_BEGIN_INVITATIONS_STATUS";
export const INVITATIONS_RECEIVE_END_INVITATIONS_STATUS =
  "INVITATIONS_RECEIVE_END_INVITATIONS_STATUS";
export const INVITATIONS_RECEIVE_MESSAGE_DETAIL =
  "INVITATIONS_RECEIVE_MESSAGE_DETAIL";
