import React from "react";
import PropTypes from "prop-types";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

import { DateTime } from "luxon";

const signatureStyles = StyleSheet.create({
  Signature: { width: 550, flexDirection: "row", fontSize: 10 },
  signatory: { width: 275 },
  sender: {
    width: 275,
    marginLeft: 20,
    justifyContent: "end",
    justifyItems: "end",
  },
  sign: { marginTop: 20, borderBottom: "1px solid black" },
  attribute: { marginTop: 5 },
  title: { fontFamily: "Helvetica-Bold", marginTop: 5 },
});
const Signature = (props) => {
  const { sender } = props;
  return (
    <View style={signatureStyles.Signature}>
      <View style={signatureStyles.signatory}>
        <Text style={signatureStyles.title}>Acepted and confirmed by: </Text>
        <Text style={signatureStyles.sign}></Text>
        <Text style={signatureStyles.attribute}>(Name)</Text>
        <Text style={signatureStyles.attribute}>(Date)</Text>
      </View>
      <View style={signatureStyles.sender}>
        <Text style={signatureStyles.title}>Yours faithfully: </Text>
        <Text style={signatureStyles.sign}></Text>
        <Text style={signatureStyles.attribute}>
          {sender.first_name} {sender.last_name}
        </Text>
        <Text style={signatureStyles.attribute}>
          {DateTime.now().toLocaleString(DateTime.DATE_HUGE)}
        </Text>
      </View>
    </View>
  );
};
Signature.propTypes = { sender: PropTypes.object.isRequired };
export default Signature;
