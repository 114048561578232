import {
  BIDREQUEST_SHOW_REPLACEABLE_TRF_MODAL,
  BIDREQUEST_HIDE_REPLACEABLE_TRF_MODAL,
  BIDREQUEST_SAVE_REPLACEABLE_TRF,
  BIDREQUEST_REVERT_REPLACEABLE_TRF,
  // ================ COMMON REPLACEABLE SERVICE ACTIONS ===============
  REPLACEABLE_SERVICE_SAVING,
  REPLACEABLE_SERVICE_SAVED,
} from "./types";

import { offerSave } from "./index";

import {
  initCustomTransfer,
  initCustomTrfFromData,
} from "@src/actions/Project/CustomTransfer";

import MessageFormatter from "@src/validators/MessageFormatter";
import validateTransfer from "@src/validators/Project/CustomTransfer";

import {
  getCustomSrvCommonPayload,
  getCustomSrvFormData,
} from "@src/tools/CustomServices";
import { saveReplaceableSrv } from "./common_replaceable_srv_actions";

import _ from "lodash";
import update from "immutability-helper";
import { Notification } from "rsuite";
import React from "react";

// ====================== REPLACEABLE TRANSFERS ======================

export const showReplaceableTrfModal = (destOrder, transfer_type) => async (
  dispatch,
  getState
) => {
  const transfer = _.get(
    getState().bidRequestOfferData,
    `replaceable_transfers.${destOrder}.${transfer_type}`,
    null
  );

  if (!transfer) {
    await dispatch(initCustomTransfer());
  } else {
    await dispatch(initCustomTrfFromData(transfer));
  }

  dispatch({
    type: BIDREQUEST_SHOW_REPLACEABLE_TRF_MODAL,
    destOrder,
    transfer_type,
  });
};

export const hideReplaceableTrfModal = () => {
  return { type: BIDREQUEST_HIDE_REPLACEABLE_TRF_MODAL };
};

export const saveReplaceableTrf = (destOrder, transfer_type) => async (
  dispatch,
  getState
) => {
  const state = getState();
  const offer = state.bidRequestOfferData;
  const trfData = state.customTransfer;

  const msgs = validateTransfer(trfData, transfer_type);
  if (msgs.length) {
    const desc = <MessageFormatter msgs={msgs} />;

    try {
      Notification.close("replaceable_transfer_notification");
    } finally {
      Notification.error({
        key: "replaceable_transfer_notification",
        title: "Transfer Validation Error",
        description: desc,
        duration: 10000,
      });
    }

    return;
  }

  dispatch({ type: REPLACEABLE_SERVICE_SAVING });

  const pData = _.cloneDeep(trfData);

  var customSrvPayload = getCustomSrvCommonPayload(offer.id, "TRF");

  const imgGetter = (path) => {
    return _.get(trfData, path, []).filter((i) => typeof i.file == "undefined");
  };

  const uploadImgGetter = (path) =>
    _.get(trfData, path, [])
      .filter((i) => typeof i.file !== "undefined")
      .map((i) => ({
        original_image: i.file,
        main_photo: i.main,
      }));

  customSrvPayload = {
    ...customSrvPayload,
    ...{
      service_data:
        transfer_type == "inbound_outbound"
          ? JSON.stringify(
              update(pData, {
                inbound: {
                  vehicle: {
                    images: { $set: imgGetter("inbound.vehicle.images") },
                  },
                },
                outbound: {
                  $apply: (out) =>
                    _.isEmpty(out)
                      ? out
                      : update(out, {
                          vehicle: {
                            images: {
                              $set: imgGetter("outbound.vehicle.images"),
                            },
                          },
                        }),
                },
              })
            )
          : JSON.stringify(
              update(pData, {
                [transfer_type]: {
                  vehicle: {
                    images: {
                      $set: imgGetter(`${transfer_type}.vehicle.images`),
                    },
                  },
                },
              })
            ),
      images: [
        ...uploadImgGetter("inbound.vehicle.images"),
        ...uploadImgGetter("outbound.vehicle.images"),
      ],
    },
  };

  const formDataPayload = getCustomSrvFormData(customSrvPayload);
  const service_data = await saveReplaceableSrv(formDataPayload, trfData.uid);

  if (service_data.data !== null) {
    service_data.data.uid = service_data.service_id;
    // Update the offer data.
    await dispatch({
      type: BIDREQUEST_SAVE_REPLACEABLE_TRF,
      destOrder,
      transfer_type,
      trfData: service_data.data,
    });
    await dispatch(offerSave()); //Save the updated offer to the backend.
    await dispatch(hideReplaceableTrfModal());
    dispatch({ type: REPLACEABLE_SERVICE_SAVED });
  }
};

export const revertReplaceableTrf = (destOrder, transfer_type) => {
  return { type: BIDREQUEST_REVERT_REPLACEABLE_TRF, destOrder, transfer_type };
};
