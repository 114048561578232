import _ from "lodash";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import React from "react";
import { dayAdhocTypes } from "../AddAddhocServiceModal";
import { getAccBoardDisplay } from "./components";
import {
  AccIcon,
  ActivitiesIcon,
  AdHocIcon,
  AddonIcon,
  CoachIcon,
  CoordinatorIcon,
  FerryIcon,
  FlightIcon,
  GeneralIcon,
  RestIcon,
  TrainIcon,
  TransferIcon,
} from "./icons";
import { formatDate, humanizeDuration } from "@src/tools/date_tools";
import { DateTime } from "luxon";
import { trainTicketClassMapping } from "../products/addhoc/train";
import {
  flightTitle,
  getArrival,
  getDeparture,
  getDuration,
  getItinerary,
  getLayover,
} from "./helpers";

const iconStyles = { width: 50, height: 50, color: "#ffffff" };
const cardPanel = {
  flexDirection: "column",
  fontSize: 10,
  color: "black",
  width: 550,
};

const cardContent = { flexDirection: "row", gap: 12, padding: 5 };

const cardImageBox = {
  width: "60px",
  maxHeight: "60px",
  alignItems: "center",
  padding: 10,
  backgroundColor: "#302A83",
  border: "1px solid #302A83",
  borderRadius: "5px",
};

const imageTitleCntr = {
  flexDirection: "column",
  alignItems: "center",
  gap: 2,
};
const imageTitle = { fontSize: 8 };
const cardTitle = { paddingBottom: 2, fontFamily: "Helvetica-Bold" };
const details = { flexDirection: "column", alignItems: "start", width: 470 };

const attribute = { flexDirection: "row", padding: 3 };

const spaceBtnInfo = { flexDirection: "row", justifyContent: "space-between" };

const divider = { borderBottom: "1px solid #000", paddingBottom: 5 };

const styles = StyleSheet.create({
  cardPanel,
  cardContent,
  cardImageBox,
  attribute,
  imageTitleCntr,
  imageTitle,
  cardTitle,
  details,
  spaceBtnInfo,
  divider,
});

export const AdhocService = ({ data, dt }) => {
  return (
    <View style={styles.cardPanel}>
      <View style={styles.cardContent}>
        <View style={styles.imageTitleCntr}>
          <View style={styles.cardImageBox}>
            <AdHocIcon
              width={iconStyles.width}
              height={iconStyles.height}
              color={iconStyles.color}
            />
          </View>
          <View>
            <Text style={styles.imageTitle}>Ad Hoc Service</Text>
          </View>
        </View>
        <View style={styles.details}>
          <View style={styles.attribute}>
            <Text style={styles.cardTitle}>
              {dayAdhocTypes[data.addhoc_service_type]}:{" "}
              {`${data?.description || "N/A"} - ${dt ? dt : ""}`}
            </Text>
          </View>
          <View style={styles.attribute}>
            <Text>Notes: </Text>
            <Text>{data?.notes || "N/A"}</Text>
          </View>
        </View>
      </View>
    </View>
  );
};
AdhocService.defaultProps = { data: {}, dt: "" };
AdhocService.propTypes = {
  data: PropTypes.object.isRequired,
  dt: PropTypes.string.isRequired,
};

export const AccService = ({ data, dt, destinations }) => {
  const { eventType, service } = data;
  const acc_srv_type = service?.accommodation_service_type ?? "ACC";

  if (!["checkIn", "checkOut"].includes(eventType)) return null;

  var rooms = (service?.detailed_rooms ?? []).filter((room) => room.selected);
  if (acc_srv_type !== "ACC") rooms = service?.rooms ?? [];

  if (!rooms.length)
    rooms = (service?.rooms ?? []).filter((room) => room.selected);

  const event = eventType === "checkIn" ? "Check In" : "Check Out";

  const dest = destinations.find(
    (dest) => dest.order.toString() === data.destOrder.toString()
  );

  return (
    <View style={styles.cardPanel}>
      <View style={styles.cardContent}>
        <View style={styles.imageTitleCntr}>
          <View style={styles.cardImageBox}>
            <AccIcon
              width={iconStyles.width}
              height={iconStyles.height}
              color={iconStyles.color}
            />
          </View>
          <View>
            <Text style={styles.imageTitle}>Accommodation</Text>
          </View>
        </View>
        <View style={styles.details}>
          <View style={styles.attribute}>
            <Text style={styles.cardTitle}>
              {service?.metadata?.name ?? ""} in {dest.name_en}: {event} - {dt}
            </Text>
          </View>
          <View style={styles.attribute}>
            <Text>Boards: </Text>
            <Text>{getAccBoardDisplay(rooms)}</Text>
          </View>
          <View style={styles.attribute}>
            <Text>Room: </Text>
            <Text>
              {[...new Set(rooms.map((room) => room.name))].join(", ")}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};
AccService.propTypes = {
  data: PropTypes.object.isRequired,
  dt: PropTypes.string.isRequired,
  destinations: PropTypes.array.isRequired,
};

export const RestaurantService = ({ data, dt }) => {
  return (
    <View style={styles.cardPanel}>
      <View style={styles.cardContent}>
        <View style={styles.imageTitleCntr}>
          <View style={styles.cardImageBox}>
            <RestIcon
              width={iconStyles.width}
              height={iconStyles.height}
              color={iconStyles.color}
            />
          </View>
          <View>
            <Text style={styles.imageTitle}>Restaurant</Text>
          </View>
        </View>
        <View style={styles.details}>
          <View style={styles.attribute}>
            <Text style={styles.cardTitle}>
              Restaurant: - {data.name} - {dt}
            </Text>
          </View>
          <View style={styles.attribute}>
            <Text>Address: </Text>
            <Text>{data.address}</Text>
          </View>
          <View style={styles.attribute}>
            <Text>Menu: </Text>
            <Text>{data?.menu?.[0]?.title}</Text>
          </View>
          <View style={styles.attribute}>
            <Text>Food Type: </Text>
            <Text>{data.food_type}</Text>
          </View>
        </View>
      </View>
    </View>
  );
};
RestaurantService.defaultProps = { data: {}, dt: "" };
RestaurantService.propTypes = {
  data: PropTypes.object.isRequired,
  dt: PropTypes.string.isRequired,
};

export const GeneralService = ({ data }) => {
  var description = data.service_data.name;

  return (
    <View style={styles.cardPanel}>
      <View style={styles.cardContent}>
        <View style={styles.imageTitleCntr}>
          <View style={styles.cardImageBox}>
            <GeneralIcon
              width={iconStyles.width}
              height={iconStyles.height}
              color={iconStyles.color}
            />
          </View>
          <View>
            <Text style={styles.imageTitle}>General Service</Text>
          </View>
        </View>
        <View style={styles.details}>
          <View style={styles.attribute}>
            <Text style={styles.cardTitle}>
              <Text>General Service: - {description}</Text>
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};
GeneralService.defaultProps = { data: {}, dt: "" };
GeneralService.propTypes = {
  data: PropTypes.object.isRequired,
  dt: PropTypes.string.isRequired,
};

export const ActivityService = ({ data }) => {
  const title = data?.service?.title ?? "";
  const description = data?.service?.description ?? "";
  const languages = (data?.service?.languages ?? [])
    .map((lang) => lang.name)
    .join(", ");
  const features = (data?.service?.features ?? [])
    .map((feat) => feat.label)
    .join(", ");

  return (
    <View style={styles.cardPanel}>
      <View style={styles.cardContent}>
        <View style={styles.imageTitleCntr}>
          <View style={styles.cardImageBox}>
            <ActivitiesIcon
              width={iconStyles.width}
              height={iconStyles.height}
              color={iconStyles.color}
            />
          </View>
          <View>
            <Text style={styles.imageTitle}>Activities</Text>
          </View>
        </View>
        <View style={styles.details}>
          <View style={styles.attribute}>
            <Text style={styles.cardTitle}>Activity Service: {title}</Text>
          </View>
          <View style={styles.attribute}>
            <Text>{description}</Text>
          </View>
          <View style={styles.spaceBtnInfo}>
            <View style={styles.attribute}>
              <Text>Languages: </Text>
              <Text>{languages}</Text>
            </View>
            <View style={styles.attribute}>
              <Text>Features: </Text>
              <Text>{features}</Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};
ActivityService.propTypes = { data: PropTypes.object.isRequired };

export const CoordinatorService = ({ data, dt, isodate }) => {
  const duration =
    data.daily_durations.find((dur) => dur.date === isodate)?.duration ?? 0;

  return (
    <View style={styles.cardPanel}>
      <View style={styles.cardContent}>
        <View style={styles.imageTitleCntr}>
          <View style={styles.cardImageBox}>
            <CoordinatorIcon
              width={iconStyles.width}
              height={iconStyles.height}
              color={iconStyles.color}
            />
          </View>
          <View>
            <Text style={styles.imageTitle}>Coordinator</Text>
          </View>
        </View>
        <View style={styles.details}>
          <View style={styles.attribute}>
            <Text style={styles.cardTitle}>
              Coordinator: - {data.specialty_display.join(", ")} - {dt} -
              Duration: {humanizeDuration(duration * 60 * 1000)}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};
CoordinatorService.defaultProps = { data: {}, dt: "", isodate: "" };
CoordinatorService.propTypes = {
  data: PropTypes.object.isRequired,
  dt: PropTypes.string.isRequired,
  isodate: PropTypes.string.isRequired,
};

export const TrainService = ({ data }) => {
  const service = data?.service?.service ?? {};

  return (
    <View style={styles.cardPanel}>
      <View style={styles.cardContent}>
        <View style={styles.imageTitleCntr}>
          <View style={styles.cardImageBox}>
            <TrainIcon
              width={iconStyles.width}
              height={iconStyles.height}
              color={iconStyles.color}
            />
          </View>
          <View>
            <Text style={styles.imageTitle}>Train</Text>
          </View>
        </View>
        <View style={styles.details}>
          <View style={styles.attribute}>
            <Text style={styles.cardTitle}>
              Train {_.startCase(data.eventType)}: {service.origin.name_en} to{" "}
              {service.destination.name_en}
            </Text>
          </View>
          <View style={styles.spaceBtnInfo}>
            <View style={styles.attribute}>
              <Text>Departure: </Text>
              <Text>
                {DateTime.fromISO(service.departure)
                  .setLocale("en-US")
                  .toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)}
              </Text>
            </View>
            <View style={styles.attribute}>
              <Text>Arrival: </Text>
              <Text>
                {DateTime.fromISO(service.arrival)
                  .setLocale("en-US")
                  .toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)}
              </Text>
            </View>
          </View>
          <View style={styles.spaceBtnInfo}>
            <View style={styles.attribute}>
              <Text>Origin Station: </Text>
              <Text>{service.origin_station}</Text>
            </View>
            <View style={styles.attribute}>
              <Text>Destination Station: </Text>
              <Text>{service.destination_station}</Text>
            </View>
          </View>
          <View style={styles.attribute}>
            <Text>Ticket Class: </Text>
            <Text>
              {trainTicketClassMapping?.[service.ticket_class] || "N/A"}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};
TrainService.propTypes = { data: PropTypes.object.isRequired };

export const ApiTrainService = ({ data }) => {
  const service = data?.service ?? {};
  const origin = service?.origin?.label ?? "";
  const destination = service?.destination?.label ?? "";
  const departure = service?.departure ?? "";
  const arrival = service?.arrival ?? "";
  const offers = service?.offers ?? [];
  const offerSelected = offers.find((offer) => offer.selected);
  const ticketType =
    (offerSelected?.comfort_category ?? "") +
    " - " +
    (offerSelected?.flexibility ?? "");

  return (
    <View style={styles.cardPanel}>
      <View style={styles.cardContent}>
        <View style={styles.imageTitleCntr}>
          <View style={styles.cardImageBox}>
            <TrainIcon
              width={iconStyles.width}
              height={iconStyles.height}
              color={iconStyles.color}
            />
          </View>
          <View>
            <Text style={styles.imageTitle}>Train</Text>
          </View>
        </View>
        <View style={styles.details}>
          <View style={styles.spaceBtnInfo}>
            <View style={styles.attribute}>
              <Text>Departure: </Text>
              <Text>
                {DateTime.fromISO(departure)
                  .setLocale("en-US")
                  .toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)}
              </Text>
            </View>
            <View style={styles.attribute}>
              <Text>Arrival: </Text>
              <Text>
                {DateTime.fromISO(arrival)
                  .setLocale("en-US")
                  .toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)}
              </Text>
            </View>
          </View>
          <View style={styles.spaceBtnInfo}>
            <View style={styles.attribute}>
              <Text>Origin Station: </Text>
              <Text>{origin}</Text>
            </View>
            <View style={styles.attribute}>
              <Text>Destination Station: </Text>
              <Text>{destination}</Text>
            </View>
          </View>
          <View style={styles.attribute}>
            <Text>Ticket Class: </Text>
            <Text>{ticketType}</Text>
          </View>
        </View>
      </View>
    </View>
  );
};
ApiTrainService.propTypes = { data: PropTypes.object.isRequired };

export const FlService = ({ data }) => {
  const opt = (data?.service?.options ?? []).filter(
    (option) => option.selected
  );

  if (!opt) return null;

  const airlines = [
    ...new Set(
      _.flattenDeep(
        opt.map((option) =>
          option.segments.map((segment) => {
            return `${segment.operating_airline.name} (${segment.operating_airline.iata})`;
          })
        )
      )
    ),
  ].join(", ");

  const flight_numbers = [
    ...new Set(
      _.flattenDeep(
        opt.map((option) =>
          option.segments.map((segment) => segment.flight_number)
        )
      )
    ),
  ];

  return (
    <View style={styles.cardPanel}>
      <View style={styles.cardContent}>
        <View style={styles.imageTitleCntr}>
          <View style={styles.cardImageBox}>
            <FlightIcon
              width={iconStyles.width}
              height={iconStyles.height}
              color={iconStyles.color}
            />
          </View>
          <View>
            <Text style={styles.imageTitle}>Flight</Text>
          </View>
        </View>
        <View style={styles.details}>
          <View style={styles.attribute}>
            <Text style={styles.cardTitle}>
              {_.startCase(data.eventType)}: {flightTitle(data.service)}
            </Text>
          </View>
          <View style={styles.spaceBtnInfo}>
            <View style={styles.attribute}>
              <Text>Itinerary: </Text>
              <Text>{getItinerary(data.service)}</Text>
            </View>
            <View style={styles.attribute}>
              <Text>Departure: </Text>
              <Text>{getDeparture(data.service)}</Text>
            </View>
          </View>
          <View style={styles.spaceBtnInfo}>
            <View style={styles.attribute}>
              <Text>Duration: </Text>
              <Text> {getDuration(data.service)}</Text>
            </View>
            <View style={styles.attribute}>
              <Text>Flight Number: </Text>
              <Text>{flight_numbers}</Text>
            </View>
          </View>
          <View style={styles.spaceBtnInfo}>
            <View style={styles.attribute}>
              <Text>Airline: </Text>
              <Text>{airlines}</Text>
            </View>
            <View style={styles.attribute}>
              <Text>Arrival: </Text>
              <Text>{getArrival(data.service)}</Text>
            </View>
          </View>
          <View style={styles.attribute}>
            <Text>Layover: </Text>
            <Text>{getLayover(data.service)}</Text>
          </View>
        </View>
      </View>
    </View>
  );
};
FlService.propTypes = { data: PropTypes.object.isRequired };

export const TrfService = ({ data }) => {
  const detail = _.isEmpty(data?.service?.inbound ?? {})
    ? data.service.outbound
    : data.service.inbound;
  const origin = data?.service?.origin?.name ?? {};
  const destination = data?.service?.destination?.name ?? {};
  const trfType = data?.eventType ?? "";

  return (
    <View style={styles.cardPanel}>
      <View style={styles.cardContent}>
        <View style={styles.imageTitleCntr}>
          <View style={styles.cardImageBox}>
            <TransferIcon
              width={iconStyles.width}
              height={iconStyles.height}
              color={iconStyles.color}
            />
          </View>
          <View>
            <Text style={styles.imageTitle}>Transfers</Text>
          </View>
        </View>
        <View style={styles.details}>
          <View style={styles.attribute}>
            <Text style={styles.cardTitle}>
              {trfType === "inbound"
                ? "Transfer: Transportation Hub to Hotel"
                : "Transfer: Hotel to Transportation Hub"}
            </Text>
          </View>
          <View style={styles.attribute}>
            <Text>Route: </Text>
            <Text>
              {origin} - {destination}
            </Text>
          </View>
          <View style={styles.spaceBtnInfo}>
            <View style={styles.attribute}>
              <Text>Pickup: </Text>
              <Text>
                {formatDate(_.get(detail, "start"), {
                  format: "DD/MM/YYYY HH:mm",
                })}
              </Text>
            </View>
            <View style={styles.attribute}>
              <Text>Vehicle: </Text>
              <Text>
                {detail?.vehicle?.brand ||
                  (detail?.vehicle?.vehicle_type ?? "N/A")}
              </Text>
            </View>
          </View>
          <View style={styles.spaceBtnInfo}>
            <View style={styles.attribute}>
              <Text>Distance: </Text>
              <Text>{detail?.distance ?? "0"}Km</Text>
            </View>
            <View style={styles.attribute}>
              <Text>Duration: </Text>
              <Text>{humanizeDuration(detail?.duration, 0)}</Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};
TrfService.propTypes = { data: PropTypes.object.isRequired };

export const AddonsService = ({ data }) => {
  const service = data?.service ?? {};
  const title = service?.title ?? "";
  const items = service?.items ?? [];

  return (
    <View style={styles.cardPanel}>
      <View style={styles.cardContent}>
        <View style={styles.imageTitleCntr}>
          <View style={styles.cardImageBox}>
            <AddonIcon
              width={iconStyles.width}
              height={iconStyles.height}
              color={iconStyles.color}
            />
          </View>
          <View>
            <Text style={styles.imageTitle}>Add ons</Text>
          </View>
        </View>
        <View style={styles.details}>
          <View style={styles.attribute}>
            <Text style={styles.cardTitle}>{title}</Text>
          </View>
          {items.map((item, idx) => (
            <React.Fragment key={idx}>
              <View style={styles.spaceBtnInfo}>
                <View style={styles.attribute}>
                  <Text>{item.data.name_en ? item.data.name_en : "N/A"}</Text>
                </View>
                <View style={styles.attribute}>
                  <Text>Type: </Text>
                  <Text>{_.startCase(item.type ? item.type : "N/A")}</Text>
                </View>
              </View>
              <View style={styles.spaceBtnInfo}>
                <View style={styles.attribute}>
                  <Text>Arrival Time: </Text>
                  <Text>{item.arrival_time ? item.arrival_time : "N/A"}</Text>
                </View>
                <View style={styles.attribute}>
                  <Text>Duration: </Text>
                  <Text>
                    {humanizeDuration(
                      item.departure_route.duration
                        ? item.departure_route.duration
                        : "N/A"
                    )}
                  </Text>
                </View>
              </View>
              <View style={idx !== items.length - 1 && styles.divider}></View>
            </React.Fragment>
          ))}
        </View>
      </View>
    </View>
  );
};
AddonsService.propTypes = { data: PropTypes.object.isRequired };

export const CoachService = ({ data }) => {
  const service = data?.service?.service ?? [];
  const arrival = service?.arrival ?? "";
  const departure = service?.departure ?? "";
  const dropoffPoint = service?.dropoff_point ?? "";
  const pickupPoint = service?.pickup_point ?? "";
  const coachType = service?.coach_type ?? "";

  return (
    <View style={styles.cardPanel}>
      <View style={styles.cardContent}>
        <View style={styles.imageTitleCntr}>
          <View style={styles.cardImageBox}>
            <CoachIcon
              width={iconStyles.width}
              height={iconStyles.height}
              color={iconStyles.color}
            />
          </View>
          <View>
            <Text style={styles.imageTitle}>Coach</Text>
          </View>
        </View>
        <View style={styles.details}>
          <View style={styles.attribute}>
            <Text style={styles.cardTitle}>
              {`Coach from ${pickupPoint ? pickupPoint : "N/A"} to ${
                dropoffPoint ? dropoffPoint : "N/A"
              }`}
            </Text>
          </View>
          <View style={styles.spaceBtnInfo}>
            <View style={styles.attribute}>
              <Text>Departure: </Text>
              <Text>
                {departure
                  ? DateTime.fromISO(departure)
                      .setLocale("en-US")
                      .toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)
                  : "N/A"}
              </Text>
            </View>
            <View style={styles.attribute}>
              <Text>Arrival: </Text>
              <Text>
                {arrival
                  ? DateTime.fromISO(arrival)
                      .setLocale("en-US")
                      .toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)
                  : "N/A"}
              </Text>
            </View>
          </View>
          <View style={styles.spaceBtnInfo}>
            <View style={styles.attribute}>
              <Text>Pick up point: </Text>
              <Text>{pickupPoint ? pickupPoint : "N/A"}</Text>
            </View>
            <View style={styles.attribute}>
              <Text>Drop off point: </Text>
              <Text>{dropoffPoint ? dropoffPoint : "N/A"}</Text>
            </View>
          </View>
          <View style={styles.attribute}>
            <Text>Type: </Text>
            <Text>{coachType ? _.startCase(coachType) : "N/A"}</Text>
          </View>
        </View>
      </View>
    </View>
  );
};
CoachService.propTypes = { data: PropTypes.object.isRequired };

export const FerryService = ({ data }) => {
  const service = data?.service?.service ?? [];
  const departure = service?.departure ?? "";
  const arrival = service?.arrival ?? "";
  const originName = service?.origin?.name_en ?? "";
  const destinationName = service?.destination?.name_en ?? "";
  const originPort = service?.origin_port ?? "";
  const destinationPort = service?.destination_port ?? "";
  const vessel = service?.vessel_name ?? "";

  return (
    <View style={styles.cardPanel}>
      <View style={styles.cardContent}>
        <View style={styles.imageTitleCntr}>
          <View style={styles.cardImageBox}>
            <FerryIcon
              width={iconStyles.width}
              height={iconStyles.height}
              color={iconStyles.color}
            />
          </View>
          <View>
            <Text style={styles.imageTitle}>Ferry</Text>
          </View>
        </View>
        <View style={styles.details}>
          <View style={styles.attribute}>
            <Text style={styles.cardTitle}>
              Ferry from {originName ? originName : "N/A"} to{" "}
              {destinationName ? destinationName : "N/A"}
            </Text>
          </View>
          <View style={styles.spaceBtnInfo}>
            <View style={styles.attribute}>
              <Text>Departure: </Text>
              <Text>
                {departure
                  ? DateTime.fromISO(departure)
                      .setLocale("en-US")
                      .toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)
                  : "N/A"}
              </Text>
            </View>
            <View style={styles.attribute}>
              <Text>Arrival: </Text>
              <Text>
                {arrival
                  ? DateTime.fromISO(arrival)
                      .setLocale("en-US")
                      .toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)
                  : "N/A"}
              </Text>
            </View>
          </View>
          <View style={styles.spaceBtnInfo}>
            <View style={styles.attribute}>
              <Text>Origin Port: </Text>
              <Text>{originPort ? originPort : "N/A"} </Text>
            </View>
            <View style={styles.attribute}>
              <Text>Destination Port: </Text>
              <Text>{destinationPort ? destinationPort : "N/A"} </Text>
            </View>
          </View>
          <View style={styles.attribute}>
            <Text>Vessel: </Text>
            <Text>{vessel ? vessel : "N/A"} </Text>
          </View>
        </View>
      </View>
    </View>
  );
};
FerryService.propTypes = { data: PropTypes.object.isRequired };

export const ApiFerryService = ({ data }) => {
  const service = data?.service ?? {};
  const legs = service?.legs ?? [];
  const leg = legs?.[0] ?? {};
  const departure = leg?.departure_datetime ?? "";
  const arrival = leg?.arrival_datetime ?? "";
  const originCode = leg?.departure_port_code ?? "N/A";
  const destinationCode = leg?.arrival_port_code ?? "N/A";
  const originPort = leg?.departure_port_name ?? "N/A";
  const destinationPort = leg?.arrival_port_name ?? "N/A";
  const vessel = leg?.vessel_data?.name ?? "N/A";

  return (
    <View style={styles.cardPanel}>
      <View style={styles.cardContent}>
        <View style={styles.imageTitleCntr}>
          <View style={styles.cardImageBox}>
            <FerryIcon
              width={iconStyles.width}
              height={iconStyles.height}
              color={iconStyles.color}
            />
          </View>
          <View>
            <Text style={styles.imageTitle}>Ferry</Text>
          </View>
        </View>
        <View style={styles.details}>
          <View style={styles.attribute}>
            <Text style={styles.cardTitle}>
              Ferry from {originCode} to {destinationCode}
            </Text>
          </View>
          <View style={styles.spaceBtnInfo}>
            <View style={styles.attribute}>
              <Text>Departure: </Text>
              <Text>
                {departure
                  ? DateTime.fromISO(departure)
                      .setLocale("en-US")
                      .toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)
                  : "N/A"}
              </Text>
            </View>
            <View style={styles.attribute}>
              <Text>Arrival: </Text>
              <Text>
                {arrival
                  ? DateTime.fromISO(arrival)
                      .setLocale("en-US")
                      .toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)
                  : "N/A"}
              </Text>
            </View>
          </View>
          <View style={styles.spaceBtnInfo}>
            <View style={styles.attribute}>
              <Text>Origin Port: </Text>
              <Text>{originPort ? originPort : "N/A"} </Text>
            </View>
            <View style={styles.attribute}>
              <Text>Destination Port: </Text>
              <Text>{destinationPort ? destinationPort : "N/A"} </Text>
            </View>
          </View>
          <View style={styles.attribute}>
            <Text>Vessel: </Text>
            <Text>{vessel ? vessel : "N/A"} </Text>
          </View>
        </View>
      </View>
    </View>
  );
};
ApiFerryService.propTypes = { data: PropTypes.object.isRequired };
