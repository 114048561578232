import {
  //============================LIST============================
  INVITATIONS_SEND_LOADING_LIST,
  INVITATIONS_SEND_FETCH_LIST_DATA,
  INVITATIONS_SEND_IDLE_LIST,
  INVITATIONS_SEND_APPLY_LIST_COLUMNS_FORM,
  //============================FILTER==========================
  INVITATIONS_SEND_INITIALIZE_LIST_FILTER_FORM,
  INVITATIONS_SEND_UPDATE_LIST_FILTER_FORM,
  INVITATIONS_SEND_APPLY_LIST_FILTER,
  INVITATIONS_SEND_RESET_LIST_FILTER_FORM,
  //============================COLUMNS==========================
  INVITATIONS_SEND_VIEW_TYPE_CHANGE,
} from "@src/actions/Network/InvitationsSend/types";

import {
  initialMembersFilterForm,
  initialMembersColumnsSetUp,
} from "@src/forms/Network/Members";

import { formProcessor, entitiesListSelector } from "@src/reducers/tools";

export const invitationsSendListFilter = (
  state = initialMembersFilterForm,
  action
) => {
  switch (action.type) {
    case INVITATIONS_SEND_INITIALIZE_LIST_FILTER_FORM:
      return action.data;
    case INVITATIONS_SEND_UPDATE_LIST_FILTER_FORM:
      return formProcessor(
        state,
        action.data.type,
        action.data.name,
        action.data.value
      );
    case INVITATIONS_SEND_APPLY_LIST_FILTER:
      return action.data;
    case INVITATIONS_SEND_RESET_LIST_FILTER_FORM:
      return action.data;
    default:
      return state;
  }
};

export const initialList = {
  count: 0,
  next: null,
  previous: null,
  results: [],
  page: 1,
  limit: 6,
  displayLength: 100,
  loading: false,
};

export const fetchInvitationsSendListData = (state = initialList, action) => {
  switch (action.type) {
    case INVITATIONS_SEND_FETCH_LIST_DATA:
      return { ...state, ...action.data };
    case INVITATIONS_SEND_LOADING_LIST:
      return { ...state, loading: true };
    case INVITATIONS_SEND_IDLE_LIST:
      return { ...state, loading: false };
    case INVITATIONS_SEND_RESET_LIST_FILTER_FORM:
      return { ...state, page: 1 };
    default:
      return state;
  }
};

export const initialChangeInvitationsSendViewModal = false;
export const changeInvitationsSendViewModal = (
  state = initialChangeInvitationsSendViewModal,
  action
) => {
  switch (action.type) {
    case INVITATIONS_SEND_VIEW_TYPE_CHANGE:
      return action.data;
    default:
      return state;
  }
};

export const invitationsSendListColumns = (
  state = entitiesListSelector(initialMembersColumnsSetUp),
  action
) => {
  switch (action.type) {
    case INVITATIONS_SEND_APPLY_LIST_COLUMNS_FORM:
      return entitiesListSelector(action.data);
    default:
      return state;
  }
};
