import _ from "lodash";
import { SISI_API_URL } from "@src/api";
import { getAuthHeaders } from "@src/api/request";
import axios from "axios";
import { toast } from "react-toastify";

export async function postReservationFeedback(payload, onError) {
  try {
    await axios.post(`${SISI_API_URL}/reservations/feedback`, payload);
    return true;
  } catch (error) {
    if (typeof onError === "function") {
      onError(error);
    }
    return false;
  }
}

export async function getReservationFeedback({
  reservation_reference,
  srv_type,
}) {
  const auth_headers = getAuthHeaders();

  try {
    const result = await axios.get(
      `${SISI_API_URL}/reservations/feedback/${reservation_reference}/`,
      { params: { srv_type }, headers: { ...auth_headers } }
    );
    return _.get(result, "data.results", []);
  } catch (error) {
    const status = _.get(error, "response.status");
    if (status !== 404) {
      toast.error(
        "Reservation feedback fetch failed. We are sorry for the inconvenience.",
        { duration: 5000 }
      );
    }
    return { status };
  }
}

export async function updateResFeedback({
  reservation_reference,
  reservation_type,
  status,
}) {
  const auth_headers = getAuthHeaders();

  try {
    const result = await axios.patch(
      `${SISI_API_URL}/reservations/feedback/${reservation_reference}/`,
      { reservation_type, status },
      { headers: { ...auth_headers } }
    );
    return result;
  } catch (error) {
    const status = _.get(error, "response.status");
    if (status !== 404) {
      toast.error(
        "Reservation feedback update failed. We are sorry for the inconvenience.",
        { duration: 5000 }
      );
    }
    return null;
  }
}
