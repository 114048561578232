import { useDispatch, useSelector } from "react-redux";
import React, { useCallback } from "react";

import { changeStopSales } from "@src/actions/Operation/CustomServices/AddOn";

import { createUseStyles } from "react-jss";
import { tableHeadStyles } from "./Highlights";
import { cardStyles, variables } from "@src/jsssetup";
import { Formik } from "formik";
import _ from "lodash";
import {
  NormalInputField,
  NormalRangePicker,
  NormalSelectField,
} from "../../../../../components/forms";
import { Button } from "rsuite";

const styles = createUseStyles({
  table: { width: "100%" },
  tableHeader: { ...tableHeadStyles },
  tableCell: {
    padding: `calc(${variables.normal_gap} / 2)`,
    textAlign: "center",
  },
  col2Cell: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: `calc(${variables.normal_gap} / 2)`,
  },
  StopSales: cardStyles.card,
  body: cardStyles.body,
  header: {
    ...cardStyles.header,
    display: "grid",
    gridTemplateColumns: "1fr max-content",
    gridGap: variables.normal_gap,
  },
});
const StopSales = () => {
  const classes = styles();

  const { stop_sales } = useSelector((state) => state.customServiceAddOn);

  const dispatch = useDispatch();
  const onStopSaleChange = useCallback(
    (stop_sales) => dispatch(changeStopSales({ stop_sales })),
    [dispatch]
  );

  const stopTmp = {
    name: "",
    distribution_from: "",
    distribution_to: "",
    operation_from: "",
    operation_to: "",
    status: "",
    mode: "edit",
  };

  return (
    <Formik
      initialValues={{ stop_sales }}
      validateOnMount={false}
      validateOnBlur={false}
      onSubmit={(values) => onStopSaleChange(values)}>
      {({ values, setFieldValue }) => (
        <div className={classes.StopSales}>
          <div className={classes.header}>
            <h5>Stop Sales</h5>
            <Button
              color="green"
              size="sm"
              onClick={() =>
                setFieldValue("stop_sales", [...values.stop_sales, stopTmp])
              }>
              <strong>Add Stop Sales</strong>
            </Button>
          </div>
          <div className={classes.body}>
            <table className={classes.table}>
              <thead className={classes.tableHeader}>
                <tr>
                  <th>Name</th>
                  <th>Distribution From - To</th>
                  <th>Operation From - To</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {_.get(values, "stop_sales", []).map((datum, idx) => (
                  <tr key={idx}>
                    <td className={classes.tableCell}>
                      {datum.mode === "edit" ? (
                        <NormalInputField name={`stop_sales.${idx}.name`} />
                      ) : (
                        <span>datum.name</span>
                      )}
                    </td>
                    <td className={classes.tableCell}>
                      {datum.mode === "edit" ? (
                        <NormalRangePicker
                          from_key={`${idx}.distribution_from`}
                          to_key={`${idx}.distribution_to`}
                        />
                      ) : (
                        <span>{`${datum.distribution_from} ${datum.distribution_to}`}</span>
                      )}
                    </td>
                    <td className={classes.tableCell}>
                      {datum.mode === "edit" ? (
                        <NormalRangePicker
                          from_key={`${idx}.operation_from`}
                          to_key={`${idx}.operation_to`}
                        />
                      ) : (
                        <span>{`${datum.operation_from} ${datum.operation_to}`}</span>
                      )}
                    </td>
                    <td className={classes.tableCell}>
                      {datum.mode === "edit" ? (
                        <NormalSelectField
                          name={`${idx}.status`}
                          withError={false}
                          options={[
                            ["AC", "Active"],
                            ["IN", "Inactive"],
                          ]}
                        />
                      ) : (
                        <span>
                          {datum.status === "AC" ? "Active" : "Inactive"}
                        </span>
                      )}
                    </td>
                    <td className={classes.cell}>
                      <span className={classes.col2Cell}>
                        <Button
                          color="red"
                          size="xs"
                          onClick={() => {
                            const tmp = _.cloneDeep(values.stop_sales);
                            tmp.splice(idx, 1);
                            setFieldValue("stop_sales", tmp);
                          }}>
                          <strong>Remove</strong>
                        </Button>
                        {datum.mode === "edit" ? (
                          <Button appearance="primary" size="xs" type="submit">
                            <strong>Add</strong>
                          </Button>
                        ) : (
                          <Button
                            appearance="primary"
                            size="xs"
                            onClick={() => {}}>
                            <strong>Edit</strong>
                          </Button>
                        )}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </Formik>
  );
};
export default StopSales;
