import { API_TRAINS_B2C_LIST_GENERIC_CHANGE } from "@src/actions/Operation/Reservations/SingleServices/APITrains/types";
import { commonList } from "@src/reducers/common";

const APITrainsListReducerInitial = {
  ...commonList,
  displayLength: 100,
  filters: {
    reference: "",
    external_reference: "",
    supplier_reference: "",
    outbound_origin__icontains: "",
    outbound_destination__icontains: "",
    outbound_origin_station__icontains: "",
    outbound_destination_station__icontains: "",
    inbound_origin__icontains: "",
    inbound_destination__icontains: "",
    inbound_origin_station__icontains: "",
    inbound_destination_station__icontains: "",
    source_entity_autocomplete: "",
    source_entity: "",
    booking_mode: "",
    created__gte: "",
    created__lte: "",
    creator: "",
    creator_email: "",
    ticket_classes__in: "",
    status: "",
    market_type: "B2C",
    page: 1,
    limit: 20,
    ordering: "",
    tags: "",
  },
};
export const APITrainsB2CListReducer = (
  state = APITrainsListReducerInitial,
  action
) => {
  switch (action.type) {
    case API_TRAINS_B2C_LIST_GENERIC_CHANGE: {
      const { filters } = action;
      return { ...state, filters: { ...state.filters, ...filters } };
    }
    default:
      return state;
  }
};
