export const SISIBID_INIT = "SISIBID_INIT";
export const SISIBID_GOTO_NEXT_STEP = "SISIBID_GOTO_NEXT_STEP";
export const SISIBID_GOTO_PREV_STEP = "SISIBID_GOTO_PREV_STEP";
export const SISIBID_TRIP_SERVICES = "SISIBID_TRIP_SERVICES";
export const SISIBID_NEW_BID_TOGGLE_TARGET_PRICE_MODE =
  "SISIBID_NEW_BID_TOGGLE_TARGET_PRICE_MODE";
export const SISIBID_BID_INSERT_LIST = "SISIBID_BID_INSERT_LIST";
export const SISIBID_BID_MODIFY_LIST_TITLE = "SISIBID_BID_MODIFY_LIST_TITLE";
export const SISIBID_BID_MODIFY_LIST_STATUS = "SISIBID_BID_MODIFY_LIST_STATUS";
export const SISIBID_CHANGE_CURRENT_BID_UID = "SISIBID_CHANGE_CURRENT_BID_UID";
export const SISIBID_BID_UPDATE_BIDDING_STATUS =
  "SISIBID_BID_UPDATE_BIDDING_STATUS";
export const SISIBID_NEW_BID_TOGGLE_SUPPLY_VIEW =
  "SISIBID_NEW_BID_TOGGLE_SUPPLY_VIEW";
export const SISIBID_BID_MODIFY_AREA_VALUE = "SISIBID_BID_MODIFY_AREA_VALUE";
export const SISIBID_BID_MODIFY_CITY = "SISIBID_BID_MODIFY_CITY";
export const SISIBID_NEW_BID_UPDATE_RESPONSE_TIME =
  "SISIBID_NEW_BID_UPDATE_RESPONSE_TIME";
export const SISIBID_BID_SUPPLY_LIST_UPDATE_CHECKED_STATUS =
  "SISIBID_BID_SUPPLY_LIST_UPDATE_CHECKED_STATUS";
export const SISIBID_NEW_BID_UPDATE_TABLE_CHECKED =
  "SISIBID_NEW_BID_UPDATE_TABLE_CHECKED";
export const SISIBID_BID_MODIFY_LIST_NEED_TIME =
  "SISIBID_BID_MODIFY_LIST_NEED_TIME";
export const SISIBID_NEW_BID_UPDATE_INPUT_TYPE =
  "SISIBID_NEW_BID_UPDATE_INPUT_TYPE";
export const SISIBID_BID_DROP_FROM_LIST = "SISIBID_BID_DROP_FROM_LIST";

export const SISIBID_BID_UPDATE_SHOW_PRICE_TYPE =
  "SISIBID_BID_UPDATE_SHOW_PRICE_TYPE";
export const SISIBID_BID_UPDATE_NEED_TIME = "SISIBID_BID_UPDATE_NEED_TIME";
export const SISIBID_BID_UPDATE_MESSAGE = "SISIBID_BID_UPDATE_MESSAGE";
export const SISIBID_NEW_BID_FETCH_AFFILIATES =
  "SISIBID_NEW_BID_FETCH_AFFILIATES";
export const SISIBID_NEW_BID_BEGIN_FETCH_AFFILIATES =
  "SISIBID_NEW_BID_BEGIN_FETCH_AFFILIATES";
export const SISIBID_NEW_BID_END_FETCH_AFFILIATES =
  "SISIBID_NEW_BID_END_FETCH_AFFILIATES";
export const SISIBID_BID_REVERT_SERVICE_PRICING =
  "SISIBID_BID_REVERT_SERVICE_PRICING";
export const SISIBID_BID_SET_BIDDING_LIST = "SISIBID_BID_SET_BIDDING_LIST";
export const SISIBID_BID_UPDATE_FLIGHT_INFORMATION =
  "SISIBID_BID_UPDATE_FLIGHT_INFORMATION";
export const SISIBID_BID_UPDATE_FLIGHT_STOPS =
  "SISIBID_BID_UPDATE_FLIGHT_STOPS";
export const SISIBID_BID_UPDATE_FLIGHT_TYPE = "SISIBID_BID_UPDATE_FLIGHT_TYPE";
export const SISIBID_BID_UPDATE_FLIGHT_START_AIRPORT =
  "SISIBID_BID_UPDATE_FLIGHT_START_AIRPORT";
export const SISIBID_BID_UPDATE_FLIGHT_SEGMENT =
  "SISIBID_BID_UPDATE_FLIGHT_SEGMENT";
export const SISIBID_UPDATE_TRANSPORTATION_TYPE =
  "SISIBID_UPDATE_TRANSPORTATION_TYPE";
export const SISIBID_BID_REVERT_FLIGHT = "SISIBID_BID_REVERT_FLIGHT";
export const FETCHED_BIDDING_FACILITY = "FETCHED_BIDDING_FACILITY";
export const SISIBID_BID_REVERT_FLIGHT_SEGMENTS =
  "SISIBID_BID_REVERT_FLIGHT_SEGMENTS";
export const SISIBID_BID_UPDATE_HOTEL_INFORMATION =
  "SISIBID_BID_UPDATE_HOTEL_INFORMATION";

// ========================== INSTANCE DATA ==========================
export const SISIBID_FETCHING_TRIPPLAN_DATA = "SISIBID_FETCHING_TRIPPLAN_DATA";
export const SISIBID_FETCHED_TRIPPLAN_DATA = "SISIBID_FETCHED_TRIPPLAN_DATA";
export const SISIBID_NEW_BID_FETCH_TRIP_PLAN_DATA =
  "SISIBID_NEW_BID_FETCH_TRIP_PLAN_DATA";
export const SISIBID_NEW_BID_RESET_TRIP_PLAN_DATA =
  "SISIBID_NEW_BID_RESET_TRIP_PLAN_DATA";
export const SISIBID_BID_SERVICE_PRICING_CHANGE =
  "SISIBID_BID_SERVICE_PRICING_CHANGE";
// ===================== SERVICE FILTERS ====================
export const SISIBID_SERVICE_FILTERS_CHANGE = "SISIBID_SERVICE_FILTERS_CHANGE";
export const SISIBID_SERVICE_FILTERS_RESET = "SISIBID_SERVICE_FILTERS_RESET";
// ======================== REQUIRED SERVICES ========================
export const SISIBID_REQUIRED_SERVICES_CHANGE =
  "SISIBID_REQUIRED_SERVICES_CHANGE";
export const SISIBID_REPLACEABLE_SERVICES_CHANGE =
  "SISIBID_REPLACEABLE_SERVICES_CHANGE";
export const SISIBID_REFUNDABLE_SERVICES_CHANGE =
  "SISIBID_REFUNDABLE_SERVICES_CHANGE";
export const SISIBID_REPLACEABILITY_TYPE_CHANGE =
  "SISIBID_REPLACEABILITY_TYPE_CHANGE";
// ========================= SERVICE DETAILS =========================
export const SISIBID_VIEW_FLIGHT_DETAILS = "SISIBID_VIEW_FLIGHT_DETAILS";
export const SISIBID_HIDE_FLIGHT_DETAILS = "SISIBID_HIDE_FLIGHT_DETAILS";
export const SISIBID_VIEW_ACC_DETAILS = "SISIBID_VIEW_ACC_DETAILS";
export const SISIBID_HIDE_ACC_DETAILS = "SISIBID_HIDE_ACC_DETAILS";
export const SISIBID_VIEW_TRF_DETAILS = "SISIBID_VIEW_TRF_DETAILS";
export const SISIBID_HIDE_TRF_DETAILS = "SISIBID_HIDE_TRF_DETAILS";
// ========================= SERVICE COMMENTS ========================
export const SISIBID_ADD_SERVICE_COMMENT = "SISIBID_ADD_SERVICE_COMMENT";
export const SISIBID_REMOVE_SERVICE_COMMENT = "SISIBID_REMOVE_SERVICE_COMMENT";
// ========================= BIDDERS FILTERS =========================
export const SISIBID_INITIALIZE_BIDDERS_FILTERS =
  "SISIBID_INITIALIZE_BIDDERS_FILTERS";
export const SISIBID_CHANGE_BIDDERS_FILTERS = "SISIBID_CHANGE_BIDDERS_FILTERS";
// ======================= STEP TWO BIDDERS LIST ======================
export const SISIBID_SELECT_BIDDER = "SISIBID_SELECT_BIDDER";
export const SISIBID_SELECT_ALL_BIDDERS = "SISIBID_SELECT_ALL_BIDDERS";
export const SISIBID_UNSELECT_ALL_BIDDERS = "SISIBID_UNSELECT_ALL_BIDDERS";
// =========================== SAVE ACTIONS ==========================
export const SISIBID_REQUEST_SAVE_TRIP = "SISIBID_REQUEST_SAVE_TRIP";
export const SISIBID_CANCEL_SAVE_REQUEST = "SISIBID_CANCEL_SAVE_REQUEST";

export const SISIBID_SAVING_BID = "SISIBID_SAVING_BID";
export const SISIBID_SAVED_BID = "SISIBID_SAVED_BID";

export const SISIBID_REQUESTS_DATA_UPDATE = "SISIBID_REQUESTS_DATA_UPDATE";
export const SISIBID_REQUEST_DATA_UPDATE = "SISIBID_REQUEST_DATA_UPDATE";
// ======================= SEND REQUEST ACTIONS ======================
export const SISIBID_REQUEST_SEND_BID_REQUEST =
  "SISIBID_REQUEST_SEND_BID_REQUEST";
export const SISIBID_CANCEL_SEND_BID_REQUEST =
  "SISIBID_CANCEL_SEND_BID_REQUEST";
export const SISIBID_SEND_BID_REQUEST = "SISIBID_SEND_BID_REQUEST";
export const SISIBID_SENT_BID_REQUEST = "SISIBID_SENT_BID_REQUEST";
// =========================== FLOW ACTIONS ==========================
export const SISIBID_FETCHING_OFFERS = "SISIBID_FETCHING_OFFERS";
export const SISIBID_FETCHED_OFFERS = "SISIBID_FETCHED_OFFERS";
export const SISIBID_SAVE_OFFERS = "SISIBID_SAVE_OFFERS";
export const SISIBID_CHANGE_FLOW_VIEW_TYPE = "SISIBID_CHANGE_FLOW_VIEW_TYPE";
export const SISIBID_COMPARE_OFFER = "SISIBID_COMPARE_OFFER";
export const SISIBID_RESET_COMPARE_OFFER = "SISIBID_RESET_COMPARE_OFFER";
// ======================== WAIT MODAL ACTIONS =======================
export const SISIBID_SHOW_WAIT_MODAL = "SISIBID_SHOW_WAIT_MODAL";
export const SISIBID_HIDE_WAIT_MODAL = "SISIBID_HIDE_WAIT_MODAL";
// ===================== OFFER SELECTION ACTIONS =====================
export const SISIBID_INIT_SELECTED_OFFERS = "SISIBID_INIT_SELECTED_OFFERS";
export const SISIBID_DESELECT_OFFER = "SISIBID_DESELECT_OFFER";
export const SISIBID_DESELECT_FULL_OFFER = "SISIBID_DESELECT_FULL_OFFER";
export const SISIBID_SELECT_OFFER = "SISIBID_SELECT_OFFER";
export const SISIBID_SELECT_FULL_OFFER = "SISIBID_SELECT_FULL_OFFER";
