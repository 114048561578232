import { commonList } from "@src/reducers/common";
import {
  B2B_MULTI_SERVICES_LIST_GENERIC_CHANGE,
  B2B_MULTI_SERVICES_LIST_SAVE_RESULTS,
} from "../../../../actions/Operation/B2BReservations/MultiServices/types";

const B2BMultiServicesReducerInitial = {
  ...commonList,
  displayLength: 100,
  filters: {
    reference: "",
    title__icontains: "",
    trip_date__gte: "",
    trip_date__lte: "",
    pax: "",
    edited__gte: "",
    edited__lte: "",
    creator__icontains: "",
    created__gte: "",
    created__lte: "",
    status__in: [],
    page: 1,
    limit: 20,
  },
};
export const B2BMultiServicesListReducer = (
  state = B2BMultiServicesReducerInitial,
  action
) => {
  switch (action.type) {
    case B2B_MULTI_SERVICES_LIST_SAVE_RESULTS:
      return action.data;
    case B2B_MULTI_SERVICES_LIST_GENERIC_CHANGE: {
      const { filters } = action;
      return { ...state, filters: { ...state.filters, ...filters } };
    }
    default:
      return state;
  }
};
