import {
  RECEIVABLE_BEGIN_FETCH_DATA,
  RECEIVABLE_FETCH_DATA,
  RECEIVABLE_END_FETCH_DATA,
  RECEIVABLE_INITIALIZE_LIST_FILTER_FORM,
  RECEIVABLE_INITIALIZE_LIST_COLUMNS_FORM,
  RECEIVABLE_APPLY_LIST_COLUMNS,
  RECEIVABLE_APPLY_LIST_FILTER,
  RECEIVABLE_UPDATE_LIST_FILTER_FORM,
  RECEIVABLE_INITIALIZE_GRAPH_VIEW_TYPE,
  RECEIVABLE_CHANGE_GRAPH_VIEW_TYPE,
  RECEIVABLE_BEGIN_FETCH_GRAPH_DATA,
  RECEIVABLE_END_FETCH_GRAPH_DATA,
  RECEIVABLE_FETCH_GRAPH_DATA,
} from "@src/actions/ManagementConsole/Accounts/Receivable/types";

import {
  initialReceivableFilterForm,
  initialReceivableColumnsSetUp,
} from "@src/forms/ManagementConsole/Receivable";

import { formProcessor, entitiesListSelector } from "@src/reducers/tools";

import update from "immutability-helper";
import _ from "lodash";

//========================================LIST VIEW==============================================
export const initialReceivableList = {
  count: 0,
  next: null,
  previous: null,
  results: [],
  page: 1,
  limit: 20,
  displayLength: 100,
};

export const receivableList = (state = initialReceivableList, action) => {
  switch (action.type) {
    case RECEIVABLE_FETCH_DATA:
      return action.data;
    case RECEIVABLE_INITIALIZE_LIST_FILTER_FORM:
      return { ...state, page: 1 };
    default:
      return state;
  }
};

export const initialReceivableListFetchStatus = "IDLE";
export const receivableListFetchStatus = (
  state = initialReceivableListFetchStatus,
  action
) => {
  switch (action.type) {
    case RECEIVABLE_BEGIN_FETCH_DATA:
      return "FETCHING";
    case RECEIVABLE_END_FETCH_DATA:
      return "FETCHED";
    default:
      return state;
  }
};

//========================================FILTER RELATION============================================
export const receivableListFilterForm = (
  state = initialReceivableFilterForm,
  action
) => {
  switch (action.type) {
    case RECEIVABLE_INITIALIZE_LIST_FILTER_FORM:
      return initialReceivableFilterForm;
    case RECEIVABLE_UPDATE_LIST_FILTER_FORM:
      return formProcessor(
        state,
        action.data.type,
        action.data.name,
        action.data.value
      );
    default:
      return state;
  }
};

export const receivableListFilter = (
  state = initialReceivableFilterForm,
  action
) => {
  switch (action.type) {
    case RECEIVABLE_APPLY_LIST_FILTER:
      return action.data;
    default:
      return state;
  }
};

// ========================= COLUMNS RELATION ========================
export const receivableListColumns = (
  state = entitiesListSelector(initialReceivableColumnsSetUp),
  action
) => {
  switch (action.type) {
    case RECEIVABLE_APPLY_LIST_COLUMNS:
      return entitiesListSelector(action.data);
    default:
      return state;
  }
};

export const receivableListColumnsForm = (
  state = initialReceivableColumnsSetUp,
  action
) => {
  switch (action.type) {
    case RECEIVABLE_INITIALIZE_LIST_COLUMNS_FORM:
      var newState = {};

      for (const key in state) {
        // clear form's value
        state[key] = update(state[key], { value: { $set: [] } });
        newState[key] = update(state[key], { value: { $set: [] } });
        // add value by action data
        action.data.forEach((item) => {
          if (_.find(state[key].choices, { value: item.value })) {
            newState[key] = update(newState[key], {
              value: { $push: [item.value] },
            });
          }
        });
      }

      return newState;
    default:
      return state;
  }
};

// =============================================================GRAPH==================================
export const initType = "ALL";

export const receivableGraphViewType = (state = initType, action) => {
  switch (action.type) {
    case RECEIVABLE_INITIALIZE_GRAPH_VIEW_TYPE:
      return initType;
    case RECEIVABLE_CHANGE_GRAPH_VIEW_TYPE:
      return action.data;
    default:
      return state;
  }
};

export const receivableGraphData = (state = {}, action) => {
  switch (action.type) {
    case RECEIVABLE_FETCH_GRAPH_DATA:
      return action.data;
    default:
      return state;
  }
};

export const initialReceivableGraphDataStatus = "IDLE";
export const receivableGraphDataStatus = (
  state = initialReceivableGraphDataStatus,
  action
) => {
  switch (action.type) {
    case RECEIVABLE_BEGIN_FETCH_GRAPH_DATA:
      return "FETCHING";
    case RECEIVABLE_END_FETCH_GRAPH_DATA:
      return "FETCHED";
    default:
      return state;
  }
};
