import {
  TRIPPLANNER_ADHOC_ACC_SRV_ADD,
  TRIPPLANNER_ADHOC_ACC_SRV_EDIT,
  TRIPPLANNER_ADHOC_ACC_SRV_REMOVE,
} from "../../types/accommodation";

export function addAdhocAccSrv({ srv }) {
  return { type: TRIPPLANNER_ADHOC_ACC_SRV_ADD, srv };
}

export function editAdhocAccSrv({ uid, srv }) {
  return { type: TRIPPLANNER_ADHOC_ACC_SRV_EDIT, uid, srv };
}

export function removeAdhocAccSrv({ srv_uid }) {
  return { type: TRIPPLANNER_ADHOC_ACC_SRV_REMOVE, srv_uid };
}
