import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { Form, Formik } from "formik";
import { NormalInputField, NormalSelectField } from "@src/components/forms";
import { CustomButton } from "@src/components/common/buttons";
import { defaultFilters } from "../helpers";

export const FiltersBar = ({
  filters,
  onSetFilters,
  options,
  averagePrice,
  averageDuration,
  operatorOptions,
}) => {
  return (
    <div className="FiltersBar">
      <Formik
        initialValues={filters}
        enableReinitialize={true}
        onSubmit={(values) => {
          onSetFilters(values);
        }}>
        {({ resetForm }) => (
          <Form className="FiltersBar__form">
            <NormalSelectField
              name="operator"
              label="Operator"
              options={operatorOptions}
            />
            <NormalInputField
              name="min_price"
              label="Min Price"
              type="number"
            />
            <NormalInputField
              name="max_price"
              label="Max Price"
              type="number"
            />
            <NormalSelectField
              name="order_by"
              label="Order By"
              options={[
                ["", "---"],
                ["price", "Price (Lower First)"],
                ["-price", "Price (Higher First)"],
                ["duration", "Duration (Shortest First)"],
                ["-duration", "Duration (Longest First)"],
                ["arrival", "Arrival time (Earliest First)"],
                ["-arrival", "Arrival time (Latest First)"],
                ["departure", "Departure time (Earliest First)"],
                ["-departure", "Departure time (Latest First)"],
              ]}
            />
            <div className="FiltersBar__form__actions">
              <span className="FiltersBar__form__actions__properties">
                <strong>Available Options: </strong>
                {options}
              </span>
              <span className="FiltersBar__form__actions__properties">
                <strong>Average Price: </strong>
                {averagePrice}
              </span>
              <span className="FiltersBar__form__actions__properties">
                <strong>Average Duration: </strong>
                {averageDuration}
              </span>
              <CustomButton
                onClick={(e) => {
                  e.preventDefault();
                  resetForm();
                  onSetFilters(defaultFilters.cast({}));
                }}>
                Reset
              </CustomButton>
              <CustomButton type="submit">Apply</CustomButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
FiltersBar.defaultProps = {
  filters: {},
  options: 0,
  averagePrice: {},
  averageDuration: "",
  operatorOptions: [],
};
FiltersBar.propTypes = {
  setShowFilters: PropTypes.func,
  filters: PropTypes.object.isRequired,
  onSetFilters: PropTypes.func.isRequired,
  options: PropTypes.number.isRequired,
  averagePrice: PropTypes.object.isRequired,
  averageDuration: PropTypes.string.isRequired,
  operatorOptions: PropTypes.array.isRequired,
};
