import React from "react";
import SubSideBar from "./SubSideBar";
import MainSideBar from "./MainSideBar";
import PropTypes from "prop-types";

const SideBar = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  return (
    <div
      className={sidebarCollapsed ? "SideBar--collapsed" : "SideBar"}
      id={sidebarCollapsed ? "sidebar_collapsed" : "sidebar"}>
      <MainSideBar
        sidebarCollapsed={sidebarCollapsed}
        onToggleSidebar={() => setSidebarCollapsed((p) => !p)}
      />
      <SubSideBar sidebarCollapsed={sidebarCollapsed} />
    </div>
  );
};
export default SideBar;
SideBar.propTypes = {
  sidebarCollapsed: PropTypes.bool,
  setSidebarCollapsed: PropTypes.func.isRequired,
};
