import _ from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useContext } from "react";
import { cardStyles, variables } from "@src/jsssetup";
import { createUseStyles } from "react-jss";
import { DayItemControls, daySrvContainerStyles } from "../Day";
import { PricingFormContext, TripPlanContext } from "../../TripPlanner";
import { DateTime } from "luxon";
import { useDispatch, useSelector } from "react-redux";
import OverviewPrice from "../OverviewPrice";
const ghostImg =
  "https://sisistaticassets.s3.ap-southeast-1.amazonaws.com/platform_graphics/imgs/others/destination_placeholder.jpg";
import { boardMapping } from "@src/config/common";
import { removeCustomAccSrv } from "@src/actions/Project/TripPlanner/custom/accommodation";
import ServicePricingForm from "../ServicePricingForm";
import { getServicePricingSelector } from "@src/selectors/Project/TripPlanner/pricing";
import { isServiceFilterSelectedSelector } from "@src/selectors/Project/TripPlanner/service_filters";
import { BookChecker } from "../ItineraryOverviewCommon";
import {
  daySrvBodyStyles,
  daySrvHeaderStyles,
  serviceTypeHeaderStyles,
} from "../Day/common";
import { BookingMetadataBtn } from "../../Widgets/step_four/booking_metadata_btn";
import { ReservationStatusTag } from "../service_panels";
import RatingPanel from "@src/components/common/RatingPanel";
import { useServiceCardExpansion } from "@src/pages/hooks";
import { InlineIcon } from "@iconify/react";

function dater(dt) {
  return DateTime.fromISO(dt).toLocaleString(DateTime.DATE_FULL);
}

const customAccHeaderStyles = createUseStyles({
  CustomAccHeader: (props) => ({
    display: "grid",
    gridTemplateColumns: "1fr max-content",
    alignItems: "center",
    gridGap: variables.half_gap,
    position: "relative",
    padding: props.services_collapsed && [
      variables.half_gap,
      variables.half_gap,
      variables.half_gap,
      variables.double_gap,
    ],
    borderRadius: props.services_collapsed && variables.half_gap,
    background:
      props.services_collapsed && variables.colors.services.accService,
    boxShadow: props.services_collapsed && variables.shadows.normalShadow,
  }),
  headerTitle: (props) => ({
    fontSize: "medium",
    fontWeight: props.services_collapsed ? "bold" : "600",
  }),
  headerActions: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    gridAutoFlow: "column",
  },
  srvType: {
    ...serviceTypeHeaderStyles(),
    fontWeight: "600",
  },
  expandIconCntr: {
    display: "grid",
    placeContent: "center",
    width: "1.5rem",
    height: "1.5rem",
    borderRadius: "50%",
    background: variables.colors.easy.orange,
    padding: variables.half_gap,
    cursor: "pointer",
  },
  expandIcon: {
    fontSize: "large",
    color: variables.colors.text.light,
  },
});
const CustomAccHeader = ({
  eventType,
  service,
  reservationMode,
  reservationLoading,
  reservationStatus,
  services_collapsed,
  toggleSelfCollapse,
}) => {
  const classes = customAccHeaderStyles({ services_collapsed });
  const currentStep = useSelector((state) => state.tripPlannerCurrentStep);

  return (
    <div className={classes.CustomAccHeader}>
      <span className={classes.headerTitle}>
        Accommodation{eventType ? ` ${_.startCase(eventType)}` : ""}:{" "}
        {service.ext_accommodation_name}
      </span>
      <div className={classes.headerActions}>
        <span className={classes.srvType}>Type: Custom</span>
        {currentStep === 4 && !reservationMode && (
          <BookingMetadataBtn
            uid={service.uid}
            srv_type={service.service_type}
            booking_metadata={service.booking_metadata}
          />
        )}
        {reservationMode && (
          <ReservationStatusTag
            reservationLoading={reservationLoading}
            reservationStatus={reservationStatus}
          />
        )}
        <div className={classes.expandIconCntr}>
          <InlineIcon
            className={classes.expandIcon}
            icon={
              services_collapsed ? "ri:arrow-down-s-line" : "ri:arrow-up-s-line"
            }
            onClick={toggleSelfCollapse}
          />
        </div>
      </div>
    </div>
  );
};
CustomAccHeader.propTypes = {
  eventType: PropTypes.string,
  service: PropTypes.object,
  reservationMode: PropTypes.string,
  reservationLoading: PropTypes.bool,
  reservationStatus: PropTypes.string,
  services_collapsed: PropTypes.bool.isRequired,
  toggleSelfCollapse: PropTypes.func,
};

const customAccDataStyles = createUseStyles({
  CustomAccSrv: (props) => ({
    display: "grid",
    gridTemplateColumns: props.services_collapsed ? "1fr" : "8rem 1fr",
    gridGap: variables.normal_gap,
    maxHeight: "11rem",
  }),
  cardContent: {
    display: "grid",
    padding: variables.normal_gap,
    gridGap: variables.half_gap,
    background: variables.colors.services.accServiceBody,
    boxShadow: variables.shadows.heavyShadow,
    borderRadius: `calc(${variables.normal_gap} / 2)`,
    textTransform: "capitalize",
  },
  img: {
    width: "100%",
    height: "100%",
    borderRadius: "5px",
    objectFit: "cover",
    boxShadow: variables.shadows.heavyShadow,
  },
  content: {
    display: "grid",
    gridTemplateColumns: "1fr max-content 1fr",
    alignItems: "center",
    gridGap: variables.normal_gap,
  },
  title: {
    color: variables.colors.text.colored,
    fontWeight: "600",
  },
  ratingCntr: {
    display: "grid",
    gridColumn: "span 3",
    gridTemplateColumns: "max-content max-content",
    gridGap: variables.half_gap,
  },
  rooms: {
    justifySelf: "center",
  },
  boards: {
    justifySelf: "center",
  },
});
export const CustomAccData = ({
  eventType,
  service,
  reservationMode,
  reservationLoading,
  reservationStatus,
  services_collapsed,
  toggleSelfCollapse,
}) => {
  const classes = customAccDataStyles({ services_collapsed });

  const img =
    _.get(
      _.get(service, "metadata.images", []).find((img) => img.main),
      "small"
    ) || ghostImg;

  const rating = _.get(service, "metadata.rating", 0);

  return (
    <div className={classes.CustomAccSrv}>
      {services_collapsed ? (
        <CustomAccHeader
          eventType={eventType}
          service={service}
          reservationMode={reservationMode}
          reservationLoading={reservationLoading}
          reservationStatus={reservationStatus}
          services_collapsed={services_collapsed}
          toggleSelfCollapse={toggleSelfCollapse}
        />
      ) : (
        <React.Fragment>
          <img className={classes.img} alt="" src={img} />
          <div className={classes.cardContent}>
            <CustomAccHeader
              eventType={eventType}
              service={service}
              reservationMode={reservationMode}
              reservationLoading={reservationLoading}
              reservationStatus={reservationStatus}
              services_collapsed={services_collapsed}
              toggleSelfCollapse={toggleSelfCollapse}
            />
            <div className={classes.content}>
              <span className={classes.ratingCntr}>
                <span className={classes.title}>Rating: </span>
                <RatingPanel
                  rating={rating}
                  color={"#F0B040"}
                  fontSize="medium"
                  showEmpty={true}
                />
              </span>
              <span>
                <span className={classes.title}>Check In: </span>
                {dater(service.check_in)}
              </span>
              <span>
                <span className={classes.title}>Check Out: </span>
                {dater(service.check_out)}
              </span>
              <span className={classes.boards}>
                <span className={classes.title}>Boards: </span>
                {boardMapping[service.rooms[0].board]}
              </span>
              <span>
                <span className={classes.title}>Address: </span>{" "}
                {_.get(service, "metadata.address", "N/A")}
              </span>
              <span>
                <span className={classes.title}>Room Types: </span>
                {_.startCase(service.rooms[0].bedding)}
              </span>
              <span className={classes.rooms}>
                <span className={classes.title}>Rooms: </span>
                {service.rooms[0].title}
              </span>
              <span>
                <span className={classes.title}>External Id: </span>
                {service.ext_accommodation_id}
              </span>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
CustomAccData.propTypes = {
  eventType: PropTypes.string.isRequired,
  service: PropTypes.object.isRequired,
  reservationMode: PropTypes.string,
  reservationLoading: PropTypes.bool,
  reservationStatus: PropTypes.string,
  services_collapsed: PropTypes.bool.isRequired,
  toggleSelfCollapse: PropTypes.func,
};

const styles = createUseStyles({
  CustomAccSrvContainer: (props) =>
    daySrvContainerStyles({ currentStep: props.currentStep }),
  CustomAccSrv: {
    ...cardStyles.card,
    background: variables.colors.services.accService,
  },
  header: {
    ...daySrvHeaderStyles(),
    borderBottomColor: variables.colors.services.accServiceBorder,
  },
  headerActions: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    gridAutoFlow: "column",
  },
  srvType: serviceTypeHeaderStyles(),
  body: (props) => ({
    ...daySrvBodyStyles(props),
    gridTemplateColumns: "2fr 7fr",
    overflow: "hidden",
    background: variables.colors.services.accServiceBody,
    height: "8rem",
  }),
  img: {
    objectFit: "cover",
    width: "100%",
    borderRadius: "5px",
    height: "6rem",
    alignSelf: "center",
  },
  content: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    gridAutoRows: "max-content",
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  span2: {
    gridColumn: "span 2",
  },
});
const CustomAccSrv = ({ srv, eventType }) => {
  const { view_style } = useSelector((state) => state.tripPlannerOverviewStyle);
  const currentStep = useSelector((state) => state.tripPlannerCurrentStep);
  const serviceIsSelected = useSelector((state) =>
    isServiceFilterSelectedSelector(state, { service_type: "ACC" })
  );

  const { markup, gross_margin, flat_value } = useSelector((state) =>
    getServicePricingSelector(state, {
      key: `ACC___CU___${_.get(srv, "uid")}`,
    })
  );

  const { showPricingForm } = useContext(PricingFormContext);
  const { services_collapsed, toggleSelfCollapse } = useServiceCardExpansion();

  const price = srv.rooms[0].price.amount;
  const currency = srv.rooms[0].price.currency;

  const dispatch = useDispatch();
  const onRemove = useCallback(({ srv_uid }) =>
    dispatch(removeCustomAccSrv({ srv_uid }))
  );

  const classes = styles({ currentStep, services_collapsed });
  return !serviceIsSelected ? null : (
    <div className={classes.CustomAccSrvContainer}>
      {currentStep === 4 ? (
        <BookChecker uid={srv.uid} srv_type={srv.service_type} />
      ) : !services_collapsed && view_style !== "day_by_day" ? (
        <DayItemControls onDeselect={() => onRemove({ srv_uid: srv.uid })} />
      ) : (
        <div />
      )}
      <CustomAccData
        service={srv}
        eventType={eventType}
        services_collapsed={services_collapsed}
        toggleSelfCollapse={toggleSelfCollapse}
      />
      {showPricingForm ? (
        <ServicePricingForm service={srv} service_type="ACC___CU" />
      ) : services_collapsed ? (
        <div />
      ) : (
        <OverviewPrice
          searching={false}
          serviceType="ACC"
          price={price}
          currency={currency}
          markup={markup}
          gross_margin={gross_margin}
          flat_value={flat_value}
        />
      )}
    </div>
  );
};
CustomAccSrv.defaultProps = {
  srv: {},
};
CustomAccSrv.propTypes = {
  eventType: PropTypes.string,
  srv: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
};
export default CustomAccSrv;
