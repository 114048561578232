import _ from "lodash";
import { retrieveAdhocAccReservation } from "@src/api/Operation/Reservations/Accommodation/adhoc";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Loader } from "rsuite";
import { ContactInformation, ReservationInfoCard, Stepper } from "../common";
import { cxlRuleTemplate } from "../common/cxl_policies";
import { onReject, onSubmit, payloader, retrieverFn } from "../common/helpers";
import { useFeedbackRemarks, useSupplierInfo } from "../common/hooks";
import { genericRsrvFeebackStyles, rsrvFeebackStyles } from "../common/styles";
import { DateTime } from "luxon";
import { currencies } from "@src/definitions";
import {
  commonValidateFeedback,
  validateSupplierInfo,
} from "../common/validators";
import { toast } from "react-toastify";
import ExtrasInfo from "./extras_information";
import RoomInfo from "./rooms_information";
import { retrieveCustomAccReservation } from "@src/api/Operation/Reservations/Accommodation/custom";
import { basicInfoStylesObj } from "./common";
import { variables } from "@src/jsssetup";
import { patchPublicAccReservation } from "@src/api";

const basicInfoStyles = createUseStyles({
  ...basicInfoStylesObj,
  groupCardSpan2: basicInfoStylesObj.groupCard,
  infoGroup2: {
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateColumns: "1fr",
  },
  infoGroup3: {
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateColumns: "repeat(3, 1fr)",
  },
  [`@media ${variables.media.bigscreen}`]: {
    groupCardSpan2: {
      gridColumn: "span 2",
    },
    infoGroup2: {
      display: "grid",
      gridGap: variables.normal_gap,
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    infoGroup3: {
      paddingTop: "20px",
      gridTemplateColumns: "repeat(6, 1fr)",
    },
  },
});
function BasicInfo({ reservation }) {
  const classes = basicInfoStyles();

  const stay = DateTime.fromISO(reservation.check_out)
    .diff(DateTime.fromISO(reservation.check_in), "days")
    .as("days");

  const price = parseFloat(
    _.get(reservation, "original_service_price", 0)
  ).toFixed(2);

  return (
    <div className={classes.BasicInfo}>
      <div className={classes.header}>
        <h5>Basic Information</h5>
      </div>
      <div className={classes.body}>
        <div className={classes.infoGroup2}>
          <div className={classes.groupCard}>
            <div className={classes.infoCouple}>
              <div className={classes.infoCoupleTitle}>Hotel Name</div>
              <div className={classes.infoCoupleValue}>
                {_.get(reservation, "accommodation_meta.name")}
              </div>
            </div>
            <div className={classes.infoCouple}>
              <div className={classes.infoCoupleTitle}>Destination</div>
              <div className={classes.infoCoupleValue}>
                {_.get(reservation, "destination_name")}
              </div>
            </div>
          </div>
          <div className={classes.groupCard}>
            <div className={classes.infoCouple}>
              <div className={classes.infoCoupleTitle}>Check In</div>
              <div className={classes.infoCoupleValue}>
                {DateTime.fromISO(reservation.check_in).toLocaleString(
                  DateTime.DATE_MED_WITH_WEEKDAY
                )}
              </div>
            </div>
            <div className={classes.infoCouple}>
              <div className={classes.infoCoupleTitle}>Check Out</div>
              <div className={classes.infoCoupleValue}>
                {DateTime.fromISO(reservation.check_out).toLocaleString(
                  DateTime.DATE_MED_WITH_WEEKDAY
                )}
              </div>
            </div>
          </div>
          <div className={classes.groupCardSpan2}>
            <div className={classes.infoGroup3}>
              <div className={classes.infoCouple}>
                <div className={classes.infoCoupleTitle}>Rooms</div>
                <div className={classes.infoCoupleValue}>
                  {_.get(reservation, "rooms", []).length}
                </div>
              </div>
              <div className={classes.infoCouple}>
                <div className={classes.infoCoupleTitle}>Stay</div>
                <div className={classes.infoCoupleValue}>
                  {stay == 1 ? "1 Night" : `${stay} Nights`}
                </div>
              </div>
              <div className={classes.infoCouple}>
                <div className={classes.infoCoupleTitle}>Total Price</div>
                <div className={classes.infoCoupleValue}>
                  {_.get(currencies, reservation.currency)} {price}
                </div>
              </div>
              <div className={classes.infoCouple}>
                <div className={classes.infoCoupleTitle}>Pax</div>
                <div className={classes.infoCoupleValue}>{reservation.pax}</div>
              </div>
              <div className={classes.infoCouple}>
                <div className={classes.infoCoupleTitle}>Adults</div>
                <div className={classes.infoCoupleValue}>
                  {reservation.adults}
                </div>
              </div>
              <div className={classes.infoCouple}>
                <div className={classes.infoCoupleTitle}>Children</div>
                <div className={classes.infoCoupleValue}>
                  {reservation.children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <div className={classes.BasicInfo}>
    //   <div className={classes.header}>
    //     <h5>Basic Information</h5>
    //   </div>
    //   <table className={classes.table}>
    //     <thead className={classes.head}>
    //       <tr>
    //         <th>Hotel Name</th>
    //         <th>Destination</th>
    //         <th>Check In</th>
    //         <th>Check Out</th>
    //         <th>Stay</th>
    //         <th>Rooms</th>
    //         <th>Pax</th>
    //         <th>Adults</th>
    //         <th>Children</th>
    //         <th>Total Price</th>
    //       </tr>
    //     </thead>
    //     <tbody>
    //       <tr>
    //         <td className={classes.cell}>
    //           {_.get(reservation, "accommodation_meta.name")}
    //         </td>
    //         <td className={classes.cell}>
    //           {_.get(reservation, "destination_name")}
    //         </td>
    //         <td className={classes.cell}>
    //           {DateTime.fromISO(reservation.check_in).toLocaleString(
    //             DateTime.DATE_MED_WITH_WEEKDAY
    //           )}
    //         </td>
    //         <td className={classes.cell}>
    //           {DateTime.fromISO(reservation.check_out).toLocaleString(
    //             DateTime.DATE_MED_WITH_WEEKDAY
    //           )}
    //         </td>
    //         <td className={classes.cell}>
    //           {stay == 1 ? "1 Night" : `${stay} Nights`}
    //         </td>
    //         <td className={classes.cell}>
    //           {_.get(reservation, "rooms", []).length}
    //         </td>
    //         <td className={classes.cell}>{reservation.pax}</td>
    //         <td className={classes.cell}>{reservation.adults}</td>
    //         <td className={classes.cell}>{reservation.children}</td>
    //         <td className={classes.pricecell}>
    //           {_.get(currencies, reservation.currency)} {price}
    //         </td>
    //       </tr>
    //     </tbody>
    //   </table>
    // </div>
  );
}
BasicInfo.propTypes = {
  reservation: PropTypes.object.isRequired,
};

function validateFeedback({
  feedRemarks,
  request_cxl_policy,
  cxl_rules,
  confirmData,
}) {
  const msgs = commonValidateFeedback({
    cxl_remarks: feedRemarks.cxl_remarks,
    booking_mode_remarks: feedRemarks.booking_mode_remarks,
    reservation_remarks: feedRemarks.reservation_remarks,
    payment_mode_remarks: feedRemarks.payment_mode_remarks,
    request_cxl_policy,
    cxl_rules,
  });

  if (!confirmData.confirmed) {
    if (!confirmData.roomsConfirmed) {
      msgs.push(
        <span>
          Please confirm all <strong>Room Information</strong>.
        </span>
      );
    }

    if (confirmData.length > 0 && !confirmData.extrasConfirmed) {
      msgs.push(
        <span>
          Please confirm all <strong>Extras Information</strong>.
        </span>
      );
    }
  }

  return msgs;
}

const accommodationResFeedbackStyles = createUseStyles({
  AccommodationResFeedback: rsrvFeebackStyles,
  ...genericRsrvFeebackStyles,
});
const AccommodationResFeedback = ({ type }) => {
  const classes = accommodationResFeedbackStyles();
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const feedRemarks = useFeedbackRemarks();
  const supplierInfo = useSupplierInfo();

  const [reservation, setReservation] = useState({});
  const [cxl_rules, setCxlRules] = useState([cxlRuleTemplate]);

  const [confirmData, setConfirmData] = useState({});

  const history = useHistory();

  const {
    params: { reference },
  } = useRouteMatch();

  useEffect(() => {
    if (reference) {
      retrieverFn({
        reference,
        fn:
          type === "ADD"
            ? retrieveAdhocAccReservation
            : retrieveCustomAccReservation,
        setLoading,
        setReservation,
      });
    }
  }, [reference]);

  useEffect(() => {
    setConfirmData({
      rooms: reservation.rooms,
      extras: reservation.extras,
      roomsConfirmed: false,
      extrasConfirmed: false,
      confirmed: false,
    });
  }, [reservation]);

  useEffect(() => {
    if (
      confirmData.roomsConfirmed &&
      confirmData.extrasConfirmed &&
      !confirmData.confirmed
    ) {
      setConfirmData({ confirmed: true });
    }
  }, [confirmData]);

  function nextStepper() {
    var msgs = [];
    if (step === 0) {
      msgs = validateFeedback({
        feedRemarks,
        request_cxl_policy: reservation.request_cxl_policy,
        cxl_rules,
        confirmData,
      });
    } else if (step === 1) {
      msgs = validateSupplierInfo(supplierInfo);
    }

    if (msgs.length) {
      msgs.forEach((error) => toast.error(error, { duration: 5000 }));
      return;
    }

    setStep((p) => p + 1);
  }

  const actionProps = {
    step,
    prevStepper: () => setStep((p) => p - 1),
    nextStepper,
    onSubmit: () => {
      const payload = payloader({
        supplierInfo,
        feedRemarks,
        cxl_rules,
        reference,
        feedback_service_data: { confirm_data: confirmData },
        reservation_type: "ADD",
      });
      onSubmit({
        history,
        payload,
        setLoading,
      });
    },
    onReject: () =>
      onReject({
        history,
        reference,
        payload: { status: "RJ" },
        setLoading,
        resPatchFunc: patchPublicAccReservation,
      }),
  };

  return (
    <div className={classes.AccommodationResFeedback}>
      <Stepper step={step} />
      {loading ? (
        <div className={classes.cardBody}>
          <Loader size="lg" center />
        </div>
      ) : [0, 2].includes(step) ? (
        <ReservationInfoCard
          reservation={reservation}
          actionProps={actionProps}
          feedRemarks={feedRemarks}
          editable={step === 0}
          cxl_rules={cxl_rules}
          setCxlRules={setCxlRules}>
          <BasicInfo reservation={reservation} />
          <RoomInfo
            step={step}
            confirmData={confirmData}
            currency={_.get(reservation, "currency")}
            onConfirm={(data) => setConfirmData((p) => ({ ...p, ...data }))}
          />
          <ExtrasInfo
            confirmData={confirmData}
            currency={_.get(reservation, "currency")}
            onConfirm={(data) => setConfirmData((p) => ({ ...p, ...data }))}
          />
        </ReservationInfoCard>
      ) : (
        <ContactInformation
          {...supplierInfo}
          actionProps={actionProps}
          onChange={(values) => {
            supplierInfo.setSupplierName(values.supplier_name);
            supplierInfo.setSupplierEmail(values.supplier_email);
            supplierInfo.setSupplierPhone(values.supplier_phone);
            supplierInfo.setSupplierRemarks(values.supplier_remarks);
          }}
        />
      )}
    </div>
  );
};
AccommodationResFeedback.propTypes = {
  type: PropTypes.oneOf(["CU", "ADD"]).isRequired,
};

export default AccommodationResFeedback;
