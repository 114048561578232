import {
  createCustomService,
  updateCustomService,
} from "@src/api/Project/CustomService";

import { Notification } from "rsuite";

export const saveReplaceableSrv = async (payload, object_id = null) => {
  try {
    var cres = null;
    if (object_id) {
      cres = await updateCustomService(object_id, payload);
    } else {
      cres = await createCustomService(payload);
    }

    var data = cres.service_data;

    if (typeof data == "string") {
      data = JSON.parse(data);
    }

    return { service_id: cres.id, data };
  } catch (error) {
    console.log(error);
    Notification.error({
      title: "Custom Service Failure",
      description: "We failed to save your service. Please try again.",
    });
    return null;
  }
};
