import {
  CUSTOM_SERVICE_ADDON_INIT,
  CUSTOM_SERVICE_ADDON_SET_DESTINATION,
  CUSTOM_SERVICE_ADDON_SET_ITEM,
  CUSTOM_SERVICE_ADDON_ADD_ITEM,
  CUSTOM_SERVICE_ADDON_REMOVE_ITEM,
  CUSTOM_SERVICE_ADDON_MOVEUP_ITEM,
  CUSTOM_SERVICE_ADDON_MOVEDOWN_ITEM,
  CUSTOM_SERVICE_ADDON_CHANGE_BASE_INFO,
  CUSTOM_SERVICE_ADDON_CHANGE_ITEM_INFO,
  CUSTOM_SERVICE_ADDON_NEXT_STEP,
  CUSTOM_SERVICE_ADDON_PREV_STEP,
  CUSTOM_SERVICE_ADDON_ADD_OPERATOR,
  CUSTOM_SERVICE_ADDON_REMOVE_OPERATOR,
  CUSTOM_SERVICE_ADDON_CHANGE_OPERATOR,
  CUSTOM_SERVICE_ADDON_PRICING_CURRENCY_CHANGE,
  CUSTOM_SERVICE_ADDON_PRICING_INIT,
  CUSTOM_SERVICE_ADDON_PRICING_PRICE_CHANGE,
  CUSTOM_SERVICE_ADDON_RESET_PRICING_PRICE,
  CUSTOM_SERVICE_ADDON_COPY_PRICING_PRICE,
  CUSTOM_SERVICE_ADDON_SHOW_SAVE_MODAL,
  CUSTOM_SERVICE_ADDON_HIDE_SAVE_MODAL,
  CUSTOM_SERVICE_ADDON_LOADING_SAVE_MODAL,
  CUSTOM_SERVICE_ADDON_IDLE_SAVE_MODAL,
  CUSTOM_SERVICE_ADDON_LOAD,
  CUSTOM_SERVICE_ADDON_LOADING,
  CUSTOM_SERVICE_ADDON_IDLE,
  CUSTOM_SERVICE_ADDON_CHANGE_CXL_FEE,
  CUSTOM_SERVICE_ADDON_CHANGE_CXL,
  CUSTOM_SERVICE_ADDON_RESET_PRICING_CXL,
  CUSTOM_SERVICE_ADDON_COPY_PRICING_CXL,
  CUSTOM_SERVICE_ADDON_SHOW_DESCRIPTION_MODAL,
  CUSTOM_SERVICE_ADDON_HIDE_DESCRIPTION_MODAL,
  CUSTOM_SERVICE_ADDON_CHANGE_DESCRIPTION,
  CUSTOM_SERVICE_ADDON_CHANGE_DESCRIPTION_MODAL_LANG,
  CUSTOM_SERVICE_ADDON_START_TIME_CHANGE,
  CUSTOM_SERVICE_ADDON_INDICATIVE_TIME_CHANGE,
  CUSTOM_SERVICE_ADDON_CHANGE_SHORT_DESCRIPTION,
  CUSTOM_SERVICE_ADDON_CHANGE_BOOKING_INFORMATION,
  CUSTOM_SERVICE_CHANGE_DISTRIBUTION_PERIODS,
  CUSTOM_SERVICE_ADDON_PATCH,
} from "./types";
import {
  infoLvl1,
  infoLvl2,
  infoLvl3,
  infoLvl4,
} from "@src/api/Project/TripPlanner";
import _ from "lodash";
import {
  createCustomService,
  updateCustomService,
  getCustomService,
} from "@src/api/Project/CustomService";
import { notifyFailedToSaveService } from "@src/components/common/notifications/CustomServiceNotifications";
import {
  validateAddOnStepOne,
  validateAddOnStepTwo,
  validateAllSteps,
} from "@src/validators/MyLibrary/AddOns";
import {
  notifyAddOnValidation,
  notifyAvailableAddOnValidation,
} from "@src/components/common/notifications/AddOnNotifications";
import { destinationDataExtractor } from "@src/actions/Project/TripPlanner/helpers";

export const initAddOn = () => {
  return { type: CUSTOM_SERVICE_ADDON_INIT };
};

const addOnLoading = () => {
  return { type: CUSTOM_SERVICE_ADDON_LOADING };
};

const addOnIdle = () => {
  return { type: CUSTOM_SERVICE_ADDON_IDLE };
};

export const loadAddOnById = (id) => async (dispatch) => {
  dispatch(addOnLoading());
  try {
    const data = await getCustomService(id);
    dispatch({ type: CUSTOM_SERVICE_ADDON_LOAD, data });
  } catch (error) {
    console.log(error);
    console.log("xasame");
  } finally {
    dispatch(addOnIdle());
  }
};

export const nextStep = () => async (dispatch, getState) => {
  const state = getState();

  const {
    customServiceAddOn,
    customServiceAddOnDestination,
    customServiceAddOnItems,
    customMeetingPointGroups,
  } = state;

  var msgs = [];
  if (state.customServiceAddOn.currentStep == 1) {
    msgs = validateAddOnStepOne({
      customServiceAddOn,
      customServiceAddOnDestination,
      customServiceAddOnItems,
    });
  } else if (state.customServiceAddOn.currentStep == 2) {
    msgs = validateAddOnStepTwo({
      customServiceAddOn,
      customMeetingPointGroups,
    });
  }

  if (msgs.length) {
    notifyAddOnValidation(msgs);
    return;
  }

  if (state.customServiceAddOn.currentStep == 2) {
    await dispatch(initPricing());
  }

  dispatch({ type: CUSTOM_SERVICE_ADDON_NEXT_STEP });
};

export const prevStep = () => {
  return { type: CUSTOM_SERVICE_ADDON_PREV_STEP };
};

const setDestination = (destData) => {
  return { type: CUSTOM_SERVICE_ADDON_SET_DESTINATION, destData };
};

export const selectDestination = (destId, lvl, query) => async (dispatch) => {
  var fn = null;
  switch (lvl) {
    case "lvl1":
      fn = infoLvl1;
      break;
    case "lvl2":
      fn = infoLvl2;
      break;
    case "lvl3":
      fn = infoLvl3;
      break;
    case "lvl4":
      fn = infoLvl4;
      break;
    default:
      fn = infoLvl2;
      break;
  }

  try {
    const result = await fn(destId);
    const dest = destinationDataExtractor({ data: result, type: lvl });
    dispatch(setDestination({ ...dest, query }));
  } catch (error) {
    console.log(error);
  }
};

export const startTimeChange = (start_time) => {
  return { type: CUSTOM_SERVICE_ADDON_START_TIME_CHANGE, start_time };
};

export const indicativePickUpTimeChange = (pickup_time) => {
  return { type: CUSTOM_SERVICE_ADDON_INDICATIVE_TIME_CHANGE, pickup_time };
};

export const addItem =
  (order = null) =>
  (dispatch, getState) => {
    const { start_time } = getState().customServiceAddOn;
    dispatch({ type: CUSTOM_SERVICE_ADDON_ADD_ITEM, order, start_time });
  };

export const removeItem = (order) => (dispatch, getState) => {
  const { start_time } = getState().customServiceAddOn;
  dispatch({ type: CUSTOM_SERVICE_ADDON_REMOVE_ITEM, order, start_time });
};

export const moveUpItem = (order) => (dispatch, getState) => {
  const { start_time } = getState().customServiceAddOn;
  dispatch({ type: CUSTOM_SERVICE_ADDON_MOVEUP_ITEM, order, start_time });
};

export const moveDownItem = (order) => (dispatch, getState) => {
  const { start_time } = getState().customServiceAddOn;
  dispatch({ type: CUSTOM_SERVICE_ADDON_MOVEDOWN_ITEM, order, start_time });
};

export const selectItem = (order, data) => {
  return { type: CUSTOM_SERVICE_ADDON_SET_ITEM, order, data };
};

export const changeItem = (order, key, value) => {
  return { type: CUSTOM_SERVICE_ADDON_CHANGE_ITEM_INFO, order, key, value };
};

export const changeBaseInfo = (key, value) => {
  return { type: CUSTOM_SERVICE_ADDON_CHANGE_BASE_INFO, key, value };
};

export const addonPatch = (data) => {
  return { type: CUSTOM_SERVICE_ADDON_PATCH, data };
};

// ====================== OPERATION PREFERENCES ======================
export const addOperator = () => {
  return { type: CUSTOM_SERVICE_ADDON_ADD_OPERATOR };
};

export const removeOperator = (idx) => {
  return { type: CUSTOM_SERVICE_ADDON_REMOVE_OPERATOR, idx };
};

export const changeOperator = (idx, key, value) => {
  return { type: CUSTOM_SERVICE_ADDON_CHANGE_OPERATOR, idx, key, value };
};

// ===================== DISTRIBUTION PREFERENCES ====================

export const changeDistributionPeriods = ({ distribution_periods }) => {
  return {
    type: CUSTOM_SERVICE_CHANGE_DISTRIBUTION_PERIODS,
    distribution_periods,
  };
};

// ========================== FINAL PRICING ==========================
export const initPricing = () => (dispatch, getState) => {
  const state = getState();
  const currency = state.userMeta.company_currency;
  const meeting_groups = state.customMeetingPointGroups;
  dispatch({
    type: CUSTOM_SERVICE_ADDON_PRICING_INIT,
    currency,
    meeting_groups,
  });
};

export const changePricingCurrency = (currencies) => {
  return { type: CUSTOM_SERVICE_ADDON_PRICING_CURRENCY_CHANGE, currencies };
};

export const changePricingPrice = (
  distIdx,
  meeting_group_uid,
  pax_group_uid,
  price_type,
  value
) => {
  return {
    type: CUSTOM_SERVICE_ADDON_PRICING_PRICE_CHANGE,
    distIdx,
    meeting_group_uid,
    pax_group_uid,
    price_type,
    value,
  };
};

export const resetPricingPrices = (distIdx) => {
  return { type: CUSTOM_SERVICE_ADDON_RESET_PRICING_PRICE, distIdx };
};

export const copyPricingPrices = (distIdx) => {
  return { type: CUSTOM_SERVICE_ADDON_COPY_PRICING_PRICE, distIdx };
};

export const changeCxlFee = (distIdx, value) => {
  return { type: CUSTOM_SERVICE_ADDON_CHANGE_CXL_FEE, distIdx, value };
};

export const changeCxl = (distIdx, key, value) => {
  return { type: CUSTOM_SERVICE_ADDON_CHANGE_CXL, distIdx, key, value };
};

export const resetPricingCxl = (distIdx) => {
  return { type: CUSTOM_SERVICE_ADDON_RESET_PRICING_CXL, distIdx };
};

export const copyPricingCxl = (distIdx) => {
  return { type: CUSTOM_SERVICE_ADDON_COPY_PRICING_CXL, distIdx };
};
// ========================== CONTROL PANEL ==========================
export const showSaveModal = () => {
  return { type: CUSTOM_SERVICE_ADDON_SHOW_SAVE_MODAL };
};

export const hideSaveModal = () => {
  return { type: CUSTOM_SERVICE_ADDON_HIDE_SAVE_MODAL };
};

export const loadingSaveModal = () => {
  return { type: CUSTOM_SERVICE_ADDON_LOADING_SAVE_MODAL };
};

export const idleSaveModal = () => {
  return { type: CUSTOM_SERVICE_ADDON_IDLE_SAVE_MODAL };
};

export const requestSaveService = () => async (dispatch, getState) => {
  const state = getState();

  const {
    customServiceAddOn,
    customServiceAddOnDestination,
    customServiceAddOnItems,
    customMeetingPointGroups,
  } = state;

  var validationMsgs = [];

  if (customServiceAddOn.status == "AV") {
    validationMsgs = validateAllSteps({
      customServiceAddOn,
      customServiceAddOnDestination,
      customServiceAddOnItems,
      customMeetingPointGroups,
    });

    if (validationMsgs.length) {
      notifyAvailableAddOnValidation(validationMsgs);
      return;
    }
  }

  dispatch(showSaveModal());
};

function getSavePayload(state) {
  const payload = {
    service_data: _.omit(state.customServiceAddOn, [
      "id",
      "mapVersion",
      "status",
    ]),
    service_type: "MI",
    status: state.customServiceAddOn.status,
    booking_mode: state.customServiceAddOn.booking_mode,
    purchase_mode: state.customServiceAddOn.purchase_mode,
    deposit_size: state.customServiceAddOn.deposit_size,
    deposit_type: state.customServiceAddOn.deposit_type,
    balance_deadline_days: state.customServiceAddOn.balance_deadline_days,
  };
  payload.service_data.destination = state.customServiceAddOnDestination;
  payload.service_data.items = state.customServiceAddOnItems.map((item) => ({
    ...item,
    data: { ...item.data },
  }));
  const mpointgroups = state.customMeetingPointGroups.map((group) => ({
    ..._.omit(group, ["mode", "loading"]),
  }));
  payload.service_data.meeting_point_groups = mpointgroups;

  const distperiods = payload.service_data.distribution_periods.map(
    (period) => ({
      ...period,
      pricing_data: period.pricing_data.map((pr) => ({
        ...pr,
        value: parseFloat(pr.value),
      })),
    })
  );
  payload.service_data.distribution_periods = distperiods;
  return payload;
}

export const saveAddonService = () => async (dispatch, getState) => {
  const state = getState();
  const payload = getSavePayload(state);

  await dispatch(loadingSaveModal());
  try {
    var result = null;
    if (state.customServiceAddOn.id) {
      result = await updateCustomService(state.customServiceAddOn.id, payload);
    } else {
      result = await createCustomService(payload);
    }

    dispatch(changeBaseInfo("id", result.id));
  } catch (error) {
    notifyFailedToSaveService("Add On");
  } finally {
    dispatch(hideSaveModal());
  }
};

export const promptlessSaveAddonService = () => async (dispatch, getState) => {
  const state = getState();
  const payload = getSavePayload(state);

  try {
    var result = null;
    if (state.customServiceAddOn.id) {
      result = await updateCustomService(state.customServiceAddOn.id, payload);
    } else {
      result = await createCustomService(payload);
    }

    dispatch(changeBaseInfo("id", result.id));
    return true;
  } catch (error) {
    notifyFailedToSaveService("Add On");
    return false;
  }
};

export const saveAsAddonService = (options) => async (dispatch, getState) => {
  const { title, link, history } = options;
  const state = getState();
  const payload = getSavePayload(state);

  payload.service_data.title = title;
  payload.status = "UV";

  if (link) {
    payload["link"] = state.customServiceAddOn.id;
  }

  try {
    const result = await createCustomService(payload);
    history.push(`/my-library/custom-services/addon/${result.id}`);
  } catch (error) {
    notifyFailedToSaveService("Add On");
  }
};

export const showGenDescriptionModal = () => {
  return { type: CUSTOM_SERVICE_ADDON_SHOW_DESCRIPTION_MODAL };
};

export const hideGenDescriptionModal = () => {
  return { type: CUSTOM_SERVICE_ADDON_HIDE_DESCRIPTION_MODAL };
};

export const setGenDescription = (description) => {
  return { type: CUSTOM_SERVICE_ADDON_CHANGE_DESCRIPTION, description };
};

export const changeDescriptionLang = (lang) => {
  return { type: CUSTOM_SERVICE_ADDON_CHANGE_DESCRIPTION_MODAL_LANG, lang };
};

export const changeDescription = (lang, description) => {
  return { type: CUSTOM_SERVICE_ADDON_CHANGE_DESCRIPTION, lang, description };
};

export const changeShortDescription = (lang, description) => {
  return {
    type: CUSTOM_SERVICE_ADDON_CHANGE_SHORT_DESCRIPTION,
    lang,
    description,
  };
};

export const changeBookingInformation = (lang, description) => {
  return {
    type: CUSTOM_SERVICE_ADDON_CHANGE_BOOKING_INFORMATION,
    lang,
    description,
  };
};

export * from "./item_actions";
export * from "./meeting_point_actions";
export * from "./stop_sales_actions";
export * from "./pax_scalling_actions";
export * from "./inclusion_exclusion_actions";
