import _ from "lodash";
import { formStyles, variables } from "@src/jsssetup";
import PropTypes from "prop-types";
import React from "react";
import { createUseStyles } from "react-jss";
import { currencies } from "@src/definitions";
import { Form, Formik } from "formik";
import {
  NormalInputField,
  NormalSelectField,
  NormalSliderField,
} from "@src/components/forms";
import { CustomButton } from "@src/components/common/buttons";

const activityFiltersStyles = createUseStyles({
  Filters: {
    display: "grid",
    gridGap: variables.normal_gap,
    position: "sticky",
    top: "0",
    marginBottom: variables.normal_gap,
  },
  form: {
    ...formStyles.form,
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateColumns: "repeat(4, 1fr)",
  },
  span2: { gridColumn: "span 2" },
  actions: {
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateColumns: "repeat(2, max-content)",
    justifyItems: "end",
    gridColumn: "span 2",
    justifyContent: "end",
    alignItems: "end",
  },
});
export const ActivityFilters = ({ filters, activities, onApplyFilters }) => {
  const classes = activityFiltersStyles();

  const categoriesOptions = _.sortBy(
    Object.entries(
      _.groupBy(
        _.flatten(
          activities.map((act) => act.categories.map((cat) => cat.label))
        )
      )
    ).map(([label, items]) => [label, `${label} (${items.length})`]),
    (v) => v[1]
  );

  const featuresOptions = _.sortBy(
    Object.entries(
      _.groupBy(
        _.flatten(
          activities.map((act) => act.features.map((feat) => feat.label))
        )
      )
    ).map(([label, items]) => [label, `${label} (${items.length})`]),
    (v) => v[1]
  );

  const orderOptions = [
    ["", "------"],
    ["price.value__desc", "Price (High to Low)"],
    ["price.value__asc", "Price (Low to High)"],
    ["ratings.average_rating__desc", "Rating (High to Low)"],
    ["ratings.average_rating__asc", "Rating (Low to High)"],
  ];

  const maxPrice =
    _.maxBy(activities, (act) => act?.price?.value ?? 0)?.price?.value ?? 0;
  const minPrice =
    (_.minBy(activities, (act) => act?.price?.value ?? 0)?.price?.value ?? 0) +
    1;
  const currency = _.get(activities, "0.price.currency", "N/A");
  const currencySymbol = _.get(currencies, currency, "N/A");

  return (
    <div className={classes.Filters}>
      <Formik
        initialValues={{ ...filters, max_price: maxPrice }}
        onSubmit={(values) => onApplyFilters(values)}>
        {({ values }) => (
          <Form className={classes.form}>
            <NormalInputField name="title" label="Title" />
            <NormalSelectField
              name="categories"
              label="Categories"
              options={[["", "------"], ...categoriesOptions]}
            />
            <NormalSelectField
              name="features"
              label="Features"
              options={[["", "------"], ...featuresOptions]}
            />
            <NormalSelectField
              name="order"
              label="Order By"
              options={orderOptions}
            />
            <div className={classes.span2}>
              <NormalSliderField
                label={`Max Price: ${currencySymbol}${values.max_price}`}
                name="max_price"
                min={minPrice}
                max={maxPrice}
              />
            </div>
            <div className={classes.actions}>
              <CustomButton type="reset" appearance="ghost">
                Reset
              </CustomButton>
              <CustomButton type="submit">Apply</CustomButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
ActivityFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  activities: PropTypes.array.isRequired,
  onApplyFilters: PropTypes.func.isRequired,
};
