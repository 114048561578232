import {
  TRIPPLANNER_TRP_ADHOC_ADD,
  TRIPPLANNER_TRP_ADHOC_REMOVE,
  TRIPPLANNER_TRP_ADHOC_UPDATE,
} from "./types";
import { v4 } from "uuid";

export const addTrpAdhocSrv = ({
  srvType,
  service,
  origin_order,
  destination_order,
  addhoc_service_type,
}) => {
  return {
    type: TRIPPLANNER_TRP_ADHOC_ADD,
    srvType,
    service: { ...service, uid: v4() },
    origin_order,
    destination_order,
    addhoc_service_type,
  };
};

export const removeTrpAdhocSrv = ({ service_uid }) => {
  return { type: TRIPPLANNER_TRP_ADHOC_REMOVE, service_uid };
};

export const updateTrpAdhocSrv = ({ srv_type, service, srv_id }) => {
  return {
    type: TRIPPLANNER_TRP_ADHOC_UPDATE,
    srv_type,
    service,
    srv_id,
  };
};
