import {
  Drawer,
  Panel,
  Carousel,
  IconButton,
  Icon,
  Tag,
  Timeline,
  Button,
  Loader,
} from "rsuite";

import {
  getAddOns,
  hideAddAddonDrawer,
  selectAddOn,
  removeAddOn,
  changeMode,
} from "@src/actions/Project/TripPlanner/day_by_day_actions";
import { humanizeDuration } from "@src/tools/date_tools";

import _ from "lodash";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";
import { naString } from "@src/tools/string_tools";
import SuIcon from "@src/style/icon/components/SuIcon";
import PriceDetails from "./PriceDetails";
import {
  addOnsFilter,
  selectedAddOnIdsSelector,
} from "@src/selectors/Project/TripPlanner/addon_selectors";
import AddOnFilters from "./AddOnFilters";
import LogoLoading from "@src/components/common/LogoLoading";
import { addon_icon_mapping } from "@src/config/service";
import AddOnDetailsFilters from "./AddOnDetailsFilters";
import AddOnDetails from "./AddOnDetails";
import {
  getAddOnDuration,
  getAddOnItemDuration,
  getAddOnItemTransitDuration,
  getAddOnItemTransitDistance,
} from "@src/selectors/MyLibrary/AddOns";
import { humanizeDistance } from "@src/map_tools/map";

class AddOn extends React.PureComponent {
  constructor(props) {
    super(props);
    this.icon_mapping = {
      ...addon_icon_mapping,
    };
  }
  render() {
    const { addon, idx, selected, meetingGroupUid, onViewDetails } = this.props;

    const images = addon.items
      .map((item) => {
        if (_.get(item, "data.custom_images.0.photo_sm_url")) {
          return _.get(item, "data.custom_images.0.photo_sm_url");
        }

        const primary = _.get(item, "data.image_set", []).find(
          (img) => img.primary
        );

        if (primary) {
          return primary.photo_sm_url;
        }
        return null;
      })
      .filter((i) => i);

    const price =
      addon.price.find((pr) => pr.group_uid == meetingGroupUid) ||
      addon.price.sort((a, b) => a.value - b.value)[0];

    const isRefundable = addon.cancellation.some(
      (cxl) => cxl.type == "refundable"
    );

    return (
      <Panel
        className="CustomPanel ListAddOn"
        header={
          <React.Fragment>
            <h5>
              {idx + 1}. {addon.title}, {addon.items.length} Attractions,
              Duration: {humanizeDuration(getAddOnDuration(addon))}, Indicative
              Pickup Time: {naString(_.get(addon.indicative_pickup_tim))}
            </h5>
            <div className="CustomPanel__header-actions">
              <Tag color={isRefundable ? "green" : "red"}>
                <strong>
                  {isRefundable ? "Refundable" : "Non Refundable"}
                </strong>
              </Tag>
            </div>
          </React.Fragment>
        }
        shaded>
        <Carousel
          autoplay={true}
          placement="bottom"
          shape="dot"
          className="ListAddOn__carousel">
          {images.map((img, iidx) => (
            <img key={iidx} className="ListAddOn__carousel-img" src={img} />
          ))}
        </Carousel>
        <div className="ListAddOn__content">
          <div className="ListAddOn__content__timeline-and-price">
            <Timeline className="ListAddOn__content__timeline-and-price__timeline">
              {addon.items.map((item, iidx) => {
                return (
                  <React.Fragment key={iidx}>
                    <Timeline.Item
                      dot={<SuIcon icon={this.icon_mapping[item.type]} />}>
                      <p>
                        <strong>{item.data.name_en}</strong>
                      </p>
                      <p>
                        <span>
                          <strong>Arrival Time:</strong>{" "}
                          {naString(item.arrival_time)}
                        </span>
                        ,{" "}
                        <span>
                          <strong>Arrive By</strong>:{" "}
                          {_.startCase(item.transportation_mode)}
                        </span>
                        ,{" "}
                        <span>
                          <strong>Duration:</strong>{" "}
                          {humanizeDuration(getAddOnItemDuration(item), {
                            abbrev: true,
                          })}
                        </span>
                      </p>
                    </Timeline.Item>
                    {iidx + 1 == addon.items.length ? null : (
                      <Timeline.Item key={`${iidx}-transit`}>
                        <small>
                          <span>
                            <strong>Transit Duration:</strong>{" "}
                            {humanizeDuration(
                              getAddOnItemTransitDuration(item),
                              {
                                abbrev: true,
                              }
                            )}
                            {", "}
                          </span>
                          <span>
                            <strong>Transit Distance:</strong>{" "}
                            {humanizeDistance(
                              getAddOnItemTransitDistance(item)
                            )}
                          </span>
                        </small>
                      </Timeline.Item>
                    )}
                  </React.Fragment>
                );
              })}
            </Timeline>
            <div className="ListAddOn__content__timeline-and-price__price">
              {selected ? (
                <Button color="green" size="xs">
                  <strong>Selected</strong>
                </Button>
              ) : null}
              <PriceDetails
                price={price.value}
                currency={price.currency}
                searching={false}
                serviceType="MI"
              />
              <Button
                color="blue"
                size="xs"
                onClick={function () {
                  onViewDetails(addon.id);
                }}>
                <strong>View Details</strong>
              </Button>
            </div>
          </div>
          <div className="ListAddOn__content__categories">
            <strong>Categories:</strong>
            <div className="ListAddOn__content__categories__tags">
              {addon.categories.map((cat, idx) => (
                <Tag key={idx} color="cyan">
                  <strong>{_.startCase(cat)}</strong>
                </Tag>
              ))}
            </div>
          </div>
        </div>
      </Panel>
    );
  }
}
AddOn.defaultProps = {
  selected: false,
};
AddOn.propTypes = {
  idx: PropTypes.number.isRequired,
  addon: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  meetingGroupUid: PropTypes.string,
  onDeselect: PropTypes.func.isRequired,
  onViewDetails: PropTypes.func.isRequired,
};

class AddOnDrawer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { meetingGroupUid: null };

    this.handleViewDetails = this.handleViewDetails.bind(this);
    this.handleViewList = this.handleViewList.bind(this);
    this.handleSelectMeetingPointUid = this.handleSelectMeetingPointUid.bind(
      this
    );
  }
  async componentDidUpdate(prevProps) {
    const { show, fetch } = this.props;

    if (!prevProps.show && show) {
      await fetch();
    }
  }
  handleViewDetails(addOnId) {
    const { onChangeMode } = this.props;
    onChangeMode("details", addOnId);
  }
  handleViewList() {
    const { onChangeMode } = this.props;
    onChangeMode("list");
  }
  handleSelectMeetingPointUid(meetingGroupUid) {
    this.setState((p) => ({ ...p, meetingGroupUid }));
  }
  renderList() {
    const { meetingGroupUid } = this.state;
    const { selectedIds, filteredResults, onDeselect } = this.props;

    if (!filteredResults.length) {
      return <LogoLoading msg="No data available." />;
    }

    return filteredResults.map((result, idx) => (
      <AddOn
        key={idx}
        addon={result}
        idx={idx + 1}
        selected={selectedIds.includes(result.id)}
        meetingGroupUid={meetingGroupUid}
        onDeselect={onDeselect}
        onViewDetails={this.handleViewDetails}
      />
    ));
  }
  renderDetails() {
    const { meetingGroupUid } = this.state;
    const {
      addOnId,
      day,
      results,
      setLang,
      selectedIds,
      onSelect,
      onDeselect,
    } = this.props;

    const addon = results.find((result) => result.id == addOnId);

    if (!addon) {
      return null;
    }

    return (
      <AddOnDetails
        setLang={setLang}
        addon={addon}
        meetingGroupUid={meetingGroupUid}
        selected={selectedIds.includes(addon.id)}
        onAddOnSelect={function (addon) {
          onSelect(day, addon);
        }}
        onAddOnDeselect={function (id) {
          onDeselect(day, id);
        }}
      />
    );
  }
  renderDetailsFilters() {
    const { meetingGroupUid } = this.state;
    const { results, accGeodata, addOnId } = this.props;

    const addon = results.find((result) => result.id == addOnId);
    return (
      <AddOnDetailsFilters
        addon={addon}
        accGeodata={accGeodata}
        meetingGroupUid={meetingGroupUid}
        onSelectMeetingPoint={this.handleSelectMeetingPointUid}
      />
    );
  }
  render() {
    const { mode, show, loading, onHide } = this.props;

    if (!show) {
      return null;
    }
    return (
      <Drawer
        className={`AddOnDrawer TripDrawer${
          loading ? " TripDrawer--loading" : ""
        }`}
        show={show}
        size="lg"
        onHide={onHide}>
        <Drawer.Header>
          <Drawer.Title
            className={`TripDrawer__header ${
              mode == "details" ? "TripDrawer__header--details" : ""
            }`}>
            {mode == "details" ? (
              <IconButton
                icon={<Icon icon="angle-left" />}
                size="sm"
                onClick={this.handleViewList}>
                Back
              </IconButton>
            ) : null}
            {mode == "list" ? "Add On Services" : "Add On Details"}
          </Drawer.Title>
          {mode == "list" ? <AddOnFilters /> : this.renderDetailsFilters()}
        </Drawer.Header>
        <Drawer.Body>
          {loading ? (
            <div className="TripDrawer__loader">
              <Loader content="Please wait..." vertical size="lg" />
            </div>
          ) : mode == "list" ? (
            this.renderList()
          ) : (
            this.renderDetails()
          )}
        </Drawer.Body>
      </Drawer>
    );
  }
}
AddOnDrawer.defaultProps = {
  show: false,
  loading: false,
  results: [],
  filteredResults: [],
  selectedIds: [],
  mode: "list",
};
AddOnDrawer.propTypes = {
  setLang: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  day: PropTypes.number.isRequired,
  results: PropTypes.array.isRequired,
  filteredResults: PropTypes.array.isRequired,
  selectedIds: PropTypes.array.isRequired,
  accGeodata: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  addOnId: PropTypes.number,
  fetch: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onDeselect: PropTypes.func.isRequired,
  onChangeMode: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  const { setLang } = state;
  const {
    show,
    loading,
    day,
    results,
    accommodation,
    mode,
    addOnId,
  } = state.tripPlannerAddOnDrawer;

  const filteredResults = addOnsFilter(state);
  const selectedIds = selectedAddOnIdsSelector(state, { day });

  const accGeodata = _.get(accommodation, "metadata.geodata", {});

  return {
    setLang,
    show,
    loading,
    day,
    results,
    selectedIds,
    filteredResults,
    accGeodata,
    mode,
    addOnId,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetch: () => dispatch(getAddOns()),
    onHide: () => dispatch(hideAddAddonDrawer()),
    onSelect: (day, addOn) => dispatch(selectAddOn(day, addOn)),
    onDeselect: (day, id) => dispatch(removeAddOn(day, id)),
    onChangeMode: (mode, addOnId) => dispatch(changeMode(mode, addOnId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddOnDrawer);
