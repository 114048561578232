export const GEO_POLICY_FETCH_DATA = "GEO_POLICY_FETCH_DATA";
export const GEO_POLICY_BEGIN_FETCH_DATA = "GEO_POLICY_BEGIN_FETCH_DATA";
export const GEO_POLICY_END_FETCH_DATA = "GEO_POLICY_END_FETCH_DATA";
export const GEO_POLICY_INITIALIZE_LIST_FILTER_FORM =
  "GEO_POLICY_INITIALIZE_LIST_FILTER_FORM";
export const GEO_POLICY_UPDATE_LIST_FILTER_FORM =
  "GEO_POLICY_UPDATE_LIST_FILTER_FORM";
export const GEO_POLICY_APPLY_LIST_FILTER = "GEO_POLICY_APPLY_LIST_FILTER";
export const GEO_POLICY_UPDATE_LIST_COLUMNS_FORM =
  "GEO_POLICY_UPDATE_LIST_COLUMNS_FORM";
export const GEO_POLICY_APPLY_LIST_COLUMNS = "GEO_POLICY_APPLY_LIST_COLUMNS";
export const GEO_POLICY_INITIALIZE_LIST_COLUMNS_FORM =
  "GEO_POLICY_INITIALIZE_LIST_COLUMNS_FORM";
export const GEO_POLICY_FETCH_OPTIONS = "GEO_POLICY_FETCH_OPTIONS";

export const GEO_POLICY_RESERVATION_DATA = "GEO_POLICY_RESERVATION_DATA";
export const GEO_POLICY_INITIALIZE_BASE_DATA_EDIT_FORM =
  "GEO_POLICY_INITIALIZE_BASE_DATA_EDIT_FORM";
export const GEO_POLICY_CLEAN_BASE_DATA_EDIT_FORM =
  "GEO_POLICY_CLEAN_BASE_DATA_EDIT_FORM";
export const GEO_POLICY_EDIT_BASE_DATA = "GEO_POLICY_EDIT_BASE_DATA";
export const GEO_POLICY_VALIDATE_BASE_DATA_EDIT_FORM =
  "GEO_POLICY_VALIDATE_BASE_DATA_EDIT_FORM";

export const GEO_POLICY_RESET_LIST_FILTER_FORM =
  "GEO_POLICY_RESET_LIST_FILTER_FORM";
export const GEO_POLICY_FETCH_GRAPH_DATA = "GEO_POLICY_FETCH_GRAPH_DATA";

export const COUNTRIES_LIST_FETCH_OPTIONS = "COUNTRIES_LIST_FETCH_OPTIONS";
export const COUNTRIES_DATA_FETCH_LIST = "COUNTRIES_DATA_FETCH_LIST";
export const COUNTRIES_FORMAT_OPTIONS = "COUNTRIES_FORMAT_OPTIONS";
// ===========================================EDIT FORM====================================
export const GEO_POLICY_INIT_BASE_DATA_EDIT_FORM =
  "GEO_POLICY_INIT_BASE_DATA_EDIT_FORM";
export const GEO_POLICY_FETCH_EDIT_DATA = "GEO_POLICY_FETCH_EDIT_DATA";
export const GEO_POLICY_LOADING_EDIT_FORM = "GEO_POLICY_LOADING_EDIT_FORM";
export const GEO_POLICY_IDLE_EDIT_FORM = "GEO_POLICY_IDLE_EDIT_FORM";
export const GEO_POLICY_HIDE_FORM_MODAL = "GEO_POLICY_HIDE_FORM_MODAL";
export const GEO_POLICY_SHOW_FORM_MODAL = "GEO_POLICY_SHOW_FORM_MODAL";
