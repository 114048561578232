import {
  // ===================== INFRASTRUCTURE ACTIONS ====================
  TRIPPLANNER_SELECT_FLIGHT_OPTION,
  // ==================== TRANSPORTATION OVERVIEW ====================
  TRIPPLANNER_HIDE_ALL_TRP,
} from "./types";

// ========================= ACTION CREATORS =========================

import { resetAllFlights } from "./flight_actions";

import _ from "lodash";
import {
  TRIPPLANNER_VIEW_ALL_TRP_IDLE,
  TRIPPLANNER_VIEW_ALL_TRP_LOADING,
} from "./types/overview_transportation_types";

export const selectFlightOption =
  (extremalLeg, destOrder, uid, optionType, optionUid) => async (dispatch) => {
    const key = extremalLeg ? "extremalLeg" : destOrder;

    await dispatch({
      type: TRIPPLANNER_SELECT_FLIGHT_OPTION,
      key,
      uid,
      optionType,
      optionUid,
    });
  };

export const loadingViewAllTrp = () => {
  return { type: TRIPPLANNER_VIEW_ALL_TRP_LOADING };
};

export const idleViewAllTrp = () => {
  return { type: TRIPPLANNER_VIEW_ALL_TRP_IDLE };
};

export const hideAllTrp = () => (dispatch) => {
  dispatch(hideTripPlannerAllTrp());
  // dispatch({ type: TRIPPLANNER_HIDE_ALL_TRP });
  dispatch(resetAllFlights());
};

export const hideTripPlannerAllTrp = () => {
  return {
    type: TRIPPLANNER_HIDE_ALL_TRP,
  };
};
