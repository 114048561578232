import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { InlineIcon } from "@iconify/react";
import { variables } from "@src/jsssetup";
import { DateTime, Duration } from "luxon";
import { handleNonValidImgFn } from "@src/tools/common_tools";

const trainDataStyles = createUseStyles({
  TrainData: (props) => ({
    display: "grid",
    gridTemplateColumns: props.services_collapsed ? "1fr" : "8rem 1fr",
    gridTemplateRows: props.services_collapsed ? "unset" : "10rem",
    gridGap: variables.normal_gap,
    maxHeight: "10rem",
  }),
  cardContent: {
    display: "grid",
    padding: variables.normal_gap,
    gridGap: variables.half_gap,
    boxShadow: variables.shadows.heavyShadow,
    borderRadius: `calc(${variables.normal_gap} / 2)`,
    textTransform: "capitalize",
  },
  header: (props) => ({
    display: "grid",
    gridTemplateColumns: props.services_collapsed
      ? "repeat(2,max-content)"
      : "1fr max-content",
    alignItems: "center",
    justifyContent: "space-between",
    gridGap: variables.half_gap,
    padding: props.services_collapsed && [
      variables.half_gap,
      variables.half_gap,
      variables.half_gap,
      variables.double_gap,
    ],
    borderRadius: props.services_collapsed && variables.half_gap,
    boxShadow: props.services_collapsed && variables.shadows.normalShadow,
  }),
  img: {
    width: "100%",
    height: "100%",
    borderRadius: "5px",
    objectFit: "contain",
    padding: ["3rem", 0],
    boxShadow: variables.shadows.heavyShadow,
  },
  metadata: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    alignItems: "center",
    gridGap: variables.normal_gap,
  },
  headerTitle: { fontSize: "medium", fontWeight: "bold" },
  title: { color: variables.colors.text.colored, fontWeight: "600" },
  actionBtns: {
    display: "grid",
    gridAutoFlow: "column",
    gridGap: variables.half_gap,
  },
  expandIconCntr: {
    display: "grid",
    placeContent: "center",
    width: "1.5rem",
    height: "1.5rem",
    borderRadius: "50%",
    background: variables.colors.easy.orange,
    padding: variables.half_gap,
    cursor: "pointer",
  },
  expandIcon: { fontSize: "large", color: variables.colors.text.light },
  itinerary: { gridColumn: "span 2" },
});
export const TrainData = ({
  eventType = null,
  train,
  services_collapsed,
  toggleSelfCollapse,
  children,
}) => {
  const classes = trainDataStyles({ services_collapsed });

  const origin = train?.origin?.label ?? "N/A";
  const destination = train?.destination?.label ?? "N/A";
  const duration = Duration.fromISO(train?.duration ?? "").toHuman();
  const carrier = train?.segments?.[0]?.operating_carrier ?? "N/A";
  const departure = DateTime.fromISO(train?.departure ?? "").toLocaleString(
    DateTime.DATETIME_MED
  );
  const arrival = DateTime.fromISO(train?.arrival ?? "").toLocaleString(
    DateTime.DATETIME_MED
  );

  const header = (
    <React.Fragment>
      <div className={classes.headerTitle}>
        {!!eventType && _.capitalize(eventType) + " "}Train: {origin} -{" "}
        {destination}
      </div>
      <div className={classes.actionBtns}>
        {children}
        <div className={classes.expandIconCntr}>
          <InlineIcon
            className={classes.expandIcon}
            icon={
              services_collapsed ? "ri:arrow-down-s-line" : "ri:arrow-up-s-line"
            }
            onClick={toggleSelfCollapse}
          />
        </div>
      </div>
    </React.Fragment>
  );

  return _.isEmpty(train) ? null : (
    <div className={classes.TrainData}>
      {services_collapsed ? (
        <div className={classes.header}>{header}</div>
      ) : (
        <React.Fragment>
          <img
            className={classes.img}
            src={train?.segments?.[0]?.supplier_logo}
            onError={handleNonValidImgFn}
          />
          <div className={classes.cardContent}>
            <div className={classes.header}>{header}</div>
            <div className={classes.metadata}>
              <span className={classes.itinerary}>
                <span className={classes.title}>Itinerary:</span> {origin} -{" "}
                {destination}
              </span>
              <span>
                <span className={classes.title}>Duration:</span>{" "}
                {duration ? duration : "N/A"}
              </span>
              <span>
                <span className={classes.title}>Carrier: </span> {carrier}
              </span>
              <span>
                <span className={classes.title}>Departure: </span> {departure}
              </span>
              <span>
                <span className={classes.title}>Arrival: </span> {arrival}
              </span>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
TrainData.propTypes = {
  eventType: PropTypes.oneOf(["arrival", "departure"]),
  train: PropTypes.object.isRequired,
  services_collapsed: PropTypes.bool.isRequired,
  toggleSelfCollapse: PropTypes.func,
  children: PropTypes.object.isRequired,
};
