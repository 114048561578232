import { Loader, IconButton, Icon } from "rsuite";
import { notifyCommonError } from "@src/components/common/notifications/CommonErrorNotification.js";

import { Modal, ControlLabel, FormGroup, Form, FormControl } from "rsuite";

import { hideAuthModal, initializeAppData } from "@src/actions/Auth";

import {
  signIn,
  getLSUserData,
  decodeJwt,
  signOut,
} from "@src/tools/auth_tools";

import update from "immutability-helper";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";

class AuthModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { username: "", password: "", loading: false };

    this.handleChange = this.handleChange.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
  }
  componentDidMount() {
    this.setUserData();
  }
  componentDidUpdate(prevProps) {
    const { show } = this.props;

    if (!prevProps.show && show) {
      this.setUserData();
    }
  }
  setUserData() {
    const userData = getLSUserData();

    if (!userData.user_info) {
      window.location.href = "#/user/SignInAndUp";
    }

    var user_info = JSON.parse(userData.user_info);
    const jwt_data = decodeJwt(user_info);
    this.setState((p) => update(p, { username: { $set: jwt_data.email } }));
  }
  handleChange(name, value) {
    this.setState((p) => update(p, { [name]: { $set: value } }));
  }
  async onConfirm() {
    const { password } = this.state;
    const { hideAuthModal, initAppData } = this.props;

    if (password == "") {
      notifyCommonError("Please write your password first.");
      return;
    }

    await this.setState((p) => update(p, { loading: { $set: true } }));

    const userData = getLSUserData();

    if (!userData.user_info) {
      window.location.href = "#/user/SignInAndUp";
    }

    var user_info = JSON.parse(userData.user_info);

    const jwt_data = decodeJwt(user_info);

    const res = await signIn(jwt_data.email, password, false);

    if (res) {
      await initAppData();
      hideAuthModal();
      this.setState((p) => update(p, { loading: { $set: false } }));
    } else {
      this.setState((p) => update(p, { loading: { $set: false } }));
    }
  }
  render() {
    const { loading } = this.state;
    const { show, onSignOut } = this.props;

    return (
      <Modal
        className="CustomModal ModalCentered ModalCentered--center-content"
        show={show}
      >
        <Modal.Header>
          <Modal.Title>
            {loading
              ? "Please wait while we are signing you in."
              : "Please enter your password."}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <Loader size="lg" />
          ) : show ? (
            <Form
              formValue={this.state}
              onKeyDown={(e) => {
                e.persist();

                if (e.type == "keydown" && e.key == "Enter") {
                  this.onConfirm();
                }
              }}
              onChange={(formValue) =>
                this.handleChange("password", formValue.password)
              }
            >
              <FormGroup>
                <ControlLabel>Username</ControlLabel>
                <FormControl disabled name="username" type="text" />
              </FormGroup>
              <FormGroup>
                <ControlLabel>Password</ControlLabel>
                <FormControl name="password" type="password" />
              </FormGroup>
            </Form>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          {loading ? null : (
            <React.Fragment>
              <IconButton
                icon={<Icon icon="sign-out" />}
                appearance="primary"
                onClick={onSignOut}
              >
                Sign Out
              </IconButton>
              <IconButton
                onClick={this.onConfirm}
                color="green"
                icon={<Icon icon="sign-in" />}
              >
                Confirm
              </IconButton>
            </React.Fragment>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}

AuthModal.propTypes = {
  show: PropTypes.bool.isRequired,
  hideAuthModal: PropTypes.func.isRequired,
  initAppData: PropTypes.func.isRequired,
  onSignOut: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    show: state.authModal.show,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideAuthModal: () => dispatch(hideAuthModal()),
    initAppData: () => dispatch(initializeAppData()),
    onSignOut: () => signOut(),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthModal);
