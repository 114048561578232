import {
  initialToursFilterForm,
  initialToursColumnsForm,
} from "@src/forms/Operation/Tours/toursForms";

import {
  BEGIN_FETCH_TOURS_DATA,
  FETCH_TOURS_DATA,
  END_FETCH_TOURS_DATA,
  FETCH_TOURS_OPTIONS,
  UPDATE_TOURS_LIST_FILTER_FORM,
  APPLY_TOURS_LIST_FILTER,
  INITIALIZE_TOURS_LIST_FILTER_FORM,
  UPDATE_TOURS_LIST_COLUMNS_FORM,
  APPLY_TOURS_LIST_COLUMNS,
  INITIALIZE_TOURS_LIST_COLUMNS_FORM,
} from "@src/actions/Operation/Tours/types";

import {
  FORMAT_TRAVEL_SERVICES_OPTIONS,
  FORMAT_TRAVEL_SERVICES_FORM,
} from "@src/actions/Shares/types";

import { formProcessor, entitiesListSelector } from "@src/reducers/tools";
import update from "immutability-helper";

import _ from "lodash";

const initialToursList = {
  count: 0,
  next: null,
  previous: null,
  results: [],
  page: 1,
  limit: 20,
  displayLength: 100,
};

export const toursList = (state = initialToursList, action) => {
  switch (action.type) {
    case FETCH_TOURS_DATA:
      return action.data;
    default:
      return state;
  }
};

export const toursOptionsFetchStatus = (state = "IDLE", action) => {
  switch (action.type) {
    case FETCH_TOURS_OPTIONS:
      return "FETCHED";
    default:
      return state;
  }
};

export const toursOptions = (state = {}, action) => {
  switch (action.type) {
    case FETCH_TOURS_OPTIONS:
      return _.assign(state, action.data);
    case FORMAT_TRAVEL_SERVICES_OPTIONS:
      return _.assign(state, action.data);
    default:
      return state;
  }
};

export const toursListFetchStatus = (state = "IDLE", action) => {
  switch (action.type) {
    case BEGIN_FETCH_TOURS_DATA:
      return "FETCHING";
    case END_FETCH_TOURS_DATA:
      return "FETCHED";
    default:
      return state;
  }
};

// filter
export const toursListFilter = (state = initialToursFilterForm, action) => {
  switch (action.type) {
    case APPLY_TOURS_LIST_FILTER:
      return action.data;
    default:
      return state;
  }
};

export const toursListFilterForm = (state = initialToursFilterForm, action) => {
  switch (action.type) {
    case INITIALIZE_TOURS_LIST_FILTER_FORM:
      return action.data;
    case UPDATE_TOURS_LIST_FILTER_FORM:
      return formProcessor(
        state,
        action.data.type,
        action.data.name,
        action.data.value
      );
    case FORMAT_TRAVEL_SERVICES_FORM:
      return _.assign(state, action.data);
    default:
      return state;
  }
};

// columns
export const toursListColumns = (
  state = entitiesListSelector(initialToursColumnsForm),
  action
) => {
  switch (action.type) {
    case APPLY_TOURS_LIST_COLUMNS:
      return entitiesListSelector(action.data);
    default:
      return state;
  }
};

export const toursListColumnsForm = (
  state = initialToursColumnsForm,
  action
) => {
  switch (action.type) {
    case INITIALIZE_TOURS_LIST_COLUMNS_FORM:
      var newState = {};
      for (const key in state) {
        // clear form's value
        state[key] = update(state[key], { value: { $set: [] } });
        newState[key] = update(state[key], { value: { $set: [] } });
        // add value by action data
        action.data.forEach((item, index) => {
          if (_.find(state[key].choices, { value: item.value })) {
            newState[key] = update(newState[key], {
              value: { $push: [item.value] },
            });
          }
        });
      }
      return newState;
    case UPDATE_TOURS_LIST_COLUMNS_FORM:
      return action.data;
    default:
      return state;
  }
};
