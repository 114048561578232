import { Notification } from "rsuite";
import React from "react";

export const notifyFetchingArchiveResultsError = (title = "Archive") => {
  Notification.warning({
    title: title,
    description: (
      <p>
        You can not get <strong>{title}</strong> list, we are sorry for this
        inconvenience
      </p>
    ),
    duration: 6000,
  });
};
