import _ from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useContext } from "react";
import { cardStyles, variables } from "@src/jsssetup";
import { createUseStyles } from "react-jss";
import { DayItemControls, daySrvContainerStyles } from "../Day";
import { PricingFormContext } from "../../TripPlanner";
import { DateTime } from "luxon";
import { useDispatch, useSelector } from "react-redux";
import { removeAdhocAccSrv } from "@src/actions/Project/TripPlanner/addhoc/accommodation";
import OverviewPrice from "../OverviewPrice";
import { boardMapping } from "@src/config/common";
import ServicePricingForm from "../ServicePricingForm";
import {
  getServicePricingSelector,
  srvPriceKeyCreator,
} from "@src/selectors/Project/TripPlanner/pricing";
import { isServiceFilterSelectedSelector } from "@src/selectors/Project/TripPlanner/service_filters";
import { BookChecker } from "../ItineraryOverviewCommon";
import { BookingMetadataBtn } from "../../Widgets/step_four/booking_metadata_btn";
import {
  daySrvBodyStyles,
  daySrvHeaderStyles,
  serviceTypeHeaderStyles,
} from "../Day/common";
import { ItinOverviewContext } from "../../Controllers/ItineraryOverview";
import { BookedTag, ReservationStatusTag } from "../service_panels";
import { extraTypeMapping } from "../Modals/products/addhoc/accommodation/formComponents";
import { srvIsBookedSelector } from "@src/selectors/Project/TripPlanner/booking_service_select";
import RatingPanel from "@src/components/common/RatingPanel";
import { useServiceCardExpansion } from "@src/pages/hooks";
import { InlineIcon } from "@iconify/react";

function dater(dt) {
  return DateTime.fromISO(dt).toLocaleString(DateTime.DATE_FULL);
}

const adhocAccDataStyles = createUseStyles({
  AdhocAccSrv: { display: "grid", gridGap: variables.half_gap },
  header: {
    ...daySrvHeaderStyles(),
    ...cardStyles.card,
    paddingLeft: variables.double_gap,
    borderRadius: `calc(${variables.normal_gap} / 2)`,
    background: variables.colors.services.accService,
  },
  headerActions: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    gridAutoFlow: "column",
  },
  srvType: serviceTypeHeaderStyles(),
  body: (props) => ({
    ...daySrvBodyStyles(props),
    ...cardStyles.card,
    paddingLeft: variables.double_gap,
    gridTemplateColumns: "1fr",
    background: variables.colors.services.accServiceBody,
    overflow: "hidden",
    height: "9rem",
  }),
  content: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    gridAutoRows: "max-content",
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  title: { fontWeight: "600", color: variables.colors.text.colored },
  ratingCntr: {
    display: "grid",
    gridColumn: "span 2",
    gridTemplateColumns: "max-content max-content",
    alignItems: "center",
    gridGap: variables.half_gap,
  },
  expandIconCntr: {
    display: "grid",
    placeContent: "center",
    width: "1.5rem",
    height: "1.5rem",
    borderRadius: "50%",
    background: variables.colors.easy.orange,
    padding: variables.half_gap,
    cursor: "pointer",
  },
  expandIcon: { fontSize: "large", color: variables.colors.text.light },
});
export const AdhocAccData = ({
  eventType,
  service,
  reservationMode,
  reservationLoading,
  reservationStatus,
  services_collapsed,
  toggleSelfCollapse,
}) => {
  const classes = adhocAccDataStyles();
  const currentStep = useSelector((state) => state.tripPlannerCurrentStep);

  const { isBooked, reservation_reference } = useSelector((state) =>
    srvIsBookedSelector(state, { reference: service.uid, srvType: "ADD" })
  );
  return (
    <div className={classes.AdhocAccSrv}>
      <div className={classes.header}>
        <span>
          Accommodation{eventType ? ` ${_.startCase(eventType)}` : ""}:{" "}
          {service.metadata.name}
        </span>
        <div className={classes.headerActions}>
          <span className={classes.srvType}>Type: Adhoc</span>
          {currentStep === 4 && !reservationMode && (
            <BookingMetadataBtn
              uid={service.uid}
              srv_type={service.service_type}
              booking_metadata={service.booking_metadata}
            />
          )}
          {reservationMode && (
            <ReservationStatusTag
              reservationLoading={reservationLoading}
              reservationStatus={reservationStatus}
            />
          )}
          {isBooked && (
            <BookedTag reservation_reference={reservation_reference} />
          )}
          <div className={classes.expandIconCntr}>
            <InlineIcon
              className={classes.expandIcon}
              icon={
                services_collapsed
                  ? "ri:arrow-down-s-line"
                  : "ri:arrow-up-s-line"
              }
              onClick={toggleSelfCollapse}
            />
          </div>
        </div>
      </div>
      {services_collapsed ? null : (
        <div className={classes.body}>
          <div className={classes.content}>
            <span className={classes.ratingCntr}>
              <span className={classes.title}>Rating: </span>{" "}
              {service.metadata.rating ? (
                <RatingPanel
                  rating={service.metadata.rating}
                  color="#F0B040"
                  fontSize="medium"
                  showEmpty={true}
                />
              ) : (
                "N/A"
              )}
            </span>
            <span>
              <span className={classes.title}>Check In: </span>
              {dater(service.check_in)}
            </span>
            <span>
              <span className={classes.title}>Check Out: </span>
              {dater(service.check_out)}
            </span>
            <span>
              <span className={classes.title}>Boards: </span>
              {[
                ...new Set(
                  service.rooms.map((room) => boardMapping[room.board])
                ),
              ].join(", ") || "N/A"}
            </span>
            <span>
              <span className={classes.title}>Address: </span>
              {service.metadata.address || "N/A"}
            </span>
            <span>
              <span className={classes.title}>Room Types: </span>
              {[
                ...new Set(service.rooms.map((room) => _.startCase(room.type))),
              ].join(", ") || "N/A"}
            </span>
            <span>
              <span className={classes.title}>Rooms: </span>
              {service.rooms
                .filter((r) => r.name)
                .map((room) => room.name)
                .join(", ") || "N/A"}
            </span>
            <span>
              <span className={classes.title}>Extras: </span>
              {[
                ...new Set(
                  (service?.extras ?? []).map(
                    (ext) => extraTypeMapping?.[ext.type]
                  )
                ),
              ].join(", ") || "N/A"}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
AdhocAccData.propTypes = {
  eventType: PropTypes.string,
  reservationMode: PropTypes.string,
  reservationLoading: PropTypes.bool,
  reservationStatus: PropTypes.string,
  service: PropTypes.object.isRequired,
  services_collapsed: PropTypes.bool.isRequired,
  toggleSelfCollapse: PropTypes.func,
};

const styles = createUseStyles({
  AdhocAccSrvContainer: (props) =>
    daySrvContainerStyles({ currentStep: props.currentStep }),
  AdhocAccSrv: {
    ...cardStyles.card,
    background: variables.colors.services.accService,
  },
  header: {
    ...daySrvHeaderStyles(),
    borderBottomColor: variables.colors.services.accServiceBorder,
  },
  headerActions: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    gridAutoFlow: "column",
  },
  srvType: serviceTypeHeaderStyles(),
  body: (props) => ({
    ...daySrvBodyStyles(props),
    gridTemplateColumns: "2fr 7fr",
    overflow: "hidden",
    background: variables.colors.services.accServiceBody,
    height: "9rem",
  }),
  img: {
    objectFit: "cover",
    width: "100%",
    borderRadius: "5px",
    height: "6rem",
    alignSelf: "center",
  },
  content: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    gridAutoRows: "max-content",
    gridTemplateColumns: "repeat(2, 1fr)",
    overflow: "auto",
  },
  span2: {
    gridColumn: "span 2",
  },
});
const AdhocAccSrv = ({ srv, eventType }) => {
  //TODO - CHECK THIS
  const dispatch = useDispatch();
  const onRemove = useCallback(({ srv_uid }) =>
    dispatch(removeAdhocAccSrv({ srv_uid }))
  );

  const { showPricingForm } = useContext(PricingFormContext);
  const { services_collapsed, toggleSelfCollapse } = useServiceCardExpansion();

  const { view_style } = useSelector((state) => state.tripPlannerOverviewStyle);
  const currentStep = useSelector((state) => state.tripPlannerCurrentStep);
  const serviceIsSelected = useSelector((state) =>
    isServiceFilterSelectedSelector(state, { service_type: "ACC" })
  );

  const { markup, gross_margin, flat_value } = useSelector((state) =>
    getServicePricingSelector(state, {
      key: srvPriceKeyCreator({ service: srv, service_type: "ACC" }),
    })
  );

  const price = srv.total_price;
  const currency = srv.currency;

  const classes = styles({ currentStep });

  const { onEditAddhocAccSrv: onEdit } = useContext(ItinOverviewContext);

  return !serviceIsSelected ? null : (
    <div className={classes.AdhocAccSrvContainer}>
      {currentStep === 4 ? (
        <BookChecker uid={srv.uid} srv_type={srv.service_type} />
      ) : !services_collapsed && view_style !== "day_by_day" ? (
        <DayItemControls
          onDeselect={() => onRemove({ srv_uid: srv.uid })}
          onEdit={() => onEdit({ uid: srv.uid })}
        />
      ) : (
        <div />
      )}
      <AdhocAccData
        service={srv}
        eventType={eventType}
        services_collapsed={services_collapsed}
        toggleSelfCollapse={toggleSelfCollapse}
      />
      {showPricingForm ? (
        <ServicePricingForm service_type="ACC___ADD" service={srv} />
      ) : services_collapsed ? (
        <div />
      ) : (
        (!eventType || eventType === "checkIn") && (
          <OverviewPrice
            searching={false}
            serviceType="ACC"
            price={price}
            currency={currency}
            markup={markup}
            gross_margin={gross_margin}
            flat_value={flat_value}
          />
        )
      )}
    </div>
  );
};
AdhocAccSrv.defaultProps = { srv: {} };
AdhocAccSrv.propTypes = {
  eventType: PropTypes.string,
  srv: PropTypes.object.isRequired,
};
export default AdhocAccSrv;
