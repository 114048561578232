import {
  TRIPPLANNER_QUOTATION_MODAL_HIDE,
  TRIPPLANNER_QUOTATION_MODAL_IDLE,
  TRIPPLANNER_QUOTATION_MODAL_LOADING,
  TRIPPLANNER_QUOTATION_MODAL_SHOW,
} from "../types/modal_types";

export const showQuotationPDFModal = () => {
  return { type: TRIPPLANNER_QUOTATION_MODAL_SHOW };
};

export const hideQuotationPDFModal = () => {
  return { type: TRIPPLANNER_QUOTATION_MODAL_HIDE };
};

export const loadingQuotationPDFModal = () => {
  return { type: TRIPPLANNER_QUOTATION_MODAL_LOADING };
};

export const idleQuotationPDFModal = () => {
  return { type: TRIPPLANNER_QUOTATION_MODAL_IDLE };
};
