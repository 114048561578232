import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import {
  Timeline,
  Panel,
  IconButton,
  Icon,
  ButtonGroup,
  Button,
  ButtonToolbar,
  Tag,
  PanelGroup,
} from "rsuite";
import SuIcon from "@src/style/icon/components/SuIcon";
import { addon_icon_mapping } from "@src/config/service";
import { humanizeDuration } from "@src/tools/date_tools";
import InteractiveMapHoc from "../InteractiveMapHoc";
import PriceDetails from "./PriceDetails";
import { AddOnMapHoc } from "@src/pages/Operation/CustomServices/AddOns/Components/AddOnMap";
import CancellationPolicyDetails from "./CancellationPolicy";
import {
  getAddOnDuration,
  getAddOnTotalTransitDuration,
  getAddOnTotalItemDuration,
  getAddOnTotalDistance,
  getAddOnItemTransitDuration,
  getAddOnItemTransitDistance,
} from "@src/selectors/MyLibrary/AddOns";
import { humanizeDistance } from "@src/map_tools/map";

import sanitizeHtml from "sanitize-html";
class TMap extends React.Component {
  constructor(props) {
    super(props);
  }
  async componentDidUpdate(pp) {
    const { recenter, center } = this.props;

    if (pp.recenter !== recenter) {
      await center();
    }
  }
  render() {
    return <div id="addondetailsmap"></div>;
  }
}

TMap.propTypes = {
  recenter: PropTypes.number.isRequired,
  center: PropTypes.func.isRequired,
};

const Map = InteractiveMapHoc(
  AddOnMapHoc(TMap, { smallMap: true, threeD: true }),
  { containerId: "addondetailsmap" }
);

export default class AddOnDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mediaCollapsed: false,
      imgMapDivider: "middle",
      mainContentMode: "description",
      reCenterVersion: 0,
    };

    this.icon_mapping = {
      ...addon_icon_mapping,
    };

    this.handleImgMapExpand = this.handleImgMapExpand.bind(this);
    this.handleImgMapCollapse = this.handleImgMapCollapse.bind(this);
    this.handleMainContentModeChange =
      this.handleMainContentModeChange.bind(this);
    this.handleToggleMediaCollapse = this.handleToggleMediaCollapse.bind(this);
  }
  handleMainContentModeChange(mode) {
    this.setState((p) => ({ ...p, mainContentMode: mode }));
  }
  handleImgMapExpand(type) {
    this.setState((p) => ({ ...p, imgMapDivider: type }));
  }
  handleImgMapCollapse(type) {
    this.setState((p) => ({ ...p, imgMapDivider: type }));
  }
  handleToggleMediaCollapse() {
    this.setState((p) => ({ ...p, mediaCollapsed: !p.mediaCollapsed }));
  }
  renderImages() {
    const { addon } = this.props;

    const images = _.flattenDeep(
      addon.items.map((item) => [
        ..._.get(item, "data.custom_images", [])
          .filter((img) => img.photo_sm_url)
          .map((img) => img.photo_sm_url),
        ..._.get(item, "data.image_set", []).map((image) => image.photo_sm_url),
      ])
    );

    return images.map((image, idx) => <img key={idx} src={image} />);
  }
  renderItems() {
    const { addon, setLang } = this.props;

    return (
      <Timeline className="AddOnDetails__main-content__items__timeline">
        {addon.items.map((item, idx) => (
          <React.Fragment key={idx}>
            <Timeline.Item dot={<SuIcon icon={this.icon_mapping[item.type]} />}>
              <Panel
                className="CustomPanel AddOnDetails__main-content__items__item"
                shaded
                collapsible
                header={
                  <h5>
                    {idx + 1}:{" "}
                    {_.get(item, `data.name_${setLang}`) || item.data.name_en}
                  </h5>
                }>
                <div className="AddOnDetails__main-content__items__item__top-content">
                  <span>
                    <strong>Basic Information</strong>
                  </span>
                  <p>
                    <span>
                      <strong>Arrival Time: </strong>
                      {item.arrival_time}
                    </span>
                    ,{" "}
                    <span>
                      <strong>Arrive By</strong>:{" "}
                      {_.startCase(item.transportation_mode)}
                    </span>
                    ,{" "}
                    <span>
                      <strong>Duration:</strong>{" "}
                      {humanizeDuration(item.visit_duration * 60 * 1000, {
                        abbrev: true,
                      })}
                    </span>
                  </p>
                </div>
                <div className="AddOnDetails__main-content__items__item__description">
                  {!item.description_en ? null : (
                    <React.Fragment>
                      <span>
                        <strong>Description</strong>
                      </span>
                      <p
                        dangerouslySetInnerHTML={{
                          __html:
                            sanitizeHtml(
                              _.get(item, `description_${setLang}`)
                            ) || sanitizeHtml(item.description_en),
                        }}></p>
                    </React.Fragment>
                  )}
                </div>
              </Panel>
            </Timeline.Item>
            <Timeline.Item key={`${idx}-transit`}>
              <span className="CustomPanel AddOnDetails__main-content__items__meta">
                <Icon icon="bus" size="2x" />
                <strong>Transit Duration:</strong>{" "}
                <span>
                  {humanizeDuration(getAddOnItemTransitDuration(item), {
                    abbrev: true,
                  })}
                </span>
                <Icon icon="road" size="2x" />
                <strong>Transit Distance:</strong>{" "}
                <span>
                  {humanizeDistance(getAddOnItemTransitDistance(item))}
                </span>
              </span>
            </Timeline.Item>
          </React.Fragment>
        ))}
      </Timeline>
    );
  }
  renderDescription() {
    const { addon, setLang } = this.props;
    return (
      <div className="AddOnDetails__main-content__description">
        <h5 className="AddOnDetails__main-content__description__header">
          {addon.title}
        </h5>
        <span className="AddOnDetails__main-content__description__meta">
          <Icon icon="clock-o" size="2x" /> <strong>Total Duration: </strong>
          <span>
            {humanizeDuration(getAddOnDuration(addon), { abbrev: true })}
          </span>
          <Icon icon="map-marker" size="2x" />{" "}
          <strong>Attraction Duration: </strong>
          <span>
            {humanizeDuration(getAddOnTotalItemDuration(addon), {
              abbrev: true,
            })}
          </span>
          <Icon icon="bus" size="2x" /> <strong>Transit Duration: </strong>
          <span>
            {humanizeDuration(getAddOnTotalTransitDuration(addon), {
              abbrev: true,
            })}
          </span>
          <Icon icon="road" size="2x" /> <strong>Total Distance: </strong>
          <span>{humanizeDistance(getAddOnTotalDistance(addon))}</span>
        </span>

        <div
          className="AddOnDetails__main-content__description__text"
          dangerouslySetInnerHTML={{
            __html:
              _.get(addon, `general_description.description_${setLang}`) ||
              addon.general_description.description_en,
          }}
        />
        <div className="AddOnDetails__main-content__description__categories">
          <h5>Categories</h5>
          <div className="AddOnDetails__main-content__description__categories__tags">
            {addon.categories.map((category, idx) => (
              <Tag key={idx} color="cyan">
                <strong>{_.startCase(category)}</strong>
              </Tag>
            ))}
          </div>
        </div>
        {
          // <div className="AddOnDetails__main-content__description__cxl-policy">
          //   <h5>Cancellation Policy</h5>
          //   <CancellationPolicyDetails cxl_policies={addon.cancellation} />
          // </div>
        }
      </div>
    );
  }
  renderPrice() {
    const { meetingGroupUid, addon, selected, onAddOnSelect, onAddOnDeselect } =
      this.props;

    const group_uid = meetingGroupUid ? meetingGroupUid.split("___")[0] : "";
    const price = addon.price?.[0];

    return (
      <Panel className="AddOnDetails__price-points" shaded>
        <h5>
          Total Price
          <PriceDetails
            serviceType="MI"
            price={_.get(price, "value", 0)}
            currency={_.get(price, "currency", "")}
            searching={false}
          />
        </h5>
        <h5>
          Per Person Price
          <PriceDetails
            serviceType="MI"
            price={_.get(price, "value_per_person", 0)}
            currency={_.get(price, "currency", "")}
            searching={false}
          />
        </h5>
        <IconButton
          icon={<Icon icon={selected ? "close" : "check"} />}
          color={selected ? "orange" : "blue"}
          size="lg"
          onClick={function () {
            selected
              ? onAddOnDeselect(addon.id)
              : onAddOnSelect({
                  ...addon,
                  meetingGroupUid: meetingGroupUid.split("___")[0],
                  meetingPoint: meetingGroupUid
                    .split("___")
                    .filter((gr, idx) => idx > 0)
                    .join("___"),
                });
          }}>
          {selected ? "Deselect" : "Select"}
        </IconButton>
      </Panel>
    );
  }
  renderInclusionsExclusions() {
    const { addon } = this.props;

    return (
      <div className="AddOnDetails__main-content__inclusions_exclusions">
        <div className="AddOnDetails__main-content__inclusions_exclusions__data">
          <h6>Inclusions</h6>
          <PanelGroup accordion bordered>
            {addon.inclusions.map((data, idx) => (
              <Panel
                key={`inclusions__${idx}`}
                header={<h6>{data.name}</h6>}
                defaultExpanded={idx == 0}>
                {data.description_en}
              </Panel>
            ))}
          </PanelGroup>
        </div>
        <div className="AddOnDetails__main-content__inclusions_exclusions__data">
          <h6>Exclusions</h6>
          <PanelGroup accordion bordered>
            {addon.exclusions.map((data, idx) => (
              <Panel
                key={`exclusions__${idx}`}
                header={<h6>{data.name}</h6>}
                defaultExpanded={idx == 0}>
                {data.description_en}
              </Panel>
            ))}
          </PanelGroup>
        </div>
      </div>
    );
  }
  render() {
    const { imgMapDivider, mediaCollapsed, mainContentMode, reCenterVersion } =
      this.state;
    const { addon } = this.props;

    return (
      <div
        className={`AddOnDetails ${
          imgMapDivider == "imgCollapsed"
            ? "AddOnDetails--imgcollapsed"
            : imgMapDivider == "imgExpanded"
            ? "AddOnDetails--imgexpanded"
            : ""
        } ${mediaCollapsed ? "AddOnDetails--media-collapsed" : ""}`}>
        <div className="AddOnDetails__images">{this.renderImages()}</div>
        <div className="AddOnDetails__map">
          {mediaCollapsed ? null : (
            <div className="AddOnDetails__map__top-expand-controls">
              <IconButton
                color="blue"
                size="sm"
                circle
                icon={<Icon icon="angle-up" />}
                onClick={() => {
                  if (imgMapDivider == "middle") {
                    this.handleImgMapExpand("imgCollapsed");
                  } else if (imgMapDivider == "imgExpanded") {
                    this.handleImgMapExpand("middle");
                  }
                }}
              />
              <IconButton
                color="blue"
                size="sm"
                circle
                icon={<Icon icon="angle-down" />}
                onClick={() => {
                  if (imgMapDivider == "middle") {
                    this.handleImgMapExpand("imgExpanded");
                  } else if (imgMapDivider == "imgCollapsed") {
                    this.handleImgMapExpand("middle");
                  }
                }}
              />
            </div>
          )}
          <Map
            shouldGeolocate={false}
            resizeFlag={imgMapDivider}
            items={addon.items}
            recenter={reCenterVersion}
          />
          <div className="AddOnDetails__map__media-collapse-toggle">
            <IconButton
              color="blue"
              size="sm"
              circle
              icon={<Icon icon={mediaCollapsed ? "angle-down" : "angle-up"} />}
              onClick={this.handleToggleMediaCollapse}
            />
          </div>
          {imgMapDivider == "imgExpanded" || mediaCollapsed ? null : (
            <div className="AddOnDetails__map__center-btn">
              <IconButton
                icon={<Icon icon="map-marker" />}
                size="sm"
                color="blue"
                onClick={() => {
                  this.setState((p) => ({
                    ...p,
                    reCenterVersion: (reCenterVersion || 0) + 1,
                  }));
                }}>
                Center
              </IconButton>
            </div>
          )}
        </div>
        <div className="AddOnDetails__main-content">
          <ButtonToolbar>
            <ButtonGroup justified>
              <Button
                appearance={
                  mainContentMode == "description" ? "primary" : "ghost"
                }
                onClick={() => this.handleMainContentModeChange("description")}>
                <strong>Description</strong>
              </Button>
              <Button
                appearance={mainContentMode == "items" ? "primary" : "ghost"}
                onClick={() => this.handleMainContentModeChange("items")}>
                <strong>Attractions</strong>
              </Button>
              <Button
                appearance={
                  mainContentMode == "inclusions_exclusions"
                    ? "primary"
                    : "ghost"
                }
                onClick={() =>
                  this.handleMainContentModeChange("inclusions_exclusions")
                }>
                <strong>Inclusions/Exclusions</strong>
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
          {mainContentMode == "items" ? (
            <div className="AddOnDetails__main-content__items">
              {this.renderItems()}
            </div>
          ) : mainContentMode == "description" ? (
            this.renderDescription()
          ) : (
            this.renderInclusionsExclusions()
          )}
        </div>
        {this.renderPrice()}
      </div>
    );
  }
}

AddOnDetails.propTypes = {
  setLang: PropTypes.string.isRequired,
  addon: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  meetingGroupUid: PropTypes.string,
  onAddOnSelect: PropTypes.func.isRequired,
  onAddOnDeselect: PropTypes.func.isRequired,
};
