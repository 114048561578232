import jwt_decode from "jwt-decode";
import moment from "moment";
import { getNewToken, signIn as signInAPI } from "@src/api";
import { signOut as signOutAPI } from "@src/api";
import _ from "lodash";
import { notifyCommonSuccess } from "@src/components/common/notifications/CommonSuccessNotification.js";
import { useQueryClient } from "@tanstack/react-query";

export const setLSUserData = (user_info, token_expiration) => {
  window.localStorage.setItem("rtt", token_expiration);
  window.localStorage.setItem("user_info", JSON.stringify(user_info));
};

export const clearLSUserData = () => {
  window.localStorage.removeItem("user_info");
  window.localStorage.removeItem("rtt");
};

export const getLSUserData = () => {
  return {
    user_info: window.localStorage.getItem("user_info"),
    rtt: window.localStorage.getItem("rtt"),
  };
};

export const decodeJwt = (user_info) => {
  const jwt_data = jwt_decode(user_info.id_token);

  if (jwt_data) {
    jwt_data.authorization = jwt_data["https://sisitrip.com"];
    delete jwt_data["https://sisitrip.com"];
  }

  return jwt_data || null;
};

export const signIn = async (username, password, cleanup = true) => {
  try {
    const res = await signInAPI({ password, username });
    const refreshTokenTime = moment().add(4, "minutes");

    if (cleanup) {
      clearLSUserData();
    }

    setLSUserData(res, refreshTokenTime.valueOf());
    return true;
  } catch (error) {
    return false;
  }
};

export const verifyUserInfo = () => {
  const userData = getLSUserData();

  if (!userData.user_info) {
    return { verified: false, reason: "user_not_authenticated" };
  }

  const jwt_data = jwt_decode(userData.user_info);

  if (moment().utc().isAfter(moment.unix(jwt_data.exp).utc())) {
    return { verified: false, reason: "expired_token" };
  }

  return { verified: true };
};

export const emailIsVerified = () => {
  const userData = getLSUserData();
  const user_info = userData.user_info;

  if (!user_info) {
    return false;
  }

  const jwt_data = jwt_decode(user_info) || {};
  return _.get(jwt_data, "email_verified", false);
};

export const refreshToken = async (recursive = true) => {
  const userData = getLSUserData();
  var user_info = userData.user_info;

  if (!user_info) {
    return false;
  }

  user_info = JSON.parse(user_info);
  const { refresh_token } = user_info;

  try {
    const res = await getNewToken({ refresh_token });
    const refreshTokenTime = moment().add(5, "minutes");
    window.localStorage.setItem("rtt", refreshTokenTime.valueOf());

    user_info.id_token = res.id_token;
    window.localStorage.setItem("user_info", JSON.stringify(user_info));
    return { refreshed: true, should_login: false };
  } catch (error) {
    if (recursive) {
      // Try once more
      return refreshToken(false);
    } else {
      if (_.get(error, "data.code") == "refreshtoken_expired") {
        return { refreshed: false, should_login: true };
      }
    }
    return { refreshed: false, should_login: false };
  }
};

export const authlessEndpoints = [
  "/api/passport/sign-up/",
  "/api/passport/sign-in/",
  "/api/passport/refresh-token/",
  "/api/passport/forget-password/",
  "/bid/dest_info_countries/",
  "/bid/dest_info_lvl1/",
];

export function getJWTData() {
  const userData = getLSUserData();

  const user_info = userData.user_info;
  if (!user_info) {
    return {};
  }

  const jwt_data = decodeJwt(JSON.parse(user_info));
  return jwt_data;
}

export const getJWTPermissions = () => {
  const userData = getLSUserData();

  const user_info = userData.user_info;
  if (!user_info) {
    return {};
  }

  const jwt_data = decodeJwt(JSON.parse(user_info));

  const { mark: { complete_user_profile, complete_company_profile } = {} } =
    jwt_data.authorization || {};

  const auth = {
    mark: _.omit(jwt_data.authorization.mark, ["company_id"]),
    permissions: [],
    role: "",
  };

  if (complete_user_profile && complete_company_profile) {
    auth.role = "admin";
  }

  return auth;
};

export const signOut = (cb) => {
  signOutAPI()
    .then(() => {
      if (typeof cb == "function") cb();
      notifyCommonSuccess("Successfully Sign Out.");

      clearLSUserData();
      clearSubscriptionValidityData();

      window.setTimeout(() => {
        window.location.reload();
      }, 1000);
    })
    .catch(() => {
      clearLSUserData();
      window.location.reload();
    });
};

// =================== USER COMPLETENESS VALIDATION ==================

export function useProfileAndCompanyCompleteness() {
  const queryClient = useQueryClient();
  const companyCompleteness = _.get(
    queryClient.getQueryData(["companyCompleteness"]),
    "data.completeness"
  );
  const userProfileCompleteness = _.get(
    queryClient.getQueryData(["profileCompleteness"]),
    "data.completeness"
  );

  const isProfileLoading = userProfileCompleteness === undefined;
  const isProfileComplete = userProfileCompleteness >= 75;
  const isCompanyProfileLoading = companyCompleteness === undefined;
  const isCompanyProfileComplete = companyCompleteness >= 75;

  return {
    isProfileLoading,
    isProfileComplete,
    isCompanyProfileLoading,
    isCompanyProfileComplete,
  };
}

export const isProfileComplete = () => {
  const userData = getLSUserData();
  if (!userData.user_info) {
    return false;
  }

  const jwt_data = decodeJwt(JSON.parse(userData.user_info));
  return _.get(jwt_data, "authorization.mark.complete_user_profile", false);
};

export const isCompanyProfileComplete = () => {
  const userData = getLSUserData();
  if (!userData.user_info) {
    return false;
  }

  const jwt_data = decodeJwt(JSON.parse(userData.user_info));
  return _.get(jwt_data, "authorization.mark.complete_company_profile", false);
};

// =================== SUBSCRIPTION VALIDITY TOOLS ===================
const clearSubscriptionValidityData = () => {
  window.localStorage.removeItem(window.btoa("subscription_validity"));
};

export const setSubscriptionValidityData = (data) => {
  window.localStorage.setItem(
    window.btoa("subscription_validity"),
    JSON.stringify(data)
  );
};

export const getSubscriptionValidityData = () => {
  const data = window.localStorage.getItem(
    window.btoa("subscription_validity")
  );
  if (data) {
    return JSON.parse(data);
  } else {
    return null;
  }
};

export const isSubscriptionPaid = () => {
  const data = getSubscriptionValidityData();

  if (!data) {
    return null;
  }

  return _.get(data, "payment.status", false) == "paid";
};
