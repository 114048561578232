import { Notification } from "rsuite";

import {
  // ======================== DEPOSIT BALANCE ACTIONS ========================
  ACCOUNT_STATEMENT_FETCHING_DEPOSIT_BALANCE,
  ACCOUNT_STATEMENT_FETCHED_DEPOSIT_BALANCE,
  ACCOUNT_STATEMENT_SET_DEPOSIT_BALANCE,
  // ==================== DEPOSIT ENTRIES ACTIONS ====================
  ACCOUNT_STATEMENT_SET_DEPOSIT_ENTRIES,
  ACCOUNT_STATEMENT_FETCHING_DEPOSIT_ENTRIES,
  ACCOUNT_STATEMENT_FETCHED_DEPOSIT_ENTRIES,
  ACCOUNT_STATEMENT_VIEW_TYPE,
  ACCOUNT_STATEMENT_STAT_DATA,
  ACCOUNT_STATEMENT_INIT_VIEW_TYPE,
  // ========================= DEPOSIT MODAL =========================
  ACCOUNT_STATEMENT_SHOW_DEPOSIT_MODAL,
  ACCOUNT_STATEMENT_HIDE_DEPOSIT_MODAL,
  ACCOUNT_STATEMENT_IDLE_DEPOSIT_MODAL,
  ACCOUNT_STATEMENT_LOADING_DEPOSIT_MODAL,
  //========================= GRAPH DATA===============================,
  //========================= FILTERS ================================
  ACCOUNT_STATEMENT_INITIALIZE_LIST_FILTER_FORM,
  ACCOUNT_STATEMENT_UPDATE_LIST_FILTER_FORM,
  ACCOUNT_STATEMENT_SHOW_SUB_ENTITY_DEPOSIT_MODAL,
  ACCOUNT_STATEMENT_HIDE_SUB_ENTITY_DEPOSIT_MODAL,
  ACCOUNT_STATEMENT_IDLE_SUB_ENTITY_DEPOSIT_MODAL,
  ACCOUNT_STATEMENT_LOADING_SUB_ENTITY_DEPOSIT_MODAL,
} from "./types";

import { getPagination } from "@src/tools/form_tools";
import { errorHandle as commonErrorHandle } from "@src/tools/common_tools";
import {
  createSubEntityDepositOrder,
  getDepositBalance,
  getDepositEntries,
  getLedgerEntryStats,
} from "@src/api";

import { initialAccountStatementFilterForm } from "@src/forms/ManagementConsole/AccountStatement";

// ============================= ACTIONS =============================
import { getPaymentMethodsList } from "../PaymentMethods/index";
import { updateCurrencyRate } from "@src/actions/Financial/index";

import React from "react";
import update from "immutability-helper";
import _ from "lodash";
import {
  notifyAmountZero,
  notifyFailedToCreatePaymentOrder,
} from "@src/components/common/Notifications";

// ========================= DEPOSIT BALANCE =========================
export const fetchingDepositBalance = () => {
  return { type: ACCOUNT_STATEMENT_FETCHING_DEPOSIT_BALANCE };
};

export const fetchedDepositBalance = () => {
  return { type: ACCOUNT_STATEMENT_FETCHED_DEPOSIT_BALANCE };
};

export const fetchDepositBalance = () => async (dispatch, getState) => {
  const state = getState();
  const currencies = state.financialAvailableCurrencies;
  const selectedCurrency = currencies.find((c) => c.selected);

  const { company_type: entity_type, company_id: entity_id } = state.userMeta;

  dispatch(fetchingDepositBalance());

  try {
    const {
      balance,
      total_credit,
      total_debit,
      currency,
    } = await getDepositBalance(`${entity_id}___${entity_type}`);

    if (currency.notation !== selectedCurrency.notation) {
      await dispatch(updateCurrencyRate(currency.notation));
    }

    dispatch(setDepositBalance(balance, total_credit, total_debit, currency));
  } catch (error) {
    Notification.error({
      title: "Deposit Balance Error",
      description: (
        <p>
          Failed to fetch current <strong>Deposit Balance</strong>. We are sorry
          for the inconvenience.
        </p>
      ),
    });
  }

  dispatch(fetchedDepositBalance());
};

export const setDepositBalance = (
  balance,
  total_credit,
  total_debit,
  currency
) => {
  return {
    type: ACCOUNT_STATEMENT_SET_DEPOSIT_BALANCE,
    balance,
    total_credit,
    total_debit,
    currency,
  };
};

// ========================= DEPOSIT ENTRIES =========================

export const fetchingDepositEntries = () => {
  return { type: ACCOUNT_STATEMENT_FETCHING_DEPOSIT_ENTRIES };
};

export const fetchedDepositEntries = () => {
  return { type: ACCOUNT_STATEMENT_FETCHED_DEPOSIT_ENTRIES };
};

export const fetchAccountStatementDataList = () => async (
  dispatch,
  getState
) => {
  const state = getState();
  const pagination = getPagination(state.accountStatementData);

  await dispatch(fetchingDepositEntries());
  try {
    const data = await getDepositEntries({ ...pagination });

    await dispatch(
      fetchAccountStatementData({
        page: pagination.page,
        limit: pagination.limit,
        ...data,
      })
    );
  } catch (error) {
    commonErrorHandle({ error });
  }

  dispatch(fetchedDepositEntries());
};

export const fetchAccountStatementData = (data) => {
  return {
    type: ACCOUNT_STATEMENT_SET_DEPOSIT_ENTRIES,
    data: data,
  };
};

export const modifyAccountStatementPage = (page) => (dispatch, getState) => {
  const state = getState();
  const accountStatementData = update(state.accountStatementData, {
    page: { $set: page },
  });

  dispatch(
    fetchAccountStatementData({
      ...accountStatementData,
    })
  );
  dispatch(fetchAccountStatementDataList());
};

export const modifyAccountStatementLimit = (limit) => (dispatch, getState) => {
  const state = getState();

  const accountStatementData = update(state.accountStatementData, {
    limit: { $set: limit },
    page: { $set: 1 },
  });

  dispatch(fetchAccountStatementData({ ...accountStatementData }));
  dispatch(fetchAccountStatementDataList());
};

// ========================== DEPOSIT MODAL ==========================

export const showDepositModal = (mode) => (dispatch) => {
  dispatch(getPaymentMethodsList());
  dispatch(showDepositModalData(mode));
};

export const showDepositModalData = (mode) => {
  return {
    type: ACCOUNT_STATEMENT_SHOW_DEPOSIT_MODAL,
    mode,
  };
};

export const hideDepositModal = () => {
  return { type: ACCOUNT_STATEMENT_HIDE_DEPOSIT_MODAL };
};

export const loadingDepositModal = () => {
  return { type: ACCOUNT_STATEMENT_LOADING_DEPOSIT_MODAL };
};

export const idleDepositModal = () => {
  return { type: ACCOUNT_STATEMENT_IDLE_DEPOSIT_MODAL };
};

// ============================ STATISTICS ===========================

export const changeAccountStatementViewType = (value) => async (
  dispatch,
  getState
) => {
  const state = getState();
  const status = state.accountStatementTagType;
  await dispatch(accountStatementViewType(value));
  await dispatch(
    fetchAccountStatementStat({
      created_period: value,
      status: status,
    })
  );
  await dispatch(
    fetchAccountStatementDataList({
      created_period: value,
      status: status,
    })
  );
};

export const accountStatementViewType = (data) => {
  return {
    type: ACCOUNT_STATEMENT_VIEW_TYPE,
    data,
  };
};

export const fetchAccountStatementStat = (params) => async (dispatch) => {
  try {
    const data = await getLedgerEntryStats(params);
    await dispatch(accountStatementStat(data));
  } catch (error) {
    commonErrorHandle({ error });
  }
};

export const accountStatementStat = (data) => {
  return {
    type: ACCOUNT_STATEMENT_STAT_DATA,
    data,
  };
};

export const initAccountStatementViewType = () => {
  return {
    type: ACCOUNT_STATEMENT_INIT_VIEW_TYPE,
  };
};

// ========================= FILTER RELATION =========================
export const initializeAccountStatementFilterForm = () => (
  dispatch,
  getState
) => {
  const serviceOptions = _.get(
    getState().travelServiceOptions,
    "service_type.choices"
  );
  (serviceOptions || []).forEach((item) => {
    item.label = item.display_name;
  });

  const res = update(initialAccountStatementFilterForm, {
    service: { data: { $set: serviceOptions } },
  });
  dispatch(initializeAccountStatementFilter(res));
};

export const initializeAccountStatementFilter = (data) => {
  return {
    type: ACCOUNT_STATEMENT_INITIALIZE_LIST_FILTER_FORM,
    data,
  };
};

export const updateAccountStatementListFilterForm = (payload) => {
  return { type: ACCOUNT_STATEMENT_UPDATE_LIST_FILTER_FORM, data: payload };
};

// ===================== SUB ENTITY DEPOSIT ORDER ====================
export const showSubEntityDepositModal = () => {
  return { type: ACCOUNT_STATEMENT_SHOW_SUB_ENTITY_DEPOSIT_MODAL };
};

export const hideSubEntityDepositModal = () => {
  return { type: ACCOUNT_STATEMENT_HIDE_SUB_ENTITY_DEPOSIT_MODAL };
};

export const setIdleSubEntityDepositModal = () => {
  return { type: ACCOUNT_STATEMENT_IDLE_SUB_ENTITY_DEPOSIT_MODAL };
};

export const setLoadingSubEntityDepositModal = () => {
  return { type: ACCOUNT_STATEMENT_LOADING_SUB_ENTITY_DEPOSIT_MODAL };
};

export const addSubEntityDeposit = (amount) => async (dispatch, getState) => {
  if (parseFloat(amount) === 0) {
    notifyAmountZero();
    return;
  }

  const currency = getState().userMeta.company_currency;

  dispatch(setLoadingSubEntityDepositModal());

  try {
    const result = await createSubEntityDepositOrder({
      amount,
      currency,
    });
    window.location.replace(result.url);
  } catch (error) {
    notifyFailedToCreatePaymentOrder();
    dispatch(setIdleSubEntityDepositModal());
  }
};
