import { submitProcessor, entityError400Handle } from "@src/actions/tools";
import {
  filterEmptyValue,
  errorHandle as commonErrorHandle,
  isEmptyObj,
} from "@src/tools/common_tools";

import { notifyCommonError } from "@src/components/common/notifications/CommonErrorNotification.js";
import { notifyCommonSuccess } from "@src/components/common/notifications/CommonSuccessNotification.js";

import update from "immutability-helper";
import _ from "lodash";

import {
  fetchCompleteAddressFactory,
  fetchAddressPointDetailFactory,
  changeUploadImgFactory,
} from "./../../ActionFactory";

import {
  COMPANY_START_FETCH_PROFILE,
  COMPANY_END_FETCH_PROFILE,
  COMPANY_SET_PROFILE_DATA,
  COMPANY_SHOW_BASE_DATA_EDIT_FORM,
  COMPANY_HIDE_BASE_DATA_EDIT_FORM,
  COMPANY_EDIT_BASE_DATA,
  COMPANY_VALIDATE_BASE_DATA_EDIT_FORM,
  COMPANY_LOADING_BASE_DATA_EDIT_FORM,
  COMPANY_IDLE_BASE_DATA_EDIT_FORM,
  INITIALIZE_BASE_DATA_EDIT_FORM,
  COMPANY_VIEW_NATURE_OF_BIZ,
  COMPANY_EDIT_NATURE_OF_BIZ,
  COMPANY_INITIALIZE_NATURE_DATA_FORM,
  COMPANY_CLEAN_BASE_EDIT_FORM_ERRORS,
  COMPANY_EDIT_NATURE_OF_BIZ_DATA,
  COMPANY_SUBMIT_NATURE_OF_BIZ_DATA,
  COMPANY_EDIT_LICENSE_CODE,
  COMPANY_TOGGLE_BILLING_ADDRESS,
  COMPANY_FETCH_OPTIONS,
  COMPANY_INITIALIZE_TRAVEL_SERVICE_FORM,
  // ========================== IMAGE UPLOAD =========================
  COMPANY_UPLOADED_PREFERENCE_IMAGE,
  COMPANY_BEGIN_UPLOAD_IMG_LINK,
  COMPANY_ENG_UPLOAD_IMG_LINK,
  COMPANY_FETCH_ADDRESS_POINT,
  COMPANY_INITIAL_ADDRESS_POINT,
  COMPANY_TOGGLE_VERIFY_ADDRESS,
  COMPANY_BEGIN_FETCH_ADDRESS_DATA,
  COMPANY_END_FETCH_ADDRESS_DATA,
  COMPANY_CLOSE_VERIFY_ADDRESS,
} from "./types";

import {
  initialCompanyBaseDataForm,
  initialCompanyNatureOfBizForm,
  travelServicesFrom,
} from "@src/forms/Admin/Company/companyProfileForms";

import {
  fetchCompanyProfile as fetchCompanyProfileAPI,
  submitCompanyProfile as submitCompanyProfileAPI,
  getCompanyOptions,
  uploadImgLink,
} from "@src/api";

import React from "react";
import TransTxt from "@src/components/common/SxFormatMessage";

export const startFetchProfile = () => {
  return { type: COMPANY_START_FETCH_PROFILE };
};

export const endFetchProfile = () => {
  return { type: COMPANY_END_FETCH_PROFILE };
};

export const setCompanyProfileData = (data) => {
  const addresses = _.groupBy(data.address || [], "address_type");
  data.billingAddress = _.get(addresses, "BI.0") || {};
  data.postalAddress = _.get(addresses, "BU.0") || {};
  data.contactPerson = _.get(data, "contact_person.0") || {};
  return { type: COMPANY_SET_PROFILE_DATA, data: data };
};

export const fetchCompanyProfile = () => async (dispatch) => {
  dispatch(startFetchProfile());
  try {
    const data = await fetchCompanyProfileAPI();
    data.original_image = data.image;
    dispatch(setCompanyProfileData(data));
    dispatch(initialCompanyTravelServiceForm({ value: data.travel_services }));
  } catch (error) {
    commonErrorHandle({ error });
  } finally {
    dispatch(endFetchProfile());
  }
};

export const initialCompanyTravelServiceForm = (data) => {
  return {
    type: COMPANY_INITIALIZE_TRAVEL_SERVICE_FORM,
    data,
  };
};

export const initialOptions = {
  status: {
    choices: [
      { value: "AC", display_name: <TransTxt id="active" startCase /> },
      {
        value: "IN",
        display_name: <TransTxt id="inactive" startCase />,
      },
      {
        value: "CA",
        display_name: <TransTxt id="candidate" startCase />,
      },
    ],
  },
};

export const fetchCompanyOptions = (langCode) => async (dispatch, getState) => {
  if (typeof langCode == "undefined") {
    return;
  }

  if (getState().companyOptions.langCode == langCode) {
    return;
  }

  try {
    const data = await getCompanyOptions(langCode);
    const options = _.merge({}, data.actions.PUT, initialOptions);
    await dispatch(
      companyOptionsData({
        ...options,
        langCode,
      })
    );
  } catch (error) {
    commonErrorHandle({ error });
  }
};

export const companyOptionsData = (data) => {
  return {
    type: COMPANY_FETCH_OPTIONS,
    data,
  };
};

// Base data edit section

export const showCompanyBaseEditForm = () => (dispatch, getState) => {
  const profile = getState().companyProfile;

  const address =
    profile.billingAddress ||
    _.groupBy(profile.address, "address_type", {}).BU[0];
  const postalAddress =
    profile.postalAddress ||
    _.groupBy(profile.address, "address_type", {}).BI[0];
  const companyContactPerson =
    profile.contactPerson || profile.contact_person[0];

  const formatCountryCodeList = getState().formatCountryCodeList;

  formatCountryCodeList.forEach((item) => {
    if (item.code && item.code == address.country_code) {
      address.country_code = item.value;
    } else {
      return null;
    }
  });
  formatCountryCodeList.forEach((item) => {
    if (item.code && item.code == postalAddress.country_code) {
      postalAddress.country_code = item.value;
    } else {
      return null;
    }
  });

  // add currency logic
  const userMeta = getState().userMeta;
  let required = false;
  if (userMeta.company_type !== "member") {
    required = false;
  } else {
    required = true;
  }
  const data = update(initialCompanyBaseDataForm, {
    name: { value: { $set: profile.name } },
    legal_title: { value: { $set: profile.legal_title } },
    registration_code: { value: { $set: profile.registration_code } },
    founding_year: { value: { $set: profile.founding_year } },
    first_name: { value: { $set: _.get(companyContactPerson, "first_name") } },
    last_name: { value: { $set: _.get(companyContactPerson, "last_name") } },
    email: { value: { $set: profile.email } },
    mobile_phone: { value: { $set: profile.mobile_phone } },
    phone: { value: { $set: profile.phone } },
    url: { value: { $set: profile.url } },
    brief_description_en: { value: { $set: profile.brief_description_en } },
    brief_description_cn: { value: { $set: profile.brief_description_cn } },
    street_number: { value: { $set: address.street_number } },
    street: { value: { $set: address.street } },
    country_code: { value: { $set: address.country_code } },
    region: { value: { $set: address.region } },
    city: { value: { $set: address.city } },
    post_code: { value: { $set: address.post_code } },
    postal_street_number: { value: { $set: postalAddress.street_number } },
    postal_street: { value: { $set: postalAddress.street } },
    postal_country_code: { value: { $set: postalAddress.country_code } },
    postal_region: { value: { $set: postalAddress.region } },
    postal_city: { value: { $set: postalAddress.city } },
    postal_post_code: { value: { $set: postalAddress.post_code } },
    original_image: {
      value: {
        $set: profile.logo ? profile.logo.photo_sm_url : "",
      },
    },
    weekdays: {
      value: {
        $set: profile.weekdays || [
          false,
          false,
          false,
          false,
          false,
          false,
          false,
        ],
      },
    },
    timezone: { value: { $set: profile.timezone } },
    working_time_from: {
      value: {
        $set: parseInt((profile.working_time_from || "0").substr(0, 2)),
      },
    },
    working_time_to: {
      value: {
        $set: parseInt((profile.working_time_to || "0").substr(0, 2)),
      },
    },
    currency: {
      value: {
        $set: profile.currency,
      },
      required: { $set: required },
    },
  });

  const point = { lat: postalAddress.lat || 0, lng: postalAddress.lng || 0 };
  dispatch(initializeBaseDataEditForm(data));
  dispatch(initialCompanyAddressPoint(point));
  dispatch(closeCompanyVerifyAddress());

  let isSame =
    JSON.stringify({
      street_number: address.street_number,
      street: address.street,
      country_code: address.country_code,
      region: address.region,
      city: address.city,
      post_code: address.post_code,
    }) ==
    JSON.stringify({
      street_number: postalAddress.street_number,
      street: postalAddress.street,
      country_code: postalAddress.country_code,
      region: postalAddress.region,
      city: postalAddress.city,
      post_code: postalAddress.post_code,
    });

  if (
    !address.street_number &&
    !address.street &&
    !address.country_code &&
    !address.region &&
    !address.city &&
    !address.post_code
  ) {
    isSame = false;
  }
  dispatch(companyShowBaseDataEditFormIsSame(isSame));
};

export const initialCompanyAddressPoint = (data) => {
  return {
    type: COMPANY_INITIAL_ADDRESS_POINT,
    data,
  };
};

export const closeCompanyVerifyAddress = () => {
  return {
    type: COMPANY_CLOSE_VERIFY_ADDRESS,
  };
};

export const companyShowBaseDataEditFormIsSame = (data) => {
  return {
    type: COMPANY_SHOW_BASE_DATA_EDIT_FORM,
    data,
  };
};

export const initializeBaseDataEditForm = (data) => {
  return { type: INITIALIZE_BASE_DATA_EDIT_FORM, data: data };
};

export const hideCompanyBaseEditForm = () => {
  return { type: COMPANY_HIDE_BASE_DATA_EDIT_FORM };
};

export const editCompanyBaseDataForm = (data) => {
  return { type: COMPANY_EDIT_BASE_DATA, data: data };
};

export const validateCompanyBaseDataEditForm = () => async (
  dispatch,
  getState
) => {
  const form = _.cloneDeep(getState().companyBaseDataForm);

  // add protocol to validate,but no send to backend
  if (form.url.value) {
    form.url.value = "http://" + form.url.value;
  }

  await dispatch(validateCompanyBaseDataEditFormData(form));
  await dispatch(submitCompanyBaseDataEditForm());
};

export const validateCompanyBaseDataEditFormData = (data) => {
  return {
    type: COMPANY_VALIDATE_BASE_DATA_EDIT_FORM,
    data,
  };
};

export const loadingCompanyBaseDataEditForm = () => {
  return { type: COMPANY_LOADING_BASE_DATA_EDIT_FORM };
};

export const idleCompanyBaseDataEditForm = () => {
  return { type: COMPANY_IDLE_BASE_DATA_EDIT_FORM };
};

export const submitCompanyBaseDataEditForm = () => (dispatch, getState) => {
  const state = _.cloneDeep(getState());
  const companyProfile = state.companyProfile;
  const verifyStatus = state.isVerifyCompanyPostalAddress;
  const userType = state.userMeta;
  const type = _.get(userType, "company_type");

  const file = state.companyBaseDataForm.original_image.fileName;
  const formData = new FormData();

  formData.append("original_image", file);
  formData.append("content_type", type);
  formData.append("object_id", state.companyProfile.id);

  let country_code = "";
  if (state.companyBaseDataForm.country_code.value) {
    country_code = state.companyBaseDataForm.country_code.value.split("-")[0];
  }
  const billingAddressData = {
    street_number: state.companyBaseDataForm.street_number.value,
    street: state.companyBaseDataForm.street.value,
    country_code: country_code,
    region: state.companyBaseDataForm.region.value,
    city: state.companyBaseDataForm.city.value,
    post_code: state.companyBaseDataForm.post_code.value,
  };

  let postal_country_code = "";
  if (state.companyBaseDataForm.postal_country_code.value) {
    postal_country_code = state.companyBaseDataForm.postal_country_code.value.split(
      "-"
    )[0];
  }
  const postalAddressData = {
    street_number: state.companyBaseDataForm.postal_street_number.value,
    street: state.companyBaseDataForm.postal_street.value,
    country_code: postal_country_code,
    region: state.companyBaseDataForm.postal_region.value,
    city: state.companyBaseDataForm.postal_city.value,
    post_code: state.companyBaseDataForm.postal_post_code.value,
    lat: state.companyBaseDataForm.lat.value || state.companyAddressPoint.lat,
    lng: state.companyBaseDataForm.lng.value || state.companyAddressPoint.lng,
  };

  if (state.companyBaseDataFormErrors.valid == false) {
    for (const key in state.companyBaseDataFormErrors) {
      if (state.companyBaseDataFormErrors[key].hasError) {
        notifyCommonError(state.companyBaseDataFormErrors[key].errorMessage);
        return null;
      }
    }
    return null;
  }

  if (
    !state.companyBaseDataForm.lat.value &&
    !state.companyBaseDataForm.lng.value &&
    !state.companyAddressPoint.lat &&
    !state.companyAddressPoint.lng
  ) {
    return notifyCommonError("Please Geolocate the map to get exact location");
  }

  if (!verifyStatus) {
    return notifyCommonError("Please Verify the Address");
  }

  if (!state.companyBaseDataForm.currency.value) {
    return notifyCommonError("Currency is required");
  }

  let entityData = {
    name: state.companyBaseDataForm.name.value,
    legal_title: state.companyBaseDataForm.legal_title.value,
    registration_code: state.companyBaseDataForm.registration_code.value,
    founding_year: state.companyBaseDataForm.founding_year.value,
    first_name: state.companyBaseDataForm.first_name.value,
    last_name: state.companyBaseDataForm.last_name.value,
    email: state.companyBaseDataForm.email.value,
    phone: state.companyBaseDataForm.phone.value,
    mobile_phone: state.companyBaseDataForm.mobile_phone.value,
    url: state.companyBaseDataForm.url.value,
    weekdays: state.companyBaseDataForm.weekdays.value,
    timezone: state.companyBaseDataForm.timezone.value,
    working_time_from:
      state.companyBaseDataForm.working_time_from.value + ":00",
    working_time_to: state.companyBaseDataForm.working_time_to.value + ":00",
    brief_description_en: state.companyBaseDataForm.brief_description_en.value,
    brief_description_cn: state.companyBaseDataForm.brief_description_cn.value,
    currency: state.companyBaseDataForm.currency.value,
  };

  // address

  const address = companyProfile.address;

  if (_.get(companyProfile, "billingAddress.id")) {
    _.merge(
      _.find(address, { id: _.get(companyProfile, "billingAddress.id") }),
      billingAddressData
    );
  } else {
    if (!isEmptyObj(billingAddressData)) {
      _.remove(address, { address_type: "BI" });
      billingAddressData.address_type = "BI";
      address.push(filterEmptyValue(billingAddressData));
    }
  }

  if (_.get(companyProfile, "postalAddress.id")) {
    _.merge(
      _.find(address, { id: _.get(companyProfile, "postalAddress.id") }),
      postalAddressData
    );
  } else {
    if (!isEmptyObj(postalAddressData)) {
      _.remove(address, { address_type: "BU" });
      postalAddressData.address_type = "BU";
      address.push(filterEmptyValue(postalAddressData));
    }
  }

  entityData.address = address;

  //contact person
  const contactPersonData = {
    first_name: state.companyBaseDataForm.first_name.value,
    last_name: state.companyBaseDataForm.last_name.value,
  };

  if (_.get(companyProfile, "contactPerson.id")) {
    _.merge(
      _.find(companyProfile.contact_person, {
        id: _.get(companyProfile, "contactPerson.id"),
      }),
      contactPersonData
    );
  } else {
    companyProfile.contact_person.push(filterEmptyValue(contactPersonData));
  }
  entityData.contact_person = companyProfile.contact_person;

  entityData = filterEmptyValue(entityData);
  dispatch(loadingCompanyBaseDataEditForm());

  if (
    file !== undefined &&
    !(Object.prototype.toString.call(file) === "[object Object]")
  ) {
    uploadImgLink(formData, {
      onUploadProgress(ProgressEvent) {
        console.log(ProgressEvent);
      },
    })
      .then((res) => {
        const original_image = res.original_image;
        dispatch({
          type: COMPANY_UPLOADED_PREFERENCE_IMAGE,
          data: { name: "original_image", value: original_image },
        });
        dispatch(
          submitCompanyProfileForm(entityData, state.companyBaseDataForm)
        );
      })
      .catch((error) => {
        error.ovrdErrHandle && error.ovrdErrHandle();
        notifyCommonError("Upload img failed, Please try again");
        dispatch(idleCompanyBaseDataEditForm());
      });
  } else {
    dispatch(submitCompanyProfileForm(entityData, state.companyBaseDataForm));
  }

  return null;
};

export const submitCompanyProfileForm = (entityData, form) => async (
  dispatch,
  getState
) => {
  try {
    const data = await submitCompanyProfileAPI(
      getState().companyProfile.id,
      entityData,
      "patch"
    );
    dispatch(setCompanyProfileData(data));
    dispatch(hideCompanyBaseEditForm());
    notifyCommonSuccess("Successfully updated your profile.");
  } catch (error) {
    error.ovrdErrHandle && error.ovrdErrHandle();
    const error400Handle = entityError400Handle(entityData, error.data, form);
    commonErrorHandle({ error, error400Handle });
  } finally {
    dispatch(idleCompanyBaseDataEditForm());
  }
};

// Nature of Biz section.
export const viewCompanyNatureSection = () => {
  return { type: COMPANY_VIEW_NATURE_OF_BIZ };
};

export const editCompanyNatureSection = (initForm = true) => (
  dispatch,
  getState
) => {
  if (initForm) {
    const profile = getState().companyProfile;

    const data = update(initialCompanyNatureOfBizForm, {
      entity_type: { value: { $set: profile.entity_type || [] } },
      expertise_type: { value: { $set: profile.expertise_type || [] } },
      license_type: { value: { $set: profile.license_type || [] } },
    });

    dispatch(initializeNatureDataForm(data));
  }
  dispatch(editCompanyNature());
};

export const editCompanyNature = () => {
  return {
    type: COMPANY_EDIT_NATURE_OF_BIZ,
  };
};
export const initializeNatureDataForm = (data) => {
  return { type: COMPANY_INITIALIZE_NATURE_DATA_FORM, data: data };
};

export const editBaseNatureDataForm = (data) => {
  return { type: COMPANY_EDIT_NATURE_OF_BIZ_DATA, data: data };
};

export const submitCompanyNatureOfBizData = () => async (
  dispatch,
  getState
) => {
  dispatch({ type: COMPANY_SUBMIT_NATURE_OF_BIZ_DATA });

  const state = getState();
  const userType = state.userMeta;
  const type = _.get(userType, "company_type");
  const entityId = state.companyProfile.id;
  const result = submitProcessor(
    state,
    "companyNatureOfBizForm",
    initialCompanyNatureOfBizForm
  );

  if (result.hasError && type !== "independent_agent") {
    dispatch(editCompanyNatureSection(false));
    result.errorMsgs.forEach((e) => notifyCommonError(e.msg));
    return null;
  }

  try {
    const data = await submitCompanyProfileAPI(
      entityId,
      result.payload,
      "patch"
    );
    dispatch(setCompanyProfileData(data));
    notifyCommonSuccess("Successfully updated Nature of Business.", 5000);
  } catch (error) {
    notifyCommonError(
      "Error submitting Nature of Business data. Please try again."
    );
    error.ovrdErrHandle && error.ovrdErrHandle();
  } finally {
    dispatch(viewCompanyNatureSection());
  }

  return null;
};

export const cleanCompanyBaseEditFormErrors = () => {
  return { type: COMPANY_CLEAN_BASE_EDIT_FORM_ERRORS };
};

export const companyLicenseCodeEdit = (data) => {
  return { type: COMPANY_EDIT_LICENSE_CODE, data: data };
};

// WIP: This is a work in progress and needs improvement.
export const submitCompanyProfile = (
  fields,
  uploadingAction,
  errorAction = [],
  successAction,
  alert = true
) => async (dispatch, getState) => {
  dispatch(uploadingAction());
  const state = getState();
  const result = submitProcessor(
    state,
    "companyTravelServicesForm",
    travelServicesFrom,
    fields
  );

  if (result.hasError) {
    errorAction.forEach((action) => dispatch(action(result)));
    if (alert) {
      result.errorMsgs.forEach((e) => notifyCommonError(e.msg));
    }
    return null;
  }
  const req = {
    ...result.payload,
    main_service: state.companyMainServicesForm.value,
  };

  try {
    const data = await submitCompanyProfileAPI(
      state.companyProfile.id,
      req,
      "patch"
    );
    dispatch({ type: COMPANY_SET_PROFILE_DATA, data: data });
    dispatch(successAction());
    notifyCommonSuccess("Successfully updated your profile.", 5000);
  } catch (error) {
    error.ovrdErrHandle && error.ovrdErrHandle();
    commonErrorHandle({ error });
    dispatch(errorAction());
  }

  return null;
};

export const synchronizeCompanyPostalAddress = (checked) => (
  dispatch,
  getState
) => {
  const form = getState().companyBaseDataForm;
  const data = update(form, {
    street_number: { value: { $set: form.postal_street_number.value } },
    street: { value: { $set: form.postal_street.value } },
    country_code: { value: { $set: form.postal_country_code.value } },
    region: { value: { $set: form.postal_region.value } },
    city: { value: { $set: form.postal_city.value } },
    post_code: { value: { $set: form.postal_post_code.value } },
  });
  dispatch(toggleBillingAddress(checked));
  if (checked) {
    dispatch(initializeBaseDataEditForm(data));
  }
};

export const toggleBillingAddress = (checked) => {
  return {
    type: COMPANY_TOGGLE_BILLING_ADDRESS,
    data: checked,
  };
};

// img relation

export const changeCompanyImg = (data) => (dispatch) => {
  dispatch(changeUploadImgFactory(data, COMPANY_UPLOADED_PREFERENCE_IMAGE));
};

export const beginUploadCompanyImg = () => {
  return { type: COMPANY_BEGIN_UPLOAD_IMG_LINK };
};

export const endUploadCompanyImg = () => {
  return { type: COMPANY_ENG_UPLOAD_IMG_LINK };
};

//fetchGeolocation
export const beginCompanyAddressPoint = () => {
  return { type: COMPANY_BEGIN_FETCH_ADDRESS_DATA };
};

export const endCompanyAddressPoint = () => {
  return { type: COMPANY_END_FETCH_ADDRESS_DATA };
};

export const fetchCompanyAddressPoint = () => (dispatch, getState) => {
  const state = getState();
  const address = state.companyBaseDataForm;
  dispatch(
    fetchCompleteAddressFactory(address, fetchCompanyAddressPointDetail)
  );
};

export const fetchCompanyAddressPointDetail = (data) => (dispatch) => {
  dispatch(
    fetchAddressPointDetailFactory(
      data,
      COMPANY_FETCH_ADDRESS_POINT,
      editCompanyBaseDataForm,
      beginCompanyAddressPoint,
      endCompanyAddressPoint
    )
  );
};

export const verifyCompanyPostalAddress = (checked) => {
  return {
    type: COMPANY_TOGGLE_VERIFY_ADDRESS,
    data: checked,
  };
};
