import qs from "qs";
import service from "@src/api/request";

// =========================== BIDING SETUP ==========================

export function fetchBiddingTripPlan(id) {
  return service({
    url: `/bid/trip-plan/${id}`,
    method: "get",
  });
}

// =========================== BID REQUEST ===========================

export const fetchBidRequest = (id) => {
  return service({
    url: `/bid/bid-request/${id}/`,
    method: "get",
  });
};

export const sendBiddingRequest = (id, payload) => {
  return service({
    url: `/bid/bid-request/${id}/`,
    method: "patch",
    data: payload,
  });
};

// ============================ BID OFFER ============================

export const createBiddingOffer = (payload) => {
  return service({ url: `/bid/bid-off/`, method: "post", data: payload });
};

export const fetchBiddingOffers = (ids = [], bid_request_ids = []) => {
  if (ids.length) {
    return service({
      url: `/bid/bid-off/`,
      method: "get",
      params: { id__in: ids.toString() },
    });
  }

  if (bid_request_ids.length) {
    return service({
      url: `/bid/bid-off/`,
      method: "get",
      params: { bid_request_id__in: bid_request_ids.toString() },
    });
  }
  return null;
};

export const fetchBiddingOffer = (id) => {
  return service({ url: `/bid/bid-off/${id}`, method: "get" });
};

export const updateBiddingOffer = (id, payload) => {
  return service({
    url: `/bid/bid-off/${id}/`,
    method: "patch",
    data: payload,
  });
};

export function fetchAirport(params) {
  return service({
    url: `/bid/simplified-airport/`,
    method: "get",
    params: params,
  });
}

export function fetchAirline(params) {
  return service({
    url: `/bid/simplified-airline/`,
    method: "get",
    params: params,
  });
}

export function fetchAircraft(params) {
  return service({
    url: `/bid/simplified-aircraft/`,
    method: "get",
    params: params,
  });
}

export function fetchAccommodations(params) {
  service.defaults.paramsSerializer = function (params) {
    return qs.stringify(params, { indices: false }); // param=value1&param=value2
  };

  return service({
    url: `/bid/simplified-accommodation/`,
    method: "get",
    params: params,
  });
}

export function fetchAccommodationInfo(params) {
  return service({
    url: `/bid/accommodation-metadata/`,
    method: "get",
    params,
  });
}

export function fetchAccommodationMeta(id) {
  return service({
    url: `/bid/accommodation-metadata/${id}/`,
    method: "get",
  });
}

export function fetchFacility(params) {
  return service({
    url: `/bid/simplified-facility/`,
    method: "get",
    params: params,
  });
}

export function fetchBiddingRequest(params) {
  return service({
    url: `/bid/bid-request/`,
    method: "get",
    params: params,
  });
}
