import _ from "lodash";
import { fetchSubAgentsProfile, fetchSuppliersProfile } from "@src/api";
import {
  NormalDatePicker,
  NormalInputField,
  NormalSelectField,
} from "@src/components/forms";
import { currencies } from "@src/definitions";
import { variables } from "@src/jsssetup";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useFormikContext } from "formik";
import { DateTime } from "luxon";
import { useEffect } from "react";
import { createUseStyles } from "react-jss";
import tinycolor from "tinycolor2";
import { SectionHeader, sectionCommonStyles } from "./components";
import React from "react";
import { Loader } from "rsuite";
import { useSelector } from "react-redux";
import { InlineIcon } from "@iconify/react";
import PropTypes from "prop-types";

const customerInfoStyles = createUseStyles({
  CustomerInfo: { ...sectionCommonStyles, minHeight: "15rem" },
  content: {
    display: "grid",
    gridGap: variables.normal_gap,
    alignItems: "start",
  },
  customerInfoCntr: {
    display: "grid",
    gridGap: `calc(${variables.half_gap} / 2)`,
  },
  customerInfo: {
    color: variables.colors.text.dark,
    fontWeight: "bold",
  },
  dueDate: {
    color: tinycolor(variables.colors.text.dark).lighten(20).toHexString(),
    fontSize: "small",
  },
  dropDownLabel: {
    color: variables.colors.text.colored,
    fontSize: "medium",
    fontWeight: 600,
  },
  inputs: {
    display: "grid",
    gridGap: variables.half_gap,
    gridTemplateColumns: "8rem 1fr 6rem",
  },
  invoiceReference: {
    "& input": {
      width: "8rem",
    },
  },
  currency: {
    "& select": {
      width: "6rem",
    },
  },
  actionBtn: {
    fontSize: "large",
    color: variables.colors.text.colored,
    cursor: "pointer",
  },
});
export const CustomerInfo = ({ customerEdit, setCustomerEdit }) => {
  const classes = customerInfoStyles({});
  const { values, setFieldValue } = useFormikContext();
  const customer = values.customer;

  const countries = {};
  useSelector((state) =>
    (state.countryCodeInfo || [])
      .filter((c) => c.code)
      .forEach((c) => (countries[c.code] = c.name_en))
  );

  const provider = _.get((values.provider || "").split("---"), 1);
  const { data: providerData, isLoading: invoiceProviderIsLoading } = useQuery({
    queryKey: ["invoiceProviderData", values.provider],
    queryFn: () =>
      provider
        ? fetchSuppliersProfile(provider)
        : new Promise((resolve) => resolve(null)),
    refetchOnWindowFocus: false,
  });
  const subagent = _.get((values.subagent || "").split("---"), 1);
  const { data: subagentData, isLoading: invoiceSubagentIsLoading } = useQuery({
    queryKey: ["invoiceSubagentData", values.subagent],
    queryFn: () =>
      subagent
        ? fetchSubAgentsProfile(subagent)
        : new Promise((resolve) => resolve(null)),
    refetchOnWindowFocus: false,
  });

  const isLoading = invoiceProviderIsLoading || invoiceSubagentIsLoading;
  // Initialize values in case of subagent or provider customer type
  useEffect(() => {
    if (values.customer_type === "guest") return;
    if (!providerData && !subagentData) return;
    if (values.customer.source_entity) return;

    const entityData =
      values.customer_type === "subagent" ? subagentData : providerData;

    if (_.get(entityData, "id") !== _.get(values, "customer.id")) {
      const address = entityData.address.find(
        (addr) => addr.address_type === "BU"
      );
      const addressStr = [
        address.street_number,
        address.street,
        address.city,
        address.region,
        address.post_code,
        countries[address.country_code],
      ]
        .filter((v) => v)
        .join(", ");

      const cPerson = entityData.contact_person?.[0];
      const cPersonPhone =
        cPerson?.phone ||
        cPerson?.mobile_phone ||
        entityData.phone ||
        entityData.mobile_phone;

      const tmp = {
        name: entityData.name,
        address: addressStr,
        country: countries[address.country_code],
        country_code: address.country_code,
        phone: cPersonPhone,
        company: entityData.legal_title,
        company_email: entityData.email,
        source_entity: `${entityData.id}___${values.customer_type}`,
      };
      setFieldValue("customer", tmp);
    }
  }, [providerData, subagentData]);
  const queryClient = useQueryClient();
  const offerData = queryClient.getQueryData([
    "invoiceOfferData",
    values.product_reference,
  ]);
  const addonData = queryClient.getQueryData([
    "invoiceAddonData",
    values.product_reference,
  ]);
  useEffect(() => {
    if (!offerData && !addonData) return;
    var currency = "";
    if (offerData) {
      currency = offerData?.trip_pricing?.currency || "";
    } else {
      currency = _.last(addonData?.data?.pricing_data)?.currency || "";
    }
    setFieldValue("currency", currency);
  }, [offerData, addonData]);

  // Initialize values in case of guest customer type
  useEffect(() => {
    if (values.customer_type !== "guest") return;
    // TODO: this is temporary we need to add support for
    // offer guests as well.
    if (values.product_type !== "addon") return;

    const groupLeader = (addonData?.data.guests || [])?.find(
      (guest) => guest.group_leader
    );
    if (!groupLeader) return;

    const tmp = {
      name: `${groupLeader.first_name} ${groupLeader.last_name}`,
      address: groupLeader?.address || "",
      country: "",
      country_code: "",
      phone: groupLeader.phone || "",
      company: "",
      company_email: groupLeader.email,
      source_entity: "",
    };
    setFieldValue("customer", tmp);
  }, [offerData, addonData]);

  const customerFilteredKeys = [
    "name",
    "company",
    "company_email",
    "address",
    "phone",
  ];

  const countryOptions = Object.entries(countries).map(([code, name]) => [
    code,
    name,
  ]);

  useEffect(() => {
    if (
      values.customer.country_code &&
      countries[values.customer.country_code]
    ) {
      setFieldValue(
        "customer.country",
        countries[values.customer.country_code]
      );
    } else {
      setFieldValue("customer.country", "");
    }
  }, [values.customer.country_code]);

  return (
    <div className={classes.CustomerInfo}>
      <SectionHeader text="Customer">
        {!customerEdit ? (
          <InlineIcon
            icon="ic:baseline-edit"
            className={classes.actionBtn}
            onClick={() => setCustomerEdit(true)}
          />
        ) : (
          <InlineIcon
            icon="mdi:tick"
            className={classes.actionBtn}
            onClick={() => setCustomerEdit(false)}
          />
        )}
      </SectionHeader>

      <div className={classes.content}>
        {isLoading ? (
          <Loader center size="lg" />
        ) : (
          <React.Fragment>
            {!customerEdit ? (
              <div className={classes.customerInfoCntr}>
                <div className={classes.customerInfo}>{customer.name}</div>
                <div className={classes.customerInfo}>{customer.company}</div>
                <div className={classes.customerInfo}>{customer.address}</div>
                <div className={classes.customerInfo}>{customer.country}</div>
                <div className={classes.customerInfo}>
                  {customer.company_email}
                </div>
                <div className={classes.customerInfo}>{customer.phone}</div>
              </div>
            ) : (
              <div className={classes.customerInfoCntr}>
                {customerFilteredKeys.map((key) => (
                  <div key={key} className={classes.customerInfo}>
                    <NormalInputField
                      name={`customer.${key}`}
                      label={
                        key === "name"
                          ? "Full Name"
                          : key === "company_email"
                          ? "Email"
                          : _.startCase(key)
                      }
                      withError={true}
                      highlightErrors={true}
                    />
                  </div>
                ))}
                <NormalSelectField
                  name={`customer.country_code`}
                  label="Country"
                  options={countryOptions}
                  withError={true}
                  highlightErrors={true}
                />
              </div>
            )}
            <div className={classes.inputs}>
              <div className={classes.invoiceReference}>
                <NormalInputField
                  name="invoiceNumber"
                  label={
                    <span className={classes.dropDownLabel}>Invoice Ref.</span>
                  }
                  withError={true}
                  highlightErrors={true}
                />
              </div>
              <div className={classes.dueDate}>
                <NormalDatePicker
                  name="dueDate"
                  label={
                    <span className={classes.dropDownLabel}>Due Date</span>
                  }
                  minAvailableDate={DateTime.now().toISODate()}
                  withError={true}
                  highlightErrors={true}
                />
              </div>
              <div className={classes.currency}>
                <NormalSelectField
                  label={
                    <span className={classes.dropDownLabel}>Currency</span>
                  }
                  name="currency"
                  options={[
                    ["", "----"],
                    ...Object.keys(currencies).map((code) => [
                      code,
                      `${code}, ${currencies[code]}`,
                    ]),
                  ]}
                  customStyles={{ input: { cursor: "pointer" } }}
                  highlightErrors={true}
                />
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};
CustomerInfo.defaultProps = {
  customerEdit: false,
};
CustomerInfo.propTypes = {
  customerEdit: PropTypes.bool.isRequired,
  setCustomerEdit: PropTypes.func.isRequired,
};
