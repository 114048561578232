import { IconButton, Icon, Loader, SelectPicker } from "rsuite";

import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { TripPlanContext } from "../TripPlanner";
import { createUseStyles } from "react-jss";
import { modalGenericStyles } from "@src/jsssetup";
import { formStyles } from "@src/jsssetup";
import { Form, Formik, useFormikContext } from "formik";
import { NormalInputField, NormalSelectField } from "@src/components/forms";
import { variables } from "@src/jsssetup";
import * as yup from "yup";
import { useMutation, useQuery } from "@tanstack/react-query";
import { createTripOffer } from "@src/api/Project/trip_offers/unsent";
import { toast } from "react-toastify";
import { getUserSourceEntitySelector } from "@src/selectors/Shared/user_selectors";
import { DateTime } from "luxon";
import { saveTrip } from "@src/actions/Project/TripPlanner";
import { getSetupFormDataSelector } from "@src/selectors/Project/TripPlanner";
import { fetchSubAgentsProfile } from "@src/api";

const offerSchema = yup.object().shape({
  recipients: yup
    .array()
    .of(
      yup
        .string()
        .email("Invalid email format")
        .required("Recipient is required")
    )
    .min(1, "At least one recipient is required"),
  currency: yup
    .string()
    .max(3, "Currency must be a maximum of 3 characters")
    .required("Offer currency is required"),
  title: yup.string().required("Title is required"),
  message: yup.string().required("Message is required"),
});

const SendOfferBtn = () => {
  const { submitForm } = useFormikContext();

  return (
    <button className="Button" onClick={submitForm}>
      Submit
    </button>
  );
};

const offerModalStyles = createUseStyles({
  ...modalGenericStyles,
  OfferModal: modalGenericStyles.modal,
  form: { ...formStyles.form, display: "grid", gridGap: variables.half_gap },
  recipient: {
    display: "grid",
    gridTemplateColumns: "max-content 1fr max-content",
    gridGap: variables.half_gap,
    alignItems: "end",
  },
});
const OfferModal = () => {
  const [saveLoading, setSaveLoading] = useState(false);
  const [receiver, setReceiver] = useState(null);
  const classes = offerModalStyles();

  const { setShowOfferModal } = useContext(TripPlanContext);

  function onHide() {
    setShowOfferModal(false);
  }

  const { source_entity, tripplan_id, currencies, title, target_entity } =
    useSelector((state) => {
      const source_entity = getUserSourceEntitySelector(state);
      const setup = getSetupFormDataSelector(state);
      return {
        source_entity,
        tripplan_id: state.tripPlannerInstanceData.id,
        currencies: state.financialAvailableCurrencies,
        title: setup.title,
        target_entity: setup.target_entity,
      };
    });

  const createTripOfferMutation = useMutation(
    (payload) => createTripOffer({ payload }),
    {
      onSuccess: (response) => {
        toast.success(
          "Trip Offer successfully moved to the sent list. You are now redirected to it.",
          { autoClose: 10000 }
        );
        onHide();
        window.location = `/#/project/trip/tripplan/${response.data.id}/view-offer`;
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );

  const dispatch = useDispatch();
  const onSave = useCallback(async () => {
    setSaveLoading(true);
    await dispatch(saveTrip({ nonUI: true }));
    setSaveLoading(false);
  }, [dispatch]);

  // Save trip before doing anything else.
  useEffect(() => {
    onSave();
  }, []);

  const { isFetching } = useQuery({
    queryKey: ["tripTargetEntity", target_entity],
    queryFn: () => fetchSubAgentsProfile(target_entity.split("___")[0]),
    onSuccess: (data) => {
      const cPerson = data?.contact_person?.[0];
      setReceiver({
        email: data?.email ?? "",
        first_name: cPerson?.first_name,
        last_name: cPerson?.last_name,
        phone: cPerson?.mobile_phone ?? cPerson?.phone ?? "",
        company: data?.legal_title,
      });
    },
    onError: () =>
      toast.error("Error fetching target entity", { autoClose: 3000 }),
    refetchOnWindowFocus: false,
    enabled: (target_entity || "").includes("subagent"),
  });
  const targetEntityLoading = isFetching;

  const loading =
    saveLoading || createTripOfferMutation.isLoading || targetEntityLoading;

  return (
    <div className={classes.OfferModal}>
      {loading ? (
        <Loader vertical content="Please Wait..." size="lg" backdrop />
      ) : (
        <Formik
          initialValues={{
            recipients: [receiver?.email ?? ""],
            currency: "EUR",
            title,
            message: "",
            auto_send_offer_email: "false",
          }}
          validateOnChange={false}
          validateOnMount={false}
          validationSchema={offerSchema}
          onSubmit={(values) => {
            const payload = {
              tripplan_id,
              offer_title: values.title,
              offer_email: values.recipients[0],
              offer_emails: values.recipients,
              notes: values.message,
              source_entity,
              validity: DateTime.now().plus({ days: 2 }).toISO(),
              currency: values.currency,
              auto_send_offer_email:
                values.auto_send_offer_email === "false" ? false : true,
            };
            createTripOfferMutation.mutate(payload);
          }}>
          {({ values, setFieldValue }) => {
            return (
              <div className={classes.card}>
                <div className={classes.cardHeader}>
                  <h5>Create Offer</h5>
                </div>
                <div className={classes.cardBody}>
                  <Form className={classes.form}>
                    <NormalInputField
                      label="Offer Title"
                      name="title"
                      highlightErrors={true}
                    />
                    <NormalSelectField
                      label="Auto Send Email"
                      name="auto_send_offer_email"
                      options={[
                        ["true", "Yes"],
                        ["false", "No"],
                      ]}
                    />
                    {values.recipients.map((recipient, idx) => (
                      <div className={classes.recipient} key={idx}>
                        {values.recipients.length > 1 ? (
                          <IconButton
                            icon={<Icon icon="close" />}
                            circle
                            color="red"
                            size="xs"
                            onClick={() =>
                              setFieldValue(
                                "recipients",
                                values.recipients.filter((_, i) => i !== idx)
                              )
                            }
                          />
                        ) : (
                          <div />
                        )}
                        <NormalInputField
                          label={`Recipient ${idx + 1}`}
                          name={`recipients.${idx}`}
                          highlightErrors={true}
                        />
                        {idx + 1 === values.recipients.length && (
                          <button
                            className="Button"
                            onClick={() =>
                              setFieldValue("recipients", [
                                ...values.recipients,
                                "",
                              ])
                            }>
                            Add Recipient
                          </button>
                        )}
                      </div>
                    ))}
                    <NormalSelectField
                      label="Offer Currency"
                      name="currency"
                      options={_.sortBy(
                        currencies.map((curr) => [
                          curr.notation,
                          `${curr.notation}, ${curr.name}`,
                        ]),
                        (v) => v[1]
                      )}
                      highlightErrors={true}
                    />
                    <NormalInputField
                      name="message"
                      label="Message"
                      as="textarea"
                      highlightErrors={true}
                    />
                  </Form>
                </div>
                <div className={classes.cardActions}>
                  {createTripOfferMutation.isLoading ? null : (
                    <React.Fragment>
                      <button
                        className="Button"
                        data-ghost="true"
                        onClick={onHide}>
                        Close
                      </button>
                      <SendOfferBtn />
                    </React.Fragment>
                  )}
                </div>
              </div>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default OfferModal;
