import axios from "axios";
export var SISI_API_URL = process.env.REACT_APP_SISI_GATEWAY;

export async function fastDestinationAutocomplete({ name }) {
  try {
    const result = await axios.get(
      `${SISI_API_URL}/destination_autocomplete/fast/`,
      { params: { name } }
    );
    return result.data;
  } catch (error) {
    return null;
  }
}

export async function fetchDestinationsQuickInfo({ lvl_ids }) {
  try {
    const result = await axios.get(
      `${SISI_API_URL}/geoapi/destination-quick-info/`,
      { params: { destinations: lvl_ids.join(",") } }
    );
    return result.data;
  } catch (error) {
    return null;
  }
}

export async function reverseGeocode({ lat, lng }) {
  try {
    const result = await axios.post(`${SISI_API_URL}/geoapi/geoconnect/`, [
      {
        lat,
        lon: lng,
      },
    ]);
    return result.data;
  } catch (error) {
    return null;
  }
}

export async function findNearestDestination({ lat, lng }) {
  try {
    const result = await axios.get(`${SISI_API_URL}/geoapi/nearest/`, {
      params: {
        lat,
        lng,
      },
    });
    return result.data;
  } catch (error) {
    return null;
  }
}
