import {
  // ========================== INIT ACTIONS =========================
  TRIPPLANNER_INIT,
  // ======================= TRIP LOAD ACTIONS =======================
  TRIPPLANNER_LOAD_TRIP,
  TRIPPLANNER_ACCOMMODATION_EDIT_ENABLED,
  TRIPPLANNER_ACCOMMODATION_EDIT_DISABLED,
  ACCOMMODATION_PREFERENCES_CHANGE,
  ACCOMMODATION_PREFERENCES_ROOM_ADD,
  ACCOMMODATION_PREFERENCES_ROOM_REMOVE,
  ACCOMMODATION_PREFERENCES_ROOM_PAX_ADD,
  ACCOMMODATION_PREFERENCES_ROOM_PAX_REMOVE,
  ACCOMMODATION_PREFERENCES_AGE_CHANGE,
  ACCOMMODATION_PREFERENCES_RESET,
  TRIPPLANNER_ACC_PREF_PRIORITIES_SET_ACC,
  TRIPPLANNER_ACC_PREF_REMOVE_ACC_PRIORITY,
  TRIPPLANNER_ACC_PREF_CHANGE_PRIORITY_BEHAVIOUR,
  TRIPPLANNER_ACC_PREF_CLEAR_DEST_PRIORITIES,
  TRIPPLANNER_CHANGE_PAX,
  TRIPPLANNER_ACC_PREF_CHANGE_PRIORITY,
  TRIPPLANNER_SET_ROOM_SETUP,
} from "@src/actions/Project/TripPlanner/types";

import { notifyCommonWarning } from "@src/components/common/notifications/CommonWarningNotification.js";

import _ from "lodash";
import update from "immutability-helper";
import v4 from "uuid/v4";

export const tripPlannerAccPrefInit = {
  rating: [5],
  board: "ALL",
  bedding: "ALL",
  type: "ALL",
  preselectionMode: "prefer", //enum: ['prefer', 'comply']
};
export const tripPlannerAccPref = (state = tripPlannerAccPrefInit, action) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return tripPlannerAccPrefInit;
    case TRIPPLANNER_LOAD_TRIP:
      return action.data.accommodation_preferences;
    case ACCOMMODATION_PREFERENCES_CHANGE: {
      const { preferences } = action;
      const newState = _.cloneDeep(state);
      return { ...newState, ...preferences };
    }
    case ACCOMMODATION_PREFERENCES_RESET:
      return tripPlannerAccPrefInit;
    default:
      return state;
  }
};

export const roomTemplate = { adults: 2, children: 0, children_ages: [] };
const tripPlannerAccPaxInitState = [{ ...roomTemplate, uid: v4() }];
export const tripPlannerAccPax = (
  state = tripPlannerAccPaxInitState,
  action
) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return tripPlannerAccPaxInitState;
    case TRIPPLANNER_LOAD_TRIP:
      return action.data.accommodation_pax_preferences;
    case ACCOMMODATION_PREFERENCES_RESET:
      return tripPlannerAccPaxInitState;
    case ACCOMMODATION_PREFERENCES_ROOM_ADD: {
      const maxRooms = 9;
      if (state.length + 1 > maxRooms) {
        notifyCommonWarning(`You cannot request more than ${maxRooms} rooms`);
        return state;
      }
      return update(state, { $push: [{ ...roomTemplate, uid: v4() }] });
    }
    case ACCOMMODATION_PREFERENCES_ROOM_REMOVE: {
      if (state.length - 1 <= 0) {
        return state;
      }

      return update(state, { $splice: [[-1]] });
    }
    case ACCOMMODATION_PREFERENCES_ROOM_PAX_ADD: {
      const limit = 8;
      if (
        state[action.roomIdx].adults + state[action.roomIdx].children + 1 >
        limit
      ) {
        notifyCommonWarning(`You cannot have more than ${limit} pax per room`);
        return state;
      }

      return update(state, {
        [action.roomIdx]: {
          [action.paxType]: { $apply: (pax) => pax + 1 },
          children_ages: {
            $apply: (ages) => {
              if (action.paxType == "children") {
                const newAges = [...ages];
                newAges.push(5);
                return newAges;
              } else {
                return ages;
              }
            },
          },
        },
      });
    }
    case ACCOMMODATION_PREFERENCES_ROOM_PAX_REMOVE: {
      if (action.paxType == "adults" && state[action.roomIdx].adults - 1 < 1) {
        return state;
      } else if (
        action.paxType == "children" &&
        state[action.roomIdx].children - 1 < 0
      ) {
        return state;
      }
      return update(state, {
        [action.roomIdx]: {
          [action.paxType]: { $apply: (pax) => pax - 1 },
          children_ages: {
            $apply: (ages) => {
              if (action.paxType == "children") {
                const newAges = [...ages];
                newAges.pop();
                return newAges;
              } else {
                return ages;
              }
            },
          },
        },
      });
    }
    case ACCOMMODATION_PREFERENCES_AGE_CHANGE:
      return update(state, {
        [action.roomIdx]: {
          children_ages: { [action.childIdx]: { $set: action.age } },
        },
      });
    case TRIPPLANNER_CHANGE_PAX: {
      const { paxType, value } = action;
      if ([1, 2].includes(value) && paxType == "adults") {
        return [{ ...roomTemplate, adults: value, uid: v4() }];
      }

      return state;
    }
    case TRIPPLANNER_SET_ROOM_SETUP: {
      const { rooms } = action;
      return rooms;
    }
    default:
      return state;
  }
};

export const tripPlannerAccPrefEditMode = (state = false, action) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return false;
    case TRIPPLANNER_LOAD_TRIP:
      return false;
    case TRIPPLANNER_ACCOMMODATION_EDIT_ENABLED:
      return true;
    case TRIPPLANNER_ACCOMMODATION_EDIT_DISABLED:
      return false;
    default:
      return state;
  }
};

const tripPlannerAccPrioritiesInitial = {};
export const tripPlannerAccPriorities = (
  state = tripPlannerAccPrioritiesInitial,
  action
) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return tripPlannerAccPrioritiesInitial;
    case TRIPPLANNER_LOAD_TRIP: {
      const { data } = action;
      return { ...state, ...data.accommodation_priorities };
    }
    case TRIPPLANNER_ACC_PREF_PRIORITIES_SET_ACC: {
      const { destOrder, acc } = action;

      if (_.get(state, `${destOrder}.hotels`)) {
        return update(state, {
          [destOrder]: { hotels: { $push: [acc] } },
        });
      } else {
        return update(state, { [destOrder]: { $set: { hotels: [acc] } } });
      }
    }
    case TRIPPLANNER_ACC_PREF_REMOVE_ACC_PRIORITY: {
      const { destOrder, accUid } = action;
      return update(state, {
        [destOrder]: {
          hotels: {
            $apply: (hotels) => hotels.filter((acc) => accUid !== acc.uid),
          },
        },
      });
    }
    case TRIPPLANNER_ACC_PREF_CHANGE_PRIORITY: {
      const { destOrder, currentIdx, newIdx } = action;
      const currentHtl = _.get(state, `${destOrder}.hotels.${currentIdx}`);
      const nextHtl = _.get(state, `${destOrder}.hotels.${newIdx}`);

      var newState = update(state, {
        [destOrder]: { hotels: { $splice: [[currentIdx, 1, nextHtl]] } },
      });
      return update(newState, {
        [destOrder]: { hotels: { $splice: [[newIdx, 1, currentHtl]] } },
      });
    }
    case TRIPPLANNER_ACC_PREF_CHANGE_PRIORITY_BEHAVIOUR: {
      const { destOrder, behaviour } = action;
      return update(state, { [destOrder]: { behaviour: { $set: behaviour } } });
    }
    case TRIPPLANNER_ACC_PREF_CLEAR_DEST_PRIORITIES: {
      const { destOrder } = action;
      return update(state, {
        [destOrder]: { hotels: { $set: [] }, behaviour: { $set: "PR" } },
      });
    }
    default:
      return state;
  }
};
