import PropTypes from "prop-types";
import React from "react";
import { createUseStyles } from "react-jss";
import { variables } from "@src/jsssetup";

const prefActionsStyles = createUseStyles({
  main: {
    display: "grid",
    gridTemplateColumns: "repeat(2, max-content)",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    justifyContent: "end",
  },
});
export const PrefActions = ({ editMode, onReset, onConfirm, onEdit }) => {
  const classes = prefActionsStyles();

  return (
    <div className={classes.main}>
      {editMode ? (
        <React.Fragment>
          <button
            className="Button"
            type="button"
            data-ghost="true"
            onClick={onReset}>
            Reset
          </button>
          <button
            className="Button"
            type="button"
            data-success="true"
            onClick={onConfirm}>
            Confirm
          </button>
        </React.Fragment>
      ) : (
        <button className="Button" type="button" onClick={onEdit}>
          Edit
        </button>
      )}
    </div>
  );
};
PrefActions.defaultProps = {
  editMode: true,
  searching: true,
};
PrefActions.propTypes = {
  editMode: PropTypes.bool.isRequired,
  searching: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
};
