export const pageLimit = [
  { value: 20, label: 20 },
  { value: 50, label: 50 },
  { value: 100, label: 100 },
];
export const archivePageLimit = [
  { value: 6, label: 6 },
  { value: 12, label: 12 },
  { value: 18, label: 18 },
];

export const weeks = [
  { label: "m", verbose: "monday" },
  { label: "t", verbose: "tuesday" },
  { label: "w", verbose: "wednesday" },
  { label: "t", verbose: "thursday" },
  { label: "f", verbose: "friday" },
  { label: "s", verbose: "saturday" },
  { label: "s", verbose: "sunday" },
];

export const flightClass = [
  { value: "Economy", label: "Economy" },
  { value: "PremiumEconomy", label: "Premium Economy" },
  { value: "Business", label: "Business Class" },
  { value: "First", label: "First Class" },
];

export const continent = [
  { label: "Asia", value: "AS" },
  { label: "Africa", value: "AF" },
  { label: "Europe", value: "EU" },
  { label: "North America", value: "NA" },
  { label: "South America", value: "SA" },
  { label: "Oceania", value: "OC" },
];

export const hotelRating = [
  {
    value: 1,
    label: "1 Star",
  },
  {
    value: 2,
    label: "2 Stars",
  },
  {
    value: 3,
    label: "3 Stars",
  },
  {
    value: 4,
    label: "4 Stars",
  },
  {
    value: 5,
    label: "5 Stars",
  },
];

export const trfVehicleTypes = [
  { value: "PV", label: "Private Car", mapping: ["private", "Private Car"] },
  { value: "EC", label: "Executive Car", mapping: ["Executive Car"] },
  { value: "LI", label: "Limo", mapping: ["Luxury Car"] },
  { value: "MV", label: "Mini Van", mapping: ["Private MPV"] },
  {
    value: "MB",
    label: "Mini Bus",
    mapping: ["Mini Bus"],
  },
  {
    value: "BU",
    label: "Bus",
    mapping: ["Shuttle", "Speedy Shuttle", "Scheduled Service"],
  },
];

export const langs = {
  en: "English",
  cn: "Chinese",
  it: "Italian",
  es: "Spanish",
  fr: "French",
  el: "Greek",
  nl: "Dutch",
  de: "German",
  pt: "Portuguese",
};

export const langTranslateMapping = {
  en: "en",
  cn: "zh",
  it: "it",
  es: "es",
  fr: "fr",
  el: "el",
  nl: "nl",
  de: "de",
  pt: "pt",
};

export const boardMapping = {
  RR: "Room Only",
  RO: "Room Only",
  BB: "Bed & Breakfast",
  HB: "Half Board",
  FB: "Full Board",
  AI: "All Inclusive",
};
