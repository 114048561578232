import {
  initialSubAgentsColumnsSetUp,
  initialSubAgentsFilterForm,
  initialSubAgentsBaseDataForm,
  initialSubAgentsNatureOfBizForm,
} from "@src/forms/Network/SubAgents";

import { Schema } from "rsuite";

import {
  SUBAGENTS_BEGIN_FETCH_DATA,
  SUBAGENTS_FETCH_DATA,
  SUBAGENTS_END_FETCH_DATA,
  SUBAGENTS_UPDATE_LIST_FILTER_FORM,
  SUBAGENTS_APPLY_LIST_FILTER,
  SUBAGENTS_INITIALIZE_LIST_FILTER_FORM,
  SUBAGENTS_UPDATE_LIST_COLUMNS_FORM,
  SUBAGENTS_APPLY_LIST_COLUMNS,
  SUBAGENTS_INITIALIZE_LIST_COLUMNS_FORM,
  SUBAGENTS_FETCH_OPTIONS,
  SUBAGENTS_SHOW_BASE_DATA_EDIT_FORM,
  SUBAGENTS_HIDE_BASE_DATA_EDIT_FORM,
  SUBAGENTS_EDIT_BASE_DATA,
  SUBAGENTS_INITIALIZE_BASE_DATA_EDIT_FORM,
  SUBAGENTS_CLEAN_BASE_DATA_EDIT_FORM,
  SUBAGENTS_UPLOADING_BASE_DATA_EDIT_FORM,
  SUBAGENTS_IDLE_BASE_DATA_EDIT_FORM,
  SUBAGENTS_VALIDATE_BASE_DATA_EDIT_FORM,
  SUBAGENTS_CLEAN_BASE_EDIT_FORM_ERRORS,
  SUBAGENTS_PROFILE_DATA,
  SUBAGENTS_VIEW_NATURE_OF_BIZ,
  SUBAGENTS_EDIT_NATURE_OF_BIZ,
  SUBAGENTS_INITIALIZE_NATURE_DATA_FORM,
  SUBAGENTS_EDIT_NATURE_OF_BIZ_DATA,
  SUBAGENTS_SUBMIT_NATURE_OF_BIZ_DATA,
  SUBAGENTS_EDIT_LICENSE_CODE,
  SUBAGENTS_TOGGLE_POSTAL_ADDRESS,
  SUBAGENTS_RESET_LIST_FILTER_FORM,
  //img relation
  SUBAGENTS_UPLOAD_PREFERENCE_IMAGE,
  SUBAGENTS_FETCH_ADDRESS_POINT,
  SUBAGENTS_INITIAL_ADDRESS_POINT,
  SUBAGENTS_TOGGLE_VERIFY_ADDRESS,
  SUBAGENTS_BEGIN_FETCH_ADDRESS_DATA,
  SUBAGENTS_END_FETCH_ADDRESS_DATA,
  SUBAGENTS_BEGIN_FETCH_PROFILE_DATA,
  SUBAGENTS_END_FETCH_PROFILE_DATA,
  SUBAGENTS_CLOSE_VERIFY_ADDRESS,
  SUBAGENTS_CLEAN_ADDRESS_POINT,
  SUBAGENTS_INITIAL_VERIFY_STATUS,
  //==================Email Config ==================
  SUBAGENTS_VIEW_EMAIL_CONFIG_MODE,
  SUBAGENTS_EDIT_EMAIL_CONFIG_MODE,
  SUBAGENTS_LOADING_EMAIL_CONFIG_MODE,
  SUBAGENTS_EDIT_EMAIL_CONFIG_FORM,
  SUBAGENTS_INITIALIZE_EMAIL_CONFIG_FORM,
} from "@src/actions/Network/SubAgents/types";

import {
  FORMAT_TRAVEL_SERVICES_OPTIONS,
  FORMAT_TRAVEL_SERVICES_FORM,
} from "@src/actions/Shares/types";

import { formProcessor, entitiesListSelector } from "@src/reducers/tools";

import update from "immutability-helper";
import _ from "lodash";

// subAgents profile
export const subAgentsBaseDataForm = (
  state = initialSubAgentsBaseDataForm,
  action
) => {
  switch (action.type) {
    case SUBAGENTS_INITIALIZE_BASE_DATA_EDIT_FORM:
      return action.data;
    case SUBAGENTS_CLEAN_BASE_DATA_EDIT_FORM:
      return initialSubAgentsBaseDataForm;
    case SUBAGENTS_UPLOAD_PREFERENCE_IMAGE:
      return update(state, {
        [action.data.name]: {
          value: { $set: action.data.value },
          fileName: { $set: action.data.fileName },
        },
      });
    case SUBAGENTS_EDIT_BASE_DATA:
      return update(state, {
        [action.data.name]: { value: { $set: action.data.value } },
      });
    default:
      return state;
  }
};

export const initialSubAgentsBaseDataFormErrorsState = { valid: false };
export const subAgentsBaseDataFormErrors = (
  state = initialSubAgentsBaseDataFormErrorsState,
  action
) => {
  const tmp = {};
  Object.entries(initialSubAgentsBaseDataForm).forEach((e) => {
    tmp[e[0]] = e[1].validator;
  });
  const validator = Schema.Model(tmp);

  switch (action.type) {
    case SUBAGENTS_VALIDATE_BASE_DATA_EDIT_FORM: {
      const tmp = {};
      Object.entries(action.data).forEach((e) => (tmp[e[0]] = e[1].value));

      const validation = validator.check(tmp);

      const valid = Object.entries(validation).every((e) => !e[1].hasError);
      validation["valid"] = valid;
      return validation;
    }
    case SUBAGENTS_CLEAN_BASE_EDIT_FORM_ERRORS:
      return initialSubAgentsBaseDataFormErrorsState;
    default:
      return state;
  }
};

export const initialSubAgentsBaseDataUploadStatus = "IDLE";
export const subAgentsBaseDataUploadStatus = (
  state = initialSubAgentsBaseDataUploadStatus,
  action
) => {
  switch (action.type) {
    case SUBAGENTS_UPLOADING_BASE_DATA_EDIT_FORM:
      return "LOADING";
    case SUBAGENTS_IDLE_BASE_DATA_EDIT_FORM:
      return "IDLE";
    default:
      return state;
  }
};

export const subAgentsProfile = (state = {}, action) => {
  switch (action.type) {
    case SUBAGENTS_PROFILE_DATA:
      return update(state, { $merge: action.data });
    default:
      return state;
  }
};

export const initialSubAgentsBaseDataEditFormShow = false;
export const subAgentsBaseDataEditFormShow = (
  state = initialSubAgentsBaseDataEditFormShow,
  action
) => {
  switch (action.type) {
    case SUBAGENTS_SHOW_BASE_DATA_EDIT_FORM:
      return true;
    case SUBAGENTS_HIDE_BASE_DATA_EDIT_FORM:
      return false;
    default:
      return state;
  }
};

export const subAgentsOptions = (state = {}, action) => {
  switch (action.type) {
    case SUBAGENTS_FETCH_OPTIONS:
      return update(state, { $merge: action.data });
    case FORMAT_TRAVEL_SERVICES_OPTIONS:
      return update(state, { $merge: action.data });
    default:
      return state;
  }
};

export const initialsSubAgentsProfileFetchStatus = "IDLE";
export const subAgentsProfileFetchStatus = (
  state = initialsSubAgentsProfileFetchStatus,
  action
) => {
  switch (action.type) {
    case SUBAGENTS_BEGIN_FETCH_PROFILE_DATA:
      return "FETCHING";
    case SUBAGENTS_END_FETCH_PROFILE_DATA:
      return "FETCHED";
    default:
      return state;
  }
};

export const initialSubAgentsOptionsFetchStatus = "IDLE";
export const subAgentsOptionsFetchStatus = (
  state = initialSubAgentsOptionsFetchStatus,
  action
) => {
  switch (action.type) {
    case SUBAGENTS_FETCH_OPTIONS:
      return "FETCHED";
    default:
      return state;
  }
};

export const subAgentsList = (state = initialSubAgentsList, action) => {
  switch (action.type) {
    case SUBAGENTS_FETCH_DATA:
      return action.data;
    case SUBAGENTS_RESET_LIST_FILTER_FORM:
      return { ...state, page: 1 };
    default:
      return state;
  }
};

export const initialSubAgentsPostalAddressIsSynchronous = false;
export const subAgentsPostalAddressIsSynchronous = (
  state = initialSubAgentsPostalAddressIsSynchronous,
  action
) => {
  switch (action.type) {
    case SUBAGENTS_SHOW_BASE_DATA_EDIT_FORM:
      return action.data;
    case SUBAGENTS_TOGGLE_POSTAL_ADDRESS:
      return action.data;
    default:
      return state;
  }
};

// subAgents list
export const initialSubAgentsList = {
  count: 0,
  next: null,
  previous: null,
  results: [],
  page: 1,
  limit: 20,
  displayLength: 100,
};

export const initialSubAgentsListFetchStatus = "IDLE";
export const subAgentsListFetchStatus = (
  state = initialSubAgentsListFetchStatus,
  action
) => {
  switch (action.type) {
    case SUBAGENTS_BEGIN_FETCH_DATA:
      return "FETCHING";
    case SUBAGENTS_END_FETCH_DATA:
      return "FETCHED";
    default:
      return state;
  }
};

// filter
export const subAgentsListFilter = (
  state = initialSubAgentsFilterForm,
  action
) => {
  switch (action.type) {
    case SUBAGENTS_APPLY_LIST_FILTER:
      return action.data;
    case FORMAT_TRAVEL_SERVICES_FORM:
      return update(state, { $merge: action.data });
    case SUBAGENTS_RESET_LIST_FILTER_FORM:
      return action.data;
    default:
      return state;
  }
};

export const subAgentsListFilterForm = (
  state = initialSubAgentsFilterForm,
  action
) => {
  switch (action.type) {
    case SUBAGENTS_INITIALIZE_LIST_FILTER_FORM:
      return action.data;
    case SUBAGENTS_RESET_LIST_FILTER_FORM:
      return action.data;
    case SUBAGENTS_UPDATE_LIST_FILTER_FORM:
      return formProcessor(
        state,
        action.data.type,
        action.data.name,
        action.data.value
      );
    case FORMAT_TRAVEL_SERVICES_FORM:
      return update(state, { $merge: action.data });
    default:
      return state;
  }
};

// columns
export const subAgentsListColumns = (
  state = entitiesListSelector(initialSubAgentsColumnsSetUp),
  action
) => {
  switch (action.type) {
    case SUBAGENTS_APPLY_LIST_COLUMNS:
      return entitiesListSelector(action.data);
    default:
      return state;
  }
};

export const subAgentsListColumnsForm = (
  state = initialSubAgentsColumnsSetUp,
  action
) => {
  switch (action.type) {
    case SUBAGENTS_INITIALIZE_LIST_COLUMNS_FORM: {
      var newState = {};
      const tmp = _.cloneDeep(state);
      for (const key in tmp) {
        // clear form's value
        tmp[key] = update(tmp[key], { value: { $set: [] } });
        newState[key] = update(tmp[key], { value: { $set: [] } });
        // add value by action data
        action.data.forEach((item) => {
          if (_.find(tmp[key].choices, { key: item.key })) {
            newState[key] = update(newState[key], {
              value: { $push: [item.key] },
            });
          }
        });
      }

      return newState;
    }
    case SUBAGENTS_UPDATE_LIST_COLUMNS_FORM:
      return action.data;
    default:
      return state;
  }
};

export const initialSubAgentsNatureOfBizMode = "VIEW";
export const subAgentsNatureOfBizMode = (
  state = initialSubAgentsNatureOfBizMode,
  action
) => {
  switch (action.type) {
    case SUBAGENTS_VIEW_NATURE_OF_BIZ:
      return "VIEW";
    case SUBAGENTS_EDIT_NATURE_OF_BIZ:
      return "EDIT";
    case SUBAGENTS_SUBMIT_NATURE_OF_BIZ_DATA:
      return "LOADING";
    default:
      return state;
  }
};

export const subAgentsNatureOfBizForm = (
  state = initialSubAgentsNatureOfBizForm,
  action
) => {
  switch (action.type) {
    case SUBAGENTS_INITIALIZE_NATURE_DATA_FORM:
      return action.data;
    case SUBAGENTS_EDIT_NATURE_OF_BIZ_DATA:
      // action.data.type defines the type of the update operation
      // push: we push an array to the existing data.
      // set: we directly set the data contained in the action.
      // remove: we empty the form array.
      //License type is a two dimensional array so we need to have special handling
      // of this data. [[<licence_type>, <license_code>]]

      if (action.data.type == "push") {
        let value = action.data.value;
        if (action.data.name == "license_type") {
          value = [action.data.value, ""];
        }

        return update(state, {
          [action.data.name]: { value: { $push: [value] } },
        });
      } else if (action.data.type == "remove") {
        let truth = (v) => v !== action.data.value;

        if (action.data.name == "license_type") {
          truth = (v) => v[0] !== action.data.value;
        }

        return update(state, {
          [action.data.name]: {
            value: { $apply: (va) => va.filter((v) => truth(v)) },
          },
        });
      } else {
        let value = action.data.value;
        if (action.data.name == "license_type") {
          value = value.map((v) => [v, ""]);
        }

        return update(state, {
          [action.data.name]: { value: { $set: value } },
        });
      }
    case SUBAGENTS_EDIT_LICENSE_CODE:
      return update(state, {
        license_type: {
          value: {
            $apply: (lt) => {
              const newLt = [];
              lt.forEach((t) => {
                if (t[0] == action.data.name) {
                  newLt.push([t[0], action.data.value]);
                } else {
                  newLt.push(t);
                }
              });
              return newLt;
            },
          },
        },
      });
    default:
      return state;
  }
};

//img relation

export const subAgentsAddressPoint = (state = {}, action) => {
  switch (action.type) {
    case SUBAGENTS_FETCH_ADDRESS_POINT:
      return action.data;
    case SUBAGENTS_INITIAL_ADDRESS_POINT:
      return action.data;
    case SUBAGENTS_CLEAN_ADDRESS_POINT:
      return { lat: "", lng: "" };
    default:
      return state;
  }
};

export const initialIsVerifySubAgentsPostalAddress = false;
export const isVerifySubAgentsPostalAddress = (
  state = initialIsVerifySubAgentsPostalAddress,
  action
) => {
  switch (action.type) {
    case SUBAGENTS_TOGGLE_VERIFY_ADDRESS:
      return action.data;
    case SUBAGENTS_CLOSE_VERIFY_ADDRESS:
      return false;
    case SUBAGENTS_INITIAL_VERIFY_STATUS:
      return false;
    default:
      return state;
  }
};

export const initialFetchSubAgentsAddressPointStatus = "IDLE";
export const fetchSubAgentsAddressPointStatus = (
  state = initialFetchSubAgentsAddressPointStatus,
  action
) => {
  switch (action.type) {
    case SUBAGENTS_BEGIN_FETCH_ADDRESS_DATA:
      return "FETCHING";
    case SUBAGENTS_END_FETCH_ADDRESS_DATA:
      return "FETCHED";
    default:
      return state;
  }
};

export const initialSubAgentsEmailConfigEditMode = "VIEW";
export const subAgentsEmailConfigEditMode = (
  state = initialSubAgentsEmailConfigEditMode,
  action
) => {
  switch (action.type) {
    case SUBAGENTS_VIEW_EMAIL_CONFIG_MODE:
      return "VIEW";
    case SUBAGENTS_EDIT_EMAIL_CONFIG_MODE:
      return "EDIT";
    case SUBAGENTS_LOADING_EMAIL_CONFIG_MODE:
      return "LOADING";
    default:
      return state;
  }
};

export const initialEmailConfigForm = {
  email_config: {
    value: [],
  },
};

export const subAgentsEmailConfigForm = (
  state = initialEmailConfigForm,
  action
) => {
  switch (action.type) {
    case SUBAGENTS_INITIALIZE_EMAIL_CONFIG_FORM:
      return action.data;
    case SUBAGENTS_EDIT_EMAIL_CONFIG_FORM: {
      const { name, value } = action.data;
      return update(state, {
        email_config: {
          value: {
            $apply: (config) =>
              config.map((c) => {
                if (c.key == name.key) {
                  return { ...name, value: value };
                } else {
                  return c;
                }
              }),
          },
        },
      });
    }
    default:
      return state;
  }
};

export * from "./ServiceCategories";
