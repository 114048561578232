import React from "react";
import { Schema } from "rsuite";
const { StringType, ArrayType } = Schema.Types;

import TransTxt from "@src/components/common/SxFormatMessage";

const POLICY_ROUNDING_DIGITS_CHOICES = [
  {
    value: "1",
    label: "No Decimal Places",
  },
  {
    value: ".1",
    label: "1 Decimal Places",
  },
  {
    value: ".2",
    label: "2 Decimal Places",
  },
  {
    value: ".3",
    label: "3 Decimal Places",
  },
];

export const initialPolicyBaseDataForm = {
  policy_name: {
    value: "",
    verbose: <TransTxt id="policy_name" startCase />,
    validator: StringType().isRequired("Policy Name is required"),
  },
  pax: {
    value: [1, 10],
    verbose: "pax",
    bottomLine: true,
    validator: ArrayType().isRequired("Pax is required"),
  },
  distribution: {
    value: [],
    verbose: <TransTxt id="distribution_period" startCase />,
    validator: ArrayType().isRequired("Distribution is required"),
    placement: "auto",
  },
  reservation: {
    value: [],
    verbose: <TransTxt id="reservation_period" startCase />,
    bottomLine: true,
    validator: ArrayType().isRequired("Reservation is required"),
    placement: "auto",
  },
  status: {
    value: "",
    verbose: "status",
    children: [
      {
        value: "Active",
        label: "AC",
        color: "green",
      },
      {
        value: "Candidate",
        label: "CA",
        color: "violet",
      },
      {
        value: "Inactive",
        label: "IN",
        color: "red",
      },
    ],
    validator: StringType().isRequired("Status is required"),
  },
  for_all: {
    value: [],
    verbose: "valid_for_all_services",
    isBase: true,
    placeholder: ["%", "$0.00"],
  },
  tr: {
    value: [],
    verbose: "transportation",
    placeholder: ["%", "$0.00"],
  },
  acc: {
    value: [],
    verbose: "accommodation",
    placeholder: ["%", "$0.00"],
  },
  la: {
    value: [],
    verbose: "transfer",
    placeholder: ["%", "$0.00"],
  },
  mi: {
    value: [],
    verbose: "add_ons",
    placeholder: ["%", "$0.00"],
  },
  all_services: {
    value: [],
  },
  transportation: {
    value: [],
    verbose: "transportation",
  },
  accommodation: {
    value: [],
    verbose: "accommodation",
  },
  transfer: {
    value: [],
    verbose: "transfer",
  },
  add_ons: {
    value: [],
    verbose: "add_ons",
  },
  note: {
    value: "",
    verbose: "Note",
  },
  days: {
    value: null,
    verbose: "days",
    type: "number",
  },
  amount: {
    value: null,
    verbose: "amount",
    type: "inputNumberWithPrefix",
  },
  rounding_digits: {
    value: "",
    verbose: "Rounding Digits",
    data: POLICY_ROUNDING_DIGITS_CHOICES,
  },
};
