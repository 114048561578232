import React from "react";
import { initialCompanyBaseDataForm } from "@src/forms/Admin/Company/companyProfileForms";
import { Schema, Tag } from "rsuite";
const { ArrayType, StringType } = Schema.Types;
import _ from "lodash";

import TransTxt from "@src/components/common/SxFormatMessage";

export const initialAffiliatesFilterForm = {
  social_media: {
    value: "",
    verbose: "Social Media",
    type: "text",
  },
  status: {
    value: [],
    verbose: "Status",
    type: "text",
    data: [
      {
        value: "AC",
        display_name: (
          <Tag color="green">
            <strong>Active</strong>
          </Tag>
        ),
        label: (
          <Tag color="green">
            <strong>Active</strong>
          </Tag>
        ),
      },
      {
        value: "IN",
        display_name: (
          <Tag color="red">
            <strong>Inactive</strong>
          </Tag>
        ),
        label: (
          <Tag color="red">
            <strong>Inactive</strong>
          </Tag>
        ),
      },
      {
        value: "CA",
        display_name: (
          <Tag color="violet">
            <strong>Candidate</strong>
          </Tag>
        ),
        label: (
          <Tag color="violet">
            <strong>Candidate</strong>
          </Tag>
        ),
      },
    ],
  },
  share: {
    value: "",
    verbose: "Share",
    type: "text",
  },
  connect: {
    value: "",
    verbose: "Connect",
    type: "text",
  },
  postal_address: {
    value: "",
    verbose: <TransTxt id="address" startCase />,
    placeholder: "Address",
    type: "text",
  },
  postal_city: {
    value: "",
    verbose: <TransTxt id="city" startCase />,
    placeholder: "City",
    type: "text",
  },
  postal_post_code: {
    value: "",
    verbose: <TransTxt id="post_code" startCase />,
    placeholder: "Post Code",
    type: "text",
  },
  postal_region: {
    value: "",
    verbose: <TransTxt id="region" startCase />,
    placeholder: "Region",
    type: "text",
  },
  postal_country: {
    value: "",
    verbose: <TransTxt id="country" startCase />,
    placeholder: "Country",
    type: "text",
  },
  billing_address: {
    value: "",
    verbose: "Billing Address",
    type: "text",
  },
  licence: {
    value: "",
    verbose: "Licence",
    type: "text",
  },
  address: {
    value: "",
    verbose: "Address",
    type: "text",
  },
  id: {
    value: "",
    verbose: <TransTxt id="uid" upperCase />,
    placeholder: "C234uo23uU",
    type: "text",
  },
  business_name: {
    value: "",
    verbose: <TransTxt id="affiliates_business_name" startCase />,
    placeholder: "Shaun Company",
    type: "text",
  },
  post_code: {
    value: "",
    verbose: "Post Code",
    type: "text",
  },
  region: {
    value: "",
    verbose: "Region",
    type: "text",
  },
  country: {
    value: "",
    verbose: "Country",
    type: "text",
  },
  brand_name: {
    value: "",
    verbose: <TransTxt id="affiliates_brand_name" startCase />,
    placeholder: "Shaun Company",
    type: "text",
  },
  city: {
    value: "",
    verbose: "City",
    type: "text",
  },
  person_name: {
    value: "",
    verbose: <TransTxt id="contact_person" startCase />,
    placeholder: "Shaun",
    type: "text",
  },
  phone: {
    value: "",
    verbose: <TransTxt id="phone" startCase />,
    placeholder: "+(86)2033248897",
    type: "text",
  },
  mobile_phone: {
    value: "",
    verbose: <TransTxt id="mobile_phone" startCase />,
    placeholder: "+(86)18547632876",
    type: "text",
  },
  legal_title: {
    value: "",
    verbose: "Legal Title",
    type: "text",
  },
  registration_code: {
    value: "",
    verbose: <TransTxt id="registration_code" startCase />,
    placeholder: "D3249203U",
    type: "text",
  },
  license_number: {
    value: "",
    verbose: "License Number",
    type: "text",
  },
  tax_office: {
    value: "",
    verbose: <TransTxt id="tax_office" startCase />,
    placeholder: "Guangzhou",
    type: "text",
  },
  email: {
    value: "",
    verbose: <TransTxt id="email" startCase />,
    placeholder: "20218@qq.com",
    type: "text",
  },
  email_xxx: {
    value: "",
    verbose: "Account E-mail",
    placeholder: "998889@qq.com",
    type: "text",
  },
  entity_type: {
    value: [],
    verbose: "Entity Type",
    labelKey: "display_name",
  },
  expertise_type: {
    value: [],
    verbose: "Expertise Type",
    labelKey: "display_name",
  },
  license_type: {
    value: "",
    verbose: "License Type",
  },
  license_type_choices: {
    value: [],
    verbose: "License Type",
    labelKey: "display_name",
  },
  creator: {
    value: "",
    verbose: <TransTxt id="created_by" startCase />,
    type: "text",
    placeholder: "Eileen",
  },
  last_editor: {
    value: "",
    verbose: <TransTxt id="last_edited_date" startCase />,
    type: "text",
  },
  created: {
    value: [],
    placement: "left",
    verbose: <TransTxt id="created_date" startCase />,
  },
  edited: {
    value: [],
    placement: "left",
    verbose: <TransTxt id="last_edited_date" startCase />,
  },
  url: {
    value: "",
    verbose: <TransTxt id="url" upperCase />,
    placeholder: "www.12306.com",
    type: "text",
  },
  founding_year: {
    value: "",
    verbose: <TransTxt id="founding_year" startCase />,
    placeholder: "12/10/1998",
    type: "text",
  },
  travel_sector: {
    value: [],
    verbose: "travel_sector",
  },
  travel_services: {
    value: "",
    verbose: "Travel Services",
    type: "text",
  },
  last_name: {
    value: "",
    verbose: <TransTxt id="last_name" startCase />,
    type: "text",
  },
  first_name: {
    value: "",
    verbose: <TransTxt id="first_name" startCase />,
    type: "text",
  },
};

export const initialAffiliatesBaseDataForm = _.merge(
  initialCompanyBaseDataForm,
  {
    travel_services: {
      value: [],
      required: false,
      verbose: "Travel Services",
      validator: ArrayType().unrepeatable("Duplicate services cannot appear."),
    },
    currency: {
      value: "",
      verbose: "currency",
      required: false,
      placement: "top",
    },
    name: {
      value: "",
      required: true,
      verbose: "brand_name",
      type: "text",
      validator: StringType().isRequired("Brand Name is required"),
    },
  }
);

export const initialAffiliatesNatureOfBizForm = {
  entity_type: {
    value: [],
    required: false,
    verbose: "Entity Type",
    validator: ArrayType()
      .unrepeatable()
      .minLength(1, "At least one entity type"),
  },
  expertise_type: {
    value: [],
    required: false,
    verbose: "Expertise Type",
    validator: ArrayType()
      .unrepeatable()
      .minLength(1, "At least one expertise type"),
  },
  license_type: {
    value: [],
    required: false,
    verbose: "License Type",
    validator: ArrayType().addRule((value) => {
      return value.every((v) => v[1] !== "");
    }, "License code cannot be empty."),
  },
};

export const defaultOption = {
  basic: {
    value: ["name", "status"],
  },
};

export * from "./columns";
