import {
  // ==================== REPLACEABLE ACCOMMODATIONS ===================
  BIDREQUEST_SHOW_REPLACEABLE_TRP_MODAL,
  BIDREQUEST_HIDE_REPLACEABLE_TRP_MODAL,
  BIDREQUEST_SAVE_REPLACEABLE_TRP,
  BIDREQUEST_REVERT_REPLACEABLE_TRP,
  // ================ COMMON REPLACEABLE SERVICE ACTIONS ===============
  REPLACEABLE_SERVICE_SAVING,
  REPLACEABLE_SERVICE_SAVED,
} from "./types";

import {
  initCustomFlight,
  initCustomFlightFromData,
} from "@src/actions/Project/CustomFlight";

import MessageFormatter from "@src/validators/MessageFormatter";
import validateFlight from "@src/validators/Project/CustomFlight";

import {
  getCustomSrvCommonPayload,
  getCustomSrvFormData,
} from "@src/tools/CustomServices";

import { saveReplaceableSrv } from "./common_replaceable_srv_actions";

import { offerSave } from "./index";

import { Notification } from "rsuite";
import React from "react";

import _ from "lodash";

// ======================= REPLACEABLE TRANSPORTATIONS =======================

export const showReplaceableTrpModal = (destOrder, trpType) => async (
  dispatch,
  getState
) => {
  const flOrder = destOrder == 0 ? "extremal" : destOrder;

  const flight = _.get(
    getState().bidRequestOfferData,
    `replaceable_flights.${flOrder}`,
    null
  );

  if (!flight) {
    await dispatch(initCustomFlight());
  } else {
    await dispatch(initCustomFlightFromData(flight));
  }

  dispatch({
    type: BIDREQUEST_SHOW_REPLACEABLE_TRP_MODAL,
    destOrder,
    trpType,
  });
};

export const hideReplaceableTrpModal = () => {
  return { type: BIDREQUEST_HIDE_REPLACEABLE_TRP_MODAL };
};

export const saveReplaceableTrp = (flOrder, srvType = "FL") => async (
  dispatch,
  getState
) => {
  const state = getState();
  const offer = state.bidRequestOfferData;
  const flData = state.customFlight;

  const msgs = validateFlight(flData);
  if (msgs.length) {
    const desc = <MessageFormatter msgs={msgs} />;

    try {
      Notification.close("replaceable_flight_notification");
    } finally {
      Notification.error({
        key: "replaceable_flight_notification",
        title: "Flight Validation Error",
        description: desc,
        duration: 10000,
      });
    }

    return;
  }

  dispatch({ type: REPLACEABLE_SERVICE_SAVING });

  var customSrvPayload = getCustomSrvCommonPayload(offer.id, "FL");
  customSrvPayload["service_data"] = JSON.stringify(flData);
  const formDataPayload = getCustomSrvFormData(customSrvPayload);

  const service_data = await saveReplaceableSrv(formDataPayload, flData.uid);

  if (service_data.data !== null) {
    service_data.data.uid = service_data.service_id;

    await dispatch({
      type: BIDREQUEST_SAVE_REPLACEABLE_TRP,
      flOrder,
      flData: service_data.data,
    });
    await dispatch(offerSave());
    dispatch({ type: REPLACEABLE_SERVICE_SAVED });
  }

  dispatch(hideReplaceableTrpModal());
};

export const revertReplaceableTrp = (flOrder, srvType = "FL") => {
  return { type: BIDREQUEST_REVERT_REPLACEABLE_TRP, flOrder };
};
