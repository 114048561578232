import { Loader, Placeholder } from "rsuite";
import CancellationPolicyDetails from "./CancellationPolicyDetails";
import { WhisperWrapper } from "@src/components/common/ui_helpers";
import {
  AutoSizer,
  List,
  CellMeasurer,
  CellMeasurerCache,
} from "react-virtualized";

import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import LeanAuthHoc from "@src/components/authorization/LeanAuthHoc";
import { Icon } from "@iconify/react";
import { CustomButton } from "@src/components/common/buttons";
import { HotelPrice } from "./OverviewAllAccommodation";
import { useMutation } from "@tanstack/react-query";
import { accCxlDetails } from "@src/api";
import { toast } from "react-toastify";
import { variables } from "@src/jsssetup";

export class Virtualizer extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { rowCount, cache, rowRenderer } = this.props;

    return (
      <AutoSizer>
        {({ height, width }) => (
          <List
            height={height}
            width={width}
            rowCount={rowCount}
            deferredMeasurementCache={cache}
            rowHeight={cache.rowHeight}
            rowRenderer={rowRenderer}
            scrollToIndex={0}
          />
        )}
      </AutoSizer>
    );
  }
}

Virtualizer.propTypes = {
  rowCount: PropTypes.number.isRequired,
  cache: PropTypes.object.isRequired,
  rowRenderer: PropTypes.func.isRequired,
};

var VendorInfo = ({ provider }) => {
  return (
    <span className="attribute">
      <Icon icon="clarity:employee-solid" />
      <strong className="attribute__title">Vendor:</strong>
      <span> {_.startCase(provider)}</span>
    </span>
  );
};
VendorInfo.propTypes = { provider: PropTypes.string.isRequired };

function AltComp() {
  return <span />;
}

VendorInfo = LeanAuthHoc(VendorInfo, {
  allowed_groups: ["member"],
  AltComponent: AltComp,
});

export const RoomDetails = ({ room, isLoading = false, fetchCxl }) => {
  return (
    <div className="RoomDetails">
      <span className="attribute">
        <Icon icon="ic:baseline-meeting-room" />
        <strong className="attribute__title">Room Type:</strong>
        <span> {_.startCase(room.type)}</span>
      </span>
      <span className="attribute">
        <Icon icon="ic:baseline-person" />
        <strong className="attribute__title">Bedding:</strong>
        <span> {_.startCase(room.bedding)}</span>
      </span>
      <span className="attribute">
        <Icon icon="fluent:document-ribbon-24-filled" />
        <strong className="attribute__title">Max Pax:</strong>
        <span>{` ${room.pax.adults} Adults${
          room.pax.children > 0 ? "," + room.pax.children + " Children" : ""
        }`}</span>
      </span>
      <span className="attribute">
        <Icon icon="ic:baseline-bed" />
        <strong className="attribute__title">Board:</strong>
        <span> {room.board_display}</span>
      </span>
      <span className="attribute">
        <Icon icon="ic:outline-restaurant" />
        <strong className="attribute__title">Rate:</strong>
        <span>
          {[
            ...new Set([
              _.get(room, "rate") || "N/A",
              _.get(room, "rate_description") || "N/A",
            ]),
          ].join(", ")}
        </span>
      </span>
      <VendorInfo provider={room.provider} />
      <span className="attribute">
        <strong>Cancellation Policy</strong>
        {typeof fetchCxl == "function" && room.requirements.fetch_cxl ? (
          <WhisperWrapper msg="Refresh cancellation policy">
            <Icon
              className="RoomDetails__refresh-cxl"
              icon={isLoading ? "line-md:loading-twotone-loop" : "mdi:reload"}
              onClick={fetchCxl}
            />
          </WhisperWrapper>
        ) : null}
      </span>
    </div>
  );
};
RoomDetails.propTypes = {
  room: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  roomIsDisabled: PropTypes.bool.isRequired,
  fetchCxl: PropTypes.func,
};

export const RoomCancellationPolicy = ({
  isLoading = false,
  cancellation_policies,
  isDisabled = false,
}) => {
  return (
    <div
      className={`RoomCancellationPolicy${
        isLoading ? " RoomCancellationPolicy--loading" : ""
      }`}>
      {isLoading ? (
        <Placeholder.Paragraph rows={2} active rowMargin={5} />
      ) : cancellation_policies.length > 0 ? (
        <CancellationPolicyDetails
          cxl_policies={cancellation_policies}
          isDisabled={isDisabled}
        />
      ) : (
        <span className="RoomCancellationPolicy__no-cxl-label">
          No cancellation policy found. Please click on the refresh button to
          fetch the cancellation policy.
        </span>
      )}
    </div>
  );
};
RoomCancellationPolicy.defaultProps = { isLoading: false };
RoomCancellationPolicy.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  cancellation_policies: PropTypes.array.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

const Room = ({
  rowIdx,
  isSelected,
  session_id,
  accommodationId,
  room,
  disabledBookingCodes = [],
  onDisable,
}) => {
  const [cxlDetails, setCxlDetails] = useState(null);

  const mutation = useMutation({
    mutationKey: ["acc_cxl_details", room.booking_code],
    mutationFn: ({ session_id, accommodationId, booking_code, supplier }) =>
      accCxlDetails(session_id, accommodationId, booking_code, supplier),
    onSuccess: (data, __) => setCxlDetails(data?.data ?? null),
    onError: (__, variables) => {
      toast.error("Error fetching cancellation policy", {
        autoClose: 3000,
      });
      onDisable(variables.booking_code);
    },
  });

  const roomIsDisabled = disabledBookingCodes.includes(room.booking_code);
  const title = `${rowIdx + 1}. ${_.startCase(room.name)}`;
  return !accommodationId ? null : (
    <div className="Room">
      <span
        className={`Room__title${
          roomIsDisabled ? " Room__title--disabled" : ""
        }`}>
        {roomIsDisabled ? (
          <span className="Room__title__disabled">{title}</span>
        ) : (
          title
        )}
        <span className="Room__title__not-available-label">
          {roomIsDisabled && " (Not available)"}
        </span>
      </span>
      <RoomDetails
        rowIdx={rowIdx}
        room={room}
        isLoading={mutation.isLoading}
        fetchCxl={() => {
          mutation.mutate({
            session_id,
            accommodationId,
            booking_code: room.booking_code,
            supplier: room.supplier,
          });
        }}
        accommodationId={accommodationId}
        session_id={session_id}
        isSelected={isSelected}
        roomIsDisabled={roomIsDisabled}
      />
      <RoomCancellationPolicy
        isLoading={mutation.isLoading}
        cancellation_policies={
          (cxlDetails?.cxl_policies ?? []).length > 0
            ? cxlDetails.cxl_policies
            : room.cancellation_policies
        }
        isDisabled={roomIsDisabled}
      />
    </div>
  );
};
Room.defaultProps = { isSelected: false, disabledBookingCodes: [] };
Room.propTypes = {
  rowIdx: PropTypes.number.isRequired,
  room: PropTypes.object.isRequired,
  accommodationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  session_id: PropTypes.string,
  isSelected: PropTypes.bool.isRequired,
  disabledBookingCodes: PropTypes.array.isRequired,
  onDisable: PropTypes.func.isRequired,
};

const Rooms = ({
  filters,
  isLoading = false,
  accommodation,
  roomGroups,
  session_id,
  destOrder,
  selectedRoomsIds,
  disabledBookingCodes = [],
  onDisable,
  onSelectRoom,
  onCloseDetails,
}) => {
  const [multipleRooms, setMultipleRooms] = useState(false);

  useEffect(() => {
    if (roomGroups.length > 0)
      setMultipleRooms(roomGroups[0][1].length > 1 ? true : false);
  }, [roomGroups]);

  const [roomVirtualizerCache, setRoomVirtualizerCache] = useState(
    new CellMeasurerCache({
      defaultHeight: multipleRooms ? 400 : 190,
      minHeight: multipleRooms ? 400 : 190,
      fixedWidth: true,
    })
  );

  function isRoomSelected(room_id) {
    return selectedRoomsIds
      .map((id) => id.toString())
      .includes(room_id.toString());
  }

  function renderPrice(price, currency, code, isSelected, isDisabled = false) {
    return (
      <div
        className={`RoomGroup__price${
          isDisabled ? " RoomGroup__price--disabled" : ""
        }`}>
        <HotelPrice price={price} currency={currency} />
        {isDisabled ? null : (
          <CustomButton
            onClick={async () => {
              await onSelectRoom(destOrder, accommodation, code);
              onCloseDetails();
            }}>
            {`${isSelected ? "Selected" : "Select"}`}
          </CustomButton>
        )}
      </div>
    );
  }

  useEffect(() => {
    setRoomVirtualizerCache(
      new CellMeasurerCache({
        defaultHeight: multipleRooms ? 400 : 190,
        minHeight: multipleRooms ? 400 : 190,
        fixedWidth: true,
      })
    );
  }, [filters, multipleRooms]);

  return (
    <div className="Rooms">
      {isLoading ? (
        <Loader center size="lg" backdrop />
      ) : (
        <Virtualizer
          rowCount={roomGroups.length}
          cache={roomVirtualizerCache}
          rowRenderer={({ index, key, parent, style }) => {
            var group = roomGroups[index];
            const room_id = group[0];
            const isSelected = isRoomSelected(room_id);

            return (
              <CellMeasurer
                cache={roomVirtualizerCache}
                key={key}
                parent={parent}
                columnIndex={0}
                rowIndex={index}>
                {({ registerChild }) => {
                  const roomIsDisabled = disabledBookingCodes.includes(
                    group[1][0].booking_code
                  );
                  return (
                    <div
                      ref={registerChild}
                      className="RoomGroup"
                      style={{ ...style, paddingBottom: variables.double_gap }}>
                      <div>
                        {group[1].map((r, jidx) => (
                          <Room
                            rowIdx={index}
                            key={jidx}
                            room={r}
                            disabledBookingCodes={disabledBookingCodes}
                            onDisable={onDisable}
                            accommodationId={accommodation.metadata.id}
                            session_id={session_id}
                            isSelected={isSelected}
                          />
                        ))}
                      </div>
                      {renderPrice(
                        group[1][0].price.value,
                        group[1][0].price.currency,
                        room_id,
                        isSelected,
                        roomIsDisabled
                      )}
                    </div>
                  );
                }}
              </CellMeasurer>
            );
          }}
        />
      )}
    </div>
  );
};
Rooms.defaultProps = {
  isLoading: false,
  selectedRoomsIds: [],
  disabledBookingCodes: [],
};
Rooms.propTypes = {
  filters: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  session_id: PropTypes.string,
  selectedRoomsIds: PropTypes.array,
  destOrder: PropTypes.number.isRequired,
  accommodation: PropTypes.object.isRequired,
  roomGroups: PropTypes.array.isRequired,
  disabledBookingCodes: PropTypes.array.isRequired,
  onDisable: PropTypes.func.isRequired,
  onSelectRoom: PropTypes.func.isRequired,
  onCloseDetails: PropTypes.func.isRequired,
};
export default Rooms;
