import _ from "lodash";
import { Divider } from "rsuite";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { modalGenericStyles, variables } from "@src/jsssetup";
import { currencies } from "@src/definitions";
import { Formik, Form } from "formik";
import { NormalInputField, NormalSelectField } from "@src/components/forms";
import Fuse from "fuse.js";
import { useDispatch, useSelector } from "react-redux";
import { addDbDCustomSrv } from "@src/actions/Project/TripPlanner/day_by_day/custom_services";
import { DateTime, Duration } from "luxon";
import { getTripDestinations } from "@src/selectors/Project/TripPlanner";
import { humanizeDuration } from "../../../../../../tools/date_tools";

function coster({ required_dur_minutes, day_cost, coordinator }) {
  const operation_duration = coordinator?.operation_duration ?? 0;
  const overtime_amount = coordinator?.overtime_amount ?? 0;

  if (required_dur_minutes <= operation_duration) {
    return day_cost;
  }

  const durdiff = required_dur_minutes - operation_duration;

  return parseFloat(
    (Number(day_cost) + overtime_amount * Math.ceil(durdiff / 60)).toFixed(2)
  );
}

const coordinatorFormStyles = createUseStyles({
  durationForm: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    borderTop: `2px solid ${variables.colors.easy.lightOrange2}`,
    padding: variables.half_gap,
  },
  durations: (props) => ({
    display: "grid",
    gridGap: variables.half_gap,
    gridTemplateColumns: `repeat(${props.days > 4 ? 4 : props.days}, 1fr)`,
  }),
  durationFieldContainer: {
    "display": "grid",
    "gridGap": `calc(${variables.normal_gap} / 2)`,
    "position": "relative",
    "border": `2px solid ${variables.colors.easy.lightOrange2}`,
    "padding": variables.half_gap,
    "borderRadius": "5px",
    "& input": {
      width: "100%",
    },
  },
  durationFieldPrice: {
    padding: `calc(${variables.normal_gap} / 2)`,
    color: "white",
    background: "green",
    fontWeight: "bold",
    textAlign: "center",
    borderRadius: `calc(${variables.normal_gap} / 2)`,
  },
  actions: {
    display: "grid",
    gridAutoFlow: "column",
    gridGap: variables.half_gap,
  },
});
export const CoordinatorForm = ({ coordinator, onSelect, children }) => {
  const [editBasePrice, setEditBasePrice] = useState(false);
  const [daily_durations, setDailyDurations] = useState([]);
  const [daily_cost, setDailyCost] = useState([]);

  const { currency } = coordinator;

  const durationInitialValues = {};
  Object.entries(coordinator.daily_cost).forEach(([___, data]) => {
    durationInitialValues[data.date] = {
      duration: 60 * 10, // 10 Hours default duration
      amount: data.amount,
    };
  });

  const classes = coordinatorFormStyles({
    days: Object.keys(durationInitialValues).length,
  });

  return (
    <Formik
      initialValues={durationInitialValues}
      validateOnMount={true}
      validate={(values) => {
        setDailyDurations(
          Object.entries(values).map((entry) => ({
            date: entry[0],
            duration: entry[1].duration,
          }))
        );
        setDailyCost(
          Object.entries(values).map(([date, data]) => ({
            date,
            amount: coster({
              required_dur_minutes: data.duration,
              day_cost: data.amount,
              coordinator,
            }),
          }))
        );
      }}>
      {({ values }) => (
        <React.Fragment>
          <Form className={classes.durationForm}>
            {editBasePrice ? (
              <button
                className="Button"
                data-ghost="true"
                onClick={() => setEditBasePrice(false)}>
                Edit Duration
              </button>
            ) : (
              <button className="Button" onClick={() => setEditBasePrice(true)}>
                Edit Base Price
              </button>
            )}
            <div className={classes.durations}>
              {Object.entries(values).map(([key, value], idx) => {
                const dateStr = DateTime.fromISO(key).toLocaleString(
                  DateTime.DATE_MED
                );

                const durStr = humanizeDuration(value.duration * 60 * 1000);
                return (
                  <div key={idx} className={classes.durationFieldContainer}>
                    {editBasePrice ? (
                      <span className={classes.durationFieldPrice}>
                        {value.duration} Minutes
                      </span>
                    ) : (
                      <NormalInputField
                        type="number"
                        label={`Dur. on ${dateStr} (${durStr})`}
                        name={`${key}.duration`}
                      />
                    )}
                    <span>
                      <strong>Price for {dateStr}</strong>
                    </span>
                    {editBasePrice ? (
                      <NormalInputField
                        type="number"
                        label={`Price for ${dateStr}`}
                        name={`${key}.amount`}
                      />
                    ) : (
                      <span className={classes.durationFieldPrice}>
                        {currencies[currency]}
                        {coster({
                          required_dur_minutes: value.duration,
                          day_cost: value.amount,
                          coordinator,
                        })}
                      </span>
                    )}
                  </div>
                );
              })}
            </div>
          </Form>
          <Divider />
          <div className={classes.actions}>
            {children}
            <button
              className="Button"
              data-success="true"
              data-size="xs"
              onClick={() => onSelect({ daily_durations, daily_cost })}>
              Select
            </button>
          </div>
        </React.Fragment>
      )}
    </Formik>
  );
};
CoordinatorForm.propTypes = {
  coordinator: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
};

const FiltersForm = ({ onFilter }) => {
  return (
    <Formik
      initialValues={{
        name: "",
        language: "",
        gender: "",
        has_driving_license: null,
        has_travel_license: null,
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validateOnMount={false}
      onSubmit={(values) => onFilter(values)}>
      {({ handleReset, submitForm }) => (
        <div className="Card">
          <div className="Card__body">
            <Form className="Form">
              <NormalInputField name="name" label="Name" />
              <NormalSelectField
                name="gender"
                label="Gender"
                options={[
                  [null, "------"],
                  ["MA", "Male"],
                  ["FA", "Female"],
                ]}
              />
              <NormalSelectField
                name="language"
                label="Language"
                options={[
                  [null, "------"],
                  ["en", "English"],
                  ["cn", "Chinese"],
                ]}
              />
              <NormalSelectField
                name="driving_license"
                label="Driving License"
                options={[
                  [null, "------"],
                  [1, "Yes"],
                  [0, "No"],
                ]}
              />
              <NormalSelectField
                name="travel_license"
                label="Travel License"
                options={[
                  [null, "------"],
                  [1, "Yes"],
                  [0, "No"],
                ]}
              />
            </Form>
          </div>
          <div className="Card__actions">
            <button
              className="Button"
              data-ghost="true"
              type="button"
              onClick={handleReset}>
              Reset
            </button>
            <button className="Button" onClick={submitForm}>
              Filter
            </button>
          </div>
        </div>
      )}
    </Formik>
  );
};
FiltersForm.defaultProps = {
  restaus: [],
};
FiltersForm.propTypes = {
  restaus: PropTypes.array.isRequired,
  onFilter: PropTypes.func.isRequired,
};

const CoordinatorStyles = createUseStyles({
  card: modalGenericStyles.card,
  header: modalGenericStyles.cardHeader,
  headerActions: {
    display: "grid",
    gridAutoRows: "max-content",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    alignItems: "center",
  },
  headerPrice: {
    borderRadius: "5px",
    fontWeight: "bold",
    padding: `calc(${variables.normal_gap} / 2)`,
    background: "green",
    color: "white",
  },
  headerAttribute: {
    fontSize: "smaller",
  },
  body: {
    ...modalGenericStyles.cardBody,
    minHeight: "unset",
    display: "grid",
    gridGap: variables.normal_gap,
    gridAutoRows: "max-content",
    alignItems: "start",
  },
  bodyHeader: {
    fontWeight: "bold",
  },
  coordinatorDetails: {
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  bodySection: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
  },
});
const Coordinator = ({
  idx,
  dest_date_couples,
  destination_ids,
  coordinator,
  onSelect,
}) => {
  const classes = CoordinatorStyles();
  const [viewDetails, setViewDetails] = useState(false);
  const { total_price: price, currency } = coordinator;

  const durationInitialValues = {};
  Object.entries(coordinator.daily_cost).forEach(([___, data]) => {
    durationInitialValues[data.date] = {
      duration: 60 * 10, // 10 Hours default duration
      amount: data.amount,
    };
  });

  const destinations = useSelector((state) => getTripDestinations(state));

  const hours = parseInt(_.get(coordinator, "operation_duration", 0) / 60, 10);
  const minutes = _.get(coordinator, "operation_duration", 0) % 60;

  if (price === 0) {
    return null;
  }
  return (
    <div className={classes.card}>
      <div className={classes.header}>
        <h6>
          {idx}. {coordinator.name_en}{" "}
          {coordinator.specialty.length
            ? `(${coordinator.specialty_display.join(", ")})`
            : ""}
          <br />
          <span className={classes.headerAttribute}>
            Internal Rating: {coordinator.internal_rating}
          </span>
          <br />
          <span className={classes.headerAttribute}>
            Experience: {coordinator.experience_years} Years
          </span>
          <br />
          <span className={classes.headerAttribute}>
            Overtime Fee: {currencies[currency]}
            {coordinator.overtime_amount}
          </span>
          <br />
          <span className={classes.headerAttribute}>
            Spoken Languages:{" "}
            {_.get(coordinator, "languages", [])
              .map((lng) => lng.toUpperCase())
              .join(", ")}
          </span>
        </h6>
        <div className={classes.headerActions}>
          <span className={classes.headerPrice}>
            Price: {currencies[currency]}
            {price}
          </span>
          <button
            className="Button"
            data-ghost={viewDetails ? "true" : "false"}
            onClick={() => setViewDetails((p) => !p)}>
            {viewDetails ? "Hide Details" : "View Details"}
          </button>
        </div>
      </div>
      {viewDetails && (
        <div className={classes.body}>
          <div className={classes.coordinatorDetails}>
            <div className={classes.bodySection}>
              <span className={classes.bodyHeader}>Operation Duration</span>
              <span>{Duration.fromObject({ hours, minutes }).toHuman()}</span>
              <span className={classes.bodyHeader}>Internal Notes</span>
              <p>{coordinator.notes || "N/A"}</p>
            </div>
            <div className={classes.bodySection}>
              <span className={classes.bodyHeader}>Gender</span>
              <p>{coordinator.gender === "MA" ? "Male" : "Female"}</p>
              <span className={classes.bodyHeader}>
                Driving License:{" "}
                {coordinator.has_driving_license ? "Yes" : "No"}
              </span>
              <span className={classes.bodyHeader}>
                Travel License: {coordinator.has_travel_license ? "Yes" : "No"}
              </span>
            </div>
          </div>
          <CoordinatorForm
            coordinator={coordinator}
            onSelect={({ daily_durations, daily_cost }) =>
              onSelect({
                ...coordinator,
                daily_durations,
                daily_cost,
                destination_id: destination_ids[0],
                destination_ids,
                destination_date_couples: dest_date_couples,
                destinations: destinations.filter((dest) =>
                  destination_ids.includes(`${dest["type"]}__${dest["id"]}`)
                ),
              })
            }
          />
        </div>
      )}
    </div>
  );
};
Coordinator.propTypes = {
  destination_ids: PropTypes.array.isRequired,
  dest_date_couples: PropTypes.array.isRequired,
  idx: PropTypes.number.isRequired,
  coordinator: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
};

const fuseCfg = {
  shouldSort: false,
  threshold: 0.5,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ["name_en"],
};
const styles = createUseStyles({
  CoordinatorsList: { width: "90vw", maxWidth: "90vw" },
  body: {
    display: "grid",
    gridTemplateColumns: "1fr 4fr",
    gridGap: variables.normal_gap,
    overflow: "hidden",
    alignItems: "start",
  },
  list: {
    display: "grid",
    gridGap: variables.normal_gap,
    gridAutoRows: "max-content",
    overflow: "auto",
    height: "100%",
  },
  filters: { ...modalGenericStyles.card, minWidth: "unset" },
  filtersHeader: modalGenericStyles.cardHeader,
});
export default function CoordinatorsList(props) {
  const {
    loading,
    data,
    searchDaysIdxs,
    destination_ids,
    dest_date_couples,
    onCancel,
  } = props;

  const [filters, setFilters] = useState({});
  const [filteredData, setFilteredData] = useState(data);

  const dispatch = useDispatch();
  const onSelect = useCallback(
    ({ dayIdx, srv }) =>
      dispatch(addDbDCustomSrv({ dayIdx, srv, srv_type: "COO" })),
    [dispatch]
  );

  useEffect(() => {
    var fData = data;
    if (filters.name) {
      const fuseData = new Fuse(fData, fuseCfg);
      fData = fuseData.search(filters.name);
    }

    if (filters.language) {
      fData = fData.filter((dt) => dt.languages.includes(filters.language));
    }

    if (filters.gender) {
      fData = fData.filter((dt) => dt.gender === filters.gender);
    }

    if (filters.driving_license) {
      fData = fData.filter(
        (dt) => dt.has_driving_license === Boolean(filters.driving_license)
      );
    }

    if (filters.travel_license) {
      fData = fData.filter(
        (dt) => dt.has_travel_license === Boolean(filters.travel_license)
      );
    }

    setFilteredData(fData);
  }, [data, filters]);

  const classes = styles();
  return (
    !loading && (
      <div className={`${classes.CoordinatorsList} Modal__card`}>
        <div className="Modal__card__header">
          <h5>Available Coordinators</h5>
        </div>
        <div className={`${classes.body} Modal__card__body`}>
          <div className={classes.filters}>
            <div className={classes.filtersHeader}>
              <h6>Filters</h6>
            </div>
            <FiltersForm onFilter={(filters) => setFilters(filters)} />
          </div>
          <div className={classes.list}>
            {filteredData.map((coordinator, idx) => (
              <Coordinator
                key={idx}
                dest_date_couples={dest_date_couples}
                destination_ids={destination_ids}
                coordinator={coordinator}
                idx={idx + 1}
                onSelect={async (srv) => {
                  for (let key in searchDaysIdxs) {
                    let dayIdx = parseInt(
                      searchDaysIdxs[key].split("___")[1],
                      10
                    );
                    await onSelect({ dayIdx, srv });
                  }

                  onCancel();
                }}
                onCloseModal={onCancel}
              />
            ))}
          </div>
        </div>
        <div className="Modal__card__actions">
          <button className="Button" data-ghost="true" onClick={onCancel}>
            Cancel
          </button>
        </div>
      </div>
    )
  );
}
CoordinatorsList.defaultProps = {
  dest_date_couples: [],
  destination_ids: [],
  loading: false,
  data: [],
  searchDaysIdxs: [],
};
CoordinatorsList.propTypes = {
  dest_date_couples: PropTypes.array.isRequired,
  destination_ids: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  searchDaysIdxs: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};
