import service from "@src/api/request";

export function getIndependentAgentsList(para) {
  return service({
    url: "/bid/independent-agent/",
    method: "get",
    params: para,
  });
}

export function getQuickIndependentAgentsList(para) {
  return service({
    url: "/bid/independent-agent/get_quick_info",
    method: "get",
    params: para,
  });
}

export function fetchIndependentAgentsProfile(id) {
  return service({
    url: `/bid/independent-agent/${id}`,
    method: "get",
  });
}

export function getIndependentAgentsOptions(lang) {
  return service({
    url: "/bid/independent-agent/",
    method: "options",
    headers: {
      "Accept-Language": lang,
    },
  });
}

export function submitIndependentAgentsProfile(id, payload, mode) {
  return service({
    url: `/bid/independent-agent/${id}/`,
    method: mode,
    data: payload,
  });
}

export function addNewIndependentAgents(data) {
  return service({
    url: "/bid/independent-agent/",
    method: "post",
    data: data,
  });
}
