import { Schema } from "rsuite";
const { StringType, NumberType, ArrayType } = Schema.Types;

export const initialCompanyBaseDataForm = {
  legal_title: {
    value: "",
    required: true,
    verbose: "business_name",
    validator: StringType("Business Name can't only with number")
      .isRequired("Business Name is required")
      .addRule((value) => {
        return isNaN(value);
      }, "Business Name can't only with number"),
  },
  registration_code: {
    value: "",
    required: true,
    verbose: "register_code",
    validator: StringType().isRequired("Registration Code is required"),
  },
  name: {
    value: "",
    required: true,
    verbose: "brand_name",
    type: "text",
    validator: StringType().isRequired("Brand Name is required"),
  },
  founding_year: {
    value: "",
    required: true,
    verbose: "founding_year",
    type: "number",
    validator: NumberType("Founding Year should be number").isRequired(
      "Founding Year is required"
    ),
  },
  last_name: {
    value: "",
    required: true,
    verbose: "last_name",
    type: "text",
    validator: StringType().isRequired("Last Name Name is required"),
  },
  first_name: {
    value: "",
    required: true,
    verbose: "first_name",
    type: "text",
    validator: StringType().isRequired("First Name Name is required"),
  },
  email: {
    value: "",
    required: true,
    verbose: "contact_email",
    validator: StringType()
      .isRequired("A valid Email address is required")
      .isEmail("Please enter a valid email address"),
  },
  url: {
    value: "",
    required: false,
    verbose: "url",
    placeholder: "www.travelshoppe.com",
    validator: StringType().isURL("Please enter a valid url"),
  },
  phone: {
    value: "",
    required: true,
    verbose: "phone",
    placeholder: "+8675688889999",
    validator: StringType().isRequired("A phone number is required."),
  },
  mobile_phone: {
    value: "",
    required: false,
    verbose: "mobile_phone",
    placeholder: "+8618998736279",
    validator: StringType(),
  },
  street_number: {
    value: "",
    required: false,
    verbose: "Str. No.",
    validator: StringType(),
  },
  street: {
    value: "",
    required: false,
    verbose: "address",
    placeholder: "No123,abced Road",
    validator: StringType(),
  },
  postal_street_number: {
    value: "",
    required: false,
    verbose: "Str. No.",
    validator: StringType(),
  },
  postal_street: {
    value: "",
    required: true,
    verbose: "address",
    placeholder: "No123,abced Road",
    validator: StringType().isRequired("Address is required"),
  },
  country_code: {
    value: "",
    data: [],
    required: false,
    verbose: "country",
    searchable: true,
    validator: StringType(),
  },
  region: {
    value: "",
    required: false,
    verbose: "region_province_state",
    validator: StringType(),
  },
  postal_country_code: {
    value: "",
    data: [],
    required: true,
    verbose: "country",
    searchable: true,
    validator: StringType().isRequired("Country is required"),
  },
  postal_region: {
    value: "",
    required: false,
    verbose: "region_province_state",
    validator: StringType(),
  },
  city: {
    value: "",
    required: false,
    verbose: "city",
    validator: StringType("City should be string"),
  },
  post_code: {
    value: "",
    required: false,
    verbose: "post_code",
    validator: StringType(),
  },
  postal_city: {
    value: "",
    required: true,
    verbose: "city",
    validator: StringType("Postal city should be string").isRequired(
      "City is required"
    ),
  },
  postal_post_code: {
    value: "",
    required: false,
    verbose: "post_code",
    validator: StringType(),
  },
  brief_description_en: {
    value: "",
    required: false,
    verbose: "About Us",
    validator: StringType(),
  },
  brief_description_cn: {
    value: "",
    required: false,
    verbose: "About Us",
    validator: StringType(),
  },
  original_image: {
    value: "",
    required: false,
    verbose: "",
    fileName: {},
  },
  lat: {
    value: 0,
    required: false,
  },
  lng: {
    value: 0,
    required: false,
  },
  viewport_arr: {
    value: 0,
    required: false,
  },
  weekdays: {
    value: [false, false, false, false, false, false, false],
    required: false,
    verbose: "days",
    validator: ArrayType(),
  },
  working_time_from: {
    value: 0,
    required: false,
    verbose: "",
  },
  working_time_to: {
    value: 0,
    required: false,
    verbose: "",
  },
  timezone: {
    value: "",
    required: false,
    verbose: "",
  },
  currency: {
    value: "",
    verbose: "currency",
    required: false,
    placement: "top",
    // validator: StringType().isRequired("Currency is required"),
  },
};

export const initialCompanyNatureOfBizForm = {
  entity_type: {
    value: [],
    required: false,
    verbose: "Entity Type",
    validator: ArrayType()
      .unrepeatable()
      .minLength(1, "At least one entity type"),
  },
  expertise_type: {
    value: [],
    required: false,
    verbose: "Expertise Type",
    validator: ArrayType()
      .unrepeatable()
      .minLength(1, "At least one expertise type"),
  },
  // We are validating a two dimensional
  // array where the second element of sub-array must not be ""
  //(this is the license code)
  license_type: {
    value: [],
    required: false,
    verbose: "License Type",
    validator: ArrayType()
      .minLength(1, "At least one license type is required.")
      .of(ArrayType())
      .addRule((value) => {
        return value.every((v) => v[1] !== "");
      }, "License code cannot be empty."),
  },
};

export const travelServicesFrom = {
  travel_services: {
    value: [],
    required: false,
    verbose: "Travel Services",
    validator: ArrayType().unrepeatable("Duplicate services cannot appear."),
  },
};
