import {
  //===============================FETCH LIST =============================
  B2C_POLICY_FETCH_DATA,
  B2C_POLICY_BEGIN_FETCH_DATA,
  B2C_POLICY_END_FETCH_DATA,
  //================================FILTER=================================
  B2C_POLICY_INITIALIZE_LIST_FILTER_FORM,
  B2C_POLICY_UPDATE_LIST_FILTER_FORM,
  B2C_POLICY_APPLY_LIST_FILTER,
  B2C_POLICY_RESET_LIST_FILTER_FORM,
  //================================COLUMNS================================
  B2C_POLICY_UPDATE_LIST_COLUMNS_FORM,
  B2C_POLICY_APPLY_LIST_COLUMNS,
  B2C_POLICY_INITIALIZE_LIST_COLUMNS_FORM,
  //=============================FETCH SUB-ENTITIES========================
  B2C_POLICY_FETCH_OPTIONS,
  //=================================GRAPH=================================
  B2C_POLICY_FETCH_GRAPH_DATA,
  //==================================EDIT=================================
  B2C_POLICY_FETCH_EDIT_DATA,
  B2C_POLICY_INIT_BASE_DATA_EDIT_FORM,
  //==================================MODAL================================
  B2C_POLICY_HIDE_FORM_MODAL,
  B2C_POLICY_SHOW_FORM_MODAL,
  B2C_POLICY_LOADING_EDIT_FORM,
  B2C_POLICY_IDLE_EDIT_FORM,
  B2C_POLICY_WEBSITE_DATA,
} from "./types";
import { timeSkipper } from "@src/actions/tools";

import {
  initialB2CPolicyFilterForm,
  defaultOption,
  initialB2CPolicyColumnsSetUp,
} from "@src/forms/Admin/B2CPolicy";

import {
  notifyPolicyAddMessage,
  notifyPolicySuccessfullyCreated,
  notifyPolicyAddError,
} from "@src/components/common/notifications/Policy/PolicyAddNotifications.js";

import {
  getB2CPolicyList,
  addNewB2CPolicy,
  getMarketPolicyOptions,
  submitB2CPolicy,
  getB2CPolicyEditData,
  getB2CWebsite,
} from "@src/api";

import { getPagination } from "@src/tools/form_tools";
import { filterProcessor } from "./filterProcessor";
import update from "immutability-helper";
import { hideGlobalModal } from "@src/actions/Shares";

import { entitiesListSelector } from "@src/reducers/tools";

import { errorHandle as commonErrorHandle } from "@src/tools/common_tools";

import _ from "lodash";

//===============================FETCH LIST =============================
export const fetchB2CPolicyOptions = () => (dispatch, getState) => {
  const b2cPolicyOptions = getState().b2cPolicyOptions;

  const call = timeSkipper(
    () => {
      getMarketPolicyOptions().then((data) => {
        const options = data.actions.POST;
        dispatch({
          type: B2C_POLICY_FETCH_OPTIONS,
          data: {
            ...options,
          },
        });
      });
    },
    b2cPolicyOptions,
    3600
  );
  call();
};

export const beginB2CPolicyListData = () => {
  return { type: B2C_POLICY_BEGIN_FETCH_DATA };
};

export const fetchB2CPolicyData = (data) => {
  return {
    type: B2C_POLICY_FETCH_DATA,
    data: data,
  };
};

export const endB2CPolicyListData = () => {
  return { type: B2C_POLICY_END_FETCH_DATA };
};

export const fetchB2CPolicyList = () => async (dispatch, getState) => {
  dispatch(beginB2CPolicyListData());
  const service_type = getState().travelServiceOptions.service_type || {};
  const filter = filterProcessor(getState().b2cPolicyListFilter, service_type);
  const userMeta = getState().userMeta;
  const userInfo = {
    member: userMeta.company_id,
    // source_content_type: userMeta.company_type,
  };
  const pagination = getPagination(getState().b2cPolicyList);

  try {
    const data = await getB2CPolicyList({
      ...filter,
      ...pagination,
      ...userInfo,
    });
    dispatch(
      fetchB2CPolicyData({
        page: pagination.page,
        limit: pagination.limit,
        ...data,
      })
    );
  } catch (error) {
    commonErrorHandle({ error });
  } finally {
    dispatch(endB2CPolicyListData());
  }
};

export const modifyB2CPolicyPage = (page) => (dispatch, getState) => {
  let b2cPolicyList = getState().b2cPolicyList;
  b2cPolicyList = update(b2cPolicyList, { page: { $set: page } });
  dispatch(fetchB2CPolicyData({ ...b2cPolicyList }));
  dispatch(fetchB2CPolicyList());
};

export const modifyB2CPolicyLimit = (limit) => (dispatch, getState) => {
  let b2cPolicyList = getState().b2cPolicyList;
  b2cPolicyList = update(b2cPolicyList, {
    limit: { $set: limit },
    page: { $set: 1 },
  });

  dispatch(fetchB2CPolicyData({ ...b2cPolicyList }));
  dispatch(fetchB2CPolicyList());
};

export const initializeB2CPolicyListColumnsForm = () => (
  dispatch,
  getState
) => {
  const currentCols = getState().b2cPolicyListColumns;
  dispatch(initializeB2CPolicyListColumnsFormData(currentCols));
};

//================================FILTER=================================
export const initializeB2CPolicyListColumnsFormData = (data) => {
  return {
    type: B2C_POLICY_INITIALIZE_LIST_COLUMNS_FORM,
    data,
  };
};

// filter relation

export const initializesB2CPolicyFilterForm = () => (dispatch, getState) => {
  const state = getState().b2cPolicyListFilter || {};
  dispatch(initializesB2CPolicyFilterFormData(state));
};

export const initializesB2CPolicyFilterFormData = (data) => {
  return {
    type: B2C_POLICY_INITIALIZE_LIST_FILTER_FORM,
    data,
  };
};

export const updateB2CPolicyListFilter = (payload) => {
  return {
    type: B2C_POLICY_UPDATE_LIST_FILTER_FORM,
    data: payload,
  };
};

export const applyB2CPolicyListFilter = () => (dispatch, getState) => {
  const state = getState().b2cPolicyListFilterForm || {};
  dispatch(hideGlobalModal());
  dispatch(applyB2CPolicyListFilterForm(state));
  dispatch(fetchB2CPolicyList(1));
};

export const applyB2CPolicyListFilterForm = (data) => {
  return {
    type: B2C_POLICY_APPLY_LIST_FILTER,
    data,
  };
};

export const resetB2CPolicyListFilter = () => (dispatch, getState) => {
  const data = update(initialB2CPolicyFilterForm, {
    $merge: getState().travelServicesForm,
  });
  dispatch(resetB2CPolicyListFilterForm(data));
};

export const resetB2CPolicyListFilterForm = (data) => {
  return {
    type: B2C_POLICY_RESET_LIST_FILTER_FORM,
    data,
  };
};

//================================COLUMNS================================
export const updateB2CPolicyListColumnsForm = (field, value) => (
  dispatch,
  getState
) => {
  const state = getState().b2cPolicyListColumnsForm || {};
  if (field.type == "push") {
    value = update(new Set(state[field.name].value), { $add: [field.value] });
  }
  if (field.type == "remove") {
    value = update(new Set(state[field.name].value), {
      $remove: [field.value],
    });
  }
  if (field.type == "set") {
    value = field.value;
  }
  const b2cPolicyListColumnsForm = update(state, {
    [field.name]: { value: { $set: [...value] } },
  });
  dispatch(updateB2CPolicyListColumnsFormData(b2cPolicyListColumnsForm));
};

export const updateB2CPolicyListColumnsFormData = (data) => {
  return {
    type: B2C_POLICY_UPDATE_LIST_COLUMNS_FORM,
    data,
  };
};

export const applyB2CPolicyListColumns = () => (dispatch, getState) => {
  const state = getState().b2cPolicyListColumnsForm || {};
  dispatch(hideGlobalModal());
  dispatch(applyB2CPolicyListColumnsForm(state));
};

export const applyB2CPolicyListColumnsForm = (data) => {
  return {
    type: B2C_POLICY_APPLY_LIST_COLUMNS,
    data,
  };
};

export const applyB2CPolicyDefaultOption = () => (dispatch) => {
  const choices = _.flatten(
    Object.values(initialB2CPolicyColumnsSetUp).map((o) => o.choices)
  );
  defaultOption.basic.choices = choices;
  const currentCols = entitiesListSelector(defaultOption);
  dispatch(applyB2CPolicyDefaultOptionForm(currentCols));
};

export const applyB2CPolicyDefaultOptionForm = (data) => {
  return {
    type: B2C_POLICY_INITIALIZE_LIST_COLUMNS_FORM,
    data,
  };
};

//==================================ADD POLICY=================================
export const addB2CPolicyListData = (localForm) => async (
  dispatch,
  getState
) => {
  const state = getState();
  const userMeta = state.userMeta;

  const form = _.omit(localForm, [
    "trf_policies",
    "tr_policies",
    "acc_policies",
    "mi_policies",
  ]);

  let travel_services = form.travel_services_policies.map((e) => e.id);

  if (!form.name) {
    notifyPolicyAddMessage("Policy Name is required");
    return null;
  }

  if (!form.b2c_websites) {
    notifyPolicyAddMessage("B2C Websites is required");
    return null;
  }

  if (travel_services.length == 0) {
    notifyPolicyAddMessage("Services is required");
    return null;
  }

  const entityData = {
    policy_type: "GRO",
    ...form,
    travel_services,
    member: userMeta.company_id,
  };

  dispatch(loadingEditPolicyForm());
  try {
    await addNewB2CPolicy(entityData);
    notifyPolicySuccessfullyCreated("Successfully add at B2C policy");
    dispatch(fetchB2CPolicyList());
  } catch (error) {
    commonErrorHandle({ error });
  } finally {
    dispatch(hidePolicyFormModal());
  }
};

export const b2cPolicyGraphData = (data) => {
  return {
    type: B2C_POLICY_FETCH_GRAPH_DATA,
    data,
  };
};

//======================================EDIT POLICY===============================

export const fetchB2CPolicyEditData = (data) => async (dispatch) => {
  dispatch(loadingEditPolicyForm());
  try {
    const res = await getB2CPolicyEditData(data);
    dispatch(idleB2CPolicyEditForm());
    dispatch(showB2CPolicyEditForm(res));
    await dispatch(b2cPolicyEditData(res));
  } catch (error) {
    notifyPolicyAddError("Something went wrong when fetch B2C Policy");
    dispatch(hidePolicyFormModal());
  }
};

export const b2cPolicyEditData = (data) => {
  return {
    type: B2C_POLICY_FETCH_EDIT_DATA,
    data,
  };
};

export const showB2CPolicyEditForm = (data) => {
  return {
    type: B2C_POLICY_INIT_BASE_DATA_EDIT_FORM,
    data,
  };
};

export const submitB2CPolicyEditData = (localForm) => async (
  dispatch,
  getState
) => {
  const state = getState();
  const userMeta = state.userMeta;
  const edit_id = _.get(state.b2cPolicyEditData, "id");

  const form = _.omit(localForm, [
    "trf_policies",
    "tr_policies",
    "acc_policies",
    "mi_policies",
  ]);
  let travel_services = form.travel_services_policies.map((e) => e.id);

  if (!form.name) {
    notifyPolicyAddMessage("Policy Name is required");
    return null;
  }

  if (!form.b2c_websites) {
    notifyPolicyAddMessage("B2C Websites is required");
    return null;
  }

  if (travel_services.length == 0) {
    notifyPolicyAddMessage("Services is required");
    return null;
  }

  const entityData = {
    policy_type: "GRO",
    ...form,
    travel_services,
    member: userMeta.company_id,
  };

  dispatch(loadingEditPolicyForm());
  try {
    await submitB2CPolicy(edit_id, entityData, "patch");
    notifyPolicySuccessfullyCreated("Successfully update the B2C policy");
    dispatch(fetchB2CPolicyList());
  } catch (error) {
    commonErrorHandle({ error });
  } finally {
    dispatch(hidePolicyFormModal());
  }
};

export const loadingEditPolicyForm = () => {
  return {
    type: B2C_POLICY_LOADING_EDIT_FORM,
  };
};

export const idleB2CPolicyEditForm = () => {
  return {
    type: B2C_POLICY_IDLE_EDIT_FORM,
  };
};

export const hidePolicyFormModal = () => {
  return {
    type: B2C_POLICY_HIDE_FORM_MODAL,
  };
};

export const showPolicyFormModal = (data) => {
  return {
    type: B2C_POLICY_SHOW_FORM_MODAL,
    data,
  };
};

//=================================get Website Info=========================
export const getB2CWebsiteData = () => async (dispatch, getState) => {
  const userMeta = getState().userMeta;
  try {
    const response = await getB2CWebsite({
      source_entity: `${userMeta.company_id}___member`,
    });

    dispatch(saveB2CWebsite(_.get(response, "data.results", [])));
  } catch (error) {
    commonErrorHandle({ error });
  }
};

export const saveB2CWebsite = (data) => {
  return {
    type: B2C_POLICY_WEBSITE_DATA,
    data,
  };
};
