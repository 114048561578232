import React from "react";
import PropTypes from "prop-types";
import { Button, Input } from "rsuite";
import { withRouter } from "react-router-dom";
import { createUseStyles } from "react-jss";
import { variables } from "@src/jsssetup";
import { useHistory } from "react-router-dom";

const pageWentWrong =
  "https://sisistaticassets.s3.ap-southeast-1.amazonaws.com/platform_graphics/imgs/error/logo_page_filler.svg";

const errorPageStyles = createUseStyles({
  ErrorPage: {
    display: "grid",
    placeContent: "center",
    width: "100vw",
    height: "100vh",
    justifyItems: "center",
    gridGap: `calc(${variables.normal_gap} * 2)`,
    background: variables.colors.background.creme,
  },
  img: {
    height: "30vh",
  },
  title: {
    fontSize: "4rem",
  },
});
const ErrorPage = () => {
  const classes = errorPageStyles();
  const history = useHistory();
  return (
    <div className={classes.ErrorPage}>
      <img className={classes.img} src={pageWentWrong} />
      <h1 className={classes.title}>Ooops!</h1>
      <h1 className={classes.title}>Something went wrong</h1>
      <h2>We are on it!</h2>
      <Button
        color="green"
        size="lg"
        onClick={() => history.push(`/admin/company/profile`)}>
        <strong>Go To Home Page</strong>
      </Button>
    </div>
  );
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }
  render() {
    if (this.state.hasError) {
      return <ErrorPage />;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any,
  history: PropTypes.object,
};

export default withRouter(ErrorBoundary);
