import { fetchTripPlan } from "@src/api/Project/TripPlanner";
import { loadTrip, recalculateActions } from "../../TripPlanner";
import {
  PACKAGES_PREVIEW_MODAL_HIDE,
  PACKAGES_PREVIEW_MODAL_IDLE,
  PACKAGES_PREVIEW_MODAL_LOADING,
  PACKAGES_PREVIEW_MODAL_SHOW,
} from "./types";

export const showPreviewPackageModal = (pkgId) => async (dispatch) => {
  await dispatch(loadingPreviewPackageModal());
  dispatch(showPreviewModal(pkgId));
  dispatch(fetchPkgTrip(pkgId));
};

export const showPreviewModal = (pkgId) => {
  return {
    type: PACKAGES_PREVIEW_MODAL_SHOW,
    pkgId,
  };
};

export const hidePreviewPackageModal = () => {
  return { type: PACKAGES_PREVIEW_MODAL_HIDE };
};

export const loadingPreviewPackageModal = () => {
  return { type: PACKAGES_PREVIEW_MODAL_LOADING };
};

export const idlePreviewPackageModal = () => {
  return { type: PACKAGES_PREVIEW_MODAL_IDLE };
};

export const fetchPkgTrip = (id) => async (dispatch) => {
  const data = await fetchTripPlan(id);
  await dispatch(loadTrip(data, "recalculate"));
  dispatch(idlePreviewPackageModal());
};

export const recalculatePkg = () => async (dispatch) => {
  await dispatch(recalculateActions({ search: "sequential" }));
};
