import { Notification } from "rsuite";
import React from "react";

export const notifyCommonError = (error) => {
  Notification.error({
    title: "Something Went Wrong",
    description: (
      <React.Fragment>
        <p>{error}</p>
      </React.Fragment>
    ),
    duration: 5000,
  });
};
