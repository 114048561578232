import {
  //======================= LIST VIEW=============================
  MY_LIBRARY_ADDONS_FETCH_DATA,
  MY_LIBRARY_ADDONS_LOADING_LIST,
  MY_LIBRARY_ADDONS_IDLE_LIST,
  //=======================Filter================================
  MY_LIBRARY_ADDONS_UPDATE_FILTERS,
  //========================DASHBOARD===============================
  MY_LIBRARY_ADDONS_VIEW_TYPE,
  MY_LIBRARY_ADDONS_FETCH_GRAPH_DATA,
  MY_LIBRARY_ADDONS_BEGIN_FETCH_GRAPH_DATA,
  MY_LIBRARY_ADDONS_END_FETCH_GRAPH_DATA,
  MY_LIBRARY_ADDONS_INIT_GRAPH_TYPE,
} from "./types";

import { getMyLibraryList, getMyLibraryAddOnStats } from "@src/api";

import { notifyFetchingArchiveResultsError } from "@src/components/common/notifications/ArchiveNotifications";

import { getPagination } from "@src/tools/form_tools";
import { errorHandle as commonErrorHandle } from "@src/tools/common_tools";

import update from "immutability-helper";
import { DateTime } from "luxon";

// ============================ LIST VIEW ============================

export const loadingMyLibraryAddonsList = () => {
  return { type: MY_LIBRARY_ADDONS_LOADING_LIST };
};

export const fetchMyLibraryAddonsData = (data) => {
  return {
    type: MY_LIBRARY_ADDONS_FETCH_DATA,
    data: data,
  };
};

export const idleMyLibraryAddonsList = () => {
  return { type: MY_LIBRARY_ADDONS_IDLE_LIST };
};

export const fetchAddonsList = (page) => async (dispatch, getState) => {
  const state = getState();
  await dispatch(loadingMyLibraryAddonsList());

  let pagination = getPagination(state.myLibraryAddonsList);
  if (page) {
    pagination.page = page;
  }
  const filter = { ...state.myLibraryAddonsList.filters };

  if (filter.created__gte) {
    filter.created__gte = DateTime.fromISO(filter.created__gte).toISO();
  }
  if (filter.created__lte) {
    filter.created__lte = DateTime.fromISO(filter.created__lte).toISO();
  }

  try {
    const data = await getMyLibraryList({
      ...filter,
      ...pagination,
      service_type: "MI",
    });
    dispatch(
      fetchMyLibraryAddonsData({
        page: pagination.page,
        limit: pagination.limit,
        ...data,
      })
    );
  } catch (error) {
    notifyFetchingArchiveResultsError("Addons");
  } finally {
    dispatch(idleMyLibraryAddonsList());
  }
};

export const modifyMyLibraryAddonsPage = (page) => (dispatch, getState) => {
  const state = getState();
  let myLibraryAddonsList = state.myLibraryAddonsList;
  myLibraryAddonsList = update(myLibraryAddonsList, { page: { $set: page } });
  dispatch(fetchMyLibraryAddonsData({ ...myLibraryAddonsList }));
  dispatch(fetchAddonsList());
};

export const modifyMyLibraryAddonsLimit = (limit) => (dispatch, getState) => {
  const state = getState();
  let myLibraryAddonsList = state.myLibraryAddonsList;
  myLibraryAddonsList = update(myLibraryAddonsList, {
    limit: { $set: limit },
    page: { $set: 1 },
  });

  dispatch(fetchMyLibraryAddonsData({ ...myLibraryAddonsList }));
  dispatch(fetchAddonsList());
};

// ========================= FILTER RELATION =========================
export const updateAddonsFilters = ({ data }) => {
  return { type: MY_LIBRARY_ADDONS_UPDATE_FILTERS, data };
};

// ============================ DASHBOARD ============================
export const changeMyLibraryAddonsViewType = (data) => {
  return {
    type: MY_LIBRARY_ADDONS_VIEW_TYPE,
    data: data,
  };
};

export const beginFetchMyLibraryAddonsGraphData = () => {
  return {
    type: MY_LIBRARY_ADDONS_BEGIN_FETCH_GRAPH_DATA,
  };
};

export const endFetchMyLibraryAddonsGraphData = () => {
  return {
    type: MY_LIBRARY_ADDONS_END_FETCH_GRAPH_DATA,
  };
};

export const fetchMyLibraryAddonsGraphData = (data) => async (dispatch) => {
  dispatch(beginFetchMyLibraryAddonsGraphData());
  try {
    const res = await getMyLibraryAddOnStats({ ...data });
    dispatch(getMyLibraryAddonsGraphData(res));
  } catch (error) {
    commonErrorHandle({ error });
  } finally {
    dispatch(endFetchMyLibraryAddonsGraphData());
  }
};

export const getMyLibraryAddonsGraphData = (data) => {
  return {
    type: MY_LIBRARY_ADDONS_FETCH_GRAPH_DATA,
    data,
  };
};

export const initMyLibraryAddonsGraphType = () => {
  return {
    type: MY_LIBRARY_ADDONS_INIT_GRAPH_TYPE,
  };
};

export * from "./rooms";
