import { SISI_API_URL } from "@src/api";
import service, { getAuthHeaders } from "@src/api/request";
import axios from "axios";

export function getSubAgentsList(para) {
  return service({
    url: "/bid/sub-agent/",
    method: "get",
    params: para,
  });
}

export function getQuickSubAgentsList(para) {
  return service({
    url: "/bid/sub-agent/get_quick_info",
    method: "get",
    params: para,
  });
}

export function fetchSubAgentsProfile(id) {
  return service({ url: `/bid/sub-agent/${id}`, method: "get" });
}

export function getSubAgentsOptions(lang) {
  return service({
    url: "/bid/sub-agent/",
    method: "options",
    headers: {
      "Accept-Language": lang,
    },
  });
}

export function submitSubAgentsProfile(id, payload, mode) {
  return service({
    url: `/bid/sub-agent/${id}/`,
    method: mode,
    data: payload,
  });
}

export function addNewSubAgents(data) {
  return service({
    url: "/bid/sub-agent/",
    method: "post",
    data: data,
  });
}

export async function getSubagentStats(params) {
  const auth_headers = getAuthHeaders();
  return await axios.get(`${SISI_API_URL}/crm/subagents/stats/`, {
    params,
    headers: { ...auth_headers },
  });
}
