import {
  MY_OFFER__BOOKED_VIEW_TYPE,
  MY_OFFER__BOOKED_FETCH_GRAPH_DATA,
  MY_OFFER__BOOKED_BEGIN_FETCH_GRAPH_DATA,
  MY_OFFER__BOOKED_END_FETCH_GRAPH_DATA,
  MY_OFFER__BOOKED_INIT_GRAPH_TYPE,
  //===================================RESENT EMAIL==================
  MY_OFFER__BOOKED_SHOW_RESENT_EMAIL_MODAL,
  MY_OFFER__BOOKED_HIDE_RESENT_EMAIL_MODAL,
  MY_OFFER__BOOKED_BEGIN_SEND_EMAIL,
  MY_OFFER__BOOKED_END_SEND_EMAIL,
  //========================New Refactored===========================
  MY_OFFER_BOOKED_LIST_SAVE_RESULTS,
  MY_OFFER_BOOKED_LIST_GENERIC_CHANGE,
} from "@src/actions/Project/Archive/MyOffers/Booked/types";

import { commonList } from "../../../../common";

//====================================DASHBOARD===============================
export const initData = {
  date: "current",
  type: "PR",
};
export const MyOffersBookedViewType = (state = initData, action) => {
  switch (action.type) {
    case MY_OFFER__BOOKED_INIT_GRAPH_TYPE:
      return initData;
    case MY_OFFER__BOOKED_VIEW_TYPE:
      return action.data;
    default:
      return state;
  }
};

export const MyOffersBookedGraphData = (state = {}, action) => {
  switch (action.type) {
    case MY_OFFER__BOOKED_FETCH_GRAPH_DATA:
      return action.data;
    default:
      return state;
  }
};

export const initialMyOffersBookedGraphDataStatus = "IDLE";
export const MyOffersBookedGraphDataStatus = (
  state = initialMyOffersBookedGraphDataStatus,
  action
) => {
  switch (action.type) {
    case MY_OFFER__BOOKED_BEGIN_FETCH_GRAPH_DATA:
      return "FETCHING";
    case MY_OFFER__BOOKED_END_FETCH_GRAPH_DATA:
      return "FETCHED";
    default:
      return state;
  }
};

// =============================== RESENT EMAIL ===============================
export const initMode = { mode: "HIDE", id: "" };
export const myOffersBookedResentEmailMode = (state = initMode, action) => {
  switch (action.type) {
    case MY_OFFER__BOOKED_SHOW_RESENT_EMAIL_MODAL:
      return action.data;
    case MY_OFFER__BOOKED_HIDE_RESENT_EMAIL_MODAL:
      return initMode;
    default:
      return state;
  }
};

export const initialMyOffersBookedResentEmailStatus = "IDLE";
export const myOffersBookedResentEmailStatus = (
  state = initialMyOffersBookedResentEmailStatus,
  action
) => {
  switch (action.type) {
    case MY_OFFER__BOOKED_BEGIN_SEND_EMAIL:
      return "FETCHING";
    case MY_OFFER__BOOKED_END_SEND_EMAIL:
      return "FETCHED";
    default:
      return state;
  }
};

const MyOfferBookedListReducerInitial = {
  ...commonList,
  displayLength: 100,
  filters: {
    reference__icontains: "",
    requester__icontains: "",
    title__icontains: "",
    trip_date__gte: "",
    trip_date__lte: "",
    destination__icontains: "",
    created__gte: "",
    created__lte: "",
    creator__icontains: "",
    stay__gte: "",
    stay__lte: "",
    page: 1,
    limit: 6,
    target_entity: "",
  },
};
export const MyOfferBookedListReducer = (
  state = MyOfferBookedListReducerInitial,
  action
) => {
  switch (action.type) {
    case MY_OFFER_BOOKED_LIST_SAVE_RESULTS:
      return action.data;
    case MY_OFFER_BOOKED_LIST_GENERIC_CHANGE: {
      const { filters } = action;
      return { ...state, filters: { ...state.filters, ...filters } };
    }
    default:
      return state;
  }
};
