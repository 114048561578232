import _ from "lodash";
import {
  NormalDatePicker,
  NormalInputField,
  NormalSelectField,
} from "@src/components/forms";
import { Formik, Form, useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { createUseStyles } from "react-jss";
import { variables } from "@src/jsssetup";
import { currencies } from "@src/definitions";
import { connect } from "react-redux";
import {
  addDbDAdhocSrv,
  editDbDAdhocSrv,
} from "@src/actions/Project/TripPlanner/day_by_day/addhoc_services";
import { v4 } from "uuid";
import * as yup from "yup";
import { cxlPolicyShape } from "../../cancellationinfo";
import { DateTime } from "luxon";

export const adhocCurrencyOptions = _.sortBy(
  Object.entries(currencies).map((entry) => [entry[0], entry[0]]),
  (v) => v[0]
);

const mapDispatchToProps = (dispatch) => {
  return {
    onAdd: ({ dayIdx, srv, srv_type }) =>
      dispatch(addDbDAdhocSrv({ dayIdx, srv, srv_type })),
    onEdit: ({ srv }) => dispatch(editDbDAdhocSrv({ srv })),
  };
};

const defaultInitialValuesSchema = yup.object().shape({
  id: yup.string().default(v4),
  description: yup.string().default(""),
  per_person_amount: yup.number().required().default(0),
  unit_amount: yup.number().required().default(0),
  amount: yup.number().required().default(0),
  currency: yup.string().required().default("EUR"),
  notes: yup.string().default(""),
  customerVisibility: yup
    .string()
    .required()
    .oneOf(["visible", "non-visible"])
    .default("non-visible"),
  cancellation_policies: yup
    .array()
    .of(cxlPolicyShape)
    .default([])
    .test(
      "previous-to-date-must-be-less-than-current-from",
      "Previous Frame's 'To' must be less than current Frame's 'From'",
      (value) => {
        if (value.length === 0) return true;
        for (let i = 1; i < value.length; i++) {
          const pToDt = DateTime.fromISO(value[i - 1].to, { setZone: true });
          const cFromDt = DateTime.fromISO(value[i].from, { setZone: true });
          if (pToDt >= cFromDt) return false;
        }
        return true;
      }
    )
    .test(
      "when-a-frame-is-non-refundable-we-can-only-have-one-frame-in-total",
      "When a frame is non-refundable, the total number of frames must be 1",
      (value) => {
        if (value.length === 0) return true;
        const nonRefundableFrames = value.filter(
          (frame) => frame.type === "non_refundable"
        );
        return nonRefundableFrames.length > 0 ? value.length === 1 : true;
      }
    ),
});

function priceCalculator({ values, adults, childs }) {
  const pax = adults + childs;
  const unit_price = values["unit_amount"];
  const pax_price = values["per_person_amount"];
  return pax_price * pax + unit_price;
}

const TotalAmountField = ({ adults, childs }) => {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    const amount = priceCalculator({ values, adults, childs });
    setFieldValue("amount", amount);
  }, [(values?.unit_amount ?? 0) + (values?.per_person_amount ?? 0)]);

  return <NormalInputField name="amount" type="number" label="Total Amount" />;
};
TotalAmountField.propTypes = {
  adults: PropTypes.number.isRequired,
  childs: PropTypes.number.isRequired,
};

const DefaultFieldsStyles = createUseStyles({
  col2: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: variables.normal_gap,
  },
});
const DefaultFields = ({ adults, childs }) => {
  const classes = DefaultFieldsStyles();

  return (
    <React.Fragment>
      <NormalInputField name="description" label="Description" />
      <div className={classes.col2}>
        <NormalInputField
          name="per_person_amount"
          type="number"
          label="Per Person Amount"
        />
        <NormalInputField
          name="unit_amount"
          type="number"
          label="Unit Amount"
        />
        <TotalAmountField adults={adults} childs={childs} />
        <NormalSelectField
          name="currency"
          label="Currency"
          options={adhocCurrencyOptions}
        />
      </div>
      <NormalSelectField
        name="customerVisibility"
        label="Customer Visibility"
        options={[
          ["visible", "Visible"],
          ["non-visible", "Non Visible"],
        ]}
      />
      <NormalInputField name="notes" label="Notes" as="textarea" />
      <CxlPoliciesField />
    </React.Fragment>
  );
};
DefaultFields.propTypes = {
  adults: PropTypes.number.isRequired,
  childs: PropTypes.number.isRequired,
};

const DefaultActions = ({ mode, onCancel }) => {
  const { submitForm } = useFormikContext();
  return (
    <React.Fragment>
      <button className="Button" data-ghost={true} onClick={onCancel}>
        Cancel
      </button>
      <button className="Button" onClick={submitForm}>
        {mode === "add" ? "Add Service" : "Submit Edit"}
      </button>
    </React.Fragment>
  );
};
DefaultActions.defaultProps = { mode: "add" };
DefaultActions.propTypes = {
  mode: PropTypes.oneOf(["add", "edit"]).isRequired,
  onCancel: PropTypes.func.isRequired,
};

const CxlPoliciesField = ({ fieldName = "cancellation_policies" }) => {
  const { values, errors, setFieldValue } = useFormikContext();

  useEffect(() => {
    const cxls = _.cloneDeep(values?.[fieldName] ?? []);
    cxls.forEach((c) => (c.currency = values.currency));
    setFieldValue(fieldName, cxls);
  }, [values.currency]);

  return (
    <div className="CxlPoliciesField">
      <div className="CxlPoliciesField__actions">
        <span>
          <strong>Cancellation Policies</strong>
        </span>
        <button
          className="Button"
          type="button"
          onClick={(e) => {
            e.preventDefault();
            const policies = _.cloneDeep(values.cancellation_policies);
            policies.push(cxlPolicyShape.cast({ currency: values.currency }));
            setFieldValue(fieldName, policies);
          }}>
          Add Cancellation Frame
        </button>
      </div>
      {typeof errors?.cancellation_policies === "string" && (
        <span className="Tag" data-danger="true">
          {errors.cancellation_policies}
        </span>
      )}
      <div className="CxlPoliciesField__frames">
        {(values?.[fieldName] ?? []).map((__, idx) => {
          return (
            <div key={idx} className="CxlPoliciesField__frames__frame">
              <span className="Tag" data-center-align="true">
                <strong>{idx + 1}.</strong>
              </span>
              <NormalDatePicker
                name={`cancellation_policies.${idx}.from`}
                label="From"
                withTime={true}
                minAvailableDate={DateTime.now().toISO()}
                withError={true}
                highlightErrors={true}
              />
              <NormalDatePicker
                name={`cancellation_policies.${idx}.to`}
                label="To"
                withTime={true}
                minAvailableDate={DateTime.now().toISO()}
                withError={true}
                highlightErrors={true}
              />
              <NormalInputField
                name={`cancellation_policies.${idx}.fee`}
                label="Fee"
                type="number"
                withError={true}
                highlightErrors={true}
              />
              <NormalInputField
                name={`cancellation_policies.${idx}.currency`}
                label="Currency"
                extraInputProps={{ disabled: true }}
                withError={true}
                highlightErrors={true}
              />
              <NormalSelectField
                name={`cancellation_policies.${idx}.type`}
                label="Type"
                type="type"
                options={[
                  ["refundable", "Refundable"],
                  ["chargeable", "Chargeable"],
                  ["non_refundable", "Non Refundable"],
                ]}
                withError={true}
                highlightErrors={true}
              />
              <button
                className="Button"
                type="button"
                data-ghost="true"
                data-center-align="true"
                onClick={(e) => {
                  e.preventDefault();
                  const policies = _.cloneDeep(values.cancellation_policies);
                  policies.splice(idx, 1);
                  setFieldValue(fieldName, policies);
                }}>
                Remove Frame
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

var AccommodationAllowance = ({
  adhoc,
  adults,
  childs,
  dayIdx,
  onCancel,
  onAdd,
  onEdit,
}) => {
  return (
    <Formik
      initialValues={
        adhoc ? { ...adhoc } : { ...defaultInitialValuesSchema.cast({}) }
      }
      validationSchema={defaultInitialValuesSchema}
      onSubmit={(values) => {
        adhoc
          ? onEdit({ srv: values })
          : onAdd({ dayIdx, srv: values, srv_type: "ACL" });
        onCancel();
      }}>
      <div className="Modal__card AccommodationAllowance">
        <div className="Modal__card__header">
          <h5>Accommodation Allowance</h5>
        </div>
        <div className="Modal__card__body">
          <Form className="Form">
            <DefaultFields adults={adults} childs={childs} />
          </Form>
        </div>
        <div className="Modal__card__actions">
          <DefaultActions mode={adhoc ? "edit" : "add"} onCancel={onCancel} />
        </div>
      </div>
    </Formik>
  );
};
AccommodationAllowance.propTypes = {
  adhoc: PropTypes.object,
  adults: PropTypes.number.isRequired,
  childs: PropTypes.number.isRequired,
  dayIdx: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};
AccommodationAllowance = connect(
  null,
  mapDispatchToProps
)(AccommodationAllowance);

var MealAllowance = ({
  adhoc,
  childs,
  adults,
  dayIdx,
  onCancel,
  onAdd,
  onEdit,
}) => {
  return (
    <Formik
      initialValues={
        adhoc ? { ...adhoc } : { ...defaultInitialValuesSchema.cast({}) }
      }
      validationSchema={defaultInitialValuesSchema}
      /* validate={(values) => priceCalculator({ values, adults, childs })} */
      onSubmit={(values) => {
        adhoc
          ? onEdit({ srv: values })
          : onAdd({ dayIdx, srv: values, srv_type: "MEA" });
        onCancel();
      }}>
      <div className="Modal__card MealAllowance">
        <div className="Modal__card__header">
          <h5>Meal Allowance</h5>
        </div>
        <div className="Modal__card__body">
          <Form className="Form">
            <DefaultFields adults={adults} childs={childs} />
          </Form>
        </div>
        <div className="Modal__card__actions">
          <DefaultActions mode={adhoc ? "edit" : "add"} onCancel={onCancel} />
        </div>
      </div>
    </Formik>
  );
};
MealAllowance.propTypes = {
  adhoc: PropTypes.object,
  adults: PropTypes.number.isRequired,
  childs: PropTypes.number.isRequired,
  dayIdx: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};
MealAllowance = connect(null, mapDispatchToProps)(MealAllowance);

var EmptyRun = ({ adhoc, adults, childs, dayIdx, onCancel, onAdd, onEdit }) => {
  return (
    <Formik
      initialValues={
        adhoc ? { ...adhoc } : { ...defaultInitialValuesSchema.cast({}) }
      }
      validationSchema={defaultInitialValuesSchema}
      /* validate={(values) => priceCalculator({ values, adults, childs })} */
      onSubmit={(values) => {
        adhoc
          ? onEdit({ srv: values })
          : onAdd({ dayIdx, srv: values, srv_type: "EMT" });
        onCancel();
      }}>
      <div className="Modal__card EmptyRun">
        <div className="Modal__card__header">
          <h5>Empty Run</h5>
        </div>
        <div className="Modal__card__body">
          <Form className="Form">
            <DefaultFields adults={adults} childs={childs} />
          </Form>
        </div>
        <div className="Modal__card__actions">
          <DefaultActions mode={adhoc ? "edit" : "add"} onCancel={onCancel} />
        </div>
      </div>
    </Formik>
  );
};
EmptyRun.propTypes = {
  adhoc: PropTypes.object,
  adults: PropTypes.number.isRequired,
  childs: PropTypes.number.isRequired,
  dayIdx: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};
EmptyRun = connect(null, mapDispatchToProps)(EmptyRun);

var Tips = ({ adhoc, adults, childs, dayIdx, onCancel, onAdd, onEdit }) => {
  return (
    <Formik
      initialValues={
        adhoc ? { ...adhoc } : { ...defaultInitialValuesSchema.cast({}) }
      }
      validationSchema={defaultInitialValuesSchema}
      /* validate={(values) => priceCalculator({ values, adults, childs })} */
      onSubmit={(values) => {
        adhoc
          ? onEdit({ srv: values })
          : onAdd({ dayIdx, srv: values, srv_type: "TIP" });
        onCancel();
      }}>
      <div className="Modal__card Tips">
        <div className="Modal__card__header">
          <h5>Tips</h5>
        </div>
        <div className="Modal__card__body">
          <Form className="Form">
            <DefaultFields adults={adults} childs={childs} />
          </Form>
        </div>
        <div className="Modal__card__actions">
          <DefaultActions mode={adhoc ? "edit" : "add"} onCancel={onCancel} />
        </div>
      </div>
    </Formik>
  );
};
Tips.propTypes = {
  adhoc: PropTypes.object,
  adults: PropTypes.number.isRequired,
  childs: PropTypes.number.isRequired,
  dayIdx: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};
Tips = connect(null, mapDispatchToProps)(Tips);

var Entrance = ({ adhoc, adults, childs, dayIdx, onCancel, onAdd, onEdit }) => {
  return (
    <Formik
      initialValues={
        adhoc ? { ...adhoc } : { ...defaultInitialValuesSchema.cast({}) }
      }
      validationSchema={defaultInitialValuesSchema}
      /* validate={(values) => priceCalculator({ values, adults, childs })} */
      onSubmit={(values) => {
        adhoc
          ? onEdit({ srv: values })
          : onAdd({ dayIdx, srv: values, srv_type: "ENT" });
        onCancel();
      }}>
      <div className="Modal__card Entrance">
        <div className="Modal__card__header">
          <h5>Entrance</h5>
        </div>
        <div className="Modal__card__body">
          <Form className="Form">
            <DefaultFields adults={adults} childs={childs} />
          </Form>
        </div>
        <div className="Modal__card__actions">
          <DefaultActions mode={adhoc ? "edit" : "add"} onCancel={onCancel} />
        </div>
      </div>
    </Formik>
  );
};
Entrance.propTypes = {
  adhoc: PropTypes.object,
  adults: PropTypes.number.isRequired,
  childs: PropTypes.number.isRequired,
  dayIdx: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};
Entrance = connect(null, mapDispatchToProps)(Entrance);

export { AccommodationAllowance, EmptyRun, MealAllowance, Tips, Entrance };
