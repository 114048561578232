import PropTypes from "prop-types";
import React, { useCallback, useContext, useEffect, useState } from "react";

import { connect, useDispatch } from "react-redux";
import { Icon, Nav, Toggle, Divider, Loader } from "rsuite";

import { promptlessSaveAddonService } from "@src/actions/Operation/CustomServices/AddOn";
import ReactQuill from "react-quill";
import {
  LanguageBar,
  descTranslationHoc,
} from "./modals/GeneralDescriptionModal";

import {
  changeItem,
  changeItemLang,
  changeItemImage,
  removeItemImage,
  changeItemMainImg,
  tglItmNonCustomImg,
} from "@src/actions/Operation/CustomServices/AddOn/item_actions";

import _ from "lodash";
import { langTranslateMapping } from "@src/config/common";
import { createUseStyles } from "react-jss";
import { modalGenericStyles, variables } from "@src/jsssetup";
import BasicInfo from "./modals/ItemContentModal/BasicInfo";
import CustomImage, { PlaceHolderImg } from "./CustomImage";
import { notifyAddOnValidation } from "@src/components/common/notifications/AddOnNotifications";
import { ImagePicker } from "@src/pages/Network/B2CDistributionChannels/detail/components/ImageModal";
import { CustomButton } from "@src/components/common/buttons";
import { useAIContentGeneration } from "@src/pages/hooks";
import { AddonModalsContext } from "../AddOn";
import { toast } from "react-toastify";

const removeImgBtn = createUseStyles({
  btn: {
    position: "absolute",
    right: variables.normal_gap,
    top: variables.normal_gap,
    color: "white",
  },
});
const RemoveImgBtn = (props) => {
  const { onClick } = props;
  const classes = removeImgBtn();
  return (
    <Icon
      className={classes.btn}
      icon="close-circle"
      size="lg"
      onClick={onClick}
    />
  );
};
RemoveImgBtn.propTypes = { onClick: PropTypes.func.isRequired };

const imgContStyles = { height: "15vh", width: "100%", position: "relative" };
const imgStyles = {
  height: "100%",
  width: "100%",
  objectFit: "cover",
  borderRadius: variables.normal_gap,
};
const toggleBoxStyles = {
  "position": "absolute",
  "bottom": variables.normal_gap,
  "right": variables.normal_gap,
  "display": "grid",
  "border": "2px solid white",
  "borderRadius": "5px",
  "padding": `calc(${variables.normal_gap} / 2)`,
  "background": "rgba(0, 0, 0, 0.4)",
  "& strong": { color: "white" },
};

const addonNonCustomImgsStyles = createUseStyles({
  imgContainer: { ...imgContStyles },
  img: { ...imgStyles },
  toggleBox: { ...toggleBoxStyles },
});
const AddonNonCustomImgs = (props) => {
  const classes = addonNonCustomImgsStyles();
  const { images, onToggleNonCustomImg } = props;
  return images.map((img, idx) => {
    const enabled = img?.enabled ?? true;
    return (
      <div key={idx} className={classes.imgContainer}>
        <img className={classes.img} alt="" src={img.photo_sm_url} />
        <div className={classes.toggleBox}>
          <span>
            <strong>{enabled ? "Enabled" : "Disabled"}</strong>
          </span>
          <Toggle
            checked={img?.enabled ?? true}
            onChange={function (checked) {
              onToggleNonCustomImg([idx], checked);
            }}
          />
        </div>
      </div>
    );
  });
};
AddonNonCustomImgs.propTypes = {
  images: PropTypes.array.isRequired,
  onToggleNonCustomImg: PropTypes.func.isRequired,
};

const imgContainerStyles = createUseStyles({
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridGap: variables.normal_gap,
    padding: `${variables.normal_gap} 0`,
    overflow: "auto",
    gridAutoRows: "max-content",
  },
  header: {
    "gridColumn": "span 3",
    "& .rs-btn-toggle": {
      float: "right",
    },
  },
  divider: {
    gridColumn: "span 3",
  },
  [`@media ${variables.media.bigscreen}`]: {
    container: {
      gridTemplateColumns: "repeat(4, 1fr)",
    },
    header: {
      gridColumn: "span 4",
    },
    divider: {
      gridColumn: "span 4",
    },
  },
});
const ImgContainer = (props) => {
  const {
    non_custom_images,
    custom_images,
    onMainPhotoChange,
    onRemoveImage,
    onToggleNonCustomImg,
  } = props;
  const classes = imgContainerStyles();

  return (
    <div className={classes.container}>
      <h5 className={classes.header}>Custom Images</h5>
      {custom_images.map((img, idx) => {
        return (
          <CustomImage
            key={idx}
            image={img}
            onMainToggle={function (value) {
              onMainPhotoChange("main_photo", value, idx);
            }}
            onRemoveImage={function (imageId) {
              onRemoveImage("custom_images", imageId);
            }}
          />
        );
      })}
      {custom_images.length === 0 && <PlaceHolderImg />}
      <Divider className={classes.divider} />
      <h5 className={classes.header}>
        Non Custom Images
        <Toggle
          checkedChildren="Enabled"
          unCheckedChildren="Disabled"
          checked={
            non_custom_images.filter((img) => img?.enabled ?? true).length > 0
          }
          onChange={function (checked) {
            const idxs = non_custom_images.map((img, idx) => idx);
            onToggleNonCustomImg(idxs, checked);
          }}
        />
      </h5>
      <AddonNonCustomImgs
        images={non_custom_images}
        onToggleNonCustomImg={onToggleNonCustomImg}
      />
    </div>
  );
};
ImgContainer.propTypes = {
  non_custom_images: PropTypes.array.isRequired,
  custom_images: PropTypes.array.isRequired,
  onMainPhotoChange: PropTypes.func.isRequired,
  onRemoveImage: PropTypes.func.isRequired,
  onToggleNonCustomImg: PropTypes.func.isRequired,
};

var ItemAiGenBtn = ({ name, location, onInitAiSesssion }) => {
  return (
    <CustomButton
      appearance="primary"
      onClick={() => {
        onInitAiSesssion({
          messages: [
            `please provide a travel oriented description for the following location`,
            `${name}, ${location}`,
            "Your response must be in no more than 500 characters.",
          ],
        });
      }}>
      AI Auto-Generation
    </CustomButton>
  );
};
ItemAiGenBtn.propTypes = {
  name: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  onInitAiSesssion: PropTypes.func.isRequired,
};
// ItemAiGenBtn = LeanAuthHoc(ItemAiGenBtn, {
//   allowed_groups: ["testingfeature"],
// });

const ItemContentModalStyles = createUseStyles({
  ...modalGenericStyles,
  ItemContentModal: modalGenericStyles.modal,
  card: { ...modalGenericStyles.card, width: "80vw" },
});
const ItemContentModal = ({
  description_en,
  description_cn,
  description_it,
  description_es,
  description_fr,
  description_el,
  description_nl,
  description_de,
  description_pt,
  itemOrder,
  data,
  non_custom_images,
  custom_images,
  current_lang,
  onChange,
  onChangeLang,
  onTranslate,
  onImageChange,
  onRemoveImage,
  onMainPhotoChange,
  onToggleNonCustomImg,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [desc_en_content, setDesc_en_content] = useState(description_en || "");
  const [desc_cn_content, setDesc_cn_content] = useState(description_cn || "");
  const [desc_it_content, setDesc_it_content] = useState(description_it || "");
  const [desc_es_content, setDesc_es_content] = useState(description_es || "");
  const [desc_fr_content, setDesc_fr_content] = useState(description_fr || "");
  const [desc_el_content, setDesc_el_content] = useState(description_el || "");
  const [desc_nl_content, setDesc_nl_content] = useState(description_nl || "");
  const [desc_de_content, setDesc_de_content] = useState(description_de || "");
  const [desc_pt_content, setDesc_pt_content] = useState(description_pt || "");

  const classes = ItemContentModalStyles();

  const { setShowItemContentModal } = useContext(AddonModalsContext);

  const [activeNav, setActiveNav] = useState("basic_info");
  const [basicInfoData, setBasicInfoData] = useState({});

  function validateBasicInfo() {
    if (_.isEmpty(basicInfoData)) {
      return true;
    }

    if (!basicInfoData.country) {
      notifyAddOnValidation([
        "Country information is missing from Basic Info.",
      ]);
      return false;
    }

    if (!basicInfoData.name_en) {
      notifyAddOnValidation(["Name is missing from Basic Info."]);
      return false;
    }

    return true;
  }

  const dispatch = useDispatch();
  const saveAddon = useCallback(
    async () => await dispatch(promptlessSaveAddonService()),
    [dispatch]
  );

  async function handleSave() {
    setIsLoading(true);
    if (!validateBasicInfo()) {
      return;
    }

    await onChange("data", { ...data, ...basicInfoData });
    await onChange(`description_en`, desc_en_content);
    await onChange(`description_cn`, desc_cn_content);
    await onChange(`description_it`, desc_it_content);
    await onChange(`description_es`, desc_es_content);
    await onChange(`description_fr`, desc_fr_content);
    await onChange(`description_el`, desc_el_content);
    await onChange(`description_nl`, desc_nl_content);
    await onChange(`description_de`, desc_de_content);
    await onChange(`description_pt`, desc_pt_content);

    const result = await saveAddon();

    if (result) {
      toast.success("Successfully saved addon service.", { autoClose: 3000 });
    }

    setIsLoading(false);
    setShowItemContentModal(false);
  }

  async function handleSelect(value) {
    if (activeNav === "basic_info") {
      await onChange("data", { ...data, ...basicInfoData });
    }

    setActiveNav(value);
  }

  // Initialize the basic info data
  useEffect(() => {
    setBasicInfoData({});
  }, []);

  var upstreamDescription = "";
  var description = "";
  var setDescription = setDesc_en_content;
  switch (current_lang) {
    case "en": {
      description = desc_en_content;
      setDescription = setDesc_en_content;
      upstreamDescription = description_en;
      break;
    }
    case "cn": {
      description = desc_cn_content;
      setDescription = setDesc_cn_content;
      upstreamDescription = description_cn;
      break;
    }
    case "it": {
      description = desc_it_content;
      setDescription = setDesc_it_content;
      upstreamDescription = description_it;
      break;
    }
    case "es": {
      description = desc_es_content;
      setDescription = setDesc_es_content;
      upstreamDescription = description_es;
      break;
    }
    case "fr": {
      description = desc_fr_content;
      setDescription = setDesc_fr_content;
      upstreamDescription = description_fr;
      break;
    }
    case "el": {
      description = desc_el_content;
      setDescription = setDesc_el_content;
      upstreamDescription = description_el;
      break;
    }
    case "nl": {
      description = desc_nl_content;
      setDescription = setDesc_nl_content;
      upstreamDescription = description_nl;
      break;
    }
    case "de": {
      description = desc_de_content;
      setDescription = setDesc_de_content;
      upstreamDescription = description_de;
      break;
    }
    case "pt": {
      description = desc_pt_content;
      setDescription = setDesc_pt_content;
      upstreamDescription = description_pt;
      break;
    }
    default: {
      description = desc_en_content;
      setDescription = setDesc_en_content;
      upstreamDescription = description_en;
      break;
    }
  }

  const {
    streamContent,
    contentGenerationStatus,
    initAutoContentSesssionMutation,
  } = useAIContentGeneration({
    callback: (streamContent) =>
      onChange(`description_${current_lang}`, streamContent),
  });

  // Store the content as it comes
  useEffect(() => {
    if (!streamContent) return;
    setDescription(streamContent);
  }, [streamContent, contentGenerationStatus]);

  useEffect(() => {
    setDescription(upstreamDescription);
  }, [
    description_en,
    description_cn,
    description_it,
    description_es,
    description_fr,
    description_el,
    description_nl,
    description_de,
    description_pt,
  ]);

  return (
    <div className={classes.ItemContentModal}>
      <div className={classes.card}>
        {isLoading && <Loader backdrop center size="lg" />}
        <div className={classes.cardHeader}>
          <h5>Item Content</h5>
        </div>
        <div className={classes.cardBody}>
          <Nav
            appearance="subtle"
            activeKey={activeNav}
            onSelect={handleSelect}>
            <Nav.Item eventKey="basic_info">
              <strong>Basic Info</strong>
            </Nav.Item>
            <Nav.Item eventKey="description">
              <strong>Description</strong>
            </Nav.Item>
            <Nav.Item eventKey="images">
              <strong>Images</strong>
            </Nav.Item>
          </Nav>
          <div className="ItemContentModal__content">
            {activeNav == "description" ? (
              <React.Fragment>
                <LanguageBar
                  current_lang={current_lang}
                  onChange={onChangeLang}
                  onTranslate={(data) => {
                    const origin_lang =
                      data.payload.translations[0].origin_lang;
                    const langKey = _.invert(langTranslateMapping)[origin_lang];

                    var descContent = "";
                    switch (langKey) {
                      case "en":
                        descContent = desc_en_content;
                        break;
                      case "cn":
                        descContent = desc_cn_content;
                        break;
                      case "it":
                        descContent = desc_it_content;
                        break;
                      case "es":
                        descContent = desc_es_content;
                        break;
                      case "fr":
                        descContent = desc_fr_content;
                        break;
                      case "el":
                        descContent = desc_el_content;
                        break;
                      case "nl":
                        descContent = desc_nl_content;
                        break;
                      case "de":
                        descContent = desc_de_content;
                        break;
                      case "pt":
                        descContent = desc_pt_content;
                        break;
                      default:
                        descContent = desc_en_content;
                    }
                    onTranslate(data, descContent);
                  }}
                />
                <ReactQuill
                  className="custom-quill"
                  value={description}
                  onChange={(value, delta, source) => {
                    if (source === "api") return;
                    setDescription(value);
                  }}
                />
              </React.Fragment>
            ) : activeNav === "basic_info" ? (
              <BasicInfo
                basicInfo={basicInfoData}
                itemOrder={itemOrder}
                onDataChange={setBasicInfoData}
              />
            ) : (
              <div className="ItemContentModal__images">
                <div className="ItemContentModal__images__checkpicker">
                  <ImagePicker
                    selectedIds={custom_images.map((img) => img.id)}
                    onSelect={(img) =>
                      onImageChange("custom_images", [...custom_images, img])
                    }
                  />
                </div>
                <ImgContainer
                  non_custom_images={non_custom_images}
                  custom_images={custom_images}
                  onMainPhotoChange={onMainPhotoChange}
                  onRemoveImage={onRemoveImage}
                  onToggleNonCustomImg={onToggleNonCustomImg}
                />
              </div>
            )}
          </div>
        </div>
        <div className={classes.cardActions}>
          <CustomButton
            appearance="ghost"
            onClick={() => setShowItemContentModal(false)}>
            Cancel
          </CustomButton>
          {activeNav === "description" && current_lang === "en" && (
            <ItemAiGenBtn
              name={data.name_en}
              location={(data?.location ?? []).join(", ")}
              onInitAiSesssion={initAutoContentSesssionMutation.mutate}
            />
          )}
          <CustomButton
            appearance="primary"
            onClick={async () => await handleSave()}>
            Save
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
ItemContentModal.defaultProps = {
  non_custom_images: [],
};
ItemContentModal.propTypes = {
  description_en: PropTypes.string,
  description_cn: PropTypes.string,
  description_it: PropTypes.string,
  description_es: PropTypes.string,
  description_fr: PropTypes.string,
  description_el: PropTypes.string,
  description_nl: PropTypes.string,
  description_de: PropTypes.string,
  description_pt: PropTypes.string,
  itemOrder: PropTypes.number,
  custom_images: PropTypes.array,
  non_custom_images: PropTypes.array,
  data: PropTypes.object,
  current_lang: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeLang: PropTypes.func.isRequired,
  onTranslate: PropTypes.func.isRequired,
  onImageChange: PropTypes.func.isRequired,
  onMainPhotoChange: PropTypes.func.isRequired,
  onRemoveImage: PropTypes.func.isRequired,
  onToggleNonCustomImg: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  const { current_lang, itemOrder } = state.customServiceItemContentModal;

  const {
    description_en,
    description_cn,
    description_it,
    description_es,
    description_fr,
    description_el,
    description_nl,
    description_de,
    description_pt,
    data,
  } = state?.customServiceAddOnItems?.[itemOrder - 1] ?? {};

  const non_custom_images = data?.image_set ?? [];
  const custom_images_full = data?.custom_images ?? [];
  const custom_images = custom_images_full.filter((e) => e.id);

  return {
    data,
    itemOrder,
    current_lang,
    description_en,
    description_cn,
    description_it,
    description_es,
    description_fr,
    description_el,
    description_nl,
    description_de,
    description_pt,
    custom_images,
    non_custom_images,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onChangeLang: (lang) => dispatch(changeItemLang(lang)),
    onChange: (key, value) => {
      dispatch(changeItem(key, value));
    },
    onPostTranslation: (lang, translated_text) => {
      dispatch(changeItem(`description_${lang}`, translated_text));
    },
    onImageChange: (image_type, images) => {
      dispatch(changeItemImage(image_type, images));
    },
    onMainPhotoChange: (key, value, id) => {
      dispatch(changeItemMainImg(key, value, id));
    },
    onRemoveImage: (key, value) => dispatch(removeItemImage(key, value)),
    onToggleNonCustomImg: (imgIdxs, status) =>
      dispatch(tglItmNonCustomImg(imgIdxs, status)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(descTranslationHoc(ItemContentModal));
