export const INNER_GROUP_POLICY_BEGIN_ADD = "INNER_GROUP_POLICY_BEGIN_ADD";
export const INNER_GROUP_POLICY_END_ADD = "INNER_GROUP_POLICY_END_ADD";
export const INNER_GROUP_POLICY_FETCH_DATA = "INNER_GROUP_POLICY_FETCH_DATA";
export const INNER_GROUP_POLICY_BEGIN_FETCH_DATA =
  "INNER_GROUP_POLICY_BEGIN_FETCH_DATA";
export const INNER_GROUP_POLICY_END_FETCH_DATA =
  "INNER_GROUP_POLICY_END_FETCH_DATA";
export const INNER_GROUP_POLICY_INITIALIZE_LIST_FILTER_FORM =
  "INNER_GROUP_POLICY_INITIALIZE_LIST_FILTER_FORM";
export const INNER_GROUP_POLICY_UPDATE_LIST_FILTER_FORM =
  "INNER_GROUP_POLICY_UPDATE_LIST_FILTER_FORM";
export const INNER_GROUP_POLICY_APPLY_LIST_FILTER =
  "INNER_GROUP_POLICY_APPLY_LIST_FILTER";
export const INNER_GROUP_POLICY_UPDATE_LIST_COLUMNS_FORM =
  "INNER_GROUP_POLICY_UPDATE_LIST_COLUMNS_FORM";
export const INNER_GROUP_POLICY_APPLY_LIST_COLUMNS =
  "INNER_GROUP_POLICY_APPLY_LIST_COLUMNS";
export const INNER_GROUP_POLICY_INITIALIZE_LIST_COLUMNS_FORM =
  "INNER_GROUP_POLICY_INITIALIZE_LIST_COLUMNS_FORM";
export const INNER_GROUP_POLICY_FETCH_OPTIONS =
  "INNER_GROUP_POLICY_FETCH_OPTIONS";
export const INNER_GROUP_POLICY_INITIALIZE_BASE_DATA_EDIT_FORM =
  "INNER_GROUP_POLICY_INITIALIZE_BASE_DATA_EDIT_FORM";
export const INNER_GROUP_POLICY_CLEAN_BASE_DATA_EDIT_FORM =
  "INNER_GROUP_POLICY_CLEAN_BASE_DATA_EDIT_FORM";
export const INNER_GROUP_POLICY_EDIT_BASE_DATA =
  "INNER_GROUP_POLICY_EDIT_BASE_DATA";
export const INNER_GROUP_POLICY_VALIDATE_BASE_DATA_EDIT_FORM =
  "INNER_GROUP_POLICY_VALIDATE_BASE_DATA_EDIT_FORM";
export const INNER_GROUP_POLICY_RESET_LIST_FILTER_FORM =
  "INNER_GROUP_POLICY_RESET_LIST_FILTER_FORM";
//=============================================EDIT====================================
export const INNER_GROUP_POLICY_INIT_BASE_DATA_EDIT_FORM =
  "INNER_GROUP_POLICY_INIT_BASE_DATA_EDIT_FORM";
export const INNER_GROUP_POLICY_FETCH_EDIT_DATA =
  "INNER_GROUP_POLICY_FETCH_EDIT_DATA";
export const INNER_GROUP_POLICY_LOADING_EDIT_FORM =
  "INNER_GROUP_POLICY_LOADING_EDIT_FORM";
export const INNER_GROUP_POLICY_LOADED_EDIT_FORM =
  "INNER_GROUP_POLICY_LOADED_EDIT_FORM";

export const INNER_GROUP_POLICY_FETCH_GRAPH_DATA =
  "INNER_GROUP_POLICY_FETCH_GRAPH_DATA";
