import _ from "lodash";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { TrainData } from "./train";
import { useSelector } from "react-redux";
import { PricingFormContext } from "../TripPlanner";
import OverviewPrice from "./OverviewPrice";
import { ItinOverviewContext } from "../Controllers/ItineraryOverview";
import { getServicePricingSelector } from "@src/selectors/Project/TripPlanner/pricing";
import ServicePricingForm from "./ServicePricingForm";
import { ServicePanelGhost } from "./ItineraryOverviewCommon";

export const OverviewTrain = ({
  eventType = null,
  legUid,
  services_collapsed,
  toggleSelfCollapse,
  withPrice = true,
}) => {
  const { showPricingForm } = useContext(PricingFormContext);
  const { onViewAllTrains } = useContext(ItinOverviewContext);

  const { train, isSearching } = useSelector((state) => {
    const train = (state.tripPlannerTrainsReducer?.services ?? []).find(
      (tra) => tra.legUid === legUid
    );
    const isSearching =
      state.tripPlannerTrainsReducer?.leg_search_state?.[legUid] ?? false;
    return { train, isSearching };
  });

  const selectedOffer = (train?.offers ?? []).find((o) => o.selected);
  const price = parseFloat(selectedOffer?.amount?.value ?? 0);
  const currency = selectedOffer?.amount?.currency ?? "EUR";

  const { markup, gross_margin, flat_value } = useSelector((state) => {
    const { markup, gross_margin, flat_value } = getServicePricingSelector(
      state,
      { key: `ATRA___${train?.id}` }
    );
    return { markup, gross_margin, flat_value };
  });

  return (
    <React.Fragment>
      <div />
      {/* currentStep < 4 ? ( */
      /*   <div /> */
      /* ) : ( */
      /*   <BookChecker uid={train?.id} srv_type="ATRA" disabled={false} /> */
      /* ) */}
      {isSearching ? (
        <ServicePanelGhost
          title={`Search in progress. Please wait...`}
          service_type="TRA"
          searchProgress={0}
        />
      ) : !!train ? (
        <TrainData
          train={train}
          services_collapsed={services_collapsed}
          toggleSelfCollapse={toggleSelfCollapse}
          eventType={eventType}>
          <button
            className="Button"
            data-size="xs"
            type="button"
            onClick={() => onViewAllTrains({ session_id: train.session_id })}>
            View All
          </button>
        </TrainData>
      ) : (
        <ServicePanelGhost
          title={`No train found. Please try again.`}
          service_type="TRA"
          searchProgress={0}
        />
      )}
      {showPricingForm && !!train ? (
        <ServicePricingForm service={train} service_type="ATRA" />
      ) : services_collapsed ? (
        <div />
      ) : withPrice ? (
        <OverviewPrice
          price={price}
          currency={currency}
          searching={isSearching}
          markup={markup}
          gross_margin={gross_margin}
          flat_value={flat_value}
        />
      ) : (
        <div />
      )}
    </React.Fragment>
  );
};
OverviewTrain.propTypes = {
  eventType: PropTypes.oneOf(["arrival", "departure"]),
  legUid: PropTypes.string.isRequired,
  services_collapsed: PropTypes.bool.isRequired,
  currentStep: PropTypes.number.isRequired,
  toggleSelfCollapse: PropTypes.func.isRequired,
  withPrice: PropTypes.bool.isRequired,
};
