import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import CheckBoxComp from "./CheckBoxComp";
import { TicketTypes } from "./TicketTypes";
import { Header } from "./Header";

export const Train = ({ data, checked, setChecked, onSelectOffer }) => {
  const offers = _.sortBy(data?.offers ?? [], (v) =>
    parseFloat(v.amount.value)
  );
  // Make sure one offer is by default selected. The first offer will be the
  // cheapeste and we select it only if there is no other offer selected.
  if (offers.every((o) => !(o?.selected ?? false))) {
    offers[0]["selected"] = true;
  }

  return (
    <div className="Train">
      <CheckBoxComp checked={checked} setChecked={setChecked} />
      <div className="Train__content">
        <Header data={data} />
        {checked && (
          <TicketTypes offers={offers} onSelectOffer={onSelectOffer} />
        )}
      </div>
    </div>
  );
};
Train.defaultProps = {
  data: {},
  checked: false,
};
Train.propTypes = {
  data: PropTypes.object.isRequired,
  checked: PropTypes.bool.isRequired,
  setChecked: PropTypes.func.isRequired,
};
