//===========================LIST=========================
export const MY_CONNECTION_LOADING_LIST = "MY_CONNECTION_LOADING_LIST";
export const MY_CONNECTION_FETCH_LIST_DATA = "MY_CONNECTION_FETCH_LIST_DATA";
export const MY_CONNECTION_IDLE_LIST = "MY_CONNECTION_IDLE_LIST";
export const MY_CONNECTION_VIEW_TYPE_CHANGE = " MY_CONNECTION_VIEW_TYPE_CHANGE";
//==========================FILTER========================
export const MY_CONNECTION_INITIALIZE_LIST_FILTER_FORM =
  "MY_CONNECTION_INITIALIZE_LIST_FILTER_FORM";
export const MY_CONNECTION_UPDATE_LIST_FILTER_FORM =
  "MY_CONNECTION_UPDATE_LIST_FILTER_FORM";
export const MY_CONNECTION_APPLY_LIST_FILTER =
  "MY_CONNECTION_APPLY_LIST_FILTER";
export const MY_CONNECTION_RESET_LIST_FILTER_FORM =
  "MY_CONNECTION_RESET_LIST_FILTER_FORM";
//========================COLUMNS==========================
export const MY_CONNECTION_APPLY_LIST_COLUMNS_FORM =
  "MY_CONNECTION_APPLY_LIST_COLUMNS_FORM";
//============================DELETE CONNECTION MODAL=================================
export const MY_CONNECTION_BEGIN_DELETE_CONNECTION =
  "MY_CONNECTION_BEGIN_DELETE_CONNECTION";
export const MY_CONNECTION_END_DELETE_CONNECTION =
  "MY_CONNECTION_END_DELETE_CONNECTION";
export const MY_CONNECTION_DELETE_CONNECTION_MODAL_DATA =
  "MY_CONNECTION_DELETE_CONNECTION_MODAL_DATA";
export const MY_CONNECTION_SHOW_MODAL = " MY_CONNECTION_SHOW_MODAL";
export const MY_CONNECTION_HIDE_MODAL = "MY_CONNECTION_HIDE_MODAL";
