import _ from "lodash";
import { View, Text, StyleSheet } from "@react-pdf/renderer";
import { variables } from "@src/jsssetup";
import PropTypes from "prop-types";
import React from "react";

const remarksStyles = StyleSheet.create({
  AccommodationGeneralRemarks: {
    width: 550,
    flexDirection: "column",
    fontSize: 10,
  },
  txt: { padding: 5, background: variables.colors.easy.lightOrange2 },
});
export const AccommodationGeneralRemarks = ({ remarks }) => {
  return (
    <View style={remarksStyles.AccommodationGeneralRemarks}>
      <Text style={remarksStyles.txt}>{remarks.en}</Text>
    </View>
  );
};
AccommodationGeneralRemarks.propTypes = {
  remarks: PropTypes.object.isRequired,
};

const accRemarksStyles = StyleSheet.create({
  AccommodationRemarks: { width: 550, flexDirection: "column", fontSize: 10 },
  firstRow: {
    flexDirection: "row",
    borderRadius: "5px",
    border: "1px solid #302A83",
    backgroundColor: "#302A83",
    color: "white",
    padding: 8,
    fontFamily: "Helvetica-Bold",
  },
  row: { flexDirection: "row", borderBottom: "1px solid black" },
  lastRow: { flexDirection: "row" },
  mainColumn: { width: 130 },
  secondColumn: { width: 120 },
  thirdColumn: { width: 300 },
  headerCellTxt: {},
  cellTxt: { padding: 5 },
});
export const AccommodationRemarks = ({
  accommodations,
  destinations,
  accommodationsRemarks,
}) => {
  const filteredAccommodationsRemarks = Object.entries(
    accommodationsRemarks
  ).filter(([code, remarks]) => remarks.remarks.en);

  return (
    <View style={accRemarksStyles.AccommodationRemarks}>
      <View style={accRemarksStyles.firstRow}>
        <View style={accRemarksStyles.mainColumn}>
          <Text style={accRemarksStyles.headerCellTxt}>Accommodation</Text>
        </View>
        <View style={accRemarksStyles.secondColumn}>
          <Text style={accRemarksStyles.headerCellTxt}>Destination</Text>
        </View>
        <View style={accRemarksStyles.thirdColumn}>
          <Text style={accRemarksStyles.headerCellTxt}>Remarks</Text>
        </View>
      </View>
      {filteredAccommodationsRemarks.map(([code, remarks], idx) => {
        const [destOrder, uid] = code.split("___");
        const acc = accommodations.find((acc) => acc.uid === uid);
        const dest = destinations.find(
          (dest) => dest.order.toString() === destOrder
        );

        return (
          <View
            key={idx}
            style={
              idx !== filteredAccommodationsRemarks.length - 1
                ? accRemarksStyles.row
                : accRemarksStyles.lastRow
            }>
            <View style={accRemarksStyles.mainColumn}>
              <Text key={idx} style={accRemarksStyles.cellTxt}>
                {acc.metadata.name}
              </Text>
            </View>
            <View style={accRemarksStyles.secondColumn}>
              <Text key={idx} style={accRemarksStyles.cellTxt}>
                {dest?.name_en ?? "N/A"}
              </Text>
            </View>
            <View style={accRemarksStyles.thirdColumn}>
              <Text key={idx} style={accRemarksStyles.cellTxt}>
                {remarks.remarks.en}
              </Text>
            </View>
          </View>
        );
      })}
    </View>
  );
};
AccommodationRemarks.propTypes = {
  accommodationsRemarks: PropTypes.object.isRequired,
  accommodations: PropTypes.array.isRequired,
  destinations: PropTypes.array.isRequired,
};
