import { Button, Loader } from "rsuite";
import { modalGenericStyles } from "@src/jsssetup";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { createUseStyles } from "react-jss";
import { Formik, Form } from "formik";
import { NormalInputField, NormalSelectField } from "@src/components/forms";
import { useDispatch, useSelector } from "react-redux";
import { saveAsAddonService } from "@src/actions/Operation/CustomServices/AddOn";
import { useHistory } from "react-router-dom";

const styles = createUseStyles({
  SaveAsModal: modalGenericStyles.modal,
  card: modalGenericStyles.card,
  header: modalGenericStyles.cardHeader,
  body: { ...modalGenericStyles.cardBody, minHeight: "unset" },
  actions: modalGenericStyles.cardActions,
});
const SaveAsModal = (props) => {
  const { onCancel } = props;

  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { title } = useSelector((state) => state.customServiceAddOn);
  const dispatch = useDispatch();
  const onSave = useCallback(
    ({ title, link }) => dispatch(saveAsAddonService({ link, title, history })),
    [dispatch]
  );

  const classes = styles();
  return (
    <div className={classes.SaveAsModal}>
      {loading ? (
        <Loader size="lg" center content="Please wait..." vertical />
      ) : (
        <div className={classes.card}>
          <div className={classes.header}>
            <h5>Save Addon As</h5>
          </div>
          <Formik
            initialValues={{ new_title: title + " (copy)", link: false }}
            onSubmit={async (values) => {
              setLoading(true);
              await onSave({ title: values.new_title, link: values.link });
              setLoading(false);
              onCancel();
            }}>
            <Form>
              <div className={classes.body}>
                <NormalInputField name="new_title" label="New Title" />
                <NormalSelectField
                  name="link"
                  label="Clone and Link"
                  options={[
                    [true, "Yes"],
                    [false, "No"],
                  ]}
                />
              </div>
              <div className={classes.actions}>
                <Button onClick={onCancel}>
                  <strong>Cancel</strong>
                </Button>
                <Button color="blue" type="submit">
                  <strong>Save</strong>
                </Button>
              </div>
            </Form>
          </Formik>
        </div>
      )}
    </div>
  );
};
SaveAsModal.propTypes = { onCancel: PropTypes.func.isRequired };

export default SaveAsModal;
