import {
  NormalInputField,
  NormalRangePicker,
  NormalSelectField,
  NormalSubagentAutocompleteField,
} from "@src/components/forms";
import { quickFiltersStylesMain, variables } from "@src/jsssetup";
import React from "react";
import { createUseStyles } from "react-jss";
import {
  NewApplyFiltersBtn,
  NewResetFiltersBtn,
} from "../../../NewResetSearchFiltersBtn";

const styles = createUseStyles({
  Filters: {
    QuickFilters: {
      ...quickFiltersStylesMain(),
      "gridTemplaterows": "6rem repeat(9, 1fr) 6rem 5rem 6.5rem auto auto",
      "& input,select": {
        width: "100%",
      },
    },
  },
  topSection: {
    display: "grid",
    gridTemplateColumns: "1fr max-content",
    alignItems: "center",
    padding: [variables.normal_gap, 0],
  },
  title: {
    display: "grid",
    color: "#FF6600",
    height: "2rem",
    alignContent: "center",
    fontSize: "large",
    fontFamily: "futura,sans-serif",
  },
  buttons: {
    display: "grid",
    gridAutoFlow: "column",
    gap: variables.half_gap,
  },
  resetButton: {
    "display": "grid",
    "alignContent": "center",
    "justifyContent": "center",
    "cursor": "pointer",
    "& div": {
      display: "grid",
      borderRadius: "50%",
      width: "1.8rem",
      height: "1.8rem",
      backgroundColor: "#FF6600",
      color: "#FFFFFF",
    },
    "& svg": {
      display: "grid",
      alignSelf: "center",
      justifySelf: "center",
      borderRadius: "50%",
      width: "60%!important",
      height: "60%!important",
      backgroundColor: "#FF6600",
    },
  },
  applyButton: {
    "display": "grid",
    "alignContent": "center",
    "justifyContent": "center",
    "cursor": "pointer",
    "& div": {
      display: "grid",
      borderRadius: "50%",
      width: "1.8rem",
      height: "1.8rem",
      backgroundColor: "#67AD5B",
      color: "#FFFFFF",
    },
    "& svg": {
      display: "grid",
      alignSelf: "center",
      justifySelf: "center",
      borderRadius: "50%",
      width: "60%!important",
      height: "60%!important",
      backgroundColor: "#67AD5B",
    },
  },
  form: {
    display: "grid",
    gap: variables.normal_gap,
  },
});

const Filters = () => {
  const classes = styles();
  return (
    <div className={classes.Filters}>
      <div className={classes.topSection}>
        <div className={classes.title}>
          <span>Filters</span>
        </div>
        <div className={classes.buttons}>
          <div className={classes.resetButton}>
            <div>
              <NewResetFiltersBtn />
            </div>
          </div>
          <div className={classes.applyButton}>
            <div>
              <NewApplyFiltersBtn />
            </div>
          </div>
        </div>
      </div>
      <div className={classes.form}>
        <NormalInputField name="reference" label="Reference" />
        <NormalInputField
          name="external_reference"
          label="External Reference"
        />
        <NormalSubagentAutocompleteField
          id="CommonChartsStats__NormalSubagentAutocompleteField"
          label="Sub Agent"
          name="source_entity_autocomplete"
        />
        <NormalInputField
          name="accommodation_meta__name__icontains"
          label="Hotel Name"
        />
        <NormalInputField
          name="destination_name__icontains"
          label="Destination"
        />
        <NormalRangePicker
          label="Check In Range"
          from_key="check_in_from"
          to_key="check_in_to"
          min_date="2000-01-01"
        />
        <NormalInputField name="guest_leader" label="Guest Leader" />
        <NormalInputField label="Provider/Vendor" name="provider__icontains" />
        <NormalRangePicker
          label="Created"
          from_key="created__gte"
          to_key="created__lte"
          min_date="2000-01-01"
        />
        <NormalInputField name="tags" label="Tags" />
        <NormalSelectField
          name="non_redundable"
          label="Non Refundable"
          options={[
            ["", "------"],
            [true, "Yes"],
            [false, "No"],
          ]}
        />
        <NormalSelectField
          name="status"
          label="Status"
          options={[
            ["", "------"],
            ["CO", "Completed"],
            ["CA", "Cancelled"],
            ["PE", "Pending"],
            ["FA", "Failed"],
          ]}
        />
      </div>
    </div>
  );
};
export default Filters;
