import { OverviewFlight } from "../Components/OverviewFlight";

// ============================= ACTIONS =============================
import {
  getAdhocOriginTrpSrvSelector,
  getAdhocDestTrpSrvSelector,
} from "@src/selectors/Project/TripPlanner";

import _ from "lodash";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import React from "react";
import { createUseStyles } from "react-jss";
import { daySrvContainerStyles } from "../Components/Day";
import { TrpServiceHeader } from "../Components/service_panels";
import CoachService from "../Components/addhoc/coach";
import { FerryService } from "../Components/Modals/products/addhoc/ferry";
import { TrainService } from "../Components/Modals/products/addhoc/train";
import { useServiceCardExpansion } from "@src/pages/hooks";
import { isServiceFilterSelectedSelector } from "@src/selectors/Project/TripPlanner/service_filters";
import { OverviewTrain } from "../Components/OverviewTrain.js";
import { OverviewFerry } from "../Components/OverviewFerry";

const AdhocSrvRouter = ({
  srv,
  srvType,
  onEditFerryService,
  onEditTrainService,
  onEditCoachService,
}) => {
  switch (srvType) {
    case "COA":
      return <CoachService srv={srv} onEdit={onEditCoachService} />;
    case "TRA":
      return <TrainService srv={srv} onEdit={onEditTrainService} />;
    case "FER":
      return <FerryService srv={srv} onEdit={onEditFerryService} />;
    default:
      return null;
  }
};
AdhocSrvRouter.defaultProps = {
  srv: {},
};
AdhocSrvRouter.propTypes = {
  srvType: PropTypes.string.isRequired,
  srv: PropTypes.object.isRequired,
  onEditFerryService: PropTypes.func.isRequired,
  onEditTrainService: PropTypes.func.isRequired,
  onEditCoachService: PropTypes.func.isRequired,
};

const styles = createUseStyles({
  OverviewTransportation: (props) =>
    daySrvContainerStyles({ currentStep: props.currentStep }),
});
const OverviewTransportation = ({
  headerTitle,
  showHeader,
  dest_addhoc_srv,
  currentStep,
  returnExtremal,
  lastTrp,
  extremal,
  originOrder,
  destinationOrder,
  onSetAddhocDestDest,
  onEditFerryService,
  onEditTrainService,
  onEditCoachService,
}) => {
  const { origin_addhoc_srv, tripLeg, hasTrains, hasFlights, hasFerries } =
    useSelector((state) => {
      const origin_addhoc_srv = getAdhocOriginTrpSrvSelector(state);

      const tripLeg = state.tripPlannerLegsReducer.find(
        (l) =>
          l.origin_order === originOrder &&
          l.destination_order === destinationOrder
      );
      var hasTrains = (state.tripPlannerTrainsReducer?.services ?? []).some(
        (s) => s.legUid === tripLeg?.uid
      );
      if (!hasTrains) {
        hasTrains =
          state.tripPlannerTrainsReducer.leg_search_state?.[tripLeg?.uid] ??
          false;
      }

      var hasFlights = (state.tripPlannerFlightsReducer?.sessions ?? []).some(
        (fl) => fl?.payload?.legUid === tripLeg?.uid
      );
      if (!hasFlights) {
        hasFlights = (state.tripPlannerFlightsReducer?.sessions ?? []).some(
          (fl) => fl?.payload?.relatedLegUids.includes(tripLeg?.uid)
        );
      }

      const hasFerries = (state.tripPlannerFerriesReducer?.sessions ?? []).some(
        (s) => s?.payload?.legUid === tripLeg?.uid
      );

      return { origin_addhoc_srv, tripLeg, hasTrains, hasFlights, hasFerries };
    });

  const { services_collapsed, toggleSelfCollapse } = useServiceCardExpansion();

  const serviceFilterIsSelected = useSelector((state) =>
    isServiceFilterSelectedSelector(state, { service_type: "TR" })
  );

  const classes = styles({ currentStep });

  return !serviceFilterIsSelected ? null : (
    <React.Fragment>
      {showHeader && (
        <TrpServiceHeader
          title={headerTitle}
          onSetAddhocDestDest={
            extremal || returnExtremal ? null : onSetAddhocDestDest
          }
        />
      )}
      {!lastTrp && extremal && origin_addhoc_srv ? (
        <AdhocSrvRouter
          srv={origin_addhoc_srv}
          srvType={origin_addhoc_srv.addhoc_service_type}
          onEditFerryService={onEditFerryService}
          onEditTrainService={onEditTrainService}
          onEditCoachService={onEditCoachService}
        />
      ) : dest_addhoc_srv ? (
        <AdhocSrvRouter
          srv={dest_addhoc_srv}
          srvType={dest_addhoc_srv.addhoc_service_type}
          onEditFerryService={onEditFerryService}
          onEditTrainService={onEditTrainService}
          onEditCoachService={onEditCoachService}
        />
      ) : (
        <div className={classes.OverviewTransportation}>
          {tripLeg?.disabled ? null : (
            <React.Fragment>
              {hasFlights && (
                <OverviewFlight
                  legUid={tripLeg.uid}
                  currentStep={currentStep}
                  services_collapsed={services_collapsed}
                  toggleSelfCollapse={toggleSelfCollapse}
                />
              )}
              {hasTrains && (
                <OverviewTrain
                  legUid={tripLeg?.uid}
                  currentStep={currentStep}
                  services_collapsed={services_collapsed}
                  toggleSelfCollapse={toggleSelfCollapse}
                />
              )}
              {hasFerries && (
                <OverviewFerry
                  legUid={tripLeg.uid}
                  currentStep={currentStep}
                  services_collapsed={services_collapsed}
                  toggleSelfCollapse={toggleSelfCollapse}
                />
              )}
            </React.Fragment>
          )}
        </div>
      )}
    </React.Fragment>
  );
};
OverviewTransportation.defaultProps = {
  headerTitle: "",
  showHeader: false,
  extremal: false,
  returnExtremal: false,
  lastTrp: false,
};
OverviewTransportation.propTypes = {
  headerTitle: PropTypes.string.isRequired,
  showHeader: PropTypes.bool.isRequired,
  origin_addhoc_srv: PropTypes.object,
  dest_addhoc_srv: PropTypes.object,
  currentStep: PropTypes.number.isRequired,
  extremal: PropTypes.bool,
  returnExtremal: PropTypes.bool,
  destOrder: PropTypes.number,
  lastTrp: PropTypes.bool.isRequired,
  extremalIsRound: PropTypes.bool.isRequired,
  originOrder: PropTypes.number,
  destinationOrder: PropTypes.number,
  onSetAddhocDestDest: PropTypes.func,
  onEditFerryService: PropTypes.func.isRequired,
  onEditTrainService: PropTypes.func.isRequired,
  onEditCoachService: PropTypes.func.isRequired,
};
const mapStateToProps = (state, ownProps) => {
  const { destOrder, lastTrp } = ownProps;

  var dest_addhoc_srv = null;
  if (destOrder) {
    dest_addhoc_srv = getAdhocDestTrpSrvSelector(state, {
      originDestOrder: destOrder,
      destinationDestOrder: lastTrp ? null : destOrder + 1,
    });
  }

  var extremalIsRound = false;
  if (!_.isEmpty(state.tripPlannerReturnData)) {
    extremalIsRound =
      state.tripPlannerDestinations[0].id ==
        _.last(state.tripPlannerDestinations).id &&
      state.tripPlannerOriginData.destination.id ==
        state.tripPlannerReturnData.destination.id;
  }

  return {
    dest_addhoc_srv,
    extremalIsRound,
  };
};
export default connect(mapStateToProps, null)(OverviewTransportation);
