//==================================LIST============================================
export const MEMBERS_LOADING_LIST = "MEMBERS_LOADING_LIST";
export const MEMBERS_FETCH_LIST_DATA = "MEMBERS_FETCH_LIST_DATA";
export const MEMBERS_IDLE_LIST = "MEMBERS_IDLE_LIST";
export const MEMBERS_VIEW_TYPE_CHANGE = "MEMBERS_VIEW_TYPE_CHANGE";

//=================================FILTER===========================================
export const MEMBERS_INITIALIZE_LIST_FILTER_FORM =
  "MEMBERS_INITIALIZE_LIST_FILTER_FORM";
export const MEMBERS_UPDATE_LIST_FILTER_FORM =
  "MEMBERS_UPDATE_LIST_FILTER_FORM";
export const MEMBERS_APPLY_LIST_FILTER = "MEMBERS_APPLY_LIST_FILTER";
export const MEMBERS_RESET_LIST_FILTER_FORM = "MEMBERS_RESET_LIST_FILTER_FORM";

//============================COLUMNS===============================================
export const MEMBERS_APPLY_LIST_COLUMNS_FORM =
  "MEMBERS_APPLY_LIST_COLUMNS_FORM";

//===========================ABOUT US MODAL=========================================
export const SYSTEM_NET_HIDE_ABOUT_US_MODAL = "SYSTEM_NET_HIDE_ABOUT_US_MODAL";
export const SYSTEM_NET_SHOW_ABOUT_US_MODAL = "SYSTEM_NET_SHOW_ABOUT_US_MODAL";
export const SYSTEM_NET_GET_ABOUT_US_MODAL_DATA =
  "SYSTEM_NET_GET_ABOUT_US_MODAL_DATA";

//================================CONNET MODAL=====================================
export const MEMBERS_SHOW_CONNECT_MODAL = "MEMBERS_SHOW_CONNECT_MODAL";
export const MEMBERS_HIDE_CONNECT_MODAL = "MEMBERS_HIDE_CONNECT_MODAL";
export const MEMBERS_SEND_EMAIL_CONTENT = "MEMBERS_SEND_EMAIL_CONTENT";
export const MEMBERS_BEGIN_SEND_CONNECT_EMAIL =
  "MEMBERS_BEGIN_SEND_CONNECT_EMAIL";
export const MEMBERS_END_SEND_CONNECT_EMAIL = "MEMBERS_END_SEND_CONNECT_EMAIL";
export const MEMBERS_GET_CONNECT_MODAL_DATA = "MEMBERS_GET_CONNECT_MODAL_DATA";
