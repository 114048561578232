import React from "react";
import { createUseStyles } from "react-jss";
import { GeneralStats } from "../GeneralStats";
import CreateBarChart from "../../ChartComponents/CreateBarChart";
import { variables } from "@src/jsssetup";
import PropTypes from "prop-types";
import _ from "lodash";
import TransTxt from "@src/components/common/SxFormatMessage";
import { injectIntl } from "react-intl";

const trendsReportTable = createUseStyles({
  TrendsReportTable: {
    display: "grid",
    gridTemplateRows: "max-content",
  },
  tableContainer: {
    display: "grid",
    border: `2px solid #FF6600`,
    borderRadius: "10px",
    textAlign: "center",
  },
  table: {
    width: "100%",
  },
  tHead: {
    backgroundColor: "#FF6600",
  },
  headRow: {
    "background": "#FF6600",
    "& th:first-child": {
      borderTopLeftRadius: "10%",
    },
    "& th:last-child": {
      borderTopRightRadius: "10%",
    },
  },
  headCell: {
    backgroundColor: "#FF6600",
    textAlign: "center",
    color: "white",
    padding: variables.half_gap,
    fontFamily: "futura,sans-serif",
    fontWeight: "400",
  },
  tableCell: {
    textAlign: "center",
    padding: `${variables.half_gap} ${variables.normal_gap}`,
    fontFamily: "futura,sans-serif",
    height: "2rem",
  },
});

const TrendsReportTable = ({ data }) => {
  const classes = trendsReportTable();

  return (
    <div className={classes.TrendsReportTable}>
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead className={classes.tHead}>
            <tr className={classes.headRow}>
              <th className={classes.headCell}>
                <TransTxt id="B2B_stats_TRF_trends__count" />
              </th>
              <th className={classes.headCell}>
                <TransTxt id="B2B_stats_TRF_trends__bounts" />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={classes.tableCell}>
                {_.get(data, "trends.count") !== 0
                  ? _.get(data, "trends.count") || "N/A"
                  : "0"}
              </td>
              <td className={classes.tableCell}>
                {_.get(data, "trends.bound_count") !== 0
                  ? _.get(data, "trends.bound_count") || "N/A"
                  : "0"}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
TrendsReportTable.propTypes = {
  data: PropTypes.object.isRequired,
};

const styles = createUseStyles({
  ACCTrends: {
    display: "grid",
    padding: variables.normal_gap,
  },
  statsTable: {
    display: "grid",
    justifyItems: "center",
  },
  charts: {
    display: "grid",
    gridAutoFlow: "column",
    // gridTemplateColumns: "22rem 1rem 22rem 1rem 22rem",
    justifyItems: "center",
    height: "20rem",
  },
  chart1: {
    display: "grid",
  },
  chart2: {
    display: "grid",
  },
  chart3: {
    display: "grid",
  },
  divider: {
    justifySelf: "center",
    alignSelf: "center",
    height: "85%",
    border: `1px solid rgba(0, 0, 0, 0.1)`,
    gridColumn: "1 span",
    width: "0%",
  },
});

var TRFTrends = ({ intl, data }) => {
  const classes = styles();

  const peakOperatingHoursChartData = _.get(data, "trends.pick.items", []).map(
    ([hour, value], idx) => ({
      name: `${idx === 0 ? "00" : ("0" + hour).slice(-2)}-${(
        "0" +
        (parseInt(hour) + 6)
      ).slice(-2)}`,
      value,
    })
  );
  const peakOperatingHoursChartColors = [
    "#FF6600",
    "#FFB381",
    "#B8B8B8",
    "#B0754E",
  ];

  const pickUpBeforDepartureChartData = _.get(
    data,
    "trends.before.items",
    []
  ).map(([name, value]) => ({
    name: name,
    value,
  }));
  const pickUpBeforDepartureChartColors = [
    "#FF6600",
    "#FFB381",
    "#B8B8B8",
    "#B0754E",
  ];

  const pickUpAfterArrivalChartData = _.get(data, "trends.after.items", []).map(
    ([name, value]) => ({
      name: name,
      value,
    })
  );
  const pickUpAfterArrivalColors = ["#FF6600", "#FFB381", "#B8B8B8", "#B0754E"];

  return (
    <div className={classes.TRFTrends}>
      <div className={classes.stats}>
        <GeneralStats
          tableTitle={intl.formatMessage({
            id: "B2B_stats_TRF_trends__issued",
          })}
          title={intl.formatMessage({
            id: "B2B_stats_TRF_trends__trends",
          })}
          startDate={_.get(data, "period.start_date")}
          endDate={_.get(data, "period.end_date")}
        />
        <div className={classes.statsTable}>
          <TrendsReportTable data={data} />
        </div>
      </div>
      <div className={classes.charts}>
        <div className={classes.chart}>
          <CreateBarChart
            title={intl.formatMessage({
              id: "B2B_stats_TRF_trends__pek_operating_hours",
            })}
            barWidth={"90%"}
            width={20}
            seriesLabelShow={false}
            predefinedColors={peakOperatingHoursChartColors}
            data={peakOperatingHoursChartData}
          />
        </div>
        <div className={classes.divider}></div>
        <div className={classes.chart2}>
          <CreateBarChart
            title={intl.formatMessage({
              id: "B2B_stats_TRF_trends__pick_up_before_departure",
            })}
            moreDataLabel="h"
            barWidth={"90%"}
            width={20}
            seriesLabelShow={false}
            predefinedColors={pickUpBeforDepartureChartColors}
            data={pickUpBeforDepartureChartData}
          />
        </div>
        <div className={classes.divider}></div>
        <div className={classes.chart3}>
          <CreateBarChart
            title={intl.formatMessage({
              id: "B2B_stats_TRF_trends__peak_up_after_arrival",
            })}
            moreDataLabel={intl.formatMessage({
              id: "B2B_stats_TRF_trends__hour",
            })}
            barWidth={"90%"}
            width={20}
            seriesLabelShow={false}
            predefinedColors={pickUpAfterArrivalColors}
            data={pickUpAfterArrivalChartData}
          />
        </div>
      </div>
    </div>
  );
};
TRFTrends.propTypes = {
  data: PropTypes.object.isRequired,
  intl: PropTypes.object,
  dashboardPayload: PropTypes.object.isRequired,
};
TRFTrends = injectIntl(TRFTrends);

export default TRFTrends;
