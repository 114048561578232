import { Notification } from "rsuite";
import React from "react";

export const notifyPastDate = () => {
  Notification.error({
    title: "Date Validation Error",
    description: <p>Please enter a feature date.</p>,
    duration: 6000,
  });
};
