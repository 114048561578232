import { Icon } from "@iconify/react";
import { variables } from "@src/jsssetup";
import PropTypes from "prop-types";
import React from "react";
import { createUseStyles } from "react-jss";

const activityCxlTagStyles = createUseStyles({
  ActivityCxlTag: (props) => ({
    color: props.refundable ? "green" : "red",
    fontWeight: "bold",
    borderRadius: "5px",
    justifySelf: "start",
    display: "grid",
    gridGap: variables.half_gap,
    alignItems: "center",
    gridTemplateColumns: "max-content 1fr",
  }),
});
export const ActivityCxlTag = ({ refundable }) => {
  const classes = activityCxlTagStyles({ refundable });
  return (
    <span className={classes.ActivityCxlTag}>
      <Icon icon="mdi:calendar-check" />
      {refundable ? "Refundable" : "Non Refundable"}
    </span>
  );
};
ActivityCxlTag.propTypes = {
  refundable: PropTypes.bool.isRequired,
};

const activityLanguesStyles = createUseStyles({
  ActivityLanguages: {
    display: "grid",
    gridTemplateColumns: "max-content max-content 1fr",
    gridGap: variables.half_gap,
    alignContent: "center",
    alignItems: "center",
  },
  lngs: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  title: {
    color: variables.colors.text.colored,
    fontWeight: "600",
  },
});
export const ActivityLanguages = ({ label = "Languages", languages }) => {
  const classes = activityLanguesStyles();
  return (
    <span className={classes.ActivityLanguages}>
      <Icon icon="ic:baseline-translate" />
      <strong className={classes.title}>{label}: </strong>
      <span className={classes.lngs}>{languages.join(", ")}</span>
    </span>
  );
};
ActivityLanguages.propTypes = {
  label: PropTypes.string,
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
    })
  ).isRequired,
};
