import _ from "lodash";
import React, { useContext } from "react";
import PropTypes from "prop-types";
import Authorize from "@src/components/authorization/Authorize";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { WhisperWrapper } from "../common/ui_helpers";
import { InfoButton } from "./InfoButton";
import { InlineIcon } from "@iconify/react";
import { injectIntl } from "react-intl";
import { initialMenu } from "@src/config/menu";
import { withHelpHint } from "@src/pages/hocs";
import { MobileViewContext } from "@src/App";
import { useSelector } from "react-redux";
import { getUserMetaSelector } from "@src/selectors/Shared/user_selectors";

var SidebarBtn = ({ onToggleSidebar, sidebarCollapsed }) => {
  return (
    <div
      className={"MainSideBar__bottom-btns__btn-container"}
      onClick={onToggleSidebar}
      id="toggleSidebarBtn">
      {!sidebarCollapsed ? (
        <InlineIcon
          className="MainSideBar__bottom-btns__btn-container__expand-icon"
          icon="majesticons:menu-expand-left"
        />
      ) : (
        <InlineIcon
          className="MainSideBar__bottom-btns__btn-container__expand-icon"
          icon="majesticons:menu-expand-right"
        />
      )}
    </div>
  );
};
SidebarBtn.propTypes = {
  onToggleSidebar: PropTypes.func.isRequired,
  sidebarCollapsed: PropTypes.bool.isRequired,
};
SidebarBtn = withHelpHint(SidebarBtn, "sidebarBtnHint", "right");

var MainSideBar = ({ intl, sidebarCollapsed, onToggleSidebar }) => {
  const history = useHistory();
  const pathname = history.location.pathname;
  const mobileView = useContext(MobileViewContext);

  const { company_type } = useSelector((state) => getUserMetaSelector(state));

  function changeFirstLevMenu(path) {
    const firstMenu = _.find(initialMenu, (item) => {
      return item.path == path;
    });
    history.push(firstMenu.path);
  }

  return (
    <div className="MainSideBar" data-mobile-view={mobileView}>
      <ul
        className={
          sidebarCollapsed
            ? "MainSideBar__list--collapsed"
            : "MainSideBar__list"
        }>
        {initialMenu
          .filter((item) => {
            const allowedUserTypes = item?.allowed_user_types ?? [];
            if (
              allowedUserTypes.length > 0 &&
              !allowedUserTypes.includes(company_type)
            ) {
              return false;
            } else {
              return true;
            }
          })
          .map((item, index) => {
            const required_groups = item?.required_groups ?? [];
            const activeBar = item.path == `/${pathname.split("/")[1]}`;

            const title = _.upperCase(intl.formatMessage({ id: item.name }));

            return (
              <Authorize
                required_groups={required_groups}
                allowed_groups={item?.allowed_groups ?? []}
                user_required_groups={item.user_required_groups}
                name={item}
                key={index}>
                <WhisperWrapper
                  msg={sidebarCollapsed ? title : ""}
                  placement="right">
                  <li
                    id={_.lowerCase(title)}
                    key={index}
                    onClick={() => changeFirstLevMenu(item.path)}
                    className={`MainSideBar__list__item${
                      activeBar ? " MainSideBar__list__item--selected" : ""
                    }`}>
                    <div>
                      <InlineIcon
                        className="MainSideBar__icon"
                        icon={item.icon}
                      />
                    </div>
                    {sidebarCollapsed ? null : <div>{title}</div>}
                  </li>
                </WhisperWrapper>
              </Authorize>
            );
          })}
      </ul>
      <div className="MainSideBar__bottom-btns">
        <InfoButton />
        <SidebarBtn
          showHint={sidebarCollapsed}
          onToggleSidebar={onToggleSidebar}
          sidebarCollapsed={sidebarCollapsed}
        />
      </div>
    </div>
  );
};
MainSideBar.propTypes = {
  sidebarCollapsed: PropTypes.bool,
  intl: PropTypes.object,
  onToggleSidebar: PropTypes.func.isRequired,
};

export default injectIntl(MainSideBar);
