import _ from "lodash";
import axios from "axios";
import React from "react";
import { toast } from "react-toastify";
import { CANAVERAL_API_BASE_URL, SISI_API_URL } from "@src/api";
import { getAuthHeaders } from "../request";

export function getB2CWebsite(params) {
  return axios.get(`${CANAVERAL_API_BASE_URL}/websites-for-entity/`, {
    params,
  });
}

export async function getB2CWebsiteDetail(uid) {
  return axios.get(`${CANAVERAL_API_BASE_URL}/websites-for-entity/${uid}`);
}

export async function updateB2CWebsite(uid, data) {
  return axios.patch(
    `${CANAVERAL_API_BASE_URL}/websites-for-entity/${uid}`,
    data
  );
}

export async function fetchB2CWebsitesDetail(uid) {
  try {
    const result = await axios.get(
      `${CANAVERAL_API_BASE_URL}/websites-for-entity/${uid}`
    );
    return _.get(result, "data");
  } catch (error) {
    toast.error(
      <p>
        We failed to fetch landing pages for entity. We are sorry for the
        inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return false;
  }
}

export async function getB2CLandingPage(params) {
  try {
    const result = await axios.get(
      `${CANAVERAL_API_BASE_URL}/landing-pages-for-entity`,
      {
        params,
      }
    );
    return _.get(result, "data.results", []);
  } catch (error) {
    toast.error(
      <p>
        We failed to fetch landing pages for entity. We are sorry for the
        inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function getB2CPromoLandingPage(params) {
  try {
    const result = await axios.get(
      `${CANAVERAL_API_BASE_URL}/promo-landing-pages-for-entity`,
      {
        params,
      }
    );
    return _.get(result, "data.results", []);
  } catch (error) {
    toast.error(
      <p>
        We failed to fetch promo landing pages. We are sorry for the
        inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function getB2CWebsitesLandingPageDetail(id) {
  try {
    const result = await axios.get(
      `${CANAVERAL_API_BASE_URL}/landing-pages-for-entity/${id}`
    );
    return _.get(result, "data", []);
  } catch (error) {
    toast.error(
      <p>
        We failed to retrieve landing page. We are sorry for the inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function deleteB2CWebsitesLandingPage(id) {
  try {
    const result = await axios.delete(
      `${CANAVERAL_API_BASE_URL}/landing-pages-for-entity/${id}`
    );
    return result;
  } catch (error) {
    toast.error(
      <p>
        We failed to delete landing page. We are sorry for the inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return false;
  }
}

export async function getB2CWebPromoLPDetail(id) {
  try {
    const result = await axios.get(
      `${CANAVERAL_API_BASE_URL}/promo-landing-pages-for-entity/${id}`
    );
    return _.get(result, "data");
  } catch (error) {
    toast.error(
      <p>
        We failed to retrieve detail promo landing page. We are sorry for the
        inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function deleteB2CWebPromoLP(id) {
  try {
    const result = await axios.delete(
      `${CANAVERAL_API_BASE_URL}/promo-landing-pages-for-entity/${id}`
    );
    return result;
  } catch (error) {
    toast.error(
      <p>
        We failed to delete promo landing page. We are sorry for the
        inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return false;
  }
}

export async function patchB2CWebLPDetail(data, id) {
  try {
    await axios({
      method: "patch",
      url: `${CANAVERAL_API_BASE_URL}/landing-pages-for-entity/${id}`,
      data,
    });
    toast.success(<p>Successfully Update the Landing Page</p>);
    return true;
  } catch (error) {
    toast.error(
      <p>
        We failed to update the landing page. We are sorry for the
        inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function patchB2CWebPromoLPDetail(data, id) {
  try {
    await axios({
      method: "patch",
      url: `${CANAVERAL_API_BASE_URL}/promo-landing-pages-for-entity/${id}`,
      data,
    });
    toast.success(<p>Successfully Update the Promo Landing Page</p>);
    return true;
  } catch (error) {
    toast.error(
      <p>
        We failed to update the promo landing page. We are sorry for the
        inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function addB2CWebsitesLandingPage(data) {
  try {
    await axios({
      method: "post",
      url: `${CANAVERAL_API_BASE_URL}/landing-pages-for-entity/`,
      data,
    });
    toast.success(<p>Successfully add the Landing Page</p>);
    return true;
  } catch (error) {
    toast.error(
      <p>
        We failed to add the landing page. We are sorry for the inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function getB2CWebsitesMainPromos(params) {
  try {
    const result = await axios.get(
      `${CANAVERAL_API_BASE_URL}/main-promo-for-entity/`,
      {
        params,
      }
    );
    return _.get(result, "data.results", []);
  } catch (error) {
    toast.error(
      <p>
        We failed to retrieve main promo for entity. We are sorry for the
        inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function getB2CWebsitesMainPromosDetail(id) {
  try {
    const result = await axios.get(
      `${CANAVERAL_API_BASE_URL}/main-promo-for-entity/${id}/`
    );
    return _.get(result, "data");
  } catch (error) {
    toast.error(
      <p>
        We failed to retrieve main promo for entity. We are sorry for the
        inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function deleteB2CWebsitesMainPromos(id) {
  try {
    const result = await axios.delete(
      `${CANAVERAL_API_BASE_URL}/main-promo-for-entity/${id}/`
    );
    return result;
  } catch (error) {
    toast.error(
      <p>
        We failed to delete main promo for entity. We are sorry for the
        inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return false;
  }
}

export async function patchB2CWebsitesMainPromosDetail(data, id) {
  try {
    await axios({
      method: "patch",
      url: `${CANAVERAL_API_BASE_URL}/main-promo-for-entity/${id}/`,
      data,
    });
    toast.success(<p>Successfully update the Main Promos</p>);
    return true;
  } catch (error) {
    toast.error(
      <p>
        We failed to update the main promos. We are sorry for the inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function addB2CWebsitesMainPromos(data) {
  try {
    await axios({
      method: "post",
      url: `${CANAVERAL_API_BASE_URL}/main-promo-for-entity/`,
      data,
    });
    toast.success(<p>Successfully add the Main Promos</p>);
    return true;
  } catch (error) {
    toast.error(
      <p>
        We failed to add the main promos. We are sorry for the inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function getB2CWebsitesRestPromos(params) {
  try {
    const result = await axios.get(
      `${CANAVERAL_API_BASE_URL}/restaurant-promo-for-entity/`,
      {
        params,
      }
    );
    return _.get(result, "data.results", []);
  } catch (error) {
    toast.error(
      <p>
        We failed to retrieve restaurant promo for entity. We are sorry for the
        inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function getB2CWebsitesRestPromosDetail(id) {
  try {
    const result = await axios.get(
      `${CANAVERAL_API_BASE_URL}/restaurant-promo-for-entity/${id}`
    );
    return _.get(result, "data");
  } catch (error) {
    toast.error(
      <p>
        We failed to retrieve restaurant promo for entity. We are sorry for the
        inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function deleteB2CWebsitesRestPromos(id) {
  try {
    const result = await axios.delete(
      `${CANAVERAL_API_BASE_URL}/restaurant-promo-for-entity/${id}`
    );
    return result;
  } catch (error) {
    toast.error(
      <p>
        We failed to delete restaurant promo for entity. We are sorry for the
        inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return false;
  }
}

export async function patchB2CWebsitesRestPromosDetail(data, id) {
  try {
    await axios({
      method: "patch",
      url: `${CANAVERAL_API_BASE_URL}/restaurant-promo-for-entity/${id}/`,
      data,
    });
    toast.success(<p>Successfully update the Restaurant Promos</p>);
    return true;
  } catch (error) {
    toast.error(
      <p>
        We failed to update the restaurant promos. We are sorry for the
        inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function addB2CWebsitesRestPromos(data) {
  try {
    await axios({
      method: "post",
      url: `${CANAVERAL_API_BASE_URL}/restaurant-promo-for-entity/`,
      data,
    });
    toast.success(<p>Successfully add the Restaurant Promos</p>);
    return true;
  } catch (error) {
    toast.error(
      <p>
        We failed to add the restaurant promos. We are sorry for the
        inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function getB2CWebsitesAboutUs(params) {
  try {
    const result = await axios.get(
      `${CANAVERAL_API_BASE_URL}/about-us-for-entity/`,
      {
        params,
      }
    );
    return _.get(result, "data.results", []);
  } catch (error) {
    toast.error(
      <p>
        We failed to fetch about us. We are sorry for the inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function getB2CWebsitesAboutUsDetail(id) {
  try {
    const result = await axios.get(
      `${CANAVERAL_API_BASE_URL}/about-us-for-entity/${id}/`
    );
    return _.get(result, "data");
  } catch (error) {
    toast.error(
      <p>
        We failed to retrieve detail about us. We are sorry for the
        inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function deleteB2CWebsitesAboutUsDetail(id) {
  try {
    const result = await axios.delete(
      `${CANAVERAL_API_BASE_URL}/about-us-for-entity/${id}/`
    );
    return result;
  } catch (error) {
    toast.error(
      <p>
        We failed to delete about us. We are sorry for the inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return False;
  }
}

export async function patchB2CWebsitesAboutUsDetail(data, id) {
  try {
    await axios({
      method: "patch",
      url: `${CANAVERAL_API_BASE_URL}/about-us-for-entity/${id}/`,
      data,
    });
    toast.success(<p>Successfully update the about us</p>);
    return true;
  } catch (error) {
    toast.error(
      <p>
        We failed to update the about us. We are sorry for the inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function addB2CWebsitesAboutUs(data) {
  try {
    await axios({
      method: "post",
      url: `${CANAVERAL_API_BASE_URL}/about-us-for-entity/`,
      data,
    });
    toast.success(<p>Successfully add the about us</p>);
    return true;
  } catch (error) {
    toast.error(
      <p>
        We failed to add the about us. We are sorry for the inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function getB2CWebsitesContactInfo(params) {
  try {
    const result = await axios.get(
      `${CANAVERAL_API_BASE_URL}/contact-info-for-entity/`,
      {
        params,
      }
    );
    return _.get(result, "data.results", []);
  } catch (error) {
    toast.error(
      <p>
        We failed to fetch contact info. We are sorry for the inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function getB2CWebsitesContactInfoDetail(id) {
  try {
    const result = await axios.get(
      `${CANAVERAL_API_BASE_URL}/contact-info-for-entity/${id}`
    );
    return _.get(result, "data");
  } catch (error) {
    toast.error(
      <p>
        We failed to retrieve detail contact info. We are sorry for the
        inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function deleteB2CWebsitesContactInfoDetail(id) {
  try {
    const result = await axios.delete(
      `${CANAVERAL_API_BASE_URL}/contact-info-for-entity/${id}`
    );
    return result;
  } catch (error) {
    toast.error(
      <p>
        We failed to delete contact info. We are sorry for the inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return false;
  }
}

export async function patchB2CWebsitesContactInfoDetail(data, id) {
  try {
    await axios({
      method: "patch",
      url: `${CANAVERAL_API_BASE_URL}/contact-info-for-entity/${id}`,
      data,
    });
    toast.success(<p>Successfully update the contact info</p>);
    return true;
  } catch (error) {
    toast.error(
      <p>
        We failed to update the contact info. We are sorry for the
        inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function addB2CWebsitesContactInfo(data) {
  try {
    await axios({
      method: "post",
      url: `${CANAVERAL_API_BASE_URL}/contact-info-for-entity/`,
      data,
    });
    toast.success(<p>Successfully add the contact info</p>);
    return true;
  } catch (error) {
    toast.error(
      <p>
        We failed to add the contact info. We are sorry for the inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function addB2CWebsitesListPagesInfo(data) {
  try {
    await axios({
      method: "post",
      url: `${CANAVERAL_API_BASE_URL}/list-pages-for-entity`,
      data,
    });
    toast.success(<p>Successfully add the List Page</p>);
    return true;
  } catch (error) {
    toast.error(
      <p>
        We failed to add the list page. We are sorry for the inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function patchB2CWebsitesListPagesInfo(data, id) {
  try {
    await axios({
      method: "patch",
      url: `${CANAVERAL_API_BASE_URL}/list-pages-for-entity/${id}`,
      data,
    });
    toast.success(<p>Successfully update the List Page</p>);
    return true;
  } catch (error) {
    toast.error(
      <p>
        We failed to update the list page. We are sorry for the inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function getB2CWebsitesListPagesInfo(params) {
  try {
    const result = await axios({
      method: "get",
      url: `${CANAVERAL_API_BASE_URL}/list-pages-for-entity`,
      params,
    });
    return _.get(result, "data.results", []);
  } catch (error) {
    toast.error(
      <p>
        We failed to fetch list pages for entity. We are sorry for the
        inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function getB2CWebsitesListPagesDetail(id) {
  try {
    const result = await axios.get(
      `${CANAVERAL_API_BASE_URL}/list-pages-for-entity/${id}/`
    );
    return _.get(result, "data");
  } catch (error) {
    toast.error(
      <p>
        We failed to retrieve detail list pages. We are sorry for the
        inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function deleteB2CWebsitesListPagesDetail(id) {
  try {
    const result = await axios.delete(
      `${CANAVERAL_API_BASE_URL}/list-pages-for-entity/${id}/`
    );
    return result;
  } catch (error) {
    toast.error(
      <p>
        We failed to delete the list page. We are sorry for the inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function getB2CAddonsQuickInfo(params) {
  try {
    const result = await axios({
      method: "get",
      url: `${CANAVERAL_API_BASE_URL}/addons-quick-info/`,
      params,
    });
    return _.get(result, "data.results", []);
  } catch (error) {
    toast.error(
      <p>
        We failed to retrieve addons quick info. We are sorry for the
        inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function getB2CPackagesQuickInfo(params) {
  try {
    const result = await axios({
      method: "get",
      url: `${CANAVERAL_API_BASE_URL}/packages-quick-info/`,
      params,
    });
    return _.get(result, "data.results", []);
  } catch (error) {
    toast.error(
      <p>
        We failed to retrieve the quick info. We are sorry for the
        inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function fetchB2cTermsData(params) {
  try {
    const result = await axios({
      method: "get",
      url: `${CANAVERAL_API_BASE_URL}/terms-for-entity/`,
      params,
    });
    return _.get(result, "data.results", []);
  } catch (error) {
    toast.error(
      <p>
        We failed to fetch terms and conditions. We are sorry for the
        inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function getB2cTermsDetail(id) {
  try {
    const result = await axios({
      method: "get",
      url: `${CANAVERAL_API_BASE_URL}/terms-for-entity/${id}`,
    });
    return _.get(result, "data");
  } catch (error) {
    toast.error(
      <p>
        We failed to get detail terms and conditions. We are sorry for the
        inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function deleteB2cTerms(id) {
  try {
    const result = await axios({
      method: "delete",
      url: `${CANAVERAL_API_BASE_URL}/terms-for-entity/${id}`,
    });
    return result;
  } catch (error) {
    toast.error(
      <p>
        We failed to delete terms and conditions. We are sorry for the
        inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return false;
  }
}

export async function addB2cTermsData(data) {
  try {
    await axios({
      method: "post",
      url: `${CANAVERAL_API_BASE_URL}/terms-for-entity`,
      data,
    });
    toast.success(<p>Successfully add the terms and conditions</p>);
    return true;
  } catch (error) {
    toast.error(
      <p>
        We failed to add the terms and conditions. We are sorry for the
        inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function patchB2cTermsData(data, id) {
  try {
    await axios({
      method: "patch",
      url: `${CANAVERAL_API_BASE_URL}/terms-for-entity/${id}`,
      data,
    });
    toast.success(<p>Successfully update the terms and conditions</p>);
    return true;
  } catch (error) {
    toast.error(
      <p>
        We failed to update the terms and conditions. We are sorry for the
        inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function fetchB2cFAQData(params) {
  try {
    const result = await axios({
      method: "get",
      url: `${CANAVERAL_API_BASE_URL}/faq-for-entity/`,
      params,
    });
    return _.get(result, "data.results", []);
  } catch (error) {
    toast.error(
      <p>
        We failed to fetch FAQs. We are sorry for the inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function getB2cFAQDetail(id) {
  try {
    const result = await axios({
      method: "get",
      url: `${CANAVERAL_API_BASE_URL}/faq-for-entity/${id}`,
    });
    return _.get(result, "data");
  } catch (error) {
    toast.error(
      <p>
        We failed to retrieve detail FAQs. We are sorry for the inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function deleteB2cFAQ(id) {
  try {
    const result = await axios({
      method: "delete",
      url: `${CANAVERAL_API_BASE_URL}/faq-for-entity/${id}`,
    });
    return result;
  } catch (error) {
    toast.error(
      <p>
        We failed to delete the FAQ. We are sorry for the inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return false;
  }
}

export async function addB2cFAQData(data) {
  try {
    await axios({
      method: "post",
      url: `${CANAVERAL_API_BASE_URL}/faq-for-entity`,
      data,
    });
    toast.success(<p>Successfully add the FAQ</p>);
    return true;
  } catch (error) {
    toast.error(
      <p>
        We failed to add the FAQ. We are sorry for the inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function patchB2cFAQData(data, id) {
  try {
    await axios({
      method: "patch",
      url: `${CANAVERAL_API_BASE_URL}/faq-for-entity/${id}`,
      data,
    });
    toast.success(<p>Successfully update the FAQ</p>);
    return true;
  } catch (error) {
    toast.error(
      <p>
        We failed to update the FAQ. We are sorry for the inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function getHotelBlackList({
  source_entity,
  website_api_key,
  filters,
  onError = (error) =>
    toast.error(
      <p>
        We failed to retrieve the black list. We are sorry for the
        inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    ),
}) {
  const auth_headers = getAuthHeaders();
  try {
    const result = await axios.get(`${SISI_API_URL}/accommodator/black-list/`, {
      params: { ...filters, source_entity, website_api_key },
      headers: { ...auth_headers },
    });
    return _.get(result, "data", []);
  } catch (error) {
    if (typeof onError === "function") {
      onError(error);
    }
    return false;
  }
}

export async function addB2CBlackList(data) {
  const auth_headers = getAuthHeaders();
  try {
    await axios.post(`${SISI_API_URL}/accommodator/black-list`, data, {
      headers: { ...auth_headers },
    });
    toast.success(<p>Successfully add the Hotel to black list</p>);
    return true;
  } catch (error) {
    toast.error(
      <p>
        We failed to add the Hotel to black list. We are sorry for the
        inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return false;
  }
}

export async function deleteB2CBlackList({
  source_entity,
  website_api_key,
  id,
}) {
  const auth_headers = getAuthHeaders();
  try {
    await axios.delete(`${SISI_API_URL}/accommodator/black-list/${id}/`, {
      params: { source_entity, website_api_key },
      headers: { ...auth_headers },
    });
    toast.success(<p>Successfully delete the Hotel from black list</p>);
    return true;
  } catch (error) {
    toast.error(
      <p>
        We failed to delete the Hotel from black list. We are sorry for the
        inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return false;
  }
}

export async function cloneHotelBlackList({
  source_entity,
  source_website_api_key,
  target_website_api_key,
  onError = (error) =>
    toast.error(
      <p>
        We failed to clone the black list. We are sorry for the inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    ),
}) {
  try {
    const auth_headers = getAuthHeaders();
    const result = await axios.put(
      `${SISI_API_URL}/accommodator/black-list/clone-black-list/`,
      {
        source_entity,
        source_website_api_key,
        target_website_api_key,
      },
      {
        headers: { ...auth_headers },
      }
    );
    return _.get(result, "data", []);
  } catch (error) {
    if (typeof onError === "function") {
      onError(error);
    }
    return false;
  }
}
