import { SISI_API_URL } from "@src/api";
import service, { getAuthHeaders } from "@src/api/request";
import axios from "axios";

export async function fetchProviders(params) {
  const headers = getAuthHeaders();
  try {
    const result = await axios.get("/bid/provider/", { params, headers });
    return result.data;
  } catch (error) {
    return null;
  }
}

export function fetchProvidersList(para) {
  return service({
    url: "/bid/provider/",
    method: "get",
    params: para,
  });
}

export function fetchSuppliersProfile(id) {
  return service({
    url: `/bid/provider/${id}`,
    method: "get",
  });
}

export function getSuppliersOptions() {
  return service({
    url: "/bid/provider/",
    method: "options",
  });
}

export function submitSuppliersProfile(id, payload, mode) {
  return service({
    url: `/bid/provider/${id}/`,
    method: mode,
    data: payload,
  });
}

export function addNewSuppliers(data) {
  return service({
    url: "/bid/provider/",
    method: "post",
    data: data,
  });
}

// export const addNewSuppliers = async (payload) => {
//   try {
//     await axios.post(`/bid/provider/`, payload);
//     toast.success(<p>Successfully add the Provide</p>);
//     return true;
//   } catch (error) {
//     toast.error(
//       <p>
//         We failed to add The Provider. We are sorry for the inconvenience.
//         <br />
//         <span>{_.get(error, "response.data.message")}</span>
//         <br />
//         <span>{_.get(error, "response.data.error")}</span>
//       </p>,
//       { duration: 5000 }
//     );
//     return false;
//   }
// };

export function getProviderProperty(para) {
  return service({
    url: "/bid/provider-property/",
    method: "get",
    params: para,
  });
}

export function addNewSupplierProperty(data) {
  return service({
    url: "/bid/provider-property/",
    method: "post",
    data: data,
  });
}

export function getProviderPropertyDetail(id) {
  return service({
    url: `/bid/provider-property/${id}`,
    method: "get",
  });
}

export function patchSupplierProperty(id, payload) {
  return service({
    url: `/bid/provider-property/${id}/`,
    method: "patch",
    data: payload,
  });
}

export async function providerAutocomplete({ name, source_entity }) {
  const auth_headers = getAuthHeaders();
  try {
    const result = await axios.get(
      `${SISI_API_URL}/crm/providers/autocomplete`,
      {
        params: { name, source_entity },
        headers: { ...auth_headers },
      }
    );
    return result.data;
  } catch (error) {
    return null;
  }
}
