import { Notification, Message } from "rsuite";

import PropTypes from "prop-types";
import React from "react";

export default class NotifierErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    Notification.error({
      title: "Something went Wrong",
      description:
        "We were unable to complete this action. We are sorry for the inconvenience!",
      duration: 6000,
    });
    console.log(error);
    return { hasError: true };
  }
  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return null;
    }

    return children;
  }
}

NotifierErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};
