import _ from "lodash";
import {
  TRIPPLANNER_INIT,
  TRIPPLANNER_LOAD_TRIP,
  TRIPPLANNER_MKT_CONTENT_CHANGE_COLOR_PALETTE,
  TRIPPLANNER_MKT_CONTENT_MODAL_HIDE,
  TRIPPLANNER_MKT_CONTENT_MODAL_IDLE,
  TRIPPLANNER_MKT_CONTENT_MODAL_LOADING,
  TRIPPLANNER_MKT_CONTENT_MODAL_MODE_TOGGLE,
  TRIPPLANNER_MKT_CONTENT_MODAL_SHOW,
  TRIPPLANNER_MKT_CONTENT_MODAL_CHANGE_TEMPLATE,
  TRIPPLANNER_MKT_CXL_CHANGE,
  TRIPPLANNER_MKT_DIST_PERIOD_ADD,
  TRIPPLANNER_MKT_DIST_PERIOD_CHANGE,
  TRIPPLANNER_MKT_DIST_PERIOD_COPY,
  TRIPPLANNER_MKT_DIST_PERIOD_REMOVE,
  TRIPPLANNER_MKT_INCEXC_ADD,
  TRIPPLANNER_MKT_INCEXC_CHANGE,
  TRIPPLANNER_MKT_INCEXC_REMOVE,
  TRIPPLANNER_MKT_STOP_SALES_ADD,
  TRIPPLANNER_MKT_STOP_SALES_CHANGE,
  TRIPPLANNER_MKT_STOP_SALES_REMOVE,
  TRIPPLANNER_MKT_DIST_SETUP_CHANGE,
  TRIPPLANNER_MKT_CONTENT_MODAL_CHANGE_DATA_CONTENT,
  TRIPPLANNER_MKT_CONTENT_MODAL_SIMPLE_HIDE,
  TRIPPLANNER_MKT_CONTENT_INIT,
  TRIPPLANNER_DBD_CONTENT_CHANGE,
} from "@src/actions/Project/TripPlanner/types";
import {
  PACKAGES_PREVIEW_MODAL_HIDE,
  PACKAGES_PREVIEW_MODAL_IDLE,
  PACKAGES_PREVIEW_MODAL_LOADING,
  PACKAGES_PREVIEW_MODAL_SHOW,
} from "@src/actions/Project/Packages/Wholesales/types";
import update from "immutability-helper";

const periodTemplate = {
  name: "Period 1",
  distribution_from: "",
  distribution_to: "",
  operation_from: "",
  operation_to: "",
  pricing_data: [],
  cancellation_policy_data: {
    type: "non-refundable",
    days_ahead: 0,
    fee: { value: 0, currency: "" },
  },
};
const tripPlannerMktDistributionPeriodsInitial = {
  periods: [{ ...periodTemplate }],
};
export const tripPlannerMktDistributionPeriods = (
  state = tripPlannerMktDistributionPeriodsInitial,
  action
) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return tripPlannerMktDistributionPeriodsInitial;
    case TRIPPLANNER_LOAD_TRIP:
      return tripPlannerMktDistributionPeriodsInitial;
    case TRIPPLANNER_MKT_DIST_PERIOD_ADD: {
      return {
        ...state,
        periods: [
          ...state.periods,
          { ...periodTemplate, name: `Period ${state.periods.length + 1}` },
        ],
      };
    }
    case TRIPPLANNER_MKT_DIST_PERIOD_REMOVE: {
      const { idx } = action;
      const periods = _.cloneDeep(state.periods);
      periods.splice(idx, 1);
      return { ...state, periods };
    }
    case TRIPPLANNER_MKT_DIST_PERIOD_CHANGE: {
      const { idx, key, value } = action;
      return {
        ...state,
        periods: state.periods.map((per, pidx) => {
          if (idx == pidx) {
            per[key] = value;
          }
          return per;
        }),
      };
    }
    case TRIPPLANNER_MKT_DIST_PERIOD_COPY: {
      const { idx } = action;
      const periods = _.cloneDeep(state.periods);
      const buffer = _.cloneDeep(periods[idx]);
      buffer.name = `${buffer.name} (Copy)`;
      periods.splice(idx + 1, 0, buffer);
      return { ...state, periods };
    }
    case TRIPPLANNER_MKT_CXL_CHANGE: {
      const { idx, key, value } = action;
      return {
        ...state,
        periods: state.periods.map((period, pidx) => {
          const tmp = _.cloneDeep(period);
          if (pidx == idx) {
            tmp.cancellation_policy_data[key] = value;
          }
          return tmp;
        }),
      };
    }
    default:
      return state;
  }
};

const stopSalesTemplate = {
  name: "",
  distribution_from: "",
  distribution_to: "",
  operation_from: "",
  operation_to: "",
  status: "AC",
};
const tripPlannerMktStopSalesInitial = { stop_sales: [] };
export const tripPlannerMktStopSales = (
  state = tripPlannerMktStopSalesInitial,
  action
) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return tripPlannerMktStopSalesInitial;
    case TRIPPLANNER_LOAD_TRIP:
      return tripPlannerMktStopSalesInitial;
    case TRIPPLANNER_MKT_STOP_SALES_ADD:
      return {
        ...state,
        stop_sales: [
          ...state.stop_sales,
          {
            ...stopSalesTemplate,
            name: `Stop Sales ${state.stop_sales.length + 1}`,
          },
        ],
      };
    case TRIPPLANNER_MKT_STOP_SALES_REMOVE: {
      const { idx } = action;
      const sales = _.cloneDeep(state.stop_sales);
      sales.splice(idx, 1);

      return { ...state, stop_sales: sales };
    }
    case TRIPPLANNER_MKT_STOP_SALES_CHANGE: {
      const { idx, key, value } = action;
      return {
        ...state,
        stop_sales: state.stop_sales.map((s, sidx) => {
          var tmp = { ...s };
          if (sidx == idx) {
            tmp[key] = value;
          }
          return tmp;
        }),
      };
    }
    default:
      return state;
  }
};

const ruleTmp = {
  name_en: "",
  name_el: "",
  name_cn: "",
  description: "",
  description_en: "",
  description_el: "",
  description_cn: "",
};
const tripPlannerMktIncExcInitial = { inclusions: [], exclusions: [] };
export const tripPlannerMktIncExc = (
  state = tripPlannerMktIncExcInitial,
  action
) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return tripPlannerMktIncExcInitial;
    case TRIPPLANNER_LOAD_TRIP: {
      const {
        data: { inclusions_exclusions },
      } = action;
      return { ...state, ...inclusions_exclusions };
    }
    case TRIPPLANNER_MKT_INCEXC_ADD: {
      const { ruleType } = action;
      return { ...state, [ruleType]: [...state[ruleType], { ...ruleTmp }] };
    }
    case TRIPPLANNER_MKT_INCEXC_REMOVE: {
      const { ruleType, idx } = action;
      const rules = _.cloneDeep(state[ruleType]);
      rules.splice(idx, 1);
      return { ...state, [ruleType]: rules };
    }
    case TRIPPLANNER_MKT_INCEXC_CHANGE: {
      const { ruleType, idx, key, value } = action;
      return {
        ...state,
        [ruleType]: state[ruleType].map((rule, ridx) => {
          const tmp = { ...rule };
          if (ridx == idx) {
            tmp[key] = value;
          }
          return tmp;
        }),
      };
    }
    default:
      return state;
  }
};

const tripPlannerMktPkgContentModalInitial = {
  show: false,
  loading: false,
  mode: "view", // or edit
  templatecolors: [
    { name: "teal", active: true },
    { name: "purple", active: false },
    { name: "yellow", active: false },
    { name: "orange", active: false },
  ],
  templates: [
    { name: "sectioned", active: true },
    { name: "one_page", active: false },
  ],
};
export const tripPlannerMktPkgContentModal = (
  state = tripPlannerMktPkgContentModalInitial,
  action
) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return tripPlannerMktPkgContentModalInitial;
    case TRIPPLANNER_LOAD_TRIP: {
      const { content } = action.data;
      return {
        ...state,
        templatecolors: [
          ...state.templatecolors.filter(
            (color) =>
              !_.get(content, "templatecolors", [])
                .map((clr) => clr.name)
                .includes(color.name)
          ),
          ..._.get(content, "templatecolors", []),
        ],
        templates: [
          ...state.templates.filter(
            (template) =>
              !_.get(content, "templates", [])
                .map((tmp) => tmp.name)
                .includes(template.name)
          ),
          ..._.get(content, "templates", []),
        ],
      };
    }
    case TRIPPLANNER_MKT_CONTENT_MODAL_SHOW:
      return { ...state, show: true };
    case TRIPPLANNER_MKT_CONTENT_MODAL_HIDE:
      return { ...state, show: false };
    case TRIPPLANNER_MKT_CONTENT_MODAL_SIMPLE_HIDE:
      return { ...state, show: false };
    case TRIPPLANNER_MKT_CONTENT_MODAL_LOADING:
      return { ...state, loading: true };
    case TRIPPLANNER_MKT_CONTENT_MODAL_IDLE:
      return { ...state, loading: false };
    case TRIPPLANNER_MKT_CONTENT_MODAL_MODE_TOGGLE:
      return { ...state, mode: state.mode == "edit" ? "view" : "edit" };
    case TRIPPLANNER_MKT_CONTENT_CHANGE_COLOR_PALETTE: {
      const { color } = action;

      return {
        ...state,
        templatecolors: state.templatecolors.map((colortmp) => {
          if (colortmp.name == color) {
            return { name: color, active: true };
          } else {
            return { ...colortmp, active: false };
          }
        }),
      };
    }
    case TRIPPLANNER_MKT_CONTENT_MODAL_CHANGE_TEMPLATE: {
      const { template } = action;
      return {
        ...state,
        templates: state.templates.map((tmp) => {
          if (tmp.name == template) {
            return { name: template, active: true };
          } else {
            return { ...tmp, active: false };
          }
        }),
      };
    }
    default:
      return state;
  }
};

const tripPlannerMktPkgContentInitial = {};
export const tripPlannerMktPkgContent = (
  state = tripPlannerMktPkgContentInitial,
  action
) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return tripPlannerMktPkgContentInitial;
    case TRIPPLANNER_LOAD_TRIP: {
      const {
        data: { content },
      } = action;
      return { ...state, ...content };
    }
    case TRIPPLANNER_MKT_CONTENT_INIT: {
      const { content } = action;
      return { ...tripPlannerMktPkgContentInitial, ...content };
    }
    case TRIPPLANNER_MKT_CONTENT_MODAL_HIDE: {
      const { content } = action;
      return { ...tripPlannerMktPkgContentInitial, ...content };
    }
    case TRIPPLANNER_MKT_CONTENT_MODAL_CHANGE_DATA_CONTENT: {
      const { content } = action;
      return { ...state, ...content };
    }
    default:
      return state;
  }
};

export const tripPlannerDayByDayContent = (state = [], action) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return [];
    case TRIPPLANNER_LOAD_TRIP: {
      const {
        data: { dbd_descriptions },
      } = action;
      if (Array.isArray(dbd_descriptions)) {
        return dbd_descriptions;
      } else {
        return [];
      }
    }
    case TRIPPLANNER_DBD_CONTENT_CHANGE: {
      const { days } = action;
      return days;
    }
    default:
      return state;
  }
};

const tripPlannerMktDistributionSetupInitial = {
  entities: [],
  sector: [],
  availability: "UV",
  b2c_channels: [],
};
export const tripPlannerMktDistributionSetup = (
  state = tripPlannerMktDistributionSetupInitial,
  action
) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return tripPlannerMktDistributionSetupInitial;
    case TRIPPLANNER_LOAD_TRIP: {
      const {
        data: { mkt_distribution_setup },
      } = action;

      return {
        ...state,
        entities: _.get(mkt_distribution_setup, "entities", []),
        sector: _.get(mkt_distribution_setup, "sector", []),
        availability: _.get(mkt_distribution_setup, "availability", []),
        b2c_channels: _.get(mkt_distribution_setup, "b2c_channels", []),
      };
    }
    case TRIPPLANNER_MKT_DIST_SETUP_CHANGE: {
      const { key, value } = action;
      return { ...state, [key]: value };
    }
    default:
      return state;
  }
};

export const TRIPPLANNER_PKG_METADATA_CHANGE =
  "TRIPPLANNER_PKG_METADATA_CHANGE";
const tripPlannerMktPkgMetadataInitial = { order: 0, themes: [], rating: 0 };
export const tripPlannerPkgMetadata = (
  state = tripPlannerMktPkgMetadataInitial,
  action
) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return tripPlannerMktPkgMetadataInitial;
    case TRIPPLANNER_LOAD_TRIP: {
      const {
        data: { order, themes, rating },
      } = action;
      return update(state, {
        order: { $set: order },
        themes: { $set: themes },
        rating: { $set: rating || 0 },
      });
    }
    case TRIPPLANNER_PKG_METADATA_CHANGE: {
      const { order, themes, rating } = action;
      return update(state, {
        order: { $set: order },
        themes: { $set: themes },
        rating: { $set: rating },
      });
    }
    default:
      return state;
  }
};

// ============================ HIGHLIGHTS ===========================
const TRIPPLANNER_MKT_SAVE_HIGHLIGHTS = "TRIPPLANNER_MKT_SAVE_HIGHLIGHTS";
export const tripPlannerMktHighlights = (state = [], action) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return [];
    case TRIPPLANNER_LOAD_TRIP: {
      const {
        data: { highlights },
      } = action;
      return highlights || [];
    }
    case TRIPPLANNER_MKT_SAVE_HIGHLIGHTS: {
      const { highlights } = action;
      return highlights;
    }
    default:
      return state;
  }
};
export const saveHighlightsAction = (highlights) => ({
  type: TRIPPLANNER_MKT_SAVE_HIGHLIGHTS,
  highlights,
});

// ======================= BOOKING INFORMATION =======================
const TRIPPLANNER_MKT_SAVE_BOOKING_INFO = "TRIPPLANNER_MKT_SAVE_BOOKING_INFO";
const tripPlannerMktBookingInfoInitial = {
  description_en: "",
};
export const tripPlannerMktBookingInfo = (
  state = tripPlannerMktBookingInfoInitial,
  action
) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return tripPlannerMktBookingInfoInitial;
    case TRIPPLANNER_LOAD_TRIP: {
      const {
        data: { booking_information },
      } = action;
      return booking_information || tripPlannerMktBookingInfoInitial;
    }
    case TRIPPLANNER_MKT_SAVE_BOOKING_INFO: {
      const { booking_information } = action;
      return booking_information;
    }
    default:
      return state;
  }
};
export const saveBookingInfoAction = (booking_information) => ({
  type: TRIPPLANNER_MKT_SAVE_BOOKING_INFO,
  booking_information,
});

// ===================== NON TRIPPLANNER REDUCERS ====================
const previewPackageModalInitial = { show: false, loading: false, pkgId: null };
export const previewPackageModal = (
  state = previewPackageModalInitial,
  action
) => {
  switch (action.type) {
    case PACKAGES_PREVIEW_MODAL_SHOW: {
      const { pkgId } = action;
      return { ...state, show: true, pkgId };
    }
    case PACKAGES_PREVIEW_MODAL_HIDE:
      return previewPackageModalInitial;
    case PACKAGES_PREVIEW_MODAL_IDLE:
      return { ...state, loading: false };
    case PACKAGES_PREVIEW_MODAL_LOADING:
      return { ...state, loading: true };
    default:
      return state;
  }
};
