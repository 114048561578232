import { Tag, IconButton, Icon, Checkbox, List, Avatar } from "rsuite";
import SuIcon from "@src/style/icon/components/SuIcon";
import { WhisperHelper } from "@src/components/common/ui_helpers";

// ============================= ACTIONS =============================
import { initBook } from "@src/actions/Project/TripPlanner";

// ============================ SELECTORS ============================
import {
  areFlightsRulesAccepted,
  getSetupFormDataSelector,
  areTrfPrebooksAcceptedSelector,
  isFlightsPrebookCompleteSelector,
  areAccPrebooksAcceptedSelector,
  areActPrebooksAcceptedSelector,
} from "@src/selectors/Project/TripPlanner";

import _ from "lodash";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import LeanAuthHoc from "@src/components/authorization/LeanAuthHoc";
import SelectedServicesController from "../Components/prebook/selected_services";
import { createUseStyles } from "react-jss";
import { cardStyles, variables } from "@src/jsssetup";
import { TripPlanContext } from "../TripPlanner";
import { getSrvsTypeForAnyBookingSelector } from "@src/selectors/Project/TripPlanner/prebook/selected_services";
import {
  PostGuestDataPrebookServices,
  useFerriesPrebookRequirement,
} from "../Components/prebook/post_guest_data_prebook_services";
import {
  getSrvsForBookingNoSelector,
  getSrvsForOptionBookingNoSelector,
  isAccPrebookRequiredSelector,
} from "@src/selectors/Project/TripPlanner/prebook";

/**
 * Checks if all required prebook processes (based on the selected services)
 * have been completed.
 * @returns {boolean} True if all required prebooks have been completed.
 */
export function usePreGuestPrebooksComplete() {
  const { flRegNo, flOptNo } = useSelector((state) => {
    const flRegNo = getSrvsForBookingNoSelector(state, { srv_type: "FL" });
    const flOptNo = getSrvsForOptionBookingNoSelector(state, {
      srv_type: "FL",
    });

    return { flRegNo, flOptNo };
  });
  const flPrebookRequired = flRegNo + flOptNo > 0;
  const isFlightsPrebookComplete = useSelector((state) =>
    isFlightsPrebookCompleteSelector(state)
  );

  const accPrebookComplete = useSelector((state) =>
    areAccPrebooksAcceptedSelector(state)
  );
  const accPrebookRequired = useSelector((state) =>
    isAccPrebookRequiredSelector(state)
  );

  const trfRegNo = useSelector((state) =>
    getSrvsForBookingNoSelector(state, { srv_type: "TRF" })
  );
  const trfOptNo = useSelector((state) =>
    getSrvsForOptionBookingNoSelector(state, { srv_type: "TRF" })
  );
  const trfPrebookRequired = trfRegNo + trfOptNo > 0;
  const trfPrebookComplete = useSelector((state) =>
    areTrfPrebooksAcceptedSelector(state)
  );

  const actRegNo = useSelector((state) =>
    getSrvsForBookingNoSelector(state, { srv_type: "ACT" })
  );
  const actOptNo = useSelector((state) =>
    getSrvsForOptionBookingNoSelector(state, { srv_type: "ACT" })
  );
  const actPrebookIsRequired = actRegNo + actOptNo > 0;
  const actPrebookComplete = useSelector((state) =>
    areActPrebooksAcceptedSelector(state)
  );

  return [
    flPrebookRequired ? isFlightsPrebookComplete : true,
    actPrebookIsRequired ? actPrebookComplete : true,
    trfPrebookRequired ? trfPrebookComplete : true,
    accPrebookRequired ? accPrebookComplete : true,
  ].every((x) => x);
}

const travellersInfoStyles = createUseStyles({
  TravellersInfo: cardStyles.card,
  header: {
    ...cardStyles.header,
    display: "grid",
    gridTemplateColumns: "1fr auto",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    alignItems: "center",
  },
  warningBanner: { maxWidth: "13rem" },
  body: {
    ...cardStyles.body,
    gridTemplateColumns: "repeat(2, 1fr)",
    justifyItems: "center",
  },
  paxType: (props) => ({
    display: "grid",
    gridGap: variables.half_gap,
    gridTemplateColumns: "repeat(4, max-content)",
    alignItems: "center",
    color: props.isValid
      ? variables.colors.deepblue
      : variables.colors.layoverRed,
  }),
});
const TravellersInfo = ({ guestsInfo }) => {
  const { setShowTripGuestsModal } = useContext(TripPlanContext);

  const { guestsInfoIsValid, accPrebookComplete, bookSrvTypes } = useSelector(
    (state) => {
      const bookSrvTypes = getSrvsTypeForAnyBookingSelector(state);

      const guestsInfoIsValid = state.tripPlannerGuestsInfoValidity;
      const acc_prebook_info = state.tripPlannerPrebookAccInfo;

      var accPrebookComplete = Object.entries(acc_prebook_info).map(
        ([__, prebookData]) => prebookData.accepted
      );
      if (!bookSrvTypes.includes("ACC")) {
        accPrebookComplete = true;
      } else {
        if (accPrebookComplete.length > 0) {
          accPrebookComplete = accPrebookComplete.every((v) => v === true);
        } else {
          accPrebookComplete = false;
        }
      }

      return { guestsInfoIsValid, accPrebookComplete, bookSrvTypes };
    }
  );
  const isValid = guestsInfoIsValid;

  const explanationMsg = [
    "A red colored icon means there are missing guest information",
    "or that not all guests are assigned to rooms. A blue colored icon",
    "means thal all guest information and room assignments are valid valid.",
  ].join(" ");

  const classes = travellersInfoStyles({ isValid });

  const prebooksAreComplete = usePreGuestPrebooksComplete();
  const canEditGuests = bookSrvTypes.length > 0 && prebooksAreComplete;

  return (
    <div className={classes.TravellersInfo}>
      <div className={classes.header}>
        <h5>2. Travellers Information</h5>
        {canEditGuests ? (
          <IconButton
            size="xs"
            color={guestsInfoIsValid ? "green" : "blue"}
            icon={<Icon icon={guestsInfoIsValid ? "check" : "edit"} />}
            onClick={() => setShowTripGuestsModal(true)}>
            {guestsInfoIsValid ? "Valid Guest Information" : "Edit Guests"}
          </IconButton>
        ) : (
          <p className={classes.warningBanner}>
            Please make sure all <strong>Prebook Actions</strong> are complete.
          </p>
        )}
      </div>
      <div className={classes.body}>
        <div className={classes.paxType}>
          <WhisperHelper msg={explanationMsg} />
          <SuIcon size="xl" icon="icon-AdultTravellericon1" />
          <strong>Adults:</strong>
          <Tag color={!isValid ? "red" : "blue"}>
            {(guestsInfo?.adults ?? []).length}
          </Tag>
        </div>
        <div className={classes.paxType}>
          <WhisperHelper msg={explanationMsg} />
          <SuIcon size="xl" icon="icon-YoungTravellericon1" />
          <strong>Children:</strong>
          <Tag color={!isValid ? "red" : "blue"}>
            {(guestsInfo?.children ?? []).length}
          </Tag>
        </div>
      </div>
    </div>
  );
};
TravellersInfo.propTypes = {
  guestsInfo: PropTypes.object.isRequired,
};

const bookSectionStyles = createUseStyles({
  BookSection: cardStyles.card,
  header: cardStyles.header,
  body: cardStyles.body,
});
var BookSection = ({ index = 3, onBook }) => {
  const { setShowBookingModal } = useContext(TripPlanContext);

  const classes = bookSectionStyles();

  const prebooksAreComplete = usePreGuestPrebooksComplete();

  const { guestsInfoIsValid } = useSelector((state) => {
    const guestsInfoIsValid = state.tripPlannerGuestsInfoValidity;
    return { guestsInfoIsValid };
  });
  const isValid = guestsInfoIsValid && prebooksAreComplete;

  return (
    <div className={classes.BookSection}>
      <div className={classes.header}>
        <h5>{index}. Booking Actions</h5>
      </div>
      <div className={classes.body}>
        {isValid ? (
          <IconButton
            color="green"
            icon={<Icon icon="check-circle" />}
            onClick={() => {
              setShowBookingModal(true);
              onBook().then((d) => {
                if (d === null) setShowBookingModal(false);
              });
            }}>
            Confirm Bookings
          </IconButton>
        ) : (
          <p>
            Please make sure <strong>Prebooks</strong> and your{" "}
            <strong>Travellers Information</strong> is valid first.
          </p>
        )}
      </div>
    </div>
  );
};
BookSection.propTypes = {
  index: PropTypes.number.isRequired,
  onBook: PropTypes.func.isRequired,
};
BookSection = LeanAuthHoc(BookSection, {
  allowed_groups: ["demo_account"],
  invert_decision: true,
});

const prebookSetupControllerStyles = createUseStyles({
  PrebookSetupController: {
    padding: variables.normal_gap,
    overflow: "auto",
    display: "grid",
    gridGap: variables.normal_gap,
    gridAutoRows: "max-content",
  },
});
const PrebookSetupController = ({ guestsInfo, onBook }) => {
  const classes = prebookSetupControllerStyles();

  const { aferPrebookRequired } = useFerriesPrebookRequirement();

  return (
    <div className={classes.PrebookSetupController}>
      <SelectedServicesController />
      <TravellersInfo guestsInfo={guestsInfo} />
      <PostGuestDataPrebookServices />
      <BookSection index={aferPrebookRequired ? 4 : 3} onBook={onBook} />
    </div>
  );
};
PrebookSetupController.propTypes = {
  guestsInfo: PropTypes.object.isRequired,
  onBook: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  const flightRulesAccepted = areFlightsRulesAccepted(state);
  const trfPrebookAccepted = areTrfPrebooksAcceptedSelector(state);

  const { requiredServices } = getSetupFormDataSelector(state);

  return {
    requiredServices,
    flightRulesAccepted,
    trfPrebookAccepted,
    roomsMappingIsValid: state.tripPlannerRoomsGuestMappingValidity,
    guestsInitialized: !_.isEmpty(state.tripPlannerGuestsInfo),
    guestsInfo: state.tripPlannerGuestsInfo,
    roomsMapping: state.tripPlannerRoomsGuestMapping,
    requestedRooms: state.tripPlannerAccPax,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onBook: () => dispatch(initBook()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrebookSetupController);
