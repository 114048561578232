import _ from "lodash";
import { Icon, Dropdown } from "rsuite";
import TransTxt from "@src/components/common/SxFormatMessage";

// ============================= ACTIONS =============================
import {
  requestTripSave,
  viewTripPreferences,
  requestUpdateOffer,
} from "@src/actions/Project/TripPlanner";

import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import React, { useCallback, useContext } from "react";
import { getSetupFormDataSelector } from "@src/selectors/Project/TripPlanner";
import { showQuotationPDFModal } from "@src/actions/Project/TripPlanner/modals/quotation_modal";
import { createUseStyles } from "react-jss";
import { variables } from "@src/jsssetup";
import {
  overviewCtrlSectionHeaderStyles,
  overviewCtrlSectionStyles,
} from "../Controllers/ServicesFilter";
import { TripPlanContext } from "../TripPlanner";
import { useRouteMatch } from "react-router-dom";
import { DayByDayContentBtn } from "../Controllers/PrebookActionController";
import { withAppUserType } from "@src/components/authorization/permissionhocs";

const ctrlBtnStyles = {
  "display": "grid",
  "gridTemplateColumns": "repeat(3,max-content)",
  "placeItems": "center",
  "placeContent": "center",
  "gridGap": `calc(${variables.normal_gap} / 1.5)`,
  "paddingLeft": `${variables.half_gap} !important`,
  "paddingRight": `${variables.half_gap} !important`,
  "paddingTop": `${variables.normal_gap} !important`,
  "paddingBottom": `${variables.normal_gap} !important`,
  "height": `calc(${variables.normal_gap} * 2.5) !important`,
  "background": variables.colors.easy.orange,
  "color": variables.colors.text.light,
  "fontWeight": "600",
  "fontSize": "medium",
  "&:hover": {
    color: "#575757 !important",
    backgroundColor: "#e5e5ea !important",
  },
};

const SaveAsBtn = (props) => {
  const { onClick } = props;
  return (
    <Dropdown.Item
      icon={
        <Icon
          icon="save"
          style={{
            ...ctrlBtnStyles,
            height: variables.double_gap,
            fontSize: "small",
          }}
        />
      }
      placement="right"
      onClick={onClick}>
      <TransTxt id="save_as" startCase />
    </Dropdown.Item>
  );
};
SaveAsBtn.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const QuotationPDFDropdownBtn = () => {
  const dispatch = useDispatch();
  const onClick = useCallback(() => dispatch(showQuotationPDFModal()));
  return <Dropdown.Item onClick={onClick}>Download PDF</Dropdown.Item>;
};

var TravelCompanionDropdownBtn = ({ setShowOfferModal }) => {
  return (
    <Dropdown.Item onClick={() => setShowOfferModal(true)}>
      Travel Companion
    </Dropdown.Item>
  );
};
TravelCompanionDropdownBtn.propTypes = {
  setShowOfferModal: PropTypes.func.isRequired,
};
TravelCompanionDropdownBtn = withAppUserType(TravelCompanionDropdownBtn, [
  "member",
]);

const controlPanelStyles = createUseStyles({
  ControlPanel: overviewCtrlSectionStyles(),
  header: overviewCtrlSectionHeaderStyles(),
  content: {
    position: "relative",
    display: "grid",
    gridTemplateColumns: "1fr",
    gap: variables.half_gap,
    marginTop: variables.normal_gap,
  },
  col2: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  dropdown: {
    "height": "max-content",
    "& .rs-dropdown-toggle": { width: "100%" },
    "& .rs-btn": {
      display: "grid",
      gridTemplateColumns: "1fr max-content max-content",
      alignContent: "center",
      background: variables.colors.easy.orange,
      color: variables.colors.text.light,
      fontWeight: "600",
      width: "100%",
      height: "70%",
    },
    "& .rs-dropdown-toggle-caret": {
      position: "relative",
      margin: "0 -2rem 0 0 !important",
      top: 0,
      left: 0,
      right: 0,
    },
  },
  ctrlBtn: { ...ctrlBtnStyles },
  [`@media ${variables.media.smallscreen}`]: {
    content: { gridTemplateColumns: "repeat(2, 1fr)" },
  },
});
const ControlPanel = ({
  usage,
  saveAsAvailable,
  onViewPreferences,
  onRequestUpdateOffer,
}) => {
  const classes = controlPanelStyles();
  const {
    setShowSaveAsModal,
    setShowOfferModal,
    setShowDayByDayContentModal,
    setShowSrvPreferencesModal,
  } = useContext(TripPlanContext);

  const dispatch = useDispatch();
  const onRequestSave = useCallback(
    () => dispatch(requestTripSave()),
    [dispatch]
  );

  const {
    params: { mode },
  } = useRouteMatch();

  function QuotationPDFBtn() {
    const dispatch = useDispatch();
    const onClick = useCallback(() => dispatch(showQuotationPDFModal()));
    return (
      <button
        className="Button"
        data-size="xs"
        data-full-width="true"
        onClick={onClick}>
        Download PDF
      </button>
    );
  }

  return (
    <div className={classes.ControlPanel}>
      <div className={classes.header}>CONTROL</div>
      <div className={classes.content}>
        {mode !== "view-offer" && (
          <Dropdown
            title="Save"
            appearance="default"
            size="xs"
            className={classes.dropdown}>
            <Dropdown.Item onClick={onRequestSave}>
              {usage === "OFF" ? (
                <TransTxt id="tripplan__save_offer" startCase />
              ) : (
                <TransTxt id="save" startCase />
              )}
            </Dropdown.Item>
            {saveAsAvailable && (
              <Dropdown.Item onClick={() => setShowSaveAsModal(true)}>
                <TransTxt id="save_as" startCase />
              </Dropdown.Item>
            )}
          </Dropdown>
        )}
        {usage === "OFF" && mode !== "view-offer" && (
          <Dropdown
            className={classes.dropdown}
            title="Send Offer"
            size="xs"
            appearance="default">
            <TravelCompanionDropdownBtn setShowOfferModal={setShowOfferModal} />
            <QuotationPDFDropdownBtn />
          </Dropdown>
        )}
        {mode === "view-offer" && (
          <React.Fragment>
            <button
              className="Button"
              data-size="xs"
              data-full-width="true"
              onClick={onRequestUpdateOffer}>
              Update Offer
            </button>
            <QuotationPDFBtn />
          </React.Fragment>
        )}
        <button
          className="Button"
          type="button"
          data-full-width="true"
          data-size="xs"
          onClick={(e) => {
            e.preventDefault();
            setShowSrvPreferencesModal(true);
          }}>
          Service Preferences
        </button>
        <button
          className="Button"
          type="button"
          data-full-width="true"
          data-size="xs"
          onClick={(e) => {
            e.preventDefault();
            onViewPreferences();
          }}>
          {mode === "view-offer" ? "Offer Preferences" : "Trip Preferences"}
        </button>
        <DayByDayContentBtn onClick={() => setShowDayByDayContentModal(true)} />
      </div>
    </div>
  );
};
ControlPanel.defaultProps = { mode: "normal", usage: "RQ" };
ControlPanel.propTypes = {
  mode: PropTypes.oneOf(["view", "view-offer", "recalculate", "normal"]),
  usage: PropTypes.oneOf(["RQ", "OFF", "MKT", "BI"]).isRequired,
  saveAsAvailable: PropTypes.bool.isRequired,
  onRequestUpdateOffer: PropTypes.func.isRequired,
  onViewPreferences: PropTypes.func.isRequired,
  onRequestSaveAs: PropTypes.func,
};
const mapStateToProps = (state) => {
  const { mode, id } = state.tripPlannerInstanceData;
  const { usage } = getSetupFormDataSelector(state);

  const saveAsAvailable = id ? true : false;
  return { mode, usage, saveAsAvailable };
};
const mapDispatchToProps = (dispatch) => {
  const leadingDebDispatch = _.debounce(dispatch, { leading: 500 });

  return {
    onRequestUpdateOffer: () => leadingDebDispatch(requestUpdateOffer()),
    onViewPreferences: () => dispatch(viewTripPreferences()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ControlPanel);
