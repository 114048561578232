import React from "react";
import {
  // ===================== ACCOMMODATION PREBOOK =====================
  TRIPPLANNER_HIDE_PREBOOKACCOMMODATION_MODAL,
  TRIPPLANNER_IDLE_PREBOOKACCOMMODATION_MODAL,
  TRIPPLANNER_LOADING_PREBOOKACCOMMODATION_MODAL,
  TRIPPLANNER_SET_PREBOOKACCOMMODATION_DATA,
  TRIPPLANNER_RESET_PREBOOKACCOMMODATION_DATA,
  TRIPPLANNER_ACCEPT_PREBOOKACCOMMODATION,
  TRIPPLANNER_UNACCEPT_ALL_PREBOOKACCOMMODATIONS,
  // ======================== BOOKING ACTIONS ========================
  TRIPPLANNER_BOOKING_DATA_UPDATE,
} from "./types";

// =============================== API ===============================
import { tripBook } from "@src/api/Project/TripPlanner";

// ============================= ACTIONS =============================
import { prepareTripInstancePayload } from "./save_actions";
import { fetchDepositBalance } from "@src/actions/ManagementConsole/AccountStatement/index";

// ============================ SELECTORS ============================
import {
  areAccPrebooksAcceptedSelector,
  getRequiredDeposit,
  areFlightsRulesAccepted,
  getTrfHandlingInfoSelector,
  areTrfPrebooksAcceptedSelector,
} from "@src/selectors/Project/TripPlanner";
import { convertToCurrency } from "@src/selectors/Financial";

// ========================== NOTIFICATIONS ==========================
import {
  notifyAcceptAllTermsForRooms,
  notifyNotAllFlightRulesAccepted,
  notifyBookingInitializationFailed,
  notifyTransferPrebookNotAccepted,
  notifySelectService,
} from "@src/components/common/Notifications";
import { getServicesListSelector } from "@src/selectors/Project/TripPlanner/generic_service_selectors";

import _ from "lodash";
import { initRoomGuestMapping, initGuestsInfo } from "./guest_actions";

import { resetTrfPrebookData } from "./transfers_prebook";
import { changeViewStyle } from "./step_three";
import {
  resetSrvsForOptionBooking,
  resetSrvsForRegularBooking,
} from "./step_four/service_select";
import {
  getSrvsUidForBookingSelector,
  getSrvsUidForOptBookingSelector,
} from "@src/selectors/Project/TripPlanner/prebook";
import { toast } from "react-toastify";
import { getSubEntityAggregates } from "@src/api";
import { resetFlightsPrebook } from "./step_four/prebook/flights";

export const stepFourRequirements = () => async (dispatch, getState) => {
  const state = getState();
  const { requiredServices } = state.tripPlannerItinerarySetupForm;

  if (state.tripPlannerOverviewStyle.view_style == "day_by_day") {
    dispatch(changeViewStyle("overview"));
  }

  // Reset selected services for any kind of bookings.
  dispatch(resetSrvsForRegularBooking());
  dispatch(resetSrvsForOptionBooking());

  // Reset prebook data for any kind of bookings.
  dispatch(resetFlightsPrebook());

  if (_.isEmpty(state.tripPlannerGuestsInfo)) {
    await dispatch(initGuestsInfo());
  }

  // We need to refresh this in case the rooms has changed.
  await dispatch(initRoomGuestMapping());

  if (requiredServices.includes("ACC")) {
    dispatch(resetAccPrebookData());
  }

  if (requiredServices.includes("TF")) {
    dispatch(resetTrfPrebookData());
  }

  dispatch(fetchDepositBalance());
  return true;
};

export const stepFourBackRequirements = () => (dispatch) => {
  dispatch(resetAccPrebookData());
};

// ====================== ACCOMMODATION PREBOOK ======================
export const hidePrebookAccModal = () => (dispatch, getState) => {
  const state = getState();

  if (!areAccPrebooksAcceptedSelector(state)) {
    dispatch(unacceptAllAccPrebook());
  }

  dispatch({ type: TRIPPLANNER_HIDE_PREBOOKACCOMMODATION_MODAL });
};

export const idlePrebookAccModal = () => {
  return { type: TRIPPLANNER_IDLE_PREBOOKACCOMMODATION_MODAL };
};

export const loadingPrebookAccModal = () => {
  return { type: TRIPPLANNER_LOADING_PREBOOKACCOMMODATION_MODAL };
};

export const resetAccPrebookData = () => {
  return { type: TRIPPLANNER_RESET_PREBOOKACCOMMODATION_DATA };
};

export const setAccPrebookData = (destOrder, prebookData) => {
  return {
    type: TRIPPLANNER_SET_PREBOOKACCOMMODATION_DATA,
    destOrder,
    prebookData,
  };
};

export const acceptAccPrebook = (destOrder, accepted) => {
  return {
    type: TRIPPLANNER_ACCEPT_PREBOOKACCOMMODATION,
    destOrder,
    accepted,
  };
};

export const unacceptAllAccPrebook = () => {
  return { type: TRIPPLANNER_UNACCEPT_ALL_PREBOOKACCOMMODATIONS };
};

export const acceptAllAccPrebook = () => (dispatch, getState) => {
  const state = getState();

  if (areAccPrebooksAcceptedSelector(state)) {
    dispatch(hidePrebookAccModal());
  } else {
    notifyAcceptAllTermsForRooms();
  }
};

function validateAccsBooking({ state, all_srvs_for_booking }) {
  const srvs = getServicesListSelector(state);

  const accUids = all_srvs_for_booking
    .filter((srv) => srv.srv_type === "ACC")
    .map((srv) => srv.uid);

  const accSrvs = srvs.filter((srv) => accUids.includes(srv.uid));

  const apiAccsRequired = accSrvs.some(
    (srv) => srv.accommodation_service_type === "ACC"
  );

  if (apiAccsRequired && !areAccPrebooksAcceptedSelector(state)) {
    notifyAcceptAllTermsForRooms();
    return false;
  }

  const adhocAccSrvs = accSrvs.filter(
    (acc) => acc.accommodation_service_type === "ADD"
  );

  if (
    adhocAccSrvs.length > 0 &&
    adhocAccSrvs.some((srv) => !Object.keys(srv).includes("booking_metadata"))
  ) {
    toast.error(
      <span>
        Please add booking remarks for all <strong>Adhoc Accommodation</strong>{" "}
        services
      </span>,
      {
        duration: 5000,
      }
    );
    return false;
  }

  return true;
}

function validateTrfsBooking({ state }) {
  if (!areTrfPrebooksAcceptedSelector(state)) {
    notifyTransferPrebookNotAccepted();
    return false;
  }

  return true;
}

function validateAddOnBooking() {
  return true;
}

function validateFlightBooking({ state }) {
  if (!areFlightsRulesAccepted(state)) {
    notifyNotAllFlightRulesAccepted();
    return false;
  }
  return true;
}

function validateAdhocTrpService({ srv_type, state, all_srvs_for_booking }) {
  const srv_verbose_mapping = {
    COA: "Coach",
    TRA: "Train",
    FER: "Ferry",
  };

  const coa_uids_for_booking = all_srvs_for_booking
    .filter((srv) => srv.srv_type === srv_type)
    .map((srv) => srv.uid);

  const srvs = _.flatten(Object.values(state.tripPlanTrpAddhocServices))
    .filter((srv) => srv.addhoc_service_type === srv_type)
    .filter((srv) => coa_uids_for_booking.includes(srv.service.uid));

  if (!srvs.length) {
    return true;
  }

  if (
    srvs.some((srv) => !Object.keys(srv.service).includes("booking_metadata"))
  ) {
    toast.error(
      <span>
        Please add booking remarks for all{" "}
        <strong>{srv_verbose_mapping[srv_type]}</strong> services
      </span>,
      {
        duration: 5000,
      }
    );
    return false;
  }

  return true;
}

function validateCOAAdhocService({ state, all_srvs_for_booking }) {
  return validateAdhocTrpService({
    srv_type: "COA",
    state,
    all_srvs_for_booking,
  });
}

function validateFERAdhocService({ state, all_srvs_for_booking }) {
  return validateAdhocTrpService({
    srv_type: "FER",
    state,
    all_srvs_for_booking,
  });
}

function validateTRAAdhocService({ state, all_srvs_for_booking }) {
  return validateAdhocTrpService({
    srv_type: "TRA",
    state,
    all_srvs_for_booking,
  });
}

function validateCustomService({ state, srv_type, msg, filterFn }) {
  const srvs = _.flatten(Object.values(state.tripPlannerDbDCustomSrvData))
    .filter((srv) => srv.custom_service_type === srv_type)
    .filter((srv) => filterFn(srv));

  if (srvs.some((srv) => !Object.keys(srv).includes("booking_metadata"))) {
    toast.error(msg, { duration: 5000 });
    return false;
  }

  return true;
}

function validateRestBooking({ state, all_srvs_for_booking }) {
  const srv_uids = all_srvs_for_booking
    .filter((srv) => srv.srv_type === "REST")
    .map((srv) => srv.uid);

  return validateCustomService({
    state,
    srv_type: "REST",
    msg: "Please add booking remarks for all restaurants",
    filterFn: (srv) => srv_uids.includes(srv.reference),
  });
}

function validateCoordBooking({ state, all_srvs_for_booking }) {
  const srv_uids = all_srvs_for_booking
    .filter((srv) => srv.srv_type === "COO")
    .map((srv) => srv.uid);

  return validateCustomService({
    state,
    srv_type: "COO",
    msg: "Please add booking remarks for all coordinators",
    filterFn: (srv) => srv_uids.includes(srv?.uid),
  });
}

function validateGenSrvBooking({ state, all_srvs_for_booking }) {
  const srv_uids = all_srvs_for_booking
    .filter((srv) => srv.srv_type === "GEN")
    .map((srv) => srv.uid);

  return validateCustomService({
    state,
    srv_type: "GEN",
    msg: "Please add booking remarks for all general services",
    filterFn: (srv) => srv_uids.includes(srv.id),
  });
}

const validateBooking = async (state, required_deposit) => {
  const srvs_uids_for_booking = getSrvsUidForBookingSelector(state);
  const srvs_uids_for_option_booking = getSrvsUidForOptBookingSelector(state);
  const all_srvs_for_booking = [
    ...srvs_uids_for_booking,
    ...srvs_uids_for_option_booking,
  ];

  const isSubEntity = !_.get(state, "userMeta.company_type", "").includes(
    "member"
  );
  // This should be removed from redux and moved to a request done here.
  var { balance, currency } = state.accountStatementDepositBalance;
  if (isSubEntity) {
    // Get the available credit for the sub entity.
    const source_entity = `${state.userMeta.company_id}___${state.userMeta.company_type}`;
    const parent_entity = `${state.userMeta.parent_entity_id}___${state.userMeta.parent_entity_type}`;
    const results = await getSubEntityAggregates({
      parent_entity,
      source_entity,
    });
    const availableCredit = _.get(results, "balance", 0);
    const availableCreditCurrency = _.get(results, "currency", "EUR");

    balance = convertToCurrency(state, {
      amount: availableCredit,
      currentCurrency: availableCreditCurrency,
      toGlobal: true,
    });
  } else {
    balance = convertToCurrency(state, {
      amount: balance,
      currentCurrency: currency.notation,
      toGlobal: true,
    });
  }

  // if (required_deposit > balance.convertedAmount) {
  //   notifyNotEnoughDepositBalance();
  //   return false;
  // }

  // if (!state.tripPlannerGuestsInfoValidity) {
  //   notifyGuestInformationIncomplete();
  //   return false;
  // }

  if (![...srvs_uids_for_option_booking, ...srvs_uids_for_booking].length) {
    notifySelectService();
    return false;
  }

  const srvValidateMapping = {
    ACC: validateAccsBooking,
    FL: validateFlightBooking,
    COA: validateCOAAdhocService,
    FER: validateFERAdhocService,
    TRA: validateTRAAdhocService,
    TRF: validateTrfsBooking,
    TF: validateTrfsBooking,
    REST: validateRestBooking,
    COO: validateCoordBooking,
    GEN: validateGenSrvBooking,
    MI: validateAddOnBooking,
    AFER: () => true, // No need to run extra validations for ferries
    ATRA: () => true, // No need to run extra validations for trains
    ACT: () => true, // No need to run extra validations for activities
  };

  const validations = [
    ...new Set(all_srvs_for_booking.map((srv) => srv.srv_type)),
  ].map((srv_type) => {
    if (srv_type === "FL") {
      return true;
    }

    return srvValidateMapping[srv_type]({
      state,
      all_srvs_for_booking,
    });
  });

  if (validations.some((vl) => !vl)) {
    return false;
  }

  return true;
};

const prepareTrfHandlingInfo = (state, transfer_data) => {
  Object.entries(transfer_data).forEach((e) => {
    if (Object.keys(e[1]).includes("inbound_outbound")) {
      e[1].inbound_outbound[0]["handling_info"] = getTrfHandlingInfoSelector(
        state,
        {
          destOrder: e[0],
          trf_type: "inbound_outbound",
        }
      );
    } else {
      if (Object.keys(e[1]).includes("inbound")) {
        e[1].inbound[0]["handling_info"] = getTrfHandlingInfoSelector(state, {
          destOrder: e[0],
          trf_type: "inbound",
        });
      }

      if (Object.keys(e[1]).includes("outbound")) {
        e[1].outbound[0]["handling_info"] = getTrfHandlingInfoSelector(state, {
          destOrder: e[0],
          trf_type: "outbound",
        });
      }
    }
  });

  return transfer_data;
};

export const initBook = () => async (dispatch, getState) => {
  const state = getState();
  const required_deposit = getRequiredDeposit(state);

  if (!(await validateBooking(state, required_deposit))) return null;

  var {
    pricing_data,
    itinerary_setup,
    origin_data,
    return_data,
    destinations,
    transportation_preferences,
    accommodation_preferences,
    accommodation_pax_preferences,
    transfer_preferences,
    transportation_data,
    accommodation_data,
    transfer_data,
    addon_data,
    activity_data,
    dbd_custom_srv_data,
    acc_custom_srv_data,
    dbd_addhoc_srv_data,
    trp_addhoc_srv_data,
    trf_addhoc_srv_data,
    acc_addhoc_srv_data,
    trip_instance_id,
    trip_instance_type,
  } = prepareTripInstancePayload(state);

  // Curate accommodation data.
  _.flatten(Object.values(accommodation_data)).forEach(
    (acc) =>
      (acc.metadata = _.omit(acc.metadata, [
        "images",
        "description",
        "full_description",
        "geodata",
      ]))
  );
  Object.entries(accommodation_data)
    .filter(([___, accommodations]) => accommodations.length > 0)
    .forEach(([destOrder, accommodations]) => {
      const dest = destinations.find((d) => d.order == destOrder);
      accommodations[0]["destination"] = { id: dest.id, name: dest.name_en };
    });

  var guests = _.flatten(
    Object.values(_.cloneDeep(state.tripPlannerGuestsInfo))
  );
  // Remove uneeded date from guests
  guests.forEach((guest, idx) => {
    Object.entries(guest).forEach(([key, value]) => {
      if (value == null || value === "") delete guests[idx][key];
      if (key === "service_bundle") delete guests[idx][key];
    });
  });

  transfer_data = prepareTrfHandlingInfo(state, transfer_data);

  var guests_mapping = state.tripPlannerRoomsGuestMapping;

  const booking_message = state.tripPlannerBookingMessage;

  const booking_services = state.tripPlannerForBookingSrvs;
  const option_services = state.tripPlannerForOptionBookingSrvs;
  const offer_data = state.tripPlannerOfferInstanceData;

  const srvs_uids_for_booking = getSrvsUidForBookingSelector(state);
  const srvs_uids_for_option_booking = getSrvsUidForOptBookingSelector(state);
  const detailed_pricing_data = state.tripPlannerDetailedSrvPricing;

  const book_payload = {
    guests,
    guests_mapping,
    required_deposit,
    pricing_data,
    itinerary_setup,
    origin_data,
    return_data,
    destinations,
    transportation_preferences,
    accommodation_preferences,
    accommodation_pax_preferences,
    transfer_preferences,
    transportation_data,
    accommodation_data,
    transfer_data,
    addon_data,
    activity_data,
    dbd_custom_srv_data,
    acc_custom_srv_data,
    dbd_addhoc_srv_data,
    trp_addhoc_srv_data,
    trf_addhoc_srv_data,
    acc_addhoc_srv_data,
    booking_message,
    booking_services,
    option_services,
    srvs_uids_for_booking,
    srvs_uids_for_option_booking,
    offer_data,
    detailed_pricing_data,
    trip_instance_id,
    trip_instance_type,
  };

  try {
    const res = await tripBook(book_payload);
    const { id, uid, reference } = res;
    await dispatch({
      type: TRIPPLANNER_BOOKING_DATA_UPDATE,
      servicebundle: { id, uid, reference },
    });
  } catch (error) {
    notifyBookingInitializationFailed();
    return null;
  }
  return true;
};

export const refreshPaymentKPI = () => async (dispatch) => {
  await dispatch(fetchDepositBalance());
  return true;
};
