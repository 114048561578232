import service from "@src/api/request";

export function getCommunicationList(para) {
  return service({
    url: "/bid/email-config/",
    method: "get",
    params: para,
  });
}

export function getCommunicationListOptions() {
  return service({
    url: "/bid/email-config/",
    method: "options",
  });
}

export function postCommunicationData(para) {
  return service({
    url: "/bid/email-config/",
    method: "post",
    data: para,
  });
}

export function getCommunicationEditData(data) {
  return service({
    url: `/bid/email-config/${data}`,
    method: "get",
  });
}

export function submitCommunicationData(id, payload, mode) {
  return service({
    url: `/bid/email-config/${id}/`,
    method: mode,
    data: payload,
  });
}
