import { commonList } from "@src/reducers/common";
import {
  //==================== FILTER RELATION ==========================
  B2B_ADDON_RESERVATION_UPDATE_FILTERS,
} from "@src/actions/Operation/B2BReservations/SingleServices/AddOns/types";

// ============================ LIST VIEW ============================
const B2BAddonsFiltersInitial = {
  market_type: "B2B",
  reference: "",
  external_reference: "",
  source_entity_autocomplete: "",
  source_entity: "",
  title: "",
  destination: "",
  created__gte: "",
  created__lte: "",
  operation_date__gte: "",
  operation_date__lte: "",
  guest_lead__icontains: "",
  external_creator__icontains: "",
  creator_email: "",
  status: "",
  pax: "",
  tags: "",
  page: 1,
  limit: 20,
  ordering: "",
};
export const initialB2BAddonsList = {
  ...commonList,
  filters: { ...B2BAddonsFiltersInitial },
};
export const B2BReservationAddonsList = (
  state = initialB2BAddonsList,
  action
) => {
  switch (action.type) {
    case B2B_ADDON_RESERVATION_UPDATE_FILTERS: {
      const { filters } = action;
      return { ...state, filters: { ...state.filters, ...filters } };
    }
    default:
      return state;
  }
};

// export const initialReservationAddonsFetchStatus = "IDLE";
// export const reservationAddonsFetchStatus = (
//   state = initialReservationAddonsFetchStatus,
//   action
// ) => {
//   switch (action.type) {
//     case B2B_ADDON_RESERVATION_BEGIN_FETCH_DATA:
//       return "FETCHING";
//     case B2B_ADDON_RESERVATION_END_FETCH_DATA:
//       return "FETCHED";
//     default:
//       return state;
//   }
// };
