import React from "react";
import TransTxt from "@src/components/common/SxFormatMessage";
import { Tag } from "rsuite";

export const initialBiddingRequestColumnsForm = {
  basic: {
    value: ["title", "request_by", "pax"],
    choices: [
      {
        verbose: <TransTxt id="tripplanlist_trip_title" upperCase />,
        value: "title",
        key: "title",
        fixed: "left",
        disabled: true,
        path: "itinerary_setup.title",
      },
      {
        verbose: <TransTxt id="tripplanlist_request_by" upperCase />,
        value: "request_by",
        key: "request_by",
        fixed: "left",
      },
      {
        verbose: <TransTxt id="tripplanlist_pax" upperCase />,
        value: "pax",
        key: "pax",
        fixed: "left",
        valuePath: ["itinerary_setup.adults", "itinerary_setup.children"],
        mergeField: true,
      },
    ],
  },
  itinerary: {
    value: [
      "time_period",
      "tripplanlist_origin",
      "destinations",
      "tripplanlist_return",
    ],
    choices: [
      {
        verbose: <TransTxt id="tripplanlist_time_period" upperCase />,
        value: "time_period",
        key: "time_period",
        valuePath: ["return_data.date", "origin_data.date"],
        formatDate: true,
        mergeField: true,
      },
      {
        verbose: <TransTxt id="tripplanlist_origin" upperCase />,
        value: "tripplanlist_origin",
        key: "tripplanlist_origin",
        path: "origin_data.destination",
        changeLang: true,
      },
      {
        verbose: <TransTxt id="tripplanlist_destinations" upperCase />,
        value: "destinations",
        key: "destinations",
        getLength: true,
      },
      {
        verbose: <TransTxt id="tripplanlist_return" startCase />,
        value: "tripplanlist_return",
        key: "tripplanlist_return",
        path: "return_data.destination",
        changeLang: true,
      },
    ],
  },
  services: {
    value: ["service_types"],
    choices: [
      {
        verbose: <TransTxt id="tripplanlist_services_category" startCase />,
        value: "service_types",
        key: "service_types",
        service_types: [
          { key: "Transportation", iata: "FL" },
          { key: "Transfer", iata: "TF" },
          { key: "Accommodation", iata: "ACC" },
        ],
      },
      {
        verbose: <TransTxt id="tripplanlist_insurance" startCase />,
        value: "insurance",
        key: "insurance",
      },
      {
        verbose: <TransTxt id="tripplanlist_travel_visa" startCase />,
        value: "travel_visa",
        key: "travel_visa",
      },
    ],
  },
  pricing: {
    value: ["total", "per_person"],
    choices: [
      {
        verbose: <TransTxt id="tripplanlist_total_price" startCase />,
        value: "total",
        key: "total",
        changPrice: true,
      },
      {
        verbose: <TransTxt id="tripplanlist_per_person_price" startCase />,
        value: "per_person",
        key: "per_person",
        path: "per_person_",
        changPrice: true,
      },
    ],
  },
  stage: {
    value: ["status"],
    choices: [
      {
        verbose: <TransTxt id="status" startCase />,
        value: "status",
        key: "status",
        fixed: "right",
        disabled: true,
      },
      {
        verbose: <TransTxt id="created" startCase />,
        value: "created",
        key: "created",
        formatDate: true,
        path: "created",
      },
      {
        verbose: <TransTxt id="creator" startCase />,
        value: "creator",
        key: "creator",
        path: "creator",
      },
      {
        verbose: <TransTxt id="last_editor" startCase />,
        value: "last_editor",
        key: "last_editor",
        path: "last_editor",
      },
    ],
  },
};

export const initialBiddingRequestFilterForm = {
  status: {
    value: [],
    verbose: <TransTxt id="status" startCase />,
    data: [
      {
        value: "PE",
        label: (
          <Tag color="blue" style={{ width: "100px" }}>
            <strong>
              <TransTxt id="pending" startCase />
            </strong>
          </Tag>
        ),
      },
      {
        value: "SE",
        label: (
          <Tag color="violet" style={{ width: "100px" }}>
            <strong>
              <TransTxt id="sent" startCase />
            </strong>
          </Tag>
        ),
      },
      {
        value: "WO",
        label: (
          <Tag color="orange" style={{ width: "100px" }}>
            <strong>
              <TransTxt id="won" startCase />
            </strong>
          </Tag>
        ),
      },
      {
        value: "LT",
        label: (
          <Tag color="red" style={{ width: "100px" }}>
            <strong>
              <TransTxt id="lost" startCase />
            </strong>
          </Tag>
        ),
      },
    ],
  },
  trip_title: {
    value: "",
    verbose: <TransTxt id="tripplanlist_title" startCase />,
  },
  request_by: {
    value: "",
    verbose: <TransTxt id="tripplanlist_request_by" startCase />,
  },
  adults: {
    value: "",
    verbose: <TransTxt id="adults" startCase />,
  },
  children: {
    value: "",
    verbose: <TransTxt id="children" startCase />,
  },
  destinations: {
    value: [],
    verbose: <TransTxt id="tripplanlist_destinations" startCase />,
  },
  date: {
    value: [],
    verbose: <TransTxt id="date" startCase />,
  },
  recently: {
    value: "0",
    verbose: <TransTxt id="sort_by" startCase />,
    data: [
      {
        value: "0",
        label: <TransTxt id="date_add(newest)" />,
      },
      {
        value: "1",
        label: <TransTxt id="date_add(oldest)" />,
      },
    ],
  },
  pricing: {
    value: "selling",
    verbose: <TransTxt id="pricing" startCase />,
    data: [
      {
        label: <TransTxt id="selling_price" startCase />,
        value: "selling",
      },
      {
        label: <TransTxt id="buying_price" startCase />,
        value: "buying",
      },
    ],
  },
  year: {
    value: null,
    verbose: <TransTxt id="tripplanlist_year" startCase />,
  },
  created_by: {
    value: [],
    verbose: <TransTxt id="created_by" startCase />,
  },
  travellers: {
    value: 0,
    verbose: <TransTxt id="tripplanlist_travellers" startCase />,
    hasContainerBorder: true,
    max: 40,
    isTime: false,
  },
  remaining_time: {
    value: 0,
    verbose: <TransTxt id="tripplanlist_remaining_time" startCase />,
    hasContainerBorder: true,
    max: 200,
    isTime: true,
  },
  pax: {
    value: [1, 10],
    verbose: <TransTxt id="tripplanlist_pax" startCase />,
    hasContainerBorder: true,
  },
  origin: {
    value: "",
    verbose: <TransTxt id="tripplanlist_origin" startCase />,
  },
  return: {
    value: "",
    verbose: <TransTxt id="tripplanlist_return" startCase />,
  },
  travel_services: {
    value: [],
    verbose: <TransTxt id="services_requested" startCase />,
  },
  total: {
    value: "",
    verbose: <TransTxt id="tripplanlist_total" startCase />,
  },
  total_buying_price: {
    value: [1, 10],
    verbose: <TransTxt id="tripplanlist_total_buying_price" startCase />,
  },
  total_selling_price: {
    value: [1, 10],
    verbose: <TransTxt id="tripplanlist_total_selling_price" startCase />,
  },
  per_person: {
    value: "",
    verbose: <TransTxt id="tripplanlist_per_person" startCase />,
  },
  per_person_buying_price: {
    value: [1, 10],
    verbose: <TransTxt id="tripplanlist_per_person_buying_price" startCase />,
  },
  per_person_selling_price: {
    value: [1, 10],
    verbose: <TransTxt id="tripplanlist_per_person_selling_price" startCase />,
  },
  last_editor: {
    value: "",
    verbose: <TransTxt id="last_edited_date" startCase />,
  },
  created: {
    value: [],
    placement: "auto",
    verbose: <TransTxt id="created_date" startCase />,
  },
};

export * from "./columns";
