import OverviewAllFlights from "../Components/OverviewAllFlights";
import OverviewAllAccommodation from "../Components/OverviewAllAccommodation";
import OverviewAllTransfers from "../Components/OverviewAllTransfers";

import OverviewTransportation from "./OverviewTransportation";
import OverviewDestination, {
  srvHeaderStyles,
  srvHeaderTitleActionsStyles,
  srvHeaderTitleStyles,
} from "./OverviewDestination";

import { extremalIsRoundSelector } from "@src/selectors/Project/TripPlanner";

import _ from "lodash";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import DayByDay from "./DayByDay";
import NotifierErrorBoundary from "@src/components/common/NotifierErrorBoundary";
import AddCustomServiceModal from "../Components/Modals/AddCustomServiceModal";
import { JssProvider, createUseStyles } from "react-jss";
import { variables } from "@src/jsssetup";
import AdhocServiceModal from "../Components/Modals/AddAddhocServiceModal";
import AddAdhocTrpServiceModal from "../Components/Modals/AddAddhocTrpServiceModal";
import AddAdhocTrfSrvModal from "../Components/Modals/AddAddhocTrfSrvModal";
import AddAdhocAccSrvModal from "../Components/Modals/AddAddhocAccSrvModal";
import CustomAccSrvModal from "../Components/Modals/CustomAccSrvModal";
import { Formik, Form } from "formik";
import { PricingFormContext } from "../TripPlanner";
import { changeDetailedSrvPricing } from "@src/actions/Project/TripPlanner/pricing";
import { toast } from "react-toastify";
import { EditAdhocAccSrvModal } from "../Components/Modals/products/addhoc/accommodation";
import EditGeneralServiceModal from "../Components/Modals/EditGeneralServiceModal";
import EditRestaurantServiceModal from "../Components/Modals/EditRestaurantSrvModal";
import EditFerrySrvModal from "../Components/Modals/EditFerrySrvModal";
import EditTrainSrvModal from "../Components/Modals/EditTrainSrvModal";
import EditCoachSrvModal from "../Components/Modals/EditCoachSrvModal";
import EditCoordSrvModal from "../Components/Modals/EditCoordSrvModal";
import { getDaysArraySelector } from "@src/selectors/Project/TripPlanner/day_by_day_selectors";
import { OverviewAllFerries } from "../Components/OverviewAllFerries";
import LeanAuthHoc from "@src/components/authorization/LeanAuthHoc";
import { OverviewAllTrains } from "../Components/OverviewAllTrains";
import { OverviewAllCarRentals } from "../Components/OverviewAllCarRentals";

const showFerriesBtnStyles = createUseStyles({
  ShowDrawerBtn: {
    display: "grid",
    placeSelf: "center",
    background: variables.colors.easy.orange,
    color: "white",
    fontSize: "large",
    padding: variables.half_gap,
    borderRadius: variables.half_gap,
    cursor: "pointer",
  },
});
var ShowFerriesBtn = ({ showFerriesDrawer }) => {
  const classes = showFerriesBtnStyles();
  return (
    <div className={classes.ShowDrawerBtn} onClick={showFerriesDrawer}>
      Show Ferries
    </div>
  );
};
ShowFerriesBtn = LeanAuthHoc(ShowFerriesBtn, {
  allowed_groups: ["testingfeature"],
});

var ShowTrainsBtn = ({ showTrainsDrawer }) => {
  const classes = showFerriesBtnStyles();
  return (
    <div className={classes.ShowDrawerBtn} onClick={showTrainsDrawer}>
      Show Trains
    </div>
  );
};
// ShowTrainsBtn = LeanAuthHoc(ShowTrainsBtn, {
//   allowed_groups: ["testingfeature"],
// });

var ShowCarRentalsBtn = ({ showCarRentalsDrawer }) => {
  const classes = showFerriesBtnStyles();
  return (
    <div className={classes.ShowDrawerBtn} onClick={showCarRentalsDrawer}>
      Show Car Rentals
    </div>
  );
};
ShowCarRentalsBtn = LeanAuthHoc(ShowCarRentalsBtn, {
  allowed_groups: ["testingfeature"],
});

const DestinationViewStyles = createUseStyles({
  DestinationView: { display: "grid", gridGap: variables.normal_gap },
});
const DestinationView = ({
  noExtremalReturn,
  destinations,
  requiredServices,
  currentStep,
  return_data,
  onSetAddhocDestDest,
  onSetAddhocTrfService,
  onEditFerryService,
  onEditTrainService,
  onEditCoachService,
}) => {
  const classes = DestinationViewStyles();

  return (
    <div className={classes.DestinationView}>
      {destinations.map((d, idx) => {
        const originOrder = d?.order;
        const destinationOrder = d?.order + 1;

        var lastTrp = false;
        if (noExtremalReturn) {
          lastTrp =
            d.order == destinations.length - 1 &&
            requiredServices.join("") == "TR";
        } else {
          lastTrp = d.order == destinations.length;
        }

        var trpTitle = "Transportation: ";
        var nextDest = null;
        const isLastDest = idx + 1 == destinations.length;
        if (!isLastDest) {
          nextDest = destinations[idx + 1];
          trpTitle += `${d.name_en} to ${nextDest.name_en}`;
        } else {
          trpTitle += `${d.name_en} to ${_.get(
            return_data,
            "destination.name_en"
          )}`;
        }

        var showHeader = true;
        if (isLastDest && _.isEmpty(return_data)) {
          showHeader = false;
        }

        return (
          <React.Fragment key={idx}>
            <OverviewDestination
              currentStep={currentStep}
              destination={d}
              requiredServices={requiredServices}
              onSetAddhocTrfService={onSetAddhocTrfService}
            />
            {requiredServices.includes("TR") && (
              <OverviewTransportation
                showHeader={showHeader}
                headerTitle={trpTitle}
                destOrder={d.order}
                lastTrp={lastTrp}
                currentStep={currentStep}
                originOrder={originOrder}
                destinationOrder={destinationOrder}
                onSetAddhocDestDest={() =>
                  onSetAddhocDestDest({
                    originDest: d,
                    destinationDest: nextDest,
                  })
                }
                onEditFerryService={onEditFerryService}
                onEditTrainService={onEditTrainService}
                onEditCoachService={onEditCoachService}
              />
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};
DestinationView.defaultProps = { return_data: {} };
DestinationView.propTypes = {
  requiredServices: PropTypes.array.isRequired,
  destinations: PropTypes.array.isRequired,
  currentStep: PropTypes.number.isRequired,
  noExtremalReturn: PropTypes.bool.isRequired,
  extremalIsRound: PropTypes.bool.isRequired,
  return_data: PropTypes.object.isRequired,
  onSetAddhocDestDest: PropTypes.func,
  onSetAddhocTrfService: PropTypes.func.isRequired,
  onEditFerryService: PropTypes.func.isRequired,
  onEditTrainService: PropTypes.func.isRequired,
  onEditCoachService: PropTypes.func.isRequired,
};

export const ItinOverviewContext = React.createContext({});

const styles = createUseStyles({
  ItineraryOverview: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} * 2)`,
    overflow: "auto",
    padding: `${variables.normal_gap} calc(${variables.normal_gap} / 2)`,
    gridAutoRows: "max-content",
  },
  serviceHeader: (props) => srvHeaderStyles(props),
  srvHeaderTitle: () => srvHeaderTitleStyles(),
  srvHeaderTitleActions: () => srvHeaderTitleActionsStyles(),
});
const ItineraryOverview = ({
  destinations,
  noExtremalReturn,
  requiredServices,
  extremalIsRound,
  return_data,
}) => {
  const currentStep = useSelector((state) => state.tripPlannerCurrentStep);
  const { view_style } = useSelector((state) => state.tripPlannerOverviewStyle);

  const classes = styles({ currentStep });

  const [showViewAllFerriesDrawer, setShowViewAllFerriesDrawer] =
    useState(false);
  const [viewAllFerriesSessionId, setViewAllFerriesSessionId] = useState(null);

  const [showViewAllTrainsDrawer, setShowViewAllTrainsDrawer] = useState(false);
  const [viewAllTrainsSessionId, setViewAllTrainsSessionId] = useState(null);

  const [showViewAllCarRentalsDrawer, setShowViewAllCarRentalsDrawer] =
    useState(false);

  const [showViewAllFlightsDrawer, setShowViewAllFlightsDrawer] =
    useState(false);
  const [viewAllFlightsSessionId, setViewAllFlightsSessionId] = useState(null);

  const [showViewAllAccsDrawer, setShowViewAllAccsDrawer] = useState(false);
  const [showViewAllAccsSessionId, setShowViewAllAccsSessionId] =
    useState(null);
  const [showViewAllAccsDestOrder, setShowViewAllAccsDestOrder] =
    useState(null);

  const [showViewAllTrfsDrawer, setShowViewAllTrfsDrawer] = useState(false);
  const [viewAllTrfsDOrder, setViewAllTrfsDOrder] = useState(false);
  const [viewAllTrfsType, setViewAllTrfsType] = useState(false);

  const [showAdhocSrvModal, setAdhocSrvModal] = useState(false);
  const [editingAdhoc, setEditingAdhoc] = useState(null);

  const [showAddAdhocTrpSrvModal, setAddAdhocTrpSrvModal] = useState(false);
  const [showAddCustomSrvModal, setAddCustomSrvModal] = useState(false);
  const [showEditCusSrvModal, setShowEditCusSrvModal] = useState(false);
  const [showEditRestSrvModal, setShowEditRestSrvModal] = useState(false);
  const [showEditFerrySrvModal, setShowEditFerrySrvModal] = useState(false);
  const [showEditTrainSrvModal, setShowEditTrainSrvModal] = useState(false);
  const [showEditCoachSrvModal, setShowEditCoachSrvModal] = useState(false);
  const [showEditCoordSrvModal, setShowEditCoordSrvModal] = useState(false);

  const [showCustomAccSrvModal, setShowCustomAccSrvModal] = useState(false);
  const [showAddAdhocAccSrvModal, setShowAddAdhocAccSrvModal] = useState(false);
  const [editingAdhocAcc, setEditingAdhocAcc] = useState(null);
  const [showEditAdhocAccModal, setShowEditAdhocAccModal] = useState(false);
  const [adhocAccDestination, setAdhocAccDestination] = useState(null);

  const [showAddAdhocTrfSrvModal, setShowAddAdhocTrfSrvModal] = useState(false);
  const [adhocTrfType, setAdhocTrfType] = useState(null);
  const [adhocTrfHotel, setAdhocTrfHotel] = useState(null);
  const [adhocTrfDestr, setAdhocTrfDest] = useState(null);

  const [adhocOriginDest, setAdhocOriginDest] = useState(null);
  const [adhocDestinationDest, setAdhocDestDest] = useState(null);

  const [customServiceDate, setCustomServiceDate] = useState(null);
  const [customServiceDayIdx, setCustomServiceDayIdx] = useState(null);
  const [customServiceDests, setCustomServiceDests] = useState(null);
  const [customServiceId, setCustomServiceId] = useState(null);
  const [commonDestDays, setCommonDestDays] = useState([]);

  const [restaurantSrvId, setRestaurantSrvId] = useState(null);
  const [restaurantSrvDayIdx, setRestaurantSrvDayIdx] = useState(null);

  const [coordSrvDayIdx, setCoordSrvDayIdx] = useState(null);
  const [coordSrvId, setCoordSrvId] = useState(null);

  const [ferrySrvId, setFerrySrvId] = useState(null);
  const [trainSrvId, setTrainSrvId] = useState(null);
  const [coachSrvId, setCoachSrvId] = useState(null);

  return (
    <ItinOverviewContext.Provider
      value={{
        onSetCustomAccSrv: ({ destination }) => {
          setAdhocAccDestination(destination);
          setShowCustomAccSrvModal(true);
        },
        onSetAddhocAccSrv: ({ destination }) => {
          setAdhocAccDestination(destination);
          setShowAddAdhocAccSrvModal(true);
        },
        onEditAddhocAccSrv: ({ uid }) => {
          setEditingAdhocAcc(uid);
          setShowEditAdhocAccModal(true);
        },
        onViewAllTrfs: ({ destOrder, trfType }) => {
          setViewAllTrfsType(trfType);
          setViewAllTrfsDOrder(destOrder);
          setShowViewAllTrfsDrawer(true);
        },
        onShowViewAllAccs: ({ session_id, destOrder, show = true }) => {
          setShowViewAllAccsDestOrder(destOrder);
          setShowViewAllAccsSessionId(session_id);
          setShowViewAllAccsDrawer(show);
        },
        onViewAllFerries: ({ session_id }) => {
          setViewAllFerriesSessionId(session_id);
          setShowViewAllFerriesDrawer(true);
        },
        onViewAllFlights: ({ session_id }) => {
          setViewAllFlightsSessionId(session_id);
          setShowViewAllFlightsDrawer(true);
        },
        onViewAllTrains: ({ session_id }) => {
          setViewAllTrainsSessionId(session_id);
          setShowViewAllTrainsDrawer(true);
        },
      }}>
      <div className={classes.ItineraryOverview}>
        {/* <div style={{ display: "grid", gridAutoFlow: "column" }}> */}
        {/*   <ShowFerriesBtn */}
        {/*     showFerriesDrawer={() => setShowViewAllFerriesDrawer(true)} */}
        {/*   /> */}
        {/*   <ShowTrainsBtn */}
        {/*     showTrainsDrawer={() => setShowViewAllTrainsDrawer(true)} */}
        {/*   /> */}
        {/*   <ShowCarRentalsBtn */}
        {/*     showCarRentalsDrawer={() => setShowViewAllCarRentalsDrawer(true)} */}
        {/*   /> */}
        {/* </div> */}
        {requiredServices.includes("TR") && view_style === "overview" && (
          <OverviewTransportation
            showHeader={true}
            headerTitle="Departure"
            extremal={true}
            currentStep={currentStep}
            lastTrp={false}
            originOrder={0}
            destinationOrder={1}
            onSetAddhocDestDest={() => {
              setAdhocDestDest(destinations[0]);
              setAddAdhocTrpSrvModal(true);
            }}
            onEditFerryService={({ srv_id }) => {
              setFerrySrvId(srv_id);
              setShowEditFerrySrvModal(true);
            }}
            onEditTrainService={({ srv_id }) => {
              setTrainSrvId(srv_id);
              setShowEditTrainSrvModal(true);
            }}
            onEditCoachService={({ srv_id }) => {
              setCoachSrvId(srv_id);
              setShowEditCoachSrvModal(true);
            }}
          />
        )}
        {view_style === "overview" ? (
          <DestinationView
            requiredServices={requiredServices}
            destinations={destinations}
            currentStep={currentStep}
            extremalIsRound={extremalIsRound}
            noExtremalReturn={noExtremalReturn}
            return_data={return_data}
            onSetAddhocDestDest={({ originDest, destinationDest }) => {
              setAdhocOriginDest(originDest);
              setAdhocDestDest(destinationDest);
              setAddAdhocTrpSrvModal(true);
            }}
            onSetAddhocTrfService={({
              trfType,
              accommodation,
              destination,
            }) => {
              setAdhocTrfType(trfType);
              setAdhocTrfHotel(accommodation);
              setAdhocTrfDest(destination);
              setShowAddAdhocTrfSrvModal(true);
            }}
            onEditFerryService={({ srv_id }) => {
              setFerrySrvId(srv_id);
              setShowEditFerrySrvModal(true);
            }}
            onEditTrainService={({ srv_id }) => {
              setTrainSrvId(srv_id);
              setShowEditTrainSrvModal(true);
            }}
            onEditCoachService={({ srv_id }) => {
              setCoachSrvId(srv_id);
              setShowEditCoachSrvModal(true);
            }}
          />
        ) : (
          <DayByDay
            onAddCustomService={({
              date,
              dayIdx,
              destination_ids,
              commonDestDays,
            }) => {
              setCustomServiceDayIdx(dayIdx);
              setCustomServiceDate(date);
              setCustomServiceDests(destination_ids);
              setAddCustomSrvModal(true);
              setCommonDestDays(commonDestDays);
            }}
            onEditCustomService={({ dayIdx, srv_id }) => {
              setCustomServiceDayIdx(dayIdx);
              setCustomServiceId(srv_id);
              setShowEditCusSrvModal(true);
            }}
            onAddAddhocService={({
              date,
              dayIdx,
              destination_ids,
              commonDestDays,
            }) => {
              setCustomServiceDayIdx(dayIdx);
              setCustomServiceDate(date);
              setCustomServiceDests(destination_ids);
              setAdhocSrvModal(true);
              setCommonDestDays(commonDestDays);
            }}
            onEditAdhocService={({ srv_id }) => {
              setEditingAdhoc(srv_id);
              setAdhocSrvModal(true);
            }}
            onEditRestService={({ dayIdx, srv_id }) => {
              setRestaurantSrvDayIdx(dayIdx);
              setRestaurantSrvId(srv_id);
              setShowEditRestSrvModal(true);
            }}
            onEditCoordService={({ dayIdx, srv_id }) => {
              setCoordSrvDayIdx(dayIdx);
              setCoordSrvId(srv_id);
              setShowEditCoordSrvModal(true);
            }}
          />
        )}
        {/* requiredServices.includes("TR") && view_style === "overview" && ( */
        /*   <OverviewTransportation */
        /*     extremal={true} */
        /*     returnExtremal={true} */
        /*     currentStep={currentStep} */
        /*     lastTrp={true} */
        /*     originOrder={_.last(destinations).order} */
        /*     destinationOrder={_.last(destinations).order + 1} */
        /*     onEditFerryService={({ srv_id }) => { */
        /*       setFerrySrvId(srv_id); */
        /*       setShowEditFerrySrvModal(true); */
        /*     }} */
        /*     onEditTrainService={({ srv_id }) => { */
        /*       setTrainSrvId(srv_id); */
        /*       setShowEditTrainSrvModal(true); */
        /*     }} */
        /*     onEditCoachService={({ srv_id }) => { */
        /*       setCoachSrvId(srv_id); */
        /*       setShowEditCoachSrvModal(true); */
        /*     }} */
        /*   /> */
        /* ) */}
        {showViewAllFlightsDrawer && (
          <NotifierErrorBoundary>
            <OverviewAllFlights
              session_id={viewAllFlightsSessionId}
              onClose={() => setShowViewAllFlightsDrawer(false)}
            />
          </NotifierErrorBoundary>
        )}
        {showViewAllAccsDrawer && (
          <JssProvider classNamePrefix="drawer-">
            <NotifierErrorBoundary>
              <OverviewAllAccommodation
                session_id={showViewAllAccsSessionId}
                destOrder={showViewAllAccsDestOrder}
                onClose={() => {
                  setShowViewAllAccsDrawer(false);
                  setShowViewAllAccsDestOrder(null);
                  setShowViewAllAccsSessionId(null);
                }}
              />
            </NotifierErrorBoundary>
          </JssProvider>
        )}
        {showViewAllTrfsDrawer && (
          <NotifierErrorBoundary>
            <OverviewAllTransfers
              destOrder={viewAllTrfsDOrder}
              transfer_type={viewAllTrfsType}
              onClose={() => setShowViewAllTrfsDrawer(false)}
            />
          </NotifierErrorBoundary>
        )}
        {showViewAllFerriesDrawer && (
          <NotifierErrorBoundary>
            <OverviewAllFerries
              sessionId={viewAllFerriesSessionId}
              onClose={() => setShowViewAllFerriesDrawer(false)}
            />
          </NotifierErrorBoundary>
        )}
        {showViewAllTrainsDrawer && (
          <NotifierErrorBoundary>
            <OverviewAllTrains
              sessionId={viewAllTrainsSessionId}
              onClose={() => setShowViewAllTrainsDrawer(false)}
            />
          </NotifierErrorBoundary>
        )}
        {showViewAllCarRentalsDrawer && (
          <NotifierErrorBoundary>
            <OverviewAllCarRentals
              closeCarRentalsDrawer={() =>
                setShowViewAllCarRentalsDrawer(false)
              }
            />
          </NotifierErrorBoundary>
        )}
        {showAddCustomSrvModal && (
          <AddCustomServiceModal
            destination_ids={customServiceDests}
            date={customServiceDate}
            dayIdx={customServiceDayIdx}
            commonDestDays={commonDestDays}
            onCancel={() => setAddCustomSrvModal(false)}
          />
        )}
        {showAdhocSrvModal && (
          <AdhocServiceModal
            destination_id={customServiceDests}
            date={customServiceDate}
            dayIdx={customServiceDayIdx}
            commonDestDays={commonDestDays}
            editingAdhoc={editingAdhoc}
            onCancel={() => {
              setAdhocSrvModal(false);
              setEditingAdhoc(null);
            }}
          />
        )}
        {showAddAdhocTrpSrvModal && (
          <AddAdhocTrpServiceModal
            origin_dest={adhocOriginDest}
            destination_dest={adhocDestinationDest}
            onCancel={() => setAddAdhocTrpSrvModal(false)}
          />
        )}
        {showAddAdhocTrfSrvModal && (
          <AddAdhocTrfSrvModal
            trfType={adhocTrfType}
            hotel={adhocTrfHotel}
            destination={adhocTrfDestr}
            onCancel={() => setShowAddAdhocTrfSrvModal(false)}
          />
        )}
        {showAddAdhocAccSrvModal && (
          <AddAdhocAccSrvModal
            destination={adhocAccDestination}
            onCancel={() => setShowAddAdhocAccSrvModal(false)}
          />
        )}
        {showEditAdhocAccModal && (
          <EditAdhocAccSrvModal
            uid={editingAdhocAcc}
            onCancel={() => setShowEditAdhocAccModal(false)}
          />
        )}
        {showCustomAccSrvModal && (
          <CustomAccSrvModal
            destination={adhocAccDestination}
            onCancel={() => setShowCustomAccSrvModal(false)}
          />
        )}
        {showEditCusSrvModal && (
          <EditGeneralServiceModal
            srv_id={customServiceId}
            dayIdx={customServiceDayIdx}
            onCancel={() => setShowEditCusSrvModal(false)}
          />
        )}
        {showEditRestSrvModal && (
          <EditRestaurantServiceModal
            srv_id={restaurantSrvId}
            dayIdx={restaurantSrvDayIdx}
            onCancel={() => setShowEditRestSrvModal(false)}
          />
        )}
        {showEditFerrySrvModal && (
          <EditFerrySrvModal
            srv_id={ferrySrvId}
            onCancel={() => setShowEditFerrySrvModal(false)}
          />
        )}
        {showEditTrainSrvModal && (
          <EditTrainSrvModal
            srv_id={trainSrvId}
            onCancel={() => setShowEditTrainSrvModal(false)}
          />
        )}
        {showEditCoachSrvModal && (
          <EditCoachSrvModal
            srv_id={coachSrvId}
            onCancel={() => setShowEditCoachSrvModal(false)}
          />
        )}
        {showEditCoordSrvModal && (
          <EditCoordSrvModal
            srv_id={coordSrvId}
            onCancel={() => setShowEditCoordSrvModal(false)}
          />
        )}
      </div>
    </ItinOverviewContext.Provider>
  );
};
ItineraryOverview.defaultProps = { noExtremalReturn: true, return_data: {} };
ItineraryOverview.propTypes = {
  requiredServices: PropTypes.array.isRequired,
  destinations: PropTypes.array.isRequired,
  extremalIsRound: PropTypes.bool.isRequired,
  noExtremalReturn: PropTypes.bool.isRequired,
  return_data: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => {
  return {
    requiredServices: state.tripPlannerItinerarySetupForm.requiredServices,
    destinations: state.tripPlannerDestinations,
    extremalIsRound: extremalIsRoundSelector(state),
    noExtremalReturn: _.isEmpty(state.tripPlannerReturnData),
    return_data: state.tripPlannerReturnData,
  };
};

const formStyles = createUseStyles({ form: { overflow: "auto" } });
const withPricingForm = (WrappedComponent) => {
  const Comp = (props) => {
    const { pricing, onPricingSave } = props;
    const [newPricing, setNewPricing] = useState(null);
    const { showPricingForm } = useContext(PricingFormContext);

    useEffect(() => {
      if (showPricingForm && newPricing) {
        onPricingSave({ pricing: newPricing });
        toast.success("Successfully applied markup", { duration: 3000 });
      }
    }, [newPricing]);

    const classes = formStyles();
    return showPricingForm ? (
      <Formik
        initialValues={{ pricing }}
        onSubmit={(values) => setNewPricing(values.pricing)}>
        <Form className={classes.form}>
          <WrappedComponent {...props} />
        </Form>
      </Formik>
    ) : (
      <WrappedComponent {...props} />
    );
  };
  Comp.propTypes = {
    pricing: PropTypes.object.isRequired,
    onPricingSave: PropTypes.func.isRequired,
  };
  const mapStateToProps = (state) => {
    return {
      pricing: _.get(state, "tripPlannerDetailedSrvPricing.pricing", {}),
    };
  };
  const mapDispatchToProps = (dispatch) => {
    return {
      onPricingSave: ({ pricing }) =>
        dispatch(changeDetailedSrvPricing({ pricing })),
    };
  };

  return connect(mapStateToProps, mapDispatchToProps)(Comp);
};
export default connect(
  mapStateToProps,
  null
)(withPricingForm(ItineraryOverview));
