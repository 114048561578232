import _ from "lodash";
import { NormalInputField } from "@src/components/forms";
import { getFormikProps } from "@src/components/forms/helpers";
import { cardStyles, tableStyles, variables } from "@src/jsssetup";
import { Form, Formik } from "formik";
import React, { useCallback, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { Button } from "rsuite";
import { useDispatch, useSelector } from "react-redux";
import { changeAddonRooms } from "@src/actions/MyLibrary/Addons";
import { v4 } from "uuid";

const ROOM_TEMPLATE = {
  name: "",
  type: "",
  min_adults: 1,
  max_adults: 2,
  min_children: 0,
  max_children: 0,
};

const roomsTableStyles = createUseStyles({
  ...cardStyles,
  RoomsTable: cardStyles.card,
  table: tableStyles.table,
  thead: tableStyles.head,
  cell: {
    ...tableStyles.cell,
    "textAlign": "center",
    "& input": { width: "7rem" },
    "& .inputGroup": { justifyItems: "center" },
  },
  actionCell: {
    ...tableStyles.cell,
    "& > button": {
      marginRight: variables.half_gap,
    },
  },
  [`@media ${variables.media.bigscreen}`]: {
    cell: {
      "& input": { width: "12rem" },
    },
  },
});
const RoomsTable = () => {
  const [editMode, setEditMode] = useState(false);
  const formRef = useRef(null);
  const dispatch = useDispatch();

  const rooms = useSelector((state) =>
    _.get(state, "customServiceAddOn.rooms", [])
  );

  const classes = roomsTableStyles();

  const onChangeRooms = useCallback(({ rooms }) =>
    dispatch(changeAddonRooms({ rooms }))
  );

  function onAddRoom() {
    const { values, setFieldValue } = getFormikProps(formRef);
    setFieldValue("rooms", [...values.rooms, { ...ROOM_TEMPLATE, uid: v4() }]);
  }

  function onSubmitRooms() {
    const {
      values: { rooms },
    } = getFormikProps(formRef);

    onChangeRooms({ rooms });
    setEditMode(false);
  }

  const inputGroupStyles = {
    inputGroup: { justifyItems: "center" },
  };

  return (
    <div className={classes.RoomsTable}>
      <div className={classes.header}>
        <h5>Room Types Table</h5>
      </div>
      <div className={classes.body}>
        {editMode ? (
          <Formik initialValues={{ rooms }}>
            {({ values, setFieldValue }) => (
              <Form ref={formRef} setFieldValue={setFieldValue} values={values}>
                <table className={classes.table}>
                  <thead className={classes.thead}>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Type</th>
                      <th>Bedding</th>
                      <th>Min Adults</th>
                      <th>Max Adults</th>
                      <th>Min Children</th>
                      <th>Max Children</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {values.rooms.map((room, idx) => (
                      <tr key={idx}>
                        <td className={classes.cell}>{idx + 1}</td>
                        <td className={classes.cell}>
                          <NormalInputField
                            name={`rooms.${idx}.name`}
                            customStyles={inputGroupStyles}
                          />
                        </td>
                        <td className={classes.cell}>
                          <NormalInputField
                            name={`rooms.${idx}.type`}
                            customStyles={inputGroupStyles}
                          />
                        </td>
                        <td className={classes.cell}>
                          <NormalInputField
                            name={`rooms.${idx}.bedding`}
                            customStyles={inputGroupStyles}
                          />
                        </td>
                        <td className={classes.cell}>
                          <NormalInputField
                            name={`rooms.${idx}.min_adults`}
                            customStyles={inputGroupStyles}
                            type="number"
                            extraInputProps={{ min: 1 }}
                          />
                        </td>
                        <td className={classes.cell}>
                          <NormalInputField
                            name={`rooms.${idx}.max_adults`}
                            customStyles={inputGroupStyles}
                            extraInputProps={{ min: 1 }}
                            type="number"
                          />
                        </td>
                        <td className={classes.cell}>
                          <NormalInputField
                            name={`rooms.${idx}.min_children`}
                            customStyles={inputGroupStyles}
                            type="number"
                            extraInputProps={{ min: 0 }}
                          />
                        </td>
                        <td className={classes.cell}>
                          <NormalInputField
                            name={`rooms.${idx}.max_children`}
                            customStyles={inputGroupStyles}
                            extraInputProps={{ min: 0 }}
                            type="number"
                          />
                        </td>
                        <td className={classes.actionCell}>
                          <Button
                            size="xs"
                            color="red"
                            onClick={() => {
                              const tmpRooms = _.cloneDeep(values.rooms);
                              tmpRooms.splice(idx, 1);
                              setFieldValue("rooms", tmpRooms);
                            }}>
                            <strong>Remove</strong>
                          </Button>
                          <Button
                            size="xs"
                            color="green"
                            onClick={() => {
                              const tmpRoom = _.cloneDeep(_.last(values.rooms));
                              tmpRoom["name"] = `${tmpRoom.name} (cloned)`;
                              setFieldValue("rooms", [
                                ...values.rooms,
                                { ...tmpRoom, uid: v4() },
                              ]);
                            }}>
                            <strong>Copy to new</strong>
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Form>
            )}
          </Formik>
        ) : (
          <table className={classes.table}>
            <thead className={classes.thead}>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Type</th>
                <th>Bedding</th>
                <th>Min Adults</th>
                <th>Max Adults</th>
                <th>Min Children</th>
                <th>Max Children</th>
              </tr>
            </thead>
            <tbody>
              {rooms.map((room, idx) => (
                <tr key={idx}>
                  <td className={classes.cell}>{idx + 1}</td>
                  <td className={classes.cell}>{room.name}</td>
                  <td className={classes.cell}>{room.type}</td>
                  <td className={classes.cell}>{room.bedding}</td>
                  <td className={classes.cell}>{room.min_adults}</td>
                  <td className={classes.cell}>{room.max_adults}</td>
                  <td className={classes.cell}>{room.min_children}</td>
                  <td className={classes.cell}>{room.max_children}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <div className={classes.actions}>
        {!editMode && (
          <Button appearance="primary" onClick={() => setEditMode(true)}>
            <strong>Edit</strong>
          </Button>
        )}
        {editMode && (
          <React.Fragment>
            <Button appearance="ghost" onClick={() => setEditMode(false)}>
              <strong>Cancel</strong>
            </Button>
            <Button appearance="primary" onClick={onSubmitRooms}>
              <strong>Submit</strong>
            </Button>
            <Button color="green" onClick={onAddRoom}>
              <strong>Add Room</strong>
            </Button>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};
export default RoomsTable;
