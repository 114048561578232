import _ from "lodash";
import { Modal, Panel, Icon, IconButton } from "rsuite";

import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { hideGenServiceModal } from "@src/actions/MyLibrary/GeneralServices";
import ServiceForm from "./ServiceForm";
import TransTxt from "@src/components/common/SxFormatMessage";
import { injectIntl } from "react-intl";

var ServiceModal = ({ mode, show, intl, onHide }) => {
  const modeHeaderMapping = {
    edit: intl.formatMessage({
      id: "MyLibrary_gen_serviceModal__edit_service",
    }),
    add: intl.formatMessage({ id: "MyLibrary_gen_serviceModal__add_service" }),
  };

  return !show ? null : (
    <Modal className="CustomModal" show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>
          <TransTxt id={_.get(modeHeaderMapping, mode)} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Panel
          className="CustomPanel"
          header={
            <h5>
              <TransTxt id="MyLibrary_gen_serviceModal__basic_info_english" />
            </h5>
          }
          shaded>
          <ServiceForm />
        </Panel>
      </Modal.Body>
      <Modal.Footer>
        <IconButton icon={<Icon icon="save" />} appearance="primary">
          <TransTxt id="MyLibrary_gen_serviceModal__save" />
        </IconButton>
        <IconButton
          id="close_add_service"
          icon={<Icon icon="close" />}
          onClick={onHide}>
          <TransTxt id="MyLibrary_gen_serviceModal__cancel" />
        </IconButton>
      </Modal.Footer>
    </Modal>
  );
};

ServiceModal.defaultProps = {
  show: false,
  loading: false,
  mode: "add",
};

ServiceModal.propTypes = {
  show: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
  mode: PropTypes.oneOf(["edit", "add"]).isRequired,
  onHide: PropTypes.func.isRequired,
};
ServiceModal = injectIntl(ServiceModal);

const mapStateToProps = (state) => {
  const { show, loading } = state.GeneralServiceModal;

  return {
    show,
    loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onHide: () => dispatch(hideGenServiceModal()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceModal);
