import { Tag, Placeholder } from "rsuite";

import {
  WhisperWrapper,
  WhisperHelper,
} from "@src/components/common/ui_helpers";

import { formatDateTime } from "@src/tools/date_tools";

// ============================ SELECTORS ============================
import {
  isAccRefundableSelector,
  isTransferRefundableSelector,
  getSelectedAccCxl,
} from "@src/selectors/Project/TripPlanner";

import _ from "lodash";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import React, { memo, useCallback, useContext } from "react";
import { isAddonRefundableSelector } from "@src/selectors/Project/TripPlanner/addon_selectors";
import { createUseStyles } from "react-jss";
import { Form, Formik } from "formik";
import { NormalInputField } from "@src/components/forms";
import { variables } from "@src/jsssetup";
import {
  srvOptionBookingSelector,
  srvRegularBookingSelector,
} from "@src/selectors/Project/TripPlanner/booking_service_select";
import {
  addSrvsForOptionBooking,
  addSrvsForRegularBooking,
  removeSrvsForOptionBooking,
  removeSrvsForRegularBooking,
} from "@src/actions/Project/TripPlanner/step_four/service_select";
import { servicePanelContentStyles } from "./OverviewFlight";
import { TripPlanContext } from "../TripPlanner";
import { handleNonValidImgFn } from "@src/tools/common_tools";
import LoadingBar from "react-top-loading-bar";

const { Paragraph } = Placeholder;

const servicePanelGhostStyles = createUseStyles({
  ServicePanelGhost: (props) => ({
    display: "grid",
    gridTemplateColumns: props.services_collapsed ? "1fr" : "8rem 1fr",
    gridGap: variables.normal_gap,
    maxHeight: "10rem",
  }),
  content: {
    ...servicePanelContentStyles,
    display: "grid",
    alignContent: "baseline",
    gridGap: variables.normal_gap,
    padding: variables.normal_gap,
    boxShadow: variables.shadows.heavyShadow,
    borderRadius: `calc(${variables.normal_gap} / 2)`,
    backgroundColor: variables.colors.lightGrey,
  },
  header: {
    display: "grid",
    gridTemplateColumns: "1fr auto",
    alignItems: "center",
    padding: [
      variables.half_gap,
      variables.half_gap,
      variables.half_gap,
      variables.double_gap,
    ],
    borderRadius: `calc(${variables.normal_gap} / 2)`,
    backgroundColor: variables.colors.lightGrey,
    boxShadow: variables.shadows.normalShadow,
  },
  img: {
    width: "100%",
    height: "100%",
    borderRadius: "5px",
    objectFit: "cover",
    boxShadow: variables.shadows.heavyShadow,
  },
  titleCntr: {
    display: "grid",
    gridTemplateColumns: "1fr auto",
    alignItems: "center",
    position: "relative",
  },
  title: {
    fontWeight: "bold",
    fontSize: "medium",
  },
  headerActions: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    gridAutoFlow: "column",
  },
});
export const ServicePanelGhost = ({
  title,
  failed,
  actions,
  failHolder,
  searchProgress,
  setProgress,
  service_type,
}) => {
  const { services_collapsed } = useContext(TripPlanContext);
  const classes = servicePanelGhostStyles({ services_collapsed });

  const ghostImg =
    service_type === "ACC"
      ? "https://sisistaticassets.s3.ap-southeast-1.amazonaws.com/assets/failed_accommodation_item.png"
      : service_type === "FL"
      ? "https://sisistaticassets.s3.ap-southeast-1.amazonaws.com/assets/flight_ghost.png"
      : service_type === "FER"
      ? "https://sisistaticassets.s3.ap-southeast-1.amazonaws.com/assets/services/ferries.svg"
      : service_type === "TRA"
      ? "https://sisistaticassets.s3.ap-southeast-1.amazonaws.com/assets/services/train.svg"
      : service_type === "ACT"
      ? "https://sisistaticassets.s3.ap-southeast-1.amazonaws.com/assets/services/activities.svg"
      : service_type === "ADD"
      ? "https://sisistaticassets.s3.ap-southeast-1.amazonaws.com/assets/services/add_on.svg"
      : service_type === "COA"
      ? "https://sisistaticassets.s3.ap-southeast-1.amazonaws.com/assets/services/coach.svg"
      : service_type === "TRF"
      ? "https://sisistaticassets.s3.ap-southeast-1.amazonaws.com/assets/transfer_ghost.png"
      : "";

  const loadingBar = (
    <LoadingBar
      color={variables.colors.easy.orange}
      progress={searchProgress}
      containerStyle={{
        position: "absolute",
        height: "3px",
        top: "100%",
        zIndex: 2,
      }}
      style={{ borderRadius: "10px" }}
      onLoaderFinished={setProgress}
      shadow={false}
    />
  );

  const generateTitle = (services_collapsed) => (
    <div className={services_collapsed ? classes.header : classes.titleCntr}>
      <span className={classes.title}>{title}</span>
      <div className={classes.headerActions}>
        {actions.length > 0 &&
          actions.map((action, idx) => (
            <button
              className="Button"
              key={idx}
              data-size="xs"
              onClick={action.onClick}>
              {action.title}
            </button>
          ))}
      </div>
      {!services_collapsed && loadingBar}
    </div>
  );

  return (
    <div className={classes.ServicePanelGhost}>
      {services_collapsed && generateTitle(services_collapsed)}
      {!services_collapsed && (
        <React.Fragment>
          <img
            className={classes.img}
            src={ghostImg}
            alt=""
            onError={(e) => handleNonValidImgFn(e, service_type)}
          />
          <div className={classes.content}>
            {generateTitle(services_collapsed)}
            <div>
              {failed ? (
                <div>{failHolder}</div>
              ) : (
                <div>
                  <Paragraph rows={2} />
                  <Paragraph rows={2} />
                </div>
              )}
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
ServicePanelGhost.defaultProps = {
  actions: [],
  failed: false,
  searchProgress: 0,
  service_type: "",
};
ServicePanelGhost.propTypes = {
  title: PropTypes.string.isRequired,
  failed: PropTypes.bool,
  failHolder: PropTypes.object,
  searchProgress: PropTypes.number,
  actions: PropTypes.array,
  setProgress: PropTypes.func,
  service_type: PropTypes.string.isRequired,
};

const bookabilityStyles = createUseStyles({
  Bookability: {
    display: "grid",
    gridTemplateColumns: "1fr auto",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    alignItems: "center",
  },
});
export function Bookability(props) {
  const { srv, srvType, prebookData, destOrder } = props;

  var isRefundable = false;
  var cxl_policies = [];

  if (srv) {
    switch (srvType) {
      case "ACC": {
        isRefundable = useSelector((state) =>
          isAccRefundableSelector(state, {
            accommodation: srv,
            prebookData,
          })
        );
        cxl_policies = useSelector((state) =>
          getSelectedAccCxl(state, { destOrder, prebookData })
        );
        break;
      }
      case "TRF": {
        isRefundable = useSelector((state) =>
          isTransferRefundableSelector(state, { transfer: srv })
        );
        cxl_policies = srv.cancellation_policies;
        break;
      }
      case "MI": {
        isRefundable = useSelector((state) =>
          isAddonRefundableSelector(state, { addOn: srv })
        );
        cxl_policies = srv.cancellation;
        break;
      }
      default: {
        break;
      }
    }
  }

  function lastFreeCxlDate() {
    const policy = cxl_policies
      .filter((p) => p.type == "refundable")
      .sort((a, b) => moment(a.to).isBefore(moment(b.to)))[0];

    return formatDateTime(policy.to, undefined, { toUTC: true });
  }
  const classes = bookabilityStyles();
  return (
    <div className={classes.Bookability}>
      {isRefundable && (
        <React.Fragment>
          <Tag color="orange">
            <strong>{lastFreeCxlDate()}</strong>
          </Tag>{" "}
          <WhisperHelper msg="Last free cancellation date." />
        </React.Fragment>
      )}
    </div>
  );
}
Bookability.propTypes = {
  srv: PropTypes.object,
  srvType: PropTypes.oneOf(["FL", "ACC", "TRF", "MI"]).isRequired,
  prebookData: PropTypes.object,
  destOrder: PropTypes.number.isRequired,
};

const checkBoxStyles = {
  input: {
    height: "1.5rem",
    width: "1.5rem",
  },
};

const bookCheckerStyles = createUseStyles({
  BookChecker: { display: "grid", alignContent: "center" },
  form: { display: "grid", gridGap: `calc(${variables.normal_gap} / 2)` },
  normalCheckboxWrapper: {
    width: "min-content",
    border: `2px solid ${variables.colors.deepblue}`,
  },
  optionCheckboxWrapper: {
    width: "min-content",
    border: `2px solid ${variables.colors.orange}`,
  },
});
var BookChecker = ({ uid, srv_type, optionable, disabled }) => {
  const classes = bookCheckerStyles();
  const isRegularlySelected = useSelector((state) =>
    srvRegularBookingSelector(state, { uid, srv_type })
  );
  const isOptionSelected = useSelector((state) =>
    srvOptionBookingSelector(state, { uid, srv_type })
  );

  const dispatch = useDispatch();
  const onRegularAdd = useCallback(
    (srv) => dispatch(addSrvsForRegularBooking({ srvs: [srv] })),
    [dispatch]
  );
  const onRegularRemove = useCallback(
    (srv) => dispatch(removeSrvsForRegularBooking({ srvs: [srv] })),
    [dispatch]
  );

  const onOptionAdd = useCallback(
    (srv) => dispatch(addSrvsForOptionBooking({ srvs: [srv] })),
    [dispatch]
  );

  const onOptionRemove = useCallback(
    (srv) => dispatch(removeSrvsForOptionBooking({ srvs: [srv] })),
    [dispatch]
  );

  return (
    <div className={classes.BookChecker}>
      <Formik
        enableReinitialize={true}
        validateOnBlur={false}
        validateOnMount={false}
        initialValues={{
          bookable: isRegularlySelected,
          optionBookable: isOptionSelected,
        }}
        validate={(values) => {
          if (values.optionBookable && isRegularlySelected) {
            onRegularRemove({ uid, srv_type });
            onOptionAdd({ uid, srv_type });
            return;
          }

          if (!values.bookable && isRegularlySelected) {
            onRegularRemove({ uid, srv_type });
            return;
          }

          if (values.bookable && isOptionSelected) {
            onOptionRemove({ uid, srv_type });
            onRegularAdd({ uid, srv_type });
            return;
          }

          if (!values.optionBookable && isOptionSelected) {
            onOptionRemove({ uid, srv_type });
            return;
          }

          if (values.bookable) {
            onRegularAdd({ uid, srv_type });
            return;
          }

          if (values.optionBookable) {
            onOptionAdd({ uid, srv_type });
          }
        }}>
        <Form className={classes.form}>
          <WhisperWrapper msg="Normal Booking" placement="right">
            <div className={classes.normalCheckboxWrapper}>
              <NormalInputField
                customStyles={checkBoxStyles}
                type="checkbox"
                name="bookable"
                extraInputProps={{ disabled }}
              />
            </div>
          </WhisperWrapper>
          {optionable && (
            <WhisperWrapper msg="Option Booking" placement="right">
              <div className={classes.optionCheckboxWrapper}>
                <NormalInputField
                  customStyles={checkBoxStyles}
                  type="checkbox"
                  name="optionBookable"
                  extraInputProps={{ disabled }}
                />
              </div>
            </WhisperWrapper>
          )}
        </Form>
      </Formik>
    </div>
  );
};
BookChecker.defaultProps = {
  disabled: false,
  optionable: false,
};
BookChecker.propTypes = {
  disabled: PropTypes.bool,
  uid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  srv_type: PropTypes.string.isRequired,
  optionable: PropTypes.bool,
};
BookChecker = memo(BookChecker);
export { BookChecker };

const regularBookTypeCheckerStyles = createUseStyles({
  RegularBookTypeChecker: { display: "grid", alignContent: "center" },
  form: { display: "grid", gridGap: variables.normal_gap },
  normalCheckboxWrapper: {
    width: "min-content",
    border: `2px solid ${variables.colors.deepblue}`,
  },
});
export function RegularBookTypeChecker() {
  const classes = regularBookTypeCheckerStyles();

  return (
    <div className={classes.RegularBookTypeChecker}>
      <Formik initialValues={{ bookable: false, optionBookable: false }}>
        <Form className={classes.form}>
          <div className={classes.normalCheckboxWrapper}>
            <NormalInputField
              customStyles={checkBoxStyles}
              type="checkbox"
              name="bookable"
              extraInputProps={{ disabled: true }}
            />
          </div>
        </Form>
      </Formik>
    </div>
  );
}

const optionBookTypeCheckerStyles = createUseStyles({
  OptionBookTypeChecker: { display: "grid", alignContent: "center" },
  form: { display: "grid", gridGap: variables.normal_gap },
  optionCheckboxWrapper: {
    width: "min-content",
    border: `2px solid ${variables.colors.orange}`,
  },
});
export function OptionBookTypeChecker() {
  const classes = optionBookTypeCheckerStyles();

  return (
    <div className={classes.OptionBookTypeChecker}>
      <Formik initialValues={{ bookable: false, optionBookable: false }}>
        <Form className={classes.form}>
          <div className={classes.optionCheckboxWrapper}>
            <NormalInputField
              customStyles={checkBoxStyles}
              type="checkbox"
              name="bookable"
              extraInputProps={{ disabled: true }}
            />
          </div>
        </Form>
      </Formik>
    </div>
  );
}
