import { Image, StyleSheet } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import React from "react";

const mainPageStyles = StyleSheet.create({
  img: { height: 80, objectFit: "contain" },
});
const PDFImage = ({ src }) => {
  return (
    <Image
      src={src + "?noCache=" + Math.random().toString()}
      style={mainPageStyles.img}
      fixed
    />
  );
};
PDFImage.defaultProps = { style: {} };
PDFImage.propTypes = { src: PropTypes.string.isRequired };
export default PDFImage;
