import PropTypes from "prop-types";
import React from "react";
import { createUseStyles } from "react-jss";
import { DayActivity } from "../Day/activities";
import { variables } from "@src/jsssetup";

const destinationActivitiesStyles = createUseStyles({
  DestinationActivities: {
    display: "grid",
    gridGap: variables.normal_gap,
  },
});
const DestinationActivities = ({ activities = [] }) => {
  const classes = destinationActivitiesStyles();
  return (
    <div className={classes.DestinationActivities}>
      {activities.map((act, idx) => (
        <DayActivity activity={act} key={idx} />
      ))}
    </div>
  );
};
DestinationActivities.defaultProps = {
  activities: [],
};
DestinationActivities.propTypes = {
  activities: PropTypes.array.isRequired,
};

export default DestinationActivities;
