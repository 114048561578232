import { Panel, Loader, Tag } from "rsuite";

import PropTypes from "prop-types";
import React from "react";
import { AddOnContent } from "../Controllers/DayByDay";
import { addOnReservationStatus } from "@src/config/service";
import _ from "lodash";
import { naString } from "@src/tools/string_tools";

export default class BookingModalAddon extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { addon, reservation } = this.props;

    if (!addon) {
      return null;
    }

    return (
      <Panel
        className="ServicePanel ServicePanel--addon"
        shaded
        header={
          <React.Fragment>
            <h6>{addon.title}</h6>
            <span className="ServicePanel__header-actions">
              {reservation.status == "PE" ? (
                <Loader className="reservation-loader" />
              ) : (
                <Tag color={reservation.status !== "CO" ? "red" : "green"}>
                  <strong>
                    {naString(
                      _.get(addOnReservationStatus, reservation.status)
                    )}
                  </strong>
                </Tag>
              )}
            </span>
          </React.Fragment>
        }>
        <AddOnContent service={addon} />
      </Panel>
    );
  }
}

BookingModalAddon.propTypes = {
  addon: PropTypes.object.isRequired,
  reservation: PropTypes.object.isRequired,
};
