import _ from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { TransportationLegs } from "../../Components/Preferences/TransportationLegs";
import { Steps } from "rsuite";
import { useDispatch, useSelector } from "react-redux";
import { getSetupFormDataSelector } from "@src/selectors/Project/TripPlanner";
import AccommodationSetup from "../../Controllers/AccommodationSetupController";
import TransferSetup from "../../Controllers/TransferSetupController";
import { prevStep } from "@src/actions/Project/TripPlanner";
import { resetDbDCustomSrvs } from "@src/actions/Project/TripPlanner/day_by_day/custom_services";

export const ServicePreferencesModal = ({ onNextStep, onClose }) => {
  const [step, setStep] = useState(1);

  const { requiredServices, currentTripStep, hasCustomServices } = useSelector(
    (state) => {
      const { requiredServices } = getSetupFormDataSelector(state);
      const currentTripStep = state.tripPlannerCurrentStep;
      const hasCustomServices =
        _.flatten(Object.values(state.tripPlannerDbDCustomSrvData)).length > 0;
      return { requiredServices, currentTripStep, hasCustomServices };
    }
  );

  const dispatch = useDispatch();
  const onPrevTripStep = useCallback(
    (current) => dispatch(prevStep(current)),
    [dispatch]
  );
  const onResetCustomSrvs = useCallback(
    () => dispatch(resetDbDCustomSrvs()),
    [dispatch]
  );

  return (
    <div className="Modal ServicePreferencesModal">
      <div className="Modal__card">
        <div className="Modal__card__header">
          <h5>Base Service Preferences</h5>
        </div>
        <div className="Modal__card__body ServicePreferencesModal__body">
          <Steps current={step - 1}>
            {requiredServices.includes("TR") && (
              <Steps.Item title="Transportation" />
            )}
            {requiredServices.includes("ACC") && (
              <Steps.Item title="Accommodation" />
            )}
            {requiredServices.includes("TF") && (
              <Steps.Item title="Transfers" />
            )}
          </Steps>
          {step === 1 &&
            (requiredServices.includes("TR") ? (
              <TransportationLegs
                onNextStep={() => {
                  if (
                    requiredServices.filter(
                      (srv) => !["TR", "MI"].includes(srv)
                    ).length === 0
                  ) {
                    onNextStep(2);
                    onClose();
                    return;
                  }
                  setStep((p) => p + 1);
                }}
              />
            ) : (
              requiredServices.includes("ACC") && (
                <AccommodationSetup
                  onNextStep={() => {
                    if (
                      requiredServices.filter(
                        (srv) => !["TR", "ACC", "MI"].includes(srv)
                      ).length === 0
                    ) {
                      onNextStep(2);
                      onClose();
                      return;
                    }
                    setStep((p) => p + 1);
                  }}
                />
              )
            ))}
          {step === 2 && requiredServices.includes("ACC") && (
            <AccommodationSetup
              onNextStep={() => {
                if (
                  requiredServices.filter(
                    (srv) => !["TR", "ACC", "MI"].includes(srv)
                  ).length === 0
                ) {
                  onNextStep(2);
                  onClose();
                  return;
                }

                setStep((p) => p + 1);
              }}
            />
          )}
          {step === 3 && (
            <TransferSetup
              onNextStep={() => {
                onNextStep(2);
                onClose();
              }}
            />
          )}
          {hasCustomServices && (
            <div className="Form">
              <h5>Service Reset Actions</h5>
              <br />
              <button
                className="Button"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  onResetCustomSrvs();
                }}>
                Reset Custom Services
              </button>
            </div>
          )}
        </div>
        <div className="Modal__card__actions">
          <button className="Button" data-ghost="true" onClick={onClose}>
            Close
          </button>
          {currentTripStep > 1 && (
            <button
              className="Button"
              data-ghost="true"
              onClick={() => {
                onPrevTripStep(2);
                onClose();
              }}>
              Back to Step 1
            </button>
          )}
          {step > 1 && (
            <button
              className="Button"
              data-ghost="true"
              onClick={() => setStep((p) => p - 1)}>
              Previous Service
            </button>
          )}
          {currentTripStep > 2 && (
            <button
              className="Button"
              onClick={() => {
                if (step === 3) {
                  onNextStep(2);
                  onClose();
                  return;
                } else if (requiredServices.length === 2 && step === 2) {
                  onNextStep(2);
                  onClose();
                  return;
                }

                setStep((p) => p + 1);
              }}>
              Next Service
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
ServicePreferencesModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onNextStep: PropTypes.func.isRequired,
};
