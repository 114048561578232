import axios from "axios";
import { point } from "@turf/turf";
import greatCircle from "@turf/great-circle";

import _ from "lodash";
import mapboxgl from "mapbox-gl";
import styles from "./styles";

const token =
  "pk.eyJ1IjoiZmlsb2RhbW9zIiwiYSI6ImNqemdneDltcTBpZWgzbnA2MmZycHRuaDUifQ.-U7GY0FFegKiJtR_Kg3OeA";
mapboxgl.accessToken = token;

export const getMap = (options) => {
  const containerId = options.containerId;
  const optionsKeys = Object.keys(options);
  var interactive = true; // Interactive by default
  if (optionsKeys.includes("interactive")) {
    interactive = options.interactive;
  }

  var scrollZoom = true;
  if (optionsKeys.includes("scrollZoom")) {
    scrollZoom = options.scrollZoom;
  }

  var startingPosition = [114.149139, 22.286394]; // Default starting position

  if (optionsKeys.includes("startingPosition") && options.startingPosition) {
    startingPosition = options.startingPosition;
  }

  var controls = true; // Controls on by default
  if (optionsKeys.includes("controls")) {
    controls = options.controls;
  }

  var zoom = 15;
  if (optionsKeys.includes("zoom") && options.zoom) {
    zoom = options.zoom;
  }

  var map = new mapboxgl.Map({
    container: containerId, // container id
    style: styles.streets,
    center: startingPosition, // starting position
    interactive: interactive,
    scrollZoom: scrollZoom,
    zoom: zoom, // starting zoom,
    attributionControl: false,
  });

  if (controls) {
    map.addControl(new mapboxgl.NavigationControl());
  }

  return map;
};

export const geolocateUser = () => {
  return new Promise((resolve) => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        resolve({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    } else {
      resolve({ lat: 0, lng: 0 });
    }
  });
};

export const centerToPoints = (map, points, zoom) => {
  var bounds = new mapboxgl.LngLatBounds();
  var validPoints = [];
  points
    .filter((p) => p[1] <= 90 && p[1] >= -90)
    .filter((p) => p[0] !== 0 && p[1] !== 0)
    .forEach((p) => {
      bounds.extend(new mapboxgl.LngLat(p[0], p[1]));
      validPoints.push(p);
    });

  validPoints = [
    ...new Set(validPoints.map((point) => point.toString())),
  ].map((point) => point.split(",").map((coord) => parseFloat(coord)));

  const options = { padding: 100, duration: 300 };

  if (points.length == 1) {
    map.setCenter(points[0]);
    return;

    // if (typeof zoom == "number") {
    //   options["maxZoom"] = zoom;
    // } else {
    //   options["maxZoom"] = 9;
    // }
  }

  if (validPoints.length) {
    map.fitBounds(bounds, { ...options });
  }
};

export const getGreatCircleCoords = (start, end) => {
  const tstart = point(start);
  const tend = point(end);

  var gCircle = greatCircle(tstart, tend);
  return gCircle.geometry.coordinates;
};

export const fetchDirections = async (points, type = "walking") => {
  const pointsStr = points.map((point) => `${point[0]},${point[1]}`).join(";");
  const url = `https://api.mapbox.com/directions/v5/mapbox/${type}/${pointsStr}?access_token=${token}`;

  const rq = axios.create({
    baseURL: url,
  });

  try {
    const result = await rq.get(url, {
      params: { geometries: "geojson", overview: "full" },
    });

    const route = _.get(result, "data.routes.0");
    if (!route) {
      return null;
    }

    const { geometry, distance, duration } = route;
    return { geometry, distance, duration };
  } catch (error) {
    return null;
  }
};

export const humanizeDistance = (meters) => {
  if (meters < 1000) {
    return `${meters.toFixed()}m`;
  } else {
    return `${(meters / 1000).toFixed()}km`;
  }
};
