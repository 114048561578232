import React from "react";
import { Schema, Tag } from "rsuite";
const { StringType, ArrayType } = Schema.Types;

import TransTxt from "@src/components/common/SxFormatMessage";

export const initialUsersFilterForm = {
  id: {
    value: "",
    verbose: <TransTxt id="uid" upperCase />,
    placeholder: "C990890J023",
    type: "text",
  },
  social_media_text: {
    value: "",
    verbose: "Connect",
    type: "text",
  },
  we_chat: {
    value: "",
    verbose: "WeChat",
    type: "text",
  },
  status: {
    value: [],
    verbose: "Status",
    type: "text",
    labelKey: "display_name",
    data: [
      {
        value: "AC",
        display_name: (
          <Tag color="green">
            <strong>Active</strong>
          </Tag>
        ),
        label: (
          <Tag color="green">
            <strong>Active</strong>
          </Tag>
        ),
      },
      {
        value: "IN",
        display_name: (
          <Tag color="red">
            <strong>Inactive</strong>
          </Tag>
        ),
        label: (
          <Tag color="red">
            <strong>Inactive</strong>
          </Tag>
        ),
      },
    ],
  },
  company_type: {
    value: [],
    verbose: "User Type",
    type: "text",
    labelKey: "display_name",
  },
  face_book: {
    value: "",
    verbose: "Facebook",
    type: "text",
  },
  qq: {
    value: "",
    verbose: "QQ",
    type: "text",
  },
  job_description: {
    value: [],
    data: [],
    verbose: <TransTxt id="job_description" startCase />,
    placeholder: "Manager",
    labelKey: "brief_description_en",
    valueKey: "id",
  },
  nick_name: {
    value: "",
    verbose: "Nick Name",
    placeholder: "Shaun",
    type: "text",
  },
  email: {
    value: "",
    verbose: <TransTxt id="account_email" startCase />,
    placeholder: "998889@qq.com",
    type: "text",
  },
  phone: {
    value: "",
    verbose: <TransTxt id="tel_number" startCase />,
    placeholder: "+(86)2034408976",
    type: "text",
  },
  mobile_phone: {
    value: "",
    verbose: <TransTxt id="mobile_number" startCase />,
    placeholder: "+(86)13888989675",
    type: "text",
  },
  creator: {
    value: "",
    verbose: <TransTxt id="created_by" startCase />,
    placeholder: "Shaun",
    type: "text",
  },
  edited: {
    value: [],
    placement: "left",
    verbose: <TransTxt id="last_edit" startCase />,
  },
  created: {
    value: [],
    placement: "auto",
    verbose: <TransTxt id="created_date" startCase />,
  },
  first_name: {
    value: "",
    verbose: <TransTxt id="first_name" startCase />,
    type: "text",
  },
  last_name: {
    value: "",
    verbose: <TransTxt id="last_name" startCase />,
    type: "text",
  },
  datejoin: {
    value: [],
    verbose: "Date Join",
  },
  gender: {
    value: [],
    verbose: "Gender",
    labelKey: "display_name",
  },
  department: {
    value: [],
    verbose: "Department",
    labelKey: "display_name",
  },
  company_legal_title: {
    value: "",
    verbose: <TransTxt id="business_name" startCase />,
    placeholder: "Co.,Ltd",
  },
  company_brand_name: {
    value: "",
    verbose: <TransTxt id="brand_name" startCase />,
    placeholder: "Brand Name",
  },
  role: {
    value: [],
    verbose: "Role",
  },
  expertise_type: {
    value: [],
    verbose: "expertise_type",
    labelKey: "display_name",
  },
  divisions: {
    value: [],
    verbose: "Divisions",
  },
  travel_sector: {
    value: [],
    verbose: "travel_sector",
    labelKey: "display_name",
  },
};

export const initialUserForm = {
  first_name: {
    value: "",
    required: true,
    verbose: "first_name",
    validator: StringType().isRequired("First name is required."),
    errorMsg: "",
  },
  last_name: {
    value: "",
    required: true,
    verbose: "last_name",
    validator: StringType().isRequired("Last name is required."),
    errorMsg: "",
  },
  email: {
    value: "",
    required: false,
    verbose: "email",
    placeholder: "192389102@qq.com",
    validator: StringType().isEmail("Please enter a valid email address"),
    errorMsg: "",
  },
  job_description: {
    value: [],
    tempValue: [],
    required: false,
    verbose: "job_description",
    placeholder: "Project Assistant",
    validator: ArrayType(),
    errorMsg: "",
  },
  phone: {
    value: "",
    required: false,
    verbose: "phone",
    placeholder: "+862033334444",
    validator: StringType(),
    errorMsg: "",
  },
  mobile_phone: {
    value: "",
    required: false,
    verbose: "mobile_phone",
    placeholder: "+8618990876568",
    validator: StringType(),
    errorMsg: "",
  },
  gender: {
    value: "",
    required: false,
    verbose: "gender",
    validator: StringType(),
    errorMsg: "",
  },
  preferred_language: {
    value: "",
    required: false,
    verbose: "preferred_language",
    validator: StringType().isRequired("A language preferece is required."),
    errorMsg: "",
  },
  original_image: {
    value: "",
    required: false,
    verbose: "original_image",
    fileName: {},
  },
  department: {
    value: "",
    required: false,
    verbose: "department",
    validator: StringType().isRequired("A department preference is required."),
    errorMsg: "",
  },
  expertise_type: {
    value: [],
    required: false,
    verbose: "expertise_type",
    validator: ArrayType()
      .unrepeatable()
      .minLength(1, "At least one expertise type"),
    errorMsg: "",
  },
  travel_sector: {
    value: [],
    required: false,
    verbose: "travel_sector",
    validator: ArrayType()
      .unrepeatable()
      .minLength(1, "At least one travel sector type"),
    errorMsg: "",
  },
  travel_services: {
    value: [],
    required: false,
    verbose: "travel_services",
    validator: ArrayType().unrepeatable(),
    errorMsg: "",
  },
  permission: {
    value: [],
    verbose: "permission",
  },
  email_config: {
    value: [],
    verbose: "email_config",
  },
};
export const initialAddUserForm = {
  email: {
    value: "",
    required: true,
    verbose: <TransTxt id="email" startCase />,
    validator: StringType()
      .isRequired("Email is required.")
      .isEmail("Please enter a valid email address"),
    errorMsg: "",
  },
  password: {
    value: "",
    required: true,
    verbose: <TransTxt id="password" startCase />,
    validator: StringType().isRequired("Password is required."),
    type: "password",
    errorMsg: "",
  },
  password_again: {
    value: "",
    required: true,
    verbose: <TransTxt id="verify_password" startCase />,
    validator: StringType()
      .isRequired("need confirm password")
      .addRule((value, data) => {
        const { password, password_again } = data;
        return password == password_again;
      }, "Two password is different."),
    type: "password",
    errorMsg: "",
  },
  object_id: {
    value: "",
  },
  content_type: {
    value: "",
    required: true,
    validator: StringType().isRequired("Please add user type"),
  },
};

export const defaultOption = {
  basic: {
    value: ["id", "first_name", "job_description", "email", "company_type"],
  },
  contact: {
    value: ["phone"],
  },
  organisation: {
    value: ["company_brand_name", "department", "role"],
  },
  sections: {
    value: ["travel_sector", "expertise_type"],
  },
  services: {
    value: ["travel_services"],
  },
  stage: {
    value: ["status"],
  },
};

export * from "./columns";
