import { getAuthHeaders, serviceCxl } from "@src/api/request";
import axios from "axios";

export const createCustomService = (payload) => {
  return serviceCxl(
    {
      url: "bid/custom-service/",
      method: "post",
      data: payload,
    },
    60000
  );
};

export const updateCustomService = (id, payload) => {
  return serviceCxl(
    {
      url: `bid/custom-service/${id}/`,
      method: "patch",
      data: payload,
    },
    60000
  );
};

export const getCustomService = (id) => {
  return serviceCxl(
    {
      url: `bid/custom-service/${id}/`,
      method: "get",
    },
    60000
  );
};

export const retrieveAddonService = async ({ id }) => {
  const authHeaders = getAuthHeaders();
  return await axios.get(`bid/custom-service/${id}/`, {
    headers: authHeaders,
  });
};

export const uploadCustomServiceImg = (service_id, payload, config = {}) => {
  return serviceCxl(
    {
      url: `bid/custom-service/${service_id}/images/`,
      method: "post",
      data: payload,
      ...config,
    },
    60000
  );
};
