import React, { useEffect, useRef, useState } from "react";
import * as echarts from "echarts";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import { variables } from "@src/jsssetup";
import { injectIntl } from "react-intl";
import _ from "lodash";

const chartStyles = createUseStyles({
  CreateBarChart: {
    "display": "grid",
    "gridTemplateRows": "repeat(2, max-content)",
    "padding": variables.normal_gap,
    "width": (props) => `${props.width}rem`,
    "height": "20rem",
    // "lineHeight": "23rem",
    "& div": {
      alignSelf: "center",
      height: "100rem!importnat",
      width: "100%!important",
    },
    "& canvas": {
      alignSelf: "center",
      width: "100%!important",
      height: "100%!important",
    },
  },
  chart: {
    display: "grid",
    padding: variables.normal_gap,
    width: "20rem",
    height: "30vh",
  },
});
var CreateBarChart = ({
  barWidth,
  title,
  width,
  xAxisColor,
  seriesLabelColor,
  seriesLabelShow,
  predefinedColors,
  moreDataLabel,
  data,
}) => {
  // const [windowSize, setWindowSize] = useState(window.innerWidth);
  const classes = chartStyles({ width });
  const chartRef = useRef(null);

  const createChartInstance = () => {
    const chartInstance = echarts.init(chartRef.current);

    const options = {
      title: {
        text: title,
        top: "5%",
        left: "center",
        textStyle: {
          fontSize: 16,
          fontFamily: "futura,sans-serif",
          fontWeight: "400",
        },
      },
      tooltip: {
        trigger: "axis",
        show: true,
        textStyle: {
          padding: [5, 10],
        },
        position: function (pt) {
          return [pt[0], "10%"];
        },
        formatter: (params) => {
          var name = `${
            params[0] === null || params[0] === undefined
              ? "N/A"
              : params[0].name + " " + moreDataLabel
          }`;
          // const name = `${params[0].name} ${moreDataLabel}`;
          var value = `${params[0].value} `;
          return `${name}<br/><strong>${value} 
          </strong>`;
        },
      },

      xAxis: {
        type: "category",
        data: data.map((item) => `${item.name} ${moreDataLabel}`),
        axisLabel: {
          rotate: 45,
          textStyle: {
            fontSize: 10,
          },
          color: xAxisColor,
        },
      },

      yAxis: {
        type: "value",
        axisLabel: {
          show: false,
        },
      },

      series: [
        {
          label: {
            show: seriesLabelShow,
            position: "top",
            padding: [20, 20, 20, 20],
            color: seriesLabelColor,
            formatter: function (params) {
              return params.value;
            },
            textStyle: {
              baseline: "middle",
            },
          },
          top: "25%",
          type: "bar",
          barWidth: barWidth,

          data: data,
          itemStyle: {
            color: function (params) {
              return predefinedColors[
                params.dataIndex % predefinedColors.length
              ];
            },
          },
        },
      ],
    };

    chartInstance.setOption(options);
    // console.log(chartInstance.getOption());
    return chartInstance;
  };

  // const handleResize = () => setWindowSize(window.innerWidth);
  // window.removeEventListener("resize", handleResize);

  useEffect(() => {
    if (!data) return;
    createChartInstance(data);
    // window.addEventListener("resize", handleResize);
  }, [data]);

  // useEffect(() => {
  //   const existingChart = echarts.getInstanceByDom(chartRef.current);
  //   if (existingChart) {
  //     existingChart.resize();
  //   }
  // }, [windowSize]);

  return (
    <React.Fragment>
      <div ref={chartRef} className={classes.CreateBarChart} />
    </React.Fragment>
  );
};
CreateBarChart.defaultProps = {
  moreDataLabel: "",
  seriesLabelShow: "true",
  xAxisColor: "black",
  seriesLabelColor: "black",
  legendColor: "black",
};
CreateBarChart.propTypes = {
  moreDataLabel: PropTypes.string,
  xAxis: PropTypes.string,
  barWidth: PropTypes.string,
  title: PropTypes.object,
  width: PropTypes.number,
  xAxisColor: PropTypes.string,
  seriesLabelColor: PropTypes.string,
  seriesLabelShow: PropTypes.oneOf(["true", "false"]),
  predefinedColors: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
};
CreateBarChart = injectIntl(CreateBarChart);
export default CreateBarChart;
