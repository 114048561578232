import {
  PURCHASES_BEGIN_FETCH_DATA,
  PURCHASES_FETCH_DATA,
  PURCHASES_END_FETCH_DATA,
  PURCHASES_INITIALIZE_LIST_FILTER_FORM,
  PURCHASES_UPDATE_LIST_FILTER_FORM,
  PURCHASES_APPLY_LIST_FILTER,
  PURCHASES_INITIALIZE_LIST_COLUMNS_FORM,
  PURCHASES_APPLY_LIST_COLUMNS,
  PURCHASES_INIT_VIEW_TYPE,
  PURCHASES_CHANGE_VIEW_TYPE,
  PURCHASES_FETCH_GRAPH_DATA,
  PURCHASES_BEGIN_FETCH_GRAPH_DATA,
  PURCHASES_END_FETCH_GRAPH_DATA,
  PURCHASES_FETCH_SUPPLIER_DATA,
  PURCHASES_BEGIN_FETCH_GRAPH_OPTIONS,
  PURCHASES_END_FETCH_GRAPH_OPTIONS,
} from "@src/actions/ManagementConsole/Purchases/types";

import {
  initialPurchasesFilterForm,
  initialPurchasesColumnsSetUp,
} from "@src/forms/ManagementConsole/Purchases";

import { formProcessor, entitiesListSelector } from "@src/reducers/tools";

import update from "immutability-helper";
import _ from "lodash";

//========================================LIST VIEW==============================================
export const initialPurchasesList = {
  count: 0,
  next: null,
  previous: null,
  results: [],
  page: 1,
  limit: 20,
  displayLength: 100,
};

export const purchasesList = (state = initialPurchasesList, action) => {
  switch (action.type) {
    case PURCHASES_FETCH_DATA:
      return action.data;
    case PURCHASES_INITIALIZE_LIST_FILTER_FORM:
      return { ...state, page: 1 };
    default:
      return state;
  }
};

export const initialPurchasesFetchStatus = "IDLE";
export const purchasesFetchStatus = (
  state = initialPurchasesFetchStatus,
  action
) => {
  switch (action.type) {
    case PURCHASES_BEGIN_FETCH_DATA:
      return "FETCHING";
    case PURCHASES_END_FETCH_DATA:
      return "FETCHED";
    default:
      return state;
  }
};

//========================================FILTER RELATION============================================
export const purchasesListFilterForm = (
  state = initialPurchasesFilterForm,
  action
) => {
  switch (action.type) {
    case PURCHASES_INITIALIZE_LIST_FILTER_FORM:
      return initialPurchasesFilterForm;
    case PURCHASES_UPDATE_LIST_FILTER_FORM:
      return formProcessor(
        state,
        action.data.type,
        action.data.name,
        action.data.value
      );
    default:
      return state;
  }
};

export const purchasesListFilter = (
  state = initialPurchasesFilterForm,
  action
) => {
  switch (action.type) {
    case PURCHASES_APPLY_LIST_FILTER:
      return action.data;
    default:
      return state;
  }
};

//======================================COLUMNS RELATION=================================
export const purchasesListColumns = (
  state = entitiesListSelector(initialPurchasesColumnsSetUp),
  action
) => {
  switch (action.type) {
    case PURCHASES_APPLY_LIST_COLUMNS:
      return entitiesListSelector(action.data);
    default:
      return state;
  }
};

export const purchasesListColumnsForm = (
  state = initialPurchasesColumnsSetUp,
  action
) => {
  switch (action.type) {
    case PURCHASES_INITIALIZE_LIST_COLUMNS_FORM:
      var newState = {};

      for (const key in state) {
        // clear form's value
        state[key] = update(state[key], { value: { $set: [] } });
        newState[key] = update(state[key], { value: { $set: [] } });
        // add value by action data
        action.data.forEach((item) => {
          if (_.find(state[key].choices, { value: item.value })) {
            newState[key] = update(newState[key], {
              value: { $push: [item.value] },
            });
          }
        });
      }

      return newState;
    default:
      return state;
  }
};

//=============================================GRAPH=================================
export const initType = {
  type: "ALL",
  date: "",
};

export const purchasesViewType = (state = initType, action) => {
  switch (action.type) {
    case PURCHASES_INIT_VIEW_TYPE:
      return initType;
    case PURCHASES_CHANGE_VIEW_TYPE:
      return action.data;
    default:
      return state;
  }
};

export const purchasesGraphData = (state = {}, action) => {
  switch (action.type) {
    case PURCHASES_FETCH_GRAPH_DATA:
      return action.data;
    default:
      return state;
  }
};

export const initialPurchasesGraphDataStatus = "IDLE";
export const purchasesGraphDataStatus = (
  state = initialPurchasesGraphDataStatus,
  action
) => {
  switch (action.type) {
    case PURCHASES_BEGIN_FETCH_GRAPH_DATA:
      return "FETCHING";
    case PURCHASES_END_FETCH_GRAPH_DATA:
      return "FETCHED";
    default:
      return state;
  }
};

export const supplierData = (state = [], action) => {
  switch (action.type) {
    case PURCHASES_FETCH_SUPPLIER_DATA: {
      const newData = [];
      action.data.forEach((item) => {
        newData.push({
          value: item.value,
          label: _.startCase(item.display_name),
        });
      });
      return newData;
    }
    default:
      return state;
  }
};

export const initialPurchasesGraphDataOptionsFetchStatus = "IDLE";
export const purchasesGraphDataOptionsFetchStatus = (
  state = initialPurchasesGraphDataOptionsFetchStatus,
  action
) => {
  switch (action.type) {
    case PURCHASES_BEGIN_FETCH_GRAPH_OPTIONS:
      return "FETCHING";
    case PURCHASES_END_FETCH_GRAPH_OPTIONS:
      return "FETCHED";
    default:
      return state;
  }
};
