import {
  TRIPPLANNER_DBD_ADD_ADHOC_SRV,
  TRIPPLANNER_DBD_EDIT_ADHOC_SRV,
  TRIPPLANNER_DBD_REMOVE_ADHOC_SRV,
} from "../types/day_by_day/addhoc_services";

export const addDbDAdhocSrv = ({ dayIdx, srv, srv_type }) => {
  srv.srvType = "AD";
  srv.addhoc_service_type = srv_type;
  return { type: TRIPPLANNER_DBD_ADD_ADHOC_SRV, dayIdx, srv };
};

export const editDbDAdhocSrv = ({ srv }) => {
  return { type: TRIPPLANNER_DBD_EDIT_ADHOC_SRV, srv };
};

export const removeDbDAdhocSrv = ({ dayIdx, srv_id }) => {
  return { type: TRIPPLANNER_DBD_REMOVE_ADHOC_SRV, dayIdx, srv_id };
};
