import _ from "lodash";
import { CustomButton } from "@src/components/common/buttons";
import { modalGenericStyles } from "@src/jsssetup";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { useDispatch, useSelector } from "react-redux";
import { getUserSourceEntitySelector } from "@src/selectors/Shared/user_selectors";
import { useAIChat, useAITripGenerator, verifyAITrip } from "@src/pages/hooks";
import MarkdownRenderer from "react-markdown-renderer";
import { useMutation } from "@tanstack/react-query";
import { NormalInputField } from "@src/components/forms";
import { Form, Formik } from "formik";
import { Loader } from "rsuite";
import { TripPlanContext } from "../../TripPlanner";
import { Destination } from "./Destination";
import { ExtremalPoint } from "./ExtremalPoint";
import { itineraryUpdateDestinations } from "@src/actions/Project/TripPlanner";
import { Icon } from "@iconify/react";
import { saveAiDialogs } from "@src/reducers/Project/TripPlanner";

const easyAiStyles = createUseStyles({
  EasyAI: modalGenericStyles.modal,
  ...modalGenericStyles,
});
const EasyAI = ({ onClose }) => {
  const lastMsg = useRef(null);
  const { source_entity } = useSelector((state) => {
    const source_entity = getUserSourceEntitySelector(state);
    return { source_entity };
  });

  const [disableInput, setDisableInput] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tripObject, setTripObject] = useState({});
  const [finalTripObject, setFinalTripObject] = useState({});
  const [messages, setMessages] = useState([]);
  const [tripValidationMsgs, setTripValidationMsgs] = useState([]);
  const [overridenRequirements, setOverridenRequirements] = useState([]);

  const classes = easyAiStyles();

  const { setShowEasyAI } = useContext(TripPlanContext);

  const {
    promptMutation,
    streamContent,
    contentGenerationStatus,
    onResetAIChat,
  } = useAIChat();
  // Set Chat Messages
  useEffect(() => {
    if (typeof streamContent === "string" && streamContent.length === 0) return;
    if (JSON.stringify(streamContent).length === 0) return;

    setMessages((p) => {
      const newMsgs = p.filter((msg, idx) =>
        msg.role == "assistant" && idx === p.length - 1 ? false : true
      );
      return [
        ...newMsgs,
        {
          role: streamContent?.role || null,
          content: streamContent?.content ?? "",
        },
      ];
    });
    if (lastMsg?.current) {
      lastMsg.current.scrollIntoView(false, {
        behavior: "smooth",
        block: "end",
      });
    }
  }, [JSON.stringify(streamContent).length]);

  const { tripGenMutation, streamTripContent } = useAITripGenerator();
  // Set Trip Object
  useEffect(() => {
    if (streamTripContent.status !== "finished") return;
    if (_.isEmpty(streamTripContent?.message)) return;

    setTripObject(streamTripContent.message);
    setLoading(false);
    setDisableInput(false);
  }, [streamTripContent.status]);

  // Disable U.I while AI is generating content
  useEffect(() => {
    if (contentGenerationStatus !== "finished") return;
    setDisableInput(false);
  }, [contentGenerationStatus]);

  const verifyTripMutation = useMutation({
    mutationFn: ({ source_entity, messages }) =>
      verifyAITrip({ source_entity, messages }),
    onSuccess: (data) => {
      const missing_fields = data?.data?.missing_fields ?? [];
      setTripValidationMsgs(missing_fields);
      if (missing_fields.length > 0) {
        setLoading(false);
        if (!overridenRequirements.length) return;
        if (
          missing_fields.some((field) => !overridenRequirements.includes(field))
        )
          return;
      }
      setLoading(true);
      tripGenMutation.mutate({
        source_entity,
        message: _.last(messages.filter((msg) => msg.role === "assistant"))
          .content,
      });
    },
    onError: () => {
      setLoading(false);
    },
  });

  const dispatch = useDispatch();

  const onSaveDialogs = useCallback(
    async (ai_dialogs) => await dispatch(saveAiDialogs(ai_dialogs)),
    [dispatch]
  );

  const onUseTrip = useCallback(
    async (destinations) => {
      await dispatch(itineraryUpdateDestinations(destinations));
      onClose();
    },
    [dispatch]
  );

  return (
    <div className={`${classes.EasyAI} EasyAI`}>
      <div
        className={`${classes.card} EasyAI__card`}
        data-withtrip={!_.isEmpty(tripObject)}>
        <div className={classes.cardHeader}>
          <h5>easyAI</h5>
        </div>
        <div
          className={`${classes.cardBody} EasyAI__card__body`}
          data-withtrip={!_.isEmpty(tripObject)}>
          {loading && <Loader size="lg" backdrop center />}
          {messages.length === 0 && (
            <div className="EasyAI__card__body__placeholder">
              <img src="https://sisistaticassets.s3.ap-southeast-1.amazonaws.com/assets/ai_placeholder.svg" />
              <h5>Where do you want to travel?</h5>
            </div>
          )}
          <div className="EasyAI__card__body__discussion">
            <div className="EasyAI__card__body__discussion__msgs">
              {messages.map((message, i) => {
                const isLast = i === messages.length - 1;

                return (
                  <div
                    key={i}
                    ref={isLast ? lastMsg : null}
                    className={`EasyAI__card__body__discussion__msgs__msg ${
                      message.role === "assistant"
                        ? "EasyAI__card__body__discussion__msgs__msg--assistant"
                        : "EasyAI__card__body__discussion__msgs__msg--user"
                    }`}>
                    <span
                      className={`EasyAI__card__body__discussion__msgs__msg__role ${
                        message.role === "assistant"
                          ? "EasyAI__card__body__discussion__msgs__msg__role--assistant"
                          : "EasyAI__card__body__discussion__msgs__msg__role--user"
                      }`}>
                      <strong>
                        {message.role === "assistant" ? "easyAI" : "Me"}
                      </strong>
                      :
                    </span>{" "}
                    <span
                      className={`EasyAI__card__body__discussion__msgs__msg__txt ${
                        message.role === "assistant"
                          ? "EasyAI__card__body__discussion__msgs__msg__txt--assistant"
                          : "EasyAI__card__body__discussion__msgs__msg__txt--user"
                      }`}>
                      {message.role === "assistant" ? (
                        <MarkdownRenderer markdown={message.content} />
                      ) : (
                        message.content.split("~~~")[0]
                      )}
                    </span>
                  </div>
                );
              })}
              {tripValidationMsgs.length > 0 && (
                <div className="EasyAI__card__body__discussion__msgs__msg EasyAI__card__body__discussion__msgs__msg--assistant">
                  <span className="EasyAI__card__body__discussion__msgs__msg__role EasyAI__card__body__discussion__msgs__msg__role--assistant">
                    <strong>easyAI</strong>:
                  </span>{" "}
                  <span className="EasyAI__card__body__discussion__msgs__msg__txt EasyAI__card__body__discussion__msgs__msg__txt--assistant">
                    Please provide the following information in order for me to
                    generate your trip
                    <br />
                    You can untick the points that you do not require.
                    <ul>
                      {tripValidationMsgs.map((msg, idx) => (
                        <li
                          key={idx}
                          className="EasyAI__card__body__discussion__msgs__msg__txt__validation_msg">
                          <strong>{_.startCase(msg)}</strong>
                          <small>Override: </small>
                          <input
                            type="checkbox"
                            onChange={(e) =>
                              e.target.checked
                                ? setOverridenRequirements((p) => [
                                    ...new Set([...p, msg]),
                                  ])
                                : setOverridenRequirements((p) =>
                                    p.filter((p) => p !== msg)
                                  )
                            }
                          />
                        </li>
                      ))}
                    </ul>
                  </span>
                </div>
              )}
            </div>
            <Formik
              initialValues={{ prompt: "" }}
              onSubmit={({ prompt }) => {
                setTripValidationMsgs([]);
                setMessages((p) => {
                  const newMsgs = [
                    ...p,
                    {
                      role: "user",
                      content: `${prompt}~~~Your response must be in markdown format`,
                    },
                  ];

                  setDisableInput(true);
                  promptMutation.mutate({
                    source_entity,
                    messages: newMsgs,
                  });
                  return newMsgs;
                });
              }}>
              {({ setFieldValue, submitForm, resetForm }) => (
                <Form className="EasyAI__card__body__discussion__promptForm">
                  {messages.length === 0 && (
                    <div className="EasyAI__card__body__discussion__promptForm__examples">
                      <span className="EasyAI__card__body__discussion__promptForm__examples__header">
                        <small>Examples</small>
                      </span>
                      <span
                        className="EasyAI__card__body__discussion__promptForm__examples__example"
                        onClick={async () => {
                          setFieldValue(
                            "prompt",
                            [
                              `Please summarize a trip for 3 nights in London`,
                              ` departing from Athens on 1st of March 2024 for 2 adults`,
                              `~~~Your response must be in markdown format`,
                            ].join("")
                          );
                          await submitForm();
                          resetForm();
                        }}>
                        London City Escape
                        <small>3 Nights, City Break</small>
                      </span>
                      <span
                        className="EasyAI__card__body__discussion__promptForm__examples__example"
                        onClick={async () => {
                          setFieldValue(
                            "prompt",
                            [
                              `Please summarize a trip for 6 nights in Scottish highlands`,
                              ` visiting Edinburgh, Glasgow and Inverness`,
                              ` departing from Athens on 1st of March 2024 for 2 adults`,
                              `~~~Your response must be in markdown format`,
                            ].join("")
                          );
                          await submitForm();
                          resetForm();
                        }}>
                        Scottish Highlands getaway
                        <small>6 Nights, Multi-destination trip</small>
                      </span>
                      <span
                        className="EasyAI__card__body__discussion__promptForm__examples__example"
                        onClick={async () => {
                          setFieldValue(
                            "prompt",
                            [
                              `Please summarize an island hopping trip for 9 nights in the Greek islands`,
                              ` visiting the Cyclades`,
                              ` departing from Athens on 1st of July 2024 for 2 adults`,
                              `~~~Your response must be in markdown format`,
                            ].join("")
                          );
                          await submitForm();
                          resetForm();
                        }}>
                        Greek Islands Hopping
                        <small>9 Nights, Single Country Trip</small>
                      </span>
                      <span
                        className="EasyAI__card__body__discussion__promptForm__examples__example"
                        onClick={async () => {
                          setFieldValue(
                            "prompt",
                            [
                              `Plan a euro-trip for 9 nights in major european cities`,
                              ` departing from Shanghai on 1st of July 2024 for 2 adults`,
                              `~~~Your response must be in markdown format`,
                            ].join("")
                          );
                          await submitForm();
                          resetForm();
                        }}>
                        Euro-trip
                        <small>9 Nights, Multi Country Trip</small>
                      </span>
                    </div>
                  )}
                  <div className="EasyAI__card__body__discussion__promptForm__prompt">
                    <NormalInputField name="prompt" />
                    <button
                      className="EasyAI__card__body__discussion__promptForm__prompt__submit-btn"
                      onClick={async (e) => {
                        e.preventDefault();
                        if (disableInput) return;

                        await submitForm();
                        resetForm();
                        if (lastMsg?.current) {
                          lastMsg.current.scrollIntoView(false, {
                            behavior: "smooth",
                            block: "end",
                          });
                        }
                      }}>
                      <Icon
                        className="EasyAI__card__body__discussion__promptForm__prompt__submit-btn__icon"
                        icon="icon-park-solid:up-one"
                      />
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          {!_.isEmpty(tripObject) && (
            <div className="EasyAI__card__body__trip">
              {!_.isEmpty(tripObject?.starting_point ?? {}) && (
                <ExtremalPoint
                  pointType="origin"
                  point={{
                    ...(tripObject?.starting_point ?? {}),
                    date: tripObject.outbound_departure,
                  }}
                />
              )}
              {(tripObject?.destinations ?? []).map((dest, idx) => (
                <Destination
                  destination={dest}
                  destIdx={idx}
                  key={idx}
                  setTripObject={setTripObject}
                />
              ))}
              {!_.isEmpty(tripObject?.starting_point ?? {}) && (
                <ExtremalPoint
                  pointType="return"
                  point={{
                    ...(tripObject?.starting_point ?? {}),
                    date: tripObject.return_date,
                  }}
                />
              )}
            </div>
          )}
        </div>
        <div className={classes.cardActions}>
          <CustomButton appearance="ghost" onClick={() => setShowEasyAI(false)}>
            Close
          </CustomButton>
          <CustomButton
            appearance="ghost"
            onClick={() => {
              setMessages([]);
              setTripObject({});
              onResetAIChat();
            }}>
            Reset
          </CustomButton>
          <CustomButton
            onClick={() => {
              setLoading(true);
              verifyTripMutation.mutate({
                source_entity,
                messages,
              });
            }}>
            Generate Trip
          </CustomButton>
          {!_.isEmpty(tripObject) && (
            <CustomButton
              onClick={async () => {
                await onSaveDialogs(messages);
                onUseTrip(tripObject?.destinations ?? []);
              }}>
              Use Trip
            </CustomButton>
          )}
        </div>
      </div>
    </div>
  );
};
EasyAI.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default EasyAI;
