import React from "react";
import { createUseStyles } from "react-jss";
import _ from "lodash";
import { variables } from "@src/jsssetup";
import {
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
  Font,
} from "@react-pdf/renderer";
import DocHeader from "./components/DocHeader";
import InvoiceBasicInfo from "./components/InvoiceBasicInfo";
import CustomerInfo from "./components/CustomerInfo";
import TotalCostDueDate from "./components/TotalCostDueDate";
import MemoComp from "./components/MemoComp";
import ItemsTable from "./components/ItemsTable";
import Footer from "./components/Footer";
import { InlineIcon } from "@iconify/react";
import logoSrc from "../../assets/easyTravelTech_logo_orange.png";
import PropTypes from "prop-types";
import Stamp from "./components/Stamp";
import PaymentLinksTable from "./components/PaymentLinksTable";
import NotoSansRegular from "@src/style/fonts/NotoSans-Regular.ttf";
import NotoSansBold from "@src/style/fonts/NotoSans-Bold.ttf";

Font.register({
  family: "Noto Sans",
  fonts: [
    { src: NotoSansRegular, fontWeight: "normal" },
    { src: NotoSansBold, fontWeight: "bold" },
  ],
});

export const easyBasicColor = "#FF6600";

const marginTop = 20;

const styles = StyleSheet.create({
  page: {
    padding: 30,
    paddingBottom: 50,
    position: "relative",
    fontFamily: "Noto Sans",
    color: "black",
    justifyContent: "space-between",
    height: "100%",
  },
  content: { width: "100%", height: "97%" },
  stampContainer: { position: "absolute", width: "92%", height: "95%" },
  title: { fontFamily: "Noto Sans" },
  textNormal: {},
  textBold: { fontFamily: "Noto Sans", fontWeight: "bold" },
  bottomPage: { position: "absolute", left: "50%", bottom: 10, fontSize: 10 },
});
export const MainPage = ({ data, metadata }) => {
  const { entity_colors } = metadata;

  const paymentLinks = data.payment_links_list;

  return (
    <Page size="A4" style={styles.page} wrap={false}>
      <View style={styles.content}>
        {!!data?.stamp && (
          <View style={styles.stampContainer}>
            <Stamp data={data} entity_colors={entity_colors} />
          </View>
        )}
        <DocHeader logoSrc={metadata?.logo_url ?? logoSrc} />
        <View style={{ marginTop: 0 }}>
          <InvoiceBasicInfo data={data} entity_colors={entity_colors} />
        </View>
        <View style={{ marginTop: 0 }}>
          <CustomerInfo data={data} entity_colors={entity_colors} />
        </View>
        <View style={{ marginTop: marginTop }}>
          <TotalCostDueDate data={data} entity_colors={entity_colors} />
        </View>
        <View style={{ marginTop: marginTop }}>
          <MemoComp data={data.memo} entity_colors={entity_colors} />
        </View>
        <View style={{ marginTop: marginTop }}>
          <ItemsTable data={data} entity_colors={entity_colors} />
        </View>
        {paymentLinks?.length > 0 && (
          <View style={{ marginTop }}>
            <PaymentLinksTable
              paymentLinks={paymentLinks}
              entity_colors={entity_colors}
            />
          </View>
        )}
      </View>
      <Footer
        data={data.footer}
        color={easyBasicColor}
        entity_colors={entity_colors}
      />
      <Text
        style={styles.bottomPage}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
        fixed
      />
    </Page>
  );
};
MainPage.defaultProps = {
  data: {},
  metadata: {},
};
MainPage.propTypes = {
  data: PropTypes.object.isRequired,
  metadata: PropTypes.object,
};

const previewPDFtyles = createUseStyles({
  PreviewPDF: { height: "100%" },
  header: {
    color: variables.colors.easy.orange,
    fontSize: "large",
    fontWeight: "600",
  },
});
const PreviewPDF = ({ children }) => {
  const classes = previewPDFtyles({});

  return (
    <div className={classes.PreviewPDF}>
      <div className={classes.header}>
        Preview <InlineIcon icon="mdi:alert-circle" />
      </div>
      <PDFViewer width="100%" height="100%">
        {children}
      </PDFViewer>
    </div>
  );
};
PreviewPDF.defaultProps = { data: {} };
PreviewPDF.propTypes = { data: PropTypes.object.isRequired };
export default PreviewPDF;
