// import { Schema } from "rsuite";
// const { StringType } = Schema.Types;

import {
  // VENDORS_ACCOMMODATION_INITIALIZE_LIST_FILTER_FORM,
  // VENDORS_ACCOMMODATION_APPLY_LIST_FILTER,
  // VENDORS_ACCOMMODATION_UPDATE_LIST_FILTER_FORM,
  // VENDORS_ACCOMMODATION_FETCH_DATA,
  // VENDORS_ACCOMMODATION_BEGIN_FETCH_DATA,
  // VENDORS_ACCOMMODATION_END_FETCH_DATA,
  // VENDORS_ACCOMMODATION_FETCH_OPTIONS,
  // //================================ADD VENDORS ACCOMMODATION========================
  // VENDORS_ACCOMMODATION_BEGIN_ADD,
  // VENDORS_ACCOMMODATION_END_ADD,
  // VENDORS_ACCOMMODATION_IDLE_ADD,
  // VENDORS_ACCOMMODATION_SHOW_BASE_FORM_MODAL,
  // VENDORS_ACCOMMODATION_HIDE_BASE_FORM_MODAL,
  // VENDORS_ACCOMMODATION_EDIT_BASE_FORM_MODAL,
  // //===============================EDIT=================================
  // VENDORS_ACCOMMODATION_INITIALIZE_BASE_DATA_EDIT_FORM,
  // VENDORS_ACCOMMODATION_FETCH_EDIT_DATA,
  // VENDORS_ACCOMMODATION_INIT_BASE_DATA_EDIT_FORM,
  // VENDORS_ACCOMMODATION_UPDATE_BASE_DATA_FORM,
  // VENDORS_ACCOMMODATION_APPLY_BASE_DATA_FORM,
  // VENDORS_ACCOMMODATION_INITIALIZE_BASE_DATA_FORM,
  // VENDORS_ACCOMMODATION_BEGIN_FETCH_EDIT_DATA,
  // VENDORS_ACCOMMODATION_END_FETCH_EDIT_DATA,
  // //=======================================================
  // VENDORS_ACCOMMODATION_DATA_FORM,
  // VENDORS_ACCOMMODATION_INIT_COLUMNS_BASE_FORM,
  // VENDORS_ACCOMMODATION_INIT_FORM,
  // VENDORS_ACCOMMODATION_BEGIN_FETCH_ACC_DATA_FORM,
  // VENDORS_ACCOMMODATION_END_FETCH_ACC_DATA_FORM,
  // //============================Validate======================
  // VENDORS_ACCOMMODATION_VALIDATE_BASE_DATA_EDIT_FORM,
  VENDORS_ACCOMMODATIONS_LIST_GENERIC_CHANGE,
  VENDORS_ACCOMMODATIONS_LIST_SAVE_RESULTS,
} from "@src/actions/Network/Vendors/Accommodation/types";

// import {
//   initialVendorsFlightsFilterForm,
//   initialVendorsFlightsBaseForm,
//   initialVendorsAccommodationColumnsSetUp,
//   initialVendorsFlightsColumnsMapping,
//   initialVendorsFlightsColumnsProcessor,
// } from "@src/forms/Network/Vendors/Transportation/Flights";

// import { formProcessor, entitiesListSelector } from "@src/reducers/tools";
// import baseDataForm from "@src/pages/Network/Vendors/Accommodation/dataConfig";

// import { updateVendorsAccommodationBaseForm } from "@src/actions/Network/Vendors/Accommodation";

// import { InputFactory } from "@src/components/common/formFactory";

// import update from "immutability-helper";
// import _ from "lodash";
import { commonList } from "@src/reducers/common";

// //========================================LIST VIEW==============================================
// export const initialVendorsAccommodationList = {
//   count: 0,
//   next: null,
//   previous: null,
//   results: [],
//   page: 1,
//   limit: 20,
//   displayLength: 100,
// };

// export const vendorsAccommodationList = (
//   state = initialVendorsAccommodationList,
//   action
// ) => {
//   switch (action.type) {
//     case VENDORS_ACCOMMODATION_FETCH_DATA:
//       return action.data;
//     case VENDORS_ACCOMMODATION_INITIALIZE_LIST_FILTER_FORM:
//       return { ...state, page: 1 };
//     default:
//       return state;
//   }
// };

// export const vendorsAccommodationFetchStatus = (state = "IDLE", action) => {
//   switch (action.type) {
//     case VENDORS_ACCOMMODATION_BEGIN_FETCH_DATA:
//       return "FETCHING";
//     case VENDORS_ACCOMMODATION_END_FETCH_DATA:
//       return "FETCHED";
//     default:
//       return state;
//   }
// };

// export const vendorsAccommodationOptions = (state = {}, action) => {
//   switch (action.type) {
//     case VENDORS_ACCOMMODATION_FETCH_OPTIONS:
//       return action.data;
//     default:
//       return state;
//   }
// };

// // ========================= FILTER RELATION =========================
// export const vendorsAccommodationListFilterForm = (
//   state = initialVendorsFlightsFilterForm,
//   action
// ) => {
//   switch (action.type) {
//     case VENDORS_ACCOMMODATION_INITIALIZE_LIST_FILTER_FORM:
//       return initialVendorsFlightsFilterForm;
//     case VENDORS_ACCOMMODATION_UPDATE_LIST_FILTER_FORM:
//       return formProcessor(
//         state,
//         action.data.type,
//         action.data.name,
//         action.data.value
//       );
//     default:
//       return state;
//   }
// };

// export const vendorsAccommodationListFilter = (
//   state = initialVendorsFlightsFilterForm,
//   action
// ) => {
//   switch (action.type) {
//     case VENDORS_ACCOMMODATION_APPLY_LIST_FILTER:
//       return action.data;
//     default:
//       return state;
//   }
// };

// //======================================COLUMNS RELATION=================================
// export const vendorsAccommodationListColumns = (
//   state = entitiesListSelector(initialVendorsAccommodationColumnsSetUp),
//   action
// ) => {
//   switch (action.type) {
//     case VENDORS_ACCOMMODATION_INIT_COLUMNS_BASE_FORM: {
//       const tmp = action.data.results;
//       const arr = [];
//       const res = _.groupBy(tmp, "vendor");
//       Object.values(res).forEach((item) => {
//         Object.keys(_.get(item[0], "vendor_data")).forEach((e) => {
//           arr.unshift(e);
//         });
//       });
//       const init = initialVendorsAccommodationColumnsSetUp;
//       const value = Array.from(new Set(init.basic.value));
//       const choices = init.basic.choices;

//       Array.from(new Set(arr)).forEach((c) => {
//         value.unshift(c);
//         choices.unshift({
//           display_name: _.startCase(c),
//           key: c,
//         });
//       });

//       return entitiesListSelector(
//         update(init, {
//           basic: {
//             value: { $set: value },
//             choices: { $set: choices },
//           },
//         })
//       );
//     }
//     default:
//       return state;
//   }
// };

// export const vendorsAccommodationColumnsMapping = (
//   state = initialVendorsFlightsColumnsMapping,
//   action
// ) => {
//   switch (action.type) {
//     case VENDORS_ACCOMMODATION_INIT_COLUMNS_BASE_FORM: {
//       const tmp = action.data.results;
//       const arr = [];
//       const newData = {};
//       const obj = {};
//       const res = _.groupBy(tmp, "vendor");
//       for (let key in res) {
//         if (key.split("__")[0] == "acc") {
//           obj[key] = res[key];
//         }
//       }

//       Object.values(obj).forEach((item) => {
//         Object.keys(_.get(item[0], "vendor_data")).forEach((e) => {
//           arr.unshift(e);
//         });
//       });
//       Array.from(new Set(arr)).forEach((c) => {
//         newData[c] = {
//           key: c,
//           width: 220,
//         };
//       });

//       const status = {
//         key: "status",
//         width: 220,
//       };

//       return update(state, {
//         $merge: {
//           ...newData,
//           status,
//         },
//       });
//     }
//     default:
//       return state;
//   }
// };

// export const vendorsAccommodationColumnsProcessor = (
//   state = initialVendorsFlightsColumnsProcessor,
//   action
// ) => {
//   switch (action.type) {
//     case VENDORS_ACCOMMODATION_INIT_COLUMNS_BASE_FORM: {
//       const tmp = action.data.results;
//       const arr = [];
//       const newData = {};
//       const res = _.groupBy(tmp, "vendor");
//       Object.values(res).forEach((item) => {
//         Object.keys(_.get(item[0], "vendor_data")).forEach((e) => {
//           arr.push(e);
//         });
//       });
//       Array.from(new Set(arr)).forEach((c) => {
//         newData[c] = {
//           key: c,
//           getter: (data) => {
//             return _.get(data, "vendor_data")[c];
//           },
//         };
//       });
//       return update(state, {
//         $merge: {
//           ...newData,
//         },
//       });
//     }
//     default:
//       return state;
//   }
// };

// export const vendorsAccommodationEditBaseData = (state = {}, action) => {
//   switch (action.type) {
//     case VENDORS_ACCOMMODATION_FETCH_EDIT_DATA:
//       return action.data;
//     default:
//       return state;
//   }
// };

// export const vendorsAccommodationListBaseForm = (
//   state = initialVendorsFlightsBaseForm,
//   action
// ) => {
//   switch (action.type) {
//     case VENDORS_ACCOMMODATION_INITIALIZE_BASE_DATA_FORM: {
//       const { res, vendors } = action.data;
//       for (let key in res) {
//         res[key] = update(res[key], {
//           value: { $set: "" },
//         });
//       }
//       return update(res, {
//         vendors: {
//           data: { $set: vendors },
//           value: { $set: "" },
//           disabled: { $set: false },
//         },
//         status: {
//           value: { $set: "CA" },
//         },
//       });
//     }
//     case VENDORS_ACCOMMODATION_INITIALIZE_BASE_DATA_EDIT_FORM: {
//       const { res, form } = action.data;
//       const tmp = _.cloneDeep(form);
//       const vendor_data = res.vendor_data;
//       for (let key in tmp) {
//         tmp[key] = update(tmp[key], {
//           value: { $set: vendor_data[key] },
//         });
//       }
//       tmp.status = update(tmp.status, {
//         value: { $set: res.status },
//       });
//       return tmp;
//     }
//     case VENDORS_ACCOMMODATION_INIT_BASE_DATA_EDIT_FORM:
//       return action.data;
//     case VENDORS_ACCOMMODATION_UPDATE_BASE_DATA_FORM:
//       return formProcessor(
//         state,
//         action.data.type,
//         action.data.name,
//         action.data.value
//       );
//     case VENDORS_ACCOMMODATION_DATA_FORM: {
//       const { form } = action.data;
//       let tmp = {};
//       for (let key in form) {
//         tmp[key] = {
//           value: "",
//           required: true,
//           verbose: form[key].label,
//           validator: StringType().isRequired(`${form[key].label} is Required`),
//         };
//       }
//       return update(state, {
//         $merge: { ...tmp },
//       });
//     }
//     default:
//       return state;
//   }
// };

// export const vendorsAccommodationListBaseData = (
//   state = initialVendorsFlightsBaseForm,
//   action
// ) => {
//   switch (action.type) {
//     case VENDORS_ACCOMMODATION_APPLY_BASE_DATA_FORM:
//       return action.data;
//     default:
//       return state;
//   }
// };

// export const vendorsAccommodationFormData = (state = baseDataForm, action) => {
//   switch (action.type) {
//     case VENDORS_ACCOMMODATION_DATA_FORM: {
//       const { form } = action.data;
//       const tmp = _.merge({}, baseDataForm);
//       Object.keys(form).map((item) => {
//         if (!_.find(tmp.credentials, (e) => e.key == item)) {
//           tmp.credentials.push({
//             key: item,
//             factory: InputFactory,
//             valuePath: `vendorsAccommodationListBaseForm[${item}]`,
//             updateValue: updateVendorsAccommodationBaseForm,
//           });
//         }
//       });

//       return tmp;
//     }
//     case VENDORS_ACCOMMODATION_INIT_FORM:
//       return baseDataForm;
//     default:
//       return state;
//   }
// };

// export const vendorsAccommodationInitForm = (state = {}, action) => {
//   switch (action.type) {
//     case VENDORS_ACCOMMODATION_DATA_FORM:
//       return action.data;
//     default:
//       return state;
//   }
// };

// export const vendorsAccommodationBaseFormModalStatus = (
//   state = { loading: false, show: false, mode: "HIDE" },
//   action
// ) => {
//   switch (action.type) {
//     case VENDORS_ACCOMMODATION_SHOW_BASE_FORM_MODAL:
//       return update(state, {
//         loading: { $set: false },
//         show: { $set: true },
//         mode: { $set: "VIEW" },
//       });
//     case VENDORS_ACCOMMODATION_HIDE_BASE_FORM_MODAL:
//       return update(state, {
//         loading: { $set: false },
//         show: { $set: false },
//         mode: { $set: "HIDE" },
//       });
//     case VENDORS_ACCOMMODATION_BEGIN_FETCH_EDIT_DATA:
//       return update(state, { loading: { $set: true }, show: { $set: true } });
//     case VENDORS_ACCOMMODATION_END_FETCH_EDIT_DATA:
//       return update(state, { loading: { $set: false }, show: { $set: true } });
//     case VENDORS_ACCOMMODATION_BEGIN_ADD:
//       return update(state, { loading: { $set: true }, show: { $set: true } });
//     case VENDORS_ACCOMMODATION_IDLE_ADD:
//       return update(state, { loading: { $set: false }, show: { $set: true } });
//     case VENDORS_ACCOMMODATION_END_ADD:
//       return update(state, { loading: { $set: false }, show: { $set: false } });
//     case VENDORS_ACCOMMODATION_BEGIN_FETCH_ACC_DATA_FORM:
//       return update(state, { loading: { $set: true }, show: { $set: true } });
//     case VENDORS_ACCOMMODATION_END_FETCH_ACC_DATA_FORM:
//       return update(state, { loading: { $set: false }, show: { $set: true } });
//     case VENDORS_ACCOMMODATION_EDIT_BASE_FORM_MODAL:
//       return update(state, {
//         loading: { $set: true },
//         show: { $set: true },
//         mode: { $set: "EDIT" },
//       });
//     default:
//       return state;
//   }
// };

// //=============================validate==========================
// // validate
// export const initialBaseDataFormErrorsState = { valid: false };
// export const vendorsAccommodationBaseDataFormErrors = (
//   state = initialBaseDataFormErrorsState,
//   action
// ) => {
//   const tmp = {};
//   Object.entries(initialVendorsFlightsBaseForm).forEach((e) => {
//     tmp[e[0]] = e[1].validator;
//   });
//   const validator = Schema.Model(tmp);

//   switch (action.type) {
//     case VENDORS_ACCOMMODATION_VALIDATE_BASE_DATA_EDIT_FORM: {
//       const tmp = {};
//       Object.entries(action.data).forEach((e) => (tmp[e[0]] = e[1].value));
//       const validation = validator.check(tmp);
//       const valid = Object.entries(validation).every((e) => !e[1].hasError);
//       validation["valid"] = valid;
//       return validation;
//     }
//     default:
//       return state;
//   }
// };

//======================================NEW REFACTOR=================================
const accommodationsVendorsListReducerInitial = {
  ...commonList,
  displayLength: 100,
  filters: {
    creator__icontains: "",
    status: "",
    vendor_type: "",
    vendor: "",
    created__gte: "",
    created__lte: "",
    market_type: "",
    page: 1,
    limit: 20,
    ordering: "",
  },
};
export const AccommodationsVendorsListReducer = (
  state = accommodationsVendorsListReducerInitial,
  action
) => {
  switch (action.type) {
    case VENDORS_ACCOMMODATIONS_LIST_SAVE_RESULTS:
      return action.data;
    case VENDORS_ACCOMMODATIONS_LIST_GENERIC_CHANGE: {
      const { filters } = action;
      return { ...state, filters: { ...state.filters, ...filters } };
    }
    default:
      return state;
  }
};
