import { StyleSheet, Text, Font } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import React from "react";
import NotoSansRegular from "@src/style/fonts/NotoSans-Regular.ttf";
import NotoSansBold from "@src/style/fonts/NotoSans-Bold.ttf";

Font.register({
  family: "Noto Sans",
  fonts: [
    { src: NotoSansRegular, fontWeight: "normal" },
    { src: NotoSansBold, fontWeight: "bold" },
  ],
});

const memoCompStyles = StyleSheet.create({
  MemoComp: { fontFamily: "Noto Sans", fontWeight: "bold", fontSize: 10 },
});
const MemoComp = ({ data }) => {
  return <Text style={memoCompStyles.MemoComp}>{data}</Text>;
};
MemoComp.defaultProps = { data: "", entity_colors: {} };
MemoComp.propTypes = { data: PropTypes.string.isRequired };
export default MemoComp;
