import _ from "lodash";
import { Icon, IconButton, Input, SelectPicker, CheckPicker } from "rsuite";

import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { variables } from "@src/jsssetup";
import { WhisperWrapper } from "@src/components/common/ui_helpers";
import { Form, Formik } from "formik";
import { NormalInputField, NormalSelectField } from "@src/components/forms";

const filtersStyles = createUseStyles({
  Filters: {
    display: "grid",
  },
  form: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr max-content max-content max-content",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    alignItems: "end",
  },
});

const Filters = ({ filters, setFilters }) => {
  const classes = filtersStyles();

  return (
    <div className={classes.Filters}>
      <Formik initialValues={filters} onSubmit={(values) => setFilters(values)}>
        {({ resetForm, submitForm }) => (
          <Form className={classes.form}>
            <NormalInputField name="external_id" label="External Id" />
            <NormalInputField name="name" label="Name" />
            <NormalInputField name="destination" label="Destination" />
            <NormalSelectField
              name="pricing"
              label="Pricing"
              options={[
                [null, "------"],
                [true, "With"],
                [false, "Without"],
              ]}
            />
            <WhisperWrapper msg="Apply Filters">
              <IconButton
                icon={<Icon icon="search" />}
                circle
                color="green"
                type="submit"
              />
            </WhisperWrapper>
            <WhisperWrapper msg="Reset Filters">
              <IconButton
                icon={<Icon icon="undo" />}
                circle
                appearance="primary"
                onClick={() => {
                  resetForm();
                  submitForm();
                }}
              />
            </WhisperWrapper>
          </Form>
        )}
      </Formik>
    </div>
  );
};
Filters.propTypes = {
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
};

export default Filters;
