import React from "react";
import TransTxt from "@src/components/common/SxFormatMessage";

export const initialMembersFilterForm = {
  company: {
    value: "",
    verbose: <TransTxt id="system_net__company" startCase />,
  },
  main_service: {
    value: [],
    verbose: <TransTxt id="main_service" startCase />,
    type: "text",
    searchable: false,
  },
  tag: {
    value: [],
    verbose: <TransTxt id="system_net__tag" startCase />,
    type: "text",
    searchable: false,
  },
  expertise: {
    value: [],
    verbose: <TransTxt id="expertise" startCase />,
    type: "text",
    searchable: false,
  },
  city: {
    value: "",
    verbose: <TransTxt id="city" startCase />,
    type: "text",
  },
  country: {
    value: [],
    display_name: <TransTxt id="country" upperCase />,
    verbose: <TransTxt id="country" startCase />,
    type: "text",
    searchable: true,
  },
};

export const iconConfig = {
  TR: { icon: "icon-Transports" },
  ACC: { icon: "icon-Hotel" },
  TF: { icon: "icon-Transfers" },
  MA: { icon: "icon-AddOns_Function" },
  MI: { icon: "icon-AddOns_Function" },
};

export const statusOptions = [
  {
    value: "UC",
    display_name: "connect",
  },
  {
    value: "SE",
    display_name: "send",
  },
  {
    value: "RE",
    display_name: "recieved",
  },
  {
    value: "RJ",
    display_name: "rejected",
  },
  {
    value: "CN",
    display_name: "connected",
  },
];

export * from "./columns";
