import service, { serviceCxl } from "@src/api/request";

export const getDepositBalance = (source_entity) => {
  return service({
    url: `bid/deposit-balance/${source_entity}`,
    method: "get",
  });
};

export const getDepositEntries = (params) => {
  return service({
    url: `bid/ledger-entry/`,
    method: "get",
    params: params,
  });
};

export const setupPayment = (
  amount,
  customer,
  description,
  currency = "EUR",
  metadata = {}
) => {
  return service({
    url: "bid/setup-payment/",
    method: "post",
    data: {
      amount,
      customer,
      description,
      currency,
      metadata,
    },
  });
};

export const getLedgerEntryStats = (data) => {
  return service({
    url: "bid/ledger-entry-stats/",
    method: "get",
    params: data,
  });
};

export const getSubEntitiesLedgerEntries = (params) => {
  return service({
    url: `bid/sub-entity-ledger-entry/`,
    method: "get",
    params,
  });
};

export const getSubEntitiesLedgerEntryStats = (data) => {
  return service({
    url: "bid/sub-entity-ledger-entry-stats/",
    method: "get",
    params: data,
  });
};

export const addCreditToSubEntities = (data) => {
  return service({
    url: "bid/credit/",
    method: "post",
    data,
  });
};

export const getB2CSubEntitiesLedgerEntries = (data) => {
  return service({
    url: "bid/b2c-ledger-entry/",
    method: "get",
    params: data,
  });
};

export const createSubEntityDepositOrder = ({ amount, currency }) => {
  return serviceCxl(
    {
      url: `/bid/payments/create-sub-entity-deposit-payment-order/`,
      method: "post",
      data: { amount, currency },
    },
    10000
  );
};
