import { SISI_API_URL } from "@src/api";
import axios from "axios";

export const fetchTrainStationsAutocomplete = ({
  source_entity,
  search_query,
  market_type,
}) => {
  return axios.get(`${SISI_API_URL}/railengine/get_destination/`, {
    params: { search_query, source_entity, market_type },
  });
};

export const trainSearch = ({ payload }) => {
  return axios.post(`${SISI_API_URL}/railengine/search/`, payload);
};

export const pollTrains = ({ session_id }) => {
  return axios.get(`${SISI_API_URL}/railengine/search/${session_id}`);
};

export const trainPrebook = ({ payload }) => {
  return axios.post(`${SISI_API_URL}/railengine/prebook`, payload);
};
