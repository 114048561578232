import {
  SISIBID_INIT,
  SISIBID_GOTO_NEXT_STEP,
  SISIBID_GOTO_PREV_STEP,
  SISIBID_NEW_BID_TOGGLE_TARGET_PRICE_MODE,
  SISIBID_BID_INSERT_LIST,
  SISIBID_BID_UPDATE_BIDDING_STATUS,
  SISIBID_BID_SET_BIDDING_LIST,
  SISIBID_BID_MODIFY_LIST_TITLE,
  SISIBID_BID_MODIFY_LIST_STATUS,
  SISIBID_CHANGE_CURRENT_BID_UID,
  SISIBID_NEW_BID_TOGGLE_SUPPLY_VIEW,
  SISIBID_BID_MODIFY_CITY,
  SISIBID_NEW_BID_UPDATE_RESPONSE_TIME,
  SISIBID_BID_SUPPLY_LIST_UPDATE_CHECKED_STATUS,
  SISIBID_NEW_BID_UPDATE_TABLE_CHECKED,
  SISIBID_BID_MODIFY_LIST_NEED_TIME,
  SISIBID_BID_DROP_FROM_LIST,
  SISIBID_UPDATE_TRANSPORTATION_TYPE,
  FETCHED_BIDDING_FACILITY,
  // ========================= INSTANCE DATA =========================
  SISIBID_FETCHING_TRIPPLAN_DATA,
  SISIBID_FETCHED_TRIPPLAN_DATA,
  SISIBID_NEW_BID_FETCH_TRIP_PLAN_DATA,
  SISIBID_NEW_BID_RESET_TRIP_PLAN_DATA,
  SISIBID_BID_UPDATE_SHOW_PRICE_TYPE,
  SISIBID_BID_UPDATE_NEED_TIME,
  SISIBID_BID_UPDATE_MESSAGE,
  SISIBID_NEW_BID_FETCH_AFFILIATES,
  SISIBID_BID_SERVICE_PRICING_CHANGE,
  // ======================== SERVICE FILTERS ========================
  SISIBID_SERVICE_FILTERS_CHANGE,
  SISIBID_SERVICE_FILTERS_RESET,
  // ======================== REQUIRED SERVICES ========================
  SISIBID_REQUIRED_SERVICES_CHANGE,
  SISIBID_REPLACEABLE_SERVICES_CHANGE,
  SISIBID_REFUNDABLE_SERVICES_CHANGE,
  SISIBID_REPLACEABILITY_TYPE_CHANGE,
  // ========================= SERVICE DETAILS =========================
  SISIBID_VIEW_FLIGHT_DETAILS,
  SISIBID_HIDE_FLIGHT_DETAILS,
  SISIBID_VIEW_ACC_DETAILS,
  SISIBID_HIDE_ACC_DETAILS,
  SISIBID_VIEW_TRF_DETAILS,
  SISIBID_HIDE_TRF_DETAILS,
  // ======================== SERVICE COMMENTS =======================
  SISIBID_ADD_SERVICE_COMMENT,
  SISIBID_REMOVE_SERVICE_COMMENT,
  // ======================== BIDDERS FILTERS ========================
  SISIBID_INITIALIZE_BIDDERS_FILTERS,
  SISIBID_CHANGE_BIDDERS_FILTERS,
  // ======================= STEP TWO BIDDERS LIST ======================
  SISIBID_SELECT_BIDDER,
  SISIBID_SELECT_ALL_BIDDERS,
  SISIBID_UNSELECT_ALL_BIDDERS,
  // ========================== SAVE ACTIONS =========================
  SISIBID_REQUEST_SAVE_TRIP,
  SISIBID_CANCEL_SAVE_REQUEST,
  SISIBID_SAVING_BID,
  SISIBID_SAVED_BID,
  SISIBID_REQUESTS_DATA_UPDATE,
  SISIBID_REQUEST_DATA_UPDATE,
  // ======================= SEND REQUEST ACTIONS ======================
  SISIBID_REQUEST_SEND_BID_REQUEST,
  SISIBID_SEND_BID_REQUEST,
  SISIBID_CANCEL_SEND_BID_REQUEST,
  SISIBID_SENT_BID_REQUEST,
  // ========================== FLOW ACTIONS =========================
  SISIBID_SAVE_OFFERS,
  SISIBID_CHANGE_FLOW_VIEW_TYPE,
  SISIBID_COMPARE_OFFER,
  SISIBID_RESET_COMPARE_OFFER,
  // ======================= WAIT MODAL ACTIONS ======================
  SISIBID_SHOW_WAIT_MODAL,
  SISIBID_HIDE_WAIT_MODAL,
  // ==================== OFFER SELECTION ACTIONS ====================
  SISIBID_SELECT_OFFER,
  SISIBID_INIT_SELECTED_OFFERS,
  SISIBID_SELECT_FULL_OFFER,
  SISIBID_DESELECT_OFFER,
  SISIBID_DESELECT_FULL_OFFER,
} from "@src/actions/Project/SiSiBid/types";

import _ from "lodash";
import update from "immutability-helper";

export const newBiddingResponseTime = (state = "", action) => {
  switch (action.type) {
    case SISIBID_NEW_BID_UPDATE_RESPONSE_TIME:
      return action.data;
    default:
      return state;
  }
};

export const newBiddingTableChecked = (state = false, action) => {
  switch (action.type) {
    case SISIBID_NEW_BID_UPDATE_TABLE_CHECKED:
      return true;
    default:
      return state;
  }
};

export const newBiddingBiddersPriceOrder = (state = "UP", action) => {
  switch (action.type) {
    case SISIBID_NEW_BID_TOGGLE_SUPPLY_VIEW:
      return action.data;
    default:
      return state;
  }
};

export const newBiddingSupplyViewType = (state = "GRID", action) => {
  switch (action.type) {
    case SISIBID_NEW_BID_TOGGLE_SUPPLY_VIEW:
      return action.data;
    default:
      return state;
  }
};

const newBiddingBiddersFiltersInitial = {
  name: "",
  rating: [],
  serviceCategory: [],
  tag: [],
  expertise: [],
  supply_partners: [],
  source: "AFF",
  location: [],
  timezoneMode: "relative",
  showSelected: false,
  showMainService: false,
};

export const newBiddingBiddersFilters = (
  state = newBiddingBiddersFiltersInitial,
  action
) => {
  switch (action.type) {
    case SISIBID_INITIALIZE_BIDDERS_FILTERS:
      return newBiddingBiddersFiltersInitial;
    case SISIBID_CHANGE_BIDDERS_FILTERS:
      return update(state, { [action.name]: { $set: action.value } });
    default:
      return state;
  }
};

export const newBiddingBidders = (state = [], action) => {
  switch (action.type) {
    case SISIBID_NEW_BID_FETCH_AFFILIATES:
      return _.get(action, "data", []);
    default:
      return state;
  }
};

export const newBiddingSelectedBidders = (state = [], action) => {
  switch (action.type) {
    case SISIBID_SELECT_BIDDER:
      return update(state, {
        $apply: (bidder_entities) => {
          if (action.actionType == "push") {
            return update(bidder_entities, { $push: [action.bidder] });
          } else {
            return update(bidder_entities, {
              $apply: (list) =>
                list.filter(
                  (l) =>
                    l.id !== action.bidder.id &&
                    l.bidderType == action.bidder.bidderType
                ),
            });
          }
        },
      });
    case SISIBID_SELECT_ALL_BIDDERS:
      return action.bidder_entities;
    case SISIBID_UNSELECT_ALL_BIDDERS:
      return [];
    default:
      return state;
  }
};

export const newBiddingSelectedSuppliers = (state = [], action) => {
  switch (action.type) {
    case SISIBID_BID_SUPPLY_LIST_UPDATE_CHECKED_STATUS: {
      return action.data;
    }
    default:
      return state;
  }
};

export const newBiddingSupplyCity = (state = [], action) => {
  switch (action.type) {
    case SISIBID_BID_MODIFY_CITY:
      return action.data;
    default:
      return state;
  }
};

export const newBiddingOriginalTripDataStatus = (
  state = "fetching",
  action
) => {
  switch (action.type) {
    case SISIBID_FETCHING_TRIPPLAN_DATA:
      return "fetching";
    case SISIBID_FETCHED_TRIPPLAN_DATA:
      return "fetched";
    default:
      return state;
  }
};

export const newBiddingOriginalTripData = (state = {}, action) => {
  switch (action.type) {
    case SISIBID_NEW_BID_FETCH_TRIP_PLAN_DATA:
      return action.data;
    case SISIBID_NEW_BID_RESET_TRIP_PLAN_DATA:
      return {};
    default:
      return state;
  }
};

const biddingTemplate = {
  title: "",
  request_status: "PE", // [PE, SE] Pending, Sent
  setup_status: "PR", // [PR, DO] In process, Done
  pricing_data: { FL: {}, ACC: {}, TF: {} },
  required_services: { FL: [], ACC: [], TF: [] },
  replaceable_services: { FL: [], ACC: [], TF: [] },
  replaceable_services_types: { FL: {}, ACC: {}, TF: {} },
  refundable_services: { FL: [], ACC: [], TF: [] },
  price_visibility: "TA", // [TA, TAP, NP] Target Price, Per Service, No Price
  service_comments: { FL: {}, ACC: {}, TF: {} },
  bidder_entities: [],
  required_time: {
    hours: 4,
    minutes: 0,
  },
  message: "",
};
export const newBiddingList = (state = [], action) => {
  const servicesUpdater = (key, state) => {
    return update(state, {
      $apply: (bids) =>
        bids.map((b) =>
          b.uid !== action.uid
            ? b
            : update(b, {
                [key]: {
                  [action.serviceType]: {
                    $apply: (orders) => {
                      if (action.actionType == "push") {
                        return [
                          ...new Set(
                            update(orders, { $push: [action.destOrder] })
                          ),
                        ].sort();
                      } else {
                        return [
                          ...new Set(
                            orders.filter((o) => o !== action.destOrder)
                          ),
                        ].sort();
                      }
                    },
                  },
                },
              })
        ),
    });
  };

  switch (action.type) {
    case SISIBID_BID_INSERT_LIST: {
      return update(state, {
        $push: [
          update(biddingTemplate, {
            uid: { $set: action.uid },
            required_services: { $set: action.required_services },
            replaceable_services_types: { $set: action.replaceableTypes },
          }),
        ],
      });
    }
    case SISIBID_BID_SET_BIDDING_LIST: {
      return update(state, { $set: action.data });
    }
    case SISIBID_BID_DROP_FROM_LIST: {
      return update(state, { $splice: [[action.data.index, 1]] });
    }
    case SISIBID_BID_UPDATE_BIDDING_STATUS: {
      return update(state, {
        $apply: (bids) =>
          bids.map((b) =>
            b.uid !== action.uid
              ? b
              : update(b, { setup_status: { $set: action.status } })
          ),
      });
    }
    case SISIBID_BID_UPDATE_SHOW_PRICE_TYPE: {
      return update(state, {
        $apply: (bids) =>
          bids.map((b) =>
            b.uid !== action.uid
              ? b
              : update(b, { price_visibility: { $set: action.priceType } })
          ),
      });
    }
    case SISIBID_BID_UPDATE_NEED_TIME: {
      return update(state, {
        [action.data.index]: { required_time: { $set: action.data.value } },
      });
    }
    case SISIBID_BID_UPDATE_MESSAGE: {
      return update(state, {
        [action.data.index]: { message: { $set: action.data.value } },
      });
    }
    case SISIBID_BID_MODIFY_LIST_TITLE: {
      return update(state, {
        $apply: (bids) =>
          bids.map((b) =>
            b.uid !== action.uid
              ? b
              : update(b, { title: { $set: action.title } })
          ),
      });
    }
    case SISIBID_BID_MODIFY_LIST_NEED_TIME: {
      return update(state, {
        [action.data.index]: { required_time: { $set: action.data.value } },
      });
    }
    case SISIBID_BID_MODIFY_LIST_STATUS: {
      return update(state, {
        [action.data.currentBiddingIndex]: {
          requested_services: {
            [action.data.index]: { checked: { $set: action.data.value } },
          },
        },
      });
    }
    case SISIBID_BID_SERVICE_PRICING_CHANGE: {
      const { value, uid, srvTypes, destOrder } = action;

      return update(state, {
        $apply: (bids) =>
          bids.map((b) => {
            if (b.uid !== uid) {
              return b;
            } else {
              return update(b, {
                pricing_data: {
                  $apply: (srvs) => {
                    var newSrvs = srvs;
                    srvTypes.forEach((s) => {
                      newSrvs = update(newSrvs, {
                        [s]: {
                          $apply: (dests) => {
                            if (_.isEmpty(dests)) {
                              return { [destOrder]: value || 0 };
                            } else {
                              return update(dests, {
                                [destOrder]: { $set: value || 0 },
                              });
                            }
                          },
                        },
                      });
                    });

                    return newSrvs;
                  },
                },
              });
            }
          }),
      });
    }
    case SISIBID_REQUIRED_SERVICES_CHANGE:
      return servicesUpdater("required_services", state);
    case SISIBID_REPLACEABLE_SERVICES_CHANGE:
      return servicesUpdater("replaceable_services", state);
    case SISIBID_REFUNDABLE_SERVICES_CHANGE:
      return servicesUpdater("refundable_services", state);
    case SISIBID_REPLACEABILITY_TYPE_CHANGE: {
      return update(state, {
        $apply: (bids) =>
          bids.map((b) => {
            if (b.uid !== action.uid) {
              return b;
            } else {
              return update(b, {
                replaceable_services_types: {
                  [action.serviceType]: {
                    [action.destOrder]: { $set: action.replaceabilityType },
                  },
                },
              });
            }
          }),
      });
    }
    case SISIBID_ADD_SERVICE_COMMENT:
      return update(state, {
        $apply: (bids) =>
          bids.map((b) =>
            b.uid !== action.uid
              ? b
              : update(b, {
                  service_comments: {
                    [action.serviceType]: {
                      [action.destOrder]: {
                        $apply: (comments) =>
                          comments
                            ? update(comments, { $push: [action.comment] })
                            : [action.comment],
                      },
                    },
                  },
                })
          ),
      });
    case SISIBID_REMOVE_SERVICE_COMMENT:
      return update(state, {
        $apply: (bids) =>
          bids.map((b) =>
            b.uid !== action.uid
              ? b
              : update(b, {
                  service_comments: {
                    [action.serviceType]: {
                      [action.destOrder]: {
                        $apply: (comments) =>
                          comments.filter((c, idx) => idx !== action.idx),
                      },
                    },
                  },
                })
          ),
      });
    case SISIBID_SELECT_BIDDER:
      return update(state, {
        $apply: (bids) =>
          bids.map((b) =>
            b.uid !== action.uid
              ? b
              : update(b, {
                  bidder_entities: {
                    $apply: (bidder_entities) => {
                      if (action.actionType == "push") {
                        return update(bidder_entities, {
                          $push: [action.bidder],
                        });
                      } else {
                        return update(bidder_entities, {
                          $apply: (list) =>
                            list.filter(
                              (l) =>
                                l.id !== action.bidder.id &&
                                l.bidderType == action.bidder.bidderType
                            ),
                        });
                      }
                    },
                  },
                })
          ),
      });
    case SISIBID_SELECT_ALL_BIDDERS:
      return update(state, {
        $apply: (bids) =>
          bids.map((b) =>
            b.uid !== action.uid
              ? b
              : update(b, { bidder_entities: { $set: action.bidder_entities } })
          ),
      });
    case SISIBID_UNSELECT_ALL_BIDDERS:
      return update(state, {
        $apply: (bids) =>
          bids.map((b) =>
            b.uid !== action.uid
              ? b
              : update(b, { bidder_entities: { $set: [] } })
          ),
      });
    case SISIBID_REQUESTS_DATA_UPDATE:
      return action.bid_requests;
    case SISIBID_REQUEST_DATA_UPDATE:
      return update(state, {
        $apply: (biddings) =>
          biddings.map((b) =>
            b.uid !== action.bidding.uid ? b : action.bidding
          ),
      });
    default:
      return state;
  }
};

export const newBiddingTargetPriceMode = (state = "overview", action) => {
  switch (action.type) {
    case SISIBID_NEW_BID_TOGGLE_TARGET_PRICE_MODE:
      return action.value;
    default:
      return state;
  }
};

export const newBiddingCurrentBiddingUid = (state = "", action) => {
  switch (action.type) {
    case SISIBID_CHANGE_CURRENT_BID_UID:
      return action.uid;
    default:
      return state;
  }
};

export const newBiddingCurrentStep = (state = 1, action) => {
  switch (action.type) {
    case SISIBID_GOTO_NEXT_STEP:
      return action.currentStep + 1;
    case SISIBID_GOTO_PREV_STEP:
      return action.currentStep - 1;
    default:
      return state;
  }
};

const serviceFiltersInitial = {
  extremal: [],
  destinations: [],
  service_types: [],
  add_ons: [],
};
export const newBiddingServiceFilters = (
  state = serviceFiltersInitial,
  action
) => {
  switch (action.type) {
    case SISIBID_SERVICE_FILTERS_CHANGE:
      return update(state, { [action.name]: { $set: action.value } });
    case SISIBID_SERVICE_FILTERS_RESET:
      return serviceFiltersInitial;
    default:
      return state;
  }
};

const newBiddingFlightDetailsInitial = { show: false, flOrder: "extremal" };
export const newBiddingFlightDetails = (
  state = newBiddingFlightDetailsInitial,
  action
) => {
  switch (action.type) {
    case SISIBID_VIEW_FLIGHT_DETAILS:
      return { show: true, flOrder: action.flOrder };
    case SISIBID_HIDE_FLIGHT_DETAILS:
      return newBiddingFlightDetailsInitial;
    default:
      return state;
  }
};

const newBiddingAccDetailsInitial = { show: false, destOrder: 0 };
export const newBiddingAccDetails = (
  state = newBiddingAccDetailsInitial,
  action
) => {
  switch (action.type) {
    case SISIBID_VIEW_ACC_DETAILS:
      return { show: true, destOrder: action.destOrder };
    case SISIBID_HIDE_ACC_DETAILS:
      return newBiddingAccDetailsInitial;
    default:
      return state;
  }
};

const newBiddingTrfDetailsInitial = {
  show: false,
  destOrder: 0,
  transfer_type: "",
};
export const newBiddingTrfDetails = (
  state = newBiddingTrfDetailsInitial,
  action
) => {
  switch (action.type) {
    case SISIBID_VIEW_TRF_DETAILS:
      return {
        show: true,
        destOrder: action.destOrder,
        transfer_type: action.transfer_type,
      };
    case SISIBID_HIDE_TRF_DETAILS:
      return newBiddingTrfDetailsInitial;
    default:
      return state;
  }
};

export const newBiddingTransportationType = (state = "A", action) => {
  switch (action.type) {
    case SISIBID_UPDATE_TRANSPORTATION_TYPE:
      return update(state, { $set: action.data });
    default:
      return state;
  }
};

const biddingRequestSaveModalInitial = { show: false, loading: false };
export const biddingRequestSaveModal = (
  state = biddingRequestSaveModalInitial,
  action
) => {
  switch (action.type) {
    case SISIBID_REQUEST_SAVE_TRIP:
      return update(state, { show: { $set: true }, loading: { $set: false } });
    case SISIBID_CANCEL_SAVE_REQUEST:
      return biddingRequestSaveModalInitial;
    case SISIBID_SAVING_BID:
      return update(state, { loading: { $set: true } });
    case SISIBID_SAVED_BID:
      return biddingRequestSaveModalInitial;
    default:
      return state;
  }
};

export const newBiddingFacility = (state = {}, action) => {
  switch (action.type) {
    case FETCHED_BIDDING_FACILITY:
      return update(state, { $set: action.data });
    default:
      return state;
  }
};

const biddingRequestSendModalInitial = { show: false, loading: false, uid: "" };
export const biddingRequestSendModal = (
  state = biddingRequestSendModalInitial,
  action
) => {
  switch (action.type) {
    case SISIBID_REQUEST_SEND_BID_REQUEST:
      return update(state, {
        show: { $set: true },
        loading: { $set: false },
        uid: { $set: action.uid },
      });
    case SISIBID_CANCEL_SEND_BID_REQUEST:
      return biddingRequestSendModalInitial;
    case SISIBID_SEND_BID_REQUEST:
      return update(state, { loading: { $set: true } });
    case SISIBID_SENT_BID_REQUEST:
      return biddingRequestSendModalInitial;
    default:
      return state;
  }
};

export const newBiddingOffers = (state = [], action) => {
  switch (action.type) {
    case SISIBID_INIT:
      return [];
    case SISIBID_SAVE_OFFERS:
      return action.offers;
    default:
      return state;
  }
};

// "best_per_offer" "best_per_service", "offer_list", "selected_services"
const newBiddingFlowViewTypeInitial = { view_type: "offer_list" };
export const newBiddingFlowViewType = (
  state = newBiddingFlowViewTypeInitial,
  action
) => {
  switch (action.type) {
    case SISIBID_INIT:
      return newBiddingFlowViewTypeInitial;
    case SISIBID_CHANGE_CURRENT_BID_UID:
      return newBiddingFlowViewTypeInitial;
    case SISIBID_CHANGE_FLOW_VIEW_TYPE:
      return update(state, { view_type: { $set: action.view_type } });
    default:
      return state;
  }
};

const newBiddingCompareOfferInitial = { offer_id: null };
export const newBiddingCompareOffer = (
  state = newBiddingCompareOfferInitial,
  action
) => {
  switch (action.type) {
    case SISIBID_INIT:
      return newBiddingCompareOfferInitial;
    case SISIBID_COMPARE_OFFER:
      return update(state, { offer_id: { $set: action.offer_id } });
    case SISIBID_RESET_COMPARE_OFFER:
      return newBiddingCompareOfferInitial;
    case SISIBID_CHANGE_CURRENT_BID_UID:
      return newBiddingCompareOfferInitial;
    default:
      return state;
  }
};

const newBiddingWaitModalInitial = {
  show: false,
  msg: "",
  title: "Please Wait...",
};
export const newBiddingWaitModal = (
  state = newBiddingWaitModalInitial,
  action
) => {
  const { type, title, msg } = action;

  switch (type) {
    case SISIBID_INIT:
      return newBiddingWaitModalInitial;
    case SISIBID_SHOW_WAIT_MODAL:
      return update(state, {
        show: { $set: true },
        title: { $set: title || "Please wait..." },
        msg: { $set: msg || "Please wait..." },
      });
    case SISIBID_HIDE_WAIT_MODAL:
      return newBiddingWaitModalInitial;
    default:
      return state;
  }
};

export const newBiddingSelectedOffers = (state = {}, action) => {
  switch (action.type) {
    case SISIBID_INIT:
      return {};
    case SISIBID_SELECT_FULL_OFFER: {
      const { offer_id, request_uid } = action;
      return update(state, {
        [request_uid]: {
          $apply: (offer) => {
            const tmp = {};
            Object.entries(offer).forEach((e) => {
              tmp[e[0]] = {};
              Object.entries(e[1]).forEach((ee) => {
                tmp[e[0]][ee[0]] = offer_id;
              });
            });
            return tmp;
          },
        },
      });
    }
    case SISIBID_DESELECT_FULL_OFFER: {
      const { request_uid } = action;
      return update(state, {
        [request_uid]: {
          $apply: (offer) => {
            const tmp = {};
            Object.entries(offer).forEach((e) => {
              tmp[e[0]] = {};
              Object.entries(e[1]).forEach((ee) => {
                tmp[e[0]][ee[0]] = null;
              });
            });
            return tmp;
          },
        },
      });
    }
    case SISIBID_INIT_SELECTED_OFFERS: {
      const { request_uid, required_services } = action;

      return update(state, {
        [request_uid]: {
          $apply: () => {
            const tmp = {};
            Object.entries(required_services).forEach((e) => {
              tmp[e[0]] = {};
              e[1].forEach((d) => (tmp[e[0]][d] = {}));
            });
            return tmp;
          },
        },
      });
    }
    case SISIBID_SELECT_OFFER: {
      const { service_type, destOrder, request_uid, offer_id } = action;
      return update(state, {
        [request_uid]: { [service_type]: { [destOrder]: { $set: offer_id } } },
      });
    }
    case SISIBID_DESELECT_OFFER: {
      const { service_type, destOrder, request_uid } = action;
      return update(state, {
        [request_uid]: { [service_type]: { [destOrder]: { $set: null } } },
      });
    }
    default:
      return state;
  }
};
