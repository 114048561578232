import _ from "lodash";
import { Loader } from "rsuite";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Form, Formik } from "formik";
import { NormalSelectField } from "@src/components/forms";
import CoachAdhocSrv from "./products/addhoc/coach";

import { connect } from "react-redux";
import { addTrpAdhocSrv } from "@src/actions/Project/TripPlanner/addhoc/transportation";
import { getSetupFormDataSelector } from "@src/selectors/Project/TripPlanner";
import { FerryAdhocSrvForm } from "./products/addhoc/ferry";
import { TrainAdhocSrvForm } from "./products/addhoc/train";

export const addhocTrpTypes = {
  // FL: "Flight",
  COA: "Coach",
  FER: "Ferry",
  TRA: "Train",
};

const AddAdhocTrpServiceModal = ({
  adults,
  childs,
  destination_dest,
  origin_dest,
  onCancel,
  onAdd,
}) => {
  const [loading, __] = useState(false);
  const [serviceType, setServiceType] = useState(null);
  const [step, setStep] = useState(1);

  const initialValues = { service_type: null };

  return (
    <div className="Modal">
      {loading && <Loader size="lg" center />}
      {step === 1 && (
        <div className="Modal__card">
          <div className="Modal__card__header">
            <h5>Add Ad hoc Transportation Service</h5>
          </div>
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
              setServiceType(values.service_type);
              setStep(2);
            }}>
            {({ submitForm }) => (
              <React.Fragment>
                <div className="Modal__card__body">
                  <Form className="Form">
                    <NormalSelectField
                      name="service_type"
                      label="Service Type"
                      options={[
                        [null, "------"],
                        ...Object.entries(addhocTrpTypes),
                      ]}
                    />
                  </Form>
                </div>
                <div className="Modal__card__actions">
                  <button
                    className="Button"
                    data-ghost="true"
                    onClick={onCancel}>
                    Cancel
                  </button>
                  <button
                    className="Button"
                    onClick={(e) => {
                      e.preventDefault();
                      submitForm();
                    }}>
                    Next
                  </button>
                </div>
              </React.Fragment>
            )}
          </Formik>
        </div>
      )}
      {step === 2 && serviceType === "COA" && (
        <CoachAdhocSrv
          adults={adults}
          childs={childs}
          destination_dest={destination_dest}
          origin_dest={origin_dest}
          onCancel={onCancel}
          onSelect={(service) => {
            onAdd({
              origin_order: _.get(origin_dest, "order"),
              destination_order: _.get(destination_dest, "order"),
              service,
              srvType: "AD",
              addhoc_service_type: "COA",
            });
            onCancel();
          }}
        />
      )}
      {step === 2 && serviceType === "TRA" && (
        <TrainAdhocSrvForm
          adults={adults}
          childs={childs}
          destination_dest={destination_dest}
          origin_dest={origin_dest}
          onCancel={onCancel}
          onSelect={(service) => {
            onAdd({
              origin_order: _.get(origin_dest, "order"),
              destination_order: _.get(destination_dest, "order"),
              service,
              srvType: "AD",
              addhoc_service_type: "TRA",
            });
            onCancel();
          }}
        />
      )}
      {step === 2 && serviceType === "FER" && (
        <FerryAdhocSrvForm
          adults={adults}
          childs={childs}
          destination_dest={destination_dest}
          origin_dest={origin_dest}
          onCancel={onCancel}
          onSelect={(service) => {
            onAdd({
              origin_order: _.get(origin_dest, "order"),
              destination_order: _.get(destination_dest, "order"),
              service,
              srvType: "AD",
              addhoc_service_type: "FER",
            });
            onCancel();
          }}
        />
      )}
    </div>
  );
};
AddAdhocTrpServiceModal.defaultProps = {
  adults: 1,
  childs: 0,
  origin_dest: {},
  destination_dest: {},
};
AddAdhocTrpServiceModal.propTypes = {
  adults: PropTypes.number.isRequired,
  childs: PropTypes.number.isRequired,
  origin_dest: PropTypes.object.isRequired,
  destination_dest: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  const { adults, children: childs } = getSetupFormDataSelector(state);
  return { adults, childs };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onAdd: ({
      origin_order,
      destination_order,
      service,
      srvType,
      addhoc_service_type,
    }) =>
      dispatch(
        addTrpAdhocSrv({
          origin_order,
          destination_order,
          service,
          srvType,
          addhoc_service_type,
        })
      ),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddAdhocTrpServiceModal);
