import {
  // ===================== PAYMENT METHODS MODAL =====================
  PAYMENT_METHODS_SHOW_ADD_METHODS_MODAL,
  PAYMENT_METHODS_HIDE_ADD_METHODS_MODAL,
  PAYMENT_METHODS_LOADING_ADD_METHODS_MODAL,
  PAYMENT_METHODS_IDLE_ADD_METHODS_MODAL,
  PAYMENT_METHODS_CHANGE_PAYMENT_METHODS,
  PAYMENT_METHODS_GET_PAYMENT_METHODS_LIST,
  PAYMENT_METHODS_BEGIN_LIST,
  PAYMENT_METHODS_END_LIST,
  PAYMENT_METHODS_SHOW_PAYMENT_MODAL,
  PAYMENT_METHODS_HIDE_PAYMENT_MODAL,
  PAYMENT_METHODS_LOADING_EDIT_METHODS_MODAL,
  PAYMENT_METHODS_IDLE_EDIT_METHODS_MODAL,
  PAYMENT_METHODS_GET_STRIPE_INIT_DATA,
  PAYMENT_METHODS_LOADING_GET_STRIPE_INIT_DATA,
  PAYMENT_METHODS_IDLE_GET_STRIPE_INIT_DATA,
} from "./types";

import {
  fetchPaymentMethodList,
  setDefaultPaymentMethod,
  detachPaymentMethod,
  getStripeInitData,
} from "@src/api";
import { getPagination } from "@src/tools/form_tools";
import { errorHandle as commonErrorHandle } from "@src/tools/common_tools";
import update from "immutability-helper";
import { loadStripe } from "@stripe/stripe-js";

import { notifyCommonError } from "@src/components/common/notifications/CommonErrorNotification.js";
import { notifyCommonSuccess } from "@src/components/common/notifications/CommonSuccessNotification.js";

export const showAddMethodsModal = () => {
  return { type: PAYMENT_METHODS_SHOW_ADD_METHODS_MODAL };
};

export const hideAddMethodsModal = () => {
  return { type: PAYMENT_METHODS_HIDE_ADD_METHODS_MODAL };
};

export const loadingAddMethodsModal = () => {
  return { type: PAYMENT_METHODS_LOADING_ADD_METHODS_MODAL };
};

export const idleAddMethodsModal = () => {
  return { type: PAYMENT_METHODS_IDLE_ADD_METHODS_MODAL };
};

export const handleChangePaymentMethods = (value) => {
  return { type: PAYMENT_METHODS_CHANGE_PAYMENT_METHODS, data: value };
};

export const showMessageModal = (id, modalType) => {
  return {
    type: PAYMENT_METHODS_SHOW_PAYMENT_MODAL,
    id,
    modalType,
  };
};

export const hideEditMethodsModal = () => {
  return {
    type: PAYMENT_METHODS_HIDE_PAYMENT_MODAL,
  };
};

export const loadingEditMethodsModal = () => {
  return { type: PAYMENT_METHODS_LOADING_EDIT_METHODS_MODAL };
};

export const idleEditMethodsModal = () => {
  return { type: PAYMENT_METHODS_IDLE_EDIT_METHODS_MODAL };
};

export const beginPaymentMethodsList = () => {
  return { type: PAYMENT_METHODS_BEGIN_LIST };
};

export const endPaymentMethodsList = () => {
  return { type: PAYMENT_METHODS_END_LIST };
};

export const getPaymentMethodsList = () => async (dispatch, getState) => {
  await dispatch(beginPaymentMethodsList());
  const pagination = getPagination(getState().paymentMethodsList);

  try {
    const data = await fetchPaymentMethodList({
      ...pagination,
    });
    dispatch(
      getPaymentMethodsData({
        page: pagination.page,
        limit: pagination.limit,
        ...data,
      })
    );
  } catch (error) {
    console.log(error);
    commonErrorHandle({ error });
  } finally {
    dispatch(endPaymentMethodsList());
  }
};

export const getPaymentMethodsData = (data) => {
  return {
    type: PAYMENT_METHODS_GET_PAYMENT_METHODS_LIST,
    data: data,
  };
};

export const setDefaultPaymentMethodData = (data) => async (dispatch) => {
  dispatch(loadingEditMethodsModal());
  try {
    await setDefaultPaymentMethod(data);
    notifyCommonSuccess("Successful update a default payment method");
    dispatch(getPaymentMethodsList());
  } catch (error) {
    notifyCommonError(
      "We failed to update your payment method. We are sorry for the inconvenience."
    );
  } finally {
    dispatch(hideEditMethodsModal());
    dispatch(idleEditMethodsModal());
  }
};

export const deletePaymentMethodData = (data) => async (dispatch) => {
  dispatch(loadingEditMethodsModal());
  try {
    await detachPaymentMethod(data);
    dispatch(getPaymentMethodsList());
    notifyCommonSuccess("Successful delete a payment method");
  } catch (error) {
    notifyCommonError(
      "We failed to delete your payment method. We are sorry for the inconvenience."
    );
  } finally {
    dispatch(hideEditMethodsModal());
    dispatch(idleEditMethodsModal());
  }
};

export const modifyPaymentMethodsPage = (page) => (dispatch, getState) => {
  const state = getState();
  const paymentMethodsList = update(state.paymentMethodsList, {
    page: { $set: page },
  });
  dispatch(
    getPaymentMethodsData({
      ...paymentMethodsList,
    })
  );
  dispatch(getPaymentMethodsList());
};

export const modifyPaymentMethodsLimit = (limit) => (dispatch, getState) => {
  const state = getState();
  const paymentMethodsList = update(state.paymentMethodsList, {
    limit: { $set: limit },
    page: { $set: 1 },
  });
  dispatch(
    getPaymentMethodsData({
      ...paymentMethodsList,
    })
  );
  dispatch(getPaymentMethodsList());
};

export const fetchStripeInitInfo = () => async (dispatch) => {
  await dispatch(loadingGetStripeInitData());
  try {
    const data = await getStripeInitData();
    const stripe = await loadStripe(data.publish_api);
    dispatch(getStripeInitInfo(stripe));
  } catch (error) {
    console.log(error);
    commonErrorHandle({ error });
  } finally {
    dispatch(idleGetStripeInitData());
  }
};

export const getStripeInitInfo = (data) => {
  return {
    type: PAYMENT_METHODS_GET_STRIPE_INIT_DATA,
    data,
  };
};

export const loadingGetStripeInitData = () => {
  return {
    type: PAYMENT_METHODS_LOADING_GET_STRIPE_INIT_DATA,
  };
};

export const idleGetStripeInitData = () => {
  return {
    type: PAYMENT_METHODS_IDLE_GET_STRIPE_INIT_DATA,
  };
};
