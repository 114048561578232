export const BIDDING_TRIPS_INITIALIZE_BASIC_FILTER_DATA =
  "BIDDING_TRIPS_INITIALIZE_BASIC_FILTER_DATA";

export const BIDDING_TRIPS_EDIT_BASIC_FILTER_DATA_FORM =
  "BIDDING_TRIPS_EDIT_BASIC_FILTER_DATA_FORM";

export const BIDDING_TRIPS_CHANGE_VIEW_MODEL =
  "BIDDING_TRIPS_CHANGE_VIEW_MODEL";
export const BIDDING_TRIPS_INITIALIZE_LIST_COLUMNS_FORM =
  "BIDDING_TRIPS_INITIALIZE_LIST_COLUMNS_FORM";
export const BIDDING_TRIPS_APPLY_LIST_COLUMNS =
  "BIDDING_TRIPS_APPLY_LIST_COLUMNS";
export const BIDDING_TRIPS_UPDATE_LIST_FILTER_FORM =
  "BIDDING_TRIPS_UPDATE_LIST_FILTER_FORM";
export const BIDDING_TRIPS_APPLY_LIST_FILTER =
  "BIDDING_TRIPS_APPLY_LIST_FILTER";
export const BIDDING_TRIPS_INITIALIZE_LIST_FILTER_FORM =
  "BIDDING_TRIPS_INITIALIZE_LIST_FILTER_FORM";
export const BIDDING_TRIPS_RESET_LIST_FILTER_FORM =
  "BIDDING_TRIPS_RESET_LIST_FILTER_FORM";
export const TOGGLE_ARCHIVE_MAP_VIEW = "TOGGLE_ARCHIVE_MAP_VIEW";
export const BIDDING_TRIPS_BEGIN_FETCH_DATA = "BIDDING_TRIPS_BEGIN_FETCH_DATA";
export const BIDDING_TRIPS_END_FETCH_DATA = "BIDDING_TRIPS_END_FETCH_DATA";
export const BIDDING_TRIPS_FETCH_DATA = "BIDDING_TRIPS_FETCH_DATA";
export const BIDDING_TRIPS_CHANGE_PRICE_STATUS =
  "BIDDING_TRIPS_CHANGE_PRICE_STATUS";
export const BIDDING_TRIPS_FETCH_GRAPH_DATA = "BIDDING_TRIPS_FETCH_GRAPH_DATA";
