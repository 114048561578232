import _ from "lodash";
import { Button } from "rsuite";
import { modalGenericStyles, variables } from "@src/jsssetup";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { createUseStyles } from "react-jss";
import { Form, Formik } from "formik";
import { NormalInputField, NormalSelectField } from "@src/components/forms";
import { useDispatch, useSelector } from "react-redux";
import { currencies } from "@src/definitions";
import { updateDbDCustomSrv } from "@src/actions/Project/TripPlanner/day_by_day/custom_services";

const styles = createUseStyles({
  EditGeneralServiceModal: modalGenericStyles.modal,
  card: { ...modalGenericStyles.card, minWidth: "35vw" },
  header: modalGenericStyles.cardHeader,
  body: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    gridGap: variables.normal_gap,
    gridAutoRows: "max-content",
  },
  actions: modalGenericStyles.cardActions,
});
const EditGeneralServiceModal = ({ dayIdx, srv_id, onCancel }) => {
  const classes = styles();

  const service = useSelector((state) =>
    _.get(state.tripPlannerDbDCustomSrvData, dayIdx, []).find(
      (srv) => srv.service_type === "GEN" && srv.id === srv_id
    )
  );

  const dispatch = useDispatch();
  const updateService = useCallback(
    ({ data }) =>
      dispatch(updateDbDCustomSrv({ dayIdx, srv_id, srv_type: "GEN", data })),
    [dispatch]
  );

  return (
    <div className={classes.EditGeneralServiceModal}>
      <div className={classes.card}>
        <div className={classes.header}>
          <h5>Edit General Service</h5>
        </div>
        <Formik
          initialValues={{
            total_price: _.get(
              service,
              "service_data.price_data.total_price",
              0
            ),
            duration: _.get(service, "service_data.duration", 0),
            currency: _.get(service, "service_data.price_data.currency"),
            remarks: _.get(service, "service_data.remarks", ""),
          }}
          onSubmit={(values) => {
            const data = _.cloneDeep(service);
            data.service_data.price_data.total_price = values.total_price;
            data.service_data.price_data.currency = values.currency;
            if (
              values.total_price !== service.service_data.total_price.amount
            ) {
              data.service_data.price_data.original_price_edited = true;
            }

            if (values.currency !== service.service_data.total_price.currency) {
              data.service_data.price_data.original_price_edited = true;
            }

            data.service_data.duration = values.duration;

            data.service_data.remarks = values.remarks;
            if (data.service_data.pricing.length) {
              data.service_data.pricing[0].remarks = values.remarks;
            }

            updateService({ data });
            onCancel();
          }}
        >
          <Form>
            <React.Fragment>
              <div className={classes.body}>
                <NormalInputField
                  name="total_price"
                  label="Price"
                  type="number"
                />
                <NormalSelectField
                  name="currency"
                  label="Currency"
                  options={_.sortBy(
                    Object.entries(currencies).map((entry) => [
                      entry[0],
                      `${entry[0]} (${entry[1]})`,
                    ]),
                    (v) => v[0]
                  )}
                />
                <NormalInputField
                  name="duration"
                  label="Duration (minutes)"
                  type="number"
                />
                <NormalInputField
                  name="remarks"
                  label="Remarks"
                  as="textarea"
                />
              </div>
              <div className={classes.actions}>
                <Button appearance="ghost" onClick={onCancel}>
                  <strong>Cancel</strong>
                </Button>
                <Button appearance="primary" type="submit">
                  <strong>Next</strong>
                </Button>
              </div>
            </React.Fragment>
          </Form>
        </Formik>
      </div>
    </div>
  );
};
EditGeneralServiceModal.defaultProps = {
  commonDestDays: [],
  destination_ids: [],
};
EditGeneralServiceModal.propTypes = {
  srv_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dayIdx: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default EditGeneralServiceModal;
