// ============================ SELECTORS ============================
import { convertAmountSelector } from "@src/selectors/Financial";

import update from "immutability-helper";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";
import { DateTime } from "luxon";

const CancellationPolicyDetails = ({ cxl_policies, isDisabled = false }) => {
  function renderCxl(idx, cxl) {
    const cxlFrom = DateTime.fromISO(cxl.from).toLocaleString(
      DateTime.DATETIME_MED_WITH_WEEKDAY
    );
    const cxlTo = DateTime.fromISO(cxl.to).toLocaleString(
      DateTime.DATETIME_MED_WITH_WEEKDAY
    );

    const fee = cxl.fee.toLocaleString("en-US", {
      style: "currency",
      currency: cxl.currency,
    });

    return (
      <span key={idx}>
        From {cxlFrom} to {cxlTo}{" "}
        {cxl.type === "chargeable" ? (
          <span className="CancellationPolicyDetails__non-refundable">
            cancellation is chargeable with <strong>{fee}</strong>
          </span>
        ) : (
          <span className="CancellationPolicyDetails__refundable">
            cancellation is refundable.
          </span>
        )}
      </span>
    );
  }

  return (
    <div
      className="CancellationPolicyDetails"
      data-disabled={isDisabled ? "true" : "false"}>
      {cxl_policies.map((c, idx) => {
        if (c.type == "non-refundable") {
          return (
            <span
              key={idx}
              className="CancellationPolicyDetails__non-refundable">
              Cancellation is Non Refundable
            </span>
          );
        } else {
          return renderCxl(idx, c);
        }
      })}
    </div>
  );
};
CancellationPolicyDetails.propTypes = {
  cxl_policies: PropTypes.array.isRequired,
};
const mapStateToProps = (state, ownProps) => {
  var { cxl_policies } = ownProps;

  cxl_policies = cxl_policies.map((c) => {
    const convertedFee = convertAmountSelector(state, { amount: c.fee });

    return update(c, {
      currency: { $set: convertedFee.currency },
      fee: { $set: convertedFee.convertedAmount },
    });
  });

  return { cxl_policies };
};
export default connect(mapStateToProps, null)(CancellationPolicyDetails);
