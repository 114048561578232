import axios from "axios";
import { getAuthHeaders } from "../request";

const sisi_api_url = process.env.REACT_APP_SISI_GATEWAY;
const sisi2_api_url = process.env.REACT_APP_SISI2_GATEWAY;

export const saveInvoice = async (payload) => {
  const auth_headers = getAuthHeaders();
  try {
    const response = await axios.post(
      `${sisi2_api_url}/paymentengine/invoice/`,
      payload,
      { headers: { ...auth_headers } }
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

// TODO: this should be removed and use the createDocument instead.
export const saveInvoiceDocument = async (payload) => {
  try {
    const response = await axios.post(
      `${sisi_api_url}/reviewer/entity/document/`,
      payload,
      { headers: { "Content-Type": "multipart/form-data" } }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
  return null;
};
