export const acc_reservation_status = {
  CO: "confirmed",
  RQ: "on_request",
  PE: "pending",
  CA: "cancelled",
  FA: "Failed",
};

export const acc_reservation_status_color_mapping = {
  "CO": "green",
  "RQ": "yellow",
  "PE": "violet",
  "CA": "orange",
  "FA": "red",
  "N/A": "red",
};
