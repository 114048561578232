// //=============================LIST VIEW===========================
// export const VENDORS_TRANSFER_BEGIN_FETCH_DATA =
//   "VENDORS_TRANSFER_BEGIN_FETCH_DATA";
// export const VENDORS_TRANSFER_END_FETCH_DATA =
//   "VENDORS_TRANSFER_END_FETCH_DATA";
// export const VENDORS_TRANSFER_FETCH_DATA = "VENDORS_TRANSFER_FETCH_DATA";
// export const VENDORS_TRANSFER_FETCH_OPTIONS = "VENDORS_TRANSFER_FETCH_OPTIONS";
// //=============================FILTER RELATIONS========================
// export const VENDORS_TRANSFER_INITIALIZE_LIST_FILTER_FORM =
//   "VENDORS_TRANSFER_INITIALIZE_LIST_FILTER_FORM";
// export const VENDORS_TRANSFER_UPDATE_LIST_FILTER_FORM =
//   "VENDORS_TRANSFER_UPDATE_LIST_FILTER_FORM";
// export const VENDORS_TRANSFER_APPLY_LIST_FILTER =
//   "VENDORS_TRANSFER_APPLY_LIST_FILTER";
// //=============================COLUMNS RELATIONS=========================
// export const VENDORS_TRANSFER_APPLY_LIST_COLUMNS =
//   "VENDORS_TRANSFER_APPLY_LIST_COLUMNS";
// export const VENDORS_TRANSFER_INITIALIZE_LIST_COLUMNS_FORM =
//   "VENDORS_TRANSFER_INITIALIZE_LIST_COLUMNS_FORM";

// //================================ADD VENDORS_TRANSFER========================
// export const VENDORS_TRANSFER_BEGIN_ADD = "VENDORS_TRANSFER_BEGIN_ADD";
// export const VENDORS_TRANSFER_END_ADD = "VENDORS_TRANSFER_END_ADD";
// export const VENDORS_TRANSFER_IDLE_ADD = "VENDORS_TRANSFER_IDLE_ADD";
// export const VENDORS_TRANSFER_SHOW_BASE_FORM_MODAL =
//   "VENDORS_TRANSFER_SHOW_BASE_FORM_MODAL";
// export const VENDORS_TRANSFER_HIDE_BASE_FORM_MODAL =
//   "VENDORS_TRANSFER_HIDE_BASE_FORM_MODAL";
// export const VENDORS_TRANSFER_EDIT_BASE_FORM_MODAL =
//   "VENDORS_TRANSFER_EDIT_BASE_FORM_MODAL";
// //================================EDIT===========================
// export const VENDORS_TRANSFER_INITIALIZE_BASE_DATA_EDIT_FORM =
//   "VENDORS_TRANSFER_INITIALIZE_BASE_DATA_EDIT_FORM";
// export const VENDORS_TRANSFER_FETCH_EDIT_DATA =
//   "VENDORS_TRANSFER_FETCH_EDIT_DATA";
// export const VENDORS_TRANSFER_INIT_BASE_DATA_EDIT_FORM =
//   "VENDORS_TRANSFER_INIT_BASE_DATA_EDIT_FORM";
// export const VENDORS_TRANSFER_UPDATE_BASE_DATA_FORM =
//   "VENDORS_TRANSFER_UPDATE_BASE_DATA_FORM";
// export const VENDORS_TRANSFER_APPLY_BASE_DATA_FORM =
//   "VENDORS_TRANSFER_APPLY_BASE_DATA_FORM";
// export const VENDORS_TRANSFER_INITIALIZE_BASE_DATA_FORM =
//   "VENDORS_TRANSFER_INITIALIZE_BASE_DATA_FORM";
// export const VENDORS_TRANSFER_DATA_FORM = "VENDORS_TRANSFER_DATA_FORM";
// export const VENDORS_TRANSFER_INIT_COLUMNS_BASE_FORM =
//   "VENDORS_TRANSFER_INIT_COLUMNS_BASE_FORM";
// export const VENDORS_TRANSFER_INIT_FORM = "VENDORS_TRANSFER_INIT_FORM";
// export const VENDORS_TRANSFER_BEGIN_FETCH_DATA_FORM =
//   "VENDORS_TRANSFER_BEGIN_FETCH_DATA_FORM";
// export const VENDORS_TRANSFER_END_FETCH_DATA_FORM =
//   "VENDORS_TRANSFER_END_FETCH_DATA_FORM";
// export const VENDORS_TRANSFER_BEGIN_FETCH_EDIT_DATA =
//   "VENDORS_TRANSFER_BEGIN_FETCH_EDIT_DATA";
// export const VENDORS_TRANSFER_END_FETCH_EDIT_DATA =
//   "VENDORS_TRANSFER_END_FETCH_EDIT_DATA";
//================================NEW REFACTORED===========================
export const VENDORS_TRANSFERS_LIST_SAVE_RESULTS =
  "VENDORS_TRANSFERS_LIST_SAVE_RESULTS";
export const VENDORS_TRANSFERS_LIST_GENERIC_CHANGE =
  "VENDORS_TRANSFERS_LIST_GENERIC_CHANGE";
