export const GROUP_POLICY_FETCH_DATA = "GROUP_POLICY_FETCH_DATA";
export const GROUP_POLICY_BEGIN_FETCH_DATA = "GROUP_POLICY_BEGIN_FETCH_DATA";
export const GROUP_POLICY_END_FETCH_DATA = "GROUP_POLICY_END_FETCH_DATA";
export const GROUP_POLICY_INITIALIZE_LIST_FILTER_FORM =
  "GROUP_POLICY_INITIALIZE_LIST_FILTER_FORM";
export const GROUP_POLICY_UPDATE_LIST_FILTER_FORM =
  "GROUP_POLICY_UPDATE_LIST_FILTER_FORM";
export const GROUP_POLICY_APPLY_LIST_FILTER = "GROUP_POLICY_APPLY_LIST_FILTER";
export const GROUP_POLICY_UPDATE_LIST_COLUMNS_FORM =
  "GROUP_POLICY_UPDATE_LIST_COLUMNS_FORM";
export const GROUP_POLICY_APPLY_LIST_COLUMNS =
  "GROUP_POLICY_APPLY_LIST_COLUMNS";
export const GROUP_POLICY_INITIALIZE_LIST_COLUMNS_FORM =
  "GROUP_POLICY_INITIALIZE_LIST_COLUMNS_FORM";
export const GROUP_POLICY_FETCH_OPTIONS = "GROUP_POLICY_FETCH_OPTIONS";
export const GROUP_POLICY_CLEAN_BASE_DATA_EDIT_FORM =
  "GROUP_POLICY_CLEAN_BASE_DATA_EDIT_FORM";

//DEPRECATED: Use the GROUP_POLICY_EDIT_CHANGE
export const GROUP_POLICY_EDIT_BASE_DATA = "GROUP_POLICY_EDIT_BASE_DATA";

export const GROUP_POLICY_EDIT_CHANGE = "GROUP_POLICY_EDIT_CHANGE";
export const GROUP_POLICY_VALIDATE_BASE_DATA_EDIT_FORM =
  "GROUP_POLICY_VALIDATE_BASE_DATA_EDIT_FORM";
export const GROUP_POLICY_RESET_LIST_FILTER_FORM =
  "GROUP_POLICY_RESET_LIST_FILTER_FORM";
export const FORMAT_BRANCH_LIST_DATA = "FORMAT_BRANCH_LIST_DATA";
export const FORMAT_SUB_AGENT_LIST_DATA = "FORMAT_SUB_AGENT_LIST_DATA";
export const FORMAT_INDEPENDENT_AGENT_LIST_DATA =
  "FORMAT_INDEPENDENT_AGENT_LIST_DATA";
export const GROUP_POLICY_FETCH_GRAPH_DATA = "GROUP_POLICY_FETCH_GRAPH_DATA";
//======================================EDIT====================================
export const GROUP_POLICY_FETCH_EDIT_DATA = "GROUP_POLICY_FETCH_EDIT_DATA";
export const GROUP_POLICY_INIT_BASE_DATA_EDIT_FORM =
  "GROUP_POLICY_INIT_BASE_DATA_EDIT_FORM";
//=======================================MODAL==================================
export const GROUP_POLICY_LOADING_EDIT_FORM = "GROUP_POLICY_LOADING_EDIT_FORM";
export const GROUP_POLICY_IDLE_EDIT_FORM = "GROUP_POLICY_IDLE_EDIT_FORM";
export const GROUP_POLICY_HIDE_FORM_MODAL = "GROUP_POLICY_HIDE_FORM_MODAL";
export const GROUP_POLICY_SHOW_FORM_MODAL = "GROUP_POLICY_SHOW_FORM_MODAL";
