import { Avatar } from "rsuite";

import {
  transportationPrefChange,
  accPrefChange,
  transferPrefChange,
  resetPreferences,
  infrastructureChange,
  transportationEdit,
} from "@src/actions/Project/TripPlanner";

import AccommodationSetup from "./AccommodationSetupController";
import TransferSetup from "./TransferSetupController";

import SuIcon from "@src/style/icon/components/SuIcon";
import _ from "lodash";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";

export class ServiceHeader extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { title } = this.props;

    return (
      <h6>
        <Avatar size="md" circle>
          <SuIcon size="lg" icon={this.props.icon} />
        </Avatar>{" "}
        {title}
      </h6>
    );
  }
}
ServiceHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  icon: PropTypes.string.isRequired,
  preselectionValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const ServiceSetupController = (props) => {
  return (
    <div className="ServiceSetupController">
      <div className="ServiceSetupController__header">
        <h5>Service Preferences</h5>
      </div>
      <div className="ServiceSetupController__preferences">
        {props.requiredServices.includes("ACC") ? <AccommodationSetup /> : null}
        {props.requiredServices.includes("TF") ? <TransferSetup /> : null}
      </div>
    </div>
  );
};
ServiceSetupController.propTypes = {
  requiredServices: PropTypes.array.isRequired,
  transportationSearching: PropTypes.bool.isRequired,
  transportationPrefEditMode: PropTypes.bool.isRequired,
  transportationPreferences: PropTypes.object.isRequired,
  accommodationPreferences: PropTypes.object.isRequired,
  transferPreferences: PropTypes.object.isRequired,
  trpInfrastructure: PropTypes.object.isRequired,
  trpInfraSearching: PropTypes.bool.isRequired,
  onTrpPrefChange: PropTypes.func.isRequired,
  onAccPrefChange: PropTypes.func.isRequired,
  onTrfPrefChange: PropTypes.func.isRequired,
  onPreferencesReset: PropTypes.func.isRequired,
  onInfraChange: PropTypes.func.isRequired,
  onTrpEdit: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  const { searching: trpInfraSearching } =
    state.tripPlannerTrpInfraSearchStatus;

  return {
    requiredServices: state.tripPlannerItinerarySetupForm.requiredServices,
    transportationPreferences: state.tripPlannerTrpPrefReducer,
    trpInfraSearching,
    trpInfrastructure: state.tripPlannerTrpInfrastructure,
    accommodationPreferences: state.tripPlannerAccPref,
    transportationSearching: state.tripPlannerTrpFastSearching,
    transportationPrefEditMode: state.tripPlannerTrpPrefEditMode,
    transferPreferences: state.tripPlannerTrfPref,
  };
};
const mapDispatchToProps = (dispatch) => {
  const leadingDebouncedDispatch = _.debounce(dispatch, 300, { leading: true });

  return {
    onTrpPrefChange: (name, value) =>
      dispatch(transportationPrefChange(name, value)),
    onAccPrefChange: (name, value) => dispatch(accPrefChange(name, value)),
    onTrfPrefChange: (name, value) => dispatch(transferPrefChange(name, value)),
    onPreferencesReset: (type) => dispatch(resetPreferences(type)),
    onInfraChange: (ids, type) => dispatch(infrastructureChange(ids, type)),
    onTrpEdit: () => leadingDebouncedDispatch(transportationEdit()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceSetupController);
