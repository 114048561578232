import { isAccRefundable } from "../accommodation_selectors";
import {
  getSrvsUidForBooking,
  getSrvsUidForOptionBooking,
} from "../prebook/selected_services";
import { getAccPrebookInfo } from "../prebook_selectors";

export function getAccAPIServices(state, props = {}) {
  var { booking_mode, refundable } = props;

  const data = state.tripPlannerAccData;
  var restrictedToServices = [];

  if (booking_mode === "regular") {
    restrictedToServices = getSrvsUidForBooking(state, {
      srv_type: "ACC",
    });
  } else if (booking_mode === "option") {
    refundable = true;
    restrictedToServices = getSrvsUidForOptionBooking(state, {
      srv_type: "ACC",
    });
  }

  if (restrictedToServices.length === 0 && booking_mode) {
    return {};
  }

  const newData = {};
  Object.entries(data).forEach((entry) => {
    newData[entry[0]] = entry[1]
      .filter((srv) => srv.selected)
      .filter((srv) => {
        if (refundable === true) {
          const prebook_info =
            getAccPrebookInfo(state, { destOrder: entry[0] }) || {};
          return isAccRefundable({ acc: srv, prebook_info });
        } else if (refundable === false) {
          const prebook_info =
            getAccPrebookInfo(state, { destOrder: entry[0] }) || {};
          return !isAccRefundable({ acc: srv, prebook_info });
        }
        return true;
      })
      .filter((srv) => {
        if (restrictedToServices.length === 0) {
          return true;
        }

        return restrictedToServices.some(
          (s) => s.srv_type === "ACC" && s.uid === srv.uid
        );
      });
  });
  return newData;
}

export function getAccCustomServices(state, props = {}) {
  const { booking_mode } = props;

  const customData = state.tripPlannerAccCustomData || [];
  if (["regular", "option"].includes(booking_mode)) {
    const all_reg_booking_srvs = getSrvsUidForBooking(state, {
      srv_type: "ACC",
    });
    const all_option_booking_srvs = getSrvsUidForOptionBooking(state, {
      srv_type: "ACC",
    });

    const book_srvs =
      booking_mode === "regular"
        ? all_reg_booking_srvs
        : all_option_booking_srvs;

    return customData.filter((srv) =>
      book_srvs.some((s) => s.srv_type === "ACC" && s.uid === srv.uid)
    );
  } else {
    return customData;
  }
}

export const getAccAdhocServices = (state, props = {}) => {
  const { booking_mode } = props;

  const adhocData = state.tripPlannerAccAddhocData || [];
  if (["regular", "option"].includes(booking_mode)) {
    const all_reg_booking_srvs = getSrvsUidForBooking(state, {
      srv_type: "ACC",
    });
    const all_option_booking_srvs = getSrvsUidForOptionBooking(state, {
      srv_type: "ACC",
    });

    const book_srvs =
      booking_mode === "regular"
        ? all_reg_booking_srvs
        : all_option_booking_srvs;
    return adhocData.filter((srv) =>
      book_srvs.some((s) => s.srv_type === "ACC" && s.uid === srv.uid)
    );
  } else {
    return adhocData;
  }
};
