import React from "react";
import service, { getAuthHeaders } from "@src/api/request";
import axios from "axios";
import { toast } from "react-toastify";
import { SISI_API_URL, SISI2_API_URL } from "@src/api";
import _ from "lodash";

export const getUserDetails = (params) => {
  return service({
    url: `/bid/user_meta/`,
    method: "get",
    params: params,
  });
};

export async function getUserProfile(id) {
  try {
    const auth_headers = getAuthHeaders();
    const result = await axios.get(`${SISI_API_URL}/crm/user/${id}/`, {
      headers: { ...auth_headers },
    });
    return _.get(result, "data");
  } catch (error) {
    toast.error(
      <p>
        We failed to retrieve User Profile. We are sorry for the inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function patchUserProfile(id, params) {
  try {
    const auth_headers = getAuthHeaders();
    const result = await axios.patch(
      `${SISI_API_URL}/crm/user/${id}/`,
      params,
      {
        headers: { ...auth_headers },
      }
    );
    return _.get(result, "data");
  } catch (error) {
    toast.error(
      <p>
        We failed to patch User Profile. We are sorry for the inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}
export async function patchUserProfileNew(id, params) {
  const auth_headers = getAuthHeaders();
  return await axios.patch(`${SISI_API_URL}/crm/user/${id}/`, params, {
    headers: { ...auth_headers },
  });
}

export async function uploadEntityImage(params) {
  try {
    const auth_headers = getAuthHeaders();
    const result = await axios.post(
      `${SISI_API_URL}/crm/entity-image/`,
      params,
      {
        headers: { ...auth_headers },
      }
    );
    return _.get(result, "data");
  } catch (error) {
    const data = _.get(error, "response.data", {});
    toast.error(
      <p>
        We failed to upload Entity Image. We are sorry for the inconvenience.
        <br />
        <span>{_.get(data, "message")}</span>
        <br />
        <span>{_.get(data, "error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function newUploadEntityImage(params) {
  const auth_headers = getAuthHeaders();
  const result = await axios.post(`${SISI_API_URL}/crm/entity-image/`, params, {
    headers: { ...auth_headers },
  });
  return result?.data;
}

export const getUserOptions = (lang) => {
  return service({
    url: "/bid/user_meta/",
    method: "options",
    headers: { "Accept-Language": lang },
  });
};

export function getUsersList(para) {
  return service({ url: "/bid/user_list", method: "get", params: para });
}

export function signUp(para) {
  return service({ url: "/api/passport/sign-up/", data: para, method: "post" });
}

export function signIn(para) {
  return service({ url: "/api/passport/sign-in/", data: para, method: "post" });
}
export function signOut(para) {
  return service({
    url: "/api/passport/sign-out/",
    data: para,
    method: "post",
  });
}

export const submitUserProfile = (id, payload, mode) => {
  return service({ url: `/bid/user_meta/${id}/`, method: mode, data: payload });
};

export function getNewToken(para) {
  return service({
    url: "/api/passport/refresh-token/",
    data: para,
    method: "post",
  });
}

export function changeEmail(para) {
  return service({
    url: "/api/passport/change-email/",
    data: para,
    method: "post",
  });
}

export const createUser = (payload) => {
  return service({
    url: `/api/passport/create-sub-user/`,
    method: "post",
    data: payload,
  });
};

export function forgotPassword(para) {
  return service({
    url: "/api/passport/forget-password/",
    data: para,
    method: "post",
  });
}

export function changePassword(para) {
  return service({
    url: "/api/passport/change-password/",
    data: para,
    method: "post",
  });
}

export function changeUserStatus(para) {
  return service({
    url: "/api/passport/change-user-status/",
    data: para,
    method: "post",
  });
}

export function resendVerifyEmail(para) {
  return service({
    url: "/api/passport/resend-verify-email/",
    data: para,
    method: "post",
  });
}

export function getJobDescriptions(para) {
  return service({ url: "/bid/job-description/", params: para, method: "get" });
}

export function createJobDescription(para) {
  return service({ url: "/bid/job-description/", data: para, method: "post" });
}

export function getJobDescriptionsAutocomplete(para) {
  return service({
    url: "/bid/job-description/autocomplete/",
    params: para,
    method: "get",
  });
}

export function getUsersFeatureGroup() {
  return service({
    url: "/bid/feature-group/",
    method: "get",
    params: { limit: 50, allow_edit: true },
  });
}

export function getUsersEmailConfigList() {
  return service({ url: "/bid/feature-group/", method: "get" });
}

export async function getGroups() {
  try {
    const auth_headers = getAuthHeaders();
    const result = await axios.get(`${SISI_API_URL}/crm/groups/`, {
      headers: { ...auth_headers },
    });
    return result?.data;
  } catch (error) {
    toast.error(
      <p>
        We failed to retrieve the Permission Groups. We are sorry for the
        inconvenience.
        <br />
        <span>{error?.response?.data?.message}</span>
        <br />
        <span>{error?.response?.data?.error}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}
export async function newGetGroups() {
  const auth_headers = getAuthHeaders();
  return await axios.get(`${SISI_API_URL}/crm/groups/`, {
    headers: { ...auth_headers },
  });
}

export async function getAppUserGroups(params) {
  const auth_headers = getAuthHeaders();
  return await axios.get(`${SISI_API_URL}/crm/appuser-groups/`, {
    headers: { ...auth_headers },
    params: params,
  });
}

export function getAppUserGroup(id) {
  const auth_headers = getAuthHeaders();
  return axios.get(`${SISI_API_URL}/crm/appuser-groups/${id}/`, {
    headers: { ...auth_headers },
  });
}

export async function createAppUserGroup(params) {
  const auth_headers = getAuthHeaders();
  return await axios.post(`${SISI_API_URL}/crm/appuser-groups/`, params, {
    headers: { ...auth_headers },
  });
}

export async function patchAppUserGroup(id, params) {
  const auth_headers = getAuthHeaders();
  return await axios.patch(
    `${SISI_API_URL}/crm/appuser-groups/${id}/`,
    params,
    { headers: { ...auth_headers } }
  );
}

export async function deleteAppUserGroup(id) {
  const auth_headers = getAuthHeaders();
  return await axios.delete(`${SISI_API_URL}/crm/appuser-groups/${id}/`, {
    headers: { ...auth_headers },
  });
}

export const getUserProfileDetails = (id) => {
  return service({ url: `/bid/user_meta/${id}`, method: "get" });
};

// TODO: fix is corse policy issue
export async function retrieveUserNew(id) {
  const auth_headers = getAuthHeaders();
  return await axios.get(`${SISI2_API_URL}/crm/appusers/${id}/`, {
    headers: { ...auth_headers },
  });
}

// TODO: fix is corse policy issue
export async function patchUserNew(id, params) {
  const auth_headers = getAuthHeaders();
  return await axios.patch(`${SISI2_API_URL}/crm/appusers/${id}/`, params, {
    headers: { ...auth_headers },
  });
}

export async function getUsersStats() {
  const auth_headers = getAuthHeaders();
  return await axios.get(`${SISI2_API_URL}/crm/appusers/stats/`, {
    headers: { ...auth_headers },
  });
}

export function getCompanyCompleteness() {
  const auth_headers = getAuthHeaders();
  return axios.get(`${SISI2_API_URL}/crm/entity/completeness/`, {
    headers: { ...auth_headers },
  });
}

export function getProfileCompleteness() {
  const auth_headers = getAuthHeaders();
  return axios.get(`${SISI2_API_URL}/crm/appusers/completeness/`, {
    headers: { ...auth_headers },
  });
}
