import service from "@src/api/request";

export function getPurchasesListData(param) {
  return service({
    url: "/bid/purse-chases",
    method: "get",
    params: param,
  });
}

export function getPurchasesGraphData(param) {
  return service({
    url: "/bid/purse-chases-stats",
    method: "get",
    params: param,
  });
}

export function getPurchasesGraphDataOptions() {
  return service({
    url: "/bid/purse-chases-stats",
    method: "options",
  });
}
