import {
  INDEPENDENT_AGENTS_LIST_FILTER_CHANGE,
  INDEPENDENT_AGENTS_SAVE_LIST_DATA,
} from "@src/actions/Network/SalesPartners/IndependentAgents/types";
import { commonList } from "@src/reducers/common";

export const initialIndependentAgentsList = {
  ...commonList,
  displayLength: 100,
  filters: {
    name__icontains: "",
    contact_person__first_name__icontains: "",
    contact_person__last_name__icontains: "",
    email__icontains: "",
    creator__icontains: "",
    created__gte: "",
    created__lte: "",
    status__in: "",
    page: 1,
    limit: 20,
  },
};
export const IndependentAgentsListReducer = (
  state = initialIndependentAgentsList,
  action
) => {
  switch (action.type) {
    case INDEPENDENT_AGENTS_LIST_FILTER_CHANGE: {
      const { filters } = action;
      return { ...state, filters: { ...state.filters, ...filters } };
    }
    case INDEPENDENT_AGENTS_SAVE_LIST_DATA:
      return action.data;
    default:
      return state;
  }
};
