import { Modal, Loader } from "rsuite";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";

class LoadingModal extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { show } = this.props;

    return (
      <Modal
        className="CustomModal CustomModal--loading ModalCentered"
        show={show}>
        <Modal.Header>
          <Modal.Title>Please wait...</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Loader vertical size="lg" content="Please wait..." />
        </Modal.Body>
      </Modal>
    );
  }
}
LoadingModal.propTypes = {
  show: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => {
  const { show } = state.customServiceAddOnStatusModal;
  return {
    show,
  };
};

export default connect(mapStateToProps, null)(LoadingModal);
