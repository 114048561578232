import {
  Modal,
  SelectPicker,
  IconButton,
  Icon,
  InputNumber,
  Divider,
  Notification,
} from "rsuite";

// ============================== STRIPE =============================
import {
  Elements,
  ElementsConsumer,
  CardElement,
} from "@stripe/react-stripe-js";

// ============================= ACTIONS =============================
import {
  hideDepositModal,
  idleDepositModal,
  loadingDepositModal,
  fetchAccountStatementDataList,
  fetchDepositBalance,
} from "@src/actions/ManagementConsole/AccountStatement/index";

import { fetchStripeInitInfo } from "@src/actions/ManagementConsole/PaymentMethods";

// =============================== API ===============================
import { setupPayment } from "@src/api";

import _ from "lodash";
import update from "immutability-helper";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";

const CARD_OPTIONS = {
  style: {
    base: {
      "color": "#32325d",
      "fontFamily": '"Helvetica Neue", Helvetica, sans-serif',
      "fontSmoothing": "antialiased",
      "fontSize": "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      disabled,
      amount,
      elements,
      stripe,
      entityCurrencyData,
      handleSubmit,
    } = this.props;

    return (
      <form
        className="payment-method-form"
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit(elements, stripe);
        }}>
        <div className="payment-method-element">
          <CardElement options={CARD_OPTIONS} />
        </div>
        <div>
          <IconButton
            disabled={disabled}
            color="green"
            type="submit"
            icon={<Icon icon="check" />}>
            {`Deposit ${entityCurrencyData.symbol}${amount}`}
          </IconButton>
        </div>
      </form>
    );
  }
}

CheckoutForm.defaultProps = {
  amount: "0",
  disabled: false,
  entityCurrencyData: {},
};

CheckoutForm.propTypes = {
  amount: PropTypes.number.isRequired,
  entityCurrencyData: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  elements: PropTypes.object.isRequired,
  stripe: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

class AddFundsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      paymentMethod: null,
      way: "NEW",
      amount: 0,
    };

    this.handleSelectPaymentMethod = this.handleSelectPaymentMethod.bind(this);
    this.handleSubmit = _.debounce(this.handleSubmit.bind(this), 1000, {
      leading: true,
      trailing: false,
    });
    this.handleChangeWay = this.handleChangeWay.bind(this);
    this.handleChangeAmount = this.handleChangeAmount.bind(this);
  }

  async componentDidMount() {
    const { stripe, getStripeInfo } = this.props;
    if (_.isEmpty(stripe)) {
      await getStripeInfo();
    }
  }

  handleChangeWay(value) {
    this.setState((p) => update(p, { way: { $set: value } }));
  }
  handleChangeAmount(value) {
    if (value.length > 9) {
      return;
    }

    this.setState((p) => update(p, { amount: { $set: value } }));
  }
  validate(amount, paymentMethod) {
    const { way } = this.state;
    if (amount == 0 || isNaN(amount)) {
      Notification.warning({
        title: "Deposit Warning",
        description: (
          <p>
            Please specify a valid <strong>Deposit Amount</strong> first.
          </p>
        ),
        duration: 6000,
      });
      return false;
    } else if (way == "EXI" && !paymentMethod) {
      Notification.warning({
        title: "Deposit Warning",
        description: (
          <p>
            Please select a <strong>Payment Method</strong> first.
          </p>
        ),
        duration: 6000,
      });
      return false;
    }
    return true;
  }
  notifyAddDepositInitError() {
    Notification.error({
      title: "Add Deposit Error",
      description: (
        <p>Failed to initiate payment! We are sorry for the inconvenience.</p>
      ),
      duration: 6000,
    });
  }
  notifyAddDepositFail(message) {
    Notification.error({
      title: "Payment Failure",
      description: <p>{message}</p>,
      duration: 20000,
    });
  }
  notifyAddDepositeSuccess() {
    Notification.success({
      title: "Payment Success",
      description: <p>Your payment was successfully completed.</p>,
      duration: 6000,
    });
  }
  async handleSubmit(elements = null, stripe = null) {
    const { amount, way, paymentMethod } = this.state;
    const {
      mode,
      deposit_stripe_id,
      entityCurrencyData,
      company_type,
      company_id,
      setIdle,
      setLoading,
      onHide,
      fetchBalance,
      fetchAccountStatement,
    } = this.props;

    if (!this.validate(amount, paymentMethod)) {
      return;
    }

    if (!stripe || (!elements && way !== "EXI")) {
      return;
    }

    var payload = null;
    if (way == "EXI") {
      payload = { payment_method: paymentMethod };
    } else {
      let cardElement = elements.getElement(CardElement);
      payload = {
        payment_method: { type: "card", card: cardElement },
        save_payment_method: true,
      };
    }

    await setLoading();

    var client_secret = null;
    try {
      //metadata can no be null
      var metadata = {};

      if (company_type !== "member") {
        metadata = { sub_entity: `${company_id}___${company_type}` };
      }

      const payment_setup_res = await setupPayment(
        amount,
        deposit_stripe_id,
        "Deposit Credit",
        entityCurrencyData.notation,
        metadata
      );
      client_secret = payment_setup_res.client_secret;
    } catch (error) {
      this.notifyAddDepositInitError();
      setIdle();
      return;
    }

    const { error, paymentIntent } = await stripe.confirmCardPayment(
      client_secret,
      payload
    );

    if (error) {
      this.notifyAddDepositFail(error.message);
      setIdle();
      return;
    }

    //TODO: Check what other statuses are available
    if (paymentIntent.status == "succeeded") {
      this.notifyAddDepositeSuccess();
      setIdle();
      onHide();

      window.setTimeout(() => {
        if (mode == "account_statement") {
          fetchAccountStatement();
        } else {
          fetchBalance();
        }
      }, 4000);
    }
  }
  handleSelectPaymentMethod(value) {
    this.setState((p) => update(p, { paymentMethod: { $set: value } }));
  }
  render() {
    const { paymentMethod, way, amount } = this.state;
    const {
      show,
      loading,
      myPaymentMethods,
      entityCurrencyData,
      onHide,
      stripe,
    } = this.props;

    return (
      <Modal
        className={`CustomModal${
          loading ? " CustomModal--loading" : ""
        } ModalCentered AddFundsModal`}
        show={show}
        onHide={onHide}>
        <Modal.Header>
          <Modal.Title>Add Deposit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {show ? (
            <React.Fragment>
              <div>
                <span>
                  <strong>
                    Deposit Amount{" "}
                    <small>
                      (your payment currency is {entityCurrencyData.name})
                    </small>
                  </strong>
                </span>
                <InputNumber
                  prefix={entityCurrencyData.symbol}
                  value={amount}
                  disabled={loading}
                  onChange={(value, event) => {
                    if (event.type == "blur") {
                      return;
                    }

                    this.handleChangeAmount(value);
                  }}
                />
              </div>
              <Divider />
              {/* <PaymentWayRadio way={way} onChangeWay={this.handleChangeWay} /> */}
              <Elements stripe={stripe}>
                <ElementsConsumer>
                  {({ elements, stripe }) => {
                    return way == "EXI" ? (
                      <React.Fragment>
                        <span>
                          <strong>Existing Payment Methods</strong>
                        </span>
                        <SelectPicker
                          disabled={loading}
                          value={paymentMethod}
                          cleanable={false}
                          searchable={false}
                          onChange={(value) =>
                            this.handleSelectPaymentMethod(value)
                          }
                          data={myPaymentMethods
                            .sort((a, b) => b.is_default - a.is_default)
                            .map((p) => ({
                              value: p.id,
                              label: `${_.startCase(p.type)} (${
                                p.brand
                              }) ******${p.last4} ${
                                p.is_default ? " Default" : ""
                              }`,
                            }))}
                        />
                        <IconButton
                          disabled={loading}
                          className="submit"
                          color="green"
                          icon={<Icon icon="check" />}
                          onClick={(event) => {
                            event.preventDefault();
                            this.handleSubmit(elements, stripe);
                          }}>
                          {`Deposit ${entityCurrencyData.symbol}${amount}`}
                        </IconButton>
                      </React.Fragment>
                    ) : !elements || !stripe ? null : (
                      <React.Fragment>
                        <span>
                          <strong>New Payment Method</strong>
                        </span>
                        <CheckoutForm
                          disabled={loading}
                          amount={parseFloat(amount)}
                          entityCurrencyData={entityCurrencyData}
                          stripe={stripe}
                          elements={elements}
                          handleSubmit={this.handleSubmit}
                        />
                      </React.Fragment>
                    );
                  }}
                </ElementsConsumer>
              </Elements>
            </React.Fragment>
          ) : null}
        </Modal.Body>
      </Modal>
    );
  }
}

AddFundsModal.defaultProps = {
  entityCurrencyData: {},
};

AddFundsModal.propTypes = {
  loading: PropTypes.bool.isRequired,
  show: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  stripe: PropTypes.object.isRequired,
  entityCurrencyData: PropTypes.object.isRequired,
  deposit_stripe_id: PropTypes.string.isRequired,
  myPaymentMethods: PropTypes.array.isRequired,
  company_type: PropTypes.string.isRequired,
  company_id: PropTypes.number.isRequired,
  onHide: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  setIdle: PropTypes.func.isRequired,
  fetchAccountStatement: PropTypes.func.isRequired,
  fetchBalance: PropTypes.func.isRequired,
  getStripeInfo: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { company_type, company_id } = state.userMeta;

  const myPaymentMethods = _.get(state.paymentMethodsList, "details", []);
  const entityCurrency = _.get(state.companyProfile, "currency", null);
  const entityCurrencyData = state.financialAvailableCurrencies.find(
    (c) => c.notation == entityCurrency
  );
  const { show, loading, mode } = state.accountStatementDepositModal;
  const deposit_stripe_id =
    _.get(state, "companyProfile.top_parent_entity.deposit_stripe_id", "") ||
    "";

  const stripe = state.paymentMethodsInitStripeInfo;
  return {
    show,
    loading,
    mode,
    entityCurrencyData,
    deposit_stripe_id,
    myPaymentMethods,
    company_type,
    company_id,
    stripe,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onHide: () => dispatch(hideDepositModal()),
    setIdle: () => dispatch(idleDepositModal()),
    setLoading: () => dispatch(loadingDepositModal()),
    fetchAccountStatement: () => dispatch(fetchAccountStatementDataList()),
    fetchBalance: () => dispatch(fetchDepositBalance()),
    getStripeInfo: () => dispatch(fetchStripeInitInfo()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddFundsModal);
