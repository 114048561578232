import langDictionaries from "./lang_dictionaries";
import _ from "lodash";

const LANGUAGE = "pt";

var langPtDictionary = {};

langDictionaries.forEach((langDictionary) => {
  const obj = _.get(langDictionary, LANGUAGE, {});
  Object.entries(obj).forEach(([key, value]) => {
    langPtDictionary[key] = value;
  });
});
export default langPtDictionary;
