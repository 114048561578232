import _ from "lodash";
import {
  prebookActivity,
  prebookActivityUpdate,
} from "@src/api/Project/activities";
import { getServicesListSelector } from "@src/selectors/Project/TripPlanner/generic_service_selectors";
import {
  getSrvsUidForBookingSelector,
  getSrvsUidForOptBookingSelector,
} from "@src/selectors/Project/TripPlanner/prebook";
import { useMutation, useQueries } from "@tanstack/react-query";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedActivityAttrs } from "../products/activities/details/ActivityPriceModal";
import { Form, Formik } from "formik";
import { NormalInputField } from "@src/components/forms";
import { Loader } from "rsuite";
import { updateDayActivity } from "@src/actions/Project/TripPlanner/activities";
import { toast } from "react-toastify";
import { DateTime } from "luxon";
import { PriceExcHoc } from "../../PriceDetails";
import CancellationPolicyDetails from "../../CancellationPolicyDetails";

const Price = PriceExcHoc(({ price, currency }) => {
  return (
    <span>
      {price.toLocaleString("en-US", { style: "currency", currency })}
    </span>
  );
});
Price.propTypes = {
  price: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
};

const ActivityPrebook = ({
  activity,
  prebookAccepted = false,
  prebookUpdateData,
  optsForDisplay,
  onAccept,
}) => {
  var totalPrice = prebookUpdateData?.full_price?.value ?? 0;
  var totalPriceCurrency = prebookUpdateData?.full_price?.currency;

  if (!totalPrice) {
    totalPrice =
      _.sum(
        (optsForDisplay?.items ?? []).map(
          (item) => Number(item.quantity) * Number(item.price.value)
        )
      ) ?? 0;
    totalPriceCurrency = optsForDisplay?.items?.[0]?.price?.currency;
  }

  const selectedProducts = activity?.selectionInfo?.products;

  return (
    <div className="ActivityPrebook Card">
      <div className="Card__header">
        <h5>{activity.title}</h5>
      </div>
      <div className="Card__body ActivityPrebook__content">
        <img
          src={activity?.images?.[0]?.sm?.url ?? ""}
          alt=""
          className="ActivityPrebook__img"
        />
        <div className="ActivityPrebook__text">
          <span>
            <strong>Date: </strong>
            {DateTime.fromISO(activity?.selectionInfo?.date).toLocaleString(
              DateTime.DATE_MED_WITH_WEEKDAY
            )}
            {!!activity?.selectionInfo?.slot && (
              <span>
                {" - "}
                {activity.selectionInfo.slot}
              </span>
            )}
          </span>
          <span>
            <strong>{`Tickets For: ${activity?.selectionInfo?.group}`}</strong>
            <ul className="ActivityPrebook__productList">
              {(activity?.bookingData?.schedules?.[0]?.groups?.[0]?.slots ?? [])
                .filter((slot) => {
                  return slot.products.some(
                    (pr) => (selectedProducts?.[pr.product_id] ?? 0) > 0
                  );
                })
                .map((slot, idx) => {
                  return slot.products.map((p) => {
                    const quantity =
                      activity.selectionInfo.products?.[p.product_id] ?? 0;
                    if (!quantity) return null;
                    return (
                      <li key={idx}>
                        {`${quantity}x ${p.name}`} (
                        <Price
                          styleless={true}
                          price={Number(p.price.value)}
                          currency={p.price.currency}
                        />
                        )
                      </li>
                    );
                  });
                })}
            </ul>
          </span>
          {!!activity?.meeting_point_info && (
            <span className="ActivityPrebook__meetingPoint">
              <strong>Meeting Point: </strong>
              {activity.meeting_point_info}
            </span>
          )}
          {(optsForDisplay?.items ?? []).length > 0 && (
            <span>
              <strong>Language: </strong>
              {/* [ */
              /*   ...new Set([ */
              /*     ...optsForDisplay.items.map((item) => item.language), */
              /*   ]), */
              /* ] */
              /*   .map((lng) => languages[_.upperCase(lng)]) */
              /*   .join(", ") */}
            </span>
          )}
          {!!activity.supplier && (
            <div className="ActivityPrebook__extraInfo">
              <strong>Provided By: </strong>
              {activity.supplier.supplier_name}
            </div>
          )}
          {!!prebookUpdateData && (
            <CancellationPolicyDetails
              cxl_policies={prebookUpdateData?.cancellation_policies}
            />
          )}
        </div>
      </div>
      <div className="ActivityPrebook__priceContainer">
        <h5>
          <span>Total Price: </span>
        </h5>
        <h5>
          <Price price={totalPrice} currency={totalPriceCurrency} />
        </h5>
        {!prebookAccepted ? (
          <button className="Button" type="button" onClick={onAccept}>
            Accept
          </button>
        ) : (
          <h5 className="ActivityPrebook__priceContainer__accepted">
            Accepted
          </h5>
        )}
      </div>
    </div>
  );
};
ActivityPrebook.propTypes = {
  activity: PropTypes.object.isRequired,
  prebookAccepted: PropTypes.bool.isRequired,
  prebookUpdateData: PropTypes.object.isRequired,
  optsForDisplay: PropTypes.object.isRequired,
  onAccept: PropTypes.func.isRequired,
};

export const ActivitiesPrebookModal = ({ onCancel }) => {
  const dispatch = useDispatch();
  const onUpdateActivity = useCallback(
    ({ uid, activity, date }) => {
      dispatch(updateDayActivity({ uid, activity, date }));
    },
    [dispatch]
  );

  const { activities } = useSelector((state) => {
    const bookingUids = getSrvsUidForBookingSelector(state);
    const optionUids = getSrvsUidForOptBookingSelector(state);
    const allUids = [...bookingUids, ...optionUids].map((i) => i.uid);

    const srvs = getServicesListSelector(state);
    const activities = srvs.filter(
      (srv) => srv.service_type === "ACT" && allUids.includes(srv.uid)
    );

    return { bookingUids, optionUids, activities };
  });

  const queries = useQueries({
    queries: activities.map((act) => {
      const { selectedProducts } = getSelectedActivityAttrs({
        selectionInfo: act.selectionInfo,
        bookingData: act.bookingData,
      });

      return {
        queryKey: ["activityPrebook", act.uid],
        queryFn: () =>
          prebookActivity({
            uid: act.uid,
            session_id: act.session_id,
            date: act.date,
            ticket_option: act.title,
            items: selectedProducts,
          }),
        refetchOnWindowFocus: false,
        retry: false,
        onError: () => {
          toast.error(
            `Failed to prebook activity ${act.title}. Please try again.`,
            { autoClose: 3000 }
          );
          onCancel();
        },
      };
    }),
  });

  const isLoading = queries.some((q) => q.isLoading || q.isFetching);

  const initialValues = {};
  activities.forEach((act, idx) => {
    if (isLoading) return;

    const prebookRequirements = queries?.[idx]?.data?.data;
    initialValues[act.uid] = {
      uid: act.uid,
      customer_data: null,
      participants_data: [],
      prebookRequirements,
      prebookUpdateData: {},
      prebookAccepted: false,
    };
    Object.entries(prebookRequirements?.customer_data ?? {}).forEach(
      ([key, __]) => {
        _.set(initialValues, `${act.uid}.customer_data.${key}`, "");
      }
    );
  });

  const prebookUpdateMutation = useMutation({
    mutationFn: ({ payload, search_session_id, callBack }) =>
      prebookActivityUpdate({ payload, search_session_id }),
    onSuccess: (data, variables) => variables.callBack(data?.data),
    onError: () => toast.error("Activity prebook failed.", { autoClose: 5000 }),
  });

  return (
    <div className="Modal ActivitiesPrebookModal">
      <div className="Modal__card">
        <div className="Modal__card__header">
          <h5>Activities Prebook</h5>
        </div>
        {isLoading ? (
          <div className="Modal__card__body">
            <Loader size="lg" center />
          </div>
        ) : (
          <Formik
            initialValues={initialValues}
            validateOnChange={false}
            validateOnBlur={false}
            validateOnMount={false}
            validate={(values) => {
              const errors = {};
              Object.entries(values).forEach(([uid, form]) => {
                if (!(form?.prebookAccepted ?? false)) {
                  toast.error(
                    "Please accept the booking terms of all activities.",
                    { autoClose: 5000 }
                  );
                  _.set(errors, `${uid}.prebookAccepted`, "Required");
                } else if (!!errors?.[uid]?.prebookAccepted) {
                  delete errors[uid].prebookAccepted;
                }

                Object.entries(form?.customer_data ?? {}).forEach(
                  ([key, value]) => {
                    const isRequired =
                      form?.prebookRequirements?.customer_data?.[key]?.required;
                    if (isRequired && !value) {
                      _.set(errors, `${uid}.customer_data.${key}`, "Required");
                    }
                  }
                );
              });
              return errors;
            }}
            onSubmit={(values) => {
              const newActivities = _.cloneDeep(activities);
              newActivities.forEach((act) => {
                Object.entries(values).forEach(([uid, ___]) => {
                  if (uid !== act.uid) return;

                  act.prebookData = values?.[act.uid];
                });
              });
              for (let i = 0; i < newActivities.length; i++) {
                const activity = newActivities[i];
                const { uid, date } = activity;
                onUpdateActivity({ uid, date, activity });
              }
              onCancel();
            }}>
            {({ values, errors, submitForm, setFieldValue, setErrors }) => {
              return (
                <React.Fragment>
                  <div className="Modal__card__body ActivitiesPrebookModal__body">
                    {activities.map((act, idx) => {
                      const prebookRqs = queries?.[idx]?.data?.data;
                      const vAct = values?.[act.uid];
                      return _.isEmpty(vAct?.prebookUpdateData ?? {}) ? (
                        prebookUpdateMutation.isLoading ? (
                          <Loader size="lg" center key={idx} />
                        ) : (
                          <div
                            key={idx}
                            className="ActivitiesPrebookModal__activity">
                            <h5>
                              {idx + 1}. {act.title}
                            </h5>
                            <span>
                              Only guest leader information is required:{" "}
                              <strong>
                                {prebookRqs?.customer_data_required ?? false
                                  ? "Yes"
                                  : "No"}
                              </strong>
                            </span>
                            {prebookRqs?.customer_data_required && (
                              <Form className="Form ActivitiesPrebookModal__activity__form">
                                {Object.entries(prebookRqs?.customer_data).map(
                                  ([name, attrs], idx) => {
                                    const isRequired = attrs.required
                                      ? " (Required)"
                                      : "";
                                    const label = _.startCase(name);
                                    return (
                                      <NormalInputField
                                        key={idx}
                                        name={`${act.uid}.customer_data.${name}`}
                                        label={`${label}${isRequired}`}
                                        type={`${
                                          attrs.input_type === "emailfield"
                                            ? "email"
                                            : "text"
                                        }`}
                                        highlightErrors={true}
                                      />
                                    );
                                  }
                                )}
                              </Form>
                            )}
                            <span>
                              Information for every guest is required:{" "}
                              <strong>
                                {prebookRqs?.participants_data_required ?? false
                                  ? "Yes"
                                  : "No"}
                              </strong>
                            </span>
                            <button
                              className="Button"
                              onClick={() => {
                                prebookUpdateMutation.mutate({
                                  payload: values?.[act.uid],
                                  search_session_id: act.session_id,
                                  callBack: (prebookUpdateData) => {
                                    setFieldValue(
                                      `${act.uid}.prebookUpdateData`,
                                      prebookUpdateData
                                    );
                                  },
                                });
                              }}>
                              Submit
                            </button>
                          </div>
                        )
                      ) : (
                        <ActivityPrebook
                          key={idx}
                          activity={act}
                          prebookAccepted={vAct?.prebookAccepted ?? false}
                          prebookUpdateData={vAct?.prebookUpdateData}
                          onAccept={() => {
                            if (!!errors?.[act.uid]) {
                              const err = _.cloneDeep(errors);
                              delete err[act.uid];
                              setErrors(err);
                            }

                            setFieldValue(`${act.uid}.prebookAccepted`, true);
                          }}
                        />
                      );
                    })}
                  </div>
                  <div className="Modal__card__actions">
                    <button
                      className="Button"
                      data-ghost="true"
                      onClick={onCancel}>
                      Cancel
                    </button>
                    <button className="Button" onClick={submitForm}>
                      Submit
                    </button>
                  </div>
                </React.Fragment>
              );
            }}
          </Formik>
        )}
      </div>
    </div>
  );
};
ActivitiesPrebookModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
};
