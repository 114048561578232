import PropTypes from "prop-types";
import React from "react";

const logo =
  "https://sisistaticassets.s3.ap-southeast-1.amazonaws.com/platform_graphics/imgs/nodata_logo.png";

export default class LogoLoading extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { msg } = this.props;
    return (
      <div className="LogoLoading generic-loader centered">
        <img className="si-form-loading" src={logo} />
        {msg !== "" ? <span>{msg}</span> : null}
      </div>
    );
  }
}

LogoLoading.defaultProps = {
  msg: "",
};

LogoLoading.propTypes = {
  msg: PropTypes.string,
};
