import service, { getAuthHeaders } from "@src/api/request";
import axios from "axios";
import { SISI2_API_URL } from "..";

export const getMyLibraryList = (param) => {
  return service({
    url: `bid/custom-service/`,
    method: "get",
    params: param,
  });
};

export const deleteAddon = async (id) => {
  const authHeaders = getAuthHeaders();
  return await axios.delete(`${SISI2_API_URL}/addons/${id}/`, {
    headers: authHeaders,
  });
};

export const getAddOnByDestination = (param) => {
  return service({
    url: `bid/custom-service/`,
    method: "get",
    params: { ...param, service_type: "MI" },
  });
};

export const getMyLibraryAddOnStats = (param) => {
  return service({
    url: `bid/customer-addons-stats/`,
    method: "get",
    params: param,
  });
};

export * from "./ImageBank";
