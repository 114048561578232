import _ from "lodash";
import { NormalInputField } from "@src/components/forms";
import { variables } from "@src/jsssetup";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { firstCellStyles } from "./remarks";
import {
  genericButtonsStyles,
  genericRsrvFeebackStyles,
  rsrvFeebackTableStyles,
} from "../common/styles";
import tinycolor from "tinycolor2";

const vehicleInformationStyles = createUseStyles({
  // VehicleInformation: cardStyles.card,
  // header: cardStyles.header,
  // table: tableStyles.table,
  // head: tableStyles.head,
  // firstCell: firstCellStyles,
  // cell: tableStyles.cell,
  // inputCell: {
  //   ...tableStyles.cell,
  //   "width": "8rem",
  //   "& input": { width: "8rem" },
  // },
  // inputNumberCell: {
  //   ...tableStyles.cell,
  //   "width": "3rem",
  //   "& input": { width: "3rem" },
  // },
  ...genericButtonsStyles,
  VehicleInformation: genericRsrvFeebackStyles.card,
  header: genericRsrvFeebackStyles.cardHeader,
  table: rsrvFeebackTableStyles.verticalTable,
  head: {
    ...rsrvFeebackTableStyles.head,
    "& > tr > th:nth-child(3)": {
      backgroundColor: tinycolor(variables.colors.background.light)
        .lighten(5)
        .toHexString(),
    },
  },
  horizontalHead: rsrvFeebackTableStyles.horizontalHead,
  firstCell: firstCellStyles,
  cell: rsrvFeebackTableStyles.cell,
  inputCell: {
    ...rsrvFeebackTableStyles.cell,
    "width": "8rem",
    "& input": { width: "8rem" },
    "background": tinycolor(variables.colors.background.light)
      .lighten(5)
      .toHexString(),
  },
  inputNumberCell: {
    ...rsrvFeebackTableStyles.cell,
    "width": "3rem",
    "& input": { width: "3rem" },
    "background": tinycolor(variables.colors.background.light)
      .lighten(5)
      .toHexString(),
  },
});
export function VehicleInformation({
  initialVehicleInfoData,
  vehicle_information,
  editable,
  onChange,
}) {
  const classes = vehicleInformationStyles();

  const [init, setInit] = useState({});
  useEffect(() => setInit(initialVehicleInfoData), []);

  return _.isEmpty(init) ? null : (
    <div className={classes.VehicleInformation}>
      <div className={classes.header}>
        <h5>Vehicle Requirements</h5>
      </div>
      <Formik
        initialValues={init}
        validateOnMount={false}
        validateOnBlur={false}
        validate={(values) => onChange(values)}>
        {({ values, setFieldValue }) => (
          <Form>
            <table className={classes.table}>
              <thead className={classes.head}>
                <tr>
                  <th>Type</th>
                  <th>Requested</th>
                  <th>Confirmed</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th className={classes.horizontalHead}>Brand</th>
                  <td className={classes.cell}>
                    {vehicle_information.vehicle_brand || "N/A"}
                  </td>
                  <td className={classes.inputCell}>
                    {editable && !values.confirmed ? (
                      <NormalInputField name="vehicle_brand" />
                    ) : (
                      <span>{values.vehicle_brand}</span>
                    )}
                  </td>
                </tr>
                <tr>
                  <th className={classes.horizontalHead}>Number of Seats</th>
                  <td className={classes.cell}>
                    {vehicle_information.vehicle_seats}
                  </td>
                  <td className={classes.inputNumberCell}>
                    {editable && !values.confirmed ? (
                      <NormalInputField name="vehicle_seats" />
                    ) : (
                      <span>{values.vehicle_seats}</span>
                    )}
                  </td>
                </tr>
                <tr>
                  <th className={classes.horizontalHead}>Number of Vehicles</th>
                  <td className={classes.cell}>
                    {vehicle_information.vehicles_no}
                  </td>
                  <td className={classes.inputNumberCell}>
                    {editable && !values.confirmed ? (
                      <NormalInputField name="vehicles_no" />
                    ) : (
                      <span>{values.vehicles_no}</span>
                    )}
                  </td>
                </tr>
                <tr>
                  <th className={classes.horizontalHead}>Actions</th>
                  <td></td>
                  {editable && (
                    <td className={classes.inputCell}>
                      {values.confirmed ? (
                        <button
                          className={classes.cancelButton}
                          onClick={() => setFieldValue("confirmed", false)}>
                          <strong>Edit</strong>
                        </button>
                      ) : (
                        <button
                          className={classes.confirmButton}
                          onClick={() => setFieldValue("confirmed", true)}>
                          <strong>Confirm</strong>
                        </button>
                      )}
                    </td>
                  )}
                </tr>
              </tbody>
            </table>
            {/* <table className={classes.table}>
              <thead className={classes.head}>
                <tr>
                  <th>Type</th>
                  <th>Brand</th>
                  <th>Number of Seats</th>
                  <th>Number of Vehicles</th>
                  {editable && <th>Actions</th>}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={classes.firstCell}>Requested</td>
                  <td className={classes.cell}>
                    {vehicle_information.vehicle_brand || "N/A"}
                  </td>
                  <td className={classes.cell}>
                    {vehicle_information.vehicle_seats}
                  </td>
                  <td className={classes.cell}>
                    {vehicle_information.vehicles_no}
                  </td>
                </tr>
                <tr>
                  <td className={classes.firstCell}>Confirmed</td>
                  <td className={classes.inputCell}>
                    {editable && !values.confirmed ? (
                      <NormalInputField name="vehicle_brand" />
                    ) : (
                      <span>{values.vehicle_brand}</span>
                    )}
                  </td>
                  <td className={classes.inputNumberCell}>
                    {editable && !values.confirmed ? (
                      <NormalInputField name="vehicle_seats" />
                    ) : (
                      <span>{values.vehicle_seats}</span>
                    )}
                  </td>
                  <td className={classes.inputNumberCell}>
                    {editable && !values.confirmed ? (
                      <NormalInputField name="vehicles_no" />
                    ) : (
                      <span>{values.vehicles_no}</span>
                    )}
                  </td>
                  {editable && (
                    <td className={classes.cell}>
                      {values.confirmed ? (
                        <Button
                          appearance="ghost"
                          size="xs"
                          onClick={() => setFieldValue("confirmed", false)}>
                          <strong>Edit</strong>
                        </Button>
                      ) : (
                        <Button
                          size="xs"
                          color="green"
                          onClick={() => setFieldValue("confirmed", true)}>
                          <strong>Confirm</strong>
                        </Button>
                      )}
                    </td>
                  )}
                </tr>
              </tbody>
            </table> */}
          </Form>
        )}
      </Formik>
    </div>
  );
}
VehicleInformation.defaultProps = {
  vehicle_information: {},
};
VehicleInformation.propTypes = {
  initialVehicleInfoData: PropTypes.object.isRequired,
  editable: PropTypes.bool.isRequired,
  vehicle_information: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};
