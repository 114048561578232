import _ from "lodash";
import { createSelector } from "reselect";

const getAddOnsList = (state) =>
  _.get(state, "myLibraryAddonsList.results", []);

const propsInjector = (state, props) => props;

export const getAddOnsListSelector = createSelector(
  [getAddOnsList, propsInjector],
  (addons, props) => {
    const { addOnId } = props;

    return addons.find((addon) => addon.id == addOnId);
  }
);

/**
 * Returns the duration of an addon in milliseconds.
 * @param {any} addon
 */
export const getAddOnDuration = (addon) =>
  _.get(addon, "items", []).reduce(
    (a, b) =>
      a + b.visit_duration + _.get(b, "departure_route.duration", 0) / 60,
    0
  ) *
  60 *
  1000;

export const getAddOnItemDuration = (item) => item.visit_duration * 60 * 1000;

export const getAddOnItemTransitDuration = (item) =>
  _.get(item, "departure_route.duration", 0) * 1000;

export const getAddOnTotalItemDuration = (addon) =>
  addon.items.reduce((a, b) => a + getAddOnItemDuration(b), 0);

export const getAddOnTotalTransitDuration = (addon) =>
  addon.items.reduce((a, b) => a + getAddOnItemTransitDuration(b), 0);

export const getAddOnItemTransitDistance = (item) =>
  _.get(item, "departure_route.distance", 0);

export const getAddOnTotalDistance = (addon) =>
  addon.items.reduce((a, b) => a + getAddOnItemTransitDistance(b), 0);
