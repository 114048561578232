import { DatePickers } from "./MktSectionsPanel";
import { Table, Input, SelectPicker, Tag, IconButton, Icon } from "rsuite";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";
import {
  addStopSales,
  changeStopSales,
  removeStopSales,
} from "@src/actions/Project/TripPlanner";

class StopSalesSection extends React.PureComponent {
  constructor(props) {
    super(props);
  }
  render() {
    var { stop_sales, onAdd, onRemove, onChange } = this.props;
    stop_sales = stop_sales.map((stop, idx) => ({ ...stop, idx }));

    return (
      <div className="MktSectionsPanel__stopsales">
        <Table data={stop_sales} rowHeight={60}>
          <Table.Column width={120}>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.Cell>
              {(rowData) => (
                <Input
                  value={rowData.name}
                  onChange={function (value) {
                    onChange(rowData.idx, "name", value);
                  }}
                />
              )}
            </Table.Cell>
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>Distribution From - To</Table.HeaderCell>
            <Table.Cell>
              {(rowData) => (
                <DatePickers
                  idx={rowData.idx}
                  attr="distribution"
                  value={[rowData.distribution_from, rowData.distribution_to]}
                  onChange={onChange}
                />
              )}
            </Table.Cell>
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>Operation From - To</Table.HeaderCell>
            <Table.Cell>
              {(rowData) => (
                <DatePickers
                  idx={rowData.idx}
                  attr="operation"
                  value={[rowData.operation_from, rowData.operation_to]}
                  onChange={onChange}
                />
              )}
            </Table.Cell>
          </Table.Column>
          <Table.Column width={120}>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.Cell>
              {(rowData) => (
                <SelectPicker
                  cleanable={false}
                  placement="auto"
                  searchable={false}
                  value={rowData.status}
                  data={[
                    { value: "AC", label: "Active" },
                    { value: "IN", label: "Inactive" },
                  ]}
                  renderMenuItem={function (label, item) {
                    return (
                      <Tag color={item.value == "AC" ? "green" : "red"}>
                        <span>
                          <strong>{label}</strong>
                        </span>
                      </Tag>
                    );
                  }}
                  renderValue={function (value, item) {
                    return (
                      <Tag color={item.value == "AC" ? "green" : "red"}>
                        <span>
                          <strong>{item.label}</strong>
                        </span>
                      </Tag>
                    );
                  }}
                  onChange={function (value) {
                    onChange(rowData.idx, "status", value);
                  }}
                />
              )}
            </Table.Cell>
          </Table.Column>
          <Table.Column width={100} fixed="right" verticalAlign="middle">
            <Table.HeaderCell>Actions</Table.HeaderCell>
            <Table.Cell>
              {(rowData) => (
                <IconButton
                  icon={<Icon icon="close" />}
                  size="xs"
                  color="red"
                  onClick={function () {
                    onRemove(rowData.idx);
                  }}>
                  Remove
                </IconButton>
              )}
            </Table.Cell>
          </Table.Column>
        </Table>
        <div className="MktSectionsPanel__stopsales__actions">
          <span>
            <strong>Actions</strong>
          </span>
          <IconButton
            icon={<Icon icon="plus" />}
            color="green"
            size="xs"
            onClick={onAdd}>
            Add
          </IconButton>
        </div>
      </div>
    );
  }
}

StopSalesSection.propTypes = {
  stop_sales: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const stop_sales = state.tripPlannerMktStopSales.stop_sales;
  return { stop_sales };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAdd: () => dispatch(addStopSales()),
    onRemove: (idx) => dispatch(removeStopSales(idx)),
    onChange: (idx, key, value) => dispatch(changeStopSales(idx, key, value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StopSalesSection);
