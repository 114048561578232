import {
  TRIPPLANNER_INIT,
  TRIPPLANNER_LOAD_TRIP,
} from "@src/actions/Project/TripPlanner/types";

const TRIPPLANNER_SAVE_AIDIALOGS = "TRIPPLANNER_SAVE_AIDIALOGS";

export function saveAiDialogs(ai_dialogs) {
  return { type: TRIPPLANNER_SAVE_AIDIALOGS, ai_dialogs };
}

export const tripAiDialogsReducer = (state = [], action) => {
  switch (action.type) {
    case TRIPPLANNER_INIT: {
      return [];
    }
    case TRIPPLANNER_LOAD_TRIP: {
      const {
        data: { ai_dialogs },
      } = action;
      return ai_dialogs;
    }
    case TRIPPLANNER_SAVE_AIDIALOGS: {
      const { ai_dialogs } = action;
      return ai_dialogs;
    }
    default:
      return state;
  }
};
