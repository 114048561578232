import _ from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  handleNonValidImgFn,
  humanizeDurationFromMins,
} from "@src/tools/common_tools";
import { DateTime } from "luxon";
import { FerryPrice } from "./FerryPrice";
// export function onViewFerryDetails({
//   history,
//   token,
//   provider,
//   page_session_id,
// }) {
//   const data = window.btoa(JSON.stringify({ provider, token }));
//   history.push({
//     pathname: `/ferry_details/${page_session_id}`,
//     search: `?data=${data}`,
//   });
// }
const common_assets_s3 =
  "https://apollo-common-assets.s3.eu-west-3.amazonaws.com/images";

export const Ferry = ({
  ferry,
  cheapest,
  fastest,
  isSelected = false,
  onContinue,
}) => {
  //   const { isRoundtrip, ferries_computed_data } = useSelector((state) => {
  //     return {
  //       isRoundtrip: state.FerrySearchForm.isRoundtrip,
  //       ferries_computed_data:
  //         getFilteredSessionFerriesSelector(state).computed_data,
  //     };
  //   });

  const goOperator = ferry?.legs?.[0]?.operator ?? {};
  const returnOperator = ferry?.legs?.[1]?.operator ?? {};
  const legs = ferry?.legs ?? [];

  return (
    <div className="Ferry">
      {!_.isEmpty(ferry) && (
        <React.Fragment>
          <div className="Ferry__header">
            <div className="Ferry__header__provider">
              <img
                src={goOperator.logo}
                onError={handleNonValidImgFn}
                alt=""
                className="Ferry__header__provider--img"
              />
              {goOperator.name}
              {returnOperator.name &&
                returnOperator.name !== goOperator.name && (
                  <React.Fragment>
                    <img
                      src={returnOperator.logo}
                      onError={handleNonValidImgFn}
                      alt=""
                      className="Ferry__header__provider--img"
                    />
                    {returnOperator.name}
                  </React.Fragment>
                )}
            </div>
            <div className="Ferry__header__bestRouteCntr">
              {isSelected && (
                <span className="Ferry__header__bestRouteCntr--labelFastest">
                  Selected
                </span>
              )}
              {fastest === (ferry?.legs?.[0]?.duration_minutes ?? 0) && (
                <span className="Ferry__header__bestRouteCntr--labelFastest">
                  Fastest
                </span>
              )}
              {cheapest === (ferry?.price?.value ?? 0) && (
                <span className="Ferry__header__bestRouteCntr--labelCheapest">
                  Best Price
                </span>
              )}
            </div>
            <div className="Ferry__header__priceAndActionsContr">
              <PriceAndActions ferry={ferry} onContinue={onContinue} />
            </div>
          </div>
          <div
            className="Ferry__content"
            data-selected={isSelected ? "true" : "false"}>
            {legs.map((leg, idx) =>
              !leg.is_return_leg ? (
                <TripInfo key={idx} leg={leg} />
              ) : (
                <React.Fragment key={idx}>
                  <img
                    src={common_assets_s3 + "/dashed_line_FER_desktop.svg"}
                    alt="divider"
                    className="Ferry__content__divider"
                  />
                  <TripInfo leg={leg} />
                </React.Fragment>
              )
            )}
          </div>
          <BtmInfo
            checkin_before_minutes={
              ferry?.legs?.[0]?.checkin_before_minutes ?? 0
            }
          />
        </React.Fragment>
      )}
    </div>
  );
};
Ferry.defaultProps = {
  ferry: null,
  page_session_id: "",
  cheapest: 0,
  fastest: 0,
  isSelected: false,
};
Ferry.propTypes = {
  ghost: PropTypes.bool,
  ferry: PropTypes.object,
  cheapest: PropTypes.number,
  fastest: PropTypes.number,
  page_session_id: PropTypes.string,
  isSelected: PropTypes.bool.isRequired,
  onContinue: PropTypes.func.isRequired,
};

export const PriceAndActions = ({ ferry, onContinue }) => {
  return (
    <div className="PriceAndActions">
      <div className="PriceAndActions__totalPrice">
        <span className="PriceAndActions__totalPrice__priceTextLabel">
          From{" "}
        </span>
        <FerryPrice
          price={Number(ferry.price.value)}
          currency={ferry.price.currency}
        />
      </div>
      <button
        onClick={onContinue}
        className="PriceAndActions__totalPrice__continueBtn">
        Continue
      </button>
    </div>
  );
};
PriceAndActions.defaultProps = {
  ferry: {},
  page_session_id: "",
  customStyles: {},
};
PriceAndActions.propTypes = {
  ferry: PropTypes.object.isRequired,
  page_session_id: PropTypes.string,
  customStyles: PropTypes.object,
  onContinue: PropTypes.func.isRequired,
};

export const TripInfo = ({ leg }) => {
  return (
    <div className="TripInfo">
      <div className="TripInfo__originSection">
        <span className="TripInfo__originSection__port">
          {leg.departure_port_name}
        </span>
        <span className="TripInfo__originSection__tripTime">
          {DateTime.fromISO(leg.departure_datetime)
            .setZone("UTC", {
              keepLocalTime: false,
            })
            .toLocaleString(DateTime.TIME_SIMPLE)}
        </span>
        <span className="TripInfo__originSection__tripDate">
          {DateTime.fromISO(leg.departure_datetime)
            .setZone("UTC", {
              keepLocalTime: false,
            })
            .toLocaleString(DateTime.DATE_MED)}
        </span>
      </div>
      <div className="TripInfo__durationSection">
        <div className="TripInfo__durationSection__duration">
          {humanizeDurationFromMins(leg.duration_minutes)}
        </div>
        <div className="TripInfo__durationSection__arrowContainer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="95"
            height="20"
            viewBox="0 0 95 20"
            fill="none">
            <path
              d="M0.666667 8C0.666666 10.9455 3.05448 13.3333 6 13.3333C8.94552 13.3333 11.3333 10.9455 11.3333 8C11.3333 5.05448 8.94552 2.66667 6 2.66667C3.05448 2.66667 0.666667 5.05448 0.666667 8ZM94.7071 8.70711C95.0976 8.31659 95.0976 7.68343 94.7071 7.2929L88.3431 0.928939C87.9526 0.538415 87.3195 0.538415 86.9289 0.928939C86.5384 1.31946 86.5384 1.95263 86.9289 2.34315L92.5858 8.00001L86.9289 13.6569C86.5384 14.0474 86.5384 14.6806 86.9289 15.0711C87.3195 15.4616 87.9526 15.4616 88.3431 15.0711L94.7071 8.70711ZM6 9L94 9.00001L94 7.00001L6 7L6 9Z"
              className="TripInfo__durationSection__arrowContainer__arrow"
            />
          </svg>
        </div>
        <div className="TripInfo__durationSection__ferryName">
          {leg?.vessel_data?.name ?? ""}
        </div>
      </div>
      <div className="TripInfo__destinationSection">
        <span className="TripInfo__destinationSection__port">
          {leg.arrival_port_name}
        </span>
        <span className="TripInfo__destinationSection__tripTime">
          {DateTime.fromISO(leg.arrival_datetime)
            .setZone("UTC", { keepLocalTime: false })
            .toLocaleString(DateTime.TIME_SIMPLE)}
        </span>
        <span className="TripInfo__destinationSection__tripDate">
          {DateTime.fromISO(leg.arrival_datetime)
            .setZone("UTC", { keepLocalTime: false })
            .toLocaleString(DateTime.DATE_MED)}
        </span>
      </div>
    </div>
  );
};
TripInfo.defaultProps = { leg: {}, isRoundtrip: false };
TripInfo.propTypes = {
  leg: PropTypes.object.isRequired,
  isRoundtrip: PropTypes.bool.isRequired,
};

export const labelAndMsg = [
  ["Latest Check-in", "checkIin"],
  ["Cabins/ Seating/ Extras", "Select your cabin, seating and extras"],
  // ["View Timetables", "Timetables"],
];

export const BtmInfo = ({ checkin_before_minutes }) => {
  const [selection, setSelection] = useState(0);
  function onSelect(input) {
    setSelection(input === selection ? -1 : input);
  }

  return (
    <div className="BtmInfo">
      <div className="BtmInfo__btnsContr">
        {new Array(labelAndMsg.length).fill(0).map((x, idx) => (
          <div
            key={idx}
            className={
              idx === selection
                ? "BtmInfo__btnsContr--btnActive"
                : "BtmInfo__btnsContr--btnDefault"
            }
            onClick={() => onSelect(idx)}>
            {labelAndMsg[idx][0]}
          </div>
        ))}
      </div>
      {selection >= 0 && (
        <div className="BtmInfo__msg">
          {selection > 0
            ? labelAndMsg[selection][1]
            : `${humanizeDurationFromMins(
                checkin_before_minutes
              )} Before Departure
              `}
        </div>
      )}
    </div>
  );
};
BtmInfo.defaultProps = {
  checkin_before_minutes: 0,
};
BtmInfo.propTypes = {
  checkin_before_minutes: PropTypes.number,
};
