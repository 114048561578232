import { chatUpdate } from "@src/actions/Chat";
import { SidebarContext } from "@src/components/layout/Container";
import { getUserMetaSelector } from "@src/selectors/Shared/user_selectors";
import _ from "lodash";
import { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useWebSocket, { ReadyState } from "react-use-websocket";

export const useList = ({ filters, fetchFn }) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const fetchData = useCallback(async () => {
    setLoading(true);
    await dispatch(fetchFn());
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    if (!filters) return;
    fetchData();
  }, [filters]);

  return { loading, setLoading, fetchData };
};

export const useReports = () => {
  const [showDownloadPDFModal, setShowDownloadPDFModal] = useState(false);
  const [showExportExcelModal, setShowExportExcelModal] = useState(false);
  return {
    showDownloadPDFModal,
    showExportExcelModal,
    setShowExportExcelModal,
    setShowDownloadPDFModal,
  };
};

export const useCommunication = ({ reservation }) => {
  const booking_mode = _.get(reservation, "booking_mode");
  const status = _.get(reservation, "status");

  const allowedEmailTypes =
    ["RCO", "RQ"].includes(booking_mode) && status !== "CA" ? ["RQ"] : [];

  if (["CO", "BO"].includes(status)) {
    allowedEmailTypes.push("VO");
  }

  return { allowedEmailTypes };
};

export function useChatNotifier() {
  const [initMsgSent, setInitMsgSent] = useState(false);
  const dispatch = useDispatch();

  const { member_email, chatNotifications } = useSelector((state) => ({
    member_email: _.get(getUserMetaSelector(state), "user.email", ""),
    chatNotifications: state.ChatReducer.notifications,
  }));

  const ws_url = `wss://sindarin.easytravel.tech:8792/ws/chat_notifier/${member_email}/`;
  const { sendMessage, lastMessage, readyState } = useWebSocket(ws_url);

  const connectionStatus = {
    [ReadyState.CONNECTING]: "connecting",
    [ReadyState.OPEN]: "open",
    [ReadyState.CLOSING]: "closing",
    [ReadyState.CLOSED]: "closed",
    [ReadyState.UNINSTANTIATED]: "uninstantiated",
  }[readyState];

  const updateChatNotifications = useCallback(
    (data) => {
      dispatch(chatUpdate(data));
    },
    [dispatch]
  );

  function sendInitialMsg() {
    sendMessage(JSON.stringify({ is_initial: true }));
    setInitMsgSent(true);
  }

  useEffect(() => {
    if (!lastMessage) {
      return;
    }

    updateChatNotifications({
      notifications: [
        ...chatNotifications,
        JSON.parse(lastMessage.data),
      ].filter((notif) => notif.sender_email !== member_email),
    });
  }, [lastMessage]);

  useEffect(() => {
    if (!connectionStatus === "open") {
      return;
    }

    if (initMsgSent) {
      return;
    }

    sendInitialMsg();
  }, [initMsgSent, connectionStatus]);
}
