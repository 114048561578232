import {
  B2B_ACCOMMODATION_LIST_FILTER_CHANGE,
  B2B_ACCOMMODATION_RESERVATION_LIST_INIT,
  B2B_ACCOMMODATION_RESET_LIST_FILTER_FORM,
} from "@src/actions/Operation/Accommodation/types";
import { commonList } from "@src/reducers/common";

export const initialAccommodationsList = {
  ...commonList,
  displayLength: 100,
  filters: {
    market_type: "B2B",
    reference: "",
    external_reference: "",
    source_entity_autocomplete: "",
    source_entity: "",
    accommodation_meta__name__icontains: "",
    destination_name__icontains: "",
    provider__icontains: "",
    provider_reference: "",
    supplier_reservation_reference__icontains: "",
    status: "",
    non_refundable: "",
    option_booking: "",
    accommodation_id: "",
    check_in_from: "",
    check_in_to: "",
    check_out_from: "",
    check_out_to: "",
    created__gte: "",
    created__lte: "",
    creator_email: "",
    supplier_reservation_reference: "",
    is_voucher_sent: "",
    room_leader_name__icontains: "",
    guest_pax: "",
    room_number: "",
    stay: "",
    page: 1,
    limit: 20,
    ordering: "",
    tags: "",
    provider: "",
  },
};
export const b2bAccReservationsList = (
  state = initialAccommodationsList,
  action
) => {
  switch (action.type) {
    case B2B_ACCOMMODATION_RESERVATION_LIST_INIT:
      return initialAccommodationsList;
    case B2B_ACCOMMODATION_RESET_LIST_FILTER_FORM:
      return { ...state, page: 1 };
    case B2B_ACCOMMODATION_LIST_FILTER_CHANGE: {
      const { filters } = action;
      return { ...state, filters: { ...state.filters, ...filters } };
    }
    default:
      return state;
  }
};
