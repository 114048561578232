import {
  // =========================== LIST VIEW ===========================
  // TRF_RESERVATION_BEGIN_FETCH_DATA,
  // TRF_RESERVATION_FETCH_DATA,
  // TRF_RESERVATION_END_FETCH_DATA,
  // TRF_RESERVATION_FETCH_OPTIONS,

  // ======================== FILTER RELATION ========================
  // TRF_RESERVATION_UPDATE_LIST_FILTER_FORM,
  // TRF_RESERVATION_APPLY_LIST_FILTER,
  // TRF_RESERVATION_INITIALIZE_LIST_FILTER_FORM,
  // TRF_RESERVATION_RESET_LIST_FILTER_FORM,

  // ======================== COLUMNS RELATION =======================
  // TRF_RESERVATION_UPDATE_LIST_COLUMNS_FORM,
  // TRF_RESERVATION_APPLY_LIST_COLUMNS,
  // TRF_RESERVATION_INITIALIZE_LIST_COLUMNS_FORM,

  // ========================== DETAIL VIEW ==========================
  TRF_RESERVATION_INIT,
  TRF_RESERVATION_FETCH_DETAIL_VIEW,
  TRF_RESERVATION_DATA,

  //========================DASHBOARD===============================
  TRF_RESERVATION_VIEW_TYPE,
  TRF_RESERVATION_FETCH_GRAPH_DATA,
  TRF_RESERVATION_BEGIN_FETCH_GRAPH_DATA,
  TRF_RESERVATION_END_FETCH_GRAPH_DATA,
  TRF_RESERVATION_INIT_GRAPH_TYPE,
} from "@src/actions/Operation/Transfers/types";
// import { FORMAT_TRAVEL_SERVICES_FORM } from "@src/actions/Shares/types";

// import { formProcessor, entitiesListSelector } from "@src/reducers/tools";

// import {
//   initialTransferColumnsSetUp,
//   initialTransferFilterForm,
// } from "@src/forms/Operation/Transfer/transferForms";

import update from "immutability-helper";
// import _ from "lodash";
import {
  TRF_RESERVATION_SHOW_BOOKING_INFO_MODAL,
  TRF_RESERVATION_HIDE_BOOKING_INFO_MODAL,
} from "@src/actions/Operation/Transfers/types";

// ============================ LIST VIEW ============================
// export const initialTransferOptionsFetchStatus = "IDLE";
// export const TransferOptionsFetchStatus = (
//   state = initialTransferOptionsFetchStatus,
//   action
// ) => {
//   switch (action.type) {
//     case TRF_RESERVATION_FETCH_OPTIONS:
//       return "FETCHED";
//     default:
//       return state;
//   }
// };

// export const transferOptions = (state = {}, action) => {
//   switch (action.type) {
//     case TRF_RESERVATION_FETCH_OPTIONS:
//       return action.data;
//     default:
//       return state;
//   }
// };

// export const initialTransferList = {
//   count: 0,
//   next: null,
//   previous: null,
//   results: [],
//   page: 1,
//   limit: 20,
//   displayLength: 100,
// };

// export const transferList = (state = initialTransferList, action) => {
//   switch (action.type) {
//     case TRF_RESERVATION_FETCH_DATA:
//       return action.data;
//     case TRF_RESERVATION_RESET_LIST_FILTER_FORM:
//       return { ...state, page: 1 };
//     default:
//       return state;
//   }
// };

// export const initialTransferListFetchStatus = "IDLE";
// export const transferListFetchStatus = (
//   state = initialTransferListFetchStatus,
//   action
// ) => {
//   switch (action.type) {
//     case TRF_RESERVATION_BEGIN_FETCH_DATA:
//       return "FETCHING";
//     case TRF_RESERVATION_END_FETCH_DATA:
//       return "FETCHED";
//     default:
//       return state;
//   }
// };

// ========================= COLUMNS RELATION ========================
// export const transferListColumns = (
//   state = entitiesListSelector(initialTransferColumnsSetUp),
//   action
// ) => {
//   switch (action.type) {
//     case TRF_RESERVATION_APPLY_LIST_COLUMNS:
//       return entitiesListSelector(action.data);
//     default:
//       return state;
//   }
// };

// export const transferListColumnsForm = (
//   state = initialTransferColumnsSetUp,
//   action
// ) => {
//   switch (action.type) {
//     case TRF_RESERVATION_INITIALIZE_LIST_COLUMNS_FORM: {
//       var newState = {};
//       const tmp = _.cloneDeep(state);
//       for (const key in tmp) {
//         // clear form's value
//         tmp[key] = update(tmp[key], { value: { $set: [] } });
//         newState[key] = update(tmp[key], { value: { $set: [] } });
//         // add value by action data
//         action.data.forEach((item) => {
//           if (_.find(tmp[key].choices, { key: item.key })) {
//             newState[key] = update(newState[key], {
//               value: { $push: [item.key] },
//             });
//           }
//         });
//       }
//       return newState;
//     }
//     case TRF_RESERVATION_UPDATE_LIST_COLUMNS_FORM:
//       return action.data;
//     default:
//       return state;
//   }
// };

// ========================= FILTER RELATION =========================
// export const transferListFilter = (
//   state = initialTransferFilterForm,
//   action
// ) => {
//   switch (action.type) {
//     case TRF_RESERVATION_APPLY_LIST_FILTER:
//       return action.data;
//     case TRF_RESERVATION_RESET_LIST_FILTER_FORM:
//       return initialTransferFilterForm;
//     default:
//       return state;
//   }
// };

// export const transferListFilterForm = (
//   state = initialTransferFilterForm,
//   action
// ) => {
//   switch (action.type) {
//     case TRF_RESERVATION_INITIALIZE_LIST_FILTER_FORM:
//       return action.data;
//     case TRF_RESERVATION_RESET_LIST_FILTER_FORM:
//       return initialTransferFilterForm;
//     case TRF_RESERVATION_UPDATE_LIST_FILTER_FORM:
//       return formProcessor(
//         state,
//         action.data.type,
//         action.data.name,
//         action.data.value
//       );
//     case FORMAT_TRAVEL_SERVICES_FORM:
//       return _.assign(state, action.data);
//     default:
//       return state;
//   }
// };

// =========================== DETAIL VIEW ===========================
export const initialTransferNavStatus = "reservation";
export const TransferNavStatus = (state = initialTransferNavStatus, action) => {
  switch (action.type) {
    case TRF_RESERVATION_INIT:
      return "reservation";
    case TRF_RESERVATION_FETCH_DETAIL_VIEW:
      return action.data;
    default:
      return state;
  }
};

export const transferReservationInfo = (state = {}, action) => {
  switch (action.type) {
    case TRF_RESERVATION_INIT:
      return {};
    case TRF_RESERVATION_DATA:
      return action.data;
    default:
      return state;
  }
};

// ======================== BOOKING INFO MODAL =======================
export const transferReservationBookingInfoModalInitial = {
  show: false,
  loading: false,
  transfer_type: "inbound", // inbound, outbound
};
export const transferReservationBookingInfoModal = (
  state = transferReservationBookingInfoModalInitial,
  action
) => {
  switch (action.type) {
    case TRF_RESERVATION_SHOW_BOOKING_INFO_MODAL: {
      const { transfer_type } = action;
      return update(state, {
        show: { $set: true },
        transfer_type: { $set: transfer_type },
      });
    }
    case TRF_RESERVATION_HIDE_BOOKING_INFO_MODAL:
      return transferReservationBookingInfoModalInitial;
    default:
      return state;
  }
};

//====================================DASHBOARD===============================
export const initData = {
  type: "current",
  name: "SR",
};
export const transferViewType = (state = initData, action) => {
  switch (action.type) {
    case TRF_RESERVATION_INIT_GRAPH_TYPE:
      return initData;
    case TRF_RESERVATION_VIEW_TYPE:
      return action.data;
    default:
      return state;
  }
};

export const transferGraphData = (state = {}, action) => {
  switch (action.type) {
    case TRF_RESERVATION_FETCH_GRAPH_DATA:
      return action.data;
    default:
      return state;
  }
};

export const initialTransferGraphDataStatus = "IDLE";
export const transferGraphDataStatus = (
  state = initialTransferGraphDataStatus,
  action
) => {
  switch (action.type) {
    case TRF_RESERVATION_BEGIN_FETCH_GRAPH_DATA:
      return "FETCHING";
    case TRF_RESERVATION_END_FETCH_GRAPH_DATA:
      return "FETCHED";
    default:
      return state;
  }
};
