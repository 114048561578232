import { Modal, IconButton, Icon, Loader } from "rsuite";

import ReactQuill from "react-quill";

// ============================= ACTIONS =============================
import {
  hideBookingMessageModal,
  changeBookingMessage,
  saveBookingMessage,
} from "@src/actions/Project/TripPlanner";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";

class BookingMessageModal extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { msg, show, loading, onHide, onChangeMsg, onSave } = this.props;

    return (
      <Modal
        className={`CustomModal${
          loading ? " CustomModal--loading" : ""
        } BookingMessageModal`}
        show={show}
        onHide={onHide}>
        <Modal.Header>
          <Modal.Title>Booking Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <Loader vertical content="Please wait..." size="lg" />
          ) : (
            <ReactQuill
              className="custom-quill"
              value={msg}
              onChange={(value, delta, source) => {
                if (source == "api") {
                  return;
                }

                onChangeMsg(value);
              }}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          {loading ? null : (
            <React.Fragment>
              <IconButton
                color="blue"
                icon={<Icon icon="close" />}
                onClick={onHide}>
                Close
              </IconButton>
              <IconButton
                color="green"
                icon={<Icon icon="save" />}
                onClick={onSave}>
                Save
              </IconButton>
            </React.Fragment>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}

BookingMessageModal.propTypes = {
  msg: PropTypes.string,
  show: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onChangeMsg: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { show, loading } = state.tripPlannerBookingMessageModal;
  return {
    msg: state.tripPlannerBookingMessage,
    show,
    loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onHide: () => dispatch(hideBookingMessageModal()),
    onChangeMsg: (msg) => dispatch(changeBookingMessage(msg)),
    onSave: () => dispatch(saveBookingMessage()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BookingMessageModal);
