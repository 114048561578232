export const GENERAL_SERVICES_SERVICE_MODAL_SHOW =
  "GENERAL_SERVICES_SERVICE_MODAL_SHOW";
export const GENERAL_SERVICES_SERVICE_MODAL_HIDE =
  "GENERAL_SERVICES_SERVICE_MODAL_HIDE";

export const GENERAL_SERVICES_IMPORT_SERVICE_MODAL_SHOW =
  "GENERAL_SERVICES_IMPORT_SERVICE_MODAL_SHOW";
export const GENERAL_SERVICES_IMPORT_SERVICE_MODAL_HIDE =
  "GENERAL_SERVICES_IMPORT_SERVICE_MODAL_HIDE";

export const GENERAL_SERVICES_LIST_GENERIC_CHANGE =
  "GENERAL_SERVICES_LIST_GENERIC_CHANGE";
export const GENERAL_SERVICES_FETCH_LIST = "GENERAL_SERVICES_FETCH_LIST";
