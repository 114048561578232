import PropTypes from "prop-types";

import {
  getJWTPermissions,
  isSubscriptionPaid,
  useProfileAndCompanyCompleteness,
} from "@src/tools/auth_tools";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { getAppUserGroups } from "@src/api";
import { toast } from "react-toastify";
import TransTxt from "@src/components/common/SxFormatMessage";

const Authorize = ({
  children,
  allowed_groups,
  required_groups,
  user_required_groups,
  noMatch,
}) => {
  const {
    isProfileLoading,
    isProfileComplete,
    isCompanyProfileLoading,
    isCompanyProfileComplete,
  } = useProfileAndCompanyCompleteness();

  const { userGroups, top_entity_groups, userId } = useSelector((state) => {
    const { groups: userGroups, top_entity_groups } = state.userPerms;
    const userId = state.userMeta?.id;
    return { userGroups, top_entity_groups, userId };
  });

  const queryAppUserGroup = useQuery({
    queryKey: ["appUserGroup", userId],
    queryFn: () => getAppUserGroups({ app_user: userId }),
    onError: (error) => {
      toast.error(
        <p>
          <TransTxt id="Users_detailsPermissions__unable_to_get_the_user_group" />
          <br />
          <span>{error?.response?.data?.message}</span>
          <br />
          <span>{error?.response?.data?.error}</span>
        </p>,
        { duration: 5000 }
      );
    },
    enabled: !!userId,
    refetchOnWindowFocus: false,
  });
  const uGroups = queryAppUserGroup?.data?.data?.results ?? [];

  function getUserPerms() {
    const perms = getJWTPermissions();

    const { mark, permissions } = perms;

    const userPerms = [
      ...permissions,
      ...[mark.company_type],
      ...Object.entries(mark) // Inject data completeness status perms.
        .filter((e) => e[0] !== "company_type")
        .filter((e) => e[1])
        .map((e) => e[0]),
      ...[isSubscriptionPaid() ? "no_pending_payments" : "no_pending_payments"],
    ].filter((p) => p !== "");

    return { userPerms };
  }

  if (!uGroups.length) {
    return noMatch || null;
  }

  if (!required_groups && !allowed_groups) {
    return children;
  }

  var { userPerms } = getUserPerms();

  // Inject data completeness status perms if query is complete.
  // The JWT will already have this information but is less frequently updated.
  // That's why we also check the query.
  if (!isProfileLoading && isProfileComplete) {
    userPerms.push("complete_user_profile");
  }
  if (!isCompanyProfileLoading && isCompanyProfileComplete) {
    userPerms.push("complete_company_profile");
  }

  userPerms = [...new Set(userPerms)];

  const groups = [
    ...new Set([
      ...userPerms,
      ...userGroups,
      ...top_entity_groups,
      ...uGroups.map((g) => g.group.codename.toLowerCase()),
    ]),
  ];

  if (
    allowed_groups.length &&
    groups.every((grp) => !allowed_groups.includes(grp))
  )
    return noMatch || null;

  if (required_groups.some((p) => !groups.includes(p))) return noMatch || null;

  if (
    user_required_groups.length &&
    user_required_groups.some(
      (group) =>
        !uGroups
          .map((g) => g.group.codename.toLowerCase())
          .includes(group.toLowerCase())
    )
  )
    return noMatch || null;

  return children;
};
Authorize.defaultProps = { allowed_groups: [], user_required_groups: [] };
Authorize.propTypes = {
  allowed_groups: PropTypes.array.isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  noMatch: PropTypes.object,
  required_groups: PropTypes.array,
  user_required_groups: PropTypes.array,
};

export default Authorize;
