import update from "immutability-helper";
import _ from "lodash";

export const formProcessor = (state, type, name, value) => {
  if (type == "push") {
    return update(state, { [name]: { value: { $push: [value] } } });
  }
  if (type == "remove") {
    return update(state, {
      [name]: { value: { $apply: (va) => va.filter((v) => v !== value) } },
    });
  }
  if (type == "set") {
    return update(state, { [name]: { value: { $set: value } } });
  }

  return state;
};

export const travelServicesFormProcessor = (
  state,
  type,
  name,
  value,
  category,
  travelServices
) => {
  if (type == "push") {
    return update(state, { [name]: { value: { $push: [value] } } });
  }
  if (type == "remove") {
    return update(state, {
      [name]: { value: { $apply: (va) => va.filter((v) => v !== value) } },
    });
  }
  if (type == "set") {
    const travel_services = _.cloneDeep(state.travel_services.value);
    _.remove(travel_services, (item) => {
      return (
        _.find(travelServices.data.results, { id: item }).service_type ==
        category
      );
    });

    const res = travel_services.concat(value);

    return update(state, { [name]: { value: { $set: res } } });
  }

  return state;
};

export function entitiesListSelector(data = {}) {
  var columns = [];
  Object.entries(data).forEach((e) => {
    const choices = e[1].choices;
    const value = e[1].value;
    const need = choices.filter((i) => value.indexOf(i.key) > -1);
    columns = [...columns, ...need];
  });
  return columns;
}

export const addressProcessor = (state, data) => {
  const map = new Map(Object.entries(data));

  for (const [key, value] of map) {
    state = update(state, { [key]: { value: { $set: value.value } } });
  }

  return state;
};
