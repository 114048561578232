import { SISI_API_URL } from "@src/api";
import axios from "axios";

export const portAutocomplete = ({ name }) => {
  return axios.get(`${SISI_API_URL}/vesselengine/ports/autocomplete/`, {
    params: { name },
  });
};

export const getPortsByGeolocation = ({
  lat,
  lng,
  radius = 1,
  country_code,
}) => {
  return axios.get(`${SISI_API_URL}/vesselengine/ports/get_by_coordinates`, {
    params: { lat, lng, radius, country_code },
  });
};

export const getFerryRoutesByOriginPort = (originPort) => {
  return axios.get(
    `${SISI_API_URL}/vesselengine/routes/by-origin-port/${originPort}`
  );
};

export const initiateFerriesSearch = (payload) => {
  return axios.post(`${SISI_API_URL}/vesselengine/search/`, payload);
};

export const pollFerriesSearch = (session_id) => {
  return axios.get(`${SISI_API_URL}/vesselengine/search/${session_id}/`);
};

export const retrieveFerryDetails = async ({ session_id, token, provider }) => {
  return axios.post(`${SISI_API_URL}/vesselengine/refine-search/`, {
    session_id,
    token,
    provider,
  });
};

export const getFinalFerryPrice = async ({ payload }) => {
  return axios.post(`${SISI_API_URL}/vesselengine/check-price/`, payload);
};

export const prebookFerry = async ({
  session_id,
  provider,
  token,
  passengers,
  legs,
}) => {
  const payload = { session_id, provider, token, passengers, legs };
  return axios.post(`${SISI_API_URL}/vesselengine/pre-book/`, payload);
};
