import axios from "axios";
import { SISI_API_URL } from "@src/api";
import { getAuthHeaders } from "../request";
import { toast } from "react-toastify";
import React from "react";
import _ from "lodash";

export function getMikiCountries() {
  return axios({
    method: "get",
    url: `${SISI_API_URL}/client-data-transformer/miki-countries`,
  });
}

export function getMikiGeneralServices({ params = {} }) {
  return axios({
    method: "get",
    url: `${SISI_API_URL}/client-data-transformer/miki-services`,
    params,
  });
}

export async function getGeneralServices({ params = {} }) {
  const auth_headers = getAuthHeaders();

  try {
    const result = await axios.get(`${SISI_API_URL}/general-service/`, {
      params,
      headers: { ...auth_headers },
    });
    return _.get(result, "data");
  } catch (error) {
    toast.error(
      <p>
        We failed to fetch General Services. We are sorry for the inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return false;
  }
}

export function getGeneralService(id) {
  const auth_headers = getAuthHeaders();

  return axios({
    method: "get",
    url: `${SISI_API_URL}/general-service/${id}`,
    headers: { ...auth_headers },
  });
}

export function generalServicesExist({ ids = [] }) {
  const auth_headers = getAuthHeaders();

  return axios({
    method: "post",
    url: `${SISI_API_URL}/general-service/ids_exist`,
    data: { ids },
    headers: { ...auth_headers },
  });
}

export function createGeneralService({ data }) {
  const auth_headers = getAuthHeaders();

  return axios({
    method: "post",
    url: `${SISI_API_URL}/general-service/`,
    data,
    headers: { ...auth_headers },
  });
}

export async function patchGeneralService({ id, data }) {
  const auth_headers = getAuthHeaders();

  try {
    const result = await axios({
      method: "patch",
      url: `${SISI_API_URL}/general-service/${id}`,
      data,
      headers: { ...auth_headers },
    });
    return result.data;
  } catch (error) {
    return null;
  }
}

export function batchCreateGeneralService({ services_list }) {
  const auth_headers = getAuthHeaders();

  return axios({
    method: "post",
    url: `${SISI_API_URL}/general-service/batch_create`,
    data: services_list,
    headers: { ...auth_headers },
  });
}

export async function generalSrvPriceSearch({ payload }) {
  const auth_headers = getAuthHeaders();

  try {
    const result = await axios.post(
      `${SISI_API_URL}/general-service/price-search/`,
      payload,
      {
        headers: { ...auth_headers },
      }
    );
    return result.data;
  } catch (error) {
    return false;
  }
}
