import { centerToPoints, getGreatCircleCoords } from "@src/map_tools/map";

import _ from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import React from "react";
import InteractiveMapHoc from "./InteractiveMapHoc";
import { IconButton, Icon } from "rsuite";

class TripMap extends React.Component {
  constructor(props) {
    super(props);

    this.extremalSourceIds = ["originData", "returnData"];
    this.destinationSourceIds = ["destinationData"];

    this.templateTextPaint = {
      "text-color": "black",
      "text-halo-color": "white",
      "text-halo-width": 2,
    };

    this.handleCenter = this.handleCenter.bind(this);
  }
  componentDidUpdate(pp) {
    const { map } = this.props;

    if (!map) {
      return;
    }

    function resize() {
      setTimeout(() => {
        map.resize();
      }, 1000);
    }

    if (pp.map == null && map !== null) {
      map.once("load", () => {
        this.initialActions();
        this.setExtremalData();
        this.setDestinationData();
        this.setDestinationRouteData();
        resize();
        this.center();
      });
    }

    if (map == null || !map.loaded()) {
      return;
    }

    if (pp.mapVersion !== this.props.mapVersion) {
      map.stop();
      this.setExtremalData();
      this.setDestinationData();
      this.setDestinationRouteData();
      this.center();
    }

    if (map.loaded() && pp.layout !== this.props.layout) {
      resize();
      this.center();
    }

    return;
  }
  handleCenter() {
    this.center();
  }
  center() {
    const { map } = this.props;
    const points = [];

    if ([1, 3].includes(this.props.currentStep)) {
      this.extremalSourceIds.forEach((s) => {
        points.push(map.getSource(s)._data.geometry.coordinates);
      });

      this.destinationSourceIds.forEach((s) => {
        map
          .getSource(s)
          ._data.features.forEach((f) => points.push(f.geometry.coordinates));
      });
    }

    centerToPoints(
      map,
      points.filter((pp) => pp.every((p) => p !== 0)),
      {
        zoom: 5,
      }
    );
  }
  initialActions() {
    this.setSources();
    this.setLayers();
  }
  setExtremalSources() {
    const { map } = this.props;

    const template = {
      type: "geojson",
      data: {
        type: "Feature",
        geometry: { type: "Point", coordinates: [0, 0] },
        properties: { title: "", roundtrip: false, visibility: "hidden" },
      },
    };

    map.addSource("originData", template);
    map.addSource("returnData", template);
  }
  setDestinationSources() {
    const { map } = this.props;
    map.addSource("destinationData", {
      type: "geojson",
      data: { type: "Feature", features: [] },
    });
  }
  setDestinationRouteSources() {
    const { map } = this.props;
    map.addSource("destinationRouteData", {
      type: "geojson",
      data: {
        type: "Feature",
        properties: { visibility: "visible" },
        geometry: { type: "LineString", coordinates: [] },
      },
    });
  }
  setSources() {
    this.setDestinationRouteSources();
    this.setExtremalSources();
    this.setDestinationSources();
  }
  setExtremalLayers() {
    const { map } = this.props;

    const templatePoint = {
      type: "circle",
      filter: ["==", ["get", "visibility"], "visible"],
    };

    const templatePointPaint = {
      "circle-stroke-width": 2,
      "circle-radius": 20,
    };

    map.addLayer({
      ...{
        id: "returnPoint",
        source: "returnData",
        paint: {
          ...templatePointPaint,
          ...{ "circle-color": "#FF8D02", "circle-stroke-color": "#FFB102" },
        },
      },
      ...templatePoint,
    });

    map.addLayer({
      ...templatePoint,
      ...{
        id: "originPoint",
        source: "originData",
        paint: {
          ...templatePointPaint,
          ...{ "circle-color": "#81c784", "circle-stroke-color": "#a5d6a7" },
        },
      },
    });

    const templateText = {
      type: "symbol",
      filter: ["==", ["get", "visibility"], "visible"],
      layout: { "text-field": "{title}" },
      paint: this.templateTextPaint,
    };

    map.addLayer({
      ...{ id: "returnText", source: "returnData" },
      ...templateText,
    });
    map.addLayer({
      ...{ id: "originText", source: "originData" },
      ...templateText,
    });

    const templateSubText = (key, source) => {
      return {
        id: `${key}SubText`,
        source: source,
        type: "symbol",
        filter: ["==", ["get", "visibility"], "visible"],
        layout: {
          "text-field": [
            "case",
            ["get", "roundtrip"],
            "(Origin - Return)",
            ["concat", "(", _.startCase(key), ")"],
          ],
          "text-anchor": "top",
          "text-size": 12,
          "text-offset": [0, 1.5],
        },
        paint: this.templateTextPaint,
      };
    };
    map.addLayer(templateSubText("return", "returnData"));
    map.addLayer(templateSubText("origin", "originData"));
  }
  setDestinationLayers() {
    const { map } = this.props;

    map.addLayer({
      id: "destinationPoint",
      source: "destinationData",
      type: "circle",
      filter: ["==", ["get", "visibility"], "visible"],
      paint: {
        "circle-stroke-width": 2,
        "circle-radius": 20,
        "circle-color": "#37B9FF",
        "circle-stroke-color": "#00a0e9",
      },
    });

    map.addLayer({
      id: "destinationText",
      source: "destinationData",
      type: "symbol",
      filter: ["==", ["get", "visibility"], "visible"],
      layout: {
        "text-field": [
          "concat",
          ["to-string", ["get", "order"]],
          ". ",
          ["get", "title"],
        ],
      },
      paint: this.templateTextPaint,
    });

    map.addLayer({
      id: "destinationSubText",
      source: "destinationData",
      type: "symbol",
      filter: ["==", ["get", "visibility"], "visible"],
      layout: {
        "text-field": ["concat", "(", ["to-string", ["get", "stay"]], ")"],
        "text-anchor": "top",
        "text-size": 12,
        "text-offset": [0, 1.5],
      },
      paint: this.templateTextPaint,
    });
  }
  setRouteLayers() {
    const { map } = this.props;
    map.addLayer({
      id: "route",
      type: "line",
      source: "destinationRouteData",
      filter: ["==", ["get", "visibility"], "visible"],
      layout: { "line-join": "round", "line-cap": "round" },
      paint: { "line-color": "#5956D8", "line-width": 3 },
    });
  }
  setLayers() {
    this.setRouteLayers();
    this.setExtremalLayers();
    this.setDestinationLayers();
  }
  setExtremalData() {
    const { map } = this.props;

    var reset = false;
    var roundtrip = false;

    if (_.isEmpty(this.props.returnData)) {
      reset = true;
    } else if (this.props.returnData?.destination?.id !== null) {
      if (this.props.originData.destination.id !== null) {
        if (
          this.props.originData.destination.id ==
          this.props.returnData.destination.id
        ) {
          roundtrip = true;
        }
      }
    }

    map.getSource("originData").setData({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [
          this.props.originData?.destination?.geodata?.lng ?? 0,
          this.props.originData?.destination?.geodata?.lat ?? 0,
        ],
      },
      properties: {
        title: this.props.originData?.destination?.name_en,
        roundtrip: roundtrip,
        visibility: "visible",
      },
    });

    map.getSource("returnData").setData({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: reset
          ? [0, 0]
          : [
              this.props.returnData.destination.geodata.lng,
              this.props.returnData.destination.geodata.lat,
            ],
      },
      properties: {
        title: reset ? "" : this.props.returnData.destination.name_en,
        roundtrip: roundtrip,
        visibility: reset ? "hidden" : "visible",
      },
    });

    return null;
  }
  setDestinationData() {
    const { map } = this.props;
    map.getSource("destinationData").setData({
      type: "FeatureCollection",
      features: this.props.destinations
        .filter((d) => d.id !== null)
        .map((d) => {
          return {
            type: "Feature",
            properties: {
              title: d.name_en,
              stay: d.stay == 1 ? "1 Night" : `${d.stay} Nights`,
              order: d.order,
              visibility: "visible",
            },
            geometry: {
              type: "Point",
              coordinates: [d.geodata.lng, d.geodata.lat],
            },
          };
        }),
    });
  }
  setDestinationRouteData() {
    const { map } = this.props;

    var couples = [];
    if (
      this.props.destinations.length > 0 &&
      this.props.originData?.destination?.id !== null
    ) {
      couples.push([
        [
          this.props.originData?.destination?.geodata?.lng ?? 0,
          this.props.originData?.destination?.geodata?.lat ?? 0,
        ],
        [
          this.props.destinations[0].geodata.lng,
          this.props.destinations[0].geodata.lat,
        ],
      ]);
    }

    var extremalEnd = [];
    if (!_.isEmpty(this.props.returnData)) {
      if (this.props.returnData.destination.id !== null) {
        extremalEnd = [
          this.props.returnData.destination.geodata.lng,
          this.props.returnData.destination.geodata.lat,
        ];
      }
    }

    if (this.props.destinations.length > 1) {
      couples = [
        ...couples,
        ..._.zip(
          this.props.destinations.map((d) => [d.geodata.lng, d.geodata.lat]),
          [
            ...this.props.destinations
              .filter((d, idx) => idx > 0)
              .map((d) => [d.geodata.lng, d.geodata.lat]),
            ...[extremalEnd],
          ]
        ),
      ];
    } else if (
      this.props.destinations.length == 1 &&
      this.props.destinations[0].id !== null
    ) {
      couples.push([
        ...[
          [
            this.props.destinations[0].geodata.lng,
            this.props.destinations[0].geodata.lat,
          ],
        ],
        ...[extremalEnd],
      ]);
    }

    couples = couples.filter((c) =>
      c.every(
        (cc) =>
          cc.every((kk) => typeof kk !== "undefined" && kk !== 0) &&
          cc.length == 2 &&
          cc
      )
    );

    couples = couples.filter(
      (c) => [...new Set(c.map((p) => p.join("")))].length != 1
    );
    var greatCircles = [];
    couples.forEach((c) => {
      getGreatCircleCoords(c[0], c[1]).forEach((cc) => {
        if (cc.length == 2 && cc[0] !== 180 && cc[0] !== -180) {
          greatCircles.push(cc);
        } else {
          cc.forEach((ccc) => {
            if (ccc[0] !== 180 && ccc[0] !== -180) {
              greatCircles.push(ccc);
            }
          });
        }
      });
    });

    map.getSource("destinationRouteData").setData({
      type: "Feature",
      properties: { visibility: "visible" },
      geometry: {
        type: "LineString",
        coordinates: greatCircles,
      },
    });
  }
  render() {
    return (
      <div className="TripMap">
        <div id="map"></div>
        <div className="TripMap__controls">
          <IconButton
            icon={<Icon icon="map-marker" />}
            color="blue"
            onClick={this.handleCenter}>
            Center Map
          </IconButton>
        </div>
      </div>
    );
  }
}
TripMap.propTypes = {
  map: PropTypes.object,
  mapVersion: PropTypes.number.isRequired,
  originData: PropTypes.object.isRequired,
  returnData: PropTypes.object.isRequired,
  destinations: PropTypes.array.isRequired,
  currentStep: PropTypes.number.isRequired,
};
const mapStateToProps = (state) => {
  return {
    mapVersion: state.tripPlannerMapDrawVersion,
    originData: state.tripPlannerOriginData,
    returnData: state.tripPlannerReturnData,
    destinations: state.tripPlannerDestinations,
  };
};
export default InteractiveMapHoc(connect(mapStateToProps, null)(TripMap), {
  containerId: "map",
});
