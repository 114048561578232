// //=============================LIST VIEW===========================
// export const COMMUNICATIONS_BEGIN_FETCH_DATA =
//   "COMMUNICATIONS_BEGIN_FETCH_DATA";
// export const COMMUNICATIONS_END_FETCH_DATA = "COMMUNICATIONS_END_FETCH_DATA";
// export const COMMUNICATIONS_FETCH_DATA = "COMMUNICATIONS_FETCH_DATA";
// export const COMMUNICATIONS_FETCH_OPTIONS = "COMMUNICATIONS_FETCH_OPTIONS";
// //=============================FILTER RELATIONS========================
// export const COMMUNICATIONS_INITIALIZE_LIST_FILTER_FORM =
//   "COMMUNICATIONS_INITIALIZE_LIST_FILTER_FORM";
// export const COMMUNICATIONS_UPDATE_LIST_FILTER_FORM =
//   "COMMUNICATIONS_UPDATE_LIST_FILTER_FORM";
// export const COMMUNICATIONS_APPLY_LIST_FILTER =
//   "COMMUNICATIONS_APPLY_LIST_FILTER";
// //=============================COLUMNS RELATIONS=========================
// export const COMMUNICATIONS_APPLY_LIST_COLUMNS =
//   "COMMUNICATIONS_APPLY_LIST_COLUMNS";
// export const COMMUNICATIONS_INITIALIZE_LIST_COLUMNS_FORM =
//   "COMMUNICATIONS_INITIALIZE_LIST_COLUMNS_FORM";

// //================================EDIT===========================
// export const COMMUNICATIONS_FETCH_EDIT_DATA = "COMMUNICATIONS_FETCH_EDIT_DATA";
// export const COMMUNICATIONS_INIT_BASE_DATA_EDIT_FORM =
//   "COMMUNICATIONS_INIT_BASE_DATA_EDIT_FORM";
// export const COMMUNICATIONS_UPDATE_BASE_DATA_FORM =
//   "COMMUNICATIONS_UPDATE_BASE_DATA_FORM";
// export const COMMUNICATIONS_INITIALIZE_BASE_DATA_FORM =
//   "COMMUNICATIONS_INITIALIZE_BASE_DATA_FORM";
// //============================VALIDATOR===========================
// export const COMMUNICATIONS_VALIDATE_BASE_DATA_EDIT_FORM =
//   "COMMUNICATIONS_VALIDATE_BASE_DATA_EDIT_FORM";
// export const COMMUNICATIONS_CLEAN_BASE_EDIT_FORM_ERRORS =
//   "COMMUNICATIONS_CLEAN_BASE_EDIT_FORM_ERRORS";
// //=============================MODAL==========================
// export const COMMUNICATIONS_SHOW_MODAL = "COMMUNICATIONS_SHOW_MODAL";
// export const COMMUNICATIONS_HIDE_MODAL = "COMMUNICATIONS_HIDE_MODAL";
// export const COMMUNICATIONS_LOADING_MODAL = "COMMUNICATIONS_LOADING_MODAL";
// export const COMMUNICATIONS_IDLE_MODAL = "COMMUNICATIONS_IDLE_MODAL";
// export const COMMUNICATIONS_CHANGE_GENERAL_ACTIONS =
//   "COMMUNICATIONS_CHANGE_GENERAL_ACTIONS";

//=============================NEW==========================
export const COMMUNICATIONS_SAVE_LIST_DATA = "COMMUNICATIONS_FETCH_LIST_DATA";
export const COMMUNICATIONS_CHANGE_GENERAL_FILTER =
  "COMMUNICATIONS_CHANGE_GENERAL_FILTER";
