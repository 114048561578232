import {
  SISIBID_SERVICE_FILTERS_CHANGE,
  SISIBID_SERVICE_FILTERS_RESET,
  SISIBID_REQUIRED_SERVICES_CHANGE,
  SISIBID_REPLACEABLE_SERVICES_CHANGE,
  SISIBID_REFUNDABLE_SERVICES_CHANGE,
  SISIBID_REPLACEABILITY_TYPE_CHANGE,
  SISIBID_ADD_SERVICE_COMMENT,
  SISIBID_REMOVE_SERVICE_COMMENT,
} from "./types";

import { filtersToServices } from "@src/selectors/Project/TripPlanner";

export const serviceFiltersChange = (name, value) => {
  return { type: SISIBID_SERVICE_FILTERS_CHANGE, name, value };
};

export const serviceFiltersReset = () => {
  return { type: SISIBID_SERVICE_FILTERS_RESET };
};

export const changeReplaceableServices = (
  serviceType,
  destOrder,
  actionType,
  uid
) => {
  return {
    type: SISIBID_REPLACEABLE_SERVICES_CHANGE,
    serviceType,
    destOrder,
    actionType,
    uid,
  };
};

export const changeAllFilterdReplaceableServices = (uid, actionType) => (
  dispatch,
  getState
) => {
  const state = getState();
  const filters = state.newBiddingServiceFilters;
  const trip = state.newBiddingOriginalTripData;

  const visibleSrvs = filtersToServices(filters, trip.destinations);

  Object.entries(visibleSrvs).forEach((e) => [
    e[1].forEach((ee) => {
      dispatch({
        type: SISIBID_REPLACEABLE_SERVICES_CHANGE,
        serviceType: e[0],
        destOrder: ee,
        actionType,
        uid,
      });
    }),
  ]);
};

export const changeRefundableServices = (
  serviceType,
  destOrder,
  actionType,
  uid
) => {
  return {
    type: SISIBID_REFUNDABLE_SERVICES_CHANGE,
    serviceType,
    destOrder,
    actionType,
    uid,
  };
};

export const changeAllFilterdRefundableServices = (uid, actionType) => (
  dispatch,
  getState
) => {
  const state = getState();
  const filters = state.newBiddingServiceFilters;
  const trip = state.newBiddingOriginalTripData;

  const visibleSrvs = filtersToServices(filters, trip.destinations);

  Object.entries(visibleSrvs).forEach((e) => [
    e[1].forEach((ee) => {
      dispatch({
        type: SISIBID_REFUNDABLE_SERVICES_CHANGE,
        serviceType: e[0],
        destOrder: ee,
        actionType,
        uid,
      });
    }),
  ]);
};

export const changeRequiredServices = (
  serviceType,
  destOrder,
  actionType,
  uid
) => {
  return {
    type: SISIBID_REQUIRED_SERVICES_CHANGE,
    serviceType,
    destOrder,
    actionType,
    uid,
  };
};

export const changeAllFilterdRequiredServices = (uid, actionType) => (
  dispatch,
  getState
) => {
  const state = getState();
  const filters = state.newBiddingServiceFilters;
  const trip = state.newBiddingOriginalTripData;

  const visibleSrvs = filtersToServices(filters, trip.destinations);

  Object.entries(visibleSrvs).forEach((e) => [
    e[1].forEach((ee) => {
      dispatch({
        type: SISIBID_REQUIRED_SERVICES_CHANGE,
        serviceType: e[0],
        destOrder: ee,
        actionType,
        uid,
      });
    }),
  ]);
};

export const changeServiceReplaceability = (
  uid,
  serviceType,
  destOrder,
  replaceabilityType
) => {
  return {
    type: SISIBID_REPLACEABILITY_TYPE_CHANGE,
    uid,
    serviceType,
    destOrder,
    replaceabilityType,
  };
};

export const addServiceComment = (uid, destOrder, serviceType, comment) => {
  return {
    type: SISIBID_ADD_SERVICE_COMMENT,
    uid,
    destOrder,
    serviceType,
    comment,
  };
};

export const removeServiceComment = (uid, destOrder, serviceType, idx) => {
  return {
    type: SISIBID_REMOVE_SERVICE_COMMENT,
    uid,
    destOrder,
    serviceType,
    idx,
  };
};
