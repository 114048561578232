import React, { useEffect, useRef, useState } from "react";
import * as echarts from "echarts";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import _ from "lodash";
import { DateTime } from "luxon";
import { variables } from "@src/jsssetup";
import { injectIntl } from "react-intl";
import TransTxt from "@src/components/common/SxFormatMessage";

const titleMapping = {
  res_per_day: {
    ACC: `B2B_stats_CreateTimeChart1__accommodations_reservations_per_day_timeseries`,
    CAR: `B2B_stats_CreateTimeChart1__car_rental_reservations_per_day_timeseries`,
    FL: `B2B_stats_CreateTimeChart1__flights_reservations_per_day_timeseries`,
    TRF: `B2B_stats_CreateTimeChart1__transfers_reservations_per_day_timeseries`,
    TRA: `B2B_stats_CreateTimeChart1__trains_reservations_per_day_timeseries`,
    ACT: "B2B_stats_CreateTimeChart1__activities_reservations_per_day_timeseries",
    MI: "B2B_stats_CreateTimeChart1__addons_reservations_per_day_timeseries",
    ADHACC: "B2B_stats_CreateTimeChart1__adhoc_reservations_per_day_timeseries",
    APIFER:
      "B2B_stats_CreateTimeChart1__api_ferries_reservations_per_day_timeseries",
    APITRA:
      "B2B_stats_CreateTimeChart1__api_trains_reservations_per_day_timeseries",
    COA: "B2B_stats_CreateTimeChart1__coaches_reservations_per_day_timeseries",
    COO: "B2B_stats_CreateTimeChart1__coordinators_reservations_per_day_timeseries",
    CUACC:
      "B2B_stats_CreateTimeChart1__contracted_accommodations_per_day_timeseries",
    FER: "B2B_stats_CreateTimeChart1__ferries_reservations_per_day_timeseries",
    GEN: "B2B_stats_CreateTimeChart1__general_services_reservations_per_day_timeseries",
    RES: "B2B_stats_CreateTimeChart1__restaurants_reservations_per_day_timeseries",
  },
  avg_price_per_day: {
    ACC: `B2B_stats_CreateTimeChart1__title_ACC`,
    CAR: `B2B_stats_CreateTimeChart1__title_CAR`,
    FL: `B2B_stats_CreateTimeChart1__title_FL`,
    TRF: `B2B_stats_CreateTimeChart1__title_TRF`,
    TRA: `B2B_stats_CreateTimeChart1__title_TRA`,
    ACT: "B2B_stats_CreateTimeChart1__title_ACT",
    MI: "B2B_stats_CreateTimeChart1__title_MI",
    ADHACC: "B2B_stats_CreateTimeChart1__title_ADHACC",
    APIFER: "B2B_stats_CreateTimeChart1__title_APIFER",
    APITRA: "B2B_stats_CreateTimeChart1__title_APITRA",
    COA: "B2B_stats_CreateTimeChart1__title_COA",
    COO: "B2B_stats_CreateTimeChart1__title_COO",
    CUACC: "B2B_stats_CreateTimeChart1__title_CUACC",
    FER: "B2B_stats_CreateTimeChart1__title_FER",
    GEN: "B2B_stats_CreateTimeChart1__title_GEN",
    RES: "B2B_stats_CreateTimeChart1__title_RES",
  },
};

const dataSourceMapping = {
  res_per_day: "reservations_per_day_series",
  avg_price_per_day: "avg_price_per_day_series",
};

const tooltipLabelMapping = {
  res_per_day: "B2B_stats_CreateTimeChart1__reservations",
  avg_price_per_day: "B2B_stats_CreateTimeChart1__avg_price",
};

const srvMapping = {
  ACC: "B2B_stats_CreateTimeChart1__title_ACC",
  CAR: "B2B_stats_CreateTimeChart1__title_CAR",
  FL: "B2B_stats_CreateTimeChart1__title_FL",
  TRF: "B2B_stats_CreateTimeChart1__title_TRF",
  TRA: "B2B_stats_CreateTimeChart1__title_TRA",
  ACT: "B2B_stats_CreateTimeChart1__title_ACT",
  MI: "B2B_stats_CreateTimeChart1__title_MI",
  ADHACC: "B2B_stats_CreateTimeChart1__title_ADHACC",
  APIFER: "B2B_stats_CreateTimeChart1__title_APIFER",
  APITRA: "B2B_stats_CreateTimeChart1__title_APITRA",
  COA: "B2B_stats_CreateTimeChart1__title_COA",
  COO: "B2B_stats_CreateTimeChart1__title_COO",
  CUACC: "B2B_stats_CreateTimeChart1__title_CUACC",
  FER: "B2B_stats_CreateTimeChart1__title_FER",
  GEN: "B2B_stats_CreateTimeChart1__title_GEN",
  RES: "B2B_stats_CreateTimeChart1__title_RES",
};

const chartStyles = createUseStyles({
  CreateTimeLineChart: {
    display: "grid",
    padding: variables.normal_gap,
    width: "32rem",
    height: "16rem",
  },
});
var CreateTimeLineChart = ({
  dashboardPayload,
  chartType,
  color,
  currency,
  data,
  intl,
}) => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const classes = chartStyles();
  const chartRef = useRef(null);

  const createChartInstance = (data) => {
    const chartInstance = echarts.init(chartRef.current);
    const data_per_day = _.get(
      data,
      `stats_per_currency.${currency}.${dataSourceMapping[chartType]}`,
      []
    );
    const finalData = data_per_day.map(([xAxis, yAxis]) => {
      return {
        name: DateTime.fromISO(xAxis).toLocaleString(
          DateTime.DATE_MED_WITH_WEEKDAY
        ),
        value: [new Date(xAxis).getTime(), yAxis],
      };
    });
    const options = {
      title: [
        {
          text: _.get(
            titleMapping,
            `${chartType}.${_.get(dashboardPayload, "serviceType", {})}`
          )
            ? intl.formatMessage({
                id: _.get(
                  titleMapping,
                  `${chartType}.${_.get(dashboardPayload, "serviceType", {})}`
                ),
              })
            : "N/A",
          left: "center",
          textStyle: {
            fontWeight: "bold",
            fontSize: windowSize >= 1024 ? 14 : 5,
          },
        },
      ],
      tooltip: {
        trigger: "axis",
        position: function (pt) {
          return [pt[0], "10%"];
        },
        formatter: (params) => {
          const name = params[0].name;
          var value = params[0].value[1];

          if (chartType === "avg_price_per_day") {
            value = value.toLocaleString("en-US", {
              style: "currency",
              currency: currency,
            });
          }

          return `${name}<br/><strong>${value} ${intl.formatMessage({
            id: tooltipLabelMapping[chartType],
          })}</strong>`;
          // return `${name}<br/><strong>${value} ${tooltipLabelMapping[chartType]}</strong>`;
        },
      },
      toolbox: {
        top: windowSize <= 1441 ? "13%" : "12%",
        // top: "20%",
        right: "10%",
        // left: "center",
        feature: {
          dataZoom: {
            yAxisIndex: "none",
          },
          restore: {},
          saveAsImage: {},
        },
      },
      xAxis: {
        type: "time",
        boundaryGap: false,
      },
      yAxis: {
        type: "value",
        boundaryGap: [0, "100%"],
        max: "dataMax",
      },
      dataZoom: [
        {
          type: "inside",
          start: 0,
          end: 100,
        },
        {
          start: 0,
          end: 100,
        },
      ],
      series: [
        {
          name: `${intl.formatMessage({
            id: srvMapping[_.get(dashboardPayload, "serviceType")],
          })}`,
          // name: `${intl.formatMessage({
          //   id: srvMapping[_.get(dashboardPayload, "serviceType", {})],
          // })}`,
          // `${
          //   srvMapping[_.get(dashboardPayload, "serviceType", {})]
          // } Reservations`,
          type: "line",
          smooth: true,
          symbol: "none",
          areaStyle: { color },
          data: finalData,
          lineStyle: {
            color: color,
          },
        },
      ],
    };

    chartInstance.setOption(options);
    return chartInstance;
  };

  const handleResize = () => setWindowSize(window.innerWidth);
  window.removeEventListener("resize", handleResize);

  useEffect(() => {
    // if (isLoading) return;
    createChartInstance(data);
    window.addEventListener("resize", handleResize);
  }, [data, currency]);

  useEffect(() => {
    const existingChart = echarts.getInstanceByDom(chartRef.current);
    if (existingChart) {
      existingChart.resize();
    }
  }, [windowSize]);

  return (
    <React.Fragment>
      <div ref={chartRef} className={classes.CreateTimeLineChart} />
    </React.Fragment>
  );
};
CreateTimeLineChart.propTypes = {
  chartType: PropTypes.oneOf(["res_per_day", "avg_price_per_day"]).isRequired,
  dashboardPayload: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};
CreateTimeLineChart = injectIntl(CreateTimeLineChart);
export default CreateTimeLineChart;
