import _ from "lodash";
import moment from "moment";

export const createAccPayloads = (
  destinations,
  accommodationPax,
  nationality,
  priorities,
  source_entity
) => {
  const payloads = destinations.map((dest) => {
    var check_in = moment(dest.checkIn).format("YYYY-MM-DD");
    var check_out = moment(dest.checkOut).format("YYYY-MM-DD");

    const query = {
      id: [window.btoa(`lv${dest.type.split("lvl")[1]}__${dest.id}`)],
      type: "destination",
    };

    if (
      _.get(priorities, `${dest.order}.behaviour`) == "RE" &&
      _.get(priorities, `${dest.order}.hotels`, []).length == 1
    ) {
      const hotel = _.get(priorities, `${dest.order}.hotels.0`);
      query.id = [window.btoa(hotel.metadata.id)];
      query.type = "hotel";
    }

    return {
      lang: "en",
      check_in,
      check_out,
      rooms: accommodationPax,
      query,
      nationality,
      destOrder: dest.order,
      market_type: "B2B",
      source_entity,
    };
  });

  const validPayloads = [];
  payloads.forEach((payload, idx) => {
    const prevPayload = _.get(payloads, `${idx - 1}`);
    if (_.get(prevPayload, `query.id.0`) === _.get(payload, "query.id.0")) {
      return;
    }

    const nextPayload = _.get(payloads, `${idx + 1}`);
    if (_.get(nextPayload, `query.id.0`) === _.get(payload, "query.id.0")) {
      const newPayload = _.cloneDeep(payload);
      newPayload.check_out = nextPayload.check_out;
      validPayloads.push(newPayload);
    } else {
      validPayloads.push(payload);
    }
  });

  return validPayloads;
};

export const selectBestRoom = ({ prefs, rooms, disabledId = null }) => {
  var newRooms = rooms;

  if (prefs.board !== "ALL") {
    newRooms = rooms.filter((r) => r.board_code == prefs.board);
    if (newRooms.length === 0) {
      newRooms = rooms;
    }
  }

  if (disabledId) {
    newRooms = newRooms.filter((r) => r.booking_code !== disabledId);
  }

  newRooms = newRooms.filter((room) => room.provider !== "bookingcom");

  return newRooms.sort((a, b) => a.price.value - b.price.value)?.[0]?.id;
};
