import thunkMiddleware from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "@src/reducers/reducers";

const composeOptions = [applyMiddleware(thunkMiddleware)];
window.__REDUX_DEVTOOLS_EXTENSION__ &&
  composeOptions.push(window.__REDUX_DEVTOOLS_EXTENSION__());

const store = createStore(rootReducer, compose(...composeOptions));

if (module.hot) {
  module.hot.accept("./reducers/reducers", () => {
    store.replaceReducer(rootReducer);
  });
}

export default store;
