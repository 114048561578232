import {
  BIDDING_REQUEST_INITIALIZE_BASIC_FILTER_DATA,
  BIDDING_REQUEST_EDIT_BASIC_FILTER_DATA_FORM,
  CHANGE_ARCHIVE_VIEW_MODEL,
  BIDDING_REQUEST_APPLY_LIST_COLUMNS,
  BIDDING_REQUEST_INITIALIZE_LIST_COLUMNS_FORM,
  BIDDING_REQUEST_UPDATE_LIST_FILTER_FORM,
  BIDDING_REQUEST_APPLY_LIST_FILTER,
  BIDDING_REQUEST_INITIALIZE_LIST_FILTER_FORM,
  BIDDING_REQUEST_RESET_LIST_FILTER_FORM,
  BIDDING_REQUEST_BEGIN_FETCH_DATA,
  BIDDING_REQUEST_END_FETCH_DATA,
  BIDDING_REQUEST_FETCH_DATA,
  BIDDING_REQUEST_DETAIL,
  BIDDING_REQUEST_BEGIN_FETCH_DETAIL,
  BIDDING_REQUEST_END_FETCH_DETAIL,
} from "@src/actions/Project/Archive/BiddingRequest/types";

import { FORMAT_TRAVEL_SERVICES_FORM } from "@src/actions/Shares/types";

import { initialBasicFilterForm } from "@src/forms/Project/Archive";
import {
  initialBiddingRequestColumnsSetUp,
  initialBiddingRequestFilterForm,
} from "@src/forms/Project/Archive/BiddingRequest";

import update from "immutability-helper";
import _ from "lodash";

import { formProcessor, entitiesListSelector } from "@src/reducers/tools";

const initialBiddingRequestList = {
  count: 0,
  next: null,
  previous: null,
  results: [],
  page: 1,
  limit: 6,
  displayLength: 100,
};

export const biddingRequestsList = (
  state = initialBiddingRequestList,
  action
) => {
  switch (action.type) {
    case BIDDING_REQUEST_FETCH_DATA:
      return action.data;
    default:
      return state;
  }
};

export const biddingRequestsFetchStatus = (state = "IDLE", action) => {
  switch (action.type) {
    case BIDDING_REQUEST_BEGIN_FETCH_DATA:
      return "FETCHING";
    case BIDDING_REQUEST_END_FETCH_DATA:
      return "FETCHED";
    default:
      return state;
  }
};

export const biddingRequestsBasicFilterData = (
  state = initialBasicFilterForm,
  action
) => {
  switch (action.type) {
    case BIDDING_REQUEST_INITIALIZE_BASIC_FILTER_DATA:
      return action.data;
    case BIDDING_REQUEST_EDIT_BASIC_FILTER_DATA_FORM:
      return update(state, {
        [action.data.name]: { value: { $set: action.data.value } },
      });
    default:
      return state;
  }
};

export const changeBiddingRequestsViewModel = (state = false, action) => {
  switch (action.type) {
    case CHANGE_ARCHIVE_VIEW_MODEL:
      return action.data;
    default:
      return state;
  }
};

// columns
export const biddingRequestsListColumns = (
  state = entitiesListSelector(initialBiddingRequestColumnsSetUp),
  action
) => {
  switch (action.type) {
    case BIDDING_REQUEST_APPLY_LIST_COLUMNS:
      return entitiesListSelector(action.data);
    default:
      return state;
  }
};

export const biddingRequestsListColumnsForm = (
  state = initialBiddingRequestColumnsSetUp,
  action
) => {
  switch (action.type) {
    case BIDDING_REQUEST_INITIALIZE_LIST_COLUMNS_FORM:
      var newState = {};

      for (const key in state) {
        // clear form's value
        state[key] = update(state[key], { value: { $set: [] } });
        newState[key] = update(state[key], { value: { $set: [] } });
        // add value by action data
        action.data.forEach((item, index) => {
          if (_.find(state[key].choices, { value: item.value })) {
            newState[key] = update(newState[key], {
              value: { $push: [item.value] },
            });
          }
        });
      }

      return newState;
    default:
      return state;
  }
};

// filter
export const biddingRequestsListFilter = (
  state = initialBiddingRequestFilterForm,
  action
) => {
  switch (action.type) {
    case BIDDING_REQUEST_APPLY_LIST_FILTER:
      return action.data;
    case FORMAT_TRAVEL_SERVICES_FORM:
      return update(state, { $merge: action.data });
    case BIDDING_REQUEST_RESET_LIST_FILTER_FORM:
      return action.data;
    default:
      return state;
  }
};

export const biddingRequestsListFilterForm = (
  state = initialBiddingRequestFilterForm,
  action
) => {
  switch (action.type) {
    case BIDDING_REQUEST_INITIALIZE_LIST_FILTER_FORM:
      return action.data;
    case BIDDING_REQUEST_RESET_LIST_FILTER_FORM:
      return action.data;
    case BIDDING_REQUEST_UPDATE_LIST_FILTER_FORM:
      return formProcessor(
        state,
        action.data.type,
        action.data.name,
        action.data.value
      );
    case FORMAT_TRAVEL_SERVICES_FORM:
      return update(state, { $merge: action.data });
    default:
      return state;
  }
};

export const biddingRequestDetail = (state = {}, action) => {
  switch (action.type) {
    case BIDDING_REQUEST_DETAIL:
      return action.data;
    default:
      return state;
  }
};

export const biddingRequestsDetailStatus = (state = "IDLE", action) => {
  switch (action.type) {
    case BIDDING_REQUEST_BEGIN_FETCH_DETAIL:
      return "FETCHING";
    case BIDDING_REQUEST_END_FETCH_DETAIL:
      return "FETCHED";
    default:
      return state;
  }
};
