import React from "react";
import _ from "lodash";
import update from "immutability-helper";
import { createHashHistory } from "history";

import {
  fetchProvidersList,
  getSuppliersOptions,
  addNewSuppliers as addNewSuppliersAPI,
  fetchSuppliersProfile as fetchSuppliersProfileAPI,
  submitSuppliersProfile as submitSuppliersProfileAPI,
  uploadImgLink,
  getProviderProperty,
  addNewSupplierProperty,
  getProviderPropertyDetail,
  patchSupplierProperty,
} from "@src/api";

import {
  initialSuppliersBaseDataForm,
  // initialSuppliersFilterForm,
  // defaultOption,
  // initialSuppliersColumnsSetUp,
} from "@src/forms/Network/Suppliers";
import { travelServicesFrom } from "@src/forms/Admin/Company/companyProfileForms";

import {
  filterEmptyValue,
  isEmptyObj,
  errorHandle as commonErrorHandle,
} from "@src/tools/common_tools";
// import { getPagination } from "@src/tools/form_tools";
import { timeSkipper, submitProcessor } from "@src/actions/tools";
import { timeStampInjector } from "@src/tools/date_tools";
// import { entitiesListSelector } from "@src/reducers/tools";

import { hideGlobalModal } from "@src/actions/Shares";

import TransTxt from "@src/components/common/SxFormatMessage";
import { notifyCommonError } from "@src/components/common/notifications/CommonErrorNotification.js";
import { notifyCommonSuccess } from "@src/components/common/notifications/CommonSuccessNotification.js";

import {
  //=========================LIST===============================
  SUPPLIERS_BEGIN_FETCH_DATA,
  PROVIDERS_LIST_SAVE_DATA,
  SUPPLIERS_END_FETCH_DATA,
  SUPPLIERS_FETCH_OPTIONS,

  //========================FILTER==============================
  // SUPPLIERS_UPDATE_LIST_FILTER_FORM,
  // SUPPLIERS_APPLY_LIST_FILTER,
  // SUPPLIERS_INITIALIZE_LIST_FILTER_FORM,
  // SUPPLIERS_RESET_LIST_FILTER_FORM,

  //========================COLUMNS=============================
  // SUPPLIERS_UPDATE_LIST_COLUMNS_FORM,
  // SUPPLIERS_APPLY_LIST_COLUMNS,
  // SUPPLIERS_INITIALIZE_LIST_COLUMNS_FORM,

  //=========================BASE DATA==========================
  SUPPLIERS_SHOW_BASE_DATA_EDIT_FORM,
  SUPPLIERS_HIDE_BASE_DATA_EDIT_FORM,
  SUPPLIERS_EDIT_BASE_DATA,
  SUPPLIERS_INITIALIZE_BASE_DATA_EDIT_FORM,
  SUPPLIERS_VALIDATE_BASE_DATA_EDIT_FORM,
  SUPPLIERS_PROFILE_DATA,
  SUPPLIERS_UPLOADING_BASE_DATA_EDIT_FORM,
  SUPPLIERS_IDLE_BASE_DATA_EDIT_FORM,
  SUPPLIERS_CLEAN_BASE_EDIT_FORM_ERRORS,
  SUPPLIERS_CLEAN_BASE_DATA_EDIT_FORM,
  SUPPLIERS_TOGGLE_POSTAL_ADDRESS,
  SUPPLIERS_CLEAN_SERVICE_BASE_DATA_EDIT_FORM,
  SUPPLIERS_CLEAN_ADDRESS_POINT,
  SUPPLIERS_INITIAL_VERIFY_STATUS,
  SUPPLIERS_INITIALIZE_TRAVEL_SERVICE_FORM,

  //==============================GEOLOCATE=====================
  SUPPLIERS_BEGIN_FETCH_ADDRESS_DATA,
  SUPPLIERS_END_FETCH_ADDRESS_DATA,
  SUPPLIERS_FETCH_ADDRESS_POINT,
  SUPPLIERS_TOGGLE_VERIFY_ADDRESS,
  SUPPLIERS_LOADING_PROFILE_VIEW,
  SUPPLIERS_LOADED_PROFILE_VIEW,
  SUPPLIERS_INITIAL_ADDRESS_POINT,
  SUPPLIERS_CLOSE_VERIFY_ADDRESS,
  SUPPLIERS_UPLOAD_PREFERENCE_IMAGE,

  //======================= PROVIDER PROPERTY LIST==============
  SUPPLIERS_NAV_TAB_INIT,
  SUPPLIERS_CHANGE_NAV_TAB,
  SUPPLIERS_SAVE_PROPERTY,
  SUPPLIERS_LOADING_PROPERTY_VIEW,
  SUPPLIERS_LOADED_PROPERTY_VIEW,

  //=====================PROVIDER PROPERTY MODAL=================
  SUPPLIERS_INIT_PROPERTY_MODAL,
  SUPPLIERS_SHOW_PROPERTY_MODAL,
  SUPPLIERS_HIDE_PROPERTY_MODAL,
  SUPPLIERS_LOADING_PROPERTY_MODAL,
  SUPPLIERS_UPDATE_PROPERTY_MODAL_FORM,
  SUPPLIERS_LOADED_PROPERTY_MODAL,
  SUPPLIERS_SAVE_PROPERTY_DETAIL,
  PROVIDERS_LIST_SIMPLE_CHANGE,
} from "./types";

// import { filterProcessor } from "./filterProcessor";
import {
  changeUploadImgFactory,
  fetchCompleteAddressFactory,
  fetchAddressPointDetailFactory,
} from "./../../ActionFactory";

//=========================BASE DATA==========================
export const addNewSuppliersData = () => (dispatch, getState) => {
  const state = getState();
  const type = state.userMeta;
  const verifyStatus = state.isVerifySuppliersPostalAddress;

  if (state.suppliersBaseDataFormErrors.valid == false) {
    for (const key in state.suppliersBaseDataFormErrors) {
      if (state.suppliersBaseDataFormErrors[key].hasError) {
        notifyCommonError(state.suppliersBaseDataFormErrors[key].errorMessage);
        return null;
      }
    }
    return null;
  }

  if (!verifyStatus) {
    return notifyCommonError("Please Verify the Address");
  }

  const entityData = {
    name: state.suppliersBaseDataForm.name.value,
    legal_title: state.suppliersBaseDataForm.legal_title.value,
    registration_code: state.suppliersBaseDataForm.registration_code.value,
    founding_year: state.suppliersBaseDataForm.founding_year.value,
    email: state.suppliersBaseDataForm.email.value,
    url: state.suppliersBaseDataForm.url.value,
    phone: state.suppliersBaseDataForm.phone.value,
    mobile_phone: state.suppliersBaseDataForm.mobile_phone.value,
    travel_services: [],
    [type.company_type]: type.company_id,
  };

  //travel_service
  const result = submitProcessor(
    state,
    "suppliersTravelServicesForm",
    travelServicesFrom,
    "travel_services"
  );
  entityData.travel_services = result.payload.travel_services;

  //contact person
  const contactPersonData = {
    first_name: state.suppliersBaseDataForm.first_name.value,
    last_name: state.suppliersBaseDataForm.last_name.value,
  };
  entityData.contact_person = [contactPersonData];

  // address
  let postal_country_code = "";
  if (state.suppliersBaseDataForm.postal_country_code.value) {
    postal_country_code = state.suppliersBaseDataForm.postal_country_code.value.split(
      "-"
    )[0];
  }
  const postal_Address_Data = {
    street_number: state.suppliersBaseDataForm.postal_street_number.value,
    street: state.suppliersBaseDataForm.postal_street.value,
    country_code: postal_country_code,
    region: state.suppliersBaseDataForm.postal_region.value,
    city: state.suppliersBaseDataForm.postal_city.value,
    post_code: state.suppliersBaseDataForm.postal_post_code.value,
    lat: state.suppliersBaseDataForm.lat.value,
    lng: state.suppliersBaseDataForm.lng.value,
  };
  entityData.address = [postal_Address_Data];

  dispatch(loadingSuppliersBaseDataEditForm());
  dispatch(addNewSuppliersAPIForm(entityData));
};

export const addNewSuppliersAPIForm = (entityData) => async (
  dispatch,
  getState
) => {
  try {
    const res = await addNewSuppliersAPI(entityData);
    const { id } = res;
    const customHistory = createHashHistory();
    customHistory.push(`/network/suppliers/profile/?id=${id}`);
  } catch (error) {
    commonErrorHandle({ error, form: getState().suppliersBaseDataForm });
  } finally {
    dispatch(idleSuppliersBaseDataEditForm());
    dispatch(hideGlobalModal());
  }
};

export const addNewSuppliers = () => (dispatch, getState) => {
  const tempForm = _.cloneDeep(getState().suppliersBaseDataForm);

  if (tempForm.url.value) {
    tempForm.url.value = "http://" + tempForm.url.value;
  }

  dispatch(validateSuppliersBaseData(tempForm));
  Promise.resolve().then(() => dispatch(addNewSuppliersData()));
};

export const validateSuppliersBaseData = (data) => {
  return {
    type: SUPPLIERS_VALIDATE_BASE_DATA_EDIT_FORM,
    data,
  };
};

const initialOptions = {
  status: {
    choices: [
      { value: "AC", display_name: <TransTxt id="active" startCase /> },
      {
        value: "IN",
        display_name: <TransTxt id="inactive" startCase />,
      },
      {
        value: "CA",
        display_name: <TransTxt id="candidate" startCase />,
      },
    ],
  },
};

//=========================LIST===============================
export const fetchSuppliersOptions = () => (dispatch, getState) => {
  const suppliersOptions = getState().suppliersOptions;

  const call = timeSkipper(
    () => {
      getSuppliersOptions().then((data) => {
        const options = _.merge(data.actions.POST, initialOptions);

        dispatch(
          getSuppliersOptionsData(
            ...options,
            ...{ metadata: timeStampInjector({}) }
          )
        );
      });
    },
    suppliersOptions,
    3600
  );

  call();
};

export const getSuppliersOptionsData = (data) => {
  return {
    type: SUPPLIERS_FETCH_OPTIONS,
    data,
  };
};

export const saveProvidersListData = (data) => {
  return {
    type: PROVIDERS_LIST_SAVE_DATA,
    data,
  };
};

export const beginSuppliersListData = () => {
  return { type: SUPPLIERS_BEGIN_FETCH_DATA };
};

export const endSuppliersListData = () => {
  return { type: SUPPLIERS_END_FETCH_DATA };
};

// suppliers profile
export const submitSuppliersProfile = (
  fields,
  uploadingAction,
  errorAction = [],
  successAction,
  alert = true
) => async (dispatch, getState) => {
  dispatch(uploadingAction());

  const state = getState();
  const result = submitProcessor(
    state,
    "suppliersTravelServicesForm",
    travelServicesFrom,
    fields
  );

  if (result.hasError) {
    errorAction.forEach((action) => dispatch(action(result)));
    if (alert) {
      result.errorMsgs.forEach((e) => notifyCommonError(e.msg));
    }
    return null;
  }
  const req = {
    ...result.payload,
    main_service: state.suppliersMainServicesForm.value,
  };

  try {
    const data = await submitSuppliersProfileAPI(
      state.suppliersProfile.id,
      req,
      "patch"
    );
    dispatch({ type: SUPPLIERS_PROFILE_DATA, data: data });
    dispatch(successAction());
    notifyCommonSuccess("Successfully updated your profile.");
  } catch (error) {
    console.log(error);
    notifyCommonError("Error updating your profile please try again.");
    dispatch(errorAction());
  }
};

//=============================PROFILE=========================
export const fetchSuppliersProfileData = (data) => {
  return {
    type: SUPPLIERS_PROFILE_DATA,
    data,
  };
};
export const suppliersProfileData = (data) => (dispatch) => {
  const addresses = _.groupBy(data.address || [], "address_type");
  data.billingAddress = _.get(addresses, "BI.0", {});
  data.postalAddress = _.get(addresses, "BU.0", {});
  data.contactPerson = _.get(data, "contact_person.0", {});
  dispatch(fetchSuppliersProfileData(data));
};

export const loadingSupplierProfileView = () => {
  return { type: SUPPLIERS_LOADING_PROFILE_VIEW };
};

export const loadedSupplierProfileView = () => {
  return { type: SUPPLIERS_LOADED_PROFILE_VIEW };
};

export const fetchSuppliersProfile = (id) => async (dispatch) => {
  dispatch(loadingSupplierProfileView());

  try {
    const data = await fetchSuppliersProfileAPI(id);
    dispatch(suppliersProfileData(data));
    dispatch(
      initializeSuppliersTravelServiceForm({ value: data.travel_services })
    );
    await dispatch(fetchProviderPropertyList({ provider_id: id }));
  } catch (error) {
    commonErrorHandle({ error });
  } finally {
    dispatch(loadedSupplierProfileView());
  }
};

export const initializeSuppliersTravelServiceForm = (data) => {
  return {
    type: SUPPLIERS_INITIALIZE_TRAVEL_SERVICE_FORM,
    data,
  };
};

export const hideSuppliersBaseEditForm = () => {
  return { type: SUPPLIERS_HIDE_BASE_DATA_EDIT_FORM };
};

export const editSuppliersBaseDataForm = (data) => {
  return { type: SUPPLIERS_EDIT_BASE_DATA, data: data };
};

export const submitSuppliersBaseDataEditForm = () => (dispatch, getState) => {
  const state = getState();
  const suppliersProfile = state.suppliersProfile;
  const verifyStatus = state.isVerifySuppliersPostalAddress;
  const postalAddress = suppliersProfile.postalAddress;

  const file = state.suppliersBaseDataForm.original_image.fileName;
  const formData = new FormData();

  formData.append("original_image", file);
  formData.append("content_type", "provider");
  formData.append("object_id", suppliersProfile.id);

  let country_code = "";
  if (state.suppliersBaseDataForm.country_code.value) {
    country_code = state.suppliersBaseDataForm.country_code.value.split("-")[0];
  }
  const billingAddressData = {
    street_number: state.suppliersBaseDataForm.street_number.value,
    street: state.suppliersBaseDataForm.street.value,
    country_code: country_code,
    region: state.suppliersBaseDataForm.region.value,
    city: state.suppliersBaseDataForm.city.value,
    post_code: state.suppliersBaseDataForm.post_code.value,
  };

  let postal_country_code = "";
  if (state.suppliersBaseDataForm.postal_country_code.value) {
    postal_country_code = state.suppliersBaseDataForm.postal_country_code.value.split(
      "-"
    )[0];
  }

  const postalAddressData = {
    street_number: state.suppliersBaseDataForm.postal_street_number.value,
    street: state.suppliersBaseDataForm.postal_street.value,
    country_code: postal_country_code,
    region: state.suppliersBaseDataForm.postal_region.value,
    city: state.suppliersBaseDataForm.postal_city.value,
    post_code: state.suppliersBaseDataForm.postal_post_code.value,
    lat: state.suppliersBaseDataForm.lat.value || postalAddress.lat,
    lng: state.suppliersBaseDataForm.lng.value || postalAddress.lng,
  };

  if (state.suppliersBaseDataFormErrors.valid == false) {
    for (const key in state.suppliersBaseDataFormErrors) {
      if (state.suppliersBaseDataFormErrors[key].hasError) {
        notifyCommonError(state.suppliersBaseDataFormErrors[key].errorMessage);
        return null;
      }
    }
    return null;
  }

  if (!verifyStatus) {
    return notifyCommonError("Please Verify the Address");
  }

  const entityData = {
    name: state.suppliersBaseDataForm.name.value,
    legal_title: state.suppliersBaseDataForm.legal_title.value,
    registration_code: state.suppliersBaseDataForm.registration_code.value,
    founding_year: state.suppliersBaseDataForm.founding_year.value,
    phone: state.suppliersBaseDataForm.phone.value,
    mobile_phone: state.suppliersBaseDataForm.mobile_phone.value,
    email: state.suppliersBaseDataForm.email.value,
    url: state.suppliersBaseDataForm.url.value,
    brief_description_en:
      state.suppliersBaseDataForm.brief_description_en.value,
    brief_description_cn:
      state.suppliersBaseDataForm.brief_description_cn.value,
    currency: state.suppliersBaseDataForm.currency.value,
  };

  // address
  const address = suppliersProfile.address;
  if (_.get(suppliersProfile, "billingAddress.id")) {
    filterEmptyValue(
      _.merge(
        _.find(address, { id: _.get(suppliersProfile, "billingAddress.id") }),
        billingAddressData
      )
    );
  } else {
    if (!isEmptyObj(billingAddressData)) {
      billingAddressData.address_type = "BI";
      address.push(filterEmptyValue(billingAddressData));
    }
  }

  if (_.get(suppliersProfile, "postalAddress.id")) {
    filterEmptyValue(
      _.merge(
        _.find(address, { id: _.get(suppliersProfile, "postalAddress.id") }),
        postalAddressData
      )
    );
  } else {
    if (!isEmptyObj(postalAddressData)) {
      postalAddressData.address_type = "BU";
      address.push(filterEmptyValue(postalAddressData));
    }
  }
  entityData.address = address;

  //contact person
  const contactPersonData = {
    first_name: state.suppliersBaseDataForm.first_name.value,
    last_name: state.suppliersBaseDataForm.last_name.value,
  };

  if (_.get(suppliersProfile, "contactPerson.id")) {
    _.merge(
      _.find(suppliersProfile.contact_person, {
        id: _.get(suppliersProfile, "contactPerson.id"),
      }),
      contactPersonData
    );
  } else {
    suppliersProfile.contact_person.push(filterEmptyValue(contactPersonData));
  }

  entityData.contact_person = suppliersProfile.contact_person;
  dispatch(loadingSuppliersBaseDataEditForm());

  if (
    file !== undefined &&
    !(Object.prototype.toString.call(file) === "[object Object]")
  ) {
    uploadImgLink(formData)
      .then((res) => {
        const original_image = res.original_image;
        dispatch(
          uploadSuppliersImages({
            name: "original_image",
            value: original_image,
          })
        );
        dispatch(submitSuppliersProfileForm(entityData));
      })
      .catch((error) => {
        dispatch(idleSuppliersBaseDataEditForm());
        commonErrorHandle({ error });
      });
  } else {
    dispatch(submitSuppliersProfileForm(entityData));
  }
  return null;
};

//=========================BASE DATA==========================
export const uploadSuppliersImages = (data) => {
  return {
    type: SUPPLIERS_UPLOAD_PREFERENCE_IMAGE,
    data,
  };
};

export const cleanSuppliersBaseEditFormErrors = () => {
  return { type: SUPPLIERS_CLEAN_BASE_EDIT_FORM_ERRORS };
};

export const submitSuppliersProfileForm = (entityData) => async (
  dispatch,
  getState
) => {
  try {
    const data = await submitSuppliersProfileAPI(
      getState().suppliersProfile.id,
      entityData,
      "patch"
    );
    dispatch(suppliersProfileData(data));
    dispatch(fetchSuppliersProfile(getState().suppliersProfile.id));
    notifyCommonSuccess("Successfully updated your profile.");
  } catch (error) {
    commonErrorHandle({ error, initialSuppliersBaseDataForm });
  } finally {
    dispatch(idleSuppliersBaseDataEditForm());
    dispatch(hideSuppliersBaseEditForm());
  }
};

export const loadingSuppliersBaseDataEditForm = () => {
  return { type: SUPPLIERS_UPLOADING_BASE_DATA_EDIT_FORM };
};

export const idleSuppliersBaseDataEditForm = () => {
  return { type: SUPPLIERS_IDLE_BASE_DATA_EDIT_FORM };
};

export const validateSuppliersBaseDataEditForm = () => (dispatch, getState) => {
  const tempForm = _.cloneDeep(getState().suppliersBaseDataForm);

  if (tempForm.url.value) {
    tempForm.url.value = "http://" + tempForm.url.value;
  }
  dispatch(validateSuppliersBaseData(tempForm));
  Promise.resolve().then(() => dispatch(submitSuppliersBaseDataEditForm()));
};

export const showSuppliersBaseEditForm = () => (dispatch, getState) => {
  const profile = getState().suppliersProfile;
  const address = profile.billingAddress || {};
  const postalAddress = profile.postalAddress || {};
  const suppliersContactPerson = profile.contactPerson || {};
  const formatCountryCodeList = getState().formatCountryCodeList;
  formatCountryCodeList.forEach((item) => {
    if (item.code && item.code == address.country_code) {
      address.country_code = item.value;
    } else {
      return null;
    }
    return null;
  });

  formatCountryCodeList.forEach((item) => {
    if (item.code && item.code == postalAddress.country_code) {
      postalAddress.country_code = item.value;
    } else {
      return null;
    }
    return null;
  });

  const data = update(initialSuppliersBaseDataForm, {
    name: { value: { $set: profile.name } },
    legal_title: { value: { $set: profile.legal_title } },
    registration_code: { value: { $set: profile.registration_code } },
    founding_year: { value: { $set: profile.founding_year } },
    first_name: { value: { $set: suppliersContactPerson.first_name } },
    last_name: { value: { $set: suppliersContactPerson.last_name } },
    mobile_phone: { value: { $set: profile.mobile_phone } },
    phone: { value: { $set: profile.phone } },
    email: { value: { $set: profile.email } },
    url: { value: { $set: profile.url } },
    brief_description_en: { value: { $set: profile.brief_description_en } },
    brief_description_cn: { value: { $set: profile.brief_description_cn } },
    street_number: { value: { $set: address.street_number } },
    street: { value: { $set: address.street } },
    country_code: { value: { $set: address.country_code } },
    region: { value: { $set: address.region } },
    city: { value: { $set: address.city } },
    post_code: { value: { $set: address.post_code } },
    postal_street_number: { value: { $set: postalAddress.street_number } },
    postal_street: { value: { $set: postalAddress.street } },
    postal_country_code: { value: { $set: postalAddress.country_code } },
    postal_region: { value: { $set: postalAddress.region } },
    postal_city: { value: { $set: postalAddress.city } },
    postal_post_code: { value: { $set: postalAddress.post_code } },
    original_image: {
      value: {
        $set: profile.logo ? profile.logo.photo_sm_url : "",
      },
    },
    currency: {
      value: {
        $set: profile.currency,
      },
    },
  });

  dispatch(initializeBaseDataEditForm(data));
  dispatch(
    initialSupplierAddressPoint({
      lat: postalAddress.lat || 0,
      lng: postalAddress.lng || 0,
    })
  );
  dispatch(closeSupplierVerifyAddress());
  const isSame =
    JSON.stringify({
      street_number: address.street_number,
      street: address.street,
      country_code: address.country_code,
      region: address.region,
      city: address.city,
      post_code: address.post_code,
    }) ==
    JSON.stringify({
      street_number: postalAddress.street_number,
      street: postalAddress.street,
      country_code: postalAddress.country_code,
      region: postalAddress.region,
      city: postalAddress.city,
      post_code: postalAddress.post_code,
    });

  dispatch(showSupplierBaseDataEditForm(isSame));
};

export const showSupplierBaseDataEditForm = (data) => {
  return {
    type: SUPPLIERS_SHOW_BASE_DATA_EDIT_FORM,
    data,
  };
};

export const initializeBaseDataEditForm = (data) => {
  return { type: SUPPLIERS_INITIALIZE_BASE_DATA_EDIT_FORM, data: data };
};

export const cleanSuppliersBaseEditForm = () => (dispatch) => {
  dispatch(cleanSupplierBasicBaseEditForm());
  dispatch(cleanSupplierServiceBaseEditForm());
  dispatch(cleanSupplierMapInfo());
  dispatch(initialSupplierVerifyStatus());
};

export const cleanSupplierBasicBaseEditForm = () => {
  return { type: SUPPLIERS_CLEAN_BASE_DATA_EDIT_FORM };
};

export const cleanSupplierServiceBaseEditForm = () => {
  return { type: SUPPLIERS_CLEAN_SERVICE_BASE_DATA_EDIT_FORM };
};

export const cleanSupplierMapInfo = () => {
  return { type: SUPPLIERS_CLEAN_ADDRESS_POINT };
};

export const initialSupplierVerifyStatus = () => {
  return { type: SUPPLIERS_INITIAL_VERIFY_STATUS };
};

export const changeSuppliersImg = (data) => (dispatch) => {
  dispatch(changeUploadImgFactory(data, SUPPLIERS_UPLOAD_PREFERENCE_IMAGE));
};

// =============================== LIST ==============================
export const getProvidersList = () => async (dispatch, getState) => {
  const state = getState();
  // dispatch(beginSuppliersListData());

  // const service_type = getState().travelServiceOptions.service_type || {};
  // const filter = filterProcessor(getState().suppliersListFilter, service_type);
  // const pagination = getPagination(getState().suppliersList);

  const { page, limit, filters } = state.providersListReducer;

  const validFilters = {};
  Object.entries(filters)
    .filter((entry) => entry[1])
    .forEach((entry) => (validFilters[entry[0]] = entry[1]));

  try {
    const data = await fetchProvidersList({ page, limit, ...validFilters });
    dispatch(saveProvidersListData(data));
  } catch (error) {
    // commonErrorHandle({ error });
    console.log(error);
  }
};

export const providersListSimpleChange = (key, value) => {
  return { type: PROVIDERS_LIST_SIMPLE_CHANGE, key, value };
};

export const modifySuppliersPage = (page) => (dispatch, getState) => {
  let suppliersList = getState().suppliersList;
  suppliersList = update(suppliersList, { page: { $set: page } });
  dispatch(saveProvidersListData({ ...suppliersList }));
  dispatch(getProvidersList());
};

export const modifySuppliersLimit = (limit) => (dispatch, getState) => {
  let suppliersList = getState().suppliersList;
  suppliersList = update(suppliersList, {
    limit: { $set: limit },
    page: { $set: 1 },
  });
  dispatch(saveProvidersListData({ ...suppliersList }));
  dispatch(getProvidersList());
};

//========================FILTER==============================
// export const updateSuppliersListFilterForm = (payload) => {
//   return { type: SUPPLIERS_UPDATE_LIST_FILTER_FORM, data: payload };
// };

// export const applySuppliersListFilter = () => (dispatch, getState) => {
//   const state = getState().suppliersListFilterForm || {};
//   dispatch(hideGlobalModal());
//   dispatch(applySuppliersListFilterForm(state));
//   dispatch(getProvidersList(1));
// };

// export const applySuppliersListFilterForm = (data) => {
//   return {
//     type: SUPPLIERS_APPLY_LIST_FILTER,
//     data,
//   };
// };

// export const initializeSuppliersListFilterForm = () => (dispatch, getState) => {
//   const state = getState().suppliersListFilter || {};
//   dispatch(initializeSuppliersListFilter(state));
// };

// export const initializeSuppliersListFilter = (data) => {
//   return {
//     type: SUPPLIERS_INITIALIZE_LIST_FILTER_FORM,
//     data,
//   };
// };

// export const resetSuppliersListFilter = () => (dispatch, getState) => {
//   const data = update(initialSuppliersFilterForm, {
//     $merge: getState().travelServicesForm,
//   });
//   dispatch(resetSuppliersListFilterForm(data));
// };

// export const resetSuppliersListFilterForm = (data) => {
//   return {
//     type: SUPPLIERS_RESET_LIST_FILTER_FORM,
//     data,
//   };
// };

//========================COLUMNS=============================
// export const updateSuppliersListColumnsForm =
//   (field, value) => (dispatch, getState) => {
//     const state = getState().suppliersListColumnsForm || {};
//     if (field.type == "push") {
//       value = update(new Set(state[field.name].value), { $add: [field.value] });
//     }
//     if (field.type == "remove") {
//       value = update(new Set(state[field.name].value), {
//         $remove: [field.value],
//       });
//     }
//     if (field.type == "set") {
//       value = field.value;
//     }
//     const suppliersListColumnsForm = update(state, {
//       [field.name]: { value: { $set: [...value] } },
//     });
//     dispatch(updateSuppliersListColumns(suppliersListColumnsForm));
//   };

// export const updateSuppliersListColumns = (data) => {
//   return {
//     type: SUPPLIERS_UPDATE_LIST_COLUMNS_FORM,
//     data,
//   };
// };

// export const applySuppliersListColumns = () => (dispatch, getState) => {
//   const state = getState().suppliersListColumnsForm || {};
//   dispatch(hideGlobalModal());
//   dispatch(applySuppliersListColumnsForm(state));
// };

// export const applySuppliersListColumnsForm = (data) => {
//   return {
//     type: SUPPLIERS_APPLY_LIST_COLUMNS,
//     data,
//   };
// };

// export const initializeSuppliersListColumnsForm =
//   () => (dispatch, getState) => {
//     const currentCols = getState().suppliersListColumns;
//     dispatch(initializeSuppliersListColumns(currentCols));
//   };

// export const initializeSuppliersListColumns = (data) => {
//   return {
//     type: SUPPLIERS_INITIALIZE_LIST_COLUMNS_FORM,
//     data,
//   };
// };

// export const applySuppliersDefaultOption = () => (dispatch) => {
//   const choices = _.flatten(
//     Object.values(initialSuppliersColumnsSetUp).map((o) => o.choices)
//   );
//   defaultOption.basic.choices = choices;
//   const currentCols = entitiesListSelector(defaultOption);
//   dispatch(initializeSuppliersListColumns(currentCols));
// };

export const synchronizeSuppliersPostalAddress = (checked) => (
  dispatch,
  getState
) => {
  const form = getState().suppliersBaseDataForm;

  const data = update(form, {
    street_number: { value: { $set: form.postal_street_number.value } },
    street: { value: { $set: form.postal_street.value } },
    country_code: { value: { $set: form.postal_country_code.value } },
    region: { value: { $set: form.postal_region.value } },
    city: { value: { $set: form.postal_city.value } },
    post_code: { value: { $set: form.postal_post_code.value } },
  });
  dispatch(toggleSuppliersPostalAddress(checked));
  if (checked) {
    dispatch(initializeBaseDataEditForm(data));
  }
};

export const toggleSuppliersPostalAddress = (data) => {
  return {
    type: SUPPLIERS_TOGGLE_POSTAL_ADDRESS,
    data,
  };
};

//==============================GEOLOCATE======================
export const beginSuppliersAddressPoint = () => {
  return { type: SUPPLIERS_BEGIN_FETCH_ADDRESS_DATA };
};

export const endSuppliersAddressPoint = () => {
  return { type: SUPPLIERS_END_FETCH_ADDRESS_DATA };
};

export const fetchSuppliersAddressPoint = () => (dispatch, getState) => {
  const state = getState();
  const address = state.suppliersBaseDataForm;
  dispatch(
    fetchCompleteAddressFactory(address, fetchSuppliersAddressPointDetail)
  );
};

export const fetchSuppliersAddressPointDetail = (data) => (dispatch) => {
  dispatch(
    fetchAddressPointDetailFactory(
      data,
      SUPPLIERS_FETCH_ADDRESS_POINT,
      editSuppliersBaseDataForm,
      beginSuppliersAddressPoint,
      endSuppliersAddressPoint
    )
  );
};

export const verifySuppliersPostalAddress = (checked) => {
  return {
    type: SUPPLIERS_TOGGLE_VERIFY_ADDRESS,
    data: checked,
  };
};

export const initialSupplierAddressPoint = (data) => {
  return {
    type: SUPPLIERS_INITIAL_ADDRESS_POINT,
    data,
  };
};

export const closeSupplierVerifyAddress = () => {
  return {
    type: SUPPLIERS_CLOSE_VERIFY_ADDRESS,
  };
};

//======================= PROVIDER PROPERTY LIST===============
export const initSupplierNavStatus = (data) => {
  return {
    type: SUPPLIERS_NAV_TAB_INIT,
    data,
  };
};

export const changeSupplierNavStatus = (data) => {
  return {
    type: SUPPLIERS_CHANGE_NAV_TAB,
    data,
  };
};

export const fetchProviderPropertyList = ({ provider_id }) => async (
  dispatch
) => {
  dispatch(loadingProviderPropertyList());
  try {
    const res = await getProviderProperty({ provider_id });
    dispatch(saveSupplierProperty(res));
  } catch (error) {
    commonErrorHandle({ error });
  } finally {
    dispatch(loadedProviderPropertyList());
  }
};

export const loadingProviderPropertyList = () => {
  return { type: SUPPLIERS_LOADING_PROPERTY_VIEW };
};

export const loadedProviderPropertyList = () => {
  return { type: SUPPLIERS_LOADED_PROPERTY_VIEW };
};

export const saveSupplierProperty = (data) => {
  return {
    type: SUPPLIERS_SAVE_PROPERTY,
    data,
  };
};

//=====================PROVIDER PROPERTY MODAL==================
export const initSupplierPropertyModal = () => {
  return {
    type: SUPPLIERS_INIT_PROPERTY_MODAL,
  };
};

export const showSupplierPropertyModal = (data) => {
  return {
    type: SUPPLIERS_SHOW_PROPERTY_MODAL,
    data,
  };
};

export const hideSupplierPropertyModal = () => {
  return {
    type: SUPPLIERS_HIDE_PROPERTY_MODAL,
  };
};

export const loadingSupplierPropertyModal = () => {
  return {
    type: SUPPLIERS_LOADING_PROPERTY_MODAL,
  };
};

export const loadedSupplierPropertyModal = () => {
  return {
    type: SUPPLIERS_LOADED_PROPERTY_MODAL,
  };
};

export const changeSupplierPropertyModalForm = (key, value) => {
  return {
    type: SUPPLIERS_UPDATE_PROPERTY_MODAL_FORM,
    key,
    value,
  };
};

export const addSupplierProperty = () => async (dispatch, getState) => {
  const { form } = getState().supplierPropertyModal;
  const profile = getState().suppliersProfile;

  if (_.isEmpty(form.accommodation_data)) {
    return notifyCommonError("Accommodation info is required");
  }

  const payload = {
    reservations_email: form.reservations_email,
    provider: profile.id,
    accommodation_uid: form.accommodation_data.id,
    accommodation_data: form.accommodation_data,
    status: form.status,
  };
  dispatch(loadingSupplierPropertyModal());
  try {
    await addNewSupplierProperty(filterEmptyValue(payload));
    dispatch(fetchProviderPropertyList({ provider_id: profile.id }));
    notifyCommonSuccess("Successfully add property.");
  } catch (error) {
    commonErrorHandle({ error });
  } finally {
    dispatch(hideSupplierPropertyModal());
  }
};

export const fetchSupplierPropertyDetail = (data) => async (dispatch) => {
  dispatch(loadingSupplierPropertyModal());

  try {
    const res = await getProviderPropertyDetail(data);
    dispatch(saveSupplierPropertyDetail(res));
  } catch (error) {
    commonErrorHandle({ error });
  } finally {
    dispatch(loadedSupplierPropertyModal());
  }
};

export const saveSupplierPropertyDetail = (data) => {
  return {
    type: SUPPLIERS_SAVE_PROPERTY_DETAIL,
    data,
  };
};

export const patchSupplierPropertyDetailInfo = () => async (
  dispatch,
  getState
) => {
  const { form } = getState().supplierPropertyModal;

  const payload = {
    reservations_email: _.get(form, "reservations_email"),
    status: form.status,
    accommodation_data: form.accommodation_data,
  };

  dispatch(loadingSupplierPropertyModal());
  try {
    await patchSupplierProperty(form.id, payload);
    dispatch(fetchProviderPropertyList({ provider_id: form.provider }));
    notifyCommonSuccess("Successfully edit property.");
  } catch (error) {
    commonErrorHandle({ error });
  } finally {
    dispatch(hideSupplierPropertyModal());
  }
};
