import _ from "lodash";
import React, { useState } from "react";

import DistributionPeriods from "../Components/DistributionPeriods";
import InclusionsExclusions from "../Components/InclusionsExclusions";
import Highlights from "../Components/Highlights";
import { createUseStyles } from "react-jss";
import { variables } from "@src/jsssetup";
import { CustomButton } from "@src/components/common/buttons";
import MeetingPointSetupController from "./MeetingPointSetupController";

const addonPreferencesStyles = createUseStyles({
  container: {
    padding: variables.normal_gap,
  },
  actions: {
    display: "grid",
    gridAutoFlow: "column",
    gridAutoColumns: "max-content",
    justifyItems: "start",
    gridGap: variables.normal_gap,
    marginBottom: variables.normal_gap,
  },
});
const AddOnPreferences = () => {
  // meeting_points, highlights, inclusions_exclusions, distribution_periods
  const [activeView, setActiveView] = useState("highlights");
  const classes = addonPreferencesStyles();

  return (
    <div className={classes.container}>
      <div className={classes.actions}>
        {[
          "highlights",
          "inclusions_exclusions",
          "distribution_periods",
          "meeting_points",
        ].map((opt, idx) => (
          <CustomButton
            key={idx}
            appearance={activeView === opt ? "primary" : "ghost"}
            onClick={() => setActiveView(opt)}>
            {_.startCase(opt)}
          </CustomButton>
        ))}
      </div>
      {activeView === "highlights" && <Highlights />}
      {activeView === "inclusions_exclusions" && <InclusionsExclusions />}
      {activeView === "distribution_periods" && <DistributionPeriods />}
      {activeView === "meeting_points" && <MeetingPointSetupController />}
    </div>
  );
};
export default AddOnPreferences;
