import service from "@src/api/request";

export function getBranchesList(para) {
  return service({
    url: "/bid/branch/",
    method: "get",
    params: para,
  });
}

export function getQuickBranchesList(para) {
  return service({
    url: "/bid/branch/get_quick_info",
    method: "get",
    params: para,
  });
}

export function fetchBranchesProfile(id) {
  return service({
    url: `/bid/branch/${id}`,
    method: "get",
  });
}

export function getBranchesOptions(lang) {
  return service({
    url: "/bid/branch/",
    method: "options",
    headers: {
      "Accept-Language": lang,
    },
  });
}

export function submitBranchesProfile(id, payload, mode) {
  return service({
    url: `/bid/branch/${id}/`,
    method: mode,
    data: payload,
  });
}

export function addNewBranches(data) {
  return service({
    url: "/bid/branch/",
    method: "post",
    data: data,
  });
}
