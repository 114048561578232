import { Canvas, StyleSheet } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import React from "react";

const dividerStyles = StyleSheet.create({
  Divider: { height: 1, width: "100%", marginVertical: 5 },
});
const Divider = ({ color }) => {
  const dividerStyle = { ...dividerStyles.Divider, backgroundColor: color };

  return <Canvas style={dividerStyle} />;
};
Divider.defaultProps = { color: "#000" };
Divider.propTypes = { color: PropTypes.string };
export default Divider;
