import { saveMktTrip } from "@src/actions/Project/TripPlanner";
import { CustomButton } from "@src/components/common/buttons";
import { NormalRichText } from "@src/components/forms";
import { cardStyles, variables } from "@src/jsssetup";
import { saveBookingInfoAction } from "@src/reducers/Project/TripPlanner";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { createUseStyles } from "react-jss";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Loader } from "rsuite";

const pkgBookingInformationStyles = createUseStyles({
  ...cardStyles,
  PkgBookingInformation: cardStyles.card,
  headerActions: {
    display: "grid",
    gridAutoFlow: "column",
    gridGap: variables.half_gap,
  },
  description: {
    maxHeight: "10rem",
    overflowY: "auto",
  },
});
const PkgBookingInformation = () => {
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const bookingInfo = useSelector((state) => state.tripPlannerMktBookingInfo);
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    async (booking_information) => {
      setIsLoading(true);
      await dispatch(saveBookingInfoAction(booking_information));

      const success = await dispatch(saveMktTrip());
      if (success) {
        toast.success("Booking Information saved successfully", {
          autoClose: 3000,
        });
      }

      setIsLoading(false);
      setEditMode(false);
    },
    [dispatch]
  );

  const classes = pkgBookingInformationStyles();
  return (
    <Formik initialValues={bookingInfo} onSubmit={(values) => onSubmit(values)}>
      {({ submitForm }) => (
        <div className={classes.PkgBookingInformation}>
          {isLoading && <Loader size="lg" backdrop />}
          <div className={classes.header}>
            <h5>Booking Information</h5>
            <div className={classes.headerActions}>
              <CustomButton
                appearance={editMode ? "ghost" : "primary"}
                onClick={() => (editMode ? submitForm() : setEditMode(true))}>
                {editMode ? "Done" : "Edit"}
              </CustomButton>
            </div>
          </div>
          <div className={classes.body}>
            {editMode ? (
              <Form>
                <NormalRichText name="description_en" label="Description" />
              </Form>
            ) : (
              <p
                className={classes.description}
                dangerouslySetInnerHTML={{
                  __html: bookingInfo?.description_en || "N/A",
                }}
              />
            )}
          </div>
        </div>
      )}
    </Formik>
  );
};
export default PkgBookingInformation;
