import { Divider } from "rsuite";
import ImageBankTiles from "@src/pages/MyLibrary/ImageBank/Components/ImageBankTiles.js";

import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import update from "immutability-helper";

import { connect } from "react-redux";
import { changePkgDataContent } from "@src/actions/Project/TripPlanner";
import { createUseStyles } from "react-jss";
import { cardStyles, variables } from "@src/jsssetup";
import { ImagePicker } from "@src/pages/Network/B2CDistributionChannels/detail/components/ImageModal";

const mainImageStyles = createUseStyles({
  MainImage: {
    ...cardStyles.card,
    height: "100%",
    overflow: "hidden",
    display: "grid",
    gridTemplateRows: "max-content 1fr",
  },
  header: cardStyles.header,
  body: {
    ...cardStyles.body,
    gridGap: variables.normal_gap,
    gridTemplateColumns: "1fr 1fr",
    height: "100%",
    overflow: "hidden",
  },
  imageList: {
    height: "100%",
    overflow: "auto",
  },
});
var MainImage = (props) => {
  const { content, main_images, onChange } = props;

  const classes = mainImageStyles();

  var handleMainImageCheckPickerChange = (value) => {
    const con = _.cloneDeep(content);

    if (value.length && !value.some((img) => _.get(img, "main_photo", false))) {
      value[0]["main_photo"] = true;
    }

    con[`pkgMainImages`] = value;
    onChange(con);
  };
  var handleMainImageMainPhotoChange = (params) => {
    const { value, idx } = params;
    const con = _.cloneDeep(content);

    const results = update(con, {
      pkgMainImages: {
        $apply: (images) =>
          images.map((image, index) => {
            if (idx == index) {
              image.main_photo = value;
            } else {
              if (value) {
                image.main_photo = false;
              }
            }
            return image;
          }),
      },
    });
    onChange(results);
  };
  var handleMainImageRemoveImage = (params) => {
    const con = _.cloneDeep(content);
    const images = con[`pkgMainImages`].filter((a) => a.id !== params.id);
    if (
      images.length > 0 &&
      !images.some((img) => _.get(img, "main_photo", false))
    ) {
      images[0]["main_photo"] = true;
    }
    con[`pkgMainImages`] = images;
    onChange(con);
  };

  return (
    <div className={classes.MainImage}>
      <div className={classes.header}>
        <h5>Main Images</h5>
      </div>
      <div className={classes.body}>
        <div className={classes.imageList}>
          <ImagePicker
            selectedIds={main_images.map((e) => e.id)}
            onSelect={(img) =>
              handleMainImageCheckPickerChange([...main_images, img])
            }
          />
        </div>
        <ImageBankTiles
          data={main_images}
          handleMainPhotoChange={(params) =>
            handleMainImageMainPhotoChange(params)
          }
          handleRemoveImage={(params) => handleMainImageRemoveImage(params)}
        />
      </div>
    </div>
  );
};
MainImage.propTypes = {
  main_images: PropTypes.array.isRequired,
  content: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

const dayByDayImageStyles = createUseStyles({
  DayByDayImage: {
    ...cardStyles.card,
    height: "100%",
    overflow: "hidden",
    display: "grid",
    gridTemplateRows: "max-content 1fr",
    position: "relative",
  },
  header: cardStyles.header,
  body: {
    ...cardStyles.body,
    gridGap: variables.normal_gap,
    height: "100%",
    overflow: "auto",
  },
  imageList: {
    height: "100%",
    overflow: "auto",
  },
  day: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    height: "40vh",
  },
  dayContent: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    overflow: "hidden",
  },
});
var DayByDayImage = (props) => {
  const { content, onChange } = props;

  const classes = dayByDayImageStyles();

  var handleCheckPickerChange = (item, value) => {
    const con = _.cloneDeep(content);
    if (value.length && !value.some((img) => _.get(img, "main_photo", false))) {
      value[0]["main_photo"] = true;
    }
    const res = update(con, {
      itineraryDaysData: {
        [item[0]]: {
          images: { $set: value },
        },
      },
    });
    onChange(res);
  };

  var handleMainPhotoChange = (item, params) => {
    const { value, idx } = params;
    const con = _.cloneDeep(content);
    const tmp = _.get(con, "itineraryDaysData", {});
    const images = tmp[item[0]][`images`];
    images.map((e, index) => {
      if (idx == index) {
        e.main_photo = value;
      } else {
        if (value) {
          e.main_photo = false;
        }
      }
      return e;
    });
    onChange(con);
  };

  var handleRemoveImage = (item, e) => {
    const con = _.cloneDeep(content);
    const tmp = _.get(con, "itineraryDaysData", {});
    const images = tmp[item[0]][`images`].filter((a) => a.id !== e.id);
    if (
      images.length > 0 &&
      !images.some((img) => _.get(img, "main_photo", false))
    ) {
      images[0]["main_photo"] = true;
    }
    const res = update(con, {
      itineraryDaysData: {
        [item[0]]: {
          images: { $set: images },
        },
      },
    });
    onChange(res);
  };

  const daysEntries = Object.entries(_.get(content, "itineraryDaysData", {}));

  return (
    <div shaded className={classes.DayByDayImage}>
      <div className={classes.header}>
        <h5>Day By Day Images</h5>
      </div>
      <div className={classes.body}>
        {daysEntries.map((item, idx) => {
          const currentImgs = _.get(item, "1.images", []);
          return (
            <React.Fragment key={idx}>
              <div className={classes.day}>
                <strong>Day {item[0]} Images</strong>
                <div className={classes.dayContent}>
                  <ImagePicker
                    selectedIds={currentImgs.map((e) => e.id)}
                    onSelect={(value) =>
                      handleCheckPickerChange(item, [...currentImgs, value])
                    }
                  />
                  <ImageBankTiles
                    data={_.get(item, "1.images", [])}
                    handleMainPhotoChange={(params) =>
                      handleMainPhotoChange(item, params)
                    }
                    handleRemoveImage={(params) =>
                      handleRemoveImage(item, params)
                    }
                  />
                </div>
              </div>
              {idx + 1 < daysEntries.length && <Divider />}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};
DayByDayImage.propTypes = {
  content: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

const pkgImageFormStyles = createUseStyles({
  PkgImageForm: {
    display: "grid",
    padding: variables.normal_gap,
    gridTemplateColumns: "repeat(2, 1fr)",
    overflow: "hidden",
    gridGap: variables.normal_gap,
    height: "100%",
  },
});
var PkgImageForm = (props) => {
  const { content, main_images, onChange } = props;

  const classes = pkgImageFormStyles();
  return (
    <div className={classes.PkgImageForm}>
      <MainImage
        content={content}
        main_images={main_images}
        onChange={(value) => onChange(value)}
      />
      <DayByDayImage content={content} onChange={(value) => onChange(value)} />
    </div>
  );
};
PkgImageForm.propTypes = {
  main_images: PropTypes.array.isRequired,
  content: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  const content = state.tripPlannerMktPkgContent;
  const main_images = _.get(content, "pkgMainImages", []);

  return { content, main_images };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onChange: (content) => dispatch(changePkgDataContent(content)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PkgImageForm);
