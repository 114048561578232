import langDictionaries from "./lang_dictionaries";
import _ from "lodash";

const LANGUAGE = "fr";

var langFrDictionary = {};

langDictionaries.forEach((langDictionary) => {
  const obj = _.get(langDictionary, LANGUAGE, {});
  Object.entries(obj).forEach(([key, value]) => {
    langFrDictionary[key] = value;
  });
});

export default langFrDictionary;
