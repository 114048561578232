import {
  // ================= ACCOMMODATION FILTERS ACTIONS =================
  TRIPPLANNER_OVERVIEW_ACC_FILTERS_CHANGE,
  TRIPPLANNER_OVERVIEW_SELECT_ROOM,
} from "./types";

// ============================ SELECTORS ============================

export const overviewAccFiltersChange = (filters) => {
  return { type: TRIPPLANNER_OVERVIEW_ACC_FILTERS_CHANGE, filters };
};

export const overviewAccSelectRoom =
  (destOrder, accommodation, roomId) => async (dispatch) => {
    await dispatch(accSelectRoom(destOrder, accommodation, roomId));
  };

/**
 * Used to store the room selection of an accommodation in a specific destination
 * @param {number} destOrder
 * @param {string|number} accommodation
 * @param {string|number} roomId
 */
export const accSelectRoom = (destOrder, accommodation, roomId) => {
  return {
    type: TRIPPLANNER_OVERVIEW_SELECT_ROOM,
    destOrder,
    accommodation,
    roomId,
  };
};
