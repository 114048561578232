import { v4 } from "uuid";
import * as yup from "yup";
import logoSrc from "../assets/easyTravelTech_logo_orange.png";
import { saveInvoiceDocument } from "@src/api/DynamicInvoice";

function itemSchema(currencyRequired) {
  return yup.object().shape({
    name: yup
      .string()
      // .required("Required")
      // .matches(/^[a-zA-Z ]+$/, "Characters only")
      .default(""),
    quantity: yup
      .mixed()
      .transform((value, originalValue) => (originalValue === "" ? "" : value))
      // .required("Required")
      // .typeError("Must be a number")
      // .positive("Must be a positive number")
      // .integer("Must be an integer")
      .nullable(true)
      .default(""),
    unitPrice: yup
      .mixed()
      .transform((value, originalValue) => (originalValue === "" ? "" : value))
      // .required("Required")
      // .typeError("Must be a number")
      // .positive("Must be a positive number")
      .nullable(true)
      .default(""),
    currency: currencyRequired
      ? yup.string().required("Required").default("")
      : yup.string().default(""),
  });
}

function guestSchema(currencyRequired) {
  return yup.object().shape({
    firstName: yup.string().default(""),
    lastName: yup.string().default(""),
    price: yup
      .mixed()
      .transform((value, originalValue) => (originalValue === "" ? "" : value))
      .nullable(true)
      .default(""),
    currency: currencyRequired
      ? yup.string().required("Required").default("")
      : yup.string().default(""),
    description: yup.string().default(""),
  });
}

export function customerSchema() {
  return yup
    .object()
    .shape({
      name: yup.string().required("Required").nullable(true).default(""),
      address: yup.string().nullable(true).default(""),
      country: yup.string().nullable(true).default(""),
      country_code: yup.string().nullable(true).default(""),
      phone: yup.string().nullable(true).default(""),
      company_email: yup.string().nullable(true).default(""),
      company: yup.string().nullable(true).default(""),
    })
    .test(
      "phone-or-email",
      "Phone or Email is required",
      (value) => !!(value.phone || value.company_email)
    );
}

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const emailOrEmptyStringRegex =
  /^(?:[a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]*\.[a-zA-Z]{2,})?$/;

function emailSchema() {
  return yup.object().shape({
    sender_email: yup
      .string()
      .required(
        "Is required. Please define a sender email address in the Invoice Setup page."
      )
      .matches(emailRegex, "Email addresses only")
      .default(""),
    receiver_email: yup
      .string()
      .required("Required")
      .matches(emailRegex, "Email addresses only")
      .default(""),
    cc_email: yup
      .string()
      .matches(emailOrEmptyStringRegex, "Email addresses only")
      .default(""),
    email_subject: yup.string().required("Required").default(""),
    email_body: yup.string().required("Required").default(""),
  });
}

export function customFieldSchema() {
  return yup.object().shape({
    label: yup
      .string()
      // .required("Required")
      .default(""),
    value: yup
      .string()
      // .required("Required")
      .default(""),
  });
}

function customerOptionsSchema() {
  return yup.object().shape({
    currency: yup.string().required("Required").default(""),
    dueDate: yup.string().required("Required").nullable(true).default(""),
    memo: yup.string().nullable(true).default(""),
    footer: yup.string().nullable(true).default(""),
    invoiceNumber: yup.string().required("Required").nullable(true).default(""),
    stamp: yup.string().nullable(true).default(""),
    xAxis: yup
      .number()
      .nullable(true)
      .default(0)
      .min(0, "Minimum value is 0")
      .max(100, "Maximum value is 100"),
    yAxis: yup
      .number()
      .nullable(true)
      .default(0)
      .min(0, "Minimum value is 0")
      .max(100, "Maximum value is 100"),
  });
}

export function validationSchema({ currencyRequired }) {
  return yup.object().shape({
    ...customerOptionsSchema().fields,
    customer: customerSchema(),
    email: emailSchema(),
    items: yup.array().of(itemSchema(currencyRequired)).min(0),
    guests: yup.array().of(guestSchema(currencyRequired)).min(0),
    customFields: yup.array().of(customFieldSchema()).min(0),
  });
}

export function createPayload({
  values,
  source_entity,
  logo,
  payment_link,
  payment_reference,
  productType,
}) {
  const totalAmount = values.items.reduce(
    (acc, item) => acc + item.unitPrice * item.quantity,
    0
  );

  const payload = {
    logo,
    product_type: productType,
    stamp: values.stamp,
    xAxis: values.xAxis,
    yAxis: values.yAxis,
    source_entity: source_entity,
    target_entity: values.customer.source_entity,
    invoice_pdf_uid: "",
    target_entity_name: values.customer.name,
    target_entity_company: values.customer.company,
    target_entity_country: values.customer.country,
    target_entity_vat: "",
    target_entity_address: values.customer.address,
    target_entity_email: values.customer.company_email,
    target_entity_phone: values.customer.phone,
    status: "open", // must contain a valid choice
    title: values.invoiceNumber, //required
    total_amount: totalAmount,
    sender_email: values.email.sender_email,
    cc_email: values.email.cc_email,
    receiver_email: values.email.receiver_email,
    email_subject: values.email.email_subject,
    email_body: values.email.email_body,
    currency: values.currency,
    product_reference: values.product_reference,
    custom_fields: values.customFields.map((field) => ({
      label: field.label,
      value: field.value,
    })),
    guests: values.guests.map((guest) => ({
      first_name: guest.firstName,
      last_name: guest.lastName,
      price: guest.price,
      currency: guest.currency,
      description: guest.description,
    })),
    items: values.items.map((item) => ({
      name: item.name,
      amount: item.unitPrice,
      currency: item.currency,
      quantity: item.quantity,
      description: "",
    })),
    description: "",
    footer_description: values.customer.footer,
    memo: values.customer.memo,
    metadata: { metadata: [] },
    payment_link: payment_link,
    payment_reference: payment_reference,
    payment_link_provider: values.payment_method,
    invoice_pdf_path: "",
  };
  return payload;
}

export async function onSaveInvoiceDocument({ pdfInstance, payload }) {
  const tags = [
    "invoice",
    `${payload.product_reference}`,
    `${payload.source_entity}_source_entity`,
    `${payload.target_entity}_target_entity`,
  ];

  const file = new File(
    [pdfInstance.blob],
    `invoice__${payload.invoice_uid}__${payload.source_entity}__${payload.target_entity}.pdf`,
    { type: "application/pdf" }
  );

  const formData = new FormData();
  formData.append("file", file);
  formData.append("source_entity", payload.source_entity);
  tags.forEach((tag) => {
    formData.append("tags", tag);
  });

  const result = await saveInvoiceDocument(formData);
  return result;
}
