import _ from "lodash";
import { IconButton, Icon, Button } from "rsuite";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { modalGenericStyles, variables } from "@src/jsssetup";
import { currencies } from "@src/definitions";
import { Formik, Form } from "formik";
import { NormalInputField, NormalSelectField } from "@src/components/forms";
import { connect } from "react-redux";
import { addDbDCustomSrv } from "@src/actions/Project/TripPlanner/day_by_day/custom_services";
import { avgPriceStyles } from "./restaurants";
import { Duration } from "luxon";

const FiltersForm = ({ availability_types, onFilter }) => {
  return (
    <Formik
      initialValues={{
        name: "",
        external_id: "",
        availability_type: "",
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validateOnMount={false}
      onSubmit={(values) => {
        onFilter(values);
      }}>
      {({ handleReset, submitForm }) => (
        <div className="Card">
          <div className="Card__body">
            <Form className="Form">
              <NormalInputField name="name" label="Name" />
              <NormalInputField name="external_id" label="External Id" />
              <NormalSelectField
                name="availability_type"
                label="Availability Type"
                options={[
                  [null, "------"],
                  ...availability_types.map((tp) => [tp, _.startCase(tp)]),
                ]}
              />
            </Form>
          </div>
          <div className="Card__actions">
            <button className="Button" data-ghost="true" onClick={handleReset}>
              Reset
            </button>
            <button className="Button" type="submit" onClick={submitForm}>
              Filter
            </button>
          </div>
        </div>
      )}
    </Formik>
  );
};
FiltersForm.defaultProps = { availability_types: [] };
FiltersForm.propTypes = {
  availability_types: PropTypes.array.isRequired,
  onFilter: PropTypes.func.isRequired,
};

const GenSrvStyles = createUseStyles({
  card: modalGenericStyles.card,
  header: modalGenericStyles.cardHeader,
  headerTitle: {
    display: "grid",
    fontSize: "large",
    fontWeight: "bold",
  },
  headerSubTitle: {
    fontSize: "medium",
    fontWeight: "normal",
  },
  headerActions: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    alignItems: "center",
  },
  headerAttribute: {
    fontSize: "smaller",
  },
  totalPriceContainer: {
    display: "grid",
    gridTemplateColumns: "max-content 1fr",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    alignItems: "center",
  },
  totalPrice: { ...avgPriceStyles, textAlign: "center" },
  body: {
    ...modalGenericStyles.cardBody,
    minHeight: "unset",
    display: "grid",
    gridGap: variables.normal_gap,
    gridAutoRows: "max-content",
    alignItems: "start",
  },
  priceOptions: {
    display: "grid",
    gridGap: variables.normal_gap,
  },
  priceOption: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    borderRadius: "5px",
    boxShadow: variables.shadows.normalShadow,
    padding: variables.normal_gap,
  },
  priceOptionContent: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
  },
  priceOptionHeader: {
    fontSize: "large",
  },
  priceOptionAttrs: {
    display: "grid",
    gridTemplateColumns: "repeat(4, max-content)",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    justifyContent: "space-between",
  },
  optionRemarksContainer: {
    borderTop: `2px solid ${variables.colors.deepblue}`,
    paddingTop: variables.half_gap,
  },
  priceOptionRemarksHeader: {
    fontSize: "large",
    fontWeight: "bold",
  },
  priceOptionPrices: {
    display: "grid",
    gridTemplateColumns: "repeat(2, max-content)",
    gridGap: variables.normal_gap,
    justifyContent: "end",
  },
  priceOptionPrice: {
    gridGap: variables.normal_gap,
    justifySelf: "end",
    background: variables.colors.deepblue,
    padding: variables.normal_gap,
    color: "white",
    borderRadius: "5px",
  },
  priceOptionForm: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
  },
  price: {
    display: "grid",
    gridGap: variables.normal_gap,
  },
  bodyHeader: {
    fontWeight: "bold",
  },
  bodySection: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
  },
  durationForm: {
    gridColumn: "span 6",
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    gridAutoFlow: "column",
    gridAutoColumns: "max-content",
    borderTop: `2px solid ${variables.colors.easy.lightOrange2}`,
  },
  durationFieldContainer: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
  },
  durationFieldPrice: {
    padding: `calc(${variables.normal_gap} / 2)`,
    fontSize: "large",
    color: "white",
    background: "green",
    fontWeight: "bold",
    textAlign: "center",
    borderRadius: `calc(${variables.normal_gap} / 2)`,
  },
  actionBtn: { gridColumn: "span 6" },
});
const GenSrv = ({
  idx,
  destination_id,
  secondary_destination_id,
  srv,
  onSelect,
}) => {
  const classes = GenSrvStyles();

  const [viewDetails, setViewDetails] = useState(false);
  const [editPrice, setEditPrice] = useState(false);
  const [remarks, setRemarks] = useState("");

  const service_data = srv?.service_data ?? {};
  const currency = currencies?.[service_data?.total_price?.currency] ?? "N/A";

  return (
    <div className={classes.card}>
      <div className={classes.header}>
        <div className={classes.headerTitle}>
          <span>
            {idx}. {service_data.short_description_en}
          </span>
          <span className={classes.headerSubTitle}>
            <strong>External Id:</strong> {service_data.id}
          </span>
          <span className={classes.headerSubTitle}>{service_data.name}</span>
        </div>
        <div className={classes.headerActions}>
          {!editPrice ? (
            <div className={classes.totalPriceContainer}>
              <IconButton
                icon={<Icon icon="edit" />}
                circle={true}
                size="xs"
                appearance="primary"
                onClick={() => setEditPrice(true)}
              />
              <span className={classes.totalPrice}>
                {currency}
                {_.get(service_data, "total_price.amount", 0)}
              </span>
            </div>
          ) : (
            <div className={classes.totalPriceContainer}>
              <IconButton
                icon={<Icon icon="close" />}
                circle={true}
                size="xs"
                color="red"
                onClick={() => setEditPrice(false)}
              />
              <Formik
                initialValues={{
                  price: _.get(service_data, "total_price.amount", 0),
                  duration: _.get(service_data, "duration", 0),
                }}
                onSubmit={(values) => {
                  const tmp = _.cloneDeep(srv);

                  if (tmp.service_data.pricing.length) {
                    tmp.service_data.pricing[0].remarks = remarks;
                  }
                  tmp.service_data.remarks = remarks;

                  const original_price_edited =
                    values.price !== tmp.service_data.total_price.amount;
                  tmp.service_data["price_data"] = {
                    total_price: values.price,
                    currency: tmp.service_data.total_price.currency,
                    original_price_edited,
                  };
                  tmp.service_data["duration"] = values.duration;

                  tmp.destination_id = destination_id;
                  tmp.secondary_destination_id = secondary_destination_id;
                  onSelect(tmp);
                  setEditPrice(false);
                }}>
                <Form className={classes.price}>
                  <NormalInputField
                    name="price"
                    label="Total Price"
                    type="number"
                  />
                  <NormalInputField
                    name="duration"
                    label="Duration (minutes)"
                    type="number"
                  />
                  <Button color="green" type="submit">
                    <strong>Select</strong>
                  </Button>
                </Form>
              </Formik>
            </div>
          )}
          <Button
            appearance={viewDetails ? "ghost" : "primary"}
            onClick={() => setViewDetails((p) => !p)}>
            <strong>{viewDetails ? "Hide Details" : "View Details"}</strong>
          </Button>
        </div>
      </div>
      {viewDetails && (
        <div className={classes.body}>
          <div className={classes.priceOptions}>
            {_.get(service_data, "pricing", []).map((pr, idx) => (
              <div key={idx} className={classes.priceOption}>
                <div className={classes.priceOptionContent}>
                  <span className={classes.priceOptionHeader}>
                    <strong>Description: </strong>
                    {pr.description}
                  </span>
                  <div className={classes.priceOptionAttrs}>
                    <span>
                      <strong>Price Id: </strong>
                      {pr.price_id}
                    </span>
                    <span>
                      <strong>Availability Type: </strong>
                      {_.startCase(pr.availability_type)}
                    </span>
                    <span>
                      <strong>Min/Max Pax: </strong>
                      {pr.min_pax}/{pr.max_pax}
                    </span>
                    <span>
                      <strong>Max Kms: </strong>
                      {pr.max_kms}
                    </span>
                    <span>
                      <strong>Duration: </strong>
                      {Object.entries(
                        Duration.fromObject({
                          hours: 0,
                          minutes: service_data.duration,
                        })
                          .normalize()
                          .toObject()
                      )
                        .filter((entry) => entry[1])
                        .map((entry) => `${entry[1]} ${entry[0]}`)
                        .join(", ") || "N/A"}
                    </span>
                  </div>
                  <div className={classes.optionRemarksContainer}>
                    <span className={classes.priceOptionRemarksHeader}>
                      Remarks
                    </span>
                    <Formik
                      initialValues={{ remarks: pr.remarks }}
                      validate={(values) => setRemarks(values.remarks)}>
                      <Form>
                        <NormalInputField
                          name="remarks"
                          label="Remarks"
                          as="textarea"
                        />
                      </Form>
                    </Formik>
                  </div>
                </div>
                <div className={classes.priceOptionPrices}>
                  <div className={classes.priceOptionPrice}>
                    <strong>
                      Pax Price: {_.get(currencies, pr.currency, "N/A")}
                      {pr.pax_price}
                    </strong>
                  </div>
                  <div className={classes.priceOptionPrice}>
                    <strong>
                      Unit Price: {_.get(currencies, pr.currency, "N/A")}
                      {pr.unit_price}
                    </strong>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
GenSrv.propTypes = {
  destination_id: PropTypes.string.isRequired,
  secondary_destination_id: PropTypes.string.isRequired,
  idx: PropTypes.number.isRequired,
  srv: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
};

const generalServicesListStyles = createUseStyles({
  GeneralServicesList: { width: "85vw", maxWidth: "85vw" },
  body: {
    display: "grid",
    gridTemplateColumns: "1fr 4fr",
    gridGap: variables.normal_gap,
  },
  list: {
    display: "grid",
    gridGap: variables.normal_gap,
    gridAutoRows: "max-content",
  },
  filters: {
    ...modalGenericStyles.card,
    minWidth: "unset",
    height: "max-content",
    position: "sticky",
    top: 0,
  },
  filtersHeader: modalGenericStyles.cardHeader,
});
const GeneralServicesList = ({
  dayIdx,
  loading,
  data,
  destination_id,
  secondary_destination_id,
  onCancel,
  onSelect,
}) => {
  const [filters, setFilters] = useState({});
  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    var fData = data;
    if (filters.name) {
      fData = fData.filter((srv) =>
        _.get(srv, "service_data.short_description_en", "")
          .toLowerCase()
          .includes(filters.name.toLowerCase())
      );
    }

    if (filters.availability_type) {
      fData = fData.filter((srv) =>
        srv.service_data.pricing.some(
          (pr) => pr.availability_type === filters.availability_type
        )
      );
    }

    if (filters.external_id) {
      fData = fData.filter((srv) =>
        srv.service_data.id
          .toLowerCase()
          .includes(filters.external_id.toLowerCase())
      );
    }

    setFilteredData(fData);
  }, [data, filters]);

  const availability_types = [
    ...new Set(
      _.flattenDeep(
        (data || []).map((dt) =>
          _.get(dt, "service_data.pricing", []).map(
            (pr) => pr.availability_type
          )
        )
      )
    ),
  ];

  const classes = generalServicesListStyles();
  return (
    !loading && (
      <div className={`${classes.GeneralServicesList} Modal__card`}>
        <div className="Modal__card__header">
          <h5>Available General Services</h5>
        </div>
        <div className={`${classes.body} Modal__card__body`}>
          <div className={classes.filters}>
            <div className={classes.filtersHeader}>
              <h6>Filters</h6>
            </div>
            <FiltersForm
              availability_types={availability_types}
              onFilter={(filters) => setFilters(filters)}
            />
          </div>
          <div className={classes.list}>
            {filteredData.map((srv, idx) => (
              <GenSrv
                key={idx}
                destination_id={destination_id}
                secondary_destination_id={secondary_destination_id}
                srv={srv}
                idx={idx + 1}
                onSelect={async (srv) => {
                  await onSelect({ dayIdx, srv });
                }}
              />
            ))}
          </div>
        </div>
        <div className="Modal__card__actions">
          <button className="Button" data-ghost="true" onClick={onCancel}>
            Close
          </button>
        </div>
      </div>
    )
  );
};
GeneralServicesList.defaultProps = {
  destination_id: "",
  loading: false,
  data: [],
};
GeneralServicesList.propTypes = {
  dayIdx: PropTypes.number.isRequired,
  destination_id: PropTypes.string.isRequired,
  secondary_destination_id: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};
const mapDispatchToProps = (dispatch) => {
  return {
    onSelect: ({ dayIdx, srv }) =>
      dispatch(addDbDCustomSrv({ dayIdx, srv, srv_type: "GEN" })),
  };
};

export default connect(null, mapDispatchToProps)(GeneralServicesList);
