import {
  INIT_NETWORK_READ_MORE_DATA,
  INIT_PAGE_READ_MORE_DATA,
} from "@src/actions/InitPage/types";

export const initNetworkReadMoreData = (state = {}, action) => {
  switch (action.type) {
    case INIT_PAGE_READ_MORE_DATA:
      return {};
    case INIT_NETWORK_READ_MORE_DATA:
      return action.data;
    default:
      return state;
  }
};
