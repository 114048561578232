import { injectIntl } from "react-intl";

import _ from "lodash";
import { naString } from "@src/tools/string_tools";
import PropTypes from "prop-types";

const TransTxt = ({ intl, id, startCase, upperCase }) => {
  if (!id) {
    return naString();
  }

  let text = intl.formatMessage({ id: id });
  if (startCase) {
    return _.startCase(text);
  }
  if (upperCase) {
    return _.upperCase(text);
  }

  return text;
};
TransTxt.propTypes = {
  intl: PropTypes.object,
  startCase: PropTypes.bool,
  upperCase: PropTypes.bool,
  id: PropTypes.string.isRequired,
};
export default injectIntl(TransTxt);
