import { tableStyles, variables } from "@src/jsssetup";
import {
  genericInfoGroupStyles,
  genericRsrvFeebackStyles,
} from "../common/styles";

// export const basicInfoStylesObj = {
//   BasicInfo: cardStyles.card,
//   header: cardStyles.header,
//   table: tableStyles.table,
//   head: tableStyles.head,
//   cell: tableStyles.cell,
//   pricecell: {
//     ...tableStyles.cell,
//     "width": "5rem",
//     "& input": {
//       width: "5rem",
//     },
//   },
// };
export const basicInfoStylesObj = {
  BasicInfo: genericRsrvFeebackStyles.card,
  header: genericRsrvFeebackStyles.cardHeader,
  body: { ...genericRsrvFeebackStyles.cardBody, overflow: "visible" },
  groupCard: {
    display: "grid",
    gridGap: variables.normal_gap,
    backgroundColor: variables.colors.easy.lightOrange3,
    padding: variables.normal_gap,
    borderRadius: "10px",
  },
  // infoGroup2: {
  //   display: "grid",
  //   gridGap: variables.normal_gap,
  //   gridTemplateColumns: "1fr",
  // },
  // infoGroup3: {
  //   display: "grid",
  //   gridGap: variables.normal_gap,
  //   gridTemplateColumns: "repeat(3, 1fr)",
  // },
  // [`@media ${variables.media.bigscreen}`]: {
  //   infoGroup2: {
  //     display: "grid",
  //     gridGap: variables.normal_gap,
  //     gridTemplateColumns: "repeat(2, 1fr)",
  //   },
  //   infoGroup3: {
  //     paddingTop: "20px",
  //   },
  // },

  infoCouple: genericInfoGroupStyles.infoCouple,
  infoCoupleTitle: genericInfoGroupStyles.infoCoupleTitle,
  infoCoupleValue: genericInfoGroupStyles.infoCoupleValue,
  table: tableStyles.table,
  head: tableStyles.head,
  cell: tableStyles.cell,
  pricecell: {
    ...tableStyles.cell,
    "width": "5rem",
    "& input": {
      width: "5rem",
    },
  },
};
