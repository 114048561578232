import _ from "lodash";
import { Button } from "rsuite";

import {
  NormalDatePicker,
  NormalInputField,
  NormalSelectField,
} from "@src/components/forms";
import { formStyles, modalGenericStyles, variables } from "@src/jsssetup";
import { Formik, Form } from "formik";
import PropTypes from "prop-types";
import React from "react";
import { createUseStyles } from "react-jss";
import { DateTime } from "luxon";
import { adhocCurrencyOptions } from ".";
import { addonSrvPriceCalculator } from "./common";

const coachAdhocSrvFormFieldsStyles = createUseStyles({
  form: formStyles.form,
  header: modalGenericStyles.cardHeader,
  body: modalGenericStyles.cardBody,
  actions: modalGenericStyles.cardActions,
  trpPoint: adhocPointStyles,
});
export const CoachAdhocSrvFormFields = ({
  origin_name,
  destination_name,
  children,
}) => {
  const classes = coachAdhocSrvFormFieldsStyles();
  return (
    <Form className="Form">
      <div className="col2">
        <span className={classes.trpPoint}>
          <strong>Origin: {origin_name || "N/A"}</strong>
        </span>
        <span className={classes.trpPoint}>
          <strong>Destination: {destination_name || "N/A"}</strong>
        </span>
        <NormalInputField name="pickup_point" label="Pickup Point" />
        <NormalInputField name="dropoff_point" label="Dropoff Point" />
        <NormalDatePicker name="departure" label="Departure" withTime={true} />
        <NormalDatePicker name="arrival" label="Arrival" withTime={true} />
      </div>
      <div className="col3">
        <NormalInputField name="company_name" label="Company Name" />
        <NormalInputField name="reference" label="Reference" />
        <NormalInputField name="distance" label="Distance (km)" type="number" />
        <NormalSelectField
          name="coach_type"
          label="Coach Type"
          options={[
            [null, "------"],
            ["wagon", "Wagon"],
            ["sedan", "Sedan"],
            ["mini_van", "Mini Van"],
            ["bus", "Bus"],
            ["other", "Other"],
          ]}
        />
        <NormalInputField name="coach_seats" label="Seats" type="number" />
        <NormalInputField
          name="coach_capacity"
          label="Coach Capacity"
          type="number"
        />
      </div>
      <div className="col2">
        <NormalSelectField
          name="parking_fees"
          label="Parking Fees"
          options={[
            [true, "Included"],
            [false, "Excluded"],
          ]}
        />
        <NormalSelectField
          name="tipping"
          label="Tipping"
          options={[
            [true, "Included"],
            [false, "Excluded"],
          ]}
        />
        <AdhocSrvPriceFields />
      </div>
      <NormalInputField
        name="booking_information"
        label="Booking Information"
        as="textarea"
      />
      {children}
    </Form>
  );
};
CoachAdhocSrvFormFields.propTypes = {
  origin_name: PropTypes.string.isRequired,
  destination_name: PropTypes.string.isRequired,
};

export function adhocPriceFields({
  total_price = 0,
  per_person_price = 0,
  currency = "EUR",
} = {}) {
  return {
    per_person_price,
    unit_price: 0,
    total_price,
    currency,
    original_price_edited: false,
  };
}

export function adhocTrpTrfCommonFields({
  referenceDeparture = "",
  referenceArrival = "",
}) {
  return {
    departure: referenceDeparture,
    arrival: referenceArrival,
    ...adhocPriceFields(),
  };
}

export const AdhocSrvPriceFields = () => {
  return (
    <React.Fragment>
      <NormalInputField
        name="per_person_price"
        label="Price Per Person"
        type="number"
      />
      <NormalInputField name="unit_price" label="Unit Price" type="number" />
      <NormalInputField name="total_price" label="Total Price" type="number" />
      <NormalSelectField
        name="currency"
        label="Currency"
        options={adhocCurrencyOptions}
      />
    </React.Fragment>
  );
};

export function getReferenceDates({ origin_dest, destination_dest }) {
  var referenceDeparture = "";
  var referenceArrival = "";
  if (!_.isEmpty(origin_dest)) {
    referenceDeparture = DateTime.fromISO(origin_dest.checkOut).toISO();
    referenceArrival = DateTime.fromISO(origin_dest.checkOut)
      .plus({ hours: 1 })
      .toISO();
  } else if (!_.isEmpty(destination_dest)) {
    referenceDeparture = DateTime.fromISO(destination_dest.checkIn).toISO();
    referenceArrival = DateTime.fromISO(destination_dest.checkIn)
      .plus({ hours: 1 })
      .toISO();
  }

  return { referenceDeparture, referenceArrival };
}
export function getOriginAndDest({ origin_dest, destination_dest }) {
  const dest_keys = [
    "country_code",
    "id",
    "geodata",
    "name_en",
    "name_cn",
    "type",
  ];
  const origin = origin_dest ? _.pick(origin_dest, dest_keys) : {};
  const destination = destination_dest
    ? _.pick(destination_dest, dest_keys)
    : {};

  return { origin, destination };
}

export const adhocPointStyles = {
  padding: `calc(${variables.normal_gap} / 2)`,
  border: `2px solid ${variables.colors.deepgrey}`,
  borderRadius: "5px",
  alignSelf: "end",
};

const CoachAdhocSrv = ({
  adults,
  childs,
  destination_dest,
  origin_dest,
  onSelect,
  onCancel,
}) => {
  const { referenceDeparture, referenceArrival } = getReferenceDates({
    destination_dest,
    origin_dest,
  });

  const { origin, destination } = getOriginAndDest({
    origin_dest,
    destination_dest,
  });

  return (
    <div className="Modal__card">
      <div className="Modal__card__header">
        <h5>
          Add Coach Service: ({_.get(origin_dest, "name_en", "N/A")} to{" "}
          {_.get(destination_dest, "name_en", "N/A")})
        </h5>
      </div>

      <Formik
        initialValues={{
          origin,
          destination,
          ...adhocTrpTrfCommonFields({
            referenceDeparture,
            referenceArrival,
          }),
          pickup_point: "",
          dropoff_point: "",
          minutes: 0,
          image: {},
          company_name: "",
          coach_type: "",
          coach_seats: 0,
          coach_capacity: 0,
          distance: "",
          parking_fees: false,
          tipping: false,
          booking_information: "",
          cancellation_policy: [],
        }}
        validate={(values) =>
          addonSrvPriceCalculator({ values, adults, childs })
        }
        onSubmit={(values) => onSelect(values)}>
        {({ submitForm }) => (
          <React.Fragment>
            <div className="Modal__card__body">
              <CoachAdhocSrvFormFields
                origin_name={origin.name_en}
                destination_name={destination.name_en}
              />
            </div>
            <div className="Modal__card__actions">
              <button className="Button" data-ghost="true" onClick={onCancel}>
                Cancel
              </button>
              <button
                className="Button"
                onClick={(e) => {
                  e.preventDefault();
                  submitForm();
                }}>
                Add
              </button>
            </div>
          </React.Fragment>
        )}
      </Formik>
    </div>
  );
};
CoachAdhocSrv.defaultProps = { destination_dest: {}, origin_dest: {} };
CoachAdhocSrv.propTypes = {
  adults: PropTypes.number.isRequired,
  childs: PropTypes.number.isRequired,
  destination_dest: PropTypes.object.isRequired,
  origin_dest: PropTypes.object.isRequired,
  onCancel: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default CoachAdhocSrv;
