import {
  AUTH_SHOW_AUTH_MODAL,
  AUTH_HIDE_AUTH_MODAL,
  AUTH_INIT_USER_PERMS,
  AUTH_SET_USER_PERMS,
  AUTH_SET_USER_FEATURE_FLAG_DATA,
} from "./types";

import {
  fetchCompanyProfile,
  fetchCompanyOptions,
} from "@src/actions/Admin/Company";
import { getB2CWebsiteData } from "@src/actions/Admin/B2CPolicy";
import { fetchUserDetails, fetchUserOptions } from "@src/actions/Admin/Users";
import { getCountryCodeListInfo } from "@src/actions/Shares";
import { fetchCurrencies, updateCurrencyRates } from "@src/actions/Financial";
import { getUserFeatureFlags } from "@src/api";
import { notifyCannotGetGroups } from "@src/components/common/AuthNotifications";

export const showAuthModal = () => {
  return { type: AUTH_SHOW_AUTH_MODAL };
};

export const hideAuthModal = () => {
  return { type: AUTH_HIDE_AUTH_MODAL };
};

export const initUserPerms = () => {
  return { type: AUTH_INIT_USER_PERMS };
};

export const setUserPerms = ({ groups, entity_groups, top_entity_groups }) => {
  return {
    type: AUTH_SET_USER_PERMS,
    groups,
    entity_groups,
    top_entity_groups,
  };
};

export const setFeatureFlagsData = (result) => {
  return { type: AUTH_SET_USER_FEATURE_FLAG_DATA, data: result };
};

export const initializeAppData = () => async (dispatch) => {
  var result = null;
  try {
    result = await getUserFeatureFlags();
    const { groups, entity_groups, top_entity_groups } = result;
    await dispatch(setFeatureFlagsData(result));
    await dispatch(setUserPerms({ groups, entity_groups, top_entity_groups }));
  } catch (error) {
    console.log({ error });
    notifyCannotGetGroups();
    return;
  }

  await dispatch(fetchUserOptions());
  await dispatch(fetchUserDetails());
  await dispatch(fetchCompanyProfile());
  await dispatch(fetchCompanyOptions());
  await dispatch(getB2CWebsiteData());

  dispatch(getCountryCodeListInfo());
  await dispatch(fetchCurrencies());
  dispatch(updateCurrencyRates());
};
