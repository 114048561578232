import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { variables } from "@src/jsssetup";

const pageLoadingStyles = createUseStyles({
  PageLoading: {},
  loadingContainer: {
    width: "200px",
    margin: "0 auto",
    display: "grid",
    gridTemplateColumns: "repeat(6, 1fr)",
  },
  circle: {
    width: "20px",
    height: "20px",
    backgroundColor: variables.colors.easy.orange,
    borderRadius: "50%",
  },
  text: {
    color: variables.colors.text.dark,
  },
});
const PageLoading = (props) => {
  const { message } = props;

  const classes = pageLoadingStyles();

  return (
    <div className="sx-loading-wrapper">
      <img src="https://sisistaticassets.s3.ap-southeast-1.amazonaws.com/platform_graphics/imgs/logo_page_filler.svg" />
      <div className={classes.loadingContainer}>
        <div className={`${classes.circle} sx-loading-circle`}></div>
        <div className={`${classes.circle} sx-loading-circle`}></div>
        <div className={`${classes.circle} sx-loading-circle`}></div>
        <div className={`${classes.circle} sx-loading-circle`}></div>
        <div className={`${classes.circle} sx-loading-circle`}></div>
        <div className={`${classes.circle} sx-loading-circle`}></div>
      </div>
      <p className={classes.text}>Loading</p>
    </div>
  );
};

PageLoading.propTypes = {
  message: PropTypes.string,
};

export default PageLoading;
