import { WhisperWrapper } from "@src/components/common/ui_helpers";
import React from "react";
import TransTxt from "@src/components/common/SxFormatMessage";
import moment from "moment";
import SuIcon from "@src/style/icon/components/SuIcon";
import _ from "lodash";

export const initialBasicFilterForm = {
  reference: {
    value: "",
    verbose: "Reference",
  },
  trip_title: {
    value: "",
    verbose: <TransTxt id="tripplanlist_title" startCase />,
  },
  request_by: {
    value: "",
    verbose: <TransTxt id="tripplanlist_request_by" startCase />,
  },
  travellers: {
    value: "",
    verbose: <TransTxt id="travellers" startCase />,
  },
  adults: {
    value: "",
    verbose: <TransTxt id="adults" startCase />,
  },
  children: {
    value: "",
    verbose: <TransTxt id="children" startCase />,
  },
  destinations: {
    value: [],
    verbose: <TransTxt id="tripplanlist_destinations" startCase />,
  },
  date: {
    value: [],
    verbose: <TransTxt id="trips_date" startCase />,
  },
  pricing: {
    value: "selling",
    verbose: <TransTxt id="pricing" startCase />,
    data: [
      {
        label: <TransTxt id="selling_price" startCase />,
        value: "selling",
      },
      {
        label: <TransTxt id="buying_price" startCase />,
        value: "buying",
      },
    ],
  },
};

export const archiveColumnsForm = {
  travellers: {
    display_name: <TransTxt id="travellers" startCase />,
    // eslint-disable-next-line react/display-name
    getter: (data) => {
      const res = _.get(data, "itinerary_setup");
      return (
        <div className="travellers">
          <div>
            <h4>{res.adults}</h4>
          </div>
          <div>
            <h4>{res.children}</h4>
          </div>
        </div>
      );
    },
    // eslint-disable-next-line react/display-name
    extraGetter: () => {
      return (
        <div className="travellers-total">
          <span>Adults</span>
          <span>Children</span>
        </div>
      );
    },
  },
  services: {
    flag: true,
    display_name: "Services",
    getter: (data) => {
      const res = _.get(data, "service_types");
      const addons_data = _.get(data, "addon_data");
      const iconArr = [];
      const options = [
        {
          value: "FL",
          icon: "icon-Transports",
          label: "Transportation",
        },
        {
          value: "TF",
          icon: "icon-Transfers",
          label: "Transfer",
        },
        {
          value: "ACC",
          icon: "icon-Accommodation",
          label: "Accommodation",
        },
        {
          value: "MI",
          icon: "icon-AddOns_Function",
          label: "Add Ons",
        },
      ];
      Object.keys(res).map((item) => {
        const icon = _.find(options, (e) => {
          return e.value == item;
        }).icon;
        const label = _.find(options, (e) => {
          return e.value == item;
        }).label;
        if (res[item].available) {
          iconArr.push({ icon, label });
        }
      });
      if (!_.isEmpty(addons_data)) {
        iconArr.push({
          icon: "icon-AddOns_Function",
          label: "Add Ons",
        });
      }

      return iconArr.map((e, index) => {
        return (
          <WhisperWrapper key={index} msg={e.label}>
            <span>
              <SuIcon icon={e.icon} />
            </span>
          </WhisperWrapper>
        );
      });
    },
    extraGetter: (data) => {
      const res = _.get(data, "service_types");
      const addons_data = _.get(data, "addon_data");
      let num = 0;
      Object.values(res).map((item) => {
        if (item.available) {
          return (num += item.number);
        }
      });
      if (!_.isEmpty(addons_data)) {
        return num + 1;
      } else {
        return num;
      }
    },
  },
  destinations: {
    display_name: "Destinations",
    // eslint-disable-next-line react/display-name
    getter: (data) => {
      const res = _.get(data, "destinations", []);
      return <h4>{res.length}</h4>;
    },
    extraGetter: (data) => {
      const res = _.get(data, "destinations", []);
      const countryArr = [];
      res.forEach((item) => {
        countryArr.push(item.country_code);
      });
      if (Array.from(new Set(countryArr)).length > 1) {
        return `${Array.from(new Set(countryArr)).length} Countries`;
      } else {
        return `${Array.from(new Set(countryArr)).length} Country`;
      }
    },
  },
  duration: {
    display_name: (data) => {
      let return_data = "";
      if (!_.isEmpty(data.return_data)) {
        return_data = _.get(data, "return_data.date");
      } else {
        return_data = _.get(data, "destinations", [])[
          _.get(data, "destinations", []).length - 1
        ].checkOut;
      }
      const num = moment(return_data).diff(
        moment(_.get(data, "origin_data.date")),
        "days"
      );
      return `${num + 1} Days`;
    },
    // eslint-disable-next-line react/display-name
    getter: (data) => {
      const date = _.get(data, "origin_data.date");
      return moment(date).format("MMM D");
    },
    // eslint-disable-next-line react/display-name
    extraGetter: (data) => {
      const arr = _.get(data, "destinations");
      const sum = arr.reduce((prev, cur) => {
        return cur.stay + prev;
      }, 0);

      if (sum > 1) {
        return `${sum} Nights`;
      } else {
        return `${sum} Night`;
      }
    },
  },
  per_person: {
    display_name: "Per Person",
    // eslint-disable-next-line react/display-name
    getter: (data, options) => {
      const price = parseFloat(_.get(data, "price")).toFixed(2);
      const currency = _.get(data, "currency");
      const symbol = (
        _.find(options, (item) => {
          return item.notation == currency;
        }) || {}
      ).symbol;

      return <h5>{`${symbol}${price}`}</h5>;
    },
    extraGetter: () => {
      return "";
    },
  },
};
