export const initialAccountStatementColumnsForm = {
  basic: {
    id: "basic",
    value: [
      "Stripe Transaction Id",
      "Date",
      "Transactions Detail",
      "Credit",
      "Debit",
      "Balance",
    ],
    verbose: "Basic",
    choices: [
      {
        verbose: "Date",
        key: "created",
        formatDate: true,
      },
      {
        verbose: "Transactions Detail",
        key: "description",
      },
      {
        verbose: "Stripe Transaction Id",
        key: "stripe_transaction_id",
      },
      {
        verbose: "Credit",
        key: "amount",
      },
      {
        verbose: "Debit",
        key: "amount",
      },
      {
        verbose: "Balance",
        key: "balance",
      },
    ],
  },
};

export const initialAccountStatementColumnsMapping = {
  "Date": {
    key: "Date",
  },
  "Stripe Transaction Id": {
    key: "Stripe Transaction Id",
  },
  "Transactions Detail": {
    key: "Transactions Detail",
  },
  "Credit": {
    key: "Credit",
  },
  "Debit": {
    key: "Debit",
  },
  "Balance": {
    key: "Balance",
  },
};

export const initialAccountStatementFilterForm = {
  entities: {
    value: "",
    verbose: "Entity Name",
  },
  reference: {
    value: "",
    verbose: "Reference",
  },
  service: {
    value: "",
    verbose: "Service",
  },
};
