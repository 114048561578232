import TransTxt from "@src/components/common/SxFormatMessage";
import React from "react";
import _ from "lodash";

import { formatDate } from "@src/tools/date_tools";

export const initialBillAndPaymentColumnsSetUp = {
  basic: {
    value: [
      "date",
      "invoice_id",
      "transaction_type",
      "payment_method",
      "amount",
    ],
    choices: [
      {
        key: "date",
        display_name: <TransTxt id="bill_and_payments__date" startCase />,
      },
      {
        key: "invoice_id",
        display_name: <TransTxt id="bill_and_payments__invoice_id" startCase />,
      },
      {
        key: "transaction_type",
        display_name: (
          <TransTxt id="bill_and_payments__transaction_type" startCase />
        ),
      },
      {
        key: "payment_method",
        display_name: (
          <TransTxt id="bill_and_payments__payment_method" startCase />
        ),
      },
      {
        key: "amount",
        display_name: <TransTxt id="bill_and_payments__amount" startCase />,
      },
    ],
  },
};

export const initialBillAndPaymentColumnsProcessor = {
  date: {
    key: "date",
    getter: (data) => formatDate(data.created),
  },
  invoice_id: {
    key: "invoice_id",
    getter: (data) => data.id,
  },
  transaction_type: {
    key: "transaction_type",
    getter: () => "Subscription",
  },
  payment_method: {
    key: "payment_method",
    getter: (data) => _.get(data, "payment_method_types[0]"),
  },
  amount: {
    key: "amount",
    getter: (data) => data.amount_paid,
  },
};

export const initialBillAndPaymentColumnsMapping = {
  date: {
    key: "date",
    width: 200,
  },
  invoice_id: {
    key: "invoice_id",
    width: 250,
  },
  transaction_type: {
    key: "transaction_type",
    width: 250,
  },
  payment_method: {
    key: "payment_method",
    width: 250,
  },
  amount: {
    key: "amount",
    width: 200,
  },
};
