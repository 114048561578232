import {
  initialAffiliatesColumnsSetUp,
  initialAffiliatesFilterForm,
  initialAffiliatesBaseDataForm,
  initialAffiliatesNatureOfBizForm,
} from "@src/forms/Network/Affiliates";

import { Schema } from "rsuite";

import {
  AFFILIATES_BEGIN_FETCH_DATA,
  AFFILIATES_FETCH_DATA,
  AFFILIATES_END_FETCH_DATA,
  AFFILIATES_UPDATE_LIST_FILTER_FORM,
  AFFILIATES_APPLY_LIST_FILTER,
  AFFILIATES_INITIALIZE_LIST_FILTER_FORM,
  AFFILIATES_UPDATE_LIST_COLUMNS_FORM,
  AFFILIATES_APPLY_LIST_COLUMNS,
  AFFILIATES_INITIALIZE_LIST_COLUMNS_FORM,
  AFFILIATES_FETCH_OPTIONS,
  AFFILIATES_FETCH_PROFILE,
  AFFILIATES_SHOW_BASE_DATA_EDIT_FORM,
  AFFILIATES_HIDE_BASE_DATA_EDIT_FORM,
  AFFILIATES_EDIT_BASE_DATA,
  AFFILIATES_INITIALIZE_BASE_DATA_EDIT_FORM,
  AFFILIATES_CLEAN_BASE_DATA_EDIT_FORM,
  AFFILIATES_UPLOADING_BASE_DATA_EDIT_FORM,
  AFFILIATES_IDLE_BASE_DATA_EDIT_FORM,
  AFFILIATES_VALIDATE_BASE_DATA_EDIT_FORM,
  AFFILIATES_CLEAN_BASE_EDIT_FORM_ERRORS,
  AFFILIATES_PROFILE_DATA,
  AFFILIATES_VIEW_NATURE_OF_BIZ,
  AFFILIATES_EDIT_NATURE_OF_BIZ,
  AFFILIATES_INITIALIZE_NATURE_DATA_FORM,
  AFFILIATES_EDIT_NATURE_OF_BIZ_DATA,
  AFFILIATES_SUBMIT_NATURE_OF_BIZ_DATA,
  AFFILIATES_EDIT_LICENSE_CODE,
  AFFILIATES_TOGGLE_POSTAL_ADDRESS,
  AFFILIATES_RESET_LIST_FILTER_FORM,
  AFFILIATES_CLEAN_BASIC_BASE_DATA_EDIT_FORM,
  AFFILIATES_TOGGLE_VERIFY_ADDRESS,
  AFFILIATES_BEGIN_FETCH_ADDRESS_DATA,
  AFFILIATES_END_FETCH_ADDRESS_DATA,
  AFFILIATES_FETCH_ADDRESS_POINT,
  AFFILIATES_CLEAN_ADDRESS_POINT,
  //img
  AFFILIATES_UPLOAD_PREFERENCE_IMAGE,
  AFFILIATES_INITIAL_ADDRESS_POINT,
  AFFILIATES_BEGIN_FETCH_PROFILE_DATA,
  AFFILIATES_END_FETCH_PROFILE_DATA,
  AFFILIATES_INITIAL_VERIFY_STATUS,
  AFFILIATES_CLOSE_VERIFY_ADDRESS,
} from "@src/actions/Network/Affiliates/types";

import {
  FORMAT_TRAVEL_SERVICES_OPTIONS,
  FORMAT_TRAVEL_SERVICES_FORM,
} from "@src/actions/Shares/types";

import { formProcessor, entitiesListSelector } from "@src/reducers/tools";

import update from "immutability-helper";
import _ from "lodash";

// affiliates profile
export const affiliatesBaseDataForm = (
  state = initialAffiliatesBaseDataForm,
  action
) => {
  switch (action.type) {
    case AFFILIATES_INITIALIZE_BASE_DATA_EDIT_FORM:
      return action.data;
    case AFFILIATES_CLEAN_BASE_DATA_EDIT_FORM:
      return initialAffiliatesBaseDataForm;
    case AFFILIATES_EDIT_BASE_DATA:
      return update(state, {
        [action.data.name]: { value: { $set: action.data.value } },
      });
    case AFFILIATES_UPLOAD_PREFERENCE_IMAGE:
      return update(state, {
        [action.data.name]: {
          value: { $set: action.data.value },
          fileName: { $set: action.data.fileName },
        },
      });
    default:
      return state;
  }
};

export const initialAffiliatesBaseDataFormErrorsState = { valid: false };
export const affiliatesBaseDataFormErrors = (
  state = initialAffiliatesBaseDataFormErrorsState,
  action
) => {
  const tmp = {};
  Object.entries(initialAffiliatesBaseDataForm).forEach((e) => {
    tmp[e[0]] = e[1].validator;
  });
  const validator = Schema.Model(tmp);

  switch (action.type) {
    case AFFILIATES_VALIDATE_BASE_DATA_EDIT_FORM: {
      const tmp = {};
      Object.entries(action.data).forEach((e) => (tmp[e[0]] = e[1].value));

      const validation = validator.check(tmp);

      const valid = Object.entries(validation).every((e) => !e[1].hasError);
      validation["valid"] = valid;
      return validation;
    }
    case AFFILIATES_CLEAN_BASE_EDIT_FORM_ERRORS:
      return initialAffiliatesBaseDataFormErrorsState;
    default:
      return state;
  }
};

export const initialAffiliatesBaseDataUploadStatus = "IDLE";
export const affiliatesBaseDataUploadStatus = (
  state = affiliatesBaseDataUploadStatus,
  action
) => {
  switch (action.type) {
    case AFFILIATES_UPLOADING_BASE_DATA_EDIT_FORM:
      return "LOADING";
    case AFFILIATES_IDLE_BASE_DATA_EDIT_FORM:
      return "IDLE";
    default:
      return state;
  }
};

export const affiliatesProfile = (state = {}, action) => {
  switch (action.type) {
    case AFFILIATES_FETCH_PROFILE:
      return action.data;
    case AFFILIATES_PROFILE_DATA:
      return update(state, { $merge: action.data });
    default:
      return state;
  }
};

export const initialAffiliatesBaseDataEditFormShow = false;
export const affiliatesBaseDataEditFormShow = (
  state = initialAffiliatesBaseDataEditFormShow,
  action
) => {
  switch (action.type) {
    case AFFILIATES_SHOW_BASE_DATA_EDIT_FORM:
      return true;
    case AFFILIATES_HIDE_BASE_DATA_EDIT_FORM:
      return false;
    default:
      return state;
  }
};

export const initialAffiliatesOptions = {
  status: {
    choices: [
      { value: 1, display_name: "Active" },
      { value: 2, display_name: "Inactive" },
      { value: 3, display_name: "Candidate" },
    ],
  },
};
export const affiliatesOptions = (state = initialAffiliatesOptions, action) => {
  switch (action.type) {
    case AFFILIATES_FETCH_OPTIONS:
      return update(state, { $merge: action.data });
    case FORMAT_TRAVEL_SERVICES_OPTIONS:
      return update(state, { $merge: action.data });
    default:
      return state;
  }
};

export const initialAffiliatesProfileFetchStatus = "IDLE";
export const affiliatesProfileFetchStatus = (
  state = initialAffiliatesProfileFetchStatus,
  action
) => {
  switch (action.type) {
    case AFFILIATES_BEGIN_FETCH_PROFILE_DATA:
      return "FETCHING";
    case AFFILIATES_END_FETCH_PROFILE_DATA:
      return "FETCHED";
    default:
      return state;
  }
};

export const initialAffiliatesOptionsFetchStatus = "IDLE";
export const affiliatesOptionsFetchStatus = (
  state = initialAffiliatesOptionsFetchStatus,
  action
) => {
  switch (action.type) {
    case AFFILIATES_FETCH_OPTIONS:
      return "FETCHED";
    default:
      return state;
  }
};

// affiliates list
export const initialAffiliatesList = {
  count: 0,
  next: null,
  previous: null,
  results: [],
  page: 1,
  limit: 20,
  displayLength: 100,
};
export const affiliatesList = (state = initialAffiliatesList, action) => {
  switch (action.type) {
    case AFFILIATES_FETCH_DATA:
      return action.data;
    case AFFILIATES_RESET_LIST_FILTER_FORM:
      return { ...state, page: 1 };
    default:
      return state;
  }
};

export const initialAffiliatesPostalAddressIsSynchronous = false;
export const affiliatesPostalAddressIsSynchronous = (
  state = initialAffiliatesPostalAddressIsSynchronous,
  action
) => {
  switch (action.type) {
    case AFFILIATES_SHOW_BASE_DATA_EDIT_FORM:
      return action.data;
    case AFFILIATES_TOGGLE_POSTAL_ADDRESS:
      return action.data;
    default:
      return state;
  }
};

export const initialAffiliatesListFetchStatus = "IDLE";
export const affiliatesListFetchStatus = (
  state = initialAffiliatesListFetchStatus,
  action
) => {
  switch (action.type) {
    case AFFILIATES_BEGIN_FETCH_DATA:
      return "FETCHING";
    case AFFILIATES_END_FETCH_DATA:
      return "FETCHED";
    default:
      return state;
  }
};

// filter
export const affiliatesListFilter = (
  state = initialAffiliatesFilterForm,
  action
) => {
  switch (action.type) {
    case AFFILIATES_APPLY_LIST_FILTER:
      return action.data;
    case FORMAT_TRAVEL_SERVICES_FORM:
      return update(state, { $merge: action.data });
    case AFFILIATES_RESET_LIST_FILTER_FORM:
      return action.data;
    default:
      return state;
  }
};

export const affiliatesListFilterForm = (
  state = initialAffiliatesFilterForm,
  action
) => {
  switch (action.type) {
    case AFFILIATES_INITIALIZE_LIST_FILTER_FORM:
      return action.data;
    case AFFILIATES_RESET_LIST_FILTER_FORM:
      return action.data;
    case AFFILIATES_UPDATE_LIST_FILTER_FORM:
      return formProcessor(
        state,
        action.data.type,
        action.data.name,
        action.data.value
      );
    case FORMAT_TRAVEL_SERVICES_FORM:
      return update(state, { $merge: action.data });
    default:
      return state;
  }
};

// columns
export const affiliatesListColumns = (
  state = entitiesListSelector(initialAffiliatesColumnsSetUp),
  action
) => {
  switch (action.type) {
    case AFFILIATES_APPLY_LIST_COLUMNS:
      return entitiesListSelector(action.data);
    default:
      return state;
  }
};

export const affiliatesListColumnsForm = (
  state = initialAffiliatesColumnsSetUp,
  action
) => {
  switch (action.type) {
    case AFFILIATES_INITIALIZE_LIST_COLUMNS_FORM: {
      var newState = {};
      const tmp = _.cloneDeep(state);
      for (const key in tmp) {
        // clear form's value
        tmp[key] = update(tmp[key], { value: { $set: [] } });
        newState[key] = update(tmp[key], { value: { $set: [] } });
        // add value by action data
        action.data.forEach((item) => {
          if (_.find(tmp[key].choices, { key: item.key })) {
            newState[key] = update(newState[key], {
              value: { $push: [item.key] },
            });
          }
        });
      }

      return newState;
    }
    case AFFILIATES_UPDATE_LIST_COLUMNS_FORM:
      return action.data;
    default:
      return state;
  }
};

export const initialAffiliatesNatureOfBizMode = "VIEW";
export const affiliatesNatureOfBizMode = (
  state = initialAffiliatesNatureOfBizMode,
  action
) => {
  switch (action.type) {
    case AFFILIATES_VIEW_NATURE_OF_BIZ:
      return "VIEW";
    case AFFILIATES_EDIT_NATURE_OF_BIZ:
      return "EDIT";
    case AFFILIATES_SUBMIT_NATURE_OF_BIZ_DATA:
      return "LOADING";
    default:
      return state;
  }
};

export const affiliatesNatureOfBizForm = (
  state = initialAffiliatesNatureOfBizForm,
  action
) => {
  switch (action.type) {
    case AFFILIATES_INITIALIZE_NATURE_DATA_FORM:
      return action.data;
    case AFFILIATES_CLEAN_BASIC_BASE_DATA_EDIT_FORM:
      return initialAffiliatesNatureOfBizForm;
    case AFFILIATES_EDIT_NATURE_OF_BIZ_DATA:
      // action.data.type defines the type of the update operation
      // push: we push an array to the existing data.
      // set: we directly set the data contained in the action.
      // remove: we empty the form array.
      //License type is a two dimensional array so we need to have special handling
      // of this data. [[<licence_type>, <license_code>]]

      if (action.data.type == "push") {
        let value = action.data.value;
        if (action.data.name == "license_type") {
          value = [action.data.value, ""];
        }

        return update(state, {
          [action.data.name]: { value: { $push: [value] } },
        });
      } else if (action.data.type == "remove") {
        let truth = (v) => v !== action.data.value;

        if (action.data.name == "license_type") {
          truth = (v) => v[0] !== action.data.value;
        }

        return update(state, {
          [action.data.name]: {
            value: { $apply: (va) => va.filter((v) => truth(v)) },
          },
        });
      } else {
        let value = action.data.value;
        if (action.data.name == "license_type") {
          value = value.map((v) => [v, ""]);
        }

        return update(state, {
          [action.data.name]: { value: { $set: value } },
        });
      }
    case AFFILIATES_EDIT_LICENSE_CODE:
      return update(state, {
        license_type: {
          value: {
            $apply: (lt) => {
              const newLt = [];
              lt.forEach((t) => {
                if (t[0] == action.data.name) {
                  newLt.push([t[0], action.data.value]);
                } else {
                  newLt.push(t);
                }
              });
              return newLt;
            },
          },
        },
      });
    default:
      return state;
  }
};

export const affiliatesAddressPoint = (state = {}, action) => {
  switch (action.type) {
    case AFFILIATES_FETCH_ADDRESS_POINT:
      return action.data;
    case AFFILIATES_INITIAL_ADDRESS_POINT:
      return action.data;
    case AFFILIATES_CLEAN_ADDRESS_POINT:
      return { lat: "", lng: "" };
    default:
      return state;
  }
};

export const initialIsVerifyAffiliatesPostalAddress = false;
export const isVerifyAffiliatesPostalAddress = (
  state = initialIsVerifyAffiliatesPostalAddress,
  action
) => {
  switch (action.type) {
    case AFFILIATES_TOGGLE_VERIFY_ADDRESS:
      return action.data;
    case AFFILIATES_INITIAL_VERIFY_STATUS:
      return false;
    case AFFILIATES_CLOSE_VERIFY_ADDRESS:
      return false;
    default:
      return state;
  }
};

export const initialFetchAffiliatesAddressPointStatus = "IDLE";
export const fetchAffiliatesAddressPointStatus = (
  state = initialFetchAffiliatesAddressPointStatus,
  action
) => {
  switch (action.type) {
    case AFFILIATES_BEGIN_FETCH_ADDRESS_DATA:
      return "FETCHING";
    case AFFILIATES_END_FETCH_ADDRESS_DATA:
      return "FETCHED";
    default:
      return state;
  }
};

export * from "./ServiceCategories";
