import { chatUpdate } from "@src/actions/Chat";
import { retrieveChatGroupMetadata } from "@src/api/chat";
import { variables } from "@src/jsssetup";
import { getUserMetaSelector } from "@src/selectors/Shared/user_selectors";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useDispatch, useSelector } from "react-redux";
import Body from "./Body";
import Header from "./Header";

const styles = createUseStyles({
  ChatComp: {
    padding: variables.normal_gap,
    paddingLeft: variables.double_gap,
    display: "grid",
    gridTemplateRows: "max-content 1fr",
    gridGap: variables.normal_gap,
    height: "100vh",
  },
});
const ChatComp = ({ defaultChatRoom }) => {
  const [selectedGroup, setSelectedGroup] = useState();
  const [groupMeta, setGroupMeta] = useState();
  const [connectionOpen, setConnectionOpen] = useState(false);
  const dispatch = useDispatch();

  const { member_email, chatNotifications } = useSelector((state) => ({
    member_email: _.get(getUserMetaSelector(state), "user.email", ""),
    chatNotifications: state.ChatReducer.notifications,
  }));

  const classes = styles({});

  const updateChatNotifications = useCallback(
    (data) => {
      dispatch(chatUpdate(data));
    },
    [dispatch]
  );

  async function getChatGroupMetadata(uid) {
    const metadata = await retrieveChatGroupMetadata({ uid });
    setGroupMeta(metadata);
  }

  function onSelectRoom(data) {
    setSelectedGroup(data);
    updateChatNotifications({
      notifications: chatNotifications.filter(
        (ntf) => ntf.room_uid.uid !== data.room_uid
      ),
    });
  }

  useEffect(() => {
    if (!_.get(selectedGroup, "uid")) {
      return;
    }
    getChatGroupMetadata(selectedGroup.uid);
  }, [selectedGroup]);

  return (
    <div className={classes.ChatComp}>
      <Header
        connectionOpen={connectionOpen}
        selectedGroup={{
          ...selectedGroup,
          members: _.get(groupMeta, "members", []).filter(
            (member) => member.email !== member_email
          ),
        }}
      />
      <Body
        preselected_group_uid={defaultChatRoom}
        selectedGroup={{
          ...selectedGroup,
          members: _.get(groupMeta, "members", []).filter(
            (member) => member.email !== member_email
          ),
        }}
        chatNotifications={chatNotifications}
        onSelectRoom={onSelectRoom}
        setConnectionStatus={(isOpen) => setConnectionOpen(isOpen)}
      />
    </div>
  );
};
ChatComp.propTypes = {
  defaultChatRoom: PropTypes.string,
};

export default ChatComp;
