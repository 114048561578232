import React, { memo } from "react";
import PropTypes from "prop-types";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import _ from "lodash";
import { naString } from "@src/tools/string_tools";
import { formatDate } from "@src/tools/date_tools";

const offerFooter =
  "https://sisistaticassets.s3.ap-southeast-1.amazonaws.com/platform_graphics/imgs/offer_foot.png";

import { PDFImage } from "@src/pages/Operation/Reservations/Component/PDF";
import { DateTime } from "luxon";
import {
  TripMeta,
  AccSummary,
  ServiceSummary,
  DayByDayContent,
} from "./components";
import {
  AdhocService,
  AccService,
  GeneralService,
  ActivityService,
  CoordinatorService,
  TrainService,
  FlService,
  TrfService,
  CoachService,
  AddonsService,
  RestaurantService,
  FerryService,
  ApiFerryService,
  ApiTrainService,
} from "./services";
import OfferMeta from "./offer_meta";
import Signature from "./signature";
import PriceTable from "./price_table";
import TermsAndConditions from "./terms_and_conditions";
import {
  AccommodationGeneralRemarks,
  AccommodationRemarks,
} from "./components/accommodation_remarks";
import NotoSansRegular from "@src/style/fonts/NotoSans-Regular.ttf";
import NotoSansBold from "@src/style/fonts/NotoSans-Bold.ttf";
import CancellationPolicy from "./components/cancellation_policy";

Font.register({
  family: "Noto Sans",
  fonts: [
    { src: NotoSansRegular, fontWeight: "normal" },
    { src: NotoSansBold, fontWeight: "bold" },
  ],
});

// ========================== Create styles ==========================
const styles = StyleSheet.create({
  page: {
    flexDirection: "columns",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 20,
    paddingBottom: 120,
    fontFamily: "Noto Sans",
  },
  body: { flexDirection: "columns", fontFamily: "Noto Sans" },
  footer: {
    position: "absolute",
    bottom: 10,
    width: "100%",
    height: "100",
    fontFamily: "Noto Sans",
  },
  logoContainer: {
    flexDirection: "row",
    alignItems: "center",
    height: "80",
    width: "120",
    fontFamily: "Noto Sans",
  },
  topSection: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
    fontFamily: "Noto Sans",
  },
  logo: { width: "100%", objectFit: "contain", fontFamily: "Noto Sans" },
  quotationOfferCntr: {
    flexDirection: "row",
    alignItems: "center",
    padding: "5",
    borderRadius: 5,
    borderColor: "#302A83",
    backgroundColor: "#302A83",
    fontFamily: "Noto Sans",
  },
  quotationOfferTxt: {
    color: "white",
    fontSize: 12,
    fontWeight: "bold",
    fontFamily: "Noto Sans",
  },
  termsAndConditionsCntr: { flexDirection: "columns", fontFamily: "Noto Sans" },
  sectionHeader: {
    textAlign: "start",
    marginTop: 20,
    marginBottom: "5px",
    fontSize: 14,
    fontFamily: "Noto Sans",
    fontWeight: "bold",
  },
  section: { minWidth: "100%", marginBottom: 10, fontFamily: "Noto Sans" },
  title: {
    width: "555px",
    padding: 8,
    marginBottom: 5,
    backgroundColor: "#302A83",
    borderRadius: "5px",
    border: "1px solid #302A83",
    fontWeight: "bold",
    color: "white",
    fontSize: 10,
    fontFamily: "Noto Sans",
  },
  image: {
    position: "relative",
    width: "100%",
    height: "50",
    objectFit: "cover",
    fontFamily: "Noto Sans",
  },
  card: {
    flexDirection: "columns",
    alignItems: "center",
    fontFamily: "Noto Sans",
  },
  cardImage: {
    width: "50",
    height: "50",
    objectFit: "contain",
    fontFamily: "Noto Sans",
  },
  cardImageBox: {
    width: "50",
    height: "50",
    borderColor: "#ccc",
    borderStyle: "solid",
    borderRadius: 4,
    marginRight: 10,
    fontFamily: "Noto Sans",
  },
  cardText: { flexDirection: "columns" },
  cardContent: {
    position: "relative",
    flexDirection: "row",
    alignItems: "center",
    padding: 5,
    fontFamily: "Noto Sans",
  },
  desDetail: {
    flexDirection: "row",
    alignItems: "end",
    justifyContent: "space-between",
    width: "100%",
    fontFamily: "Noto Sans",
  },
  desInfo: {
    flexDirection: "row",
    alignItems: "center",
    padding: 5,
    fontFamily: "Noto Sans",
  },
  flDetailSection: { width: "calc(50% - 10px)", fontFamily: "Noto Sans" },
  desInfoTxtTop: {
    fontSize: 10,
    color: "#006dcc",
    fontWeight: "light",
    maxWidth: "280",
    fontFamily: "Noto Sans",
  },
  desInfoTop: {
    flexDirection: "row",
    alignItems: "center",
    padding: 5,
    fontFamily: "Noto Sans",
  },
  desInfoFileds: {
    paddingRight: 5,
    color: "#006dcc",
    fontFamily: "Roboto",
    fontFamily: "Noto Sans",
  },
  desInfoTxt: {
    fontSize: 10,
    color: "#006dcc",
    fontWeight: "light",
    fontFamily: "Noto Sans",
  },
  cardLine: {
    height: "20",
    position: "absolute",
    top: -20,
    borderLeftWidth: 2,
    borderColor: "#b7cade",
    borderStyle: "dashed",
    fontFamily: "Noto Sans",
  },
  line: {
    position: "absolute",
    top: 10,
    left: 100,
    borderBottomWidth: 1,
    width: "100%",
    borderColor: "#006dcc",
    borderStyle: "solid",
    marginLeft: 2,
    marginRight: 2,
    fontFamily: "Noto Sans",
  },
  bottomText: {
    flexDirection: "columns",
    alignContent: "space-between",
    gap: 10,
    width: "100%",
    height: "60",
    backgroundColor: "#302A83",
    borderRadius: "5px",
    border: "1px solid #302A83",
    position: "absolute",
    bottom: 40,
    fontSize: 10,
    color: "white",
    padding: 15,
    fontFamily: "Noto Sans",
  },
  bottomPage: { position: "absolute", bottom: 10, fontFamily: "Noto Sans" },
  stayInfo: {
    color: "#006dcc",
    fontSize: "10",
    textAlign: "center",
    fontFamily: "Noto Sans",
  },
  stayInfoTxt: { textAlign: "center", fontFamily: "Noto Sans" },
  panel: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    fontFamily: "Noto Sans",
  },
  icon: { width: "30", height: "30", marginRight: 10, fontFamily: "Noto Sans" },
  cardPanel: {
    flexDirection: "columns",
    fontSize: 10,
    borderWidth: 2,
    borderColor: "#b7cade",
    borderStyle: "dashed",
    borderRadius: 4,
    color: "#006dcc",
    backgroundColor: "#fff",
    width: "100%",
    fontFamily: "Noto Sans",
  },
  cardPanelTop: {
    padding: 5,
    backgroundColor: "#e5f1fe",
    fontFamily: "Noto Sans",
  },
  cardPanelTopFlight: {
    padding: 3,
    backgroundColor: "#b3d4fd",
    color: "#fff",
    fontFamily: "Noto Sans",
  },
  cardPanelTitle: { maxWidth: "400", color: "black", fontFamily: "Noto Sans" },
  customerInfo: { flexDirection: "columns", fontFamily: "Noto Sans" },
  paxInfo: {
    padding: 5,
    color: "#006dcc",
    fontSize: "10",
    fontFamily: "Noto Sans",
  },
  specText: {
    fontFamily: "Noto Sans",
    fontWeight: "bold",
    fontFamily: "Noto Sans",
  },
  addon: {
    marginBottom: "10",
    flexDirection: "row",
    alignItems: "center",
    width: "90%",
    fontFamily: "Noto Sans",
  },
  addonCard: {
    display: "flex",
    flexDirection: "columns",
    justifyContent: "space-between",
    fontFamily: "Noto Sans",
  },
  addonDetail: {
    width: "80%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontFamily: "Noto Sans",
  },
  width_2: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 5,
    maxWidth: 160,
    fontFamily: "Noto Sans",
  },
  width_3: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 5,
    maxWidth: 120,
    fontFamily: "Noto Sans",
  },
  catergories: {
    color: "#fff",
    borderRadius: 2,
    padding: 2,
    backgroundColor: "#3cc7cc",
    display: "flex",
    width: "100",
    fontFamily: "Noto Sans",
  },
  cate: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textAlign: "center",
    fontFamily: "Noto Sans",
  },
  adhocContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    width: "100%",
    marginBottom: 20,
    fontFamily: "Noto Sans",
  },
  descriptionSection: {
    margin: "10px 0px",
    fontSize: 10,
    width: 550,
    fontFamily: "Noto Sans",
  },
});

const getServiceTypeCom = (type) => {
  switch (type) {
    case "ACC":
      return AccService;
    case "FL":
      return FlService;
    case "TRA":
      return TrainService;
    case "ATRA":
      return ApiTrainService;
    case "TRF":
      return TrfService;
    case "COA":
      return CoachService;
    case "MI":
      return AddonsService;
    case "COO":
      return CoordinatorService;
    case "REST":
      return RestaurantService;
    case "FER":
      return FerryService;
    case "AFER":
      return ApiFerryService;
    case "AD":
      return AdhocService;
    case "GEN":
      return GeneralService;
    case "ACT":
      return ActivityService;
    default:
      return null;
  }
};

//=======================Destination Card===============================
const Card = ({ data, service_type, dt, isodate, destinations = [] }) => {
  const ServiceCard = getServiceTypeCom(service_type);

  return (
    <View style={styles.section} wrap={false}>
      <View style={styles.card}>
        <ServiceCard
          data={data}
          dt={dt}
          isodate={isodate}
          destinations={destinations}
        />
      </View>
    </View>
  );
};
Card.propTypes = {
  isodate: PropTypes.string.isRequired,
  dt: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  service_type: PropTypes.string.isRequired,
  destinations: PropTypes.array.isRequired,
};

//===========================Address====================================
const getAddressStr = (address) => {
  if (!address.street || typeof address.street == "undefined")
    return naString("");

  return `${address.street_number || ""} ${address.street || ""}`;
};

const getCompanyAddress = (address) => {
  const tempAddress = _.groupBy(address, "address_type");
  address = tempAddress?.BU?.[0] ?? {};

  return [
    getAddressStr(address),
    address.region,
    address.post_code,
    address.country_code,
  ]
    .filter((str) => str)
    .join(", ");
};

const FooterComponent = ({ offerFooter, address, legal_title }) => {
  return (
    <React.Fragment>
      <Image source={offerFooter} style={styles.footer} fixed />
      <View style={styles.bottomText} fixed>
        <Text style={styles.specText}>{legal_title}</Text>
        <Text>Address: {getCompanyAddress(address)}</Text>
      </View>
      <Text
        style={styles.bottomPage}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
        fixed
      />
    </React.Fragment>
  );
};
FooterComponent.defaultProps = {
  offerFooter: "",
  address: [],
  legal_title: "",
};
FooterComponent.propTypes = {
  offerFooter: PropTypes.string.isRequired,
  address: PropTypes.array.isRequired,
  legal_title: PropTypes.string.isRequired,
};

// =======================Create Document Component=====================
const OfferPDFBody = ({
  priceSegments,
  sender,
  receiver,
  configForm,
  logo,
  destinations,
  legal_title,
  address,
  returnData,
  trip_days,
  services,
  termsAndConditions,
  tripContent,
  day_by_day_descriptions,
  reference,
  external_reference,
  custom_cancellation_policy,
}) => {
  const url = logo?.photo_sm_url ?? logo?.thumbnail_url;
  const logoSrc = url
    ? url
    : "https://sisistaticassets.s3.ap-southeast-1.amazonaws.com/platform_graphics/imgs/others/SiSi_trip_logo.png";

  const selectedSrvs = new Set(tripContent?.selectedSrvs ?? []);

  return (
    <Document title="Offer PDF">
      <Page size="A4" style={styles.page}>
        <View style={styles.body}>
          <View style={styles.topSection}>
            <View style={styles.logoContainer}>
              <PDFImage src={logoSrc} style={styles.logo} />
            </View>
            <View style={styles.quotationOfferCntr}>
              <Text style={styles.quotationOfferTxt}>Quotation Offer</Text>
            </View>
          </View>
          <View>
            <OfferMeta
              sender={sender}
              receiver={receiver}
              configForm={configForm}
            />
          </View>
          {configForm.price_info && (
            <React.Fragment>
              <View>
                <Text style={styles.sectionHeader}>Pricing Table</Text>
              </View>
              <PriceTable
                priceSegments={priceSegments}
                cancellationPolicy={custom_cancellation_policy}
              />
            </React.Fragment>
          )}
          <View style={styles.descriptionSection}>
            <Text>{configForm.description}</Text>
          </View>
          <TripMeta
            external_reference={external_reference}
            reference={reference}
            configForm={configForm}
            start={_.first(Object.values(destinations)).checkIn}
            end={_.last(Object.values(destinations)).checkOut}
          />
          <View>
            <Text style={styles.sectionHeader}>Service Summary</Text>
          </View>
          <ServiceSummary
            services={services}
            selectedSrvs={selectedSrvs}
            remarks={configForm.service_remarks}
            destinations={_.flatten(Object.values(destinations))}
          />
          <View>
            <Text style={styles.sectionHeader}>Accommodation Summary</Text>
          </View>
          <AccSummary
            destinations={_.flatten(Object.values(destinations))}
            accs={services
              .filter((srv) => srv.service_type === "ACC")
              .filter((srv) => selectedSrvs.has(`ACC___${srv.uid}`))}
          />
          {configForm?.show_cxl_policy === "true" && (
            <View wrap={false}>
              <CancellationPolicy
                cancellationPolicy={custom_cancellation_policy}
              />
            </View>
          )}
          {tripContent?.accommodationGeneralRemarks?.en !== "" && (
            <React.Fragment>
              <View>
                <Text style={styles.sectionHeader}>
                  Accommodation General Remarks
                </Text>
              </View>
              <AccommodationGeneralRemarks
                remarks={tripContent.accommodationGeneralRemarks}
              />
            </React.Fragment>
          )}
          {Object.values(tripContent?.accommodations ?? {})
            .map((val) => val.remarks.en)
            .join("") ? (
            <React.Fragment>
              <View
                wrap={
                  tripContent?.accommodationGeneralRemarks?.en !== ""
                    ? false
                    : true
                }>
                <Text style={styles.sectionHeader}>Accommodation Remarks</Text>
              </View>
              <AccommodationRemarks
                destinations={_.flatten(Object.values(destinations))}
                accommodations={services.filter(
                  (srv) => srv.service_type === "ACC"
                )}
                accommodationsRemarks={tripContent?.accommodations ?? {}}
              />
            </React.Fragment>
          ) : null}
        </View>
        <FooterComponent
          offerFooter={offerFooter}
          address={address}
          legal_title={legal_title}
        />
      </Page>
      {(day_by_day_descriptions || []).length > 0 && (
        <Page size="A4" style={styles.page}>
          <View style={styles.body}>
            <React.Fragment>
              <View wrap={true}>
                <Text style={styles.sectionHeader}>Day By Day Summary</Text>
                <DayByDayContent
                  day_by_day_descriptions={day_by_day_descriptions}
                />
              </View>
            </React.Fragment>
          </View>
          <FooterComponent
            offerFooter={offerFooter}
            address={address}
            legal_title={legal_title}
          />
        </Page>
      )}
      {configForm.offer_size !== "summary" && (
        <Page size="A4" style={styles.page}>
          <View style={styles.body}>
            <React.Fragment>
              <View>
                <Text style={styles.sectionHeader}>Itinerary</Text>
              </View>
              {trip_days.map((day, idx) => {
                const dd = _.cloneDeep(day);
                if (dd.services.length === 0) return null;

                const dt = DateTime.fromISO(dd.date)
                  .setLocale("en-US")
                  .toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
                const arrivalFl = dd.services.find(
                  (srv) => srv.eventType === "arrival" && srv.srvType === "FL"
                );
                if (arrivalFl)
                  arrivalFl.service.options = arrivalFl.service.legs[0];

                const departureFl = dd.services.find(
                  (srv) => srv.eventType === "departure" && srv.srvType === "FL"
                );

                if (departureFl) {
                  departureFl.service.options = _.flatten(
                    departureFl.service.legs.filter((l) =>
                      l.some((fl) => {
                        return (
                          DateTime.fromISO(fl.departure).toISODate() === dd.date
                        );
                      })
                    )
                  );
                }
                const departureTrain = dd.services.find(
                  (srv) =>
                    srv.srvType === "TRA" && srv.eventType === "departure"
                );

                const departureApiTrain = dd.services.find(
                  (srv) =>
                    srv.srvType === "ATRA" && srv.eventType === "departure"
                );

                const checkInSrv = dd.services.find(
                  (srv) => srv.eventType === "checkIn"
                );
                const checkOutSrv = dd.services.find(
                  (srv) => srv.eventType === "checkOut"
                );

                const adhocSrvs = dd.services
                  .filter((srv) => srv.srvType === "AD")
                  .filter((srv) => selectedSrvs.has(`AD___${srv.id}`));

                const departureCoaSrv = dd.services.find(
                  (srv) =>
                    srv.srvType === "COA" && srv.eventType === "departure"
                );

                const departureFerrySrv = dd.services.find(
                  (srv) =>
                    srv.srvType === "FER" && srv.eventType === "departure"
                );

                const departureApiFerrySrv = dd.services.find(
                  (srv) =>
                    srv.srvType === "AFER" && srv.eventType === "departure"
                );

                const customSrvs = dd.services
                  .filter((srv) => srv.srvType === "CU")
                  .filter((srv) => {
                    switch (srv.custom_service_type) {
                      case "GEN":
                        return selectedSrvs.has(`GEN___${srv.id}`);
                      case "COO":
                        return selectedSrvs.has(`COO___${srv?.uid}`);
                      default:
                        return true;
                    }
                  });

                const addonSrvs = dd.services.filter(
                  (srv) => srv.srvType === "MI"
                );
                const outboundTrf = dd.services.find(
                  (s) => s.srvType == "TRF" && s.eventType == "outbound"
                );
                const inboundTrf = dd.services.find(
                  (s) => s.srvType == "TRF" && s.eventType == "inbound"
                );

                const activities = dd.services.filter(
                  (srv) => srv.srvType === "ACT"
                );

                return (
                  <View key={idx}>
                    <View>
                      <Text style={styles.title}>
                        Day {idx + 1}:{" "}
                        {dd.destinations.map((dest) => dest.name_en).join(", ")}{" "}
                        - {dt}
                      </Text>
                    </View>
                    {arrivalFl && (
                      <Card
                        data={arrivalFl}
                        service_type="FL"
                        dt={dt}
                        isodate={dd.date}
                      />
                    )}
                    {inboundTrf && (
                      <Card
                        data={inboundTrf}
                        service_type="TRF"
                        dt={dt}
                        isodate={dd.date}
                      />
                    )}
                    {checkOutSrv && (
                      <Card
                        data={checkOutSrv}
                        service_type="ACC"
                        dt={dt}
                        isodate={dd.date}
                        destinations={dd.destinations}
                      />
                    )}
                    {checkInSrv && (
                      <Card
                        data={checkInSrv}
                        service_type="ACC"
                        dt={dt}
                        isodate={dd.date}
                        destinations={dd.destinations}
                      />
                    )}
                    {departureCoaSrv && (
                      <Card
                        data={departureCoaSrv}
                        service_type="COA"
                        dt={dt}
                        isodate={dd.date}
                        destinations={dd.destinations}
                      />
                    )}
                    {departureFerrySrv && (
                      <Card
                        data={departureFerrySrv}
                        service_type="FER"
                        dt={dt}
                        isodate={dd.date}
                        destinations={dd.destinations}
                      />
                    )}
                    {departureApiFerrySrv && (
                      <Card
                        data={departureApiFerrySrv}
                        service_type="AFER"
                        dt={dt}
                        isodate={dd.date}
                        destinations={dd.destinations}
                      />
                    )}
                    {addonSrvs.map((srv, idx) => (
                      <Card
                        key={`mi___${idx}`}
                        data={srv}
                        service_type={srv.srvType}
                        dt={dt}
                        isodate={dd.date}
                      />
                    ))}
                    {activities.map((srv, idx) => (
                      <Card
                        key={`act___${idx}`}
                        data={srv}
                        service_type={srv.srvType}
                        dt={dt}
                        isodate={dd.date}
                      />
                    ))}
                    {customSrvs.map((srv, idx) => (
                      <Card
                        key={`cu___${idx}`}
                        data={srv}
                        service_type={srv.custom_service_type}
                        dt={dt}
                        isodate={dd.date}
                      />
                    ))}
                    {departureTrain && (
                      <Card
                        data={departureTrain}
                        service_type="TRA"
                        dt={dt}
                        isodate={dd.date}
                      />
                    )}
                    {departureApiTrain && (
                      <Card
                        data={departureApiTrain}
                        service_type="ATRA"
                        dt={dt}
                        isodate={dd.date}
                      />
                    )}
                    {adhocSrvs.map((srv, idx) => (
                      <Card
                        key={`ad___${idx}`}
                        data={srv}
                        dt={dt}
                        service_type="AD"
                        isodate={dd.date}
                      />
                    ))}
                    {outboundTrf && (
                      <Card
                        data={outboundTrf}
                        service_type="TRF"
                        dt={dt}
                        isodate={dd.date}
                      />
                    )}
                    {departureFl && (
                      <Card
                        data={departureFl}
                        service_type="FL"
                        dt={dt}
                        isodate={dd.date}
                      />
                    )}
                  </View>
                );
              })}
              {!_.isEmpty(returnData) && (
                <View style={styles.title}>
                  <Text>{`Return: ${
                    returnData.destination.name_en
                  } - ${formatDate(returnData.date, {
                    format: "DD MMM YYYY",
                  })}`}</Text>
                </View>
              )}
            </React.Fragment>
          </View>
          <FooterComponent
            offerFooter={offerFooter}
            address={address}
            legal_title={legal_title}
          />
        </Page>
      )}
      {Boolean(termsAndConditions) && (
        <Page size="A4" style={styles.page}>
          <View style={styles.body}>
            <React.Fragment>
              <View wrap={true}>
                <Text style={styles.sectionHeader}>Terms and Conditions</Text>
              </View>
              <TermsAndConditions termsAndConditions={termsAndConditions} />
            </React.Fragment>
            <View wrap={false}>
              <Signature sender={sender} />
            </View>
          </View>
          <FooterComponent
            offerFooter={offerFooter}
            address={address}
            legal_title={legal_title}
          />
        </Page>
      )}
    </Document>
  );
};
OfferPDFBody.defaultProps = {
  priceSegments: [],
  reference: "",
  external_reference: "",
  custom_cancellation_policy: {},
};
OfferPDFBody.propTypes = {
  sender: PropTypes.object.isRequired,
  receiver: PropTypes.object.isRequired,
  priceSegments: PropTypes.array.isRequired,
  configForm: PropTypes.object.isRequired,
  services: PropTypes.array.isRequired,
  logo: PropTypes.object.isRequired,
  destinations: PropTypes.object.isRequired,
  legal_title: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  address: PropTypes.array.isRequired,
  returnData: PropTypes.object.isRequired,
  termsAndConditions: PropTypes.object.isRequired,
  trip_days: PropTypes.array.isRequired,
  tripContent: PropTypes.object.isRequired,
  day_by_day_descriptions: PropTypes.array.isRequired,
  reference: PropTypes.string,
  external_reference: PropTypes.string,
  custom_cancellation_policy: PropTypes.object,
  sellingAmount: PropTypes.number,
  sellingAmountPerPerson: PropTypes.number,
  sellingCurrency: PropTypes.string,
};
export default memo(OfferPDFBody);
