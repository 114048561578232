import _ from "lodash";
const { createSelector } = require("reselect");

const regularBookSrv = (state, { uid, srv_type }) => {
  return state.tripPlannerRegularBookSrvs.find(
    (srv) => srv.uid === uid && srv.srv_type === srv_type
  );
};

const optionBookSrv = (state, { uid, srv_type }) => {
  return state.tripPlannerOptionBookSrvs.find(
    (srv) => srv.uid === uid && srv.srv_type === srv_type
  );
};

export const srvRegularBookingSelector = createSelector(
  [regularBookSrv],
  (srv) => Boolean(srv)
);

export const srvOptionBookingSelector = createSelector([optionBookSrv], (srv) =>
  Boolean(srv)
);

const getTripInstanceData = (state) => state.tripPlannerInstanceData;

const propsInjector = (state, { reference, srvType }) => ({
  reference,
  srvType,
});
export const srvIsBookedSelector = createSelector(
  [getTripInstanceData, propsInjector],
  (tripInstanceData, { reference, srvType }) => {
    const booked_services = _.get(
      tripInstanceData,
      `booked_services.${srvType}`,
      []
    );
    const reservationData = booked_services.find(
      (res) => res.service_reference === reference
    );
    if (!reservationData) {
      return false;
    }

    const isBooked = !_.isEmpty(reservationData);
    return {
      isBooked,
      reservation_reference: reservationData.reservation_reference,
    };
  }
);
