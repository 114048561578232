import {
  BOOKING_REMARKS_FETCH_LIST,
  BOOKING_REMARKS_LIST_GENERIC_CHANGE,
} from "../../../actions/Admin/BookingRemarks/types";
import { commonList } from "../../common";

const BookingRemarksReducerInitial = {
  ...commonList,
  filters: {},
};

export const BookingRemarksReducer = (
  state = BookingRemarksReducerInitial,
  action
) => {
  switch (action.type) {
    case BOOKING_REMARKS_FETCH_LIST: {
      const { data } = action;
      return { ...state, ...data };
    }
    case BOOKING_REMARKS_LIST_GENERIC_CHANGE: {
      const { key, value } = action;
      if (key == "filters") {
        return { ...state, [key]: value, page: 1 };
      } else {
        return { ...state, [key]: value };
      }
    }
    default:
      return state;
  }
};
