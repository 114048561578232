import _ from "lodash";
import axios from "axios";
import { SISI_API_URL } from "@src/api";
import { getAuthHeaders } from "../request";

export async function createCoordinatorService({ payload }) {
  const auth_headers = getAuthHeaders();

  try {
    const result = await axios.post(`${SISI_API_URL}/coordinators/`, payload, {
      headers: { ...auth_headers },
    });
    return result;
  } catch (error) {
    const errors = _.get(error, "response.data", []);
    return { errors };
  }
}

export async function fetchCoordinatorsServices({
  source_entity,
  page,
  page_size,
  filters = {},
}) {
  const auth_headers = getAuthHeaders();

  try {
    const result = await axios.get(`${SISI_API_URL}/coordinators/`, {
      params: { source_entity, page, page_size, ...filters },
      headers: { ...auth_headers },
    });

    return result.data;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function fetchCoordinatorsService({ reference }) {
  const auth_headers = getAuthHeaders();

  try {
    const result = await axios.get(
      `${SISI_API_URL}/coordinators/${reference}`,
      {
        headers: { ...auth_headers },
      }
    );

    return result.data;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function updateCoordinatorService({ reference, payload }) {
  const auth_headers = getAuthHeaders();

  try {
    const result = await axios.patch(
      `${SISI_API_URL}/coordinators/${reference}`,
      payload,
      {
        headers: { ...auth_headers },
      }
    );
    return result;
  } catch (error) {
    const errors = _.get(error, "response.data", []);
    return { errors };
  }
}

export async function coordServicePriceSearch({ payload }) {
  const auth_headers = getAuthHeaders();

  try {
    const result = await axios.post(
      `${SISI_API_URL}/coordinators/price-search`,
      payload,
      {
        headers: { ...auth_headers },
      }
    );
    return _.get(result, "data", {});
  } catch (error) {
    const errors = _.get(error, "response.data", []);
    return { errors };
  }
}
