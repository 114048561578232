import { Notification } from "rsuite";
import _ from "lodash";
import React from "react";
import { langs, langTranslateMapping } from "@src/config/common";

export const notifyNoContentToTranslate = (origin_lang, target_lang) => {
  Notification.warning({
    title: "Translation Warning",
    description: (
      <p>
        There is no content in{" "}
        {langs[_.invert(langTranslateMapping)[origin_lang]]} to translate to{" "}
        {langs[_.invert(langTranslateMapping)[target_lang]]}
      </p>
    ),
    duration: 6000,
  });
};

export const notifyTranslateFailure = () => {
  Notification.error({
    title: "Translation Failure",
    description: (
      <p>Failed to translate content. We are sorry for the inconvenience.</p>
    ),
    duration: 6000,
  });
};
