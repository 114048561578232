import service, { getAuthHeaders } from "@src/api/request";
import axios from "axios";
import * as yup from "yup";
import { SISI2_API_URL } from "../../";

export function getProducts() {
  return axios.get(`${SISI2_API_URL}/subscribtions-manager/stripe/products/`);
}

export function getSubs(params) {
  return axios.get(
    `${SISI2_API_URL}/subscribtions-manager/stripe/member/subscriptions/`,
    { params }
  );
}

export function getSubsOverview(params) {
  return axios.get(
    `${SISI2_API_URL}/subscribtions-manager/stripe/member/overview/`,
    { params }
  );
}

export function getProductDetails(productId) {
  return axios.get(
    `${SISI2_API_URL}/subscribtions-manager/stripe/products/${productId}`
  );
}

export function getUpdateSubscription(payload) {
  return axios.put(
    `${SISI2_API_URL}/subscribtions-manager/stripe/member/subscriptions/`,
    payload
  );
}

// =====================================================  OLD =============================== //
export function getProductsData() {
  return service({
    url: "/bid/product/",
    method: "get",
  });
}

export function getProductsDataDetail(id) {
  return service({
    url: `/bid/product/${id}/`,
    method: "get",
  });
}

export function getUsersLicenseData() {
  return service({
    url: `/bid/user-license/`,
    method: "get",
  });
}

export function getSubscriptionPlanData(payload, mode = "get") {
  return service({
    url: "bid/subscription/",
    method: mode,
    data: payload,
  });
}

const subscriptionUpdateSchema = yup.object().shape({
  is_change_cycle: yup.boolean().required("Required"),
  cycle_type: yup.string().oneOf(["month", "week"]).required("Required"),
  cancel_trial: yup.boolean().required("Required"),
  items: yup.array().of(
    yup.object().shape({
      quantity: yup.number().required("Required").positive().integer(),
      product_id: yup.string().required("Required"),
    })
  ),
});

export async function updateSubscriptionPlan({
  is_change_cycle = false,
  cycle_type = "month",
  cancel_trial = false,
  items = [],
}) {
  subscriptionUpdateSchema.validate({
    is_change_cycle,
    cycle_type,
    cancel_trial,
    items,
  });

  const auth_headers = getAuthHeaders();

  return axios.patch(
    "/bid/subscription/",
    { is_change_cycle, cycle_type, cancel_trial, items },
    { headers: { ...auth_headers } }
  );
}
