// ========================== OPTION ACTIONS =========================
export const SHOW_RESERVATION_REQUEST_OPTIONCONF_MODAL =
  "SHOW_RESERVATION_REQUEST_OPTIONCONF_MODAL";
export const HIDE_RESERVATION_REQUEST_OPTIONCONF_MODAL =
  "HIDE_RESERVATION_REQUEST_OPTIONCONF_MODAL";
export const LOADING_RESERVATION_REQUEST_OPTIONCONF_MODAL =
  "LOADING_RESERVATION_REQUEST_OPTIONCONF_MODAL";
export const IDLE_RESERVATION_REQUEST_OPTIONCONF_MODAL =
  "IDLE_RESERVATION_REQUEST_OPTIONCONF_MODAL";
export const SET_RESERVATION_REQUEST_OPTIONCONF_MODAL_INFO =
  "SET_RESERVATION_REQUEST_OPTIONCONF_MODAL_INFO";
export const SAVE_RESERVATION_REQUEST_OPTIONCONF_DATA =
  "SAVE_RESERVATION_REQUEST_OPTIONCONF_DATA";
export const SHOW_RESERVATION_REQUEST_VOUCHER_DOWNLOAD_MODAL =
  "SHOW_RESERVATION_REQUEST_VOUCHER_DOWNLOAD_MODAL";
export const HIDE_RESERVATION_REQUEST_VOUCHER_DOWNLOAD_MODAL =
  "HIDE_RESERVATION_REQUEST_VOUCHER_DOWNLOAD_MODAL";

// ======================= PAYMENT CONFIRMATION ======================
export const SHOW_RESERVATION_REQUEST_OPTIONCONF_PAYMENT_MODAL =
  "SHOW_RESERVATION_REQUEST_OPTIONCONF_PAYMENT_MODAL";
export const HIDE_RESERVATION_REQUEST_OPTIONCONF_PAYMENT_MODAL =
  "HIDE_RESERVATION_REQUEST_OPTIONCONF_PAYMENT_MODAL";
export const LOADING_RESERVATION_REQUEST_OPTIONCONF_PAYMENT_MODAL =
  "LOADING_RESERVATION_REQUEST_OPTIONCONF_PAYMENT_MODAL";
export const IDLE_RESERVATION_REQUEST_OPTIONCONF_PAYMENT_MODAL =
  "IDLE_RESERVATION_REQUEST_OPTIONCONF_PAYMENT_MODAL";

// =========================== EDIT STATUS ===========================
export const RESERVATION_EDIT_STATUS_MODAL_SHOW =
  "RESERVATION_EDIT_STATUS_MODAL_SHOW";
export const RESERVATION_EDIT_STATUS_MODAL_HIDE =
  "RESERVATION_EDIT_STATUS_MODAL_HIDE";
export const RESERVATION_EDIT_STATUS_MODAL_LOADING =
  "RESERVATION_EDIT_STATUS_MODAL_LOADING";
export const RESERVATION_EDIT_STATUS_MODAL_IDLE =
  "RESERVATION_EDIT_STATUS_MODAL_IDLE";
export const RESERVATION_EDIT_STATUS_MODAL_CHANGE =
  "RESERVATION_EDIT_STATUS_MODAL_CHANGE";

export const RESERVATION_EVENTS_MODAL_SHOW = "RESERVATION_EVENTS_MODAL_SHOW";
export const RESERVATION_EVENTS_MODAL_HIDE = "RESERVATION_EVENTS_MODAL_HIDE";
