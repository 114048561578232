// ======================== BOOKING INFO MODAL =======================

export const TRF_RESERVATION_SHOW_BOOKING_INFO_MODAL =
  "TRF_RESERVATION_SHOW_BOOKING_INFO_MODAL";
export const TRF_RESERVATION_HIDE_BOOKING_INFO_MODAL =
  "TRF_RESERVATION_HIDE_BOOKING_INFO_MODAL";
export const TRF_RESERVATION_LOADING_BOOKING_INFO_MODAL =
  "TRF_RESERVATION_LOADING_BOOKING_INFO_MODAL";

//============================ LIST VIEW ===========================================
// export const TRF_RESERVATION_FETCH_DATA = "TRF_RESERVATION_FETCH_DATA";
// export const TRF_RESERVATION_BEGIN_FETCH_DATA =
//   "TRF_RESERVATION_BEGIN_FETCH_DATA";
// export const TRF_RESERVATION_END_FETCH_DATA = "TRF_RESERVATION_END_FETCH_DATA";
// export const TRF_RESERVATION_FETCH_OPTIONS = "TRF_RESERVATION_FETCH_OPTIONS";

// ========================= FILTER RELATION =========================
// export const TRF_RESERVATION_INITIALIZE_LIST_FILTER_FORM =
//   "TRF_RESERVATION_INITIALIZE_LIST_FILTER_FORM";
// export const TRF_RESERVATION_UPDATE_LIST_FILTER_FORM =
//   "TRF_RESERVATION_UPDATE_LIST_FILTER_FORM";
// export const TRF_RESERVATION_APPLY_LIST_FILTER =
//   "TRF_RESERVATION_APPLY_LIST_FILTER";
// export const TRF_RESERVATION_RESET_LIST_FILTER_FORM =
//   "TRF_RESERVATION_RESET_LIST_FILTER_FORM";

// ========================= COLUMNS RELATION ========================
// export const TRF_RESERVATION_UPDATE_LIST_COLUMNS_FORM =
//   "TRF_RESERVATION_UPDATE_LIST_COLUMNS_FORM";
// export const TRF_RESERVATION_APPLY_LIST_COLUMNS =
//   "TRF_RESERVATION_APPLY_LIST_COLUMNS";
// export const TRF_RESERVATION_INITIALIZE_LIST_COLUMNS_FORM =
//   "TRF_RESERVATION_INITIALIZE_LIST_COLUMNS_FORM";

// =========================== DETAIL VIEW ===========================
export const TRF_RESERVATION_INIT = "TRF_RESERVATION_INIT";
export const TRF_RESERVATION_FETCH_DETAIL_VIEW =
  "TRF_RESERVATION_FETCH_DETAIL_VIEW";
export const TRF_RESERVATION_DATA = "TRF_RESERVATION_DATA";

//========================DASHBOARD===============================
export const TRF_RESERVATION_VIEW_TYPE = "TRF_RESERVATION_VIEW_TYPE";
export const TRF_RESERVATION_FETCH_GRAPH_DATA =
  "TRF_RESERVATION_FETCH_GRAPH_DATA";
export const TRF_RESERVATION_BEGIN_FETCH_GRAPH_DATA =
  "TRF_RESERVATION_BEGIN_FETCH_GRAPH_DATA";
export const TRF_RESERVATION_END_FETCH_GRAPH_DATA =
  "TRF_RESERVATION_END_FETCH_GRAPH_DATA";
export const TRF_RESERVATION_INIT_GRAPH_TYPE =
  "TRF_RESERVATION_INIT_GRAPH_TYPE";

//=========================NEW====================================
export const B2B_TRANSFERS_RESERVATION_LIST_INIT =
  "B2B_TRANSFERS_RESERVATION_LIST_INIT";
export const B2B_TRANSFERS_RESET_LIST_FILTER_FORM =
  "B2B_TRANSFERS_RESET_LIST_FILTER_FORM";
export const B2B_TRANSFERS_LIST_FILTER_CHANGE =
  "B2B_TRANSFERS_LIST_FILTER_CHANGE";
