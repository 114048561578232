import { connect } from "react-redux";
import React from "react";
import ControlPanel from "../Components/ControlPanel";
import MktSectionsPanel from "./MktSectionsPanel";

class MktFinalizeTopController extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="MktFinalizeTopController">
        <ControlPanel />
        <MktSectionsPanel />
      </div>
    );
  }
}

export default connect(null, null)(MktFinalizeTopController);
