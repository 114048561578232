import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";
import { Modal, IconButton, Icon, Input } from "rsuite";
import PoiAutoComplete from "@src/pages/Project/TripPlan/Components/PoiAutoComplete";
import {
  hideCustomMPModal,
  setCustomMP,
} from "@src/actions/Operation/CustomServices/AddOn";
import update from "immutability-helper";
import _ from "lodash";

class MeetingPointPOIModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      point: {
        brief_description: "",
      },
    };
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleApply = this.handleApply.bind(this);
  }
  handleDescriptionChange(value) {
    this.setState((p) =>
      update(p, { point: { brief_description: { $set: value } } })
    );
  }
  handleApply() {
    const { point } = this.state;
    const { groupIdx, onApply } = this.props;

    if (!_.get(point, "geodata")) {
      alert("not enough");
    }

    onApply(groupIdx, point);
  }
  render() {
    const { point } = this.state;
    const { show, lang, onHide } = this.props;

    if (!show) {
      return null;
    }

    return (
      <Modal
        className="CustomModal ModalCentered MeetingPointPOIModal"
        show={show}
        onHide={onHide}>
        <Modal.Header>
          <Modal.Title>Custom Meeting Point</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="MeetingPointPOIModal__filter">
            <span>
              <strong>POI</strong>
            </span>
            <PoiAutoComplete
              geodataDetailsOnly={true}
              lang={lang}
              onSelect={(value) => {
                this.setState((p) => ({
                  ...p,
                  point: {
                    ...p.point,
                    ...value,
                    selected: false,
                    type: "custom",
                  },
                }));
              }}
            />
          </div>
          <div className="MeetingPointPOIModal__filter">
            <span>
              <strong>Brief Description</strong>
            </span>
            <Input
              value={point.brief_description}
              onChange={(value) => this.handleDescriptionChange(value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <IconButton
            icon={<Icon icon="close" />}
            color="blue"
            onClick={onHide}>
            Cancel
          </IconButton>
          <IconButton
            icon={<Icon icon="check" />}
            color="green"
            onClick={this.handleApply}>
            Apply
          </IconButton>
        </Modal.Footer>
      </Modal>
    );
  }
}

MeetingPointPOIModal.defaultProps = {
  groupIdx: 0,
};

MeetingPointPOIModal.propTypes = {
  groupIdx: PropTypes.number,
  show: PropTypes.bool.isRequired,
  lang: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { show, groupIdx } = state.customMeetingPointPOIModal;

  return {
    show,
    groupIdx,
    lang: state.setLang,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onHide: () => dispatch(hideCustomMPModal()),
    onApply: (groupIdx, point) => dispatch(setCustomMP(groupIdx, point)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MeetingPointPOIModal);
