// import enCommon from "./common/en";
import { ___commonDictionary } from "@src/i18n/common/dictionary";
import { ___commonListHeaderDictionary } from "@src/components/common/dictionary";
import { ___commonQuickFiltersDict } from "@src/components/quickfilters/dictionary";
import {
  ___downLoadPDFBtnDictionary,
  ___exportExcelBtnDictionary,
} from "@src/components/common/buttons/dictionary";
import {
  ___controlStripDictionary,
  ___listPageHeaderDictionary,
  ___orderingDictionary,
} from "@src/components/common/lists/dictionary";
import {
  ___commonModals__uploadDocumentModal__dict,
  ___commonModals__ConfirmationtModal__dict,
  ___commonModals__ExportExcelModal__dict,
  ___commonModals__ExportPDFModal__dict,
} from "@src/components/common/modals/dictionary";
// Project
import { ___myTripListDictionary } from "@src/pages/Project/TripPlan/Archive/TripTemplates/dictionary";
import {
  ___projectPckgsCmtsDictionary,
  ___projectPckgsCmtsCarouselDictionary,
} from "@src/pages/Project/Packages/components/dictionary";
import { ___myOffersRejectedDictionary } from "@src/pages/Project/TripPlan/Archive/MyOffers/Rejected/dictionary";
import { ___myOffersAcceptedDictionary } from "@src/pages/Project/TripPlan/Archive/MyOffers/Accepted/dictionary";
import {
  ___myOffersBookedDictionary,
  ___myOffersBookedListDictionary,
} from "@src/pages/Project/TripPlan/Archive/MyOffers/Booked/dictionary";
import { ___myOffersComponentsSentOfferDictionary } from "@src/pages/Project/TripPlan/Archive/MyOffers/components/dictionary";
import {
  ___myOffersInProgressUnsentDictionary,
  ___myOffersInProgressUnsentListDictionary,
} from "@src/pages/Project/TripPlan/Archive/MyOffers/InProgress/dictionary";
import {
  ___myOffersSentDictionary,
  ___myOffersSentQuickFiltersDictionary,
} from "@src/pages/Project/TripPlan/Archive/MyOffers/Sent/dictionary";
import {
  ___QuotationRequest_ItinerarySetup__dict,
  ___QuotationRequest_index__dict,
  ___QuotationRequest_ItinStpDest__dict,
  ___QuotationRequest_Overview_dict,
  ___QuotationRequest_SaveAsModal_dict,
  ___QuotationRequest_SrvReqDestination_dict,
  ___QuotationRequest_SrvReqLeg_dict,
  ___QuotationRequest_TopCtrlr_dict,
  ___QuotationRequest_TopCtrlrReqSrvs_dict,
} from "@src/pages/Project/QuotationRequest/dictionary";
import { ___QuotationUnsentDict } from "@src/pages/Project/QuotationRequest/unsent/dictionary";
import { ___WholesalesDict } from "@src/pages/Project/Packages/Wholesales/dictionary";
// Admin
import {
  ___AdminCommon_ProformaDict,
  ___AdminCommon_DepositStatDict,
} from "@src/pages/Admin/common/dictionary";
import { ___UsersStatsModalStatsDictionary } from "@src/pages/Admin/Company/Users/list/CustomUsersChartsModal/dictionary";
import {
  ___invoiceSetupDltModalDictionary,
  ___invoiceSetupAddEditModalDictionary,
  ___invoiceSetupListDictionary,
} from "@src/pages/Admin/ControlPanel/InvoiceSetup/dictionary";
import {
  ___companyStampAddEditModalDictionary,
  ___companyStampDltModalDictionary,
  ___companyStampListDictionary,
} from "@src/pages/Admin/ControlPanel/CompanyStamps/dictionary";
import {
  ___companyProfileDictionary,
  ___companyProfileServCategDict,
  ___companyProfileServCategModalDict,
  ___companyProfileNatOfBusinessDict,
  ___companyProfileNatOfBusinessModalDict,
  ___companyProfileBasicDataModalDict,
  ___companyProfileBasicDataDict,
} from "@src/pages/Admin/Company/Profile/NewCompanyProfile/dictionary";
import {
  ___usersListsDictionary,
  ___usersAddModalDictionary,
} from "@src/pages/Admin/Company/Users/list/dictionary";
import {
  ___subagentModalStatsDictionary,
  ___subagentModalStatsFiltersDictionary,
  ___subagentModalStatsGroupDictionary,
  ___subagentModalStatsTagDictionary,
  ___subagentModalStatsUsersDictionary,
} from "@src/pages/Network/Sales/SubAgents/list/CustomSubagentChartsModal/dictionary";
import {
  ___usersDetailsDictionary,
  ___usersDetChangePassDict,
  ___usersDetJobDescrDict,
  ___usersDetJobDescrModalDict,
  ___usersDetPermissionsDict,
  ___usersDetPermissionsModalDict,
  ___usersDetProfileDict,
  ___usersDetProfileModalDict,
  ___usersDetServCategDict,
  ___usersDetServCategModalDict,
  ___usersDetWorkDutiesDict,
  ___usersDetWorkDutiesModalDict,
  ___usersUploadImgModalDict,
} from "@src/pages/Admin/Company/Users/details/dictionary";
import {
  ___communicationsListDictionary,
  ___communicationsModalDictionary,
} from "@src/pages/Admin/ControlPanel/Communications/list/dictionary";
import { ___financialInvoice__listDictionary } from "@src/pages/Admin/Financial/Invoice/list/dictionary";
import {
  ___financialInvoice__dtlDictionary,
  ___financialInvoice__gstDictionary,
  ___financialInvoice__itemsDictionary,
  ___financialInvoice__editModalDictionary,
} from "@src/pages/Admin/Financial/Invoice/details/dictionary";
import {
  ___PTAC_ListDictionary,
  ___PTAC_ModalDictionary,
  ___PTAC_CloneModalDictionary,
} from "@src/pages/Admin/ControlPanel/ProductTermsAndConditions/dictionary";
import {
  ___bookingRemarksListDictionary,
  ___bookingRemarksModalDictionary,
} from "@src/pages/Admin/ControlPanel/BookingRemarks/dictionary";
import {
  ___B2BPoliciesModalDictionary,
  ___B2BPoliciesModal_BI_Dictionary,
  ___B2BPoliciesModal_NAS_Dictionary,
  ___B2BPoliciesModal_TS_Dictionary,
  ___B2BPoliciesModal_helpers_Dictionary,
  ___B2BPoliciesModal_TE_Dictionary,
} from "@src/pages/Admin/MarketPolicy/common/list/AddEditPolicyModal/dictionary";
import { ___B2BPolicies_ListDictionary } from "@src/pages/Admin/MarketPolicy/common/list/dictionary";

// Operation
import { ___IssueReservationModal__dict } from "@src/pages/Operation/common/IssueReservationModal/dictionary";
import {
  ___statsDictionary,
  ___statsBarChartDictionary,
  ___stats_TimeChartDictionary,
  ___statsPieChartDictionary,
} from "@src/pages/Operation/B2BReservations/SingleServices/Common/StatsBox/dictionary";
import { ___reservationsEditReservationModalDict } from "@src/pages/Operation/common/EditReservationModal/dictionary";
import {
  ___multiservicesDetail,
  ___multiservicesDetailPrimary,
  ___multiservicesDetailSrvTable,
} from "@src/pages/Operation/B2BReservations/MultiServices/details/dictionary";

import {
  ___statsModalDictionary,
  ___statsModalBarChart1Dictionary,
  ___statsModalCreatePieChart1Dictionary,
  ___statsModalCreateTimeChart1Dictionary,
} from "@src/pages/Operation/B2BReservations/SingleServices/Common/StatsModal/dictionary";
import {
  ___B2BStatsModalDictionary,
  ___B2BStatsACCDictionary,
  ___B2BStatsACTDictionary,
  ___B2BStatsTRFDictionary,
  ___B2BStatsADHACCDictionary,
  ___B2BStatsCUACCDictionary,
  ___B2BStats_MIDictionary,
  ___B2BStats_FLDictionary,
} from "@src/pages/Operation/B2BReservations/SingleServices/Common/StatsModal/DashboardModal/dictionary";

import { ___travelDocModalDict } from "@src/pages/Operation/common/TravelDocModal/dictionary";
import {
  ___operationGuestsDict,
  ___operationGuestsTableDict,
  ___operationGuestsModalDict,
} from "@src/pages/Operation/common/GuestsSection/dictionary";
import {
  ___reservationPaymentLinksSection,
  ___cancelPaymentLinkModal__dict,
  ___createPaymentLinkModal__dict,
} from "@src/pages/Operation/components/ReservationPaymentLinks/dictionary";
import { ___operationsCommonDictionary } from "@src/pages/Operation/B2BReservations/SingleServices/Common/dictionary";
import {
  ___operationResSrvSpecifications,
  ___operationResSrvCxlModal,
} from "@src/pages/Operation/Reservations/Accommodations/dictionary";
import {
  ___operationCommunicationDict,
  ___operationCommunicationTableDict,
} from "@src/pages/Operation/common/CommunicationSection/dictionary";
import { ___OperationStatementTabDictionary } from "@src/pages/Operation/common/StatementTab/dictionary";
import {
  ___operationsRsrvtEvents,
  ___operationsPricing,
  ___operationsPricingModal,
} from "@src/pages/Operation/components/dictionary";
import { ___operationShared } from "@src/pages/Operation/Reservations/Shared/dictionary";
import { ___geopolicyB2BListsDictionary } from "@src/pages/Admin/MarketPolicy/B2C/list/dictionary";
import { ___multiservicesListsDictionary } from "@src/pages/Operation/B2BReservations/MultiServices/list/dictionary";
import { ___accListsDictionary } from "@src/pages/Operation/B2BReservations/SingleServices/Accommodation/list/dictionary";
import {
  ___B2CAccDetailsDictionary,
  ___B2CAccDetailsResPrimaryDict,
} from "@src/pages/Operation/B2CReservations/SingleServices/Accommodation/details/dictionary";
import {
  ___accDetail,
  ___accDetail_primary,
} from "@src/pages/Operation/B2BReservations/SingleServices/Accommodation/detail/dictionary";
import { ___actListsDictionary } from "@src/pages/Operation/B2BReservations/SingleServices/Activities/list/dictionary";
import {
  ___actDetail,
  ___actDetailPrimary,
  ___actDetailSrvSpecification,
} from "@src/pages/Operation/B2BReservations/SingleServices/Activities/details/dictionary";
import { ___miListsDictionary } from "@src/pages/Operation/B2BReservations/SingleServices/Addons/list/dictionary";
import {
  ___miDetail,
  ___miDetailEvents,
  ___miDetailPrimary,
  ___miDetailPrEditStatus,
  ___miDetailPrReqOptConfModal,
  ___miDetailPrReqOptConfPayModal,
} from "@src/pages/Operation/B2BReservations/SingleServices/Addons/details/dictionary";
import { ___adhocAccListsDictionary } from "@src/pages/Operation/B2BReservations/SingleServices/AdhocAccommodations/list/dictionary";
import {
  ___adhocDetails,
  ___adhocDetailsEventFBM,
  ___adhocDetailsEvent,
  ___adhocDetailsPrim,
  ___adhocDetailsServSpec,
} from "@src/pages/Operation/B2BReservations/SingleServices/AdhocAccommodations/detail/dictionary";
import { ___carRentalListsDictionary } from "@src/pages/Operation/B2BReservations/SingleServices/CarRental/list/dictionary";
import { ___coaListsDictionary } from "@src/pages/Operation/B2BReservations/SingleServices/Coach/list/dictionary";
import {
  ___CarRenDetailsDictionary,
  ___CarRenDetails_PrimaryDict,
  ___CarRenDetails_ServSpecifDict,
} from "@src/pages/Operation/B2BReservations/SingleServices/CarRental/detail/dictionary";
import {
  ___coaDetailsDictionary,
  ___coaDetailsPrimaryDictionary,
  ___coaDetailsSrvSpecificationDictionary,
} from "@src/pages/Operation/B2BReservations/SingleServices/Coach/detail/dictionary";
import { ___contractedAccListsDictionary } from "@src/pages/Operation/B2BReservations/SingleServices/ContractedAccommodations/list/dictionary";
import {
  ___contractedAccDictionary,
  ___contractedAccEventsFeedbackDictionary,
  ___contractedAccEventsDictionary,
  ___contractedAccPrimaryDictionary,
  ___contractedAccSrvSpecificationsDictionary,
} from "@src/pages/Operation/B2BReservations/SingleServices/ContractedAccommodations/detail/dictionary";
import { ___cooListsDictionary } from "@src/pages/Operation/B2BReservations/SingleServices/Coordinators/list/dictionary";
import {
  ___CooDetailsDictionary,
  ___CooDetailsEventsDictionary,
  ___CooDetailsPrimaryDictionary,
  ___CooDetailsSrvSpecificationsDictionary,
} from "@src/pages/Operation/B2BReservations/SingleServices/Coordinators/detail/dictionary";
import { ___ferListsDictionary } from "@src/pages/Operation/B2BReservations/SingleServices/Ferries/list/dictionary";
import { ___apiFerListsDictionary } from "@src/pages/Operation/B2BReservations/SingleServices/APIFerries/list/dictionary";
import { ___apiTraListsDictionary } from "@src/pages/Operation/B2BReservations/SingleServices/APITrains/list/dictionary";
import {
  ___ferDetailsDictionary,
  ___ferDetailsEventsFMDictionary,
  ___ferDetailsPrimaryDictionary,
  ___ferDetailsSrvSpecificationsDictionary,
} from "@src/pages/Operation/B2BReservations/SingleServices/Ferries/detail/dictionary";
import {
  ___APIFerDetailsDictionary,
  ___APIFerDetailsEventsFMDictionary,
  ___APIFerDetailsPrimaryDictionary,
  ___APIFerDetailsSrvSpecificationsDictionary,
} from "@src/pages/Operation/B2BReservations/SingleServices/APIFerries/detail/dictionary";
import {
  ___APITraDetailsDictionary,
  ___APITraDetailsEventsFMDictionary,
  ___APITraDetailsPrimaryDictionary,
  ___APITraDetailsSrvSpecificationsDictionary,
} from "@src/pages/Operation/B2BReservations/SingleServices/APITrains/detail/dictionary";
import { ___flightListsdictionary } from "@src/pages/Operation/B2BReservations/SingleServices/Flights/list/dictionary";
import {
  ___flDetailsDictionary,
  ___flDetailsResPrimaryDict,
  ___flDetailsServSpecDict,
  ___flDetailsServSpecLBDict,
} from "@src/pages/Operation/B2CReservations/SingleServices/Flights/details/dictionary";
import { ___genListsDictionary } from "@src/pages/Operation/B2BReservations/SingleServices/GeneralServices/list/dictionary";
import {
  ___GSDetailsDictionary,
  ___GSDetailsEventsModalDict,
  ___GSDetailsResPrimaryDict,
  ___GSDetailsResServSpecDict,
} from "@src/pages/Operation/B2BReservations/SingleServices/GeneralServices/detail/dictionary";
import { ___resListsDictionary } from "@src/pages/Operation/B2BReservations/SingleServices/Restaurants/list/dictionary";
import {
  ___ResDetailsDictionary,
  ___ResDetailsEventsModalDictionary,
  ___ResDetailsResPrimaryDictionary,
  ___ResDetailsResServSpecDictionary,
} from "@src/pages/Operation/B2BReservations/SingleServices/Restaurants/detail/dictionary";
import { ___traListsDictionary } from "@src/pages/Operation/B2BReservations/SingleServices/Trains/list/dictionary";
import {
  ___TRDetailsDictionary,
  ___TRDetailsEventsModalDict,
  ___TRDetailsResPrimaryDict,
  ___TRDetailsResServSpecDict,
} from "@src/pages/Operation/B2BReservations/SingleServices/Trains/detail/dictionary";
import { ___trfListsDictionary } from "@src/pages/Operation/B2BReservations/SingleServices/Transfers/list/dictionary";
import {
  ___trfDetailsDictionary,
  ___trfDetailsPrimaryDictionary,
  ___trfDetailsPrimarySSDictionary,
} from "@src/pages/Operation/B2BReservations/SingleServices/Transfers/details/dictionary";
import {
  ___dashHdnBtnDictionary,
  ___flightReservationStatusDictionary,
  ___reservationBookingModeDictionary,
  ___reservationStatusDictionary,
  ___translatedTableHeadDictionary,
  ___commonModeTagsDictionary,
  ___commonOperatorHelpers,
} from "@src/pages/Operation/common/dictionary";
import {
  ___GuestsB2cListsDictionary,
  ___GuestsAddModalDictionary,
} from "@src/pages/Operation/Guests/B2C/list/dictionary";
import {
  ___GuestsB2cDetailDictionary,
  ___GuestsB2cDetailEditGuestModal,
  ___GuestsB2cDetailBlockGuestModal,
  ___GuestsB2cDetailChangePassModal,
} from "@src/pages/Operation/Guests/B2C/detail/dictionary";
import {
  ___GuestsB2cDetSubscriptionInfoDictionary,
  ___GuestsB2cDetCancelSubscriptionDictionary,
  ___GuestsB2cDetCreateSubscriptionDictionary,
} from "@src/pages/Operation/Guests/B2C/detail/subscription_info/dictionary";
import {
  ___GuestsB2cDetFinInfPaymentMethDictionary,
  ___GuestsB2cDetFinInfSetDefPaymentMethDictionary,
  ___GuestsB2cDetFinInfDeletePaymentMethModalDictionary,
  ___GuestsB2cDetFinInfCreateChargeModalDict,
  ___GuestB2cDetFinInfBillingHistoryDict,
  ___GuestB2cDetFinInfAddEditPayMethModDict,
} from "@src/pages/Operation/Guests/B2C/detail/financial_info/dictionary";
import {
  ___GuestB2cDetFinInfDepInfDict,
  ___GuestB2cDetFinInfDepInfCurTableDict,
  ___GuestB2cDetFinInfDepInfGCRMEntryModalDict,
  ___GuestB2cDetFinInfDepInfGCRMEntryEditModalDict,
} from "@src/pages/Operation/Guests/B2C/detail/financial_info/DepositInfo/dictionary";
import {
  ___commonReceivableAddModal,
  ___commonReceivableList,
  ___commonReceivableVoidModal,
} from "@src/pages/Operation/components/ReservationReceivable/dictionary";

// Newtwork
import {
  ___DocumentTabDict,
  ___DocumentTabDictModal,
} from "@src/pages/Network/Common/DocumentTab/dictionary";
import {
  ___network_components_Dictionary,
  ___WebCMSStatusTagDictionary,
  ___ConfirmationModalDictionary,
  ___ImageModalDictionary,
  ___TranslateButton_Dictionary,
} from "@src/pages/Network/B2CDistributionChannels/detail/components/dictionary";
import { ___branchesList } from "@src/pages/Network/Sales/Branches/list/dictionary";
import { ___subAgentsList } from "@src/pages/Network/Sales/SubAgents/list/dictionary";
import { ___subagentApproveModalDictionary } from "@src/pages/Network/Sales/SubAgents/ApproveModal/dictionary";
import { ___independentAgentsList } from "@src/pages/Network/Sales/IndependentAgents/list/dictionary";
import {
  ___providersListDictionary,
  ___providersModalDictionary,
} from "@src/pages/Network/Supply/Suppliers/dictionary";
import { ___providerProfileDictionary } from "@src/pages/Network/Supply/Suppliers/NewProfile/dictionary";
import { ___networkCommonMemberDictionary } from "@src/pages/Network/System/common/CommonMemberCardInfo/dictionary";
import {
  ___myConnectionsMembersListDictionary,
  ___myConnectionsMembersCardDictionary,
  ___myConnectionsMembersAboutUsModalDictionary,
} from "@src/pages/Network/System/MyConnections/list/dictionary";
import {
  ___membersListSendInvitationsDictionary,
  ___membersSendInvitationsViewInviteDictionary,
} from "@src/pages/Network/System/InvitationsSend/list/dictionary";
import {
  ___membersCardRecievedInvitationsDictionary,
  ___viewRecievedInvitationsModalDictionary,
} from "@src/pages/Network/System/Invitations/NewInvitations/dictionary";
import {
  ___networkVendorsActivityList,
  ___networkVendorsActivityModal,
} from "@src/pages/Network/Vendors/Activity/list/dictionary";
import { ___networkACTVendorDetail } from "@src/pages/Network/Vendors/Activity/detail/dictionary";
import {
  ___networkVendorsFerryList,
  ___networkVendorsFerryModal,
} from "@src/pages/Network/Vendors/Ferry/list/dictionary";
import { ___networkFERVendorDetail } from "@src/pages/Network/Vendors/Ferry/detail/dictionary";
import {
  ___networkVendorsRestaurantList,
  ___networkVendorsRestaurantModal,
} from "@src/pages/Network/Vendors/Restaurant/list/dictionary";
import {
  ___networkVendorsFlightslist,
  ___networkVendorsFlightsModal,
  ___networkVendorsFlightsVLTBModal,
} from "@src/pages/Network/Vendors/Transportation/Flights/list/dictionary";
import { ___networkFLVendorDetail } from "@src/pages/Network/Vendors/Transportation/Flights/detail/dictionary";
import {
  ___networkVendorsAcclist,
  ___networkVendorsAccModal,
} from "@src/pages/Network/Vendors/Accommodation/list/dictionary";
import { ___networkACCVendorDetail } from "@src/pages/Network/Vendors/Accommodation/detail/dictionary";
import {
  ___networkVendorsCarList,
  ___networkVendorsCarModal,
} from "@src/pages/Network/Vendors/CarRental/list/dictionary";
import { ___networkCARVendorDetail } from "@src/pages/Network/Vendors/CarRental/detail/dictionary";
import {
  ___networkVendorsCommonHooks,
  ___networkVendorsCommonHelpers,
  ___networkVendorsCommonErrors,
  ___networkVendorsContactDetailsSection,
  ___networkVendorsCommonNotificationSec,
  ___networkVendorsCommonDetailPage,
  ___networkVendorsCommonPaymentTermsTab,
} from "@src/pages/Network/Vendors/common/dictionary";
import {
  ___networkVendorsTFlist,
  ___networkVendorsTFModal,
} from "@src/pages/Network/Vendors/Transfer/list/dictionary";
import { ___networkTRFVendorDetail } from "@src/pages/Network/Vendors/Transfer/detail/dictionary";
import {
  ___networkVendorsTrainList,
  ___networkVendorsTrainModal,
} from "@src/pages/Network/Vendors/Train/list/dictionary";
import { ___networkTRAVendorDetail } from "@src/pages/Network/Vendors/Train/detail/dictionary";
import { ___networkB2CDistributionlist } from "@src/pages/Network/B2CDistributionChannels/list/dictionary";
import {
  ___networkB2CDistributionDetails,
  ___netowrkB2CDistEditModal_dictionary,
} from "@src/pages/Network/B2CDistributionChannels/detail/dictionary";
import {
  ___networkB2CDistributionDetailsAboutUs,
  ___networkB2CDistributionDetailsAboutUsList,
  ___networkB2CDistributionDetailsAboutUsModal,
} from "@src/pages/Network/B2CDistributionChannels/detail/AboutUs/dictionary";
import {
  ___networkB2CDistDetContactInfoModal,
  ___networkB2CDistDetContactInfoList,
  ___networkB2CDistDetContactInfo,
} from "@src/pages/Network/B2CDistributionChannels/detail/ContactInfo/dictionary";
import {
  ___networkB2CDistDetFAQ,
  ___networkB2CDistDetFAQModal,
} from "@src/pages/Network/B2CDistributionChannels/detail/FAQ/dictionary";
import { ___networkB2CDistDetGenSec } from "@src/pages/Network/B2CDistributionChannels/detail/GenericSections/dictionary";
import {
  ___networkB2CDistDetHotelPrior,
  ___networkB2CDistDetHotelPriorImpModal,
  ___networkB2CDistDetHotelPriorConfModal,
} from "@src/pages/Network/B2CDistributionChannels/detail/HotelPriorities/dictionary";
import {
  ___networkB2CDistDetLandPages,
  ___networkB2CDistDetLandPagModal,
} from "@src/pages/Network/B2CDistributionChannels/detail/LandingPages/dictionary";
import {
  ___networkB2CDistDetListPages,
  ___networkB2CDistDetListPagEditModal,
  ___networkB2CDistDetListPagList,
} from "@src/pages/Network/B2CDistributionChannels/detail/ListPages/dictionary";
import {
  ___networkB2CDistDetMainPromos,
  ___networkB2CDistDetMainPromosEM,
  ___networkB2CDistDetMainPromosList,
} from "@src/pages/Network/B2CDistributionChannels/detail/MainPromos/dictionary";
import {
  ___networkB2CDistDetPromoLandMod,
  ___networkB2CDistDetPromoLand,
} from "@src/pages/Network/B2CDistributionChannels/detail/PromoLandingPage/dictionary";
import {
  ___networkB2CDistDetRest,
  ___networkB2CDistDetRestMod,
  ___networkB2CDistDetRestList,
} from "@src/pages/Network/B2CDistributionChannels/detail/RestaurantPromos/dictionary";

import {
  ___membersListDictionary,
  ___membersCardDictionary,
  ___membersConnectModalDictionary,
  ___membersAboutUsModalDictionary,
} from "@src/pages/Network/System/Members/list/dictionary";

// My Library
import {
  ___myLibraryAddonsList,
  ___myLibraryAddonTitle,
} from "@src/pages/MyLibrary/Addons/dictionary";

import {
  ___myLibraryHotelsList,
  ___myLibraryHotelsAddModal,
  ___myLibraryHotelsEditModal,
  ___myLibraryHotelsdetail,
} from "@src/pages/MyLibrary/Hotels/dictionary";
import {
  ___myLibraryHotelsCmpsARM,
  ___myLibraryHotelsCmpsBI,
  ___myLibraryHotelsCmpsCalendars,
  ___myLibraryHotelsCmpsERM,
  ___myLibraryHotelsCmps,
  ___myLibraryHotelsCmpsRPC,
  ___myLibraryHotelsCmpsrooms,
} from "@src/pages/MyLibrary/Hotels/components/dictionary";
import {
  ___myLibraryHotelsCmpsCalACa,
  ___myLibraryHotelsCmpsCalACo,
  ___myLibraryHotelsCmpsCalCntrls,
  ___myLibraryHotelsCmpsCalRAF,
  ___myLibraryHotelsCmpsCalRRF,
  ___myLibraryHotelsCmpsCalSSC,
  ___myLibraryHotelsCmpsCalSSCs,
  ___myLibraryHotelsCmpsCalSSCntrls,
} from "@src/pages/MyLibrary/Hotels/components/calendars/dictionary";
import {
  ___myLibraryResList,
  ___myLibraryResAddRes,
  ___myLibraryResAddMM,
  ___myLibraryResdetail,
  ___myLibraryResEditBIModal,
  ___myLibraryResRemMenuModal,
  ___myLibraryResPriceTool,
  ___myLibraryResMenus,
  ___myLibraryResLocation,
  ___myLibraryResEditMenuModal,
} from "@src/pages/MyLibrary/Restaurants/dictionary";
import {
  ___myLibraryCooList,
  ___myLibraryCooAddModal,
} from "@src/pages/MyLibrary/Coordinators/dictionary";
import {
  ___myLibraryGenList,
  ___myLibraryGenServiceModal,
} from "@src/pages/MyLibrary/GeneralServices/dictionary";
import {
  ___myLibraryImgList,
  ___myLibraryImgPanel,
  ___myLibraryImgModal,
} from "@src/pages/MyLibrary/ImageBank/dictionary";

// Manage Console
import {
  ___PaymentMethodsList_dictionary,
  ___AddMethodModal_dictionary,
  ___SetDefaultMethodModal_dictionary,
  ___DeleteMethodModal_dictionary,
} from "@src/pages/ManagementConsole/Billing/PaymentMethods/dictionary";
import { ___BillingAndPaymentsDict } from "@src/pages/ManagementConsole/Billing/BillAndPayments/dictionary";
// User Profile
import {
  ___userProfileDictionary,
  ___userProfileChangePassDict,
  ___userProfileJobDescrDict,
  ___userProfileJobDescrModalDict,
  ___userProfilePermissionsDict,
  ___userProfilePermissionsModalDict,
  ___userProfileProfileDict,
  ___userProfileProfileModalDict,
  ___userProfileServCategDict,
  ___userProfileServCategModalDict,
  ___userProfileWorkDutiesDict,
  ___userProfileWorkDutiesModalDict,
  ___userProfileUploadImgModalDict,
} from "@src/pages/UserProfile/New/dictionary";

const langDictionaries = [
  // Common
  ___commonDictionary,
  ___commonListHeaderDictionary,
  ___commonQuickFiltersDict,
  // enCommon,
  // Project
  ___myTripListDictionary,
  ___projectPckgsCmtsDictionary,
  ___myOffersRejectedDictionary,
  ___myOffersAcceptedDictionary,
  ___myOffersBookedDictionary,
  ___myOffersBookedListDictionary,
  ___projectPckgsCmtsCarouselDictionary,
  ___myOffersInProgressUnsentDictionary,
  ___myOffersInProgressUnsentListDictionary,
  ___myOffersComponentsSentOfferDictionary,
  ___myOffersSentDictionary,
  ___myOffersSentQuickFiltersDictionary,
  ___QuotationRequest_ItinerarySetup__dict,
  ___QuotationRequest_index__dict,
  ___QuotationRequest_ItinStpDest__dict,
  ___QuotationRequest_Overview_dict,
  ___QuotationRequest_SaveAsModal_dict,
  ___QuotationRequest_SrvReqDestination_dict,
  ___QuotationRequest_SrvReqLeg_dict,
  ___QuotationRequest_TopCtrlr_dict,
  ___QuotationRequest_TopCtrlrReqSrvs_dict,
  ___QuotationUnsentDict,
  ___WholesalesDict,
  // Operations
  ___IssueReservationModal__dict,
  ___statsDictionary,
  ___statsBarChartDictionary,
  ___stats_TimeChartDictionary,
  ___statsPieChartDictionary,
  ___reservationsEditReservationModalDict,
  ___travelDocModalDict,
  ___operationResSrvSpecifications,
  ___operationsCommonDictionary,
  ___operationsRsrvtEvents,
  ___operationsPricing,
  ___operationsPricingModal,
  ___operationResSrvCxlModal,
  ___flightListsdictionary,
  ___flDetailsDictionary,
  ___flDetailsResPrimaryDict,
  ___flDetailsServSpecDict,
  ___flDetailsServSpecLBDict,
  ___controlStripDictionary,
  ___statsModalDictionary,
  ___statsModalBarChart1Dictionary,
  ___statsModalCreatePieChart1Dictionary,
  ___statsModalCreateTimeChart1Dictionary,
  ___B2BStatsModalDictionary,
  ___B2BStatsACCDictionary,
  ___B2BStatsACTDictionary,
  ___B2BStatsTRFDictionary,
  ___B2BStatsADHACCDictionary,
  ___B2BStatsCUACCDictionary,
  ___B2BStats_MIDictionary,
  ___B2BStats_FLDictionary,
  ___operationGuestsDict,
  ___operationGuestsTableDict,
  ___operationGuestsModalDict,
  ___reservationPaymentLinksSection,
  ___cancelPaymentLinkModal__dict,
  ___createPaymentLinkModal__dict,
  ___operationCommunicationDict,
  ___operationCommunicationTableDict,
  ___OperationStatementTabDictionary,
  ___operationShared,
  ___orderingDictionary,
  ___commonModals__uploadDocumentModal__dict,
  ___commonModals__ConfirmationtModal__dict,
  ___commonModals__ExportExcelModal__dict,
  ___commonModals__ExportPDFModal__dict,
  ___dashHdnBtnDictionary,
  ___downLoadPDFBtnDictionary,
  ___exportExcelBtnDictionary,
  ___translatedTableHeadDictionary,
  ___commonModeTagsDictionary,
  ___commonOperatorHelpers,
  ___reservationStatusDictionary,
  ___flightReservationStatusDictionary,
  ___reservationBookingModeDictionary,
  ___listPageHeaderDictionary,
  ___accListsDictionary,
  ___B2CAccDetailsDictionary,
  ___B2CAccDetailsResPrimaryDict,
  ___accDetail,
  ___accDetail_primary,
  ___actListsDictionary,
  ___actDetail,
  ___actDetailPrimary,
  ___actDetailSrvSpecification,
  ___miListsDictionary,
  ___miDetail,
  ___miDetailEvents,
  ___miDetailPrimary,
  ___miDetailPrEditStatus,
  ___miDetailPrReqOptConfModal,
  ___miDetailPrReqOptConfPayModal,
  ___adhocAccListsDictionary,
  ___adhocDetails,
  ___adhocDetailsEventFBM,
  ___adhocDetailsEvent,
  ___adhocDetailsPrim,
  ___adhocDetailsServSpec,
  ___carRentalListsDictionary,
  ___coaListsDictionary,
  ___CarRenDetailsDictionary,
  ___CarRenDetails_PrimaryDict,
  ___CarRenDetails_ServSpecifDict,
  ___coaDetailsDictionary,
  ___coaDetailsPrimaryDictionary,
  ___coaDetailsSrvSpecificationDictionary,
  ___contractedAccListsDictionary,
  ___contractedAccDictionary,
  ___contractedAccEventsFeedbackDictionary,
  ___contractedAccEventsDictionary,
  ___contractedAccPrimaryDictionary,
  ___contractedAccSrvSpecificationsDictionary,
  ___cooListsDictionary,
  ___CooDetailsDictionary,
  ___CooDetailsEventsDictionary,
  ___CooDetailsPrimaryDictionary,
  ___CooDetailsSrvSpecificationsDictionary,
  ___ferListsDictionary,
  ___apiFerListsDictionary,
  ___apiTraListsDictionary,
  ___ferDetailsDictionary,
  ___ferDetailsEventsFMDictionary,
  ___ferDetailsPrimaryDictionary,
  ___ferDetailsSrvSpecificationsDictionary,
  ___APIFerDetailsDictionary,
  ___APIFerDetailsEventsFMDictionary,
  ___APIFerDetailsPrimaryDictionary,
  ___APIFerDetailsSrvSpecificationsDictionary,
  ___APITraDetailsDictionary,
  ___APITraDetailsEventsFMDictionary,
  ___APITraDetailsPrimaryDictionary,
  ___APITraDetailsSrvSpecificationsDictionary,
  ___genListsDictionary,
  ___GSDetailsDictionary,
  ___GSDetailsEventsModalDict,
  ___GSDetailsResPrimaryDict,
  ___GSDetailsResServSpecDict,
  ___resListsDictionary,
  ___ResDetailsDictionary,
  ___ResDetailsEventsModalDictionary,
  ___ResDetailsResPrimaryDictionary,
  ___ResDetailsResServSpecDictionary,
  ___traListsDictionary,
  ___TRDetailsDictionary,
  ___TRDetailsEventsModalDict,
  ___TRDetailsResPrimaryDict,
  ___TRDetailsResServSpecDict,
  ___trfListsDictionary,
  ___trfDetailsDictionary,
  ___trfDetailsPrimaryDictionary,
  ___trfDetailsPrimarySSDictionary,
  ___multiservicesListsDictionary,
  ___multiservicesDetail,
  ___multiservicesDetailPrimary,
  ___multiservicesDetailSrvTable,
  ___GuestsB2cListsDictionary,
  ___GuestsAddModalDictionary,
  ___GuestsB2cDetailDictionary,
  ___GuestsB2cDetailEditGuestModal,
  ___GuestsB2cDetailBlockGuestModal,
  ___GuestsB2cDetailChangePassModal,
  ___GuestsB2cDetSubscriptionInfoDictionary,
  ___GuestsB2cDetCancelSubscriptionDictionary,
  ___GuestsB2cDetCreateSubscriptionDictionary,
  ___GuestsB2cDetFinInfPaymentMethDictionary,
  ___GuestsB2cDetFinInfSetDefPaymentMethDictionary,
  ___GuestsB2cDetFinInfDeletePaymentMethModalDictionary,
  ___GuestsB2cDetFinInfCreateChargeModalDict,
  ___GuestB2cDetFinInfBillingHistoryDict,
  ___GuestB2cDetFinInfAddEditPayMethModDict,
  ___GuestB2cDetFinInfDepInfDict,
  ___GuestB2cDetFinInfDepInfCurTableDict,
  ___GuestB2cDetFinInfDepInfGCRMEntryModalDict,
  ___GuestB2cDetFinInfDepInfGCRMEntryEditModalDict,
  ___commonReceivableAddModal,
  ___commonReceivableList,
  ___commonReceivableVoidModal,
  // Admin
  ___companyStampAddEditModalDictionary,
  ___companyStampDltModalDictionary,
  ___companyStampListDictionary,
  ___AdminCommon_ProformaDict,
  ___AdminCommon_DepositStatDict,
  ___UsersStatsModalStatsDictionary,
  ___invoiceSetupDltModalDictionary,
  ___invoiceSetupAddEditModalDictionary,
  ___invoiceSetupListDictionary,
  ___companyProfileDictionary,
  ___companyProfileServCategDict,
  ___companyProfileServCategModalDict,
  ___companyProfileNatOfBusinessDict,
  ___companyProfileNatOfBusinessModalDict,
  ___companyProfileBasicDataModalDict,
  ___companyProfileBasicDataDict,
  ___financialInvoice__listDictionary,
  ___financialInvoice__dtlDictionary,
  ___financialInvoice__gstDictionary,
  ___financialInvoice__itemsDictionary,
  ___financialInvoice__editModalDictionary,
  ___usersListsDictionary,
  ___usersAddModalDictionary,
  ___subagentModalStatsDictionary,
  ___subagentModalStatsFiltersDictionary,
  ___subagentModalStatsGroupDictionary,
  ___subagentModalStatsTagDictionary,
  ___subagentModalStatsUsersDictionary,
  ___usersDetailsDictionary,
  ___usersDetChangePassDict,
  ___usersDetJobDescrDict,
  ___usersDetJobDescrModalDict,
  ___usersDetPermissionsDict,
  ___usersDetPermissionsModalDict,
  ___usersDetProfileDict,
  ___usersDetProfileModalDict,
  ___usersDetServCategDict,
  ___usersDetServCategModalDict,
  ___usersDetWorkDutiesDict,
  ___usersDetWorkDutiesModalDict,
  ___usersUploadImgModalDict,
  ___geopolicyB2BListsDictionary,
  ___communicationsListDictionary,
  ___communicationsModalDictionary,
  ___PTAC_ListDictionary,
  ___PTAC_ModalDictionary,
  ___PTAC_CloneModalDictionary,
  ___bookingRemarksListDictionary,
  ___bookingRemarksModalDictionary,
  ___B2BPolicies_ListDictionary,
  ___B2BPoliciesModal_NAS_Dictionary,
  ___B2BPoliciesModal_BI_Dictionary,
  ___B2BPoliciesModal_TS_Dictionary,
  ___B2BPoliciesModal_helpers_Dictionary,
  ___B2BPoliciesModal_TE_Dictionary,
  // Network
  ___DocumentTabDict,
  ___DocumentTabDictModal,
  ___branchesList,
  ___subAgentsList,
  ___subagentApproveModalDictionary,
  ___independentAgentsList,
  ___network_components_Dictionary,
  ___WebCMSStatusTagDictionary,
  ___ConfirmationModalDictionary,
  ___ImageModalDictionary,
  ___TranslateButton_Dictionary,
  ___B2BPoliciesModalDictionary,
  ___providersListDictionary,
  ___providersModalDictionary,
  ___providerProfileDictionary,
  ___networkVendorsActivityList,
  ___networkVendorsActivityModal,
  ___networkACTVendorDetail,
  ___networkVendorsFerryList,
  ___networkVendorsFerryModal,
  ___networkFERVendorDetail,
  ___networkVendorsRestaurantList,
  ___networkVendorsRestaurantModal,
  ___networkVendorsFlightslist,
  ___networkVendorsFlightsModal,
  ___networkVendorsFlightsVLTBModal,
  ___networkFLVendorDetail,
  ___networkVendorsAcclist,
  ___networkVendorsCommonHooks,
  ___networkVendorsCommonHelpers,
  ___networkVendorsCommonErrors,
  ___networkVendorsContactDetailsSection,
  ___networkVendorsCommonNotificationSec,
  ___networkVendorsCommonDetailPage,
  ___networkVendorsCommonPaymentTermsTab,
  ___networkVendorsAccModal,
  ___networkACCVendorDetail,
  ___networkVendorsCarList,
  ___networkVendorsCarModal,
  ___networkCARVendorDetail,
  ___networkVendorsTFlist,
  ___networkTRFVendorDetail,
  ___networkVendorsTFModal,
  ___networkVendorsTrainList,
  ___networkVendorsTrainModal,
  ___networkTRAVendorDetail,
  ___networkB2CDistributionlist,
  ___networkB2CDistributionDetails,
  ___netowrkB2CDistEditModal_dictionary,
  ___networkB2CDistributionDetailsAboutUs,
  ___networkB2CDistributionDetailsAboutUsList,
  ___networkB2CDistributionDetailsAboutUsModal,
  ___networkB2CDistDetContactInfoModal,
  ___networkB2CDistDetContactInfoList,
  ___networkB2CDistDetContactInfo,
  ___networkB2CDistDetFAQ,
  ___networkB2CDistDetFAQModal,
  ___networkB2CDistDetGenSec,
  ___networkB2CDistDetHotelPrior,
  ___networkB2CDistDetHotelPriorImpModal,
  ___networkB2CDistDetHotelPriorConfModal,
  ___networkB2CDistDetLandPages,
  ___networkB2CDistDetLandPagModal,
  ___networkB2CDistDetListPages,
  ___networkB2CDistDetListPagEditModal,
  ___networkB2CDistDetListPagList,
  ___networkB2CDistDetMainPromos,
  ___networkB2CDistDetMainPromosEM,
  ___networkB2CDistDetMainPromosList,
  ___networkB2CDistDetPromoLandMod,
  ___networkB2CDistDetPromoLand,
  ___networkB2CDistDetRest,
  ___networkB2CDistDetRestMod,
  ___networkB2CDistDetRestList,
  ___membersListDictionary,
  ___membersCardDictionary,
  ___membersConnectModalDictionary,
  ___membersAboutUsModalDictionary,
  ___networkCommonMemberDictionary,
  ___myConnectionsMembersListDictionary,
  ___myConnectionsMembersCardDictionary,
  ___myConnectionsMembersAboutUsModalDictionary,
  ___membersCardRecievedInvitationsDictionary,
  ___viewRecievedInvitationsModalDictionary,
  ___membersListSendInvitationsDictionary,
  ___membersSendInvitationsViewInviteDictionary,
  // MyLibrary
  ___myLibraryAddonsList,
  ___myLibraryAddonTitle,
  ___myLibraryHotelsList,
  ___myLibraryHotelsAddModal,
  ___myLibraryHotelsEditModal,
  ___myLibraryHotelsdetail,
  ___myLibraryHotelsCmpsARM,
  ___myLibraryHotelsCmpsBI,
  ___myLibraryHotelsCmpsCalendars,
  ___myLibraryHotelsCmpsERM,
  ___myLibraryHotelsCmps,
  ___myLibraryHotelsCmpsRPC,
  ___myLibraryHotelsCmpsrooms,
  ___myLibraryHotelsCmpsCalACa,
  ___myLibraryHotelsCmpsCalACo,
  ___myLibraryHotelsCmpsCalCntrls,
  ___myLibraryHotelsCmpsCalRAF,
  ___myLibraryHotelsCmpsCalRRF,
  ___myLibraryHotelsCmpsCalSSC,
  ___myLibraryHotelsCmpsCalSSCs,
  ___myLibraryHotelsCmpsCalSSCntrls,
  ___myLibraryResList,
  ___myLibraryResAddRes,
  ___myLibraryResAddMM,
  ___myLibraryResdetail,
  ___myLibraryResEditBIModal,
  ___myLibraryResRemMenuModal,
  ___myLibraryResPriceTool,
  ___myLibraryResMenus,
  ___myLibraryResLocation,
  ___myLibraryResEditMenuModal,
  ___myLibraryCooList,
  ___myLibraryCooAddModal,
  ___myLibraryGenList,
  ___myLibraryGenServiceModal,
  ___myLibraryImgList,
  ___myLibraryImgPanel,
  ___myLibraryImgModal,
  // Manage Console
  ___PaymentMethodsList_dictionary,
  ___AddMethodModal_dictionary,
  ___SetDefaultMethodModal_dictionary,
  ___DeleteMethodModal_dictionary,
  ___BillingAndPaymentsDict,
  // My Profile
  ___userProfileDictionary,
  ___userProfileChangePassDict,
  ___userProfileJobDescrDict,
  ___userProfileJobDescrModalDict,
  ___userProfilePermissionsDict,
  ___userProfilePermissionsModalDict,
  ___userProfileProfileDict,
  ___userProfileProfileModalDict,
  ___userProfileServCategDict,
  ___userProfileServCategModalDict,
  ___userProfileWorkDutiesDict,
  ___userProfileWorkDutiesModalDict,
  ___userProfileUploadImgModalDict,
];

export default langDictionaries;
