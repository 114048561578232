import _ from "lodash";
import React from "react";
import axios from "axios";
import { getAuthHeaders } from "../../../request";
import { SISI_API_URL } from "@src/api";
import { toast } from "react-toastify";

export async function fetchGenSrvReservation(params) {
  const auth_headers = getAuthHeaders();
  return await axios.get(`${SISI_API_URL}/reservations/general-services`, {
    params,
    headers: auth_headers,
  });
}

export async function retrieveGenSrvReservation({ reference }) {
  const auth_headers = getAuthHeaders();
  return await axios.get(
    `${SISI_API_URL}/reservations/general-services/${reference}/`,
    { headers: auth_headers }
  );
}

export async function updateGenSrvReservation({ reference, payload }) {
  const auth_headers = getAuthHeaders();
  return await axios.patch(
    `${SISI_API_URL}/reservations/general-services/${reference}/`,
    payload,
    { headers: auth_headers }
  );
}

export async function retrievePublicGenSrvReservation({
  reference,
  onError = (error) =>
    toast.error(
      <p>
        We failed to retrieve the General Service Reservation. We are sorry for
        the inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    ),
}) {
  const auth_headers = getAuthHeaders();
  try {
    const result = await axios.get(
      `${SISI_API_URL}/reservations/general-services/public/${reference}/`,
      { headers: auth_headers }
    );
    return result.data;
  } catch (error) {
    if (typeof onError === "function") {
      onError(error);
    }

    return null;
  }
}

export async function patchPublicGenSrvReservation({
  reference,
  payload,
  onError = (error) =>
    toast.error(
      <p>
        We failed to update the General Service Reservation. We are sorry for
        the inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    ),
}) {
  try {
    const result = await axios.patch(
      `${SISI_API_URL}/reservations/general-services/public/update/${reference}/`,
      payload
    );
    return result.data;
  } catch (error) {
    if (typeof onError === "function") {
      onError(error);
    }
    return null;
  }
}

export async function getGeneralServiceStats(params) {
  const auth_headers = getAuthHeaders();
  return await axios.get(
    `${SISI_API_URL}/reservations/general-services/stats/`,
    {
      params,
      headers: { ...auth_headers },
    }
  );
}
