import {
  FINANCIAL_SET_CURRENCIES,
  FINANCIAL_SET_CURRENCY,
  FINANCIAL_UPDATING_CURRENCY_INFO,
  FINANCIAL_UPDATED_CURRENCY_INFO,
  FINANCIAL_UPDATE_CURRENCY_RATE,
} from "./types";

import { Notification } from "rsuite";

import { getCurrenciesInfo, getForexData } from "@src/api";
import { DateTime } from "luxon";
import { getUserTopParentEntitySelector } from "@src/selectors/Shared/user_selectors";

const conversion_date = DateTime.now().toISODate();

const setLSCurrency = (currency) => {
  window.localStorage.setItem(window.btoa("currency"), currency);
};

const getLSCurrency = () => {
  return window.localStorage.getItem(window.btoa("currency"));
};

export const fetchCurrencies = () => async (dispatch) => {
  try {
    const currencies = await getCurrenciesInfo();

    const currency = getLSCurrency();
    if (currency) {
      await dispatch({
        type: FINANCIAL_SET_CURRENCIES,
        currencies: currencies.results,
      });

      if (currency !== "EUR") {
        dispatch(selectCurrency(currency));
      }
    } else {
      dispatch({
        type: FINANCIAL_SET_CURRENCIES,
        currencies: currencies.results,
        selectedCurrency: "EUR",
      });
    }
  } catch (error) {
    error.ovrdErrHandle && error.ovrdErrHandle();
    console.log(error);
    // pass
  }
};

export const selectCurrency = (notation) => async (dispatch, getState) => {
  const state = getState();
  const currencies = state.financialAvailableCurrencies;
  const top_parent_entity = getUserTopParentEntitySelector(state);
  const globalCurrency = currencies.find((c) => c.is_global);
  const currentCurrency = currencies.find((c) => c.selected);

  dispatch({ type: FINANCIAL_UPDATING_CURRENCY_INFO });

  try {
    const forexData = await getForexData(
      globalCurrency.notation,
      notation,
      conversion_date,
      top_parent_entity
    );

    dispatch({
      type: FINANCIAL_SET_CURRENCY,
      notation,
      rate: forexData.rates[notation],
      globalCurrency,
      currentCurrency,
    });
    setLSCurrency(notation);
  } catch (error) {
    error.ovrdErrHandle && error.ovrdErrHandle();
    Notification.error({
      title: "Currency Change Failure",
      description:
        "We could not get the exchange rate for your requested currency. Please try again later. We are sorry for the inconvenience!",
      duration: 6000,
    });
    return;
  } finally {
    dispatch({ type: FINANCIAL_UPDATED_CURRENCY_INFO });
  }
};

export const updateCurrencyRate = (notation) => async (dispatch, getState) => {
  const state = getState();
  const currencies = state.financialAvailableCurrencies;
  const top_parent_entity = getUserTopParentEntitySelector(state);
  const globalCurrency = currencies.find((c) => c.is_global);

  const forexData = await getForexData(
    globalCurrency.notation,
    notation,
    conversion_date,
    top_parent_entity
  );

  dispatch({
    type: FINANCIAL_UPDATE_CURRENCY_RATE,
    notation,
    rate: forexData.rates[notation],
  });
};

export const updateCurrencyRates = () => async (dispatch, getState) => {
  const state = getState();
  const currencies = state.financialAvailableCurrencies;
  const top_parent_entity = getUserTopParentEntitySelector(state);
  const globalCurrency = currencies.find((c) => c.is_global);

  const forexData = await getForexData(
    globalCurrency.notation,
    currencies.map((curr) => curr.notation).join(", "),
    conversion_date,
    top_parent_entity
  );

  Object.entries(forexData.rates).forEach((entry) => {
    dispatch({
      type: FINANCIAL_UPDATE_CURRENCY_RATE,
      notation: entry[0],
      rate: entry[1],
    });
  });
};
