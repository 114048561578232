import React from "react";
import { createUseStyles } from "react-jss";
import { GeneralStats } from "../GeneralStats";
import CreatePieChart from "../../ChartComponents/CreatePieChart";
import { variables } from "@src/jsssetup";
import PropTypes from "prop-types";
import TransTxt from "@src/components/common/SxFormatMessage";
import { injectIntl } from "react-intl";
import _ from "lodash";

const salesReportTable = createUseStyles({
  SalesReportTable: {
    display: "grid",
    gridTemplateRows: "max-content",
  },
  statsTable: {
    display: "grid",
    justifyItems: "center",
  },
  tableContainer: {
    display: "grid",
    border: `2px solid #FF6600`,
    borderRadius: "10px",
    textAlign: "center",
  },
  table: {
    width: "100%",
  },
  tHead: {
    backgroundColor: "#FF6600",
  },
  headRow: {
    "background": "#FF6600",
    "& th:first-child": {
      borderTopLeftRadius: "10%",
    },
    "& th:last-child": {
      borderTopRightRadius: "10%",
    },
  },
  headCell: {
    backgroundColor: "#FF6600",
    textAlign: "center",
    color: "white",
    padding: variables.half_gap,
    fontFamily: "futura,sans-serif",
    fontWeight: "400",
  },
  tableCell: {
    textAlign: "center",
    padding: `${variables.half_gap} ${variables.normal_gap}`,
    fontFamily: "futura,sans-serif",
    height: "2rem",
  },
});

const SalesReportTable = ({ data }) => {
  const classes = salesReportTable();

  return (
    <div className={classes.SalesReportTable}>
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead className={classes.tHead}>
            <tr className={classes.headRow}>
              <th className={classes.headCell}>
                {<TransTxt id="B2B_stats_ADHACC_top_sales__count" />}
              </th>
              <th className={classes.headCell}>
                {<TransTxt id="B2B_stats_ADHACC_top_sales__pax_per_booking" />}
              </th>
              <th className={classes.headCell}>
                {
                  <TransTxt id="B2B_stats_ADHACC_top_sales__avg_price_per_room" />
                }
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={classes.tableCell}>
                {_.get(data, "top_sales.count") !== 0
                  ? _.get(data, "top_sales.count") || "N/A"
                  : "0"}
              </td>
              <td className={classes.tableCell}>
                {_.get(data, "top_sales.pax_per_booking") !== 0
                  ? Math.round(_.get(data, "top_sales.pax_per_booking")) ||
                    "N/A"
                  : "0"}
              </td>
              <td className={classes.tableCell}>
                {_.get(data, "top_sales.room_average_price.avg") !== 0
                  ? _.get(data, "top_sales.room_average_price.currency")
                  : ""}{" "}
                {_.get(data, "top_sales.room_average_price.avg") !== 0
                  ? _.get(data, "top_sales.room_average_price.avg") || "N/A"
                  : "0"}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
SalesReportTable.propTypes = {
  data: PropTypes.object.isRequired,
};

const topSales = createUseStyles({
  ADHACCTopSales: {
    display: "grid",
    padding: variables.normal_gap,
  },
  statsTable: {
    display: "grid",
    justifyItems: "center",
  },
  charts: {
    display: "grid",
    gridTemplateColumns: "20fr 1fr 20fr 1fr 20fr",
    justifyItems: "center",
  },
  chart1: {
    display: "grid",
  },
  chart2: {
    display: "grid",
  },
  chart3: {
    display: "grid",
  },
  divider: {
    justifySelf: "center",
    alignSelf: "center",
    height: "85%",
    border: `1px solid rgba(0, 0, 0, 0.1)`,
    gridColumn: "1 span",
    width: "0%",
  },
});

var ADHACCTopSales = ({ intl, data }) => {
  const classes = topSales();

  const topDestinationsData = _.get(
    data,
    "top_sales.top_destinations.items"
  ).map(([name, value]) => ({
    name,
    value,
  }));
  const topDestinationsTotal = topDestinationsData.reduce(
    (dt, hotel) => dt + hotel.value,
    0
  );
  const topDestinationsPercentages = topDestinationsData.map((dest) => ({
    name: dest.name,
    value: ((dest.value / topDestinationsTotal) * 100).toFixed(2),
  }));
  const top5DestinationsColors = [
    "#FF6600",
    "#B174FF",
    "#B8B8B8",
    "#FFB381",
    "#B0754E",
  ];

  const topHotelsData = _.get(data, "top_sales.top_hotels.items").map(
    ([name, value]) => ({
      name,
      value,
    })
  );

  const topHotelsTotal = topHotelsData.reduce(
    (dt, hotel) => dt + hotel.value,
    0
  );
  const topHotelsPercentages = topHotelsData.map((hotel) => ({
    name: hotel.name,
    value: ((hotel.value / topHotelsTotal) * 100).toFixed(2),
  }));

  const top5HotelsColors = [
    "#FF6600",
    "#B174FF",
    "#B8B8B8",
    "#B0754E",
    "#FFB381",
  ];

  const topBookingData = _.get(data, "top_sales.top_booking.items").map(
    ([name, value]) => ({
      name,
      value,
    })
  );
  const topBookingsTotal = topBookingData.reduce(
    (dt, sale) => dt + sale.value,
    0
  );
  const topBookingsPercentages = topHotelsData.map((sale) => ({
    name: sale.name,
    value: ((sale.value / topBookingsTotal) * 100).toFixed(2),
  }));
  const topBookingRatingColors = [
    "#B0754E",
    "#FFB381",
    "#B8B8B8",
    "#B174FF",
    "#FF6600",
  ];

  return (
    <div className={classes.ADHACCTopSales}>
      <div className={classes.stats}>
        <GeneralStats
          title={intl.formatMessage({
            id: "B2B_stats_ADHACC_top_sales__top_sales",
          })}
          tableTitle={intl.formatMessage({
            id: "B2B_stats_ADHACC_top_sales__confirmed_reservations",
          })}
          startDate={_.get(data, "period.start_date")}
          endDate={_.get(data, "period.end_date")}
        />
        <div className={classes.statsTable}>
          <SalesReportTable data={data} />
        </div>
      </div>
      <div className={classes.charts}>
        <div className={classes.chart1}>
          <CreatePieChart
            title={intl.formatMessage({
              id: "B2B_stats_ADHACC_top_sales__top_destinations",
            })}
            bottom={75}
            colors={top5DestinationsColors}
            data={topDestinationsPercentages}
          />
        </div>
        <div className={classes.divider}></div>
        <div className={classes.chart2}>
          <CreatePieChart
            title={intl.formatMessage({
              id: "B2B_stats_ADHACC_top_sales__top_hotels",
            })}
            bottom={75}
            colors={top5HotelsColors}
            data={topHotelsPercentages}
          />
        </div>
        <div className={classes.divider}></div>
        <div className={classes.chart3}>
          <CreatePieChart
            title={intl.formatMessage({
              id: "B2B_stats_ADHACC_top_sales__booking_per_adhocc_rating",
            })}
            bottom={75}
            colors={topBookingRatingColors}
            data={topBookingsPercentages}
          />
        </div>
      </div>
    </div>
  );
};
ADHACCTopSales.propTypes = {
  data: PropTypes.object.isRequired,
  intl: PropTypes.object,
  dashboardPayload: PropTypes.object.isRequired,
};
ADHACCTopSales = injectIntl(ADHACCTopSales);
export default ADHACCTopSales;
