import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { InlineIcon } from "@iconify/react";
import _ from "lodash";
import { variables } from "@src/jsssetup";
import { DateTime, Duration } from "luxon";
import { handleNonValidImgFn } from "@src/tools/common_tools";
import { useServiceCardExpansion } from "@src/pages/hooks";

const carRentalDataStyles = createUseStyles({
  CarRentalData: (props) => ({
    display: "grid",
    gridTemplateColumns: props.services_collapsed ? "1fr" : "8rem 1fr",
    gridTemplateRows: props.services_collapsed ? "unset" : "10rem",
    gridGap: variables.normal_gap,
    maxHeight: "10rem",
  }),
  cardContent: {
    display: "grid",
    padding: variables.normal_gap,
    gridGap: variables.half_gap,
    boxShadow: variables.shadows.heavyShadow,
    borderRadius: `calc(${variables.normal_gap} / 2)`,
    textTransform: "capitalize",
  },
  header: (props) => ({
    display: "grid",
    gridTemplateColumns: props.services_collapsed
      ? "repeat(2,max-content)"
      : "1fr max-content",
    alignItems: "center",
    justifyContent: "space-between",
    gridGap: variables.half_gap,
    padding: props.services_collapsed && [
      variables.half_gap,
      variables.half_gap,
      variables.half_gap,
      variables.double_gap,
    ],
    borderRadius: props.services_collapsed && variables.half_gap,
    boxShadow: props.services_collapsed && variables.shadows.normalShadow,
  }),
  img: {
    width: "100%",
    height: "100%",
    borderRadius: "5px",
    objectFit: "contain",
    padding: ["3rem", 0],
    boxShadow: variables.shadows.heavyShadow,
  },
  metadata: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    alignItems: "center",
    gridGap: variables.half_gap,
  },
  headerTitle: {
    fontSize: "medium",
    fontWeight: "bold",
  },
  title: {
    color: variables.colors.text.colored,
    fontWeight: "600",
  },
  actionBtns: {
    display: "grid",
    gridAutoFlow: "column",
    gridGap: variables.half_gap,
  },
  expandIconCntr: {
    display: "grid",
    placeContent: "center",
    width: "1.5rem",
    height: "1.5rem",
    borderRadius: "50%",
    background: variables.colors.easy.orange,
    padding: variables.half_gap,
    cursor: "pointer",
  },
  expandIcon: {
    fontSize: "large",
    color: variables.colors.text.light,
  },
  maxPaxContr: {
    display: "grid",
    gridTemplateColumns: "repeat(2,max-content)",
    gridGap: variables.half_gap,
  },
  maxPax: {
    display: "grid",
    gridTemplateColumns: "repeat(2, max-content)",
    alignItems: "center",
  },
  icon: {
    fontSize: "large",
    color: variables.colors.easy.orange,
  },
});
export const CarRentalData = ({
  car,
  children,
  toggleSelfCollapse,
  services_collapsed,
}) => {
  const classes = carRentalDataStyles({ services_collapsed });
  console.log("car = ", car);

  const pickUp = _.get(car, "pick_up.name", "N/A");
  const dropOff = _.get(car, "drop_off.name", "N/A");
  const fuelType = _.get(car, "vehicle.fuel_type", "N/A");
  const img = _.get(car, "vehicle.image", "");
  const transmission = _.get(car, "vehicle.transmission_type", "N/A");
  const aircondition = _.get(car, "vehicle.has_air_condition", "N/A");
  const vehicle = _.get(car, "vehicle.name", "N/A");
  const provider = _.get(car, "provider.name", "N/A");
  const maxPax = _.get(car, "vehicle.passenger_quantity", "N/A");

  const header = (
    <React.Fragment>
      <div className={classes.headerTitle}>Car Rental: {pickUp}</div>
      <div className={classes.actionBtns}>
        {children}
        <div className={classes.expandIconCntr}>
          <InlineIcon
            className={classes.expandIcon}
            icon={
              services_collapsed ? "ri:arrow-down-s-line" : "ri:arrow-up-s-line"
            }
            onClick={toggleSelfCollapse}
          />
        </div>
      </div>
    </React.Fragment>
  );

  return _.isEmpty(car) ? null : (
    <div className={classes.CarRentalData}>
      {services_collapsed ? (
        <div className={classes.header}>{header}</div>
      ) : (
        <React.Fragment>
          <img
            className={classes.img}
            src={img}
            onError={handleNonValidImgFn}
          />
          <div className={classes.cardContent}>
            <div className={classes.header}>{header}</div>
            <div className={classes.metadata}>
              <span>
                <span className={classes.title}>Pick-up Location:</span>{" "}
                {pickUp}
              </span>
              <span>
                <span className={classes.title}>Vehicle:</span> {vehicle}
              </span>
              <span>
                <span className={classes.title}>Drop-off Location:</span>{" "}
                {dropOff}
              </span>
              <span>
                <span className={classes.title}>Provider:</span> {provider}
              </span>
              <span>
                <span className={classes.title}>Aircondition:</span>{" "}
                {aircondition === "N/A"
                  ? aircondition
                  : aircondition
                  ? "Yes"
                  : "No"}
              </span>
              <span>
                <span className={classes.title}>Fuel Type:</span> {fuelType}
              </span>
              <span>
                <span className={classes.title}>Transmission:</span>{" "}
                {transmission}
              </span>
              <span className={classes.maxPaxContr}>
                <span className={classes.title}>Max Pax:</span>
                <span className={classes.maxPax}>
                  {maxPax}
                  <InlineIcon
                    className={classes.icon}
                    icon="material-symbols:person"
                  />
                </span>
              </span>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
CarRentalData.propTypes = {
  car: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
  services_collapsed: PropTypes.bool.isRequired,
  toggleSelfCollapse: PropTypes.func,
};
