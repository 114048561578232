import { Notification } from "rsuite";
import React from "react";

export const notifyAmountZero = () => {
  Notification.warning({
    title: "Validation Error",
    description: <p>A positive amount is required.</p>,
  });
};

export const notifyFailedToCreatePaymentOrder = () => {
  Notification.error({
    title: "Payment Error",
    description: (
      <p>Failed to create payment order. We are sorry for the inconvenience.</p>
    ),
  });
};
