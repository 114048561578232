import PropTypes from "prop-types";
import React, { useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, IconButton, Icon, Whisper, Popover, Dropdown } from "rsuite";
import { setPaxScalling } from "@src/actions/Operation/CustomServices/AddOn/pax_scalling_actions";

import update from "immutability-helper";
import v4 from "uuid/v4";
import { createUseStyles } from "react-jss";
import { modalGenericStyles, tableStyles, variables } from "@src/jsssetup";
import { Formik, Form } from "formik";
import { NormalInputField, NormalSelectField } from "@src/components/forms";
import * as yup from "yup";

const paxGroupSchema = yup.object().shape({
  uid: yup.string().required("Required").default(v4()),
  min_pax: yup.number().required("Required").min(1),
  max_pax: yup
    .number()
    .required("Required")
    .min(yup.ref("min_pax"), "Must be greater or equal to min pax"),
  min_adults: yup.number().required("Required").min(0),
  max_adults: yup.number().required("Required").min(0),
  max_children: yup.number().required("Required").min(0),
  child_age_from: yup.number().min(0).max(18).nullable(),
  child_age_to: yup.number().min(0).max(18).nullable(),
  count_as_pax: yup.string().required("Required").oneOf(["yes", "no"]),
});
const paxGroupsSchema = yup.object().shape({
  pax_groups: yup.array().of(paxGroupSchema).required().min(1),
});

const RowAction = ({ onAddBelow, onRemove }) => {
  const triggerRef = useRef();

  function handleSelectMenu(eventKey) {
    switch (eventKey) {
      case 1:
        onAddBelow();
        break;
      case 2:
        onRemove();
        break;
    }

    if (triggerRef.current) {
      triggerRef.current.hide();
    }
  }

  return (
    <Whisper
      placement="auto"
      trigger="click"
      triggerRef={triggerRef}
      speaker={
        <Popover full>
          <Dropdown.Menu onSelect={handleSelectMenu}>
            <Dropdown.Item icon={<Icon icon="plus" />} eventKey={1}>
              Add Below
            </Dropdown.Item>
            <Dropdown.Item icon={<Icon icon="minus" />} eventKey={2}>
              Remove
            </Dropdown.Item>
          </Dropdown.Menu>
        </Popover>
      }>
      <IconButton color="blue" icon={<Icon icon="chevron-down" />} size="sm">
        Actions
      </IconButton>
    </Whisper>
  );
};
RowAction.propTypes = {
  onAddBelow: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

const styles = createUseStyles({
  PaxScallingModal: modalGenericStyles.modal,
  card: { ...modalGenericStyles.card, width: "80vw" },
  header: modalGenericStyles.cardHeader,
  body: modalGenericStyles.cardBody,
  actions: modalGenericStyles.cardActions,
  table: tableStyles.table,
  tableHead: tableStyles.head,
  cell: {
    ...tableStyles.cell,
    "textAlign": "center",
    "& input": {
      width: "5rem",
    },
  },
  col2: {
    display: "grid",
    gridTemplateColumns: "repeat(2, max-content)",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    justifyContent: "center",
  },
  cellCol: {
    display: "grid",
    justifyContent: "center",
  },
});
const PaxScallingModal = ({ onClose }) => {
  const { pax_groups } = useSelector((state) => state.customServiceAddOn);

  const dispatch = useDispatch();
  const onApply = useCallback(
    (pax_groups) => dispatch(setPaxScalling(pax_groups)),
    []
  );

  const classes = styles();

  function handleApply({ pax_groups }) {
    onApply(pax_groups);
    onClose();
  }

  function handleAddBelow(pax_groups, idx) {
    return update(pax_groups, {
      $splice: [[idx + 1, 0, { ...pax_groups[idx], uid: v4() }]],
    });
  }

  function handleRemove(pax_groups, idx) {
    return update(pax_groups, {
      $set: pax_groups.filter((g, gidx) => gidx !== idx),
    });
  }

  return (
    <div className={classes.PaxScallingModal}>
      <div className={classes.card}>
        <div className={classes.header}>
          <h5>Pax Scalling</h5>
        </div>
        <Formik
          initialValues={{ pax_groups }}
          validationSchema={paxGroupsSchema}
          onSubmit={(values) => handleApply({ pax_groups: values.pax_groups })}>
          {({ values, setFieldValue }) => {
            return (
              <Form>
                <div className={classes.body}>
                  <table className={classes.table}>
                    <thead className={classes.tableHead}>
                      <tr>
                        <th>Name</th>
                        <th>Pax From</th>
                        <th>Pax To</th>
                        <th>Min Adults</th>
                        <th>Max Adults</th>
                        <th>Max Children</th>
                        <th>Child Age Group</th>
                        <th>Count as Pax</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {values.pax_groups.map((gr, idx) => (
                        <tr key={idx}>
                          <td className={classes.cell}>Pax Group {idx + 1}</td>
                          <td className={classes.cell}>
                            <div className={classes.cellCol}>
                              <NormalInputField
                                name={`pax_groups.${idx}.min_pax`}
                                extraInputProps={{ min: 1 }}
                                type="number"
                              />
                            </div>
                          </td>
                          <td className={classes.cell}>
                            <div className={classes.cellCol}>
                              <NormalInputField
                                name={`pax_groups.${idx}.max_pax`}
                                extraInputProps={{ min: 1 }}
                                type="number"
                              />
                            </div>
                          </td>
                          <td className={classes.cell}>
                            <div className={classes.cellCol}>
                              <NormalInputField
                                name={`pax_groups.${idx}.min_adults`}
                                extraInputProps={{ min: 1 }}
                                type="number"
                              />
                            </div>
                          </td>
                          <td className={classes.cell}>
                            <div className={classes.cellCol}>
                              <NormalInputField
                                name={`pax_groups.${idx}.max_adults`}
                                extraInputProps={{ min: 1 }}
                                type="number"
                              />
                            </div>
                          </td>
                          <td className={classes.cell}>
                            <div className={classes.cellCol}>
                              <NormalInputField
                                name={`pax_groups.${idx}.max_children`}
                                extraInputProps={{ min: 0 }}
                                type="number"
                              />
                            </div>
                          </td>
                          <td className={classes.cell}>
                            <span className={classes.col2}>
                              <NormalInputField
                                name={`pax_groups.${idx}.child_age_from`}
                                extraInputProps={{ min: 0, max: 18 }}
                                type="number"
                              />
                              <NormalInputField
                                name={`pax_groups.${idx}.child_age_to`}
                                extraInputProps={{ min: 0, max: 18 }}
                                type="number"
                              />
                            </span>
                          </td>
                          <td className={classes.cell}>
                            <NormalSelectField
                              name={`pax_groups.${idx}.count_as_pax`}
                              options={[
                                ["", "------"],
                                ["yes", "Yes"],
                                ["no", "No"],
                              ]}
                            />
                          </td>
                          <td className={classes.cell}>
                            <RowAction
                              onAddBelow={() => {
                                setFieldValue(
                                  "pax_groups",
                                  handleAddBelow(values.pax_groups, idx)
                                );
                              }}
                              onRemove={() =>
                                setFieldValue(
                                  "pax_groups",
                                  handleRemove(values.pax_groups, idx)
                                )
                              }
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className={classes.actions}>
                  <IconButton
                    icon={<Icon icon="close" />}
                    color="blue"
                    onClick={onClose}>
                    Cancel
                  </IconButton>
                  <Button color="green" type="submit">
                    Apply
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};
PaxScallingModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default PaxScallingModal;
