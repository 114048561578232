import { SISI_API_URL } from "@src/api";
import { getAuthHeaders } from "@src/api/request";
import axios from "axios";

export async function offerAutocomplete({ offer_title, source_entity }) {
  const headers = getAuthHeaders();
  return await axios.get(`${SISI_API_URL}/crs/trip-offer/autocomplete`, {
    params: { offer_title, source_entity },
    headers,
  });
}
