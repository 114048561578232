//==========================FILTER===============================
export const MY_OFFER__REJECTED_UPDATE_LIST_FILTER_FORM =
  "MY_OFFER__REJECTED_UPDATE_LIST_FILTER_FORM";
export const MY_OFFER__REJECTED_APPLY_LIST_FILTER =
  "MY_OFFER__REJECTED_APPLY_LIST_FILTER";
export const MY_OFFER__REJECTED_INITIALIZE_LIST_FILTER_FORM =
  "MY_OFFER__REJECTED_INITIALIZE_LIST_FILTER_FORM";
export const MY_OFFER__REJECTED_RESET_LIST_FILTER_FORM =
  "MY_OFFER__REJECTED_RESET_LIST_FILTER_FORM";
//==============================LIST========================
export const MY_OFFER__REJECTED_LIST_LOADING =
  "MY_OFFER__REJECTED_LIST_LOADING";
export const MY_OFFER__REJECTED_LIST_IDLE = "MY_OFFER__REJECTED_LIST_IDLE";
export const MY_OFFER__REJECTED_FETCH_DATA = "MY_OFFER__REJECTED_FETCH_DATA";
//========================DASHBOARD===============================
export const MY_OFFER__REJECTED_VIEW_TYPE = "MY_OFFER__REJECTED_VIEW_TYPE";
export const MY_OFFER__REJECTED_FETCH_GRAPH_DATA =
  "MY_OFFER__REJECTED_FETCH_GRAPH_DATA";
export const MY_OFFER__REJECTED_BEGIN_FETCH_GRAPH_DATA =
  " MY_OFFER__REJECTED_BEGIN_FETCH_GRAPH_DATA";
export const MY_OFFER__REJECTED_END_FETCH_GRAPH_DATA =
  "MY_OFFER__REJECTED_END_FETCH_GRAPH_DATA";
export const MY_OFFER__REJECTED_INIT_GRAPH_TYPE =
  "MY_OFFER__REJECTED_INIT_GRAPH_TYPE";
// ========= New Refactored ================
export const MY_OFFER_REJECTED_LIST_GENERIC_CHANGE =
  "MY_OFFER_REJECTED_LIST_GENERIC_CHANGE";
