import { createSelector } from "reselect";
import moment from "moment";
import _ from "lodash";
import { getSelectedAddonData, getAddonSearchStatus } from "./addon_selectors";
import { filtersToServicesSelector } from "./service_filters";
import { getDbDCustomServices } from "./day_by_day/custom_services";
import { getDbDAdhocServices } from "./day_by_day/addhoc_services";
import { DateTime } from "luxon";
import {
  getAccAdhocServices,
  getAccAPIServices,
  getAccCustomServices,
} from "./accommodation";
import {
  getSrvsUidForBooking,
  getSrvsUidForOptionBooking,
} from "./prebook/selected_services";
import { getTripActivitiesListSelector } from "./day_by_day/activities";
const dateCheckFmt = "YYYY-MM-DD";

const getDestinations = (state) => state.tripPlannerDestinations;

const propsInjector = (__, props) => props;

export const getDateForDay = createSelector(
  [getDestinations, propsInjector],
  (destinations, props) => {
    const { day } = props;
    const start = moment.parseZone(destinations[0].checkIn);
    const end = moment.parseZone(_.last(destinations).checkOut);

    const trip_days = new Array(end.diff(start, "days") + 1)
      .fill(1)
      .map((__, idx) =>
        start
          .clone()
          .add(idx + 1, "days")
          .format("YYYY-MM-DD")
      );

    return trip_days[day - 1];
  }
);

const getDestination = (state, props) =>
  state.tripPlannerDestinations.find((dest) => dest.order == props.destOrder);

export const getFlightServices = (state, props = {}) => {
  const { booking_mode, refundable } = props;

  if (refundable === true) return [];

  var restrictedToServices = [];
  if (booking_mode === "regular") {
    restrictedToServices = getSrvsUidForBooking(state, {
      srv_type: "FL",
    });
  } else if (booking_mode === "option") {
    restrictedToServices = getSrvsUidForOptionBooking(state, {
      srv_type: "FL",
    });
  }

  if (restrictedToServices.length === 0 && booking_mode) {
    return [];
  }

  return (state.tripPlannerFlightsReducer?.services ?? []).filter((fl) => {
    if (restrictedToServices.length > 0) {
      return restrictedToServices.map((srv) => srv.uid).includes(fl.uid);
    } else {
      return true;
    }
  });
};

export const getAPITrainServices = (state, props = {}) => {
  const { booking_mode, refundable } = props;

  // TODO: Check this.
  if (refundable === true) return [];

  var restrictedToServices = [];
  if (booking_mode === "regular") {
    restrictedToServices = getSrvsUidForBooking(state, {
      srv_type: "ATRA",
    });
  } else if (booking_mode === "option") {
    restrictedToServices = getSrvsUidForOptionBooking(state, {
      srv_type: "ATRA",
    });
  }

  if (restrictedToServices.length === 0 && booking_mode) {
    return [];
  }

  const srvs = (state.tripPlannerTrainsReducer?.services ?? []).filter(
    (tra) => {
      if (restrictedToServices.length > 0) {
        return restrictedToServices.map((srv) => srv.uid).includes(tra.id);
      } else {
        return true;
      }
    }
  );

  return srvs;
};

export const getAPIFerryServices = (state, props = {}) => {
  const { booking_mode, refundable } = props;

  if (refundable === true) return [];

  var restrictedToServices = [];
  if (booking_mode === "regular") {
    restrictedToServices = getSrvsUidForBooking(state, {
      srv_type: "AFER",
    });
  } else if (booking_mode === "option") {
    restrictedToServices = getSrvsUidForOptionBooking(state, {
      srv_type: "AFER",
    });
  }

  if (restrictedToServices.length === 0 && booking_mode) {
    return [];
  }

  const srvs = (state.tripPlannerFerriesReducer?.services ?? []).filter(
    (fr) => {
      if (restrictedToServices.length > 0) {
        return restrictedToServices.map((srv) => srv.uid).includes(fr.token);
      } else {
        return true;
      }
    }
  );

  return srvs;
};

export const getFerryServices = (state) => {
  const data = _.get(state.tripPlanTrpAddhocServices, "legs", []).filter(
    (srv) => srv.transportation_service_type === "FER"
  );
  return data;
};

export const getTrainServices = (state) => {
  const data = _.get(state.tripPlanTrpAddhocServices, "legs", []).filter(
    (srv) => srv.transportation_service_type === "TRA"
  );
  return data;
};

export const getCoachServices = (state) => {
  const data = _.get(state.tripPlanTrpAddhocServices, "legs", []).filter(
    (srv) => srv.transportation_service_type === "COA"
  );
  return data;
};

export const getTransferServices = (state, props = {}) => {
  var { booking_mode, refundable } = props;

  const data = state.tripPlannerTransferData;
  var restrictedToServices = [];

  if (booking_mode === "regular") {
    restrictedToServices = getSrvsUidForBooking(state, {
      srv_type: "TRF",
    });
  } else if (booking_mode === "option") {
    refundable = true;
    restrictedToServices = getSrvsUidForOptionBooking(state, {
      srv_type: "TRF",
    });
  }

  if (restrictedToServices.length === 0 && booking_mode) {
    return {};
  }

  const newData = {};
  Object.entries(data).forEach(([destOrder, legTypes]) => {
    const segments = {};

    Object.entries(legTypes ?? {}).forEach(([legType, transfers]) => {
      var validSrvs = (transfers ?? []).filter((srv) => {
        if (restrictedToServices.length > 0) {
          return restrictedToServices
            .map((s) => s.uid)
            .includes(srv.booking_id);
        } else {
          return true;
        }
      });
      if (refundable) {
        validSrvs = validSrvs.filter((srv) =>
          srv.cancellation_policies.some((c) => c.type === "refundable")
        );
      } else if (refundable === false) {
        validSrvs = validSrvs.filter((srv) =>
          srv.cancellation_policies.every((c) => c.type !== "refundable")
        );
      }

      segments[legType] = validSrvs;
    });

    newData[destOrder] = segments;
  });
  return newData;
};

export const getAccServicesSelector = createSelector(
  [getAccAPIServices, getAccCustomServices, getAccAdhocServices],
  (accs, customAccs, addhocAccs) => {
    var validAccs = [];
    Object.entries(accs).forEach((entry) => {
      const destOrder = entry[0].toString();

      const addhocAcc = addhocAccs.find(
        (acc) => acc.destOrder.toString() === destOrder
      );
      if (addhocAcc) {
        addhocAcc.accommodation_service_type = "ADD";
        validAccs.push(addhocAcc);
        return;
      }

      const customAcc = customAccs.find(
        (acc) => acc.destOrder.toString() === destOrder
      );
      if (customAcc) {
        customAcc.accommodation_service_type = "CU";
        validAccs.push(customAcc);
        return;
      }
    });

    function validAccsContainsOrd(destOrder) {
      return validAccs
        .map((acc) => acc.destOrder.toString())
        .includes(destOrder.toString());
    }

    validAccs = [
      ...validAccs,
      ...addhocAccs
        .filter((acc) => !validAccsContainsOrd(acc.destOrder))
        .map((acc) => {
          acc.accommodation_service_type = "ADD";
          return acc;
        }),
    ];

    validAccs = [
      ...validAccs,
      ...customAccs
        .filter((acc) => !validAccsContainsOrd(acc.destOrder))
        .map((acc) => {
          acc.accommodation_service_type = "CU";
          return acc;
        }),
    ];

    validAccs = [
      ...validAccs,
      ...Object.entries(accs)
        .filter((entry) => !validAccsContainsOrd(entry[0]))
        .map((entry) => {
          const acc = _.get(entry[1], 0);
          if (!acc) {
            return null;
          }
          acc.accommodation_service_type = "ACC";
          acc.destOrder = entry[0];
          return acc;
        }),
    ];

    return validAccs.filter((acc) => acc);
  }
);

const getDestsForDay = (date, destinations) => {
  return destinations
    .map((d, idx) => ({ ...d, isLast: idx + 1 == destinations.length }))
    .filter(
      (d) =>
        moment
          .parseZone(date)
          .isSameOrAfter(moment.parseZone(d.checkIn), "day") &&
        moment
          .parseZone(date)
          .isSameOrBefore(moment.parseZone(d.checkOut), "day")
    );
};

const getAccsForDests = (destinations, accommodations) => {
  return _.flatten(
    Object.entries(accommodations)
      .filter((e) =>
        destinations.map((dest) => dest.order).includes(parseInt(e[0], 10))
      )
      .map((e) => e[1].map((acc) => ({ ...acc, order: e[0] })))
  ).filter((acc) => acc.selected);
};

const addAccServicesForDate = ({
  date,
  destination,
  accommodations,
  customAccommodations = [],
  adhocAccommodations = [],
}) => {
  const srvs = [];

  var custom_service = false;
  var adhoc_service = false;
  var service = adhocAccommodations.find(
    (a) => a.destOrder === destination.order
  );

  if (service) {
    adhoc_service = true;
  } else {
    service = customAccommodations.find(
      (a) => a.destOrder === destination.order
    );
    if (service) {
      custom_service = true;
    }
  }

  if (!service) {
    service = accommodations.find((a) => a.order == destination.order);
  }

  const srv = {
    srvType: "ACC",
    custom_service,
    adhoc_service,
    service,
    destOrder: destination.order,
    markup: 0,
  };

  const cIn = DateTime.fromISO(destination.checkIn).toISODate();
  const cOut = DateTime.fromISO(destination.checkOut).toISODate();
  const dt = DateTime.fromISO(date).toISODate();

  if (cIn === dt) {
    srv["eventType"] = "checkIn";
  }

  if (cOut === dt) {
    srv["eventType"] = "checkOut";
  }

  if (srv.service) {
    srvs.push(srv);
  }

  return srvs;
};

const getTransfersForDests = (destinations, transfers) => {
  return _.flattenDeep(
    Object.entries(transfers)
      .filter((e) =>
        destinations.map((d) => d.order).includes(parseInt(e[0], 10))
      )
      .map((e) =>
        Object.entries(e[1]).map((ee) =>
          ee[1]
            .filter((tf) => tf.selected)
            .map((tf) => ({
              ...tf,
              leg_type: ee[0],
              destOrder: parseInt(e[0], 10),
            }))
        )
      )
  );
};

const addTrfServicesForDate = (date, destination, transfers) => {
  const srvs = [];
  transfers
    .filter((trf) => !_.isEmpty(trf.inbound))
    .forEach((trf) => {
      if (
        moment.parseZone(trf.inbound.start).format(dateCheckFmt) ==
        moment.parseZone(date).format(dateCheckFmt)
      ) {
        srvs.push({
          srvType: "TRF",
          eventType: "inbound",
          service: trf,
          destOrder: trf.destOrder,
          markup: 0,
        });
      }
    });

  transfers
    .filter((trf) => !_.isEmpty(trf.outbound))
    .forEach((trf) => {
      if (
        moment.parseZone(trf.outbound.start).format(dateCheckFmt) ==
        moment.parseZone(date).format(dateCheckFmt)
      ) {
        srvs.push({
          srvType: "TRF",
          eventType: "outbound",
          service: trf,
          destOrder: trf.destOrder,
          markup: 0,
        });
      }
    });

  return srvs;
};

const addFlServicesForDate = (date, destination, flights, eventType) => {
  const srvs = [];
  flights.forEach((fl) => {
    var flDates = [];
    if (eventType === "departure") {
      flDates = fl?.legs.map((leg) => {
        const selL = leg.find((l) => l.selected);
        return DateTime.fromISO(selL.segments?.[0]?.departure, {
          setZone: true,
        }).toISODate({
          includeOffset: false,
        });
      });
    } else {
      flDates = fl?.legs.map((leg) => {
        const selL = leg.find((l) => l.selected);
        return DateTime.fromISO(_.last(selL.segments)?.arrival, {
          setZone: true,
        }).toISODate({
          includeOffset: false,
        });
      });
    }

    if (flDates.includes(date)) {
      srvs.push({
        srvType: "FL",
        eventType,
        service: fl,
        destOrder: destination.order,
      });
    }
  });

  return srvs;
};

const addAPIFerrySrvsForDate = (date, destination, ferries, eventType) => {
  function getDt(dtstr) {
    return DateTime.fromISO(dtstr, {
      setZone: true,
    }).toISODate({
      includeOffset: false,
    });
  }

  const srvs = [];
  ferries.forEach((fer) => {
    var ferryDates = [];
    if (eventType === "departure") {
      ferryDates = fer?.legs.map((leg) => getDt(leg.departure_datetime));
    } else {
      ferryDates = fer?.legs.map((leg) => getDt(leg.arrival_datetime));
    }

    if (ferryDates.includes(date)) {
      srvs.push({
        srvType: "AFER",
        eventType,
        service: fer,
        destOrder: destination.order,
      });
    }
  });

  return srvs;
};

const addAPITrainsSrvsForDate = (date, destination, trains, eventType) => {
  function getDt(dtstr) {
    return DateTime.fromISO(dtstr, {
      setZone: true,
    }).toISODate({
      includeOffset: false,
    });
  }

  const srvs = [];
  trains.forEach((tra) => {
    var ferryDates = [];
    if (eventType === "departure") {
      ferryDates = getDt(tra.departure);
    } else {
      ferryDates = getDt(tra.arrival);
    }

    if (ferryDates.includes(date)) {
      srvs.push({
        srvType: "ATRA",
        eventType,
        service: tra,
        destOrder: destination.order,
      });
    }
  });

  return srvs;
};

function addFerryServicesForDate({ date, destination, ferries, type }) {
  const services = [];

  const dt = DateTime.fromISO(date, { setZone: true });

  ferries
    .filter((fer) => {
      if (
        destination.order.toString() === fer.destination_order.toString() &&
        type === "arrival"
      ) {
        return true;
      } else if (
        destination.order.toString() === fer.origin_order.toString() &&
        type === "departure"
      ) {
        return true;
      } else {
        return false;
      }
    })
    .filter((fer) => {
      if (type === "departure") {
        const departure = DateTime.fromISO(fer.service.departure, {
          setZone: true,
        });
        return dt.toISODate() === departure.toISODate();
      } else {
        const arrival = DateTime.fromISO(fer.service.arrival, {
          setZone: true,
        });
        return dt.toISODate() === arrival.toISODate();
      }
    })
    .forEach((fer) => {
      services.push({
        srvType: "FER",
        eventType: type,
        service: fer,
        destOrder: destination.order,
      });
    });

  return services;
}

function addTrainServicesForDate({ date, destination, trains, type }) {
  const services = [];

  const dt = DateTime.fromISO(date, { setZone: true });

  trains
    .filter((tra) => {
      if (
        destination.order.toString() === tra.destination_order.toString() &&
        type === "arrival"
      ) {
        return true;
      } else if (
        destination.order.toString() === tra.origin_order.toString() &&
        type === "departure"
      ) {
        return true;
      } else {
        return false;
      }
    })
    .filter((tra) => {
      if (type === "departure") {
        const departure = DateTime.fromISO(tra.service.departure, {
          setZone: true,
        });
        return dt.toISODate() === departure.toISODate();
      } else {
        const arrival = DateTime.fromISO(tra.service.arrival, {
          setZone: true,
        });
        return dt.toISODate() === arrival.toISODate();
      }
    })
    .forEach((tra) => {
      services.push({
        srvType: "TRA",
        eventType: type,
        service: tra,
        destOrder: destination.order,
      });
    });

  return services;
}

function addCoachServicesForDate({ date, destination, coaches, type }) {
  const services = [];

  const dt = DateTime.fromISO(date, { setZone: true });

  coaches
    .filter((coa) => {
      if (
        destination.order.toString() ===
          (coa?.destination_order || "").toString() &&
        type === "arrival"
      ) {
        return true;
      } else if (
        destination.order.toString() === (coa?.origin_order || "").toString() &&
        type === "departure"
      ) {
        return true;
      } else {
        return false;
      }
    })
    .filter((coa) => {
      if (type === "departure") {
        const departure = DateTime.fromISO(coa.service.departure, {
          setZone: true,
        });
        return dt.toISODate() === departure.toISODate();
      } else {
        const arrival = DateTime.fromISO(coa.service.arrival, {
          setZone: true,
        });
        return dt.toISODate() === arrival.toISODate();
      }
    })
    .forEach((coa) => {
      services.push({
        srvType: "COA",
        eventType: type,
        service: coa,
        destOrder: destination.order,
      });
    });

  return services;
}

const getAddOnsForDay = (day, dests, addons, addonsStatus) => {
  return (addons?.[day] ?? []).map((addon) => {
    const status = (addonsStatus?.[day] ?? []).find(
      (status) => status.search_uid == addon.search_uid
    )?.status;

    return {
      srvType: "MI",
      eventType: "addon",
      service: addon,
      destOrder: dests[0].order,
      markup: 0,
      status,
    };
  });
};

const getActivitiesForDate = ({
  activities,
  date,
  destination_id,
  destOrder,
}) => {
  return activities
    .filter((srv) => srv.date === date && srv.destination_id === destination_id)
    .map((srv) => ({
      srvType: "ACT",
      eventType: "activity",
      service: srv,
      destOrder,
      markup: 0,
    }));
};

export function getDaysArray(destinations) {
  const start = DateTime.fromISO(_.first(destinations).checkIn);
  const end = DateTime.fromISO(_.last(destinations).checkOut);

  const days = new Array(
    parseInt(end.diff(start, "days").toObject().days + 1) // To be tested
  ).fill(1);
  return days;
}

export const getDaysArraySelector = createSelector(
  [getDestinations],
  (destinations) => {
    if (!(destinations || []).length) {
      return [];
    }

    const start = DateTime.fromISO(destinations[0].checkIn);
    return getDaysArray(destinations).map((d, idx) => {
      const date = DateTime.fromISO(start, { setZone: true })
        .plus({ days: idx })
        .toISODate();
      return date;
    });
  }
);

export const getTripDaysSelector = createSelector(
  [
    getDestinations,
    getFlightServices,
    getAPITrainServices,
    getAPIFerryServices,
    getTrainServices,
    getFerryServices,
    getCoachServices,
    getAccAPIServices,
    getTransferServices,
    getSelectedAddonData,
    getAddonSearchStatus,
    getDbDCustomServices,
    getDbDAdhocServices,
    getTripActivitiesListSelector,
    getAccCustomServices,
    getAccAdhocServices,
  ],
  (
    destinations,
    flights,
    apitrains,
    apiferries,
    trains,
    ferries,
    coaches,
    accommodations,
    transfers,
    addons,
    addonsStatus,
    customSrvs,
    addhocSrvs,
    activitySrvs,
    customAccs,
    adhocAccs
  ) => {
    if (!(destinations ?? []).length) {
      return [];
    }

    const start = DateTime.fromISO(destinations?.[0]?.checkIn, {
      setZone: true,
    });

    const days = getDaysArray(destinations).map((i, idx) => {
      const date = DateTime.fromISO(start, { setZone: true })
        .plus({ days: idx })
        .toISODate();

      const dests = getDestsForDay(date, destinations);
      const accs = getAccsForDests(dests, accommodations);
      const trfs = getTransfersForDests(dests, transfers);

      const adds = getAddOnsForDay(idx + 1, dests, addons, addonsStatus);
      const dayCustomSrvs = customSrvs?.[idx + 1] ?? [];
      const dayAdhocSrvs = addhocSrvs?.[idx + 1] ?? [];

      var services = [];
      dests.forEach((dest, didx) => {
        const dest_id = `${dest.type}__${dest.id}`;
        services = [
          ...services,
          ...addAccServicesForDate({
            date,
            destination: dest,
            accommodations: accs,
            customAccommodations: customAccs,
            adhocAccommodations: adhocAccs,
          }),
          ...addTrfServicesForDate(date, dest, trfs),
          ...addFlServicesForDate(date, dest, flights, "arrival"),
          ...addFlServicesForDate(date, dest, flights, "departure"),
          ...addAPIFerrySrvsForDate(date, dest, apiferries, "arrival"),
          ...addAPIFerrySrvsForDate(date, dest, apiferries, "departure"),
          ...addAPITrainsSrvsForDate(date, dest, apitrains, "arrival"),
          ...addAPITrainsSrvsForDate(date, dest, apitrains, "departure"),
          ...addTrainServicesForDate({
            date,
            destination: dest,
            trains,
            type: "departure",
          }),
          ...addFerryServicesForDate({
            date,
            destination: dest,
            ferries,
            type: "departure",
          }),
          ...addCoachServicesForDate({
            date,
            destination: dest,
            coaches,
            type: "departure",
          }),
          ...getActivitiesForDate({
            activities: activitySrvs,
            date,
            destination_id: dest_id,
            destOrder: dest.order,
          }),
          ...adds,
          ...dayCustomSrvs.filter((srv) => {
            if (srv.custom_service_type === "COO") {
              if (
                services
                  .filter((s) => s.custom_service_type === "COO")
                  .find((s) => s?.uid === srv?.uid)
              ) {
                return false;
              }
            }

            return (
              srv.destination_id === dest_id ||
              (srv?.destination_ids ?? []).includes(dest_id)
            );
          }),
        ];

        if (didx === 0) {
          services = [...services, ...dayAdhocSrvs];
        }
      });

      services = _.sortBy(services, (v) => {
        const start_time = v?.booking_metadata?.start_time;
        if (!start_time) {
          return 0;
        }

        return DateTime.fromISO(start_time).toMillis();
      });

      return { date, destinations: dests, services, day: idx + 1 };
    });

    return days;
  }
);

export const getDestDaysSelector = createSelector(
  [getDestinations, getDestination],
  (destinations, destination) => {
    const start = moment.parseZone(destinations[0].checkIn);
    const end = moment.parseZone(_.last(destinations).checkOut);

    const deststart = moment.parseZone(destination.checkIn);
    const destend = moment.parseZone(destination.checkOut);

    const trip_days = new Array(end.diff(start, "days") + 1)
      .fill(1)
      .map((day, idx) =>
        start
          .clone()
          .add(idx + 1, "days")
          .format("YYYY-MM-DD")
      );

    const dest_days = new Array(destend.diff(deststart, "days") + 1)
      .fill(1)
      .map((day, idx) => {
        return [
          idx + 1,
          deststart
            .clone()
            .add(idx + 1, "days")
            .format("YYYY-MM-DD"),
        ];
      });

    return { trip_days, dest_days };
  }
);

export const getAddOnsForDest = createSelector(
  [getSelectedAddonData, getDestinations, getDestination],
  (addons, destinations, destination) => {
    if (!destination) {
      return [];
    }

    const start = DateTime.fromISO(destinations[0].checkIn, { zone: "UTC" });
    const end = DateTime.fromISO(_.last(destinations).checkOut, {
      zone: "UTC",
    });

    const deststart = DateTime.fromISO(destination.checkIn, { zone: "UTC" });
    const destend = DateTime.fromISO(destination.checkOut, { zone: "UTC" });

    const trip_days = new Array(end.diff(start, "days").as("days"))
      .fill(1)
      .map((__, idx) => start.plus({ days: idx + 1 }).toISODate());

    const dest_days = new Array(destend.diff(deststart, "days").as("days"))
      .fill(1)
      .map((__, idx) => {
        const date = deststart.plus({ days: idx + 1 }).toISODate();
        const dateIdx = trip_days.indexOf(date) + 1;

        return [dateIdx, date];
      });

    const destaddons = _.flatten(
      dest_days
        .map((day) =>
          _.get(addons, trip_days.indexOf(day[1]) + 1, []).map((addon) => ({
            srvType: "MI",
            eventType: "addon",
            service: addon,
            destOrder: destination.order,
            markup: 0,
            day: day[0],
          }))
        )
        .filter((addon) => addon)
    );

    return destaddons;
  }
);

export const getAddOnsStatusForDest = createSelector(
  [
    getAddonSearchStatus,
    getDestDaysSelector,
    getDestination,
    filtersToServicesSelector,
  ],
  (addons, destDays, destination, visibleSrvs) => {
    const { trip_days, dest_days } = destDays;

    const destaddons = _.flatten(
      dest_days
        .filter(() => _.get(visibleSrvs, "MI", []).includes(destination.order))
        .map((day) =>
          _.get(addons, trip_days.indexOf(day[1]) + 1, []).map((addon) => ({
            srvType: "MI",
            eventType: "addon",
            service: addon,
            destOrder: destination.order,
            markup: 0,
            day: day[0],
          }))
        )
        .filter((addon) => addon)
    );

    return destaddons;
  }
);

export const getCustomServiceForDestSelector = createSelector(
  [getDbDCustomServices, getDestinations, getDestination],
  (custom_services, destinations, destination) => {
    if (!destination) return [];

    const { type, id } = destination;
    const destId = `${type}__${id}`;
    const start = DateTime.fromISO(destinations[0].checkIn, {
      setZone: true,
    });
    const end = DateTime.fromISO(_.last(destinations).checkOut, {
      setZone: true,
    });

    const deststart = DateTime.fromISO(destination.checkIn, { setZone: true });
    const destend = DateTime.fromISO(destination.checkOut, { setZone: true });

    const trip_days = new Array(
      parseInt(end.diff(start, "days").as("days"), 10) + 1
    )
      .fill(1)
      .map((__, idx) => {
        return start.plus({ days: idx }).toISODate();
      });

    const dest_days = new Array(
      parseInt(destend.diff(deststart, "days").as("days"), 10) + 1
    )
      .fill(1)
      .map((__, idx) => {
        const date = deststart.plus({ days: idx }).toISODate();
        const dateIdx = trip_days.indexOf(date) + 1;
        return [dateIdx, date];
      });

    var destServices = _.flatten(
      dest_days.map((entry) => {
        return _.get(custom_services, entry[0], [])
          .filter((srv) => {
            if (_.get(srv, "destination_ids", []).length) {
              return srv.destination_ids.includes(destId);
            } else if (srv.destination_id) {
              return srv.destination_id === destId;
            }
            return true;
          })
          .map((srv) => ({
            srvType: "CU",
            eventType: "custom_service",
            service: srv,
            destOrder: destination.order,
            markup: 0,
            day: entry[0],
          }));
      })
    );

    return _.sortBy(destServices, (v) => v.service.custom_service_type);
  }
);
