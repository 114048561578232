import { getCompanyTimezone } from "@src/selectors/Shared/company_selectors";
import { selectedBidding } from "@src/selectors/Project/TripPlanner";
import { createSelector } from "reselect";

import _ from "lodash";
import moment from "moment-timezone";

export const getSentRequests = (state) =>
  state.newBiddingList.filter((b) => b.request_status == "SE");

const propsInjector = (state, props) => props;
export const getBidRequestTimeData = createSelector(
  [selectedBidding, getCompanyTimezone, propsInjector],
  (bidding, currentTz, props) => {
    const bid = _.get(props, "bidding") ? props.bidding : bidding;

    const now = moment.tz(currentTz);
    const todayIdx = now.isoWeekday() - 1;
    const nowDateStr = moment().toISOString().split("T")[0];

    const rqMinutes = bid.required_time.hours * 60 + bid.required_time.minutes;

    const minimumResponse = now.clone().add(rqMinutes, "minutes");

    var isNowOperational = (dayIdx, bidder, workingFrom, workingTo) => {
      const weekdays = bidder.weekdays || [];
      return (
        _.get(weekdays, dayIdx, false) &&
        now.isAfter(workingFrom) &&
        now.isBefore(workingTo) &&
        minimumResponse.isBefore(workingTo)
      );
    };

    const getNextOperationDay = (dayIdx, bidder) => {
      const weekdays = bidder.weekdays || [];
      var day =
        _.get(
          weekdays
            .map((w, idx) => (idx > dayIdx ? idx : null))
            .filter((w) => w),
          0,
          null
        ) ||
        _.get(
          weekdays.map((w, idx) => (w ? idx : null)).filter((w) => w),
          0,
          null
        );

      if (!day) {
        return Infinity;
      }

      return day;
    };

    const biddersMinResponseTime = bid.bidder_entities
      .map((b) => {
        const localWorkingFrom = moment.tz(
          `${nowDateStr}T${b.working_time_from}`,
          b.timezone
        );
        const localWorkingTo = moment.tz(
          `${nowDateStr}T${b.working_time_to}`,
          b.timezone
        );

        const currentWorkingFrom = localWorkingFrom.clone().tz(currentTz);
        const currentWorkingTo = localWorkingTo.clone().tz(currentTz);

        var opDayIdx = todayIdx;
        if (
          isNowOperational(opDayIdx, b, currentWorkingFrom, currentWorkingTo)
        ) {
          return rqMinutes;
        }

        const nextOperationDay = getNextOperationDay(opDayIdx, b);

        if (!nextOperationDay) {
          return -1;
        }

        const responseDateTime = now
          .clone()
          .add(Math.abs((nextOperationDay - todayIdx) % 6), "days")
          .hours(currentWorkingFrom.hour())
          .minutes(currentWorkingFrom.minute())
          .add(rqMinutes, "minutes");

        return moment.duration(responseDateTime.diff(now)).as("minutes");
      })
      .filter((m) => m >= 0);

    const minResponseHours = moment
      .duration(
        biddersMinResponseTime.length ? Math.min(...biddersMinResponseTime) : 0,
        "minutes"
      )
      .as("hours")
      .toFixed();

    const maxResponseHours = moment
      .duration(
        biddersMinResponseTime.length ? Math.max(...biddersMinResponseTime) : 0,
        "minutes"
      )
      .as("hours")
      .toFixed();

    const expirationTime = now.clone().add(maxResponseHours, "hours");
    expirationTime.hours(23);
    expirationTime.minutes(59);
    expirationTime.seconds(59);

    return {
      minResponseHours,
      maxResponseHours,
      expirationTime: expirationTime.toISOString(),
    };
  }
);
