import { Icon, Dropdown, Badge, Loader, IconButton } from "rsuite";
import SuIcon from "@src/style/icon/components/SuIcon";

// ============================= ACTIONS =============================
import { changeLang } from "@src/actions/i18n/lang";
import { selectCurrency } from "@src/actions/Financial";
import { variables } from "@src/jsssetup";

import AnnounceKit from "announcekit-react";

import { useHistory, withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";

import langs from "@src/i18n";
import { signOut } from "@src/tools/auth_tools";

import _ from "lodash";
import { connect, useSelector } from "react-redux";
import React, { useContext } from "react";
import PropTypes from "prop-types";

import { createUseStyles } from "react-jss";
import { GlobalModalContext } from "@src/App";
import { useChatNotifier } from "@src/pages/Operation/hooks";
import { VERSION, fetchCompanyProfile } from "@src/api";
import { useQuery } from "@tanstack/react-query";
import { MobileViewContext } from "@src/App";
import TransTxt from "@src/components/common/SxFormatMessage";
import { withAppUserType } from "@src/components/authorization/permissionhocs";
import { toast } from "react-toastify";

const BACKGROUND =
  "https://easytravel-tech.s3.eu-central-1.amazonaws.com/navbar.svg";
export const LOGO =
  "https://easytravel-tech.s3.eu-central-1.amazonaws.com/easyTravel_logo.svg";

export const HelpBtn = ({ url }) => {
  return (
    <IconButton
      className="HelpBtn"
      appearance="primary"
      icon={<Icon icon="question-circle" size="2x" />}
      circle
      onClick={function () {
        toast.warning("New help content comming soon!", { autoClose: 5000 });
      }}
    />
  );
};
HelpBtn.propTypes = {
  url: PropTypes.string.isRequired,
};

/**
 * We created the ChattingSection as a different component to use
 * withAppUserType HOC.
 * @param {object} props
 * @param {object} props.classes
 * @param {function} props.onClick
 * @param {array} props.chatNotifications
 * @param {object} props.intl
 */
var ChattingSection = ({ classes, onClick, chatNotifications, intl }) => {
  return (
    <div className={classes.chatSection} onClick={onClick}>
      <SuIcon icon="icon-Chat" />{" "}
      {chatNotifications.length > 0 ? (
        <Badge className={classes.badge} content={chatNotifications.length}>
          {_.upperCase(intl.formatMessage({ id: "chatting" }))}
        </Badge>
      ) : (
        _.upperCase(intl.formatMessage({ id: "chatting" }))
      )}
    </div>
  );
};
ChattingSection.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  chatNotifications: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired,
};
ChattingSection = withAppUserType(ChattingSection, ["member"]);

const styles = createUseStyles({
  NavBar: (props) => ({
    "display": props.mobileView ? "none" : "grid",
    "gridGap": `calc(${variables.normal_gap} * 2)`,
    "gridTemplateColumns": "1fr repeat(6, max-content)",
    "alignItems": "center",
    "height": "4rem",
    "padding": `0 ${variables.normal_gap}`,
    "backgroundImage": `url("${BACKGROUND}")`,
    "backgroundRepeat": "no-repeat",
    "backgroundSize": "cover",
    "color": "white",
    "boxShadow": variables.shadows.normalShadow,
    "zIndex": 3,
    "& a": { color: "white", fontWeight: "bold" },
  }),
  langFlag: {
    height: "2rem",
    marginRight: variables.half_gap,
    borderRadius: "50%",
  },
  userThumbnail: {
    width: "3rem",
    height: "3rem",
    borderRadius: "50%",
    border: "2px solid white",
    objectFit: "cover",
  },
  chatSection: { cursor: "pointer" },
  badge: (props) => ({
    "& > div": {
      width: `calc(${variables.normal_gap} * (1.5 * ${
        props.chatNotifNum < 3 ? props.chatNotifNum : 2
      }))!important`,
      height: `calc(${variables.normal_gap} * 1.5)!important`,
      border: "2px solid white",
      borderRadius: "50%!important",
      padding: 0,
      textAlign: "center",
    },
  }),
  logo: { "& img": { maxHeight: "3rem", objectFit: "contain" } },
  [`@media ${variables.media.smallscreen}`]: {
    NavBar: (props) => ({
      display: (props.mobileView || !props.mobileView) && "grid",
    }),
  },
});
const MainHeader = ({
  user,
  userThumbnail,
  intl,
  currencies,
  currenciesStatus,
  changeLang,
  onSelectCurrency,
}) => {
  const mobileView = useContext(MobileViewContext);
  const history = useHistory();
  const { top_entity_groups, chatNotifications } = useSelector((state) => {
    const { top_entity_groups } = state.userPerms;

    return {
      chatNotifications: state.ChatReducer.notifications,
      top_entity_groups,
    };
  });

  const classes = styles({
    chatNotifNum: chatNotifications
      ? chatNotifications.length.toString().length
      : 0,
    mobileView,
  });

  const { setShowChatDrawer } = useContext(GlobalModalContext);

  function onUserProfileClick() {
    history.push("/my-profile");
  }

  const selCurr = currencies.find((c) => c.selected);
  useChatNotifier();

  const { data: company } = useQuery({
    queryKey: ["companyProfile"],
    queryFn: () => fetchCompanyProfile(),
    refetchOnWindowFocus: false,
    retry: false,
  });
  var companyLogo = LOGO;
  if ((top_entity_groups ?? []).includes("branded_ui")) {
    companyLogo = company?.logo_url ?? LOGO;
  }

  return (
    <div className={classes.NavBar}>
      <a href="#" className={classes.logo}>
        <img src={companyLogo} />
      </a>
      <div>
        <span>
          <small>
            {VERSION.split("-")
              .filter((s, idx) => idx + 1 < VERSION.split("-").length)
              .join("-")}
          </small>
        </span>
      </div>
      <div>
        <SuIcon icon="icon-warn" />
        <AnnounceKit widget="https:announcekit.app/widgets/v2/EUIaA">
          <span>
            <TransTxt id="WHAT'S NEW" />
          </span>
        </AnnounceKit>
      </div>
      <ChattingSection
        // className={classes.chatSection}
        classes={classes}
        onClick={() => setShowChatDrawer(true)}
        chatNotifications={chatNotifications}
        intl={intl}
      />
      <Dropdown
        className="CurrencyDropdown"
        title={
          currenciesStatus == "UPDATED" ? (
            `${intl.formatMessage({ id: "CURRENCY" })}: ${selCurr.notation}, ${
              selCurr.symbol
            }`
          ) : (
            <Loader content="" />
          )
        }>
        {currencies.map((curr, idx) => (
          <Dropdown.Item
            active={curr.selected}
            key={idx}
            onClick={() => onSelectCurrency(curr.notation)}>
            {curr.notation}, <strong>{curr.symbol}</strong>
          </Dropdown.Item>
        ))}
      </Dropdown>
      <span id="languageDropDown">
        <Dropdown
          icon={
            <React.Fragment>
              <SuIcon icon="icon-global" />{" "}
            </React.Fragment>
          }
          title={_.upperCase(intl.formatMessage({ id: "language" }))}>
          {langs.map((item, idx) => (
            <Dropdown.Item
              id={item.name}
              key={idx}
              className={classes.langEntry}
              onClick={() => changeLang(item.lang)}>
              <img className={classes.langFlag} src={item.img} />
              {item.name}
            </Dropdown.Item>
          ))}
        </Dropdown>
      </span>
      <div>
        {!!userThumbnail ? (
          <img src={userThumbnail} className={classes.userThumbnail} />
        ) : (
          <SuIcon icon="icon-bianzu2" />
        )}
        <span id="profileDropDown">
          <Dropdown
            title={
              _.isEmpty(user)
                ? `${_.upperCase(intl.formatMessage({ id: "my_profile" }))}`
                : `${user?.first_name ?? ""} ${user?.last_name ?? ""}`
            }>
            <Dropdown.Item
              icon={<Icon icon="profile" />}
              onClick={onUserProfileClick}
              id="my_profile">
              {_.upperCase(intl.formatMessage({ id: "my_profile" }))}
            </Dropdown.Item>
            <Dropdown.Item onSelect={signOut} icon={<Icon icon="sign-out" />}>
              {_.upperCase(intl.formatMessage({ id: "sign_out" }))}
            </Dropdown.Item>
          </Dropdown>
        </span>
      </div>
    </div>
  );
};
MainHeader.propTypes = {
  user: PropTypes.object,
  userThumbnail: PropTypes.object,
  currenciesStatus: PropTypes.string.isRequired,
  currencies: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired,
  changeLang: PropTypes.func.isRequired,
  onSelectCurrency: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  const userMeta = state.userMeta;

  const { top_parent_logo: companyLogo } = state.companyProfile;

  const { user, profile_image_url: userThumbnail } = userMeta;
  const saveMsg = JSON.parse(window.sessionStorage.getItem("chatMsg")) || [];
  const chatMsg = saveMsg.reduce(
    (all, next) =>
      all.some((atom) => atom["msg_id"] == next["msg_id"])
        ? all
        : [...all, next],
    []
  );
  return {
    user,
    userThumbnail,
    companyLogo,
    currenciesStatus: state.financialCurrenciesStatus,
    currencies: state.financialAvailableCurrencies,
    unReadMsg: chatMsg,
    chatBoxStatus: state.toggleChattingRoomStatus,
    getRoomLinkErrorStatus: state.getRoomLinkErrorStatus,
    selectGroup: state.offerChattingSelectGroups,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onSelectCurrency: (notation) => dispatch(selectCurrency(notation)),
    changeLang: (lang) => {
      dispatch(changeLang({ lang, updateOPTIONS: true }));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withRouter(MainHeader)));
