import {
  COMMUNICATIONS_CHANGE_GENERAL_FILTER,
  COMMUNICATIONS_SAVE_LIST_DATA,
} from "../../../actions/Admin/Communications/types";
import { commonList } from "@src/reducers/common";

export const initiaCommunicationsList = {
  ...commonList,
  displayLength: 100,
  filters: {
    subject_prefix__icontains: "",
    sender_email__icontains: "",
    email__icontains: "",
    entity_types__in: [],
    re_action_types__in: [],
    of_action_types__in: [],
    creator__icontains: "",
    created__gte: "",
    created__lte: "",
    status: "",
    page: 1,
    limit: 20,
  },
};
export const NewCommunicationsReducer = (
  state = initiaCommunicationsList,
  action
) => {
  switch (action.type) {
    case COMMUNICATIONS_SAVE_LIST_DATA:
      return action.data;
    case COMMUNICATIONS_CHANGE_GENERAL_FILTER: {
      const { filters } = action;
      return { ...state, filters: { ...state.filters, ...filters } };
    }
    default:
      return state;
  }
};
