import {
  RadioGroup,
  Radio,
  Checkbox,
  Input,
  InputNumber,
  Avatar,
  SelectPicker,
} from "rsuite";

import TransTxt from "@src/components/common/SxFormatMessage";
import SuIcon from "@src/style/icon/components/SuIcon";

// ============================= ACTIONS =============================
import {
  itineraryAddPax,
  itineraryChangePax,
  itineraryRemovePax,
  itinerarySetupFormChange,
} from "@src/actions/Project/TripPlanner";

// ============================ SELECTORS ============================
import {
  getSetupFormDataSelector,
  getNationalitiesSelector,
} from "@src/selectors/Project/TripPlanner";
import { getSelCurrencySelector } from "@src/selectors/Financial";

// ============================== TOOLS ==============================
import { bankersRounder } from "@src/tools/pricing_tools";

import { connect, useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import React, { useCallback, useContext } from "react";
import { WhisperHelper } from "@src/components/common/ui_helpers";
import LeanAuthHoc from "@src/components/authorization/LeanAuthHoc";
import { createUseStyles } from "react-jss";
import { variables } from "@src/jsssetup";
import { Form, Formik } from "formik";
import { NormalInputField, NormalSelectField } from "@src/components/forms";
import {
  CustomButton,
  CustomCircIconBtn,
} from "@src/components/common/buttons";
import { TripPlanContext } from "../TripPlanner";
import { useQuery } from "@tanstack/react-query";
import { fetchSubAgentsProfile } from "@src/api";
import { toast } from "react-toastify";
import { withAppUserType } from "@src/components/authorization/permissionhocs";

const sectionHeaderStyles = createUseStyles({
  SectionHeader: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    justifyItems: "center",
    margin: "0px",
  },
  avatar: { background: variables.colors.easy.lightOrange2 },
});
function SectionHeader(props) {
  const { title, icon } = props;

  const classes = sectionHeaderStyles();

  return (
    <div className={classes.SectionHeader}>
      <Avatar className={classes.avatar} size="md" circle>
        <SuIcon size="lg" icon={icon} />
      </Avatar>
      <span>
        <strong>{title}</strong>
      </span>
    </div>
  );
}
SectionHeader.propTypes = {
  title: PropTypes.object.isRequired,
  icon: PropTypes.string.isRequired,
};

const srvIconStyles = createUseStyles({
  SrvIcon: {
    background: variables.colors.easy.lightOrange2,
  },
});
function SrvIcon(props) {
  const { icon } = props;

  const classes = srvIconStyles();
  return (
    <Avatar className={classes.SrvIcon} size="md" circle>
      <SuIcon size="md" icon={icon} />
    </Avatar>
  );
}
SrvIcon.propTypes = {
  icon: PropTypes.string.isRequired,
};

class TmpServices extends React.PureComponent {
  renderIcon(icon) {
    return (
      <Avatar className="top-controller-avatar" size="md" circle>
        <SuIcon size="md" icon={icon} />
      </Avatar>
    );
  }
  renderSrvCategory(trf_key) {
    return (
      <span>
        <strong>
          <TransTxt id={trf_key} startCase />
        </strong>
      </span>
    );
  }
  renderCheckbox(srv, overrideDisabled) {
    const { currentStep, requiredServices, onChange } = this.props;

    return (
      <Checkbox
        disabled={currentStep !== 1 || overrideDisabled}
        checked={requiredServices.includes(srv)}
        value={{ name: "requiredServices", value: srv, all: false }}
        name={srv}
        onChange={(value, checked) =>
          onChange(srv, checked ? "push" : "remove")
        }
      />
    );
  }
  render() {
    const { currentStep, requiredServices, onChange } = this.props;

    const baseCls = "ItinerarySetupController__controller--topcontroller";

    return (
      <div className={`${baseCls}__section ${baseCls}__section--services`}>
        <SectionHeader
          title={<TransTxt id="tripplan_services" upperCase />}
          icon="icon-Service"
        />
        <div
          className={`${baseCls}__section__inputs ${baseCls}__section__inputs--services`}>
          <div
            className={`${baseCls}__section__inputs__servicegroup ${baseCls}__section__inputs__servicegroup--selectall`}>
            <span>
              <strong>All Services</strong>
            </span>
            <Checkbox
              disabled={currentStep !== 1}
              checked={["TR", "TF", "ACC", "MI"].every((type) =>
                requiredServices.includes(type)
              )}
              name="ALL"
              onChange={(value, checked) => {
                onChange("TR", checked ? "push" : "remove");
                onChange("ACC", checked ? "push" : "remove");
                onChange("TF", checked ? "push" : "remove");
                onChange("MI", checked ? "push" : "remove");
              }}
            />
          </div>
          <div className={`${baseCls}__section__inputs__servicegroup`}>
            <SrvIcon icon="icon-Transports" />
            {this.renderSrvCategory("tripplan_transportation")}
            {this.renderCheckbox("TR")}
          </div>
          <div className={`${baseCls}__section__inputs__servicegroup`}>
            <SrvIcon icon="icon-Accommodation" />
            {this.renderSrvCategory("tripplan_accommodation")}
            {this.renderCheckbox("ACC")}
          </div>
          <div className={`${baseCls}__section__inputs__servicegroup`}>
            <SrvIcon icon="icon-Transfers" />
            {this.renderSrvCategory("tripplan_transfers")}
            <Checkbox
              disabled={
                currentStep !== 1 ||
                requiredServices.filter((s) => s == "ACC" || s == "TR").length <
                  2
              }
              checked={requiredServices.includes("TF")}
              value={{ name: "requiredServices", value: "TF", all: false }}
              name="TF"
              onChange={(value, checked) =>
                onChange("TF", checked ? "push" : "remove")
              }
            />
          </div>
          <div className={`${baseCls}__section__inputs__servicegroup`}>
            <SrvIcon icon="icon-AddOns_Function" />
            {this.renderSrvCategory("tripplan_add_ons")}
            {this.renderCheckbox("MI")}
          </div>
        </div>
      </div>
    );
  }
}
TmpServices.propTypes = {
  currentStep: PropTypes.number.isRequired,
  requiredServices: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};
const srvsMapStateToProps = (state) => {
  const { requiredServices } = getSetupFormDataSelector(state);
  return { requiredServices, currentStep: state.tripPlannerCurrentStep };
};
const srvsMapDispatchToProps = (dispatch) => {
  return {
    onChange: (srvType, actionType) => {
      if (["TR", "ACC"].includes(srvType) && actionType == "remove") {
        dispatch(
          itinerarySetupFormChange({
            name: "requiredServices",
            value: "TF",
            type: "array",
            action: "remove",
          })
        );
      }

      dispatch(
        itinerarySetupFormChange({
          name: "requiredServices",
          value: srvType,
          type: "array",
          action: actionType,
        })
      );
    },
  };
};
const Services = connect(
  srvsMapStateToProps,
  srvsMapDispatchToProps
)(TmpServices);

class TmpTravellers extends React.PureComponent {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      currentStep,
      adults,
      children,
      countryData,
      nationality,
      onChange,
      onChangePax,
    } = this.props;

    const baseCls = "ItinerarySetupController__controller--bottomcontroller";

    return (
      <div className={`${baseCls}__section`}>
        <SectionHeader
          title={<TransTxt id="tripplan_travellers" upperCase />}
          icon="icon-Travellers"
        />
        <div
          className={`${baseCls}__section__inputs ${baseCls}__section__inputs--travellers`}>
          <div className={`${baseCls}__section__inputs__inputgroup`}>
            <span>
              <strong>
                Nationality <small>(required)</small>
              </strong>
            </span>
            <SelectPicker
              value={nationality}
              data={countryData}
              onSelect={(value) => onChange("nationality", value)}
              onClean={() => onChange("nationality", "")}
            />
          </div>
          <div className={`${baseCls}__section__inputs__inputgroup`}>
            <span>
              <strong>
                <TransTxt id="tripplan_adults" startCase />{" "}
                <small>(required)</small>
              </strong>
            </span>
            <InputNumber
              disabled={currentStep !== 1}
              onChange={(value) =>
                onChangePax(
                  "adults",
                  isNaN(parseInt(value, 10)) ? 0 : parseInt(value, 10)
                )
              }
              name="adults"
              min={1}
              max={9}
              value={adults.toString()}
            />
          </div>
          <div className={`${baseCls}__section__inputs__inputgroup`}>
            <span>
              <strong>
                <TransTxt id="tripplan_children" startCase />{" "}
                <small>(required)</small>
              </strong>
            </span>
            <InputNumber
              disabled={currentStep !== 1}
              onChange={(value) =>
                onChangePax(
                  "children",
                  isNaN(parseInt(value, 10)) ? 0 : parseInt(value, 10)
                )
              }
              name="children"
              min={0}
              max={9}
              value={children.toString()}
            />
          </div>
        </div>
      </div>
    );
  }
}
TmpTravellers.propTypes = {
  nationality: PropTypes.string.isRequired,
  countryData: PropTypes.array.isRequired,
  currentStep: PropTypes.number.isRequired,
  adults: PropTypes.number.isRequired,
  children: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangePax: PropTypes.func.isRequired,
};
const trvMapStateToProps = (state) => {
  const { adults, children, nationality } = getSetupFormDataSelector(state);

  const countryData = getNationalitiesSelector(state);

  return {
    nationality,
    adults,
    children,
    currentStep: state.tripPlannerCurrentStep,
    countryData,
  };
};
const trvMapDispatchToProps = (dispatch) => {
  return {
    onChangePax: (paxType, value) =>
      dispatch(itineraryChangePax(paxType, value)),
    onChange: (name, value) => {
      dispatch(
        itinerarySetupFormChange({
          name,
          value,
          type: "text",
          action: "set",
        })
      );
    },
  };
};
const Travellers = connect(
  trvMapStateToProps,
  trvMapDispatchToProps
)(TmpTravellers);

class TmpBudget extends React.PureComponent {
  constructor(props) {
    super(props);
  }
  render() {
    const { pax, budget, currency, onChange } = this.props;

    const baseCls = "ItinerarySetupController__controller--bottomcontroller";

    return (
      <div className={`${baseCls}__section`}>
        <SectionHeader
          title={<TransTxt id="tripplan_budget" upperCase />}
          icon="icon-Budget"
        />
        <div
          className={`${baseCls}__section__inputs ${baseCls}__section__inputs--budget`}>
          <div className={`${baseCls}__section__inputs__inputgroup`}>
            <span>
              <strong>
                <TransTxt id="tripplan_per_person_budget" startCase />
              </strong>
            </span>
            <InputNumber
              prefix={<strong>{currency.symbol}</strong>}
              onChange={(value, event) => {
                event.preventDefault();
                if (event.type == "blur") {
                  return;
                }
                const paxBudget = parseFloat(value * pax);
                onChange(
                  "budget",
                  isNaN(paxBudget) ? 0 : bankersRounder(paxBudget, 2)
                );
              }}
              min={0}
              name="budget"
              value={bankersRounder(budget / pax, 2).toString()}
            />
          </div>
          <div className={`${baseCls}__section__inputs__inputgroup`}>
            <span>
              <strong>
                <TransTxt id="tripplan_total_budget" startCase />
              </strong>
            </span>
            <InputNumber
              prefix={<strong>{currency.symbol}</strong>}
              onChange={(value, event) => {
                if (event.type == "blur") {
                  return;
                }
                const budget = parseFloat(value);
                onChange("budget", isNaN(budget) ? 0 : budget);
              }}
              min={0}
              name="budget"
              value={budget.toString()}
            />
          </div>
        </div>
      </div>
    );
  }
}
TmpBudget.propTypes = {
  pax: PropTypes.number.isRequired,
  budget: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
  currency: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};
const budgetMapStateToProps = (state) => {
  var { adults, children, budget } = getSetupFormDataSelector(state);
  const pax = adults + children;

  const currency = getSelCurrencySelector(state);

  return { pax, budget, currency, currentStep: state.tripPlannerCurrentStep };
};
const budgetMapDispatchToProps = (dispatch) => {
  return {
    onChange: (name, value) => {
      dispatch(
        itinerarySetupFormChange({
          name,
          value,
          type: "text",
          action: "set",
        })
      );
    },
  };
};
const Budget = connect(
  budgetMapStateToProps,
  budgetMapDispatchToProps
)(TmpBudget);

var Usage = ({
  usage,
  options = [
    ["OFF", "Offer"],
    ["RQ", "Template"],
    ["MKT", "Marketplace"],
  ],
  onChange,
}) => {
  return (
    <Formik
      initialValues={{ trip_type: usage }}
      validate={(values) => onChange("usage", values.trip_type)}>
      <Form>
        <NormalSelectField
          label="Trip Type"
          name="trip_type"
          options={options}
        />
      </Form>
    </Formik>
  );
};
Usage.propTypes = {
  usage: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
const withUsageOptions = (WrappedComponent, options) => {
  const Comp = (props) => {
    return <WrappedComponent {...props} options={options} />;
  };
  return Comp;
};

const MemberUsage = withAppUserType(
  withUsageOptions(Usage, [
    ["OFF", "Offer"],
    ["RQ", "Template"],
    ["MKT", "Marketplace"],
  ]),
  ["member"]
);
const SubagentUsage = withAppUserType(
  withUsageOptions(Usage, [["OFF", "Offer"]]),
  ["subagent"]
);

class Title extends React.PureComponent {
  constructor(props) {
    super(props);
  }
  render() {
    const { title, onChange } = this.props;

    return (
      <Input
        placeholder="Title"
        onChange={(value) => onChange("title", value)}
        name="title"
        type="text"
        value={title}
      />
    );
  }
}
Title.propTypes = {
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const onBehalfOfStyles = createUseStyles({
  labelValue: {
    display: "grid",
    gridTemplateColumns: "auto 1fr auto",
    alignItems: "center",
    gridGap: variables.half_gap,
  },
});
var OnBehalfOf = ({
  targetEntityIsLoading,
  targetEntityData,
  setShowSubEntitySelectModal,
}) => {
  const classes = onBehalfOfStyles();
  return (
    <div className={classes.labelValue}>
      <label>
        <strong>On Behalf Of:</strong>
      </label>{" "}
      <span>
        {targetEntityIsLoading
          ? "Loading..."
          : targetEntityData
          ? `${targetEntityData?.name} (${targetEntityData?.legal_title})`
          : "N/A"}
      </span>
      <CustomCircIconBtn
        icon="ic:baseline-edit"
        onClick={() => setShowSubEntitySelectModal(true)}
      />
    </div>
  );
};
OnBehalfOf = withAppUserType(OnBehalfOf, ["member"]);

const basicInfoStyles = createUseStyles({
  BasicInfo: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    gridGap: variables.half_gap,
    alignItems: "center",
    padding: [variables.half_gap, 0],
  },
  titleAndUsage: {
    display: "grid",
    gridGap: variables.half_gap,
    // gridTemplateColumns: "repeat(2, 1fr)",
    alignItems: "end",
    padding: [0, variables.normal_gap],
  },
  labelValue: {
    display: "grid",
    gridTemplateColumns: "auto 1fr auto",
    alignItems: "center",
    gridGap: variables.half_gap,
  },
  icon: {
    "width": "1.5rem",
    "height": "1.5rem",
    "background": variables.colors.easy.lightOrange,
    "borderRadius": "50%",
    "cursor": "pointer",
    "display": "grid",
    "placeItems": "center",
    "& svg": {
      color: "white",
    },
  },
});
const BasicInfo = () => {
  const { tripLoading, setShowSubEntitySelectModal } =
    useContext(TripPlanContext);

  const dispatch = useDispatch();
  const onChange = useCallback(
    (name, value) => {
      dispatch(
        itinerarySetupFormChange({ name, value, type: "text", action: "set" })
      );
    },
    [dispatch]
  );

  const { title, usage, target_entity } = useSelector((state) =>
    getSetupFormDataSelector(state)
  );
  const usageDisabled = useSelector(
    (state) => !!state.tripPlannerInstanceData.id
  );

  const classes = basicInfoStyles();

  const { data: targetEntityData, isLoading: targetEntityIsLoading } = useQuery(
    {
      queryKey: ["tripTargetEntity", target_entity],
      queryFn: () =>
        target_entity
          ? fetchSubAgentsProfile(target_entity.split("___")[0])
          : new Promise((resolve) => resolve(null)),
      onError: () =>
        toast.error("Error fetching target entity", { autoClose: 3000 }),
      refetchOnWindowFocus: false,
      enabled: (target_entity || "").includes("subagent"),
    }
  );

  return (
    <div className={classes.BasicInfo}>
      <SectionHeader
        title={<TransTxt id="tripplan_basic_information" upperCase />}
        icon="icon-Basic"
      />
      <div className={classes.titleAndUsage}>
        {!tripLoading && (
          <Formik
            initialValues={{ title }}
            validate={(values) => onChange("title", values.title)}>
            <NormalInputField label="Title" name="title" />
          </Formik>
        )}
        <MemberUsage
          usage={usage}
          usageDisabled={usageDisabled}
          onChange={onChange}
        />
        <SubagentUsage
          usage={usage}
          usageDisabled={usageDisabled}
          onChange={onChange}
        />
        <OnBehalfOf
          targetEntityData={targetEntityData}
          targetEntityIsLoading={
            (target_entity || "").includes("subagent") && targetEntityIsLoading
          }
          setShowSubEntitySelectModal={setShowSubEntitySelectModal}
        />
        {/* <div className={classes.labelValue}> */}
        {/*   <label> */}
        {/*     <strong>On Behalf Of:</strong> */}
        {/*   </label>{" "} */}
        {/*   <span> */}
        {/*     {targetEntityIsLoading */}
        {/*       ? "Loading..." */}
        {/*       : targetEntityData */}
        {/*       ? `${targetEntityData?.name} (${targetEntityData?.legal_title})` */}
        {/*       : "N/A"} */}
        {/*   </span> */}
        {/*   <CustomCircIconBtn */}
        {/*     icon="ic:baseline-edit" */}
        {/*     onClick={() => setShowSubEntitySelectModal(true)} */}
        {/*   /> */}
        {/* </div> */}
      </div>
    </div>
  );
};

const easyAIBtnStyles = createUseStyles({
  EasyAIBtn: {
    padding: variables.normal_gap,
  },
});
var EasyAIBtn = () => {
  const classes = easyAIBtnStyles();

  const { setShowEasyAI } = useContext(TripPlanContext);

  return (
    <div className={classes.EasyAIBtn}>
      <CustomButton onClick={() => setShowEasyAI(true)}>easyAI</CustomButton>
    </div>
  );
};
EasyAIBtn = LeanAuthHoc(EasyAIBtn, {
  entity_allowed_groups: ["ai_preview"],
});

const itinerarySetupControllerStyles = createUseStyles({
  ItinerarySetupController: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
  },
  section: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    gridTemplateColumns: "max-content repeat(2, 1fr)",
    borderBottom: `2px solid ${variables.colors.easy.lightOrange2}`,
  },
});
export default function ItinerarySetupController() {
  const classes = itinerarySetupControllerStyles();

  return (
    <div className={classes.ItinerarySetupController}>
      <div className={classes.section}>
        <EasyAIBtn />
        <Services />
        <BasicInfo />
      </div>
      <div className={classes.section}>
        <Travellers />
        <Budget />
      </div>
    </div>
  );
}
