import _ from "lodash";

export const getErrorMsg = (field, formErrors) => {
  if (
    _.isEmpty(formErrors) ||
    Object.keys(formErrors).every((k) => k == "valid")
  ) {
    return "";
  }

  if (typeof formErrors[field] == "undefined") {
    throw new Error(`Field ${field} does not exist.`);
  } else if (formErrors[field].hasError) {
    return formErrors[field].errorMessage;
  } else {
    return "";
  }
};

export const choicesCheckBoxChange = (value, checked) => {
  var payload = { name: value.field, value: value.value, key: value.key };
  if (value.all) {
    payload["type"] = "set";
  } else {
    if (checked) {
      payload["type"] = "push";
    } else {
      payload["type"] = "remove";
    }
  }

  return payload;
};

export const inputChange = (key, value) => {
  const payload = { name: key, value: value, type: "set" };
  return payload;
};

export const radioChange = (key, value) => {
  const payload = { name: key, value: value, type: "set" };
  return payload;
};

export const getFilter = (listFilter, service_type) => {
  const para = {};
  const travel_services = [];
  Object.keys(listFilter).forEach((item) => {
    para[item] = listFilter[item].value
      ? listFilter[item].value.toString()
      : "";
    if (_.find(service_type.choices || [], { value: item })) {
      travel_services.push(listFilter[item].value);
    }
    return {
      [item]: listFilter[item].value ? listFilter[item].value.toString() : "",
    };
  });
  para.travel_services = travel_services.flat().toString();
  return para;
};

export const getPagination = (list) => {
  return {
    page: list.page || 1,
    limit: list.limit || 20,
  };
};

export const checkForm = (checkObj, checkModel) => {
  const checkResult = checkModel.check(checkObj);
  for (const key in checkResult) {
    const obj = checkResult[key];
    if (obj.hasError) {
      return {
        hasError: true,
        errorMessage: obj.errorMessage,
      };
    }
  }
  return {
    hasError: false,
  };
};

//calculate DOM width
export const getWidthByEle = (text) => {
  const node = document.createElement("span");
  node.id = "test";
  node.innerHTML = _.startCase(text);
  document.body.appendChild(node);
  const width = node.offsetWidth || 10;
  document.body.removeChild(node);
  return width + 40;
};

// convert table list service display name

export const convertServiceToDisplayName = ({
  servicesValue,
  servicesOptions,
  servicesCategoryOptions,
  setLang,
  lang_mapping,
}) => {
  let serviceValue = [];
  servicesCategoryOptions.forEach((category) => {
    servicesValue.forEach((item) => {
      const servicesObj = _.find(servicesOptions, { id: item });
      if (servicesObj.service_type == category.value) {
        serviceValue.push(
          servicesObj[`brief_description_${lang_mapping[setLang]}`]
        );
      }
    });
  });

  serviceValue = Array.from(new Set(serviceValue));

  return {
    value: _.isEmpty(serviceValue)
      ? ""
      : `${serviceValue[0]}(${serviceValue.length})`,
    fullName: serviceValue.toString(),
  };
};

export const convertServiceCategoryToDisplayName = ({
  servicesValue,
  servicesOptions,
  servicesCategoryOptions,
}) => {
  let categoryValue = [];
  servicesCategoryOptions.forEach((category) => {
    servicesValue.forEach((item) => {
      if (
        _.find(servicesOptions, { id: item }).service_type == category.value
      ) {
        categoryValue.push(category.display_name);
      }
    });
  });

  categoryValue = Array.from(new Set(categoryValue));

  return {
    value: _.isEmpty(categoryValue)
      ? ""
      : `${categoryValue[0]}(${categoryValue.length})`,
    fullName: categoryValue.toString(),
  };
};

export const convertAddressToDisplayName = (address) => {
  const tempAddress = _.groupBy(address, "address_type", {});
  const city = _.get(tempAddress, "BU.0.city");
  const country = _.get(tempAddress, "BU.0.country_code");
  return `${city},${country}`;
};
