import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Drawer, Icon, IconButton } from "rsuite";
import { FiltersBar } from "./components/FiltersBar.js";
import { Ferry } from "./components/Ferry.js";
import { TripSeats } from "./components/TripSeats.js";
import Prices from "./components/Prices.js";
import { defaultFilters } from "./helpers.js";
import { DateTime } from "luxon";
import { FerryPrice } from "./components/FerryPrice.js";
import {
  handleNonValidImgFn,
  humanizeDurationFromMins,
} from "@src/tools/common_tools.js";
import _ from "lodash";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getFinalFerryPrice,
  pollFerriesSearch,
  retrieveFerryDetails,
} from "@src/api/Project/ferries/index.js";
import { getSetupFormDataSelector } from "@src/selectors/Project/TripPlanner/trip_setup_selectors.js";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useLoadingAnimation } from "@src/pages/hooks/index.js";
import { getFerryByLegUidSelector } from "@src/selectors/Project/TripPlanner/ferry_selectors.js";

export const OverviewAllFerries = ({ sessionId, onClose }) => {
  const [step, setStep] = useState(1);
  const [accommodations, setAccommodations] = useState(null);
  const [tickets, setTickets] = useState(null);
  const { adults, children, tripLegUid, currentFerryToken } = useSelector(
    (state) => {
      const { adults, children } = getSetupFormDataSelector(state);
      const ferrySession = (
        state.tripPlannerFerriesReducer?.sessions ?? []
      ).find((s) => s.session_id === sessionId);
      const tripLegUid = ferrySession?.payload?.legUid;
      const { ferryData } = getFerryByLegUidSelector(state, {
        legUid: tripLegUid,
      });
      const currentFerryToken = ferryData.token;
      return { adults, children, tripLegUid, currentFerryToken };
    }
  );
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedReturnOptions, setSelectedReturnOptions] = useState([]);
  const [filters, setFilters] = useState(defaultFilters.cast({}));
  const [session_id, setSessionId] = useState(null);
  const [token, setToken] = useState(null);
  const [provider, setProvider] = useState(null);
  const [priceDetails, setPriceDetails] = useState(null);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ["view_all_ferries_poll", sessionId],
    queryFn: () => pollFerriesSearch(sessionId),
    onError: (error) => {
      toast.error("Error fetching available ferry options.", {
        autoClose: 5000,
      });
      onClose();
    },
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!sessionId,
  });
  const ferries = data?.data?.results ?? [];
  const loading = isLoading || isFetching;

  const detailsMutation = useMutation({
    mutationFn: ({ session_id, token, provider }) =>
      retrieveFerryDetails({ session_id, token, provider }),
    onSuccess: (data, variables) => {
      setSessionId(variables.session_id);
      setToken(variables.token);
      setProvider(variables.provider);
      setAccommodations(data?.data?.accommodations ?? {});
      setTickets(data?.data?.tickets ?? {});
      setStep(2);
    },
  });

  const allLegs = _.flatten(
    ferries.map((ferry) => [
      ...new Set(ferry.legs.map((leg) => leg.operator.name)),
    ])
  );

  const operatorCount = _.countBy(allLegs);
  const operatorOptions = [
    ["", "---"],
    ...Object.entries(operatorCount).map(([operator, count]) => [
      operator,
      `${operator} (${count})`,
    ]),
  ];

  var newFilteredFerries = ferries.filter((ferry) => {
    if (filters.operator) {
      const isValid = ferry.legs.some((leg) =>
        leg.operator.name.includes(filters.operator)
      );
      if (!isValid) {
        return false;
      }
    }
    if (
      filters.min_price &&
      Number(ferry.price.value) < Number(filters.min_price)
    ) {
      return false;
    }
    if (
      filters.max_price &&
      Number(ferry.price.value) > Number(filters.max_price)
    ) {
      return false;
    }
    return true;
  });

  const [filteredFerries, setFilteredFerries] = useState(newFilteredFerries);
  const currency = filteredFerries?.[0]?.price?.currency ?? "EUR";
  var averagePrice = 0;
  var minPrice = 0;
  var fastest = 0;
  var averageDuration = 0;
  if (filteredFerries.length > 0) {
    const totalPrices = filteredFerries.reduce(
      (a, b) => a + b?.price?.value,
      0
    );
    averagePrice = totalPrices / filteredFerries.length;
    minPrice = Math.min(...filteredFerries.map((ferry) => ferry.price.value));

    const totalDurations = filteredFerries?.reduce(
      (a, b) => a + b.legs[0].duration_minutes,
      0
    );

    fastest = Math.min(
      ...filteredFerries.map((ferry) => ferry.legs[0].duration_minutes)
    );

    averageDuration = totalDurations / filteredFerries.length;
  }

  //Filtering
  useEffect(() => {
    if (filters.order_by) {
      const sortingCriteria = {
        "price": (a, b) => a.price.value - b.price.value,
        "-price": (a, b) => b.price.value - a.price.value,
        "duration": (a, b) =>
          a.legs[0].duration_minutes - b.legs[0].duration_minutes,
        "-duration": (a, b) =>
          b.legs[0].duration_minutes - a.legs[0].duration_minutes,
        "arrival": (a, b) =>
          DateTime.fromISO(a.legs[0].arrival_datetime).valueOf() -
          DateTime.fromISO(b.legs[0].arrival_datetime).valueOf(),
        "-arrival": (a, b) =>
          DateTime.fromISO(b.legs[0].arrival_datetime).valueOf() -
          DateTime.fromISO(a.legs[0].arrival_datetime).valueOf(),
        "departure": (a, b) =>
          DateTime.fromISO(a.legs[0].departure_datetime).valueOf() -
          DateTime.fromISO(b.legs[0].departure_datetime).valueOf(),
        "-departure": (a, b) =>
          DateTime.fromISO(b.legs[0].departure_datetime).valueOf() -
          DateTime.fromISO(a.legs[0].departure_datetime).valueOf(),
      };

      if (sortingCriteria[filters.order_by]) {
        newFilteredFerries.sort(sortingCriteria[filters.order_by]);
      }
    }
    setFilteredFerries(newFilteredFerries);
  }, [filters, loading]);

  const priceDetailsMutation = useMutation({
    mutationFn: (payload) => getFinalFerryPrice({ payload }),
    onSuccess: (data) => {
      setPriceDetails(data?.data);
      setStep(3);
    },
  });

  const ultimateLoading =
    loading || detailsMutation.isLoading || priceDetailsMutation.isLoading;
  const { animationRef } = useLoadingAnimation({
    loading: ultimateLoading,
    animationName: "ferries.json",
  });

  const totalPax = adults + children;
  const [selectedNum, setSelectedNum] = useState(0);
  const [selectedReturnNum, setSelectedReturnNum] = useState(0);
  const [totalTicketsSelected, setTotalTicketsSelected] = useState(0);

  function goToPrices() {
    if (totalTicketsSelected >= 0 && totalTicketsSelected !== totalPax) {
      toast.warn(
        <span>
          {`You have selected ${totalTicketsSelected} from ${totalPax} tickets`}
        </span>
      );
      return;
    }
    priceDetailsMutation.mutate({
      session_id,
      provider,
      token,
      legs: [
        {
          is_return_leg: false,
          accommodations: selectedOptions.map((o) => ({
            id: o.id,
            is_shared: o.is_shared,
            quantity: o.tickets,
          })),
        },
      ],
    });
  }

  const animationStyles = { height: "15rem" };
  return (
    <Drawer size="lg" placement="right" show={true}>
      <Drawer.Header className="ferries-drawer-header">
        <div></div>
        <Drawer.Title>Ferries</Drawer.Title>
        <IconButton
          className="close-btn"
          icon={<Icon icon="close" className="rs-icon" />}
          size="sm"
          placement="right"
          onClick={onClose}>
          Close
        </IconButton>
      </Drawer.Header>
      <Drawer.Body className="ferries-drawer-body">
        {ultimateLoading && (
          <div className="ferries-drawer-body__loadingCntr">
            <div ref={animationRef} style={animationStyles} />
            <div className="ferries-drawer-body__loadingCntr__text">
              Loading...
            </div>
          </div>
        )}
        {ultimateLoading ? null : step === 1 ? (
          <div className="ferries-drawer-body__ferriesContainer">
            <FiltersBar
              operatorOptions={operatorOptions}
              averagePrice={
                averagePrice !== 0 ? (
                  <FerryPrice
                    customStyles={{
                      Price: { fontSize: "small", fontWeight: "normal" },
                    }}
                    price={averagePrice}
                    currency={currency}
                  />
                ) : (
                  "N/A"
                )
              }
              averageDuration={
                averageDuration !== 0
                  ? humanizeDurationFromMins(Math.floor(averageDuration))
                  : "N/A"
              }
              options={filteredFerries.length}
              filters={filters}
              onSetFilters={(filters) => setFilters(filters)}
            />
            <div
              className={"ferries-drawer-body__ferriesContainer__ferriesList"}>
              {filteredFerries.length > 0 ? (
                filteredFerries.map((ferry, idx) => (
                  <div
                    className="ferries-drawer-body__ferriesContainer__ferriesList__ferryCard"
                    key={idx}>
                    <Ferry
                      ferry={ferry}
                      isSelected={ferry.token === currentFerryToken}
                      cheapest={minPrice}
                      fastest={fastest}
                      onContinue={() => {
                        detailsMutation.mutate({
                          session_id: sessionId,
                          token: ferry.token,
                          provider: ferry.provider,
                        });
                      }}
                    />
                  </div>
                ))
              ) : (
                <div className="ferries-drawer-body__ferriesContainer__ferriesList--noFerries">
                  No results found
                  <img
                    src={
                      "https://apollo-statics.s3.eu-central-1.amazonaws.com/svgs/noferryresults.svg"
                    }
                    onError={(e) => {
                      handleNonValidImgFn(e, "AFER");
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        ) : step === 2 ? (
          <React.Fragment>
            <div className="ferries-drawer-body__tripSeatsContainer">
              <div className="ferries-drawer-body__tripSeatsContainer__actionsTop">
                <button
                  className="ferries-drawer-body__tripSeatsContainer__actionsTop__actionBtn"
                  onClick={() => {
                    setSelectedNum(0);
                    setSelectedReturnNum(0);
                    setTotalTicketsSelected(0);
                    setSelectedOptions([]);
                    setSelectedReturnOptions([]);
                    setStep(1);
                  }}>
                  Back to Ferries List
                </button>
              </div>
              {(accommodations?.legs ?? []).map((leg, idx) => (
                <React.Fragment key={idx}>
                  <TripSeats
                    leg={leg}
                    totalPax={totalPax}
                    selectedOptions={selectedOptions}
                    setSelectedOptions={setSelectedOptions}
                    selectedReturnOptions={selectedReturnOptions}
                    setSelectedReturnOptions={setSelectedReturnOptions}
                    isReturnLeg={idx === 1}
                    selectedNum={selectedNum}
                    selectedReturnNum={selectedReturnNum}
                    totalTicketsSelected={totalTicketsSelected}
                    setTotalTicketsSelected={setTotalTicketsSelected}
                    setSelectedNum={setSelectedNum}
                    setSelectedReturnNum={setSelectedReturnNum}
                  />
                </React.Fragment>
              ))}
            </div>
            <div className="ferries-drawer-body__actionsBtm">
              <button
                className="ferries-drawer-body__actionsBtm__actionBtn"
                onClick={() => goToPrices()}>
                Continue
              </button>
            </div>
          </React.Fragment>
        ) : step === 3 ? (
          <div className="ferries-drawer-body__pricesCntr">
            <div className="ferries-drawer-body__pricesCntr__actionsTop">
              <button
                className="ferries-drawer-body__pricesCntr__actionsTop__actionBtn"
                onClick={() => {
                  setSelectedNum(0);
                  setSelectedReturnNum(0);
                  setTotalTicketsSelected(0);
                  setSelectedOptions([]);
                  setSelectedReturnOptions([]);
                  setStep(2);
                }}>
                Back to trip seats
              </button>
            </div>
            <Prices
              sessionId={sessionId}
              priceDetails={priceDetails}
              tripLegUid={tripLegUid}
              currentFerryToken={currentFerryToken}
              onClose={onClose}
            />
          </div>
        ) : null}
      </Drawer.Body>
    </Drawer>
  );
};
OverviewAllFerries.propTypes = {
  sessionId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
