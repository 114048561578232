import service, { getAuthHeaders } from "@src/api/request";
import axios from "axios";
import { SISI2_API_URL } from "..";

export const deleteTrip = async (id) => {
  const authHeaders = getAuthHeaders();
  return await axios.delete(`${SISI2_API_URL}/trips/${id}/`, {
    headers: authHeaders,
  });
};

export function getArchiveList(params) {
  return service({ url: "/bid/trip-plan/", method: "get", params });
}

export function fetchBiddingTripsStatistics(params) {
  return service({ url: "/bid/trip-plan-statistics/", method: "get", params });
}

export function fetchBiddingRequestsStatistics(params) {
  return service({
    url: "/bid/bid-request-statistics/",
    method: "get",
    params,
  });
}

export function fetchBiddingOfferStatistics(params) {
  return service({
    url: "/bid/bidding-flow-statistics/",
    method: "get",
    params,
  });
}

export function getArchiveMyOffersList(params) {
  return service({ url: "/bid/trip-offer/", method: "get", params });
}

export function editArchiveMyOffersList(params) {
  return service({
    url: `/bid/trip-offer/${params.id}/`,
    method: "patch",
    data: params.payload,
  });
}

export function getArchiveMyOffersDetail(id) {
  return service({ url: `/bid/trip-offer/${id}`, method: "get" });
}

export function getArchiveMyOffersSentGraphStats(params) {
  return service({ url: "/bid/trip-offer-sent-stats/", method: "get", params });
}

export function resentMyOffersSentEmail(reference) {
  return service({
    url: `/bid/trip-offer/${reference}/email-sender/`,
    method: "post",
    data: {},
  });
}

export function getMyOffersUnsentGraphStats(params) {
  return service({ url: "bid/trip-plan-unsent-stats/", method: "get", params });
}

export function getMyOffersBookedGraphStats(params) {
  return service({
    url: "bid/trip-offer-booked-stats/",
    method: "get",
    params,
  });
}
