import _ from "lodash";
import { StyleSheet, Text, View, Link, Font } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import React from "react";
import { easyBasicColor } from "../PreviewPDF";
import Divider from "./Divider";
import { formatPrice } from "../helpers";
import { currencies } from "@src/definitions";
import NotoSansRegular from "@src/style/fonts/NotoSans-Regular.ttf";
import NotoSansBold from "@src/style/fonts/NotoSans-Bold.ttf";
import { DateTime } from "luxon";

Font.register({
  family: "Noto Sans",
  fonts: [
    { src: NotoSansRegular, fontWeight: "normal" },
    { src: NotoSansBold, fontWeight: "bold" },
  ],
});

const paymentLinksTableStyles = StyleSheet.create({
  PaymentLinksTable: {
    fontSize: 10,
    fontFamily: "Noto Sans",
    fontWeight: "bold",
  },
  headers: { flexDirection: "row", justifyContent: "space-between" },
  header: { fontSize: 10, fontWeight: "bold", textAlign: "center" },
  value: {
    fontSize: 10,
    fontFamily: "Noto Sans",
    fontWeight: "bold",
    textAlign: "center",
  },
  sumsContr: { flexDirection: "row" },
  sums: { width: "50%" },
  labelValueRow: {
    flexDirection: "row",
    justifyContent: "flex-end",
    fontSize: 10,
    paddingBottom: 5,
    width: "88%",
    gap: 12,
  },
  normalText: { fontSize: 10, fontFamily: "Noto Sans" },
  boldText: { fontSize: 10, fontFamily: "Noto Sans", fontWeight: "bold" },
  classicButton: {
    backgroundColor: "#007BFF",
    color: "white",
    padding: 3,
    borderRadius: 4,
    textAlign: "center",
    cursor: "pointer",
    fontWeight: "bold",
  },
  textLabel: { color: "white", fontSize: 10, textDecoration: "none" },
});
const PaymentLinksTable = ({ paymentLinks, entity_colors }) => {
  const color = entity_colors?.text?.colored ?? easyBasicColor;
  const headerStyle = { ...paymentLinksTableStyles.header, color };

  const valuesStyle = { ...paymentLinksTableStyles.headers, paddingBottom: 10 };
  const payBtn = {
    ...paymentLinksTableStyles.classicButton,
    backgroundColor: color,
  };

  const currency = (paymentLinks ?? []).length > 0 && paymentLinks[0].currency;
  const totalPaidAmount =
    (paymentLinks ?? []).length > 0
      ? paymentLinks.reduce((acc, link) => {
          return link.status === "paid" ? acc + link.amount : acc;
        }, 0)
      : "";

  const totalUnpaidAmount =
    (paymentLinks ?? []).length > 0
      ? paymentLinks.reduce((acc, link) => {
          return link.status === "unpaid" ? acc + link.amount : acc;
        }, 0)
      : "";

  return (
    <View style={paymentLinksTableStyles.ItemsTable}>
      <Text style={paymentLinksTableStyles.boldText}>
        {paymentLinks?.length > 0 ? "Payment Links" : ""}
      </Text>
      <View style={paymentLinksTableStyles.headers}>
        <Text style={{ ...headerStyle, width: "30%", textAlign: "left" }}>
          Description
        </Text>
        <Text style={{ ...headerStyle, width: "20%" }}>Due Date</Text>
        <Text style={{ ...headerStyle, width: "15%" }}>Status</Text>
        <Text style={{ ...headerStyle, width: "15%" }}>Type</Text>
        <Text style={{ ...headerStyle, width: "15%" }}>Amount</Text>
        <Text style={{ ...headerStyle, width: "10%", textAlign: "center" }}>
          Actions
        </Text>
      </View>
      <Divider color={color} />
      {(paymentLinks && paymentLinks.length > 0 ? paymentLinks : []).map(
        (link, idx) => (
          <React.Fragment key={idx}>
            <View style={valuesStyle}>
              <View
                style={{
                  ...paymentLinksTableStyles.value,
                  width: "30%",
                  textAlign: "left",
                }}>
                <Text style={paymentLinksTableStyles.value}>
                  {link?.description}
                </Text>
              </View>
              <Text style={{ ...paymentLinksTableStyles.value, width: "20%" }}>
                {link?.due_date &&
                  DateTime.fromISO(link.due_date).toLocaleString(
                    DateTime.DATE_MED_SHORT
                  )}
              </Text>
              <Text style={{ ...paymentLinksTableStyles.value, width: "15%" }}>
                {link?.status && _.capitalize(link.status)}
              </Text>
              <Text style={{ ...paymentLinksTableStyles.value, width: "15%" }}>
                {link?.type && _.capitalize(link.type)}
              </Text>
              <Text style={{ ...paymentLinksTableStyles.value, width: "15%" }}>
                {link?.amount &&
                  currencies[link?.currency === "GBX" ? "GBP" : link.currency]}
                {link?.amount && formatPrice(link.amount)}
              </Text>
              {link.status === "unpaid" ? (
                <Text style={{ ...payBtn, width: "10%", height: "22px" }}>
                  <Link
                    style={paymentLinksTableStyles.textLabel}
                    src={link?.url}>
                    Pay now
                  </Link>
                </Text>
              ) : (
                <Text
                  style={{
                    ...paymentLinksTableStyles.value,
                    width: "10%",
                  }}></Text>
              )}
            </View>
          </React.Fragment>
        )
      )}
      <View style={paymentLinksTableStyles.sumsContr}>
        <View style={{ width: "50%" }} />
        <View style={paymentLinksTableStyles.sums}>
          <Divider color={color} />
          <View style={paymentLinksTableStyles.labelValueRow}>
            <Text style={paymentLinksTableStyles.boldText}>Paid Total</Text>
            <View style={{ width: 10 }} />
            <Text style={{ ...paymentLinksTableStyles.boldText, width: 80 }}>
              {currencies[currency === "GBX" ? "GBP" : currency]}
              {formatPrice(totalPaidAmount)}
            </Text>
          </View>
          <View style={paymentLinksTableStyles.labelValueRow}>
            <Text style={paymentLinksTableStyles.boldText}>Balance</Text>
            <View style={{ width: 10 }} />
            <Text style={{ ...paymentLinksTableStyles.boldText, width: 80 }}>
              {currencies[currency === "GBX" ? "GBP" : currency]}
              {formatPrice(totalUnpaidAmount)}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};
PaymentLinksTable.defaultProps = { paymentLinks: [], entity_colors: {} };
PaymentLinksTable.propTypes = {
  paymentLinks: PropTypes.array.isRequired,
  entity_colors: PropTypes.object,
};
export default PaymentLinksTable;
