export const bankersRounder = (amount, digits = 3) => {
  var x = amount * Math.pow(10, digits);
  var r = Math.round(x);
  var br = Math.abs(x) % 1 === 0.5 ? (r % 2 === 0 ? r : r - 1) : r;
  return br / Math.pow(10, digits);
};

export const markUpper = (amount, markUp, digits = 3) => {
  return bankersRounder(amount * (1 + markUp / 100), digits);
};

export const markupGetter = (revenue, buying, digits = 3) => {
  return bankersRounder((revenue / buying - 1) * 100, digits);
};

export const grossMarginer = (revenue, cost, digits = 3) => {
  return bankersRounder((revenue - cost) / revenue, digits);
};

export const revenuer = (cost, gross_margin, digits = 3) => {
  return bankersRounder(cost / (1 - gross_margin / 100), digits);
};

export const grossProfiter = (revenue, cost, digits = 3) => {
  return bankersRounder(revenue - cost, digits);
};

/**
 * Converts an amount to a requested currency. The amount is always
 * assumed to be in global currency.
 * @param {number} amount The amount to be converted.
 * @param {string} globalCurrency The global currency.
 * @param {string} currency The currency to convert to.
 * @param {number} rate The rate between the globalCurrency and currency.
 * @returns {number} The converted amount
 */
export const convert = (amount, globalCurrency, currency, rate) => {
  if (globalCurrency == currency) {
    return amount;
  } else {
    return bankersRounder(amount * rate, 2);
  }
};

/**
 * Converts an amount which is not in the globalCurrency back to global
 * currency. The amount is assumed to be in the same currency as the currency
 * parameter.
 * @param {number} amount The amount to be unconverted.
 * @param {string} globalCurrency The globalCurrency notation.
 * @param {string} currency The current currency notation.
 * @param {number} rate
 * @returns {number}
 */
export const unConvert = (amount, globalCurrency, currency, rate) => {
  if (globalCurrency == currency) {
    return amount;
  } else {
    return bankersRounder(amount / rate, 2);
  }
};

/**
 * Formats an amount to a currency.
 * @param {number} amount The amount to be formatted.
 * @param {string} currency The currency to be formatted to.
 * @returns {string} The formatted amount.
 */
export function formatToCurrency(amount, currency) {
  if (!currency) {
    return `${currency}${amount}`;
  }

  return amount.toLocaleString("en-US", {
    style: "currency",
    currency,
    currencyDisplay: "symbol",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}
