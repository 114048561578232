import PropTypes from "prop-types";
import React, { useState } from "react";
import { Icon, IconButton, Input, Nav, Panel, Table } from "rsuite";
import { createUseStyles } from "react-jss";
import { variables } from "@src/jsssetup";
import { connect } from "react-redux";
import _ from "lodash";
import { changeBaseInfo } from "@src/actions/Operation/CustomServices/AddOn";
import { highlightTemplate } from "@src/reducers/Operation/CustomServices/AddOn";
import { langs } from "@src/config/common";

export const tableHeadStyles = {
  "background": variables.colors.background.base,
  "borderBottom": `2px solid ${variables.colors.lightBorderFillColor}`,
  "& th": {
    padding: `calc(${variables.normal_gap} / 2)`,
  },
};

const highlightStyles = createUseStyles({
  header: {
    display: "inline-grid",
    gridTemplateColumns: "auto 1fr",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    alignItems: "center",
  },
  content: {
    maxHeight: "20vw",
    overflow: "auto",
  },
  table: {
    "width": "100%",
    "& td": {
      padding: `calc(${variables.normal_gap} / 2)`,
      textAlign: "center",
    },
  },
  tableHead: {
    ...tableHeadStyles,
  },
});
const Highlights = ({ highlights, onChange }) => {
  const [lang, setLang] = useState("en");

  const classes = highlightStyles();

  return (
    <Panel
      className={`CustomPanel`}
      header={
        <React.Fragment>
          <h5 className={classes.header}>
            <Icon icon="asterisk" />
            Highlights
          </h5>
          <span className="CustomPanel__header-actions">
            <IconButton
              icon={<Icon icon="plus" />}
              color="green"
              size="xs"
              onClick={() =>
                onChange("highlights", [
                  ...highlights,
                  { ...highlightTemplate },
                ])
              }>
              <strong>Add Highlight</strong>
            </IconButton>
          </span>
        </React.Fragment>
      }
      shaded>
      <div className={classes.content}>
        <Nav activeKey={lang} appearance="tabs">
          {Object.entries(langs).map((lg, idx) => (
            <Nav.Item key={idx} eventKey={lg[0]} onClick={() => setLang(lg[0])}>
              {lg[1]}
            </Nav.Item>
          ))}
        </Nav>
        <table className={classes.table}>
          <thead className={classes.tableHead}>
            <tr>
              <th>#</th>
              <th>Description</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {highlights.map((hl, idx) => {
              return (
                <tr>
                  <td>{idx + 1}</td>
                  <td>
                    <Input
                      value={_.get(hl, `description_${lang}`, "")}
                      onChange={function (value) {
                        const newHighlights = highlights.map((hl, hidx) => {
                          if (hidx !== idx) {
                            return { ...hl };
                          } else {
                            return { ...hl, [`description_${lang}`]: value };
                          }
                        });
                        onChange("highlights", newHighlights);
                      }}
                    />
                  </td>
                  <td>
                    <IconButton
                      icon={<Icon icon="close" />}
                      color="red"
                      size="xs"
                      onClick={function () {
                        const newHighlights = highlights
                          .filter((hl, hidx) => hidx !== idx)
                          .map((hl) => ({ ...hl }));
                        onChange("highlights", newHighlights);
                      }}>
                      Remove
                    </IconButton>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Panel>
  );
};
Highlights.propTypes = {
  highlights: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  const { highlights } = state.customServiceAddOn;

  return {
    highlights,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onChange: (key, value) => dispatch(changeBaseInfo(key, value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Highlights);
