import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";
import {
  addIncEncRule,
  changeIncEncRule,
  removeIncEncRule,
} from "@src/actions/Project/TripPlanner";
import { Icon, IconButton, Input, Nav, Table, Tag } from "rsuite";
import _ from "lodash";

class IncExcRulesSection extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { lang: "en" };
    this.handleChangeLang = this.handleChangeLang.bind(this);
  }
  handleChangeLang(lang) {
    this.setState((p) => ({ ...p, lang }));
  }
  render() {
    const { lang } = this.state;
    const { inclusions, exclusions, onAdd, onChange, onRemove } = this.props;

    const data = [
      ...inclusions.map((rule, idx) => ({ ...rule, type: "inclusions", idx })),
      ...exclusions.map((rule, idx) => ({ ...rule, type: "exclusions", idx })),
    ];

    return (
      <div className="MktSectionsPanel__incexcrules">
        <Table data={data} rowHeight={60}>
          <Table.Column width={120}>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.Cell>
              {(rowData) => (
                <Tag color={rowData.type == "exclusions" ? "red" : "green"}>
                  <strong>
                    {rowData.type == "exclusions" ? "Exclusion" : "Inclusion"}
                  </strong>
                </Tag>
              )}
            </Table.Cell>
          </Table.Column>
          <Table.Column width={200}>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.Cell>
              {(rowData) => (
                <Input
                  value={_.get(rowData, `name_${lang}`, "")}
                  onChange={function (value) {
                    onChange(rowData.type, rowData.idx, `name_${lang}`, value);
                  }}
                />
              )}
            </Table.Cell>
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.Cell>
              {(rowData) => (
                <Input
                  value={_.get(rowData, `description_${lang}`, "")}
                  onChange={function (value) {
                    onChange(
                      rowData.type,
                      rowData.idx,
                      `description_${lang}`,
                      value
                    );
                  }}
                />
              )}
            </Table.Cell>
          </Table.Column>
          <Table.Column width={100} fixed="right" verticalAlign="middle">
            <Table.HeaderCell>Actions</Table.HeaderCell>
            <Table.Cell>
              {(rowData) => (
                <IconButton
                  icon={<Icon icon="close" />}
                  size="xs"
                  color="red"
                  onClick={function () {
                    onRemove(rowData.type, rowData.idx);
                  }}>
                  Remove
                </IconButton>
              )}
            </Table.Cell>
          </Table.Column>
        </Table>
        <Nav vertical activeKey={lang} reversed appearance="tabs">
          <Nav.Item eventKey="en" onClick={() => this.handleChangeLang("en")}>
            English
          </Nav.Item>
          <Nav.Item eventKey="el" onClick={() => this.handleChangeLang("el")}>
            Greek
          </Nav.Item>
          <Nav.Item eventKey="cn" onClick={() => this.handleChangeLang("cn")}>
            Chinese
          </Nav.Item>
        </Nav>
        <div className="MktSectionsPanel__incexcrules__actions">
          <span>
            <strong>Actions</strong>
          </span>
          <IconButton
            icon={<Icon icon="plus" />}
            color="green"
            size="xs"
            onClick={function () {
              onAdd("inclusions");
            }}>
            Add Inclusion
          </IconButton>
          <IconButton
            icon={<Icon icon="plus" />}
            color="red"
            size="xs"
            onClick={function () {
              onAdd("exclusions");
            }}>
            Add Exclusion
          </IconButton>
        </div>
      </div>
    );
  }
}
IncExcRulesSection.propTypes = {
  inclusions: PropTypes.array.isRequired,
  exclusions: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { inclusions, exclusions } = state.tripPlannerMktIncExc;
  return {
    inclusions,
    exclusions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAdd: (ruleType) => dispatch(addIncEncRule(ruleType)),
    onRemove: (ruleType, idx) => dispatch(removeIncEncRule(ruleType, idx)),
    onChange: (ruleType, idx, key, value) =>
      dispatch(changeIncEncRule(ruleType, idx, key, value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IncExcRulesSection);
