import React from "react";
import PropTypes from "prop-types";

const CheckBoxComp = ({ checked, setChecked }) => {
  return (
    <div className="CheckBoxComp">
      <label className="CheckBoxComp__checkbox">
        <input
          type="checkbox"
          checked={checked}
          onChange={(e) => {
            setChecked(e.target.checked);
          }}
        />
        <span>✓</span>
      </label>
    </div>
  );
};
CheckBoxComp.defaultProps = {
  checked: false,
};
CheckBoxComp.propTypes = {
  checked: PropTypes.bool.isRequired,
  setChecked: PropTypes.func.isRequired,
};
export default CheckBoxComp;
