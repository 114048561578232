export const ONE_TO_ONE_FETCH_DATA = "ONE_TO_ONE_FETCH_DATA";
export const ONE_TO_ONE_BEGIN_FETCH_DATA = "ONE_TO_ONE_BEGIN_FETCH_DATA";
export const ONE_TO_ONE_END_FETCH_DATA = "ONE_TO_ONE_END_FETCH_DATA";
export const ONE_TO_ONE_INITIALIZE_LIST_FILTER_FORM =
  "ONE_TO_ONE_INITIALIZE_LIST_FILTER_FORM";
export const ONE_TO_ONE_UPDATE_LIST_FILTER_FORM =
  "ONE_TO_ONE_UPDATE_LIST_FILTER_FORM";
export const ONE_TO_ONE_APPLY_LIST_FILTER = "ONE_TO_ONE_APPLY_LIST_FILTER";
export const ONE_TO_ONE_UPDATE_LIST_COLUMNS_FORM =
  "ONE_TO_ONE_UPDATE_LIST_COLUMNS_FORM";
export const ONE_TO_ONE_APPLY_LIST_COLUMNS = "ONE_TO_ONE_APPLY_LIST_COLUMNS";
export const ONE_TO_ONE_INITIALIZE_LIST_COLUMNS_FORM =
  "ONE_TO_ONE_INITIALIZE_LIST_COLUMNS_FORM";
export const ONE_TO_ONE_FETCH_OPTIONS = "ONE_TO_ONE_FETCH_OPTIONS";
export const ONE_TO_ONE_CLEAN_BASE_DATA_EDIT_FORM =
  "ONE_TO_ONE_CLEAN_BASE_DATA_EDIT_FORM";

//DEPRECATED: Use the ONE_TO_ONE_EDIT_BASE_DATA
export const ONE_TO_ONE_EDIT_BASE_DATA = "ONE_TO_ONE_EDIT_BASE_DATA";

export const ONE_TO_ONE_POLICY_EDIT_CHANGE = "ONE_TO_ONE_POLICY_EDIT_CHANGE";

export const ONE_TO_ONE_VALIDATE_BASE_DATA_EDIT_FORM =
  "ONE_TO_ONE_VALIDATE_BASE_DATA_EDIT_FORM";
export const ONE_TO_ONE_RESET_LIST_FILTER_FORM =
  "ONE_TO_ONE_RESET_LIST_FILTER_FORM";
//==========================================EDIT=====================================
export const ONE_TO_ONE_FETCH_EDIT_DATA = "ONE_TO_ONE_FETCH_EDIT_DATA";
export const ONE_TO_ONE_INIT_POLICY_BASE_DATA_EDIT_FORM =
  "ONE_TO_ONE_INIT_POLICY_BASE_DATA_EDIT_FORM";
//========================================MODAL======================================
export const ONE_TO_ONE_LOADING_POLICY_FORM_MODAL =
  "ONE_TO_ONE_LOADING_POLICY_FORM_MODAL";
export const ONE_TO_ONE_IDLE_POLICY_FORM_MODAL =
  "ONE_TO_ONE_IDLE_POLICY_FORM_MODAL";
export const ONE_TO_ONE_SHOW_POLICY_FORM_MODAL =
  "ONE_TO_ONE_SHOW_POLICY_FORM_MODAL";
export const ONE_TO_ONE_HIDE_POLICY_FORM_MODAL =
  "ONE_TO_ONE_HIDE_POLICY_FORM_MODAL";
export const ONE_TO_ONE_COMPANY_USER_INFO = "ONE_TO_ONE_COMPANY_USER_INFO";
export const ONE_TO_ONE_INIT_POLICY_FORM_MODAL =
  "ONE_TO_ONE_INIT_POLICY_FORM_MODAL";
