import _ from "lodash";
import { Button, IconButton, Icon, Loader, Tag } from "rsuite";
import { modalGenericStyles, variables } from "@src/jsssetup";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { getImageBankList } from "@src/api";
import { connect } from "react-redux";
import { getUserSourceEntitySelector } from "@src/selectors/Shared/user_selectors";
import { Formik, Form } from "formik";
import { NormalInputField } from "@src/components/forms";
import TransTxt from "@src/components/common/SxFormatMessage";

const imgCardStyles = createUseStyles({
  ImgCard: {
    boxShadow: variables.shadows.normalShadow,
    display: "grid",
    gridGap: variables.normal_gap,
    gridAutoRows: "max-content",
    padding: variables.normal_gap,
    position: "relative",
  },
  img: {
    height: "10rem",
    width: "100%",
    objectFit: "cover",
    borderRadius: "5px",
  },
  tags: {
    whiteSpace: "nowrap",
    overflowX: "auto",
  },
  imgActions: {
    padding: `calc(${variables.normal_gap} / 2)`,
    display: "grid",
  },
});
const ImgCard = ({ img, children }) => {
  const classes = imgCardStyles();

  return (
    <div className={classes.ImgCard}>
      <span>
        <strong>{img.title}</strong>
      </span>
      <img alt="" src={img.big_thumbnail_url} className={classes.img} />
      <div className={classes.tags}>
        {img.tags.map((tag, idx) => (
          <Tag size="xs" key={idx}>
            {tag}
          </Tag>
        ))}
      </div>
      <div className={classes.imgActions}>{children}</div>
    </div>
  );
};
ImgCard.propTypes = {
  img: PropTypes.object.isRequired,
  children: PropTypes.node,
};

const imagePickerStyles = createUseStyles({
  ImagePicker: {
    display: "grid",
    gridGap: variables.normal_gap,
    minHeight: "20vh",
    overflow: "auto",
    padding: variables.normal_gap,
    paddingTop: "0px",
    gridAutoRows: "max-content",
    height: "100%",
  },
  imgCard: {
    boxShadow: variables.shadows.normalShadow,
    display: "grid",
    gridGap: variables.normal_gap,
    padding: variables.normal_gap,
    position: "relative",
  },
  img: {
    height: "15vh",
    width: "100%",
    objectFit: "cover",
    borderRadius: "5px",
  },
  tags: {
    whiteSpace: "nowrap",
    overflowX: "auto",
  },
  imgActions: {
    padding: `calc(${variables.normal_gap} / 2)`,
    display: "grid",
  },
  form: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    background: "white",
    zIndex: 1,
    position: "sticky",
    top: 0,
    padding: `calc(${variables.normal_gap} / 2)`,
  },
  formActions: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    gridTemplateColumns: "max-content repeat(2, 1fr)",
  },
  filtersBtn: {
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
});
var ImagePicker = ({ source_entity, selectedIds, onSelect }) => {
  const [loading, setLoading] = useState(true);
  const [images, setImages] = useState([]);
  const [page, setPage] = useState(1);
  const [hasNext, setHasNext] = useState(0);
  const [payload, setPayload] = useState({});
  const [filtersExpanded, setFiltersExpanded] = useState(false);

  const classes = imagePickerStyles();
  const page_size = 10;
  const retriever = () => {
    setLoading(true);

    getImageBankList({ ...payload, source_entity, page, page_size })
      .then((data) => {
        if (page === 1) {
          setImages(_.get(data, "results", []));
        } else {
          setImages((p) => [...p, ..._.get(data, "results", [])]);
        }
        setHasNext(Boolean(_.get(data, "next")));
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (page > 0) {
      retriever();
    }
  }, [page]);

  return (
    <div className={classes.ImagePicker}>
      {filtersExpanded ? (
        <Formik
          initialValues={{ title: "", tags: "" }}
          onSubmit={(value) => {
            setPage(0);
            const payload = _.pickBy(value, (v) => Boolean(v));
            setPayload(payload);
            setPage(1);
          }}>
          {(formProps) => (
            <Form className={classes.form}>
              <NormalInputField
                name="title"
                label={<TransTxt id="ImageModal__title" />}
              />
              <NormalInputField
                name="tags"
                label={<TransTxt id="ImageModal__tags" />}
              />
              <div className={classes.formActions}>
                <IconButton
                  icon={<Icon icon="angle-up" />}
                  size="xs"
                  onClick={() => setFiltersExpanded(false)}
                />
                <Button
                  size="xs"
                  onClick={() => {
                    setPage(0);
                    formProps.resetForm();
                    setPayload({ title: "", tags: "" });
                    setPage(1);
                  }}>
                  <strong>
                    <TransTxt id="ImageModal__reset" />
                  </strong>
                </Button>
                <Button color="blue" size="xs" type="submit">
                  <strong>
                    <TransTxt id="ImageModal__search" />
                  </strong>
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <Button
          className={classes.filtersBtn}
          size="xs"
          onClick={() => setFiltersExpanded(true)}>
          <strong>
            <TransTxt id="ImageModal__filters" />
          </strong>
        </Button>
      )}
      {loading ? (
        <Loader center size="lg" />
      ) : (
        images
          .filter((img) => !selectedIds.includes(img.id))
          .map((img, idx) => (
            <ImgCard img={img} key={idx}>
              <Button size="xs" color="blue" onClick={() => onSelect(img)}>
                <strong>
                  <TransTxt id="ImageModal__select" />
                </strong>
              </Button>
            </ImgCard>
          ))
      )}
      {images.length > 0 && hasNext && (
        <Button onClick={() => setPage((p) => p + 1)}>
          <strong>
            <TransTxt id="ImageModal__load_more" />
          </strong>
        </Button>
      )}
    </div>
  );
};
ImagePicker.defaultProps = {
  selectedIds: [],
};
ImagePicker.propTypes = {
  source_entity: PropTypes.string.isRequired,
  selectedIds: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
};
const imgMapStateToProps = (state) => {
  const source_entity = getUserSourceEntitySelector(state);

  return { source_entity };
};
ImagePicker = connect(imgMapStateToProps, null)(ImagePicker);
export { ImagePicker };

const styles = createUseStyles({
  "@keyframes slidein": modalGenericStyles["@keyframes slidein"],
  "modal": { ...modalGenericStyles.modal },
  "card": { ...modalGenericStyles.card },
  "cardHeader": { ...modalGenericStyles.cardHeader },
  "cardBody": {
    ...modalGenericStyles.cardBody,
    display: "grid",
    gridTemplateColumns: "1fr 4fr",
    gridTemplateRows: "1fr",
    overflow: "hidden",
  },
  "cardActions": { ...modalGenericStyles.cardActions },
  "imageList": {
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateColumns: "repeat(4, 1fr)",
    gridAutoRows: "max-content",
    overflow: "auto",
  },
});
var ImageModal = ({
  source_entity,
  editingId,
  onClose,
  onSave,
  onGetCurrentImgIds,
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const classes = styles();

  useEffect(() => {
    onGetCurrentImgIds().then((imageIds) => {
      if (imageIds.length) {
        getImageBankList({
          source_entity,
          page: 1,
          page_size: imageIds.length,
          ids: imageIds.join(","),
        }).then((result) => {
          setSelectedImages(_.get(result, "results"));
        });
      }
    });
  }, []);

  return (
    <div className={classes.modal}>
      {loading ? (
        <Loader center vertical size="lg" />
      ) : (
        <div className={classes.card}>
          <div className={classes.cardHeader}>
            <h5>
              <TransTxt id="ImageModal__edit_images" />
            </h5>
          </div>
          <div className={classes.cardBody}>
            <ImagePicker
              selectedIds={selectedImages.map((img) => img.id)}
              onSelect={(img) => setSelectedImages((p) => [...p, img])}
            />
            <div className={classes.imageList}>
              {selectedImages.map((img, idx) => (
                <ImgCard key={idx} img={img}>
                  <Button
                    size="xs"
                    color="red"
                    onClick={() =>
                      setSelectedImages((p) => p.filter((i) => i.id !== img.id))
                    }>
                    <strong>
                      <TransTxt id="ImageModal__remove" />
                    </strong>
                  </Button>
                </ImgCard>
              ))}
            </div>
          </div>
          <div className={classes.cardActions}>
            <Button onClick={onClose}>
              <strong>
                <TransTxt id="ImageModal__cancel" />
              </strong>
            </Button>
            <Button
              color="blue"
              onClick={async () => {
                setLoading(true);
                const result = await onSave(
                  editingId,
                  selectedImages.map((img) => img.id)
                );
                if (result == true) {
                  setLoading(false);
                  onClose();
                } else {
                  setLoading(false);
                }
              }}>
              <strong>
                <TransTxt id="ImageModal__save" />
              </strong>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
ImageModal.propTypes = {
  source_entity: PropTypes.string.isRequired,
  editingId: PropTypes.number.isRequired,
  onGetCurrentImgIds: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  const source_entity = getUserSourceEntitySelector(state);

  return { source_entity };
};
export default connect(mapStateToProps, null)(ImageModal);
