import React from "react";
import NProgress from "nprogress";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";
import PageLoading from "@src/components/common/Loading";
import _ from "lodash";

NProgress.configure({ showSpinner: false });

class LoadingBar extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    NProgress.start();
  }
  componentWillUnmount() {
    NProgress.done();
  }
  render() {
    const { intl } = this.props;

    return (
      <PageLoading
        message={`${_.upperCase(intl.formatMessage({ id: "loading" }))}...`}
      />
    );
  }
}

LoadingBar.propTypes = {
  intl: PropTypes.object,
};

export default injectIntl(LoadingBar);
