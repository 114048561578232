import { Panel, Table, Input, IconButton, Icon, Tag } from "rsuite";

import _ from "lodash";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import React, { useState } from "react";
import SuIcon from "@src/style/icon/components/SuIcon";
import {
  addInclusionExclusion,
  removeInclusionExclusion,
  changeInclusionExclusion,
} from "@src/actions/Operation/CustomServices/AddOn";
import { createUseStyles } from "react-jss";
import { tableHeadStyles } from "./Highlights";
import { variables } from "@src/jsssetup";

const inclusionsExclusionsStyles = createUseStyles({
  table: { width: "100%" },
  tableHead: { ...tableHeadStyles },
  tableCell: {
    textAlign: "center",
    padding: `calc(${variables.normal_gap} / 2)`,
  },
});
const InclusionExclusions = ({
  inclusions,
  exclusions,
  onAdd,
  onRemove,
  onChange,
}) => {
  const classes = inclusionsExclusionsStyles();
  return (
    <Panel
      className="CustomPanel"
      header={
        <React.Fragment>
          <h5>
            <SuIcon icon="icon-icon_Detail40w" size="lg" />{" "}
            Inclusions/Exclusions
          </h5>
          <span className="CustomPanel__header-actions">
            <IconButton
              icon={<Icon icon="plus" />}
              color="green"
              size="xs"
              onClick={function () {
                onAdd("inclusion");
              }}>
              <strong>Add Inclusion</strong>
            </IconButton>
            <IconButton
              icon={<Icon icon="plus" />}
              color="red"
              size="xs"
              onClick={function () {
                onAdd("exclusion");
              }}>
              <strong>Add Exclusion</strong>
            </IconButton>
          </span>
        </React.Fragment>
      }
      shaded>
      <div className={classes.container}>
        <table className={classes.table}>
          <thead className={classes.tableHead}>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Type</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {[...inclusions, ...exclusions].map((rule, idx) => (
              <tr key={idx}>
                <td className={classes.tableCell}>
                  <Input
                    value={rule.name}
                    onChange={function (value) {
                      onChange(rule.type, rule.uid, "name", value);
                    }}
                  />
                </td>
                <td className={classes.tableCell}>
                  <Input
                    value={rule.description_en}
                    onChange={function (value) {
                      onChange(rule.type, rule.uid, "description_en", value);
                    }}
                  />
                </td>
                <td className={classes.tableCell}>
                  <Tag color={rule.type == "exclusion" ? "red" : "green"}>
                    <strong>{_.startCase(rule.type)}</strong>
                  </Tag>
                </td>
                <td className={classes.tableCell}>
                  <IconButton
                    icon={<Icon icon="minus" />}
                    color="red"
                    size="xs"
                    onClick={function () {
                      onRemove(rule.uid, rule.type);
                    }}>
                    Remove
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Panel>
  );
};
InclusionExclusions.defaultProps = {
  inclusions: [],
  exclusions: [],
};
InclusionExclusions.propTypes = {
  inclusions: PropTypes.array.isRequired,
  exclusions: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  const { inclusions, exclusions } = state.customServiceAddOn;

  return {
    inclusions,
    exclusions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onAdd: (dataType) => dispatch(addInclusionExclusion(dataType)),
    onRemove: (uid, dataType) =>
      dispatch(removeInclusionExclusion(uid, dataType)),
    onChange: (dataType, uid, key, value) =>
      dispatch(changeInclusionExclusion(dataType, uid, key, value)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InclusionExclusions);
