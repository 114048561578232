import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, useLocation, Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

import _ from "lodash";

import {
  Button,
  Form,
  FormGroup,
  InputGroup,
  Input,
  FormControl,
  Schema,
  Loader,
} from "rsuite";

import { signUp, forgotPassword } from "@src/api";
import { changeLang } from "@src/actions/i18n/lang";
import { checkForm } from "@src/tools/form_tools";
import { errorHandle } from "@src/tools/common_tools";
import { signIn as signInAuth } from "@src/tools/auth_tools";
import moment from "moment";
import {
  notifySignInValidationWarning,
  notifySignUpValidationWarning,
  notifySignInFailure,
  notifyResetEmailSent,
} from "@src/components/common/AuthNotifications";
import { toast } from "react-toastify";

const LOGO =
  "https://easytravel-tech.s3.eu-central-1.amazonaws.com/easyTravel_logo.svg";

const topImg =
  "https://sisistaticassets.s3.ap-southeast-1.amazonaws.com/assets/signup/graphic.png";

const { StringType } = Schema.Types;

const Loading = ({ intl }) => {
  return (
    <div className="LoaderContainer">
      <Loader
        center
        size="lg"
        vertical
        content={`${_.upperFirst(intl.formatMessage({ id: "loading" }))}...`}
      />
    </div>
  );
};
Loading.propTypes = {
  intl: PropTypes.object.isRequired,
};

const SignUpForm = ({ intl, toggleForm }) => {
  const [form, setForm] = useState({
    Email: "",
    CompanyName: "",
    // CompanyRegistrationNumber: "",
    UserName: "",
    Password: "",
    PasswordAgain: "",
  });
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  function onSignUp(e) {
    e.currentTarget.blur();
    const signUpModel = Schema.Model({
      Email: StringType()
        .isRequired(
          _.upperFirst(intl.formatMessage({ id: "email_cant_empty" }))
        )
        .isEmail(
          _.upperFirst(intl.formatMessage({ id: "please_input_right_email" }))
        ),
      CompanyName: StringType().isRequired(
        _.upperFirst(intl.formatMessage({ id: "company_name_cant_empty" }))
      ),
      Password: StringType().isRequired(
        _.upperFirst(intl.formatMessage({ id: "password_cant_empty" }))
      ),
      PasswordAgain: StringType()
        .isRequired(
          _.upperFirst(
            intl.formatMessage({ id: "please_input_password_again" })
          )
        )
        .addRule((value, data) => {
          if (value !== data.Password) {
            return false;
          }
          return true;
        }, _.upperFirst(intl.formatMessage({ id: "passwords_are_not_equal" }))),
      // CompanyRegistrationNumber: StringType().isRequired(
      //   _.upperFirst(intl.formatMessage({ id: "company_code_cant_empty" }))
      // ),
    });

    const {
      Email,
      CompanyName,
      // CompanyRegistrationNumber,
      UserName,
      Password,
      PasswordAgain,
    } = form;

    const checkResult = checkForm(
      {
        Email,
        CompanyName,
        // CompanyRegistrationNumber,
        UserName,
        Password,
        PasswordAgain,
      },
      signUpModel
    );

    if (checkResult.hasError) {
      notifySignUpValidationWarning(checkResult.errorMessage);
      return; // Break
    }
    setLoading(true);
    window.localStorage.removeItem("user_info");
    window.localStorage.removeItem("rtt");

    signUp({
      email: Email,
      company_name: CompanyName,
      // registration_code: CompanyRegistrationNumber,
      password: Password,
    })
      .then((res) => {
        const refreshTokenTime = moment().add(4, "minutes");
        window.localStorage.setItem("rtt", refreshTokenTime.valueOf());
        window.localStorage.setItem("user_info", JSON.stringify(res));
        history.push("/signUpFlow");
      })
      .catch((error) => {
        setLoading(false);
        const errorsList = [];
        Object.entries(error?.data ?? {}).map(([key, error], idx) => {
          if (Array.isArray(error)) {
            errorsList.push(
              <li key={idx}>
                {_.startCase(key)}: {error.map((err) => err.message).join(", ")}
              </li>
            );
          }
        });
        toast.error(<ul>{errorsList}</ul>, { autoClose: 6000 });
      });
  }

  return (
    <div className="SignUpForm">
      {loading && <Loading intl={intl} />}
      <Form fluid onChange={setForm} className="SignUpForm__form">
        <input type="password" style={{ display: "none" }} />
        <input type="text" autoComplete="off" style={{ display: "none" }} />
        <input
          type="password"
          name="tradePassword"
          id="txPassword"
          autoComplete="new-password"
          style={{ display: "none" }}
        />
        <div className="SignUpForm__form__element">
          <div className="SignUpForm__form__element__label">
            {_.upperFirst(intl.formatMessage({ id: "email" }))}
          </div>
          <FormControl name="Email" />
        </div>
        <div className="SignUpForm__form__element">
          <div className="SignUpForm__form__element__label">
            {_.startCase(intl.formatMessage({ id: "company_name" }))}
          </div>
          <FormControl name="CompanyName" />
        </div>
        {/* <div className="SignUpForm__form__element">
          <div className="SignUpForm__form__element__label">
            {_.startCase(
              intl.formatMessage({ id: "company_registration_number" })
            )}
          </div>
          <FormControl name="CompanyRegistrationNumber" />
        </div> */}
        <div className="SignUpForm__form__element">
          <div className="SignUpForm__form__element__label">
            {_.startCase(intl.formatMessage({ id: "password" }))}
          </div>
          <FormControl name="Password" type="password" />
        </div>
        <div className="SignUpForm__form__element">
          <div className="SignUpForm__form__element__label">
            {_.startCase(intl.formatMessage({ id: "password_again" }))}
          </div>
          <FormControl
            name="PasswordAgain"
            type="password"
            onKeyUp={(e) => {
              if (e.keyCode == 13) onSignUp(e);
            }}
          />
        </div>
        <div className="SignUpForm__form__element">
          <div className="SignUpForm__form__element__applyBtnCntr">
            <Button size="lg" appearance="primary" block onClick={onSignUp}>
              {_.startCase(intl.formatMessage({ id: "apply_registration" }))}
            </Button>
          </div>
        </div>
      </Form>
      <div className="SignUpForm__actions">
        <p>
          {_.upperFirst(intl.formatMessage({ id: "already_have_an_account" }))}?
        </p>
        <Link to="/user/SignInAndUp?view=in">
          <strong>{_.upperFirst(intl.formatMessage({ id: "sign_in" }))}</strong>
        </Link>
      </div>
    </div>
  );
};
SignUpForm.propTypes = {
  intl: PropTypes.object,
  history: PropTypes.object,
  toggleForm: PropTypes.func,
};

const SignInForm = ({ intl, toggleForm }) => {
  const [loading, setLoading] = useState(false);
  const [account, setAccount] = useState("");
  const [password, setPassword] = useState("");

  async function onSignIn(e) {
    e.currentTarget.blur();
    const signInModel = Schema.Model({
      account: StringType()
        .isRequired(
          _.upperFirst(intl.formatMessage({ id: "email_cant_empty" }))
        )
        .isEmail(
          _.upperFirst(intl.formatMessage({ id: "please_input_right_email" }))
        ),
      password: StringType().isRequired(
        _.upperFirst(intl.formatMessage({ id: "password_cant_empty" }))
      ),
    });

    const checkResult = checkForm(
      { account: account, password: password },
      signInModel
    );

    if (checkResult.hasError) {
      notifySignInValidationWarning(checkResult.errorMessage);
      return;
    }

    setLoading(true);
    const res = await signInAuth(account, password);

    if (res) {
      window.location.href = "/#/project/trip/new-trip";
      window.location.reload();
    } else {
      notifySignInFailure();
      setLoading(false);
    }
  }

  return (
    <div className="SignInForm">
      {loading && <Loading intl={intl} />}
      <Form className="SignInForm__formContainer">
        <div className="SignInForm__formContainer__element">
          <div className="SignInForm__formContainer__element__inputLabel">
            {_.upperFirst(intl.formatMessage({ id: "email" }))}
          </div>
          <InputGroup inside>
            <Input
              id="user_email"
              type={"text"}
              onChange={(value) => setAccount(value)}
            />
            <InputGroup.Button>
              <span className={"sisi-family icon-account"} />
            </InputGroup.Button>
          </InputGroup>
        </div>
        <div className="SignInForm__formContainer__element">
          <div className="SignInForm__formContainer__element__inputLabel">
            {_.upperFirst(intl.formatMessage({ id: "password" }))}
          </div>
          <InputGroup inside>
            <Input
              id="user_password"
              type="password"
              onChange={(value) => setPassword(value)}
              onKeyUp={(e) => {
                if (e.keyCode == 13) onSignIn(e);
              }}
            />
            <InputGroup.Button>
              <span className={"sisi-family icon-lock"} />
            </InputGroup.Button>
          </InputGroup>
          <div
            className="SignInForm__formContainer__element__forgotPass"
            onClick={() => toggleForm("forgot")}>
            {_.startCase(intl.formatMessage({ id: "forgot_password" }))} ?
          </div>
        </div>
        <div className="SignInForm__formContainer__element">
          <div className="SignInForm__formContainer__element__loginBtnCntr">
            <Button
              id="logInBtn"
              size="lg"
              appearance="primary"
              block
              onClick={onSignIn}>
              {_.upperFirst(intl.formatMessage({ id: "login" }))}
            </Button>
          </div>
        </div>
      </Form>
      <div className="SignInForm__formContainer__element__registerBtn">
        {/* <Link to="/user/SignInAndUp?view=up"> */}
        {/*   {_.startCase(intl.formatMessage({ id: "register_now" }))} */}
        {/* </Link> */}
      </div>
    </div>
  );
};
SignInForm.propTypes = {
  intl: PropTypes.object,
  history: PropTypes.object,
  toggleForm: PropTypes.func,
  isloading: PropTypes.bool,
};

const ResetPasswordForm = ({ intl, toggleForm }) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  function resetPassword() {
    setLoading(true);
    forgotPassword({ email })
      .then(() => {
        notifyResetEmailSent(email);
      })
      .catch((error) => {
        errorHandle({ error, form: { email: { verbose: "Email" } } });
      })
      .finally(() => setLoading(false));
  }

  return (
    <div className="ResetPasswordForm">
      {loading && <Loading intl={intl} />}
      <div>{_.upperFirst(intl.formatMessage({ id: "reset" }))}</div>
      <p>{_.upperFirst(intl.formatMessage({ id: "sign_in_message" }))}</p>
      <Form>
        <FormGroup className="ResetPasswordForm__form">
          <div className="ResetPasswordForm__form__formLabel">
            {_.upperFirst(intl.formatMessage({ id: "email" }))}
          </div>
          <FormControl
            name="Email"
            value={email}
            onChange={setEmail}
            placeholder="xxx@gmail.com"
          />
          <Button size="lg" appearance="primary" block onClick={resetPassword}>
            {_.upperFirst(intl.formatMessage({ id: "reset_password" }))}
          </Button>
          <div
            className="ResetPasswordForm__form__backBtn"
            onClick={() => toggleForm("in")}>
            {_.upperFirst(intl.formatMessage({ id: "back_sign_in" }))}
          </div>
        </FormGroup>
      </Form>
    </div>
  );
};
ResetPasswordForm.propTypes = {
  intl: PropTypes.object,
  history: PropTypes.object,
  toggleForm: PropTypes.func,
};

const SignInAndUpNew = ({ intl }) => {
  const [imgUrl, setImgUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState("in");
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const qview = query.get("view");
  useEffect(() => {
    if (!qview) return;
    setView(qview);
  }, [qview]);

  function renderContainer() {
    switch (view) {
      case "up":
        return <SignUpForm intl={intl} toggleForm={(view) => setView(view)} />;
      case "in":
        return <SignInForm intl={intl} toggleForm={(view) => setView(view)} />;
      case "forgot":
        return (
          <ResetPasswordForm intl={intl} toggleForm={(view) => setView(view)} />
        );
      default:
        break;
    }
  }

  useEffect(() => {
    const bg =
      "https://sisistaticassets.s3.ap-southeast-1.amazonaws.com/assets/signup/bg.jpg";
    const tempImg = new Image();
    tempImg.src = bg;

    if (!loading) {
      tempImg.onload = () => {
        setImgUrl(bg);
        setLoading(true);
      };
    }
  }, []);

  return (
    <div className="SignInAndUp">
      <img className="SignInAndUp__backgroundImg" src={imgUrl} alt="" />
      <div className="SignInAndUp__container">
        <img className="SignInAndUp__container__logo" src={LOGO} />
        <div className="SignInAndUp__container__formContainer">
          <div className="SignInAndUp__container__formContainer__topImgCntr">
            <img
              className="SignInAndUp__container__formContainer__topImgCntr__topImg"
              src={topImg}
            />
          </div>
          {renderContainer()}
        </div>
      </div>
    </div>
  );
};
SignInAndUpNew.propTypes = {
  intl: PropTypes.object,
  changeLang: PropTypes.func,
};
const mapDispatchToProps = (dispatch) => {
  return {
    changeLang: (lang) => {
      dispatch(changeLang({ lang }));
    },
  };
};
export default connect(
  null,
  mapDispatchToProps
)(injectIntl(withRouter(SignInAndUpNew)));
