import React, { useCallback, useEffect, useState } from "react";
import { injectIntl } from "react-intl";

import { emailIsVerified, signOut } from "@src/tools/auth_tools";
import _ from "lodash";
import { resendVerifyEmail } from "@src/actions/Admin/Users";
import { createUseStyles } from "react-jss";
import { modalGenericStyles, variables } from "@src/jsssetup";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import { CustomButton } from "../common/buttons";
import { showAuthModal } from "@src/actions/Auth";

const emailVerifyModalStyles = createUseStyles({
  ...modalGenericStyles,
  EmailVerifyModal: {
    ...modalGenericStyles.modal,
  },
  modal: {
    ...modalGenericStyles.card,
    background: variables.colors.easy.lightOrange4,
    padding: `calc(${variables.normal_gap} * 3) 0`,
    maxWidth: "50rem",
  },
  body: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    gridGap: variables.normal_gap,
    background: "white",
    justifyItems: "center",
    padding: `${variables.double_gap} calc(${variables.normal_gap} * 7)`,
  },
  loadingIcon: {
    color: variables.colors.easy.orange,
    fontSize: "xxx-large",
    alignSelf: "center",
  },
  mailIcon: {
    color: variables.colors.easy.orange,
    fontSize: "xx-large",
  },
  header: {
    color: variables.colors.easy.orange,
    fontSize: "x-large",
    fontWeight: "bold",
  },
  p: {
    color: variables.colors.text.dark,
    fontWeight: "bold",
    textAlign: "center",
  },
  email: {
    color: variables.colors.easy.orange,
    fontWeight: "bold",
    fontSize: "large",
  },
  actions: {
    display: "grid",
    gridTemplateColumns: "repeat(3, max-content)",
    gridGap: variables.normal_gap,
    width: "70%",
    padding: [variables.normal_gap, 0],
  },
  [`@media ${variables.media.smallscreen}`]: {},
  [`@media ${variables.media.bigscreen}`]: {},
});
var EmailVerifyModal = () => {
  const [loading, setLoading] = useState(false);
  const [emailVerified, setEmailVerified] = useState(emailIsVerified());
  const classes = emailVerifyModalStyles();
  const userMeta = useSelector((state) => state.userMeta);
  const signInModalVisible = useSelector((state) => state.authModal.show);

  const dispatch = useDispatch();
  const debouncedResendVerifyEmail = useCallback(
    _.debounce(
      () => {
        dispatch(resendVerifyEmail(() => setLoading(false)));
      },
      1000,
      {
        leading: true,
        trailing: false,
      }
    ),
    [dispatch]
  );
  function onResendVerifyEmail() {
    setLoading(true);
    debouncedResendVerifyEmail();
  }
  const onSignIn = useCallback(
    async () => await dispatch(showAuthModal()),
    [dispatch]
  );

  async function onMyEmailIsVerified() {
    await onSignIn();
  }

  useEffect(() => {
    if (emailVerified) {
      return;
    }

    if (signInModalVisible) {
      return;
    }

    setEmailVerified(emailIsVerified());
  }, [signInModalVisible]);

  return emailVerified ? null : (
    <div className={classes.EmailVerifyModal}>
      <div className={classes.modal}>
        <div className={classes.body}>
          {loading ? (
            <Icon
              icon="line-md:loading-twotone-loop"
              className={classes.loadingIcon}
            />
          ) : (
            <React.Fragment>
              <Icon icon="ic:baseline-email" className={classes.mailIcon} />
              <div className={classes.header}>Verify Your Email Address</div>
              <p className={classes.p}>
                Thank you for registering!
                <br /> We just need you to verify your email address to complete
                setting up your account.
              </p>
              <div className={classes.email}>
                {_.get(userMeta, "user.email") || ""}
              </div>
              <p className={classes.p}>
                Be sure to also check you junk email folder. In case you haven't
                an email click the button below to resend the verification
                email.
              </p>
              <p className={classes.p}>
                Once you verify your email you can refresh this page.
              </p>
              <div className={classes.actions}>
                <CustomButton onClick={onMyEmailIsVerified}>
                  My email is verified
                </CustomButton>
                <CustomButton onClick={onResendVerifyEmail}>
                  Resend Verification Email
                </CustomButton>
                <CustomButton onClick={() => signOut()}>Sign Out</CustomButton>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};
EmailVerifyModal.defaultProps = {};
EmailVerifyModal.propTypes = {};
EmailVerifyModal = injectIntl(EmailVerifyModal);

export { EmailVerifyModal };
