// ========================== BASIC ACTIONS ==========================
export const BIDREQUEST_INIT = "BIDREQUEST_INIT";

// ========================= BID REQUEST DATA ========================
export const BIDREQUEST_FETCHING_REQUEST = "BIDREQUEST_FETCHING_REQUEST";
export const BIDREQUEST_FETCHED_REQUEST = "BIDREQUEST_FETCHED_REQUEST";
export const BIDREQUEST_SET_REQUEST_DATA = "BIDREQUEST_SET_REQUEST_DATA";
// ========================== BID OFFER DATA =========================
export const BIDREQUEST_SET_OFFER_DATA = "BIDREQUEST_SET_OFFER_DATA";
// =========================== SRV PRICING ===========================
export const BIDREQUEST_PRICING_TYPE = "BIDREQUEST_PRICING_TYPE";
export const BIDREQUEST_INIT_SRV_PRICING = "BIDREQUEST_INIT_SRV_PRICING";
export const BIDREQUEST_CHANGE_SRV_PRICING = "BIDREQUEST_CHANGE_SRV_PRICING";
export const BIDREQUEST_RESET_SRV_PRICING = "BIDREQUEST_CHANGE_SRV_PRICING";
// ========================= RESPONSE MESSAGE ========================
export const BIDREQUEST_SHOW_RESPONSE_MSG_MODAL =
  "BIDREQUEST_SHOW_RESPONSE_MSG_MODAL";
export const BIDREQUEST_HIDE_RESPONSE_MSG_MODAL =
  "BIDREQUEST_HIDE_RESPONSE_MSG_MODAL";
export const BIDREQUEST_CHANGE_OFFER_TERMS_MSG =
  "BIDREQUEST_CHANGE_OFFER_TERMS_MSG";
// =========================== OFFER TERMS ===========================
export const BIDREQUEST_CHANGE_OFFER_TERMS = "BIDREQUEST_CHANGE_OFFER_TERMS";
// ========================= SERVICE DETAILS =========================
export const BIDREQUEST_VIEW_FLIGHT_DETAILS = "BIDREQUEST_VIEW_FLIGHT_DETAILS";
export const BIDREQUEST_HIDE_FLIGHT_DETAILS = "BIDREQUEST_HIDE_FLIGHT_DETAILS";
export const BIDREQUEST_VIEW_ACC_DETAILS = "BIDREQUEST_VIEW_ACC_DETAILS";
export const BIDREQUEST_HIDE_ACC_DETAILS = "BIDREQUEST_HIDE_ACC_DETAILS";
export const BIDREQUEST_VIEW_TRF_DETAILS = "BIDREQUEST_VIEW_TRF_DETAILS";
export const BIDREQUEST_HIDE_TRF_DETAILS = "BIDREQUEST_HIDE_TRF_DETAILS";
// ========================= SERVICE COMMENTS ========================
export const BIDREQUEST_ADD_SERVICE_COMMENT = "BIDREQUEST_ADD_SERVICE_COMMENT";
export const BIDREQUEST_REMOVE_SERVICE_COMMENT =
  "BIDREQUEST_REMOVE_SERVICE_COMMENT";
// ====================== SERVICE BOOKING TERMS ======================
export const BIDREQUEST_CHANGE_SERVICE_BOOKING_TERMS =
  "BIDREQUEST_CHANGE_SERVICE_BOOKING_TERMS";
// =================== SERVICE CANCELLATION POLICY ===================
export const BIDREQUEST_CHANGE_SRV_BOOKING_CXL_POLICY =
  "BIDREQUEST_CHANGE_SRV_BOOKING_CXL_POLICY";
// ================ COMMON REPLACEABLE SERVICE ACTIONS ===============
export const REPLACEABLE_SERVICE_SAVING = "REPLACEABLE_SERVICE_SAVING";
export const REPLACEABLE_SERVICE_SAVED = "REPLACEABLE_SERVICE_SAVED";
// ==================== REPLACEABLE ACCOMMODATIONS ===================
export const BIDREQUEST_SHOW_REPLACEABLE_ACC_MODAL =
  "BIDREQUEST_SHOW_REPLACEABLE_ACC_MODAL";
export const BIDREQUEST_HIDE_REPLACEABLE_ACC_MODAL =
  "BIDREQUEST_HIDE_REPLACEABLE_ACC_MODAL";
export const BIDREQUEST_SAVE_REPLACEABLE_ACC =
  "BIDREQUEST_SAVE_REPLACEABLE_ACC";
export const BIDREQUEST_REVERT_REPLACEABLE_ACC =
  "BIDREQUEST_REVERT_REPLACEABLE_ACC";
// ======================== REPLACEABLE TRP =======================
export const BIDREQUEST_SHOW_REPLACEABLE_TRP_MODAL =
  "BIDREQUEST_SHOW_REPLACEABLE_TRP_MODAL";
export const BIDREQUEST_HIDE_REPLACEABLE_TRP_MODAL =
  "BIDREQUEST_HIDE_REPLACEABLE_TRP_MODAL";
export const BIDREQUEST_SAVE_REPLACEABLE_TRP =
  "BIDREQUEST_SAVE_REPLACEABLE_TRP";
export const BIDREQUEST_REVERT_REPLACEABLE_TRP =
  "BIDREQUEST_REVERT_REPLACEABLE_TRP";
// ========================= REPLACEABLE TRF =========================
export const BIDREQUEST_SHOW_REPLACEABLE_TRF_MODAL =
  "BIDREQUEST_SHOW_REPLACEABLE_TRF_MODAL";
export const BIDREQUEST_HIDE_REPLACEABLE_TRF_MODAL =
  "BIDREQUEST_HIDE_REPLACEABLE_TRF_MODAL";
export const BIDREQUEST_SAVE_REPLACEABLE_TRF =
  "BIDREQUEST_SAVE_REPLACEABLE_TRF";
export const BIDREQUEST_REVERT_REPLACEABLE_TRF =
  "BIDREQUEST_REVERT_REPLACEABLE_TRF";
// ========================= SERVICE FILTERS =========================
export const BIDREQUEST_SRV_FILTERS_CHANGE = "BIDREQUEST_SRV_FILTERS_CHANGE";
// ========================= SERVICE TYPE FILTERS =========================
export const BIDREQUEST_SRV_RQ_TYPE_FILTERS_CHANGE =
  "BIDREQUEST_SRV_RQ_TYPE_FILTERS_CHANGE";
// =========================== SAVE ACTIONS ==========================
export const BIDREQUEST_REQUEST_SAVE_OFFER = "BIDREQUEST_REQUEST_SAVE_OFFER";
export const BIDREQUEST_CANCEL_SAVE_OFFER = "BIDREQUEST_CANCEL_SAVE_OFFER";

export const BIDREQUEST_SAVING_OFFER = "BIDREQUEST_SAVING_OFFER";
export const BIDREQUEST_SAVED_OFFER = "BIDREQUEST_SAVED_OFFER";

export const BIDREQUEST_OFFER_DATA_UPDATE = "BIDREQUEST_OFFER_DATA_UPDATE";
// =========================== SEND ACTIONS ==========================
export const BIDREQUEST_OFFER_REQUEST_SEND = "BIDREQUEST_OFFER_REQUEST_SEND";
export const BIDREQUEST_CANCEL_SEND_OFFER = "BIDREQUEST_CANCEL_SEND_OFFER";

export const BIDREQUEST_SENDING_OFFER = "BIDREQUEST_SENDING_OFFER";
export const BIDREQUEST_SENT_OFFER = "BIDREQUEST_SENT_OFFER";
