//=====================================LIST====================================
export const INVITATIONS_SEND_LOADING_LIST = "INVITATIONS_SEND_LOADING_LIST";
export const INVITATIONS_SEND_FETCH_LIST_DATA =
  "INVITATIONS_SEND_FETCH_LIST_DATA";
export const INVITATIONS_SEND_IDLE_LIST = "INVITATIONS_SEND_IDLE_LIST";
export const INVITATIONS_SEND_VIEW_TYPE_CHANGE =
  "INVITATIONS_SEND_VIEW_TYPE_CHANGE";
//====================================FILTER===================================
export const INVITATIONS_SEND_INITIALIZE_LIST_FILTER_FORM =
  "INVITATIONS_SEND_INITIALIZE_LIST_FILTER_FORM";
export const INVITATIONS_SEND_UPDATE_LIST_FILTER_FORM =
  "INVITATIONS_SEND_UPDATE_LIST_FILTER_FORM";
export const INVITATIONS_SEND_APPLY_LIST_FILTER =
  "INVITATIONS_SEND_APPLY_LIST_FILTER";
export const INVITATIONS_SEND_RESET_LIST_FILTER_FORM =
  "INVITATIONS_SEND_RESET_LIST_FILTER_FORM";
//======================================COLUMNS=================================
export const INVITATIONS_SEND_APPLY_LIST_COLUMNS_FORM =
  "INVITATIONS_SEND_APPLY_LIST_COLUMNS_FORM";
