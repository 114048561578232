import _ from "lodash";
import {
  getSelectedAddonData,
  generateAddOnSrvCode,
  getAddonSearchStatus,
} from "./addon_selectors";
import {
  getCurrencies,
  getGlobalCurrencySelector,
  unConvertAmount,
} from "@src/selectors/Financial";

export const getRooms = (acc, refundable = null) => {
  const filterer = (room) => {
    const sel = room.selected;
    if (refundable == true) {
      return (
        sel && room.cancellation_policies.some((c) => c.type == "refundable")
      );
    } else if (refundable == false) {
      return (
        sel && room.cancellation_policies.every((c) => c.type !== "refundable")
      );
    } else {
      return sel;
    }
  };

  var rooms = _.get(acc, "detailed_rooms", []).filter((r) => filterer(r));

  if (!rooms.length) {
    rooms = _.get(acc, "rooms", []).filter((r) => filterer(r));
  }

  return rooms;
};

export const getAccPrice = (acc, refundable = null) => {
  const prices = [
    ...new Set(
      getRooms(acc, refundable).map(
        (r) => `${r.id}___${r.price.currency}___${r.price.value}`
      )
    ),
  ].map((p) => [p.split("___")[1], parseFloat(p.split("___")[2])]);

  return prices.reduce((a, b) => a + b[1], 0);
};
