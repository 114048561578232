import { Form, SelectPicker, Loader } from "rsuite";

import {
  hideGuestModal,
  saveGuestsInfo,
  guestsUpdate,
  saveTrip,
  guestsAreValid,
} from "@src/actions/Project/TripPlanner";

import { guestsData } from "@src/api/Shares";
import { errorHandle as commonErrorHandle } from "@src/tools/common_tools";

import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { formStyles, modalGenericStyles, variables } from "@src/jsssetup";

import {
  guestValidationSchema,
  tripGuestsValidationSchema,
} from "@src/reducers/Project/TripPlanner/GuestReducers.js";

import AirlineAutoComplete from "@src/pages/Project/SiSiBid/Components/AirlineAutoComplete.js";
import { getSetupFormDataSelector } from "@src/selectors/Project/TripPlanner";
import { CustomButton } from "@src/components/common/buttons";
import { Formik, useFormikContext } from "formik";
import {
  NormalDatePicker,
  NormalInputField,
  NormalSelectField,
} from "@src/components/forms";
import { DateTime, Duration } from "luxon";
import { toast } from "react-toastify";
import { v4 } from "uuid";
import {
  getSrvsTypeForBookingSelector,
  getSrvsTypeForOptionBookingSelector,
} from "@src/selectors/Project/TripPlanner/prebook/selected_services";
import { tripUpdate } from "@src/api";
import { getRoomPaxSetupSelector } from "@src/selectors/Project/TripPlanner/guest_selector";

const GuestsSelect = ({
  idx,
  existingGuests,
  guest_type,
  srv_types_for_booking,
}) => {
  const { setFieldValue } = useFormikContext();
  const { nationality } = useSelector((state) =>
    getSetupFormDataSelector(state)
  );

  const newGuests = existingGuests
    .filter((e) => e.guest_type == guest_type)
    .map((item) => ({
      ...item,
      label: `${item.first_name} ${item.last_name} (${item.email})`,
    }));

  const guestKey = guest_type === "ADT" ? "adults" : "children";
  return (
    <SelectPicker
      data={newGuests}
      valueKey="id"
      onChange={(value) => {
        let guest_info = _.find(existingGuests, (e) => e.id == value);
        if (!guest_info.guest_uid) {
          guest_info.guest_uid = v4();
        }

        const commonKwargs = {
          room_uids: [],
          room_leader: false,
          group_leader: false,
          nationality,
        };

        var guestTemplate = {};
        if (guest_type === "ADT") {
          const gInfo = _.cloneDeep(guest_info);
          // Convert date of birth to an empty string if it is null
          // so that it can pass initial validation from yup.
          if (!gInfo.date_of_birth) {
            gInfo.date_of_birth = "";
          }
          guestTemplate = guestValidationSchema({ srv_types_for_booking }).cast(
            {
              ...gInfo,
              ...commonKwargs,
              guest_type: "ADT",
              group_leader: idx === 0,
              room_leader: idx === 0,
            }
          );
        } else {
          guestTemplate = guestValidationSchema({ srv_types_for_booking }).cast(
            {
              ...guest_info,
              ...commonKwargs,
              guest_type: "CHD",
            }
          );
        }
        setFieldValue(`${guestKey}.${idx}`, guestTemplate);
        toast.success("Guest loaded successfully", { autoClose: 3000 });
      }}
    />
  );
};
GuestsSelect.defaultProps = {
  existingGuests: [],
  srv_types_for_booking: [],
};
GuestsSelect.propTypes = {
  idx: PropTypes.number.isRequired,
  guest_type: PropTypes.oneOf(["ADT", "CHD"]).isRequired,
  existingGuests: PropTypes.array.isRequired,
  srv_types_for_booking: PropTypes.array.isRequired,
};

const roomsSelectorStyles = createUseStyles({
  roomChecks: {
    display: "grid",
    gridGap: variables.normal_gap,
    padding: variables.normal_gap,
    border: `1px solid ${variables.colors.borders.dark}`,
    borderRadius: "5px",
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  roomCheckbox: {
    "& ul": { marginTop: "-20px" },
    "& li": {
      listStyleType: "none",
    },
  },
});
const RoomsSelector = ({ rooms, guestIdx, guestType = "ADT" }) => {
  const { values, setFieldValue } = useFormikContext();

  const classes = roomsSelectorStyles();
  const guestKey = guestType === "ADT" ? "adults" : "children";
  return (
    <div>
      <h6>Rooms</h6>
      <div className={classes.roomChecks}>
        {rooms.map((room, ridx) => {
          const roomIdValue = `destOrder___${room.destOrder}___${room.uid}`;
          const guest_room_uids = values[guestKey][guestIdx]?.room_uids || [];

          const otherSameDestRoomSelected =
            guest_room_uids.some((uid) =>
              uid.includes(`destOrder___${room.destOrder}`)
            ) && !guest_room_uids.includes(roomIdValue);
          const noRoomCapacity =
            values[guestKey].filter((g) =>
              (g?.room_uids || []).includes(roomIdValue)
            ).length >= room.pax[guestKey] &&
            !guest_room_uids.includes(roomIdValue);

          const formikKey = `${guestKey}.${guestIdx}.room_uids`;
          return (
            <div className={classes.roomCheckbox} key={ridx}>
              <input
                type="checkbox"
                id={formikKey}
                name={formikKey}
                value={roomIdValue}
                checked={guest_room_uids.includes(roomIdValue)}
                disabled={otherSameDestRoomSelected || noRoomCapacity}
                onChange={(e) => {
                  const roomUidValue =
                    values[guestKey][guestIdx]?.room_uids || [];
                  const tValue = e.target.value;
                  var value = [];
                  if (e.target.checked) {
                    value = [...new Set([...roomUidValue, tValue])];
                  } else {
                    value = roomUidValue.filter((uid) => uid !== tValue);
                  }
                  setFieldValue(formikKey, value);
                }}
              />
              <label htmlFor={room.uid}>
                <ul>
                  <li>{room.destinationName}</li>
                  <li>{room.metadata.name}</li>
                  <li>{room.name}</li>
                  <li>
                    (Adults: {room.pax.adults}, Children: {room.pax.children})
                  </li>
                </ul>
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};
RoomsSelector.defaultProps = {
  guestType: "ADT",
};
RoomsSelector.propTypes = {
  rooms: PropTypes.array.isRequired,
  guestIdx: PropTypes.number.isRequired,
  guestType: PropTypes.oneOf(["ADT", "CHD"]).isRequired,
};

const guestTitleOptions = [
  ["", "------"],
  ["MR", "Mr."],
  ["MRS", "Mrs."],
  ["MS", "Ms"],
];

const genderOptions = [
  ["", "------"],
  ["MA", "Male"],
  ["FE", "Female"],
];

const identificationOptions = [
  ["", "------"],
  ["ID", "Identity Card"],
  ["PA", "Passport"],
];

const adultFormStyles = createUseStyles({
  AdultForm: { display: "grid" },
  basic_info: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridGap: variables.normal_gap,
  },
  frequent_flyer_data: {
    "display": "grid",
    "gridTemplateColumns": "repeat(2, 1fr)",
    "gridGap": variables.normal_gap,
    "& .rs-input-group": {
      width: "unset !important",
    },
  },
  col2: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: variables.normal_gap,
  },
  col3: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridGap: variables.normal_gap,
  },
  roomChecks: {
    display: "grid",
    gridGap: variables.normal_gap,
    padding: variables.normal_gap,
    border: `1px solid ${variables.colors.borders.dark}`,
    borderRadius: "5px",
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  roomCheckbox: {
    "& ul": { marginTop: "-20px" },
    "& li": { listStyleType: "none" },
  },
});
const AdultForm = ({ idx, rooms }) => {
  const classes = adultFormStyles();
  return (
    <div className={classes.AdultForm}>
      <div className={classes.basic_info}>
        <NormalSelectField
          name={`adults.${idx}.guest_title`}
          label="Title"
          options={guestTitleOptions}
          highlightErrors={true}
        />
        <NormalInputField
          name={`adults.${idx}.first_name`}
          label="First Name"
          highlightErrors={true}
        />
        <NormalInputField
          name={`adults.${idx}.last_name`}
          label="Last Name"
          highlightErrors={true}
          withError={true}
        />
        <NormalSelectField
          name={`adults.${idx}.gender`}
          label="Gender"
          options={genderOptions}
          highlightErrors={true}
        />
      </div>
      <div className={classes.col3}>
        <NormalSelectField
          name={`adults.${idx}.identification_type`}
          label="Id Type"
          highlightErrors={true}
          options={identificationOptions}
        />
        <NormalInputField
          name={`adults.${idx}.identification_code`}
          label="Id Code"
          highlightErrors={true}
          withError={true}
        />
        <NormalDatePicker
          name={`adults.${idx}.identification_expiry_date`}
          label="Id. Expiry Date"
          highlightErrors={true}
          minAvailableDate={DateTime.now().plus({ days: 1 }).toISODate()}
          maxAvailableDate={DateTime.now().plus({ years: 10 }).toISODate()}
          showYearDropdown={true}
          withError={true}
        />
      </div>
      <div className={classes.col3}>
        <NormalDatePicker
          name={`adults.${idx}.date_of_birth`}
          label="Date of Birth"
          highlightErrors={true}
          maxAvailableDate={DateTime.now().minus({ years: 12 }).toISODate()}
          showYearDropdown={true}
          withError={true}
        />
        <NormalInputField
          name={`adults.${idx}.phone`}
          label="Phone"
          highlightErrors={true}
        />
        <NormalInputField
          name={`adults.${idx}.email`}
          label="Email"
          highlightErrors={true}
        />
      </div>
      {rooms.length > 0 && (
        <RoomsSelector guestType="ADT" guestIdx={idx} rooms={rooms} />
      )}
      {/* <NormalSelectField name={`adults.${idx}.room_uids`} label="Room" /> */}
      <div className={classes.col2}>
        <NormalSelectField
          name={`adults.${idx}.room_leader`}
          label="Room Leader"
          options={[
            [true, "Yes"],
            [false, "No"],
          ]}
        />
        <NormalSelectField
          name={`adults.${idx}.group_leader`}
          label="Group Leader"
          options={[
            [true, "Yes"],
            [false, "No"],
          ]}
        />
      </div>
    </div>
  );
};
AdultForm.defaultProps = { rooms: [] };
AdultForm.propTypes = {
  idx: PropTypes.number.isRequired,
  rooms: PropTypes.array.isRequired,
};

const childFormStyles = createUseStyles({
  ChildForm: {
    display: "grid",
  },
  basic_info: {
    display: "grid",
    gridTemplateColumns: "max-content 1fr 1fr max-content",
    gridGap: variables.normal_gap,
  },
  col3: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridGap: variables.normal_gap,
  },
});
const ChildForm = ({ idx, rooms, earliestCheckIn }) => {
  const classes = childFormStyles();
  const room_pax_setup = useSelector((state) => getRoomPaxSetupSelector(state));
  const children_ages = _.flatten(
    room_pax_setup.map((room) => room.children_ages)
  );
  const age = children_ages[idx];
  const max_date = DateTime.fromISO(earliestCheckIn)
    .minus(Duration.fromObject({ years: age }))
    .toISODate();

  const min_date = DateTime.fromISO(max_date)
    .minus(Duration.fromObject({ years: 1 }))
    .toISODate();

  return (
    <div className={classes.ChildForm}>
      <div className={classes.basic_info}>
        <NormalSelectField
          name={`children.${idx}.guest_title`}
          label="Title"
          options={guestTitleOptions}
          highlightErrors={true}
        />
        <NormalInputField
          name={`children.${idx}.first_name`}
          label="First Name"
          highlightErrors={true}
        />
        <NormalInputField
          name={`children.${idx}.last_name`}
          label="Last Name"
          highlightErrors={true}
          withError={true}
        />
        <NormalSelectField
          name={`children.${idx}.gender`}
          label="Gender"
          options={genderOptions}
          highlightErrors={true}
        />
      </div>
      <div className={classes.col3}>
        <NormalSelectField
          name={`children.${idx}.identification_type`}
          label="Id Type"
          highlightErrors={true}
          options={identificationOptions}
        />
        <NormalDatePicker
          name={`children.${idx}.identification_expiry_date`}
          label="Id. Expiry Date"
          highlightErrors={true}
          withError={true}
          minAvailableDate={DateTime.now().plus({ days: 1 }).toISODate()}
          maxAvailableDate={DateTime.now().plus({ years: 10 }).toISODate()}
          showYearDropdown={true}
        />
        <NormalInputField
          name={`children.${idx}.identification_code`}
          label="Id Code"
          highlightErrors={true}
          withError={true}
        />
        <NormalDatePicker
          name={`children.${idx}.date_of_birth`}
          label="Date of Birth"
          highlightErrors={true}
          withError={true}
          showYearDropdown={true}
          minAvailableDate={min_date}
          maxAvailableDate={max_date}
        />
      </div>
      {rooms.length > 0 && (
        <RoomsSelector guestType="CHD" guestIdx={idx} rooms={rooms} />
      )}
    </div>
  );
};
ChildForm.propTypes = {
  idx: PropTypes.number.isRequired,
  rooms: PropTypes.array.isRequired,
  earliestCheckIn: PropTypes.string,
};

const tripGuestsModalStyles = createUseStyles({
  ...modalGenericStyles,
  TripGuestsModal: modalGenericStyles.modal,
  card: { ...modalGenericStyles.card, width: "90vw", maxWidth: "unset" },
  form: { ...formStyles.form, display: "grid", gridGap: variables.normal_gap },
  forms: {
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  panel: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    alignItems: "center",
    justifyContent: "space-between",
    gridTemplateColumns: "max-content max-content",
    paddingBottom: variables.half_gap,
    borderBottom: `2px solid ${variables.colors.easy.lightOrange}`,
  },
});
const TripGuestsModal = ({ onClose }) => {
  const [loading, setLoading] = useState(false);

  const {
    info,
    rooms,
    srv_types_for_booking,
    trip_instance_id,
    earliestCheckIn,
  } = useSelector((state) => {
    const info = state.tripPlannerGuestsInfo;
    const destinations = state.tripPlannerDestinations;
    const trip_instance = state.tripPlannerInstanceData;

    const acc_prebook_info = state.tripPlannerPrebookAccInfo;

    const earliestCheckIn = _.flatten(Object.values(acc_prebook_info))
      .map((htl) => htl.operational_data.check_in)
      .sort()[0];

    const rooms = _.flatten(
      Object.entries(acc_prebook_info).map(([destOrder, prebook]) =>
        (prebook?.rooms || []).map((room) => {
          const destinationName =
            destinations.find(
              (dest) => dest.order.toString() === destOrder.toString()
            )?.name_en || "N/A";

          return {
            ...room,
            destOrder,
            metadata: prebook.metadata,
            destinationName,
          };
        })
      )
    );

    const srv_types_for_booking = [
      ...new Set([
        ...getSrvsTypeForBookingSelector(state),
        ...getSrvsTypeForOptionBookingSelector(state),
      ]),
    ];

    return {
      info,
      rooms,
      srv_types_for_booking,
      trip_instance_id: trip_instance.id,
      earliestCheckIn,
    };
  });

  const dispatch = useDispatch();
  const onValidGuests = useCallback(
    () => dispatch(guestsAreValid()),
    [dispatch]
  );
  const onSave = useCallback(
    async ({ guestsInfo }) => {
      setLoading(true);
      var saveId = trip_instance_id;
      if (!saveId) {
        const save_result = await dispatch(saveTrip({ nonUi: true }));
        if (save_result) {
          saveId = save_result;
          toast.success("Trip information saved", { autoClose: 3000 });
        } else {
          toast.error("Failed to save trip information", { autoClose: 3000 });
          setLoading(false);
          return false;
        }
      }

      try {
        await tripUpdate({ guest_information: guestsInfo }, saveId);
      } catch (error) {
        toast.error("Failed to save guest information", { autoClose: 3000 });
        setLoading(false);
        return false;
      }
      toast.success("Guest information saved", { autoClose: 3000 });
      setLoading(false);
      return true;
    },
    [dispatch]
  );
  const onGuestsUpdate = useCallback(
    async ({ guestsInfo }) => await dispatch(guestsUpdate({ guestsInfo })),
    [dispatch]
  );

  const classes = tripGuestsModalStyles();
  const [existingGuests, setExistingGuests] = useState([]);

  const fetchGuestsList = async () => {
    try {
      const data = await guestsData({ unique: true, limit: 100 });
      setExistingGuests(data.results);
    } catch (error) {
      error.ovrdErrHandle && error.ovrdErrHandle();
      commonErrorHandle({ error });
    }
  };

  useEffect(() => {
    fetchGuestsList();
  }, []);

  const children = _.get(info, "children", []);
  return (
    <div className={classes.TripGuestsModal}>
      <Formik
        initialValues={info}
        validationSchema={tripGuestsValidationSchema({ srv_types_for_booking })}
        onSubmit={async (values) => {
          await onGuestsUpdate({ guestsInfo: values });
          await onValidGuests();
          await onSave({ guestsInfo: values });
          onClose();
        }}>
        {({ errors, submitForm }) => {
          return (
            <div className={classes.card}>
              {loading && (
                <Loader size="lg" content="Please wait..." vertical backdrop />
              )}
              <div className={classes.cardHeader}>
                <div>Guests Information</div>
              </div>
              <div className={classes.cardBody}>
                <Form>
                  <h5>Adults</h5>
                  <div className={classes.forms}>
                    {(info?.adults ?? []).map((g, idx) => (
                      <div key={idx} className={classes.form}>
                        <div className={classes.panel}>
                          <h6>{`Adult ${idx + 1}`}</h6>{" "}
                          <GuestsSelect
                            guest_type="ADT"
                            idx={idx}
                            existingGuests={existingGuests}
                            form={g}
                            srv_types_for_booking={srv_types_for_booking}
                          />
                        </div>
                        <AdultForm idx={idx} rooms={rooms} />
                      </div>
                    ))}
                  </div>
                  {children.length > 0 && <h5>Children</h5>}
                  <div className={classes.forms}>
                    {children.length > 0 && (
                      <div className="guestForms">
                        {info.children.map((g, idx) => (
                          <div key={idx} className={classes.form}>
                            <div className={classes.panel}>
                              <h6>{`Child ${idx + 1}`}</h6>{" "}
                              <GuestsSelect
                                guest_type="CHD"
                                form={g}
                                idx={idx}
                                existingGuests={existingGuests}
                                srv_types_for_booking={srv_types_for_booking}
                              />
                            </div>
                            <ChildForm
                              idx={idx}
                              rooms={rooms}
                              earliestCheckIn={earliestCheckIn}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </Form>
              </div>
              <div className={classes.cardActions}>
                <CustomButton appearance="ghost" onClick={onClose}>
                  Close
                </CustomButton>
                <CustomButton
                  onClick={() => {
                    if (!_.isEmpty(errors)) {
                      const errorsList = [];
                      Object.entries(errors).forEach(
                        ([guestType, guestsErrors]) => {
                          if (typeof guestsErrors === "string") {
                            errorsList.push(
                              <li key={v4()}>
                                {guestType === "adults"
                                  ? `Adults: ${guestsErrors}`
                                  : `Child ${guestsErrors}`}
                              </li>
                            );
                            return;
                          }
                          (guestsErrors || []).forEach(
                            (guestErrors, guestIdx) =>
                              Object.entries(guestErrors || {}).map(
                                ([key, value], errorIdx) => {
                                  errorsList.push(
                                    <li key={v4()}>
                                      {guestType === "adults"
                                        ? `Adult ${guestIdx + 1}`
                                        : `Child ${guestIdx + 1}`}
                                      : {value}
                                    </li>
                                  );
                                }
                              )
                          );
                        }
                      );
                      toast.error(
                        <p>
                          <ul>{errorsList}</ul>
                        </p>,
                        { autoClose: 6000 }
                      );
                      return;
                    }
                    submitForm();
                  }}>
                  Save
                </CustomButton>
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};
TripGuestsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default TripGuestsModal;
