import _ from "lodash";
import axios from "axios";
import { getAuthHeaders } from "../../request";
import { SISI_API_URL } from "@src/api";

export async function createProductTerms({ payload }) {
  const auth_headers = getAuthHeaders();

  try {
    const result = await axios.post(
      `${SISI_API_URL}/product-terms-and-conditions/`,
      payload,
      {
        headers: { ...auth_headers },
      }
    );
    return result;
  } catch (error) {
    const errors = _.get(error, "response.data", []);
    return { errors };
  }
}

export async function fetchProductTermsProm(params) {
  const auth_headers = getAuthHeaders();

  return axios.get(`${SISI_API_URL}/product-terms-and-conditions/`, {
    params,
    headers: { ...auth_headers },
  });
}

export async function fetchProductTerms(params) {
  const auth_headers = getAuthHeaders();

  try {
    const result = await axios.get(
      `${SISI_API_URL}/product-terms-and-conditions/`,
      {
        params,
        headers: { ...auth_headers },
      }
    );
    return _.get(result, "data", {});
  } catch (error) {
    return null;
  }
}

export async function changeProductTerms({ payload, id }) {
  const auth_headers = getAuthHeaders();

  try {
    const result = await axios.put(
      `${SISI_API_URL}/product-terms-and-conditions/${id}/`,
      { ...payload },
      {
        headers: auth_headers,
      }
    );
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function retrieveProductTerms(id) {
  const auth_headers = getAuthHeaders();

  try {
    const result = await axios.get(
      `${SISI_API_URL}/product-terms-and-conditions/${id}/`,
      {
        headers: auth_headers,
      }
    );

    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function cloneProductTerms(id) {
  const auth_headers = getAuthHeaders();

  try {
    const result = await axios.post(
      `${SISI_API_URL}/product-terms-and-conditions/clone/${id}/`,
      {},
      {
        headers: auth_headers,
      }
    );

    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}
