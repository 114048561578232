import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { inputStyles, variables } from "@src/jsssetup";
import { Field, useField } from "formik";
import { createUseStyles } from "react-jss";
import "react-datepicker/dist/react-datepicker.css";
import { fastDestinationAutocomplete } from "@src/api/cms";
import { Loader } from "rsuite";
import {
  accommodationAutocomplete,
  getJobDescriptionsAutocomplete,
  poiAutoComplete,
  providerAutocomplete,
} from "@src/api";
import { openTableAutocomplete } from "@src/api/Restaurants";
import { getUserSourceEntitySelector } from "@src/selectors/Shared/user_selectors";
import { useSelector } from "react-redux";
import { subAgentsAutocomplete } from "../../../api/Admin/SubAgents";
import { membersAutocomplete } from "../../../api/Admin/Members";
import { branchesAutocomplete } from "../../../api/Admin/Branches";
import { independentAgentsAutocomplete } from "../../../api/Admin/IndependentAgents";
import { useDebounce } from "@src/pages/hooks";
import { offerAutocomplete } from "@src/api/Project/trip_offers";
import { DateTime } from "luxon";
import { portAutocomplete } from "@src/api/Project/ferries/index.js";

async function hotelGetter({ name, setOptions, setLoading }) {
  setLoading(true);

  const result = await accommodationAutocomplete({ name });

  if (!result) {
    setLoading(false);
    return;
  }

  const hotels = _.get(result, "candidates", []).map((htl, idx) => ({
    ...htl,
    label: `${idx + 1}. ${htl.name}`,
    disabledLabel: `${htl.address} ${htl.country_name}`,
  }));
  setOptions(hotels);
  setLoading(false);
}
function hotelValuer({ option }) {
  return `${option.label}---${option.uid}`;
}

async function destGetter({ name, setOptions, setLoading }) {
  setLoading(true);
  await fastDestinationAutocomplete({ name })
    .then((result) => {
      if (!result) {
        return;
      }

      const dests = _.get(result, "destinations", []).map((dest, idx) => ({
        ...dest,
        label: `${idx + 1}. ${[...new Set(dest.destination.split("-"))].join(
          " - "
        )}`,
      }));
      setOptions(dests);
    })
    .finally(() => setLoading(false));
}
function destValuer({ option }) {
  return `${option.label}---${option.type}__${option.id}`;
}

async function poiGetter({ name, setOptions, setLoading }) {
  setLoading(true);
  const result = await poiAutoComplete({ query: name });

  if (!result) {
    setLoading(false);
    return;
  }

  const pois = _.get(result, "pois", []).map((poi, idx) => ({
    ...poi,
    label: `${idx + 1}. ${poi.name}, ${poi.location.join(", ")}`,
  }));
  setOptions(pois);
  setLoading(false);
}
function poiValuer({ option }) {
  return `${option.label}---${option.uid}`;
}

async function openTableGetter({ name, setOptions, setLoading }) {
  setLoading(true);
  const result = await openTableAutocomplete({ name });

  if (!result) {
    setLoading(false);
    return;
  }

  const options = _.get(result, "restaurants", []).map((rest, idx) => ({
    ...rest,
    label: rest.name,
    disabledLabel: `${idx + 1}. ${rest.address} ${rest.country}`,
  }));
  setOptions(options);
  setLoading(false);
}
function openTableValuer({ option }) {
  return `${option.label}---${option.rid}`;
}

async function providersGetter({
  name,
  setOptions,
  setLoading,
  source_entity,
}) {
  setLoading(true);
  const result = await providerAutocomplete({ name, source_entity });

  if (!result) {
    setLoading(false);
    return;
  }

  const options = result.map((provider) => ({
    ...provider,
    label: provider.name,
    disabledLabel: ``,
  }));
  setOptions(options);
  setLoading(false);
}
function providerValuer({ option }) {
  return `${option.label}---${option.id}`;
}

export function subagentLabeler(subagent) {
  return `${subagent.name} (${subagent.legal_title})`;
}
async function subAgentGetter({ name, source_entity, setLoading, setOptions }) {
  setLoading(true);
  const result = await subAgentsAutocomplete({
    name,
    member: source_entity.split("___")[0],
  });

  if (!result) {
    setLoading(false);
    return;
  }

  const options = result.map((subagent) => ({
    ...subagent,
    label: subagentLabeler(subagent),
    disabledLabel: subagent.email,
  }));
  setOptions(options);
  setLoading(false);
}
export function subAgentValuer({ option }) {
  return `${option.label}---${option.id}---${option.legal_title}---subagent`;
}

async function memberGetter({ name, setLoading, setOptions }) {
  setLoading(true);
  const result = await membersAutocomplete({
    name,
  });

  if (!result) {
    setLoading(false);
    return;
  }

  const options = result.map((member) => ({
    ...member,
    label: `${member.name} (${member.legal_title})`,
    disabledLabel: member.email,
  }));
  setOptions(options);
  setLoading(false);
}
function memberValuer({ option }) {
  return `${option.label}---${option.id}---${option.legal_title}---member`;
}

async function branchGetter({ name, source_entity, setLoading, setOptions }) {
  setLoading(true);
  const result = await branchesAutocomplete({
    name,
    member: source_entity.split("___")[0],
  });

  if (!result) {
    setLoading(false);
    return;
  }

  const options = result.map((branch) => ({
    ...branch,
    label: `${branch.name} (${branch.legal_title})`,
    disabledLabel: branch.email,
  }));
  setOptions(options);
  setLoading(false);
}
function branchValuer({ option }) {
  return `${option.label}---${option.id}---${option.legal_title}---branch`;
}

async function independentAgentGetter({
  name,
  source_entity,
  setLoading,
  setOptions,
}) {
  setLoading(true);
  const result = await independentAgentsAutocomplete({
    name,
    member: source_entity.split("___")[0],
  });

  if (!result) {
    setLoading(false);
    return;
  }

  const options = result.map((independentAgent) => ({
    ...independentAgent,
    label: `${independentAgent.name} (${independentAgent.legal_title})`,
    disabledLabel: independentAgent.email,
  }));
  setOptions(options);
  setLoading(false);
}
function independentAgentValuer({ option }) {
  return `${option.label}---${option.id}---${option.legal_title}---independentagent`;
}

async function entityGetter({ name, setLoading, setOptions }) {
  setLoading(true);

  async function getEntity(func, entity_type) {
    // setLoading(true);
    try {
      const results = await func({ name });
      var newOptions = [];
      results.forEach((entity) =>
        newOptions.push({
          ...entity,
          entity_type,
          label: `${entity.legal_title} (${entity_type})`,
          disabledLabel: entity.email,
        })
      );
      setOptions((options) => [...options, ...newOptions]);
    } catch (e) {}
    // setLoading(false);
  }
  const entityFunctions = [
    { func: membersAutocomplete, entityType: "member" },
    { func: branchesAutocomplete, entityType: "branch" },
    { func: independentAgentsAutocomplete, entityType: "independentagent" },
    { func: subAgentsAutocomplete, entityType: "subagent" },
  ];
  Promise.all(
    entityFunctions.map((entityFunction) =>
      getEntity(entityFunction.func, entityFunction.entityType)
    )
  );
  setLoading(false);
}
function entityValuer({ option }) {
  return `${option.label}---${option.entity_type}---${option.id}---${option.legal_title}`;
}

async function jobDescriptionGetter({ name, setLoading, setOptions }) {
  setLoading(true);
  const result = await getJobDescriptionsAutocomplete({
    brief_description: name,
  });

  if (!result) {
    setLoading(false);
    return;
  }

  // Show Unique job descriptions
  var options = [];
  result.forEach((jobDescr) => {
    if (
      !options.find(
        (opt) => opt.brief_description_en === jobDescr.brief_description_en
      )
    ) {
      options.push({
        ...jobDescr,
        label: `${jobDescr.brief_description_en}`,
        disabledLabel: `${jobDescr.brief_description_en}`,
      });
    }
  });

  // Show all job descriptions
  // const options = result.map((jobDescr) => ({
  //   ...jobDescr,
  //   label: `${jobDescr.brief_description_en}`,
  //   disabledLabel: `${jobDescr.brief_description_en}`,
  // }));
  setOptions(options);
  setLoading(false);
}
function jobDescriptionValuer({ option }) {
  return `${option.brief_description_en}---${option.id}`;
}

async function offerGetter({ name, source_entity, setLoading, setOptions }) {
  setLoading(true);

  var data = [];
  try {
    data = await offerAutocomplete({ offer_title: name, source_entity });
    data = data?.data ?? [];
  } catch (error) {
    setLoading(false);
    return;
  }

  const options = [];
  data.forEach((offer) => {
    if (!options.find((opt) => opt.reference === offer.reference)) {
      options.push({
        ...offer,
        label: `${offer.offer_title} (${offer.reference})`,
        disabledLabel: `Created: ${DateTime.fromISO(
          offer.created
        ).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)}`,
      });
    }
  });

  setOptions(options);
  setLoading(false);
}
function offerValuer({ option }) {
  return `${option.label}---${option.reference}---${option.id}---offer`;
}

async function portGetter({ name, setLoading, setOptions }) {
  setLoading(true);

  var data = [];
  try {
    data = await portAutocomplete({ name });
    data = data?.data?.ports ?? [];
  } catch (error) {
    setLoading(false);
    return;
  }
  const options = [];
  data.forEach((port) => {
    options.push({
      ...port,
      label: `(${port.code}) ${port.name}, ${port.country_code}`,
    });
  });

  setOptions(options);
  setLoading(false);
}
function portValuer({ option }) {
  return `${option.label}---${option.code}---port`;
}

const autoStyles = createUseStyles({
  AutocompleteComponent: {
    position: "relative",
    display: "grid",
  },
});
const AutocompleteComponent = ({ field, ...props }) => {
  const classes = autoStyles();

  const { id, getter, valuer, setLoading } = props;
  const { name, value } = field;

  const [dummy, setDummy] = useState(null);
  const [options, setOptions] = useState([]);
  const source_entity = useSelector((state) =>
    getUserSourceEntitySelector(state)
  );

  const debValue = useDebounce(value, 500);

  useEffect(() => {
    if (!dummy) {
      return null;
    }

    if ((debValue || "").length >= 3) {
      const opt = options.find((opt) => opt.label === debValue.split("---")[0]);
      if (!opt) {
        getter({ name: debValue, setOptions, setLoading, source_entity });
      }
    }
  }, [debValue]);

  return (
    <div className={classes.AutocompleteComponent}>
      <input
        className={props.className}
        {...field}
        name={name}
        value={
          !!field.value && typeof field.value === "string"
            ? field.value.split("---")[0]
            : field.value
        }
        type="text"
        list={id}
        autoComplete="off"
        onChange={(event) => {
          setDummy(event.target.value);
          const opt = options.find((opt) => opt.label === event.target.value);
          if (!opt) {
            field.onChange(event);
          } else {
            event.target.value = valuer({ option: opt });
            field.onChange(event);
          }
        }}
      />
      <datalist id={id}>
        {options.map((opt, idx) => (
          <option key={idx} value={opt.label}>
            {opt.disabledLabel}
          </option>
        ))}
      </datalist>
    </div>
  );
};
AutocompleteComponent.propTypes = {
  className: PropTypes.string.isRequired,
  id: PropTypes.string,
  getter: PropTypes.func.isRequired,
  valuer: PropTypes.func.isRequired,
  field: PropTypes.object.isRequired,
  setLoading: PropTypes.func.isRequired,
};

const normalInputFieldStyles = createUseStyles({
  inputGroup: (props) => ({
    "display": "grid",
    "gridGap": `calc(${variables.normal_gap} / 2)`,
    "width": "100%",
    "& >span": {
      color: props.theme === "normal" ? "unset" : "white",
    },
    ..._.get(props, "customStyles.inputGroup"),
  }),
  input: (props) => ({
    ...inputStyles,
    borderColor: props.theme === "normal" ? variables.colors.deepgrey : "white",
    ..._.get(props, "customStyles.input"),
  }),
  label: {
    display: "inline-grid",
    gridTemplateColumns: "max-content auto",
    alignItems: "center",
    gridGap: variables.normal_gap,
  },
  error: { color: (props) => (props.highlightErrors ? "red" : "black") },
});
function normalAutocompleteFactory({ id, getter, valuer }) {
  function Comp(props) {
    const {
      label,
      name,
      withError,
      extraInputProps,
      required,
      theme,
      inputRef,
    } = props;

    var validId = id;
    if (!validId) {
      validId = props.id;
    }
    if (props.id) {
      validId = props.id;
    }

    const [loading, setLoading] = useState(false);
    const [__, meta] = useField({ name });

    return (
      <div
        className="NormalInputField__inputGroup"
        ref={inputRef}
        id={id}
        data-normal-theme={theme === "normal"}>
        <span>
          <strong>
            {label}
            {required && " *"}
          </strong>
          {loading && <Loader size="xs" />}
        </span>
        <Field
          name={name}
          className="NormalInputField__inputGroup__input"
          data-normal-theme={theme === "normal"}
          setLoading={setLoading}
          id={validId}
          getter={getter}
          valuer={valuer}
          component={AutocompleteComponent}
          {...extraInputProps}
        />
        {withError && (meta?.error ?? "").length > 1 && (
          <div className="NormalInputField__inputGroup__error">
            {meta.error}
          </div>
        )}
      </div>
    );
  }
  Comp.defaultProps = {
    customStyles: {},
    label: "",
    name: "",
    extraInputProps: {},
    withError: true,
    highlightErrors: false,
    required: false,
    theme: "normal",
  };
  Comp.propTypes = {
    customStyles: PropTypes.object.isRequired,
    withError: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    name: PropTypes.string.isRequired,
    extraInputProps: PropTypes.object,
    highlightErrors: PropTypes.bool,
    required: PropTypes.bool.isRequired,
    theme: PropTypes.oneOf(["normal", "filter"]).isRequired,
    inputRef: PropTypes.object,
  };
  return Comp;
}

export const NormalDestAutocompleteField = normalAutocompleteFactory({
  id: "dest-autocomplete",
  getter: destGetter,
  valuer: destValuer,
});

export const NormalAccAutocompleteField = normalAutocompleteFactory({
  id: "acc-autocomplete",
  getter: hotelGetter,
  valuer: hotelValuer,
});

export const NormalPOIAutocompleteField = normalAutocompleteFactory({
  id: "poi-autocomplete",
  getter: poiGetter,
  valuer: poiValuer,
});

export const NormalOpenTableAutocompleteField = normalAutocompleteFactory({
  id: "opentable-autocomplete",
  getter: openTableGetter,
  valuer: openTableValuer,
});

export const NormalProviderAutocompleteField = normalAutocompleteFactory({
  id: "provider-autocomplete",
  getter: providersGetter,
  valuer: providerValuer,
});

export const NormalSubagentAutocompleteField = normalAutocompleteFactory({
  id: "subagent-autocomplete",
  getter: subAgentGetter,
  valuer: subAgentValuer,
});

export const NormalMemberAutocompleteField = normalAutocompleteFactory({
  id: "member-autocomplete",
  getter: memberGetter,
  valuer: memberValuer,
});

export const NormalBranchAutocompleteField = normalAutocompleteFactory({
  id: "branch-autocomplete",
  getter: branchGetter,
  valuer: branchValuer,
});

export const NormalEntityAutocompleteField = normalAutocompleteFactory({
  id: "entity-autocomplete",
  getter: entityGetter,
  valuer: entityValuer,
});

export const NormalJobDescriptionField = normalAutocompleteFactory({
  id: "jobDescription-autocomplete",
  getter: jobDescriptionGetter,
  valuer: jobDescriptionValuer,
});

export const NormalIndAgentAutocompleteField = normalAutocompleteFactory({
  id: "branch-independent-agent",
  getter: independentAgentGetter,
  valuer: independentAgentValuer,
});

export const NormalSentOfferAutocompleteField = normalAutocompleteFactory({
  id: "sent-offer",
  getter: offerGetter,
  valuer: offerValuer,
});

export const NormalPortAutocompleteField = normalAutocompleteFactory({
  id: "port-autocomplete",
  getter: portGetter,
  valuer: portValuer,
});
