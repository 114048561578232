import { getGeneralServicesSelector } from "@src/selectors/Project/TripPlanner/day_by_day/custom_services";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { GeneralSrvData } from "../../Day/general_service";

export const GeneralSrvReservations = ({ reservations }) => {
  const regular_coords = useSelector((state) =>
    getGeneralServicesSelector(state, { booking_mode: "regular" })
  );
  const option_coords = useSelector((state) =>
    getGeneralServicesSelector(state, { booking_mode: "option" })
  );

  const for_reservation_srvs = [...regular_coords, ...option_coords];

  return !reservations.length ? null : (
    <div>
      <h5>General Services</h5>
      {reservations.map((res, idx) => {
        const srv = for_reservation_srvs.find(
          (gen) => gen.id.toString() === res.search_uid.toString()
        );

        return (
          <GeneralSrvData
            key={idx}
            service={srv}
            reservationMode={true}
            reservationLoading={res.status === "PE"}
            reservationStatus={res.status}
          />
        );
      })}
    </div>
  );
};

GeneralSrvReservations.defaultProps = {
  reservations: [],
};

GeneralSrvReservations.propTypes = {
  reservations: PropTypes.array.isRequired,
};
