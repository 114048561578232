import _ from "lodash";
import { WhisperHelper } from "@src/components/common/ui_helpers";
import PropTypes from "prop-types";
import React from "react";
import { Loader, Panel, Tag } from "rsuite";
import {
  AccommodationData,
  getPrebookReadyRooms,
} from "../../../Controllers/OverviewAccommodation";
import { accommodationReservationStatus } from "@src/config/service";

export default function AccommodationReservation({
  acc_status_data,
  accommodation,
  prebookData,
  index,
}) {
  const rooms = getPrebookReadyRooms({ prebook_info: prebookData });

  return (
    <div className="AccommodationReservation">
      <div className="AccommodationReservation__title">
        <h6>
          Accommodation {index}. Reservation Reference:{" "}
          {acc_status_data.reference}.
        </h6>
        <span className="AccommodationReservation__title__actions">
          Status:
          {acc_status_data.status == "PE" ? (
            <Loader className="reservation-loader" />
          ) : (
            <div>
              <Tag color={acc_status_data.status !== "CO" ? "red" : "green"}>
                {_.get(
                  accommodationReservationStatus,
                  acc_status_data.status,
                  "N/A"
                )}
              </Tag>
              {acc_status_data.status == "FA" &&
              _.get(acc_status_data, "reservation_events", []).some((e) =>
                _.get(e, "error.error_recoverable", false)
              ) ? (
                <WhisperHelper
                  informational={true}
                  msg="There is a recoverable error on this reservation. You can view more information on the Reservation Detail page."
                />
              ) : null}
            </div>
          )}
        </span>
      </div>
      <AccommodationData
        accommodation={accommodation}
        meta={accommodation.metadata}
        rooms={rooms}
      />
    </div>
  );
}
AccommodationReservation.propTypes = {
  acc_status_data: PropTypes.object.isRequired,
  accommodation: PropTypes.object.isRequired,
  prebookData: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};
