import React, { memo } from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import { CarRentalsPrice } from "./CarRentalsPrice";

export var BottomContainer = ({ carRental }) => {
  const pick_up_date = "2021-10-10T10:00:00.000Z";
  const drop_off_date = "2021-10-20T10:00:00.000Z";

  const pickUpDateTime = DateTime.fromISO(pick_up_date);
  const dropOffDateTime = DateTime.fromISO(drop_off_date);
  const differenceInDays = Math.round(
    dropOffDateTime.diff(pickUpDateTime, "days").days
  );

  return (
    <div className="BottomContainer">
      <div className="BottomContainer__priceContr">
        <div className="BottomContainer__priceContr__priceTitle">
          <CarRentalsPrice
            price={Number(carRental.price.amount)}
            currency={carRental.price.currency}
          />
        </div>
        <div className="BottomContainer__priceContr__priceSubTitle">{`Price for ${differenceInDays} ${
          differenceInDays > 1 ? "Days" : "Day"
        }`}</div>
      </div>
      <button
        className="BottomContainer__selectBtn"
        // onClick={onPreBook}
      >
        Select
      </button>
    </div>
  );
};
BottomContainer.defaultProps = {
  ghost: false,
  carRental: {},
  customStyles: {},
};
BottomContainer.propTypes = {
  ghost: PropTypes.bool,
  carRental: PropTypes.object,
  customStyles: PropTypes.object,
};
BottomContainer = memo(BottomContainer);
