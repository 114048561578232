import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Modal, IconButton, Icon, Loader } from "rsuite";

import {
  hideSaveModal,
  saveAddonService,
} from "@src/actions/Operation/CustomServices/AddOn";

class SaveModal extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { show, loading, onHide, onSave } = this.props;

    var cls = "CustomModal ModalCentered SaveModal";
    if (loading) {
      cls += " CustomModal--loading";
    }

    return (
      <Modal className={cls} show={show} onHide={loading ? null : onHide}>
        <Modal.Header>
          <Modal.Title>Save Addon</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <Loader vertical size="lg" content="Please wait..." />
          ) : (
            <p>Save Add On?</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          {loading ? null : (
            <React.Fragment>
              <IconButton
                color="blue"
                icon={<Icon icon="close" />}
                onClick={onHide}>
                Cancel
              </IconButton>
              <IconButton
                color="green"
                icon={<Icon icon="check" />}
                onClick={onSave}>
                Save
              </IconButton>
            </React.Fragment>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}

SaveModal.propTypes = {
  show: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  imgLength: PropTypes.number,
  totalSize: PropTypes.number,
  percent: PropTypes.number,
  num: PropTypes.number,
  loadeSize: PropTypes.number,
  onHide: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { show, loading } = state.customServiceSaveModal;

  return {
    show,
    loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onHide: () => dispatch(hideSaveModal()),
    onSave: () => dispatch(saveAddonService()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SaveModal);
