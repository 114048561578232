import { variables } from "@src/jsssetup";
import PropTypes from "prop-types";
import React from "react";
import { createUseStyles } from "react-jss";
import { Drawer } from "rsuite";
import ChatComp from "./ChatComp";

const styles = createUseStyles({
  chatDrawerBody: {
    background: variables.colors.easy.orange,
    margin: 0,
    height: "100%!important",
  },
});

const ChatDrawer = ({ defaultChatRoom, show, onHide }) => {
  const classes = styles({});

  return (
    <Drawer show={show} size="md" onHide={onHide}>
      <Drawer.Body className={classes.chatDrawerBody}>
        <ChatComp defaultChatRoom={defaultChatRoom} />
      </Drawer.Body>
    </Drawer>
  );
};
ChatDrawer.propTypes = {
  defaultChatRoom: PropTypes.string,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};
export default ChatDrawer;
