import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { Icon, InlineIcon } from "@iconify/react";
import _ from "lodash";
import { variables } from "@src/jsssetup";
import { DateTime, Duration } from "luxon";
import {
  handleNonValidImgFn,
  humanizeDurationFromMins,
} from "@src/tools/common_tools";
import { WhisperWrapper } from "@src/components/common/ui_helpers";

const ferryDataStyles = createUseStyles({
  FerryData: (props) => ({
    display: "grid",
    gridTemplateColumns: props.services_collapsed ? "1fr" : "8rem 1fr",
    gridTemplateRows: props.services_collapsed ? "unset" : "10rem",
    gridGap: variables.normal_gap,
    maxHeight: "10rem",
  }),
  cardContent: {
    display: "grid",
    padding: variables.normal_gap,
    gridGap: variables.half_gap,
    boxShadow: variables.shadows.heavyShadow,
    borderRadius: `calc(${variables.normal_gap} / 2)`,
    textTransform: "capitalize",
  },
  header: (props) => ({
    display: "grid",
    gridTemplateColumns: props.services_collapsed
      ? "repeat(2,max-content)"
      : "1fr max-content",
    alignItems: "center",
    justifyContent: "space-between",
    gridGap: variables.half_gap,
    padding: props.services_collapsed && [
      variables.half_gap,
      variables.half_gap,
      variables.half_gap,
      variables.double_gap,
    ],
    borderRadius: props.services_collapsed && variables.half_gap,
    boxShadow: props.services_collapsed && variables.shadows.normalShadow,
  }),
  img: {
    width: "100%",
    height: "100%",
    borderRadius: "5px",
    objectFit: "contain",
    padding: ["3rem", 0],
    boxShadow: variables.shadows.heavyShadow,
  },
  metadata: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    alignItems: "center",
    gridGap: variables.normal_gap,
  },
  headerTitle: {
    fontSize: "medium",
    fontWeight: "bold",
  },
  title: {
    color: variables.colors.text.colored,
    fontWeight: "600",
  },
  actionBtns: {
    display: "grid",
    gridAutoFlow: "column",
    gridGap: variables.half_gap,
  },
  expandIconCntr: {
    display: "grid",
    placeContent: "center",
    width: "1.5rem",
    height: "1.5rem",
    borderRadius: "50%",
    background: variables.colors.easy.orange,
    padding: variables.half_gap,
    cursor: "pointer",
  },
  expandIcon: {
    fontSize: "large",
    color: variables.colors.text.light,
  },
  itinerary: {
    gridColumn: "span 2",
  },
});
export const FerryData = ({
  ferryData,
  services_collapsed,
  toggleSelfCollapse,
  children,
}) => {
  const classes = ferryDataStyles({ services_collapsed });
  const ferry = ferryData?.legs?.[0];
  if (!ferry) return null;

  const routeName = ferry?.route_name ?? "N/A";
  const isPriced = ferryData?.priced ?? false;
  const duration = humanizeDurationFromMins(ferry?.duration_minutes ?? "");
  const operator = ferry?.operator?.name ?? "N/A";
  const vessel = ferry?.vessel_data?.name ?? "N/A";
  const logo = ferry?.operator?.logo ?? "";
  const departure = DateTime.fromISO(ferry?.departure_datetime ?? "", {
    setZone: true,
  }).toLocaleString(DateTime.DATETIME_MED, { keepOffset: true });
  const arrival = DateTime.fromISO(ferry?.arrival_datetime ?? "", {
    setZone: true,
  }).toLocaleString(DateTime.DATETIME_MED, { keepOffset: true });

  const header = (
    <React.Fragment>
      <div className={classes.headerTitle}>
        {!isPriced && (
          <WhisperWrapper msg="This service needs further selection so as to be properly priced click View Details">
            <InlineIcon
              icon="material-symbols:warning"
              fontSize="x-large"
              color={variables.colors.orange}
            />
          </WhisperWrapper>
        )}
        {routeName}
      </div>
      <div className={classes.actionBtns}>
        {children}
        {typeof toggleSelfCollapse === "function" && (
          <div className={classes.expandIconCntr}>
            <InlineIcon
              className={classes.expandIcon}
              icon={
                services_collapsed
                  ? "ri:arrow-down-s-line"
                  : "ri:arrow-up-s-line"
              }
              onClick={toggleSelfCollapse}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );

  return _.isEmpty(ferry) ? null : (
    <div className={classes.FerryData}>
      {services_collapsed ? (
        <div className={classes.header}>{header}</div>
      ) : (
        <React.Fragment>
          <img
            className={classes.img}
            src={logo}
            onError={(e) => handleNonValidImgFn(e, "AFER")}
          />
          <div className={classes.cardContent}>
            <div className={classes.header}>{header}</div>
            <div className={classes.metadata}>
              <span>
                <span className={classes.title}>Itinerary:</span> {routeName}
              </span>
              <span>
                <span className={classes.title}>Company: </span>
                {operator}
              </span>
              <span>
                <span className={classes.title}>Duration:</span>{" "}
                {duration ? duration : "N/A"}
              </span>
              <span>
                <span className={classes.title}>Vessel:</span> {vessel}
              </span>
              <span>
                <span className={classes.title}>Departure: </span>
                {departure}
              </span>
              <span>
                <span className={classes.title}>Arrival: </span>
                {arrival}
              </span>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
FerryData.propTypes = {
  ferry: PropTypes.object.isRequired,
  services_collapsed: PropTypes.bool.isRequired,
  toggleSelfCollapse: PropTypes.func,
  children: PropTypes.object.isRequired,
};
