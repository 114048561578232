const langCN =
  "https://sisistaticassets.s3.ap-southeast-1.amazonaws.com/flags/1x1/cn.svg";
const langEN =
  "https://sisistaticassets.s3.ap-southeast-1.amazonaws.com/flags/1x1/gb.svg";
const langPT =
  "https://sisistaticassets.s3.ap-southeast-1.amazonaws.com/flags/1x1/br.svg";
const langFR =
  "https://sisistaticassets.s3.ap-southeast-1.amazonaws.com/flags/1x1/fr.svg";
const langIT =
  "https://sisistaticassets.s3.ap-southeast-1.amazonaws.com/flags/1x1/it.svg";
const langES =
  "https://sisistaticassets.s3.ap-southeast-1.amazonaws.com/flags/1x1/es.svg";
const langDE =
  "https://sisistaticassets.s3.ap-southeast-1.amazonaws.com/flags/1x1/de.svg";
const langNL =
  "https://sisistaticassets.s3.ap-southeast-1.amazonaws.com/flags/1x1/nl.svg";

const langs = [
  {
    justifySelf: "start",
    lang: "zh",
    img: langCN,
    name: "中文",
  },
  {
    justifySelf: "center",
    lang: "en",
    img: langEN,
    name: "ENGLISH",
  },
  {
    justifySelf: "center",
    lang: "pt",
    img: langPT,
    name: "PORTUGUESE",
  },
  {
    justifySelf: "center",
    lang: "fr",
    img: langFR,
    name: "FRENCH",
  },
  {
    justifySelf: "center",
    lang: "it",
    img: langIT,
    name: "ITALIAN",
  },
  {
    justifySelf: "center",
    lang: "es",
    img: langES,
    name: "SPANISH",
  },
  {
    justifySelf: "center",
    lang: "de",
    img: langDE,
    name: "GERMAN",
  },
  {
    justifySelf: "center",
    lang: "nl",
    img: langNL,
    name: "DUTCH",
  },
];

export default langs;
