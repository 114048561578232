import { Icon, IconButton, Loader, SelectPicker } from "rsuite";
import { DestinationImage } from "./ItineraryController.js";

import {
  itnAddDestination,
  itnAddReturn,
  itnSelectDestination,
  itnRemoveDestination,
  itnDestinationQueryChange,
  itnDestinationDown,
  itnDestinationUp,
  itnSelectOriginDate,
  itnDestinationGenericChange,
  itnDestStayChange,
} from "@src/actions/Project/TripPlanner";

import TransTxt from "@src/components/common/SxFormatMessage";

import _ from "lodash";
import { connect, useDispatch } from "react-redux";

import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { getSetupFormDataSelector } from "@src/selectors/Project/TripPlanner/index.js";
import { DestAutoComplete } from "./DestAutoComplete.js";
import { createUseStyles } from "react-jss";
import { variables } from "@src/jsssetup.js";
import { DestinationForm } from "./itinerary/destination.js";

const destinationAdderStyles = createUseStyles({
  DestinationAdder: (props) => ({
    display: "grid",
    gridTemplateColumns: props.showReturn
      ? "1fr auto auto 1fr"
      : "1fr auto 1fr",
    gridGap: variables.normal_gap,
    gridAutoRows: "max-content",
    alignItems: "center",
  }),
  placeholder: {
    border: `1px solid ${variables.colors.borders.base}`,
    height: "0px",
  },
  adderBtn: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    alignItems: "center",
    cursor: "pointer",
  },
});
function DestinationAdder(props) {
  const {
    transportationRequired,
    currentStep,
    order,
    returnExists,
    onAddClick,
    onReturnClick,
  } = props;

  const showReturn = order > 0 && !returnExists && transportationRequired;

  const classes = destinationAdderStyles({ showReturn });

  return currentStep > 1 ? null : (
    <div className={classes.DestinationAdder}>
      <div className={classes.placeholder} />
      <div className={classes.adderBtn} onClick={() => onAddClick(order)}>
        <IconButton
          icon={<Icon icon="plus" />}
          color="green"
          circle
          size="sm"
        />
        <TransTxt id="tripplan_add_destination" startCase />
      </div>
      {showReturn && (
        <div className={classes.adderBtn} onClick={onReturnClick}>
          <IconButton
            icon={<Icon icon="plus" />}
            color="blue"
            circle
            size="sm"
          />
          <TransTxt id="tripplan_add_return" startCase />
        </div>
      )}
      <div className={classes.placeholder} />
    </div>
  );
}
DestinationAdder.propTypes = {
  currentStep: PropTypes.number.isRequired,
  order: PropTypes.number.isRequired,
  returnExists: PropTypes.bool.isRequired,
  transportationRequired: PropTypes.bool.isRequired,
  onAddClick: PropTypes.func.isRequired,
  onReturnClick: PropTypes.func.isRequired,
};

const DestControls = ({
  destId,
  order,
  index,
  destinationsLength,
  onAddDestination,
  onDestinationUp,
  onDestinationRemove,
  onDestinationDown,
}) => {
  return (
    <React.Fragment>
      {index > 0 ? (
        <IconButton
          icon={<Icon icon="up" />}
          color="blue"
          circle
          size="xs"
          onClick={() => (order == 1 ? null : onDestinationUp(order))}
        />
      ) : null}
      <IconButton
        icon={<Icon icon="minus" />}
        color="red"
        circle
        size="xs"
        onClick={() => onDestinationRemove(order, destId)}
      />
      {index < destinationsLength ? (
        <IconButton
          icon={<Icon icon="down" />}
          color="blue"
          circle
          size="xs"
          onClick={() => onDestinationDown(order)}
        />
      ) : null}
      {index < destinationsLength ? (
        <IconButton
          icon={<Icon icon="plus" />}
          color="green"
          circle
          size="xs"
          onClick={() => onAddDestination(order)}
        />
      ) : null}
    </React.Fragment>
  );
};
DestControls.propTypes = {
  destId: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  order: PropTypes.number.isRequired,
  destinationsLength: PropTypes.number.isRequired,
  onAddDestination: PropTypes.func.isRequired,
  onDestinationUp: PropTypes.func.isRequired,
  onDestinationRemove: PropTypes.func.isRequired,
  onDestinationDown: PropTypes.func.isRequired,
};

const destinationStyles = createUseStyles({
  Destination: {
    "display": "grid",
    "gridTemplateColumns": "auto 1fr",
    "gridGap": variables.normal_gap,
    "padding": variables.normal_gap,
    "boxShadow": variables.shadows.normalShadow,
    "borderRadius": variables.normal_gap,
    "transition": "all 0.3s",
    "position": "relative",
    "&:hover": {
      boxShadow: variables.shadows.hoverShadow,
    },
  },
  controlsContainer: {
    display: "grid",
    gridAutoRows: "max-content",
    placeItems: "center",
    gridGap: variables.normal_gap,
    alignContent: "space-between",
  },
  content: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
  },
  topForm: {
    display: "grid",
    gridTemplateColumns: "4fr 1fr",
    gridGap: `calc(${variables.normal_gap} / 2)`,
  },
  mainContent: {
    display: "grid",
    gridTemplateColumns: "12rem 1fr",
    gridGap: `calc(${variables.normal_gap} / 2)`,
  },
  imgContainer: {
    "height": "100%",
    "maxHeight": "8rem",
    "width": "12rem",
    "alignSelf": "center",
    "& img": {
      height: "100%",
      width: "100%",
      borderRadius: "5px",
      objectFit: "cover",
    },
  },
  destIdx: {
    background: variables.colors.easy.lightOrange2,
    width: "2rem",
    height: "2rem",
    color: "white",
    display: "grid",
    placeItems: "center",
    borderRadius: "5px",
  },
  [`@media ${variables.media.bigscreen}`]: {
    mainContent: {
      gridTemplateColumns: "17rem 1fr",
    },
    imgContainer: {
      width: "17rem",
    },
  },
});
var Destination = ({
  id,
  type,
  dest_type,
  lang,
  checkIn,
  checkOut,
  images,
  requiredServices,
  loading,
  query,
  destIdx,
  order,
  destinationsLength,
  currentStep,
  destGenericChange,
  onAddDestination,
  onDestinationUp,
  onDestinationRemove,
  onDestinationDown,
  onDestinationSelect,
}) => {
  const destId = `${type}__${id}`;

  const idx = destIdx + 1;
  const classes = destinationStyles();

  const dispatch = useDispatch();
  const onStayChange = useCallback(
    ({ stay, destOrder }) => {
      dispatch(itnDestStayChange({ stay, destOrder }));
    },
    [dispatch]
  );

  return !requiredServices ? null : (
    <div className={classes.Destination}>
      <div className={classes.controlsContainer}>
        <div className={classes.destIdx}>
          <strong>{idx}</strong>
        </div>
        {currentStep > 1 ? null : (
          <DestControls
            destId={destId}
            index={destIdx + 1}
            order={order}
            destinationsLength={destinationsLength}
            onAddDestination={onAddDestination}
            onDestinationUp={onDestinationUp}
            onDestinationRemove={onDestinationRemove}
            onDestinationDown={onDestinationDown}
          />
        )}
      </div>
      <div className={classes.content}>
        {loading && (
          <Loader backdrop content="Loading destination content..." vertical />
        )}
        {currentStep !== 1 ? (
          <h5>{query}</h5>
        ) : (
          <div className={classes.topForm}>
            <DestAutoComplete
              lang={lang}
              id={[order, query].join("___")}
              value={query}
              onSelect={function (id, lvl, query) {
                onDestinationSelect({ id, lvl, query, order });
              }}
            />
            <SelectPicker
              searchable={false}
              value={dest_type}
              data={[
                { label: "Normal Destination", value: "NOR" },
                { label: "Layover Point", value: "LAY" },
                { label: "Non Serviced Destination", value: "NON" },
              ]}
              onChange={async (value) => {
                switch (value) {
                  case "LAY": {
                    await onStayChange({ stay: 0, destOrder: order });
                    break;
                  }
                  case "NOR": {
                    await onStayChange({ stay: 1, destOrder: order });
                    break;
                  }
                  default:
                    break;
                }

                await destGenericChange({
                  destOrder: order,
                  key: "dest_type",
                  value: value,
                });
              }}
            />
          </div>
        )}
        <div className={classes.mainContent}>
          <div className={classes.imgContainer}>
            <DestinationImage images={images} />
          </div>
          <DestinationForm
            destIdx={destIdx}
            dest_type={dest_type}
            disabled={currentStep > 1}
            checkIn={checkIn}
            checkOut={checkOut}
            destOrder={order}
            datePickerEnabled={!requiredServices.includes("TR") && idx == 1}
          />
        </div>
      </div>
    </div>
  );
};
Destination.defaultProps = {
  loading: false,
};
Destination.propTypes = {
  id: PropTypes.number,
  type: PropTypes.string.isRequired,
  order: PropTypes.number.isRequired,
  query: PropTypes.string.isRequired,
  checkIn: PropTypes.string.isRequired,
  checkOut: PropTypes.string.isRequired,
  images: PropTypes.array.isRequired,
  lang: PropTypes.string.isRequired,
  currentStep: PropTypes.number.isRequired,
  destIdx: PropTypes.number.isRequired,
  destinationsLength: PropTypes.number.isRequired,
  requiredServices: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  dest_type: PropTypes.oneOf(["NOR", "LAY", "NON"]).isRequired,
  onAddDestination: PropTypes.func.isRequired,
  onDestinationSelect: PropTypes.func.isRequired,
  onDestinationRemove: PropTypes.func.isRequired,
  onDestQueryChange: PropTypes.func.isRequired,
  onDestinationDown: PropTypes.func.isRequired,
  onDestinationUp: PropTypes.func.isRequired,
  onDestinationDateChange: PropTypes.func.isRequired,
  destGenericChange: PropTypes.func.isRequired,
};
const dMapStateToProps = (state, ownProps) => {
  const { destIdx } = ownProps;

  const { id, type, order, query, checkIn, checkOut, images, dest_type } =
    state.tripPlannerDestinations[destIdx];

  const loading = state.tripPlannerDestinationsLoadingStatus[destIdx + 1];

  const { requiredServices } = getSetupFormDataSelector(state);

  return {
    id,
    type,
    order,
    query,
    checkIn,
    checkOut,
    images,
    lang: state.setLang,
    currentStep: state.tripPlannerCurrentStep,
    requiredServices,
    loading,
    dest_type,
  };
};
const dMapDispatchToProps = (dispatch) => {
  return {
    destGenericChange: ({ destOrder, key, value }) =>
      dispatch(itnDestinationGenericChange({ destOrder, key, value })),
    onAddDestination: (order) => dispatch(itnAddDestination(order)),
    onDestQueryChange: (id, query) => {
      const order = parseInt(id, 10);
      dispatch(itnDestinationQueryChange(order, query));
    },
    onDestinationSelect: ({ id, lvl, query, order }) => {
      dispatch(itnSelectDestination({ id, lvl, query, order }));
    },
    onDestinationRemove: (order, destId) => {
      dispatch(itnRemoveDestination(order, destId));
    },
    onDestinationDown: (order) => {
      dispatch(itnDestinationDown(order));
    },
    onDestinationUp: (order) => {
      dispatch(itnDestinationUp(order));
    },
    onDestinationDateChange: async (date) => {
      await dispatch(itnSelectOriginDate(date));
    },
  };
};
Destination = connect(dMapStateToProps, dMapDispatchToProps)(Destination);

const itineraryDestinationsStyles = createUseStyles({
  ItineraryDestinations: {
    display: "grid",
    gridTemplateRows: "min-content",
    gridGap: variables.normal_gap,
  },
});
const ItineraryDestinations = ({
  destIdxs,
  currentStep,
  returnData,
  transportationRequired,
  onAddDestination,
  onAddReturn,
}) => {
  const classes = itineraryDestinationsStyles();

  return (
    <div className={classes.ItineraryDestinations}>
      {destIdxs.map((d, idx) => (
        <Destination
          key={idx}
          destIdx={d}
          destinationsLength={destIdxs.length}
        />
      ))}
      <DestinationAdder
        currentStep={currentStep}
        returnExists={!_.isEmpty(returnData)}
        order={destIdxs.length}
        transportationRequired={transportationRequired}
        onAddClick={onAddDestination}
        onReturnClick={onAddReturn}
      />
    </div>
  );
};
ItineraryDestinations.propTypes = {
  lang: PropTypes.string.isRequired,
  currentStep: PropTypes.number.isRequired,
  destIdxs: PropTypes.array.isRequired,
  returnData: PropTypes.object.isRequired,
  transportationRequired: PropTypes.bool.isRequired,
  onAddReturn: PropTypes.func.isRequired,
  onAddDestination: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  const { requiredServices } = getSetupFormDataSelector(state);
  const transportationRequired = requiredServices.includes("TR");
  const destIdxs = state.tripPlannerDestinations.map((d, idx) => idx);

  return {
    lang: state.setLang,
    currentStep: state.tripPlannerCurrentStep,
    destIdxs,
    transportationRequired,
    returnData: state.tripPlannerReturnData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onAddReturn: () => dispatch(itnAddReturn()),
    onAddDestination: (order) => dispatch(itnAddDestination(order)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ItineraryDestinations);
