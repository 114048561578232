import React from "react";
import _ from "lodash";

import TransTxt from "@src/components/common/SxFormatMessage";
import { initialPolicyBaseDataForm } from "@src/forms/Admin/Columns/policyCommonBaseForm";

export const initialB2CPolicyFilterForm = {
  name: {
    value: "",
    verbose: <TransTxt id="policy_name" startCase />,
  },
  pax: {
    value: [1, 40],
    verbose: "pax",
  },
  distribution: {
    value: [],
    placeholder: "YYYY-MM-DD~YYYY-MM-DD",
    placement: "auto",
    verbose: <TransTxt id="distribution_period" startCase />,
  },
  reservation: {
    value: [],
    placeholder: "YYYY-MM-DD~YYYY-MM-DD",
    placement: "auto",
    verbose: <TransTxt id="reservation_period" startCase />,
  },
  sales_partners: {
    value: [],
    verbose: <TransTxt id="sales_partners" startCase />,
  },
  territory: {
    value: [],
    verbose: "",
  },
  created: {
    value: [],
    placeholder: "YYYY-MM-DD~YYYY-MM-DD",
    verbose: <TransTxt id="created_date" startCase />,
    placement: "auto",
  },
  creator: {
    value: "",
    verbose: <TransTxt id="created_by" startCase />,
  },
  travel_services: {
    value: [],
    verbose: "Travel Services",
  },
  last_edit: {
    value: [],
    placeholder: "YYYY-MM-DD~YYYY-MM-DD",
    verbose: <TransTxt id="last_edited_date" startCase />,
    placement: "auto",
  },
  status: {
    value: [],
    verbose: "status",
    data: [
      {
        value: "IN",
        label: "Inactive",
        display_name: <TransTxt id="inactive" startCase />,
      },
      {
        value: "AC",
        label: "Active",
        display_name: <TransTxt id="active" startCase />,
      },
      {
        value: "CA",
        label: "Candidate",
        display_name: <TransTxt id="candidate" startCase />,
      },
    ],
  },
  AF: {
    value: [],
    verbose: "AF",
  },
  AS: {
    value: [],
    verbose: "AS",
  },
  EU: {
    value: [],
    verbose: "EU",
  },
  NA: {
    value: [],
    verbose: "NA",
  },
  SA: {
    value: [],
    verbose: "SA",
  },
  AU: {
    value: [],
    verbose: "AU",
  },
  AN: {
    value: [],
    verbose: "AN",
  },
};

export const initialB2CPolicyBaseDataForm = _.merge(
  {},
  {
    market: {
      value: "B2B",
      verbose: "market",
      disabled: true,
    },
    sales_partners: {
      value: [],
      verbose: "sales_partners",
      // validator: ArrayType().isRequired("Sales Partners is required"),
    },
    b2c_websites: {
      value: "",
      verbose: "B2C Websites",
      data: [],
    },
  },
  initialPolicyBaseDataForm
);

export const defaultOption = {
  basic: {
    value: ["policy_name", "status"],
  },
};

export * from "./columns";
