import _ from "lodash";

import { filterEmptyValue } from "@src/tools/common_tools";

export const filterProcessor = (listFilter, service_type) => {
  const params = {};
  const travel_services = [];
  Object.keys(listFilter).forEach((item) => {
    if (_.find(service_type.choices || [], { value: item })) {
      travel_services.push(listFilter[item].value);
    }
  });
  params.name__icontains = listFilter.brand_name.value;
  params.id = listFilter.id.value;
  params.url__icontains = listFilter.url.value;
  params.license_type__contains = listFilter.license_type.value;
  params.registration_code__icontains = listFilter.registration_code.value;
  params.founding_year = listFilter.founding_year.value;
  params.travel_services__in = _.flatten(travel_services).toString();
  params.entity_type__contains = listFilter.entity_type.value.toString();
  params.expertise_type__contains = listFilter.expertise_type.value.toString();
  params.status__in = listFilter.status.value.toString();
  params.contact_person__first_name__icontains = listFilter.first_name.value;
  params.contact_person__last_name__icontains = listFilter.last_name.value;
  params.email__icontains = listFilter.email.value;
  params.creator__icontains = listFilter.creator.value;
  params.created__gte = listFilter.created.value[0];
  params.created__lte = listFilter.created.value[1];
  params.edited__gte = listFilter.edited.value[0];
  params.edited__lte = listFilter.edited.value[1];
  params.phone__contains = listFilter.phone.value;
  params.mobile_phone__contains = listFilter.mobile_phone.value;
  params.business_address_country_code__icontains = "";
  params.business_address_region__icontains = listFilter.postal_region.value;
  params.business_address_post_code__icontains =
    listFilter.postal_post_code.value;
  params.business_address_city__icontains = listFilter.postal_city.value;
  params.business_address_street__icontains = listFilter.postal_address.value;
  return filterEmptyValue(params);
};
