export const getCustomSrvCommonPayload = (object_id, service_type) => {
  return {
    content_type: "bidoffer",
    object_id: object_id,
    service_type,
    images: [],
  };
};

export const getCustomSrvFormData = (payload) => {
  const formDataPayload = new FormData();

  Object.entries(payload).forEach((e) => {
    if (e[0] == "service_data") {
      formDataPayload.append(e[0], e[1]);
    } else if (e[0] == "images") {
      e[1].forEach((img, idx) => {
        Object.entries(img).forEach((i) =>
          formDataPayload.append(`images[${idx}]${i[0]}`, i[1])
        );
      });
    } else {
      formDataPayload.append(e[0], e[1]);
    }
  });

  return formDataPayload;
};
