import {
  TRIPPLANNER_ACCEPT_FLIGHTS_PREBOOK,
  TRIPPLANNER_RESET_FLIGHTS_PREBOOK,
} from "@src/reducers/Project/TripPlanner";

export function acceptFlightsPrebook({ flight_uids }) {
  return { type: TRIPPLANNER_ACCEPT_FLIGHTS_PREBOOK, flight_uids };
}

export function resetFlightsPrebook() {
  return { type: TRIPPLANNER_RESET_FLIGHTS_PREBOOK };
}
