import { Loader } from "rsuite";

import { useHistory, useLocation, withRouter } from "react-router-dom";

import { Route, Switch, Redirect } from "react-router-dom";
import loadable from "@loadable/component";

import PageErrorBoundary from "@src/components/common/PageErrorBoundary";

import {
  verifyUserInfo,
  getLSUserData,
  emailIsVerified,
  setSubscriptionValidityData,
  useProfileAndCompanyCompleteness,
} from "@src/tools/auth_tools";
import { MobileViewContext } from "@src/App";

// ========================== NOTIFICATIONS ==========================
import {
  notifyPaymentNotSetupWarning,
  accoundDisabledError,
  paymentDueInfo,
  notifyIncompleteCompanyProfileWarning,
  notifyIncompletePersonalProfileWarning,
} from "@src/components/common/Notifications";

// ============================== MODALS =============================
import AuthModal from "@src/pages/Auth/AuthModal";
import { EmailVerifyModal } from "./VerifyModal";

import LoadingBar from "@src/components/layout/LoadingBar";

import SideBar from "@src/components/layout/SideBar";
import MainHeader from "@src/components/layout/MainHeader";
import { initialMenu, IDROUTES } from "@src/config/menu";

// ============================= ACTIONS =============================
import {
  showAuthModal,
  hideAuthModal,
  initializeAppData,
} from "@src/actions/Auth";
import NoAuth from "@src/components/authorization/NoAuth";

import _ from "lodash";
import moment from "moment";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";

import { getSubscriptionValidity } from "@src/api";
import { refreshIdToken } from "@src/actions/Authentication";
// import { resizeContainer } from "@src/actions/Menu";
import { InlineIcon } from "@iconify/react";
import { getUserMetaSelector } from "@src/selectors/Shared/user_selectors";

const options = { fallback: <LoadingBar /> };

const CompanyProfile = loadable(
  () => import("@src/pages/Admin/Company/Profile/NewCompanyProfile"),
  options
);

const UsersList = loadable(
  () => import("@src/pages/Admin/Company/Users/list"),
  options
);
const Branches = loadable(
  () => import("@src/pages/Network/Sales/Branches/list"),
  options
);
const SubAgents = loadable(
  () => import("@src/pages/Network/Sales/SubAgents/list"),
  options
);
const IndependentAgents = loadable(
  () => import("@src/pages/Network/Sales/IndependentAgents/list"),
  options
);
const Affiliates = loadable(
  () => import("@src/pages/Network/Supply/Affiliates"),
  options
);
const Providers = loadable(
  () => import("@src/pages/Network/Supply/Suppliers"),
  options
);

const Members = loadable(
  () => import("@src/pages/Network/System/Members/list"),
  options
);
const MyConnections = loadable(
  () => import("@src/pages/Network/System/MyConnections/list/index"),
  options
);

const Invitations = loadable(
  () => import("@src/pages/Network/System/Invitations/NewInvitations"),
  options
);

const InvitationsSend = loadable(
  () => import("@src/pages/Network/System/InvitationsSend/list"),
  options
);

const GroupPolicy = loadable(
  () => import("@src/pages/Admin/MarketPolicy/common/list"),
  options
);

const OneToOne = loadable(
  () => import("@src/pages/Admin/MarketPolicy/common/list"),
  options
);

const GeoPolicy = loadable(
  () => import("@src/pages/Admin/MarketPolicy/common/list"),
  options
);

const InvoiceList = loadable(
  () => import("@src/pages/Admin/Financial/Invoice/list"),
  options
);

const InvoiceDetail = loadable(
  () => import("@src/pages/Admin/Financial/Invoice/details"),
  options
);

const CompanyStampsList = loadable(
  () => import("@src/pages/Admin/ControlPanel/CompanyStamps/list"),
  options
);

const InnerGroupPolicy = loadable(
  () => import("@src/pages/Admin/InnerPolicy/InnerGroupPolicy"),
  options
);

const B2CPolicy = loadable(
  () => import("@src/pages/Admin/MarketPolicy/B2C/list"),
  options
);

const SiSiBid = loadable(
  () => import("@src/pages/Project/SiSiBid/SiSiBid"),
  options
);

const BiddingRequest = loadable(
  () => import("@src/pages/Project/SiSiBid/BiddingRequest"),
  options
);

const CurrentBid = loadable(
  () => import("@src/pages/Project/CurrentBid"),
  options
);

const BiddingTrips = loadable(
  () => import("@src/pages/Project/Archive/BiddingTrips"),
  options
);

const BiddingRequests = loadable(
  () => import("@src/pages/Project/Archive/BiddingRequests"),
  options
);

const BiddingRequestsForMember = loadable(
  () => import("@src/pages/Project/Archive/BiddingRequestsForMember"),
  options
);

const BiddingFlow = loadable(
  () => import("@src/pages/Project/Archive/BiddingFlow"),
  options
);

const TripPlanner = loadable(
  () => import("@src/pages/Project/TripPlan/TripPlanner"),
  options
);

const QuotationRequest = loadable(
  () => import("@src/pages/Project/QuotationRequest"),
  options
);

const RedirectAs404 = loadable(
  () => import("@src/pages/RedirectAs404"),
  options
);

const UserListProfile = loadable(
  () => import("@src/pages/Admin/Company/Users/details"),
  options
);

const UserProfile = loadable(
  () => import("@src/pages/UserProfile/New"),
  options
);

const BranchesProfile = loadable(
  () => import("@src/pages/Network/Sales/Branches/details"),
  options
);

const SubAgentsProfile = loadable(
  () => import("@src/pages/Network/Sales/SubAgents/NewProfile"),
  options
);

const IndependentAgentsProfile = loadable(
  () => import("@src/pages/Network/Sales/IndependentAgents/NewProfile"),
  options
);

const AffiliatesProfile = loadable(
  () => import("@src/pages/Network/Supply/Affiliates/Profile"),
  options
);

const SuppliersProfile = loadable(
  () => import("@src/pages/Network/Supply/Suppliers/NewProfile"),
  options
);

// ============================ OPERATION ============================
const Accommodation = loadable(
  () => () =>
    import("@src/pages/Operation/Reservations/Accommodations/Accommodations"),
  options
);
const AccommodationB2BReservationsList = loadable(
  () =>
    import(
      "@src/pages/Operation/B2BReservations/SingleServices/Accommodation/list"
    ),
  options
);
const AccommodationB2CReservationsList = loadable(
  () =>
    import(
      "@src/pages/Operation/B2CReservations/SingleServices/Accommodation/list"
    ),
  options
);
const Flights = loadable(
  () =>
    import("@src/pages/Operation/B2BReservations/SingleServices/Flights/list"),
  options
);

const FlightsB2CReservationsList = loadable(
  () =>
    import("@src/pages/Operation/B2CReservations/SingleServices/Flights/list"),
  options
);

const AddonReservationsLists = loadable(
  () =>
    import(
      "@src/pages/Operation/B2CReservations/SingleServices/Addons/list/index"
    ),
  options
);

const B2BAddonReservationsLists = loadable(
  () =>
    import(
      "@src/pages/Operation/B2BReservations/SingleServices/Addons/list/index"
    ),
  options
);

const B2BActivitiesReservationsLists = loadable(
  () =>
    import(
      "@src/pages/Operation/B2BReservations/SingleServices/Activities/list/index"
    ),
  options
);

const B2BActivitiesReservationsDetails = loadable(
  () =>
    import(
      "@src/pages/Operation/B2BReservations/SingleServices/Activities/details/index"
    ),
  options
);

const B2CActivitiesReservationsLists = loadable(
  () =>
    import(
      "@src/pages/Operation/B2CReservations/SingleServices/Activities/list/index"
    ),
  options
);

const B2CActivitiesReservationsDetails = loadable(
  () =>
    import(
      "@src/pages/Operation/B2CReservations/SingleServices/Activities/details/index"
    ),
  options
);

const CarRentalB2BList = loadable(
  () =>
    import(
      "@src/pages/Operation/B2BReservations/SingleServices/CarRental/list/index"
    ),
  options
);

const CarRentalB2BDetail = loadable(
  () =>
    import(
      "@src/pages/Operation/B2BReservations/SingleServices/CarRental/detail/index"
    ),
  options
);

const CarRentalB2CList = loadable(
  () =>
    import(
      "@src/pages/Operation/B2CReservations/SingleServices/CarRental/list/index"
    ),
  options
);

const CarRentalB2CDetail = loadable(
  () =>
    import(
      "@src/pages/Operation/B2CReservations/SingleServices/CarRental/detail/index"
    ),
  options
);

const B2BCoachReservationsLists = loadable(
  () =>
    import(
      "@src/pages/Operation/B2BReservations/SingleServices/Coach/list/index"
    ),
  options
);

const B2BCoachReservationsDetail = loadable(
  () =>
    import(
      "@src/pages/Operation/B2BReservations/SingleServices/Coach/detail/index"
    ),
  options
);

const B2BAdhocAccReservationsLists = loadable(
  () =>
    import(
      "@src/pages/Operation/B2BReservations/SingleServices/AdhocAccommodations/list/index"
    ),
  options
);

const B2BContractedAccReservationsLists = loadable(
  () =>
    import(
      "@src/pages/Operation/B2BReservations/SingleServices/ContractedAccommodations/list/index"
    ),
  options
);

const B2BAdhocAccReservationsDetail = loadable(
  () =>
    import(
      "@src/pages/Operation/B2BReservations/SingleServices/AdhocAccommodations/detail/index"
    ),
  options
);

const B2BContractedAccReservationsDetail = loadable(
  () =>
    import(
      "@src/pages/Operation/B2BReservations/SingleServices/ContractedAccommodations/detail/index"
    ),
  options
);

const Packages = loadable(
  () => import("@src/pages/Operation/Reservations/Packages/Packages"),
  options
);
const Tours = loadable(
  () => import("@src/pages/Operation/Reservations/Tours/Tours"),
  options
);
const Transfer = loadable(
  () =>
    import(
      "@src/pages/Operation/B2BReservations/SingleServices/Transfers/list"
    ),
  options
);
const B2CTransfer = loadable(
  () =>
    import(
      "@src/pages/Operation/B2CReservations/SingleServices/Transfers/list"
    ),
  options
);
const AccommodationDetails = loadable(
  () =>
    import(
      "@src/pages/Operation/B2BReservations/SingleServices/Accommodation/detail"
    ),
  options
);

const AccommodationB2CDetails = loadable(
  () =>
    import(
      "@src/pages/Operation/B2CReservations/SingleServices/Accommodation/details"
    ),
  options
);
const FlightsDetails = loadable(
  () =>
    import(
      "@src/pages/Operation/B2CReservations/SingleServices/Flights/details"
    ),
  options
);

const B2BFlightsDetails = loadable(
  () =>
    import(
      "@src/pages/Operation/B2CReservations/SingleServices/Flights/details"
    ),
  options
);

const TransferDetails = loadable(
  () =>
    import(
      "@src/pages/Operation/B2BReservations/SingleServices/Transfers/details"
    ),
  options
);

const B2CTransferDetails = loadable(
  () =>
    import(
      "@src/pages/Operation/B2CReservations/SingleServices/Transfers/details"
    ),
  options
);

const AddonReservationDetails = loadable(
  () =>
    import(
      "@src/pages/Operation/B2BReservations/SingleServices/Addons/details"
    ),
  options
);

const B2CAddonReservationDetails = loadable(
  () =>
    import(
      "@src/pages/Operation/B2CReservations/SingleServices/Addons/details"
    ),
  options
);

const MultiServices = loadable(
  () => import("@src/pages/Operation/B2BReservations/MultiServices/list"),
  options
);

const MultiServicesDetail = loadable(
  () => import("@src/pages/Operation/B2BReservations/MultiServices/details"),
  options
);

const B2CGuestsList = loadable(
  () => import("@src/pages/Operation/Guests/B2C/list"),
  options
);

const B2CGuestDetail = loadable(
  () => import("@src/pages/Operation/Guests/B2C/detail"),
  options
);

const SSRestaurants = loadable(
  () =>
    import(
      "@src/pages/Operation/B2BReservations/SingleServices/Restaurants/list"
    ),
  options
);

const SSRestaurantsDetail = loadable(
  () =>
    import(
      "@src/pages/Operation/B2BReservations/SingleServices/Restaurants/detail"
    ),
  options
);

const SSCoordinators = loadable(
  () =>
    import(
      "@src/pages/Operation/B2BReservations/SingleServices/Coordinators/list"
    ),
  options
);

const SSCoordinatorsDetail = loadable(
  () =>
    import(
      "@src/pages/Operation/B2BReservations/SingleServices/Coordinators/detail"
    ),
  options
);

const SSGeneralServices = loadable(
  () =>
    import(
      "@src/pages/Operation/B2BReservations/SingleServices/GeneralServices/list"
    ),
  options
);

const SSGeneralServicesDetail = loadable(
  () =>
    import(
      "@src/pages/Operation/B2BReservations/SingleServices/GeneralServices/detail"
    ),
  options
);

const APIFerries = loadable(
  () =>
    import(
      "@src/pages/Operation/B2BReservations/SingleServices/APIFerries/list"
    ),
  options
);

const APIFerriesB2C = loadable(
  () =>
    import(
      "@src/pages/Operation/B2CReservations/SingleServices/APIFerries/list"
    ),
  options
);

const SSFerries = loadable(
  () =>
    import("@src/pages/Operation/B2BReservations/SingleServices/Ferries/list"),
  options
);

const SSFerriesDetail = loadable(
  () =>
    import(
      "@src/pages/Operation/B2BReservations/SingleServices/Ferries/detail"
    ),
  options
);

const APIFerriesDetails = loadable(
  () =>
    import(
      "@src/pages/Operation/B2BReservations/SingleServices/APIFerries/detail"
    ),
  options
);

const APIFerriesB2CDetails = loadable(
  () =>
    import(
      "@src/pages/Operation/B2CReservations/SingleServices/APIFerries/detail"
    ),
  options
);

const APITrains = loadable(
  () =>
    import(
      "@src/pages/Operation/B2BReservations/SingleServices/APITrains/list"
    ),
  options
);

const APITrainsB2C = loadable(
  () =>
    import(
      "@src/pages/Operation/B2CReservations/SingleServices/APITrains/list"
    ),
  options
);

const APITrainsDetails = loadable(
  () =>
    import(
      "@src/pages/Operation/B2BReservations/SingleServices/APITrains/detail"
    ),
  options
);

const APITrainsB2CDetails = loadable(
  () =>
    import(
      "@src/pages/Operation/B2CReservations/SingleServices/APITrains/detail"
    ),
  options
);

const SSTrains = loadable(
  () =>
    import("@src/pages/Operation/B2BReservations/SingleServices/Trains/list"),
  options
);

const SSTrainsDetail = loadable(
  () =>
    import("@src/pages/Operation/B2BReservations/SingleServices/Trains/detail"),
  options
);

// ============================= archive =============================
const TripTemplatesList = loadable(
  () => import("@src/pages/Project/TripPlan/Archive/TripTemplates"),
  options
);

const MyOffersInProgress = loadable(
  () => import("@src/pages/Project/TripPlan/Archive/MyOffers/InProgress"),
  options
);

const MyOffersSent = loadable(
  () => import("@src/pages/Project/TripPlan/Archive/MyOffers/Sent"),
  options
);

const MyOffersBooked = loadable(
  () => import("@src/pages/Project/TripPlan/Archive/MyOffers/Booked"),
  options
);

const MyOffersAccepted = loadable(
  () => import("@src/pages/Project/TripPlan/Archive/MyOffers/Accepted"),
  options
);

const MyOffersRejected = loadable(
  () => import("@src/pages/Project/TripPlan/Archive/MyOffers/Rejected"),
  options
);

// ============================ quotations ===========================
const MyQuotationsUnsent = loadable(
  () => import("@src/pages/Project/QuotationRequest/unsent"),
  options
);

const MyQuotationsSent = loadable(
  () => import("@src/pages/Project/QuotationRequest/sent"),
  options
);

const UnassignedQuotations = loadable(
  () => import("@src/pages/Project/QuotationRequest/unassigned"),
  options
);

const AssignedQuotations = loadable(
  () => import("@src/pages/Project/QuotationRequest/assigned"),
  options
);

const RejectedQuotations = loadable(
  () => import("@src/pages/Project/QuotationRequest/rejected"),
  options
);

// ======================== MANAGEMENT CONSOLE =======================
const Products = loadable(
  () => import("@src/pages/ManagementConsole/Pricing/Products"),
  options
);

const UsersNetwork = loadable(
  () => import("@src/pages/ManagementConsole/Pricing/Users"),
  options
);

const Manage = loadable(
  () => import("@src/pages/ManagementConsole/Subscription/Manage/NEW/index.js"),
  options
);

// const Manage = loadable(
//   () => import("@src/pages/ManagementConsole/SubscriptionNew/Manage/index.js"),
//   options
// );

const PaymentMethods = loadable(
  () => import("@src/pages/ManagementConsole/Billing/PaymentMethods"),
  options
);

const SubscriptionFee = loadable(
  () => import("@src/pages/ManagementConsole/CostExplore/Usage"),
  options
);

const AccountStatement = loadable(
  () => import("@src/pages/ManagementConsole/AccountStatement"),
  options
);

const BillAndPayments = loadable(
  () => import("@src/pages/ManagementConsole/Billing/BillAndPayments"),
  options
);

const Purchases = loadable(
  () => import("@src/pages/ManagementConsole/Business/Purchases"),
  options
);

const Sales = loadable(
  () => import("@src/pages/ManagementConsole/Business/Sales"),
  options
);

const Payable = loadable(
  () => import("@src/pages/ManagementConsole/Business/Accounts/Payable"),
  options
);

const Receivable = loadable(
  () => import("@src/pages/ManagementConsole/Business/Accounts/Receivable"),
  options
);

const B2CAccountStatement = loadable(
  () => import("@src/pages/ManagementConsole/B2CAccountStatement/index.js"),
  options
);

//==========================INIT PAGE======================================

const ProjectInitPage = loadable(
  () => import("@src/pages/InitPage/ProjectInitPage"),
  options
);

const AdminInitPage = loadable(
  () => import("@src/pages/InitPage/AdminInitPage"),
  options
);

const NetworkInitPage = loadable(
  () => import("@src/pages/InitPage/NetworkInitPage"),
  options
);

const OperationInitPage = loadable(
  () => import("@src/pages/InitPage/OperationInitPage"),
  options
);

const MyLibraryInitPage = loadable(
  () => import("@src/pages/InitPage/MyLibrary"),
  options
);

const ManagementConsoleInitPage = loadable(
  () => import("@src/pages/InitPage/ManagementConsoleInitPage"),
  options
);
// ========================== CONTROL PANEL ==========================
const Communications = loadable(
  () => import("@src/pages/Admin/ControlPanel/Communications/list"),
  options
);

const ProductTermsAndConditions = loadable(
  () => import("@src/pages/Admin/ControlPanel/ProductTermsAndConditions"),
  options
);

const BookingRemarks = loadable(
  () => import("@src/pages/Admin/ControlPanel/BookingRemarks"),
  options
);

const CurrencySetup = loadable(
  () => import("@src/pages/Admin/ControlPanel/CurrencySetup/list"),
  options
);

const InvoiceSetupList = loadable(
  () => import("@src/pages/Admin/ControlPanel/InvoiceSetup/list"),
  options
);

const AddOn = loadable(
  () => import("@src/pages/Operation/CustomServices/AddOns/AddOn"),
  options
);
// ============================= VENDORS =============================
const ActivityRentalVendors = loadable(
  () => import("@src/pages/Network/Vendors/Activity/list"),
  options
);

const ACTVendorDetails = loadable(
  () => import("@src/pages/Network/Vendors/Activity/detail"),
  options
);

const AccommodationVendors = loadable(
  () => import("@src/pages/Network/Vendors/Accommodation/list"),
  options
);

const ACCVendorDetails = loadable(
  () => import("@src/pages/Network/Vendors/Accommodation/detail"),
  options
);

const CarRentalVendors = loadable(
  () => import("@src/pages/Network/Vendors/CarRental/list"),
  options
);

const CARVendorDetails = loadable(
  () => import("@src/pages/Network/Vendors/CarRental/detail"),
  options
);

const FerryVendors = loadable(
  () => import("@src/pages/Network/Vendors/Ferry/list"),
  options
);

const FERVendorDetails = loadable(
  () => import("@src/pages/Network/Vendors/Ferry/detail"),
  options
);

const RestaurantRentalVendors = loadable(
  () => import("@src/pages/Network/Vendors/Restaurant/list"),
  options
);

const TrainRentalVendors = loadable(
  () => import("@src/pages/Network/Vendors/Train/list"),
  options
);

const TRAVendorDetails = loadable(
  () => import("@src/pages/Network/Vendors/Train/detail"),
  options
);

const TransferVendors = loadable(
  () => import("@src/pages/Network/Vendors/Transfer/list"),
  options
);

const TRFVendorDetails = loadable(
  () => import("@src/pages/Network/Vendors/Transfer/detail"),
  options
);

const TransportationFlightVendors = loadable(
  () => import("@src/pages/Network/Vendors/Transportation/Flights/list"),
  options
);

const FLVendorDetails = loadable(
  () => import("@src/pages/Network/Vendors/Transportation/Flights/detail"),
  options
);

// ============================ My Library ===========================
const MyLibraryAddons = loadable(
  () => import("@src/pages/MyLibrary/Addons"),
  options
);
const GeneralServices = loadable(
  () => import("@src/pages/MyLibrary/GeneralServices"),
  options
);
const GeneralServiceDetailView = loadable(
  () => import("@src/pages/MyLibrary/GeneralServices/detail"),
  options
);
const HotelContractsList = loadable(
  () => import("@src/pages/MyLibrary/Hotels"),
  options
);
const HotelContractDetailsView = loadable(() =>
  import("@src/pages/MyLibrary/Hotels/detail")
);
const RestaurantContractsList = loadable(
  () => import("@src/pages/MyLibrary/Restaurants"),
  options
);
const RestaurantDetail = loadable(
  () => import("@src/pages/MyLibrary/Restaurants/detail"),
  options
);
const CoordinatorContractsList = loadable(
  () => import("@src/pages/MyLibrary/Coordinators"),
  options
);
const CoordinatorDetail = loadable(
  () => import("@src/pages/MyLibrary/Coordinators/detail"),
  options
);
// ============================ WHOLESALES ===========================
const Wholesales = loadable(
  () => import("@src/pages/Project/Packages/Wholesales"),
  options
);

const SubEntitiesStatement = loadable(
  () => import("@src/pages/ManagementConsole/SubentitiesStatement"),
  options
);

// ==================== B2C Distribution Channels ====================
const B2CDistChannelsList = loadable(
  () => import("@src/pages/Network/B2CDistributionChannels/list"),
  options
);

const B2CDistChannelsDetailView = loadable(
  () => import("@src/pages/Network/B2CDistributionChannels/detail/index.js"),
  options
);

const ImageBank = loadable(
  () => import("@src/pages/MyLibrary/ImageBank/index.js"),
  options
);

const COMPONENT_MAP = {
  UserProfile: UserProfile,
  UserListProfile: UserListProfile,
  // ======================= Company: Company, ======================
  Users: UsersList,
  CompanyProfile: CompanyProfile,
  // ================ sales and suppliers and members ================
  Branches: Branches,
  SubAgents: SubAgents,
  IndependentAgents: IndependentAgents,
  Affiliates: Affiliates,
  Providers: Providers,
  BranchesProfile: BranchesProfile,
  SubAgentsProfile: SubAgentsProfile,
  IndependentAgentsProfile: IndependentAgentsProfile,
  AffiliatesProfile: AffiliatesProfile,
  SuppliersProfile: SuppliersProfile,
  Members: Members,
  MyConnections: MyConnections,
  Invitations: Invitations,
  InvitationsSend,
  // ============================ Invoice ============================
  InvoiceList,
  InvoiceDetail,
  // ============================ Project ============================
  SiSiBid,
  BiddingRequest,
  CurrentBid: CurrentBid,
  BiddingTrips,
  BiddingRequests,
  BiddingRequestsForMember,
  BiddingFlow,
  Accommodation,
  AccommodationB2BReservationsList,
  AccommodationB2CReservationsList,
  Flights,
  AddonReservationsLists,
  B2BAddonReservationsLists,
  AddonReservationDetails,
  B2CAddonReservationDetails,
  Packages,
  Tours,
  Transfer,
  B2CTransfer,
  MultiServices,
  MultiServicesDetail,
  AccommodationDetails,
  AccommodationB2CDetails,
  FlightsDetails,
  TransferDetails,
  B2CTransferDetails,
  TripPlanner,
  // =========================== OPERATION ===========================
  B2CGuestsList,
  B2CGuestDetail,
  SSRestaurants,
  SSRestaurantsDetail,
  SSGeneralServices,
  SSGeneralServicesDetail,
  APIFerries,
  APIFerriesB2C,
  SSFerries,
  SSFerriesDetail,
  APIFerriesDetails,
  APIFerriesB2CDetails,
  APITrainsDetails,
  APITrainsB2CDetails,
  APITrains,
  APITrainsB2C,
  SSTrains,
  SSTrainsDetail,
  SSCoordinators,
  SSCoordinatorsDetail,
  B2BCoachReservationsLists,
  B2BCoachReservationsDetail,
  B2BAdhocAccReservationsLists,
  B2BContractedAccReservationsLists,
  B2BAdhocAccReservationsDetail,
  B2BContractedAccReservationsDetail,
  B2BActivitiesReservationsLists,
  B2BActivitiesReservationsDetails,
  B2CActivitiesReservationsLists,
  B2CActivitiesReservationsDetails,
  CarRentalB2BList,
  CarRentalB2CList,
  CarRentalB2BDetail,
  CarRentalB2CDetail,
  FlightsB2CReservationsList,
  B2BFlightsDetails,
  // =========================== QUOTATIONS ==========================
  QuotationRequest,
  MyQuotationsUnsent,
  MyQuotationsSent,
  UnassignedQuotations,
  AssignedQuotations,
  RejectedQuotations,
  // ============================ ARCHIVE ============================
  TripTemplatesList,
  MyOffersSent,
  MyOffersInProgress,
  MyOffersBooked,
  MyOffersAccepted,
  MyOffersRejected,
  // ========================== MARKETPOLICY =========================
  GroupPolicy,
  OneToOne,
  GeoPolicy,
  InnerGroupPolicy,
  B2CPolicy,
  // ======================= MANAGEMENT CONSOLE ======================
  Products,
  UsersNetwork,
  Manage,
  PaymentMethods,
  SubscriptionFee,
  BillAndPayments,
  AccountStatement,
  Purchases,
  Sales,
  Payable,
  Receivable,
  B2CAccountStatement,
  //============================INIT PAGE================================
  ProjectInitPage,
  AdminInitPage,
  NetworkInitPage,
  OperationInitPage,
  MyLibraryInitPage,
  ManagementConsoleInitPage,
  // ======================== CUSTOM SERVICES ========================
  AddOn,
  // ========================= CONTROL PANEL =========================
  Communications,
  ProductTermsAndConditions,
  BookingRemarks,
  CurrencySetup,
  InvoiceSetupList,
  CompanyStampsList,
  // ============================ VENDORS ============================
  ActivityRentalVendors,
  ACTVendorDetails,
  AccommodationVendors,
  ACCVendorDetails,
  CarRentalVendors,
  CARVendorDetails,
  FerryVendors,
  FERVendorDetails,
  RestaurantRentalVendors,
  TrainRentalVendors,
  TRAVendorDetails,
  TransferVendors,
  TRFVendorDetails,
  TransportationFlightVendors,
  FLVendorDetails,
  // =========================== MY LIBRARY ==========================
  MyLibraryAddons,
  GeneralServices,
  GeneralServiceDetailView,
  HotelContractsList,
  HotelContractDetailsView,
  RestaurantContractsList,
  RestaurantDetail,
  CoordinatorContractsList,
  CoordinatorDetail,
  // =========================== WHOLESALES ==========================
  Wholesales,
  SubEntitiesStatement,
  B2CDistChannelsList,
  B2CDistChannelsDetailView,
  ImageBank,
};

let ROUTES = [
  { path: "/my-profile", component: "UserProfile" },
  { path: "/project", component: "ProjectInitPage" },
  { path: "/admin", component: "AdminInitPage" },
  { path: "/network", component: "NetworkInitPage" },
  { path: "/operation", component: "OperationInitPage" },
  { path: "/my-library", component: "MyLibraryInitPage" },
  { path: "/management-console", component: "ManagementConsoleInitPage" },
  { path: "/admin/users/profile", component: "UserListProfile" },
  { path: "/network/subagents/profile/:id", component: "SubAgentsProfile" },
  { path: "/network/branches/profile/:id", component: "BranchesProfile" },
  {
    path: "/network/independentagents/profile/:id",
    component: "IndependentAgentsProfile",
  },
  { path: "/network/affiliates/profile", component: "AffiliatesProfile" },
  { path: "/network/suppliers/profile", component: "SuppliersProfile" },
  { path: "/project/bid/new-bid/:id", component: "SiSiBid" },
  { path: "/admin/financial/invoices", component: "InvoiceList" },
  { path: "/admin/financial/invoices/:uid", component: "InvoiceDetail" },

  // ============================ NETWORK ============================
  {
    path: "/network/vendors/accommodation/:id",
    component: "ACCVendorDetails",
  },
  {
    path: "/network/vendors/activity/:id",
    component: "ACTVendorDetails",
  },
  {
    path: "/network/vendors/car-rentals/:id",
    component: "CARVendorDetails",
  },
  {
    path: "/network/vendors/ferry/:id",
    component: "FERVendorDetails",
  },
  {
    path: "/network/vendors/train/:id",
    component: "TRAVendorDetails",
  },
  {
    path: "/network/vendors/transfer/:id",
    component: "TRFVendorDetails",
  },
  {
    path: "/network/vendors/flight/:id",
    component: "FLVendorDetails",
  },

  // =========================== OPERATION ===========================
  {
    path: "/operation/B2B-reservations/multi-services",
    component: "MultiServices",
  },
  {
    path: "/operation/B2B-reservations/multi-services/:id",
    component: "MultiServicesDetail",
  },
  {
    path: "/operation/guests/b2c",
    component: "B2CGuestsList",
  },
  {
    path: "/operation/guests/b2c/:id",
    component: "B2CGuestDetail",
  },
  // ========================== DETAIL PAGE ==========================
  {
    path: "/operation/reservations/accommodations/:reference",
    component: "AccommodationDetails",
  },
  {
    path: "/operation/reservations/accommodations/b2c/:reference",
    component: "AccommodationB2CDetails",
  },
  { path: "/operation/reservations/flights/:id", component: "FlightsDetails" },
  {
    path: "/operation/reservations/flights/b2b/:id",
    component: "B2BFlightsDetails",
  },
  {
    path: "/operation/reservations/transfer/:reference",
    component: "TransferDetails",
  },
  {
    path: "/operation/reservations/transfer/b2c/:reference",
    component: "B2CTransferDetails",
  },
  {
    path: "/operation/B2B-reservations/addons/:reference",
    component: "AddonReservationDetails",
  },
  {
    path: "/operation/B2C-reservations/addons/:reference",
    component: "B2CAddonReservationDetails",
  },
  { path: "/admin/market/b2b/group/:id", component: "GroupPolicyDetails" },
  {
    path: "/project/bid/archive/bidding-requests/:id",
    component: "BiddingRequest",
  },
  {
    path: "/my-library/services/hotel-contracts/:id",
    component: "HotelContractDetailsView",
  },
  {
    path: "/my-library/services/restaurant-contracts/:id",
    component: "RestaurantDetail",
  },
  {
    path: "/my-library/services/coordinator-contracts/:id",
    component: "CoordinatorDetail",
  },
  {
    path: "/operation/B2B-reservations/single-services/restaurants/:reference",
    component: "SSRestaurantsDetail",
  },
  {
    path: "/operation/B2B-reservations/single-services/coordinators/:reference",
    component: "SSCoordinatorsDetail",
  },
  {
    path: "/operation/B2B-reservations/single-services/car-rental/:reference",
    component: "CarRentalB2BDetail",
  },
  {
    path: "/operation/B2C-reservations/single-services/car-rental/:reference",
    component: "CarRentalB2CDetail",
  },
  {
    path: "/operation/B2B-reservations/single-services/general-services/:reference",
    component: "SSGeneralServicesDetail",
  },
  {
    path: "/operation/B2B-reservations/single-services/coaches/:reference",
    component: "B2BCoachReservationsDetail",
  },
  {
    path: "/operation/B2B-reservations/single-services/adhoc-accommodations/:reference",
    component: "B2BAdhocAccReservationsDetail",
  },
  {
    path: "/operation/B2B-reservations/single-services/contracted-accommodations/:reference",
    component: "B2BContractedAccReservationsDetail",
  },
  {
    path: "/operation/B2B-reservations/single-services/ferries/:reference",
    component: "SSFerriesDetail",
  },
  {
    path: "/operation/B2B-reservations/single-services/api-ferries/:reference",
    component: "APIFerriesDetails",
  },
  {
    path: "/operation/B2C-reservations/single-services/api-ferries/:reference",
    component: "APIFerriesB2CDetails",
  },
  {
    path: "/operation/B2B-reservations/single-services/api-trains/:reference",
    component: "APITrainsDetails",
  },
  {
    path: "/operation/B2C-reservations/single-services/api-trains/:reference",
    component: "APITrainsB2CDetails",
  },
  {
    path: "/operation/B2B-reservations/single-services/trains/:reference",
    component: "SSTrainsDetail",
  },
  {
    path: "/operation/B2B-reservations/single-services/activities/:reference",
    component: "B2BActivitiesReservationsDetails",
  },
  {
    path: "/operation/B2C-reservations/single-services/activities/:reference",
    component: "B2CActivitiesReservationsDetails",
  },
  // ======================== END DETAIL PAGES =======================
  // =================== B2C DISTRIBUTION CHANNELS ===================
  {
    path: "/network/b2c-distribution-channels",
    component: "B2CDistChannelsList",
  },
  {
    path: "/network/b2c-distribution-channels/channels/:id",
    component: "B2CDistChannelsDetailView",
  },
  {
    path: "/my-library/image-bank",
    component: "ImageBank",
  },
  {
    path: "/my-library/general-services",
    component: "GeneralServices",
  },
  {
    path: "/my-library/hotel-contracts",
    component: "HotelContractsList",
  },
  {
    path: "/my-library/restaurant-contracts",
    component: "RestaurantContractsList",
  },
  {
    path: "/my-library/coordinator-contracts",
    component: "CoordinatorContractsList",
  },
  {
    path: "/my-library/general-services/:id",
    component: "GeneralServiceDetailView",
  },
];

export function registerRoutes(menu, path = "") {
  menu.forEach((item) => {
    if (!item.subMenu) {
      if (!item.path) {
        return;
      }

      const { allowed_user_types = [], component } = item;
      ROUTES.push({ path: path + item.path, allowed_user_types, component });
    } else {
      registerRoutes(item.subMenu, path + item.path);
    }
  });
}

registerRoutes(initialMenu);

export const SidebarContext = React.createContext({ open: true });

function attachMountLSEvent() {
  window.addEventListener("storage", function () {
    const userData = getLSUserData();

    if (!userData.user_info) {
      window.location.reload();
    }
  });
}

const BreadCrumbs = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const breadcrumbs = pathname.split("/");

  const generateIcon = () => {
    switch (_.toUpper(breadcrumbs[1])) {
      case _.toUpper("Project"):
        return "ri:stack-fill";
      case _.toUpper("Admin"):
        return "material-symbols:person";
      case _.toUpper("Network"):
        return "zondicons:network";
      case _.toUpper("Operation"):
        return "solar:widget-bold";
      case _.toUpper("My-library"):
        return "solar:library-bold";
      case _.toUpper("Management-console"):
        return "mingcute:settings-6-fill";
      default:
        return "";
    }
  };

  const { closeSidebar } = useContext(SidebarContext);
  const { openSidebar } = useContext(SidebarContext);
  const mobileView = useContext(MobileViewContext);

  useEffect(() => {
    if (pathname.split("/").length <= 2) {
      openSidebar();
      return;
    }

    closeSidebar();
  }, [pathname]);

  return (
    <div className="main_section__header" data-mobile-view={mobileView}>
      <InlineIcon
        className="main_section__header__title-icon"
        icon={generateIcon()}
        onClick={() => {
          history.push("/");
        }}
      />{" "}
      {breadcrumbs.map((pth, idx) => (
        <div className="main_section__header__parts" key={idx}>
          <span
            // onClick={() => {
            //   console.log("path = ", pth);
            //   history.push(`/${pth}`);
            // }}
            className="main_section__header__part">
            {_.startCase(pth)}
          </span>
          {idx !== breadcrumbs.length - 1 && (
            <InlineIcon
              className="main_section__header__arrow"
              icon="ri:arrow-right-s-line"
            />
          )}
        </div>
      ))}
    </div>
  );
};

const Container = ({
  groups,
  userMeta,
  sideBarIsOpenStatus,
  authModalShown,
  onShowAuthModal,
  hideAuthModal,
  initAppData,
  refreshTkn,
}) => {
  const history = useHistory();
  const [tokenInterval, setTokenInterval] = useState(null);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const { company_type } = useSelector((state) => getUserMetaSelector(state));

  const {
    isProfileLoading,
    isProfileComplete,
    isCompanyProfileLoading,
    isCompanyProfileComplete,
  } = useProfileAndCompanyCompleteness();

  function setTknInterval() {
    const tokenInterval = window.setInterval(async () => {
      const userData = getLSUserData();
      const time_remaining = moment(parseInt(userData.rtt, 10)).diff(
        moment(),
        "seconds"
      );

      const res = await refreshTkn();

      // Only show the auth modal if less than one repetion remains.
      if (!res && time_remaining < 120) {
        onShowAuthModal();
        window.clearInterval(tokenInterval);
      }
    }, 120000);

    setTokenInterval(tokenInterval);
  }

  async function validateSubscription() {
    const subValidity = await getSubscriptionValidity();
    setSubscriptionValidityData(subValidity);

    if (!_.get(subValidity, "payment_method")) {
      if (groups.includes("member")) {
        notifyPaymentNotSetupWarning(history);
      }

      return;
    }

    if (["void", "uncollectible"].includes(subValidity.payment.status)) {
      accoundDisabledError();
    } else if (
      subValidity.payment.status !== "paid" &&
      groups.includes("management_console")
    ) {
      paymentDueInfo(history);
    }
  }

  function authVerifier() {
    const result = verifyUserInfo();

    if (!result.verified) {
      onShowAuthModal();
      return false;
    } else if (authModalShown) {
      hideAuthModal();
    }
    return true;
  }

  function renderRoutes({ company_type }) {
    const onlyCompanyProfileAllowed = !isCompanyProfileComplete;
    return ROUTES.filter((r) => {
      const allowedUserTypes = r?.allowed_user_types ?? [];
      if (
        allowedUserTypes.length > 0 &&
        !allowedUserTypes.includes(company_type)
      )
        return false;

      if (
        onlyCompanyProfileAllowed &&
        ["/admin/company/profile"].includes(r.path)
      ) {
        return true;
      } else if (onlyCompanyProfileAllowed) {
        return false;
      }

      return true;
    }).map((route) => {
      return (
        <Route
          key={route.path}
          path={route.path}
          exact
          render={(routeProps) => {
            const Comp = COMPONENT_MAP[route.component];
            return (
              <PageErrorBoundary>
                <Comp {...routeProps} />
              </PageErrorBoundary>
            );
          }}
        />
      );
    });
  }

  function renderIdRoutes() {
    // const onlyMyProfileAllowed = !isProfileComplete;
    // const onlyCompanyProfileAllowed = !isCompanyProfileComplete;

    // if (onlyMyProfileAllowed || onlyCompanyProfileAllowed) {
    //   return null;
    // }

    return [
      ...IDROUTES.map((r) => (
        <Route
          key={r.path}
          path={r.path}
          render={(routeProps) => {
            const Comp = COMPONENT_MAP[r.component];
            return (
              <PageErrorBoundary>
                <Comp {...routeProps} />
              </PageErrorBoundary>
            );
          }}
        />
      )),
    ];
  }

  async function componentDidMount() {
    attachMountLSEvent();

    if (!authVerifier()) {
      await refreshTkn();
    }

    // Start trying to refresh the token every 4 minutes;
    setTknInterval();
    await initAppData();

    if (emailIsVerified()) {
      if (!isProfileLoading && !isProfileComplete) {
        notifyIncompletePersonalProfileWarning({ history });
        return;
      } else if (!isCompanyProfileLoading && !isCompanyProfileComplete) {
        notifyIncompleteCompanyProfileWarning({ history });
        return;
      }
    } else {
      await refreshTkn();
      return;
    }

    await validateSubscription(history);
  }

  useEffect(() => {
    componentDidMount();
    return () => window.clearInterval(tokenInterval);
  }, [isCompanyProfileLoading || isProfileLoading]);

  useEffect(() => {
    // If for some reason user data not in localStorage refresh the page.
    // This will initiate the whole authentication check from scratch.
    window.addEventListener("storage", function () {
      const userData = getLSUserData();
      if (!userData.user_info) window.location.reload();
    });
  }, []);

  return _.isEmpty(userMeta) ? (
    <div className="InitialLoader">
      <Loader content="loading..." size="lg" />
      <AuthModal />
    </div>
  ) : (
    <SidebarContext.Provider
      value={{
        open: !sideBarIsOpenStatus,
        sidebarCollapsed,
        closeSidebar: () => setSidebarCollapsed(true),
        openSidebar: () => setSidebarCollapsed(false),
      }}>
      <div className="Container">
        <AuthModal />
        {/* <EmailVerifyModal /> */}
        <MainHeader />
        <section>
          <SideBar
            sidebarCollapsed={sidebarCollapsed}
            setSidebarCollapsed={setSidebarCollapsed}
          />
          <main className="main_section">
            <BreadCrumbs />
            <div className="main_section__container">
              <Switch>
                {renderIdRoutes()}
                {renderRoutes({ company_type })}
                <Route path="/noauth" component={NoAuth} />
                <Route path="/404" component={RedirectAs404} />
                <Redirect from="" to="/project/trip/new-trip" />
                <Route component={RedirectAs404} />
              </Switch>
            </div>
          </main>
        </section>
      </div>
    </SidebarContext.Provider>
  );
};
Container.defaultProps = { groups: [], sideBarIsOpenStatus: false };
Container.propTypes = {
  groups: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  authModalShown: PropTypes.bool.isRequired,
  userMeta: PropTypes.object.isRequired,
  userObjOptions: PropTypes.object.isRequired,
  userGroups: PropTypes.array,
  getCountryCodeInfo: PropTypes.func,
  countryCodeInfo: PropTypes.array,
  sideBarIsOpenStatus: PropTypes.bool.isRequired,
  onShowAuthModal: PropTypes.func.isRequired,
  hideAuthModal: PropTypes.func.isRequired,
  initAppData: PropTypes.func.isRequired,
  refreshTkn: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  const { groups } = state.userPerms;

  return {
    groups,
    authModalShown: state.authModal.show,
    userMeta: state.userMeta,
    userObjOptions: state.userObjOptions,
    userGroups: state.userGroups,
    countryCodeInfo: state.countryCodeInfo,
    sideBarIsOpenStatus: state.toggleAllContainerStatus,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onShowAuthModal: () => dispatch(showAuthModal()),
    hideAuthModal: () => dispatch(hideAuthModal()),
    initAppData: () => dispatch(initializeAppData()),
    refreshTkn: () => dispatch(refreshIdToken(true)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Container));
