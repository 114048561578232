import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Loader } from "rsuite";
import { variables } from "@src/jsssetup";
import {
  fetchPublicCoordResDetails,
  patchPublicCoordReservation,
} from "@src/api/Operation/Reservations/Coordinators";
import { DateTime } from "luxon";

import { VehicleInformation } from "./vehicle_info";
import { toast } from "react-toastify";
import { postReservationFeedback } from "@src/api/Operation/Reservations/feedback";
import { Itinerary } from "./itinerary";
import {
  Actions,
  CommonFeedback,
  ContactInformation,
  RequestInfo,
  Stepper,
} from "../common";
import { cxlRuleTemplate } from "../common/cxl_policies";
import { commonValidateFeedback } from "../common/validators";
import {
  genericButtonsStyles,
  genericInfoGroupStyles,
  genericRsrvFeebackStyles,
  rsrvFeebackStyles,
} from "../common/styles";
import { useFeedbackRemarks, useSupplierInfo } from "../common/hooks";
import { onReject, onSubmit, payloader, retrieverFn } from "../common/helpers";
import { basicInfoStylesObj } from "../accommodation/common";

const basicInfoStyles = createUseStyles({
  ...genericInfoGroupStyles,
  ...genericButtonsStyles,
  BasicInfo: genericRsrvFeebackStyles.card,
  header: genericRsrvFeebackStyles.cardHeader,
  body: genericRsrvFeebackStyles.cardBody,

  infoGroup3: {
    ...basicInfoStylesObj.groupCard,
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr)",
    gridGap: variables.normal_gap,
  },
  infoGroup4: {
    ...basicInfoStylesObj.groupCard,
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridGap: variables.normal_gap,
  },
  [`@media ${variables.media.bigscreen}`]: {
    infoGroup3: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
  },
});
// const basicInfoStyles = createUseStyles({
//   BasicInfo: cardStyles.card,
//   header: cardStyles.header,
//   table: tableStyles.table,
//   head: tableStyles.head,
//   cell: tableStyles.cell,
// });
function BasicInfo({ reservation }) {
  const classes = basicInfoStyles();
  return (
    <div className={classes.BasicInfo}>
      <div className={classes.header}>
        <h5>Basic Information</h5>
      </div>
      <div className={classes.body}>
        <div className={classes.infoGroup3}>
          <div className={classes.infoCouple}>
            <span className={classes.infoCoupleTitle}>Name</span>
            <span className={classes.infoCoupleValue}>{reservation.name}</span>
          </div>
          <div className={classes.infoCouple}>
            <span className={classes.infoCoupleTitle}>Travel Start</span>
            <span className={classes.infoCoupleValue}>
              {DateTime.fromISO(reservation.travel_start_date).toLocaleString(
                DateTime.DATE_MED_WITH_WEEKDAY
              )}
            </span>
          </div>
          <div className={classes.infoCouple}>
            <span className={classes.infoCoupleTitle}>Travel End</span>
            <span className={classes.infoCoupleValue}>
              {DateTime.fromISO(reservation.travel_end_date).toLocaleString(
                DateTime.DATE_MED_WITH_WEEKDAY
              )}
            </span>
          </div>
        </div>

        <div className={classes.infoGroup4}>
          <div className={classes.infoCouple}>
            <span className={classes.infoCoupleTitle}>Destinations</span>
            <span className={classes.infoCoupleValue}>
              {_.get(reservation, "destinations", []).length}
            </span>
          </div>
          <div className={classes.infoCouple}>
            <span className={classes.infoCoupleTitle}>Pax</span>
            <span className={classes.infoCoupleValue}>{reservation.pax}</span>
          </div>
          <div className={classes.infoCouple}>
            <span className={classes.infoCoupleTitle}>Adults</span>
            <span className={classes.infoCoupleValue}>
              {reservation.adults}
            </span>
          </div>
          <div className={classes.infoCouple}>
            <span className={classes.infoCoupleTitle}>Children</span>
            <span className={classes.infoCoupleValue}>
              {reservation.children}
            </span>
          </div>
        </div>
      </div>
      {/* <table className={classes.table}>
        <thead className={classes.head}>
          <tr>
            <th>Name</th>
            <th>Travel Start</th>
            <th>Travel End</th>
            <th>Destinations</th>
            <th>Pax</th>
            <th>Adults</th>
            <th>Children</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={classes.cell}>{reservation.name}</td>
            <td className={classes.cell}>
              {DateTime.fromISO(reservation.travel_start_date).toLocaleString(
                DateTime.DATE_MED_WITH_WEEKDAY
              )}
            </td>
            <td className={classes.cell}>
              {DateTime.fromISO(reservation.travel_end_date).toLocaleString(
                DateTime.DATE_MED_WITH_WEEKDAY
              )}
            </td>
            <td className={classes.cell}>
              {_.get(reservation, "destinations", []).length}
            </td>
            <td className={classes.cell}>{reservation.pax}</td>
            <td className={classes.cell}>{reservation.adults}</td>
            <td className={classes.cell}>{reservation.children}</td>
          </tr>
        </tbody>
      </table> */}
    </div>
  );
}
BasicInfo.propTypes = {
  reservation: PropTypes.object.isRequired,
};

function validateFeedback({
  cxl_remarks,
  booking_mode_remarks,
  reservation_remarks,
  payment_mode_remarks,
  itinConfirmData,
  vehicleInfoData,
  request_cxl_policy,
  cxl_rules,
}) {
  const msgs = commonValidateFeedback({
    cxl_remarks,
    booking_mode_remarks,
    reservation_remarks,
    payment_mode_remarks,
    request_cxl_policy,
    cxl_rules,
  });

  if (itinConfirmData.some((day) => !day.confirmed)) {
    msgs.push(
      <span>
        Please confirm all days in the <strong>Itinerary</strong> table.
      </span>
    );
  }

  if (!vehicleInfoData.confirmed) {
    msgs.push(
      <span>
        Please confirm <strong>Vehicle Information</strong>.
      </span>
    );
  }

  return msgs;
}

const styles = createUseStyles({
  ...genericRsrvFeebackStyles,
  CoordinatorReservationFeedback: rsrvFeebackStyles,
  cardBody: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridGap: "1.5rem",
    overflow: "auto",
  },
  leftCol: {
    width: "100%",
  },
  rightCol: {
    width: "100%",
    display: "grid",
    gridGap: variables.normal_gap,
  },
  [`@media ${variables.media.bigscreen}`]: {
    cardBody: {
      gridTemplateColumns: "30% 65%",
    },
  },
});
const CoordinatorResFeedback = () => {
  const [step, setStep] = useState(0);
  const [reservation, setReservation] = useState({});
  const [loading, setLoading] = useState(false);

  const feedRemarks = useFeedbackRemarks();
  const supplierInfo = useSupplierInfo();

  const [cxl_rules, setCxlRules] = useState([cxlRuleTemplate]);
  const [itinConfirmData, setItinConfirmData] = useState([]);
  const [vehicleInfoData, setVehicleInfoData] = useState({});

  const history = useHistory();

  const {
    params: { reference },
  } = useRouteMatch();

  useEffect(() => {
    if (!reference) {
      return;
    }

    retrieverFn({
      reference,
      fn: fetchPublicCoordResDetails,
      history,
      setLoading,
      setReservation: (data) => {
        setReservation(data);
        setVehicleInfoData({ ...data.vehicle_information, confirmed: false });

        setItinConfirmData([
          ...data.daily_data.map((day) => ({
            ...day,
            proposed_amount: day.amount,
            confirmed: false,
            currency: data.currency,
          })),
        ]);
      },
    });
  }, [reference]);

  function nextStepper() {
    if (step === 0) {
      const msgs = validateFeedback({
        cxl_remarks: feedRemarks.cxl_remarks,
        booking_mode_remarks: feedRemarks.booking_mode_remarks,
        reservation_remarks: feedRemarks.reservation_remarks,
        payment_mode_remarks: feedRemarks.payment_mode_remarks,
        itinConfirmData,
        vehicleInfoData,
        request_cxl_policy: reservation.request_cxl_policy,
        cxl_rules,
      });

      if (msgs.length) {
        msgs.forEach((error) => toast.error(error, { duration: 5000 }));
        return;
      }
    }
    setStep((p) => p + 1);
  }

  const classes = styles({
    request_cxl_policy: reservation.request_cxl_policy || false,
  });

  const actionProps = {
    step,
    prevStepper: () => setStep((p) => p - 1),
    nextStepper,
    onSubmit: () => {
      const payload = payloader({
        supplierInfo,
        feedRemarks,
        cxl_rules,
        reference,
        feedback_service_data: { daily_data: itinConfirmData },
        reservation_type: "COO",
      });
      onSubmit({ history, payload, setLoading, postReservationFeedback });
    },
    onReject: () =>
      onReject({
        history,
        reference,
        payload: { status: "RJ" },
        setLoading,
        resPatchFunc: patchPublicCoordReservation,
      }),
  };

  return (
    <div className={classes.CoordinatorReservationFeedback}>
      <Stepper step={step} />
      {loading ? (
        <div className={classes.body}>
          <Loader size="lg" center />
        </div>
      ) : [0, 2].includes(step) ? (
        <div>
          {/* <div className={classes.card}> */}
          {/* {" "}
          <div className={classes.cardHeader}>
             <h5>Reservation Confirmation Panel: {reservation.reference}</h5>
            {" "}
          </div> */}
          <div className={classes.cardBody}>
            <div className={classes.leftCol}>
              <RequestInfo reservation={reservation} />
            </div>
            <div className={classes.rightCol}>
              <BasicInfo reservation={reservation} />
              <Itinerary
                daily_data={itinConfirmData}
                currency={reservation.currency}
                editable={step === 0}
                onChange={(itinerary) => setItinConfirmData(itinerary)}
              />
              <VehicleInformation
                vehicle_information={reservation.vehicle_information}
                initialVehicleInfoData={vehicleInfoData}
                editable={step === 0}
                onChange={(vehicle_information) =>
                  setVehicleInfoData(vehicle_information)
                }
              />
              <CommonFeedback
                reservation={reservation}
                feedRemarks={feedRemarks}
                editable={step == 0}
                cxl_rules={cxl_rules}
                setCxlRules={setCxlRules}
              />
              <Actions {...actionProps} />
            </div>
          </div>
        </div>
      ) : (
        <ContactInformation
          {...supplierInfo}
          actionProps={actionProps}
          onChange={(values) => {
            supplierInfo.setSupplierName(values.supplier_name);
            supplierInfo.setSupplierEmail(values.supplier_email);
            supplierInfo.setSupplierPhone(values.supplier_phone);
            supplierInfo.setSupplierRemarks(values.supplier_remarks);
          }}
        />
      )}
    </div>
  );
};
export default CoordinatorResFeedback;
