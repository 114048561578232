import React from "react";
import TransTxt from "@src/components/common/SxFormatMessage";
import _ from "lodash";
import { getDisplayNameFromOptions } from "@src/processors/columnsDataProcessor";
import { formatDate } from "@src/tools/date_tools";
import { lang_mapping } from "@src/tools/lang_tools";
import { archiveColumnsForm } from "@src/forms/Project/Archive";

export const initialBiddingRequestForMemberColumnsSetUp = {
  basic: {
    value: [
      "title",
      "request_by",
      "pax",
      "time_period",
      "tripplanlist_origin",
      "destinations",
      "tripplanlist_return",
      "service_types",
      "in_process",
      "done",
      "sent",
      "total",
      "per_person",
      "creator",
      "created",
      "edited",
    ],
    choices: [
      {
        display_name: <TransTxt id="tripplanlist_trip_title" startCase />,
        key: "title",
      },
      {
        display_name: <TransTxt id="tripplanlist_request_by" startCase />,
        key: "request_by",
      },
      {
        display_name: <TransTxt id="tripplanlist_pax" startCase />,
        key: "pax",
      },
      {
        display_name: <TransTxt id="tripplanlist_selling_price" startCase />,
        key: "selling_price",
      },
      {
        display_name: <TransTxt id="tripplanlist_buying_price" startCase />,
        key: "buying_price",
      },
      {
        display_name: <TransTxt id="tripplanlist_time_period" startCase />,
        key: "time_period",
      },
      {
        display_name: <TransTxt id="tripplanlist_destinations" startCase />,
        key: "destinations",
      },
      {
        display_name: (
          <TransTxt id="tripplanlist_services_category" startCase />
        ),
        key: "service_types",
      },
      {
        display_name: <TransTxt id="in_process" startCase />,
        key: "in_process",
      },
      {
        display_name: <TransTxt id="done" startCase />,
        key: "done",
      },
      {
        display_name: <TransTxt id="sent" startCase />,
        key: "sent",
      },
      {
        display_name: <TransTxt id="tripplanlist_total_price" startCase />,

        key: "total",
      },
      {
        display_name: <TransTxt id="tripplanlist_per_person_price" startCase />,

        key: "per_person",
      },

      {
        display_name: <TransTxt id="creator" startCase />,
        key: "creator",
      },
      {
        display_name: <TransTxt id="created" startCase />,
        key: "created",
      },

      {
        display_name: <TransTxt id="edited" startCase />,
        key: "edited",
      },
    ],
  },
};

export const initialBiddingRequestForMemberColumnsProcessor = {
  title: {
    key: "title",
    getter: (data) => _.get(data, "itinerary_setup.title"),
  },
  request_by: {
    key: "request_by",
    getter: () => "",
  },
  pax: {
    key: "pax",
    getter: (data) => {
      return `${_.get(data, "itinerary_setup.adults")} Adults,${_.get(
        data,
        "itinerary_setup.children"
      )} Children`;
    },
  },
  time_period: {
    key: "main_service",
    getter: (data) => {
      const data_a = formatDate(_.get(data, "origin_data.date"));
      const data_b = formatDate(_.get(data, "return_data.date"));
      return `${data_a}-${data_b}`;
    },
  },
  tripplanlist_origin: {
    key: "tripplanlist_origin",
    getter: (data, options) => {
      const lang = options[0];
      return _.get(data, `origin_data.destination.name_${lang_mapping[lang]}`);
    },
  },
  destinations: {
    key: "destinations",
    getter: (data, options) => {
      const res = _.get(data, "destinations", []);
      const lang = options[0];
      const arr = [];
      res.forEach((item) => {
        arr.push(item[`name_${lang_mapping[lang]}`]);
      });

      return `${arr[0]}(${arr.length})`;
    },
    extraGetter: (data, options) => {
      const res = _.get(data, "destinations", []);
      const lang = options[0];
      const arr = [];
      res.forEach((item) => {
        arr.push(item[`name_${lang_mapping[lang]}`]);
      });

      return arr;
    },
  },
  tripplanlist_return: {
    key: "tripplanlist_return",
    getter: (data, options) => {
      const lang = options[0];
      return _.get(data, `return_data.destination.name_${lang_mapping[lang]}`);
    },
  },
  service_types: {
    key: "service_types",
    getter: (data) => {
      const res = _.get(data, "service_types");
      const arr = [];

      Object.keys(res).map((item) => {
        if (res[item].available) {
          arr.push(
            getDisplayNameFromOptions(item, [
              { display_name: "Transportation", value: "FL" },
              { display_name: "Transfer", value: "TF" },
              { display_name: "Accommodation", value: "ACC" },
            ])
          );
        }
      });
      return `${arr[0]}(${arr.length})`;
    },
    extraGetter: (data) => {
      const res = _.get(data, "service_types");
      const arr = [];
      Object.keys(res).map((item) => {
        if (res[item].available) {
          arr.push(
            getDisplayNameFromOptions(item, [
              { display_name: "Transportation", value: "FL" },
              { display_name: "Transfer", value: "TF" },
              { display_name: "Accommodation", value: "ACC" },
            ])
          );
        }
      });
      return arr;
    },
  },
  in_process: {
    key: "in_process",
    getter: (data) => _.get(data, "bid_request_status_count.IP"),
  },
  done: {
    key: "done",
    getter: (data) => _.get(data, "bid_request_status_count.DO"),
  },
  sent: {
    key: "sent",
    getter: (data) => _.get(data, "bid_request_status_count.SE"),
  },
  total: {
    key: "total",
    getter: (data) => {
      return _.get(data, "final_pricing.selling_price");
    },
  },
  per_person: {
    key: "per_person",
    getter: (data) => {
      return _.get(data, "final_pricing.per_person_selling_price");
    },
  },
  creator: {
    key: "creator",
    getter: () => "",
  },
  created: {
    key: "created",
    getter: (data) => formatDate(data.created),
  },
  edited: {
    key: "edited",
    getter: (data) => formatDate(data.edited),
  },
};

export const initialBiddingRequestForMemberColumnsMapping = _.merge({
  title: {
    key: "title",
  },
  request_by: {
    key: "request_by",
  },
  pax: {
    key: "pax",
  },
  selling_price: {
    key: "selling_price",
  },
  buying_price: {
    key: "buying_price",
  },
  time_period: {
    key: "main_service",
  },
  destinations: {
    key: "destinations",
  },
  service_types: {
    key: "service_types",
  },
  in_process: {
    key: "in_process",
  },
  done: {
    key: "done",
  },
  sent: {
    key: "sent",
  },
  total: {
    key: "total",
  },
  per_person: {
    key: "per_person",
  },
  created: {
    key: "created",
  },
  edited: {
    key: "edited",
  },
  creator: {
    key: "creator",
  },
});

export const biddingRequestsForMembersArchiveColumnsForm = _.merge(
  {},
  archiveColumnsForm,
  {
    per_person: {
      display_name: "Biddings",
      // eslint-disable-next-line react/display-name
      getter: (data) => {
        return <h3>{_.get(data, "bid_requests.length")}</h3>;
      },
      extraGetter: () => {
        return "";
      },
    },
  }
);
