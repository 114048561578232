//================================ LIST VIEW======================================
export const PURCHASES_BEGIN_FETCH_DATA = "PURCHASES_BEGIN_FETCH_DATA";
export const PURCHASES_FETCH_DATA = "PURCHASES_FETCH_DATA";
export const PURCHASES_END_FETCH_DATA = "PURCHASES_END_FETCH_DATA";

//==================================FILTER RELATION================================
export const PURCHASES_INITIALIZE_LIST_FILTER_FORM =
  "PURCHASES_INITIALIZE_LIST_FILTER_FORM";
export const PURCHASES_UPDATE_LIST_FILTER_FORM =
  "PURCHASES_UPDATE_LIST_FILTER_FORM";
export const PURCHASES_APPLY_LIST_FILTER = "PURCHASES_APPLY_LIST_FILTER";
//================================== COLUMNS RELATION====================================
export const PURCHASES_INITIALIZE_LIST_COLUMNS_FORM =
  "PURCHASES_INITIALIZE_LIST_COLUMNS_FORM";
export const PURCHASES_APPLY_LIST_COLUMNS = "PURCHASES_APPLY_LIST_COLUMNS";

//================================= GRAPH===============================
export const PURCHASES_INIT_VIEW_TYPE = "PURCHASES_INIT_VIEW_TYPE";
export const PURCHASES_CHANGE_VIEW_TYPE = "PURCHASES_CHANGE_VIEW_TYPE";
export const PURCHASES_FETCH_GRAPH_DATA = "PURCHASES_FETCH_GRAPH_DATA";
export const PURCHASES_BEGIN_FETCH_GRAPH_DATA =
  "PURCHASES_BEGIN_FETCH_GRAPH_DATA";
export const PURCHASES_END_FETCH_GRAPH_DATA = "PURCHASES_END_FETCH_GRAPH_DATA";
export const PURCHASES_FETCH_SUPPLIER_DATA = "PURCHASES_FETCH_SUPPLIER_DATA";
export const PURCHASES_BEGIN_FETCH_GRAPH_OPTIONS =
  "PURCHASES_BEGIN_FETCH_GRAPH_OPTIONS";
export const PURCHASES_END_FETCH_GRAPH_OPTIONS =
  "PURCHASES_END_FETCH_GRAPH_OPTIONS";
