import _ from "lodash";
import { Modal, IconButton, Icon } from "rsuite";

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { hideServiceCommentsModal } from "@src/actions/Project/TripPlanner/service_comments.js";

// import Description from "@src/pages/Network/B2CDistributionChannels/components/Description.js";
import { getSelectedAccSelector } from "@src/selectors/Project/TripPlanner";

var ServiceCommentsModal = (props) => {
  const { show, remarks, destOrder, onHide } = props;

  if (!show) {
    return null;
  }

  const [localRemarks, setRemarks] = useState();
  useEffect(() => {
    setRemarks(remarks);
  }, [show]);

  return (
    <Modal className="CustomModal" show={show} onHide={onHide} size="md">
      <Modal.Header>
        <Modal.Title>
          <strong>Service Comments</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <Description */}
        {/*   verbose={"Comments"} */}
        {/*   value={localRemarks} */}
        {/*   onChange={(value) => setRemarks(value)} */}
        {/* /> */}
      </Modal.Body>
      <Modal.Footer>
        <IconButton icon={<Icon icon="close" />} onClick={onHide}>
          Cancel
        </IconButton>
        <IconButton
          icon={<Icon icon="check" />}
          color="green"
          onClick={() => onHide({ destOrder, remarks: localRemarks })}>
          Apply
        </IconButton>
      </Modal.Footer>
    </Modal>
  );
};

ServiceCommentsModal.defaultProps = {
  show: false,
  remarks: "",
  destOrder: 0,
};

ServiceCommentsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  destOrder: PropTypes.number.isRequired,
  remarks: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const {
    show,
    destOrder,
    serviceType,
  } = state.tripPlannerServiceCommentsModal;

  const acc = getSelectedAccSelector(state, { destOrder });
  const remarks = _.get(acc, "customer_remarks", "");

  return {
    show,
    remarks,
    destOrder,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onHide: ({ destOrder = 0, remarks = "" } = {}) => {
      dispatch(hideServiceCommentsModal(destOrder, remarks));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceCommentsModal);
