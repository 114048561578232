import _ from "lodash";
import { View, Text, StyleSheet } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import React from "react";
import Html from "react-pdf-html";

const styles = StyleSheet.create({
  TermsAndConditions: {
    width: 550,
    border: "1px solid black",
    borderRadius: 5,
    padding: 5,
  },
  text: { fontSize: 10 },
  termHeader: { fontFamily: "Helvetica-Bold", fontSize: 12, margin: "5px 0px" },
  termDescription: { fontSize: 10 },
});
const TermsAndConditions = ({ termsAndConditions }) => {
  return (
    <View style={styles.TermsAndConditions}>
      {termsAndConditions.description_en && (
        <Text style={styles.text}>
          <Html
            collapse={false}
            style={
              styles.termDescription
            }>{`<html><body>${termsAndConditions.description_en}</body></html>`}</Html>
        </Text>
      )}
      {(termsAndConditions?.terms_en ?? []).map((term, idx) => (
        <View style={styles.term} key={idx}>
          <Text style={styles.termHeader}>{term.title}</Text>
          <Text style={styles.termDescription}>
            <Html
              collapse={false}
              style={
                styles.termDescription
              }>{`<html><body>${term.description}</body></html>`}</Html>
          </Text>
        </View>
      ))}
    </View>
  );
};
TermsAndConditions.defaultProps = { termsAndConditions: {} };
TermsAndConditions.propTypes = {
  termsAndConditions: PropTypes.object.isRequired,
};
export default TermsAndConditions;
