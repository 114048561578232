import TransTxt from "@src/components/common/SxFormatMessage";

import { formatToDateTime } from "@src/tools/date_tools";
import { getDisplayNameFromOptions } from "@src/processors/columnsDataProcessor";

import React from "react";
import _ from "lodash";

import ClipboardCopier from "sisi_js_components/lib/components/ClipboardCopier/ClipboardCopier";

export const initialPurchasesFilterForm = {
  dates: {
    value: [],
    display_name: <TransTxt id="business_dates" startCase />,
    verbose: <TransTxt id="business_dates" startCase />,
  },
  suppliers: {
    value: [],
    display_name: <TransTxt id="business_suppliers" startCase />,
    verbose: <TransTxt id="business_suppliers" startCase />,
    searchable: false,
  },
  buyers: {
    value: [],
    display_name: <TransTxt id="business_buyers" startCase />,
    verbose: <TransTxt id="business_buyers" startCase />,
    searchable: true,
  },
  service_category: {
    value: [],
    display_name: <TransTxt id="service_category" startCase />,
    verbose: <TransTxt id="service_category" startCase />,
    searchable: false,
  },
};

export const initialPurchasesColumnsSetUp = {
  basic: {
    value: [
      "reference",
      "delivery_date",
      "service_category",
      "suppliers",
      "buyers",
      "buying_price",
      "amount",
    ],
    choices: [
      {
        key: "reference",
        display_name: "Reference",
      },
      {
        key: "delivery_date",
        display_name: <TransTxt id="purchases__delivery_date" startCase />,
      },
      {
        key: "service_category",
        display_name: <TransTxt id="purchases__service_category" startCase />,
      },
      {
        key: "suppliers",
        display_name: <TransTxt id="purchases__suppliers" startCase />,
      },
      {
        key: "buyers",
        display_name: <TransTxt id="purchases__buyers" startCase />,
      },
      {
        key: "buying_price",
        display_name: "Buying Price",
      },
      {
        key: "amount",
        display_name: "Charged Amount",
      },
    ],
  },
};

export const initialPurchasesColumnsProcessor = {
  reference: {
    key: "reference",
    // eslint-disable-next-line react/display-name
    getter: (data) => {
      return _.get(data, "product_info.reference") ? (
        <ClipboardCopier
          reference={_.get(data, "product_info.reference")}
          size="lg"
        />
      ) : (
        " N /A"
      );
    },
    extraGetter: (data) => _.get(data, "product_info.reference"),
  },
  delivery_date: {
    key: "delivery_date",
    getter: (data) => {
      const arr = [];
      const res = _.get(data, "product_info.delivery_dates", []);
      res.forEach((item) => {
        arr.push(
          `${formatToDateTime(item.start_at)} to ${formatToDateTime(
            item.end_at
          )} `
        );
      });

      return arr;
    },
  },
  service_category: {
    key: "service_category",
    getter: (data) => {
      data.id;
      return getDisplayNameFromOptions(data.product_type, [
        { display_name: "Transportation", value: "FL" },
        { display_name: "Transfer", value: "TRF" },
        { display_name: "Accommodation", value: "ACC" },
        { display_name: "Add Ons", value: "MI" },
      ]);
    },
  },
  suppliers: {
    key: "suppliers",
    getter: (data) => {
      return _.startCase(_.get(data, "product_info.provider", ""));
    },
  },
  buyers: {
    key: "buyers",
    getter: (data) => {
      const title = _.get(data, "source_entity_info.legal_title");
      const type = _.get(data, "source_entity").split("___")[1];
      return `${_.startCase(type)}--${title}`;
    },
  },
  buying_price: {
    key: "buying_price",
    getter: (data, options) => {
      const currencyArr = options[0];
      const currency = data.currency;
      const count = Math.abs(data.buying);

      const symbol = (
        _.find(currencyArr, (item) => {
          return item.notation == currency;
        }) || {}
      ).symbol;

      return `${symbol}${count}`;
    },
  },
  amount: {
    key: "amount",
    getter: (data, options) => {
      const currencyArr = options[0];
      const currency = data.currency;
      const count = Math.abs(data.charged_amount);

      const symbol = (
        _.find(currencyArr, (item) => {
          return item.notation == currency;
        }) || {}
      ).symbol;

      return `${symbol}${count}`;
    },
  },
};

export const initialPurchasesColumnsMapping = {
  reference: {
    key: "reference",
    width: 110,
  },
  delivery_date: {
    key: "delivery_date",
    width: 350,
  },
  service_category: {
    key: "service_category",
    width: 170,
  },
  suppliers: {
    key: "suppliers",
    width: 170,
  },
  buyers: {
    key: "buyers",
    width: 170,
  },
  buying_price: {
    key: "buying_price",
    width: 200,
  },
  amount: {
    key: "amount",
    width: 200,
  },
};
