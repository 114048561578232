import { TRIPPLANNER_SAVE_SERVICE_BOOKING_METADATA } from "@src/actions/Project/TripPlanner/step_four/service_remarks/types";
import {
  TRIPPLANNER_INIT,
  TRIPPLANNER_LOAD_TRIP,
  TRIPPLANNER_DAYBYDAY_SHOW_ADD_ADDON_DRAWER,
  TRIPPLANNER_DAYBYDAY_HIDE_ADD_ADDON_DRAWER,
  TRIPPLANNER_DAYBYDAY_SET_ADDONS,
  TRIPPLANNER_DAYBYDAY_RESET_ADDONS,
  TRIPPLANNER_DAYBYDAY_CHANGE_FILTERS,
  TRIPPLANNER_DAYBYDAY_TRIP_STORE_ADDONS,
  TRIPPLANNER_DAYBYDAY_TRIP_REMOVE_ADDON,
  TRIPPLANNER_DAYBYDAY_LOADING_ADDON_DRAWER,
  TRIPPLANNER_DAYBYDAY_IDLE_ADDON_DRAWER,
  TRIPPLANNER_DAYBYDAY_CHANGE_ADDON_DRAWER_MODE,
  TRIPPLANNER_DAYBYDAY_ADDON_NOT_FOUND,
  TRIPPLANNER_DAYBYDAY_ADDON_SEARCHING,
  TRIPPLANNER_DAYBYDAY_ADDON_IDLE,
  ITINERARY_REMOVE_DESTINATION,
} from "@src/actions/Project/TripPlanner/types";
import {
  TRIPPLANNER_DBD_ADD_ADHOC_SRV,
  TRIPPLANNER_DBD_EDIT_ADHOC_SRV,
  TRIPPLANNER_DBD_REMOVE_ADHOC_SRV,
} from "@src/actions/Project/TripPlanner/types/day_by_day/addhoc_services";
import {
  TRIPPLANNER_DBD_ADD_CUSTOM_SRV,
  TRIPPLANNER_DBD_REMOVE_CUSTOM_SRV,
  TRIPPLANNER_DBD_UPDATE_COORD_SRV,
  TRIPPLANNER_DBD_UPDATE_CUSTOM_SRV,
  TRIPPLANNER_RESET_CUSTOM_SERVICES,
} from "@src/actions/Project/TripPlanner/types/day_by_day/custom_services";

import update from "immutability-helper";
import _ from "lodash";

const tripPlannerAddOnDrawerInitial = {
  show: false,
  loading: false,
  results: [],
  destOrders: [],
  serviceDate: "",
  day: 0,
  filters: {
    title: "",
    items: [],
    categories: [],
    min_max_price: [0, 0],
  },
  accommodation: {},
  mode: "list",
  addOnId: null,
};

export const tripPlannerAddOnDrawer = (
  state = tripPlannerAddOnDrawerInitial,
  action
) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return tripPlannerAddOnDrawerInitial;
    case TRIPPLANNER_LOAD_TRIP:
      return tripPlannerAddOnDrawerInitial;
    case TRIPPLANNER_DAYBYDAY_LOADING_ADDON_DRAWER:
      return { ...state, loading: true };
    case TRIPPLANNER_DAYBYDAY_IDLE_ADDON_DRAWER:
      return { ...state, loading: false };
    case TRIPPLANNER_DAYBYDAY_SHOW_ADD_ADDON_DRAWER: {
      const { destOrders, serviceDate, day, accommodation, mode, addOnId } =
        action;
      return {
        ...state,
        show: true,
        destOrders,
        serviceDate,
        day,
        results: [],
        accommodation,
        mode,
        addOnId,
      };
    }
    case TRIPPLANNER_DAYBYDAY_HIDE_ADD_ADDON_DRAWER:
      return tripPlannerAddOnDrawerInitial;
    case TRIPPLANNER_DAYBYDAY_SET_ADDONS: {
      const { results, destOrder } = action;
      return {
        ...state,
        ...{ results: results.map((r) => ({ ...r, destOrder })) },
      };
    }
    case TRIPPLANNER_DAYBYDAY_CHANGE_FILTERS: {
      const { key, value } = action;
      return { ...state, filters: { ...state.filters, ...{ [key]: value } } };
    }
    case TRIPPLANNER_DAYBYDAY_CHANGE_ADDON_DRAWER_MODE: {
      const { mode, addOnId } = action;
      return { ...state, mode, addOnId };
    }
    default:
      return state;
  }
};

const tripPlannerAddOnDataInitial = {};
export const tripPlannerAddOnData = (
  state = tripPlannerAddOnDataInitial,
  action
) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return tripPlannerAddOnDataInitial;
    case TRIPPLANNER_LOAD_TRIP: {
      const { data } = action;
      return data.addon_data;
    }
    case TRIPPLANNER_DAYBYDAY_TRIP_STORE_ADDONS: {
      const { day, addon } = action;
      if (!!state?.day) {
        if (!state[day].map((a) => a.id).includes(addon.id)) {
          return update(state, { [day]: { $push: [addon] } });
        } else {
          return state;
        }
      } else {
        return { ...state, ...{ [day]: [addon] } };
      }
    }
    case TRIPPLANNER_DAYBYDAY_TRIP_REMOVE_ADDON: {
      const { day, id } = action;
      return update(state, {
        [day]: {
          $apply: (addons) => addons.filter((addon) => addon.id !== id),
        },
      });
    }
    case TRIPPLANNER_DAYBYDAY_RESET_ADDONS:
      return tripPlannerAddOnDataInitial;
    default:
      return state;
  }
};

const tripPlannerAddOnSearchStatusInitial = {};
export const tripPlannerAddOnSearchStatus = (
  state = tripPlannerAddOnSearchStatusInitial,
  action
) => {
  const genericUpdater = (state, day, search_uid, status) => {
    return update(state, {
      [day]: {
        $apply: (addons) => {
          if (!addons) {
            return [{ search_uid, status }];
          } else {
            return [
              ...addons.filter((status) => status.search_uid !== search_uid),
              ...[{ search_uid, status }],
            ];
          }
        },
      },
    });
  };

  switch (action.type) {
    case TRIPPLANNER_INIT:
      return tripPlannerAddOnSearchStatusInitial;
    case TRIPPLANNER_LOAD_TRIP:
      return tripPlannerAddOnSearchStatusInitial;
    case TRIPPLANNER_DAYBYDAY_ADDON_NOT_FOUND: {
      const { day, search_uid, status } = action;
      return genericUpdater(state, day, search_uid, status);
    }
    case TRIPPLANNER_DAYBYDAY_ADDON_SEARCHING: {
      const { day, search_uid, status } = action;
      return genericUpdater(state, day, search_uid, status);
    }
    case TRIPPLANNER_DAYBYDAY_ADDON_IDLE: {
      const { day, search_uid, status } = action;
      return genericUpdater(state, day, search_uid, status);
    }
    default:
      return state;
  }
};

const tripPlannerDbDCustomSrvDataInitial = {};
export const tripPlannerDbDCustomSrvData = (
  state = tripPlannerDbDCustomSrvDataInitial,
  action
) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return tripPlannerDbDCustomSrvDataInitial;
    case TRIPPLANNER_LOAD_TRIP: {
      const { data } = action;
      return data?.dbd_custom_srv_data ?? tripPlannerDbDCustomSrvDataInitial;
    }
    case TRIPPLANNER_RESET_CUSTOM_SERVICES: {
      const newState = {};
      Object.entries(state).forEach(([dayIdx, __]) => {
        newState[dayIdx] = [];
      });

      return newState;
    }
    case TRIPPLANNER_DBD_ADD_CUSTOM_SRV: {
      const { dayIdx, srv } = action;
      return update(state, {
        [dayIdx]: {
          $apply: (srvs) => {
            if (Array.isArray(srvs)) {
              return [...srvs, srv];
            } else {
              return [srv];
            }
          },
        },
      });
    }
    case TRIPPLANNER_DBD_REMOVE_CUSTOM_SRV: {
      const { dayIdx, srv_id, srv_type } = action;
      return update(state, {
        [dayIdx]: {
          $apply: (srvs) => [
            ...srvs.filter((srv) => {
              if (srv.custom_service_type !== srv_type) {
                return true;
              }

              if (!!srv?.id) {
                return srv.id !== srv_id;
              } else if (!!srv?.uid) {
                return srv.uid !== srv_id;
              } else {
                return srv.reference !== srv_id;
              }
            }),
          ],
        },
      });
    }
    case TRIPPLANNER_DBD_UPDATE_CUSTOM_SRV: {
      const { dayIdx, srv_id, srv_type, data } = action;
      return update(state, {
        [dayIdx]: {
          $apply: (srvs) =>
            srvs.map((srv) => {
              if (srv.id !== srv_id || srv.service_type !== srv_type) {
                return srv;
              } else {
                return data;
              }
            }),
        },
      });
    }
    case TRIPPLANNER_DBD_UPDATE_COORD_SRV: {
      const { srv_id, data } = action;
      const tmp = _.cloneDeep(state);
      const newState = {};
      Object.entries(tmp).forEach(([dayIdx, services]) => {
        newState[dayIdx] = services.map((srv) => {
          if (srv.custom_service_type !== "COO") {
            return srv;
          }
          if (srv?.uid !== srv_id) {
            return srv;
          }
          return { ...srv, ...data };
        });
      });
      return newState;
    }
    case TRIPPLANNER_SAVE_SERVICE_BOOKING_METADATA: {
      const { uid, srv_type, booking_metadata } = action;

      const newState = _.cloneDeep(state) || {};
      Object.entries(newState).forEach((entry) => {
        switch (srv_type) {
          case "COO": {
            entry[1]
              .filter(
                (srv) => srv.custom_service_type === "COO" && srv?.uid === uid
              )
              .forEach((srv) => (srv.booking_metadata = booking_metadata));
            break;
          }
          case "REST":
            entry[1]
              .filter(
                (srv) => srv.custom_service_type === "REST" && srv.id === uid
              )
              .forEach((srv) => (srv.booking_metadata = booking_metadata));
            break;
          case "GEN":
            entry[1]
              .filter(
                (srv) => srv.custom_service_type === "GEN" && srv.id === uid
              )
              .forEach((srv) => (srv.booking_metadata = booking_metadata));
            break;
          default:
            break;
        }
      });
      return newState;
    }
    case ITINERARY_REMOVE_DESTINATION: {
      const { destId } = action;
      const newState = _.cloneDeep(state);
      Object.entries(newState).forEach(([day, srvs]) => {
        newState[day] = srvs.filter(
          (srv) =>
            srv?.destination_id !== destId &&
            !(srv?.destination_ids ?? []).includes(destId)
        );
      });
      return newState;
    }
    default:
      return state;
  }
};

const tripPlannerDbDAdhocSrvDataInitial = {};
export const tripPlannerDbDAddhocSrvData = (
  state = tripPlannerDbDAdhocSrvDataInitial,
  action
) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return tripPlannerDbDAdhocSrvDataInitial;
    case TRIPPLANNER_LOAD_TRIP: {
      const { data } = action;
      return data?.dbd_addhoc_srv_data ?? tripPlannerDbDAdhocSrvDataInitial;
    }
    case TRIPPLANNER_DBD_ADD_ADHOC_SRV: {
      const { dayIdx, srv } = action;
      return update(state, {
        [dayIdx]: {
          $apply: (srvs) => {
            if (Array.isArray(srvs)) {
              return [...srvs, srv];
            } else {
              return [srv];
            }
          },
        },
      });
    }
    case TRIPPLANNER_DBD_EDIT_ADHOC_SRV: {
      const { srv } = action;
      const newState = _.cloneDeep(state);

      Object.entries(newState).forEach(([dayIdx, srvs]) => {
        srvs.forEach((s, idx) => {
          if (s.id !== srv.id) return;
          newState[dayIdx][idx] = srv;
        });
      });

      return newState;
    }
    case TRIPPLANNER_DBD_REMOVE_ADHOC_SRV: {
      const { dayIdx, srv_id } = action;
      return update(state, {
        [dayIdx]: {
          $apply: (srvs) => [...srvs.filter((srv) => srv.id !== srv_id)],
        },
      });
    }
    default:
      return state;
  }
};

export const TRIPPLANNER_DBD_ADD_ACTIVITY = "TRIPPLANNER_DBD_ADD_ACTIVITY";
export const TRIPPLANNER_DBD_REMOVE_ACTIVITY =
  "TRIPPLANNER_DBD_REMOVE_ACTIVITY";
export const TRIPPLANNER_DBD_UPDATE_ACTIVITY =
  "TRIPPLANNER_DBD_UPDATE_ACTIVITY";
export const TRIPPLANNER_DBD_RESET_ACTIVITY_PREBOOK =
  "TRIPPLANNER_DBD_RESET_ACTIVITY_PREBOOK";
const tripPlannerDbDActivityDataInitial = {};
export const tripPlannerDbDActivityReducer = (
  state = tripPlannerDbDActivityDataInitial,
  action
) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return tripPlannerDbDActivityDataInitial;
    case TRIPPLANNER_LOAD_TRIP: {
      const { data } = action;
      return data.activity_data || tripPlannerDbDActivityDataInitial;
    }
    case TRIPPLANNER_DBD_ADD_ACTIVITY: {
      const { date, activity } = action;
      return update(state, {
        [date]: {
          $apply: (activities) => {
            if (Array.isArray(activities)) {
              return update(activities, { $push: [activity] });
            } else {
              return [activity];
            }
          },
        },
      });
    }
    case TRIPPLANNER_DBD_REMOVE_ACTIVITY: {
      const { date, activity_uid } = action;
      return update(state, {
        [date]: {
          $apply: (activities) =>
            activities.filter((activity) => activity.uid !== activity_uid),
        },
      });
    }
    case TRIPPLANNER_DBD_UPDATE_ACTIVITY: {
      const { date, uid, activity } = action;
      return update(state, {
        [date]: {
          $apply: (activities) =>
            activities.map((act) => (act.uid === uid ? activity : act)),
        },
      });
    }
    case TRIPPLANNER_DBD_RESET_ACTIVITY_PREBOOK: {
      const { date, uid } = action;
      const newState = _.cloneDeep(state);
      const activity = (newState?.[date] ?? []).find((act) => act.uid === uid);
      if (activity && activity?.prebookData) {
        delete activity.prebookData;
      }

      return newState;
    }
    default:
      return state;
  }
};
