import {
  CUSTOM_TRF_INIT,
  CUSTOM_TRF_INIT_FROM_DATA,
  CUSTOM_TRF_CHANGE,
  CUSTOM_TRF_VEHICLE_IMAGE_CHANGE,
} from "@src/actions/Project/CustomTransfer/types";

import _ from "lodash";
import update from "immutability-helper";

const vehicleTemplate = {
  name: "",
  min_pax: 1,
  max_pax: 1,
  brand: "",
  class: "",
  images: [{ url: "" }],
  quantity: 1,
  big_baggage_allowance: 0,
  small_baggage_allowance: 0,
};

const legTemplate = {
  start: "",
  vehicle: vehicleTemplate,
  distance: 0,
  duration: 0,
  provider: { name: "string", logo: { url: "" }, source: "0", rating: 0 },
};

const transferTemplate = {
  uid: null,
  session_id: "",
  booking_id: "",
  origin: { name: "", geodata: { lat: 0, lng: 0 } },
  destination: { name: "", geodata: { lat: 0, lng: 0 } },
  price: { value: 0, currency: "EUR" },
  cxl: [],
  inbound: legTemplate,
  outbound: {},
  prebook_required: false,
};

export const customTransfer = (state = transferTemplate, action) => {
  switch (action.type) {
    case CUSTOM_TRF_INIT:
      return transferTemplate;
    case CUSTOM_TRF_INIT_FROM_DATA:
      return action.data;
    case CUSTOM_TRF_CHANGE: {
      const { legType, name, value } = action;

      let newState = state;
      if (legType == "outbound" && _.isEmpty(state.outbound)) {
        newState = update(newState, { outbound: { $set: legTemplate } });
      }

      return update(newState, {
        origin: { $apply: (val) => (name == "origin" ? value : val) },
        destination: { $apply: (val) => (name == "destination" ? value : val) },
        [legType]: {
          start: {
            $apply: (dt) =>
              name == "start"
                ? value
                : ["origin", "destination"].includes(name)
                ? ""
                : dt,
          },
          distance: { $apply: (val) => (name == "distance" ? value : val) },
          duration: { $apply: (val) => (name == "duration" ? value : val) },
          vehicle: {
            name: { $apply: (val) => (name == "vehicle.type" ? value : val) },
            quantity: {
              $apply: (val) => (name == "vehicle.quantity" ? value : val),
            },
            min_pax: {
              $apply: (val) => (name == "vehicle.min_pax" ? value : val),
            },
            max_pax: {
              $apply: (val) => (name == "vehicle.max_pax" ? value : val),
            },
            big_baggage_allowance: {
              $apply: (val) => (name == "vehicle.big_bag" ? value : val),
            },
            small_baggage_allowance: {
              $apply: (val) => (name == "vehicle.small_bag" ? value : val),
            },
          },
        },
      });
    }
    case CUSTOM_TRF_VEHICLE_IMAGE_CHANGE: {
      const { transfer_leg_type, image } = action;

      let newState = state;
      if (transfer_leg_type == "outbound" && _.isEmpty(state.outbound)) {
        newState = update(newState, { outbound: { $set: legTemplate } });
      }

      return update(newState, {
        [transfer_leg_type]: { vehicle: { images: { [0]: { $set: image } } } },
      });
    }
    default:
      return state;
  }
};
