import _ from "lodash";
import {
  TRIPPLANNER_TRANSFER_EDIT_ENABLED,
  TRIPPLANNER_OVERVIEW_VIEW_TRANSFER_DETAILS,
  TRIPPLANNER_TRANSFER_EDIT_DISABLED,
} from "./types";
import {
  TRIPPLANNER_CLEAR_TRANSFERS,
  TRIPPLANNER_SAVE_TRANSFER,
} from "./types/transfers";
import { retrieveSelectedTrfKey } from "./transfer_tools";

export const clearTransfers = () => {
  return { type: TRIPPLANNER_CLEAR_TRANSFERS };
};

export const saveTransfer = ({ trfType, destOrder, transfer }) => {
  return { type: TRIPPLANNER_SAVE_TRANSFER, trfType, destOrder, transfer };
};

export const transferEdit = () => {
  return { type: TRIPPLANNER_TRANSFER_EDIT_ENABLED };
};

export const transferConfirm = () => {
  return { type: TRIPPLANNER_TRANSFER_EDIT_DISABLED };
};

export const viewTrfDetails =
  (destOrder, trfType, booking_id) => (dispatch, getState) => {
    const trfKey = retrieveSelectedTrfKey(
      getState().tripPlannerTransferData,
      destOrder,
      trfType
    );

    dispatch({
      type: TRIPPLANNER_OVERVIEW_VIEW_TRANSFER_DETAILS,
      destOrder,
      transfer_type: trfKey,
      booking_id,
    });
  };
