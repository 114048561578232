import { IconButton, Icon } from "rsuite";
import { BookChecker, ServicePanelGhost } from "./ItineraryOverviewCommon";

import { humanizeDuration } from "@src/tools/date_tools";

import _ from "lodash";

import PropTypes from "prop-types";
import React, { useCallback, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TripModeHoc } from "../Hydrators";
import { createUseStyles } from "react-jss";
import { variables } from "@src/jsssetup";
import { InlineIcon } from "@iconify/react";
import { handleNonValidImgFn } from "@src/tools/common_tools";
import { useQuery } from "@tanstack/react-query";
import { pollFlightsSession } from "@src/api";
import { DateTime } from "luxon";
import { PricingFormContext } from "../TripPlanner";
import ServicePricingForm from "./ServicePricingForm";
import OverviewPrice from "./OverviewPrice";
import { getServicePricingSelector } from "@src/selectors/Project/TripPlanner/pricing";
import PrebookPrice from "./PrebookPrice";
import { saveFlightData } from "@src/actions/Project/TripPlanner/flights";
import { ItinOverviewContext } from "../Controllers/ItineraryOverview";
import {
  getSecondaryLegFlightSelector,
  getSelectedFlightByLegUidSelector,
} from "@src/selectors/Project/TripPlanner";

export const flightTitle = (leg) => {
  const firstSeg = leg.segments[0];
  const lastSeg = _.last(leg.segments);

  const originAirport = firstSeg.origin_airport;
  const destAirport = lastSeg.destination_airport;

  return [
    `(${originAirport.iata}) ${originAirport.name}`,
    `to (${destAirport.iata}) ${destAirport.name}`,
  ].join(" ");
};

export const getItinerary = (leg) => {
  var selectedOption = leg;

  return [
    ...new Set(
      _.flatten(
        (selectedOption?.segments ?? []).map((s) => [
          s.origin_airport.iata,
          s.destination_airport.iata,
        ])
      )
    ),
  ].join(" - ");
};

export const getDuration = (leg) => {
  var selectedOption = leg;
  return humanizeDuration(
    selectedOption.segments.reduce(
      (a, b) => a + (b.duration + (b?.transfer_time ?? 0)),
      0
    ),
    { abbrev: true }
  );
};

export const getLayover = (leg) => {
  var selectedOption = leg;
  return humanizeDuration(
    selectedOption.segments.reduce((a, b) => a + (b?.transfer_time ?? 0), 0),
    { abbrev: true }
  );
};

export const getDeparture = (leg) => {
  var selectedOption = leg;
  return DateTime.fromISO(selectedOption.segments[0].departure, {
    setZone: true,
  }).toLocaleString(DateTime.DATETIME_MED);
};

export const getArrival = (leg) => {
  var selectedOption = leg;
  return DateTime.fromISO(_.last(selectedOption.segments).arrival, {
    setZone: true,
  }).toLocaleString(DateTime.DATETIME_MED);
};

export const getFlightNumber = (leg) => {
  var selectedOption = leg;
  return [
    ...new Set(selectedOption.segments.map((seg) => seg.flight_number)),
  ].join(", ");
};

const flightDataStyles = createUseStyles({
  FlightData: (props) => ({
    display: "grid",
    gridTemplateColumns: props.services_collapsed ? "1fr" : "8rem 1fr",
    gridTemplateRows: props.services_collapsed ? "unset" : "10rem",
    gridGap: variables.normal_gap,
    maxHeight: "10rem",
  }),
  cardContent: {
    display: "grid",
    padding: variables.normal_gap,
    gridGap: variables.half_gap,
    boxShadow: variables.shadows.heavyShadow,
    borderRadius: `calc(${variables.normal_gap} / 2)`,
    textTransform: "capitalize",
  },
  header: (props) => ({
    display: "grid",
    gridTemplateColumns: props.services_collapsed
      ? "max-content 1fr max-content"
      : "1fr max-content",
    alignItems: "center",
    gridGap: variables.half_gap,
    padding: props.services_collapsed && [
      variables.half_gap,
      variables.half_gap,
      variables.half_gap,
      variables.double_gap,
    ],
    borderRadius: props.services_collapsed && variables.half_gap,
    boxShadow: props.services_collapsed && variables.shadows.normalShadow,
  }),
  img: {
    width: "100%",
    height: "100%",
    borderRadius: "5px",
    objectFit: "contain",
    padding: ["3rem", 0],
    boxShadow: variables.shadows.heavyShadow,
  },
  imgError: {
    width: "100%",
    height: "100%",
    borderRadius: "5px",
    objectFit: "cover",
    padding: 0,
    boxShadow: variables.shadows.heavyShadow,
  },
  metadata: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    alignItems: "center",
    gridGap: variables.half_gap,
  },
  headerTitle: {
    fontSize: "medium",
    fontWeight: "500",
  },
  title: {
    color: variables.colors.text.colored,
    fontWeight: "600",
  },
  iconCntr: {
    display: "grid",
    placeContent: "center",
    width: "2rem",
    height: "2rem",
    borderRadius: "50%",
    background: variables.colors.easy.orange,
    padding: variables.half_gap,
  },
  icon: {
    fontSize: "x-large",
    color: variables.colors.text.light,
  },
  actionBtns: {
    display: "grid",
    gridAutoFlow: "column",
    gridGap: variables.half_gap,
    placeItems: "center",
  },
  expandIconCntr: {
    display: "grid",
    placeContent: "center",
    width: "1.5rem",
    height: "1.5rem",
    borderRadius: "50%",
    background: variables.colors.easy.orange,
    padding: variables.half_gap,
    cursor: "pointer",
  },
  expandIcon: {
    fontSize: "large",
    color: variables.colors.text.light,
  },
});
export const FlightData = ({
  flight,
  flightLegUid = null,
  children,
  services_collapsed,
  eventType = null,
  toggleSelfCollapse,
}) => {
  const classes = flightDataStyles({ services_collapsed });

  const flightLeg = (flight?.legs ?? []).find((leg) =>
    leg.find((l) => l.uid === flightLegUid)
  )?.[0];
  const logo = flightLeg?.segments[0]?.operating_airline?.logo ?? "";
  const header = (
    <React.Fragment>
      <div className={classes.headerTitle}>
        <FlightTitle leg={flightLeg} eventType={eventType} />
      </div>
      <div className={classes.actionBtns}>
        {children}
        {typeof toggleSelfCollapse === "function" && (
          <div className={classes.expandIconCntr}>
            <InlineIcon
              className={classes.expandIcon}
              icon={
                services_collapsed
                  ? "ri:arrow-down-s-line"
                  : "ri:arrow-up-s-line"
              }
              onClick={toggleSelfCollapse}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );

  return (
    !!flightLeg && (
      <div className={classes.FlightData}>
        {services_collapsed ? (
          <div className={classes.header}>
            {services_collapsed && (
              <div className={classes.iconCntr}>
                <InlineIcon
                  className={classes.icon}
                  icon="ic:round-airplanemode-active"
                />
              </div>
            )}
            {header}
          </div>
        ) : (
          <React.Fragment>
            <img
              src={logo}
              className={logo ? classes.img : classes.imgError}
              onError={(event) => handleNonValidImgFn(event, "FL")}
            />
            <div className={classes.cardContent}>
              <div className={classes.header}>{header}</div>
              <div className={classes.metadata}>
                <span>
                  <span className={classes.title}>Itinerary: </span>{" "}
                  {getItinerary(flightLeg)}
                </span>
                <span>
                  <span className={classes.title}>Flight Number: </span>{" "}
                  {getFlightNumber(flightLeg)}
                </span>
                <span>
                  <span className={classes.title}>Flight Class: </span>{" "}
                  {[
                    ...new Set(
                      flightLeg?.segments.map(
                        (s) => `${s.cabin_class_display} (${s.cabin_class})`
                      )
                    ),
                  ].join(", ")}
                </span>
                <span>
                  <span className={classes.title}>Layover: </span>{" "}
                  {getLayover(flightLeg)}
                </span>
                <span>
                  <span className={classes.title}>Departure: </span>{" "}
                  {getDeparture(flightLeg)}
                </span>
                <span>
                  <span className={classes.title}>Arrival: </span>{" "}
                  {getArrival(flightLeg)}
                </span>
                <span>
                  <span className={classes.title}>Duration: </span>{" "}
                  {getDuration(flightLeg)}
                </span>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    )
  );
};
FlightData.propTypes = {
  eventType: PropTypes.oneOf[("arrival", "departure")],
  flight: PropTypes.object.isRequired,
  flightLegUid: PropTypes.string,
  children: PropTypes.object,
  services_collapsed: PropTypes.bool.isRequired,
  toggleSelfCollapse: PropTypes.func,
};

const flightTitlteStyles = createUseStyles({
  FlightTitle: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100%",
    maxWidth: "23rem",
    display: "inline-block",
  },
  airportName: {
    fontSize: "small",
  },
  [`@media ${variables.media.retina}`]: {
    FlightTitle: { maxWidth: "30rem" },
  },
  [`@media ${variables.media.bigscreen}`]: {
    FlightTitle: { maxWidth: "38rem" },
    airportName: { fontSize: "medium" },
  },
});
const FlightTitle = ({ leg, eventType }) => {
  const classes = flightTitlteStyles();

  const firstSeg = leg.segments[0];
  const lastSeg = _.last(leg.segments);
  const originAirport = firstSeg.origin_airport;
  const destAirport = lastSeg.destination_airport;

  return originAirport && destAirport ? (
    <span className={classes.FlightTitle}>
      {!!eventType && <strong>{_.capitalize(eventType) + ": "}</strong>}
      <strong>({originAirport.iata})</strong>{" "}
      <span className={classes.airportName}>{originAirport.name}</span> -{" "}
      <strong>({destAirport.iata})</strong>{" "}
      <span className={classes.airportName}>{destAirport.name}</span>
    </span>
  ) : null;
};
FlightTitle.propTypes = {
  eventType: PropTypes.oneOf[("arrival", "departure")],
  leg: PropTypes.object,
};

export const servicePanelHeaderStyles = {
  borderRadius: [
    `calc(${variables.normal_gap} / 2)`,
    `calc(${variables.normal_gap} / 2)`,
    0,
    0,
  ],
  background: variables.colors.deepblue,
  color: "white",
  padding: [`calc(${variables.normal_gap} / 2)`, variables.normal_gap],
  display: "grid",
  gridTemplateColumns: "1fr auto",
  fontSize: "medium",
  alignItems: "center",
};

export function servicePanelContentStyles({ srvType = "" } = {}) {
  return {
    background: variables.colors.calculatorGrey,
    display: "grid",
    gridTemplateColumns: srvType === "FL" ? "6rem 1fr" : "8rem 1fr",
    gridGap: variables.normal_gap,
    padding: variables.normal_gap,
    borderRadius: [
      0,
      0,
      `calc(${variables.normal_gap} / 2)`,
      `calc(${variables.normal_gap} / 2)`,
    ],
    alignItems: "center",
  };
}

const MAXPOLLS = 30;

const OverviewFlightStyles = createUseStyles({
  OverviewFlight: { display: "grid" },
  actionBtnCntr: { display: "grid" },
});
export const OverviewFlight = ({
  legUid,
  currentStep,
  onGoBack,
  services_collapsed,
  eventType = null,
  withPrice = true,
  toggleSelfCollapse,
}) => {
  const classes = OverviewFlightStyles();

  const [pollCount, setPollCount] = useState(0);
  const [shouldPoll, setShouldPoll] = useState(false);
  const [progress, setProgress] = useState(0);

  const { showPricingForm } = useContext(PricingFormContext);
  const { onViewAllFlights } = useContext(ItinOverviewContext);

  var { flightSession, tripLeg, flight, secondaryFlightLeg } = useSelector(
    (state) => {
      var { flight, tripLeg } = getSelectedFlightByLegUidSelector(state, {
        legUid,
      });

      const flightSessions = state.tripPlannerFlightsReducer.sessions;
      var flightSession = flightSessions.find(
        (s) => s?.payload?.legUid === legUid
      );

      // If true we are looking at a leg which is not the first leg of a
      // roundtrip or multi-stop flight.
      var secondaryFlightLeg = false;
      if (!flightSession) {
        secondaryFlightLeg = true;
        const { flight: fl, flightSession: flS } =
          getSecondaryLegFlightSelector(state, { legUid });
        flight = fl;
        flightSession = flS;
      }

      return { tripLeg, flightSession, flight, secondaryFlightLeg };
    }
  );

  const { markup, gross_margin, flat_value } = useSelector((state) => {
    const { markup, gross_margin, flat_value } = getServicePricingSelector(
      state,
      { key: `FL___${flight?.uid}` }
    );
    return { markup, gross_margin, flat_value };
  });

  const customIconBtnStyles = {
    background: variables.colors.easy.orange,
    color: variables.colors.text.light,
    fontWeight: "600",
  };

  const dispatch = useDispatch();
  const saveFlData = useCallback(
    (flight) => dispatch(saveFlightData(flight)),
    [dispatch]
  );

  const { data: flightData } = useQuery({
    queryKey: ["flightPolling", flightSession?.session_id],
    queryFn: () => {
      if (flight) {
        return new Promise((resolve) =>
          resolve({ data: { results: [flight] } })
        );
      }

      setShouldPoll(true);
      setPollCount((p) => p + 1);
      setProgress(Math.ceil((pollCount / MAXPOLLS) * 100));
      return pollFlightsSession({
        session_id: flightSession?.session_id,
        api_key: flightSession?.payload.api_key,
        page_size: 1,
        ordering: "price",
      });
    },
    onSuccess: (data) => {
      if (data?.data?.status === "done" || pollCount >= MAXPOLLS) {
        setShouldPoll(false);
        setPollCount(0);

        const flight = data?.data?.results?.[0];
        if (!_.isEmpty(flight)) {
          // We need this check because if we are on a secondary leg
          // the result might be cached from react query and we don't
          // want to override the primary leg's legUid.
          if (!flight.legUid) {
            flight.legUid = tripLeg?.uid;
          }
          // Auto select the first option from each leg
          flight.legs.forEach((leg) => {
            leg[0].selected = true;
          });

          saveFlData(flight);
        }
      }
    },
    onError: () => {
      setShouldPoll(false);
      setPollCount(0);
    },
    refetchOnWindowFocus: false,
    refetchInterval: shouldPoll ? 1000 : false,
    refetchIntervalInBackground: true,
    retry: true,
    enabled: !secondaryFlightLeg && !flight,
  });
  if (!flight && !secondaryFlightLeg) {
    flight = flightData?.data?.results?.[0];
  }

  const tripLegSrvPreferences = tripLeg?.service_preferences;
  const tripLegOriginAirport =
    tripLegSrvPreferences?.preferences?.origin_airports;
  const tripLegDestAirport =
    tripLegSrvPreferences?.preferences?.destination_airports;

  const flightLegUid = (flight?.legs ?? []).find((leg) =>
    leg.some(
      (l) =>
        l?.segments?.[0]?.origin_airport?.iata === tripLegOriginAirport &&
        _.last(l?.segments ?? [])?.destination_airport?.iata ===
          tripLegDestAirport
    )
  )?.[0]?.uid;

  const isFirstLeg =
    (flight?.legs ?? []).map((leg) => leg[0].uid).indexOf(flightLegUid) === 0;

  const isSearching = (flightData?.data?.status ?? "") === "searching";

  return (
    <React.Fragment>
      {currentStep < 4 || _.isEmpty(flight) ? (
        <div />
      ) : (
        <BookChecker uid={flight?.uid} srv_type="FL" />
      )}
      {isSearching ? (
        <ServicePanelGhost
          title={`Search progress: (${progress}%) Please wait...`}
          service_type="FL"
          searchProgress={progress}
        />
      ) : _.isEmpty(flight) ? (
        <ServicePanelGhost
          failed={true}
          service_type="FL"
          title="Failed to find flights."
          failHolder={
            <span>
              Please go back to Step 2 and change your{" "}
              <strong>Transportation Preferences</strong>. We are sorry for the
              inconvenience.
            </span>
          }
          onGoBack={onGoBack}
        />
      ) : (
        <div className={classes.OverviewFlight}>
          <FlightData
            flight={flight}
            flightLegUid={flightLegUid}
            services_collapsed={services_collapsed}
            eventType={eventType}
            toggleSelfCollapse={toggleSelfCollapse}>
            <span className={classes.actionBtnCntr}>
              {currentStep == 4 || secondaryFlightLeg ? null : (
                <IconButton
                  style={customIconBtnStyles}
                  size="xs"
                  icon={<Icon icon="info" style={customIconBtnStyles} />}
                  onClick={() =>
                    onViewAllFlights({
                      session_id: flightSession?.session_id,
                    })
                  }>
                  View All
                </IconButton>
              )}
            </span>
          </FlightData>
        </div>
      )}
      {!isFirstLeg ? null : currentStep === 3 ? (
        showPricingForm && flight ? (
          <ServicePricingForm service={flight} service_type="FL" />
        ) : services_collapsed ? (
          <div />
        ) : withPrice ? (
          <OverviewPrice
            serviceType="FL"
            price={flight?.price?.total_price ?? 0}
            currency={flight?.price?.currency ?? ""}
            searching={isSearching}
            markup={markup}
            gross_margin={gross_margin}
            flat_value={flat_value}
          />
        ) : (
          <div />
        )
      ) : _.isEmpty(flight) ? (
        <div />
      ) : (
        <PrebookPrice
          price={flight?.price?.total_price ?? 0}
          currency={flight?.price?.currency ?? "EUR"}
        />
      )}
    </React.Fragment>
  );
};
OverviewFlight.defaultProps = { searchingData: [], services_collapsed: false };
OverviewFlight.propTypes = {
  eventType: PropTypes.oneOf[("arrival", "departure")],
  legUid: PropTypes.string.isRequired,
  currentStep: PropTypes.number.isRequired,
  searchingData: PropTypes.array.isRequired,
  onGoBack: PropTypes.func,
  services_collapsed: PropTypes.bool.isRequired,
  withPrice: PropTypes.bool,
  toggleSelfCollapse: PropTypes.func,
};
