import _ from "lodash";
import React from "react";
import axios from "axios";
import { getAuthHeaders } from "../../../request";
import { SISI_API_URL } from "@src/api";
import { toast } from "react-toastify";

export async function fetchAPITrainsReser(params) {
  const auth_headers = getAuthHeaders();
  return await axios.get(`${SISI_API_URL}/reservations/api-trains`, {
    params,
    headers: { ...auth_headers },
  });
}

export async function retrieveAPITrainReservation({ reference }) {
  const auth_headers = getAuthHeaders();
  return await axios.get(
    `${SISI_API_URL}/reservations/api-trains/${reference}/`,
    {
      params: {},
      headers: { ...auth_headers },
    }
  );
}

export async function updateAPITrainReservation({ reference, payload }) {
  const auth_headers = getAuthHeaders();
  return await axios.patch(
    `${SISI_API_URL}/reservations/api-trains/${reference}/`,
    payload,
    {
      params: {},
      headers: { ...auth_headers },
    }
  );
}

export async function retrievePublicAPITrainReservation({
  reference,
  onError = (error) =>
    toast.error(
      <p>
        We failed to retrieve the API Train Reservation. We are sorry for the
        inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    ),
}) {
  const auth_headers = getAuthHeaders();
  try {
    const result = await axios.get(
      `${SISI_API_URL}/reservations/api-trains/public/${reference}/`,
      {
        params: {},
        headers: { ...auth_headers },
      }
    );
    return result.data;
  } catch (error) {
    if (typeof onError === "function") {
      onError(error);
    }

    return null;
  }
}

export async function patchPublicAPITrainReservation({ reference, payload }) {
  return await axios.patch(
    `${SISI_API_URL}/reservations/api-trains/public/update/${reference}/`,
    payload
  );
}

export async function getAPITrainStats(params) {
  const auth_headers = getAuthHeaders();
  return await axios.get(`${SISI_API_URL}/reservations/api-trains/stats/`, {
    params,
    headers: { ...auth_headers },
  });
}
