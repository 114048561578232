import {
  //======================================FETCH SUBSCRIPTION PLAN================================
  SUBSCRIPTION_PLAN_BEGIN_FETCH_DATA,
  SUBSCRIPTION_PLAN_END_FETCH_DATA,
  SUBSCRIPTION_PLAN_FETCH_DATA,

  //=========================================FETCH MANAGE PLAN DATA================================
  SUBSCRIPTION_PLAN_USERS_LICENSE_DATA,
  //====================================== INIT SUBSCRIPTION PLAN=========================
  SUBSCRIPTION_PLAN_INITIALIZE_PRODUCTS_DATA,
  SUBSCRIPTION_PLAN_INITIALIZE_ADD_USERS_NUM,
  //=====================================EDIT PRODUCTS FEATURES PLAN========================
  SUBSCRIPTION_PLAN_CHANGE_PRODUCTS,
  //===================================== BILLING CYCLE=============================
  SUBSCRIPTION_PLAN_EDIT_BILLING_CYCLE,
  SUBSCRIPTION_PLAN_SUBMIT_MANAGE_HEADER_EDIT,
  SUBSCRIPTION_PLAN_INITIALIZE_BILLING_MANAGE_FORM,
  SUBSCRIPTION_PLAN_MANAGE_INTERVAL_LOADING_EDIT,
  SUBSCRIPTION_PLAN_INTERVAL_IDLE_EDIT,
  //===================================== EDIT USER LICENSE PLAN==========================
  SUBSCRIPTION_PLAN_EDIT_USER_LICENSE,
  SUBSCRIPTION_PLAN_TOGGLE_USER_LICENSE_OPERATION_TYPE,
  //===================================MODAL===============================
  SUBSCRIPTION_PLAN_SHOW_MANAGEMENT_MODAL,
  SUBSCRIPTION_PLAN_HIDE_MANAGEMENT_MODAL,
  ///================================ SUBMIT PRODUCTS FEATURES AND USER LICENSE EDIT ===========================================
  SUBSCRIPTION_PLAN_SUBMIT_PLAN_EDIT,
  SUBSCRIPTION_PLAN_LOADING_PLAN,
  SUBSCRIPTION_PLAN_IDLE_PLAN,
  //==================================INIT DATA====================================
  SUBSCRIPTION_PLAN_DATA_INIT,
  SUBSCRIPTION_PLAN_INITIALIZE_SUBSCRIPTION_HEADER_DATA,
  SUBSCRIPTION_PLAN_GET_DISCOUNT,
} from "@src/actions/ManagementConsole/Manage/types";

import { initialSubscriptionPlanForm } from "@src/forms/ManagementConsole";
import { calculateDiscountValue } from "@src/pages/ManagementConsole/Processor";

import update from "immutability-helper";
import _ from "lodash";

export const fetchSubscriptionPlanDataStatus = (state = "IDLE", action) => {
  switch (action.type) {
    case SUBSCRIPTION_PLAN_BEGIN_FETCH_DATA:
      return "FETCHING";
    case SUBSCRIPTION_PLAN_END_FETCH_DATA:
      return "FETCHED";
    default:
      return state;
  }
};

const initialModal = {
  id: "",
  mode: "HIDE",
};

export const subscribeManageModalId = (state = initialModal, action) => {
  switch (action.type) {
    case SUBSCRIPTION_PLAN_SHOW_MANAGEMENT_MODAL:
      return action.data;
    case SUBSCRIPTION_PLAN_HIDE_MANAGEMENT_MODAL:
      return initialModal;
    default:
      return state;
  }
};

export const getSubscribeEditStatus = (state = { mode: "VIEW" }, action) => {
  switch (action.type) {
    case SUBSCRIPTION_PLAN_DATA_INIT:
      return { mode: "VIEW" };
    case SUBSCRIPTION_PLAN_SUBMIT_PLAN_EDIT:
      return action.data;
    default:
      return state;
  }
};

export const getSubscribeHeaderEditStatus = (
  state = { mode: "VIEW", flag: false },
  action
) => {
  switch (action.type) {
    case SUBSCRIPTION_PLAN_DATA_INIT:
      return { mode: "VIEW", flag: false };
    case SUBSCRIPTION_PLAN_SUBMIT_MANAGE_HEADER_EDIT:
      return action.data;
    default:
      return state;
  }
};

export const usersLicenseList = (state = {}, action) => {
  switch (action.type) {
    case SUBSCRIPTION_PLAN_USERS_LICENSE_DATA:
      return action.data;
    default:
      return state;
  }
};

export const subscriptionPlanData = (state = {}, action) => {
  switch (action.type) {
    case SUBSCRIPTION_PLAN_FETCH_DATA:
      return action.data;
    default:
      return state;
  }
};

export const subscriptionPlanProductsData = (
  state = initialSubscriptionPlanForm,
  action
) => {
  switch (action.type) {
    case SUBSCRIPTION_PLAN_INITIALIZE_PRODUCTS_DATA: {
      const obj = _.cloneDeep(initialSubscriptionPlanForm);
      const { productData, interval, planData } = action.data;
      productData.results.forEach((item) => {
        const tmp = _.groupBy(_.get(item, "pricing"), "interval")[interval];
        Object.keys(obj).forEach((el) => {
          if (item.codename == obj[el].codename) {
            obj[el].amount = tmp[0].amount;
            obj[el].strip_id = tmp[0].id;
            obj[el].stripe_id = item.stripe_id;
          }
        });
      });
      Object.keys(obj).forEach((el) => {
        planData.items.forEach((item) => {
          if (item.product_id == obj[el].stripe_id) {
            obj[el].checked = true;
          }
        });
      });
      return obj;
    }
    case SUBSCRIPTION_PLAN_EDIT_BILLING_CYCLE: {
      action.data.productData.results.forEach((item) => {
        const tmp = _.groupBy(_.get(item, "pricing"), "interval")[
          action.data.cycle.billing.value
        ];
        Object.keys(state).forEach((el) => {
          if (item.id == state[el].id) {
            state[el].amount = tmp[0].amount;
            state[el].strip_id = tmp[0].id;
          }
        });
      });
      action.data.planData.items.forEach((item) => {
        Object.keys(state).forEach((el) => {
          if (item.plan_id == state[el].strip_id) {
            state[el].checked = true;
          }
        });
      });

      return state;
    }
    case SUBSCRIPTION_PLAN_CHANGE_PRODUCTS:
      return update(state, {
        [action.data.key]: {
          checked: {
            $set: action.data.checked,
          },
        },
      });
    default:
      return state;
  }
};

export const subscriptionPlanUsersData = (state = {}, action) => {
  switch (action.type) {
    case SUBSCRIPTION_PLAN_INITIALIZE_ADD_USERS_NUM: {
      const { subscriptionData } = action.data;
      const items = subscriptionData.items || [];
      const obj = {};
      items.forEach((item) => {
        if (item.license_type) {
          obj[item.product_id] = { num: item.quantity, active: item.used };
        }
      });
      return obj;
    }
    case SUBSCRIPTION_PLAN_EDIT_BILLING_CYCLE: {
      const arr_a = [];
      action.data.data.forEach((item) => {
        const tmp = item.stripe_id;
        arr_a.push([tmp, { num: 1 }]);
      });
      arr_a.forEach((c, index) => {
        Object.values(action.data.usersPlan).forEach((item, idx) => {
          if (index == idx) {
            c[1].num = item.num;
          }
        });
      });

      const res_a = _.fromPairs(arr_a);
      _.get(action.data.subscriptionData, "items", []).forEach((item) => {
        Object.keys(res_a).map((e) => {
          if (item.product_id == e) {
            res_a[e].num = item.quantity;
            res_a[e].active = item.used;
          }
        });
      });
      return res_a;
    }
    default:
      return state;
  }
};

export const subscriptionPlanAddUsersNum = (state = {}, action) => {
  switch (action.type) {
    case SUBSCRIPTION_PLAN_INITIALIZE_ADD_USERS_NUM: {
      const { subscriptionData } = action.data;
      const items = subscriptionData.items || [];
      const obj = {};
      items.forEach((item) => {
        if (item.license_type) {
          obj[item.product_id] = { num: 0, active: item.used };
        }
      });
      return obj;
    }
    case SUBSCRIPTION_PLAN_EDIT_USER_LICENSE: {
      const { data, value } = action.data;
      return update(state, {
        [data.stripe_id]: {
          num: { $set: value },
        },
      });
    }
    default:
      return state;
  }
};

const initialBillingCycleForm = {
  billing: {
    value: "month",
  },
  renewal: {
    value: "yes",
  },
  cancel_trial: {
    value: "no",
  },
};

export const billingCycleDataForm = (
  state = initialBillingCycleForm,
  action
) => {
  switch (action.type) {
    case SUBSCRIPTION_PLAN_INITIALIZE_BILLING_MANAGE_FORM:
      return update(state, {
        [action.data.name]: {
          $set: { value: action.data.value },
        },
      });
    case SUBSCRIPTION_PLAN_EDIT_BILLING_CYCLE:
      return update(state, {
        [action.data.value.name]: {
          $set: { value: action.data.value.value },
        },
      });
    default:
      return state;
  }
};

const manageEditModalInitial = { loading: false };
export const manageIntervalEditStatus = (
  state = manageEditModalInitial,
  action
) => {
  switch (action.type) {
    case SUBSCRIPTION_PLAN_MANAGE_INTERVAL_LOADING_EDIT:
      return update(state, { loading: { $set: true } });
    case SUBSCRIPTION_PLAN_INTERVAL_IDLE_EDIT:
      return update(state, { loading: { $set: false } });
    case SUBSCRIPTION_PLAN_LOADING_PLAN:
      return update(state, { loading: { $set: true } });
    case SUBSCRIPTION_PLAN_IDLE_PLAN:
      return update(state, { loading: { $set: false } });
    default:
      return state;
  }
};

export const subscriptionPlanUserLicenseOperationType = (
  state = true,
  action
) => {
  switch (action.type) {
    case SUBSCRIPTION_PLAN_TOGGLE_USER_LICENSE_OPERATION_TYPE:
      return action.data;
    case SUBSCRIPTION_PLAN_INITIALIZE_PRODUCTS_DATA:
      return true;
    default:
      return state;
  }
};

//=====================================================HEADER DATA==============================================

const initHeaderData = {
  pro_usage: 0,
  users_active: 0,
  users_assigned: 0,
  price: {
    pro: 0,
    license: 0,
  },
};
export const subscriptionHeaderData = (state = initHeaderData, action) => {
  switch (action.type) {
    case SUBSCRIPTION_PLAN_INITIALIZE_SUBSCRIPTION_HEADER_DATA: {
      const { items } = action.data;
      const pro_data = [];
      const users_data = [];
      items.forEach((e) => {
        const string = e.license_type;
        if (string) {
          users_data.push(e);
        } else {
          pro_data.push(e);
        }
      });
      const cycle = items[0].interval;
      let total_year = 0;
      let total_month = 0;
      if (cycle == "year") {
        total_year =
          (users_data.reduce((a, b) => a + b.total_amount, 0) +
            pro_data.reduce((a, b) => a + b.total_amount, 0)) *
          0.8;
        total_month =
          (users_data.reduce((a, b) => a + b.total_amount, 0) +
            pro_data.reduce((a, b) => a + b.total_amount, 0)) /
          12;
      } else {
        total_year =
          (users_data.reduce((a, b) => a + b.total_amount, 0) +
            pro_data.reduce((a, b) => a + b.total_amount, 0)) *
          12 *
          0.8;
        total_month =
          users_data.reduce((a, b) => a + b.total_amount, 0) +
          pro_data.reduce((a, b) => a + b.total_amount, 0);
      }
      return update(state, {
        pro_usage: { $set: pro_data.length },
        users_active: { $set: users_data.reduce((a, b) => a + b.used, 0) },
        users_assigned: {
          $set: users_data.reduce((a, b) => a + b.quantity, 0),
        },
        price: {
          pro: { $set: pro_data.reduce((a, b) => a + b.total_amount, 0) },
          license: { $set: users_data.reduce((a, b) => a + b.total_amount, 0) },
          total_year: { $set: total_year },
          total_month: { $set: total_month },
        },
      });
    }
    case SUBSCRIPTION_PLAN_EDIT_BILLING_CYCLE: {
      const { cycle, subscriptionData, discount } = action.data;
      const tmp_cycle = cycle.billing.value;
      const res_cycle = subscriptionData.items[0].interval;
      const pro_data = [];
      const users_data = [];
      subscriptionData.items.forEach((e) => {
        const string = e.license_type;
        if (string) {
          users_data.push(e);
        } else {
          pro_data.push(e);
        }
      });
      const pro = pro_data.reduce((a, b) => a + b.total_amount, 0);
      const license = users_data.reduce((a, b) => a + b.total_amount, 0);
      if (res_cycle == "year") {
        if (tmp_cycle == "year") {
          return update(state, {
            price: {
              pro: { $set: pro },
              license: { $set: license },
              total_year: { $set: (pro + license) * discount },
              total_month: { $set: (pro + license) / 12 },
            },
          });
        } else if (tmp_cycle == "month") {
          return update(state, {
            price: {
              pro: { $set: pro / discount / 12 },
              license: { $set: license / discount / 12 },
              total_year: { $set: (pro + license) * discount },
              total_month: { $set: (pro + license) / 12 },
            },
          });
        }
      } else {
        if (tmp_cycle == "year") {
          return update(state, {
            price: {
              pro: { $set: pro * 12 * discount },
              license: { $set: license * 12 * discount },
            },
          });
        } else if (tmp_cycle == "month") {
          return update(state, {
            price: {
              pro: { $set: pro },
              license: { $set: license },
            },
          });
        }
      }

      return state;
    }

    default:
      return state;
  }
};

export const subscriptionPlanDiscount = (state = 1, action) => {
  switch (action.type) {
    case SUBSCRIPTION_PLAN_GET_DISCOUNT: {
      const { productData, userLicenseList } = action.data;
      return calculateDiscountValue(
        productData.results,
        userLicenseList.results
      );
    }
    default:
      return state;
  }
};
