import PricingController from "./PricingController";
import KPIController from "./KPIController";
import ServicesFilter, {
  overviewCtrlSectionHeaderStyles,
  overviewCtrlSectionStyles,
} from "./ServicesFilter";

import ControlPanel from "../Components/ControlPanel";

import _ from "lodash";

import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { getServicesListSelector } from "@src/selectors/Project/TripPlanner/generic_service_selectors";
import { createUseStyles } from "react-jss";
import { variables } from "@src/jsssetup";
import { Form, Formik } from "formik";
import { NormalInputField } from "@src/components/forms";
import { isServiceFilterSelectedSelector } from "@src/selectors/Project/TripPlanner/service_filters";
import { changeDetailedSrvPricing } from "@src/actions/Project/TripPlanner/pricing";
import { getPricingUIDForSrv } from "../Components/ServicePricingForm";
import { InlineIcon } from "@iconify/react";
import ActionsController from "./ActionsController";

const inputCustomStyles = {
  input: { border: `2px solid ${variables.colors.background.dark} !important` },
};

const calculatorStyles = createUseStyles({
  Calculator: {
    ...overviewCtrlSectionStyles(),
  },
  header: overviewCtrlSectionHeaderStyles(),
  content: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    marginTop: variables.normal_gap,
  },
  group: {
    "display": "grid",
    "gridGap": `calc(${variables.normal_gap} / 2)`,
    "gridTemplateColumns": "repeat(3, max-content)",
    "alignItems": "center",
    "fontWeight": "bold",
    "justifyContent": "space-between",
    "& .rs-input-group": {
      border: `2px solid ${variables.colors.easy.lightOrange2}!important`,
    },
    "& input": {
      width: "3rem!important",
    },
  },
  label: {
    display: "grid",
    justifyItems: "center",
  },
  form: {
    display: "grid",
    gridGap: variables.half_gap,
  },
  actions: (props) => ({
    display: "grid",
    gridGap: variables.half_gap,
    gridTemplateColumns: props.mode === "edit" ? "repeat(2, 1fr)" : "1fr",
  }),
  viewModeValue: {
    background: variables.colors.background.base,
    padding: variables.normal_gap,
    borderRadius: variables.half_gap,
  },
  calcIcon: {
    background: variables.colors.background.base,
    borderRadius: variables.half_gap,
    color: variables.colors.easy.orange,
    padding: `calc(${variables.half_gap} / 1.5)`,
    placeSelf: "center",
    fontSize: "xx-large",
  },
});
function Calculator() {
  const [mode, setMode] = useState("view");
  const [pricing, setMarkup] = useState({
    markup: 0,
    gross_margin: 0,
    flat_value: 0,
  });

  const classes = calculatorStyles({ mode });

  const { srvKeys, rPricing } = useSelector((state) => {
    const rPricing = state?.tripPlannerDetailedSrvPricing?.pricing ?? {};

    const services = getServicesListSelector(state);

    const srvKeys = services
      .map((srv) => {
        if (
          isServiceFilterSelectedSelector(state, {
            service_type: srv.service_type,
          })
        ) {
          var service = srv;
          var specificSrvType = null;
          switch (srv.service_type) {
            case "TR": {
              specificSrvType = srv.transportation_service_type;
              service = ["TRA", "FER", "COA"].includes(
                srv.transportation_service_type
              )
                ? srv.service
                : srv;
              break;
            }
            case "ACC":
              specificSrvType = `${srv.service_type}___${srv.accommodation_service_type}`;
              break;
            default:
              specificSrvType = srv.service_type;
              break;
          }

          return getPricingUIDForSrv({ srvType: specificSrvType, service });
        } else {
          return null;
        }
      })
      .filter((s) => s);
    return { srvKeys, rPricing };
  });

  const dispatch = useDispatch();
  const onChangePricing = useCallback(
    (pricing) => dispatch(changeDetailedSrvPricing({ pricing })),
    [dispatch]
  );

  return (
    <div className={classes.Calculator}>
      <div className={classes.header}>CALCULATOR</div>
      <div className={classes.content}>
        <Formik
          initialValues={{ markup: 0, gross_margin: 0, flat_value: 0 }}
          onSubmit={(values) => {
            const { flat_value, gross_margin, markup } = values;
            setMode("view");

            var pricing = {};
            if (flat_value > 0) {
              pricing = { flat_value, gross_margin: 0, markup: 0 };
            } else if (gross_margin > 0) {
              pricing = { flat_value: 0, gross_margin, markup: 0 };
            } else {
              pricing = { flat_value: 0, gross_margin: 0, markup };
            }
            setMarkup(pricing);
            const prs = {};
            srvKeys.forEach((key) => (prs[key] = pricing));
            onChangePricing({ ...rPricing, ...prs });
          }}>
          {({ submitForm }) => (
            <Form className={classes.form}>
              <div className={classes.group}>
                <InlineIcon
                  className={classes.calcIcon}
                  icon="ant-design:stock-outlined"
                />
                <span className={classes.label}>
                  (%) MARK UP <small>on cost</small>
                </span>
                {mode === "edit" && (
                  <NormalInputField
                    name="markup"
                    type="number"
                    extraInputProps={{ min: 0 }}
                    customStyles={inputCustomStyles}
                  />
                )}
              </div>
              <div className={classes.group}>
                <InlineIcon
                  className={classes.calcIcon}
                  icon="ic:baseline-percentage"
                />
                <span className={classes.label}>
                  (%) MARGIN <small>on selling price</small>
                </span>
                {/* mode === "edit" && ( */
                /*   <NormalInputField */
                /*     name="gross_margin" */
                /*     type="number" */
                /*     extraInputProps={{ min: 0 }} */
                /*   /> */
                /* ) */}
              </div>
              <div className={classes.group}>
                <InlineIcon
                  className={classes.calcIcon}
                  icon="grommet-icons:money"
                />
                <span className={classes.label}>GROSS PROFIT</span>
                {/* mode === "edit" && ( */
                /*   <NormalInputField */
                /*     name="flat_value" */
                /*     type="number" */
                /*     extraInputProps={{ min: 0 }} */
                /*   /> */
                /* ) */}
              </div>
              <div className={classes.actions}>
                {mode === "view" && (
                  <button
                    className="Button"
                    data-size="xs"
                    data-full-width="true"
                    onClick={() => setMode("edit")}>
                    Edit
                  </button>
                )}
                {mode === "edit" && (
                  <React.Fragment>
                    <button
                      className="Button"
                      data-size="xs"
                      data-ghost="true"
                      data-full-width="true"
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        setMode("view");
                      }}>
                      Cancel
                    </button>
                    <button
                      className="Button"
                      data-success="true"
                      data-size="xs"
                      data-full-width="true"
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        submitForm();
                      }}>
                      Apply
                    </button>
                  </React.Fragment>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

const overviewControllerStyles = createUseStyles({
  OverviewController: {
    position: "relative",
    display: "grid",
    gridAutoFlow: "row",
    gridGap: variables.normal_gap,
    padding: [
      `calc(${variables.normal_gap} * 3)`,
      0,
      `calc(${variables.normal_gap} * 1)`,
      0,
    ],
    placeItems: "center",
    borderBottom: `2px solid ${variables.colors.borders.base}`,
  },
  loader: { zIndex: 6 },
  card: {
    display: "grid",
    gridTemplateColumns: "repeat(6, max-content)",
    padding: [
      `calc(${variables.normal_gap} * /2)`,
      variables.normal_gap,
      variables.normal_gap,
      variables.normal_gap,
    ],
    justifyContent: "center",
    borderRadius: variables.half_gap,
    boxShadow: variables.shadows.heavyShadow,
    background: "#FFFF",
  },
});
const OverviewController = ({ currentStep }) => {
  const classes = overviewControllerStyles();

  const actionsSection = () => {
    if ([1, 2].includes(currentStep)) {
      return null;
    } else if (currentStep == 3) {
      return <ActionsController />;
    } else {
      return null;
    }
  };

  return (
    <div className={`${classes.OverviewController} OverviewController`}>
      <div className={classes.card}>
        <ControlPanel />
        {actionsSection()}
        <ServicesFilter mode="tripplan" />
        <Calculator />
        <PricingController />
        <KPIController />
      </div>
    </div>
  );
};
OverviewController.propTypes = {
  currentStep: PropTypes.number.isRequired,
  usage: PropTypes.string,
};

export default OverviewController;
