import langDictionaries from "./lang_dictionaries";
import _ from "lodash";

const LANGUAGE = "de";

var langDeDictionary = {};

langDictionaries.forEach((langDictionary) => {
  const obj = _.get(langDictionary, LANGUAGE, {});
  Object.entries(obj).forEach(([key, value]) => {
    langDeDictionary[key] = value;
  });
});

export default langDeDictionary;
