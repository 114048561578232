import { getSrvsTypeForAnyBookingSelector } from "@src/selectors/Project/TripPlanner/prebook/selected_services";
import {
  TRIPPLANNER_ADD_SRVS_FOR_OPTION_BOOKING,
  TRIPPLANNER_ADD_SRVS_FOR_REGULAR_BOOKING,
  TRIPPLANNER_REMOVE_SRVS_FOR_OPTION_BOOKING,
  TRIPPLANNER_REMOVE_SRVS_FOR_REGULAR_BOOKING,
  TRIPPLANNER_RESET_SRVS_FOR_OPTION_BOOKING,
  TRIPPLANNER_RESET_SRVS_FOR_REGULAR_BOOKING,
} from "../types";
import { resetAccPrebookData } from "../step_four";
import { guestsAreInvalid, guestsUpdate } from "../guest_actions";
import _ from "lodash";
import { resetActivityPrebook } from "../activities";
import { getTripActivitiesListSelector } from "@src/selectors/Project/TripPlanner/day_by_day/activities";
import { resetTrfPrebookData } from "../transfers_prebook";

const resetAbsentSrvsPrebooks = () => (dispatch, getState) => {
  const state = getState();
  const srvTypesForBooking = getSrvsTypeForAnyBookingSelector(state);

  if (!srvTypesForBooking.includes("ACC")) {
    dispatch(resetAccPrebookData());
  }
  if (!srvTypesForBooking.includes("TRF")) {
    dispatch(resetTrfPrebookData());
  }
};

const resetPresentSrvsPrebooks = () => (dispatch, getState) => {
  const state = getState();
  const srvTypesForBooking = getSrvsTypeForAnyBookingSelector(state);

  if (srvTypesForBooking.includes("ACC")) {
    dispatch(resetAccPrebookData());
  }
  if (srvTypesForBooking.includes("TRF")) {
    dispatch(resetTrfPrebookData());
  }
};

export function resetSrvsForRegularBooking() {
  return { type: TRIPPLANNER_RESET_SRVS_FOR_REGULAR_BOOKING };
}

export const addSrvsForRegularBooking =
  ({ srvs }) =>
  async (dispatch, getState) => {
    await dispatch({ type: TRIPPLANNER_ADD_SRVS_FOR_REGULAR_BOOKING, srvs });
    const state = getState();
    const srvTypesForBooking = getSrvsTypeForAnyBookingSelector(state);

    await dispatch(resetPresentSrvsPrebooks());

    if (srvTypesForBooking.includes("ACC")) {
      dispatch(guestsAreInvalid());

      // Reset accommodation information on guests
      const guestsInfo = _.cloneDeep(state.tripPlannerGuestsInfo);
      guestsInfo["adults"] = guestsInfo.adults.map((guest) => {
        guest["room_uids"] = [];
        if (Object.keys(guest).includes("room_uid")) {
          guest["room_uid"] = null;
        }
        return guest;
      });
      guestsInfo["children"] = (guestsInfo?.children || []).map((guest) => {
        guest["room_uids"] = [];
        if (Object.keys(guest).includes("room_uid")) {
          guest["room_uid"] = null;
        }
        return guest;
      });

      dispatch(guestsUpdate({ guestsInfo }));
    }
  };

export const removeSrvsForRegularBooking =
  ({ srvs }) =>
  async (dispatch, getState) => {
    await dispatch({ type: TRIPPLANNER_REMOVE_SRVS_FOR_REGULAR_BOOKING, srvs });
    const state = getState();
    dispatch(resetAbsentSrvsPrebooks());

    srvs.forEach((srv) => {
      if (srv.srv_type === "ACT") {
        const activitiesList = getTripActivitiesListSelector(state);
        const activity = activitiesList.find((act) => act.uid === srv.uid);
        if (activity?.prebookData) {
          dispatch(
            resetActivityPrebook({ uid: activity.uid, date: activity.date })
          );
        }
      }
    });
  };

export function resetSrvsForOptionBooking() {
  return { type: TRIPPLANNER_RESET_SRVS_FOR_OPTION_BOOKING };
}

export function addSrvsForOptionBooking({ srvs }) {
  return { type: TRIPPLANNER_ADD_SRVS_FOR_OPTION_BOOKING, srvs };
}

export const removeSrvsForOptionBooking =
  ({ srvs }) =>
  async (dispatch) => {
    await dispatch({ type: TRIPPLANNER_REMOVE_SRVS_FOR_OPTION_BOOKING, srvs });
    dispatch(resetAbsentSrvsPrebooks());
  };
