import moment from "moment";
import _ from "lodash";
import { Duration } from "luxon";

const getIsoTimeStamp = () => {
  return moment().toISOString();
};

export const timeStampInjector = (data) => {
  return { ...data, ...{ timestamp: getIsoTimeStamp() } };
};

export const formatDateTime = (
  date,
  format = "DD/MM/YYYY HH:mm Z",
  options
) => {
  if (date == "" || date == null) {
    return "";
  } else {
    if (typeof options !== "undefined") {
      if (options.toUTC) {
        return moment.utc(date).format(format);
      } else {
        return moment(date).format(format);
      }
    } else {
      return moment(date).format(format);
    }
  }
};

export function humanizeDuration(milliseconds) {
  if (isNaN(milliseconds)) {
    return "0 mins";
  }

  var dur = Duration.fromObject({ milliseconds });
  var minutes = dur.as("minutes");
  const hours = parseInt(minutes / 60, 10);
  minutes = parseInt(minutes, 10) % 60;
  const durObj = {};
  if (hours > 0) {
    durObj["hours"] = hours;
  }
  if (minutes > 0) {
    durObj["minutes"] = minutes;
  }

  if (_.isEmpty(durObj)) {
    return "0 mins";
  }

  dur = Duration.fromObject(durObj);
  const duration = dur.toHuman({ unitDisplay: "short" });
  return duration;
}

export const formatDate = (date, options) => {
  if (date == "" || date == null) {
    return "";
  } else {
    if (typeof options == "object") {
      if (typeof options.format == "string") {
        return moment(date).format(options.format);
      }
    } else {
      return moment(date).format("DD/MM/YYYY");
    }
  }
};

export const formatToDateTime = (date, options) => {
  if (date == "" || date == null) {
    return "";
  } else {
    if (typeof options !== "undefined") {
      if (options.toUTC) {
        return moment.utc(date).format("DD/MM/YYYY HH:mm Z");
      } else {
        return moment(date).format("DD/MM/YYYY HH:mm Z");
      }
    } else {
      return moment(date).format("DD/MM/YYYY HH:mm Z");
    }
  }
};

export const formatRangeDateTimeToUTC = (rangeTimevalue) => {
  const temp = _.cloneDeep(rangeTimevalue);
  if (!_.isEmpty(temp)) {
    temp[0] = formatDateTime(temp[0], null, {
      toUTC: true,
    });
    temp[1] = formatDateTime(temp[1], null, {
      toUTC: true,
    });
  }
  return temp;
};
