import {
  // ===================== CONTROL PANEL ACTIONS =====================
  TRIPPLANNER_CANCEL_CONTROL_PANEL_REQUEST,
  TRIPPLANNER_REQUEST_SAVE_TRIP,
  // ===================== OVERVIEW SERVICE FILTERS ====================
  TRIPPLANNER_SERVICE_FILTERS_CHANGE,
  TRIPPLANNER_SERVICE_FILTERS_RESET,
} from "./types";

// ============================= ACTIONS =============================

import { resetAccPrebookData } from "./step_four";
import { TRIPPLANNER_CHANGE_OVERVIEW_VIEW_STYLE } from "./overview_types";
import { resetAddOns } from "./day_by_day_actions";
import { accommodationEdit } from "./accommodation_actions";
import { getSetupFormDataSelector } from "@src/selectors/Project/TripPlanner";
import { validateAccPref } from "./tools";
import { notifyCommonWarning } from "@src/components/common/notifications/CommonWarningNotification.js";
import { resetGuestsRooms } from "./guest_actions";
import { resetFlightsPrebook } from "./step_four/prebook/flights";

export const stepThreeRequirements = () => (dispatch, getState) => {
  const state = getState();
  const { adults, children, children_ages, requiredServices } =
    getSetupFormDataSelector(state);

  if (requiredServices.includes("ACC")) {
    const validInfo = validateAccPref(
      {
        adults: parseInt(adults, 10),
        children: parseInt(children, 10),
        children_ages,
      },
      state.tripPlannerAccPax
    );

    if (!validInfo.isValid) {
      validInfo.msg.forEach((m) => {
        window.setTimeout(() => notifyCommonWarning(m), 100);
      });
      dispatch(accommodationEdit());
      dispatch(resetGuestsRooms());
      return false;
    }
  }
  dispatch(resetAccPrebookData());
  dispatch(resetFlightsPrebook());
  dispatch(resetAddOns());

  if (getState().tripPlannerOverviewStyle.view_style == "day_by_day") {
    dispatch(changeViewStyle("overview"));
  }
  return true;
};

export const serviceFiltersChange = (name, value) => {
  return { type: TRIPPLANNER_SERVICE_FILTERS_CHANGE, name, value };
};

export const serviceFiltersReset = () => {
  return { type: TRIPPLANNER_SERVICE_FILTERS_RESET };
};

export const requestTripSave = () => {
  return { type: TRIPPLANNER_REQUEST_SAVE_TRIP };
};

export const cancelRequestTripSave = () => {
  return { type: TRIPPLANNER_CANCEL_CONTROL_PANEL_REQUEST };
};

export const changeViewStyle = (view_style) => {
  return { type: TRIPPLANNER_CHANGE_OVERVIEW_VIEW_STYLE, view_style };
};
