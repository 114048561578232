import { createSelector } from "reselect";

import { bankersRounder } from "@src/tools/pricing_tools";
import _ from "lodash";
import { extremalIsRoundSelector } from "./transportation_selectors";
import { destsLengthSelector } from "./trip_itinerary_selectors";

const propsInjector = (___, props) => props || {};

export const getAllTripTransfers = (state) => state.tripPlannerTransferData;

export const getSelectedTrfSelector = createSelector(
  [
    (state, { destOrder, transfer_type }) => {
      const transfers = state.tripPlannerTransferData;
      var trf = (transfers?.[destOrder]?.[transfer_type] ?? []).find(
        (t) => t?.selected ?? false
      );
      return trf ?? null;
    },
  ],
  (transfers) => transfers
);

export const getAllSelectedTrfSelector = createSelector(
  [getAllTripTransfers],
  (transfers) => {
    const trfs = {};

    Object.entries(transfers).forEach((entry) => {
      if (_.get(entry[1], `inbound_outbound`, null)) {
        const trf = _.cloneDeep(
          _.get(entry[1], `inbound_outbound`, []).find((t) => t.selected)
        );

        trfs[entry[0].toString()] = {
          inbound: { ...trf, outbound: {} },
          outbound: { ...trf, inbound: {} },
        };
      } else {
        trfs[entry[0].toString()] = {
          inbound:
            _.get(entry[1], "inbound", []).find((trf) => trf.selected) || null,
          outbound:
            _.get(entry[1], "outbound", []).find((trf) => trf.selected) || null,
        };
      }
    });

    return trfs;
  }
);

export const isTransferRefundable = (transfer) => {
  return _.get(transfer, "cancellation_policies", []).some(
    (c) => c.type == "refundable"
  );
};

export const isTransferRefundableSelector = createSelector(
  [propsInjector],
  (props) => {
    const { transfer } = props;
    return isTransferRefundable(transfer);
  }
);

export const getTrfHandlingInfoSelector = createSelector(
  [destsLengthSelector, extremalIsRoundSelector, () => ({}), , propsInjector],
  (destsLength, extremalIsRound, flights, props) => {
    const { destOrder, trf_type } = props;

    const inboundFlOrder = destOrder == 1 ? "extremalLeg" : destOrder - 1;

    const outboundFlOrder =
      destOrder == destsLength && extremalIsRound ? "extremalLeg" : destOrder;

    const inboundFlight = flights[inboundFlOrder][0];
    const outboundFlight = _.get(flights, `${outboundFlOrder}.0`);

    const originInbSeg = inboundFlight.outbound_options[0].segments[0];
    const destInbdSegment = _.last(inboundFlight.outbound_options[0].segments);

    var destOutbdSegment = null;
    var destOutbdArrSegment = null;

    if (outboundFlOrder == "extremalLeg") {
      destOutbdSegment = _.last(outboundFlight.legs)[0].segments[0];
      destOutbdArrSegment = _.last(_.last(outboundFlight.legs)[0].segments);
    } else {
      destOutbdSegment = _.get(outboundFlight, `outbound_options.0.segments.0`);
      destOutbdArrSegment = _.last(
        _.get(outboundFlight, `outbound_options.0.segments`)
      );
    }

    const inbound = {
      departing_airport: `${originInbSeg.origin_airport.name} (${originInbSeg.origin_airport.iata})`,
      arriving_airport: `${destInbdSegment.destination_airport.name} (${destInbdSegment.destination_airport.iata})`,
      airline: `${inboundFlight.outbound_operating_airline.name} (${inboundFlight.outbound_operating_airline.iata})`,
      flight_number: destInbdSegment.flight_number,
    };

    const outbound = {
      departing_airport: `${_.get(
        destOutbdSegment,
        "origin_airport.name"
      )} (${_.get(destOutbdSegment, "origin_airport.iata")})`,
      arriving_airport: `${_.get(
        destOutbdArrSegment,
        "destination_airport.name"
      )} (${_.get(destOutbdArrSegment, "destination_airport.iata")})`,
      airline: `${_.get(
        destOutbdArrSegment,
        "operating_airline.name"
      )} (${_.get(destOutbdSegment, "operating_airline.iata")})`,
      flight_number: `${_.get(destOutbdArrSegment, "flight_number")}`,
    };

    if (trf_type == "inbound_outbound") {
      return { inbound, outbound };
    } else if (trf_type == "inbound") {
      return { inbound, outbound: {} };
    } else if (trf_type == "outbound") {
      return { outbound, inbound: {} };
    } else {
      return null;
    }
  }
);

export const getTransferFromUid = createSelector(
  [getAllTripTransfers, propsInjector],
  (transfers, props) => {
    const { search_uid } = props;
    const transfer_results = _.flattenDeep(
      Object.entries(transfers).map((e) =>
        Object.entries(e[1]).map((ee) =>
          ee[1].map((vl) => ({
            ...vl,
            ...{ destOrder: parseInt(e[0], 10), transfer_type: ee[0] },
          }))
        )
      )
    );

    return transfer_results.find((trf) => trf.search_uid == search_uid);
  }
);

export const getTransferPriceSelector = (transfer) => {
  const price =
    !_.isEmpty(transfer?.outbound ?? {}) && !_.isEmpty(transfer?.inbound ?? {})
      ? bankersRounder((transfer?.price?.value ?? 0) / 2, 2)
      : bankersRounder(transfer?.price?.value ?? 0, 2);
  return price;
};
