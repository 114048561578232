export const TRIPPLANNER_SAVE_FERRY_SESSION_DATA =
  "TRIPPLANNER_SAVE_FERRY_SESSION_DATA";
export const TRIPPLANNER_RESET_FERRY_SESSION_DATA =
  "TRIPPLANNER_RESET_FERRY_SESSION_DATA";
export const TRIPPLANNER_SAVE_FERRY_DATA = "TRIPPLANNER_SAVE_FERRY_DATA";
export const TRIPPLANNER_UPDATE_FERRY_DATA = "TRIPPLANNER_UPDATE_FERRY_DATA";
export const TRIPPLANNER_REMOVE_FERRY_DATA = "TRIPPLANNER_REMOVE_FERRY_DATA";

export function saveFerrySessionData(session_data) {
  return { type: TRIPPLANNER_SAVE_FERRY_SESSION_DATA, session_data };
}

export function saveFerryData(ferryData) {
  ferryData.service_type = "TR";
  ferryData.transportation_service_type = "AFER";
  return { type: TRIPPLANNER_SAVE_FERRY_DATA, ferryData };
}

export function removeFerryData(ferryToken) {
  return { type: TRIPPLANNER_REMOVE_FERRY_DATA, ferryToken };
}

export function resetFerrySessionData() {
  return { type: TRIPPLANNER_RESET_FERRY_SESSION_DATA };
}

export function updateFerryData({ token, key, data }) {
  return { type: TRIPPLANNER_UPDATE_FERRY_DATA, token, key, data };
}
