import _ from "lodash";
import { createSelector } from "reselect";
import {
  getSrvsUidForBooking,
  getSrvsUidForOptionBooking,
} from "../prebook/selected_services";

function getTranspBookingSrvs({ state, booking_mode, srv_type }) {
  const all_reg_booking_srvs = getSrvsUidForBooking(state, {
    srv_type,
  });

  const all_option_booking_srvs = getSrvsUidForOptionBooking(state, {
    srv_type,
  });

  const book_srvs =
    booking_mode === "regular" ? all_reg_booking_srvs : all_option_booking_srvs;

  return book_srvs;
}

const getCoachServices = (state, props = {}) => {
  const { booking_mode } = props;

  const services = _.get(state, "tripPlanTrpAddhocServices.legs", []).filter(
    (srv) => srv.addhoc_service_type === "COA"
  );

  if (["regular", "option"].includes(booking_mode)) {
    const book_srvs = getTranspBookingSrvs({
      state,
      srv_type: "COA",
      booking_mode,
    });

    return services.filter((srv) =>
      book_srvs.some((s) => s.srv_type === "COA" && s.uid === srv.service.uid)
    );
  } else {
    return services;
  }
};

export const getCoachServicesSelector = createSelector(
  [getCoachServices],
  (coaches) => coaches
);

const getFerryServices = (state, props = {}) => {
  const { booking_mode } = props;
  const services = _.get(state, "tripPlanTrpAddhocServices.legs", []).filter(
    (srv) => srv.addhoc_service_type === "FER"
  );

  if (["regular", "option"].includes(booking_mode)) {
    const book_srvs = getTranspBookingSrvs({
      state,
      srv_type: "FER",
      booking_mode,
    });

    return services.filter((srv) =>
      book_srvs.some((s) => s.srv_type === "FER" && s.uid === srv.service.uid)
    );
  } else {
    return services;
  }
};

export const getFerryServicesSelector = createSelector(
  [getFerryServices],
  (srvs) => srvs
);

const getTrainServices = (state, props = {}) => {
  const { booking_mode } = props;

  var services = _.get(state, "tripPlanTrpAddhocServices.legs", []).filter(
    (srv) => srv.addhoc_service_type === "TRA"
  );

  if (["regular", "option"].includes(booking_mode)) {
    const book_srvs = getTranspBookingSrvs({
      state,
      srv_type: "TRA",
      booking_mode,
    });

    return services.filter((srv) =>
      book_srvs.some((s) => s.srv_type === "TRA" && s.uid === srv.service.uid)
    );
  } else {
    return services;
  }
};

export const getTrainServicesSelector = createSelector(
  [getTrainServices],
  (srvs) => srvs
);
