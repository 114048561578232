import PropTypes from "prop-types";
import React from "react";
import { Form, Formik } from "formik";
import {
  NormalDatePicker,
  NormalInputField,
  NormalSelectField,
} from "@src/components/forms";
import { CustomButton } from "@src/components/common/buttons";
import { defaultFilters } from "../helpers";

export const FiltersBar = ({
  filters,
  onSetFilters,
  options,
  averageDuration,
  averagePrice,
  minPrice,
  maxPrice,
  operatorOptions,
}) => {
  return (
    <div className="FiltersBar">
      <Formik
        initialValues={filters}
        onSubmit={async (values) => {
          onSetFilters(values);
        }}>
        {({ resetForm }) => (
          <Form className="FiltersBar__form">
            <NormalSelectField
              name="operator"
              label="Operator"
              options={operatorOptions}
            />
            <NormalDatePicker
              name="maxArrival"
              label="Arrival Before"
              withTime
              withTimeOnly
            />
            <NormalDatePicker
              name="minArrival"
              label="Arrival After"
              withTime
              withTimeOnly
            />
            <NormalDatePicker
              name="maxDeparture"
              label="Departure Before"
              withTime
              withTimeOnly
            />
            <NormalDatePicker
              name="minDeparture"
              label="Departure After"
              withTime
              withTimeOnly
            />
            <NormalInputField
              name="min_price"
              label="Min Price"
              type="number"
            />
            <NormalInputField
              name="max_price"
              label="Max Price"
              type="number"
            />
            <div className="FiltersBar__form__orderByCntr">
              <NormalSelectField
                name="order_by"
                label="Order By"
                options={[
                  ["", "---"],
                  ["price", "Price (Lower First)"],
                  ["-price", "Price (Higher First)"],
                  ["duration", "Duration (Shortest First)"],
                  ["-duration", "Duration (Longest First)"],
                  ["arrival", "Arrival time (Earliest First)"],
                  ["-arrival", "Arrival time (Latest First)"],
                  ["departure", "Departure time (Earliest First)"],
                  ["-departure", "Departure time (Latest First)"],
                ]}
              />
            </div>
            <div className="FiltersBar__form__actions">
              <span className="FiltersBar__form__actions__properties">
                <strong>Available Options: </strong>
                {options}
              </span>
              <span className="FiltersBar__form__actions__properties">
                <strong>Average Duration: </strong>
                {averageDuration}
              </span>
              <span className="FiltersBar__form__actions__properties">
                <strong>Average Price: </strong>
                {averagePrice}
              </span>
              <span className="FiltersBar__form__actions__properties">
                <strong>Min Price: </strong>
                {minPrice}
              </span>
              <span className="FiltersBar__form__actions__properties">
                <strong>Max Price: </strong>
                {maxPrice}
              </span>
              <div
                className="FiltersBar__form__actions__actionBtnsContr"
                data-has-results={options > 0 ? true : false}>
                <CustomButton
                  onClick={(e) => {
                    e.preventDefault();
                    resetForm();
                    onSetFilters(defaultFilters.cast({}));
                  }}>
                  Reset
                </CustomButton>
                <CustomButton type="submit">Apply</CustomButton>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
FiltersBar.defaultProps = {
  filters: {},
  options: 0,
  averageDuration: "",
  averagePrice: "",
  minPrice: 0,
  maxPrice: 0,
  operatorOptions: [],
};
FiltersBar.propTypes = {
  setShowFilters: PropTypes.func,
  filters: PropTypes.object.isRequired,
  onSetFilters: PropTypes.func.isRequired,
  options: PropTypes.number.isRequired,
  averageDuration: PropTypes.string.isRequired,
  averagePrice: PropTypes.string.isRequired,
  minPrice: PropTypes.number.isRequired,
  maxPrice: PropTypes.number.isRequired,
  operatorOptions: PropTypes.array.isRequired,
};
