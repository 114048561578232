import { IconButton, Icon, Drawer, Loader } from "rsuite";

import { AutoSizer, List } from "react-virtualized";
import SuIcon from "@src/style/icon/components/SuIcon";
import HotelDetails from "./OverviewHotelDetails";
import OverviewAccFilters, {
  accFiltersValidationSchema,
} from "./OverviewAccFilters";

// ============================ SELECTORS ============================
import { getSelectedAccSelector } from "@src/selectors/Project/TripPlanner/accommodation_selectors";

import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { humanizeDistance } from "@src/map_tools/map";
import LeanAuthHoc from "@src/components/authorization/LeanAuthHoc";
import { createUseStyles } from "react-jss";
import { image_placeholder_s3, variables } from "@src/jsssetup";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchSimpleHotelMetaData, pollAllAccs } from "@src/api";
import { handleNonValidImgFn } from "@src/tools/common_tools";
import { ItinOverviewContext } from "../Controllers/ItineraryOverview";
import { CustomButton } from "@src/components/common/buttons";
import { convertAmountSelector } from "@src/selectors/Financial";
import Fuse from "fuse.js";
import { distance, point } from "@turf/turf";
import { toast } from "react-toastify";
import { overviewAccFiltersChange } from "@src/actions/Project/TripPlanner";
import HotelsMap from "./OverviewAllHotels/HotelsMap";

const fuseCfg = {
  shouldSort: false,
  threshold: 0.3,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ["metadata.name"],
};

export const Rating = (props) => {
  if (isNaN(props.rating)) {
    return <span className="Rating"></span>;
  }

  return (
    <span className="Rating">
      {new Array(Math.abs(props.rating)).fill(1).map((r, idx) => (
        <SuIcon key={idx} icon="icon-xiaoxingxing" />
      ))}
    </span>
  );
};
Rating.defaultProps = {
  rating: 0,
};
Rating.propTypes = {
  rating: PropTypes.number.isRequired,
};

var VendorInfo = ({ vendors }) => {
  return (
    <span className="attribute">
      <strong>Vendors</strong>
      <small>
        {_.sortBy(vendors.map((vendor) => _.capitalize(vendor))).join(", ")}
      </small>
    </span>
  );
};
VendorInfo.defaultProps = { vendors: [] };
VendorInfo.propTypes = { vendors: PropTypes.array.isRequired };
VendorInfo = LeanAuthHoc(VendorInfo, { allowed_groups: ["member"] });

const priceStyles = createUseStyles({
  Price: { fontWeight: "bold", fontSize: "larger", alignSelf: "end" },
});
export const HotelPrice = ({ price, currency }) => {
  const classes = priceStyles();
  const { convertedAmount, currency: convertedCurrency } = useSelector(
    (state) =>
      convertAmountSelector(state, {
        amount: price,
        currentCurrency: currency,
      })
  );

  return (
    <span className={classes.Price}>
      {convertedAmount.toLocaleString("en-US", {
        style: "currency",
        currency: convertedCurrency,
      })}
    </span>
  );
};
HotelPrice.propTypes = {
  price: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
};

const ListHotel = ({
  accommodation,
  virtualStyle,
  ghost = false,
  isSelected = false,
  onViewRooms,
}) => {
  var { data: metadata } = useQuery({
    queryKey: [
      "hotelMetaData",
      accommodation.metadata.id,
      accommodation.metadata.data_supplier,
      ghost,
    ],
    queryFn: async () => {
      if (ghost) {
        new Promise((resolve) => resolve(null));
      }

      const delay = async () =>
        new Promise((resolve) => setTimeout(resolve, 300));

      if (accommodation.metadata.id) {
        await delay();
        return fetchSimpleHotelMetaData(
          accommodation.metadata.id,
          accommodation.metadata.data_supplier
        );
      }

      return new Promise((resolve) => resolve(null));
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
  metadata = metadata?.data?.hotels[0];

  const refundable = new Boolean(
    accommodation?.computed_data?.refundable
  ).valueOf();

  const mainImg = _.get(metadata, "images.0.url", "");
  const vendors = accommodation?.computed_data?.available_providers || [];

  const minPrice = Math.min(
    ...(accommodation?.detailed_rooms || accommodation?.rooms || []).map(
      (r) => r.price.value
    )
  );
  const minPriceCurrency = _.minBy(
    accommodation?.detailed_rooms || accommodation?.rooms || [],
    (r) => r.price.value
  )?.price?.currency;

  return (
    <div
      style={virtualStyle}
      className={`ListHotel${isSelected ? " ListHotel--selected" : ""}`}>
      <img
        className="img"
        src={mainImg}
        alt=""
        onError={(e) => handleNonValidImgFn(e, "ACC")}
      />
      <div className="content">
        <span className="title">
          <strong>{metadata?.name}</strong>
          <small className="rating">
            <Rating rating={metadata?.rating} />
          </small>
        </span>
        <span>
          <small>{metadata?.address}</small>
        </span>
        <div
          className={`attributes${
            accommodation.nearbyDistance ? " attributes--with-nearby-poi" : ""
          }`}>
          <span className="attribute">
            <strong>Type</strong>
            <small>{metadata?.hotel_type || "Hotel"}</small>
          </span>
          <span className="attribute">
            <strong>Boards</strong>
            <small>
              {_.sortBy(accommodation.computed_data.available_boards).join(
                ", "
              )}
            </small>
          </span>
          <span
            className={`attribute${
              refundable ? " refundable" : " nonrefundable"
            }`}>
            <strong>Cancellation Policies </strong>
            <small>{refundable ? "Refundable" : "Non Refundable"}</small>
          </span>
          <VendorInfo vendors={vendors} />
          {accommodation.nearbyDistance ? (
            <span className="attribute">
              <strong>POI Distance</strong>
              <small>
                {humanizeDistance(accommodation.nearbyDistance * 1000)}
              </small>
            </span>
          ) : null}
        </div>
        <div className="bottomContent">
          <HotelPrice price={minPrice} currency={minPriceCurrency} />
          <CustomButton onClick={onViewRooms}>View Rooms</CustomButton>
        </div>
      </div>
    </div>
  );
};
ListHotel.defaultProps = {
  ghost: false,
  isSelected: false,
};

ListHotel.propTypes = {
  accommodation: PropTypes.object.isRequired,
  virtualStyle: PropTypes.object.isRequired,
  ghost: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onViewRooms: PropTypes.func.isRequired,
};

const accListStyles = createUseStyles({
  AccommodationList: {
    overflow: "hidden",
    height: "100%",
  },
});
const AccommodationList = ({
  accommodations,
  destOrder,
  setPage,
  onViewRooms,
}) => {
  const classes = accListStyles();

  const rowCount = accommodations.length;

  const selectedHotel = useSelector((state) =>
    getSelectedAccSelector(state, { destOrder })
  );

  return (
    <div className={classes.AccommodationList}>
      {accommodations.length > 0 && (
        <AutoSizer>
          {({ width, height }) => (
            <List
              height={height}
              width={width}
              rowCount={accommodations.length}
              overscanRowCount={10}
              rowHeight={160}
              rowRenderer={({ index, style, isScrolling }) => {
                const acc = _.get(accommodations, index);
                if (index + 1 === rowCount) {
                  if (acc?.type === "donebtn") {
                    return null;
                  }
                  return (
                    <CustomButton
                      key={`loadmore-${index}`}
                      customStyles={{
                        CustomButton: {
                          ...style,
                          width: "20%",
                          height: "2rem",
                          left: "50%",
                          transform: "translate(-50%, 200%)",
                          textAlign: "center",
                        },
                      }}
                      onClick={() => setPage((p) => p + 1)}>
                      Load More
                    </CustomButton>
                  );
                }

                return !acc ? null : (
                  <ListHotel
                    key={acc.metadata.id}
                    isSelected={selectedHotel?.metadata?.id === acc.metadata.id}
                    ghost={isScrolling}
                    virtualStyle={style}
                    accommodation={acc}
                    onViewRooms={() => onViewRooms(acc.metadata.id)}
                  />
                );
              }}
            />
          )}
        </AutoSizer>
      )}
    </div>
  );
};
AccommodationList.defaultProps = {
  accommodations: [],
};
AccommodationList.propTypes = {
  accommodations: PropTypes.array.isRequired,
  destOrder: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  onViewRooms: PropTypes.func.isRequired,
};

const overviewAllAccommodationStyles = createUseStyles({
  OverviewAllAccommodation: {
    "& .rs-drawer-content": {
      position: "absolute",
      backgroundColor: "#fff",
      outline: 0,
      width: "100%",
      height: "100%",
      display: "grid",
      gridTemplateRows: "5rem 1fr",
    },
  },
  header: {
    "background": variables.colors.background.dark,
    "padding": variables.normal_gap,
    "margin": 0,
    "display": "grid",
    "gridTemplateColumns": "1fr max-content",
    "alignItems": "center",
    "& .rs-drawer-title": {
      color: "white",
    },
    "& .rs-drawer-header-close": {
      display: "none",
    },
  },
  body: {
    height: "100%!important",
    display: "grid",
    gridTemplateRows: "max-content 1fr",
    margin: "0px",
  },
});
const OverviewAllAccommodation = ({ destOrder, session_id }) => {
  const pageSize = 10;
  const [mode, setMode] = useState("list"); // list, listmap, details
  const [accId, setAccId] = useState(null);
  const [page, setPage] = useState(1);
  const filters = useSelector((state) => state.tripPlannerOverviewAccsFilters);

  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const setFilters = useCallback(
    (filters) => dispatch(overviewAccFiltersChange(filters)),
    []
  );

  const { onShowViewAllAccs } = useContext(ItinOverviewContext);

  useEffect(() => {
    setPage(1);
  }, [filters]);

  function handleClose() {
    setFilters(accFiltersValidationSchema.cast({}));
    onShowViewAllAccs({
      session_id: null,
      destOrder: null,
      show: false,
    });
  }

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ["accommodations", destOrder],
    queryFn: async () =>
      await pollAllAccs({ session_id, market_type: "B2B", lean: true }),
    onError: () => {
      toast.error("Failed to fetch accommodations", { autoClose: 3000 });
      handleClose();
    },
    refetchOnWindowFocus: false,
  });
  const accommodations = data?.data?.results || [];

  var filteredAccs = accommodations.filter((acc) => {
    if (filters.rating && acc.metadata.rating != filters.rating) {
      return false;
    }

    if (
      filters.vendor &&
      !acc.computed_data.available_providers.includes(filters.vendor)
    ) {
      return false;
    }

    if (
      filters.board &&
      !acc.computed_data.available_boards.includes(filters.board)
    ) {
      return false;
    }

    if (
      filters.refundable &&
      filters.refundable !== acc.computed_data.refundable.toString()
    ) {
      return false;
    }

    return true;
  });

  filteredAccs = useSelector((state) => {
    const fAccs = filteredAccs.filter((acc) => {
      var accMinPrice = Math.min(...acc.rooms.map((r) => r.price.value));
      var accMaxPrice = Math.max(...acc.rooms.map((r) => r.price.value));
      const currency = acc?.rooms?.[0]?.price?.currency || "EUR";
      accMinPrice = convertAmountSelector(state, {
        amount: accMinPrice,
        currentCurrency: currency,
      }).convertedAmount;
      accMaxPrice = convertAmountSelector(state, {
        amount: accMaxPrice,
        currentCurrency: currency,
      }).convertedAmount;

      if (filters.min_price && accMinPrice < filters.min_price) {
        return false;
      }
      if (filters.max_price && accMaxPrice > filters.max_price) {
        return false;
      }

      return true;
    });

    return { filteredAccs: fAccs };
  }).filteredAccs;

  if (filters.name) {
    const fuse = new Fuse(filteredAccs, fuseCfg);
    filteredAccs = fuse.search(filters.name).map((r) => r);
  }

  switch (filters.order_by) {
    case "price": {
      filteredAccs = _.sortBy(filteredAccs, (a) => {
        const minPrice = Math.min(
          ...(a?.detailed_rooms || a?.rooms || []).map((r) => r.price.value)
        );
        return minPrice;
      });
      break;
    }
    case "-price": {
      filteredAccs = _.sortBy(filteredAccs, (a) => {
        const minPrice = Math.min(
          ...(a?.detailed_rooms || a?.rooms || []).map((r) => r.price.value)
        );
        return minPrice * -1;
      });
      break;
    }
    default:
      break;
  }

  if (
    (filters?.nearbyGeodata?.lat !== 0 || 0) &&
    (filters?.nearbyGeodata?.lng || 0) !== 0
  ) {
    const fromPoint = point([
      filters.nearbyGeodata.lat,
      filters.nearbyGeodata.lng,
    ]);

    filteredAccs = _.sortBy(filteredAccs, (a) => {
      var ddistance = null;
      const ppoint = point([a.metadata.geodata.lat, a.metadata.geodata.lng]);
      ddistance = parseFloat(distance(fromPoint, ppoint).toFixed(1));
      a.nearbyDistance = ddistance;
      return ddistance;
    });
  }

  var pagedAccs = filteredAccs.slice(0, page * pageSize);

  if (pagedAccs.length != filteredAccs.length) {
    pagedAccs.push({ type: "btn" });
  } else {
    pagedAccs.push({ type: "donebtn" });
  }

  const classes = overviewAllAccommodationStyles();

  var selectedAccDetails = null;
  if (accId) {
    selectedAccDetails = accommodations.find((a) => a.metadata.id == accId);
  }

  return (
    <Drawer
      size="lg"
      placement="right"
      show={true}
      onHide={handleClose}
      className={classes.OverviewAllAccommodation}>
      {(isLoading || isFetching) && <Loader center size="lg" backdrop />}
      <Drawer.Header className={classes.header}>
        <div></div>
        <Drawer.Title>
          {mode === "details" ? "Room List" : "Accommodation List"}
        </Drawer.Title>
        {mode == "details" ? (
          <IconButton
            className="back-btn"
            icon={<Icon icon="left" />}
            size="sm"
            placement="left"
            onClick={() => {
              setMode("list");
              setAccId(null);
            }}>
            Back
          </IconButton>
        ) : (
          <IconButton
            icon={<Icon icon="close" />}
            size="sm"
            placement="right"
            onClick={handleClose}>
            Close
          </IconButton>
        )}
      </Drawer.Header>
      <Drawer.Body className={classes.body}>
        {mode === "details" ? (
          <HotelDetails
            destOrder={destOrder}
            accommodation={selectedAccDetails}
            accId={selectedAccDetails?.metadata?.id}
            session_id={session_id}
            onBack={() => {
              setMode("list");
              setAccId(null);
              queryClient.invalidateQueries(["hotelRoomsDetails", accId]);
            }}
            onCloseDetails={handleClose}
          />
        ) : (
          <React.Fragment>
            <OverviewAccFilters
              accommodations={accommodations}
              onSetFilters={(filters) => setFilters(filters)}
              mode={mode}
              onViewMap={() => setMode("listmap")}
              onViewList={() => setMode("list")}
            />
            {mode == "list" ? (
              <AccommodationList
                destOrder={destOrder}
                accommodations={pagedAccs}
                setPage={setPage}
                onViewRooms={(accId) => {
                  setMode("details");
                  setAccId(accId);
                }}
              />
            ) : (
              <HotelsMap
                accommodations={filteredAccs}
                onViewDetails={(accId) => {
                  setAccId(accId);
                  setMode("details");
                }}
              />
            )}
          </React.Fragment>
        )}
      </Drawer.Body>
    </Drawer>
  );
};
OverviewAllAccommodation.propTypes = {
  destOrder: PropTypes.number.isRequired,
  session_id: PropTypes.string.isRequired,
};
export default OverviewAllAccommodation;
