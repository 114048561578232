import { Placeholder, Panel, IconButton, Icon, Drawer, Button } from "rsuite";

import { humanizeDuration } from "@src/tools/date_tools";

import OverviewTrfFilters from "./OverviewTrfFilters";

import PriceDetails from "../Components/PriceDetails";
import SuIcon from "@src/style/icon/components/SuIcon";
import { saveTransfer } from "@src/actions/Project/TripPlanner";

import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import CancellationPolicyDetails from "./CancellationPolicyDetails";
import { createUseStyles } from "react-jss";
import { variables } from "@src/jsssetup";
import { trfGetResults } from "@src/api";
import { toast } from "react-toastify";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";

const transferStyles = createUseStyles({
  Transfer: {
    display: "grid",
    gridTemplateColumns: "8rem 1fr 5rem",
    gridGap: variables.normal_gap,
    padding: variables.normal_gap,
    position: "relative",
  },
  mainContent: {
    display: "grid",
    gridGap: variables.half_gap,
    boxShadow: variables.shadows.normalShadow,
    borderRadius: variables.normal_gap,
    padding: variables.normal_gap,
  },
  pointHeader: {
    color: `${variables.colors.text.colored}!important`,
  },
  trfAttributes: {
    display: "grid",
    gridGap: variables.half_gap,
    gridTemplateColumns: "repeat(3, 1fr)",
    justifyItems: "center",
  },
  trfAttribute: {
    color: `${variables.colors.text.colored}!important`,
  },
  icon: {
    color: `${variables.colors.text.colored}!important`,
  },
  attributesContainer: {
    display: "grid",
    gridGap: variables.half_gap,
    padding: variables.normal_gap,
    background: variables.colors.background.creme,
    borderRadius: "5px",
    justifySelf: "start",
    justifyItems: "start",
  },
  bottomAttributes: {
    display: "grid",
    gridGap: variables.half_gap,
    gridTemplateColumns: "max-content 1fr",
    justifyItems: "center",
  },
  vehImg: {
    width: "100%",
    borderRadius: "5px",
    boxShadow: variables.shadows.normalShadow,
  },
  priceContainer: {
    display: "grid",
    gridAutoRows: "max-content",
    gridGap: variables.half_gap,
    alignSelf: "center",
  },
});
export const Transfer = ({ transfer_type, transfer, isSelected, onSelect }) => {
  const classes = transferStyles();

  function getStart(legType) {
    const dt = transfer?.[legType]?.start;
    if (!dt) return null;
    return DateTime.fromISO(dt, { setZone: true }).toLocaleString(
      DateTime.DATETIME_MED_WITH_WEEKDAY
    );
    // return formatDateTime(dt);
  }

  function getEnd(legType) {
    const dt = transfer?.[legType]?.start;
    if (!dt) return null;
    // moment(dt).add(transfer?.[legType]?.duration ?? 0, "ms");
    const endT = DateTime.fromISO(dt, { setZone: true })
      .plus({
        milliseconds: transfer?.[legType]?.duration,
      })
      .toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY);
    return endT;
  }

  function getVehicleImg() {
    return _.get(
      [
        ...new Set(
          [
            transfer?.inbound?.vehicle?.images?.[0]?.url,
            transfer?.outbound?.vehicle?.images?.[0]?.url,
          ].filter((i) => i !== null)
        ),
      ],
      0
    );
  }

  function getVehicleName() {
    return [
      ...new Set(
        [
          transfer?.inbound?.vehicle?.name,
          transfer?.outbound?.vehicle?.name,
        ].filter((i) => i !== null)
      ),
    ];
  }

  function getBaggage() {
    const key = "vehicle.baggage_allowance";
    const smallBg = Math.max(
      transfer?.inbound?.[key]?.small_baggage ?? 0,
      transfer?.outbound?.[key]?.small_baggage ?? 0
    );

    const bigBg = Math.max(
      transfer?.inbound?.[key]?.big_baggage ?? 0,
      transfer?.outbound?.[key]?.big_baggage ?? 0
    );

    return `${smallBg}, ${bigBg}`;
  }

  function getMinMaxPax() {
    const minPax = Math.min(
      ...[
        transfer?.inbound?.vehicle?.min_pax ?? 1000,
        transfer?.outbound?.vehicle?.min_pax ?? 1000,
      ]
    );

    const maxPax = Math.max(
      ...[
        transfer?.inbound?.vehicle?.max_pax ?? 0,
        transfer?.outbound?.vehicle?.max_pax ?? 0,
      ]
    );

    return `${minPax}, ${maxPax}`;
  }

  function getOperator() {
    return [
      ...new Set(
        [
          transfer?.inbound?.provider?.name ?? "",
          transfer?.outbound?.provider?.name ?? "",
        ].filter((o) => o !== "")
      ),
    ].join(", ");
  }

  function renderTransferData(legType) {
    if (_.isEmpty(transfer[legType])) {
      return null;
    }

    return (
      <React.Fragment>
        <span>
          <strong className={classes.trfAttribute}>Origin: </strong>
          {transfer.origin.name}
          {" - "}
          <strong className={classes.trfAttribute}>Destination: </strong>
          {transfer.destination.name}
        </span>
        <span>
          <strong className={classes.trfAttribute}>Departure: </strong>
          {getStart(legType)}
          {" - "}
          <strong className={classes.trfAttribute}>Arrival: </strong>
          {getEnd(legType)}
        </span>
        <span>
          <strong className={classes.trfAttribute}>Distance:</strong>{" "}
          {transfer?.[legType]?.distance ?? 0}
          {" - "}
          <strong className={classes.trfAttribute}>Duration:</strong>{" "}
          {humanizeDuration(transfer?.[legType]?.duration ?? 0)}
        </span>
      </React.Fragment>
    );
  }

  return (
    <div className={classes.Transfer}>
      <img className={classes.vehImg} alt="" src={getVehicleImg()} />
      <div className={classes.mainContent}>
        {getVehicleName(transfer).map((tr, jidx) => (
          <span key={jidx}>
            <strong>{_.startCase(tr)}</strong>
          </span>
        ))}
        {renderTransferData("inbound")}
        {renderTransferData("outbound")}
        <div className={classes.attributesContainer}>
          <div className={classes.trfAttributes}>
            <span>
              <SuIcon icon="icon-Baggage" className={classes.icon} />
              <strong> Small/Big Baggage: </strong>
              {getBaggage(transfer)}
            </span>
            <span>
              <SuIcon icon="icon-Personicon" className={classes.icon} />
              <strong> Min/Max Pax: </strong>
              {getMinMaxPax(transfer)}
            </span>
            <span>
              <SuIcon icon="icon-Vendoricon" className={classes.icon} />
              <strong> Supplier: </strong>
              {_.startCase(_.get(transfer, "supplier"))}
            </span>
          </div>
          <div className={classes.bottomAttributes}>
            <span>
              <SuIcon icon="icon-People1" className={classes.icon} />
              <strong> Vehicle: </strong>
              {transfer?.[transfer_type]?.vehicle?.brand ||
                transfer?.[transfer_type]?.vehicle?.vehicle_type ||
                "N/A"}
            </span>
            <span>
              <SuIcon icon="icon-Vendoricon" className={classes.icon} />
              <strong> Operator: </strong>
              {getOperator(transfer)}
            </span>
          </div>
        </div>
        <span>
          <strong> Cancellation Policy: </strong>
          <CancellationPolicyDetails
            cxl_policies={transfer.cancellation_policies}
          />
        </span>
      </div>
      <div className={classes.priceContainer}>
        <PriceDetails
          serviceType="TRF"
          price={transfer.price.value}
          currency={transfer.price.currency}
          searching={false}
        />
        {typeof onSelect === "function" && (
          <Button
            size="xs"
            color={isSelected ? "green" : "blue"}
            onClick={onSelect}>
            {isSelected ? "Selected" : "Select"}
          </Button>
        )}
      </div>
    </div>
  );
};
Transfer.propTypes = {
  destOrder: PropTypes.number,
  transfer_type: PropTypes.oneOf(["inbound_outbound", "inbound", "outbound"]),
  transfer: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func,
};

const overviewAllTransfersStyles = createUseStyles({
  OverviewAllTransfers: {},
  header: {
    "background": variables.colors.background.dark,
    "margin": 0,
    "padding": variables.normal_gap,
    "display": "grid",
    "gridTemplateColumns": "1fr max-content",
    "alignItems": "center",
    "&:before": { content: "none" },
    "& .rs-drawer-header-close": { display: "none" },
  },
  title: { color: "white!important" },
  body: {
    display: "grid",
    height: "90%!important",
    gridAutoRows: "max-content",
  },
  transfersList: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} * 2)`,
    padding: variables.normal_gap,
  },
});
const OverviewAllTransfers = ({ destOrder, transfer_type, onClose }) => {
  const classes = overviewAllTransfersStyles();
  const [filters, setFilters] = useState({
    vehicle_type: "",
    provider: "",
    supplier: "",
    max_price: 1000000000,
  });
  const [filteredTransfers, setFilteredTransfers] = useState([]);

  const selectedTransfer = useSelector((state) =>
    (state.tripPlannerTransferData?.[destOrder]?.[transfer_type] ?? []).find(
      (trf) => trf.selected
    )
  );
  const session_id = selectedTransfer?.session_id;

  const dispatch = useDispatch();
  const onSelect = useCallback(
    (transfer, destOrder, transfer_type) => {
      // Make sure we have a session_id to the selected result
      if (!transfer.session_id) {
        transfer.session_id = session_id;
      }
      dispatch(saveTransfer({ trfType: transfer_type, destOrder, transfer }));
    },
    [dispatch]
  );

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ["poll_transfer_session", session_id],
    queryFn: () => trfGetResults(session_id),
    onError: () => {
      toast.error(
        "We couldn't fetch the available transfer options. Please try again.",
        { autoClose: 5000 }
      );
      onClose();
    },
    refetchOnWindowFocus: false,
  });
  const transfers = data?.data?.results ?? [];
  const loading = isLoading || isFetching;

  // Filter transfers
  useEffect(() => {
    if (!transfers.length) return;

    var tmp = _.cloneDeep(transfers);
    if (filters.supplier) {
      tmp = tmp.filter((trf) => trf.supplier === filters.supplier);
    }
    if (filters.provider) {
      tmp = tmp.filter((trf) => {
        if (trf?.inbound?.provider?.name === filters.provider) {
          return true;
        } else if (trf?.outbound?.provider?.name === filters.provider) {
          return true;
        }
        return false;
      });
    }
    if (filters.vehicle_type) {
      tmp = tmp.filter((trf) => {
        const vehType = filters.vehicle_type;
        if (trf?.inbound?.vehicle?.brand === vehType) {
          return true;
        } else if (trf?.outbound?.vehicle?.brand === vehType) {
          return true;
        } else if (trf?.inbound?.vehicle?.vehicle_type === vehType) {
          return true;
        } else if (trf?.outbound?.vehicle?.vehicle_type === vehType) {
          return true;
        }
        return false;
      });
    }

    tmp = tmp.filter((trf) => trf.price.value <= filters.max_price);
    setFilteredTransfers(tmp.sort((a, b) => a.price.value - b.price.value));
  }, [transfers, filters]);

  return (
    <Drawer size="lg" placement="right" show={true} onHide={onClose}>
      <Drawer.Header className={classes.header}>
        <Drawer.Title className={classes.title}>Transfer List</Drawer.Title>
        <IconButton
          icon={<Icon icon="close" />}
          size="sm"
          placement="right"
          onClick={onClose}>
          Close
        </IconButton>
      </Drawer.Header>
      <Drawer.Body className={classes.body}>
        <OverviewTrfFilters
          transfers={transfers}
          onApplyFilters={(filters) => setFilters(filters)}
        />
        <div className={classes.transfersList}>
          {loading
            ? new Array(8).fill(1).map((___, idx) => (
                <Panel key={idx}>
                  <Placeholder.Paragraph rows={3} active={true} />
                </Panel>
              ))
            : filteredTransfers.map((trf, idx) => (
                <Transfer
                  key={idx}
                  idx={idx}
                  destOrder={destOrder}
                  transfer_type={transfer_type}
                  transfer={trf}
                  isSelected={trf.booking_id === selectedTransfer?.booking_id}
                  onSelect={() => onSelect(trf, destOrder, transfer_type)}
                />
              ))}
        </div>
      </Drawer.Body>
    </Drawer>
  );
};
OverviewAllTransfers.propTypes = {
  destOrder: PropTypes.number.isRequired,
  transfer_type: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default OverviewAllTransfers;
