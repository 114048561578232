import service, { getAuthHeaders } from "@src/api/request";
import axios from "axios";
import { SISI2_API_URL } from "..";

export const createQuotationRequest = ({ payload }) => {
  return service({
    url: `/bid/quotation-request/`,
    method: "post",
    data: payload,
  });
};

export const fetchQuotationRequests = (payload) => {
  return service({
    url: `/bid/quotation-request/`,
    method: "get",
    params: payload,
  });
};

export const fetchQuotationRequest = ({ id, is_admin = false }) => {
  return service({
    url: `/bid/quotation-request/${id}/`,
    method: "get",
    params: { is_admin },
  });
};

export const deleteQuotationRequest = ({ id, is_admin = false }) => {
  return service({
    url: `/bid/quotation-request/${id}/`,
    method: "delete",
    params: { is_admin },
  });
};

export const updateQuotationRequest = ({ id, payload, is_admin = false }) => {
  return service({
    url: `/bid/quotation-request/${id}/`,
    method: "patch",
    data: { ...payload, is_admin },
  });
};

export function initQuotationGenSession({
  lang = "en",
  source_entity,
  max_tokens = 4000,
  message,
  adults,
  children,
  children_ages,
  // nights,
  rooms,
  required_services = [],
  trip_start_date,
}) {
  return axios.post(`${SISI2_API_URL}/primeradiant/quotation/`, {
    lang,
    source_entity,
    max_tokens,
    message,
    adults,
    children,
    children_ages,
    // nights,
    rooms,
    required_services,
    trip_start_date,
  });
}

export function pollQuotationGenSession(session_id) {
  return axios.get(`${SISI2_API_URL}/primeradiant/quotation/${session_id}`);
}

export function createTripFromQuotation({ id, reference }) {
  const headers = getAuthHeaders();
  return axios.post(
    `${SISI2_API_URL}/crs/quotation/trip-from-quotation`,
    { id, reference },
    { headers }
  );
}
