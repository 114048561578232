import {
  // FLIGHTS_FETCH_DATA,
  // FLIGHTS_RESERVATION_BEGIN_FETCH_DATA,
  // FLIGHTS_RESERVATION_END_FETCH_DATA,
  // FLIGHTS_RESERVATION_INITIALIZE_LIST_FILTER_FORM,
  // FLIGHTS_RESERVATION_UPDATE_LIST_FILTER_FORM,
  // FLIGHTS_RESERVATION_APPLY_LIST_FILTER,
  // FLIGHTS_RESERVATION_UPDATE_LIST_COLUMNS_FORM,
  // FLIGHTS_RESERVATION_APPLY_LIST_COLUMNS,
  // FLIGHTS_RESERVATION_INITIALIZE_LIST_COLUMNS_FORM,
  // FLIGHTS_RESERVATION_FETCH_OPTIONS,
  // FLIGHT_RESERVATION_SAVE_DATA,
  // FLIGHT_RESERVATION_FETCH_NAV_STATUS,
  // FLIGHT_RESERVATION_RESET_LIST_FILTER_FORM,
  // FLIGHTS_RESERVATION_FLIGHT_INIT,
  //========================DASHBOARD===============================
  FLIGHT_RESERVATION_VIEW_TYPE,
  FLIGHT_RESERVATION_FETCH_GRAPH_DATA,
  FLIGHT_RESERVATION_BEGIN_FETCH_GRAPH_DATA,
  FLIGHT_RESERVATION_END_FETCH_GRAPH_DATA,
  FLIGHT_RESERVATION_INIT_GRAPH_TYPE,
} from "@src/actions/Operation/Flights/types";

// import {
//   initialFlightColumnsSetUp,
//   initialFlightFilterForm,
// } from "@src/forms/Operation/Flight/flightForms";

// import { formProcessor, entitiesListSelector } from "@src/reducers/tools";

// import update from "immutability-helper";
// import _ from "lodash";

// export const initialFlightsList = {
//   count: 0,
//   next: null,
//   previous: null,
//   results: [],
//   page: 1,
//   limit: 20,
//   displayLength: 100,
// };

// export const initialFlightsFetchStatus = "IDLE";
// export const flightsFetchStatus = (
//   state = initialFlightsFetchStatus,
//   action
// ) => {
//   switch (action.type) {
//     case FLIGHTS_RESERVATION_BEGIN_FETCH_DATA:
//       return "FETCHING";
//     case FLIGHTS_RESERVATION_END_FETCH_DATA:
//       return "FETCHED";
//     default:
//       return state;
//   }
// };

// export const flightsList = (state = initialFlightsList, action) => {
//   switch (action.type) {
//     case FLIGHTS_FETCH_DATA:
//       return action.data;
//     case FLIGHT_RESERVATION_RESET_LIST_FILTER_FORM:
//       return { ...state, page: 1 };
//     default:
//       return state;
//   }
// };

// // filter
// export const flightsListFilter = (state = initialFlightFilterForm, action) => {
//   switch (action.type) {
//     case FLIGHTS_RESERVATION_APPLY_LIST_FILTER:
//       return action.data;
//     case FLIGHT_RESERVATION_RESET_LIST_FILTER_FORM:
//       return initialFlightFilterForm;
//     default:
//       return state;
//   }
// };

// export const flightsListFilterForm = (
//   state = initialFlightFilterForm,
//   action
// ) => {
//   switch (action.type) {
//     case FLIGHTS_RESERVATION_INITIALIZE_LIST_FILTER_FORM:
//       return action.data;
//     case FLIGHT_RESERVATION_RESET_LIST_FILTER_FORM:
//       return initialFlightFilterForm;
//     case FLIGHTS_RESERVATION_UPDATE_LIST_FILTER_FORM:
//       return formProcessor(
//         state,
//         action.data.type,
//         action.data.name,
//         action.data.value
//       );
//     default:
//       return state;
//   }
// };

// // columns
// export const flightsListColumns = (
//   state = entitiesListSelector(initialFlightColumnsSetUp),
//   action
// ) => {
//   switch (action.type) {
//     case FLIGHTS_RESERVATION_APPLY_LIST_COLUMNS:
//       return entitiesListSelector(action.data);
//     default:
//       return state;
//   }
// };

// export const flightsListColumnsForm = (
//   state = initialFlightColumnsSetUp,
//   action
// ) => {
//   switch (action.type) {
//     case FLIGHTS_RESERVATION_INITIALIZE_LIST_COLUMNS_FORM: {
//       var newState = {};
//       const tmp = _.cloneDeep(state);
//       for (const key in tmp) {
//         // clear form's value
//         tmp[key] = update(tmp[key], { value: { $set: [] } });
//         newState[key] = update(tmp[key], { value: { $set: [] } });
//         // add value by action data
//         action.data.forEach((item) => {
//           if (_.find(tmp[key].choices, { key: item.key })) {
//             newState[key] = update(newState[key], {
//               value: { $push: [item.key] },
//             });
//           }
//         });
//       }
//       return newState;
//     }
//     case FLIGHTS_RESERVATION_UPDATE_LIST_COLUMNS_FORM:
//       return action.data;
//     default:
//       return state;
//   }
// };

// export const flightsReservationOptions = (state = {}, action) => {
//   switch (action.type) {
//     case FLIGHTS_RESERVATION_FETCH_OPTIONS:
//       return action.data;
//     default:
//       return state;
//   }
// };

// export const initialFlightsOptionsFetchStatus = "IDLE";
// export const FlightsOptionsFetchStatus = (
//   state = initialFlightsOptionsFetchStatus,
//   action
// ) => {
//   switch (action.type) {
//     case FLIGHTS_RESERVATION_FETCH_OPTIONS:
//       return "FETCHED";
//     default:
//       return state;
//   }
// };

// ======================= DETAIL VIEW REDUCERS ======================
// export const initialFlightNavStatus = "reservation";
// export const flightNavStatus = (state = initialFlightNavStatus, action) => {
//   switch (action.type) {
//     case FLIGHTS_RESERVATION_FLIGHT_INIT:
//       return "reservation";
//     case FLIGHT_RESERVATION_FETCH_NAV_STATUS:
//       return action.data;
//     default:
//       return state;
//   }
// };

// export const flightReservationInfo = (state = {}, action) => {
//   switch (action.type) {
//     case FLIGHTS_RESERVATION_FLIGHT_INIT:
//       return {};
//     case FLIGHT_RESERVATION_SAVE_DATA:
//       return action.data;
//     default:
//       return state;
//   }
// };

//====================================DASHBOARD===============================
export const initData = {
  type: "current",
  name: "SR",
};
export const flightViewType = (state = initData, action) => {
  switch (action.type) {
    case FLIGHT_RESERVATION_INIT_GRAPH_TYPE:
      return initData;
    case FLIGHT_RESERVATION_VIEW_TYPE:
      return action.data;
    default:
      return state;
  }
};

export const flightGraphData = (state = {}, action) => {
  switch (action.type) {
    case FLIGHT_RESERVATION_FETCH_GRAPH_DATA:
      return action.data;
    default:
      return state;
  }
};

export const initialFlightGraphDataStatus = "IDLE";
export const flightGraphDataStatus = (
  state = initialFlightGraphDataStatus,
  action
) => {
  switch (action.type) {
    case FLIGHT_RESERVATION_BEGIN_FETCH_GRAPH_DATA:
      return "FETCHING";
    case FLIGHT_RESERVATION_END_FETCH_GRAPH_DATA:
      return "FETCHED";
    default:
      return state;
  }
};
