import { createSelector } from "reselect";

export function getSrvsTypeForBooking(state) {
  return [
    ...new Set(state.tripPlannerRegularBookSrvs.map((srv) => srv.srv_type)),
  ];
}

export const getSrvsTypeForBookingSelector = createSelector(
  [getSrvsTypeForBooking],
  (srvsType) => srvsType
);

export function getSrvsTypeForOptionBooking(state) {
  return [
    ...new Set(state.tripPlannerOptionBookSrvs.map((srv) => srv.srv_type)),
  ];
}

export const getSrvsTypeForOptionBookingSelector = createSelector(
  [getSrvsTypeForOptionBooking],
  (srvsType) => srvsType
);

export const getSrvsTypeForAnyBookingSelector = createSelector(
  [getSrvsTypeForBooking, getSrvsTypeForOptionBooking],
  (regularSrvs, optionSrvs) => [...new Set([...regularSrvs, ...optionSrvs])]
);

export function getSrvsUidForBooking(state, props = {}) {
  const { srv_type } = props;
  if (!srv_type) {
    return state.tripPlannerRegularBookSrvs;
  }

  return state.tripPlannerRegularBookSrvs.filter(
    (srv) => srv.srv_type === srv_type
  );
}

export function getSrvsUidForOptionBooking(state, props = {}) {
  const { srv_type } = props;
  if (!srv_type) {
    return state.tripPlannerOptionBookSrvs;
  }

  return state.tripPlannerOptionBookSrvs.filter(
    (srv) => srv.srv_type === srv_type
  );
}

export function getSrvsForBookingNo(state, props) {
  return getSrvsUidForBooking(state, props).length;
}

export function getSrvsForOptionBookingNo(state, props) {
  return getSrvsUidForOptionBooking(state, props).length;
}
