import _ from "lodash";
import update from "immutability-helper";
import {
  QUOTATION_REQUEST_INIT,
  QUOTATION_REQUEST_NEXT_STEP,
  QUOTATION_REQUEST_PREV_STEP,
  QUOTATION_REQUEST_BASIC_INFO_CHANGE,
  QUOTATION_REQUEST_REQUIRED_SERVICES_CHANGE,
  QUOTATION_REQUEST_ADD_DESTINATION,
  QUOTATION_REQUEST_DESTINATION_CHANGE,
  QUOTATION_REQUEST_DESTINATION_ORDER_CHANGE,
  QUOTATION_REQUEST_DESTINATION_REMOVE,
  QUOTATION_REQUEST_SRV_REQUIREMENTS_CHANGE,
  QUOTATION_REQUEST_GENERIC_CHANGE,
  QUOTATION_REQUEST_LEGS_CHANGE,
  QUOTATION_REQUEST_LEG_REMOVE_SERVICE,
  QUOTATION_REQUEST_METADATA_LOAD,
  QUOTATION_REQUEST_LOAD,
  QUOTATION_REQUEST_SET_MODE,
} from "@src/actions/Project/QuotationRequest/types";
import { DateTime } from "luxon";

const destinationDtCalculator = (destinations) => {
  if (!destinations.length) {
    return destinations;
  }

  if (!destinations[0].date_from) {
    return destinations;
  }

  return destinations.map((d, idx) => {
    d["date_to"] = DateTime.fromISO(d.date_from)
      .plus({ days: d.stay })
      .toISODate();

    if (idx > 0) {
      d["date_from"] = destinations[idx - 1].date_to;
    }
    return d;
  });
};

function getDefaultFlPref() {
  return {
    service_type: "FL",
    notes: "",
    preferences: {
      stops: ["DI", "MF"],
      class: ["Economy"],
      arrival: "ALL",
      flight_type: ["GDS", "LCC"],
    },
  };
}

function getDefaultBusPref() {
  return {
    service_type: "BUS",
    notes: "",
    preferences: {
      seats: ["14"],
      vehicles: "1",
      facilities: ["WIFI"],
    },
  };
}

function getDefaultFerryPref() {
  return {
    service_type: "FER",
    notes: "",
    preferences: {
      seat_class: ["ECONOMY"],
      cabin: [],
      vessel_type: ["regular"],
    },
  };
}

function getDefaultTrainPref() {
  return {
    service_type: "TRA",
    notes: "",
    preferences: {
      seat_class: ["ECONOMY"],
      cabin: [],
      train_type: ["regular"],
    },
  };
}

function getDefaultAccPref() {
  return {
    service_type: "ACC",
    notes: "",
    preferences: {
      hotel: {},
      rating: [3, 4, 5],
      board: ["BB"],
      room_type: ["STD"],
      bedding: ["TWI"],
    },
  };
}

function getDefaultTrfPref() {
  return {
    service_type: "TRF",
    notes: "",
    preferences: { transfer_type: ["PV"] },
  };
}

function getDefaultMIPref() {
  return {
    service_type: "MI",
    notes: "",
    preferences: {
      poi: {},
      activity_types: [],
      number_of_activities: "MED",
      meal_types: [],
    },
  };
}

function getDefaultCoordinatorPref() {
  return {
    service_type: "COO",
    notes: "",
    preferences: {
      languages: [],
      skills: [],
      inclusions: [],
      exclusions: [],
    },
  };
}

export function getDefaultPrefFnMapping(srv_type) {
  switch (srv_type) {
    case "ACC":
      return getDefaultAccPref;
    case "FL":
      return getDefaultFlPref;
    case "BUS":
      return getDefaultBusPref;
    case "FER":
      return getDefaultFerryPref;
    case "TRA":
      return getDefaultTrainPref;
    case "TRF":
      return getDefaultTrfPref;
    case "MI":
      return getDefaultMIPref;
    case "COO":
      return getDefaultCoordinatorPref;
    default:
      return null;
  }
}

const destinationServiceInitiator = ({
  coordinators = 0,
  required_services,
  destination,
}) => {
  const services = [];
  if (destination.dest_type === "NON") {
    return [];
  }

  if (!["origin", "return"].includes(destination.point_type)) {
    if (destination.dest_type !== "LAY") {
      if (required_services.includes("ACC")) {
        services.push(getDefaultAccPref());
      }

      if (required_services.includes("TRF")) {
        services.push(getDefaultTrfPref());
      }

      if (required_services.includes("MI")) {
        services.push(getDefaultMIPref());
      }

      if (!isNaN(coordinators) && parseInt(coordinators, 10) > 0) {
        new Array(parseInt(coordinators, 10)).fill(1).forEach((c) => {
          services.push(getDefaultCoordinatorPref());
        });
      }
    }
  }

  return services;
};

const legsInitiator = ({ destinations }) => {
  const legs = {};

  destinations.forEach((dest, idx) => {
    if (idx + 1 < destinations.length) {
      const target_dest = destinations[idx + 1];
      const code = `${dest.order}___${target_dest.order}`;

      legs[code] = {
        origin_order: dest.order,
        destination_order: target_dest.order,
        origin_code: dest.code,
        destination_code: target_dest.code,
        leg_type: dest.point_type === "destination" ? "inbetween" : "extremal",
        link: _.get(
          Object.values(legs).find((l) => l.origin_code === target_dest.code),
          "origin_code"
        ),
        departure: dest.date_to,
        arrival: dest.date_to,
        service_preferences: getDefaultFlPref(),
      };
    }
  });

  return legs;
};

export const excIncRuleTemplate = { description_en: "" };
export const destTypeMapping = {
  NOR: "Normal Destination",
  LAY: "Layover Point",
  NON: "Non Serviced Destination",
};
const destinationTemplate = {
  query: "",
  name_en: "",
  name_cn: "",
  geodata: {},
  images: [],
  id: null,
  order: 0,
  type: "",
  stay: 0,
  date_from: "",
  date_to: "",
  point_type: "destination",
  dest_type: "NOR",
};
const initialQuotationRequest = {
  step: 1,
  basic_info: {
    title: "",
    adults: 2,
    children: 0,
    rooms: 1,
    coordinators: 0,
    budget: 1000,
  },
  description: { en: "", cn: "" },
  due_date: null,
  required_services: [],
  destinations: [],
  service_requirements: {},
  legs: {},
  status: "DR",
  internal_notes: "",
  inclusions: [{ ...excIncRuleTemplate }],
  exclusions: [{ ...excIncRuleTemplate }],
  difficulty_lvl: null,
};
export const QuotationRequest = (state = initialQuotationRequest, action) => {
  switch (action.type) {
    case QUOTATION_REQUEST_INIT:
      return initialQuotationRequest;
    case QUOTATION_REQUEST_LOAD: {
      const { data } = action;
      let newState = _.cloneDeep(state);
      newState = { ...newState, ...data };
      if (data.is_raw) {
        newState.step = 0;
      }
      return newState;
    }
    case QUOTATION_REQUEST_NEXT_STEP: {
      if (state.step === 3) return state;

      var newState = { ...state, step: state.step + 1 };
      // Next step is 2
      if (state.step + 1 === 2) {
        const service_requirements = {};
        _.cloneDeep(state.destinations).forEach((dest) => {
          const services = destinationServiceInitiator({
            coordinators: state.basic_info.coordinators,
            required_services: state.required_services,
            destination: dest,
          });
          service_requirements[dest.order.toString()] = services;
        });

        const legs = legsInitiator({ destinations: state.destinations });

        const currentLegsEnc = _.flatten(Object.values(state.legs))
          .map((l) => `${l.origin_code}___${l.destination_code}`)
          .join("---");
        const newLegsEnc = _.flatten(Object.values(legs))
          .map((l) => `${l.origin_code}___${l.destination_code}`)
          .join("---");

        // Only update if destinations have changed
        if (newLegsEnc !== currentLegsEnc) {
          newState = { ...newState, service_requirements, legs };
        }
      }

      return newState;
    }
    case QUOTATION_REQUEST_PREV_STEP: {
      if (state === 1) {
        return state;
      }

      return { ...state, step: state.step - 1 };
    }
    case QUOTATION_REQUEST_BASIC_INFO_CHANGE: {
      const { basic_info } = action;
      return { ...state, basic_info };
    }
    case QUOTATION_REQUEST_REQUIRED_SERVICES_CHANGE: {
      const { required_services } = action;
      var dests = _.cloneDeep(state.destinations);
      if (!required_services.includes("TRP")) {
        dests.forEach((d) => (d.point_type = "destination"));
      }

      return {
        ...state,
        required_services: [...new Set(required_services)],
        destinations: dests,
      };
    }
    case QUOTATION_REQUEST_ADD_DESTINATION: {
      const { order } = action;

      var newDests = _.cloneDeep(state.destinations).map((d) => {
        if (d.order >= order) {
          return { ...d, order: d.order + 1 };
        } else {
          return d;
        }
      });
      newDests.push({
        ...destinationTemplate,
        order,
        date_from: DateTime.now().toISODate(),
        date_to: DateTime.now().toISODate(),
      });

      newDests = destinationDtCalculator(newDests);
      return {
        ...state,
        destinations: _.sortBy(newDests, "order"),
      };
    }
    case QUOTATION_REQUEST_DESTINATION_CHANGE: {
      const { order, destData } = action;
      const newState = update(state, {
        destinations: {
          [order - 1]: {
            $set: destData,
          },
        },
      });

      newState.destinations = destinationDtCalculator(newState.destinations);
      return newState;
    }
    case QUOTATION_REQUEST_DESTINATION_ORDER_CHANGE: {
      const { currentOrder, newOrder } = action;
      var tmp = _.cloneDeep(state.destinations);

      const dest = tmp.find((d) => d.order === currentOrder);
      const targetDest = tmp.find((d) => d.order === newOrder);

      const tmpDt = dest.date_from;
      if (dest.point_type === "origin") {
        // Moving dest down (from origin to destination)
        targetDest.point_type = "origin";
        dest.point_type = "destination";

        dest.stay = targetDest.stay;
        targetDest.stay = 0;

        dest.date_from = targetDest.date_from;
        targetDest.date_from = tmpDt;
      } else if (dest.point_type === "return") {
        // Moving dest up (from return to destination)
        targetDest.point_type = "return";
        targetDest.stay = 0;
        dest.point_type = "destination";

        dest.stay = targetDest.stay;
        targetDest.stay = 0;

        dest.date_from = targetDest.date_from;
        targetDest.date_from = tmpDt;
      } else if (targetDest.point_type === "origin") {
        // Moving Dest up (from destination to origin)
        targetDest.point_type = "destination";
        dest.point_type = "origin";

        targetDest.stay = dest.stay;
        dest.stay = 0;

        dest.date_from = targetDest.date_from;
        targetDest.date_from = tmpDt;
      } else if (targetDest.point_type === "return") {
        // Moving Dest down (from destination to return)
        targetDest.point_type = "destination";
        dest.point_type = "return";

        targetDest.stay = dest.stay;
        dest.stay = 0;

        dest.date_from = targetDest.date_from;
        targetDest.date_from = tmpDt;
      }

      tmp = tmp.filter((d) => d.order !== currentOrder);
      tmp.splice(newOrder - 1, 0, dest);
      tmp.forEach((d, idx) => (d.order = idx + 1));
      tmp = destinationDtCalculator(tmp);
      return update(state, { destinations: { $set: tmp } });
    }
    case QUOTATION_REQUEST_DESTINATION_REMOVE: {
      const { order } = action;
      const tmp = _.cloneDeep(state.destinations).filter(
        (d) => d.order !== order
      );
      tmp.forEach((d, idx) => (d.order = idx + 1));
      return update(state, { destinations: { $set: tmp } });
    }
    case QUOTATION_REQUEST_SRV_REQUIREMENTS_CHANGE: {
      const { order, service_requirements } = action;
      return update(state, {
        service_requirements: {
          [order]: {
            $set: service_requirements,
          },
        },
      });
    }
    case QUOTATION_REQUEST_GENERIC_CHANGE: {
      const { key, value } = action;
      return { ...state, [key]: value };
    }
    case QUOTATION_REQUEST_LEGS_CHANGE: {
      const { code, leg } = action;
      return { ...state, legs: { ...state.legs, [code]: leg } };
    }
    case QUOTATION_REQUEST_LEG_REMOVE_SERVICE: {
      const { code } = action;

      return {
        ...state,
        legs: {
          ...state.legs,
          [code]: { ...state.legs[code], service_preferences: null },
        },
      };
    }
    default:
      return state;
  }
};

const initialQuotationRequestMetadata = {
  id: null,
  created: null,
  edited: null,
  creator_metadata: {
    first_name: "",
    last_name: "",
    email: "",
    username: "",
  },
  source_entity: "",
  status_display: "",
  reference: "",
  mode: null,
};
export const QuotationRequestMetadata = (
  state = initialQuotationRequestMetadata,
  action
) => {
  switch (action.type) {
    case QUOTATION_REQUEST_SET_MODE: {
      const { mode } = action;

      return { ...state, mode };
    }
    case QUOTATION_REQUEST_INIT:
      return { ...initialQuotationRequestMetadata, mode: state.mode };
    case QUOTATION_REQUEST_METADATA_LOAD: {
      const { metadata } = action;
      return { ...metadata, mode: state.mode };
    }
    default:
      return state;
  }
};
