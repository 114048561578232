import _ from "lodash";
import PropTypes from "prop-types";

import {
  clearTransfers,
  resetPreferences,
  transferConfirm,
  transferEdit,
  transferPrefChange,
} from "@src/actions/Project/TripPlanner";

import { PrefActions } from "../Components/PreferencesControllersCommon";
import { trfVehicleTypes } from "@src/config/common";

import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect } from "react";
import { Form, Formik } from "formik";
import { NormalSelectField } from "@src/components/forms";

const TransferSetup = ({ onNextStep }) => {
  const { editMode, preferences, currentTripStep } = useSelector((state) => {
    const editMode = state.tripPlannerTrfPrefEditMode;
    const preferences = state.tripPlannerTrfPref;
    const currentTripStep = state.tripPlannerCurrentStep;
    return { editMode, preferences, currentTripStep };
  });

  const dispatch = useDispatch();
  const onEdit = useCallback(() => dispatch(transferEdit()), [dispatch]);
  const onConfirm = useCallback(() => {
    dispatch(clearTransfers());
    dispatch(transferConfirm());
  }, [dispatch]);
  const onChange = useCallback(
    (preferences) => dispatch(transferPrefChange(preferences)),
    [dispatch]
  );
  const onReset = useCallback(
    (type) => dispatch(resetPreferences(type)),
    [dispatch]
  );

  useEffect(() => {
    if (currentTripStep <= 2) {
      onEdit();
    }
  }, [currentTripStep]);

  return (
    <Formik
      initialValues={{ preferences }}
      onSubmit={(values) => {
        const prefs = _.cloneDeep(values.preferences);
        prefs.pickupBeforeDeparture = parseInt(prefs.pickupBeforeDeparture, 10);
        prefs.pickupAfterArrival = parseInt(prefs.pickupAfterArrival, 10);
        onChange(prefs);
        onConfirm();
        if (typeof onNextStep === "function") onNextStep();
      }}>
      {({ submitForm }) => (
        <div className="Card" bordered>
          <div className="Card__header">
            <h5>Transfers</h5>
          </div>
          <div className="Card__body">
            <Form className="Form">
              <div className="ServiceSetupController__preferences__trf-setup__inputs">
                <NormalSelectField
                  name="preferences.transferType"
                  label="Transfer Type"
                  options={[
                    ["ALL", "All Types"],
                    ["PV", "Private Transfer"],
                    ["SH", "Shared Transfer"],
                  ]}
                />
                <NormalSelectField
                  name="preferences.vehicleType"
                  label="Vehicle Type"
                  options={[
                    ["ALL", "All Vehicles"],
                    ...trfVehicleTypes.map((o) => [o.value, o.label]),
                  ]}
                />
                <NormalSelectField
                  name="preferences.pickupBeforeDeparture"
                  label="Pickup Before Departures"
                  options={[
                    [60, "1 Hour"],
                    [120, "2 Hours"],
                    [180, "3 Hours"],
                    [240, "4 Hours"],
                    [300, "5 Hours"],
                  ]}
                />
                <NormalSelectField
                  name="preferences.pickupAfterArrival"
                  label="Pickup After Arrival"
                  options={[
                    [15, "15 Minutes"],
                    [30, "30 Minutes"],
                    [45, "45 Minutes"],
                    [60, "1 Hour"],
                  ]}
                />
              </div>
            </Form>
          </div>
          <div className="Card__actions">
            <PrefActions
              editMode={editMode}
              searching={false}
              onConfirm={submitForm}
              onEdit={onEdit}
              onReset={() => onReset("transfers")}
            />
          </div>
        </div>
      )}
    </Formik>
  );
};
TransferSetup.propTypes = {
  onNextStep: PropTypes.func,
};

export default TransferSetup;
