import {
  TRIPPLANNER_INIT,
  TRIPPLANNER_LOAD_TRIP,
  TRIPPLANNER_SHOW_BOOKINGMESSAGE_MODAL,
  TRIPPLANNER_HIDE_BOOKINGMESSAGE_MODAL,
  TRIPPLANNER_LOADING_BOOKINGMESSAGE_MODAL,
  TRIPPLANNER_IDLE_BOOKINGMESSAGE_MODAL,
  TRIPPLANNER_CHANGE_BOOKING_MESSAGE,
} from "@src/actions/Project/TripPlanner/types";

import update from "immutability-helper";

const tripPlannerBookingMessageModalInitial = { show: false, loading: false };
export const tripPlannerBookingMessageModal = (
  state = tripPlannerBookingMessageModalInitial,
  action
) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return tripPlannerBookingMessageModalInitial;
    case TRIPPLANNER_LOAD_TRIP:
      return tripPlannerBookingMessageModalInitial;
    case TRIPPLANNER_SHOW_BOOKINGMESSAGE_MODAL:
      return update(state, { show: { $set: true } });
    case TRIPPLANNER_HIDE_BOOKINGMESSAGE_MODAL:
      return tripPlannerBookingMessageModalInitial;
    case TRIPPLANNER_LOADING_BOOKINGMESSAGE_MODAL:
      return update(state, { loading: { $set: true } });
    case TRIPPLANNER_IDLE_BOOKINGMESSAGE_MODAL:
      return update(state, { loading: { $set: false } });
    default:
      return state;
  }
};

export const tripPlannerBookingMessage = (state = "", action) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return "";
    case TRIPPLANNER_LOAD_TRIP:
      return action.data.booking_message;
    case TRIPPLANNER_CHANGE_BOOKING_MESSAGE:
      return action.msg;
    default:
      return state;
  }
};
