import PropTypes from "prop-types";
import React from "react";

import { NormalInputField, NormalSelectField } from "@src/components/forms";
import { variables } from "@src/jsssetup";
import _ from "lodash";
import { createUseStyles } from "react-jss";
import { Button } from "rsuite";
import { v4 } from "uuid";
import { AdhocPrice } from "./accommodation/formComponents";

export const cabinTypeTmp = {
  type: "",
  number_of_cabins: 1,
  pax: 1,
  price: 0,
  price_per_person: 0,
};

export function addonSrvPriceCalculator({
  values,
  adults,
  childs,
  original_total_price = 0,
  original_currency = "EUR",
}) {
  const pax = parseInt(adults, 10) + parseInt(childs, 10);
  const unit_price = values["unit_price"];

  var totalCabinPrice = 0;
  const cabins = _.get(values, "cabins", []);

  if (cabins.length) {
    totalCabinPrice = cabins.reduce(
      (a, b) => a + b.price_per_person * b.pax * b.number_of_cabins,
      0
    );
  }

  const pax_price = values["per_person_price"];

  _.get(values, "extras", []).forEach((ext) => {
    ext.price = ext.price_per_person * pax;
  });
  const totalExtrasPrice = _.get(values, "extras", []).reduce(
    (a, b) => a + b.price,
    0
  );

  values["total_price"] =
    pax_price * pax + unit_price + totalExtrasPrice + totalCabinPrice;

  const price_changed = values["total_price"] !== original_total_price;
  const currency_changed = values["currency"] !== original_currency;
  values["original_price_edited"] = price_changed || currency_changed;
}

const cabinStyles = createUseStyles({
  Cabin: {
    padding: variables.normal_gap,
    border: `2px solid ${variables.colors.deepgrey}`,
    borderRadius: "5px",
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateColumns: "repeat(4, 1fr)",
  },
});
export const Cabin = ({ idx, cabinTypeMapping }) => {
  const classes = cabinStyles();

  return (
    <div className={classes.Cabin} key={idx}>
      <NormalSelectField
        name={`cabins.${idx}.type`}
        label="Type"
        options={[[null, "------"], ...Object.entries(cabinTypeMapping)]}
      />
      <NormalInputField
        name={`cabins.${idx}.number_of_cabins`}
        label="Number of Cabins"
        type="number"
      />
      <NormalInputField name={`cabins.${idx}.pax`} label="Pax" type="number" />
      <NormalInputField
        name={`cabins.${idx}.price_per_person`}
        label="Price Per Pax"
        type="number"
      />
    </div>
  );
};
Cabin.propTypes = {
  idx: PropTypes.number.isRequired,
  cabinTypeMapping: PropTypes.object.isRequired,
};

const cabinsStyles = createUseStyles({
  Cabins: { display: "grid", gridGap: `calc(${variables.normal_gap} / 2)` },
  cabinsHeader: {
    display: "inline-grid",
    gridTemplateColumns: "1fr auto auto",
    gridGap: variables.normal_gap,
    padding: `calc(${variables.normal_gap} / 2)`,
    borderBottom: `2px solid ${variables.colors.deepgrey}`,
  },
});
export const Cabins = ({ cabins, cabinTypeMapping, setFieldValue }) => {
  const classes = cabinsStyles();

  return (
    <div className={classes.Cabins}>
      <h5 className={classes.cabinsHeader}>
        <span>Cabins: {cabins.length}</span>
        <Button
          size="xs"
          color="red"
          onClick={() => {
            const cbs = [...cabins];
            cbs.pop();
            setFieldValue("cabins", cbs);
          }}>
          <strong>Remove</strong>
        </Button>
        <Button
          size="xs"
          color="green"
          onClick={() =>
            setFieldValue("cabins", [...cabins, { ...cabinTypeTmp }])
          }>
          <strong>Add</strong>
        </Button>
      </h5>
      {cabins.map((cab, idx) => (
        <Cabin key={idx} idx={idx} cabinTypeMapping={cabinTypeMapping} />
      ))}
    </div>
  );
};
Cabins.propTypes = {
  cabins: PropTypes.array.isRequired,
  cabinTypeMapping: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

const extraFormStyles = createUseStyles({
  extra: {
    padding: variables.normal_gap,
    borderRadius: `calc(${variables.normal_gap} / 2)`,
    border: "2px solid black",
  },
  extraHeader: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr) auto",
    alignItems: "center",
    gridGap: `calc(${variables.normal_gap} / 2)`,
  },
  extraActions: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
  },
  extraAttrs: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
  },
});
export const ExtraForm = ({
  idx,
  extra,
  extras,
  extraTypeMapping,
  setExtras,
}) => {
  const classes = extraFormStyles();

  return (
    <div className={classes.extra}>
      <div className={classes.extraHeader}>
        <NormalSelectField
          name={`extras.${idx}.type`}
          label={`Extra ${idx + 1} Type`}
          options={[[null, "------"], ...Object.entries(extraTypeMapping)]}
        />
        <NormalInputField
          name={`extras.${idx}.price_per_person`}
          label="Price Per Person"
          type="number"
        />
        <AdhocPrice label="Price" price={extras[idx].price} />
        <div className={classes.extraActions}>
          <Button
            appearance="primary"
            size="xs"
            onClick={() => {
              const newExtra = _.cloneDeep(extra);
              newExtra["uid"] = v4();
              const newExtras = _.cloneDeep(extras);
              newExtras.push(newExtra);
              setExtras({ extras: newExtras });
            }}>
            <strong>Copy to new</strong>
          </Button>
          <Button
            color="red"
            size="xs"
            onClick={() => {
              const rooms = _.cloneDeep(rooms);
              setExtras({
                extras: extras.filter((r) => r.uid !== extra.uid),
              });
            }}>
            <strong>Remove</strong>
          </Button>
        </div>
      </div>
      <div className={classes.extraAttrs}>
        <NormalInputField
          label="Description"
          name={`extras.${idx}.description`}
        />
      </div>
    </div>
  );
};
ExtraForm.propTypes = {
  idx: PropTypes.number.isRequired,
  extra: PropTypes.object.isRequired,
  extras: PropTypes.array.isRequired,
  extraTypeMapping: PropTypes.object.isRequired,
  setExtras: PropTypes.func.isRequired,
};

const extrasStyles = createUseStyles({
  extrasHeader: {
    borderBottom: `2px solid black`,
    fontSize: "medium",
    fontWeight: "bold",
    display: "grid",
    gridTemplateColumns: "1fr auto",
    padding: `calc(${variables.normal_gap} / 2)`,
  },
});
export const Extras = ({ extras, extraTypeMapping, setExtras }) => {
  const classes = extrasStyles();

  return (
    <React.Fragment>
      <div className={classes.extrasHeader}>
        <span>Extras</span>
        <Button
          size="xs"
          color="green"
          onClick={() => {
            const newExtras = _.cloneDeep(extras);
            newExtras.push({
              type: [],
              description: "",
              price_per_person: 0,
              price: 0,
              uid: v4(),
            });
            setExtras(newExtras);
          }}>
          <strong>Add Extra</strong>
        </Button>
      </div>
      {extras.map((ext, idx) => (
        <ExtraForm
          key={idx}
          idx={idx}
          extra={ext}
          extras={extras}
          extraTypeMapping={extraTypeMapping}
          setExtras={({ extras }) => setExtras(extras)}
        />
      ))}
    </React.Fragment>
  );
};
Extras.propTypes = {
  extras: PropTypes.array.isRequired,
  extraTypeMapping: PropTypes.object.isRequired,
  setExtras: PropTypes.func.isRequired,
};
