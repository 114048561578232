// ===================== OVERVIEW TRANSPORTATION =====================
export const TRIPPLANNER_VIEW_ALL_TRP = "TRIPPLANNER_VIEW_ALL_TRP";
export const TRIPPLANNER_HIDE_ALL_TRP = "TRIPPLANNER_HIDE_ALL_TRP";

export const TRIPPLANNER_VIEW_ALL_TRP_LOADING =
  "TRIPPLANNER_VIEW_ALL_TRP_LOADING";
export const TRIPPLANNER_VIEW_ALL_TRP_IDLE = "TRIPPLANNER_VIEW_ALL_TRP_IDLE";

export const TRIPPLANNER_SET_ALL_FLIGHTS = "TRIPPLANNER_SET_ALL_FLIGHTS";
export const TRIPPLANNER_RESET_ALL_FLIGHTS = "TRIPPLANNER_RESET_ALL_FLIGHTS";

export const TRIPPLANNER_SELECT_FLIGHT = "TRIPPLANNER_SELECT_FLIGHT";
export const TRIPPLANNER_SELECT_FLIGHT_OPTION =
  "TRIPPLANNER_SELECT_FLIGHT_OPTION";
