import { SISI_API_URL } from "@src/api";
import axios from "axios";
import _ from "lodash";
import React from "react";
import { toast } from "react-toastify";
import { getAuthHeaders } from "../../../../request";

export async function fetchCustomAccReservations({
  page,
  page_size,
  filters = {},
}) {
  const auth_headers = getAuthHeaders();
  try {
    const result = await axios.get(
      `${SISI_API_URL}/reservations/custom-accommodation/`,
      {
        params: { page, page_size, ...filters },
        headers: { ...auth_headers },
      }
    );
    return result.data;
  } catch (error) {
    toast.error(
      <p>
        We failed to fetch Custom Accommodation Reservations. We are sorry for
        the inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function retrieveCustomAccReservation({ reference }) {
  const auth_headers = getAuthHeaders();
  try {
    const result = await axios.get(
      `${SISI_API_URL}/reservations/custom-accommodation/${reference}`,
      {
        params: {},
        headers: { ...auth_headers },
      }
    );
    return result.data;
  } catch (error) {
    toast.error(
      <p>
        We failed to retrieve the Custom Accommodation Reservation. We are sorry
        for the inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}
