import _ from "lodash";
import React from "react";
import axios from "axios";
import { getAuthHeaders } from "../../../request";
import { SISI_API_URL, SISI2_API_URL } from "@src/api";
import { toast } from "react-toastify";

export async function createB2CGuest({ payload }) {
  const auth_headers = getAuthHeaders();
  return await axios.post(
    `${SISI_API_URL}/guest-crm/internal/guest/create_user`,
    payload,
    {
      headers: { ...auth_headers },
    }
  );
}

export async function editB2CGuest({ payload, uuid }) {
  const auth_headers = getAuthHeaders();
  try {
    const result = await axios.patch(
      `${SISI_API_URL}/guest-crm/internal/guest/${uuid}/`,
      payload,
      {
        headers: { ...auth_headers },
      }
    );
    return result;
  } catch (error) {
    toast.error(
      <p>
        Failed to edit guest. We are sorry for the inconvenience.
        <br />
        <p>{_.get(error, "response.data.message")}</p>
        <br />
        <p>{_.get(error, "response.data.error")}</p>
      </p>,
      { duration: 5000 }
    );
    return false;
  }
}

export async function setBlockGuest({
  source_entity,
  website,
  ext_user_id,
  blocked,
}) {
  const auth_headers = getAuthHeaders();

  try {
    const result = await axios.post(
      `${SISI_API_URL}/guest-crm/internal/guest/change_blocked`,
      { source_entity, website, ext_user_id, blocked },
      { headers: { ...auth_headers } }
    );
    return result;
  } catch (error) {
    toast.error(
      <p>
        We failed to block the guest. We are sorry for the inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function fetchB2CGuests({
  source_entity,
  page,
  page_size,
  filters = {},
}) {
  const auth_headers = getAuthHeaders();

  try {
    const result = await axios.get(`${SISI_API_URL}/guest-crm/internal/guest`, {
      params: { source_entity, page, page_size, ...filters },
      headers: { ...auth_headers },
    });
    return result.data;
  } catch (error) {
    toast.error(
      <p>
        We failed to fetch the guest. We are sorry for the inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function changeGuestPassword({
  source_entity,
  ext_user_id,
  website,
  password,
}) {
  const auth_headers = getAuthHeaders();

  try {
    const result = await axios.post(
      `${SISI_API_URL}/guest-crm/internal/guest/change_password/`,
      { source_entity, ext_user_id, website, password },
      {
        headers: auth_headers,
      }
    );
    return result.data;
  } catch (error) {
    toast.error(
      <p>
        We failed to change the password. We are sorry for the inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function retrieveB2CGuest({
  uuid,
  requester_source_entity,
  requester_website,
}) {
  const auth_headers = getAuthHeaders();

  try {
    const result = await axios.get(
      `${SISI_API_URL}/guest-crm/internal/guest/${uuid}/`,
      {
        params: { requester_source_entity, requester_website },
        headers: auth_headers,
      }
    );

    return result.data;
  } catch (error) {
    toast.error(
      <p>
        We failed to retrieve the guest. We are sorry for the inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function cancelSubscription({
  subscription_id,
  requester_source_entity,
  requester_website,
}) {
  const auth_headers = getAuthHeaders();

  try {
    await axios.delete(
      `${SISI_API_URL}/guest-crm/internal/guest/subscription/${subscription_id}/`,
      {
        params: { requester_source_entity, requester_website },
        headers: auth_headers,
      }
    );
    return true;
  } catch (error) {
    toast.error(
      <p>
        We failed to cancel the subscription. We are sorry for the
        inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function retrievePaymentMethod({
  payment_method_id,
  requester_source_entity,
  requester_website,
}) {
  const auth_headers = getAuthHeaders();
  try {
    const result = await axios.get(
      `${SISI_API_URL}/guest-crm/internal/guest/payment-methods/${payment_method_id}/`,
      {
        params: { requester_source_entity, requester_website },
        headers: auth_headers,
      }
    );
    return result.data;
  } catch (error) {
    toast.error(
      <p>
        We failed to retrieve the payment method. We are sorry for the
        inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function updatePaymentMethod({ payment_method_id, payload }) {
  const auth_headers = getAuthHeaders();
  try {
    const result = await axios.patch(
      `${SISI_API_URL}/guest-crm/internal/guest/payment-methods/${payment_method_id}/`,
      { ...payload },
      {
        headers: auth_headers,
      }
    );
    return result.data;
  } catch (error) {
    toast.error(
      <p>
        We failed to update the payment method. We are sorry for the
        inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function changeDefaultPayment({
  requester_source_entity,
  requester_website,
  customer_stripe_id,
  payment_method_id,
}) {
  const auth_headers = getAuthHeaders();

  try {
    const result = await axios.post(
      `${SISI_API_URL}/guest-crm/internal/guest/payment-methods/set-default/`,
      {
        requester_source_entity: requester_source_entity,
        requester_website: requester_website,
        customer_stripe_id: customer_stripe_id,
        payment_method_id: payment_method_id,
      },
      {
        headers: auth_headers,
      }
    );
    return result.data;
  } catch (error) {
    toast.error(
      <p>
        We failed to change the default payment method. We are sorry for the
        inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function deletePaymentMethod({
  payment_method_id,
  requester_website,
  requester_source_entity,
}) {
  const auth_headers = getAuthHeaders();

  try {
    const result = await axios.delete(
      `${SISI_API_URL}/guest-crm/internal/guest/payment-methods/${payment_method_id}/`,
      {
        params: {
          requester_website,
          requester_source_entity,
        },
        headers: auth_headers,
      }
    );
    return result.data;
  } catch (error) {
    toast.error(
      <p>
        We failed to delete the payment method. We are sorry for the
        inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function attachPaymentMethod({
  requester_source_entity,
  requester_website,
  customer_id,
  payment_method_id,
}) {
  const auth_headers = getAuthHeaders();
  try {
    const result = await axios.post(
      `${SISI_API_URL}/guest-crm/internal/guest/payment-methods/`,
      {
        requester_source_entity,
        requester_website,
        customer_id,
        payment_method_id,
      },
      { headers: auth_headers }
    );
    return result;
  } catch (error) {
    toast.error(
      <p>
        We failed to add your payment method. We are sorry for the
        inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function getCharges({ uuid }) {
  const auth_headers = getAuthHeaders();

  try {
    const result = await axios.get(
      `${SISI_API_URL}/guest-crm/internal/guest/manual-charge/${uuid}`,
      {
        headers: auth_headers,
      }
    );
    return result.data;
  } catch (error) {
    toast.error(
      <p>
        We failed to fetch the charges. We are sorry for the inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function createCharge({ uuid, amount, currency, description }) {
  const auth_headers = getAuthHeaders();
  try {
    const result = await axios.post(
      `${SISI_API_URL}/guest-crm/internal/guest/manual-charge/${uuid}`,
      { amount, currency, description },
      { headers: auth_headers }
    );
    return result.data;
  } catch (error) {
    toast.error(
      <p>
        We failed to create a new charge. We are sorry for the inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function createCharge3d({ uuid, amount, currency, description }) {
  const auth_headers = getAuthHeaders();
  try {
    const result = await axios.post(
      `${SISI_API_URL}/guest-crm/internal/guest/manual-charge-3d/${uuid}`,
      { amount, currency, description },
      { headers: auth_headers }
    );
    return result.data;
  } catch (error) {
    toast.error(
      <p>
        We failed to create a new charge. We are sorry for the inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function getSubscriptionProducts({
  requester_source_entity,
  requester_website,
}) {
  const auth_headers = getAuthHeaders();

  try {
    const result = await axios.get(
      `${SISI_API_URL}/guest-crm/internal/subscription-products`,
      {
        params: { requester_source_entity, requester_website },
        headers: auth_headers,
      }
    );
    return result.data;
  } catch (error) {
    toast.error(
      <p>
        We failed to fetch the subscription producs. We are sorry for the
        inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export async function createSubscription({
  ext_user_id,
  items,
  onetime_invoice_items,
  coupon_id,
  payment_method_id,
  requester_source_entity,
  requester_website,
}) {
  const auth_headers = getAuthHeaders();
  try {
    const result = await axios.post(
      `${SISI_API_URL}/guest-crm/internal/guest/subscription`,
      {
        ext_user_id,
        items,
        onetime_invoice_items,
        coupon_id,
        payment_method_id,
        requester_source_entity,
        requester_website,
      },
      {
        headers: auth_headers,
      }
    );
    return result.data;
  } catch (error) {
    toast.error(
      <p>
        We failed to create a new charge. We are sorry for the inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    );
    return null;
  }
}

export const validateCouponCode = async ({
  coupon_code,
  requester_website,
  requester_source_entity,
}) => {
  try {
    const response = await axios.get(
      `${SISI_API_URL}/guest-crm/coupons/validate/${encodeURIComponent(
        coupon_code
      )}`,
      {
        params: { requester_website, requester_source_entity },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
  return null;
};

export async function fetchGuestCRMUserEntries({ params }) {
  const auth_header = getAuthHeaders();
  return axios.get(`${SISI2_API_URL}/paymentengine/guest-crm-user-entry/`, {
    params,
    headers: { ...auth_header },
  });
}

export async function getGuestCRMUserEntry(uid) {
  const auth_header = getAuthHeaders();
  return axios.get(
    `${SISI2_API_URL}/paymentengine/guest-crm-user-entry/${uid}/`,
    {
      headers: { ...auth_header },
    }
  );
}

export async function createGuestCRMUserEntry({ data }) {
  return axios.post(
    `${SISI2_API_URL}/paymentengine/guest-crm-user-entry/`,
    data
  );
}

export async function patchGuestCRMUserEntry({ uid, data }) {
  const auth_header = getAuthHeaders();
  return axios.patch(
    `${SISI2_API_URL}/paymentengine/guest-crm-user-entry/${uid}/`,
    data,
    {
      headers: { ...auth_header },
    }
  );
}

export async function voidGuestCRMUserEntry({ uid }) {
  const auth_header = getAuthHeaders();
  return axios.post(
    `${SISI2_API_URL}/paymentengine/guest-crm-user-entry/${uid}/void`,
    {},
    {
      headers: { ...auth_header },
    }
  );
}
