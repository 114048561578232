import service, { getAuthHeaders } from "@src/api/request";
import axios from "axios";

const sisi_api_url = process.env.REACT_APP_SISI_GATEWAY;

export const fetchPricedAccContract = async ({
  destination_uid,
  ext_ids = [],
  check_in,
  check_out,
  rooms,
  nationality = "US",
}) => {
  const auth_headers = getAuthHeaders();
  try {
    const response = await axios.post(
      `${sisi_api_url}/accommodation-contractor/price-search`,
      {
        destination_uid,
        external_contract_ids: ext_ids,
        check_in,
        check_out,
        rooms,
        nationality,
      },
      {
        headers: { ...auth_headers },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const accommodationAutocomplete = async ({ name }) => {
  try {
    const response = await axios.get(
      `${sisi_api_url}/accommodator/autocomplete`,
      { params: { name } }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const destinationHotels = async ({ destination }) => {
  try {
    const response = await axios.get(
      `${sisi_api_url}/accommodator/destination_hotels`,
      { params: { destination, supplier: "hotelston" } } // TODO: change supplier to is primary
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const hotelGeocoding = async ({ lat, lng, radius, with_metadata }) => {
  try {
    const response = await axios.get(
      `${sisi_api_url}/accommodator/hotel-geocode`,
      { params: { lat, lng, radius, supplier: "hotelston", with_metadata } } // TODO: change supplier to is primary
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const fetchAccommodationMetadata = async ({
  hotel_ids,
  supplier = "hotelston",
}) => {
  try {
    const response = await axios.post(
      `${sisi_api_url}/accommodator/hotels-metadata`,
      { hotel_ids, supplier }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export function fetchAccommodation(params) {
  return service({
    url: `/bid/simplified-accommodation/`,
    method: "get",
    params: params,
  });
}

export const createHotelContract = async (payload) => {
  const auth_headers = getAuthHeaders();

  const response = await axios.post(
    `${sisi_api_url}/hotel-contract/`,
    payload,
    {
      headers: { ...auth_headers },
    }
  );
  return response;
};

export const fetchHotelContracts = async (params) => {
  const auth_headers = getAuthHeaders();

  try {
    const response = await axios.get(`${sisi_api_url}/hotel-contract/`, {
      params,
      headers: { ...auth_headers },
    });
    return response;
  } catch (error) {
    return null;
  }
};

export const retrieveHotelContract = async (id) => {
  const auth_headers = getAuthHeaders();

  const response = await axios.get(`${sisi_api_url}/hotel-contract/${id}`, {
    headers: { ...auth_headers },
  });
  return response;
};

export const patchHotelContract = async (id, payload) => {
  const auth_headers = getAuthHeaders();

  const response = await axios.patch(
    `${sisi_api_url}/hotel-contract/${id}`,
    payload,
    {
      headers: { ...auth_headers },
    }
  );
  return response;
};

export const batchCloneHtlCntrsMeta = async ({ payload }) => {
  const auth_headers = getAuthHeaders();

  try {
    const response = await axios.post(
      `${sisi_api_url}/hotel-contract/clone-hotel-meta/`,
      payload,
      {
        headers: { ...auth_headers },
      }
    );
    return response;
  } catch (error) {
    return null;
  }
};
