import React from "react";
import { createUseStyles } from "react-jss";
import { variables } from "@src/jsssetup";
import { ServiceCollapseToggle } from "./ActionsController";
import {
  overviewCtrlSectionHeaderStyles,
  overviewCtrlSectionStyles,
} from "./ServicesFilter";
import PropTypes from "prop-types";
import LeanAuthHoc from "@src/components/authorization/LeanAuthHoc";

export var DayByDayContentBtn = ({ onClick }) => {
  return (
    <button
      className="Button span2-on-desktop-only"
      type="button"
      data-full-width="true"
      data-size="xs"
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}>
      Day By Day Content
    </button>
  );
};
DayByDayContentBtn.propTypes = {
  onClick: PropTypes.func.isRequired,
};
DayByDayContentBtn = LeanAuthHoc(DayByDayContentBtn, {
  entity_groups: ["ai_preview"],
});

const prebookActionControllerStyles = createUseStyles({
  PrebookActionController: overviewCtrlSectionStyles(),
  header: overviewCtrlSectionHeaderStyles(),
  actions: {
    display: "grid",
    gridGap: variables.normal_gap,
    paddingTop: variables.normal_gap,
    fontWeight: "600",
  },
});
const PrebookActionController = () => {
  const classes = prebookActionControllerStyles();

  return (
    <div className={classes.PrebookActionController}>
      <div className={classes.header}>ITINERARY</div>
      <div className={classes.actions}>
        <ServiceCollapseToggle />
      </div>
    </div>
  );
};

export default PrebookActionController;
