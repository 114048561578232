import React from "react";
import { InlineIcon } from "@iconify/react";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";

const newApplyFiltersBtn = createUseStyles({
  cursor: "pointer",
});

export const NewApplyFiltersBtn = ({ onClick }) => {
  const classes = newApplyFiltersBtn();
  const { submitForm } = useFormikContext();
  return (
    <InlineIcon
      icon="ph:magnifying-glass-bold"
      //   circle
      className={classes.NewApplyFiltersBtn}
      onClick={() => {
        onClick();
        submitForm();
      }}
    />
  );
};
NewApplyFiltersBtn.defaultProps = {
  onClick: () => {},
};
NewApplyFiltersBtn.propTypes = {
  onClick: PropTypes.func,
};

const newResetFiltersBtn = createUseStyles({
  cursor: "pointer",
});

export const NewResetFiltersBtn = () => {
  const classes = newResetFiltersBtn();
  const { resetForm, submitForm } = useFormikContext();

  return (
    <InlineIcon
      icon="jam:refresh"
      className={classes.NewApplyFiltersBtn}
      //   circle
      //   appearance="primary"
      onClick={() => {
        resetForm();
        submitForm();
      }}
    />
  );
};
