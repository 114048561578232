import axios from "axios";
import { SISI_API_URL } from "@src/api";

export const fetchContactInfoForReservation = ({ reference, type }) => {
  return axios.get(
    `${SISI_API_URL}/contact-info-for-reservation/${reference}/`,
    {
      params: { type },
    }
  );
};

export async function fetchReservationReceivable({
  market_type,
  product_type,
  product_uid,
  source_entity,
  barclays_transaction_id = "",
  stripe_transaction_id = "",
  viva_order_code = "",
  viva_transaction_id = "",
}) {
  const params = {
    market_type,
    product_type,
    product_uid,
    source_entity,
    barclays_transaction_id,
    stripe_transaction_id,
    viva_order_code,
    viva_transaction_id,
  };
  let vParams = {};
  Object.entries(params)
    .filter((entry) => entry[1])
    .forEach((entry) => (vParams[entry[0]] = entry[1]));

  return await axios.get(
    `${SISI_API_URL}/payments/inbound/reservation-ledger-entry`,
    {
      params: vParams,
    }
  );
}

export async function addReservationReceivable({ payload }) {
  return await axios.post(
    `${SISI_API_URL}/payments/inbound/reservation-ledger-entry`,
    payload
  );
}

export async function issueReservation({ service_type, reference }) {
  return await axios.get(
    `${SISI_API_URL}/crs/issue-reservation/${reference}/`,
    {
      params: { reservation_type: service_type },
    }
  );
}

export * from "./Accommodations";
export * from "./Addons";
export * from "./Emails";
export * from "./MultiServices";
export * from "./Transfer";
export * from "./Flights";
export * from "./Guests/B2C";
