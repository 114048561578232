const validateSegments = (segments) => {
  const msgs = [];

  segments.forEach((s, idx) => {
    if (s.departure == "") {
      msgs.push([`Segment ${idx + 1}`, "Departure datetime is missing."]);
    }

    if (s.arrival == "") {
      msgs.push([`Segment ${idx + 1}`, "Arrival datetime is missing."]);
    }

    if (s.cabin_class == "") {
      msgs.push([`Segment ${idx + 1}`, "Flight class is missing."]);
    }

    if (s.flight_number == "") {
      msgs.push([`Segment ${idx + 1}`, "Flight number is missing."]);
    }
  });

  return msgs;
};

const validateFlight = (flight) => {
  var msgs = [];

  if (flight.origin_airport.iata == "") {
    msgs.push(["Origin Airport", "Please add the origin airport."]);
  }

  if (flight.destination_airport.iata == "") {
    msgs.push(["Destination Airport", "Please add the destination airport."]);
  }

  if (flight.outbound_operating_airline.iata == "") {
    msgs.push(["Airline", "Please set the airline information."]);
  }

  msgs = [...msgs, ...validateSegments(flight.outbound_options[0].segments)];

  return msgs;
};

export default validateFlight;
