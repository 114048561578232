import _ from "lodash";
import { NormalInputField } from "@src/components/forms";
import { variables } from "@src/jsssetup";
import { humanizeDuration } from "@src/tools/date_tools";
import { hoursToMilliseconds, minutesToMilliseconds } from "date-fns";
import { Form, Formik } from "formik";
import { DateTime, Duration } from "luxon";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
// import { basicInfoStylesObj } from "../accommodation/common";
import {
  genericButtonsStyles,
  genericInfoGroupStyles,
  genericRsrvFeebackStyles,
} from "../common/styles";
import { basicInfoStylesObj } from "../accommodation/common";

const itineraryTableStyles = createUseStyles({
  ...genericButtonsStyles,
  ItineraryTable: {
    display: "grid",
    gridGap: variables.normal_gap,
  },
  ItineraryCard: {
    ...basicInfoStylesObj.groupCard,
    paddingBottom: variables.normal_gap,
  },
  body: {
    ...genericRsrvFeebackStyles.cardBody,
    overflow: "visible",
    padding: variables.normal_gap,
  },
  infoGroup2: {
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr)",
    gridGap: variables.normal_gap,
  },
  infoCouple: {
    display: "grid",
    gridTemplateColumns: "repeat(2, max-content)",
    gridGap: variables.normal_gap,
  },
  indexCouple: {
    extend: "infoCouple",
    borderBottom: `solid 1px ${variables.colors.easy.orange}`,
    marginTop: variables.normal_gap,
    marginBottom: variables.normal_gap,
  },
  infoCoupleTitle: genericInfoGroupStyles.infoCoupleTitle,
  infoCoupleValue: genericInfoGroupStyles.infoCoupleValue,
  [`@media ${variables.media.bigscreen}`]: {
    infoGroup2: {
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
    },
  },
  buttonsGroup: {
    display: "grid",
    justifyContent: "end",
  },
});
const ItineraryTable = ({ daily_data, editable, onChange }) => {
  const classes = itineraryTableStyles();
  const [tmpDailyData, setTmpDailyData] = useState([]);

  useEffect(() => {
    if (daily_data.length > 0) {
      setTmpDailyData(daily_data);
    }
  }, [daily_data]);

  function timerFn(time) {
    const dt = DateTime.fromISO(time);
    if (dt.invalid) {
      return null;
    }

    return dt.toLocaleString(DateTime.TIME_24_SIMPLE);
  }

  return tmpDailyData.length === 0 ? null : (
    <Formik
      initialValues={{ daily_data: tmpDailyData }}
      validateOnMount={false}
      validateOnBlur={false}
      validate={(values) => onChange(values.daily_data)}>
      {({ values, setFieldValue }) => (
        <Form className={classes.ItineraryTable}>
          {daily_data.map((day, idx) => {
            const endTime = DateTime.fromISO(day.start_time)
              .plus(Duration.fromObject({ minutes: day.duration }))
              .toISOTime();
            return (
              <div className={classes.ItineraryCard} key={idx}>
                <div className={classes.indexCouple}>
                  <div className={classes.infoCoupleTitle}>#</div>
                  <div className={classes.infoCoupleValue}>Day: {idx + 1}</div>
                </div>
                <div className={classes.infoGroup2}>
                  <React.Fragment>
                    <div className={classes.infoCouple}>
                      <div className={classes.infoCoupleTitle}>Travel Date</div>
                      <div className={classes.infoCoupleValue}>
                        {DateTime.fromISO(day.date).toLocaleString(
                          DateTime.DATE_MED_WITH_WEEKDAY
                        )}
                      </div>
                    </div>
                    <div className={classes.infoCouple}>
                      <div className={classes.infoCoupleTitle}>Destination</div>
                      <div className={classes.infoCoupleValue}>
                        <ul className={classes.destinationList}>
                          {[
                            ...new Set(day.destinations.map((dest) => dest)),
                          ].map((dest, idx) => (
                            <li key={idx}>{dest}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className={classes.infoCouple}>
                      <div className={classes.infoCoupleTitle}>Start Point</div>
                      <div className={classes.infoCoupleValue}>
                        {day.start_point || "N/A"}
                      </div>
                    </div>
                    <div className={classes.infoCouple}>
                      <div className={classes.infoCoupleTitle}>Duration</div>
                      <div className={classes.infoCoupleValue}>
                        {humanizeDuration(
                          hoursToMilliseconds(day.hours) +
                            minutesToMilliseconds(day.minutes)
                        )}
                      </div>
                    </div>
                  </React.Fragment>
                  <React.Fragment>
                    <div className={classes.infoCouple}>
                      <div className={classes.infoCoupleTitle}>Start Time</div>
                      <div className={classes.infoCoupleValue}>
                        {timerFn(day.start_time) || "N/A"}
                      </div>
                    </div>
                    <div className={classes.infoCouple}>
                      <div className={classes.infoCoupleTitle}>End Time</div>
                      <div className={classes.infoCoupleValue}>
                        {timerFn(endTime) || "N/A"}
                      </div>
                    </div>
                    <div className={classes.infoCouple}>
                      <div className={classes.infoCoupleTitle}>Amount</div>
                      <div className={classes.infoCoupleValue}>
                        {`${day.currency || ""} ${day.amount}`}
                      </div>
                    </div>
                    <div className={classes.infoCouple}>
                      <div className={classes.infoCoupleTitle}>
                        Confirmed Amount
                      </div>
                      <div className={classes.infoCoupleValue}>
                        {editable && !values.daily_data[idx].confirmed ? (
                          <NormalInputField
                            name={`daily_data.${idx}.proposed_amount`}
                            type="number"
                          />
                        ) : (
                          <span>
                            {_.get(values, `daily_data.${idx}.proposed_amount`)}
                          </span>
                        )}
                      </div>
                    </div>
                  </React.Fragment>
                </div>
                {editable && (
                  <div className={classes.buttonsGroup}>
                    {values.daily_data[idx].confirmed ? (
                      <button
                        className={classes.cancelButton}
                        onClick={() =>
                          setFieldValue(`daily_data.${idx}.confirmed`, false)
                        }>
                        <strong>Edit</strong>
                      </button>
                    ) : (
                      <button
                        className={classes.confirmButton}
                        onClick={() =>
                          setFieldValue(`daily_data.${idx}.confirmed`, true)
                        }>
                        <strong>Confirm</strong>
                      </button>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </Form>
      )}
    </Formik>
  );
};
ItineraryTable.defaultProps = {
  daily_data: [],
};
ItineraryTable.propTypes = {
  editable: PropTypes.bool.isRequired,
  daily_data: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

const itineraryStyles = createUseStyles({
  // Itinerary: cardStyles.card,
  // header: cardStyles.header,
  // body: cardStyles.body,
  Itinerary: genericRsrvFeebackStyles.card,
  body: genericRsrvFeebackStyles.cardBody,
  header: genericRsrvFeebackStyles.cardHeader,
});
export const Itinerary = ({ daily_data, editable, onChange }) => {
  const classes = itineraryStyles();
  return (
    <div className={classes.Itinerary}>
      <div className={classes.header}>
        <h5>Itinerary</h5>
      </div>
      <div className={classes.body}>
        <ItineraryTable
          daily_data={daily_data}
          editable={editable}
          onChange={onChange}
        />
      </div>
    </div>
  );
};
Itinerary.propTypes = {
  daily_data: PropTypes.array.isRequired,
  editable: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};
