import {
  //==========================FILTER=============================
  // MY_OFFER__SENT_UPDATE_LIST_FILTER_FORM,
  // MY_OFFER__SENT_APPLY_LIST_FILTER,
  MY_OFFER__SENT_INITIALIZE_LIST_FILTER_FORM,
  // MY_OFFER__SENT_RESET_LIST_FILTER_FORM,
  //======================LIST====================================
  MY_OFFER__SENT_FETCH_DATA,
  //========================DASHBOARD===============================
  MY_OFFER__SENT_VIEW_TYPE,
  MY_OFFER__SENT_FETCH_GRAPH_DATA,
  MY_OFFER__SENT_BEGIN_FETCH_GRAPH_DATA,
  MY_OFFER__SENT_END_FETCH_GRAPH_DATA,
  MY_OFFER__SENT_INIT_GRAPH_TYPE,
  //========================CHAT==============================,
  MY_OFFER__SENT_OPEN_CHAT_DRAWER,
  MY_OFFER__SENT_HIDE_CHAT_DRAWER,
  MY_OFFERS_SENT_DATA_DETAIL,
  MY_OFFER__SENT_SHOW_CHAT_DRAWER,
  //===================================RESENT EMAIL===================
  MY_OFFER__SENT_SHOW_RESENT_EMAIL_MODAL,
  MY_OFFER__SENT_HIDE_RESENT_EMAIL_MODAL,
  MY_OFFER__SENT_BEGIN_SEND_EMAIL,
  MY_OFFER__SENT_END_SEND_EMAIL,
  MY_OFFER__SENT__RESENT_EMAIL_MODAL_DATA,
  MY_OFFER__SENT_LIST_LOADING,
  MY_OFFER__SENT_LIST_IDLE,
  //======================change status===================================
  MY_OFFER__SENT_SHOW_STATUS_MODAL,
  MY_OFFER__SENT_HIDE_STATUS_MODAL,
  MY_OFFER__SENT_EDIT_STATUS,
  MY_OFFER__SENT_BEGIN_SUBMIT_STATUS,
  MY_OFFER__SENT_END_SUBMIT_STATUS,
  //========== New Refactored =====================
  MY_OFFER__SENT_LIST_GENERIC_CHANGE,
  MY_OFFER__SENT_LIST_SAVE_RESULTS,
} from "@src/actions/Project/Archive/MyOffers/Sent/types";
import * as yup from "yup";

import update from "immutability-helper";

import { commonList } from "../../../../common";

export const initialMyOffersSentList = {
  count: 0,
  next: null,
  previous: null,
  results: [],
  page: 1,
  limit: 6,
  displayLength: 100,
  loading: false,
};

export const myOffersSentList = (state = initialMyOffersSentList, action) => {
  switch (action.type) {
    case MY_OFFER__SENT_FETCH_DATA:
      return { ...state, ...action.data };
    case MY_OFFER__SENT_LIST_LOADING:
      return { ...state, loading: true };
    case MY_OFFER__SENT_LIST_IDLE:
      return { ...state, loading: false };
    case MY_OFFER__SENT_INITIALIZE_LIST_FILTER_FORM:
      return { ...state, page: 1 };
    default:
      return state;
  }
};

//====================================DASHBOARD===============================
export const initData = {
  date: "current",
  type: "PR",
};
export const myOffersSentViewType = (state = initData, action) => {
  switch (action.type) {
    case MY_OFFER__SENT_INIT_GRAPH_TYPE:
      return initData;
    case MY_OFFER__SENT_VIEW_TYPE:
      return action.data;
    default:
      return state;
  }
};

export const myOffersSentGraphData = (state = {}, action) => {
  switch (action.type) {
    case MY_OFFER__SENT_FETCH_GRAPH_DATA:
      return action.data;
    default:
      return state;
  }
};

export const myOffersSentGraphDataStatus = (state = "IDLE", action) => {
  switch (action.type) {
    case MY_OFFER__SENT_BEGIN_FETCH_GRAPH_DATA:
      return "FETCHING";
    case MY_OFFER__SENT_END_FETCH_GRAPH_DATA:
      return "FETCHED";
    default:
      return state;
  }
};

// =============================== CHAT ==============================
export const initMyOffersSentChatDrawerStatus = { loading: false };
export const myOffersSentChatDrawerStatus = (
  state = initMyOffersSentChatDrawerStatus,
  action
) => {
  switch (action.type) {
    case MY_OFFER__SENT_SHOW_CHAT_DRAWER:
      return update(state, { loading: { $set: true } });
    case MY_OFFER__SENT_HIDE_CHAT_DRAWER:
      return update(state, { loading: { $set: false } });
    default:
      return state;
  }
};

export const myOffersChatRoomId = (state = {}, action) => {
  switch (action.type) {
    case MY_OFFER__SENT_OPEN_CHAT_DRAWER:
      return action.data;
    default:
      return state;
  }
};

export const myOffersSentDetailData = (state = {}, action) => {
  switch (action.type) {
    case MY_OFFERS_SENT_DATA_DETAIL: {
      return action.data;
    }
    default:
      return state;
  }
};

// =========================== RESENT EMAIL ==========================
export const initialMyOffersSentResentEmailMode = { loading: false };
export const myOffersSentResentEmailMode = (
  state = initialMyOffersSentResentEmailMode,
  action
) => {
  switch (action.type) {
    case MY_OFFER__SENT_SHOW_RESENT_EMAIL_MODAL:
      return update(state, { loading: { $set: true } });
    case MY_OFFER__SENT_HIDE_RESENT_EMAIL_MODAL:
      return update(state, { loading: { $set: false } });
    default:
      return state;
  }
};

export const myOffersSentResendEmailModalData = (state = {}, action) => {
  switch (action.type) {
    case MY_OFFER__SENT__RESENT_EMAIL_MODAL_DATA:
      return action.data;
    default:
      return state;
  }
};

export const myOffersSentResentEmailLoadingStatus = (
  state = "IDLE",
  action
) => {
  switch (action.type) {
    case MY_OFFER__SENT_BEGIN_SEND_EMAIL:
      return "FETCHING";
    case MY_OFFER__SENT_END_SEND_EMAIL:
      return "FETCHED";
    default:
      return state;
  }
};

//======================change status===================================
export const initialMyOffersSentStatusModalData = {
  show: false,
  data: {},
  loading: false,
};
export const myOffersSentStatusModalData = (
  state = initialMyOffersSentStatusModalData,
  action
) => {
  switch (action.type) {
    case MY_OFFER__SENT_SHOW_STATUS_MODAL:
      return update(state, {
        show: { $set: true },
        data: { $set: action.data },
        loading: { $set: false },
      });
    case MY_OFFER__SENT_HIDE_STATUS_MODAL:
      return update(state, {
        show: { $set: false },
        data: { $set: {} },
        loading: { $set: false },
      });
    case MY_OFFER__SENT_EDIT_STATUS:
      return update(state, {
        show: { $set: true },
        data: { $set: action.data },
        loading: { $set: false },
      });
    case MY_OFFER__SENT_BEGIN_SUBMIT_STATUS:
      return update(state, {
        show: { $set: true },
        data: { $set: action.data },
        loading: { $set: true },
      });
    case MY_OFFER__SENT_END_SUBMIT_STATUS:
      return update(state, {
        show: { $set: false },
        data: { $set: action.data },
        loading: { $set: false },
      });
    default:
      return state;
  }
};

export const offerSentListFiltersSchema = yup.object().shape({
  reference__icontains: yup.string().default(""),
  requester__icontains: yup.string().default(""),
  title__icontains: yup.string().default(""),
  trip_date__gte: yup.string().default(""),
  trip_date__lte: yup.string().default(""),
  destination__icontains: yup.string().default(""),
  stay__gte: yup.string().default(""),
  stay__lte: yup.string().default(""),
  created__gte: yup.string().default(""),
  created__lte: yup.string().default(""),
  creator__icontains: yup.string().default(""),
  link: yup.string().default(""),
  page: yup.number().default(1).min(1),
  limit: yup.number().default(6).min(6).max(6),
  target_entity: yup.string().default(""),
  status: yup.string().default("AC"),
});
const MyOfferSentListReducerInitial = {
  ...commonList,
  displayLength: 100,
  filters: offerSentListFiltersSchema.cast({}),
};

export const MyOfferSentListReducer = (
  state = MyOfferSentListReducerInitial,
  action
) => {
  switch (action.type) {
    case MY_OFFER__SENT_LIST_SAVE_RESULTS:
      return action.data;
    case MY_OFFER__SENT_LIST_GENERIC_CHANGE: {
      const { filters } = action;
      return { ...state, filters: { ...state.filters, ...filters } };
    }
    default:
      return state;
  }
};
