import {
  //====================FILTER=====================================
  MY_OFFER__REJECTED_UPDATE_LIST_FILTER_FORM,
  MY_OFFER__REJECTED_APPLY_LIST_FILTER,
  MY_OFFER__REJECTED_INITIALIZE_LIST_FILTER_FORM,
  MY_OFFER__REJECTED_RESET_LIST_FILTER_FORM,
  //===========================LIST=================================
  MY_OFFER__REJECTED_LIST_LOADING,
  MY_OFFER__REJECTED_LIST_IDLE,
  MY_OFFER__REJECTED_FETCH_DATA,
  //========================DASHBOARD===============================
  MY_OFFER__REJECTED_VIEW_TYPE,
  MY_OFFER__REJECTED_FETCH_GRAPH_DATA,
  MY_OFFER__REJECTED_BEGIN_FETCH_GRAPH_DATA,
  MY_OFFER__REJECTED_END_FETCH_GRAPH_DATA,
  MY_OFFER__REJECTED_INIT_GRAPH_TYPE,
  MY_OFFER_REJECTED_LIST_GENERIC_CHANGE,
} from "@src/actions/Project/Archive/MyOffers/Rejected/types";

import { FORMAT_TRAVEL_SERVICES_FORM } from "@src/actions/Shares/types";

import { initialMyOffersSentFilterForm } from "@src/forms/Project/Archive/MyOffers/Sent";

import update from "immutability-helper";

import { formProcessor } from "@src/reducers/tools";
import { commonList } from "../../../../common";

export const initialList = {
  count: 0,
  next: null,
  previous: null,
  results: [],
  page: 1,
  limit: 6,
  displayLength: 100,
};

export const myOffersRejectedList = (state = initialList, action) => {
  switch (action.type) {
    case MY_OFFER__REJECTED_FETCH_DATA:
      return { ...state, ...action.data };
    case MY_OFFER__REJECTED_LIST_LOADING:
      return { ...state, loading: true };
    case MY_OFFER__REJECTED_LIST_IDLE:
      return { ...state, loading: false };
    case MY_OFFER__REJECTED_INITIALIZE_LIST_FILTER_FORM:
      return { ...state, page: 1 };
    default:
      return state;
  }
};

// filter
export const myOffersRejectedListFilter = (
  state = initialMyOffersSentFilterForm,
  action
) => {
  switch (action.type) {
    case MY_OFFER__REJECTED_APPLY_LIST_FILTER:
      return action.data;
    case FORMAT_TRAVEL_SERVICES_FORM:
      return update(state, { $merge: action.data });
    case MY_OFFER__REJECTED_RESET_LIST_FILTER_FORM:
      return action.data;
    default:
      return state;
  }
};

export const myOffersRejectedListFilterForm = (
  state = initialMyOffersSentFilterForm,
  action
) => {
  switch (action.type) {
    case MY_OFFER__REJECTED_INITIALIZE_LIST_FILTER_FORM:
      return initialMyOffersSentFilterForm;
    case MY_OFFER__REJECTED_RESET_LIST_FILTER_FORM:
      return action.data;
    case MY_OFFER__REJECTED_UPDATE_LIST_FILTER_FORM:
      return formProcessor(
        state,
        action.data.type,
        action.data.name,
        action.data.value
      );
    case FORMAT_TRAVEL_SERVICES_FORM:
      return update(state, { $merge: action.data });
    default:
      return state;
  }
};

//====================================DASHBOARD===============================
export const initData = {
  date: "current",
  type: "PR",
};
export const MyOffersRejectedViewType = (state = initData, action) => {
  switch (action.type) {
    case MY_OFFER__REJECTED_INIT_GRAPH_TYPE:
      return initData;
    case MY_OFFER__REJECTED_VIEW_TYPE:
      return action.data;
    default:
      return state;
  }
};

export const MyOffersRejectedGraphData = (state = {}, action) => {
  switch (action.type) {
    case MY_OFFER__REJECTED_FETCH_GRAPH_DATA:
      return action.data;
    default:
      return state;
  }
};

export const initialMyOffersRejectedGraphDataStatus = "IDLE";
export const MyOffersRejectedGraphDataStatus = (state = "IDLE", action) => {
  switch (action.type) {
    case MY_OFFER__REJECTED_BEGIN_FETCH_GRAPH_DATA:
      return "FETCHING";
    case MY_OFFER__REJECTED_END_FETCH_GRAPH_DATA:
      return "FETCHED";
    default:
      return state;
  }
};

// ========= New Refactored ================

const MyOffeRejectedListReducerInitial = {
  ...commonList,
  displayLength: 100,
  filters: {
    reference__icontains: "",
    requester__icontains: "",
    title__icontains: "",
    trip_date__gte: "",
    trip_date__lte: "",
    destination__icontains: "",
    stay__gte: "",
    stay__lte: "",
    created__gte: "",
    created__lte: "",
    creator__icontains: "",
    status: "RJ",
    page: 1,
    limit: 6,
    target_entity: "",
  },
};

export const MyOffeRejectedListReducer = (
  state = MyOffeRejectedListReducerInitial,
  action
) => {
  switch (action.type) {
    case MY_OFFER_REJECTED_LIST_GENERIC_CHANGE: {
      const { filters } = action;
      return { ...state, filters: { ...state.filters, ...filters } };
    }
    default:
      return state;
  }
};
