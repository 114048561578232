import { PricingFilter } from "./FiltersCommon";

import _ from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import PoiAutoComplete from "./PoiAutoComplete";
import { createUseStyles } from "react-jss";
import { Form, Formik } from "formik";
import {
  NormalInputField,
  NormalPOIAutocompleteField,
  NormalSelectField,
} from "@src/components/forms";
import { variables } from "@src/jsssetup";
import { CustomButton } from "@src/components/common/buttons";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  convertAmountSelector,
  getSelCurrencySelector,
} from "@src/selectors/Financial";
import { poiGeodata } from "@src/api";
import { Loader } from "rsuite";
import { overviewAccFiltersChange } from "@src/actions/Project/TripPlanner";
import { withAppUserType } from "@src/components/authorization/permissionhocs";

export function useConvertedHotelPrice(price, currency) {
  const { convertedPrice, convertCurrency } = useSelector((state) => {
    const convertedPrice = parseInt(
      convertAmountSelector(state, {
        amount: price,
        currentCurrency: currency,
      }).convertedAmount,
      10
    );
    const selectedCurrency = getSelCurrencySelector(state).notation;
    return {
      convertedPrice,
      convertCurrency: selectedCurrency,
    };
  });
  return { convertedPrice, convertCurrency };
}

export const accFiltersValidationSchema = yup.object().shape({
  name: yup.string().default(""),
  nearby: yup.string().default(""),
  nearbyGeodata: yup.object().default({ lat: 0, lng: 0 }),
  room_type: yup.string().default(""),
  board: yup.string().default(""),
  vendor: yup.string().default(""),
  rating: yup.string().default(""),
  refundable: yup.string().default(""),
  order_by: yup.string().default("price"),
  // min_price: yup.number().default(0),
  // max_price: yup.number().default(1000000),
});

var VendorFilter = ({ vendorOptions = [] }) => {
  return (
    <NormalSelectField
      name="vendor"
      label="Vendor"
      options={[["", "------"], ...vendorOptions]}
    />
  );
};
VendorFilter.defaultProps = { vendorOptions: [] };
VendorFilter.propTypes = { vendorOptions: PropTypes.array.isRequired };
VendorFilter = withAppUserType(VendorFilter, ["member"]);

const overviewAccFiltersStyles = createUseStyles({
  OverviewAccFilters: {
    background: variables.colors.background.grey,
    zIndex: 1,
    boxShadow: variables.shadows.normalShadow,
  },
  form: {
    padding: variables.normal_gap,
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridGap: variables.normal_gap,
  },
  actions: {
    display: "grid",
    gridTemplateColumns: "max-content max-content",
    gridGap: variables.half_gap,
    alignItems: "end",
    gridColumn: "span 4",
    justifyContent: "space-between",
  },
  actionBtns: {
    display: "grid",
    gridTemplateColumns: "repeat(3, max-content)",
    gridGap: variables.half_gap,
  },
  properties: {
    padding: variables.normal_gap,
    background: "white",
    borderRadius: "5px",
    justifySelf: "start",
  },
  orderBy: { gridColumn: "span 2" },
});
const OverviewAccFilters = ({
  accommodations = [],
  mode,
  onSetFilters,
  onViewMap,
  onViewList,
}) => {
  const [loading, setLoading] = useState(false);
  const classes = overviewAccFiltersStyles();

  const ratingOptions = Object.entries(
    _.countBy(accommodations, (a) => a.metadata.rating)
  ).map(([key, number]) => [
    key,
    `${key} Star${key !== "1" ? "s" : ""} (${number})`,
  ]);

  const vendorOptions = _.sortBy(
    Object.entries(
      _.countBy(
        _.flatten(
          accommodations.map((a) => a.computed_data.available_providers)
        ),
        (a) => a
      )
    ).map(([key, number]) => [key, `${_.capitalize(key)} (${number})`]),
    (v) => v[1]
  );

  const boardOptions = _.sortBy(
    Object.entries(
      _.countBy(
        _.flatten(accommodations.map((a) => a.computed_data.available_boards)),
        (a) => a
      )
    ).map(([key, number]) => [key, `${key} (${number})`]),
    (v) => v[1]
  );

  const cancellationOptions = _.sortBy(
    Object.entries(
      _.countBy(
        accommodations.map((a) => a.computed_data.refundable),
        (a) => a
      )
    ).map(([key, number]) => [
      key,
      `${key === "true" ? "Refundable" : "Non Refundable"} (${number})`,
    ]),
    (v) => v[1]
  );

  // var meanPrice = _.mean(
  //   _.flatten(accommodations.map((a) => a.rooms.map((r) => r.price.value)))
  // );
  // var minPrice = parseInt(
  //   Math.min(
  //     ..._.flatten(accommodations.map((a) => a.rooms.map((r) => r.price.value)))
  //   ),
  //   10
  // );
  // var maxPrice = parseInt(
  //   Math.max(
  //     ..._.flatten(accommodations.map((a) => a.rooms.map((r) => r.price.value)))
  //   ),
  //   10
  // );
  // const currency = accommodations?.[0]?.rooms?.[0]?.price?.currency || "EUR";
  // const prices = useSelector((state) => {
  //   const miPrice = parseInt(
  //     convertAmountSelector(state, {
  //       amount: minPrice,
  //       currentCurrency: currency,
  //     }).convertedAmount,
  //     10
  //   );

  //   const maPrice = parseInt(
  //     convertAmountSelector(state, {
  //       amount: maxPrice,
  //       currentCurrency: currency,
  //     }).convertedAmount,
  //     10
  //   );

  //   const mePrice = parseInt(
  //     convertAmountSelector(state, {
  //       amount: meanPrice,
  //       currentCurrency: currency,
  //     }).convertedAmount,
  //     10
  //   );

  //   const selectedCurrency = getSelCurrencySelector(state).notation;
  //   return {
  //     minPrice: miPrice,
  //     maxPrice: maPrice,
  //     meanPrice: mePrice,
  //     selectedCurrency,
  //   };
  // });

  // meanPrice = prices.meanPrice.toLocaleString("en-US", {
  //   style: "currency",
  //   currency: prices.selectedCurrency,
  // });
  // maxPrice = prices.maxPrice;
  // minPrice = prices.minPrice;

  const dispatch = useDispatch();

  const setFilters = useCallback(
    (filters) => dispatch(overviewAccFiltersChange(filters)),
    []
  );
  const filters = useSelector((state) => state.tripPlannerOverviewAccsFilters);
  useEffect(() => {
    setFilters({
      ...filters,
      // min_price: minPrice - 1 > 0 ? minPrice - 1 : minPrice,
      // max_price: maxPrice + 1,
    });
  }, [accommodations.length === 0]);

  return (
    <div className={classes.OverviewAccFilters}>
      {loading && <Loader backdrop center size="lg" />}
      <Formik
        initialValues={filters}
        validationSchema={accFiltersValidationSchema}
        onSubmit={async (values) => {
          if (values.nearby.includes("---")) {
            const dest_uid = values.nearby.split("---")[1];
            setLoading(true);
            const result = await poiGeodata({ uid: dest_uid });
            values.nearbyGeodata = result?.geodata || { lat: 0, lng: 0 };
            setLoading(false);
          }

          onSetFilters(values);
        }}>
        {({ resetForm, submitForm }) => (
          <Form className={classes.form}>
            <NormalInputField name="name" label="Name" />
            <NormalPOIAutocompleteField
              id="OverviewAccFilters__NormalPOIAutocompleteField"
              label="Nearby"
              name="nearby"
            />
            <NormalSelectField
              name="board"
              label="Board"
              options={[["", "------"], ...boardOptions]}
            />
            <VendorFilter vendorOptions={vendorOptions} />
            <NormalSelectField
              name="rating"
              label="Rating"
              options={[["", "------"], ...ratingOptions]}
            />
            <NormalSelectField
              name="refundable"
              label="Refundable"
              options={[["", "------"], ...cancellationOptions]}
            />
            {/* <NormalInputField
              name="min_price"
              label="Min Price"
              type="number"
              extraInputProps={{ min: minPrice - 1, max: maxPrice + 1 }}
            />
            <NormalInputField
              name="max_price"
              label="Max Price"
              type="number"
              extraInputProps={{ min: minPrice - 1, max: maxPrice + 1 }}
            /> */}
            <span className={classes.orderBy}>
              <NormalSelectField
                name="order_by"
                label="Order By"
                options={[
                  ["price", "Price Ascending"],
                  ["-price", "Price Descending"],
                  ["rating", "Rating Ascending"],
                  ["-rating", "Rating Descending"],
                ]}
              />
            </span>
            <div className={classes.actions}>
              <span className={classes.properties}>
                <strong>Available Properties: </strong>
                {accommodations.length}
              </span>
              {/* <span className={classes.properties}>
                <strong>Average Price: </strong>
                {meanPrice}
              </span> */}
              <span className={classes.actionBtns}>
                <button className="Button" type="submit" onClick={submitForm}>
                  Apply
                </button>
                <button
                  className="Button"
                  data-ghost="true"
                  onClick={(e) => {
                    e.preventDefault();
                    resetForm();
                    const defaultFilters = accFiltersValidationSchema.cast({});
                    // defaultFilters.min_price = minPrice - 1;
                    // defaultFilters.max_price = maxPrice + 1;
                    onSetFilters(defaultFilters);
                  }}>
                  Reset
                </button>
                <button
                  className="Button"
                  data-ghost={true}
                  onClick={(e) => {
                    e.preventDefault();
                    mode === "list" ? onViewMap() : onViewList();
                  }}>
                  {mode === "list" ? "Map View" : "List View"}
                </button>
              </span>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
OverviewAccFilters.defaultProps = {
  accommodations: [],
};
OverviewAccFilters.propTypes = {
  accommodations: PropTypes.array.isRequired,
  mode: PropTypes.oneOf(["list", "listmap"]).isRequired,
  onViewList: PropTypes.func.isRequired,
  onSetFilters: PropTypes.func.isRequired,
  onViewMap: PropTypes.func.isRequired,
};

export default OverviewAccFilters;
