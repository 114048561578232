import { StyleSheet, Text, View, Font } from "@react-pdf/renderer";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React from "react";
import NotoSansRegular from "@src/style/fonts/NotoSans-Regular.ttf";
import NotoSansBold from "@src/style/fonts/NotoSans-Bold.ttf";

Font.register({
  family: "Noto Sans",
  fonts: [
    { src: NotoSansRegular, fontWeight: "normal" },
    { src: NotoSansBold, fontWeight: "bold" },
  ],
});

const invoiceBasicInfoStyles = StyleSheet.create({
  labelValueRow: {
    flexDirection: "row",
    fontSize: 10,
    paddingBottom: 5,
    fontFamily: "Noto Sans",
    fontWeight: "bold",
  },
  invoiceLabel: { width: 100 },
  dueDateLabel: { width: 62 },
  value: { fontFamily: "Noto Sans" },
});
const InvoiceBasicInfo = ({ data }) => {
  return (
    <React.Fragment>
      {!!data && (
        <React.Fragment>
          <View style={invoiceBasicInfoStyles.labelValueRow} key="invoice">
            <Text style={invoiceBasicInfoStyles.invoiceLabel}>
              {data.invoiceNumber && "Invoice Number :"}
            </Text>
            <Text style={invoiceBasicInfoStyles.value}>
              {data.invoiceNumber && data.invoiceNumber}
            </Text>
          </View>
          <View style={invoiceBasicInfoStyles.labelValueRow} key="invoice">
            <Text style={invoiceBasicInfoStyles.dueDateLabel}>
              {data.dueDate && "Due Date :"}
            </Text>
            <Text style={invoiceBasicInfoStyles.value}>
              {data.dueDate &&
                DateTime.fromISO(data.dueDate).toLocaleString(
                  DateTime.DATE_MED_WITH_WEEKDAY
                )}
            </Text>
          </View>
        </React.Fragment>
      )}
      {(data?.customFields ?? []).map((item, idx) => (
        <View style={invoiceBasicInfoStyles.labelValueRow} key={idx}>
          <Text style={invoiceBasicInfoStyles.label}>
            {item.label && item.label + ":"}
          </Text>
          <Text style={invoiceBasicInfoStyles.value}>{item.value}</Text>
        </View>
      ))}
    </React.Fragment>
  );
};
InvoiceBasicInfo.defaultProps = { data: {}, entity_colors: {} };
InvoiceBasicInfo.propTypes = {
  data: PropTypes.object.isRequired,
  entity_colors: PropTypes.object,
};
export default InvoiceBasicInfo;
