import _ from "lodash";
import { getDestinationTypeInfoFn } from "@src/actions/Project/TripPlanner/helpers";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React from "react";
import { Placeholder } from "rsuite";
import update from "immutability-helper";

const destinationTypes = {
  NOR: "Normal",
  LAY: "Layover",
  NON: "Non-serviced",
};

export const Destination = ({ destIdx, destination, setTripObject }) => {
  const cIn = DateTime.fromISO(destination.checkIn);
  const cOut = DateTime.fromISO(destination.checkOut);

  const fetcherFn = getDestinationTypeInfoFn({ destLvl: destination.type });
  const { data, isLoading } = useQuery({
    queryKey: ["destination", `${destination.type}__${destination.id}`],
    queryFn: () => fetcherFn(destination.id),
    onSuccess: (data) => {
      function getCountryCode(data) {
        if (typeof data?.parent !== "undefined" && !data?.parent?.code) {
          return getCountryCode(data?.parent);
        }

        return data?.parent?.code;
      }
      const country_code = getCountryCode(data);

      setTripObject((p) =>
        update(p, {
          destinations: {
            [destIdx]: {
              images: { $set: data.images },
              country_code: { $set: country_code },
            },
          },
        })
      );
    },
  });
  const mainImgUrl =
    _.sortBy(data?.images ?? [], "priority")?.[0]?.big_thumbnail_url ?? "";

  const stay = cOut.diff(cIn, "days").as("days");

  return (
    <div className="EasyAI__destination">
      {isLoading ? (
        <Placeholder.Paragraph
          rows={0}
          graph="image"
          active={true}
          style={{
            display: "grid",
            width: "100%",
            justifyContent: "center",
            alignContent: "center",
          }}
        />
      ) : (
        <img src={mainImgUrl} className="EasyAI__destination__img" />
      )}
      <div className="EasyAI__destination__content">
        <h6>
          {destIdx + 1}. {destination.name_en}
        </h6>
        <span>
          <strong>Check In: </strong>
          {cIn.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
        </span>
        <span>
          <strong>Check Out: </strong>
          {cOut.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
        </span>
        <span>
          <strong>Stay:</strong> {stay} {stay === 1 ? "Night" : "Nights"}
        </span>
        <span>
          <strong>Destination Type:</strong>{" "}
          {destinationTypes?.[destination.dest_type] ?? "N/A"}
        </span>
      </div>
    </div>
  );
};
Destination.defaultProps = {
  destIdx: 0,
};
Destination.propTypes = {
  destIdx: PropTypes.number.isRequired,
  destination: PropTypes.object.isRequired,
};
