// ========================== B2B LIST VIEW ==========================
export const B2B_ACCOMMODATION_RESERVATION_LIST_INIT =
  "B2B_ACCOMMODATION_RESERVATION_LIST_INIT";
export const B2B_ACCOMMODATION_SAVE_RESERVATION_LIST_DATA =
  "B2B_ACCOMMODATION_SAVE_RESERVATION_LIST_DATA";
export const B2B_ACCOMMODATION_RESET_LIST_FILTER_FORM =
  "B2B_ACCOMMODATION_RESET_LIST_FILTER_FORM";
export const B2B_ACCOMMODATION_LIST_FILTER_CHANGE =
  "B2B_ACCOMMODATION_LIST_FILTER_CHANGE";
// ============================ LIST VIEW ============================
export const ACCOMMODATION_RESERVATION_LIST_INIT =
  "ACCOMMODATION_RESERVATION_LIST_INIT";
export const ACCOMMODATION_SAVE_RESERVATION_LIST_DATA =
  "ACCOMMODATION_SAVE_RESERVATION_LIST_DATA";
export const ACCOMMODATION_BEGIN_FETCH_DATA = "ACCOMMODATION_BEGIN_FETCH_DATA";
export const ACCOMMODATION_END_FETCH_DATA = "ACCOMMODATION_END_FETCH_DATA";
export const ACCOMMODATION_FETCH_OPTIONS = "ACCOMMODATION_FETCH_OPTIONS";

// =========================== LIST FILTERS ==========================
export const ACCOMMODATION_LIST_FILTER_INIT = "ACCOMMODATION_LIST_FILTER_INIT";
export const ACCOMMODATION_LIST_FILTER_CHANGE =
  "ACCOMMODATION_LIST_FILTER_CHANGE";

// ========================= FILTER RELATION =========================
export const ACCOMMODATION_INITIALIZE_LIST_FILTER_FORM =
  "ACCOMMODATION_INITIALIZE_LIST_FILTER_FORM";
export const ACCOMMODATION_UPDATE_LIST_FILTER_FORM =
  "ACCOMMODATION_UPDATE_LIST_FILTER_FORM";
export const ACCOMMODATION_APPLY_LIST_FILTER =
  "ACCOMMODATION_APPLY_LIST_FILTER";
export const ACCOMMODATION_RESET_LIST_FILTER_FORM =
  "ACCOMMODATION_RESET_LIST_FILTER_FORM";

// ========================= COLUMNS RELATION ========================
export const ACCOMMODATION_UPDATE_LIST_COLUMNS_FORM =
  "ACCOMMODATION_UPDATE_LIST_COLUMNS_FORM";
export const ACCOMMODATION_APPLY_LIST_COLUMNS =
  "ACCOMMODATION_APPLY_LIST_COLUMNS";
export const ACCOMMODATION_INITIALIZE_LIST_COLUMNS_FORM =
  "ACCOMMODATION_INITIALIZE_LIST_COLUMNS_FORM";

// =========================== DETAIL VIEW ===========================
export const ACCOMMODATION_RESERVATION_INIT = "ACCOMMODATION_RESERVATION_INIT";
export const ACCOMMODATION_FETCH_DETAIL_VIEW =
  "ACCOMMODATION_FETCH_DETAIL_VIEW";
export const ACCOMMODATION_RESERVATION_DATA = "ACCOMMODATION_RESERVATION_DATA";

//========================DASHBOARD===============================
export const ACCOMMODATION_VIEW_TYPE = "ACCOMMODATION_VIEW_TYPE";
export const ACCOMMODATION_FETCH_GRAPH_DATA = "ACCOMMODATION_FETCH_GRAPH_DATA";
export const ACCOMMODATION_BEGIN_FETCH_GRAPH_DATA =
  "ACCOMMODATION_BEGIN_FETCH_GRAPH_DATA";
export const ACCOMMODATION_END_FETCH_GRAPH_DATA =
  "ACCOMMODATION_END_FETCH_GRAPH_DATA";
export const ACCOMMODATION_INIT_GRAPH_TYPE = "ACCOMMODATION_INIT_GRAPH_TYPE";

// ==========================NEW====================================
export const B2C_ACCOMMODATION_LIST_FILTER_CHANGE =
  "B2C_ACCOMMODATION_LIST_FILTER_CHANGE";
