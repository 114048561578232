import TransTxt from "@src/components/common/SxFormatMessage";

import { initialColumnsProcessor } from "./../Columns/policyCommonColumns";

import React from "react";

export const initialB2CPolicyColumnsSetUp = {
  basic: {
    value: [
      "policy_name",
      "b2c_websites",
      "pax",
      "distribution_period",
      "reservation_period",
      "rounding_digits",
    ],
    choices: [
      {
        key: "policy_name",
        disabled: true,
        display_name: <TransTxt id="policy_name" startCase />,
      },
      {
        key: "pax",
        disabled: false,
        display_name: <TransTxt id="pax" startCase />,
      },
      {
        key: "b2c_websites",
        disabled: false,
        display_name: <TransTxt id="b2c_websites" startCase />,
      },
      {
        key: "distribution_period",
        disabled: false,
        display_name: <TransTxt id="distribution_period" startCase />,
      },
      {
        key: "reservation_period",
        disabled: false,
        display_name: <TransTxt id="reservation_period" startCase />,
      },
      {
        key: "rounding_digits",
        disabled: false,
        display_name: "Rounding Digits",
      },
    ],
  },
  services: {
    value: ["service_categories", "service_type"],
    choices: [
      {
        key: "service_categories",
        disabled: false,
        display_name: <TransTxt id="service_categories" startCase />,
      },
      {
        key: "service_type",
        disabled: false,
        display_name: <TransTxt id="service_type" startCase />,
      },
    ],
  },
  stage: {
    value: ["status", "created", "creator", "edited"],
    choices: [
      {
        key: "created",
        disabled: false,
        display_name: <TransTxt id="created" startCase />,
      },
      {
        key: "edited",
        disabled: false,
        display_name: <TransTxt id="edited" startCase />,
      },
      {
        key: "creator",
        disabled: false,
        display_name: <TransTxt id="creator" startCase />,
      },
      {
        key: "status",
        disabled: true,
        display_name: <TransTxt id="status" startCase />,
      },
    ],
  },
};

export const initialB2CPolicyColumnsProcessor = initialColumnsProcessor;

export const initialB2CPolicyColumnsMapping = {
  policy_name: {
    key: "policy_name",
    fixed: "left",
    width: 150,
  },
  pax: {
    key: "pax",
    fixed: "left",
  },
  b2c_websites: {
    key: "b2c_websites",
    fixed: true,
    width: 250,
  },
  distribution_period: {
    key: "distribution_period",
    width: 200,
  },
  reservation_period: {
    key: "reservation_period",
    width: 200,
  },
  rounding_digits: {
    key: "rounding_digits",
    width: 200,
  },
  service_categories: {
    key: "service_categories",
    width: 200,
  },
  service_type: {
    key: "service_type",
    width: 150,
  },
  created: {
    key: "created",
    width: 170,
  },
  edited: {
    key: "edited",
    width: 170,
  },
  creator: {
    key: "creator",
    width: 170,
  },
  status: {
    key: "status",
    fixed: "right",
  },
};
