import _ from "lodash";
import {
  fetchGeneralServicesData,
  generalServicesGenericChange,
  showGenImportServiceModal,
  showGenServiceModal,
} from "@src/actions/MyLibrary/GeneralServices";
import { variables, tableHeadStyles, baseListLayout } from "@src/jsssetup";

import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useDispatch, useSelector } from "react-redux";
import { Icon, IconButton, Loader } from "rsuite";
import ServiceImportModal from "./ServiceImportModal";
import ServiceModal from "./ServiceModal";
import ClipboardCopier from "sisi_js_components/lib/components/ClipboardCopier/ClipboardCopier";
import { useHistory, withRouter } from "react-router-dom";
import { ControlStrip, ListPageHeader } from "@src/components/common/lists";
import TransTxt from "@src/components/common/SxFormatMessage";
import { injectIntl } from "react-intl";
import { shortDatetimer } from "@src/pages/Operation/common/helpers";
import { withAppUserPermission } from "@src/components/authorization/permissionhocs";
import { QuickFilters } from "@src/components/quickfilters";

const ImportButton = withAppUserPermission(
  (props) => (
    <button
      // className="Button"
      data-size="xs"
      data-primary="true"
      {...props}>
      {props.children}
    </button>
  ),
  ["my_library"],
  {
    edit: true,
  }
);
const AddServicesButton = withAppUserPermission(
  (props) => (
    <button
      // className="Button"
      data-size="xs"
      data-success="true"
      {...props}>
      {props.children}
    </button>
  ),
  ["my_library"],
  {
    edit: true,
  }
);

export const service_type_mapping = {
  BUN: "Service Combination",
  GUI: "Guide/Assistant",
  INT: "Interpreter",
  MEAL: "Meal",
  GEN: "Generic Service",
  SIGH: "Sightseeing",
  TRF: "Transfer",
  VEH: "Vehicle Rental",
};

const srvsTableStyles = createUseStyles({
  servicesTable: { overflow: "auto", width: "100%" },
  table: {
    width: "100%",
  },
  tableHead: {
    ...tableHeadStyles,
  },
  cell: {
    textAlign: "center",
    padding: `calc(${variables.normal_gap} / 2)`,
  },
});
const ServicesTable = (props) => {
  const { services, history, loading } = props;

  const classes = srvsTableStyles();

  const status_mapping = { IN: "Inactive", AC: "Active", CA: "Candidate" };
  const status_color_mapping = { IN: "red", AC: "green", CA: "purple" };

  return (
    <div className={classes.servicesTable}>
      {loading ? (
        <Loader backdrop size="lg" />
      ) : (
        <table className={classes.table}>
          <thead className={classes.tableHead}>
            <tr>
              <th>#</th>
              <th>
                <TransTxt id="MyLibrary_gen_list__reference" />
              </th>
              <th>
                <TransTxt id="MyLibrary_gen_list__external_id" />
              </th>
              <th>
                <TransTxt id="MyLibrary_gen_list__name" />
              </th>
              <th>
                <TransTxt id="MyLibrary_gen_list__codename" />
              </th>
              <th>
                <TransTxt id="MyLibrary_gen_list__type" />
              </th>
              <th>
                <TransTxt id="MyLibrary_gen_list__destination" />
              </th>
              <th>
                <TransTxt id="MyLibrary_gen_list__creator" />
              </th>
              <th>
                <TransTxt id="MyLibrary_gen_list__created" />
              </th>
              {/* <th><TransTxt id="MyLibrary_gen_list__status" /></th> */}
              <th>
                <TransTxt id="MyLibrary_gen_list__source" />
              </th>
              <th>
                <TransTxt id="MyLibrary_gen_list__actions" />
              </th>
            </tr>
          </thead>
          <tbody>
            {services.map((srv, idx) => {
              return (
                <tr key={idx}>
                  <td className={classes.cell}>{idx + 1}</td>
                  <td className={classes.cell}>
                    <ClipboardCopier reference={srv.reference} size="lg" />
                  </td>
                  <td className={classes.cell}>
                    <ClipboardCopier
                      reference={srv.service_data.id}
                      size="sm"
                    />
                  </td>
                  <td className={classes.cell}>{srv.service_data.name}</td>
                  <td className={classes.cell}>
                    {srv.service_data.short_description_en}
                  </td>
                  <td className={classes.cell}>
                    {service_type_mapping[srv.service_data.type]}
                  </td>
                  <td className={classes.cell}>
                    {_.get(srv, "service_data.destination_meta.name_en")} (
                    {_.get(
                      srv,
                      "service_data.destination_meta.country_name_en"
                    )}
                    )
                  </td>
                  <td className={classes.cell}>
                    {srv.creator &&
                      [
                        _.get(srv, "creator_metadata.first_name"),
                        _.get(srv, "creator_metadata.last_name"),
                        `(${_.get(srv, "creator_metadata.email")})`,
                      ].join(" ")}
                  </td>
                  <td className={classes.cell}>
                    {srv.created ? shortDatetimer(srv.created) : "N/A"}
                  </td>
                  {/* <td className={classes.cell}> */}
                  {/*   <Tag color={status_color_mapping[srv.status]}> */}
                  {/*     <strong>{status_mapping[srv.status]}</strong> */}
                  {/*   </Tag> */}
                  {/* </td> */}
                  <td className={classes.cell}>OSCAR</td>
                  <td className={classes.cell}>
                    <IconButton
                      id={`viewDetailsBtn__${idx}`}
                      color="green"
                      icon={<Icon icon="long-arrow-right" />}
                      size="xs"
                      circle
                      onClick={() =>
                        history.push(`/my-library/general-services/${srv.id}`)
                      }
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};
ServicesTable.defaultProps = {
  services: [],
  loading: false,
};
ServicesTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  services: PropTypes.array.isRequired,
};

const dashStyles = createUseStyles({
  main: {
    backgroundColor: variables.colors.deepblue,
  },
});
const Dashboard = (props) => {
  const classes = dashStyles();
  return <div className={classes.main}>t</div>;
};

const getFilterFields = (intl) => [
  { name: "reference" },
  { name: "external_id" },
  { name: "codename" },
  { name: "name" },
  { name: "destination" },
  {
    comp: "NormalSelectField",
    name: "service_type",
    label: intl.formatMessage({ id: "MyLibrary_gen_list__type" }),
    options: [
      ["", "-------"],
      ["ACC", intl.formatMessage({ id: "MyLibrary_gen_list__accommodation" })],
      [
        "BUN",
        intl.formatMessage({
          id: "MyLibrary_gen_list__service combination",
        }),
      ],
      [
        "GUI",
        intl.formatMessage({ id: "MyLibrary_gen_list__guide_assistant" }),
      ],
      ["INT", intl.formatMessage({ id: "MyLibrary_gen_list__interpreter" })],
      ["MEAL", intl.formatMessage({ id: "MyLibrary_gen_list__meal" })],
      [
        "GEN",
        intl.formatMessage({ id: "MyLibrary_gen_list__generic_service" }),
      ],
      ["SIGH", intl.formatMessage({ id: "MyLibrary_gen_list__sightseeing" })],
      ["TRF", intl.formatMessage({ id: "MyLibrary_gen_list__transfer" })],
      ["VEH", intl.formatMessage({ id: "MyLibrary_gen_list__vehicle rental" })],
    ],
  },
];

const controlsStyles = createUseStyles({
  main: {
    display: "grid",
    gridTemplateColumns: "repeat(3, max-content)",
    gridGap: variables.normal_gap,
    padding: variables.normal_gap,
    alignItems: "center",
    justifyContent: "end",
  },
});
const Controls = (props) => {
  const { loading, onAddService, onImportService, onRefreshServices } = props;

  const classes = controlsStyles();
  return (
    <div className={classes.main}>
      <IconButton
        icon={<Icon icon="plus" />}
        appearance="primary"
        size="sm"
        onClick={onImportService}>
        <strong>
          <TransTxt id="MyLibrary_gen_list__import_service" />
        </strong>
      </IconButton>
      <IconButton
        icon={<Icon icon="plus" />}
        color="green"
        size="sm"
        onClick={onAddService}>
        <strong>
          <TransTxt id="MyLibrary_gen_list__add_service" />
        </strong>
      </IconButton>
      <IconButton
        icon={<Icon icon="refresh" spin={loading} />}
        appearance="primary"
        size="sm"
        onClick={onRefreshServices}>
        <strong>
          <TransTxt id="MyLibrary_gen_list__refresh_services" />
        </strong>
      </IconButton>
    </div>
  );
};
Controls.propTypes = {
  loading: PropTypes.bool.isRequired,
  onAddService: PropTypes.func.isRequired,
  onImportService: PropTypes.func.isRequired,
  onRefreshServices: PropTypes.func.isRequired,
};

const styles = createUseStyles({
  GeneralServices: {
    ...baseListLayout,
    overflow: "auto",
  },
  desktopOnly: {
    display: "none",
  },
  [`@media ${variables.media.smallscreen}`]: {
    desktopOnly: {
      display: "block",
    },
  },
});
var GeneralServices = ({ intl }) => {
  const history = useHistory();
  const classes = styles();

  const [loading, setLoading] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);

  const dispatch = useDispatch();

  const { filters, page, count, limit, results } = useSelector(
    (state) => state.GeneralSrvsListReducer
  );

  const fetchListData = useCallback(
    () => dispatch(fetchGeneralServicesData()),
    [dispatch]
  );
  const changePage = useCallback(
    (page) => dispatch(generalServicesGenericChange("page", page)),
    [dispatch]
  );
  const changeLimit = useCallback(
    (limit) => dispatch(generalServicesGenericChange("limit", limit)),
    [dispatch]
  );
  const changeFilters = useCallback(
    (filters) => dispatch(generalServicesGenericChange("filters", filters)),
    [dispatch]
  );
  const onAddService = useCallback(
    () => dispatch(showGenServiceModal()),
    [dispatch]
  );
  const onImportService = useCallback(
    () => dispatch(showGenImportServiceModal()),
    [dispatch]
  );

  async function getServices() {
    setLoading(true);
    await fetchListData();
    setLoading(false);
  }

  useEffect(() => {
    getServices();
  }, [filters, page, limit]);

  return (
    <div className={classes.GeneralServices}>
      <ListPageHeader title="GENERAL SERVICES" />
      {/* <QuickFilters
        filters={filters}
        applyFilters={(filters) => changeFilters(filters)}
      /> */}
      <QuickFilters
        initialFilters={filters}
        translationKeyPrefix="MyLibrary_gen_list__"
        fields={getFilterFields(intl)}
        onSubmit={(values) => changeFilters({ ...values, page: 1 })}
      />
      <ControlStrip
        limit={limit}
        page={page}
        count={count}
        onChangePage={(page) => changePage(page)}
        onChangeLimit={(limit) => changeLimit(limit)}>
        <ImportButton
          id="import_service"
          className={`${classes.desktopOnly} Button`}
          /* onImportService */
          onClick={() => setShowImportModal(true)}>
          <strong>
            <TransTxt id="MyLibrary_gen_list__import_service" />
          </strong>
        </ImportButton>
        <AddServicesButton
          id="add_service"
          className={`${classes.desktopOnly} Button`}
          onClick={onAddService}>
          <strong>
            <TransTxt id="MyLibrary_gen_list__add_service" />
          </strong>
        </AddServicesButton>
        <button
          id="refresh_services"
          className="Button"
          data-size="xs"
          data-primary="true"
          onClick={getServices}>
          <strong>
            <TransTxt id="MyLibrary_gen_list__refresh_services" />
          </strong>
        </button>
      </ControlStrip>
      <ServicesTable services={results} history={history} loading={loading} />
      <ServiceModal />
      {showImportModal && (
        <ServiceImportModal
          onGetServices={() => getServices()}
          onHide={() => setShowImportModal(false)}
        />
      )}
    </div>
  );
};
GeneralServices.propTypes = {
  history: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};
GeneralServices = injectIntl(GeneralServices);
export default withRouter(GeneralServices);
