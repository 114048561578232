import { notifyCommonWarning } from "@src/components/common/notifications/CommonWarningNotification.js";

import {
  // =============== ACCOMMODATION PREFERENCES ACTIONS ===============
  TRIPPLANNER_ACCOMMODATION_EDIT_DISABLED,
  TRIPPLANNER_ACCOMMODATION_EDIT_ENABLED,
  TRIPPLANNER_CLEAR_ACCS,
  // ================== ACCOMMODATION SEARCH ACTIONS =================
  TRIPPLANNER_ACCOMMODATION_STORE_POLL_RESULTS,
  TRIPPLANNER_CLEAR_DESTINATION_ACCS,
  TRIPPLANNER_ACCOMMODATION_SET_CUSTOMER_REMARKS,
} from "./types";

import { validateAccPref } from "./tools";

// ============================= ACTIONS =============================

import _ from "lodash";
import { getHotelSelectedRooms } from "@src/selectors/Project/TripPlanner";
import {
  notifyAccSearchInitFailed,
  notifyAccSearchInitFailedTerminally,
  notifyAccSearchTakingTooLong,
} from "@src/components/common/notifications/TripPlanner/accommodation_notifications";

export const accommodationEdit = () => {
  return { type: TRIPPLANNER_ACCOMMODATION_EDIT_ENABLED };
};

export const accPrefEditDisabled = () => {
  return { type: TRIPPLANNER_ACCOMMODATION_EDIT_DISABLED };
};

export const accConfirmAndSearch = () => (dispatch, getState) => {
  const state = getState();
  const setup = state.tripPlannerItinerarySetupForm;

  dispatch(accPrefEditDisabled());
  dispatch(clearAccs());

  const rqServices = setup.requiredServices;

  if (rqServices.includes("ACC")) {
    const validInfo = validateAccPref(
      {
        adults: parseInt(setup.adults, 10),
        children: parseInt(setup.children, 10),
        children_ages: setup.children_ages,
      },
      state.tripPlannerAccPax
    );

    if (!validInfo.isValid) {
      validInfo.msg.forEach((m) => {
        window.setTimeout(() => notifyCommonWarning(m), 100);
      });
      dispatch(accommodationEdit());
      return null;
    }
  }

  if (setup.requiredServices.includes("TR")) {
    if (
      state.tripPlannerTrpPrefEditMode &&
      [2, 3].includes(state.tripPlannerCurrentStep)
    ) {
      window.setTimeout(() => {
        dispatch(accConfirmAndSearch());
      }, 1000);
      return null;
    }
  }

  if (state.tripPlannerTrpFastSearching) {
    if ([2, 3].includes(state.tripPlannerCurrentStep)) {
      window.setTimeout(() => {
        console.log("trp pref searching");
        console.log("dispatching again confirm");
        dispatch(accConfirmAndSearch());
      }, 1000);
    }
    return null;
  }

  return null;
};

export const clearAccs = () => {
  return { type: TRIPPLANNER_CLEAR_ACCS };
};

export const clearDestAccs = (destOrder) => {
  return { type: TRIPPLANNER_CLEAR_DESTINATION_ACCS, destOrder };
};

export const maxAccPollCount = 15;

export const accStorePollResults = (destOrder, results, prefs) => {
  const validResults = results.filter((r) => r.rooms.length > 0) || [];

  return {
    type: TRIPPLANNER_ACCOMMODATION_STORE_POLL_RESULTS,
    destOrder,
    results: validResults,
    prefs,
  };
};

export const accPrebookPayloadCreator = (accommodation, session_id) => {
  const selected_rooms = getHotelSelectedRooms({ acc: accommodation });

  const payload = {
    session_id,
    acc_id: accommodation.metadata.id,
    booking_codes: selected_rooms.map((room) => room.booking_code),
    destOrder: accommodation.destOrder,
    supplier: selected_rooms[0].supplier,
    uids: selected_rooms.map((room) => room.uid),
  };

  return { accommodation, payloads: [payload] };
};

export const accSetCustomerRemarks = (destOrder, remarks) => {
  return {
    type: TRIPPLANNER_ACCOMMODATION_SET_CUSTOMER_REMARKS,
    destOrder,
    remarks,
  };
};
