import { serviceFiltersChange } from "@src/actions/Project/TripPlanner";

import { useDispatch, useSelector } from "react-redux";
import React, { useCallback } from "react";
import { createUseStyles } from "react-jss";
import { variables } from "@src/jsssetup";
import { getServicesListSelector } from "@src/selectors/Project/TripPlanner/generic_service_selectors";

const serviceMapping = {
  ACC: "Accommodation",
  FL: "Flights",
  FER: "Ferries",
  TRA: "Trains",
  TR: "Transportation",
  TRF: "Transfers",
  MI: "Add Ons",
  REST: "Restaurants",
  COO: "Coordinators",
  GEN: "General Services",
  AD: "Ad hoc Land Services",
  ACT: "Activities",
};

export function overviewCtrlSectionStyles() {
  return {
    "position": "relative",
    "display": "grid",
    "padding": variables.normal_gap,
    "gridTemplateRows": "min-content",
    "&::after": {
      content: "''",
      position: "absolute",
      top: "0.5rem",
      right: 0,
      bottom: 0,
      borderRight: "1px solid #E4E4E4",
      height: "90%",
    },
    "&:last-child": {
      borderRight: "unset",
    },
  };
}

export function overviewCtrlSectionHeaderStyles() {
  return {
    // background: "white",
    // border: `2px solid ${variables.colors.borders.base}`,
    borderRadius: `calc(${variables.normal_gap} / 2)`,
    textAlign: "center",
    justifySelf: "center",
    alignSelf: "center",
    padding: ["0", variables.normal_gap],
    position: "absolute",
    top: 0,
    transform: "translateY(-120%)",
    fontWeight: "600",
    fontSize: "large",
  };
}

export function topControlsOptionBtnStyles() {
  return {
    "padding": `2px calc(${variables.normal_gap} / 2)`,
    "border": `2px solid ${variables.colors.easy.orange}`,
    "borderRadius": "5px",
    "transition": "color .3s, background .3s",
    "cursor": "pointer",
    "background": "unset",
    "fontWeight": "600",
    "color": variables.colors.text.colored,
    "&:hover": {
      color: "white",
      background: variables.colors.easy.orange,
    },
  };
}

export function topControlsSelectedOptionBtnStyles() {
  return {
    color: "white",
    background: `${variables.colors.easy.orange}!important`,
  };
}

const styles = createUseStyles({
  ServicesFilter: overviewCtrlSectionStyles(),
  header: overviewCtrlSectionHeaderStyles(),
  optionBtn: topControlsOptionBtnStyles(),
  selectedOptionBtn: topControlsSelectedOptionBtnStyles(),
  optionsContainer: {
    "display": "grid",
    "gridTemplateColumns": "repeat(2, max-content)",
    "gridGap": `calc(${variables.normal_gap} / 2)`,
    "& .rs-picker-default": {
      gridColumn: "span 2",
    },
  },
  optionsContainerHeader: {
    justifySelf: "center",
    gridColumn: "span 2",
    fontWeight: "bold",
  },
});
const ServicesFilter = () => {
  const filters = useSelector((state) => state.tripPlannerServiceFilters);
  const services_list = useSelector((state) => getServicesListSelector(state));

  const service_types = [
    ...new Set(services_list.map((srv) => srv.service_type)),
  ];

  const dispatch = useDispatch();
  const onChange = useCallback(
    (name, value) => dispatch(serviceFiltersChange(name, value)),
    [dispatch]
  );

  const classes = styles();

  return (
    <div className={classes.ServicesFilter}>
      <div className={classes.header}>FILTERS</div>
      <div className={classes.optionsContainer}>
        <span className={classes.optionsContainerHeader}>
          Service Categories
        </span>
        {service_types.map((srvtype, idx) => {
          const checked = filters.service_types.includes(srvtype);
          return (
            <button
              key={idx}
              className="Button"
              data-ghost={!checked}
              data-size="xs"
              data-full-width="true"
              onClick={() =>
                onChange(
                  "service_types",
                  checked
                    ? [...filters.service_types].filter((e) => e !== srvtype)
                    : [...filters.service_types, ...[srvtype]]
                )
              }>
              {serviceMapping[srvtype]}
            </button>
          );
        })}
      </div>
    </div>
  );
};
export default ServicesFilter;
