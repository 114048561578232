import _ from "lodash";
import {
  TRIPPLANNER_INIT,
  TRIPPLANNER_LOAD_TRIP,
} from "@src/actions/Project/TripPlanner/types";
import {
  TRIPPLANNER_ADHOC_TRF_SRV_ADD,
  TRIPPLANNER_ADHOC_TRF_SRV_REMOVE,
  TRIPPLANNER_CLEAR_TRANSFERS,
  TRIPPLANNER_SAVE_TRANSFER,
} from "@src/actions/Project/TripPlanner/types/transfers";

export const tripPlannerTransferData = (state = {}, action) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return {};
    case TRIPPLANNER_LOAD_TRIP:
      return action.data.transfer_data ?? {};
    case TRIPPLANNER_SAVE_TRANSFER: {
      const { destOrder, trfType, transfer } = action;
      const newS = _.cloneDeep(state);

      transfer.selected = true;
      if (!transfer?.payload) {
        transfer.payload = newS?.[destOrder]?.[trfType]?.[0]?.payload;
      }

      newS[destOrder] = { ...(newS?.[destOrder] ?? {}), [trfType]: [transfer] };
      return newS;
    }
    case TRIPPLANNER_CLEAR_TRANSFERS:
      return {};
    default:
      return state;
  }
};

export const tripPlanTransferAddhocServices = (state = [], action) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return [];
    case TRIPPLANNER_LOAD_TRIP: {
      const { data } = action;
      return _.get(data, "trf_addhoc_srv_data", []) || [];
    }
    case TRIPPLANNER_ADHOC_TRF_SRV_ADD: {
      const { srv } = action;
      return [...state, srv];
    }
    case TRIPPLANNER_ADHOC_TRF_SRV_REMOVE: {
      const { srv_uid } = action;
      const newState = _.cloneDeep(state);
      return newState.filter((srv) => srv.uid !== srv_uid);
    }
    default:
      return state;
  }
};
