import React from "react";
import { createUseStyles } from "react-jss";
import { GeneralStats } from "../GeneralStats";
import CreatePieChart from "../../ChartComponents/CreatePieChart";
import CreateBarChart from "../../ChartComponents/CreateBarChart";
import { variables } from "@src/jsssetup";
import PropTypes from "prop-types";
import _, { capitalize } from "lodash";
import TransTxt from "@src/components/common/SxFormatMessage";
import { injectIntl } from "react-intl";

const salesReportTable = createUseStyles({
  SalesReportTable: {
    display: "grid",
    gridTemplateRows: "max-content",
  },
  tableContainer: {
    display: "grid",
    border: `2px solid #FF6600`,
    borderRadius: "10px",
    textAlign: "center",
  },
  table: {
    width: "100%",
  },
  tHead: {
    backgroundColor: "#FF6600",
  },
  headRow: {
    "background": "#FF6600",
    "& th:first-child": {
      borderTopLeftRadius: "10%",
    },
    "& th:last-child": {
      borderTopRightRadius: "10%",
    },
  },
  headCell: {
    backgroundColor: "#FF6600",
    textAlign: "center",
    color: "white",
    padding: variables.half_gap,
    fontFamily: "futura,sans-serif",
    fontWeight: "400",
  },
  tableCell: {
    textAlign: "center",
    padding: `${variables.half_gap} ${variables.normal_gap}`,
    fontFamily: "futura,sans-serif",
    height: "2rem",
  },
});

const SalesReportTable = ({ data }) => {
  const classes = salesReportTable();

  return (
    <div className={classes.SalesReportTable}>
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead className={classes.tHead}>
            <tr className={classes.headRow}>
              <th className={classes.headCell}>
                <TransTxt id="B2B_stats_ACC_sales_reports__count" />
              </th>
              <th className={classes.headCell}>
                <TransTxt id="B2B_stats_ACC_sales_reports__revenues" />
              </th>
              <th className={classes.headCell}>
                <TransTxt id="B2B_stats_ACC_sales_reports__average" />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={classes.tableCell}>
                {_.get(data, "sales_reports.count") !== 0
                  ? _.get(data, "sales_reports.count") || "N/A"
                  : "0"}
              </td>
              <td className={classes.tableCell}>
                {_.get(data, "sales_reports.revenues.average_revenues") !== 0
                  ? _.get(data, "sales_reports.revenues.currency")
                  : ""}{" "}
                {_.get(data, "sales_reports.revenues.average_revenues") !== 0
                  ? _.get(data, "sales_reports.revenues.average_revenues")
                  : "0"}
              </td>
              <td className={classes.tableCell}>
                {_.get(data, "sales_reports.revenues.total_revenues") !== 0
                  ? _.get(data, "sales_reports.revenues.currency")
                  : ""}{" "}
                {_.get(data, "sales_reports.revenues.total_revenues") !== 0
                  ? _.get(data, "sales_reports.revenues.total_revenues")
                  : "0"}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
SalesReportTable.propTypes = {
  data: PropTypes.object.isRequired,
};

const accSalesReportChartsyles = createUseStyles({
  ACCSalesReportCharts: {
    display: "grid",
    padding: variables.normal_gap,
  },
  statsTable: {
    display: "grid",
    justifyItems: "center",
  },
  charts: {
    display: "grid",
    gridAutoFlow: "column",
    justifyItems: "center",
  },
  chart1: {
    display: "grid",
  },
  chart2: {
    display: "grid",
  },
  chart3: {
    display: "grid",
  },
  divider: {
    justifySelf: "center",
    alignSelf: "center",
    height: "85%",
    border: `1px solid rgba(0, 0, 0, 0.1)`,
    gridColumn: "1 span",
    width: "0%",
  },
});

var ACCSalesReportCharts = ({ intl, data }) => {
  const classes = accSalesReportChartsyles();

  const PieChartColors = ["#B174FF", "#FF6600"];

  const barChartData = _.get(data, "sales_reports.entities_per_month").map(
    ([date, value]) => ({
      name: date,
      value,
    })
  );
  const salesFlowChartColors = [
    "#FF6600",
    "#FF6600",
    "#FF6600",
    "#FF6600",
    "#FF6600",
    "#FF6600",
    "#FF6600",
    "#FF6600",
    "#FF6600",
    "#FF6600",
    "#FF6600",
    "#FF6600",
    "#FF6600",
    "#FF6600",
  ];

  const pieChartData = Object.entries(
    _.get(data, "sales_reports.cxl_type")
  ).map(([name, value]) => ({
    name,
    value,
  }));

  const total = pieChartData.reduce((acc, item) => acc + item.value, 0);

  const pieChartPercentages = pieChartData.map((item) => ({
    name: capitalize(item.name).replace(/_/g, " "),
    value: ((item.value / total) * 100).toFixed(2),
  }));

  return (
    <div className={classes.ACCSalesReportCharts}>
      <div className={classes.stats}>
        <GeneralStats
          title={intl.formatMessage({
            id: "B2B_stats_ACC_sales_reports__sales_report",
          })}
          tableTitle={intl.formatMessage({
            id: "B2B_stats_ACC_sales_reports__confirmed_reservations",
          })}
          startDate={_.get(data, "period.start_date")}
          endDate={_.get(data, "period.end_date")}
        />
        <div className={classes.statsTable}>
          <SalesReportTable data={data} />
        </div>
      </div>
      <div className={classes.charts}>
        <div className={classes.chart2}>
          <CreateBarChart
            title={intl.formatMessage({
              id: "B2B_stats_ACC_sales_reports__sales_sales_flow",
            })}
            width={34}
            barWidth={"90%"}
            seriesLabelShow={false}
            xAxisColor="#FF6600"
            predefinedColors={salesFlowChartColors}
            data={barChartData}
          />
        </div>
        <div className={classes.divider}></div>
        <div className={classes.chart1}>
          <CreatePieChart
            title={intl.formatMessage({
              id: "B2B_stats_ACC_sales_reports__cxl_type",
            })}
            bottom={55}
            colors={PieChartColors}
            data={pieChartPercentages}
          />
        </div>
      </div>
    </div>
  );
};
ACCSalesReportCharts.propTypes = {
  data: PropTypes.object.isRequired,
  intl: PropTypes.object,
  dashboardPayload: PropTypes.object.isRequired,
};
ACCSalesReportCharts = injectIntl(ACCSalesReportCharts);
export default ACCSalesReportCharts;
