import { itnDestStayChange } from "@src/actions/Project/TripPlanner";
import {
  itnDestManualDateChange,
  itnDestManualDateReset,
  itnInitialDestDateChange,
} from "@src/actions/Project/TripPlanner/step_one";
import { formStyles, variables } from "@src/jsssetup";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import { NormalRangePicker } from "@src/components/forms";
import { Button, Icon, IconButton, Slider } from "rsuite";
import { DateTime } from "luxon";
import TransTxt from "@src/components/common/SxFormatMessage";
import { getSetupFormDataSelector } from "@src/selectors/Project/TripPlanner";

const staySliderStyles = createUseStyles({
  StaySlider: {
    display: "grid",
    gridTemplateColumns: "max-content 1fr max-content",
    gridGap: variables.normal_gap,
    alignItems: "center",
  },
});
const StaySlider = ({ destination, stay, setStay }) => {
  const classes = staySliderStyles();

  function handleIncreaseStay() {
    if (stay == 30) {
      return;
    }

    setStay((p) => p + 1);
  }

  function handleDecreaseStay() {
    if (stay == 1) {
      return;
    }

    setStay((p) => p - 1);
  }

  function handleStayChange(value) {
    setStay(value);
  }

  return (
    <div className={classes.StaySlider}>
      <IconButton
        icon={<Icon icon="minus" />}
        circle
        color="blue"
        size="xs"
        onClick={handleDecreaseStay}
      />
      <Slider
        progress={true}
        min={0}
        max={90}
        value={destination.stay}
        onChange={handleStayChange}
      />
      <IconButton
        icon={<Icon icon="plus" />}
        circle
        color="blue"
        size="xs"
        onClick={handleIncreaseStay}
      />
    </div>
  );
};
StaySlider.propTypes = {
  destination: PropTypes.object.isRequired,
  stay: PropTypes.number.isRequired,
  setStay: PropTypes.func.isRequired,
};

const manualDatesFormStyles = createUseStyles({
  ManualDatesForm: {
    ...formStyles.form,
    display: "grid",
    gridGap: variables.half_gap,
    alignItems: "end",
  },
  actions: {
    display: "grid",
    gridGap: variables.half_gap,
    gridTemplateColumns: "repeat(2, 1fr)",
  },
});
const ManualDatesForm = ({ destination, onExitEdit }) => {
  const destOrder = destination.order;
  const classes = manualDatesFormStyles();

  const dispatch = useDispatch();
  const onManualDatesChange = useCallback(
    ({ destOrder, checkIn, checkOut }) => {
      dispatch(itnDestManualDateChange({ destOrder, checkIn, checkOut }));
    },
    [dispatch]
  );

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        checkIn: destination.checkIn,
        checkOut: destination.checkOut,
      }}
      onSubmit={({ checkIn, checkOut }) => {
        onManualDatesChange({ destOrder, checkIn, checkOut });
        onExitEdit();
      }}>
      <Form className={classes.ManualDatesForm}>
        <NormalRangePicker
          label="Manual Check In/Check Out"
          from_key="checkIn"
          to_key="checkOut"
          min_date={DateTime.now().toJSDate()}
        />
        <div className={classes.actions}>
          <Button appearance="ghost" onClick={onExitEdit}>
            <strong>Cancel</strong>
          </Button>
          <Button type="submit" appearance="primary">
            <strong>Apply</strong>
          </Button>
        </div>
      </Form>
    </Formik>
  );
};
ManualDatesForm.propTypes = {
  destination: PropTypes.object.isRequired,
  onExitEdit: PropTypes.func.isRequired,
};

const initialDestDatesFormStyles = createUseStyles({
  InitialDestDatesForm: {
    ...formStyles.form,
    display: "grid",
    gridTemplateColumns: "1fr max-content",
    gridGap: variables.half_gap,
    alignItems: "end",
  },
  actions: {
    display: "grid",
    gridTemplateColumns: "repeat(2, max-content)",
    gridGap: variables.half_gap,
  },
});
const InitialDestDatesForm = ({ destination }) => {
  const classes = initialDestDatesFormStyles();

  const [edit, setEdit] = useState(false);

  const dispatch = useDispatch();
  const onInitialDateChange = useCallback(
    ({ checkIn, checkOut }) => {
      dispatch(itnInitialDestDateChange({ checkIn, checkOut }));
    },
    [dispatch]
  );

  return !edit ? (
    <DestinationDatesInfo
      checkIn={destination.checkIn}
      checkOut={destination.checkOut}
      onEdit={() => setEdit(true)}
    />
  ) : (
    <Formik
      enableReinitialize={true}
      validateOnBlur={false}
      validateOnMount={false}
      initialValues={{
        checkIn: destination.checkIn,
        checkOut: destination.checkOut,
      }}
      onSubmit={({ checkIn, checkOut }) => {
        onInitialDateChange({ checkIn, checkOut });
        setEdit(false);
      }}>
      <Form className={classes.InitialDestDatesForm}>
        <NormalRangePicker
          label="Check In/Check Out"
          from_key="checkIn"
          to_key="checkOut"
          min_date={DateTime.now().toISODate()}
        />
        <div className={classes.actions}>
          <Button appearance="ghost" onClick={() => setEdit(false)}>
            <strong>Cancel</strong>
          </Button>
          <Button appearance="primary" type="submit">
            <strong>Apply</strong>
          </Button>
        </div>
      </Form>
    </Formik>
  );
};
InitialDestDatesForm.propTypes = { destination: PropTypes.object.isRequired };

const destinationDatesInfoStyles = createUseStyles({
  datesHeader: {
    borderBottom: `2px solid ${variables.colors.easy.lightOrange2}`,
    display: "grid",
    gridTemplateColumns: "1fr max-content",
    paddingBottom: variables.half_gap,
    alignItems: "end",
  },
  datesFromTo: {
    display: "grid",
    gridAutoFlow: "column",
    gridGap: variables.half_gap,
    justifyContent: "start",
  },
});
const DestinationDatesInfo = ({ checkIn, checkOut, onEdit }) => {
  const classes = destinationDatesInfoStyles();
  return (
    <React.Fragment>
      <span className={classes.datesHeader}>
        <strong>
          <TransTxt id="tripplan_dates" startCase />
        </strong>
        {typeof onEdit === "function" && (
          <IconButton
            icon={<Icon icon="edit" />}
            circle
            size="xs"
            appearance="primary"
            onClick={onEdit}
          />
        )}
      </span>
      <div className={classes.datesFromTo}>
        <span>
          <strong>From:</strong>
        </span>
        <span>
          <strong>
            {DateTime.fromISO(checkIn).toLocaleString(
              DateTime.DATE_MED_WITH_WEEKDAY
            )}
          </strong>
        </span>
        <span>
          <strong>To:</strong>
        </span>
        <span>
          <strong>
            {DateTime.fromISO(checkOut).toLocaleString(
              DateTime.DATE_MED_WITH_WEEKDAY
            )}
          </strong>
        </span>
      </div>
    </React.Fragment>
  );
};
DestinationDatesInfo.propTypes = {
  checkIn: PropTypes.string.isRequired,
  checkOut: PropTypes.string.isRequired,
  onEdit: PropTypes.func,
};

const destFormStyles = createUseStyles({
  DestForm: {
    display: "grid",
    alignContent: "start",
    gridGap: variables.half_gap,
    gridAutoRows: "max-content",
  },
  dates: { display: "grid", gridGap: `calc(${variables.normal_gap} / 2)` },
  stayContainer: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
  },
  initialDestForm: {
    ...formStyles.form,
    display: "grid",
    gridTemplateColumns: "1fr max-content",
    gridGap: variables.half_gap,
    alignItems: "end",
  },
  actions: {
    display: "grid",
    gridGap: variables.half_gap,
    gridTemplateColumns: "repeat(2, 1fr)",
  },
});
export const DestinationForm = ({
  destIdx,
  datePickerEnabled,
  destOrder,
  dest_type,
}) => {
  const classes = destFormStyles();
  const destination = useSelector((state) =>
    state.tripPlannerDestinations.find((dest) => dest.order == destOrder)
  );
  const { requiredServices } = useSelector((state) => {
    const { requiredServices } = getSetupFormDataSelector(state);
    return { requiredServices };
  });

  const [editManualDates, setEditManualDates] = useState(false);
  const [stay, setStay] = useState(destination?.stay ?? 0);

  const dispatch = useDispatch();
  const onManualDatesReset = useCallback(
    ({ destOrder }) => {
      dispatch(itnDestManualDateReset({ destOrder }));
    },
    [dispatch]
  );
  const onStayChange = useCallback(
    ({ stay, destOrder }) => {
      dispatch(itnDestStayChange({ stay, destOrder }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (isNaN(stay)) {
      return;
    }

    onStayChange({ stay, destOrder });
  }, [stay]);

  const showSlider =
    dest_type !== "LAY" &&
    !destination?.manualDates &&
    (destOrder !== 1 || requiredServices.includes("TR"));

  return (
    <div className={classes.DestForm}>
      {editManualDates ? (
        <ManualDatesForm
          destination={destination}
          onExitEdit={() => setEditManualDates(false)}
        />
      ) : (
        <React.Fragment>
          {datePickerEnabled ? (
            <InitialDestDatesForm destination={destination} />
          ) : (
            <DestinationDatesInfo
              checkIn={destination.checkIn}
              checkOut={destination.checkOut}
            />
          )}
          <div className={classes.stayContainer}>
            <span>
              <strong>
                <TransTxt id="tripplan_stay" startCase />: {destination.stay}{" "}
                {destination.stay > 1 ? (
                  <TransTxt id="nights" startCase />
                ) : (
                  <TransTxt id="night" startCase />
                )}
              </strong>
            </span>
            {showSlider && (
              <StaySlider
                destination={destination}
                stay={stay}
                setStay={setStay}
              />
            )}
          </div>
          {destIdx === 0 ? null : destination?.manualDates ?? false ? (
            <div className={classes.actions}>
              <Button
                appearance="ghost"
                size="xs"
                onClick={() => onManualDatesReset({ destOrder })}>
                <strong>Reset Manual Dates</strong>
              </Button>
              <Button
                appearance="primary"
                size="xs"
                onClick={() => setEditManualDates(true)}>
                <strong>Edit Manual Dates</strong>
              </Button>
            </div>
          ) : (
            <Button
              appearance="primary"
              size="xs"
              onClick={() => setEditManualDates(true)}>
              <strong>Set Manual Dates</strong>
            </Button>
          )}
        </React.Fragment>
      )}
    </div>
  );
};
DestinationForm.propTypes = {
  destIdx: PropTypes.number.isRequired,
  dest_type: PropTypes.oneOf(["NOR", "LAY", "NON"]).isRequired,
  destOrder: PropTypes.number.isRequired,
  datePickerEnabled: PropTypes.bool.isRequired,
};
