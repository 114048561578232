import { TRIPPLANNER_SAVE_SERVICE_BOOKING_METADATA } from "./types";

export function saveServiceRemarks({ uid, srv_type, booking_metadata }) {
  return {
    type: TRIPPLANNER_SAVE_SERVICE_BOOKING_METADATA,
    uid,
    srv_type,
    booking_metadata,
  };
}
