import {
  CUSTOM_HOTEL_INIT,
  CUSTOM_HOTEL_INIT_FROM_DATA,
  CUSTOM_HOTEL_NEW_HOTEL,
  CUSTOM_HOTEL_FULL_META_CHANGE,
  CUSTOM_HOTEL_META_CHANGE,
  CUSTOM_HOTEL_IMAGE_CHANGE,
  CUSTOM_HOTEL_ADD_ROOM,
  CUSTOM_HOTEL_REMOVE_ROOM,
  CUSTOM_HOTEL_CLONE_ROOM,
  CUSTOM_HOTEL_ROOM_CHANGE,
} from "@src/actions/Project/CustomHotel/types";

import update from "immutability-helper";

const metadataTemplate = {
  is_custom: false,
  id: null,
  name: "",
  rating: 5,
  images: [
    { url: "", main: true },
    { url: "", main: false },
    { url: "", main: false },
    { url: "", main: false },
  ],
  geodata: { lng: 0, lat: 0 },
  description: "",
  rich_description: "",
  full_description: "",
  rich_full_description: "",
  facilities: [],
  address: "",
  phones: "",
};

const roomTemplate = {
  id: "",
  supplier_accommodation_id: "",
  booking_code: "",
  requirements: {
    prebook: false,
    fetch_cxl: false,
  },
  board_code: "",
  board_display: "",
  supplier: "",
  provider: "",
  price: {
    value: 0,
    value_per_night: 0,
    currency: "EUR",
  },
  price_per_room: false,
  name: "",
  type: "",
  bedding: "",
  images: [],
  description: "",
  cancellation_policies: [],
  quantity: { min: 0, max: 0 },
  availability: "on-request", // available on-request
  pax: {
    adults: 2,
    children: 0,
    children_ages: [0],
  },
};

const hotelInitial = {
  custom_id: null,
  metadata: metadataTemplate,
  rooms: [roomTemplate],
};
export const customHotel = (state = hotelInitial, action) => {
  switch (action.type) {
    case CUSTOM_HOTEL_INIT:
      return hotelInitial;
    case CUSTOM_HOTEL_INIT_FROM_DATA:
      return action.data;
    case CUSTOM_HOTEL_NEW_HOTEL:
      return update(state, { metadata: { $set: metadataTemplate } });
    case CUSTOM_HOTEL_FULL_META_CHANGE:
      return update(state, { metadata: { $set: action.meta } });
    case CUSTOM_HOTEL_META_CHANGE:
      return update(state, {
        metadata: { [action.name]: { $set: action.value } },
      });

    case CUSTOM_HOTEL_IMAGE_CHANGE:
      return update(state, {
        metadata: {
          images: {
            [action.idx]: { $set: action.image },
          },
        },
      });
    case CUSTOM_HOTEL_ADD_ROOM:
      return update(state, { rooms: { $push: [roomTemplate] } });
    case CUSTOM_HOTEL_REMOVE_ROOM:
      return update(state, {
        rooms: {
          $apply: (rooms) =>
            rooms.filter((r, idx) =>
              !action.idx ? idx + 1 < rooms.length : idx !== action.idx
            ),
        },
      });
    case CUSTOM_HOTEL_CLONE_ROOM: {
      const room = state.rooms[action.idx];
      return update(state, { rooms: { $push: [room] } });
    }
    case CUSTOM_HOTEL_ROOM_CHANGE: {
      const { idx, name, value } = action;
      return update(state, { rooms: { [idx]: { [name]: { $set: value } } } });
    }
    default:
      return state;
  }
};
