import { StyleSheet, Text, View, Font } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import React from "react";
import PDFImage from "./PDFImage";
import NotoSansRegular from "@src/style/fonts/NotoSans-Regular.ttf";
import NotoSansBold from "@src/style/fonts/NotoSans-Bold.ttf";

Font.register({
  family: "Noto Sans",
  fonts: [
    { src: NotoSansRegular, fontWeight: "normal" },
    { src: NotoSansBold, fontWeight: "bold" },
  ],
});

const styles = StyleSheet.create({
  DocHeader: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: { fontFamily: "Noto Sans", fontWeight: "bold" },
  logoContainer: {
    height: "40",
    width: "200",
    flexDirection: "row",
    alignItems: "center",
  },
});
const DocHeader = ({ logoSrc }) => {
  return (
    <View style={styles.DocHeader}>
      <Text style={styles.title}>Invoice</Text>
      <View style={styles.logoContainer}>
        <PDFImage src={logoSrc} />
      </View>
    </View>
  );
};
DocHeader.defaultProps = { logoSrc: "" };
DocHeader.propTypes = { logoSrc: PropTypes.string.isRequired };
export default DocHeader;
