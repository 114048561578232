import _ from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { connect } from "react-redux";
import {
  changePricingPrice,
  resetPricingPrices,
  copyPricingPrices,
  changeBaseInfo,
  changeCxlFee,
  changeCxl,
  resetPricingCxl,
  copyPricingCxl,
} from "@src/actions/Operation/CustomServices/AddOn";
import PricingTable from "../Components/PricingTable";
import CxlTable from "../Components/CxlTable";
import { showPaxScallingModal } from "@src/actions/Operation/CustomServices/AddOn";
import StopSales from "../Components/StopSales";
import { createUseStyles } from "react-jss";
import { variables } from "@src/jsssetup";
// import OffersTable from "../Components/OffersTable";
import RoomsTable from "../Components/pricing/RoomsTable";
import { PaymentRules } from "../Components/PaymentRules";
import ExtraCharges from "../Components/pricing/ExtraCharges";
import PriceItems from "../Components/PriceItems";
import { CustomButton } from "@src/components/common/buttons";
import { WhisperWrapper } from "@src/components/common/ui_helpers";

const styles = createUseStyles({
  FinalPricing: {
    display: "grid",
    gridGap: variables.normal_gap,
    padding: variables.normal_gap,
  },
  pricingActions: {
    display: "grid",
    gridAutoFlow: "column",
    gridAutoColumns: "max-content",
    justifyItems: "start",
    gridGap: variables.normal_gap,
  },
});
const FinalPricing = ({
  currencies,
  companyCurrency,
  price_type,
  distribution_periods,
  pax_groups,
  meeting_groups,
  rooms,
  onPriceChange,
  onPricingReset,
  onPricingCopy,
  onPriceTypeChange,
  onCxlFeeChange,
  onCxlChange,
  onCxlReset,
  onCxlCopy,
  onShowPaxScalling,
}) => {
  // old_pricing, pricing, cancelation_policy, stop_sales, payment_rules
  const [activeView, setActiveView] = useState("pricing");
  distribution_periods = [
    ...distribution_periods.map((p, distIdx) => ({ ...p, distIdx })),
  ];

  const classes = styles();

  return (
    <div className={classes.FinalPricing}>
      <div className={classes.pricingActions}>
        <CustomButton
          appearance={activeView === "old_pricing" ? "primary" : "ghost"}
          onClick={() => setActiveView("old_pricing")}>
          <WhisperWrapper
            msg="This function will  be deprecated by 31st December 2023"
            placement="bottom">
            <span>Old Pricing</span>
          </WhisperWrapper>
        </CustomButton>
        {["pricing", "cancelation_policy", "stop_sales", "payment_rules"].map(
          (opt, idx) => (
            <CustomButton
              key={idx}
              appearance={activeView === opt ? "primary" : "ghost"}
              onClick={() => setActiveView(opt)}>
              {_.startCase(opt)}
            </CustomButton>
          )
        )}
      </div>
      {activeView === "old_pricing" && (
        <React.Fragment>
          <PricingTable
            rooms={rooms}
            distribution_periods={distribution_periods}
            price_type={price_type}
            currencies={currencies}
            companyCurrency={companyCurrency}
            pax_groups={pax_groups}
            meeting_groups={meeting_groups}
            onPricingReset={onPricingReset}
            onPricingCopy={onPricingCopy}
            onPriceTypeChange={onPriceTypeChange}
            onPriceChange={onPriceChange}
            onShowPaxScalling={onShowPaxScalling}
          />
          <RoomsTable />
          <ExtraCharges />
        </React.Fragment>
      )}
      {activeView === "pricing" &&
        distribution_periods.map((period, idx) => (
          <PriceItems
            key={idx}
            distribution_periods={distribution_periods}
            period={period}
            periodIdx={idx}
          />
        ))}
      {activeView === "cancelation_policy" && (
        <CxlTable
          currencies={currencies}
          companyCurrency={companyCurrency}
          distribution_periods={distribution_periods}
          onFeeChange={onCxlFeeChange}
          onCxlChange={onCxlChange}
          onReset={onCxlReset}
          onCopy={onCxlCopy}
        />
      )}
      {activeView === "stop_sales" && <StopSales />}
      {activeView === "payment_rules" && <PaymentRules />}
      {/* <OffersTable /> */}
    </div>
  );
};
FinalPricing.propTypes = {
  currencies: PropTypes.array.isRequired,
  companyCurrency: PropTypes.string.isRequired,
  price_type: PropTypes.oneOf(["per_person", "toatal"]).isRequired,
  distribution_periods: PropTypes.array.isRequired,
  pax_groups: PropTypes.array.isRequired,
  meeting_groups: PropTypes.array.isRequired,
  rooms: PropTypes.array.isRequired,
  onPriceChange: PropTypes.func.isRequired,
  onPricingReset: PropTypes.func.isRequired,
  onPricingCopy: PropTypes.func.isRequired,
  onPriceTypeChange: PropTypes.func.isRequired,
  onCxlFeeChange: PropTypes.func.isRequired,
  onCxlChange: PropTypes.func.isRequired,
  onCxlReset: PropTypes.func.isRequired,
  onCxlCopy: PropTypes.func.isRequired,
  onShowPaxScalling: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  const { price_type, distribution_periods, pax_groups, rooms } =
    state.customServiceAddOn;
  const meeting_groups = state.customMeetingPointGroups;
  const currencies = state.financialAvailableCurrencies;
  const companyCurrency = state.userMeta.company_currency;

  return {
    currencies,
    companyCurrency,
    price_type,
    distribution_periods,
    pax_groups,
    meeting_groups,
    rooms,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onPriceChange: (
      distIdx,
      meeting_group_uid,
      pax_group_uid,
      price_type,
      value
    ) =>
      dispatch(
        changePricingPrice(
          distIdx,
          meeting_group_uid,
          pax_group_uid,
          price_type,
          value
        )
      ),
    onPricingReset: (distIdx) => dispatch(resetPricingPrices(distIdx)),
    onPricingCopy: (distIdx) => dispatch(copyPricingPrices(distIdx)),
    onPriceTypeChange: (price_type) =>
      dispatch(changeBaseInfo("price_type", price_type)),
    onCxlFeeChange: (distIdx, value) => dispatch(changeCxlFee(distIdx, value)),
    onCxlChange: (distIdx, key, value) =>
      dispatch(changeCxl(distIdx, key, value)),
    onCxlReset: (distIdx) => dispatch(resetPricingCxl(distIdx)),
    onCxlCopy: (distIdx) => dispatch(copyPricingCxl(distIdx)),
    onShowPaxScalling: () => dispatch(showPaxScallingModal()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FinalPricing);
