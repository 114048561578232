import _ from "lodash";
import {
  TRIPPLANNER_INIT,
  TRIPPLANNER_LOAD_TRIP,
} from "@src/actions/Project/TripPlanner/types";

import {
  TRIPPLANNER_RESET_FLIGHT_SESSION_DATA,
  TRIPPLANNER_SAVE_FLIGHT_DATA,
  TRIPPLANNER_SAVE_FLIGHT_SESSION_DATA,
} from "@src/actions/Project/TripPlanner/flights/index.js";
import { TRIPPLANNER_REMOVE_FLIGHT_DATA } from "@src/actions/Project/TripPlanner/flights";

const tripPlannerFlightsReducerInitial = { services: [], sessions: [] };
export const tripPlannerFlightsReducer = (
  state = tripPlannerFlightsReducerInitial,
  action
) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return tripPlannerFlightsReducerInitial;
    case TRIPPLANNER_LOAD_TRIP: {
      return (
        action?.data?.transportation_data?.flights ??
        tripPlannerFlightsReducerInitial
      );
    }
    case TRIPPLANNER_RESET_FLIGHT_SESSION_DATA: {
      return tripPlannerFlightsReducerInitial;
    }
    case TRIPPLANNER_SAVE_FLIGHT_SESSION_DATA: {
      const { session_data } = action;
      const newState = _.cloneDeep(state);
      newState.sessions.push(session_data);
      return newState;
    }
    case TRIPPLANNER_SAVE_FLIGHT_DATA: {
      const { flight } = action;
      const newState = _.cloneDeep(state);
      if (!newState.services.map((fl) => fl.uid).includes(flight.uid)) {
        newState.services.push(flight);
      }

      return newState;
    }
    case TRIPPLANNER_REMOVE_FLIGHT_DATA: {
      const { flightUid } = action;
      const newState = _.cloneDeep(state);
      newState.services = newState.services.filter(
        (srv) => srv.uid !== flightUid
      );
      return newState;
    }
    default:
      return state;
  }
};
