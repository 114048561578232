import { commonList } from "@src/reducers/common";
import { B2B_COACHES_LIST_GENERIC_CHANGE } from "../../../../../actions/Operation/B2BReservations/SingleServices/Coaches/types";

const B2BCoachesListReducerInitial = {
  ...commonList,
  displayLength: 100,
  filters: {
    reference: "",
    external_reference: "",
    source_entity_autocomplete: "",
    source_entity: "",
    company_name: "",
    origin: "",
    destination_name: "",
    created__lte: "",
    created__gte: "",
    creator: "",
    creator_email: "",
    status: "",
    page: 1,
    limit: 20,
    ordering: "",
    tags: "",
  },
};
export const B2BCoachesListReducer = (
  state = B2BCoachesListReducerInitial,
  action
) => {
  switch (action.type) {
    case B2B_COACHES_LIST_GENERIC_CHANGE: {
      const { filters } = action;
      return { ...state, filters: { ...state.filters, ...filters } };
    }
    default:
      return state;
  }
};
