import React from "react";
import TransTxt from "@src/components/common/SxFormatMessage";
import { Tag } from "rsuite";

export const initialMyOffersSentFilterForm = {
  reference: {
    value: "",
    verbose: "Reference",
  },
  trip_title: {
    value: "",
    verbose: <TransTxt id="tripplanlist_title" startCase />,
  },
  request_by: {
    value: "",
    verbose: <TransTxt id="tripplanlist_request_by" startCase />,
  },
  adults: {
    value: "",
    verbose: <TransTxt id="adults" startCase />,
  },
  children: {
    value: "",
    verbose: <TransTxt id="children" startCase />,
  },
  destinations: {
    value: "",
    verbose: <TransTxt id="tripplanlist_destinations" startCase />,
  },
  date: {
    value: [],
    verbose: "Trip Date",
    placement: "auto",
  },
  travel_services: {
    value: [],
    verbose: <TransTxt id="travel_services" startCase />,
    data: [
      {
        value: "TR",
        label: <TransTxt id="transportation" startCase />,
      },
      {
        value: "ACC",
        label: <TransTxt id="accommodation" startCase />,
      },
      {
        value: "TF",
        label: <TransTxt id="transfer" startCase />,
      },
      {
        value: "MI",
        label: <TransTxt id="Add Ons" startCase />,
      },
    ],
    placement: "left",
  },
  pricing: {
    value: "selling",
    verbose: <TransTxt id="pricing" startCase />,
    data: [
      {
        label: <TransTxt id="selling_price" startCase />,
        value: "selling",
      },
      {
        label: <TransTxt id="buying_price" startCase />,
        value: "buying",
      },
    ],
  },
  created_by: {
    value: "",
    verbose: <TransTxt id="created_by" startCase />,
  },
  created: {
    value: [],
    placement: "auto",
    verbose: <TransTxt id="created_date" startCase />,
  },
  recently: {
    value: "0",
    verbose: <TransTxt id="sort_by" startCase />,
    data: [
      {
        value: "0",
        label: (
          <Tag color="green">
            <strong>
              <TransTxt id="date_add(newest)" />
            </strong>
          </Tag>
        ),
      },
      {
        value: "1",
        label: (
          <Tag color="orange">
            <strong>
              <TransTxt id="date_add(oldest)" />
            </strong>
          </Tag>
        ),
      },
    ],
  },
  expired_date: {
    value: [],
    verbose: "Expired Date",
  },
};
