import _ from "lodash";
import { notifyCommonError } from "@src/components/common/notifications/CommonErrorNotification.js";
import {
  fetchCompleteAddress,
  fetchCompleteAddressDetail,
  getRelatedEmployees,
} from "@src/api";
import { notifyImgTooBig } from "@src/components/common/notifications/ImageNotifications";

//fetchGeolocation
export const fetchCompleteAddressFactory = (
  BaseDataForm,
  fetchAddressPointDetail
) => (dispatch) => {
  const address = BaseDataForm;
  let country_code = "";
  if (address.postal_country_code.value) {
    country_code = address.postal_country_code.value.split("-")[1];
  }
  const addressArr = [
    address.postal_street.value,
    address.postal_city.value,
    address.postal_region.value,
    country_code,
  ];
  if (addressArr.join("")) {
    fetchCompleteAddress({ input: addressArr.join(" ") })
      .then((res) => {
        if (!_.isEmpty(res)) {
          dispatch(fetchAddressPointDetail(res[0].place_id));
        } else {
          notifyCommonError(
            "The address you are looking for is not found.Please enter specific street information and click on the map to select the correct location"
          );
        }
      })
      .catch((err) => {
        console.log(err);
        notifyCommonError("You add a wrong address, Please try again");
      });
  }
};

export const fetchAddressPointDetailFactory = (
  data,
  fetchBaseFormType,
  editBaseDataFormType,
  beginAddressPoint,
  endAddressPoint
) => (dispatch) => {
  dispatch(beginAddressPoint());
  fetchCompleteAddressDetail({ place_id: data })
    .then((res) => {
      const data = res.result.geometry.location;
      const viewport = res.result.geometry.viewport;
      const arr = [];
      for (let key in viewport) {
        arr.push([viewport[key].lng, viewport[key].lat]);
      }
      const lat_info = { name: "lat", value: data.lat };
      const lng_info = { name: "lng", value: data.lng };
      const viewportData = { name: "viewport_arr", value: arr };
      dispatch({
        type: fetchBaseFormType,
        data: { ...data, arr },
      });
      dispatch(endAddressPoint());
      dispatch(editBaseDataFormType(lng_info));
      dispatch(editBaseDataFormType(lat_info));
      dispatch(editBaseDataFormType(viewportData));
    })
    .catch((err) => {
      console.log(err);
      notifyCommonError("Failed to get the Detail, Please try again");
    });
};

//upload img
export const changeUploadImgFactory = (data, type) => (dispatch) => {
  const imgName = data.imgUrl.name;
  const res = {
    value: imgName,
    fileName: data.imgUrl,
    name: "original_image",
    id: data.id,
  };

  if (parseInt(data.imgUrl.size / 1024) > 2048) {
    notifyImgTooBig(parseFloat(data.imgUrl.size / (1024 * 1000)).toFixed(2));
    return;
  }
  let reader = new FileReader();
  reader.readAsDataURL(data.imgUrl);
  reader.onload = (event) => {
    res.value = event.target.result;
    dispatch({
      type: type,
      data: res,
    });
  };
};

// getRelatedEmployees
export const getRelatedEmployeesDataFactory = (word, type) => (dispatch) => {
  getRelatedEmployees({
    status__in: "AC",
  })
    .then((res) => {
      dispatch(getRelatedEmployeesDataList(res.results, type));
    })
    .catch((error) => {
      error.ovrdErrHandle && error.ovrdErrHandle();
    });
};

export const getRelatedEmployeesDataList = (data, type) => (dispatch) => {
  dispatch({
    type: type,
    data: _.remove(_.uniqBy(data, "username"), (el) => {
      return el.first_name != "" && el.last_name != "";
    }),
  });
};
