import _ from "lodash";
import { NormalInputField } from "@src/components/forms";
import { cardStyles, tableStyles, variables } from "@src/jsssetup";
import { Form, Formik } from "formik";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { Steps } from "rsuite";
import { CxlPolicies } from "./cxl_policies";
import {
  genericButtonsStyles,
  genericInfoGroupStyles,
  genericRsrvFeebackStyles,
  rsrvFeedbackColorCardStyles,
} from "./styles";
import { basicInfoStylesObj } from "../accommodation/common";

const stepperStyles = createUseStyles({ Stepper: { width: "90vw" } });
export const Stepper = ({ step }) => {
  const classes = stepperStyles();
  return (
    <Steps current={step} className={classes.Stepper}>
      <Steps.Item title="Reservation Information" />
      <Steps.Item title="Contact Information" />
      {/* <Steps.Item title="Attach Document" /> */}
      <Steps.Item title="Overview" />
    </Steps>
  );
};
Stepper.propTypes = {
  step: PropTypes.number.isRequired,
};

export const firstCellStyles = {
  ...tableStyles.cell,
  background: variables.colors.background.base,
  fontWeight: "bold",
  borderRight: `2px solid ${variables.colors.borders.base}`,
};

export const cxlRowStyles = ({ request_cxl_policy }) => ({
  display: "grid",
  gridTemplateColumns: request_cxl_policy ? "4fr 7fr" : "1fr",
  gridGap: variables.normal_gap,
});

const remarkerStyles = createUseStyles({
  ...genericButtonsStyles,
  Remarker: {},
  cell: tableStyles.cell,
  btn: { marginRight: `calc(${variables.normal_gap} / 2)` },
  valueCell: { ...tableStyles.cell, textAlign: "left" },
  infoCoupleTitle: genericInfoGroupStyles.infoCoupleTitle,
  infoCoupleValue: genericInfoGroupStyles.infoCoupleValue,
  buttonsGroup: {
    display: "grid",
    gridTemplateColumns: "repeat(2, max-content)",
    gridGap: variables.half_gap,
    justifyContent: "end",
  },
});
// const remarkerStyles = createUseStyles({
//   Remarker: {},
//   cell: tableStyles.cell,
//   firstCell: firstCellStyles,
//   btn: { marginRight: `calc(${variables.normal_gap} / 2)` },
//   valueCell: { ...tableStyles.cell, textAlign: "left" },
// });
const Remarker = ({
  editable,
  fieldValue,
  fieldKey,
  confirmed,
  onConfirm,
  onCancel,
}) => {
  const [mode, setMode] = useState(confirmed ? "confirmed" : "");

  const classes = remarkerStyles();

  // <tr>
  //   <td className={classes.firstCell}>Reply</td>
  //   <td className={classes.valueCell}>
  //     {editable && !mode && (
  //       <React.Fragment>
  //         <Button
  //           color="blue"
  //           size="xs"
  //           className={classes.btn}
  //           onClick={() => setMode("reply")}>
  //           <strong>Reply</strong>
  //         </Button>
  //         <Button
  //           color="green"
  //           size="xs"
  //           className={classes.btn}
  //           onClick={() => {
  //             setMode("confirmed");
  //             onConfirm();
  //           }}>
  //           <strong>Confirm</strong>
  //         </Button>
  //       </React.Fragment>
  //     )}
  //     {editable && mode === "reply" && (
  //       <React.Fragment>
  //         <NormalInputField name={fieldKey} as="textarea" />
  //         <br />
  //         <Button
  //           className={classes.btn}
  //           size="xs"
  //           appearance="ghost"
  //           onClick={() => {
  //             setMode("");
  //             onCancel();
  //           }}>
  //           Cancel
  //         </Button>
  //         <Button
  //           color="green"
  //           size="xs"
  //           className={classes.btn}
  //           onClick={() => {
  //             setMode("confirmed");
  //             onConfirm();
  //           }}>
  //           <strong>Confirm</strong>
  //         </Button>
  //       </React.Fragment>
  //     )}
  //     {(!editable || mode === "confirmed") && (
  //       <span>{fieldValue || "N/A"}</span>
  //     )}
  //   </td>
  //   {editable && mode === "confirmed" && (
  //     <td>
  //       <Button size="xs" appearance="ghost" onClick={() => setMode("reply")}>
  //         <strong>Edit</strong>
  //       </Button>
  //     </td>
  //   )}
  // </tr>
  return (
    <React.Fragment>
      <div className={classes.infoCoupleTitle}>Request</div>
      <div className={classes.infoCoupleValue}>
        <div className={classes.valueCell}>
          {editable && !mode && (
            <div className={classes.buttonsGroup}>
              <button
                // color="blue"
                // size="xs"
                // className={classes.btn}
                className={classes.replyButton}
                onClick={() => setMode("reply")}>
                {/* <strong> */}
                Reply
                {/* </strong> */}
              </button>
              <button
                // color="green"
                // size="xs"
                // className={classes.btn}
                className={classes.confirmButton}
                onClick={() => {
                  setMode("confirmed");
                  onConfirm();
                }}>
                {/* <strong> */}
                Confirm
                {/* </strong> */}
              </button>
            </div>
          )}
          {editable && mode === "reply" && (
            <React.Fragment>
              <NormalInputField name={fieldKey} as="textarea" />
              <br />
              <div className={classes.buttonsGroup}>
                <button
                  // className={classes.btn}
                  // size="xs"
                  // appearance="ghost"
                  className={classes.cancelButton}
                  onClick={() => {
                    setMode("");
                    onCancel();
                  }}>
                  Cancel
                </button>
                <button
                  // color="green"
                  // size="xs"
                  // className={classes.btn}
                  className={classes.confirmButton}
                  onClick={() => {
                    setMode("confirmed");
                    onConfirm();
                  }}>
                  {/* <strong> */}
                  Confirm
                  {/* </strong> */}
                </button>
              </div>
            </React.Fragment>
          )}
          {(!editable || mode === "confirmed") && (
            <span>{fieldValue || "N/A"}</span>
          )}
        </div>
        {editable && mode === "confirmed" && (
          <div className={classes.buttonsGroup}>
            <button
              // size="xs"
              // appearance="ghost"
              className={classes.cancelButton}
              onClick={() => setMode("reply")}>
              <strong>Edit</strong>
            </button>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
Remarker.propTypes = {
  confirmed: PropTypes.bool.isRequired,
  editable: PropTypes.bool.isRequired,
  fieldValue: PropTypes.string.isRequired,
  fieldKey: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

const serviceStatusStyles = createUseStyles({
  activated: {
    borderRadius: "3px",
    backgroundColor: "white",
    color: variables.colors.easy.orange,
    textAlign: "center",
  },
  deactivated: {
    borderRadius: "3px",
    backgroundColor: "rgb(255,255,255, 0.50)",
    color: "rgb(255,255,255, 0.70)",
    textAlign: "center",
  },
});
const ServiceStatusTag = ({
  target_status,
  target_status_display,
  status,
  status_display,
}) => {
  const classes = serviceStatusStyles();
  return (
    <div
      className={
        status === target_status ? classes.activated : classes.deactivated
      }>
      <strong>
        {status === target_status ? status_display : target_status_display}
      </strong>
    </div>
  );
};
ServiceStatusTag.propTypes = {
  status: PropTypes.string.isRequired,
  status_display: PropTypes.string.isRequired,
  target_status: PropTypes.string.isRequired,
  target_status_display: PropTypes.string.isRequired,
};

const requestStyles = createUseStyles({
  Request: { ...rsrvFeedbackColorCardStyles },
  infoCouple: {
    display: "grid",
    gridTemplateRows: "repeat ( 2, 1fr )",
  },
  infoCoupleTitle: {
    fontSize: "1rem",
  },
  infoCoupleValue: {
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
  statusValues: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridGap: variables.half_gap,
  },
});
// const requestStyles = createUseStyles({
//   Request: { ...cardStyles.card },
//   header: cardStyles.header,
//   body: { ...cardStyles.body },
//   table: tableStyles.table,
//   head: tableStyles.head,
//   cell: tableStyles.cell,
// });
export const RequestInfo = ({ reservation }) => {
  const classes = requestStyles();

  const response_deadline = DateTime.fromISO(reservation.response_deadline);

  const statusTagProps = {
    status: reservation.status,
    status_display: reservation.status_display,
  };
  var statusCouples = [];
  switch (reservation.booking_mode) {
    case "RCO":
      statusCouples = [
        ["RQ", "On Request"],
        ["CO", "Confirmed"],
        ["RCO", "Reconfirmed"],
      ];
      break;
    case "RQ":
      statusCouples = [
        ["RQ", "On Request"],
        ["CO", "Confirmed"],
      ];
      break;
    case "CO":
      statusCouples = [["CO", "Confirmed"]];
      break;
    default:
      statusCouples = [];
      break;
  }

  return (
    <div className={classes.Request}>
      <div className={classes.infoCouple}>
        <span className={classes.infoCoupleTitle}>
          Reservation Confirmation Panel
        </span>
        <span className={classes.infoCoupleValue}>
          {_.get(reservation, "reference") || "N/A"}
        </span>
      </div>
      <div className={classes.infoCouple}>
        <span className={classes.infoCoupleTitle}>Payment Mode</span>
        <span className={classes.infoCoupleValue}>
          {_.get(reservation, "payment_mode_display") || "N/A"}
        </span>
      </div>
      <div className={classes.infoCouple}>
        <span className={classes.infoCoupleTitle}>Response Deadline</span>
        <span className={classes.infoCoupleValue}>
          {response_deadline.isValid
            ? response_deadline.toLocaleString(
                DateTime.DATETIME_MED_WITH_WEEKDAY
              )
            : "N/A"}
        </span>
      </div>
      <div className={classes.infoCouple}>
        <span className={classes.infoCoupleTitle}>Status</span>
        <span className={classes.statusValues}>
          {statusCouples.map(([t, d], idx) => (
            <ServiceStatusTag
              key={idx}
              {...statusTagProps}
              target_status={t}
              target_status_display={d}
            />
          ))}
        </span>
      </div>
    </div>
    // <div className={classes.Request}>
    //    <div className={classes.header}>
    //     <h5>Request Information</h5>
    //   </div>
    //   <div className={classes.body}>
    //     <table className={classes.table}>
    //       <thead className={classes.head}>
    //         <tr>
    //   {/* <th>Booking Mode</th> */}
    //    <th>Payment Mode</th>
    //           <th>Response Deadline</th>
    //           <th>Status</th>
    //         </tr>
    //       </thead>
    //       <tbody>
    //         <tr>
    //           <td className={classes.cell}>
    //             {reservation.payment_mode_display}
    //           </td>
    //           <td className={classes.cell}>
    //             {response_deadline.isValid
    //               ? response_deadline.toLocaleString(
    //                   DateTime.DATETIME_MED_WITH_WEEKDAY
    //                 )
    //               : "N/A"}
    //           </td>
    //           <td className={classes.cell}>
    //             {statusCouples.map(([t, d]) => (
    //               <ServiceStatusTag
    //                 {...statusTagProps}
    //                 target_status={t}
    //                 target_status_display={d}
    //               />
    //             ))}
    //           </td>
    //         </tr>
    //       </tbody>
    //     </table>
    //   </div>
    // </div>
  );
};
RequestInfo.defaultProps = {
  reservation: {},
};
RequestInfo.propTypes = {
  reservation: PropTypes.object.isRequired,
};

const genericRemarksStyles = createUseStyles({
  ...genericInfoGroupStyles,
  GenericRemarks: genericRsrvFeebackStyles.card,
  head: genericRsrvFeebackStyles.cardHeader,
  body: genericRsrvFeebackStyles.cardBody,
  groupCard: basicInfoStylesObj.groupCard,
});
// const genericRemarksStyles = createUseStyles({
//   GenericRemarks: cardStyles.card,
//   head: cardStyles.header,
//   table: tableStyles.table,
//   thead: tableStyles.head,
//   cell: tableStyles.cell,
//   remarksCell: { ...tableStyles.cell, textAlign: "left" },
//   firstCell: firstCellStyles,
// });
const GenericRemarks = ({
  title,
  remarks,
  reply_remarks,
  editable,
  onChange,
}) => {
  const classes = genericRemarksStyles();

  const [init, setInit] = useState({});
  useEffect(() => setInit(reply_remarks), []);

  return _.isEmpty(init) ? null : (
    <div className={classes.GenericRemarks}>
      <div className={classes.head}>
        <h5>{title}</h5>
      </div>
      <Formik
        initialValues={{ ...init }}
        validateOnBlur={false}
        validateOnMount={false}>
        {({ values, setFieldValue }) => (
          <Form className={classes.body}>
            <div className={classes.groupCard}>
              <div
                className={classes.infoCouple}
                dangerouslySetInnerHTML={{ __html: remarks }}
              />
              {/* <div className={classes.infoCoupleTitle}>Request</div>
              <div
                className={classes.infoCoupleValue}
                dangerouslySetInnerHTML={{ __html: remarks }}
              /> */}
              <Remarker
                editable={editable}
                fieldKey="remarks"
                fieldValue={values.remarks}
                confirmed={values.confirmed}
                onConfirm={() => onChange({ ...values, confirmed: true })}
                onCancel={() => {
                  setFieldValue("remarks", "");
                  onChange({ remarks: "", confirmed: false });
                }}
              />
              {/* <table className={classes.table}>
              <thead className={classes.thead}>
                <tr>
                  <th>Type</th>
                  <th>Remarks</th>
                  {values.confirmed ? <th>Actions</th> : <th></th>}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={classes.firstCell}>Request</td>
                  <td
                    className={classes.remarksCell}
                    dangerouslySetInnerHTML={{ __html: remarks }}
                  />
                </tr>
                <Remarker
                  editable={editable}
                  fieldKey="remarks"
                  fieldValue={values.remarks}
                  confirmed={values.confirmed}
                  onConfirm={() => onChange({ ...values, confirmed: true })}
                  onCancel={() => {
                    setFieldValue("remarks", "");
                    onChange({ remarks: "", confirmed: false });
                  }}
                />
              </tbody>
            </table> */}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
GenericRemarks.propTypes = {
  title: PropTypes.string.isRequired,
  remarks: PropTypes.string,
  reply_remarks: PropTypes.object.isRequired,
  reservation_remarks: PropTypes.string,
  editable: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export const GeneralRemarks = ({
  reservation_remarks,
  reply_remarks,
  editable,
  onChange,
}) => {
  return (
    <GenericRemarks
      title="General Remarks"
      remarks={reservation_remarks}
      {...{ reply_remarks, editable, onChange }}
    />
  );
};
GeneralRemarks.propTypes = {
  reply_remarks: PropTypes.object.isRequired,
  reservation_remarks: PropTypes.string,
  editable: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export const PaymentModeRemarks = ({
  payment_mode_remarks,
  reply_remarks,
  editable,
  onChange,
}) => {
  return (
    <GenericRemarks
      title="Payment Mode Remarks"
      remarks={payment_mode_remarks}
      {...{ reply_remarks, editable, onChange }}
    />
  );
};
PaymentModeRemarks.propTypes = {
  reply_remarks: PropTypes.object.isRequired,
  payment_mode_remarks: PropTypes.string,
  editable: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export const BookingModeRemarks = ({
  booking_mode_remarks,
  reply_remarks,
  editable,
  onChange,
}) => {
  return (
    <GenericRemarks
      title="Booking Mode Remarks"
      remarks={booking_mode_remarks}
      {...{ reply_remarks, editable, onChange }}
    />
  );
};
BookingModeRemarks.propTypes = {
  booking_mode_remarks: PropTypes.string,
  reply_remarks: PropTypes.object.isRequired,
  editable: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export const CxlRemarks = ({
  cxl_remarks,
  reply_remarks,
  editable,
  onChange,
}) => {
  return (
    <GenericRemarks
      title="Cancellation Policy Remarks"
      remarks={cxl_remarks}
      {...{ reply_remarks, editable, onChange }}
    />
  );
};
CxlRemarks.propTypes = {
  cxl_remarks: PropTypes.string,
  reply_remarks: PropTypes.object.isRequired,
  editable: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

const actionsStyles = createUseStyles({
  actions: cardStyles.actions,
  nextButton: {
    width: "6rem",
    backgroundColor: variables.colors.easy.orange,
    border: `solid 2px ${variables.colors.easy.orange}`,
    borderRadius: "4px",
    color: "white",
  },
  backButton: {
    width: "6rem",
    backgroundColor: "white",
    border: `solid 2px ${variables.colors.easy.orange}`,
    borderRadius: "4px",
    color: variables.colors.easy.orange,
  },
});
export const Actions = ({
  step,
  prevStepper,
  nextStepper,
  onSubmit,
  onReject,
}) => {
  const classes = actionsStyles();
  return (
    <div className={classes.actions}>
      <button className={classes.backButton} onClick={onReject}>
        <strong>Reject</strong>
      </button>
      {step > 0 && (
        <button className={classes.backButton} onClick={prevStepper}>
          <strong>Back</strong>
        </button>
      )}
      {step === 2 ? (
        <button className={classes.nextButton} onClick={onSubmit}>
          <strong>Confirm</strong>
        </button>
      ) : (
        <button className={classes.nextButton} onClick={nextStepper}>
          <strong>Next</strong>
        </button>
      )}
    </div>
  );
};
Actions.propTypes = {
  step: PropTypes.number.isRequired,
  prevStepper: PropTypes.func.isRequired,
  nextStepper: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
};

const contactInformationStyles = createUseStyles({
  // ...cardStyles,
  // ContactInformation: cardStyles.card,
  ...genericRsrvFeebackStyles,
  ContactInformation: genericRsrvFeebackStyles.card,
  header: genericRsrvFeebackStyles.cardHeader,
  body: genericRsrvFeebackStyles.cardBody,
  col3: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridGap: variables.normal_gap,
  },
  groupCard: basicInfoStylesObj.groupCard,
});
export const ContactInformation = ({
  supplier_name,
  supplier_email,
  supplier_phone,
  supplier_remarks,
  actionProps,
  onChange,
  children,
}) => {
  const classes = contactInformationStyles();
  const [init, setInit] = useState({});
  useState(() => {
    setInit({
      supplier_name,
      supplier_email,
      supplier_phone,
      supplier_remarks,
    });
  }, []);

  return (
    <div className={classes.ContactInformation}>
      <div className={classes.header}>
        <h5>Contact Information</h5>
      </div>
      <Formik
        initialValues={{ ...init }}
        validateOnBlur={false}
        validateOnMount={false}
        validate={(values) => onChange(values)}>
        <div className={classes.body}>
          <Form className={classes.groupCard}>
            <div>
              <div className={classes.col3}>
                <NormalInputField name="supplier_name" label="Name" />
                <NormalInputField name="supplier_email" label="Email" />
                <NormalInputField name="supplier_phone" label="Phone Number" />
              </div>
              <NormalInputField
                name="supplier_remarks"
                label="Remarks"
                as="textarea"
              />
            </div>
            {children}
            <Actions {...actionProps} />
          </Form>
        </div>
      </Formik>
    </div>
  );
};
ContactInformation.propTypes = {
  supplier_name: PropTypes.string.isRequired,
  supplier_email: PropTypes.string.isRequired,
  supplier_phone: PropTypes.string.isRequired,
  supplier_remarks: PropTypes.string.isRequired,
  actionProps: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export const RemarksCompSet = ({
  reservation_remarks,
  payment_mode_remarks,
  booking_mode_remarks,
  response_reservation_remarks,
  response_payment_mode_remarks,
  response_booking_mode_remarks,
  editable,
  setResRemarks,
  setPModeRemarks,
  setBModeRemarks,
}) => {
  return (
    <React.Fragment>
      <GeneralRemarks
        reservation_remarks={reservation_remarks}
        reply_remarks={response_reservation_remarks}
        editable={editable}
        onChange={(value) => setResRemarks(value)}
      />
      <PaymentModeRemarks
        payment_mode_remarks={payment_mode_remarks}
        reply_remarks={response_payment_mode_remarks}
        editable={editable}
        onChange={(value) => setPModeRemarks(value)}
      />
      <BookingModeRemarks
        editable={editable}
        booking_mode_remarks={booking_mode_remarks}
        reply_remarks={response_booking_mode_remarks}
        onChange={(value) => setBModeRemarks(value)}
      />
    </React.Fragment>
  );
};
RemarksCompSet.propTypes = {
  reservation_remarks: PropTypes.string,
  payment_mode_remarks: PropTypes.string,
  booking_mode_remarks: PropTypes.string,
  response_reservation_remarks: PropTypes.object.isRequired,
  response_payment_mode_remarks: PropTypes.object.isRequired,
  response_booking_mode_remarks: PropTypes.object.isRequired,
  editable: PropTypes.bool.isRequired,
  setResRemarks: PropTypes.func.isRequired,
  setPModeRemarks: PropTypes.func.isRequired,
  setBModeRemarks: PropTypes.func.isRequired,
};

const commonFeedbackStyles = createUseStyles({
  col3: {
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr)",
    gridGap: variables.normal_gap,
  },
  [`@media ${variables.media.bigscreen}`]: {
    col3: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
  },
  // cxlRow: ({ request_cxl_policy }) => cxlRowStyles({ request_cxl_policy }),
});
export const CommonFeedback = ({
  reservation,
  feedRemarks,
  editable,
  cxl_rules,
  setCxlRules,
}) => {
  const classes = commonFeedbackStyles({
    request_cxl_policy: reservation.request_cxl_policy || false,
  });

  return (
    <React.Fragment>
      {/* <div className={classes.col3}> */}
      <RemarksCompSet
        reservation_remarks={reservation.reservation_remarks}
        payment_mode_remarks={reservation.payment_mode_remarks}
        booking_mode_remarks={reservation.booking_mode_remarks}
        response_reservation_remarks={feedRemarks.reservation_remarks}
        response_payment_mode_remarks={feedRemarks.payment_mode_remarks}
        response_booking_mode_remarks={feedRemarks.booking_mode_remarks}
        editable={editable}
        setResRemarks={(value) => feedRemarks.setResRemarks(value)}
        setPModeRemarks={(value) => feedRemarks.setPModeRemarks(value)}
        setBModeRemarks={(value) => feedRemarks.setBModeRemarks(value)}
      />
      {/* </div> */}
      {/* <div className={classes.cxlRow}> */}
      <CxlRemarks
        cxl_remarks={reservation.request_cxl_policy_remarks}
        reply_remarks={feedRemarks.cxl_remarks}
        editable={editable}
        onChange={(value) => feedRemarks.setCxlRemarks(value)}
      />
      {reservation.request_cxl_policy && (
        <CxlPolicies
          editable={editable}
          rules={cxl_rules}
          onChange={(rules) => setCxlRules(rules)}
        />
      )}
      {/* </div> */}
    </React.Fragment>
  );
};
CommonFeedback.propTypes = {
  reservation: PropTypes.object.isRequired,
  request_cxl_policy: PropTypes.bool,
  feedRemarks: PropTypes.object.isRequired,
  editable: PropTypes.bool.isRequired,
  cxl_rules: PropTypes.array.isRequired,
  setCxlRules: PropTypes.func.isRequired,
};

const reservationInfoCardStyles = createUseStyles({
  // card: { ...rsrvFeedbackMainCardStyles, padding: "0 10% 0 10%" },
  cardHeader: cardStyles.header,
  ReservationInfoCard: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridAutoRows: "max-content",
    gridGap: variables.normal_gap,
    alignItems: "start",
  },
  rightCol: {
    display: "grid",
    gridGap: variables.normal_gap,
  },
  [`@media ${variables.media.smallscreen}`]: {
    ReservationInfoCard: {
      gridTemplateColumns: "1fr 2fr",
    },
  },
  [`@media ${variables.media.bigscreen}`]: {
    ReservationInfoCard: {
      gridTemplateColumns: "1fr 3fr",
    },
  },
});
export const ReservationInfoCard = ({
  reservation,
  actionProps,
  feedRemarks,
  editable,
  cxl_rules,
  setCxlRules,
  children,
}) => {
  const classes = reservationInfoCardStyles();
  return !reservation ? null : (
    <div className={classes.ReservationInfoCard}>
      <RequestInfo reservation={reservation} />
      <div className={classes.rightCol}>
        {children}
        <CommonFeedback
          reservation={reservation}
          feedRemarks={feedRemarks}
          editable={editable}
          cxl_rules={cxl_rules}
          setCxlRules={setCxlRules}
        />
        <Actions {...actionProps} />
      </div>
    </div>
  );
};
ReservationInfoCard.propTypes = {
  reservation: PropTypes.object.isRequired,
  actionProps: PropTypes.object.isRequired,
  feedRemarks: PropTypes.object.isRequired,
  editable: PropTypes.bool.isRequired,
  cxl_rules: PropTypes.array.isRequired,
  setCxlRules: PropTypes.func.isRequired,
  children: PropTypes.node,
};
