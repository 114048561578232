import {
  //======================= LIST VIEW=============================
  MY_LIBRARY_ADDONS_FETCH_DATA,
  MY_LIBRARY_ADDONS_LOADING_LIST,
  MY_LIBRARY_ADDONS_IDLE_LIST,
  //=======================Filter================================
  MY_LIBRARY_ADDONS_UPDATE_FILTERS,
  //========================DASHBOARD===============================
  MY_LIBRARY_ADDONS_VIEW_TYPE,
  MY_LIBRARY_ADDONS_FETCH_GRAPH_DATA,
  MY_LIBRARY_ADDONS_BEGIN_FETCH_GRAPH_DATA,
  MY_LIBRARY_ADDONS_END_FETCH_GRAPH_DATA,
  MY_LIBRARY_ADDONS_INIT_GRAPH_TYPE,
} from "@src/actions/MyLibrary/Addons/types";
import { commonList } from "@src/reducers/common";

// ============================ LIST VIEW ============================
const addonsFiltersInitial = {
  reference: "",
  title: "",
  distribution_time: "",
  city: "",
  activities: [],
  creator__icontains: "",
  created__gte: "",
  created__lte: "",
  status: "",
};
export const initialMyLibraryAddonsList = {
  ...commonList,
  limit: 6,
  displayLength: 100,
  filters: addonsFiltersInitial,
};
export const myLibraryAddonsList = (
  state = initialMyLibraryAddonsList,
  action
) => {
  switch (action.type) {
    case MY_LIBRARY_ADDONS_FETCH_DATA:
      return { ...state, ...action.data };
    case MY_LIBRARY_ADDONS_LOADING_LIST:
      return { ...state, loading: true };
    case MY_LIBRARY_ADDONS_IDLE_LIST:
      return { ...state, loading: false };
    case MY_LIBRARY_ADDONS_UPDATE_FILTERS: {
      const { data } = action;
      return { ...state, filters: data, page: 1 };
    }
    default:
      return state;
  }
};

// ============================ DASHBOARD ============================
export const initData = {
  type: "all",
  name: "TS",
};
export const myLibraryAddonsViewType = (state = initData, action) => {
  switch (action.type) {
    case MY_LIBRARY_ADDONS_INIT_GRAPH_TYPE:
      return initData;
    case MY_LIBRARY_ADDONS_VIEW_TYPE:
      return action.data;
    default:
      return state;
  }
};

export const myLibraryAddonsGraphData = (state = {}, action) => {
  switch (action.type) {
    case MY_LIBRARY_ADDONS_FETCH_GRAPH_DATA:
      return action.data;
    default:
      return state;
  }
};

export const initialMyLibraryAddonsGraphDataStatus = "IDLE";
export const myLibraryAddonsGraphDataStatus = (
  state = initialMyLibraryAddonsGraphDataStatus,
  action
) => {
  switch (action.type) {
    case MY_LIBRARY_ADDONS_BEGIN_FETCH_GRAPH_DATA:
      return "FETCHING";
    case MY_LIBRARY_ADDONS_END_FETCH_GRAPH_DATA:
      return "FETCHED";
    default:
      return state;
  }
};
