export const INDEPENDENT_AGENTS_FETCH_DATA = "INDEPENDENT_AGENTS_FETCH_DATA";
export const INDEPENDENT_AGENTS_BEGIN_FETCH_DATA =
  "INDEPENDENT_AGENTS_BEGIN_FETCH_DATA";
export const INDEPENDENT_AGENTS_END_FETCH_DATA =
  "INDEPENDENT_AGENTS_END_FETCH_DATA";
export const INDEPENDENT_AGENTS_INITIALIZE_LIST_FILTER_FORM =
  "INDEPENDENT_AGENTS_INITIALIZE_LIST_FILTER_FORM";
export const INDEPENDENT_AGENTS_UPDATE_LIST_FILTER_FORM =
  "INDEPENDENT_AGENTS_UPDATE_LIST_FILTER_FORM";
export const INDEPENDENT_AGENTS_APPLY_LIST_FILTER =
  "INDEPENDENT_AGENTS_APPLY_LIST_FILTER";
export const INDEPENDENT_AGENTS_UPDATE_LIST_COLUMNS_FORM =
  "INDEPENDENT_AGENTS_UPDATE_LIST_COLUMNS_FORM";
export const INDEPENDENT_AGENTS_APPLY_LIST_COLUMNS =
  "INDEPENDENT_AGENTS_APPLY_LIST_COLUMNS";
export const INDEPENDENT_AGENTS_INITIALIZE_LIST_COLUMNS_FORM =
  "INDEPENDENT_AGENTS_INITIALIZE_LIST_COLUMNS_FORM";
export const INDEPENDENT_AGENTS_FETCH_OPTIONS =
  "INDEPENDENT_AGENTS_FETCH_OPTIONS";
export const INDEPENDENT_AGENTS_INITIALIZE_BASE_DATA_EDIT_FORM =
  "INDEPENDENT_AGENTS_INITIALIZE_BASE_DATA_EDIT_FORM";
export const INDEPENDENT_AGENTS_HIDE_BASE_DATA_EDIT_FORM =
  "INDEPENDENT_AGENTS_HIDE_BASE_DATA_EDIT_FORM";
export const INDEPENDENT_AGENTS_SHOW_BASE_DATA_EDIT_FORM =
  "INDEPENDENT_AGENTS_SHOW_BASE_DATA_EDIT_FORM";
export const INDEPENDENT_AGENTS_VALIDATE_BASE_DATA_EDIT_FORM =
  "INDEPENDENT_AGENTS_VALIDATE_BASE_DATA_EDIT_FORM";
export const INDEPENDENT_AGENTS_PROFILE_DATA =
  "INDEPENDENT_AGENTS_PROFILE_DATA";
export const INDEPENDENT_AGENTS_UPLOADING_BASE_DATA_EDIT_FORM =
  "INDEPENDENT_AGENTS_UPLOADING_BASE_DATA_EDIT_FORM";
export const INDEPENDENT_AGENTS_IDLE_BASE_DATA_EDIT_FORM =
  "INDEPENDENT_AGENTS_IDLE_BASE_DATA_EDIT_FORM";
export const INDEPENDENT_AGENTS_EDIT_BASE_DATA =
  "INDEPENDENT_AGENTS_EDIT_BASE_DATA";
export const INDEPENDENT_AGENTS_CLEAN_BASE_EDIT_FORM_ERRORS =
  "INDEPENDENT_AGENTS_CLEAN_BASE_EDIT_FORM_ERRORS";
export const INDEPENDENT_AGENTS_CLEAN_BASE_DATA_EDIT_FORM =
  "INDEPENDENT_AGENTS_CLEAN_BASE_DATA_EDIT_FORM";
export const INDEPENDENT_AGENTS_VIEW_NATURE_OF_BIZ =
  "INDEPENDENT_AGENTS_VIEW_NATURE_OF_BIZ";
export const INDEPENDENT_AGENTS_SUBMIT_NATURE_OF_BIZ_DATA =
  "INDEPENDENT_AGENTS_SUBMIT_NATURE_OF_BIZ_DATA";
export const INDEPENDENT_AGENTS_EDIT_NATURE_OF_BIZ_DATA =
  "INDEPENDENT_AGENTS_EDIT_NATURE_OF_BIZ_DATA";
export const INDEPENDENT_AGENTS_INITIALIZE_NATURE_DATA_FORM =
  "INDEPENDENT_AGENTS_INITIALIZE_NATURE_DATA_FORM";
export const INDEPENDENT_AGENTS_EDIT_NATURE_OF_BIZ =
  "INDEPENDENT_AGENTS_EDIT_NATURE_OF_BIZ";
export const INDEPENDENT_AGENTS_EDIT_LICENSE_CODE =
  "INDEPENDENT_AGENTS_EDIT_LICENSE_CODE";
export const INDEPENDENT_AGENTS_TOGGLE_POSTAL_ADDRESS =
  "INDEPENDENT_AGENTS_TOGGLE_POSTAL_ADDRESS";
export const INDEPENDENT_AGENTS_RESET_LIST_FILTER_FORM =
  "INDEPENDENT_AGENTS_RESET_LIST_FILTER_FORM";
export const INDEPENDENT_AGENTS_FETCHING_OPTIONS =
  "INDEPENDENT_AGENTS_FETCHING_OPTIONS";
export const INDEPENDENT_AGENTS_FETCHED_OPTIONS =
  "INDEPENDENT_AGENTS_FETCHED_OPTIONS";

// ======================== Service categories =======================

export const INDEPENDENT_AGENTS_SELECT_SERVICE_CATEGORY =
  "INDEPENDENT_AGENTS_SELECT_SERVICE_CATEGORY";
export const INDEPENDENT_AGENTS_SELECT_TRAVEL_SERVICE =
  "INDEPENDENT_AGENTS_SELECT_TRAVEL_SERVICE";
export const INDEPENDENT_AGENTS_INITIALIZE_TRAVEL_SERVICE_FORM =
  "INDEPENDENT_AGENTS_INITIALIZE_TRAVEL_SERVICE_FORM";

export const INDEPENDENT_AGENTS_VIEW_SERVICE_CATEGORIES_MODE =
  "INDEPENDENT_AGENTS_VIEW_SERVICE_CATEGORIES_MODE";
export const INDEPENDENT_AGENTS_EDIT_SERVICE_CATEGORIES_MODE =
  "INDEPENDENT_AGENTS_EDIT_SERVICE_CATEGORIES_MODE";
export const INDEPENDENT_AGENTS_LOADING_SERVICE_CATEGORY_MODE =
  "INDEPENDENT_AGENTS_LOADING_SERVICE_CATEGORY_MODE";
export const INDEPENDENT_AGENTS_INITIALIZE_MAIN_SERVICES_FORM =
  "INDEPENDENT_AGENTS_INITIALIZE_MAIN_SERVICES_FORM";
export const INDEPENDENT_AGENTS_UPDATE_MAIN_SERVICES_FORM =
  "INDEPENDENT_AGENTS_UPDATE_MAIN_SERVICES_FORM";

//img relation

export const INDEPENDENT_AGENTS_UPLOAD_PREFERENCE_IMAGE =
  "INDEPENDENT_AGENTS_UPLOAD_PREFERENCE_IMAGE";
export const INDEPENDENT_AGENTS_FETCH_ADDRESS_POINT =
  "INDEPENDENT_AGENTS_FETCH_ADDRESS_POINT";
export const INDEPENDENT_AGENTS_INITIAL_ADDRESS_POINT =
  "INDEPENDENT_AGENTS_INITIAL_ADDRESS_POINT";
export const INDEPENDENT_AGENTS_TOGGLE_VERIFY_ADDRESS =
  "INDEPENDENT_AGENTS_TOGGLE_VERIFY_ADDRESS";
export const INDEPENDENT_AGENTS_BEGIN_FETCH_ADDRESS_DATA =
  "INDEPENDENT_AGENTS_BEGIN_FETCH_ADDRESS_DATA";
export const INDEPENDENT_AGENTS_END_FETCH_ADDRESS_DATA =
  "INDEPENDENT_AGENTS_END_FETCH_ADDRESS_DATA";
export const INDEPENDENT_AGENTS_BEGIN_FETCH_PROFILE_DATA =
  "INDEPENDENT_AGENTS_BEGIN_FETCH_PROFILE_DATA";
export const INDEPENDENT_AGENTS_END_FETCH_PROFILE_DATA =
  "INDEPENDENT_AGENTS_END_FETCH_PROFILE_DATA";
export const INDEPENDENT_AGENTS_CLOSE_VERIFY_ADDRESS =
  "INDEPENDENT_AGENTS_CLOSE_VERIFY_ADDRESS";
export const INDEPENDENT_AGENTS_CLEAN_ADDRESS_POINT =
  "INDEPENDENT_AGENTS_CLEAN_ADDRESS_POINT";
export const INDEPENDENT_AGENTS_INITIAL_VERIFY_STATUS =
  "INDEPENDENT_AGENTS_INITIAL_VERIFY_STATUS";

//======================= Email Config Section==========================
export const INDEPENDENT_AGENTS_VIEW_EMAIL_CONFIG_MODE =
  "INDEPENDENT_AGENTS_VIEW_EMAIL_CONFIG_MODE";
export const INDEPENDENT_AGENTS_EDIT_EMAIL_CONFIG_MODE =
  "INDEPENDENT_AGENTS_EDIT_EMAIL_CONFIG_MODE";
export const INDEPENDENT_AGENTS_LOADING_EMAIL_CONFIG_MODE =
  "INDEPENDENT_AGENTS_LOADING_EMAIL_CONFIG_MODE";
export const INDEPENDENT_AGENTS_EDIT_EMAIL_CONFIG_FORM =
  "INDEPENDENT_AGENTS_EDIT_EMAIL_CONFIG_FORM";
export const INDEPENDENT_AGENTS_INITIALIZE_EMAIL_CONFIG_FORM =
  "INDEPENDENT_AGENTS_INITIALIZE_EMAIL_CONFIG_FORM";
