export const TRIPPLANNER_DAYBYDAY_SHOW_ADD_ADDON_DRAWER =
  "TRIPPLANNER_DAYBYDAY_SHOW_ADD_ADDON_DRAWER";
export const TRIPPLANNER_DAYBYDAY_HIDE_ADD_ADDON_DRAWER =
  "TRIPPLANNER_DAYBYDAY_HIDE_ADD_ADDON_DRAWER";
export const TRIPPLANNER_DAYBYDAY_LOADING_ADDON_DRAWER =
  "TRIPPLANNER_DAYBYDAY_LOADING_ADDON_DRAWER";
export const TRIPPLANNER_DAYBYDAY_IDLE_ADDON_DRAWER =
  "TRIPPLANNER_DAYBYDAY_IDLE_ADDON_DRAWER";
export const TRIPPLANNER_DAYBYDAY_SET_ADDONS =
  "TRIPPLANNER_DAYBYDAY_SET_ADDONS";
export const TRIPPLANNER_DAYBYDAY_RESET_ADDONS =
  "TRIPPLANNER_DAYBYDAY_RESET_ADDONS";
export const TRIPPLANNER_DAYBYDAY_CHANGE_FILTERS =
  "TRIPPLANNER_DAYBYDAY_CHANGE_FILTERS";
export const TRIPPLANNER_DAYBYDAY_TRIP_STORE_ADDONS =
  "TRIPPLANNER_DAYBYDAY_TRIP_STORE_ADDONS";
export const TRIPPLANNER_DAYBYDAY_TRIP_REMOVE_ADDON =
  "TRIPPLANNER_DAYBYDAY_TRIP_REMOVE_ADDON";
export const TRIPPLANNER_DAYBYDAY_CHANGE_ADDON_DRAWER_MODE =
  "TRIPPLANNER_DAYBYDAY_CHANGE_ADDON_DRAWER_MODE";
export const TRIPPLANNER_DAYBYDAY_ADDON_NOT_FOUND =
  "TRIPPLANNER_DAYBYDAY_ADDON_NOT_FOUND";
export const TRIPPLANNER_DAYBYDAY_ADDON_SEARCHING =
  "TRIPPLANNER_DAYBYDAY_ADDON_SEARCHING";
export const TRIPPLANNER_DAYBYDAY_ADDON_IDLE =
  "TRIPPLANNER_DAYBYDAY_ADDON_IDLE";
