import {
  SHOW_GLOBAL_FILTER_MODAL,
  HIDE_GLOBAL_FILTER_MODAL,
  SHOW_GRAPH_BOX_MAP_VIEW_MODAL,
  HIDE_GRAPH_BOX_MAP_VIEW_MODAL,
  SHOW_REPLACEABLE_MODAL,
  HIDE_REPLACEABLE_MODAL,
  DOWNLOAD_PDF_MODAL_SHOW,
  DOWNLOAD_PDF_MODAL_HIDE,
  DOWNLOAD_EXCEL_MODAL_SHOW,
  DOWNLOAD_EXCEL_MODAL_HIDE,
} from "@src/actions/Shares/types";

export const initialModal = {
  id: "",
  mode: "HIDE",
};
export const globalModal = (state = initialModal, action) => {
  switch (action.type) {
    case SHOW_GLOBAL_FILTER_MODAL:
      return action.data;
    case HIDE_GLOBAL_FILTER_MODAL:
      return initialModal;
    default:
      return state;
  }
};

export const globalMapModal = (state = initialModal, action) => {
  switch (action.type) {
    case SHOW_GRAPH_BOX_MAP_VIEW_MODAL:
      return action.data;
    case HIDE_GRAPH_BOX_MAP_VIEW_MODAL:
      return initialModal;
    default:
      return state;
  }
};

export const replaceableModal = (state = initialModal, action) => {
  switch (action.type) {
    case SHOW_REPLACEABLE_MODAL:
      return action.data;
    case HIDE_REPLACEABLE_MODAL:
      return initialModal;
    default:
      return state;
  }
};

export const initialDownloadPdfModal = {
  show: false,
};

export const downloadPdfModal = (state = initialDownloadPdfModal, action) => {
  switch (action.type) {
    case DOWNLOAD_PDF_MODAL_SHOW:
      return { show: true };
    case DOWNLOAD_PDF_MODAL_HIDE:
      return initialDownloadPdfModal;
    default:
      return state;
  }
};

const initialDownloadExcelModal = {
  show: false,
};

export const downloadExcelModal = (
  state = initialDownloadExcelModal,
  action
) => {
  switch (action.type) {
    case DOWNLOAD_EXCEL_MODAL_SHOW:
      return { ...state, show: true };
    case DOWNLOAD_EXCEL_MODAL_HIDE:
      return initialDownloadExcelModal;
    default:
      return state;
  }
};
