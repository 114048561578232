import _ from "lodash";
import React from "react";
import axios from "axios";
import { getAuthHeaders } from "../../../request";
import { SISI_API_URL } from "@src/api";
import { toast } from "react-toastify";

export async function fetchCoordReservation(params) {
  const auth_headers = getAuthHeaders();
  return await axios.get(`${SISI_API_URL}/reservations/coordinators`, {
    params,
    headers: { ...auth_headers },
  });
}

export async function fetchCoordResDetails({ reference }) {
  const auth_headers = getAuthHeaders();
  return await axios.get(
    `${SISI_API_URL}/reservations/coordinators/${reference}/`,
    { headers: { ...auth_headers } }
  );
}

export async function updateCoordResDetails({ reference, payload }) {
  const auth_headers = getAuthHeaders();
  return await axios.patch(
    `${SISI_API_URL}/reservations/coordinators/${reference}/`,
    payload,
    { headers: { ...auth_headers } }
  );
}

export async function fetchPublicCoordResDetails({
  reference,
  onError = (error) =>
    toast.error(
      <p>
        We failed to retrieve the Coordinator Reservation. We are sorry for the
        inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    ),
}) {
  const auth_headers = getAuthHeaders();
  try {
    const result = await axios.get(
      `${SISI_API_URL}/reservations/coordinators/public/${reference}/`,
      { headers: { ...auth_headers } }
    );
    return result.data;
  } catch (error) {
    if (typeof onError === "function") {
      onError(error);
    }

    return null;
  }
}

export async function patchPublicCoordReservation({
  reference,
  payload,
  onError = (error) =>
    toast.error(
      <p>
        We failed to update the Coordinator Reservation. We are sorry for the
        inconvenience.
        <br />
        <span>{_.get(error, "response.data.message")}</span>
        <br />
        <span>{_.get(error, "response.data.error")}</span>
      </p>,
      { duration: 5000 }
    ),
}) {
  try {
    const result = await axios.patch(
      `${SISI_API_URL}/reservations/coordinators/public/update/${reference}/`,
      payload
    );
    return result.data;
  } catch (error) {
    if (typeof onError === "function") {
      onError(error);
    }
    return null;
  }
}

export async function getCoordinatorStats(params) {
  const auth_headers = getAuthHeaders();
  return await axios.get(`${SISI_API_URL}/reservations/coordinators/stats/`, {
    params,
    headers: { ...auth_headers },
  });
}
