import {
  TRIPPLANNER_LOAD_TRIP,
  TRIPPLANNER_SETUP_CHANGE,
  TRIPPLANNER_SET_MODE,
  TRIPPLANNER_SET_ROOM_SETUP,
} from "./types";

import { itnSelectOriginDate } from "./step_one";

import { notifyCommonError } from "@src/components/common/notifications/CommonErrorNotification.js";

import {
  fetchTripPlan,
  fetchTripPlanOffer,
} from "@src/api/Project/TripPlanner";

import {
  showTripWaitModal,
  hideTripWaitModal,
  prevStep,
  mapRedraw,
  clearAccs,
  clearTransfers,
} from "./index";
import { stepFourRequirements } from "./step_four";

import _ from "lodash";
import { recalculateAddOn } from "./day_by_day_actions";
import { DateTime } from "luxon";

export const loadTrip = (data, mode) => {
  return { type: TRIPPLANNER_LOAD_TRIP, data, mode };
};

export function fetchTripPlanInstance({ id, mode = "normal" }) {
  return async function (dispatch, getState) {
    await dispatch(
      showTripWaitModal("Please wait while your trip is loading.")
    );

    const fetchFn = mode == "view-offer" ? fetchTripPlanOffer : fetchTripPlan;

    try {
      const data = await fetchFn(id);

      if (mode == "view-offer") {
        data["current_step"] = 3;
      }

      await dispatch(loadTrip(data, mode));
      dispatch(mapRedraw());

      // Ensure that requirements for current step are fullfiled.
      const currentStep = getState().tripPlannerCurrentStep;
      if (currentStep == 4) {
        await dispatch(stepFourRequirements());
      }

      await dispatch(hideTripWaitModal());
    } catch (error) {
      console.log(error);
      notifyCommonError(
        "We couldn't load this trip. We are sorry for the inconvenience."
      );
      window.location.href = "/#/project/trip/archive/on-request/";
      await dispatch(hideTripWaitModal());
    }
  };
}

export const setTripMode = ({ mode }) => {
  return { type: TRIPPLANNER_SET_MODE, mode };
};

export function recalculateTrip(tripStartDate) {
  return async function (dispatch, getState) {
    await dispatch(itnSelectOriginDate(tripStartDate));

    const state = getState();
    if (state.tripPlannerCurrentStep == 4) dispatch(prevStep(4));

    const itinSetup = state.tripPlannerItinerarySetupForm;
    if (itinSetup.requiredServices.includes("MI")) dispatch(recalculateAddOn());
  };
}

export function recalculateActions(options = { search: "parallel" }) {
  return async function (dispatch, getState) {
    const { search } = options;
    const state = getState();
    const itinSetup = state.tripPlannerItinerarySetupForm;

    if (itinSetup.requiredServices.includes("TF")) dispatch(clearTransfers());

    if (itinSetup.requiredServices.includes("MI")) {
      if (search == "parallel") {
        dispatch(recalculateAddOn());
      } else {
        await dispatch(recalculateAddOn());
      }
    }
  };
}

export const tripSetupChange = (name, value) => {
  return { type: TRIPPLANNER_SETUP_CHANGE, name, value };
};

export const setRoomSetup = ({ rooms }) => {
  return { type: TRIPPLANNER_SET_ROOM_SETUP, rooms };
};
