import _ from "lodash";
import { CustomButton } from "@src/components/common/buttons";
import { cardStyles, tableStyles, variables } from "@src/jsssetup";
import { Formik } from "formik";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { createUseStyles } from "react-jss";
import { v4 } from "uuid";
import * as yup from "yup";
import { NormalInputField, NormalSelectField } from "@src/components/forms";
import { DateTime } from "luxon";
import { useDispatch } from "react-redux";
import { addonPatch } from "@src/actions/Operation/CustomServices/AddOn";
import { toast } from "react-toastify";
import { currencies } from "@src/definitions";
import tinycolor from "tinycolor2";

const priceSchema = yup.object().shape({
  description: yup.string().required("Required").default(""),
  price: yup.number().required("Required").default(0),
  pax_type: yup
    .string()
    .oneOf(["ADT", "CHD"])
    .required("Required")
    .default("ADT"),
  min_age: yup.number().required("Required").min(0).default(0),
  max_age: yup.number().required("Required").max(100).default(100),
  max_pax: yup.number().required("Required").default(1),
  uid: yup.string().required("Required").default(v4),
});

const chargeSchema = yup.object().shape({
  pax_type: yup
    .string()
    .oneOf(["ADT", "CHD"])
    .required("Required")
    .default("ADT"),
  description: yup.string().required("Required").default(""),
  min_age: yup.number().required("Required").min(0).default(0),
  max_age: yup.number().required("Required").max(100).default(100),
  price: yup.number().required("Required").default(0),
  uid: yup.string().required("Required").default(v4),
});

const priceItemSchema = yup.object().shape({
  name: yup.string().required("Required").default(""),
  min_pax: yup.number().required("Required").default(1),
  max_pax: yup.number().required("Required").default(1),
  currency: yup.string().required("Required").default("EUR").length(3),
  prices: yup
    .array()
    .of(priceSchema)
    .default([])
    .min(1)
    .test({
      name: "priceItemSchema",
      message: "At least one price is required",
      test: (value) => {
        if (value.length === 0) {
          toast.error("For each Price Item at least one price is required", {
            autoClose: 3000,
          });
        }
        return value.length > 0;
      },
    }),
  pricesCollapsed: yup.boolean().default(false),
  extra_charges: yup.array().of(chargeSchema).default([]),
  extraChargesCollapsed: yup.boolean().default(false),
  uid: yup.string().required("Required").default(v4),
  collapsed: yup.boolean().default(true),
});

const priceItemsSchema = yup
  .array()
  .of(priceItemSchema)
  .default([])
  .min(1)
  .test("priceItemsSchema", "At least one price item is required", (value) => {
    if (value.length === 0) {
      toast.error("At least one price item is required", {
        autoClose: 3000,
      });
    }
    return value.length > 0;
  });

const priceItemsStyles = createUseStyles({
  PriceItems: cardStyles.card,
  ...cardStyles,
  table: tableStyles.table,
  thead: tableStyles.head,
  cell: {
    ...tableStyles.cell,
    "textAlign": "left",
    "& input[type=number]": {
      width: "6rem",
    },
  },
  actioncell: {
    ...tableStyles.cell,
    "verticalAlign": "bottom",
    "& button": {
      float: "left",
      marginRight: variables.half_gap,
    },
  },
  priceRow: {
    "backgroundColor": `${tinycolor(variables.colors.green)
      .lighten(20)
      .toHexString()}!important`,
    "color": "black!important",
    "&:nth-child(2n)": {
      backgroundColor: `${tinycolor(variables.colors.green)
        .lighten(30)
        .toHexString()}!important`,
    },
  },
  chargeRow: {
    "backgroundColor": `${tinycolor(variables.colors.deepRed)
      .lighten(40)
      .toHexString()}!important`,
    "color": "black!important",
    "&:nth-child(2n)": {
      backgroundColor: `${tinycolor(variables.colors.deepRed)
        .lighten(45)
        .toHexString()}!important`,
    },
  },
});
const PriceItems = ({
  distribution_periods = [],
  period = {},
  periodIdx = 0,
}) => {
  const [collapsed, setCollapsed] = useState(false);
  const [mode, setMode] = useState("view"); // view, edit
  const classes = priceItemsStyles();

  const dispatch = useDispatch();
  const onSubmit = useCallback(
    async (values) => {
      const newPeriods = _.cloneDeep(distribution_periods);
      newPeriods[periodIdx] = { ..._.cloneDeep(period), price_items: values };
      await dispatch(addonPatch({ distribution_periods: newPeriods }));
      setMode("view");
    },
    [dispatch]
  );

  return (
    <div className={classes.PriceItems}>
      <Formik
        initialValues={priceItemsSchema.cast(period?.price_items ?? [])}
        validateOnBlur={false}
        validateOnChange={false}
        validateOnMount={false}
        validateOnSubmit={true}
        validationSchema={priceItemsSchema}
        onSubmit={onSubmit}>
        {({ values, setValues, setFieldValue, submitForm }) => {
          return (
            <React.Fragment>
              <div className={classes.header}>
                <h5>Price Items for: {period.name}.</h5>
                <CustomButton
                  appearance="ghost"
                  onClick={() => setCollapsed((p) => !p)}>
                  {collapsed ? "Expand" : "Collapse"}
                </CustomButton>
                <span>
                  Distribution from{" "}
                  {DateTime.fromISO(period.distribution_from).toLocaleString(
                    DateTime.DATE_MED_WITH_WEEKDAY
                  )}{" "}
                  to{" "}
                  {DateTime.fromISO(period.distribution_to).toLocaleString(
                    DateTime.DATE_MED_WITH_WEEKDAY
                  )}
                </span>
                <span />
                <span>
                  Operation from{" "}
                  {DateTime.fromISO(period.operation_from).toLocaleString(
                    DateTime.DATE_MED_WITH_WEEKDAY
                  )}{" "}
                  to{" "}
                  {DateTime.fromISO(period.operation_to).toLocaleString(
                    DateTime.DATE_MED_WITH_WEEKDAY
                  )}
                </span>
              </div>
              {collapsed ? null : (
                <React.Fragment>
                  <div className={classes.body}>
                    <table className={classes.table}>
                      <thead className={classes.thead}>
                        <tr>
                          <th>#</th>
                          <th>Name</th>
                          <th>Min Pax</th>
                          <th>Max Pax</th>
                          <th>Currency</th>
                          <th />
                          <th />
                          <th />
                          {mode === "edit" && <th>Actions</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {values.map((item, idx) => (
                          <React.Fragment key={idx}>
                            <tr>
                              <th className={classes.cell}>
                                <h6>
                                  Price Item {idx + 1} ({item.name ?? ""})
                                </h6>
                              </th>
                              <th className={classes.cell} />
                              <th className={classes.cell} />
                              <th className={classes.cell} />
                              <th className={classes.cell} />
                              <th className={classes.cell} />
                              <th className={classes.cell} />
                              {mode === "edit" && (
                                <th className={classes.cell} />
                              )}
                              <th>
                                <CustomButton
                                  appearance="ghost"
                                  onClick={() =>
                                    setFieldValue(
                                      `${idx}.collapsed`,
                                      !item.collapsed
                                    )
                                  }>
                                  {item.collapsed ? "Expand" : "Collapse"}
                                </CustomButton>
                              </th>
                            </tr>
                            {!item.collapsed && (
                              <React.Fragment>
                                <tr>
                                  <td className={classes.cell}>{idx + 1}</td>
                                  <td className={classes.cell}>
                                    {mode === "edit" && (
                                      <NormalInputField
                                        name={`${idx}.name`}
                                        highlightErrors={true}
                                      />
                                    )}
                                    {mode === "view" && item.name}
                                  </td>
                                  <td className={classes.cell}>
                                    {mode === "edit" && (
                                      <NormalInputField
                                        name={`${idx}.min_pax`}
                                        type="number"
                                        customStyles={{
                                          inputGroup: { width: "6rem" },
                                        }}
                                        highlightErrors={true}
                                      />
                                    )}
                                    {mode === "view" && item.min_pax}
                                  </td>
                                  <td className={classes.cell}>
                                    {mode === "edit" && (
                                      <NormalInputField
                                        name={`${idx}.max_pax`}
                                        type="number"
                                        customStyles={{
                                          inputGroup: { width: "6rem" },
                                        }}
                                        highlightErrors={true}
                                      />
                                    )}
                                    {mode === "view" && item.max_pax}
                                  </td>
                                  <td className={classes.cell}>
                                    {mode === "edit" && (
                                      <NormalSelectField
                                        name={`${idx}.currency`}
                                        options={Object.entries(currencies).map(
                                          ([code, name]) => [
                                            code,
                                            `${code} - ${name}`,
                                          ]
                                        )}
                                      />
                                    )}
                                    {mode === "view" && item.currency}
                                  </td>
                                  <td className={classes.cell} />
                                  {/* <td className={classes.cell} /> */}
                                  {mode === "edit" ? (
                                    <td
                                      className={classes.actioncell}
                                      colSpan="3">
                                      <React.Fragment>
                                        <CustomButton
                                          size="xs"
                                          onClick={() =>
                                            setValues(
                                              _.cloneDeep(
                                                values.filter(
                                                  (it) => it.uid !== item.uid
                                                )
                                              )
                                            )
                                          }>
                                          Remove Item
                                        </CustomButton>
                                        <CustomButton
                                          size="xs"
                                          onClick={() => {
                                            const newItems =
                                              _.cloneDeep(values);
                                            newItems[idx].prices.push(
                                              priceSchema.cast({})
                                            );
                                            setValues(newItems);
                                          }}>
                                          Add Price
                                        </CustomButton>
                                        <CustomButton
                                          size="xs"
                                          onClick={() => {
                                            const newItems =
                                              _.cloneDeep(values);
                                            newItems[idx].extra_charges.push(
                                              chargeSchema.cast({})
                                            );
                                            setValues(newItems);
                                          }}>
                                          Add Charge
                                        </CustomButton>
                                      </React.Fragment>
                                    </td>
                                  ) : (
                                    <td className={classes.cell} colSpan="2" />
                                  )}{" "}
                                </tr>
                                {item.prices.length > 0 && (
                                  <React.Fragment>
                                    <tr className={classes.priceRow}>
                                      <th className={classes.cell} />
                                      <th className={classes.cell}>
                                        Item {idx + 1} Prices
                                      </th>
                                      <th className={classes.cell} />
                                      <th className={classes.cell} />
                                      <th className={classes.cell} />
                                      <th className={classes.cell} />
                                      <th className={classes.cell} />
                                      {mode === "edit" && (
                                        <th className={classes.cell} />
                                      )}
                                      <th className={classes.cell}>
                                        <CustomButton
                                          appearance="ghost"
                                          onClick={() =>
                                            setFieldValue(
                                              `${idx}.pricesCollapsed`,
                                              !item.pricesCollapsed
                                            )
                                          }>
                                          {!item.pricesCollapsed
                                            ? "Collapse"
                                            : "Expand"}
                                        </CustomButton>
                                      </th>
                                    </tr>
                                    {item.pricesCollapsed ? null : (
                                      <tr className={classes.priceRow}>
                                        <th className={classes.cell} />
                                        <th className={classes.cell}>#</th>
                                        <th className={classes.cell}>
                                          Pax Type
                                        </th>
                                        <th className={classes.cell}>
                                          Description
                                        </th>
                                        <th className={classes.cell}>
                                          Min Age
                                        </th>
                                        <th className={classes.cell}>
                                          Max Age
                                        </th>
                                        <th className={classes.cell}>
                                          Max Pax
                                        </th>
                                        <th className={classes.cell}>Price</th>
                                        {mode === "edit" && (
                                          <th className={classes.cell}>
                                            Actions
                                          </th>
                                        )}
                                      </tr>
                                    )}
                                  </React.Fragment>
                                )}
                                {!item.pricesCollapsed &&
                                  item.prices.map((price, pidx) => (
                                    <tr key={pidx} className={classes.priceRow}>
                                      <td className={classes.cell}></td>
                                      <td className={classes.cell}>
                                        {pidx + 1}
                                      </td>
                                      <td className={classes.cell}>
                                        {mode === "edit" && (
                                          <NormalSelectField
                                            name={`${idx}.prices.${pidx}.pax_type`}
                                            options={[
                                              ["ADT", "Adult"],
                                              ["CHD", "Child"],
                                            ]}
                                            highlightErrors={true}
                                          />
                                        )}
                                        {mode === "view" &&
                                          (price.pax_type === "ADT"
                                            ? "Adult"
                                            : "Child")}
                                      </td>
                                      <td className={classes.cell}>
                                        {mode === "edit" && (
                                          <NormalInputField
                                            name={`${idx}.prices.${pidx}.description`}
                                            highlightErrors={true}
                                          />
                                        )}
                                        {mode === "view" && price.description}
                                      </td>
                                      <td className={classes.cell}>
                                        {mode === "edit" && (
                                          <NormalInputField
                                            name={`${idx}.prices.${pidx}.min_age`}
                                            type="number"
                                            customStyles={{
                                              inputGroup: { width: "6rem" },
                                            }}
                                            highlightErrors={true}
                                          />
                                        )}
                                        {mode === "view" && price.min_age}
                                      </td>
                                      <td className={classes.cell}>
                                        {mode === "edit" && (
                                          <NormalInputField
                                            name={`${idx}.prices.${pidx}.max_age`}
                                            type="number"
                                            customStyles={{
                                              inputGroup: { width: "6rem" },
                                            }}
                                            highlightErrors={true}
                                          />
                                        )}
                                        {mode === "view" && price.max_age}
                                      </td>
                                      <td className={classes.cell}>
                                        {mode === "edit" && (
                                          <NormalInputField
                                            name={`${idx}.prices.${pidx}.max_pax`}
                                            type="number"
                                            customStyles={{
                                              inputGroup: { width: "6rem" },
                                            }}
                                            highlightErrors={true}
                                          />
                                        )}
                                        {mode === "view" && price.max_pax}
                                      </td>
                                      <td className={classes.cell}>
                                        {mode === "edit" && (
                                          <NormalInputField
                                            name={`${idx}.prices.${pidx}.price`}
                                            type="number"
                                            customStyles={{
                                              inputGroup: { width: "6rem" },
                                            }}
                                            highlightErrors={true}
                                          />
                                        )}
                                        {mode === "view" && price.price}
                                      </td>
                                      {mode === "edit" && (
                                        <td className={classes.actioncell}>
                                          <CustomButton
                                            onClick={() => {
                                              const newItems =
                                                _.cloneDeep(values);
                                              newItems[idx].prices = newItems[
                                                idx
                                              ].prices.filter(
                                                (it) => it.uid !== price.uid
                                              );
                                              setValues(newItems);
                                            }}>
                                            Remove Price
                                          </CustomButton>
                                        </td>
                                      )}
                                    </tr>
                                  ))}
                                {item.extra_charges.length > 0 && (
                                  <React.Fragment>
                                    <tr className={classes.chargeRow}>
                                      <th className={classes.cell} />
                                      <th className={classes.cell}>
                                        Item {idx + 1} Extra Charges
                                      </th>
                                      <th className={classes.cell} />
                                      <th className={classes.cell} />
                                      <th className={classes.cell} />
                                      <th className={classes.cell} />
                                      <th className={classes.cell} />
                                      {mode === "edit" && (
                                        <th className={classes.cell} />
                                      )}
                                      <th className={classes.cell}>
                                        <CustomButton
                                          appearance="ghost"
                                          onClick={() =>
                                            setFieldValue(
                                              `${idx}.extraChargesCollapsed`,
                                              !item.extraChargesCollapsed
                                            )
                                          }>
                                          {!item.extraChargesCollapsed
                                            ? "Collapse"
                                            : "Expand"}
                                        </CustomButton>
                                      </th>
                                    </tr>
                                    {item.extraChargesCollapsed ? null : (
                                      <tr className={classes.chargeRow}>
                                        <th className={classes.cell} />
                                        <th className={classes.cell}>#</th>
                                        <th className={classes.cell}>
                                          Pax Type
                                        </th>
                                        <th className={classes.cell}>
                                          Description
                                        </th>
                                        <th className={classes.cell}>
                                          Min Age
                                        </th>
                                        <th className={classes.cell}>
                                          Max Age
                                        </th>
                                        <th className={classes.cell} />
                                        <th className={classes.cell}>Price</th>
                                        {mode === "edit" && (
                                          <th className={classes.cell}>
                                            Actions
                                          </th>
                                        )}
                                      </tr>
                                    )}
                                  </React.Fragment>
                                )}
                                {item.extraChargesCollapsed
                                  ? null
                                  : item.extra_charges.map((charge, cidx) => {
                                      return (
                                        <tr
                                          key={idx}
                                          className={classes.chargeRow}>
                                          <td></td>
                                          <td className={classes.cell}>
                                            {cidx + 1}
                                          </td>
                                          <td className={classes.cell}>
                                            {mode === "edit" && (
                                              <NormalSelectField
                                                name={`${idx}.extra_charges.${cidx}.pax_type`}
                                                options={[
                                                  ["ADT", "Adult"],
                                                  ["CHD", "Child"],
                                                ]}
                                                highlightErrors={true}
                                              />
                                            )}
                                            {mode === "view" &&
                                              (charge.pax_type === "ADT"
                                                ? "Adult"
                                                : "Child")}
                                          </td>
                                          <td className={classes.cell}>
                                            {mode === "edit" && (
                                              <NormalInputField
                                                name={`${idx}.extra_charges.${cidx}.description`}
                                                highlightErrors={true}
                                              />
                                            )}
                                            {mode === "view" &&
                                              charge.description}
                                          </td>
                                          <td className={classes.cell}>
                                            {mode === "edit" && (
                                              <NormalInputField
                                                name={`${idx}.extra_charges.${cidx}.min_age`}
                                                type="number"
                                                customStyles={{
                                                  inputGroup: { width: "6rem" },
                                                }}
                                                highlightErrors={true}
                                              />
                                            )}
                                            {mode === "view" && charge.min_age}
                                          </td>
                                          <td className={classes.cell}>
                                            {mode === "edit" && (
                                              <NormalInputField
                                                name={`${idx}.extra_charges.${cidx}.max_age`}
                                                type="number"
                                                customStyles={{
                                                  inputGroup: { width: "6rem" },
                                                }}
                                                highlightErrors={true}
                                              />
                                            )}
                                            {mode === "view" && charge.max_age}
                                          </td>
                                          <td className={classes.cell} />
                                          <td className={classes.cell}>
                                            {mode === "edit" && (
                                              <NormalInputField
                                                name={`${idx}.extra_charges.${cidx}.price`}
                                                type="number"
                                                customStyles={{
                                                  inputGroup: { width: "6rem" },
                                                }}
                                                highlightErrors={true}
                                              />
                                            )}
                                            {mode === "view" && charge.price}
                                          </td>
                                          {mode === "edit" && (
                                            <td className={classes.actioncell}>
                                              <CustomButton
                                                onClick={() => {
                                                  const newItems =
                                                    _.cloneDeep(values);
                                                  newItems[idx].extra_charges =
                                                    newItems[
                                                      idx
                                                    ].extra_charges.filter(
                                                      (it) =>
                                                        it.uid !== charge.uid
                                                    );
                                                  setValues(newItems);
                                                }}>
                                                Remove Charge
                                              </CustomButton>
                                            </td>
                                          )}
                                        </tr>
                                      );
                                    })}
                              </React.Fragment>
                            )}
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className={classes.actions}>
                    {mode === "view" ? (
                      <CustomButton onClick={() => setMode("edit")}>
                        Edit
                      </CustomButton>
                    ) : (
                      <React.Fragment>
                        <CustomButton
                          onClick={() =>
                            setValues([
                              ..._.cloneDeep(values),
                              priceItemSchema.cast({}),
                            ])
                          }>
                          Add Item
                        </CustomButton>
                        <CustomButton onClick={() => submitForm()}>
                          Submit
                        </CustomButton>
                      </React.Fragment>
                    )}
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          );
        }}
      </Formik>
    </div>
  );
};
PriceItems.defaultProps = {
  period: {},
  distribution_periods: [],
  periodIdx: 0,
};
PriceItems.propTypes = {
  period: PropTypes.array.isRequired,
  distribution_periods: PropTypes.array.isRequired,
  periodIdx: PropTypes.number.isRequired,
};

export default PriceItems;
