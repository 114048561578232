import _ from "lodash";
import { CustomButton } from "@src/components/common/buttons";
import { NormalDatePicker, NormalInputField } from "@src/components/forms";
import { cardStyles, tableStyles } from "@src/jsssetup";
import { Form, Formik } from "formik";
import React, { useCallback, useState } from "react";
import { createUseStyles } from "react-jss";
import * as yup from "yup";
import { DateTime } from "luxon";
import { useDispatch, useSelector } from "react-redux";
import {
  addonPatch,
  promptlessSaveAddonService,
} from "@src/actions/Operation/CustomServices/AddOn";
import { toast } from "react-toastify";
import { Loader } from "rsuite";

const chargeSchema = yup.object({
  description: yup.string().default("").required("Required"),
  operation_from: yup
    .string()
    .default(DateTime.now().toISODate())
    .required("Required"),
  operation_to: yup
    .string()
    .default(DateTime.now().plus({ days: 1 }).toISODate())
    .required("Required"),
  min_age: yup.number().min(0).max(100).default(0).required("Required"),
  max_age: yup.number().min(0).max(100).default(0).required("Required"),
  amount: yup.number().min(0).default(0).required("Required"),
});

const validationSchema = yup.object({
  extra_charges: yup.array().of(chargeSchema),
});

const extraChargesStyles = createUseStyles({
  ExtraCharges: { ...cardStyles.card, position: "relative" },
  ...cardStyles,
  ...tableStyles,
});
const ExtraCharges = () => {
  const [loading, setIsLoading] = useState(false);
  const [mode, setMode] = useState("view");
  const classes = extraChargesStyles();
  const dispatch = useDispatch();

  const addon = useSelector((state) => state.customServiceAddOn);
  const extra_charges = addon?.extra_charges ?? [];

  const onSubmit = useCallback(
    async ({ extra_charges }) => {
      setIsLoading(true);
      await dispatch(addonPatch({ extra_charges }));
      const result = await dispatch(promptlessSaveAddonService());

      if (result) {
        toast.success("Extra Charges Updated", { autoClose: 3000 });
      }

      setMode("view");
      setIsLoading(false);
    },
    [dispatch]
  );

  return (
    <Formik
      initialValues={
        extra_charges.length > 0
          ? { extra_charges }
          : validationSchema.cast({ extra_charges: [] })
      }>
      {({ values, setFieldValue }) => {
        return (
          <div className={classes.ExtraCharges}>
            <div className={classes.header}>
              <h5>Extra Charges</h5>
            </div>
            <div className={classes.body}>
              {loading && <Loader size="lg" backdrop center />}
              <Form>
                {mode === "view" ? (
                  <table className={classes.table}>
                    <thead className={classes.head}>
                      <tr>
                        <th>#</th>
                        <th>Description</th>
                        <th>Operation From</th>
                        <th>Operation To</th>
                        <th>Min Age</th>
                        <th>Max Age</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(values?.extra_charges ?? []).map((charge, idx) => (
                        <tr key={idx}>
                          <td className={classes.cell}>{idx + 1}</td>
                          <td className={classes.cell}>{charge.description}</td>
                          <td className={classes.cell}>
                            {DateTime.fromISO(
                              charge.operation_from
                            ).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
                          </td>
                          <td className={classes.cell}>
                            {DateTime.fromISO(
                              charge.operation_to
                            ).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
                          </td>
                          <td className={classes.cell}>{charge.min_age}</td>
                          <td className={classes.cell}>{charge.max_age}</td>
                          <td className={classes.cell}>{charge.amount}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <table className={classes.table}>
                    <thead className={classes.head}>
                      <tr>
                        <th>#</th>
                        <th>Description</th>
                        <th>Operation From</th>
                        <th>Operation To</th>
                        <th>Min Age</th>
                        <th>Max Age</th>
                        <th>Amount</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {values.extra_charges.map((charge, idx) => (
                        <tr key={idx}>
                          <td className={classes.cell}>{idx + 1}</td>
                          <td className={classes.cell}>
                            <NormalInputField
                              name={`extra_charges.${idx}.description`}
                            />
                          </td>
                          <td className={classes.cell}>
                            <NormalDatePicker
                              name={`extra_charges.${idx}.operation_from`}
                              minAvailableDate={DateTime.now().toISODate()}
                              maxAvailableDate={DateTime.now()
                                .plus({ days: 365 * 10 })
                                .toISODate()}
                              showYearDropdown={true}
                            />
                          </td>
                          <td className={classes.cell}>
                            <NormalDatePicker
                              name={`extra_charges.${idx}.operation_to`}
                              minAvailableDate={DateTime.now().toISODate()}
                              maxAvailableDate={DateTime.now()
                                .plus({ days: 365 * 10 })
                                .toISODate()}
                              showYearDropdown={true}
                            />
                          </td>
                          <td className={classes.cell}>
                            <NormalInputField
                              name={`extra_charges.${idx}.min_age`}
                              type="number"
                            />
                          </td>
                          <td className={classes.cell}>
                            <NormalInputField
                              name={`extra_charges.${idx}.max_age`}
                              type="number"
                            />
                          </td>
                          <td className={classes.cell}>
                            <NormalInputField
                              name={`extra_charges.${idx}.amount`}
                              type="number"
                            />
                          </td>
                          <td className={classes.cell}>
                            <CustomButton
                              appearance="ghost"
                              onClick={() =>
                                setFieldValue("extra_charges", [
                                  ..._.cloneDeep(values.extra_charges).filter(
                                    (__, jidx) => idx !== jidx
                                  ),
                                ])
                              }>
                              Remove
                            </CustomButton>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </Form>
            </div>
            <div className={classes.actions}>
              {mode === "edit" && (
                <React.Fragment>
                  <CustomButton
                    onClick={() =>
                      setFieldValue("extra_charges", [
                        ..._.cloneDeep(values.extra_charges),
                        chargeSchema.cast({}),
                      ])
                    }>
                    Add Charge
                  </CustomButton>
                  <CustomButton
                    onClick={() =>
                      onSubmit({ extra_charges: values.extra_charges })
                    }>
                    Submit
                  </CustomButton>
                </React.Fragment>
              )}
              {mode === "view" && (
                <CustomButton onClick={() => setMode("edit")}>
                  Edit
                </CustomButton>
              )}
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default ExtraCharges;
