import service from "@src/api/request";

export function getAffiliatesList(para) {
  return service({
    url: "/bid/affiliate/",
    method: "get",
    params: para,
  });
}

export function getQuickAffiliatesList(para) {
  return service({
    url: "/bid/affiliate/get_quick_info/",
    method: "get",
    params: para,
  });
}

export function fetchAffiliatesProfile(id) {
  return service({
    url: `/bid/affiliate/${id}`,
    method: "get",
  });
}

export function getAffiliatesOptions(lang) {
  return service({
    url: "/bid/affiliate/",
    method: "options",
    headers: {
      "Accept-Language": lang,
    },
  });
}

export function submitAffiliatesProfile(id, payload, mode) {
  return service({
    url: `/bid/affiliate/${id}/`,
    method: mode,
    data: payload,
  });
}

export function addNewAffiliates(data) {
  return service({
    url: "/bid/affiliate/",
    method: "post",
    data: data,
  });
}
