export const USER_UPDATE_DETAILS = "USER_UPDATE_DETAILS";
export const USER_FETCHED_DETAILS = "USER_FETCHED_DETAILS";
//======================================LIST===========================================
export const USER_BEGIN_FETCH_DATA = "USER_BEGIN_FETCH_DATA";
export const USER_FETCH_DATA = "USER_FETCH_DATA";
export const USER_END_FETCH_DATA = "USER_END_FETCH_DATA";
export const USER_FETCH_OPTIONS = "USER_FETCH_OPTIONS";
//=====================================FILTER==========================================
export const USER_APPLY_LIST_FILTER = "USER_APPLY_LIST_FILTER";
export const USER_UPDATE_LIST_FILTER_FORM = "USER_UPDATE_LIST_FILTER_FORM";
export const USER_INITIALIZE_LIST_FILTER_FORM =
  "USER_INITIALIZE_LIST_FILTER_FORM";
export const USER_UPDATE_FILTERS = "USER_UPDATE_FILTERS";
export const USER_FILTERS_GENERIC_CHANGE = "USER_FILTERS_GENERIC_CHANGE";

// ==================== COLUMNS ====================
export const USER_UPDATE_LIST_COLUMNS_FORM = "USER_UPDATE_LIST_COLUMNS_FORM";
export const USER_INITIALIZE_LIST_COLUMNS_FORM =
  "USER_INITIALIZE_LIST_COLUMNS_FORM";
export const USER_APPLY_LIST_COLUMNS = "USER_APPLY_LIST_COLUMNS";
export const USER_RESET_LIST_FILTER_FORM = "USER_RESET_LIST_FILTER_FORM";

// =========================== User Profile ==========================
export const USER_PROFILE_LOADED = "USER_PROFILE_LOADED";
export const USER_PROFILE_LOADING = "USER_PROFILE_LOADING";
export const USER_PROFILE_UPDATE_JOB_DESCRIPTION =
  "USER_PROFILE_UPDATE_JOB_DESCRIPTION";
export const USER_FILTER_UPDATE_JOB_DESCRIPTION =
  "USER_FILTER_UPDATE_JOB_DESCRIPTION";
export const USER_INITIALIZE_USER_GENDER_FORM =
  "USER_INITIALIZE_USER_GENDER_FORM";

// ================== User Profile: Use Base Section =================
export const USER_CHANGE_EMAIL = "USER_CHANGE_EMAIL";
export const USER_BEGIN_SUBMIT_NEXT_EMAIL = "USER_BEGIN_SUBMIT_NEXT_EMAIL";
export const USER_END_SUBMIT_NEXT_EMAIL = "USER_END_SUBMIT_NEXT_EMAIL";
export const USER_BEGIN_CHANGE_USER_PASSWORD =
  "USER_BEGIN_CHANGE_USER_PASSWORD";
export const USER_END_CHANGE_USER_PASSWORD = "USER_END_SUBMIT_NEXT_EMAIL";
export const USER_BEGIN_CHANGE_USER_STATUS = "USER_BEGIN_CHANGE_USER_STATUS";
export const USER_END_CHANGE_USER_STATUS = "USER_END_CHANGE_USER_STATUS";

export const USER_VIEW_BASE_DATA_MODE = "USER_VIEW_BASE_DATA_MODE";
export const USER_EDIT_BASE_DATA_MODE = "USER_EDIT_BASE_DATA_MODE";

export const USER_EDIT_BASE_DATA_FORM = "USER_EDIT_BASE_DATA_FORM";
export const USER_EDIT_JOB_DESCRIPTION_FORM = "USER_EDIT_JOB_DESCRIPTION_FORM";
export const USER_INITIALIZE_BASE_DATA_FORM = "USER_INITIALIZE_BASE_DATA_FORM";
export const USER_VALIDATE_BASE_DATA_FORM = "USER_VALIDATE_BASE_DATA_FORM";
export const USER_UPLOADING_BASE_DATA_MODE = "USER_UPLOADING_BASE_DATA_MODE";

// ================= User Profile: Expertise Section ================
export const USER_VIEW_USER_EXPERTISE_MODE = "USER_VIEW_USER_EXPERTISE_MODE";
export const USER_EDIT_USER_EXPERTISE_MODE = "USER_EDIT_USER_EXPERTISE_MODE";
export const USER_UPLOADING_USER_EXPERTISE_MODE =
  "USER_UPLOADING_USER_EXPERTISE_MODE";
export const USER_INITIALIZE_EXPERTISE_FORM = "USER_INITIALIZE_EXPERTISE_FORM";
export const USER_EDIT_EXPERTISE_FORM = "USER_EDIT_EXPERTISE_FORM";

// =============== User Profile: Travel Sector Section ==============
export const USER_VIEW_TRAVEL_SECTOR_MODE = "USER_VIEW_TRAVEL_SECTOR_MODE";
export const USER_EDIT_TRAVEL_SECTOR_MODE = "USER_EDIT_TRAVEL_SECTOR_MODE";
export const USER_UPLOADING_TRAVEL_SECTOR_MODE =
  "USER_UPLOADING_TRAVEL_SECTOR_MODE";

export const USER_EDIT_TRAVEL_SECTOR_FORM = "USER_EDIT_TRAVEL_SECTOR_FORM";
export const USER_INITIALIZE_TRAVEL_SECTOR_FORM =
  "USER_INITIALIZE_TRAVEL_SECTOR_FORM";

// ==================== User Profile: Preferences ===================
export const USER_VIEW_PREFERENCES_MODE = "USER_VIEW_PREFERENCES_MODE";
export const USER_EDIT_PREFERENCES_MODE = "USER_EDIT_PREFERENCES_MODE";
export const USER_UPLOADING_PREFERENCES_MODE =
  "USER_UPLOADING_PREFERENCES_MODE";

export const USER_INITIALIZE_LANGUAGE_FORM = "USER_INITIALIZE_LANGUAGE_FORM";
export const USER_EDIT_LANGUAGE_FORM = "USER_EDIT_LANGUAGE_FORM";
export const USER_INITIALIZE_IMAGE_FORM = "USER_INITIALIZE_IMAGE_FORM";
export const USER_UPLOAD_PREFERENCE_IMAGE = "USER_UPLOAD_PREFERENCE_IMAGE";

// ============== User Profile: Job Description Section =============
export const USER_VIEW_JOB_DESCRIPTION_MODE = "USER_VIEW_JOB_DESCRIPTION_MODE";
export const USER_EDIT_JOB_DESCRIPTION_MODE = "USER_EDIT_JOB_DESCRIPTION_MODE";
export const USER_UPLOADING_JOB_DESCRIPTION_MODE =
  "USER_UPLOADING_JOB_DESCRIPTION_MODE";

export const USER_INITIALIZE_DEPARTMENT_FORM =
  "USER_INITIALIZE_DEPARTMENT_FORM";
export const USER_INITIALIZE_JOB_DESCRIPTION_FORM =
  "USER_INITIALIZE_JOB_DESCRIPTION_FORM";
export const USER_FORMAT_JOB_DESCRIPTION_FORM =
  "USER_FORMAT_JOB_DESCRIPTION_FORM";
export const USER_EDIT_DEPARTMENT_FORM = "USER_EDIT_DEPARTMENT_FORM";

// ======= User Profile: User Work Duties Section Actions ======
export const USER_VIEW_WORK_DUTIES_MODE = "USER_VIEW_WORK_DUTIES_MODE";
export const USER_EDIT_WORK_DUTIES_MODE = "USER_EDIT_WORK_DUTIES_MODE";
export const USER_UPLOADING_WORK_DUTIES_MODE =
  "USER_UPLOADING_WORK_DUTIES_MODE";

// ==================== Service Specialty Section ====================
export const USER_VIEW_SERVICE_SPECIALTY_MODE =
  "USER_VIEW_SERVICE_SPECIALTY_MODE";
export const USER_EDIT_SERVICE_SPECIALTY_MODE =
  "USER_EDIT_SERVICE_SPECIALTY_MODE";
export const USER_LOADING_SERVICE_SPECIALTY_MODE =
  "USER_LOADING_SERVICE_SPECIALTY_MODE";

export const USER_INITIALIZE_SERVICE_SPECIALTY_FORM =
  "USER_INITIALIZE_SERVICE_SPECIALTY_FORM";
export const USER_LOADING_SERVICE_CATEGORY_MODE =
  "USER_LOADING_SERVICE_CATEGORY_MODE";
export const USER_SELECT_TRAVEL_SERVICE = "USER_SELECT_TRAVEL_SERVICE";
export const USER_INITIALIZE_TRAVEL_SERVICE_FORM =
  "USER_INITIALIZE_TRAVEL_SERVICE_FORM";
export const USER_SELECT_SERVICE_CATEGORY = "USER_SELECT_SERVICE_CATEGORY";
export const USER_VIEW_SERVICE_CATEGORIES_MODE =
  "USER_VIEW_SERVICE_CATEGORIES_MODE";
export const USER_EDIT_SERVICE_CATEGORIES_MODE =
  "USER_EDIT_SERVICE_CATEGORIES_MODE";

export const USER_PERMISSION_FEATURE_GROUPS = "USER_PERMISSION_FEATURE_GROUPS";

export const USER_EMAIL_CONFIG_LIST = "USER_EMAIL_CONFIG_LIST";

export * from "./add_user_types";

// ===================== NEW TYPES ==================
export const USERLIST_FILTER_CHANGE = "USERLIST_FILTER_CHANGE";
export const USERLIST_SAVE_LIST_DATA = "USERLIST_SAVE_LIST_DATA";
