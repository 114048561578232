import _ from "lodash";
import { View, Text, StyleSheet } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import React from "react";
import { DateTime } from "luxon";

const styles = StyleSheet.create({
  CancellationPolicy: { width: 550, borderRadius: 5, padding: 5 },
  text: { fontSize: 10 },
  header: {
    fontSize: 14,
    fontFamily: "Noto Sans",
    fontWeight: "bold",
    margin: "5px 0px",
  },
  cancelationContainer: { width: 550, paddingBottom: 5, paddingLeft: 5 },
  termsContainer: {
    width: "50%",
    fontSize: 10,
    border: "1px solid #302A83",
    borderRadius: 5,
    padding: 5,
    gap: 5,
  },
  termDescription: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  total: { flexDirection: "row", justifyContent: "flex-end" },
  totalPrice: { fontFamily: "Helvetica-Bold" },
  divider: { height: 1, backgroundColor: "grey", marginVertical: 2 },
});
const CancellationPolicy = ({ cancellationPolicy }) => {
  const mode = cancellationPolicy?.mode ?? "prepaid";
  const deposit = cancellationPolicy?.deposit ?? 0;
  const balance = cancellationPolicy?.balance ?? 0;
  var totalPaymentTerms = deposit + balance;
  const cancellationPolicies = cancellationPolicy?.cancellation_policies ?? [];
  const isNonRefundable = deposit > 0 && mode === "prepaid";

  if (cancellationPolicy?.totalPriceRounding === "round-up") {
    totalPaymentTerms = Math.ceil(totalPaymentTerms);
  } else if (cancellationPolicy?.totalPriceRounding === "round-down") {
    totalPaymentTerms = Math.floor(totalPaymentTerms);
  }

  return (
    <View style={styles.CancellationPolicy}>
      <Text style={styles.header}>Cancellation Policy</Text>
      {isNonRefundable && (
        <View style={styles.cancelationContainer}>
          <Text style={styles.text}>This offer is non refundable</Text>
        </View>
      )}
      {!isNonRefundable &&
        cancellationPolicies.map((policy, idx) => (
          <View style={styles.cancelationContainer} key={idx}>
            {policy.type === "refundable" ? (
              <Text style={styles.text}>
                From{" "}
                {DateTime.fromISO(policy.from, {
                  setZone: true,
                })
                  .setLocale("en-US")
                  .toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)}{" "}
                to{" "}
                {DateTime.fromISO(policy.to, { setZone: true })
                  .setLocale("en-US")
                  .toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)}{" "}
                cancelation is free
              </Text>
            ) : (
              <Text style={styles.text}>
                From{" "}
                {DateTime.fromISO(policy.from, {
                  setZone: true,
                })
                  .setLocale("en-US")
                  .toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)}{" "}
                to{" "}
                {DateTime.fromISO(policy.to, { setZone: true })
                  .setLocale("en-US")
                  .toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)}{" "}
                cancellation is chargeable with{" "}
                {policy.fee.toLocaleString("en-US", {
                  style: "currency",
                  currency: policy.currency,
                })}
              </Text>
            )}
          </View>
        ))}
      <View style={styles.paymentTerms}>
        <Text style={styles.header}>Payment Terms</Text>
        <View style={styles.termsContainer}>
          {!isNonRefundable && (
            <React.Fragment>
              <View style={styles.termDescription}>
                <Text style={styles.text}>Deposit (non-refundable)</Text>
                <Text style={styles.text}>
                  {" "}
                  {cancellationPolicy.deposit.toLocaleString("en-US", {
                    style: "currency",
                    currency: cancellationPolicy.currency,
                  })}
                </Text>
              </View>
              <View style={styles.termDescription}>
                <Text style={styles.text}>Balance</Text>
                <Text style={styles.text}>
                  {" "}
                  {cancellationPolicy.balance.toLocaleString("en-US", {
                    style: "currency",
                    currency: cancellationPolicy.currency,
                  })}
                </Text>
              </View>
              <View style={styles.divider}></View>
            </React.Fragment>
          )}
          <View style={styles.total}>
            <Text>
              <Text>Total: </Text>
              <Text style={styles.totalPrice}>
                {totalPaymentTerms.toLocaleString("en-US", {
                  style: "currency",
                  currency: cancellationPolicy.currency,
                })}
              </Text>
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};
CancellationPolicy.defaultProps = { cancellationPolicy: {} };
CancellationPolicy.propTypes = { cancellationPolicy: PropTypes.object };
export default CancellationPolicy;
