import {
  GEO_POLICY_FETCH_DATA,
  GEO_POLICY_BEGIN_FETCH_DATA,
  GEO_POLICY_END_FETCH_DATA,
  GEO_POLICY_INITIALIZE_LIST_FILTER_FORM,
  GEO_POLICY_UPDATE_LIST_FILTER_FORM,
  GEO_POLICY_APPLY_LIST_FILTER,
  GEO_POLICY_UPDATE_LIST_COLUMNS_FORM,
  GEO_POLICY_APPLY_LIST_COLUMNS,
  GEO_POLICY_INITIALIZE_LIST_COLUMNS_FORM,
  COUNTRIES_DATA_FETCH_LIST,
  GEO_POLICY_FETCH_OPTIONS,
  GEO_POLICY_INITIALIZE_BASE_DATA_EDIT_FORM,
  GEO_POLICY_CLEAN_BASE_DATA_EDIT_FORM,
  GEO_POLICY_EDIT_BASE_DATA,
  GEO_POLICY_VALIDATE_BASE_DATA_EDIT_FORM,
  COUNTRIES_LIST_FETCH_OPTIONS,
  COUNTRIES_FORMAT_OPTIONS,
  GEO_POLICY_RESET_LIST_FILTER_FORM,
  GEO_POLICY_FETCH_GRAPH_DATA,
  //==========================================EDIT FORM====================================
  GEO_POLICY_INIT_BASE_DATA_EDIT_FORM,
  GEO_POLICY_FETCH_EDIT_DATA,
  //=====================================MODAL=====================================
  GEO_POLICY_LOADING_EDIT_FORM,
  GEO_POLICY_IDLE_EDIT_FORM,
  GEO_POLICY_HIDE_FORM_MODAL,
  GEO_POLICY_SHOW_FORM_MODAL,
} from "@src/actions/Admin/GeoPolicy/types";

import {
  FORMAT_BRANCH_LIST_DATA,
  FORMAT_SUB_AGENT_LIST_DATA,
  FORMAT_INDEPENDENT_AGENT_LIST_DATA,
} from "@src/actions/Admin/GroupPolicy/types";

import { Schema } from "rsuite";

import {
  FORMAT_TRAVEL_SERVICES_OPTIONS,
  FORMAT_TRAVEL_SERVICES_FORM,
} from "@src/actions/Shares/types";

import {
  initialGeoPolicyColumnsSetUp,
  initialGeoPolicyFilterForm,
  initialGeoPolicyBaseDataForm,
} from "@src/forms/Admin/GeoPolicy";

import update from "immutability-helper";
import _ from "lodash";
import { formProcessor, entitiesListSelector } from "@src/reducers/tools";

export const initialGeoPolicyFetchStatus = "IDLE";
export const geoPolicyFetchStatus = (
  state = initialGeoPolicyFetchStatus,
  action
) => {
  switch (action.type) {
    case GEO_POLICY_BEGIN_FETCH_DATA:
      return "FETCHING";
    case GEO_POLICY_END_FETCH_DATA:
      return "FETCHED";
    default:
      return state;
  }
};

export const initialGroupPolicyList = {
  count: 0,
  next: null,
  previous: null,
  results: [],
  page: 1,
  limit: 20,
  displayLength: 100,
};

export const geoPolicyList = (state = initialGroupPolicyList, action) => {
  switch (action.type) {
    case GEO_POLICY_FETCH_DATA:
      return action.data;
    case GEO_POLICY_RESET_LIST_FILTER_FORM:
      return { ...state, page: 1 };
    default:
      return state;
  }
};

// columns
export const geoPolicyListColumns = (
  state = entitiesListSelector(initialGeoPolicyColumnsSetUp),
  action
) => {
  switch (action.type) {
    case GEO_POLICY_APPLY_LIST_COLUMNS:
      return entitiesListSelector(action.data);
    default:
      return state;
  }
};

export const geoPolicyListColumnsForm = (
  state = initialGeoPolicyColumnsSetUp,
  action
) => {
  switch (action.type) {
    case GEO_POLICY_INITIALIZE_LIST_COLUMNS_FORM: {
      var newState = {};
      const tmp = _.cloneDeep(state);
      for (const key in tmp) {
        // clear form's value
        tmp[key] = update(tmp[key], { value: { $set: [] } });
        newState[key] = update(tmp[key], { value: { $set: [] } });
        // add value by action data
        action.data.forEach((item) => {
          if (_.find(tmp[key].choices, { key: item.key })) {
            newState[key] = update(newState[key], {
              value: { $push: [item.key] },
            });
          }
        });
      }
      return newState;
    }
    case GEO_POLICY_UPDATE_LIST_COLUMNS_FORM:
      return action.data;
    default:
      return state;
  }
};

// filter
export const geoPolicyListFilter = (
  state = initialGeoPolicyFilterForm,
  action
) => {
  switch (action.type) {
    case GEO_POLICY_APPLY_LIST_FILTER:
      return action.data;
    case FORMAT_TRAVEL_SERVICES_FORM:
      return update(state, { $merge: action.data });
    case GEO_POLICY_RESET_LIST_FILTER_FORM:
      return action.data;
    default:
      return state;
  }
};

export const geoPolicyListFilterForm = (
  state = initialGeoPolicyFilterForm,
  action
) => {
  switch (action.type) {
    case GEO_POLICY_INITIALIZE_LIST_FILTER_FORM:
      return action.data;
    case GEO_POLICY_RESET_LIST_FILTER_FORM:
      return action.data;
    case GEO_POLICY_UPDATE_LIST_FILTER_FORM:
      return formProcessor(
        state,
        action.data.type,
        action.data.name,
        action.data.value
      );
    case FORMAT_TRAVEL_SERVICES_FORM:
      return update(state, { $merge: action.data });
    case FORMAT_TRAVEL_SERVICES_OPTIONS:
      return update(state, { $merge: action.data });
    case GEO_POLICY_FETCH_OPTIONS:
      return update(state, { $merge: action.data.travel_entities });
    case COUNTRIES_LIST_FETCH_OPTIONS:
      return update(state, { $merge: action.data.continent });
    case COUNTRIES_FORMAT_OPTIONS:
      return update(state, { $merge: action.data });
    default:
      return state;
  }
};

//add list
export const geoPolicyBaseDataForm = (
  state = initialGeoPolicyBaseDataForm,
  action
) => {
  switch (action.type) {
    case GEO_POLICY_INITIALIZE_BASE_DATA_EDIT_FORM:
      return action.data;
    case GEO_POLICY_INIT_BASE_DATA_EDIT_FORM:
      return action.data;
    case GEO_POLICY_CLEAN_BASE_DATA_EDIT_FORM:
      return _.merge(initialGeoPolicyBaseDataForm, action.data);
    case GEO_POLICY_EDIT_BASE_DATA:
      return formProcessor(
        _.merge(state, action.data),
        action.data.type,
        action.data.name,
        action.data.value
      );
    case FORMAT_TRAVEL_SERVICES_FORM:
      return update(state, { $merge: action.data });
    case FORMAT_TRAVEL_SERVICES_OPTIONS:
      return update(state, { $merge: action.data });
    case GEO_POLICY_FETCH_OPTIONS:
      return update(state, { $merge: action.data.travel_entities });
    case COUNTRIES_LIST_FETCH_OPTIONS:
      return update(state, { $merge: action.data.continent });
    case COUNTRIES_FORMAT_OPTIONS:
      return update(state, { $merge: action.data });
    case FORMAT_BRANCH_LIST_DATA:
      return _.cloneDeep({ ...state, ...action.data });
    case FORMAT_SUB_AGENT_LIST_DATA:
      return _.cloneDeep({ ...state, ...action.data });
    case FORMAT_INDEPENDENT_AGENT_LIST_DATA:
      return _.cloneDeep({ ...state, ...action.data });
    default:
      return state;
  }
};

export const geoPolicyOptions = (state = {}, action) => {
  switch (action.type) {
    case GEO_POLICY_FETCH_OPTIONS:
      return update(state, { $merge: action.data });
    case FORMAT_TRAVEL_SERVICES_OPTIONS:
      return update(state, { $merge: action.data });
    default:
      return state;
  }
};

// validate
export const initialGeoPolicyBaseDataFormErrorsState = { valid: false };
export const geoPolicyBaseDataFormErrors = (
  state = initialGeoPolicyBaseDataFormErrorsState,
  action
) => {
  const tmp = {};
  Object.entries(initialGeoPolicyBaseDataForm).forEach((e) => {
    tmp[e[0]] = e[1].validator;
  });
  const validator = Schema.Model(tmp);

  switch (action.type) {
    case GEO_POLICY_VALIDATE_BASE_DATA_EDIT_FORM: {
      const tmp = {};
      Object.entries(action.data).forEach((e) => (tmp[e[0]] = e[1].value));
      const validation = validator.check(tmp);

      const valid = Object.entries(validation).every((e) => !e[1].hasError);
      validation["valid"] = valid;
      return validation;
    }
    default:
      return state;
  }
};

export const countriesListOptions = (state = {}, action) => {
  switch (action.type) {
    case COUNTRIES_LIST_FETCH_OPTIONS:
      return update(state, { $merge: action.data });
    case FORMAT_TRAVEL_SERVICES_OPTIONS:
      return update(state, { $merge: action.data });
    default:
      return state;
  }
};

export const countriesData = (state = {}, action) => {
  switch (action.type) {
    case COUNTRIES_DATA_FETCH_LIST:
      return action.data;
    default:
      return state;
  }
};

export const initialGeoPolicyOptionsFetchStatus = "IDLE";
export const geoPolicyOptionsFetchStatus = (
  state = initialGeoPolicyOptionsFetchStatus,
  action
) => {
  switch (action.type) {
    case GEO_POLICY_FETCH_OPTIONS:
      return "FETCHED";
    default:
      return state;
  }
};

export const geoPolicyGraphData = (state = {}, action) => {
  switch (action.type) {
    case GEO_POLICY_FETCH_GRAPH_DATA:
      return action.data;
    default:
      return state;
  }
};

export const geoPolicyEditData = (state = {}, action) => {
  switch (action.type) {
    case GEO_POLICY_FETCH_EDIT_DATA:
      return action.data;
    default:
      return state;
  }
};

export const initialGeoPolicyEditFormStatus = {
  loading: false,
  show: false,
  mode: "",
};
export const geoPolicyFormModal = (
  state = initialGeoPolicyEditFormStatus,
  action
) => {
  switch (action.type) {
    case GEO_POLICY_LOADING_EDIT_FORM:
      return { ...state, loading: true };
    case GEO_POLICY_IDLE_EDIT_FORM:
      return { ...state, loading: false };
    case GEO_POLICY_HIDE_FORM_MODAL:
      return initialGeoPolicyEditFormStatus;
    case GEO_POLICY_SHOW_FORM_MODAL:
      return { ...state, show: true, mode: action.data.mode };
    default:
      return state;
  }
};
