import _ from "lodash";
import {
  TRIPPLANNER_ALTER_SEARCH_STATE,
  TRIPPLANNER_REMOVE_TRAIN_RESULT,
  TRIPPLANNER_RESET_TRAINS,
  TRIPPLANNER_SAVE_TRAINS_RESULT,
  TRIPPLANNER_SAVE_TRAINS_SESSION,
} from "@src/actions/Project/TripPlanner/trains";
import {
  TRIPPLANNER_INIT,
  TRIPPLANNER_LOAD_TRIP,
} from "@src/actions/Project/TripPlanner/types";

const tripPlannerTrainsReducerInitial = {
  services: [],
  sessions: [],
  leg_search_state: {},
};
export const tripPlannerTrainsReducer = (
  state = tripPlannerTrainsReducerInitial,
  action
) => {
  switch (action.type) {
    case TRIPPLANNER_INIT: {
      return tripPlannerTrainsReducerInitial;
    }
    case TRIPPLANNER_LOAD_TRIP: {
      {
        const { data } = action;
        return (
          data?.transportation_data?.trains ?? tripPlannerTrainsReducerInitial
        );
      }
    }
    case TRIPPLANNER_RESET_TRAINS: {
      return tripPlannerTrainsReducerInitial;
    }
    case TRIPPLANNER_SAVE_TRAINS_SESSION: {
      const { sessionData } = action;
      if (
        !(state?.sessions ?? [])
          .map((s) => s.session_id)
          .includes(sessionData.session_id)
      ) {
        const newState = _.cloneDeep(state);
        newState.sessions.push(sessionData);
        return newState;
      }
      return state;
    }
    case TRIPPLANNER_SAVE_TRAINS_RESULT: {
      const { trainData } = action;
      if (!(state?.services ?? []).map((s) => s.uid).includes(trainData.id)) {
        const newState = _.cloneDeep(state);
        newState.services.push(trainData);
        return newState;
      }
      return state;
    }
    case TRIPPLANNER_REMOVE_TRAIN_RESULT: {
      const { id } = action;
      const newState = _.cloneDeep(state);
      newState.services = newState.services.filter((s) => s.id !== id);
      return newState;
    }
    case TRIPPLANNER_ALTER_SEARCH_STATE: {
      const { legUid, searchState } = action;
      const newState = _.cloneDeep(state);
      newState.leg_search_state[legUid] = searchState;
      return newState;
    }
    default:
      return state;
  }
};
