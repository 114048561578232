import {
  QUOTATION_REQUEST_LIST_INIT,
  QUOTATION_REQUEST_LIST_LOADING,
  QUOTATION_REQUEST_LIST_IDLE,
  QUOTATION_REQUEST_LIST_GENERIC_CHANGE,
  QUOTATION_REQUEST_LIST_UPDATE,
  QUOTATION_REQUEST_LIST_RESET_FILTERS,
} from "@src/actions/Project/QuotationRequest/list/types";

import { commonList } from "@src/reducers/common";

export const filters = {
  reference: "",
  title: "",
  poi: "",
  required_services: "",
  sent_date_before: "",
  sent_date_after: "",
  due_date_before: "",
  due_date_after: "",
  assign_date_before: "",
  assign_date_after: "",
  is_raw: 0,
  source_entity: "",
  assigned_user_username: "",
};

const quotationRequestListReducerInitial = {
  ...commonList,
  filters: { ...filters },
};
export const QuotationRequestListReducer = (
  state = quotationRequestListReducerInitial,
  action
) => {
  switch (action.type) {
    case QUOTATION_REQUEST_LIST_INIT:
      return { ...quotationRequestListReducerInitial };
    case QUOTATION_REQUEST_LIST_LOADING:
      return { ...state, loading: true };
    case QUOTATION_REQUEST_LIST_IDLE:
      return { ...state, loading: false };
    case QUOTATION_REQUEST_LIST_GENERIC_CHANGE: {
      const { key, value } = action;
      return { ...state, [key]: value };
    }
    case QUOTATION_REQUEST_LIST_UPDATE: {
      const { data } = action;
      return { ...state, ...data };
    }
    case QUOTATION_REQUEST_LIST_RESET_FILTERS:
      return { ...state, filters: { ...filters } };
    default:
      return state;
  }
};
