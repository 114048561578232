import _ from "lodash";
import {
  USER_FETCH_OPTIONS,
  USER_BEGIN_FETCH_DATA,
  USER_FETCH_DATA,
  USER_END_FETCH_DATA,
  USER_INITIALIZE_LIST_FILTER_FORM,
  USER_FILTER_UPDATE_JOB_DESCRIPTION,
  USER_UPDATE_LIST_FILTER_FORM,
  USER_APPLY_LIST_FILTER,
  USER_APPLY_LIST_COLUMNS,
  USER_UPDATE_LIST_COLUMNS_FORM,
  USER_INITIALIZE_LIST_COLUMNS_FORM,
  USER_RESET_LIST_FILTER_FORM,
  // ========================== User Profile =========================
  USER_PROFILE_LOADED,
  USER_PROFILE_LOADING,
  USER_PROFILE_UPDATE_JOB_DESCRIPTION,
  // ============== User Profile: User Base Data Section =============
  USER_FETCHED_DETAILS,
  USER_UPDATE_DETAILS,
  USER_CHANGE_EMAIL,
  USER_BEGIN_SUBMIT_NEXT_EMAIL,
  USER_END_SUBMIT_NEXT_EMAIL,
  USER_END_CHANGE_USER_PASSWORD,
  USER_BEGIN_CHANGE_USER_PASSWORD,
  USER_BEGIN_CHANGE_USER_STATUS,
  USER_END_CHANGE_USER_STATUS,
  USER_VIEW_BASE_DATA_MODE,
  USER_EDIT_BASE_DATA_MODE,
  USER_UPLOADING_BASE_DATA_MODE,
  USER_VALIDATE_BASE_DATA_FORM,
  USER_INITIALIZE_BASE_DATA_FORM,
  USER_EDIT_BASE_DATA_FORM,
  USER_INITIALIZE_USER_GENDER_FORM,
  // ================ User Profile: Expertise Section ================
  USER_VIEW_USER_EXPERTISE_MODE,
  USER_EDIT_USER_EXPERTISE_MODE,
  USER_UPLOADING_USER_EXPERTISE_MODE,
  USER_INITIALIZE_EXPERTISE_FORM,
  USER_EDIT_EXPERTISE_FORM,
  // ================ User Profile: Travel Sector Section ===============
  USER_VIEW_TRAVEL_SECTOR_MODE,
  USER_EDIT_TRAVEL_SECTOR_MODE,
  USER_UPLOADING_TRAVEL_SECTOR_MODE,
  USER_EDIT_TRAVEL_SECTOR_FORM,
  USER_INITIALIZE_TRAVEL_SECTOR_FORM,
  // =============== User Profile: Preferences Section ===============
  USER_VIEW_PREFERENCES_MODE,
  USER_EDIT_PREFERENCES_MODE,
  USER_UPLOADING_PREFERENCES_MODE,
  USER_INITIALIZE_LANGUAGE_FORM,
  USER_INITIALIZE_IMAGE_FORM,
  USER_EDIT_LANGUAGE_FORM,
  USER_UPLOAD_PREFERENCE_IMAGE,
  // ============= User Profile: Job Description Section =============
  USER_VIEW_JOB_DESCRIPTION_MODE,
  USER_EDIT_JOB_DESCRIPTION_MODE,
  USER_UPLOADING_JOB_DESCRIPTION_MODE,
  USER_INITIALIZE_DEPARTMENT_FORM,
  USER_FORMAT_JOB_DESCRIPTION_FORM,
  USER_EDIT_JOB_DESCRIPTION_FORM,
  USER_INITIALIZE_JOB_DESCRIPTION_FORM,
  USER_EDIT_DEPARTMENT_FORM,
  // ======= User Profile: User Work Duties Section Actions ======
  USER_VIEW_WORK_DUTIES_MODE,
  USER_EDIT_WORK_DUTIES_MODE,
  USER_UPLOADING_WORK_DUTIES_MODE,
  // ============ User Profile: Service Specialty Section ============
  USER_VIEW_SERVICE_SPECIALTY_MODE,
  USER_EDIT_SERVICE_SPECIALTY_MODE,
  USER_LOADING_SERVICE_SPECIALTY_MODE,
  USER_SELECT_SERVICE_CATEGORY,
  USER_INITIALIZE_SERVICE_SPECIALTY_FORM,
  USER_INITIALIZE_TRAVEL_SERVICE_FORM,
  USER_SELECT_TRAVEL_SERVICE,
  USER_LOADING_SERVICE_CATEGORY_MODE,
  USER_VIEW_SERVICE_CATEGORIES_MODE,
  USER_EDIT_SERVICE_CATEGORIES_MODE,

  //graph
  USER_PERMISSION_FEATURE_GROUPS,
  USER_EMAIL_CONFIG_LIST,
  USER_UPDATE_FILTERS,
  USER_FILTERS_GENERIC_CHANGE,
} from "@src/actions/Admin/Users/types";

import {
  FORMAT_TRAVEL_SERVICES_OPTIONS,
  FORMAT_TRAVEL_SERVICES_FORM,
  BEGIN_FETCH_ENTITY,
  END_FETCH_ENTITY,
} from "@src/actions/Shares/types";

import { BRANCHES_FETCH_DATA } from "@src/actions/Network/Branches/types";
import { SUBAGENTS_FETCH_DATA } from "@src/actions/Network/SubAgents/types";
import { INDEPENDENT_AGENTS_FETCH_DATA } from "@src/actions/Network/IndependentAgents/types";
import { AFFILIATES_FETCH_DATA } from "@src/actions/Network/Affiliates/types";

import {
  initialUsersFilterForm,
  initialUsersColumnsSetUp,
  initialUserForm,
} from "@src/forms/Admin/Company/userForms";

import {
  formProcessor,
  travelServicesFormProcessor,
  entitiesListSelector,
} from "@src/reducers/tools";

import update from "immutability-helper";
import { commonList } from "../../common";

export const nextEmail = (state = "", action) => {
  switch (action.type) {
    case USER_CHANGE_EMAIL:
      return action.data;
    default:
      return state;
  }
};

export const initialNextEmailStatus = "LOADED";
export const nextEmailStatus = (state = initialNextEmailStatus, action) => {
  switch (action.type) {
    case USER_END_SUBMIT_NEXT_EMAIL:
      return "LOADED";
    case USER_BEGIN_SUBMIT_NEXT_EMAIL:
      return "LOADING";
    default:
      return state;
  }
};

export const initialChangeUserPasswordStatus = "LOADED";
export const changeUserPasswordStatus = (
  state = initialChangeUserPasswordStatus,
  action
) => {
  switch (action.type) {
    case USER_END_CHANGE_USER_PASSWORD:
      return "LOADED";
    case USER_BEGIN_CHANGE_USER_PASSWORD:
      return "LOADING";
    default:
      return state;
  }
};

export const initialChangeUserStatusStatus = "LOADED";
export const changeUserStatusStatus = (
  state = initialChangeUserStatusStatus,
  action
) => {
  switch (action.type) {
    case USER_END_CHANGE_USER_STATUS:
      return "LOADED";
    case USER_BEGIN_CHANGE_USER_STATUS:
      return "LOADING";
    default:
      return state;
  }
};

export const userMeta = (state = {}, action) => {
  switch (action.type) {
    case USER_FETCHED_DETAILS:
      return update(state, { $merge: action.data });
    case USER_UPDATE_DETAILS:
      return update(state, { $merge: action.data });
    default:
      return state;
  }
};

export const initialUserObjOptionsFetchStatus = "IDLE";
export const userObjOptionsFetchStatus = (
  state = initialUserObjOptionsFetchStatus,
  action
) => {
  switch (action.type) {
    case USER_FETCH_OPTIONS:
      return "FETCHED";
    default:
      return state;
  }
};

export const initialUserOptions = {};
export const userObjOptions = (state = {}, action) => {
  const data = update(state, { $merge: initialUserOptions });

  switch (action.type) {
    case USER_FETCH_OPTIONS:
      return update(data, { $merge: action.data });
    case FORMAT_TRAVEL_SERVICES_OPTIONS:
      return update(state, { $merge: action.data });
    default:
      return state;
  }
};

export const initialUsersList = {
  count: 0,
  next: null,
  previous: null,
  results: [],
  page: 1,
  limit: 20,
  displayLength: 100,
};
export const usersList = (state = initialUsersList, action) => {
  switch (action.type) {
    case USER_FETCH_DATA:
      return action.data;
    case USER_RESET_LIST_FILTER_FORM:
      return { ...state, page: 1 };
    default:
      return state;
  }
};

export const initialUsersListFetchStatus = "IDLE";
export const usersListFetchStatus = (
  state = initialUsersListFetchStatus,
  action
) => {
  switch (action.type) {
    case USER_BEGIN_FETCH_DATA:
      return "FETCHING";
    case USER_END_FETCH_DATA:
      return "FETCHED";
    default:
      return state;
  }
};

// filter
export const usersListFilter = (state = initialUsersFilterForm, action) => {
  switch (action.type) {
    case USER_APPLY_LIST_FILTER:
      return action.data;
    case FORMAT_TRAVEL_SERVICES_FORM:
      return update(state, { $merge: action.data });
    case USER_RESET_LIST_FILTER_FORM:
      return action.data;
    default:
      return state;
  }
};

// export const usersListFilterForm = (state = initialUsersFilterForm, action) => {
export const usersListFilterForm = (
  state = {
    ...commonList,
    limit: 20,
    displayLength: 100,
    filters: {
      company_type__in: [],
      first_name__icontains: "",
      last_name__icontains: "",
      email__icontains: "",
      creator__icontains: "",
      created__gte: "",
      created__lte: "",
      status__in: [],
    },
  },
  action
) => {
  switch (action.type) {
    case USER_INITIALIZE_LIST_FILTER_FORM:
      return action.data;
    case USER_RESET_LIST_FILTER_FORM:
      return action.data;
    case USER_UPDATE_LIST_FILTER_FORM:
      return formProcessor(
        state,
        action.data.type,
        action.data.name,
        action.data.value
      );
    case FORMAT_TRAVEL_SERVICES_FORM:
      return update(state, { $merge: action.data });
    case USER_FILTER_UPDATE_JOB_DESCRIPTION: {
      return update(state, {
        job_description: { data: { $set: action.data } },
      });
    }
    case USER_UPDATE_FILTERS: {
      const { data } = action;
      return { ...state, filters: data };
    }
    case USER_FILTERS_GENERIC_CHANGE: {
      const { key, value } = action;
      return { ...state, [key]: value };
    }
    default:
      return state;
  }
};

// columns
export const usersListColumns = (
  state = entitiesListSelector(initialUsersColumnsSetUp),
  action
) => {
  switch (action.type) {
    case USER_APPLY_LIST_COLUMNS:
      return entitiesListSelector(action.data);
    default:
      return state;
  }
};

export const usersListColumnsForm = (
  state = initialUsersColumnsSetUp,
  action
) => {
  switch (action.type) {
    case USER_INITIALIZE_LIST_COLUMNS_FORM: {
      var newState = {};
      const tmp = _.cloneDeep(state);
      for (const key in tmp) {
        // clear form's value
        tmp[key] = update(tmp[key], { value: { $set: [] } });
        newState[key] = update(tmp[key], { value: { $set: [] } });
        // add value by action data
        action.data.forEach((item) => {
          if (_.find(tmp[key].choices, { key: item.key })) {
            newState[key] = update(newState[key], {
              value: { $push: [item.key] },
            });
          }
        });
      }

      return newState;
    }
    case USER_UPDATE_LIST_COLUMNS_FORM:
      return action.data;
    default:
      return state;
  }
};

// ======================= User Profile Section ======================

export const userProfileStatus = (state = "LOADED", action) => {
  switch (action.type) {
    case USER_PROFILE_LOADING:
      return "LOADING";
    case USER_PROFILE_LOADED:
      return "LOADED";
    default:
      return state;
  }
};

export const initialUsersFetchStatus = "IDLE";
export const usersFetchStatus = (state = initialUsersFetchStatus, action) => {
  switch (action.type) {
    case USER_BEGIN_FETCH_DATA:
      return "FETCHING";
    case USER_END_FETCH_DATA:
      return "FETCHED";
    default:
      return state;
  }
};

export const initialUsersBaseDataMode = "VIEW";
export const userBaseDataMode = (state = initialUsersBaseDataMode, action) => {
  switch (action.type) {
    case USER_VIEW_BASE_DATA_MODE:
      return "VIEW";
    case USER_EDIT_BASE_DATA_MODE:
      return "EDIT";
    case USER_UPLOADING_BASE_DATA_MODE:
      return "LOADING";
    default:
      return state;
  }
};

export const initialUserExpertiseMode = "VIEW";
export const userExpertiseMode = (state = initialUserExpertiseMode, action) => {
  switch (action.type) {
    case USER_VIEW_USER_EXPERTISE_MODE:
      return "VIEW";
    case USER_EDIT_USER_EXPERTISE_MODE:
      return "EDIT";
    case USER_UPLOADING_USER_EXPERTISE_MODE:
      return "LOADING";
    default:
      return state;
  }
};

export const initialUserTravelSectorMode = "VIEW";
export const userTravelSectorMode = (
  state = initialUserTravelSectorMode,
  action
) => {
  switch (action.type) {
    case USER_VIEW_TRAVEL_SECTOR_MODE:
      return "VIEW";
    case USER_EDIT_TRAVEL_SECTOR_MODE:
      return "EDIT";
    case USER_UPLOADING_TRAVEL_SECTOR_MODE:
      return "LOADING";
    default:
      return state;
  }
};

export const initialUserPreferencesMode = "VIEW";
export const userPreferencesMode = (
  state = initialUserPreferencesMode,
  action
) => {
  switch (action.type) {
    case USER_VIEW_PREFERENCES_MODE:
      return "VIEW";
    case USER_EDIT_PREFERENCES_MODE:
      return "EDIT";
    case USER_UPLOADING_PREFERENCES_MODE:
      return "LOADING";
    default:
      return state;
  }
};

export const initialUserJobDescriptionMode = "VIEW";
export const userJobDescriptionMode = (
  state = initialUserJobDescriptionMode,
  action
) => {
  switch (action.type) {
    case USER_VIEW_JOB_DESCRIPTION_MODE:
      return "VIEW";
    case USER_EDIT_JOB_DESCRIPTION_MODE:
      return "EDIT";
    case USER_UPLOADING_JOB_DESCRIPTION_MODE:
      return "LOADING";
    default:
      return state;
  }
};

export const initialUserWorkDutiesMode = "VIEW";
export const userWorkDutiesMode = (
  state = initialUserWorkDutiesMode,
  action
) => {
  switch (action.type) {
    case USER_VIEW_WORK_DUTIES_MODE:
      return "VIEW";
    case USER_EDIT_WORK_DUTIES_MODE:
      return "EDIT";
    case USER_UPLOADING_WORK_DUTIES_MODE:
      return "LOADING";
    default:
      return state;
  }
};

// ============= User Profile: Service Specialty Section =============
export const initialUserProfileServiceSpecialtyMode = "VIEW";
export const userProfileServiceSpecialtyMode = (
  state = userProfileServiceSpecialtyMode,
  action
) => {
  switch (action.type) {
    case USER_VIEW_SERVICE_SPECIALTY_MODE:
      return "VIEW";
    case USER_EDIT_SERVICE_SPECIALTY_MODE:
      return "EDIT";
    case USER_LOADING_SERVICE_SPECIALTY_MODE:
      return "LOADING";
    default:
      return state;
  }
};

export const initialUserServiceCategoriesMode = "VIEW";
export const userServiceCategoriesMode = (
  state = initialUserServiceCategoriesMode,
  action
) => {
  switch (action.type) {
    case USER_VIEW_SERVICE_CATEGORIES_MODE:
      return "VIEW";
    case USER_EDIT_SERVICE_CATEGORIES_MODE:
      return "EDIT";
    case USER_LOADING_SERVICE_CATEGORY_MODE:
      return "LOADING";
    default:
      return state;
  }
};

export const userSelectedServiceCategory = (state = "", action) => {
  switch (action.type) {
    case USER_SELECT_SERVICE_CATEGORY:
      return action.data;
    default:
      return state;
  }
};

// ===================== User Profile: User Form ====================
export const initialSales = {
  branches: { legal_title: "branch", id: "branch", children: [] },
  subagents: { legal_title: "subagent", id: "subagent", children: [] },
  independentagents: {
    legal_title: "independentagent",
    id: "independentagent",
    children: [],
  },
};
export const userAddEntities = (state = initialSales, action) => {
  switch (action.type) {
    case BRANCHES_FETCH_DATA: {
      return update(state, {
        branches: { children: { $set: action.data.results } },
      });
    }

    case SUBAGENTS_FETCH_DATA: {
      return update(state, {
        subagents: { children: { $set: action.data.results } },
      });
    }

    case INDEPENDENT_AGENTS_FETCH_DATA: {
      return update(state, {
        independentagents: { children: { $set: action.data.results } },
      });
    }

    default:
      return state;
  }
};

export const initialSupplies = {
  affiliates: { legal_title: "affiliate", id: "affiliate", children: [] },
};
export const userAddSupplies = (state = initialSupplies, action) => {
  switch (action.type) {
    case AFFILIATES_FETCH_DATA: {
      return update(state, {
        affiliates: { children: { $set: action.data.results } },
      });
    }
    default:
      return state;
  }
};

export const initialFetctSalesPartnerStatus = "LOADED";
export const fetctSalesPartnerStatus = (
  state = initialFetctSalesPartnerStatus,
  action
) => {
  switch (action.type) {
    case BEGIN_FETCH_ENTITY:
      return "LOADING";
    case END_FETCH_ENTITY:
      return "LOADED";
    default:
      return state;
  }
};

export const userForm = (state = initialUserForm, action) => {
  switch (action.type) {
    case USER_INITIALIZE_TRAVEL_SERVICE_FORM:
      return update(state, { travel_services: { $set: action.data } });
    case USER_SELECT_TRAVEL_SERVICE:
      return travelServicesFormProcessor(
        state,
        action.data.type,
        "travel_services",
        action.data.value,
        action.data.name,
        action.data.travelServices
      );
    case USER_VALIDATE_BASE_DATA_FORM: {
      let newState = state;
      action.data.errorMsgs.forEach(
        (e) =>
          (newState = update(newState, {
            [e.field]: { errorMsg: { $set: e.msg } },
          }))
      );

      return newState;
    }
    case USER_INITIALIZE_BASE_DATA_FORM: {
      let newState = state;
      Object.entries(action.data.value).forEach(
        (e) =>
          (newState = update(newState, { [e[0]]: { value: { $set: e[1] } } }))
      );

      return newState;
    }
    case USER_INITIALIZE_DEPARTMENT_FORM:
      return update(state, {
        department: { value: { $set: action.data.value } },
      });
    case USER_FORMAT_JOB_DESCRIPTION_FORM: {
      const tempValue = state.job_description.tempValue;
      const value = state.job_description.value;
      const arr = tempValue.map((id) => {
        if (_.find(value, { id: id })) {
          return _.find(value, { id: id });
        } else {
          return {
            brief_description_en: id,
          };
        }
      });

      return update(state, {
        job_description: { value: { $set: arr } },
      });
    }
    case USER_EDIT_JOB_DESCRIPTION_FORM: {
      return update(state, {
        job_description: { tempValue: { $set: action.data } },
      });
    }
    case USER_INITIALIZE_JOB_DESCRIPTION_FORM:
      return update(state, {
        job_description: {
          value: { $set: action.data.value },
          tempValue: {
            $set: action.data.value.map((item) => {
              return item.id;
            }),
          },
        },
      });
    case USER_PROFILE_UPDATE_JOB_DESCRIPTION: {
      return update(state, {
        job_description: { value: { $set: action.data } },
      });
    }
    case USER_INITIALIZE_LANGUAGE_FORM:
      return update(state, {
        preferred_language: { value: { $set: action.data.value } },
      });
    case USER_INITIALIZE_USER_GENDER_FORM:
      return update(state, {
        gender: { value: { $set: action.data.value } },
      });
    case USER_INITIALIZE_IMAGE_FORM:
      return update(state, {
        original_image: { value: { $set: action.data.value } },
      });
    case USER_INITIALIZE_TRAVEL_SECTOR_FORM:
      return update(state, {
        travel_sector: { value: { $set: action.data.value } },
      });
    case USER_INITIALIZE_EXPERTISE_FORM:
      return update(state, {
        expertise_type: { value: { $set: action.data.value } },
      });
    case USER_INITIALIZE_SERVICE_SPECIALTY_FORM:
      return update(state, {
        travel_services: { value: { $set: action.data.value } },
      });
    case USER_EDIT_BASE_DATA_FORM:
      return update(state, {
        [action.data.name]: {
          value: { $set: action.data.value },
          errorMsg: { $set: "" },
        },
      });
    case USER_EDIT_EXPERTISE_FORM:
    case USER_EDIT_TRAVEL_SECTOR_FORM:
      return formProcessor(
        state,
        action.data.type,
        action.data.name,
        action.data.value
      );
    case USER_EDIT_LANGUAGE_FORM:
      return update(state, {
        preferred_language: { value: { $set: action.data.value } },
      });
    case USER_UPLOAD_PREFERENCE_IMAGE:
      return update(state, {
        original_image: {
          value: { $set: action.data.value },
          fileName: { $set: action.data.fileName },
        },
      });
    case USER_EDIT_DEPARTMENT_FORM:
      return update(state, {
        department: { value: { $set: action.data.value } },
      });
    default:
      return state;
  }
};

export const getUserFeaturesGroupsData = (state = {}, action) => {
  switch (action.type) {
    case USER_PERMISSION_FEATURE_GROUPS:
      return action.data;
    default:
      return state;
  }
};

export const initialEmailConfigList = [
  {
    id: 1,
    name: "address",
    key: "address",
  },
  {
    id: 2,
    name: "logo_img",
    key: "logo",
  },
];
export const userEmailConfigData = (state = initialEmailConfigList, action) => {
  switch (action.type) {
    case USER_EMAIL_CONFIG_LIST:
      return action.data;
    default:
      return state;
  }
};

export * from "./UserAddReducers";
