import TransTxt from "@src/components/common/SxFormatMessage";

import {
  initialColumnsProcessor,
  initialColumnsMapping,
} from "./../Columns/policyCommonColumns";

import React from "react";

export const initialGroupPolicyColumnsSetUp = {
  basic: {
    value: ["policy_name", "pax", "distribution_period", "rounding_digits"],
    choices: [
      {
        key: "policy_name",
        disabled: true,
        display_name: <TransTxt id="policy_name" startCase />,
      },
      {
        key: "pax",
        disabled: false,
        display_name: <TransTxt id="pax" startCase />,
      },
      {
        key: "distribution_period",
        disabled: false,
        display_name: <TransTxt id="distribution_period" startCase />,
      },
      {
        key: "reservation_period",
        disabled: false,
        display_name: <TransTxt id="reservation_period" startCase />,
      },
      {
        key: "rounding_digits",
        disabled: false,
        display_name: "Rounding Digits",
      },
    ],
  },
  sales_partners_categories: {
    value: ["sales_partners_categories", "company_name"],
    choices: [
      {
        key: "sales_partners_categories",
        disabled: false,
        display_name: <TransTxt id="sales_partners_categories" startCase />,
      },
      {
        key: "company_name",
        disabled: false,
        display_name: <TransTxt id="company_name" startCase />,
      },
    ],
  },
  services: {
    value: ["service_categories", "service_type"],
    choices: [
      {
        key: "service_categories",
        disabled: false,
        display_name: <TransTxt id="service_categories" startCase />,
      },
      {
        key: "service_type",
        disabled: false,
        display_name: <TransTxt id="service_type" startCase />,
      },
    ],
  },
  stage: {
    value: ["status"],
    choices: [
      {
        key: "created",
        disabled: false,
        display_name: <TransTxt id="created" startCase />,
      },
      {
        key: "edited",
        disabled: false,
        display_name: <TransTxt id="edited" startCase />,
      },
      {
        key: "creator",
        disabled: false,
        display_name: <TransTxt id="creator" startCase />,
      },
      {
        key: "status",
        disabled: true,
        display_name: <TransTxt id="status" startCase />,
      },
    ],
  },
};

export const initialGroupPolicyColumnsProcessor = initialColumnsProcessor;

export const initialGroupPolicyColumnsMapping = initialColumnsMapping;
