import { Toggle, Icon } from "rsuite";

import PropTypes from "prop-types";
import React from "react";
import { createUseStyles } from "react-jss";
import { variables } from "@src/jsssetup";

const placeholderImg =
  "https://sisistaticassets.s3.ap-southeast-1.amazonaws.com/platform_graphics/imgs/others/destination_placeholder.jpg";

const styles = createUseStyles({
  ImageBankTiles: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    overflow: "auto",
    gridAutoRows: "max-content",
  },
  box: { position: "relative" },
  img: {
    width: "100%",
    height: "15vh",
    borderRadius: `calc(${variables.normal_gap} / 2)`,
    objectFit: "cover",
  },
  toggle: {
    position: "absolute",
    bottom: `calc(${variables.normal_gap} / 2)`,
    right: `calc(${variables.normal_gap} / 2)`,
    display: "grid",
    border: "2px solid white",
    borderRadius: `calc(${variables.normal_gap} / 2)`,
    padding: `calc(${variables.normal_gap} / 2)`,
    background: "rgba(0, 0, 0, 0.4)",
  },
  toggleTxt: {
    color: "white",
  },
  icon: {
    "position": "absolute",
    "top": `calc(${variables.normal_gap} / 2)`,
    "right": `calc(${variables.normal_gap} / 2)`,
    "cursor": "pointer",
    "& .rs-icon": {
      color: "white",
    },
  },
});
const ImageBankTiles = (props) => {
  const { data, handleMainPhotoChange, handleRemoveImage } = props;

  const classes = styles();

  return (
    <div className={classes.ImageBankTiles}>
      {data.map((e, idx) => {
        const checked = e.main_photo || false;
        return (
          <div key={idx} className={classes.box}>
            <img className={classes.img} src={e.big_thumbnail_url} />
            <div className={classes.toggle}>
              {checked && (
                <strong className={classes.toggleTxt}>Main Image</strong>
              )}
              <Toggle
                checked={checked}
                onChange={(value) => {
                  handleMainPhotoChange({
                    value,
                    idx,
                  });
                }}
              />
            </div>
            <div className={classes.icon}>
              <Icon
                icon="close-circle"
                size="lg"
                onClick={() => {
                  handleRemoveImage(e);
                }}
              />
            </div>
          </div>
        );
      })}
      {data.length == 0 && <img src={placeholderImg} className={classes.img} />}
    </div>
  );
};
ImageBankTiles.propTypes = {
  data: PropTypes.array.isRequired,
  handleMainPhotoChange: PropTypes.func.isRequired,
  handleRemoveImage: PropTypes.func.isRequired,
};

export default ImageBankTiles;
