import {
  initialBranchesColumnsSetUp,
  initialBranchesFilterForm,
  initialBranchesBaseDataForm,
  initialBranchesNatureOfBizForm,
} from "@src/forms/Network/Branches";

import { Schema } from "rsuite";

import {
  BRANCHES_BEGIN_FETCH_DATA,
  BRANCHES_FETCH_DATA,
  BRANCHES_END_FETCH_DATA,
  BRANCHES_UPDATE_LIST_FILTER_FORM,
  BRANCHES_APPLY_LIST_FILTER,
  BRANCHES_INITIALIZE_LIST_FILTER_FORM,
  BRANCHES_UPDATE_LIST_COLUMNS_FORM,
  BRANCHES_APPLY_LIST_COLUMNS,
  BRANCHES_INITIALIZE_LIST_COLUMNS_FORM,
  BRANCHES_FETCH_OPTIONS,
  BRANCHES_FETCH_PROFILE,
  BRANCHES_SHOW_BASE_DATA_EDIT_FORM,
  BRANCHES_HIDE_BASE_DATA_EDIT_FORM,
  BRANCHES_EDIT_BASE_DATA,
  BRANCHES_INITIALIZE_BASE_DATA_EDIT_FORM,
  BRANCHES_CLEAN_BASE_DATA_EDIT_FORM,
  BRANCHES_UPLOADING_BASE_DATA_EDIT_FORM,
  BRANCHES_IDLE_BASE_DATA_EDIT_FORM,
  BRANCHES_VALIDATE_BASE_DATA_EDIT_FORM,
  BRANCHES_CLEAN_BASE_EDIT_FORM_ERRORS,
  BRANCHES_PROFILE_DATA,
  BRANCHES_VIEW_NATURE_OF_BIZ,
  BRANCHES_EDIT_NATURE_OF_BIZ,
  BRANCHES_INITIALIZE_NATURE_DATA_FORM,
  BRANCHES_EDIT_NATURE_OF_BIZ_DATA,
  BRANCHES_SUBMIT_NATURE_OF_BIZ_DATA,
  BRANCHES_EDIT_LICENSE_CODE,
  BRANCHES_TOGGLE_POSTAL_ADDRESS,
  BRANCHES_RESET_LIST_FILTER_FORM,
  //img relation
  BRANCHES_UPLOAD_PREFERENCE_IMAGE,
  BRANCHES_FETCH_ADDRESS_POINT,
  BRANCHES_INITIAL_ADDRESS_POINT,
  BRANCHES_TOGGLE_VERIFY_ADDRESS,
  BRANCHES_BEGIN_FETCH_ADDRESS_DATA,
  BRANCHES_END_FETCH_ADDRESS_DATA,
  BRANCHES_BEGIN_FETCH_PROFILE_DATA,
  BRANCHES_END_FETCH_PROFILE_DATA,
  BRANCHES_CLOSE_VERIFY_ADDRESS,
  BRANCHES_CLEAN_ADDRESS_POINT,
  BRANCHES_INITIAL_VERIFY_STATUS,
  //==================Email Config ==================
  BRANCHES_VIEW_EMAIL_CONFIG_MODE,
  BRANCHES_EDIT_EMAIL_CONFIG_MODE,
  BRANCHES_LOADING_EMAIL_CONFIG_MODE,
  BRANCHES_EDIT_EMAIL_CONFIG_FORM,
  BRANCHES_INITIALIZE_EMAIL_CONFIG_FORM,
} from "@src/actions/Network/Branches/types";

import {
  FORMAT_TRAVEL_SERVICES_OPTIONS,
  FORMAT_TRAVEL_SERVICES_FORM,
} from "@src/actions/Shares/types";

import { formProcessor, entitiesListSelector } from "@src/reducers/tools";

import update from "immutability-helper";
import _ from "lodash";

// branches profile
export const branchesBaseDataForm = (
  state = initialBranchesBaseDataForm,
  action
) => {
  switch (action.type) {
    case BRANCHES_INITIALIZE_BASE_DATA_EDIT_FORM:
      return action.data;
    case BRANCHES_CLEAN_BASE_DATA_EDIT_FORM:
      return initialBranchesBaseDataForm;
    case BRANCHES_UPLOAD_PREFERENCE_IMAGE:
      return update(state, {
        [action.data.name]: {
          value: { $set: action.data.value },
          fileName: { $set: action.data.fileName },
        },
      });
    case BRANCHES_EDIT_BASE_DATA:
      return update(state, {
        [action.data.name]: { value: { $set: action.data.value } },
      });
    default:
      return state;
  }
};

export const initialBranchesBaseDataFormErrorsState = { valid: false };
export const branchesBaseDataFormErrors = (
  state = initialBranchesBaseDataFormErrorsState,
  action
) => {
  const tmp = {};
  Object.entries(initialBranchesBaseDataForm).forEach((e) => {
    tmp[e[0]] = e[1].validator;
  });
  const validator = Schema.Model(tmp);

  switch (action.type) {
    case BRANCHES_VALIDATE_BASE_DATA_EDIT_FORM: {
      const tmp = {};
      Object.entries(action.data).forEach((e) => (tmp[e[0]] = e[1].value));

      const validation = validator.check(tmp);
      const valid = Object.entries(validation).every((e) => !e[1].hasError);
      validation["valid"] = valid;
      return validation;
    }
    case BRANCHES_CLEAN_BASE_EDIT_FORM_ERRORS:
      return initialBranchesBaseDataFormErrorsState;
    default:
      return state;
  }
};

export const initialBranchesBaseDataUploadStatus = "IDLE";
export const branchesBaseDataUploadStatus = (
  state = initialBranchesBaseDataUploadStatus,
  action
) => {
  switch (action.type) {
    case BRANCHES_UPLOADING_BASE_DATA_EDIT_FORM:
      return "LOADING";
    case BRANCHES_IDLE_BASE_DATA_EDIT_FORM:
      return "IDLE";
    default:
      return state;
  }
};

export const branchesProfile = (state = {}, action) => {
  switch (action.type) {
    case BRANCHES_FETCH_PROFILE:
      return action.data;
    case BRANCHES_PROFILE_DATA:
      return update(state, { $merge: action.data });
    default:
      return state;
  }
};

export const initialBranchesBaseDataEditFormShow = false;
export const branchesBaseDataEditFormShow = (
  state = initialBranchesBaseDataEditFormShow,
  action
) => {
  switch (action.type) {
    case BRANCHES_SHOW_BASE_DATA_EDIT_FORM:
      return true;
    case BRANCHES_HIDE_BASE_DATA_EDIT_FORM:
      return false;
    default:
      return state;
  }
};

export const branchesOptions = (state = {}, action) => {
  switch (action.type) {
    case BRANCHES_FETCH_OPTIONS:
      return update(state, { $merge: action.data });
    case FORMAT_TRAVEL_SERVICES_OPTIONS:
      return update(state, { $merge: action.data });
    default:
      return state;
  }
};

export const initialBranchesProfileFetchStatus = "IDLE";
export const branchesProfileFetchStatus = (
  state = initialBranchesProfileFetchStatus,
  action
) => {
  switch (action.type) {
    case BRANCHES_BEGIN_FETCH_PROFILE_DATA:
      return "FETCHING";
    case BRANCHES_END_FETCH_PROFILE_DATA:
      return "FETCHED";
    default:
      return state;
  }
};

export const initialBranchesOptionsFetchStatus = "IDLE";
export const branchesOptionsFetchStatus = (
  state = initialBranchesOptionsFetchStatus,
  action
) => {
  switch (action.type) {
    case BRANCHES_FETCH_OPTIONS:
      return "FETCHED";
    default:
      return state;
  }
};

export const branchesList = (state = initialBranchesList, action) => {
  switch (action.type) {
    case BRANCHES_FETCH_DATA:
      return action.data;
    case BRANCHES_RESET_LIST_FILTER_FORM:
      return { ...state, page: 1 };
    default:
      return state;
  }
};

export const initialBranchesPostalAddressIsSynchronous = false;
export const branchesPostalAddressIsSynchronous = (
  state = initialBranchesPostalAddressIsSynchronous,
  action
) => {
  switch (action.type) {
    case BRANCHES_SHOW_BASE_DATA_EDIT_FORM:
      return action.data;
    case BRANCHES_TOGGLE_POSTAL_ADDRESS:
      return action.data;
    default:
      return state;
  }
};

// branches list
export const initialBranchesList = {
  count: 0,
  next: null,
  previous: null,
  results: [],
  page: 1,
  limit: 20,
  displayLength: 100,
};

export const initialBranchesListFetchStatus = "IDLE";
export const branchesListFetchStatus = (
  state = initialBranchesListFetchStatus,
  action
) => {
  switch (action.type) {
    case BRANCHES_BEGIN_FETCH_DATA:
      return "FETCHING";
    case BRANCHES_END_FETCH_DATA:
      return "FETCHED";
    default:
      return state;
  }
};

// filter
export const branchesListFilter = (
  state = initialBranchesFilterForm,
  action
) => {
  switch (action.type) {
    case BRANCHES_APPLY_LIST_FILTER:
      return action.data;
    case FORMAT_TRAVEL_SERVICES_FORM:
      return update(state, { $merge: action.data });
    case BRANCHES_RESET_LIST_FILTER_FORM:
      return action.data;
    default:
      return state;
  }
};

export const branchesListFilterForm = (
  state = initialBranchesFilterForm,
  action
) => {
  switch (action.type) {
    case BRANCHES_INITIALIZE_LIST_FILTER_FORM:
      return action.data;
    case BRANCHES_RESET_LIST_FILTER_FORM:
      return action.data;
    case BRANCHES_UPDATE_LIST_FILTER_FORM:
      return formProcessor(
        state,
        action.data.type,
        action.data.name,
        action.data.value
      );
    case FORMAT_TRAVEL_SERVICES_FORM:
      return update(state, { $merge: action.data });
    default:
      return state;
  }
};

// columns
export const branchesListColumns = (
  state = entitiesListSelector(initialBranchesColumnsSetUp),
  action
) => {
  switch (action.type) {
    case BRANCHES_APPLY_LIST_COLUMNS:
      return entitiesListSelector(action.data);
    default:
      return state;
  }
};

export const branchesListColumnsForm = (
  state = initialBranchesColumnsSetUp,
  action
) => {
  switch (action.type) {
    case BRANCHES_INITIALIZE_LIST_COLUMNS_FORM: {
      var newState = {};
      const tmp = _.cloneDeep(state);
      for (const key in tmp) {
        // clear form's value
        tmp[key] = update(tmp[key], { value: { $set: [] } });
        newState[key] = update(tmp[key], { value: { $set: [] } });
        // add value by action data
        action.data.forEach((item) => {
          if (_.find(tmp[key].choices, { key: item.key })) {
            newState[key] = update(newState[key], {
              value: { $push: [item.key] },
            });
          }
        });
      }

      return newState;
    }
    case BRANCHES_UPDATE_LIST_COLUMNS_FORM:
      return action.data;
    default:
      return state;
  }
};

export const initialBranchesNatureOfBizMode = "VIEW";
export const branchesNatureOfBizMode = (
  state = initialBranchesNatureOfBizMode,
  action
) => {
  switch (action.type) {
    case BRANCHES_VIEW_NATURE_OF_BIZ:
      return "VIEW";
    case BRANCHES_EDIT_NATURE_OF_BIZ:
      return "EDIT";
    case BRANCHES_SUBMIT_NATURE_OF_BIZ_DATA:
      return "LOADING";
    default:
      return state;
  }
};

export const branchesNatureOfBizForm = (
  state = initialBranchesNatureOfBizForm,
  action
) => {
  switch (action.type) {
    case BRANCHES_INITIALIZE_NATURE_DATA_FORM:
      return action.data;
    case BRANCHES_EDIT_NATURE_OF_BIZ_DATA:
      // action.data.type defines the type of the update operation
      // push: we push an array to the existing data.
      // set: we directly set the data contained in the action.
      // remove: we empty the form array.
      //License type is a two dimensional array so we need to have special handling
      // of this data. [[<licence_type>, <license_code>]]

      if (action.data.type == "push") {
        let value = action.data.value;
        if (action.data.name == "license_type") {
          value = [action.data.value, ""];
        }

        return update(state, {
          [action.data.name]: { value: { $push: [value] } },
        });
      } else if (action.data.type == "remove") {
        let truth = (v) => v !== action.data.value;

        if (action.data.name == "license_type") {
          truth = (v) => v[0] !== action.data.value;
        }

        return update(state, {
          [action.data.name]: {
            value: { $apply: (va) => va.filter((v) => truth(v)) },
          },
        });
      } else {
        let value = action.data.value;
        if (action.data.name == "license_type") {
          value = value.map((v) => [v, ""]);
        }

        return update(state, {
          [action.data.name]: { value: { $set: value } },
        });
      }
    case BRANCHES_EDIT_LICENSE_CODE:
      return update(state, {
        license_type: {
          value: {
            $apply: (lt) => {
              const newLt = [];
              lt.forEach((t) => {
                if (t[0] == action.data.name) {
                  newLt.push([t[0], action.data.value]);
                } else {
                  newLt.push(t);
                }
              });
              return newLt;
            },
          },
        },
      });
    default:
      return state;
  }
};

export const branchesAddressPoint = (state = {}, action) => {
  switch (action.type) {
    case BRANCHES_FETCH_ADDRESS_POINT:
      return action.data;
    case BRANCHES_INITIAL_ADDRESS_POINT:
      return action.data;
    case BRANCHES_CLEAN_ADDRESS_POINT:
      return { lat: "", lng: "" };
    default:
      return state;
  }
};

export const initialIsVerifyBranchesPostalAddress = false;
export const isVerifyBranchesPostalAddress = (
  state = initialIsVerifyBranchesPostalAddress,
  action
) => {
  switch (action.type) {
    case BRANCHES_TOGGLE_VERIFY_ADDRESS:
      return action.data;
    case BRANCHES_CLOSE_VERIFY_ADDRESS:
      return false;
    case BRANCHES_INITIAL_VERIFY_STATUS:
      return false;
    default:
      return state;
  }
};

export const initialFetchBranchesAddressPointStatus = "IDLE";
export const fetchBranchesAddressPointStatus = (
  state = initialFetchBranchesAddressPointStatus,
  action
) => {
  switch (action.type) {
    case BRANCHES_BEGIN_FETCH_ADDRESS_DATA:
      return "FETCHING";
    case BRANCHES_END_FETCH_ADDRESS_DATA:
      return "FETCHED";
    default:
      return state;
  }
};

export const initialBranchesEmailConfigEditMode = "VIEW";
export const branchesEmailConfigEditMode = (
  state = initialBranchesEmailConfigEditMode,
  action
) => {
  switch (action.type) {
    case BRANCHES_VIEW_EMAIL_CONFIG_MODE:
      return "VIEW";
    case BRANCHES_EDIT_EMAIL_CONFIG_MODE:
      return "EDIT";
    case BRANCHES_LOADING_EMAIL_CONFIG_MODE:
      return "LOADING";
    default:
      return state;
  }
};

export const initialEmailConfigForm = {
  email_config: {
    value: [],
  },
};

export const branchesEmailConfigForm = (
  state = initialEmailConfigForm,
  action
) => {
  switch (action.type) {
    case BRANCHES_INITIALIZE_EMAIL_CONFIG_FORM:
      return action.data;
    case BRANCHES_EDIT_EMAIL_CONFIG_FORM: {
      const { name, value } = action.data;
      return update(state, {
        email_config: {
          value: {
            $apply: (config) =>
              config.map((c) => {
                if (c.key == name.key) {
                  return { ...name, value: value };
                } else {
                  return c;
                }
              }),
          },
        },
      });
    }
    default:
      return state;
  }
};

export * from "./ServiceCategories";
