import _ from "lodash";
import { createSelector } from "reselect";
import { getTripDestinationsSelector } from "../trip_itinerary_selectors";
import { getDaysArraySelector } from "../day_by_day_selectors";
import { DateTime } from "luxon";

export function getDbDAdhocServices(state) {
  return state.tripPlannerDbDAddhocSrvData;
}

export function getDbDAdhocServicesList(state, props = {}) {
  const { booking_mode } = props;

  const list = _.flattenDeep(Object.values(getDbDAdhocServices(state)));
  if (["regular", "option"].includes(booking_mode)) {
    return [];
  }

  return list;
}

export const getDbDAdhocServicesSelector = createSelector(
  [getDbDAdhocServicesList],
  (srvs) => {
    return srvs;
  }
);

export const getDestsAdhocSrvsSelector = createSelector(
  [getDbDAdhocServices, getTripDestinationsSelector, getDaysArraySelector],
  (srvs, dests, daysArray) => {
    var destsAdhocSrvs = {};
    dests.forEach((d) => {
      const destSrvsUids = _.flatten(Object.values(destsAdhocSrvs)).map(
        (srv) => srv.id
      );

      const check_in = DateTime.fromISO(d.checkIn);
      const check_out = DateTime.fromISO(d.checkOut);
      const stay = check_out.diff(check_in, "days").toObject().days;
      const minDayIdx = daysArray.indexOf(d.checkIn) + 1;

      var destSrvs = [];
      new Array(stay + 1).fill(0).forEach((___, idx) => {
        const dayIdx = minDayIdx + idx;

        var validSrvs = srvs?.[dayIdx] ?? [];
        if (destSrvsUids.length) {
          validSrvs = validSrvs.filter((srv) => !destSrvsUids.includes(srv.id));
        }

        destSrvs = [...destSrvs, ...validSrvs];
      });
      destsAdhocSrvs[d.order] = destSrvs;
    });
    return destsAdhocSrvs;
  }
);
