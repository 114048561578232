import mapboxgl from "mapbox-gl";
import { centerToPoints } from "@src/map_tools/map";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import ReactDOM from "react-dom";
import { Avatar, Icon, IconButton, Loader } from "rsuite";
import InteractiveMapHoc from "../../InteractiveMapHoc";
import {
  useQuery,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { fetchSimpleHotelMetaData } from "@src/api";
import { queryClient } from "@src/index";
import { CustomButton } from "@src/components/common/buttons";
import { useConvertedHotelPrice } from "../OverviewAccFilters";
import { Provider } from "react-redux";
import store from "@src/store";
import RatingPanel from "@src/components/common/RatingPanel";

const HotelMapPopup = ({
  accId,
  accDataSupplier,
  computedData,
  minPrice = 0,
  currency = "EUR",
  onViewDetails,
}) => {
  var { data: metadata, isLoading } = useQuery({
    queryKey: ["hotelMapPopup", accId],
    queryFn: async () => fetchSimpleHotelMetaData(accId, accDataSupplier),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
  metadata = metadata?.data?.hotels[0];
  const { convertedPrice, convertCurrency } = useConvertedHotelPrice(
    minPrice,
    currency
  );
  const customBtnStyles = {
    CustomButton: {
      borderTopLeftRadius: "0px",
      borderTopRightRadius: "0px",
      fontWeight: "400",
      gridTemplateColumns: "max-content",
      justifyContent: "center",
    },
  };
  return (
    <div className="HotelMapPopup">
      {isLoading && <Loader center backdrop />}
      <span className="HotelMapPopup__title">
        <strong>{metadata?.name}</strong>
      </span>
      <span>
        {metadata?.rating ? (
          <RatingPanel
            rating={metadata.rating}
            color="#F0B040"
            fontSize="medium"
            showEmpty={true}
          />
        ) : null}
      </span>
      <img className="HotelMapPopup__img" src={metadata?.images?.[0]?.url} />
      <small>{metadata?.address}</small>
      <small>
        <strong>Refundable: </strong>
        {computedData?.refundable ? "Yes" : "No"}
      </small>
      <small>
        <strong>From: </strong>
        {convertedPrice.toLocaleString("en-US", {
          style: "currency",
          currency: convertCurrency,
        })}
      </small>
      <CustomButton
        customStyles={customBtnStyles}
        onClick={function () {
          onViewDetails(accId);
        }}>
        View Details
      </CustomButton>
    </div>
  );
};
HotelMapPopup.propTypes = {
  accId: PropTypes.string.isRequired,
  computedData: PropTypes.object.isRequired,
  minPrice: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  accDataSupplier: PropTypes.string.isRequired,
  onViewDetails: PropTypes.func.isRequired,
};

class HotelsMap extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidUpdate(prevProps) {
    const { map, accommodations } = this.props;

    if (!prevProps.map && map) {
      if (map.loaded()) {
        this.initialActions();
      } else {
        map.once("load", () => {
          this.initialActions();
        });
      }
    }

    if (map) {
      if (
        prevProps.accommodations.map((acc) => acc.metadata.id).join("") !==
        accommodations.map((acc) => acc.metadata.id).join("")
      ) {
        if (map.loaded()) {
          this.setAccData();
          this.center();
        } else {
          map.once("load", () => {
            this.setAccData();
            this.center();
          });
        }
      }
    }
  }
  initialActions() {
    this.setSources();
    this.setLayers();
    this.setAccData();
    this.setEventHandlers();
    this.center();
  }
  setEventHandlers() {
    const { map, onViewDetails } = this.props;

    for (let i = 1; i <= 5; i++) {
      map.on("click", `${i}star`, function (e) {
        var coordinates = e.features[0].geometry.coordinates.slice();
        const accId = e.features[0].properties.accId;
        // Ensure that if the map is zoomed out such that
        // multiple copies of the feature are visible, the
        // popup appears over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        const placeholder = document.createElement("div");
        placeholder.className = "OverviewAllAccommodation--listmap__mappopup";
        ReactDOM.render(
          <QueryClientProvider client={queryClient}>
            <Provider store={store}>
              <HotelMapPopup
                accId={accId}
                accDataSupplier={e.features[0].properties.accDataSupplier}
                onViewDetails={onViewDetails}
                computedData={JSON.parse(e.features[0].properties.computedData)}
                minPrice={e.features[0].properties.minPrice}
                currency={e.features[0].properties.currency}
              />
            </Provider>
          </QueryClientProvider>,
          placeholder
        );

        new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setDOMContent(placeholder)
          .addTo(map);
      });
    }

    map.on("mouseenter", "clusters", function () {
      map.getCanvas().style.cursor = "pointer";
    });
    map.on("mouseleave", "clusters", function () {
      map.getCanvas().style.cursor = "";
    });
  }
  accLayerTemplate(rating, color) {
    return {
      id: `${rating}star`,
      source: `accData${rating}`,
      type: "circle",
      filter: ["!", ["has", "point_count"]],
      paint: {
        "circle-stroke-width": 2,
        "circle-radius": 7,
        "circle-color": color,
        "circle-stroke-color": "black",
      },
    };
  }
  textLayerTemplate(rating) {
    return {
      id: `accText${rating}`,
      source: `accData${rating}`,
      type: "symbol",
      layout: {
        "text-field": [
          "concat",
          ["to-string", ["get", "title"]],
          ". ",
          "(",
          ["get", "rating"],
          ")",
        ],
        "text-allow-overlap": false,
        "text-max-width": 20,
        "text-size": 14,
        "text-offset": [0, 1.2],
      },
      filter: ["!", ["has", "point_count"]],
      paint: {
        "text-color": "black",
        "text-halo-color": "white",
        "text-halo-width": 2,
      },
    };
  }
  setLayers() {
    this.setAccLayers();
  }
  setAccLayers() {
    const { map } = this.props;

    this.setClusterLayers(1, "#33b2db");
    this.setClusterLayers(2, "#f0ad74");
    this.setClusterLayers(3, "#7ecb94");
    this.setClusterLayers(4, "#7BCACF");
    this.setClusterLayers(5, "#FF4E24");
    map.addLayer(this.accLayerTemplate(1, "#33b2db"));
    map.addLayer(this.accLayerTemplate(2, "#f0ad74"));
    map.addLayer(this.accLayerTemplate(3, "#7ecb94"));
    map.addLayer(this.accLayerTemplate(4, "#7BCACF"));
    map.addLayer(this.accLayerTemplate(5, "#FF4E24"));
    map.addLayer(this.textLayerTemplate(1));
    map.addLayer(this.textLayerTemplate(2));
    map.addLayer(this.textLayerTemplate(3));
    map.addLayer(this.textLayerTemplate(4));
    map.addLayer(this.textLayerTemplate(5));
  }
  setClusterLayers(rating, color) {
    const { map } = this.props;
    const clr = [
      "step",
      ["get", "point_count"],
      `${color}`,
      100,
      `${color}`,
      750,
      `${color}`,
    ];

    map.addLayer({
      id: `clusters${rating}`,
      type: "circle",
      source: `accData${rating}`,
      filter: ["has", "point_count"],
      paint: {
        "circle-color": clr,
        "circle-radius": ["step", ["get", "point_count"], 20, 100, 30, 750, 40],
      },
    });

    map.addLayer({
      id: `cluster-count${rating}`,
      type: "symbol",
      source: `accData${rating}`,
      filter: ["has", "point_count"],
      layout: {
        "text-field": "{point_count_abbreviated}",
        "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
        "text-size": 12,
      },
    });
  }
  setSources() {
    this.setAccSources();
  }
  setAccSources() {
    const { map } = this.props;

    for (let i = 1; i <= 5; i++) {
      map.addSource(`accData${i}`, {
        type: "geojson",
        data: { type: "Feature", features: [] },
        cluster: true,
        clusterMaxZoom: 14,
        clusterRadius: 50,
      });
    }
  }
  filterAccForSource(acc, rating) {
    return (
      _.get(acc, "metadata.rating", 0) == rating &&
      _.get(acc, "metadata.geodata.lng", 0) !== 0 &&
      _.get(acc, "metadata.geodata.lat", 0) !== 0
    );
  }
  getFeatureFromAcc(acc) {
    var rooms = acc?.detailed_rooms || [];
    if (!rooms.length) {
      rooms = acc?.rooms || [];
    }

    return {
      type: "Feature",
      properties: {
        accId: _.get(acc, "metadata.id"),
        accDataSupplier: _.get(acc, "metadata.data_supplier"),
        title: _.get(acc, "metadata.name"),
        img: _.get(acc, "metadata.images.0.url"),
        address: _.get(acc, "metadata.address"),
        computedData: acc?.computed_data || {},
        rating: _.get(acc, "metadata.rating", 0),
        visibility: "visible",
        minPrice: Math.min(...rooms.map((room) => room?.price?.value || 0)),
        currency: rooms?.[0]?.price?.currency || "EUR",
      },
      geometry: {
        type: "Point",
        coordinates: [
          _.get(acc, "metadata.geodata.lng", 0),
          _.get(acc, "metadata.geodata.lat", 0),
        ],
      },
    };
  }
  setAccData() {
    const { map, accommodations } = this.props;

    if (!map) {
      return;
    }

    for (let i = 1; i <= 5; i++) {
      map.getSource(`accData${i}`).setData({
        type: "FeatureCollection",
        features: accommodations
          .filter((acc) => this.filterAccForSource(acc, i))
          .map((acc) => this.getFeatureFromAcc(acc)),
      });
    }
  }
  async center() {
    const { map } = this.props;
    const points = [];

    for (let i = 1; i <= 5; i++) {
      map
        .getSource(`accData${i}`)
        ._data.features.forEach((f) => points.push(f.geometry.coordinates));
    }

    const cPoints = points.filter((pp) => pp.every((p) => p !== 0));
    centerToPoints(map, cPoints, {
      zoom: 9,
    });
  }
  render() {
    return (
      <div className="HotelsMapContainer">
        <div id="HotelsMap"></div>
        <div className="HotelsMap__legend">
          <strong>Stars:</strong>
          {new Array(5).fill(1).map((i, idx) => (
            <Avatar
              key={idx}
              className={`HotelsMap__legend__star HotelsMap__legend__star--${
                idx + 1
              }`}
              circle
              size="sm">
              <strong>{idx + 1}</strong>
            </Avatar>
          ))}
        </div>
      </div>
    );
  }
}
HotelsMap.propTypes = {
  map: PropTypes.object.isRequired,
  accommodations: PropTypes.array.isRequired,
  onViewDetails: PropTypes.func.isRequired,
};
export default InteractiveMapHoc(HotelsMap, {
  containerId: "HotelsMap",
});
