export const TRIPPLANNER_RESET_TRAINS = "TRIPPLANNER_RESET_TRAINS";
export const TRIPPLANNER_SAVE_TRAINS_RESULT = "TRIPPLANNER_SAVE_TRAINS_RESULT";
export const TRIPPLANNER_REMOVE_TRAIN_RESULT =
  "TRIPPLANNER_REMOVE_TRAIN_RESULT";
export const TRIPPLANNER_SAVE_TRAINS_SESSION =
  "TRIPPLANNER_SAVE_TRAINS_SESSION";
export const TRIPPLANNER_ALTER_SEARCH_STATE = "TRIPPLANNER_ALTER_SEARCH_STATE";

export function resetTripTrains(sessionData) {
  return { type: TRIPPLANNER_RESET_TRAINS };
}

export function saveTrainsSession(sessionData) {
  return { type: TRIPPLANNER_SAVE_TRAINS_SESSION, sessionData };
}

export function removeTrain(id) {
  return { type: TRIPPLANNER_REMOVE_TRAIN_RESULT, id };
}

export function saveTrainsResult(trainData, legUid, sessionId, priced = false) {
  trainData["service_type"] = "TR";
  trainData["transportation_service_type"] = "ATRA";
  trainData["legUid"] = legUid;
  trainData["session_id"] = sessionId;
  trainData["priced"] = priced;
  return { type: TRIPPLANNER_SAVE_TRAINS_RESULT, trainData };
}

export function alterTrainSearchState(legUid, searchState) {
  return { type: TRIPPLANNER_ALTER_SEARCH_STATE, legUid, searchState };
}
