import {
  CUSTOM_FLIGHT_INIT,
  CUSTOM_FLIGHT_INIT_FROM_DATA,
  CUSTOM_FLIGHT_CHANGE_STOPS,
  CUSTOM_FLIGHT_CHANGE_SEGMENTS_NUMBER,
  CUSTOM_FLIGHT_CHANGE_AIRPORT,
  CUSTOM_FLIGHT_CHANGE_SEGMENT_DATETIME,
  CUSTOM_FLIGHT_CHANGE_SEG_SIMPLE_DATA,
  CUSTOM_FLIGHT_CHANGE_AIRLINE,
  CUSTOM_FLIGHT_CHANGE_SEGMENT_AIRLINE,
  CUSTOM_FLIGHT_CHANGE_AIRCRAFT,
} from "@src/actions/Project/CustomFlight/types";

import moment from "moment-timezone";
import _ from "lodash";
import update from "immutability-helper";

const airportTemplate = {
  iata: "",
  name: "N/A",
  lng: 0,
  lat: 0,
  timezone: null,
};

const segmentTemplate = {
  order: 0,
  origin_airport: { iata: "", name: "N/A", lng: 0, lat: 0 },
  destination_airport: { iata: "", name: "N/A", lng: 0, lat: 0 },
  departure: "",
  arrival: "",
  operating_airline: { iata: "", name: "N/A", logo: "" },
  aircraft: { code: "", name: "" },
  flight_number: "",
  fare_base: "",
  cabin_class: "",
  baggage_allowance: { number: "0", unit: "K", verbose: "" },
  transfer_time: 0,
  duration: 0,
};

const flightTemplate = {
  uid: null,
  origin_airport: { iata: "", name: "N/A", lng: 0, lat: 0 },
  destination_airport: { iata: "", name: "N/A", lng: 0, lat: 0 },
  outbound_operating_airline: { iata: "", name: "N/A", logo: "" },
  inbound_operating_airline: { iata: "", name: "N/A", logo: "" },
  price: { total_price: 0, total_fare_price: 0, total_taxes: 0, currency: "" },
  outbound_options: [
    {
      uid: null,
      arrival: "",
      duration: 0,
      selected: true,
      departure: "",
      avail_string: "",
      segments: [segmentTemplate],
    },
  ],
  inbound_options: [
    {
      uid: null,
      arrival: "",
      duration: 0,
      selected: true,
      departure: "",
      avail_string: "",
      segments: [segmentTemplate],
    },
  ],
  record_set: "",
  source: "",
  crs_info: {
    crs_name: ["string"],
    gds: "",
    guid: null,
    received_from: "",
    special_gds_name: null,
    terminal_country: "",
    terminal_pcc: "",
    user: "",
  },
};

export const customFlight = (state = flightTemplate, action) => {
  switch (action.type) {
    case CUSTOM_FLIGHT_INIT:
      return flightTemplate;
    case CUSTOM_FLIGHT_INIT_FROM_DATA:
      return action.data;
    case CUSTOM_FLIGHT_CHANGE_STOPS: {
      const { legs, legType } = action;

      return update(state, {
        [`${legType}_options`]: {
          [0]: {
            segments: {
              $set: new Array(legs)
                .fill(segmentTemplate)
                .map((s, idx) => update(s, { order: { $set: idx + 1 } })),
            },
          },
        },
      });
    }
    case CUSTOM_FLIGHT_CHANGE_SEGMENTS_NUMBER: {
      const { legType, segments_number } = action;
      const segLength = _.get(state, `${legType}_options.0.segments`, [])
        .length;
      if (segments_number > segLength) {
        return update(state, {
          origin_airport: { $set: airportTemplate },
          destination_airport: { $set: airportTemplate },
          [`${legType}_options`]: {
            [0]: {
              segments: {
                $set: new Array(segments_number).map((s) => segmentTemplate),
              },
            },
          },
        });
      } else if (segments_number == segLength) {
        return state;
      } else {
        return update(state, {
          [`${legType}_options`]: {
            [0]: {
              segments: {
                $apply: (segs) => segs.filter((s, idx) => idx + 1 < segLength),
              },
            },
          },
        });
      }
    }
    case CUSTOM_FLIGHT_CHANGE_AIRPORT: {
      const { legType, segmentIdx, airport_type, airport } = action;

      const updater = (air) =>
        update(air, {
          iata: { $set: airport.iata },
          lat: { $set: airport.lat },
          lng: { $set: airport.lng },
          name: { $set: airport.name_en },
          timezone: { $set: airport.timezone },
        });

      const segLength = state[`${legType}_options`][0].segments.length;

      return update(state, {
        [airport_type]: {
          $apply: (air) => {
            if (airport_type == "origin_airport" && segmentIdx == 0) {
              return updater(air);
            } else if (
              airport_type == "destination_airport" &&
              segmentIdx + 1 == segLength
            ) {
              return updater(air);
            } else {
              return air;
            }
          },
        },
        [`${legType}_options`]: {
          [0]: {
            segments: {
              [segmentIdx]: {
                [airport_type]: { $apply: (air) => updater(air) },
                departure: { $set: "" },
                arrival: { $set: "" },
              },
            },
          },
        },
      });
    }
    case CUSTOM_FLIGHT_CHANGE_SEGMENT_DATETIME: {
      const { legType, segment_idx, datetime_type, datetime } = action;

      const segLength = state[`${legType}_options`][0].segments.length;

      const seg = state[`${legType}_options`][0].segments[segment_idx];

      const newState = update(state, {
        [`${legType}_options`]: {
          [0]: {
            [datetime_type]: {
              $apply: (dt) => {
                if (segment_idx == 0 && datetime_type == "departure") {
                  return datetime;
                } else if (
                  segment_idx + 1 == segLength &&
                  datetime_type == "arrival"
                ) {
                  return datetime;
                } else {
                  return dt;
                }
              },
            },
            segments: {
              [segment_idx]: {
                [datetime_type]: { $set: datetime },
                duration: {
                  $apply: (dur) => {
                    const otherDt =
                      seg[
                        datetime_type == "departure" ? "arrival" : "departure"
                      ];

                    if (otherDt !== "") {
                      return moment
                        .duration(
                          moment.utc(datetime).diff(moment.utc(otherDt))
                        )
                        .asMilliseconds();
                    }

                    return dur;
                  },
                },
                transfer_time: {
                  $apply: (tt) => {
                    if (datetime_type !== "departure" || segment_idx == 0) {
                      return tt;
                    }

                    const prevSeg =
                      state[`${legType}_options`][0].segments[segment_idx - 1];

                    return moment
                      .duration(
                        moment.utc(datetime).diff(moment.utc(prevSeg.arrival))
                      )
                      .asMilliseconds();
                  },
                },
              },
            },
          },
        },
      });

      return update(newState, {
        [`${legType}_options`]: {
          [0]: {
            duration: {
              $set: newState[`${legType}_options`][0].segments.reduce(
                (a, b) => a + b.duration + b.transfer_time,
                0
              ),
            },
          },
        },
      });
    }
    case CUSTOM_FLIGHT_CHANGE_SEG_SIMPLE_DATA: {
      const { fieldName, legType, segment_idx, flNumber } = action;

      return update(state, {
        [`${legType}_options`]: {
          [0]: {
            segments: { [segment_idx]: { [fieldName]: { $set: flNumber } } },
          },
        },
      });
    }
    case CUSTOM_FLIGHT_CHANGE_AIRLINE: {
      const { legType, airline } = action;

      return update(state, {
        [`${legType}_operating_airline`]: {
          $set: _.pick(airline, ["iata", "name", "logo"]),
        },
        // Update also segments airlines in case their airline entry is empty
        [`${legType}_options`]: {
          [0]: {
            segments: {
              $apply: (segs) =>
                segs.map((s) =>
                  s.operating_airline.iata == ""
                    ? update(s, {
                        operating_airline: {
                          $set: _.pick(airline, ["iata", "name", "logo"]),
                        },
                      })
                    : s
                ),
            },
          },
        },
      });
    }
    case CUSTOM_FLIGHT_CHANGE_SEGMENT_AIRLINE: {
      const { legType, segment_idx, airline } = action;

      return update(state, {
        [`${legType}_options`]: {
          [0]: {
            segments: {
              [segment_idx]: {
                operating_airline: {
                  $set: _.pick(airline, ["iata", "name", "logo"]),
                },
              },
            },
          },
        },
      });
    }
    case CUSTOM_FLIGHT_CHANGE_AIRCRAFT: {
      const { legType, segment_idx, aircraft } = action;
      return update(state, {
        [`${legType}_options`]: {
          [0]: {
            segments: {
              [segment_idx]: {
                aircraft: { $set: _.pick(aircraft, ["code", "name"]) },
              },
            },
          },
        },
      });
    }
    default:
      return state;
  }
};
