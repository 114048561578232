import _ from "lodash";
import { NormalInputField } from "@src/components/forms";
import { cardStyles, tableStyles, variables } from "@src/jsssetup";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { Button } from "rsuite";

export const firstCellStyles = {
  ...tableStyles.cell,
  background: variables.colors.background.base,
  fontWeight: "bold",
  borderRight: `2px solid ${variables.colors.borders.base}`,
};

const remarkerStyles = createUseStyles({
  Remarker: {},
  cell: tableStyles.cell,
  firstCell: firstCellStyles,
  btn: { marginRight: `calc(${variables.normal_gap} / 2)` },
  valueCell: { ...tableStyles.cell, textAlign: "left" },
});
const Remarker = ({
  editable,
  fieldValue,
  fieldKey,
  confirmed,
  onConfirm,
  onCancel,
}) => {
  const [mode, setMode] = useState(confirmed ? "confirmed" : "");

  const classes = remarkerStyles();

  return (
    <tr>
      <td className={classes.firstCell}>Reply</td>
      <td className={classes.valueCell}>
        {editable && !mode && (
          <React.Fragment>
            <Button
              color="blue"
              size="xs"
              className={classes.btn}
              onClick={() => setMode("reply")}
            >
              <strong>Reply</strong>
            </Button>
            <Button
              color="green"
              size="xs"
              className={classes.btn}
              onClick={() => {
                setMode("confirmed");
                onConfirm();
              }}
            >
              <strong>Confirm</strong>
            </Button>
          </React.Fragment>
        )}
        {editable && mode === "reply" && (
          <React.Fragment>
            <NormalInputField name={fieldKey} as="textarea" />
            <br />
            <Button
              className={classes.btn}
              size="xs"
              appearance="ghost"
              onClick={() => {
                setMode("");
                onCancel();
              }}
            >
              Cancel
            </Button>
            <Button
              color="green"
              size="xs"
              className={classes.btn}
              onClick={() => {
                setMode("confirmed");
                onConfirm();
              }}
            >
              <strong>Confirm</strong>
            </Button>
          </React.Fragment>
        )}
        {(!editable || mode === "confirmed") && (
          <span>{fieldValue || "N/A"}</span>
        )}
      </td>
      {editable && mode === "confirmed" && (
        <td>
          <Button size="xs" appearance="ghost" onClick={() => setMode("reply")}>
            <strong>Edit</strong>
          </Button>
        </td>
      )}
    </tr>
  );
};
Remarker.propTypes = {
  confirmed: PropTypes.bool.isRequired,
  editable: PropTypes.bool.isRequired,
  fieldValue: PropTypes.string.isRequired,
  fieldKey: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

const genericRemarksStyles = createUseStyles({
  GenericRemarks: cardStyles.card,
  head: cardStyles.header,
  table: tableStyles.table,
  thead: tableStyles.head,
  cell: tableStyles.cell,
  remarksCell: { ...tableStyles.cell, textAlign: "left" },
  firstCell: firstCellStyles,
});
const GenericRemarks = ({
  title,
  remarks,
  reply_remarks,
  editable,
  onChange,
}) => {
  const classes = genericRemarksStyles();

  const [init, setInit] = useState({});
  useEffect(() => setInit(reply_remarks), []);

  return _.isEmpty(init) ? null : (
    <div className={classes.GenericRemarks}>
      <div className={classes.head}>
        <h5>{title}</h5>
      </div>
      <Formik
        initialValues={{ ...init }}
        validateOnBlur={false}
        validateOnMount={false}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <table className={classes.table}>
              <thead className={classes.thead}>
                <tr>
                  <th>Type</th>
                  <th>Remarks</th>
                  {values.confirmed ? <th>Actions</th> : <th></th>}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={classes.firstCell}>Request</td>
                  <td
                    className={classes.remarksCell}
                    dangerouslySetInnerHTML={{ __html: remarks }}
                  />
                </tr>
                <Remarker
                  editable={editable}
                  fieldKey="remarks"
                  fieldValue={values.remarks}
                  confirmed={values.confirmed}
                  onConfirm={() => onChange({ ...values, confirmed: true })}
                  onCancel={() => {
                    setFieldValue("remarks", "");
                    onChange({ remarks: "", confirmed: false });
                  }}
                />
              </tbody>
            </table>
          </Form>
        )}
      </Formik>
    </div>
  );
};
GenericRemarks.propTypes = {
  title: PropTypes.string.isRequired,
  remarks: PropTypes.string.isRequired,
  reply_remarks: PropTypes.object.isRequired,
  reservation_remarks: PropTypes.string.isRequired,
  editable: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export const GeneralRemarks = ({
  reservation_remarks,
  reply_remarks,
  editable,
  onChange,
}) => {
  return (
    <GenericRemarks
      title="General Remarks"
      remarks={reservation_remarks}
      {...{ reply_remarks, editable, onChange }}
    />
  );
};
GeneralRemarks.propTypes = {
  initialRemarksData: PropTypes.object.isRequired,
  reply_remarks: PropTypes.object.isRequired,
  reservation_remarks: PropTypes.string.isRequired,
  editable: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export const PaymentModeRemarks = ({
  payment_mode_remarks,
  reply_remarks,
  editable,
  onChange,
}) => {
  return (
    <GenericRemarks
      title="Payment Mode Remarks"
      remarks={payment_mode_remarks}
      {...{ reply_remarks, editable, onChange }}
    />
  );
};
PaymentModeRemarks.propTypes = {
  reply_remarks: PropTypes.object.isRequired,
  payment_mode_remarks: PropTypes.string.isRequired,
  editable: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export const BookingModeRemarks = ({
  booking_mode_remarks,
  reply_remarks,
  editable,
  onChange,
}) => {
  return (
    <GenericRemarks
      title="Booking Mode Remarks"
      remarks={booking_mode_remarks}
      {...{ reply_remarks, editable, onChange }}
    />
  );
};
BookingModeRemarks.propTypes = {
  booking_mode_remarks: PropTypes.string.isRequired,
  reply_remarks: PropTypes.object.isRequired,
  editable: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export const CxlRemarks = ({
  cxl_remarks,
  reply_remarks,
  editable,
  onChange,
}) => {
  return (
    <GenericRemarks
      title="Cancellation Policy Remarks"
      remarks={cxl_remarks}
      {...{ reply_remarks, editable, onChange }}
    />
  );
};
CxlRemarks.propTypes = {
  cxl_remarks: PropTypes.string.isRequired,
  reply_remarks: PropTypes.object.isRequired,
  editable: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};
