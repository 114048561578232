import {
  ADDON_RESERVATION_DETAILS_INIT,
  ADDON_RESERVATION_DETAILS_SET,
  ADDON_RESERVATION_DETAILS_CHANGE_SECTION,
  ADDON_RESERVATION_DETAILS_SHOW_BOOKING_INFO_MODAL,
  ADDON_RESERVATION_DETAILS_HIDE_BOOKING_INFO_MODAL,
} from "@src/actions/Operation/AddOns/types";

export const addOnReservationInitial = {
  reservation: {},
  activeNavSection: "reservation",
};
export const addOnReservation = (state = addOnReservationInitial, action) => {
  switch (action.type) {
    case ADDON_RESERVATION_DETAILS_INIT:
      return addOnReservationInitial;
    case ADDON_RESERVATION_DETAILS_SET: {
      const { data } = action;
      return { ...state, reservation: data };
    }
    case ADDON_RESERVATION_DETAILS_CHANGE_SECTION: {
      const { activeNavSection } = action;
      return { ...state, activeNavSection };
    }
    default:
      return state;
  }
};

export const addOnReservationBookingInfoModalInitial = { show: false };
export const addOnReservationBookingInfoModal = (
  state = addOnReservationBookingInfoModalInitial,
  action
) => {
  switch (action.type) {
    case ADDON_RESERVATION_DETAILS_INIT:
      return addOnReservationBookingInfoModalInitial;
    case ADDON_RESERVATION_DETAILS_SHOW_BOOKING_INFO_MODAL:
      return { ...state, show: true };
    case ADDON_RESERVATION_DETAILS_HIDE_BOOKING_INFO_MODAL:
      return addOnReservationBookingInfoModalInitial;
    default:
      return state;
  }
};
