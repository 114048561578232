import React, { useEffect, useRef, useState } from "react";
import * as echarts from "echarts";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import { variables } from "@src/jsssetup";
import _ from "lodash";

const chartStyles = createUseStyles({
  MultipleBarChart: {
    "display": "grid",
    "gridTemplateRows": "repeat(2, max-content)",
    "padding": variables.normal_gap,
    "width": (props) => `${props.width}rem`,
    "height": "17rem",
    "& div": {
      alignSelf: "center",
      height: "50rem!importnat",
      width: "100%!important",
    },
    "& canvas": {
      alignSelf: "center",
      width: "100%!important",
      height: "100%!important",
    },
  },
  chart: {
    display: "grid",
    padding: variables.normal_gap,
    width: "20rem",
    height: "30vh",
  },
});
const MultipleBarChart = ({
  barWidth,
  title,
  width,
  seriesLabelColor,
  seriesLabelShow,
  predefinedColors,
  moreDataLabel,
  legendData,
  data,
}) => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const classes = chartStyles({ width });
  const chartRef = useRef(null);

  const createChartInstance = () => {
    const chartInstance = echarts.init(chartRef.current);

    const options = {
      grid: {
        top: "20%",
        left: "5%",
        right: "20%",
        bottom: "10%",
        containLabel: true,
      },
      title: {
        text: title,
        top: "5%",
        left: "center",
        textStyle: {
          fontSize: 16,
          fontFamily: "futura,sans-serif",
          fontWeight: "400",
        },
      },
      tooltip: {
        trigger: "axis",
        show: true,
        textStyle: {
          padding: [5, 10],
        },
        position: function (pt) {
          return [pt[0], "10%"];
        },
        formatter: (params) => {
          const name = `${params[0].name} ${moreDataLabel}`;
          var value = `${params[0].value} `;
          return `${_.capitalize(name)}<br/><strong>${value} 
          </strong>`;
        },
      },
      legend: {
        orient: "vertical",
        right: 0,
        top: "center",
        padding: [5, 10],
        color: predefinedColors,
      },
      dataset: {
        source: data,
      },
      xAxis: {
        type: "category",
        axisLine: { show: false },
        axisTick: { show: false },
        splitLine: { show: false },
      },
      yAxis: {
        axisLabel: {
          show: false,
        },
        axisLine: { show: false },
        axisTick: { show: false },
        splitLine: { show: false },
      },
      series: [
        {
          label: {
            show: seriesLabelShow,
            position: "top",
          },
          color: predefinedColors[0],
          type: "bar",
          //   barWidth: barWidth,
        },
        {
          label: {
            show: seriesLabelShow,
            position: "top",
          },
          color: predefinedColors[1],
          type: "bar",
          //   barWidth: barWidth,
        },
        {
          label: {
            show: seriesLabelShow,
            position: "top",
          },
          color: predefinedColors[2],
          type: "bar",
          //   barWidth: barWidth,
        },
      ],
    };

    chartInstance.setOption(options);
    console.log(chartInstance.getOption());
    return chartInstance;
  };

  const handleResize = () => setWindowSize(window.innerWidth);
  window.removeEventListener("resize", handleResize);

  useEffect(() => {
    if (!data) return;
    createChartInstance(data);
    window.addEventListener("resize", handleResize);
  }, [data]);

  useEffect(() => {
    const existingChart = echarts.getInstanceByDom(chartRef.current);
    if (existingChart) {
      existingChart.resize();
    }
  }, [windowSize]);

  return (
    <React.Fragment>
      <div ref={chartRef} className={classes.MultipleBarChart} />
    </React.Fragment>
  );
};
MultipleBarChart.defaultProps = {
  moreDataLabel: "",
  seriesLabelShow: "true",
  seriesLabelColor: "black",
};
MultipleBarChart.propTypes = {
  moreDataLabel: PropTypes.string,
  xAxis: PropTypes.string,
  barWidth: PropTypes.string,
  title: PropTypes.object,
  width: PropTypes.number,
  seriesLabelColor: PropTypes.string,
  seriesLabelShow: PropTypes.oneOf(["true", "false"]),
  predefinedColors: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  legendData: PropTypes.array.isRequired,
};
export default MultipleBarChart;
