import _ from "lodash";
import { IconButton } from "rsuite";
import PropTypes from "prop-types";
import React, { memo, useCallback, useState } from "react";
import TransTxt from "@src/components/common/SxFormatMessage";
import { createUseStyles } from "react-jss";
import { variables } from "@src/jsssetup";
import { Icon } from "@iconify/react";

export var DownLoadPDFBtn = (props) => {
  const { onClick } = props;
  return (
    <IconButton
      id={props.id}
      icon={<Icon icon="file-pdf-o" />}
      color="green"
      size="xs"
      onClick={onClick}>
      PDF {<TransTxt id="DownLoadPDFBtn__report" />}
    </IconButton>
  );
};
DownLoadPDFBtn.propTypes = {
  onClick: PropTypes.func.isRequired,
};
DownLoadPDFBtn = memo(DownLoadPDFBtn);

function buttonSizer({ size }) {
  const md = {
    fontSize: "14px",
    // padding: "8px 12px",
    lineHeight: "20px",
  };

  let sizeOptions = {};
  switch (size) {
    case "lg":
      sizeOptions = {
        fontSize: "16px",
        lineHeight: "22px",
      };
      break;
    case "sm":
      sizeOptions = {
        fontSize: "14px",
        lineHeight: "20px",
      };
      break;
    case "xs":
      sizeOptions = {
        fontSize: "12px",
        lineHeight: "20px",
      };
      break;
    case "md": {
      sizeOptions = md;
      break;
    }
    default:
      sizeOptions = md;
      break;
  }
  return sizeOptions;
}

function buttonStyler({ appearance }) {
  const primary = {
    extend: "button",
    border: `solid 1px ${variables.colors.easy.orange}`,
    backgroundColor: variables.colors.easy.orange,
    color: "white",
  };

  switch (appearance) {
    case "fade": {
      return {
        border: `solid 1px ${variables.colors.easy.lightOrange2}`,
        backgroundColor: variables.colors.easy.lightOrange2,
        color: "white",
      };
    }
    case "primary": {
      return primary;
    }
    case "ghost": {
      return {
        extend: "button",
        border: `solid 1px ${variables.colors.easy.orange}`,
        backgroundColor: "white",
        color: variables.colors.easy.orange,
      };
    }
    case "alert": {
      return {
        extend: "button",
        border: `solid 1px red`,
        backgroundColor: "white",
        color: "red",
      };
    }
    default: {
      return primary;
    }
  }
}

export function useDynamicBtnStyles() {
  const [height, setHeight] = useState("auto");
  const inputGroupRef = useCallback((node) => {
    if (node !== null) {
      setHeight(node.getElementsByTagName("input")[0].offsetHeight);
    }
  }, []);
  return { styles: { CustomButton: { height } }, inputGroupRef };
}

const customButtonStyles = createUseStyles({
  CustomButton: ({ size, appearance, customStyles, icon, placement }) => ({
    borderRadius: "5px",
    textAlign: "center",
    display: "grid",
    gridTemplateColumns: icon
      ? placement === "left"
        ? "auto 1fr"
        : "1fr auto"
      : "1fr",
    gridGap: icon ? variables.half_gap : 0,
    placeItems: "center",
    fontWeight: "bold",
    ...buttonSizer({ size }),
    ...buttonStyler({ appearance }),
    ..._.get(customStyles, "CustomButton", {}),
  }),
  children: { height: "100%" },
  icon: { fontSize: "large" },
});
export const CustomButton = ({
  id,
  icon,
  size,
  appearance = "primary",
  type,
  placement = "left",
  customStyles = {},
  onClick,
  children,
}) => {
  const classes = customButtonStyles({
    appearance,
    icon,
    size,
    customStyles,
    placement,
  });
  return (
    <button
      id={id}
      type={type}
      className={classes.CustomButton}
      onClick={onClick}>
      {icon && placement === "left" && (
        <Icon icon={icon} className={classes.icon} />
      )}
      {children}
      {icon && placement === "right" && (
        <Icon icon={icon} className={classes.icon} />
      )}
    </button>
  );
};
CustomButton.defaultProps = {
  type: "button",
  appearance: "primary",
  icon: null,
  size: "md",
  id: "",
  onClick: () => null,
};
CustomButton.propTypes = {
  id: PropTypes.string,
  icon: PropTypes.string,
  size: PropTypes.oneOf(["lg", "md", "sm", "xs"]),
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  placement: PropTypes.oneOf(["left", "right"]),
  appearance: PropTypes.oneOf(["ghost", "fade", "primary", "alert"]).isRequired,
  customStyles: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node,
};

const CustomIconBtnStyles = createUseStyles({
  CustomIconBtn: {
    "width": "1.5rem",
    "height": "1.5rem",
    "background": variables.colors.easy.lightOrange,
    "borderRadius": "50%",
    "cursor": "pointer",
    "display": "grid",
    "placeItems": "center",
    "& svg": {
      color: "white",
    },
  },
});
export const CustomCircIconBtn = ({ icon, onClick }) => {
  const classes = CustomIconBtnStyles();
  return (
    <div className={classes.CustomIconBtn} onClick={onClick}>
      <Icon icon={icon} />
    </div>
  );
};
CustomCircIconBtn.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
};
