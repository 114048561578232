import { useState } from "react";
import * as Yup from "yup";

export function usePageLimit(pageSize) {
  const defaultPageSize = pageSize || 10;

  const [pageLimit, setPageLimit] = useState(defaultPageSize);

  function increasePageSize() {
    setPageLimit((p) => p + defaultPageSize);
  }

  return { pageLimit, increasePageSize };
}

export const filtersSchema = Yup.object().shape({
  max_price: Yup.mixed().nullable().default(""),
  min_price: Yup.mixed().nullable().default(""),
  order_by: Yup.string().oneOf(["price", "-price"]).nullable().default(""),
  numOfDoors: Yup.string().nullable().default(""),
  transmission: Yup.string().nullable().default(""),
  aircondition: Yup.mixed().nullable().default(""),
  provider: Yup.string().nullable().default(""),
  vehicle: Yup.string().nullable().default(""),
  passengers: Yup.string().nullable().default(""),
});

export const defaultfiltersSchema = Yup.object().shape({
  max_price: Yup.mixed().nullable().default(""),
  min_price: Yup.mixed().nullable().default(""),
  order_by: Yup.string().oneOf(["price", "-price"]).nullable().default(""),
  numOfDoors: Yup.string().nullable().default(""),
  transmission: Yup.string().nullable().default(""),
  aircondition: Yup.mixed().nullable().default(""),
  provider: Yup.string().nullable().default(""),
  vehicle: Yup.string().nullable().default(""),
  passengers: Yup.string().nullable().default(""),
});
