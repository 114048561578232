//======================================FETCH SUBSCRIPTION PLAN================================
export const SUBSCRIPTION_PLAN_BEGIN_FETCH_DATA =
  "SUBSCRIPTION_PLAN_BEGIN_FETCH_DATA";
export const SUBSCRIPTION_PLAN_END_FETCH_DATA =
  "SUBSCRIPTION_PLAN_END_FETCH_DATA";
export const SUBSCRIPTION_PLAN_FETCH_DATA = "SUBSCRIPTION_PLAN_FETCH_DATA";

//=========================================FETCH MANAGE PLAN DATA================================
export const SUBSCRIPTION_PLAN_USERS_LICENSE_DATA =
  "SUBSCRIPTION_PLAN_USERS_LICENSE_DATA";

//====================================== INIT SUBSCRIPTION PLAN=========================
export const SUBSCRIPTION_PLAN_INITIALIZE_PRODUCTS_DATA =
  "SUBSCRIPTION_PLAN_INITIALIZE_PRODUCTS_DATA";
export const SUBSCRIPTION_PLAN_INITIALIZE_ADD_USERS_NUM =
  "SUBSCRIPTION_PLAN_INITIALIZE_ADD_USERS_NUM";

//=====================================EDIT PRODUCTS FEATURES PLAN========================
export const SUBSCRIPTION_PLAN_CHANGE_PRODUCTS =
  "SUBSCRIPTION_PLAN_CHANGE_PRODUCTS";

//===================================== BILLING CYCLE=============================
export const SUBSCRIPTION_PLAN_EDIT_BILLING_CYCLE =
  "SUBSCRIPTION_PLAN_EDIT_BILLING_CYCLE";
export const SUBSCRIPTION_PLAN_SUBMIT_MANAGE_HEADER_EDIT =
  "SUBSCRIPTION_PLAN_SUBMIT_MANAGE_HEADER_EDIT";
export const SUBSCRIPTION_PLAN_INITIALIZE_BILLING_MANAGE_FORM =
  "SUBSCRIPTION_PLAN_INITIALIZE_BILLING_MANAGE_FORM";
export const SUBSCRIPTION_PLAN_MANAGE_INTERVAL_LOADING_EDIT =
  "SUBSCRIPTION_PLAN_MANAGE_INTERVAL_LOADING_EDIT";
export const SUBSCRIPTION_PLAN_INTERVAL_IDLE_EDIT =
  "SUBSCRIPTION_PLAN_INTERVAL_IDLE_EDIT";

//===================================== EDIT USER LICENSE PLAN==========================
export const SUBSCRIPTION_PLAN_EDIT_USER_LICENSE =
  "SUBSCRIPTION_PLAN_EDIT_USER_LICENSE";
export const SUBSCRIPTION_PLAN_TOGGLE_USER_LICENSE_OPERATION_TYPE =
  "SUBSCRIPTION_PLAN_TOGGLE_USER_LICENSE_OPERATION_TYPE";

//===================================MODAL===============================
export const SUBSCRIPTION_PLAN_SHOW_MANAGEMENT_MODAL =
  "SUBSCRIPTION_PLAN_SHOW_MANAGEMENT_MODAL";
export const SUBSCRIPTION_PLAN_HIDE_MANAGEMENT_MODAL =
  "SUBSCRIPTION_PLAN_HIDE_MANAGEMENT_MODAL";

///================================ SUBMIT PRODUCTS FEATURES AND USER LICENSE EDIT ===========================================
export const SUBSCRIPTION_PLAN_SUBMIT_PLAN_EDIT =
  "SUBSCRIPTION_PLAN_SUBMIT_PLAN_EDIT";
export const SUBSCRIPTION_PLAN_LOADING_PLAN = "SUBSCRIPTION_PLAN_LOADING_PLAN";
export const SUBSCRIPTION_PLAN_IDLE_PLAN = "SUBSCRIPTION_PLAN_IDLE_PLAN";

//==================================INIT DATA====================================
export const SUBSCRIPTION_PLAN_DATA_INIT = "SUBSCRIPTION_PLAN_DATA_INIT";
export const SUBSCRIPTION_PLAN_INITIALIZE_SUBSCRIPTION_HEADER_DATA =
  "SUBSCRIPTION_PLAN_INITIALIZE_SUBSCRIPTION_HEADER_DATA";
export const SUBSCRIPTION_PLAN_GET_DISCOUNT = "SUBSCRIPTION_PLAN_GET_DISCOUNT";
