import _ from "lodash";
import { createSelector } from "reselect";

// ==================== MARKETPLACE CONTENT MODAL ====================

const getSectTemplateColors = (state) =>
  state.tripPlannerMktPkgContentModal.templatecolors;

const getPkgMktTemplates = (state) =>
  state.tripPlannerMktPkgContentModal.templates;

export const getSectTemplateColorSelector = createSelector(
  [getSectTemplateColors],
  (colors) =>
    _.get(
      colors.find((color) => color.active),
      "name",
      ""
    )
);

export const getMktPkgTemplateSelector = createSelector(
  [getPkgMktTemplates],
  (templates) =>
    _.get(
      templates.find((tmp) => tmp.active),
      "name",
      ""
    )
);

// =========================== PREVIEW PKG ===========================

const getPkgTemplateColors = (state) =>
  state.tripPlannerMktPkgContent.templatecolors;

const getPkgTemplates = (state) => state.tripPlannerMktPkgContent.templates;

export const getPkgTemplateColorSelector = createSelector(
  [getPkgTemplateColors],
  (colors) =>
    _.get(
      colors.find((color) => color.active),
      "name",
      ""
    )
);

export const getPkgTemplateSelector = createSelector(
  [getPkgTemplates],
  (templates) =>
    _.get(
      templates.find((tmp) => tmp.active),
      "name",
      ""
    )
);
