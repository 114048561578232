import {
  // MULTI_SERVICES_INITIALIZE_LIST_FILTER_FORM,
  // MULTI_SERVICES_APPLY_LIST_FILTER,
  // MULTI_SERVICES_UPDATE_LIST_FILTER_FORM,
  // MULTI_SERVICES_FETCH_DATA,
  // MULTI_SERVICES_BEGIN_FETCH_DATA,
  // MULTI_SERVICES_END_FETCH_DATA,
  // MULTI_SERVICES_APPLY_LIST_COLUMNS,
  // MULTI_SERVICES_INITIALIZE_LIST_COLUMNS_FORM,
  // MULTI_SERVICES_FETCH_DETAIL_VIEW,
  // MULTI_SERVICES_RESERVATION_INIT,
  // MULTI_SERVICES_DETAIL_DATA,
  // MULTI_SERVICES_RESET_LIST_FILTER,
  //========================DASHBOARD===============================
  MULTI_SERVICES_CHANGE_GRAPH_VIEW_TYPE,
  MULTI_SERVICES_FETCH_GRAPH_DATA,
  MULTI_SERVICES_BEGIN_FETCH_GRAPH_DATA,
  MULTI_SERVICES_END_FETCH_GRAPH_DATA,
  MULTI_SERVICES_INIT_GRAPH_TYPE,
} from "@src/actions/Operation/MultiServices/types";

// import {
//   initialMultiServiceFilterForm,
//   initialMultiServiceColumnsSetUp,
// } from "@src/forms/Operation/MultiServices";

// import { formProcessor, entitiesListSelector } from "@src/reducers/tools";

// import update from "immutability-helper";
// import _ from "lodash";
// ============================ LIST VIEW ============================
// export const initialMultiServicesList = {
//   count: 0,
//   next: null,
//   previous: null,
//   results: [],
//   page: 1,
//   limit: 20,
//   displayLength: 100,
// };

// export const multiServicesList = (state = initialMultiServicesList, action) => {
//   switch (action.type) {
//     case MULTI_SERVICES_FETCH_DATA:
//       return action.data;
//     case MULTI_SERVICES_RESET_LIST_FILTER:
//       return { ...state, page: 1 };
//     default:
//       return state;
//   }
// };

// export const initialMultiServicesFetchStatus = "IDLE";
// export const multiServicesFetchStatus = (
//   state = initialMultiServicesFetchStatus,
//   action
// ) => {
//   switch (action.type) {
//     case MULTI_SERVICES_BEGIN_FETCH_DATA:
//       return "FETCHING";
//     case MULTI_SERVICES_END_FETCH_DATA:
//       return "FETCHED";
//     default:
//       return state;
//   }
// };
//========================================FILTER RELATION============================================
// export const multiServicesListFilterForm = (
//   state = initialMultiServiceFilterForm,
//   action
// ) => {
//   switch (action.type) {
//     case MULTI_SERVICES_INITIALIZE_LIST_FILTER_FORM:
//       return action.data;
//     case MULTI_SERVICES_UPDATE_LIST_FILTER_FORM:
//       return formProcessor(
//         state,
//         action.data.type,
//         action.data.name,
//         action.data.value
//       );
//     case MULTI_SERVICES_RESET_LIST_FILTER:
//       return initialMultiServiceFilterForm;
//     default:
//       return state;
//   }
// };

// export const multiServicesListFilter = (
//   state = initialMultiServiceFilterForm,
//   action
// ) => {
//   switch (action.type) {
//     case MULTI_SERVICES_APPLY_LIST_FILTER:
//       return action.data;
//     case MULTI_SERVICES_RESET_LIST_FILTER:
//       return initialMultiServiceFilterForm;
//     default:
//       return state;
//   }
// };

// ========================= COLUMNS RELATION ========================
// export const multiServicesListColumns = (
//   state = entitiesListSelector(initialMultiServiceColumnsSetUp),
//   action
// ) => {
//   switch (action.type) {
//     case MULTI_SERVICES_APPLY_LIST_COLUMNS:
//       return entitiesListSelector(action.data);
//     default:
//       return state;
//   }
// };

// export const multiServicesListColumnsForm = (
//   state = initialMultiServiceColumnsSetUp,
//   action
// ) => {
//   switch (action.type) {
//     case MULTI_SERVICES_INITIALIZE_LIST_COLUMNS_FORM:
//       var newState = {};

//       for (const key in state) {
//         // clear form's value
//         state[key] = update(state[key], { value: { $set: [] } });
//         newState[key] = update(state[key], { value: { $set: [] } });
//         // add value by action data
//         action.data.forEach((item, index) => {
//           if (_.find(state[key].choices, { value: item.value })) {
//             newState[key] = update(newState[key], {
//               value: { $push: [item.value] },
//             });
//           }
//         });
//       }

//       return newState;
//     default:
//       return state;
//   }
// };

// =========================== DETAIL VIEW ===========================
// export const initialMultiServicesNavStatus = "reservation";
// export const multiServicesNavStatus = (
//   state = initialMultiServicesNavStatus,
//   action
// ) => {
//   switch (action.type) {
//     case MULTI_SERVICES_RESERVATION_INIT:
//       return "reservation";
//     case MULTI_SERVICES_FETCH_DETAIL_VIEW:
//       return action.data;
//     default:
//       return state;
//   }
// };

// export const multiServicesDetailInfo = (state = {}, action) => {
//   switch (action.type) {
//     case MULTI_SERVICES_DETAIL_DATA:
//       return action.data;
//     default:
//       return state;
//   }
// };

//====================================DASHBOARD===============================
export const initData = {
  type: "current",
  name: "SR",
};
export const multiServicesGraphViewType = (state = initData, action) => {
  switch (action.type) {
    case MULTI_SERVICES_INIT_GRAPH_TYPE:
      return initData;
    case MULTI_SERVICES_CHANGE_GRAPH_VIEW_TYPE:
      return action.data;
    default:
      return state;
  }
};

export const multiServicesGraphData = (state = {}, action) => {
  switch (action.type) {
    case MULTI_SERVICES_FETCH_GRAPH_DATA:
      return action.data;
    default:
      return state;
  }
};

export const initialMultiServicesGraphDataStatus = "IDLE";
export const multiServicesGraphDataStatus = (
  state = initialMultiServicesGraphDataStatus,
  action
) => {
  switch (action.type) {
    case MULTI_SERVICES_BEGIN_FETCH_GRAPH_DATA:
      return "FETCHING";
    case MULTI_SERVICES_END_FETCH_GRAPH_DATA:
      return "FETCHED";
    default:
      return state;
  }
};
