//=========================LIST===============================
export const PROVIDERS_LIST_SAVE_DATA = "PROVIDERS_LIST_SAVE_DATA";
export const PROVIDERS_LIST_SIMPLE_CHANGE = "PROVIDERS_LIST_SIMPLE_CHANGE";
export const SUPPLIERS_BEGIN_FETCH_DATA = "SUPPLIERS_BEGIN_FETCH_DATA";
export const SUPPLIERS_END_FETCH_DATA = "SUPPLIERS_END_FETCH_DATA";
export const SUPPLIERS_FETCH_OPTIONS = "SUPPLIERS_FETCH_OPTIONS";

//========================FILTER==============================
// export const SUPPLIERS_INITIALIZE_LIST_FILTER_FORM =
//   "SUPPLIERS_INITIALIZE_LIST_FILTER_FORM";
// export const SUPPLIERS_UPDATE_LIST_FILTER_FORM =
//   "SUPPLIERS_UPDATE_LIST_FILTER_FORM";
// export const SUPPLIERS_APPLY_LIST_FILTER = "SUPPLIERS_APPLY_LIST_FILTER";
export const SUPPLIERS_RESET_LIST_FILTER_FORM =
  "SUPPLIERS_RESET_LIST_FILTER_FORM";

//========================COLUMNS=============================
// export const SUPPLIERS_UPDATE_LIST_COLUMNS_FORM =
//   "SUPPLIERS_UPDATE_LIST_COLUMNS_FORM";
// export const SUPPLIERS_APPLY_LIST_COLUMNS = "SUPPLIERS_APPLY_LIST_COLUMNS";
// export const SUPPLIERS_INITIALIZE_LIST_COLUMNS_FORM =
//   "SUPPLIERS_INITIALIZE_LIST_COLUMNS_FORM";

//=========================BASE DATA==========================
export const SUPPLIERS_INITIALIZE_BASE_DATA_EDIT_FORM =
  "SUPPLIERS_INITIALIZE_BASE_DATA_EDIT_FORM";
export const SUPPLIERS_HIDE_BASE_DATA_EDIT_FORM =
  "SUPPLIERS_HIDE_BASE_DATA_EDIT_FORM";
export const SUPPLIERS_SHOW_BASE_DATA_EDIT_FORM =
  "SUPPLIERS_SHOW_BASE_DATA_EDIT_FORM";
export const SUPPLIERS_VALIDATE_BASE_DATA_EDIT_FORM =
  "SUPPLIERS_VALIDATE_BASE_DATA_EDIT_FORM";
export const SUPPLIERS_UPLOADING_BASE_DATA_EDIT_FORM =
  "SUPPLIERS_UPLOADING_BASE_DATA_EDIT_FORM";
export const SUPPLIERS_IDLE_BASE_DATA_EDIT_FORM =
  "SUPPLIERS_IDLE_BASE_DATA_EDIT_FORM";
export const SUPPLIERS_EDIT_BASE_DATA = "SUPPLIERS_EDIT_BASE_DATA";
export const SUPPLIERS_CLEAN_BASE_EDIT_FORM_ERRORS =
  "SUPPLIERS_CLEAN_BASE_EDIT_FORM_ERRORS";
export const SUPPLIERS_CLEAN_BASE_DATA_EDIT_FORM =
  "SUPPLIERS_CLEAN_BASE_DATA_EDIT_FORM";
export const SUPPLIERS_TOGGLE_POSTAL_ADDRESS =
  "SUPPLIERS_TOGGLE_POSTAL_ADDRESS";
export const SUPPLIERS_UPLOAD_PREFERENCE_IMAGE =
  "SUPPLIERS_UPLOAD_PREFERENCE_IMAGE";

// ======================== Service categories ================
export const SUPPLIERS_SELECT_TRAVEL_SERVICE =
  "SUPPLIERS_SELECT_TRAVEL_SERVICE";
export const SUPPLIERS_INITIALIZE_TRAVEL_SERVICE_FORM =
  "SUPPLIERS_INITIALIZE_TRAVEL_SERVICE_FORM";
export const SELECT_SUPPLIERS_SERVICE_CATEGORY =
  "SELECT_SUPPLIERS_SERVICE_CATEGORY";
export const SUPPLIERS_VIEW_SERVICE_CATEGORIES_MODE =
  "SUPPLIERS_VIEW_SERVICE_CATEGORIES_MODE";
export const SUPPLIERS_EDIT_SERVICE_CATEGORIES_MODE =
  "SUPPLIERS_EDIT_SERVICE_CATEGORIES_MODE";
export const SUPPLIERS_LOADING_SERVICE_CATEGORY_MODE =
  "SUPPLIERS_LOADING_SERVICE_CATEGORY_MODE";
export const SUPPLIERS_INITIALIZE_MAIN_SERVICES_FORM =
  "SUPPLIERS_INITIALIZE_MAIN_SERVICES_FORM";
export const SUPPLIERS_UPDATE_MAIN_SERVICES_FORM =
  "SUPPLIERS_UPDATE_MAIN_SERVICES_FORM";
export const SUPPLIERS_CLEAN_SERVICE_BASE_DATA_EDIT_FORM =
  "SUPPLIERS_CLEAN_SERVICE_BASE_DATA_EDIT_FORM";

//==============================GEOLOCATE======================
export const SUPPLIERS_FETCH_ADDRESS_POINT = "SUPPLIERS_FETCH_ADDRESS_POINT";
export const SUPPLIERS_INITIAL_ADDRESS_POINT =
  "SUPPLIERS_INITIAL_ADDRESS_POINT";
export const SUPPLIERS_CLEAN_ADDRESS_POINT = "SUPPLIERS_CLEAN_ADDRESS_POINT";
export const SUPPLIERS_TOGGLE_VERIFY_ADDRESS =
  "SUPPLIERS_TOGGLE_VERIFY_ADDRESS";
export const SUPPLIERS_INITIAL_VERIFY_STATUS =
  "SUPPLIERS_INITIAL_VERIFY_STATUS";
export const SUPPLIERS_CLOSE_VERIFY_ADDRESS = "SUPPLIERS_CLOSE_VERIFY_ADDRESS";
export const SUPPLIERS_BEGIN_FETCH_ADDRESS_DATA =
  "SUPPLIERS_BEGIN_FETCH_ADDRESS_DATA";
export const SUPPLIERS_END_FETCH_ADDRESS_DATA =
  "SUPPLIERS_END_FETCH_ADDRESS_DATA";

//=============================PROFILE=========================
export const SUPPLIERS_LOADING_PROFILE_VIEW = "SUPPLIERS_LOADING_PROFILE_VIEW";
export const SUPPLIERS_LOADED_PROFILE_VIEW = "SUPPLIERS_LOADED_PROFILE_VIEW";
export const SUPPLIERS_PROFILE_DATA = "SUPPLIERS_PROFILE_DATA";

//======================= PROVIDER PROPERTY LIST===============
export const SUPPLIERS_NAV_TAB_INIT = "SUPPLIERS_NAV_TAB_INIT";
export const SUPPLIERS_CHANGE_NAV_TAB = "SUPPLIERS_CHANGE_NAV_TAB";
export const SUPPLIERS_SAVE_PROPERTY = "SUPPLIERS_SAVE_PROPERTY";
export const SUPPLIERS_LOADING_PROPERTY_VIEW =
  "SUPPLIERS_LOADING_PROPERTY_VIEW";
export const SUPPLIERS_LOADED_PROPERTY_VIEW = "SUPPLIERS_LOADED_PROPERTY_VIEW";

//=====================PROVIDER PROPERTY MODAL==================
export const SUPPLIERS_INIT_PROPERTY_MODAL = "SUPPLIERS_INIT_PROPERTY_MODAL";
export const SUPPLIERS_SHOW_PROPERTY_MODAL = "SUPPLIERS_SHOW_PROPERTY_MODAL";
export const SUPPLIERS_HIDE_PROPERTY_MODAL = "SUPPLIERS_HIDE_PROPERTY_MODAL";
export const SUPPLIERS_LOADING_PROPERTY_MODAL =
  "SUPPLIERS_LOADING_PROPERTY_MODAL";
export const SUPPLIERS_UPDATE_PROPERTY_MODAL_FORM =
  "SUPPLIERS_UPDATE_PROPERTY_MODAL_FORM";
export const SUPPLIERS_LOADED_PROPERTY_MODAL =
  "SUPPLIERS_LOADED_PROPERTY_MODAL";
export const SUPPLIERS_SAVE_PROPERTY_DETAIL = "SUPPLIERS_SAVE_PROPERTY_DETAIL";
