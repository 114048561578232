import {
  //=================LIST====================
  MY_CONNECTION_LOADING_LIST,
  MY_CONNECTION_FETCH_LIST_DATA,
  MY_CONNECTION_IDLE_LIST,
  MY_CONNECTION_VIEW_TYPE_CHANGE,
  //================FILTER===================
  MY_CONNECTION_INITIALIZE_LIST_FILTER_FORM,
  MY_CONNECTION_UPDATE_LIST_FILTER_FORM,
  MY_CONNECTION_APPLY_LIST_FILTER,
  MY_CONNECTION_RESET_LIST_FILTER_FORM,

  //==============COLUMNS=====================
  MY_CONNECTION_APPLY_LIST_COLUMNS_FORM,
  //====================MODAL==================
  MY_CONNECTION_DELETE_CONNECTION_MODAL_DATA,
  MY_CONNECTION_SHOW_MODAL,
  MY_CONNECTION_HIDE_MODAL,
  MY_CONNECTION_BEGIN_DELETE_CONNECTION,
  MY_CONNECTION_END_DELETE_CONNECTION,
} from "@src/actions/Network/MyConnections/types";

import {
  initialMembersFilterForm,
  initialMembersColumnsSetUp,
} from "@src/forms/Network/Members";

import { formProcessor, entitiesListSelector } from "@src/reducers/tools";

export const myConnectionsListFilter = (
  state = initialMembersFilterForm,
  action
) => {
  switch (action.type) {
    case MY_CONNECTION_INITIALIZE_LIST_FILTER_FORM:
      return action.data;
    case MY_CONNECTION_UPDATE_LIST_FILTER_FORM:
      return formProcessor(
        state,
        action.data.type,
        action.data.name,
        action.data.value
      );
    case MY_CONNECTION_APPLY_LIST_FILTER:
      return action.data;
    case MY_CONNECTION_RESET_LIST_FILTER_FORM:
      return action.data;
    default:
      return state;
  }
};

export const initialList = {
  count: 0,
  next: null,
  previous: null,
  results: [],
  page: 1,
  limit: 6,
  displayLength: 100,
  loading: false,
};

export const fetchMyConnectionsListData = (state = initialList, action) => {
  switch (action.type) {
    case MY_CONNECTION_FETCH_LIST_DATA:
      return { ...state, ...action.data };
    case MY_CONNECTION_LOADING_LIST:
      return { ...state, loading: true };
    case MY_CONNECTION_IDLE_LIST:
      return { ...state, loading: false };
    case MY_CONNECTION_RESET_LIST_FILTER_FORM:
      return { ...state, page: 1 };
    default:
      return state;
  }
};

export const initialChangeMyConnectionsViewModal = false;
export const changeMyConnectionsViewModal = (
  state = initialChangeMyConnectionsViewModal,
  action
) => {
  switch (action.type) {
    case MY_CONNECTION_VIEW_TYPE_CHANGE:
      return action.data;
    default:
      return state;
  }
};

export const myConnectionsListColumns = (
  state = entitiesListSelector(initialMembersColumnsSetUp),
  action
) => {
  switch (action.type) {
    case MY_CONNECTION_APPLY_LIST_COLUMNS_FORM:
      return entitiesListSelector(action.data);
    default:
      return state;
  }
};

//====================MODAL==================
export const disconnectionModalData = (state = {}, action) => {
  switch (action.type) {
    case MY_CONNECTION_DELETE_CONNECTION_MODAL_DATA:
      return action.data;
    default:
      return state;
  }
};

export const initialDisconnectionModalStatus = false;
export const disconnectionModalStatus = (
  state = initialDisconnectionModalStatus,
  action
) => {
  switch (action.type) {
    case MY_CONNECTION_SHOW_MODAL:
      return true;
    case MY_CONNECTION_HIDE_MODAL:
      return false;
    default:
      return state;
  }
};

export const initialDeleteConnectionStatus = "IDLE";
export const deleteConnectionStatus = (
  state = initialDeleteConnectionStatus,
  action
) => {
  switch (action.type) {
    case MY_CONNECTION_BEGIN_DELETE_CONNECTION:
      return "FETCHING";
    case MY_CONNECTION_END_DELETE_CONNECTION:
      return "FETCHED";
    default:
      return state;
  }
};
