import langDictionaries from "./lang_dictionaries";
import _ from "lodash";

const LANGUAGE = "nl";

var langNlDictionary = {};

langDictionaries.forEach((langDictionary) => {
  const obj = _.get(langDictionary, LANGUAGE, {});
  Object.entries(obj).forEach(([key, value]) => {
    langNlDictionary[key] = value;
  });
});

export default langNlDictionary;
