import _ from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PricingFormContext } from "../TripPlanner";
import OverviewPrice from "./OverviewPrice";
import { FerryData } from "./ferry";
import ServicePricingForm from "./ServicePricingForm";
import { useQuery } from "@tanstack/react-query";
import { pollFerriesSearch } from "@src/api/Project/ferries";
import { BookChecker, ServicePanelGhost } from "./ItineraryOverviewCommon";
import { saveFerryData } from "@src/actions/Project/TripPlanner/ferries";
import { ItinOverviewContext } from "../Controllers/ItineraryOverview";
import { getServicePricingSelector } from "@src/selectors/Project/TripPlanner/pricing";
import { getFerryByLegUidSelector } from "@src/selectors/Project/TripPlanner/ferry_selectors";

const MAXPOLLS = 20;

export const OverviewFerry = ({
  legUid,
  services_collapsed,
  toggleSelfCollapse,
  currentStep,
}) => {
  const [pollCount, setPollCount] = useState(0);
  const [shouldPoll, setShouldPoll] = useState(false);
  const [progress, setProgress] = useState(0);

  const { showPricingForm } = useContext(PricingFormContext);
  const { onViewAllFerries } = useContext(ItinOverviewContext);

  var { session, ferryData } = useSelector((state) => {
    const { session, ferryData } = getFerryByLegUidSelector(state, { legUid });

    return { session, ferryData };
  });

  const dispatch = useDispatch();
  const saveFrData = useCallback(
    (ferryData) => dispatch(saveFerryData(ferryData)),
    [dispatch]
  );

  // Polling for ferry search results if required.
  const { data } = useQuery({
    queryKey: ["ferries_poll", session.session_id],
    queryFn: () => {
      setShouldPoll(true);
      return pollFerriesSearch(session.session_id);
    },
    onSuccess: (data) => {
      const session_id = data?.data?.session_id;
      setPollCount((p) => p + 1);
      setProgress(Math.ceil((pollCount / MAXPOLLS) * 100));
      if (data?.data?.status === "done") {
        setShouldPoll(false);
        setPollCount(0);
        const ferryData = data?.data?.results?.[0];
        ferryData.legUid = legUid;
        ferryData.priced = false;
        ferryData.session_id = session_id;
        saveFrData(ferryData);
      }
    },
    onError: (error) => {
      if (error?.response?.status === 400) {
        setPollCount((p) => p + 1);
        setProgress(Math.ceil((pollCount / MAXPOLLS) * 100));
        return;
      }

      setShouldPoll(false);
      setPollCount(0);
      setProgress(0);
    },
    refetchOnWindowFocus: false,
    refetchInterval: shouldPoll ? 1000 : false,
    refetchIntervalInBackground: true,
    retry: false,
    enabled: _.isEmpty(ferryData),
  });
  if (!ferryData) ferryData = data?.data?.results?.[0];
  const isSearching = shouldPoll && pollCount < MAXPOLLS;

  const price = ferryData?.price?.value ?? 0;
  const currency = ferryData?.price?.currency ?? "EUR";

  const { markup, gross_margin, flat_value } = useSelector((state) => {
    const { markup, gross_margin, flat_value } = getServicePricingSelector(
      state,
      { key: `AFER___${ferryData?.token}` }
    );
    return { markup, gross_margin, flat_value };
  });

  const isPriced = ferryData?.priced ?? false;

  return (
    <React.Fragment>
      {currentStep < 4 || _.isEmpty(ferryData) ? (
        <div />
      ) : currentStep === 4 ? (
        <BookChecker
          uid={ferryData?.token}
          srv_type="AFER"
          disabled={!isPriced}
        />
      ) : (
        <div />
      )}
      {isSearching ? (
        <ServicePanelGhost
          title={`Search progress: (${progress}%) Please wait...`}
          service_type="FER"
          searchProgress={progress}
        />
      ) : _.isEmpty(ferryData) ? (
        <ServicePanelGhost
          failed={true}
          service_type="FER"
          title="Failed to find ferries."
          failHolder={
            <span>
              Please go back to Step 2 and change your{" "}
              <strong>Transportation Preferences</strong>. We are sorry for the
              inconvenience.
            </span>
          }
        />
      ) : (
        <FerryData
          ferryData={ferryData}
          services_collapsed={services_collapsed}
          toggleSelfCollapse={toggleSelfCollapse}>
          {currentStep < 4 && (
            <button
              className="Button"
              data-size="xs"
              type="button"
              onClick={() =>
                onViewAllFerries({ session_id: session.session_id })
              }>
              View All
            </button>
          )}
        </FerryData>
      )}
      {showPricingForm ? (
        <ServicePricingForm service={ferryData} service_type="AFER" />
      ) : services_collapsed ? (
        <div />
      ) : (
        <OverviewPrice
          price={price}
          currency={currency}
          searching={isSearching}
          markup={markup}
          gross_margin={gross_margin}
          flat_value={flat_value}
        />
      )}
    </React.Fragment>
  );
};
OverviewFerry.propTypes = {
  legUid: PropTypes.string.isRequired,
  services_collapsed: PropTypes.bool.isRequired,
  toggleSelfCollapse: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
};
