import _ from "lodash";
import axios from "axios";
import { SISI_API_URL } from "@src/api";
import * as yup from "yup";

export const searchActivitiesSchema = yup.object().shape({
  pax: yup.object().shape({
    adults: yup.number().required("Required"),
    children: yup.number().required("Required"),
    children_ages: yup.array().of(yup.number()),
  }),
  date_from: yup.date().required("Required"),
  date_to: yup.date().required("Required"),
  latitude: yup.number().required("Required"),
  longitude: yup.number().required("Required"),
  radius: yup.number().required("Required"),
  source_entity: yup
    .string()
    .matches(/\d+___member/)
    .required("Required"),
  market_type: yup.string().oneOf(["B2B", "B2C"]).required("Required"),
});

export const initSearchActivities = ({ payload }) => {
  return axios.post(`${SISI_API_URL}/activities/search/`, {
    ...payload,
    market_type: "B2B",
  });
};

export const pollActivitiesSearch = ({ session_id }) => {
  return axios.get(`${SISI_API_URL}/activities/search/${session_id}/`);
};

export const retrieveActivity = async ({ activity_uid, session_id }) => {
  try {
    const response = await axios.get(
      `${SISI_API_URL}/activities/retrieve-activity/${session_id}/`,
      { params: { uid: activity_uid } }
    );

    return _.get(response, "data", {});
  } catch (error) {
    console.error(error);
  }
  return null;
};

export const getActivityPriceInfo = ({ session_id, activity_uid, date }) => {
  return axios.get(`${SISI_API_URL}/activities/choose-date/${session_id}/`, {
    params: { uid: activity_uid, date },
  });
};

export const prebookActivity = async ({
  uid,
  session_id,
  date,
  ticket_option,
  items,
}) => {
  return axios.post(`${SISI_API_URL}/activities/prebook/`, {
    uid,
    session_id,
    date,
    ticket_option,
    items,
  });
};

export const prebookActivityUpdate = async ({ payload, search_session_id }) => {
  return axios.put(
    `${SISI_API_URL}/activities/prebook/${search_session_id}`,
    payload
  );
};
