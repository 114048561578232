import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { destAutoComplete } from "@src/api/Project/TripPlanner";
import { createUseStyles } from "react-jss";
import { tableHeadStyles, variables } from "@src/jsssetup";
import update from "immutability-helper";
import _ from "lodash";
import {
  Form,
  FormControl,
  FormGroup,
  ControlLabel,
  HelpBlock,
  SelectPicker,
  CheckPicker,
  InputNumber,
  Divider,
  DateRangePicker,
  Checkbox,
  Button,
} from "rsuite";
import { Duration } from "luxon";
import DestinationAutocomplete from "sisi_js_components/lib/components/Autocompletes/DestinationAutocomplete";

import ReactQuill from "react-quill";
import { fastDestinationAutocomplete } from "@src/api/cms";

export const getDestPickerData = (destinations = []) => {
  return destinations.map((d) => {
    const lvls = [
      _.get(d, "name_en"),
      _.get(d, "fourth_lvl_name_en"),
      _.get(d, "third_lvl_name_en"),
      _.get(d, "second_lvl_name_en"),
      _.get(d, "first_lvl_name_en"),
      _.get(d, "country_name_en"),
    ].filter((l) => l);

    return {
      label: lvls.join(" - "),
      value: `${d.type}__${d.id}`,
      role: d.country_name_en,
    };
  });
};

export const getDestMeta = (destinations = []) => {
  const destMeta = {};
  destinations.forEach((d) => {
    destMeta[`${d.type}__${d.id}`] = d;
  });

  return destMeta;
};

export async function getDestinations(name) {
  const data = await destAutoComplete({
    name_en: name,
  });

  const destinationData = getDestPickerData(_.get(data, "results", []));
  const destMeta = getDestMeta(_.get(data, "results", []));

  return { destinationData, destMeta };
}

const formStyles = createUseStyles({
  form: {
    "display": "grid",
    "gridTemplateColumns": "repeat(2, 1fr)",
    "gridGap": variables.normal_gap,
    "& .rs-form-control-wrapper": {
      display: "grid!important",
    },
    "& .rs-input-group": {
      width: "100%!important",
    },
    "& textarea": {
      width: "100%",
    },
  },
  span2: {
    gridColumn: "span 2",
  },
  priceTable: {
    width: "100%",
  },
  priceTableHead: {
    ...tableHeadStyles,
  },
  cell: {
    padding: `calc(${variables.normal_gap} / 2)`,
  },
  cell2: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: `calc(${variables.normal_gap} / 2)`,
  },
  tableSelectPicker: {
    maxWidth: "10vw",
  },
  destinationLabel: {
    display: "grid!important",
    gridTemplateColumns: "1fr auto",
    justifyContent: "space-between",
  },
});
const ServiceForm = ({ currencies, data, metadata, onUpdateFormData }) => {
  const classes = formStyles();
  const [formData, setFormData] = useState(data);
  const [destData, setDestData] = useState([]);
  const [destMetadata, setDestMetadata] = useState({});
  const [allPricesSelected, setAllPricesSelected] = useState(1);
  const [manualDestMode, setManualDestMode] = useState(false);

  useEffect(() => {
    if (!_.isEmpty(metadata)) {
      //getDestinations()
      fastDestinationAutocomplete({
        name: _.get(metadata, "destination.name"),
      }).then((data) => {
        setDestData(
          data.destinations.map((dest) => ({
            value: `${dest["type"]}__${dest["id"]}`,
            label: dest.destination,
          }))
        );
        // setDestMetadata(data.destMeta);
      });
    }
  }, []);

  useEffect(() => {
    if (typeof onUpdateFormData === "function") {
      onUpdateFormData(formData);
    }
  }, [formData]);

  const typeData = [
    { value: "ACC", label: "Accommodation" },
    { value: "BUN", label: "Service Combination" },
    { value: "GUI", label: "Guide/Assistant" },
    { value: "INT", label: "Interpreter" },
    { value: "MEAL", label: "Meal" },
    { value: "GEN", label: "Generic Service" },
    { value: "SIGH", label: "Sightseeing" },
    { value: "TRF", label: "Transfer" },
    { value: "VEH", label: "Vehicle Rental" },
  ];

  const durationArray = Duration.fromObject({
    minutes: _.get(formData, "duration", 0) || 0,
  })
    .toFormat("h m")
    .split(" ");

  return _.isEmpty(data) ? null : (
    <Form
      className={classes.form}
      formValue={formData}
      fluid
      onChange={(value) => {
        if (value.destination) {
          value["destination_meta"] = _.get(
            destMetadata,
            `${value.destination}`,
            {}
          );
        }

        setFormData(value);
      }}>
      <div className={classes.span2}>
        <h4>Basic Information for: {formData.id}</h4>
        <Divider />
      </div>
      <FormGroup>
        <ControlLabel>
          <strong>Name</strong>
        </ControlLabel>
        <FormControl name="name" />
        <HelpBlock>Required</HelpBlock>
      </FormGroup>
      <FormGroup>
        <ControlLabel>
          <strong>Codename</strong>
        </ControlLabel>
        <FormControl name="codename" />
        <HelpBlock>Required</HelpBlock>
      </FormGroup>
      <FormGroup>
        <ControlLabel className={classes.destinationLabel}>
          <strong>
            Destination ({_.get(metadata, "destination.name", "N/A")})
          </strong>
          <Button
            size="xs"
            appearance="primary"
            onClick={() => setManualDestMode(!manualDestMode)}>
            {manualDestMode ? "Auto Mode" : "Manual Mode"}
          </Button>
        </ControlLabel>
        {manualDestMode ? (
          <DestinationAutocomplete
            anystatus={false}
            onSelect={async (value) => {
              const meta = getDestMeta([value.destMeta]);
              const pickerData = getDestPickerData([value.destMeta]);
              await setDestData(pickerData);
              await setDestMetadata(meta);
              setManualDestMode(false);
            }}
          />
        ) : (
          <FormControl
            name="destination"
            accepter={SelectPicker}
            data={destData}
          />
        )}
        <HelpBlock>Required</HelpBlock>
      </FormGroup>
      <FormGroup>
        <ControlLabel>
          <strong>Type</strong>
        </ControlLabel>
        <FormControl
          name="type"
          accepter={SelectPicker}
          data={typeData}
          searchable={false}
        />
        <HelpBlock>Required</HelpBlock>
      </FormGroup>
      <FormGroup>
        <ControlLabel>
          <strong>
            Duration ({durationArray[0]} Hours {durationArray[1]} Minutes)
          </strong>
        </ControlLabel>
        <FormControl name="duration" accepter={InputNumber} />
        <HelpBlock>In minutes</HelpBlock>
      </FormGroup>
      <FormGroup>
        <ControlLabel>
          <strong>Languages</strong>
        </ControlLabel>
        <FormControl
          name="languages"
          accepter={CheckPicker}
          data={[
            { label: "English", value: "en" },
            { label: "Chinese", value: "cn" },
            { label: "Japanese", value: "jp" },
            { label: "French", value: "fr" },
            { label: "Deutch", value: "de" },
            { label: "Spanish", value: "es" },
            { label: "Italian", value: "it" },
          ]}
        />
        <HelpBlock>Required</HelpBlock>
      </FormGroup>
      <div className={classes.span2}>
        <h4>Descriptions</h4>
        <Divider />
      </div>
      <FormGroup className={classes.span2}>
        <ControlLabel>
          <strong>Short Description</strong>
        </ControlLabel>
        <FormControl name="short_description_en" componentClass="textarea" />
        <HelpBlock>Required</HelpBlock>
      </FormGroup>
      <FormGroup className={classes.span2}>
        <ControlLabel>
          <strong>Description</strong>
        </ControlLabel>
        <ReactQuill
          className="custom-quill"
          onChange={(value, delta, source) => {
            if (source == "api") {
              return;
            }

            setFormData({ ...formData, description_en: value });
          }}
        />
      </FormGroup>
      <FormGroup className={classes.span2}>
        <ControlLabel>
          <strong>Booking Information</strong>
        </ControlLabel>
        <ReactQuill
          className="custom-quill"
          value={formData.booking_description_en}
          onChange={(value, delta, source) => {
            if (source == "api") {
              return;
            }

            setFormData({ ...formData, booking_description_en: value });
          }}
        />
      </FormGroup>
      <div className={classes.span2}>
        <h4>Pricing</h4>
        <Divider />
      </div>
      <table className={`${classes.priceTable} ${classes.span2}`}>
        <thead className={classes.priceTableHead}>
          <tr>
            <th>
              <Checkbox
                checked={allPricesSelected === 1}
                indeterminate={allPricesSelected === 0}
                onChange={(value, checked) => {
                  setAllPricesSelected(checked ? 1 : -1);
                  setFormData((p) =>
                    update(p, {
                      pricing: {
                        $apply: (pricing) => {
                          return pricing.map((pr) => {
                            pr.import = checked;
                            return pr;
                          });
                        },
                      },
                    })
                  );
                }}
              />
              Import
            </th>
            <th>Id</th>
            <th>Valid From-To</th>
            <th>Price Type</th>
            <th>Availability Type</th>
            <th>Min/Max Pax</th>
            <th>Pax Price</th>
            <th>Unit Price</th>
            <th>Currency</th>
          </tr>
        </thead>
        <tbody>
          {(formData.pricing || []).map((price, idx) => {
            return (
              <tr key={idx}>
                <td className={classes.cell}>
                  <Checkbox
                    checked={price.import}
                    onChange={(value, checked) => {
                      setAllPricesSelected(0);
                      setFormData((p) =>
                        update(p, {
                          pricing: {
                            [idx]: { import: { $set: checked } },
                          },
                        })
                      );
                    }}
                  />
                </td>
                <td className={classes.cell}>
                  <small>{price.price_id}</small>
                </td>
                <td className={classes.cell}>
                  <DateRangePicker value={[price.from_date, price.to_date]} />
                </td>
                <td className={classes.cell}>
                  <SelectPicker
                    value={price.price_unit}
                    searchable={false}
                    data={[
                      { label: "Pax", value: "pax" },
                      { label: "Unit", value: "unit" },
                      { label: "Group", value: "group" },
                    ]}
                    onChange={(value) => {
                      setFormData((p) =>
                        update(p, {
                          pricing: { [idx]: { price_unit: { $set: value } } },
                        })
                      );
                    }}
                  />
                </td>
                <td className={classes.cell}>
                  <SelectPicker
                    className={classes.tableSelectPicker}
                    value={price.availability_type}
                    searchable={false}
                    data={[
                      { label: "Free Sales", value: "free_sales" },
                      {
                        label: "On Request Without Price",
                        value: "on_request_without_price",
                      },
                      {
                        label: "On Request Without Availability",
                        value: "on_request_without_availability",
                      },
                      { label: "Stop Sales", value: "stop_sales" },
                      { label: "Unavailable", value: "unavailable" },
                    ]}
                  />
                </td>
                <td className={`${classes.cell} ${classes.cell2}`}>
                  <InputNumber value={price.min_pax} />
                  <InputNumber value={price.max_pax} />
                </td>
                <td className={classes.cell}>
                  <InputNumber value={price.pax_price} />
                </td>
                <td className={classes.cell}>
                  <InputNumber value={price.unit_price} />
                </td>
                <td className={classes.cell}>
                  <SelectPicker
                    value={price.currency}
                    data={currencies.map((c) => ({ label: c, value: c }))}
                    searchable={false}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Form>
  );
};

ServiceForm.defaultProps = {
  currencies: ["EUR"],
  data: {},
  metadata: {},
};

ServiceForm.propTypes = {
  currencies: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  metadata: PropTypes.object.isRequired,
  onUpdateFormData: PropTypes.func.isRequired,
};

export default ServiceForm;
