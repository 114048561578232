import { commonList } from "@src/reducers/common";
import {
  USERLIST_FILTER_CHANGE,
  USERLIST_SAVE_LIST_DATA,
} from "../../../actions/Admin/Users/types";

export const initialUserList = {
  ...commonList,
  displayLength: 100,
  filters: {
    id: "",
    created__gte: "",
    created__lte: "",
    edited__gte: "",
    edited__lte: "",
    email__icontains: "",
    last_name__icontains: "",
    first_name__icontains: "",
    phone__contains: "",
    creator__icontains: "",
    mobile_phone__contains: "",
    company_legal_title__icontains: "",
    company_name__icontains: "",
    expertise_type__in: [],
    travel_sector__contains: "",
    gender__in: [],
    job_description__in: [],
    company_type__in: [],
    department__in: [],
    travel_services__in: [],
    status__in: [],
    tags: "",
    page: 1,
    limit: 20,
  },
};
export const UserListReducer = (state = initialUserList, action) => {
  switch (action.type) {
    case USERLIST_SAVE_LIST_DATA:
      return action.data;
    case USERLIST_FILTER_CHANGE: {
      const { filters } = action;
      return { ...state, filters: { ...state.filters, ...filters } };
    }
    default:
      return state;
  }
};
