import {
  B2B_COMMON_POLICIES_LIST_GENERIC_CHANGE,
  B2B_COMMON_POLICIES_LIST_SAVE_RESULTS,
} from "../../../actions/Admin/CommonPolicies/types";
import { commonList } from "@src/reducers/common";

const B2BCommonPoliciesListReducerInitial = {
  ...commonList,
  displayLength: 100,
  filters: {
    name__icontains: "",
    booking_window_from__gte: "",
    booking_window_to__lte: "",
    service_execution_from__gte: "",
    service_execution_to__lte: "",
    travel_entities__contains: "",
    travel_service: "",
    creator__icontains: "",
    created__gte: "",
    created__lte: "",
    status: "",
    page: 1,
    limit: 20,
  },
};

export const B2BCommonPoliciesListReducer = (
  state = B2BCommonPoliciesListReducerInitial,
  action
) => {
  switch (action.type) {
    case B2B_COMMON_POLICIES_LIST_SAVE_RESULTS:
      return action.data;
    case B2B_COMMON_POLICIES_LIST_GENERIC_CHANGE: {
      const { filters } = action;
      return { ...state, filters: { ...state.filters, ...filters } };
    }
    default:
      return state;
  }
};
