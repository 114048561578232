import update from "immutability-helper";

import {
  SUBAGENTS_SELECT_SERVICE_CATEGORY,
  SUBAGENTS_SELECT_TRAVEL_SERVICE,
  SUBAGENTS_VIEW_SERVICE_CATEGORIES_MODE,
  SUBAGENTS_EDIT_SERVICE_CATEGORIES_MODE,
  SUBAGENTS_LOADING_SERVICE_CATEGORY_MODE,
  SUBAGENTS_INITIALIZE_TRAVEL_SERVICE_FORM,
  SUBAGENTS_INITIALIZE_MAIN_SERVICES_FORM,
  SUBAGENTS_UPDATE_MAIN_SERVICES_FORM,
} from "@src/actions/Network/SubAgents/types";

import { travelServicesFrom } from "@src/forms/Admin/Company/companyProfileForms";

import { travelServicesFormProcessor } from "@src/reducers/tools";

export const initialSubAgentsServiceCategoriesMode = "VIEW";
export const subAgentsServiceCategoriesMode = (
  state = initialSubAgentsServiceCategoriesMode,
  action
) => {
  switch (action.type) {
    case SUBAGENTS_VIEW_SERVICE_CATEGORIES_MODE:
      return "VIEW";
    case SUBAGENTS_EDIT_SERVICE_CATEGORIES_MODE:
      return "EDIT";
    case SUBAGENTS_LOADING_SERVICE_CATEGORY_MODE:
      return "LOADING";
    default:
      return state;
  }
};

export const subAgentsSelectedServiceCategory = (state = "", action) => {
  switch (action.type) {
    case SUBAGENTS_SELECT_SERVICE_CATEGORY:
      return action.data;
    default:
      return state;
  }
};

export const subAgentsTravelServicesForm = (
  state = travelServicesFrom,
  action
) => {
  switch (action.type) {
    case SUBAGENTS_INITIALIZE_TRAVEL_SERVICE_FORM:
      return update(state, { travel_services: { $set: action.data } });
    case SUBAGENTS_SELECT_TRAVEL_SERVICE:
      return travelServicesFormProcessor(
        state,
        action.data.type,
        "travel_services",
        action.data.value,
        action.data.name,
        action.data.travelServices
      );
    default:
      return state;
  }
};

export const initialSubAgentsMainServicesForm = {
  value: "",
  verbose: "Main Services",
};

export const subAgentsMainServicesForm = (
  state = initialSubAgentsMainServicesForm,
  action
) => {
  switch (action.type) {
    case SUBAGENTS_INITIALIZE_MAIN_SERVICES_FORM:
      return update(state, { value: { $set: action.data } });
    case SUBAGENTS_UPDATE_MAIN_SERVICES_FORM:
      return update(state, { value: { $set: action.data } });
    default:
      break;
  }
  return state;
};
