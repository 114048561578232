import _ from "lodash";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Icon, InlineIcon } from "@iconify/react";
import CheckBoxComp from "./CheckBoxComp";
import { TrainPrice } from "./TrainPrice";

export const TicketTypes = ({ offers, onSelectOffer }) => {
  const [offerCondsExpandedId, setOfferCondsExpandedId] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const offer = offers.find((o) => o.selected);

  return (
    <div className="TicketTypes">
      <div className="TicketTypes__header">
        <div className="TicketTypes__header__headerTitle">{`${offer.comfort_category}, ${offer.flexibility}`}</div>
        <span className="TicketTypes__header__headerPrice">
          <TrainPrice
            currency={offer.amount?.currency}
            price={Number(offer?.amount?.value)}
          />
        </span>
        <InlineIcon
          icon={`material-symbols:keyboard-arrow-${expanded ? "up" : "down"}`}
          onClick={() => setExpanded(!expanded)}
          className="TicketTypes__header__arrowIcon"
        />
      </div>
      {expanded && (
        <div className="TicketTypes__options">
          <div className="TicketTypes__options__headerDivider" />
          {offers.map((off, idx) => (
            <React.Fragment key={idx}>
              <div className="TicketTypes__options__option">
                <CheckBoxComp
                  checked={off?.selected ?? false}
                  setChecked={(checked) =>
                    checked ? onSelectOffer(off.id) : null
                  }
                />
                <span>{`${off.comfort_category}, ${off.flexibility}`}</span>
                <span className="TicketTypes__options__price">
                  <TrainPrice
                    price={off.amount.value}
                    currency={off.amount.currency}
                  />
                </span>
                <div />
                <span
                  className="TicketTypes__options__option__conditions-controller"
                  data-expanded={off.id === offerCondsExpandedId}
                  onClick={() =>
                    off.id === offerCondsExpandedId
                      ? setOfferCondsExpandedId(null)
                      : setOfferCondsExpandedId(off.id)
                  }>
                  <small>Conditions</small>
                  <Icon
                    icon={
                      off.id === offerCondsExpandedId
                        ? "ic:baseline-keyboard-arrow-up"
                        : "ic:baseline-keyboard-arrow-down"
                    }
                  />
                </span>
                <div />
                <div />
                <span
                  className="TicketTypes__options__option__conditions"
                  data-expanded={off.id === offerCondsExpandedId}>
                  <small
                    dangerouslySetInnerHTML={{
                      __html: off?.conditions ?? "",
                    }}
                  />
                </span>
              </div>
              {idx < offers.length - 1 && (
                <div className="TicketTypes__options__divider" />
              )}
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
};
TicketTypes.defaultProps = { offers: [] };
TicketTypes.propTypes = { offers: PropTypes.array.isRequired };
