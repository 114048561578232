import "core-js/stable";
import "regenerator-runtime/runtime";
import "react-quill/dist/quill.snow.css";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App.js";
import ErrorBoundary from "@src/components/common/ErrorBoundary";
import { HashRouter } from "react-router-dom";

import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

Sentry.init({
  dsn: "https://96161f136e1641849c17cf5cd5144364@o301819.ingest.sentry.io/4505271144218624",
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.3,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: 3600000, refetchOnMount: "always" } },
});
ReactDOM.render(
  <HashRouter>
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </ErrorBoundary>
  </HashRouter>,
  document.getElementById("root")
);
