import _ from "lodash";
import { DateTime } from "luxon";

export const createTrfPayloads = (
  transferPrefs,
  trpData = { flights: {} },
  accData,
  destinations,
  pax,
  destOrder = 0,
  extremalIsRound,
  source_entity
) => {
  function formatAirport(airport) {
    const { lat, lng, name, iata } = airport;
    const airname = _.replace(_.deburr(`${name} (${iata})`), "-", " ");
    return { lat, lng, name: airname };
  }

  const payloads = _.flatten(
    Object.entries(accData)
      .filter(([accDestOrder, htl]) =>
        destOrder > 0 ? accDestOrder == destOrder : true
      )
      .map(([accDestOrder, htl]) => {
        const landDestination = destinations.find(
          (d) => d.order == (destOrder == 0 ? 1 : destOrder)
        );

        const acc = htl.find((a) => a.selected);
        if (!acc) {
          return null;
        }

        const legType =
          accDestOrder == 1 ? "extremalLeg" : parseInt(accDestOrder, 0) - 1;

        const inboundFlights = _.get(trpData, `flights.${legType}`, []) || [];
        var inboundFl = inboundFlights.find((f) => f.selected);
        if (!inboundFl) {
          return null;
        }

        const inboundArrivalSegment = _.last(
          inboundFl.outbound_options.find((o) => o.selected).segments
        );
        const inboundArrivalAirport = inboundArrivalSegment.destination_airport;

        var inbound = DateTime.fromISO(inboundArrivalSegment.arrival, {
          setZone: true,
        }).plus({ minutes: transferPrefs.pickupAfterArrival });

        const origin = formatAirport(inboundArrivalAirport);
        const origin_iata = inboundArrivalAirport.iata;

        const destination = {
          lat: acc.metadata.geodata.lat,
          lng: acc.metadata.geodata.lng,
          name: _.replace(_.deburr(acc.metadata.name), "-", " "),
        };

        var payload = {
          destOrder: parseInt(accDestOrder, 10),
          origin_lat: origin.lat,
          origin_lng: origin.lng,
          origin_iata,
          origin_name: origin.name,
          origin_country_code: landDestination.country_code,
          destination_lat: destination.lat,
          destination_lng: destination.lng,
          destination_name: destination.name,
          destination_country_code: landDestination.country_code,
          inbound: inbound.toISO({ includeOffset: false }),
          adults: pax.adults,
          transfer_type: "inbound",
          route_type: "airport_to_point",
          page_size: 100,
        };

        if (pax.children > 0) {
          payload["children"] = pax.children;
          payload["children_ages"] = pax.children_ages;
        }

        var outboundFlights = [];
        var flOptionKey = "outbound_options";

        if (_.last(destinations).order == destOrder) {
          if (extremalIsRound) {
            outboundFlights = trpData.flights["extremalLeg"];
            flOptionKey = "inbound_options";
          } else {
            outboundFlights = _.get(trpData.flights, destOrder, []);
          }
        } else {
          outboundFlights = _.get(trpData.flights, destOrder, []);
        }

        const pls = [];

        if (outboundFlights.length) {
          const outboundFl = outboundFlights.find((f) => f.selected);
          const outboundDepartureSegment = outboundFl[flOptionKey].find(
            (o) => o.selected
          ).segments[0];

          const outbound = DateTime.fromISO(
            outboundDepartureSegment.departure,
            {
              setZone: true,
            }
          ).minus({ minutes: transferPrefs.pickupBeforeDeparture });

          var outPl = {};
          const dest = formatAirport(outboundDepartureSegment.origin_airport);

          outPl = _.cloneDeep(payload);
          delete outPl.inbound;

          outPl["origin_name"] = payload["destination_name"];
          outPl["origin_lat"] = payload["destination_lat"];
          outPl["origin_lng"] = payload["destination_lng"];

          outPl["destination_name"] = dest.name;
          outPl["destination_lat"] = dest.lat;
          outPl["destination_lng"] = dest.lng;

          outPl["outbound"] = outbound.toISO({ includeOffset: false });
          outPl["transfer_type"] = "outbound";
          outPl["route_type"] = "point_to_airport";
          outPl["page_size"] = 100;
          pls.push(outPl);

          pls.push(payload);
        } else {
          pls.push(payload);
        }

        return pls.filter((p) => !_.isEmpty(p));
      })
      .filter((payload) => payload)
  );

  return payloads
    .filter((p) => p)
    .map((p) => ({ ...p, source_entity, market_type: "B2B" }));
};

export const retrieveSelectedTrfKey = (transfers, destOrder, trfType) => {
  if (_.get(transfers, `${destOrder}.inbound_outbound`, null)) {
    return "inbound_outbound";
  } else {
    return trfType;
  }
};

export const retrieveSelectedTrf = (transfers, destOrder, trfType) => {
  var trf = {};
  if (_.get(transfers, `${destOrder}.inbound_outbound`, null)) {
    trf = _.get(transfers, `${destOrder}.inbound_outbound`, []).filter(
      (t) => t.selected
    );
  } else {
    trf = _.get(transfers, `${destOrder}.${trfType}`, []).filter(
      (t) => t.selected
    );
  }

  return _.get(trf, 0, {});
};
