import { Tag } from "rsuite";

import TransTxt from "@src/components/common/SxFormatMessage";
import React from "react";
import _ from "lodash";

import { formatDate } from "@src/tools/date_tools";

export const initialPaymentColumnsSetUp = {
  basic: {
    value: ["type", "brand", "country", "last_digit", "created", "default"],
    choices: [
      {
        key: "id",
        display_name: <TransTxt id="id" startCase />,
      },
      {
        key: "type",
        display_name: <TransTxt id="payment_method__type" startCase />,
      },
      {
        key: "brand",
        display_name: <TransTxt id="payment_method__brand" startCase />,
      },
      {
        key: "created",
        display_name: <TransTxt id="payment_method__created" startCase />,
      },
      {
        key: "country",
        display_name: <TransTxt id="payment_method__country" startCase />,
      },
      {
        key: "last_digit",
        display_name: <TransTxt id="payment_method__last_digit" startCase />,
      },
      {
        key: "default",
        display_name: <TransTxt id="payment_method__default" startCase />,
      },
    ],
  },
};

export const initialPaymentColumnsProcessor = {
  type: {
    key: "type",
    getter: (data) => data.type,
  },
  brand: {
    key: "brand",
    getter: (data) => _.startCase(data.brand),
  },
  created: {
    key: "created",
    getter: (data) => formatDate(data.created),
  },
  last_digit: {
    key: "last_digit",
    getter: (data) => data.last4,
  },
  country: {
    key: "country",
    getter: (data) => data.country,
  },
  default: {
    key: "default",
    getter: (data) => {
      if (data.is_default) {
        return <Tag color="green">Yes</Tag>;
      } else {
        return <Tag style={{ backgroundColor: "transparent" }} />;
      }
    },
    extraGetter: (data) => {
      return data.is_default ? "Yes" : "";
    },
  },
};

export const initialPaymentColumnsMapping = {
  type: {
    key: "type",
    width: 200,
  },
  brand: {
    key: "brand",
    width: 200,
  },
  created: {
    key: "created",
    width: 200,
  },
  country: {
    key: "country",
    width: 200,
  },
  last_digit: {
    key: "last_digit",
    width: 200,
  },
  default: {
    key: "default",
    width: 200,
  },
};
