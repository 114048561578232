import _ from "lodash";
import { variables } from "@src/jsssetup";

export function labelStyles(props) {
  return {
    ..._.get(props, "customStyles.label", {}),
  };
}

export function inputGroupStyles(props) {
  return {
    "display": "grid",
    "gridGap": `calc(${variables.normal_gap} / 2)`,
    "width": "100%",
    "gridAutoRows": "max-content",
    "& >span": {
      color: props.theme === "normal" ? "unset" : "white",
    },
    ..._.get(props, "customStyles.inputGroup", {}),
  };
}
