import axios from "axios";
import { CANAVERAL_API_BASE_URL } from "@src/api";

export async function getImageBankList(params) {
  try {
    const result = await axios.get(`${CANAVERAL_API_BASE_URL}/images/`, {
      params,
    });
    return result.data;
  } catch (error) {
    return null;
  }
}

export function postImageToImageBank(data) {
  return axios({
    method: "post",
    url: `${CANAVERAL_API_BASE_URL}/images/`,
    data,
  });
}

export function getImageBankDetail(id) {
  return axios({
    method: "get",
    url: `${CANAVERAL_API_BASE_URL}/images/${id}`,
  });
}

export function patchImageBankDetail(data, id) {
  return axios({
    method: "patch",
    url: `${CANAVERAL_API_BASE_URL}/images/${id}/`,
    data,
  });
}
