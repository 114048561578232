import { Notification } from "rsuite";
import React from "react";

export const notifyPolicyAddMessage = (msg) => {
  Notification.warning({
    title: "Add Policy Warning",
    description: <p>{msg}</p>,
    duration: 10000,
  });
};

export const notifyPolicySuccessfullyCreated = (msg) => {
  Notification.success({
    title: "Add Policy Successful",
    description: <p>{msg}</p>,
    duration: 10000,
  });
};

export const notifyPolicyAddError = (msg) => {
  Notification.error({
    title: "Policy Error",
    description: <p>{msg}</p>,
    duration: 10000,
  });
};

export const notifyPolicyFormInitFail = () => {
  Notification.warning({
    title: "Initial Data Warning",
    description: <p>Failed to init the data.</p>,
    duration: 10000,
  });
};
