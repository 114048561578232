import moment from "moment";
import { centerToPoints, getGreatCircleCoords } from "@src/map_tools/map";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import InteractiveMapHoc from "../../InteractiveMapHoc";
import { naString } from "@src/tools/string_tools";
import {
  Button,
  Form,
  FormControl,
  FormGroup,
  Input,
  List,
  Nav,
  Panel,
  Rate,
  Tag,
  Timeline,
} from "rsuite";

import bookFlowHoc from "./BookFlowHoc";
import { pkgContentHoc } from "./PkgContentHoc";

class TPkgMap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingAction: false,
    };
  }
  componentDidUpdate() {
    const { loadingAction } = this.state;
    const { map } = this.props;

    if (map && !map.loaded() && !loadingAction) {
      this.setState(
        (p) => ({ ...p, loadingAction: true }),
        () => map.once("load", () => this.initialActions())
      );
    }
  }
  initialActions() {
    this.setSources();
    this.setLayers();
    this.setDestData();
    this.setDestRouteData();
    this.center();
  }
  setDestSources() {
    const { map } = this.props;
    map.addSource("destData", {
      type: "geojson",
      data: { type: "Feature", features: [] },
    });

    map.addSource("destRouteData", {
      type: "geojson",
      data: {
        type: "Feature",
        properties: { visibility: "visible" },
        geometry: { type: "LineString", coordinates: [] },
      },
    });
  }
  setSources() {
    this.setDestSources();
  }
  setDestLayers() {
    const { map } = this.props;

    map.addLayer({
      id: "route",
      type: "line",
      source: "destRouteData",
      filter: ["==", ["get", "visibility"], "visible"],
      layout: { "line-join": "round", "line-cap": "round" },
      paint: { "line-color": "#212e36", "line-width": 3 },
    });

    map.addLayer({
      id: "destinationLayer",
      source: "destData",
      type: "circle",
      filter: ["==", ["get", "type"], "destination"],
      paint: {
        "circle-stroke-width": 2,
        "circle-radius": 20,
        "circle-color": "#7bcacf",
        "circle-stroke-color": "#212e36",
      },
    });

    map.addLayer({
      id: "destText",
      source: "destData",
      type: "symbol",
      filter: ["==", ["get", "visibility"], "visible"],
      layout: {
        "text-field": [
          "concat",
          ["to-string", ["get", "order"]],
          ". ",
          ["get", "title"],
        ],
        "text-allow-overlap": true,
        "text-max-width": 20,
        "text-size": 14,
      },
      paint: {
        "text-color": "black",
        "text-halo-color": "white",
        "text-halo-width": 2,
      },
    });
  }
  setLayers() {
    this.setDestLayers();
  }
  setDestData() {
    const { map, destinations } = this.props;

    if (!map) {
      return;
    }

    map.getSource("destData").setData({
      type: "FeatureCollection",
      features: destinations.map((dest) => ({
        type: "Feature",
        properties: {
          title: dest.name_en,
          order: dest.order,
          visibility: "visible",
          type: "destination",
        },
        geometry: {
          type: "Point",
          coordinates: [dest.geodata.lng, dest.geodata.lat],
        },
      })),
    });
  }
  setDestRouteData() {
    const { map, destinations } = this.props;
    var couples = [
      ..._.zip(
        destinations.map((d) => [d.geodata.lng, d.geodata.lat]),
        destinations
          .filter((d, idx) => idx > 0)
          .map((d) => [d.geodata.lng, d.geodata.lat])
      ),
    ].filter((couple) => couple.filter((c) => c).length == 2);

    var greatCircles = [];

    couples.forEach((c) => {
      getGreatCircleCoords(c[0], c[1]).forEach((cc) => {
        if (cc.length == 2 && cc[0] !== 180 && cc[0] !== -180) {
          greatCircles.push(cc);
        } else {
          cc.forEach((ccc) => {
            if (ccc[0] !== 180 && ccc[0] !== -180) {
              greatCircles.push(ccc);
            }
          });
        }
      });
    });

    map.getSource("destRouteData").setData({
      type: "Feature",
      properties: { visibility: "visible" },
      geometry: {
        type: "LineString",
        coordinates: greatCircles,
      },
    });
  }
  center() {
    const { map } = this.props;
    const points = [];

    map
      .getSource("destData")
      ._data.features.forEach((f) => points.push(f.geometry.coordinates));

    const cPoints = points.filter((pp) => pp.every((p) => p !== 0));

    centerToPoints(map, cPoints, {
      zoom: 9,
    });
  }
  render() {
    return <div id="PkgMap"></div>;
  }
}

TPkgMap.propTypes = {
  map: PropTypes.object,
  mapVersion: PropTypes.number.isRequired,
  destinations: PropTypes.array.isRequired,
};

const PkgMap = InteractiveMapHoc(connect(null, null)(TPkgMap), {
  containerId: "PkgMap",
});

class DayAcc extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { className, service } = this.props;

    return (
      <Panel
        className={className}
        header={
          <h6>
            {_.startCase(service.eventType)}:{" "}
            {_.get(service, "service.metadata.name")}
          </h6>
        }
        bordered>
        <div className={`${className}__img`}>
          <img
            alt=""
            src={_.get(service, "service.metadata.images.0.url", "")}
          />
        </div>
        <div className={`${className}__content`}>
          <span>
            <strong>Rating: </strong>
            <Rate
              value={_.get(service, "service.metadata.rating", 1)}
              size="xs"
              readOnly={true}
            />
          </span>
          <span>
            <strong>Rooms: </strong>
            {[
              ...new Set(
                _.get(service, "service.rooms", [])
                  .filter((room) => room.selected)
                  .map((room) => `${room.name} (${room.board_display})`)
              ),
            ].join(", ")}
          </span>
        </div>
      </Panel>
    );
  }
}

DayAcc.propTypes = {
  className: PropTypes.string.isRequired,
  service: PropTypes.object.isRequired,
};

class DayFlight extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { className, service } = this.props;

    const outboundOption = service.service.outbound_options.find(
      (option) => option.selected
    );
    return (
      <Panel
        className={className}
        header={
          <h6>
            {_.startCase(service.eventType)}:{" "}
            {[
              ...new Set(
                _.flattenDeep(
                  outboundOption.segments.map((seg) => [
                    seg.origin_airport.iata,
                    seg.destination_airport.iata,
                  ])
                )
              ),
            ].join(" - ")}
          </h6>
        }
        bordered>
        <div className={`${className}__img ${className}__img--flight`}>
          <img
            alt=""
            src={_.get(service, "service.outbound_operating_airline.logo", "")}
          />
        </div>
        <div className={`${className}__content`}>
          <span>
            <strong>Departure: </strong>
            {moment
              .parseZone(outboundOption.departure)
              .format("DD/MM/YYYY HH:mm")}
          </span>
          <span>
            <strong>Arrival: </strong>
            {moment
              .parseZone(outboundOption.arrival)
              .format("DD/MM/YYYY HH:mm")}
          </span>
          <span>
            <strong>Flight Number: </strong>
            {[
              ...new Set(
                outboundOption.segments.map((seg) => seg.flight_number)
              ),
            ].join(" - ")}
          </span>
        </div>
      </Panel>
    );
  }
}

DayFlight.propTypes = {
  className: PropTypes.string.isRequired,
  service: PropTypes.object.isRequired,
};

class DayTransfer extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { service, className, transferLeg } = this.props;

    const transfer = service.service[transferLeg];

    return (
      <Panel
        className={className}
        header={
          <h6>
            {service.eventType == "inbound"
              ? "Transportation Hub to Hotel Transfer"
              : "Hotel to Transportation Hub Transfer"}
          </h6>
        }
        bordered>
        <div className={`${className}__img ${className}__img--trf`}>
          <img alt="" src={_.get(transfer, "vehicle.images.0.url", "")} />
        </div>
        <div className={`${className}__content`}>
          <span>
            <strong>Origin: </strong>
            {transferLeg == "inbound"
              ? service.service.origin.name
              : service.service.destination.name}
          </span>
          <span>
            <strong>Destination: </strong>
            {transferLeg == "inbound"
              ? service.service.destination.name
              : service.service.origin.name}
          </span>
          <span>
            <strong>Pickup Time: </strong>
            {moment.parseZone(transfer.start).format("DD/MM/YYYY HH:mm")}
          </span>
        </div>
      </Panel>
    );
  }
}

DayTransfer.propTypes = {
  transferLeg: PropTypes.oneOf(["inbound", "outbound"]).isRequired,
  className: PropTypes.string.isRequired,
  service: PropTypes.object.isRequired,
};

class DayAddons extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { addons, className } = this.props;

    return addons.map((service, idx) => {
      return (
        <Panel
          key={idx}
          className={className}
          header={<h6>{service.service.title}</h6>}
          bordered>
          <div className={`${className}__img ${className}__img`}>
            <img
              alt=""
              src={_.get(
                service.service,
                "items.0.data.image_set.0.photo_sm_url",
                ""
              )}
            />
          </div>
          <div className={`${className}__content`}>
            <span>
              <strong>Attractions: </strong>
              {service.service.items
                .map((item) => item.data.name_en)
                .join(" - ")}
            </span>
          </div>
        </Panel>
      );
    });
  }
}

DayAddons.propTypes = {
  addons: PropTypes.array.isRequired,
  className: PropTypes.string.isRequired,
};

export class DayServices extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { day, baseCls } = this.props;

    const arrivalFlight = day.services.find(
      (srv) => srv.eventType == "arrival"
    );
    const departureFlight = day.services.find(
      (srv) => srv.eventType == "departure"
    );
    const accCheckIn = day.services.find((srv) => srv.eventType == "checkIn");
    const accCheckOut = day.services.find((srv) => srv.eventType == "checkOut");
    const inboundTrf = day.services.find((srv) => srv.eventType == "inbound");
    const outboundTrf = day.services.find((srv) => srv.eventType == "outbound");
    const addons = day.services.filter((srv) => srv.eventType == "addon");

    return (
      <div className={`${baseCls}__dayservices`}>
        <React.Fragment>
          {accCheckOut ? (
            <DayAcc
              className={`${baseCls}__dayservices__service`}
              service={accCheckOut}
            />
          ) : null}
          {outboundTrf ? (
            <DayTransfer
              className={`${baseCls}__dayservices__service`}
              service={outboundTrf}
              transferLeg="outbound"
            />
          ) : null}
          {departureFlight ? (
            <DayFlight
              className={`${baseCls}__dayservices__service`}
              service={departureFlight}
            />
          ) : null}
          {arrivalFlight ? (
            <DayFlight
              className={`${baseCls}__dayservices__service`}
              service={arrivalFlight}
            />
          ) : null}
          {inboundTrf ? (
            <DayTransfer
              className={`${baseCls}__dayservices__service`}
              service={inboundTrf}
              transferLeg="inbound"
            />
          ) : null}
          {accCheckIn ? (
            <DayAcc
              className={`${baseCls}__dayservices__service`}
              service={accCheckIn}
            />
          ) : null}
          {addons ? (
            <DayAddons
              className={`${baseCls}__dayservices__service`}
              addons={addons}
            />
          ) : null}
        </React.Fragment>
      </div>
    );
  }
}

DayServices.propTypes = {
  baseCls: PropTypes.string.isRequired,
  day: PropTypes.object.isRequired,
};

class Day extends React.PureComponent {
  constructor(props) {
    super(props);
    this.baseCls =
      "TravelPackage--sectionedtemplate__content__itinerary__timeline__description";

    this.state = {
      activeSection: "description", // or services
    };

    this.handleChangeSection = this.handleChangeSection.bind(this);
  }
  handleChangeSection(section) {
    this.setState((p) => ({ ...p, activeSection: section }));
  }
  renderDescription() {
    const { day, mode, data, onDescChange } = this.props;

    return (
      <div className={`${this.baseCls}__daydescription`}>
        {mode == "edit" ? (
          <Form
            formValue={_.get(data, "description", { en: "" })}
            className={`${this.baseCls}__daydescription__form`}
            onChange={(formValue) => onDescChange(day.day, formValue)}>
            <FormGroup>
              {" "}
              <FormControl rows={5} name="en" componentClass="textarea" />
            </FormGroup>
          </Form>
        ) : (
          <p>
            {day.services.length
              ? naString(_.get(data, "description.en"))
              : _.get(data, "description.en") || "Day at Leisure."}
          </p>
        )}
      </div>
    );
  }
  renderServices() {
    const { day } = this.props;
    return <DayServices baseCls={this.baseCls} day={day} />;
  }
  renderRouter() {
    const { activeSection } = this.state;

    switch (activeSection) {
      case "description":
        return this.renderDescription();
      case "services":
        return this.renderServices();
      default:
        return null;
    }
  }
  render() {
    const { activeSection } = this.state;
    const { day, isLastDay } = this.props;

    const atLeisure = day.services.length == 0;
    return (
      <Panel
        className={`${this.baseCls} ${
          atLeisure
            ? `${this.baseCls}--atleisure ${this.baseCls}--atleisure--${
                day.day % 2 ? "right" : "left"
              }`
            : ""
        }`}
        shaded
        header={
          <h6>
            {day.day == 1
              ? "Arrival Day: "
              : isLastDay
              ? "Departure Day: "
              : ""}
            {day.destinations.map((dest) => dest.name_en).join(" to ")}
            {atLeisure ? " (day at leisure)" : ""}
          </h6>
        }>
        <div
          className={`${this.baseCls}__dayimage ${this.baseCls}__dayimage--${
            day.destinations.length
          } ${atLeisure ? `${this.baseCls}__dayimage--atleisure` : ""}`}>
          {day.destinations.map((dest, didx) => {
            return (
              <img
                key={didx}
                alt=""
                src={_.get(
                  dest,
                  `images.${
                    Math.abs(day.day - dest.order) %
                    _.get(dest, "images.length", [])
                  }.photo_sm_url`
                )}
              />
            );
          })}
        </div>
        {atLeisure ? null : (
          <React.Fragment>
            <Nav appearance="subtle" activeKey={activeSection}>
              <Nav.Item
                eventKey="description"
                onClick={() => this.handleChangeSection("description")}>
                <strong>Description</strong>
              </Nav.Item>
              <Nav.Item
                eventKey="services"
                onClick={() => this.handleChangeSection("services")}>
                <strong>Services</strong>
              </Nav.Item>
            </Nav>
            {this.renderRouter()}
          </React.Fragment>
        )}
      </Panel>
    );
  }
}

Day.propTypes = {
  day: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  isLastDay: PropTypes.bool.isRequired,
  mode: PropTypes.oneOf(["edit", "view"]).isRequired,
  onDescChange: PropTypes.func.isRequired,
};

class TSectionedTemplate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeSection: "overview",
    };

    this.handleSectionChange = this.handleSectionChange.bind(this);
  }
  handleSectionChange(section) {
    this.setState((p) => ({ ...p, activeSection: section }));
  }
  renderOverview() {
    const {
      destinations,
      mode,
      price,
      generalDescription,
      pkgTitle,
      onPkgTitleChange,
      onGenDescChange,
    } = this.props;
    const baseCls = "TravelPackage--sectionedtemplate__content__overview";

    const tripStay = moment
      .duration(
        moment(_.last(destinations).checkOut).diff(
          moment(destinations[0].checkIn)
        )
      )
      .days();

    return (
      <div className={baseCls}>
        <div className={`${baseCls}__description`}>
          <div className={`${baseCls}__description__title`}>
            {mode == "view" ? (
              <h4>{naString(pkgTitle.en)}</h4>
            ) : (
              <Input
                value={pkgTitle.en}
                onChange={(value) => onPkgTitleChange(value)}
              />
            )}
          </div>
          <div className={`${baseCls}__description__subtitle`}>
            <span>
              <strong>
                {tripStay + 1} Days / {tripStay}{" "}
                {tripStay == 1 ? "Night" : "Nights"}
              </strong>
            </span>
            <span>
              <strong>From: </strong>
              <Tag
                className={`${baseCls}__description__subtitle__pricetag`}
                color="green">
                <strong>{price}</strong>
              </Tag>
            </span>
          </div>
          <div className={`${baseCls}__description__description`}>
            {mode == "view" ? (
              <p>{naString(generalDescription.en)}</p>
            ) : (
              <Form
                formValue={generalDescription}
                className={`${baseCls}__form`}
                onChange={(formValue) => onGenDescChange(formValue)}>
                <FormGroup>
                  {" "}
                  <FormControl rows={5} name="en" componentClass="textarea" />
                </FormGroup>
              </Form>
            )}
          </div>
          <div className={`${baseCls}__description__destinations`}>
            {destinations.map((dest, idx) => {
              const stay = moment
                .duration(moment(dest.checkOut).diff(moment(dest.checkIn)))
                .days();
              return (
                <Panel
                  key={idx}
                  shaded
                  header={
                    <h6>
                      {idx + 1}. {dest.name_en} ({stay}{" "}
                      {stay == 1 ? "Night" : "Nights"})
                    </h6>
                  }
                />
              );
            })}
          </div>
        </div>
        <div className={`${baseCls}__map`}>
          <PkgMap destinations={destinations} />
        </div>
      </div>
    );
  }
  renderItinerary() {
    const { days, mode, itineraryDaysData, onDayDescChange } = this.props;
    const baseCls = "TravelPackage--sectionedtemplate__content__itinerary";

    return (
      <div className={baseCls}>
        <h4 className={`${baseCls}__header`}>Trip Timeline</h4>
        <Timeline className={`${baseCls}__timeline`} align="alternate">
          {days.map((day, idx) => (
            <Timeline.Item
              key={idx}
              time={
                <Tag className={`${baseCls}__timeline__time`}>
                  <strong>Day {day.day}</strong>
                </Tag>
              }>
              <Day
                key={idx}
                day={day}
                mode={mode}
                isLastDay={idx + 1 == days.length}
                data={itineraryDaysData[day.day]}
                onDescChange={onDayDescChange}
              />
            </Timeline.Item>
          ))}
        </Timeline>
      </div>
    );
  }
  renderInclusionsExclusions() {
    const { inclusions, exclusions } = this.props;

    const baseCls = "TravelPackage--sectionedtemplate__content__incexc";

    return (
      <div className={baseCls}>
        <div className={`${baseCls}__inclusions`}>
          <h4>Trip Inclusions</h4>
          <List className={`${baseCls}__inclusions__ruleslist`}>
            {inclusions.map((rule, idx) => (
              <List.Item
                key={idx}
                className={`${baseCls}__inclusions__ruleslist__rule`}>
                <span
                  className={`${baseCls}__inclusions__ruleslist__rule__dot`}></span>
                <div
                  className={`${baseCls}__inclusions__ruleslist__rule__content`}>
                  <span>
                    <strong>{rule.name}</strong>
                  </span>
                  <p>
                    <small>{rule.description}</small>
                  </p>
                </div>
              </List.Item>
            ))}
          </List>
        </div>
        <div className={`${baseCls}__exclusions`}>
          <h4>Trip Exclusions</h4>
          <List className={`${baseCls}__inclusions__ruleslist`}>
            {exclusions.map((rule, idx) => (
              <List.Item
                key={idx}
                className={`${baseCls}__inclusions__ruleslist__rule`}>
                <span
                  className={`${baseCls}__inclusions__ruleslist__rule__dot`}></span>
                <div
                  className={`${baseCls}__inclusions__ruleslist__rule__content`}>
                  <span>
                    <strong>{rule.name}</strong>
                  </span>
                  <p>
                    <small>{rule.description}</small>
                  </p>
                </div>
              </List.Item>
            ))}
          </List>
        </div>
      </div>
    );
  }
  renderRouter() {
    const { activeSection } = this.state;

    switch (activeSection) {
      case "overview":
        return this.renderOverview();
      case "itinerary":
        return this.renderItinerary();
      case "inclusions_exclusions":
        return this.renderInclusionsExclusions();
      default:
        return null;
    }
  }
  render() {
    const { activeSection } = this.state;
    const {
      mode,
      color,
      destinations,
      step,
      loading,
      pkgTitle,
      pkgSubTitle,
      onGoToRecalculate,
      renderRecalcOverlay,
      renderLoadingOverlay,
      onPkgSubTitleChange,
    } = this.props;

    const baseCls = "TravelPackage--sectionedtemplate";

    return (
      <div className={`${baseCls} ${baseCls}--${color}`}>
        <div className={`${baseCls}__header`}>
          <img alt="" src={_.get(destinations, "0.images.0.photo_l_url")} />
          <div className={`${baseCls}__header__overlay`}></div>
          <h2 className={`${baseCls}__header__title`}>
            {naString(pkgTitle.en)}
          </h2>
          <h3 className={`${baseCls}__header__subtitle`}>
            {mode == "view" ? (
              naString(pkgSubTitle.en)
            ) : (
              <Input
                value={pkgSubTitle.en}
                onChange={(value) => onPkgSubTitleChange(value)}
              />
            )}
          </h3>
        </div>
        <Nav className={`${baseCls}__nav`}>
          <Nav.Item
            active={activeSection == "overview"}
            onClick={() => this.handleSectionChange("overview")}>
            Overview
          </Nav.Item>
          <Nav.Item
            active={activeSection == "itinerary"}
            onClick={() => this.handleSectionChange("itinerary")}>
            Itinerary
          </Nav.Item>
          <Nav.Item
            active={activeSection == "inclusions_exclusions"}
            onClick={() => this.handleSectionChange("inclusions_exclusions")}>
            Inclusions & Exclusions
          </Nav.Item>
          <Button
            className={`${baseCls}__nav__recalcbtn`}
            onClick={onGoToRecalculate}>
            <strong>Book Now</strong>
          </Button>
        </Nav>
        <div className={`${baseCls}__content`}>{this.renderRouter()}</div>
        {step == "recalculate" && !loading ? renderRecalcOverlay() : null}
        {loading ? renderLoadingOverlay() : null}
      </div>
    );
  }
}

TSectionedTemplate.defaultProps = {
  color: "teal",
  destinations: [],
  days: [],
  mode: "view",
  inclusions: [],
  exclusions: [],
  price: 0,
  pricePerPerson: 0,
};

TSectionedTemplate.propTypes = {
  destinations: PropTypes.array.isRequired,
  days: PropTypes.array.isRequired,
  mode: PropTypes.oneOf(["edit", "view"]).isRequired,
  inclusions: PropTypes.array.isRequired,
  exclusions: PropTypes.array.isRequired,
  color: PropTypes.oneOf(["teal", "purple"]).isRequired,
  price: PropTypes.number.isRequired,
  pricePerPerson: PropTypes.number.isRequired,
  content: PropTypes.object.isRequired,
  step: PropTypes.oneOf(["overview", "recalculate"]).isRequired,
  loading: PropTypes.bool.isRequired,
  itineraryDaysData: PropTypes.array.isRequired,
  pkgTitle: PropTypes.object.isRequired,
  pkgSubTitle: PropTypes.object.isRequired,
  pkgStyle: PropTypes.object.isRequired,
  generalDescription: PropTypes.object.isRequired,
  onContentChange: PropTypes.func.isRequired,
  onGoToRecalculate: PropTypes.func.isRequired,
  renderRecalcOverlay: PropTypes.func.isRequired,
  renderLoadingOverlay: PropTypes.func.isRequired,
  onPkgTitleChange: PropTypes.func.isRequired,
  onGenDescChange: PropTypes.func.isRequired,
  onDayDescChange: PropTypes.func.isRequired,
  onPkgSubTitleChange: PropTypes.func.isRequired,
};

export default bookFlowHoc(pkgContentHoc(TSectionedTemplate));
