import service from "@src/api/request";

export function getPayableListData(param) {
  return service({
    url: "/bid/payable/",
    method: "get",
    params: param,
  });
}

export function getPayableGraphData(params) {
  return service({
    url: "bid/payable-stats/",
    method: "get",
    params: params,
  });
}
