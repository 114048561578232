import {
  B2C_SUB_ENTITIES_STATEMENT_INIT,
  // ==================== DEPOSIT BALANCE ACTIONS ====================
  B2C_SUB_ENTITIES_STATEMENT_FETCHING_DEPOSIT_BALANCE,
  B2C_SUB_ENTITIES_STATEMENT_FETCHED_DEPOSIT_BALANCE,
  B2C_SUB_ENTITIES_STATEMENT_SET_DEPOSIT_BALANCE,
  // ======================== DEPOSIT ENTRIES ========================
  B2C_SUB_ENTITIES_STATEMENT_SET_DEPOSIT_ENTRIES,
  B2C_SUB_ENTITIES_STATEMENT_FETCHING_DEPOSIT_ENTRIES,
  B2C_SUB_ENTITIES_STATEMENT_FETCHED_DEPOSIT_ENTRIES,
  //========================= GRAPH DATA =============================
  B2C_SUB_ENTITIES_STATEMENT_STAT_DATA,
  B2C_SUB_ENTITIES_STATEMENT_VIEW_TYPE,
  B2C_SUB_ENTITIES_STATEMENT_INIT_VIEW_TYPE,
  B2C_SUB_ENTITIES_STATEMENT_FETCHING_STAT_DATA,
  B2C_SUB_ENTITIES_STATEMENT_FETCHED_STAT_DATA,
  //========================= Filter =================================
  B2C_SUB_ENTITIES_STATEMENT_INITIALIZE_LIST_FILTER_FORM,
  B2C_SUB_ENTITIES_STATEMENT_UPDATE_LIST_FILTER_FORM,
} from "@src/actions/ManagementConsole/B2CStatement/types";

import update from "immutability-helper";
import { formProcessor } from "@src/reducers/tools";
import { initialB2CAccountStatementFilterForm } from "@src/forms/ManagementConsole/B2CStatement";

export const initialFetchB2CSubEntitiesStatementStatus = "IDLE";
export const fetchB2CSubEntitiesStatementStatus = (
  state = initialFetchB2CSubEntitiesStatementStatus,
  action
) => {
  switch (action.type) {
    case B2C_SUB_ENTITIES_STATEMENT_INIT:
      return "IDLE";
    case B2C_SUB_ENTITIES_STATEMENT_FETCHING_DEPOSIT_ENTRIES:
      return "FETCHING";
    case B2C_SUB_ENTITIES_STATEMENT_FETCHED_DEPOSIT_ENTRIES:
      return "FETCHED";
    default:
      return state;
  }
};

export const initialList = {
  count: 0,
  next: null,
  previous: null,
  details: [],
  page: 1,
  limit: 20,
  displayLength: 100,
};
export const b2cSubEntitiesStatementData = (state = initialList, action) => {
  switch (action.type) {
    case B2C_SUB_ENTITIES_STATEMENT_INIT:
      return initialList;
    case B2C_SUB_ENTITIES_STATEMENT_SET_DEPOSIT_ENTRIES:
      return action.data;
    case B2C_SUB_ENTITIES_STATEMENT_INITIALIZE_LIST_FILTER_FORM:
      return { ...state, page: 1 };
    default:
      return state;
  }
};

export const subagentsStatementDepositBalanceInitial = {
  balance: 0,
  total_debit: 0,
  total_credit: 0,
  currency: {},
  loading: false,
};
export const b2cSubEntitiesStatementDepositBalance = (
  state = subagentsStatementDepositBalanceInitial,
  action
) => {
  switch (action.type) {
    case B2C_SUB_ENTITIES_STATEMENT_INIT:
      return subagentsStatementDepositBalanceInitial;
    case B2C_SUB_ENTITIES_STATEMENT_FETCHING_DEPOSIT_BALANCE:
      return update(state, { loading: { $set: true } });
    case B2C_SUB_ENTITIES_STATEMENT_FETCHED_DEPOSIT_BALANCE:
      return update(state, { loading: { $set: false } });
    case B2C_SUB_ENTITIES_STATEMENT_SET_DEPOSIT_BALANCE: {
      const { balance, total_debit, total_credit, currency } = action;
      return update(state, {
        balance: { $set: balance },
        total_credit: { $set: total_credit },
        total_debit: { $set: total_debit },
        currency: { $set: currency },
      });
    }
    default:
      return state;
  }
};

export const b2cSubEntitiesStatementGraphData = (state = {}, action) => {
  switch (action.type) {
    case B2C_SUB_ENTITIES_STATEMENT_STAT_DATA:
      return action.data;
    default:
      return state;
  }
};

export const initType = "current";

export const b2cSubEntitiesStatementViewType = (state = initType, action) => {
  switch (action.type) {
    case B2C_SUB_ENTITIES_STATEMENT_INIT_VIEW_TYPE:
      return initType;
    case B2C_SUB_ENTITIES_STATEMENT_VIEW_TYPE:
      return action.data;
    default:
      return state;
  }
};

export const b2cSubEntitiesStatementListFilterForm = (
  state = initialB2CAccountStatementFilterForm,
  action
) => {
  switch (action.type) {
    case B2C_SUB_ENTITIES_STATEMENT_INITIALIZE_LIST_FILTER_FORM: {
      return initialB2CAccountStatementFilterForm;
    }
    case B2C_SUB_ENTITIES_STATEMENT_UPDATE_LIST_FILTER_FORM:
      return formProcessor(
        state,
        action.data.type,
        action.data.name,
        action.data.value
      );
    default:
      return state;
  }
};

export const b2cSubEntitiesStatementStatsFetchingStatus = (
  state = "IDLE",
  action
) => {
  switch (action.type) {
    case B2C_SUB_ENTITIES_STATEMENT_FETCHING_STAT_DATA:
      return "FETCHING";
    case B2C_SUB_ENTITIES_STATEMENT_FETCHED_STAT_DATA:
      return "FETCHED";
    default:
      return state;
  }
};
