import {
  PRODUCTS_NAV_BAR_STATUS,
  PRODUCTS_FETCH_FEATURES_LIST,
  PRODUCTS_BEGIN_FETCH_FEATURES_DATA,
  PRODUCTS_END_FETCH_FEATURES_DATA,
  PRODUCTS_FETCH_FEATURES_DETAIL,
  //=========================Modal======================
  PRODUCTS_SHOW_LANDING_MODAL,
  PRODUCTS_HIDE_LANDING_MODAL,
  //=========================Auto Play==================
  PRODUCTS_HANDLE_SECTION_AUTO_PLAY,
  PRODUCTS_INIT_SECTION_AUTO_PLAY,
  PRODUCTS_HANDLE_SECTION_SELECT,
  PRODUCTS_INIT_SECTION_SELECT,
} from "@src/actions/ManagementConsole/Products/types";

export const initialProductNavBarValue = "trip_planning";
export const productNavBarValue = (
  state = initialProductNavBarValue,
  action
) => {
  switch (action.type) {
    case PRODUCTS_NAV_BAR_STATUS:
      return action.data;
    default:
      return state;
  }
};

export const producesFeaturesData = (state = {}, action) => {
  switch (action.type) {
    case PRODUCTS_FETCH_FEATURES_LIST:
      return action.data;
    default:
      return state;
  }
};

export const initialProductsFeaturesDataStatus = "IDLE";
export const productsFeaturesDataStatus = (
  state = initialProductsFeaturesDataStatus,
  action
) => {
  switch (action.type) {
    case PRODUCTS_BEGIN_FETCH_FEATURES_DATA:
      return "FETCHING";
    case PRODUCTS_END_FETCH_FEATURES_DATA:
      return "FETCHED";
    default:
      return state;
  }
};

export const productsFeaturesDetail = (state = {}, action) => {
  switch (action.type) {
    case PRODUCTS_FETCH_FEATURES_DETAIL:
      return action.data;
    default:
      return state;
  }
};

//=========================Modal======================
export const initialModal = {
  id: "",
  mode: "HIDE",
};
export const productsLandingModalData = (state = initialModal, action) => {
  switch (action.type) {
    case PRODUCTS_SHOW_LANDING_MODAL:
      return action.data;
    case PRODUCTS_HIDE_LANDING_MODAL:
      return initialModal;
    default:
      return state;
  }
};

//==============================Auto Play========================
export const initialProductsSectionAutoPlayStatus = false;
export const productsSectionAutoPlayStatus = (
  state = initialProductsSectionAutoPlayStatus,
  action
) => {
  switch (action.type) {
    case PRODUCTS_INIT_SECTION_AUTO_PLAY:
      return false;
    case PRODUCTS_HANDLE_SECTION_AUTO_PLAY:
      return action.data;
    default:
      return state;
  }
};

export const initialProductsSectionSelectKey = 1;
export const productsSectionSelectKey = (
  state = initialProductsSectionSelectKey,
  action
) => {
  switch (action.type) {
    case PRODUCTS_INIT_SECTION_SELECT:
      return 1;
    case PRODUCTS_HANDLE_SECTION_SELECT:
      return action.data;
    default:
      return state;
  }
};
