import _ from "lodash";
import { getServicesListSelector } from "@src/selectors/Project/TripPlanner/generic_service_selectors";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import CancellationPolicyDetails from "../../CancellationPolicyDetails";
import { Form, Formik, useField, useFormikContext } from "formik";
import { NormalInputField, NormalSelectField } from "@src/components/forms";
import {
  convertAmountSelector,
  convertAmountToCurrencySelector,
  getCurrencySelector,
} from "@src/selectors/Financial";
import { setCxlPolicyData } from "@src/actions/Project/TripPlanner/pricing";
import { bankersRounder, markUpper } from "@src/tools/pricing_tools";
import { getSelectedActivityAttrs } from "../products/activities/details/ActivityPriceModal";
import {
  getServicePricingSelector,
  srvPriceKeyCreator,
} from "@src/selectors/Project/TripPlanner/pricing";

export const cxlPolicyShape = yup.object().shape({
  from: yup
    .string()
    .required("Required")
    .default(DateTime.now().plus({ days: 1 }).toISO({ includeOffset: false }))
    .test(
      "is-before-to",
      "From date must be before To date ",
      function (value) {
        const { to } = this.parent;
        const dt = DateTime.fromISO(value, { setZone: true });
        const toDt = DateTime.fromISO(to, { setZone: true });
        return dt < toDt ? true : false;
      }
    ),
  to: yup
    .string()
    .required("Required")
    .default(DateTime.now().plus({ days: 7 }).toISO({ includeOffset: false }))
    .test("is-after-from", "To date must be after from date", function (value) {
      const { from } = this.parent;
      const dt = DateTime.fromISO(value, { setZone: true });
      const fromDt = DateTime.fromISO(from, { setZone: true });
      return dt > fromDt ? true : false;
    }),
  fee: yup
    .number()
    .required("Required")
    .default(0)
    .test(
      "if-type-refundable-fee-must-be-zero",
      "Fee must be zero for refundable policies",
      function (value) {
        const { type } = this.parent;
        return type === "refundable" ? value === 0 : true;
      }
    ),
  type: yup
    .string()
    .required("Required")
    .oneOf(["refundable", "non_refundable", "chargeable"])
    .default("non_refundable"),
  reason: yup.string().default(""),
  currency: yup.string().required("Required").length(3).default("EUR"),
});

function getToughestPolicy({ totalFee, cxlPolicies, buffer, tripStartDate }) {
  var toughestCxlPolicy = [];

  const validCxlPolicies = _.cloneDeep(cxlPolicies);
  toughestCxlPolicy.push(
    _.sortBy(
      validCxlPolicies.filter((c) => c.type === "refundable"),
      (v) => DateTime.fromISO(v.to).toMillis()
    )?.[0]
  );
  toughestCxlPolicy = toughestCxlPolicy.filter((v) => v);

  if (toughestCxlPolicy.length > 0) {
    toughestCxlPolicy.push(
      cxlPolicyShape.cast({
        from: DateTime.fromISO(toughestCxlPolicy?.[0]?.to, { setZone: true })
          .plus({ minutes: 1 })
          .toISO({ includeOffset: false }),
        to: DateTime.fromISO(tripStartDate, { setZone: true }).toISO({
          includeOffset: false,
        }),
        type: "chargeable",
        fee: totalFee,
        currency: toughestCxlPolicy?.[0]?.currency ?? "EUR",
      })
    );
  }

  if (buffer > 0) {
    toughestCxlPolicy.forEach((cxl, idx) => {
      if (idx === 0) {
        cxl.to = DateTime.fromISO(cxl.to, { setZone: true })
          .minus({ days: buffer })
          .toISO({ includeOffset: false });
        return;
      }
      cxl.from = DateTime.fromISO(cxl.from, { setZone: true })
        .minus({ days: buffer })
        .toISO({ includeOffset: false });
    });
  }

  return toughestCxlPolicy;
}

const TotalsAmountConvertor = ({
  amounts = [],
  rounding = "no-rounding",
  priceBuffer = 0,
}) => {
  const convertedAmounts = useSelector((state) => {
    return amounts.map((dep) => {
      const convertedFee = convertAmountSelector(state, {
        amount: dep.value,
        currentCurrency: dep.currency,
      });
      const { convertedAmount, currency } = convertedFee;
      return { value: convertedAmount, currency };
    });
  });

  var total =
    convertedAmounts.reduce((acc, curr) => acc + curr.value, 0) + priceBuffer;

  if (rounding === "round-up") {
    total = Math.ceil(total);
  } else if (rounding === "round-down") {
    total = Math.floor(total);
  }

  const currency = convertedAmounts?.[0]?.currency;
  return (
    !!currency && (
      <span>
        {!total
          ? 0
          : total.toLocaleString("en-US", { style: "currency", currency })}
      </span>
    )
  );
};
TotalsAmountConvertor.defaultProps = {
  amounts: [],
  priceBuffer: 0,
  rounding: "no-rounding",
};
TotalsAmountConvertor.propTypes = {
  amounts: PropTypes.array.isRequired,
  rounding: PropTypes.oneOf(["no-rounding", "round-up", "round-down"]),
  priceBuffer: PropTypes.number.isRequired,
};

const PriceView = ({ amount, currency, rounding = "no-rounding" }) => {
  const { convertedAmount, convertedCurrency } = useSelector((state) => {
    const { convertedAmount, currency: convertedCurrency } =
      convertAmountSelector(state, { amount, currentCurrency: currency });
    return { convertedAmount, convertedCurrency };
  });

  var finalAmount = convertedAmount;
  if (rounding === "round-up") {
    finalAmount = Math.ceil(convertedAmount);
  } else if (rounding === "round-down") {
    finalAmount = Math.floor(convertedAmount);
  }

  return (
    <span>
      {finalAmount.toLocaleString("en-US", {
        style: "currency",
        currency: convertedCurrency,
        maximumFractionDigits: 2,
      })}
    </span>
  );
};
PriceView.defaultProps = { rounding: "no-rounding" };
PriceView.propTypes = {
  amount: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  rounding: PropTypes.oneOf(["no-rounding", "round-up", "round-down"]),
};

const ModeField = () => {
  const { setValues } = useFormikContext();
  const [{ value }, { touched }, ____] = useField("mode");

  useEffect(() => {
    if (value !== "prepaid") return;
    if (!touched) return;

    setValues({
      mode: value,
      buffer: 0,
      rounding: "round-up",
      totalPriceRounding: "round-up",
      priceBuffer: 0,
    });
  }, [value]);

  return (
    <NormalSelectField
      label="Mode"
      name="mode"
      options={[
        ["with_deposit", "With Deposit"],
        ["prepaid", "Prepaid"],
      ]}
    />
  );
};

export const CancellationInfoModal = ({ onCancel }) => {
  // Get basic cancellation information.
  const { mode, buffer, priceBuffer, rounding, totalPriceRounding } =
    useSelector((state) => {
      const { mode, buffer, priceBuffer, rounding, totalPriceRounding } =
        state.tripPlannerCxlReducer;

      return {
        mode,
        buffer,
        priceBuffer,
        rounding,
        totalPriceRounding,
      };
    });

  const dispatch = useDispatch();
  const onSubmitData = useCallback(
    async ({ data }) => {
      dispatch(setCxlPolicyData({ data }));
      onCancel();
    },
    [dispatch]
  );

  var cxlPolicies = [];
  var nonRefDeposit = [];

  // Calculate cancellation policies and get trip destinations.
  const { destinations } = useSelector((state) => {
    const destinations = state.tripPlannerDestinations;
    const srvs = getServicesListSelector(state);
    srvs.forEach((srv) => {
      var srvType = srv.service_type;
      var service = srv;

      if (srvType === "TR") {
        if (Object.hasOwn(srv, "service")) service = srv.service;
      }

      const key = srvPriceKeyCreator({ service, service_type: srvType });
      const { markup } = getServicePricingSelector(state, { key });

      var nonRefValue = 0;
      var nonRefCurrency = null;
      switch (srv?.service_type) {
        case "ACC": {
          if (srv?.accommodation_service_type === "ACC") {
            var selRoom = (srv?.detailed_rooms ?? []).find((r) => r.selected);

            if (!selRoom) selRoom = srv?.rooms?.[0];
            if (!selRoom) break;

            const roomCxlPolicies = selRoom?.cancellation_policies ?? [];

            const price = selRoom?.price?.value ?? 0;
            const markedUpPrice = markUpper(price, markup, 2);

            if (
              !roomCxlPolicies.length ||
              roomCxlPolicies.every((c) => c.type === "chargeable") ||
              (roomCxlPolicies.length === 1 &&
                roomCxlPolicies.every((c) => c.type !== "refundable"))
            ) {
              nonRefValue = markedUpPrice;
              nonRefCurrency = selRoom.price.currency;
              break;
            } else {
              const cxl = _.last(
                _.cloneDeep(roomCxlPolicies).filter(
                  (c) => c.type === "chargeable"
                )
              );
              cxlPolicies = [
                ...cxlPolicies,
                ...[
                  {
                    ...cxl,
                    fee: markedUpPrice,
                    currency: selRoom.price.currency,
                  },
                ],
              ];
            }
          } else if (srv?.accommodation_service_type === "ADD") {
            const markedUpPrice = markUpper(srv?.total_price ?? 0, markup, 2);
            nonRefValue = markedUpPrice;
            nonRefCurrency = srv.currency;
          }
          break;
        }
        case "TRF": {
          cxlPolicies = [
            ...cxlPolicies,
            ..._.cloneDeep(srv?.cancellation_policies ?? []).map((c) => {
              c.fee = markUpper(c.fee, markup, 2);
              return c;
            }),
          ];
          if (!(srv?.cancellation_policies ?? []).length) {
            nonRefValue = markUpper(srv?.price?.value ?? 0, markup, 2);
            nonRefCurrency = srv.price.currency;
            break;
          }
          break;
        }
        case "GEN": {
          const price = srv?.service_data?.price_data?.total_price ?? 0;
          nonRefValue = markUpper(price, markup, 2);
          nonRefCurrency = srv.service_data.price_data.currency;
          break;
        }
        case "REST": {
          const price = srv?.menu?.[0]?.total_price?.value ?? 0;
          nonRefValue = markUpper(price, markup, 2);
          nonRefCurrency = srv?.menu?.[0]?.total_price?.currency;
          break;
        }
        case "COO": {
          const dCost = srv?.daily_cost ?? [];
          const price = dCost.reduce((a, b) => a + b?.amount ?? 0, 0);
          nonRefValue = markUpper(price, markup, 2);
          nonRefCurrency = srv.currency;
          break;
        }
        case "AD": {
          cxlPolicies = [
            ...cxlPolicies,
            ..._.cloneDeep(srv?.cancellation_policies ?? []).map((c) => {
              c.fee = markUpper(c.fee, markup, 2);
              return c;
            }),
          ];
          if (!(srv?.cancellation_policies ?? []).length) {
            nonRefValue = markUpper(srv?.amount ?? 0, markup, 2);
            nonRefCurrency = srv.currency;
            break;
          }
          break;
        }
        case "TR": {
          if (srv.transportation_service_type === "FL") {
            nonRefValue = markUpper(srv.price?.total_price ?? 0, markup, 2);
            nonRefCurrency = srv.price.currency;
          } else if (srv.transportation_service_type === "AFER") {
            nonRefValue = markUpper(srv.price?.value ?? 0, markup, 2);
            nonRefCurrency = srv.price.currency;
          } else if (srv.transportation_service_type === "ATRA") {
            const selectedOffer = (srv.offers ?? []).find((o) => o.selected);
            const price = parseFloat(selectedOffer?.amount?.value ?? 0);

            nonRefValue = markUpper(price, markup, 2);
            nonRefCurrency = selectedOffer?.amount?.currency;
          } else if (["FER", "COA", "TRA"].includes(srv?.addhoc_service_type)) {
            nonRefValue = markUpper(srv?.service?.total_price ?? 0, markup, 2);
            nonRefCurrency = srv.service.currency;
          }
          break;
        }
        case "ACT": {
          const { totalPrice, currency } = getSelectedActivityAttrs({
            selectionInfo: srv.selectionInfo,
            bookingData: srv.bookingData,
          });
          const markedUpTotalPrice = markUpper(totalPrice, markup, 2);
          if (!srv.free_cancellation) {
            nonRefValue = markedUpTotalPrice;
            nonRefCurrency = currency;
          } else {
            const activityCxlPolicies = [
              cxlPolicyShape.cast({
                from: DateTime.now().toISO({ includeOffset: false }),
                to: DateTime.fromISO(srv.date, { setZone: true })
                  .minus({ days: 1 })
                  .toISO({ includeOffset: false }),
                type: "refundable",
                fee: 0,
                currency,
              }),
              cxlPolicyShape.cast({
                from: DateTime.fromISO(srv.date, { setZone: true })
                  .minus({ days: 1 })
                  .plus({ minutes: 1 })
                  .toISO({ includeOffset: false }),
                to: DateTime.fromISO(srv.date).toISO(
                  { includeOffset: false },
                  { setZone: true }
                ),
                type: "chargeable",
                fee: markedUpTotalPrice,
                currency,
              }),
            ];
            cxlPolicies = [...cxlPolicies, ...activityCxlPolicies];
          }
        }
        default:
          break;
      }

      if (!isNaN(nonRefValue) && !!nonRefCurrency)
        nonRefDeposit.push({ value: nonRefValue, currency: nonRefCurrency });
    });
    return { srvs, destinations };
  });

  // Create the fees list [{value, currency}] and then convert everything to
  // EUR so that we have a common currency among all services.
  const totalCxlFees = useSelector((state) =>
    cxlPolicies
      .map((cxl) => ({ value: cxl.fee, currency: cxl.currency }))
      .map((cxl) => {
        var convertedFee = {
          convertedAmount: cxl.value,
          currency: cxl.currency,
        };
        if (cxl.currency !== "EUR") {
          convertedFee = convertAmountToCurrencySelector(state, {
            amount: cxl.value,
            currentCurrency: cxl.currency,
            targetCurrency: "EUR",
          });
        }

        return {
          ...cxl,
          value: convertedFee.convertedAmount,
          currency: convertedFee.currency,
        };
      })
  );

  const totalCxlFee = totalCxlFees.reduce(
    (acc, curr) => acc + curr?.value ?? 0,
    0
  );

  nonRefDeposit = useSelector((state) => {
    return (nonRefDeposit ?? []).map((cxl) => {
      var convertedFee = { convertedAmount: cxl.value, currency: cxl.currency };
      if (cxl.currency !== "EUR") {
        const tCurrency = getCurrencySelector(state, {
          currentCurrency: "EUR",
        });
        convertedFee = convertAmountToCurrencySelector(state, {
          amount: cxl.value,
          currentCurrency: cxl.currency,
          targetCurrency: tCurrency,
        });
      }
      return {
        ...cxl,
        value: convertedFee.convertedAmount,
        currency: convertedFee.currency,
      };
    });
  });
  // Since all prices are converted to EUR for uniformity the pkgPrice is EUR
  const pkgCurrency = "EUR";

  // Add an initial refundable frame if all policies are chargeable.
  if (
    cxlPolicies.length > 0 &&
    cxlPolicies.every((c) => c.type !== "refundable")
  ) {
    const from = DateTime.now().toISO({ includeOffset: false });
    const to = DateTime.fromISO(cxlPolicies?.[0]?.["from"], { setZone: true })
      .minus({ minutes: 1 })
      .toISO({ includeOffset: false });

    const currency = cxlPolicies?.[0]?.currency;
    cxlPolicies = [
      cxlPolicyShape.cast({ from, to, type: "refundable", fee: 0, currency }),
      ...cxlPolicies,
    ];
  }

  return (
    <div className="Modal CancellationInfoModal">
      <Formik
        initialValues={{
          mode,
          buffer,
          rounding,
          totalPriceRounding,
          priceBuffer,
        }}>
        {({ values: v, resetForm }) => {
          const pBuffer = v?.priceBuffer ?? 0;
          var deposit =
            nonRefDeposit.reduce((a, b) => a + b.value, 0) + pBuffer;

          var depositRoundingDiff = 0;
          if (v.rounding === "round-up") {
            depositRoundingDiff = deposit - Math.ceil(deposit);
            deposit = Math.ceil(deposit);
          } else if (v.rounding === "round-down") {
            depositRoundingDiff = deposit - Math.floor(deposit);
            deposit = Math.floor(deposit);
          }

          var balance = totalCxlFee + (-1 * pBuffer + depositRoundingDiff);

          const total = deposit + balance;
          var balanceBuffer = 0;
          if (v.totalPriceRounding === "round-up") {
            balanceBuffer = bankersRounder(Math.ceil(total) - total);
          } else if (v.totalPriceRounding === "round-down") {
            balanceBuffer = bankersRounder(Math.floor(total) - total);
          }
          balance = bankersRounder(balance + balanceBuffer);

          const totalPrice = bankersRounder(deposit + balance, 2);

          var isNonRefundable =
            (nonRefDeposit.length > 0 && v.mode === "prepaid") ||
            (nonRefDeposit.length > 0 &&
              totalCxlFee === 0 &&
              v.mode === "with_deposit");

          const toughestCxlPolicy = getToughestPolicy({
            totalFee: totalCxlFee,
            cxlPolicies,
            buffer: v.buffer,
            tripStartDate: destinations?.[0]?.checkIn,
          }).map((c) => {
            if ((c?.fee ?? 0) === 0) return { ...c, fee: 0 };
            const b = -1 * pBuffer + depositRoundingDiff + balanceBuffer;
            return { ...c, fee: bankersRounder(c.fee + b) };
          });

          const dueDate = toughestCxlPolicy?.[1]?.from;
          var dueDateDisplay = null;
          if (!!dueDate) {
            dueDateDisplay = DateTime.fromISO(dueDate, {
              setZone: true,
            }).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY);
          }

          return (
            <div className="Modal__card">
              <div className="Modal__card__header">
                <h5>Cancellation Policies & Payment Information</h5>
              </div>
              <div className="Modal__card__body CancellationInfoModal__body">
                <Form className="CancellationInfoModal__body__buffer_form">
                  <ModeField />
                  <NormalInputField
                    name="buffer"
                    label="Days Buffer"
                    type="number"
                    extraInputProps={{ min: 0, disabled: v.mode === "prepaid" }}
                  />
                  <div className="CancellationInfoModal__body__buffer_form__col3">
                    <NormalSelectField
                      name="rounding"
                      label="Deposit Rounding"
                      options={[
                        ["no-rounding", "No Rounding"],
                        ["round-up", "Round Up"],
                        ["round-down", "Round Down"],
                      ]}
                      disabled={v.mode === "prepaid"}
                    />
                    <NormalInputField
                      name="priceBuffer"
                      label="Deposit Price Buffer"
                      type="number"
                      extraInputProps={{
                        min: 0,
                        disabled: v.mode === "prepaid",
                      }}
                    />
                    <NormalSelectField
                      name="totalPriceRounding"
                      label="Total Price Rounding"
                      options={[
                        ["no-rounding", "No Rounding"],
                        ["round-up", "Round Up"],
                        ["round-down", "Round Down"],
                      ]}
                    />
                  </div>
                </Form>
                <div className="CancellationInfoModal__cxls">
                  <div className="CancellationInfoModal__tripcxlstatus">
                    <p>
                      <strong>Trip Cancellation Policy: </strong>{" "}
                      <span
                        data-refundable={isNonRefundable ? "false" : "true"}>
                        {isNonRefundable ? "Non Refundable" : "Refundable"}
                      </span>
                    </p>
                  </div>
                  {v.mode === "with_deposit" && (
                    <React.Fragment>
                      <div className="CancellationInfoModal__tripcxlstatus">
                        <strong>Non Refundable Deposit</strong>
                        <TotalsAmountConvertor
                          /* We only apply rounding on the non refundable */
                          /* part as well as the price buffer */
                          amounts={nonRefDeposit}
                          rounding={v.rounding}
                          priceBuffer={pBuffer}
                        />
                      </div>
                      <div className="CancellationInfoModal__tripcxlstatus">
                        <strong>Balance Amount</strong>
                        {dueDateDisplay}
                        <PriceView amount={balance} currency={pkgCurrency} />
                      </div>
                    </React.Fragment>
                  )}
                  <div className="CancellationInfoModal__tripcxlstatus">
                    <strong>Total</strong>
                    <PriceView
                      amount={totalPrice}
                      currency={pkgCurrency}
                      rounding={v.totalPriceRounding}
                    />
                  </div>
                </div>
                {!isNonRefundable && toughestCxlPolicy.length > 0 && (
                  <div className="CancellationInfoModal__tripcxlstatus">
                    <strong>Cancellation Policy Terms</strong>
                    <CancellationPolicyDetails
                      cxl_policies={toughestCxlPolicy}
                    />
                  </div>
                )}
              </div>
              <div className="Modal__card__actions">
                <button className="Button" data-ghost="true" onClick={onCancel}>
                  Close
                </button>
                <button className="Button" onClick={() => resetForm()}>
                  Reset
                </button>
                <button
                  className="Button"
                  onClick={async () => {
                    // We always store the data in EUR so that all prices are
                    // uniform
                    await onSubmitData({
                      data: {
                        ...v,
                        cancellation_policies: toughestCxlPolicy,
                        deposit: bankersRounder(deposit),
                        balance: bankersRounder(balance),
                        currency: pkgCurrency,
                      },
                    });
                  }}>
                  Submit
                </button>
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};
CancellationInfoModal.propTypes = { onCancel: PropTypes.func.isRequired };
