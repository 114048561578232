import { getFlightRules } from "@src/actions/Project/TripPlanner";
import LeanAuthHoc from "@src/components/authorization/LeanAuthHoc";
import { variables } from "@src/jsssetup";
import SuIcon from "@src/style/icon/components/SuIcon";
import { humanizeDuration } from "@src/tools/date_tools";
import { naString } from "@src/tools/string_tools";
import _ from "lodash";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { createUseStyles } from "react-jss";
import { useDispatch } from "react-redux";
import { Button, Icon, IconButton } from "rsuite";

const segAttrLabelStyles = {
  "display": "inline-grid",
  "gridTemplateColumns": "2rem 1fr",
  "gridGap": variables.half_gap,
  "alignItems": "center",
  "& .sisi-family, .rs-icon": {
    fontSize: "15px",
    color: `${variables.colors.easy.orange}!important`,
  },
};

const channelInfoStyles = createUseStyles({ segAttrLabel: segAttrLabelStyles });
var ChannelInfo = ({ crs_info }) => {
  const classes = channelInfoStyles();
  return (
    <React.Fragment>
      <span className={classes.segAttrLabel}>
        <Icon icon="cog" />
        <strong>Channel</strong>
      </span>
      <span>{naString(crs_info.terminal_pcc)}</span>
    </React.Fragment>
  );
};
ChannelInfo.propTypes = {
  crs_info: PropTypes.object.isRequired,
};
ChannelInfo = LeanAuthHoc(ChannelInfo, { allowed_groups: ["member"] });

const flightSegmentTitleStyles = createUseStyles({
  FlightSegmentTitle: { display: "grid" },
  logo: {
    height: "4rem",
  },
});
const FlightSegmentTitle = ({ segment }) => {
  const classes = flightSegmentTitleStyles();
  var title = `${segment.origin_airport.iata} - ${segment.destination_airport.iata}`;
  if (typeof segment.transfer_time > 0) {
    title += `, Layover: ${humanizeDuration(segment.transfer_time, {
      abbrev: true,
    })}`;
  }

  const logo = _.get(segment, "operating_airline.logo", "")
    .replace("suixingtrip.com", "sisitrip.com")
    .split(":8080")
    .join("");

  return (
    <div className={classes.FlightSegmentTitle}>
      <img alt="" src={logo} className={classes.logo} />
      <span>
        <strong>{title}</strong>
      </span>
    </div>
  );
};
FlightSegmentTitle.propTypes = {
  segment: PropTypes.object.isRequired,
};

const flightLegStyles = createUseStyles({
  FlightLeg: {
    display: "grid",
    gridGap: variables.half_gap,
  },
  header: {
    display: "inline-grid",
    gridTemplateColumns: "1fr max-content max-content",
    gridGap: variables.normal_gap,
    width: "100%",
  },
  segAttrLabel: segAttrLabelStyles,
});
const FlightLeg = ({
  leg,
  legType,
  inbound_option,
  flOrder,
  flight_uid,
  outbound_option,
  crs_info,
  ticketing_deadline,
  fare_type,
  onHideDetails,
}) => {
  const classes = flightLegStyles();
  if (!_.get(leg, "segments", []).length) {
    return null;
  }

  const dispatch = useDispatch();
  const onGetFlightRules = useCallback(
    ({ flOrder, flight_uid, outbound_option_uid, inbound_option_uid }) => {
      dispatch(
        getFlightRules(
          flOrder,
          flight_uid,
          outbound_option_uid,
          inbound_option_uid,
          "allflights"
        )
      );
    },
    [dispatch]
  );

  return (
    <div className={classes.FlightLeg}>
      <h5 className={classes.header}>
        {`${_.startCase(legType)}: ${leg.segments[0].origin_airport.iata} - ${
          _.last(leg.segments).destination_airport.iata
        } ${DateTime.fromISO(leg.departure, { setZone: true }).toLocaleString(
          DateTime.DATETIME_MED_WITH_WEEKDAY
        )}`}
        {/* crs_info.gds !== "+W" && ( */
        /*   <IconButton */
        /*     size="xs" */
        /*     appearance="primary" */
        /*     icon={<Icon icon="id-info" />} */
        /*     onClick={() => */
        /*       onGetFlightRules({ */
        /*         flOrder, */
        /*         flight_uid, */
        /*         outbound_option_uid: outbound_option.uid, */
        /*         inbound_option_uid: _.get(inbound_option, "uid"), */
        /*       }) */
        /*     }> */
        /*     Flight Rules */
        /*   </IconButton> */
        /* ) */}
        {typeof onHideDetails === "function" && (
          <Button size="xs" appearance="primary" onClick={onHideDetails}>
            Close
          </Button>
        )}
      </h5>
      {leg.segments.map((seg, idx) => (
        <React.Fragment key={idx}>
          <FlightSegmentTitle segment={seg} />
          <div className="FlightDetails__content__leg__description">
            <span className={classes.segAttrLabel}>
              <SuIcon icon="icon-Flying" />
              <strong>
                {DateTime.fromISO(seg.departure, {
                  setZone: true,
                }).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)}
              </strong>
            </span>
            <span>{`${seg.origin_airport.name}`}</span>
            <span className={classes.segAttrLabel}>
              <SuIcon icon="icon-Landing" />
              <strong>
                {DateTime.fromISO(seg.arrival, {
                  setZone: true,
                }).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)}
              </strong>
            </span>
            <span>{`${seg.destination_airport.name}`}</span>
            <span className={classes.segAttrLabel}>
              <SuIcon icon="icon-TripPlan" />
              <strong>Airline</strong>
            </span>
            <span>{seg.operating_airline.name}</span>
            <span className={classes.segAttrLabel}>
              <SuIcon icon="icon-FlightNumber" />
              <strong>Flight Number</strong>
            </span>
            <span>{seg.flight_number}</span>
            <span className={classes.segAttrLabel}>
              <SuIcon icon="icon-FlightNumber" />
              <strong>Fare Base</strong>
            </span>
            <span>{seg.fare_base}</span>
            <span className={classes.segAttrLabel}>
              <SuIcon icon="icon-FlightNumber" />
              <strong>Fare Type</strong>
            </span>
            <span>{fare_type || "N/A"}</span>
            <span className={classes.segAttrLabel}>
              <SuIcon icon="icon-FlightNumber" />
              <strong>Fare Brand</strong>
            </span>
            <span>{`${naString(seg.brand_name)} (${naString(
              seg.brand_code
            )})`}</span>
            <span className={classes.segAttrLabel}>
              <SuIcon icon="icon-FlightClass" />
              <strong>Flight Class</strong>
            </span>
            <span>{seg.cabin_class}</span>
            <span className={classes.segAttrLabel}>
              <SuIcon icon="icon-Flight" />
              <strong>Aircraft</strong>
            </span>
            <span>{naString(seg.aircraft.name)}</span>
            <span className={classes.segAttrLabel}>
              <SuIcon icon="icon-Baggage" />
              <strong>Baggage Allowance</strong>
            </span>
            <span>{naString(seg.baggage_allowance.verbose)}</span>
            <span className={classes.segAttrLabel}>
              <SuIcon icon="icon-warn" />
              <strong>Ticket Deadling</strong>
            </span>
            <span>
              {ticketing_deadline
                ? DateTime.fromISO(ticketing_deadline).toLocaleString(
                    DateTime.DATETIME_MED_WITH_WEEKDAY
                  )
                : "N/A"}
            </span>
            <ChannelInfo crs_info={crs_info} />
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};
FlightLeg.propTypes = {
  outbound_option: PropTypes.object,
  leg: PropTypes.object.isRequired,
  legType: PropTypes.oneOf(["inbound", "outbound"]).isRequired,
  inbound_option: PropTypes.object.isRequired,
  flOrder: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  flight_uid: PropTypes.string.isRequired,
  crs_info: PropTypes.object.isRequired,
  ticketing_deadline: PropTypes.string,
  fare_type: PropTypes.string,
  onHideDetails: PropTypes.func.isRequired,
};

const FlightDetails = ({
  flight_uid,
  flOrder,
  outbound_option,
  inbound_option,
  crs_info,
  hideDetails,
  ticketing_deadline,
  fare_type,
}) => {
  const commonFlightLegProps = {
    outbound_option,
    inbound_option,
    flOrder,
    crs_info,
    flight_uid,
    ticketing_deadline,
    fare_type,
  };

  return (
    <div className="FlightDetails">
      <div className="FlightDetails__content">
        <div
          className="FlightDetails__content__connectorCntr"
          data-has-inbound={_.isEmpty(inbound_option) ? "false" : "true"}>
          <div className="FlightDetails__content__connectorCntr__number">1</div>
          {/* {!_.isEmpty(inbound_option) && (
            <div className="FlightDetails__content__connectorCntr__line"></div>
          )} */}
        </div>
        <FlightLeg
          leg={outbound_option}
          legType="outbound"
          onHideDetails={hideDetails}
          {...commonFlightLegProps}
        />
        {!_.isEmpty(inbound_option) && (
          <React.Fragment>
            <div className="FlightDetails__content__connectorCntr__number">
              2
            </div>
            <FlightLeg
              leg={inbound_option}
              legType="inbound"
              {...commonFlightLegProps}
            />
          </React.Fragment>
        )}
      </div>
      {/* <div className="FlightDetails__controls">
        <Button size="xs" appearance="primary" onClick={hideDetails}>
          Close
        </Button>
      </div> */}
    </div>
  );
};
FlightDetails.defaultProps = {
  outbound_option: {},
  inbound_option: {},
};
FlightDetails.propTypes = {
  flight_uid: PropTypes.string.isRequired,
  flOrder: PropTypes.string.isRequired,
  outbound_option: PropTypes.object.isRequired,
  inbound_option: PropTypes.object,
  crs_info: PropTypes.object.isRequired,
  ticketing_deadline: PropTypes.string,
  fare_type: PropTypes.string,
  hideDetails: PropTypes.func,
};

export default FlightDetails;
