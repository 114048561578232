import _ from "lodash";
import moment from "moment";

export const validateAddOnStepOne = (data) => {
  const {
    customServiceAddOn,
    customServiceAddOnDestination,
    customServiceAddOnItems,
  } = data;

  const msgs = [];
  if (!customServiceAddOn.title) {
    msgs.push("Title not set.");
  }

  if (!customServiceAddOn.categories.length) {
    msgs.push("At least one category must be selected.");
  }

  if (_.isEmpty(customServiceAddOnDestination.destination)) {
    msgs.push("A destination must be set.");
  }

  if (!customServiceAddOn.start_time) {
    msgs.push("Start time must be set.");
  }

  if (!customServiceAddOn.indicative_pickup_time) {
    msgs.push("Indicative Pickup Time must be set.");
  }

  if (!customServiceAddOnItems.length) {
    msgs.push("At least one Item must be set.");
  } else if (customServiceAddOnItems.some((addon) => !addon.arrival_time)) {
    msgs.push("All Items must have the Arrival Time set.");
  }

  return msgs;
};

export const validateAddOnStepTwo = (data) => {
  const { customServiceAddOn, customMeetingPointGroups } = data;

  const msgs = [];

  if (!customServiceAddOn.distribution_periods.length) {
    msgs.push("At least one distribution period must be set.");
  } else if (
    customServiceAddOn.distribution_periods.some((period) => !period.name)
  ) {
    msgs.push("All periods must have a name.");
  } else if (
    customServiceAddOn.distribution_periods.some((period) => {
      const keys = [
        "distribution_from",
        "distribution_to",
        "operation_from",
        "operation_to",
      ];

      return keys.some((key) => !period[key]);
    })
  ) {
    msgs.push("All periods must have Distribution and Operation dates set.");
  } else {
    const distPeriodCouples = customServiceAddOn.distribution_periods.map(
      (period) => [period.distribution_from, period.distribution_to]
    );

    const opPeriodCouples = customServiceAddOn.distribution_periods.map(
      (period) => [period.operation_from, period.operation_to]
    );

    var overlapsIdxs = distPeriodCouples
      .map((couple, idx) => {
        if (
          distPeriodCouples
            .filter((ccouple, jidx) => idx !== jidx)
            .some((ccouple) => {
              const c0 = moment(couple[0]);
              const c1 = moment(couple[0]);
              const cc0 = moment(ccouple[0]);
              const cc1 = moment(ccouple[1]);
              return (
                (c0.isSameOrAfter(cc0) && c0.isSameOrBefore(cc1)) ||
                (c1.isSameOrAfter(cc0) && c1.isSameOrBefore(cc1))
              );
            })
        ) {
          return idx;
        }
        return null;
      })
      .filter((idx) => idx !== null);

    const overlapsExist = overlapsIdxs.some((idx) => {
      return distPeriodCouples
        .map((period, idx) => idx)
        .filter((jidx) => jidx !== idx)
        .some((jidx) => {
          const couple = opPeriodCouples[idx];
          const ccouple = opPeriodCouples[jidx];

          const c0 = moment(couple[0]);
          const c1 = moment(couple[0]);
          const cc0 = moment(ccouple[0]);
          const cc1 = moment(ccouple[1]);

          return (
            (c0.isSameOrAfter(cc0) && c0.isSameOrBefore(cc1)) ||
            (c1.isSameOrAfter(cc0) && c1.isSameOrBefore(cc1))
          );
        });
    });

    if (overlapsExist) {
      msgs.push("Operation Periods conflict.");
    }
  }

  if (!customMeetingPointGroups.length) {
    msgs.push("At least one Pick-Up Group must be created.");
  } else {
    if (
      customMeetingPointGroups.some(
        (group) =>
          !_.flatten(Object.values(group.points)).filter(
            (point) => point.selected
          ).length
      )
    ) {
      msgs.push("All Pick-Up Groups must have at least one point selected.");
    }
  }

  return msgs;
};

export const validateAddOnStepThree = (data) => {
  const msgs = [];
  const { customServiceAddOn } = data;
  return msgs;
};

export const validateAllSteps = (data) => {
  var msgs = [];
  msgs = [
    ...msgs,
    ...validateAddOnStepOne(data),
    ...validateAddOnStepTwo(data),
    ...validateAddOnStepThree(data),
  ];

  return msgs;
};
