export const initialToursFilterForm = {
  name: {
    value: "",
    verbose: "Name",
    type: "text",
  },
  social_media: {
    value: "",
    verbose: "Social Media",
    type: "text",
  },
  status: {
    value: "",
    verbose: "Status",
    type: "text",
  },
  address: {
    value: "",
    verbose: "Address",
    type: "text",
  },
  id: {
    value: "",
    verbose: "ID",
    type: "text",
  },
  business_name: {
    value: "",
    verbose: "Business Name",
    type: "text",
  },
  post_code: {
    value: "",
    verbose: "Post Code",
    type: "text",
  },
  region: {
    value: "",
    verbose: "Region",
    type: "text",
  },
  country: {
    value: "",
    verbose: "Country",
    type: "text",
  },
  brand_name: {
    value: "",
    verbose: "Brand Name",
    type: "text",
  },
  city: {
    value: "",
    verbose: "City",
    type: "text",
  },
  we_chat: {
    value: "",
    verbose: "WeChat",
    type: "text",
  },
  face_book: {
    value: "",
    verbose: "Facebook",
    type: "text",
  },
  person_name: {
    value: "",
    verbose: "Person Name",
    type: "text",
  },
  phone: {
    value: "",
    verbose: "Phone Number",
    type: "text",
  },
  legal_title: {
    value: "",
    verbose: "Legal Title",
    type: "text",
  },
  registration_code: {
    value: "",
    verbose: "Registration Code",
    type: "text",
  },
  license_number: {
    value: "",
    verbose: "License Number",
    type: "text",
  },
  tax_office: {
    value: "",
    verbose: "Tax Office",
    type: "text",
  },
  email: {
    value: "",
    verbose: "E-mail",
    type: "text",
  },
  entity_type: {
    value: [],
    verbose: "Entity Type",
  },
  expertise_type: {
    value: [],
    verbose: "Expertise Type",
  },
  license_type: {
    value: [],
    verbose: "License Type",
  },
  creator: {
    value: "",
    verbose: "Created By",
    type: "text",
  },
  last_editor: {
    value: "",
    verbose: "Last Editor",
    type: "text",
  },
  created: {
    value: [],
    placement: "bottomRight",
    verbose: "Created Date",
  },
  edited: {
    value: [],
    placement: "bottomRight",
    verbose: "Last Edited Date",
  },
  url: {
    value: "",
    verbose: "URL",
    type: "text",
  },
  founding_year: {
    value: "",
    verbose: "Founding Year",
    type: "text",
  },
  travel_sector: {
    value: [],
    verbose: "travel_sector",
  },
  travel_services: {
    value: "",
    verbose: "Travel Services",
    type: "text",
  },
};

export const initialToursColumnsForm = {
  basic: {
    id: "basic",
    value: [
      "name",
      "legal_title",
      "registration_code",
      "license_number",
      "entity_type",
      "expertise_type",
      "license_type",
      "tax_office",
      "email",
      "city",
      "brand_name",
      "we_chat",
    ],
    verbose: "Basic",
    choices: [
      {
        display_name: "Name",
        value: "name",
        width: 100,
        dataKey: "name",
        fixed: false,
      },
      {
        display_name: "Legal Title",
        value: "legal_title",
        width: 100,
        dataKey: "legal_title",
        fixed: false,
      },
      {
        display_name: "Registration Code",
        value: "registration_code",
        width: 100,
        dataKey: "registration_code",
        fixed: false,
      },
      {
        display_name: "License Number",
        value: "license_number",
        width: 100,
        dataKey: "license_number",
        fixed: false,
      },
      {
        display_name: "Tax Office",
        value: "tax_office",
        width: 100,
        dataKey: "tax_office",
        fixed: false,
      },
      {
        display_name: "Email",
        value: "email",
        width: 100,
        dataKey: "email",
        fixed: false,
      },
      {
        display_name: "Entity Type",
        value: "entity_type",
        width: 100,
        dataKey: "entity_type",
        fixed: false,
      },
      {
        display_name: "Expertise Type",
        value: "expertise_type",
        width: 100,
        dataKey: "expertise_type",
        fixed: false,
      },
      {
        display_name: "License Type",
        value: "license_type",
        width: 100,
        dataKey: "license_type",
        fixed: false,
      },
    ],
  },
  activity: {
    id: "activity",
    value: ["creator", "last_editor", "created", "edited"],
    verbose: "Activity",
    choices: [
      {
        display_name: "Creator",
        value: "creator",
        width: 100,
        dataKey: "creator",
        fixed: false,
      },
      {
        display_name: "Last Editor",
        value: "last_editor",
        width: 100,
        dataKey: "last_editor",
        fixed: false,
      },
      {
        display_name: "Created",
        value: "created",
        width: 100,
        dataKey: "created",
        fixed: false,
      },
      {
        display_name: "Edited",
        value: "edited",
        width: 100,
        dataKey: "edited",
        fixed: false,
      },
    ],
  },
  other_features: {
    id: "other_features",
    value: ["url", "founding_year", "travel_services"],
    verbose: "Other Features",
    choices: [
      {
        display_name: "Url",
        value: "url",
        width: 100,
        dataKey: "url",
        fixed: false,
      },
      {
        display_name: "Founding Year",
        value: "founding_year",
        width: 100,
        dataKey: "founding_year",
        fixed: false,
      },
      {
        display_name: "Travel Services",
        value: "travel_services",
        width: 100,
        dataKey: "travel_services",
        fixed: false,
      },
    ],
  },
};
