import _ from "lodash";
import service from "@src/api/request";
import { SISI_API_URL } from "@src/api";
import axios from "axios";

export function getIndependentAgentsList(para) {
  return service({
    url: "/bid/independent-agent/",
    method: "get",
    params: para,
  });
}

export function fetchIndependentAgentsProfile(id) {
  return service({
    url: `/bid/independent-agent/${id}`,
    method: "get",
  });
}

export function getIndependentAgentsOptions(lang) {
  return service({
    url: "/bid/independent-agent/",
    method: "options",
    headers: {
      "Accept-Language": lang,
    },
  });
}

export function submitIndependentAgentsProfile(id, payload, mode) {
  return service({
    url: `/bid/independent-agent/${id}/`,
    method: mode,
    data: payload,
  });
}

export function addNewIndependentAgents(data) {
  return service({
    url: "/bid/independent-agent/",
    method: "post",
    data: data,
  });
}

export function fetchIndependentAgentsQuickInfoList(para) {
  return service({
    url: "/bid/independent-agent/get_quick_info/",
    method: "get",
    params: para,
  });
}

export async function independentAgentsAutocomplete(params) {
  try {
    const result = await axios.get(
      `${SISI_API_URL}/crm/independent-agents/autocomplete/`,
      { params }
    );
    return _.get(result, "data");
  } catch (error) {
    console.log("error");
    return null;
  }
}
