import { IconButton, Icon } from "rsuite";
import { ServicePanelGhost } from "../Components/ItineraryOverviewCommon";
import { humanizeDuration } from "@src/tools/date_tools";
import { prevStep } from "@src/actions/Project/TripPlanner";
import _ from "lodash";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import React, { useCallback, useContext } from "react";
import { TripModeHoc } from "../Hydrators";
import { createUseStyles } from "react-jss";
import { variables } from "@src/jsssetup";
import { ItinOverviewContext } from "./ItineraryOverview";
import { InlineIcon } from "@iconify/react";
import { handleNonValidImgFn } from "@src/tools/common_tools";
import { DateTime } from "luxon";

const transferDataStyles = createUseStyles({
  TransferData: (props) => ({
    display: "grid",
    gridTemplateColumns: props.services_collapsed ? "1fr" : "8rem 1fr",
    gridTemplateRows: props.services_collapsed ? "unset" : "10rem",
    gridGap: variables.normal_gap,
    maxHeight: "10rem",
  }),
  cardContent: {
    display: "grid",
    padding: variables.normal_gap,
    gridGap: variables.half_gap,
    boxShadow: variables.shadows.heavyShadow,
    borderRadius: `calc(${variables.normal_gap} / 2)`,
    textTransform: "capitalize",
  },
  header: (props) => ({
    display: "grid",
    gridTemplateColumns: props.services_collapsed
      ? "max-content 1fr max-content"
      : "1fr max-content",
    gridGap: variables.half_gap,
    alignItems: "center",
    padding: props.services_collapsed && [
      variables.half_gap,
      variables.half_gap,
      variables.half_gap,
      variables.double_gap,
    ],
    borderRadius: props.services_collapsed && variables.half_gap,
    boxShadow: props.services_collapsed && variables.shadows.normalShadow,
  }),
  img: {
    width: "100%",
    height: "100%",
    borderRadius: "5px",
    objectFit: "contain",
    boxShadow: variables.shadows.heavyShadow,
  },
  imgError: {
    width: "100%",
    height: "100%",
    borderRadius: "5px",
    objectFit: "cover",
    boxShadow: variables.shadows.heavyShadow,
  },
  metadata: {
    display: "grid",
    gridTemplateColumns: "1fr max-content 1fr",
    alignItems: "center",
    gridGap: variables.normal_gap,
  },
  headerTitle: {
    fontSize: "medium",
    fontWeight: "bold",
  },
  title: { color: variables.colors.text.colored, fontWeight: "600" },
  route: { gridColumn: "span 3" },
  refundCntr: {
    display: "grid",
    gridTemplateColumns: "max-content max-content",
    gridGap: variables.half_gap,
    alignItems: "center",
    justifySelf: "center",
  },
  iconCntr: {
    display: "grid",
    placeContent: "center",
    width: "2rem",
    height: "2rem",
    borderRadius: "50%",
    background: variables.colors.easy.orange,
    padding: variables.half_gap,
  },
  icon: { fontSize: "x-large", color: variables.colors.text.light },
  actionBtns: {
    display: "grid",
    gridAutoFlow: "column",
    gridGap: variables.half_gap,
    placeItems: "center",
  },
  expandIconCntr: {
    display: "grid",
    placeContent: "center",
    width: "1.5rem",
    height: "1.5rem",
    borderRadius: "50%",
    background: variables.colors.easy.orange,
    padding: variables.half_gap,
    cursor: "pointer",
  },
  expandIcon: { fontSize: "large", color: variables.colors.text.light },
  refundText: (props) => ({ color: props.isRefundable ? "green" : "red" }),
  refundIcon: (props) => ({
    fontSize: "large",
    color: props.isRefundable ? "green" : "red",
  }),
});
export const TransferData = ({
  trfData,
  trfType,
  transfer,
  children,
  services_collapsed,
  toggleSelfCollapse,
}) => {
  const isRefundable = trfData?.cancellation_policies.some(
    (c) => c.type === "refundable"
  );

  function getItin() {
    var keys = [trfData.origin.name, trfData.destination.name];

    if (["inbound", "outbound"].every((k) => !_.isEmpty(trfData[k]))) {
      if (trfType == "outbound") {
        keys = [trfData.destination.name, trfData.origin.name];
      }
    }

    return keys.join(" - ");
  }

  // Logic to accommodate for old transfer images where the image was just a
  // string.
  var imgSource = "";
  if ((transfer?.vehicle?.images ?? []).length) {
    imgSource = Object.keys(transfer?.vehicle?.images?.[0] ?? {}).includes(
      "url"
    )
      ? transfer.vehicle.images[0].url
      : transfer?.vehicle?.images[0];
  }

  const vehicleBrand = transfer?.vehicle?.brand;
  const vehicleType = transfer?.vehicle?.vehicle_type;
  const distance = transfer?.distance ?? "";
  const duration = transfer?.duration ?? 0;
  const provider = transfer?.provider?.name ?? "";

  const classes = transferDataStyles({ isRefundable, services_collapsed });

  const header = (
    <React.Fragment>
      <div className={classes.headerTitle}>
        Transfer:{" "}
        {trfType == "inbound"
          ? "Transportation Hub to Hotel"
          : "Hotel to Transportation Hub"}
      </div>
      <div className={classes.actionBtns}>
        {children}
        <div className={classes.expandIconCntr}>
          <InlineIcon
            className={classes.expandIcon}
            icon={
              services_collapsed ? "ri:arrow-down-s-line" : "ri:arrow-up-s-line"
            }
            onClick={toggleSelfCollapse}
          />
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <div className={classes.TransferData}>
      {services_collapsed ? (
        <div className={classes.header}>
          <div className={classes.iconCntr}>
            <InlineIcon
              className={classes.icon}
              icon="ic:baseline-directions-car"
            />
          </div>
          {header}
        </div>
      ) : (
        <React.Fragment>
          <img
            className={imgSource ? classes.img : classes.imgError}
            src={imgSource ?? ""}
            onError={(event) => handleNonValidImgFn(event, "TRF")}
          />
          <div className={classes.cardContent}>
            <div className={classes.header}>{header}</div>
            <div className={classes.metadata}>
              <span className={classes.route}>
                <span className={classes.title}>Route: </span>
                {getItin()}
              </span>
              <span>
                <span className={classes.title}>Pickup: </span>
                {transfer.start
                  ? DateTime.fromISO(transfer.start, {
                      setZone: true,
                    }).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)
                  : "N/A"}
              </span>
              <span>
                <span className={classes.title}>Distance: </span>
                {distance ? distance + "Km" : "N/A"}
              </span>
              {isRefundable ? (
                <span className={classes.refundCntr}>
                  <InlineIcon
                    className={classes.refundIcon}
                    icon="mdi:calendar-check"
                  />
                  <strong className={classes.refundText}>Refundable</strong>
                </span>
              ) : (
                <span className={classes.refundCntr}>
                  <InlineIcon
                    className={classes.refundIcon}
                    icon="mdi:calendar-check"
                  />
                  <strong className={classes.refundText}>Non Refundable</strong>
                </span>
              )}
              <span>
                <span className={classes.title}>Vehicle: </span>
                {`${
                  vehicleType
                    ? vehicleType
                    : vehicleBrand
                    ? vehicleBrand
                    : "N/A"
                }`}
              </span>
              <span>
                <span className={classes.title}>Duration: </span>
                {humanizeDuration(duration)}
              </span>
              <span className={classes.refundCntr}>
                <span className={classes.title}>Provider: </span>
                {provider}
              </span>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
TransferData.defaultProps = {
  isRefundable: false,
  children: {},
  services_collapsed: false,
};
TransferData.propTypes = {
  isRefundable: PropTypes.bool,
  transfer: PropTypes.object.isRequired,
  trfData: PropTypes.object.isRequired,
  trfType: PropTypes.string.isRequired,
  children: PropTypes.object,
  services_collapsed: PropTypes.bool.isRequired,
  toggleSelfCollapse: PropTypes.func,
};

const overviewTransferStyles = createUseStyles({
  actionBtnsCntr: {
    display: "grid",
    gridAutoFlow: "column",
    gridGap: variables.half_gap,
  },
});
const OverviewTransfer = ({
  isLoading,
  currentStep,
  destOrder,
  transfer,
  trfType,
  services_collapsed,
  toggleSelfCollapse,
}) => {
  const classes = overviewTransferStyles();
  const { onViewAllTrfs } = useContext(ItinOverviewContext);

  const dispatch = useDispatch();
  const onGoBack = useCallback(
    () => dispatch(prevStep(currentStep)),
    [dispatch]
  );

  const trf = transfer[trfType];

  const customIconBtnStyles = {
    background: variables.colors.easy.orange,
    color: variables.colors.text.light,
    fontWeight: "600",
  };

  return isLoading ? (
    <ServicePanelGhost
      title={`Searching... Please wait...`}
      service_type="TRF"
    />
  ) : _.isEmpty(trf) ? (
    <ServicePanelGhost
      failed={true}
      service_type="TRF"
      title={`Transfer Search for ${
        trfType == "inbound"
          ? "Transportation Hub to Hotel"
          : "Hotel to Transportation Hub"
      } failed to find results.`}
      failHolder={
        <span>
          You can try changing your <strong>flight</strong> or{" "}
          <strong>accommodation, </strong>
          or go back to the previous step and change your{" "}
          <strong>search criteria</strong>.
        </span>
      }
      onGoBack={onGoBack}
    />
  ) : (
    <TransferData
      transfer={trf}
      trfData={transfer}
      trfType={trfType}
      services_collapsed={services_collapsed}
      toggleSelfCollapse={toggleSelfCollapse}>
      <span className={classes.actionBtnsCntr}>
        {currentStep === 4 ? null : (
          <IconButton
            style={customIconBtnStyles}
            size="xs"
            icon={<Icon icon="info" style={customIconBtnStyles} />}
            onClick={() => onViewAllTrfs({ destOrder, trfType })}>
            View All
          </IconButton>
        )}
      </span>
    </TransferData>
  );
};
OverviewTransfer.defaultProps = { transfer: {}, services_collapsed: false };
OverviewTransfer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  transfer: PropTypes.object,
  currentStep: PropTypes.number.isRequired,
  trfType: PropTypes.oneOf(["inbound", "outbound"]),
  destOrder: PropTypes.number.isRequired,
  failed: PropTypes.bool,
  services_collapsed: PropTypes.bool.isRequired,
  toggleSelfCollapse: PropTypes.func,
};

export default TripModeHoc(OverviewTransfer);
