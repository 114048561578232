import { variables } from "@src/jsssetup";
import React from "react";
import { Icon } from "@iconify/react";
import { WhisperWrapper } from "../common/ui_helpers";

export const InfoButton = () => {
  const message = "Customer Support";
  const url = "https://sisitrip.atlassian.net/servicedesk/customer/portal/1";
  return (
    <WhisperWrapper msg={message} placement="right">
      <button
        className="MainSideBar__bottom-btns__info-button"
        onClick={() => window.open(url, "_blank")}>
        <Icon icon={variables.icons.questionMark} />
      </button>
    </WhisperWrapper>
  );
};
