import {
  Panel,
  Table,
  InputNumber,
  Whisper,
  Popover,
  Dropdown,
  IconButton,
  Icon,
  SelectPicker,
  Tag,
} from "rsuite";

import PropTypes from "prop-types";
import React from "react";
import { naString } from "@src/tools/string_tools";
import { formatDate } from "@src/tools/date_tools";
import { createUseStyles } from "react-jss";
import { tableHeadStyles } from "./Highlights";
import { variables } from "@src/jsssetup";

class RowAction extends React.Component {
  constructor(props) {
    super(props);
    this.triggerRef = React.createRef();
    this.handleSelectMenu = this.handleSelectMenu.bind(this);
  }
  handleSelectMenu(eventKey) {
    const { idx, onCopy, onReset } = this.props;

    switch (eventKey) {
      case 1:
        onCopy(idx);
        break;
      case 2:
        onReset(idx);
        break;
    }

    this.triggerRef.current.hide();
  }
  render() {
    const { copyAvailable } = this.props;

    return (
      <Whisper
        placement="auto"
        trigger="click"
        triggerRef={this.triggerRef}
        speaker={
          <Popover full>
            <Dropdown.Menu onSelect={this.handleSelectMenu}>
              {copyAvailable ? (
                <Dropdown.Item icon={<Icon icon="copy" />} eventKey={1}>
                  Copy Below
                </Dropdown.Item>
              ) : null}
              <Dropdown.Item icon={<Icon icon="refresh" />} eventKey={2}>
                Reset
              </Dropdown.Item>
            </Dropdown.Menu>
          </Popover>
        }>
        <IconButton color="blue" icon={<Icon icon="chevron-down" />} size="sm">
          Actions
        </IconButton>
      </Whisper>
    );
  }
}

RowAction.propTypes = {
  idx: PropTypes.number.isRequired,
  copyAvailable: PropTypes.bool.isRequired,
  onCopy: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
};

const styles = createUseStyles({
  table: { width: "100%" },
  tableHeader: { ...tableHeadStyles },
  tableCell: {
    padding: `calc(${variables.normal_gap} / 2)`,
    textAlign: "center",
  },
});

const CxlTable = (props) => {
  const { distribution_periods, onFeeChange, onCxlChange, onReset, onCopy } =
    props;

  const classes = styles();

  return (
    <Panel
      className="CustomPanel"
      header={<h5>Cancellation Policies Table</h5>}
      shaded>
      <table className={classes.table}>
        <thead className={classes.tableHeader}>
          <tr>
            <th>Period Name</th>
            <th>Distribution From - To</th>
            <th>Operation From - To</th>
            <th>Type</th>
            <th>Days Ahead</th>
            <th>Fee</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {distribution_periods.map((period, idx) => (
            <tr key={idx}>
              <td className={classes.tableCell}>{period.name}</td>
              <td className={classes.tableCell}>{`${naString(
                formatDate(period.distribution_from)
              )} - ${naString(formatDate(period.distribution_to))}`}</td>
              <td className={classes.tableCell}>{`${naString(
                formatDate(period.operation_from)
              )} - ${naString(formatDate(period.operation_to))}`}</td>
              <td className={classes.tableCell}>
                <SelectPicker
                  value={period.cancellation_policy_data.type}
                  data={[
                    { label: "Non-Refundable", value: "non-refundable" },
                    { label: "Chargeable", value: "chargeable" },
                  ]}
                  renderMenuItem={(label, item) => (
                    <Tag
                      color={item.value == "non-refundable" ? "red" : "orange"}>
                      <strong>{label}</strong>
                    </Tag>
                  )}
                  renderValue={(value, item) => (
                    <Tag color={value == "non-refundable" ? "red" : "orange"}>
                      <strong>{item.label}</strong>
                    </Tag>
                  )}
                  searchable={false}
                  onChange={(value) =>
                    onCxlChange(period.distIdx, "type", value)
                  }
                />
              </td>
              <td className={classes.tableCell}>
                <InputNumber
                  value={
                    isNaN(period.cancellation_policy_data.days_ahead)
                      ? ""
                      : period.cancellation_policy_data.days_ahead
                  }
                  onChange={(value) =>
                    onCxlChange(
                      period.distIdx,
                      "days_ahead",
                      parseInt(value, 10)
                    )
                  }
                />
              </td>
              <td className={classes.tableCell}>
                <InputNumber
                  value={period.cancellation_policy_data.fee.value}
                  prefix="%"
                  onChange={function (value) {
                    onFeeChange(period.distIdx, value);
                  }}
                />
              </td>
              <td className={classes.tableCell}>
                <RowAction
                  idx={period.distIdx}
                  copyAvailable={
                    distribution_periods.length > 1 &&
                    period.distIdx + 1 !== distribution_periods.length
                  }
                  onReset={onReset}
                  onCopy={onCopy}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Panel>
  );
};

CxlTable.propTypes = {
  currencies: PropTypes.array.isRequired,
  companyCurrency: PropTypes.string.isRequired,
  distribution_periods: PropTypes.array.isRequired,
  onFeeChange: PropTypes.func.isRequired,
  onCxlChange: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired,
};

export default CxlTable;
