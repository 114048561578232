import React from "react";
import { createUseStyles } from "react-jss";
import { GeneralStats } from "../GeneralStats";
import CreateBarChart from "../../ChartComponents/CreateBarChart";
import { variables } from "@src/jsssetup";
import PropTypes from "prop-types";
import TransTxt from "@src/components/common/SxFormatMessage";
import _ from "lodash";
import { injectIntl } from "react-intl";

const financeReportTable = createUseStyles({
  FinanceReportTable: {
    display: "grid",
    gridTemplateRows: "max-content",
  },
  tableContainer: {
    display: "grid",
    border: `2px solid #FF6600`,
    borderRadius: "10px",
    textAlign: "center",
  },
  table: {
    width: "100%",
  },
  tHead: {
    backgroundColor: "#FF6600",
  },
  headRow: {
    "background": "#FF6600",
    "& th:first-child": {
      borderTopLeftRadius: "10%",
    },
    "& th:last-child": {
      borderTopRightRadius: "10%",
    },
  },
  headCell: {
    backgroundColor: "#FF6600",
    textAlign: "center",
    color: "white",
    padding: variables.half_gap,
    fontFamily: "futura,sans-serif",
    fontWeight: "400",
  },
  tableCell: {
    textAlign: "center",
    padding: `${variables.half_gap} ${variables.normal_gap}`,
    fontFamily: "futura,sans-serif",
    height: "2rem",
  },
});

const FinanceReportTable = ({ data }) => {
  const classes = financeReportTable();

  return (
    <div className={classes.FinanceReportTable}>
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead className={classes.tHead}>
            <tr className={classes.headRow}>
              <th className={classes.headCell}>
                {<TransTxt id="B2B_stats_ADHACC_finance__count" />}
              </th>
              <th className={classes.headCell}>
                {<TransTxt id="B2B_stats_ADHACC_finance__buying" />}
              </th>
              <th className={classes.headCell}>
                {<TransTxt id="B2B_stats_ADHACC_finance__selling" />}
              </th>
              <th className={classes.headCell}>
                {<TransTxt id="B2B_stats_ADHACC_finance__margin" />}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={classes.tableCell}>
                {_.get(data, "finance.count") !== 0
                  ? _.get(data, "finance.count") || "N/A"
                  : "0"}
              </td>
              <td className={classes.tableCell}>
                {_.get(data, "finance.buying") !== 0
                  ? _.get(data, "finance.currency")
                  : ""}{" "}
                {_.get(data, "finance.buying") !== 0
                  ? _.get(data, "finance.buying") || "N/A"
                  : "0"}
              </td>
              <td className={classes.tableCell}>
                {_.get(data, "finance.selling") !== 0
                  ? _.get(data, "finance.currency")
                  : ""}{" "}
                {_.get(data, "finance.selling") !== 0
                  ? _.get(data, "finance.selling") || "N/A"
                  : "0"}
              </td>
              <td className={classes.tableCell}>
                {(_.get(data, "finance.margin.amount") !== 0
                  ? (
                      (_.get(data, "finance.margin.amount") * 100) /
                      _.get(data, "finance.count")
                    ).toFixed(2)
                  : "0") || "N/A"}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
FinanceReportTable.propTypes = {
  data: PropTypes.object.isRequired,
};

const styles = createUseStyles({
  ADHACCFinanceCharts: {
    display: "grid",
    padding: variables.double_gap,
  },
  stats: {
    display: "grid",
    gridTemplateRows: "max-content",
  },
  statsTable: {
    display: "grid",
    justifyItems: "center",
  },
  chartsContainer: {
    display: "grid",
    gridTemplateRows: "repeat(2, max-content)",
  },
  labelContainer: {
    display: "grid",
    gridTemplateColumns: "30.5rem 34rem",
    justifyItems: "center",
    paddingTop: variables.normal_gap,
  },
  label: {
    display: "grid",
    alignSelf: "start",
    padding: variables.half_gap,
    width: "15rem",
    borderRadius: "5px",
    textAlign: "center",
    color: "white",
    backgroundColor: "#C9C9C9",
    fontFamily: "futura,sans-serif",
  },
  label1: {
    display: "grid",
    alignSelf: "start",
    padding: variables.half_gap,
    width: "15rem",
    borderRadius: "5px",
    textAlign: "center",
    color: "white",
    backgroundColor: "#C9C9C9",
    fontFamily: "futura,sans-serif",
  },
  charts: {
    display: "grid",
    gridTemplateColumns: "22rem 1rem 41rem",
    justifyContent: "center",
    height: "20rem",
  },
  chart1: {
    display: "grid",
    justifyContent: "center",
  },
  chart2: {
    display: "grid",
    justifyContent: "center",
  },
  divider: {
    justifySelf: "center",
    alignSelf: "center",
    height: "85%",
    border: `1px solid rgba(0, 0, 0, 0.1)`,
    gridColumn: "1 span",
    width: "0%",
  },
});

var ADHACCFinanceCharts = ({ intl, selectedDate, data }) => {
  const classes = styles();

  const purchasesBarChartData = _.get(data, "finance.purchases.providers").map(
    ([date, value]) => ({
      name: date,
      value,
    })
  );
  const purchasesChartColors = ["#FF6600"];

  const salesBarChartData = _.get(
    data,
    "finance.sales.per_accommodation_type"
  ).map(([date, value]) => ({
    name: date,
    value,
  }));
  const salesChartColors = [
    "#006DAA",
    "#FFB381",
    "#B8B8B8",
    "#B0754E",
    "#B174FF",
    "#59CAE3",
  ];

  return (
    <div className={classes.ADHACCFinanceCharts}>
      <div className={classes.stats}>
        <GeneralStats
          tableTitle={intl.formatMessage({
            id: "B2B_stats_ADHACC_finance__confirmed_reservations",
          })}
          title={intl.formatMessage({
            id: "B2B_stats_ADHACC_finance__finance",
          })}
          startDate={_.get(data, "period.start_date")}
          endDate={_.get(data, "period.end_date")}
        />
        <div className={classes.statsTable}>
          <FinanceReportTable data={data} />
        </div>
      </div>
      <div className={classes.chartsContainer}>
        <div className={classes.labelContainer}>
          <div className={classes.label}>
            {<TransTxt id="B2B_stats_ADHACC_finance__purchases" />}
          </div>
          <div className={classes.label1}>
            {<TransTxt id="B2B_stats_ADHACC_finance__sales" />}
          </div>
        </div>
        <div className={classes.charts}>
          <div className={classes.chart1}>
            <CreateBarChart
              title={intl.formatMessage({
                id: "B2B_stats_ADHACC_finance__top_5_providers",
              })}
              barWidth={"100%"}
              width={25}
              xAxisColor="#FF6600"
              seriesLabelColor="#FF6600"
              predefinedColors={purchasesChartColors}
              data={purchasesBarChartData}
            />
          </div>
          <div className={classes.divider}></div>
          <div className={classes.chart2}>
            <CreateBarChart
              title={intl.formatMessage({
                id: "B2B_stats_ADHACC_finance__per_accommodation_type",
              })}
              moreDataLabel={intl.formatMessage({
                id: "B2B_stats_ADHACC_finance__stars",
              })}
              width={50}
              barWidth={"90%"}
              xAxisColor="#FF6600"
              seriesLabelColor="#FF6600"
              predefinedColors={salesChartColors}
              data={salesBarChartData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
ADHACCFinanceCharts.propTypes = {
  selectedDate: PropTypes.instanceOf(Date),
  data: PropTypes.object.isRequired,
  intl: PropTypes.object,
  dashboardPayload: PropTypes.object.isRequired,
};
ADHACCFinanceCharts = injectIntl(ADHACCFinanceCharts);
export default ADHACCFinanceCharts;
