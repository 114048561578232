import React from "react";
import TransTxt from "@src/components/common/SxFormatMessage";
import { Tag } from "rsuite";

export const initialBiddingTripsFilterForm = {
  year: {
    value: null,
    verbose: <TransTxt id="tripplanlist_year" startCase />,
  },
  trip_title: {
    value: "",
    verbose: <TransTxt id="tripplanlist_title" startCase />,
  },
  request_by: {
    value: "",
    verbose: <TransTxt id="tripplanlist_request_by" startCase />,
  },
  adults: {
    value: "",
    verbose: <TransTxt id="adults" startCase />,
  },
  children: {
    value: "",
    verbose: <TransTxt id="children" startCase />,
  },
  destinations: {
    value: [],
    verbose: <TransTxt id="tripplanlist_destinations" startCase />,
  },
  date: {
    value: [],
    verbose: <TransTxt id="date" startCase />,
  },
  recently: {
    value: "0",
    verbose: <TransTxt id="sort_by" startCase />,
    data: [
      {
        value: "0",
        label: <TransTxt id="date_add(newest)" />,
      },
      {
        value: "1",
        label: <TransTxt id="date_add(oldest)" />,
      },
    ],
  },
  pricing: {
    value: "selling",
    verbose: <TransTxt id="pricing" startCase />,
    data: [
      {
        label: <TransTxt id="selling_price" upperCase />,
        value: "selling",
      },
      {
        label: <TransTxt id="buying_price" upperCase />,
        value: "buying",
      },
    ],
  },
  travellers: {
    value: 0,
    verbose: <TransTxt id="tripplanlist_travellers" startCase />,
    hasContainerBorder: true,
    max: 40,
    isTime: false,
  },
  status: {
    value: [],
    verbose: <TransTxt id="status" startCase />,
    data: [
      {
        value: "unused",
        label: (
          <Tag color="red" style={{ width: "100px" }}>
            <strong>
              <TransTxt id="unused" startCase />
            </strong>
          </Tag>
        ),
      },
      {
        value: "used",
        label: (
          <Tag color="green" style={{ width: "100px" }}>
            <strong>
              <TransTxt id="used" startCase />
            </strong>
          </Tag>
        ),
      },
    ],
  },
  pax: {
    value: [1, 10],
    verbose: <TransTxt id="tripplanlist_pax" startCase />,
    hasContainerBorder: true,
  },
  origin: {
    value: "",
    verbose: <TransTxt id="tripplanlist_origin" startCase />,
  },
  return: {
    value: "",
    verbose: <TransTxt id="tripplanlist_return" startCase />,
  },
  travel_services: {
    value: [],
    verbose: <TransTxt id="travel_services" startCase />,
    data: [
      {
        value: "TR",
        label: <TransTxt id="transportation" startCase />,
        display_name: <TransTxt id="transportation" startCase />,
      },
      {
        value: "ACC",
        label: <TransTxt id="accommodation" startCase />,
        display_name: <TransTxt id="accommodation" startCase />,
      },
      {
        value: "TF",
        label: <TransTxt id="transfer" startCase />,
        display_name: <TransTxt id="transfer" startCase />,
      },
      {
        value: "MI",
        label: <TransTxt id="Add Ons" startCase />,
        display_name: <TransTxt id="Add Ons" startCase />,
      },
    ],
  },
  total: {
    value: "",
    verbose: <TransTxt id="tripplanlist_total" startCase />,
  },
  total_buying_price: {
    value: [1, 10],
    verbose: <TransTxt id="tripplanlist_total_buying_price" startCase />,
  },
  total_selling_price: {
    value: [1, 10],
    verbose: <TransTxt id="tripplanlist_total_selling_price" startCase />,
  },
  per_person: {
    value: "",
    verbose: <TransTxt id="tripplanlist_per_person" startCase />,
  },
  created_by: {
    value: [],
    verbose: <TransTxt id="created_by" startCase />,
  },
  per_person_buying_price: {
    value: [1, 10],
    verbose: <TransTxt id="tripplanlist_per_person_buying_price" startCase />,
  },
  per_person_selling_price: {
    value: [1, 10],
    verbose: <TransTxt id="tripplanlist_per_person_selling_price" startCase />,
  },
  last_editor: {
    value: "",
    verbose: <TransTxt id="last_edited_date" startCase />,
  },
  created: {
    value: [],
    placement: "auto",
    verbose: <TransTxt id="created_date" startCase />,
  },
};

export * from "./columns";
