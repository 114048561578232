import moment from "moment";
import _ from "lodash";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";
import {
  extremalIsRoundSelector,
  getDestArrivalFlightSelector,
  getSelectedAccSelector,
  getSelectedTrfSelector,
} from "@src/selectors/Project/TripPlanner";
import { Icon, Panel, Rate, Tag, Timeline } from "rsuite";
import { formatDate, humanizeDuration } from "@src/tools/date_tools";
import { getAddOnsForDest } from "@src/selectors/Project/TripPlanner/day_by_day_selectors";
import SuIcon from "@src/style/icon/components/SuIcon";
import { addon_icon_mapping } from "@src/config/service";
import { naString } from "@src/tools/string_tools";
import { createUseStyles } from "react-jss";
import { variables } from "@src/jsssetup";
import { AccommodationServicePanel } from "../Components/service_panels";

export const tripServiceStyles = {
  service: {
    "marginRight": variables.normal_gap,
    "marginBottom": 0,
    "& > .rs-panel-body": {
      display: "grid",
      gridTemplateColumns: "auto 1fr",
      gridGap: variables.normal_gap,
      background: "white",
      alignItems: "center",
    },
  },
  imageContainer: {
    width: "170px",
    height: "100px",
    borderRadius: "5px",
    position: "relative",
  },
  image: {
    width: "100%",
    height: "100%",
    borderRadius: "5px",
    objectFit: "cover",
  },
  content: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
  },
};

const addOnServicePanelStyles = createUseStyles({
  service: { ...tripServiceStyles.service },
  imageContainer: { ...tripServiceStyles.imageContainer },
  image: { ...tripServiceStyles.image },
  content: { ...tripServiceStyles.content },
  items: {
    display: "inline-grid",
    gridAutoFlow: "column",
    padding: "0px",
    whiteSpace: "nowrap",
    overflowX: "auto",
    gridGap: `calc(${variables.normal_gap} / 2)`,
  },
  item: {
    display: "inline-grid",
    paddingRight: `calc(${variables.normal_gap} / 2)`,
    position: "relative",
    gridTemplateColumns: "auto 1fr",
    alignItems: "center",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    border: `2px solid ${variables.colors.easy.lightOrange2}`,
    borderRadius: "5px",
    padding: `calc(${variables.normal_gap} / 2)`,
  },
  icon: {
    "display": "grid",
    "textAlign": "center",
    "alignItems": "center",
    "width": "35px",
    "height": "35px",
    "background": "white",
    "border": "2px solid #ddd",
    "fontSize": "18px",
    "borderRadius": "50%",
    "&.icon-highlightsicon": {
      color: variables.colors.highlight,
      borderColor: variables.colors.highlight,
    },
    "&.icon-foodanddrinkicon": {
      color: variables.colors.food_and_drink,
      borderColor: variables.colors.food_and_drink,
    },
    "&.icon-Shoppingicon": {
      color: variables.colors.shopping,
      borderColor: variables.colors.shopping,
    },
  },
});
const AddonServicePanel = (props) => {
  const { addon } = props;
  const classes = addOnServicePanelStyles();
  return (
    <Panel
      className={`CustomPanel ${classes.service}`}
      shaded
      header={
        <h6>
          {_.get(addon, "service.title")}
          {" - "}
          {formatDate(_.get(addon, "service.operation_date"))}
          {" - "}
          Day {_.get(addon, "day")}
        </h6>
      }>
      <div className={classes.imageContainer}>
        <img
          className={classes.image}
          alt=""
          src={_.get(
            addon,
            "service.items.0.data.image_set.0.photo_sm_url",
            ""
          )}
        />
      </div>
      <div className={classes.content}>
        <ul className={classes.items}>
          {_.get(addon, "service.items", []).map((item, iidx) => {
            return (
              <li className={classes.item} key={iidx}>
                <SuIcon
                  className={classes.icon}
                  icon={addon_icon_mapping[item.type]}
                />
                <div>
                  <p>
                    <strong>Name: </strong>
                    {item.data.name_en}
                  </p>
                  <p>
                    <span>
                      <strong>Arrival Time:</strong>{" "}
                      {naString(item.arrival_time)}
                    </span>
                  </p>
                  <p>
                    <span>
                      <strong>Duration:</strong>{" "}
                      {humanizeDuration(item.visit_duration * 60 * 1000, {
                        abbrev: true,
                      })}
                    </span>
                  </p>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </Panel>
  );
};
AddonServicePanel.propTypes = {
  addon: PropTypes.object.isRequired,
};

const flServicePanelStyles = createUseStyles({
  service: { ...tripServiceStyles.service },
  imageContainer: {
    ...tripServiceStyles.imageContainer,
    display: "grid",
    alignItems: "center",
    justifyItems: "center",
  },
  image: {
    ...tripServiceStyles.image,
    width: `80%`,
    height: "60%",
    objectFit: "contain",
  },
  content: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: variables.normal_gap,
  },
});
const FlightServicePanel = (props) => {
  const { flight, returnFlight, extremalIsRoundtrip } = props;
  const classes = flServicePanelStyles();
  var option;
  if (returnFlight && extremalIsRoundtrip) {
    option = _.last(flight.legs).find((option) => option.selected);
  } else {
    option = flight.legs[0].find((option) => option.selected);
  }

  const originAirport = _.get(option, "segments.0.origin_airport", {});
  const destinationAirport = _.last(
    _.get(option, "segments", [])
  ).destination_airport;

  return (
    <Panel
      className={`CustomPanel ${classes.service}`}
      shaded
      header={
        <h6>
          {returnFlight ? "Departure Flight" : "Arrival Flight"}:{" "}
          {originAirport.iata} <small>{originAirport.name}</small> -{" "}
          {destinationAirport.iata} <small>{destinationAirport.name}</small>
        </h6>
      }>
      <div className={classes.imageContainer}>
        <img
          className={classes.image}
          alt=""
          src={_.get(flight, "outbound_operating_airline.logo", "")}
        />
      </div>
      <div className={classes.content}>
        <div>
          <strong>Itinerary: </strong>
          {[
            ...new Set(
              _.flatten(
                option.segments.map((segment) => [
                  segment.origin_airport.iata,
                  segment.destination_airport.iata,
                ])
              )
            ),
          ].join(" - ")}
        </div>
        <div>
          <strong>Airlines: </strong>
          {[
            ...new Set(
              option.segments.map((segment) => segment.operating_airline.name)
            ),
          ].join(" - ")}
        </div>
        <div>
          <strong>Departure: </strong>
          {moment
            .parseZone(option.segments[0].departure)
            .format("DD/MM/YYYY HH:mm")}
        </div>
        <div>
          <strong>Arrival: </strong>
          {moment
            .parseZone(_.last(option.segments).arrival)
            .format("DD/MM/YYYY HH:mm")}
        </div>
        <div>
          <strong>Duration: </strong>
          {humanizeDuration(
            option.segments.reduce(
              (a, b) => a + (b.duration + _.get(b, "transfer_time", 0)),
              0
            ),
            { abbrev: true }
          )}
        </div>
        <div>
          <strong>Layover: </strong>
          {humanizeDuration(
            option.segments.reduce(
              (a, b) => a + _.get(b, "transfer_time", 0),
              0
            ),
            { abbrev: true }
          )}
        </div>
      </div>
    </Panel>
  );
};
FlightServicePanel.propTypes = {
  flight: PropTypes.object.isRequired,
  returnFlight: PropTypes.bool.isRequired,
  extremalIsRoundtrip: PropTypes.bool.isRequired,
};

const trfServicePanelStyles = createUseStyles({
  service: { ...tripServiceStyles.service },
  imageContainer: { ...tripServiceStyles.imageContainer },
  image: { ...tripServiceStyles.image },
  content: { ...tripServiceStyles.content },
});
const TransferServicePanel = (props) => {
  const { transferLeg, transfer } = props;

  const getItin = () => {
    var keys = [transfer.origin.name, transfer.destination.name];

    if (["inbound", "outbound"].every((k) => !_.isEmpty(transfer[k]))) {
      if (transferLeg == "outbound") {
        keys = [transfer.destination.name, transfer.origin.name];
      }
    }

    return keys.join(" - ");
  };

  const classes = trfServicePanelStyles();

  return (
    <Panel
      className={`CustomPanel ${classes.service}`}
      shaded
      header={
        <h6>
          Transfer:{" "}
          {transferLeg == "inbound"
            ? "Transportation Hub to Hotel"
            : "Hotel to Transportation Hub"}
        </h6>
      }>
      <div className={classes.imageContainer}>
        <img
          className={classes.image}
          alt=""
          src={_.get(transfer, `${transferLeg}.vehicle.images.0.url`, "")}
        />
      </div>
      <div className={classes.content}>
        <div>
          <strong>Route: </strong>
          {getItin()}
        </div>
        <div>
          <strong>Pickup: </strong>
          {moment
            .parseZone(_.get(transfer, `${transferLeg}.start`))
            .format("DD/MM/YYYY HH:mm")}
        </div>
        <div>
          <strong>Distance: </strong>
          {_.get(transfer, `${transferLeg}.distance`, "0")}km
        </div>
        <div>
          <strong>Duration: </strong>
          {humanizeDuration(_.get(transfer, `${transferLeg}.duration`, 0))}
        </div>
        <div>
          <strong>Vehicle: </strong>
          {naString(_.get(transfer, `${transferLeg}.vehicle.name`))} (
          {naString(_.get(transfer, `${transferLeg}.vehicle.brand`))})
        </div>
      </div>
    </Panel>
  );
};
TransferServicePanel.propTypes = {
  transfer: PropTypes.object.isRequired,
  transferLeg: PropTypes.oneOf(["inbound", "outbound"]).isRequired,
};

const serviceStyles = {
  service: {
    display: "grid",
    gridAutoRows: "min-content",
    gridGap: variables.normal_gap,
    alignItems: "center",
    padding: `${variables.normal_gap} 0`,
  },
};

const trfServiceStyles = createUseStyles({
  service: { ...serviceStyles.service },
});
const Transfer = (props) => {
  const { transferLeg, inboundTrf, outboundTrf } = props;
  const service = transferLeg == "inbound" ? inboundTrf : outboundTrf;

  if (!service) {
    return null;
  }

  const classes = trfServiceStyles();
  return (
    <Timeline.Item dot={<Icon icon="car" size="2x" />}>
      <div className={classes.service}>
        <TransferServicePanel transfer={service} transferLeg={transferLeg} />
      </div>
    </Timeline.Item>
  );
};
Transfer.propTypes = {
  transferLeg: PropTypes.oneOf(["inbound", "outbound"]).isRequired,
  inboundTrf: PropTypes.object.isRequired,
  outboundTrf: PropTypes.object.isRequired,
};

const accServiceStyles = createUseStyles({
  service: { ...serviceStyles.service },
});
const Accommodation = (props) => {
  const { accommodation } = props;

  if (!accommodation) {
    return null;
  }

  const classes = accServiceStyles();
  return (
    <Timeline.Item dot={<Icon icon="h-square" size="2x" />}>
      <div className={classes.service}>
        <AccommodationServicePanel accommodation={accommodation} />
      </div>
    </Timeline.Item>
  );
};
Accommodation.propTypes = {
  accommodation: PropTypes.object.isRequired,
};

const flServiceStyles = createUseStyles({
  service: { ...serviceStyles.service },
});
const Flight = (props) => {
  const { flight, returnFlight, returningFlight, extremalIsRoundtrip } = props;

  if (!flight && !returningFlight) {
    return null;
  }

  const service = returnFlight ? returningFlight : flight;
  const classes = flServiceStyles();
  return (
    <Timeline.Item dot={<Icon icon="plane" size="2x" />}>
      <div className={classes.service}>
        <FlightServicePanel
          flight={service}
          returnFlight={returnFlight}
          extremalIsRoundtrip={extremalIsRoundtrip}
        />
      </div>
    </Timeline.Item>
  );
};
Flight.propTypes = {
  flight: PropTypes.object.isRequired,
  returnFlight: PropTypes.bool.isRequired,
  returningFlight: PropTypes.object.isRequired,
  extremalIsRoundtrip: PropTypes.bool.isRequired,
};

const addonServiceStyles = createUseStyles({
  service: { ...serviceStyles.service },
});
const AddOn = (props) => {
  const { addons } = props;

  if (!addons.length) {
    return null;
  }

  const classes = addonServiceStyles();

  return addons.map((addon, idx) => {
    return (
      <Timeline.Item key={idx} dot={<Icon icon="map" size="2x" />}>
        <div className={classes.service}>
          <AddonServicePanel addon={addon} />
        </div>
      </Timeline.Item>
    );
  });
};
const destinationStyles = createUseStyles({
  services: {
    display: "grid",
  },
  timeline: {
    "& .rs-timeline-item-content": {
      paddingLeft: "24px",
      paddingBottom: "0px !important",
    },
    "& .rs-timeline-item": {
      position: "relative",
    },
    "& .rs-timeline-item-tail": {
      top: "50% !important",
      marginLeft: "15px",
      backgroundColor: variables.colors.deepblue,
    },
    "& > li:not(:last-of-type)": {
      "& .rs-timeline-item-tail": {
        height: "100% !important",
      },
    },
    "& .rs-timeline-item-dot": {
      "top": "50%",
      "transform": "translateY(-50%)",
      "& .rs-icon": {
        background: "#fff",
        border: `2px solid ${variables.colors.deepblue}`,
        width: "40px",
        height: "40px",
        borderRadius: "50%",
        fontSize: "25px",
        paddingTop: "6px",
        color: variables.colors.deepblue,
      },
    },
  },
});

const TMktDestination = (props) => {
  const {
    addons,
    accommodation,
    destination,
    isLastDestination,
    inboundTrf,
    outboundTrf,
    flight,
    returningFlight,
    extremalIsRoundtrip,
  } = props;

  const classes = destinationStyles();

  const stay = moment
    .duration(moment(destination.checkOut).diff(moment(destination.checkIn)))
    .days();
  return (
    <Timeline.Item>
      <h6>
        {destination.order}. {destination.name_en} - From{" "}
        {formatDate(destination.checkIn)} To {formatDate(destination.checkOut)}{" "}
        ({stay} {`${stay == 1 ? "Night" : "Nights"}`}, {stay + 1}{" "}
        {`${stay + 1 == 1 ? "Day" : "Days"}`})
      </h6>
      <div className={classes.services}>
        <Timeline className={classes.timeline}>
          <Flight
            flight={flight}
            returnFlight={false}
            returningFlight={returningFlight}
            extremalIsRoundtrip={extremalIsRoundtrip}
          />
          <Transfer
            transferLeg="inbound"
            inboundTrf={inboundTrf}
            outboundTrf={outboundTrf}
          />
          <Accommodation accommodation={accommodation} />
          <AddOn addons={addons} />
          <Transfer
            transferLeg="outbound"
            inboundTrf={inboundTrf}
            outboundTrf={outboundTrf}
          />
          {isLastDestination ? (
            <Flight
              flight={flight}
              returnFlight={true}
              returningFlight={returningFlight}
              extremalIsRoundtrip={extremalIsRoundtrip}
            />
          ) : null}
        </Timeline>
      </div>
    </Timeline.Item>
  );
};
TMktDestination.propTypes = {
  destination: PropTypes.object.isRequired,
  extremalIsRoundtrip: PropTypes.bool.isRequired,
  flight: PropTypes.object,
  returningFlight: PropTypes.object,
  accommodation: PropTypes.object,
  addons: PropTypes.array,
  inboundTrf: PropTypes.object,
  outboundTrf: PropTypes.object,
  isLastDestination: PropTypes.bool.isRequired,
};
const TMktDestinationMapStateToProps = (state, ownProps) => {
  const {
    destination: { order: destOrder },
    isLastDestination,
  } = ownProps;

  const extremalIsRoundtrip = extremalIsRoundSelector(state);
  const flight = getDestArrivalFlightSelector(state, {
    destOrder,
  });

  var returningFlight = null;
  if (isLastDestination) {
    returningFlight = getDestArrivalFlightSelector(state, {
      destOrder,
      returnFlight: true,
    });
  }

  const accommodation = getSelectedAccSelector(state, { destOrder });
  const addons = getAddOnsForDest(state, { destOrder });
  const inboundTrf = getSelectedTrfSelector(state, {
    destOrder,
    transfer_type: "inbound",
  });
  const outboundTrf = getSelectedTrfSelector(state, {
    destOrder,
    transfer_type: "outbound",
  });

  return {
    extremalIsRoundtrip,
    flight,
    returningFlight,
    accommodation,
    addons,
    inboundTrf,
    outboundTrf,
  };
};

const MktDestination = connect(
  TMktDestinationMapStateToProps,
  null
)(TMktDestination);

const mktStyles = createUseStyles({
  itineraryContainer: {
    display: "grid",
    justifyContent: "center",
    overflow: "auto",
    background: variables.colors.lightGrey1,
    position: "relative",
    padding: variables.spaces.smallScreenCenterPadding,
  },
  itinerary: {
    "minWidth": "50vw",
    "padding": variables.normal_gap,
    "& > .rs-timeline-item:only-child": {
      "& .rs-timeline-item-tail": {
        display: "block",
        height: "0px",
      },
    },
    "& .rs-timeline-item-tail": {
      backgroundColor: variables.colors.easy.lightOrange2,
    },
    "& .rs-timeline-item-dot": {
      "&:before": {
        backgroundColor: variables.colors.easy.lightOrange2,
      },
    },
  },
});
const MktItinerary = (props) => {
  const { originPoint, returnPoint, destinations } = props;

  const classes = mktStyles();

  return (
    <div className={classes.itineraryContainer}>
      <Timeline className={classes.itinerary}>
        {_.get(originPoint, "destination.id") ? (
          <Timeline.Item>
            <h6>Origin: {_.get(originPoint, "destination.query")}</h6>
            <h6>
              Departure:{" "}
              <Tag color="blue">
                <strong>{formatDate(originPoint.date)}</strong>
              </Tag>
            </h6>
          </Timeline.Item>
        ) : null}
        {destinations.map((dest, idx) => {
          const isLastDestination = idx + 1 == destinations.length;
          return (
            <MktDestination
              destination={dest}
              key={idx}
              isLastDestination={isLastDestination}
            />
          );
        })}
        {_.get(returnPoint, "destination.id") ? (
          <Timeline.Item>
            <h6>Return: {_.get(returnPoint, "destination.query")}</h6>
            <h6>
              Arrival:{" "}
              <Tag color="blue">
                <strong>{formatDate(returnPoint.date)}</strong>
              </Tag>
            </h6>
          </Timeline.Item>
        ) : null}
      </Timeline>
    </div>
  );
};
MktItinerary.propTypes = {
  originPoint: PropTypes.object.isRequired,
  returnPoint: PropTypes.object.isRequired,
  destinations: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  const originPoint = state.tripPlannerOriginData;
  const returnPoint = state.tripPlannerReturnData;
  const destinations = state.tripPlannerDestinations;

  return { originPoint, returnPoint, destinations };
};

export default connect(mapStateToProps, null)(MktItinerary);
