import {
  //==============LIST=======================
  SALES_BEGIN_FETCH_DATA,
  SALES_FETCH_DATA,
  SALES_END_FETCH_DATA,
  SALES_INITIALIZE_LIST_FILTER_FORM,
  SALES_APPLY_LIST_FILTER,
  SALES_UPDATE_LIST_FILTER_FORM,
  SALES_INITIALIZE_LIST_COLUMNS_FORM,
  SALES_APPLY_LIST_COLUMNS,
  //==================================GRAPH================================
  SALES_INIT_VIEW_TYPE,
  SALES_CHANGE_VIEW_TYPE,
  SALES_FETCH_GRAPH_DATA,
  SALES_BEGIN_FETCH_GRAPH_DATA,
  SALES_END_FETCH_GRAPH_DATA,
} from "@src/actions/ManagementConsole/Sales/types";

import {
  initialSalesFilterForm,
  initialSalesColumnsSetUp,
} from "@src/forms/ManagementConsole/Sales";

import { formProcessor, entitiesListSelector } from "@src/reducers/tools";

import update from "immutability-helper";
import _ from "lodash";

//========================================LIST VIEW==============================================
export const initialSalesList = {
  count: 0,
  next: null,
  previous: null,
  results: [],
  page: 1,
  limit: 20,
  displayLength: 100,
};

export const salesList = (state = initialSalesList, action) => {
  switch (action.type) {
    case SALES_FETCH_DATA:
      return action.data;
    case SALES_INITIALIZE_LIST_FILTER_FORM:
      return { ...state, page: 1 };
    default:
      return state;
  }
};

export const initialSalesFetchStatus = "IDLE";
export const salesFetchStatus = (state = initialSalesFetchStatus, action) => {
  switch (action.type) {
    case SALES_BEGIN_FETCH_DATA:
      return "FETCHING";
    case SALES_END_FETCH_DATA:
      return "FETCHED";
    default:
      return state;
  }
};

//========================================FILTER RELATION============================================
export const salesListFilterForm = (state = initialSalesFilterForm, action) => {
  switch (action.type) {
    case SALES_INITIALIZE_LIST_FILTER_FORM:
      return initialSalesFilterForm;
    case SALES_UPDATE_LIST_FILTER_FORM:
      return formProcessor(
        state,
        action.data.type,
        action.data.name,
        action.data.value
      );
    default:
      return state;
  }
};

export const salesListFilter = (state = initialSalesFilterForm, action) => {
  switch (action.type) {
    case SALES_APPLY_LIST_FILTER:
      return action.data;
    default:
      return state;
  }
};

//======================================COLUMNS RELATION=================================
export const salesListColumns = (
  state = entitiesListSelector(initialSalesColumnsSetUp),
  action
) => {
  switch (action.type) {
    case SALES_APPLY_LIST_COLUMNS:
      return entitiesListSelector(action.data);
    default:
      return state;
  }
};

export const salesListColumnsForm = (
  state = initialSalesColumnsSetUp,
  action
) => {
  switch (action.type) {
    case SALES_INITIALIZE_LIST_COLUMNS_FORM:
      var newState = {};

      for (const key in state) {
        // clear form's value
        state[key] = update(state[key], { value: { $set: [] } });
        newState[key] = update(state[key], { value: { $set: [] } });
        // add value by action data
        action.data.forEach((item) => {
          if (_.find(state[key].choices, { key: item.key })) {
            newState[key] = update(newState[key], {
              value: { $push: [item.key] },
            });
          }
        });
      }

      return newState;
    default:
      return state;
  }
};

//=============================================GRAPH=================================
export const initType = "ALL";

export const salesViewType = (state = initType, action) => {
  switch (action.type) {
    case SALES_INIT_VIEW_TYPE:
      return initType;
    case SALES_CHANGE_VIEW_TYPE:
      return action.data;
    default:
      return state;
  }
};

export const salesGraphData = (state = {}, action) => {
  switch (action.type) {
    case SALES_FETCH_GRAPH_DATA:
      return action.data;
    default:
      return state;
  }
};

export const initialSalesGraphDataStatus = { loading: false };
export const salesGraphDataStatus = (
  state = initialSalesGraphDataStatus,
  action
) => {
  switch (action.type) {
    case SALES_BEGIN_FETCH_GRAPH_DATA:
      return update(state, { loading: { $set: true } });
    case SALES_END_FETCH_GRAPH_DATA:
      return update(state, { loading: { $set: false } });
    default:
      return state;
  }
};
