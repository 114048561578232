import service from "@src/api/request";

export const getTravelServicesOptions = (lang) => {
  return service({
    url: `/bid/travel_service/`,
    method: "options",
    headers: {
      "Accept-Language": lang,
    },
  });
};

export const getTravelServices = () => {
  return service({ url: `/bid/travel_service/`, method: "get" });
};

export const getGuestOptions = () => {
  return service({ url: `/bid/guest/`, method: "options" });
};

export const getEmailOptions = () => {
  return service({ url: `/bid/reservation-emails/`, method: "options" });
};

export function getReservationEmailById(reservation_id, service_type) {
  return service({
    url: `bid/reservation-emails/`,
    method: "get",
    params: { reservation_id, service_type },
  });
}

//territories relation

export function getCountriesOptions() {
  return service({
    url: "/bid/dest_info_countries/",
    method: "options",
  });
}

// country
export function getCountriesList(params) {
  return service({
    url: `/bid/dest_info_countries/`,
    method: "get",
    params: params,
  });
}

// province
export function getFirstLevelData(params) {
  return service({
    url: `/bid/dest_info_lvl1/`,
    method: "get",
    params: params,
  });
}

// city
export function getSecondLevelData(params) {
  return service({
    url: `/bid/dest_info_lvl2/`,
    method: "get",
    params: params,
  });
}

// region
export function getThirdLevelData(params) {
  return service({
    url: `/bid/dest_info_lvl3/`,
    method: "get",
    params: params,
  });
}

export function uploadImgLink(params, config = {}) {
  return service({
    url: "/bid/image/",
    method: "post",
    data: params,
    ...config,
  });
}

export function fetchImgLink() {
  return service({
    url: "/bid/image/",
    method: "get",
  });
}

export function fetchCompleteAddress(params) {
  return service({
    url: "/bid/gmap-place-autocomplete/",
    method: "get",
    params: params,
  });
}

export function fetchCompleteAddressDetail(params) {
  return service({
    url: "/bid/gmap-place-detail/",
    method: "get",
    params: params,
  });
}

export function changeEmployeesStatus(para) {
  return service({
    url: "/api/passport/change-employees-status/",
    data: para,
    method: "post",
  });
}

// ======================= SIMPLE COUNTRY INFO =======================
export function getCountryCodeInfo(params) {
  return service({
    url: "/bid/country-simple-infos/",
    method: "get",
    params: params,
  });
}

// ============================ DASHBOARD ============================

export function getUserGraphData(para) {
  return service({
    url: "/bid/user-statistics/",
    params: para,
    method: "get",
  });
}

export function getCompanyGraphData(para) {
  return service({
    url: "/bid/company-statistics/",
    params: para,
    method: "get",
  });
}

export function getGraphMapViewData(para) {
  return service({
    url: "/bid/map-view-statistics/",
    params: para,
    method: "get",
  });
}

// ========================= RelatedEmployees ========================
export function getRelatedEmployees(params) {
  return service({
    url: "bid/related-employees/",
    method: "get",
    params: params,
  });
}

//==============================RELATED ENTITIES==========================
export function getRelatedEntities() {
  return service({
    url: "bid/related-entities/",
    method: "get",
  });
}

// ========================= TRANSLATION API =========================
export function translate(payload) {
  return service({
    url: "bid/translate/",
    method: "post",
    data: payload,
  });
}

// ========================= GUESTS API =========================
export function guestsData({ unique, limit }) {
  return service({
    url: "bid/guest/",
    method: "get",
    params: { unique, limit },
  });
}
