import _ from "lodash";
import { fetchTrainStationsAutocomplete } from "@src/api/Project/trains";
import { NormalDatePicker, NormalSelectField } from "@src/components/forms";
import { getUserSourceEntitySelector } from "@src/selectors/Shared/user_selectors";
import { useQueries } from "@tanstack/react-query";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useSelector } from "react-redux";

function findBestCode(stations, pointName) {
  var bestCode = `${stations[0].code}___${stations[0].type}`;
  return bestCode;
}

export const TrainPreferences = ({
  leg,
  legIdx,
  originName,
  destinationName,
  mode,
}) => {
  const { setFieldValue } = useFormikContext();

  const [originOptions, setOriginOptions] = useState([]);
  const [destinationOptions, setDestinationOptions] = useState([]);

  const source_entity = useSelector((state) =>
    getUserSourceEntitySelector(state)
  );

  useQueries({
    queries: [
      {
        queryKey: ["train_preferences", legIdx, "origin", originName],
        queryFn: () =>
          fetchTrainStationsAutocomplete({
            market_type: "B2B",
            search_query: originName,
            source_entity,
          }),
        onSuccess: (data) => {
          const results = data?.data ?? [];
          const bestCode = findBestCode(results, originName);
          setFieldValue(
            `legs.${legIdx}.service_preferences.preferences.origin_station`,
            bestCode
          );

          setOriginOptions(
            _.sortBy(results, (v) => v.type !== "city").map((st) => [
              `${st.code}___${st.type}`,
              `${st.label} (${st.country_name})`,
            ])
          );
        },
        refetchOnWindowFocus: false,
        retry: false,
      },
      {
        queryKey: ["train_preferences", legIdx, "destination", destinationName],
        queryFn: () =>
          fetchTrainStationsAutocomplete({
            market_type: "B2B",
            search_query: destinationName,
            source_entity,
          }),
        onSuccess: (data) => {
          const results = data?.data ?? [];
          const bestCode = findBestCode(results, destinationName);
          setFieldValue(
            `legs.${legIdx}.service_preferences.preferences.destination_station`,
            bestCode
          );

          setDestinationOptions(
            _.sortBy(results, (v) => v.type !== "city").map((st) => [
              `${st.code}___${st.type}`,
              `${st.label} (${st.country_name})`,
            ])
          );
        },
        refetchOnWindowFocus: false,
        retry: false,
      },
    ],
  });

  const srvPrefs = leg.service_preferences.preferences;
  return (
    <div className="TransportationLegs__leg__train-preferences">
      {mode === "edit" && (
        <React.Fragment>
          <NormalDatePicker
            name={`legs.${legIdx}.service_preferences.preferences.departure`}
            label="Departure Time"
            withTime={true}
            withTimeOnly={true}
          />
          <NormalSelectField
            name={`legs.${legIdx}.service_preferences.preferences.origin_station`}
            label="Departing Station"
            options={[["", "------"], ...originOptions]}
          />
          <NormalSelectField
            name={`legs.${legIdx}.service_preferences.preferences.destination_station`}
            label="Arriving Station"
            options={[["", "------"], ...destinationOptions]}
          />
        </React.Fragment>
      )}
      {mode === "view" && (
        <React.Fragment>
          <span className="TransportationLegs__view-attribute">
            <strong>Departing Station</strong>
            {originOptions.find((c) => c[0] === srvPrefs.origin_station)?.[1] ??
              "N/A"}
          </span>
          <span className="TransportationLegs__view-attribute">
            <strong>Arriving Station</strong>
            {destinationOptions.find(
              (c) => c[0] === srvPrefs.destination_station
            )?.[1] ?? "N/A"}
          </span>
        </React.Fragment>
      )}
    </div>
  );
};
TrainPreferences.propTypes = {
  leg: PropTypes.object.isRequired,
  legIdx: PropTypes.number.isRequired,
  originName: PropTypes.string.isRequired,
  destinationName: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(["view", "edit"]).isRequired,
};
