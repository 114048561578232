import { notifyCommonError } from "@src/components/common/notifications/CommonErrorNotification.js";

import {
  TRIPPLANNER_CANCEL_CONTROL_PANEL_REQUEST,
  TRIPPLANNER_SAVING_TRIP,
  TRIPPLANNER_SAVED_TRIP,
  TRIPPLANNER_INSTANCE_DATA_UPDATE,
  TRIPPLANNER_REQUEST_UPDATE_OFFER,
  TRIPPLANNER_REQUEST_UPDATE_OFFER_HIDE_MODAL,
  TRIPPLANNER_REQUEST_UPDATE_OFFER_LOADING,
  TRIPPLANNER_REQUEST_UPDATE_OFFER_IDLE,
} from "./types";

import update from "immutability-helper";

import _ from "lodash";

import {
  tripSave,
  tripUpdate,
  updateTripPlanOffer,
} from "@src/api/Project/TripPlanner";
import { tripModeMapping } from "@src/selectors/Project/TripPlanner/trip_save_selectors";
import { getServicesPricingSelector } from "@src/selectors/Project/TripPlanner/pricing";

const prepareAccommodationsSave = (accommodations) => {
  const prepAccs = {};
  Object.entries(accommodations).forEach(
    (e) =>
      (prepAccs[e[0]] = e[1]
        .filter((a) => a.selected)
        .map((a) =>
          update(a, {
            rooms: { $set: (a?.rooms ?? []).filter((r) => r.selected) },
            ["detailed_rooms"]: {
              $set: (a?.detailed_rooms ?? []).filter((r) => r.selected),
            },
            ["ungrouped_rooms"]: {
              $set: (a?.ungrouped_rooms ?? []).filter((r) => r.selected),
            },
          })
        ))
  );

  return prepAccs;
};

const prepareTransfersSave = (transfers) => {
  const prepTransfers = {};

  Object.entries(transfers).forEach((e) => {
    prepTransfers[e[0]] = {};
    Object.entries(e[1]).forEach(
      (ee) => (prepTransfers[e[0]][ee[0]] = ee[1].filter((t) => t.selected))
    );
  });

  return prepTransfers;
};

export function prepareTripInstancePayload(state) {
  const { id: trip_instance_id, trip_instance_type } =
    state.tripPlannerInstanceData || {};

  const trip_legs = state.tripPlannerLegsReducer;
  const flights = state.tripPlannerFlightsReducer;
  const trains = state.tripPlannerTrainsReducer;
  const ferries = state.tripPlannerFerriesReducer;
  const accommodations = prepareAccommodationsSave(state.tripPlannerAccData);
  const transfers = prepareTransfersSave(state.tripPlannerTransferData);
  const addOns = state.tripPlannerAddOnData;
  const activity_data = state.tripPlannerDbDActivityReducer;

  const dbdCustomSrvs = state.tripPlannerDbDCustomSrvData;
  const accCustomSrvs = state.tripPlannerAccCustomData;

  const dbdAddhocSrvs = state.tripPlannerDbDAddhocSrvData;
  const trpAddhocSrvs = state.tripPlanTrpAddhocServices;
  const trfAddhocSrvs = state.tripPlanTransferAddhocServices;
  const accAddhocSrvs = state.tripPlannerAccAddhocData;

  const dbdDescriptions = state.tripPlannerDayByDayContent;

  var origin_data = _.cloneDeep(state.tripPlannerOriginData);
  origin_data = update(origin_data, {
    destination: { images: { $set: [origin_data.destination.images[0]] } },
  });

  var return_data = _.cloneDeep(state.tripPlannerReturnData);
  if (!_.isEmpty(return_data)) {
    return_data = update(return_data, {
      destination: { images: { $set: [return_data.destination.images[0]] } },
    });
  }

  var destinations = _.cloneDeep(state.tripPlannerDestinations);
  destinations = update(destinations, {
    $apply: (dests) =>
      dests.map((d) =>
        update(d, {
          images: {
            $set: d.images
              .sort((a, b) => a.priority - b.priority)
              .filter((__, idx) => idx < 5),
          },
        })
      ),
  });

  const { linked_offer } = state.tripPlannerItinerarySetupForm;

  const trip_type = tripModeMapping(state.tripPlannerItinerarySetupForm);
  const trip_pricing = getServicesPricingSelector(state);

  const detailed_pricing_data = state.tripPlannerDetailedSrvPricing;

  // We clone because we do not want to change the redux state.
  const itinerary_setup = _.cloneDeep(state.tripPlannerItinerarySetupForm);
  const external_reference = itinerary_setup.external_reference;
  // This is deleted so as to not save it in the CRS DB. It has to be saved
  // as a separate field
  delete itinerary_setup.external_reference;

  const ai_dialogs = state.tripAiDialogsReducer;

  // If a target entity is set, then remove it from the itinerary setup
  var target_entity = null;
  if (itinerary_setup.target_entity) {
    target_entity = itinerary_setup.target_entity;
    // This is deleted so as to not save it in the CRS DB. It has to be saved
    // as a separate field
    delete itinerary_setup.target_entity;
  }

  const custom_cancellation_policy = state.tripPlannerCxlReducer;

  const trip_instance = {
    current_step: state.tripPlannerCurrentStep,
    pricing_data: {},
    external_reference,
    target_entity,
    itinerary_setup,
    origin_data,
    return_data,
    destinations,
    transportation_preferences: state.tripPlannerTrpPrefReducer,
    accommodation_preferences: state.tripPlannerAccPref,
    accommodation_pax_preferences: state.tripPlannerAccPax,
    transfer_preferences: state.tripPlannerTrfPref,
    transportation_data: { flights, ferries, trains },
    trip_legs,
    accommodation_data: accommodations,
    accommodation_priorities: state.tripPlannerAccPriorities,
    transfer_data: transfers,
    addon_data: addOns,
    activity_data,
    dbd_custom_srv_data: dbdCustomSrvs,
    acc_custom_srv_data: accCustomSrvs,
    dbd_addhoc_srv_data: dbdAddhocSrvs,
    trp_addhoc_srv_data: trpAddhocSrvs,
    trf_addhoc_srv_data: trfAddhocSrvs,
    acc_addhoc_srv_data: accAddhocSrvs,
    trip_type,
    trip_pricing,
    trip_instance_id,
    trip_instance_type,
    detailed_pricing_data,
    dbd_descriptions: dbdDescriptions,
    link: linked_offer,
    ai_dialogs,
    custom_cancellation_policy,
  };

  return trip_instance;
}

export const saveTrip = (options) => async (dispatch, getState) => {
  const { nonUi = false } = options || {};
  const state = getState();
  const trip_instance = prepareTripInstancePayload(state);

  if (!nonUi) await dispatch(tripPlannerSavingTrips());

  var fn = tripSave;
  var id = null;
  if (state?.tripPlannerInstanceData?.id) {
    id = state.tripPlannerInstanceData.id;
    fn = tripUpdate;
  }

  try {
    const data = await fn(trip_instance, id);

    if (!nonUi) dispatch(tripPlannerSavedTrips());

    dispatch(
      updateTripInstanceData({
        id: data.id,
        created: data.created,
        edited: data.edited,
        creator: data.creator,
      })
    );
    return data.id;
  } catch (error) {
    notifyCommonError(
      "We were unable to save this trip plan. We are sorry for the inconvenience!"
    );
    if (!nonUi) dispatch(tripPlannerCancelControlPanelRequest());
    return false;
  }
};

export const tripPlannerSavingTrips = () => {
  return { type: TRIPPLANNER_SAVING_TRIP };
};

export const tripPlannerSavedTrips = () => {
  return { type: TRIPPLANNER_SAVED_TRIP };
};

export const tripPlannerCancelControlPanelRequest = () => {
  return { type: TRIPPLANNER_CANCEL_CONTROL_PANEL_REQUEST };
};

export const saveAsTrip = (options) => async (__, getState) => {
  const { title, usage, link } = options;

  const state = getState();
  const trip_instance = prepareTripInstancePayload(state);
  const { id } = state.tripPlannerInstanceData;
  const trip_type = tripModeMapping({ usage });

  //Update the usage and trip_type of the trip.
  trip_instance.itinerary_setup["usage"] = usage;
  trip_instance.itinerary_setup["title"] = title;
  trip_instance.trip_type = trip_type;

  if (link) trip_instance.link = id;

  try {
    const data = await tripSave(trip_instance);
    return data.id;
  } catch (error) {
    error.ovrdErrHandle && error.ovrdErrHandle();
    notifyCommonError(
      "We were unable to save this trip plan. We are sorry for the inconvenience!"
    );
    return false;
  }
};

export const saveMktTrip = () => async (dispatch, getState) => {
  const state = getState();
  var trip_instance = prepareTripInstancePayload(state);
  trip_instance["distribution_periods"] =
    state.tripPlannerMktDistributionPeriods;
  trip_instance["stop_sales"] = state.tripPlannerMktStopSales;
  trip_instance["inclusions_exclusions"] = state.tripPlannerMktIncExc;
  trip_instance["highlights"] = state.tripPlannerMktHighlights;
  trip_instance["booking_information"] = state.tripPlannerMktBookingInfo;
  trip_instance["content"] = {
    ...state.tripPlannerMktPkgContent,
    ..._.pick(
      state.tripPlannerMktPkgContentModal,
      "templatecolors",
      "templates"
    ),
  };
  trip_instance["mkt_distribution_setup"] =
    state.tripPlannerMktDistributionSetup;
  trip_instance["order"] = state.tripPlannerPkgMetadata.order;
  trip_instance["themes"] = state.tripPlannerPkgMetadata.themes;
  trip_instance["rating"] = state.tripPlannerPkgMetadata.rating;

  await dispatch(tripPlannerSavingTrips());

  var fn = tripSave;
  var id = null;

  if (state?.tripPlannerInstanceData?.id) {
    id = state.tripPlannerInstanceData.id;
    fn = tripUpdate;
  }

  try {
    const data = await fn(trip_instance, id);
    dispatch(tripPlannerSavedTrips());

    dispatch(
      updateTripInstanceData({
        id: data.id,
        created: data.created,
        edited: data.edited,
        creator: data.creator,
      })
    );
    return true;
  } catch (error) {
    error.ovrdErrHandle && error.ovrdErrHandle();
    notifyCommonError(
      "We were unable to save this trip plan. We are sorry for the inconvenience!"
    );
    dispatch(tripPlannerCancelControlPanelRequest());
    return false;
  }
};

export const requestUpdateOffer = () => {
  return { type: TRIPPLANNER_REQUEST_UPDATE_OFFER };
};

export const hideUpdateOfferModal = () => {
  return { type: TRIPPLANNER_REQUEST_UPDATE_OFFER_HIDE_MODAL };
};

export const loadingUpdateOfferModal = () => {
  return { type: TRIPPLANNER_REQUEST_UPDATE_OFFER_LOADING };
};

export const idleUpdateOfferModal = () => {
  return { type: TRIPPLANNER_REQUEST_UPDATE_OFFER_IDLE };
};

export const updateOffer = () => async (dispatch, getState) => {
  const state = getState();
  var { version } = state.tripPlannerInstanceData;
  const guestsInfo = state.tripPlannerGuestsInfo;
  const trip_instance = prepareTripInstancePayload(state);

  if (!_.isEmpty(guestsInfo)) trip_instance["guest_information"] = guestsInfo;

  if (!version) {
    trip_instance["version"] = 1;
  } else {
    trip_instance["version"] = version + 1;
  }

  const { id } = state.tripPlannerInstanceData;
  const { offer_emails, validity, offer_title, notes } =
    state.tripPlannerOfferInstanceData;
  trip_instance["offer_emails"] = offer_emails;
  trip_instance["offer_email"] = offer_emails?.[0] ?? "";
  trip_instance["validity"] = validity;
  trip_instance["offer_title"] = offer_title;
  trip_instance["notes"] = notes;
  if (state.tripPlannerOfferInstanceData?.terms_and_conditions) {
    trip_instance["terms_and_conditions"] =
      state.tripPlannerOfferInstanceData.terms_and_conditions;
  }

  try {
    await dispatch(loadingUpdateOfferModal());
    const data = await updateTripPlanOffer(id, trip_instance);

    dispatch(
      updateTripInstanceData({
        id: data.id,
        created: data.created,
        edited: data.edited,
        creator: data.creator,
        version: data.version,
      })
    );
  } catch (error) {
    notifyCommonError(
      "We were unable to update this offer. We are sorry for the inconvenience!"
    );
  } finally {
    dispatch(hideUpdateOfferModal());
  }
};

export const updateTripInstanceData = (data) => {
  return { type: TRIPPLANNER_INSTANCE_DATA_UPDATE, data };
};
