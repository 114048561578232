import React, { useEffect } from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import { variables } from "@src/jsssetup";
import { NormalInputField } from "@src/components/forms";
import { useFormikContext } from "formik";
import { useQueryClient } from "@tanstack/react-query";

export const sectionCommonStyles = {
  background: "#F6F8FA",
  borderRadius: variables.double_gap,
  padding: variables.double_gap,
  position: "relative",
};

const SectionHeaderStyles = createUseStyles({
  SectionHeader: (props) => ({
    display: props.hasChildren ? "grid" : "block",
    gridTemplateColumns: "1fr max-content",
    color: variables.colors.easy.orange,
    fontSize: "large",
    paddingBottom: variables.normal_gap,
    textTransform: "capitalize",
  }),
});
export const SectionHeader = ({ text, children }) => {
  const classes = SectionHeaderStyles({
    hasChildren: React.Children.count(children) > 0,
  });

  return (
    <div className={classes.SectionHeader}>
      {text}
      {children}
    </div>
  );
};
SectionHeader.defaultProps = {
  text: "",
};
SectionHeader.propTypes = {
  text: PropTypes.string,
  children: PropTypes.node,
};

const SectionSubHeaderStyles = createUseStyles({
  SectionSubHeader: (props) => ({
    display: props.hasChildren ? "grid" : "block",
    gridTemplateColumns: "1fr max-content",
    color: variables.colors.easy.orange,
    fontSize: "small",
    paddingBottom: variables.normal_gap,
    textTransform: "capitalize",
  }),
});
export const SectionSubHeader = ({ text, children }) => {
  const classes = SectionSubHeaderStyles({
    hasChildren: React.Children.count(children) > 0,
  });

  return (
    <div className={classes.SectionSubHeader}>
      {text}
      {children}
    </div>
  );
};
SectionSubHeader.defaultProps = {
  text: "",
};
SectionSubHeader.propTypes = {
  text: PropTypes.string,
  children: PropTypes.node,
};

const messageStyles = createUseStyles(() => ({
  Message: {
    ...sectionCommonStyles,
    display: "grid",
    gridGap: variables.normal_gap,
  },
  description: {
    fontSize: "small",
  },
}));
export const EmailMessage = () => {
  const classes = messageStyles();

  const { values, setFieldValue } = useFormikContext();
  const queryClient = useQueryClient();

  var invoiceSetup = queryClient.getQueryData(["invoiceInvoiceSetup"]);
  invoiceSetup = invoiceSetup?.data?.results?.[0];

  useEffect(() => {
    if (!invoiceSetup) return;
    if (!values.email.email_subject) {
      setFieldValue(`email.email_subject`, invoiceSetup.subject);
    }
    if (!values.email.email_body) {
      setFieldValue(`email.email_body`, invoiceSetup.email_message);
    }
  }, [invoiceSetup]);

  const subagent = queryClient.getQueryData([
    "invoiceSubagentData",
    values.subagent,
  ]);
  const provider = queryClient.getQueryData([
    "invoiceProviderData",
    values.provider,
  ]);
  const entityData = !provider ? subagent : provider;

  // Initialize values in case of subagent or provider customer type
  useEffect(() => {
    if (values.customer_type === "guest") return;

    if (!entityData) return;
    if (values.email.receiver_email) return;

    setFieldValue(`email.receiver_email`, entityData.email);
  }, [subagent, provider]);

  const addonData = queryClient.getQueryData([
    "invoiceAddonData",
    values.product_reference,
  ]);

  // Initialize values in case of guest customer type
  useEffect(() => {
    if (values.customer_type !== "guest") return;

    // TODO: this is temporary we need to add support for
    // offer guests as well.
    if (values.product_type !== "addon") return;

    const groupLeader = (addonData?.data.guests || [])?.find(
      (guest) => guest.group_leader
    );
    if (!groupLeader) return;
    setFieldValue(`email.receiver_email`, groupLeader.email);
  }, []);

  return (
    <div className={classes.Message}>
      <SectionHeader text="Your email message" />
      <NormalInputField
        label="Recipient’s Email address"
        name="email.receiver_email"
        highlightErrors={true}
        type="email"
      />
      <NormalInputField
        label="CC Email address"
        name="email.cc_email"
        highlightErrors={true}
        type="email"
      />
      <NormalInputField
        label="Subject"
        name="email.email_subject"
        highlightErrors={true}
      />
      <NormalInputField
        label="Message"
        name="email.email_body"
        highlightErrors={true}
        as="textarea"
        extraInputProps={{ rows: 5 }}
        resize={false}
      />
      <div className={classes.description}>
        Invoice will be accompanied with this message.
      </div>
    </div>
  );
};
EmailMessage.defaultProps = {};
EmailMessage.propTypes = {};
