import { variables } from "@src/jsssetup";
import React, { useEffect } from "react";
import { createUseStyles } from "react-jss";
import { NormalSelectField } from "@src/components/forms";
import { useFormikContext } from "formik";
import { InlineIcon } from "@iconify/react";
import { getDateTitle } from "./Charts/Components/GeneralStats";
import PropTypes from "prop-types";

export const handleSelectedDaysChange = (values) => {
  // Update the form values when selectedDays changes
  const value = values.selectedDays;
  if (!value) {
    return { startDate: values.created__gte, endDate: values.created__lte };
  }
  let currentDate = new Date();
  let endDate = new Date(currentDate);
  if (value === "ALL") {
    return { startDate: null, endDate: null };
  } else if (value === "last3w") {
    endDate.setDate(currentDate.getDate() - 21);
  } else if (value === "today") {
    endDate.setDate(currentDate.getDate());
  } else if (value === "last7d") {
    endDate.setDate(currentDate.getDate() - 7);
  } else if (value === "last3m") {
    endDate.setDate(currentDate.getDate() - 90);
  } else if (value === "last12m") {
    endDate.setDate(currentDate.getDate() - 365);
  } else if (value === "mtd") {
    endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  } else if (value === "qtd") {
    const currentMonth = currentDate.getMonth();
    const quarterStartMonth = Math.floor(currentMonth / 3) * 3;
    endDate = new Date(currentDate.getFullYear(), quarterStartMonth, 1);
  } else if (value === "ytd") {
    endDate = new Date(currentDate.getFullYear(), 0, 1);
  }
  return { startDate: endDate, endDate: currentDate };
  // setValues("created__lte", currentDate.toISOString());
  // setValues("created__gte", endDate.toISOString());
};

const styles = createUseStyles({
  ordering: {
    display: "grid",
    gridTemplateColumns: "repeat(3, max-content)",
  },
  pickDays: {
    display: "grid",
    padding: [
      variables.normal_gap,
      0,
      variables.normal_gap,
      variables.normal_gap,
    ],
  },
  showPickedDate: {
    display: "grid",
    gridTemplateColumns: "1fr 9fr",
    alignContent: "center",
    height: "2rem",
    alignSelf: "center",
    width: "13rem",
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "8px",
    backgroundColor: "#FFF3EB",
  },
  pickedDate: {
    display: "grid",
    fontFamily: "futura,sans-serif",
  },
  icon: { display: "grid", alignContent: "center", justifyContent: "center" },
  pickedMonth: {
    "display": "grid",
    "alignContent": "center",
    "paddingLeft": variables.normal_gap,
    "& div": {
      display: "grid",
      alignItems: "center",
    },
  },
  orderForm: {
    display: "grid",
    gridAutoFlow: "column",
    gridGap: variables.normal_gap,
    width: "40%",
  },
});

const Ordering = () => {
  const classes = styles();
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    const { startDate, endDate } = handleSelectedDaysChange(values);

    endDate
      ? setFieldValue("created__lte", endDate.toISOString())
      : setFieldValue("created__lte", null);
    startDate
      ? setFieldValue("created__gte", startDate.toISOString())
      : setFieldValue("created__gte", null);
  }, [values.selectedDays]);
  return (
    <div className={classes.ordering}>
      <div className={classes.pickDays}>
        <NormalSelectField
          name="selectedDays"
          label=""
          options={[
            [null, "-----"],
            ["ALL", "All time"],
            ["today", "Today"],
            ["last7d", "Last 7 days"],
            ["last3w", "Last 3 weeks"],
            ["last3m", "Last 3 months"],
            ["last12m", "Last 12 months"],
            ["mtd", "Month to date"],
            ["qtd", "Quarter to date"],
            ["ytd", "Year to date"],
          ]}
        />
      </div>
      <div className={classes.showPickedDate}>
        <div className={classes.icon}>
          <InlineIcon icon="ic:baseline-calendar-month" />
        </div>
        <div className={classes.pickedDate}>
          {getDateTitle(values.created__gte, values.created__lte)}
        </div>
      </div>
    </div>
  );
};
Ordering.propTypes = {
  filters: PropTypes.object,
  setFilters: PropTypes.func,
};
export default Ordering;
