import {
  INNER_GROUP_POLICY_BEGIN_ADD,
  INNER_GROUP_POLICY_END_ADD,
  INNER_GROUP_POLICY_FETCH_DATA,
  INNER_GROUP_POLICY_BEGIN_FETCH_DATA,
  INNER_GROUP_POLICY_END_FETCH_DATA,
  INNER_GROUP_POLICY_INITIALIZE_LIST_FILTER_FORM,
  INNER_GROUP_POLICY_UPDATE_LIST_FILTER_FORM,
  INNER_GROUP_POLICY_APPLY_LIST_FILTER,
  INNER_GROUP_POLICY_UPDATE_LIST_COLUMNS_FORM,
  INNER_GROUP_POLICY_APPLY_LIST_COLUMNS,
  INNER_GROUP_POLICY_INITIALIZE_LIST_COLUMNS_FORM,
  INNER_GROUP_POLICY_FETCH_OPTIONS,
  INNER_GROUP_POLICY_INITIALIZE_BASE_DATA_EDIT_FORM,
  INNER_GROUP_POLICY_CLEAN_BASE_DATA_EDIT_FORM,
  INNER_GROUP_POLICY_EDIT_BASE_DATA,
  INNER_GROUP_POLICY_VALIDATE_BASE_DATA_EDIT_FORM,
  INNER_GROUP_POLICY_RESET_LIST_FILTER_FORM,
  INNER_GROUP_POLICY_FETCH_GRAPH_DATA,
  //==========================================EDIT=====================================
  INNER_GROUP_POLICY_INIT_BASE_DATA_EDIT_FORM,
  INNER_GROUP_POLICY_FETCH_EDIT_DATA,
  INNER_GROUP_POLICY_LOADING_EDIT_FORM,
  INNER_GROUP_POLICY_LOADED_EDIT_FORM,
} from "@src/actions/Admin/InnerGroupPolicy/types";

import {
  FORMAT_BRANCH_LIST_DATA,
  FORMAT_SUB_AGENT_LIST_DATA,
  FORMAT_INDEPENDENT_AGENT_LIST_DATA,
} from "@src/actions/Admin/GroupPolicy/types";

import { Schema } from "rsuite";

import {
  FORMAT_TRAVEL_SERVICES_OPTIONS,
  FORMAT_TRAVEL_SERVICES_FORM,
} from "@src/actions/Shares/types";

import {
  initialInnerGroupPolicyFilterForm,
  initialInnerGroupPolicyBaseDataForm,
  initialInnerGroupPolicyColumnsSetUp,
} from "@src/forms/Admin/InnerGroupPolicy";

import update from "immutability-helper";
import _ from "lodash";
import { formProcessor, entitiesListSelector } from "@src/reducers/tools";

export const initialInnerGroupPolicyFetchStatus = "IDLE";
export const innerGroupPolicyFetchStatus = (
  state = initialInnerGroupPolicyFetchStatus,
  action
) => {
  switch (action.type) {
    case INNER_GROUP_POLICY_BEGIN_FETCH_DATA:
      return "FETCHING";
    case INNER_GROUP_POLICY_END_FETCH_DATA:
      return "FETCHED";
    default:
      return state;
  }
};

export const initialPolicyList = {
  count: 0,
  next: null,
  previous: null,
  results: [],
  page: 1,
  limit: 20,
  displayLength: 100,
};

export const innerGroupPolicyList = (state = initialPolicyList, action) => {
  switch (action.type) {
    case INNER_GROUP_POLICY_FETCH_DATA:
      return action.data;
    case INNER_GROUP_POLICY_RESET_LIST_FILTER_FORM:
      return { ...state, page: 1 };
    default:
      return state;
  }
};

// columns
export const innerGroupPolicyListColumns = (
  state = entitiesListSelector(initialInnerGroupPolicyColumnsSetUp),
  action
) => {
  switch (action.type) {
    case INNER_GROUP_POLICY_APPLY_LIST_COLUMNS:
      return entitiesListSelector(action.data);
    default:
      return state;
  }
};

export const innerGroupPolicyListColumnsForm = (
  state = initialInnerGroupPolicyColumnsSetUp,
  action
) => {
  switch (action.type) {
    case INNER_GROUP_POLICY_INITIALIZE_LIST_COLUMNS_FORM: {
      var newState = {};
      const tmp = _.cloneDeep(state);
      for (const keys in tmp) {
        // clear form's value
        tmp[keys] = update(tmp[keys], { value: { $set: [] } });
        newState[keys] = update(tmp[keys], { value: { $set: [] } });
        // add value by action data
        action.data.forEach((item) => {
          if (_.find(tmp[keys].choices, { key: item.key })) {
            newState[keys] = update(newState[keys], {
              value: { $push: [item.key] },
            });
          }
        });
      }
      return newState;
    }
    case INNER_GROUP_POLICY_UPDATE_LIST_COLUMNS_FORM:
      return action.data;
    default:
      return state;
  }
};

// filter
export const innerGroupPolicyListFilter = (
  state = initialInnerGroupPolicyFilterForm,
  action
) => {
  switch (action.type) {
    case INNER_GROUP_POLICY_APPLY_LIST_FILTER:
      return action.data;
    case FORMAT_TRAVEL_SERVICES_FORM:
      return update(state, { $merge: action.data });
    case INNER_GROUP_POLICY_RESET_LIST_FILTER_FORM:
      return action.data;
    default:
      return state;
  }
};

export const innerGroupPolicyListFilterForm = (
  state = initialInnerGroupPolicyFilterForm,
  action
) => {
  switch (action.type) {
    case INNER_GROUP_POLICY_INITIALIZE_LIST_FILTER_FORM:
      return action.data;
    case INNER_GROUP_POLICY_RESET_LIST_FILTER_FORM:
      return action.data;
    case INNER_GROUP_POLICY_UPDATE_LIST_FILTER_FORM:
      return formProcessor(
        state,
        action.data.type,
        action.data.name,
        action.data.value
      );
    case FORMAT_TRAVEL_SERVICES_FORM:
      return update(state, { $merge: action.data });
    case FORMAT_TRAVEL_SERVICES_OPTIONS:
      return update(state, { $merge: action.data });
    case INNER_GROUP_POLICY_FETCH_OPTIONS:
      return update(state, { $merge: action.data.travel_entities });
    default:
      return state;
  }
};

//add list
export const innerGroupPolicyBaseDataForm = (
  state = initialInnerGroupPolicyBaseDataForm,
  action
) => {
  switch (action.type) {
    case INNER_GROUP_POLICY_INITIALIZE_BASE_DATA_EDIT_FORM:
      return action.data;
    case INNER_GROUP_POLICY_INIT_BASE_DATA_EDIT_FORM:
      return action.data;
    case INNER_GROUP_POLICY_CLEAN_BASE_DATA_EDIT_FORM:
      return action.data;
    case INNER_GROUP_POLICY_EDIT_BASE_DATA:
      return formProcessor(
        _.merge(state, action.data),
        action.data.type,
        action.data.name,
        action.data.value
      );
    case INNER_GROUP_POLICY_FETCH_OPTIONS:
      return update(state, { $merge: action.data.travel_entities });
    case FORMAT_TRAVEL_SERVICES_FORM:
      return update(state, { $merge: action.data });
    case FORMAT_TRAVEL_SERVICES_OPTIONS:
      return update(state, { $merge: action.data });
    case FORMAT_BRANCH_LIST_DATA:
      return _.cloneDeep({ ...state, ...action.data });
    case FORMAT_SUB_AGENT_LIST_DATA:
      return _.cloneDeep({ ...state, ...action.data });
    case FORMAT_INDEPENDENT_AGENT_LIST_DATA:
      return _.cloneDeep({ ...state, ...action.data });
    default:
      return state;
  }
};

export const initialInnerGroupPolicyAddStatus = "LOADED";
export const innerGroupPolicyAddStatus = (
  state = initialInnerGroupPolicyAddStatus,
  action
) => {
  switch (action.type) {
    case INNER_GROUP_POLICY_BEGIN_ADD:
      return "LOADING";
    case INNER_GROUP_POLICY_END_ADD:
      return "LOADED";
    default:
      return state;
  }
};

export const innerGroupPolicyOptions = (state = {}, action) => {
  switch (action.type) {
    case INNER_GROUP_POLICY_FETCH_OPTIONS:
      return update(state, { $merge: action.data });
    default:
      return state;
  }
};

// validate
export const initialInnerGroupPolicyBaseDataFormErrorsState = { valid: false };
export const innerGroupPolicyPolicyBaseDataFormErrors = (
  state = initialInnerGroupPolicyBaseDataFormErrorsState,
  action
) => {
  const tmp = {};
  Object.entries(initialInnerGroupPolicyBaseDataForm).forEach((e) => {
    tmp[e[0]] = e[1].validator;
  });
  const validator = Schema.Model(tmp);

  switch (action.type) {
    case INNER_GROUP_POLICY_VALIDATE_BASE_DATA_EDIT_FORM: {
      const tmp = {};
      Object.entries(action.data).forEach((e) => {
        tmp[e[0]] = e[1].value;
      });
      const validation = validator.check(tmp);

      const valid = Object.entries(validation).every((e) => !e[1].hasError);
      validation["valid"] = valid;
      return validation;
    }
    default:
      return state;
  }
};

export const innerGroupPolicyGraphData = (state = {}, action) => {
  switch (action.type) {
    case INNER_GROUP_POLICY_FETCH_GRAPH_DATA:
      return action.data;
    default:
      return state;
  }
};

export const innerGroupPolicyEditBaseData = (state = {}, action) => {
  switch (action.type) {
    case INNER_GROUP_POLICY_FETCH_EDIT_DATA:
      return action.data;
    default:
      return state;
  }
};

export const initialInnerGroupPolicyEditFormStatus = false;
export const innerGroupPolicyEditFormStatus = (
  state = initialInnerGroupPolicyEditFormStatus,
  action
) => {
  switch (action.type) {
    case INNER_GROUP_POLICY_LOADING_EDIT_FORM:
      return true;
    case INNER_GROUP_POLICY_LOADED_EDIT_FORM:
      return false;
    default:
      return state;
  }
};
