// ============================ LIST VIEW ============================
export const MY_LIBRARY_ADDONS_FETCH_DATA = "MY_LIBRARY_ADDONS_FETCH_DATA";
export const MY_LIBRARY_ADDONS_LOADING_LIST = "MY_LIBRARY_ADDONS_LOADING_LIST";
export const MY_LIBRARY_ADDONS_IDLE_LIST = "MY_LIBRARY_ADDONS_IDLE_LIST";

// ========================= FILTER ========================
export const MY_LIBRARY_ADDONS_UPDATE_FILTERS =
  "MY_LIBRARY_ADDONS_UPDATE_FILTERS";

//========================DASHBOARD===============================
export const MY_LIBRARY_ADDONS_VIEW_TYPE = "MY_LIBRARY_ADDONS_VIEW_TYPE";
export const MY_LIBRARY_ADDONS_FETCH_GRAPH_DATA =
  "MY_LIBRARY_ADDONS_FETCH_GRAPH_DATA";
export const MY_LIBRARY_ADDONS_BEGIN_FETCH_GRAPH_DATA =
  "MY_LIBRARY_ADDONS_BEGIN_FETCH_GRAPH_DATA";
export const MY_LIBRARY_ADDONS_END_FETCH_GRAPH_DATA =
  "MY_LIBRARY_ADDONS_END_FETCH_GRAPH_DATA";
export const MY_LIBRARY_ADDONS_INIT_GRAPH_TYPE =
  "MY_LIBRARY_ADDONS_INIT_GRAPH_TYPE";
