import { FINANCIAL_INVOICE_LIST_GENERIC_CHANGE } from "@src/actions/Admin/Financial/Invoice/types";
import { commonList } from "@src/reducers/common";

const InvoiceListReducerInitial = {
  ...commonList,
  displayLength: 100,
  filters: {
    source_entity: "",
    target_entity: "",
    source_entity_autocomplete: "",
    target_entity_autocomplete: "",
    status: "",
    page: 1,
    limit: 20,
    ordering: "-created",
  },
};
export const InvoiceListReducer = (
  state = InvoiceListReducerInitial,
  action
) => {
  switch (action.type) {
    case FINANCIAL_INVOICE_LIST_GENERIC_CHANGE: {
      const { filters } = action;
      return { ...state, filters: { ...state.filters, ...filters } };
    }
    default:
      return state;
  }
};
