import { accSetCustomerRemarks } from "./accommodation_actions";
import {
  TRIPPLANNER_SERVICE_COMMENTS_MODAL_SHOW,
  TRIPPLANNER_SERVICE_COMMENTS_MODAL_HIDE,
} from "./types/modal_types";

export const showServiceCommentsModal = (destOrder) => {
  return { type: TRIPPLANNER_SERVICE_COMMENTS_MODAL_SHOW, destOrder };
};
export const hideServiceCommentsModal = (destOrder, remarks) => (dispatch) => {
  if (typeof remarks !== "undefined" && remarks) {
    dispatch(accSetCustomerRemarks(destOrder, remarks));
  }

  dispatch({ type: TRIPPLANNER_SERVICE_COMMENTS_MODAL_HIDE });
};
