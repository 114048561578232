import _ from "lodash";
import { variables } from "@src/jsssetup";
import PropTypes from "prop-types";
import React from "react";
import { createUseStyles } from "react-jss";
import ChatPanel from "./ChatPanel";
import ChatRooms from "./ChatRooms";

const styles = createUseStyles({
  Body: {
    display: "grid",
    gridTemplateColumns: "15rem 1fr",
    gridGap: variables.double_gap,
  },
});
const Body = ({
  preselected_group_uid,
  selectedGroup,
  chatNotifications,
  onSelectRoom,
  setConnectionStatus,
}) => {
  const classes = styles({});
  return (
    <div className={classes.Body}>
      <ChatRooms
        chatNotifications={chatNotifications}
        selected_group_uid={_.get(selectedGroup, "uid")}
        preselected_group_uid={preselected_group_uid}
        onSelectRoom={(data) => onSelectRoom(data)}
      />
      <ChatPanel
        selectedGroup={selectedGroup}
        groupUid={_.get(selectedGroup, "uid")}
        setConnectionStatus={setConnectionStatus}
      />
    </div>
  );
};
Body.propTypes = {
  preselected_group_uid: PropTypes.string,
  selectedGroup: PropTypes.object,
  chatNotifications: PropTypes.array.isRequired,
  onSelectRoom: PropTypes.func.isRequired,
  setConnectionStatus: PropTypes.func.isRequired,
};
export default Body;
