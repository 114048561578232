import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import _ from "lodash";
import { DateTime } from "luxon";
import { variables } from "@src/jsssetup";
import { getCarrierIcon } from "../helpers";
import { Train } from "./Train";
import { TrainPrice } from "./TrainPrice";
import { getSetupFormDataSelector } from "@src/selectors/Project/TripPlanner";
import { useSelector } from "react-redux";

const carrierIconStyles = createUseStyles({
  CarrierIcon: (props) => ({
    ..._.get(props, "customStyles.CarrierIcon", {}),
  }),
  icon: (props) => ({
    width: "100%",
    height: "100%",
    maxHeight: `calc(${variables.navbar_height} / 2)`,
    maxWidth: `calc(${variables.navbar_height} * 2)`,
    ..._.get(props, "customStyles.icon", {}),
  }),
  [`@media ${variables.media.smallscreen}`]: {},
  [`@media ${variables.media.bigscreen}`]: {},
});
export const CarrierIcon = ({ carrier }) => {
  const [icon, setIcon] = useState(null);
  const classes = carrierIconStyles({});

  async function onGetCarrierIcon(carrier) {
    const operatorIcon = await getCarrierIcon(_.lowerCase(carrier));
    setIcon(operatorIcon);
  }

  useEffect(() => {
    onGetCarrierIcon(carrier);
  }, [carrier]);

  return (
    <div className={classes.CarrierIcon}>
      {icon ? (
        <img src={icon} className={classes.icon} />
      ) : (
        _.capitalize(carrier)
      )}
    </div>
  );
};
CarrierIcon.defaultProps = {
  carrier: "",
};
CarrierIcon.propTypes = {
  carrier: PropTypes.string.isRequired,
};

export const SelectedOption = ({ train }) => {
  const segments = train?.segments ?? [];
  const ticketTitle = train.title;
  const offer = (train?.offers ?? []).find((o) => o.selected);
  const price = offer?.amount?.value ?? 0;
  const priceCurrency = offer?.amount?.currency;

  return (
    <div className="SelectedOption">
      {segments.map((segment, idx) => (
        <React.Fragment key={idx}>
          <div className="SelectedOption__header">
            {segment.vehicle.type} #{segment.vehicle.reference}
            <CarrierIcon carrier={segment.marketing_carrier} />
          </div>
          <div className="SelectedOption__body">
            <div className="SelectedOption__body__stationInfo">
              {DateTime.fromISO(segment.departure).toLocal().toFormat("hh:mm")}{" "}
              {segment.origin.label}
            </div>
            <div className="SelectedOption__body__stationInfoDate">
              {DateTime.fromISO(segment.departure).toLocaleString(
                DateTime.DATE_MED_WITH_WEEKDAY
              )}
            </div>
            <br />
            <div className="SelectedOption__body__stationInfo">
              {DateTime.fromISO(segment.arrival).toLocal().toFormat("hh:mm")}{" "}
              {segment.destination.label}
            </div>
          </div>
          {idx === segments.length - 1 && (
            <div className="SelectedOption__footer">
              <div className="SelectedOption__footer__ticketTitle">
                {ticketTitle}
              </div>
              <div className="SelectedOption__footer__price">
                <TrainPrice price={Number(price)} currency={priceCurrency} />
              </div>
            </div>
          )}
          {idx + 1 !== segments.length && (
            <div className="SelectedOption__divider" />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};
SelectedOption.defaultProps = {
  train: {},
};
SelectedOption.propTypes = {
  train: PropTypes.object.isRequired,
};

export const SelectedOptions = ({ goRoute, returnRoute, onSelect }) => {
  const { adults, children } = useSelector((state) =>
    getSetupFormDataSelector(state)
  );
  const seniors = 0;
  const youths = children;

  const goOffer = (goRoute?.offers ?? []).find((o) => o.selected);
  const goRoutePrice = goOffer?.amount?.value ?? 0;
  const returnRoutePrice = 0;
  const roundTripPrice = goRoutePrice + returnRoutePrice;
  const priceCurrency = goOffer?.amount?.currency ?? "";
  const totalPrice = roundTripPrice;

  return (
    <div className="SelectedOptions">
      <div className="SelectedOptions__selectionsContr">
        <div className="SelectedOptions__selectionsContr__header">Selected</div>
        {!!goRoute && <SelectedOption returnRoute={false} train={goRoute} />}
        {!!returnRoute && (
          <React.Fragment>
            {!!goRoute && (
              <div className="SelectedOptions__selectionsContr__divider" />
            )}
            <SelectedOption train={returnRoute} />
          </React.Fragment>
        )}
      </div>
      {!!goRoute && (
        <div className="SelectedOptions__info">
          Price for{" "}
          {adults && adults > 1
            ? `${adults} Adults`
            : adults !== 0 && `${adults} Adult`}
          {(seniors > 0 || youths > 0) && adults !== 0 && ","}{" "}
          {seniors > 1
            ? `${seniors} Seniors`
            : seniors !== 0 && `${seniors} Senior`}
          {youths > 0 && seniors !== 0 && ","}{" "}
          {youths > 1 ? `${youths} Youths` : youths !== 0 && `${youths} Youth`}{" "}
          <span className="SelectedOptions__info__priceLabel">Total</span>
          <span className="SelectedOptions__info__price">
            <TrainPrice price={Number(totalPrice)} currency={priceCurrency} />
          </span>
        </div>
      )}
      <button className="SelectedOptions__bookBtn" onClick={onSelect}>
        Select
      </button>
    </div>
  );
};
SelectedOptions.propTypes = {
  selectedTrain: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
};
