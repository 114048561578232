import axios from "axios";
import { getAuthHeaders } from "../../../request";
import { SISI_API_URL } from "@src/api";

export async function fetchCarRentalReservations({
  limit,
  page_size,
  filters = {},
}) {
  const auth_headers = getAuthHeaders();
  return await axios.get(`${SISI_API_URL}/reservations/car-rental`, {
    params: { limit, page_size, ...filters },
    headers: { ...auth_headers },
  });
}

export async function retrieveCarRentalReservation({ reference }) {
  const auth_headers = getAuthHeaders();
  return await axios.get(
    `${SISI_API_URL}/reservations/car-rental/${reference}/`,
    {
      params: {},
      headers: { ...auth_headers },
    }
  );
}

export async function patchCarRentalReservation({ reference, payload }) {
  const auth_headers = getAuthHeaders();
  return await axios.patch(
    `${SISI_API_URL}/reservations/car-rental/${reference}/`,
    payload,
    {
      params: {},
      headers: { ...auth_headers },
    }
  );
}

export async function getCarRentalStats(params) {
  const auth_headers = getAuthHeaders();
  return await axios.get(`${SISI_API_URL}/reservations/car-rental/stats/`, {
    params,
    headers: { ...auth_headers },
  });
}
