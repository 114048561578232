import React from "react";
import { DateTime } from "luxon";
import { toast } from "react-toastify";
import TransTxt from "@src/components/common/SxFormatMessage";
import _ from "lodash";

/**
 * Takes an object with columns data and transforms it in
 * array with data for pdf and xml creator.
 * @param {object} {tableHeaders} : Object with columns data
 * @returns {array}: Array with data for pdf and xml creator
 */
export function getAvailableReportColumns({ tableHeaders }) {
  return Object.entries(tableHeaders).map((entry) => {
    const [label, valFn] = entry;
    return { label, value: [label, valFn] };
  });
}

/**
 * Takes a date in str iso format and returns a DateTime.DATE_SHORT
 * @param {string} {date}: date ISO string
 * @returns {string}: DateTime.DATE_SHORT
 */
export function shortDater(date) {
  return DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT);
}

/**
 * Takes a date in str iso format and returns a DateTime.DATETIME_SHORT
 * @param {string} {date}: date ISO string
 * @returns {string}: DateTime.DATETIME_SHORT
 */
export function shortDatetimer(date) {
  return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_SHORT);
}

/**
 * Takes a date in str iso format and returns a DateTime.DATE_MED
 * @param {string} {date}: date ISO string
 * @returns {string}: DateTime.DATE_SHORT
 */
export function medDater(date) {
  return DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED);
}

/**
 * Takes a date in str iso format and returns a DateTime.DATETIME_MED
 * @param {string} {date}: date ISO string
 * @returns {string}: DateTime.DATE_SHORT
 */
export function medDateTimer(date) {
  return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_MED);
}

/**
 * Takes a date in str iso format and returns a DateTime.DATETIME_FULL
 * @param {string} {date}: date ISO string
 * @returns {string} DateTime.DATETIME_FULL
 */
export function fullDater(date) {
  return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_FULL);
}

/**
 * Takes a datetime in str iso format and returns a
 *  DateTime.DATETIME_MED_WITH_WEEKDAY
 * @param {string} {date}: date ISO string
 * @returns {string} DateTime.DATETIME_MED_WITH_WEEKDAY
 */
export function medWeekdayDatetimer(date) {
  return DateTime.fromISO(date).toLocaleString(
    DateTime.DATETIME_MED_WITH_WEEKDAY
  );
}

/**
 * Takes a date in str iso format and returns a
 *  Date.DATE_MED_WITH_WEEKDAY
 * @param {string} {date}: date ISO string
 * @returns {string} DateTime.DATE_MED_WITH_WEEKDAY
 */
export function medWeekdayDate(date) {
  return DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
}

/**
 * Takes reservation check in/out and calculates number of nights.
 * @param {string} {cIn}: Check In ISO string
 * @param {string} {cOut}: Check Out ISO string
 * @returns {string} Number of nights
 */
export function stayer(cIn, cOut) {
  if (!cIn || !cOut) return "0 Nights";

  const nights = DateTime.fromISO(cOut)
    .diff(DateTime.fromISO(cIn), "days")
    .toObject().days;
  if (nights === 1) {
    return `1 Night`;
  } else {
    return `${nights} Nights`;
  }
}

/**
 * Takes a Promise error and shows a toast with the error message.
 * @param {*} error
 */
export function showFetchError(error) {
  toast.error(
    <p>
      <TransTxt id="CommonOperatorHelpers__unable_to_fetch_data" />
      <br />
      <span>{_.get(error, "response.data.message")}</span>
      <br />
      <span>{_.get(error, "response.data.error")}</span>
    </p>,
    { duration: 5000 }
  );
}
