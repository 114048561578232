import _ from "lodash";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";
import { CheckPicker, SelectPicker, Tag } from "rsuite";
import { distributionSetupChange } from "@src/actions/Project/TripPlanner";
import { getB2CWebsite } from "@src/api";

class MktDistributionSetupSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      b2c_channels: [],
    };
  }
  async componentDidMount() {
    const { source_entity } = this.props;

    const data = await getB2CWebsite({ source_entity });
    console.log(data);
    const channels = _.get(data, "data.results", []) || [];
    this.setState((p) => ({
      ...p,
      b2c_channels: channels.map((ch) => ({
        label: ch.name,
        value: ch.api_key,
      })),
    }));
  }
  render() {
    const { b2c_channels: available_b2c_channels } = this.state;
    const { entities, sector, availability, b2c_channels } = this.props;
    const { onChange } = this.props;

    return (
      <div className="MktSectionsPanel__status">
        {/* <div className="MktSectionsPanel__status__inputgroup"> */}
        {/*   <span> */}
        {/*     <strong>Elligible B2B Entities</strong> */}
        {/*   </span> */}
        {/*   <CheckPicker */}
        {/*     searchable={false} */}
        {/*     value={entities} */}
        {/*     data={[ */}
        {/*       { label: "Branches", value: "branch" }, */}
        {/*       { label: "Subagents", value: "subagent" }, */}
        {/*       { label: "Independent Agents", value: "independentagent" }, */}
        {/*       { label: "Network Members", value: "member" }, */}
        {/*     ]} */}
        {/*     onChange={function (value) { */}
        {/*       onChange("entities", value); */}
        {/*     }} */}
        {/*   /> */}
        {/* </div> */}
        <div className="MktSectionsPanel__status__inputgroup">
          <span>
            <strong>Market Sector</strong>
          </span>
          <CheckPicker
            searchable={false}
            value={sector}
            data={[
              /* { label: "B2B (Wholesales)", value: "B2B" }, */
              /* { label: "B2C (Retail)", value: "B2CR" }, */
              { label: "B2C (Online)", value: "B2C" },
            ]}
            onChange={function (value) {
              onChange("sector", value);
            }}
          />
        </div>
        <div className="MktSectionsPanel__status__inputgroup">
          <span>
            <strong>B2C Channels</strong>
          </span>
          <CheckPicker
            searchable={false}
            value={b2c_channels}
            data={available_b2c_channels}
            onChange={function (value) {
              onChange("b2c_channels", value);
            }}
          />
        </div>
        <div className="MktSectionsPanel__status__inputgroup">
          <span>
            <strong>Availability Status</strong>
          </span>
          <SelectPicker
            searchable={false}
            value={availability}
            data={[
              { label: "Available", value: "AV" },
              { label: "Unavailable", value: "UV" },
            ]}
            renderValue={(value) =>
              value == "AV" ? (
                <Tag color="green">Available</Tag>
              ) : (
                <Tag color="red">Unavailable</Tag>
              )
            }
            renderMenuItem={(label, item) =>
              item.value == "AV" ? (
                <Tag color="green">Available</Tag>
              ) : (
                <Tag color="red">Unavailable</Tag>
              )
            }
            onChange={function (value) {
              onChange("availability", value);
            }}
          />
        </div>
      </div>
    );
  }
}
MktDistributionSetupSection.propTypes = {
  source_entity: PropTypes.string.isRequired,
  entities: PropTypes.array.isRequired,
  sector: PropTypes.array.isRequired,
  availability: PropTypes.array.isRequired,
  b2c_channels: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  const {
    entities,
    sector,
    availability,
    b2c_channels,
  } = state.tripPlannerMktDistributionSetup;

  const { member_id } = state.userMeta;

  return {
    entities,
    sector,
    availability,
    b2c_channels,
    source_entity: `${member_id}___member`,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onChange: (key, value) => dispatch(distributionSetupChange(key, value)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MktDistributionSetupSection);
