import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";
import Slider from "rc-slider";

import { RangeSlider } from "rsuite";

import { convert, unConvert } from "@src/tools/pricing_tools";

import {
  getSelCurrencySelector,
  getGlobalCurrencySelector,
} from "@src/selectors/Financial";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";
import { use } from "echarts";
import { useFormikContext } from "formik";

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

var PricingFilter = ({
  currency,
  globalCurrency,
  currSymbol,
  maxPrice,
  minPrice,
  fMinPrice,
  fMaxPrice,
}) => {
  if (
    isNaN(minPrice) ||
    isNaN(maxPrice) ||
    minPrice == Infinity ||
    maxPrice == Infinity
  ) {
    return null;
  }

  const miPrice = convert(
    minPrice,
    globalCurrency.notation,
    currency.notation,
    currency.rate
  );
  const maPrice = convert(
    maxPrice,
    globalCurrency.notation,
    currency.notation,
    currency.rate
  );

  const fMin = convert(
    fMinPrice,
    globalCurrency.notation,
    currency.notation,
    currency.rate
  );
  const fMax = convert(
    fMaxPrice,
    globalCurrency.notation,
    currency.notation,
    currency.rate
  );
  const { setFieldValue } = useFormikContext();
  return (
    <div>
      <span>
        <strong>{`${currSymbol}${fMin == 0 ? miPrice : fMin} - ${currSymbol}${
          fMax == 1000000 ? maPrice : fMax
        }`}</strong>
      </span>
      <RangeSlider
        defaultValue={[miPrice, maPrice]}
        min={parseInt(miPrice - 1, 10)}
        max={parseInt(maPrice + 1, 10)}
        value={[fMin, fMax]}
        onChange={(value) => {
          const min = unConvert(
            value[0],
            globalCurrency,
            currency.notation,
            currency.rate
          );

          const max = unConvert(
            value[1],
            globalCurrency,
            currency.notation,
            currency.rate
          );

          setFieldValue("min_price", min);
          setFieldValue("max_price", max);
        }}
      />
    </div>
  );
};
PricingFilter.defaultProps = {
  minPrice: 0,
  maxPrice: 1000000,
  fMinPrice: 0,
  fMaxPrice: 1000000,
};
PricingFilter.propTypes = {
  currency: PropTypes.object.isRequired,
  currSymbol: PropTypes.string.isRequired,
  globalCurrency: PropTypes.object.isRequired,
  minPrice: PropTypes.number.isRequired,
  maxPrice: PropTypes.number.isRequired,
  fMinPrice: PropTypes.number.isRequired,
  fMaxPrice: PropTypes.number.isRequired,
};
const mapStateToProps = (state) => {
  const currency = getSelCurrencySelector(state);
  const globalCurrency = getGlobalCurrencySelector(state);

  return { currency, currSymbol: currency.symbol, globalCurrency };
};
PricingFilter = connect(mapStateToProps, null)(PricingFilter);
export { PricingFilter };
