import _ from "lodash";
import { tableStyles, variables } from "@src/jsssetup";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { Divider, Button, Nav } from "rsuite";
import { Form, Formik } from "formik";
import {
  NormalDestAutocompleteField,
  NormalPOIAutocompleteField,
} from "@src/components/forms";
import {
  fetchDestinationsQuickInfo,
  findNearestDestination,
} from "@src/api/cms";
import { DateTime } from "luxon";
import { poiGeodata } from "@src/api";
const styles = createUseStyles({
  BatchServiceImportOverview: { display: "grid" },
  destinationForm: {
    display: "grid",
    gridTemplateColumns: "1fr auto",
    gridGap: variables.normal_gap,
    alignItems: "end",
  },
  ...tableStyles,
  pricingTable: { display: "grid", justifyItems: "center" },
});
const BatchServiceImportOverview = ({
  selectedItems,
  finalItems,
  children,
  setFinalItems,
}) => {
  const [activeNav, setActiveNav] = useState("destination");
  const [editingIdx, setEditingIdx] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const classes = styles();

  async function retrieveNearBy({ poi, setFieldValue }) {
    setBtnLoading(true);
    const [__, uid] = poi.split("---");
    const result = await poiGeodata({ uid });
    const {
      geodata: { lat, lng },
    } = result;
    const nearest = await findNearestDestination({ lat, lng });
    setFieldValue("nearby_destinations", nearest);
    setBtnLoading(false);
  }

  return (
    <div className={classes.BatchServiceImportOverview}>
      <Nav activeKey={activeNav} appearance="tabs">
        <Nav.Item
          eventKey="destination"
          onClick={() => setActiveNav("destination")}>
          Destination
        </Nav.Item>
        <Nav.Item eventKey="poi" onClick={() => setActiveNav("poi")}>
          Point of Interest
        </Nav.Item>
      </Nav>
      <Formik
        initialValues={{
          destination: "",
          nearby_destinations: [],
          selected_nearby_destination: null,
        }}
        validateOnMount={false}
        validateOnBlur={false}
        validate={(values) => {
          const errors = {};
          if (
            (!values.destination || !values.destination.includes("---")) &&
            !values.selected_nearby_destination
          ) {
            errors["destination"] = "Please select a destination.";
          }

          return errors;
        }}
        onSubmit={async (values) => {
          const { destination, selected_nearby_destination } = values;
          var dest_id = null;
          if (!selected_nearby_destination) {
            dest_id = destination.split("---")[1];
          } else {
            dest_id = selected_nearby_destination;
          }
          const destResult = await fetchDestinationsQuickInfo({
            lvl_ids: [dest_id],
          });
          const dest = _.get(destResult, 0);

          const updatedItems = finalItems.map((item) => {
            item.service_data.destination = dest.query;
            item.service_data.destination_meta = dest;
            return item;
          });
          setFinalItems(updatedItems);
        }}>
        {({ values, isSubmitting, setFieldValue }) => (
          <React.Fragment>
            <Form className={classes.destinationForm}>
              {activeNav === "destination" ? (
                <React.Fragment>
                  {!values.selected_nearby_destination && (
                    <NormalDestAutocompleteField
                      id="BatchServiceImportOverview__NormalDestAutocompleteField"
                      label="Destination"
                      name="destination"
                    />
                  )}
                  <Button
                    type="submit"
                    appearance="primary"
                    loading={isSubmitting}>
                    <strong>Update Destinations</strong>
                  </Button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <NormalPOIAutocompleteField
                    id="BatchServiceImportOverview__NormalPOIAutocompleteField"
                    label="Point of Interest"
                    name="poi"
                  />
                  <Button
                    appearance="primary"
                    loading={btnLoading}
                    onClick={() =>
                      retrieveNearBy({ poi: values.poi, setFieldValue })
                    }>
                    <strong>Find Nearby Destinations</strong>
                  </Button>
                </React.Fragment>
              )}
            </Form>
            <Divider />
            <h5>Services to import: {selectedItems.length}</h5>
            {editingIdx !== null ? (
              <div className={classes.pricingTable}>
                <table className={classes.table}>
                  <thead className={classes.head}>
                    <tr>
                      <th>#</th>
                      <th>External Id</th>
                      <th>From Date</th>
                      <th>To Date</th>
                      <th>Pax Price</th>
                      <th>Unit Price</th>
                      <th>Currency</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.get(
                      finalItems,
                      `${editingIdx}.service_data.pricing`,
                      []
                    ).map((price, idx) => (
                      <tr key={idx}>
                        <td className={classes.cell}>{idx + 1}</td>
                        <td className={classes.cell}>{price.price_id}</td>
                        <td className={classes.cell}>
                          {DateTime.fromISO(price.from_date).toLocaleString(
                            DateTime.DATE_MED
                          )}
                        </td>
                        <td className={classes.cell}>
                          {DateTime.fromISO(price.to_date).toLocaleString(
                            DateTime.DATE_MED
                          )}
                        </td>
                        <td className={classes.cell}>{price.pax_price}</td>
                        <td className={classes.cell}>{price.unit_price}</td>
                        <td className={classes.cell}>{price.currency}</td>
                        <td className={classes.cell}>
                          <Button
                            size="xs"
                            color="red"
                            onClick={() => {
                              setFinalItems((p) =>
                                p.map((item, fidx) => {
                                  if (fidx === editingIdx) {
                                    item.service_data.pricing =
                                      item.service_data.pricing.filter(
                                        (price, pidx) => pidx !== idx
                                      );
                                  }
                                  return item;
                                })
                              );
                            }}>
                            <strong>Remove</strong>
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Button appearance="ghost" onClick={() => setEditingIdx(null)}>
                  <strong>Back</strong>
                </Button>
              </div>
            ) : !values.nearby_destinations.length ? (
              <table className={classes.table}>
                <thead className={classes.head}>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Original Destination</th>
                    <th>New Destination</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {finalItems.map((item, idx) => {
                    const destKeys = new Set([
                      "name_en",
                      "fourth_lvl",
                      "third_lvl",
                      "second_lvl",
                      "first_lvl",
                      "country",
                    ]);
                    const service_data = item.service_data;
                    const dest = Object.entries(
                      _.get(service_data, "destination_meta", {})
                    )
                      .filter((entry) => destKeys.has(entry[0]))
                      .map((entry) => entry[1])
                      .join(" - ");

                    return (
                      <tr key={idx}>
                        <td className={classes.cell}>{idx + 1}</td>
                        <td className={classes.cell}>{service_data.name}</td>
                        <td className={classes.cell}>
                          {service_data.city.name}
                        </td>
                        <td className={classes.cell}>{dest || "N/A"}</td>
                        <td className={classes.cell}>
                          {!dest ? null : (
                            <Button
                              size="xs"
                              appearance="primary"
                              onClick={() => setEditingIdx(idx)}>
                              <strong>Edit Pricing</strong>
                            </Button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <React.Fragment>
                <table className={classes.table}>
                  <thead className={classes.head}>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Distacne</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {values.nearby_destinations.map((dest, idx) => (
                      <tr key={idx}>
                        <td className={classes.cell}>{idx + 1}</td>
                        <td className={classes.cell}>{dest.name}</td>
                        <td className={classes.cell}>{dest.distance}</td>
                        <td className={classes.cell}>
                          <Button
                            appearance="primary"
                            onClick={async () => {
                              await setFieldValue(
                                "selected_nearby_destination",
                                `${dest.type}__${dest.id}`
                              );
                              await setFieldValue("nearby_destinations", []);
                              setActiveNav("destination");
                            }}>
                            <strong>Select</strong>
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Button
                  appearance="ghost"
                  onClick={() => setFieldValue("nearby_destinations", [])}>
                  <strong>Back</strong>
                </Button>
              </React.Fragment>
            )}
            {editingIdx == null &&
              !values.nearby_destinations.length &&
              children}
          </React.Fragment>
        )}
      </Formik>
    </div>
  );
};
BatchServiceImportOverview.propTypes = {
  selectedItems: PropTypes.array.isRequired,
  finalItems: PropTypes.array.isRequired,
  children: PropTypes.object,
  setFinalItems: PropTypes.func.isRequired,
};

export default BatchServiceImportOverview;
