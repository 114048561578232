import _ from "lodash";
import {
  TRIPPLANNER_REMOVE_FERRY_DATA,
  TRIPPLANNER_RESET_FERRY_SESSION_DATA,
  TRIPPLANNER_SAVE_FERRY_DATA,
  TRIPPLANNER_SAVE_FERRY_SESSION_DATA,
  TRIPPLANNER_UPDATE_FERRY_DATA,
} from "@src/actions/Project/TripPlanner/ferries";
import {
  TRIPPLANNER_INIT,
  TRIPPLANNER_LOAD_TRIP,
} from "@src/actions/Project/TripPlanner/types";

const tripPlannerFerriesReducerInitial = { services: [], sessions: [] };
export const tripPlannerFerriesReducer = (
  state = tripPlannerFerriesReducerInitial,
  action
) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return tripPlannerFerriesReducerInitial;
    case TRIPPLANNER_LOAD_TRIP: {
      const { data } = action;

      return (
        data?.transportation_data?.ferries ?? tripPlannerFerriesReducerInitial
      );
    }
    case TRIPPLANNER_RESET_FERRY_SESSION_DATA: {
      return tripPlannerFerriesReducerInitial;
    }
    case TRIPPLANNER_SAVE_FERRY_SESSION_DATA: {
      const { session_data } = action;
      const newState = _.cloneDeep(state);
      newState.sessions.push(session_data);
      return newState;
    }
    case TRIPPLANNER_SAVE_FERRY_DATA: {
      const { ferryData } = action;
      const newState = _.cloneDeep(state);
      if (!newState.services.map((f) => f.token).includes(ferryData.token)) {
        newState.services.push(ferryData);
      }

      return newState;
    }
    case TRIPPLANNER_UPDATE_FERRY_DATA: {
      const { key, data, token } = action;
      const newState = _.cloneDeep(state);
      const ferry = newState.services.find((f) => f.token === token);
      ferry[key] = data;
      return newState;
    }
    case TRIPPLANNER_REMOVE_FERRY_DATA: {
      const { ferryToken } = action;
      const newState = _.cloneDeep(state);
      newState.services = newState.services.filter(
        (s) => s.token !== ferryToken
      );
      return newState;
    }
    default:
      return state;
  }
};
