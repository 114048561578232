import _ from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useContext } from "react";
import { createUseStyles } from "react-jss";
import { Activity } from "../Modals/products/activities/ActivitiesDrawer";
import { Icon, IconButton } from "rsuite";
import { useDispatch, useSelector } from "react-redux";
import { removeActivityFromDay } from "@src/actions/Project/TripPlanner/activities";
import OverviewPrice from "../OverviewPrice";
import { daySrvContainerStyles } from ".";
import { isServiceFilterSelectedSelector } from "@src/selectors/Project/TripPlanner/service_filters";
import { useServiceCardExpansion } from "@src/pages/hooks";
import { getSelectedActivityAttrs } from "../Modals/products/activities/details/ActivityPriceModal";
import {
  getServicePricingSelector,
  srvPriceKeyCreator,
} from "@src/selectors/Project/TripPlanner/pricing";
import { BookChecker } from "../ItineraryOverviewCommon";
import { variables } from "@src/jsssetup";

const dayActivityStyles = createUseStyles({
  DayActivity: (props) => ({
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    gridTemplateColumns:
      props.currentStep === 3 ? "2rem 1fr 6rem" : "2rem 1fr 5rem",
    alignItems: "center",
  }),
  actions: { display: "grid", alignItems: "center" },
});
export const DayActivity = ({ activity }) => {
  const dispatch = useDispatch();
  const onRemove = useCallback(() => {
    dispatch(
      removeActivityFromDay({ date: activity.date, activity_uid: activity.uid })
    );
  }, [activity, dispatch]);

  const { services_collapsed, toggleSelfCollapse } = useServiceCardExpansion();

  const { currentStep, serviceFilterIsSelected } = useSelector((state) => {
    const currentStep = state.tripPlannerCurrentStep;
    const serviceFilterIsSelected = isServiceFilterSelectedSelector(state, {
      service_type: "ACT",
    });
    return { currentStep, serviceFilterIsSelected };
  });
  const classes = dayActivityStyles({ currentStep });

  const { totalPrice, currency } = getSelectedActivityAttrs({
    selectionInfo: activity.selectionInfo,
    bookingData: activity.bookingData,
  });

  const { markup, gross_margin, flat_value } = useSelector((state) =>
    getServicePricingSelector(state, {
      key: srvPriceKeyCreator({ service: activity, service_type: "ACT" }),
    })
  );

  return (
    serviceFilterIsSelected && (
      <div className={classes.DayActivity}>
        {services_collapsed ? (
          <div />
        ) : (
          <div className={classes.actions}>
            {currentStep !== 4 ? (
              <IconButton
                icon={<Icon icon="close" />}
                circle
                size="xs"
                color="red"
                onClick={onRemove}
              />
            ) : (
              <BookChecker
                uid={activity.uid}
                srv_type="ACT"
                disabled={false}
                /* optionable={activity.free_cancellation} */
                optionable={false}
              />
            )}
          </div>
        )}
        <Activity
          activity={activity}
          mode="short"
          services_collapsed={services_collapsed}
          toggleSelfCollapse={toggleSelfCollapse}
        />
        {services_collapsed ? (
          <div />
        ) : (
          <OverviewPrice
            searching={false}
            serviceType="ACT"
            price={totalPrice}
            currency={currency}
            markup={markup}
            gross_margin={gross_margin}
            flat_value={flat_value}
          />
        )}
      </div>
    )
  );
};
DayActivity.propTypes = {
  activity: PropTypes.object.isRequired,
};

const dayActivitiesStyles = createUseStyles({
  DayActivities: { display: "grid", gridGap: variables.normal_gap },
});
const DayActivities = ({ day }) => {
  const classes = dayActivitiesStyles();
  const activities = _.get(day, "services", []).filter(
    (srv) => srv.srvType == "ACT"
  );

  return (
    <div className={classes.DayActivities}>
      {activities.map((act, idx) => (
        <DayActivity key={idx} activity={act.service} />
      ))}
    </div>
  );
};
DayActivities.propTypes = {
  day: PropTypes.object.isRequired,
};

export default DayActivities;
