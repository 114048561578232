import {
  // ============================= FLIGHT ============================
  TRIPPLANNER_FLIGHT_SHOW_RULES_MODAL,
  TRIPPLANNER_FLIGHT_HIDE_RULES_MODAL,
  TRIPPLANNER_FLIGHT_SAVE_RULES,
  TRIPPLANNER_FLIGHT_FETCHING_RULES,
  TRIPPLANNER_FLIGHT_FETCHED_RULES,
  TRIPPLANNER_TRANSPORTATION_CLEAR_DEST_FLIGHT,
} from "./types";

import { Notification } from "rsuite";
import React from "react";

import { fetchFlightRules } from "@src/api/Project/TripPlanner";

import _ from "lodash";

import {
  TRIPPLANNER_RESET_ALL_FLIGHTS,
  TRIPPLANNER_SET_ALL_FLIGHTS,
} from "./types/overview_transportation_types";

export const maxFlightPolls = 60;

export const flightRulesPayloadCreator = (
  flight,
  adults,
  children,
  outbound_option,
  inbound_option
) => {
  const segmentCreator = (segment) => ({
    arrival: segment.destination_airport.iata,
    arrival_datetime: segment.arrival,
    departure: segment.origin_airport.iata,
    departure_datetime: segment.departure,
    booking_class: segment.booking_class,
    carrier: segment.carrier_code,
    flight_number: segment.flight_number,
    fare_base: segment.fare_base,
  });

  const payload = {
    session_id: flight.session_id,
    record_set: flight.record_set,
    language: "EN",
    currency: "EUR",
    source: flight.source,
    departure: flight.origin_airport,
    arrival: flight.destination_airport,
    legs: [{ segs: outbound_option.segments.map((o) => segmentCreator(o)) }],
    passengers: [
      ...new Array(adults).fill(1).map(() => ({
        passenger_type: "Adult",
      })),
      ...new Array(children).fill(1).map(() => ({
        passenger_type: "Child",
      })),
    ],
  };

  if (inbound_option) {
    payload.legs.push({
      segs: inbound_option.segments.map((o) => segmentCreator(o)),
    });
  }

  return payload;
};

export const getFlightRules =
  (
    flight,
    flOrder,
    flight_uid,
    outbound_option_uid,
    inbound_option_uid = null,
    source = "selected"
  ) =>
  async (dispatch, getState) => {
    const state = getState();
    const itnSetupForm = state.tripPlannerItinerarySetupForm;

    const outbound_option = flight.legs[0].find(
      (o) => o.uid == outbound_option_uid
    );

    const inbound_option = _.last(flight.legs).find(
      (o) => o.uid == inbound_option_uid
    );

    const payload = flightRulesPayloadCreator(
      flight,
      itnSetupForm.adults,
      itnSetupForm.children_ages,
      outbound_option,
      inbound_option
    );

    await dispatch(flightRulesFetching());
    dispatch(showFlightRulesModal());

    try {
      const { rules } = await fetchFlightRules(payload);
      await dispatch(flightSaveRules(rules));
    } catch (error) {
      dispatch(hideFlightRulesModal());
      Notification.error({
        title: "Flight Rules Error",
        description: (
          <p>
            We could not fetch <strong>Flight Rules</strong> for this flight. We
            are sorry for the inconvenience.
          </p>
        ),
        duration: 10000,
      });
    } finally {
      dispatch(flightRulesFetched());
    }
  };

export const flightSaveRules = (rules) => {
  return {
    type: TRIPPLANNER_FLIGHT_SAVE_RULES,
    rules,
  };
};

export const flightRulesFetching = () => {
  return { type: TRIPPLANNER_FLIGHT_FETCHING_RULES };
};

export const flightRulesFetched = () => {
  return { type: TRIPPLANNER_FLIGHT_FETCHED_RULES };
};

export const showFlightRulesModal = () => {
  return { type: TRIPPLANNER_FLIGHT_SHOW_RULES_MODAL };
};

export const hideFlightRulesModal = () => {
  return { type: TRIPPLANNER_FLIGHT_HIDE_RULES_MODAL };
};

export const clearDestFlight = (flOrder) => {
  return { type: TRIPPLANNER_TRANSPORTATION_CLEAR_DEST_FLIGHT, flOrder };
};

export const setAllFlights = (flights) => {
  return { type: TRIPPLANNER_SET_ALL_FLIGHTS, flights };
};

export const resetAllFlights = () => {
  return { type: TRIPPLANNER_RESET_ALL_FLIGHTS };
};
