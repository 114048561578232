import {
  TRIPPLANNER_DAYBYDAY_SHOW_ADD_ADDON_DRAWER,
  TRIPPLANNER_DAYBYDAY_HIDE_ADD_ADDON_DRAWER,
  TRIPPLANNER_DAYBYDAY_SET_ADDONS,
  TRIPPLANNER_DAYBYDAY_RESET_ADDONS,
  TRIPPLANNER_DAYBYDAY_CHANGE_FILTERS,
  TRIPPLANNER_DAYBYDAY_TRIP_STORE_ADDONS,
  TRIPPLANNER_DAYBYDAY_TRIP_REMOVE_ADDON,
  TRIPPLANNER_DAYBYDAY_LOADING_ADDON_DRAWER,
  TRIPPLANNER_DAYBYDAY_IDLE_ADDON_DRAWER,
  TRIPPLANNER_DAYBYDAY_CHANGE_ADDON_DRAWER_MODE,
  TRIPPLANNER_DAYBYDAY_ADDON_NOT_FOUND,
  TRIPPLANNER_DAYBYDAY_ADDON_SEARCHING,
  TRIPPLANNER_DAYBYDAY_ADDON_IDLE,
} from "./day_by_day_types";

import {
  getSetupFormDataSelector,
  getSelectedAccSelector,
} from "@src/selectors/Project/TripPlanner";
import { fetchAddonsPrice } from "@src/api/Project/TripPlanner";
import { addOnsMinMaxSelector } from "@src/selectors/Project/TripPlanner/addon_selectors";
import { notifyNoServicesForDate } from "@src/components/common/notifications/DayByDayNotifications";
import { getDateForDay } from "@src/selectors/Project/TripPlanner/day_by_day_selectors";

export const showAddAddonDrawer = (
  destOrders,
  serviceDate,
  day,
  accommodation,
  mode,
  addOnId
) => {
  return {
    type: TRIPPLANNER_DAYBYDAY_SHOW_ADD_ADDON_DRAWER,
    destOrders,
    serviceDate,
    day,
    accommodation,
    mode,
    addOnId,
  };
};

export const hideAddAddonDrawer = () => {
  return { type: TRIPPLANNER_DAYBYDAY_HIDE_ADD_ADDON_DRAWER };
};

export const loadingAddAddonDrawer = () => {
  return { type: TRIPPLANNER_DAYBYDAY_LOADING_ADDON_DRAWER };
};

export const idleAddAddonDrawer = () => {
  return { type: TRIPPLANNER_DAYBYDAY_IDLE_ADDON_DRAWER };
};

export const resetAddOns = () => {
  return { type: TRIPPLANNER_DAYBYDAY_RESET_ADDONS };
};

export const setAddOns = (results, destOrder) => {
  return { type: TRIPPLANNER_DAYBYDAY_SET_ADDONS, results, destOrder };
};

export const getAddOns = () => async (dispatch, getState) => {
  const state = getState();
  const { destOrders, serviceDate } = state.tripPlannerAddOnDrawer;
  const destination = state.tripPlannerDestinations.find(
    (dest) => dest.order == destOrders[0]
  );
  const destination_code = window.btoa(
    `${destination.id}___${destination.type}`
  );
  const execution = serviceDate;
  const { adults, children } = getSetupFormDataSelector(state);

  await dispatch(loadingAddAddonDrawer());

  try {
    const result = await fetchAddonsPrice({
      destination_code,
      execution,
      adults,
      children,
    });

    await dispatch(setAddOns(result.results, destination.order));
    const minMaxPrice = addOnsMinMaxSelector(getState());
    dispatch(changeAddOnFilters("min_max_price", minMaxPrice));
    dispatch(idleAddAddonDrawer());
  } catch (error) {
    notifyNoServicesForDate();
    dispatch(hideAddAddonDrawer());
  }
};

export const requestAddService =
  (destOrders, serviceDate, day) => (dispatch, getState) => {
    const accommodation = getSelectedAccSelector(getState(), {
      destOrder: destOrders[0],
    });

    dispatch(showAddAddonDrawer(destOrders, serviceDate, day, accommodation));
  };

export const viewAddOnDetails =
  (destOrders, serviceDate, day, addOnId) => (dispatch, getState) => {
    const accommodation = getSelectedAccSelector(getState(), {
      destOrder: destOrders[0],
    });

    dispatch(
      showAddAddonDrawer(
        destOrders,
        serviceDate,
        day,
        accommodation,
        "details",
        addOnId
      )
    );
  };

export const changeAddOnFilters = (key, value) => {
  return { type: TRIPPLANNER_DAYBYDAY_CHANGE_FILTERS, key, value };
};

export const selectAddOn = (day, addon) => {
  return { type: TRIPPLANNER_DAYBYDAY_TRIP_STORE_ADDONS, day, addon };
};

export const removeAddOn = (day, id) => {
  return { type: TRIPPLANNER_DAYBYDAY_TRIP_REMOVE_ADDON, day, id };
};

export const changeMode = (mode, addOnId) => {
  return { type: TRIPPLANNER_DAYBYDAY_CHANGE_ADDON_DRAWER_MODE, mode, addOnId };
};

export const didNotFindAddOn = (day, search_uid) => {
  const status = "unavailable";
  return {
    type: TRIPPLANNER_DAYBYDAY_ADDON_NOT_FOUND,
    day,
    search_uid,
    status,
  };
};

export const recalculateAddOn = () => async (dispatch, getState) => {
  const state = getState();
  const { tripPlannerAddOnData } = state;
  const { adults, children } = getSetupFormDataSelector(state);

  for (let day in tripPlannerAddOnData) {
    const addOns = tripPlannerAddOnData[day];

    for (let i = 0; i < addOns.length; i++) {
      const addOn = addOns[i];
      const { search_uid, destOrder, meetingGroupUid, meetingPoint } = addOn;

      const destination = state.tripPlannerDestinations.find(
        (dest) => dest.order == destOrder
      );
      const destination_code = window.btoa(
        `${destination.id}___${destination.type}`
      );

      const execution = getDateForDay(state, { day });

      await dispatch(searchingAddOns(day, search_uid));
      const result = await fetchAddonsPrice({
        destination_code,
        execution,
        adults,
        children,
      });
      await dispatch(idleAddOns(day, search_uid));

      const newAddon = result.results.find(
        (result) => result.search_uid == search_uid
      );

      if (!newAddon) {
        dispatch(didNotFindAddOn(day, search_uid));
      } else {
        newAddon["meetingGroupUid"] = meetingGroupUid;
        newAddon["meetingPoint"] = meetingPoint;
        dispatch(selectAddOn(day, newAddon));
      }
    }
  }
};

export const searchingAddOns = (day, search_uid) => {
  const status = "searching";
  return {
    type: TRIPPLANNER_DAYBYDAY_ADDON_SEARCHING,
    day,
    search_uid,
    status,
  };
};

export const idleAddOns = (day, search_uid) => {
  const status = "idle";
  return { type: TRIPPLANNER_DAYBYDAY_ADDON_IDLE, day, search_uid, status };
};
