import {
  IMAGE_BANK_SHOW_UPLOAD_IMAGE_MODAL,
  IMAGE_BANK_HIDE_UPLOAD_IMAGE_MODAL,
  IMAGE_BANK_LOADING_UPLOAD_IMAGE_MODAL,
  IMAGE_BANK_UPDATE_IMAGE,
  IMAGE_BANK_UPDATE_IMAGE_MODAL_TITLE,
  IMAGE_BANK_LOADED_UPLOAD_IMAGE_MODAL,
  //==================LIST================
  IMAGE_BANK_SAVE_IMAGES_DATA,
  // IMAGE_BANK_LOADING_SAVE_IMAGES_DATA,
  // IMAGE_BANK_LOADED_SAVE_IMAGES_DATA,
  IMAGE_BANK_SAVE_IMAGES_DETAIL,
  //======================FILTERS===============
  IMAGE_BANK_INITIALIZE_LIST_FILTER_FORM,
  IMAGE_BANK_UPDATE_LIST_FILTER_FORM,
  IMAGE_BANK_APPLY_LIST_FILTER,
  IMAGE_BANK_LIST_SET_PAGE,
  IMAGE_BANK_FETCH_LIST,
  IMAGE_BANK_LIST_GENERIC_CHANGE,
} from "@src/actions/MyLibrary/ImageBank/types";

import update from "immutability-helper";
import { formProcessor } from "@src/reducers/tools";

import { initialMyLibraryImagesBankFilterForm } from "@src/forms/MyLibrary/ImagesBank";
import { commonList } from "@src/reducers/common";

export const imageBankListData = (
  state = {
    ...commonList,
    limit: 8,
  },
  action
) => {
  switch (action.type) {
    case IMAGE_BANK_SAVE_IMAGES_DATA:
      return { ...state, ...action.data };
    case IMAGE_BANK_LIST_SET_PAGE: {
      const { page } = action;
      return { ...state, page };
    }
    case IMAGE_BANK_INITIALIZE_LIST_FILTER_FORM:
      return { ...state, page: 1 };
    default:
      return state;
  }
};

export const initialModal = {
  show: false,
  loading: false,
  image: {},
  title: "",
  tags: [],
  mode: "ADD",
};
export const imageBankUploadImageModal = (state = initialModal, action) => {
  switch (action.type) {
    case IMAGE_BANK_SHOW_UPLOAD_IMAGE_MODAL:
      return { ...state, show: true, mode: action.data.mode };
    case IMAGE_BANK_HIDE_UPLOAD_IMAGE_MODAL:
      return initialModal;
    case IMAGE_BANK_LOADING_UPLOAD_IMAGE_MODAL:
      return { ...state, loading: true };
    case IMAGE_BANK_UPDATE_IMAGE: {
      return { ...state, image: action.data };
    }
    case IMAGE_BANK_LOADED_UPLOAD_IMAGE_MODAL:
      return { ...state, loading: false };
    case IMAGE_BANK_UPDATE_IMAGE_MODAL_TITLE: {
      const { key, value } = action;
      return update(state, {
        [key]: { $set: value },
      });
    }
    case IMAGE_BANK_SAVE_IMAGES_DETAIL:
      return {
        ...state,
        ...action.data,
        image: { value: action.data.big_thumbnail_url },
      };
    default:
      return state;
  }
};

export const myLibraryImageBankDetail = (state = {}, action) => {
  switch (action.type) {
    case IMAGE_BANK_SAVE_IMAGES_DETAIL:
      return action.data;
    default:
      return state;
  }
};

// ========================= FILTER RELATION =========================
export const myLibraryImagesBankListFilterForm = (
  state = initialMyLibraryImagesBankFilterForm,
  action
) => {
  switch (action.type) {
    case IMAGE_BANK_INITIALIZE_LIST_FILTER_FORM:
      return initialMyLibraryImagesBankFilterForm;
    case IMAGE_BANK_UPDATE_LIST_FILTER_FORM:
      return formProcessor(
        state,
        action.data.type,
        action.data.name,
        action.data.value
      );
    default:
      return state || {};
  }
};

export const myLibraryImagesBankListFilter = (
  state = initialMyLibraryImagesBankFilterForm,
  action
) => {
  switch (action.type) {
    case IMAGE_BANK_APPLY_LIST_FILTER:
      return action.data;
    case IMAGE_BANK_INITIALIZE_LIST_FILTER_FORM:
      return initialMyLibraryImagesBankFilterForm;
    default:
      return state || {};
  }
};

//====================NEW=========================
export const newImageBankListData = (
  state = {
    ...commonList,
    limit: 6,
    filters: {
      title: "",
      tags: "",
      status: "AC",
    },
  },
  action
) => {
  switch (action.type) {
    case IMAGE_BANK_FETCH_LIST: {
      const { data } = action;
      return { ...state, ...data };
    }
    case IMAGE_BANK_LIST_GENERIC_CHANGE: {
      const { key, value } = action;
      return { ...state, [key]: value };
    }
    default:
      return state;
  }
};
