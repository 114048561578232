export const PAYMENT_METHODS_SHOW_ADD_METHODS_MODAL =
  "PAYMENT_METHODS_SHOW_ADD_METHODS_MODAL";
export const PAYMENT_METHODS_HIDE_ADD_METHODS_MODAL =
  "PAYMENT_METHODS_HIDE_ADD_METHODS_MODAL";

export const PAYMENT_METHODS_LOADING_ADD_METHODS_MODAL =
  "PAYMENT_METHODS_LOADING_ADD_METHODS_MODAL";
export const PAYMENT_METHODS_IDLE_ADD_METHODS_MODAL =
  "PAYMENT_METHODS_IDLE_ADD_METHODS_MODAL";

export const PAYMENT_METHODS_CHANGE_PAYMENT_METHODS =
  "PAYMENT_METHODS_CHANGE_PAYMENT_METHODS";
export const PAYMENT_METHODS_GET_PAYMENT_METHODS_LIST =
  "PAYMENT_METHODS_GET_PAYMENT_METHODS_LIST";
export const PAYMENT_METHODS_BEGIN_LIST = "PAYMENT_METHODS_BEGIN_LIST";
export const PAYMENT_METHODS_END_LIST = "PAYMENT_METHODS_END_LIST";
export const PAYMENT_METHODS_APPLY_LIST_COLUMNS =
  "PAYMENT_METHODS_APPLY_LIST_COLUMNS";
export const PAYMENT_METHODS_SHOW_PAYMENT_MODAL =
  "PAYMENT_METHODS_SHOW_PAYMENT_MODAL";
export const PAYMENT_METHODS_HIDE_PAYMENT_MODAL =
  "PAYMENT_METHODS_HIDE_PAYMENT_MODAL";
export const PAYMENT_METHODS_LOADING_EDIT_METHODS_MODAL =
  "PAYMENT_METHODS_LOADING_EDIT_METHODS_MODAL";
export const PAYMENT_METHODS_IDLE_EDIT_METHODS_MODAL =
  "PAYMENT_METHODS_IDLE_EDIT_METHODS_MODAL";
export const PAYMENT_METHODS_GET_STRIPE_INIT_DATA =
  "PAYMENT_METHODS_GET_STRIPE_INIT_DATA";
export const PAYMENT_METHODS_LOADING_GET_STRIPE_INIT_DATA =
  "PAYMENT_METHODS_LOADING_GET_STRIPE_INIT_DATA";
export const PAYMENT_METHODS_IDLE_GET_STRIPE_INIT_DATA =
  "PAYMENT_METHODS_IDLE_GET_STRIPE_INIT_DATA";
