import _ from "lodash";
import TransTxt from "@src/components/common/SxFormatMessage";

import { formatToDateTime } from "@src/tools/date_tools";
import { getDisplayNameFromOptions } from "@src/processors/columnsDataProcessor";

import React from "react";

import ClipboardCopier from "sisi_js_components/lib/components/ClipboardCopier/ClipboardCopier";
import { naString } from "@src/tools/string_tools";

export const initialPayableFilterForm = {
  due_date: {
    value: [],
    verbose: "DUE DATE",
  },
  product_type: {
    value: "",
    verbose: "PRODUCT TYPE",
    data: [
      {
        value: "ACC",
        label: "Accommodation",
      },
      {
        value: "TR",
        label: "Transportation",
      },
      {
        value: "TRF",
        label: "Transfer",
      },
      {
        value: "MI",
        label: "Add ons",
      },
    ],
  },
  amount: {
    value: [],
    verbose: "AMOUNT",
  },
  created: {
    value: "",
    verbose: "CREATED",
  },
};

export const initialPayableColumnsSetUp = {
  basic: {
    value: [
      "reference",
      "delivery_date",
      "payment_due",
      "service_category",
      "service_name",
      "payable_to",
      "due_date",
      "amount",
      "created",
    ],
    choices: [
      {
        key: "reference",
        display_name: "Reference",
      },
      {
        key: "delivery_date",
        display_name: <TransTxt id="payable__delivery_date" startCase />,
      },
      {
        key: "payment_due",
        display_name: <TransTxt id="payable__payment_due" startCase />,
      },
      {
        key: "service_category",
        display_name: <TransTxt id="payable__service_category" startCase />,
      },
      {
        key: "service_name",
        display_name: "Service Name",
      },
      {
        key: "payable_to",
        display_name: "Payable To",
      },
      {
        key: "due_date",
        display_name: "Due Date",
      },
      {
        key: "amount",
        display_name: <TransTxt id="payable__amount" startCase />,
      },
      {
        key: "created",
        display_name: "Created",
      },
    ],
  },
};

export const initialPayableColumnsProcessor = {
  reference: {
    key: "reference",
    // eslint-disable-next-line react/display-name
    getter: (data) => {
      return _.get(data, "product_info.reference") ? (
        <ClipboardCopier
          reference={_.get(data, "product_info.reference")}
          size="lg"
        />
      ) : (
        "N/A"
      );
    },
    extraGetter: (data) => _.get(data, "product_info.reference"),
  },
  delivery_date: {
    key: "delivery_date",
    getter: (data) => {
      const arr = [];
      const res = _.get(data, "product_info.delivery_dates", []);
      res.forEach((item) => {
        arr.push(
          `${formatToDateTime(item.start_at)} to ${formatToDateTime(
            item.end_at
          )} `
        );
      });

      return arr;
    },
  },
  payment_due: {
    key: "payment_due",
    getter: (data) => formatToDateTime(data.due_date),
  },
  created: {
    key: "created",
    getter: (data) => {
      return formatToDateTime(data.created);
    },
  },
  service_category: {
    key: "service_category",
    getter: (data) => {
      return getDisplayNameFromOptions(data.product_type, [
        { display_name: "Transportation", value: "FL" },
        { display_name: "Transfer", value: "TRF" },
        { display_name: "Accommodation", value: "ACC" },
        { display_name: "Add ons", value: "MI" },
      ]);
    },
  },
  service_name: {
    key: "service_name",
    getter: (data) => naString(_.get(data, "product_info.service_name")),
  },
  payable_to: {
    key: "payable_to",
    getter: (data) =>
      `${_.get(data, "provider.name")} (${_.get(
        data,
        "provider.legal_title"
      )})`,
  },
  due_date: {
    key: "due_date",
    getter: (data) => {
      return formatToDateTime(_.get(data, "due_date", ""));
    },
  },
  amount: {
    key: "amount",
    getter: (data, options) => {
      const currencyArr = options[0];
      const curreny = data.currency;
      const count = Math.abs(data.amount);

      const symbol =
        (
          _.find(currencyArr, (item) => {
            return item.notation == curreny;
          }) || {}
        ).symbol || "";

      return `${symbol}${count}`;
    },
  },
};

export const initialPayableColumnsMapping = {
  reference: {
    key: "reference",
    width: 170,
  },
  delivery_date: {
    key: "delivery_date",
    fixed: false,
    width: 350,
  },
  service_category: {
    key: "service_category",
    fixed: false,
    width: 200,
  },
  service_name: {
    key: "service_name",
    fixed: false,
    width: 200,
  },
  payable_to: {
    key: "payable_to",
    fixed: false,
    width: 170,
  },
  due_date: {
    key: "due_date",
    fixed: false,
    width: 200,
  },
  amount: {
    key: "amount",
    fixed: false,
    width: 200,
  },
  created: {
    key: "created",
    fixed: false,
    width: 200,
  },
};
