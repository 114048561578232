import {
  BIDDING_REQUESTS_FOR_MEMBER_INITIALIZE_BASIC_FILTER_DATA,
  BIDDING_REQUESTS_FOR_MEMBER_EDIT_BASIC_FILTER_DATA_FORM,
  BIDDING_REQUESTS_FOR_MEMBER_CHANGE_VIEW_MODEL,
  BIDDING_REQUESTS_FOR_MEMBER_APPLY_LIST_COLUMNS,
  BIDDING_REQUESTS_FOR_MEMBER_INITIALIZE_LIST_COLUMNS_FORM,
  BIDDING_REQUESTS_FOR_MEMBER_UPDATE_LIST_FILTER_FORM,
  BIDDING_REQUESTS_FOR_MEMBER_APPLY_LIST_FILTER,
  BIDDING_REQUESTS_FOR_MEMBER_INITIALIZE_LIST_FILTER_FORM,
  BIDDING_REQUESTS_FOR_MEMBER_RESET_LIST_FILTER_FORM,
  BIDDING_REQUESTS_FOR_MEMBER_BEGIN_FETCH_DATA,
  BIDDING_REQUESTS_FOR_MEMBER_END_FETCH_DATA,
  BIDDING_REQUESTS_FOR_MEMBER_FETCH_DATA,
  BIDDING_REQUESTS_FOR_MEMBER_DETAIL,
  BIDDING_REQUESTS_FOR_MEMBER_BEGIN_FETCH_DETAIL,
  BIDDING_REQUESTS_FOR_MEMBER_END_FETCH_DETAIL,
  BIDDING_REQUESTS_FOR_MEMBER_FETCH_GRAPH_DATA,
} from "@src/actions/Project/Archive//BiddingRequestsForMember/types";

import { FORMAT_TRAVEL_SERVICES_FORM } from "@src/actions/Shares/types";

import { initialBasicFilterForm } from "@src/forms/Project/Archive";
import {
  initialBiddingRequestForMemberColumnsSetUp,
  initialBiddingRequestForMemberFilterForm,
} from "@src/forms/Project/Archive/BiddingRequestsForMember";

import update from "immutability-helper";
import _ from "lodash";

import { formProcessor, entitiesListSelector } from "@src/reducers/tools";

const initialBiddingRequestForMemberList = {
  count: 0,
  next: null,
  previous: null,
  results: [],
  page: 1,
  limit: 6,
  displayLength: 100,
};

export const biddingRequestForMemberList = (
  state = initialBiddingRequestForMemberList,
  action
) => {
  switch (action.type) {
    case BIDDING_REQUESTS_FOR_MEMBER_FETCH_DATA:
      return action.data;
    default:
      return state;
  }
};

export const biddingRequestForMemberFetchStatus = (state = "IDLE", action) => {
  switch (action.type) {
    case BIDDING_REQUESTS_FOR_MEMBER_BEGIN_FETCH_DATA:
      return "FETCHING";
    case BIDDING_REQUESTS_FOR_MEMBER_END_FETCH_DATA:
      return "FETCHED";
    default:
      return state;
  }
};

export const biddingRequestForMemberBasicFilterData = (
  state = initialBasicFilterForm,
  action
) => {
  switch (action.type) {
    case BIDDING_REQUESTS_FOR_MEMBER_INITIALIZE_BASIC_FILTER_DATA:
      return action.data;
    case BIDDING_REQUESTS_FOR_MEMBER_EDIT_BASIC_FILTER_DATA_FORM:
      return update(state, {
        [action.data.name]: { value: { $set: action.data.value } },
      });
    default:
      return state;
  }
};

export const changeBiddingRequestForMemberViewModel = (
  state = false,
  action
) => {
  switch (action.type) {
    case BIDDING_REQUESTS_FOR_MEMBER_CHANGE_VIEW_MODEL:
      return action.data;
    default:
      return state;
  }
};

// columns
export const biddingRequestForMemberListColumns = (
  state = entitiesListSelector(initialBiddingRequestForMemberColumnsSetUp),
  action
) => {
  switch (action.type) {
    case BIDDING_REQUESTS_FOR_MEMBER_APPLY_LIST_COLUMNS:
      return entitiesListSelector(action.data);
    default:
      return state;
  }
};

export const biddingRequestForMemberListColumnsForm = (
  state = initialBiddingRequestForMemberColumnsSetUp,
  action
) => {
  switch (action.type) {
    case BIDDING_REQUESTS_FOR_MEMBER_INITIALIZE_LIST_COLUMNS_FORM:
      var newState = {};

      for (const key in state) {
        // clear form's value
        state[key] = update(state[key], { value: { $set: [] } });
        newState[key] = update(state[key], { value: { $set: [] } });
        // add value by action data
        action.data.forEach((item, index) => {
          if (_.find(state[key].choices, { value: item.value })) {
            newState[key] = update(newState[key], {
              value: { $push: [item.value] },
            });
          }
        });
      }

      return newState;
    default:
      return state;
  }
};

// filter
export const biddingRequestForMemberListFilter = (
  state = initialBiddingRequestForMemberFilterForm,
  action
) => {
  switch (action.type) {
    case BIDDING_REQUESTS_FOR_MEMBER_APPLY_LIST_FILTER:
      return action.data;
    case FORMAT_TRAVEL_SERVICES_FORM:
      return update(state, { $merge: action.data });
    case BIDDING_REQUESTS_FOR_MEMBER_RESET_LIST_FILTER_FORM:
      return action.data;
    default:
      return state;
  }
};

export const biddingRequestForMemberListFilterForm = (
  state = initialBiddingRequestForMemberFilterForm,
  action
) => {
  switch (action.type) {
    case BIDDING_REQUESTS_FOR_MEMBER_INITIALIZE_LIST_FILTER_FORM:
      return action.data;
    case BIDDING_REQUESTS_FOR_MEMBER_RESET_LIST_FILTER_FORM:
      return action.data;
    case BIDDING_REQUESTS_FOR_MEMBER_UPDATE_LIST_FILTER_FORM:
      return formProcessor(
        state,
        action.data.type,
        action.data.name,
        action.data.value
      );
    case FORMAT_TRAVEL_SERVICES_FORM:
      return update(state, { $merge: action.data });
    default:
      return state;
  }
};

export const biddingRequestDetail = (state = {}, action) => {
  switch (action.type) {
    case BIDDING_REQUESTS_FOR_MEMBER_DETAIL:
      return action.data;
    default:
      return state;
  }
};

export const biddingRequestForMemberDetailStatus = (state = "IDLE", action) => {
  switch (action.type) {
    case BIDDING_REQUESTS_FOR_MEMBER_BEGIN_FETCH_DETAIL:
      return "FETCHING";
    case BIDDING_REQUESTS_FOR_MEMBER_END_FETCH_DETAIL:
      return "FETCHED";
    default:
      return state;
  }
};

export const biddingRequestForMemberGraphData = (state = {}, action) => {
  switch (action.type) {
    case BIDDING_REQUESTS_FOR_MEMBER_FETCH_GRAPH_DATA:
      return action.data;
    default:
      return state;
  }
};
