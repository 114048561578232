import PropTypes from "prop-types";
import React from "react";
import { SelectPicker } from "rsuite";
import _ from "lodash";
import { point, distance } from "@turf/turf";
import { WhisperHelper } from "@src/components/common/ui_helpers";

export default class AddOnDetailsFilters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }
  componentDidMount() {
    this.setState(
      (p) => ({ ...p, data: this.getPickUpData() }),
      () => {
        this.props.onSelectMeetingPoint(_.get(this.state, "data.0.value"));
      }
    );
  }
  componentDidUpdate(pp, ps) {
    const { data } = this.state;
    const { addon, onSelectMeetingPoint } = this.props;

    if (!pp.addon && addon) {
      this.setState((p) => ({ ...p, data: this.getPickUpData() }));
    }

    if (!ps.data.length && data.length) {
      onSelectMeetingPoint(_.get(this.state, "data.0.value"));
    }
  }
  getDistance(fromPoint, lat, lng) {
    if (!fromPoint) {
      return null;
    }

    var ddistance = null;
    const ppoint = point([lat, lng]);
    ddistance = parseFloat(distance(fromPoint, ppoint).toFixed(1));
    return ddistance;
  }
  setDistance(tmp, distance) {
    if (distance !== null || typeof distance !== "undefined") {
      tmp["distance"] = distance;
      tmp["label"] = `${tmp["label"]} (${distance}km)`;
    }

    return tmp;
  }
  getPickUpData() {
    const { addon, accGeodata } = this.props;

    var accPoint = null;
    if (!_.isEmpty(accGeodata)) {
      accPoint = point([accGeodata.lat, accGeodata.lng]);
    }

    const data = _.flatten(
      _.get(addon, "groups", []).map((group) => {
        return [
          ...group.points.custom.map((poi) => {
            const ddistance = this.getDistance(
              accPoint,
              _.get(poi, "geodata.lat", 0),
              _.get(poi, "geodata.lng", 0)
            );

            var tmp = this.setDistance(
              {
                label: poi.query_value,
                value: `${group.uid}___custom___${poi.id}`,
                group_uid: group.uid,
                role: "Custom POI",
              },
              ddistance
            );

            return tmp;
          }),
          ...group.points.airports.map((airport) => {
            return {
              label: airport.name_en,
              value: `${group.uid}___airport___${airport.iata}`,
              group_uid: group.uid,
              role: "Airport",
            };
          }),
          ...group.points.hotels.map((hotel) => {
            const ddistance = this.getDistance(accPoint, hotel.lat, hotel.lng);

            var tmp = this.setDistance(
              {
                label: hotel.name,
                value: `${group.uid}___hotel___${hotel.id}`,
                group_uid: group.uid,
                role: "Hotel",
              },
              ddistance
            );

            return tmp;
          }),
        ];
      })
    ).sort((a, b) => {
      return _.get(a, "distance", 1000) - _.get(b, "distance", 1000);
    });
    return data;
  }
  render() {
    const { data } = this.state;
    const { meetingGroupUid, onSelectMeetingPoint } = this.props;
    return (
      <div className="TripDrawer__filters AddOnDetailsFilters">
        <div className="TripDrawer__filters__inputs">
          <div className="TripDrawer__filters__inputs__input">
            <span className="TripDrawer__filters__inputs__input__label">
              <strong>
                Pick-up Point{" "}
                <WhisperHelper msg="The distance at the end of each choice indicates the distance from your selected accommodation." />
              </strong>
            </span>
            <SelectPicker
              data={data}
              groupBy="role"
              value={meetingGroupUid ? meetingGroupUid : null}
              onChange={function (value) {
                onSelectMeetingPoint(value);
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

AddOnDetailsFilters.propTypes = {
  addon: PropTypes.object.isRequired,
  accGeodata: PropTypes.object.isRequired,
  meetingGroupUid: PropTypes.string,
  onSelectMeetingPoint: PropTypes.func.isRequired,
};
