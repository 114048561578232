import { AutoComplete, InputGroup, Icon } from "rsuite";

import { fetchAirline as fetchAirlineAPI } from "@src/api/Project/Bidding";

import update from "immutability-helper";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";

export default class AirlineAutoComplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: false, airlines: [], keyword: "" };

    this.fetchAirline = _.debounce(this.fetchAirline.bind(this), 300);
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    this.setState((p) => update(p, { keyword: { $set: this.props.value } }));
  }
  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (prevProps.value !== "" && value == "") {
      this.setState((p) => update(p, { keyword: { $set: "" } }));
    } else if (prevProps.value == "" && value !== "") {
      this.setState((p) => update(p, { keyword: { $set: value } }));
    }
  }
  fetchAirline() {
    const { keyword } = this.state;

    if (keyword < 2) {
      return;
    }
    this.setState((p) => update(p, { loading: { $set: true } }));

    fetchAirlineAPI({ keyword }).then((res) => {
      res.results.forEach((item) => {
        item.value = item.id.toString();
        item.label = `(${item.iata}) ${item.name}`;
      });

      this.setState((p) =>
        update(p, { airlines: { $set: res.results }, loading: { $set: false } })
      );
    });
  }
  handleChange(keyword) {
    this.setState(
      (p) => update(p, { keyword: { $set: keyword } }),
      () => this.fetchAirline()
    );
  }
  render() {
    const { keyword, airlines, loading } = this.state;
    const { onSelect } = this.props;

    return (
      <InputGroup inside>
        <AutoComplete
          value={keyword}
          data={airlines}
          onChange={(value, event) => {
            event.persist();

            if (event.type !== "change") {
              return;
            }

            this.handleChange(value);
          }}
          onSelect={(value) => {
            this.setState((p) => update(p, { keyword: { $set: value.label } }));
            if (typeof onSelect == "function") {
              onSelect(value);
            }
          }}
        />
        <InputGroup.Addon>
          <Icon icon={loading ? "spinner" : "search"} spin={loading} />
        </InputGroup.Addon>
      </InputGroup>
    );
  }
}

AirlineAutoComplete.propTypes = {
  value: PropTypes.string,
  onSelect: PropTypes.func,
};
