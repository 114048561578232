import _ from "lodash";
import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { FerryPrice } from "./FerryPrice";
import { useDispatch } from "react-redux";
import {
  removeFerryData,
  saveFerryData,
} from "@src/actions/Project/TripPlanner/ferries";

const Prices = ({
  sessionId,
  priceDetails,
  tripLegUid,
  currentFerryToken,
  onClose,
}) => {
  const legs = priceDetails?.legs ?? [];
  const currency = legs[0].accommodations[0].price?.currency ?? "EUR";
  const totalPrice = priceDetails?.price?.value ?? 0;

  const dispatch = useDispatch();
  const onConfirm = useCallback(
    (ferryData, ferryToken) => {
      dispatch(removeFerryData(ferryToken));
      ferryData["check_price_token"] = ferryData.token;
      ferryData.token = ferryToken;
      dispatch(saveFerryData(ferryData));
    },
    [dispatch]
  );

  return (
    <div className="Prices">
      <div className="Prices__header">Selection Prices</div>
      <div className="Prices__content">
        {legs.length > 0 &&
          legs.map((leg, idx) => (
            <div key={idx}>
              <div className="Prices__content__route">{leg.route_name}</div>
              {leg.accommodations.map((opt, idx) => (
                <div key={idx} className="Prices__content__seat">
                  <span> {opt.description}</span>x {opt.quantity}
                </div>
              ))}
              {idx > 0 && idx !== legs.length && (
                <div className="Prices__content__divider" />
              )}
            </div>
          ))}
        <div className="Prices__content__totalPrice">
          <span>Total Price</span>
          <strong>
            <FerryPrice price={Number(totalPrice)} currency={currency} />
          </strong>
        </div>
      </div>
      <div className="Prices__actions">
        <button
          className="Prices__actions__confirmBtn"
          onClick={(e) => {
            e.preventDefault();
            const ferryData = _.cloneDeep(priceDetails);
            ferryData.legUid = tripLegUid;
            ferryData.priced = true;
            ferryData.session_id = sessionId;
            onConfirm(ferryData, currentFerryToken);
            onClose();
          }}>
          Confirm
        </button>
      </div>
    </div>
  );
};
Prices.defaultProps = { priceDetails: {} };
Prices.propTypes = {
  sessionId: PropTypes.string.isRequired,
  priceDetails: PropTypes.object.isRequired,
  tripLegUid: PropTypes.string.isRequired,
  currentFerryToken: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default Prices;
