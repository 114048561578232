import React from "react";
import { Schema, Tag } from "rsuite";
const { StringType, ArrayType, NumberType } = Schema.Types;

import TransTxt from "@src/components/common/SxFormatMessage";

export const initialIndependentAgentsFilterForm = {
  name: {
    value: "",
    verbose: "Name",
    type: "text",
  },
  social_media: {
    value: "",
    verbose: "Social Media",
    type: "text",
  },
  status: {
    value: [],
    verbose: "Status",
    type: "text",
    data: [
      {
        value: "AC",
        display_name: (
          <Tag color="green">
            <strong>Active</strong>
          </Tag>
        ),
        label: (
          <Tag color="green">
            <strong>Active</strong>
          </Tag>
        ),
      },
      {
        value: "IN",
        display_name: (
          <Tag color="red">
            <strong>Inactive</strong>
          </Tag>
        ),
        label: (
          <Tag color="red">
            <strong>Inactive</strong>
          </Tag>
        ),
      },
      {
        value: "CA",
        display_name: (
          <Tag color="violet">
            <strong>Candidate</strong>
          </Tag>
        ),
        label: (
          <Tag color="violet">
            <strong>Candidate</strong>
          </Tag>
        ),
      },
    ],
  },
  share: {
    value: "",
    verbose: "Share",
    type: "text",
  },
  connect: {
    value: "",
    verbose: "Connect",
    type: "text",
  },
  postal_address: {
    value: "",
    verbose: <TransTxt id="address" startCase />,
    placeholder: "Address",
    type: "text",
  },
  postal_city: {
    value: "",
    verbose: <TransTxt id="city" startCase />,
    placeholder: "City",
    type: "text",
  },
  postal_post_code: {
    value: "",
    verbose: <TransTxt id="post_code" startCase />,
    placeholder: "Post Code",
    type: "text",
  },
  postal_region: {
    value: "",
    verbose: <TransTxt id="region" startCase />,
    placeholder: "Region",
    type: "text",
  },
  postal_country: {
    value: "",
    verbose: <TransTxt id="country" startCase />,
    placeholder: "Country",
    type: "text",
  },
  billing_address: {
    value: "",
    verbose: "Billing Address",
    type: "text",
  },
  licence: {
    value: "",
    verbose: "Licence",
    type: "text",
  },
  address: {
    value: "",
    verbose: "Address",
    type: "text",
  },
  id: {
    value: "",
    verbose: <TransTxt id="uid" upperCase />,
    placeholder: "C234uo23uU",
    type: "text",
  },
  business_name: {
    value: "",
    verbose: <TransTxt id="business_name" startCase />,
    placeholder: "Shaun Company",
    type: "text",
  },
  post_code: {
    value: "",
    verbose: "Post Code",
    type: "text",
  },
  region: {
    value: "",
    verbose: "Region",
    type: "text",
  },
  country: {
    value: "",
    verbose: "Country",
    type: "text",
  },
  brand_name: {
    value: "",
    verbose: <TransTxt id="brand_name" startCase />,
    placeholder: "Shaun Company",
    type: "text",
  },
  city: {
    value: "",
    verbose: "City",
    type: "text",
  },
  last_name: {
    value: "",
    verbose: <TransTxt id="last_name" startCase />,
    type: "text",
  },
  first_name: {
    value: "",
    verbose: <TransTxt id="first_name" startCase />,
    type: "text",
  },
  phone: {
    value: "",
    verbose: <TransTxt id="phone" startCase />,
    placeholder: "+(86)2033248897",
    type: "text",
  },
  mobile_phone: {
    value: "",
    verbose: <TransTxt id="mobile_phone" startCase />,
    placeholder: "+(86)18547632876",
    type: "text",
  },
  legal_title: {
    value: "",
    verbose: "Legal Title",
    type: "text",
  },
  registration_code: {
    value: "",
    verbose: <TransTxt id="registration_code" startCase />,
    placeholder: "D3249203U",
    type: "text",
  },
  license_number: {
    value: "",
    verbose: "License Number",
    type: "text",
  },
  tax_office: {
    value: "",
    verbose: <TransTxt id="tax_office" startCase />,
    placeholder: "Guangzhou",
    type: "text",
  },
  email: {
    value: "",
    verbose: <TransTxt id="email" startCase />,
    placeholder: "20218@qq.com",
    type: "text",
  },
  email_xxx: {
    value: "",
    verbose: "Account E-mail",
    placeholder: "998889@qq.com",
    type: "text",
  },
  entity_type: {
    value: [],
    verbose: "Entity Type",
    labelKey: "display_name",
  },
  expertise_type: {
    value: [],
    verbose: "Expertise Type",
    labelKey: "display_name",
  },
  license_type: {
    value: "",
    verbose: "License Type",
  },
  license_type_choices: {
    value: [],
    verbose: "License Type",
    labelKey: "display_name",
  },
  creator: {
    value: "",
    verbose: <TransTxt id="created_by" startCase />,
    placeholder: "Shaun",
    type: "text",
  },
  last_editor: {
    value: "",
    verbose: "Last Editor",
    type: "text",
  },
  created: {
    value: [],
    placement: "left",
    verbose: <TransTxt id="created_date" startCase />,
  },
  edited: {
    value: [],
    placement: "left",
    verbose: <TransTxt id="last_edited_date" startCase />,
  },
  url: {
    value: "",
    verbose: <TransTxt id="url" upperCase />,
    placeholder: "www.12306.com",
    type: "text",
  },
  founding_year: {
    value: "",
    verbose: <TransTxt id="founding_year" startCase />,
    placeholder: "12/10/1998",
    type: "text",
  },
  travel_sector: {
    value: [],
    verbose: "travel_sector",
  },
  travel_services: {
    value: "",
    verbose: "Travel Services",
    type: "text",
  },
};

export const initialIndependentAgentsBaseDataForm = {
  legal_title: {
    value: "",
    required: true,
    verbose: "business_name",
    validator: StringType("Business Name can't only with number").addRule(
      (value) => {
        return isNaN(value);
      },
      "Business Name can't only with number"
    ),
  },
  travel_services: {
    value: [],
    required: false,
    verbose: "Travel Services",
    validator: ArrayType().unrepeatable("Duplicate services cannot appear."),
  },
  currency: {
    value: "",
    verbose: "currency",
    required: false,
    placement: "top",
  },

  registration_code: {
    value: "",
    required: true,
    verbose: "register_code",
    validator: StringType().isRequired("Registration Code is required"),
  },
  name: {
    value: "",
    required: false,
    verbose: "brand_name",
    type: "text",
  },
  founding_year: {
    value: "",
    required: true,
    verbose: "founding_year",
    type: "number",
    validator: NumberType("Founding Year should be number").isRequired(
      "Founding Year is required"
    ),
  },
  last_name: {
    value: "",
    required: true,
    verbose: "last_name",
    type: "text",
    validator: StringType().isRequired("Last Name Name is required"),
  },
  first_name: {
    value: "",
    required: true,
    verbose: "first_name",
    type: "text",
    validator: StringType().isRequired("First Name Name is required"),
  },
  email: {
    value: "",
    required: true,
    verbose: "contact_email",
    validator: StringType()
      .isRequired("A valid Email address is required")
      .isEmail("Please enter a valid email address"),
  },
  url: {
    value: "",
    required: false,
    verbose: "url",
    placeholder: "www.travelshoppe.com",
    validator: StringType().isURL("Please enter a valid url"),
  },
  phone: {
    value: "",
    required: true,
    verbose: "phone",
    placeholder: "+8675688889999",
    validator: StringType().isRequired("A phone number is required."),
  },
  mobile_phone: {
    value: "",
    required: false,
    verbose: "mobile_phone",
    placeholder: "+8618998736279",
    validator: StringType(),
  },
  street_number: {
    value: "",
    required: false,
    verbose: "Str. No.",
    validator: StringType(),
  },
  street: {
    value: "",
    required: false,
    verbose: "address",
    placeholder: "No123,abced Road",
    validator: StringType(),
  },
  postal_street_number: {
    value: "",
    required: false,
    verbose: "Str. No.",
    validator: StringType(),
  },
  postal_street: {
    value: "",
    required: true,
    verbose: "address",
    placeholder: "No123,abced Road",
    validator: StringType().isRequired("Address is required"),
  },
  country_code: {
    value: "",
    data: [],
    required: false,
    verbose: "country",
    searchable: true,
    validator: StringType(),
  },
  region: {
    value: "",
    required: false,
    verbose: "region_province_state",
    validator: StringType(),
  },
  postal_country_code: {
    value: "",
    data: [],
    required: true,
    verbose: "country",
    searchable: true,
    validator: StringType().isRequired("Country is required"),
  },
  postal_region: {
    value: "",
    required: false,
    verbose: "region_province_state",
    validator: StringType(),
  },
  city: {
    value: "",
    required: false,
    verbose: "city",
    validator: StringType("City should be string"),
  },
  post_code: {
    value: "",
    required: false,
    verbose: "post_code",
    validator: StringType(),
  },
  postal_city: {
    value: "",
    required: true,
    verbose: "city",
    validator: StringType("Postal city should be string").isRequired(
      "City is required"
    ),
  },
  postal_post_code: {
    value: "",
    required: false,
    verbose: "post_code",
    validator: StringType(),
  },
  brief_description_en: {
    value: "",
    required: false,
    verbose: "About Us",
    validator: StringType(),
  },
  brief_description_cn: {
    value: "",
    required: false,
    verbose: "About Us",
    validator: StringType(),
  },
  original_image: {
    value: "",
    required: false,
    verbose: "",
    fileName: {},
  },
  lat: {
    value: 0,
    required: false,
  },
  lng: {
    value: 0,
    required: false,
  },
  viewport_arr: {
    value: 0,
    required: false,
  },
  weekdays: {
    value: [false, false, false, false, false, false, false],
    required: false,
    verbose: "days",
    validator: ArrayType(),
  },
  working_time_from: {
    value: 0,
    required: false,
    verbose: "",
  },
  working_time_to: {
    value: 0,
    required: false,
    verbose: "",
  },
  timezone: {
    value: "",
    required: false,
    verbose: "",
  },
};

export const initialIndependentAgentsNatureOfBizForm = {
  expertise_type: {
    value: [],
    required: false,
    verbose: "Expertise Type",
    validator: ArrayType()
      .unrepeatable()
      .minLength(1, "At least one expertise type"),
  },
};

export const defaultOption = {
  basic: {
    value: ["id", "name"],
  },
  contact: {
    value: ["mobile_phone", "last_name", "email", "phone", "postal_address"],
  },
  certification: {
    value: [],
  },
  operation: {
    value: ["expertise_type"],
  },
  services: {
    value: ["main_service", "travel_services"],
  },
  stage: {
    value: ["status"],
  },
};

export * from "./columns";
