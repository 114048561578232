import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Drawer } from "rsuite";
import { Routes } from "./components/Routes";
import { Train } from "./components/Train";
import _ from "lodash";
import { SelectedOptions } from "./components/SelectedOptions";
import { FiltersBar } from "./components/FiltersBar";
import { defaultFilters } from "./helpers";
import { DateTime, Duration } from "luxon";
import {
  handleNonValidImgFn,
  humanizeDurationFromMins,
} from "@src/tools/common_tools";
import { TrainPrice } from "./components/TrainPrice";
import { useQuery } from "@tanstack/react-query";
import { pollTrains } from "@src/api/Project/trains";
import { useLoadingAnimation } from "@src/pages/hooks";
import { useDispatch, useSelector } from "react-redux";
import {
  removeTrain,
  saveTrainsResult,
} from "@src/actions/Project/TripPlanner/trains";

function filterTrains(trainSolutions, filters) {
  var filteredTrains = trainSolutions.filter((train) => {
    if (filters.operator) {
      const isValid = train.segments.some((seg) =>
        seg.marketing_carrier.includes(filters.operator)
      );
      if (!isValid) {
        return false;
      }
    }

    if (filters.min_price) {
      const meetsMinPrice = train.offers.some((offer) => {
        const offerPrice = parseFloat(offer.amount.value);
        return Number(offerPrice) >= Number(filters.min_price);
      });

      if (!meetsMinPrice) {
        return false;
      }
    }

    if (filters.max_price) {
      const meetsMaxPrice = train.offers.some((offer) => {
        const offerPrice = parseFloat(offer.amount.value);
        return Number(offerPrice) <= Number(filters.max_price);
      });

      if (!meetsMaxPrice) {
        return false;
      }
    }

    if (filters.minArrival) {
      const trainArrival = DateTime.fromISO(train.arrival.split("+")[0]);
      const filterArrival = DateTime.fromISO(filters.minArrival);

      const isValid =
        trainArrival.hour * 60 + trainArrival.minute >=
        filterArrival.hour * 60 + filterArrival.minute;
      if (!isValid) {
        return false;
      }
    }

    if (filters.maxArrival) {
      const trainArrival = DateTime.fromISO(train.arrival.split("+")[0]);
      const filterArrival = DateTime.fromISO(filters.maxArrival);

      const isValid =
        trainArrival.hour * 60 + trainArrival.minute <=
        filterArrival.hour * 60 + filterArrival.minute;
      if (!isValid) {
        return false;
      }
    }

    if (filters.minDeparture) {
      const trainDeparture = DateTime.fromISO(train.departure.split("+")[0]);
      const filterDeparture = DateTime.fromISO(filters.minDeparture);

      const isValid =
        trainDeparture.hour * 60 + trainDeparture.minute >=
        filterDeparture.hour * 60 + filterDeparture.minute;
      if (!isValid) {
        return false;
      }
    }

    if (filters.maxDeparture) {
      const trainDeparture = DateTime.fromISO(train.departure.split("+")[0]);
      const filterDeparture = DateTime.fromISO(filters.maxDeparture);

      const isValid =
        trainDeparture.hour * 60 + trainDeparture.minute <=
        filterDeparture.hour * 60 + filterDeparture.minute;
      if (!isValid) {
        return false;
      }
    }

    return true;
  });
  return filteredTrains;
}

export const OverviewAllTrains = ({ sessionId, onClose }) => {
  const [returnRoute, setReturnRoute] = useState(false);
  const [filters, setFilters] = useState(defaultFilters.cast({}));

  const { selectedTrainData } = useSelector((state) => {
    const selectedTrainData = (
      state.tripPlannerTrainsReducer.services ?? []
    ).find((item) => item.session_id === sessionId);
    return { selectedTrainData };
  });
  const legUid = selectedTrainData.legUid;

  const [selectedId, setSelectedId] = useState({
    goTrainId: selectedTrainData.id,
    returnTrainId: "",
  });
  const [selectedOfferId, setSelectedOfferId] = useState({
    goOfferId: (selectedTrainData?.offers ?? []).find((o) => o.selected)?.id,
    returnOfferId: "",
  });

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ["view_all_trains", sessionId],
    queryFn: () => pollTrains({ session_id: sessionId }),
    refetchOnWindowFocus: false,
  });
  const loading = isLoading || isFetching;
  const trains = data?.data?.results ?? [];

  const trainsGoToList = trains?.[0]?.solutions ?? [];
  const trainsReturnList = trains?.[1]?.solutions ?? [];
  var trainSolutions = trainsGoToList;

  const allSegments = [
    ...trainsGoToList.flatMap((train) => train.segments),
    ...trainsReturnList.flatMap((train) => train.segments),
  ];
  const operatorsCount = _.countBy(allSegments, "marketing_carrier");
  const uniqueMarketingCarriers = [
    ...new Set(allSegments.map((segment) => segment.marketing_carrier)),
  ];
  const operatorOptions = [
    ["", "---"],
    ...uniqueMarketingCarriers.map((carrier) => [
      carrier,
      `${carrier} (${operatorsCount[carrier]})`,
    ]),
  ];

  var filteredTrains = filterTrains(trainSolutions, filters);
  // Order results
  useEffect(() => {
    if (filters.order_by) {
      const sortingCriteria = {
        "price": (a, b) =>
          Math.min(
            ...a.offers.map((offer) => Number(offer.amount.value))
          ).toFixed(2) -
          Math.min(
            ...b.offers.map((offer) => Number(offer.amount.value))
          ).toFixed(2),
        "-price": (a, b) =>
          Math.min(
            ...b.offers.map((offer) => Number(offer.amount.value))
          ).toFixed(2) -
          Math.min(
            ...a.offers.map((offer) => Number(offer.amount.value))
          ).toFixed(2),
        "duration": (a, b) =>
          Duration.fromISO(a.duration).as("minutes") -
          Duration.fromISO(b.duration).as("minutes"),
        "-duration": (a, b) =>
          Duration.fromISO(b.duration).as("minutes") -
          Duration.fromISO(a.duration).as("minutes"),
        "arrival": (a, b) =>
          DateTime.fromISO(a.arrival).valueOf() -
          DateTime.fromISO(b.arrival).valueOf(),
        "-arrival": (a, b) =>
          DateTime.fromISO(b.arrival).valueOf() -
          DateTime.fromISO(a.arrival).valueOf(),
        "departure": (a, b) =>
          DateTime.fromISO(a.departure).valueOf() -
          DateTime.fromISO(b.departure).valueOf(),
        "-departure": (a, b) =>
          DateTime.fromISO(b.departure).valueOf() -
          DateTime.fromISO(a.departure).valueOf(),
      };

      if (sortingCriteria[filters.order_by]) {
        filteredTrains.sort(sortingCriteria[filters.order_by]);
      }
    }
  }, [returnRoute, filters, loading]);

  var durations = [];
  var totalDuration = 0;
  var averageDuration = 0;
  var averagePrice = 0;
  var maxPrice = 0;
  var minPrice = 0;
  const currency = filteredTrains?.[0]?.offers?.[0]?.amount?.currency ?? "EUR";

  if (filteredTrains.length > 0) {
    durations = filteredTrains.map((train) =>
      Duration.fromISO(train.duration).as("minutes")
    );

    totalDuration = durations.reduce((total, duration) => total + duration, 0);
    averageDuration = totalDuration / filteredTrains.length;

    const allPrices = filteredTrains.flatMap((item) =>
      item.offers.map((offer) => parseFloat(offer.amount.value))
    );
    maxPrice = Math.max(...allPrices);
    minPrice = Math.min(...allPrices);
    const sum = allPrices.reduce((acc, price) => acc + price, 0);
    averagePrice = sum / allPrices.length;
  }

  const { animationRef } = useLoadingAnimation({
    loading: loading,
    animationName: "trains.json",
  });

  const animationStyles = { height: "15rem" };
  const trainIsSelected = !!selectedId.goTrainId || !!selectedId.returnTrainId;

  function selectOffer(trainId, offerId) {
    trainSolutions.forEach((t) => {
      if (t.id !== trainId) return;
      t.offers.forEach((o) => {
        o.id === offerId ? (o.selected = true) : (o.selected = false);
      });
    });
    setSelectedOfferId(offerId);
  }

  // Regenerate filtered results when an offer is selected
  useEffect(() => {
    filteredTrains = filterTrains(trainSolutions, filters);
  }, [selectedOfferId]);

  const dispatch = useDispatch();
  const onSelect = useCallback(
    (trainData, legUid, sessionId) => {
      dispatch(removeTrain(selectedTrainData.id));
      dispatch(saveTrainsResult(trainData, legUid, sessionId, true));
    },
    [dispatch]
  );

  return (
    <Drawer
      size="lg"
      placement="right"
      show={true}
      className="OverviewAllTrains">
      <Drawer.Header className="trains-drawer-header">
        <div></div>
        <Drawer.Title>Trains</Drawer.Title>
        <button className="Button close-btn" onClick={onClose}>
          Close
        </button>
      </Drawer.Header>
      <Drawer.Body className="trains-drawer-body">
        <FiltersBar
          operatorOptions={operatorOptions}
          averageDuration={
            averageDuration !== 0
              ? humanizeDurationFromMins(Math.floor(averageDuration))
              : "N/A"
          }
          options={filteredTrains.length}
          averagePrice={
            averagePrice !== 0 ? (
              <TrainPrice
                currency={currency}
                price={averagePrice}
                customStyles={{
                  Price: { fontWeight: "normal", fontSize: "small" },
                }}
              />
            ) : (
              "N/A"
            )
          }
          minPrice={
            minPrice !== 0 ? (
              <TrainPrice
                currency={currency}
                price={minPrice}
                customStyles={{
                  Price: { fontWeight: "normal", fontSize: "small" },
                }}
              />
            ) : (
              "N/A"
            )
          }
          maxPrice={
            maxPrice !== 0 ? (
              <TrainPrice
                currency={currency}
                price={maxPrice}
                customStyles={{
                  Price: { fontWeight: "normal", fontSize: "small" },
                }}
              />
            ) : (
              "N/A"
            )
          }
          filters={filters}
          onSetFilters={(filters) => setFilters(filters)}
        />
        <div
          className="trains-drawer-body__content"
          data-train-is-selected={trainIsSelected}>
          {loading && (
            <div className="trains-drawer-body__content__loadingCntr">
              <div ref={animationRef} style={animationStyles} />
              <div className="trains-drawer-body__content__loadingCntr__text">
                Loading...
              </div>
            </div>
          )}
          {loading ? null : filteredTrains.length > 0 ? (
            <div className="trains-drawer-body__content__trainsCntr">
              <Routes
                data={trains}
                returnRoute={returnRoute}
                setReturnRoute={setReturnRoute}
              />
              {filteredTrains.map((slot, idx) => {
                return (
                  <React.Fragment key={idx}>
                    <Train
                      key={idx}
                      data={slot}
                      checked={slot.id === selectedId.goTrainId}
                      setChecked={(checked) => {
                        checked
                          ? setSelectedId((p) => ({ ...p, goTrainId: slot.id }))
                          : setSelectedId((p) => ({ ...p, goTrainId: "" }));
                      }}
                      onSelectOffer={(offerId) => selectOffer(slot.id, offerId)}
                    />
                    {idx !== trainSolutions?.length - 1 && (
                      <div className="trains-drawer-body__content__trainsCntr__divider" />
                    )}
                  </React.Fragment>
                );
              })}
            </div>
          ) : (
            <div className="trains-drawer-body__content--noTrains">
              No results found
              <img
                src={
                  "https://apollo-statics.s3.eu-central-1.amazonaws.com/svgs/noTrains+.svg"
                }
                onError={(e) => {
                  handleNonValidImgFn(e, "ATRA");
                }}
              />
            </div>
          )}
          {trainIsSelected && !loading ? (
            <SelectedOptions
              goRoute={trainsGoToList.find(
                (t) => t.id === selectedId.goTrainId
              )}
              returnRoute={null}
              onSelect={() => {
                onSelect(
                  trainsGoToList.find((t) => t.id === selectedId.goTrainId),
                  legUid,
                  sessionId
                );
                onClose();
              }}
            />
          ) : (
            <div></div>
          )}
        </div>
      </Drawer.Body>
    </Drawer>
  );
};
OverviewAllTrains.propTypes = {
  sessionId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
