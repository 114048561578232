import { variables } from "@src/jsssetup";
import React from "react";
import { createUseStyles } from "react-jss";
import { Icon } from "rsuite";

const styles = createUseStyles({
  SuccessfullPage: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridGap: variables.normal_gap,
    justifyItems: "center",
    alignItems: "center",
    padding: "10rem",
  },
  successIcon: {
    marginTop: "50px",
    fontSize: "xxx-large",
    transform: "scale(2)",
    color: variables.colors.green,
    width: "5%",
  },
});
var SuccessfullPage = () => {
  const classes = styles();
  return (
    <div className={classes.SuccessfullPage}>
      <h3>Successfully registered information!</h3>
      <Icon className={classes.successIcon} icon="check-circle" size="5x" />
    </div>
  );
};
export default SuccessfullPage;
