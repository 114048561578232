import { SISI2_API_URL } from "@src/api";
import service, { getAuthHeaders } from "@src/api/request";
import axios from "axios";

export function deleteConnections(param) {
  return service({
    url: `/bid/entity-relation/${param}/`,
    method: "delete",
  });
}

export async function createConnection(params) {
  const auth_headers = getAuthHeaders();
  return await axios.post(`${SISI2_API_URL}/crm/entity/connections/`, params, {
    headers: { ...auth_headers },
  });
}

export async function updateConnection({ params, id }) {
  const auth_headers = getAuthHeaders();
  return await axios.patch(
    `${SISI2_API_URL}/crm/entity/connections/${id}/`,
    params,
    {
      headers: { ...auth_headers },
    }
  );
}

export async function fetchInvitations(params) {
  const auth_headers = getAuthHeaders();
  return await axios.get(`${SISI2_API_URL}/crm/entity/connections/`, {
    params,
    headers: { ...auth_headers },
  });
}

export async function getConnection(id) {
  const auth_headers = getAuthHeaders();
  return await axios.get(`${SISI2_API_URL}/crm/entity/connections/${id}/`, {
    headers: { ...auth_headers },
  });
}

export async function deleteConnection(id) {
  const auth_headers = getAuthHeaders();
  return await axios.delete(`${SISI2_API_URL}/crm/entity/connections/${id}/`, {
    headers: { ...auth_headers },
  });
}
