import update from "immutability-helper";
import _ from "lodash";
import { DateTime, Duration } from "luxon";

export const calcTripDates = (destinations, originData) => {
  var initCheckIn = originData.date;
  var prevChekOut = null;

  return destinations.map((d, idx) => {
    var checkIn = null;
    checkIn = idx == 0 ? initCheckIn : prevChekOut;

    var checkOut = DateTime.fromISO(checkIn).plus(
      Duration.fromObject({ days: d.stay })
    );

    prevChekOut = _.cloneDeep(checkOut);

    return update(d, {
      checkIn: { $set: DateTime.fromISO(checkIn).toISODate() },
      checkOut: { $set: checkOut.toISODate() },
    });
  });
};

export const calcDestOrders = (destinations) => {
  return update(destinations, {
    $apply: (dests) => {
      return dests.map((d, idx) => update(d, { order: { $set: idx + 1 } }));
    },
  });
};
