import {
  //================================LIST=============================
  AFFILIATES_BEGIN_FETCH_DATA,
  AFFILIATES_FETCH_DATA,
  AFFILIATES_END_FETCH_DATA,
  AFFILIATES_FETCH_OPTIONS,
  //==============================FILTER===========================
  AFFILIATES_UPDATE_LIST_FILTER_FORM,
  AFFILIATES_APPLY_LIST_FILTER,
  AFFILIATES_INITIALIZE_LIST_FILTER_FORM,
  AFFILIATES_RESET_LIST_FILTER_FORM,
  //==============================COLUMNS===========================
  AFFILIATES_UPDATE_LIST_COLUMNS_FORM,
  AFFILIATES_APPLY_LIST_COLUMNS,
  AFFILIATES_INITIALIZE_LIST_COLUMNS_FORM,
  AFFILIATES_SHOW_BASE_DATA_EDIT_FORM,
  AFFILIATES_HIDE_BASE_DATA_EDIT_FORM,
  AFFILIATES_EDIT_BASE_DATA,
  AFFILIATES_INITIALIZE_BASE_DATA_EDIT_FORM,
  AFFILIATES_VALIDATE_BASE_DATA_EDIT_FORM,
  AFFILIATES_PROFILE_DATA,
  AFFILIATES_UPLOADING_BASE_DATA_EDIT_FORM,
  AFFILIATES_IDLE_BASE_DATA_EDIT_FORM,
  AFFILIATES_CLEAN_BASE_EDIT_FORM_ERRORS,
  AFFILIATES_CLEAN_BASE_DATA_EDIT_FORM,
  AFFILIATES_CLEAN_BASIC_BASE_DATA_EDIT_FORM,
  AFFILIATES_TOGGLE_POSTAL_ADDRESS,
  //==========================NATURE OF BIZ==========================
  AFFILIATES_VIEW_NATURE_OF_BIZ,
  AFFILIATES_EDIT_NATURE_OF_BIZ,
  AFFILIATES_INITIALIZE_NATURE_DATA_FORM,
  AFFILIATES_EDIT_NATURE_OF_BIZ_DATA,
  AFFILIATES_SUBMIT_NATURE_OF_BIZ_DATA,
  AFFILIATES_EDIT_LICENSE_CODE,

  //==========================SERVICES===============================
  AFFILIATES_CLEAN_SERVICE_BASE_DATA_EDIT_FORM,
  AFFILIATES_INITIALIZE_MAIN_SERVICES_FORM,
  AFFILIATES_INITIALIZE_TRAVEL_SERVICE_FORM,
  AFFILIATES_TOGGLE_VERIFY_ADDRESS,
  AFFILIATES_BEGIN_FETCH_ADDRESS_DATA,
  AFFILIATES_END_FETCH_ADDRESS_DATA,
  AFFILIATES_FETCH_ADDRESS_POINT,
  AFFILIATES_INITIAL_ADDRESS_POINT,
  //img
  AFFILIATES_UPLOAD_PREFERENCE_IMAGE,
  AFFILIATES_BEGIN_FETCH_PROFILE_DATA,
  AFFILIATES_END_FETCH_PROFILE_DATA,
  AFFILIATES_CLEAN_ADDRESS_POINT,
  AFFILIATES_INITIAL_VERIFY_STATUS,
  AFFILIATES_CLOSE_VERIFY_ADDRESS,
} from "./types";

import { notifyCommonError } from "@src/components/common/notifications/CommonErrorNotification.js";
import { notifyCommonSuccess } from "@src/components/common/notifications/CommonSuccessNotification.js";
import _ from "lodash";
import { createHashHistory } from "history";
import React from "react";
import TransTxt from "@src/components/common/SxFormatMessage";

import {
  initialAffiliatesBaseDataForm,
  initialAffiliatesNatureOfBizForm,
  initialAffiliatesFilterForm,
} from "@src/forms/Network/Affiliates";

import {
  changeUploadImgFactory,
  fetchCompleteAddressFactory,
  fetchAddressPointDetailFactory,
} from "./../../ActionFactory";

import { filterProcessor } from "./filterProcessor";

import { submitProcessor } from "@src/actions/tools";
import { entitiesListSelector } from "@src/reducers/tools";
import { travelServicesFrom } from "@src/forms/Admin/Company/companyProfileForms";

import {
  getAffiliatesList,
  getAffiliatesOptions,
  addNewAffiliates as addNewAffiliatesAPI,
  fetchAffiliatesProfile as fetchAffiliatesProfileAPI,
  submitAffiliatesProfile as submitAffiliatesProfileAPI,
} from "@src/api";

import {
  filterEmptyValue,
  isEmptyObj,
  errorHandle as commonErrorHandle,
} from "@src/tools/common_tools";

import { hideGlobalModal } from "@src/actions/Shares";

import update from "immutability-helper";

import { getPagination } from "@src/tools/form_tools";

import { uploadImgLink } from "@src/api";

import {
  defaultOption,
  initialAffiliatesColumnsSetUp,
} from "@src/forms/Network/Affiliates";

// affiliates list

export const addNewAffiliatesData = () => (dispatch, getState) => {
  const state = getState();
  const type = state.userMeta;
  const verifyStatus = state.isVerifyAffiliatesPostalAddress;

  if (state.affiliatesBaseDataFormErrors.valid == false) {
    for (const key in state.affiliatesBaseDataFormErrors) {
      if (state.affiliatesBaseDataFormErrors[key].hasError) {
        notifyCommonError(state.affiliatesBaseDataFormErrors[key].errorMessage);
        return null;
      }
    }
    return null;
  }

  if (!verifyStatus) {
    return notifyCommonError("Please Verify the Address");
  }

  const entityData = {
    name: state.affiliatesBaseDataForm.name.value,
    legal_title: state.affiliatesBaseDataForm.legal_title.value,
    registration_code: state.affiliatesBaseDataForm.registration_code.value,
    founding_year: state.affiliatesBaseDataForm.founding_year.value,
    email: state.affiliatesBaseDataForm.email.value,
    url: state.affiliatesBaseDataForm.url.value,
    weekdays: [true, true, true, true, true, false, false],
    working_time_from: "09:00",
    working_time_to: "18:00",
    brief_description_en:
      state.affiliatesBaseDataForm.brief_description_en.value,
    brief_description_cn:
      state.affiliatesBaseDataForm.brief_description_cn.value,
    license_type: [],
    entity_type: state.affiliatesNatureOfBizForm.entity_type.value,
    expertise_type: state.affiliatesNatureOfBizForm.expertise_type.value,
    phone: state.affiliatesBaseDataForm.phone.value,
    mobile_phone: state.affiliatesBaseDataForm.mobile_phone.value,
    [type.company_type]: type.company_id,
  };

  //travel_service
  const result = submitProcessor(
    state,
    "affiliatesTravelServicesForm",
    travelServicesFrom,
    "travel_services"
  );
  entityData.travel_services = result.payload.travel_services;

  //contact person
  const contactPersonData = {
    first_name: state.affiliatesBaseDataForm.first_name.value,
    last_name: state.affiliatesBaseDataForm.last_name.value,
  };
  entityData.contact_person = [contactPersonData];

  // address
  let postal_country_code = "";
  if (state.affiliatesBaseDataForm.postal_country_code.value) {
    postal_country_code =
      state.affiliatesBaseDataForm.postal_country_code.value.split("-")[0];
  }
  const postal_Address_Data = {
    street_number: state.affiliatesBaseDataForm.postal_street_number.value,
    street: state.affiliatesBaseDataForm.postal_street.value,
    country_code: postal_country_code,
    region: state.affiliatesBaseDataForm.postal_region.value,
    city: state.affiliatesBaseDataForm.postal_city.value,
    post_code: state.affiliatesBaseDataForm.postal_post_code.value,
    lat: state.affiliatesBaseDataForm.lat.value,
    lng: state.affiliatesBaseDataForm.lng.value,
  };
  entityData.address = [postal_Address_Data];

  dispatch(loadingAffiliatesBaseDataEditForm());
  dispatch(addNewAffiliatesAPIForm(entityData));
};

export const addNewAffiliatesAPIForm =
  (entityData) => async (dispatch, getState) => {
    try {
      const res = await addNewAffiliatesAPI(entityData);
      const { id } = res;
      const customHistory = createHashHistory();
      customHistory.push(`/network/affiliates/profile/?id=${id}`);
      dispatch(hideGlobalModal());
    } catch (error) {
      commonErrorHandle({ error, form: getState().affiliatesBaseDataForm });
    } finally {
      dispatch(idleAffiliatesBaseDataEditForm());
    }
  };

export const addNewAffiliates = () => (dispatch, getState) => {
  const tempForm = _.cloneDeep(getState().affiliatesBaseDataForm);
  // add protocol to validate,but no send to backend
  if (tempForm.url.value) {
    tempForm.url.value = "http://" + tempForm.url.value;
  }

  dispatch(validateAffiliatesBaseDataEditFormData(tempForm));

  Promise.resolve().then(() => dispatch(addNewAffiliatesData()));
};

export const validateAffiliatesBaseDataEditFormData = (data) => {
  return {
    type: AFFILIATES_VALIDATE_BASE_DATA_EDIT_FORM,
    data,
  };
};

const initialOptions = {
  status: {
    choices: [
      { value: "AC", display_name: <TransTxt id="active" startCase /> },
      {
        value: "IN",
        display_name: <TransTxt id="inactive" startCase />,
      },
      {
        value: "CA",
        display_name: <TransTxt id="candidate" startCase />,
      },
    ],
  },
};
export const fetchAffiliatesOptions =
  (langCode) => async (dispatch, getState) => {
    const lang = getState().setLang;

    try {
      const data = await getAffiliatesOptions(langCode || lang);
      const options = _.merge(data.actions.POST, initialOptions);
      dispatch(fetchAffiliatesOptionsData({ ...options }));
    } catch (error) {
      commonErrorHandle({ error });
    }
  };

export const fetchAffiliatesOptionsData = (data) => {
  return {
    type: AFFILIATES_FETCH_OPTIONS,
    data,
  };
};

export const fetchAffiliatesListData = (data) => {
  return {
    type: AFFILIATES_FETCH_DATA,
    data,
  };
};

export const beginAffiliatesListData = () => {
  return { type: AFFILIATES_BEGIN_FETCH_DATA };
};

export const endAffiliatesListData = () => {
  return { type: AFFILIATES_END_FETCH_DATA };
};

// affiliates profile
export const submitAffiliatesProfile =
  (fields, uploadingAction, errorAction = [], successAction, alert = true) =>
  async (dispatch, getState) => {
    dispatch(uploadingAction());

    const state = getState();
    const result = submitProcessor(
      state,
      "affiliatesTravelServicesForm",
      travelServicesFrom,
      fields
    );

    if (result.hasError) {
      errorAction.forEach((action) => dispatch(action(result)));
      if (alert) {
        result.errorMsgs.forEach((e) => notifyCommonError(e.msg));
      }
      return null;
    }
    const req = {
      ...result.payload,
      main_service: state.affiliatesMainServicesForm.value,
    };

    try {
      const data = await submitAffiliatesProfileAPI(
        state.affiliatesProfile.id,
        req,
        "patch"
      );
      dispatch({ type: AFFILIATES_PROFILE_DATA, data: data });
      dispatch(successAction());
      notifyCommonSuccess("Successfully updated your profile.");
    } catch (error) {
      console.log(error);
      notifyCommonError("Error updating your profile please try again.");
      dispatch(errorAction());
    }
  };

export const fetchAffiliatesProfileData = (data) => {
  return {
    type: AFFILIATES_PROFILE_DATA,
    data,
  };
};

export const cleanAffiliatesBaseEditFormErrors = () => {
  return { type: AFFILIATES_CLEAN_BASE_EDIT_FORM_ERRORS };
};

export const affiliatesProfileData = (data) => (dispatch) => {
  const addresses = _.groupBy(data.address || [], "address_type");
  data.billingAddress = _.get(addresses, "BI.0", {});
  data.postalAddress = _.get(addresses, "BU.0", {});
  data.contactPerson = _.get(data, "contact_person.0", {});
  dispatch(fetchAffiliatesProfileData(data));
};

export const beginFetchAffiliatesProfile = () => {
  return { type: AFFILIATES_BEGIN_FETCH_PROFILE_DATA };
};

export const endFetchAffiliatesProfile = () => {
  return { type: AFFILIATES_END_FETCH_PROFILE_DATA };
};

export const fetchAffiliatesProfile = (id) => async (dispatch) => {
  dispatch(beginFetchAffiliatesProfile());
  try {
    const data = await fetchAffiliatesProfileAPI(id);
    dispatch(affiliatesProfileData(data));
    dispatch(initializeAffiliatesMainServiceForm(data.main_service));
    dispatch(
      initializeAffiliatesTravelServiceForm({ value: data.travel_services })
    );
  } catch (error) {
    commonErrorHandle({ error });
  } finally {
    dispatch(endFetchAffiliatesProfile());
  }
};

export const initializeAffiliatesMainServiceForm = (data) => {
  return {
    type: AFFILIATES_INITIALIZE_MAIN_SERVICES_FORM,
    data,
  };
};

export const initializeAffiliatesTravelServiceForm = (data) => {
  return {
    type: AFFILIATES_INITIALIZE_TRAVEL_SERVICE_FORM,
    data,
  };
};

export const hideAffiliatesBaseEditForm = () => {
  return { type: AFFILIATES_HIDE_BASE_DATA_EDIT_FORM };
};

export const editAffiliatesBaseDataForm = (data) => {
  return { type: AFFILIATES_EDIT_BASE_DATA, data: data };
};

export const submitAffiliatesBaseDataEditForm = () => (dispatch, getState) => {
  const state = getState();
  const affiliatesProfile = state.affiliatesProfile;
  const verifyStatus = state.isVerifyAffiliatesPostalAddress;
  const postalAddress = affiliatesProfile.postalAddress;

  const file = state.affiliatesBaseDataForm.original_image.fileName;
  const formData = new FormData();

  formData.append("original_image", file);
  formData.append("content_type", "affiliate");
  formData.append("object_id", affiliatesProfile.id);

  let country_code = "";
  if (state.affiliatesBaseDataForm.country_code.value) {
    country_code =
      state.affiliatesBaseDataForm.country_code.value.split("-")[0];
  }
  const billingAddressData = {
    street_number: state.affiliatesBaseDataForm.street_number.value,
    street: state.affiliatesBaseDataForm.street.value,
    country_code: country_code,
    region: state.affiliatesBaseDataForm.region.value,
    city: state.affiliatesBaseDataForm.city.value,
    post_code: state.affiliatesBaseDataForm.post_code.value,
  };

  let postal_country_code = "";
  if (state.affiliatesBaseDataForm.postal_country_code.value) {
    postal_country_code =
      state.affiliatesBaseDataForm.postal_country_code.value.split("-")[0];
  }

  const postalAddressData = {
    street_number: state.affiliatesBaseDataForm.postal_street_number.value,
    street: state.affiliatesBaseDataForm.postal_street.value,
    country_code: postal_country_code,
    region: state.affiliatesBaseDataForm.postal_region.value,
    city: state.affiliatesBaseDataForm.postal_city.value,
    post_code: state.affiliatesBaseDataForm.postal_post_code.value,
    lat: state.affiliatesBaseDataForm.lat.value || postalAddress.lat,
    lng: state.affiliatesBaseDataForm.lng.value || postalAddress.lng,
  };

  if (state.affiliatesBaseDataFormErrors.valid == false) {
    for (const key in state.affiliatesBaseDataFormErrors) {
      if (state.affiliatesBaseDataFormErrors[key].hasError) {
        notifyCommonError(state.affiliatesBaseDataFormErrors[key].errorMessage);
        return null;
      }
    }
    return null;
  }

  if (
    !postalAddress.lat &&
    !postalAddress.lng &&
    !state.affiliatesBaseDataForm.lat.value &&
    !state.affiliatesBaseDataForm.lng.value
  ) {
    return notifyCommonError("Please Geolocate the map to get exact location");
  }

  if (!verifyStatus) {
    return notifyCommonError("Please Verify the Address");
  }

  let entityData = {
    name: state.affiliatesBaseDataForm.name.value,
    legal_title: state.affiliatesBaseDataForm.legal_title.value,
    registration_code: state.affiliatesBaseDataForm.registration_code.value,
    founding_year: state.affiliatesBaseDataForm.founding_year.value,
    email: state.affiliatesBaseDataForm.email.value,
    phone: state.affiliatesBaseDataForm.phone.value,
    mobile_phone: state.affiliatesBaseDataForm.mobile_phone.value,
    url: state.affiliatesBaseDataForm.url.value,
    weekdays: state.affiliatesBaseDataForm.weekdays.value,
    timezone: state.affiliatesBaseDataForm.timezone.value,
    working_time_from:
      state.affiliatesBaseDataForm.working_time_from.value + ":00",
    working_time_to: state.affiliatesBaseDataForm.working_time_to.value + ":00",
    brief_description_en:
      state.affiliatesBaseDataForm.brief_description_en.value,
    brief_description_cn:
      state.affiliatesBaseDataForm.brief_description_cn.value,
    currency: state.affiliatesBaseDataForm.currency.value,
  };

  // address
  const address = affiliatesProfile.address;
  if (_.get(affiliatesProfile, "billingAddress.id")) {
    filterEmptyValue(
      _.merge(
        _.find(address, { id: _.get(affiliatesProfile, "billingAddress.id") }),
        billingAddressData
      )
    );
  } else {
    if (!isEmptyObj(billingAddressData)) {
      billingAddressData.address_type = "BI";
      address.push(filterEmptyValue(billingAddressData));
    }
  }

  if (_.get(affiliatesProfile, "postalAddress.id")) {
    filterEmptyValue(
      _.merge(
        _.find(address, { id: _.get(affiliatesProfile, "postalAddress.id") }),
        postalAddressData
      )
    );
  } else {
    if (!isEmptyObj(postalAddressData)) {
      postalAddressData.address_type = "BU";
      address.push(filterEmptyValue(postalAddressData));
    }
  }
  entityData.address = address;

  //contact person
  const contactPersonData = {
    first_name: state.affiliatesBaseDataForm.first_name.value,
    last_name: state.affiliatesBaseDataForm.last_name.value,
  };

  if (_.get(affiliatesProfile, "contactPerson.id")) {
    _.merge(
      _.find(affiliatesProfile.contact_person, {
        id: _.get(affiliatesProfile, "contactPerson.id"),
      }),
      contactPersonData
    );
  } else {
    affiliatesProfile.contact_person.push(filterEmptyValue(contactPersonData));
  }
  entityData.contact_person = affiliatesProfile.contact_person;

  entityData = filterEmptyValue(entityData);
  dispatch(loadingAffiliatesBaseDataEditForm());

  if (
    file !== undefined &&
    !(Object.prototype.toString.call(file) === "[object Object]")
  ) {
    uploadImgLink(formData)
      .then((res) => {
        const original_image = res.original_image;
        dispatch(
          uploadAffiliatesImages({
            name: "original_image",
            value: original_image,
          })
        );
        dispatch(
          submitAffiliatesProfileForm(entityData, state.affiliatesBaseDataForm)
        );
      })
      .catch((error) => {
        dispatch(idleAffiliatesBaseDataEditForm());
        error.ovrdErrHandle && error.ovrdErrHandle();
        commonErrorHandle({ error });
      });
  } else {
    dispatch(
      submitAffiliatesProfileForm(entityData, state.affiliatesBaseDataForm)
    );
  }

  return null;
};

export const uploadAffiliatesImages = (data) => {
  return {
    type: AFFILIATES_UPLOAD_PREFERENCE_IMAGE,
    data,
  };
};

export const submitAffiliatesProfileForm =
  (entityData) => async (dispatch, getState) => {
    try {
      const data = await submitAffiliatesProfileAPI(
        getState().affiliatesProfile.id,
        entityData,
        "patch"
      );
      dispatch(affiliatesProfileData(data));
      notifyCommonSuccess("Successfully updated your profile.");
    } catch (error) {
      error.ovrdErrHandle && error.ovrdErrHandle();
      commonErrorHandle({ error, initialAffiliatesBaseDataForm });
    } finally {
      dispatch(idleAffiliatesBaseDataEditForm());
      dispatch(hideAffiliatesBaseEditForm());
    }
  };

export const loadingAffiliatesBaseDataEditForm = () => {
  return { type: AFFILIATES_UPLOADING_BASE_DATA_EDIT_FORM };
};

export const idleAffiliatesBaseDataEditForm = () => {
  return { type: AFFILIATES_IDLE_BASE_DATA_EDIT_FORM };
};

export const validateAffiliatesBaseDataEditForm =
  () => (dispatch, getState) => {
    const tempForm = _.cloneDeep(getState().affiliatesBaseDataForm);
    // add protocol to validate,but no send to backend
    if (tempForm.url.value) {
      tempForm.url.value = "http://" + tempForm.url.value;
    }

    dispatch(validateAffiliatesBaseDataEditFormData(tempForm));

    Promise.resolve().then(() => dispatch(submitAffiliatesBaseDataEditForm()));
  };

// Base data edit section

export const showAffiliatesBaseEditForm = () => (dispatch, getState) => {
  const profile = getState().affiliatesProfile;
  const address = profile.billingAddress || {};
  const postalAddress = profile.postalAddress || {};
  const affiliatesContactPerson = profile.contactPerson || {};
  const formatCountryCodeList = getState().formatCountryCodeList;
  formatCountryCodeList.forEach((item) => {
    if (item.code && item.code == address.country_code) {
      address.country_code = item.value;
    } else {
      return null;
    }
    return null;
  });

  formatCountryCodeList.forEach((item) => {
    if (item.code && item.code == postalAddress.country_code) {
      postalAddress.country_code = item.value;
    } else {
      return null;
    }
    return null;
  });

  const data = update(initialAffiliatesBaseDataForm, {
    name: { value: { $set: profile.name } },
    legal_title: { value: { $set: profile.legal_title } },
    registration_code: { value: { $set: profile.registration_code } },
    founding_year: { value: { $set: profile.founding_year } },
    first_name: { value: { $set: affiliatesContactPerson.first_name } },
    last_name: { value: { $set: affiliatesContactPerson.last_name } },
    email: { value: { $set: profile.email } },
    mobile_phone: { value: { $set: profile.mobile_phone } },
    phone: { value: { $set: profile.phone } },
    url: { value: { $set: profile.url } },
    brief_description_en: { value: { $set: profile.brief_description_en } },
    brief_description_cn: { value: { $set: profile.brief_description_cn } },
    street_number: { value: { $set: address.street_number } },
    street: { value: { $set: address.street } },
    country_code: { value: { $set: address.country_code } },
    region: { value: { $set: address.region } },
    city: { value: { $set: address.city } },
    post_code: { value: { $set: address.post_code } },
    postal_street_number: { value: { $set: postalAddress.street_number } },
    postal_street: { value: { $set: postalAddress.street } },
    postal_country_code: { value: { $set: postalAddress.country_code } },
    postal_region: { value: { $set: postalAddress.region } },
    postal_city: { value: { $set: postalAddress.city } },
    postal_post_code: { value: { $set: postalAddress.post_code } },
    original_image: {
      value: {
        $set: profile.logo ? profile.logo.photo_sm_url : "",
      },
    },
    weekdays: {
      value: {
        $set: profile.weekdays || [
          false,
          false,
          false,
          false,
          false,
          false,
          false,
        ],
      },
    },
    timezone: { value: { $set: profile.timezone } },
    working_time_from: {
      value: {
        $set: parseInt((profile.working_time_from || "0").substr(0, 2)),
      },
    },
    working_time_to: {
      value: {
        $set: parseInt((profile.working_time_to || "0").substr(0, 2)),
      },
    },
    currency: {
      value: {
        $set: profile.currency,
      },
    },
  });

  dispatch(initializeBaseDataEditForm(data));
  dispatch(
    initializeAffiliatesAddressPoint({
      lat: postalAddress.lat || 0,
      lng: postalAddress.lng || 0,
    })
  );
  dispatch(closeAffiliatesVerifyAddress());
  const isSame =
    JSON.stringify({
      street_number: address.street_number,
      street: address.street,
      country_code: address.country_code,
      region: address.region,
      city: address.city,
      post_code: address.post_code,
    }) ==
    JSON.stringify({
      street_number: postalAddress.street_number,
      street: postalAddress.street,
      country_code: postalAddress.country_code,
      region: postalAddress.region,
      city: postalAddress.city,
      post_code: postalAddress.post_code,
    });
  dispatch(showAffiliatesBaseDataForm(isSame));
};

export const initializeAffiliatesAddressPoint = (data) => {
  return {
    type: AFFILIATES_INITIAL_ADDRESS_POINT,
    data,
  };
};

export const closeAffiliatesVerifyAddress = () => {
  return {
    type: AFFILIATES_CLOSE_VERIFY_ADDRESS,
  };
};

export const showAffiliatesBaseDataForm = (data) => {
  return {
    type: AFFILIATES_SHOW_BASE_DATA_EDIT_FORM,
    data,
  };
};

export const initializeBaseDataEditForm = (data) => {
  return { type: AFFILIATES_INITIALIZE_BASE_DATA_EDIT_FORM, data: data };
};

export const cleanAffiliatesBaseEditForm = () => (dispatch) => {
  dispatch(cleanAffiliatesBasicBaseEditForm());
  dispatch(cleanAffiliatesNatureBizBaseEditForm());
  dispatch(cleanAffiliatesServiceBaseEditForm());
  dispatch(cleanAffiliatesMapInfo());
  dispatch(initialAffiliatesVerifyStatus());
};

export const cleanAffiliatesMapInfo = () => {
  return { type: AFFILIATES_CLEAN_ADDRESS_POINT };
};

export const initialAffiliatesVerifyStatus = () => {
  return { type: AFFILIATES_INITIAL_VERIFY_STATUS };
};

export const cleanAffiliatesBasicBaseEditForm = () => {
  return { type: AFFILIATES_CLEAN_BASE_DATA_EDIT_FORM };
};

export const cleanAffiliatesNatureBizBaseEditForm = () => {
  return { type: AFFILIATES_CLEAN_BASIC_BASE_DATA_EDIT_FORM };
};

export const cleanAffiliatesServiceBaseEditForm = () => {
  return { type: AFFILIATES_CLEAN_SERVICE_BASE_DATA_EDIT_FORM };
};

export const fetchAffiliatesList = (cb) => async (dispatch, getState) => {
  dispatch(beginAffiliatesListData());

  const service_type = getState().travelServiceOptions.service_type || {};
  const filter = filterProcessor(getState().affiliatesListFilter, service_type);
  const pagination = getPagination(getState().affiliatesList);

  try {
    const data = await getAffiliatesList({ ...filter, ...pagination });
    if (typeof cb == "function") cb();
    dispatch(
      fetchAffiliatesListData({
        page: pagination.page,
        limit: pagination.limit,
        ...data,
      })
    );
  } catch (error) {
    commonErrorHandle({ error });
  } finally {
    dispatch(endAffiliatesListData());
  }
};

export const modifyAffiliatesPage = (page) => (dispatch, getState) => {
  let affiliatesList = getState().affiliatesList;
  affiliatesList = update(affiliatesList, { page: { $set: page } });
  dispatch(fetchAffiliatesListData({ ...affiliatesList }));
  dispatch(fetchAffiliatesList());
};

export const modifyAffiliatesLimit = (limit) => (dispatch, getState) => {
  let affiliatesList = getState().affiliatesList;
  affiliatesList = update(affiliatesList, {
    limit: { $set: limit },
    page: { $set: 1 },
  });
  dispatch(fetchAffiliatesListData({ ...affiliatesList }));
  dispatch(fetchAffiliatesList());
};

// filter relation
export const updateAffiliatesListFilterForm = (payload) => {
  return { type: AFFILIATES_UPDATE_LIST_FILTER_FORM, data: payload };
};

export const applyAffiliatesListFilter = () => (dispatch, getState) => {
  const state = getState().affiliatesListFilterForm || {};
  dispatch(hideGlobalModal());
  dispatch(applyAffiliatesListFilterForm(state));
  dispatch(fetchAffiliatesList(1));
};

export const applyAffiliatesListFilterForm = (data) => {
  return {
    type: AFFILIATES_APPLY_LIST_FILTER,
    data,
  };
};

export const initializeAffiliatesListFilterForm =
  () => (dispatch, getState) => {
    const state = getState().affiliatesListFilter || {};
    dispatch(initializeAffiliatesListFilter(state));
  };

export const initializeAffiliatesListFilter = (data) => {
  return {
    type: AFFILIATES_INITIALIZE_LIST_FILTER_FORM,
    data,
  };
};

export const resetAffiliatesListFilter = () => (dispatch, getState) => {
  const data = update(initialAffiliatesFilterForm, {
    $merge: getState().travelServicesForm,
  });
  dispatch(resetAffiliatesListFilterForm(data));
};

export const resetAffiliatesListFilterForm = (data) => {
  return {
    type: AFFILIATES_RESET_LIST_FILTER_FORM,
    data,
  };
};

// columns relation
export const updateAffiliatesListColumnsForm =
  (field, value) => (dispatch, getState) => {
    const state = getState().affiliatesListColumnsForm || {};
    if (field.type == "push") {
      value = update(new Set(state[field.name].value), { $add: [field.value] });
    }
    if (field.type == "remove") {
      value = update(new Set(state[field.name].value), {
        $remove: [field.value],
      });
    }
    if (field.type == "set") {
      value = field.value;
    }
    const affiliatesListColumnsForm = update(state, {
      [field.name]: { value: { $set: [...value] } },
    });

    dispatch(updateAffiliatesListColumns(affiliatesListColumnsForm));
  };

export const updateAffiliatesListColumns = (data) => {
  return {
    type: AFFILIATES_UPDATE_LIST_COLUMNS_FORM,
    data,
  };
};

export const applyAffiliatesListColumns = () => (dispatch, getState) => {
  const state = getState().affiliatesListColumnsForm || {};
  dispatch(hideGlobalModal());
  dispatch(applyAffiliatesListColumnsForm(state));
};

export const applyAffiliatesListColumnsForm = (data) => {
  return {
    type: AFFILIATES_APPLY_LIST_COLUMNS,
    data,
  };
};

export const initializeAffiliatesListColumnsForm =
  () => (dispatch, getState) => {
    const currentCols = getState().affiliatesListColumns;
    dispatch(initializeAffiliatesListColumns(currentCols));
  };

export const initializeAffiliatesListColumns = (data) => {
  return {
    type: AFFILIATES_INITIALIZE_LIST_COLUMNS_FORM,
    data,
  };
};

// Nature of Biz section.
export const viewAffiliatesNatureSection = () => {
  return { type: AFFILIATES_VIEW_NATURE_OF_BIZ };
};

export const editAffiliatesNatureSection =
  (initForm = true) =>
  (dispatch, getState) => {
    if (initForm) {
      const profile = getState().affiliatesProfile;
      const data = update(initialAffiliatesNatureOfBizForm, {
        entity_type: { value: { $set: profile.entity_type || [] } },
        expertise_type: { value: { $set: profile.expertise_type || [] } },
        license_type: { value: { $set: profile.license_type || [] } },
      });

      dispatch(initializeNatureDataForm(data));
    }

    dispatch(editAffiliatesNatureSectionMode());
  };

export const editAffiliatesNatureSectionMode = () => {
  return {
    type: AFFILIATES_EDIT_NATURE_OF_BIZ,
  };
};

export const initializeNatureDataForm = (data) => {
  return { type: AFFILIATES_INITIALIZE_NATURE_DATA_FORM, data: data };
};

export const editBaseNatureDataForm = (data) => {
  return { type: AFFILIATES_EDIT_NATURE_OF_BIZ_DATA, data: data };
};

export const submitAffiliatesNatureOfBizData =
  () => async (dispatch, getState) => {
    dispatch(loadingSubmitAffiliatesNatureOfBizData());
    const state = getState();
    const entityId = state.affiliatesProfile.id;
    const result = submitProcessor(
      state,
      "affiliatesNatureOfBizForm",
      initialAffiliatesNatureOfBizForm
    );
    if (result.hasError) {
      dispatch(editAffiliatesNatureSection(false));
      result.errorMsgs.forEach((e) => {
        const msg = `${_.startCase(e.field)}:${e.msg}`;
        notifyCommonError(msg);
      });
      return null;
    }

    try {
      const data = await submitAffiliatesProfileAPI(
        entityId,
        result.payload,
        "patch"
      );
      dispatch(affiliatesProfileData(data));
      notifyCommonSuccess("Successfully updated Nature of Business.");
    } catch (error) {
      notifyCommonError(
        "Error submitting Nature of Business data. Please try again."
      );
    } finally {
      dispatch(viewAffiliatesNatureSection());
    }
  };

export const loadingSubmitAffiliatesNatureOfBizData = () => {
  return {
    type: AFFILIATES_SUBMIT_NATURE_OF_BIZ_DATA,
  };
};

export const affiliatesLicenseCodeEdit = (data) => {
  return { type: AFFILIATES_EDIT_LICENSE_CODE, data: data };
};

export const synchronizeAffiliatesPostalAddress =
  (checked) => (dispatch, getState) => {
    const form = getState().affiliatesBaseDataForm;

    const data = update(form, {
      street_number: { value: { $set: form.postal_street_number.value } },
      street: { value: { $set: form.postal_street.value } },
      country_code: { value: { $set: form.postal_country_code.value } },
      region: { value: { $set: form.postal_region.value } },
      city: { value: { $set: form.postal_city.value } },
      post_code: { value: { $set: form.postal_post_code.value } },
    });
    dispatch(toggleAffiliatesPostalAddress(checked));
    if (checked) {
      dispatch(initializeBaseDataEditForm(data));
    }
  };

export const toggleAffiliatesPostalAddress = (data) => {
  return {
    type: AFFILIATES_TOGGLE_POSTAL_ADDRESS,
    data,
  };
};

//img relation
export const changeAffiliatesImg = (data) => (dispatch) => {
  dispatch(changeUploadImgFactory(data, AFFILIATES_UPLOAD_PREFERENCE_IMAGE));
};

//fetchGeolocation

export const beginAffiliatesAddressPoint = () => {
  return { type: AFFILIATES_BEGIN_FETCH_ADDRESS_DATA };
};

export const endAffiliatesAddressPoint = () => {
  return { type: AFFILIATES_END_FETCH_ADDRESS_DATA };
};

export const fetchAffiliatesAddressPoint = () => (dispatch, getState) => {
  const state = getState();
  const address = state.affiliatesBaseDataForm;
  dispatch(
    fetchCompleteAddressFactory(address, fetchAffiliatesAddressPointDetail)
  );
};

export const fetchAffiliatesAddressPointDetail = (data) => (dispatch) => {
  dispatch(
    fetchAddressPointDetailFactory(
      data,
      AFFILIATES_FETCH_ADDRESS_POINT,
      editAffiliatesBaseDataForm,
      beginAffiliatesAddressPoint,
      endAffiliatesAddressPoint
    )
  );
};

export const verifyAffiliatesPostalAddress = (checked) => {
  return {
    type: AFFILIATES_TOGGLE_VERIFY_ADDRESS,
    data: checked,
  };
};

//default columns

export const applyAffiliateDefaultOption = () => (dispatch) => {
  const choices = _.flatten(
    Object.values(initialAffiliatesColumnsSetUp).map((o) => o.choices)
  );
  defaultOption.basic.choices = choices;
  const currentCols = entitiesListSelector(defaultOption);
  dispatch(initializeAffiliatesListColumns(currentCols));
};
