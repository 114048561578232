import { variables } from "@src/jsssetup";
import PropTypes from "prop-types";
import React from "react";

export default class SuIcon extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { size, icon, className, style } = this.props;

    return (
      <span
        style={style ? style : {}}
        onClick={this.props.onClick}
        className={`sisi-family sisi-family--${size} ${icon} ${
          className || ""
        }`}
      />
    );
  }
}

SuIcon.defaultProps = {
  size: "sm",
};

SuIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
  size: PropTypes.oneOf(["xl", "lg", "md", "sm"]),
  onClick: PropTypes.func,
};
