import {
  // ========================== INIT ACTIONS =========================
  TRIPPLANNER_INIT,
  // ======================= TRIP LOAD ACTIONS =======================
  TRIPPLANNER_LOAD_TRIP,
  // ===================== CONTROL PANEL ACTIONS =====================
  TRIPPLANNER_REQUEST_SAVE_TRIP,
  TRIPPLANNER_CANCEL_CONTROL_PANEL_REQUEST,
  TRIPPLANNER_SAVING_TRIP,
  TRIPPLANNER_SAVED_TRIP,
  // ===================== CONTROL PANEL ACTIONS =====================
  TRIPPLANNER_VIEW_TRIP_PREFERENCES,
  TRIPPLANNER_HIDE_TRIP_PREFERENCES,
  // ======================== TRIP WAIT MODAL ========================
  TRIPPLANNER_SHOW_TRIP_WAIT_MODAL,
  TRIPPLANNER_HIDE_TRIP_WAIT_MODAL,
  // ====================== PREBOOK FLIGHT RULES =====================
  TRIPPLANNER_SHOW_PREBOOKFLIGHTRULES_MODAL,
  TRIPPLANNER_LOADING_PREBOOKFLIGHTRULES_MODAL,
  TRIPPLANNER_IDLE_PREBOOKFLIGHTRULES_MODAL,
  // ===================== PREBOOK ACCOMMODATION =====================
  TRIPPLANNER_SHOW_PREBOOKACCOMMODATION_MODAL,
  TRIPPLANNER_HIDE_PREBOOKACCOMMODATION_MODAL,
  TRIPPLANNER_IDLE_PREBOOKACCOMMODATION_MODAL,
  TRIPPLANNER_LOADING_PREBOOKACCOMMODATION_MODAL,
  // ========================= BOOKING MODAL =========================
  TRIPPLANNER_SHOW_BOOKING_MODAL,
  TRIPPLANNER_HIDE_BOOKING_MODAL,
  TRIPPLANNER_BOOKING_DATA_UPDATE,
  TRIPPLANNER_REQUEST_UPDATE_OFFER,
  TRIPPLANNER_REQUEST_UPDATE_OFFER_HIDE_MODAL,
  TRIPPLANNER_REQUEST_UPDATE_OFFER_LOADING,
  TRIPPLANNER_REQUEST_UPDATE_OFFER_IDLE,
} from "@src/actions/Project/TripPlanner/types";

import moment from "moment";
import update from "immutability-helper";
import {
  TRIPPLANNER_QUOTATION_MODAL_HIDE,
  TRIPPLANNER_QUOTATION_MODAL_IDLE,
  TRIPPLANNER_QUOTATION_MODAL_LOADING,
  TRIPPLANNER_QUOTATION_MODAL_SHOW,
  TRIPPLANNER_SAVEAS_MODAL_CHANGE,
  TRIPPLANNER_SAVEAS_MODAL_HIDE,
  TRIPPLANNER_SAVEAS_MODAL_IDLE,
  TRIPPLANNER_SAVEAS_MODAL_LOADING,
  TRIPPLANNER_SAVEAS_MODAL_SHOW,
  TRIPPLANNER_SERVICE_COMMENTS_MODAL_SHOW,
  TRIPPLANNER_SERVICE_COMMENTS_MODAL_HIDE,
} from "@src/actions/Project/TripPlanner/types/modal_types";

const tripPlannerRequestSaveModalInitial = {
  show: false,
  loading: false,
  id: null,
  uuid: null,
  reference: null,
};
export const tripPlannerRequestSaveModal = (
  state = tripPlannerRequestSaveModalInitial,
  action
) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return tripPlannerRequestSaveModalInitial;
    case TRIPPLANNER_LOAD_TRIP:
      return tripPlannerRequestSaveModalInitial;
    case TRIPPLANNER_REQUEST_SAVE_TRIP:
      return update(state, { show: { $set: true } });
    case TRIPPLANNER_CANCEL_CONTROL_PANEL_REQUEST:
      return tripPlannerRequestSaveModalInitial;
    case TRIPPLANNER_SAVING_TRIP:
      return update(state, { loading: { $set: true } });
    case TRIPPLANNER_SAVED_TRIP:
      return tripPlannerRequestSaveModalInitial;
    default:
      return state;
  }
};

const tripPlannerPreferencesModalInitial = { show: false };
export const tripPlannerPreferencesModal = (
  state = tripPlannerPreferencesModalInitial,
  action
) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return tripPlannerPreferencesModalInitial;
    case TRIPPLANNER_LOAD_TRIP:
      return tripPlannerPreferencesModalInitial;
    case TRIPPLANNER_VIEW_TRIP_PREFERENCES:
      return update(state, { show: { $set: true } });
    case TRIPPLANNER_HIDE_TRIP_PREFERENCES:
      return tripPlannerPreferencesModalInitial;
    default:
      return state;
  }
};

const tripPlannerTripWaitModalInitial = { show: false, msg: "Please wait..." };
export const tripPlannerTripWaitModal = (
  state = tripPlannerTripWaitModalInitial,
  action
) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return tripPlannerTripWaitModalInitial;
    case TRIPPLANNER_LOAD_TRIP:
      return tripPlannerTripWaitModalInitial;
    case TRIPPLANNER_SHOW_TRIP_WAIT_MODAL:
      return update(state, { show: { $set: true }, msg: { $set: action.msg } });
    case TRIPPLANNER_HIDE_TRIP_WAIT_MODAL:
      return tripPlannerTripWaitModalInitial;
    default:
      return state;
  }
};

const tripPlannerPrebookFlightRulesInitial = { show: false, loading: false };
export const tripPlannerPrebookFlightRulesModal = (
  state = tripPlannerPrebookFlightRulesInitial,
  action
) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return tripPlannerPrebookFlightRulesInitial;
    case TRIPPLANNER_LOAD_TRIP:
      return tripPlannerPrebookFlightRulesInitial;
    case TRIPPLANNER_SHOW_PREBOOKFLIGHTRULES_MODAL:
      return update(state, { show: { $set: true } });
    case TRIPPLANNER_IDLE_PREBOOKFLIGHTRULES_MODAL:
      return update(state, { loading: { $set: false } });
    case TRIPPLANNER_LOADING_PREBOOKFLIGHTRULES_MODAL:
      return update(state, { loading: { $set: true } });
    default:
      return state;
  }
};

const tripPlannerPrebookAccModalInitial = { show: false, loading: false };
export const tripPlannerPrebookAccModal = (
  state = tripPlannerPrebookAccModalInitial,
  action
) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return tripPlannerPrebookAccModalInitial;
    case TRIPPLANNER_LOAD_TRIP:
      return tripPlannerPrebookAccModalInitial;
    case TRIPPLANNER_SHOW_PREBOOKACCOMMODATION_MODAL:
      return update(state, { show: { $set: true } });
    case TRIPPLANNER_HIDE_PREBOOKACCOMMODATION_MODAL:
      return tripPlannerPrebookAccModalInitial;
    case TRIPPLANNER_IDLE_PREBOOKACCOMMODATION_MODAL:
      return update(state, { loading: { $set: false } });
    case TRIPPLANNER_LOADING_PREBOOKACCOMMODATION_MODAL:
      return update(state, { loading: { $set: true } });
    default:
      return state;
  }
};

const tripPlannerBookingModalInitial = {
  show: false,
  id: null,
  uuid: null,
  reference: null,
  status_data: {},
  status_timestamp: null,
  notificationsShown: 0,
  failed: false,
};
export const tripPlannerBookingModal = (
  state = tripPlannerBookingModalInitial,
  action
) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return tripPlannerBookingModalInitial;
    case TRIPPLANNER_LOAD_TRIP:
      return tripPlannerBookingModalInitial;
    case TRIPPLANNER_SHOW_BOOKING_MODAL:
      return update(state, {
        show: { $set: true },
        status_timestamp: { $set: moment().toISOString() },
      });
    case TRIPPLANNER_HIDE_BOOKING_MODAL:
      return tripPlannerBookingModalInitial;
    case TRIPPLANNER_BOOKING_DATA_UPDATE: {
      const {
        servicebundle: { id, uid, reference },
      } = action;

      return update(state, {
        id: { $set: id },
        uid: { $set: uid },
        reference: { $set: reference },
      });
    }
    default:
      return state;
  }
};

const tripPlannerRequestUpdateOfferModalInitial = {
  show: false,
  loading: false,
};
export const tripPlannerRequestUpdateOfferModal = (
  state = tripPlannerRequestUpdateOfferModalInitial,
  action
) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return tripPlannerRequestUpdateOfferModalInitial;
    case TRIPPLANNER_LOAD_TRIP:
      return tripPlannerRequestUpdateOfferModalInitial;
    case TRIPPLANNER_REQUEST_UPDATE_OFFER:
      return { ...state, show: true };
    case TRIPPLANNER_REQUEST_UPDATE_OFFER_LOADING:
      return { ...state, loading: true };
    case TRIPPLANNER_REQUEST_UPDATE_OFFER_IDLE:
      return { ...state, loading: false };
    case TRIPPLANNER_REQUEST_UPDATE_OFFER_HIDE_MODAL:
      return tripPlannerRequestUpdateOfferModalInitial;
    default:
      return state;
  }
};

const tripPlannerSaveAsModalInitial = {
  show: false,
  loading: false,
  title: "",
  usage: "",
};
export const tripPlannerSaveAsModal = (
  state = tripPlannerSaveAsModalInitial,
  action
) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return tripPlannerSaveAsModalInitial;
    case TRIPPLANNER_LOAD_TRIP:
      return tripPlannerSaveAsModalInitial;
    case TRIPPLANNER_SAVEAS_MODAL_SHOW: {
      const { title, usage } = action;
      return { ...state, show: true, title, usage };
    }
    case TRIPPLANNER_SAVEAS_MODAL_HIDE:
      return tripPlannerSaveAsModalInitial;
    case TRIPPLANNER_SAVEAS_MODAL_LOADING:
      return { ...state, loading: true };
    case TRIPPLANNER_SAVEAS_MODAL_IDLE:
      return { ...state, loading: false };
    case TRIPPLANNER_SAVEAS_MODAL_CHANGE: {
      const { key, value } = action;
      return { ...state, [key]: value };
    }
    default:
      return state;
  }
};

export const tripPlannerQuotationPDFModalFormInitial = {
  offer_title: "",
  last_name: "",
  first_name: "",
  email: "",
  price_info: false,
  description: "",
};
const tripPlannerQuotationPDFModalInitial = {
  show: false,
  loading: false,
  form: tripPlannerQuotationPDFModalFormInitial,
};
export const tripPlannerQuotationPDFModal = (
  state = tripPlannerQuotationPDFModalInitial,
  action
) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return tripPlannerQuotationPDFModalInitial;
    case TRIPPLANNER_LOAD_TRIP:
      return tripPlannerQuotationPDFModalInitial;
    case TRIPPLANNER_QUOTATION_MODAL_SHOW:
      return { ...state, show: true };
    case TRIPPLANNER_QUOTATION_MODAL_HIDE:
      return { ...state, show: false };
    case TRIPPLANNER_QUOTATION_MODAL_LOADING:
      return { ...state, loading: true };
    case TRIPPLANNER_QUOTATION_MODAL_IDLE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

const tripPlannerServiceCommentsModalInitial = {
  show: false,
  loading: false,
  serviceType: "",
  destOrder: 0,
};

export const tripPlannerServiceCommentsModal = (
  state = tripPlannerServiceCommentsModalInitial,
  action
) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return tripPlannerServiceCommentsModalInitial;
    case TRIPPLANNER_LOAD_TRIP:
      return tripPlannerServiceCommentsModalInitial;
    case TRIPPLANNER_SERVICE_COMMENTS_MODAL_SHOW: {
      const { destOrder } = action;
      return { ...state, show: true, destOrder };
    }
    case TRIPPLANNER_SERVICE_COMMENTS_MODAL_HIDE:
      return tripPlannerServiceCommentsModalInitial;
    default:
      return state;
  }
};
