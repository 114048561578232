import {
  CUSTOM_SERVICE_ADDON_ADD_STOP_SALES,
  CUSTOM_SERVICE_ADDON_REMOVE_STOP_SALES,
  CUSTOM_SERVICE_ADDON_CHANGE_STOP_SALES,
  CUSTOM_SERVICE_ADDON_CHANGE_OFFERS,
} from "./types";

export const addStopSales = () => {
  return { type: CUSTOM_SERVICE_ADDON_ADD_STOP_SALES };
};

export const removeStopSales = (idx) => {
  return { type: CUSTOM_SERVICE_ADDON_REMOVE_STOP_SALES, idx };
};

export const changeStopSales = ({ stop_sales }) => {
  return { type: CUSTOM_SERVICE_ADDON_CHANGE_STOP_SALES, stop_sales };
};

export const changeOffers = ({ offers }) => {
  return { type: CUSTOM_SERVICE_ADDON_CHANGE_OFFERS, offers };
};
