import _ from "lodash";
import { Button } from "rsuite";

import {
  NormalDatePicker,
  NormalInputField,
  NormalSelectField,
} from "@src/components/forms";
import {
  cardStyles,
  formStyles,
  modalGenericStyles,
  variables,
} from "@src/jsssetup";
import { Formik, Form } from "formik";
import PropTypes from "prop-types";
import React, { useCallback, useContext } from "react";
import { createUseStyles } from "react-jss";
import {
  AdhocSrvPriceFields,
  adhocTrpTrfCommonFields,
  getOriginAndDest,
  getReferenceDates,
  adhocPointStyles,
} from "./coach";
import { addonSrvPriceCalculator, Cabins, Extras } from "./common";
import { DayItemControls, daySrvContainerStyles } from "../../../Day";
import {
  daySrvBodyStyles,
  daySrvHeaderStyles,
  serviceHeaderActions,
} from "../../../Day/common";
import { useDispatch, useSelector } from "react-redux";
import { isServiceFilterSelectedSelector } from "@src/selectors/Project/TripPlanner/service_filters";
import {
  getServicePricingSelector,
  srvPriceKeyCreator,
} from "@src/selectors/Project/TripPlanner/pricing";
import {
  PricingFormContext,
  TripPlanContext,
} from "@src/pages/Project/TripPlan/TripPlanner";
import { BookChecker } from "../../../ItineraryOverviewCommon";
import { DateTime } from "luxon";
import ServicePricingForm from "../../../ServicePricingForm";
import OverviewPrice from "../../../OverviewPrice";
import { AdhocSrvAttribute } from "../../../addhoc";
import { removeTrpAdhocSrv } from "@src/actions/Project/TripPlanner/addhoc/transportation";
import { BookingMetadataBtn } from "@src/pages/Project/TripPlan/Widgets/step_four/booking_metadata_btn";
import { BookedTag, ReservationStatusTag } from "../../../service_panels";
import { srvIsBookedSelector } from "@src/selectors/Project/TripPlanner/booking_service_select";
import { useServiceCardExpansion } from "@src/pages/hooks";
import { InlineIcon } from "@iconify/react";

const trainAdhocSrvFormFieldsStyles = createUseStyles({
  TrainAdhocSrv: modalGenericStyles.card,
  header: modalGenericStyles.cardHeader,
  body: modalGenericStyles.cardBody,
  actions: modalGenericStyles.cardActions,
  form: {
    ...formStyles.form,
    display: "grid",
    gridGap: variables.normal_gap,
  },
  col2: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: variables.normal_gap,
  },
  col3: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridGap: variables.normal_gap,
  },
  trpPoint: adhocPointStyles,
});
export const TrainAdhocSrvFormFields = ({
  origin_name,
  destination_name,
  values,
  setFieldValue,
  children,
}) => {
  const classes = trainAdhocSrvFormFieldsStyles();
  return (
    <Form className={classes.form}>
      <div className={classes.col2}>
        <span className={classes.trpPoint}>
          <strong>Origin: {origin_name || "N/A"}</strong>
        </span>
        <span className={classes.trpPoint}>
          <strong>Destination: {destination_name || "N/A"}</strong>
        </span>
        <NormalInputField name="origin_station" label="Origin Station" />
        <NormalInputField
          name="destination_station"
          label="Destination Station"
        />
        <NormalDatePicker name="departure" label="Departure" withTime={true} />
        <NormalDatePicker name="arrival" label="Arrival" withTime={true} />
      </div>
      <div className={classes.col2}>
        <NormalInputField name="company_name" label="Company Name" />
        <NormalInputField name="reference" label="Reference" />
        <NormalInputField name="train_number" label="Train Route Number" />
        <NormalSelectField
          name="ticket_class"
          label="Ticket Class"
          options={[
            [null, "------"],
            ...Object.entries(trainTicketClassMapping),
          ]}
        />
      </div>
      <Cabins
        cabins={values.cabins}
        cabinTypeMapping={trainCabinTypeMapping}
        setFieldValue={setFieldValue}
      />
      <Extras
        extras={values.extras}
        extraTypeMapping={extraTypeMapping}
        setExtras={(extras) => setFieldValue("extras", extras)}
      />
      <div className={classes.col2}>
        <AdhocSrvPriceFields />
      </div>
      <NormalInputField
        name="booking_information"
        label="Booking Information"
        as="textarea"
      />
      {children}
    </Form>
  );
};
TrainAdhocSrvFormFields.propTypes = {
  origin_name: PropTypes.string.isRequired,
  destination_name: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

function dater(dt) {
  return DateTime.fromISO(dt).toLocaleString(
    DateTime.DATETIME_MED_WITH_WEEKDAY
  );
}

export const trainTicketClassMapping = {
  1: "First Class",
  2: "Second Class",
  3: "Standard",
  3: "Comfort",
  4: "Premium",
};

export const trainCabinTypeMapping = {
  "2_berth": "Two Berth",
  "3_berth": "Three Berth",
  "4_berth": "Four Berth",
  "6_berth": "Six Berth",
};

export const extraTypeMapping = {
  "2MEAL": "2 Course Meal",
  "3MEAL": "3 Course Meal",
  "4MEAL": "4 Course Meal",
  "OTH": "Other",
};

const styles = createUseStyles({
  TrainAdhocSrv: modalGenericStyles.card,
  header: modalGenericStyles.cardHeader,
  body: modalGenericStyles.cardBody,
  actions: modalGenericStyles.cardActions,
  form: {
    display: "grid",
    gridGap: variables.normal_gap,
  },
  col2: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: variables.normal_gap,
  },
  col3: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridGap: variables.normal_gap,
  },
  trpPoint: adhocPointStyles,
});
export const TrainAdhocSrvForm = ({
  adults,
  childs,
  destination_dest,
  origin_dest,
  onCancel,
  onSelect,
}) => {
  const classes = styles();

  const { referenceDeparture, referenceArrival } = getReferenceDates({
    destination_dest,
    origin_dest,
  });

  const { origin, destination } = getOriginAndDest({
    origin_dest,
    destination_dest,
  });

  const origin_name = origin.name_en;
  const destination_name = destination.name_en;

  return (
    <div className={classes.TrainAdhocSrv}>
      <div className={classes.header}>
        <h5>Add Train Service</h5>
      </div>
      <Formik
        initialValues={{
          origin,
          destination,
          origin_station: "",
          destination_station: "",
          ...adhocTrpTrfCommonFields({
            referenceDeparture,
            referenceArrival,
          }),
          train_number: "",
          image: {},
          company_name: "",
          booking_information: "",
          ticket_class: "",
          cancellation_policy: [],
          cabins: [],
          extras: [],
        }}
        validate={(values) =>
          addonSrvPriceCalculator({ values, adults, childs })
        }
        onSubmit={(values) => onSelect(values)}>
        {({ values, setFieldValue, submitForm }) => (
          <React.Fragment>
            <div className={classes.body}>
              <TrainAdhocSrvFormFields
                origin_name={origin_name}
                destination_name={destination_name}
                values={values}
                setFieldValue={setFieldValue}
              />
            </div>
            <div className={classes.actions}>
              <Button appearance="ghost" onClick={onCancel}>
                <strong>Cancel</strong>
              </Button>
              <Button appearance="primary" onClick={submitForm}>
                <strong>Add</strong>
              </Button>
            </div>
          </React.Fragment>
        )}
      </Formik>
    </div>
  );
};
TrainAdhocSrvForm.defaultProps = {
  destination_dest: {},
  origin_dest: {},
};
TrainAdhocSrvForm.propTypes = {
  adults: PropTypes.number.isRequired,
  childs: PropTypes.number.isRequired,
  destination_dest: PropTypes.object.isRequired,
  origin_dest: PropTypes.object.isRequired,
  onCancel: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
};

const trainSrvDataStyles = createUseStyles({
  TrainSrvData: {
    display: "grid",
    gridGap: variables.half_gap,
  },
  header: {
    ...daySrvHeaderStyles(),
    ...cardStyles.card,
    paddingLeft: variables.double_gap,
    background: variables.colors.services.trpService,
    borderRadius: `calc(${variables.normal_gap} / 2)`,
  },
  headerActions: serviceHeaderActions,
  body: (props) => ({
    ...daySrvBodyStyles(props),
    ...cardStyles.card,
    paddingLeft: variables.double_gap,
    background: variables.colors.services.trpServiceBody,
    overflow: "hidden",
    height: "auto",
  }),
  img: {
    objectFit: "cover",
    width: "100%",
    borderRadius: "5px",
    height: "8rem",
    alignSelf: "center",
  },
  content: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    gridAutoRows: "max-content",
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  expandIconCntr: {
    display: "grid",
    placeContent: "center",
    width: "1.5rem",
    height: "1.5rem",
    borderRadius: "50%",
    background: variables.colors.easy.orange,
    padding: variables.half_gap,
    cursor: "pointer",
  },
  expandIcon: {
    fontSize: "large",
    color: variables.colors.text.light,
  },
});
export const TrainSrvData = ({
  service,
  reservationMode,
  reservationLoading,
  reservationStatus,
  eventType,
  services_collapsed,
  toggleSelfCollapse,
}) => {
  const classes = trainSrvDataStyles();

  const { isBooked, reservation_reference } = useSelector((state) =>
    srvIsBookedSelector(state, {
      reference: service.uid,
      srvType: "TRA",
    })
  );

  return (
    <div className={classes.TrainSrvData}>
      <div className={classes.header}>
        <span>
          Train Service{eventType && ` ${_.startCase(eventType)}`}:{" "}
          {service.origin.name_en} to {service.destination.name_en}
        </span>
        <div className={classes.headerActions}>
          {!reservationMode ? (
            <BookingMetadataBtn
              uid={service.uid}
              srv_type="TRA"
              booking_metadata={service.booking_metadata}
            />
          ) : (
            <ReservationStatusTag
              reservationLoading={reservationLoading}
              reservationStatus={reservationStatus}
            />
          )}
          {isBooked && (
            <BookedTag reservation_reference={reservation_reference} />
          )}
          <div className={classes.expandIconCntr}>
            <InlineIcon
              className={classes.expandIcon}
              icon={
                services_collapsed
                  ? "ri:arrow-down-s-line"
                  : "ri:arrow-up-s-line"
              }
              onClick={toggleSelfCollapse}
            />
          </div>
        </div>
      </div>
      {services_collapsed ? null : (
        <div className={classes.body}>
          <div className={classes.content}>
            <AdhocSrvAttribute
              label="Departure"
              value={dater(service.departure)}
            />
            <AdhocSrvAttribute
              label="Ticket Class"
              value={_.get(
                trainTicketClassMapping,
                service.ticket_class,
                "N/A"
              )}
            />
            <AdhocSrvAttribute label="Arrival" value={dater(service.arrival)} />
            <AdhocSrvAttribute
              label="Cabins"
              value={
                (service?.cabins || []).length === 0
                  ? "N/A"
                  : (service?.cabins || []).length
              }
            />
            <AdhocSrvAttribute label="Origin" value={service.origin_station} />
            <AdhocSrvAttribute
              label="Destination"
              value={service.destination_station}
            />
            <AdhocSrvAttribute
              label="Train Number"
              value={service.train_number}
            />
            <AdhocSrvAttribute label="Company" value={service.company_name} />
          </div>
        </div>
      )}
    </div>
  );
};
TrainSrvData.defaultProps = {
  reservationMode: false,
  services_collapsed: false,
};
TrainSrvData.propTypes = {
  service: PropTypes.object.isRequired,
  reservationMode: PropTypes.bool.isRequired,
  reservationLoading: PropTypes.bool,
  reservationStatus: PropTypes.string,
  eventType: PropTypes.oneOf(["arrival", "departure"]),
  services_collapsed: PropTypes.bool.isRequired,
  toggleSelfCollapse: PropTypes.func,
};

const trainServiceStyles = createUseStyles({
  TrainServiceContainer: (props) => ({
    ...daySrvContainerStyles({ currentStep: props.currentStep }),
  }),
  TrainService: {
    ...cardStyles.card,
    background: variables.colors.services.trpService,
  },
  header: {
    ...daySrvHeaderStyles(),
    borderBottomColor: variables.colors.services.trpServiceBorder,
  },
  body: (props) => ({
    ...daySrvBodyStyles(props),
    gridTemplateColumns: "2fr 7fr",
    overflow: "hidden",
    background: variables.colors.services.trpServiceBody,
  }),
  img: {
    objectFit: "cover",
    width: "100%",
    borderRadius: "5px",
    height: "8rem",
    alignSelf: "center",
  },
  content: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    gridAutoRows: "max-content",
    gridTemplateColumns: "repeat(2, 1fr)",
  },
});
export const TrainService = ({ srv, eventType, onEdit }) => {
  const { service } = srv;
  const currentStep = useSelector((state) => state.tripPlannerCurrentStep);
  const serviceIsSelected = useSelector((state) =>
    isServiceFilterSelectedSelector(state, { service_type: "TR" })
  );

  const { markup, gross_margin, flat_value } = useSelector((state) =>
    getServicePricingSelector(state, {
      key: srvPriceKeyCreator({ service: srv.service, service_type: "TR" }),
    })
  );

  const { showPricingForm } = useContext(PricingFormContext);
  const { services_collapsed, toggleSelfCollapse } = useServiceCardExpansion();

  const classes = trainServiceStyles({ currentStep });

  const price = service.total_price;

  const currency = service.currency;

  const dispatch = useDispatch();
  const onRemove = useCallback(
    ({ service_uid }) => dispatch(removeTrpAdhocSrv({ service_uid })),
    [dispatch]
  );

  return !serviceIsSelected || !service ? null : (
    <div className={classes.TrainServiceContainer}>
      {currentStep === 4 ? (
        <BookChecker uid={service.uid} srv_type="TRA" />
      ) : !services_collapsed && typeof onEdit === "function" ? (
        <DayItemControls
          onEdit={() => onEdit({ srv_id: service.uid })}
          onDeselect={() => onRemove({ service_uid: service.uid })}
        />
      ) : (
        <div />
      )}
      <TrainSrvData
        service={service}
        eventType={eventType}
        services_collapsed={services_collapsed}
        toggleSelfCollapse={toggleSelfCollapse}
      />
      {showPricingForm ? (
        <ServicePricingForm service_type="TRA" service={service} />
      ) : services_collapsed ? (
        <div />
      ) : (
        <OverviewPrice
          searching={false}
          serviceType="MI"
          price={price}
          currency={currency}
          markup={markup}
          gross_margin={gross_margin}
          flat_value={flat_value}
        />
      )}
    </div>
  );
};
TrainService.defaultProps = {
  srv: {},
};
TrainService.propTypes = {
  srv: PropTypes.object.isRequired,
  eventType: PropTypes.oneOf(["arrival", "departure"]),
  onRemove: PropTypes.func,
  onEdit: PropTypes.func.isRequired,
};
