import _ from "lodash";
import { prebookFerry } from "@src/api/Project/ferries";
import { useQueries } from "@tanstack/react-query";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
import { InlineIcon } from "@iconify/react";
import { PriceExcHoc } from "../../../PriceDetails";
import CancellationPolicyDetails from "../../../CancellationPolicyDetails";
import { NormalCheckboxField } from "@src/components/forms";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import { Loader } from "rsuite";
import { updateFerryData } from "@src/actions/Project/TripPlanner/ferries";

function dateFn(dt) {
  return DateTime.fromISO(dt)
    .setZone("UTC", { keepLocalTime: false })
    .toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY);
}

const TripInfo = ({ leg, operatorIcon = "" }) => {
  return (
    <div className="TripInfo">
      <div className="TripInfo__origin-section">
        <span className="TripInfo__port">{leg.departure_port_name}</span>
        <span className="TripInfo__trip-time">
          {DateTime.fromISO(leg.departure_datetime)
            .setZone("UTC", {
              keepLocalTime: false,
            })
            .toLocaleString(DateTime.TIME_SIMPLE)}
        </span>
        <span className="TripInfo__trip-date">
          {DateTime.fromISO(leg.departure_datetime)
            .setZone("UTC", {
              keepLocalTime: false,
            })
            .toLocaleString(DateTime.DATE_MED)}
        </span>
      </div>
      <div className="TripInfo__duration-section">
        <div className="TripInfo__duration">
          {/* humanizeDurationFromMins(leg.duration_minutes) */}
        </div>
        <div className="TripInfo__arrow-container">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="95"
            height="20"
            viewBox="0 0 95 20"
            fill="none">
            <path
              d="M0.666667 8C0.666666 10.9455 3.05448 13.3333 6 13.3333C8.94552 13.3333 11.3333 10.9455 11.3333 8C11.3333 5.05448 8.94552 2.66667 6 2.66667C3.05448 2.66667 0.666667 5.05448 0.666667 8ZM94.7071 8.70711C95.0976 8.31659 95.0976 7.68343 94.7071 7.2929L88.3431 0.928939C87.9526 0.538415 87.3195 0.538415 86.9289 0.928939C86.5384 1.31946 86.5384 1.95263 86.9289 2.34315L92.5858 8.00001L86.9289 13.6569C86.5384 14.0474 86.5384 14.6806 86.9289 15.0711C87.3195 15.4616 87.9526 15.4616 88.3431 15.0711L94.7071 8.70711ZM6 9L94 9.00001L94 7.00001L6 7L6 9Z"
              className="TripInfo__arrow"
            />
          </svg>
        </div>
        <div className="TripInfo__ferry-name">
          {leg?.vessel_data?.name ?? ""}
        </div>
      </div>
      <div className="TripInfo__destination-section">
        <span className="TripInfo__port">{leg.arrival_port_name}</span>
        <span className="TripInfo__trip-time">
          {DateTime.fromISO(leg.arrival_datetime)
            .setZone("UTC", {
              keepLocalTime: false,
            })
            .toLocaleString(DateTime.TIME_SIMPLE)}
        </span>
        <span className="TripInfo__trip-date">
          {DateTime.fromISO(leg.arrival_datetime)
            .setZone("UTC", {
              keepLocalTime: false,
            })
            .toLocaleString(DateTime.DATE_MED)}
        </span>
      </div>
      {operatorIcon && (
        <img
          src={operatorIcon}
          alt="provider icon"
          className="TripInfo__operator-icon"
        />
      )}
    </div>
  );
};

var Price = ({ price, currency }) => {
  return (
    <span className="FerriesPrebook__prebook__total-price-value">
      {price.toLocaleString("en-US", { style: "currency", currency })}
    </span>
  );
};
Price = PriceExcHoc(Price);

export const PrebookFerriesModal = ({ onCancel }) => {
  const { ferries, adults, children } = useSelector((state) => {
    const ferries = state.tripPlannerFerriesReducer?.services ?? [];
    const { adults, children } = state.tripPlannerGuestsInfo;
    return { ferries, adults, children };
  });

  const queries = useQueries({
    queries: ferries.map((f) => {
      const session_id = f.session_id;
      const provider = f.provider;
      const token = f.token;
      const legs = (f?.legs ?? []).map((l, idx) => {
        return {
          is_return_leg: idx > 0,
          accommodations: l.accommodations.map((a) => ({
            id: a.id,
            is_shared: a.is_shared,
            quantity: a.quantity,
          })),
        };
      });
      const passengers = adults.map((p) => ({
        title: p.guest_title,
        is_leader: p.group_leader,
        first_name: p.first_name,
        last_name: p.last_name,
        nationality: p.nationality,
        birthday: p.date_of_birth,
        email: p.email,
        phone: p.phone,
        uid: p.guest_uid,
        telephone: p.phone,
        age: 18,
      }));
      return {
        queryKey: ["ferry_prebook", token],
        queryFn: () => {
          return prebookFerry({
            session_id,
            provider,
            token,
            passengers,
            legs,
          });
        },
        onError: () => {
          toast.error(
            "Failed to fetch ferry prebook information. Please try re-searching for ferries.",
            { autoClose: 5000 }
          );
          onCancel();
        },
        refetchOnWindowFocus: false,
        retries: 1,
      };
    }),
  });
  const isFetching = queries.some((q) => q.isFetching);
  const isLoading = queries.some((q) => q.isLoading);
  const loading = isFetching || isLoading;

  const initialValues = { ferries: {} };
  queries.forEach((q) => {
    var token = null;
    try {
      token = JSON.parse(q.data.config.data).token;
    } catch {
      return;
    }
    initialValues.ferries[token] = false;
  });

  const dispatch = useDispatch();
  const onAcceptFerryPrebook = useCallback(
    ({ token, key, data }) => {
      dispatch(updateFerryData({ token, key, data }));
    },
    [dispatch]
  );

  return (
    <div className="Modal FerriesPrebook">
      {loading ? (
        <div className="Modal__card">
          <div className="Modal__card__header">
            <h5>Ferries Prebook</h5>
          </div>
          <div className="Modal__card__body">
            <Loader center size="lg" />
          </div>
        </div>
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            if (_.isEmpty(values.ferries)) return;
            if (Object.values(values.ferries).some((v) => !v)) {
              toast.error(
                "Please accept all ferry prebook information before continuying.",
                { autoClose: 5000 }
              );
              return;
            }
            queries.forEach((q) => {
              var token = null;
              try {
                token = JSON.parse(q.data.config.data).token;
              } catch {
                return;
              }

              const data = q?.data?.data;
              onAcceptFerryPrebook({ token, key: "prebookData", data });
            });

            onCancel();
          }}>
          {({ submitForm }) => {
            return (
              <div className="Modal__card">
                <div className="Modal__card__header">
                  <h5>Ferries Prebook</h5>
                </div>
                <div className="Modal__card__body">
                  <Form className="FerriesPrebook__prebooks">
                    {queries
                      .map((q, idx) => {
                        var token = null;
                        try {
                          token = JSON.parse(q.data.config.data).token;
                        } catch {
                          return null;
                        }

                        const data = q?.data?.data;
                        const legs = data?.legs ?? [];

                        return (
                          <div key={idx} className="Card">
                            <div className="Card__header">
                              <h5>
                                {idx + 1}:{" "}
                                {[
                                  ...new Set(
                                    _.flatten(
                                      legs.map((l) => [
                                        `${l.departure_port_name} (${l.departure_port_code})`,
                                        `${l.arrival_port_name} (${l.arrival_port_code})`,
                                      ])
                                    )
                                  ),
                                ].join(" - ")}
                              </h5>
                              <div className="Card__header__actions">
                                <NormalCheckboxField
                                  name={`ferries.${token}`}
                                  label="Accept"
                                  highlightErrors={true}
                                />
                              </div>
                            </div>
                            <div className="Card__body FerriesPrebook__prebook">
                              <div className="FerriesPrebook__prebook__content">
                                <div>
                                  {dateFn(legs?.[0]?.departure_datetime ?? "")}
                                  {(legs?.[1]?.is_return_leg ?? false) &&
                                    ` - ${dateFn(
                                      legs?.[1]?.departure_datetime ?? ""
                                    )}`}
                                </div>
                                <div className="FerriesPrebook__prebook__info">
                                  <div className="FerriesPrebook__prebook__info-title">
                                    Passengers
                                  </div>
                                  <div className="FerriesPrebook__prebook__passengers">
                                    {(data?.detailed_passengers ?? []).map(
                                      (pax, idx) => (
                                        <div key={idx}>
                                          <InlineIcon
                                            icon="ic:baseline-person"
                                            className="FerriesPrebook__prebook__pax-icon"
                                          />
                                          {` ${pax.title} ${pax.first_name} ${pax.last_name} `}
                                          <span>
                                            {`(${pax.age_category})`}
                                            {pax.is_leader && ` (Leader)`}
                                          </span>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                                <div className="FerriesPrebook__prebook__divider-gray-color" />
                                {legs.map((leg, idx) => (
                                  <TripInfo
                                    key={idx}
                                    leg={leg}
                                    operatorIcon={leg.operator.logo}
                                  />
                                ))}
                                <div className="FerriesPrebook__prebook__divider-base-color" />
                                <div className="FerriesPrebook__prebook__price-details">
                                  {legs.map((leg, idx) => (
                                    <div key={idx}>
                                      <span>
                                        {idx === 0 ? "Outbound" : "Inbound"} (
                                        {leg.route_name})
                                      </span>
                                      {leg.accommodations.map((acc, index) => (
                                        <div
                                          className="FerriesPrebook__prebook__num-of-seats"
                                          key={index}>
                                          <span>
                                            {
                                              (acc?.description ?? "").split(
                                                "|"
                                              )?.[0]
                                            }
                                          </span>
                                          <strong>
                                            {` x `}
                                            {acc.quantity}
                                          </strong>
                                        </div>
                                      ))}
                                    </div>
                                  ))}
                                  <div className="FerriesPrebook__prebook__divider-gray-color" />
                                  <div className="FerriesPrebook__prebook__total-price">
                                    Total:{" "}
                                    <Price
                                      price={data.price.value}
                                      currency={data.price.currency}
                                    />
                                  </div>
                                </div>
                                <CancellationPolicyDetails
                                  cxl_policies={data.cancellation_policies}
                                />
                              </div>
                            </div>
                            <div className="Card__actions"></div>
                          </div>
                        );
                      })
                      .filter((q) => q)}
                  </Form>
                </div>
                <div className="Modal__card__actions">
                  <button
                    className="Button"
                    data-ghost="true"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      onCancel();
                    }}>
                    Close
                  </button>
                  <button className="Button" type="submit" onClick={submitForm}>
                    Accept
                  </button>
                </div>
              </div>
            );
          }}
        </Formik>
      )}
    </div>
  );
};
PrebookFerriesModal.propTypes = { onCancel: PropTypes.func.isRequired };
