export const lang_mapping = {
  en: "en",
  zh: "cn",
};

export const lang_mapping_verbose = {
  en: "english",
  zh: "中文",
};

export const common_language_mapping = {
  en: "English",
  cn: "Chinese",
  jp: "Japanese",
  fr: "French",
  de: "Deutch",
  es: "Spanish",
  it: "Italian",
};
