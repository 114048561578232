export const initialMyLibraryImagesBankFilterForm = {
  title: {
    value: "",
    verbose: "Title",
  },
  tags: {
    value: [],
    verbose: "Tags",
  },
};
