import { commonList } from "@src/reducers/common";
import { B2B_ACTIVITIES_LIST_GENERIC_CHANGE } from "../../../../../actions/Operation/B2BReservations/SingleServices/Activities/types";

const B2BActivitiesListReducerInitial = {
  ...commonList,
  displayLength: 100,
  filters: {
    market_type: "B2B",
    name__icontains: "",
    destination_name__icontains: "",
    source_entity_autocomplete: "",
    source_entity: "",
    reference: "",
    external_reference: "",
    start_date__lte: "",
    start_date__gte: "",
    end_date__gte: "",
    end_date__lte: "",
    created__gte: "",
    created__lte: "",
    creator: "",
    creator_email: "",
    tags: "",
    status: "",
    page: 1,
    limit: 20,
    ordering: "",
  },
};
export const B2BActivitiesListReducer = (
  state = B2BActivitiesListReducerInitial,
  action
) => {
  switch (action.type) {
    case B2B_ACTIVITIES_LIST_GENERIC_CHANGE: {
      const { filters } = action;
      return { ...state, filters: { ...state.filters, ...filters } };
    }
    default:
      return state;
  }
};
