export const B2C_POLICY_FETCH_DATA = "B2C_POLICY_FETCH_DATA";
export const B2C_POLICY_BEGIN_FETCH_DATA = "B2C_POLICY_BEGIN_FETCH_DATA";
export const B2C_POLICY_END_FETCH_DATA = "B2C_POLICY_END_FETCH_DATA";
export const B2C_POLICY_INITIALIZE_LIST_FILTER_FORM =
  "B2C_POLICY_INITIALIZE_LIST_FILTER_FORM";
export const B2C_POLICY_UPDATE_LIST_FILTER_FORM =
  "B2C_POLICY_UPDATE_LIST_FILTER_FORM";
export const B2C_POLICY_APPLY_LIST_FILTER = "B2C_POLICY_APPLY_LIST_FILTER";
export const B2C_POLICY_UPDATE_LIST_COLUMNS_FORM =
  "B2C_POLICY_UPDATE_LIST_COLUMNS_FORM";
export const B2C_POLICY_APPLY_LIST_COLUMNS = "B2C_POLICY_APPLY_LIST_COLUMNS";
export const B2C_POLICY_INITIALIZE_LIST_COLUMNS_FORM =
  "B2C_POLICY_INITIALIZE_LIST_COLUMNS_FORM";
export const B2C_POLICY_FETCH_OPTIONS = "B2C_POLICY_FETCH_OPTIONS";
export const B2C_POLICY_EDIT_BASE_DATA = "B2C_POLICY_EDIT_BASE_DATA";
export const B2C_POLICY_VALIDATE_BASE_DATA_EDIT_FORM =
  "B2C_POLICY_VALIDATE_BASE_DATA_EDIT_FORM";
export const B2C_POLICY_RESET_LIST_FILTER_FORM =
  "B2C_POLICY_RESET_LIST_FILTER_FORM";
export const FORMAT_BRANCH_LIST_DATA = "FORMAT_BRANCH_LIST_DATA";
export const FORMAT_SUB_AGENT_LIST_DATA = "FORMAT_SUB_AGENT_LIST_DATA";
export const FORMAT_INDEPENDENT_AGENT_LIST_DATA =
  "FORMAT_INDEPENDENT_AGENT_LIST_DATA";
export const B2C_POLICY_FETCH_GRAPH_DATA = "B2C_POLICY_FETCH_GRAPH_DATA";
//======================================EDIT====================================
export const B2C_POLICY_FETCH_EDIT_DATA = "B2C_POLICY_FETCH_EDIT_DATA";
export const B2C_POLICY_INIT_BASE_DATA_EDIT_FORM =
  "B2C_POLICY_INIT_BASE_DATA_EDIT_FORM";
//=======================================MODAL==================================
export const B2C_POLICY_LOADING_EDIT_FORM = "B2C_POLICY_LOADING_EDIT_FORM";
export const B2C_POLICY_IDLE_EDIT_FORM = "B2C_POLICY_IDLE_EDIT_FORM";
export const B2C_POLICY_HIDE_FORM_MODAL = "B2C_POLICY_HIDE_FORM_MODAL";
export const B2C_POLICY_SHOW_FORM_MODAL = "B2C_POLICY_SHOW_FORM_MODAL";

export const B2C_POLICY_WEBSITE_DATA = "B2C_POLICY_WEBSITE_DATA";
