import {
  BIDREQUEST_INIT,
  // ========================== BID REQUEST ==========================
  BIDREQUEST_FETCHING_REQUEST,
  BIDREQUEST_FETCHED_REQUEST,
  BIDREQUEST_SET_REQUEST_DATA,
  // =========================== BID OFFER ===========================
  BIDREQUEST_SET_OFFER_DATA,
  // ========================== SRV PRICING ==========================
  BIDREQUEST_INIT_SRV_PRICING,
  BIDREQUEST_CHANGE_SRV_PRICING,
  BIDREQUEST_RESET_SRV_PRICING,
  BIDREQUEST_PRICING_TYPE,
  // ======================== RESPONSE MESSAGE =======================
  BIDREQUEST_SHOW_RESPONSE_MSG_MODAL,
  BIDREQUEST_CHANGE_OFFER_TERMS_MSG,
  // ========================== OFFER TERMS ==========================
  BIDREQUEST_CHANGE_OFFER_TERMS,
  // ========================= SERVICE DETAILS =========================
  BIDREQUEST_VIEW_FLIGHT_DETAILS,
  BIDREQUEST_HIDE_FLIGHT_DETAILS,
  BIDREQUEST_VIEW_ACC_DETAILS,
  BIDREQUEST_HIDE_ACC_DETAILS,
  BIDREQUEST_VIEW_TRF_DETAILS,
  BIDREQUEST_HIDE_TRF_DETAILS,
  // ======================== SERVICE COMMENTS =======================
  BIDREQUEST_ADD_SERVICE_COMMENT,
  BIDREQUEST_REMOVE_SERVICE_COMMENT,
  // ====================== SERVICE BOOKING TERMS ======================
  BIDREQUEST_CHANGE_SERVICE_BOOKING_TERMS,
  // =================== SERVICE CANCELLATION POLICY ===================
  BIDREQUEST_CHANGE_SRV_BOOKING_CXL_POLICY,
  // ======================== SERVICE FILTERS ========================
  BIDREQUEST_SRV_FILTERS_CHANGE,
  // ========================= SERVICE TYPE FILTERS =========================
  BIDREQUEST_SRV_RQ_TYPE_FILTERS_CHANGE,
  // =========================== SAVE ACTIONS ==========================
  BIDREQUEST_REQUEST_SAVE_OFFER,
  BIDREQUEST_CANCEL_SAVE_OFFER,
  BIDREQUEST_SAVING_OFFER,
  BIDREQUEST_SAVED_OFFER,
  BIDREQUEST_OFFER_DATA_UPDATE,
} from "./types";

import {
  showReplaceableAccModal,
  hideReplaceableAccModal,
  saveReplaceableAcc,
  revertReplaceableAcc,
} from "./replaceable_accs_actions";

import {
  showReplaceableTrpModal,
  hideReplaceableTrpModal,
  saveReplaceableTrp,
  revertReplaceableTrp,
} from "./replaceable_trp_actions";

import {
  showReplaceableTrfModal,
  hideReplaceableTrfModal,
  saveReplaceableTrf,
  revertReplaceableTrf,
} from "./replaceable_trf_actions";

import {
  sendOffer,
  requestSendOffer,
  cancelSendOffer,
} from "./send_offer_actions";

import { notifyCommonError } from "@src/components/common/notifications/CommonErrorNotification.js";
import { markUpper } from "@src/tools/pricing_tools";
import { getAccPrice } from "@src/selectors/Project/TripPlanner";

import _ from "lodash";

import {
  fetchBidRequest as fetchBidRequestAPI,
  createBiddingOffer as createBiddingOfferAPI,
  fetchBiddingOffer,
  updateBiddingOffer,
} from "@src/api/Project/Bidding";

import { Notification } from "rsuite";

export const initBidRequest = () => {
  return { type: BIDREQUEST_INIT };
};

export const createBiddingOffer = (id) => async (dispatch) => {
  try {
    const offer = await createBiddingOfferAPI({ bid_request: id });
    return offer;
  } catch (error) {
    console.log(error);
    notifyCommonError(
      "We are unable to process this bidding at this time. We are sorry for the inconvenience."
    );
    window.location.href = "/#/project/bid/archive/bidding-requests/";
    return null;
  }
};

export const fetchBidRequestData = (id) => async (dispatch, getState) => {
  const failMsg =
    "We are unable to process this bidding at this time. We are sorry for the inconvenience.";

  dispatch({ type: BIDREQUEST_FETCHING_REQUEST });

  try {
    const res = await fetchBidRequestAPI(id);
    await dispatch({ type: BIDREQUEST_SET_REQUEST_DATA, data: res });

    const state = getState();
    const companyProfile = state.companyProfile;
    const bidRequest = state.bidRequestRequestData;
    var bidOffer = bidRequest.bid_offers.find(
      (o) =>
        o.source_entity == `${companyProfile.id}___${companyProfile.klass_type}`
    );

    if (!bidOffer) {
      try {
        await createBiddingOfferAPI({ bid_request: id });
        await dispatch(fetchBidRequestData(id));
      } catch (error) {
        console.log(error);
        notifyCommonError(failMsg);
        window.location.href = "/#/project/bid/archive/bidding-requests/";
      }
    }
  } catch (error) {
    console.log(error);
    notifyCommonError(failMsg);
    window.location.href = "/#/project/bid/archive/bidding-requests/";
    return;
  }

  if (!bidOffer) {
    notifyCommonError(failMsg);
    return;
  }

  try {
    const offer = await fetchBiddingOffer(bidOffer.id);
    await dispatch({ type: BIDREQUEST_SET_OFFER_DATA, data: offer });
  } catch (error) {
    console.log(error);
    notifyCommonError(failMsg);
    return;
  }

  dispatch({ type: BIDREQUEST_FETCHED_REQUEST });
};

export const initializeSrvPricing = () => (dispatch, getState) => {
  const state = getState();
  const offer_pricing_data = _.get(
    state.bidRequestOfferData,
    "offer_pricing_data",
    {}
  );

  if (offer_pricing_data) {
    return;
  }

  const prc = { FL: {}, ACC: {}, TF: {} };

  _.get(state.bidRequestRequestData, "trip_plan.destinations", []).forEach(
    (d) => {
      prc.FL[d.order] = 0;
      prc.ACC[d.order] = 0;
      prc.TF[d.order] = 0;
    }
  );

  dispatch({ type: BIDREQUEST_INIT_SRV_PRICING, data: prc });
};

export const changeSrvPricing = (destOrder, serviceType, value) => {
  return { type: BIDREQUEST_CHANGE_SRV_PRICING, destOrder, serviceType, value };
};

export const changeGlobalSrvPricing = (perc_change) => (dispatch, getState) => {
  const state = getState();
  const trip = state.bidRequestRequestData.trip_plan;
  const pricing_data = state.bidRequestRequestData.pricing_data;
  const required_services = state.bidRequestRequestData.required_services;

  const extflightPrice = markUpper(
    _.get(
      _.get(trip.transportation_data.flights, "extremalLeg", []).find(
        (f) => f.selected
      ),
      "price.total_price",
      0
    ),
    _.get(pricing_data.FL, "extremal", 0)
  );

  const extSellingFlightPrice = markUpper(extflightPrice, perc_change);
  if (required_services.FL.includes("extremal")) {
    dispatch({
      type: BIDREQUEST_CHANGE_SRV_PRICING,
      destOrder: "extremal",
      serviceType: "FL",
      value: extSellingFlightPrice,
    });
  }

  const trfs = trip.transfer_data;
  trip.destinations.forEach((d) => {
    const acc = _.get(trip.accommodation_data, d.order, []).find(
      (a) => a.selected
    );
    var trfPrice = null;
    const trf = _.get(trfs, d.order, {});

    if (_.get(trf, "inbound_outbound", null)) {
      const t = _.get(trf, "inbound_outbound", []);
      trfPrice = _.get(
        t.find((t) => t.selected),
        "price.value",
        0
      );
    } else {
      let t = _.get(trf, "inbound", []);
      const inPrice = _.get(
        t.find((t) => t.selected),
        "price.value",
        0
      );

      t = _.get(trf, "outbound", []);
      const outPrice = _.get(
        t.find((t) => t.selected),
        "price.value",
        0
      );

      trfPrice = inPrice + outPrice;
    }

    const flightPrice = markUpper(
      _.get(
        _.get(trip.transportation_data.flights, d.order, []).find(
          (f) => f.selected
        ),
        "price.total_price",
        0
      ),
      _.get(pricing_data.FL, d.order, 0)
    );

    const sellingFlightPrice = markUpper(flightPrice, perc_change);
    const sellingAccPrice = markUpper(
      markUpper(getAccPrice(acc), _.get(pricing_data.ACC, d.order, 0)),
      perc_change
    );
    const sellingTrfPrice = markUpper(
      markUpper(trfPrice, _.get(pricing_data.TF, d.order, 0)),
      perc_change
    );

    const prices = [
      ["FL", sellingFlightPrice],
      ["ACC", sellingAccPrice],
      ["TF", sellingTrfPrice],
    ];
    prices
      .filter((p) => required_services[p[0]].includes(d.order))
      .forEach((p) =>
        dispatch({
          type: BIDREQUEST_CHANGE_SRV_PRICING,
          destOrder: d.order,
          serviceType: p[0],
          value: p[1],
        })
      );
  });
};

export const changePricingType = (pricingType) => {
  return { type: BIDREQUEST_PRICING_TYPE, pricingType };
};

export const showResponseMessageModal = () => {
  return { type: BIDREQUEST_SHOW_RESPONSE_MSG_MODAL };
};

export const hideResponseMessageModal = () => {
  return { type: BIDREQUEST_SHOW_RESPONSE_MSG_MODAL };
};

export const changeResponseMessage = (msg) => {
  return { type: BIDREQUEST_CHANGE_OFFER_TERMS_MSG, msg };
};

export const changeOfferTerms = (name, value) => {
  return { type: BIDREQUEST_CHANGE_OFFER_TERMS, name, value };
};

// ========================= SERVICE DETAILS =========================
export const viewDetails = (
  destOrder,
  serviceType,
  transfer_type = "inbound_outbound",
  isReplaceable = false
) => {
  switch (serviceType) {
    case "FL":
      return {
        type: BIDREQUEST_VIEW_FLIGHT_DETAILS,
        flOrder: destOrder,
        isReplaceable,
      };
    case "ACC":
      return { type: BIDREQUEST_VIEW_ACC_DETAILS, destOrder, isReplaceable };
    case "TF":
      return {
        type: BIDREQUEST_VIEW_TRF_DETAILS,
        destOrder,
        transfer_type,
        isReplaceable,
      };
    default:
      return null;
  }
};

export const hideDetails = (serviceType) => {
  switch (serviceType) {
    case "FL":
      return { type: BIDREQUEST_HIDE_FLIGHT_DETAILS };
    case "ACC":
      return { type: BIDREQUEST_HIDE_ACC_DETAILS };
    case "TF":
      return { type: BIDREQUEST_HIDE_TRF_DETAILS };
    default:
      return null;
  }
};

// ========================= SERVICE COMMENTS ========================
export const addServiceComment = (destOrder, serviceType, comment) => {
  return {
    type: BIDREQUEST_ADD_SERVICE_COMMENT,
    destOrder,
    serviceType,
    comment,
  };
};

export const removeServiceComment = (destOrder, serviceType, uuid) => {
  return {
    type: BIDREQUEST_REMOVE_SERVICE_COMMENT,
    destOrder,
    serviceType,
    uuid,
  };
};

// ====================== SERVICE BOOKING TERMS ======================
export const changeServiceTerms = (destOrder, serviceType, terms_text) => {
  return {
    type: BIDREQUEST_CHANGE_SERVICE_BOOKING_TERMS,
    destOrder,
    serviceType,
    terms_text,
  };
};

// =========================== CXL POLICIES ==========================
export const changeCxlPolicy = (destOrder, serviceType, rules) => {
  return {
    type: BIDREQUEST_CHANGE_SRV_BOOKING_CXL_POLICY,
    destOrder,
    serviceType,
    rules,
  };
};

export const serviceFiltersChange = (serviceType, checked) => (
  dispatch,
  getState
) => {
  const trip = getState().bidRequestRequestData.trip_plan;

  if (serviceType == "FL") {
    dispatch({
      type: BIDREQUEST_SRV_FILTERS_CHANGE,
      destOrder: "extremal",
      serviceType,
      actionType: checked ? "add" : "remove",
    });
  }

  trip.destinations.forEach((d) => {
    dispatch({
      type: BIDREQUEST_SRV_FILTERS_CHANGE,
      destOrder: d.order,
      serviceType,
      actionType: checked ? "add" : "remove",
    });
  });
};

export const serviceRqTypeFiltersChange = (serviceRqType, checked) => (
  dispatch
) => {
  ["FL", "ACC", "TF"].forEach((s) =>
    dispatch({
      type: BIDREQUEST_SRV_RQ_TYPE_FILTERS_CHANGE,
      serviceRqType,
      serviceType: s,
      actionType: checked ? "add" : "remove",
    })
  );
};

// =========================== SAVE ACTIONS ==========================

export const requestOfferSave = () => {
  return { type: BIDREQUEST_REQUEST_SAVE_OFFER };
};

export const cancelOfferSave = () => {
  return { type: BIDREQUEST_CANCEL_SAVE_OFFER };
};

export const offerSave = () => async (dispatch, getState) => {
  const offerData = getState().bidRequestOfferData;

  dispatch({ type: BIDREQUEST_SAVING_OFFER });
  const payload = _.cloneDeep(offerData);

  try {
    const data = await updateBiddingOffer(payload.id, payload);
    dispatch({ type: BIDREQUEST_OFFER_DATA_UPDATE, data });

    Notification.success({
      title: "Offer Save Success",
      description: "We successfully saved your current offer.",
    });
  } catch (error) {
    error.ovrdErrHandle && error.ovrdErrHandle();
    Notification.error(
      {
        title: "Offer Save Failure",
        description:
          "We failed to save your current offer. We are sorry for the inconvenience.",
      },
      6000
    );
  }

  dispatch({ type: BIDREQUEST_SAVED_OFFER });
};

export {
  // =================== REPLACEABLE ACCOMMODATION ==================
  showReplaceableAccModal,
  hideReplaceableAccModal,
  saveReplaceableAcc,
  revertReplaceableAcc,
  // =================== REPLACEABLE TRANSPORTATION ==================
  showReplaceableTrpModal,
  hideReplaceableTrpModal,
  saveReplaceableTrp,
  revertReplaceableTrp,
  // ===================== REPLACEABLE TRANSFERS =====================
  showReplaceableTrfModal,
  hideReplaceableTrfModal,
  saveReplaceableTrf,
  revertReplaceableTrf,
  // ========================== SEND ACTIONS =========================
  sendOffer,
  requestSendOffer,
  cancelSendOffer,
};
