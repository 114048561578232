import _ from "lodash";
import { SISI_API_URL } from "@src/api";
import { getAuthHeaders } from "@src/api/request";
import axios from "axios";

export async function deleteUnsentTripOffer({ id }) {
  const auth_headers = getAuthHeaders();
  try {
    await axios.delete(`${SISI_API_URL}/crs/trip-offer/unsent/${id}`, {
      headers: { ...auth_headers },
    });
    return true;
  } catch (error) {
    return false;
  }
}

export async function createTripOffer({ payload }) {
  const auth_headers = getAuthHeaders();
  return await axios.post("/bid/trip-offer/", payload, {
    headers: { ...auth_headers },
  });
}
