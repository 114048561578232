export const FETCH_TOURS_DATA = "FETCH_TOURS_DATA";
export const BEGIN_FETCH_TOURS_DATA = "BEGIN_FETCH_TOURS_DATA";
export const END_FETCH_TOURS_DATA = "END_FETCH_TOURS_DATA";
export const INITIALIZE_TOURS_LIST_FILTER_FORM =
  "INITIALIZE_TOURS_LIST_FILTER_FORM";
export const UPDATE_TOURS_LIST_FILTER_FORM = "UPDATE_TOURS_LIST_FILTER_FORM";
export const APPLY_TOURS_LIST_FILTER = "APPLY_TOURS_LIST_FILTER";
export const UPDATE_TOURS_LIST_COLUMNS_FORM = "UPDATE_TOURS_LIST_COLUMNS_FORM";
export const APPLY_TOURS_LIST_COLUMNS = "APPLY_TOURS_LIST_COLUMNS";
export const INITIALIZE_TOURS_LIST_COLUMNS_FORM =
  "INITIALIZE_TOURS_LIST_COLUMNS_FORM";
export const UPDATE_TOURS_LIST_REPORT_FORM = "UPDATE_TOURS_LIST_REPORT_FORM";
export const FETCH_TOURS_OPTIONS = "FETCH_TOURS_OPTIONS";
