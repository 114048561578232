import React from "react";
import TransTxt from "@src/components/common/SxFormatMessage";
import { initialBasicFilterForm } from "./../index";
import _ from "lodash";
import { Tag } from "rsuite";

export const initialOnRequestColumnsForm = {
  basic: {
    value: ["title", "request_by", "pax"],
    choices: [
      {
        verbose: <TransTxt id="tripplanlist_trip_title" startCase />,
        value: "title",
        key: "title",
        fixed: "left",
        disabled: true,
        path: "itinerary_setup.title",
      },
      {
        verbose: <TransTxt id="tripplanlist_request_by" startCase />,
        value: "request_by",
        key: "request_by",
        fixed: "left",
      },
      {
        verbose: <TransTxt id="tripplanlist_pax" startCase />,
        value: "pax",
        key: "pax",
        fixed: "left",
        valuePath: ["itinerary_setup.adults", "itinerary_setup.children"],
        mergeField: true,
      },
    ],
  },
  itinerary: {
    value: [
      "time_period",
      "tripplanlist_origin",
      "destinations",
      "tripplanlist_return",
    ],
    choices: [
      {
        verbose: <TransTxt id="tripplanlist_time_period" startCase />,
        value: "time_period",
        key: "time_period",
        valuePath: ["return_data.date", "origin_data.date"],
        formatDate: true,
        mergeField: true,
      },
      {
        verbose: <TransTxt id="tripplanlist_origin" startCase />,
        value: "tripplanlist_origin",
        key: "tripplanlist_origin",
        path: "origin_data.destination",
        changeLang: true,
      },
      {
        verbose: <TransTxt id="tripplanlist_destinations" startCase />,
        value: "destinations",
        key: "destinations",
        getLength: true,
      },
      {
        verbose: <TransTxt id="tripplanlist_return" startCase />,
        value: "tripplanlist_return",
        key: "tripplanlist_return",
        path: "return_data.destination",
        changeLang: true,
      },
    ],
  },
  services: {
    value: ["service_types"],
    choices: [
      {
        verbose: <TransTxt id="tripplanlist_services_category" startCase />,
        value: "service_types",
        key: "service_types",
        service_types: [
          { key: "Transportation", iata: "FL" },
          { key: "Transfer", iata: "TF" },
          { key: "Accommodation", iata: "ACC" },
        ],
      },
      {
        verbose: <TransTxt id="tripplanlist_insurance" startCase />,
        value: "insurance",
        key: "insurance",
      },
      {
        verbose: <TransTxt id="tripplanlist_travel_visa" startCase />,
        value: "travel_visa",
        key: "travel_visa",
      },
    ],
  },
  pricing: {
    value: ["total", "per_person"],
    choices: [
      {
        verbose: <TransTxt id="tripplanlist_total_price" startCase />,
        value: "total",
        key: "total",
        changPrice: true,
      },
      {
        verbose: <TransTxt id="tripplanlist_per_person_price" startCase />,
        value: "per_person",
        key: "per_person",
        path: "per_person_",
        changPrice: true,
      },
    ],
  },
  stage: {
    value: ["status"],
    choices: [
      {
        verbose: <TransTxt id="status" startCase />,
        value: "status",
        key: "status",
        fixed: "right",
        disabled: true,
      },
      {
        verbose: <TransTxt id="created" startCase />,
        value: "created",
        key: "created",
        formatDate: true,
        path: "created",
      },
      {
        verbose: <TransTxt id="creator" startCase />,
        value: "creator",
        key: "creator",
        path: "creator",
      },
      {
        verbose: <TransTxt id="last_editor" startCase />,
        value: "last_editor",
        key: "last_editor",
        path: "last_editor",
      },
    ],
  },
};

export const initialOnRequestFilterForm = _.merge(initialBasicFilterForm, {
  status: {
    value: [],
    verbose: <TransTxt id="status" startCase />,
    data: [
      {
        value: "IP",
        label: (
          <Tag color="orange" style={{ width: "100px" }}>
            <strong>
              <TransTxt id="in_progress" startCase />
            </strong>
          </Tag>
        ),
      },
      {
        value: "OF",
        label: (
          <Tag color="blue" style={{ width: "100px", textAlign: "center" }}>
            <strong>
              <TransTxt id="on_offer" startCase />
            </strong>
          </Tag>
        ),
      },
      {
        value: "BO",
        label: (
          <Tag color="green" style={{ width: "100px", textAlign: "center" }}>
            <strong>
              <TransTxt id="booked" startCase />
            </strong>
          </Tag>
        ),
      },
      {
        value: "FA",
        label: (
          <Tag color="red" style={{ width: "100px", textAlign: "center" }}>
            <strong>
              <TransTxt id="failed" startCase />
            </strong>
          </Tag>
        ),
      },
    ],
  },
  pax: {
    value: [1, 10],
    verbose: <TransTxt id="tripplanlist_pax" startCase />,
    hasContainerBorder: true,
  },
  recently: {
    value: "0",
    verbose: <TransTxt id="sort_by" startCase />,
    data: [
      {
        value: "0",
        label: (
          <Tag color="green">
            <strong>
              <TransTxt id="date_add(newest)" />
            </strong>
          </Tag>
        ),
      },
      {
        value: "1",
        label: (
          <Tag color="orange">
            <strong>
              <TransTxt id="date_add(oldest)" />
            </strong>
          </Tag>
        ),
      },
    ],
  },
  origin: {
    value: "",
    verbose: <TransTxt id="tripplanlist_origin" startCase />,
  },
  return: {
    value: "",
    verbose: <TransTxt id="tripplanlist_return" startCase />,
  },
  travel_services: {
    value: "",
    verbose: "Travel Services",
  },
  total: {
    value: "",
    verbose: <TransTxt id="tripplanlist_total" startCase />,
  },
  total_buying_price: {
    value: "",
    verbose: <TransTxt id="tripplanlist_total_buying_price" startCase />,
  },
  total_selling_price: {
    value: "",
    verbose: <TransTxt id="tripplanlist_total_selling_price" startCase />,
  },
  per_person: {
    value: "",
    verbose: <TransTxt id="tripplanlist_per_person" startCase />,
  },
  created_by: {
    value: [],
    verbose: <TransTxt id="created_by" startCase />,
  },
  per_person_buying_price: {
    value: [1, 10],
    verbose: <TransTxt id="tripplanlist_per_person_buying_price" startCase />,
  },
  per_person_selling_price: {
    value: [1, 10],
    verbose: <TransTxt id="tripplanlist_per_person_selling_price" startCase />,
  },
  last_editor: {
    value: "",
    verbose: <TransTxt id="last_edited_date" startCase />,
  },
  created: {
    value: [],
    placement: "auto",
    verbose: <TransTxt id="created_date" startCase />,
  },
});

export * from "./columns";
