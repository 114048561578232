import _ from "lodash";
import { Field, useField } from "formik";
import PropTypes from "prop-types";
import React from "react";
import { createUseStyles } from "react-jss";
import { inputGroupStyles, labelStyles } from "./styles";
import { variables, inputStyles } from "@src/jsssetup";

const normalCheckboxFieldStyles = createUseStyles({
  NormalCheckboxField: {},
  inputGroup: (props) => ({
    ...inputGroupStyles(props),
    gridTemplateColumns: "repeat(2, max-content)",
    gridGap: variables.half_gap,
  }),
  label: (props) => labelStyles(props),
  input: (props) => ({
    ...inputStyles,
    borderColor: props.theme === "normal" ? variables.colors.deepgrey : "white",
    resize: props.resize ? "auto" : "none",
    ..._.get(props, "customStyles.input", {}),
  }),
  error: { color: (props) => (props.highlightErrors ? "red" : "black") },
});
export const NormalCheckboxField = ({
  label,
  name,
  theme = "normal",
  extraInputProps = {},
  highlightErrors = false,
  withError = true,
  customStyles = {},
}) => {
  const classes = normalCheckboxFieldStyles({
    theme,
    highlightErrors,
    customStyles,
  });

  const [__, meta] = useField({ name });

  return (
    <div className={classes.inputGroup}>
      <span className={classes.label}>
        <strong>{label}</strong>
      </span>
      <Field
        type="checkbox"
        name={name}
        className={classes.input}
        {...extraInputProps}
      />
      {withError && !!meta.error && (
        <div className={classes.error}>{meta.error}</div>
      )}
    </div>
  );
};
NormalCheckboxField.defaultProps = {
  theme: "normal",
  extraInputProps: {},
  highlightErrors: false,
  withError: true,
  customStyles: {},
};
NormalCheckboxField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  theme: PropTypes.oneOf(["normal"]),
  highlightErrors: PropTypes.bool,
  extraInputProps: PropTypes.object,
  withError: PropTypes.bool,
  customStyles: PropTypes.object,
};
