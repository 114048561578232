import _ from "lodash";
import { trainPrebook } from "@src/api/Project/trains";
import { useQueries } from "@tanstack/react-query";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { DateTime } from "luxon";
import { PriceExcHoc } from "../../../PriceDetails";
import { Loader } from "rsuite";

export const TrainDetails = ({ idx, segment, segmentsLength }) => {
  return (
    <div className="TrainDetails">
      <div className="TrainDetails__header">
        {segment.vehicle.type} #{segment.vehicle.reference} -{" "}
        {segment.product.comfort_category.label} ,{" "}
        {segment.product.flexibility.label}
        {/* <CarrierIcon carrier={segment.marketingCarrier} /> */}
      </div>
      <div className="TrainDetails__body">
        <div className="TrainDetails__stationInfo">
          {DateTime.fromISO(segment.departure).toLocal().toFormat("hh:mm")}{" "}
          {segment.origin.label}
          <div>
            <div className="TrainDetails__stationInfoDate">
              {DateTime.fromISO(segment.departure).toLocaleString(
                DateTime.DATE_MED_WITH_WEEKDAY
              )}
            </div>
            <div className="TrainDetails__services">
              {segment.services
                ? segment.services.map((service) => service.label).join(", ")
                : "No Services"}
            </div>
          </div>
        </div>
        <div className="TrainDetails__stationInfo">
          {DateTime.fromISO(segment.arrival).toLocal().toFormat("hh:mm")}{" "}
          {segment.destination.label}
        </div>
      </div>
      {idx + 1 === segmentsLength && (
        <div className="TrainDetails__conditionsContainer">
          <div className="TrainDetails__conditionsTitle">Fare Conditions</div>
          <div />
          <div
            className="TrainDetails__conditions"
            dangerouslySetInnerHTML={{
              __html: segment.product.conditions,
            }}
          />
        </div>
      )}
      {idx + 1 !== segmentsLength && <div className="TrainDetails__divider" />}
    </div>
  );
};
TrainDetails.propTypes = {
  idx: PropTypes.number.isRequired,
  segment: PropTypes.object.isRequired,
  segmentsLength: PropTypes.number.isRequired,
};

const Price = PriceExcHoc(({ price, currency }) => {
  return (
    <span>
      {price.toLocaleString("en-US", { style: "currency", currency })}
    </span>
  );
});

const TrainPrebookInfo = ({ idx, data = {} }) => {
  console.log("data", data);
  const goRoute = data?.booking_items?.[0]?.legs?.[0] ?? [];
  const origin = goRoute?.origin?.label ?? "";
  const destination = goRoute?.destination?.label ?? "";
  const returnRoute = data?.booking_items?.[0]?.legs?.[1] ?? [];
  const goRouteSegments = goRoute?.segments ?? [];
  const returnRouteSegments = returnRoute?.segments ?? [];
  const netPrice = data?.prices?.value_net ?? 0;
  const totalPrice = data?.prices?.value_gross ?? 0;
  const priceCurrency = data?.prices?.currency ?? "";
  const feeLabel = data?.fees?.label ?? "";
  const feeCurrency = data?.fees?.amount.currency ?? "";
  const fee = data?.fees?.amount?.value ?? 0;

  const warnings = data?.booking_items?.[0]?.warnings ?? [];
  const additionalCosts = warnings.filter((warning) =>
    ["era-bookings-price-discrepancy-seat-reservation"].includes(warning.code)
  );

  return (
    <div>
      <div className="TrainPrebookInfo Card">
        <div className="Card__header">
          <h5>
            <span className="TrainPrebookInfo__headerTitle">
              {idx}. {origin} to {destination}
            </span>
          </h5>
        </div>
        <div className="Card__body TrainPrebookInfo__content">
          {!!goRouteSegments &&
            goRouteSegments.map((segment, idx) => (
              <TrainDetails
                key={idx}
                idx={idx}
                segment={segment}
                segmentsLength={goRouteSegments.length}
              />
            ))}
          {returnRouteSegments.length > 0 && (
            <React.Fragment>
              <div className="TrainPrebookInfo__divider" />
              {returnRouteSegments.map((segment, idx) => (
                <TrainDetails
                  segment={segment}
                  key={idx}
                  idx={idx}
                  segmentsLength={returnRouteSegments.length}
                />
              ))}
            </React.Fragment>
          )}
          <div className="TrainPrebookInfo__feeContainer">
            <div className="TrainPrebookInfo__fee">
              <span className="TrainPrebookInfo__feeLabel"> {feeLabel}</span>{" "}
              <span className="TrainPrebookInfo__feePrice">
                <Price price={fee} currency={feeCurrency} />
              </span>
            </div>
            <div className="TrainPrebookInfo__fee">
              <span className="TrainPrebookInfo__feeLabel"> Net Price</span>{" "}
              <span className="TrainPrebookInfo__feePrice">
                <Price price={netPrice} currency={priceCurrency} />
              </span>
            </div>
            {additionalCosts.map((cost, idx) => (
              <div className="TrainPrebookInfo__fee" key={idx}>
                <span className="TrainPrebookInfo__feeLabel">
                  {" "}
                  {cost.label}
                </span>
                <span className="TrainPrebookInfo__feePrice">
                  <Price
                    price={cost?.details?.[0] ?? 0}
                    currency={cost?.details?.[1]}
                  />
                </span>
              </div>
            ))}
          </div>
        </div>
        <div className="Card__actions TrainPrebookInfo__totalPriceContainer">
          <span className="TrainPrebookInfo__total">Total</span>
          <span className="TrainPrebookInfo__price">
            <Price price={totalPrice} currency={priceCurrency} />
          </span>
          <button className="Button">Accept</button>
        </div>
      </div>
    </div>
  );
};
TrainPrebookInfo.propTypes = {
  idx: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
};

export const PrebookTrainsModal = ({ onCancel }) => {
  const { trains } = useSelector((state) => {
    const trains = state.tripPlannerTrainsReducer?.services ?? [];
    return { trains };
  });

  const queries = useQueries({
    queries: trains
      .map((t) => ({
        queryKey: ["trainprebok", t.id],
        queryFn: () => {
          const selectedOffer = (t?.offers ?? []).find((o) => o.selected);
          if (!selectedOffer) return null;
          // This will have length 2 when we implement the roundtrip logic.
          const prebook_items = [selectedOffer?.url ?? ""];

          var payload = {
            supplier: "raileurope",
            session_id: t.session_id,
            api_uid: selectedOffer.api_uid,
            market_type: "B2B",
            prebook_items,
          };

          return trainPrebook({ payload });
        },
        refetchOnWindowFocus: false,
      }))
      .filter((q) => q),
  });

  const loading = queries.every((q) => q.isLoading || q.isFetching);

  if (queries.every((q) => q.isError)) {
    toast.error("Failed to prebook trains", { autoClose: 5000 });
    onCancel();
  }

  return (
    <div className="Modal">
      <div className="Modal__card">
        <div className="Modal__card__header">
          <h5>Prebook Trains</h5>
        </div>
        <div className="Modal__card__body">
          {loading ? (
            <Loader center size="lg" />
          ) : (
            queries.map((q, idx) => (
              <TrainPrebookInfo key={idx} idx={idx + 1} data={q?.data?.data} />
            ))
          )}
        </div>
        <div className="Modal__card__actions">
          <button className="Button" onClick={onCancel}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};
PrebookTrainsModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
};
