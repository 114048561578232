import _ from "lodash";
import { createSelector } from "reselect";
import { getAllSelectedAccSelector } from "./accommodation_selectors";
import { getAllSelectedTrfSelector } from "./transfer_selectors";
import { getSelectedAddonData } from "./addon_selectors";
import {
  getTripDestinations,
  getTripOriginData,
  getTripReturnData,
} from "./trip_itinerary_selectors";
import { getSetupFormDataSelector } from "./trip_setup_selectors";
import { getFlightServices } from "./day_by_day_selectors";
import {
  getFlightSessions,
  getSecondaryLegFlight,
  getTripLegs,
} from "./transportation_selectors";

export const getTripDataSelector = createSelector(
  [
    getAllSelectedAccSelector,
    getAllSelectedTrfSelector,
    getFlightServices,
    getFlightSessions,
    getSelectedAddonData,
    getTripDestinations,
    getTripOriginData,
    getTripReturnData,
    getSetupFormDataSelector,
    getTripLegs,
  ],
  (
    accommodations,
    transfers,
    flights,
    flightSessions,
    addons,
    destinations,
    originData,
    returnData,
    tripSetUp,
    tripLegs
  ) => {
    var originLegUid = tripLegs.find((l) => l.origin_order === 0);
    const dests = {};
    destinations.forEach((dest) => {
      var flight = null;
      if (dest.order == 1) {
        const leg = tripLegs.find((l) => l.destination_order === 1);
        flight = _.cloneDeep(flights.find((f) => f.legUid === leg.uid) ?? {});
      } else {
        const leg = tripLegs.find((l) => l.origin_order === dest.order);
        flight = _.cloneDeep(flights.find((f) => f.legUid === leg.uid) ?? {});
      }
      flight.serviceType = "FL";

      var returnFlight = null;
      if (dest.order == _.last(destinations).order) {
        if (
          `${dest.type}___${dest.id}` ==
          `${destinations[0].type}___${destinations[0].id}`
        ) {
          const leg = tripLegs.find(
            (l) => l.destination_order === dest.order + 1
          );
          if (leg) {
            const { flight: fl } = getSecondaryLegFlight(
              flights,
              tripLegs,
              flightSessions,
              leg.uid
            );
            if (fl) {
              returnFlight = _.cloneDeep(fl);
              returnFlight.serviceType = "FL";
            }
          }
        } else {
          returnFlight = flight;
        }

        if (returnFlight) {
          if (_.get(returnFlight, "inbound_options", []).length) {
            returnFlight["operating_airline"] =
              returnFlight["inbound_operating_airline"];
            returnFlight["options"] = returnFlight["inbound_options"];
          } else {
            returnFlight["operating_airline"] =
              returnFlight["outbound_operating_airline"];
            returnFlight["options"] = returnFlight["outbound_options"];
          }

          returnFlight = _.omit(returnFlight, [
            "inbound_options",
            "inbound_operating_airline",
            "outbound_options",
            "outbound_operating_airline",
          ]);
        }
      }

      var inbTrf = _.get(transfers, `${dest.order}.inbound`, null);
      if (inbTrf) {
        inbTrf["serviceType"] = "TRF";
      }

      var outTrf = _.get(transfers, `${dest.order}.outbound`, null);
      if (outTrf) {
        outTrf["serviceType"] = "TRF";
      }

      var acc = _.get(accommodations, dest.order.toString(), null);
      if (acc) {
        acc["serviceType"] = "ACC";
      }

      var addon = {
        service: _.flatten(Object.values(addons)).filter(
          (e) => e.destOrder == dest.order
        ),
      };
      if (addon) {
        addon["serviceType"] = "MI";
      }

      dests[dest.order.toString()] = {
        ...dest,
        images: [dest.images[0]],
        services: [flight, inbTrf, acc, outTrf, returnFlight, addon].filter(
          (srv) => srv
        ),
      };
    });

    const { adults, children } = tripSetUp;

    return {
      destinations: dests,
      originData,
      returnData,
      pax: { adults, children },
    };
  }
);
