import {
  //=========================FILTER==========================
  PACKAGES_WHOLESALES_INITIALIZE_BASIC_FILTER_DATA,
  PACKAGES_WHOLESALES_EDIT_BASIC_FILTER_DATA_FORM,
  PACKAGES_WHOLESALES_UPDATE_FILTER_FORM,
  PACKAGES_WHOLESALES_APPLY_LIST_FILTER,
  PACKAGES_WHOLESALES_INITIALIZE_LIST_FILTER_FORM,
  PACKAGES_WHOLESALES_RESET_LIST_FILTER_FORM,
  PACKAGES_WHOLESALES_CHANGE_PRICE_STATUS,
  //=======================LIST=============================
  PACKAGES_WHOLESALES_FETCH_DATA,
  PACKAGES_WHOLESALES_LIST_LOADING,
  PACKAGES_WHOLESALES_LIST_IDLE,
  PACKAGES_WHOLESALES_FETCH_LIST,
  PACKAGES_WHOLESALES_LIST_GENERIC_CHANGE,
} from "./types";
import update from "immutability-helper";

import { getArchiveList } from "@src/api";
import { filterProcessor } from "./filterProcessor";

import { getPagination } from "@src/tools/form_tools";
import { initialOnRequestFilterForm } from "@src/forms/Project/Archive/OnRequest";

import { notifyFetchingArchiveResultsError } from "@src/components/common/notifications/ArchiveNotifications";

export const fetchWholesalesData = (data) => {
  return {
    type: PACKAGES_WHOLESALES_FETCH_DATA,
    data: data,
  };
};

export const loadingWholesalesList = () => {
  return { type: PACKAGES_WHOLESALES_LIST_LOADING };
};

export const idleWholesalesList = () => {
  return { type: PACKAGES_WHOLESALES_LIST_IDLE };
};

export const fetchWholesalesList = () => async (dispatch, getState) => {
  const filter = filterProcessor(getState().wholesalesListFilters);
  const pagination = getPagination(getState().wholesalesList);

  dispatch(loadingWholesalesList());
  try {
    const data = await getArchiveList({
      ...filter,
      ...pagination,
      trip_type: "MKT",
    });
    dispatch(
      fetchWholesalesData({
        page: pagination.page,
        limit: pagination.limit,
        ...data,
      })
    );
  } catch (error) {
    console.log(error);
    notifyFetchingArchiveResultsError("My Wholesaless/Templates");
  } finally {
    dispatch(idleWholesalesList());
  }
};

export const modifyWholesalesPage = (page) => (dispatch, getState) => {
  let wholesalesList = getState().wholesalesList;
  wholesalesList = update(wholesalesList, { page: { $set: page } });
  dispatch(fetchWholesalesData({ ...wholesalesList }));
  dispatch(fetchWholesalesList());
};

export const modifyWholesalesLimit = (limit) => (dispatch, getState) => {
  let wholesalesList = getState().wholesalesList;
  wholesalesList = update(wholesalesList, {
    limit: { $set: limit },
    page: { $set: 1 },
  });

  dispatch(fetchWholesalesData({ ...wholesalesList }));
  dispatch(fetchWholesalesList());
};

export const initializeBasicFilterForm = (data) => {
  return { type: PACKAGES_WHOLESALES_INITIALIZE_BASIC_FILTER_DATA, data: data };
};

export const editWholesalesBasicFilterDataForm = (data) => {
  return { type: PACKAGES_WHOLESALES_EDIT_BASIC_FILTER_DATA_FORM, data: data };
};

// filter relation
export const updateWholesalesListFilterForm = (payload) => {
  return { type: PACKAGES_WHOLESALES_UPDATE_FILTER_FORM, data: payload };
};

export const applyWholesalesListFilter = () => (dispatch, getState) => {
  const state = getState().wholesalesListFilters || {};
  dispatch(applyWholesalesListFilterForm(state));
  dispatch(fetchWholesalesList(1));
};

export const applyWholesalesListFilterForm = (data) => {
  return {
    type: PACKAGES_WHOLESALES_APPLY_LIST_FILTER,
    data,
  };
};

export const initializeWholesalesListFilterForm = () => {
  return {
    type: PACKAGES_WHOLESALES_INITIALIZE_LIST_FILTER_FORM,
  };
};

export const changeWholesalesPriceStatus = (data) => {
  return { type: PACKAGES_WHOLESALES_CHANGE_PRICE_STATUS, data: data };
};

export * from "./preview_package_actions";

//============================NEW=======================
export const saveWholesalesResults = (data) => {
  return { type: PACKAGES_WHOLESALES_FETCH_LIST, data };
};

export const getWholesalesData = () => async (dispatch, getState) => {
  const state = getState();

  const { page, limit, filters } = state.newWholesalesListReducer;

  const vFilters = {};
  Object.entries(filters)
    .filter((entry) => entry[1])
    .forEach((entry) => (vFilters[entry[0]] = entry[1]));

  try {
    const data = await getArchiveList({
      ...vFilters,
      page,
      limit,
      trip_type: "MKT",
    });
    dispatch(
      saveWholesalesResults({
        page,
        limit,
        ...data,
      })
    );
  } catch (error) {
    console.log(error);
    notifyFetchingArchiveResultsError("My Wholesaless/Templates");
  }
};
export const wholesalesGenericChange = (key, value) => {
  return { type: PACKAGES_WHOLESALES_LIST_GENERIC_CHANGE, key, value };
};
