import { InputGroup, AutoComplete, Icon, Tag } from "rsuite";

import Fuse from "fuse.js";
import _ from "lodash";
import update from "immutability-helper";
import PropTypes from "prop-types";
import React from "react";
import {
  poiAutoComplete,
  poiDetails,
  poiGeodata,
} from "@src/api/Project/TripPlanner";

export default class PoiAutoComplete extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      autocompleteData: [],
      searching: false,
      disabled: false,
      value: "",
    };

    this.fuseCfg = {
      shouldSort: false,
      threshold: 0.5,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ["label"],
    };

    this.handleChange = this.handleChange.bind(this);
    this.autocomplete = _.debounce(this.autocomplete.bind(this), 200);
    this.handleSelect = _.debounce(this.handleSelect.bind(this), 500, {
      leading: true,
    });
  }
  componentDidMount() {
    const { value } = this.props;

    if (value) {
      this.setState((p) => update(p, { value: { $set: value } }));
    }
  }
  componentDidUpdate(prevProps) {
    const { value, uid } = this.props;
    if (prevProps.uid !== uid) {
      this.setState((p) => update(p, { value: { $set: value || "" } }));
    }
  }
  lengthCheck(query) {
    const { lang } = this.props;

    if (lang == "en" && query.length < 3) {
      return false;
    } else if (lang == "cn" && query.length < 1) {
      return false;
    }
    return true;
  }
  async autocomplete(query) {
    if (!this.lengthCheck(query)) {
      return;
    }

    await this.setState((p) => update(p, { searching: { $set: true } }));

    try {
      const result = await poiAutoComplete({ query });

      const newData = [
        ...result.pois,
        ...result.enhanced_pois
          .filter((p) => p.uid)
          .map((p) => ({ ...p, enhanced: true })),
      ]
        .map((p) => {
          const value = [p.name, ...p.location].join(" - ");
          return { ...p, ...{ value, label: value } };
        })
        .sort((a, b) => (a.enhanced ? -1 : 1));
      this.setState(
        (p) => update(p, { data: { $set: newData } }),
        () => {
          this.fuseData = new Fuse(this.state.data, this.fuseCfg);
          const fuzzyMatchings = this.fuseData.search(this.state.value);
          this.setState((p) =>
            update(p, { autocompleteData: { $set: fuzzyMatchings } })
          );
        }
      );
    } catch (error) {
      console.log("error");
    } finally {
      this.setState((p) =>
        update(p, { searching: { $set: false }, disabled: { $set: false } })
      );
    }
  }
  async handleSelect(item) {
    const { geodataDetailsOnly, onSelect } = this.props;

    const { label, id, uid, enhanced, geodata } = item;

    if (enhanced && geodataDetailsOnly) {
      onSelect({ query_value: label, geodata, id });
      await this.setState((p) => update(p, { value: { $set: label } }));
      return;
    }

    await this.setState((p) =>
      update(p, { disabled: { $set: true }, searching: { $set: true } })
    );

    try {
      var result = null;
      if (geodataDetailsOnly) {
        result = await poiGeodata({ uid });
      } else {
        result = await poiDetails(enhanced ? { id } : { uid });
      }

      result.query_value = label;
      result.id = id;
      onSelect(result);
      this.setState((p) => update(p, { value: { $set: label } }));
    } catch (error) {
      console.log("xasame", error);
    } finally {
      this.setState((p) =>
        update(p, { disabled: { $set: false }, searching: { $set: false } })
      );
    }
  }
  async handleChange(query) {
    this.autocomplete(query);
    this.setState((p) =>
      update(p, {
        value: { $set: query },
        data: { $apply: (d) => (!query.length ? [] : d) },
        autocompleteData: { $apply: (d) => (!query.length ? [] : d) },
      })
    );
  }
  render() {
    const { uid } = this.props;
    const { autocompleteData, searching, value, disabled } = this.state;

    return (
      <InputGroup className="PoiAutoComplete" inside>
        <AutoComplete
          uid={uid}
          placement="autoVerticalStart"
          disabled={disabled}
          data={autocompleteData}
          value={value}
          filterBy={() => true}
          renderItem={(item) => {
            return (
              <span className="PoiAutoComplete__option">
                <strong>{item.label} </strong>
                {item.enhanced ? (
                  <Tag color="blue">
                    <strong>
                      <small>Enhanced POI</small>
                    </strong>
                  </Tag>
                ) : null}
              </span>
            );
          }}
          onChange={(query, event) => {
            event.persist();

            if (event.type == "change") {
              this.handleChange(query);
            }
          }}
          onSelect={(item) => this.handleSelect(item)}
        />
        <InputGroup.Button>
          <Icon icon={searching ? "spinner" : "search"} spin={searching} />
        </InputGroup.Button>
      </InputGroup>
    );
  }
}

PoiAutoComplete.defaultProps = {
  disabled: false,
  geodataDetailsOnly: false,
};

PoiAutoComplete.propTypes = {
  // Both value and uid are used to update the input during UI rerenderings
  // when multiple autocomplete components are used. In case of only one
  // component shown there is no need to provide them.
  geodataDetailsOnly: PropTypes.bool,
  uid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  value: PropTypes.string,
  lang: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};
