import _ from "lodash";
import { cardStyles, variables } from "@src/jsssetup";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { createUseStyles } from "react-jss";
import { DayItemControls, daySrvContainerStyles } from ".";
import OverviewPrice from "../OverviewPrice";
import { connect, useSelector } from "react-redux";
import {
  addDbDCustomSrv,
  removeDbDCustomSrv,
} from "@src/actions/Project/TripPlanner/day_by_day/custom_services";
import { PricingFormContext, TripPlanContext } from "../../TripPlanner";
import { service_type_mapping } from "@src/pages/MyLibrary/GeneralServices";
import ServicePricingForm from "../ServicePricingForm";
import {
  getServicePricingSelector,
  srvPriceKeyCreator,
} from "@src/selectors/Project/TripPlanner/pricing";
import { isServiceFilterSelectedSelector } from "@src/selectors/Project/TripPlanner/service_filters";
import { BookChecker } from "../ItineraryOverviewCommon";
import { BookingMetadataBtn } from "../../Widgets/step_four/booking_metadata_btn";
import {
  daySrvBodyStyles,
  daySrvHeaderStyles,
  serviceHeaderActions,
  serviceTypeHeaderStyles,
} from "./common";
import { ReservationStatusTag } from "../service_panels";
import { DateTime, Duration } from "luxon";
import { InlineIcon } from "@iconify/react";
import { useServiceCardExpansion } from "@src/pages/hooks";

const GeneralSrvDataStyles = createUseStyles({
  GeneralService: {
    display: "grid",
    gridGap: variables.half_gap,
  },
  header: {
    ...daySrvHeaderStyles(),
    ...cardStyles.card,
    paddingLeft: variables.double_gap,
    borderRadius: `calc(${variables.normal_gap} / 2)`,
    background: variables.colors.services.generalService,
  },
  headerActions: serviceHeaderActions,
  srvType: serviceTypeHeaderStyles(),
  body: (props) => ({
    ...daySrvBodyStyles(props),
    ...cardStyles.card,
    paddingLeft: variables.double_gap,
    background: variables.colors.services.generalServiceBody,
    height: _.get(props, "collapsed", false) ? 0 : "auto",
    maxHeight: "10rem",
    gridTemplateColumns: "1fr",
    zIndex: 1,
    overflow: "auto",
  }),
  content: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    gridAutoRows: "max-content",
    overflow: "auto",
  },
  title: {
    fontWeight: "600",
    color: variables.colors.text.colored,
  },
  expandCntr: {
    display: "grid",
    gridTemplateColumns: "max-content 1fr",
    gridGap: variables.normal_gap,
    alignItems: "center",
    marginTop: `calc(${variables.normal_gap} * -1)`,
    padding: [
      `calc(${variables.normal_gap} * 1.2)`,
      0,
      `calc(${variables.normal_gap} * 0.8)`,
      variables.double_gap,
    ],
    boxShadow: variables.shadows.normalShadow,
    borderBottomLeftRadius: `calc(${variables.normal_gap} / 2)`,
    borderBottomRightRadius: `calc(${variables.normal_gap} / 2)`,
    background: variables.colors.services.generalServiceBorder,
  },
  expandedDataCntr: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridColumn: "span 2",
    gridGap: variables.normal_gap,
    width: "100%",
  },
  expandTitle: {
    fontSize: "medium",
    fontWeight: "500",
  },
  arrowCntr: {
    display: "grid",
    placeContent: "center",
    width: `calc(${variables.normal_gap} * 1.5)`,
    height: `calc(${variables.normal_gap} * 1.5)`,
    borderRadius: "50%",
    padding: `calc(${variables.half_gap} / 2)`,
    background: variables.colors.easy.orange,
    cursor: "pointer",
  },
  arrowIcon: {
    fontSize: "medium",
    color: "white",
  },
  divider: {
    borderBottom: `1px solid ${variables.colors.deepgrey}`,
    width: "98%",
  },
  expandIconCntr: {
    display: "grid",
    placeContent: "center",
    width: "1.5rem",
    height: "1.5rem",
    borderRadius: "50%",
    background: variables.colors.easy.orange,
    padding: variables.half_gap,
    cursor: "pointer",
  },
  expandIcon: {
    fontSize: "large",
    color: variables.colors.text.light,
  },
});
export const GeneralSrvData = ({
  service,
  date,
  reservationMode,
  reservationLoading,
  reservationStatus,
  services_collapsed,
  toggleSelfCollapse,
}) => {
  const classes = GeneralSrvDataStyles();

  const [expanded, setExpanded] = useState(false);
  const { service_data } = service;
  const pricing_data = _.get(service_data, "pricing", []);
  const price_changed = _.get(service_data, "price_data.original_price_edited");

  return (
    <div className={classes.GeneralService}>
      <div className={classes.header}>
        <span>
          General Service (
          {_.get(service_type_mapping, service_data.type, "N/A")}
          ): {service.name_en}{" "}
          {_.get(service_data, "short_description_en", "N/A")}
          {date && ", Date: "}
          {date &&
            DateTime.fromISO(date).toLocaleString(
              DateTime.DATE_MED_WITH_WEEKDAY
            )}
        </span>
        <div className={classes.headerActions}>
          {price_changed && (
            <span className={classes.srvType}>Price Edited</span>
          )}
          {!reservationMode ? (
            <BookingMetadataBtn
              uid={service.id}
              srv_type={service.custom_service_type}
              booking_metadata={service.booking_metadata}
            />
          ) : (
            <ReservationStatusTag
              reservationLoading={reservationLoading}
              reservationStatus={reservationStatus}
            />
          )}
          <div className={classes.expandIconCntr}>
            <InlineIcon
              className={classes.expandIcon}
              icon={
                services_collapsed
                  ? "ri:arrow-down-s-line"
                  : "ri:arrow-up-s-line"
              }
              onClick={toggleSelfCollapse}
            />
          </div>
        </div>
      </div>
      {services_collapsed ? null : (
        <React.Fragment>
          <div className={classes.body}>
            <div className={classes.content}>
              <span>
                <span className={classes.title}>External Id: </span>{" "}
                {service_data.id}
              </span>
              <span>
                <span className={classes.title}>Start Time: </span>
                {_.get(service, "booking_metadata.start_time", "N/A")}
              </span>
              <span>
                <span className={classes.title}>Description: </span>{" "}
                {service_data.name ? service_data.name : "N/A"}
              </span>
              <span>
                <span className={classes.title}>Duration: </span>
                {service_data.duration
                  ? Object.entries(
                      Duration.fromObject({
                        hours: 0,
                        minutes: service_data.duration,
                      })
                        .normalize()
                        .toObject()
                    )
                      .filter((e) => e[1])
                      .map((e) => `${e[1]} ${e[0]}`)
                      .join(", ")
                  : "0 Minutes"}
              </span>
              <span>
                <span className={classes.title}>Price Ids: </span>{" "}
                {pricing_data.map((pr) => pr.description).join(", ") || "N/A"}
              </span>
              <span>
                <span className={classes.title}>Availability Type: </span>
                {[
                  ...new Set(
                    pricing_data.map((pr) => _.startCase(pr.availability_type))
                  ),
                ].join(", ") || "N/A"}
              </span>
            </div>
          </div>
          <div className={classes.expandCntr}>
            <span className={classes.expandTitle}>Remarks</span>
            <span className={classes.arrowCntr}>
              <InlineIcon
                className={classes.arrowIcon}
                icon={
                  expanded ? "akar-icons:chevron-up" : "akar-icons:chevron-down"
                }
                onClick={() => setExpanded(!expanded)}
              />
            </span>
            {expanded && (
              <div className={classes.expandedDataCntr}>
                <div className={classes.divider} />
                <div className={classes.expandedData}>
                  {service_data.remarks ? service_data.remarks : "N/A"}
                </div>
              </div>
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
GeneralSrvData.propTypes = {
  date: PropTypes.string.isRequired,
  service: PropTypes.object.isRequired,
  reservationMode: PropTypes.bool,
  reservationLoading: PropTypes.bool,
  reservationStatus: PropTypes.string,
};

const GeneralServiceStyles = createUseStyles({
  GeneralServiceContainer: (props) =>
    daySrvContainerStyles({ currentStep: props.currentStep }),
});
const GeneralService = ({
  no_price,
  service,
  dayIdx,
  withControls,
  date,
  onRemove,
  onAdd,
  onEdit,
}) => {
  const { view_style } = useSelector((state) => state.tripPlannerOverviewStyle);
  const currentStep = useSelector((state) => state.tripPlannerCurrentStep);
  const serviceIsSelected = useSelector((state) =>
    isServiceFilterSelectedSelector(state, { service_type: "GEN" })
  );

  const { markup, gross_margin, flat_value } = useSelector((state) =>
    getServicePricingSelector(state, {
      key: srvPriceKeyCreator({ service, service_type: "GEN" }),
    })
  );

  const { showPricingForm } = useContext(PricingFormContext);
  const { services_collapsed, toggleSelfCollapse } = useServiceCardExpansion();

  const { service_data } = service;

  const classes = GeneralServiceStyles({
    collapsed: services_collapsed,
    currentStep,
  });

  var price = _.get(service_data, "price_data.total_price", 0);
  const currency = _.get(service_data, "price_data.currency");

  return !serviceIsSelected ? null : (
    <div className={classes.GeneralServiceContainer}>
      {currentStep === 4 ? (
        <BookChecker uid={service.id} srv_type={service.custom_service_type} />
      ) : !services_collapsed && withControls ? (
        <DayItemControls
          onDeselect={() => onRemove({ dayIdx, srv_id: service.id })}
          onAdd={onAdd}
          onEdit={() => onEdit({ dayIdx, srv_id: service.id })}
        />
      ) : (
        <div />
      )}
      <GeneralSrvData
        service={service}
        services_collapsed={services_collapsed}
        toggleSelfCollapse={toggleSelfCollapse}
        date={view_style === "overview" ? date : null}
      />
      {!no_price && showPricingForm ? (
        <ServicePricingForm service={service} service_type="GEN" />
      ) : !no_price ? (
        services_collapsed ? (
          <div />
        ) : (
          <OverviewPrice
            searching={false}
            serviceType="MI"
            price={price}
            currency={currency}
            markup={markup}
            gross_margin={gross_margin}
            flat_value={flat_value}
            onDetails={() => console.log("onDetails")}
          />
        )
      ) : null}
    </div>
  );
};
GeneralService.defaultProps = {
  withControls: true,
  no_price: false,
};
GeneralService.propTypes = {
  no_price: PropTypes.bool.isRequired,
  withControls: PropTypes.bool.isRequired,
  dayIdx: PropTypes.number,
  service: PropTypes.object.isRequired,
  date: PropTypes.string,
  onRemove: PropTypes.func,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  onCopyBelow: PropTypes.func.isRequired,
};
const mapDispatchToProps = (dispatch) => {
  return {
    onRemove: ({ dayIdx, srv_id }) =>
      dispatch(removeDbDCustomSrv({ dayIdx, srv_id, srv_type: "GEN" })),
    onCopyBelow: ({ dayIdx, srv }) =>
      dispatch(addDbDCustomSrv({ dayIdx: dayIdx + 1, srv, srv_type: "GEN" })),
  };
};
export default connect(null, mapDispatchToProps)(GeneralService);
