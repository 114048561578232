import { InlineIcon } from "@iconify/react";
import { fetchChatGroups } from "@src/api/chat";
import { variables } from "@src/jsssetup";
import { getUserMetaSelector } from "@src/selectors/Shared/user_selectors";
import _ from "lodash";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from "react-redux";
import { AvatarComp } from "../helpers";
import Fuse from "fuse.js";

const fuseCfg = {
  shouldSort: false,
  threshold: 0.5,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ["name", "members.email"],
};

const chatRoomstyles = createUseStyles({
  ChatRoom: (props) => ({
    display: "grid",
    gridTemplateColumns: "max-content 1fr max-content",
    gridGap: variables.half_gap,
    alignItems: "center",
    background: props.selected ? variables.colors.easy.lightOrange : "none",
    padding: variables.half_gap,
    borderRadius: variables.half_gap,
    cursor: props.selected ? "unset" : "pointer",
    lineHeight: "normal",
  }),
  name: {
    fontWeight: "bold",
    fontSize: "small",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "9rem",
  },
  owner: {
    fontSize: "small",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  newMsgsNum: (props) => ({
    display: "grid",
    placeItems: "center",
    background: "white",
    color: variables.colors.text.dark,
    borderRadius: "50%",
    fontSize: "small",
    height: `calc(${variables.normal_gap} * ${props.newMsgsNumSize})`,
    aspectRatio: "1 / 1",
  }),
});
const ChatRoom = ({ data, selected, newMsgsNum, onSelect }) => {
  const classes = chatRoomstyles({
    selected,
    newMsgsNumSize: newMsgsNum < 10 ? 1.6 : newMsgsNum > 100 ? 2.3 : 1.8,
  });

  const moreThanTwoMembers =
    _.get(data, "members", []).filter(
      (member) => member.user_type === "customer"
    ).length > 1;

  return (
    <div className={classes.ChatRoom} onClick={() => onSelect(data)}>
      <AvatarComp
        members={data.members.filter(
          (member) => member.user_type === "customer"
        )}
        moreThanTwo={moreThanTwoMembers}
      />

      <div className={classes.content}>
        <div className={classes.name}>{data.name}</div>
        <div className={classes.owner}>
          {_.get(
            data.members.find((m) => m.user_type === "customer"),
            "email"
          )}
        </div>
        <div className={classes.name}>
          <small>
            {data.created
              ? DateTime.fromISO(data.created).toLocaleString(
                  DateTime.DATE_MED_WITH_WEEKDAY
                )
              : ""}
          </small>
        </div>
      </div>
      {newMsgsNum > 0 && <div className={classes.newMsgsNum}>{newMsgsNum}</div>}
    </div>
  );
};
ChatRoom.propTypes = {
  data: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  newMsgsNum: PropTypes.number,
  onSelect: PropTypes.func.isRequired,
};

const styles = createUseStyles({
  ChatRooms: {
    color: "white",
    gridGap: variables.double_gap,
    position: "relative",
  },
  searchForm: {
    display: "grid",
    gridTemplateColumns: "1fr max-content",
    alignItems: "center",
    background: "white",
    padding: variables.half_gap,
    borderRadius: variables.half_gap,
    boxSizing: "border-box",
  },
  inputField: {
    border: "none",
    width: "100%",
    color: variables.colors.text.dark,
  },
  searchIcon: {
    fontSize: "large",
    color: "gray",
  },
  offersList: {
    display: "grid",
    alignContent: "baseline",
    gridGap: variables.normal_gap,
    position: "absolute",
    top: `calc(${variables.normal_gap} * 4)`,
    bottom: 0,
    overflowY: "auto",
    width: "100%",
  },
});

const ChatRooms = ({
  preselected_group_uid,
  chatNotifications,
  selected_group_uid,
  onSelectRoom,
}) => {
  const classes = styles({});
  const [groupsList, setGroupsList] = useState([]);
  const [filteredGroupsList, setFilteredGroupsList] = useState([]);
  const [filter, setFilter] = useState("");

  const member_email = useSelector((state) =>
    _.get(getUserMetaSelector(state), "user.email", "")
  );

  async function getGroups() {
    await fetchChatGroups({ member_email }).then((result) => {
      setGroupsList(result ?? []);
      setFilteredGroupsList(result ?? []);
    });
  }

  useEffect(() => {
    getGroups();
  }, []);

  useEffect(() => {
    if (!filter) {
      setFilteredGroupsList(groupsList);
    } else {
      const fuseData = new Fuse(groupsList, fuseCfg);
      setFilteredGroupsList(fuseData.search(filter) ?? []);
    }
  }, [filter]);

  // Activate preselected room if it exists.
  useEffect(() => {
    if (!preselected_group_uid) {
      return;
    }

    if (!groupsList.length) {
      return;
    }

    const group = groupsList.find((grp) => grp.uid === preselected_group_uid);
    if (group) {
      onSelectRoom(group);
    }
  }, [preselected_group_uid, groupsList]);

  return (
    <div className={classes.ChatRooms}>
      <div className={classes.searchForm}>
        <input
          type="text"
          className={classes.inputField}
          placeholder="Search"
          onChange={(e) => setFilter(e.target.value)}
        />
        <InlineIcon
          icon="ph:magnifying-glass-bold"
          className={classes.searchIcon}
        />
      </div>
      <div className={classes.offersList}>
        {filteredGroupsList
          .filter((group) =>
            _.get(group, "members", []).some(
              (mbr) => mbr.user_type === "customer"
            )
          )
          .map((group, idx) => (
            <ChatRoom
              key={idx}
              newMsgsNum={
                [
                  ...chatNotifications.filter(
                    (notf) => notf.room_uid === group.uid
                  ),
                ].length
              }
              member_email={member_email}
              data={group}
              selected={selected_group_uid === group.uid}
              onSelect={(data) => onSelectRoom(data)}
            />
          ))}
      </div>
    </div>
  );
};
ChatRooms.propTypes = {
  preselected_group_uid: PropTypes.string,
  chatNotifications: PropTypes.array.isRequired,
  selected_group_uid: PropTypes.object,
  onSelectRoom: PropTypes.func.isRequired,
};
export default ChatRooms;
