import { variables } from "@src/jsssetup";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import {
  getAccAPIPriceSelector,
  getTransferPriceSelector,
} from "@src/selectors/Project/TripPlanner";
import { WhisperWrapper } from "@src/components/common/ui_helpers";
import { Icon, IconButton } from "rsuite";
import OverviewAccommodation from "../../Controllers/OverviewAccommodation";
import OverviewPrice from "../OverviewPrice";
import { createUseStyles } from "react-jss";
import OverviewTransfer from "../../Controllers/OverviewTransfer";
import { OverviewFlight } from "../OverviewFlight";
import { AddOn } from "../../Controllers/DayByDay";
import RestaurantService from "./restaurant";
import CoordinatorService from "./coordinator";
import GeneralService from "./general_service";
import { GenericAdhocSrv } from "./addhoc";
import CustomAccSrv from "../custom/accommodation";
import AdhocAccSrv from "../addhoc/accommodation";
import { sideControlsStyles } from "../../Controllers/OverviewDestination";
import { useSelector } from "react-redux";
import { isServiceFilterSelectedSelector } from "@src/selectors/Project/TripPlanner/service_filters";
import { TrainService } from "../Modals/products/addhoc/train";
import { useServiceCardExpansion } from "@src/pages/hooks";
import {
  getServicePricingSelector,
  srvPriceKeyCreator,
} from "@src/selectors/Project/TripPlanner/pricing";
import { FerryService } from "../Modals/products/addhoc/ferry";
import CoachService from "../addhoc/coach";
import { OverviewFerry } from "../OverviewFerry";
import { OverviewTrain } from "../OverviewTrain";

export function daySrvContainerStyles(options = {}) {
  const { currentStep = 3 } = options;
  return {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    gridTemplateColumns: currentStep === 3 ? "2rem 1fr 6rem" : "2rem 1fr 5rem",
    alignItems: "center",
  };
}

function daySrvControls() {
  return {
    display: "grid",
    alignContent: "center",
    gridGap: variables.normal_gap,
  };
}

const dayItemControlsStyles = createUseStyles({
  DayItemControls: sideControlsStyles(),
});
export const DayItemControls = ({ onEdit, onDeselect, onAdd, onCopyBelow }) => {
  const classes = dayItemControlsStyles();

  return (
    <div className={classes.DayItemControls}>
      {typeof onEdit === "function" && (
        <WhisperWrapper msg="Edit Service">
          <IconButton
            icon={<Icon icon="edit" />}
            circle
            size="xs"
            color="blue"
            onClick={onEdit}
          />
        </WhisperWrapper>
      )}
      {typeof onDeselect === "function" && (
        <WhisperWrapper msg="Remove Service">
          <IconButton
            icon={<Icon icon="close" />}
            circle
            size="xs"
            color="red"
            onClick={onDeselect}
          />
        </WhisperWrapper>
      )}
      {typeof onCopyBelow === "function" && (
        <WhisperWrapper msg="Copy Below">
          <IconButton
            icon={<Icon icon="copy" />}
            circle
            size="xs"
            color="blue"
            onClick={onCopyBelow}
          />
        </WhisperWrapper>
      )}
      {typeof onAdd === "function" && (
        <WhisperWrapper msg="Add Service">
          <IconButton
            icon={<Icon icon="plus" />}
            circle
            size="xs"
            color="green"
            onClick={onAdd}
          />
        </WhisperWrapper>
      )}
    </div>
  );
};
DayItemControls.propTypes = {
  onEdit: PropTypes.func,
  onDeselect: PropTypes.func.isRequired,
  onAdd: PropTypes.func,
  onCopy: PropTypes.func,
  onCopyBelow: PropTypes.func,
};

const dayAccommodationStyles = createUseStyles({
  DayAccommodation: daySrvContainerStyles(),
  controls: daySrvControls(),
});
export const DayAccommodation = ({ day, eventType, withPrice, onAdd }) => {
  const serviceIsSelected = useSelector((state) =>
    isServiceFilterSelectedSelector(state, { service_type: "ACC" })
  );

  const classes = dayAccommodationStyles();

  const services = _.get(day, "services", []);
  function getAccService(eventType) {
    const srv = services.find(
      (s) => s.eventType == eventType && s.srvType == "ACC"
    );
    return srv;
  }

  const srv = getAccService(eventType);

  if (!srv || !srv.service) {
    return null;
  }

  const { price, currency } = useSelector((state) =>
    getAccAPIPriceSelector(state, { accommodation: srv.service })
  );

  const hasAddons = services.filter((srv) => srv.srvType === "MI").length > 0;
  const inBetweenDestsDay =
    services.filter((srv) => srv.srvType == "ACC").length > 1;

  const { services_collapsed, toggleSelfCollapse } = useServiceCardExpansion();

  const { markup } = useSelector((state) =>
    getServicePricingSelector(state, {
      key: srvPriceKeyCreator({ service: srv.service, service_type: "ACC" }),
    })
  );

  return !serviceIsSelected ? null : srv.adhoc_service ? (
    <AdhocAccSrv srv={srv.service} eventType={eventType} />
  ) : srv.custom_service ? (
    <CustomAccSrv srv={srv.service} eventType={eventType} />
  ) : (
    <div
      key={`${day.day}__acc__${eventType}`}
      className={classes.DayAccommodation}>
      <div className={classes.controls}>
        {hasAddons || inBetweenDestsDay
          ? null
          : !services_collapsed && (
              <WhisperWrapper msg="Add Service">
                <IconButton
                  icon={<Icon icon="plus" />}
                  circle
                  size="xs"
                  color="green"
                  onClick={function () {
                    onAdd(
                      day.destinations.map((d) => d.order),
                      day.date,
                      day.day
                    );
                  }}
                />
              </WhisperWrapper>
            )}
      </div>
      <OverviewAccommodation
        services_collapsed={services_collapsed}
        toggleSelfCollapse={toggleSelfCollapse}
        eventType={eventType}
        prebookData={{}}
        destOrder={srv.destOrder}
        withPrice={withPrice}
      />
    </div>
  );
};
DayAccommodation.propTypes = {
  day: PropTypes.object.isRequired,
  eventType: PropTypes.string.isRequired,
  withPrice: PropTypes.bool.isRequired,
  onAdd: PropTypes.func.isRequired,
};

const dayTransferStyles = createUseStyles({
  DayTransfer: daySrvContainerStyles(),
});
export const DayTransfer = ({
  day,
  eventType,
  currentStep,
  onViewTrfDetails,
}) => {
  const serviceIsSelected = useSelector((state) =>
    isServiceFilterSelectedSelector(state, { service_type: "TRF" })
  );

  const classes = dayTransferStyles();

  const services = _.get(day, "services", []);
  const srv = services.find(
    (s) => s.srvType == "TRF" && s.eventType == eventType
  );

  const { services_collapsed, toggleSelfCollapse } = useServiceCardExpansion();

  if (!srv || !srv.service) {
    return null;
  }

  const { markup } = useSelector((state) =>
    getServicePricingSelector(state, {
      key: srvPriceKeyCreator({ service: srv.service, service_type: "TRF" }),
    })
  );

  const price = getTransferPriceSelector(srv.service);

  return !serviceIsSelected ? null : (
    <div key={`${day.day}__trf__${eventType}`} className={classes.DayTransfer}>
      <div />
      <OverviewTransfer
        isLoading={false}
        trfType={eventType}
        destOrder={srv.destOrder}
        transfer={srv.service}
        currentStep={currentStep}
        services_collapsed={services_collapsed}
        toggleSelfCollapse={toggleSelfCollapse}
      />
      {!services_collapsed && (
        <OverviewPrice
          serviceType="TRF"
          price={price}
          markup={markup}
          onDetails={function () {
            onViewTrfDetails(srv.destOrder, eventType, srv.service.booking_id);
          }}
        />
      )}
    </div>
  );
};
DayTransfer.propTypes = {
  currentStep: PropTypes.number.isRequired,
  day: PropTypes.object.isRequired,
  eventType: PropTypes.string.isRequired,
  onViewTrfDetails: PropTypes.func.isRequired,
};

const dayFlightStyles = createUseStyles({ DayFlight: daySrvContainerStyles() });
export const DayFlight = ({ day, eventType, withPrice = true }) => {
  const classes = dayFlightStyles();

  // Calculate the correct leg based on the eventType
  const { tripLeg } = useSelector((state) => {
    var tripLeg = null;
    if (eventType === "departure") {
      tripLeg = state.tripPlannerLegsReducer.find(
        (l) => l.origin_order === day.destinations[0].order
      );
    } else {
      // arrival
      tripLeg = state.tripPlannerLegsReducer.find(
        (l) => l.destination_order === _.last(day.destinations).order
      );
    }
    return { tripLeg };
  });

  const { services_collapsed, toggleSelfCollapse } = useServiceCardExpansion();
  const serviceIsSelected = useSelector((state) =>
    isServiceFilterSelectedSelector(state, {
      service_type: "TR",
    })
  );

  const hasFlights = day?.services?.some((srv) => srv.srvType === "FL");
  return !serviceIsSelected || !hasFlights ? null : (
    <div key={`${day.day}__fl`} className={classes.DayFlight}>
      <OverviewFlight
        legUid={tripLeg.uid}
        currentStep={3}
        services_collapsed={services_collapsed}
        toggleSelfCollapse={toggleSelfCollapse}
        eventType={eventType}
        withPrice={withPrice}
      />
    </div>
  );
};
DayFlight.propTypes = {
  day: PropTypes.object.isRequired,
  eventType: PropTypes.string.isRequired,
  withPrice: PropTypes.bool.isRequired,
};

const dayApiFerryStyles = createUseStyles({
  DayAPIFerry: daySrvContainerStyles(),
});
export const DayAPIFerry = ({ day, eventType }) => {
  const classes = dayApiFerryStyles();
  const ferryData = day.services.find(
    (srv) => srv.srvType === "AFER" && srv.eventType === eventType
  );

  const { services_collapsed, toggleSelfCollapse } = useServiceCardExpansion();
  const serviceIsSelected = useSelector((state) =>
    isServiceFilterSelectedSelector(state, {
      service_type: "TR",
    })
  );

  return !serviceIsSelected || !ferryData ? null : (
    <div key={`${day.day}__fl`} className={classes.DayAPIFerry}>
      <OverviewFerry
        legUid={ferryData?.service?.legUid}
        services_collapsed={services_collapsed}
        toggleSelfCollapse={toggleSelfCollapse}
      />
    </div>
  );
};
DayAPIFerry.propTypes = {
  day: PropTypes.object.isRequired,
  eventType: PropTypes.string.isRequired,
};

const dayApiTrainStyles = createUseStyles({
  DayAPITrain: daySrvContainerStyles(),
});
export const DayAPITrain = ({ day, eventType, withPrice = true }) => {
  const classes = dayApiTrainStyles();
  const trainData = day.services.find(
    (srv) => srv.srvType === "ATRA" && srv.eventType === eventType
  );

  const { services_collapsed, toggleSelfCollapse } = useServiceCardExpansion();
  const serviceIsSelected = useSelector((state) =>
    isServiceFilterSelectedSelector(state, { service_type: "TR" })
  );

  return !serviceIsSelected || !trainData ? null : (
    <div key={`${day.day}__fl`} className={classes.DayAPITrain}>
      <OverviewTrain
        legUid={trainData?.service?.legUid}
        services_collapsed={services_collapsed}
        currentStep={3}
        toggleSelfCollapse={toggleSelfCollapse}
        withPrice={withPrice}
        eventType={eventType}
      />
    </div>
  );
};
DayAPITrain.propTypes = {
  day: PropTypes.object.isRequired,
  eventType: PropTypes.string.isRequired,
  withPrice: PropTypes.bool.isRequired,
};

export const DayTrain = ({ day, eventType }) => {
  const serviceIsSelected = useSelector((state) =>
    isServiceFilterSelectedSelector(state, { service_type: "TR" })
  );

  const srvs = (day?.services ?? []).filter(
    (srv) => srv.srvType == "TRA" && srv.eventType === eventType
  );

  return (
    serviceIsSelected &&
    srvs.map((srv, idx) => (
      <TrainService
        srv={srv.service}
        key={`${day.day}__tra__${idx}`}
        eventType={eventType}
      />
    ))
  );
};

export const DayFerry = ({ day, eventType }) => {
  const serviceIsSelected = useSelector((state) =>
    isServiceFilterSelectedSelector(state, { service_type: "TR" })
  );

  const srvs = (day?.services ?? []).filter(
    (srv) => srv.srvType == "FER" && srv.eventType === eventType
  );

  return (
    serviceIsSelected &&
    srvs.map((srv, idx) => (
      <FerryService
        srv={srv.service}
        key={`${day.day}__fer__${idx}`}
        eventType={eventType}
      />
    ))
  );
};

export const DayCoach = ({ day, eventType }) => {
  const serviceIsSelected = useSelector((state) =>
    isServiceFilterSelectedSelector(state, { service_type: "TR" })
  );

  const srvs = (day?.services ?? []).filter(
    (srv) => srv.srvType == "COA" && srv.eventType === eventType
  );

  return (
    serviceIsSelected &&
    srvs.map((srv, idx) => (
      <CoachService
        srv={srv.service}
        key={`${day.day}__fer__${idx}`}
        eventType={eventType}
      />
    ))
  );
};

const dayAddonStyles = createUseStyles({
  DayAddon: { ...daySrvContainerStyles() },
  controls: daySrvControls(),
  price: { display: "grid" },
});
export const DayAddon = ({
  day,
  addon,
  price,
  onDeselect,
  onAdd,
  onViewAddOnDetails,
}) => {
  const classes = dayAddonStyles();

  const { services_collapsed, toggleSelfCollapse } = useServiceCardExpansion();

  return (
    <div className={classes.DayAddon}>
      <div className={classes.controls}>
        {!services_collapsed && (
          <React.Fragment>
            <WhisperWrapper msg="Remove Service">
              <IconButton
                icon={<Icon icon="close" />}
                circle
                size="xs"
                color="red"
                onClick={function () {
                  onDeselect(day.day, addon.service.id);
                }}
              />
            </WhisperWrapper>
            <WhisperWrapper msg="Add Service">
              <IconButton
                icon={<Icon icon="plus" />}
                circle
                size="xs"
                color="green"
                onClick={function () {
                  onAdd(
                    day.destinations.map((d) => d.order),
                    day.date,
                    day.day
                  );
                }}
              />
            </WhisperWrapper>
          </React.Fragment>
        )}
      </div>
      <AddOn
        addon={addon}
        status={addon.status}
        services_collapsed={services_collapsed}
        toggleSelfCollapse={toggleSelfCollapse}
        onViewAll={function () {
          if (addon.status == "unavailable") {
            onDeselect(day.day, addon.service.id);
            onAdd(
              day.destinations.map((d) => d.order),
              day.date,
              day.day
            );
          } else {
            onAdd(
              day.destinations.map((d) => d.order),
              day.date,
              day.day
            );
          }
        }}
      />
      {!services_collapsed && (
        <OverviewPrice
          searching={addon.status == "searching"}
          serviceType="MI"
          price={addon.status == "unavailable" ? 0 : price?.value ?? 0}
          currency={price?.currency ?? "EUR"}
          markup={addon.markup}
          onDetails={function () {
            onViewAddOnDetails(
              day.destinations.map((d) => d.order),
              day.date,
              day.day,
              addon.service.id
            );
          }}
        />
      )}
    </div>
  );
};
DayAddon.propTypes = {
  day: PropTypes.object.isRequired,
  addon: PropTypes.object.isRequired,
  price: PropTypes.object.isRequired,
  onDeselect: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onViewAddOnDetails: PropTypes.func.isRequired,
};

export const DayAddons = ({ day, onAdd, onViewAddOnDetails, onDeselect }) => {
  const serviceIsSelected = useSelector((state) =>
    isServiceFilterSelectedSelector(state, { service_type: "MI" })
  );

  const addons = _.get(day, "services", []).filter(
    (srv) => srv.srvType == "MI"
  );

  return !serviceIsSelected
    ? null
    : addons.map((addon, idx) => {
        const price = _.get(addon, "service.price", []).find(
          (pr) => pr.group_uid == addon.service.meetingGroupUid
        );

        return (
          <DayAddon
            key={idx}
            day={day}
            price={price}
            addon={addon}
            onDeselect={onDeselect}
            onAdd={onAdd}
            onViewAddOnDetails={onViewAddOnDetails}
          />
        );
      });
};
DayAddons.propTypes = {
  day: PropTypes.object.isRequired,
  onDeselect: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onViewAddOnDetails: PropTypes.func.isRequired,
};

export const DayCustomSrvs = ({
  day,
  isLastDay,
  onAdd,
  onEdit,
  onEditRestService,
  onEditCoordService,
}) => {
  const srvs = (day?.services ?? []).filter((srv) => srv.srvType === "CU");

  return srvs.map((srv, idx) => {
    switch (srv.custom_service_type) {
      case "REST":
        return (
          <RestaurantService
            key={idx}
            service={srv}
            dayIdx={day.day}
            isLastDay={isLastDay}
            onAdd={onAdd}
            onEdit={onEditRestService}
          />
        );
      case "COO":
        return (
          <CoordinatorService
            key={idx}
            service={srv}
            date={day.date}
            dayIdx={day.day}
            onAdd={onAdd}
            onEdit={onEditCoordService}
          />
        );
      case "GEN":
        return (
          <GeneralService
            key={idx}
            service={srv}
            date={day.date}
            dayIdx={day.day}
            onAdd={onAdd}
            onEdit={onEdit}
          />
        );
      default:
        return null;
    }
  });
};
DayCustomSrvs.propTypes = {
  day: PropTypes.object.isRequired,
  isLastDay: PropTypes.bool.isRequired,
  onAdd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onEditRestService: PropTypes.func.isRequired,
  onEditCoordService: PropTypes.func.isRequired,
};

export const DayAdhocSrvs = ({ day, isLastDay, onAdd, onEdit }) => {
  const srvs = _.get(day, "services", []).filter((srv) => srv.srvType === "AD");

  return srvs.map((srv, idx) => (
    <GenericAdhocSrv
      key={idx}
      service={srv}
      dayIdx={day.day}
      isLastDay={isLastDay}
      onAdd={onAdd}
      onEdit={onEdit}
    />
  ));
};
DayAdhocSrvs.propTypes = {
  day: PropTypes.object.isRequired,
  isLastDay: PropTypes.bool.isRequired,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func.isRequired,
};
