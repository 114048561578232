import _ from "lodash";
import {
  NormalAccAutocompleteField,
  NormalDatePicker,
  NormalInputField,
  NormalSelectField,
} from "@src/components/forms";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import React from "react";
import { Button, Loader } from "rsuite";
import { v4 } from "uuid";
import { adhocCurrencyOptions } from "..";
import { addonAccSrvPriceCalculator } from "../../../AddAddhocAccSrvModal";
import { useSelector } from "react-redux";
import { getSetupFormDataSelector } from "@src/selectors/Project/TripPlanner";
import { AdhocPrice, ExtraForm, RoomForm } from "./formComponents";

const AdhocAccForm = ({
  searchable = true,
  initialValues,
  loading,
  onSubmit,
  onCancel,
  setUid,
}) => {
  const { adults, children: childs } = useSelector((state) =>
    getSetupFormDataSelector(state)
  );

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{ ...initialValues }}
      validate={(values) => {
        addonAccSrvPriceCalculator({ values, adults, children: childs });

        if (values.search_accommodation.includes("---")) {
          setUid(values.search_accommodation.split("---")[1]);
        }
      }}
      onSubmit={(values) => onSubmit(values)}>
      {({ values, setValues, submitForm }) => (
        <React.Fragment>
          <div className="Modal__card__body">
            <Form className="Form AdhocAccForm">
              <div className="AdhocAccForm__body">
                {loading && <Loader size="lg" backdrop={true} />}
                {searchable && (
                  <NormalAccAutocompleteField
                    name="search_accommodation"
                    label="Search Accommoation"
                    id="adhoc_search_accommodation"
                  />
                )}
                <div className="AdhocAccForm__body__imagesContainer">
                  {_.get(values, "metadata.images", []).map((img, idx) => (
                    <img
                      key={idx}
                      src={img.url}
                      className="AdhocAccForm__img"
                    />
                  ))}
                </div>
                <div className="AdhocAccForm__col2">
                  <NormalInputField name="metadata.name" label="Name" />
                  <NormalSelectField
                    name="metadata.rating"
                    label="Rating"
                    options={[
                      [null, "------"],
                      [1, "1 Star"],
                      [2, "2 Star"],
                      [3, "3 Star"],
                      [4, "4 Star"],
                      [5, "5 Star"],
                    ]}
                  />
                </div>
                <NormalInputField name="metadata.address" label="Address" />
                <NormalInputField
                  name="metadata.description"
                  label="Description"
                  as="textarea"
                />
                <div className="AdhocAccForm__col2">
                  <NormalInputField name="metadata.phone" label="Phone" />
                  <NormalInputField name="metadata.fax" label="Fax" />
                  <NormalDatePicker
                    name="check_in"
                    label="Check In"
                    withTime={true}
                  />
                  <NormalDatePicker
                    name="check_out"
                    label="Check Out"
                    withTime={true}
                  />
                </div>
                <div className="AdhocAccForm__roomsHeader">
                  <span>Rooms</span>
                  <div className="AdhocAccForm__roomsHeader__roomsHeaderActions">
                    <Button
                      size="xs"
                      color="green"
                      onClick={() => {
                        const newRooms = _.cloneDeep(values.rooms);
                        newRooms.push({
                          name: "",
                          board: "",
                          type: "",
                          bedding: "",
                          uid: v4(),
                          price_per_person_per_night: 0,
                          price_per_room_per_night: 0,
                          price: 0,
                          adults: 0,
                          children: 0,
                          children_ages: "",
                          price_type: "per_person",
                        });
                        setValues({ ...values, rooms: newRooms });
                      }}>
                      <strong>Add Room</strong>
                    </Button>
                  </div>
                </div>
                {values.rooms.map((room, idx) => (
                  <RoomForm
                    key={idx}
                    idx={idx}
                    room={room}
                    rooms={values.rooms}
                    setRooms={({ rooms }) => setValues({ ...values, rooms })}
                  />
                ))}
                <div className="AdhocAccForm__roomsHeader">
                  <span>Extras</span>
                  <div className="AdhocAccForm__roomsHeader__roomsHeaderActions">
                    <Button
                      size="xs"
                      color="green"
                      onClick={() => {
                        const newExtras = _.cloneDeep(values.extras);
                        newExtras.push({
                          type: [],
                          description: "",
                          price_per_person: 0,
                          price_per_room: 0,
                          price: 0,
                          uid: v4(),
                          price_type: "per_person",
                        });
                        setValues({ ...values, extras: newExtras });
                      }}>
                      <strong>Add Extra</strong>
                    </Button>
                  </div>
                </div>
                {values.extras.map((ext, idx) => (
                  <ExtraForm
                    key={idx}
                    idx={idx}
                    extra={ext}
                    extras={values.extras}
                    price_type={values.price_type}
                    setExtras={({ extras }) => setValues({ ...values, extras })}
                  />
                ))}
                <div className="AdhocAccForm__col2">
                  <AdhocPrice
                    label="Price Per Night"
                    price={values.per_night_price}
                  />
                  <AdhocPrice label="Price" price={values.total_price} />
                  <NormalSelectField
                    name="currency"
                    label="Currency"
                    options={adhocCurrencyOptions}
                  />
                </div>
              </div>
            </Form>
          </div>
          <div className="Modal__card__actions AdhocAccForm__actions">
            <Button appearance="ghost" onClick={onCancel}>
              <strong>Cancel</strong>
            </Button>
            <Button appearance="primary" onClick={submitForm}>
              <strong>Submit</strong>
            </Button>
          </div>
        </React.Fragment>
      )}
    </Formik>
  );
};
AdhocAccForm.propTypes = {
  searchable: PropTypes.bool,
  hotelMeta: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  setUid: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export { AdhocAccForm };
