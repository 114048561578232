import service from "@src/api/request";

export function getMultiServicesList(para) {
  return service({
    url: "bid/servicebundle/",
    method: "get",
    params: para,
  });
}

export function getMultiServicesDetailInfo(id) {
  return service({
    url: `bid/servicebundle/${id}`,
    method: "get",
  });
}

export function getMultiServicesGraphData(param) {
  return service({
    url: "/bid/multi-services-stats/",
    method: "get",
    params: param,
  });
}
