//================================ LIST VIEW======================================
export const RECEIVABLE_BEGIN_FETCH_DATA = "RECEIVABLE_BEGIN_FETCH_DATA";
export const RECEIVABLE_FETCH_DATA = "RECEIVABLE_FETCH_DATA";
export const RECEIVABLE_END_FETCH_DATA = "RECEIVABLE_END_FETCH_DATA";

//==================================FILTER RELATION================================
export const RECEIVABLE_INITIALIZE_LIST_FILTER_FORM =
  "RECEIVABLE_INITIALIZE_LIST_FILTER_FORM";
export const RECEIVABLE_UPDATE_LIST_FILTER_FORM =
  "RECEIVABLE_UPDATE_LIST_FILTER_FORM";
export const RECEIVABLE_APPLY_LIST_FILTER = "RECEIVABLE_APPLY_LIST_FILTER";

//================================== COLUMNS RELATION====================================
export const RECEIVABLE_INITIALIZE_LIST_COLUMNS_FORM =
  "RECEIVABLE_INITIALIZE_LIST_COLUMNS_FORM";
export const RECEIVABLE_APPLY_LIST_COLUMNS = "RECEIVABLE_APPLY_LIST_COLUMNS";

//================================= GRAPH============================================
export const RECEIVABLE_INITIALIZE_GRAPH_VIEW_TYPE =
  "RECEIVABLE_INITIALIZE_GRAPH_VIEW_TYPE";
export const RECEIVABLE_CHANGE_GRAPH_VIEW_TYPE =
  "RECEIVABLE_CHANGE_GRAPH_VIEW_TYPE";
export const RECEIVABLE_BEGIN_FETCH_GRAPH_DATA =
  "RECEIVABLE_BEGIN_FETCH_GRAPH_DATA";
export const RECEIVABLE_END_FETCH_GRAPH_DATA =
  "RECEIVABLE_END_FETCH_GRAPH_DATA";
export const RECEIVABLE_FETCH_GRAPH_DATA = "RECEIVABLE_FETCH_GRAPH_DATA";
