import service from "@src/api/request";

export function getSalesListData(param) {
  return service({
    url: "/bid/sales/",
    method: "get",
    params: param,
  });
}

export function getSalesGraphData(param) {
  return service({
    url: "/bid/sales-stats/",
    method: "get",
    params: param,
  });
}
