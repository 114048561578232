// ============================= ACTIONS =============================
import { viewTrfDetails } from "@src/actions/Project/TripPlanner";

// ============================ SELECTORS ============================
import { getSelectedAccSelector } from "@src/selectors/Project/TripPlanner";

import _ from "lodash";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import React from "react";
import {
  getAddOnsForDest,
  getAddOnsStatusForDest,
  getCustomServiceForDestSelector,
  getDaysArraySelector,
} from "@src/selectors/Project/TripPlanner/day_by_day_selectors";
import { WhisperWrapper } from "@src/components/common/ui_helpers";

import { createUseStyles } from "react-jss";
import { variables } from "@src/jsssetup";
import RestaurantService from "../Components/Day/restaurant";
import CoordinatorService from "../Components/Day/coordinator";
import GeneralService from "../Components/Day/general_service";

import { IconButton, Icon } from "rsuite";
import { getAdhocDestTrfSelector } from "@src/selectors/Project/TripPlanner/addhoc/transfers";
import { TransferAdhocService } from "../Components/addhoc";
import { DateTime } from "luxon";
import { Addons } from "../Components/custom/addons";
import { Transfer } from "../Components/api_srvs/transfers";
import { Accommodation } from "../Components/api_srvs/accommodation";
import LeanAuthHoc from "@src/components/authorization/LeanAuthHoc";
import { getTripActivitiesByDestOrderSelector } from "@src/selectors/Project/TripPlanner/day_by_day/activities";
import DestinationActivities from "../Components/api_srvs/activities";
import { getDestsAdhocSrvsSelector } from "@src/selectors/Project/TripPlanner/day_by_day/addhoc_services";
import { GenericAdhocSrv } from "../Components/Day/addhoc";

export function sideControlsStyles() {
  return {
    display: "grid",
    alignContent: "center",
    gridGap: variables.normal_gap,
    gridAutoRows: "max-content",
  };
}

const CustomServices = ({ services }) => {
  const days = useSelector((state) => getDaysArraySelector(state));

  return services.map((srv, idx) => {
    switch (srv.service.custom_service_type) {
      case "REST": {
        return (
          <RestaurantService
            key={idx}
            service={srv.service}
            withControls={false}
          />
        );
      }
      case "COO":
        return (
          <CoordinatorService
            key={idx}
            service={srv.service}
            no_price={srv.no_price}
            withControls={false}
            date={days[srv.day - 1]}
          />
        );
      case "GEN":
        return (
          <GeneralService
            key={idx}
            service={srv.service}
            no_price={srv.no_price}
            withControls={false}
            date={days[srv.day - 1]}
          />
        );
      default:
        return null;
    }
  });
};
CustomServices.defaultProps = {
  services: [],
};
CustomServices.propTypes = {
  services: PropTypes.array.isRequired,
};

export const srvContainerStyles = (props) => ({
  display: "grid",
  gridGap: `${variables.normal_gap} calc(${variables.normal_gap} / 2)`,
  gridTemplateColumns:
    props.currentStep !== 4 ? "2rem 1fr 15.5rem" : "2rem 1fr 11rem",
});

export const srvHeaderStyles = (props) => {
  return {
    display: "grid",
    gridTemplateColumns:
      props.currentStep !== 4
        ? "minmax(auto, 2rem) 1fr 6rem"
        : "minmax(auto, 2rem) 1fr 5rem",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    alignItems: "center",
    fontSize: "larger",
    fontWeight: "bold",
    paddingBottom: `calc(${variables.normal_gap} / 2)`,
  };
};

export const srvHeaderTitleStyles = () => ({
  borderBottom: `2px solid ${variables.colors.borders.base}`,
  display: "grid",
  gridTemplateColumns: "1fr auto",
  alignItems: "center",
  padding: `0 calc(${variables.normal_gap} / 2)`,
  fontSize: "medium",
});

export const srvHeaderTitleActionsStyles = () => ({
  display: "grid",
  gridAutoFlow: "column",
  gridGap: `calc(${variables.normal_gap} / 2)`,
  padding: `calc(${variables.normal_gap} / 2)`,
});

const AddAdhocInboundTrfSrv = ({ onClick }) => {
  return (
    <WhisperWrapper msg="Add Inbound Adhoc Transfer">
      <IconButton
        icon={<Icon icon="car" />}
        size="xs"
        circle
        color="green"
        onClick={onClick}
      />
    </WhisperWrapper>
  );
};
AddAdhocInboundTrfSrv.propTypes = { onClick: PropTypes.func.isRequired };

const AddAdhocOutboundTrfSrv = ({ onClick }) => {
  return (
    <WhisperWrapper msg="Add Outbound Adhoc Transfer">
      <IconButton
        icon={<Icon icon="car" />}
        size="xs"
        circle
        color="blue"
        onClick={onClick}
      />
    </WhisperWrapper>
  );
};
AddAdhocOutboundTrfSrv.propTypes = { onClick: PropTypes.func.isRequired };

const styles = createUseStyles({
  OverviewDestination: { display: "grid", gridGap: variables.normal_gap },
  header: (props) => srvHeaderStyles(props),
  headerTitle: {
    borderBottom: `2px solid ${variables.colors.borders.base}`,
    display: "inline-grid",
    gridTemplateColumns: "max-content max-content 1fr auto",
    justifyContent: "space-between",
    alignItems: "center",
    gridGap: `calc(${variables.normal_gap} / 2)`,
  },
  headerActions: {
    display: "grid",
    gridAutoFlow: "column",
    gridGap: `calc(${variables.normal_gap} / 2)`,
  },
  headerStay: {
    justifySelf: "end",
  },
  index: {
    display: "grid",
    width: "2rem",
    height: "2rem",
    borderRadius: "50%",
    fontWeight: "bold",
    background: variables.colors.easy.orange,
    fontSize: "large",
    color: "white",
    placeItems: "center",
  },
  services: { display: "grid", gridGap: variables.normal_gap },
});
const OverviewDestination = ({
  destination,
  accommodation,
  addons,
  customServices,
  addonsStatus,
  destOrder,
  inboundAddhocTrf,
  outboundAddhocTrf,
  currentStep,
  requiredServices,
  onSetAddhocTrfService,
}) => {
  const classes = styles({ currentStep });
  const activities = useSelector((state) =>
    getTripActivitiesByDestOrderSelector(state, { destOrder })
  );

  const trfRequired = requiredServices.includes("TF");
  function trfAddhocAdder(trfType) {
    onSetAddhocTrfService({ trfType, accommodation, destination });
  }

  var destTitle = destination.name_en;
  var check_in = DateTime.fromISO(destination.checkIn);
  var check_out = DateTime.fromISO(destination.checkOut);

  const stay = check_out.diff(check_in, "days").toObject().days;
  check_in = check_in.toLocaleString(DateTime.DATE_MED);
  check_out = check_out.toLocaleString(DateTime.DATE_MED);
  const destStay = `${check_in} to ${check_out} ${
    stay > 0 ? (stay === 1 ? "(1 Night)" : `(${stay} Nights)`) : ""
  }`;

  const destMapping = { NOR: "Normal Destination", LAY: "Layover Point" };
  const destType = `${destMapping[destination.dest_type]}`;

  const destsAdhocSrvs = useSelector((state) =>
    getDestsAdhocSrvsSelector(state)
  );
  const destAdhocSrvs = destsAdhocSrvs?.[destOrder] ?? [];

  return requiredServices.join("") === "TR" ? null : (
    <div className="Destination">
      <div className={classes.header}>
        <span className={classes.index}>{destination.order}</span>
        <span className={classes.headerTitle}>
          {destTitle} <small>({destType})</small>{" "}
          <small className={classes.headerStay}>{destStay}</small>
          <span className={classes.headerActions}>
            {trfRequired && currentStep < 4 && (
              <React.Fragment>
                <AddAdhocInboundTrfSrv
                  onClick={() => trfAddhocAdder("inbound")}
                />
                <AddAdhocOutboundTrfSrv
                  onClick={() => trfAddhocAdder("outbound")}
                />
              </React.Fragment>
            )}
          </span>
        </span>
      </div>
      <div className={classes.services}>
        {inboundAddhocTrf ? (
          <TransferAdhocService srv={inboundAddhocTrf} />
        ) : (
          requiredServices.includes("TF") && (
            <Transfer
              trfType="inbound"
              destOrder={destOrder}
              currentStep={currentStep}
            />
          )
        )}
        {(!destination.dest_type || destination.dest_type === "NOR") && (
          <Accommodation
            destination={destination}
            currentStep={currentStep}
            destOrder={destOrder}
            requiredServices={requiredServices}
          />
        )}
        <Addons
          currentStep={currentStep}
          addons={addons}
          addonsStatus={addonsStatus}
        />
        {destAdhocSrvs.map((srv, idx) => (
          <GenericAdhocSrv key={idx} service={srv} isLastDay={false} />
        ))}
        <DestinationActivities activities={activities} />
        <CustomServices services={customServices} />
        {outboundAddhocTrf ? (
          <TransferAdhocService srv={outboundAddhocTrf} />
        ) : (
          requiredServices.includes("TF") && (
            <Transfer
              trfType="outbound"
              destOrder={destOrder}
              currentStep={currentStep}
            />
          )
        )}
      </div>
    </div>
  );
};
OverviewDestination.defaultProps = {
  accommodation: {},
  customServices: [],
};
OverviewDestination.propTypes = {
  destOrder: PropTypes.number.isRequired,
  accommodation: PropTypes.object,
  inboundAddhocTrf: PropTypes.object,
  outboundAddhocTrf: PropTypes.object,
  currentStep: PropTypes.number.isRequired,
  requiredServices: PropTypes.array.isRequired,
  destination: PropTypes.object.isRequired,
  addons: PropTypes.array.isRequired,
  addonsStatus: PropTypes.array.isRequired,
  customServices: PropTypes.array.isRequired,
  onTrfDetails: PropTypes.func.isRequired,
  onSetAddhocTrfService: PropTypes.func.isRequired,
};
const mapStateToProps = (state, ownProps) => {
  const { destination } = ownProps;

  const { order: destOrder } = destination;

  const inboundAddhocTrf = getAdhocDestTrfSelector(state, {
    trfType: "inbound",
    destOrder: destination.order,
  });
  const outboundAddhocTrf = getAdhocDestTrfSelector(state, {
    trfType: "outbound",
    destOrder: destination.order,
  });

  const accommodation = getSelectedAccSelector(state, { destOrder });

  const addons = getAddOnsForDest(state, { destOrder });
  const addonsStatus = getAddOnsStatusForDest(state, { destOrder });

  const customServices = getCustomServiceForDestSelector(state, { destOrder });
  var correctedCustomServices = customServices;
  if (destOrder > 1) {
    const prevCusServices = getCustomServiceForDestSelector(state, {
      destOrder: destOrder - 1,
    });
    const prevCoords = prevCusServices.filter(
      (srv) => srv.service.srvType === "CU"
    );

    // Avoid showing duplicate prices for Coordinator services
    correctedCustomServices = _.cloneDeep(customServices).map((srv) => {
      // If this is not a coordinator return the service and move on.
      if (srv?.service?.custom_service_type !== "COO") return srv;

      const pCoord = prevCoords.find(
        (psrv) => psrv.day === srv.day && psrv.service?.uid === srv.service?.uid
      );
      if (pCoord) srv.no_price = true;
      return srv;
    });
  }

  return {
    destOrder,
    accommodation,
    addons,
    addonsStatus,
    customServices: correctedCustomServices,
    inboundAddhocTrf,
    outboundAddhocTrf,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onTrfDetails: (destOrder, trfType, booking_id) => {
      dispatch(viewTrfDetails(destOrder, trfType, booking_id));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OverviewDestination);
