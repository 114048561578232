import {
  BIDREQUEST_SHOW_REPLACEABLE_ACC_MODAL,
  BIDREQUEST_HIDE_REPLACEABLE_ACC_MODAL,
  BIDREQUEST_SAVE_REPLACEABLE_ACC,
  BIDREQUEST_REVERT_REPLACEABLE_ACC,
  // ================ COMMON REPLACEABLE SERVICE ACTIONS ===============
  REPLACEABLE_SERVICE_SAVING,
  REPLACEABLE_SERVICE_SAVED,
} from "./types";

import { offerSave } from "./index";

import MessageFormatter from "@src/validators/MessageFormatter";
import { validateData as validateCustomHotel } from "@src/validators/Project/CustomHotel";

import {
  initCustomHotel,
  initCustomHotelFromData,
} from "@src/actions/Project/CustomHotel";

import {
  getCustomSrvCommonPayload,
  getCustomSrvFormData,
} from "@src/tools/CustomServices";
import { saveReplaceableSrv } from "./common_replaceable_srv_actions";

import _ from "lodash";
import update from "immutability-helper";
import { Notification } from "rsuite";
import React from "react";

// ==================== REPLACEABLE ACCOMMODATIONS ===================
export const showReplaceableAccModal = (destOrder) => async (
  dispatch,
  getState
) => {
  const hotel = _.get(
    getState().bidRequestOfferData,
    `replaceable_accommodations.${destOrder}`,
    null
  );

  if (hotel) {
    await dispatch(initCustomHotelFromData(hotel));
  } else {
    await dispatch(initCustomHotel());
  }

  dispatch({ type: BIDREQUEST_SHOW_REPLACEABLE_ACC_MODAL, destOrder });
};

export const hideReplaceableAccModal = () => {
  return { type: BIDREQUEST_HIDE_REPLACEABLE_ACC_MODAL };
};

export const saveReplaceableAcc = (destOrder) => async (dispatch, getState) => {
  const state = getState();
  const offer = state.bidRequestOfferData;
  const accData = state.customHotel;

  const msgs = validateCustomHotel(accData);
  if (msgs.length) {
    const desc = <MessageFormatter msgs={msgs} />;

    try {
      Notification.close("replaceable_accommodation_notification");
    } finally {
      Notification.error({
        key: "replaceable_accommodation_notification",
        title: "Accommodation Validation Error",
        description: desc,
        duration: 10000,
      });
    }
    return;
  }

  dispatch({ type: REPLACEABLE_SERVICE_SAVING });

  var customSrvPayload = getCustomSrvCommonPayload(offer.id, "ACC");

  customSrvPayload = {
    ...customSrvPayload,
    ...{
      service_data: JSON.stringify(
        accData.metadata.is_custom // TODO: This should be modified for the edit case
          ? update(_.cloneDeep(accData), {
              metadata: {
                images: {
                  $apply: (imgs) =>
                    imgs.filter((i) => typeof i.file == "undefined"),
                },
              },
            })
          : accData
      ),
      images: accData.metadata.images
        .filter((i) => typeof i.file !== "undefined")
        .map((i) => ({
          original_image: i.file,
          main_photo: i.main,
        })),
    },
  };

  console.log(customSrvPayload);

  const formDataPayload = getCustomSrvFormData(customSrvPayload);

  const service_data = await saveReplaceableSrv(
    formDataPayload,
    _.get(accData, "custom_id", null)
  );

  if (service_data.data !== null) {
    service_data.data.custom_id = service_data.service_id;
    // Update the offer data.
    await dispatch({
      type: BIDREQUEST_SAVE_REPLACEABLE_ACC,
      destOrder,
      data: service_data.data,
    });
    await dispatch(offerSave()); // Save the updated offer to the backend.
    await dispatch(hideReplaceableAccModal()); // Close the modal.
    // Stop the loading status of the replaceable service modal.
    dispatch({ type: REPLACEABLE_SERVICE_SAVED });
  }
};

export const revertReplaceableAcc = (destOrder) => {
  return { type: BIDREQUEST_REVERT_REPLACEABLE_ACC, destOrder };
};
