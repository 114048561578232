import {
  CUSTOM_TRF_INIT,
  CUSTOM_TRF_INIT_FROM_DATA,
  CUSTOM_TRF_CHANGE,
} from "./types";

export const initCustomTransfer = () => {
  return { type: CUSTOM_TRF_INIT };
};

export const initCustomTrfFromData = (data) => {
  console.log("initCustomTrfFromData", data);
  return { type: CUSTOM_TRF_INIT_FROM_DATA, data };
};

export const customTransferChange = (legType, name, value) => {
  return { type: CUSTOM_TRF_CHANGE, legType, name, value };
};
