import {
  CUSTOM_SERVICE_ADDON_MAP_EDIT_ON,
  CUSTOM_SERVICE_ADDON_MAP_EDIT_OFF,
  CUSTOM_SERVICE_ADDON_ITEM_LOCK_DEPARTURE_META,
  CUSTOM_SERVICE_ADDON_ITEM_UNLOCK_DEPARTURE_META,
} from "./types";

export const enterMapEditMode = (itemOrders) => {
  return { type: CUSTOM_SERVICE_ADDON_MAP_EDIT_ON, itemOrders };
};

export const exitMapEditMode = (itemOrder) => {
  return { type: CUSTOM_SERVICE_ADDON_MAP_EDIT_OFF };
};

export const lockDepartureMeta = (itemOrder) => {
  return { type: CUSTOM_SERVICE_ADDON_ITEM_LOCK_DEPARTURE_META, itemOrder };
};

export const unlockDepartureMeta = (itemOrder) => {
  return { type: CUSTOM_SERVICE_ADDON_ITEM_UNLOCK_DEPARTURE_META, itemOrder };
};
