import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { InlineIcon } from "@iconify/react";
import { DateTime } from "luxon";

export const Routes = ({ data, returnRoute, setReturnRoute }) => {
  const hasReturnRoute = data.length > 1 ? true : false;

  const trainsGoToList = _.get(data, "0.solutions", []);
  const trainsReturnList = _.get(data, "1.solutions", []);

  const firstSequenceOrigin = _.get(trainsGoToList, "0.origin.label", "");
  const firstSequenceDestination = _.get(
    trainsGoToList,
    "0.destination.label",
    ""
  );

  const firstSequenceDeparture = _.get(trainsGoToList, "0.departure", "");

  const secondSequenceOrigin = _.get(trainsReturnList, "0.origin.label", "");
  const secondSequenceDestination = _.get(
    trainsReturnList,
    "0.destination.label",
    ""
  );
  const secondSequenceDeparture = _.get(trainsReturnList, "0.departure", "");

  return (
    <div className="Routes">
      <div className="Routes__routesContr" data-has-return-route={hasReturnRoute}>
        <div
          className={`Routes__routesContr__outBoundRoute
          ${
            !returnRoute
              ? " Routes__routesContr__outBoundRoute--activeRoute"
              : ""
          }`}
          onClick={() => setReturnRoute(false)}>
          <div className="Routes__routesContr__outBoundRoute__icons">
            <InlineIcon
              icon="ic:baseline-train"
              className="Routes__routesContr__outBoundRoute__icons__icon"
            />
            <InlineIcon
              icon="material-symbols:arrow-right"
              className="Routes__routesContr__outBoundRoute__icons__icon"
            />
          </div>
          <div className="Routes__routesContr__outBoundRoute__values">
            <strong className="Routes__routesContr__outBoundRoute__values__header">
              {firstSequenceOrigin} to {firstSequenceDestination}
            </strong>
            <div className="Routes__routesContr__outBoundRoute__values__subHeader">
              {DateTime.fromISO(firstSequenceDeparture).toLocaleString(
                DateTime.DATE_MED_WITH_WEEKDAY
              )}
            </div>
          </div>
        </div>
        {hasReturnRoute && (
          <div
            className={`Routes__routesContr__inBoundRoute
            ${
              returnRoute
                ? " Routes__routesContr__inBoundRoute--activeRoute"
                : ""
            }`}
            onClick={() => setReturnRoute(true)}>
            <div className="Routes__routesContr__inBoundRoute__icons">
              <InlineIcon
                icon="material-symbols:arrow-left"
                className="Routes__routesContr__inBoundRoute__icons__icon"
              />
              <InlineIcon
                icon="ic:baseline-train"
                className="Routes__routesContr__inBoundRoute__icons__icon"
              />
            </div>
            <div className="Routes__routesContr__inBoundRoute__values">
              <strong className="Routes__routesContr__inBoundRoute__values__header">
                {secondSequenceOrigin} to {secondSequenceDestination}
              </strong>
              <div className="Routes__routesContr__inBoundRoute__values__subHeader">
                {" "}
                {DateTime.fromISO(secondSequenceDeparture).toLocaleString(
                  DateTime.DATE_MED_WITH_WEEKDAY
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
Routes.defaultProps = {
  data: [],
  returnRoute: false,
};
Routes.propTypes = {
  data: PropTypes.array.isRequired,
  returnRoute: PropTypes.bool.isRequired,
  setReturnRoute: PropTypes.func.isRequired,
};
