import { Whisper, Icon, Tooltip } from "rsuite";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { variables } from "@src/jsssetup";

export class WhisperHelper extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { trigger, placement, msg, informational, color } = this.props;

    return (
      <Whisper
        trigger={trigger}
        placement={placement}
        speaker={<Tooltip>{msg}</Tooltip>}>
        <Icon
          style={{ color: color ? color : "unset" }}
          icon={informational ? "info" : "help-o"}
        />
      </Whisper>
    );
  }
}
WhisperHelper.defaultProps = {
  informational: false,
  placement: "top",
  trigger: "hover",
  color: "unset",
};
WhisperHelper.propTypes = {
  informational: PropTypes.bool.isRequired,
  msg: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  placement: PropTypes.string,
  trigger: PropTypes.string,
  color: PropTypes.string,
};
const styles = createUseStyles({
  Tooltip: (props) => ({
    "& > .rs-tooltip-inner": {
      background: "rgba(209, 209, 209, 0.6)",
      fontWeight: "bold",
      color: variables.colors.text.colored,
      backdropFilter: "blur(5px)",
    },
    "& > .rs-tooltip-arrow": {
      borderRightColor:
        props.placement === "right" && "rgba(209, 209, 209, 0.6) !important",
      borderTopColor:
        props.placement === "top" && "rgba(209, 209, 209, 0.6) !important",
      borderBottomColor:
        props.placement === "bottom" && "rgba(209, 209, 209, 0.6) !important",
      borderLeftColor:
        props.placement === "left" && "rgba(209, 209, 209, 0.6) !important",
    },
  }),
});
export const WhisperWrapper = ({
  trigger,
  placement,
  msg,
  children,
  open = null,
  delay = 0,
}) => {
  const [defaultProps, setDefaultProps] = useState({});
  const classes = styles({ placement });

  useEffect(() => {
    if (!msg) return null;

    let timeoutId = null;
    if (open !== null && typeof open !== "undefined") {
      if (!open) {
        delay = 0;
      }
      timeoutId = setTimeout(() => {
        setDefaultProps((prevProps) => ({ ...prevProps, open }));
      }, delay);
    }

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [msg, open, delay]);

  return msg ? (
    <Whisper
      {...defaultProps}
      trigger={trigger}
      placement={placement}
      speaker={
        msg ? <Tooltip className={classes.Tooltip}>{msg}</Tooltip> : <div />
      }>
      {children}
    </Whisper>
  ) : (
    children
  );
};
WhisperWrapper.defaultProps = { placement: "top", trigger: "hover" };
WhisperWrapper.propTypes = {
  children: PropTypes.object.isRequired,
  msg: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  placement: PropTypes.string,
  trigger: PropTypes.string,
  open: PropTypes.bool,
  delay: PropTypes.number,
};

export class Disabler extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { active, msg } = this.props;
    return (
      <div className={`Disabler ${active ? "Disabler--active" : ""}`}>
        <h4>{msg ? msg : "Disabled"}</h4>
      </div>
    );
  }
}
Disabler.propTypes = {
  msg: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
};
