import { createSelector } from "reselect";
import Fuse from "fuse.js";
import _ from "lodash";
import {
  getSrvsUidForBooking,
  getSrvsUidForOptionBooking,
} from "./prebook/selected_services";

const getAddOns = (state) => state.tripPlannerAddOnDrawer.results;
const getAddOnFilters = (state) => state.tripPlannerAddOnDrawer.filters;
export function getSelectedAddonData(state, { booking_mode } = {}) {
  return state.tripPlannerAddOnData;
}
export const getSelectedAddonsSelector = createSelector(
  [getSelectedAddonData],
  (addons) => addons
);
export const getAddonSearchStatus = (state) =>
  state.tripPlannerAddOnSearchStatus;

export const generateAddOnSrvCode = (addon, day) =>
  `${addon.id}___${addon.destOrder}___${day}`;

export const addOnsMinMaxSelector = createSelector([getAddOns], (addons) => {
  const prices = _.flatten(
    addons.map((addon) => addon.price.map((pr) => pr.value))
  );

  return [addons.length > 1 ? Math.min(...prices) : 0, Math.max(...prices)];
});

export const addOnsFilter = createSelector(
  [getAddOns, getAddOnFilters],
  (addOns, filters) => {
    const fuseCfg = {
      shouldSort: false,
      threshold: 0.5,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ["title"],
    };
    const fuseData = new Fuse(addOns, fuseCfg);
    if (filters.title) {
      addOns = fuseData.search(filters.title);
    }

    return addOns.filter((add) => {
      var itemsOk = true;
      var categoriesOk = true;

      if (filters.items.length) {
        itemsOk = add.items.some((item) =>
          filters.items.includes(item.data.id)
        );
      }

      if (filters.categories.length) {
        categoriesOk =
          _.intersection(add.categories, filters.categories).length > 0;
      }

      const price = Math.min(...add.price.map((pr) => pr.value));

      const minMaxPriceOk =
        price >= filters.min_max_price[0] && price <= filters.min_max_price[1];

      return itemsOk && categoriesOk && minMaxPriceOk;
    });
  }
);

const propsInjector = (state, props) => props;

export const selectedAddOnIdsSelector = createSelector(
  [getSelectedAddonData, propsInjector],
  (addons, props) => {
    const { day } = props;

    return _.get(addons, day, [])
      .filter((addon) => addon)
      .map((addon) => addon.id);
  }
);

function isAddOnRefundable({ addon }) {
  return addon.cancellation.some((cxl) => cxl.type == "refundable");
}

export const isAddonRefundableSelector = createSelector(
  [propsInjector],
  (props) => {
    const { addOn } = props;
    return isAddOnRefundable({ addon: addOn });
  }
);

export const getAddOnFromUid = createSelector(
  [getSelectedAddonData, propsInjector],
  (addons, props) => {
    const { search_uid } = props;

    const addOn = _.flatten(Object.values(addons)).find(
      (addon) => addon.search_uid == search_uid
    );
    return addOn;
  }
);
