const {
  infoLvl1,
  infoLvl2,
  infoLvl3,
  infoLvl4,
  infoLvl5,
} = require("@src/api");

export function getDestinationTypeInfoFn({ destLvl }) {
  var fn;
  switch (destLvl) {
    case "lvl1":
      fn = infoLvl1;
      break;
    case "lvl2":
      fn = infoLvl2;
      break;
    case "lvl3":
      fn = infoLvl3;
      break;
    case "lvl4":
      fn = infoLvl4;
      break;
    case "lvl5":
      fn = infoLvl5;
      break;
    default:
      fn = infoLvl2;
      break;
  }
  return fn;
}

export const destinationDataExtractor = ({ data, type, order = 0, query }) => {
  const getcountrycode = (data) => {
    return Object.keys(data).includes("parent")
      ? getcountrycode(data.parent)
      : data.code;
  };

  const { id, name_en, name_cn, coords: geodata } = data;

  return {
    name_en,
    name_cn,
    images: data.images
      .sort((a, b) => a.priority - b.priority)
      .filter((img, idx) => idx < 5),
    geodata,
    id,
    type,
    order,
    country_code: getcountrycode(data),
    query,
  };
};
