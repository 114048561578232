import PropTypes from "prop-types";
import React from "react";
import PriceDetails from "./PriceDetails";
import { createUseStyles } from "react-jss";
import { variables } from "@src/jsssetup";

const styles = createUseStyles({
  PrebookPrice: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    justifyContent: "center",
    alignContent: "center",
  },
});
const PrebookPrice = ({ price, currency }) => {
  const classes = styles();
  return (
    <div className={classes.PrebookPrice}>
      <PriceDetails price={price} currency={currency} searching={false} />
    </div>
  );
};
PrebookPrice.defaultProps = { price: 0, currency: "EUR" };
PrebookPrice.propTypes = {
  price: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
};
export default PrebookPrice;
