import _ from "lodash";
import {
  CustomButton,
  CustomCircIconBtn,
} from "@src/components/common/buttons";
import { NormalInputField, NormalSelectField } from "@src/components/forms";
import { cardStyles, tableStyles } from "@src/jsssetup";
import { Form, Formik } from "formik";
import React, { useCallback, useState } from "react";
import { createUseStyles } from "react-jss";
import { useDispatch, useSelector } from "react-redux";
import {
  addonPatch,
  promptlessSaveAddonService,
} from "@src/actions/Operation/CustomServices/AddOn";
import { Loader } from "rsuite";
import * as yup from "yup";
import { toast } from "react-toastify";

const validationSchema = yup.object({
  purchase_mode: yup.string().required("Required").oneOf(["PP", "DE", "PA"]),
  deposit_size: yup
    .number()
    .required("Required")
    .test(
      "is-smaller-than-100",
      "When in deposit type is percentage this must be less than 100",
      function (value) {
        if (this.parent.deposit_type === "P" && value > 100) {
          return false;
        }

        return true;
      }
    ),
  deposit_type: yup.string().required("Required").oneOf(["P", "A"]),
  balance_deadline_days: yup.number().required("Required"),
});

const purchase_mode_choices = [
  ["PP", "Prepaid"],
  ["DE", "Deposit"],
  ["PA", "Pay at Destination"],
];
const purchaseModeMapping = {};
purchase_mode_choices.forEach((e) =>
  Object.assign(purchaseModeMapping, { [e[0]]: e[1] })
);

const deposit_type_choices = [
  ["P", "Percentage"],
  ["A", "Absolute"],
];
const depositTypeMapping = {};
deposit_type_choices.forEach((e) =>
  Object.assign(depositTypeMapping, { [e[0]]: e[1] })
);

const paymentRulesStyles = createUseStyles({
  ...cardStyles,
  PaymentRules: cardStyles.card,
  table: tableStyles.table,
  thead: tableStyles.head,
  cell: tableStyles.cell,
});
export const PaymentRules = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const classes = paymentRulesStyles();

  const { purchase_mode, deposit_size, deposit_type, balance_deadline_days } =
    useSelector((state) => state.customServiceAddOn);

  const dispatch = useDispatch();
  const onSubmit = useCallback(
    async (values) => {
      setIsLoading(true);
      await dispatch(addonPatch(values));
      const result = await dispatch(promptlessSaveAddonService());

      if (result) {
        toast.success("Payment Rules Updated", { autoClose: 3000 });
      }

      setEditMode(false);
      setIsLoading(false);
    },
    [dispatch]
  );

  return (
    <div className={classes.PaymentRules}>
      {isLoading && <Loader center size="lg" backdrop />}
      <div className={classes.header}>
        <h5>Payment Rules</h5>
      </div>
      <div className={classes.body}>
        {editMode ? (
          <Formik
            validationSchema={validationSchema}
            initialValues={{
              purchase_mode,
              deposit_size,
              deposit_type,
              balance_deadline_days,
            }}
            onSubmit={(values) => onSubmit(values)}>
            {({ submitForm }) => (
              <Form>
                <table className={classes.table}>
                  <thead className={classes.thead}>
                    <tr>
                      <th>Payment Mode</th>
                      <th>Deposit Size</th>
                      <th>Deposit Type</th>
                      <th>Balance Deadline (days)</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className={classes.cell}>
                        <NormalSelectField
                          name="purchase_mode"
                          options={[
                            ["PP", "Prepaid"],
                            ["DE", "Deposit"],
                            ["PA", "Pay at Destination"],
                          ]}
                          highlightErrors={true}
                        />
                      </td>
                      <td className={classes.cell}>
                        <NormalInputField
                          name="deposit_size"
                          type="number"
                          extraInputProps={{ min: 0 }}
                          highlightErrors={true}
                        />
                      </td>
                      <td className={classes.cell}>
                        <NormalSelectField
                          name="deposit_type"
                          options={[
                            ["P", "Percentage"],
                            ["A", "Absolute"],
                          ]}
                          highlightErrors={true}
                        />
                      </td>
                      <td className={classes.cell}>
                        <NormalInputField
                          name="balance_deadline_days"
                          type="number"
                          extraInputProps={{ min: 0 }}
                          highlightErrors={true}
                        />
                      </td>
                      <td className={classes.cell}>
                        <CustomButton onClick={submitForm}>Save</CustomButton>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Form>
            )}
          </Formik>
        ) : (
          <table className={classes.table}>
            <thead className={classes.thead}>
              <tr>
                <th>Payment Mode</th>
                <th>Deposit Size</th>
                <th>Deposit Type</th>
                <th>Balance Deadline</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={classes.cell}>
                  {_.get(purchaseModeMapping, purchase_mode)}
                </td>
                <td className={classes.cell}>{deposit_size}</td>
                <td className={classes.cell}>
                  {_.get(depositTypeMapping, deposit_type)}
                </td>
                <td className={classes.cell}>{balance_deadline_days} Days</td>
                <td className={classes.cell}>
                  <CustomCircIconBtn
                    icon="ic:baseline-edit"
                    onClick={() => setEditMode(true)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};
