import {
  infoLvl1,
  infoLvl2,
  infoLvl3,
  infoLvl4,
} from "@src/api/Project/TripPlanner";
import { createSelector } from "reselect";

const getDestsWithStay = (state) => {
  return state.tripPlannerDestinations.filter(
    (dest) => !["LAY", "NON"].includes(dest.dest_type)
  );
};

export const getDestsWithStaySelector = createSelector(
  [getDestsWithStay],
  (dests) => {
    return dests;
  }
);

export const getCountryCode = async (destId, destType) => {
  const fnMapping = {
    lvl1: infoLvl1,
    lvl2: infoLvl2,
    lvl3: infoLvl3,
    lvl4: infoLvl4,
  };

  const fn = fnMapping[destType];

  try {
    const res = await fn(destId);

    const getCountry = (data) =>
      !data.parent.code ? getCountry(data.parent) : data.parent.code;

    return getCountry(res);
  } catch (error) {
    console.log(error);
    return null;
  }
};
