import service from "./request";

export var BUILD = process.env.REACT_APP_BUILD;
export var CANAVERAL_API_BASE_URL = process.env.REACT_APP_CANAVERAL_ENDPOINT;
export var SISI_API_URL = process.env.REACT_APP_SISI_GATEWAY;
export var SISI2_API_URL = process.env.REACT_APP_SISI2_GATEWAY;
export var VALAQUENTA_API_URL = process.env.REACT_APP_VALAQUENTA_API_URL;
export var VERSION = process.env.REACT_APP_VERSION;

export const getUserFeatureFlags = () => {
  return service({ url: "/bid/feature-flags/", method: "get" });
};

export function getTripPlanning() {
  return service({
    url: "/getTripPlanning",
    method: "get",
  });
}

export function getRateList() {
  return service({
    url: "/getRateList",
    method: "get",
  });
}

export function createBid() {
  return service({
    url: "/createBid",
    method: "get",
  });
}
export function getCountry() {
  return service({
    url: "/getCountry",
    method: "get",
  });
}

export function getCityByCountry() {
  return service({
    url: "/getCityByCountry",
    method: "get",
  });
}

export function queryCompanyByKeyword() {
  return service({
    url: "/queryCompanyByKeyword",
    method: "get",
  });
}

export function getBidRequestsByBidCode() {
  return service({
    url: "/getBidRequestsByBidCode",
    method: "get",
  });
}

export function getBidders() {
  return service({
    url: "/getBidders",
    method: "get",
  });
}

export function getResponseBiddersByServices() {
  return service({
    url: "/getResponseBiddersByServices",
    method: "get",
  });
}

export function getPOIByAddress({ address = "" }) {
  return service({
    url: `https://api.mapbox.com/geocoding/v5/mapbox.places/${address}.json?access_token=pk.eyJ1IjoibWF0dGZpY2tlIiwiYSI6ImNqNnM2YmFoNzAwcTMzM214NTB1NHdwbnoifQ.Or19S7KmYPHW8YjRz82v6g&cachebuster=1563848978331&autocomplete=true`,
    method: "get",
  });
}

export * from "./Operation";
export * from "./MyLibrary";
export * from "./Admin";
export * from "./Shares";
export * from "./Network";
export * from "./Project/ArchiveList";
export * from "./ManagementConsole";
export * from "./Project";
export * from "./Financial";
export * from "./Hotels";
export * from "./Restaurants";
