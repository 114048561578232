import service from "@src/api/request";

export function getReceivableListData(param) {
  return service({
    url: "bid/receivable/",
    method: "get",
    params: param,
  });
}

export function getReceivableGraphData(params) {
  return service({
    url: "bid/receivable-stats/",
    method: "get",
    params: params,
  });
}
