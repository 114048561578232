import _ from "lodash";
import { connect } from "react-redux";
import { IconButton, Icon } from "rsuite";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { showPkgContentModal } from "@src/actions/Project/TripPlanner";
import { TripPlanContext } from "../TripPlanner";

const MktContentSection = ({ content, onAddContent }) => {
  const { setShowContentModal } = useContext(TripPlanContext);

  return (
    <div className="MktSectionsPanel__content">
      {_.isEmpty(content) ? (
        <IconButton
          icon={<Icon icon="plus" />}
          color="green"
          onClick={() => setShowContentModal(true)}>
          Add Content
        </IconButton>
      ) : (
        <IconButton
          icon={<Icon icon="edit" />}
          color="blue"
          onClick={() => setShowContentModal(true)}>
          Edit Content
        </IconButton>
      )}
    </div>
  );
};

MktContentSection.propTypes = {
  content: PropTypes.object.isRequired,
  onAddContent: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const content = state.tripPlannerMktPkgContent;

  return {
    content,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddContent: () => dispatch(showPkgContentModal()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MktContentSection);
