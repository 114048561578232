import _ from "lodash";
import { Button } from "rsuite";
import { modalGenericStyles, variables } from "@src/jsssetup";
import PropTypes from "prop-types";
import React from "react";
import { createUseStyles } from "react-jss";
import { Form, Formik } from "formik";
import {
  NormalDatePicker,
  NormalInputField,
  NormalSelectField,
} from "@src/components/forms";

import { connect } from "react-redux";
import { getSetupFormDataSelector } from "@src/selectors/Project/TripPlanner";
import {
  adhocPointStyles,
  AdhocSrvPriceFields,
  adhocTrpTrfCommonFields,
} from "./products/addhoc/coach";
import { DateTime } from "luxon";
import { addAdhocTrfService } from "@src/actions/Project/TripPlanner/addhoc/transfers";
import { v4 } from "uuid";
import { addonSrvPriceCalculator } from "./products/addhoc/common";

export const vehicleTypesMapping = {
  private_car: "Private Car",
  limo: "Limousine",
  minivan: "Minivan",
  coach: "Coach",
  public_bus: "Public Bus",
  metro: "Metro",
};

const styles = createUseStyles({
  AddAdhocTrfSrvModal: modalGenericStyles.modal,
  card: modalGenericStyles.card,
  header: modalGenericStyles.cardHeader,
  body: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    gridGap: variables.normal_gap,
    gridAutoRows: "max-content",
  },
  requiredDays: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
  },
  day: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    gridTemplateColumns: "auto 1fr",
  },
  actions: modalGenericStyles.cardActions,
  col2: {
    display: "grid",
    gridTemplateColumns: "repeat(2 ,1fr)",
    gridGap: variables.normal_gap,
  },
  point: adhocPointStyles,
});
const AddAdhocTrfSrvModal = (props) => {
  const {
    destination,
    adults,
    childs,
    trfType,
    hotel,
    onCancel,
    onAdd,
  } = props;
  const classes = styles();

  const htlName = _.get(hotel, "metadata.name", "Hotel");

  const htlPoint = hotel
    ? _.pick({ ..._.pick(hotel, ["metadata"], {}).metadata }, [
        "name",
        "geodata",
        "address",
      ])
    : { name: "" };
  htlPoint["type"] = "hotel";
  const infraPoint = { name: "", type: "airport" };
  const origin_point = trfType === "inbound" ? infraPoint : htlPoint;
  const destination_point = trfType === "inbound" ? htlPoint : infraPoint;

  var referenceDeparture = "";
  var referenceArrival = "";
  if (hotel) {
    const checkIn = DateTime.fromISO(
      _.get(hotel, "operational_data.check_in")
    ).set({ hour: 14 });
    const checkOut = DateTime.fromISO(
      _.get(hotel, "operational_data.check_out")
    ).set({ hour: 12 });

    referenceDeparture =
      trfType === "inbound"
        ? checkIn.minus({ hours: 1 }).toISO()
        : checkOut.toISO();
    referenceArrival =
      trfType === "inbound"
        ? checkIn.toISO()
        : checkOut.plus({ hours: 1 }).toISO();
  }
  return (
    <div className={classes.AddAdhocTrfSrvModal}>
      <div className={classes.card}>
        <div className={classes.header}>
          <h5>
            Add{" "}
            {trfType === "inbound"
              ? `Transportation Infrastructure to ${htlName}`
              : `${htlName} to Transportation Infrastructure`}{" "}
            Service
          </h5>
        </div>
        <Formik
          initialValues={{
            origin_point,
            destination_point,
            ...adhocTrpTrfCommonFields({
              referenceDeparture,
              referenceArrival,
            }),
            vehicle_type: "",
            vehicles: 1,
            company_name: "",
            distance: 0,
            baggages_per_vehicle: 1,
            seats: 1,
          }}
          validate={(values) =>
            addonSrvPriceCalculator({ values, adults, childs })
          }
          onSubmit={(values) => {
            onAdd({
              srv: {
                ...values,
                trfType,
                destOrder: destination.order,
                uid: v4(),
              },
            });
            onCancel();
          }}>
          <Form>
            <React.Fragment>
              <div className={classes.body}>
                <div className={classes.col2}>
                  {trfType === "inbound" ? (
                    <NormalInputField
                      name="origin_point.name"
                      label="Origin Point"
                    />
                  ) : (
                    <span className={classes.point}>
                      <strong>Origin: {htlName}</strong>
                    </span>
                  )}
                  {trfType === "outbound" ? (
                    <NormalInputField
                      name="destination_point.name"
                      label="Destination Point"
                    />
                  ) : (
                    <span className={classes.point}>
                      <strong>Destination: {htlName}</strong>
                    </span>
                  )}
                  <NormalDatePicker
                    name="departure"
                    label="Departure"
                    withTime={true}
                  />
                  <NormalDatePicker
                    name="arrival"
                    label="Arrival"
                    withTime={true}
                  />
                  <NormalInputField
                    name="vehicles"
                    label="Vehicles"
                    type="number"
                  />
                  <NormalSelectField
                    name="vehicle_type"
                    label="Vehicle Type"
                    options={[
                      [null, "------"],
                      ...Object.entries(vehicleTypesMapping),
                    ]}
                  />
                  <NormalInputField
                    name="baggages_per_vehicle"
                    label="Baggage Allowance (per vehicle)"
                    type="number"
                  />
                  <NormalInputField name="seats" label="Seats" type="number" />
                  <NormalInputField name="company_name" label="Company" />
                  <NormalInputField
                    name="distance"
                    label="Distance"
                    type="number"
                  />
                  <AdhocSrvPriceFields />
                </div>
              </div>
              <div className={classes.actions}>
                <Button appearance="ghost" onClick={onCancel}>
                  <strong>Cancel</strong>
                </Button>
                <Button appearance="primary" type="submit">
                  <strong>Next</strong>
                </Button>
              </div>
            </React.Fragment>
          </Form>
        </Formik>
      </div>
    </div>
  );
};
AddAdhocTrfSrvModal.defaultProps = {
  adults: 1,
  childs: 0,
  commonDestDays: [],
};
AddAdhocTrfSrvModal.propTypes = {
  adults: PropTypes.number.isRequired,
  childs: PropTypes.number.isRequired,
  trfType: PropTypes.oneOf(["inbound", "outbound"]).isRequired,
  hotel: PropTypes.object.isRequired,
  destination: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  const { adults, children: childs } = getSetupFormDataSelector(state);
  return { adults, childs };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onAdd: ({ srv }) => dispatch(addAdhocTrfService({ srv })),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddAdhocTrfSrvModal);
