import { getAccCustomServices } from "@src/selectors/Project/TripPlanner/accommodation";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { CustomAccData } from "../../custom/accommodation";

const CustomAccReservations = ({ reservations }) => {
  const regular_coords = useSelector((state) =>
    getAccCustomServices(state, { booking_mode: "regular" })
  );
  const option_coords = useSelector((state) =>
    getAccCustomServices(state, { booking_mode: "option" })
  );

  const for_reservation_srvs = [...regular_coords, ...option_coords];

  return !reservations.length ? null : (
    <div>
      <h5>Custom Accommodations</h5>
      {reservations.map((res, idx) => {
        const acc = for_reservation_srvs.find(
          (acc) => acc.uid === res.search_uid
        );

        return (
          <CustomAccData
            key={idx}
            service={acc}
            reservationMode={true}
            reservationLoading={res.status === "PE"}
            reservationStatus={res.status}
          />
        );
      })}
    </div>
  );
};
CustomAccReservations.defaultProps = {
  reservations: [],
};
CustomAccReservations.propTypes = {
  reservations: PropTypes.array.isRequired,
};

export default CustomAccReservations;
