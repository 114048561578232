import { createSelector } from "reselect";

export function getTripDestinations(state) {
  return state.tripPlannerDestinations;
}

export const getTripDestinationsSelector = createSelector(
  [getTripDestinations],
  (destinations) => destinations
);

export const destsLengthSelector = createSelector(
  [getTripDestinations],
  (destinations) => destinations.length
);

export const tripDestsIdMappingSelector = createSelector(
  [getTripDestinations],
  (dests) => {
    const tmp = {};
    dests.forEach((dest) => (tmp[`${dest.type}__${dest.id}`] = dest.name_en));
    return tmp;
  }
);

export function getTripOriginData(state) {
  return state.tripPlannerOriginData;
}
export function getTripReturnData(state) {
  return state.tripPlannerReturnData;
}

export const getTripCountriesSelector = createSelector(
  [getTripDestinations],
  (destinations) => {
    return [...new Set(destinations.map((dest) => dest.country_code))];
  }
);
