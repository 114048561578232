import { Notification } from "rsuite";
import React from "react";

export const notifySignInValidationWarning = (msg) => {
  Notification.warning({
    title: "Sign In Validation Warning!",
    description: <p>{msg}</p>,
    duration: 6000,
  });
};

export const notifySignUpValidationWarning = (msg) => {
  Notification.warning({
    title: "Sign Up Validation Warning!",
    description: <p>{msg}</p>,
    duration: 6000,
  });
};

export const notifySignInFailure = () => {
  Notification.error({
    title: "Sign In Failure!",
    description: "We are sorry for the inconvenience.",
    duration: 6000,
  });
};

export const notifyResetEmailSent = (email) => {
  Notification.info({
    title: "Reset Email Sent",
    description: `A reset email has been sent to ${email}`,
    duration: 6000,
  });
};

export const notifyCannotGetGroups = () => {
  Notification.error({
    title: "Permission Authorization Failed",
    description: `Please contact your administrator`,
    duration: 6000,
  });
};
