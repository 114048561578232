import { Toggle } from "rsuite";

import moment from "moment";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import React, { useCallback, useContext } from "react";
import { changeViewStyle } from "@src/actions/Project/TripPlanner";
import { getSetupFormDataSelector } from "@src/selectors/Project/TripPlanner";
import {
  WhisperHelper,
  WhisperWrapper,
} from "@src/components/common/ui_helpers";
import { createUseStyles } from "react-jss";
import { variables } from "@src/jsssetup";
import { PricingFormContext, TripPlanContext } from "../TripPlanner";
import { stayer } from "@src/pages/Operation/common/helpers";
import { getRoomPaxSetupSelector } from "@src/selectors/Project/TripPlanner/guest_selector";
import { useQuery } from "@tanstack/react-query";
import { fetchSubAgentsProfile } from "@src/api";
import { toast } from "react-toastify";
import {
  overviewCtrlSectionHeaderStyles,
  overviewCtrlSectionStyles,
} from "./ServicesFilter";

const toggleStyles = (props) => ({
  "& .rs-btn-toggle-inner": {
    color: props.checked ? "white" : "black",
  },
});

const serviceCollapseToggleStyles = createUseStyles({
  ServiceCollapseToggle: (props) => toggleStyles(props),
});
export const ServiceCollapseToggle = () => {
  const { services_collapsed, setSrvsCollapsed } = useContext(TripPlanContext);
  const classes = serviceCollapseToggleStyles({ checked: services_collapsed });

  return (
    <Toggle
      size="xs"
      className={classes.ServiceCollapseToggle}
      checked={services_collapsed}
      checkedChildren="Collapsed"
      unCheckedChildren="Expanded"
      onChange={(checked) => setSrvsCollapsed(checked)}
    />
  );
};

const dayByDayToggleStyles = createUseStyles({
  DayByDayToggle: (props) => toggleStyles(props),
});
var DayByDayToggle = (props) => {
  const { view_style, requiredServices, viewStyleChange } = props;
  const classes = dayByDayToggleStyles({ checked: view_style == "day_by_day" });
  return (
    <Toggle
      size="xs"
      className={classes.DayByDayToggle}
      disabled={!requiredServices.includes("MI")}
      checked={view_style == "day_by_day"}
      checkedChildren="Day by Day"
      unCheckedChildren="Day by Day"
      onChange={(checked) =>
        viewStyleChange(checked ? "day_by_day" : "overview")
      }
    />
  );
};
DayByDayToggle.propTypes = {
  view_style: PropTypes.string.isRequired,
  requiredServices: PropTypes.array.isRequired,
  viewStyleChange: PropTypes.func.isRequired,
};

const pricingToggleStyles = createUseStyles({
  PricingToggle: (props) => toggleStyles(props),
});
const PricingToggle = () => {
  const con = useContext(PricingFormContext);
  const { showPricingForm, setShowPricingForm } = con;

  const classes = pricingToggleStyles({ checked: showPricingForm });

  return (
    <Toggle
      size="xs"
      className={classes.PricingToggle}
      checked={showPricingForm}
      checkedChildren="Price Edit"
      unCheckedChildren="Price View"
      onChange={(checked) => setShowPricingForm(checked)}
    />
  );
};

const actionsControllerStyles = createUseStyles({
  ActionsController: overviewCtrlSectionStyles(),
  header: overviewCtrlSectionHeaderStyles(),
  actions: {
    display: "grid",
    gridGap: variables.normal_gap,
    gridAutoColumns: "max-content",
    alignItems: "center",
    justifyContent: "center",
    padding: `calc(${variables.normal_gap} / 2)`,
    fontWeight: "600",
  },
  [`@media ${variables.media.bigscreen}`]: {},
});
const ActionsController = () => {
  const { requiredServices, view_style } = useSelector((state) => {
    const { view_style } = state.tripPlannerOverviewStyle;
    const { requiredServices } = getSetupFormDataSelector(state);
    return { requiredServices, view_style };
  });

  const dispatch = useDispatch();
  const viewStyleChange = useCallback(
    (view_style) => dispatch(changeViewStyle(view_style)),
    [dispatch]
  );

  const classes = actionsControllerStyles();

  return (
    <div className={classes.ActionsController}>
      <div className={classes.header}>ITINERARY</div>
      <div className={classes.actions}>
        <ServiceCollapseToggle />
        <Toggle
          size="xs"
          disabled={!requiredServices.includes("MI")}
          checked={view_style == "overview"}
          checkedChildren="Overview"
          unCheckedChildren="Overview"
          onChange={(checked) =>
            viewStyleChange(checked ? "overview" : "day_by_day")
          }
        />
        <WhisperWrapper
          placement="right"
          msg={
            requiredServices.includes("MI")
              ? null
              : "To enable Day By Day view please select Add Ons in Trip Preferences"
          }>
          <div>
            <DayByDayToggle
              view_style={view_style}
              viewStyleChange={viewStyleChange}
              requiredServices={requiredServices}
            />
          </div>
        </WhisperWrapper>
        <PricingToggle />
      </div>
    </div>
  );
};

export default ActionsController;
