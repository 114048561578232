import { CHANGE_LANG } from "@src/actions/i18n/types";

export const setLang = (state = "en", action) => {
  switch (action.type) {
    case CHANGE_LANG:
      return action.lang;
    default:
      return state;
  }
};
