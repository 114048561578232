//=====================================FILTER=========================================
export const PACKAGES_WHOLESALES_INITIALIZE_BASIC_FILTER_DATA =
  "PACKAGES_WHOLESALES_INITIALIZE_BASIC_FILTER_DATA";
export const PACKAGES_WHOLESALES_EDIT_BASIC_FILTER_DATA_FORM =
  "PACKAGES_WHOLESALES_EDIT_BASIC_FILTER_DATA_FORM";
export const PACKAGES_WHOLESALES_UPDATE_FILTER_FORM =
  "PACKAGES_WHOLESALES_UPDATE_FILTER_FORM";
export const PACKAGES_WHOLESALES_APPLY_LIST_FILTER =
  "PACKAGES_WHOLESALES_APPLY_LIST_FILTER";
export const PACKAGES_WHOLESALES_INITIALIZE_LIST_FILTER_FORM =
  "PACKAGES_WHOLESALES_INITIALIZE_LIST_FILTER_FORM";
export const PACKAGES_WHOLESALES_RESET_LIST_FILTER_FORM =
  "PACKAGES_WHOLESALES_RESET_LIST_FILTER_FORM";
export const PACKAGES_WHOLESALES_CHANGE_PRICE_STATUS =
  "PACKAGES_WHOLESALES_CHANGE_PRICE_STATUS";
//====================================COLUMNS========================================
export const PACKAGES_WHOLESALES_INITIALIZE_LIST_COLUMNS_FORM =
  "PACKAGES_WHOLESALES_INITIALIZE_LIST_COLUMNS_FORM";
export const PACKAGES_WHOLESALES_APPLY_LIST_COLUMNS =
  "PACKAGES_WHOLESALES_APPLY_LIST_COLUMNS";
//======================================LIST=================================
export const PACKAGES_WHOLESALES_FETCH_DATA = "PACKAGES_WHOLESALES_FETCH_DATA";

export const PACKAGES_WHOLESALES_LIST_LOADING =
  "PACKAGES_WHOLESALES__LIST_LOADING";
export const PACKAGES_WHOLESALES_LIST_IDLE = "PACKAGES_WHOLESALES__LIST_IDLE";

//======================================NEW==================================
export const PACKAGES_WHOLESALES_FETCH_LIST = "PACKAGES_WHOLESALES_FETCH_LIST";
export const PACKAGES_WHOLESALES_LIST_GENERIC_CHANGE =
  "PACKAGES_WHOLESALES_LIST_GENERIC_CHANGE";

// ========================= PREVIEW PACKAGE =========================
export const PACKAGES_PREVIEW_MODAL_SHOW = "PACKAGES_PREVIEW_MODAL_SHOW";
export const PACKAGES_PREVIEW_MODAL_HIDE = "PACKAGES_PREVIEW_MODAL_HIDE";
export const PACKAGES_PREVIEW_MODAL_LOADING = "PACKAGES_PREVIEW_MODAL_LOADING";
export const PACKAGES_PREVIEW_MODAL_IDLE = "PACKAGES_PREVIEW_MODAL_IDLE";
