import { combineReducers } from "redux";

import * as financialReducer from "./Financial";
import * as authReducer from "./Auth";
import * as QRModalReducer from "./QRModal";

import * as companyReducer from "./Admin/Company/index";

import * as userReducer from "./Admin/User";
import * as userListReducer from "./Admin/User/new_list_reducer";

import * as branchesReducer from "./Network/Branches";

import * as subAgentsReducer from "./Network/SubAgents";

import * as independentAgentsReducer from "./Network/IndependentAgents";

import * as affiliatesReducer from "./Network/Affiliates";

import * as suppliersReducer from "./Network/Suppliers";

import * as shareReducer from "./Shares";
// ======================= ADMIN ======================
import * as invoiceListReducer from "./Admin/Financial/Invoice";

// ======================= TRIP PLANNER REDUCERS ======================
import * as tripPlannerReducer from "./Project/TripPlanner";

import * as sisibidReducer from "./Project/SiSiBid";
import * as biddingRequestReducer from "./Project/BiddingRequest";
import * as customHotelReducer from "./Project/CustomHotel";
import * as customFlightReducer from "./Project/CustomFlight";
import * as customTransferReducer from "./Project/CustomTransfer";

// ========================= CUSTOM SERVICES =========================
import * as addOnReducers from "./Operation/CustomServices/AddOn";

import { setLang } from "./lang/setLang";

import * as accountReducer from "./Account";

//============================================Reservation=================================================

import * as AccommodationReducer from "./Operation/B2BReservations/SingleServices/Accommodation";

import * as FlightsReducer from "./Operation/Flights";

import * as TransferReducer from "./Operation/Transfer";

import * as SSTransferReducer from "./Operation/B2BReservations/SingleServices/Transfers";

import * as SSB2CTransferReducer from "./Operation/Reservation/SingleServices/Transfers";

import * as multiServicesReducer from "./Operation/MultiServices";

import * as ReservationAddOnsReducer from "./Operation/Addons";

import * as B2BReservAddonsReducer from "./Operation/B2BReservations/SingleServices/AddOns";

import * as B2BCoachesListReducer from "./Operation/B2BReservations/SingleServices/Coaches";

import * as ReservationReducers from "./Operation/Reservation";

import * as ToursReducer from "./Operation/Tours";

import * as RentalReducer from "./Operation/Rental";

import * as SSRestaurantsReducer from "./Operation/Reservation/SingleServices/Restaurants";

import * as SSCoordinatorsReducer from "./Operation/Reservation/SingleServices/Coordinators";

import * as SSGeneralServicesReducer from "./Operation/Reservation/SingleServices/GeneralServices";

import * as SSFerriesReducer from "./Operation/Reservation/SingleServices/Ferries";

import * as APIFerriesListReducer from "./Operation/B2BReservations/SingleServices/APIFerries";

import * as APIFerriesB2CListReducer from "./Operation/Reservation/SingleServices/APIFerries";

import * as APITrainsListReducer from "./Operation/B2BReservations/SingleServices/APITrains";

import * as APITrainsB2CListReducer from "./Operation/Reservation/SingleServices/APITrains";

import * as SSTrainsReducer from "./Operation/Reservation/SingleServices/Trains";

import * as SSAdhocAccReducer from "./Operation/Reservation/SingleServices/AdhocAccommodations";

import * as SSContractedAccReducer from "./Operation/Reservation/SingleServices/ContractedAccommodations";

import * as B2CActivitiesReducer from "./Operation/Reservation/SingleServices/Activities";

import * as B2BActivitiesReducer from "./Operation/B2BReservations/SingleServices/Activities";

import * as B2CAddonsListReducer from "./Operation/Reservation/SingleServices/Addons";

import * as B2CFlightsListReducer from "./Operation/Reservation/SingleServices/Flights";

import * as B2BMultiServicesListReducer from "./Operation/B2BReservations/MultiServices";

import * as B2BFlightsListReducer from "./Operation/B2BReservations/SingleServices/Flights";

import * as B2BCarRentalListReducer from "./Operation/B2BReservations/SingleServices/CarRental";

import * as B2CCarRentalListReducer from "./Operation/Reservation/SingleServices/CarRental";

//=================================================Policy===================================================

import * as geoPolicyReducer from "./Admin/GeoPolicy";

import * as oneToOneReducer from "./Admin/OneToOne";

import * as groupPolicyReducer from "./Admin/GroupPolicy";

import * as innerGroupPolicyReducer from "./Admin/InnerGroupPolicy";

import * as b2cPolicyReducer from "./Admin/B2CPolicy";

import * as communicationsReducer from "./Admin/Communications";

import * as NewCommunicationsReducer from "./Admin/Communications/new_reducer";

import * as OnRequestReducer from "./Project/OnRequest";

import * as BiddingTripsReducer from "./Project/Archive/BiddingTrips";

import * as BiddingFlowReducer from "./Project/Archive/BiddingFlow";

import * as BiddingRequestsReducer from "./Project/Archive/BiddingRequests";
import * as BiddingRequestMemberReducer from "./Project/Archive/BiddingRequestsForMember";

import * as MyOffersInProgressReducer from "./Project/Archive/MyOffers/InProgress";
import * as MyOffersSentReducer from "./Project/Archive/MyOffers/Sent";
import * as MyOfferBookedReducer from "./Project/Archive/MyOffers/Booked";
import * as MyOfferRejectedReducer from "./Project/Archive/MyOffers/Rejected";
import * as MyOfferSentListReducer from "./Project/Archive/MyOffers/Sent";
import * as MyOfferBookedListReducer from "./Project/Archive/MyOffers/Booked";

import * as ProductsReducer from "./ManagementConsole/Products";

import * as ManageReducer from "./ManagementConsole/Manage";

import * as paymentMethodsModalReducer from "./ManagementConsole/PaymentMethods";

import * as subscriptionFeesReducer from "./ManagementConsole/Usage";

import * as billAndPaymentsReducer from "./ManagementConsole/BillAndPayments";

import * as accountStatementReducer from "./ManagementConsole/AccountStatement";

import * as purchasesReducer from "./ManagementConsole/Purchases";

import * as salesReducer from "./ManagementConsole/Sales";

import * as payableReducer from "./ManagementConsole/Accounts/Payable";

import * as receivableReducer from "./ManagementConsole/Accounts/Receivable";

import * as subagentsStatementReducer from "./ManagementConsole/SubagentsStatement";

import * as B2BCommonPoliciesListReducer from "./Admin/CommonPolicies";

import * as B2CPoliciesListReducer from "./Admin/B2C";
//==========================================NETWORK MEMBERS========================================
import * as membersReducer from "./Network/Members";

import * as networkMembersReducer from "./Network/Members/NewVersion";

import * as networkMyConnectionsListReducer from "./Network/MyConnections/NewVersion";

import * as networkInvitationSendsListReducer from "./Network/InvitationsSend/NewVersion";

import * as NetworkInvitationListReducer from "./Network/Invitations/NewVersion";

import * as myInvitationsReducer from "./Network/MyConnections";

import * as invitationsReducer from "./Network/Invitations";

import * as invitationsSendReducer from "./Network/InvitationsSend";

import * as initNetworkReducer from "./InitPage";

import * as VendorsFlightsReducer from "./Network/Vendors/Transportation/Flights";
import * as VendorsAccommodationReducer from "./Network/Vendors/Accommodation";
import * as VendorsTransferReducer from "./Network/Vendors/Transfer";
import * as CarRentalVendorsListReducer from "./Network/Vendors/CarRental";
import * as TrainVendorsListReducer from "./Network/Vendors/Train";
import * as RestaurantVendorsListReducer from "./Network/Vendors/Restaurant";
import * as ActivityVendorsListReducer from "./Network/Vendors/Activity";
import * as FerryVendorsListReducer from "./Network/Vendors/Ferry";

import * as BranchesListReducer from "./Network/SalesPartners/Branches";
import * as SubAgentsListReducer from "./Network/SalesPartners/SubAgents";
import * as IndependentAgentsListReducer from "./Network/SalesPartners/IndependentAgents";

// ============================ My Library ===========================
import * as MyLibraryAddonsReducer from "./MyLibrary/Addons";
import * as ImageBankReducer from "./MyLibrary/ImageBank";
import * as GeneralServicesReducer from "./MyLibrary/GeneralServices";
// ============================ wholesales ===========================
import * as WholesalesReducer from "./Project/Packages/Wholesales";

import * as MenuReducer from "./Menu";

import * as B2CStatementReducer from "./ManagementConsole/B2CStatement";

import * as B2CDistributionChannelsReducer from "./Network/B2CDistributionChannels";

import * as QuotationListReducer from "./Project/Quotation";
import * as RestaurantListReducer from "./MyLibrary/Restaurants";
import * as HotelContractListReducer from "./MyLibrary/Hotels";
import * as CoordinatorListReducer from "./MyLibrary/Coordinators";
import * as B2CGuestReducers from "./Operation/Guests/B2C";
import * as ProductTnCReducers from "./Admin/ProductTermsAndConditions";
import * as BookingRemarksReducers from "./Admin/BookingRemarks";
import * as ChatReducers from "./Chat";

const rootReducer = combineReducers({
  ...authReducer,
  ...financialReducer,
  ...companyReducer,
  ...userReducer,
  ...userListReducer,
  ...accountReducer,
  ...branchesReducer,
  ...subAgentsReducer,
  ...independentAgentsReducer,
  ...affiliatesReducer,
  ...suppliersReducer,
  ...shareReducer,
  // ====================== ADMIN REDUCERS =====================
  ...invoiceListReducer,
  // ====================== TRIP PLANNER REDUCERS =====================
  ...tripPlannerReducer,
  setLang,
  ...MenuReducer,
  ...AccommodationReducer,
  ...FlightsReducer,
  ...TransferReducer,
  ...SSTransferReducer,
  ...SSB2CTransferReducer,
  ...ReservationAddOnsReducer,
  ...B2BReservAddonsReducer,
  ...B2BCoachesListReducer,
  ...ReservationReducers,
  ...ToursReducer,
  ...RentalReducer,
  ...SSRestaurantsReducer,
  ...SSCoordinatorsReducer,
  ...SSGeneralServicesReducer,
  ...SSFerriesReducer,
  ...APIFerriesListReducer,
  ...APIFerriesB2CListReducer,
  ...APITrainsListReducer,
  ...APITrainsB2CListReducer,
  ...SSTrainsReducer,
  ...SSAdhocAccReducer,
  ...SSContractedAccReducer,
  ...B2CActivitiesReducer,
  ...B2CAddonsListReducer,
  ...B2CFlightsListReducer,
  ...B2BMultiServicesListReducer,
  ...B2BFlightsListReducer,
  ...B2BCarRentalListReducer,
  ...B2CCarRentalListReducer,
  ...B2BActivitiesReducer,
  ...geoPolicyReducer,
  ...oneToOneReducer,
  ...groupPolicyReducer,
  ...OnRequestReducer,
  ...sisibidReducer,
  ...biddingRequestReducer,
  ...BiddingTripsReducer,
  ...BiddingRequestsReducer,
  ...BiddingRequestMemberReducer,
  ...BiddingFlowReducer,
  ...MyOffersInProgressReducer,
  ...MyOffersSentReducer,
  ...MyOfferSentListReducer,
  ...MyOfferBookedListReducer,
  ...MyOfferBookedReducer,
  ...MyOfferRejectedReducer,
  ...customHotelReducer,
  ...customFlightReducer,
  ...customTransferReducer,
  ...innerGroupPolicyReducer,
  ...b2cPolicyReducer,
  ...ProductsReducer,
  ...ManageReducer,
  ...paymentMethodsModalReducer,
  ...subscriptionFeesReducer,
  ...billAndPaymentsReducer,
  ...accountStatementReducer,
  ...membersReducer,
  ...networkMembersReducer,
  ...networkMyConnectionsListReducer,
  ...networkInvitationSendsListReducer,
  ...NetworkInvitationListReducer,
  ...myInvitationsReducer,
  ...invitationsReducer,
  ...invitationsSendReducer,
  ...initNetworkReducer,
  ...multiServicesReducer,
  ...QRModalReducer,
  ...purchasesReducer,
  ...salesReducer,
  ...payableReducer,
  ...receivableReducer,
  ...communicationsReducer,
  ...NewCommunicationsReducer,
  ...addOnReducers,
  ...VendorsFlightsReducer,
  ...VendorsAccommodationReducer,
  ...VendorsTransferReducer,
  ...CarRentalVendorsListReducer,
  ...TrainVendorsListReducer,
  ...RestaurantVendorsListReducer,
  ...ActivityVendorsListReducer,
  ...FerryVendorsListReducer,
  ...BranchesListReducer,
  ...SubAgentsListReducer,
  ...IndependentAgentsListReducer,
  ...MyLibraryAddonsReducer,
  ...WholesalesReducer,
  ...subagentsStatementReducer,
  ...B2BCommonPoliciesListReducer,
  ...B2CPoliciesListReducer,
  ...B2CStatementReducer,
  ...B2CDistributionChannelsReducer,
  ...ImageBankReducer,
  ...GeneralServicesReducer,
  ...QuotationListReducer,
  ...RestaurantListReducer,
  ...HotelContractListReducer,
  ...CoordinatorListReducer,
  ...B2CGuestReducers,
  ...ProductTnCReducers,
  ...BookingRemarksReducers,
  ...ChatReducers,
});

export default rootReducer;
