import React from "react";
import PropTypes from "prop-types";
import { getUserMetaSelector } from "@src/selectors/Shared/user_selectors";
import _, { every } from "lodash";
import { useSelector } from "react-redux";
import TransTxt from "@src/components/common/SxFormatMessage";
import { toast } from "react-toastify";

export const withAppUserType = (WrappedComponent, types = []) => {
  const Comp = (props) => {
    const { company_type } = useSelector((state) => getUserMetaSelector(state));
    if (types.length > 0 && !types.includes(company_type)) return null;
    return <WrappedComponent {...props} />;
  };
  return Comp;
};

/**
 * This function checks if the user has the required groups to see the component
 * @param {Element} WrappedComponent
 * @param {Array} groups
 * @param {Boolean} showMessage
 * @returns Element or null
 */
const checkAppUserGroup = (
  WrappedComponent,
  groups = [],
  showMessage = true,
  renderComponent = false
) => {
  const upperGroups = groups.map((group) => _.upperCase(group));
  const Comp = (props) => {
    // Get app user groups
    const app_user_groups = useSelector((state) =>
      _.get(getUserMetaSelector(state), "app_user_groups", [])
    );

    const userGroupCodes = app_user_groups.map(
      (app_group) => app_group?.group?.codename
    );

    const hasAllGroups = upperGroups.every((upperGroup) =>
      userGroupCodes.some((groupCode) => _.upperCase(groupCode) === upperGroup)
    );
    if (!hasAllGroups) {
      if (showMessage) {
        toast.error(
          <TransTxt id="you_dont_have_permission_to_see_that_page" />
        );
      }
    }

    if (!hasAllGroups && !renderComponent) return null;

    return <WrappedComponent {...props} />;
  };
  Comp.propTypes = { onClick: PropTypes.func };
  return Comp;
};

/**
 * This function checks if the user has the required permissions to see the component
 * @param {Element} WrappedComponent
 * @param {Array} groups
 * @param {Object} permissions
 * @param {Boolean} showMessage
 * @param {Boolean} renderComponent
 * @returns Element or null
 */
const checkAppUserPermission = (
  WrappedComponent,
  groups = [],
  permissions = { view: true, edit: true, execute: true },
  showMessage = true,
  renderComponent = true
) => {
  // If the WrappedComponent is not defined, return null
  if (!WrappedComponent) return null;

  const upperGroups = groups.map((group) => _.upperCase(group));

  const Comp = (props) => {
    // get app user groups
    const app_user_groups = useSelector((state) =>
      _.get(getUserMetaSelector(state), "app_user_groups", [])
    );

    // Doesn't chech for groups. It considers that the user has the groups
    // and checks only for permissions
    const appUserGroups = app_user_groups.filter((app_group) =>
      upperGroups.includes(_.upperCase(app_group?.group?.codename))
    );

    var permissionsAreValid = [];
    appUserGroups.forEach((appUserGroup) => {
      var groupPermissionsValid = [];
      Object.entries(permissions).forEach(([permission, value]) => {
        groupPermissionsValid.push(appUserGroup[permission] === value);
      });
      permissionsAreValid.push(every(groupPermissionsValid));
    });
    const hasFullPermissions = every(permissionsAreValid);

    if (!hasFullPermissions) {
      var newProps = { ...props };
      if (typeof props.onClick === "function") {
        newProps.onClick = () =>
          showMessage
            ? toast.error(<TransTxt id="you_dont_have_permission_to_do_that" />)
            : null;
      }

      return renderComponent ? <WrappedComponent {...newProps} /> : null;
    }

    return <WrappedComponent {...props} />;
  };
  Comp.propTypes = { onClick: PropTypes.func };
  return Comp;
};

/**
 * This function checks if the user has the required groups and permissions to see the component
 * Is used to check the pages.
 * @param {Component} WrappedComponent
 * @param {Array} groups
 * @param {Object} permissions
 * @param {Boolean} showMessage
 * @param {Boolean} renderComponent
 * @returns
 */
export const withAppUserPermission = (
  WrappedComponent,
  groups = [],
  permissions = { view: true, edit: true, execute: true },
  showMessage = true,
  renderComponent = false
) => {
  return checkAppUserPermission(
    checkAppUserGroup(WrappedComponent, groups, showMessage, renderComponent),
    groups,
    permissions,
    showMessage,
    renderComponent
  );
};

/**
 * This function checks if the user has the required groups and permissions to see the component
 * Is used to check the components.
 * @param {Component} WrappedComponent
 * @param {Array} groups
 * @param {Object} permissions
 * @param {Boolean} showMessage
 * @param {Boolean} renderComponent
 * @returns
 */
export const withAppUserGroup = (
  WrappedComponent,
  groups = [],
  permissions = { view: true, edit: true, execute: true },
  showMessage = true,
  renderComponent = false
) => {
  return checkAppUserPermission(
    checkAppUserGroup(WrappedComponent, groups, showMessage, renderComponent),
    groups,
    permissions,
    showMessage,
    renderComponent
  );
};
