import _ from "lodash";
import { NormalInputField, NormalSelectField } from "@src/components/forms";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import React from "react";
import * as yup from "yup";
import { CustomButton } from "@src/components/common/buttons";
import {
  convertAmountSelector,
  getSelCurrencySelector,
} from "@src/selectors/Financial";
import { useSelector } from "react-redux";
import { withAppUserType } from "@src/components/authorization/permissionhocs";

export const roomFilters = yup.object().shape({
  name: yup.string().default(""),
  board: yup.string().default(""),
  room_type: yup.string().default(""),
  bedding: yup.string().default(""),
  refundable: yup.string().default(""),
  vendor: yup.string().default(""),
  // min_price: yup.number().default(0),
  // max_price: yup.number().default(1000000),
});

var VendorFilter = ({ vendorOptions }) => {
  return (
    <NormalSelectField
      label="Vendor"
      name="vendor"
      options={[["", "------"], ...vendorOptions]}
    />
  );
};
VendorFilter.defaultProps = { vendorOptions: [] };
VendorFilter.propTypes = { vendorOptions: PropTypes.array.isRequired };
VendorFilter = withAppUserType(VendorFilter, ["member"]);

export const RoomFilters = ({ rooms = [], onSetFilters }) => {
  const boardOptions = Object.entries(
    _.countBy(rooms.map((room) => `${room.board_code}__${room.board_display}`))
  ).map(([value, count]) => [
    value.split("__")[0],
    `${value.split("__")[1]} (${count})`,
  ]);
  const beddingOptions = Object.entries(
    _.countBy(rooms.map((room) => room.bedding).filter((bed) => bed))
  ).map(([value, count]) => [value, `${_.capitalize(value)} (${count})`]);

  const roomTypeOptions = Object.entries(
    _.countBy(rooms.map((room) => room.type))
  ).map(([value, count]) => [value, `${_.capitalize(value)} (${count})`]);

  const vendorOptions = Object.entries(
    _.countBy(rooms.map((room) => room.provider))
  ).map(([value, count]) => [value, `${_.capitalize(value)} (${count})`]);

  const refundableOptions = [
    ["", "------"],
    ["true", "Yes"],
    ["false", "No"],
  ];

  // var min_price = 0;
  // var max_price = 0;
  // const roomPrices = rooms.map((room) => room?.price?.value || 0);
  // if (roomPrices.length) {
  //   min_price = parseInt(Math.min(...roomPrices), 10) - 1;
  //   max_price = parseInt(Math.max(...roomPrices), 10) + 1;
  // }

  // const currency = rooms?.[0]?.price?.currency || "EUR";
  // const prices = useSelector((state) => {
  //   const miPrice = parseInt(
  //     convertAmountSelector(state, {
  //       amount: min_price,
  //       currentCurrency: currency,
  //     }).convertedAmount,
  //     10
  //   );

  //   const maPrice = parseInt(
  //     convertAmountSelector(state, {
  //       amount: max_price,
  //       currentCurrency: currency,
  //     }).convertedAmount,
  //     10
  //   );

  //   const selectedCurrency = getSelCurrencySelector(state).notation;
  //   return {
  //     minPrice: miPrice,
  //     maxPrice: maPrice,
  //     selectedCurrency,
  //   };
  // });

  // max_price = prices.maxPrice;
  // min_price = prices.minPrice;

  return !rooms.length ? null : (
    <div className="RoomFilters">
      <Formik
        initialValues={roomFilters.cast({})}
        onSubmit={(values) => onSetFilters(values)}>
        {({ resetForm, submitForm }) => (
          <Form className="RoomFilters__form">
            <NormalInputField label="Name" name="name" />
            <NormalSelectField
              label="Board"
              name="board"
              options={[["", "------"], ...boardOptions]}
            />
            <NormalSelectField
              label="Room Type"
              name="room_type"
              options={[["", "------"], ...roomTypeOptions]}
            />
            <NormalSelectField
              label="Bedding"
              name="bedding"
              options={[["", "------"], ...beddingOptions]}
            />
            <NormalSelectField
              label="Refundable"
              name="refundable"
              options={refundableOptions}
            />
            <VendorFilter vendorOptions={vendorOptions} />
            {/* <NormalInputField
              label="Min Price"
              name="min_price"
              type="number"
            />
            <NormalInputField
              label="Max Price"
              name="max_price"
              type="number"
            /> */}
            <div className="RoomFilters__form__actions">
              <CustomButton
                onClick={(e) => {
                  e.preventDefault();
                  resetForm();
                  const defaultFilters = roomFilters.cast({});
                  onSetFilters(defaultFilters);
                  submitForm();
                }}>
                Reset
              </CustomButton>
              <CustomButton type="sumbmit">Apply</CustomButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
RoomFilters.defaultProps = {
  rooms: [],
};
RoomFilters.propTypes = {
  rooms: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSetFilters: PropTypes.func.isRequired,
};
