import axios from "axios";
import service from "@src/api/request";
import { SISI_API_URL } from "@src/api";

export function getVendorsList(para) {
  return service({ url: "/bid/vendor/", method: "get", params: para });
}

export function fetchVendorsProfile(id) {
  return service({ url: `/bid/vendor/${id}`, method: "get" });
}

export function getVendorsOptions() {
  return service({ url: "/bid/vendor/", method: "options" });
}

export function submitVendorsProfile(id, payload, mode) {
  return service({ url: `/bid/vendor/${id}/`, method: mode, data: payload });
}

export function addNewVendors(data) {
  return service({ url: "/bid/vendor/", method: "post", data: data });
}

export function getVendorsFormData(params) {
  return service({ url: `bid/vendor-data/`, method: "get", params: params });
}

export function submitAirTicketDeliveryInfo(payload) {
  return service({
    url: `bid/air-ticket-delivery/`,
    data: payload,
    method: "post",
  });
}

export function getAirTicketDeliveryInfo(id) {
  return service({
    url: `bid/air-ticket-delivery/`,
    method: "get",
    params: { vendor: id },
  });
}

export function fetchFlightVendorsViewToBook(params) {
  return axios.get(`${SISI_API_URL}/pcc-look-to-book/${params}`);
}
