import { SISI2_API_URL } from "@src/api";
import service, { getAuthHeaders } from "@src/api/request";
import axios from "axios";

export function acceptEmailInvitation(params) {
  return service({
    url: `/bid/entity-invitation/${params.id}/`,
    method: "patch",
    data: { status: params.status },
  });
}

export function getInvitationDetail(params) {
  return service({
    url: `/bid/entity-invitation/${params}/`,
    method: "get",
  });
}

export async function ignoreInvitation(id) {
  const auth_headers = getAuthHeaders();
  return await axios.delete(`/bid/entity-invitation/${id}/`, {
    headers: { ...auth_headers },
  });
}

export async function createInvitation(params) {
  const auth_headers = getAuthHeaders();
  return await axios.post(`${SISI2_API_URL}/crm/entity/invitations/`, params, {
    headers: { ...auth_headers },
  });
}

export async function updateInvitation({ params, id }) {
  const auth_headers = getAuthHeaders();
  return await axios.patch(
    `${SISI2_API_URL}/crm/entity/invitations/${id}/`,
    params,
    {
      headers: { ...auth_headers },
    }
  );
}

export async function fetchInvitations(params) {
  const auth_headers = getAuthHeaders();
  return await axios.get(`${SISI2_API_URL}/crm/entity/invitations/`, {
    params,
    headers: { ...auth_headers },
  });
}

export async function getInvitation(id) {
  const auth_headers = getAuthHeaders();
  return await axios.get(`${SISI2_API_URL}/crm/entity/invitations/${id}/`, {
    headers: { ...auth_headers },
  });
}

export async function deleteInvitation(id) {
  const auth_headers = getAuthHeaders();
  return await axios.delete(`${SISI2_API_URL}/crm/entity/invitations/${id}/`, {
    headers: { ...auth_headers },
  });
}
