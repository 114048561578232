import {
  CUSTOM_SERVICE_ADDON_SHOW_EDIT_ITEM_MODAL,
  CUSTOM_SERVICE_ADDON_HIDE_EDIT_ITEM_MODAL,
  CUSTOM_SERVICE_ADDON_EDIT_ITEM_CONTENT,
  CUSTOM_SERVICE_ADDON_CHANGE_EDIT_ITEM_CURRENT_LANG,
  CUSTOM_SERVICE_ADDON_UPDATE_ITEM_DISTANCE,
  CUSTOM_SERVICE_ADDON_EDIT_ITEM_IMAGE,
  CUSTOM_SERVICE_ADDON_EDIT_ITEM_IMAGE_MAIN_PHOTO,
  CUSTOM_SERVICE_ADDON_REMOVE_ITEM_IMAGE,
  CUSTOM_SERVICE_ADDON_TOGGLE_ITEM_NON_CUSTOM_IMAGE,
  CUSTOM_SERVICE_ADDON_SHOW_EDIT_ITEM_DEP_META_MODAL,
  CUSTOM_SERVICE_ADDON_HIDE_EDIT_ITEM_DEP_META_MODAL,
  CUSTOM_SERVICE_ADDON_SHOW_IMAGES_MODAL,
  CUSTOM_SERVICE_ADDON_HIDE_IMAGES_MODAL,
} from "./types";

import _ from "lodash";

export const showItemEditModal = (itemOrder) => {
  return { type: CUSTOM_SERVICE_ADDON_SHOW_EDIT_ITEM_MODAL, itemOrder };
};

export const hideItemEditModal = () => {
  return { type: CUSTOM_SERVICE_ADDON_HIDE_EDIT_ITEM_MODAL };
};

export const changeItem = (key, value, order) => (dispatch, getState) => {
  const { itemOrder } = getState().customServiceItemContentModal;
  dispatch({
    type: CUSTOM_SERVICE_ADDON_EDIT_ITEM_CONTENT,
    itemOrder: typeof order !== "undefined" ? order : itemOrder,
    key,
    value,
  });
};

export const tglItmNonCustomImg = (imgIdxs, status) => (dispatch, getState) => {
  const { itemOrder } = getState().customServiceItemContentModal;
  dispatch({
    type: CUSTOM_SERVICE_ADDON_TOGGLE_ITEM_NON_CUSTOM_IMAGE,
    itemOrder,
    imgIdxs,
    status,
  });
};

export const changeItemImage = (image_type, images) => (dispatch, getState) => {
  const state = getState();
  const { itemOrder } = state.customServiceItemContentModal;
  // const { data } = _.get(state, `customServiceAddOnItems.${itemOrder - 1}`, {});
  // const list = state.imageBankListData;

  dispatch({
    type: CUSTOM_SERVICE_ADDON_EDIT_ITEM_IMAGE,
    itemOrder,
    image_type,
    images,
    // data,
    // list,
  });
};

export const removeItemImage = (key, value) => (dispatch, getState) => {
  const { itemOrder } = getState().customServiceItemContentModal;
  dispatch({
    type: CUSTOM_SERVICE_ADDON_REMOVE_ITEM_IMAGE,
    itemOrder,
    key,
    value,
  });
};

export const changeItemMainImg = (key, value, id) => (dispatch, getState) => {
  const { itemOrder } = getState().customServiceItemContentModal;
  dispatch({
    type: CUSTOM_SERVICE_ADDON_EDIT_ITEM_IMAGE_MAIN_PHOTO,
    itemOrder,
    key,
    value,
    id,
  });
};

export const changeItemLang = (lang) => {
  return { type: CUSTOM_SERVICE_ADDON_CHANGE_EDIT_ITEM_CURRENT_LANG, lang };
};

export const updateDistanceData = (data) => {
  return { type: CUSTOM_SERVICE_ADDON_UPDATE_ITEM_DISTANCE, data };
};

// ======================= DEPARTURE META MODAL ======================

export const showItemDepMetaModal = (itemOrder) => {
  return {
    type: CUSTOM_SERVICE_ADDON_SHOW_EDIT_ITEM_DEP_META_MODAL,
    itemOrder,
  };
};

export const hideItemDepMetaModal = () => {
  return {
    type: CUSTOM_SERVICE_ADDON_HIDE_EDIT_ITEM_DEP_META_MODAL,
  };
};
