import PropTypes from "prop-types";
import React from "react";

function SupplierError({ title }) {
  return (
    <span>
      Please enter the <strong>{title}</strong>.
    </span>
  );
}
SupplierError.propTypes = {
  title: PropTypes.string.isRequired,
};

function RemarksError({ title }) {
  return (
    <span>
      Please confirm the <strong>{title}</strong> remarks.
    </span>
  );
}
RemarksError.propTypes = {
  title: PropTypes.string.isRequired,
};

export function commonValidateFeedback({
  cxl_remarks,
  booking_mode_remarks,
  reservation_remarks,
  payment_mode_remarks,
  request_cxl_policy,
  cxl_rules,
}) {
  const msgs = [];

  if (!cxl_remarks.confirmed) {
    msgs.push(<RemarksError title="Cancellation Policy" />);
  }

  if (!booking_mode_remarks.confirmed) {
    msgs.push(<RemarksError title="Booking Mode" />);
  }

  if (!reservation_remarks.confirmed) {
    msgs.push(<RemarksError title="General Remarks" />);
  }

  if (!payment_mode_remarks.confirmed) {
    msgs.push(<RemarksError title="Payment Mode" />);
  }

  if (request_cxl_policy && cxl_rules.some((rule) => !rule.valid)) {
    msgs.push(
      <span>
        Please add correct data to all{" "}
        <strong>Cancellation Policy Rules</strong>.
      </span>
    );
  }

  return msgs;
}

export function validateSupplierInfo({
  supplier_name,
  supplier_email,
  supplier_phone,
}) {
  const msgs = [];

  if (!supplier_name) {
    msgs.push(<SupplierError title="Supplier Name" />);
  }

  if (!supplier_email) {
    msgs.push(<SupplierError title="Supplier Email" />);
  }

  if (!supplier_phone) {
    msgs.push(<SupplierError title="Supplier Phone" />);
  }

  return msgs;
}
