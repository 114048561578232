import React from "react";
import TransTxt from "@src/components/common/SxFormatMessage";
import _ from "lodash";

import { baseColumnsMapping } from "@src/forms/CommonColumns/baseColumns";
import { archiveColumnsForm } from "@src/forms/Project/Archive";

export const initialBiddingRequestColumnsSetUp = {
  basic: {
    value: [
      //   "title",
      //   "request_by",
      //   "pax",
      //   "time_period",
      //   "tripplanlist_origin",
      //   "destinations",
      //   "tripplanlist_return",
      //   "service_types",
      //   "total",
      //   "per_person",
      //   "creator",
      //   "created",
      //   "edited",
      "status",
    ],
    choices: [
      //   {
      //     display_name: <SxFormatMessage id="tripplanlist_trip_title" startCase />,
      //     key: "title",
      //   },
      //   {
      //     display_name: <SxFormatMessage id="tripplanlist_request_by" startCase />,
      //     key: "request_by",
      //   },
      //   {
      //     display_name: <SxFormatMessage id="tripplanlist_pax" startCase />,
      //     key: "pax",
      //   },
      //   {
      //     display_name: <SxFormatMessage id="tripplanlist_time_period" startCase />,
      //     key: "time_period",
      //   },
      //   {
      //     display_name: <SxFormatMessage id="tripplanlist_destinations" startCase />,
      //     key: "destinations",
      //   },
      //   {
      //     display_name: (
      //       <SxFormatMessage id="tripplanlist_services_category" startCase />
      //     ),
      //     key: "service_types",
      //   },
      //   {
      //     display_name: <SxFormatMessage id="tripplanlist_total_price" startCase />,
      //     key: "total",
      //   },
      //   {
      //     display_name: (
      //       <SxFormatMessage id="tripplanlist_per_person_price" startCase />
      //     ),
      //     key: "per_person",
      //   },

      //   {
      //     display_name: <SxFormatMessage id="creator" startCase />,
      //     key: "creator",
      //   },
      //   {
      //     display_name: <SxFormatMessage id="created" startCase />,
      //     key: "created",
      //   },
      //   {
      //     display_name: <SxFormatMessage id="edited" startCase />,
      //     key: "edited",
      //   },
      {
        display_name: <TransTxt id="status" startCase />,
        key: "status",
      },
    ],
  },
};

export const initialBiddingRequestColumnsProcessor = {
  //   title: {
  //     key: "title",
  //     getter: data => _.get(data, "itinerary_setup.title"),
  //   },
  //   request_by: {
  //     key: "request_by",
  //     getter: () => "",
  //   },
  //   pax: {
  //     key: "pax",
  //     getter: data => {
  //       return `${_.get(data, "itinerary_setup.adults")} Adults,${_.get(
  //         data,
  //         "itinerary_setup.children"
  //       )} Children`;
  //     },
  //   },
  //   time_period: {
  //     key: "main_service",
  //     getter: data => {
  //       const data_a = formatDate(_.get(data, "origin_data.date"));
  //       const data_b = formatDate(_.get(data, "return_data.date"));
  //       return `${data_a}-${data_b}`;
  //     },
  //   },
  //   tripplanlist_origin: {
  //     key: "tripplanlist_origin",
  //     getter: (data, options) => {
  //       const lang = options[0];
  //       return _.get(data, `origin_data.destination.name_${lang_mapping[lang]}`);
  //     },
  //   },
  //   destinations: {
  //     key: "destinations",
  //     getter: (data, options) => {
  //       const res = _.get(data, "destinations", []);
  //       const lang = options[0];
  //       const arr = [];
  //       res.forEach(item => {
  //         arr.push(item[`name_${lang_mapping[lang]}`]);
  //       });

  //       return `${arr[0]}(${arr.length})`;
  //     },
  //     extraGetter: (data, options) => {
  //       const res = _.get(data, "destinations", []);
  //       const lang = options[0];
  //       const arr = [];
  //       res.forEach(item => {
  //         arr.push(item[`name_${lang_mapping[lang]}`]);
  //       });

  //       return arr;
  //     },
  //   },
  //   tripplanlist_return: {
  //     key: "tripplanlist_return",
  //     getter: (data, options) => {
  //       const lang = options[0];
  //       return _.get(data, `return_data.destination.name_${lang_mapping[lang]}`);
  //     },
  //   },
  //   service_types: {
  //     key: "service_types",
  //     getter: data => {
  //       const res = _.get(data, "service_types");
  //       const arr = [];

  //       Object.keys(res).map(item => {
  //         if (res[item].available) {
  //           arr.push(
  //             getDisplayNameFromOptions(item, [
  //               { display_name: "Transportation", value: "FL" },
  //               { display_name: "Transfer", value: "TF" },
  //               { display_name: "Accommodation", value: "ACC" },
  //             ])
  //           );
  //         }
  //       });
  //       return `${arr[0]}(${arr.length})`;
  //     },
  //     extraGetter: data => {
  //       const res = _.get(data, "service_types");
  //       const arr = [];
  //       Object.keys(res).map(item => {
  //         if (res[item].available) {
  //           arr.push(
  //             getDisplayNameFromOptions(item, [
  //               { display_name: "Transportation", value: "FL" },
  //               { display_name: "Transfer", value: "TF" },
  //               { display_name: "Accommodation", value: "ACC" },
  //             ])
  //           );
  //         }
  //       });
  //       return arr;
  //     },
  //   },
  //   total: {
  //     key: "total",
  //     getter: data => {
  //       return _.get(data, "final_pricing.selling_price");
  //     },
  //   },
  //   per_person: {
  //     key: "per_person",
  //     getter: data => {
  //       return _.get(data, "final_pricing.per_person_selling_price");
  //     },
  //   },
  //   creator: {
  //     key: "creator",
  //     getter: () => "",
  //   },
  //   created: {
  //     key: "created",
  //     getter: data => formatDate(data.created),
  //   },
  //   edited: {
  //     key: "edited",
  //     getter: data => formatDate(data.edited),
  //   },
  status: {
    key: "status",
    getter: () => "",
  },
};

export const initialBiddingRequestColumnsMapping = _.merge({
  title: {
    key: "title",
  },
  request_by: {
    key: "request_by",
  },
  pax: {
    key: "pax",
  },
  time_period: {
    key: "main_service",
  },
  destinations: {
    key: "destinations",
  },
  service_types: {
    key: "service_types",
  },

  status: {
    key: "status",
    fixed: "right",
  },
  baseColumnsMapping,
});

export const biddingRequestsArchiveColumnsForm = _.merge(
  {},
  archiveColumnsForm,
  {
    per_person: {
      display_name: "Target",
      // eslint-disable-next-line react/display-name
      getter: () => {
        return "";
      },
      extraGetter: () => {
        return "";
      },
    },
  }
);
