import { Schema } from "rsuite";
const { ArrayType } = Schema.Types;
import _ from "lodash";

import TransTxt from "@src/components/common/SxFormatMessage";
import React from "react";
import { initialPolicyBaseDataForm } from "@src/forms/Admin/Columns/policyCommonBaseForm";

export const initialInnerGroupPolicyFilterForm = {
  name: {
    value: "",
    verbose: <TransTxt id="policy_name" startCase />,
    type: "text",
    placeholder: "Big Policy",
  },
  pax: {
    value: [1, 40],
    verbose: "pax",
    type: "text",
    isSlider: true,
    isFilter: true,
  },
  distribution: {
    value: [],
    placeholder: "YYYY-MM-DD~YYYY-MM-DD",
    verbose: <TransTxt id="distribution_period" startCase />,
    placement: "right",
  },
  reservation: {
    value: [],
    placeholder: "YYYY-MM-DD~YYYY-MM-DD",
    verbose: <TransTxt id="reservation_period" startCase />,
    placement: "right",
  },
  company_users: {
    value: [],
    verbose: <TransTxt id="company_users" startCase />,
    placeholder: "Eileen",
  },
  territory: {
    value: [],
    verbose: "",
  },
  created: {
    value: [],
    placeholder: "YYYY-MM-DD~YYYY-MM-DD",
    verbose: <TransTxt id="created_date" startCase />,
    placement: "left",
  },
  creator: {
    value: "",
    verbose: <TransTxt id="created_by" startCase />,
    type: "text",
    placeholder: "Eileen",
  },
  credit_amount: {
    value: "",
    verbose: <TransTxt id="amount" startCase />,
    type: "text",
    placeholder: "$100",
  },
  credit_days: {
    value: "",
    verbose: <TransTxt id="days" startCase />,
    type: "text",
    placeholder: "30",
  },
  travel_services: {
    value: [],
    verbose: "Travel Services",
  },
  last_edit: {
    value: [],
    placeholder: "YYYY-MM-DD~YYYY-MM-DD",
    verbose: <TransTxt id="last_edited_date" startCase />,
    type: "text",
    placement: "left",
  },
  status: {
    value: [],
    verbose: "status",
    type: "text",
    data: [
      {
        value: "IN",
        label: "Inactive",
        display_name: <TransTxt id="inactive" startCase />,
      },
      {
        value: "AC",
        label: "Active",
        display_name: <TransTxt id="active" startCase />,
      },
      {
        value: "CA",
        label: "Candidate",
        display_name: <TransTxt id="candidate" startCase />,
      },
    ],
  },
  AF: {
    value: [],
    verbose: "AF",
  },
  AS: {
    value: [],
    verbose: "AS",
  },
  EU: {
    value: [],
    verbose: "EU",
  },
  NA: {
    value: [],
    verbose: "NA",
  },
  SA: {
    value: [],
    verbose: "SA",
  },
  AU: {
    value: [],
    verbose: "AU",
  },
  AN: {
    value: [],
    verbose: "AN",
  },
};

export const initialInnerGroupPolicyBaseDataForm = _.merge(
  {},
  {
    company_users: {
      value: [],
      validator: ArrayType().isRequired("Company Users is required"),
    },
  },
  initialPolicyBaseDataForm
);
export const defaultOption = {
  basic: {
    value: ["policy_name", "status"],
  },
};

export * from "./columns";
