import { SSCOORDINATORS_LIST_GENERIC_CHANGE } from "@src/actions/Operation/Reservations/SingleServices/Coordinators/types";
import { commonList } from "@src/reducers/common";

const SSCoordinatorsListReducerInitial = {
  ...commonList,
  displayLength: 100,
  filters: {
    reference: "",
    external_reference: "",
    source_entity_autocomplete: "",
    source_entity: "",
    service_reference: "",
    name: "",
    creator: "",
    creator_email: "",
    travel_start_date__gte: "",
    travel_end_date__lte: "",
    response_deadline__gte: "",
    response_deadline__lte: "",
    created__gte: "",
    created__lte: "",
    payment_mode: "",
    booking_mode: "",
    status: "",
    page: 1,
    limit: 20,
    ordering: "",
    tags: "",
  },
};
export const SSCoordinatorsListReducer = (
  state = SSCoordinatorsListReducerInitial,
  action
) => {
  switch (action.type) {
    case SSCOORDINATORS_LIST_GENERIC_CHANGE: {
      const { filters } = action;
      return { ...state, filters: { ...state.filters, ...filters } };
    }
    default:
      return state;
  }
};
