import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import { variables } from "@src/jsssetup";

const pieChartStyles = createUseStyles({
  CreatePieChart: {
    "display": "grid",
    "padding": variables.normal_gap,
    // "width": "30rem",
    "height": "20rem",
    "& div": {
      width: "23rem",
      height: "10rem",
    },
    "& canvas": {
      width: "100%!important",
      height: "100%!important",
    },
  },
});
var CreatePieChart = ({ title, colors, bottom, data }) => {
  const classes = pieChartStyles();
  const chartRef = useRef(null);

  const createChartInstance = () => {
    const chartInstance = echarts.init(chartRef.current);

    const option = {
      title: {
        text: title,
        top: "5%",
        left: "center",
        textStyle: {
          fontSize: 16,
          fontFamily: "futura,sans-serif",
          fontWeight: "400",
        },
      },
      tooltip: {
        trigger: "item",
        formatter: function (params) {
          return ` ${params.name} ${params.value} %`;
        },
      },
      legend: {
        type: "scroll",
        orient: "vertical",
        left: 0,
        bottom: bottom,
        itemWidth: 10,
        itemHeight: 10,
        textStyle: {
          fontSize: 10,
          color: colors,
          width: 120,
          overflow: "break",
        },
      },
      series: [
        {
          top: "25%",
          right: 0,
          type: "pie",
          radius: ["30%", "60%"],
          center: ["62%", "40%"],

          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: "#fff",
            borderWidth: 2,
          },
          label: {
            show: true,
            formatter: "{c}",
            position: "outside",
            textStyle: {
              color: "auto",
              fontSize: 8,
            },
            emphasis: {
              show: true,
              position: "edge",
            },
          },
          labelLine: {
            show: false,
            length: 0.1,
          },
          color: colors,
          data: data,
        },
      ],
    };

    chartInstance.setOption(option);
    return chartInstance;
  };

  useEffect(() => {
    createChartInstance(data);
  }, [data]);
  useEffect(() => {
    if (!data) return;
    const existingChart = echarts.getInstanceByDom(chartRef.current);
    if (existingChart) {
      existingChart.resize();
    }
  }, []);
  return (
    <React.Fragment>
      <div ref={chartRef} className={classes.CreatePieChart} />
    </React.Fragment>
  );
};
CreatePieChart.propTypes = {
  title: PropTypes.string.isRequired,
  bottom: PropTypes.number.isRequired,
  colors: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
};
export default CreatePieChart;
