import PropTypes from "prop-types";
import React from "react";

export default class MessageFormatter extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { msgs } = this.props;

    return (
      <ul className="MessageFormatter">
        {msgs.map((m, idx) => (
          <li key={idx}>
            <span>
              <strong>{m[0]}: </strong>
              {m[1]}
            </span>
          </li>
        ))}
      </ul>
    );
  }
}

MessageFormatter.propTypes = {
  msgs: PropTypes.array.isRequired,
};
