import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";

const LeanAuthHoc = (
  WrappedComponent,
  {
    allowed_groups = [],
    top_entity_allowed_groups = [],
    entity_allowed_groups = [],
    invert_decision = false,
    AltComponent = null,
  }
) => {
  class Hoc extends React.Component {
    constructor(props) {
      super(props);
    }
    shouldRender() {
      const { groups, entity_groups, top_entity_groups } = this.props;

      if (
        !allowed_groups.length &&
        !top_entity_allowed_groups.length &&
        !entity_allowed_groups.length
      ) {
        return true;
      }

      var shouldRender = true;

      if (groups.length && allowed_groups.length) {
        shouldRender = groups.some((group) => allowed_groups.includes(group));
      }

      if (entity_groups.length && entity_allowed_groups.length) {
        shouldRender =
          shouldRender &&
          entity_groups.some((group) => entity_allowed_groups.includes(group));
      }

      if (top_entity_groups.length && top_entity_allowed_groups.length) {
        shouldRender =
          shouldRender &&
          top_entity_groups.some((group) =>
            top_entity_allowed_groups.includes(group)
          );
      }

      return shouldRender;
    }
    render() {
      const shouldRender = invert_decision
        ? !this.shouldRender()
        : this.shouldRender();

      if (shouldRender) {
        return <WrappedComponent {...this.props} />;
      } else {
        return AltComponent ? <AltComponent /> : null;
      }
    }
  }

  Hoc.defaultProps = {
    groups: [],
    entity_groups: [],
    top_entity_groups: [],
  };

  Hoc.propTypes = {
    groups: PropTypes.array.isRequired,
    entity_groups: PropTypes.array.isRequired,
    top_entity_groups: PropTypes.array,
  };

  const mapStateToProps = (state) => {
    const { groups, entity_groups, top_entity_groups } = state.userPerms;
    return {
      groups,
      entity_groups,
      top_entity_groups,
    };
  };

  return connect(mapStateToProps, null)(Hoc);
};

export default LeanAuthHoc;
