import axios from "axios";
import { SISI2_API_URL } from "..";

export function startContentGenerationSession({
  lang = "en",
  source_entity,
  messages,
  context = "content_generator",
  max_tokens = 200,
}) {
  const url = `${SISI2_API_URL}/primeradiant/content_generation`;
  return axios.post(url, {
    lang,
    source_entity,
    messages,
    max_tokens,
    task: context,
  });
}

export function pollContentGenerationSession(session_id) {
  const url = `${SISI2_API_URL}/primeradiant/content_generation/${session_id}`;
  return axios.get(url);
}
