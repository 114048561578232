import _ from "lodash";
import { Button, Loader } from "rsuite";
import { modalGenericStyles, variables } from "@src/jsssetup";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { Field, Form, Formik } from "formik";
import {
  NormalDestAutocompleteField,
  NormalSelectField,
} from "@src/components/forms";
import { restaurantPriceSearch } from "@src/api/Restaurants";
import { getUserSourceEntitySelector } from "@src/selectors/Shared/user_selectors";
import { useSelector } from "react-redux";
import RestaurantsList from "./products/restaurants";
import CoordinatorsList from "./products/coordinators";
import GeneralServicesList from "./products/general_services";
import { coordServicePriceSearch } from "@src/api/Coordinators";
import { DateTime } from "luxon";
import { toast } from "react-toastify";
import {
  getSetupFormDataSelector,
  tripDestsIdMappingSelector,
} from "@src/selectors/Project/TripPlanner";
import { generalSrvPriceSearch } from "@src/api/GeneralServices";
import { service_type_mapping } from "@src/pages/MyLibrary/GeneralServices";
import { getRoomPaxSetupSelector } from "@src/selectors/Project/TripPlanner/guest_selector";

const styles = createUseStyles({
  requiredDaysHeader: {
    display: "grid",
    gridTemplateColumns: "1fr auto",
    gridGap: variables.half_gap,
  },
  requiredDays: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
  },
  day: {
    display: "grid",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    gridAutoFlow: "column",
    gridAutoColumns: "max-content",
  },
  dayDests: {
    display: "grid",
    gridAutoFlow: "column",
    gridGap: `calc(${variables.normal_gap} / 2)`,
  },
  destinationSelector: {
    display: "grid",
    gridTemplateColumns: "1fr 2fr",
    gridGap: `calc(${variables.normal_gap} / 2)`,
  },
  actions: modalGenericStyles.cardActions,
});
const AddCustomServiceModal = ({
  date,
  dayIdx,
  destination_ids,
  commonDestDays,
  onCancel,
}) => {
  const classes = styles();

  const { adults, children, children_ages } = useSelector((state) => {
    const rooms = getRoomPaxSetupSelector(state);
    if (rooms.length) {
      const adults = rooms.reduce((a, b) => a + b.adults, 0);
      const children = rooms.reduce((a, b) => a + b.children, 0);
      const children_ages =
        _.flatten(rooms.map((room) => room.children_ages)) || [];
      return { adults, children, children_ages };
    }
    return { ...getSetupFormDataSelector(state), children_ages: [] };
  });

  const destination_id_mapping = useSelector((state) =>
    tripDestsIdMappingSelector(state)
  );
  const source_entity = useSelector((state) =>
    getUserSourceEntitySelector(state)
  );

  const [loading, setLoading] = useState(false);
  const [serviceType, setServiceType] = useState(null);
  const [searchFromDate, setSearchFromDate] = useState(null);
  const [searchToDate, setSearchToDate] = useState(null);
  const [searchDaysIdxs, setSearchDaysIdxs] = useState(null);
  const [generalServiceType, setGeneralServiceType] = useState(null);
  const [step, setStep] = useState(1);
  const [results, setResults] = useState([]);
  const [destination_id, setDestinationId] = useState(null);
  const [secondary_destination_id, setSecondaryDestinationId] = useState(null);
  const [selected_destination_ids, setSelectedDestinationIds] = useState(null);
  const [sel_dest_ids_dates, setSelDestIdsDates] = useState(null);

  async function generalServicePriceSearch() {
    const payload = {
      date_from: searchFromDate.split("___")[0],
      date_to: searchToDate.split("___")[0],
      source_entity,
      destination: secondary_destination_id
        ? secondary_destination_id
        : destination_id,
      languages: ["en", "cn"],
      pax: { adults, children, children_ages },
      type: generalServiceType,
    };
    setResults([]);

    setLoading(true);
    const result = await generalSrvPriceSearch({ payload });
    setResults(_.get(result, "results", []));
    setLoading(false);
  }

  async function restauPriceSearch() {
    const payload = {
      source_entity,
      date,
      destination: secondary_destination_id
        ? secondary_destination_id
        : destination_id,
      pax: { adults, children: 0, children_ages: [] },
    };
    setResults([]);
    setLoading(true);
    const result = await restaurantPriceSearch({ payload });
    setResults(_.get(result, "results", []));
    setLoading(false);
  }

  async function coordPriceSearch() {
    const country_codes = [
      ...new Set(
        _.flatten(
          commonDestDays.map((day) =>
            day.destinations.filter((dest) =>
              selected_destination_ids.includes(`${dest.type}__${dest.id}`)
            )
          )
        ).map((dest) => dest.country_code)
      ),
    ];

    const payload = {
      date_from: searchFromDate.split("___")[0],
      date_to: searchToDate.split("___")[0],
      source_entity,
      languages: ["en", "cn"],
      countries: country_codes,
    };

    setResults([]);
    setLoading(true);
    const result = await coordServicePriceSearch({ payload });
    setResults(_.get(result, "results", []));
    setLoading(false);
  }

  useEffect(() => {
    if (!destination_id) {
      return;
    }

    if (step === 2) {
      if (serviceType === "REST") {
        restauPriceSearch();
      } else if (serviceType === "COO") {
        coordPriceSearch();
      } else if (serviceType === "GEN") {
        generalServicePriceSearch();
      }
    }
  }, [step, destination_id]);

  const destination_ids_checkboxes = {};
  const destKeys = _.flattenDeep(
    commonDestDays.map((day) =>
      day.destinations.map((dest) => ({ ...dest, dest_date: day.date }))
    )
  ).map((dest) => `${dest.dest_date}___${dest.type}__${dest.id}`);
  destKeys.forEach((key) => {
    destination_ids_checkboxes[key] = false;
  });
  const initialValues = {
    service_type: "REST",
    general_service_type: null,
    destination_id: _.first(destination_ids),
    destination_ids: selected_destination_ids,
    destination_ids_checkboxes,
    secondary_destination: "",
  };

  commonDestDays.forEach(
    (day) => (initialValues[`${day.date}___${day.day}`] = day.date === date)
  );

  var destinationOptions = Object.entries(destination_id_mapping);
  destinationOptions = destinationOptions.filter((entry) =>
    destination_ids.includes(entry[0])
  );

  function getDayDestCheckKeys({ values }) {
    const dayDestKeys = Object.entries(values)
      .map((entry) => {
        if (!entry[0].includes("___")) {
          return null;
        }

        return entry[0];
      })
      .filter((k) => k);
    return dayDestKeys;
  }

  function allChecksAreChecked({ values }) {
    const dayDestKeys = getDayDestCheckKeys({ values });

    const checked = [
      ...Object.entries(values.destination_ids_checkboxes).map(
        (entry) => entry[1]
      ),
      ...dayDestKeys.map((key) => values[key]),
    ];
    return !checked.includes(false);
  }

  return (
    <div className="Modal">
      {loading && <Loader size="lg" center />}
      {step === 1 && (
        <div className="Modal__card">
          <div className="Modal__card__header">
            <h5>Add Custom Service</h5>
          </div>
          <Formik
            initialValues={initialValues}
            validateOnChange={false}
            validateOnBlur={false}
            validateOnMount={false}
            validate={(values) => {
              const errors = {};
              if (
                values.service_type === "GEN" &&
                !values.general_service_type
              ) {
                errors.general_service_type =
                  "Please select a General Service Type first.";
                toast.error(errors.general_service_type, { duration: 6000 });
              }
              return errors;
            }}
            onSubmit={(values) => {
              setServiceType(values.service_type);
              if (values.service_type === "GEN") {
                setGeneralServiceType(values.general_service_type);
              }

              if (["COO", "GEN"].includes(values.service_type)) {
                const searchDays = Object.entries(values)
                  .filter(
                    (entry) =>
                      !DateTime.fromISO(entry[0].split("___")[0]).invalid
                  )
                  .filter((entry) => entry[1])
                  .map((entry) => entry[0]);

                if (!searchDays.length) {
                  toast.error("At least one day must be selected", {
                    duration: 5000,
                  });
                  return;
                }

                const selected_dest_ids = [
                  ...new Set(
                    Object.entries(values.destination_ids_checkboxes)
                      .filter((entry) => entry[1])
                      .map((entry) => {
                        const [date, destId] = entry[0].split("___");
                        return destId;
                      })
                  ),
                ];

                const sel_dest_ids_dates = [
                  ...new Set(
                    Object.entries(values.destination_ids_checkboxes)
                      .filter((entry) => entry[1])
                      .map((entry) => {
                        const [date, destId] = entry[0].split("___");
                        return [destId, date];
                      })
                  ),
                ];

                setSelectedDestinationIds(selected_dest_ids);
                setSelDestIdsDates(sel_dest_ids_dates);
                setSearchFromDate(searchDays[0]);
                setSearchToDate(_.last(searchDays));
                setSearchDaysIdxs(searchDays);
              }

              if (values.secondary_destination.includes("---")) {
                const [__, dest_id] = values.secondary_destination.split("---");
                setSecondaryDestinationId(dest_id);
              }
              setDestinationId(values.destination_id);
              setStep(2);
            }}>
            {({ values, setFieldValue, submitForm }) => (
              <React.Fragment>
                <div className="Modal__card__body">
                  <Form className="Form">
                    <NormalSelectField
                      name="service_type"
                      label="Service Type"
                      options={[
                        ["REST", "Restaurant"],
                        ["COO", "Coordinator"],
                        // ["GEN", "General Service"],
                      ]}
                    />
                    {values.service_type !== "COO" &&
                      (["GEN", "REST"].includes(values.service_type) ? (
                        <React.Fragment>
                          <NormalSelectField
                            name="destination_id"
                            label="Destination"
                            options={destinationOptions}
                          />
                          <NormalDestAutocompleteField
                            id="secondary_destination_autocomplete"
                            name="secondary_destination"
                            label="Secondary Destination"
                          />
                        </React.Fragment>
                      ) : (
                        <NormalSelectField
                          name="destination_id"
                          label="Destination"
                          options={destinationOptions}
                        />
                      ))}
                    {values.service_type === "GEN" && (
                      <NormalSelectField
                        name="general_service_type"
                        label="General Service Type"
                        options={[
                          [null, "------"],
                          ...Object.entries(service_type_mapping),
                        ]}
                      />
                    )}
                    {["COO", "GEN"].includes(values.service_type) && (
                      <div className={classes.requiredDays}>
                        <div className={classes.requiredDaysHeader}>
                          <span>
                            <strong>Required Days</strong>
                          </span>
                          <Button
                            appearance="primary"
                            size="xs"
                            onClick={() => {
                              const dayDestKeys = getDayDestCheckKeys({
                                values,
                              });
                              const newValue = allChecksAreChecked({ values })
                                ? false
                                : true;

                              const tmp = {};
                              Object.entries(
                                values.destination_ids_checkboxes
                              ).forEach((entry) => {
                                tmp[entry[0]] = newValue;
                              });
                              setFieldValue("destination_ids_checkboxes", tmp);

                              dayDestKeys.forEach((key) =>
                                setFieldValue(key, newValue)
                              );
                            }}>
                            <strong>
                              {allChecksAreChecked({ values })
                                ? "Deselect All"
                                : "Select All"}
                            </strong>
                          </Button>
                        </div>
                        {Object.entries(values)
                          .filter(
                            (entry) =>
                              ![
                                "service_type",
                                "destination_id",
                                "destination_ids",
                                "general_service_type",
                                "destination_ids_checkboxes",
                                "secondary_destination",
                              ].includes(entry[0])
                          )
                          .map((entry, idx) => {
                            const dests = _.flattenDeep(
                              commonDestDays
                                .filter(
                                  (day) => day.date === entry[0].split("___")[0]
                                )
                                .map((day) =>
                                  day.destinations.map((dest) => ({
                                    ...dest,
                                    dest_date: day.date,
                                  }))
                                )
                            );

                            return (
                              <div key={idx} className={classes.day}>
                                <Field type="checkbox" name={entry[0]} />
                                <span>
                                  Day {idx + 1}.{" "}
                                  {DateTime.fromISO(
                                    entry[0].split("___")[0]
                                  ).toLocaleString(DateTime.DATE_MED)}
                                </span>
                                <div className={classes.dayDests}>
                                  (
                                  {dests.map((d, didx) => (
                                    <div key={didx}>
                                      <Field
                                        name={`destination_ids_checkboxes.${d.dest_date}___${d.type}__${d.id}`}
                                        type="checkbox"
                                      />{" "}
                                      {d.name_en}
                                    </div>
                                  ))}
                                  )
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    )}
                  </Form>
                </div>
                <div className="Modal__card__actions">
                  <button
                    className="Button"
                    data-ghost="true"
                    onClick={(e) => {
                      e.preventDefault();
                      onCancel();
                    }}>
                    Cancel
                  </button>
                  <button className="Button" type="submit" onClick={submitForm}>
                    Next
                  </button>
                </div>
              </React.Fragment>
            )}
          </Formik>
        </div>
      )}
      {step === 2 && serviceType === "REST" && (
        <RestaurantsList
          destination_id={destination_id}
          secondary_destination_id={secondary_destination_id}
          dayIdx={dayIdx}
          data={results}
          loading={loading}
          onCancel={onCancel}
        />
      )}
      {step === 2 && serviceType === "COO" && (
        <CoordinatorsList
          dest_date_couples={sel_dest_ids_dates}
          destination_ids={selected_destination_ids}
          searchDaysIdxs={searchDaysIdxs}
          data={results}
          loading={loading}
          onCancel={onCancel}
        />
      )}
      {step === 2 && serviceType === "GEN" && (
        <GeneralServicesList
          destination_id={destination_id}
          secondary_destination_id={secondary_destination_id}
          searchDaysIdxs={searchDaysIdxs}
          data={results}
          loading={loading}
          dayIdx={dayIdx}
          onCancel={onCancel}
        />
      )}
    </div>
  );
};
AddCustomServiceModal.defaultProps = {
  commonDestDays: [],
  destination_ids: [],
};
AddCustomServiceModal.propTypes = {
  destination_ids: PropTypes.string.isRequired,
  commonDestDays: PropTypes.array.isRequired,
  date: PropTypes.string.isRequired,
  dayIdx: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default AddCustomServiceModal;
