import {
  CUSTOM_SERVICE_ADDON_ADD_MEETING_POINT_GROUP,
  CUSTOM_SERVICE_ADDON_REMOVE_MEETING_POINT_GROUP,
  CUSTOM_SERVICE_ADDON_CHANGE_MEETING_POINT_SETUP,
  CUSTOM_SERVICE_ADDON_CHANGE_MEETING_POINT_GROUP_MODE,
  CUSTOM_SERVICE_ADDON_LOADING_MEETING_POINT_GROUP,
  CUSTOM_SERVICE_ADDON_IDLE_MEETING_POINT_GROUP,
  CUSTOM_SERVICE_ADDON_SET_MEETING_POINT_AIRPORTS,
  CUSTOM_SERVICE_ADDON_SET_MEETING_POINT_ACCOMMODATIONS,
  CUSTOM_SERVICE_ADDON_SHOW_ADD_CUSTOM_MEETING_POINT_POI_MODAL,
  CUSTOM_SERVICE_ADDON_HIDE_ADD_CUSTOM_MEETING_POINT_POI_MODAL,
  CUSTOM_SERVICE_ADDON_SELECT_MEETING_POINT,
  CUSTOM_SERVICE_ADDON_DESELECT_ALL_MEETING_POINTS,
  CUSTOM_SERVICE_ADDON_SELECT_ALL_MEETING_POINTS,
  CUSTOM_SERVICE_ADDON_SET_CUSTOM_MEETING_POINT,
} from "./types";
import {
  notifyMeetingPointGroupValidation,
  notifyMeetingGroupPoints,
} from "@src/components/common/notifications/AddOnNotifications";

import _ from "lodash";
import React from "react";
import { createCMSB64Id } from "@src/actions/Project/TripPlanner/tools";
import {
  airportFromCms,
  airportsByDestination,
} from "@src/api/Project/TripPlanner";
import { fetchAccommodations } from "@src/api/Project/Bidding";

export const addMeetingPointGroup = (groupIdx) => {
  return { type: CUSTOM_SERVICE_ADDON_ADD_MEETING_POINT_GROUP, groupIdx };
};

export const removeMeeetingPointGroup = (groupIdx) => {
  return { type: CUSTOM_SERVICE_ADDON_REMOVE_MEETING_POINT_GROUP, groupIdx };
};

export const changeMeetingPointGroupSetup = (groupIdx, key, value) => {
  return {
    type: CUSTOM_SERVICE_ADDON_CHANGE_MEETING_POINT_SETUP,
    groupIdx,
    key,
    value,
  };
};

export const loadingMeetingPointGroup = (groupIdx) => {
  return { type: CUSTOM_SERVICE_ADDON_LOADING_MEETING_POINT_GROUP, groupIdx };
};

export const idleMeetingPointGroup = (groupIdx) => {
  return { type: CUSTOM_SERVICE_ADDON_IDLE_MEETING_POINT_GROUP, groupIdx };
};

export const setAirportPoints = (groupIdx) => async (dispatch, getState) => {
  const destination = getState().customServiceAddOnDestination.destination;

  const destination_id = `${destination.type}__${destination.id}`;
  const result = await airportsByDestination({ destination_id });

  dispatch({
    type: CUSTOM_SERVICE_ADDON_SET_MEETING_POINT_AIRPORTS,
    groupIdx,
    airports: result.airports.map((airport) => ({
      ...airport,
      type: "airport",
      selected: false,
    })),
  });
};

const setAccommodationPoints = (groupIdx) => async (dispatch, getState) => {
  const group = getState().customMeetingPointGroups[groupIdx];
  const destination = getState().customServiceAddOnDestination.destination;
  const main_destination = `${destination.type}__${destination.id}`;
  const payload = {
    main_destination,
    official_rating__in: group.setup.rating.join(","),
    country_code: destination.country_code,
    limit: 10000,
  };
  if (_.get(group, "setup.nearby.geodata.lat")) {
    payload["geolocation"] = `lat__${_.get(
      group,
      "setup.nearby.geodata.lat"
    )}___lng__${_.get(group, "setup.nearby.geodata.lng")}___radius__${
      group.setup.radius * 1000
    }`;
  }
  var results = {};
  try {
    results = await fetchAccommodations(payload);
  } catch (error) {
    notifyMeetingGroupPoints();
    dispatch(idleMeetingPointGroup());
    return;
  }

  if (!results.length && !Object.keys(payload).includes("geolocation")) {
    try {
      results = await fetchAccommodations({
        ..._.omit(payload, main_destination),
        secondary_destinations__in: main_destination,
      });
    } catch (error) {
      notifyMeetingGroupPoints();
      dispatch(idleMeetingPointGroup());
      return;
    }
  }
  dispatch({
    type: CUSTOM_SERVICE_ADDON_SET_MEETING_POINT_ACCOMMODATIONS,
    groupIdx,
    accommodations: results.map((result) => ({
      ...result,
      type: "accommodation",
      selected: false,
    })),
  });
};

export const changeMeetingPointGroupMode =
  (groupIdx, mode) => async (dispatch, getState) => {
    const group = getState().customMeetingPointGroups[groupIdx];

    if (
      mode == "list" &&
      !getState().customMeetingPointGroups[groupIdx].setup.type.length
    ) {
      notifyMeetingPointGroupValidation([
        <span key={0}>
          A <strong>Point Type</strong> must be selected for{" "}
          <strong>Group {groupIdx + 1}</strong>.
        </span>,
      ]);
      return;
    }

    dispatch(loadingMeetingPointGroup(groupIdx));

    dispatch({
      type: CUSTOM_SERVICE_ADDON_CHANGE_MEETING_POINT_GROUP_MODE,
      groupIdx,
      mode,
    });

    if (mode == "list") {
      if (group.setup.type.includes("airports")) {
        await dispatch(setAirportPoints(groupIdx));
      }

      if (group.setup.type.includes("hotels")) {
        await dispatch(setAccommodationPoints(groupIdx));
      }

      dispatch(idleMeetingPointGroup(groupIdx));
    }
  };

export const showCustomMPModal = (groupIdx) => {
  return {
    type: CUSTOM_SERVICE_ADDON_SHOW_ADD_CUSTOM_MEETING_POINT_POI_MODAL,
    groupIdx,
  };
};

export const hideCustomMPModal = () => {
  return { type: CUSTOM_SERVICE_ADDON_HIDE_ADD_CUSTOM_MEETING_POINT_POI_MODAL };
};

export const setCustomMP = (groupIdx, point) => (dispatch) => {
  dispatch({
    type: CUSTOM_SERVICE_ADDON_SET_CUSTOM_MEETING_POINT,
    groupIdx,
    point,
  });
  dispatch(hideCustomMPModal());
};

export const selectMeetingPoint = (groupIdx, pointType, id, selected) => {
  return {
    type: CUSTOM_SERVICE_ADDON_SELECT_MEETING_POINT,
    groupIdx,
    pointType,
    id,
    selected,
  };
};

export const deselectAllMeetingPoints = (groupIdx) => {
  return {
    type: CUSTOM_SERVICE_ADDON_DESELECT_ALL_MEETING_POINTS,
    groupIdx,
  };
};

export const selectAllMeetingPoints = (groupIdx) => {
  return {
    type: CUSTOM_SERVICE_ADDON_SELECT_ALL_MEETING_POINTS,
    groupIdx,
  };
};
