import _ from "lodash";
import { NormalInputField, NormalSelectField } from "@src/components/forms";
import { modalGenericStyles, tableStyles, variables } from "@src/jsssetup";
import { getServicesListSelector } from "@src/selectors/Project/TripPlanner/generic_service_selectors";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { createUseStyles } from "react-jss";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "rsuite";
import { updateDbDCustomSrv } from "@src/actions/Project/TripPlanner/day_by_day/custom_services";
import { toast } from "react-toastify";
import { mealTypeOptions } from "./products/restaurants";

const editRestaurantServiceModalStyles = createUseStyles({
  ...modalGenericStyles,
  EditRestaurantServiceModal: modalGenericStyles.modal,
  cardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateColumns: "1fr 2fr",
  },
  ...tableStyles,
  menuTableContainer: {
    display: "grid",
    gridGap: variables.normal_gap,
  },
  menuHeader: {
    display: "grid",
    gridTemplateColumns: "1fr auto",
    alignItems: "center",
  },
});
const EditRestaurantServiceModal = ({ srv_id, dayIdx, onCancel }) => {
  const services = useSelector((state) => getServicesListSelector(state));
  const service = services.find(
    (srv) => srv.service_type === "REST" && srv.reference === srv_id
  );
  const classes = editRestaurantServiceModalStyles();

  const dispatch = useDispatch();
  const onApply = useCallback(
    ({ data }) =>
      dispatch(
        updateDbDCustomSrv({ dayIdx, srv_id: data.id, data, srv_type: "REST" })
      ),
    [dispatch]
  );

  return (
    <div className={classes.EditRestaurantServiceModal}>
      <div className={classes.card}>
        <div className={classes.cardHeader}>
          <h5>Edit Restaurant Service</h5>
        </div>
        <Formik
          validateOnBlur={false}
          validateOnChange={false}
          initialValues={{ menu: service.menu, meal_type: service.meal_type }}
          validate={(values) => {
            const mains = values.menu[0].items.filter(
              (it) => it.main.toString() === "true"
            ).length;
            if (mains > 1) {
              const msg = "Only one course can be the main course.";
              toast.error(msg, {
                duration: 5000,
              });
              return { menu: msg };
            }
            return {};
          }}
          onSubmit={(values) => {
            const data = _.cloneDeep(service);
            data.meal_type = values.meal_type;
            data.menu = values.menu;
            data.menu[0].items.forEach((it) => {
              if (it.main === "true") {
                it.main = true;
              } else if (it.main === "false") {
                it.main = false;
              } else {
                return;
              }
            });
            onApply({ data });
            onCancel();
          }}>
          {({ values, setFieldValue }) => (
            <Form>
              <div className={classes.cardBody}>
                <div className={classes.col}>
                  <NormalInputField label="Title" name="menu.0.title" />
                  <NormalInputField
                    label="Buying Price Per Person"
                    name="menu.0.buying_price.value"
                    type="number"
                  />
                  <NormalInputField
                    label="Selling Price Per Person"
                    name="menu.0.selling_price.value"
                    type="number"
                  />
                  <NormalSelectField
                    name="meal_type"
                    label="Meal Type"
                    options={Object.entries(mealTypeOptions)}
                  />
                </div>
                <div className={classes.menuTableContainer}>
                  <div className={classes.menuHeader}>
                    <h6>Menu</h6>
                    <Button
                      size="xs"
                      color="green"
                      onClick={() => {
                        const menu = _.cloneDeep(values.menu);
                        menu[0].items.push({
                          main: false,
                          name: "",
                          description: "",
                        });
                        setFieldValue("menu", menu);
                      }}>
                      <strong>Add Item</strong>
                    </Button>
                  </div>
                  <table className={classes.table}>
                    <thead className={classes.head}>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Main Course</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {values.menu[0].items.map((mn, idx) => (
                        <tr key={idx}>
                          <td className={classes.cell}>{idx + 1}</td>
                          <td className={classes.cell}>
                            <NormalInputField
                              name={`menu.0.items.${idx}.name`}
                            />
                          </td>
                          <td className={classes.cell}>
                            <NormalInputField
                              name={`menu.0.items.${idx}.description`}
                            />
                          </td>
                          <td className={classes.cell}>
                            <NormalSelectField
                              name={`menu.0.items.${idx}.main`}
                              options={[
                                [true, "Yes"],
                                [false, "No"],
                              ]}
                            />
                          </td>
                          <td className={classes.cell}>
                            <Button
                              size="xs"
                              color="red"
                              onClick={() => {
                                const menu = _.cloneDeep(values.menu);
                                menu[0].items.splice(idx, 1);
                                setFieldValue("menu", menu);
                              }}>
                              <strong>Remove</strong>
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className={classes.cardActions}>
                <Button appearance="ghost" onClick={onCancel}>
                  <strong>Cancel</strong>
                </Button>
                <Button appearance="primary" type="submit">
                  <strong>Apply</strong>
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
EditRestaurantServiceModal.propTypes = {
  srv_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  dayIdx: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default EditRestaurantServiceModal;
