export const ACCOUNT_FETCHED_DETAILS = "ACCOUNT_FETCHED_DETAILS";

export const ACCOUNT_BEGIN_CHANGE_PASSWORD = "ACCOUNT_BEGIN_CHANGE_PASSWORD";
export const ACCOUNT_END_CHANGE_PASSWORD = "ACCOUNT_END_CHANGE_PASSWORD";

export const ACCOUNT_BEGIN_CHANGE_STATUS = "ACCOUNT_BEGIN_CHANGE_STATUS";
export const ACCOUNT_END_CHANGE_STATUS = "ACCOUNT_END_CHANGE_STATUS";
export const ACCOUNT_UPDATE_DETAILS = "ACCOUNT_UPDATE_DETAILS";

// =========================== Account Profile ==========================
export const ACCOUNT_PROFILE_LOADED = "ACCOUNT_PROFILE_LOADED";
export const ACCOUNT_PROFILE_LOADING = "ACCOUNT_PROFILE_LOADING";

// ================== Account Profile: Account Base Section =================
export const ACCOUNT_VIEW_BASE_DATA_MODE = "ACCOUNT_VIEW_BASE_DATA_MODE";
export const ACCOUNT_EDIT_BASE_DATA_MODE = "ACCOUNT_EDIT_BASE_DATA_MODE";

export const ACCOUNT_EDIT_BASE_DATA_FORM = "ACCOUNT_EDIT_BASE_DATA_FORM";
export const ACCOUNT_INITIALIZE_BASE_DATA_FORM =
  "ACCOUNT_INITIALIZE_BASE_DATA_FORM";
export const ACCOUNT_VALIDATE_BASE_DATA_FORM =
  "ACCOUNT_VALIDATE_BASE_DATA_FORM";
export const ACCOUNT_UPLOADING_BASE_DATA_MODE =
  "ACCOUNT_UPLOADING_BASE_DATA_MODE";

// ================= Account Profile: Expertise Section ================
export const ACCOUNT_VIEW_EXPERTISE_MODE = "ACCOUNT_VIEW_EXPERTISE_MODE";
export const ACCOUNT_EDIT_EXPERTISE_MODE = "ACCOUNT_EDIT_EXPERTISE_MODE";
export const ACCOUNT_UPLOADING_EXPERTISE_MODE =
  "ACCOUNT_UPLOADING_EXPERTISE_MODE";

export const ACCOUNT_EDIT_EXPERTISE_FORM = "ACCOUNT_EDIT_EXPERTISE_FORM";
export const ACCOUNT_INITIALIZE_EXPERTISE_FORM =
  "ACCOUNT_INITIALIZE_EXPERTISE_FORM";

// =============== Account Profile: Travel Sector Section ==============
export const ACCOUNT_VIEW_TRAVEL_SECTOR_MODE =
  "ACCOUNT_VIEW_TRAVEL_SECTOR_MODE";
export const ACCOUNT_EDIT_TRAVEL_SECTOR_MODE =
  "ACCOUNT_EDIT_TRAVEL_SECTOR_MODE";
export const ACCOUNT_UPLOADING_TRAVEL_SECTOR_MODE =
  "ACCOUNT_UPLOADING_TRAVEL_SECTOR_MODE";

export const ACCOUNT_EDIT_TRAVEL_SECTOR_FORM =
  "ACCOUNT_EDIT_TRAVEL_SECTOR_FORM";
export const ACCOUNT_INITIALIZE_TRAVEL_SECTOR_FORM =
  "ACCOUNT_INITIALIZE_TRAVEL_SECTOR_FORM";

// ==================== Account Profile: Preferences ===================
export const ACCOUNT_VIEW_PREFERENCES_MODE = "ACCOUNT_VIEW_PREFERENCES_MODE";
export const ACCOUNT_EDIT_PREFERENCES_MODE = "ACCOUNT_EDIT_PREFERENCES_MODE";
export const ACCOUNT_UPLOADING_PREFERENCES_MODE =
  "ACCOUNT_UPLOADING_PREFERENCES_MODE";

export const ACCOUNT_INITIALIZE_LANGUAGE_FORM =
  "ACCOUNT_INITIALIZE_LANGUAGE_FORM";
export const ACCOUNT_EDIT_LANGUAGE_FORM = "ACCOUNT_EDIT_LANGUAGE_FORM";

export const ACCOUNT_INITIALIZE_IMAGE_FORM = "ACCOUNT_INITIALIZE_IMAGE_FORM";

export const ACCOUNT_UPLOADED_PREFERENCE_IMAGE =
  "ACCOUNT_UPLOADED_PREFERENCE_IMAGE";
export const ACCOUNT_BEGIN_UPLOAD_IMG_LINK = "ACCOUNT_BEGIN_UPLOAD_IMG_LINK";
export const ACCOUNT_ENG_UPLOAD_IMG_LINK = "ACCOUNT_ENG_UPLOAD_IMG_LINK";
export const ACCOUNT_INITIALIZE_GENDER_FORM = "ACCOUNT_INITIALIZE_GENDER_FORM";

// ============== Account Profile: Job Description Section =============
export const ACCOUNT_VIEW_JOB_DESCRIPTION_MODE =
  "ACCOUNT_VIEW_JOB_DESCRIPTION_MODE";
export const ACCOUNT_EDIT_JOB_DESCRIPTION_FORM =
  "ACCOUNT_EDIT_JOB_DESCRIPTION_FORM";
export const ACCOUNT_PROFILE_UPDATE_JOB_DESCRIPTION =
  "ACCOUNT_PROFILE_UPDATE_JOB_DESCRIPTION";
export const ACCOUNT_INITIALIZE_JOB_DESCRIPTION_FORM =
  "ACCOUNT_INITIALIZE_JOB_DESCRIPTION_FORM";
export const ACCOUNT_EDIT_JOB_DESCRIPTION_MODE =
  "ACCOUNT_EDIT_JOB_DESCRIPTION_MODE";
export const ACCOUNT_FORMAT_JOB_DESCRIPTION_FORM =
  "ACCOUNT_FORMAT_JOB_DESCRIPTION_FORM";
export const ACCOUNT_UPLOADING_JOB_DESCRIPTION_MODE =
  "ACCOUNT_UPLOADING_JOB_DESCRIPTION_MODE";

export const ACCOUNT_INITIALIZE_DEPARTMENT_FORM =
  "ACCOUNT_INITIALIZE_DEPARTMENT_FORM";
export const ACCOUNT_EDIT_DEPARTMENT_FORM = "ACCOUNT_EDIT_DEPARTMENT_FORM";

// ======= Account Profile: Account Work Duties Section Actions ======
export const ACCOUNT_VIEW_WORK_DUTIES_MODE = "ACCOUNT_VIEW_WORK_DUTIES_MODE";
export const ACCOUNT_EDIT_WORK_DUTIES_MODE = "ACCOUNT_EDIT_WORK_DUTIES_MODE";
export const ACCOUNT_UPLOADING_WORK_DUTIES_MODE =
  "ACCOUNT_UPLOADING_WORK_DUTIES_MODE";

// ==================== Service Specialty Section ====================
export const ACCOUNT_LOADING_SERVICE_SPECIALTY_MODE =
  "ACCOUNT_LOADING_SERVICE_SPECIALTY_MODE";
export const ACCOUNT_INITIALIZE_SERVICE_SPECIALTY_FORM =
  "ACCOUNT_INITIALIZE_SERVICE_SPECIALTY_FORM";
export const ACCOUNT_SELECT_TRAVEL_SERVICE = "ACCOUNT_SELECT_TRAVEL_SERVICE";
export const ACCOUNT_INITIALIZE_TRAVEL_SERVICE_FORM =
  "ACCOUNT_INITIALIZE_TRAVEL_SERVICE_FORM";
export const ACCOUNT_LOADING_SERVICE_CATEGORY_MODE =
  "ACCOUNT_LOADING_SERVICE_CATEGORY_MODE";
export const ACCOUNT_VIEW_SERVICE_CATEGORIES_MODE =
  "ACCOUNT_VIEW_SERVICE_CATEGORIES_MODE";
export const ACCOUNT_EDIT_SERVICE_CATEGORIES_MODE =
  "ACCOUNT_EDIT_SERVICE_CATEGORIES_MODE";
export const ACCOUNT_SELECT_SERVICE_CATEGORY =
  "ACCOUNT_SELECT_SERVICE_CATEGORY";

//====================  User Permission Section=======================
export const ACCOUNT_EDIT_PERMISSION_MODE = "ACCOUNT_EDIT_PERMISSION_MODE";
export const ACCOUNT_VIEW_PERMISSION_MODE = "ACCOUNT_VIEW_PERMISSION_MODE";
export const ACCOUNT_LOADING_PERMISSION_MODE =
  "ACCOUNT_LOADING_PERMISSION_MODE";
export const ACCOUNT_EDIT_PERMISSION_FORM = "ACCOUNT_EDIT_PERMISSION_FORM";
export const ACCOUNT_INITIALIZE_PERMISSION_FORM =
  "ACCOUNT_INITIALIZE_PERMISSION_FORM";
export const ACCOUNT_LOADED_PERMISSION_MODE = "ACCOUNT_LOADED_PERMISSION_MODE";

//====================  User EmailConfig Section======================
export const ACCOUNT_EDIT_EMAIL_CONFIG_MODE = "ACCOUNT_EDIT_EMAIL_CONFIG_MODE";
export const ACCOUNT_VIEW_EMAIL_CONFIG_MODE = "ACCOUNT_VIEW_EMAIL_CONFIG_MODE";
export const ACCOUNT_LOADING_EMAIL_CONFIG_MODE =
  "ACCOUNT_LOADING_EMAIL_CONFIG_MODE";
export const ACCOUNT_EDIT_EMAIL_CONFIG_FORM = "ACCOUNT_EDIT_EMAIL_CONFIG_FORM";
export const ACCOUNT_INITIALIZE_EMAIL_CONFIG_FORM =
  "ACCOUNT_INITIALIZE_EMAIL_CONFIG_FORM";
export const ACCOUNT_LOADED_EMAIL_CONFIG_MODE =
  "ACCOUNT_LOADED_EMAIL_CONFIG_MODE";
