import React from "react";
import { createUseStyles } from "react-jss";
import { GeneralStats } from "../GeneralStats";
import CreatePieChart from "../../ChartComponents/CreatePieChart";
import { variables } from "@src/jsssetup";
import PropTypes from "prop-types";
import TransTxt from "@src/components/common/SxFormatMessage";
import { injectIntl } from "react-intl";
import _ from "lodash";

const salesReportTable = createUseStyles({
  SalesReportTable: {
    display: "grid",
    gridTemplateRows: "max-content",
  },
  statsTable: {
    display: "grid",
    justifyItems: "center",
  },
  tableContainer: {
    display: "grid",
    border: `2px solid #FF6600`,
    borderRadius: "10px",
    textAlign: "center",
  },
  table: {
    width: "100%",
  },
  tHead: {
    backgroundColor: "#FF6600",
  },
  headRow: {
    "background": "#FF6600",
    "& th:first-child": {
      borderTopLeftRadius: "10%",
    },
    "& th:last-child": {
      borderTopRightRadius: "10%",
    },
  },
  headCell: {
    backgroundColor: "#FF6600",
    textAlign: "center",
    color: "white",
    padding: variables.half_gap,
    fontFamily: "futura,sans-serif",
    fontWeight: "400",
  },
  tableCell: {
    textAlign: "center",
    padding: `${variables.half_gap} ${variables.normal_gap}`,
    fontFamily: "futura,sans-serif",
    height: "2rem",
  },
});

const SalesReportTable = ({ data }) => {
  const classes = salesReportTable();

  return (
    <div className={classes.SalesReportTable}>
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead className={classes.tHead}>
            <tr className={classes.headRow}>
              <th className={classes.headCell}>
                {<TransTxt id="B2B_stats_TRF_top_sales__count" />}
              </th>
              <th className={classes.headCell}>
                {<TransTxt id="B2B_stats_TRF_top_sales__pax_per_booking" />}
              </th>
              <th className={classes.headCell}>
                {
                  <TransTxt id="B2B_stats_TRF_top_sales__avg_price_per_booking" />
                }
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={classes.tableCell}>
                {_.get(data, "top_sales.count") !== 0
                  ? _.get(data, "top_sales.count") || "N/A"
                  : "0"}
              </td>
              <td className={classes.tableCell}>
                {_.get(data, "top_sales.pax_per_booking") !== 0
                  ? Math.round(_.get(data, "top_sales.pax_per_booking")) ||
                    "N/A"
                  : "0"}
              </td>
              <td className={classes.tableCell}>
                {_.get(data, "top_sales.price.avg") !== 0
                  ? _.get(data, "top_sales.price.currency")
                  : ""}{" "}
                {_.get(data, "top_sales.price.avg") !== 0
                  ? _.get(data, "top_sales.price.avg") || "N/A"
                  : "0"}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
SalesReportTable.propTypes = {
  data: PropTypes.object.isRequired,
};

const styles = createUseStyles({
  TRFTopSales: {
    display: "grid",
    padding: variables.normal_gap,
  },
  statsTable: {
    display: "grid",
    justifyItems: "center",
  },
  charts: {
    display: "grid",
    gridTemplateColumns: "20fr 1fr 20fr 1fr 20fr",
    justifyItems: "center",
  },
  chart1: {
    display: "grid",
  },
  chart2: {
    display: "grid",
  },
  chart3: {
    display: "grid",
  },
  divider: {
    justifySelf: "center",
    alignSelf: "center",
    height: "85%",
    border: `1px solid rgba(0, 0, 0, 0.1)`,
    gridColumn: "1 span",
    width: "0%",
  },
});

var TRFTopSales = ({ intl, data }) => {
  const classes = styles();

  const transferModeData = _.get(data, "top_sales.transfer_mode", []).map(
    ([date, value]) => ({
      name: date,
      value,
    })
  );
  const transferModeTotal = transferModeData.reduce(
    (acc, dt) => acc + dt.value,
    0
  );
  const transfermodePercentages = transferModeData.map((dt) => ({
    name: dt.name,
    value: ((dt.value / transferModeTotal) * 100).toFixed(2),
  }));
  const transferModeColors = ["#FF6600", "#B174FF"];

  const top5VehicleTypesData = _.get(
    data,
    "top_sales.vehicle_type.items",
    []
  ).map(([date, value]) => ({
    name: date,
    value,
  }));

  const top5VehicleTypesTotal = top5VehicleTypesData.reduce(
    (acc, dt) => acc + dt.value,
    0
  );
  const top5VehicleTypesPercentages = top5VehicleTypesData.map((dt) => ({
    name: dt.name,
    value: ((dt.value / top5VehicleTypesTotal) * 100).toFixed(2),
  }));

  const top5VehicleTypesColors = ["#FF6600", "#B174FF", "#A4A4A4"];

  const transferTypesData = _.get(data, "top_sales.transfer_type", []).map(
    ([date, value]) => ({
      name: date,
      value,
    })
  );
  const transferTypesTotal = transferTypesData.reduce(
    (acc, sale) => acc + sale.value,
    0
  );
  const transferTypesPercentages = transferTypesData.map((sale) => ({
    name: sale.name,
    value: ((sale.value / transferTypesTotal) * 100).toFixed(2),
  }));
  const transferTypesColors = ["#FF6600", "#B174FF"];

  return (
    <div className={classes.TRFTopSales}>
      <div className={classes.stats}>
        <GeneralStats
          title={intl.formatMessage({
            id: "B2B_stats_TRF_top_sales__top_sales",
          })}
          tableTitle={intl.formatMessage({
            id: "B2B_stats_TRF_top_sales__confirmed_reservations",
          })}
          startDate={_.get(data, "period.start_date")}
          endDate={_.get(data, "period.end_date")}
        />
        <div className={classes.statsTable}>
          <SalesReportTable data={data} />
        </div>
      </div>
      <div className={classes.charts}>
        <div className={classes.chart1}>
          <CreatePieChart
            title={intl.formatMessage({
              id: "B2B_stats_TRF_top_sales__transfer_mode",
            })}
            bottom={75}
            colors={transferModeColors}
            data={transfermodePercentages}
          />
        </div>
        <div className={classes.divider}></div>
        <div className={classes.chart2}>
          <CreatePieChart
            title={intl.formatMessage({
              id: "B2B_stats_TRF_top_sales__top__5_vehicle_types",
            })}
            bottom={75}
            colors={top5VehicleTypesColors}
            data={top5VehicleTypesPercentages}
          />
        </div>
        <div className={classes.divider}></div>
        <div className={classes.chart3}>
          <CreatePieChart
            title={intl.formatMessage({
              id: "B2B_stats_TRF_top_sales__tranfer_type",
            })}
            bottom={75}
            colors={transferTypesColors}
            data={transferTypesPercentages}
          />
        </div>
      </div>
    </div>
  );
};
TRFTopSales.propTypes = {
  data: PropTypes.object.isRequired,
  intl: PropTypes.object,
  dashboardPayload: PropTypes.object.isRequired,
};
TRFTopSales = injectIntl(TRFTopSales);

export default TRFTopSales;
