//=========================================LIST======================================
export const AFFILIATES_FETCH_DATA = "AFFILIATES_FETCH_DATA";
export const AFFILIATES_BEGIN_FETCH_DATA = "AFFILIATES_BEGIN_FETCH_DATA";
export const AFFILIATES_END_FETCH_DATA = "AFFILIATES_END_FETCH_DATA";
export const AFFILIATES_FETCH_OPTIONS = "AFFILIATES_FETCH_OPTIONS";

//===========================================FILTER==================================
export const AFFILIATES_INITIALIZE_LIST_FILTER_FORM =
  "AFFILIATES_INITIALIZE_LIST_FILTER_FORM";
export const AFFILIATES_UPDATE_LIST_FILTER_FORM =
  "AFFILIATES_UPDATE_LIST_FILTER_FORM";
export const AFFILIATES_APPLY_LIST_FILTER = "AFFILIATES_APPLY_LIST_FILTER";
export const AFFILIATES_RESET_LIST_FILTER_FORM =
  "AFFILIATES_RESET_LIST_FILTER_FORM";

//====================================COLUMNS========================================
export const AFFILIATES_UPDATE_LIST_COLUMNS_FORM =
  "AFFILIATES_UPDATE_LIST_COLUMNS_FORM";
export const AFFILIATES_APPLY_LIST_COLUMNS = "AFFILIATES_APPLY_LIST_COLUMNS";
export const AFFILIATES_INITIALIZE_LIST_COLUMNS_FORM =
  "AFFILIATES_INITIALIZE_LIST_COLUMNS_FORM";

//==============================DETAIL AND EDIT=====================================
export const AFFILIATES_FETCH_PROFILE = "AFFILIATES_FETCH_PROFILE";
//==============================EDIT BASE DATA========================================
export const AFFILIATES_INITIALIZE_BASE_DATA_EDIT_FORM =
  "AFFILIATES_INITIALIZE_BASE_DATA_EDIT_FORM";
export const AFFILIATES_HIDE_BASE_DATA_EDIT_FORM =
  "AFFILIATES_HIDE_BASE_DATA_EDIT_FORM";
export const AFFILIATES_SHOW_BASE_DATA_EDIT_FORM =
  "AFFILIATES_SHOW_BASE_DATA_EDIT_FORM";
export const AFFILIATES_VALIDATE_BASE_DATA_EDIT_FORM =
  "AFFILIATES_VALIDATE_BASE_DATA_EDIT_FORM";
export const AFFILIATES_PROFILE_DATA = "AFFILIATES_PROFILE_DATA";
export const AFFILIATES_UPLOADING_BASE_DATA_EDIT_FORM =
  "AFFILIATES_UPLOADING_BASE_DATA_EDIT_FORM";
export const AFFILIATES_IDLE_BASE_DATA_EDIT_FORM =
  "AFFILIATES_IDLE_BASE_DATA_EDIT_FORM";
export const AFFILIATES_EDIT_BASE_DATA = "AFFILIATES_EDIT_BASE_DATA";
export const AFFILIATES_CLEAN_BASE_EDIT_FORM_ERRORS =
  "AFFILIATES_CLEAN_BASE_EDIT_FORM_ERRORS";
export const AFFILIATES_CLEAN_BASE_DATA_EDIT_FORM =
  "AFFILIATES_CLEAN_BASE_DATA_EDIT_FORM";
export const AFFILIATES_CLEAN_BASIC_BASE_DATA_EDIT_FORM =
  "AFFILIATES_CLEAN_BASIC_BASE_DATA_EDIT_FORM";
export const AFFILIATES_CLEAN_SERVICE_BASE_DATA_EDIT_FORM =
  "AFFILIATES_CLEAN_SERVICE_BASE_DATA_EDIT_FORM";
//==============================ADDRESS==========================================
export const AFFILIATES_TOGGLE_VERIFY_ADDRESS =
  "AFFILIATES_TOGGLE_VERIFY_ADDRESS";
export const AFFILIATES_BEGIN_FETCH_ADDRESS_DATA =
  "AFFILIATES_BEGIN_FETCH_ADDRESS_DATA";
export const AFFILIATES_END_FETCH_ADDRESS_DATA =
  "AFFILIATES_END_FETCH_ADDRESS_DATA";
export const AFFILIATES_FETCH_ADDRESS_POINT = "AFFILIATES_FETCH_ADDRESS_POINT";
export const AFFILIATES_INITIAL_ADDRESS_POINT =
  "AFFILIATES_INITIAL_ADDRESS_POINT";
export const AFFILIATES_BEGIN_FETCH_PROFILE_DATA =
  "AFFILIATES_BEGIN_FETCH_PROFILE_DATA";
export const AFFILIATES_END_FETCH_PROFILE_DATA =
  "AFFILIATES_END_FETCH_PROFILE_DATA";
export const AFFILIATES_CLEAN_ADDRESS_POINT = "AFFILIATES_CLEAN_ADDRESS_POINT";
export const AFFILIATES_INITIAL_VERIFY_STATUS =
  "AFFILIATES_INITIAL_VERIFY_STATUS";
export const AFFILIATES_CLOSE_VERIFY_ADDRESS =
  "AFFILIATES_CLOSE_VERIFY_ADDRESS";

//===================================NATURE OF BIZ===================================
export const AFFILIATES_VIEW_NATURE_OF_BIZ = "AFFILIATES_VIEW_NATURE_OF_BIZ";
export const AFFILIATES_SUBMIT_NATURE_OF_BIZ_DATA =
  "AFFILIATES_SUBMIT_NATURE_OF_BIZ_DATA";
export const AFFILIATES_EDIT_NATURE_OF_BIZ_DATA =
  "AFFILIATES_EDIT_NATURE_OF_BIZ_DATA";
export const AFFILIATES_INITIALIZE_NATURE_DATA_FORM =
  "AFFILIATES_INITIALIZE_NATURE_DATA_FORM";
export const AFFILIATES_EDIT_NATURE_OF_BIZ = "AFFILIATES_EDIT_NATURE_OF_BIZ";
export const AFFILIATES_EDIT_LICENSE_CODE = "AFFILIATES_EDIT_LICENSE_CODE";
export const AFFILIATES_TOGGLE_POSTAL_ADDRESS =
  "AFFILIATES_TOGGLE_POSTAL_ADDRESS";
export const AFFILIATES_UPLOAD_PREFERENCE_IMAGE =
  "AFFILIATES_UPLOAD_PREFERENCE_IMAGE";

// ======================== Service categories =======================
export const AFFILIATES_SELECT_SERVICE_CATEGORY =
  "AFFILIATES_SELECT_SERVICE_CATEGORY";
export const AFFILIATES_SELECT_TRAVEL_SERVICE =
  "AFFILIATES_SELECT_TRAVEL_SERVICE";
export const AFFILIATES_INITIALIZE_TRAVEL_SERVICE_FORM =
  "AFFILIATES_INITIALIZE_TRAVEL_SERVICE_FORM";
export const AFFILIATES_VIEW_SERVICE_CATEGORIES_MODE =
  "AFFILIATES_VIEW_SERVICE_CATEGORIES_MODE";
export const AFFILIATES_EDIT_SERVICE_CATEGORIES_MODE =
  "AFFILIATES_EDIT_SERVICE_CATEGORIES_MODE";
export const AFFILIATES_LOADING_SERVICE_CATEGORY_MODE =
  "AFFILIATES_LOADING_SERVICE_CATEGORY_MODE";
export const AFFILIATES_INITIALIZE_MAIN_SERVICES_FORM =
  "AFFILIATES_INITIALIZE_MAIN_SERVICES_FORM";
export const AFFILIATES_UPDATE_MAIN_SERVICES_FORM =
  "AFFILIATES_UPDATE_MAIN_SERVICES_FORM";
