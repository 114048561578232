import _ from "lodash";
import {
  patchPublicCoachReservation,
  retrievePubCoachReservation,
} from "@src/api/Operation/Reservations/Coach";
import { NormalInputField } from "@src/components/forms";
import { currencies } from "@src/definitions";
import { variables } from "@src/jsssetup";
import { Form, Formik } from "formik";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Loader } from "rsuite";
import { ContactInformation, ReservationInfoCard, Stepper } from "../common";
import {
  genericButtonsStyles,
  genericInfoGroupStyles,
  genericRsrvFeebackStyles,
  rsrvFeebackStyles,
} from "../common/styles";
import { useFeedbackRemarks, useSupplierInfo } from "../common/hooks";
import { cxlRuleTemplate } from "../common/cxl_policies";
import { onReject, onSubmit, payloader, retrieverFn } from "../common/helpers";
import {
  commonValidateFeedback,
  validateSupplierInfo,
} from "../common/validators";
import { toast } from "react-toastify";
import { postReservationFeedback } from "@src/api/Operation/Reservations/feedback";
import { basicInfoStylesObj } from "../accommodation/common";

const basicInfoStyles = createUseStyles({
  ...genericInfoGroupStyles,
  ...genericButtonsStyles,
  BasicInfo: genericRsrvFeebackStyles.card,
  header: genericRsrvFeebackStyles.cardHeader,
  body: genericRsrvFeebackStyles.cardBody,
  groupCard: basicInfoStylesObj.groupCard,
  col2: {
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr)",
    gridGap: variables.normal_gap,
  },
  infoGroup2: {
    "display": "grid",
    "gridTemplateColumns": "repeat(2, 1fr)",
    "gridGap": variables.normal_gap,
    "& input": {
      width: "8rem",
    },
  },
  infoGroup3: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridGap: variables.normal_gap,
  },
  buttonsGroup: {
    display: "grid",
    justifyContent: "end",
  },
  [`@media ${variables.media.bigscreen}`]: {
    col2: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
  },
});
// const basicInfoStyles = createUseStyles({
//   BasicInfo: cardStyles.card,
//   header: cardStyles.header,
//   table: tableStyles.table,
//   head: tableStyles.head,
//   cell: tableStyles.cell,
//   pricecell: {
//     ...tableStyles.cell,
//     "width": "5rem",
//     "& input": {
//       width: "5rem",
//     },
//   },
// });
function BasicInfo({ step, reservation, confirmData, onConfirm }) {
  const [viewMode, setViewMode] = useState(!_.isEmpty(confirmData) || step > 0);
  const classes = basicInfoStyles();

  return (
    <div className={classes.BasicInfo}>
      <div className={classes.header}>
        <h5>Basic Information</h5>
      </div>
      <Formik
        initialValues={{
          price: parseFloat(_.get(reservation, "original_service_price", 0)),
        }}
        onSubmit={(values) => {
          onConfirm(values);
          setViewMode(true);
        }}>
        {({ values }) => (
          <Form className={classes.body}>
            {/* <div className={classes.infoGroup2}> */}
            <React.Fragment>
              <div className={classes.infoGroup2}>
                <div className={classes.groupCard}>
                  <div className={classes.infoCouple}>
                    <span className={classes.infoCoupleTitle}>Origin</span>
                    <span className={classes.infoCoupleValue}>
                      {_.get(reservation, "origin.name_en")}
                    </span>
                  </div>
                  <div className={classes.infoCouple}>
                    <span className={classes.infoCoupleTitle}>Destination</span>
                    <span className={classes.infoCoupleValue}>
                      {_.get(reservation, "destination.name_en")}
                    </span>
                  </div>
                </div>
                <div className={classes.groupCard}>
                  <div className={classes.infoCouple}>
                    <span className={classes.infoCoupleTitle}>
                      Pickup Point
                    </span>
                    <span className={classes.infoCoupleValue}>
                      {reservation.pickup}
                    </span>
                  </div>
                  <div className={classes.infoCouple}>
                    <span className={classes.infoCoupleTitle}>
                      Dropoff Point
                    </span>
                    <span className={classes.infoCoupleValue}>
                      {reservation.dropoff}
                    </span>
                  </div>
                </div>
                <div className={classes.groupCard}>
                  <div className={classes.infoGroup3}>
                    <div className={classes.infoCouple}>
                      <span className={classes.infoCoupleTitle}>Pax</span>
                      <span className={classes.infoCoupleValue}>
                        {reservation.pax}
                      </span>
                    </div>
                    <div className={classes.infoCouple}>
                      <span className={classes.infoCoupleTitle}>Adults</span>
                      <span className={classes.infoCoupleValue}>
                        {reservation.adults}
                      </span>
                    </div>
                    <div className={classes.infoCouple}>
                      <span className={classes.infoCoupleTitle}>Children</span>
                      <span className={classes.infoCoupleValue}>
                        {reservation.children}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
            {/* </div> */}
            <div className={classes.col2}>
              <div className={classes.groupCard}>
                <div className={classes.infoGroup2}>
                  <div className={classes.infoCouple}>
                    <span className={classes.infoCoupleTitle}>Departure</span>
                    <span className={classes.infoCoupleValue}>
                      {DateTime.fromISO(reservation.departure).toLocaleString(
                        DateTime.DATE_MED_WITH_WEEKDAY
                      )}
                    </span>
                  </div>
                  <div className={classes.infoCouple}>
                    <span className={classes.infoCoupleTitle}>Arrival</span>
                    <span className={classes.infoCoupleValue}>
                      {DateTime.fromISO(reservation.arrival).toLocaleString(
                        DateTime.DATE_MED_WITH_WEEKDAY
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className={classes.groupCard}>
                <div className={classes.infoGroup2}>
                  <div className={classes.infoCouple}>
                    <span className={classes.infoCoupleTitle}>Currency</span>
                    <span className={classes.infoCoupleValue}>
                      {reservation.currency} (
                      {_.get(currencies, reservation.currency)})
                    </span>
                  </div>
                  <div className={classes.infoCouple}>
                    <span className={classes.infoCoupleTitle}>Price</span>
                    <span className={classes.infoCoupleValue}>
                      {viewMode ? (
                        <span>{values.price}</span>
                      ) : (
                        <NormalInputField name="price" type="number" />
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {step <= 1 && (
              <div className={classes.buttonsGroup}>
                {viewMode ? (
                  <button
                    className={classes.cancelButton}
                    onClick={(e) => {
                      e.preventDefault();
                      setViewMode(false);
                    }}>
                    <strong>Edit</strong>
                  </button>
                ) : (
                  <button className={classes.confirmButton} type="submit">
                    <strong>Confirm</strong>
                  </button>
                )}
              </div>
            )}
            <div className={classes.col2}>
              <div className={classes.groupCard}>
                <div className={classes.infoGroup2}>
                  <div className={classes.infoCouple}>
                    <span className={classes.infoCoupleTitle}>
                      Company Name
                    </span>
                    <span className={classes.infoCoupleValue}>
                      {reservation.company_name || "N/A"}
                    </span>
                  </div>
                  <div className={classes.infoCouple}>
                    <span className={classes.infoCoupleTitle}>Coach Type</span>
                    <span className={classes.infoCoupleValue}>
                      {_.startCase(reservation.coach_type)}
                    </span>
                  </div>
                </div>
              </div>
              <div className={classes.groupCard}>
                <div className={classes.infoGroup2}>
                  <div className={classes.infoCouple}>
                    <span className={classes.infoCoupleTitle}>Seats</span>
                    <span className={classes.infoCoupleValue}>
                      {reservation.seats}
                    </span>
                  </div>
                  <div className={classes.infoCouple}>
                    <span className={classes.infoCoupleTitle}>Capacity</span>
                    <span className={classes.infoCoupleValue}>
                      {reservation.capacity}
                    </span>
                  </div>
                </div>
              </div>
              <div className={classes.groupCard}>
                <div className={classes.infoGroup2}>
                  <div className={classes.infoCouple}>
                    <span className={classes.infoCoupleTitle}>
                      Parking Fees
                    </span>
                    <span className={classes.infoCoupleValue}>
                      {reservation.parking_fees ? "Yes" : "No"}
                    </span>
                  </div>
                  <div className={classes.infoCouple}>
                    <span className={classes.infoCoupleTitle}>Tipping</span>
                    <span className={classes.infoCoupleValue}>
                      {reservation.tipping ? "Yes" : "No"}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* <table className={classes.table}>
              <thead className={classes.head}>
                <tr>
                  <th>Origin</th>
                  <th>Destination</th>
                  <th>Pickup Point</th>
                  <th>Dropoff Point</th>
                  <th>Pax</th>
                  <th>Adults</th>
                  <th>Children</th>
                  <th>Departure</th>
                  <th>Arrival</th>
                  <th>Currency</th>
                  <th>Price</th>
                  {step !== 2 && <th>Actions</th>}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={classes.cell}>
                    {_.get(reservation, "origin.name_en")}
                  </td>
                  <td className={classes.cell}>
                    {_.get(reservation, "destination.name_en")}
                  </td>
                  <td className={classes.cell}>{reservation.pickup}</td>
                  <td className={classes.cell}>{reservation.dropoff}</td>
                  <td className={classes.cell}>{reservation.pax}</td>
                  <td className={classes.cell}>{reservation.adults}</td>
                  <td className={classes.cell}>{reservation.children}</td>
                  <td className={classes.cell}>
                    {DateTime.fromISO(reservation.departure).toLocaleString(
                      DateTime.DATE_MED_WITH_WEEKDAY
                    )}
                  </td>
                  <td className={classes.cell}>
                    {DateTime.fromISO(reservation.arrival).toLocaleString(
                      DateTime.DATE_MED_WITH_WEEKDAY
                    )}
                  </td>
                  <td className={classes.cell}>
                    {reservation.currency} (
                    {_.get(currencies, reservation.currency)})
                  </td>
                  <td className={classes.pricecell}>
                    {viewMode ? (
                      <span>{values.price}</span>
                    ) : (
                      <NormalInputField name="price" type="number" />
                    )}
                  </td>
                  {step !== 2 && (
                    <td className={classes.cell}>
                      {viewMode ? (
                        <Button
                          color="blue"
                          size="xs"
                          onClick={(e) => {
                            e.preventDefault();
                            setViewMode(false);
                          }}>
                          <strong>Edit</strong>
                        </Button>
                      ) : (
                        <Button color="green" size="xs" type="submit">
                          <strong>Confirm</strong>
                        </Button>
                      )}
                    </td>
                  )}
                </tr>
              </tbody>
            </table>
            <table className={classes.table}>
              <thead className={classes.head}>
                <tr>
                  <th>Company Name</th>
                  <th>Coach Type</th>
                  <th>Seats</th>
                  <th>Capacity</th>
                  <th>Parking Fees</th>
                  <th>Tipping</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={classes.cell}>
                    {reservation.company_name || "N/A"}
                  </td>
                  <td className={classes.cell}>
                    {_.startCase(reservation.coach_type)}
                  </td>
                  <td className={classes.cell}>{reservation.seats}</td>
                  <td className={classes.cell}>{reservation.capacity}</td>
                  <td className={classes.cell}>
                    {reservation.parking_fees ? "Yes" : "No"}
                  </td>
                  <td className={classes.cell}>
                    {reservation.tipping ? "Yes" : "No"}
                  </td>
                </tr>
              </tbody>
            </table> */}
          </Form>
        )}
      </Formik>
    </div>
  );
}
BasicInfo.propTypes = {
  step: PropTypes.number.isRequired,
  reservation: PropTypes.object.isRequired,
  confirmData: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

function validateFeedback({
  cxl_remarks,
  booking_mode_remarks,
  reservation_remarks,
  payment_mode_remarks,
  request_cxl_policy,
  cxl_rules,
}) {
  const msgs = commonValidateFeedback({
    cxl_remarks,
    booking_mode_remarks,
    reservation_remarks,
    payment_mode_remarks,
    request_cxl_policy,
    cxl_rules,
  });

  return msgs;
}

const CoachResFeedbackStyles = createUseStyles({
  CoachResFeedback: rsrvFeebackStyles,
  ...genericRsrvFeebackStyles,
});
const CoachResFeedback = () => {
  const classes = CoachResFeedbackStyles();
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const feedRemarks = useFeedbackRemarks();
  const supplierInfo = useSupplierInfo();

  const [reservation, setReservation] = useState({});
  const [cxl_rules, setCxlRules] = useState([cxlRuleTemplate]);
  const [confirmData, setConfirmData] = useState({});

  const history = useHistory();

  const {
    params: { reference },
  } = useRouteMatch();

  function nextStepper() {
    var msgs = [];
    if (step === 0) {
      msgs = validateFeedback({
        cxl_remarks: feedRemarks.cxl_remarks,
        booking_mode_remarks: feedRemarks.booking_mode_remarks,
        reservation_remarks: feedRemarks.reservation_remarks,
        payment_mode_remarks: feedRemarks.payment_mode_remarks,
        request_cxl_policy: reservation.request_cxl_policy,
        cxl_rules,
      });
    } else if (step === 1) {
      msgs = validateSupplierInfo({
        supplier_name: supplierInfo.supplier_name,
        supplier_email: supplierInfo.supplier_email,
        supplier_phone: supplierInfo.supplier_phone,
      });
    }
    if (msgs.length) {
      msgs.forEach((error) => toast.error(error, { duration: 5000 }));
      return;
    }

    setStep((p) => p + 1);
  }

  useEffect(() => {
    if (reference) {
      retrieverFn({
        reference,
        fn: retrievePubCoachReservation,
        setLoading,
        setReservation,
      });
    }
  }, [reference]);

  const actionProps = {
    step,
    prevStepper: () => setStep((p) => p - 1),
    nextStepper,
    onSubmit: () => {
      const payload = payloader({
        supplierInfo,
        feedRemarks,
        cxl_rules,
        reference: reservation.reference,
        feedback_service_data: {
          basic_info: confirmData,
        },
        reservation_type: "COA",
      });

      onSubmit({
        history,
        payload,
        setLoading,
        postReservationFeedback: (payload) =>
          postReservationFeedback(payload, () =>
            toast.error(
              "Reservation feedback creation failed. We are sorry for the inconvenience.",
              { duration: 5000 }
            )
          ),
      });
    },
    onReject: () =>
      onReject({
        history,
        reference,
        payload: { status: "RJ" },
        setLoading,
        resPatchFunc: patchPublicCoachReservation,
      }),
  };

  return (
    <div className={classes.CoachResFeedback}>
      <Stepper step={step} />
      {loading ? (
        <div className={classes.cardBody}>
          <Loader size="lg" center />
        </div>
      ) : [0, 2].includes(step) ? (
        <ReservationInfoCard
          reservation={reservation}
          actionProps={actionProps}
          feedRemarks={feedRemarks}
          editable={step === 0}
          cxl_rules={cxl_rules}
          setCxlRules={setCxlRules}>
          <BasicInfo
            step={step}
            reservation={reservation}
            confirmData={confirmData}
            onConfirm={(data) => setConfirmData((p) => ({ ...p, ...data }))}
          />
        </ReservationInfoCard>
      ) : (
        <ContactInformation
          {...supplierInfo}
          actionProps={actionProps}
          onChange={(values) => {
            supplierInfo.setSupplierName(values.supplier_name);
            supplierInfo.setSupplierEmail(values.supplier_email);
            supplierInfo.setSupplierPhone(values.supplier_phone);
            supplierInfo.setSupplierRemarks(values.supplier_remarks);
          }}
        />
      )}
    </div>
  );
};

export default CoachResFeedback;
