import _ from "lodash";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { JssProvider, createUseStyles } from "react-jss";
import { labeledTagStyles, variables } from "@src/jsssetup";
import { connect, useSelector } from "react-redux";
import { ADDON_CATEGORIES } from "../SetupController";
import {
  Checkbox,
  CheckPicker,
  Icon,
  IconButton,
  Input,
  Placeholder,
  SelectPicker,
  Tag,
} from "rsuite";
import {
  changeBaseInfo,
  showAddonImagesModal,
  showGenDescriptionModal,
} from "@src/actions/Operation/CustomServices/AddOn";
import {
  addOnHasDescriptionSelector,
  addOnProvidersSelector,
} from "@src/selectors/Operation/CustomServices/AddOnSelectors";
import LeanAuthHoc from "@src/components/authorization/LeanAuthHoc";
import { AddonModalsContext } from "../../AddOn";
import { NormalInputField, NormalSelectField } from "@src/components/forms";
import { Form, Formik } from "formik";

const inputGroupStyles = {
  "display": "grid",
  "gridGap": `calc(${variables.normal_gap} / 2)`,
  "& .rs-picker-check": {
    display: "grid",
  },
};

const websitesSelectorStyles = createUseStyles({
  inputgroup: { ...inputGroupStyles },
});
var WebsitesSelector = (props) => {
  const { data, value, onChange } = props;
  const classes = websitesSelectorStyles();

  return (
    <div className={classes.inputgroup}>
      <span>
        <strong>Website</strong>
      </span>
      <CheckPicker
        data={data}
        value={value}
        searchable={false}
        onChange={function (value) {
          onChange(value);
        }}
      />
    </div>
  );
};
WebsitesSelector.propTypes = {
  data: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
WebsitesSelector = LeanAuthHoc(WebsitesSelector, {
  entity_allowed_groups: ["has_b2c"],
});

const basicInfoStyles = createUseStyles({
  BasicInfo: {
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateColumns: "1fr",
    borderLeft: (props) =>
      props.currentStep > 1
        ? `2px solid ${variables.colors.lightBorderFillColor}`
        : "unset",
    borderRight: (props) =>
      props.currentStep === 2
        ? `2px solid ${variables.colors.lightBorderFillColor}`
        : "unset",
    padding: `0 calc(${variables.normal_gap} / 2)`,
  },
  content: {
    display: "grid",
    alignContent: "center",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    alignItems: "end",
  },
  contentTop: {
    display: "grid",
    alignContent: "center",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    gridTemplateColumns: "max-content repeat(4, 1fr)",
  },
  contentBottom: {
    display: "grid",
    alignContent: "center",
    gridGap: `calc(${variables.normal_gap} / 2)`,
    gridAutoFlow: "column",
  },
  inputgroup: {
    ...inputGroupStyles,
    "& .rs-picker-input": {
      maxHeight: "2rem",
    },
  },
  title_inputgroup: {
    ...inputGroupStyles,
  },
  grid2: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: `calc(${variables.normal_gap} / 2)`,
  },
  tag: { ...labeledTagStyles, alignSelf: "start" },
});
const BasicInfo = ({
  currentStep,
  hasImages,
  hasDescriptions,
  websitesData,
  internal_rating,
  onChange,
  onGenDescription,
}) => {
  const { setShowAddonImgsModal } = useContext(AddonModalsContext);

  const initialCategories = [...ADDON_CATEGORIES];

  const {
    title,
    subtitle,
    categories,
    status,
    booking_mode,
    reference,
    websites = [],
    access,
    themes,
    required_guest_ages,
    room_selection_type,
    loading,
  } = useSelector((state) => state.customServiceAddOn);

  const classes = basicInfoStyles({ currentStep, reference });
  return (
    <JssProvider classNamePrefix="BasicInfo">
      <div className={classes.BasicInfo}>
        <div className={classes.content}>
          <div className={classes.contentTop}>
            {loading ? (
              <React.Fragment>
                <div />
                <Placeholder.Paragraph rows={1} active={true} />
                <Placeholder.Paragraph rows={1} active={true} />
                <Placeholder.Paragraph rows={1} active={true} />
                <Placeholder.Paragraph rows={1} active={true} />
              </React.Fragment>
            ) : (
              <React.Fragment>
                {reference && (
                  <span className={classes.tag}>
                    <strong>Reference</strong>
                    <strong>{reference}</strong>
                  </span>
                )}
                <div className={classes.title_inputgroup}>
                  <span>
                    <strong>Title</strong>
                  </span>
                  <Input
                    value={title}
                    onChange={(value) => onChange("title", value)}
                  />
                </div>
                <div className={classes.inputgroup}>
                  <span>
                    <strong>Subtitle</strong>
                  </span>
                  <Input
                    value={subtitle}
                    onChange={(value) => onChange("subtitle", value)}
                  />
                </div>
                {currentStep === 1 && (
                  <React.Fragment>
                    <Formik
                      initialValues={{ themes: themes.join(", ") }}
                      validate={(values) =>
                        onChange(
                          "themes",
                          values.themes.split(",").map((th) => th.trim())
                        )
                      }>
                      <Form>
                        <NormalInputField label="Themes" name="themes" />
                      </Form>
                    </Formik>
                    <div className={classes.inputgroup}>
                      <span>
                        <strong>Category</strong>
                      </span>
                      <CheckPicker
                        groupBy="role"
                        data={initialCategories}
                        value={categories}
                        onChange={(value) => onChange("categories", value)}
                        renderExtraFooter={() => (
                          <div className="picker-footer">
                            <Checkbox
                              inline
                              indeterminate={
                                categories.length > 0 &&
                                categories.length != initialCategories.length
                              }
                              checked={
                                categories.length == initialCategories.length
                              }
                              onChange={() =>
                                onChange(
                                  "categories",
                                  categories.length == initialCategories.length
                                    ? []
                                    : initialCategories.map((c) => c.value)
                                )
                              }>
                              Check all
                            </Checkbox>
                          </div>
                        )}
                      />
                    </div>
                  </React.Fragment>
                )}
                {currentStep !== 1 && (
                  <React.Fragment>
                    <Formik
                      initialValues={{ required_guest_ages }}
                      validate={(values) =>
                        onChange(
                          "required_guest_ages",
                          values.required_guest_ages
                        )
                      }>
                      <Form>
                        <NormalSelectField
                          name="required_guest_ages"
                          label="Required Guest Ages"
                          options={[
                            ["ADT", "Adults Only"],
                            ["CHD", "Children Only"],
                            ["ALL", "All Guest Types"],
                          ]}
                        />
                      </Form>
                    </Formik>
                    <Formik
                      initialValues={{ room_selection_type }}
                      validate={(values) =>
                        onChange(
                          "room_selection_type",
                          values.room_selection_type
                        )
                      }>
                      <Form>
                        <NormalSelectField
                          name="room_selection_type"
                          label="Room Selection"
                          options={[
                            ["SPE", "Specific Rooms"],
                            ["ABS", "Abstract Rooms"],
                            ["NOR", "No Rooms"],
                          ]}
                        />
                      </Form>
                    </Formik>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </div>
          <div className={classes.contentBottom}>
            {loading ? (
              <React.Fragment>
                <Placeholder.Paragraph rows={1} active={true} />
                <Placeholder.Paragraph rows={1} active={true} />
                <Placeholder.Paragraph rows={1} active={true} />
                <Placeholder.Paragraph rows={1} active={true} />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className={classes.inputgroup}>
                  <span>
                    <strong>Descriptions</strong>
                  </span>
                  <IconButton
                    icon={<Icon icon={hasDescriptions ? "edit" : "plus"} />}
                    color={hasDescriptions ? "blue" : "green"}
                    onClick={onGenDescription}>
                    {hasDescriptions ? "Edit" : "Add"}
                  </IconButton>
                </div>
                <div className={classes.inputgroup}>
                  <span>
                    <strong>Add On Images</strong>
                  </span>
                  <IconButton
                    icon={<Icon icon="camera" />}
                    color={hasImages ? "blue" : "green"}
                    onClick={() => setShowAddonImgsModal(true)}>
                    {hasImages ? "Edit" : "Add"}
                  </IconButton>
                </div>
                {currentStep === 1 && (
                  <Formik
                    initialValues={{ internal_rating }}
                    validate={(values) =>
                      onChange("internal_rating", values.internal_rating)
                    }>
                    <Form>
                      <NormalSelectField
                        name="internal_rating"
                        label="Internal Rating"
                        options={[
                          [1, "1 Star"],
                          [2, "2 Stars"],
                          [3, "3 Stars"],
                          [4, "4 Stars"],
                          [5, "5 Stars"],
                        ]}
                      />
                    </Form>
                  </Formik>
                )}
                {currentStep !== 2 && (
                  <WebsitesSelector
                    data={websitesData}
                    value={websites}
                    onChange={(value) => onChange("websites", value)}
                  />
                )}
                <div className={classes.inputgroup}>
                  <span>
                    <strong>Booking Mode</strong>
                  </span>
                  <SelectPicker
                    searchable={false}
                    value={booking_mode}
                    data={[
                      { label: "Confirm", value: "CO" },
                      { label: "On Request", value: "RQ" },
                      { label: "Redirect", value: "RD" },
                    ]}
                    onChange={(value) => onChange("booking_mode", value)}
                    renderValue={(value, item) => (
                      <Tag
                        color={
                          _.get(item, "value") === "RQ"
                            ? "orange"
                            : _.get(item, "value") === "RD"
                            ? "blue"
                            : "green"
                        }>
                        <strong>{_.get(item, "label")}</strong>
                      </Tag>
                    )}
                    renderMenuItem={(label, item) => (
                      <Tag
                        color={
                          _.get(item, "value") === "RQ"
                            ? "orange"
                            : _.get(item, "value") === "RD"
                            ? "blue"
                            : "green"
                        }>
                        <strong>{_.get(item, "label")}</strong>
                      </Tag>
                    )}
                  />
                </div>
                <div className={classes.inputgroup}>
                  <span>
                    <strong>Access</strong>
                  </span>
                  <SelectPicker
                    cleanable={false}
                    searchable={false}
                    value={access}
                    data={[
                      { label: "Public", value: "PUB" },
                      { label: "Private", value: "PRI" },
                    ]}
                    onChange={(value) => onChange("access", value)}
                  />
                </div>
                <div className={classes.inputgroup}>
                  <span>
                    <strong>Status</strong>
                  </span>
                  <SelectPicker
                    searchable={false}
                    value={status}
                    data={[
                      { label: "Available", value: "AV" },
                      { label: "Unavailable", value: "UV" },
                    ]}
                    onChange={(value) => onChange("status", value)}
                    renderValue={(value, item) => (
                      <Tag color={item.value == "UV" ? "red" : "green"}>
                        <strong>{item.label}</strong>
                      </Tag>
                    )}
                    renderMenuItem={(label, item) => (
                      <Tag color={item.value == "UV" ? "red" : "green"}>
                        <strong>{item.label}</strong>
                      </Tag>
                    )}
                  />
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </JssProvider>
  );
};
BasicInfo.defaultProps = {
  booking_mode: "CO",
};
BasicInfo.propTypes = {
  currentStep: PropTypes.number.isRequired,
  hasDescriptions: PropTypes.bool.isRequired,
  hasImages: PropTypes.bool.isRequired,
  websitesData: PropTypes.array.isRequired,
  internal_rating: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onGenDescription: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  const hasDescriptions = addOnHasDescriptionSelector(state);
  const hasImages =
    _.get(state.customServiceAddOn, "custom_images", []).length > 0;
  const websitesData = state.b2cWebsiteData;

  const internal_rating = state.customServiceAddOn.internal_rating;

  return {
    hasDescriptions,
    hasImages,
    websitesData,
    internal_rating,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onChange: (key, value) => dispatch(changeBaseInfo(key, value)),
    onGenDescription: () => dispatch(showGenDescriptionModal()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BasicInfo);
