import {
  // ========================== INIT ACTIONS =========================
  TRIPPLANNER_INIT,
  // ======================= TRIP LOAD ACTIONS =======================
  TRIPPLANNER_LOAD_TRIP,
  // ===================== OVERVIEW TRANSPORTATION =====================
  TRIPPLANNER_VIEW_ALL_TRP,
  TRIPPLANNER_HIDE_ALL_TRP,
  // ======================= OVERVIEW TRANSFERS ======================
  TRIPPLANNER_VIEW_ALL_TRP_LOADING,
  TRIPPLANNER_VIEW_ALL_TRP_IDLE,
} from "@src/actions/Project/TripPlanner/types";

import update from "immutability-helper";

const tripPlannerViewAllFlightsInitial = {
  session_id: "",
  api_key: "",
  page_size: "",
  ordering: "",
};
export const tripPlannerViewAllFlights = (
  state = tripPlannerViewAllFlightsInitial,
  action
) => {
  switch (action.type) {
    case TRIPPLANNER_INIT:
      return tripPlannerViewAllFlightsInitial;
    case TRIPPLANNER_LOAD_TRIP:
      return tripPlannerViewAllFlightsInitial;
    case TRIPPLANNER_VIEW_ALL_TRP:
      return update(state, {
        trpType: { $set: action.trpType },
        extremal: { $set: action.extremal },
        returnExtremal: { $set: action.returnExtremal },
        destOrder: { $set: action.destOrder },
        show: { $set: true },
        uid: { $set: null },
      });
    case TRIPPLANNER_HIDE_ALL_TRP:
      return update(state, { show: { $set: false } });
    case TRIPPLANNER_VIEW_ALL_TRP_LOADING:
      return { ...state, loading: true };
    case TRIPPLANNER_VIEW_ALL_TRP_IDLE:
      return { ...state, loading: false };
    default:
      return state;
  }
};
