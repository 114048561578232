export const B2C_SUB_ENTITIES_STATEMENT_INIT =
  "B2C_SUB_ENTITIES_STATEMENT_INIT";

// ========================= DEPOSIT BALANCE =========================
export const B2C_SUB_ENTITIES_STATEMENT_FETCHING_DEPOSIT_BALANCE =
  "B2C_SUB_ENTITIES_STATEMENT_FETCHING_DEPOSIT_BALANCE";
export const B2C_SUB_ENTITIES_STATEMENT_FETCHED_DEPOSIT_BALANCE =
  "B2C_SUB_ENTITIES_STATEMENT_FETCHED_DEPOSIT_BALANCE";
export const B2C_SUB_ENTITIES_STATEMENT_SET_DEPOSIT_BALANCE =
  "B2C_SUB_ENTITIES_STATEMENT_SET_DEPOSIT_BALANCE";

// ========================= DEPOSIT ENTRIES =========================
export const B2C_SUB_ENTITIES_STATEMENT_SET_DEPOSIT_ENTRIES =
  "B2C_SUB_ENTITIES_STATEMENT_SET_DEPOSIT_ENTRIES";
export const B2C_SUB_ENTITIES_STATEMENT_FETCHING_DEPOSIT_ENTRIES =
  "B2C_SUB_ENTITIES_STATEMENT_FETCHING_DEPOSIT_ENTRIES";
export const B2C_SUB_ENTITIES_STATEMENT_FETCHED_DEPOSIT_ENTRIES =
  "B2C_SUB_ENTITIES_STATEMENT_FETCHED_DEPOSIT_ENTRIES";

export const B2C_SUB_ENTITIES_STATEMENT_VIEW_TYPE =
  "B2C_SUB_ENTITIES_STATEMENT_VIEW_TYPE";
export const B2C_SUB_ENTITIES_STATEMENT_STAT_DATA =
  "B2C_SUB_ENTITIES_STATEMENT_STAT_DATA";
export const B2C_SUB_ENTITIES_STATEMENT_TAG_TYPE =
  "B2C_SUB_ENTITIES_STATEMENT_TAG_TYPE";
export const B2C_SUB_ENTITIES_STATEMENT_INIT_VIEW_TYPE =
  "B2C_SUB_ENTITIES_STATEMENT_INIT_VIEW_TYPE";
export const B2C_SUB_ENTITIES_STATEMENT_INIT_TAG_TYPE =
  "B2C_SUB_ENTITIES_STATEMENT_INIT_TAG_TYPE";
export const B2C_SUB_ENTITIES_STATEMENT_FETCHING_STAT_DATA =
  "B2C_SUB_ENTITIES_STATEMENT_FETCHING_STAT_DATA";
export const B2C_SUB_ENTITIES_STATEMENT_FETCHED_STAT_DATA =
  "B2C_SUB_ENTITIES_STATEMENT_FETCHED_STAT_DATA";

// ========================== DEPOSIT MODAL ==========================
export const B2C_SUB_ENTITIES_STATEMENT_SHOW_DEPOSIT_MODAL =
  "B2C_SUB_ENTITIES_STATEMENT_SHOW_DEPOSIT_MODAL";
export const B2C_SUB_ENTITIES_STATEMENT_HIDE_DEPOSIT_MODAL =
  "B2C_SUB_ENTITIES_STATEMENT_HIDE_DEPOSIT_MODAL";
export const B2C_SUB_ENTITIES_STATEMENT_IDLE_DEPOSIT_MODAL =
  "B2C_SUB_ENTITIES_STATEMENT_IDLE_DEPOSIT_MODAL";
export const B2C_SUB_ENTITIES_STATEMENT_STATEMENT_LOADING_DEPOSIT_MODAL =
  "B2C_SUB_ENTITIES_STATEMENT_STATEMENT_LOADING_DEPOSIT_MODAL";

//========================= Filter =================================
export const B2C_SUB_ENTITIES_STATEMENT_INITIALIZE_LIST_FILTER_FORM =
  "B2C_SUB_ENTITIES_STATEMENT_STATEMENT_INITIALIZE_LIST_FILTER_FORM";
export const B2C_SUB_ENTITIES_STATEMENT_UPDATE_LIST_FILTER_FORM =
  "B2C_SUB_ENTITIES_STATEMENT_UPDATE_LIST_FILTER_FORM";
export const B2C_SUB_ENTITIES_STATEMENT_APPLY_LIST_FILTER =
  "B2C_SUB_ENTITIES_STATEMENT_STATEMENT_APPLY_LIST_FILTER";
