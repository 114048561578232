export const COMPANY_START_FETCH_PROFILE = "COMPANY_START_FETCH_PROFILE";
export const COMPANY_END_FETCH_PROFILE = "COMPANY_END_FETCH_PROFILE";
export const COMPANY_SET_PROFILE_DATA = "COMPANY_SET_PROFILE_DATA";

export const COMPANY_SHOW_BASE_DATA_EDIT_FORM =
  "COMPANY_SHOW_BASE_DATA_EDIT_FORM";
export const COMPANY_HIDE_BASE_DATA_EDIT_FORM =
  "COMPANY_HIDE_BASE_DATA_EDIT_FORM";
export const INITIALIZE_BASE_DATA_EDIT_FORM = "INITIALIZE_BASE_DATA_EDIT_FORM";

export const COMPANY_EDIT_BASE_DATA = "COMPANY_EDIT_BASE_DATA";

export const COMPANY_VALIDATE_BASE_DATA_EDIT_FORM =
  "COMPANY_VALIDATE_BASE_DATA_EDIT_FORM";
export const COMPANY_CLEAN_BASE_EDIT_FORM_ERRORS =
  "COMPANY_CLEAN_BASE_EDIT_FORM_ERRORS";

export const COMPANY_LOADING_BASE_DATA_EDIT_FORM =
  "COMPANY_LOADING_BASE_DATA_EDIT_FORM";
export const COMPANY_IDLE_BASE_DATA_EDIT_FORM =
  "COMPANY_IDLE_BASE_DATA_EDIT_FORM";

export const COMPANY_VIEW_NATURE_OF_BIZ = "COMPANY_VIEW_NATURE_OF_BIZ";
export const COMPANY_EDIT_NATURE_OF_BIZ = "COMPANY_EDIT_NATURE_OF_BIZ";

export const COMPANY_EDIT_NATURE_OF_BIZ_DATA =
  "COMPANY_EDIT_NATURE_OF_BIZ_DATA";
export const COMPANY_INITIALIZE_NATURE_DATA_FORM =
  "COMPANY_INITIALIZE_NATURE_DATA_FORM";
export const COMPANY_SUBMIT_NATURE_OF_BIZ_DATA =
  "COMPANY_SUBMIT_NATURE_OF_BIZ_DATA";
export const COMPANY_EDIT_LICENSE_CODE = "COMPANY_EDIT_LICENSE_CODE";
export const COMPANY_TOGGLE_BILLING_ADDRESS = "COMPANY_TOGGLE_BILLING_ADDRESS";
export const COMPANY_CLEAN_BASE_DATA_EDIT_FORM =
  "COMPANY_CLEAN_BASE_DATA_EDIT_FORM";
export const COMPANY_FETCH_OPTIONS = "COMPANY_FETCH_OPTIONS";

export const COMPANY_START_FETCH_ADDRESS = "COMPANY_START_FETCH_ADDRESS";
export const COMPANY_END_FETCH_ADDRESS = "COMPANY_END_FETCH_ADDRESS";
export const COMPANY_START_FETCH_CONTACT_PERSON =
  "COMPANY_START_FETCH_CONTACT_PERSON";
export const COMPANY_END_FETCH_CONTACT_PERSON =
  "COMPANY_END_FETCH_CONTACT_PERSON";

// img relation
export const COMPANY_UPLOADED_PREFERENCE_IMAGE =
  "COMPANY_UPLOADED_PREFERENCE_IMAGE";
export const COMPANY_BEGIN_UPLOAD_IMG_LINK = "COMPANY_BEGIN_UPLOAD_IMG_LINK";
export const COMPANY_ENG_UPLOAD_IMG_LINK = "COMPANY_ENG_UPLOAD_IMG_LINK";

// ======================== Service categories =======================
export const COMPANY_SELECT_SERVICE_CATEGORY =
  "COMPANY_SELECT_SERVICE_CATEGORY";
export const COMPANY_SELECT_TRAVEL_SERVICE = "COMPANY_SELECT_TRAVEL_SERVICE";
export const COMPANY_INITIALIZE_TRAVEL_SERVICE_FORM =
  "COMPANY_INITIALIZE_TRAVEL_SERVICE_FORM";

export const COMPANY_VIEW_SERVICE_CATEGORIES_MODE =
  "COMPANY_VIEW_SERVICE_CATEGORIES_MODE";
export const COMPANY_EDIT_SERVICE_CATEGORIES_MODE =
  "COMPANY_EDIT_SERVICE_CATEGORIES_MODE";
export const COMPANY_LOADING_SERVICE_CATEGORY_MODE =
  "COMPANY_LOADING_SERVICE_CATEGORY_MODE";
export const COMPANY_INITIALIZE_MAIN_SERVICES_FORM =
  "COMPANY_INITIALIZE_MAIN_SERVICES_FORM";
export const COMPANY_UPDATE_MAIN_SERVICES_FORM =
  "COMPANY_UPDATE_MAIN_SERVICES_FORM";
export const COMPANY_FETCH_ADDRESS_POINT = "COMPANY_FETCH_ADDRESS_POINT";
export const COMPANY_TOGGLE_GEOLOCATION = "COMPANY_TOGGLE_GEOLOCATION";
export const COMPANY_INITIAL_ADDRESS_POINT = "COMPANY_INITIAL_ADDRESS_POINT";
export const COMPANY_TOGGLE_VERIFY_ADDRESS = "COMPANY_TOGGLE_VERIFY_ADDRESS";
export const COMPANY_BEGIN_FETCH_ADDRESS_DATA =
  "COMPANY_BEGIN_FETCH_ADDRESS_DATA";
export const COMPANY_END_FETCH_ADDRESS_DATA = "COMPANY_END_FETCH_ADDRESS_DATA";
export const COMPANY_CLOSE_VERIFY_ADDRESS = "COMPANY_CLOSE_VERIFY_ADDRESS";
