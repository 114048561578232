import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import v4 from "uuid";

import {
  Panel,
  Form,
  FormGroup,
  ControlLabel,
  HelpBlock,
  FormControl,
  SelectPicker,
} from "rsuite";

import { createUseStyles } from "react-jss";
import { variables } from "@src/jsssetup";
import { connect } from "react-redux";
import InteractiveMapHoc from "@src/pages/Project/TripPlan/InteractiveMapHoc";
const remark =
  "https://sisistaticassets.s3.ap-southeast-1.amazonaws.com/platform_graphics/imgs/others/remark.png";

const mapStyles = createUseStyles({
  panel: {
    "display": "grid",
    "gridTemplateRows": "auto 1fr",
    "& .rs-panel-body": {
      display: "grid",
      height: "100%",
      gridGap: variables.normal_gap,
    },
  },
  map: {
    height: "100%",
    borderRadius: "5px",
  },
  geocoder: {
    "width": "100%",
    "& .mapboxgl-ctrl-geocoder": { width: "100%", margin: 0 },
  },
});
var Map = (props) => {
  const { mapCenterLocation, version, map, onCoordsChange } = props;
  const classes = mapStyles();

  useEffect(() => {
    if (typeof map !== "undefined" && map !== null && map.loaded()) {
      const geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        flyTo: { speed: 3 },
        marker: false,
      });
      geocoder.on("result", (result) => {
        const {
          result: { geometry },
        } = result;

        onCoordsChange({ geometry });
        map.getSource("point-data").setData({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: geometry.coordinates,
          },
        });
      });

      document.getElementById("geocoder").appendChild(geocoder.onAdd(map));

      map.on("click", (e) => {
        var coordinates = e.lngLat;

        map.getSource("point-data").setData({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [coordinates.lng, coordinates.lat],
          },
        });
        const geometry = {
          type: "Point",
          coordinates: [coordinates.lng, coordinates.lat],
        };
        onCoordsChange({ geometry });
      });

      map.loadImage(remark, (error, image) => {
        if (error) return;
        map.addImage("remark", image);
      });

      map.addSource("point-data", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [
              _.get(mapCenterLocation, "lng", 0),
              _.get(mapCenterLocation, "lat", 0),
            ],
          },
        },
      });

      map.addLayer({
        id: "point",
        source: "point-data",
        type: "symbol",
        layout: {
          "icon-image": "remark",
          "icon-size": 0.1,
        },
      });
    }
  }, [version]);

  return (
    <Panel
      className={`CustomPanel ${classes.panel}`}
      header={<h6>Map</h6>}
      shaded>
      <div id="geocoder" className={classes.geocoder}></div>
      <div id="itemmap" className={classes.map}></div>
    </Panel>
  );
};
Map.defaultProps = {
  onCoordsChange: ({ geometry }) => console.log(geometry),
};
Map.propTypes = {
  map: PropTypes.object.isRequired,
  version: PropTypes.number.isRequired,
  mapCenterLocation: PropTypes.object.isRequired,
  onCoordsChange: PropTypes.func.isRequired,
};
Map = InteractiveMapHoc(Map, { containerId: "itemmap" });

const basicInfoStyles = createUseStyles({
  BasicInfo: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: variables.normal_gap,
    padding: variables.normal_gap,
  },
  form: {
    "display": "grid",
    "gridTemplateColumns": "repeat(2, 1fr)",
    "gridGap": variables.normal_gap,
    "& .rs-picker-select": {
      width: "100%",
    },
  },
});
const BasicInfo = ({ data, countries, onDataChange }) => {
  const {
    query_value,
    name_en,
    geodata: { lat, lng },
    country,
  } = data;

  const country_code = _.get(data, "country.code");
  const [formData, setFormData] = useState({
    query_value,
    name_en,
    country_code,
    country,
    lat,
    lng,
  });

  const transformData = (data) => ({
    query_value: data.name_en,
    name_en: data.name_en,
    geodata: { lat: data.lat, lng: data.lng },
    country: data.country,
    custom: true,
    uid: v4(),
    id: null,
  });

  const classes = basicInfoStyles();
  return (
    <div className={classes.BasicInfo}>
      <Panel className="CustomPanel" shaded header={<h6>Basic Info</h6>}>
        <Form
          className={classes.form}
          fluid={true}
          formValue={formData}
          onChange={function (value) {
            setFormData((formData) => {
              const newState = { ...formData, ...value };

              const country = countries.find(
                (country) => country.code === value.country_code
              );
              if (country) {
                newState["country"] = country;
              }

              onDataChange(transformData(newState));

              return newState;
            });
          }}>
          <FormGroup>
            <ControlLabel>
              <strong>Country</strong>
            </ControlLabel>
            <FormControl
              name="country_code"
              accepter={SelectPicker}
              data={countries.map((country) => ({
                label: country.name_en,
                value: country.code,
              }))}
            />
            <HelpBlock>Required</HelpBlock>
          </FormGroup>
          <FormGroup>
            <ControlLabel>
              <strong>Name</strong>
            </ControlLabel>
            <FormControl name="name_en" />
            <HelpBlock>Required</HelpBlock>
          </FormGroup>
          <FormGroup>
            <ControlLabel>
              <strong>Latitude</strong>
            </ControlLabel>
            <FormControl name="lat" />
          </FormGroup>
          <FormGroup>
            <ControlLabel>
              <strong>Longitude</strong>
            </ControlLabel>
            <FormControl name="lng" />
          </FormGroup>
        </Form>
      </Panel>
      <Map
        mapCenterLocation={{
          lat: _.get(data, "geodata.lat", 0),
          lng: _.get(data, "geodata.lng", 0),
        }}
        onCoordsChange={function ({ geometry }) {
          const lng = geometry.coordinates[0];
          const lat = geometry.coordinates[1];
          setFormData((formData) => {
            const newState = { ...formData, lat, lng };
            onDataChange(transformData(newState));
            return newState;
          });
        }}
      />
    </div>
  );
};
BasicInfo.propTypes = {
  countries: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  itemOrder: PropTypes.number.isRequired,
  onDataChange: PropTypes.func.isRequired,
};
const mapStateToProps = (state, ownProps) => {
  const { itemOrder } = ownProps;
  const { data } = _.get(state, `customServiceAddOnItems.${itemOrder - 1}`, {});

  const countries = _.sortBy(state.countryCodeInfo, "name_en");
  return { countries, data };
};
export default connect(mapStateToProps, null)(BasicInfo);
