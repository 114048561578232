import service from "@src/api/request";

export function resendEmail(srvType, reference, email_type) {
  console.log("resendEmail");
  var baseUrl = "";
  switch (srvType) {
    case "ACC":
      baseUrl = "accreservations";
      break;
    case "FL":
      baseUrl = "flightreservation";
      break;
    case "TRF":
      baseUrl = "transferreservation";
      break;
    case "MI":
      baseUrl = "addonreservation";
      break;
  }

  return service({
    url: `bid/${baseUrl}/${reference}/email-sender/`,
    method: "post",
    data: { email_type },
  });
}
