import {
  Panel,
  Icon,
  Slider,
  Tag,
  SelectPicker,
  DatePicker,
  IconButton,
  InputNumber,
  Toggle,
} from "rsuite";

import { DestAutoComplete } from "@src/pages/Project/TripPlan/Controllers/DestAutoComplete";

import { DestinationImage } from "@src/pages/Project/TripPlan/Controllers/ItineraryController";

import {
  WhisperHelper,
  WhisperWrapper,
} from "@src/components/common/ui_helpers";
import PoiAutoComplete from "@src/pages/Project/TripPlan/Components/PoiAutoComplete";

// ============================ SELECTORS ============================
import {
  getItemSelector,
  getItemIdxsSelector,
} from "@src/selectors/Operation/CustomServices/AddOnSelectors";

// ============================= ACTIONS =============================

import {
  selectDestination,
  selectItem,
  addItem,
  removeItem,
  moveUpItem,
  moveDownItem,
  changeItem,
  showItemEditModal,
  startTimeChange,
  indicativePickUpTimeChange,
  changeBaseInfo,
  showItemDepMetaModal,
} from "@src/actions/Operation/CustomServices/AddOn";

import moment from "moment";
import _ from "lodash";
import update from "immutability-helper";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import SuIcon from "@src/style/icon/components/SuIcon";
import { humanizeDuration } from "@src/tools/date_tools";
import { humanizeDistance } from "@src/map_tools/map";
import { createUseStyles } from "react-jss";
import { cardStyles, variables } from "@src/jsssetup";
import {
  enterMapEditMode,
  exitMapEditMode,
  lockDepartureMeta,
  unlockDepartureMeta,
} from "@src/actions/Operation/CustomServices/AddOn/map_actions";
import { SidebarContext } from "@src/components/layout/Container";
import { AddonModalsContext } from "../AddOn";

const addOnDestinationStyles = createUseStyles({
  ...cardStyles,
  AddOnDestination: cardStyles.card,
  body: {
    ...cardStyles.body,
    gridTemplateColumns: "1fr 12rem",
    gridGap: variables.normal_gap,
    alignItems: "end",
  },
  destinationInput: {
    gridColumn: "span 2",
  },
  image: {
    "display": "grid",
    "alignContent": "center",
    "justifyItems": "center",
    "& img": {
      width: "100%",
      height: "15vh",
      objectFit: "cover",
      borderRadius: "5px",
    },
  },
  inputs: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: variables.half_gap,
  },
  inputGroup: {
    display: "grid",
  },
  sectionLabel: {
    gridColumn: "span 2",
  },
  [`@media ${variables.media.bigscreen}`]: {
    body: {
      gridTemplateColumns: "1fr 15rem",
    },
  },
});
var AddOnDestination = ({
  lang,
  destination,
  start_time,
  indicative_pickup_time,
  approximate_duration,
  onSelect,
  onStartTimeChange,
  onPickupTimeChange,
  onDurationChange,
}) => {
  const classes = addOnDestinationStyles();
  return (
    <div className={classes.AddOnDestination}>
      <div className={classes.body}>
        <div className={classes.destinationInput}>
          <span>
            <WhisperHelper
              msg="The destination which this service belongs to."
              placement="right"
            />{" "}
            <strong>Destination</strong>
          </span>
          <DestAutoComplete
            id={destination.id}
            value={destination.query}
            lang={lang}
            onSelect={onSelect}
          />
        </div>
        <div className={classes.inputs}>
          <div className={classes.inputGroup}>
            <span>
              <strong>Indicative Pick Up Time</strong>
            </span>
            <DatePicker
              format="HH:mm"
              ranges={[]}
              placement="top"
              value={
                indicative_pickup_time
                  ? moment()
                      .hour(parseInt(indicative_pickup_time.split(":")[0], 10))
                      .minute(
                        parseInt(indicative_pickup_time.split(":")[1], 10)
                      )
                      .toDate()
                  : null
              }
              onChange={(value) => {
                onPickupTimeChange(value ? moment(value).format("HH:mm") : "");
              }}
            />
          </div>
          <div className={classes.inputGroup}>
            <span>
              <WhisperHelper
                msg="The time of arrival at the first item/attraction."
                placement="right"
              />{" "}
              <strong>Starting Time</strong>
            </span>
            <DatePicker
              format="HH:mm"
              ranges={[]}
              placement="top"
              value={
                start_time
                  ? moment()
                      .hour(parseInt(start_time.split(":")[0], 10))
                      .minute(parseInt(start_time.split(":")[1], 10))
                      .toDate()
                  : null
              }
              onChange={(value) => {
                onStartTimeChange(value ? moment(value).format("HH:mm") : "");
              }}
            />
          </div>
          <span className={classes.sectionLabel}>
            <WhisperHelper
              msg="In case the Add-on contains no calculable durations you can describe the approximate total duration of the service."
              placement="right"
            />{" "}
            <strong>Approximate Total Duration</strong>
          </span>
          <div className={classes.inputGroup}>
            <span>
              <strong>Hours</strong>
            </span>
            <InputNumber
              value={approximate_duration.hours}
              onChange={function (value) {
                onDurationChange(value, approximate_duration.minutes);
              }}
            />
          </div>
          <div className={classes.inputGroup}>
            <span>
              <strong>Minutes</strong>
            </span>
            <InputNumber
              value={approximate_duration.minutes}
              onChange={function (value) {
                onDurationChange(approximate_duration.hours, value);
              }}
            />
          </div>
        </div>
        <div className={classes.image}>
          <DestinationImage images={destination.images} />
        </div>
      </div>
    </div>
  );
};
AddOnDestination.propTypes = {
  lang: PropTypes.string.isRequired,
  destination: PropTypes.object.isRequired,
  start_time: PropTypes.string.isRequired,
  indicative_pickup_time: PropTypes.string.isRequired,
  approximate_duration: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  onStartTimeChange: PropTypes.func.isRequired,
  onPickupTimeChange: PropTypes.func.isRequired,
  onDurationChange: PropTypes.func.isRequired,
};
const destMapStateToProps = (state) => {
  const { destination } = state.customServiceAddOnDestination;
  const { start_time, indicative_pickup_time, approximate_duration } =
    state.customServiceAddOn;

  return {
    destination,
    start_time,
    indicative_pickup_time,
    approximate_duration,
  };
};
const destMapDispatchToProps = (dispatch) => {
  return {
    onSelect: (id, lvl, query) => dispatch(selectDestination(id, lvl, query)),
    onStartTimeChange: (start_time) => dispatch(startTimeChange(start_time)),
    onPickupTimeChange: (pickup_time) =>
      dispatch(indicativePickUpTimeChange(pickup_time)),
    onDurationChange: (hours, minutes) => {
      dispatch(changeBaseInfo("approximate_duration", { hours, minutes }));
    },
  };
};
AddOnDestination = connect(
  destMapStateToProps,
  destMapDispatchToProps
)(AddOnDestination);

class ItemAdder extends React.PureComponent {
  constructor(props) {
    super(props);
  }
  render() {
    const { onAddItem } = this.props;

    return (
      <div className="ItemAdder">
        <div></div>
        <Icon icon="plus-circle" size="2x" onClick={onAddItem} />
        <span onClick={onAddItem}>
          <strong>Add Item</strong>
        </span>
        <div></div>
      </div>
    );
  }
}
ItemAdder.propTypes = {
  onAddItem: PropTypes.func.isRequired,
};

const addOnItemStyles = createUseStyles({
  AddOnItem: cardStyles.card,
  body: (props) => ({
    ...cardStyles.body,
    gridTemplateColumns: props.mode === "type" ? "1fr" : "auto 1fr",
    gridGap: variables.normal_gap,
  }),
  types: {
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateColumns: "repeat(3, 1fr)",
  },
  type: {
    "display": "grid",
    "gridGap": variables.half_gap,
    "textAlign": "center",
    "borderRadius": "5px",
    "padding": variables.half_gap,
    "boxShadow": variables.shadows.normalShadow,
    "cursor": "pointer",
    "&:hover": {
      border: `2px solid ${variables.colors.borders.base}`,
    },
  },
  typeHighlight: { color: variables.colors.highlight },
  typeFood: { color: variables.colors.food_and_drink },
  typeShopping: { color: variables.colors.shopping },
  controls: {
    display: "grid",
    gridGap: variables.half_gap,
    justifyItems: "center",
    alignItems: "center",
    paddingRight: variables.normal_gap,
    borderRight: `2px solid ${variables.colors.borders.base}`,
  },
  itemUp: {
    cursor: "pointer",
    color: variables.colors.default,
  },
  itemDown: {
    cursor: "pointer",
    color: variables.colors.default,
  },
  itemRemove: {
    cursor: "pointer",
    color: variables.colors.layoverRed,
  },
  itemAdd: {
    cursor: "pointer",
    color: variables.colors.green,
  },
  poiInput: {
    display: "grid",
    gridTemplateColumns: "auto 1fr auto",
    gridGap: variables.half_gap,
  },
  itemTypeBtn: (props) => ({
    "color": "white",
    "background":
      props.type === "highlight"
        ? variables.colors.highlight
        : props.type === "shopping"
        ? variables.colors.shopping
        : variables.colors.food_and_drink,
    "& .rs-icon": {
      color: "white",
      background:
        props.type === "highlight"
          ? `${variables.colors.highlight}!important`
          : props.type === "shopping"
          ? `${variables.colors.shopping}!important`
          : `${variables.colors.food_and_drink}!important`,
    },
  }),
  container: {
    display: "grid",
    gridGap: variables.half_gap,
  },
  formContainer: (props) => ({
    display: "grid",
    gridGap: variables.half_gap,
    gridTemplateColumns: props.sideBarOpen ? "10rem 1fr" : "20rem 1fr",
  }),
  image: {
    "display": "grid",
    "width": "100%",
    "height": "100%",
    "alignItems": "center",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: "5px",
      maxHeight: "170px",
    },
  },
  form: {
    display: "grid",
    gridGap: variables.half_gap,
  },
  slider: {
    display: "grid",
    gridGap: variables.normal_gap,
    gridTemplateColumns: "auto 1fr auto",
    alignItems: "center",
  },
});
var AddOnItem = ({
  order,
  item,
  lang,
  custom_images,
  isLast,
  onMoveDown,
  onMoveUp,
  onAdd,
  onRemove,
  onSelectItem,
  onChange,
  onEditItemContent,
}) => {
  const { setShowItemContentModal } = useContext(AddonModalsContext);
  const [mode, setMode] = useState(item.type !== "" ? "details" : "type");
  const baseCls = "AddOnItem--details-mode";

  function onDetailsMode(type) {
    setMode("details");
    onChange(item.order, "type", type);
  }

  const { open: sideBarOpen } = useContext(SidebarContext);
  const classes = addOnItemStyles({ mode, type: item.type, sideBarOpen });

  return (
    <div className={classes.AddOnItem}>
      <div className={classes.body}>
        {mode == "type" || !item.type ? (
          <div className={classes.types}>
            <div
              className={`${classes.type} ${classes.typeHighlight}`}
              onClick={() => onDetailsMode("highlight")}>
              <SuIcon icon="icon-highlightsicon" />
              <span>
                <strong>Highlight</strong>
              </span>
            </div>
            <div
              className={`${classes.type} ${classes.typeFood}`}
              shaded
              onClick={() => onDetailsMode("food_and_drink")}>
              <SuIcon icon="icon-foodanddrinkicon" />
              <span>
                <strong>Food & Drink</strong>
              </span>
            </div>
            <div
              className={`${classes.type} ${classes.typeShopping}`}
              shaded
              onClick={() => onDetailsMode("shopping")}>
              <SuIcon icon="icon-Shoppingicon" />
              <span>
                <strong>Shopping</strong>
              </span>
            </div>
          </div>
        ) : (
          <React.Fragment>
            <div className={classes.controls}>
              <Tag color="blue">
                <strong>{item.order}</strong>
              </Tag>
              <Icon
                className={classes.itemUp}
                icon="chevron-circle-up"
                size="2x"
                onClick={() => (item.order == 1 ? null : onMoveUp(item.order))}
              />
              <Icon
                className={classes.itemRemove}
                icon="minus-circle"
                size="2x"
                onClick={() => onRemove(item.order)}
              />
              <Icon
                className={classes.itemDown}
                icon="chevron-circle-down"
                size="2x"
                onClick={() => (isLast ? null : onMoveDown(item.order))}
              />
              <Icon
                className={classes.itemAdd}
                icon="plus-circle"
                size="2x"
                onClick={() => onAdd(item.order)}
              />
            </div>
            <div className={classes.container}>
              <div className={classes.poiInput}>
                <WhisperWrapper msg="Change Item Type">
                  <IconButton
                    className={classes.itemTypeBtn}
                    icon={<Icon icon="bars" />}
                    onClick={() => setMode("type")}>
                    {_.startCase(item.type)}
                  </IconButton>
                </WhisperWrapper>
                <PoiAutoComplete
                  uid={item.data.id || item.data.uid}
                  value={item.data.query_value}
                  lang={lang}
                  onSelect={(data) => onSelectItem(item.order, data)}
                />
                <IconButton
                  color="blue"
                  className={`ItemTypeBtn`}
                  icon={<Icon icon="edit" />}
                  onClick={async function () {
                    await onEditItemContent(order);
                    setShowItemContentModal(true);
                  }}>
                  Edit Content
                </IconButton>
              </div>
              <div className={classes.formContainer}>
                <div className={classes.image}>
                  <DestinationImage
                    images={item.data.image_set}
                    custom_images={custom_images}
                  />
                </div>
                <div className={classes.form}>
                  <span>
                    <strong>Arrival Mode</strong>
                  </span>
                  <SelectPicker
                    searchable={false}
                    placement="top"
                    data={[
                      { value: "on_foot", label: "On Foot" },
                      { value: "transfer", label: "By Transfer" },
                    ]}
                    value={item.transportation_mode}
                    renderValue={(value, item) => (
                      <Tag color={item.value == "on_foot" ? "green" : "orange"}>
                        <strong>{item.label}</strong>
                      </Tag>
                    )}
                    renderMenuItem={(label, item) => (
                      <Tag color={item.value == "on_foot" ? "green" : "orange"}>
                        <strong>{label}</strong>
                      </Tag>
                    )}
                    onChange={(value) =>
                      onChange(item.order, "transportation_mode", value)
                    }
                  />
                  <span>
                    <strong>Arrival Time</strong>
                  </span>
                  <Tag color="blue">
                    <strong>{item.arrival_time}</strong>
                  </Tag>
                  <span>
                    <strong>
                      Visit Duration (
                      {humanizeDuration(item.visit_duration * 60 * 1000)})
                    </strong>
                  </span>
                  <div className={classes.slider}>
                    <IconButton
                      icon={<Icon icon="minus" />}
                      circle
                      size="xs"
                      appearance="primary"
                      onClick={function () {
                        if (item.visit_duration == 1) {
                          return;
                        }
                        onChange(
                          item.order,
                          "visit_duration",
                          item.visit_duration - 1
                        );
                      }}
                    />
                    <Slider
                      value={item.visit_duration}
                      min={1}
                      max={300}
                      onChange={(value) =>
                        onChange(item.order, "visit_duration", value)
                      }
                    />
                    <IconButton
                      icon={<Icon icon="plus" />}
                      circle
                      size="xs"
                      appearance="primary"
                      onClick={function () {
                        onChange(
                          item.order,
                          "visit_duration",
                          item.visit_duration + 1
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};
AddOnItem.propTypes = {
  order: PropTypes.number.isRequired,
  lang: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  isLast: PropTypes.bool.isRequired,
  custom_images: PropTypes.array,
  onSelectItem: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onMoveUp: PropTypes.func.isRequired,
  onMoveDown: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onEditItemContent: PropTypes.func.isRequired,
};

const AddOnItemMapStateToProps = (state, ownProps) => {
  const { order } = ownProps;
  const { item, isLast } = getItemSelector(state, { order });
  const { data } = _.get(state, `customServiceAddOnItems.${order - 1}`, {});
  const custom_images = _.get(data, "custom_images", []);
  return { item, isLast, custom_images };
};
const AddOnItemMapDispatchToProps = (dispatch) => {
  return {
    onMoveUp: (order) => dispatch(moveUpItem(order)),
    onMoveDown: (order) => dispatch(moveDownItem(order)),
    onRemove: (order) => dispatch(removeItem(order)),
    onAdd: (order) => dispatch(addItem(order)),
    onChange: (order, key, value) => dispatch(changeItem(order, key, value)),
    onEditItemContent: (itemIdx) => dispatch(showItemEditModal(itemIdx)),
  };
};
AddOnItem = connect(
  AddOnItemMapStateToProps,
  AddOnItemMapDispatchToProps
)(AddOnItem);

const pseudoStyles = {
  content: '""',
  position: "absolute",
  width: "5%",
  transform: "translateY(-50%)",
  height: "50%",
};
const itemDepMetaStyles = createUseStyles({
  meta: {
    "alignItems": "center",
    "position": "relative",
    "display": "grid",
    "gridGap": `calc(${variables.normal_gap} / 2)`,
    "gridTemplateColumns": "repeat(7, max-content)",
    "justifySelf": "center",
    "justifyItems": "center",
    "color": variables.colors.lightDarkTextColor,
    "border": `2px solid ${variables.colors.lightDarkTextColor}`,
    "borderRadius": variables.normal_gap,
    "padding": `calc(${variables.normal_gap} / 2)`,
    "&:before": {
      ...pseudoStyles,
      left: "-5%",
      top: "calc(25% + 2px)",
      borderBottom: `2px solid ${variables.colors.lightDarkTextColor}`,
      borderLeft: `2px solid ${variables.colors.lightDarkTextColor}`,
      borderRadius: "3px 0px 0px 20px",
    },
    "&:after": {
      ...pseudoStyles,
      right: "-5%",
      top: "calc(75% - 2px)",
      transform: "translateY(-50%)",
      borderTop: `2px solid ${variables.colors.lightDarkTextColor}`,
      borderRight: `2px solid ${variables.colors.lightDarkTextColor}`,
      borderRadius: "0px 20px 3px 0px",
    },
  },
});
const TItemDepartureMeta = (props) => {
  const {
    lock,
    mapEditMode,
    mapEditItemOrders,
    order,
    departure_route,
    onEdit,
    onEnterMapEdit,
    onExitMapEdit,
    onLock,
    onUnlock,
  } = props;
  const classes = itemDepMetaStyles();

  return (
    <div className={classes.meta}>
      <span>
        <strong>
          Distance: {humanizeDistance(_.get(departure_route, "distance", 0))}
        </strong>
      </span>
      <span>
        <strong>
          Duration:{" "}
          {humanizeDuration(_.get(departure_route, "duration", 0) * 1000)}
        </strong>
      </span>
      <IconButton
        icon={<Icon icon="edit" />}
        circle
        appearance="primary"
        size="xs"
        onClick={function () {
          onEdit(order);
        }}
      />
      <span>
        <strong>Edit Route</strong>
      </span>
      <Toggle
        checked={mapEditMode && _.get(mapEditItemOrders, 0, 0) === order}
        checkedChildren="Enabled"
        unCheckedChildren="Disabled"
        onChange={function (checked) {
          if (checked) {
            onEnterMapEdit([order, order + 1]);
          } else {
            onExitMapEdit(order);
          }
        }}
      />
      <span>
        <strong>Route Lock</strong>
      </span>
      <Toggle
        checked={lock}
        checkedChildren="On"
        unCheckedChildren="Off"
        onChange={function (checked) {
          if (checked) {
            onLock(order);
          } else {
            onUnlock(order);
          }
        }}
      />
    </div>
  );
};
TItemDepartureMeta.propTypes = {
  lock: PropTypes.bool.isRequired,
  mapEditMode: PropTypes.bool.isRequired,
  mapEditItemOrders: PropTypes.array.isRequired,
  order: PropTypes.number.isRequired,
  departure_route: PropTypes.object,
  onEdit: PropTypes.func.isRequired,
  onEnterMapEdit: PropTypes.func.isRequired,
  onExitMapEdit: PropTypes.func.isRequired,
  onLock: PropTypes.func.isRequired,
  onUnlock: PropTypes.func.isRequired,
};
const ItemDepartureMetaMapStateToProps = (state, ownProps) => {
  const { order } = ownProps;
  const { mapEditMode, mapEditItemOrders } = state.customServiceAddOn;
  const { item } = getItemSelector(state, { order });
  return {
    departure_route: item.departure_route,
    lock: _.get(item, "departure_route.lock", false),
    mapEditMode,
    mapEditItemOrders,
  };
};
const ItemDepartureMetaMapDispatchToProps = (dispatch) => {
  return {
    onEdit: (itemOrder) => dispatch(showItemDepMetaModal(itemOrder)),
    onEnterMapEdit: (itemOrders) => dispatch(enterMapEditMode(itemOrders)),
    onExitMapEdit: (itemOrder) => dispatch(exitMapEditMode(itemOrder)),
    onLock: (itemOrder) => dispatch(lockDepartureMeta(itemOrder)),
    onUnlock: (itemOrder) => dispatch(unlockDepartureMeta(itemOrder)),
  };
};
const ItemDepartureMeta = connect(
  ItemDepartureMetaMapStateToProps,
  ItemDepartureMetaMapDispatchToProps
)(TItemDepartureMeta);

class ItemsSetupController extends React.PureComponent {
  constructor(props) {
    super(props);
  }
  render() {
    const { lang, items, selectAddOnItem, onAddItem } = this.props;

    return (
      <React.Fragment>
        <AddOnDestination lang={lang} />
        {items.map((item, idx) => {
          return (
            <React.Fragment key={idx}>
              <AddOnItem
                idx={idx}
                order={item}
                lang={lang}
                onSelectItem={selectAddOnItem}
              />
              {idx + 1 < items.length ? (
                <ItemDepartureMeta order={item} />
              ) : null}
            </React.Fragment>
          );
        })}
        <ItemAdder onAddItem={onAddItem} />
      </React.Fragment>
    );
  }
}
ItemsSetupController.propTypes = {
  lang: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  selectAddOnItem: PropTypes.func.isRequired,
  onAddItem: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  const items = getItemIdxsSelector(state);

  return {
    lang: state.setLang,
    items,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onAddItem: () => dispatch(addItem()),
    selectAddOnItem: (order, data) => dispatch(selectItem(order, data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemsSetupController);
