import { Button, Loader } from "rsuite";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { hideSaveAsModal, saveAs } from "@src/actions/Project/TripPlanner";
import { createUseStyles } from "react-jss";
import { modalGenericStyles } from "@src/jsssetup";
import { Formik, Form } from "formik";
import { NormalInputField, NormalSelectField } from "@src/components/forms";
import { getSetupFormDataSelector } from "@src/selectors/Project/TripPlanner";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const styles = createUseStyles({
  SaveAsModal: modalGenericStyles.modal,
  card: modalGenericStyles.card,
  header: modalGenericStyles.cardHeader,
  body: modalGenericStyles.cardBody,
  actions: modalGenericStyles.cardActions,
});
const SaveAsModal = (props) => {
  const { onCancel, onSaveAs } = props;
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const { title, usage } = useSelector((state) =>
    getSetupFormDataSelector(state)
  );
  const { link } = useSelector((state) => state.tripPlannerInstanceData);

  const classes = styles();

  return (
    <div className={classes.SaveAsModal}>
      {loading ? (
        <Loader vertical size="lg" content="Please wait..." center />
      ) : (
        <div className={classes.card}>
          <div className={classes.header}>
            <h5>Save As</h5>
          </div>
          <Formik
            initialValues={{ title: title + " (copy)", usage, link: false }}
            onSubmit={async (values) => {
              if (values.usage !== "OFF" && values.link) {
                toast.warning("Trips can be linked only in Offer Mode.", {
                  duration: 5000,
                });
                return;
              }

              setLoading(true);
              const id = await onSaveAs({ ...values });
              setLoading(false);
              if (id) {
                const url = values.link
                  ? `/project/trip/tripplan/${id}/recalculate`
                  : `/project/trip/tripplan/${id}/`;
                history.push(url);
              }
            }}>
            <Form>
              <div className={classes.body}>
                <NormalInputField name="title" label="Title" />
                <NormalSelectField
                  name="usage"
                  label="Usage"
                  options={[
                    ["OFF", "Offer"],
                    ["RQ", "Template"],
                    ["MKT", "Marketplace"],
                  ]}
                />
                {!link && (
                  <NormalSelectField
                    name="link"
                    label="Clone and Link"
                    options={[
                      [false, "No"],
                      [true, "Yes"],
                    ]}
                  />
                )}
              </div>
              <div className={classes.actions}>
                <Button color="blue" onClick={onCancel}>
                  <strong>Cancel</strong>
                </Button>
                <Button color="green" type="submit">
                  <strong>Save As</strong>
                </Button>
              </div>
            </Form>
          </Formik>
        </div>
      )}
    </div>
  );
};
SaveAsModal.propTypes = {
  title: PropTypes.string.isRequired,
  usage: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSaveAs: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  const { show, loading, title, usage } = state.tripPlannerSaveAsModal;
  return { show, loading, usage, title };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onHide: () => dispatch(hideSaveAsModal()),
    onSaveAs: ({ title, usage, link }) =>
      dispatch(saveAs({ title, usage, link })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SaveAsModal);
