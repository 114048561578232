import { lineString, length } from "@turf/turf";
import _ from "lodash";
import update from "immutability-helper";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";
import { centerToPoints, fetchDirections } from "@src/map_tools/map";
import InteractiveMapHoc from "@src/pages/Project/TripPlan/InteractiveMapHoc";
import { humanizeDuration } from "@src/tools/date_tools";
import { updateDistanceData } from "@src/actions/Operation/CustomServices/AddOn";
import { Icon, IconButton } from "rsuite";

export const AddOnMapHoc = (
  WrappedComponent,
  { smallMap = false, threeD = false }
) => {
  class Comp extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        currentPoints: [],
      };

      this.templateTextPaint = {
        "text-color": "black",
        "text-halo-color": "white",
        "text-halo-width": 2,
      };

      this.routeSourceTemplate = {
        type: "geojson",
        data: {
          type: "Feature",
          properties: { visibility: "visible" },
          geometry: { type: "LineString", coordinates: [] },
        },
      };

      this.getDirections = this.getDirections.bind(this);
      this.setItemData = this.setItemData.bind(this);
      this.updateRouteDataVisibility =
        this.updateRouteDataVisibility.bind(this);
      this.setRouteData = this.setRouteData.bind(this);
      this.addItemsDrawData = this.addItemsDrawData.bind(this);
      this.deleteDrawData = this.deleteDrawData.bind(this);
      this.getDrawData = this.getDrawData.bind(this);
      this.center = this.center.bind(this);
    }
    async componentDidUpdate(pp) {
      const { map } = this.props;

      if (!pp.map && map) {
        map.once("load", async () => {
          this.initialActions();
          await this.getDirections({ keepExistingData: true });
          this.setItemData();
          await this.center();
        });
      }
    }
    initialActions() {
      this.setSources();
      this.setLayers();
    }
    setItemSources() {
      const { map } = this.props;
      map.addSource("itemData", {
        type: "geojson",
        data: { type: "Feature", features: [] },
      });
    }
    setSources() {
      this.setItemSources();
    }
    setItemLayers() {
      const { map } = this.props;

      map.addLayer(
        this.itemLayerTemplate("highlightPoint", "highlight", "#33b2db")
      );

      map.addLayer(
        this.itemLayerTemplate("fooAndDrinkPoint", "food_and_drink", "#f0ad74")
      );

      map.addLayer(
        this.itemLayerTemplate("shoppingPoint", "shopping", "#7ecb94")
      );

      map.addLayer(
        this.itemTextLayerTemplate({
          txtExpression: [
            "concat",
            ["to-string", ["get", "order"]],
            ". ",
            ["get", "title"],
          ],
          txtOffset: [0, 1.5],
        })
      );

      map.addLayer(
        this.subTextLayerTemplate(
          "itemSubText",
          ["concat", "(", ["to-string", ["get", "visit_duration"]], ")"],
          [0, 3.5]
        )
      );

      map.addLayer(
        this.subTextLayerTemplate(
          "itemUpText",
          ["concat", "(", ["to-string", ["get", "arrival_time"]], ")"],
          [0, -2.5]
        )
      );

      map.addLayer(
        this.itemTextLayerTemplate({
          id: "order-text",
          txtExpression: ["to-string", ["get", "order"]],
          txtOffset: [0, 0],
        })
      );
    }
    setLayers() {
      this.setItemLayers();
    }
    routeLayerTemplate(id, source, lineColor) {
      return {
        id,
        type: "line",
        source,
        filter: ["==", ["get", "visibility"], "visible"],
        layout: { "line-join": "round", "line-cap": "round" },
        paint: { "line-color": lineColor, "line-width": 4 },
      };
    }
    itemLayerTemplate(id, type, color) {
      return {
        id,
        source: "itemData",
        type: "circle",
        filter: [
          "all",
          ["==", ["get", "type"], type],
          ["==", ["get", "visibility"], "visible"],
        ],
        paint: {
          "circle-stroke-width": 2,
          "circle-radius": 10,
          "circle-color": color,
          "circle-stroke-color": "black",
        },
      };
    }
    itemTextLayerTemplate({ id = "itemText", txtExpression, txtOffset }) {
      return {
        id,
        source: "itemData",
        type: "symbol",
        filter: ["==", ["get", "visibility"], "visible"],
        layout: {
          "text-field": txtExpression,
          "text-allow-overlap": true,
          "text-max-width": 20,
          "text-size": 12,
          "text-offset": txtOffset,
        },
        paint: this.templateTextPaint,
      };
    }
    subTextLayerTemplate(id, txtExpression, txtOffset) {
      return {
        id,
        source: "itemData",
        type: "symbol",
        filter: ["==", ["get", "visibility"], "visible"],
        layout: {
          "text-field": txtExpression,
          "text-anchor": "top",
          "text-size": 10,
          "text-offset": txtOffset,
          "text-allow-overlap": true,
        },
        paint: this.templateTextPaint,
      };
    }
    setItemData({ visible = true, itemOrders = [] } = {}) {
      const { map, items } = this.props;

      if (!map) return;
      if (!map.getSource("itemData")) return;

      map.getSource("itemData").setData({
        type: "FeatureCollection",
        features: items
          .filter(
            (item) =>
              !_.isEmpty(item.data) &&
              _.get(item, "data.geodata.lng", 0) !== 0 &&
              _.get(item, "data.geodata.lat", 0) !== 0
          )
          .map((item) => {
            return {
              type: "Feature",
              properties: {
                title: item.data.name_en,
                visit_duration: humanizeDuration(
                  item.visit_duration * 60 * 1000
                ),
                order: item.order,
                visibility: visible
                  ? "visible"
                  : itemOrders.includes(item.order)
                  ? "visible"
                  : "none",
                type: item.type,
                arrival_time: item.arrival_time || "00:00",
              },
              geometry: {
                type: "Point",
                coordinates: [
                  _.get(item, "data.geodata.lng", 0),
                  _.get(item, "data.geodata.lat", 0),
                ],
              },
            };
          }),
      });
    }
    updateRouteDataVisibility({ visible = true } = {}) {
      const { map } = this.props;

      if (!map) {
        return;
      }

      const { couples, differ } = this.getItemCouples();
      if (!couples.length) {
        return;
      }

      const sourceIdCreator = (order) => `itemRouteData__${order}`;
      couples.forEach((couple) => {
        const source = map.getSource(sourceIdCreator(couple[0].order));
        const data = _.cloneDeep(_.get(source, "_data"));
        if (!data) {
          return;
        }

        data.properties.visibility = visible ? "visible" : "none";
        source.setData(data);
      });
    }
    setRouteData({ geometry = {}, itemOrders = [] }) {
      const { map } = this.props;

      if (!map || _.isEmpty(geometry)) {
        return;
      }

      const { couples } = this.getItemCouples();
      if (!couples.length) {
        return;
      }

      if (!itemOrders.length) {
        return;
      }

      const sourceIdCreator = (order) => `itemRouteData__${order}`;
      couples.forEach((couple) => {
        if (couple[0].order !== itemOrders[0]) {
          return;
        }

        const source = map.getSource(sourceIdCreator(couple[0].order));
        const data = _.cloneDeep(_.get(source, "_data"));
        if (!data) {
          return;
        }

        data.geometry = geometry;
        source.setData(data);
      });
    }
    getItemCouples() {
      const { items } = this.props;
      const { currentPoints } = this.state;
      const newPoints = items
        .filter(
          (item) =>
            !_.isEmpty(item.data) &&
            _.get(item, "data.geodata.lng", 0) !== 0 &&
            _.get(item, "data.geodata.lat", 0) !== 0
        )
        .map((item) => ({
          order: item.order,
          transportation_mode: item.transportation_mode,
          lng: _.get(item, "data.geodata.lng", 0),
          lat: _.get(item, "data.geodata.lat", 0),
          lock: _.get(item, "departure_route.lock", false),
        }));

      var differ = false;
      if (JSON.stringify(currentPoints) !== JSON.stringify(newPoints)) {
        this.setState((p) => update(p, { currentPoints: { $set: newPoints } }));
        differ = true;
      }

      var couples = [];
      if (newPoints.length > 2) {
        couples = _.zip(newPoints, newPoints.slice(1)).slice(0, -1);
      } else if (newPoints.length == 2) {
        couples = [newPoints];
      }

      return { couples, differ };
    }
    addItemsDrawData({ itemOrders = [] } = {}) {
      const { items, map, draw } = this.props;

      if (!map || !draw) {
        return;
      }

      const polyData = items.find((item) => item.order === itemOrders[0]);
      if (_.isEmpty(_.get(polyData, "departure_route.geometry"))) {
        return;
      }

      draw.add({
        id: "item-custom-route",
        type: "Feature",
        properties: {},
        geometry: polyData.departure_route.geometry,
      });
    }
    deleteDrawData() {
      const { draw } = this.props;
      if (!draw) {
        return;
      }

      draw.deleteAll();
    }
    getDrawData({ itemOrders = [] }) {
      const { draw } = this.props;

      if (!draw || !itemOrders.length) {
        return null;
      }

      const drawData = draw.getAll();
      const geometry = _.get(drawData, "features.0.geometry");
      const { couples } = this.getItemCouples();

      if (!couples.length) {
        return null;
      }

      const couple = couples.find(
        (couple) => couple[0].order === itemOrders[0]
      );
      if (!couple) {
        return null;
      }

      const distance = Math.floor(
        length(lineString(geometry.coordinates), {
          units: "kilometers",
        }) * 1000
      );

      const data = {
        couple,
        route: { distance, duration: 0, geometry, lock: true },
      };

      return data;
    }
    async getDirections({
      updateDistanceFn = null,
      keepExistingData = false,
    } = {}) {
      const { map, items } = this.props;

      if (!map) {
        return;
      }

      var { couples, differ } = this.getItemCouples();
      if (!couples.length || !differ) {
        return;
      }

      const sourceIdCreator = (order) => `itemRouteData__${order}`;
      const layerIdCreator = (order) => `itemRouteLayer__${order}`;

      items
        .filter((item) => !_.isEmpty(item.data))
        .forEach((item) => {
          if (map.getLayer(layerIdCreator(item.order))) {
            map.removeLayer(layerIdCreator(item.order));
          }

          if (map.getSource(sourceIdCreator(item.order))) {
            map.removeSource(sourceIdCreator(item.order));
          }
        });

      const routeTypeMapping = { transfer: "driving", on_foot: "walking" };
      for (let i = 0; i < couples.length; i++) {
        var route = {};

        if (!keepExistingData && !couples[i][0].lock) {
          route = await fetchDirections(
            couples[i].map((c) => [c.lng, c.lat]),
            routeTypeMapping[couples[i][1].transportation_mode]
          );
        } else {
          const item = items.find((item) => item.order === couples[i][0].order);
          if (!item) {
            continue;
          }

          route = item.departure_route;
        }

        if (!route) {
          continue;
        }

        if (typeof updateDistanceFn == "function") {
          updateDistanceFn({ couple: couples[i], route });
        }

        map.addSource(
          sourceIdCreator(couples[i][0].order),
          update(this.routeSourceTemplate, {
            data: {
              geometry: { coordinates: { $set: route.geometry.coordinates } },
            },
          })
        );

        map.addLayer(
          this.routeLayerTemplate(
            layerIdCreator(couples[i][0].order),
            sourceIdCreator(couples[i][0].order),
            couples[i][1].transportation_mode == "transfer"
              ? "#FF9830"
              : "#38A650"
          )
        );
        map.moveLayer(layerIdCreator(couples[i][0].order), "highlightPoint");
      }
    }
    async center() {
      const { map } = this.props;
      const points = [];

      map
        .getSource("itemData")
        ._data.features.forEach((f) => points.push(f.geometry.coordinates));

      const cPoints = points.filter((pp) => pp.every((p) => p !== 0));

      if (threeD) {
        map.setPitch(60);
      }

      if (smallMap) {
        centerToPoints(map, [cPoints[0]], {
          zoom: 9,
        });
      } else {
        centerToPoints(map, cPoints, {
          zoom: 9,
        });
      }
    }
    render() {
      return (
        <WrappedComponent
          {...this.props}
          getDirections={this.getDirections}
          setItemData={this.setItemData}
          updateRouteDataVisibility={this.updateRouteDataVisibility}
          addItemsDrawData={this.addItemsDrawData}
          deleteDrawData={this.deleteDrawData}
          getDrawData={this.getDrawData}
          setRouteData={this.setRouteData}
          center={this.center}
        />
      );
    }
  }

  Comp.defaultProps = {
    items: [],
  };

  Comp.propTypes = {
    map: PropTypes.object,
    draw: PropTypes.object,
    items: PropTypes.array,
    onUpdateDistance: PropTypes.func,
  };

  return Comp;
};

class AddOnMap extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      editItemOrders: [],
    };
    this.handleCenterMap = this.handleCenterMap.bind(this);
  }
  async componentDidUpdate(pp) {
    const {
      addonMapVersion,
      map,
      mapVersion,
      mapEditMode,
      getDirections,
      setItemData,
      center,
      onUpdateDistance,
    } = this.props;

    if (pp.addonMapVersion !== addonMapVersion && map.loaded()) {
      setTimeout(() => map.resize(), 1000);
    }

    if (pp.mapVersion !== mapVersion && map.loaded()) {
      map.resize();
      await getDirections({ updateDistanceFn: onUpdateDistance });
      setItemData();
      await center();
    }

    if (!pp.mapEditMode && mapEditMode && map.loaded()) {
      this.enableEditMode();
    } else if (pp.mapEditMode && !mapEditMode && map.loaded()) {
      this.saveEditedData();
    }
  }
  enableEditMode() {
    const {
      mapEditItemOrders,
      setItemData,
      updateRouteDataVisibility,
      addItemsDrawData,
      deleteDrawData,
    } = this.props;

    deleteDrawData();
    this.setState(
      (p) => ({ ...p, editItemOrders: mapEditItemOrders }),
      () => {
        setItemData({ visible: false, itemOrders: mapEditItemOrders });
        updateRouteDataVisibility({ visible: false });
        addItemsDrawData({ itemOrders: mapEditItemOrders });
      }
    );
  }
  async saveEditedData() {
    const {
      setItemData,
      updateRouteDataVisibility,
      center,
      onUpdateDistance,
      deleteDrawData,
      getDrawData,
      setRouteData,
    } = this.props;

    const departure_meta = getDrawData({
      itemOrders: this.state.editItemOrders,
    });
    if (departure_meta) {
      await onUpdateDistance(departure_meta);
    }
    deleteDrawData();
    setItemData();
    if (_.get(departure_meta, "route.goemetry")) {
      setRouteData({
        geometry: departure_meta.route.geometry,
        itemOrders: this.state.editItemOrders,
      });
    }
    updateRouteDataVisibility({ visible: true });
    await center();
  }
  handleCenterMap() {
    const { center } = this.props;

    center();
  }
  render() {
    return (
      <div className="AddOnMap">
        <div id="map"></div>
        <IconButton
          className="AddOnMap__center-btn"
          icon={<Icon icon="map" />}
          appearance="primary"
          onClick={this.handleCenterMap}>
          Center Map
        </IconButton>
      </div>
    );
  }
}
AddOnMap.defaultProps = {
  addonMapVersion: 0,
};
AddOnMap.propTypes = {
  addonMapVersion: PropTypes.number.isRequired,
  map: PropTypes.object,
  draw: PropTypes.object,
  mapVersion: PropTypes.number.isRequired,
  mapEditMode: PropTypes.bool.isRequired,
  mapEditItemOrders: PropTypes.number,
  items: PropTypes.array.isRequired,
  getDirections: PropTypes.func.isRequired,
  setItemData: PropTypes.func.isRequired,
  updateRouteDataVisibility: PropTypes.func.isRequired,
  addItemsDrawData: PropTypes.func.isRequired,
  center: PropTypes.func.isRequired,
  onUpdateDistance: PropTypes.func.isRequired,
  deleteDrawData: PropTypes.func.isRequired,
  getDrawData: PropTypes.func.isRequired,
  setRouteData: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  const items = state.customServiceAddOnItems;
  const { mapVersion, mapEditMode, mapEditItemOrders } =
    state.customServiceAddOn;
  return {
    items,
    mapVersion,
    mapEditMode,
    mapEditItemOrders,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateDistance: (data) => {
      dispatch(updateDistanceData(data));
    },
  };
};
export default InteractiveMapHoc(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AddOnMapHoc(AddOnMap, { smallMap: false, threeD: false })),
  { containerId: "map", withDraw: true }
);
