import { Notification } from "rsuite";
import React from "react";

export const notifyFailedToSaveService = (srvType) => {
  Notification.error({
    title: "Save Custom Service Failure",
    description: <p>{`Failed to save ${srvType}.`}</p>,
    duration: 6000,
  });
};
