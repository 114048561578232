import {
  // ========================== SEND ACTIONS =========================
  BIDREQUEST_OFFER_REQUEST_SEND,
  BIDREQUEST_CANCEL_SEND_OFFER,
  BIDREQUEST_SENDING_OFFER,
  BIDREQUEST_SENT_OFFER,
} from "./types";

import { updateBiddingOffer } from "@src/api/Project/Bidding";

import { Notification } from "rsuite";

import update from "immutability-helper";
import _ from "lodash";
import MessageFormatter from "@src/validators/MessageFormatter";
import { validateOffer } from "@src/validators/Project/BidRequest";
import React from "react";

export const requestSendOffer = () => (dispatch, getState) => {
  const state = getState();
  const offerData = state.bidRequestOfferData;

  const msgs = validateOffer(offerData);
  if (msgs.length) {
    const desc = <MessageFormatter msgs={msgs} />;
    try {
      Notification.close("offer_validation_notification");
    } finally {
      Notification.error({
        key: "offer_validation_notification",
        title: "Offer Validation Error",
        description: desc,
        duration: 10000,
      });
    }
    return;
  }

  dispatch({ type: BIDREQUEST_OFFER_REQUEST_SEND });
};

export const sendOffer = () => async (dispatch, getState) => {
  const state = getState();
  const offer = state.bidRequestOfferData;

  dispatch({ type: BIDREQUEST_SENDING_OFFER });

  try {
    const res = await updateBiddingOffer(
      offer.id,
      update(
        _.omit(offer, [
          "id",
          "creator",
          "last_editor",
          "edited",
          "created",
          "source_entity",
          "bid_request",
        ]),
        { offer_status: { $set: "ST" } }
      )
    );
    // TODO: Here we should save the data back to redux.
  } catch (error) {
    error.ovrdErrHandle && error.ovrdErrHandle();
    console.log(error);
  } finally {
    dispatch({ type: BIDREQUEST_SENT_OFFER });
  }
};

export const cancelSendOffer = () => {
  return { type: BIDREQUEST_CANCEL_SEND_OFFER };
};
