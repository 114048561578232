import {
  SUBSCRIPTION_FEES_STATISTICS,
  BEGIN_SUBSCRIPTION_FEES_DATA,
  END_SUBSCRIPTION_FEES_DATA,
  SUBSCRIPTION_FEES_VIEW_TYPE,
} from "@src/actions/ManagementConsole/Usage/types";

export const fetchSubscriptionFeesStatus = (state = "IDLE", action) => {
  switch (action.type) {
    case BEGIN_SUBSCRIPTION_FEES_DATA:
      return "FETCHING";
    case END_SUBSCRIPTION_FEES_DATA:
      return "FETCHED";
    default:
      return state;
  }
};

export const fetchSubscriptionFeesData = (state = {}, action) => {
  switch (action.type) {
    case SUBSCRIPTION_FEES_STATISTICS:
      return action.data;
    default:
      return state;
  }
};

export const subscriptionFeesViewType = (state = "current_month", action) => {
  switch (action.type) {
    case SUBSCRIPTION_FEES_VIEW_TYPE:
      return action.data;
    default:
      return state;
  }
};
