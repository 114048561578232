import React, { useState } from "react";

export function useFeedbackRemarks() {
  const remarksTmp = { remarks: "", confirmed: false };
  const [booking_mode_remarks, setBModeRemarks] = useState({ ...remarksTmp });
  const [payment_mode_remarks, setPModeRemarks] = useState({
    ...remarksTmp,
  });
  const [reservation_remarks, setResRemarks] = useState({ ...remarksTmp });
  const [cxl_remarks, setCxlRemarks] = useState({ ...remarksTmp });

  return {
    booking_mode_remarks,
    payment_mode_remarks,
    reservation_remarks,
    cxl_remarks,
    setBModeRemarks,
    setPModeRemarks,
    setResRemarks,
    setCxlRemarks,
  };
}

export function useSupplierInfo() {
  const [supplier_name, setSupplierName] = useState("");
  const [supplier_email, setSupplierEmail] = useState("");
  const [supplier_phone, setSupplierPhone] = useState("");
  const [supplier_remarks, setSupplierRemarks] = useState("");

  return {
    supplier_name,
    supplier_email,
    supplier_phone,
    supplier_remarks,
    setSupplierName,
    setSupplierEmail,
    setSupplierPhone,
    setSupplierRemarks,
  };
}
