//=============================LIST VIEW===========================
// export const VENDORS_FLIGHTS_BEGIN_FETCH_DATA =
//   "VENDORS_FLIGHTS_BEGIN_FETCH_DATA";
// export const VENDORS_FLIGHTS_END_FETCH_DATA = "VENDORS_FLIGHTS_END_FETCH_DATA";
// export const VENDORS_FLIGHTS_FETCH_DATA = "VENDORS_FLIGHTS_FETCH_DATA";
// export const VENDORS_FLIGHTS_FETCH_OPTIONS = "VENDORS_FLIGHTS_FETCH_OPTIONS";
//=============================FILTER RELATIONS========================
// export const VENDORS_FLIGHTS_INITIALIZE_LIST_FILTER_FORM =
//   "VENDORS_FLIGHTS_INITIALIZE_LIST_FILTER_FORM";
// export const VENDORS_FLIGHTS_UPDATE_LIST_FILTER_FORM =
//   "VENDORS_FLIGHTS_UPDATE_LIST_FILTER_FORM";
// export const VENDORS_FLIGHTS_APPLY_LIST_FILTER =
//   "VENDORS_FLIGHTS_APPLY_LIST_FILTER";
//=============================COLUMNS RELATIONS=========================
// export const VENDORS_FLIGHTS_APPLY_LIST_COLUMNS =
//   "VENDORS_FLIGHTS_APPLY_LIST_COLUMNS";
// export const VENDORS_FLIGHTS_INITIALIZE_LIST_COLUMNS_FORM =
//   "VENDORS_FLIGHTS_INITIALIZE_LIST_COLUMNS_FORM";

//================================ADD VENDORS_FLIGHTS========================
// export const VENDORS_FLIGHTS_BEGIN_ADD = "VENDORS_FLIGHTS_BEGIN_ADD";
// export const VENDORS_FLIGHTS_IDLE_ADD = "VENDORS_FLIGHTS_IDLE_ADD";
// export const VENDORS_FLIGHTS_END_ADD = "VENDORS_FLIGHTS_END_ADD";
// export const VENDORS_FLIGHTS_INIT_ADD_MODAL_FORM =
//   "VENDORS_FLIGHTS_INIT_ADD_MODAL_FORM";
// export const VENDORS_FLIGHTS_SHOW_BASE_FORM_MODAL =
//   "VENDORS_FLIGHTS_SHOW_BASE_FORM_MODAL";
// export const VENDORS_FLIGHTS_HIDE_BASE_FORM_MODAL =
//   "VENDORS_FLIGHTS_HIDE_BASE_FORM_MODAL";
// export const VENDORS_FLIGHTS_EDIT_BASE_FORM_MODAL =
//   "VENDORS_FLIGHTS_EDIT_BASE_FORM_MODAL";
//================================EDIT===========================
// export const VENDORS_FLIGHTS_INITIALIZE_BASE_DATA_EDIT_FORM =
//   "VENDORS_FLIGHTS_INITIALIZE_BASE_DATA_EDIT_FORM";
// export const VENDORS_FLIGHTS_FETCH_EDIT_DATA =
//   "VENDORS_FLIGHTS_FETCH_EDIT_DATA";
// export const VENDORS_FLIGHTS_INIT_BASE_DATA_EDIT_FORM =
//   "VENDORS_FLIGHTS_INIT_BASE_DATA_EDIT_FORM";
// export const VENDORS_FLIGHTS_UPDATE_BASE_DATA_FORM =
//   "VENDORS_FLIGHTS_UPDATE_BASE_DATA_FORM";
// export const VENDORS_FLIGHTS_APPLY_BASE_DATA_FORM =
//   "VENDORS_FLIGHTS_APPLY_BASE_DATA_FORM";
// export const VENDORS_FLIGHTS_INITIALIZE_BASE_DATA_FORM =
//   "VENDORS_FLIGHTS_INITIALIZE_BASE_DATA_FORM";
//=======================================================
// export const VENDORS_FLIGHTS_DATA_FORM = "VENDORS_FLIGHTS_DATA_FORM";
// export const VENDORS_FLIGHTS_INIT_FLIGHTS_COLUMNS_BASE_FORM =
//   "VENDORS_FLIGHTS_INIT_FLIGHTS_COLUMNS_BASE_FORM";
// export const VENDORS_FLIGHTS_INIT_FORM = "VENDORS_FLIGHTS_INIT_FORM,";
// export const VENDORS_FLIGHTS_BEGIN_FETCH_DATA_FORM =
//   "VENDORS_FLIGHTS_BEGIN_FETCH_DATA_FORM";
// export const VENDORS_FLIGHTS_END_FETCH_DATA_FORM =
//   "VENDORS_FLIGHTS_END_FETCH_DATA_FORM";
// export const VENDORS_FLIGHTS_BEGIN_FETCH_EDIT_DATA =
//   "VENDORS_FLIGHTS_BEGIN_FETCH_EDIT_DATA";
// export const VENDORS_FLIGHTS_END_FETCH_EDIT_DATA =
//   "VENDORS_FLIGHTS_END_FETCH_EDIT_DATA";
// export const VENDORS_FLIGHTS_DELIVERY_EDIT_DATA =
//   "VENDORS_FLIGHTS_DELIVERY_EDIT_DATA";

//===================================VALIDATE=====================================
// export const VENDORS_VALIDATE_BASE_DATA_EDIT_FORM =
//   "VENDORS_VALIDATE_BASE_DATA_EDIT_FORM";
// export const VENDORS_CLEAN_BASE_EDIT_FORM_ERRORS =
//   "VENDORS_CLEAN_BASE_EDIT_FORM_ERRORS";

//========================View Look To Book=====================
// export const LOADING_FLIGHT_VENDORS_VIEW_TO_BOOK_MODAL =
//   "LOADING_FLIGHT_VENDORS_VIEW_TO_BOOK_MODAL";
// export const LOADED_FLIGHT_VENDORS_VIEW_TO_BOOK_MODAL =
//   "LOADED_FLIGHT_VENDORS_VIEW_TO_BOOK_MODAL";
// export const SHOW_FLIGHT_VENDORS_VIEW_TO_BOOK_MODAL =
//   "SHOW_FLIGHT_VENDORS_VIEW_TO_BOOK_MODAL";
// export const HIDE_FLIGHT_VENDORS_VIEW_TO_BOOK_MODAL =
//   "HIDE_FLIGHT_VENDORS_VIEW_TO_BOOK_MODAL";

// export const SAVE_FLIGHT_VENDORS_VIEW_TO_BOOK_DATA =
//   "SAVE_FLIGHT_VENDORS_VIEW_TO_BOOK_DATA";

//========================NEW ACTIONS=====================

export const VENDORS_FLIGHTS_LIST_SAVE_RESULTS =
  "VENDORS_FLIGHTS_LIST_SAVE_RESULTS";
export const VENDORS_FLIGHTS_LIST_GENERIC_CHANGE =
  "VENDORS_FLIGHTS_LIST_GENERIC_CHANGE";
