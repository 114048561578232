import {
  RESTAURANT_LIST_GENERIC_CHANGE,
  RESTAURANT_LIST_SAVE_RESULTS,
} from "@src/actions/MyLibrary/Restaurants/types";
import { commonList } from "@src/reducers/common";

const restaurantsListReducerInitial = {
  ...commonList,
  filters: {
    reference: "",
    external_id: "",
    name: "",
    country_code: "",
    destination: "",
    has_menu: "",
    food_type: "",
    status: "",
  },
};
export const RestaurantsListReducer = (
  state = restaurantsListReducerInitial,
  action
) => {
  switch (action.type) {
    case RESTAURANT_LIST_SAVE_RESULTS: {
      const { data } = action;
      return { ...state, ...data };
    }
    case RESTAURANT_LIST_GENERIC_CHANGE: {
      const { key, value } = action;
      if (key === "filters") {
        return { ...state, [key]: value, page: 1 };
      } else {
        return { ...state, [key]: value };
      }
    }
    default:
      return state;
  }
};
