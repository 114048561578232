import _ from "lodash";
import { InlineIcon } from "@iconify/react";
import { fetchChatGroupMessages } from "@src/api/chat";
import { variables } from "@src/jsssetup";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { createUseStyles, JssProvider } from "react-jss";
import { Button } from "rsuite";
import useWebSocket, { ReadyState } from "react-use-websocket";
const easy_pin_icon_orange =
  "https://sisistaticassets.s3.ap-southeast-1.amazonaws.com/platform_graphics/imgs/easy_pin_icon_orange.svg";
import { useSelector } from "react-redux";
import { getUserMetaSelector } from "@src/selectors/Shared/user_selectors";
import { WhisperWrapper } from "@src/components/common/ui_helpers";

const noRoomSelectedtyles = createUseStyles({
  noRoom: {
    display: "grid",
    gridTemplateColumns: "repeat(3, max-content)",
    textAlign: "center",
    color: "#9F9F9F",
    fontSize: "large",
    alignItems: "center",
    justifyContent: "center",
  },
  noRoomArrowIcon: {
    color: "#9F9F9F",
    fontSize: "xx-large",
  },
  easyPinIcon: {
    transform: "translateY(-35%)",
    height: "3rem",
  },
});
const NoRoomSelected = () => {
  const classes = noRoomSelectedtyles();

  return (
    <div className={classes.noRoom}>
      <InlineIcon
        icon="ic:baseline-keyboard-arrow-left"
        className={classes.noRoomArrowIcon}
      />
      Please select a chat group{" "}
      <img src={easy_pin_icon_orange} alt="" className={classes.easyPinIcon} />
    </div>
  );
};

const messagetyles = createUseStyles({
  Message: (props) => ({
    justifyContent: props.ownMessage ? "end" : "start",
    justifyItems: props.ownMessage ? "end" : "baseline",
    height: "max-content",
    display: "grid",
    gridTemplateColumns: `${variables.double_gap} 75%`,
    alignItems: "end",
    gridGap: variables.half_gap,
    marginTop: props.isNotFirstOfSameSender
      ? `calc(${variables.normal_gap} / 4)`
      : variables.double_gap,
  }),
  messageContr: (props) => ({
    display: "grid",
    width: "100%",
    gridTemplateColumns: "100%",
    justifyItems: props.ownMessage ? "end" : "baseline",
  }),
  messageBubble: (props) => ({
    position: "relative",
    background: props.ownMessage ? "#ffc29a" : "#efefef",
    borderRadius:
      props.ownMessage &&
      !props.isNotFirstOfSameSender &&
      props.isLastOfSameSender
        ? variables.normal_gap
        : props.ownMessage &&
          !props.isNotFirstOfSameSender &&
          !props.isLastOfSameSender
        ? [variables.normal_gap, variables.normal_gap, 0, variables.normal_gap]
        : props.ownMessage &&
          props.isNotFirstOfSameSender &&
          !props.isLastOfSameSender
        ? [variables.normal_gap, 0, 0, variables.normal_gap]
        : props.ownMessage && props.isLastOfSameSender
        ? [variables.normal_gap, 0, variables.normal_gap, variables.normal_gap]
        : // ===============================================================
        !props.ownMessage &&
          !props.isNotFirstOfSameSender &&
          props.isLastOfSameSender
        ? variables.normal_gap
        : !props.ownMessage &&
          !props.isNotFirstOfSameSender &&
          !props.isLastOfSameSender
        ? [variables.normal_gap, variables.normal_gap, variables.normal_gap, 0]
        : !props.ownMessage &&
          props.isNotFirstOfSameSender &&
          !props.isLastOfSameSender
        ? [0, variables.normal_gap, variables.normal_gap, 0]
        : !props.ownMessage && props.isLastOfSameSender
        ? [0, variables.normal_gap, variables.normal_gap, variables.normal_gap]
        : 0,
    padding: variables.normal_gap,
    paddingBottom: `calc(${variables.normal_gap} * 1.6)`,
    height: "max-content",
    cursor: "pointer",
    overflowWrap: "anywhere",
    maxWidth: "100%",
    width: "max-content",
    minWidth: "10rem",
  }),
  timestamp: (props) => ({
    position: "absolute",
    right: variables.half_gap,
    bottom: variables.half_gap,
    color: props.ownMessage ? "white" : "gray",
    fontSize: "x-small",
  }),
  sender: (props) => ({
    display:
      props.ownMessage || props.isNotFirstOfSameSender ? "none" : "block",
    color: "#CCCCCC",
    fontSize: "x-small",
    paddingLeft: variables.normal_gap,
  }),
  status: (props) => ({
    textAlign: "end",
    color: props.ownMessage ? variables.colors.easy.lightOrange2 : "gray",
    fontSize: "small",
  }),
  usersIconContr: (props) => ({
    display: props.ownMessage || !props.isLastOfSameSender ? "none" : "grid",

    background: "#D9D9D9",
    borderRadius: "50%",
    height: variables.double_gap,
    width: variables.double_gap,
    placeItems: "center",
  }),
  usersIcon: () => ({
    display: "block",
    color: "white",
    fontSize: "medium",
  }),
  usersInitials: {
    fontSize: "small",
  },
});
const Message = ({
  message,
  ownMessage,
  isNotFirstOfSameSender,
  isLastOfSameSender,
}) => {
  const [expanded, setExpanded] = useState(false);
  const classes = messagetyles({
    ownMessage,
    isNotFirstOfSameSender,
    isLastOfSameSender,
  });

  return (
    <div className={classes.Message}>
      {isLastOfSameSender && !ownMessage ? (
        <WhisperWrapper msg={message.sender_email}>
          <div className={classes.usersIconContr}>
            {_.get(message, "sender_meta.first_name", "") &&
            _.get(message, "sender_meta.last_name", "") ? (
              <React.Fragment>
                <span className={classes.usersInitials}>
                  {_.get(message, "sender_meta.first_name", "").substring(0, 1)}
                  {_.get(message, "sender_meta.last_name", "").substring(0, 1)}
                </span>
              </React.Fragment>
            ) : (
              <InlineIcon icon="mdi:user" className={classes.usersIcon} />
            )}
          </div>
        </WhisperWrapper>
      ) : (
        <div />
      )}
      <div className={classes.messageContr}>
        <div className={classes.sender}>
          {_.get(message, "sender_meta.first_name", " ")}
        </div>
        <div
          className={classes.messageBubble}
          onClick={() => setExpanded((p) => !p)}>
          {message.content}
          <br />
          <div className={classes.timestamp}>
            {DateTime.fromISO(message.send_date, { zone: "utc" })
              .toLocal()
              .toLocaleString(DateTime.TIME_24_SIMPLE)}
          </div>
        </div>
        {expanded && <div className={classes.status}>Seen</div>}
      </div>
    </div>
  );
};
Message.propTypes = {
  message: PropTypes.object.isRequired,
  ownMessage: PropTypes.bool.isRequired,
  isNotFirstOfSameSender: PropTypes.bool.isRequired,
  isLastOfSameSender: PropTypes.bool.isRequired,
};

const chatPanelStyles = createUseStyles({
  ChatPanel: {
    background: "white",
    borderRadius: variables.normal_gap,
    padding: variables.normal_gap,
    position: "relative",
  },
  messagesPanel: {
    display: "grid",
    gridTemplateColumns: "100%",
    paddingBottom: variables.double_gap,
    position: "absolute",
    top: 0,
    left: variables.normal_gap,
    right: variables.normal_gap,
    overflowY: "auto",
    bottom: "6.5rem",
    alignContent: "center",
    paddingRight: variables.normal_gap,
  },
  textEditor: {
    display: "grid",
    height: "max-content",
    background: "#efefef",
    borderRadius: variables.normal_gap,
    padding: variables.half_gap,
    gridGap: variables.half_gap,
    zIndex: 1,
  },
  textInput: {
    "border": "none",
    "resize": "none",
    "height": "3rem",
    "background": "none",
    "color": variables.colors.text.dark,
    "&::placeholder": {
      color: "#9F9F9F",
    },
  },
  bottomContr: {
    position: "absolute",
    left: variables.half_gap,
    right: variables.half_gap,
    bottom: variables.half_gap,
  },
  sendButton: {
    "background": variables.colors.easy.orange,
    "color": "white",
    "alignSelf": "end",
    "padding": [variables.half_gap, variables.double_gap],
    "justifySelf": "end",
    "&:disabled": {
      background: "#CECECE",
      color: "white",
    },
  },
  controlsAndInfo: {
    position: "relative",
    height: `calc(${variables.normal_gap} * 4)`,
  },
  typingIcon: {
    fontSize: "xxx-large",
    position: "absolute",
    left: 0,
    bottom: `calc(${variables.normal_gap} * -1)`,
    zIndex: 0,
  },
  arrowIcon: {
    position: "absolute",
    left: "50%",
    top: 0,
    transform: "translate(-50%,50%)",
    background: "white",
    color: variables.colors.easy.orange,
    borderRadius: "50%",
    fontSize: "x-large",
    boxShadow: variables.shadows.heavyShadow,
    cursor: "pointer",
  },
});
const ChatPanel = ({ selectedGroup, groupUid, setConnectionStatus }) => {
  const classes = chatPanelStyles();
  const bottomOfChatRef = document.getElementById("bottom-of-chat-panel");
  const messageRef = useRef(null);
  const disabled = _.isEmpty(selectedGroup);

  const [url, setUrl] = useState("");
  const [messages, setMessages] = useState([]);
  const [showScrollToBtn, setShowScrollToBtn] = useState(false);

  const getSocketUrl = useCallback(() => {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (!url) {
          return;
        }

        resolve(url);
      }, 1000);
    });
  }, [url]);

  const { sendMessage, lastMessage, readyState } = useWebSocket(getSocketUrl);

  const member_email = useSelector(
    (state) => getUserMetaSelector(state)?.user?.email ?? ""
  );

  function scrollToBottom() {
    if (bottomOfChatRef) {
      bottomOfChatRef.scrollIntoView({
        behavior: "smooth",
        block: "start",
        alignToTop: false,
      });
    }
  }

  function setMsgs(messages) {
    setMessages(messages);
    setTimeout(() => {
      scrollToBottom();
    }, 500);
  }

  async function getMessages() {
    const result = await fetchChatGroupMessages({
      uid: groupUid,
    });
    setMsgs(result);
  }

  const handleClickSendMessage = useCallback(() => {
    const msg = JSON.stringify({ content: messageRef.current.value });
    if (messageRef.current.value) {
      sendMessage(msg);
      messageRef.current.value = "";
    }
  }, []);

  const connectionStatus = {
    [ReadyState.CONNECTING]: "connecting",
    [ReadyState.OPEN]: "open",
    [ReadyState.CLOSING]: "closing",
    [ReadyState.CLOSED]: "closed",
    [ReadyState.UNINSTANTIATED]: "uninstantiated",
  }[readyState];

  // Update messages
  useEffect(() => {
    if (!lastMessage) {
      return;
    }
    setMsgs((p) => [...p, JSON.parse(lastMessage.data)]);
  }, [lastMessage]);

  // Fetch old messages
  useEffect(() => {
    if (!groupUid) {
      return;
    }
    setUrl(
      `wss://sindarin.easytravel.tech:8792/ws/chat/${groupUid}/${member_email}/`
    );

    getMessages();
  }, [groupUid]);

  // Control connection status
  useEffect(() => {
    setConnectionStatus(connectionStatus === "open");
  }, [connectionStatus]);

  return (
    <div className={classes.ChatPanel}>
      <div
        className={classes.messagesPanel}
        id="chat-panel"
        onScroll={(e) => {
          const el = e.target;
          if (!el) {
            return;
          }

          if (el.scrollTop + el.offsetHeight === el.scrollHeight) {
            setShowScrollToBtn(false);
          } else {
            setShowScrollToBtn(true);
          }
        }}>
        {!disabled ? (
          messages.map((message, idx) => (
            <JssProvider classNamePrefix={`message___${idx}-`} key={idx}>
              <Message
                message={{
                  ...message,
                  sender_meta: selectedGroup.members.find(
                    (member) => member.email === message.sender_email
                  ),
                }}
                ownMessage={member_email === message.sender_email}
                isNotFirstOfSameSender={
                  idx > 0 &&
                  message.sender_email === messages[idx - 1].sender_email
                }
                isLastOfSameSender={
                  messages.length - 1 === idx ||
                  message.sender_email !== messages[idx + 1].sender_email
                }
              />
            </JssProvider>
          ))
        ) : (
          <NoRoomSelected />
        )}
        <div id="bottom-of-chat-panel"></div>
      </div>
      <div className={classes.bottomContr}>
        {!disabled && connectionStatus === "open" && showScrollToBtn && (
          <div className={classes.controlsAndInfo}>
            <InlineIcon
              icon="ic:baseline-keyboard-arrow-down"
              className={classes.arrowIcon}
              onClick={scrollToBottom}
            />
          </div>
        )}
        <form
          className={classes.textEditor}
          onSubmit={(e) => {
            e.preventDefault();
            handleClickSendMessage();
          }}>
          <input
            className={classes.textInput}
            placeholder="Type message..."
            ref={messageRef}
            disabled={disabled || connectionStatus !== "open"}
          />
          <Button
            size="sm"
            type="submit"
            className={classes.sendButton}
            disabled={disabled || connectionStatus !== "open"}>
            Send
          </Button>
        </form>
      </div>
    </div>
  );
};
ChatPanel.propTypes = {
  selectedGroup: PropTypes.object,
  groupUid: PropTypes.string.isRequired,
  setConnectionStatus: PropTypes.func.isRequired,
};
export default ChatPanel;
