import _ from "lodash";
import { NormalDatePicker, NormalInputField } from "@src/components/forms";
import { currencies } from "@src/definitions";
import { cardStyles, variables } from "@src/jsssetup";
import { Form, Formik } from "formik";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Loader } from "rsuite";
import {
  Actions,
  CommonFeedback,
  ContactInformation,
  RequestInfo,
  Stepper,
} from "../common";
import {
  genericButtonsStyles,
  genericInfoGroupStyles,
  genericRsrvFeebackStyles,
  rsrvFeebackStyles,
} from "../common/styles";
import { useFeedbackRemarks, useSupplierInfo } from "../common/hooks";
import { cxlRuleTemplate } from "../common/cxl_policies";
import { onReject, onSubmit, payloader, retrieverFn } from "../common/helpers";
import {
  patchPublicFerryReservation,
  retrievePublicFerryReservation,
} from "@src/api/Operation/Reservations/Ferries";
import Cabins from "../common/cabins";
import {
  commonValidateFeedback,
  validateSupplierInfo,
} from "../common/validators";
import { toast } from "react-toastify";
import { basicInfoStylesObj } from "../accommodation/common";

const basicInfoStyles = createUseStyles({
  ...genericInfoGroupStyles,
  ...genericButtonsStyles,
  BasicInfo: genericRsrvFeebackStyles.card,
  header: genericRsrvFeebackStyles.cardHeader,
  body: genericRsrvFeebackStyles.cardBody,
  groupCard: basicInfoStylesObj.groupCard,
  infoGroup2: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: variables.normal_gap,
  },
  infoGroup1to2: {
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr)",
    gridGap: variables.normal_gap,
  },
  infoGroup4: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: variables.normal_gap,
  },
  buttonsGroup: {
    display: "grid",
    justifyContent: "end",
  },
  [`@media ${variables.media.bigscreen}`]: {
    infoGroup4: {
      gridTemplateColumns: "repeat(4, 1fr)",
    },
    infoGroup1to2: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
  },
});
// const basicInfoStyles = createUseStyles({
//   BasicInfo: cardStyles.card,
//   header: cardStyles.header,
//   table: tableStyles.table,
//   head: tableStyles.head,
//   cell: tableStyles.cell,
//   gateCell: {
//     ...tableStyles.cell,
//     "width": "7.5rem",
//     "& input": {
//       width: "7.5rem",
//     },
//   },
//   pricecell: {
//     ...tableStyles.cell,
//     "width": "5rem",
//     "& input": {
//       width: "5rem",
//     },
//   },
// });
function BasicInfo({ step, reservation, inViewMode, onConfirm }) {
  const classes = basicInfoStyles();
  const [viewMode, setViewMode] = useState(inViewMode);

  return (
    <div className={classes.BasicInfo}>
      <div className={classes.header}>
        <h5>Basic Information</h5>
      </div>
      <Formik
        initialValues={{
          origin: reservation.origin,
          destination: reservation.destination,
          origin_port: reservation.origin_port,
          destination_port: reservation.destination_port,
          departure: reservation.departure,
          arrival: reservation.arrival,
          price: parseFloat(_.get(reservation, "original_service_price", 0)),
          company: reservation.company,
          departure_gate: reservation.departure_gate,
          arrival_gate: reservation.arrival_gate,
          vessel: reservation.vessel_name,
          ticket_class: reservation.ticket_class_display,
          meal_type: reservation.meal_type,
          cabins: [
            ..._.get(reservation, "service_data.cabins", []).map((c) => {
              c.type = _.startCase(c.type);
              return c;
            }),
          ],
          pax: reservation.pax,
          adults: reservation.adults,
          children: reservation.children,
          vehicle_included: reservation.vehicle_included,
          vehicle: reservation.vehicle,
        }}
        onSubmit={(values) => {
          setViewMode(true);
          onConfirm(values);
        }}>
        {({ values }) => (
          <Form className={classes.body}>
            <div className={classes.groupCard}>
              <div className={classes.infoGroup2}>
                <div className={classes.infoCouple}>
                  <span className={classes.infoCoupleTitle}>Origin</span>
                  <span className={classes.infoCoupleValue}>
                    {reservation.origin || "N/A"}
                  </span>
                </div>
                <div className={classes.infoCouple}>
                  <span className={classes.infoCoupleTitle}>Destination</span>
                  <span className={classes.infoCoupleValue}>
                    {reservation.destination || "N/A"}
                  </span>
                </div>
              </div>
            </div>
            <div className={classes.groupCard}>
              <div className={classes.infoGroup4}>
                <div className={classes.infoCouple}>
                  <span className={classes.infoCoupleTitle}>Origin Port</span>
                  <span className={classes.infoCoupleValue}>
                    {reservation.origin_port}
                  </span>
                </div>
                <div className={classes.infoCouple}>
                  <span className={classes.infoCoupleTitle}>
                    Destination Port
                  </span>
                  <span className={classes.infoCoupleValue}>
                    {reservation.destination_port}
                  </span>
                </div>
                <div className={classes.infoCouple}>
                  <span className={classes.infoCoupleTitle}>
                    Departure Gate
                  </span>
                  <span className={classes.infoCoupleValue}>
                    {viewMode ? (
                      values.departure_gate || "N/A"
                    ) : (
                      <NormalInputField name="departure_gate" />
                    )}
                  </span>
                </div>
                <div className={classes.infoCouple}>
                  <span className={classes.infoCoupleTitle}>Arrival Gate</span>
                  <span className={classes.infoCoupleValue}>
                    {viewMode ? (
                      values.arrival_gate || "N/A"
                    ) : (
                      <NormalInputField name="arrival_gate" />
                    )}
                  </span>
                </div>
              </div>
            </div>

            <div className={classes.infoGroup1to2}>
              <div className={classes.groupCard}>
                <div className={classes.infoGroup1to2}>
                  <div className={classes.infoCouple}>
                    <span className={classes.infoCoupleTitle}>Departure</span>
                    <span className={classes.infoCoupleValue}>
                      {viewMode ? (
                        DateTime.fromISO(values.departure).toLocaleString(
                          DateTime.DATETIME_MED_WITH_WEEKDAY
                        )
                      ) : (
                        <NormalDatePicker withTime={true} name="departure" />
                      )}
                    </span>
                  </div>
                  <div className={classes.infoCouple}>
                    <span className={classes.infoCoupleTitle}>Arrival</span>
                    <span className={classes.infoCoupleValue}>
                      {viewMode ? (
                        DateTime.fromISO(values.arrival).toLocaleString(
                          DateTime.DATETIME_MED_WITH_WEEKDAY
                        )
                      ) : (
                        <NormalDatePicker withTime={true} name="arrival" />
                      )}
                    </span>
                  </div>
                </div>
              </div>

              <div className={classes.groupCard}>
                <div className={classes.infoGroup2}>
                  <div className={classes.infoCouple}>
                    <span className={classes.infoCoupleTitle}>Currency</span>
                    <span className={classes.infoCoupleValue}>
                      {reservation.currency} (
                      {_.get(currencies, reservation.currency)})
                    </span>
                  </div>
                  <div className={classes.infoCouple}>
                    <span className={classes.infoCoupleTitle}>Price</span>
                    <span className={classes.infoCoupleValue}>
                      {viewMode ? (
                        Number(values.price).toFixed(2) || 0
                      ) : (
                        <NormalInputField name="price" type="number" />
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {step <= 1 && (
              <div className={classes.buttonsGroup}>
                {viewMode ? (
                  <button
                    // appearance="ghost"
                    // size="xs"
                    className={classes.cancelButton}
                    onClick={(e) => {
                      e.preventDefault();
                      setViewMode(false);
                    }}>
                    <strong>Edit</strong>
                  </button>
                ) : (
                  <button
                    //  color="green" size="xs"
                    className={classes.confirmButton}
                    type="submit">
                    <strong>Confirm</strong>
                  </button>
                )}
              </div>
            )}
            {/* <table className={classes.table}>
              <thead className={classes.head}>
                <tr>
                  <th>Origin</th>
                  <th>Destination</th>
                  <th>Origin Port</th>
                  <th>Destination Port</th>
                  <th>Departure Gate</th>
                  <th>Arrival Gate</th>
                  <th>Departure</th>
                  <th>Arrival</th>
                  <th>Currency</th>
                  <th>Price</th>
                  {step <= 1 && <th>Actions</th>}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={classes.cell}>
                    {reservation.origin || "N/A"}
                  </td>
                  <td className={classes.cell}>
                    {reservation.destination || "N/A"}
                  </td>
                  <td className={classes.cell}>{reservation.origin_port}</td>
                  <td className={classes.cell}>
                    {reservation.destination_port}
                  </td>
                  <td className={classes.gateCell}>
                    {viewMode ? (
                      values.departure_gate || "N/A"
                    ) : (
                      <NormalInputField name="departure_gate" />
                    )}
                  </td>
                  <td className={classes.gateCell}>
                    {viewMode ? (
                      values.arrival_gate || "N/A"
                    ) : (
                      <NormalInputField name="arrival_gate" />
                    )}
                  </td>
                  <td className={classes.cell}>
                    {viewMode ? (
                      DateTime.fromISO(values.departure).toLocaleString(
                        DateTime.DATETIME_MED_WITH_WEEKDAY
                      )
                    ) : (
                      <NormalDatePicker withTime={true} name="departure" />
                    )}
                  </td>
                  <td className={classes.cell}>
                    {viewMode ? (
                      DateTime.fromISO(values.arrival).toLocaleString(
                        DateTime.DATETIME_MED_WITH_WEEKDAY
                      )
                    ) : (
                      <NormalDatePicker withTime={true} name="arrival" />
                    )}
                  </td>
                  <td className={classes.cell}>
                    {reservation.currency} (
                    {_.get(currencies, reservation.currency)})
                  </td>
                  <td className={classes.pricecell}>
                    {viewMode ? (
                      Number(values.price).toFixed(2) || 0
                    ) : (
                      <NormalInputField name="price" type="number" />
                    )}
                  </td>
                  {step <= 1 && (
                    <td className={classes.cell}>
                      {viewMode ? (
                        <Button
                          appearance="ghost"
                          size="xs"
                          onClick={(e) => {
                            e.preventDefault();
                            setViewMode(false);
                          }}>
                          <strong>Edit</strong>
                        </Button>
                      ) : (
                        <Button color="green" size="xs" type="submit">
                          <strong>Confirm</strong>
                        </Button>
                      )}
                    </td>
                  )}
                </tr>
              </tbody>
            </table>
            <table className={classes.table}>
              <thead className={classes.head}>
                <tr>
                  <th>Company Name</th>
                  <th>Vessel</th>
                  <th>Ticket Class</th>
                  <th>Meal Type</th>
                  <th>Vehicle</th>
                  <th>Pax</th>
                  <th>Adults</th>
                  <th>Children</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={classes.cell}>
                    {viewMode ? (
                      values.company || "N/A"
                    ) : (
                      <NormalInputField name="company" />
                    )}
                  </td>
                  <td className={classes.cell}>
                    {viewMode ? (
                      values.vessel_name || "N/A"
                    ) : (
                      <NormalInputField name="vessel_name" />
                    )}
                  </td>
                  <td className={classes.cell}>
                    {viewMode ? (
                      values.ticket_class || "N/A"
                    ) : (
                      <NormalInputField name="ticket_class" />
                    )}
                  </td>
                  <td className={classes.cell}>
                    {viewMode ? (
                      values.meal_type || "N/A"
                    ) : (
                      <NormalInputField name="meal_type" />
                    )}
                  </td>
                  <td className={classes.cell}>
                    {reservation.vehicle_included ? reservation.vehicle : "N/A"}
                  </td>
                  <td className={classes.cell}>{reservation.pax}</td>
                  <td className={classes.cell}>{reservation.adults}</td>
                  <td className={classes.cell}>{reservation.children}</td>
                </tr>
              </tbody>
            </table> */}
            <Cabins cabins={values.cabins} viewMode={viewMode} />
          </Form>
        )}
      </Formik>
    </div>
  );
}
BasicInfo.propTypes = {
  step: PropTypes.number.isRequired,
  reservation: PropTypes.object.isRequired,
  inViewMode: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

function validateFeedback({
  cxl_remarks,
  booking_mode_remarks,
  reservation_remarks,
  payment_mode_remarks,
  request_cxl_policy,
  cxl_rules,
}) {
  const msgs = commonValidateFeedback({
    cxl_remarks,
    booking_mode_remarks,
    reservation_remarks,
    payment_mode_remarks,
    request_cxl_policy,
    cxl_rules,
  });

  return msgs;
}

const FerryResFeedbackStyles = createUseStyles({
  FerryResFeedback: rsrvFeebackStyles,
  ...genericRsrvFeebackStyles,
  cardHeader: cardStyles.header,
  cardBody: {
    height: "100%",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridGap: "1.5rem",
  },
  leftCol: {
    width: "100%",
  },
  rightCol: {
    width: "100%",
    display: "grid",
    gridGap: variables.normal_gap,
  },
  [`@media ${variables.media.bigscreen}`]: {
    cardBody: {
      gridTemplateColumns: "30% 65%",
    },
  },
});
const FerryResFeedback = () => {
  const classes = FerryResFeedbackStyles();
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const feedRemarks = useFeedbackRemarks();
  const supplierInfo = useSupplierInfo();

  const [reservation, setReservation] = useState({});
  const [cxl_rules, setCxlRules] = useState([cxlRuleTemplate]);
  const [confirmData, setConfirmData] = useState({});

  const history = useHistory();

  const {
    params: { reference },
  } = useRouteMatch();

  useEffect(() => {
    if (reference) {
      retrieverFn({
        reference,
        fn: retrievePublicFerryReservation,
        setLoading,
        setReservation,
      });
    }
  }, [reference]);

  function nextStepper() {
    var msgs = [];
    if (step === 0) {
      msgs = validateFeedback({
        cxl_remarks: feedRemarks.cxl_remarks,
        booking_mode_remarks: feedRemarks.booking_mode_remarks,
        reservation_remarks: feedRemarks.reservation_remarks,
        payment_mode_remarks: feedRemarks.payment_mode_remarks,
        request_cxl_policy: reservation.request_cxl_policy,
        cxl_rules,
      });
    } else if (step === 1) {
      msgs = validateSupplierInfo({
        supplier_name: supplierInfo.supplier_name,
        supplier_email: supplierInfo.supplier_email,
        supplier_phone: supplierInfo.supplier_phone,
      });
    }
    if (msgs.length) {
      msgs.forEach((error) => toast.error(error, { duration: 5000 }));
      return;
    }

    setStep((p) => p + 1);
  }

  const actionProps = {
    step,
    prevStepper: () => setStep((p) => p - 1),
    nextStepper,
    onSubmit: () => {
      const payload = payloader({
        supplierInfo,
        feedRemarks,
        cxl_rules,
        reference,
        feedback_service_data: {
          ...confirmData,
          currency: reservation.currency,
        },
        reservation_type: "FER",
      });
      onSubmit({ history, payload, setLoading });
    },
    onReject: () =>
      onReject({
        history,
        reference,
        payload: { status: "RJ" },
        setLoading,
        resPatchFunc: patchPublicFerryReservation,
      }),
  };

  return (
    <div className={classes.FerryResFeedback}>
      <Stepper step={step} />
      {loading ? (
        <div className={classes.cardBody}>
          <Loader size="lg" center />
        </div>
      ) : [0, 2].includes(step) ? (
        // <div className={classes.card}>
        //   <div className={classes.cardHeader}>
        //     <h5>
        //       Reservation Confirmation Panel: {reservation.reference || "N/A"}
        //     </h5>
        //   </div>
        <div className={classes.cardBody}>
          <div className={classes.leftCol}>
            <RequestInfo reservation={reservation} />
          </div>
          <div className={classes.rightCol}>
            <BasicInfo
              step={step}
              reservation={reservation}
              inViewMode={!_.isEmpty(confirmData)}
              onConfirm={(data) => setConfirmData(data)}
            />
            <CommonFeedback
              reservation={reservation}
              feedRemarks={feedRemarks}
              editable={step == 0}
              cxl_rules={cxl_rules}
              setCxlRules={setCxlRules}
            />
            <Actions {...actionProps} />
          </div>
        </div>
      ) : (
        // </div>
        <ContactInformation
          {...supplierInfo}
          onChange={(values) => {
            supplierInfo.setSupplierName(values.supplier_name);
            supplierInfo.setSupplierEmail(values.supplier_email);
            supplierInfo.setSupplierPhone(values.supplier_phone);
            supplierInfo.setSupplierRemarks(values.supplier_remarks);
          }}
          actionProps={actionProps}
        />
      )}
    </div>
  );
};

export default FerryResFeedback;
