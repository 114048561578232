import {
  TRIPPLANNER_SET_PREBOOKTRF_DATA,
  TRIPPLANNER_RESET_PREBOOKTRF_DATA,
} from "./prebook_types";

export const setTrfPrebookData = ({ data }) => {
  return { type: TRIPPLANNER_SET_PREBOOKTRF_DATA, data };
};

export const resetTrfPrebookData = () => {
  return { type: TRIPPLANNER_RESET_PREBOOKTRF_DATA };
};
