import { notifyCommonError } from "@src/components/common/notifications/CommonErrorNotification.js";
import { notifyCommonWarning } from "@src/components/common/notifications/CommonWarningNotification.js";

import {
  SISIBID_NEW_BID_FETCH_AFFILIATES,
  SISIBID_NEW_BID_BEGIN_FETCH_AFFILIATES,
  SISIBID_NEW_BID_END_FETCH_AFFILIATES,
  SISIBID_BID_MODIFY_AREA_VALUE,
  SISIBID_BID_MODIFY_CITY,
  SISIBID_BID_SUPPLY_LIST_UPDATE_CHECKED_STATUS,
  // ======================== BIDDERS FILTERS ========================
  SISIBID_INITIALIZE_BIDDERS_FILTERS,
  SISIBID_CHANGE_BIDDERS_FILTERS,
  // ======================= STEP TWO BIDDERS LIST ======================
  SISIBID_SELECT_BIDDER,
  SISIBID_SELECT_ALL_BIDDERS,
  SISIBID_UNSELECT_ALL_BIDDERS,
  // ======================= SEND REQUEST ACTIONS ======================
  SISIBID_REQUEST_SEND_BID_REQUEST,
  SISIBID_CANCEL_SEND_BID_REQUEST,
  SISIBID_SEND_BID_REQUEST,
  SISIBID_SENT_BID_REQUEST,
  SISIBID_REQUEST_DATA_UPDATE,
} from "./types";

import { fetchAffiliatesOptions } from "@src/actions/Network/Affiliates";
import { getBidRequestTimeData } from "@src/selectors/Project/Bid/request_selectors";

import update from "immutability-helper";

import _ from "lodash";
import {
  getAffiliatesList,
  sendBiddingRequest as sendBiddingRequestAPI,
} from "@src/api";

import { filterEmptyValue } from "@src/tools/common_tools";

export const fetchAffiliatesList = () => async (dispatch, getState) => {
  dispatch({ type: SISIBID_NEW_BID_BEGIN_FETCH_AFFILIATES });
  const filters = getState().newBiddingBiddersFilters;

  const req = filterEmptyValue({
    rating: filters.rating.toString(),
    travel_services__in: filters.serviceCategory.toString(),
    entity_type: filters.tag.toString(),
    expertise_type: filters.expertise.toString(),
    limit: 1000,
    // area: filters.toString()
  });

  try {
    const res = await getAffiliatesList(req);
    const results = update(_.get(res, "results", []), {
      $apply: (results) =>
        results.map((r) =>
          update(r, { ["bidderType"]: { $set: "affiliate" } })
        ),
    });

    dispatch({ type: SISIBID_NEW_BID_END_FETCH_AFFILIATES });
    dispatch({ type: SISIBID_NEW_BID_FETCH_AFFILIATES, data: results });
  } catch (error) {
    //todo;
  }
};

export const changeBiddingSupplyArea = (value) => (dispatch) => {
  dispatch({ type: SISIBID_BID_MODIFY_AREA_VALUE, data: value });
};

export const changeBiddingSupplyCity = (id) => (dispatch, getState) => {
  const biddingSupplyCity = getState().newBiddingSupplyCity;
  const city = _.find(biddingSupplyCity, { id: id }) || {};

  return { type: SISIBID_BID_MODIFY_CITY, data: city };
};

export const initializeBiddersFilters = () => {
  return { type: SISIBID_INITIALIZE_BIDDERS_FILTERS };
};

export const changeBiddersFilters = (name, value, fetch = true) => async (
  dispatch
) => {
  await dispatch({ type: SISIBID_CHANGE_BIDDERS_FILTERS, name, value });
  if (fetch) {
    await dispatch(fetchAffiliatesList());
  }
};

export const updateBiddingSupplierCheckedStatus = (value) => {
  return {
    type: SISIBID_BID_SUPPLY_LIST_UPDATE_CHECKED_STATUS,
    data: value,
  };
};

export const updateBiddingSuppliersCheckAllStatus = (checked) => (
  dispatch,
  getState
) => {
  const biddingSupplyList = getState().newBiddingSupplyList;
  const allValue = biddingSupplyList.map((item) => {
    return item.id;
  });

  dispatch({
    type: SISIBID_BID_SUPPLY_LIST_UPDATE_CHECKED_STATUS,
    data: checked ? allValue : [],
  });
};

export const selectBidder = (bidder, actionType, uid) => {
  return { type: SISIBID_SELECT_BIDDER, bidder, actionType, uid };
};

export const selectAllBidders = (uid) => (dispatch, getState) => {
  const bidder_entities = getState().newBiddingBidders;
  dispatch({ type: SISIBID_SELECT_ALL_BIDDERS, bidder_entities, uid });
};

export const unselectAllBidders = (uid) => {
  return { type: SISIBID_UNSELECT_ALL_BIDDERS, uid };
};

export const requestSendBidRequest = (uid) => (dispatch, getState) => {
  const state = getState();

  const bidding = state.newBiddingList.find((b) => b.uid == uid);

  if (!bidding.bidder_entities.length) {
    notifyCommonWarning("At least one bidder is required to send a request!");
    return;
  }

  dispatch({ type: SISIBID_REQUEST_SEND_BID_REQUEST, uid });
};

export const cancelSendBidRequest = () => {
  return { type: SISIBID_CANCEL_SEND_BID_REQUEST };
};

export const sendBiddingRequest = (uid) => async (dispatch, getState) => {
  const state = getState();

  const bidding = state.newBiddingList.find((b) => b.uid == uid) || null;

  const { expirationTime } = getBidRequestTimeData(state, { bidding });

  const fail_msg =
    "Your bidding request cannot be sent at this time. We are sorry for the inconvenience";

  if (!bidding) {
    notifyCommonError(fail_msg);
    return;
  }

  if (!bidding.id) {
    notifyCommonError("Please save your bidding first!");
    return;
  }

  await dispatch({ type: SISIBID_SEND_BID_REQUEST });

  try {
    const res = await sendBiddingRequestAPI(bidding.id, {
      request_status: "SE",
      expiration_time: expirationTime,
    });
    await dispatch({ type: SISIBID_REQUEST_DATA_UPDATE, bidding: res });
    dispatch({ type: SISIBID_SENT_BID_REQUEST });
  } catch (error) {
    notifyCommonError(fail_msg);
    dispatch({ type: SISIBID_CANCEL_SEND_BID_REQUEST });
  }
};

export const stepTwoInitActions = () => (dispatch) => {
  dispatch(fetchAffiliatesOptions());
  dispatch(initializeBiddersFilters());
  dispatch(fetchAffiliatesList());
};
