import {
  //=========================FILTER==========================
  PACKAGES_WHOLESALES_INITIALIZE_BASIC_FILTER_DATA,
  PACKAGES_WHOLESALES_EDIT_BASIC_FILTER_DATA_FORM,
  PACKAGES_WHOLESALES_UPDATE_FILTER_FORM,
  PACKAGES_WHOLESALES_APPLY_LIST_FILTER,
  PACKAGES_WHOLESALES_INITIALIZE_LIST_FILTER_FORM,
  PACKAGES_WHOLESALES_RESET_LIST_FILTER_FORM,
  PACKAGES_WHOLESALES_CHANGE_PRICE_STATUS,
  //=======================LIST=============================
  PACKAGES_WHOLESALES_FETCH_DATA,
  PACKAGES_WHOLESALES_LIST_LOADING,
  PACKAGES_WHOLESALES_LIST_IDLE,
  PACKAGES_WHOLESALES_LIST_GENERIC_CHANGE,
  PACKAGES_WHOLESALES_FETCH_LIST,
} from "@src/actions/Project/Packages/Wholesales/types";

import { FORMAT_TRAVEL_SERVICES_FORM } from "@src/actions/Shares/types";

import { initialBasicFilterForm } from "@src/forms/Project/Archive";
import { initialOnRequestFilterForm } from "@src/forms/Project/Archive/OnRequest";

import update from "immutability-helper";
import { formProcessor } from "@src/reducers/tools";
import { commonList } from "../../../common";

export const initialList = {
  count: 0,
  next: null,
  previous: null,
  results: [],
  page: 1,
  limit: 6,
  displayLength: 100,
  loading: false,
};

export const wholesalesList = (state = initialList, action) => {
  switch (action.type) {
    case PACKAGES_WHOLESALES_FETCH_DATA:
      return { ...state, ...action.data };
    case PACKAGES_WHOLESALES_LIST_IDLE:
      return { ...state, loading: false };
    case PACKAGES_WHOLESALES_LIST_LOADING:
      return { ...state, loading: true };
    case PACKAGES_WHOLESALES_RESET_LIST_FILTER_FORM:
      return { ...state, page: 1 };
    default:
      return state;
  }
};

export const wholesalesBasicFilterData = (
  state = initialBasicFilterForm,
  action
) => {
  switch (action.type) {
    case PACKAGES_WHOLESALES_INITIALIZE_BASIC_FILTER_DATA:
      return action.data;
    case PACKAGES_WHOLESALES_EDIT_BASIC_FILTER_DATA_FORM:
      return update(state, {
        [action.data.name]: { value: { $set: action.data.value } },
      });
    default:
      return state;
  }
};
// filter
export const wholesalesListFilter = (
  state = initialOnRequestFilterForm,
  action
) => {
  switch (action.type) {
    case PACKAGES_WHOLESALES_APPLY_LIST_FILTER:
      return action.data;
    case FORMAT_TRAVEL_SERVICES_FORM:
      return update(state, { $merge: action.data });
    default:
      return state;
  }
};

export const wholesalesListFilters = (
  state = initialOnRequestFilterForm,
  action
) => {
  switch (action.type) {
    case PACKAGES_WHOLESALES_INITIALIZE_LIST_FILTER_FORM:
      return initialOnRequestFilterForm;
    case PACKAGES_WHOLESALES_RESET_LIST_FILTER_FORM:
      return action.data;
    case PACKAGES_WHOLESALES_UPDATE_FILTER_FORM:
      return formProcessor(
        state,
        action.data.type,
        action.data.name,
        action.data.value
      );
    case FORMAT_TRAVEL_SERVICES_FORM:
      return update(state, { $merge: action.data });
    default:
      return state;
  }
};

export const setWholesalesPrice = (state = "selling", action) => {
  switch (action.type) {
    case PACKAGES_WHOLESALES_CHANGE_PRICE_STATUS:
      return action.data;
    default:
      return state;
  }
};

// ====================NEW====================
export const newWholesalesListReducer = (
  state = {
    ...commonList,
    limit: 6,
    filters: {
      reference: "",
      title__icontains: "",
      trip_date__gte: "",
      trip_date__lte: "",
      destination__icontains: "",
      creator__icontains: "",
      is_owner: true,
      status: "",
      stay: "",
      website_assigned: "",
    },
  },
  action
) => {
  switch (action.type) {
    case PACKAGES_WHOLESALES_FETCH_LIST: {
      const { data } = action;
      return { ...state, ...data };
    }
    case PACKAGES_WHOLESALES_LIST_GENERIC_CHANGE: {
      const { key, value } = action;
      if (key !== "filters") {
        return { ...state, [key]: value };
      } else {
        return { ...state, [key]: value, page: 1 };
      }
    }
    default:
      return state;
  }
};
